export const companyList = {
    "companiesList": [
        {
            "name": "135 Airways",
            "code": "",
            "auxcode": "GNL",
            "companyName": "GENERAL",
            "country": "United States"
        },
        {
            "name": "1Time Airline",
            "code": "1T",
            "auxcode": "RNX",
            "companyName": "NEXTIME",
            "country": "South Africa"
        },
        {
            "name": "2 Sqn No 1 Elementary Flying Training School",
            "code": "",
            "auxcode": "WYT",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "213 Flight Unit",
            "code": "",
            "auxcode": "TFU",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "223 Flight Unit State Airline",
            "code": "",
            "auxcode": "CHD",
            "companyName": "CHKALOVSKcodeAVIA",
            "country": "Russia"
        },
        {
            "name": "224th Flight Unit",
            "code": "",
            "auxcode": "TTF",
            "companyName": "CARGO UNIT",
            "country": "Russia"
        },
        {
            "name": "247 Jet Ltd",
            "code": "",
            "auxcode": "TWF",
            "companyName": "CLOUD RUNNER",
            "country": "United Kingdom"
        },
        {
            "name": "3D Aviation",
            "code": "",
            "auxcode": "SEC",
            "companyName": "SECUREX",
            "country": "United States"
        },
        {
            "name": "40codeMile Air",
            "code": "Q5",
            "auxcode": "MLA",
            "companyName": "MILEcodeAIR",
            "country": "United States"
        },
        {
            "name": "4D Air",
            "code": "",
            "auxcode": "QRT",
            "companyName": "QUARTET",
            "country": "Thailand"
        },
        {
            "name": "611897 Alberta Limited",
            "code": "",
            "auxcode": "THD",
            "companyName": "DONUT",
            "country": "Canada"
        },
        {
            "name": "Ansett Australia",
            "code": "AN",
            "auxcode": "AAA",
            "companyName": "ANSETT",
            "country": "Australia"
        },
        {
            "name": "Abacus International",
            "code": "1B",
            "auxcode": "",
            "companyName": "",
            "country": "Singapore"
        },
        {
            "name": "Abelag Aviation",
            "code": "W9",
            "auxcode": "AAB",
            "companyName": "ABG",
            "country": "Belgium"
        },
        {
            "name": "Army Air Corps",
            "code": "",
            "auxcode": "AAC",
            "companyName": "ARMYAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Aero Aviation Centre Ltd.",
            "code": "",
            "auxcode": "AAD",
            "companyName": "SUNRISE",
            "country": "Canada"
        },
        {
            "name": "Aero Servicios Ejecutivos Internacionales",
            "code": "",
            "auxcode": "SII",
            "companyName": "ASEISA",
            "country": "Mexico"
        },
        {
            "name": "Aero Biniza",
            "code": "",
            "auxcode": "BZS",
            "companyName": "BINIZA",
            "country": "Mexico"
        },
        {
            "name": "Aero Albatros",
            "code": "",
            "auxcode": "ABM",
            "companyName": "ALBATROS ESPANA",
            "country": "Spain"
        },
        {
            "name": "Aigle Azur",
            "code": "ZI",
            "auxcode": "AAF",
            "companyName": "AIGLE AZUR",
            "country": "France"
        },
        {
            "name": "Aloha Airlines",
            "code": "AQ",
            "auxcode": "AAH",
            "companyName": "ALOHA",
            "country": "United States"
        },
        {
            "name": "Alaska Island Air",
            "code": "",
            "auxcode": "AAK",
            "companyName": "ALASKA ISLAND",
            "country": "United States"
        },
        {
            "name": "American Airlines",
            "code": "AA",
            "auxcode": "AAL",
            "companyName": "AMERICAN",
            "country": "United States"
        },
        {
            "name": "Aviation Management Corporation",
            "code": "",
            "auxcode": "AAM",
            "companyName": "AM CORP",
            "country": "United States"
        },
        {
            "name": "Atlantis Airlines (USA)",
            "code": "",
            "auxcode": "AAO",
            "companyName": "ATLANTIS AIR",
            "country": "United States"
        },
        {
            "name": "Aerovista Airlines",
            "code": "",
            "auxcode": "AAP",
            "companyName": "AEROVISTA GROUP",
            "country": "United Arab Emirates"
        },
        {
            "name": "Asiana Airlines",
            "code": "OZ",
            "auxcode": "AAR",
            "companyName": "ASIANA",
            "country": "Republic of Korea"
        },
        {
            "name": "Askari Aviation",
            "code": "4K",
            "auxcode": "AAS",
            "companyName": "ALcodeAAS",
            "country": "Pakistan"
        },
        {
            "name": "Australia Asia Airlines",
            "code": "",
            "auxcode": "AAU",
            "companyName": "AUSTASIA",
            "country": "Australia"
        },
        {
            "name": "Astro Air International",
            "code": "",
            "auxcode": "AAV",
            "companyName": "ASTROcodePHIL",
            "country": "United States"
        },
        {
            "name": "Afriqiyah Airways",
            "code": "8U",
            "auxcode": "AAW",
            "companyName": "AFRIQIYAH",
            "country": "Libya"
        },
        {
            "name": "Afrinat International Airlines",
            "code": "Q9",
            "auxcode": "AFU",
            "companyName": "",
            "country": "Gambia"
        },
        {
            "name": "Afric'air Express",
            "code": "",
            "auxcode": "AAX",
            "companyName": "AFREX",
            "country": "Ivory Coast"
        },
        {
            "name": "Allegiant Air",
            "code": "G4",
            "auxcode": "AAY",
            "companyName": "ALLEGIANT",
            "country": "United States"
        },
        {
            "name": "Angus Aviation",
            "code": "",
            "auxcode": "AAZ",
            "companyName": "ANGUS",
            "country": "Canada"
        },
        {
            "name": "ArtemcodeAvia",
            "code": "",
            "auxcode": "ABA",
            "companyName": "ARTEMcodeAVIA",
            "country": "Ukraine"
        },
        {
            "name": "African Business and Transportations",
            "code": "",
            "auxcode": "ABB",
            "companyName": "AFRICAN BUSINESScode",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Aban Air",
            "code": "K5",
            "auxcode": "ABE",
            "companyName": "ABAN",
            "country": "Iran"
        },
        {
            "name": "Aerial Oy",
            "code": "",
            "auxcode": "ABF",
            "companyName": "SKYWINGS",
            "country": "Finland"
        },
        {
            "name": "AbakancodeAvia",
            "code": "",
            "auxcode": "ABG",
            "companyName": "ABAKANcodeAVIA",
            "country": "Russia"
        },
        {
            "name": "ABSA code Aerolinhas Brasileiras",
            "code": "M3",
            "auxcode": "TUS",
            "companyName": "ABSA Cargo",
            "country": "Brazil"
        },
        {
            "name": "Abaet",
            "code": "",
            "auxcode": "ABJ",
            "companyName": "Abaet",
            "country": "Brazil"
        },
        {
            "name": "Alberta Citylink",
            "code": "",
            "auxcode": "ABK",
            "companyName": "ALBERTA CITYLINK",
            "country": "Canada"
        },
        {
            "name": "APSA Colombia",
            "code": "",
            "auxcode": "ABO",
            "companyName": "AEROEXPRESO",
            "country": "Colombia"
        },
        {
            "name": "Aerovias Bueno",
            "code": "",
            "auxcode": "ABU",
            "companyName": "AEROBUENO",
            "country": "Colombia"
        },
        {
            "name": "Aerocenter",
            "code": "",
            "auxcode": "ACR",
            "companyName": " Escuela de Formacion de Pioltos Privados de Avion",
            "country": "AEROCENTER"
        },
        {
            "name": "Antrak Air",
            "code": "4",
            "auxcode": "ABV",
            "companyName": "ANTRAK",
            "country": "Ghana"
        },
        {
            "name": "Airborne Express",
            "code": "GB",
            "auxcode": "ABX",
            "companyName": "ABEX",
            "country": "United States"
        },
        {
            "name": "ABX Air",
            "code": "GB",
            "auxcode": "ABX",
            "companyName": "ABEX",
            "country": "United States"
        },
        {
            "name": "ATA Brasil",
            "code": "",
            "auxcode": "ABZ",
            "companyName": "ATAcodeBRAZIL",
            "country": "Brazil"
        },
        {
            "name": "Avcard Services",
            "code": "",
            "auxcode": "ACC",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Academy Airlines",
            "code": "",
            "auxcode": "ACD",
            "companyName": "ACADEMY",
            "country": "United States"
        },
        {
            "name": "Aero Comondu",
            "code": "",
            "auxcode": "ACO",
            "companyName": "AERO COMONDU",
            "country": "Mexico"
        },
        {
            "name": "Astral Aviation",
            "code": "8V",
            "auxcode": "ACP",
            "companyName": "ASTRAL CARGO",
            "country": "Kenya"
        },
        {
            "name": "Air Cess",
            "code": "",
            "auxcode": "ACS",
            "companyName": "",
            "country": "Liberia"
        },
        {
            "name": "Air Aurora",
            "code": "",
            "auxcode": "AAI",
            "companyName": "BOREALIS",
            "country": "United States"
        },
        {
            "name": "Air Cargo Transportation System",
            "code": "",
            "auxcode": "ACU",
            "companyName": "AFRISPIRIT",
            "country": "Kenya"
        },
        {
            "name": "Air Charter Service",
            "code": "",
            "auxcode": "ACV",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Aero Asia International",
            "code": "E4",
            "auxcode": "RSO",
            "companyName": "AERO ASIA",
            "country": "Pakistan"
        },
        {
            "name": "Air Charters",
            "code": "",
            "auxcode": "ACX",
            "companyName": "PARAIR",
            "country": "Canada"
        },
        {
            "name": "Air Togo",
            "code": "YT",
            "auxcode": "TGA",
            "companyName": "AIR TOGO",
            "country": "Togo"
        },
        {
            "name": "Air Somalia",
            "code": "",
            "auxcode": "RSM",
            "companyName": "AIR SOMALIA",
            "country": "Somali Republic"
        },
        {
            "name": "Atlas Cargo Lines",
            "code": "",
            "auxcode": "ACY",
            "companyName": "ATLAS CARGOLINES",
            "country": "Morocco"
        },
        {
            "name": "Airservices Australia",
            "code": "",
            "auxcode": "ADA",
            "companyName": "AUSCAL",
            "country": "Australia"
        },
        {
            "name": "Advance Leasing Company",
            "code": "4G",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Aztec Worldwide Airlines",
            "code": "7A",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Air Tindi",
            "code": "8T",
            "auxcode": "",
            "companyName": "",
            "country": "Canadian Territories"
        },
        {
            "name": "Antonov Airlines",
            "code": "",
            "auxcode": "ADB",
            "companyName": "ANTONOV BUREAU",
            "country": "Ukraine"
        },
        {
            "name": "Air Atlantic Dominicana",
            "code": "",
            "auxcode": "ADC",
            "companyName": "ATLANcodeDOMINICAN",
            "country": "Dominican Republic"
        },
        {
            "name": "Advanced Air Co.",
            "code": "",
            "auxcode": "ADD",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Ada Air",
            "code": "ZY",
            "auxcode": "ADE",
            "companyName": "ADA AIR",
            "country": "Albania"
        },
        {
            "name": "Aerea Flying Training Organization",
            "code": "",
            "auxcode": "ADG",
            "companyName": "AEREA TRAINING",
            "country": "Spain"
        },
        {
            "name": "Audeli Air",
            "code": "",
            "auxcode": "ADI",
            "companyName": "AUDELI",
            "country": "Spain"
        },
        {
            "name": "Abicar",
            "code": "",
            "auxcode": "ADJ",
            "companyName": "ABICAR",
            "country": "Ivory Coast"
        },
        {
            "name": "ADC Airlines",
            "code": "Z7",
            "auxcode": "ADK",
            "companyName": "ADCO",
            "country": "Nigeria"
        },
        {
            "name": "Aero Dynamics",
            "code": "",
            "auxcode": "ADL",
            "companyName": "COTSWOLD",
            "country": "United Kingdom"
        },
        {
            "name": "Aerolineas Dominicanas",
            "code": "",
            "auxcode": "ADM",
            "companyName": "DOMINAIR",
            "country": "Dominican Republic"
        },
        {
            "name": "Aerodienst GmbH",
            "code": "",
            "auxcode": "ADN",
            "companyName": "AERODIENST",
            "country": "Germany"
        },
        {
            "name": "Aerodiplomatic",
            "code": "",
            "auxcode": "ADP",
            "companyName": "AERODIPLOMATIC",
            "country": "Mexico"
        },
        {
            "name": "Aerodyne",
            "code": "",
            "auxcode": "ADY",
            "companyName": "AERODYNE",
            "country": "United States"
        },
        {
            "name": "Avion Taxi",
            "code": "",
            "auxcode": "ADQ",
            "companyName": "AIR DATA",
            "country": "Canada"
        },
        {
            "name": "Adria Airways",
            "code": "JP",
            "auxcode": "ADR",
            "companyName": "ADRIA",
            "country": "Slovenia"
        },
        {
            "name": "Aviones de Sonora",
            "code": "",
            "auxcode": "ADS",
            "companyName": "SONORAV",
            "country": "Mexico"
        },
        {
            "name": "Air Dorval",
            "code": "",
            "auxcode": "ADT",
            "companyName": "AIR DORVAL",
            "country": "Canada"
        },
        {
            "name": "Airdeal Oy",
            "code": "",
            "auxcode": "ADU",
            "companyName": "AIRDEAL",
            "country": "Finland"
        },
        {
            "name": "Advance Air Charters",
            "code": "",
            "auxcode": "ADV",
            "companyName": "ADVANCE",
            "country": "Canada"
        },
        {
            "name": "Air Andaman",
            "code": "",
            "auxcode": "ADW",
            "companyName": "AIR ANDAMAN",
            "country": "Thailand"
        },
        {
            "name": "Anderson Aviation",
            "code": "",
            "auxcode": "ADX",
            "companyName": "ANDAX",
            "country": "United States"
        },
        {
            "name": "Air Europa",
            "code": "UX",
            "auxcode": "AEA",
            "companyName": "EUROPA",
            "country": "Spain"
        },
        {
            "name": "Air Southwest Ltd.",
            "code": "",
            "auxcode": "ASW",
            "companyName": "AIRSOUTHWEST",
            "country": "Canada"
        },
        {
            "name": "Air Special",
            "code": "",
            "auxcode": "ASX",
            "companyName": "AIRSPEC",
            "country": "Czech Republic"
        },
        {
            "name": "Aero Benin",
            "code": "EM",
            "auxcode": "AEB",
            "companyName": "AEROBEN",
            "country": "Benin"
        },
        {
            "name": "Aerocesar",
            "code": "",
            "auxcode": "AEC",
            "companyName": " Aerovias Del Cesar",
            "country": "AEROCESAR"
        },
        {
            "name": "Aerotrans Airlines",
            "code": "",
            "auxcode": "AED",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Aegean Airlines",
            "code": "A3",
            "auxcode": "AEE",
            "companyName": "AEGEAN",
            "country": "Greece"
        },
        {
            "name": "Aerofumigaciones Sam",
            "code": "",
            "auxcode": "AEG",
            "companyName": "FUMIGACIONES SAM",
            "country": "Chile"
        },
        {
            "name": "Aeroexpreso Interamericano",
            "code": "",
            "auxcode": "AEI",
            "companyName": "INTERAM",
            "country": "Colombia"
        },
        {
            "name": "Air Express",
            "code": "",
            "auxcode": "AEJ",
            "companyName": "KHAKI EXPRESS",
            "country": "Tanzania"
        },
        {
            "name": "Aerocon",
            "code": "",
            "auxcode": "AEK",
            "companyName": "AEROCON",
            "country": "Bolivia"
        },
        {
            "name": "Aero Madrid",
            "code": "",
            "auxcode": "AEM",
            "companyName": "AEROMADRID",
            "country": "Spain"
        },
        {
            "name": "Aeroenlaces Nacionales",
            "code": "",
            "auxcode": "AEN",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "Aeroservicios Ejecutivos Del Occidente",
            "code": "",
            "auxcode": "AEO",
            "companyName": "AERO OCCIDENTE",
            "country": "Mexico"
        },
        {
            "name": "Aerotec",
            "code": "",
            "auxcode": "AEP",
            "companyName": "AEROTEC",
            "country": "Spain"
        },
        {
            "name": "Air Atlantique",
            "code": "KI",
            "auxcode": "AAG",
            "companyName": "ATLANTIC",
            "country": "United Kingdom"
        },
        {
            "name": "Air Europe",
            "code": "PE",
            "auxcode": "AEL",
            "companyName": "AIR EUROPE",
            "country": "Italy"
        },
        {
            "name": "Air Alma",
            "code": "",
            "auxcode": "AAJ",
            "companyName": "AIR ALMA",
            "country": "Canada"
        },
        {
            "name": "Air Express",
            "code": "",
            "auxcode": "AEQ",
            "companyName": "LUNA",
            "country": "Sweden"
        },
        {
            "name": "Alaska Central Express",
            "code": "KO",
            "auxcode": "AER",
            "companyName": "ACE AIR",
            "country": "United States"
        },
        {
            "name": "ACES Colombia",
            "code": "",
            "auxcode": "AES",
            "companyName": "ACES",
            "country": "Colombia"
        },
        {
            "name": "Aeronautical Radio of Thailand",
            "code": "",
            "auxcode": "AET",
            "companyName": "AEROTHAI",
            "country": "Thailand"
        },
        {
            "name": "Astraeus",
            "code": "5W",
            "auxcode": "AEU",
            "companyName": "FLYSTAR",
            "country": "United Kingdom"
        },
        {
            "name": "Aeroventas",
            "code": "",
            "auxcode": "AEV",
            "companyName": "AEROVENTAS",
            "country": "Mexico"
        },
        {
            "name": "Aerosvit Airlines",
            "code": "VV",
            "auxcode": "AEW",
            "companyName": "AEROSVIT",
            "country": "Ukraine"
        },
        {
            "name": "Airway Express",
            "code": "",
            "auxcode": "AEX",
            "companyName": "AVCO",
            "country": "United States"
        },
        {
            "name": "Air Italy",
            "code": "I9",
            "auxcode": "AEY",
            "companyName": "AIR ITALY",
            "country": "Italy"
        },
        {
            "name": "Aerial Transit Company",
            "code": "",
            "auxcode": "AEZ",
            "companyName": "AERIAL TRANZ",
            "country": "United States"
        },
        {
            "name": "Alfa Air",
            "code": "",
            "auxcode": "AFA",
            "companyName": "BLUE ALFA",
            "country": "Czech Republic"
        },
        {
            "name": "American Falcon",
            "code": "WK",
            "auxcode": "AFB",
            "companyName": "AMERICAN FALCON",
            "country": "Argentina"
        },
        {
            "name": "Alliance Airlines",
            "code": "QQ",
            "auxcode": "UTY",
            "companyName": "UNITY",
            "country": "Australia"
        },
        {
            "name": "Air Universal",
            "code": "",
            "auxcode": "UVS",
            "companyName": "UNIcodeLEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Auvia Air",
            "code": "",
            "auxcode": "UVT",
            "companyName": "AUVIA",
            "country": "Indonesia"
        },
        {
            "name": "African West Air",
            "code": "",
            "auxcode": "AFC",
            "companyName": "AFRICAN WEST",
            "country": "Senegal"
        },
        {
            "name": "Airfast Indonesia",
            "code": "",
            "auxcode": "AFE",
            "companyName": "AIRFAST",
            "country": "Indonesia"
        },
        {
            "name": "Ariana Afghan Airlines",
            "code": "FG",
            "auxcode": "AFG",
            "companyName": "ARIANA",
            "country": "Afghanistan"
        },
        {
            "name": "Air Fecteau",
            "code": "",
            "auxcode": "AFH",
            "companyName": "FECTO",
            "country": "Canada"
        },
        {
            "name": "Africaone",
            "code": "",
            "auxcode": "AFI",
            "companyName": "AFRICAWORLD",
            "country": "Uganda"
        },
        {
            "name": "Alliance",
            "code": "",
            "auxcode": "AFJ",
            "companyName": "JAMBO",
            "country": "Uganda"
        },
        {
            "name": "Africa Air Links",
            "code": "",
            "auxcode": "AFK",
            "companyName": "AFRICA LINKS",
            "country": "Sierra Leone"
        },
        {
            "name": "Aeroflot Russian Airlines",
            "code": "SU",
            "auxcode": "AFL",
            "companyName": "AEROFLOT",
            "country": "Russia"
        },
        {
            "name": "Aero Empresa Mexicana",
            "code": "",
            "auxcode": "AFO",
            "companyName": "AERO EMPRESA",
            "country": "Mexico"
        },
        {
            "name": "Air Bosna",
            "code": "JA",
            "auxcode": "BON",
            "companyName": "AIR BOSNA",
            "country": "Bosnia and Herzegovina"
        },
        {
            "name": "Air Bravo",
            "code": "",
            "auxcode": "BRF",
            "companyName": "AIR BRAVO",
            "country": "Uganda"
        },
        {
            "name": "Air Brasd'or",
            "code": "",
            "auxcode": "BRL",
            "companyName": "BRASD'OR",
            "country": "Canada"
        },
        {
            "name": "Air 500",
            "code": "",
            "auxcode": "BRM",
            "companyName": "BOOMERANG",
            "country": "Canada"
        },
        {
            "name": "Alba Servizi Aerotrasporti",
            "code": "",
            "auxcode": "AFQ",
            "companyName": "ALBA",
            "country": "Italy"
        },
        {
            "name": "Air France",
            "code": "AF",
            "auxcode": "AFR",
            "companyName": "AIRFRANS",
            "country": "France"
        },
        {
            "name": "Air Partner",
            "code": "",
            "auxcode": "ACG",
            "companyName": "AIR PARTNER",
            "country": "United Kingdom"
        },
        {
            "name": "Air Caledonie International",
            "code": "SB",
            "auxcode": "ACI",
            "companyName": "AIRCALIN",
            "country": "France"
        },
        {
            "name": "Air Caledonia",
            "code": "",
            "auxcode": "ACM",
            "companyName": "WEST CAL",
            "country": "Canada"
        },
        {
            "name": "Air Guam",
            "code": "",
            "auxcode": "AGM",
            "companyName": "AIR GUAM",
            "country": "United States"
        },
        {
            "name": "Air Gabon",
            "code": "GN",
            "auxcode": "AGN",
            "companyName": "GOLF NOVEMBER",
            "country": "Gabon"
        },
        {
            "name": "Air Data",
            "code": "",
            "auxcode": "AFS",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Air Afrique Vacancies",
            "code": "",
            "auxcode": "AFV",
            "companyName": "AFRIQUE VACANCE",
            "country": "Ivory Coast"
        },
        {
            "name": "Air Cargo America",
            "code": "",
            "auxcode": "MVM",
            "companyName": "PEGASUS",
            "country": "United States"
        },
        {
            "name": "Air Salone",
            "code": "2O",
            "auxcode": "",
            "companyName": "",
            "country": "Sierra Leone"
        },
        {
            "name": "AircodeAngol",
            "code": "",
            "auxcode": "NGO",
            "companyName": "AIR ANGOL",
            "country": "Angola"
        },
        {
            "name": "Air Nigeria",
            "code": "",
            "auxcode": "NGP",
            "companyName": "REGAL EAGLE",
            "country": "Nigeria"
        },
        {
            "name": "Air Cargo Carriers",
            "code": "2Q",
            "auxcode": "SNC",
            "companyName": "NIGHT CARGO",
            "country": "United States"
        },
        {
            "name": "Air Samarkand",
            "code": "",
            "auxcode": "SND",
            "companyName": "ARSAM",
            "country": "Uzbekistan"
        },
        {
            "name": "Air Senegal International",
            "code": "V7",
            "auxcode": "SNG",
            "companyName": "AIR SENEGAL",
            "country": "Senegal"
        },
        {
            "name": "Air Sandy",
            "code": "",
            "auxcode": "SNY",
            "companyName": "AIR SANDY",
            "country": "Canada"
        },
        {
            "name": "Air Namibia",
            "code": "SW",
            "auxcode": "NMB",
            "companyName": "NAMIBIA",
            "country": "Namibia"
        },
        {
            "name": "Air Intersalonika",
            "code": "",
            "auxcode": "NSK",
            "companyName": "INTERSALONIKA",
            "country": "Greece"
        },
        {
            "name": "Air Anatolia",
            "code": "",
            "auxcode": "NTL",
            "companyName": "AIR ANATOLIA",
            "country": "Turkey"
        },
        {
            "name": "Air Saigon",
            "code": "",
            "auxcode": "SGA",
            "companyName": "AIR SAIGON",
            "country": "Vietnam"
        },
        {
            "name": "Afrique Regional Airways",
            "code": "",
            "auxcode": "AFW",
            "companyName": "AFRAIR",
            "country": "Ivory Coast"
        },
        {
            "name": "Airfreight Express",
            "code": "",
            "auxcode": "AFX",
            "companyName": " ",
            "country": "United Kingdom"
        },
        {
            "name": "Africa Chartered Services",
            "code": "",
            "auxcode": "AFY",
            "companyName": "AFRICA CHARTERED",
            "country": "Nigeria"
        },
        {
            "name": "Africa Freight Services",
            "code": "",
            "auxcode": "AFZ",
            "companyName": "AFREIGHT",
            "country": "Zambia"
        },
        {
            "name": "Aeronaves Del Centro",
            "code": "",
            "auxcode": "AGA",
            "companyName": "",
            "country": "Venezuela"
        },
        {
            "name": "Air Service Gabon",
            "code": "G8",
            "auxcode": "AGB",
            "companyName": "",
            "country": "Gabon"
        },
        {
            "name": "Arab Agricultural Aviation Company",
            "code": "",
            "auxcode": "AGC",
            "companyName": "AGRICO",
            "country": "Egypt"
        },
        {
            "name": "Atlantic Gulf Airlines",
            "code": "",
            "auxcode": "AGF",
            "companyName": "ATLANTIC GULF",
            "country": "United States"
        },
        {
            "name": "Aerolitoral",
            "code": "5D",
            "auxcode": "SLI",
            "companyName": "COSTERA",
            "country": "Mexico"
        },
        {
            "name": "Algoma Airways",
            "code": "",
            "auxcode": "AGG",
            "companyName": "ALGOMA",
            "country": "Canada"
        },
        {
            "name": "Altagna",
            "code": "",
            "auxcode": "AGH",
            "companyName": "ALTAGNA",
            "country": "France"
        },
        {
            "name": "Angola Air Charter",
            "code": "",
            "auxcode": "AGO",
            "companyName": "ANGOLA CHARTER",
            "country": "Angola"
        },
        {
            "name": "Angola Air Charter",
            "code": "",
            "auxcode": "AGO",
            "companyName": "ANGOLA CHARTER",
            "country": "Angola"
        },
        {
            "name": "AERFI Group",
            "code": "",
            "auxcode": "AGP",
            "companyName": "AIR TARA",
            "country": "Ireland"
        },
        {
            "name": "Aerogala",
            "code": "",
            "auxcode": "AGQ",
            "companyName": "GALASERVICE",
            "country": "Chile"
        },
        {
            "name": "Amadeus Global Travel Distribution",
            "code": "1A",
            "auxcode": "AGT",
            "companyName": "AMADEUS",
            "country": "Spain"
        },
        {
            "name": "Angara Airlines",
            "code": "",
            "auxcode": "AGU",
            "companyName": "SARMA",
            "country": "Russia"
        },
        {
            "name": "Air Glaciers",
            "code": "7T",
            "auxcode": "AGV",
            "companyName": "AIR GLACIERS",
            "country": "Switzerland"
        },
        {
            "name": "Aero Gambia",
            "code": "",
            "auxcode": "AGW",
            "companyName": "AERO GAMBIA",
            "country": "Gambia"
        },
        {
            "name": "Aviogenex",
            "code": "",
            "auxcode": "AGX",
            "companyName": "GENEX",
            "country": "Serbia"
        },
        {
            "name": "Atlantic Coast Airlines",
            "code": "",
            "auxcode": "BLR",
            "companyName": "BLUE RIDGE",
            "country": "United States"
        },
        {
            "name": "Aero Barloz",
            "code": "",
            "auxcode": "BLZ",
            "companyName": "AEROLOZ",
            "country": "Mexico"
        },
        {
            "name": "Aeroper",
            "code": "PL",
            "auxcode": "PLI",
            "companyName": "Aeroperu",
            "country": "Peru"
        },
        {
            "name": "Atlas Blue",
            "code": "8A",
            "auxcode": "BMM",
            "companyName": "ATLAS BLUE",
            "country": "Morocco"
        },
        {
            "name": "Aero Banobras",
            "code": "",
            "auxcode": "BNB",
            "companyName": "AEROBANOBRAS",
            "country": "Mexico"
        },
        {
            "name": "Aero Flight Service",
            "code": "",
            "auxcode": "AGY",
            "companyName": "FLIGHT GROUP",
            "country": "United States"
        },
        {
            "name": "AgroletcodeMci",
            "code": "",
            "auxcode": "AGZ",
            "companyName": "AGROLET",
            "country": "Slovakia"
        },
        {
            "name": "Air Alpha Greenland",
            "code": "GD",
            "auxcode": "AHA",
            "companyName": "AIR ALPHA",
            "country": "Denmark"
        },
        {
            "name": "Azal Avia Cargo",
            "code": "",
            "auxcode": "AHC",
            "companyName": "AZALAVIACARGO",
            "country": "Azerbaijan"
        },
        {
            "name": "Airport Helicopter Basel",
            "code": "",
            "auxcode": "AHE",
            "companyName": " Muller & Co.",
            "country": "AIRPORT HELICOPTER"
        },
        {
            "name": "Aeroservices Corporate",
            "code": "",
            "auxcode": "CJE",
            "companyName": "BIRD JET",
            "country": "France"
        },
        {
            "name": "Aspen Helicopters",
            "code": "",
            "auxcode": "AHF",
            "companyName": "ASPEN",
            "country": "United States"
        },
        {
            "name": "Aerochago Airlines",
            "code": "",
            "auxcode": "AHG",
            "companyName": "AEROCHAGO",
            "country": "Dominican Republic"
        },
        {
            "name": "Airplanes Holdings",
            "code": "",
            "auxcode": "AHH",
            "companyName": "AIRHOLD",
            "country": "Ireland"
        },
        {
            "name": "Air Hong Kong",
            "code": "LD",
            "auxcode": "AHK",
            "companyName": "AIR HONG KONG",
            "country": "Hong Kong"
        },
        {
            "name": "Aerochiapas",
            "code": "",
            "auxcode": "AHP",
            "companyName": "AEROCHIAPAS",
            "country": "Mexico"
        },
        {
            "name": "Air Adriatic",
            "code": "",
            "auxcode": "AHR",
            "companyName": "ADRIATIC",
            "country": "Croatia"
        },
        {
            "name": "Air Viggi San Raffaele",
            "code": "",
            "auxcode": "AHS",
            "companyName": "AIRSAR",
            "country": "Italy"
        },
        {
            "name": "ABC Air Hungary",
            "code": "",
            "auxcode": "AHU",
            "companyName": "ABC HUNGARY",
            "country": "Hungary"
        },
        {
            "name": "AeromistcodeKharkiv",
            "code": "HT",
            "auxcode": "AHW",
            "companyName": "AEROMIST",
            "country": "Ukraine"
        },
        {
            "name": "Azerbaijan Airlines",
            "code": "J2",
            "auxcode": "AHY",
            "companyName": "AZAL",
            "country": "Azerbaijan"
        },
        {
            "name": "Avies",
            "code": "U3",
            "auxcode": "AIA",
            "companyName": "AVIES",
            "country": "Estonia"
        },
        {
            "name": "Airbus Industrie",
            "code": "AP",
            "auxcode": "AIB",
            "companyName": "AIRBUS INDUSTRIE",
            "country": "France"
        },
        {
            "name": "Alpine Air Chile",
            "code": "",
            "auxcode": "AIH",
            "companyName": "ALPINE CHILE",
            "country": "Chile"
        },
        {
            "name": "Air Integra",
            "code": "",
            "auxcode": "AII",
            "companyName": "INTEGRA",
            "country": "Canada"
        },
        {
            "name": "ABC Aerolineas",
            "code": "",
            "auxcode": "AIJ",
            "companyName": "ABC AEROLINEAS",
            "country": "Mexico"
        },
        {
            "name": "African Airlines International Limited",
            "code": "",
            "auxcode": "AIK",
            "companyName": "AFRICAN AIRLINES",
            "country": "Kenya"
        },
        {
            "name": "African International Airways",
            "code": "",
            "auxcode": "AIN",
            "companyName": "FLY CARGO",
            "country": "Swaziland"
        },
        {
            "name": "Alpine Air Express",
            "code": "5A",
            "auxcode": "AIP",
            "companyName": "ALPINE AIR",
            "country": "United States"
        },
        {
            "name": "Alicante Internacional Airlines",
            "code": "",
            "auxcode": "AIU",
            "companyName": "ALIA",
            "country": "Spain"
        },
        {
            "name": "Aba Air",
            "code": "",
            "auxcode": "ABP",
            "companyName": "BAIR",
            "country": "Czech Republic"
        },
        {
            "name": "Airblue",
            "code": "ED",
            "auxcode": "ABQ",
            "companyName": "PAKBLUE",
            "country": "Pakistan"
        },
        {
            "name": "Airmark Aviation",
            "code": "",
            "auxcode": "THM",
            "companyName": "THAI AIRMARK",
            "country": "Thailand"
        },
        {
            "name": "Airlift International",
            "code": "",
            "auxcode": "AIR",
            "companyName": "AIRLIFT",
            "country": "United States"
        },
        {
            "name": "Airest",
            "code": "",
            "auxcode": "AIT",
            "companyName": " ",
            "country": "Estonia"
        },
        {
            "name": "Air Baffin",
            "code": "",
            "auxcode": "BFF",
            "companyName": "AIR BAFFIN",
            "country": "Canada"
        },
        {
            "name": "Air Bandama",
            "code": "",
            "auxcode": "BDM",
            "companyName": "BANDAMA",
            "country": "Ivory Coast"
        },
        {
            "name": "Air Berlin",
            "code": "AB",
            "auxcode": "BER",
            "companyName": "AIR BERLIN",
            "country": "Germany"
        },
        {
            "name": "Air Brousse",
            "code": "",
            "auxcode": "ABT",
            "companyName": "AIR BROUSSE",
            "country": "Canada"
        },
        {
            "name": "Air Contractors",
            "code": "AG",
            "auxcode": "ABR",
            "companyName": "CONTRACT",
            "country": "Ireland"
        },
        {
            "name": "Air Illinois",
            "code": "",
            "auxcode": "AIL",
            "companyName": "AIR ILLINOIS",
            "country": "United States"
        },
        {
            "name": "Air India Limited",
            "code": "AI",
            "auxcode": "AIC",
            "companyName": "AIRINDIA",
            "country": "India"
        },
        {
            "name": "Air Inter Gabon",
            "code": "",
            "auxcode": "AIG",
            "companyName": " ",
            "country": "Gabon"
        },
        {
            "name": "Air Bourbon",
            "code": "ZB",
            "auxcode": "BUB",
            "companyName": "BOURBON",
            "country": "Reunion"
        },
        {
            "name": "Air Atlanta Icelandic",
            "code": "CC",
            "auxcode": "ABD",
            "companyName": "ATLANTA",
            "country": "Iceland"
        },
        {
            "name": "Air Inuit",
            "code": "",
            "auxcode": "AIE",
            "companyName": "AIR INUIT",
            "country": "Canada"
        },
        {
            "name": "Air Sureste",
            "code": "",
            "auxcode": "AIS",
            "companyName": "SURESTE",
            "country": "Spain"
        },
        {
            "name": "Air Srpska",
            "code": "RB",
            "auxcode": "SBK",
            "companyName": "Air Srpska",
            "country": "Bosnia and Herzegovina"
        },
        {
            "name": "Air Tahiti Nui",
            "code": "TN",
            "auxcode": "THT",
            "companyName": "TAHITI AIRLINES",
            "country": "France"
        },
        {
            "name": "Airvias S/A Linhas Aereas",
            "code": "",
            "auxcode": "AIV",
            "companyName": "AIRVIAS",
            "country": "Brazil"
        },
        {
            "name": "Aero Services Executive",
            "code": "W4",
            "auxcode": "BES",
            "companyName": "BIRD EXPRESS",
            "country": "France"
        },
        {
            "name": "Atlantic Island Airways",
            "code": "",
            "auxcode": "AIW",
            "companyName": "TARTAN",
            "country": "Canada"
        },
        {
            "name": "Aircruising Australia",
            "code": "",
            "auxcode": "AIX",
            "companyName": "CRUISER",
            "country": "Australia"
        },
        {
            "name": "Aircrew Check and Training Australia",
            "code": "",
            "auxcode": "AIY",
            "companyName": "AIRCREW",
            "country": "Australia"
        },
        {
            "name": "Arkia Israel Airlines",
            "code": "IZ",
            "auxcode": "AIZ",
            "companyName": "ARKIA",
            "country": "Israel"
        },
        {
            "name": "A J Services",
            "code": "",
            "auxcode": "AJA",
            "companyName": "AYJAY SERVICES",
            "country": "United Kingdom"
        },
        {
            "name": "Aero JBR",
            "code": "",
            "auxcode": "AJB",
            "companyName": "AERO JBR",
            "country": "Mexico"
        },
        {
            "name": "Aero Jet Express",
            "code": "",
            "auxcode": "AJE",
            "companyName": "JET EXPRESS",
            "country": "Mexico"
        },
        {
            "name": "Avia Consult Flugbetriebs",
            "code": "",
            "auxcode": "AJF",
            "companyName": "AVIACONSULT",
            "country": "Austria"
        },
        {
            "name": "Ameristar Jet Charter",
            "code": "",
            "auxcode": "AJI",
            "companyName": "AMERISTAR",
            "country": "United States"
        },
        {
            "name": "A2 Jet Leasing",
            "code": "",
            "auxcode": "AJJ",
            "companyName": "ATLANTIC JET",
            "country": "United States"
        },
        {
            "name": "Allied Air",
            "code": "",
            "auxcode": "AJK",
            "companyName": "BAMBI",
            "country": "Nigeria"
        },
        {
            "name": "Air Jamaica",
            "code": "JM",
            "auxcode": "AJM",
            "companyName": "JAMAICA",
            "country": "Jamaica"
        },
        {
            "name": "Air One",
            "code": "AP",
            "auxcode": "ADH",
            "companyName": "HERON",
            "country": "Italy"
        },
        {
            "name": "Air Sahara",
            "code": "S2",
            "auxcode": "RSH",
            "companyName": "SAHARA",
            "country": "India"
        },
        {
            "name": "Air Malta",
            "code": "KM",
            "auxcode": "AMC",
            "companyName": "AIR MALTA",
            "country": "Malta"
        },
        {
            "name": "Aeroejecutivo",
            "code": "",
            "auxcode": "AJO",
            "companyName": "AEROEXO",
            "country": "Mexico"
        },
        {
            "name": "Aero Jets Corporativos",
            "code": "",
            "auxcode": "AJP",
            "companyName": "AEROJETS",
            "country": "Mexico"
        },
        {
            "name": "Aeroejecutivos Colombia",
            "code": "",
            "auxcode": "AJS",
            "companyName": "AEROEJECUTIVOS",
            "country": "Colombia"
        },
        {
            "name": "Amerijet International",
            "code": "M6",
            "auxcode": "AJT",
            "companyName": "AMERIJET",
            "country": "United States"
        },
        {
            "name": "Air Jetsul",
            "code": "",
            "auxcode": "AJU",
            "companyName": "AIRJETSUL",
            "country": "Portugal"
        },
        {
            "name": "ANA & JP Express",
            "code": "",
            "auxcode": "AJV",
            "companyName": "AYJAY CARGO",
            "country": "Japan"
        },
        {
            "name": "Alpha Jet International",
            "code": "",
            "auxcode": "AJW",
            "companyName": "ALPHAJET",
            "country": "United States"
        },
        {
            "name": "Air Japan",
            "code": "NQ",
            "auxcode": "AJX",
            "companyName": "AIR JAPAN",
            "country": "Japan"
        },
        {
            "name": "Ajet",
            "code": "",
            "auxcode": "AJY",
            "companyName": "AYJET",
            "country": "Cyprus"
        },
        {
            "name": "Air Korea Co. Ltd.",
            "code": "",
            "auxcode": "AKA",
            "companyName": "",
            "country": "Republic of Korea"
        },
        {
            "name": "Air Livonia",
            "code": "",
            "auxcode": "LIV",
            "companyName": "LIVONIA",
            "country": "Estonia"
        },
        {
            "name": "Air BC",
            "code": "",
            "auxcode": "ABL",
            "companyName": "AIRCOACH",
            "country": "Canada"
        },
        {
            "name": "Air Fret Senegal",
            "code": "",
            "auxcode": "ABN",
            "companyName": " ",
            "country": "Senegal"
        },
        {
            "name": "Air Jamahiriya Company",
            "code": "",
            "auxcode": "LJA",
            "companyName": "AIR JAMAHIRIYA",
            "country": "Libya"
        },
        {
            "name": "Aktjubavia",
            "code": "",
            "auxcode": "AKB",
            "companyName": "KARAB",
            "country": "Kazakhstan"
        },
        {
            "name": "Arca Aerovias Colombianas Ltda.",
            "code": "",
            "auxcode": "AKC",
            "companyName": "ARCA",
            "country": "Colombia"
        },
        {
            "name": "Anikay Air Company",
            "code": "",
            "auxcode": "AKF",
            "companyName": "ANIKAY",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Akhal",
            "code": "",
            "auxcode": "AKH",
            "companyName": "AKHAL",
            "country": "Turkmenistan"
        },
        {
            "name": "Aeromilenio",
            "code": "",
            "auxcode": "MNI",
            "companyName": "AEROMIL",
            "country": "Mexico"
        },
        {
            "name": "Aklak Air",
            "code": "",
            "auxcode": "AKK",
            "companyName": "AKLAK",
            "country": "Canada"
        },
        {
            "name": "Air Kiribati",
            "code": "4A",
            "auxcode": "AKL",
            "companyName": "",
            "country": "Kiribati"
        },
        {
            "name": "Alkan Air",
            "code": "",
            "auxcode": "AKN",
            "companyName": "ALKAN AIR",
            "country": "Canada"
        },
        {
            "name": "Angkor Airways",
            "code": "",
            "auxcode": "AKW",
            "companyName": "ANGKORWAYS",
            "country": "Cambodia"
        },
        {
            "name": "Air Nippon Network Co. Ltd.",
            "code": "EH",
            "auxcode": "AKX",
            "companyName": "ALFA WING",
            "country": "Japan"
        },
        {
            "name": "AK Navigator LLC",
            "code": "",
            "auxcode": "AKZ",
            "companyName": "ABSOLUTE",
            "country": "Kazakhstan"
        },
        {
            "name": "Aero Albatros",
            "code": "",
            "auxcode": "ALB",
            "companyName": "ALBATROS",
            "country": "Mexico"
        },
        {
            "name": "Albion Aviation",
            "code": "",
            "auxcode": "ALD",
            "companyName": "ALBION",
            "country": "United Kingdom"
        },
        {
            "name": "Aeroalas Colombia",
            "code": "",
            "auxcode": "ALE",
            "companyName": "AEROALAS",
            "country": "Colombia"
        },
        {
            "name": "Allied Command Europe (Mobile Force)",
            "code": "",
            "auxcode": "ALF",
            "companyName": "ACEFORCE",
            "country": "Belgium"
        },
        {
            "name": "Aerotaxis Albatros",
            "code": "",
            "auxcode": "BTS",
            "companyName": "AEROLINEAS ALBATROS",
            "country": "Mexico"
        },
        {
            "name": "American Flyers",
            "code": "",
            "auxcode": "FYS",
            "companyName": "AMERICAN FLYERS",
            "country": "United States"
        },
        {
            "name": "Aero Coach Aviation",
            "code": "",
            "auxcode": "DFA",
            "companyName": "AERO COACH",
            "country": "United States"
        },
        {
            "name": "Atlantic Southeast Airlines",
            "code": "",
            "auxcode": "CAA",
            "companyName": "Chandler",
            "country": "United States"
        },
        {
            "name": "ACM Air Charter",
            "code": "",
            "auxcode": "BVR",
            "companyName": "BAVARIAN",
            "country": "Germany"
        },
        {
            "name": "Air Logistics",
            "code": "",
            "auxcode": "ALG",
            "companyName": "AIRLOG",
            "country": "United States"
        },
        {
            "name": "Aerovallarta",
            "code": "",
            "auxcode": "ALL",
            "companyName": "VALLARTA",
            "country": "Mexico"
        },
        {
            "name": "Air ALM",
            "code": "",
            "auxcode": "ALM",
            "companyName": "ANTILLEAN",
            "country": "Netherlands Antilles"
        },
        {
            "name": "Air Lincoln",
            "code": "",
            "auxcode": "ALN",
            "companyName": "CHICAGO LINCOLN",
            "country": "United States"
        },
        {
            "name": "America West Airlines",
            "code": "HP",
            "auxcode": "AWE",
            "companyName": "CACTUS",
            "country": "United States"
        },
        {
            "name": "Air Wisconsin",
            "code": "ZW",
            "auxcode": "AWI",
            "companyName": "AIR WISCONSIN",
            "country": "United States"
        },
        {
            "name": "Aerotransporte de Carga Union",
            "code": "",
            "auxcode": "TNO",
            "companyName": "AEROUNION",
            "country": "Mexico"
        },
        {
            "name": "Aero Taxis Cessna",
            "code": "",
            "auxcode": "TND",
            "companyName": "TAXIS CESSNA",
            "country": "Mexico"
        },
        {
            "name": "Arizona Express Airlines",
            "code": "",
            "auxcode": "TMP",
            "companyName": "TEMPE",
            "country": "United States"
        },
        {
            "name": "Tatarstan Airlines",
            "code": "U9",
            "auxcode": "TAK",
            "companyName": "TATARSTAN",
            "country": "Russia"
        },
        {
            "name": "Allegheny Commuter Airlines",
            "code": "",
            "auxcode": "ALO",
            "companyName": "ALLEGHENY",
            "country": "United States"
        },
        {
            "name": "Alpliner AG",
            "code": "",
            "auxcode": "ALP",
            "companyName": "ALPINER",
            "country": "Switzerland"
        },
        {
            "name": "Altair Aviation (1986)",
            "code": "",
            "auxcode": "ALQ",
            "companyName": "ALTAIR",
            "country": "Canada"
        },
        {
            "name": "Air Luxor STP",
            "code": "",
            "auxcode": "ALU",
            "companyName": "LUXORJET",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "Alas de Venezuela",
            "code": "",
            "auxcode": "ALV",
            "companyName": "ALVEN",
            "country": "Venezuela"
        },
        {
            "name": "Alas Nacionales",
            "code": "",
            "auxcode": "ALW",
            "companyName": " S.A.",
            "country": "ALNACIONAL"
        },
        {
            "name": "Alyeska Air Service",
            "code": "",
            "auxcode": "ALY",
            "companyName": "ALYESKA",
            "country": "United States"
        },
        {
            "name": "Alta Flights (Charters) Ltd.",
            "code": "",
            "auxcode": "ALZ",
            "companyName": " ",
            "country": "Canada"
        },
        {
            "name": "Air Sunshine",
            "code": "",
            "auxcode": "RSI",
            "companyName": "AIR SUNSHINE",
            "country": "United States"
        },
        {
            "name": "ATMA",
            "code": "",
            "auxcode": "AMA",
            "companyName": "ADIK",
            "country": "Kazakhstan"
        },
        {
            "name": "Aerolineas Medellin",
            "code": "",
            "auxcode": "AMD",
            "companyName": "AEROLINEAS MEDELLIN",
            "country": "Colombia"
        },
        {
            "name": "Ameriflight",
            "code": "",
            "auxcode": "AMF",
            "companyName": "AMFLIGHT",
            "country": "United States"
        },
        {
            "name": "Air Libert",
            "code": "VD",
            "auxcode": "",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Air Lithuania",
            "code": "TT",
            "auxcode": "KLA",
            "companyName": "KAUNAS",
            "country": "Lithuania"
        },
        {
            "name": "Air Minas Linhas A",
            "code": "",
            "auxcode": "AMG",
            "companyName": "AIR MINAS",
            "country": "Brazil"
        },
        {
            "name": "Alan Mann Helicopters Ltd.",
            "code": "",
            "auxcode": "AMH",
            "companyName": "MANN",
            "country": "United Kingdom"
        },
        {
            "name": "Air Maldives",
            "code": "",
            "auxcode": "AMI",
            "companyName": "AIR MALDIVES",
            "country": "Maldives"
        },
        {
            "name": "Aviation Amos",
            "code": "",
            "auxcode": "AMJ",
            "companyName": "AVIATION AMOS",
            "country": "Canada"
        },
        {
            "name": "Amerer Air",
            "code": "",
            "auxcode": "AMK",
            "companyName": "AMER AIR",
            "country": "Austria"
        },
        {
            "name": "Air Malawi",
            "code": "QM",
            "auxcode": "AML",
            "companyName": "MALAWI",
            "country": "Malawi"
        },
        {
            "name": "Aeroputul International Marculesti",
            "code": "",
            "auxcode": "AMM",
            "companyName": "AEROM",
            "country": "Moldova"
        },
        {
            "name": "Air Montenegro",
            "code": "",
            "auxcode": "AMN",
            "companyName": "MONTENEGRO",
            "country": "Montenegro"
        },
        {
            "name": "Air Montreal (Air Holdings Inc.)",
            "code": "",
            "auxcode": "AMO",
            "companyName": "AIR MONTREAL",
            "country": "Canada"
        },
        {
            "name": "Aero Transporte S.A. (ATSA)",
            "code": "",
            "auxcode": "AMP",
            "companyName": "ATSA",
            "country": "Peru"
        },
        {
            "name": "Aeromedicare Ltd.",
            "code": "",
            "auxcode": "AMQ",
            "companyName": "LIFELINE",
            "country": "United Kingdom"
        },
        {
            "name": "Air Sicilia",
            "code": "BM",
            "auxcode": "",
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Air Specialties Corporation",
            "code": "",
            "auxcode": "AMR",
            "companyName": "AIR AM",
            "country": "United States"
        },
        {
            "name": "Air Muskoka",
            "code": "",
            "auxcode": "AMS",
            "companyName": "AIR MUSKOKA",
            "country": "Canada"
        },
        {
            "name": "ATA Airlines",
            "code": "",
            "auxcode": "AMT",
            "companyName": "AMTRAN",
            "country": "United States"
        },
        {
            "name": "Air Macau",
            "code": "NX",
            "auxcode": "AMU",
            "companyName": "AIR MACAO",
            "country": "Macao"
        },
        {
            "name": "AMC Airlines",
            "code": "",
            "auxcode": "AMV",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Air Midwest",
            "code": "ZV",
            "auxcode": "AMW",
            "companyName": "AIR MIDWEST",
            "country": "United States"
        },
        {
            "name": "Air Seychelles",
            "code": "HM",
            "auxcode": "SEY",
            "companyName": "SEYCHELLES",
            "country": "Seychelles"
        },
        {
            "name": "Air Sofia",
            "code": "",
            "auxcode": "SFB",
            "companyName": "AIR SOFIA",
            "country": "Bulgaria"
        },
        {
            "name": "AeroMéxico",
            "code": "AM",
            "auxcode": "AMX",
            "companyName": "AEROMEXICO",
            "country": "Mexico"
        },
        {
            "name": "Air Ambar",
            "code": "",
            "auxcode": "AMY",
            "companyName": "AIR AMBAR",
            "country": "Dominican Republic"
        },
        {
            "name": "Amiya Airline",
            "code": "",
            "auxcode": "AMZ",
            "companyName": "AMIYA AIR",
            "country": "Nigeria"
        },
        {
            "name": "All Nippon Airways",
            "code": "NH",
            "auxcode": "ANA",
            "companyName": "ALL NIPPON",
            "country": "Japan"
        },
        {
            "name": "Air Navigation And Trading Co. Ltd.",
            "code": "",
            "auxcode": "ANB",
            "companyName": "AIR NAV",
            "country": "United Kingdom"
        },
        {
            "name": "Anglo Cargo",
            "code": "",
            "auxcode": "ANC",
            "companyName": "ANGLO",
            "country": "United Kingdom"
        },
        {
            "name": "Air Nostrum",
            "code": "YW",
            "auxcode": "ANE",
            "companyName": "AIR NOSTRUM",
            "country": "Spain"
        },
        {
            "name": "Air Niugini",
            "code": "PX",
            "auxcode": "ANG",
            "companyName": "NUIGINI",
            "country": "Papua New Guinea"
        },
        {
            "name": "Air Arabia",
            "code": "G9",
            "auxcode": "ABY",
            "companyName": "ARABIA",
            "country": "United Arab Emirates"
        },
        {
            "name": "Air Canada",
            "code": "AC",
            "auxcode": "ACA",
            "companyName": "AIR CANADA",
            "country": "Canada"
        },
        {
            "name": "Airport Bratsk",
            "code": "",
            "auxcode": "BRP",
            "companyName": "AEROBRA",
            "country": "Russia"
        },
        {
            "name": "Air Wings",
            "code": "",
            "auxcode": "BSB",
            "companyName": "ARBAS",
            "country": "Moldova"
        },
        {
            "name": "Air Baltic",
            "code": "BT",
            "auxcode": "BTI",
            "companyName": "AIRBALTIC",
            "country": "Latvia"
        },
        {
            "name": "Alajnihah for Air Transport",
            "code": "",
            "auxcode": "ANH",
            "companyName": "ALAJNIHAH",
            "country": "Libya"
        },
        {
            "name": "Air Atlantic (Nig) Limited",
            "code": "",
            "auxcode": "ANI",
            "companyName": "NIGALANTIC",
            "country": "Nigeria"
        },
        {
            "name": "Air Nippon",
            "code": "EL",
            "auxcode": "ANK",
            "companyName": "ANK AIR",
            "country": "Japan"
        },
        {
            "name": "Antares Airtransport",
            "code": "",
            "auxcode": "ANM",
            "companyName": " Maintenance & Service GmbH",
            "country": "ANTARES"
        },
        {
            "name": "Airnorth",
            "code": "TL",
            "auxcode": "ANO",
            "companyName": "TOPEND",
            "country": "Australia"
        },
        {
            "name": "Aerol",
            "code": "",
            "auxcode": "ANQ",
            "companyName": "ANTIOQUIA",
            "country": "Colombia"
        },
        {
            "name": "Andes Lineas Aereas",
            "code": "",
            "auxcode": "ANS",
            "companyName": "AEROANDES",
            "country": "Argentina"
        },
        {
            "name": "Air North Charter code Canada",
            "code": "4N",
            "auxcode": "ANT",
            "companyName": "AIR NORTH",
            "country": "Canada"
        },
        {
            "name": "Air Nevada",
            "code": "",
            "auxcode": "ANV",
            "companyName": "AIR NEVADA",
            "country": "United States"
        },
        {
            "name": "AOM French Airlines",
            "code": "IW",
            "auxcode": "AOM",
            "companyName": "French Lines",
            "country": "France"
        },
        {
            "name": "Aviacion Del Noroeste",
            "code": "",
            "auxcode": "ANW",
            "companyName": " S.A. de C.V.",
            "country": "AVINOR"
        },
        {
            "name": "Air New Zealand",
            "code": "NZ",
            "auxcode": "ANZ",
            "companyName": "NEW ZEALAND",
            "country": "New Zealand"
        },
        {
            "name": "Avia Jaynar",
            "code": "",
            "auxcode": "SAP",
            "companyName": "TOBOL",
            "country": "Kazakhstan"
        },
        {
            "name": "Aero Servicios Empresariales",
            "code": "",
            "auxcode": "EMS",
            "companyName": "SERVIEMPRESARIAL",
            "country": "Mexico"
        },
        {
            "name": "Alcon Servicios Aereos",
            "code": "",
            "auxcode": "AOA",
            "companyName": " S.A. de C.V.",
            "country": "ALCON"
        },
        {
            "name": "AVCOM",
            "code": "J6",
            "auxcode": "AOC",
            "companyName": "AERO AVCOM",
            "country": "Russia"
        },
        {
            "name": "Aero Vodochody",
            "code": "",
            "auxcode": "AOD",
            "companyName": "AERO CZECH",
            "country": "Czech Republic"
        },
        {
            "name": "Air One Executive",
            "code": "",
            "auxcode": "AOE",
            "companyName": " ",
            "country": "Italy"
        },
        {
            "name": "Atair Pty Ltd.",
            "code": "",
            "auxcode": "AOF",
            "companyName": "ATAIR",
            "country": "South Africa"
        },
        {
            "name": "Aero VIP",
            "code": "2D",
            "auxcode": "AOG",
            "companyName": "AVIP",
            "country": "Argentina"
        },
        {
            "name": "Aeromundo Ejecutivo",
            "code": "",
            "auxcode": "MUN",
            "companyName": "AEROMUNDO",
            "country": "Mexico"
        },
        {
            "name": "Aerolinea Muri",
            "code": "",
            "auxcode": "MUR",
            "companyName": "MURI",
            "country": "Mexico"
        },
        {
            "name": "Astoria",
            "code": "",
            "auxcode": "AOI",
            "companyName": " Inc.",
            "country": "ASTORIA"
        },
        {
            "name": "Aero Rent JSC",
            "code": "",
            "auxcode": "NRO",
            "companyName": "AEROMASTER",
            "country": "Russia"
        },
        {
            "name": "AeronordcodeGrup",
            "code": "",
            "auxcode": "NRP",
            "companyName": "AERONORD",
            "country": "Moldova"
        },
        {
            "name": "Aeroatlantico Colombia",
            "code": "",
            "auxcode": "AOK",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "Angkor Airlines",
            "code": "",
            "auxcode": "AOL",
            "companyName": "ANGKOR AIR",
            "country": "Cambodia"
        },
        {
            "name": "Aero Entreprise",
            "code": "",
            "auxcode": "AON",
            "companyName": "AERO ENTERPRISE",
            "country": "France"
        },
        {
            "name": "As",
            "code": "",
            "auxcode": "AOO",
            "companyName": " Opened Joint Stock Company",
            "country": "COMPANY AS"
        },
        {
            "name": "Aeropiloto",
            "code": "",
            "auxcode": "AOP",
            "companyName": "AEROPILOTO",
            "country": "Portugal"
        },
        {
            "name": "Aeroenlaces Nacionales",
            "code": "",
            "auxcode": "VIV",
            "companyName": "AEROENLACES",
            "country": "Mexico"
        },
        {
            "name": "Aerovis Airlines",
            "code": "",
            "auxcode": "VIZ",
            "companyName": "AEROVIZ",
            "country": "Ukraine"
        },
        {
            "name": "Avjet International (FZE)",
            "code": "",
            "auxcode": "VJE",
            "companyName": "",
            "country": "United Arab Emirates"
        },
        {
            "name": "Aerovista Gulf Express",
            "code": "",
            "auxcode": "VGF",
            "companyName": "VISTA GULF",
            "country": "United Arab Emirates"
        },
        {
            "name": "Air Vegas",
            "code": "6V",
            "auxcode": "VGA",
            "companyName": "AIR VEGAS",
            "country": "United States"
        },
        {
            "name": "Almaver",
            "code": "",
            "auxcode": "VER",
            "companyName": "ALMAVER",
            "country": "Mexico"
        },
        {
            "name": "Afro International Ent. Limited",
            "code": "",
            "auxcode": "AOR",
            "companyName": "INTERcodeAFRO",
            "country": "Nigeria"
        },
        {
            "name": "Alitalia Express",
            "code": "XM",
            "auxcode": "SMX",
            "companyName": "ALIEXPRESS",
            "country": "Italy"
        },
        {
            "name": "Asia Overnight Express",
            "code": "OE",
            "auxcode": "AOT",
            "companyName": "ASIA OVERNIGHT",
            "country": "Philippines"
        },
        {
            "name": "Air Tractor",
            "code": "",
            "auxcode": "AOU",
            "companyName": "AIR TRACTOR",
            "country": "Croatia"
        },
        {
            "name": "Aero Vision",
            "code": "",
            "auxcode": "AOV",
            "companyName": "AEROVISION",
            "country": "France"
        },
        {
            "name": "Aerotaxi Del Valle",
            "code": "",
            "auxcode": "AOX",
            "companyName": "AEROVALLE",
            "country": "Colombia"
        },
        {
            "name": "Air Park Aviation Ltd.",
            "code": "",
            "auxcode": "APA",
            "companyName": "CANcodeAM",
            "country": "Canada"
        },
        {
            "name": "Airpac Airlines",
            "code": "",
            "auxcode": "APC",
            "companyName": " Inc.",
            "country": "AIRPAC"
        },
        {
            "name": "Aeroservicios Monterrey",
            "code": "",
            "auxcode": "SVM",
            "companyName": "SERVIMONTE",
            "country": "Mexico"
        },
        {
            "name": "Amapola Flyg AB",
            "code": "",
            "auxcode": "APF",
            "companyName": "AMAPOLA",
            "country": "Sweden"
        },
        {
            "name": "Air People International",
            "code": "",
            "auxcode": "APG",
            "companyName": "AIR PEOPLE",
            "country": "Thailand"
        },
        {
            "name": "Alpha Aviation",
            "code": "",
            "auxcode": "APH",
            "companyName": " Inc.",
            "country": "AIRFLIGHT"
        },
        {
            "name": "ASA Pesada",
            "code": "",
            "auxcode": "API",
            "companyName": " Lda.",
            "country": "ASA PESADA"
        },
        {
            "name": "Air Print",
            "code": "",
            "auxcode": "APJ",
            "companyName": " S.A.",
            "country": "AIR PRINT"
        },
        {
            "name": "Atlantic Airlines",
            "code": "",
            "auxcode": "BJK",
            "companyName": "BLACKJACK",
            "country": "United States"
        },
        {
            "name": "Aerotransporte Petrolero",
            "code": "",
            "auxcode": "PET",
            "companyName": "AEROPETRO",
            "country": "Colombia"
        },
        {
            "name": "Aero Flight",
            "code": "GV",
            "auxcode": "ARF",
            "companyName": "Aero Fox",
            "country": "Germany"
        },
        {
            "name": "ACM Aviation",
            "code": "",
            "auxcode": "BJT",
            "companyName": "BAY JET",
            "country": "United States"
        },
        {
            "name": "Aircompany Barcol",
            "code": "",
            "auxcode": "BKL",
            "companyName": "BARCOL",
            "country": "Russia"
        },
        {
            "name": "All Charter Limited",
            "code": "",
            "auxcode": "BLA",
            "companyName": "ALL CHARTER",
            "country": "United Kingdom"
        },
        {
            "name": "Appalachian Flying Service",
            "code": "",
            "auxcode": "APL",
            "companyName": " Inc.",
            "country": "APPALACHIAN"
        },
        {
            "name": "Airpac",
            "code": "",
            "auxcode": "APM",
            "companyName": " Inc.",
            "country": "ALASKA PACIFIC"
        },
        {
            "name": "Aeropro",
            "code": "",
            "auxcode": "APO",
            "companyName": "AEROPRO",
            "country": "Canada"
        },
        {
            "name": "Aerolineas Pacifico Atlantico",
            "code": "",
            "auxcode": "APP",
            "companyName": " S.A. (Apair)",
            "country": "AEROPERLAS"
        },
        {
            "name": "Aspen Aviation",
            "code": "",
            "auxcode": "APQ",
            "companyName": "ASPEN BASE",
            "country": "United States"
        },
        {
            "name": "Aeropuma",
            "code": "",
            "auxcode": "APU",
            "companyName": " S.A.",
            "country": "AEROPUMA"
        },
        {
            "name": "Air Plan International",
            "code": "",
            "auxcode": "APV",
            "companyName": "AIR PLAN",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Arrow Air",
            "code": "JW",
            "auxcode": "APW",
            "companyName": "BIG A",
            "country": "United States"
        },
        {
            "name": "Apex Air Cargo",
            "code": "",
            "auxcode": "APX",
            "companyName": "PARCEL EXPRESS",
            "country": "United States"
        },
        {
            "name": "APA Internacional",
            "code": "",
            "auxcode": "APY",
            "companyName": "APA INTERNACIONAL",
            "country": "Dominican Republic"
        },
        {
            "name": "Aeroatlas",
            "code": "",
            "auxcode": "AQA",
            "companyName": " S.A.",
            "country": "ATCO"
        },
        {
            "name": "Aquila Air Ltd.",
            "code": "",
            "auxcode": "AQL",
            "companyName": "AQUILA",
            "country": "Canada"
        },
        {
            "name": "Air Queensland",
            "code": "",
            "auxcode": "AQN",
            "companyName": "BUSHAIR",
            "country": "Australia"
        },
        {
            "name": "Aluminum Company Of America",
            "code": "",
            "auxcode": "AQO",
            "companyName": "ALCOA SHUTTLE",
            "country": "United States"
        },
        {
            "name": "Aviones de Renta de Quintana Roo",
            "code": "",
            "auxcode": "AQT",
            "companyName": " S.A. de C.V.",
            "country": "AVIOQUINTANA"
        },
        {
            "name": "AirQuarius Aviation",
            "code": "",
            "auxcode": "AQU",
            "companyName": "QUARIUS",
            "country": "South Africa"
        },
        {
            "name": "Aerodyne Charter Company",
            "code": "",
            "auxcode": "AQZ",
            "companyName": "QUANZA",
            "country": "United States"
        },
        {
            "name": "Arik Air",
            "code": "W3",
            "auxcode": "ARA",
            "companyName": "ARIK AIR",
            "country": "Nigeria"
        },
        {
            "name": "Avia Air N.V.",
            "code": "",
            "auxcode": "ARB",
            "companyName": "AVIAIR",
            "country": "Aruba"
        },
        {
            "name": "Air Routing International Corp.",
            "code": "",
            "auxcode": "ARC",
            "companyName": " ",
            "country": "United States"
        },
        {
            "name": "Aerocondor",
            "code": "2B",
            "auxcode": "ARD",
            "companyName": "AEROCONDOR",
            "country": "Portugal"
        },
        {
            "name": "Aires",
            "code": "4C",
            "auxcode": "ARE",
            "companyName": " Aerovias de Integracion Regional",
            "country": " S.A."
        },
        {
            "name": "Aerolineas Argentinas",
            "code": "AR",
            "auxcode": "ARG",
            "companyName": "ARGENTINA",
            "country": "Argentina"
        },
        {
            "name": "Arrowhead Airways",
            "code": "",
            "auxcode": "ARH",
            "companyName": "ARROWHEAD",
            "country": "United States"
        },
        {
            "name": "Aero Vics",
            "code": "",
            "auxcode": "ARI",
            "companyName": "AEROVICS",
            "country": "Mexico"
        },
        {
            "name": "Aerojet de Costa Rica",
            "code": "",
            "auxcode": "ARJ",
            "companyName": " S.A.",
            "country": ""
        },
        {
            "name": "Antillana De Navegacion Aerea",
            "code": "",
            "auxcode": "SUN",
            "companyName": "",
            "country": "Dominican Republic"
        },
        {
            "name": "Aeroservicios De San Luis",
            "code": "",
            "auxcode": "SUO",
            "companyName": "SERVICIO SANLUIS",
            "country": "Mexico"
        },
        {
            "name": "Aerosuper",
            "code": "",
            "auxcode": "SUP",
            "companyName": "AEROSUPER",
            "country": "Mexico"
        },
        {
            "name": "Aero Link Air Services S.L.",
            "code": "",
            "auxcode": "ARK",
            "companyName": "LINK SERVICE",
            "country": "Spain"
        },
        {
            "name": "Airlec code Air Aquitaine Transport",
            "code": "",
            "auxcode": "ARL",
            "companyName": "AIRLEC",
            "country": "France"
        },
        {
            "name": "Aeromarket Express",
            "code": "",
            "auxcode": "ARM",
            "companyName": "AMEX",
            "country": "Spain"
        },
        {
            "name": "Arrow Aviation Ltd.",
            "code": "",
            "auxcode": "ARO",
            "companyName": "ARROW",
            "country": "Canada"
        },
        {
            "name": "RPAcodeAviataxi Ltd.",
            "code": "",
            "auxcode": "ARP",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Klaipeda",
            "code": "",
            "auxcode": "KLD",
            "companyName": "AIR KLAIPEDA",
            "country": "Lithuania"
        },
        {
            "name": "Armstrong Air",
            "code": "",
            "auxcode": "ARQ",
            "companyName": " Inc.",
            "country": "ARMSTRONG"
        },
        {
            "name": "Air Armenia",
            "code": "QN",
            "auxcode": "ARR",
            "companyName": "AIR ARMENIA",
            "country": "Armenia"
        },
        {
            "name": "Aeromet Servicios",
            "code": "",
            "auxcode": "ARS",
            "companyName": "METSERVICE",
            "country": "Chile"
        },
        {
            "name": "Aerotal Aerolineas Territoriales de Colombia Ltda",
            "code": "",
            "auxcode": "ART",
            "companyName": "AEROTAL",
            "country": "Colombia"
        },
        {
            "name": "Aravco Ltd.",
            "code": "",
            "auxcode": "ARV",
            "companyName": "ARAVCO",
            "country": "United Kingdom"
        },
        {
            "name": "Aria",
            "code": "",
            "auxcode": "ARW",
            "companyName": "ARIABIRD",
            "country": "France"
        },
        {
            "name": "Air Xpress",
            "code": "",
            "auxcode": "ARX",
            "companyName": " Inc.",
            "country": "AIREX"
        },
        {
            "name": "Airline Alania",
            "code": "",
            "auxcode": "OST",
            "companyName": "ALANIA",
            "country": "Russia"
        },
        {
            "name": "Air Tchad",
            "code": "",
            "auxcode": "HTT",
            "companyName": "HOTEL TANGO",
            "country": "Chad"
        },
        {
            "name": "Aerloineas de Techuacan",
            "code": "",
            "auxcode": "HUC",
            "companyName": "LINEAS TEHUACAN",
            "country": "Mexico"
        },
        {
            "name": "Aerotransportes Huitzilin",
            "code": "",
            "auxcode": "HUT",
            "companyName": "AEROHUITZILIN",
            "country": "Mexico"
        },
        {
            "name": "Aero Transportes Del Humaya",
            "code": "",
            "auxcode": "HUY",
            "companyName": "AERO HUMAYA",
            "country": "Mexico"
        },
        {
            "name": "Argosy Airways",
            "code": "",
            "auxcode": "ARY",
            "companyName": "GOSEY",
            "country": "United States"
        },
        {
            "name": "Air Resorts",
            "code": "",
            "auxcode": "ARZ",
            "companyName": "AIR RESORTS",
            "country": "United States"
        },
        {
            "name": "Alaska Airlines",
            "code": "AS",
            "auxcode": "ASA",
            "companyName": " Inc.",
            "country": "ALASKA"
        },
        {
            "name": "AircodeSpray 1967 Ltd.",
            "code": "",
            "auxcode": "ASB",
            "companyName": "AIR SPRAY",
            "country": "Canada"
        },
        {
            "name": "Air Star Corporation",
            "code": "",
            "auxcode": "ASC",
            "companyName": "AIR STAR",
            "country": "Canada"
        },
        {
            "name": "Air Sinai",
            "code": "4D",
            "auxcode": "ASD",
            "companyName": "AIR SINAI",
            "country": "Egypt"
        },
        {
            "name": "Airstars",
            "code": "PL",
            "auxcode": "ASE",
            "companyName": "MOROZOV",
            "country": "Russia"
        },
        {
            "name": "Air Schefferville",
            "code": "",
            "auxcode": "ASF",
            "companyName": " Inc.",
            "country": "SCHEFF"
        },
        {
            "name": "African Star Airways (PTY) Ltd.",
            "code": "",
            "auxcode": "ASG",
            "companyName": "AFRICAN STAR",
            "country": "South Africa"
        },
        {
            "name": "Aerosun International",
            "code": "",
            "auxcode": "ASI",
            "companyName": " Inc.",
            "country": "AEROSUN"
        },
        {
            "name": "Air Satellite",
            "code": "",
            "auxcode": "ASJ",
            "companyName": "SATELLITE",
            "country": "Canada"
        },
        {
            "name": "Awesome Flight Services (PTY) Ltd.",
            "code": "",
            "auxcode": "ASM",
            "companyName": "AWESOME",
            "country": "South Africa"
        },
        {
            "name": "Air and Sea Transport",
            "code": "",
            "auxcode": "ASN",
            "companyName": " ",
            "country": "Russia"
        },
        {
            "name": "Aero Slovakia",
            "code": "",
            "auxcode": "ASO",
            "companyName": "AERO NITRA",
            "country": "Slovakia"
        },
        {
            "name": "Airsprint",
            "code": "",
            "auxcode": "ASP",
            "companyName": "AIRSPRINT",
            "country": "Canada"
        },
        {
            "name": "Atlantic Southeast Airlines",
            "code": "EV",
            "auxcode": "ASQ",
            "companyName": "ACEY",
            "country": "United States"
        },
        {
            "name": "All Star Airlines",
            "code": "",
            "auxcode": "ASR",
            "companyName": " Inc.",
            "country": "ALL STAR"
        },
        {
            "name": "Air Class",
            "code": "",
            "auxcode": "ASS",
            "companyName": " S.A. de C.V.",
            "country": "AIR CLASS"
        },
        {
            "name": "Aerlineas Del Oeste",
            "code": "",
            "auxcode": "AST",
            "companyName": "AEROESTE",
            "country": "Mexico"
        },
        {
            "name": "Aviones Are",
            "code": "",
            "auxcode": "NRE",
            "companyName": "AVIONES ARE",
            "country": "Mexico"
        },
        {
            "name": "Air Nepal International",
            "code": "",
            "auxcode": "NPL",
            "companyName": "AIR NEPAL",
            "country": "Nepal"
        },
        {
            "name": "Air Napier",
            "code": "",
            "auxcode": "NPR",
            "companyName": "",
            "country": "New Zealand"
        },
        {
            "name": "Air Taxi & Cargo",
            "code": "",
            "auxcode": "WAM",
            "companyName": "TAXI CARGO",
            "country": "Sudan"
        },
        {
            "name": "Arrow Panama",
            "code": "",
            "auxcode": "WAP",
            "companyName": "ARROW PANAMA",
            "country": "Panama"
        },
        {
            "name": "AstraviacodeBissau Air Transports Ltd.",
            "code": "",
            "auxcode": "ASV",
            "companyName": "ASTRAVIA",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Astrakhan Airlines",
            "code": "OB",
            "auxcode": "ASZ",
            "companyName": "AIR ASTRAKHAN",
            "country": "Russia"
        },
        {
            "name": "Air Transport Association",
            "code": "",
            "auxcode": "ATA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Atlantair Ltd.",
            "code": "",
            "auxcode": "ATB",
            "companyName": "STARLITE",
            "country": "Canada"
        },
        {
            "name": "Air Tanzania",
            "code": "TC",
            "auxcode": "ATC",
            "companyName": "TANZANIA",
            "country": "Tanzania"
        },
        {
            "name": "Aerotours Dominicana",
            "code": "",
            "auxcode": "ATD",
            "companyName": "AEROTOURS",
            "country": "Dominican Republic"
        },
        {
            "name": "Atlantis Transportation Services",
            "code": "",
            "auxcode": "ATE",
            "companyName": " Ltd.",
            "country": "ATLANTIS CANADA"
        },
        {
            "name": "Aerotrans Airline",
            "code": "",
            "auxcode": "ATG",
            "companyName": "BACHYT",
            "country": "Kazakhstan"
        },
        {
            "name": "Air Charter World",
            "code": "",
            "auxcode": "XAC",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Air Burkina",
            "code": "2J",
            "auxcode": "VBW",
            "companyName": "BURKINA",
            "country": "Burkina Faso"
        },
        {
            "name": "Air Travel Corp.",
            "code": "",
            "auxcode": "ATH",
            "companyName": "AIR TRAVEL",
            "country": "United States"
        },
        {
            "name": "AerocodeTropics Air Services",
            "code": "HC",
            "auxcode": "ATI",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Air Traffic GmbH",
            "code": "",
            "auxcode": "ATJ",
            "companyName": "SNOOPY",
            "country": "Germany"
        },
        {
            "name": "AeroTACA",
            "code": "",
            "auxcode": "ATK",
            "companyName": "AEROTACA",
            "country": "Colombia"
        },
        {
            "name": "Aerocentral",
            "code": "",
            "auxcode": "ATL",
            "companyName": "CENTRALMEX",
            "country": "Mexico"
        },
        {
            "name": "Airlines Of Tasmania",
            "code": "FO",
            "auxcode": "ATM",
            "companyName": "AIRTAS",
            "country": "Australia"
        },
        {
            "name": "Air Saint Pierre",
            "code": "PJ",
            "auxcode": "SPM",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Air Transport International",
            "code": "8C",
            "auxcode": "ATN",
            "companyName": "AIR TRANSPORT",
            "country": "United States"
        },
        {
            "name": "ASTRAL Colombia code Aerotransportes Especiales Ltda.",
            "code": "",
            "auxcode": "ATP",
            "companyName": "ASTRAL",
            "country": "Colombia"
        },
        {
            "name": "Air Transport Schiphol",
            "code": "",
            "auxcode": "ATQ",
            "companyName": "MULTI",
            "country": "Netherlands"
        },
        {
            "name": "Aeroferinco",
            "code": "",
            "auxcode": "FEO",
            "companyName": "FERINCO",
            "country": "Mexico"
        },
        {
            "name": "Aero Taxis Y Servicios Alfe",
            "code": "",
            "auxcode": "FES",
            "companyName": "AERO ALFE",
            "country": "Mexico"
        },
        {
            "name": "Avialesookhrana",
            "code": "",
            "auxcode": "FFA",
            "companyName": "AVIALESOOKHRANA",
            "country": "Russia"
        },
        {
            "name": "Africair Service",
            "code": "",
            "auxcode": "FFB",
            "companyName": "FOXTROT FOXTROT",
            "country": "Senegal"
        },
        {
            "name": "Atlas Airlines",
            "code": "",
            "auxcode": "ATR",
            "companyName": "ATLAScodeAIR",
            "country": "United States"
        },
        {
            "name": "Air Transport Service",
            "code": "",
            "auxcode": "ATS",
            "companyName": "",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Aer Turas",
            "code": "",
            "auxcode": "ATT",
            "companyName": "AERTURAS",
            "country": "Ireland"
        },
        {
            "name": "Atlant Aerobatics Ltd.",
            "code": "",
            "auxcode": "ATU",
            "companyName": "ATLANT HUNGARY",
            "country": "Hungary"
        },
        {
            "name": "Avanti Air",
            "code": "",
            "auxcode": "ATV",
            "companyName": "AVANTI AIR",
            "country": "Germany"
        },
        {
            "name": "Aero Trades (Western) Ltd.",
            "code": "",
            "auxcode": "ATW",
            "companyName": "AERO TRADES",
            "country": "Canada"
        },
        {
            "name": "Austrian Airlines",
            "code": "OS",
            "auxcode": "AUA",
            "companyName": "AUSTRIAN",
            "country": "Austria"
        },
        {
            "name": "Air Southwest",
            "code": "",
            "auxcode": "WOW",
            "companyName": "SWALLOW",
            "country": "United Kingdom"
        },
        {
            "name": "Augsburg Airways",
            "code": "IQ",
            "auxcode": "AUB",
            "companyName": "AUGSBURGcodeAIR",
            "country": "Germany"
        },
        {
            "name": "Air Corporate",
            "code": "",
            "auxcode": "CPV",
            "companyName": "AIRCORPORATE",
            "country": "Italy"
        },
        {
            "name": "AviastarcodeTu",
            "code": "",
            "auxcode": "TUP",
            "companyName": "TUPOLEVAIR",
            "country": "Russia"
        },
        {
            "name": "AirBridge Cargo",
            "code": "RU",
            "auxcode": "ABW",
            "companyName": "AIRBRIDGE CARGO",
            "country": "Russia"
        },
        {
            "name": "ATUR",
            "code": "",
            "auxcode": "TUR",
            "companyName": "",
            "country": "Ecuador"
        },
        {
            "name": "ATESA Aerotaxis Ecuatorianos",
            "code": "",
            "auxcode": "TXU",
            "companyName": "ATESA",
            "country": "Ecuador"
        },
        {
            "name": "Austin Express",
            "code": "",
            "auxcode": "TXX",
            "companyName": "COWBOY",
            "country": "United States"
        },
        {
            "name": "Audi Air",
            "code": "",
            "auxcode": "AUD",
            "companyName": " Inc.",
            "country": "AUDI AIR"
        },
        {
            "name": "Augusta Air Luftfahrtunternehmen",
            "code": "",
            "auxcode": "AUF",
            "companyName": "AUGUSTA",
            "country": "Germany"
        },
        {
            "name": "Abu Dhabi Amiri Flight",
            "code": "MO",
            "auxcode": "AUH",
            "companyName": "SULTAN",
            "country": "United Arab Emirates"
        },
        {
            "name": "AeroflotcodeNord",
            "code": "5N",
            "auxcode": "AUL",
            "companyName": "DVINA",
            "country": "Russia"
        },
        {
            "name": "Air Atlantic Uruguay",
            "code": "",
            "auxcode": "AUM",
            "companyName": "ATLAMUR",
            "country": "Uruguay"
        },
        {
            "name": "Aviones Unidos",
            "code": "",
            "auxcode": "AUN",
            "companyName": "AVIONES UNIDOS",
            "country": "Mexico"
        },
        {
            "name": "Avia Business Group",
            "code": "",
            "auxcode": "AUP",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Aero Servicios Expecializados",
            "code": "",
            "auxcode": "SVE",
            "companyName": "AEROESPECIAL",
            "country": "Mexico"
        },
        {
            "name": "Aurigny Air Services",
            "code": "GR",
            "auxcode": "AUR",
            "companyName": "AYLINE",
            "country": "United Kingdom"
        },
        {
            "name": "AuscodeAir",
            "code": "NO",
            "auxcode": "AUS",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Austral Lineas Aereas",
            "code": "AU",
            "auxcode": "AUT",
            "companyName": "AUSTRAL",
            "country": "Argentina"
        },
        {
            "name": "Aurora Aviation",
            "code": "",
            "auxcode": "AUU",
            "companyName": " Inc.",
            "country": "AURORA AIR"
        },
        {
            "name": "Air Uganda International Ltd.",
            "code": "",
            "auxcode": "AUX",
            "companyName": "",
            "country": "Uganda"
        },
        {
            "name": "Aerolineas Uruguayas",
            "code": "",
            "auxcode": "AUY",
            "companyName": " S.A.",
            "country": "AUSA"
        },
        {
            "name": "Australian Airlines",
            "code": "AO",
            "auxcode": "AUZ",
            "companyName": "AUSTRALIAN",
            "country": "Australia"
        },
        {
            "name": "Avianca code Aerovias Nacionales de Colombia",
            "code": "AV",
            "auxcode": "AVA",
            "companyName": " S.A.",
            "country": "AVIANCA"
        },
        {
            "name": "Aviation Beaufort",
            "code": "",
            "auxcode": "AVB",
            "companyName": "BEAUPAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Alamo Aviacion",
            "code": "",
            "auxcode": "AVD",
            "companyName": " S.L.",
            "country": "ALAMO"
        },
        {
            "name": "Aviair Aviation Ltd.",
            "code": "",
            "auxcode": "AVF",
            "companyName": "CARIBOO",
            "country": "Canada"
        },
        {
            "name": "Air Falcon",
            "code": "",
            "auxcode": "AVG",
            "companyName": "DJIBOUTI FALCON",
            "country": "Djibouti"
        },
        {
            "name": "AV8 Helicopters",
            "code": "",
            "auxcode": "AVH",
            "companyName": "KENT HELI",
            "country": "United Kingdom"
        },
        {
            "name": "Avia Traffic Company",
            "code": "",
            "auxcode": "AVJ",
            "companyName": "ATOMIC",
            "country": "Kyrgyzstan"
        },
        {
            "name": "AV8 Helicopters",
            "code": "",
            "auxcode": "AVK",
            "companyName": "AVIATEcodeCOPTER",
            "country": "South Africa"
        },
        {
            "name": "Aviacion Ejecutiva Mexicana",
            "code": "",
            "auxcode": "AVM",
            "companyName": " S.A.",
            "country": "AVEMEX"
        },
        {
            "name": "Air Vanuatu",
            "code": "NF",
            "auxcode": "AVN",
            "companyName": "AIR VAN",
            "country": "Vanuatu"
        },
        {
            "name": "Aviation at Work",
            "code": "",
            "auxcode": "AVO",
            "companyName": "AVIATION WORK",
            "country": "South Africa"
        },
        {
            "name": "Avcorp Registrations",
            "code": "",
            "auxcode": "AVP",
            "companyName": "AVCORP",
            "country": "United Kingdom"
        },
        {
            "name": "Alfa Aerospace",
            "code": "",
            "auxcode": "LFP",
            "companyName": "ALFAcodeSPACE",
            "country": "Australia"
        },
        {
            "name": "Atlantic Airfreight Aviation",
            "code": "",
            "auxcode": "LFR",
            "companyName": "LANFREIGHT",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "Aerolift Company",
            "code": "",
            "auxcode": "LFT",
            "companyName": "LIFTCO",
            "country": "Sierra Leone"
        },
        {
            "name": "Aviation Services",
            "code": "",
            "auxcode": "AVQ",
            "companyName": " Inc.",
            "country": "AQUILINE"
        },
        {
            "name": "Active Aero Charter",
            "code": "",
            "auxcode": "AVR",
            "companyName": " Inc.",
            "country": "ACTIVE AERO"
        },
        {
            "name": "Avialsa Tcode35",
            "code": "",
            "auxcode": "AVS",
            "companyName": "AVIALSA",
            "country": "Spain"
        },
        {
            "name": "Asia Avia Airlines",
            "code": "",
            "auxcode": "AVT",
            "companyName": "ASIAVIA",
            "country": "Indonesia"
        },
        {
            "name": "Avia Sud A",
            "code": "",
            "auxcode": "AVU",
            "companyName": "AVIASUD",
            "country": "France"
        },
        {
            "name": "Airvantage Incorporated",
            "code": "",
            "auxcode": "AVV",
            "companyName": "AIRVANTAGE",
            "country": "United States"
        },
        {
            "name": "Aviator Airways",
            "code": "",
            "auxcode": "AVW",
            "companyName": "AVIATOR",
            "country": "Greece"
        },
        {
            "name": "Aviapaslauga",
            "code": "",
            "auxcode": "AVX",
            "companyName": "PASLAUGA",
            "country": "Lithuania"
        },
        {
            "name": "ASL",
            "code": "",
            "auxcode": "XXX",
            "companyName": "",
            "country": "Belgium"
        },
        {
            "name": "Air Yugoslavia",
            "code": "",
            "auxcode": "YRG",
            "companyName": "YUGAIR",
            "country": "Serbia"
        },
        {
            "name": "Airlink Zambia",
            "code": "K8",
            "auxcode": "ZAK",
            "companyName": "",
            "country": "Zambia"
        },
        {
            "name": "Agence Nationale des Aerodromes et de la Meteorologie",
            "code": "",
            "auxcode": "ZZM",
            "companyName": "",
            "country": "Ivory Coast"
        },
        {
            "name": "Airbus Transport International",
            "code": "",
            "auxcode": "BGA",
            "companyName": "BELOUGA",
            "country": "France"
        },
        {
            "name": "Air Bangladesh",
            "code": "B9",
            "auxcode": "BGD",
            "companyName": "AIR BANGLA",
            "country": "Bangladesh"
        },
        {
            "name": "Aviodetachmentcode28",
            "code": "",
            "auxcode": "BGF",
            "companyName": "BULGARIAN",
            "country": "Bulgaria"
        },
        {
            "name": "Aero BG",
            "code": "",
            "auxcode": "BGG",
            "companyName": "AERO BG",
            "country": "Mexico"
        },
        {
            "name": "Aerotaxis De La Bahia",
            "code": "",
            "auxcode": "BHC",
            "companyName": "BAHIA",
            "country": "Mexico"
        },
        {
            "name": "Air Mediterranee",
            "code": "DR",
            "auxcode": "BIE",
            "companyName": "MEDITERRANEE",
            "country": "France"
        },
        {
            "name": "Aviaservice",
            "code": "",
            "auxcode": "BIV",
            "companyName": "AVIASERVICE",
            "country": "Georgia"
        },
        {
            "name": "Aerolineas De El Salvador",
            "code": "",
            "auxcode": "SZA",
            "companyName": "AESA",
            "country": "El Salvador"
        },
        {
            "name": "Swaziland Airlink",
            "code": "",
            "auxcode": "SZL",
            "companyName": "SWAZILINK",
            "country": "Swaziland"
        },
        {
            "name": "Air Moorea",
            "code": "",
            "auxcode": "TAH",
            "companyName": "AIR MOOREA",
            "country": "France"
        },
        {
            "name": "Aerovaradero",
            "code": "",
            "auxcode": "AVY",
            "companyName": " S.A.",
            "country": "AEROVARADERO"
        },
        {
            "name": "Air Valencia",
            "code": "",
            "auxcode": "AVZ",
            "companyName": "AIR VALENCIA",
            "country": "Spain"
        },
        {
            "name": "Airways International",
            "code": "",
            "auxcode": "AWB",
            "companyName": " Inc.",
            "country": "AIRNAT"
        },
        {
            "name": "Airwork",
            "code": "",
            "auxcode": "AWK",
            "companyName": "AIRWORK",
            "country": "New Zealand"
        },
        {
            "name": "Australian Wetleasing",
            "code": "",
            "auxcode": "AWL",
            "companyName": "AUSSIEWORLD",
            "country": "Australia"
        },
        {
            "name": "Air Niamey",
            "code": "",
            "auxcode": "AWN",
            "companyName": "AIR NIAMEY",
            "country": "Niger"
        },
        {
            "name": "Awood Air Ltd.",
            "code": "",
            "auxcode": "AWO",
            "companyName": "AWOOD AIR",
            "country": "Canada"
        },
        {
            "name": "Arctic Wings And Rotors Ltd.",
            "code": "",
            "auxcode": "AWR",
            "companyName": "ARCTIC WINGS",
            "country": "Canada"
        },
        {
            "name": "Auo Airclub AISTcodeM",
            "code": "",
            "auxcode": "ISM",
            "companyName": "STORK",
            "country": "Russia"
        },
        {
            "name": "Arab Wings",
            "code": "",
            "auxcode": "AWS",
            "companyName": "ARAB WINGS",
            "country": "Jordan"
        },
        {
            "name": "Air West",
            "code": "",
            "auxcode": "AWT",
            "companyName": "AIR WEST",
            "country": "Canada"
        },
        {
            "name": "Aeroline GmbH",
            "code": "7E",
            "auxcode": "AWU",
            "companyName": "SYLTcodeAIR",
            "country": "Germany"
        },
        {
            "name": "Airwave Transport",
            "code": "",
            "auxcode": "AWV",
            "companyName": " Inc.",
            "country": "AIRWAVE"
        },
        {
            "name": "Air Wales",
            "code": "6G",
            "auxcode": "AWW",
            "companyName": "RED DRAGON",
            "country": "United Kingdom"
        },
        {
            "name": "Aeroway",
            "code": "",
            "auxcode": "AWY",
            "companyName": " S.L.",
            "country": "AEROWEE"
        },
        {
            "name": "Air Caraïbes",
            "code": "TX",
            "auxcode": "FWI",
            "companyName": "FRENCH WEST",
            "country": "France"
        },
        {
            "name": "AirWest",
            "code": "",
            "auxcode": "AWZ",
            "companyName": " ",
            "country": "Sudan"
        },
        {
            "name": "Air India Express",
            "code": "IX",
            "auxcode": "AXB",
            "companyName": "EXPRESS INDIA",
            "country": "India"
        },
        {
            "name": "Air Express",
            "code": "",
            "auxcode": "AXD",
            "companyName": "AIR SUDEX",
            "country": "Sudan"
        },
        {
            "name": "Asian Express Airlines",
            "code": "HJ",
            "auxcode": "AXF",
            "companyName": "FREIGHTEXPRESS",
            "country": "Australia"
        },
        {
            "name": "Aeromexhaga",
            "code": "",
            "auxcode": "AXH",
            "companyName": "AEROMEXHAGA",
            "country": "Mexico"
        },
        {
            "name": "Aeron International Airlines",
            "code": "",
            "auxcode": "AXI",
            "companyName": " Inc.",
            "country": "AIR FREIGHTER"
        },
        {
            "name": "African Express Airways",
            "code": "",
            "auxcode": "AXK",
            "companyName": "EXPRESS JET",
            "country": "Kenya"
        },
        {
            "name": "Air Exel",
            "code": "XT",
            "auxcode": "AXL",
            "companyName": "EXEL COMMUTER",
            "country": "Netherlands"
        },
        {
            "name": "AirAsia",
            "code": "AK",
            "auxcode": "AXM",
            "companyName": "ASIAN EXPRESS",
            "country": "Malaysia"
        },
        {
            "name": "Alexandair",
            "code": "",
            "auxcode": "AXN",
            "companyName": "ALEXANDROS",
            "country": "Greece"
        },
        {
            "name": "Aeromax",
            "code": "",
            "auxcode": "AXP",
            "companyName": "AEROMAX SPAIN",
            "country": "Spain"
        },
        {
            "name": "Action Airlines (Action Air Charter)",
            "code": "",
            "auxcode": "AXQ",
            "companyName": "ACTION AIR",
            "country": "United States"
        },
        {
            "name": "Alberni Airways",
            "code": "",
            "auxcode": "BNI",
            "companyName": "ALBERNI",
            "country": "Canada"
        },
        {
            "name": "Aerolineas Bonanza",
            "code": "",
            "auxcode": "BNZ",
            "companyName": "AERO BONANZA",
            "country": "Mexico"
        },
        {
            "name": "Aerobona",
            "code": "",
            "auxcode": "BOC",
            "companyName": "AEROBONA",
            "country": "Mexico"
        },
        {
            "name": "Aboitiz Air",
            "code": "",
            "auxcode": "BOI",
            "companyName": "ABAIR",
            "country": "Philippines"
        },
        {
            "name": "Axel Rent",
            "code": "",
            "auxcode": "AXR",
            "companyName": " S.A.",
            "country": "RENTAXEL"
        },
        {
            "name": "Altus Airlines",
            "code": "",
            "auxcode": "AXS",
            "companyName": "ALTUS",
            "country": "United States"
        },
        {
            "name": "Aviaxess",
            "code": "",
            "auxcode": "AXV",
            "companyName": "AXAVIA",
            "country": "France"
        },
        {
            "name": "Avioimpex A.D.p.o.",
            "code": "",
            "auxcode": "AXX",
            "companyName": "IMPEX",
            "country": "Macedonia"
        },
        {
            "name": "Axis Airways",
            "code": "6V",
            "auxcode": "AXY",
            "companyName": "AXIS",
            "country": "France"
        },
        {
            "name": "Aladia Airlines",
            "code": "",
            "auxcode": "AYD",
            "companyName": "AIRLINES ALADIA",
            "country": "Mexico"
        },
        {
            "name": "Aykavia Aircompany",
            "code": "",
            "auxcode": "AYK",
            "companyName": "",
            "country": "Armenia"
        },
        {
            "name": "Airman",
            "code": "",
            "auxcode": "AYM",
            "companyName": " S.L.",
            "country": "AIRMAN"
        },
        {
            "name": "Atlantic Airlines",
            "code": "",
            "auxcode": "AYN",
            "companyName": " S.A.",
            "country": "ATLANTIC NICARAGUA"
        },
        {
            "name": "Awsaj Aviation Services",
            "code": "",
            "auxcode": "AYS",
            "companyName": "",
            "country": "Libya"
        },
        {
            "name": "Ayeet Aviation & Tourism",
            "code": "",
            "auxcode": "AYT",
            "companyName": "AYEET",
            "country": "Israel"
        },
        {
            "name": "AtlantcodeSoyuz Airlines",
            "code": "3G",
            "auxcode": "AYZ",
            "companyName": "ATLANTcodeSOYUZ",
            "country": "Russia"
        },
        {
            "name": "Alitalia",
            "code": "AZ",
            "auxcode": "AZA",
            "companyName": "ALITALIA",
            "country": "Italy"
        },
        {
            "name": "Azamat",
            "code": "",
            "auxcode": "AZB",
            "companyName": "TUMARA",
            "country": "Kazakhstan"
        },
        {
            "name": "ArcuscodeAir Logistic",
            "code": "ZE",
            "auxcode": "AZE",
            "companyName": "ARCUS AIR",
            "country": "Germany"
        },
        {
            "name": "Air Zermatt AG",
            "code": "",
            "auxcode": "AZF",
            "companyName": "AIR ZERMATT",
            "country": "Switzerland"
        },
        {
            "name": "Azalhelikopter",
            "code": "",
            "auxcode": "AZK",
            "companyName": "AZALHELICOPTER",
            "country": "Azerbaijan"
        },
        {
            "name": "Africa One",
            "code": "",
            "auxcode": "AZL",
            "companyName": "SKY AFRICA",
            "country": "Zambia"
        },
        {
            "name": "Aerocozumel",
            "code": "",
            "auxcode": "AZM",
            "companyName": "AEROCOZUMEL",
            "country": "Mexico"
        },
        {
            "name": "Amaszonas",
            "code": "Z8",
            "auxcode": "AZN",
            "companyName": "",
            "country": "Bolivia"
        },
        {
            "name": "Arizona Pacific Airways",
            "code": "",
            "auxcode": "AZP",
            "companyName": "ARIZONA PACIFIC",
            "country": "United States"
        },
        {
            "name": "Aviacon Zitotrans Air Company",
            "code": "",
            "auxcode": "AZS",
            "companyName": "ZITOTRANS",
            "country": "Russia"
        },
        {
            "name": "Azimut",
            "code": "",
            "auxcode": "AZT",
            "companyName": " S.A.",
            "country": "AZIMUT"
        },
        {
            "name": "Azov Avia Airlines",
            "code": "",
            "auxcode": "AZV",
            "companyName": "AZOV AVIA",
            "country": "Ukraine"
        },
        {
            "name": "Air Zimbabwe",
            "code": "UM",
            "auxcode": "AZW",
            "companyName": "AIR ZIMBABWE",
            "country": "Zimbabwe"
        },
        {
            "name": "Aero Jomacha",
            "code": "",
            "auxcode": "MHC",
            "companyName": "AERO JOMACHA",
            "country": "Mexico"
        },
        {
            "name": "Air Memphis",
            "code": "",
            "auxcode": "MHS",
            "companyName": "AIR MEMPHIS",
            "country": "Egypt"
        },
        {
            "name": "Air Memphis",
            "code": "",
            "auxcode": "MHU",
            "companyName": "MEPHIS UGANDA",
            "country": "Uganda"
        },
        {
            "name": "Air Marrakech Service",
            "code": "",
            "auxcode": "MKH",
            "companyName": "AIR MARRAKECH",
            "country": "Morocco"
        },
        {
            "name": "Air Max Africa",
            "code": "",
            "auxcode": "AZX",
            "companyName": "AZIMA",
            "country": "Gabon"
        },
        {
            "name": "Arizona Airways",
            "code": "",
            "auxcode": "AZY",
            "companyName": " Inc.",
            "country": "ARIZAIR"
        },
        {
            "name": "Azza Transport",
            "code": "",
            "auxcode": "AZZ",
            "companyName": "AZZA TRANSPORT",
            "country": "Sudan"
        },
        {
            "name": "Air Continental Inc",
            "code": "",
            "auxcode": "NAR",
            "companyName": "NIGHT AIR",
            "country": "United States"
        },
        {
            "name": "AntanikcodeAir",
            "code": "",
            "auxcode": "NAU",
            "companyName": "ANTANIK",
            "country": "Ukraine"
        },
        {
            "name": "Air Newark",
            "code": "",
            "auxcode": "NER",
            "companyName": "NEWAIR",
            "country": "United States"
        },
        {
            "name": "Aircraft Support and Services",
            "code": "",
            "auxcode": "NFF",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "Aerobanana",
            "code": "",
            "auxcode": "OBA",
            "companyName": "AEROBANANA",
            "country": "Mexico"
        },
        {
            "name": "Amako Airlines",
            "code": "",
            "auxcode": "OBK",
            "companyName": "AMAKO AIR",
            "country": "Nigeria"
        },
        {
            "name": "Aserca Airlines",
            "code": "R7",
            "auxcode": "OCA",
            "companyName": "AROSCA",
            "country": "Venezuela"
        },
        {
            "name": "Afrique CArgo Service Senegal",
            "code": "",
            "auxcode": "NFS",
            "companyName": "",
            "country": "Senegal"
        },
        {
            "name": "Angoservice",
            "code": "",
            "auxcode": "NGC",
            "companyName": "ANGOSERVICE",
            "country": "Angola"
        },
        {
            "name": "Angel Airlines",
            "code": "",
            "auxcode": "NGE",
            "companyName": "ANGEL AIR",
            "country": "Thailand"
        },
        {
            "name": "Angel Flight America",
            "code": "",
            "auxcode": "NGF",
            "companyName": "ANGEL FLIGHT",
            "country": "United States"
        },
        {
            "name": "Air Atonabee",
            "code": "",
            "auxcode": "OUL",
            "companyName": "CITY EXPRESS",
            "country": "Canada"
        },
        {
            "name": "Aero Nova",
            "code": "",
            "auxcode": "OVA",
            "companyName": "AERONOVA",
            "country": "Spain"
        },
        {
            "name": "Amira Air",
            "code": "",
            "auxcode": "XPE",
            "companyName": "EXPERT",
            "country": "Austria"
        },
        {
            "name": "Aero Express Intercontinental",
            "code": "",
            "auxcode": "XSS",
            "companyName": "INTER EXPRESS",
            "country": "Mexico"
        },
        {
            "name": "Advance Aviation Services",
            "code": "",
            "auxcode": "XTJ",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Air Libya Tibesti",
            "code": "",
            "auxcode": "TLR",
            "companyName": "AIR LIBYA",
            "country": "Libya"
        },
        {
            "name": "Aerovic",
            "code": "",
            "auxcode": "OVC",
            "companyName": "",
            "country": "Ecuador"
        },
        {
            "name": "Airventure",
            "code": "",
            "auxcode": "RVE",
            "companyName": "AIRVENTURE",
            "country": "Belgium"
        },
        {
            "name": "Aero Servicios",
            "code": "",
            "auxcode": "RVI",
            "companyName": "AERO SERVICIOS",
            "country": "Mexico"
        },
        {
            "name": "Airvallee",
            "code": "",
            "auxcode": "RVL",
            "companyName": "AIR VALLEE",
            "country": "Italy"
        },
        {
            "name": "Aeromover",
            "code": "",
            "auxcode": "OVE",
            "companyName": "AEROMOVER",
            "country": "Mexico"
        },
        {
            "name": "Aerovias Ejecutivas",
            "code": "",
            "auxcode": "OVI",
            "companyName": "VIAS EJECUTIVAS",
            "country": "Mexico"
        },
        {
            "name": "Aero Servicio Pity",
            "code": "",
            "auxcode": "PTD",
            "companyName": "PITY",
            "country": "Mexico"
        },
        {
            "name": "Aero Copter",
            "code": "",
            "auxcode": "PTE",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "RossiyacodeRussian Airlines",
            "code": "FV",
            "auxcode": "SDM",
            "companyName": "PULKOVO",
            "country": "Russia"
        },
        {
            "name": "Air Pal",
            "code": "",
            "auxcode": "PLL",
            "companyName": "AIRPAL",
            "country": "Spain"
        },
        {
            "name": "Air Pullmantur",
            "code": "",
            "auxcode": "PLM",
            "companyName": "PULLMANTUR",
            "country": "Spain"
        },
        {
            "name": "Aviaexpress",
            "code": "RX",
            "auxcode": "AEH",
            "companyName": "Avex",
            "country": "Hungary"
        },
        {
            "name": "Aviones Para Servirle",
            "code": "",
            "auxcode": "PSG",
            "companyName": "SERVIAVIONES",
            "country": "Mexico"
        },
        {
            "name": "Avio Sluzba",
            "code": "",
            "auxcode": "SLU",
            "companyName": "AVIO SLUZBA",
            "country": "Serbia"
        },
        {
            "name": "Air Scorpio",
            "code": "",
            "auxcode": "SCU",
            "companyName": "SCORPIO UNIVERS",
            "country": "Bulgaria"
        },
        {
            "name": "Air Spirit",
            "code": "",
            "auxcode": "SIP",
            "companyName": "AIR SPIRIT",
            "country": "United States"
        },
        {
            "name": "Alatau Airlines",
            "code": "",
            "auxcode": "BMV",
            "companyName": "OLIGA",
            "country": "Kazakhstan"
        },
        {
            "name": "Aviateca",
            "code": "",
            "auxcode": "GUG",
            "companyName": "AVIATECA",
            "country": "Guatemala"
        },
        {
            "name": "Aroostook Aviation",
            "code": "",
            "auxcode": "PXX",
            "companyName": "PINE STATE",
            "country": "United States"
        },
        {
            "name": "Aeropycsa",
            "code": "",
            "auxcode": "PYC",
            "companyName": "AEROPYCSA",
            "country": "Mexico"
        },
        {
            "name": "Association of Private Pilots of Kazakhstan",
            "code": "",
            "auxcode": "PVK",
            "companyName": "BORIS",
            "country": "Kazakhstan"
        },
        {
            "name": "The Amiri Flight",
            "code": "",
            "auxcode": "BAH",
            "companyName": "BAHRAIN",
            "country": "Bahrain"
        },
        {
            "name": "Aero Services",
            "code": "",
            "auxcode": "BAS",
            "companyName": "AEROSERV",
            "country": "Barbados"
        },
        {
            "name": "Ababeel Aviation",
            "code": "",
            "auxcode": "BBE",
            "companyName": "BABEL AIR",
            "country": "Sudan"
        },
        {
            "name": "Air Bashkortostan",
            "code": "",
            "auxcode": "BBT",
            "companyName": "AGYDAL",
            "country": "Russia"
        },
        {
            "name": "Ambulance Air Africa",
            "code": "",
            "auxcode": "MCY",
            "companyName": "MERCY",
            "country": "South Africa"
        },
        {
            "name": "American Eagle Airlines",
            "code": "MQ",
            "auxcode": "EGF",
            "companyName": "EAGLE FLIGHT",
            "country": "United States"
        },
        {
            "name": "Aeropuelche",
            "code": "",
            "auxcode": "PUE",
            "companyName": "PUELCHE",
            "country": "Chile"
        },
        {
            "name": "Aeroput",
            "code": "",
            "auxcode": "PUT",
            "companyName": "",
            "country": "Serbia"
        },
        {
            "name": "Azzurra Air",
            "code": "",
            "auxcode": "AZI",
            "companyName": "AZZURRA",
            "country": "Italy"
        },
        {
            "name": "Airshop",
            "code": "FF",
            "auxcode": "",
            "companyName": "",
            "country": "Netherlands"
        },
        {
            "name": "African Transport Trading and Investment Company",
            "code": "ML",
            "auxcode": "",
            "companyName": "",
            "country": "Sudan"
        },
        {
            "name": "AD Aviation",
            "code": "",
            "auxcode": "VUE",
            "companyName": "FLIGHTVUE",
            "country": "United Kingdom"
        },
        {
            "name": "Aero Costa Taxi Aereo",
            "code": "",
            "auxcode": "XCT",
            "companyName": "AEROCOSTAXI",
            "country": "Mexico"
        },
        {
            "name": "Aerovitro",
            "code": "",
            "auxcode": "VRO",
            "companyName": "AEROVITRO",
            "country": "Mexico"
        },
        {
            "name": "Aerotaxi Villa Rica",
            "code": "",
            "auxcode": "VRI",
            "companyName": "VILLARICA",
            "country": "Mexico"
        },
        {
            "name": "Aerovega",
            "code": "",
            "auxcode": "VEG",
            "companyName": "AEROVEGA",
            "country": "Mexico"
        },
        {
            "name": "Aerovilla",
            "code": "",
            "auxcode": "VVG",
            "companyName": "AEROVILLA",
            "country": "Colombia"
        },
        {
            "name": "Aerolineas Villaverde",
            "code": "",
            "auxcode": "VLR",
            "companyName": "VILLAVERDE",
            "country": "Mexico"
        },
        {
            "name": "Aero Air",
            "code": "",
            "auxcode": "WIL",
            "companyName": "WILLIAMETTE",
            "country": "United States"
        },
        {
            "name": "Aero Ejecutivos",
            "code": "",
            "auxcode": "VEJ",
            "companyName": "VENEJECUTIV",
            "country": "Venezuela"
        },
        {
            "name": "Aero Industries Inc",
            "code": "",
            "auxcode": "WAB",
            "companyName": "WABASH",
            "country": "United States"
        },
        {
            "name": "Aero Servicios Vanguardia",
            "code": "",
            "auxcode": "VNG",
            "companyName": "VANGUARDIA",
            "country": "Mexico"
        },
        {
            "name": "Aero Taxi Los Valles",
            "code": "",
            "auxcode": "VAD",
            "companyName": "VALLES",
            "country": "Spain"
        },
        {
            "name": "Aero Vilamoura",
            "code": "",
            "auxcode": "VMR",
            "companyName": "AERO VILAMOURA",
            "country": "Portugal"
        },
        {
            "name": "Aero Virel",
            "code": "",
            "auxcode": "VLS",
            "companyName": "VIREL",
            "country": "Mexico"
        },
        {
            "name": "Aeronautical Radio Inc",
            "code": "",
            "auxcode": "XAA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Aerovuelox",
            "code": "",
            "auxcode": "VUO",
            "companyName": "AEROVUELOX",
            "country": "Mexico"
        },
        {
            "name": "Aeronaves TSM",
            "code": "",
            "auxcode": "VTM",
            "companyName": "AERONAVES TSM",
            "country": "Mexico"
        },
        {
            "name": "Air Ivoire",
            "code": "VU",
            "auxcode": "VUN",
            "companyName": "AIRIVOIRE",
            "country": "Ivory Coast"
        },
        {
            "name": "Air Botswana",
            "code": "BP",
            "auxcode": "BOT",
            "companyName": "BOTSWANA",
            "country": "Botswana"
        },
        {
            "name": "AircodeRep",
            "code": "",
            "auxcode": "XPR",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Air Excel",
            "code": "",
            "auxcode": "XLL",
            "companyName": "TINGAcodeTINGA",
            "country": "Tanzania"
        },
        {
            "name": "Air Evans",
            "code": "",
            "auxcode": "VAE",
            "companyName": "AIRcodeEVANS",
            "country": "Spain"
        },
        {
            "name": "Air Sorel",
            "code": "",
            "auxcode": "WHY",
            "companyName": "AIR SOREL",
            "country": "Canada"
        },
        {
            "name": "Air Net Private Charter",
            "code": "",
            "auxcode": "WDR",
            "companyName": "WIND RIDER",
            "country": "United States"
        },
        {
            "name": "Air Executive Charter",
            "code": "",
            "auxcode": "XEC",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Air Foyle",
            "code": "GS",
            "auxcode": "UPA",
            "companyName": "FOYLE",
            "country": "United Kingdom"
        },
        {
            "name": "Air Midwest",
            "code": "",
            "auxcode": "VTY",
            "companyName": "VICTORY",
            "country": "Nigeria"
        },
        {
            "name": "Air Tahiti",
            "code": "VT",
            "auxcode": "VTA",
            "companyName": "AIR TAHITI",
            "country": "French Polynesia"
        },
        {
            "name": "Air Urga",
            "code": "3N",
            "auxcode": "URG",
            "companyName": "URGA",
            "country": "Ukraine"
        },
        {
            "name": "Air Vardar",
            "code": "",
            "auxcode": "VDR",
            "companyName": "VARDAR",
            "country": "Macedonia"
        },
        {
            "name": "Air VIA",
            "code": "VL",
            "auxcode": "VIM",
            "companyName": "",
            "country": "Bulgaria"
        },
        {
            "name": "Air Walser",
            "code": "",
            "auxcode": "WLR",
            "companyName": "AIRWALSER",
            "country": "Italy"
        },
        {
            "name": "Aircompany Rosavia",
            "code": "",
            "auxcode": "URA",
            "companyName": "ROSAVIA",
            "country": "Ukraine"
        },
        {
            "name": "Aircraft Performance Group",
            "code": "",
            "auxcode": "XLB",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Airwaves Airlink",
            "code": "",
            "auxcode": "WLA",
            "companyName": "AIRLIMITED",
            "country": "Zambia"
        },
        {
            "name": "Airways Corporation of New Zealand",
            "code": "",
            "auxcode": "XFX",
            "companyName": "AIRCORP",
            "country": "New Zealand"
        },
        {
            "name": "Airways",
            "code": "",
            "auxcode": "WAY",
            "companyName": "GARONNE",
            "country": "France"
        },
        {
            "name": "Airwings oy",
            "code": "",
            "auxcode": "WGS",
            "companyName": "AIRWINGS",
            "country": "Finland"
        },
        {
            "name": "Airkenya",
            "code": "",
            "auxcode": "XAK",
            "companyName": "SUNEXPRESS",
            "country": "Kenya"
        },
        {
            "name": "AircodeLift Associates",
            "code": "",
            "auxcode": "WPK",
            "companyName": "WOLFPACK",
            "country": "United States"
        },
        {
            "name": "Airtrans Ltd",
            "code": "",
            "auxcode": "VAB",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "ARP 410 Airlines",
            "code": "",
            "auxcode": "URP",
            "companyName": "AIRcodeARP",
            "country": "Ukraine"
        },
        {
            "name": "Auckland Regional Rescue Helicopter Trust",
            "code": "",
            "auxcode": "WPR",
            "companyName": "WESTPAC RESCUE",
            "country": "New Zealand"
        },
        {
            "name": "Aurora Airlines",
            "code": "",
            "auxcode": "URR",
            "companyName": "AIR AURORA",
            "country": "Slovenia"
        },
        {
            "name": "Austro Aereo",
            "code": "",
            "auxcode": "UST",
            "companyName": "AUSTRO AEREO",
            "country": "Ecuador"
        },
        {
            "name": "Aviation Partners",
            "code": "",
            "auxcode": "WLT",
            "companyName": "WINGLET",
            "country": "United States"
        },
        {
            "name": "Avialift Vladivostok",
            "code": "",
            "auxcode": "VLV",
            "companyName": "VLADLIFT",
            "country": "Russia"
        },
        {
            "name": "Aviacion Comercial de America",
            "code": "",
            "auxcode": "VME",
            "companyName": "AVIAMERICA",
            "country": "Mexico"
        },
        {
            "name": "Aviast Air",
            "code": "",
            "auxcode": "VVA",
            "companyName": "IALSI",
            "country": "Russia"
        },
        {
            "name": "Aviation North",
            "code": "",
            "auxcode": "WLV",
            "companyName": "WOLVERINE",
            "country": "United States"
        },
        {
            "name": "Africa West",
            "code": "FK",
            "auxcode": "WTA",
            "companyName": "WEST TOGO",
            "country": "Togo"
        },
        {
            "name": "Avient Air Zambia",
            "code": "",
            "auxcode": "VNT",
            "companyName": "AVIENT",
            "country": "Zambia"
        },
        {
            "name": "Aviazur",
            "code": "",
            "auxcode": "VZR",
            "companyName": "IAZUR",
            "country": "France"
        },
        {
            "name": "Aviaprad",
            "code": "",
            "auxcode": "VID",
            "companyName": "AVIAPRAD",
            "country": "Russia"
        },
        {
            "name": "Avirex",
            "code": "G2",
            "auxcode": "VXG",
            "companyName": "AVIREXcodeGABON",
            "country": "Gabon"
        },
        {
            "name": "Aviaexpress Aircompany",
            "code": "",
            "auxcode": "VXX",
            "companyName": "EXPRESSAVIA",
            "country": "Ukraine"
        },
        {
            "name": "AMR Services Corporation",
            "code": "",
            "auxcode": "XAM",
            "companyName": "ALLIANCE",
            "country": "United States"
        },
        {
            "name": "Airline Operations Services",
            "code": "",
            "auxcode": "XAO",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Airlines 400",
            "code": "",
            "auxcode": "VAZ",
            "companyName": "REMONT AIR",
            "country": "Russia"
        },
        {
            "name": "ATRAN Cargo Airlines",
            "code": "V8",
            "auxcode": "VAS",
            "companyName": "ATRAN",
            "country": "Russian Federation"
        },
        {
            "name": "Ameravia",
            "code": "",
            "auxcode": "VAM",
            "companyName": "AMERAVIA",
            "country": "Uruguay"
        },
        {
            "name": "Angkor Air",
            "code": "",
            "auxcode": "VAV",
            "companyName": "AIR ANGKOR",
            "country": "Cambodia"
        },
        {
            "name": "AVBcode2004 Ltd",
            "code": "",
            "auxcode": "VBC",
            "companyName": "AIR VICTOR",
            "country": "Bulgaria"
        },
        {
            "name": "ASECNA",
            "code": "",
            "auxcode": "XKX",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "AT and T Aviation Division",
            "code": "",
            "auxcode": "XAT",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Avalair",
            "code": "",
            "auxcode": "VAI",
            "companyName": "AIR AVALAIR",
            "country": "Serbia"
        },
        {
            "name": "Averitt Air Charter",
            "code": "",
            "auxcode": "VRT",
            "companyName": "AVERITT",
            "country": "United States"
        },
        {
            "name": "Avensa",
            "code": "VE",
            "auxcode": "AVE",
            "companyName": "Avensa",
            "country": "Venezuela"
        },
        {
            "name": "Avolar Aerolineas",
            "code": "V5",
            "auxcode": "VLI",
            "companyName": "AEROVOLAR",
            "country": "Mexico"
        },
        {
            "name": "Avstar Aviation",
            "code": "",
            "auxcode": "VSA",
            "companyName": "STARBIRD",
            "country": "South Africa"
        },
        {
            "name": "AVESCA",
            "code": "",
            "auxcode": "VSC",
            "companyName": "AVESCA",
            "country": "Colombia"
        },
        {
            "name": "Aviaprom Enterprises",
            "code": "",
            "auxcode": "XAV",
            "companyName": "AVIAPROM",
            "country": "Russia"
        },
        {
            "name": "Avia Trans Air Transport",
            "code": "",
            "auxcode": "VTT",
            "companyName": "VIATRANSPORT",
            "country": "Sudan"
        },
        {
            "name": "Aviostart AS",
            "code": "",
            "auxcode": "VSR",
            "companyName": "AVIOSTART",
            "country": "Bulgaria"
        },
        {
            "name": "Aviacao Transportes Aereos e Cargas",
            "code": "",
            "auxcode": "VTG",
            "companyName": "ATACARGO",
            "country": "Angola"
        },
        {
            "name": "Assistance Aeroportuaire de L'Aeroport de Paris",
            "code": "",
            "auxcode": "XJA",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "American Flight Service Systems",
            "code": "",
            "auxcode": "XFS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "AASANA",
            "code": "",
            "auxcode": "XXV",
            "companyName": "",
            "country": "Bolivia"
        },
        {
            "name": "Australian air Express",
            "code": "",
            "auxcode": "XME",
            "companyName": "AUScodeCARGO",
            "country": "Australia"
        },
        {
            "name": "AMS Group",
            "code": "",
            "auxcode": "XMG",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Caraibes Atlantique",
            "code": "",
            "auxcode": "CAJ",
            "companyName": "CAR LINE",
            "country": "France"
        },
        {
            "name": "Air China Cargo",
            "code": "",
            "auxcode": "CAO",
            "companyName": "AIRCHINA FREIGHT",
            "country": "China"
        },
        {
            "name": "Aerovias Caribe",
            "code": "",
            "auxcode": "CBE",
            "companyName": "AEROCARIBE",
            "country": "Mexico"
        },
        {
            "name": "Aerotaxi del Cabo",
            "code": "",
            "auxcode": "CBO",
            "companyName": "TAXI CABO",
            "country": "Mexico"
        },
        {
            "name": "Air Columbus",
            "code": "",
            "auxcode": "CBS",
            "companyName": "AIR COLUMBUS",
            "country": "Ukraine"
        },
        {
            "name": "Aero Cabo",
            "code": "",
            "auxcode": "CBV",
            "companyName": "CABOAEREO",
            "country": "Mexico"
        },
        {
            "name": "Air China",
            "code": "CA",
            "auxcode": "CCA",
            "companyName": "AIR CHINA",
            "country": "China"
        },
        {
            "name": "Aerocardal",
            "code": "",
            "auxcode": "CDA",
            "companyName": "CARDAL",
            "country": "Chile"
        },
        {
            "name": "Aero Condor Peru",
            "code": "Q6",
            "auxcode": "CDP",
            "companyName": "CONDORcodePERU",
            "country": "Peru"
        },
        {
            "name": "Aerotrans",
            "code": "",
            "auxcode": "CDU",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Airline Skol",
            "code": "",
            "auxcode": "CDV",
            "companyName": "SKOL",
            "country": "Russia"
        },
        {
            "name": "Aerofan",
            "code": "",
            "auxcode": "CFF",
            "companyName": "AEROFAN",
            "country": "Spain"
        },
        {
            "name": "ACEF",
            "code": "",
            "auxcode": "CFM",
            "companyName": "ACEF",
            "country": "Portugal"
        },
        {
            "name": "Africa One",
            "code": "",
            "auxcode": "CFR",
            "companyName": "",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Aero Calafia",
            "code": "",
            "auxcode": "CFV",
            "companyName": "CALAFIA",
            "country": "Mexico"
        },
        {
            "name": "Air Cargo Belize",
            "code": "",
            "auxcode": "CGB",
            "companyName": "CARGO BELIZE",
            "country": "Belize"
        },
        {
            "name": "Aero Clube Do Algarve",
            "code": "",
            "auxcode": "CGV",
            "companyName": "CLUBE ALGARVE",
            "country": "Portugal"
        },
        {
            "name": "Air Great Wall",
            "code": "",
            "auxcode": "CGW",
            "companyName": "CHANGCHENG",
            "country": "China"
        },
        {
            "name": "Aircompany Chaika",
            "code": "",
            "auxcode": "CHJ",
            "companyName": "AIR CHAIKA",
            "country": "Ukraine"
        },
        {
            "name": "Air Charter Services",
            "code": "",
            "auxcode": "CHR",
            "companyName": "ZAIRE CHARTER",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Air Charter Professionals",
            "code": "",
            "auxcode": "CHV",
            "companyName": "CHARTAIR",
            "country": "United States"
        },
        {
            "name": "Asia Continental Airlines",
            "code": "",
            "auxcode": "CID",
            "companyName": "ACID",
            "country": "Kazakhstan"
        },
        {
            "name": "Arctic Circle Air Service",
            "code": "5F",
            "auxcode": "CIR",
            "companyName": "AIR ARCTIC",
            "country": "United States"
        },
        {
            "name": "Aviation Charter Services",
            "code": "",
            "auxcode": "CKL",
            "companyName": "CIRCLE CITY",
            "country": "United States"
        },
        {
            "name": "Aerovias Castillo",
            "code": "",
            "auxcode": "CLL",
            "companyName": "AEROCASTILLO",
            "country": "Mexico"
        },
        {
            "name": "Aero Club De Portugal",
            "code": "",
            "auxcode": "CLP",
            "companyName": "CLUB PORTUGAL",
            "country": "Portugal"
        },
        {
            "name": "Air Care Alliance",
            "code": "",
            "auxcode": "CMF",
            "companyName": "COMPASSION",
            "country": "United States"
        },
        {
            "name": "Aero Continente Dominicana",
            "code": "",
            "auxcode": "CND",
            "companyName": "CONDOMINICANA",
            "country": "Dominican Republic"
        },
        {
            "name": "Air Toronto",
            "code": "",
            "auxcode": "CNE",
            "companyName": "CONNECTOR",
            "country": "Canada"
        },
        {
            "name": "Aquila Air",
            "code": "",
            "auxcode": "CNH",
            "companyName": "CHENANGO",
            "country": "United States"
        },
        {
            "name": "Air Consul",
            "code": "",
            "auxcode": "CNU",
            "companyName": "AIR CONSUL",
            "country": "Spain"
        },
        {
            "name": "Allcanada Express",
            "code": "",
            "auxcode": "CNX",
            "companyName": "CANEX",
            "country": "Canada"
        },
        {
            "name": "ATS Private Company",
            "code": "",
            "auxcode": "CPF",
            "companyName": "TECHSERVICE",
            "country": "Ukraine"
        },
        {
            "name": "Air Corridor",
            "code": "QC",
            "auxcode": "CRD",
            "companyName": "AIR CORRIDOR",
            "country": "Mozambique"
        },
        {
            "name": "Air Central",
            "code": "NV",
            "auxcode": "CRF",
            "companyName": "AIR CENTRAL",
            "country": "Japan"
        },
        {
            "name": "Air Cruzal",
            "code": "",
            "auxcode": "CRJ",
            "companyName": "AIR CRUZAL",
            "country": "Angola"
        },
        {
            "name": "Aerotransportes Corporativos",
            "code": "",
            "auxcode": "CRP",
            "companyName": "AEROTRANSCORP",
            "country": "Mexico"
        },
        {
            "name": "Air Creebec",
            "code": "",
            "auxcode": "CRQ",
            "companyName": "CREE",
            "country": "Canada"
        },
        {
            "name": "Aero Charter and Transport",
            "code": "",
            "auxcode": "CTA",
            "companyName": "CHARcodeTRAN",
            "country": "United States"
        },
        {
            "name": "Air Tenglong",
            "code": "",
            "auxcode": "CTE",
            "companyName": "TENGLONG",
            "country": "China"
        },
        {
            "name": "Aerlineas Centauro",
            "code": "",
            "auxcode": "CTR",
            "companyName": "CENTAURO",
            "country": "Mexico"
        },
        {
            "name": "Aerocuahonte",
            "code": "",
            "auxcode": "CUO",
            "companyName": "CUAHONTE",
            "country": "Mexico"
        },
        {
            "name": "Air Chathams",
            "code": "CV",
            "auxcode": "CVA",
            "companyName": "CHATHAM",
            "country": "New Zealand"
        },
        {
            "name": "Air Marshall Islands",
            "code": "CW",
            "auxcode": "CWM",
            "companyName": "AIR MARSHALLS",
            "country": "Marshall Islands"
        },
        {
            "name": "Australian Customs Service",
            "code": "",
            "auxcode": "CWP",
            "companyName": "COASTWATCH",
            "country": "Australia"
        },
        {
            "name": "Air One Cityliner",
            "code": "",
            "auxcode": "CYL",
            "companyName": "CITYLINER",
            "country": "Italy"
        },
        {
            "name": "Air Transport",
            "code": "",
            "auxcode": "CYO",
            "companyName": "COYOTE",
            "country": "United States"
        },
        {
            "name": "Access Air",
            "code": "ZA",
            "auxcode": "CYD",
            "companyName": "CYCLONE",
            "country": "United States"
        },
        {
            "name": "Aerocheyenne",
            "code": "",
            "auxcode": "CYE",
            "companyName": "AEROCHEYENNE",
            "country": "Mexico"
        },
        {
            "name": "Air Algerie",
            "code": "AH",
            "auxcode": "DAH",
            "companyName": "AIR ALGERIE",
            "country": "Algeria"
        },
        {
            "name": "Aerovias DAP",
            "code": "",
            "auxcode": "DAP",
            "companyName": "DAP",
            "country": "Chile"
        },
        {
            "name": "Air Alpha",
            "code": "",
            "auxcode": "DBA",
            "companyName": "DOUBLEcodeA",
            "country": "United States"
        },
        {
            "name": "Air Niagara Express",
            "code": "",
            "auxcode": "DBD",
            "companyName": "AIR NIAGARA",
            "country": "Canada"
        },
        {
            "name": "Aviation Defense Service",
            "code": "",
            "auxcode": "DEF",
            "companyName": "TIRPA",
            "country": "France"
        },
        {
            "name": "Air Service Groningen",
            "code": "",
            "auxcode": "DEG",
            "companyName": "DEGGER",
            "country": "Netherlands"
        },
        {
            "name": "Adam Air",
            "code": "KI",
            "auxcode": "DHI",
            "companyName": "ADAM SKY",
            "country": "Indonesia"
        },
        {
            "name": "Astar Air Cargo",
            "code": "ER",
            "auxcode": "DHL",
            "companyName": "DAHL",
            "country": "United States"
        },
        {
            "name": "Archer Aviation",
            "code": "",
            "auxcode": "DHM",
            "companyName": "ARCHER",
            "country": "United Kingdom"
        },
        {
            "name": "Aeromedica",
            "code": "",
            "auxcode": "DIC",
            "companyName": "AEROMEDICA",
            "country": "Mexico"
        },
        {
            "name": "Aerodin",
            "code": "",
            "auxcode": "DIN",
            "companyName": "AERODIN",
            "country": "Mexico"
        },
        {
            "name": "Antinea Airlines",
            "code": "HO",
            "auxcode": "DJA",
            "companyName": "ANTINEA",
            "country": "Algeria"
        },
        {
            "name": "Air Djibouti",
            "code": "",
            "auxcode": "DJU",
            "companyName": "AIR DJIB",
            "country": "Djibouti"
        },
        {
            "name": "Air Dolomiti",
            "code": "EN",
            "auxcode": "DLA",
            "companyName": "DOLOMOTI",
            "country": "Italy"
        },
        {
            "name": "Aero Modelo",
            "code": "",
            "auxcode": "DLS",
            "companyName": "AEROMODELO",
            "country": "Mexico"
        },
        {
            "name": "Aerolineas Del Sur",
            "code": "",
            "auxcode": "DLU",
            "companyName": "DEL SUR",
            "country": "Chile"
        },
        {
            "name": "Aerodinamica de Monterrey",
            "code": "",
            "auxcode": "DMC",
            "companyName": "DINAMICAMONT",
            "country": "Mexico"
        },
        {
            "name": "Aeroservicios Dinamicos",
            "code": "",
            "auxcode": "DMI",
            "companyName": "AERODINAMICO",
            "country": "Mexico"
        },
        {
            "name": "Aerotaxis Dosmil",
            "code": "",
            "auxcode": "DML",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "Aerodespachos De El Salvador",
            "code": "",
            "auxcode": "DNA",
            "companyName": "AERODESPACHOS",
            "country": "El Salvador"
        },
        {
            "name": "Aeroynamics Malaga",
            "code": "",
            "auxcode": "DNC",
            "companyName": "FLYINGOLIVE",
            "country": "Spain"
        },
        {
            "name": "Aerodynamics Incorporated",
            "code": "",
            "auxcode": "DNJ",
            "companyName": "DYNAJET",
            "country": "United States"
        },
        {
            "name": "AeroflotcodeDon",
            "code": "D9",
            "auxcode": "DNV",
            "companyName": "DONAVIA",
            "country": "Russia"
        },
        {
            "name": "Air Madrid",
            "code": "NM",
            "auxcode": "DRD",
            "companyName": "ALADA AIR",
            "country": "Spain"
        },
        {
            "name": "Airways Flight Training",
            "code": "",
            "auxcode": "DRM",
            "companyName": "DARTMOOR",
            "country": "United Kingdom"
        },
        {
            "name": "Aeronaves Del Noreste",
            "code": "",
            "auxcode": "DRO",
            "companyName": "AERONORESTE",
            "country": "Mexico"
        },
        {
            "name": "Addis Air Cargo Services",
            "code": "",
            "auxcode": "DSC",
            "companyName": "ADDIS CARGO",
            "country": "Ethiopia"
        },
        {
            "name": "Aero Algarve",
            "code": "",
            "auxcode": "DSK",
            "companyName": "SKYBANNER",
            "country": "Portugal"
        },
        {
            "name": "Aex Air",
            "code": "",
            "auxcode": "DST",
            "companyName": "DESERT",
            "country": "United States"
        },
        {
            "name": "Aero Davinci International",
            "code": "",
            "auxcode": "DVI",
            "companyName": "AERO DAVINCI",
            "country": "Mexico"
        },
        {
            "name": "Aero Dynamics",
            "code": "",
            "auxcode": "DYN",
            "companyName": "AERO DYNAMIC",
            "country": "United States"
        },
        {
            "name": "Aeroservicios Ecuatorianos",
            "code": "",
            "auxcode": "EAE",
            "companyName": "AECA",
            "country": "Ecuador"
        },
        {
            "name": "AerocodePyrenees",
            "code": "",
            "auxcode": "EAP",
            "companyName": "AEROcodePYRENEES",
            "country": "France"
        },
        {
            "name": "Air Transport",
            "code": "",
            "auxcode": "EAT",
            "companyName": "TRANS EUROPE",
            "country": "Slovakia"
        },
        {
            "name": "Aero Airlines",
            "code": "EE",
            "auxcode": "EAY",
            "companyName": "REVAL",
            "country": "Estonia"
        },
        {
            "name": "Aero Ejecutivo De Baja California",
            "code": "",
            "auxcode": "EBC",
            "companyName": "CALIXJET",
            "country": "Mexico"
        },
        {
            "name": "Air City",
            "code": "4F",
            "auxcode": "ECE",
            "companyName": "AIRCITY",
            "country": "Germany"
        },
        {
            "name": "Aero Ejecutivos RCG",
            "code": "",
            "auxcode": "ECG",
            "companyName": "EJECTUIVOS RCG",
            "country": "Mexico"
        },
        {
            "name": "Aeronautica Castellana",
            "code": "",
            "auxcode": "ECL",
            "companyName": "AERO CASTELLANA",
            "country": "Spain"
        },
        {
            "name": "Aerolineas Comerciales",
            "code": "",
            "auxcode": "ECM",
            "companyName": "AERO COMERCIALES",
            "country": "Mexico"
        },
        {
            "name": "Aerolineas Nacionales Del Ecuador",
            "code": "",
            "auxcode": "EDA",
            "companyName": "ANDES",
            "country": "Ecuador"
        },
        {
            "name": "Air Este",
            "code": "",
            "auxcode": "EET",
            "companyName": "AESTE",
            "country": "Spain"
        },
        {
            "name": "Air Mana",
            "code": "",
            "auxcode": "EFC",
            "companyName": "FLIGHT TAXI",
            "country": "France"
        },
        {
            "name": "Aer Lingus",
            "code": "EI",
            "auxcode": "EIN",
            "companyName": "SHAMROCK",
            "country": "Ireland"
        },
        {
            "name": "Aeroservicios Ejecutivos Corporativos",
            "code": "",
            "auxcode": "EJP",
            "companyName": "EJECCORPORATIVOS",
            "country": "Mexico"
        },
        {
            "name": "Alpi Eagles",
            "code": "E8",
            "auxcode": "ELG",
            "companyName": "ALPI EAGLES",
            "country": "Italy"
        },
        {
            "name": "Arrendoora y Transportadora Aerea",
            "code": "",
            "auxcode": "END",
            "companyName": "ARRENDADORA",
            "country": "Mexico"
        },
        {
            "name": "Eliossola",
            "code": "",
            "auxcode": "EOS",
            "companyName": "ELIOSSOLA",
            "country": "Italy"
        },
        {
            "name": "Shenzhen Donghai Airlines",
            "code": "",
            "auxcode": "EPA",
            "companyName": "DONGHAI AIR",
            "country": "China"
        },
        {
            "name": "Aeronaves Del Noroeste",
            "code": "",
            "auxcode": "ENW",
            "companyName": "AERONOR",
            "country": "Spain"
        },
        {
            "name": "Airailes",
            "code": "",
            "auxcode": "EOL",
            "companyName": "EOLE",
            "country": "France"
        },
        {
            "name": "Aero Ermes",
            "code": "",
            "auxcode": "EOM",
            "companyName": "AERO ERMES",
            "country": "Mexico"
        },
        {
            "name": "Aero Transportes Empresariales",
            "code": "",
            "auxcode": "EPL",
            "companyName": "EMPRESARIALES",
            "country": "Mexico"
        },
        {
            "name": "Epps Air Service",
            "code": "",
            "auxcode": "EPS",
            "companyName": "EPPS AIR",
            "country": "United States"
        },
        {
            "name": "Aero Empresarial",
            "code": "",
            "auxcode": "EPE",
            "companyName": "AEROEMPRESARIAL",
            "country": "Mexico"
        },
        {
            "name": "Air S",
            "code": "KY",
            "auxcode": "EQL",
            "companyName": "EQUATORIAL",
            "country": "Air S"
        },
        {
            "name": "Avianergo",
            "code": "",
            "auxcode": "ERG",
            "companyName": "AVIANERGO",
            "country": "Russia"
        },
        {
            "name": "Aero Servicios Regiomontanos",
            "code": "",
            "auxcode": "ERI",
            "companyName": "ASERGIO",
            "country": "Mexico"
        },
        {
            "name": "Aerosec",
            "code": "",
            "auxcode": "ERK",
            "companyName": "AEROSEC",
            "country": "Chile"
        },
        {
            "name": "Aeromaan",
            "code": "",
            "auxcode": "ERM",
            "companyName": "EOMAAN",
            "country": "Mexico"
        },
        {
            "name": "Aerosaba",
            "code": "",
            "auxcode": "ESB",
            "companyName": "AEROSABA",
            "country": "Mexico"
        },
        {
            "name": "Avitat",
            "code": "",
            "auxcode": "ESO",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Aerolineas Ejecutivas Del Sureste",
            "code": "",
            "auxcode": "ESU",
            "companyName": "ALESUR",
            "country": "Mexico"
        },
        {
            "name": "Aeronautica Le Esperanza",
            "code": "",
            "auxcode": "ESZ",
            "companyName": "ESPERANZA",
            "country": "Mexico"
        },
        {
            "name": "ATTICO",
            "code": "",
            "auxcode": "ETC",
            "companyName": "TRANATTICO",
            "country": "Sudan"
        },
        {
            "name": "Aero Siete",
            "code": "",
            "auxcode": "ETE",
            "companyName": "AEROSIETE",
            "country": "Mexico"
        },
        {
            "name": "Air Atlanta Europe",
            "code": "",
            "auxcode": "EUK",
            "companyName": "SNOWBIRD",
            "country": "United Kingdom"
        },
        {
            "name": "Air Evex",
            "code": "",
            "auxcode": "EVE",
            "companyName": "SUNBEAM",
            "country": "Germany"
        },
        {
            "name": "Aeronautical Academy of Europe",
            "code": "",
            "auxcode": "EVR",
            "companyName": "DIANA",
            "country": "Portugal"
        },
        {
            "name": "Air Exchange",
            "code": "",
            "auxcode": "EXG",
            "companyName": "EXCHANGE",
            "country": "United States"
        },
        {
            "name": "Atlantic Helicopters",
            "code": "",
            "auxcode": "FAC",
            "companyName": "FAROECOPTER",
            "country": "Denmark"
        },
        {
            "name": "Argentine Air Force",
            "code": "",
            "auxcode": "FAG",
            "companyName": "FUAER",
            "country": "Argentina"
        },
        {
            "name": "Air Fiji",
            "code": "PC",
            "auxcode": "FAJ",
            "companyName": "FIJIAIR",
            "country": "Fiji"
        },
        {
            "name": "AFcodeAir International",
            "code": "",
            "auxcode": "FAN",
            "companyName": "FANBIRD",
            "country": "Angola"
        },
        {
            "name": "Aviation Data Systems",
            "code": "",
            "auxcode": "FBW",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Air Carriers",
            "code": "",
            "auxcode": "FCI",
            "companyName": "FAST CHECK",
            "country": "United States"
        },
        {
            "name": "Aerofrisco",
            "code": "",
            "auxcode": "FCO",
            "companyName": "AEROFRISCO",
            "country": "Mexico"
        },
        {
            "name": "Alfa 4",
            "code": "",
            "auxcode": "FCU",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "African Airlines",
            "code": "",
            "auxcode": "FDA",
            "companyName": "AIR SANKORE",
            "country": "Mali"
        },
        {
            "name": "African Medical and Research Foundation",
            "code": "",
            "auxcode": "FDS",
            "companyName": "FLYDOC",
            "country": "Kenya"
        },
        {
            "name": "Aero Freight",
            "code": "",
            "auxcode": "FGT",
            "companyName": "FREIAERO",
            "country": "Mexico"
        },
        {
            "name": "Aerosafin",
            "code": "",
            "auxcode": "FIC",
            "companyName": "AEROSAFIN",
            "country": "Mexico"
        },
        {
            "name": "Air Finland",
            "code": "OF",
            "auxcode": "FIF",
            "companyName": "AIR FINLAND",
            "country": "Finland"
        },
        {
            "name": "Aerodata Flight Inspection",
            "code": "",
            "auxcode": "FII",
            "companyName": "FLIGHT CHECKER",
            "country": "Germany"
        },
        {
            "name": "Airfix Aviation",
            "code": "",
            "auxcode": "FIX",
            "companyName": "AIRFIX",
            "country": "Finland"
        },
        {
            "name": "Air Pacific",
            "code": "FJ",
            "auxcode": "FJI",
            "companyName": "PACIFIC",
            "country": "Fiji"
        },
        {
            "name": "Air Falcon",
            "code": "",
            "auxcode": "FLD",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Atlantic Airways",
            "code": "RC",
            "auxcode": "FLI",
            "companyName": "FAROELINE",
            "country": "Faroe Islands"
        },
        {
            "name": "Air Florida",
            "code": "QH",
            "auxcode": "FLZ",
            "companyName": "AIR FLORIDA",
            "country": "United States"
        },
        {
            "name": "Air Fret De Mauritanie",
            "code": "",
            "auxcode": "FMT",
            "companyName": "",
            "country": "Mauritania"
        },
        {
            "name": "Avio Nord",
            "code": "",
            "auxcode": "FNM",
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Aeroflota Del Noroeste",
            "code": "",
            "auxcode": "FNO",
            "companyName": "RIAZOR",
            "country": "Spain"
        },
        {
            "name": "Aero Fenix",
            "code": "",
            "auxcode": "FNX",
            "companyName": "AERO FENIX",
            "country": "France"
        },
        {
            "name": "Ford Motor Company",
            "code": "",
            "auxcode": "FRD",
            "companyName": "FORD",
            "country": "United States"
        },
        {
            "name": "African Company Airlines",
            "code": "",
            "auxcode": "FPY",
            "companyName": "AFRICOMPANY",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Afrijet Airlines",
            "code": "",
            "auxcode": "FRJ",
            "companyName": "AFRIJET",
            "country": "Nigeria"
        },
        {
            "name": "Afrika Aviation Handlers",
            "code": "",
            "auxcode": "FRK",
            "companyName": "AFRIFAST",
            "country": "Kenya"
        },
        {
            "name": "Afrique Chart'air",
            "code": "",
            "auxcode": "FRQ",
            "companyName": "CHARTER AFRIQUE",
            "country": "Cameroon"
        },
        {
            "name": "Aerofreight Airlines",
            "code": "",
            "auxcode": "FRT",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Aereos Limited",
            "code": "",
            "auxcode": "FST",
            "companyName": "FAST TRACK",
            "country": "United Kingdom"
        },
        {
            "name": "Air Affaires Tchad",
            "code": "",
            "auxcode": "FTC",
            "companyName": "AFFAIRES TCHAD",
            "country": "Chad"
        },
        {
            "name": "ABC Bedarsflug",
            "code": "",
            "auxcode": "FTY",
            "companyName": "FLY TYROL",
            "country": "Austria"
        },
        {
            "name": "Air Iceland",
            "code": "NY",
            "auxcode": "FXI",
            "companyName": "FAXI",
            "country": "Iceland"
        },
        {
            "name": "Air Philippines",
            "code": "2P",
            "auxcode": "GAP",
            "companyName": "ORIENT PACIFIC",
            "country": "Philippines"
        },
        {
            "name": "Aerogaucho",
            "code": "",
            "auxcode": "GAU",
            "companyName": "AEROGAUCHO",
            "country": "Uruguay"
        },
        {
            "name": "Aero Business Charter",
            "code": "",
            "auxcode": "GBJ",
            "companyName": "GLOBAL JET",
            "country": "Germany"
        },
        {
            "name": "Atlantic Airlines",
            "code": "",
            "auxcode": "GBN",
            "companyName": "ATLANTIC GABON",
            "country": "Gabon"
        },
        {
            "name": "Aeronor",
            "code": "",
            "auxcode": "GCF",
            "companyName": "AEROCARTO",
            "country": "Spain"
        },
        {
            "name": "Aerogem Cargo",
            "code": "",
            "auxcode": "GCK",
            "companyName": "AEROGEM",
            "country": "Ghana"
        },
        {
            "name": "Aerovias del Golfo",
            "code": "",
            "auxcode": "GFO",
            "companyName": "AEROVIAS GOLFO",
            "country": "Mexico"
        },
        {
            "name": "Aeronautica",
            "code": "",
            "auxcode": "GGL",
            "companyName": "GIRA GLOBO",
            "country": "Angola"
        },
        {
            "name": "Air Georgian",
            "code": "ZX",
            "auxcode": "GGN",
            "companyName": "GEORGIAN",
            "country": "Canada"
        },
        {
            "name": "Aviance",
            "code": "",
            "auxcode": "GHL",
            "companyName": "HANDLING",
            "country": "United Kingdom"
        },
        {
            "name": "Air Ghana",
            "code": "",
            "auxcode": "GHN",
            "companyName": "AIR GHANA",
            "country": "Ghana"
        },
        {
            "name": "African International Transport",
            "code": "",
            "auxcode": "GIL",
            "companyName": "AFRICAN TRANSPORT",
            "country": "Guinea"
        },
        {
            "name": "Air Guinee Express",
            "code": "2U",
            "auxcode": "GIP",
            "companyName": "FUTURE EXPRESS",
            "country": "Guinea"
        },
        {
            "name": "Africa Airlines",
            "code": "",
            "auxcode": "GIZ",
            "companyName": "AFRILENS",
            "country": "Guinea"
        },
        {
            "name": "Air Gemini",
            "code": "",
            "auxcode": "GLL",
            "companyName": "TWINS",
            "country": "Angola"
        },
        {
            "name": "Aero Charter",
            "code": "",
            "auxcode": "GLT",
            "companyName": "GASLIGHT",
            "country": "United States"
        },
        {
            "name": "Aguilas Mayas Internacional",
            "code": "",
            "auxcode": "GME",
            "companyName": "MAYAN EAGLES",
            "country": "Guatemala"
        },
        {
            "name": "Aerotaxis Guamuchil",
            "code": "",
            "auxcode": "GMM",
            "companyName": "AEROGUAMUCHIL",
            "country": "Mexico"
        },
        {
            "name": "Aeroservicios Gama",
            "code": "",
            "auxcode": "GMS",
            "companyName": "SERVICIOS GAMA",
            "country": "Mexico"
        },
        {
            "name": "Amber Air",
            "code": "0A",
            "auxcode": "GNT",
            "companyName": "GINTA",
            "country": "Lithuania"
        },
        {
            "name": "Alberta Government",
            "code": "",
            "auxcode": "GOA",
            "companyName": "ALBERTA",
            "country": "Canada"
        },
        {
            "name": "Air Scotland",
            "code": "",
            "auxcode": "GRE",
            "companyName": "GREECE AIRWAYS",
            "country": "Greece"
        },
        {
            "name": "Air Georgia",
            "code": "DA",
            "auxcode": "GRG",
            "companyName": "AIR GEORGIA",
            "country": "Georgia"
        },
        {
            "name": "Air Cargo Center",
            "code": "",
            "auxcode": "GRI",
            "companyName": "",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "Air Greenland",
            "code": "GL",
            "auxcode": "GRL",
            "companyName": "GREENLAND",
            "country": "Denmark"
        },
        {
            "name": "Allegro",
            "code": "LL",
            "auxcode": "GRO",
            "companyName": "ALLEGRO",
            "country": "Mexico"
        },
        {
            "name": "Agroar code Trabalhos Aereos",
            "code": "",
            "auxcode": "GRR",
            "companyName": "AGROAR",
            "country": "Portugal"
        },
        {
            "name": "Aircompany Grodno",
            "code": "",
            "auxcode": "GRX",
            "companyName": "GRODNO",
            "country": "Belarus"
        },
        {
            "name": "Airlift Alaska",
            "code": "",
            "auxcode": "GSP",
            "companyName": "GREEN SPEED",
            "country": "United States"
        },
        {
            "name": "AgrocentrcodeAvia",
            "code": "",
            "auxcode": "GSV",
            "companyName": "AGRAV",
            "country": "Kazakhstan"
        },
        {
            "name": "Altin Havayolu Tasimaciligi Turizm Ve Ticaret",
            "code": "",
            "auxcode": "GTC",
            "companyName": "GOLDEN WINGS",
            "country": "Turkey"
        },
        {
            "name": "Atlas Air",
            "code": "5Y",
            "auxcode": "GTI",
            "companyName": "GIANT",
            "country": "United States"
        },
        {
            "name": "Aerotaxi Grupo Tampico",
            "code": "",
            "auxcode": "GTP",
            "companyName": "GRUPOTAMPICO",
            "country": "Mexico"
        },
        {
            "name": "Aerotaxis de Aguascalientes",
            "code": "",
            "auxcode": "GUA",
            "companyName": "AGUASCALIENTES",
            "country": "Mexico"
        },
        {
            "name": "Air Guyane",
            "code": "GG",
            "auxcode": "GUY",
            "companyName": "GREEN BIRD",
            "country": "French Guiana"
        },
        {
            "name": "Air Victoria Georgia",
            "code": "",
            "auxcode": "GVI",
            "companyName": "IRINA",
            "country": "Georgia"
        },
        {
            "name": "Air D'Ayiti",
            "code": "H9",
            "auxcode": "HAD",
            "companyName": "HAITI AVIA",
            "country": "Haiti"
        },
        {
            "name": "Air Comores International",
            "code": "GG",
            "auxcode": "HAH",
            "companyName": "AIR COMORES",
            "country": "Comoros"
        },
        {
            "name": "Air Taxi",
            "code": "",
            "auxcode": "HAT",
            "companyName": "TAXI BIRD",
            "country": "France"
        },
        {
            "name": "Aerohein",
            "code": "",
            "auxcode": "HEI",
            "companyName": "AEROHEIN",
            "country": "Chile"
        },
        {
            "name": "Atlantic Air Lift",
            "code": "",
            "auxcode": "HGH",
            "companyName": "HIGHER",
            "country": "France"
        },
        {
            "name": "Atlantic Airlines de Honduras",
            "code": "",
            "auxcode": "HHA",
            "companyName": "ATLANTIC HONDURAS",
            "country": "Honduras"
        },
        {
            "name": "Aviacion Ejecutiva De Hildago",
            "code": "",
            "auxcode": "HID",
            "companyName": "EJECUTIVA HIDALGO",
            "country": "Mexico"
        },
        {
            "name": "Air Haiti",
            "code": "",
            "auxcode": "HJA",
            "companyName": "AIRHAITI",
            "country": "Haiti"
        },
        {
            "name": "Al Rais Cargo",
            "code": "",
            "auxcode": "HJT",
            "companyName": "ALcodeRAIS CARGO",
            "country": "United Arab Emirates"
        },
        {
            "name": "AircodeInvest",
            "code": "",
            "auxcode": "HKH",
            "companyName": "HAWKHUNGARY",
            "country": "Hungary"
        },
        {
            "name": "Air Tahoma",
            "code": "",
            "auxcode": "HMA",
            "companyName": "TAHOMA",
            "country": "United States"
        },
        {
            "name": "Air Nova",
            "code": "",
            "auxcode": "HMT",
            "companyName": "HAMILTON",
            "country": "Canada"
        },
        {
            "name": "Aero Homex",
            "code": "",
            "auxcode": "HOM",
            "companyName": "AERO HOMEX",
            "country": "Mexico"
        },
        {
            "name": "Almiron Aviation",
            "code": "",
            "auxcode": "HPO",
            "companyName": "ALMIRON",
            "country": "Uganda"
        },
        {
            "name": "Avinor",
            "code": "",
            "auxcode": "HQO",
            "companyName": "",
            "country": "Norway"
        },
        {
            "name": "Airlink Airways",
            "code": "",
            "auxcode": "HYR",
            "companyName": "HIGHFLYER",
            "country": "Ireland"
        },
        {
            "name": "Air Horizon",
            "code": "8C",
            "auxcode": "HZT",
            "companyName": "HORIZON TOGO",
            "country": "Togo"
        },
        {
            "name": "AC InsatcodeAero",
            "code": "",
            "auxcode": "IAE",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Inter Cameroun",
            "code": "",
            "auxcode": "ICM",
            "companyName": "INTERcodeCAMEROUN",
            "country": "Cameroon"
        },
        {
            "name": "Air Lift",
            "code": "",
            "auxcode": "IFI",
            "companyName": "HELLAS LIFT",
            "country": "Greece"
        },
        {
            "name": "Aero Survey",
            "code": "",
            "auxcode": "IKM",
            "companyName": "GHANA SURVEY",
            "country": "Ghana"
        },
        {
            "name": "Aero Airline",
            "code": "",
            "auxcode": "ILK",
            "companyName": "ILEK",
            "country": "Kazakhstan"
        },
        {
            "name": "Airtime Charters",
            "code": "",
            "auxcode": "IME",
            "companyName": "AIRTIME",
            "country": "United Kingdom"
        },
        {
            "name": "Aerotaxis Cimarron",
            "code": "",
            "auxcode": "IMN",
            "companyName": "TAXI CIMARRON",
            "country": "Mexico"
        },
        {
            "name": "Aero International",
            "code": "",
            "auxcode": "INA",
            "companyName": "AEROcodeNACIONAL",
            "country": "Mexico"
        },
        {
            "name": "Aeroingenieria",
            "code": "",
            "auxcode": "ING",
            "companyName": "AEROINGE",
            "country": "Chile"
        },
        {
            "name": "Aeroservicios Intergrados de Norte",
            "code": "",
            "auxcode": "INO",
            "companyName": "INTENOR",
            "country": "Mexico"
        },
        {
            "name": "Airpull Aviation",
            "code": "",
            "auxcode": "IPL",
            "companyName": "IPULL",
            "country": "Spain"
        },
        {
            "name": "Arvand Airlines",
            "code": "",
            "auxcode": "IRD",
            "companyName": "ARVAND",
            "country": "Iran"
        },
        {
            "name": "Atlas Aviation Group",
            "code": "",
            "auxcode": "IRH",
            "companyName": "ATLAS AVIA",
            "country": "Iran"
        },
        {
            "name": "Aram Airline",
            "code": "",
            "auxcode": "IRW",
            "companyName": "ARAM",
            "country": "Iran"
        },
        {
            "name": "Aria Tour",
            "code": "",
            "auxcode": "IRX",
            "companyName": "ARIA",
            "country": "Iran"
        },
        {
            "name": "Aerotaxi",
            "code": "",
            "auxcode": "ITE",
            "companyName": "AEROTAXI",
            "country": "Czech Republic"
        },
        {
            "name": "AvitacodeServicos Aereos",
            "code": "",
            "auxcode": "ITF",
            "companyName": "AIR AVITA",
            "country": "Angola"
        },
        {
            "name": "Aero Citro",
            "code": "",
            "auxcode": "ITO",
            "companyName": "AERO CITRO",
            "country": "Mexico"
        },
        {
            "name": "Air Executive",
            "code": "",
            "auxcode": "IVE",
            "companyName": "COMPANY EXEC",
            "country": "Spain"
        },
        {
            "name": "Aviainvest",
            "code": "",
            "auxcode": "IWS",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Bagan",
            "code": "W9",
            "auxcode": "JAB",
            "companyName": "AIR BAGAN",
            "country": "Myanmar"
        },
        {
            "name": "Aerojal",
            "code": "",
            "auxcode": "JAD",
            "companyName": "AEROJAL",
            "country": "Mexico"
        },
        {
            "name": "Airlink",
            "code": "",
            "auxcode": "JAR",
            "companyName": "AIRLINK",
            "country": "Austria"
        },
        {
            "name": "Ambjek Air Services",
            "code": "",
            "auxcode": "JEE",
            "companyName": "AMBJEK AIR",
            "country": "Nigeria"
        },
        {
            "name": "Avfinity",
            "code": "",
            "auxcode": "UTX",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Alexandair",
            "code": "",
            "auxcode": "JMR",
            "companyName": "ALEXANDAIR",
            "country": "Canada"
        },
        {
            "name": "Air Jamaica Express",
            "code": "",
            "auxcode": "JMX",
            "companyName": "JAMAICA EXPRESS",
            "country": "Jamaica"
        },
        {
            "name": "Air Swift Aviation",
            "code": "",
            "auxcode": "JOA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Aerojobeni",
            "code": "",
            "auxcode": "JOB",
            "companyName": "JOBENI",
            "country": "Mexico"
        },
        {
            "name": "Atyrau Air Ways",
            "code": "IP",
            "auxcode": "JOL",
            "companyName": "EDIL",
            "country": "Kazakhstan"
        },
        {
            "name": "Aerosmith Aviation",
            "code": "",
            "auxcode": "JPR",
            "companyName": "JASPER",
            "country": "United States"
        },
        {
            "name": "Arrendamiento de Aviones Jets",
            "code": "",
            "auxcode": "JTS",
            "companyName": "AVIONESJETS",
            "country": "Mexico"
        },
        {
            "name": "Aero Juarez",
            "code": "",
            "auxcode": "JUA",
            "companyName": "JUAREZ",
            "country": "Mexico"
        },
        {
            "name": "Air Canada Jazz",
            "code": "QK",
            "auxcode": "JZA",
            "companyName": "JAZZ",
            "country": "Canada"
        },
        {
            "name": "Asia Aero Survey and Consulting Engineers",
            "code": "",
            "auxcode": "KAA",
            "companyName": "AASCO",
            "country": "Republic of Korea"
        },
        {
            "name": "Air Kirovograd",
            "code": "",
            "auxcode": "KAD",
            "companyName": "AIR KIROVOGRAD",
            "country": "Ukraine"
        },
        {
            "name": "Air Mach",
            "code": "",
            "auxcode": "KAM",
            "companyName": "ICOcodeAIR",
            "country": "Italy"
        },
        {
            "name": "Air Kufra",
            "code": "",
            "auxcode": "KAV",
            "companyName": "AIRKUFRA",
            "country": "Libya"
        },
        {
            "name": "Arkhabay",
            "code": "",
            "auxcode": "KEK",
            "companyName": "ARKHABAY",
            "country": "Kazakhstan"
        },
        {
            "name": "Aero Charter Krifka",
            "code": "",
            "auxcode": "KFK",
            "companyName": "KRIFKA AIR",
            "country": "Austria"
        },
        {
            "name": "Air Kraft Mir",
            "code": "",
            "auxcode": "KFT",
            "companyName": "AIR KRAFT MIR",
            "country": "Uzbekistan"
        },
        {
            "name": "Air Concorde",
            "code": "",
            "auxcode": "KGD",
            "companyName": "CONCORDE AIR",
            "country": "Bulgaria"
        },
        {
            "name": "Alexandria Airlines",
            "code": "",
            "auxcode": "KHH",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Afit",
            "code": "",
            "auxcode": "KIE",
            "companyName": "TWEETY",
            "country": "Germany"
        },
        {
            "name": "Air South",
            "code": "",
            "auxcode": "KKB",
            "companyName": "KHAKI BLUE",
            "country": "United States"
        },
        {
            "name": "Atlasjet",
            "code": "KK",
            "auxcode": "KKK",
            "companyName": "ATLASJET",
            "country": "Turkey"
        },
        {
            "name": "Air Mali International",
            "code": "",
            "auxcode": "KLB",
            "companyName": "TRANS MALI",
            "country": "Mali"
        },
        {
            "name": "Aerokaluz",
            "code": "",
            "auxcode": "KLZ",
            "companyName": "AEROKALUZ",
            "country": "Mexico"
        },
        {
            "name": "Air Koryo",
            "code": "JS",
            "auxcode": "KOR",
            "companyName": "AIR KORYO",
            "country": "Democratic People's Republic of Korea"
        },
        {
            "name": "Araiavia",
            "code": "",
            "auxcode": "KOY",
            "companyName": "ALEKS",
            "country": "Kazakhstan"
        },
        {
            "name": "AeroSucre",
            "code": "",
            "auxcode": "KRE",
            "companyName": "AEROSUCRE",
            "country": "Colombia"
        },
        {
            "name": "Air Kokshetau",
            "code": "",
            "auxcode": "KRT",
            "companyName": "KOKTA",
            "country": "Kazakhstan"
        },
        {
            "name": "Air Kissari",
            "code": "",
            "auxcode": "KSI",
            "companyName": "KISSARI",
            "country": "Angola"
        },
        {
            "name": "Aeronavigaciya",
            "code": "",
            "auxcode": "KTN",
            "companyName": "AERONAVIGACIYA",
            "country": "Ukraine"
        },
        {
            "name": "Alliance Avia",
            "code": "",
            "auxcode": "KVR",
            "companyName": "KAVAIR",
            "country": "Kazakhstan"
        },
        {
            "name": "Av Atlantic",
            "code": "",
            "auxcode": "KYC",
            "companyName": "DOLPHIN",
            "country": "United States"
        },
        {
            "name": "Air Astana",
            "code": "KC",
            "auxcode": "KZR",
            "companyName": "ASTANALINE",
            "country": "Kazakhstan"
        },
        {
            "name": "Aerovias De Lagos",
            "code": "",
            "auxcode": "LAG",
            "companyName": "AEROLAGOS",
            "country": "Mexico"
        },
        {
            "name": "Albanian Airlines",
            "code": "LV",
            "auxcode": "LBC",
            "companyName": "ALBANIAN",
            "country": "Albania"
        },
        {
            "name": "Albisa",
            "code": "",
            "auxcode": "LBI",
            "companyName": "ALBISA",
            "country": "Mexico"
        },
        {
            "name": "Albatros Airways",
            "code": "",
            "auxcode": "LBW",
            "companyName": "ALBANWAYS",
            "country": "Albania"
        },
        {
            "name": "Aerolineas Aereas Ejecutivas De Durango",
            "code": "",
            "auxcode": "LDG",
            "companyName": "DURANGO",
            "country": "Mexico"
        },
        {
            "name": "Aerologic",
            "code": "",
            "auxcode": "LDL",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "AlcodeDonas Airlines",
            "code": "",
            "auxcode": "LDN",
            "companyName": "ALDONAS AIR",
            "country": "Nigeria"
        },
        {
            "name": "Aero Lider",
            "code": "",
            "auxcode": "LDR",
            "companyName": "AEROLIDER",
            "country": "Mexico"
        },
        {
            "name": "Aleem",
            "code": "",
            "auxcode": "LEM",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Aerolineas Ejecutivas",
            "code": "",
            "auxcode": "LET",
            "companyName": "MEXEJECUTIV",
            "country": "Mexico"
        },
        {
            "name": "Air Alfa",
            "code": "",
            "auxcode": "LFA",
            "companyName": "",
            "country": "Turkey"
        },
        {
            "name": "Aero Control Air",
            "code": "",
            "auxcode": "LFC",
            "companyName": "LIFE FLIGHT CANADA",
            "country": "Canada"
        },
        {
            "name": "Aerolaguna",
            "code": "",
            "auxcode": "LGN",
            "companyName": "AEROLAGUNA",
            "country": "Mexico"
        },
        {
            "name": "Al Ahram Aviation",
            "code": "",
            "auxcode": "LHR",
            "companyName": "AL AHRAM",
            "country": "Egypt"
        },
        {
            "name": "Alidaunia",
            "code": "D4",
            "auxcode": "LID",
            "companyName": "ALIDA",
            "country": "Italy"
        },
        {
            "name": "AlcodeDawood Air",
            "code": "",
            "auxcode": "LIE",
            "companyName": "ALcodeDAWOOD AIR",
            "country": "Nigeria"
        },
        {
            "name": "American Aviation",
            "code": "",
            "auxcode": "LKP",
            "companyName": "LAKE POWELL",
            "country": "United States"
        },
        {
            "name": "Airlink Solutions",
            "code": "",
            "auxcode": "LKS",
            "companyName": "AIRLIN",
            "country": "Spain"
        },
        {
            "name": "Air Solutions",
            "code": "",
            "auxcode": "LKY",
            "companyName": "LUCKY",
            "country": "United States"
        },
        {
            "name": "Alliance Air",
            "code": "CD",
            "auxcode": "LLR",
            "companyName": "ALLIED",
            "country": "India"
        },
        {
            "name": "Aerolima",
            "code": "",
            "auxcode": "LMA",
            "companyName": "AEROLIMA",
            "country": "Mexico"
        },
        {
            "name": "Alamia Air",
            "code": "",
            "auxcode": "LML",
            "companyName": "ALAMIA AIR",
            "country": "Libya"
        },
        {
            "name": "Air Plus Argentina",
            "code": "",
            "auxcode": "LMP",
            "companyName": "AIR FLIGHT",
            "country": "Argentina"
        },
        {
            "name": "Almaty Aviation",
            "code": "",
            "auxcode": "LMT",
            "companyName": "ALMATY",
            "country": "Kazakhstan"
        },
        {
            "name": "Aerolineas Mexicanas J S",
            "code": "",
            "auxcode": "LMX",
            "companyName": "LINEAS MEXICANAS",
            "country": "Mexico"
        },
        {
            "name": "Air Almaty",
            "code": "",
            "auxcode": "LMY",
            "companyName": "AGLEB",
            "country": "Kazakhstan"
        },
        {
            "name": "Air Almaty ZK",
            "code": "",
            "auxcode": "LMZ",
            "companyName": "ALUNK",
            "country": "Kazakhstan"
        },
        {
            "name": "Aerolane",
            "code": "XL",
            "auxcode": "LNE",
            "companyName": "LAN ECUADOR",
            "country": "Ecuador"
        },
        {
            "name": "Airlink",
            "code": "",
            "auxcode": "LNK",
            "companyName": "LINK",
            "country": "South Africa"
        },
        {
            "name": "Aerolineas Internacionales",
            "code": "",
            "auxcode": "LNT",
            "companyName": "LINEAINT",
            "country": "Mexico"
        },
        {
            "name": "Alok Air",
            "code": "",
            "auxcode": "LOK",
            "companyName": "ALOK AIR",
            "country": "Sudan"
        },
        {
            "name": "Air Saint Louis",
            "code": "",
            "auxcode": "LOU",
            "companyName": "AIR SAINTLOUIS",
            "country": "Senegal"
        },
        {
            "name": "Alpine Aviation",
            "code": "",
            "auxcode": "LPC",
            "companyName": "NETSTAR",
            "country": "South Africa"
        },
        {
            "name": "Air Alps Aviation",
            "code": "A6",
            "auxcode": "LPV",
            "companyName": "ALPAV",
            "country": "Austria"
        },
        {
            "name": "AlrosacodeAvia",
            "code": "",
            "auxcode": "LRO",
            "companyName": "ALROSA",
            "country": "Russia"
        },
        {
            "name": "Al Rida Airways",
            "code": "",
            "auxcode": "LRW",
            "companyName": "AL RIDA",
            "country": "Mauritania"
        },
        {
            "name": "Aurela",
            "code": "",
            "auxcode": "LSK",
            "companyName": "AURELA",
            "country": "Lithuania"
        },
        {
            "name": "Aerobusinessservice",
            "code": "",
            "auxcode": "LSM",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Alsair",
            "code": "",
            "auxcode": "LSR",
            "companyName": "ALSAIR",
            "country": "France"
        },
        {
            "name": "Aerotaxis Latinoamericanos",
            "code": "",
            "auxcode": "LTI",
            "companyName": "LATINO",
            "country": "Mexico"
        },
        {
            "name": "Alninati Aeronautics",
            "code": "",
            "auxcode": "LUC",
            "companyName": "STEF",
            "country": "Switzerland"
        },
        {
            "name": "Atlantis European Airways",
            "code": "TD",
            "auxcode": "LUR",
            "companyName": "",
            "country": "Armenia"
        },
        {
            "name": "Aliven",
            "code": "",
            "auxcode": "LVN",
            "companyName": "ALIVEN",
            "country": "Italy"
        },
        {
            "name": "Aviavilsa",
            "code": "",
            "auxcode": "LVR",
            "companyName": "AVIAVILSA",
            "country": "Lithuania"
        },
        {
            "name": "Air Luxor GB",
            "code": "L8",
            "auxcode": "LXG",
            "companyName": "LUXOR GOLF",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Air Luxor",
            "code": "LK",
            "auxcode": "LXR",
            "companyName": "AIRLUXOR",
            "country": "Portugal"
        },
        {
            "name": "Apatas Air",
            "code": "",
            "auxcode": "LYT",
            "companyName": "APATAS",
            "country": "Lithuania"
        },
        {
            "name": "Air Ban",
            "code": "",
            "auxcode": "LZP",
            "companyName": "DOC AIR",
            "country": "Bulgaria"
        },
        {
            "name": "Air Lazur",
            "code": "",
            "auxcode": "LZR",
            "companyName": "LAZUR BEEcodeGEE",
            "country": "Bulgaria"
        },
        {
            "name": "Aerodromo De La Mancha",
            "code": "",
            "auxcode": "MAM",
            "companyName": "AEROMAN",
            "country": "Spain"
        },
        {
            "name": "Air Mauritius",
            "code": "MK",
            "auxcode": "MAU",
            "companyName": "AIRMAURITIUS",
            "country": "Mauritius"
        },
        {
            "name": "Avag Air",
            "code": "",
            "auxcode": "MBA",
            "companyName": "AVAG AIR",
            "country": "Austria"
        },
        {
            "name": "Air Manas",
            "code": "",
            "auxcode": "MBB",
            "companyName": "AIR MANAS",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Airjet Exploracao Aerea De Carga",
            "code": "",
            "auxcode": "MBC",
            "companyName": "MABECO",
            "country": "Angola"
        },
        {
            "name": "AerianturcodeM",
            "code": "",
            "auxcode": "MBV",
            "companyName": "AEREM",
            "country": "Moldova"
        },
        {
            "name": "Air Mercia",
            "code": "",
            "auxcode": "MCB",
            "companyName": "WESTMID",
            "country": "United Kingdom"
        },
        {
            "name": "Air Medical",
            "code": "",
            "auxcode": "MCD",
            "companyName": "AIR MED",
            "country": "United Kingdom"
        },
        {
            "name": "Aerolineas Marcos",
            "code": "",
            "auxcode": "MCO",
            "companyName": "MARCOS",
            "country": "Mexico"
        },
        {
            "name": "Atlantic Aero and MidcodeAtlantic Freight",
            "code": "",
            "auxcode": "MDC",
            "companyName": "NIGHT SHIP",
            "country": "United States"
        },
        {
            "name": "Air Madagascar",
            "code": "MD",
            "auxcode": "MDG",
            "companyName": "AIR MADAGASCAR",
            "country": "Madagascar"
        },
        {
            "name": "Aerosud Charter",
            "code": "",
            "auxcode": "MDX",
            "companyName": "MEDAIR",
            "country": "South Africa"
        },
        {
            "name": "Air Meridan",
            "code": "",
            "auxcode": "MEF",
            "companyName": "EMPENNAGE",
            "country": "Nigeria"
        },
        {
            "name": "Aero McFly",
            "code": "",
            "auxcode": "MFL",
            "companyName": "MCFLY",
            "country": "Mexico"
        },
        {
            "name": "Asia Pacific Airlines",
            "code": "",
            "auxcode": "MGE",
            "companyName": "MAGELLAN",
            "country": "United States"
        },
        {
            "name": "Aeromagar",
            "code": "",
            "auxcode": "MGS",
            "companyName": "AEROMAGAR",
            "country": "Mexico"
        },
        {
            "name": "Aero Premier De Mexico",
            "code": "",
            "auxcode": "MIE",
            "companyName": "AEROPREMIER",
            "country": "Mexico"
        },
        {
            "name": "Air Moldova",
            "code": "9U",
            "auxcode": "MLD",
            "companyName": "AIR MOLDOVA",
            "country": "Moldova"
        },
        {
            "name": "Amal Airlines",
            "code": "",
            "auxcode": "MLF",
            "companyName": "AMAL",
            "country": "Djibouti"
        },
        {
            "name": "Air Mali",
            "code": "",
            "auxcode": "MLI",
            "companyName": "AIR MALI",
            "country": "Mali"
        },
        {
            "name": "Air Madeleine",
            "code": "",
            "auxcode": "MLN",
            "companyName": "AIR MADELEINE",
            "country": "Canada"
        },
        {
            "name": "Aermarche",
            "code": "",
            "auxcode": "MMC",
            "companyName": "AERMARCHE",
            "country": "Italy"
        },
        {
            "name": "Air Alsie",
            "code": "",
            "auxcode": "MMD",
            "companyName": "MERMAID",
            "country": "Denmark"
        },
        {
            "name": "Aviation Company Meridian",
            "code": "",
            "auxcode": "MMM",
            "companyName": "AVIAMERIDIAN",
            "country": "Russia"
        },
        {
            "name": "AMP Incorporated",
            "code": "",
            "auxcode": "MMP",
            "companyName": "AMPcodeINC",
            "country": "United States"
        },
        {
            "name": "Airmax",
            "code": "",
            "auxcode": "MMX",
            "companyName": "PERUMAX",
            "country": "Peru"
        },
        {
            "name": "Aerolineas Amanecer",
            "code": "",
            "auxcode": "MNE",
            "companyName": "AEROAMANECER",
            "country": "Mexico"
        },
        {
            "name": "Aero Mongolia",
            "code": "M0",
            "auxcode": "MNG",
            "companyName": "AERO MONGOLIA",
            "country": "Mongolia"
        },
        {
            "name": "Air Monarch Cargo",
            "code": "",
            "auxcode": "MOC",
            "companyName": "MONARCH CARGO",
            "country": "Mexico"
        },
        {
            "name": "Aeropublicitaria De Angola",
            "code": "",
            "auxcode": "MOP",
            "companyName": "PUBLICITARIA",
            "country": "Angola"
        },
        {
            "name": "Aerolineas De Morelia",
            "code": "",
            "auxcode": "MOR",
            "companyName": "AEROMORELIA",
            "country": "Mexico"
        },
        {
            "name": "Air Plus Comet",
            "code": "A7",
            "auxcode": "MPD",
            "companyName": "RED COMET",
            "country": "Spain"
        },
        {
            "name": "Aeromexpress",
            "code": "QO",
            "auxcode": "MPX",
            "companyName": "AEROMEXPRESS",
            "country": "Mexico"
        },
        {
            "name": "Air ITM",
            "code": "",
            "auxcode": "MQT",
            "companyName": "MUSKETEER",
            "country": "France"
        },
        {
            "name": "Aeromorelos",
            "code": "",
            "auxcode": "MRL",
            "companyName": "AEROMORELOS",
            "country": "Mexico"
        },
        {
            "name": "Aerocharter",
            "code": "",
            "auxcode": "MRM",
            "companyName": "MARITIME",
            "country": "Canada"
        },
        {
            "name": "Abas",
            "code": "",
            "auxcode": "MRP",
            "companyName": "ABAS",
            "country": "Czech Republic"
        },
        {
            "name": "Air Mauritanie",
            "code": "MR",
            "auxcode": "MRT",
            "companyName": "MIKE ROMEO",
            "country": "Mauritania"
        },
        {
            "name": "Air Cairo",
            "code": "",
            "auxcode": "MSC",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Air Sport",
            "code": "",
            "auxcode": "MSK",
            "companyName": "AIR SPORT",
            "country": "Bulgaria"
        },
        {
            "name": "Aeromas",
            "code": "",
            "auxcode": "MSM",
            "companyName": "AEROMAS EXPRESS",
            "country": "Uruguay"
        },
        {
            "name": "Aerol",
            "code": "",
            "auxcode": "MSO",
            "companyName": "MESO AMERICANAS",
            "country": "Mexico"
        },
        {
            "name": "AerocodeKamov",
            "code": "",
            "auxcode": "MSV",
            "companyName": "AERAFKAM",
            "country": "Russia"
        },
        {
            "name": "Aerotaxis Metropolitanos",
            "code": "",
            "auxcode": "MTB",
            "companyName": "AEROMETROPOLIS",
            "country": "Mexico"
        },
        {
            "name": "Aeromet Linea Aerea",
            "code": "",
            "auxcode": "MTE",
            "companyName": "AEROMET",
            "country": "Chile"
        },
        {
            "name": "Air Metack",
            "code": "",
            "auxcode": "MTK",
            "companyName": "AIRMETACK",
            "country": "Angola"
        },
        {
            "name": "Air Montegomery",
            "code": "",
            "auxcode": "MTY",
            "companyName": "MONTY",
            "country": "United Kingdom"
        },
        {
            "name": "Aerotaxi Mexicano",
            "code": "",
            "auxcode": "MXO",
            "companyName": "MAXAERO",
            "country": "Mexico"
        },
        {
            "name": "Aero Yaqui Mayo",
            "code": "",
            "auxcode": "MYS",
            "companyName": "AERO YAQUI",
            "country": "Mexico"
        },
        {
            "name": "AVC Airlines",
            "code": "",
            "auxcode": "MZK",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Aerovias Montes Azules",
            "code": "",
            "auxcode": "MZL",
            "companyName": "MONTES AZULES",
            "country": "Mexico"
        },
        {
            "name": "Aeroland Airways",
            "code": "3S",
            "auxcode": "",
            "companyName": "",
            "country": "Greece"
        },
        {
            "name": "Astair",
            "code": "8D",
            "auxcode": "",
            "companyName": "",
            "country": "Russian Federation"
        },
        {
            "name": "Albarka Air",
            "code": "F4",
            "auxcode": "NBK",
            "companyName": "ALcodeAIR",
            "country": "Nigeria"
        },
        {
            "name": "ACAcodeAncargo Air Sociedade de Transporte de Carga Lda",
            "code": "",
            "auxcode": "NCL",
            "companyName": "ANCARGO AIR",
            "country": "Angola"
        },
        {
            "name": "Aero Servicios de Nuevo Laredo",
            "code": "",
            "auxcode": "NEL",
            "companyName": "AEROLAREDO",
            "country": "Mexico"
        },
        {
            "name": "Angoavia",
            "code": "",
            "auxcode": "NGV",
            "companyName": "ANGOAVIA",
            "country": "Angola"
        },
        {
            "name": "Aeroni",
            "code": "",
            "auxcode": "NID",
            "companyName": "AERONI",
            "country": "Mexico"
        },
        {
            "name": "Aeroejecutiva Nieto",
            "code": "",
            "auxcode": "NIE",
            "companyName": "AERONIETO",
            "country": "Mexico"
        },
        {
            "name": "Aero Contractors",
            "code": "AJ",
            "auxcode": "NIG",
            "companyName": "AEROLINE",
            "country": "Nigeria"
        },
        {
            "name": "Aerokuzbass",
            "code": "",
            "auxcode": "NKZ",
            "companyName": "NOVOKUZNETSK",
            "country": "Russia"
        },
        {
            "name": "Atlantic Airlines",
            "code": "",
            "auxcode": "NPT",
            "companyName": "NEPTUNE",
            "country": "United Kingdom"
        },
        {
            "name": "Atlantic Richfield Company",
            "code": "",
            "auxcode": "NRS",
            "companyName": "NORTH SLOPE",
            "country": "United States"
        },
        {
            "name": "Aerolineas Sosa",
            "code": "",
            "auxcode": "NSO",
            "companyName": "SOSA",
            "country": "Honduras"
        },
        {
            "name": "Aero Norte",
            "code": "",
            "auxcode": "NTD",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "Air Inter Ivoire",
            "code": "",
            "auxcode": "NTV",
            "companyName": "INTERcodeIVOIRE",
            "country": "Ivory Coast"
        },
        {
            "name": "Aeroservicios De Nuevo Leon",
            "code": "",
            "auxcode": "NUL",
            "companyName": "SERVICIOS NUEVOLEON",
            "country": "Mexico"
        },
        {
            "name": "Avial NV Aviation Company",
            "code": "",
            "auxcode": "NVI",
            "companyName": "NEW AVIAL",
            "country": "Russia"
        },
        {
            "name": "Airwing",
            "code": "",
            "auxcode": "NWG",
            "companyName": "NORWING",
            "country": "Norway"
        },
        {
            "name": "Air Next",
            "code": "",
            "auxcode": "NXA",
            "companyName": "BLUEcodeDOLPHIN",
            "country": "Japan"
        },
        {
            "name": "Arkhangelsk 2 Aviation Division",
            "code": "",
            "auxcode": "OAO",
            "companyName": "DVINA",
            "country": "Russia"
        },
        {
            "name": "Aerogisa",
            "code": "",
            "auxcode": "OGI",
            "companyName": "AEROGISA",
            "country": "Mexico"
        },
        {
            "name": "Aerolineas Olve",
            "code": "",
            "auxcode": "OLV",
            "companyName": "OLVE",
            "country": "Mexico"
        },
        {
            "name": "Aeromega",
            "code": "",
            "auxcode": "OMG",
            "companyName": "OMEGA",
            "country": "United Kingdom"
        },
        {
            "name": "Air One Nine",
            "code": "",
            "auxcode": "ONR",
            "companyName": "EDER",
            "country": "Libya"
        },
        {
            "name": "Air Ontario",
            "code": "",
            "auxcode": "ONT",
            "companyName": "ONTARIO",
            "country": "Canada"
        },
        {
            "name": "Aerocorp",
            "code": "",
            "auxcode": "ORP",
            "companyName": "CORPSA",
            "country": "Mexico"
        },
        {
            "name": "Action Air",
            "code": "",
            "auxcode": "ORS",
            "companyName": "AVIATION SERVICE",
            "country": "Italy"
        },
        {
            "name": "Aerosan",
            "code": "",
            "auxcode": "OSN",
            "companyName": "AEROSAN",
            "country": "Mexico"
        },
        {
            "name": "Aviapartner Limited Company",
            "code": "",
            "auxcode": "OSO",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Aliparma",
            "code": "",
            "auxcode": "PAJ",
            "companyName": "ALIPARMA",
            "country": "Italy"
        },
        {
            "name": "Air Parabet",
            "code": "",
            "auxcode": "PBT",
            "companyName": "PARABET",
            "country": "Bangladesh"
        },
        {
            "name": "Air Burundi",
            "code": "8Y",
            "auxcode": "PBU",
            "companyName": "AIRcodeBURUNDI",
            "country": "Burundi"
        },
        {
            "name": "Aeropostal Cargo de Mexico",
            "code": "",
            "auxcode": "PCG",
            "companyName": "POSTAL CARGO",
            "country": "Mexico"
        },
        {
            "name": "Air Pack Express",
            "code": "",
            "auxcode": "PCK",
            "companyName": "AIRPACK EXPRESS",
            "country": "Spain"
        },
        {
            "name": "Air Palace",
            "code": "",
            "auxcode": "PCS",
            "companyName": "AIR PALACE",
            "country": "Mexico"
        },
        {
            "name": "Aeropelican Air Services",
            "code": "OT",
            "auxcode": "PEL",
            "companyName": "PELICAN",
            "country": "Australia"
        },
        {
            "name": "Aerolineas Chihuahua",
            "code": "",
            "auxcode": "PFI",
            "companyName": "PACIFICO CHIHUAHUA",
            "country": "Mexico"
        },
        {
            "name": "Air Cargo Express International",
            "code": "",
            "auxcode": "PFT",
            "companyName": "PROFREIGHT",
            "country": "United States"
        },
        {
            "name": "Al Farana Airline",
            "code": "",
            "auxcode": "PHR",
            "companyName": "PHARAOH",
            "country": "Egypt"
        },
        {
            "name": "Ave.com",
            "code": "",
            "auxcode": "PHW",
            "companyName": "PHOENIX SHARJAH",
            "country": "United Arab Emirates"
        },
        {
            "name": "Air South West",
            "code": "",
            "auxcode": "PIE",
            "companyName": "PIRATE",
            "country": "United Kingdom"
        },
        {
            "name": "Aeroservicios California Pacifico",
            "code": "",
            "auxcode": "PIF",
            "companyName": "AEROCALPA",
            "country": "Mexico"
        },
        {
            "name": "AST Pakistan Airways",
            "code": "",
            "auxcode": "PKA",
            "companyName": "PAKISTAN AIRWAY",
            "country": "Pakistan"
        },
        {
            "name": "Aero Personal",
            "code": "",
            "auxcode": "PNL",
            "companyName": "AEROPERSONAL",
            "country": "Mexico"
        },
        {
            "name": "Aero Servicios Platinum",
            "code": "",
            "auxcode": "PNU",
            "companyName": "AERO PLATINUM",
            "country": "Mexico"
        },
        {
            "name": "Apoyo Aereo",
            "code": "",
            "auxcode": "POY",
            "companyName": "APOYO AEREO",
            "country": "Mexico"
        },
        {
            "name": "Aerotransportes Privados",
            "code": "",
            "auxcode": "PRI",
            "companyName": "AEROPRIV",
            "country": "Mexico"
        },
        {
            "name": "Atlantic Coast Jet",
            "code": "",
            "auxcode": "PRT",
            "companyName": "PATRIOT",
            "country": "United States"
        },
        {
            "name": "Air Paradise International",
            "code": "AD",
            "auxcode": "PRZ",
            "companyName": "RADISAIR",
            "country": "Indonesia"
        },
        {
            "name": "Aeroservicio Sipse",
            "code": "",
            "auxcode": "PSE",
            "companyName": "SIPSE",
            "country": "Mexico"
        },
        {
            "name": "Aeroservicios Corporativos De San Luis",
            "code": "",
            "auxcode": "PSL",
            "companyName": "CORSAN",
            "country": "Mexico"
        },
        {
            "name": "Aerotransportes Privados",
            "code": "",
            "auxcode": "PVA",
            "companyName": "TRANSPRIVADO",
            "country": "Mexico"
        },
        {
            "name": "Aereo Taxi Paraza",
            "code": "",
            "auxcode": "PZA",
            "companyName": "AEREO PARAZA",
            "country": "Mexico"
        },
        {
            "name": "Air Class Lineas Aereas",
            "code": "QD",
            "auxcode": "QCL",
            "companyName": "ACLA",
            "country": "Uruguay"
        },
        {
            "name": "Aviation Consultancy Office",
            "code": "",
            "auxcode": "QEA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Aero Taxi",
            "code": "",
            "auxcode": "QAT",
            "companyName": "AIR QUASAR",
            "country": "Canada"
        },
        {
            "name": "Aero Taxi Aviation",
            "code": "",
            "auxcode": "QKC",
            "companyName": "QUAKER CITY",
            "country": "United States"
        },
        {
            "name": "Aviation Quebec Labrador",
            "code": "",
            "auxcode": "QLA",
            "companyName": "QUEBEC LABRADOR",
            "country": "Canada"
        },
        {
            "name": "African Safari Airways",
            "code": "QS",
            "auxcode": "QSC",
            "companyName": "ZEBRA",
            "country": "Kenya"
        },
        {
            "name": "Aero Quimmco",
            "code": "",
            "auxcode": "QUI",
            "companyName": "QUIMMCO",
            "country": "Mexico"
        },
        {
            "name": "Alada",
            "code": "",
            "auxcode": "RAD",
            "companyName": "AIR ALADA",
            "country": "Angola"
        },
        {
            "name": "AeroturcodeAir",
            "code": "",
            "auxcode": "RAI",
            "companyName": "DIASA",
            "country": "Kazakhstan"
        },
        {
            "name": "Air Center Helicopters",
            "code": "",
            "auxcode": "RAP",
            "companyName": "RAPTOR",
            "country": "United States"
        },
        {
            "name": "Aur Rum Benin",
            "code": "",
            "auxcode": "RBE",
            "companyName": "RUM BENIN",
            "country": "Benin"
        },
        {
            "name": "Aeroserivios Del Bajio",
            "code": "",
            "auxcode": "RBJ",
            "companyName": "AEROBAJIO",
            "country": "Mexico"
        },
        {
            "name": "Airbus France",
            "code": "4Y",
            "auxcode": "RBU",
            "companyName": "AIRBUS FRANCE",
            "country": "France"
        },
        {
            "name": "Air Roberval",
            "code": "",
            "auxcode": "RBV",
            "companyName": "AIR ROBERVAL",
            "country": "Canada"
        },
        {
            "name": "Arubaexel",
            "code": "",
            "auxcode": "RBX",
            "companyName": "ARUBA",
            "country": "Aruba"
        },
        {
            "name": "Air Charters Eelde",
            "code": "",
            "auxcode": "RCC",
            "companyName": "RACER",
            "country": "Netherlands"
        },
        {
            "name": "Aerocer",
            "code": "",
            "auxcode": "RCE",
            "companyName": "AEROCER",
            "country": "Mexico"
        },
        {
            "name": "AeroflotcodeCargo",
            "code": "",
            "auxcode": "RCF",
            "companyName": "AEROFLOTcodeCARGO",
            "country": "Russia"
        },
        {
            "name": "Air Mobility Command",
            "code": "MC",
            "auxcode": "RCH",
            "companyName": "REACH",
            "country": "United States"
        },
        {
            "name": "Air Cassai",
            "code": "",
            "auxcode": "RCI",
            "companyName": "AIR CASSAI",
            "country": "Angola"
        },
        {
            "name": "Aero Renta De Coahuila",
            "code": "",
            "auxcode": "RCO",
            "companyName": "AEROCOAHUILA",
            "country": "Mexico"
        },
        {
            "name": "Aerocorp",
            "code": "",
            "auxcode": "RCP",
            "companyName": "AEROCORPSA",
            "country": "Mexico"
        },
        {
            "name": "Aerolineas Regionales",
            "code": "",
            "auxcode": "RCQ",
            "companyName": "REGIONAL CARGO",
            "country": "Mexico"
        },
        {
            "name": "Atlantic S.L.",
            "code": "",
            "auxcode": "RCU",
            "companyName": "AIR COURIER",
            "country": "Spain"
        },
        {
            "name": "Air Service Center",
            "code": "",
            "auxcode": "RCX",
            "companyName": "SERVICE CENTER",
            "country": "Italy"
        },
        {
            "name": "Adygeya Airlines",
            "code": "",
            "auxcode": "RDD",
            "companyName": "ADLINES",
            "country": "Russia"
        },
        {
            "name": "Air Ada",
            "code": "",
            "auxcode": "RDM",
            "companyName": "AIR ADA",
            "country": "Mauritania"
        },
        {
            "name": "Aer Arann",
            "code": "RE",
            "auxcode": "REA",
            "companyName": "AER ARANN",
            "country": "Ireland"
        },
        {
            "name": "AerocodeRent",
            "code": "",
            "auxcode": "REN",
            "companyName": "AERORENT",
            "country": "Mexico"
        },
        {
            "name": "Australian Maritime Safety Authority",
            "code": "",
            "auxcode": "RES",
            "companyName": "RESCUE",
            "country": "Australia"
        },
        {
            "name": "Air Austral",
            "code": "UU",
            "auxcode": "REU",
            "companyName": "REUNION",
            "country": "France"
        },
        {
            "name": "AerocodeRey",
            "code": "",
            "auxcode": "REY",
            "companyName": "AEROREY",
            "country": "Mexico"
        },
        {
            "name": "Aero Africa",
            "code": "",
            "auxcode": "RFC",
            "companyName": "AERO AFRICA",
            "country": "Swaziland"
        },
        {
            "name": "Aerotransportes Rafilher",
            "code": "",
            "auxcode": "RFD",
            "companyName": "RAFHILER",
            "country": "Mexico"
        },
        {
            "name": "Argo",
            "code": "",
            "auxcode": "RGO",
            "companyName": "ARGOS",
            "country": "Dominican Republic"
        },
        {
            "name": "Airbourne School of Flying",
            "code": "",
            "auxcode": "RGT",
            "companyName": "AIRGOAT",
            "country": "United Kingdom"
        },
        {
            "name": "Air Archipels",
            "code": "",
            "auxcode": "RHL",
            "companyName": "ARCHIPELS",
            "country": "France"
        },
        {
            "name": "Aviation Ministry of the Interior of the Russian Federation",
            "code": "",
            "auxcode": "RIF",
            "companyName": "INTERMIN AVIA",
            "country": "Russian Federation"
        },
        {
            "name": "Aeris Gestion",
            "code": "",
            "auxcode": "RIS",
            "companyName": "AERIS",
            "country": "Spain"
        },
        {
            "name": "Asian Spirit",
            "code": "6K",
            "auxcode": "RIT",
            "companyName": "ASIAN SPIRIT",
            "country": "Philippines"
        },
        {
            "name": "Aeroservicios Jet",
            "code": "",
            "auxcode": "RJS",
            "companyName": "ASERJET",
            "country": "Mexico"
        },
        {
            "name": "Air Afrique",
            "code": "RK",
            "auxcode": "RKA",
            "companyName": "AIRAFRIC",
            "country": "Ivory Coast"
        },
        {
            "name": "Airlinair",
            "code": "A5",
            "auxcode": "RLA",
            "companyName": "AIRLINAIR",
            "country": "France"
        },
        {
            "name": "Air Nelson",
            "code": "",
            "auxcode": "RLK",
            "companyName": "",
            "country": "New Zealand"
        },
        {
            "name": "Air Leone",
            "code": "",
            "auxcode": "RLL",
            "companyName": "AEROLEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Aero Lanka",
            "code": "QL",
            "auxcode": "RLN",
            "companyName": "AERO LANKA",
            "country": "Sri Lanka"
        },
        {
            "name": "Air Alize",
            "code": "",
            "auxcode": "RLZ",
            "companyName": "ALIZE",
            "country": "France"
        },
        {
            "name": "Air Amder",
            "code": "",
            "auxcode": "RMD",
            "companyName": "AIR AMDER",
            "country": "Mauritania"
        },
        {
            "name": "Armenian Airlines",
            "code": "",
            "auxcode": "RME",
            "companyName": "ARMENIAN",
            "country": "Armenia"
        },
        {
            "name": "Armenian International Airways",
            "code": "MV",
            "auxcode": "RML",
            "companyName": "",
            "country": "Armenia"
        },
        {
            "name": "ArmcodeAero",
            "code": "",
            "auxcode": "RMO",
            "companyName": "ARMcodeAERO",
            "country": "Armenia"
        },
        {
            "name": "Air Max",
            "code": "",
            "auxcode": "RMX",
            "companyName": "AEROMAX",
            "country": "Bulgaria"
        },
        {
            "name": "Air Salone",
            "code": "20",
            "auxcode": "RNE",
            "companyName": "AIR SALONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Aeronem Air Cargo",
            "code": "",
            "auxcode": "RNM",
            "companyName": "AEROMNEM",
            "country": "Ecuador"
        },
        {
            "name": "Air Cargo Masters",
            "code": "",
            "auxcode": "RNR",
            "companyName": "RUNNER",
            "country": "United States"
        },
        {
            "name": "Armavia",
            "code": "U8",
            "auxcode": "RNV",
            "companyName": "ARMAVIA",
            "country": "Armenia"
        },
        {
            "name": "Aeroeste",
            "code": "",
            "auxcode": "ROE",
            "companyName": "ESTEcodeBOLIVIA",
            "country": "Bolivia"
        },
        {
            "name": "Aero Gen",
            "code": "",
            "auxcode": "ROH",
            "companyName": "AEROGEN",
            "country": "Mexico"
        },
        {
            "name": "Avior Airlines",
            "code": "",
            "auxcode": "ROI",
            "companyName": "AVIOR",
            "country": "Venezuela"
        },
        {
            "name": "Aeroel Airways",
            "code": "",
            "auxcode": "ROL",
            "companyName": "AEROEL",
            "country": "Israel"
        },
        {
            "name": "Aeromar",
            "code": "BQ",
            "auxcode": "ROM",
            "companyName": "BRAVO QUEBEC",
            "country": "Dominican Republic"
        },
        {
            "name": "Aeroitalia",
            "code": "",
            "auxcode": "ROO",
            "companyName": "AEROITALIA",
            "country": "Italy"
        },
        {
            "name": "Aerodan",
            "code": "",
            "auxcode": "ROD",
            "companyName": "AERODAN",
            "country": "Mexico"
        },
        {
            "name": "AeroRep",
            "code": "P5",
            "auxcode": "RPB",
            "companyName": "AEROREPUBLICA",
            "country": "Colombia"
        },
        {
            "name": "Aerolineas Del Pacifico",
            "code": "",
            "auxcode": "RPC",
            "companyName": "AEROPACSA",
            "country": "Ecuador"
        },
        {
            "name": "Aero Roca",
            "code": "",
            "auxcode": "RRC",
            "companyName": "AEROROCA",
            "country": "Mexico"
        },
        {
            "name": "Aerotransportes Internacionales De Torreon",
            "code": "",
            "auxcode": "RRE",
            "companyName": "AERO TORREON",
            "country": "Mexico"
        },
        {
            "name": "Acvila AircodeRomanian Carrier",
            "code": "",
            "auxcode": "RRM",
            "companyName": "AIR ROMANIA",
            "country": "Romania"
        },
        {
            "name": "Aerolineas Ejecutivas Tarascas",
            "code": "",
            "auxcode": "RSC",
            "companyName": "TARASCAS",
            "country": "Mexico"
        },
        {
            "name": "AerocodeService",
            "code": "BF",
            "auxcode": "RSR",
            "companyName": "CONGOSERV",
            "country": "Republic of the Congo"
        },
        {
            "name": "Aerosur",
            "code": "5L",
            "auxcode": "RSU",
            "companyName": "AEROSUR",
            "country": "Bolivia"
        },
        {
            "name": "Aeronorte",
            "code": "",
            "auxcode": "RTE",
            "companyName": "LUZAVIA",
            "country": "Portugal"
        },
        {
            "name": "Artis",
            "code": "",
            "auxcode": "RTH",
            "companyName": "ARTHELICO",
            "country": "France"
        },
        {
            "name": "Arhabaev Tourism Airlines",
            "code": "",
            "auxcode": "RTO",
            "companyName": "ARTOAIR",
            "country": "Kazakhstan"
        },
        {
            "name": "Air Turquoise",
            "code": "",
            "auxcode": "RTQ",
            "companyName": "TURQUOISE",
            "country": "France"
        },
        {
            "name": "Aerotucan",
            "code": "",
            "auxcode": "RTU",
            "companyName": "AEROTUCAN",
            "country": "Mexico"
        },
        {
            "name": "Air Anastasia",
            "code": "",
            "auxcode": "RUD",
            "companyName": "ANASTASIA",
            "country": "Ukraine"
        },
        {
            "name": "Air Rum",
            "code": "",
            "auxcode": "RUM",
            "companyName": "AIR RUM",
            "country": "Sierra Leone"
        },
        {
            "name": "ACT Havayollari",
            "code": "",
            "auxcode": "RUN",
            "companyName": "CARGO TURK",
            "country": "Turkey"
        },
        {
            "name": "Air VIP",
            "code": "",
            "auxcode": "RVP",
            "companyName": "AEROVIP",
            "country": "Portugal"
        },
        {
            "name": "Aircompany Veteran",
            "code": "",
            "auxcode": "RVT",
            "companyName": "AIRcodeVET",
            "country": "Armenia"
        },
        {
            "name": "Alliance Express Rwanda",
            "code": "",
            "auxcode": "RWB",
            "companyName": "",
            "country": "Rwanda"
        },
        {
            "name": "Arrow Ecuador Arrowec",
            "code": "",
            "auxcode": "RWC",
            "companyName": "ARROWEC",
            "country": "Ecuador"
        },
        {
            "name": "Aerogroup 98 Limited",
            "code": "",
            "auxcode": "RWY",
            "companyName": "TYNWALD",
            "country": "United Kingdom"
        },
        {
            "name": "Aeroxtra",
            "code": "",
            "auxcode": "RXT",
            "companyName": "AEROcodeEXTRA",
            "country": "Mexico"
        },
        {
            "name": "Express Air Charter",
            "code": "",
            "auxcode": "RXX",
            "companyName": "REX AIR",
            "country": "Canada"
        },
        {
            "name": "Air Whitsunday",
            "code": "",
            "auxcode": "RWS",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Aero Zambia",
            "code": "",
            "auxcode": "RZL",
            "companyName": "AERO ZAMBIA",
            "country": "Zambia"
        },
        {
            "name": "Aero Zano",
            "code": "",
            "auxcode": "RZN",
            "companyName": "ZANO",
            "country": "Mexico"
        },
        {
            "name": "Anoka Air Charter",
            "code": "",
            "auxcode": "RZZ",
            "companyName": "RED ZONE",
            "country": "United States"
        },
        {
            "name": "Aerosaab",
            "code": "",
            "auxcode": "SBH",
            "companyName": "AEROSAAB",
            "country": "Mexico"
        },
        {
            "name": "Associated Aviation",
            "code": "",
            "auxcode": "SCD",
            "companyName": "ASSOCIATED",
            "country": "Nigeria"
        },
        {
            "name": "American Jet International",
            "code": "",
            "auxcode": "SCM",
            "companyName": "SCREAMER",
            "country": "United States"
        },
        {
            "name": "Air Santo Domingo",
            "code": "EX",
            "auxcode": "SDO",
            "companyName": "AERO DOMINGO",
            "country": "Dominican Republic"
        },
        {
            "name": "Aero Sudpacifico",
            "code": "",
            "auxcode": "SDP",
            "companyName": "SUDPACIFICO",
            "country": "Mexico"
        },
        {
            "name": "Aero Servicios Ejecutivas Del Pacifico",
            "code": "",
            "auxcode": "SEF",
            "companyName": "SERVIPACIFICO",
            "country": "Mexico"
        },
        {
            "name": "Aero California",
            "code": "JR",
            "auxcode": "SER",
            "companyName": "AEROCALIFORNIA",
            "country": "Mexico"
        },
        {
            "name": "AcodeSafar Air Services",
            "code": "",
            "auxcode": "SFM",
            "companyName": "AIR SAFAR",
            "country": "Nigeria"
        },
        {
            "name": "Aerosegovia",
            "code": "",
            "auxcode": "SGV",
            "companyName": "SEGOVIA",
            "country": "Nicaragua"
        },
        {
            "name": "Airshare Holdings",
            "code": "",
            "auxcode": "SHH",
            "companyName": "AIRSHARE",
            "country": "United Kingdom"
        },
        {
            "name": "Aerosiyusa",
            "code": "",
            "auxcode": "SIY",
            "companyName": "SIYUSA",
            "country": "Mexico"
        },
        {
            "name": "Aero Silza",
            "code": "",
            "auxcode": "SIZ",
            "companyName": "AEROSILZA",
            "country": "Mexico"
        },
        {
            "name": "Air San Juan",
            "code": "",
            "auxcode": "SJN",
            "companyName": "SAN JUAN",
            "country": "United States"
        },
        {
            "name": "AerocodeNorth Aviation Services",
            "code": "",
            "auxcode": "SKP",
            "companyName": "SKIPPER",
            "country": "Canada"
        },
        {
            "name": "Aero Sami",
            "code": "",
            "auxcode": "SMI",
            "companyName": "SAMI",
            "country": "Mexico"
        },
        {
            "name": "Avient Aviation",
            "code": "Z3",
            "auxcode": "SMJ",
            "companyName": "AVAVIA",
            "country": "Zimbabwe"
        },
        {
            "name": "Aerolineas Sol",
            "code": "",
            "auxcode": "SOD",
            "companyName": "ALSOL",
            "country": "Mexico"
        },
        {
            "name": "Air Soleil",
            "code": "",
            "auxcode": "SOE",
            "companyName": "AIR SOLEIL",
            "country": "Mauritania"
        },
        {
            "name": "Aero Soga",
            "code": "",
            "auxcode": "SOG",
            "companyName": "AEROSOGA",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Airspeed Aviation",
            "code": "",
            "auxcode": "SPD",
            "companyName": "SPEEDLINE",
            "country": "Canada"
        },
        {
            "name": "Air Service",
            "code": "M3",
            "auxcode": "SPJ",
            "companyName": "AIR SKOPJE",
            "country": "Macedonia"
        },
        {
            "name": "Aeroservicios Ejecutivos Del Pacifico",
            "code": "",
            "auxcode": "SPO",
            "companyName": "EJECTUIV PACIFICO",
            "country": "Mexico"
        },
        {
            "name": "Asian Aerospace Service",
            "code": "",
            "auxcode": "SPY",
            "companyName": "THAI SPACE",
            "country": "Thailand"
        },
        {
            "name": "Airworld",
            "code": "",
            "auxcode": "SPZ",
            "companyName": "SPEED SERVICE",
            "country": "South Africa"
        },
        {
            "name": "Alsaqer Aviation",
            "code": "",
            "auxcode": "SQR",
            "companyName": "ALSAQER AVIATION",
            "country": "Libya"
        },
        {
            "name": "Air Safaris and Services",
            "code": "",
            "auxcode": "SRI",
            "companyName": "AIRSAFARI",
            "country": "New Zealand"
        },
        {
            "name": "Aero Servicio Corporativo",
            "code": "",
            "auxcode": "SRV",
            "companyName": "SERVICORP",
            "country": "Mexico"
        },
        {
            "name": "Air Sultan",
            "code": "",
            "auxcode": "SSL",
            "companyName": "SIERRA SULTAN",
            "country": "Sierra Leone"
        },
        {
            "name": "Aero 1 ProcodeJet",
            "code": "",
            "auxcode": "SSM",
            "companyName": "RAPID",
            "country": "Canada"
        },
        {
            "name": "Airquarius Air Charter",
            "code": "",
            "auxcode": "SSN",
            "companyName": "SUNSTREAM",
            "country": "South Africa"
        },
        {
            "name": "Aeropac",
            "code": "",
            "auxcode": "STK",
            "companyName": "SAT PAK",
            "country": "United States"
        },
        {
            "name": "Air St. Thomas",
            "code": "",
            "auxcode": "STT",
            "companyName": "PARADISE",
            "country": "United States"
        },
        {
            "name": "Aerolineas Del Sureste",
            "code": "",
            "auxcode": "SUE",
            "companyName": "AEROSURESTE",
            "country": "Mexico"
        },
        {
            "name": "Aerial Surveys (1980) Limited",
            "code": "",
            "auxcode": "SUY",
            "companyName": "SURVEY",
            "country": "New Zealand"
        },
        {
            "name": "Air Slovakia",
            "code": "GM",
            "auxcode": "SVK",
            "companyName": "SLOVAKIA",
            "country": "Slovakia"
        },
        {
            "name": "Adler Aviation",
            "code": "",
            "auxcode": "SWH",
            "companyName": "SHOCKWAVE",
            "country": "Canada"
        },
        {
            "name": "Aircompany Yakutia",
            "code": "R3",
            "auxcode": "SYL",
            "companyName": "AIR YAKUTIA",
            "country": "Russia"
        },
        {
            "name": "Aerosud Aviation",
            "code": "",
            "auxcode": "SYT",
            "companyName": "SKYTRACK",
            "country": "South Africa"
        },
        {
            "name": "Aeroservicios de La Costa",
            "code": "",
            "auxcode": "TAA",
            "companyName": "AERO COSTA",
            "country": "Mexico"
        },
        {
            "name": "Aeromar",
            "code": "VW",
            "auxcode": "TAO",
            "companyName": "TRANScodeAEROMAR",
            "country": "Mexico"
        },
        {
            "name": "Aerotrebol",
            "code": "",
            "auxcode": "TBL",
            "companyName": "AEROTREBOL",
            "country": "Mexico"
        },
        {
            "name": "Aero Taxi de Los Cabos",
            "code": "",
            "auxcode": "TBO",
            "companyName": "AERO CABOS",
            "country": "Mexico"
        },
        {
            "name": "Air Turks and Caicos",
            "code": "JY",
            "auxcode": "TCI",
            "companyName": "KERRMONT",
            "country": "Turks and Caicos Islands"
        },
        {
            "name": "Aerotranscolombina de Carga",
            "code": "",
            "auxcode": "TCO",
            "companyName": "TRANSCOLOMBIA",
            "country": "Colombia"
        },
        {
            "name": "Air Cargo Express",
            "code": "",
            "auxcode": "TDG",
            "companyName": "TURBO DOG",
            "country": "United States"
        },
        {
            "name": "Atlas Helicopters",
            "code": "",
            "auxcode": "TDT",
            "companyName": "TRIDENT",
            "country": "United Kingdom"
        },
        {
            "name": "Air Today",
            "code": "",
            "auxcode": "TDY",
            "companyName": "AIR TODAY",
            "country": "United States"
        },
        {
            "name": "Aero Servicios Azteca",
            "code": "",
            "auxcode": "TED",
            "companyName": "AEROAZTECA",
            "country": "Mexico"
        },
        {
            "name": "Arkefly",
            "code": "OR",
            "auxcode": "TFL",
            "companyName": "ARKEFLY",
            "country": "Netherlands"
        },
        {
            "name": "Antair",
            "code": "",
            "auxcode": "TIR",
            "companyName": "ANTAIR",
            "country": "Mexico"
        },
        {
            "name": "Atlantique Air Assistance",
            "code": "",
            "auxcode": "TLB",
            "companyName": "TRIPLEcodeA",
            "country": "France"
        },
        {
            "name": "Aero Taxi Autlan",
            "code": "",
            "auxcode": "TLD",
            "companyName": "AEREO AUTLAN",
            "country": "Mexico"
        },
        {
            "name": "Aero Util",
            "code": "",
            "auxcode": "TLE",
            "companyName": "AEROUTIL",
            "country": "Mexico"
        },
        {
            "name": "Aero Toluca Internactional",
            "code": "",
            "auxcode": "TLU",
            "companyName": "AEROTOLUCA",
            "country": "Mexico"
        },
        {
            "name": "Aero Taxi del Centro de Mexico",
            "code": "",
            "auxcode": "TME",
            "companyName": "TAXICENTRO",
            "country": "Mexico"
        },
        {
            "name": "Aerotropical",
            "code": "",
            "auxcode": "TOC",
            "companyName": "TROPICMEX",
            "country": "Mexico"
        },
        {
            "name": "Air Tomisko",
            "code": "",
            "auxcode": "TOH",
            "companyName": "TOMISKO CARGO",
            "country": "Serbia"
        },
        {
            "name": "Airlines PNG",
            "code": "CG",
            "auxcode": "TOK",
            "companyName": "BALUS",
            "country": "Papua New Guinea"
        },
        {
            "name": "Aero Tanala",
            "code": "",
            "auxcode": "TON",
            "companyName": "AEROTANALA",
            "country": "Mexico"
        },
        {
            "name": "Aero Tropical",
            "code": "",
            "auxcode": "TPB",
            "companyName": "AERO TROPICAL",
            "country": "Angola"
        },
        {
            "name": "Air Cal",
            "code": "TY",
            "auxcode": "TPC",
            "companyName": "AIRCAL",
            "country": "France"
        },
        {
            "name": "Air Horizon",
            "code": "",
            "auxcode": "TPK",
            "companyName": "TCHADcodeHORIZON",
            "country": "Chad"
        },
        {
            "name": "Aero Taxi del Potosi",
            "code": "",
            "auxcode": "TPO",
            "companyName": "TAXIcodePOTOSI",
            "country": "Mexico"
        },
        {
            "name": "Aeroturquesa",
            "code": "",
            "auxcode": "TQS",
            "companyName": "AEROTURQUESA",
            "country": "Mexico"
        },
        {
            "name": "Airmark Aviation",
            "code": "",
            "auxcode": "TRH",
            "companyName": "TRANSTAR",
            "country": "United States"
        },
        {
            "name": "AirTran Airways",
            "code": "FL",
            "auxcode": "TRS",
            "companyName": "CITRUS",
            "country": "United States"
        },
        {
            "name": "Air Transat",
            "code": "TS",
            "auxcode": "TSC",
            "companyName": "TRANSAT",
            "country": "Canada"
        },
        {
            "name": "airtransse",
            "code": "",
            "auxcode": "TSQ",
            "companyName": "AIRTRA",
            "country": "Japan"
        },
        {
            "name": "Aerolineas Turisticas del Caribe",
            "code": "",
            "auxcode": "TTB",
            "companyName": "AERO TURISTICAS",
            "country": "Mexico"
        },
        {
            "name": "Avcenter",
            "code": "",
            "auxcode": "TTE",
            "companyName": "TETON",
            "country": "United States"
        },
        {
            "name": "Air Tungaru",
            "code": "",
            "auxcode": "TUN",
            "companyName": "TUNGARU",
            "country": "Kiribati"
        },
        {
            "name": "Avialeasing Aviation Company",
            "code": "EC",
            "auxcode": "TWN",
            "companyName": "TWINARROW",
            "country": "Uzbekistan"
        },
        {
            "name": "Aerotaxis del Noroeste",
            "code": "",
            "auxcode": "TXD",
            "companyName": "TAXI OESTE",
            "country": "Mexico"
        },
        {
            "name": "Aerotaxis Alfe",
            "code": "",
            "auxcode": "TXF",
            "companyName": "ALFE",
            "country": "Mexico"
        },
        {
            "name": "Aereotaxis",
            "code": "",
            "auxcode": "TXI",
            "companyName": "AEREOTAXIS",
            "country": "Mexico"
        },
        {
            "name": "Tyrolean Airways",
            "code": "VO",
            "auxcode": "TYR",
            "companyName": "TYROLEAN",
            "country": "Austria"
        },
        {
            "name": "Aero Tomza",
            "code": "",
            "auxcode": "TZA",
            "companyName": "AERO TOMZA",
            "country": "Mexico"
        },
        {
            "name": "Air Zambezi",
            "code": "",
            "auxcode": "TZT",
            "companyName": "ZAMBEZI",
            "country": "Zimbabwe"
        },
        {
            "name": "Afra Airlines",
            "code": "",
            "auxcode": "UAG",
            "companyName": "AFRALINE",
            "country": "Ghana"
        },
        {
            "name": "Aerostar Airlines",
            "code": "",
            "auxcode": "UAR",
            "companyName": "AEROSTAR",
            "country": "Ukraine"
        },
        {
            "name": "Air Division of the Eastern Kazakhstan Region",
            "code": "",
            "auxcode": "UCK",
            "companyName": "GALETA",
            "country": "Kazakhstan"
        },
        {
            "name": "AerocodeCharter Ukraine",
            "code": "DW",
            "auxcode": "UCR",
            "companyName": "CHARTER UKRAINE",
            "country": "Ukraine"
        },
        {
            "name": "Air LA",
            "code": "",
            "auxcode": "UED",
            "companyName": "AIR LcodeA",
            "country": "United States"
        },
        {
            "name": "Air Ukraine",
            "code": "6U",
            "auxcode": "UKR",
            "companyName": "AIR UKRAINE",
            "country": "Ukraine"
        },
        {
            "name": "Air Umbria",
            "code": "",
            "auxcode": "UMB",
            "companyName": "AIR UMBRIA",
            "country": "Italy"
        },
        {
            "name": "Atuneros Unidos de California",
            "code": "",
            "auxcode": "UND",
            "companyName": "ATUNEROS UNIDOS",
            "country": "Mexico"
        },
        {
            "name": "Aeroclub Flaps",
            "code": "",
            "auxcode": "FLP",
            "companyName": "AEROCLUB FLAPS",
            "country": "Spain"
        },
        {
            "name": "Aerolineas Galapagos (Aerogal)",
            "code": "2K",
            "auxcode": "GLG",
            "companyName": "AEROGAL",
            "country": "Ecuador"
        },
        {
            "name": "Air Castle Corporation / Global Airways",
            "code": "",
            "auxcode": "GLB",
            "companyName": "GLOcodeAIR",
            "country": "United States"
        },
        {
            "name": "Alrosa Mirny Air Enterprise",
            "code": "6R",
            "auxcode": "DRU",
            "companyName": "MIRNY",
            "country": "Russia"
        },
        {
            "name": "Bearing Supplies Limited",
            "code": "",
            "auxcode": "PVO",
            "companyName": "PROVOST",
            "country": "United Kingdom"
        },
        {
            "name": "Balkan Agro Aviation",
            "code": "",
            "auxcode": "BAA",
            "companyName": "BALKAN AGRO",
            "country": "Bulgaria"
        },
        {
            "name": "Bahrain Air BSC (Closed)",
            "code": "",
            "auxcode": "BAB",
            "companyName": "AWAL",
            "country": "Bahrain"
        },
        {
            "name": "BAC Leasing Limited",
            "code": "",
            "auxcode": "BAC",
            "companyName": " ",
            "country": "United Kingdom"
        },
        {
            "name": "BAE Systems",
            "code": "",
            "auxcode": "BAE",
            "companyName": "FELIX",
            "country": "United Kingdom"
        },
        {
            "name": "Belgian Air Force",
            "code": "",
            "auxcode": "BAF",
            "companyName": "BELGIAN AIRFORCE",
            "country": "Belgium"
        },
        {
            "name": "Baker Aviation",
            "code": "8Q",
            "auxcode": "BAJ",
            "companyName": "BAKER AVIATION",
            "country": "United States"
        },
        {
            "name": "Blackhawk Airways",
            "code": "",
            "auxcode": "BAK",
            "companyName": "BLACKHAWK",
            "country": "United States"
        },
        {
            "name": "Britannia Airways",
            "code": "",
            "auxcode": "BAL",
            "companyName": "BRITANNIA",
            "country": "United Kingdom"
        },
        {
            "name": "Business Air Services",
            "code": "",
            "auxcode": "BAM",
            "companyName": "BUSINESS AIR",
            "country": "Canada"
        },
        {
            "name": "British Antarctic Survey",
            "code": "",
            "auxcode": "BAN",
            "companyName": "PENGUIN",
            "country": "United Kingdom"
        },
        {
            "name": "Bradly Air (Charter) Services",
            "code": "",
            "auxcode": "BAR",
            "companyName": "BRADLEY",
            "country": "Canada"
        },
        {
            "name": "Bissau Airlines",
            "code": "",
            "auxcode": "BAU",
            "companyName": "AIR BISSAU",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Bay Aviation Ltd",
            "code": "",
            "auxcode": "BAV",
            "companyName": "BAY AIR",
            "country": "Bangladesh"
        },
        {
            "name": "British Airways",
            "code": "BA",
            "auxcode": "BAW",
            "companyName": "SPEEDBIRD",
            "country": "United Kingdom"
        },
        {
            "name": "Best Aero Handling Ltd",
            "code": "",
            "auxcode": "BAX",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Bayon Airlines",
            "code": "",
            "auxcode": "BAY",
            "companyName": "BAYON",
            "country": "Cambodia"
        },
        {
            "name": "Bannert Air",
            "code": "",
            "auxcode": "BBA",
            "companyName": "BANAIR",
            "country": "Austria"
        },
        {
            "name": "Biman Bangladesh Airlines",
            "code": "BG",
            "auxcode": "BBC",
            "companyName": "BANGLADESH",
            "country": "Bangladesh"
        },
        {
            "name": "Bluebird Cargo",
            "code": "BF",
            "auxcode": "BBD",
            "companyName": "BLUE CARGO",
            "country": "Iceland"
        },
        {
            "name": "Beibars CJSC",
            "code": "",
            "auxcode": "BBS",
            "companyName": "BEIBARS",
            "country": "Kazakhstan"
        },
        {
            "name": "Bravo Airlines",
            "code": "",
            "auxcode": "BBV",
            "companyName": "BRAVO EUROPE",
            "country": "Spain"
        },
        {
            "name": "Blue Bird Aviation",
            "code": "",
            "auxcode": "BBZ",
            "companyName": "COBRA",
            "country": "Kenya"
        },
        {
            "name": "BACH Flugbetriebsges",
            "code": "B4",
            "auxcode": "BCF",
            "companyName": "BACH",
            "country": "Austria"
        },
        {
            "name": "Blue Islands",
            "code": "",
            "auxcode": "BCI",
            "companyName": "BLUE ISLAND",
            "country": "United Kingdom"
        },
        {
            "name": "British Caribbean Airways",
            "code": "",
            "auxcode": "BCL",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "British Charter",
            "code": "",
            "auxcode": "BCR",
            "companyName": "BACKER",
            "country": "United Kingdom"
        },
        {
            "name": "BCT Aviation",
            "code": "",
            "auxcode": "BCT",
            "companyName": "BOBCAT",
            "country": "United Kingdom"
        },
        {
            "name": "Business Aviation Center",
            "code": "",
            "auxcode": "BCV",
            "companyName": "BUSINESS AVIATION",
            "country": "Ukraine"
        },
        {
            "name": "Blue Dart Aviation",
            "code": "BZ",
            "auxcode": "BDA",
            "companyName": "BLUE DART",
            "country": "India"
        },
        {
            "name": "B&H Airlines",
            "code": "JA",
            "auxcode": "BON",
            "companyName": "Air Bosna",
            "country": "Bosnia and Herzegovina"
        },
        {
            "name": "Bissau Discovery Flying Club",
            "code": "",
            "auxcode": "BDF",
            "companyName": "BISSAU DISCOVERY",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Belgian Army",
            "code": "",
            "auxcode": "AYB",
            "companyName": "BELGIAN ARMY",
            "country": "Belgium"
        },
        {
            "name": "Badr Airlines",
            "code": "",
            "auxcode": "BDR",
            "companyName": "BADR AIR",
            "country": "Sudan"
        },
        {
            "name": "Best Aviation Ltd",
            "code": "",
            "auxcode": "BEA",
            "companyName": "BEST AIR",
            "country": "Bangladesh"
        },
        {
            "name": "Belgorod Aviation Enterprise",
            "code": "",
            "auxcode": "BED",
            "companyName": "BELOGORYE",
            "country": "Russia"
        },
        {
            "name": "Bar Harbor Airlines",
            "code": "",
            "auxcode": "AJC",
            "companyName": "BAR HARBOR",
            "country": "United States"
        },
        {
            "name": "Balear Express",
            "code": "",
            "auxcode": "BEF",
            "companyName": "BALEAR EXPRESS",
            "country": "Spain"
        },
        {
            "name": "Bel Air Helicopters",
            "code": "",
            "auxcode": "BEH",
            "companyName": "BLUECOPTER",
            "country": "Denmark"
        },
        {
            "name": "Berkut Air",
            "code": "",
            "auxcode": "BEK",
            "companyName": "BERKUT",
            "country": "Kazakhstan"
        },
        {
            "name": "BETA code Brazilian Express Transportes Aereos",
            "code": "",
            "auxcode": "BET",
            "companyName": "BETA CARGO",
            "country": "Brazil"
        },
        {
            "name": "Basler Flight Service",
            "code": "",
            "auxcode": "BFC",
            "companyName": "BASLER",
            "country": "United States"
        },
        {
            "name": "Bear Flight",
            "code": "",
            "auxcode": "BFG",
            "companyName": "BEARFLIGHT",
            "country": "Sweden"
        },
        {
            "name": "Buffalo Airways",
            "code": "J4",
            "auxcode": "BFL",
            "companyName": "BUFFALO",
            "country": "Canada"
        },
        {
            "name": "Bombardier",
            "code": "",
            "auxcode": "BFO",
            "companyName": "BOMBARDIER",
            "country": "Canada"
        },
        {
            "name": "Burkina Airlines",
            "code": "",
            "auxcode": "BFR",
            "companyName": "BURKLINES",
            "country": "Burkina Faso"
        },
        {
            "name": "Business Flight Sweden",
            "code": "",
            "auxcode": "BFS",
            "companyName": "BUSINESS FLIGHT",
            "country": "Sweden"
        },
        {
            "name": "Bahrain Defence Force",
            "code": "",
            "auxcode": "BFW",
            "companyName": "SUMMAN",
            "country": "Bahrain"
        },
        {
            "name": "BH Air",
            "code": "",
            "auxcode": "BGH",
            "companyName": "BALKAN HOLIDAYS",
            "country": "Bulgaria"
        },
        {
            "name": "British Gulf International",
            "code": "",
            "auxcode": "BGI",
            "companyName": "BRITISH GULF",
            "country": "S"
        },
        {
            "name": "British Gulf InternationalcodeFez",
            "code": "",
            "auxcode": "BGK",
            "companyName": "GULF INTER",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Benin Golf Air",
            "code": "A8",
            "auxcode": "BGL",
            "companyName": "BENIN GOLF",
            "country": "Benin"
        },
        {
            "name": "Bugulma Air Enterprise",
            "code": "",
            "auxcode": "BGM",
            "companyName": "BUGAVIA",
            "country": "Russia"
        },
        {
            "name": "Budget Air Bangladesh",
            "code": "",
            "auxcode": "BGR",
            "companyName": "BUDGET AIR",
            "country": "Bangladesh"
        },
        {
            "name": "Bergen Air Transport",
            "code": "",
            "auxcode": "BGT",
            "companyName": "BERGEN AIR",
            "country": "Norway"
        },
        {
            "name": "Buddha Air",
            "code": "",
            "auxcode": "BHA",
            "companyName": "BUDDHA AIR",
            "country": "Nepal"
        },
        {
            "name": "Balkh Airlines",
            "code": "",
            "auxcode": "BHI",
            "companyName": "SHARIF",
            "country": "Afghanistan"
        },
        {
            "name": "Bristow Helicopters",
            "code": "",
            "auxcode": "BHL",
            "companyName": "BRISTOW",
            "country": "United Kingdom"
        },
        {
            "name": "Bristow Helicopters Nigeria",
            "code": "",
            "auxcode": "BHN",
            "companyName": "BRISTOW HELICOPTERS",
            "country": "Nigeria"
        },
        {
            "name": "Bhoja Airlines",
            "code": "",
            "auxcode": "BHO",
            "companyName": "BHOJA",
            "country": "Pakistan"
        },
        {
            "name": "Belair Airlines",
            "code": "4T",
            "auxcode": "BHP",
            "companyName": "BELAIR",
            "country": "Switzerland"
        },
        {
            "name": "Bighorn Airways",
            "code": "",
            "auxcode": "BHR",
            "companyName": "BIGHORN AIR",
            "country": "United States"
        },
        {
            "name": "Bahamasair",
            "code": "UP",
            "auxcode": "BHS",
            "companyName": "BAHAMAS",
            "country": "Bahamas"
        },
        {
            "name": "Bright Air",
            "code": "",
            "auxcode": "BHT",
            "companyName": "BRIGHTAIR",
            "country": "Netherlands"
        },
        {
            "name": "Bringer Air Cargo Taxi Aereo",
            "code": "E6",
            "auxcode": "",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "Balkan Bulgarian Airlines",
            "code": "LZ",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "BA Connect",
            "code": "TH",
            "auxcode": "",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Bosphorus European Airways",
            "code": "",
            "auxcode": "BHY",
            "companyName": "BOSPHORUS",
            "country": "Turkey"
        },
        {
            "name": "Binair",
            "code": "",
            "auxcode": "BID",
            "companyName": "BINAIR",
            "country": "Germany"
        },
        {
            "name": "Big Island Air",
            "code": "",
            "auxcode": "BIG",
            "companyName": "BIG ISLE",
            "country": "United States"
        },
        {
            "name": "British International Helicopters",
            "code": "BS",
            "auxcode": "BIH",
            "companyName": "BRINTEL",
            "country": "United Kingdom"
        },
        {
            "name": "Billund Air Center",
            "code": "",
            "auxcode": "BIL",
            "companyName": "BILAIR",
            "country": "Denmark"
        },
        {
            "name": "Boise Interagency Fire Center",
            "code": "",
            "auxcode": "BIN",
            "companyName": "BISONcodeAIR",
            "country": "United States"
        },
        {
            "name": "Bird Leasing",
            "code": "",
            "auxcode": "BIR",
            "companyName": "BIRD AIR",
            "country": "United States"
        },
        {
            "name": "Bizjet Ltd",
            "code": "",
            "auxcode": "BIZ",
            "companyName": "BIZZ",
            "country": "United Kingdom"
        },
        {
            "name": "Baja Air",
            "code": "",
            "auxcode": "BJA",
            "companyName": "BAJA AIR",
            "country": "Mexico"
        },
        {
            "name": "Baltic Jet Aircompany",
            "code": "",
            "auxcode": "BJC",
            "companyName": "BALTIC JET",
            "country": "Latvia"
        },
        {
            "name": "Business Jet Solutions",
            "code": "",
            "auxcode": "BJS",
            "companyName": "SOLUTION",
            "country": "United States"
        },
        {
            "name": "Bankair",
            "code": "B4",
            "auxcode": "BKA",
            "companyName": "BANKAIR",
            "country": "United States"
        },
        {
            "name": "BFcodeLento OY",
            "code": "",
            "auxcode": "BKF",
            "companyName": "BAKERFLIGHT",
            "country": "Finland"
        },
        {
            "name": "Barken International",
            "code": "",
            "auxcode": "BKJ",
            "companyName": "BARKEN JET",
            "country": "United States"
        },
        {
            "name": "Bangkok Airways",
            "code": "PG",
            "auxcode": "BKP",
            "companyName": "BANGKOK AIR",
            "country": "Thailand"
        },
        {
            "name": "Bukovyna",
            "code": "",
            "auxcode": "BKV",
            "companyName": "BUKOVYNA",
            "country": "Ukraine"
        },
        {
            "name": "Blue Bird Aviation",
            "code": "",
            "auxcode": "BLB",
            "companyName": "BLUEBIRD SUDAN",
            "country": "Sudan"
        },
        {
            "name": "Bellesavia",
            "code": "",
            "auxcode": "BLC",
            "companyName": "BELLESAVIA",
            "country": "Belarus"
        },
        {
            "name": "Blue Line",
            "code": "",
            "auxcode": "BLE",
            "companyName": "BLUE BERRY",
            "country": "France"
        },
        {
            "name": "Blue1",
            "code": "KF",
            "auxcode": "BLF",
            "companyName": "BLUEFIN",
            "country": "Finland"
        },
        {
            "name": "Belgavia",
            "code": "",
            "auxcode": "BLG",
            "companyName": "BELGAVIA",
            "country": "Belgium"
        },
        {
            "name": "Blue Horizon Travel Club",
            "code": "",
            "auxcode": "BLH",
            "companyName": "BLUE HORIZON",
            "country": "United States"
        },
        {
            "name": "Blue Jet",
            "code": "",
            "auxcode": "BLJ",
            "companyName": "BLUEWAY",
            "country": "Spain"
        },
        {
            "name": "Baltic Airlines",
            "code": "",
            "auxcode": "BLL",
            "companyName": "BALTIC AIRLINES",
            "country": "Russia"
        },
        {
            "name": "Blue Airways",
            "code": "",
            "auxcode": "BLM",
            "companyName": "BLUE ARMENIA",
            "country": "Armenia"
        },
        {
            "name": "Bali International Air Service",
            "code": "",
            "auxcode": "BLN",
            "companyName": "BIAR",
            "country": "Indonesia"
        },
        {
            "name": "Bearskin Lake Air Service",
            "code": "JV",
            "auxcode": "BLS",
            "companyName": "BEARSKIN",
            "country": "Canada"
        },
        {
            "name": "Baltic Aviation",
            "code": "",
            "auxcode": "BLT",
            "companyName": "BALTAIR",
            "country": "United States"
        },
        {
            "name": "Bellview Airlines",
            "code": "B3",
            "auxcode": "BLV",
            "companyName": "BELLVIEW AIRLINES",
            "country": "Nigeria"
        },
        {
            "name": "bmi",
            "code": "BD",
            "auxcode": "BMA",
            "companyName": "MIDLAND",
            "country": "United Kingdom"
        },
        {
            "name": "British Medical Charter",
            "code": "",
            "auxcode": "BMD",
            "companyName": "BRITISH MEDICAL",
            "country": "United Kingdom"
        },
        {
            "name": "Briggs Marine Environmental Services",
            "code": "",
            "auxcode": "BME",
            "companyName": "BRIGGS",
            "country": "United Kingdom"
        },
        {
            "name": "Bristow Masayu Helicopters",
            "code": "",
            "auxcode": "BMH",
            "companyName": "MASAYU",
            "country": "Indonesia"
        },
        {
            "name": "bmibaby",
            "code": "WW",
            "auxcode": "BMI",
            "companyName": "BABY",
            "country": "United Kingdom"
        },
        {
            "name": "Bemidji Airlines",
            "code": "CH",
            "auxcode": "BMJ",
            "companyName": "BEMIDJI",
            "country": "United States"
        },
        {
            "name": "Bismillah Airlines",
            "code": "5Z",
            "auxcode": "BML",
            "companyName": "BISMILLAH",
            "country": "Bangladesh"
        },
        {
            "name": "Bowman Aviation",
            "code": "",
            "auxcode": "BMN",
            "companyName": "BOWMAN",
            "country": "United States"
        },
        {
            "name": "British Midland Regional",
            "code": "",
            "auxcode": "BMR",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "BMW",
            "code": "",
            "auxcode": "BMW",
            "companyName": "BMWcodeFLIGHT",
            "country": "Germany"
        },
        {
            "name": "Banco de Mexico",
            "code": "",
            "auxcode": "BMX",
            "companyName": "BANXICO",
            "country": "Mexico"
        },
        {
            "name": "Bond Offshore Helicopters",
            "code": "",
            "auxcode": "BND",
            "companyName": "BOND",
            "country": "United Kingdom"
        },
        {
            "name": "Benina Air",
            "code": "",
            "auxcode": "BNE",
            "companyName": "BENINA AIR",
            "country": "Libya"
        },
        {
            "name": "BN Group Limited",
            "code": "",
            "auxcode": "BNG",
            "companyName": "VECTIS",
            "country": "United Kingdom"
        },
        {
            "name": "Blue Nile Ethiopia Trading",
            "code": "",
            "auxcode": "BNL",
            "companyName": "NILE TRADING",
            "country": "Ethiopia"
        },
        {
            "name": "Bonair Aviation",
            "code": "",
            "auxcode": "BNR",
            "companyName": "BONAIR",
            "country": "Canada"
        },
        {
            "name": "Bancstar code Valley National Corporation",
            "code": "",
            "auxcode": "BNS",
            "companyName": "BANCSTAR",
            "country": "United States"
        },
        {
            "name": "Bentiu Air Transport",
            "code": "",
            "auxcode": "BNT",
            "companyName": "BENTIU AIR",
            "country": "Sudan"
        },
        {
            "name": "Benane Aviation Corporation",
            "code": "",
            "auxcode": "BNV",
            "companyName": "BENANE",
            "country": "Mauritania"
        },
        {
            "name": "British North West Airlines",
            "code": "",
            "auxcode": "BNW",
            "companyName": "BRITISH NORTH",
            "country": "United Kingdom"
        },
        {
            "name": "Boniair",
            "code": "",
            "auxcode": "BOA",
            "companyName": "KUMANOVO",
            "country": "Macedonia"
        },
        {
            "name": "Bond Air Services",
            "code": "",
            "auxcode": "BOD",
            "companyName": "UGABOND",
            "country": "Uganda"
        },
        {
            "name": "Boeing",
            "code": "",
            "auxcode": "BOE",
            "companyName": "BOEING",
            "country": "United States"
        },
        {
            "name": "Bordaire",
            "code": "",
            "auxcode": "BOF",
            "companyName": "BORDAIR",
            "country": "Canada"
        },
        {
            "name": "Bookajet Limited",
            "code": "",
            "auxcode": "BOO",
            "companyName": "BOOKAJET",
            "country": "United Kingdom"
        },
        {
            "name": "Bouraq Indonesia Airlines",
            "code": "BO",
            "auxcode": "BOU",
            "companyName": "BOURAQ",
            "country": "Indonesia"
        },
        {
            "name": "Blue Panorama Airlines",
            "code": "BV",
            "auxcode": "BPA",
            "companyName": "BLUE PANOROMA",
            "country": "Italy"
        },
        {
            "name": "Berkhut ZK",
            "code": "",
            "auxcode": "BPK",
            "companyName": "VENERA",
            "country": "Kazakhstan"
        },
        {
            "name": "BundepolizeicodeFliegertruppe",
            "code": "",
            "auxcode": "BPO",
            "companyName": "PIROL",
            "country": "Germany"
        },
        {
            "name": "Budapest Aircraft Services/Manx2",
            "code": "",
            "auxcode": "BPS",
            "companyName": "BASE",
            "country": "Hungary"
        },
        {
            "name": "Bonus Aviation",
            "code": "",
            "auxcode": "BPT",
            "companyName": "BONUS",
            "country": "United Kingdom"
        },
        {
            "name": "British Petroleum Exploration",
            "code": "",
            "auxcode": "BPX",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "BRAcodeTransportes Aereos",
            "code": "7R",
            "auxcode": "BRB",
            "companyName": "BRAcodeTRANSPAEREOS",
            "country": "Brazil"
        },
        {
            "name": "Brock Air Services",
            "code": "",
            "auxcode": "BRD",
            "companyName": "BROCK AIR",
            "country": "Canada"
        },
        {
            "name": "Breeze Ltd",
            "code": "",
            "auxcode": "BRE",
            "companyName": "AVIABREEZE",
            "country": "Ukraine"
        },
        {
            "name": "Bering Air",
            "code": "8E",
            "auxcode": "BRG",
            "companyName": "BERING AIR",
            "country": "United States"
        },
        {
            "name": "Briansk State Air Enterprise",
            "code": "",
            "auxcode": "BRK",
            "companyName": "BRIANSKcodeAVIA",
            "country": "Russia"
        },
        {
            "name": "Branson Airlines",
            "code": "",
            "auxcode": "BRN",
            "companyName": "BRANSON",
            "country": "United States"
        },
        {
            "name": "BASE Regional Airlines",
            "code": "",
            "auxcode": "BRO",
            "companyName": "COASTRIDER",
            "country": "Netherlands"
        },
        {
            "name": "Brazilian Air Force",
            "code": "",
            "auxcode": "BRS",
            "companyName": "BRAZILIAN AIR FORCE",
            "country": "Brazil"
        },
        {
            "name": "British Regional Airlines",
            "code": "",
            "auxcode": "BRT",
            "companyName": "BRITISH",
            "country": "United Kingdom"
        },
        {
            "name": "Belavia Belarusian Airlines",
            "code": "B2",
            "auxcode": "BRU",
            "companyName": "BELARUS AVIA",
            "country": "Belarus"
        },
        {
            "name": "Bravo Air Congo",
            "code": "K6",
            "auxcode": "BRV",
            "companyName": "BRAVO",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Bright Aviation Services",
            "code": "",
            "auxcode": "BRW",
            "companyName": "BRIGHT SERVICES",
            "country": "Bulgaria"
        },
        {
            "name": "Braniff International Airways",
            "code": "BN",
            "auxcode": "BNF",
            "companyName": "Braniff",
            "country": "United States"
        },
        {
            "name": "Buffalo Express Airlines",
            "code": "",
            "auxcode": "BRX",
            "companyName": "BUFF EXPRESS",
            "country": "United States"
        },
        {
            "name": "Burundayavia",
            "code": "",
            "auxcode": "BRY",
            "companyName": "BURAIR",
            "country": "Kazakhstan"
        },
        {
            "name": "Bistair code Fez",
            "code": "",
            "auxcode": "BSC",
            "companyName": "BIG SHOT",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Blue Star Airlines",
            "code": "",
            "auxcode": "BSD",
            "companyName": "AIRLINES STAR",
            "country": "Mexico"
        },
        {
            "name": "Brasair Transportes Aereos",
            "code": "",
            "auxcode": "BSI",
            "companyName": "BRASAIR",
            "country": "Brazil"
        },
        {
            "name": "Blue Swann Aviation",
            "code": "",
            "auxcode": "BSJ",
            "companyName": "BLUE SWANN",
            "country": "United Kingdom"
        },
        {
            "name": "Blue Sky Aviation",
            "code": "",
            "auxcode": "BSM",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "Bissau Aero Transporte",
            "code": "",
            "auxcode": "BSS",
            "companyName": "BISSAU AIRSYSTEM",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Best Air",
            "code": "",
            "auxcode": "BST",
            "companyName": "TUNCA",
            "country": "Turkey"
        },
        {
            "name": "Blue Sky Airways",
            "code": "",
            "auxcode": "BSW",
            "companyName": "SKY BLUE",
            "country": "Czech Republic"
        },
        {
            "name": "Big Sky Airlines",
            "code": "GQ",
            "auxcode": "BSY",
            "companyName": "BIG SKY",
            "country": "United States"
        },
        {
            "name": "BAL Bashkirian Airlines",
            "code": "V9",
            "auxcode": "BTC",
            "companyName": "BASHKIRIAN",
            "country": "Russia"
        },
        {
            "name": "Baltijas Helicopters",
            "code": "",
            "auxcode": "BTH",
            "companyName": "BALTIJAS HELICOPTERS",
            "country": "Latvia"
        },
        {
            "name": "Baltyka",
            "code": "",
            "auxcode": "BTK",
            "companyName": "BALTYKA",
            "country": "Ukraine"
        },
        {
            "name": "Baltia Air Lines",
            "code": "",
            "auxcode": "BTL",
            "companyName": "BALTIA FLIGHT",
            "country": "United States"
        },
        {
            "name": "BotircodeAvia",
            "code": "",
            "auxcode": "BTR",
            "companyName": "BOTIRcodeAVIA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Business Express",
            "code": "",
            "auxcode": "GAA",
            "companyName": "BIZEX",
            "country": "United States"
        },
        {
            "name": "BTcodeSlavuta",
            "code": "",
            "auxcode": "BTT",
            "companyName": "BEETEEcodeSLAVUTA",
            "country": "Ukraine"
        },
        {
            "name": "Metro Batavia",
            "code": "7P",
            "auxcode": "BTV",
            "companyName": "BATAVIA",
            "country": "Indonesia"
        },
        {
            "name": "Bulgarian Air Charter",
            "code": "",
            "auxcode": "BUC",
            "companyName": "BULGARIAN CHARTER",
            "country": "Bulgaria"
        },
        {
            "name": "Blue Airlines",
            "code": "",
            "auxcode": "BUL",
            "companyName": "BLUE AIRLINES",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Buryat Airlines Aircompany",
            "code": "",
            "auxcode": "BUN",
            "companyName": "BURAL",
            "country": "Russia"
        },
        {
            "name": "Buzz Stansted",
            "code": "",
            "auxcode": "BUZ",
            "companyName": "BUZZ",
            "country": "United Kingdom"
        },
        {
            "name": "Buffalo Airways",
            "code": "",
            "auxcode": "BVA",
            "companyName": "BUFFALO AIR",
            "country": "United States"
        },
        {
            "name": "Bulgarian Aeronautical Centre",
            "code": "",
            "auxcode": "BVC",
            "companyName": "BULGARIAN WINGS",
            "country": "Bulgaria"
        },
        {
            "name": "Baron Aviation Services",
            "code": "",
            "auxcode": "BVN",
            "companyName": "SHOWcodeME",
            "country": "United States"
        },
        {
            "name": "Berjaya Air",
            "code": "J8",
            "auxcode": "BVT",
            "companyName": "BERJAYA",
            "country": "Malaysia"
        },
        {
            "name": "Bellview Airlines",
            "code": "",
            "auxcode": "BVU",
            "companyName": " Sierra Leone",
            "country": ""
        },
        {
            "name": "Blue Wings",
            "code": "QW",
            "auxcode": "BWG",
            "companyName": "BLUE WINGS",
            "country": "Germany"
        },
        {
            "name": "Blue Wing Airlines",
            "code": "",
            "auxcode": "BWI",
            "companyName": "BLUE TAIL",
            "country": "Suriname"
        },
        {
            "name": "BWA (2002) Ltd",
            "code": "",
            "auxcode": "BWL",
            "companyName": "BRITWORLD",
            "country": "United Kingdom"
        },
        {
            "name": "Bahrain Executive Air Services",
            "code": "",
            "auxcode": "BXA",
            "companyName": "BEXAIR",
            "country": "Bahrain"
        },
        {
            "name": "Bar XH Air",
            "code": "",
            "auxcode": "BXH",
            "companyName": "PALLISER",
            "country": "Canada"
        },
        {
            "name": "Brussels International Airlines",
            "code": "",
            "auxcode": "BXI",
            "companyName": "XENIA",
            "country": "Belgium"
        },
        {
            "name": "BAX Global",
            "code": "8W",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "Redding Aero Enterprises",
            "code": "",
            "auxcode": "BXR",
            "companyName": "BOXER",
            "country": "United States"
        },
        {
            "name": "Berry Aviation",
            "code": "",
            "auxcode": "BYA",
            "companyName": "BERRY",
            "country": "United States"
        },
        {
            "name": "Bylina JointcodeStock Company",
            "code": "",
            "auxcode": "BYL",
            "companyName": "BYLINA",
            "country": "Russia"
        },
        {
            "name": "Berytos Airlines",
            "code": "",
            "auxcode": "BYR",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "Bayu Indonesia Air",
            "code": "BM",
            "auxcode": "BYE",
            "companyName": "BAYU",
            "country": "Indonesia"
        },
        {
            "name": "Bizair Fluggesellschaft",
            "code": "",
            "auxcode": "BZA",
            "companyName": "BERLIN BEAR",
            "country": "Germany"
        },
        {
            "name": "Brit Air",
            "code": "DB",
            "auxcode": "BZH",
            "companyName": "BRITAIR",
            "country": "France"
        },
        {
            "name": "Butane Buzzard Aviation Corporation",
            "code": "",
            "auxcode": "BZZ",
            "companyName": "BUZZARD",
            "country": "United Kingdom"
        },
        {
            "name": "Business Flight Salzburg",
            "code": "",
            "auxcode": "AUJ",
            "companyName": "AUSTROJET",
            "country": "Austria"
        },
        {
            "name": "BKS Air (Rivaflecha)",
            "code": "",
            "auxcode": "CKM",
            "companyName": "COSMOS",
            "country": "Spain"
        },
        {
            "name": "Bristol Flying Centre",
            "code": "",
            "auxcode": "CLF",
            "companyName": "CLIFTON",
            "country": "United Kingdom"
        },
        {
            "name": "Barnes Olsen Aeroleasing",
            "code": "",
            "auxcode": "CLN",
            "companyName": "SEELINE",
            "country": "United Kingdom"
        },
        {
            "name": "Baltimore Air Transport",
            "code": "",
            "auxcode": "CPJ",
            "companyName": "CORPJET",
            "country": "United States"
        },
        {
            "name": "BostoncodeMaine Airways",
            "code": "E9",
            "auxcode": "CXS",
            "companyName": "CLIPPER CONNECTION",
            "country": "United States"
        },
        {
            "name": "Brussels Airlines",
            "code": "SN",
            "auxcode": "DAT",
            "companyName": "BEEcodeLINE",
            "country": "Belgium"
        },
        {
            "name": "Baltimore Airways",
            "code": "",
            "auxcode": "EAH",
            "companyName": "EASTERN",
            "country": "United States"
        },
        {
            "name": "Bond Aviation",
            "code": "",
            "auxcode": "EBA",
            "companyName": "BOND AVIATION",
            "country": "Italy"
        },
        {
            "name": "Brazilian Army Aviation",
            "code": "",
            "auxcode": "EXB",
            "companyName": "BRAZILIAN ARMY",
            "country": "Brazil"
        },
        {
            "name": "Business Express Delivery",
            "code": "",
            "auxcode": "EXP",
            "companyName": "EXPRESS AIR",
            "country": "Canada"
        },
        {
            "name": "Bel Limited",
            "code": "",
            "auxcode": "FOS",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Bangkok Aviation Center",
            "code": "",
            "auxcode": "HAW",
            "companyName": "THAI HAWK",
            "country": "Thailand"
        },
        {
            "name": "Benair",
            "code": "",
            "auxcode": "HAX",
            "companyName": "SCOOP",
            "country": "Norway"
        },
        {
            "name": "Binter Canarias",
            "code": "NT",
            "auxcode": "IBB",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Bonyad Airlines",
            "code": "",
            "auxcode": "IRJ",
            "companyName": "BONYAD AIR",
            "country": "Iran"
        },
        {
            "name": "Burundaiavia",
            "code": "",
            "auxcode": "IVR",
            "companyName": "RERUN",
            "country": "Kazakhstan"
        },
        {
            "name": "Blue Air",
            "code": "0B",
            "auxcode": "JOR",
            "companyName": "BLUE TRANSPORT",
            "country": "Romania"
        },
        {
            "name": "British Mediterranean Airways",
            "code": "KJ",
            "auxcode": "LAJ",
            "companyName": "BEE MED",
            "country": "United Kingdom"
        },
        {
            "name": "Belle Air",
            "code": "",
            "auxcode": "LBY",
            "companyName": "ALBANcodeBELLE",
            "country": "Albania"
        },
        {
            "name": "Blom Geomatics",
            "code": "",
            "auxcode": "LED",
            "companyName": "SWEEPER",
            "country": "Norway"
        },
        {
            "name": "Benin Littoral Airways",
            "code": "",
            "auxcode": "LTL",
            "companyName": "LITTORAL",
            "country": "Benin"
        },
        {
            "name": "Bombardier Business Jet Solutions",
            "code": "",
            "auxcode": "LXJ",
            "companyName": "FLEXJET",
            "country": "United States"
        },
        {
            "name": "Bulgaria Air",
            "code": "FB",
            "auxcode": "LZB",
            "companyName": "FLYING BULGARIA",
            "country": "Bulgaria"
        },
        {
            "name": "Brazilian Navy Aviation",
            "code": "",
            "auxcode": "MBR",
            "companyName": "BRAZILIAN NAVY",
            "country": "Brazil"
        },
        {
            "name": "Barents AirLink",
            "code": "8N",
            "auxcode": "NKF",
            "companyName": "NORDFLIGHT",
            "country": "Sweden"
        },
        {
            "name": "Belgian Navy",
            "code": "",
            "auxcode": "NYB",
            "companyName": "BELGIAN NAVY",
            "country": "Belgium"
        },
        {
            "name": "Bakoji Airlines Services",
            "code": "",
            "auxcode": "OGJ",
            "companyName": "BAKO AIR",
            "country": "Nigeria"
        },
        {
            "name": "Benders Air",
            "code": "",
            "auxcode": "PEB",
            "companyName": "PALEMA",
            "country": "Sweden"
        },
        {
            "name": "Balmoral Central Contracts",
            "code": "",
            "auxcode": "PNT",
            "companyName": "PORTNET",
            "country": "South Africa"
        },
        {
            "name": "BGB Air",
            "code": "",
            "auxcode": "POI",
            "companyName": "BOJBAN",
            "country": "Kazakhstan"
        },
        {
            "name": "Butte Aviation",
            "code": "",
            "auxcode": "PPS",
            "companyName": "PIPESTONE",
            "country": "United States"
        },
        {
            "name": "Bond Air Services",
            "code": "",
            "auxcode": "RHD",
            "companyName": "RED HEAD",
            "country": "United Kingdom"
        },
        {
            "name": "Business Airfreight",
            "code": "",
            "auxcode": "RLR",
            "companyName": "RATTLER",
            "country": "United States"
        },
        {
            "name": "BAC Express Airlines",
            "code": "",
            "auxcode": "RPX",
            "companyName": "RAPEX",
            "country": "United Kingdom"
        },
        {
            "name": "Boscombe Down DERA (Formation)",
            "code": "",
            "auxcode": "RRS",
            "companyName": "BLACKBOX",
            "country": "United Kingdom"
        },
        {
            "name": "Business Jet Sweden",
            "code": "",
            "auxcode": "SCJ",
            "companyName": "SCANJET",
            "country": "Sweden"
        },
        {
            "name": "British Airways Shuttle",
            "code": "",
            "auxcode": "SHT",
            "companyName": "SHUTTLE",
            "country": "United Kingdom"
        },
        {
            "name": "British Sky Broadcasting",
            "code": "",
            "auxcode": "SKH",
            "companyName": "SKYNEWS",
            "country": "United Kingdom"
        },
        {
            "name": "Bell Helicopter Textron",
            "code": "",
            "auxcode": "TXB",
            "companyName": "TEXTRON",
            "country": "Canada"
        },
        {
            "name": "Buzzaway Limited",
            "code": "",
            "auxcode": "UKA",
            "companyName": "UKAY",
            "country": "United Kingdom"
        },
        {
            "name": "Biz Jet Charter",
            "code": "",
            "auxcode": "VLX",
            "companyName": "AVOLAR",
            "country": "United States"
        },
        {
            "name": "Blue Chip Jet",
            "code": "",
            "auxcode": "VOL",
            "companyName": "BLUE SPEED",
            "country": "Sweden"
        },
        {
            "name": "BAE Systems",
            "code": "",
            "auxcode": "WFD",
            "companyName": "AVRO",
            "country": "United Kingdom"
        },
        {
            "name": "BAE Systems",
            "code": "",
            "auxcode": "WTN",
            "companyName": "TARNISH",
            "country": "United Kingdom"
        },
        {
            "name": "Baseops International",
            "code": "",
            "auxcode": "XBO",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Bureau Veritas",
            "code": "",
            "auxcode": "XDA",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "British Airways",
            "code": "",
            "auxcode": "XMS",
            "companyName": "SANTA",
            "country": "United Kingdom"
        },
        {
            "name": "Boskovic Air Charters Limited",
            "code": "",
            "auxcode": "ZBA",
            "companyName": "BOSKY",
            "country": "Kenya"
        },
        {
            "name": "C Air Jet Airlines",
            "code": "",
            "auxcode": "SRJ",
            "companyName": "SYRJET",
            "country": "Syrian Arab Republic"
        },
        {
            "name": "C N Air",
            "code": "",
            "auxcode": "ORO",
            "companyName": "CAPRI",
            "country": "Spain"
        },
        {
            "name": "C and M Aviation",
            "code": "",
            "auxcode": "TIP",
            "companyName": "TRANSPAC",
            "country": "United States"
        },
        {
            "name": "C&M Airways",
            "code": "",
            "auxcode": "RWG",
            "companyName": "RED WING",
            "country": "United States"
        },
        {
            "name": "C.S.P.",
            "code": "",
            "auxcode": "RMU",
            "companyName": " Societe",
            "country": "AIRcodeMAUR"
        },
        {
            "name": "CABI",
            "code": "",
            "auxcode": "CBI",
            "companyName": "CABI",
            "country": "Ukraine"
        },
        {
            "name": "CAI",
            "code": "",
            "auxcode": "CPI",
            "companyName": "AIRCAI",
            "country": "Italy"
        },
        {
            "name": "CAL Cargo Air Lines",
            "code": "5C",
            "auxcode": "ICL",
            "companyName": "CAL",
            "country": "Israel"
        },
        {
            "name": "CAM Air Management",
            "code": "",
            "auxcode": "CMR",
            "companyName": "CAMEO",
            "country": "United Kingdom"
        },
        {
            "name": "CATA L",
            "code": "",
            "auxcode": "CTZ",
            "companyName": "CATA",
            "country": "Argentina"
        },
        {
            "name": "CCF Manager Airline",
            "code": "",
            "auxcode": "CCF",
            "companyName": "TOMCAT",
            "country": "Germany"
        },
        {
            "name": "CEDTA (Compania Ecuatoriana De Transportes Aereos)",
            "code": "",
            "auxcode": "CED",
            "companyName": "CEDTA",
            "country": "Ecuador"
        },
        {
            "name": "CHC Denmark",
            "code": "",
            "auxcode": "HBI",
            "companyName": "HELIBIRD",
            "country": "Denmark"
        },
        {
            "name": "CHC Helicopters",
            "code": "",
            "auxcode": "HEM",
            "companyName": "HEMS",
            "country": "Australia"
        },
        {
            "name": "CHC Airways",
            "code": "AW",
            "auxcode": "SCH",
            "companyName": "",
            "country": "Netherlands"
        },
        {
            "name": "CHC Helikopter Service",
            "code": "",
            "auxcode": "HKS",
            "companyName": "HELIBUS",
            "country": "Norway"
        },
        {
            "name": "CIcodeTours",
            "code": "",
            "auxcode": "VCI",
            "companyName": "CIcodeTOURS",
            "country": "Ivory Coast"
        },
        {
            "name": "CKC Services",
            "code": "",
            "auxcode": "CKC",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "CM Stair",
            "code": "",
            "auxcode": "CMZ",
            "companyName": "CEEcodeEM STAIRS",
            "country": "Mauritania"
        },
        {
            "name": "CNET",
            "code": "",
            "auxcode": "CNT",
            "companyName": "KNET",
            "country": "France"
        },
        {
            "name": "COAPA AIR",
            "code": "",
            "auxcode": "OAP",
            "companyName": "COAPA",
            "country": "Mexico"
        },
        {
            "name": "COMAV",
            "code": "",
            "auxcode": "PDR",
            "companyName": "SPEEDSTER",
            "country": "Namibia"
        },
        {
            "name": "CRI Helicopters Mexico",
            "code": "",
            "auxcode": "CRH",
            "companyName": "HELIcodeMEX",
            "country": "Mexico"
        },
        {
            "name": "CSA Air",
            "code": "",
            "auxcode": "IRO",
            "companyName": "IRON AIR",
            "country": "United States"
        },
        {
            "name": "CSE Aviation",
            "code": "",
            "auxcode": "CSE",
            "companyName": "OXFORD",
            "country": "United Kingdom"
        },
        {
            "name": "CTK Network Aviation",
            "code": "",
            "auxcode": "CTQ",
            "companyName": "CITYLINK",
            "country": "Ghana"
        },
        {
            "name": "Cabair College of Air Training",
            "code": "",
            "auxcode": "CBR",
            "companyName": "CABAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Cabo Verde Express",
            "code": "",
            "auxcode": "CVE",
            "companyName": "KABEX",
            "country": "Cape Verde"
        },
        {
            "name": "Caernarfon Airworld",
            "code": "",
            "auxcode": "CWD",
            "companyName": "AMBASSADOR",
            "country": "United Kingdom"
        },
        {
            "name": "Cairo Air Transport Company",
            "code": "",
            "auxcode": "CCE",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Cal Gulf Aviation",
            "code": "",
            "auxcode": "CGC",
            "companyName": "CALcodeGULF",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "CalcodeWest Aviation",
            "code": "",
            "auxcode": "REZ",
            "companyName": "CAL AIR",
            "country": "United States"
        },
        {
            "name": "California Air Shuttle",
            "code": "",
            "auxcode": "CSL",
            "companyName": "CALIFORNIA SHUTTLE",
            "country": "United States"
        },
        {
            "name": "Calima Aviacion",
            "code": "XG",
            "auxcode": "CLI",
            "companyName": "CALIMA",
            "country": "Spain"
        },
        {
            "name": "Calm Air",
            "code": "MO",
            "auxcode": "CAV",
            "companyName": "CALM AIR",
            "country": "Canada"
        },
        {
            "name": "Camai Air",
            "code": "R9",
            "auxcode": "CAM",
            "companyName": "AIR CAMAI",
            "country": "United States"
        },
        {
            "name": "Cambodia Airlines",
            "code": "",
            "auxcode": "KHM",
            "companyName": "CAMBODIA AIRLINES",
            "country": "Cambodia"
        },
        {
            "name": "Cameroon Airlines",
            "code": "UY",
            "auxcode": "UYC",
            "companyName": "CAMcodeAIR",
            "country": "Cameroon"
        },
        {
            "name": "Campania Helicopteros De Transporte",
            "code": "",
            "auxcode": "HSO",
            "companyName": "HELIASTURIAS",
            "country": "Spain"
        },
        {
            "name": "CanJet",
            "code": "C6",
            "auxcode": "CJA",
            "companyName": "CANJET",
            "country": "Canada"
        },
        {
            "name": "Canada Jet Charters",
            "code": "",
            "auxcode": "PIL",
            "companyName": "PINNACLE",
            "country": "Canada"
        },
        {
            "name": "Canadian Airlines",
            "code": "CP",
            "auxcode": "CDN",
            "companyName": "CANADIAN",
            "country": "Canada"
        },
        {
            "name": "Canadian Coast Guard",
            "code": "",
            "auxcode": "CTG",
            "companyName": "CANADIAN COAST GUARD",
            "country": "Canada"
        },
        {
            "name": "Canadian Eagle Aviation",
            "code": "",
            "auxcode": "HIA",
            "companyName": "HAIDA",
            "country": "Canada"
        },
        {
            "name": "Canadian Forces",
            "code": "",
            "auxcode": "CFC",
            "companyName": "CANFORCE",
            "country": "Canada"
        },
        {
            "name": "Canadian Global Air Ambulance",
            "code": "",
            "auxcode": "BZD",
            "companyName": "BLIZZARD",
            "country": "Canada"
        },
        {
            "name": "Canadian Helicopters",
            "code": "",
            "auxcode": "CDN",
            "companyName": "CANADIAN",
            "country": "Canada"
        },
        {
            "name": "Canadian Interagency Forest Fire Centre",
            "code": "",
            "auxcode": "TKR",
            "companyName": "TANKER",
            "country": "Canada"
        },
        {
            "name": "Canadian National Telecommunications",
            "code": "",
            "auxcode": "XNC",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Canadian North",
            "code": "5T",
            "auxcode": "MPE",
            "companyName": "EMPRESS",
            "country": "Canada"
        },
        {
            "name": "Canadian Regional Airlines",
            "code": "",
            "auxcode": "CDR",
            "companyName": "CANADIAN REGIONAL",
            "country": "Canada"
        },
        {
            "name": "Canadian Warplane Heritage Museum",
            "code": "",
            "auxcode": "CWH",
            "companyName": "WARPLANE HERITAGE",
            "country": "Canada"
        },
        {
            "name": "Canadian Western Airlines",
            "code": "W2",
            "auxcode": "CWA",
            "companyName": "CANADIAN WESTERN",
            "country": "Canada"
        },
        {
            "name": "Canair",
            "code": "",
            "auxcode": "CWW",
            "companyName": "CANAIR",
            "country": "China"
        },
        {
            "name": "Cancun Air",
            "code": "",
            "auxcode": "CUI",
            "companyName": "CANcodeAIR",
            "country": "Mexico"
        },
        {
            "name": "Cape Air",
            "code": "9K",
            "auxcode": "KAP",
            "companyName": "CAIR",
            "country": "United States"
        },
        {
            "name": "Cape Air Transport",
            "code": "",
            "auxcode": "CTO",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Cape Central Airways",
            "code": "",
            "auxcode": "SEM",
            "companyName": "SEMO",
            "country": "United States"
        },
        {
            "name": "Cape Smythe Air",
            "code": "",
            "auxcode": "CMY",
            "companyName": "CAPE SMYTHE AIR",
            "country": "United States"
        },
        {
            "name": "Capital Air Service",
            "code": "",
            "auxcode": "CPX",
            "companyName": "CAPAIR",
            "country": "United States"
        },
        {
            "name": "Capital Airlines",
            "code": "",
            "auxcode": "CPD",
            "companyName": "CAPITAL DELTA",
            "country": "Kenya"
        },
        {
            "name": "Capital Airlines Limited",
            "code": "",
            "auxcode": "NCP",
            "companyName": "CAPITAL SHUTTLE",
            "country": "Nigeria"
        },
        {
            "name": "Capital Cargo International Airlines",
            "code": "PT",
            "auxcode": "CCI",
            "companyName": "CAPPY",
            "country": "United States"
        },
        {
            "name": "Capital City Air Carriers",
            "code": "",
            "auxcode": "CCQ",
            "companyName": "CAP CITY",
            "country": "United States"
        },
        {
            "name": "Capital Trading Aviation",
            "code": "",
            "auxcode": "EGL",
            "companyName": "PRESTIGE",
            "country": "United Kingdom"
        },
        {
            "name": "Capitol Air Express",
            "code": "",
            "auxcode": "CEX",
            "companyName": "CAPITOL EXPRESS",
            "country": "United States"
        },
        {
            "name": "Capitol Wings Airline",
            "code": "",
            "auxcode": "CWZ",
            "companyName": "CAPWINGS",
            "country": "United States"
        },
        {
            "name": "Caravan Air",
            "code": "",
            "auxcode": "VAN",
            "companyName": "CAMEL",
            "country": "Mauritania"
        },
        {
            "name": "Cardiff Wales Flying Club",
            "code": "",
            "auxcode": "CWN",
            "companyName": "CAMBRIAN",
            "country": "United Kingdom"
        },
        {
            "name": "Cardinal/Air Virginia",
            "code": "",
            "auxcode": "FVA",
            "companyName": "AIR VIRGINIA",
            "country": "United States"
        },
        {
            "name": "Cardolaar",
            "code": "",
            "auxcode": "GOL",
            "companyName": "CARGOLAAR",
            "country": "Namibia"
        },
        {
            "name": "Cards Air Services",
            "code": "",
            "auxcode": "CDI",
            "companyName": "CARDS",
            "country": "Philippines"
        },
        {
            "name": "Care Flight",
            "code": "",
            "auxcode": "CFH",
            "companyName": "CARE FLIGHT",
            "country": "Australia"
        },
        {
            "name": "Carga Aerea Dominicana",
            "code": "",
            "auxcode": "CDM",
            "companyName": "CARGA AEREA",
            "country": "Dominican Republic"
        },
        {
            "name": "Carga Express Internacional",
            "code": "",
            "auxcode": "EST",
            "companyName": "CARGAINTER",
            "country": "Mexico"
        },
        {
            "name": "Cargo 360",
            "code": "GG",
            "auxcode": "GGC",
            "companyName": "LONGcodeHAUL",
            "country": "United States"
        },
        {
            "name": "Cargo Express",
            "code": "",
            "auxcode": "MCX",
            "companyName": "MAURICARGO",
            "country": "Mauritania"
        },
        {
            "name": "Cargo Ivoire",
            "code": "",
            "auxcode": "CRV",
            "companyName": "CARGOIV",
            "country": "Ivory Coast"
        },
        {
            "name": "Cargo Link (Caribbean)",
            "code": "",
            "auxcode": "CLM",
            "companyName": "CARGO LINK",
            "country": "Barbados"
        },
        {
            "name": "Cargo Three",
            "code": "",
            "auxcode": "CTW",
            "companyName": "THIRD CARGO",
            "country": "Panama"
        },
        {
            "name": "Cargoitalia",
            "code": "2G",
            "auxcode": "CRG",
            "companyName": "WHITE PELICAN",
            "country": "Italy"
        },
        {
            "name": "Cargojet Airways",
            "code": "W8",
            "auxcode": "CJT",
            "companyName": "CARGOJET",
            "country": "Canada"
        },
        {
            "name": "Cargolux",
            "code": "CV",
            "auxcode": "CLX",
            "companyName": "CARGOLUX",
            "country": "Luxembourg"
        },
        {
            "name": "Cargoman",
            "code": "",
            "auxcode": "CGM",
            "companyName": "HOTEL CHARLIE",
            "country": "Oman"
        },
        {
            "name": "Carib Aviation",
            "code": "",
            "auxcode": "DEL",
            "companyName": "RED TAIL",
            "country": "Antigua and Barbuda"
        },
        {
            "name": "Carib Express",
            "code": "",
            "auxcode": "BCB",
            "companyName": "WAVEBIRD",
            "country": "Barbados"
        },
        {
            "name": "Caribair",
            "code": "",
            "auxcode": "CBC",
            "companyName": "CARIBAIR",
            "country": "Dominican Republic"
        },
        {
            "name": "Caribbean Air Cargo",
            "code": "",
            "auxcode": "DCC",
            "companyName": "CARICARGO",
            "country": "Barbados"
        },
        {
            "name": "Caribbean Air Transport",
            "code": "",
            "auxcode": "CLT",
            "companyName": "CARIBBEAN",
            "country": "Netherlands"
        },
        {
            "name": "Caribbean Airlines",
            "code": "BW",
            "auxcode": "BWA",
            "companyName": "CARIBBEAN AIRLINES",
            "country": "Trinidad and Tobago"
        },
        {
            "name": "Caribbean Airways",
            "code": "",
            "auxcode": "IQQ",
            "companyName": "CARIBJET",
            "country": "Barbados"
        },
        {
            "name": "Caribbean Express",
            "code": "",
            "auxcode": "TLC",
            "companyName": "CARIBcodeX",
            "country": "United States"
        },
        {
            "name": "Caribbean Star Airlines",
            "code": "8B",
            "auxcode": "GFI",
            "companyName": "CARIB STAR",
            "country": "Antigua and Barbuda"
        },
        {
            "name": "Caribintair",
            "code": "",
            "auxcode": "CRT",
            "companyName": "CARIBINTAIR",
            "country": "Haiti"
        },
        {
            "name": "Carill Aviation",
            "code": "",
            "auxcode": "CVG",
            "companyName": "CARILL",
            "country": "United Kingdom"
        },
        {
            "name": "Carpatair",
            "code": "V3",
            "auxcode": "KRP",
            "companyName": "CARPATAIR",
            "country": "Romania"
        },
        {
            "name": "Carranza",
            "code": "",
            "auxcode": "CRR",
            "companyName": "CARRANZA",
            "country": "Chile"
        },
        {
            "name": "Carroll Air Service",
            "code": "",
            "auxcode": "ULS",
            "companyName": "ULSTER",
            "country": "United States"
        },
        {
            "name": "Casement Aviation",
            "code": "",
            "auxcode": "CMT",
            "companyName": "CASEMENT",
            "country": "United States"
        },
        {
            "name": "Casino Airline",
            "code": "",
            "auxcode": "CSO",
            "companyName": "CASAIR",
            "country": "United States"
        },
        {
            "name": "Casper Air Service",
            "code": "",
            "auxcode": "CSP",
            "companyName": "CASPER AIR",
            "country": "United States"
        },
        {
            "name": "Caspian Airlines",
            "code": "RV",
            "auxcode": "CPN",
            "companyName": "CASPIAN",
            "country": "Iran"
        },
        {
            "name": "Castle Aviation",
            "code": "",
            "auxcode": "CSJ",
            "companyName": "CASTLE",
            "country": "United States"
        },
        {
            "name": "Cat Aviation",
            "code": "",
            "auxcode": "CAZ",
            "companyName": "EUROCAT",
            "country": "Switzerland"
        },
        {
            "name": "Catalina Flying Boats",
            "code": "",
            "auxcode": "CBT",
            "companyName": "CATALINA AIR",
            "country": "United States"
        },
        {
            "name": "Catex",
            "code": "",
            "auxcode": "TEX",
            "companyName": "CATEX",
            "country": "France"
        },
        {
            "name": "Cathay Pacific",
            "code": "CX",
            "auxcode": "CPA",
            "companyName": "CATHAY",
            "country": "Hong Kong SAR of China"
        },
        {
            "name": "Caverton Helicopters",
            "code": "",
            "auxcode": "CJR",
            "companyName": "CAVERTON AIR",
            "country": "Nigeria"
        },
        {
            "name": "Cayman Airways",
            "code": "KX",
            "auxcode": "CAY",
            "companyName": "CAYMAN",
            "country": "Cayman Islands"
        },
        {
            "name": "Cebu Pacific",
            "code": "5J",
            "auxcode": "CEB",
            "companyName": "CEBU AIR",
            "country": "Philippines"
        },
        {
            "name": "Cecil Aviation",
            "code": "",
            "auxcode": "CIL",
            "companyName": "CECIL",
            "country": "United Kingdom"
        },
        {
            "name": "Cega Aviation",
            "code": "",
            "auxcode": "CEG",
            "companyName": "CEGA",
            "country": "United Kingdom"
        },
        {
            "name": "Celtic Airways",
            "code": "",
            "auxcode": "CEC",
            "companyName": "CELTAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Celtic West",
            "code": "",
            "auxcode": "CWE",
            "companyName": "CELTIC",
            "country": "United Kingdom"
        },
        {
            "name": "Centavia",
            "code": "7N",
            "auxcode": "CNA",
            "companyName": "",
            "country": "Serbia"
        },
        {
            "name": "Cente D'Essais En Vol",
            "code": "",
            "auxcode": "CEV",
            "companyName": "CENTEV",
            "country": "France"
        },
        {
            "name": "Centennial Airlines",
            "code": "",
            "auxcode": "CNL",
            "companyName": "WYOcodeAIR",
            "country": "United States"
        },
        {
            "name": "Centennial Flight Centre",
            "code": "",
            "auxcode": "CNS",
            "companyName": "CENTENNIAL",
            "country": "Canada"
        },
        {
            "name": "Center Vol",
            "code": "",
            "auxcode": "CVO",
            "companyName": "CENTERVOL",
            "country": "Spain"
        },
        {
            "name": "CentercodeSouth",
            "code": "",
            "auxcode": "CTS",
            "companyName": "CENTERcodeSOUTH",
            "country": "Russia"
        },
        {
            "name": "Centrafrican Airlines",
            "code": "",
            "auxcode": "CET",
            "companyName": "CENTRAFRICAIN",
            "country": "Central African Republic"
        },
        {
            "name": "Central Air Express",
            "code": "",
            "auxcode": "CAX",
            "companyName": "CENTRAL EXPRESS",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Central Airlines",
            "code": "",
            "auxcode": "CTL",
            "companyName": "CENTRAL COMMUTER",
            "country": "United States"
        },
        {
            "name": "Central Airways",
            "code": "",
            "auxcode": "CNY",
            "companyName": "CENTRAL LEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Central American Airlines",
            "code": "",
            "auxcode": "ACN",
            "companyName": "AEROCENTRO",
            "country": "Nicaragua"
        },
        {
            "name": "Central Aviation",
            "code": "",
            "auxcode": "YOG",
            "companyName": "YOGAN AIR",
            "country": "United States"
        },
        {
            "name": "Central Connect Airlines",
            "code": "",
            "auxcode": "CCG",
            "companyName": "",
            "country": "Czech Republic"
        },
        {
            "name": "Central De Discos De Reynosa",
            "code": "",
            "auxcode": "DRN",
            "companyName": "DISCOS REYNOSA",
            "country": "Mexico"
        },
        {
            "name": "Central European Airlines",
            "code": "",
            "auxcode": "CMA",
            "companyName": "EUROCENTRAL",
            "country": "Czech Republic"
        },
        {
            "name": "Central Flying Service",
            "code": "",
            "auxcode": "CHA",
            "companyName": "CHARTER CENTRAL",
            "country": "United States"
        },
        {
            "name": "Central Mongolia Airways",
            "code": "",
            "auxcode": "CEM",
            "companyName": "CENTRAL MONGOLIA",
            "country": "Mongolia"
        },
        {
            "name": "Central Mountain Air",
            "code": "",
            "auxcode": "GLR",
            "companyName": "GLACIER",
            "country": "Canada"
        },
        {
            "name": "Central Queensland Aviation College",
            "code": "",
            "auxcode": "CQC",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Central Skyport",
            "code": "",
            "auxcode": "CSI",
            "companyName": "SKYPORT",
            "country": "United States"
        },
        {
            "name": "Centralwings",
            "code": "C0",
            "auxcode": "CLW",
            "companyName": "CENTRALWINGS",
            "country": "Poland"
        },
        {
            "name": "Centre Airlines",
            "code": "",
            "auxcode": "DTV",
            "companyName": "DUTCH VALLEY",
            "country": "United States"
        },
        {
            "name": "Centre of Applied Geodynamica",
            "code": "",
            "auxcode": "CGS",
            "companyName": "GEO CENTRE",
            "country": "Russia"
        },
        {
            "name": "CentrecodeAvia",
            "code": "J7",
            "auxcode": "CVC",
            "companyName": "AVIACENTRE",
            "country": "Russia"
        },
        {
            "name": "Centro De Helicopteros Corporativos",
            "code": "",
            "auxcode": "CCV",
            "companyName": "HELICORPORATIVO",
            "country": "Mexico"
        },
        {
            "name": "Centro de Formacion Aeronautica de Canarias",
            "code": "",
            "auxcode": "ACF",
            "companyName": "FORCAN",
            "country": "Spain"
        },
        {
            "name": "Centurion Air Cargo",
            "code": "WE",
            "auxcode": "CWC",
            "companyName": "CHALLENGE CARGO",
            "country": "United States"
        },
        {
            "name": "Century Aviation",
            "code": "",
            "auxcode": "URY",
            "companyName": "CENTURY AVIA",
            "country": "Mexico"
        },
        {
            "name": "Century Aviation International",
            "code": "",
            "auxcode": "HAI",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Certified Air Dispatch",
            "code": "",
            "auxcode": "XAD",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Cetraca Aviation Service",
            "code": "",
            "auxcode": "CER",
            "companyName": "CETRACA",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Chabahar Airlines",
            "code": "",
            "auxcode": "IRU",
            "companyName": "CHABAHAR",
            "country": "Iran"
        },
        {
            "name": "Chalair Aviation",
            "code": "",
            "auxcode": "CLG",
            "companyName": "CHALLAIR",
            "country": "France"
        },
        {
            "name": "Chalk's Ocean Airways",
            "code": "OP",
            "auxcode": "CHK",
            "companyName": "CHALKS",
            "country": "United States"
        },
        {
            "name": "Challenge Air Transport",
            "code": "",
            "auxcode": "CLS",
            "companyName": "AIRISTO",
            "country": "Germany"
        },
        {
            "name": "Challenge Aviation",
            "code": "",
            "auxcode": "CHS",
            "companyName": "CHALLENGE AVIATION",
            "country": "Australia"
        },
        {
            "name": "Challenge International Airlines",
            "code": "",
            "auxcode": "OFF",
            "companyName": "CHALLENGE AIR",
            "country": "United States"
        },
        {
            "name": "AC Challenge Aero",
            "code": "",
            "auxcode": "CHG",
            "companyName": "SKY CHALLENGER",
            "country": "Ukraine"
        },
        {
            "name": "Champagne Airlines",
            "code": "",
            "auxcode": "CPH",
            "companyName": "CHAMPAGNE",
            "country": "France"
        },
        {
            "name": "Champion Air",
            "code": "MG",
            "auxcode": "CCP",
            "companyName": "CHAMPION AIR",
            "country": "United States"
        },
        {
            "name": "Chanchangi Airlines",
            "code": "",
            "auxcode": "NCH",
            "companyName": "CHANCHANGI",
            "country": "Nigeria"
        },
        {
            "name": "Changan Airlines",
            "code": "2Z",
            "auxcode": "CGN",
            "companyName": "CHANGAN",
            "country": "China"
        },
        {
            "name": "Channel Island Aviation",
            "code": "",
            "auxcode": "CHN",
            "companyName": "CHANNEL",
            "country": "United States"
        },
        {
            "name": "Chantilly Air",
            "code": "",
            "auxcode": "WML",
            "companyName": "MARLIN",
            "country": "United States"
        },
        {
            "name": "Chaparral Airlines",
            "code": "",
            "auxcode": "CPL",
            "companyName": "CHAPARRAL",
            "country": "United States"
        },
        {
            "name": "Chari Aviation Services",
            "code": "S8",
            "auxcode": "CSU",
            "companyName": "CHARI SERVICE",
            "country": "Chad"
        },
        {
            "name": "Charlan Air Charter",
            "code": "",
            "auxcode": "CAH",
            "companyName": "CHARLAN",
            "country": "South Africa"
        },
        {
            "name": "Charlie Hammonds Flying Service",
            "code": "",
            "auxcode": "HMD",
            "companyName": "HAMMOND",
            "country": "United States"
        },
        {
            "name": "Charlotte Air National Guard",
            "code": "",
            "auxcode": "CGD",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Charter Air",
            "code": "",
            "auxcode": "CHW",
            "companyName": "CHARTER WIEN",
            "country": "Austria"
        },
        {
            "name": "Chartright Air",
            "code": "",
            "auxcode": "HRT",
            "companyName": "CHARTRIGHT",
            "country": "Canada"
        },
        {
            "name": "Chautauqua Airlines",
            "code": "RP",
            "auxcode": "CHQ",
            "companyName": "CHAUTAUQUA",
            "country": "United States"
        },
        {
            "name": "Cheboksary Airenterprise JSC",
            "code": "",
            "auxcode": "CBB",
            "companyName": "CHEBAIR",
            "country": "Russia"
        },
        {
            "name": "Chemech Aviation",
            "code": "",
            "auxcode": "CHM",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Cherline",
            "code": "",
            "auxcode": "CHZ",
            "companyName": "CHERL",
            "country": "Russia"
        },
        {
            "name": "ChernomorcodeAvia",
            "code": "",
            "auxcode": "CMK",
            "companyName": "CHERAVIA",
            "country": "Russia"
        },
        {
            "name": "Cherokee Express",
            "code": "",
            "auxcode": "CBM",
            "companyName": "BLUE MAX",
            "country": "United States"
        },
        {
            "name": "Cherry Air",
            "code": "",
            "auxcode": "CCY",
            "companyName": "CHERRY",
            "country": "United States"
        },
        {
            "name": "Chesapeake Air Service",
            "code": "",
            "auxcode": "CAB",
            "companyName": "CHESAPEAKE AIR",
            "country": "United States"
        },
        {
            "name": "Chevron U.S.A",
            "code": "",
            "auxcode": "CVR",
            "companyName": "CHEVRON",
            "country": "United States"
        },
        {
            "name": "Cheyenne Airways",
            "code": "",
            "auxcode": "CYA",
            "companyName": "CHEYENNE AIR",
            "country": "United States"
        },
        {
            "name": "Chicago Air",
            "code": "",
            "auxcode": "CGO",
            "companyName": "WILD ONION",
            "country": "United States"
        },
        {
            "name": "Chicago Express",
            "code": "C8",
            "auxcode": "WDY",
            "companyName": "WINDY CITY",
            "country": "United States"
        },
        {
            "name": "Chief Rat Flight Services",
            "code": "",
            "auxcode": "RAT",
            "companyName": "RIVERRAT",
            "country": "South Africa"
        },
        {
            "name": "Chilchota Taxi Aereo",
            "code": "",
            "auxcode": "CCH",
            "companyName": "CHILCHOTA",
            "country": "Mexico"
        },
        {
            "name": "Chilcotin Caribou Aviation",
            "code": "",
            "auxcode": "DES",
            "companyName": "CHILCOTIN",
            "country": "Canada"
        },
        {
            "name": "Chilliwack Aviation",
            "code": "",
            "auxcode": "CAD",
            "companyName": "CHILLIWACKAIR",
            "country": "Canada"
        },
        {
            "name": "ChimcodeNir Aviation",
            "code": "",
            "auxcode": "ETN",
            "companyName": "CHMINIR",
            "country": "Israel"
        },
        {
            "name": "China Airlines",
            "code": "CI",
            "auxcode": "CAL",
            "companyName": "DYNASTY",
            "country": "Taiwan"
        },
        {
            "name": "China Cargo Airlines",
            "code": "CK",
            "auxcode": "CKK",
            "companyName": "CARGO KING",
            "country": "China"
        },
        {
            "name": "China Eastern Airlines",
            "code": "MU",
            "auxcode": "CES",
            "companyName": "CHINA EASTERN",
            "country": "China"
        },
        {
            "name": "China Express Airlines",
            "code": "",
            "auxcode": "HXA",
            "companyName": "CHINA EXPRESS",
            "country": "China"
        },
        {
            "name": "China Flying Dragon Aviation",
            "code": "",
            "auxcode": "CFA",
            "companyName": "FEILONG",
            "country": "China"
        },
        {
            "name": "China General Aviation Corporation",
            "code": "",
            "auxcode": "CTH",
            "companyName": "TONGHANG",
            "country": "China"
        },
        {
            "name": "China National Aviation Corporation",
            "code": "",
            "auxcode": "CAG",
            "companyName": "CHINA NATIONAL",
            "country": "China"
        },
        {
            "name": "China Northern Airlines",
            "code": "CJ",
            "auxcode": "CBF",
            "companyName": "CHINA NORTHERN",
            "country": "China"
        },
        {
            "name": "China Northwest Airlines",
            "code": "WH",
            "auxcode": "CNW",
            "companyName": "CHINA NORTHWEST",
            "country": "China"
        },
        {
            "name": "China Ocean Helicopter Corporation",
            "code": "",
            "auxcode": "CHC",
            "companyName": "CHINA HELICOPTER",
            "country": "China"
        },
        {
            "name": "China Postal Airlines",
            "code": "8Y",
            "auxcode": "CYZ",
            "companyName": "CHINA POST",
            "country": "China"
        },
        {
            "name": "China Southern Airlines",
            "code": "CZ",
            "auxcode": "CSN",
            "companyName": "CHINA SOUTHERN",
            "country": "China"
        },
        {
            "name": "China Southwest Airlines",
            "code": "",
            "auxcode": "CXN",
            "companyName": "CHINA SOUTHWEST",
            "country": "China"
        },
        {
            "name": "China United Airlines",
            "code": "HR",
            "auxcode": "CUA",
            "companyName": "LIANHANG",
            "country": "China"
        },
        {
            "name": "China Xinhua Airlines",
            "code": "XO",
            "auxcode": "CXH",
            "companyName": "XINHUA",
            "country": "China"
        },
        {
            "name": "Yunnan Airlines",
            "code": "3Q",
            "auxcode": "CYH",
            "companyName": "YUNNAN",
            "country": "China"
        },
        {
            "name": "Chinguetti Airlines",
            "code": "",
            "auxcode": "CGU",
            "companyName": "CHINGUETTI",
            "country": "Mauritania"
        },
        {
            "name": "Chipola Aviation",
            "code": "",
            "auxcode": "CEP",
            "companyName": "CHIPOLA",
            "country": "United States"
        },
        {
            "name": "Chippewa Air Commuter",
            "code": "",
            "auxcode": "CPW",
            "companyName": "CHIPPEWAcodeAIR",
            "country": "United States"
        },
        {
            "name": "Chitaavia",
            "code": "X7",
            "auxcode": "CHF",
            "companyName": "CHITA",
            "country": "Russia"
        },
        {
            "name": "Christman Air System",
            "code": "",
            "auxcode": "CAS",
            "companyName": "CHRISTMAN",
            "country": "United States"
        },
        {
            "name": "Christophorus Flugrettungsverein",
            "code": "",
            "auxcode": "OEC",
            "companyName": "CHRISTOPHORUS",
            "country": "Austria"
        },
        {
            "name": "Chrome Air Services",
            "code": "",
            "auxcode": "CHO",
            "companyName": "CHROME AIR",
            "country": "United States"
        },
        {
            "name": "Church Aircraft",
            "code": "",
            "auxcode": "CHU",
            "companyName": "CHURCHAIR",
            "country": "United States"
        },
        {
            "name": "Cielos Airlines",
            "code": "A2",
            "auxcode": "CIU",
            "companyName": "CIELOS",
            "country": "Peru"
        },
        {
            "name": "Cimber Air",
            "code": "QI",
            "auxcode": "CIM",
            "companyName": "CIMBER",
            "country": "Denmark"
        },
        {
            "name": "Cirrus",
            "code": "",
            "auxcode": "RRU",
            "companyName": "HELICIRRUS",
            "country": "Chile"
        },
        {
            "name": "Cirrus Air",
            "code": "",
            "auxcode": "NTS",
            "companyName": "NITE STAR",
            "country": "United States"
        },
        {
            "name": "Cirrus Airlines",
            "code": "C9",
            "auxcode": "RUS",
            "companyName": "CIRRUS AIR",
            "country": "Germany"
        },
        {
            "name": "Cirrus Middle East",
            "code": "",
            "auxcode": "JTI",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "Citationshares",
            "code": "",
            "auxcode": "FIV",
            "companyName": "FIVE STAR",
            "country": "United States"
        },
        {
            "name": "Citibank",
            "code": "",
            "auxcode": "XCX",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Citic General Aviation",
            "code": "",
            "auxcode": "HZX",
            "companyName": "ZHONGXIN",
            "country": "China"
        },
        {
            "name": "City Airline",
            "code": "CF",
            "auxcode": "SDR",
            "companyName": "SWEDESTAR",
            "country": "Sweden"
        },
        {
            "name": "City Connexion Airlines",
            "code": "G3",
            "auxcode": "CIX",
            "companyName": "CONNEXION",
            "country": "Burundi"
        },
        {
            "name": "City of Bangor",
            "code": "",
            "auxcode": "XBG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "CityJet",
            "code": "WX",
            "auxcode": "BCY",
            "companyName": "CITYcodeIRELAND",
            "country": "Ireland"
        },
        {
            "name": "Cityair (Chester) Limited",
            "code": "",
            "auxcode": "CAQ",
            "companyName": "AIR CHESTER",
            "country": "United Kingdom"
        },
        {
            "name": "Cityfly",
            "code": "",
            "auxcode": "CII",
            "companyName": "CITYFLY",
            "country": "Italy"
        },
        {
            "name": "BA CityFlyer",
            "code": "CJ",
            "auxcode": "CFE",
            "companyName": "FLYER",
            "country": "United Kingdom"
        },
        {
            "name": "Cityline Hungary",
            "code": "",
            "auxcode": "CNB",
            "companyName": "CITYHUN",
            "country": "Hungary"
        },
        {
            "name": "Citylink Airlines",
            "code": "",
            "auxcode": "HSR",
            "companyName": "HOOSIER",
            "country": "United States"
        },
        {
            "name": "Civair Airways",
            "code": "",
            "auxcode": "CIW",
            "companyName": "CIVFLIGHT",
            "country": "South Africa"
        },
        {
            "name": "Civil Air Patrol South Carolina Wing",
            "code": "",
            "auxcode": "BKR",
            "companyName": "BOX KAR",
            "country": "United States"
        },
        {
            "name": "Civil Air Transport",
            "code": "CT",
            "auxcode": "CAT",
            "companyName": "Mandarin",
            "country": "Taiwan"
        },
        {
            "name": "Civil Aviation Authority",
            "code": "",
            "auxcode": "CIA",
            "companyName": "CALIMERA",
            "country": "Slovakia"
        },
        {
            "name": "Civil Aviation Authority of New Zealand",
            "code": "",
            "auxcode": "CIV",
            "companyName": "CIVAIR",
            "country": "New Zealand"
        },
        {
            "name": "Civil Aviation Inspectorate of the Czech Republic",
            "code": "",
            "auxcode": "CBA",
            "companyName": "CALIBRA",
            "country": "Czech Republic"
        },
        {
            "name": "Claessens International Limited",
            "code": "",
            "auxcode": "FMC",
            "companyName": "CLAESSENS",
            "country": "United Kingdom"
        },
        {
            "name": "Clark Aviation",
            "code": "",
            "auxcode": "CLK",
            "companyName": "CLARKAIR",
            "country": "United States"
        },
        {
            "name": "Clasair",
            "code": "",
            "auxcode": "CSF",
            "companyName": "CALEDONIAN",
            "country": "United Kingdom"
        },
        {
            "name": "Clay Lacy Aviation",
            "code": "",
            "auxcode": "CLY",
            "companyName": "CLAYcodeLACY",
            "country": "United States"
        },
        {
            "name": "Click Airways",
            "code": "",
            "auxcode": "CGK",
            "companyName": "CLICK AIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Cloud 9 Air Charters",
            "code": "",
            "auxcode": "CLZ",
            "companyName": "CLOUDLINE",
            "country": "South Africa"
        },
        {
            "name": "Clowes Estates Limited",
            "code": "",
            "auxcode": "CLD",
            "companyName": "CLOWES",
            "country": "United Kingdom"
        },
        {
            "name": "Club 328",
            "code": "",
            "auxcode": "SDJ",
            "companyName": "SPACEJET",
            "country": "United Kingdom"
        },
        {
            "name": "Club Air",
            "code": "6P",
            "auxcode": "ISG",
            "companyName": "CLUBAIR",
            "country": "Italy"
        },
        {
            "name": "Coast Air",
            "code": "BX",
            "auxcode": "CST",
            "companyName": "COAST CENTER",
            "country": "Norway"
        },
        {
            "name": "Coastal Air",
            "code": "DQ",
            "auxcode": "",
            "companyName": "U.S. Virgin Islands",
            "country": "United States"
        },
        {
            "name": "Coastal Air Transport",
            "code": "",
            "auxcode": "TCL",
            "companyName": "TRANS COASTAL",
            "country": "United States"
        },
        {
            "name": "Coastal Airways",
            "code": "",
            "auxcode": "CNG",
            "companyName": "SIDcodeAIR",
            "country": "United States"
        },
        {
            "name": "Coastal Travels",
            "code": "",
            "auxcode": "CSV",
            "companyName": "COASTAL TRAVEL",
            "country": "Tanzania"
        },
        {
            "name": "Cohlmia Aviation",
            "code": "",
            "auxcode": "CHL",
            "companyName": "COHLMIA",
            "country": "United States"
        },
        {
            "name": "Colaereos",
            "code": "",
            "auxcode": "OLR",
            "companyName": "COLAEREOS",
            "country": "Ecuador"
        },
        {
            "name": "Colemill Enterprises",
            "code": "",
            "auxcode": "CLE",
            "companyName": "COLEMILL",
            "country": "United States"
        },
        {
            "name": "Colgan Air",
            "code": "9L",
            "auxcode": "CJC",
            "companyName": "COLGAN",
            "country": "United States"
        },
        {
            "name": "Colibri Aviation",
            "code": "",
            "auxcode": "CAE",
            "companyName": "HUMMINGBIRD",
            "country": "Canada"
        },
        {
            "name": "Colt International",
            "code": "",
            "auxcode": "CCX",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Columbia Airlines",
            "code": "",
            "auxcode": "COL",
            "companyName": "COLAIR",
            "country": "Canada"
        },
        {
            "name": "Columbia Helicopters",
            "code": "",
            "auxcode": "WCO",
            "companyName": "COLUMBIA HELI",
            "country": "United States"
        },
        {
            "name": "Columbus Air Transport",
            "code": "",
            "auxcode": "KLR",
            "companyName": "KAYcodeLER",
            "country": "United States"
        },
        {
            "name": "Colvin Aviation",
            "code": "",
            "auxcode": "GHP",
            "companyName": "GRASSHOPPER EX",
            "country": "United States"
        },
        {
            "name": "Comair",
            "code": "OH",
            "auxcode": "COM",
            "companyName": "COMAIR",
            "country": "United States"
        },
        {
            "name": "Comair",
            "code": "MN",
            "auxcode": "CAW",
            "companyName": "COMMERCIAL",
            "country": "South Africa"
        },
        {
            "name": "Comed Group",
            "code": "",
            "auxcode": "CDE",
            "companyName": "COMEX",
            "country": "United Kingdom"
        },
        {
            "name": "Comeravia",
            "code": "",
            "auxcode": "CVV",
            "companyName": "COMERAVIA",
            "country": "Venezuela"
        },
        {
            "name": "Comercial Aerea",
            "code": "",
            "auxcode": "CRS",
            "companyName": "COMERCIAL AEREA",
            "country": "Mexico"
        },
        {
            "name": "Comet Airlines",
            "code": "",
            "auxcode": "CMG",
            "companyName": "SUNSPY",
            "country": "Nigeria"
        },
        {
            "name": "Comfort Air",
            "code": "",
            "auxcode": "FYN",
            "companyName": "FLYNN",
            "country": "Germany"
        },
        {
            "name": "Comlux Aviation",
            "code": "",
            "auxcode": "CLA",
            "companyName": "COMLUX",
            "country": "Switzerland"
        },
        {
            "name": "Commair Aviation",
            "code": "",
            "auxcode": "CMH",
            "companyName": "COMMODORE",
            "country": "United Kingdom"
        },
        {
            "name": "Commandement Du Transport Aerien Militaire Francais",
            "code": "",
            "auxcode": "CTM",
            "companyName": "COTAM",
            "country": "France"
        },
        {
            "name": "Commander Air Charter",
            "code": "",
            "auxcode": "CML",
            "companyName": "COMMANDAIR",
            "country": "Canada"
        },
        {
            "name": "Commander Mexicana",
            "code": "",
            "auxcode": "CRM",
            "companyName": "COMMANDERMEX",
            "country": "Mexico"
        },
        {
            "name": "Commercial Aviation",
            "code": "",
            "auxcode": "CMS",
            "companyName": "ACCESS",
            "country": "Canada"
        },
        {
            "name": "Commodore Aviation",
            "code": "",
            "auxcode": "GAR",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Commonwealth Jet Service",
            "code": "",
            "auxcode": "CJS",
            "companyName": "COMMONWEALTH",
            "country": "United States"
        },
        {
            "name": "CommutAir",
            "code": "C5",
            "auxcode": "UCA",
            "companyName": "COMMUTAIR",
            "country": "United States"
        },
        {
            "name": "Comores Airlines",
            "code": "KR",
            "auxcode": "CWK",
            "companyName": "CONTICOM",
            "country": "Comoros"
        },
        {
            "name": "Compagnia Generale Ripreseaeree",
            "code": "",
            "auxcode": "CGR",
            "companyName": "COMPRIP",
            "country": "Italy"
        },
        {
            "name": "Compagnie Aerienne du Mali",
            "code": "",
            "auxcode": "CMM",
            "companyName": "CAMALI",
            "country": "Mali"
        },
        {
            "name": "Compagnie Mauritanienne Des Transports",
            "code": "",
            "auxcode": "CPM",
            "companyName": "",
            "country": "Mauritania"
        },
        {
            "name": "Compagnie de Bauxites de Guinee",
            "code": "",
            "auxcode": "GIC",
            "companyName": "CEBEGE",
            "country": "Guinea"
        },
        {
            "name": "Compania Aerea de Valencia",
            "code": "",
            "auxcode": "AIF",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Compania Aerotecnicas Fotograficas",
            "code": "",
            "auxcode": "ATF",
            "companyName": "AEROTECNICAS",
            "country": "Spain"
        },
        {
            "name": "Compania Boliviana de Transporte Aereo Privado Aerosur",
            "code": "",
            "auxcode": "ASU",
            "companyName": " S.A.",
            "country": "ASUR"
        },
        {
            "name": "Compania De Actividades Y Servicios De Aviacion",
            "code": "",
            "auxcode": "LCT",
            "companyName": "STELLAIR",
            "country": "Spain"
        },
        {
            "name": "Compania Ejecutiva",
            "code": "",
            "auxcode": "EJV",
            "companyName": "EJECUTIVA",
            "country": "Mexico"
        },
        {
            "name": "Compania Helicopteros Del Sureste",
            "code": "",
            "auxcode": "HSE",
            "companyName": "HELISURESTE",
            "country": "Spain"
        },
        {
            "name": "Compania Mexicana De Aeroplanos",
            "code": "",
            "auxcode": "MDR",
            "companyName": "AEROPLANOS",
            "country": "Mexico"
        },
        {
            "name": "Compania Mexicargo",
            "code": "GJ",
            "auxcode": "MXC",
            "companyName": "MEXICARGO",
            "country": "Mexico"
        },
        {
            "name": "Compania Transportes Aereos Del Sur",
            "code": "",
            "auxcode": "HSS",
            "companyName": "TAS HELICOPTEROS",
            "country": "Spain"
        },
        {
            "name": "Compania de Servicios Aereos Tavisa",
            "code": "",
            "auxcode": "TAV",
            "companyName": "TAVISA",
            "country": "Spain"
        },
        {
            "name": "Company Flight",
            "code": "",
            "auxcode": "CYF",
            "companyName": "COMPANY FLIGHT",
            "country": "Denmark"
        },
        {
            "name": "Compass Airlines",
            "code": "CP",
            "auxcode": "CPZ",
            "companyName": "Compass Rose",
            "country": "United States"
        },
        {
            "name": "Compass International Airways",
            "code": "",
            "auxcode": "CPS",
            "companyName": "COMPASS",
            "country": "United Kingdom"
        },
        {
            "name": "Compuflight Operations Service",
            "code": "",
            "auxcode": "XCO",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Compuserve Incorporated",
            "code": "",
            "auxcode": "XCS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Conair Aviation",
            "code": "",
            "auxcode": "CRC",
            "companyName": "CONAIRcodeCANADA",
            "country": "Canada"
        },
        {
            "name": "Concordavia",
            "code": "",
            "auxcode": "COD",
            "companyName": "CONCORDAVIA",
            "country": "Ukraine"
        },
        {
            "name": "Condor Aero Services",
            "code": "",
            "auxcode": "CNR",
            "companyName": "CONAERO",
            "country": "United States"
        },
        {
            "name": "Condor",
            "code": "",
            "auxcode": "CIB",
            "companyName": "CONDOR BERLIN",
            "country": "Germany"
        },
        {
            "name": "Condor Flugdienst",
            "code": "DE",
            "auxcode": "CFG",
            "companyName": "CONDOR",
            "country": "Germany"
        },
        {
            "name": "Confort Air",
            "code": "",
            "auxcode": "COF",
            "companyName": "CONFORT",
            "country": "Canada"
        },
        {
            "name": "Congo Air",
            "code": "",
            "auxcode": "CAK",
            "companyName": "",
            "country": "Bahamas"
        },
        {
            "name": "Congressional Air",
            "code": "",
            "auxcode": "CGA",
            "companyName": "CONGRESSIONAL",
            "country": "United States"
        },
        {
            "name": "Conifair Aviation",
            "code": "",
            "auxcode": "ROY",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Connectair Charters",
            "code": "",
            "auxcode": "BSN",
            "companyName": "BASTION",
            "country": "Canada"
        },
        {
            "name": "Conquest Airlines",
            "code": "",
            "auxcode": "CAC",
            "companyName": "CONQUEST AIR",
            "country": "United States"
        },
        {
            "name": "Conroe Aviation Services",
            "code": "",
            "auxcode": "CXO",
            "companyName": "CONROE AIR",
            "country": "United States"
        },
        {
            "name": "Consorcio Aviaxsa",
            "code": "6A",
            "auxcode": "CHP",
            "companyName": "AVIACSA",
            "country": "Mexico"
        },
        {
            "name": "Consorcio Helitec",
            "code": "",
            "auxcode": "VCH",
            "companyName": "CONSORCIO HELITEC",
            "country": "Venezuela"
        },
        {
            "name": "Constanta Airline",
            "code": "",
            "auxcode": "UZA",
            "companyName": "CONSTANTA",
            "country": "Ukraine"
        },
        {
            "name": "Contact Air",
            "code": "C3",
            "auxcode": "KIS",
            "companyName": "CONTACTAIR",
            "country": "Germany"
        },
        {
            "name": "Contel ASC",
            "code": "",
            "auxcode": "XCL",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Continental Airlines",
            "code": "CO",
            "auxcode": "COA",
            "companyName": "CONTINENTAL",
            "country": "United States"
        },
        {
            "name": "Continental Airways",
            "code": "PC",
            "auxcode": "PVV",
            "companyName": "CONTAIR",
            "country": "Russia"
        },
        {
            "name": "Continental Express",
            "code": "CO",
            "auxcode": "",
            "companyName": "JETLINK",
            "country": "United States"
        },
        {
            "name": "Continental Micronesia",
            "code": "CS",
            "auxcode": "CMI",
            "companyName": "AIR MIKE",
            "country": "United States"
        },
        {
            "name": "Continental Oil",
            "code": "",
            "auxcode": "CON",
            "companyName": "CONOCO",
            "country": "United States"
        },
        {
            "name": "Conviasa",
            "code": "V0",
            "auxcode": "VCV",
            "companyName": "CONVIASA",
            "country": "Venezuela"
        },
        {
            "name": "Cook Inlet Aviation",
            "code": "",
            "auxcode": "CKA",
            "companyName": "COOKcodeAIR",
            "country": "United States"
        },
        {
            "name": "Cooper Aerial Surveys",
            "code": "",
            "auxcode": "SVY",
            "companyName": "SURVEYOR",
            "country": "United Kingdom"
        },
        {
            "name": "Copa Airlines",
            "code": "CM",
            "auxcode": "CMP",
            "companyName": "COPA",
            "country": "Panama"
        },
        {
            "name": "Copenhagen Airtaxi",
            "code": "",
            "auxcode": "CAT",
            "companyName": "AIRCAT",
            "country": "Denmark"
        },
        {
            "name": "Copper State Air Service",
            "code": "",
            "auxcode": "COP",
            "companyName": "COPPER STATE",
            "country": "United States"
        },
        {
            "name": "Copterline",
            "code": "",
            "auxcode": "AAQ",
            "companyName": "COPTERLINE",
            "country": "Finland"
        },
        {
            "name": "Coptrade Air Transport",
            "code": "",
            "auxcode": "CCW",
            "companyName": "COPTRADE AIR",
            "country": "Sudan"
        },
        {
            "name": "Corendon Airlines",
            "code": "",
            "auxcode": "CAI",
            "companyName": "CORENDON",
            "country": "Turkey"
        },
        {
            "name": "Coronado Aerolineas",
            "code": "",
            "auxcode": "CRA",
            "companyName": "CORAL",
            "country": "Colombia"
        },
        {
            "name": "Corpac Canada",
            "code": "",
            "auxcode": "CPB",
            "companyName": "PENTA",
            "country": "Canada"
        },
        {
            "name": "Corporacion Aereo Cencor",
            "code": "",
            "auxcode": "CNC",
            "companyName": "CENCOR",
            "country": "Mexico"
        },
        {
            "name": "Corporacion Aeroangeles",
            "code": "",
            "auxcode": "CPG",
            "companyName": "CORPORANG",
            "country": "Mexico"
        },
        {
            "name": "Corporacion Paraguaya De Aeronautica",
            "code": "",
            "auxcode": "CGY",
            "companyName": "",
            "country": "Paraguay"
        },
        {
            "name": "Corporate Air",
            "code": "",
            "auxcode": "CPT",
            "companyName": "AIR SPUR",
            "country": "United States"
        },
        {
            "name": "Corporate Air",
            "code": "",
            "auxcode": "CPR",
            "companyName": "CORPAIR",
            "country": "United States"
        },
        {
            "name": "Corporate Aircraft Company",
            "code": "",
            "auxcode": "CPO",
            "companyName": "MOKAN",
            "country": "United States"
        },
        {
            "name": "Corporate Airlink",
            "code": "",
            "auxcode": "COO",
            "companyName": "CORPORATE",
            "country": "Canada"
        },
        {
            "name": "Corporate Aviation Services",
            "code": "",
            "auxcode": "CKE",
            "companyName": "CHECKMATE",
            "country": "United States"
        },
        {
            "name": "Corporate Flight International",
            "code": "",
            "auxcode": "VHT",
            "companyName": "VEGAS HEAT",
            "country": "United States"
        },
        {
            "name": "Corporate Flight Management",
            "code": "",
            "auxcode": "VTE",
            "companyName": "VOLUNTEER",
            "country": "United States"
        },
        {
            "name": "Corporate Jets",
            "code": "",
            "auxcode": "CJI",
            "companyName": "SEA JET",
            "country": "United States"
        },
        {
            "name": "Corsairfly",
            "code": "SS",
            "auxcode": "CRL",
            "companyName": "CORSAIR",
            "country": "France"
        },
        {
            "name": "CorsecodeMediterranee",
            "code": "XK",
            "auxcode": "CCM",
            "companyName": "CORSICA",
            "country": "France"
        },
        {
            "name": "Cosmic Air",
            "code": "F5",
            "auxcode": "COZ",
            "companyName": "COSMIC AIR",
            "country": "Nepal"
        },
        {
            "name": "Cougar Helicopters",
            "code": "",
            "auxcode": "CHI",
            "companyName": "COUGAR",
            "country": "Canada"
        },
        {
            "name": "Coulson Flying Service",
            "code": "",
            "auxcode": "MGB",
            "companyName": "MOCKINGBIRD",
            "country": "United Kingdom"
        },
        {
            "name": "Country Connection Airlines",
            "code": "",
            "auxcode": "NSW",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Country International Airlines",
            "code": "",
            "auxcode": "CIK",
            "companyName": "COUNTRY AIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Courier Services",
            "code": "",
            "auxcode": "CSD",
            "companyName": "DELIVERY",
            "country": "United States"
        },
        {
            "name": "Court Helicopters",
            "code": "",
            "auxcode": "CUT",
            "companyName": "COURT AIR",
            "country": "South Africa"
        },
        {
            "name": "Coval Air",
            "code": "",
            "auxcode": "CVL",
            "companyName": "COVAL",
            "country": "Canada"
        },
        {
            "name": "Cowi",
            "code": "",
            "auxcode": "COW",
            "companyName": "COWI",
            "country": "Netherlands"
        },
        {
            "name": "Coyne Aviation",
            "code": "",
            "auxcode": "COY",
            "companyName": "COYNE AIR",
            "country": "United Kingdom"
        },
        {
            "name": "Cranfield University",
            "code": "",
            "auxcode": "CFD",
            "companyName": "AERONAUT",
            "country": "United Kingdom"
        },
        {
            "name": "Cree Airways",
            "code": "",
            "auxcode": "CRE",
            "companyName": "CREE AIR",
            "country": "Canada"
        },
        {
            "name": "Crelam",
            "code": "",
            "auxcode": "ELM",
            "companyName": "CRELAM",
            "country": "Mexico"
        },
        {
            "name": "Crest Aviation",
            "code": "",
            "auxcode": "CAN",
            "companyName": "CREST",
            "country": "United Kingdom"
        },
        {
            "name": "Crimea Universal Avia",
            "code": "",
            "auxcode": "KRM",
            "companyName": "TRANS UNIVERSAL",
            "country": "Ukraine"
        },
        {
            "name": "Croatia Airlines",
            "code": "OU",
            "auxcode": "CTN",
            "companyName": "CROATIA",
            "country": "Croatia"
        },
        {
            "name": "Croatian Air Force",
            "code": "",
            "auxcode": "HRZ",
            "companyName": "CROATIAN AIRFORCE",
            "country": "Croatia"
        },
        {
            "name": "Cross Aviation",
            "code": "",
            "auxcode": "CRX",
            "companyName": "CROSSAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Crossair Europe",
            "code": "QE",
            "auxcode": "ECC",
            "companyName": "Cigogne",
            "country": "Switzerland"
        },
        {
            "name": "Crow Executive Air",
            "code": "",
            "auxcode": "CWX",
            "companyName": "CROW EXPRESS",
            "country": "United States"
        },
        {
            "name": "Crown Air Systems",
            "code": "",
            "auxcode": "CKR",
            "companyName": "CROWN AIR",
            "country": "United States"
        },
        {
            "name": "Crown Airways",
            "code": "",
            "auxcode": "CRO",
            "companyName": "CROWN AIRWAYS",
            "country": "United States"
        },
        {
            "name": "Crownair",
            "code": "",
            "auxcode": "CRW",
            "companyName": "REGAL",
            "country": "Canada"
        },
        {
            "name": "Cruiser Linhas Aereas",
            "code": "",
            "auxcode": "VCR",
            "companyName": "VOE CRUISER",
            "country": "Brazil"
        },
        {
            "name": "Cryderman Air Service",
            "code": "",
            "auxcode": "CTY",
            "companyName": "CENTURY",
            "country": "United States"
        },
        {
            "name": "Crystal Shamrock",
            "code": "",
            "auxcode": "CYT",
            "companyName": "CRYSTALcodeAIR",
            "country": "United States"
        },
        {
            "name": "Cubana de Aviación",
            "code": "CU",
            "auxcode": "CUB",
            "companyName": "CUBANA",
            "country": "Cuba"
        },
        {
            "name": "Cumberland Airways (Nicholson Air Service)",
            "code": "",
            "auxcode": "CBL",
            "companyName": "CUMBERLAND",
            "country": "United States"
        },
        {
            "name": "Custom Air Transport",
            "code": "",
            "auxcode": "CTT",
            "companyName": "CATT",
            "country": "United States"
        },
        {
            "name": "Cygnus Air",
            "code": "",
            "auxcode": "RGN",
            "companyName": "CYGNUS AIR",
            "country": "Spain"
        },
        {
            "name": "Cyprair Tours",
            "code": "",
            "auxcode": "CYC",
            "companyName": "CYPRAIR",
            "country": "Cyprus"
        },
        {
            "name": "Cypress Airlines",
            "code": "",
            "auxcode": "CYS",
            "companyName": "SKYBIRD",
            "country": "Canada"
        },
        {
            "name": "Cyprus Airways",
            "code": "CY",
            "auxcode": "CYP",
            "companyName": "CYPRUS",
            "country": "Cyprus"
        },
        {
            "name": "Cyprus Turkish Airlines",
            "code": "YK",
            "auxcode": "",
            "companyName": "",
            "country": "Turkey"
        },
        {
            "name": "Czech Air Force",
            "code": "",
            "auxcode": "CEF",
            "companyName": "CZECH AIR FORCE",
            "country": "Czech Republic"
        },
        {
            "name": "Czech Air Handling",
            "code": "",
            "auxcode": "AHD",
            "companyName": "AIRHANDLING",
            "country": "Czech Republic"
        },
        {
            "name": "Czech Airlines",
            "code": "OK",
            "auxcode": "CSA",
            "companyName": "CSAcodeLINES",
            "country": "Czech Republic"
        },
        {
            "name": "Czech Government Flying Service",
            "code": "",
            "auxcode": "CIE",
            "companyName": "CZECHIA",
            "country": "Czech Republic"
        },
        {
            "name": "D & D Aviation",
            "code": "",
            "auxcode": "DDA",
            "companyName": "DUSTY",
            "country": "United States"
        },
        {
            "name": "D&K Aviation",
            "code": "",
            "auxcode": "DNK",
            "companyName": "DIRECT JET",
            "country": "United States"
        },
        {
            "name": "DAP Helicopteros",
            "code": "",
            "auxcode": "DHE",
            "companyName": "HELIDAP",
            "country": "Chile"
        },
        {
            "name": "DFS UK Limited",
            "code": "",
            "auxcode": "VLF",
            "companyName": "VOLANTE",
            "country": "United Kingdom"
        },
        {
            "name": "DAS Air Cargo",
            "code": "WD",
            "auxcode": "DSR",
            "companyName": "DAIRAIR",
            "country": "Uganda"
        },
        {
            "name": "DAS Airlines",
            "code": "",
            "auxcode": "RKC",
            "companyName": "DAS CONGO",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "DAT Danish Air Transport",
            "code": "DX",
            "auxcode": "DTR",
            "companyName": "DANISH",
            "country": "Denmark"
        },
        {
            "name": "DAT Enterprise Limited",
            "code": "",
            "auxcode": "ENT",
            "companyName": "DATENT",
            "country": "United Kingdom"
        },
        {
            "name": "DERA Boscombe Down",
            "code": "",
            "auxcode": "BDN",
            "companyName": "GAUNTLET",
            "country": "United Kingdom"
        },
        {
            "name": "DESNA",
            "code": "",
            "auxcode": "DSN",
            "companyName": "DESNA",
            "country": "Ukraine"
        },
        {
            "name": "DETA Air",
            "code": "",
            "auxcode": "DET",
            "companyName": "SAMAL",
            "country": "Kazakhstan"
        },
        {
            "name": "DGO Jet",
            "code": "",
            "auxcode": "DGO",
            "companyName": "DGO JET",
            "country": "Mexico"
        },
        {
            "name": "DHL Aero Expreso",
            "code": "",
            "auxcode": "DAE",
            "companyName": "YELLOW",
            "country": "Panama"
        },
        {
            "name": "DHL Air",
            "code": "",
            "auxcode": "DHK",
            "companyName": "WORLD EXPRESS",
            "country": "United Kingdom"
        },
        {
            "name": "DHL Aviation",
            "code": "",
            "auxcode": "DHV",
            "companyName": "WORLDSTAR",
            "country": "South Africa"
        },
        {
            "name": "DHL International",
            "code": "ES",
            "auxcode": "DHX",
            "companyName": "DILMUN",
            "country": "Bahrain"
        },
        {
            "name": "DHL de Guatemala",
            "code": "L3",
            "auxcode": "JOS",
            "companyName": "",
            "country": "Guatemala"
        },
        {
            "name": "DSWA",
            "code": "",
            "auxcode": "RSK",
            "companyName": "REDSKIN",
            "country": "United States"
        },
        {
            "name": "Daallo Airlines",
            "code": "D3",
            "auxcode": "DAO",
            "companyName": "DALO AIRLINES",
            "country": "Djibouti"
        },
        {
            "name": "Dagestan Airlines",
            "code": "N2",
            "auxcode": "DAG",
            "companyName": "DAGAL",
            "country": "Russia"
        },
        {
            "name": "Dahla Airlines",
            "code": "",
            "auxcode": "DHA",
            "companyName": "",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Daimler Chrysler Aviation",
            "code": "",
            "auxcode": "DCS",
            "companyName": "TWIN STAR",
            "country": "Germany"
        },
        {
            "name": "DaimlercodeChrysler",
            "code": "",
            "auxcode": "DCX",
            "companyName": "DAIMLER",
            "country": "United States"
        },
        {
            "name": "Daka",
            "code": "",
            "auxcode": "DKA",
            "companyName": "",
            "country": "Kazakhstan"
        },
        {
            "name": "Dala Air Services",
            "code": "",
            "auxcode": "DLR",
            "companyName": "DALA AIR",
            "country": "Nigeria"
        },
        {
            "name": "Dalavia",
            "code": "H8",
            "auxcode": "KHB",
            "companyName": "DALAVIA",
            "country": "Russia"
        },
        {
            "name": "Dallas Express Airlines",
            "code": "",
            "auxcode": "DXP",
            "companyName": "DALLAS EXPRESS",
            "country": "United States"
        },
        {
            "name": "Damascene Airways",
            "code": "",
            "auxcode": "DAS",
            "companyName": "AIRDAM",
            "country": "Syrian Arab Republic"
        },
        {
            "name": "Danbury Airways",
            "code": "",
            "auxcode": "DSA",
            "companyName": "DANBURY AIRWAYS",
            "country": "United States"
        },
        {
            "name": "Dancopter",
            "code": "",
            "auxcode": "DOP",
            "companyName": "DANCOPTER",
            "country": "Denmark"
        },
        {
            "name": "Danish Air Force",
            "code": "",
            "auxcode": "DAF",
            "companyName": "DANISH AIRFORCE",
            "country": "Denmark"
        },
        {
            "name": "Danish Army",
            "code": "",
            "auxcode": "DAR",
            "companyName": "DANISH ARMY",
            "country": "Denmark"
        },
        {
            "name": "Danish Navy",
            "code": "",
            "auxcode": "DNY",
            "companyName": "DANISH NAVY",
            "country": "Denmark"
        },
        {
            "name": "Danu Oro Transportas",
            "code": "",
            "auxcode": "DNU",
            "companyName": "DANU",
            "country": "Lithuania"
        },
        {
            "name": "Darta",
            "code": "",
            "auxcode": "DRT",
            "companyName": "DARTA",
            "country": "France"
        },
        {
            "name": "Darwin Airline",
            "code": "0D",
            "auxcode": "DWT",
            "companyName": "DARWIN",
            "country": "Switzerland"
        },
        {
            "name": "Dasab Airlines",
            "code": "",
            "auxcode": "DSQ",
            "companyName": "DASAB AIR",
            "country": "Uganda"
        },
        {
            "name": "Dash Air Charter",
            "code": "",
            "auxcode": "DSH",
            "companyName": "DASH CHARTER",
            "country": "United States"
        },
        {
            "name": "Dash Aviation",
            "code": "",
            "auxcode": "GOB",
            "companyName": "PILGRIM",
            "country": "United Kingdom"
        },
        {
            "name": "Dasnair",
            "code": "",
            "auxcode": "DGX",
            "companyName": "DASNA",
            "country": "Switzerland"
        },
        {
            "name": "Dassault Aviation",
            "code": "",
            "auxcode": "DAB",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Dassault Falcon Jet Corporation",
            "code": "",
            "auxcode": "CVF",
            "companyName": "CLOVERLEAF",
            "country": "United States"
        },
        {
            "name": "Dassault Falcon Service",
            "code": "",
            "auxcode": "DSO",
            "companyName": "DASSAULT",
            "country": "France"
        },
        {
            "name": "Data International",
            "code": "",
            "auxcode": "DTN",
            "companyName": "DATA AIR",
            "country": "Sudan"
        },
        {
            "name": "Date Transformation Corp",
            "code": "",
            "auxcode": "XDT",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Dauair",
            "code": "D5",
            "auxcode": "DAU",
            "companyName": "DAUAIR",
            "country": "Germany"
        },
        {
            "name": "David Crawshaw Consultants Limited",
            "code": "",
            "auxcode": "DCO",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Dawn Air",
            "code": "",
            "auxcode": "DWN",
            "companyName": "DAWN AIR",
            "country": "United States"
        },
        {
            "name": "DayJet",
            "code": "",
            "auxcode": "DJS",
            "companyName": "DAYJET",
            "country": "United States"
        },
        {
            "name": "Daya Aviation",
            "code": "",
            "auxcode": "DAY",
            "companyName": "DAYA",
            "country": "Sri Lanka"
        },
        {
            "name": "De Havilland",
            "code": "",
            "auxcode": "DHC",
            "companyName": "DEHAVILLAND",
            "country": "Canada"
        },
        {
            "name": "Deadalos Flugtbetriebs",
            "code": "",
            "auxcode": "IAY",
            "companyName": "IASON",
            "country": "Austria"
        },
        {
            "name": "Decatur Aviation",
            "code": "",
            "auxcode": "DAA",
            "companyName": "DECUR",
            "country": "United States"
        },
        {
            "name": "Deccan Aviation",
            "code": "",
            "auxcode": "DKN",
            "companyName": "DECCAN",
            "country": "India"
        },
        {
            "name": "Deccan Aviation (Lanka)",
            "code": "",
            "auxcode": "DLK",
            "companyName": "DEKKANLANKA",
            "country": "Sri Lanka"
        },
        {
            "name": "Deer Jet",
            "code": "",
            "auxcode": "DER",
            "companyName": "DEER JET",
            "country": "China"
        },
        {
            "name": "Deere and Company",
            "code": "",
            "auxcode": "JDC",
            "companyName": "JOHN DEERE",
            "country": "United States"
        },
        {
            "name": "Delaware Skyways",
            "code": "",
            "auxcode": "DWR",
            "companyName": "DELAWARE",
            "country": "United States"
        },
        {
            "name": "Delta Aerotaxi",
            "code": "",
            "auxcode": "DEA",
            "companyName": "JET SERVICE",
            "country": "Italy"
        },
        {
            "name": "Delta Air Charter",
            "code": "",
            "auxcode": "SNO",
            "companyName": "SNOWBALL",
            "country": "Canada"
        },
        {
            "name": "Delta Air Elite",
            "code": "",
            "auxcode": "ELJ",
            "companyName": "ELITE JET",
            "country": "United States"
        },
        {
            "name": "Delta Air Lines",
            "code": "DL",
            "auxcode": "DAL",
            "companyName": "DELTA",
            "country": "United States"
        },
        {
            "name": "Delta Engineering Aviation",
            "code": "",
            "auxcode": "KMB",
            "companyName": "KEMBLEJET",
            "country": "United Kingdom"
        },
        {
            "name": "Delta Express International",
            "code": "",
            "auxcode": "DLI",
            "companyName": "DELTA EXPRESS",
            "country": "Ukraine"
        },
        {
            "name": "Delta State University",
            "code": "",
            "auxcode": "DSU",
            "companyName": "DELTA STATE",
            "country": "United States"
        },
        {
            "name": "Denim Air",
            "code": "",
            "auxcode": "DNM",
            "companyName": "DENIM",
            "country": "Netherlands"
        },
        {
            "name": "Denver Express",
            "code": "",
            "auxcode": "FEC",
            "companyName": "FALCON EXPRESS",
            "country": "United States"
        },
        {
            "name": "Denver Jet",
            "code": "",
            "auxcode": "DJT",
            "companyName": "DENVER JET",
            "country": "United States"
        },
        {
            "name": "Departmento De Agricultura De La Generalitat De Cataluna",
            "code": "",
            "auxcode": "FGC",
            "companyName": "FORESTALES",
            "country": "Spain"
        },
        {
            "name": "Deraya Air Taxi",
            "code": "",
            "auxcode": "DRY",
            "companyName": "DERAYA",
            "country": "Indonesia"
        },
        {
            "name": "Des R Cargo Express",
            "code": "",
            "auxcode": "DRX",
            "companyName": "",
            "country": "Mauritania"
        },
        {
            "name": "Desarrollo Milaz",
            "code": "",
            "auxcode": "MIZ",
            "companyName": "MILAZ",
            "country": "Mexico"
        },
        {
            "name": "Destiny Air Services",
            "code": "",
            "auxcode": "DTY",
            "companyName": "DESTINY",
            "country": "Sierra Leone"
        },
        {
            "name": "Deutsche Bahn",
            "code": "2A",
            "auxcode": "",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Deutsche Rettungsflugwacht",
            "code": "1I",
            "auxcode": "AMB",
            "companyName": "CIVIL AIR AMBULANCE",
            "country": "Germany"
        },
        {
            "name": "Deutsches Zentrum fur Luftcodeund Raumfahrt EV",
            "code": "",
            "auxcode": "LFO",
            "companyName": "LUFO",
            "country": "Germany"
        },
        {
            "name": "Di Air",
            "code": "",
            "auxcode": "DIS",
            "companyName": "DI AIR",
            "country": "Serbia"
        },
        {
            "name": "Diamond Aviation",
            "code": "",
            "auxcode": "SPK",
            "companyName": "SPARKLE",
            "country": "United States"
        },
        {
            "name": "Didier Rousset Buy",
            "code": "",
            "auxcode": "DRB",
            "companyName": "DIDIER",
            "country": "Chile"
        },
        {
            "name": "Digital Equipment Corporation",
            "code": "",
            "auxcode": "DGT",
            "companyName": "DIGITAL",
            "country": "United States"
        },
        {
            "name": "Dinar",
            "code": "D7",
            "auxcode": "RDN",
            "companyName": "AERO DINAR",
            "country": "Argentina"
        },
        {
            "name": "Diplomatic Freight Services",
            "code": "",
            "auxcode": "DIP",
            "companyName": "DIPFREIGHT",
            "country": "United Kingdom"
        },
        {
            "name": "Direccion General de Aviacion Civil y Telecomunicasciones",
            "code": "",
            "auxcode": "ENA",
            "companyName": "ENA",
            "country": "Spain"
        },
        {
            "name": "Direct Air",
            "code": "",
            "auxcode": "DIA",
            "companyName": "BLUE SKY",
            "country": "United States"
        },
        {
            "name": "Direct Air trading as Midway Connection",
            "code": "",
            "auxcode": "XAP",
            "companyName": "MIDcodeTOWN",
            "country": "United States"
        },
        {
            "name": "Direct Flight",
            "code": "",
            "auxcode": "DCT",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Sky Express",
            "code": "",
            "auxcode": "SXP",
            "companyName": "EXPRESS SKY",
            "country": "Poland"
        },
        {
            "name": "Dirgantara Air Service",
            "code": "AW",
            "auxcode": "DIR",
            "companyName": "DIRGANTARA",
            "country": "Indonesia"
        },
        {
            "name": "Discover Air",
            "code": "",
            "auxcode": "DCV",
            "companyName": "DISCOVER",
            "country": "United States"
        },
        {
            "name": "Discovery Airways",
            "code": "DH",
            "auxcode": "DVA",
            "companyName": "DISCOVERY AIRWAYS",
            "country": "United States"
        },
        {
            "name": "Dispatch Services",
            "code": "",
            "auxcode": "XDS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Dix Aviation",
            "code": "",
            "auxcode": "DIX",
            "companyName": "DIX FLIGHT",
            "country": "Germany"
        },
        {
            "name": "Dixie Airways",
            "code": "",
            "auxcode": "DEE",
            "companyName": "TACAIR",
            "country": "United States"
        },
        {
            "name": "Djibouti Airlines",
            "code": "D8",
            "auxcode": "DJB",
            "companyName": "DJIBOUTI AIR",
            "country": "Djibouti"
        },
        {
            "name": "Dniproavia",
            "code": "",
            "auxcode": "UDN",
            "companyName": "DNIEPRO",
            "country": "Ukraine"
        },
        {
            "name": "Flying Dolphin Airlines",
            "code": "",
            "auxcode": "FDN",
            "companyName": "FLYING DOLPHIN",
            "country": "United Arab Emirates"
        },
        {
            "name": "Dolphin Express Airlines",
            "code": "",
            "auxcode": "IXX",
            "companyName": "ISLAND EXPRESS",
            "country": "United States"
        },
        {
            "name": "Dome Petroleum",
            "code": "",
            "auxcode": "DPL",
            "companyName": "DOME",
            "country": "Canada"
        },
        {
            "name": "Dominguez Toledo (Grupo Mayoral)",
            "code": "",
            "auxcode": "MYO",
            "companyName": "MAYORAL",
            "country": "Spain"
        },
        {
            "name": "Dominicana de Aviaci",
            "code": "DO",
            "auxcode": "DOA",
            "companyName": "DOMINICANA",
            "country": "Dominican Republic"
        },
        {
            "name": "Domodedovo Airlines",
            "code": "E3",
            "auxcode": "DMO",
            "companyName": "DOMODEDOVO",
            "country": "Russia"
        },
        {
            "name": "Don Avia",
            "code": "",
            "auxcode": "DVB",
            "companyName": "DONSEBAI",
            "country": "Kazakhstan"
        },
        {
            "name": "Donair Flying Club",
            "code": "",
            "auxcode": "DON",
            "companyName": "DONAIR",
            "country": "United Kingdom"
        },
        {
            "name": "DonbassAero",
            "code": "5D",
            "auxcode": "UDC",
            "companyName": "DONBASS AERO",
            "country": "Ukraine"
        },
        {
            "name": "Dorado Air",
            "code": "",
            "auxcode": "DAD",
            "companyName": "DORADO AIR",
            "country": "Dominican Republic"
        },
        {
            "name": "Dornier",
            "code": "",
            "auxcode": "DOR",
            "companyName": "DORNIER",
            "country": "Germany"
        },
        {
            "name": "Dornier Aviation Nigeria",
            "code": "",
            "auxcode": "DAV",
            "companyName": "DANA AIR",
            "country": "Nigeria"
        },
        {
            "name": "Dos Mundos",
            "code": "",
            "auxcode": "DOM",
            "companyName": "DOS MUNDOS",
            "country": "Dominican Republic"
        },
        {
            "name": "Dragonair",
            "code": "KA",
            "auxcode": "HDA",
            "companyName": " Hong Kong Dragon Airlines",
            "country": "DRAGON"
        },
        {
            "name": "Dreamcatcher Airways",
            "code": "",
            "auxcode": "DCA",
            "companyName": "DREAM CATCHER",
            "country": "United Kingdom"
        },
        {
            "name": "Druk Air",
            "code": "KB",
            "auxcode": "DRK",
            "companyName": "ROYAL BHUTAN",
            "country": "Bhutan"
        },
        {
            "name": "Drummond Island Air",
            "code": "",
            "auxcode": "DRE",
            "companyName": "MICHIGAN",
            "country": "United States"
        },
        {
            "name": "Dubai Airwing",
            "code": "",
            "auxcode": "DUB",
            "companyName": "DUBAI",
            "country": "United Arab Emirates"
        },
        {
            "name": "Dubrovnik Air",
            "code": "",
            "auxcode": "DBK",
            "companyName": "SEAGULL",
            "country": "Croatia"
        },
        {
            "name": "Ducair",
            "code": "",
            "auxcode": "DUK",
            "companyName": "LION KING",
            "country": "Luxembourg"
        },
        {
            "name": "Duchess of Britany (Jersey) Limited",
            "code": "",
            "auxcode": "DBJ",
            "companyName": "DUCHESS",
            "country": "United Kingdom"
        },
        {
            "name": "UK Royal/HRH Duke of York",
            "code": "",
            "auxcode": "LPD",
            "companyName": "LEOPARD",
            "country": "United Kingdom"
        },
        {
            "name": "Dun'Air",
            "code": "",
            "auxcode": "DUN",
            "companyName": "DUNAIR",
            "country": "Mauritania"
        },
        {
            "name": "Duncan Aviation",
            "code": "",
            "auxcode": "PHD",
            "companyName": "PANHANDLE",
            "country": "United States"
        },
        {
            "name": "Dunyaya Bakis Hava Tasimaciligi",
            "code": "",
            "auxcode": "VVF",
            "companyName": "WORLDFOCUS",
            "country": "Turkey"
        },
        {
            "name": "Duo Airways",
            "code": "",
            "auxcode": "DUO",
            "companyName": "FLY DUO",
            "country": "United Kingdom"
        },
        {
            "name": "Durango Jet",
            "code": "",
            "auxcode": "DJE",
            "companyName": "DURANGO JET",
            "country": "Mexico"
        },
        {
            "name": "Dutch Antilles Express",
            "code": "",
            "auxcode": "DNL",
            "companyName": "DUTCH ANTILLES",
            "country": "Netherlands Antilles"
        },
        {
            "name": "Dutch Caribbean Express",
            "code": "",
            "auxcode": "DCE",
            "companyName": "DUTCH CARIBBEAN",
            "country": "Netherlands Antilles"
        },
        {
            "name": "Dutchbird",
            "code": "",
            "auxcode": "DBR",
            "companyName": "DUTCHBIRD",
            "country": "Netherlands"
        },
        {
            "name": "Dwyer Aircraft Services",
            "code": "",
            "auxcode": "DFS",
            "companyName": "DWYAIR",
            "country": "United States"
        },
        {
            "name": "Dynair Services",
            "code": "",
            "auxcode": "XDY",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Dynamair Aviation",
            "code": "",
            "auxcode": "DNR",
            "companyName": "DYNAMAIR",
            "country": "Canada"
        },
        {
            "name": "Dynamic Air",
            "code": "",
            "auxcode": "DYE",
            "companyName": "DYNAMIC",
            "country": "Netherlands"
        },
        {
            "name": "dba",
            "code": "DI",
            "auxcode": "BAG",
            "companyName": "SPEEDWAY",
            "country": "Germany"
        },
        {
            "name": "E H Darby Aviation",
            "code": "",
            "auxcode": "EHD",
            "companyName": "PLATINUM AIR",
            "country": "United States"
        },
        {
            "name": "Electronic Data Systems",
            "code": "1C",
            "auxcode": "",
            "companyName": "",
            "country": "Switzerland"
        },
        {
            "name": "Electronic Data Systems",
            "code": "",
            "auxcode": "1Y",
            "companyName": "",
            "country": ""
        },
        {
            "name": "EAA Escola De Aviacao Aerocondor",
            "code": "",
            "auxcode": "EAD",
            "companyName": "AEROcodeESCOLA",
            "country": "Portugal"
        },
        {
            "name": "Executive Airlines Services",
            "code": "",
            "auxcode": "EXW",
            "companyName": "ECHOLINE",
            "country": "Nigeria"
        },
        {
            "name": "EFAOScode Agencia De Viagens e Turismo",
            "code": "",
            "auxcode": "EFS",
            "companyName": "EFAOS",
            "country": "Angola"
        },
        {
            "name": "EFD Eisele Flugdienst",
            "code": "",
            "auxcode": "EFD",
            "companyName": "EVER FLIGHT",
            "country": "Germany"
        },
        {
            "name": "EFScodeFlugservice",
            "code": "",
            "auxcode": "FSD",
            "companyName": "FLUGSERVICE",
            "country": "Germany"
        },
        {
            "name": "EIS Aircraft",
            "code": "",
            "auxcode": "EIS",
            "companyName": "COOL",
            "country": "Germany"
        },
        {
            "name": "EPAG Groupe Air France",
            "code": "",
            "auxcode": "IAG",
            "companyName": "EPAG",
            "country": "France"
        },
        {
            "name": "ESI Eliservizi Italiani",
            "code": "",
            "auxcode": "ESI",
            "companyName": "ELISERVIZI",
            "country": "Italy"
        },
        {
            "name": "EU Airways",
            "code": "",
            "auxcode": "EUY",
            "companyName": "EUROAIRWAYS",
            "country": "Ireland"
        },
        {
            "name": "EUjet",
            "code": "VE",
            "auxcode": "EUJ",
            "companyName": "UNION JET",
            "country": "Ireland"
        },
        {
            "name": "EVA Air",
            "code": "BR",
            "auxcode": "EVA",
            "companyName": "EVA",
            "country": "Taiwan"
        },
        {
            "name": "Eagle Aero",
            "code": "",
            "auxcode": "ICR",
            "companyName": "ICARUS FLIGHTS",
            "country": "United States"
        },
        {
            "name": "Eagle Air",
            "code": "",
            "auxcode": "EGR",
            "companyName": "EAGLE SIERRA",
            "country": "Sierra Leone"
        },
        {
            "name": "Eagle Air",
            "code": "H7",
            "auxcode": "",
            "companyName": "",
            "country": "Uganda"
        },
        {
            "name": "Eagle Air Company",
            "code": "",
            "auxcode": "EGX",
            "companyName": "THAI EAGLE",
            "country": "Thailand"
        },
        {
            "name": "Eagle Air Iceland",
            "code": "",
            "auxcode": "FEI",
            "companyName": "ARCTIC EAGLE",
            "country": "Iceland"
        },
        {
            "name": "Eagle Aviation",
            "code": "",
            "auxcode": "EGU",
            "companyName": "AFRICAN EAGLE",
            "country": "Uganda"
        },
        {
            "name": "Eagle Aviation",
            "code": "",
            "auxcode": "GYP",
            "companyName": "GYPSY",
            "country": "United Kingdom"
        },
        {
            "name": "Eagle Aviation France",
            "code": "",
            "auxcode": "EGN",
            "companyName": "FRENCH EAGLE",
            "country": "France"
        },
        {
            "name": "Eagle International",
            "code": "",
            "auxcode": "SEG",
            "companyName": "SENcodeEAGLE",
            "country": "Senegal"
        },
        {
            "name": "Eagle Jet Charter",
            "code": "",
            "auxcode": "EGJ",
            "companyName": "EAGLE JET",
            "country": "United States"
        },
        {
            "name": "Eaglemed (Ballard Aviation)",
            "code": "",
            "auxcode": "EMD",
            "companyName": "EAGLEMED",
            "country": "United States"
        },
        {
            "name": "Earth Airlines Services",
            "code": "",
            "auxcode": "ERX",
            "companyName": "EARTH AIR",
            "country": "Nigeria"
        },
        {
            "name": "East African",
            "code": "QU",
            "auxcode": "UGX",
            "companyName": "CRANE",
            "country": "Uganda"
        },
        {
            "name": "East African Safari Air",
            "code": "S9",
            "auxcode": "HSA",
            "companyName": "DUMA",
            "country": "Kenya"
        },
        {
            "name": "East African Safari Air Express",
            "code": "",
            "auxcode": "EXZ",
            "companyName": "TWIGA",
            "country": "Kenya"
        },
        {
            "name": "East Asia Airlines",
            "code": "",
            "auxcode": "EMU",
            "companyName": "",
            "country": "Macao"
        },
        {
            "name": "East Coast Airways",
            "code": "",
            "auxcode": "ECT",
            "companyName": "EASTWAY",
            "country": "South Africa"
        },
        {
            "name": "East Coast Jets",
            "code": "",
            "auxcode": "ECJ",
            "companyName": "EASTCOAST JET",
            "country": "United States"
        },
        {
            "name": "East Hampton Aire",
            "code": "",
            "auxcode": "EHA",
            "companyName": "AIRE HAMPTON",
            "country": "United States"
        },
        {
            "name": "East Kansas City Aviation",
            "code": "",
            "auxcode": "EKC",
            "companyName": "BLUE GOOSE",
            "country": "United States"
        },
        {
            "name": "East Midlands Helicopters",
            "code": "",
            "auxcode": "CTK",
            "companyName": "COSTOCK",
            "country": "United Kingdom"
        },
        {
            "name": "East Star Airlines",
            "code": "",
            "auxcode": "DXH",
            "companyName": "EAST STAR",
            "country": "China"
        },
        {
            "name": "EastcodeWest Airlines",
            "code": "",
            "auxcode": "EWA",
            "companyName": "EASTWEST",
            "country": "Australia"
        },
        {
            "name": "Eastern Air",
            "code": "",
            "auxcode": "EAZ",
            "companyName": "EASAIR",
            "country": "Zambia"
        },
        {
            "name": "Eastern Air Executive",
            "code": "",
            "auxcode": "EAX",
            "companyName": "EASTEX",
            "country": "United Kingdom"
        },
        {
            "name": "Eastern Airways",
            "code": "T3",
            "auxcode": "EZE",
            "companyName": "EASTFLIGHT",
            "country": "United Kingdom"
        },
        {
            "name": "Eastern Australia Airlines",
            "code": "",
            "auxcode": "EAQ",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Eastern Carolina Aviation",
            "code": "",
            "auxcode": "ECI",
            "companyName": "EASTERN CAROLINA",
            "country": "United States"
        },
        {
            "name": "Eastern Executive Air Charter",
            "code": "",
            "auxcode": "GNS",
            "companyName": "GENESIS",
            "country": "United Kingdom"
        },
        {
            "name": "Eastern Express",
            "code": "",
            "auxcode": "LIS",
            "companyName": "LARISA",
            "country": "Kazakhstan"
        },
        {
            "name": "Eastern Metro Express",
            "code": "",
            "auxcode": "EME",
            "companyName": "EMAIR",
            "country": "United States"
        },
        {
            "name": "Eastern Pacific Aviation",
            "code": "",
            "auxcode": "EPB",
            "companyName": "EAST PAC",
            "country": "Canada"
        },
        {
            "name": "Eastern Sky Jets",
            "code": "",
            "auxcode": "ESJ",
            "companyName": "EASTERN SKYJETS",
            "country": "United Arab Emirates"
        },
        {
            "name": "Eastland Air",
            "code": "DK",
            "auxcode": "ELA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Eastwind Airlines",
            "code": "W9",
            "auxcode": "SGR",
            "companyName": "STINGER",
            "country": "United States"
        },
        {
            "name": "Easy Link Aviation Services",
            "code": "",
            "auxcode": "FYE",
            "companyName": "FLYME",
            "country": "Nigeria"
        },
        {
            "name": "Eckles Aircraft",
            "code": "",
            "auxcode": "CMN",
            "companyName": "CIMMARON AIRE",
            "country": "United States"
        },
        {
            "name": "Eclipse Aviation",
            "code": "",
            "auxcode": "EJT",
            "companyName": "ECLIPSE JET",
            "country": "United States"
        },
        {
            "name": "Eco Air",
            "code": "",
            "auxcode": "ECQ",
            "companyName": "SKYBRIDGE",
            "country": "Nigeria"
        },
        {
            "name": "Ecoair",
            "code": "",
            "auxcode": "DEI",
            "companyName": "",
            "country": "Algeria"
        },
        {
            "name": "Ecomex Air Cargo",
            "code": "",
            "auxcode": "ECX",
            "companyName": "AIR ECOMEX",
            "country": "Angola"
        },
        {
            "name": "Ecotour",
            "code": "",
            "auxcode": "ECD",
            "companyName": "ECOTOUR",
            "country": "Mexico"
        },
        {
            "name": "Ecoturistica de Xcalak",
            "code": "",
            "auxcode": "XCC",
            "companyName": "XCALAK",
            "country": "Mexico"
        },
        {
            "name": "Ecuatoguineana De Aviacion (EGA)",
            "code": "",
            "auxcode": "ECV",
            "companyName": "EQUATOGUINEA",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Ecuatorial Cargo",
            "code": "",
            "auxcode": "EQC",
            "companyName": "ECUAcodeCARGO",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Ecuavia",
            "code": "",
            "auxcode": "ECU",
            "companyName": "ECUAVIA",
            "country": "Ecuador"
        },
        {
            "name": "Edelweiss Air",
            "code": "WK",
            "auxcode": "EDW",
            "companyName": "EDELWEISS",
            "country": "Switzerland"
        },
        {
            "name": "Edgartown Air",
            "code": "",
            "auxcode": "SLO",
            "companyName": "SLOW",
            "country": "United States"
        },
        {
            "name": "Edinburgh Air Charter",
            "code": "",
            "auxcode": "EDC",
            "companyName": "SALTIRE",
            "country": "United Kingdom"
        },
        {
            "name": "Edwards Jet Center of Montana",
            "code": "",
            "auxcode": "EDJ",
            "companyName": "EDWARDS",
            "country": "United States"
        },
        {
            "name": "Efata Papua Airlines",
            "code": "",
            "auxcode": "EIJ",
            "companyName": "EFATA",
            "country": "Indonesia"
        },
        {
            "name": "Egyptair",
            "code": "MS",
            "auxcode": "MSR",
            "companyName": "EGYPTAIR",
            "country": "Egypt"
        },
        {
            "name": "Egyptair Cargo",
            "code": "",
            "auxcode": "MSX",
            "companyName": "EGYPTAIR CARGO",
            "country": "Egypt"
        },
        {
            "name": "Egyptian Air Force",
            "code": "",
            "auxcode": "EGY",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Egyptian Aviation",
            "code": "",
            "auxcode": "EJX",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Egyptian Aviation Company",
            "code": "",
            "auxcode": "EMA",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Ei Air Exports",
            "code": "",
            "auxcode": "EIX",
            "companyName": "AIR EXPORTS",
            "country": "Ireland"
        },
        {
            "name": "Eirjet",
            "code": "",
            "auxcode": "EIR",
            "companyName": "EIRJET",
            "country": "Ireland"
        },
        {
            "name": "El Al Israel Airlines",
            "code": "LY",
            "auxcode": "ELY",
            "companyName": "ELAL",
            "country": "Israel"
        },
        {
            "name": "El Caminante Taxi Aereo",
            "code": "",
            "auxcode": "CMX",
            "companyName": "EL CAMINANTE",
            "country": "Mexico"
        },
        {
            "name": "El Quilada International Aviation",
            "code": "",
            "auxcode": "GLQ",
            "companyName": "QUILADA",
            "country": "Sudan"
        },
        {
            "name": "El Sal Air",
            "code": "",
            "auxcode": "ELS",
            "companyName": "EL SAL",
            "country": "El Salvador"
        },
        {
            "name": "El Sol De America",
            "code": "",
            "auxcode": "ESC",
            "companyName": "SOLAMERICA",
            "country": "Venezuela"
        },
        {
            "name": "ElcodeBuraq Air Transport",
            "code": "UZ",
            "auxcode": "BRQ",
            "companyName": "BURAQAIR",
            "country": "Libya"
        },
        {
            "name": "Elan Express",
            "code": "",
            "auxcode": "ELX",
            "companyName": "ELAN",
            "country": "United States"
        },
        {
            "name": "Elbe Air Transport",
            "code": "",
            "auxcode": "LBR",
            "companyName": "MOTION",
            "country": "Germany"
        },
        {
            "name": "ElbruscodeAvia Air Enterprise",
            "code": "",
            "auxcode": "NLK",
            "companyName": "ELAVIA",
            "country": "Russia"
        },
        {
            "name": "Eldinder Aviation",
            "code": "",
            "auxcode": "DND",
            "companyName": "DINDER",
            "country": "Sudan"
        },
        {
            "name": "Elicar",
            "code": "",
            "auxcode": "PDV",
            "companyName": "ELICAR",
            "country": "Italy"
        },
        {
            "name": "Elidolomiti",
            "code": "",
            "auxcode": "EDO",
            "companyName": "ELIDOLOMITI",
            "country": "Italy"
        },
        {
            "name": "Elieuro",
            "code": "",
            "auxcode": "ELB",
            "companyName": "ELILOBARDIA",
            "country": "Italy"
        },
        {
            "name": "Elifriulia",
            "code": "",
            "auxcode": "EFG",
            "companyName": "ELIFRIULIA",
            "country": "Italy"
        },
        {
            "name": "Elilario Italia",
            "code": "",
            "auxcode": "ELH",
            "companyName": "LARIO",
            "country": "Italy"
        },
        {
            "name": "Elilombarda",
            "code": "",
            "auxcode": "EOA",
            "companyName": "LOMBARDA",
            "country": "Italy"
        },
        {
            "name": "Elimediterranea",
            "code": "",
            "auxcode": "MEE",
            "companyName": "ELIMEDITERRANEA",
            "country": "Italy"
        },
        {
            "name": "Elios",
            "code": "",
            "auxcode": "VUL",
            "companyName": "ELIOS",
            "country": "Italy"
        },
        {
            "name": "Elipiu'",
            "code": "",
            "auxcode": "IEP",
            "companyName": "ELIPIU",
            "country": "Italy"
        },
        {
            "name": "Elisra Airlines",
            "code": "",
            "auxcode": "RSA",
            "companyName": "ESRA",
            "country": "Sudan"
        },
        {
            "name": "Elite Air",
            "code": "",
            "auxcode": "EAI",
            "companyName": "ELAIR",
            "country": "Togo"
        },
        {
            "name": "Elite Jets",
            "code": "",
            "auxcode": "EJD",
            "companyName": "ELITE DUBAI",
            "country": "United Arab Emirates"
        },
        {
            "name": "Elitellina",
            "code": "",
            "auxcode": "FGS",
            "companyName": "ELITELLINA",
            "country": "Italy"
        },
        {
            "name": "Elliott Aviation",
            "code": "",
            "auxcode": "ELT",
            "companyName": "ELLIOT",
            "country": "United States"
        },
        {
            "name": "Elmagal Aviation Services",
            "code": "",
            "auxcode": "MGG",
            "companyName": "ELMAGAL",
            "country": "Sudan"
        },
        {
            "name": "Elrom Aviation and Investments",
            "code": "",
            "auxcode": "ELR",
            "companyName": "",
            "country": "Israel"
        },
        {
            "name": "Embassy Airlines",
            "code": "",
            "auxcode": "EAM",
            "companyName": "EMBASSY AIR",
            "country": "Nigeria"
        },
        {
            "name": "Embassy Freight Company",
            "code": "",
            "auxcode": "EFT",
            "companyName": "EMBASSY FREIGHT",
            "country": "United States"
        },
        {
            "name": "Empresa Brasileira De Aeronautica",
            "code": "",
            "auxcode": "EMB",
            "companyName": "EMBRAER",
            "country": "Brazil"
        },
        {
            "name": "EmbrycodeRiddle Aeronautical University",
            "code": "",
            "auxcode": "XSL",
            "companyName": "SATSLAB",
            "country": "United States"
        },
        {
            "name": "Emerald Airways",
            "code": "",
            "auxcode": "JEM",
            "companyName": "GEMSTONE",
            "country": "United Kingdom"
        },
        {
            "name": "Emery Worldwide Airlines",
            "code": "",
            "auxcode": "EWW",
            "companyName": "EMERY",
            "country": "United States"
        },
        {
            "name": "Emetebe",
            "code": "",
            "auxcode": "EMT",
            "companyName": "EMETEBE",
            "country": "Ecuador"
        },
        {
            "name": "Emirates",
            "code": "EK",
            "auxcode": "UAE",
            "companyName": "EMIRATES",
            "country": "United Arab Emirates"
        },
        {
            "name": "Emoyeni Air Charter",
            "code": "",
            "auxcode": "SBC",
            "companyName": "SABIAN AIR",
            "country": "South Africa"
        },
        {
            "name": "Empire Air Service",
            "code": "",
            "auxcode": "EMP",
            "companyName": "EMPIRE",
            "country": "United States"
        },
        {
            "name": "Empire Airlines",
            "code": "EM",
            "auxcode": "CFS",
            "companyName": "EMPIRE AIR",
            "country": "United States"
        },
        {
            "name": "Empire Aviation Services",
            "code": "",
            "auxcode": "MPR",
            "companyName": "",
            "country": "Nigeria"
        },
        {
            "name": "Empire Test Pilots' School",
            "code": "",
            "auxcode": "ETP",
            "companyName": "TESTER",
            "country": "United Kingdom"
        },
        {
            "name": "Empresa (Aero Uruguay)",
            "code": "",
            "auxcode": "AUO",
            "companyName": " S.A.",
            "country": "UNIFORM OSCAR"
        },
        {
            "name": "Empresa AerocodeServicios Parrague",
            "code": "",
            "auxcode": "PRG",
            "companyName": "ASPAR",
            "country": "Chile"
        },
        {
            "name": "Empresa Aerocaribbean",
            "code": "",
            "auxcode": "CRN",
            "companyName": "AEROCARIBBEAN",
            "country": "Cuba"
        },
        {
            "name": "Empresa Aviacion Interamericana",
            "code": "",
            "auxcode": "VNA",
            "companyName": "EBBA",
            "country": "Uruguay"
        },
        {
            "name": "Empresa Ecuatoriana De Aviacion",
            "code": "EU",
            "auxcode": "EEA",
            "companyName": "ECUATORIANA",
            "country": "Ecuador"
        },
        {
            "name": "Empresa Nacional De Servicios Aereos",
            "code": "",
            "auxcode": "CNI",
            "companyName": "SERAER",
            "country": "Cuba"
        },
        {
            "name": "Empresa Venezolana",
            "code": "",
            "auxcode": "VNE",
            "companyName": "VENEZOLANA",
            "country": "Venezuela"
        },
        {
            "name": "Empresa de Aviacion Aerogaviota",
            "code": "",
            "auxcode": "GTV",
            "companyName": "GAVIOTA",
            "country": "Cuba"
        },
        {
            "name": "Empressa Brasileira de InfracodeEstrutura AeroportuariacodeInfraero",
            "code": "",
            "auxcode": "XLT",
            "companyName": "INFRAERO",
            "country": "Brazil"
        },
        {
            "name": "Endecots",
            "code": "",
            "auxcode": "ENC",
            "companyName": "ENDECOTS",
            "country": "Ecuador"
        },
        {
            "name": "Enimex",
            "code": "",
            "auxcode": "ENI",
            "companyName": "ENIMEX",
            "country": "Estonia"
        },
        {
            "name": "Enkor JSC",
            "code": "G8",
            "auxcode": "ENK",
            "companyName": "ENKOR",
            "country": "Russia]]"
        },
        {
            "name": "Enrique Gleisner Vivanco",
            "code": "",
            "auxcode": "EGV",
            "companyName": "GLEISNER",
            "country": "Chile"
        },
        {
            "name": "Ensenada Vuelos Especiales",
            "code": "",
            "auxcode": "ESE",
            "companyName": "ENSENADA ESPECIAL",
            "country": "Mexico"
        },
        {
            "name": "Entergy Services",
            "code": "",
            "auxcode": "ENS",
            "companyName": "ENTERGY SHUTTLE",
            "country": "United States"
        },
        {
            "name": "Enterprise World Airways",
            "code": "",
            "auxcode": "EWS",
            "companyName": "WORLD ENTERPRISE",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Eos Airlines",
            "code": "E0",
            "auxcode": "ESS",
            "companyName": "NEW DAWN",
            "country": "United States"
        },
        {
            "name": "Equaflight Service",
            "code": "",
            "auxcode": "EKA",
            "companyName": "EQUAFLIGHT",
            "country": "Republic of the Congo"
        },
        {
            "name": "Equatair Air Services (Zambia)",
            "code": "",
            "auxcode": "EQZ",
            "companyName": "ZAMBIA CARGO",
            "country": "Zambia"
        },
        {
            "name": "Equatorial Airlines",
            "code": "",
            "auxcode": "EQT",
            "companyName": "",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Era Helicopters",
            "code": "",
            "auxcode": "ERH",
            "companyName": "ERAH",
            "country": "United States"
        },
        {
            "name": "Eram Air",
            "code": "",
            "auxcode": "IRY",
            "companyName": "ERAM AIR",
            "country": "Iran"
        },
        {
            "name": "Erfoto",
            "code": "",
            "auxcode": "ERF",
            "companyName": "ERFOTO",
            "country": "Portugal"
        },
        {
            "name": "Erie Airways",
            "code": "",
            "auxcode": "ERE",
            "companyName": "AIR ERIE",
            "country": "United States"
        },
        {
            "name": "Eritrean Airlines",
            "code": "B8",
            "auxcode": "ERT",
            "companyName": "ERITREAN",
            "country": "Eritrea"
        },
        {
            "name": "Escuela De Pilotos Are Aviacion",
            "code": "",
            "auxcode": "CTV",
            "companyName": "ARE AVIACION",
            "country": "Spain"
        },
        {
            "name": "Espace Aviation Services",
            "code": "",
            "auxcode": "EPC",
            "companyName": "ESPACE",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Esso Resources Canada",
            "code": "",
            "auxcode": "ERC",
            "companyName": "ESSO",
            "country": "Canada"
        },
        {
            "name": "Estafeta Carga Aerea",
            "code": "E7",
            "auxcode": "ESF",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "Estonian Air",
            "code": "OV",
            "auxcode": "ELL",
            "companyName": "ESTONIAN",
            "country": "Estonia"
        },
        {
            "name": "Estrellas Del Aire",
            "code": "",
            "auxcode": "ETA",
            "companyName": "ESTRELLAS",
            "country": "Mexico"
        },
        {
            "name": "Ethiopian Airlines",
            "code": "ET",
            "auxcode": "ETH",
            "companyName": "ETHIOPIAN",
            "country": "Ethiopia"
        },
        {
            "name": "Eti 2000",
            "code": "",
            "auxcode": "MJM",
            "companyName": "ELCO ETI",
            "country": "Italy"
        },
        {
            "name": "Etihad Airways",
            "code": "EY",
            "auxcode": "ETD",
            "companyName": "ETIHAD",
            "country": "United Arab Emirates"
        },
        {
            "name": "Etram Air Wing",
            "code": "",
            "auxcode": "ETM",
            "companyName": "ETRAM",
            "country": "Angola"
        },
        {
            "name": "Euraviation",
            "code": "",
            "auxcode": "EVN",
            "companyName": "EURAVIATION",
            "country": "Italy"
        },
        {
            "name": "Euro Continental AIE",
            "code": "",
            "auxcode": "ECN",
            "companyName": "EURO CONTINENTAL",
            "country": "Spain"
        },
        {
            "name": "Euro Exec Express",
            "code": "RZ",
            "auxcode": "",
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Euro Sun",
            "code": "",
            "auxcode": "ESN",
            "companyName": "EURO SUN",
            "country": "Turkey"
        },
        {
            "name": "EurocodeAsia Air",
            "code": "",
            "auxcode": "EAK",
            "companyName": "EAKAZ",
            "country": "Kazakhstan"
        },
        {
            "name": "EurocodeAsia Air International",
            "code": "",
            "auxcode": "KZE",
            "companyName": "KAZEUR",
            "country": "Kazakhstan"
        },
        {
            "name": "EuroAtlantic Airways",
            "code": "",
            "auxcode": "MMZ",
            "companyName": "EUROATLANTIC",
            "country": "Portugal"
        },
        {
            "name": "EuroJet Aviation",
            "code": "",
            "auxcode": "GOJ",
            "companyName": "GOJET",
            "country": "United Kingdom"
        },
        {
            "name": "Euroair",
            "code": "",
            "auxcode": "EUP",
            "companyName": "EUROSTAR",
            "country": "Greece"
        },
        {
            "name": "Euroamerican Air",
            "code": "",
            "auxcode": "EUU",
            "companyName": "EUROAMERICAN",
            "country": "Uruguay"
        },
        {
            "name": "Euroceltic Airways",
            "code": "",
            "auxcode": "ECY",
            "companyName": "ECHELON",
            "country": "United Kingdom"
        },
        {
            "name": "Eurocontrol",
            "code": "",
            "auxcode": "EUC",
            "companyName": "",
            "country": "Belgium"
        },
        {
            "name": "Eurocopter",
            "code": "",
            "auxcode": "ECF",
            "companyName": "EUROCOPTER",
            "country": "France"
        },
        {
            "name": "Eurocypria Airlines",
            "code": "UI",
            "auxcode": "ECA",
            "companyName": "EUROCYPRIA",
            "country": "Cyprus"
        },
        {
            "name": "Eurofly",
            "code": "",
            "auxcode": "EEZ",
            "companyName": "EcodeFLY",
            "country": "Italy"
        },
        {
            "name": "Eurofly Service",
            "code": "GJ",
            "auxcode": "EEU",
            "companyName": "EUROFLY",
            "country": "Italy"
        },
        {
            "name": "Euroguineana de Aviacion",
            "code": "",
            "auxcode": "EUG",
            "companyName": "EUROGUINEA",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Eurojet Italia",
            "code": "",
            "auxcode": "ERJ",
            "companyName": "JET ITALIA",
            "country": "Italy"
        },
        {
            "name": "Eurojet Limited",
            "code": "",
            "auxcode": "JLN",
            "companyName": "JET LINE",
            "country": "Malta"
        },
        {
            "name": "Eurojet Romania",
            "code": "",
            "auxcode": "RDP",
            "companyName": "JETcodeARROW",
            "country": "Romania"
        },
        {
            "name": "Eurojet Servis",
            "code": "",
            "auxcode": "EJS",
            "companyName": "EEJAY SERVICE",
            "country": "Czech Republic"
        },
        {
            "name": "Eurolot",
            "code": "K2",
            "auxcode": "ELO",
            "companyName": "EUROLOT",
            "country": "Poland"
        },
        {
            "name": "Euromanx Airways",
            "code": "3W",
            "auxcode": "EMX",
            "companyName": "EUROMANX",
            "country": "Austria"
        },
        {
            "name": "Europe Air Lines",
            "code": "",
            "auxcode": "GED",
            "companyName": "LANGUEDOC",
            "country": "France"
        },
        {
            "name": "Europe Airpost",
            "code": "",
            "auxcode": "FPO",
            "companyName": "FRENCH POST",
            "country": "France"
        },
        {
            "name": "European 2000 Airlines",
            "code": "",
            "auxcode": "EUT",
            "companyName": "FIESTA",
            "country": "Malta"
        },
        {
            "name": "European Aeronautical Group UK",
            "code": "",
            "auxcode": "EAG",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "European Air Express",
            "code": "EA",
            "auxcode": "EAL",
            "companyName": "STAR WING",
            "country": "Germany"
        },
        {
            "name": "European Air Transport",
            "code": "QY",
            "auxcode": "BCS",
            "companyName": "EUROTRANS",
            "country": "Belgium"
        },
        {
            "name": "European Aviation Air Charter",
            "code": "E7",
            "auxcode": "EAF",
            "companyName": "EUROCHARTER",
            "country": "United Kingdom"
        },
        {
            "name": "European Business Jets",
            "code": "",
            "auxcode": "EBJ",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "European Coastal Airlines",
            "code": "",
            "auxcode": "ECB",
            "companyName": "COASTAL CLIPPER",
            "country": "Croatia"
        },
        {
            "name": "European Executive",
            "code": "",
            "auxcode": "ETV",
            "companyName": "EURO EXEC",
            "country": "United Kingdom"
        },
        {
            "name": "European Executive Express",
            "code": "",
            "auxcode": "EXC",
            "companyName": "ECHO EXPRESS",
            "country": "Sweden"
        },
        {
            "name": "Eurosense",
            "code": "",
            "auxcode": "EBG",
            "companyName": "EUROSENSE",
            "country": "Bulgaria"
        },
        {
            "name": "Euroskylink",
            "code": "",
            "auxcode": "ESX",
            "companyName": "CATFISH",
            "country": "United Kingdom"
        },
        {
            "name": "Eurowings",
            "code": "EW",
            "auxcode": "EWG",
            "companyName": "EUROWINGS",
            "country": "Germany"
        },
        {
            "name": "Evergreen International Airlines",
            "code": "EZ",
            "auxcode": "EIA",
            "companyName": "EVERGREEN",
            "country": "United States"
        },
        {
            "name": "Everts Air Alaska/Everts Air Cargo",
            "code": "",
            "auxcode": "VTS",
            "companyName": "EVERTS",
            "country": "United States"
        },
        {
            "name": "Examiner Training Agency",
            "code": "",
            "auxcode": "EMN",
            "companyName": "AGENCY",
            "country": "United Kingdom"
        },
        {
            "name": "Excel Airways",
            "code": "JN",
            "auxcode": "XLA",
            "companyName": "EXPO",
            "country": "United Kingdom"
        },
        {
            "name": "Excel Charter",
            "code": "",
            "auxcode": "XEL",
            "companyName": "HELI EXCEL",
            "country": "United Kingdom"
        },
        {
            "name": "Excellent Air",
            "code": "",
            "auxcode": "GZA",
            "companyName": "EXCELLENT AIR",
            "country": "Germany"
        },
        {
            "name": "Execair Aviation",
            "code": "MB",
            "auxcode": "EXA",
            "companyName": "CANADIAN EXECAIRE",
            "country": "Canada"
        },
        {
            "name": "Execujet Charter",
            "code": "",
            "auxcode": "VCN",
            "companyName": "AVCON",
            "country": "Switzerland"
        },
        {
            "name": "Execujet Middle East",
            "code": "",
            "auxcode": "EJO",
            "companyName": "MIDJET",
            "country": "United Arab Emirates"
        },
        {
            "name": "Execujet Scandinavia",
            "code": "",
            "auxcode": "VMP",
            "companyName": "VAMPIRE",
            "country": "Denmark"
        },
        {
            "name": "Executive Aerospace",
            "code": "",
            "auxcode": "EAS",
            "companyName": "AEROSPACE",
            "country": "South Africa"
        },
        {
            "name": "Executive Air",
            "code": "",
            "auxcode": "LFL",
            "companyName": "LIFE FLIGHT",
            "country": "Zimbabwe"
        },
        {
            "name": "Executive Air Charter",
            "code": "",
            "auxcode": "EAC",
            "companyName": "EXECAIR",
            "country": "United States"
        },
        {
            "name": "Executive Air Fleet",
            "code": "",
            "auxcode": "XAF",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Executive Aircraft Charter and Charter Services",
            "code": "",
            "auxcode": "ECS",
            "companyName": "ECHO",
            "country": "Ireland"
        },
        {
            "name": "Executive Aircraft Services",
            "code": "",
            "auxcode": "XAH",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Executive Airlines",
            "code": "OW",
            "auxcode": "EXK",
            "companyName": "EXECUTIVE EAGLE",
            "country": "United States"
        },
        {
            "name": "Executive Airlines",
            "code": "",
            "auxcode": "EXU",
            "companyName": "SACAIR",
            "country": "Spain"
        },
        {
            "name": "Executive Aviation Services",
            "code": "",
            "auxcode": "JTR",
            "companyName": "JESTER",
            "country": "United Kingdom"
        },
        {
            "name": "Executive Flight",
            "code": "",
            "auxcode": "EXE",
            "companyName": "EXEC",
            "country": "United States"
        },
        {
            "name": "Executive Flight Operations Ontario Government",
            "code": "",
            "auxcode": "TRI",
            "companyName": "TRILLIUM",
            "country": "Canada"
        },
        {
            "name": "Executive Jet Charter",
            "code": "",
            "auxcode": "EXJ",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Executive Jet Management",
            "code": "",
            "auxcode": "EJM",
            "companyName": "JET SPEED",
            "country": "United States"
        },
        {
            "name": "Executive Turbine Aviation",
            "code": "",
            "auxcode": "TEA",
            "companyName": "TRAVELMAX",
            "country": "South Africa"
        },
        {
            "name": "Eximflight",
            "code": "",
            "auxcode": "EXF",
            "companyName": "EXIMFLIGHT",
            "country": "Mexico"
        },
        {
            "name": "Exin",
            "code": "",
            "auxcode": "EXN",
            "companyName": "EXIN",
            "country": "Poland"
        },
        {
            "name": "Expertos En Carga",
            "code": "",
            "auxcode": "EXR",
            "companyName": "EXPERTOS ENCARGA",
            "country": "Mexico"
        },
        {
            "name": "Expo Aviation",
            "code": "8D",
            "auxcode": "EXV",
            "companyName": "EXPOAVIA",
            "country": "Sri Lanka"
        },
        {
            "name": "Express Air",
            "code": "",
            "auxcode": "FXA",
            "companyName": "EFFEX",
            "country": "United States"
        },
        {
            "name": "Express International Cargo",
            "code": "",
            "auxcode": "EIC",
            "companyName": "EXCARGO",
            "country": "S"
        },
        {
            "name": "Express Line Aircompany",
            "code": "",
            "auxcode": "XPL",
            "companyName": "EXPRESSLINE",
            "country": "United States"
        },
        {
            "name": "Express Net Airlines",
            "code": "",
            "auxcode": "XNA",
            "companyName": "EXPRESSNET",
            "country": "United States"
        },
        {
            "name": "Express One International",
            "code": "EO",
            "auxcode": "LHN",
            "companyName": "LONGHORN",
            "country": "United States"
        },
        {
            "name": "Express Tours",
            "code": "",
            "auxcode": "XTO",
            "companyName": "EXPRESS TOURS",
            "country": "Canada"
        },
        {
            "name": "ExpressJet",
            "code": "XE",
            "auxcode": "BTA",
            "companyName": "JET LINK",
            "country": "United States"
        },
        {
            "name": "Exxavia Limited",
            "code": "",
            "auxcode": "JTM",
            "companyName": "SKYMAN",
            "country": "Ireland"
        },
        {
            "name": "easyJet",
            "code": "U2",
            "auxcode": "EZY",
            "companyName": "EASY",
            "country": "United Kingdom"
        },
        {
            "name": "NetJets",
            "code": "",
            "auxcode": "EJA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "F.S. Air Service",
            "code": "",
            "auxcode": "EYE",
            "companyName": "SOCKEYE",
            "country": "United States"
        },
        {
            "name": "FAI Airservice",
            "code": "",
            "auxcode": "IFA",
            "companyName": "RED ANGLE",
            "country": "Germany"
        },
        {
            "name": "FINFO Flight Inspection Aircraft",
            "code": "",
            "auxcode": "FLC",
            "companyName": "FLIGHT CHECK",
            "country": "United States"
        },
        {
            "name": "FLM Aviation Mohrdieck",
            "code": "",
            "auxcode": "FKI",
            "companyName": "KIEL AIR",
            "country": "Germany"
        },
        {
            "name": "FLTPLAN",
            "code": "",
            "auxcode": "DCM",
            "companyName": "DOT COM",
            "country": "United States"
        },
        {
            "name": "FLowair Aviation",
            "code": "",
            "auxcode": "FLW",
            "companyName": "QUICKFLOW",
            "country": "France"
        },
        {
            "name": "FMG Verkehrsfliegerschule Flughafen PaderborncodeLippstadt",
            "code": "",
            "auxcode": "FMG",
            "companyName": "HUSKY",
            "country": "Germany"
        },
        {
            "name": "FR Aviation",
            "code": "",
            "auxcode": "FRA",
            "companyName": "RUSHTON",
            "country": "United Kingdom"
        },
        {
            "name": "FSB Flugservice & Development",
            "code": "",
            "auxcode": "FSB",
            "companyName": "SEABIRD",
            "country": "Germany"
        },
        {
            "name": "FSH Luftfahrtunternehmen",
            "code": "",
            "auxcode": "LEJ",
            "companyName": "LEIPZIG FAIR",
            "country": "Germany"
        },
        {
            "name": "Fab Air",
            "code": "",
            "auxcode": "FBA",
            "companyName": "FAB AIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Facts Air",
            "code": "",
            "auxcode": "FCS",
            "companyName": "MEXFACTS",
            "country": "Mexico"
        },
        {
            "name": "Fair Aviation",
            "code": "",
            "auxcode": "FAV",
            "companyName": "FAIRAVIA",
            "country": "South Africa"
        },
        {
            "name": "Fair Wind Air Charter",
            "code": "",
            "auxcode": "FWD",
            "companyName": "FAIR WIND",
            "country": "United Arab Emirates"
        },
        {
            "name": "Fairlines",
            "code": "",
            "auxcode": "FLS",
            "companyName": "FAIRLINE",
            "country": "Netherlands"
        },
        {
            "name": "Fairoaks Flight Centre",
            "code": "",
            "auxcode": "FFC",
            "companyName": "FAIROAKS",
            "country": "United Kingdom"
        },
        {
            "name": "Fairways Corporation",
            "code": "",
            "auxcode": "FWY",
            "companyName": "FAIRWAYS",
            "country": "United States"
        },
        {
            "name": "Falcon Air",
            "code": "",
            "auxcode": "FCN",
            "companyName": "FALCON",
            "country": "Sweden"
        },
        {
            "name": "Falcon Air",
            "code": "",
            "auxcode": "FAR",
            "companyName": "FALCAIR",
            "country": "Slovenia"
        },
        {
            "name": "Falcon Air Express",
            "code": "",
            "auxcode": "FAO",
            "companyName": "PANTHER",
            "country": "United States"
        },
        {
            "name": "Falcon Airline",
            "code": "",
            "auxcode": "FAU",
            "companyName": "FALCON AIRLINE",
            "country": "Nigeria"
        },
        {
            "name": "Falcon Aviation",
            "code": "IH",
            "auxcode": "",
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Falcon Aviation Services",
            "code": "",
            "auxcode": "FVS",
            "companyName": "FALCON AVIATION",
            "country": "United Arab Emirates"
        },
        {
            "name": "Falcon Jet Centre",
            "code": "",
            "auxcode": "FJC",
            "companyName": "FALCONJET",
            "country": "United Kingdom"
        },
        {
            "name": "Falwell Aviation",
            "code": "",
            "auxcode": "FAW",
            "companyName": "FALWELL",
            "country": "United States"
        },
        {
            "name": "Far Eastern Air Transport",
            "code": "EF",
            "auxcode": "EFA",
            "companyName": "Far Eastern",
            "country": "Taiwan"
        },
        {
            "name": "Farmingdale State University",
            "code": "",
            "auxcode": "FDL",
            "companyName": "FARMINGDALE STATE",
            "country": "United States"
        },
        {
            "name": "Farnair Hungary",
            "code": "",
            "auxcode": "FAH",
            "companyName": "BLUE STRIP",
            "country": "Hungary"
        },
        {
            "name": "Farnair Netherlands",
            "code": "",
            "auxcode": "FRN",
            "companyName": "FARNED",
            "country": "Netherlands"
        },
        {
            "name": "Farnair Switzerland",
            "code": "",
            "auxcode": "FAT",
            "companyName": "FARNER",
            "country": "Switzerland"
        },
        {
            "name": "Farnas Aviation Services",
            "code": "",
            "auxcode": "RAF",
            "companyName": "FARNAS",
            "country": "Sudan"
        },
        {
            "name": "Faroecopter",
            "code": "",
            "auxcode": "HBL",
            "companyName": "HELIBLUE",
            "country": "Denmark"
        },
        {
            "name": "Faroejet",
            "code": "F6",
            "auxcode": "RCK",
            "companyName": "ROCKROSE",
            "country": "Faroe Islands"
        },
        {
            "name": "Farwest Airlines",
            "code": "",
            "auxcode": "FRW",
            "companyName": "FARWEST",
            "country": "United States"
        },
        {
            "name": "Faso Airways",
            "code": "F3",
            "auxcode": "FSW",
            "companyName": "FASO",
            "country": "Burkina Faso"
        },
        {
            "name": "Fast Helicopters",
            "code": "",
            "auxcode": "FHL",
            "companyName": "FINDON",
            "country": "United Kingdom"
        },
        {
            "name": "Fayban Air Services",
            "code": "",
            "auxcode": "FAY",
            "companyName": "FAYBAN AIR",
            "country": "Nigeria"
        },
        {
            "name": "Fayetteville Flying Service and Scheduled Skyways System",
            "code": "",
            "auxcode": "SKM",
            "companyName": "SKYTEM",
            "country": "United States"
        },
        {
            "name": "Federal Air",
            "code": "",
            "auxcode": "FDR",
            "companyName": "FEDAIR",
            "country": "South Africa"
        },
        {
            "name": "Federal Airlines",
            "code": "",
            "auxcode": "FLL",
            "companyName": "FEDERAL AIRLINES",
            "country": "Sweden"
        },
        {
            "name": "Federal Armed Forces",
            "code": "",
            "auxcode": "DCN",
            "companyName": "DIPLOMATIC CLEARANCE",
            "country": "Germany"
        },
        {
            "name": "Federal Armored Service",
            "code": "",
            "auxcode": "FRM",
            "companyName": "FEDARM",
            "country": "United States"
        },
        {
            "name": "Federal Aviation Administration",
            "code": "",
            "auxcode": "NHK",
            "companyName": "NIGHTHAWK",
            "country": "United States"
        },
        {
            "name": "Federal Express",
            "code": "FX",
            "auxcode": "FDX",
            "companyName": "FEDEX",
            "country": "United States"
        },
        {
            "name": "Feniks Airline",
            "code": "",
            "auxcode": "FNK",
            "companyName": "AURIKA",
            "country": "Kazakhstan"
        },
        {
            "name": "Feria Aviacion",
            "code": "",
            "auxcode": "FER",
            "companyName": "FERIA",
            "country": "Spain"
        },
        {
            "name": "Fika Salaama Airlines",
            "code": "N8",
            "auxcode": "HGK",
            "companyName": "SALAAMA",
            "country": "Uganda"
        },
        {
            "name": "Finalair Congo",
            "code": "4S",
            "auxcode": "FNC",
            "companyName": "FINALAIR CONGO",
            "country": "Republic of the Congo"
        },
        {
            "name": "Financial Airxpress",
            "code": "",
            "auxcode": "FAK",
            "companyName": "FACTS",
            "country": "United States"
        },
        {
            "name": "Fine Airlines",
            "code": "",
            "auxcode": "FBF",
            "companyName": "FINE AIR",
            "country": "United States"
        },
        {
            "name": "Finist'air",
            "code": "",
            "auxcode": "FTR",
            "companyName": "FINISTAIR",
            "country": "France"
        },
        {
            "name": "Finnair",
            "code": "AY",
            "auxcode": "FIN",
            "companyName": "FINNAIR",
            "country": "Finland"
        },
        {
            "name": "Finncomm Airlines",
            "code": "FC",
            "auxcode": "WBA",
            "companyName": "WESTBIRD",
            "country": "Finland"
        },
        {
            "name": "Finnish Air Force",
            "code": "",
            "auxcode": "FNF",
            "companyName": "FINNFORCE",
            "country": "Finland"
        },
        {
            "name": "Firefly",
            "code": "FY",
            "auxcode": "FFM",
            "companyName": "FIREFLY",
            "country": "Malaysia"
        },
        {
            "name": "First Air",
            "code": "7F",
            "auxcode": "FAB",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "First Air Transport",
            "code": "",
            "auxcode": "JRF",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "First Cambodia Airlines",
            "code": "",
            "auxcode": "FCC",
            "companyName": "FIRST CAMBODIA",
            "country": "Cambodia"
        },
        {
            "name": "First Choice Airways",
            "code": "DP",
            "auxcode": "FCA",
            "companyName": "JETSET",
            "country": "United Kingdom"
        },
        {
            "name": "First City Air",
            "code": "",
            "auxcode": "MBL",
            "companyName": "FIRST CITY",
            "country": "United Kingdom"
        },
        {
            "name": "First Flying Squadron",
            "code": "",
            "auxcode": "GGA",
            "companyName": "JAWJA",
            "country": "United States"
        },
        {
            "name": "First Line Air",
            "code": "",
            "auxcode": "FIR",
            "companyName": "FIRSTLINE AIR",
            "country": "Sierra Leone"
        },
        {
            "name": "First Sabre",
            "code": "",
            "auxcode": "FTS",
            "companyName": "FIRST SABRE",
            "country": "Mexico"
        },
        {
            "name": "Fischer Air",
            "code": "8F",
            "auxcode": "FFR",
            "companyName": "FISCHER",
            "country": "Czech Republic"
        },
        {
            "name": "Fischer Air Polska",
            "code": "",
            "auxcode": "FFP",
            "companyName": "FLYING FISH",
            "country": "Poland"
        },
        {
            "name": "Flagship Express Services",
            "code": "",
            "auxcode": "FSX",
            "companyName": "FLAG",
            "country": "United States"
        },
        {
            "name": "Flair Airlines",
            "code": "",
            "auxcode": "FLE",
            "companyName": "FLAIR",
            "country": "Canada"
        },
        {
            "name": "Flamenco Airways",
            "code": "",
            "auxcode": "WAF",
            "companyName": "FLAMENCO",
            "country": "United States"
        },
        {
            "name": "Flamingo Air",
            "code": "",
            "auxcode": "FMR",
            "companyName": "FLAMINGO AIR",
            "country": "United States"
        },
        {
            "name": "Flamingo AircodeLine",
            "code": "",
            "auxcode": "FLN",
            "companyName": "ILIAS",
            "country": "Kazakhstan"
        },
        {
            "name": "Flash Airlines",
            "code": "",
            "auxcode": "FSH",
            "companyName": "FLASH",
            "country": "Egypt"
        },
        {
            "name": "Fleet Requirements Air Direction Unit",
            "code": "",
            "auxcode": "BWY",
            "companyName": "BROADWAY",
            "country": "United Kingdom"
        },
        {
            "name": "Fleetair",
            "code": "",
            "auxcode": "FLR",
            "companyName": "FLEETAIR",
            "country": "South Africa"
        },
        {
            "name": "Flexair",
            "code": "",
            "auxcode": "FXY",
            "companyName": "FLEXY",
            "country": "Netherlands"
        },
        {
            "name": "Flexflight",
            "code": "",
            "auxcode": "FXT",
            "companyName": "",
            "country": "Denmark"
        },
        {
            "name": "Flight Alaska",
            "code": "",
            "auxcode": "TUD",
            "companyName": "TUNDRA",
            "country": "United States"
        },
        {
            "name": "Flight Calibration Service",
            "code": "",
            "auxcode": "FCK",
            "companyName": "NAV CHECKER",
            "country": "Germany"
        },
        {
            "name": "Flight Centre Victoria",
            "code": "",
            "auxcode": "FCV",
            "companyName": "NAVAIR",
            "country": "Canada"
        },
        {
            "name": "Flight Corporation",
            "code": "",
            "auxcode": "FCP",
            "companyName": "FLIGHTCORP",
            "country": "New Zealand"
        },
        {
            "name": "Flight Dispatch Services",
            "code": "",
            "auxcode": "FDP",
            "companyName": "",
            "country": "Poland"
        },
        {
            "name": "Flight Express",
            "code": "",
            "auxcode": "FLX",
            "companyName": "FLIGHT EXPRESS",
            "country": "United States"
        },
        {
            "name": "Flight Inspection Center of the General Administration of Civil Aviation in China",
            "code": "",
            "auxcode": "CFI",
            "companyName": "CHINA JET",
            "country": "China"
        },
        {
            "name": "Flight Inspections and Systems",
            "code": "",
            "auxcode": "LTS",
            "companyName": "SPECAIR",
            "country": "Russia"
        },
        {
            "name": "Flight International",
            "code": "",
            "auxcode": "IVJ",
            "companyName": "INVADER JACK",
            "country": "United States"
        },
        {
            "name": "Flight Line",
            "code": "",
            "auxcode": "MIT",
            "companyName": "MATCO",
            "country": "United States"
        },
        {
            "name": "Flight Ops International",
            "code": "",
            "auxcode": "FOI",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Flight Options",
            "code": "",
            "auxcode": "OPT",
            "companyName": "OPTIONS",
            "country": "United States"
        },
        {
            "name": "Flight Precision Limited",
            "code": "",
            "auxcode": "CLB",
            "companyName": "CALIBRATOR",
            "country": "United Kingdom"
        },
        {
            "name": "Flight Safety Limited",
            "code": "",
            "auxcode": "FSL",
            "companyName": "FLIGHTSAFETY",
            "country": "United Kingdom"
        },
        {
            "name": "Flight Support Sweden",
            "code": "",
            "auxcode": "FSU",
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Flight Trac",
            "code": "",
            "auxcode": "CCK",
            "companyName": "CABLE CHECK",
            "country": "United States"
        },
        {
            "name": "Flight Training Europe",
            "code": "",
            "auxcode": "AYR",
            "companyName": "CYGNET",
            "country": "Spain"
        },
        {
            "name": "Flight West Airlines",
            "code": "",
            "auxcode": "FWQ",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "FlightcodeOps International",
            "code": "",
            "auxcode": "KLO",
            "companyName": "KLONDIKE",
            "country": "Canada"
        },
        {
            "name": "Flightcraft",
            "code": "",
            "auxcode": "CSK",
            "companyName": "CASCADE",
            "country": "United States"
        },
        {
            "name": "Flightexec",
            "code": "",
            "auxcode": "FEX",
            "companyName": "FLIGHTEXEC",
            "country": "Canada"
        },
        {
            "name": "Flightline",
            "code": "B5",
            "auxcode": "FLT",
            "companyName": "FLIGHTLINE",
            "country": "United Kingdom"
        },
        {
            "name": "Flightpass Limited",
            "code": "",
            "auxcode": "FPS",
            "companyName": "FLIGHTPASS",
            "country": "United Kingdom"
        },
        {
            "name": "Flightstar Corporation",
            "code": "",
            "auxcode": "FSR",
            "companyName": "FLIGHTSTAR",
            "country": "United States"
        },
        {
            "name": "Flightworks",
            "code": "",
            "auxcode": "KDZ",
            "companyName": "KUDZU",
            "country": "United States"
        },
        {
            "name": "Flint Aviation Services",
            "code": "",
            "auxcode": "FAZ",
            "companyName": "FLINT AIR",
            "country": "United States"
        },
        {
            "name": "Florida Air",
            "code": "",
            "auxcode": "OJY",
            "companyName": "OHJAY",
            "country": "United States"
        },
        {
            "name": "Florida Coastal Airlines",
            "code": "PA",
            "auxcode": "FCL",
            "companyName": "FLORIDA COASTAL",
            "country": "United States"
        },
        {
            "name": "Florida Department of Agriculture",
            "code": "",
            "auxcode": "FFS",
            "companyName": "FORESTRY",
            "country": "United States"
        },
        {
            "name": "Florida Jet Service",
            "code": "",
            "auxcode": "FJS",
            "companyName": "FLORIDAJET",
            "country": "United States"
        },
        {
            "name": "Florida West International Airways",
            "code": "RF",
            "auxcode": "FWL",
            "companyName": "FLO WEST",
            "country": "United States"
        },
        {
            "name": "Flugdienst Fehlhaber",
            "code": "",
            "auxcode": "FFG",
            "companyName": "WITCHCRAFT",
            "country": "Germany"
        },
        {
            "name": "Flugschule Basel",
            "code": "",
            "auxcode": "FLU",
            "companyName": "YELLOW FLYER",
            "country": "Switzerland"
        },
        {
            "name": "Flugschule Eichenberger",
            "code": "",
            "auxcode": "EZB",
            "companyName": "EICHENBURGER",
            "country": "Switzerland"
        },
        {
            "name": "Flugwerkzeuge Aviation Software",
            "code": "",
            "auxcode": "FWZ",
            "companyName": "",
            "country": "Austria"
        },
        {
            "name": "Fly Air",
            "code": "F2",
            "auxcode": "FLM",
            "companyName": "FLY WORLD",
            "country": "Turkey"
        },
        {
            "name": "Fly CI Limited",
            "code": "",
            "auxcode": "FCT",
            "companyName": "DEALER",
            "country": "United Kingdom"
        },
        {
            "name": "Fly Europa Limited",
            "code": "",
            "auxcode": "FEE",
            "companyName": "FLY EURO",
            "country": "United Kingdom"
        },
        {
            "name": "Fly Excellent",
            "code": "",
            "auxcode": "FXL",
            "companyName": "FLY EXCELLENT",
            "country": "Sweden"
        },
        {
            "name": "Fly International Airways",
            "code": "",
            "auxcode": "NVJ",
            "companyName": "NOUVINTER",
            "country": "Tunisia"
        },
        {
            "name": "Fly Line",
            "code": "",
            "auxcode": "FIL",
            "companyName": "FLYLINE",
            "country": "Spain"
        },
        {
            "name": "Fly Me Sweden",
            "code": "SH",
            "auxcode": "FLY",
            "companyName": "FLYBIRD",
            "country": "Sweden"
        },
        {
            "name": "Fly Wex",
            "code": "",
            "auxcode": "IAD",
            "companyName": "FLYWEX",
            "country": "Italy"
        },
        {
            "name": "AirAsia X",
            "code": "D7",
            "auxcode": "XAX",
            "companyName": "XANADU",
            "country": "Malaysia"
        },
        {
            "name": "FlyLal",
            "code": "TE",
            "auxcode": "LIL",
            "companyName": "LITHUANIA AIR",
            "country": "Lithuania"
        },
        {
            "name": "FlyNordic",
            "code": "LF",
            "auxcode": "NDC",
            "companyName": "NORDIC",
            "country": "Sweden"
        },
        {
            "name": "Flybaboo",
            "code": "F7",
            "auxcode": "BBO",
            "companyName": "BABOO",
            "country": "Switzerland"
        },
        {
            "name": "Flybe",
            "code": "BE",
            "auxcode": "BEE",
            "companyName": "JERSEY",
            "country": "United Kingdom"
        },
        {
            "name": "Flycolumbia",
            "code": "",
            "auxcode": "FCE",
            "companyName": "FLYCOLUMBIA",
            "country": "Spain"
        },
        {
            "name": "Flycom",
            "code": "",
            "auxcode": "FLO",
            "companyName": "FLYCOM",
            "country": "Slovenia"
        },
        {
            "name": "Flygaktiebolaget Gota Vingar",
            "code": "",
            "auxcode": "GVG",
            "companyName": "BLUECRAFT",
            "country": "Sweden"
        },
        {
            "name": "Flyglobespan",
            "code": "B4",
            "auxcode": "GSM",
            "companyName": "GLOBESPAN",
            "country": "United Kingdom"
        },
        {
            "name": "Flygprestanda",
            "code": "",
            "auxcode": "FPA",
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Flygtransporter I Nykoping",
            "code": "",
            "auxcode": "ETS",
            "companyName": "EXTRANS",
            "country": "Sweden"
        },
        {
            "name": "Flyguppdraget Backamo",
            "code": "",
            "auxcode": "INU",
            "companyName": "INSTRUCTOR",
            "country": "Sweden"
        },
        {
            "name": "Flyhy Cargo Airlines",
            "code": "",
            "auxcode": "FYH",
            "companyName": "FLY HIGH",
            "country": "Thailand"
        },
        {
            "name": "Flying Carpet Company",
            "code": "",
            "auxcode": "FCR",
            "companyName": "FLYING CARPET",
            "country": "Lebanon"
        },
        {
            "name": "Flying Service",
            "code": "",
            "auxcode": "FYG",
            "companyName": "FLYING GROUP",
            "country": "Belgium"
        },
        {
            "name": "FlyingcodeResearch Aerogeophysical Center",
            "code": "",
            "auxcode": "FGP",
            "companyName": "FLYING CENTER",
            "country": "Russia"
        },
        {
            "name": "Flylink Express",
            "code": "",
            "auxcode": "FLK",
            "companyName": "FLYLINK",
            "country": "Spain"
        },
        {
            "name": "Flyteam Aviation",
            "code": "",
            "auxcode": "FTM",
            "companyName": "FLYTEAM",
            "country": "United Kingdom"
        },
        {
            "name": "Focus Air",
            "code": "",
            "auxcode": "FKS",
            "companyName": "FOCUS",
            "country": "United States"
        },
        {
            "name": "Fokker",
            "code": "",
            "auxcode": "FOP",
            "companyName": "",
            "country": "Netherlands"
        },
        {
            "name": "Fonnafly",
            "code": "",
            "auxcode": "NOF",
            "companyName": "FONNA",
            "country": "Norway"
        },
        {
            "name": "Ford Motor Company",
            "code": "",
            "auxcode": "FOB",
            "companyName": "FORDAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Formula One Management",
            "code": "",
            "auxcode": "FOR",
            "companyName": "FORMULA",
            "country": "United Kingdom"
        },
        {
            "name": "Forth and Clyde Helicopter Services",
            "code": "",
            "auxcode": "FHS",
            "companyName": "HELISCOT",
            "country": "United Kingdom"
        },
        {
            "name": "Fortunair Canada",
            "code": "",
            "auxcode": "FXC",
            "companyName": "AIR FUTURE",
            "country": "Canada"
        },
        {
            "name": "Forward Air International Airlines",
            "code": "BN",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Foster Aviation",
            "code": "",
            "auxcode": "FSA",
            "companyName": "FOSTERcodeAIR",
            "country": "United States"
        },
        {
            "name": "Foster Yeoman",
            "code": "",
            "auxcode": "JFY",
            "companyName": "YEOMAN",
            "country": "United Kingdom"
        },
        {
            "name": "Fotografia F3",
            "code": "",
            "auxcode": "FTE",
            "companyName": "FOTOGRAFIA",
            "country": "Spain"
        },
        {
            "name": "Four Island Air",
            "code": "",
            "auxcode": "FIA",
            "companyName": "ISLANDAIR",
            "country": "Antigua and Barbuda"
        },
        {
            "name": "Four Star Aviation / Four Star Cargo",
            "code": "HK",
            "auxcode": "FSC",
            "companyName": "FOUR STAR",
            "country": "United States"
        },
        {
            "name": "Four Winds Aviation",
            "code": "",
            "auxcode": "WDS",
            "companyName": "WINDS",
            "country": "United States"
        },
        {
            "name": "Foxair",
            "code": "",
            "auxcode": "FXR",
            "companyName": "WILDFOX",
            "country": "Italy"
        },
        {
            "name": "France Douanes",
            "code": "",
            "auxcode": "FDO",
            "companyName": "FRENCH CUSTOM",
            "country": "France"
        },
        {
            "name": "Free Bird Airlines",
            "code": "",
            "auxcode": "FHY",
            "companyName": "FREE BIRD",
            "country": "Turkey"
        },
        {
            "name": "Freedom Air",
            "code": "",
            "auxcode": "FOM",
            "companyName": "FREE AIR",
            "country": "New Zealand"
        },
        {
            "name": "Freedom Air",
            "code": "FP",
            "auxcode": "FRE",
            "companyName": "FREEDOM",
            "country": "United States"
        },
        {
            "name": "Freedom Air Services",
            "code": "",
            "auxcode": "FFF",
            "companyName": "INTER FREEDOM",
            "country": "Nigeria"
        },
        {
            "name": "Freedom Airlines",
            "code": "",
            "auxcode": "FRL",
            "companyName": "FREEDOM AIR",
            "country": "United States"
        },
        {
            "name": "Freedom Airways",
            "code": "",
            "auxcode": "FAS",
            "companyName": "FREEDOM AIRWAYS",
            "country": "Cyprus"
        },
        {
            "name": "Freeway Air",
            "code": "",
            "auxcode": "FWC",
            "companyName": "FREEWAY",
            "country": "Netherlands"
        },
        {
            "name": "Freight Runners Express",
            "code": "",
            "auxcode": "FRG",
            "companyName": "FREIGHT RUNNERS",
            "country": "United States"
        },
        {
            "name": "Force Aerienne Francaise",
            "code": "",
            "auxcode": "FAF",
            "companyName": "FRENCH AIR FORCE",
            "country": "France"
        },
        {
            "name": "Aviation Legere De L'Armee De Terre",
            "code": "",
            "auxcode": "FMY",
            "companyName": "FRENCH ARMY",
            "country": "France"
        },
        {
            "name": "France Marine Nationale",
            "code": "",
            "auxcode": "FNY",
            "companyName": "FRENCH NAVY",
            "country": "France"
        },
        {
            "name": "Fresh Air",
            "code": "",
            "auxcode": "FRR",
            "companyName": "FRESH AIR",
            "country": "Nigeria"
        },
        {
            "name": "Freshaer",
            "code": "",
            "auxcode": "FAE",
            "companyName": "WILDGOOSE",
            "country": "United Kingdom"
        },
        {
            "name": "Friendship Air Alaska",
            "code": "",
            "auxcode": "FAL",
            "companyName": "FRIENDSHIP",
            "country": "United States"
        },
        {
            "name": "Friendship Airlines",
            "code": "",
            "auxcode": "FLF",
            "companyName": "FRIEND AIR",
            "country": "Uganda"
        },
        {
            "name": "Froggy Corporate Aviation",
            "code": "",
            "auxcode": "FGY",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Frontier Airlines",
            "code": "F9",
            "auxcode": "FFT",
            "companyName": "FRONTIER FLIGHT",
            "country": "United States"
        },
        {
            "name": "Frontier Commuter",
            "code": "",
            "auxcode": "ITR",
            "companyName": "OUT BACK",
            "country": "United States"
        },
        {
            "name": "Frontier Flying Service",
            "code": "2F",
            "auxcode": "FTA",
            "companyName": "FRONTIERcodeAIR",
            "country": "United States"
        },
        {
            "name": "Frontier Guard",
            "code": "",
            "auxcode": "FNG",
            "companyName": "FINNGUARD",
            "country": "Finland"
        },
        {
            "name": "Fujairah Aviation Centre",
            "code": "",
            "auxcode": "FUJ",
            "companyName": "FUJAIRAH",
            "country": "United Arab Emirates"
        },
        {
            "name": "Fujian Airlines",
            "code": "",
            "auxcode": "CFJ",
            "companyName": "FUJIAN",
            "country": "China"
        },
        {
            "name": "Full Express",
            "code": "",
            "auxcode": "GAX",
            "companyName": "GRAND AIRE",
            "country": "United States"
        },
        {
            "name": "Fumigacion Aerea Andaluza",
            "code": "",
            "auxcode": "FAM",
            "companyName": "FAASA",
            "country": "Spain"
        },
        {
            "name": "Fun Flying Thai Air Service",
            "code": "",
            "auxcode": "FFY",
            "companyName": "FUN FLYING",
            "country": "Thailand"
        },
        {
            "name": "Fundació Rego",
            "code": "",
            "auxcode": "ROG",
            "companyName": "REGO",
            "country": "Spain"
        },
        {
            "name": "Funtshi Aviation Service",
            "code": "",
            "auxcode": "FUN",
            "companyName": "FUNTSHI",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Futura Gael",
            "code": "",
            "auxcode": "FGL",
            "companyName": "Applewood",
            "country": "Ireland"
        },
        {
            "name": "Futura International Airways",
            "code": "FH",
            "auxcode": "FUA",
            "companyName": "FUTURA",
            "country": "Spain"
        },
        {
            "name": "G & L Aviation",
            "code": "",
            "auxcode": "GML",
            "companyName": "GEEANDEL",
            "country": "South Africa"
        },
        {
            "name": "G5 Executive",
            "code": "",
            "auxcode": "EXH",
            "companyName": "BATMAN",
            "country": "Germany"
        },
        {
            "name": "GAK/Mitchell Aero",
            "code": "",
            "auxcode": "MTA",
            "companyName": "GAK AVIATION",
            "country": "United States"
        },
        {
            "name": "GATSA",
            "code": "",
            "auxcode": "GGS",
            "companyName": "GATSA",
            "country": "Mexico"
        },
        {
            "name": "GB Airlink",
            "code": "",
            "auxcode": "GBX",
            "companyName": "ISLAND TIGER",
            "country": "United States"
        },
        {
            "name": "GB Airways",
            "code": "GT",
            "auxcode": "GBL",
            "companyName": "GEEBEE AIRWAYS",
            "country": "United Kingdom"
        },
        {
            "name": "GCS Air Service",
            "code": "",
            "auxcode": "GCS",
            "companyName": "GALION",
            "country": "United States"
        },
        {
            "name": "GEC Marconi Avionics",
            "code": "",
            "auxcode": "FFU",
            "companyName": "FERRANTI",
            "country": "United Kingdom"
        },
        {
            "name": "GECAS",
            "code": "",
            "auxcode": "GCC",
            "companyName": "GECAS",
            "country": "Ireland"
        },
        {
            "name": "GENSA",
            "code": "",
            "auxcode": "GEN",
            "companyName": "GENSAcodeBRASIL",
            "country": "Brazil"
        },
        {
            "name": "GETRA",
            "code": "",
            "auxcode": "GET",
            "companyName": "GETRA",
            "country": "Equatorial Guinea"
        },
        {
            "name": "GFW Aviation",
            "code": "",
            "auxcode": "GFW",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "GH Stansted Limited",
            "code": "",
            "auxcode": "GHI",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "GM Helicopters",
            "code": "",
            "auxcode": "GMG",
            "companyName": "GEEcodeEM HELICOPTERS",
            "country": "Latvia"
        },
        {
            "name": "GP Express Airlines",
            "code": "",
            "auxcode": "GPE",
            "companyName": "REGIONAL EXPRESS",
            "country": "United States"
        },
        {
            "name": "GPM Aeroservicio",
            "code": "",
            "auxcode": "GPR",
            "companyName": "GPM AEROSERVICIO",
            "country": "Mexico"
        },
        {
            "name": "GRcodeAvia",
            "code": "",
            "auxcode": "GIB",
            "companyName": "GRAVIA",
            "country": "Guinea"
        },
        {
            "name": "GST Aero Aircompany",
            "code": "",
            "auxcode": "BMK",
            "companyName": "MURAT",
            "country": "Kazakhstan"
        },
        {
            "name": "GTA Air",
            "code": "",
            "auxcode": "GTX",
            "companyName": "BIGcodeDEE",
            "country": "United States"
        },
        {
            "name": "GacodeMa Helicoptere",
            "code": "",
            "auxcode": "GAH",
            "companyName": "GAMHELICO",
            "country": "France"
        },
        {
            "name": "Gabon Express",
            "code": "",
            "auxcode": "GBE",
            "companyName": "GABEX",
            "country": "Gabon"
        },
        {
            "name": "GaboncodeAircodeTransport",
            "code": "",
            "auxcode": "GRT",
            "companyName": "",
            "country": "Gabon"
        },
        {
            "name": "Gacela Air Cargo",
            "code": "",
            "auxcode": "GIG",
            "companyName": "GACELA AIR",
            "country": "Mexico"
        },
        {
            "name": "Gail Force Express",
            "code": "",
            "auxcode": "GFC",
            "companyName": "GAIL FORCE",
            "country": "United States"
        },
        {
            "name": "Gain Jet Aviation",
            "code": "",
            "auxcode": "GNJ",
            "companyName": "HERCULES JET",
            "country": "Greece"
        },
        {
            "name": "Galair International",
            "code": "",
            "auxcode": "SWF",
            "companyName": "GALAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Galaircervis",
            "code": "",
            "auxcode": "GLS",
            "companyName": "GALS",
            "country": "Ukraine"
        },
        {
            "name": "Galaxy Air",
            "code": "7O",
            "auxcode": "GAL",
            "companyName": "GALAXY",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Galaxy Airlines",
            "code": "",
            "auxcode": "GXY",
            "companyName": "GALAX",
            "country": "Japan"
        },
        {
            "name": "Galena Air Service",
            "code": "",
            "auxcode": "GAS",
            "companyName": "GALENA AIR SERVICE",
            "country": "United States"
        },
        {
            "name": "Galileo International",
            "code": "1G",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Gama Aviation",
            "code": "",
            "auxcode": "GMA",
            "companyName": "GAMA",
            "country": "United Kingdom"
        },
        {
            "name": "Gambia International Airlines",
            "code": "GC",
            "auxcode": "GNR",
            "companyName": "GAMBIA INTERNATIONAL",
            "country": "Gambia"
        },
        {
            "name": "Gambia New Millennium Air",
            "code": "",
            "auxcode": "NML",
            "companyName": "NEWMILL",
            "country": "Gambia"
        },
        {
            "name": "Gamisa Aviacion",
            "code": "",
            "auxcode": "GMJ",
            "companyName": "GAMISA",
            "country": "Spain"
        },
        {
            "name": "Gandalf Airlines",
            "code": "G7",
            "auxcode": "GNF",
            "companyName": "Gandalf",
            "country": "Italy"
        },
        {
            "name": "Gander Aviation",
            "code": "",
            "auxcode": "GAN",
            "companyName": "GANAIR",
            "country": "Canada"
        },
        {
            "name": "Garden State Airlines",
            "code": "",
            "auxcode": "GSA",
            "companyName": "GARDEN STATE",
            "country": "United States"
        },
        {
            "name": "Garrison Aviation",
            "code": "",
            "auxcode": "AHM",
            "companyName": "AIR HURON",
            "country": "Canada"
        },
        {
            "name": "Garuda Indonesia",
            "code": "GA",
            "auxcode": "GIA",
            "companyName": "INDONESIA",
            "country": "Indonesia"
        },
        {
            "name": "Gatari Hutama Air Services",
            "code": "",
            "auxcode": "GHS",
            "companyName": "GATARI",
            "country": "Indonesia"
        },
        {
            "name": "Gauteng Air Cargo",
            "code": "",
            "auxcode": "EGO",
            "companyName": "GAUTENG",
            "country": "South Africa"
        },
        {
            "name": "Gavina",
            "code": "",
            "auxcode": "GVN",
            "companyName": "GAVINA",
            "country": "Spain"
        },
        {
            "name": "Gazpromavia",
            "code": "4G",
            "auxcode": "GZP",
            "companyName": "GAZPROMAVIA",
            "country": "Russia"
        },
        {
            "name": "Geesair",
            "code": "",
            "auxcode": "GEE",
            "companyName": "GEESAIR",
            "country": "Canada"
        },
        {
            "name": "Gemini Air Cargo",
            "code": "GR",
            "auxcode": "GCO",
            "companyName": "GEMINI",
            "country": "United States"
        },
        {
            "name": "Gendall Air",
            "code": "",
            "auxcode": "GAB",
            "companyName": "GENDALL",
            "country": "Canada"
        },
        {
            "name": "Gendarmerie Belge",
            "code": "",
            "auxcode": "GDB",
            "companyName": "BELGIAN GENERMERIE",
            "country": "Belgium"
        },
        {
            "name": "Gendarmie Nationale",
            "code": "",
            "auxcode": "FGN",
            "companyName": "FRANCE GENDARME",
            "country": "France"
        },
        {
            "name": "General Aerospace",
            "code": "",
            "auxcode": "SWK",
            "companyName": "SKYWALKER",
            "country": "Canada"
        },
        {
            "name": "General Airways",
            "code": "",
            "auxcode": "GWS",
            "companyName": "GENAIR",
            "country": "South Africa"
        },
        {
            "name": "General Aviation",
            "code": "",
            "auxcode": "GNZ",
            "companyName": "GONZO",
            "country": "Poland"
        },
        {
            "name": "General Aviation Flying Services",
            "code": "",
            "auxcode": "GTH",
            "companyName": "GOTHAM",
            "country": "United States"
        },
        {
            "name": "General Aviation Terminal",
            "code": "",
            "auxcode": "XGA",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "General Motors",
            "code": "",
            "auxcode": "GMC",
            "companyName": "GENERAL MOTORS",
            "country": "United States"
        },
        {
            "name": "Genex",
            "code": "",
            "auxcode": "GNX",
            "companyName": "",
            "country": "Belarus"
        },
        {
            "name": "Geographic Air Surveys",
            "code": "",
            "auxcode": "GSL",
            "companyName": "SURVEYcodeCANADA",
            "country": "Canada"
        },
        {
            "name": "Georgian Airways",
            "code": "A9",
            "auxcode": "TGZ",
            "companyName": "TAMAZI",
            "country": "Georgia"
        },
        {
            "name": "Georgian Aviation Federation",
            "code": "",
            "auxcode": "FGA",
            "companyName": "GEORGIA FED",
            "country": "Georgia"
        },
        {
            "name": "Georgian Cargo Airlines Africa",
            "code": "",
            "auxcode": "GGF",
            "companyName": "GEORGIAN AFRICA",
            "country": "Senegal"
        },
        {
            "name": "Georgian National Airlines",
            "code": "QB",
            "auxcode": "GFG",
            "companyName": "NATIONAL",
            "country": "Georgia"
        },
        {
            "name": "DLR",
            "code": "",
            "auxcode": "GPL",
            "companyName": "GERMAN POLAR",
            "country": "Germany"
        },
        {
            "name": "German Air Force",
            "code": "",
            "auxcode": "GAF",
            "companyName": "GERMAN AIR FORCE",
            "country": "Germany"
        },
        {
            "name": "German Army",
            "code": "",
            "auxcode": "GAM",
            "companyName": "GERMAN ARMY",
            "country": "Germany"
        },
        {
            "name": "German Navy",
            "code": "",
            "auxcode": "GNY",
            "companyName": "GERMAN NAVY",
            "country": "Germany"
        },
        {
            "name": "German Sky Airlines",
            "code": "",
            "auxcode": "GHY",
            "companyName": "GERMAN SKY",
            "country": "Germany"
        },
        {
            "name": "Germania",
            "code": "ST",
            "auxcode": "GMI",
            "companyName": "GERMANIA",
            "country": "Germany"
        },
        {
            "name": "Germanwings",
            "code": "4U",
            "auxcode": "GWI",
            "companyName": "GERMAN WINGS",
            "country": "Germany"
        },
        {
            "name": "Gerry's Dnata",
            "code": "",
            "auxcode": "GDN",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Gesekkschaft Fur Flugzieldarstellung",
            "code": "",
            "auxcode": "GFD",
            "companyName": "KITE",
            "country": "Germany"
        },
        {
            "name": "Gestair",
            "code": "GP",
            "auxcode": "GES",
            "companyName": "GESTAIR",
            "country": "Spain"
        },
        {
            "name": "Gestar",
            "code": "",
            "auxcode": "GTR",
            "companyName": "STAR GESTAR",
            "country": "Chile"
        },
        {
            "name": "Gestion Aerea Ajecutiva",
            "code": "",
            "auxcode": "GJT",
            "companyName": "BANJET",
            "country": "Spain"
        },
        {
            "name": "Ghadames Air Transport",
            "code": "",
            "auxcode": "GHT",
            "companyName": "",
            "country": "Libya"
        },
        {
            "name": "Ghana Airways",
            "code": "",
            "auxcode": "GHA",
            "companyName": "GHANA",
            "country": "Ghana"
        },
        {
            "name": "Ghana International Airlines",
            "code": "G0",
            "auxcode": "GHB",
            "companyName": "GHANA AIRLINES",
            "country": "Ghana"
        },
        {
            "name": "Gibson Aviation",
            "code": "",
            "auxcode": "NTC",
            "companyName": "NIGHT CHASE",
            "country": "United States"
        },
        {
            "name": "Global Air Charter",
            "code": "",
            "auxcode": "RPS",
            "companyName": "RESPONSE",
            "country": "United States"
        },
        {
            "name": "Global Air Operations",
            "code": "",
            "auxcode": "GAG",
            "companyName": "GLOBAL AIRGROUP",
            "country": "Comoros"
        },
        {
            "name": "Global Air Services Nigeria",
            "code": "",
            "auxcode": "GBS",
            "companyName": "GLOBAL SERVE",
            "country": "Nigeria"
        },
        {
            "name": "Global Aircargo",
            "code": "",
            "auxcode": "GLC",
            "companyName": "",
            "country": "Bahrain"
        },
        {
            "name": "Global Airways",
            "code": "",
            "auxcode": "BSP",
            "companyName": "",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Global Aviation Operations",
            "code": "",
            "auxcode": "GBB",
            "companyName": "GLOBE",
            "country": "South Africa"
        },
        {
            "name": "Global Aviation and Services Group",
            "code": "",
            "auxcode": "GAK",
            "companyName": "AVIAGROUP",
            "country": "Libya"
        },
        {
            "name": "Global Georgian Airways",
            "code": "",
            "auxcode": "GGZ",
            "companyName": "GLOBAL GEORGIAN",
            "country": "Georgia"
        },
        {
            "name": "Global Jet Austria",
            "code": "",
            "auxcode": "GLJ",
            "companyName": "GLOBAL AUSTRIA",
            "country": "Austria"
        },
        {
            "name": "Global Jet Corporation",
            "code": "",
            "auxcode": "NSM",
            "companyName": "THUNDERCLOUD",
            "country": "United States"
        },
        {
            "name": "Global Jet Luxembourg",
            "code": "",
            "auxcode": "SVW",
            "companyName": "SILVER ARROWS",
            "country": "Luxembourg"
        },
        {
            "name": "Global Sky Aircharter",
            "code": "",
            "auxcode": "GSK",
            "companyName": "GLOBAL SKY",
            "country": "United States"
        },
        {
            "name": "Global Supply Systems",
            "code": "",
            "auxcode": "GSS",
            "companyName": "JET LIFT",
            "country": "United Kingdom"
        },
        {
            "name": "Global System",
            "code": "",
            "auxcode": "XGS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Global Weather Dynamics",
            "code": "",
            "auxcode": "XGW",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Global Wings",
            "code": "",
            "auxcode": "GLW",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Globe Jet",
            "code": "",
            "auxcode": "GJA",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "Go Air",
            "code": "G8",
            "auxcode": "GOW",
            "companyName": "GOAIR",
            "country": "India"
        },
        {
            "name": "Go One Airways",
            "code": "GK",
            "auxcode": "",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "GoJet Airlines",
            "code": "G7",
            "auxcode": "GJS",
            "companyName": "GATEWAY",
            "country": "United States"
        },
        {
            "name": "Gobierno De Guinea Ecuatorial",
            "code": "",
            "auxcode": "GGE",
            "companyName": "",
            "country": "Equatorial Guinea"
        },
        {
            "name": "GofcodeAir",
            "code": "",
            "auxcode": "GOF",
            "companyName": "GOFcodeAIR",
            "country": "Mexico"
        },
        {
            "name": "Gofir",
            "code": "",
            "auxcode": "GOI",
            "companyName": "SWISS HAWK",
            "country": "Switzerland"
        },
        {
            "name": "Gol Transportes Aéreos",
            "code": "G3",
            "auxcode": "GLO",
            "companyName": "GOL TRANSPORTE",
            "country": "Brazil"
        },
        {
            "name": "Gold Belt Air Transport",
            "code": "",
            "auxcode": "GBT",
            "companyName": "GOLD BELT",
            "country": "Canada"
        },
        {
            "name": "GoldAir",
            "code": "",
            "auxcode": "GDA",
            "companyName": "AIR PARTNER",
            "country": "United Kingdom"
        },
        {
            "name": "GoldeckcodeFlug",
            "code": "",
            "auxcode": "GDK",
            "companyName": "GOLDECK FLUG",
            "country": "Austria"
        },
        {
            "name": "Golden Air",
            "code": "DC",
            "auxcode": "GAO",
            "companyName": "GOLDEN",
            "country": "Sweden"
        },
        {
            "name": "Golden Airlines",
            "code": "",
            "auxcode": "GDD",
            "companyName": "GOLDEN AIRLINES",
            "country": "United States"
        },
        {
            "name": "Golden Pacific Airlines",
            "code": "",
            "auxcode": "GPA",
            "companyName": "GOLDEN PAC",
            "country": "United States"
        },
        {
            "name": "Golden Rule Airlines",
            "code": "",
            "auxcode": "GRS",
            "companyName": "GOLDEN RULE",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Golden Star Air Cargo",
            "code": "",
            "auxcode": "GLD",
            "companyName": "GOLDEN STAR",
            "country": "Sudan"
        },
        {
            "name": "Goldfields Air Services",
            "code": "",
            "auxcode": "GOS",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Golfe Air Quebec",
            "code": "",
            "auxcode": "GAQ",
            "companyName": "GOLFAIR",
            "country": "Canada"
        },
        {
            "name": "Goliaf Air",
            "code": "",
            "auxcode": "GLE",
            "companyName": "GOLIAF AIR",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "Gomel Airlines",
            "code": "",
            "auxcode": "GOM",
            "companyName": "GOMEL",
            "country": "Belarus"
        },
        {
            "name": "Goodridge (UK) Limited",
            "code": "",
            "auxcode": "RDR",
            "companyName": "RED STAR",
            "country": "United Kingdom"
        },
        {
            "name": "Gorkha Airlines",
            "code": "G1",
            "auxcode": "IKA",
            "companyName": "GORKHA AIRLINES",
            "country": "Nepal"
        },
        {
            "name": "Gorlitsa Airlines",
            "code": "",
            "auxcode": "GOR",
            "companyName": "GORLITSA",
            "country": "Ukraine"
        },
        {
            "name": "Government Flying Service",
            "code": "",
            "auxcode": "HKG",
            "companyName": "HONGKONG GOVERNMENT",
            "country": "Hong Kong SAR of China"
        },
        {
            "name": "Government of Zambia Communications Flight",
            "code": "",
            "auxcode": "GRZ",
            "companyName": "COM FLIGHT",
            "country": "Zambia"
        },
        {
            "name": "Grampian Flight Centre",
            "code": "",
            "auxcode": "HLD",
            "companyName": "GRANITE",
            "country": "United Kingdom"
        },
        {
            "name": "Granada Aviacion",
            "code": "",
            "auxcode": "GAV",
            "companyName": "GRANAVI",
            "country": "Spain"
        },
        {
            "name": "Grand Aire Express",
            "code": "",
            "auxcode": "GAE",
            "companyName": "GRAND EXPRESS",
            "country": "United States"
        },
        {
            "name": "Grand Airways",
            "code": "",
            "auxcode": "GND",
            "companyName": "GRAND VEGAS",
            "country": "United States"
        },
        {
            "name": "Grand Canyon Airlines",
            "code": "",
            "auxcode": "CVU",
            "companyName": "CANYON VIEW",
            "country": "United States"
        },
        {
            "name": "Grant Aviation",
            "code": "GS",
            "auxcode": "GUN",
            "companyName": "HOOT",
            "country": "United States"
        },
        {
            "name": "Grantex Aviation",
            "code": "",
            "auxcode": "LMK",
            "companyName": "LANDMARK",
            "country": "United Kingdom"
        },
        {
            "name": "Great American Airways",
            "code": "",
            "auxcode": "GRA",
            "companyName": "GREAT AMERICAN",
            "country": "United States"
        },
        {
            "name": "Great Lakes Airlines",
            "code": "ZK",
            "auxcode": "GLA",
            "companyName": "LAKES AIR",
            "country": "United States"
        },
        {
            "name": "Great Lakes Airways (Uganda)",
            "code": "",
            "auxcode": "GLU",
            "companyName": "LAKES CARGO",
            "country": "Uganda"
        },
        {
            "name": "Great Plains Airlines",
            "code": "",
            "auxcode": "GRP",
            "companyName": "GREAT PLAINS",
            "country": "United States"
        },
        {
            "name": "Great Wall Airlines",
            "code": "IJ",
            "auxcode": "GWL",
            "companyName": "GREAT WALL",
            "country": "China"
        },
        {
            "name": "Great Western Air",
            "code": "",
            "auxcode": "GWA",
            "companyName": "GcodeW AIR",
            "country": "United States"
        },
        {
            "name": "Hellenic Air Force",
            "code": "",
            "auxcode": "HAF",
            "companyName": "HELLENIC AIR FORCE",
            "country": "Greece"
        },
        {
            "name": "Greek Navy",
            "code": "",
            "auxcode": "HNA",
            "companyName": "HELLENIC NAVY",
            "country": "Greece"
        },
        {
            "name": "Griffin Aviation",
            "code": "",
            "auxcode": "GFF",
            "companyName": "GRIFFIN AIR",
            "country": "Cyprus"
        },
        {
            "name": "Grixona",
            "code": "",
            "auxcode": "GXA",
            "companyName": "GRIXONA",
            "country": "Moldova"
        },
        {
            "name": "Grizodubova Air Company",
            "code": "",
            "auxcode": "GZD",
            "companyName": "GRIZODUBOVA AIR",
            "country": "Russia"
        },
        {
            "name": "Grossmann Air Service",
            "code": "",
            "auxcode": "HTG",
            "companyName": "GROSSMANN",
            "country": "Austria"
        },
        {
            "name": "Grossmann Jet Service",
            "code": "",
            "auxcode": "GSJ",
            "companyName": "GROSSJET",
            "country": "Czech Republic"
        },
        {
            "name": "Ground Handling Service de Mexico",
            "code": "",
            "auxcode": "GHV",
            "companyName": "GROUND HANDLING",
            "country": "Mexico"
        },
        {
            "name": "Grup AircodeMed",
            "code": "",
            "auxcode": "GPM",
            "companyName": "GRUPOMED",
            "country": "Spain"
        },
        {
            "name": "Grupo De Aviacion Ejecutiva",
            "code": "",
            "auxcode": "EJC",
            "companyName": "GRUPOEJECUTIVA",
            "country": "Mexico"
        },
        {
            "name": "Grupo TACA",
            "code": "TA",
            "auxcode": "TAT",
            "companyName": "TACAcodeCOSTARICA",
            "country": "Costa Rica"
        },
        {
            "name": "Grupo Vuelos Mediterraneo",
            "code": "",
            "auxcode": "VMM",
            "companyName": "VUELOS MED",
            "country": "Spain"
        },
        {
            "name": "Grupoaereo Monterrey",
            "code": "",
            "auxcode": "GMT",
            "companyName": "GRUPOMONTERREY",
            "country": "Mexico"
        },
        {
            "name": "Guard Systems",
            "code": "",
            "auxcode": "GSY",
            "companyName": "GUARD AIR",
            "country": "Norway"
        },
        {
            "name": "Guine Bissaur Airlines",
            "code": "G6",
            "auxcode": "BSR",
            "companyName": "BISSAU AIRLINES",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Guinea Airways",
            "code": "",
            "auxcode": "GIJ",
            "companyName": "GUINEA AIRWAYS",
            "country": "Guinea"
        },
        {
            "name": "Guinea Cargo",
            "code": "",
            "auxcode": "GNC",
            "companyName": "GUINEA CARGO",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Guinee Airlines",
            "code": "J9",
            "auxcode": "GIF",
            "companyName": "GUINEE AIRLINES",
            "country": "Guinea"
        },
        {
            "name": "Guinea Ecuatorial Airlines",
            "code": "",
            "auxcode": "GEA",
            "companyName": "GEASA",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Guinee Paramount Airlines",
            "code": "",
            "auxcode": "GIQ",
            "companyName": "GUIPAR",
            "country": "Guinea"
        },
        {
            "name": "Guizhou Airlines",
            "code": "",
            "auxcode": "CGH",
            "companyName": "GUIZHOU",
            "country": "China"
        },
        {
            "name": "Guja",
            "code": "",
            "auxcode": "GUS",
            "companyName": "GUJA",
            "country": "Mexico"
        },
        {
            "name": "Gujarat Airways",
            "code": "G8",
            "auxcode": "GUJ",
            "companyName": "GUJARATAIR",
            "country": "India"
        },
        {
            "name": "Gulf & Caribbean Cargo / Contract Air Cargo",
            "code": "",
            "auxcode": "TSU",
            "companyName": "TRANSAUTO",
            "country": "United States"
        },
        {
            "name": "Gulf African Airlines code Gambia",
            "code": "",
            "auxcode": "GUF",
            "companyName": "GULF AFRICAN",
            "country": ""
        },
        {
            "name": "Gulf Air",
            "code": "",
            "auxcode": "GFA",
            "companyName": "GULF AIR",
            "country": "Oman"
        },
        {
            "name": "Gulf Air Bahrain",
            "code": "GF",
            "auxcode": "GBA",
            "companyName": "GULF BAHRAIN",
            "country": "Bahrain"
        },
        {
            "name": "Gulf Air Inc",
            "code": "",
            "auxcode": "GAT",
            "companyName": "GULF TRANS",
            "country": "United States"
        },
        {
            "name": "Gulf Central Airlines",
            "code": "",
            "auxcode": "GCN",
            "companyName": "GULF CENTRAL",
            "country": "United States"
        },
        {
            "name": "Gulf Flite Center",
            "code": "",
            "auxcode": "SFY",
            "companyName": "SKY FLITE",
            "country": "United States"
        },
        {
            "name": "Gulf Pearl Air Lines",
            "code": "",
            "auxcode": "GPC",
            "companyName": "AIR GULFPEARL",
            "country": "Libya"
        },
        {
            "name": "Gulfstream Aerospace",
            "code": "",
            "auxcode": "GLF",
            "companyName": "GULFSTREAM TEST",
            "country": "United States"
        },
        {
            "name": "Gulfstream Airlines",
            "code": "",
            "auxcode": "GFS",
            "companyName": "GULFSTAR",
            "country": "United States"
        },
        {
            "name": "Gulfstream International Airlines",
            "code": "",
            "auxcode": "GFT",
            "companyName": "GULF FLIGHT",
            "country": "United States"
        },
        {
            "name": "Gull Air",
            "code": "",
            "auxcode": "GUL",
            "companyName": "GULLcodeAIR",
            "country": "United States"
        },
        {
            "name": "Gum Air",
            "code": "",
            "auxcode": "GUM",
            "companyName": "GUM AIR",
            "country": "Suriname"
        },
        {
            "name": "Guneydogu Havacilik Isletmesi",
            "code": "",
            "auxcode": "GDH",
            "companyName": "RISING SUN",
            "country": "Turkey"
        },
        {
            "name": "Guyana Airways 2000",
            "code": "GY",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "Gwyn Aviation",
            "code": "",
            "auxcode": "GWN",
            "companyName": "GWYN",
            "country": "United Kingdom"
        },
        {
            "name": "HC Airlines",
            "code": "",
            "auxcode": "HLA",
            "companyName": "HEAVYLIFT",
            "country": "United Kingdom"
        },
        {
            "name": "HPM Investments",
            "code": "",
            "auxcode": "HWD",
            "companyName": "FLITEWISE",
            "country": "United Kingdom"
        },
        {
            "name": "HT Helikoptertransport",
            "code": "",
            "auxcode": "KTR",
            "companyName": "COPTER TRANS",
            "country": "Sweden"
        },
        {
            "name": "HTA Helicopteros",
            "code": "",
            "auxcode": "AHT",
            "companyName": "HELIAPRA",
            "country": "Portugal"
        },
        {
            "name": "Hadison Aviation",
            "code": "",
            "auxcode": "FMS",
            "companyName": "HADI",
            "country": "United States"
        },
        {
            "name": "Hageland Aviation Services",
            "code": "H6",
            "auxcode": "HAG",
            "companyName": "HAGELAND",
            "country": "United States"
        },
        {
            "name": "Hagondale Limited",
            "code": "",
            "auxcode": "POW",
            "companyName": "AIRNET",
            "country": "United Kingdom"
        },
        {
            "name": "Hahn Air",
            "code": "HR",
            "auxcode": "HHN",
            "companyName": "ROOSTER",
            "country": "Germany"
        },
        {
            "name": "Hainan Airlines",
            "code": "HU",
            "auxcode": "CHH",
            "companyName": "HAINAN",
            "country": "China"
        },
        {
            "name": "Hainan Phoenix Information Systems",
            "code": "1R",
            "auxcode": "",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Haiti Air Freight",
            "code": "",
            "auxcode": "HLS",
            "companyName": "",
            "country": "Haiti"
        },
        {
            "name": "Haiti Ambassador Airlines",
            "code": "2T",
            "auxcode": "HAM",
            "companyName": "",
            "country": "Haiti"
        },
        {
            "name": "Haiti International Air",
            "code": "",
            "auxcode": "HTI",
            "companyName": "HAITI INTERNATIONAL",
            "country": "Haiti"
        },
        {
            "name": "Haiti International Airline",
            "code": "",
            "auxcode": "HRB",
            "companyName": "HAITI AIRLINE",
            "country": "Haiti"
        },
        {
            "name": "Haiti National Airlines (HANA)",
            "code": "",
            "auxcode": "HNR",
            "companyName": "HANAIR",
            "country": "Haiti"
        },
        {
            "name": "Haiti North Airline",
            "code": "",
            "auxcode": "HTN",
            "companyName": "",
            "country": "Haiti"
        },
        {
            "name": "Haiti Trans Air",
            "code": "",
            "auxcode": "HTC",
            "companyName": "HAITI TRANSAIR",
            "country": "Haiti"
        },
        {
            "name": "Haitian Aviation Line",
            "code": "",
            "auxcode": "HBC",
            "companyName": "HALISA",
            "country": "Haiti"
        },
        {
            "name": "Hajvairy Airlines",
            "code": "",
            "auxcode": "HAJ",
            "companyName": "HAJVAIRY",
            "country": "Pakistan"
        },
        {
            "name": "Hak Air",
            "code": "",
            "auxcode": "HKL",
            "companyName": "HAK AIRLINE",
            "country": "Nigeria"
        },
        {
            "name": "Hala Air",
            "code": "",
            "auxcode": "HLH",
            "companyName": "HALA AIR",
            "country": "Sudan"
        },
        {
            "name": "Halcyon Air/Bissau Airways",
            "code": "",
            "auxcode": "HCN",
            "companyName": "HALCYON",
            "country": "GuineacodeBissau"
        },
        {
            "name": "Hamburg International",
            "code": "4R",
            "auxcode": "HHI",
            "companyName": "HAMBURG JET",
            "country": "Germany"
        },
        {
            "name": "Hamlin Jet",
            "code": "",
            "auxcode": "HJL",
            "companyName": "BIZJET",
            "country": "United Kingdom"
        },
        {
            "name": "Hamra Air",
            "code": "",
            "auxcode": "HMM",
            "companyName": "HAMRA",
            "country": "United Arab Emirates"
        },
        {
            "name": "Hand D Aviation",
            "code": "",
            "auxcode": "WVA",
            "companyName": "WABASH VALLEY",
            "country": "United States"
        },
        {
            "name": "Hangar 8",
            "code": "",
            "auxcode": "HGR",
            "companyName": "HANG",
            "country": "United Kingdom"
        },
        {
            "name": "Hangard Aviation",
            "code": "",
            "auxcode": "HGD",
            "companyName": "HANGARD",
            "country": "Mongolia"
        },
        {
            "name": "Hansung Airlines",
            "code": "",
            "auxcode": "HAN",
            "companyName": "HANSUNG AIR",
            "country": "Republic of Korea"
        },
        {
            "name": "TUIfly",
            "code": "X3",
            "auxcode": "HLX",
            "companyName": "YELLOW CAB",
            "country": "Germany"
        },
        {
            "name": "Hapagfly",
            "code": "HF",
            "auxcode": "HLF",
            "companyName": "HAPAG LLOYD",
            "country": "Germany"
        },
        {
            "name": "Harbor Airlines",
            "code": "HB",
            "auxcode": "HAR",
            "companyName": "HARBOR",
            "country": "United States"
        },
        {
            "name": "Harmony Airways",
            "code": "HQ",
            "auxcode": "HMY",
            "companyName": "HARMONY",
            "country": "Canada"
        },
        {
            "name": "Haughey Air",
            "code": "",
            "auxcode": "NBR",
            "companyName": "NORBROOK",
            "country": "United Kingdom"
        },
        {
            "name": "Haverfordwest Air Charter Services",
            "code": "",
            "auxcode": "PYN",
            "companyName": "POYSTON",
            "country": "United Kingdom"
        },
        {
            "name": "Havilah Air Services",
            "code": "",
            "auxcode": "HAV",
            "companyName": "HAVILAH",
            "country": "Nigeria"
        },
        {
            "name": "Hawaiian Airlines",
            "code": "HA",
            "auxcode": "HAL",
            "companyName": "HAWAIIAN",
            "country": "United States"
        },
        {
            "name": "Hawaiian Pacific Airlines",
            "code": "HP",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Hawk Air",
            "code": "",
            "auxcode": "HKR",
            "companyName": "AIR HAW",
            "country": "Argentina"
        },
        {
            "name": "Hawk De Mexico",
            "code": "",
            "auxcode": "HMX",
            "companyName": "HAWK MEXICO",
            "country": "Mexico"
        },
        {
            "name": "Hawkair",
            "code": "BH",
            "auxcode": "",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Hawkaire",
            "code": "",
            "auxcode": "HKI",
            "companyName": "HAWKEYE",
            "country": "United States"
        },
        {
            "name": "Hazelton Airlines",
            "code": "",
            "auxcode": "HZL",
            "companyName": "HAZELTON",
            "country": "Australia"
        },
        {
            "name": "Heavylift Cargo Airlines",
            "code": "HN",
            "auxcode": "HVY",
            "companyName": "HEAVY CARGO",
            "country": "Australia"
        },
        {
            "name": "Heavylift International",
            "code": "",
            "auxcode": "HVL",
            "companyName": "HEAVYLIFT INTERNATIONAL",
            "country": "United Arab Emirates"
        },
        {
            "name": "Helcopteros De Cataluna",
            "code": "",
            "auxcode": "HDC",
            "companyName": "HELICATALUNA",
            "country": "Spain"
        },
        {
            "name": "Helenair (Barbados)",
            "code": "",
            "auxcode": "HCB",
            "companyName": "HELEN",
            "country": "Barbados"
        },
        {
            "name": "Helenair Corporation",
            "code": "",
            "auxcode": "HCL",
            "companyName": "HELENCORP",
            "country": "Saint Lucia"
        },
        {
            "name": "Helenia Helicopter Service",
            "code": "",
            "auxcode": "HHP",
            "companyName": "HELENIA",
            "country": "Denmark"
        },
        {
            "name": "Heli Air Services",
            "code": "",
            "auxcode": "HLR",
            "companyName": "HELI BULGARIA",
            "country": "Bulgaria"
        },
        {
            "name": "Heli Ambulance Team",
            "code": "",
            "auxcode": "ALJ",
            "companyName": "ALPIN HELI",
            "country": "Austria"
        },
        {
            "name": "Heli Bernina",
            "code": "",
            "auxcode": "HEB",
            "companyName": "HELIBERNINA",
            "country": "Switzerland"
        },
        {
            "name": "Heli France",
            "code": "8H",
            "auxcode": "HFR",
            "companyName": "HELIFRANCE",
            "country": "France"
        },
        {
            "name": "Heli Hungary",
            "code": "",
            "auxcode": "HYH",
            "companyName": "HELIHUNGARY",
            "country": "Hungary"
        },
        {
            "name": "Heli Medwest De Mexico",
            "code": "",
            "auxcode": "HLM",
            "companyName": "HELIMIDWEST",
            "country": "Mexico"
        },
        {
            "name": "Heli Securite",
            "code": "",
            "auxcode": "HLI",
            "companyName": "HELI SAINTcodeTROPEZ",
            "country": "France"
        },
        {
            "name": "Heli Trip",
            "code": "",
            "auxcode": "HTP",
            "companyName": "HELI TRIP",
            "country": "Mexico"
        },
        {
            "name": "Heli Union Heli Prestations",
            "code": "",
            "auxcode": "HLU",
            "companyName": "HELI UNION",
            "country": "France"
        },
        {
            "name": "HelicodeAircodeMonaco",
            "code": "",
            "auxcode": "MCM",
            "companyName": "HELI AIR",
            "country": "Monaco"
        },
        {
            "name": "HelicodeHolland",
            "code": "",
            "auxcode": "HHE",
            "companyName": "HELI HOLLAND",
            "country": "Netherlands"
        },
        {
            "name": "HelicodeIberica",
            "code": "",
            "auxcode": "HRA",
            "companyName": "ERICA",
            "country": "Spain"
        },
        {
            "name": "HelicodeIberica Fotogrametria",
            "code": "",
            "auxcode": "HIF",
            "companyName": "HIFSA",
            "country": "Spain"
        },
        {
            "name": "HelicodeInter Guyane",
            "code": "",
            "auxcode": "HIG",
            "companyName": "INTER GUYANNE",
            "country": "France"
        },
        {
            "name": "HelicodeLink",
            "code": "",
            "auxcode": "HLK",
            "companyName": "HELIcodeLINK",
            "country": "Switzerland"
        },
        {
            "name": "Heliamerica De Mexico",
            "code": "",
            "auxcode": "HMC",
            "companyName": "HELIAMERICA",
            "country": "Mexico"
        },
        {
            "name": "HeliaviacodeTransporte Aereo",
            "code": "",
            "auxcode": "HEA",
            "companyName": "HELIAVIA",
            "country": "Portugal"
        },
        {
            "name": "Heliaviation Limited",
            "code": "",
            "auxcode": "CDY",
            "companyName": "CADDY",
            "country": "United Kingdom"
        },
        {
            "name": "Helibravo Aviacao",
            "code": "",
            "auxcode": "HIB",
            "companyName": "HELIBRAVO",
            "country": "Portugal"
        },
        {
            "name": "Helicap",
            "code": "",
            "auxcode": "HLC",
            "companyName": "HELICAP",
            "country": "France"
        },
        {
            "name": "Helicentre Coventry",
            "code": "",
            "auxcode": "COV",
            "companyName": "HELICENTRE",
            "country": "United Kingdom"
        },
        {
            "name": "Helicol",
            "code": "",
            "auxcode": "HEL",
            "companyName": "HELICOL",
            "country": "Colombia"
        },
        {
            "name": "Helicopter",
            "code": "",
            "auxcode": "HCP",
            "companyName": "HELI CZECH",
            "country": "Czech Republic"
        },
        {
            "name": "Helicopter & Aviation Services",
            "code": "",
            "auxcode": "JKY",
            "companyName": "JOCKEY",
            "country": "United Kingdom"
        },
        {
            "name": "Helicopter Training & Hire",
            "code": "",
            "auxcode": "MVK",
            "companyName": "MAVRIK",
            "country": "United Kingdom"
        },
        {
            "name": "Helicopteros Aero Personal",
            "code": "",
            "auxcode": "HAP",
            "companyName": "HELIPERSONAL",
            "country": "Mexico"
        },
        {
            "name": "Helicopteros Agroforestal",
            "code": "",
            "auxcode": "HAA",
            "companyName": "AGROFORESTAL",
            "country": "Chile"
        },
        {
            "name": "Helicopteros Internacionales",
            "code": "",
            "auxcode": "HNT",
            "companyName": "HELICOP INTER",
            "country": "Mexico"
        },
        {
            "name": "Helicopteros Y Vehiculos Nacionales Aereos",
            "code": "",
            "auxcode": "HEN",
            "companyName": "HELINAC",
            "country": "Mexico"
        },
        {
            "name": "Helicsa",
            "code": "",
            "auxcode": "HHH",
            "companyName": "HELICSA",
            "country": "Spain"
        },
        {
            "name": "Helijet",
            "code": "JB",
            "auxcode": "JBA",
            "companyName": "HELIJET",
            "country": "Canada"
        },
        {
            "name": "Helikopterdrift",
            "code": "",
            "auxcode": "HDR",
            "companyName": "HELIDRIFT",
            "country": "Norway"
        },
        {
            "name": "Helikopterservice Euro Air",
            "code": "",
            "auxcode": "SCO",
            "companyName": "SWEDCOPTER",
            "country": "Sweden"
        },
        {
            "name": "Heliocean",
            "code": "",
            "auxcode": "OCE",
            "companyName": "HELIOCEAN",
            "country": "France"
        },
        {
            "name": "Helios Airways",
            "code": "ZU",
            "auxcode": "HCY",
            "companyName": "HELIOS",
            "country": "Cyprus"
        },
        {
            "name": "Helipistas",
            "code": "",
            "auxcode": "HLP",
            "companyName": "HELIPISTAS",
            "country": "Spain"
        },
        {
            "name": "Heliportugal",
            "code": "",
            "auxcode": "HPL",
            "companyName": "HELIPORTUGAL",
            "country": "Portugal"
        },
        {
            "name": "Heliservicio Campeche",
            "code": "",
            "auxcode": "HEC",
            "companyName": "HELICAMPECHE",
            "country": "Mexico"
        },
        {
            "name": "Helisul",
            "code": "",
            "auxcode": "HSU",
            "companyName": "HELIS",
            "country": "Portugal"
        },
        {
            "name": "Heliswiss",
            "code": "",
            "auxcode": "HSI",
            "companyName": "HELISWISS",
            "country": "Switzerland"
        },
        {
            "name": "Helitafe",
            "code": "",
            "auxcode": "HLT",
            "companyName": "HELITAFE",
            "country": "Mexico"
        },
        {
            "name": "Helitalia",
            "code": "",
            "auxcode": "HIT",
            "companyName": "HELITALIA",
            "country": "Italy"
        },
        {
            "name": "Helitaxi Ofavi",
            "code": "",
            "auxcode": "OFA",
            "companyName": "OFAVI",
            "country": "Mexico"
        },
        {
            "name": "Helitrans",
            "code": "",
            "auxcode": "HTA",
            "companyName": "SCANBIRD",
            "country": "Norway"
        },
        {
            "name": "Helitrans Air Service",
            "code": "",
            "auxcode": "HTS",
            "companyName": "HELITRANS",
            "country": "United States"
        },
        {
            "name": "Heliworks",
            "code": "",
            "auxcode": "HLW",
            "companyName": "HELIWORKS",
            "country": "Chile"
        },
        {
            "name": "Hellas Jet",
            "code": "T4",
            "auxcode": "HEJ",
            "companyName": "HELLAS JET",
            "country": "Greece"
        },
        {
            "name": "Hello",
            "code": "HW",
            "auxcode": "FHE",
            "companyName": "FLYHELLO",
            "country": "Switzerland"
        },
        {
            "name": "Helog",
            "code": "",
            "auxcode": "HLG",
            "companyName": "HELOG",
            "country": "Switzerland"
        },
        {
            "name": "Helvetic Airways",
            "code": "2L",
            "auxcode": "OAW",
            "companyName": "HELVETIC",
            "country": "Switzerland"
        },
        {
            "name": "Hemus Air",
            "code": "DU",
            "auxcode": "HMS",
            "companyName": "HEMUS AIR",
            "country": "Bulgaria"
        },
        {
            "name": "Henebury Aviation",
            "code": "",
            "auxcode": "HAC",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Heritiage Flight (Valley Air Services)",
            "code": "",
            "auxcode": "SSH",
            "companyName": "SNOWSHOE",
            "country": "United States"
        },
        {
            "name": "Herman's Markair Express",
            "code": "",
            "auxcode": "MRX",
            "companyName": "SPEEDMARK",
            "country": "United States"
        },
        {
            "name": "Herritage Aviation Developments",
            "code": "",
            "auxcode": "HED",
            "companyName": "FLAPJACK",
            "country": "United Kingdom"
        },
        {
            "name": "Hewa Bora Airways",
            "code": "EO",
            "auxcode": "ALX",
            "companyName": "ALLCONGO",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Hex'Air",
            "code": "UD",
            "auxcode": "HER",
            "companyName": "HEX AIRLINE",
            "country": "France"
        },
        {
            "name": "HicodeJet Helicopter Services",
            "code": "",
            "auxcode": "HHS",
            "companyName": "HIJET",
            "country": "Suriname"
        },
        {
            "name": "Hi Fly",
            "code": "5K",
            "auxcode": "HFY",
            "companyName": "SKY FLYER",
            "country": "Portugal"
        },
        {
            "name": "HighcodeLine Airways",
            "code": "",
            "auxcode": "HLB",
            "companyName": "HIGHcodeLINE",
            "country": "Canada"
        },
        {
            "name": "Highland Airways",
            "code": "",
            "auxcode": "HWY",
            "companyName": "HIWAY",
            "country": "United Kingdom"
        },
        {
            "name": "Hispaniola Airways",
            "code": "",
            "auxcode": "HIS",
            "companyName": "HISPANIOLA",
            "country": "Dominican Republic"
        },
        {
            "name": "Hogan Air",
            "code": "",
            "auxcode": "HGA",
            "companyName": "HOGAN AIR",
            "country": "United States"
        },
        {
            "name": "Hokkaido Air System",
            "code": "",
            "auxcode": "NTH",
            "companyName": "NORTH AIR",
            "country": "Japan"
        },
        {
            "name": "Hokkaido International Airlines",
            "code": "HD",
            "auxcode": "ADO",
            "companyName": "AIR DO",
            "country": "Japan"
        },
        {
            "name": "HokurikicodeKoukuu Company",
            "code": "",
            "auxcode": "ABH",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Hola Airlines",
            "code": "H5",
            "auxcode": "HOA",
            "companyName": "HOLA",
            "country": "Spain"
        },
        {
            "name": "Holding International Group",
            "code": "",
            "auxcode": "HIN",
            "companyName": "HOLDING GROUP",
            "country": "Mexico"
        },
        {
            "name": "Holiday Airlines",
            "code": "",
            "auxcode": "HOL",
            "companyName": "HOLIDAY",
            "country": "United States"
        },
        {
            "name": "Holstenair Lubeck",
            "code": "",
            "auxcode": "HTR",
            "companyName": "HOLSTEN",
            "country": "Germany"
        },
        {
            "name": "Homac Aviation",
            "code": "",
            "auxcode": "HMV",
            "companyName": "HOMAC",
            "country": "Luxembourg"
        },
        {
            "name": "Honduras Airlines",
            "code": "",
            "auxcode": "HAS",
            "companyName": "HONDURAS AIR",
            "country": "Honduras"
        },
        {
            "name": "Hong Kong Airlines",
            "code": "HX",
            "auxcode": "CRK",
            "companyName": "BAUHINIA",
            "country": "Hong Kong SAR of China"
        },
        {
            "name": "Hong Kong Express Airways",
            "code": "UO",
            "auxcode": "HKE",
            "companyName": "HONGKONG SHUTTLE",
            "country": "Hong Kong SAR of China"
        },
        {
            "name": "Honiara Cargo Express",
            "code": "",
            "auxcode": "HEX",
            "companyName": "HONIARA CARGO",
            "country": "Solomon Islands"
        },
        {
            "name": "HopcodeAcodeJet",
            "code": "",
            "auxcode": "HPJ",
            "companyName": "HOPAcodeJET",
            "country": "United States"
        },
        {
            "name": "Hope Air",
            "code": "HH",
            "auxcode": "",
            "companyName": "HOPE AIR",
            "country": "Canada"
        },
        {
            "name": "Horizon Air",
            "code": "QX",
            "auxcode": "QXE",
            "companyName": "HORIZON AIR",
            "country": "United States"
        },
        {
            "name": "Horizon Air Service",
            "code": "",
            "auxcode": "KOK",
            "companyName": "KOKO",
            "country": "United States"
        },
        {
            "name": "Horizon Air for Transport and Training",
            "code": "",
            "auxcode": "HSM",
            "companyName": "ALOFUKAIR",
            "country": "Libya"
        },
        {
            "name": "Horizon AircodeTaxi",
            "code": "",
            "auxcode": "HOR",
            "companyName": "HORIZON",
            "country": "Switzerland"
        },
        {
            "name": "Horizon Airlines",
            "code": "BN",
            "auxcode": "HZA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Horizon Plus",
            "code": "",
            "auxcode": "HPS",
            "companyName": "HORIZON PLUS",
            "country": "Bangladesh"
        },
        {
            "name": "Horizons Unlimited",
            "code": "",
            "auxcode": "HUD",
            "companyName": "HUD",
            "country": "United States"
        },
        {
            "name": "Horizontes Aereos",
            "code": "",
            "auxcode": "HOZ",
            "companyName": "HORIZONTES AEREOS",
            "country": "Mexico"
        },
        {
            "name": "Hoteles Dinamicos",
            "code": "",
            "auxcode": "HDI",
            "companyName": "DINAMICOS",
            "country": "Mexico"
        },
        {
            "name": "Houston Helicopters",
            "code": "",
            "auxcode": "HHO",
            "companyName": "HOUSTON HELI",
            "country": "United States"
        },
        {
            "name": "Houston Jet Services",
            "code": "",
            "auxcode": "GGV",
            "companyName": "GREGG AIR",
            "country": "Austria"
        },
        {
            "name": "HozucodeAvia",
            "code": "",
            "auxcode": "OZU",
            "companyName": "HOZAVIA",
            "country": "Kazakhstan"
        },
        {
            "name": "Hub Airlines",
            "code": "",
            "auxcode": "HUB",
            "companyName": "HUB",
            "country": "United States"
        },
        {
            "name": "Huessler Air Service",
            "code": "",
            "auxcode": "HUS",
            "companyName": "HUESSLER",
            "country": "United States"
        },
        {
            "name": "Hughes Aircraft Company",
            "code": "",
            "auxcode": "GMH",
            "companyName": "HUGHES EXPRESS",
            "country": "United States"
        },
        {
            "name": "Hummingbird Helicopter Service",
            "code": "",
            "auxcode": "WHR",
            "companyName": "WHIRLEYBIRD",
            "country": "United States"
        },
        {
            "name": "Hunair Hungarian Airlines",
            "code": "",
            "auxcode": "HUV",
            "companyName": "SILVER EAGLE",
            "country": "Hungary"
        },
        {
            "name": "Hungarian Air Force",
            "code": "",
            "auxcode": "HUF",
            "companyName": "HUNGARIAN AIRFORCE",
            "country": "Hungary"
        },
        {
            "name": "Hyack Air",
            "code": "",
            "auxcode": "HYA",
            "companyName": "HYACK",
            "country": "Canada"
        },
        {
            "name": "Hydro Air Flight Operations",
            "code": "",
            "auxcode": "HYC",
            "companyName": "HYDRO CARGO",
            "country": "South Africa"
        },
        {
            "name": "HydrocodeQuébec",
            "code": "",
            "auxcode": "HYD",
            "companyName": "HYDRO",
            "country": "Canada"
        },
        {
            "name": "Héli Sécurité Helicopter Airlines",
            "code": "H4",
            "auxcode": "",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "International Air Carrier Association",
            "code": "",
            "auxcode": "ITC",
            "companyName": "",
            "country": "Belgium"
        },
        {
            "name": "IBC Airways",
            "code": "II",
            "auxcode": "CSQ",
            "companyName": "CHASQUI",
            "country": "United States"
        },
        {
            "name": "IBL Aviation",
            "code": "",
            "auxcode": "IBL",
            "companyName": "CATOVAIR",
            "country": "Mauritius"
        },
        {
            "name": "IBM Euroflight Operations",
            "code": "",
            "auxcode": "BBL",
            "companyName": "BLUE",
            "country": "Switzerland"
        },
        {
            "name": "ICAO",
            "code": "",
            "auxcode": "YYY",
            "companyName": "",
            "country": ""
        },
        {
            "name": "ICAR Airlines",
            "code": "C3",
            "auxcode": "",
            "companyName": "",
            "country": "Ukraine"
        },
        {
            "name": "ICC Canada",
            "code": "",
            "auxcode": "CIC",
            "companyName": "AIR TRADER",
            "country": "Canada"
        },
        {
            "name": "IDG Technology Air",
            "code": "",
            "auxcode": "IDG",
            "companyName": "INDIGO",
            "country": "Czech Republic"
        },
        {
            "name": "IFL Group",
            "code": "",
            "auxcode": "IFL",
            "companyName": "EIFEL",
            "country": "United States"
        },
        {
            "name": "II Lione Alato Arl",
            "code": "",
            "auxcode": "RDE",
            "companyName": "FLIGHT RED",
            "country": "United Kingdom"
        },
        {
            "name": "IJM International Jet Management",
            "code": "",
            "auxcode": "IJM",
            "companyName": "JET MANAGEMENT",
            "country": "Austria"
        },
        {
            "name": "IKI International Airlines",
            "code": "",
            "auxcode": "IKK",
            "companyName": "IKIAIR",
            "country": "Japan"
        },
        {
            "name": "IKON FTO",
            "code": "",
            "auxcode": "IKN",
            "companyName": "IKON",
            "country": "Germany"
        },
        {
            "name": "IMP Aviation Services",
            "code": "",
            "auxcode": "BLU",
            "companyName": "BLUENOSE",
            "country": "Canada"
        },
        {
            "name": "IMP Group Aviation Services",
            "code": "",
            "auxcode": "XGG",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "INFINI Travel Information",
            "code": "1F",
            "auxcode": "",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "IPEC Aviation",
            "code": "",
            "auxcode": "IPA",
            "companyName": "IPEC",
            "country": "Australia"
        },
        {
            "name": "IPM Europe",
            "code": "",
            "auxcode": "IPM",
            "companyName": "SHIPEX",
            "country": "United Kingdom"
        },
        {
            "name": "IRS Airlines",
            "code": "",
            "auxcode": "LVB",
            "companyName": "SILVERBIRD",
            "country": "Nigeria"
        },
        {
            "name": "ISD Avia",
            "code": "",
            "auxcode": "ISD",
            "companyName": "ISDAVIA",
            "country": "Ukraine"
        },
        {
            "name": "ITA Software",
            "code": "1U",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "IVV Femida",
            "code": "",
            "auxcode": "FDF",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Iberia Airlines",
            "code": "IB",
            "auxcode": "IBE",
            "companyName": "IBERIA",
            "country": "Spain"
        },
        {
            "name": "Ibertour Servicios Aereos",
            "code": "",
            "auxcode": "IBR",
            "companyName": "IBERTOUR",
            "country": "Spain"
        },
        {
            "name": "Ibertrans Aerea",
            "code": "",
            "auxcode": "IBT",
            "companyName": "IBERTRANS",
            "country": "Spain"
        },
        {
            "name": "Iberworld",
            "code": "TY",
            "auxcode": "IWD",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Ibex Airlines",
            "code": "FW",
            "auxcode": "IBX",
            "companyName": "IBEX",
            "country": "Japan"
        },
        {
            "name": "Ibicenca Air",
            "code": "",
            "auxcode": "IBC",
            "companyName": "IBICENCA",
            "country": "Spain"
        },
        {
            "name": "IbkcodePetra",
            "code": "",
            "auxcode": "AKI",
            "companyName": " ",
            "country": "Sudan"
        },
        {
            "name": "Icar Air",
            "code": "",
            "auxcode": "RAC",
            "companyName": "TUZLA AIR",
            "country": "Bosnia and Herzegovina"
        },
        {
            "name": "Icare Franche Compte",
            "code": "",
            "auxcode": "FRC",
            "companyName": "FRANCHE COMPTE",
            "country": "France"
        },
        {
            "name": "Icaro",
            "code": "",
            "auxcode": "ICD",
            "companyName": "ICARO",
            "country": "Ecuador"
        },
        {
            "name": "Icaro",
            "code": "",
            "auxcode": "ICA",
            "companyName": "ICARFLY",
            "country": "Italy"
        },
        {
            "name": "Icarus",
            "code": "",
            "auxcode": "IUS",
            "companyName": "ICARUS",
            "country": "Italy"
        },
        {
            "name": "Icejet",
            "code": "",
            "auxcode": "ICJ",
            "companyName": "ICEJET",
            "country": "Iceland"
        },
        {
            "name": "Icelandair",
            "code": "FI",
            "auxcode": "ICE",
            "companyName": "ICEAIR",
            "country": "Iceland"
        },
        {
            "name": "Icelandic Coast Guard",
            "code": "",
            "auxcode": "ICG",
            "companyName": "ICELAND COAST",
            "country": "Iceland"
        },
        {
            "name": "Ikaros DK",
            "code": "",
            "auxcode": "IKR",
            "companyName": "IKAROS",
            "country": "Denmark"
        },
        {
            "name": "Il Ciocco International Travel Service",
            "code": "",
            "auxcode": "CIO",
            "companyName": "CIOCCO",
            "country": "Italy"
        },
        {
            "name": "IlcodeAvia",
            "code": "",
            "auxcode": "ILV",
            "companyName": "ILAVIA",
            "country": "Russia"
        },
        {
            "name": "Ildefonso Redriguez",
            "code": "",
            "auxcode": "IDL",
            "companyName": "ILDEFONSO",
            "country": "Mexico"
        },
        {
            "name": "Iliamna Air Taxi",
            "code": "",
            "auxcode": "IAR",
            "companyName": "ILIAMNA AIR",
            "country": "United States"
        },
        {
            "name": "Ilpo Aruba Cargo",
            "code": "",
            "auxcode": "ILP",
            "companyName": "",
            "country": "Aruba"
        },
        {
            "name": "IlyichcodeAvia",
            "code": "",
            "auxcode": "ILL",
            "companyName": "ILYICHAVIA",
            "country": "Ukraine"
        },
        {
            "name": "Imaer",
            "code": "",
            "auxcode": "IMR",
            "companyName": "IMAER",
            "country": "Portugal"
        },
        {
            "name": "Imair Airlines",
            "code": "IK",
            "auxcode": "ITX",
            "companyName": "IMPROTEX",
            "country": "Azerbaijan"
        },
        {
            "name": "Imperial Airways",
            "code": "",
            "auxcode": "PNX",
            "companyName": "PHOENIX",
            "country": "United States"
        },
        {
            "name": "Imtrec Aviation",
            "code": "",
            "auxcode": "IMT",
            "companyName": "IMTREC",
            "country": "Cambodia"
        },
        {
            "name": "Independence Air",
            "code": "DH",
            "auxcode": "IDE",
            "companyName": "INDEPENDENCE AIR",
            "country": "United States"
        },
        {
            "name": "Independent Air Freighters",
            "code": "",
            "auxcode": "IDP",
            "companyName": "INDEPENDENT",
            "country": "Australia"
        },
        {
            "name": "IndiGo Airlines",
            "code": "6E",
            "auxcode": "IGO",
            "companyName": "IFLY",
            "country": "India"
        },
        {
            "name": "India International Airways",
            "code": "",
            "auxcode": "IIL",
            "companyName": "INDIA INTER",
            "country": "India"
        },
        {
            "name": "Indian Air Force",
            "code": "",
            "auxcode": "IFC",
            "companyName": "INDIAN AIRFORCE",
            "country": "India"
        },
        {
            "name": "Indian Airlines",
            "code": "IC",
            "auxcode": "IAC",
            "companyName": "INDAIR",
            "country": "India"
        },
        {
            "name": "Indicator Company",
            "code": "",
            "auxcode": "IDR",
            "companyName": "INDICATOR",
            "country": "Hungary"
        },
        {
            "name": "Indigo",
            "code": "I9",
            "auxcode": "IBU",
            "companyName": "INDIGO BLUE",
            "country": "United States"
        },
        {
            "name": "Indonesia Air Transport",
            "code": "",
            "auxcode": "IDA",
            "companyName": "INTRA",
            "country": "Indonesia"
        },
        {
            "name": "Indonesia AirAsia",
            "code": "QZ",
            "auxcode": "AWQ",
            "companyName": "WAGON AIR",
            "country": "Indonesia"
        },
        {
            "name": "Indonesian Airlines",
            "code": "IO",
            "auxcode": "IAA",
            "companyName": "INDO LINES",
            "country": "Indonesia"
        },
        {
            "name": "Industri Pesawat Terbang Nusantara",
            "code": "",
            "auxcode": "IPN",
            "companyName": "NUSANTARA",
            "country": "Indonesia"
        },
        {
            "name": "Industrias Titan",
            "code": "",
            "auxcode": "ITN",
            "companyName": "TITANLUX",
            "country": "Spain"
        },
        {
            "name": "Infinit Air",
            "code": "",
            "auxcode": "FFI",
            "companyName": "INFINIT",
            "country": "Spain"
        },
        {
            "name": "Inflite The Jet Centre",
            "code": "",
            "auxcode": "INS",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Innotech Aviation",
            "code": "",
            "auxcode": "IVA",
            "companyName": "INNOTECH",
            "country": "Canada"
        },
        {
            "name": "Insel Air International",
            "code": "",
            "auxcode": "INC",
            "companyName": "INSELAIR",
            "country": "Netherlands Antilles"
        },
        {
            "name": "Instituto Cartografico de Cataluna",
            "code": "",
            "auxcode": "ICC",
            "companyName": "CARTO",
            "country": "Spain"
        },
        {
            "name": "Intair",
            "code": "",
            "auxcode": "INT",
            "companyName": "INTAIRCO",
            "country": "Canada"
        },
        {
            "name": "Intal Avia",
            "code": "",
            "auxcode": "INL",
            "companyName": "INTAL AVIA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Intavia Limited",
            "code": "",
            "auxcode": "FFL",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "InteliJet Airways",
            "code": "IJ",
            "auxcode": "IJT",
            "companyName": "INTELIJET",
            "country": "Colombia"
        },
        {
            "name": "Intensive Air",
            "code": "",
            "auxcode": "XRA",
            "companyName": "INTENSIVE",
            "country": "South Africa"
        },
        {
            "name": "Inter Air",
            "code": "",
            "auxcode": "ITW",
            "companyName": "INTER WINGS",
            "country": "Bulgaria"
        },
        {
            "name": "Inter Express",
            "code": "",
            "auxcode": "INX",
            "companyName": "INTERcodeEURO",
            "country": "Turkey"
        },
        {
            "name": "Inter Islands Airlines",
            "code": "H4",
            "auxcode": "IIN",
            "companyName": "",
            "country": "Cape Verde"
        },
        {
            "name": "Inter RCA",
            "code": "",
            "auxcode": "CAR",
            "companyName": "QUEBEC ROMEO",
            "country": "Central African Republic"
        },
        {
            "name": "Inter Tropic Airlines",
            "code": "",
            "auxcode": "NTT",
            "companyName": "INTERcodeTROPIC",
            "country": "Sierra Leone"
        },
        {
            "name": "IntercodeAir",
            "code": "",
            "auxcode": "ITA",
            "companyName": "CAFEX",
            "country": "United States"
        },
        {
            "name": "IntercodeCanadian",
            "code": "",
            "auxcode": "ICN",
            "companyName": "INTERcodeCANADIAN",
            "country": "Canada"
        },
        {
            "name": "IntercodeIsland Air",
            "code": "",
            "auxcode": "UGL",
            "companyName": "UGLY VAN",
            "country": "United States"
        },
        {
            "name": "IntercodeMountain Airways",
            "code": "",
            "auxcode": "IMA",
            "companyName": "INTERcodeMOUNTAIN",
            "country": "United States"
        },
        {
            "name": "IntercodeState Aviation",
            "code": "",
            "auxcode": "ITS",
            "companyName": "INTERcodeSTATE",
            "country": "United States"
        },
        {
            "name": "Interair South Africa",
            "code": "D6",
            "auxcode": "ILN",
            "companyName": "INLINE",
            "country": "South Africa"
        },
        {
            "name": "Interaire",
            "code": "",
            "auxcode": "NTE",
            "companyName": "INTERMEX",
            "country": "Mexico"
        },
        {
            "name": "Interavia Airlines",
            "code": "ZA",
            "auxcode": "SUW",
            "companyName": "ASTAIR",
            "country": "Russia"
        },
        {
            "name": "Interaviatrans",
            "code": "",
            "auxcode": "IVT",
            "companyName": "INTERAVIA",
            "country": "Ukraine"
        },
        {
            "name": "Intercontinental de Aviaci",
            "code": "RS",
            "auxcode": "ICT",
            "companyName": "CONTAVIA",
            "country": "Colombia"
        },
        {
            "name": "Intercopters",
            "code": "",
            "auxcode": "ICP",
            "companyName": "CHOPER",
            "country": "Spain"
        },
        {
            "name": "Interflight",
            "code": "",
            "auxcode": "IFT",
            "companyName": "INTERFLIGHT",
            "country": "United Kingdom"
        },
        {
            "name": "Interflight (Learjet)",
            "code": "",
            "auxcode": "IJT",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Interfly",
            "code": "",
            "auxcode": "RFL",
            "companyName": "INFLY",
            "country": "Italy"
        },
        {
            "name": "Interfreight Forwarding",
            "code": "",
            "auxcode": "IFF",
            "companyName": "INTERFREIGHT",
            "country": "Sudan"
        },
        {
            "name": "Interguide Air",
            "code": "",
            "auxcode": "IGN",
            "companyName": "DIVINE AIR",
            "country": "Nigeria"
        },
        {
            "name": "Interisland Airlines",
            "code": "",
            "auxcode": "ISN",
            "companyName": "TRIcodeBIRD",
            "country": "Philippines"
        },
        {
            "name": "Interisland Airways Limited",
            "code": "",
            "auxcode": "IWY",
            "companyName": "ISLANDWAYS",
            "country": "Turks and Caicos Islands"
        },
        {
            "name": "Interjet",
            "code": "",
            "auxcode": "MTF",
            "companyName": "INTERJET",
            "country": "Italy"
        },
        {
            "name": "Interjet Helicopters",
            "code": "",
            "auxcode": "IHE",
            "companyName": "INTERCOPTER",
            "country": "Greece"
        },
        {
            "name": "Interlink Airlines",
            "code": "ID",
            "auxcode": "ITK",
            "companyName": "INTERLINK",
            "country": "South Africa"
        },
        {
            "name": "International Air Cargo Corporation",
            "code": "",
            "auxcode": "IAK",
            "companyName": "AIR CARGO EGYPT",
            "country": "Egypt"
        },
        {
            "name": "International Air Corporation",
            "code": "",
            "auxcode": "EXX",
            "companyName": "EXPRESS INTERNATIONAL",
            "country": "United States"
        },
        {
            "name": "International Air Service",
            "code": "",
            "auxcode": "IAS",
            "companyName": "STARFLEET",
            "country": "United States"
        },
        {
            "name": "International Air Services",
            "code": "",
            "auxcode": "IAX",
            "companyName": "INTERAIR SERVICES",
            "country": "Liberia"
        },
        {
            "name": "International Business Air",
            "code": "6I",
            "auxcode": "IBZ",
            "companyName": "INTERBIZ",
            "country": "Sweden"
        },
        {
            "name": "International Business Aircraft",
            "code": "",
            "auxcode": "IBY",
            "companyName": "CENTRAL STAGE",
            "country": "United States"
        },
        {
            "name": "International Charter Services",
            "code": "",
            "auxcode": "ICS",
            "companyName": "INTERSERVI",
            "country": "Mexico"
        },
        {
            "name": "International Charter Xpress",
            "code": "",
            "auxcode": "ICX",
            "companyName": "INTEX",
            "country": "United States"
        },
        {
            "name": "International Committee of the Red Cross",
            "code": "",
            "auxcode": "RED",
            "companyName": "RED CROSS",
            "country": "Switzerland"
        },
        {
            "name": "International Company for Transport",
            "code": "",
            "auxcode": "IIG",
            "companyName": " Trade and Public Works",
            "country": "ALDAWLYH AIR"
        },
        {
            "name": "International Flight Training Academy",
            "code": "",
            "auxcode": "IFX",
            "companyName": "IFTA",
            "country": "United States"
        },
        {
            "name": "International Jet Aviation Services",
            "code": "",
            "auxcode": "IJA",
            "companyName": "IcodeJET",
            "country": "United States"
        },
        {
            "name": "International Jet Charter",
            "code": "",
            "auxcode": "HSP",
            "companyName": "HOSPITAL",
            "country": "United States"
        },
        {
            "name": "International SOS WIndhoek",
            "code": "",
            "auxcode": "RSQ",
            "companyName": "SKYMEDIC",
            "country": "Namibia"
        },
        {
            "name": "International Sabilisation Assistance Force",
            "code": "",
            "auxcode": "ISF",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "International Security Assistance Force",
            "code": "",
            "auxcode": "THN",
            "companyName": "ATHENA",
            "country": "Canada"
        },
        {
            "name": "International TranscodeAir",
            "code": "",
            "auxcode": "ITH",
            "companyName": "INTRANS NIGERIA",
            "country": "Nigeria"
        },
        {
            "name": "Interport Corporation",
            "code": "",
            "auxcode": "IPT",
            "companyName": "INTERPORT",
            "country": "United States"
        },
        {
            "name": "Intersky",
            "code": "",
            "auxcode": "IKY",
            "companyName": "GENERAL SKY",
            "country": "Bulgaria"
        },
        {
            "name": "Intersky",
            "code": "3L",
            "auxcode": "ISK",
            "companyName": "INTERSKY",
            "country": "Austria"
        },
        {
            "name": "Interstate Airline",
            "code": "I4",
            "auxcode": "FWA",
            "companyName": "FREEWAYAIR",
            "country": "Netherlands"
        },
        {
            "name": "Intervuelos",
            "code": "",
            "auxcode": "ITU",
            "companyName": "INTERLOS",
            "country": "Mexico"
        },
        {
            "name": "Inversija",
            "code": "",
            "auxcode": "INV",
            "companyName": "INVER",
            "country": "Latvia"
        },
        {
            "name": "Iona National Airways",
            "code": "",
            "auxcode": "IND",
            "companyName": "IONA",
            "country": "Ireland"
        },
        {
            "name": "Iowa Airways",
            "code": "",
            "auxcode": "IOA",
            "companyName": "IOWA AIR",
            "country": "United States"
        },
        {
            "name": "Iran Air",
            "code": "IR",
            "auxcode": "IRA",
            "companyName": "IRANAIR",
            "country": "Iran"
        },
        {
            "name": "Iran Aseman Airlines",
            "code": "EP",
            "auxcode": "IRC",
            "companyName": "",
            "country": "Iran"
        },
        {
            "name": "Iranair Tours",
            "code": "",
            "auxcode": "IRB",
            "companyName": "",
            "country": "Iran"
        },
        {
            "name": "Naft Air Lines",
            "code": "",
            "auxcode": "IRG",
            "companyName": "NAFT",
            "country": "Iran"
        },
        {
            "name": "Iraqi Airways",
            "code": "IA",
            "auxcode": "IAW",
            "companyName": "IRAQI",
            "country": "Iraq"
        },
        {
            "name": "Irbis Air",
            "code": "",
            "auxcode": "BIS",
            "companyName": "IRBIS",
            "country": "Kazakhstan"
        },
        {
            "name": "Irish Air Corps",
            "code": "",
            "auxcode": "IRL",
            "companyName": "IRISH",
            "country": "Ireland"
        },
        {
            "name": "Irish Air Transport",
            "code": "",
            "auxcode": "RDK",
            "companyName": "IRISH TRANS",
            "country": "Ireland"
        },
        {
            "name": "Irish Aviation Authority",
            "code": "",
            "auxcode": "XMR",
            "companyName": "AUTHORITY",
            "country": "Ireland"
        },
        {
            "name": "Irtysh Airlines",
            "code": "",
            "auxcode": "IRT",
            "companyName": "IRTYSH AIRLINES",
            "country": "Uzbekistan"
        },
        {
            "name": "Irving Oil",
            "code": "",
            "auxcode": "XIA",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Irving Oil Transport",
            "code": "",
            "auxcode": "KCE",
            "companyName": "KACEY",
            "country": "Canada"
        },
        {
            "name": "Island Air",
            "code": "",
            "auxcode": "ISI",
            "companyName": "ISLANDMEX",
            "country": "Mexico"
        },
        {
            "name": "Island Air Charters",
            "code": "",
            "auxcode": "ILF",
            "companyName": "ISLAND FLIGHT",
            "country": "United States"
        },
        {
            "name": "Island Air Express",
            "code": "",
            "auxcode": "XYZ",
            "companyName": "RAINBIRD",
            "country": "United States"
        },
        {
            "name": "Island Airlines",
            "code": "IS",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Island Aviation",
            "code": "",
            "auxcode": "SOY",
            "companyName": "SORIANO",
            "country": "Philippines"
        },
        {
            "name": "Island Aviation Services",
            "code": "",
            "auxcode": "DQA",
            "companyName": "",
            "country": "Maldives"
        },
        {
            "name": "Island Aviation and Travel",
            "code": "",
            "auxcode": "IOM",
            "companyName": "ISLE AVIA",
            "country": "United Kingdom"
        },
        {
            "name": "Island Express",
            "code": "2S",
            "auxcode": "SDY",
            "companyName": "SANDY ISLE",
            "country": "United States"
        },
        {
            "name": "Cargo Plus Aviation",
            "code": "8L",
            "auxcode": "CGP",
            "companyName": "",
            "country": "United Arab Emirates"
        },
        {
            "name": "Island Helicopters",
            "code": "",
            "auxcode": "MTP",
            "companyName": "METROCOPTER",
            "country": "United States"
        },
        {
            "name": "Island Link",
            "code": "",
            "auxcode": "ILC",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Islandair Jersey",
            "code": "",
            "auxcode": "IAJ",
            "companyName": "JARLAND",
            "country": "United Kingdom"
        },
        {
            "name": "Islands Nationair",
            "code": "CN",
            "auxcode": "",
            "companyName": "",
            "country": "Papua New Guinea"
        },
        {
            "name": "Icebird Airline",
            "code": "",
            "auxcode": "ICB",
            "companyName": "ICEBIRD",
            "country": "Iceland"
        },
        {
            "name": "Islas Airways",
            "code": "IF",
            "auxcode": "ISW",
            "companyName": "PINTADERA",
            "country": "Spain"
        },
        {
            "name": "Isle Grande Flying School",
            "code": "",
            "auxcode": "IGS",
            "companyName": "ISLA GRANDE",
            "country": "United States"
        },
        {
            "name": "Islena De Inversiones",
            "code": "WC",
            "auxcode": "ISV",
            "companyName": "",
            "country": "Honduras"
        },
        {
            "name": "Isles of Scilly Skybus",
            "code": "",
            "auxcode": "IOS",
            "companyName": "SCILLONIA",
            "country": "United Kingdom"
        },
        {
            "name": "Israel Aircraft Industries",
            "code": "",
            "auxcode": "IAI",
            "companyName": "ISRAEL AIRCRAFT",
            "country": "Israel"
        },
        {
            "name": "Israeli Air Force",
            "code": "",
            "auxcode": "IAF",
            "companyName": "",
            "country": "Israel"
        },
        {
            "name": "Israir",
            "code": "6H",
            "auxcode": "ISR",
            "companyName": "ISRAIR",
            "country": "Israel"
        },
        {
            "name": "Istanbul Airlines",
            "code": "",
            "auxcode": "IST",
            "companyName": "ISTANBUL",
            "country": "Turkey"
        },
        {
            "name": "Itali Airlines",
            "code": "9X",
            "auxcode": "ACL",
            "companyName": "ITALI",
            "country": "Italy"
        },
        {
            "name": "Italy First",
            "code": "",
            "auxcode": "IFS",
            "companyName": "RIVIERA",
            "country": "Italy"
        },
        {
            "name": "Itek Air",
            "code": "GI",
            "auxcode": "IKA",
            "companyName": "ITEKcodeAIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Ivoire Aero Services",
            "code": "",
            "auxcode": "IVS",
            "companyName": "IVOIRE AERO",
            "country": "Ivory Coast"
        },
        {
            "name": "Ivoire Airways",
            "code": "",
            "auxcode": "IVW",
            "companyName": "IVOIRAIRWAYS",
            "country": "Ivory Coast"
        },
        {
            "name": "Ivoire Jet Express",
            "code": "",
            "auxcode": "IJE",
            "companyName": "IVOIRE JET",
            "country": "Ivory Coast"
        },
        {
            "name": "Iwamoto Crane Co Ltd",
            "code": "",
            "auxcode": "OIC",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Ixair",
            "code": "",
            "auxcode": "IXR",
            "companyName": "XcodeBIRD",
            "country": "France"
        },
        {
            "name": "Izair",
            "code": "H9",
            "auxcode": "IZM",
            "companyName": "IZMIR",
            "country": "Turkey"
        },
        {
            "name": "Izhavia",
            "code": "",
            "auxcode": "IZA",
            "companyName": "IZHAVIA",
            "country": "Russia"
        },
        {
            "name": "J C Bamford (Excavators)",
            "code": "",
            "auxcode": "JCB",
            "companyName": "JAYSEEBEE",
            "country": "United Kingdom"
        },
        {
            "name": "J P Hunt Air Carriers",
            "code": "",
            "auxcode": "RFX",
            "companyName": "REFLEX",
            "country": "United States"
        },
        {
            "name": "JcodeAir",
            "code": "",
            "auxcode": "JLJ",
            "companyName": "J AIR",
            "country": "Japan"
        },
        {
            "name": "JAL Express",
            "code": "JC",
            "auxcode": "JEX",
            "companyName": "JANEX",
            "country": "Japan"
        },
        {
            "name": "JALways",
            "code": "JO",
            "auxcode": "JAZ",
            "companyName": "JALWAYS",
            "country": "Japan"
        },
        {
            "name": "JDAviation",
            "code": "",
            "auxcode": "JDA",
            "companyName": "JAY DEE",
            "country": "United Kingdom"
        },
        {
            "name": "JDP Lux",
            "code": "",
            "auxcode": "JDP",
            "companyName": "RED PELICAN",
            "country": "Luxembourg"
        },
        {
            "name": "JHM Cargo Expreso",
            "code": "",
            "auxcode": "JHM",
            "companyName": "",
            "country": "Costa Rica"
        },
        {
            "name": "JM Family Aviation",
            "code": "",
            "auxcode": "TQM",
            "companyName": "TACOMA",
            "country": "United States"
        },
        {
            "name": "JMC Airlines",
            "code": "",
            "auxcode": "JMC",
            "companyName": "JAYEMMSEE",
            "country": "United Kingdom"
        },
        {
            "name": "JSC Transport Automated Information Systems",
            "code": "1M",
            "auxcode": "",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "JS Air",
            "code": "",
            "auxcode": "JSJ",
            "companyName": "JS CHARTER",
            "country": "Pakistan"
        },
        {
            "name": "JS Aviation",
            "code": "",
            "auxcode": "JES",
            "companyName": "JAYcodeESS AVIATION",
            "country": "Mexico"
        },
        {
            "name": "Jackson Air Services",
            "code": "",
            "auxcode": "JCK",
            "companyName": "JACKSON",
            "country": "Canada"
        },
        {
            "name": "Jade Cargo International",
            "code": "",
            "auxcode": "JAE",
            "companyName": "JADE CARGO",
            "country": "China"
        },
        {
            "name": "Jamahiriya Airways",
            "code": "",
            "auxcode": "JAW",
            "companyName": "JAW",
            "country": "Libya"
        },
        {
            "name": "Jambo Africa Airlines",
            "code": "",
            "auxcode": "JMB",
            "companyName": "JAMBOAFRICA",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "JanacodeArka",
            "code": "",
            "auxcode": "JAK",
            "companyName": "YANZAR",
            "country": "Kazakhstan"
        },
        {
            "name": "Janair",
            "code": "",
            "auxcode": "JAX",
            "companyName": "JANAIR",
            "country": "United States"
        },
        {
            "name": "Japan Air Commuter",
            "code": "",
            "auxcode": "JAC",
            "companyName": "COMMUTER",
            "country": "Japan"
        },
        {
            "name": "Japan Aircraft Service",
            "code": "",
            "auxcode": "JSV",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Japan Airlines",
            "code": "JL",
            "auxcode": "JAL",
            "companyName": "JAPANAIR",
            "country": "Japan"
        },
        {
            "name": "Japan Airlines Domestic",
            "code": "JL",
            "auxcode": "JAL",
            "companyName": "JcodeBIRD",
            "country": "Japan"
        },
        {
            "name": "Japan Asia Airways",
            "code": "EG",
            "auxcode": "JAA",
            "companyName": "ASIA",
            "country": "Japan"
        },
        {
            "name": "Japan Transocean Air",
            "code": "NU",
            "auxcode": "JTA",
            "companyName": "JAI OCEAN",
            "country": "Japan"
        },
        {
            "name": "Jat Airways",
            "code": "JU",
            "auxcode": "JAT",
            "companyName": "JAT",
            "country": "Serbia"
        },
        {
            "name": "Jatayu Airlines",
            "code": "VJ",
            "auxcode": "JTY",
            "companyName": "JATAYU",
            "country": "Indonesia"
        },
        {
            "name": "Jazeera Airways",
            "code": "J9",
            "auxcode": "JZR",
            "companyName": "JAZEERA",
            "country": "Kuwait"
        },
        {
            "name": "Jeju Air",
            "code": "7C",
            "auxcode": "JJA",
            "companyName": "JEJU AIR",
            "country": "Republic of Korea"
        },
        {
            "name": "Jenney Beechcraft",
            "code": "",
            "auxcode": "JNY",
            "companyName": "JENAIR",
            "country": "United States"
        },
        {
            "name": "Jeppesen Data Plan",
            "code": "",
            "auxcode": "XLD",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Jeppesen UK",
            "code": "",
            "auxcode": "JPN",
            "companyName": "JETPLAN",
            "country": "United Kingdom"
        },
        {
            "name": "Jet Air",
            "code": "",
            "auxcode": "JEA",
            "companyName": "JETA",
            "country": "Poland"
        },
        {
            "name": "Jet Air Group",
            "code": "",
            "auxcode": "JSI",
            "companyName": "SISTEMA",
            "country": "Russia"
        },
        {
            "name": "Jet Airways",
            "code": "9W",
            "auxcode": "JAI",
            "companyName": "JET AIRWAYS",
            "country": "India"
        },
        {
            "name": "Jet Airways",
            "code": "QJ",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Jet Aspen Air Lines",
            "code": "",
            "auxcode": "JTX",
            "companyName": "JET ASPEN",
            "country": "United States"
        },
        {
            "name": "Jet Aviation",
            "code": "",
            "auxcode": "PJS",
            "companyName": "JETAVIATION",
            "country": "Switzerland"
        },
        {
            "name": "Jet Aviation Business Jets",
            "code": "",
            "auxcode": "BZF",
            "companyName": "BIZFLEET",
            "country": "United States"
        },
        {
            "name": "Jet Center Flight Training",
            "code": "",
            "auxcode": "JCF",
            "companyName": "JET CENTER",
            "country": "Spain"
        },
        {
            "name": "Jet Charter",
            "code": "",
            "auxcode": "JCT",
            "companyName": "JET CHARTER",
            "country": "United States"
        },
        {
            "name": "Jetclub",
            "code": "0J",
            "auxcode": "",
            "companyName": "",
            "country": "Switzerland"
        },
        {
            "name": "Jet Connection",
            "code": "",
            "auxcode": "JCX",
            "companyName": "JET CONNECT",
            "country": "Germany"
        },
        {
            "name": "Jet Courier Service",
            "code": "",
            "auxcode": "DWW",
            "companyName": "DON JUAN",
            "country": "United States"
        },
        {
            "name": "Jet East International",
            "code": "",
            "auxcode": "JED",
            "companyName": "JET EAST",
            "country": "United States"
        },
        {
            "name": "Jet Executive International Charter",
            "code": "",
            "auxcode": "JEI",
            "companyName": "JET EXECUTIVE",
            "country": "Germany"
        },
        {
            "name": "Jet Fighter Flights",
            "code": "",
            "auxcode": "RZA",
            "companyName": "RAZOR",
            "country": "Australia"
        },
        {
            "name": "Jet Freighters",
            "code": "",
            "auxcode": "CFT",
            "companyName": "CASPER FREIGHT",
            "country": "United States"
        },
        {
            "name": "Jet G&D Aviation",
            "code": "",
            "auxcode": "JGD",
            "companyName": "JET GEEcodeANDcodeDEE",
            "country": "Israel"
        },
        {
            "name": "Jet Line International",
            "code": "",
            "auxcode": "MJL",
            "companyName": "MOLDJET",
            "country": "Moldova"
        },
        {
            "name": "Jet Link",
            "code": "",
            "auxcode": "JEK",
            "companyName": "JET OPS",
            "country": "Israel"
        },
        {
            "name": "Jet Link Aviation",
            "code": "",
            "auxcode": "HTL",
            "companyName": "HEARTLAND",
            "country": "United States"
        },
        {
            "name": "Jet Norte",
            "code": "",
            "auxcode": "JNR",
            "companyName": "JET NORTE",
            "country": "Mexico"
        },
        {
            "name": "Jet Rent",
            "code": "",
            "auxcode": "JRN",
            "companyName": "JET RENT",
            "country": "Mexico"
        },
        {
            "name": "Jet Service",
            "code": "",
            "auxcode": "JDI",
            "companyName": "JEDI",
            "country": "Poland"
        },
        {
            "name": "Jetstar Asia Airways",
            "code": "3K",
            "auxcode": "JSA",
            "companyName": "JETSTAR ASIA",
            "country": "Singapore"
        },
        {
            "name": "Jet Stream",
            "code": "",
            "auxcode": "JSM",
            "companyName": "JET STREAM",
            "country": "Moldova"
        },
        {
            "name": "Jet Trans Aviation",
            "code": "",
            "auxcode": "JTC",
            "companyName": "JETRANS",
            "country": "Ghana"
        },
        {
            "name": "Jetcode2000",
            "code": "",
            "auxcode": "JTT",
            "companyName": "MOSCOW JET",
            "country": "Russia"
        },
        {
            "name": "JetcodeOps",
            "code": "",
            "auxcode": "OPS",
            "companyName": "OPScodeJET",
            "country": "United Arab Emirates"
        },
        {
            "name": "Jet2.com",
            "code": "LS",
            "auxcode": "EXS",
            "companyName": "CHANNEX",
            "country": "United Kingdom"
        },
        {
            "name": "Jet4You",
            "code": "8J",
            "auxcode": "JFU",
            "companyName": "ARGAN",
            "country": "Morocco"
        },
        {
            "name": "JetAfrica Swaziland",
            "code": "",
            "auxcode": "OSW",
            "companyName": "BEVO",
            "country": "Swaziland"
        },
        {
            "name": "JetBlue Airways",
            "code": "B6",
            "auxcode": "JBU",
            "companyName": "JETBLUE",
            "country": "United States"
        },
        {
            "name": "JetConnect",
            "code": "",
            "auxcode": "QNZ",
            "companyName": "QANTAS JETCONNECT",
            "country": "New Zealand"
        },
        {
            "name": "JetMagic",
            "code": "",
            "auxcode": "JMG",
            "companyName": "JET MAGIC",
            "country": "Ireland"
        },
        {
            "name": "Jetairfly",
            "code": "JF",
            "auxcode": "JAF",
            "companyName": "BEAUTY",
            "country": "Belgium"
        },
        {
            "name": "Jetall Holdings",
            "code": "",
            "auxcode": "JTL",
            "companyName": "FIREFLY",
            "country": "Canada"
        },
        {
            "name": "Jetalliance",
            "code": "",
            "auxcode": "JAG",
            "companyName": "JETALLIANCE",
            "country": "Austria"
        },
        {
            "name": "Jetclub",
            "code": "0J",
            "auxcode": "JCS",
            "companyName": "JETCLUB",
            "country": "Switzerland"
        },
        {
            "name": "Jetcorp",
            "code": "",
            "auxcode": "UEJ",
            "companyName": "JETCORP",
            "country": "United States"
        },
        {
            "name": "Jetcraft Aviation",
            "code": "",
            "auxcode": "JCC",
            "companyName": "JETCRAFT",
            "country": "Australia"
        },
        {
            "name": "Jetex Aviation",
            "code": "",
            "auxcode": "JXA",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "Jetflite",
            "code": "",
            "auxcode": "JEF",
            "companyName": "JETFLITE",
            "country": "Finland"
        },
        {
            "name": "Jetfly Airlines",
            "code": "",
            "auxcode": "JFL",
            "companyName": "LINEFLYER",
            "country": "Austria"
        },
        {
            "name": "Jetgo International",
            "code": "",
            "auxcode": "JIC",
            "companyName": "JICcodeJET",
            "country": "Thailand"
        },
        {
            "name": "Jetlink Express",
            "code": "",
            "auxcode": "JLX",
            "companyName": "KEN JET",
            "country": "Kenya"
        },
        {
            "name": "Jetlink Holland",
            "code": "",
            "auxcode": "JLH",
            "companyName": "",
            "country": "Netherlands"
        },
        {
            "name": "Jetnova de Aviacion Ejecutiva",
            "code": "",
            "auxcode": "JNV",
            "companyName": "JETNOVA",
            "country": "Spain"
        },
        {
            "name": "Jetpro",
            "code": "",
            "auxcode": "JPO",
            "companyName": "JETPRO",
            "country": "Mexico"
        },
        {
            "name": "Jetran Air",
            "code": "",
            "auxcode": "MDJ",
            "companyName": "JETRAN AIR",
            "country": "Romania"
        },
        {
            "name": "Jetrider International",
            "code": "",
            "auxcode": "JRI",
            "companyName": "JETRIDER",
            "country": "United Kingdom"
        },
        {
            "name": "Jets Ejecutivos",
            "code": "",
            "auxcode": "JEJ",
            "companyName": "MEXJETS",
            "country": "Mexico"
        },
        {
            "name": "Jets Personales",
            "code": "",
            "auxcode": "JEP",
            "companyName": "JET PERSONALES",
            "country": "Spain"
        },
        {
            "name": "Jets Y Servicios Ejecutivos",
            "code": "",
            "auxcode": "JSE",
            "companyName": "SERVIJETS",
            "country": "Mexico"
        },
        {
            "name": "JetsGo",
            "code": "SG",
            "auxcode": "JGO",
            "companyName": "JETSGO",
            "country": "Canada"
        },
        {
            "name": "Jetstar Airways",
            "code": "JQ",
            "auxcode": "JST",
            "companyName": "JETSTAR",
            "country": "Australia"
        },
        {
            "name": "Jetstream Air",
            "code": "",
            "auxcode": "JSH",
            "companyName": "STREAMcodeAIR",
            "country": "Hungary"
        },
        {
            "name": "Jetstream Executive Travel",
            "code": "",
            "auxcode": "JXT",
            "companyName": "VANNIN",
            "country": "United Kingdom"
        },
        {
            "name": "Jett Paqueteria",
            "code": "",
            "auxcode": "JPQ",
            "companyName": "JETT PAQUETERIA",
            "country": "Mexico"
        },
        {
            "name": "Jett8 Airlines Cargo",
            "code": "JX",
            "auxcode": "JEC",
            "companyName": "",
            "country": "Singapore"
        },
        {
            "name": "Jettime",
            "code": "",
            "auxcode": "JTG",
            "companyName": "JETTIME",
            "country": "Denmark"
        },
        {
            "name": "Jettrain Corporation",
            "code": "",
            "auxcode": "JTN",
            "companyName": "JETTRAIN",
            "country": "United States"
        },
        {
            "name": "Jetways of Iowa",
            "code": "",
            "auxcode": "JWY",
            "companyName": "JETWAYS",
            "country": "United States"
        },
        {
            "name": "Jetx Airlines",
            "code": "GX",
            "auxcode": "JXX",
            "companyName": "JETBIRD",
            "country": "Iceland"
        },
        {
            "name": "Jibair",
            "code": "",
            "auxcode": "JIB",
            "companyName": "JIBAIRLINE",
            "country": "Djibouti"
        },
        {
            "name": "Jigsaw Project",
            "code": "",
            "auxcode": "JSW",
            "companyName": "JIGSAW",
            "country": "United Kingdom"
        },
        {
            "name": "Jim Hankins Air Service",
            "code": "",
            "auxcode": "HKN",
            "companyName": "HANKINS",
            "country": "United States"
        },
        {
            "name": "Jim Ratliff Air Service",
            "code": "",
            "auxcode": "RAS",
            "companyName": "SHANHIL",
            "country": "United States"
        },
        {
            "name": "Joanas Avialinijos",
            "code": "",
            "auxcode": "JDG",
            "companyName": "LADYBLUE",
            "country": "Lithuania"
        },
        {
            "name": "Job Air",
            "code": "",
            "auxcode": "JBR",
            "companyName": "JOBAIR",
            "country": "Czech Republic"
        },
        {
            "name": "Johnson Air",
            "code": "",
            "auxcode": "JHN",
            "companyName": "AIR JOHNSON",
            "country": "United States"
        },
        {
            "name": "Johnsons Air",
            "code": "",
            "auxcode": "JON",
            "companyName": "JOHNSONSAIR",
            "country": "Ghana"
        },
        {
            "name": "Johnston Airways",
            "code": "",
            "auxcode": "JMJ",
            "companyName": "JOHNSTON",
            "country": "United States"
        },
        {
            "name": "Joint Military Commission",
            "code": "",
            "auxcode": "JMM",
            "companyName": "JOICOMAR",
            "country": "Sudan"
        },
        {
            "name": "Jomartaxi Aereo",
            "code": "",
            "auxcode": "JMT",
            "companyName": "JOMARTAXI",
            "country": "Mexico"
        },
        {
            "name": "Jonsson",
            "code": "",
            "auxcode": "ODI",
            "companyName": " H Air Taxi",
            "country": "ODINN"
        },
        {
            "name": "Jordan Aviation",
            "code": "R5",
            "auxcode": "JAV",
            "companyName": "JORDAN AVIATION",
            "country": "Jordan"
        },
        {
            "name": "Jordan International Air Cargo",
            "code": "",
            "auxcode": "JCI",
            "companyName": "",
            "country": "Jordan"
        },
        {
            "name": "Jorvik",
            "code": "",
            "auxcode": "JVK",
            "companyName": "ISLANDIC",
            "country": "Iceland"
        },
        {
            "name": "JucodeAir",
            "code": "",
            "auxcode": "JUR",
            "companyName": "JUNKERS",
            "country": "Switzerland"
        },
        {
            "name": "Juanda Flying School",
            "code": "",
            "auxcode": "JFS",
            "companyName": "JAEMCO",
            "country": "Indonesia"
        },
        {
            "name": "Juba Cargo Services & Aviation Company",
            "code": "",
            "auxcode": "JUC",
            "companyName": "JUBA CARGO",
            "country": "Sudan"
        },
        {
            "name": "Jubba Airways",
            "code": "",
            "auxcode": "JUB",
            "companyName": "JUBBA",
            "country": "Somali Republic"
        },
        {
            "name": "Jubilee Airways",
            "code": "",
            "auxcode": "DKE",
            "companyName": "DUKE",
            "country": "United Kingdom"
        },
        {
            "name": "Juneyao Airlines",
            "code": "HO",
            "auxcode": "DKH",
            "companyName": "JUNEYAO AIRLINES",
            "country": "China"
        },
        {
            "name": "Justair Scandinavia",
            "code": "",
            "auxcode": "MEY",
            "companyName": "MELODY",
            "country": "Sweden"
        },
        {
            "name": "Justice Prisoner and Alien Transportation System",
            "code": "",
            "auxcode": "DOJ",
            "companyName": "JUSTICE",
            "country": "United States"
        },
        {
            "name": "K D Air Corporation",
            "code": "",
            "auxcode": "KDC",
            "companyName": "KAY DEE",
            "country": "Canada"
        },
        {
            "name": "K S Avia",
            "code": "",
            "auxcode": "KSA",
            "companyName": "SKY CAMEL",
            "country": "Latvia"
        },
        {
            "name": "KcodeMile Air",
            "code": "",
            "auxcode": "KMI",
            "companyName": "KAYcodeMILE AIR",
            "country": "Thailand"
        },
        {
            "name": "KD Avia",
            "code": "KD",
            "auxcode": "KNI",
            "companyName": "KALININGRAD AIR",
            "country": "Russia"
        },
        {
            "name": "KLM Cityhopper",
            "code": "WA",
            "auxcode": "KLC",
            "companyName": "CITY",
            "country": "Netherlands"
        },
        {
            "name": "KLM Helicopter",
            "code": "",
            "auxcode": "KLH",
            "companyName": "KLM HELI",
            "country": "Netherlands"
        },
        {
            "name": "KLM Royal Dutch Airlines",
            "code": "KL",
            "auxcode": "KLM",
            "companyName": "KLM",
            "country": "Netherlands"
        },
        {
            "name": "Kabo Air",
            "code": "N2",
            "auxcode": "QNK",
            "companyName": "KABO",
            "country": "Nigeria"
        },
        {
            "name": "Kahama Mining Corporation",
            "code": "",
            "auxcode": "KMC",
            "companyName": "KAHAMA",
            "country": "Tanzania"
        },
        {
            "name": "Kaiser Air",
            "code": "",
            "auxcode": "KAI",
            "companyName": "KAISER",
            "country": "United States"
        },
        {
            "name": "Kalitta Air",
            "code": "K4",
            "auxcode": "CKS",
            "companyName": "CONNIE",
            "country": "United States"
        },
        {
            "name": "Kalitta Charters",
            "code": "",
            "auxcode": "KFS",
            "companyName": "KALITTA",
            "country": "United States"
        },
        {
            "name": "Kallat El Saker Air Company",
            "code": "",
            "auxcode": "KES",
            "companyName": "KALLAT EL SKER",
            "country": "Libya"
        },
        {
            "name": "Kam Air",
            "code": "RQ",
            "auxcode": "KMF",
            "companyName": "KAMGAR",
            "country": "Afghanistan"
        },
        {
            "name": "Kampuchea Airlines",
            "code": "E2",
            "auxcode": "KMP",
            "companyName": "KAMPUCHEA",
            "country": "Cambodia"
        },
        {
            "name": "KanafcodeArkia Airlines",
            "code": "",
            "auxcode": "KIZ",
            "companyName": "",
            "country": "Israel"
        },
        {
            "name": "Kanfey Ha'emek Aviation",
            "code": "",
            "auxcode": "KHE",
            "companyName": "KANFEY HAEMEK",
            "country": "Israel"
        },
        {
            "name": "Kansas State University",
            "code": "",
            "auxcode": "KSU",
            "companyName": "KcodeSTATE",
            "country": "United States"
        },
        {
            "name": "Karat",
            "code": "V2",
            "auxcode": "AKT",
            "companyName": "AVIAKARAT",
            "country": "Russia"
        },
        {
            "name": "Karibu Airways Company",
            "code": "",
            "auxcode": "KRB",
            "companyName": "KARIBU AIR",
            "country": "Tanzania"
        },
        {
            "name": "Karlog Air Charter",
            "code": "",
            "auxcode": "KLG",
            "companyName": "KARLOG",
            "country": "Denmark"
        },
        {
            "name": "Karthago Airlines",
            "code": "",
            "auxcode": "KAJ",
            "companyName": "KARTHAGO",
            "country": "Tunisia"
        },
        {
            "name": "Kartika Airlines",
            "code": "",
            "auxcode": "KAE",
            "companyName": "KARTIKA",
            "country": "Indonesia"
        },
        {
            "name": "Kata Transportation",
            "code": "",
            "auxcode": "KTV",
            "companyName": "KATAVIA",
            "country": "Sudan"
        },
        {
            "name": "Katekavia",
            "code": "",
            "auxcode": "KTK",
            "companyName": "KATEKAVIA",
            "country": "Russia"
        },
        {
            "name": "Kato Airline",
            "code": "",
            "auxcode": "KAT",
            "companyName": "KATOcodeAIR",
            "country": "Norway"
        },
        {
            "name": "Kavminvodyavia",
            "code": "KV",
            "auxcode": "MVD",
            "companyName": "AIR MINVODY",
            "country": "Russia"
        },
        {
            "name": "Kavouras Inc",
            "code": "",
            "auxcode": "XKA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Kaz Agros Avia",
            "code": "",
            "auxcode": "KRN",
            "companyName": "ANTOL",
            "country": "Kazakhstan"
        },
        {
            "name": "Kaz Air West",
            "code": "",
            "auxcode": "KAW",
            "companyName": "KAZWEST",
            "country": "Kazakhstan"
        },
        {
            "name": "Kazan Aviation Production Association",
            "code": "",
            "auxcode": "KAO",
            "companyName": "KAZAVAIA",
            "country": "Russia"
        },
        {
            "name": "Kazan Helicopters",
            "code": "",
            "auxcode": "KPH",
            "companyName": "KAMA",
            "country": "Russia"
        },
        {
            "name": "Kazavia",
            "code": "",
            "auxcode": "KKA",
            "companyName": "KAKAIR",
            "country": "Kazakhstan"
        },
        {
            "name": "Kazaviaspas",
            "code": "",
            "auxcode": "KZS",
            "companyName": "SPAKAZ",
            "country": "Kazakhstan"
        },
        {
            "name": "Keenair Charter code",
            "code": "",
            "auxcode": "JFK",
            "companyName": "KEENAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Kelix Air",
            "code": "",
            "auxcode": "KLX",
            "companyName": "KELIX",
            "country": "Nigeria"
        },
        {
            "name": "Kelner Airways",
            "code": "",
            "auxcode": "FKL",
            "companyName": "KELNER",
            "country": "Canada"
        },
        {
            "name": "Kelowna Flightcraft Air Charter",
            "code": "",
            "auxcode": "KFA",
            "companyName": "FLIGHTCRAFT",
            "country": "Canada"
        },
        {
            "name": "Kendell Airlines",
            "code": "",
            "auxcode": "KDA",
            "companyName": "KENDELL",
            "country": "Australia"
        },
        {
            "name": "Kenmore Air",
            "code": "M5",
            "auxcode": "KEN",
            "companyName": "KENMORE",
            "country": "United States"
        },
        {
            "name": "Kenn Borek Air",
            "code": "",
            "auxcode": "KBA",
            "companyName": "BOREK AIR",
            "country": "Canada"
        },
        {
            "name": "Kent Aviation",
            "code": "",
            "auxcode": "KAH",
            "companyName": "DEKAIR",
            "country": "Canada"
        },
        {
            "name": "Kenya Airways",
            "code": "KQ",
            "auxcode": "KQA",
            "companyName": "KENYA",
            "country": "Kenya"
        },
        {
            "name": "Kevis",
            "code": "",
            "auxcode": "KVS",
            "companyName": "KEVIS",
            "country": "Kazakhstan"
        },
        {
            "name": "Key Airlines",
            "code": "",
            "auxcode": "KEY",
            "companyName": "KEY AIR",
            "country": "United States"
        },
        {
            "name": "Key Lime Air",
            "code": "",
            "auxcode": "LYM",
            "companyName": "KEY LIME",
            "country": "United States"
        },
        {
            "name": "Keystone Aerial Surveys",
            "code": "",
            "auxcode": "FTP",
            "companyName": "FOOTPRINT",
            "country": "United States"
        },
        {
            "name": "Keystone Air Services",
            "code": "BZ",
            "auxcode": "KEE",
            "companyName": "KEYSTONE",
            "country": "Canada"
        },
        {
            "name": "Khalifa Airways",
            "code": "",
            "auxcode": "KZW",
            "companyName": "KHALIFA AIR",
            "country": "Algeria"
        },
        {
            "name": "Kharkov Aircraft Manufacturing Company",
            "code": "",
            "auxcode": "WKH",
            "companyName": "WESTcodeKHARKOV",
            "country": "Ukraine"
        },
        {
            "name": "Khazar",
            "code": "",
            "auxcode": "KHR",
            "companyName": "KHAZAR",
            "country": "Turkmenistan"
        },
        {
            "name": "Khoezestan Photros Air Lines",
            "code": "",
            "auxcode": "KHP",
            "companyName": "PHOTROS AIR",
            "country": "Iran"
        },
        {
            "name": "KhorivcodeAvia",
            "code": "",
            "auxcode": "KRV",
            "companyName": "KHORIVcodeAVIA",
            "country": "Ukraine"
        },
        {
            "name": "Khors Aircompany",
            "code": "",
            "auxcode": "KHO",
            "companyName": "AIRCOMPANY KHORS",
            "country": "Ukraine"
        },
        {
            "name": "Khyber Afghan Airlines",
            "code": "",
            "auxcode": "KHY",
            "companyName": "KHYBER",
            "country": "Afghanistan"
        },
        {
            "name": "Kiev Aviation Plant",
            "code": "",
            "auxcode": "UAK",
            "companyName": "AVIATION PLANT",
            "country": "Ukraine"
        },
        {
            "name": "King Aviation",
            "code": "",
            "auxcode": "KNG",
            "companyName": "KING",
            "country": "United Kingdom"
        },
        {
            "name": "Kingfisher Air Services",
            "code": "",
            "auxcode": "BEZ",
            "companyName": "SEA BREEZE",
            "country": "United States"
        },
        {
            "name": "Kingfisher Airlines",
            "code": "IT",
            "auxcode": "KFR",
            "companyName": "KINGFISHER",
            "country": "India"
        },
        {
            "name": "Knighthawk Air Express",
            "code": "",
            "auxcode": "KNX",
            "companyName": "KNIGHT FLIGHT",
            "country": "Canada"
        },
        {
            "name": "Kingston Air Services",
            "code": "",
            "auxcode": "KAS",
            "companyName": "KINGSTON AIR",
            "country": "Canada"
        },
        {
            "name": "Kinnarps",
            "code": "",
            "auxcode": "KIP",
            "companyName": "KINNARPS",
            "country": "Sweden"
        },
        {
            "name": "Kinshasa Airways",
            "code": "",
            "auxcode": "KNS",
            "companyName": "KINSHASA AIRWAYS",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Kirov Air Enterprise",
            "code": "",
            "auxcode": "KTA",
            "companyName": "VYATKAcodeAVIA",
            "country": "Russia"
        },
        {
            "name": "Kish Air",
            "code": "Y9",
            "auxcode": "IRK",
            "companyName": "KISHAIR",
            "country": "Iran"
        },
        {
            "name": "Kitty Hawk Aircargo",
            "code": "",
            "auxcode": "KHA",
            "companyName": "AIR KITTYHAWK",
            "country": "United States"
        },
        {
            "name": "Kitty Hawk Airways",
            "code": "",
            "auxcode": "KHC",
            "companyName": "CARGO HAWK",
            "country": "United States"
        },
        {
            "name": "Kiwi International Air Lines",
            "code": "KP",
            "auxcode": "KIA",
            "companyName": "KIWI AIR",
            "country": "United States"
        },
        {
            "name": "Knight Air",
            "code": "",
            "auxcode": "KNA",
            "companyName": "KNIGHTAIR",
            "country": "Canada"
        },
        {
            "name": "Knighthawk Express",
            "code": "",
            "auxcode": "KHX",
            "companyName": "RIZZ",
            "country": "United States"
        },
        {
            "name": "Knights Airlines",
            "code": "",
            "auxcode": "KGT",
            "companyName": "KNIGHTcodeLINER",
            "country": "Nigeria"
        },
        {
            "name": "Koanda Avacion",
            "code": "",
            "auxcode": "KOA",
            "companyName": "KOANDA",
            "country": "Spain"
        },
        {
            "name": "Koda International",
            "code": "",
            "auxcode": "OYE",
            "companyName": "KODA AIR",
            "country": "Nigeria"
        },
        {
            "name": "Kogalymavia Air Company",
            "code": "7K",
            "auxcode": "KGL",
            "companyName": "KOGALYM",
            "country": "Russia"
        },
        {
            "name": "Kom Activity",
            "code": "",
            "auxcode": "KOM",
            "companyName": "COMJET",
            "country": "Netherlands"
        },
        {
            "name": "Komiaviatrans State Air Enterprise",
            "code": "",
            "auxcode": "KMA",
            "companyName": "KOMI AVIA",
            "country": "Russia"
        },
        {
            "name": "Komiinteravia",
            "code": "8J",
            "auxcode": "KMV",
            "companyName": "KOMIINTER",
            "country": "Russia"
        },
        {
            "name": "KomsomolskcodeoncodeAmur Air Enterprise",
            "code": "",
            "auxcode": "KNM",
            "companyName": "KNAAPO",
            "country": "Russia"
        },
        {
            "name": "KoobcodeCorp code 96 KFT",
            "code": "",
            "auxcode": "KOB",
            "companyName": "AUTOFLEX",
            "country": "Hungary"
        },
        {
            "name": "Korean Air",
            "code": "KE",
            "auxcode": "KAL",
            "companyName": "KOREANAIR",
            "country": "Republic of Korea"
        },
        {
            "name": "Kosmas Air",
            "code": "",
            "auxcode": "KMG",
            "companyName": "KOSMAS CARGO",
            "country": "Serbia"
        },
        {
            "name": "Kosmos",
            "code": "",
            "auxcode": "KSM",
            "companyName": "KOSMOS",
            "country": "Russia"
        },
        {
            "name": "Kosova Airlines",
            "code": "",
            "auxcode": "KOS",
            "companyName": "KOSOVA",
            "country": "Serbia"
        },
        {
            "name": "Kovar Air",
            "code": "",
            "auxcode": "WOK",
            "companyName": "WOKAIR",
            "country": "Czech Republic"
        },
        {
            "name": "Krasnojarsky Airlines",
            "code": "7B",
            "auxcode": "KJC",
            "companyName": "KRASNOJARSKY AIR",
            "country": "Russia"
        },
        {
            "name": "Kremenchuk Flight College",
            "code": "",
            "auxcode": "KFC",
            "companyName": "KREMENCHUK",
            "country": "Ukraine"
        },
        {
            "name": "Krimaviamontag",
            "code": "",
            "auxcode": "KRG",
            "companyName": "AVIAMONTAG",
            "country": "Ukraine"
        },
        {
            "name": "Kroonk Air Agency",
            "code": "",
            "auxcode": "KRO",
            "companyName": "KROONK",
            "country": "Ukraine"
        },
        {
            "name": "Krylo Airlines",
            "code": "K9",
            "auxcode": "KRI",
            "companyName": "Krylo",
            "country": "Russia"
        },
        {
            "name": "Krym",
            "code": "",
            "auxcode": "KYM",
            "companyName": "CRIMEA AIR",
            "country": "Ukraine"
        },
        {
            "name": "Krystel Air Charter",
            "code": "",
            "auxcode": "OPC",
            "companyName": "OPTIC",
            "country": "United Kingdom"
        },
        {
            "name": "Kuban Airlines",
            "code": "GW",
            "auxcode": "KIL",
            "companyName": "AIR KUBAN",
            "country": "Russia"
        },
        {
            "name": "Kunpeng Airlines",
            "code": "VD",
            "auxcode": "KPA",
            "companyName": "KUNPENG",
            "country": "China"
        },
        {
            "name": "Kurzemes Avio",
            "code": "",
            "auxcode": "KZA",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Kustbevakningen",
            "code": "",
            "auxcode": "KBV",
            "companyName": "SWECOAST",
            "country": "Sweden"
        },
        {
            "name": "Kuwait Airways",
            "code": "KU",
            "auxcode": "KAC",
            "companyName": "KUWAITI",
            "country": "Kuwait"
        },
        {
            "name": "Kuzu Airlines Cargo",
            "code": "GO",
            "auxcode": "KZU",
            "companyName": "KUZU CARGO",
            "country": "Turkey"
        },
        {
            "name": "Kvadro Aero",
            "code": "",
            "auxcode": "QVR",
            "companyName": "PEGASO",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kwena Air",
            "code": "",
            "auxcode": "KWN",
            "companyName": "KWENA",
            "country": "South Africa"
        },
        {
            "name": "Kyrgyz Airlines",
            "code": "N5",
            "auxcode": "KGZ",
            "companyName": "BERMET",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kyrgyz Trans Avia",
            "code": "",
            "auxcode": "KTC",
            "companyName": "DINARA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kyrgyzstan",
            "code": "QH",
            "auxcode": "LYN",
            "companyName": "ALTYN AVIA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kyrgyzstan Airlines",
            "code": "R8",
            "auxcode": "KGA",
            "companyName": "KYRGYZ",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kyrgyzstan Department of Aviation",
            "code": "",
            "auxcode": "DAM",
            "companyName": "FLIGHT RESCUE",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kyrgz General Aviation",
            "code": "",
            "auxcode": "KGB",
            "companyName": "KEMIN",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kibris T",
            "code": "KY",
            "auxcode": "KYV",
            "companyName": "AIRKIBRIS",
            "country": "Turkey"
        },
        {
            "name": "L A Helicopter",
            "code": "",
            "auxcode": "LAH",
            "companyName": "STAR SHIP",
            "country": "United States"
        },
        {
            "name": "L J Aviation",
            "code": "",
            "auxcode": "LJY",
            "companyName": "ELJAY",
            "country": "United States"
        },
        {
            "name": "L R Airlines",
            "code": "",
            "auxcode": "LRB",
            "companyName": "LADY RACINE",
            "country": "Czech Republic"
        },
        {
            "name": "L&L Flygbildteknik",
            "code": "",
            "auxcode": "PHO",
            "companyName": "PHOTOFLIGHT",
            "country": "Sweden"
        },
        {
            "name": "L'Express",
            "code": "",
            "auxcode": "LEX",
            "companyName": "LEX",
            "country": "United States"
        },
        {
            "name": "Lcode3 Communications Flight Internation Aviation",
            "code": "",
            "auxcode": "FNT",
            "companyName": "FLIGHT INTERNATIONAL",
            "country": "United States"
        },
        {
            "name": "L.A.B. Flying Service",
            "code": "JF",
            "auxcode": "LAB",
            "companyName": "LAB",
            "country": "United States"
        },
        {
            "name": "LACSA",
            "code": "LR",
            "auxcode": "LRC",
            "companyName": "LACSA",
            "country": "Costa Rica"
        },
        {
            "name": "LADE code Lineas Aereas Del Estado",
            "code": "",
            "auxcode": "LDE",
            "companyName": "LADE",
            "country": "Argentina"
        },
        {
            "name": "LAI code Linea Aerea IAACA",
            "code": "KG",
            "auxcode": "BNX",
            "companyName": "AIR BARINAS",
            "country": "Venezuela"
        },
        {
            "name": "LAN Airlines",
            "code": "LA",
            "auxcode": "LAN",
            "companyName": "LAN",
            "country": "Chile"
        },
        {
            "name": "LAN Argentina",
            "code": "4M",
            "auxcode": "DSM",
            "companyName": "LAN AR",
            "country": "Argentina"
        },
        {
            "name": "LAN Cargo",
            "code": "",
            "auxcode": "LCO",
            "companyName": "LAN CARGO",
            "country": "Chile"
        },
        {
            "name": "LAN Dominica",
            "code": "",
            "auxcode": "LNC",
            "companyName": "LANCANA",
            "country": "Dominican Republic"
        },
        {
            "name": "LAN Express",
            "code": "LU",
            "auxcode": "LXP",
            "companyName": "LANEX",
            "country": "Chile"
        },
        {
            "name": "LAN Peru",
            "code": "LP",
            "auxcode": "LPE",
            "companyName": "LANPERU",
            "country": "Peru"
        },
        {
            "name": "LANSA",
            "code": "",
            "auxcode": "LSA",
            "companyName": "INTERNACIONAL",
            "country": "Dominican Republic"
        },
        {
            "name": "LAP Colombia code Lineas Aereas Petroleras",
            "code": "",
            "auxcode": "APT",
            "companyName": " S.A.",
            "country": "LAP"
        },
        {
            "name": "LASTP",
            "code": "",
            "auxcode": "OTN",
            "companyName": "LASTP",
            "country": "S"
        },
        {
            "name": "LC Busre",
            "code": "",
            "auxcode": "LCB",
            "companyName": "BUSRE",
            "country": "Peru"
        },
        {
            "name": "LOT Polish Airlines",
            "code": "LO",
            "auxcode": "LOT",
            "companyName": "POLLOT",
            "country": "Poland"
        },
        {
            "name": "LTE International Airways",
            "code": "XO",
            "auxcode": "LTE",
            "companyName": "FUN JET",
            "country": "Spain"
        },
        {
            "name": "LTU Austria",
            "code": "L3",
            "auxcode": "LTO",
            "companyName": "BILLA TRANSPORT",
            "country": "Austria"
        },
        {
            "name": "LTU International",
            "code": "LT",
            "auxcode": "LTU",
            "companyName": "LTU",
            "country": "Germany"
        },
        {
            "name": "LTV Jet Fleet Corporation",
            "code": "",
            "auxcode": "JFC",
            "companyName": "JETcodeFLEET",
            "country": "United States"
        },
        {
            "name": "LUKoilcodeAvia",
            "code": "",
            "auxcode": "LUK",
            "companyName": "LUKOIL",
            "country": "Russia"
        },
        {
            "name": "La Ronge Aviation Services",
            "code": "",
            "auxcode": "ASK",
            "companyName": "AIR SASK",
            "country": "Canada"
        },
        {
            "name": "La Valenciana Taxi Aereo",
            "code": "",
            "auxcode": "LVT",
            "companyName": "TAXIVALENCIANA",
            "country": "Mexico"
        },
        {
            "name": "Labcorp",
            "code": "",
            "auxcode": "SKQ",
            "companyName": "SKYLAB",
            "country": "United States"
        },
        {
            "name": "Labrador Airways",
            "code": "",
            "auxcode": "LAL",
            "companyName": "LAB AIR",
            "country": "Canada"
        },
        {
            "name": "Lagun Air",
            "code": "N6",
            "auxcode": "JEV",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Lake Havasu Air Service",
            "code": "",
            "auxcode": "HCA",
            "companyName": "HAVASU",
            "country": "United States"
        },
        {
            "name": "Lakeland Aviation",
            "code": "",
            "auxcode": "LKL",
            "companyName": "LAKELAND",
            "country": "United States"
        },
        {
            "name": "Laker Airways",
            "code": "",
            "auxcode": "LKR",
            "companyName": "LAKER",
            "country": "United States"
        },
        {
            "name": "Laker Airways (Bahamas)",
            "code": "",
            "auxcode": "LBH",
            "companyName": "LAKER BAHAMAS",
            "country": "United States"
        },
        {
            "name": "Lamra",
            "code": "",
            "auxcode": "LMR",
            "companyName": "LAMAIR",
            "country": "Sudan"
        },
        {
            "name": "Lanaes Aereas Trans Costa Rica",
            "code": "",
            "auxcode": "TCR",
            "companyName": "TICOS",
            "country": "Costa Rica"
        },
        {
            "name": "Landsflug",
            "code": "",
            "auxcode": "ISL",
            "companyName": "ISLANDIA",
            "country": "Iceland"
        },
        {
            "name": "Langtry Flying Group",
            "code": "",
            "auxcode": "PAP",
            "companyName": "PROFLIGHT",
            "country": "United Kingdom"
        },
        {
            "name": "Lankair",
            "code": "IK",
            "auxcode": "LKN",
            "companyName": "Lankair",
            "country": "Sri Lanka"
        },
        {
            "name": "Lanza Air",
            "code": "",
            "auxcode": "LZA",
            "companyName": "AEROLANZA",
            "country": "Spain"
        },
        {
            "name": "Lanzarote Aerocargo",
            "code": "",
            "auxcode": "LZT",
            "companyName": "BARAKA",
            "country": "Spain"
        },
        {
            "name": "Lao Air Company",
            "code": "",
            "auxcode": "LLL",
            "companyName": "LAVIE",
            "country": "Lao Peoples Democratic Republic"
        },
        {
            "name": "Lao Airlines",
            "code": "QV",
            "auxcode": "LAO",
            "companyName": "LAO",
            "country": "Lao Peoples Democratic Republic"
        },
        {
            "name": "Lao Capricorn Air",
            "code": "",
            "auxcode": "LKA",
            "companyName": "NAKLOA",
            "country": "Lao Peoples Democratic Republic"
        },
        {
            "name": "Laoag International Airlines",
            "code": "L7",
            "auxcode": "LPN",
            "companyName": "LAOAG AIR",
            "country": "Philippines"
        },
        {
            "name": "Laredo Air",
            "code": "",
            "auxcode": "LRD",
            "companyName": "LAREDO AIR",
            "country": "United States"
        },
        {
            "name": "LatCharter",
            "code": "",
            "auxcode": "LTC",
            "companyName": "LATCHARTER",
            "country": "Latvia"
        },
        {
            "name": "Latvian Air Force",
            "code": "",
            "auxcode": "LAF",
            "companyName": "LATVIAN AIRFORCE",
            "country": "Latvia"
        },
        {
            "name": "Lauda Air",
            "code": "NG",
            "auxcode": "LDA",
            "companyName": "LAUDA AIR",
            "country": "Austria"
        },
        {
            "name": "Lauda Air Italy",
            "code": "",
            "auxcode": "LDI",
            "companyName": "LAUDA ITALY",
            "country": "Italy"
        },
        {
            "name": "Laughlin Express",
            "code": "",
            "auxcode": "LEP",
            "companyName": "LAUGHLIN EXPRESS",
            "country": "United States"
        },
        {
            "name": "Laus",
            "code": "",
            "auxcode": "LSU",
            "companyName": "LAUS AIR",
            "country": "Croatia"
        },
        {
            "name": "Lawrence Aviation",
            "code": "",
            "auxcode": "LAR",
            "companyName": "LAWRENCE",
            "country": "United States"
        },
        {
            "name": "LayangcodeLayang Aerospace",
            "code": "",
            "auxcode": "LAY",
            "companyName": "LAYANG",
            "country": "Malaysia"
        },
        {
            "name": "LeasecodeacodePlane International",
            "code": "",
            "auxcode": "LPL",
            "companyName": "LEASEcodeAcodePLANE",
            "country": "United States"
        },
        {
            "name": "Lebanese Air Transport",
            "code": "LQ",
            "auxcode": "LAQ",
            "companyName": "LAT",
            "country": "Lebanon"
        },
        {
            "name": "Lebanese Air Transport (Charter)",
            "code": "",
            "auxcode": "LAT",
            "companyName": "LEBANESE AIR",
            "country": "Lebanon"
        },
        {
            "name": "Lebanon Airport Development Corporation",
            "code": "",
            "auxcode": "LAD",
            "companyName": "LADCOcodeAIR",
            "country": "United States"
        },
        {
            "name": "Leconte Airlines",
            "code": "",
            "auxcode": "LCA",
            "companyName": "LECONTE",
            "country": "United States"
        },
        {
            "name": "Leeward Islands Air Transport",
            "code": "LI",
            "auxcode": "LIA",
            "companyName": "LIAT",
            "country": "Antigua and Barbuda"
        },
        {
            "name": "Legend Airlines",
            "code": "",
            "auxcode": "LGD",
            "companyName": "LEGENDARY",
            "country": "United States"
        },
        {
            "name": "Leisure Air",
            "code": "",
            "auxcode": "LWD",
            "companyName": "LEISURE WORLD",
            "country": "United States"
        },
        {
            "name": "Lentini Aviation",
            "code": "",
            "auxcode": "LEN",
            "companyName": "LENTINI",
            "country": "United States"
        },
        {
            "name": "LeocodeAir",
            "code": "",
            "auxcode": "LOR",
            "companyName": "LEO CHARTER",
            "country": "South Africa"
        },
        {
            "name": "Leonsa De Aviacion",
            "code": "",
            "auxcode": "LEL",
            "companyName": "LEONAVIA",
            "country": "Spain"
        },
        {
            "name": "Libyan Airlines",
            "code": "",
            "auxcode": "LYW",
            "companyName": "LIBYAN AIRWAYS",
            "country": "Libya"
        },
        {
            "name": "Libyan Arab Airlines",
            "code": "LN",
            "auxcode": "LAA",
            "companyName": "LIBAIR",
            "country": "Libya"
        },
        {
            "name": "Libyan Arab Air Cargo",
            "code": "",
            "auxcode": "LCR",
            "companyName": "LIBAC",
            "country": "Libya"
        },
        {
            "name": "Lid Air",
            "code": "",
            "auxcode": "LIQ",
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Lignes Aeriennes Congolaises",
            "code": "",
            "auxcode": "LCG",
            "companyName": "CONGOLAISE",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Lignes Aeriennes Du Tchad",
            "code": "",
            "auxcode": "LKD",
            "companyName": "LATCHAD",
            "country": "Chad"
        },
        {
            "name": "Lignes Mauritaniennes Air Express",
            "code": "",
            "auxcode": "LME",
            "companyName": "LIMAIR EXPRESS",
            "country": "Mauritania"
        },
        {
            "name": "Lignes Nationales Aeriennes code Linacongo",
            "code": "",
            "auxcode": "GCB",
            "companyName": "LINACONGO",
            "country": "Republic of the Congo"
        },
        {
            "name": "Lincoln Air National Guard",
            "code": "",
            "auxcode": "GDQ",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Lincoln Airlines",
            "code": "",
            "auxcode": "LRT",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Lindsay Aviation",
            "code": "",
            "auxcode": "LSY",
            "companyName": "LINDSAY AIR",
            "country": "United States"
        },
        {
            "name": "Linea Aerea Costa Norte",
            "code": "",
            "auxcode": "NOT",
            "companyName": "COSTA NORTE",
            "country": "Chile"
        },
        {
            "name": "Linea Aerea Mexicana de Carga",
            "code": "",
            "auxcode": "LMC",
            "companyName": "LINEAS DECARGA",
            "country": "Mexico"
        },
        {
            "name": "Linea Aerea SAPSA",
            "code": "L7",
            "auxcode": "LNP",
            "companyName": "SAPSA",
            "country": "Chile"
        },
        {
            "name": "Linea Aerea de Fumig Aguas Negras",
            "code": "",
            "auxcode": "NEG",
            "companyName": "AGUAS NEGRAS",
            "country": "Chile"
        },
        {
            "name": "Linea Aerea de Servicio Ejecutivo Regional",
            "code": "8z",
            "auxcode": "LER",
            "companyName": "LASER",
            "country": "Venezuela"
        },
        {
            "name": "Linea De Aeroservicios",
            "code": "",
            "auxcode": "LSE",
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "Linea Turistica Aerotuy",
            "code": "LD",
            "auxcode": "TUY",
            "companyName": "AEREOTUY",
            "country": "Venezuela"
        },
        {
            "name": "Lineas Aereas Alaire S.L.",
            "code": "",
            "auxcode": "ALR",
            "companyName": "AEROLAIRE",
            "country": "Spain"
        },
        {
            "name": "Lineas Aereas Azteca",
            "code": "ZE",
            "auxcode": "LCD",
            "companyName": "LINEAS AZTECA",
            "country": "Mexico"
        },
        {
            "name": "Lineas Aereas Canedo LAC",
            "code": "",
            "auxcode": "LCN",
            "companyName": "CANEDO",
            "country": "Bolivia"
        },
        {
            "name": "Lineas Aereas Comerciales",
            "code": "",
            "auxcode": "LCM",
            "companyName": "LINEAS COMERCIALES",
            "country": "Mexico"
        },
        {
            "name": "Lineas Aereas Ejectuivas De Durango",
            "code": "",
            "auxcode": "EDD",
            "companyName": "LINEAS DURANGO",
            "country": "Mexico"
        },
        {
            "name": "Lineas Aereas Eldorado",
            "code": "",
            "auxcode": "EDR",
            "companyName": "ELDORADRO",
            "country": "Colombia"
        },
        {
            "name": "Lineas Aereas Federales",
            "code": "",
            "auxcode": "FED",
            "companyName": "FEDERALES",
            "country": "Argentina"
        },
        {
            "name": "Lineas Aereas Monarca",
            "code": "",
            "auxcode": "LMN",
            "companyName": "LINEAS MONARCA",
            "country": "Mexico"
        },
        {
            "name": "Lineas Aereas San Jose",
            "code": "",
            "auxcode": "LIJ",
            "companyName": "LINEAS JOSE",
            "country": "Mexico"
        },
        {
            "name": "Lineas Aereas del Humaya",
            "code": "",
            "auxcode": "UMA",
            "companyName": "HUMAYA",
            "country": "Mexico"
        },
        {
            "name": "Linex",
            "code": "",
            "auxcode": "LEC",
            "companyName": "LECA",
            "country": "Central African Republic"
        },
        {
            "name": "Linhas Aereas Santomenses",
            "code": "",
            "auxcode": "SMS",
            "companyName": "SANTOMENSES",
            "country": "S"
        },
        {
            "name": "Linhas A",
            "code": "LM",
            "auxcode": "LAM",
            "companyName": "MOZAMBIQUE",
            "country": "Mozambique"
        },
        {
            "name": "Link Airways of Australia",
            "code": "",
            "auxcode": "LAW",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Lion Air Services",
            "code": "",
            "auxcode": "WGT",
            "companyName": "WORLDGATE",
            "country": "United Kingdom"
        },
        {
            "name": "Lion Mentari Airlines",
            "code": "JT",
            "auxcode": "LNI",
            "companyName": "LION INTER",
            "country": "Indonesia"
        },
        {
            "name": "LionscodeAir",
            "code": "",
            "auxcode": "LEU",
            "companyName": "LIONSAIR",
            "country": "Switzerland"
        },
        {
            "name": "Lithuanian Air Force",
            "code": "",
            "auxcode": "LYF",
            "companyName": "LITHUANIAN AIRFORCE",
            "country": "Lithuania"
        },
        {
            "name": "Little Red Air Service",
            "code": "",
            "auxcode": "LRA",
            "companyName": "LITTLE RED",
            "country": "Canada"
        },
        {
            "name": "Livingston",
            "code": "LM",
            "auxcode": "LVG",
            "companyName": "LIVINGSTON",
            "country": "Italy"
        },
        {
            "name": "Lloyd Aereo Boliviano",
            "code": "LB",
            "auxcode": "LLB",
            "companyName": "LLOYDAEREO",
            "country": "Bolivia"
        },
        {
            "name": "Lnair Air Services",
            "code": "",
            "auxcode": "LNA",
            "companyName": "ELNAIR",
            "country": "Spain"
        },
        {
            "name": "Lockheed Air Terminal",
            "code": "",
            "auxcode": "XLG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Lockeed Aircraft Corporation",
            "code": "",
            "auxcode": "LAC",
            "companyName": "LOCKHEED",
            "country": "United States"
        },
        {
            "name": "Lockheed DUATS",
            "code": "",
            "auxcode": "XDD",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Lockheed Martin Aeronautics",
            "code": "",
            "auxcode": "CBD",
            "companyName": "CATBIRD",
            "country": "United States"
        },
        {
            "name": "Lockheed Martin Aeronautics Company",
            "code": "",
            "auxcode": "LNG",
            "companyName": "LIGHTNING",
            "country": "United States"
        },
        {
            "name": "Logan Air",
            "code": "",
            "auxcode": "LOG",
            "companyName": "LOGAN",
            "country": "United Kingdom"
        },
        {
            "name": "Lom Praha Flying School",
            "code": "",
            "auxcode": "CLV",
            "companyName": "AEROTRAINING",
            "country": "Czech Republic"
        },
        {
            "name": "Lomas Helicopters",
            "code": "",
            "auxcode": "LMS",
            "companyName": "LOMAS",
            "country": "United Kingdom"
        },
        {
            "name": "London City Airport Jet Centre",
            "code": "",
            "auxcode": "LCY",
            "companyName": "LONDON CITY",
            "country": "United Kingdom"
        },
        {
            "name": "London Executive Aviation",
            "code": "",
            "auxcode": "LNX",
            "companyName": "LONEX",
            "country": "United Kingdom"
        },
        {
            "name": "London Flight Centre (Stansted)",
            "code": "",
            "auxcode": "LOV",
            "companyName": "LOVEAIR",
            "country": "United Kingdom"
        },
        {
            "name": "London Helicopter Centres",
            "code": "",
            "auxcode": "LHC",
            "companyName": "MUSTANG",
            "country": "United Kingdom"
        },
        {
            "name": "Lone Star Airlines",
            "code": "",
            "auxcode": "LSS",
            "companyName": "LONE STAR",
            "country": "United States"
        },
        {
            "name": "Long Island Airlines",
            "code": "",
            "auxcode": "ORA",
            "companyName": "LONG ISLAND",
            "country": "United States"
        },
        {
            "name": "Longtail Aviation",
            "code": "",
            "auxcode": "LGT",
            "companyName": "LONGTAIL",
            "country": "Bermuda"
        },
        {
            "name": "Lorraine Aviation",
            "code": "",
            "auxcode": "LRR",
            "companyName": "LORRAINE",
            "country": "France"
        },
        {
            "name": "Los Cedros Aviacion",
            "code": "",
            "auxcode": "LSC",
            "companyName": "CEDROS",
            "country": "Chile"
        },
        {
            "name": "Lotus Air",
            "code": "",
            "auxcode": "TAS",
            "companyName": "LOTUS FLOWER",
            "country": "Egypt"
        },
        {
            "name": "Luchtvaartmaatschappij Twente",
            "code": "",
            "auxcode": "LTW",
            "companyName": "TWENTAIR",
            "country": "Netherlands"
        },
        {
            "name": "Lucky Air",
            "code": "",
            "auxcode": "LKE",
            "companyName": "LUCKY AIR",
            "country": "China"
        },
        {
            "name": "Luft Carago",
            "code": "",
            "auxcode": "LUT",
            "companyName": "LUGO",
            "country": "South Africa"
        },
        {
            "name": "LuftfahrtcodeVermietungscodeDienst",
            "code": "",
            "auxcode": "LVD",
            "companyName": "AIR SANTE",
            "country": "Austria"
        },
        {
            "name": "Luftfahrtgesellschaft Walter",
            "code": "HE",
            "auxcode": "LGW",
            "companyName": "WALTER",
            "country": "Germany"
        },
        {
            "name": "Lufthansa",
            "code": "LH",
            "auxcode": "DLH",
            "companyName": "LUFTHANSA",
            "country": "Germany"
        },
        {
            "name": "Lufthansa Cargo",
            "code": "LH",
            "auxcode": "GEC",
            "companyName": "LUFTHANSA CARGO",
            "country": "Germany"
        },
        {
            "name": "Lufthansa CityLine",
            "code": "CL",
            "auxcode": "CLH",
            "companyName": "HANSALINE",
            "country": "Germany"
        },
        {
            "name": "Lufthansa Systems",
            "code": "L1",
            "auxcode": "",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Lufthansa Technik",
            "code": "",
            "auxcode": "LHT",
            "companyName": "LUFTHANSA TECHNIK",
            "country": "Germany"
        },
        {
            "name": "Lufttaxi Fluggesellschaft",
            "code": "DV",
            "auxcode": "LTF",
            "companyName": "Garfield",
            "country": "Germany"
        },
        {
            "name": "Lufttransport",
            "code": "L5",
            "auxcode": "LTR",
            "companyName": "LUFT TRANSPORT",
            "country": "Norway"
        },
        {
            "name": "Luhansk",
            "code": "",
            "auxcode": "LHS",
            "companyName": "ENTERPRISE LUHANSK",
            "country": "Ukraine"
        },
        {
            "name": "Lund University School of Aviation",
            "code": "",
            "auxcode": "UNY",
            "companyName": "UNIVERSITY",
            "country": "Sweden"
        },
        {
            "name": "Luxair",
            "code": "LG",
            "auxcode": "LGL",
            "companyName": "LUXAIR",
            "country": "Luxembourg"
        },
        {
            "name": "Luxaviation",
            "code": "",
            "auxcode": "LXA",
            "companyName": "RED LION",
            "country": "Luxembourg"
        },
        {
            "name": "Luxembourg Air Rescue",
            "code": "",
            "auxcode": "LUV",
            "companyName": "LUX RESCUE",
            "country": "Luxembourg"
        },
        {
            "name": "Luxflight Executive",
            "code": "",
            "auxcode": "LFE",
            "companyName": "LUX EXPRESS",
            "country": "Luxembourg"
        },
        {
            "name": "Luxor Air",
            "code": "",
            "auxcode": "LXO",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Luzair",
            "code": "",
            "auxcode": "LUZ",
            "companyName": "LISBON JET",
            "country": "Portugal"
        },
        {
            "name": "Lviv Airlines",
            "code": "5V",
            "auxcode": "UKW",
            "companyName": "UKRAINE WEST",
            "country": "Ukraine"
        },
        {
            "name": "Lydd Air",
            "code": "",
            "auxcode": "LYD",
            "companyName": "LYDDAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Lynch Flying Service",
            "code": "",
            "auxcode": "LCH",
            "companyName": "LYNCH AIR",
            "country": "United States"
        },
        {
            "name": "Lynden Air Cargo",
            "code": "L2",
            "auxcode": "LYC",
            "companyName": "LYNDEN",
            "country": "United States"
        },
        {
            "name": "Lynx Air International",
            "code": "",
            "auxcode": "LXF",
            "companyName": "LYNX FLIGHT",
            "country": "United States"
        },
        {
            "name": "Lynx Aviation",
            "code": "",
            "auxcode": "LYX",
            "companyName": "LYNX AIR",
            "country": "Pakistan"
        },
        {
            "name": "L",
            "code": "MJ",
            "auxcode": "LPR",
            "companyName": "LAPA",
            "country": "Argentina"
        },
        {
            "name": "L",
            "code": "",
            "auxcode": "LAU",
            "companyName": "SURAMERICANO",
            "country": "Colombia"
        },
        {
            "name": "M & N Aviation",
            "code": "",
            "auxcode": "JNH",
            "companyName": "JONAH",
            "country": "United States"
        },
        {
            "name": "MAC Fotografica",
            "code": "",
            "auxcode": "MCF",
            "companyName": "MAC FOTO",
            "country": "Spain"
        },
        {
            "name": "MANAG'AIR",
            "code": "",
            "auxcode": "MRG",
            "companyName": "MANAG'AIR",
            "country": "France"
        },
        {
            "name": "MAPcodeManagement and Planung",
            "code": "",
            "auxcode": "MPJ",
            "companyName": "MAPJET",
            "country": "Austria"
        },
        {
            "name": "MAS Airways",
            "code": "",
            "auxcode": "TFG",
            "companyName": "TRAFALGAR",
            "country": "United Kingdom"
        },
        {
            "name": "MasAir",
            "code": "M7",
            "auxcode": "MAA",
            "companyName": "MAS CARGA",
            "country": "Mexico"
        },
        {
            "name": "MAT Macedonian Airlines",
            "code": "IN",
            "auxcode": "MAK",
            "companyName": "MAKAVIO",
            "country": "Macedonia"
        },
        {
            "name": "MCC Aviation",
            "code": "",
            "auxcode": "MCC",
            "companyName": "DISCOVERY",
            "country": "South Africa"
        },
        {
            "name": "MG Aviacion",
            "code": "",
            "auxcode": "MGA",
            "companyName": "MAG AVACION",
            "country": "Spain"
        },
        {
            "name": "MIA Airlines",
            "code": "",
            "auxcode": "JLA",
            "companyName": "SALLINE",
            "country": "Romania"
        },
        {
            "name": "MIAT Mongolian Airlines",
            "code": "OM",
            "auxcode": "MGL",
            "companyName": "MONGOL AIR",
            "country": "Mongolia"
        },
        {
            "name": "MIT Airlines",
            "code": "",
            "auxcode": "MNC",
            "companyName": "MUNCIE",
            "country": "Canada"
        },
        {
            "name": "MK Airline",
            "code": "",
            "auxcode": "MKA",
            "companyName": "KRUGERcodeAIR",
            "country": "Ghana"
        },
        {
            "name": "MNG Airlines",
            "code": "MB",
            "auxcode": "MNB",
            "companyName": "BLACK SEA",
            "country": "Turkey"
        },
        {
            "name": "MSR FlugcodeCharter",
            "code": "",
            "auxcode": "EBF",
            "companyName": "SKYRUNNER",
            "country": "Germany"
        },
        {
            "name": "MTC Aviacion",
            "code": "",
            "auxcode": "MCV",
            "companyName": "MTC AVIACION",
            "country": "Mexico"
        },
        {
            "name": "Mac Aviation",
            "code": "",
            "auxcode": "MAQ",
            "companyName": "MAC AVIATION",
            "country": "Spain"
        },
        {
            "name": "Mac Dan Aviation Corporation",
            "code": "",
            "auxcode": "MCN",
            "companyName": "MAC DAN",
            "country": "United States"
        },
        {
            "name": "MacKnight Airlines",
            "code": "",
            "auxcode": "MTD",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Macair Airlines",
            "code": "CC",
            "auxcode": "MCK",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Macedonian Airlines",
            "code": "",
            "auxcode": "MCS",
            "companyName": "MACAIR",
            "country": "Greece"
        },
        {
            "name": "Madina Air",
            "code": "",
            "auxcode": "MDH",
            "companyName": "MADINA AIR",
            "country": "Libya"
        },
        {
            "name": "Maersk",
            "code": "DM",
            "auxcode": "",
            "companyName": "",
            "country": "Denmark"
        },
        {
            "name": "Magic Blue Airlines",
            "code": "",
            "auxcode": "MJB",
            "companyName": "MAGIC BLUE",
            "country": "Netherlands"
        },
        {
            "name": "Magna Air",
            "code": "",
            "auxcode": "MGR",
            "companyName": "MAGNA AIR",
            "country": "Austria"
        },
        {
            "name": "Mahalo Air",
            "code": "",
            "auxcode": "MLH",
            "companyName": "MAHALO",
            "country": "United States"
        },
        {
            "name": "Mahan Air",
            "code": "W5",
            "auxcode": "IRM",
            "companyName": "MAHAN AIR",
            "country": "Iran"
        },
        {
            "name": "Mahfooz Aviation",
            "code": "M2",
            "auxcode": "MZS",
            "companyName": "MAHFOOZ",
            "country": "Gambia"
        },
        {
            "name": "Maine Aviation",
            "code": "",
            "auxcode": "MAT",
            "companyName": "MAINEcodeAV",
            "country": "United States"
        },
        {
            "name": "Majestic Airlines",
            "code": "",
            "auxcode": "MAJ",
            "companyName": "MAGIC AIR",
            "country": "United States"
        },
        {
            "name": "Mak Air",
            "code": "",
            "auxcode": "AKM",
            "companyName": "MAKAIR",
            "country": "Kazakhstan"
        },
        {
            "name": "Malagasy Airlines",
            "code": "",
            "auxcode": "MLG",
            "companyName": "",
            "country": "Madagascar"
        },
        {
            "name": "Malawi Express",
            "code": "",
            "auxcode": "MLX",
            "companyName": "MALAWI EXPRESS",
            "country": "Malawi"
        },
        {
            "name": "Malaya Aviatsia Dona",
            "code": "",
            "auxcode": "MKK",
            "companyName": "AEROKEY",
            "country": "Russia"
        },
        {
            "name": "Malaysia Airlines",
            "code": "MH",
            "auxcode": "MAS",
            "companyName": "MALAYSIAN",
            "country": "Malaysia"
        },
        {
            "name": "Mali Air",
            "code": "",
            "auxcode": "MAE",
            "companyName": "MALI AIREXPRESS",
            "country": "Austria"
        },
        {
            "name": "Mali Air Express",
            "code": "",
            "auxcode": "VXP",
            "companyName": "AVION EXPRESS",
            "country": "Mali"
        },
        {
            "name": "Mali Airways",
            "code": "",
            "auxcode": "MTZ",
            "companyName": "MALI AIRWAYS",
            "country": "Mali"
        },
        {
            "name": "Malila Airlift",
            "code": "",
            "auxcode": "MLC",
            "companyName": "MALILA",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Mall Airways",
            "code": "",
            "auxcode": "MLS",
            "companyName": "MALLcodeAIRWAYS",
            "country": "United States"
        },
        {
            "name": "Malmo Aviation",
            "code": "",
            "auxcode": "SCW",
            "companyName": "SCANWING",
            "country": "Sweden"
        },
        {
            "name": "Malmoe Air Taxi",
            "code": "",
            "auxcode": "LOD",
            "companyName": "LOGIC",
            "country": "Sweden"
        },
        {
            "name": "Malmö Aviation",
            "code": "TF",
            "auxcode": "SCW",
            "companyName": "Scanwings",
            "country": "Sweden"
        },
        {
            "name": "Malta Air Charter",
            "code": "R5",
            "auxcode": "MAC",
            "companyName": "MALTA CHARTER",
            "country": "Malta"
        },
        {
            "name": "Malta Wings",
            "code": "",
            "auxcode": "MWS",
            "companyName": "MALTA WINGS",
            "country": "Malta"
        },
        {
            "name": "Malév",
            "code": "MA",
            "auxcode": "MAH",
            "companyName": "MALEV",
            "country": "Hungary"
        },
        {
            "name": "Manaf International Airways",
            "code": "",
            "auxcode": "MLB",
            "companyName": "MANAF",
            "country": "Burundi"
        },
        {
            "name": "Mandala Airlines",
            "code": "RI",
            "auxcode": "MDL",
            "companyName": "MANDALA",
            "country": "Indonesia"
        },
        {
            "name": "Mandarin Airlines",
            "code": "AE",
            "auxcode": "MDA",
            "companyName": "Mandarin",
            "country": "Taiwan"
        },
        {
            "name": "Mango",
            "code": "JE",
            "auxcode": "MNO",
            "companyName": "TULCA",
            "country": "South Africa"
        },
        {
            "name": "Manhattan Air",
            "code": "",
            "auxcode": "MHN",
            "companyName": "MANHATTAN",
            "country": "United Kingdom"
        },
        {
            "name": "Manitoulin Air Services",
            "code": "",
            "auxcode": "MTO",
            "companyName": "MANITOULIN",
            "country": "Canada"
        },
        {
            "name": "Mann Air",
            "code": "",
            "auxcode": "MNR",
            "companyName": "TEEMOL",
            "country": "United Kingdom"
        },
        {
            "name": "Mannion Air Charter",
            "code": "",
            "auxcode": "MAN",
            "companyName": "MANNION",
            "country": "United States"
        },
        {
            "name": "Mantrust Asahi Airways",
            "code": "",
            "auxcode": "MTS",
            "companyName": "MANTRUST",
            "country": "Indonesia"
        },
        {
            "name": "Manx Airlines",
            "code": "",
            "auxcode": "MNX",
            "companyName": "MANX",
            "country": "United Kingdom"
        },
        {
            "name": "Maple Air Services",
            "code": "",
            "auxcode": "MAD",
            "companyName": "MAPLE AIR",
            "country": "Canada"
        },
        {
            "name": "March Helicopters",
            "code": "",
            "auxcode": "MAR",
            "companyName": "MARCH",
            "country": "United Kingdom"
        },
        {
            "name": "Marcopolo Airways",
            "code": "",
            "auxcode": "MCP",
            "companyName": "MARCOPOLO",
            "country": "Afghanistan"
        },
        {
            "name": "Marghi Air",
            "code": "",
            "auxcode": "MGI",
            "companyName": "MARGHI",
            "country": "Nigeria"
        },
        {
            "name": "Markair",
            "code": "",
            "auxcode": "MRK",
            "companyName": "MARKAIR",
            "country": "United States"
        },
        {
            "name": "Markoss Aviation",
            "code": "",
            "auxcode": "MKO",
            "companyName": "GOSHAWK",
            "country": "United Kingdom"
        },
        {
            "name": "Mars RK",
            "code": "6V",
            "auxcode": "MRW",
            "companyName": "AVIAMARS",
            "country": "Ukraine"
        },
        {
            "name": "Marshall Aerospace",
            "code": "",
            "auxcode": "MCE",
            "companyName": "MARSHALL",
            "country": "United Kingdom"
        },
        {
            "name": "Marsland Aviation",
            "code": "M7",
            "auxcode": "MSL",
            "companyName": "MARSLANDAIR",
            "country": "Sudan"
        },
        {
            "name": "Martin Aviation Services",
            "code": "",
            "auxcode": "XMA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "MartincodeBaker",
            "code": "",
            "auxcode": "MBE",
            "companyName": "MARTIN",
            "country": "United Kingdom"
        },
        {
            "name": "Martinair",
            "code": "MP",
            "auxcode": "MPH",
            "companyName": "MARTINAIR",
            "country": "Netherlands"
        },
        {
            "name": "Martinaire",
            "code": "",
            "auxcode": "MRA",
            "companyName": "MARTEX",
            "country": "United States"
        },
        {
            "name": "Martyn Fiddler Associates",
            "code": "",
            "auxcode": "MFA",
            "companyName": "SEAHORSE",
            "country": "United Kingdom"
        },
        {
            "name": "Marvin Limited",
            "code": "",
            "auxcode": "MVN",
            "companyName": "MARVIN",
            "country": "United Kingdom"
        },
        {
            "name": "Maryland State Police",
            "code": "",
            "auxcode": "TRP",
            "companyName": "TROOPER",
            "country": "United States"
        },
        {
            "name": "Massachusetts Institute of Technology",
            "code": "",
            "auxcode": "MTH",
            "companyName": "RESEARCH",
            "country": "United States"
        },
        {
            "name": "Massey University School of Aviation",
            "code": "",
            "auxcode": "MSY",
            "companyName": "MASSEY",
            "country": "New Zealand"
        },
        {
            "name": "Master Airways",
            "code": "",
            "auxcode": "MSW",
            "companyName": "MASTER AIRWAYS",
            "country": "Serbia"
        },
        {
            "name": "Master Planner",
            "code": "",
            "auxcode": "MPL",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Masterjet",
            "code": "",
            "auxcode": "LMJ",
            "companyName": "MASTERJET",
            "country": "Portugal"
        },
        {
            "name": "Mastertop Linhas Aereas",
            "code": "Q4",
            "auxcode": "",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "Mauria",
            "code": "",
            "auxcode": "MIA",
            "companyName": "MAURIA",
            "country": "Mauritania"
        },
        {
            "name": "Mauritanienne Aerienne Et Navale",
            "code": "",
            "auxcode": "MNV",
            "companyName": "NAVALE",
            "country": "Mauritania"
        },
        {
            "name": "Mauritanienne Air Fret",
            "code": "",
            "auxcode": "MRF",
            "companyName": "MAURcodeFRET",
            "country": "Mauritania"
        },
        {
            "name": "Mauritanienne Airways",
            "code": "",
            "auxcode": "MWY",
            "companyName": "MAURITANIENNE",
            "country": "Mauritania"
        },
        {
            "name": "Mauritanienne De Transport Aerien",
            "code": "",
            "auxcode": "MDE",
            "companyName": "MAURIcodeTRANS",
            "country": "Mauritania"
        },
        {
            "name": "Maverick Airways",
            "code": "",
            "auxcode": "MVR",
            "companyName": "MAVcodeAIR",
            "country": "United States"
        },
        {
            "name": "Mavial Magadan Airlines",
            "code": "H5",
            "auxcode": "MVL",
            "companyName": "Mavial",
            "country": "Russia"
        },
        {
            "name": "Max Avia",
            "code": "",
            "auxcode": "MAI",
            "companyName": "MAX AVIA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Max Sea Food",
            "code": "",
            "auxcode": "MSF",
            "companyName": "MAXESA",
            "country": "El Salvador"
        },
        {
            "name": "MaxcodeAviation",
            "code": "",
            "auxcode": "MAX",
            "companyName": "MAX AVIATION",
            "country": "Canada"
        },
        {
            "name": "Maxair",
            "code": "8M",
            "auxcode": "MXL",
            "companyName": "MAXAIR",
            "country": "Sweden"
        },
        {
            "name": "Maximus Air Cargo",
            "code": "",
            "auxcode": "MXU",
            "companyName": "CARGO MAX",
            "country": "United Arab Emirates"
        },
        {
            "name": "Maxjet Airways",
            "code": "MY",
            "auxcode": "MXJ",
            "companyName": "MAXcodeJET",
            "country": "United States"
        },
        {
            "name": "MaxsuscodeAvia",
            "code": "",
            "auxcode": "MXS",
            "companyName": "MAXSUScodeAVIA",
            "country": "Uzbekistan"
        },
        {
            "name": "May Air Xpress",
            "code": "",
            "auxcode": "MXP",
            "companyName": "BEECHNUT",
            "country": "United States"
        },
        {
            "name": "Maya Island Air",
            "code": "MW",
            "auxcode": "MYD",
            "companyName": "MYLAND",
            "country": "Belize"
        },
        {
            "name": "Mayair",
            "code": "",
            "auxcode": "MYI",
            "companyName": "MAYAIR",
            "country": "Mexico"
        },
        {
            "name": "Mbach Air",
            "code": "",
            "auxcode": "MBS",
            "companyName": "MBACHI AIR",
            "country": "Malawi"
        },
        {
            "name": "McAlpine Helicopters",
            "code": "",
            "auxcode": "MCH",
            "companyName": "MACLINE",
            "country": "United Kingdom"
        },
        {
            "name": "McCall Aviation",
            "code": "",
            "auxcode": "MKL",
            "companyName": "MCCALL",
            "country": "United States"
        },
        {
            "name": "McDonnell Douglas",
            "code": "",
            "auxcode": "DAC",
            "companyName": "DACO",
            "country": "United States"
        },
        {
            "name": "McNeely Charter Services",
            "code": "",
            "auxcode": "MDS",
            "companyName": "MIDcodeSOUTH",
            "country": "United States"
        },
        {
            "name": "MedcodeTrans of Florida",
            "code": "",
            "auxcode": "MEK",
            "companyName": "MEDcodeTRANS",
            "country": "United States"
        },
        {
            "name": "Medavia Company",
            "code": "",
            "auxcode": "MDM",
            "companyName": "MEDAVIA",
            "country": "Malta"
        },
        {
            "name": "Medical Air Rescue Services",
            "code": "",
            "auxcode": "MRZ",
            "companyName": "MARS",
            "country": "Zimbabwe"
        },
        {
            "name": "Medical Aviation Services",
            "code": "",
            "auxcode": "MCL",
            "companyName": "MEDIC",
            "country": "United Kingdom"
        },
        {
            "name": "Mediterranean Air Freight",
            "code": "",
            "auxcode": "MDF",
            "companyName": "MEDcodeFREIGHT",
            "country": "Greece"
        },
        {
            "name": "Mediterranean Airways",
            "code": "",
            "auxcode": "MDY",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Medjet International",
            "code": "",
            "auxcode": "MEJ",
            "companyName": "MEDJET",
            "country": "United States"
        },
        {
            "name": "Mega",
            "code": "",
            "auxcode": "MGK",
            "companyName": "MEGLA",
            "country": "Kazakhstan"
        },
        {
            "name": "Mega Linhas Aereas",
            "code": "",
            "auxcode": "MEL",
            "companyName": "MEGA AIR",
            "country": "Brazil"
        },
        {
            "name": "Mekong Airlines",
            "code": "",
            "auxcode": "MKN",
            "companyName": "MEKONG AIRLINES",
            "country": "Cambodia"
        },
        {
            "name": "Menajet",
            "code": "IM",
            "auxcode": "MNJ",
            "companyName": "MENAJET",
            "country": "Lebanon"
        },
        {
            "name": "Merchant Express Aviation",
            "code": "",
            "auxcode": "MXX",
            "companyName": "MERCHANT",
            "country": "Nigeria"
        },
        {
            "name": "Mercury Aircourier Service",
            "code": "",
            "auxcode": "MEC",
            "companyName": "MERCAIR",
            "country": "United States"
        },
        {
            "name": "Meridian",
            "code": "",
            "auxcode": "POV",
            "companyName": "AIR POLTAVA",
            "country": "Ukraine"
        },
        {
            "name": "Meridian Air Cargo",
            "code": "",
            "auxcode": "MRD",
            "companyName": "MERIDIAN",
            "country": "United States"
        },
        {
            "name": "Meridian Airlines",
            "code": "",
            "auxcode": "MHL",
            "companyName": "HASSIMAIR",
            "country": "Nigeria"
        },
        {
            "name": "Meridian Aviation",
            "code": "",
            "auxcode": "DSL",
            "companyName": "DIESEL",
            "country": "United Kingdom"
        },
        {
            "name": "Meridian Limited",
            "code": "",
            "auxcode": "MEM",
            "companyName": "MERIDIAN CHERRY",
            "country": "Ukraine"
        },
        {
            "name": "Meridiana",
            "code": "IG",
            "auxcode": "ISS",
            "companyName": "MERAIR",
            "country": "Italy"
        },
        {
            "name": "Merlin Airways",
            "code": "",
            "auxcode": "MEI",
            "companyName": "AVALON",
            "country": "United States"
        },
        {
            "name": "Merpati Nusantara Airlines",
            "code": "MZ",
            "auxcode": "MNA",
            "companyName": "MERPATI",
            "country": "Indonesia"
        },
        {
            "name": "Mesa Airlines",
            "code": "YV",
            "auxcode": "ASH",
            "companyName": "AIR SHUTTLE",
            "country": "United States"
        },
        {
            "name": "Mesaba Airlines",
            "code": "XJ",
            "auxcode": "MES",
            "companyName": "MESABA",
            "country": "United States"
        },
        {
            "name": "Meta Linhas A",
            "code": "",
            "auxcode": "MSQ",
            "companyName": "META",
            "country": "Brazil"
        },
        {
            "name": "Meteorological Research Flight",
            "code": "",
            "auxcode": "MET",
            "companyName": "METMAN",
            "country": "United Kingdom"
        },
        {
            "name": "Methow Aviation",
            "code": "",
            "auxcode": "MER",
            "companyName": "METHOW",
            "country": "United States"
        },
        {
            "name": "Metro Business Aviation",
            "code": "",
            "auxcode": "MVI",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Metro Express",
            "code": "",
            "auxcode": "MEX",
            "companyName": "EAGLE EXPRESS",
            "country": "United States"
        },
        {
            "name": "Metroflight",
            "code": "",
            "auxcode": "MTR",
            "companyName": "METRO",
            "country": "United States"
        },
        {
            "name": "Metrojet",
            "code": "",
            "auxcode": "MTJ",
            "companyName": "METROJET",
            "country": "Hong Kong SAR of China"
        },
        {
            "name": "Metropix UK",
            "code": "",
            "auxcode": "PIX",
            "companyName": "METROPIX",
            "country": "United Kingdom"
        },
        {
            "name": "Metroplis",
            "code": "",
            "auxcode": "MPS",
            "companyName": "METRO REGIONAL",
            "country": "Netherlands"
        },
        {
            "name": "Mex Blue",
            "code": "",
            "auxcode": "MXB",
            "companyName": "MEX BLUE",
            "country": "Mexico"
        },
        {
            "name": "MexcodeJet",
            "code": "",
            "auxcode": "MJT",
            "companyName": "MEJETS",
            "country": "Mexico"
        },
        {
            "name": "Mexicana de Aviaci",
            "code": "MX",
            "auxcode": "MXA",
            "companyName": "MEXICANA",
            "country": "Mexico"
        },
        {
            "name": "Mexico Transportes Aereos",
            "code": "",
            "auxcode": "MXT",
            "companyName": "TRANSMEX",
            "country": "Mexico"
        },
        {
            "name": "Miami Air Charter",
            "code": "",
            "auxcode": "HUR",
            "companyName": "HURRICANE CHARTER",
            "country": "United States"
        },
        {
            "name": "Miami Air International",
            "code": "GL",
            "auxcode": "BSK",
            "companyName": "BISCAYNE",
            "country": "United States"
        },
        {
            "name": "Miami Valley Aviation",
            "code": "",
            "auxcode": "OWL",
            "companyName": "NIGHT OWL",
            "country": "United States"
        },
        {
            "name": "MiapetcodeAvia",
            "code": "",
            "auxcode": "MPT",
            "companyName": "MIAPET",
            "country": "Armenia"
        },
        {
            "name": "Michelin Air Services",
            "code": "",
            "auxcode": "BIB",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Micromatter Technology Solutions",
            "code": "",
            "auxcode": "WIZ",
            "companyName": "WIZARD",
            "country": "United Kingdom"
        },
        {
            "name": "Mid Airlines",
            "code": "",
            "auxcode": "NYL",
            "companyName": "NILE",
            "country": "Sudan"
        },
        {
            "name": "MidcodePacific Airlines",
            "code": "",
            "auxcode": "MPA",
            "companyName": "MID PAC",
            "country": "United States"
        },
        {
            "name": "Midamerica Jet",
            "code": "",
            "auxcode": "MJR",
            "companyName": "MAJOR",
            "country": "United States"
        },
        {
            "name": "Middle East Airlines",
            "code": "ME",
            "auxcode": "MEA",
            "companyName": "CEDAR JET",
            "country": "Lebanon"
        },
        {
            "name": "Midland Airport Services",
            "code": "",
            "auxcode": "MID",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Midline Air Freight",
            "code": "",
            "auxcode": "MFR",
            "companyName": "MIDLINE FREIGHT",
            "country": "United States"
        },
        {
            "name": "Midstate Airlines",
            "code": "",
            "auxcode": "MIS",
            "companyName": "MIDSTATE",
            "country": "United States"
        },
        {
            "name": "Midway Airlines",
            "code": "JI",
            "auxcode": "MDW",
            "companyName": "MIDWAY",
            "country": "United States"
        },
        {
            "name": "Midway Express",
            "code": "",
            "auxcode": "FLA",
            "companyName": "PALM",
            "country": "United States"
        },
        {
            "name": "Midwest Air Freighters",
            "code": "",
            "auxcode": "FAX",
            "companyName": "FAIRFAX",
            "country": "United States"
        },
        {
            "name": "Midwest Airlines",
            "code": "YX",
            "auxcode": "MEP",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Midwest Airlines (Egypt)",
            "code": "MY",
            "auxcode": "MWA",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Midwest Aviation",
            "code": "",
            "auxcode": "NIT",
            "companyName": "NIGHTTRAIN",
            "country": "United States"
        },
        {
            "name": "Midwest Aviation Division",
            "code": "",
            "auxcode": "MWT",
            "companyName": "MIDWEST",
            "country": "United States"
        },
        {
            "name": "Midwest Helicopters De Mexico",
            "code": "",
            "auxcode": "HTE",
            "companyName": "HELICOPTERSMEXICO",
            "country": "Mexico"
        },
        {
            "name": "Millardair",
            "code": "",
            "auxcode": "MAB",
            "companyName": "MILLARDAIR",
            "country": "Canada"
        },
        {
            "name": "Millen Corporation",
            "code": "",
            "auxcode": "RJM",
            "companyName": "MILLEN",
            "country": "United Kingdom"
        },
        {
            "name": "Millennium Air",
            "code": "",
            "auxcode": "MLK",
            "companyName": "NIGERJET",
            "country": "Nigeria"
        },
        {
            "name": "Miller Flying Services",
            "code": "",
            "auxcode": "MFS",
            "companyName": "MILLER TIME",
            "country": "United States"
        },
        {
            "name": "Million Air",
            "code": "",
            "auxcode": "OXO",
            "companyName": "MILL AIR",
            "country": "United States"
        },
        {
            "name": "Mimino",
            "code": "",
            "auxcode": "MIM",
            "companyName": "MIMINO",
            "country": "Russia"
        },
        {
            "name": "Mina Airline Company",
            "code": "",
            "auxcode": "NAB",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Minair",
            "code": "",
            "auxcode": "OMR",
            "companyName": "ORMINE",
            "country": "Central African Republic"
        },
        {
            "name": "Minebea Technologies",
            "code": "",
            "auxcode": "EBE",
            "companyName": "MINEBEA",
            "country": "United States"
        },
        {
            "name": "Mines Air Services Zambia",
            "code": "",
            "auxcode": "MAZ",
            "companyName": "MINES",
            "country": "Zambia"
        },
        {
            "name": "Miniliner",
            "code": "",
            "auxcode": "MNL",
            "companyName": "MINILINER",
            "country": "Italy"
        },
        {
            "name": "Ministic Air",
            "code": "",
            "auxcode": "MNS",
            "companyName": "MINISTIC",
            "country": "Canada"
        },
        {
            "name": "Ministry of Agriculture",
            "code": "",
            "auxcode": "WDG",
            "companyName": " Fisheries and Food",
            "country": "WATCHDOG"
        },
        {
            "name": "Minsk Aircraft Overhaul Plant",
            "code": "",
            "auxcode": "LIR",
            "companyName": "LISLINE",
            "country": "Belarus"
        },
        {
            "name": "Miramichi Air Service",
            "code": "",
            "auxcode": "MIR",
            "companyName": "MIRAMICHI",
            "country": "Canada"
        },
        {
            "name": "Miras",
            "code": "",
            "auxcode": "MIF",
            "companyName": "MIRAS",
            "country": "Kazakhstan"
        },
        {
            "name": "Misr Overseas Airways",
            "code": "",
            "auxcode": "MOS",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Mission Aviation Fellowship",
            "code": "",
            "auxcode": "MAF",
            "companyName": "MISSI",
            "country": "Indonesia"
        },
        {
            "name": "Missionair",
            "code": "",
            "auxcode": "MSN",
            "companyName": "MISIONAIR",
            "country": "Spain"
        },
        {
            "name": "Missions Gouvernemtales Francaises",
            "code": "",
            "auxcode": "MRN",
            "companyName": "MARIANNE",
            "country": "France"
        },
        {
            "name": "Mississippi State University",
            "code": "",
            "auxcode": "BDG",
            "companyName": "BULLDOG",
            "country": "United States"
        },
        {
            "name": "Mississippi Valley Airways",
            "code": "",
            "auxcode": "MVA",
            "companyName": "VALAIR",
            "country": "United States"
        },
        {
            "name": "Mistral Air",
            "code": "",
            "auxcode": "MSA",
            "companyName": "AIRMERCI",
            "country": "Italy"
        },
        {
            "name": "Mobil Oil",
            "code": "",
            "auxcode": "MBO",
            "companyName": "MOBIL",
            "country": "Canada"
        },
        {
            "name": "Mocambique Expresso",
            "code": "",
            "auxcode": "MXE",
            "companyName": "MOZAMBIQUE EXPRESS",
            "country": "Mozambique"
        },
        {
            "name": "Mofaz Air",
            "code": "",
            "auxcode": "MFZ",
            "companyName": "MOFAZ AIR",
            "country": "Malaysia"
        },
        {
            "name": "Moldaeroservice",
            "code": "",
            "auxcode": "MLE",
            "companyName": "MOLDAERO",
            "country": "Moldova"
        },
        {
            "name": "Moldavian Airlines",
            "code": "2M",
            "auxcode": "MDV",
            "companyName": "MOLDAVIAN",
            "country": "Moldova"
        },
        {
            "name": "Moldova",
            "code": "",
            "auxcode": "MVG",
            "companyName": "MOLDOVAcodeSTATE",
            "country": "Moldova"
        },
        {
            "name": "Mombasa Air Safari",
            "code": "",
            "auxcode": "RRV",
            "companyName": "SKYROVER",
            "country": "Kenya"
        },
        {
            "name": "Monarch Airlines",
            "code": "ZB",
            "auxcode": "MON",
            "companyName": "MONARCH",
            "country": "United Kingdom"
        },
        {
            "name": "Monarch Airlines",
            "code": "",
            "auxcode": "MNH",
            "companyName": "MONARCH AIR",
            "country": "United States"
        },
        {
            "name": "Myway Airlines",
            "code": "8I",
            "auxcode": "",
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Moncton Flying Club",
            "code": "",
            "auxcode": "MFC",
            "companyName": "EAST WIND",
            "country": "Canada"
        },
        {
            "name": "Monde Air Charters",
            "code": "",
            "auxcode": "MDB",
            "companyName": "MONDEAIR CARGO",
            "country": "United Kingdom"
        },
        {
            "name": "Monerrey Air Taxi",
            "code": "",
            "auxcode": "MTI",
            "companyName": "MONTERREY AIR",
            "country": "Mexico"
        },
        {
            "name": "Monky Aerotaxis",
            "code": "",
            "auxcode": "MKY",
            "companyName": "MONKY",
            "country": "Mexico"
        },
        {
            "name": "Montenegro Airlines",
            "code": "YM",
            "auxcode": "MGX",
            "companyName": "MONTAIR",
            "country": "Montenegro"
        },
        {
            "name": "Montserrat Airways",
            "code": "",
            "auxcode": "MNT",
            "companyName": "MONTSERRAT",
            "country": "Montserrat"
        },
        {
            "name": "Mooney Aircraft Corporation",
            "code": "",
            "auxcode": "MNY",
            "companyName": "MOONEY FLIGHT",
            "country": "United States"
        },
        {
            "name": "Morningstar Air Express",
            "code": "",
            "auxcode": "MAL",
            "companyName": "MORNINGSTAR",
            "country": "Canada"
        },
        {
            "name": "Morris Air Service",
            "code": "",
            "auxcode": "MSS",
            "companyName": "WASATCH",
            "country": "United States"
        },
        {
            "name": "Morrison Flying Service",
            "code": "",
            "auxcode": "MRO",
            "companyName": "MORRISON",
            "country": "United States"
        },
        {
            "name": "Moskovia Airlines",
            "code": "3R",
            "auxcode": "GAI",
            "companyName": "GROMOV AIRLINE",
            "country": "Russia"
        },
        {
            "name": "Mosphil Aero",
            "code": "",
            "auxcode": "MPI",
            "companyName": "MOSPHIL",
            "country": "Philippines"
        },
        {
            "name": "Motor Sich",
            "code": "M9",
            "auxcode": "MSI",
            "companyName": "MOTOR SICH",
            "country": "Ukraine"
        },
        {
            "name": "Mount Cook Airlines",
            "code": "NM",
            "auxcode": "NZM",
            "companyName": "MOUNTCOOK",
            "country": "New Zealand"
        },
        {
            "name": "Mountain Air Cargo",
            "code": "",
            "auxcode": "MTN",
            "companyName": "MOUNTAIN",
            "country": "United States"
        },
        {
            "name": "Mountain Air Company",
            "code": "N4",
            "auxcode": "MTC",
            "companyName": "MOUNTAIN LEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Mountain Air Express",
            "code": "",
            "auxcode": "PKP",
            "companyName": "PIKES PEAK",
            "country": "United States"
        },
        {
            "name": "Mountain Air Service",
            "code": "",
            "auxcode": "BRR",
            "companyName": "MOUNTAIN AIR",
            "country": "United States"
        },
        {
            "name": "Mountain Bird",
            "code": "",
            "auxcode": "MBI",
            "companyName": "MOUNTAIN BIRD",
            "country": "United States"
        },
        {
            "name": "Mountain High Aviation",
            "code": "",
            "auxcode": "MHA",
            "companyName": "MOUNTAIN HIGH",
            "country": "United States"
        },
        {
            "name": "Mountain Pacific Air",
            "code": "",
            "auxcode": "MPC",
            "companyName": "MOUNTAIN PACIFIC",
            "country": "Canada"
        },
        {
            "name": "Mountain Valley Air Service",
            "code": "",
            "auxcode": "MTV",
            "companyName": "MOUNTAIN VALLEY",
            "country": "United States"
        },
        {
            "name": "Mowhawk Airlines",
            "code": "",
            "auxcode": "MOW",
            "companyName": "MOHAWK AIR",
            "country": "United States"
        },
        {
            "name": "Mudan Airlines",
            "code": "",
            "auxcode": "MDN",
            "companyName": "",
            "country": "Somali Republic"
        },
        {
            "name": "Mudanjiang General Aviation",
            "code": "",
            "auxcode": "CMJ",
            "companyName": "MUDANJIANG",
            "country": "China"
        },
        {
            "name": "Multi Taxi",
            "code": "",
            "auxcode": "MTX",
            "companyName": "MULTITAXI",
            "country": "Mexico"
        },
        {
            "name": "MulticodeAero",
            "code": "",
            "auxcode": "WBR",
            "companyName": "WEBER",
            "country": "United States"
        },
        {
            "name": "Multiflight",
            "code": "",
            "auxcode": "MFT",
            "companyName": "YORKAIR",
            "country": ""
        },
        {
            "name": "Murmansk Aircompany",
            "code": "",
            "auxcode": "MNZ",
            "companyName": "MURMAN AIR",
            "country": "Russia"
        },
        {
            "name": "Murray Air",
            "code": "",
            "auxcode": "MUA",
            "companyName": "MURRAY AIR",
            "country": "United States"
        },
        {
            "name": "Musrata Air Transport",
            "code": "",
            "auxcode": "MMR",
            "companyName": "MUSRATA AIR",
            "country": "Libya"
        },
        {
            "name": "Mustique Airways",
            "code": "",
            "auxcode": "MAW",
            "companyName": "MUSTIQUE",
            "country": "Barbados"
        },
        {
            "name": "My Way Airlines",
            "code": "",
            "auxcode": "MYW",
            "companyName": "FRANKY",
            "country": "Italy"
        },
        {
            "name": "MyTravel Airways",
            "code": "VZ",
            "auxcode": "MYT",
            "companyName": "KESTREL",
            "country": "United Kingdom"
        },
        {
            "name": "Myanma Airways",
            "code": "UB",
            "auxcode": "UBA",
            "companyName": "UNIONAIR",
            "country": "Myanmar"
        },
        {
            "name": "Myanmar Airways International",
            "code": "8M",
            "auxcode": "MMM",
            "companyName": "assignment postponed",
            "country": "Myanmar"
        },
        {
            "name": "Myflug",
            "code": "",
            "auxcode": "MYA",
            "companyName": "MYFLUG",
            "country": "Iceland"
        },
        {
            "name": "Mytravel Airways",
            "code": "",
            "auxcode": "VKG",
            "companyName": "VIKING",
            "country": "Denmark"
        },
        {
            "name": "NEL Cargo",
            "code": "",
            "auxcode": "NLG",
            "companyName": "NELCARGO",
            "country": "Ivory Coast"
        },
        {
            "name": "NHT Lineas Aereas",
            "code": "",
            "auxcode": "NHG",
            "companyName": "HELGA",
            "country": "Brazil"
        },
        {
            "name": "NZ Warbirds Association",
            "code": "",
            "auxcode": "WAR",
            "companyName": "WARBIRDS",
            "country": "New Zealand"
        },
        {
            "name": "Nacoia Lda",
            "code": "",
            "auxcode": "ANL",
            "companyName": "AIR NACOIA",
            "country": "Angola"
        },
        {
            "name": "Nada Air Service",
            "code": "",
            "auxcode": "NHZ",
            "companyName": "NADA AIR",
            "country": "Chad"
        },
        {
            "name": "Compangnie Nationale Naganagani",
            "code": "",
            "auxcode": "BFN",
            "companyName": "",
            "country": "Burkina Faso"
        },
        {
            "name": "Nahanni Air Services Ltd",
            "code": "",
            "auxcode": "NAH",
            "companyName": "NAHANNI",
            "country": "Canada"
        },
        {
            "name": "Nakheel Aviation",
            "code": "",
            "auxcode": "NKL",
            "companyName": "NAKHEEL",
            "country": "United Arab Emirates"
        },
        {
            "name": "Namibia Commerical Aviation",
            "code": "",
            "auxcode": "MRE",
            "companyName": "MED RESCUE",
            "country": "Namibia"
        },
        {
            "name": "Namibian Defence Force",
            "code": "",
            "auxcode": "NDF",
            "companyName": "NAMIBIAN AIR FORCE",
            "country": "Namibia"
        },
        {
            "name": "Nanjing Airlines",
            "code": "",
            "auxcode": "CNJ",
            "companyName": "NINGHANG",
            "country": "China"
        },
        {
            "name": "Nantucket Airlines",
            "code": "DV",
            "auxcode": "ACK",
            "companyName": "ACK AIR",
            "country": "United States"
        },
        {
            "name": "Nanyah Aviation",
            "code": "",
            "auxcode": "NYA",
            "companyName": "NANYAH",
            "country": "Israel"
        },
        {
            "name": "Napier Air Service Inc",
            "code": "",
            "auxcode": "NAP",
            "companyName": "NAPIER",
            "country": "United States"
        },
        {
            "name": "Nas Air",
            "code": "",
            "auxcode": "NCM",
            "companyName": "AIR BANE",
            "country": "Angola"
        },
        {
            "name": "Nas Air",
            "code": "P9",
            "auxcode": "",
            "companyName": "",
            "country": "Mali"
        },
        {
            "name": "Nasair",
            "code": "UE",
            "auxcode": "NAS",
            "companyName": "NASAIRWAYS",
            "country": "Eritrea"
        },
        {
            "name": "Nashville Jet Charters",
            "code": "",
            "auxcode": "NJC",
            "companyName": "NASHVILLE JET",
            "country": "United States"
        },
        {
            "name": "Natalco Air Lines",
            "code": "",
            "auxcode": "NCO",
            "companyName": "NATALCO",
            "country": "S"
        },
        {
            "name": "Natioanl Air Traffic Controllers Association",
            "code": "",
            "auxcode": "NTK",
            "companyName": "NATCA",
            "country": "United States"
        },
        {
            "name": "National Air Charter",
            "code": "",
            "auxcode": "NSR",
            "companyName": "NASAIR",
            "country": "Indonesia"
        },
        {
            "name": "National Air Traffic Services",
            "code": "",
            "auxcode": "RFI",
            "companyName": "SHERLOCK",
            "country": "United Kingdom"
        },
        {
            "name": "National Airlines",
            "code": "",
            "auxcode": "NAN",
            "companyName": "NATION AIR",
            "country": "United States"
        },
        {
            "name": "National Airlines",
            "code": "N4",
            "auxcode": "NCN",
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "National Airlines",
            "code": "N7",
            "auxcode": "ROK",
            "companyName": "RED ROCK",
            "country": "United States"
        },
        {
            "name": "National Airlines",
            "code": "NA",
            "auxcode": "NAL",
            "companyName": "NATIONAL",
            "country": "United States"
        },
        {
            "name": "National Airlines",
            "code": "",
            "auxcode": "KUS",
            "companyName": "KUSWAG",
            "country": "South Africa"
        },
        {
            "name": "National Airways Cameroon",
            "code": "9O",
            "auxcode": "",
            "companyName": "",
            "country": "Cameroon"
        },
        {
            "name": "National Airways Corporation",
            "code": "",
            "auxcode": "LFI",
            "companyName": "AEROMED",
            "country": "South Africa"
        },
        {
            "name": "National Aviation Company",
            "code": "",
            "auxcode": "GTY",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "National Aviation Consultants",
            "code": "",
            "auxcode": "TNC",
            "companyName": "NATCOM",
            "country": "Canada"
        },
        {
            "name": "National Express",
            "code": "",
            "auxcode": "NXT",
            "companyName": "NATIONAL FREIGHT",
            "country": "United States"
        },
        {
            "name": "National Grid",
            "code": "",
            "auxcode": "GRD",
            "companyName": "GRID",
            "country": "United Kingdom"
        },
        {
            "name": "National Jet Express",
            "code": "",
            "auxcode": "JTE",
            "companyName": "JETEX",
            "country": "Australia"
        },
        {
            "name": "National Jet Service",
            "code": "",
            "auxcode": "AND",
            "companyName": "AIR INDIANA",
            "country": "United States"
        },
        {
            "name": "National Jet Systems",
            "code": "NC",
            "auxcode": "NJS",
            "companyName": "NATIONAL JET",
            "country": "Australia"
        },
        {
            "name": "National Oceanic and Atmospheric Administration",
            "code": "",
            "auxcode": "NAA",
            "companyName": "NOAA",
            "country": "United States"
        },
        {
            "name": "National Overseas Airlines Company",
            "code": "",
            "auxcode": "NOL",
            "companyName": "NAT AIRLINE",
            "country": "Egypt"
        },
        {
            "name": "Nationale Luchtvaartschool",
            "code": "",
            "auxcode": "NLS",
            "companyName": "PANDER",
            "country": "Netherlands"
        },
        {
            "name": "Nations Air Express Inc",
            "code": "",
            "auxcode": "NAE",
            "companyName": "NATIONS EXPRESS",
            "country": "United States"
        },
        {
            "name": "Nationwide Airlines",
            "code": "CE",
            "auxcode": "NTW",
            "companyName": "NATIONWIDE",
            "country": "South Africa"
        },
        {
            "name": "Nationwide Airlines (Zambia)",
            "code": "",
            "auxcode": "NWZ",
            "companyName": "ZAMNAT",
            "country": "Zambia"
        },
        {
            "name": "Natural Environment Research Council",
            "code": "",
            "auxcode": "EVM",
            "companyName": "SCIENCE",
            "country": "United Kingdom"
        },
        {
            "name": "Natureair",
            "code": "",
            "auxcode": "NRR",
            "companyName": "NATUREAIR",
            "country": "Costa Rica"
        },
        {
            "name": "Naturelink Charter",
            "code": "",
            "auxcode": "NRK",
            "companyName": "NATURELINK",
            "country": "South Africa"
        },
        {
            "name": "Nauru Air Corporation",
            "code": "ON",
            "auxcode": "RON",
            "companyName": "AIR NAURU",
            "country": "Nauru"
        },
        {
            "name": "NAV CANADA",
            "code": "",
            "auxcode": "NVC",
            "companyName": "NAV CAN",
            "country": "Canada"
        },
        {
            "name": "Nav Flight Planning",
            "code": "",
            "auxcode": "NAV",
            "companyName": "NAV DISPATCH",
            "country": "Czech Republic"
        },
        {
            "name": "Navegacao Aerea De Portugal",
            "code": "",
            "auxcode": "NVP",
            "companyName": "",
            "country": "Portugal"
        },
        {
            "name": "Navegacion Servicios Aereos Canarios SA",
            "code": "",
            "auxcode": "NAY",
            "companyName": "NAYSA",
            "country": "Spain"
        },
        {
            "name": "Navid",
            "code": "",
            "auxcode": "IRI",
            "companyName": "NAVID",
            "country": "Iran"
        },
        {
            "name": "Naviera Mexicana",
            "code": "",
            "auxcode": "NVM",
            "companyName": "NAVIERA",
            "country": "Mexico"
        },
        {
            "name": "Navigator Airlines",
            "code": "",
            "auxcode": "NVL",
            "companyName": "NAVLINES",
            "country": "Armenia"
        },
        {
            "name": "Navinc Airlines Services",
            "code": "",
            "auxcode": "XNV",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Navitaire",
            "code": "1N",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Navtech System Support",
            "code": "",
            "auxcode": "XNS",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Nayzak Air Transport",
            "code": "",
            "auxcode": "NZA",
            "companyName": "",
            "country": "Libya"
        },
        {
            "name": "State of Nebraska",
            "code": "",
            "auxcode": "NEB",
            "companyName": "NEBRASKA",
            "country": "United States"
        },
        {
            "name": "Necon Air",
            "code": "",
            "auxcode": "NEC",
            "companyName": "NECON AIR",
            "country": "Nepal"
        },
        {
            "name": "Nederlandse Kustwacht",
            "code": "",
            "auxcode": "NCG",
            "companyName": "NETHERLANDS COASTGUARD",
            "country": "Netherlands"
        },
        {
            "name": "Nefteyugansk Aviation Division",
            "code": "",
            "auxcode": "NFT",
            "companyName": "NEFTEAVIA",
            "country": "Russia"
        },
        {
            "name": "Neiltown Air",
            "code": "",
            "auxcode": "NLA",
            "companyName": "NEILTOWN AIR",
            "country": "Canada"
        },
        {
            "name": "Nelair Charters",
            "code": "",
            "auxcode": "NLC",
            "companyName": "NELAIR",
            "country": "South Africa"
        },
        {
            "name": "Nelson Aviation College",
            "code": "",
            "auxcode": "CGE",
            "companyName": "COLLEGE",
            "country": "New Zealand"
        },
        {
            "name": "Nepal Airlines",
            "code": "RA",
            "auxcode": "RNA",
            "companyName": "ROYAL NEPAL",
            "country": "Nepal"
        },
        {
            "name": "Neos",
            "code": "NO",
            "auxcode": "NOS",
            "companyName": "MOONFLOWER",
            "country": "Italy"
        },
        {
            "name": "Neosiam Airways",
            "code": "",
            "auxcode": "TOX",
            "companyName": "SKY KINGDOM",
            "country": "Thailand"
        },
        {
            "name": "Neric",
            "code": "",
            "auxcode": "NSL",
            "companyName": "NERICAIR",
            "country": "United Kingdom"
        },
        {
            "name": "NetJets",
            "code": "1I",
            "auxcode": "EJA",
            "companyName": "EXECJET",
            "country": "United States"
        },
        {
            "name": "Network Aviation Services",
            "code": "",
            "auxcode": "NET",
            "companyName": "NETWORK",
            "country": "Nigeria"
        },
        {
            "name": "New England Air Express",
            "code": "",
            "auxcode": "NEZ",
            "companyName": "ENGAIR",
            "country": "United States"
        },
        {
            "name": "New England Airlines",
            "code": "EJ",
            "auxcode": "NEA",
            "companyName": "NEW ENGLAND",
            "country": "United States"
        },
        {
            "name": "New Heights 291",
            "code": "",
            "auxcode": "NHT",
            "companyName": "NEWHEIGHTS",
            "country": "South Africa"
        },
        {
            "name": "New World Jet Corporation",
            "code": "",
            "auxcode": "NWD",
            "companyName": "NEW WORLD",
            "country": "United States"
        },
        {
            "name": "New York Helicopter",
            "code": "",
            "auxcode": "NYH",
            "companyName": "NEW YORK",
            "country": "United States"
        },
        {
            "name": "New York State Police",
            "code": "",
            "auxcode": "GRY",
            "companyName": "GRAY RIDER",
            "country": "United States"
        },
        {
            "name": "New Zealand Air Defence Force",
            "code": "",
            "auxcode": "KRC",
            "companyName": "KIWI RESCUE",
            "country": "New Zealand"
        },
        {
            "name": "Newair",
            "code": "",
            "auxcode": "HVA",
            "companyName": "HAVENcodeAIR",
            "country": "United States"
        },
        {
            "name": "Newfoundland Labrador Air Transport",
            "code": "",
            "auxcode": "NLT",
            "companyName": "NALAIR",
            "country": "Canada"
        },
        {
            "name": "NextJet",
            "code": "2N",
            "auxcode": "NTJ",
            "companyName": "NEXTJET",
            "country": "Sweden"
        },
        {
            "name": "Nextflight Aviation",
            "code": "",
            "auxcode": "NXF",
            "companyName": "NEXTFLIGHT",
            "country": "United States"
        },
        {
            "name": "Nexus Aviation",
            "code": "",
            "auxcode": "NXS",
            "companyName": "NEXUS AVIATION",
            "country": "Nigeria"
        },
        {
            "name": "Nicaraguense De Aviacion",
            "code": "",
            "auxcode": "NIS",
            "companyName": "NICA",
            "country": "Nicaragua"
        },
        {
            "name": "Nicon Airways",
            "code": "",
            "auxcode": "NCN",
            "companyName": "NICON AIRWAYS",
            "country": "Nigeria"
        },
        {
            "name": "Nigeria Airways",
            "code": "",
            "auxcode": "NGA",
            "companyName": "NIGERIA",
            "country": "Nigeria"
        },
        {
            "name": "Nigerian Air Force",
            "code": "",
            "auxcode": "NGR",
            "companyName": "NIGERIAN AIRFORCE",
            "country": "Nigeria"
        },
        {
            "name": "Nigerian Global",
            "code": "",
            "auxcode": "NGX",
            "companyName": "AIR GLOBAL",
            "country": "Nigeria"
        },
        {
            "name": "Night Express",
            "code": "",
            "auxcode": "EXT",
            "companyName": "EXECUTIVE",
            "country": "Germany"
        },
        {
            "name": "Niki",
            "code": "HG",
            "auxcode": "NLY",
            "companyName": "FLYNIKI",
            "country": "Austria"
        },
        {
            "name": "NikolaevcodeAir",
            "code": "",
            "auxcode": "NKV",
            "companyName": "AIR NIKOLAEV",
            "country": "Ukraine"
        },
        {
            "name": "Nile Safaris Aviation",
            "code": "",
            "auxcode": "NSA",
            "companyName": "NILE SAFARIS",
            "country": "Sudan"
        },
        {
            "name": "Nile Valley Aviation Company",
            "code": "",
            "auxcode": "NVA",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Nile Wings Aviation Services",
            "code": "",
            "auxcode": "NLW",
            "companyName": "NILE WINGS",
            "country": "Sudan"
        },
        {
            "name": "Nimbus Aviation",
            "code": "",
            "auxcode": "NBS",
            "companyName": "NIMBUS",
            "country": "United Kingdom"
        },
        {
            "name": "Nippon Cargo Airlines",
            "code": "KZ",
            "auxcode": "NCA",
            "companyName": "NIPPON CARGO",
            "country": "Japan"
        },
        {
            "name": "Nizhnevartovskavia",
            "code": "",
            "auxcode": "NVK",
            "companyName": "VARTOSKAVIA",
            "country": "Russia"
        },
        {
            "name": "Search and Rescue 202",
            "code": "",
            "auxcode": "SRG",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Search and Rescue 22",
            "code": "",
            "auxcode": "SRD",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "No. 32 (The Royal) Squadron RAF",
            "code": "",
            "auxcode": "NOH",
            "companyName": "NORTHOLT",
            "country": "United Kingdom"
        },
        {
            "name": "84 Squadron Royal Air Force @ RAF Akrotiri",
            "code": "",
            "auxcode": "AKG",
            "companyName": "GRIFTER",
            "country": "United Kingdom"
        },
        {
            "name": "Nobil Air",
            "code": "",
            "auxcode": "NBL",
            "companyName": "NOBIL AIR",
            "country": "Moldova"
        },
        {
            "name": "Nok Air",
            "code": "DD",
            "auxcode": "NOK",
            "companyName": "NOK AIR",
            "country": "Thailand"
        },
        {
            "name": "Nolinor Aviation",
            "code": "",
            "auxcode": "NRL",
            "companyName": "NOLINOR",
            "country": "Canada"
        },
        {
            "name": "Nomad Aviation",
            "code": "",
            "auxcode": "NMD",
            "companyName": "NOMAD AIR",
            "country": "Namibia"
        },
        {
            "name": "Norcopter",
            "code": "",
            "auxcode": "NOC",
            "companyName": "",
            "country": "Norway"
        },
        {
            "name": "NordcodeFlyg",
            "code": "",
            "auxcode": "NEF",
            "companyName": "NORDEX",
            "country": "Sweden"
        },
        {
            "name": "Nordeste Linhas Aereas Regionais",
            "code": "JH",
            "auxcode": "NES",
            "companyName": "NORDESTE",
            "country": "Brazil"
        },
        {
            "name": "Nordic Regional",
            "code": "6N",
            "auxcode": "NRD",
            "companyName": "NORTH RIDER",
            "country": "Sweden"
        },
        {
            "name": "Nordic Solutions",
            "code": "",
            "auxcode": "NVD",
            "companyName": "NORDVIND",
            "country": "Lithuania"
        },
        {
            "name": "Nordstree (Australia)",
            "code": "",
            "auxcode": "NDS",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Norestair",
            "code": "",
            "auxcode": "NRT",
            "companyName": "NORESTAIR",
            "country": "Spain"
        },
        {
            "name": "Norfolk County Flight College",
            "code": "",
            "auxcode": "NCF",
            "companyName": "COUNTY",
            "country": "United Kingdom"
        },
        {
            "name": "Norontair",
            "code": "",
            "auxcode": "NOA",
            "companyName": "NORONTAIR",
            "country": "Canada"
        },
        {
            "name": "Norrlandsflyg",
            "code": "",
            "auxcode": "HMF",
            "companyName": "LIFEGUARD SWEDEN",
            "country": "Sweden"
        },
        {
            "name": "Norse Air Charter",
            "code": "",
            "auxcode": "NRX",
            "companyName": "NORSE AIR",
            "country": "South Africa"
        },
        {
            "name": "Norsk Flyrjeneste",
            "code": "",
            "auxcode": "NIR",
            "companyName": "NORSEMAN",
            "country": "Norway"
        },
        {
            "name": "Norsk Helikopter",
            "code": "",
            "auxcode": "NOR",
            "companyName": "NORSKE",
            "country": "Norway"
        },
        {
            "name": "Norsk Luftambulanse",
            "code": "",
            "auxcode": "DOC",
            "companyName": "HELIDOC",
            "country": "Norway"
        },
        {
            "name": "Nortavia",
            "code": "",
            "auxcode": "RTV",
            "companyName": "TICcodeTAC",
            "country": "Portugal"
        },
        {
            "name": "North Adria Aviation",
            "code": "",
            "auxcode": "NAI",
            "companyName": "NORTHcodeADRIA",
            "country": "Croatia"
        },
        {
            "name": "North American Airlines",
            "code": "",
            "auxcode": "NTM",
            "companyName": "NORTHAM",
            "country": "Canada"
        },
        {
            "name": "North American Charters",
            "code": "",
            "auxcode": "HMR",
            "companyName": "HAMMER",
            "country": "Canada"
        },
        {
            "name": "North American Jet Charter Group",
            "code": "",
            "auxcode": "NAJ",
            "companyName": "JET GROUP",
            "country": "United States"
        },
        {
            "name": "North Atlantic Air Inc",
            "code": "",
            "auxcode": "NAT",
            "companyName": "MASS AIR",
            "country": "United States"
        },
        {
            "name": "North Atlantic Cargo",
            "code": "",
            "auxcode": "NFC",
            "companyName": "NORTH ATLANTIC",
            "country": "Norway"
        },
        {
            "name": "North British Airlines",
            "code": "",
            "auxcode": "NBN",
            "companyName": "TEESAIR",
            "country": "United Kingdom"
        },
        {
            "name": "North Caribou Flying Service Ltd",
            "code": "",
            "auxcode": "NCB",
            "companyName": "NORTH CARIBOU",
            "country": "Canada"
        },
        {
            "name": "North Coast Air Services Ltd",
            "code": "",
            "auxcode": "NCC",
            "companyName": "NORTH COAST",
            "country": "Canada"
        },
        {
            "name": "North Coast Aviation",
            "code": "N9",
            "auxcode": "",
            "companyName": "",
            "country": "Papua New Guinea"
        },
        {
            "name": "North Flying",
            "code": "M3",
            "auxcode": "NFA",
            "companyName": "NORTH FLYING",
            "country": "Denmark"
        },
        {
            "name": "North Sea Airways",
            "code": "",
            "auxcode": "NRC",
            "companyName": "NORTH SEA",
            "country": "Netherlands"
        },
        {
            "name": "North Star Air Cargo",
            "code": "",
            "auxcode": "SBX",
            "companyName": "SKY BOX",
            "country": "United States"
        },
        {
            "name": "North Vancouver Airlines",
            "code": "",
            "auxcode": "NRV",
            "companyName": "NORVAN",
            "country": "Canada"
        },
        {
            "name": "North West Airlines",
            "code": "",
            "auxcode": "NWW",
            "companyName": "HALANT",
            "country": "Australia"
        },
        {
            "name": "North West Geomatics",
            "code": "",
            "auxcode": "PTO",
            "companyName": "PHOTO",
            "country": "Canada"
        },
        {
            "name": "NorthcodeEast Airlines",
            "code": "",
            "auxcode": "NEN",
            "companyName": "NORTHEAST SWAN",
            "country": "Nigeria"
        },
        {
            "name": "NorthcodeWest Air Transport Company code Vyborg",
            "code": "",
            "auxcode": "VBG",
            "companyName": "VYBORG AIR",
            "country": "Russia"
        },
        {
            "name": "NorthcodeWright Airways",
            "code": "HW",
            "auxcode": "NWL",
            "companyName": "NORTHWRIGHT",
            "country": "Canada"
        },
        {
            "name": "Northafrican Air Transport",
            "code": "",
            "auxcode": "NLL",
            "companyName": "NORTHAFRICAN AIR",
            "country": "Libya"
        },
        {
            "name": "Northaire Feight Lines",
            "code": "",
            "auxcode": "NFL",
            "companyName": "GREAT LAKES",
            "country": "United States"
        },
        {
            "name": "Northamptonshire School of Flying",
            "code": "",
            "auxcode": "NSF",
            "companyName": "NORTON",
            "country": "United Kingdom"
        },
        {
            "name": "Northcoast Executive Airlines",
            "code": "",
            "auxcode": "NCE",
            "companyName": "TOP HAT",
            "country": "United States"
        },
        {
            "name": "Northeast Airlines",
            "code": "",
            "auxcode": "NEE",
            "companyName": "NORTHEAST",
            "country": "United States"
        },
        {
            "name": "Northeast Aviation",
            "code": "",
            "auxcode": "NPX",
            "companyName": "NORTHEAST EXPRESS",
            "country": "United States"
        },
        {
            "name": "Northern Air Cargo",
            "code": "NC",
            "auxcode": "NAC",
            "companyName": "YUKON",
            "country": "United States"
        },
        {
            "name": "Northern Airlines Sanya",
            "code": "",
            "auxcode": "BYC",
            "companyName": "BEIYA",
            "country": "China"
        },
        {
            "name": "Northern Airways",
            "code": "",
            "auxcode": "NDA",
            "companyName": "NORTHERN DAKOTA",
            "country": "United States"
        },
        {
            "name": "Northern Aviation Service",
            "code": "",
            "auxcode": "CMU",
            "companyName": "LANNA AIR",
            "country": "Thailand"
        },
        {
            "name": "Northern Dene Airways",
            "code": "U7",
            "auxcode": "",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Northern Executive Aviation",
            "code": "",
            "auxcode": "NEX",
            "companyName": "NEATAX",
            "country": "United Kingdom"
        },
        {
            "name": "Northern Illinois Commuter",
            "code": "",
            "auxcode": "NIC",
            "companyName": "ILLINOIS COMMUTER",
            "country": "United States"
        },
        {
            "name": "Northern Jet Management",
            "code": "",
            "auxcode": "NTX",
            "companyName": "NORTAX",
            "country": "United States"
        },
        {
            "name": "Northern Thunderbird Air",
            "code": "",
            "auxcode": "NTA",
            "companyName": "THUNDERBIRD",
            "country": "Canada"
        },
        {
            "name": "Northland Aviation",
            "code": "",
            "auxcode": "KOE",
            "companyName": "KOKEE",
            "country": "United States"
        },
        {
            "name": "Northstar Aviation",
            "code": "",
            "auxcode": "NSS",
            "companyName": "NORTHSTAR",
            "country": "United States"
        },
        {
            "name": "Northumbria Helicopters",
            "code": "",
            "auxcode": "NHL",
            "companyName": "NORTHUMBRIA",
            "country": "United Kingdom"
        },
        {
            "name": "Northway Aviation Ltd",
            "code": "",
            "auxcode": "NAL",
            "companyName": "NORTHWAY",
            "country": "Canada"
        },
        {
            "name": "Northwest Aero Associates",
            "code": "",
            "auxcode": "NWE",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Northwest Airlines",
            "code": "NW",
            "auxcode": "NWA",
            "companyName": "NORTHWEST",
            "country": "United States"
        },
        {
            "name": "Northwest Regional Airlines",
            "code": "FY",
            "auxcode": "NWR",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Northwest Territorial Airways",
            "code": "",
            "auxcode": "NWT",
            "companyName": "TERRITORIAL",
            "country": "Canada"
        },
        {
            "name": "Northwestern Air",
            "code": "J3",
            "auxcode": "PLR",
            "companyName": "POLARIS",
            "country": "Canada"
        },
        {
            "name": "Northwinds Northern",
            "code": "",
            "auxcode": "NWN",
            "companyName": "NORTHWINDS",
            "country": "Canada"
        },
        {
            "name": "Nortland Air Manitoba",
            "code": "",
            "auxcode": "NAM",
            "companyName": "MANITOBA",
            "country": "Canada"
        },
        {
            "name": "Norwegian Air Shuttle",
            "code": "DY",
            "auxcode": "NAX",
            "companyName": "NOR SHUTTLE",
            "country": "Norway"
        },
        {
            "name": "Norwegian Aviation College",
            "code": "",
            "auxcode": "TFN",
            "companyName": "SPRIT",
            "country": "Norway"
        },
        {
            "name": "Notams International",
            "code": "",
            "auxcode": "XNT",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Nouvel Air Tunisie",
            "code": "BJ",
            "auxcode": "LBT",
            "companyName": "NOUVELAIR",
            "country": "Tunisia"
        },
        {
            "name": "Nova Airline",
            "code": "M4",
            "auxcode": "NOV",
            "companyName": "NOVANILE",
            "country": "Sudan"
        },
        {
            "name": "Nova Scotia Department of Lands and Forests",
            "code": "",
            "auxcode": "PTR",
            "companyName": "PATROL",
            "country": "Canada"
        },
        {
            "name": "Novair",
            "code": "1I",
            "auxcode": "NVR",
            "companyName": "NAVIGATOR",
            "country": "Sweden"
        },
        {
            "name": "Novogorod Air Enterprise",
            "code": "",
            "auxcode": "NVG",
            "companyName": "SADKO AVIA",
            "country": "Russia"
        },
        {
            "name": "Novosibirsk Aircraft Repairing Plant",
            "code": "",
            "auxcode": "NSP",
            "companyName": "NARPAIR",
            "country": "Russia"
        },
        {
            "name": "Novosibirsk Aviaenterprise",
            "code": "",
            "auxcode": "NBE",
            "companyName": "NAKAIR",
            "country": "Russia"
        },
        {
            "name": "Novosibirsk Aviation Production Association",
            "code": "",
            "auxcode": "NPO",
            "companyName": "NOVSIB",
            "country": "Russia"
        },
        {
            "name": "Noy Aviation",
            "code": "",
            "auxcode": "NOY",
            "companyName": "NOY AVIATION",
            "country": "Israel"
        },
        {
            "name": "Nuevo Continente",
            "code": "N6",
            "auxcode": "ACQ",
            "companyName": "AERO CONTINENTE",
            "country": "Peru"
        },
        {
            "name": "Nuevo Horizonte Internacional",
            "code": "",
            "auxcode": "NHR",
            "companyName": "NUEVO HORIZONTE",
            "country": "Mexico"
        },
        {
            "name": "NunasicodeCentral Airlines",
            "code": "",
            "auxcode": "NUN",
            "companyName": "NUNASI",
            "country": "Canada"
        },
        {
            "name": "Nurman Avia Indopura",
            "code": "",
            "auxcode": "NIN",
            "companyName": "NURVINDO",
            "country": "Indonesia"
        },
        {
            "name": "Nyasa Express",
            "code": "",
            "auxcode": "NYS",
            "companyName": "NYASA",
            "country": "Malawi"
        },
        {
            "name": "Nas Air",
            "code": "XY",
            "auxcode": "KNE",
            "companyName": "NAS EXPRESS",
            "country": "Saudi Arabia"
        },
        {
            "name": "O Air",
            "code": "",
            "auxcode": "OCN",
            "companyName": "OcodeBIRD",
            "country": "France"
        },
        {
            "name": "O'Connor Airlines",
            "code": "UQ",
            "auxcode": "OCM",
            "companyName": "OCONNOR",
            "country": "Australia"
        },
        {
            "name": "OAG",
            "code": "CR",
            "auxcode": "",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "OSACOM",
            "code": "",
            "auxcode": "JPA",
            "companyName": "JcodePAT",
            "country": "United States"
        },
        {
            "name": "Oasis Hong Kong Airlines",
            "code": "O8",
            "auxcode": "OHK",
            "companyName": "OASIS",
            "country": "Hong Kong"
        },
        {
            "name": "Ocean Air",
            "code": "",
            "auxcode": "BCN",
            "companyName": "BLUE OCEAN",
            "country": "Mauritania"
        },
        {
            "name": "Ocean Airlines",
            "code": "VC",
            "auxcode": "VCX",
            "companyName": "OCEANCARGO",
            "country": "Italy"
        },
        {
            "name": "Ocean Sky (UK)",
            "code": "",
            "auxcode": "OCS",
            "companyName": "OCEANSKY",
            "country": "United Kingdom"
        },
        {
            "name": "Ocean Wings Commuter Service",
            "code": "",
            "auxcode": "TUK",
            "companyName": "TUCKERNUCK",
            "country": "United States"
        },
        {
            "name": "Oceanair",
            "code": "O6",
            "auxcode": "ONE",
            "companyName": "OCEANAIR",
            "country": "Brazil"
        },
        {
            "name": "Oceanic Airlines",
            "code": "O2",
            "auxcode": "",
            "companyName": "",
            "country": "Guinea"
        },
        {
            "name": "Odessa Airlines",
            "code": "",
            "auxcode": "ODS",
            "companyName": "ODESSA AIR",
            "country": "Ukraine"
        },
        {
            "name": "Odyssey International",
            "code": "",
            "auxcode": "ODY",
            "companyName": "ODYSSEY",
            "country": "Canada"
        },
        {
            "name": "Office Federal De'Aviation Civile",
            "code": "",
            "auxcode": "FOC",
            "companyName": "FOCA",
            "country": "Switzerland"
        },
        {
            "name": "Ogooue Air Cargo",
            "code": "",
            "auxcode": "GBO",
            "companyName": "",
            "country": "Gabon"
        },
        {
            "name": "Okada Airlines",
            "code": "",
            "auxcode": "OKJ",
            "companyName": "OKADA AIR",
            "country": "Nigeria"
        },
        {
            "name": "Okapi Airways",
            "code": "",
            "auxcode": "OKP",
            "companyName": "OKAPI",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Okay Airways",
            "code": "",
            "auxcode": "OKA",
            "companyName": "OKAYJET",
            "country": "China"
        },
        {
            "name": "Oklahoma Department of Public Safety",
            "code": "",
            "auxcode": "OKL",
            "companyName": "OKLAHOMA",
            "country": "United States"
        },
        {
            "name": "Olimex Aerotaxi",
            "code": "",
            "auxcode": "OLX",
            "companyName": "OLIMEX",
            "country": "Czech Republic"
        },
        {
            "name": "Olimp Air",
            "code": "",
            "auxcode": "KVK",
            "companyName": "PONTA",
            "country": "Kazakhstan"
        },
        {
            "name": "Olympic Airlines",
            "code": "OA",
            "auxcode": "OAL",
            "companyName": "OLYMPIC",
            "country": "Greece"
        },
        {
            "name": "Olympic Aviation",
            "code": "",
            "auxcode": "OLY",
            "companyName": "OLAVIA",
            "country": "Greece"
        },
        {
            "name": "Oman Air",
            "code": "WY",
            "auxcode": "OMA",
            "companyName": "OMAN AIR",
            "country": "Oman"
        },
        {
            "name": "Oman Royal Flight",
            "code": "",
            "auxcode": "ORF",
            "companyName": "OMAN",
            "country": "Oman"
        },
        {
            "name": "Omni code Aviacao e Tecnologia",
            "code": "",
            "auxcode": "OAV",
            "companyName": "OMNI",
            "country": "Portugal"
        },
        {
            "name": "Omni Air International",
            "code": "OY",
            "auxcode": "OAE",
            "companyName": "OMNIcodeEXPRESS",
            "country": "United States"
        },
        {
            "name": "Omniflys",
            "code": "",
            "auxcode": "OMF",
            "companyName": "OMNIFLYS",
            "country": "Mexico"
        },
        {
            "name": "Omskavia Airline",
            "code": "N3",
            "auxcode": "OMS",
            "companyName": "OMSK",
            "country": "Russia"
        },
        {
            "name": "On Air Limited",
            "code": "",
            "auxcode": "ORL",
            "companyName": "ON AIR",
            "country": "Canada"
        },
        {
            "name": "One Two Go Airlines",
            "code": "",
            "auxcode": "OTG",
            "companyName": "THAI EXPRESS",
            "country": "Thailand"
        },
        {
            "name": "Onetime Airlines Zambia",
            "code": "",
            "auxcode": "OTM",
            "companyName": "ZEDTIME",
            "country": "Zambia"
        },
        {
            "name": "Ontario Ministry of Health",
            "code": "",
            "auxcode": "MED",
            "companyName": "MEDICAL",
            "country": "Canada"
        },
        {
            "name": "Onur Air",
            "code": "8Q",
            "auxcode": "OHY",
            "companyName": "ONUR AIR",
            "country": "Turkey"
        },
        {
            "name": "Opal Air",
            "code": "",
            "auxcode": "OPA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Open Sky Aviation",
            "code": "",
            "auxcode": "OSA",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "OpenSkies",
            "code": "",
            "auxcode": "BOS",
            "companyName": "MISTRAL",
            "country": "United Kingdom"
        },
        {
            "name": "Operadora Turistica Aurora",
            "code": "",
            "auxcode": "ORR",
            "companyName": "TURISTICA AURORA",
            "country": "Mexico"
        },
        {
            "name": "Operadora de Lineas Ejecutivas",
            "code": "",
            "auxcode": "OLE",
            "companyName": "OPERADORA",
            "country": "Mexico"
        },
        {
            "name": "Operadora de Transportes Aereos",
            "code": "",
            "auxcode": "OTP",
            "companyName": "OPERADORA AEREO",
            "country": "Mexico"
        },
        {
            "name": "Operadora de Veulos Ejectutivos",
            "code": "",
            "auxcode": "OPV",
            "companyName": "OPERADORA DE VUELOS",
            "country": "Mexico"
        },
        {
            "name": "Operation Enduring Freedom",
            "code": "",
            "auxcode": "LLO",
            "companyName": "APOLLO",
            "country": "Canada"
        },
        {
            "name": "Operational Aviation Services",
            "code": "",
            "auxcode": "OAX",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Orange Air Services",
            "code": "",
            "auxcode": "ORD",
            "companyName": "ORANGE SERVICES",
            "country": "Sierra Leone"
        },
        {
            "name": "Orange Air Sierra Leone",
            "code": "",
            "auxcode": "ORJ",
            "companyName": "ORANGE SIERRA",
            "country": "Sierra Leone"
        },
        {
            "name": "Orange Aviation",
            "code": "",
            "auxcode": "ORE",
            "companyName": "ORANGE AVIATION",
            "country": "Israel"
        },
        {
            "name": "Orbit Express Airlines",
            "code": "",
            "auxcode": "ORX",
            "companyName": "OREX",
            "country": "Turkey"
        },
        {
            "name": "Orca Air",
            "code": "",
            "auxcode": "ORK",
            "companyName": "ORCA TAXI",
            "country": "Egypt"
        },
        {
            "name": "Orebro Aviation",
            "code": "",
            "auxcode": "BUE",
            "companyName": "BLUELIGHT",
            "country": "Sweden"
        },
        {
            "name": "Orel State Air Enterprise",
            "code": "",
            "auxcode": "ORM",
            "companyName": "ORPRISE",
            "country": "Russia"
        },
        {
            "name": "Orenburg Airlines",
            "code": "R2",
            "auxcode": "ORB",
            "companyName": "ORENBURG",
            "country": "Russia"
        },
        {
            "name": "Organizacion De Transportes Aereos",
            "code": "",
            "auxcode": "OTA",
            "companyName": "ORGANIZACION",
            "country": "Mexico"
        },
        {
            "name": "Organizacoes Mambra",
            "code": "",
            "auxcode": "OML",
            "companyName": "MAMBRA",
            "country": "Angola"
        },
        {
            "name": "Orient Air",
            "code": "",
            "auxcode": "OVV",
            "companyName": "ORIENTSYR",
            "country": "Syrian Arab Republic"
        },
        {
            "name": "Orient Airlines",
            "code": "",
            "auxcode": "OTR",
            "companyName": "ORIENTROC",
            "country": "Sudan"
        },
        {
            "name": "Orient Airways",
            "code": "",
            "auxcode": "ORN",
            "companyName": "ORIENT LINER",
            "country": "Pakistan"
        },
        {
            "name": "Orient Thai Airlines",
            "code": "OX",
            "auxcode": "OEA",
            "companyName": "ORIENT THAI",
            "country": "Thailand"
        },
        {
            "name": "Oriental Air Bridge",
            "code": "",
            "auxcode": "NGK",
            "companyName": "ORIENTAL BRIDGE",
            "country": "Japan"
        },
        {
            "name": "Oriental Airlines",
            "code": "",
            "auxcode": "OAC",
            "companyName": "ORIENTAL AIR",
            "country": "Nigeria"
        },
        {
            "name": "Origin Pacific Airways",
            "code": "QO",
            "auxcode": "OGN",
            "companyName": "ORIGIN",
            "country": "New Zealand"
        },
        {
            "name": "Orion Air Charter",
            "code": "",
            "auxcode": "OED",
            "companyName": "ORION CHARTER",
            "country": "South Africa"
        },
        {
            "name": "Orioncodex",
            "code": "",
            "auxcode": "OIX",
            "companyName": "ORIONIX",
            "country": "Russia"
        },
        {
            "name": "Orlancode2000",
            "code": "",
            "auxcode": "KOV",
            "companyName": "ORLAN",
            "country": "Kazakhstan"
        },
        {
            "name": "Ornage Aircraft Leasing",
            "code": "",
            "auxcode": "RNG",
            "companyName": "ORANGE",
            "country": "Netherlands"
        },
        {
            "name": "Orscom Tourist Installations Company",
            "code": "",
            "auxcode": "OAD",
            "companyName": "ORSCOM",
            "country": "Egypt"
        },
        {
            "name": "Osh Avia",
            "code": "",
            "auxcode": "OSH",
            "companyName": "OSH AVIA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Ostend Air College",
            "code": "",
            "auxcode": "OCO",
            "companyName": "AIR COLLEGE",
            "country": "Belgium"
        },
        {
            "name": "Ostfriesische Lufttransport",
            "code": "OL",
            "auxcode": "OLT",
            "companyName": "OLTRA",
            "country": "Germany"
        },
        {
            "name": "Oulun Tilauslento",
            "code": "",
            "auxcode": "FNL",
            "companyName": "FINN FLIGHT",
            "country": "Finland"
        },
        {
            "name": "Our Airline",
            "code": "ON",
            "auxcode": "RON",
            "companyName": "OUR AIRLINE",
            "country": "Nauru"
        },
        {
            "name": "Out Of The Blue Air Safaris",
            "code": "",
            "auxcode": "OOT",
            "companyName": "OOTBAS",
            "country": "South Africa"
        },
        {
            "name": "Overland Airways",
            "code": "OJ",
            "auxcode": "OLA",
            "companyName": "OVERLAND",
            "country": "Nigeria"
        },
        {
            "name": "Oxaero",
            "code": "",
            "auxcode": "OXE",
            "companyName": "OXOE",
            "country": "United Kingdom"
        },
        {
            "name": "Oxford Air Services",
            "code": "",
            "auxcode": "WDK",
            "companyName": "WOODSTOCK",
            "country": "United Kingdom"
        },
        {
            "name": "Oxley Aviation",
            "code": "",
            "auxcode": "OAA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Ozark Air Lines",
            "code": "OZ",
            "auxcode": "OZR",
            "companyName": "OZARK",
            "country": "United States"
        },
        {
            "name": "Ozjet Airlines",
            "code": "O7",
            "auxcode": "OZJ",
            "companyName": "AUSJET",
            "country": "Australia"
        },
        {
            "name": "P & P Floss Pick Manufacturers",
            "code": "",
            "auxcode": "KTL",
            "companyName": "KNOTTSBERRY",
            "country": "South Africa"
        },
        {
            "name": "PAC Air",
            "code": "",
            "auxcode": "PCR",
            "companyName": "PACAIR",
            "country": "United States"
        },
        {
            "name": "PAN Air",
            "code": "PV",
            "auxcode": "PNR",
            "companyName": "SKYJET",
            "country": "Spain"
        },
        {
            "name": "PB Air",
            "code": "9Q",
            "auxcode": "PBA",
            "companyName": "PEEBEE AIR",
            "country": "Thailand"
        },
        {
            "name": "PDQ Air Charter",
            "code": "",
            "auxcode": "PDQ",
            "companyName": "DISPATCH",
            "country": "United States"
        },
        {
            "name": "PHH Aviation System",
            "code": "",
            "auxcode": "XAS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "PLM Dollar Group",
            "code": "",
            "auxcode": "PDG",
            "companyName": "OSPREY",
            "country": "United Kingdom"
        },
        {
            "name": "PLUNA",
            "code": "PU",
            "auxcode": "PUA",
            "companyName": "PLUNA",
            "country": "Uruguay"
        },
        {
            "name": "PMTair",
            "code": "U4",
            "auxcode": "PMT",
            "companyName": "MULTITRADE",
            "country": "Cambodia"
        },
        {
            "name": "PRT Aviation",
            "code": "",
            "auxcode": "PRP",
            "companyName": "PRONTO",
            "country": "Spain"
        },
        {
            "name": "PSA Airlines",
            "code": "",
            "auxcode": "JIA",
            "companyName": "BLUE STREAK",
            "country": "United States"
        },
        {
            "name": "PTL Luftfahrtunternehemen",
            "code": "",
            "auxcode": "KST",
            "companyName": "KING STAR",
            "country": "Germany"
        },
        {
            "name": "Paccair",
            "code": "",
            "auxcode": "WIS",
            "companyName": "WISCAIR",
            "country": "United States"
        },
        {
            "name": "Pace Airlines",
            "code": "Y5",
            "auxcode": "PCE",
            "companyName": "PACE",
            "country": "United States"
        },
        {
            "name": "Pacific Air Boats",
            "code": "",
            "auxcode": "PAB",
            "companyName": "AIR BOATS",
            "country": "Canada"
        },
        {
            "name": "Pacific Air Charter",
            "code": "",
            "auxcode": "PRC",
            "companyName": "PACIFIC CHARTER",
            "country": "United States"
        },
        {
            "name": "Pacific Air Express",
            "code": "",
            "auxcode": "PCF",
            "companyName": "PACIFIC EXPRESS",
            "country": "United States"
        },
        {
            "name": "Pacific Air Transport",
            "code": "",
            "auxcode": "PXP",
            "companyName": "PAK EXPRESS",
            "country": "United States"
        },
        {
            "name": "Jetstar Pacific",
            "code": "BL",
            "auxcode": "PIC",
            "companyName": "PACIFIC AIRLINES",
            "country": "Vietnam"
        },
        {
            "name": "Pacific Alaska Airlines",
            "code": "",
            "auxcode": "PAK",
            "companyName": "PACIFIC ALASKA",
            "country": "United States"
        },
        {
            "name": "Pacific Aviation (Australia)",
            "code": "",
            "auxcode": "PCV",
            "companyName": "PACAV",
            "country": "Australia"
        },
        {
            "name": "Pacific Aviation (United States)",
            "code": "",
            "auxcode": "PCX",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Pacific Blue",
            "code": "DJ",
            "auxcode": "PBN",
            "companyName": "BLUEBIRD",
            "country": "New Zealand"
        },
        {
            "name": "Pacific Coast Airlines",
            "code": "",
            "auxcode": "PQA",
            "companyName": "SAGE BRUSH",
            "country": "United States"
        },
        {
            "name": "Pacific Coastal Airline",
            "code": "8P",
            "auxcode": "PCO",
            "companyName": "PASCO",
            "country": "Canada"
        },
        {
            "name": "Pacific East Asia Cargo Airlines",
            "code": "Q8",
            "auxcode": "PEC",
            "companyName": "PACcodeEAST CARGO",
            "country": "Philippines"
        },
        {
            "name": "Pacific Flight Services",
            "code": "",
            "auxcode": "PFA",
            "companyName": "PACIFIC SING",
            "country": "Singapore"
        },
        {
            "name": "Pacific International Airlines",
            "code": "",
            "auxcode": "PIN",
            "companyName": "ROAD RUNNERS",
            "country": "United States"
        },
        {
            "name": "Pacific Island Aviation",
            "code": "",
            "auxcode": "PSA",
            "companyName": "PACIFIC ISLE",
            "country": "United States"
        },
        {
            "name": "Pacific Jet",
            "code": "",
            "auxcode": "PCJ",
            "companyName": "PACIFIC JET",
            "country": "United States"
        },
        {
            "name": "Pacific Pearl Airways",
            "code": "",
            "auxcode": "PPM",
            "companyName": "PACIFIC PEARL",
            "country": "Philippines"
        },
        {
            "name": "Pacific Rim Airways",
            "code": "",
            "auxcode": "PAR",
            "companyName": "PACRIM",
            "country": "Australia"
        },
        {
            "name": "Pacific Southwest Airlines",
            "code": "PS",
            "auxcode": "PSX",
            "companyName": "SMILEY",
            "country": "United States"
        },
        {
            "name": "Pacific Wings",
            "code": "LW",
            "auxcode": "NMI",
            "companyName": "TSUNAMI",
            "country": "United States"
        },
        {
            "name": "Pacificair",
            "code": "GX",
            "auxcode": "",
            "companyName": "",
            "country": "Philippines"
        },
        {
            "name": "Pacificair Airlines",
            "code": "",
            "auxcode": "PFR",
            "companyName": "PACIFIC WEST",
            "country": "United States"
        },
        {
            "name": "Package Express",
            "code": "",
            "auxcode": "RCY",
            "companyName": "RACE CITY",
            "country": "United States"
        },
        {
            "name": "Paisajes Espanoles",
            "code": "",
            "auxcode": "PAE",
            "companyName": "PAISAJES",
            "country": "Spain"
        },
        {
            "name": "Pak West Airlines",
            "code": "",
            "auxcode": "PKW",
            "companyName": "PLATINUM WEST",
            "country": "United States"
        },
        {
            "name": "Pakistan International Airlines",
            "code": "PK",
            "auxcode": "PIA",
            "companyName": "PAKISTAN",
            "country": "Pakistan"
        },
        {
            "name": "Pakker Avio",
            "code": "",
            "auxcode": "PKR",
            "companyName": "PAKKER AVIO",
            "country": "Estonia"
        },
        {
            "name": "Pal Aerolineas",
            "code": "",
            "auxcode": "LPA",
            "companyName": "LINEASPAL",
            "country": "Mexico"
        },
        {
            "name": "Palau Asia Pacific Airlines",
            "code": "",
            "auxcode": "PPC",
            "companyName": "PALAU ASIAPAC",
            "country": "Palau"
        },
        {
            "name": "Palau Trans Pacific Airline",
            "code": "GP",
            "auxcode": "PTP",
            "companyName": "TRANS PACIFIC",
            "country": "Palau"
        },
        {
            "name": "Palestinian Airlines",
            "code": "PF",
            "auxcode": "PNW",
            "companyName": "PALESTINIAN",
            "country": "Egypt"
        },
        {
            "name": "Palmer Aviation",
            "code": "",
            "auxcode": "JSP",
            "companyName": "PALMER",
            "country": "United Kingdom"
        },
        {
            "name": "Pamir Airways",
            "code": "NR",
            "auxcode": "PIR",
            "companyName": "PAMIR",
            "country": "Afghanistan"
        },
        {
            "name": "Pan African Air Services",
            "code": "",
            "auxcode": "PFN",
            "companyName": "PANAFRICAN",
            "country": "Sierra Leone"
        },
        {
            "name": "Pan African Airways",
            "code": "",
            "auxcode": "ODM",
            "companyName": "",
            "country": "Kenya"
        },
        {
            "name": "Pan Air",
            "code": "",
            "auxcode": "PAX",
            "companyName": "PANNEX",
            "country": "United States"
        },
        {
            "name": "Pan Am Weather Systems",
            "code": "",
            "auxcode": "XPA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Pan Am World Airways",
            "code": "",
            "auxcode": "PWD",
            "companyName": "",
            "country": "Dominican Republic"
        },
        {
            "name": "Pan American Airways",
            "code": "PA",
            "auxcode": "PAA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Pan American World Airways",
            "code": "PA",
            "auxcode": "PAA",
            "companyName": "CLIPPER",
            "country": "United States"
        },
        {
            "name": "Pan Europeenne Air Service",
            "code": "",
            "auxcode": "PEA",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Pan Havacilik Ve Ticaret",
            "code": "",
            "auxcode": "PHT",
            "companyName": "PANANK",
            "country": "Turkey"
        },
        {
            "name": "Pan Malaysian Air Transport",
            "code": "",
            "auxcode": "PMA",
            "companyName": "PAN MALAYSIA",
            "country": "Malaysia"
        },
        {
            "name": "PancodeAir",
            "code": "",
            "auxcode": "PNC",
            "companyName": "PANAIRSA",
            "country": "Mexico"
        },
        {
            "name": "Panafrican Airways",
            "code": "PQ",
            "auxcode": "PNF",
            "companyName": "PANWAYS",
            "country": "Ivory Coast"
        },
        {
            "name": "Panagra Airways",
            "code": "",
            "auxcode": "PGI",
            "companyName": "PANAGRA",
            "country": "United States"
        },
        {
            "name": "Panamedia",
            "code": "",
            "auxcode": "PEI",
            "companyName": "PANAMEDIA",
            "country": "Spain"
        },
        {
            "name": "Panavia",
            "code": "",
            "auxcode": "PVI",
            "companyName": "",
            "country": "Panama"
        },
        {
            "name": "Panh",
            "code": "",
            "auxcode": "PNH",
            "companyName": "KUBAN LIK",
            "country": "Russia"
        },
        {
            "name": "Pannon Air Service",
            "code": "",
            "auxcode": "PHU",
            "companyName": "PANNON",
            "country": "Hungary"
        },
        {
            "name": "Panorama",
            "code": "",
            "auxcode": "PNM",
            "companyName": "PANORAMA",
            "country": "Spain"
        },
        {
            "name": "Panorama Air Tour",
            "code": "",
            "auxcode": "PAH",
            "companyName": "LANI",
            "country": "United States"
        },
        {
            "name": "Panorama Flight Service",
            "code": "",
            "auxcode": "AFD",
            "companyName": "AIRFED",
            "country": "United States"
        },
        {
            "name": "Pantanal Linhas Aéreas",
            "code": "P8",
            "auxcode": "PTN",
            "companyName": "PANTANAL",
            "country": "Brazil"
        },
        {
            "name": "Papair Terminal",
            "code": "",
            "auxcode": "HMP",
            "companyName": "PAPAIR TERMINAL",
            "country": "Haiti"
        },
        {
            "name": "Paradise Airways",
            "code": "",
            "auxcode": "PAI",
            "companyName": "SEA RAY",
            "country": "United States"
        },
        {
            "name": "Paradise Island Airways",
            "code": "",
            "auxcode": "PDI",
            "companyName": "PARADISE ISLAND",
            "country": "United States"
        },
        {
            "name": "Paragon Air Express",
            "code": "",
            "auxcode": "PGX",
            "companyName": "PARAGON EXPRESS",
            "country": "United States"
        },
        {
            "name": "Paragon Global Flight Support",
            "code": "",
            "auxcode": "PGF",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Paramount Airlines",
            "code": "",
            "auxcode": "PRR",
            "companyName": "PARAMOUNT",
            "country": "Sierra Leone"
        },
        {
            "name": "Paramount Airways",
            "code": "I7",
            "auxcode": "PMW",
            "companyName": "PARAWAY",
            "country": "India"
        },
        {
            "name": "Parcel Express",
            "code": "",
            "auxcode": "APE",
            "companyName": "AIR PARCEL",
            "country": "United States"
        },
        {
            "name": "Pariz Air",
            "code": "",
            "auxcode": "IRE",
            "companyName": "PARIZAIR",
            "country": "Iran"
        },
        {
            "name": "Pars Aviation Service",
            "code": "",
            "auxcode": "PRA",
            "companyName": "PARSAVIA",
            "country": "Iran"
        },
        {
            "name": "Parsa",
            "code": "",
            "auxcode": "PST",
            "companyName": "TURISMO REGIONAL",
            "country": "Panama"
        },
        {
            "name": "Parsons Airways Northern",
            "code": "",
            "auxcode": "FAP",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Pascan Aviation",
            "code": "",
            "auxcode": "PSC",
            "companyName": "PASCAN",
            "country": "Canada"
        },
        {
            "name": "Passaredo Transportes Aereos",
            "code": "",
            "auxcode": "PTB",
            "companyName": "PASSAREDO",
            "country": "Brazil"
        },
        {
            "name": "Patria Cargas Aereas",
            "code": "",
            "auxcode": "PTC",
            "companyName": "PATRIA",
            "country": "Argentina"
        },
        {
            "name": "Patriot Aviation Limited",
            "code": "",
            "auxcode": "BYT",
            "companyName": "BYTE",
            "country": "United Kingdom"
        },
        {
            "name": "Patterson Aviation Company",
            "code": "",
            "auxcode": "ETL",
            "companyName": "ENTEL",
            "country": "United States"
        },
        {
            "name": "Pawan Hans",
            "code": "",
            "auxcode": "PHE",
            "companyName": "PAWAN HANS",
            "country": "India"
        },
        {
            "name": "Payam Air",
            "code": "",
            "auxcode": "IRP",
            "companyName": "PAYAMAIR",
            "country": "Iran"
        },
        {
            "name": "Peach Air",
            "code": "",
            "auxcode": "KGC",
            "companyName": "GOLDCREST",
            "country": "United Kingdom"
        },
        {
            "name": "Pearl Air",
            "code": "",
            "auxcode": "PRL",
            "companyName": "PEARL LINE",
            "country": "Pakistan"
        },
        {
            "name": "Pearl Air Services",
            "code": "",
            "auxcode": "PBY",
            "companyName": "PEARL SERVICES",
            "country": "Uganda"
        },
        {
            "name": "Pearl Airways",
            "code": "HP",
            "auxcode": "HPA",
            "companyName": "PEARL AIRWAYS",
            "country": "Haiti"
        },
        {
            "name": "Peau Vava",
            "code": "",
            "auxcode": "PVU",
            "companyName": "PEAU",
            "country": "Tonga"
        },
        {
            "name": "Pecotox Air",
            "code": "",
            "auxcode": "PXA",
            "companyName": "PECOTOX",
            "country": "Moldova"
        },
        {
            "name": "Pegasus Airlines",
            "code": "PC",
            "auxcode": "PGT",
            "companyName": "SUNTURK",
            "country": "Turkey"
        },
        {
            "name": "Pegasus Aviation",
            "code": "",
            "auxcode": "PEV",
            "companyName": "PEGAVIATION",
            "country": "Greece"
        },
        {
            "name": "Pegasus Hava Tasimaciligi",
            "code": "1I",
            "auxcode": "",
            "companyName": "",
            "country": "Turkey"
        },
        {
            "name": "Pegasus Helicopters",
            "code": "",
            "auxcode": "HAK",
            "companyName": "HELIFALCON",
            "country": "Norway"
        },
        {
            "name": "Pelican Air Services",
            "code": "",
            "auxcode": "PDF",
            "companyName": "PELICAN AIRWAYS",
            "country": "South Africa"
        },
        {
            "name": "Pelican Express",
            "code": "",
            "auxcode": "PEX",
            "companyName": "PELICAN EXPRESS",
            "country": "United States"
        },
        {
            "name": "Pelita Air Service",
            "code": "",
            "auxcode": "PAS",
            "companyName": "PELITA",
            "country": "Indonesia"
        },
        {
            "name": "PemcodeAir",
            "code": "",
            "auxcode": "PEM",
            "companyName": "PEMcodeAIR",
            "country": "Canada"
        },
        {
            "name": "PencodeAvia",
            "code": "",
            "auxcode": "PDY",
            "companyName": "PENDLEY",
            "country": "United Kingdom"
        },
        {
            "name": "Peninsula Airways",
            "code": "KS",
            "auxcode": "PEN",
            "companyName": "PENINSULA",
            "country": "United States"
        },
        {
            "name": "Peninter Aerea",
            "code": "",
            "auxcode": "PNE",
            "companyName": "PENINTER",
            "country": "Mexico"
        },
        {
            "name": "Penya De L'Aire",
            "code": "",
            "auxcode": "PCA",
            "companyName": "PENA DEL AIRE",
            "country": "Spain"
        },
        {
            "name": "Peran",
            "code": "",
            "auxcode": "CVT",
            "companyName": "CVETA",
            "country": "Kazakhstan"
        },
        {
            "name": "Perforadora Central",
            "code": "",
            "auxcode": "PCC",
            "companyName": "PERFORADORA CENTRAL",
            "country": "Mexico"
        },
        {
            "name": "Perimeter Aviation",
            "code": "",
            "auxcode": "PAG",
            "companyName": "PERIMETER",
            "country": "Canada"
        },
        {
            "name": "Perm Airlines",
            "code": "P9",
            "auxcode": "PGP",
            "companyName": "PERM AIR",
            "country": "Russia"
        },
        {
            "name": "Personas Y Pasquetes Por Air",
            "code": "",
            "auxcode": "PPQ",
            "companyName": "PERSONSPAQ",
            "country": "Mexico"
        },
        {
            "name": "Peruvian Air Force",
            "code": "",
            "auxcode": "FPR",
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "Peruvian Navy",
            "code": "",
            "auxcode": "INP",
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "Petroleos Mexicanos",
            "code": "",
            "auxcode": "PMX",
            "companyName": "PEMEX",
            "country": "Mexico"
        },
        {
            "name": "Petroleum Helicopters",
            "code": "",
            "auxcode": "PHM",
            "companyName": "PETROLEUM",
            "country": "United States"
        },
        {
            "name": "Petroleum Helicopters de Colombia",
            "code": "",
            "auxcode": "PHC",
            "companyName": "HELICOPTERS",
            "country": "Colombia"
        },
        {
            "name": "PetropavlovskcodeKamchatsk Air Enterprise",
            "code": "",
            "auxcode": "PTK",
            "companyName": "PETROKAM",
            "country": "Russia"
        },
        {
            "name": "Petty Transport",
            "code": "",
            "auxcode": "PTY",
            "companyName": "PETTY",
            "country": "United States"
        },
        {
            "name": "Phenix Aviation",
            "code": "",
            "auxcode": "PHV",
            "companyName": "NEW BIRD",
            "country": "France"
        },
        {
            "name": "Phetchabun Airline",
            "code": "",
            "auxcode": "PMY",
            "companyName": "PHETCHABUN AIR",
            "country": "Thailand"
        },
        {
            "name": "Philippine Airlines",
            "code": "PR",
            "auxcode": "PAL",
            "companyName": "PHILIPPINE",
            "country": "Philippines"
        },
        {
            "name": "Philips Aviation Services",
            "code": "",
            "auxcode": "PHI",
            "companyName": "PHILAIR",
            "country": "Netherlands"
        },
        {
            "name": "Phillips Air",
            "code": "",
            "auxcode": "BCH",
            "companyName": "BEACHBALL",
            "country": "United States"
        },
        {
            "name": "Phillips Alaska",
            "code": "",
            "auxcode": "PDD",
            "companyName": "PADA",
            "country": "United States"
        },
        {
            "name": "Phillips Michigan City Flying Service",
            "code": "",
            "auxcode": "PHL",
            "companyName": "PHILLIPS",
            "country": "United States"
        },
        {
            "name": "Phoebus Apollo Aviation",
            "code": "",
            "auxcode": "PHB",
            "companyName": "PHOEBUS",
            "country": "South Africa"
        },
        {
            "name": "Phoebus Apolloa Zambia",
            "code": "",
            "auxcode": "KZM",
            "companyName": "CARZAM",
            "country": "Zambia"
        },
        {
            "name": "Phoenix Air Group",
            "code": "",
            "auxcode": "PHA",
            "companyName": "GRAY BIRD",
            "country": "United States"
        },
        {
            "name": "Phoenix Air Lines",
            "code": "",
            "auxcode": "PHN",
            "companyName": "PHOENIX BRASIL",
            "country": "Brazil"
        },
        {
            "name": "Phoenix Air Service",
            "code": "",
            "auxcode": "PAM",
            "companyName": "PHOENIX",
            "country": "Germany"
        },
        {
            "name": "Phoenix Air Transport",
            "code": "",
            "auxcode": "PPG",
            "companyName": "PAPAGO",
            "country": "United States"
        },
        {
            "name": "Phoenix Airline Services",
            "code": "",
            "auxcode": "WDY",
            "companyName": "WINDYCITY",
            "country": "United States"
        },
        {
            "name": "Phoenix Airways",
            "code": "HP",
            "auxcode": "",
            "companyName": "",
            "country": "Switzerland"
        },
        {
            "name": "Phoenix Avia",
            "code": "",
            "auxcode": "PHY",
            "companyName": "PHOENIX ARMENIA",
            "country": "Armenia"
        },
        {
            "name": "Phoenix Aviation",
            "code": "",
            "auxcode": "PHG",
            "companyName": "PHOENIX GROUP",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Phoenix Flight Operations",
            "code": "",
            "auxcode": "XPX",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Phuket Air",
            "code": "9R",
            "auxcode": "VAP",
            "companyName": "PHUKET AIR",
            "country": "Thailand"
        },
        {
            "name": "Piedmont Airlines (1948code1989)",
            "code": "PI",
            "auxcode": "PDT",
            "companyName": "PIEDMONT",
            "country": "United States"
        },
        {
            "name": "Pilatus Flugzeugwerke",
            "code": "",
            "auxcode": "PCH",
            "companyName": "PILATUS WINGS",
            "country": "Switzerland"
        },
        {
            "name": "Pilatus PCcode12 Center De Mexico",
            "code": "",
            "auxcode": "PLU",
            "companyName": "PILATUS MEXICO",
            "country": "Mexico"
        },
        {
            "name": "Pimichikamac Air",
            "code": "",
            "auxcode": "MKS",
            "companyName": "MIKISEW",
            "country": "Canada"
        },
        {
            "name": "Pineapple Air",
            "code": "",
            "auxcode": "PNP",
            "companyName": "PINEAPPLE AIR",
            "country": "Bahamas"
        },
        {
            "name": "Pinframat",
            "code": "",
            "auxcode": "PIM",
            "companyName": "PINFRAMAT",
            "country": "Angola"
        },
        {
            "name": "Pinnacle Air Group",
            "code": "",
            "auxcode": "PCL",
            "companyName": "PINNACLE GROUP",
            "country": "United States"
        },
        {
            "name": "Pinnacle Airlines",
            "code": "9E",
            "auxcode": "FLG",
            "companyName": "FLAGSHIP",
            "country": "United States"
        },
        {
            "name": "Pioneer Airlines",
            "code": "",
            "auxcode": "PIO",
            "companyName": "PIONEER",
            "country": "United States"
        },
        {
            "name": "Pioneers Limited",
            "code": "",
            "auxcode": "PER",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Pirinair Express",
            "code": "",
            "auxcode": "PRN",
            "companyName": "PRINAIR EXPRESS",
            "country": "Spain"
        },
        {
            "name": "Planar",
            "code": "",
            "auxcode": "PLN",
            "companyName": "PLANAR",
            "country": "Angola"
        },
        {
            "name": "Planemaster Services",
            "code": "",
            "auxcode": "PMS",
            "companyName": "PLANEMASTER",
            "country": "United States"
        },
        {
            "name": "Planet Airways",
            "code": "",
            "auxcode": "PLZ",
            "companyName": "PLANET",
            "country": "United States"
        },
        {
            "name": "Players Air",
            "code": "",
            "auxcode": "PYZ",
            "companyName": "PLAYERS AIR",
            "country": "United States"
        },
        {
            "name": "Ploizeihubschrauberstaffel Hamburg",
            "code": "",
            "auxcode": "LIB",
            "companyName": "LIBELLE",
            "country": "Germany"
        },
        {
            "name": "Plymouth School of Flying",
            "code": "",
            "auxcode": "PSF",
            "companyName": "LIZARD",
            "country": "United Kingdom"
        },
        {
            "name": "Pocono Air Lines",
            "code": "",
            "auxcode": "POC",
            "companyName": "POCONO",
            "country": "United States"
        },
        {
            "name": "PodiliacodeAvia",
            "code": "",
            "auxcode": "PDA",
            "companyName": "PODILIA",
            "country": "Ukraine"
        },
        {
            "name": "Point Afrique Niger",
            "code": "",
            "auxcode": "PAZ",
            "companyName": "POINTAIR NIGER",
            "country": "Niger"
        },
        {
            "name": "Point Airlines",
            "code": "",
            "auxcode": "RMI",
            "companyName": "POINT AIRLINE",
            "country": "Nigeria"
        },
        {
            "name": "Pointair Burkina",
            "code": "",
            "auxcode": "PAW",
            "companyName": "POINTAIR BURKINA",
            "country": "Burkina Faso"
        },
        {
            "name": "Points of Call Airlines",
            "code": "",
            "auxcode": "PTS",
            "companyName": "POINTSCALL",
            "country": "Canada"
        },
        {
            "name": "Polar Air Cargo",
            "code": "PO",
            "auxcode": "PAC",
            "companyName": "POLAR",
            "country": "United States"
        },
        {
            "name": "Polar Airlines de Mexico",
            "code": "",
            "auxcode": "PMO",
            "companyName": "POLAR MEXICO",
            "country": "Mexico"
        },
        {
            "name": "Polestar Aviation",
            "code": "",
            "auxcode": "PSR",
            "companyName": "POLESTAR",
            "country": "United Kingdom"
        },
        {
            "name": "Polet",
            "code": "",
            "auxcode": "POT",
            "companyName": "POLET",
            "country": "Russia"
        },
        {
            "name": "Police Aux Frontiers",
            "code": "",
            "auxcode": "POF",
            "companyName": "AIRPOL",
            "country": "France"
        },
        {
            "name": "Police Aviation Services",
            "code": "",
            "auxcode": "PLC",
            "companyName": "SPECIAL",
            "country": "United Kingdom"
        },
        {
            "name": "Polish Air Force",
            "code": "",
            "auxcode": "PLF",
            "companyName": "POLISH AIRFORCE",
            "country": "Poland"
        },
        {
            "name": "Polish Navy",
            "code": "",
            "auxcode": "PNY",
            "companyName": "POLISH NAVY",
            "country": "Poland"
        },
        {
            "name": "Polizeifliegerstaffel NordrheincodeWestfalen",
            "code": "",
            "auxcode": "NRW",
            "companyName": "HUMMEL",
            "country": "Germany"
        },
        {
            "name": "Polizeihibschrauberstaffel Neidersachsen",
            "code": "",
            "auxcode": "PPH",
            "companyName": "POLICE PHOENIX",
            "country": "Germany"
        },
        {
            "name": "Polizeihuberschrauberstaffel SachsencodeAnhalt",
            "code": "",
            "auxcode": "PIK",
            "companyName": "POLICE IKARUS",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrafterstaffel RheinlandcodePfalz",
            "code": "",
            "auxcode": "SRP",
            "companyName": "SPERBER",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel BadencodeWurtemberg",
            "code": "",
            "auxcode": "PBW",
            "companyName": "BUSSARD",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel Bayern",
            "code": "",
            "auxcode": "EDL",
            "companyName": "POLICE EDELWEISS",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel Brandenburg",
            "code": "",
            "auxcode": "PBB",
            "companyName": "ADEBAR",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel Hessen",
            "code": "",
            "auxcode": "PHH",
            "companyName": "IBIS",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel MecklenburgcodeVorpommern",
            "code": "",
            "auxcode": "PMV",
            "companyName": "POLICE MERLIN",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel Sachsen",
            "code": "",
            "auxcode": "PHS",
            "companyName": "PASSAT",
            "country": "Germany"
        },
        {
            "name": "Polizeihubschrauberstaffel Thuringen",
            "code": "",
            "auxcode": "HBT",
            "companyName": "HABICHT",
            "country": "Germany"
        },
        {
            "name": "Polo Aviation",
            "code": "",
            "auxcode": "CUK",
            "companyName": "CHUKKA",
            "country": "United Kingdom"
        },
        {
            "name": "Polynesian AircodeWays",
            "code": "",
            "auxcode": "PLA",
            "companyName": "POLYAIR",
            "country": "United States"
        },
        {
            "name": "Polynesian Airlines",
            "code": "PH",
            "auxcode": "PAO",
            "companyName": "POLYNESIAN",
            "country": "Samoa"
        },
        {
            "name": "Polynesian Blue",
            "code": "DJ",
            "auxcode": "PLB",
            "companyName": "POLYBLUE",
            "country": "New Zealand"
        },
        {
            "name": "Polyot Sirena",
            "code": "1U",
            "auxcode": "",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Pond Air Express",
            "code": "",
            "auxcode": "PND",
            "companyName": "POND AIR",
            "country": "United States"
        },
        {
            "name": "Pont International Airline Services",
            "code": "",
            "auxcode": "PSI",
            "companyName": "PONT",
            "country": "Suriname"
        },
        {
            "name": "Pool Aviation",
            "code": "",
            "auxcode": "PLX",
            "companyName": "POOLEX",
            "country": "United Kingdom"
        },
        {
            "name": "Port Townsend Airways",
            "code": "",
            "auxcode": "PTQ",
            "companyName": "TOWNSEND",
            "country": "United States"
        },
        {
            "name": "Porteadora De Cosola",
            "code": "",
            "auxcode": "POR",
            "companyName": "PORTEADORA",
            "country": "Mexico"
        },
        {
            "name": "Porter Airlines",
            "code": "PD",
            "auxcode": "POE",
            "companyName": "PORTER AIR",
            "country": "Canada"
        },
        {
            "name": "Portugalia",
            "code": "NI",
            "auxcode": "PGA",
            "companyName": "PORTUGALIA",
            "country": "Portugal"
        },
        {
            "name": "Portuguese Air Force",
            "code": "",
            "auxcode": "AFP",
            "companyName": "PORTUGUESE AIR FORCE",
            "country": "Portugal"
        },
        {
            "name": "Portuguese Army",
            "code": "",
            "auxcode": "POA",
            "companyName": "PORTUGUESE ARMY",
            "country": "Portugal"
        },
        {
            "name": "Portuguese Navy",
            "code": "",
            "auxcode": "PON",
            "companyName": "PORTUGUESE NAVY",
            "country": "Portugal"
        },
        {
            "name": "Potomac Air",
            "code": "BK",
            "auxcode": "PDC",
            "companyName": "DISTRICT",
            "country": "United States"
        },
        {
            "name": "Potosina Del Aire",
            "code": "",
            "auxcode": "PSN",
            "companyName": "POTOSINA",
            "country": "Mexico"
        },
        {
            "name": "Powell Air",
            "code": "",
            "auxcode": "PWL",
            "companyName": "POWELL AIR",
            "country": "Canada"
        },
        {
            "name": "Prairie Flying Service",
            "code": "",
            "auxcode": "PFS",
            "companyName": "PRAIRIE",
            "country": "United States"
        },
        {
            "name": "Pratt and Whitney Canada",
            "code": "",
            "auxcode": "PWC",
            "companyName": "PRATT",
            "country": "Canada"
        },
        {
            "name": "Precision Air",
            "code": "PW",
            "auxcode": "PRF",
            "companyName": "PRECISION AIR",
            "country": "Tanzania"
        },
        {
            "name": "Precision Airlines",
            "code": "",
            "auxcode": "PRE",
            "companyName": "PRECISION",
            "country": "United States"
        },
        {
            "name": "Premiair",
            "code": "",
            "auxcode": "BAT",
            "companyName": "BALLISTIC",
            "country": "Luxembourg"
        },
        {
            "name": "Premiair Aviation Services",
            "code": "",
            "auxcode": "PGL",
            "companyName": "PREMIERE",
            "country": "United Kingdom"
        },
        {
            "name": "Premiair Fliyng Club",
            "code": "",
            "auxcode": "PME",
            "companyName": "ADUR",
            "country": "United Kingdom"
        },
        {
            "name": "Premium Air Shuttle",
            "code": "",
            "auxcode": "EMI",
            "companyName": "BLUE SHUTTLE",
            "country": "Nigeria"
        },
        {
            "name": "Premium Aviation",
            "code": "",
            "auxcode": "PMU",
            "companyName": "PREMIUM",
            "country": "Germany"
        },
        {
            "name": "Presidence Du Faso",
            "code": "",
            "auxcode": "BFA",
            "companyName": "",
            "country": "Burkina Faso"
        },
        {
            "name": "Presidencia de La Republica de Guinea Ecuatorial",
            "code": "",
            "auxcode": "ONM",
            "companyName": "",
            "country": "Equatorial Guinea"
        },
        {
            "name": "President Airlines",
            "code": "TO",
            "auxcode": "PSD",
            "companyName": "",
            "country": "Cambodia"
        },
        {
            "name": "Presidential Aviation",
            "code": "",
            "auxcode": "PRD",
            "companyName": "PRESIDENTIAL",
            "country": "United States"
        },
        {
            "name": "Priester Aviation",
            "code": "",
            "auxcode": "PWA",
            "companyName": "PRIESTER",
            "country": "United States"
        },
        {
            "name": "Primair",
            "code": "",
            "auxcode": "PMM",
            "companyName": "PRIMAVIA",
            "country": "Russia"
        },
        {
            "name": "Primaris Airlines",
            "code": "FE",
            "auxcode": "WCP",
            "companyName": "WHITECAP",
            "country": "United States"
        },
        {
            "name": "Primas Courier",
            "code": "",
            "auxcode": "PMC",
            "companyName": "PRIMAC",
            "country": "United States"
        },
        {
            "name": "Primavia Limited",
            "code": "",
            "auxcode": "CRY",
            "companyName": "CARRIERS",
            "country": "United Kingdom"
        },
        {
            "name": "Prime Air",
            "code": "",
            "auxcode": "PRM",
            "companyName": "PRIME AIR",
            "country": "United States"
        },
        {
            "name": "Prime Aviation",
            "code": "",
            "auxcode": "PKZ",
            "companyName": "PRAVI",
            "country": "Kazakhstan"
        },
        {
            "name": "Prince Edward Air",
            "code": "",
            "auxcode": "CME",
            "companyName": "COMET",
            "country": "Canada"
        },
        {
            "name": "Princely Jets",
            "code": "",
            "auxcode": "PJP",
            "companyName": "PRINCELY JETS",
            "country": "Pakistan"
        },
        {
            "name": "Princess Air",
            "code": "8Q",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "Princeton Aviation Corporation",
            "code": "",
            "auxcode": "PCN",
            "companyName": "PRINCETON",
            "country": "United States"
        },
        {
            "name": "Priority Air Charter",
            "code": "",
            "auxcode": "PRY",
            "companyName": "PRIORITY AIR",
            "country": "United States"
        },
        {
            "name": "Priority Air Transport",
            "code": "",
            "auxcode": "PAT",
            "companyName": "PAT",
            "country": "United States"
        },
        {
            "name": "Priority Aviation Company",
            "code": "",
            "auxcode": "BCK",
            "companyName": "BANKCHECK",
            "country": "United States"
        },
        {
            "name": "Privatair",
            "code": "",
            "auxcode": "PTI",
            "companyName": "PRIVATAIR",
            "country": "Switzerland"
        },
        {
            "name": "Private Jet Expeditions",
            "code": "",
            "auxcode": "PJE",
            "companyName": "PEE JAY",
            "country": "United States"
        },
        {
            "name": "Private Jet Management",
            "code": "",
            "auxcode": "PJA",
            "companyName": "PRIVATE FLIGHT",
            "country": "United States"
        },
        {
            "name": "Private Wings Flugcharter",
            "code": "8W",
            "auxcode": "PWF",
            "companyName": "PRIVATE WINGS",
            "country": "Germany"
        },
        {
            "name": "Privilege Style L",
            "code": "",
            "auxcode": "PVG",
            "companyName": "PRIVILEGE",
            "country": "Spain"
        },
        {
            "name": "Pro Air",
            "code": "",
            "auxcode": "PRH",
            "companyName": "PROHAWK",
            "country": "United States"
        },
        {
            "name": "Pro Air Service",
            "code": "",
            "auxcode": "PSZ",
            "companyName": "POPcodeAIR",
            "country": "United States"
        },
        {
            "name": "Probiz Guinee",
            "code": "",
            "auxcode": "GIY",
            "companyName": "PROBIZ",
            "country": "Guinea"
        },
        {
            "name": "Professional Express Courier Service",
            "code": "",
            "auxcode": "PAD",
            "companyName": "AIR PROFESSIONAL",
            "country": "United States"
        },
        {
            "name": "Professione VOlare",
            "code": "",
            "auxcode": "PVL",
            "companyName": "VOLARE",
            "country": "Italy"
        },
        {
            "name": "Proflight Commuter Services",
            "code": "P0",
            "auxcode": "",
            "companyName": "",
            "country": "Zambia"
        },
        {
            "name": "Promotora Industria Totolapa",
            "code": "",
            "auxcode": "PTT",
            "companyName": "TOTOLAPA",
            "country": "Mexico"
        },
        {
            "name": "Propair",
            "code": "",
            "auxcode": "PRO",
            "companyName": "PROPAIR",
            "country": "Canada"
        },
        {
            "name": "Propflight Air Services",
            "code": "",
            "auxcode": "PFZ",
            "companyName": "PROFLIGHTcodeZAMBIA",
            "country": "Zambia"
        },
        {
            "name": "Propheter Aviation",
            "code": "",
            "auxcode": "PPA",
            "companyName": "AIR PROP",
            "country": "United States"
        },
        {
            "name": "Proteus Helicopteres",
            "code": "",
            "auxcode": "PTH",
            "companyName": "PROTEUS",
            "country": "France"
        },
        {
            "name": "Providence Airline",
            "code": "",
            "auxcode": "PTL",
            "companyName": "PLANTATION",
            "country": "United States"
        },
        {
            "name": "Providence Aviation Services",
            "code": "",
            "auxcode": "AWD",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Provincial Airlines",
            "code": "",
            "auxcode": "SPR",
            "companyName": "SPEEDAIR",
            "country": "Canada"
        },
        {
            "name": "Provincial Express",
            "code": "",
            "auxcode": "PRV",
            "companyName": "PROVINCIAL",
            "country": "Canada"
        },
        {
            "name": "Pskovavia",
            "code": "",
            "auxcode": "PSW",
            "companyName": "PSKOVAVIA",
            "country": "Russia"
        },
        {
            "name": "Psudiklat Perhubungan Udara/PLP",
            "code": "",
            "auxcode": "UDA",
            "companyName": "UDARA",
            "country": "Indonesia"
        },
        {
            "name": "Ptarmigan Airways",
            "code": "",
            "auxcode": "PTA",
            "companyName": "PTARMIGAN",
            "country": "Canada"
        },
        {
            "name": "Publiservicios Aereos",
            "code": "",
            "auxcode": "PSP",
            "companyName": "PUBLISERVICIOS",
            "country": "Mexico"
        },
        {
            "name": "Publivoo",
            "code": "",
            "auxcode": "PUV",
            "companyName": "PUBLIVOO",
            "country": "Portugal"
        },
        {
            "name": "Puerto Rico National Guard",
            "code": "",
            "auxcode": "PNG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Puerto Vallarta Taxi Aereo",
            "code": "",
            "auxcode": "TXV",
            "companyName": "TAXIVALLARTA",
            "country": "Mexico"
        },
        {
            "name": "Pulkovo Aircraft Services",
            "code": "",
            "auxcode": "PGH",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Puma Linhas Aereas",
            "code": "",
            "auxcode": "PLY",
            "companyName": "PUMA BRASIL",
            "country": "Brazil"
        },
        {
            "name": "Puntavia Air Services",
            "code": "",
            "auxcode": "PTV",
            "companyName": "PUNTAVIA",
            "country": "Djibouti"
        },
        {
            "name": "Punto Fa",
            "code": "",
            "auxcode": "MGO",
            "companyName": "MANGO",
            "country": "Spain"
        },
        {
            "name": "Pyramid Air Lines",
            "code": "",
            "auxcode": "PYR",
            "companyName": "PYAIR",
            "country": "Egypt"
        },
        {
            "name": "Qanot Sharq",
            "code": "",
            "auxcode": "QNT",
            "companyName": "QANAT SHARQ",
            "country": "Uzbekistan"
        },
        {
            "name": "Qantas",
            "code": "QF",
            "auxcode": "QFA",
            "companyName": "QANTAS",
            "country": "Australia"
        },
        {
            "name": "Qatar Air Cargo",
            "code": "",
            "auxcode": "QAC",
            "companyName": "QATAR CARGO",
            "country": "Qatar"
        },
        {
            "name": "Qatar Airways",
            "code": "QR",
            "auxcode": "QTR",
            "companyName": "QATARI",
            "country": "Qatar"
        },
        {
            "name": "Qatar Amiri Flight",
            "code": "",
            "auxcode": "QAF",
            "companyName": "AMIRI",
            "country": "Qatar"
        },
        {
            "name": "Qeshm Air",
            "code": "",
            "auxcode": "IRQ",
            "companyName": "QESHM AIR",
            "country": "Iran"
        },
        {
            "name": "Quantex Environmental",
            "code": "",
            "auxcode": "QTX",
            "companyName": "AIR QUANTEX",
            "country": "Canada"
        },
        {
            "name": "Quebec Government Air Service",
            "code": "",
            "auxcode": "QUE",
            "companyName": "QUEBEC",
            "country": "Canada"
        },
        {
            "name": "Queen Air",
            "code": "",
            "auxcode": "QNA",
            "companyName": "QUEEN AIR",
            "country": "Dominican Republic"
        },
        {
            "name": "Quest Diagnostics",
            "code": "",
            "auxcode": "LBQ",
            "companyName": "LABQUEST",
            "country": "United States"
        },
        {
            "name": "Quick Air Jet Charter",
            "code": "",
            "auxcode": "QAJ",
            "companyName": "DAGOBERT",
            "country": "Germany"
        },
        {
            "name": "Quick Airways Holland",
            "code": "",
            "auxcode": "QAH",
            "companyName": "QUICK",
            "country": "Netherlands"
        },
        {
            "name": "Quisqueya Airlines",
            "code": "",
            "auxcode": "QAS",
            "companyName": "QUISQUEYA",
            "country": "Haiti"
        },
        {
            "name": "Qurinea Air Service",
            "code": "",
            "auxcode": "QAQ",
            "companyName": "QURINEA AIR",
            "country": "Libya"
        },
        {
            "name": "Qwest Commuter Corporation",
            "code": "",
            "auxcode": "QCC",
            "companyName": "QWEST AIR",
            "country": "United States"
        },
        {
            "name": "Qwestair",
            "code": "",
            "auxcode": "QWA",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Qwila Air",
            "code": "",
            "auxcode": "QWL",
            "companyName": "QcodeCHARTER",
            "country": "South Africa"
        },
        {
            "name": "RA Jet Aeroservicios",
            "code": "",
            "auxcode": "RJT",
            "companyName": "RA JET",
            "country": "Mexico"
        },
        {
            "name": "RACSA",
            "code": "R6",
            "auxcode": "",
            "companyName": "",
            "country": "Guatemala"
        },
        {
            "name": "RAF Barkston Heath",
            "code": "",
            "auxcode": "BKH",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Church Fenton Flying Training Unit",
            "code": "",
            "auxcode": "CFN",
            "companyName": "CHURCH FENTON",
            "country": "United Kingdom"
        },
        {
            "name": "Coltishall Flying Training Unit",
            "code": "",
            "auxcode": "COH",
            "companyName": "COLT",
            "country": "United Kingdom"
        },
        {
            "name": "Coningsby Flying Training Unit",
            "code": "",
            "auxcode": "CBY",
            "companyName": "TYPHOON",
            "country": "United Kingdom"
        },
        {
            "name": "Cottesmore Flying Training Unit",
            "code": "",
            "auxcode": "COT",
            "companyName": "COTTESMORE",
            "country": "United Kingdom"
        },
        {
            "name": "Cranwell Flying Training Unit",
            "code": "",
            "auxcode": "CWL",
            "companyName": "CRANWELL",
            "country": "United Kingdom"
        },
        {
            "name": "Kinloss Flying Training Unit",
            "code": "",
            "auxcode": "KIN",
            "companyName": "KINLOSS",
            "country": "United Kingdom"
        },
        {
            "name": "Leeming Flying Training Unit",
            "code": "",
            "auxcode": "LEE",
            "companyName": "JAVELIN",
            "country": "United Kingdom"
        },
        {
            "name": "RAF Leuchars",
            "code": "",
            "auxcode": "LCS",
            "companyName": "LEUCHARS",
            "country": "United Kingdom"
        },
        {
            "name": "LintoncodeoncodeOuse Flying Training Unit",
            "code": "",
            "auxcode": "LOP",
            "companyName": "LINTON ON OUSE",
            "country": "United Kingdom"
        },
        {
            "name": "Lossiemouth Flying Training Unit",
            "code": "",
            "auxcode": "LOS",
            "companyName": "LOSSIE",
            "country": "United Kingdom"
        },
        {
            "name": "Marham Flying Training Unit",
            "code": "",
            "auxcode": "MRH",
            "companyName": "MARHAM",
            "country": "United Kingdom"
        },
        {
            "name": "Northwood Headquarters (RAF",
            "code": "",
            "auxcode": "NWO",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "RAF Scampton",
            "code": "",
            "auxcode": "SMZ",
            "companyName": "SCAMPTON",
            "country": "United Kingdom"
        },
        {
            "name": "RAF St Athan",
            "code": "",
            "auxcode": "STN",
            "companyName": "SAINT ATHAN",
            "country": "UNited Kingdom"
        },
        {
            "name": "RAF St Mawgan Search and Rescue",
            "code": "",
            "auxcode": "SMG",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "RAF Topcliffe Flying Training Unit",
            "code": "",
            "auxcode": "TOF",
            "companyName": "TOPCLIFFE",
            "country": "United Kingdom"
        },
        {
            "name": "RAF Valley Flying Training Unit",
            "code": "",
            "auxcode": "VYT",
            "companyName": "ANGLESEY",
            "country": "United Kingdom"
        },
        {
            "name": "RAF Valley SAR Training Unit",
            "code": "",
            "auxcode": "VLL",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Waddington FTU",
            "code": "",
            "auxcode": "WAD",
            "companyName": "VULCAN",
            "country": "United Kingdom"
        },
        {
            "name": "Wittering FTU",
            "code": "",
            "auxcode": "WIT",
            "companyName": "STRIKER",
            "country": "United Kingdom"
        },
        {
            "name": "RAFcodeAvia",
            "code": "",
            "auxcode": "MTL",
            "companyName": "MITAVIA",
            "country": "Latvia"
        },
        {
            "name": "RAK Airways",
            "code": "",
            "auxcode": "RKM",
            "companyName": "RAKAIR",
            "country": "United Arab Emirates"
        },
        {
            "name": "RWL Luftfahrtgesellschaft",
            "code": "",
            "auxcode": "RWL",
            "companyName": "RHEINTRAINER",
            "country": "Germany"
        },
        {
            "name": "RabbitcodeAir",
            "code": "",
            "auxcode": "RBB",
            "companyName": "RABBIT",
            "country": "Switzerland"
        },
        {
            "name": "Race Cargo Airlines",
            "code": "",
            "auxcode": "ACE",
            "companyName": "Fastcargo",
            "country": "Ghana"
        },
        {
            "name": "Rader Aviation",
            "code": "",
            "auxcode": "GBR",
            "companyName": "GREENBRIER AIR",
            "country": "United States"
        },
        {
            "name": "Radixx Solutions International",
            "code": "1D",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Raji Airlines",
            "code": "",
            "auxcode": "RAJ",
            "companyName": "RAJI",
            "country": "Pakistan"
        },
        {
            "name": "Raleigh Flying Service",
            "code": "",
            "auxcode": "RFA",
            "companyName": "RALEIGH SERVICE",
            "country": "United States"
        },
        {
            "name": "Ram Air Freight",
            "code": "",
            "auxcode": "REX",
            "companyName": "RAM EXPRESS",
            "country": "United States"
        },
        {
            "name": "Ram Aircraft Corporation",
            "code": "",
            "auxcode": "RMT",
            "companyName": "RAM FLIGHT",
            "country": "United States"
        },
        {
            "name": "Ramp 66",
            "code": "",
            "auxcode": "PPK",
            "companyName": "PELICAN",
            "country": "United States"
        },
        {
            "name": "Rangemile Limited",
            "code": "",
            "auxcode": "RGM",
            "companyName": "RANGEMILE",
            "country": "United Kingdom"
        },
        {
            "name": "Raslan Air Service",
            "code": "",
            "auxcode": "MWR",
            "companyName": "RASLAN",
            "country": "Egypt"
        },
        {
            "name": "Rath Aviaton",
            "code": "",
            "auxcode": "RAQ",
            "companyName": "RATH AVIATION",
            "country": "Austria"
        },
        {
            "name": "Ratkhan Air",
            "code": "",
            "auxcode": "CSM",
            "companyName": "LORRY",
            "country": "Kazakhstan"
        },
        {
            "name": "Raven Air",
            "code": "",
            "auxcode": "RVR",
            "companyName": "RAVEN",
            "country": "United Kingdom"
        },
        {
            "name": "Raven Air",
            "code": "",
            "auxcode": "RVN",
            "companyName": "RAVEN UcodeS",
            "country": "United States"
        },
        {
            "name": "Ray Aviation",
            "code": "",
            "auxcode": "REI",
            "companyName": "RAY AVIATION",
            "country": "Israel"
        },
        {
            "name": "Raya Jet",
            "code": "",
            "auxcode": "RYT",
            "companyName": "",
            "country": "Jordan"
        },
        {
            "name": "Raytheon Aircraft Company",
            "code": "",
            "auxcode": "RTN",
            "companyName": "RAYTHEON",
            "country": "United States"
        },
        {
            "name": "Raytheon Corporate Jets",
            "code": "",
            "auxcode": "RCJ",
            "companyName": "NEWPIN",
            "country": "United Kingdom"
        },
        {
            "name": "Raytheon Travel Air",
            "code": "",
            "auxcode": "KSS",
            "companyName": "KANSAS",
            "country": "United States"
        },
        {
            "name": "Real Aero Club De Baleares",
            "code": "",
            "auxcode": "RCB",
            "companyName": "BALEARES",
            "country": "Spain"
        },
        {
            "name": "Real Aero Club de ReuscodeCosta Dorado",
            "code": "",
            "auxcode": "CDT",
            "companyName": "AEROREUS",
            "country": "Spain"
        },
        {
            "name": "Real Aeroclub De Ternerife",
            "code": "",
            "auxcode": "RCD",
            "companyName": "AEROCLUB",
            "country": "Spain"
        },
        {
            "name": "Real Aviation",
            "code": "",
            "auxcode": "RLV",
            "companyName": "REAL",
            "country": "Ghana"
        },
        {
            "name": "Rebus",
            "code": "",
            "auxcode": "REB",
            "companyName": "REBUS",
            "country": "Bulgaria"
        },
        {
            "name": "Red Aviation",
            "code": "",
            "auxcode": "PSH",
            "companyName": "PASSION",
            "country": "United Kingdom"
        },
        {
            "name": "Red Baron Aviation",
            "code": "",
            "auxcode": "RBN",
            "companyName": "RED BARON",
            "country": "United States"
        },
        {
            "name": "Red Devils Parachute Display Team",
            "code": "",
            "auxcode": "DEV",
            "companyName": "RED DEVILS",
            "country": "United Kingdom"
        },
        {
            "name": "Red Sea Aviation",
            "code": "",
            "auxcode": "RDV",
            "companyName": "RED AVIATION",
            "country": "Egypt"
        },
        {
            "name": "Red Sky Ventures",
            "code": "",
            "auxcode": "RSV",
            "companyName": "RED SKY",
            "country": "Namibia"
        },
        {
            "name": "Red Star",
            "code": "",
            "auxcode": "STR",
            "companyName": "STARLINE",
            "country": "United Arab Emirates"
        },
        {
            "name": "Redhill Aviation",
            "code": "8L",
            "auxcode": "RHC",
            "companyName": "REDAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Reed Aviation",
            "code": "",
            "auxcode": "RAV",
            "companyName": "REED AVIATION",
            "country": "United Kingdom"
        },
        {
            "name": "Reef Air",
            "code": "",
            "auxcode": "REF",
            "companyName": "REEF AIR",
            "country": "New Zealand"
        },
        {
            "name": "Reem Air",
            "code": "V4",
            "auxcode": "REK",
            "companyName": "REEM AIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Reeve Aleutian Airways",
            "code": "",
            "auxcode": "RVV",
            "companyName": "REEVE",
            "country": "United States"
        },
        {
            "name": "Regal Bahamas International Airways",
            "code": "",
            "auxcode": "RBH",
            "companyName": "CALYPSO",
            "country": "Bahamas"
        },
        {
            "name": "Regency Airlines",
            "code": "",
            "auxcode": "RGY",
            "companyName": "REGENCY",
            "country": "United States"
        },
        {
            "name": "Regent Air",
            "code": "",
            "auxcode": "RAH",
            "companyName": "REGENT",
            "country": "Canada"
        },
        {
            "name": "Regio Air",
            "code": "",
            "auxcode": "RAG",
            "companyName": "GERMAN LINK",
            "country": "Germany"
        },
        {
            "name": "Region Air",
            "code": "",
            "auxcode": "RGR",
            "companyName": "REGIONAIR",
            "country": "Canada"
        },
        {
            "name": "Regional 1",
            "code": "",
            "auxcode": "TSH",
            "companyName": "TRANSCANADA",
            "country": "Canada"
        },
        {
            "name": "Regional Air",
            "code": "",
            "auxcode": "RIL",
            "companyName": "",
            "country": "Mauritania"
        },
        {
            "name": "Regional Air Express",
            "code": "",
            "auxcode": "REW",
            "companyName": "REGIONAL WINGS",
            "country": "Germany"
        },
        {
            "name": "Regional Air Lines",
            "code": "",
            "auxcode": "RGL",
            "companyName": "MAROC REGIONAL",
            "country": "Morocco"
        },
        {
            "name": "Regional Air Services",
            "code": "",
            "auxcode": "REG",
            "companyName": "REGIONAL SERVICES",
            "country": "Tanzania"
        },
        {
            "name": "Regional Airlines",
            "code": "FN",
            "auxcode": "",
            "companyName": "",
            "country": "Morocco"
        },
        {
            "name": "Regional Express",
            "code": "ZL",
            "auxcode": "RXA",
            "companyName": "REX",
            "country": "Australia"
        },
        {
            "name": "Regional Geodata Air",
            "code": "",
            "auxcode": "JJM",
            "companyName": "GEODATA",
            "country": "Spain"
        },
        {
            "name": "RegionsAir",
            "code": "3C",
            "auxcode": "CEA",
            "companyName": "CORPcodeX",
            "country": "United States"
        },
        {
            "name": "Reliance Aviation",
            "code": "",
            "auxcode": "REL",
            "companyName": "RELIANCE AIR",
            "country": "United States"
        },
        {
            "name": "Reliant Airlines",
            "code": "",
            "auxcode": "RLT",
            "companyName": "RELIANT",
            "country": "United States"
        },
        {
            "name": "Relief Transport Services",
            "code": "",
            "auxcode": "RTS",
            "companyName": "RELIEF",
            "country": "United Kingdom"
        },
        {
            "name": "Renan",
            "code": "",
            "auxcode": "RAN",
            "companyName": "RENAN",
            "country": "Moldova"
        },
        {
            "name": "Reno Air",
            "code": "QQ",
            "auxcode": "ROA",
            "companyName": "RENO AIR",
            "country": "United States"
        },
        {
            "name": "Renown Aviation",
            "code": "",
            "auxcode": "RGS",
            "companyName": "RENOWN",
            "country": "United States"
        },
        {
            "name": "Republic Airlines",
            "code": "RW",
            "auxcode": "RPA",
            "companyName": "BRICKYARD",
            "country": "United States"
        },
        {
            "name": "Republic Express Airlines",
            "code": "RH",
            "auxcode": "RPH",
            "companyName": "PUBLIC EXPRESS",
            "country": "Indonesia"
        },
        {
            "name": "Republicair",
            "code": "",
            "auxcode": "RBC",
            "companyName": "REPUBLICAIR",
            "country": "Mexico"
        },
        {
            "name": "Resort Air",
            "code": "",
            "auxcode": "RST",
            "companyName": "RESORT AIR",
            "country": "United States"
        },
        {
            "name": "Rhoades Aviation",
            "code": "",
            "auxcode": "RDS",
            "companyName": "RHOADES EXPRESS",
            "country": "United States"
        },
        {
            "name": "Riau Airlines",
            "code": "",
            "auxcode": "RIU",
            "companyName": "RIAU AIR",
            "country": "Indonesia"
        },
        {
            "name": "Rich International Airways",
            "code": "",
            "auxcode": "RIA",
            "companyName": "RICHAIR",
            "country": "United States"
        },
        {
            "name": "Richards Aviation",
            "code": "",
            "auxcode": "RVC",
            "companyName": "RIVER CITY",
            "country": "United States"
        },
        {
            "name": "Richardson's Airway",
            "code": "",
            "auxcode": "RIC",
            "companyName": "RICHARDSON",
            "country": "United States"
        },
        {
            "name": "Richland Aviation",
            "code": "",
            "auxcode": "RCA",
            "companyName": "RICHLAND",
            "country": "United States"
        },
        {
            "name": "Rick Lucas Helicopters",
            "code": "",
            "auxcode": "HPR",
            "companyName": "HELIPRO",
            "country": "New Zealand"
        },
        {
            "name": "Rico Linhas A",
            "code": "C7",
            "auxcode": "RLE",
            "companyName": "RICO",
            "country": "Brazil"
        },
        {
            "name": "Ridder Avia",
            "code": "",
            "auxcode": "RID",
            "companyName": "AKRID",
            "country": "Kazakhstan"
        },
        {
            "name": "Riga Airclub",
            "code": "",
            "auxcode": "RAK",
            "companyName": "SPORT CLUB",
            "country": "Latvia"
        },
        {
            "name": "Rijnmond Air Services",
            "code": "",
            "auxcode": "RAZ",
            "companyName": "RIJNMOND",
            "country": "Netherland"
        },
        {
            "name": "Rikspolisstyrelsen",
            "code": "",
            "auxcode": "POL",
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Rimrock Airlines",
            "code": "",
            "auxcode": "RIM",
            "companyName": "RIMROCK",
            "country": "United States"
        },
        {
            "name": "Rio Air Express",
            "code": "",
            "auxcode": "SKA",
            "companyName": "RIO EXPRESS",
            "country": "Brazil"
        },
        {
            "name": "Rio Airways",
            "code": "",
            "auxcode": "REO",
            "companyName": "RIO",
            "country": "United States"
        },
        {
            "name": "Rio Grande Air",
            "code": "E2",
            "auxcode": "GRN",
            "companyName": "GRANDE",
            "country": "United States"
        },
        {
            "name": "Rio Sul Servi",
            "code": "SL",
            "auxcode": "RSL",
            "companyName": "RIO SUL",
            "country": "Brazil"
        },
        {
            "name": "River Ministries Air Charter",
            "code": "",
            "auxcode": "RVM",
            "companyName": "RIVER",
            "country": "South Africa"
        },
        {
            "name": "River State Government of Nigeria",
            "code": "",
            "auxcode": "RGP",
            "companyName": "GARDEN CITY",
            "country": "Nigeria"
        },
        {
            "name": "Rivne Universal Avia",
            "code": "",
            "auxcode": "UNR",
            "companyName": "RIVNE UNIVERSAL",
            "country": "Ukraine"
        },
        {
            "name": "Roadair Lines",
            "code": "",
            "auxcode": "RDL",
            "companyName": "ROADAIR",
            "country": "Canada"
        },
        {
            "name": "Robinton Aero",
            "code": "",
            "auxcode": "RBT",
            "companyName": "ROBIN",
            "country": "Dominican Republic"
        },
        {
            "name": "Roblex Aviation",
            "code": "",
            "auxcode": "ROX",
            "companyName": "ROBLEX",
            "country": "United States"
        },
        {
            "name": "Rockwell Collins Avionics",
            "code": "",
            "auxcode": "RKW",
            "companyName": "ROCKWELL",
            "country": "United States"
        },
        {
            "name": "Rocky Mountain Airlines",
            "code": "",
            "auxcode": "ROC",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Rocky Mountain Airways",
            "code": "",
            "auxcode": "RMA",
            "companyName": "ROCKY MOUNTAIN",
            "country": "United States"
        },
        {
            "name": "Rocky Mountain Holdings",
            "code": "",
            "auxcode": "LIF",
            "companyName": "LIFECARE",
            "country": "United States"
        },
        {
            "name": "Rodze Air",
            "code": "",
            "auxcode": "RDZ",
            "companyName": "RODZE AIR",
            "country": "Nigeria"
        },
        {
            "name": "RogcodeAir",
            "code": "",
            "auxcode": "FAD",
            "companyName": "AIR FRONTIER",
            "country": "Canada"
        },
        {
            "name": "Rollright Aviation",
            "code": "",
            "auxcode": "RRZ",
            "companyName": "ROLLRIGHT",
            "country": "United Kingdom"
        },
        {
            "name": "RollscodeRoyce Limited",
            "code": "",
            "auxcode": "RRL",
            "companyName": "MERLIN",
            "country": "United Kingdom"
        },
        {
            "name": "RollscodeRoyce plc",
            "code": "",
            "auxcode": "BTU",
            "companyName": "ROLLS",
            "country": "United Kingdom"
        },
        {
            "name": "Romanian Air Force",
            "code": "",
            "auxcode": "ROF",
            "companyName": "ROMAF",
            "country": "Romania"
        },
        {
            "name": "Romavia",
            "code": "",
            "auxcode": "RMV",
            "companyName": "AEROMAVIA",
            "country": "Romania"
        },
        {
            "name": "Ronso",
            "code": "",
            "auxcode": "RNS",
            "companyName": "RONSO",
            "country": "Mexico"
        },
        {
            "name": "Roraima Airways",
            "code": "",
            "auxcode": "ROR",
            "companyName": "RORAIMA",
            "country": "Guyana"
        },
        {
            "name": "RosneftcodeBaltika",
            "code": "",
            "auxcode": "RNB",
            "companyName": "ROSBALT",
            "country": "Russia"
        },
        {
            "name": "Ross Aviation",
            "code": "",
            "auxcode": "NRG",
            "companyName": "ENERGY",
            "country": "United States"
        },
        {
            "name": "Rossair",
            "code": "",
            "auxcode": "RFS",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Rossair",
            "code": "",
            "auxcode": "RSS",
            "companyName": "ROSS CHARTER",
            "country": "South Africa"
        },
        {
            "name": "Rossair Europe",
            "code": "",
            "auxcode": "ROS",
            "companyName": "CATCHER",
            "country": "Netherlands"
        },
        {
            "name": "Rossiya",
            "code": "R4",
            "auxcode": "",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Roswell Airlines",
            "code": "",
            "auxcode": "RAL",
            "companyName": "ROSWELL",
            "country": "United States"
        },
        {
            "name": "Air Rarotonga",
            "code": "GZ",
            "auxcode": "RAR",
            "companyName": "",
            "country": "Cook Islands"
        },
        {
            "name": "Rotatur",
            "code": "",
            "auxcode": "RTR",
            "companyName": "ROTATUR",
            "country": "Brazil"
        },
        {
            "name": "Rotormotion",
            "code": "",
            "auxcode": "RKT",
            "companyName": "ROCKET",
            "country": "United Kingdom"
        },
        {
            "name": "Rotterdam Jet Center",
            "code": "",
            "auxcode": "JCR",
            "companyName": "ROTTERDAM JETCENTER",
            "country": "Netherlands"
        },
        {
            "name": "Rover Airways International",
            "code": "",
            "auxcode": "ROV",
            "companyName": "ROVERAIR",
            "country": "United States"
        },
        {
            "name": "Rovos Air",
            "code": "",
            "auxcode": "VOS",
            "companyName": "ROVOS",
            "country": "South Africa"
        },
        {
            "name": "Royal Air Cargo",
            "code": "",
            "auxcode": "RCG",
            "companyName": "ROYAL CARGO",
            "country": "South Africa"
        },
        {
            "name": "Royal Air Force",
            "code": "RR",
            "auxcode": "RFR",
            "companyName": "RAFAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Royal Air Force of Oman",
            "code": "RS",
            "auxcode": "MJN",
            "companyName": "MAJAN",
            "country": "Oman"
        },
        {
            "name": "Royal Air Force",
            "code": "",
            "auxcode": "ACW",
            "companyName": "AIR CADET",
            "country": "United Kingdom"
        },
        {
            "name": "Royal Air Force",
            "code": "",
            "auxcode": "RRR",
            "companyName": "ASCOT",
            "country": "United Kingdom"
        },
        {
            "name": "Royal Air Force",
            "code": "",
            "auxcode": "RRF",
            "companyName": "KITTY",
            "country": "United Kingdom"
        },
        {
            "name": "Royal Air Force",
            "code": "",
            "auxcode": "SHF",
            "companyName": "VORTEX",
            "country": "United Kingdom"
        },
        {
            "name": "Royal Air Freight",
            "code": "",
            "auxcode": "RAX",
            "companyName": "AIR ROYAL",
            "country": "United States"
        },
        {
            "name": "Royal Air Maroc",
            "code": "AT",
            "auxcode": "RAM",
            "companyName": "ROYALAIR MAROC",
            "country": "Morocco"
        },
        {
            "name": "Royal Airlines",
            "code": "R0",
            "auxcode": "RPK",
            "companyName": "ROYAL PAKISTAN",
            "country": "Pakistan"
        },
        {
            "name": "Royal American Airways",
            "code": "",
            "auxcode": "RLM",
            "companyName": "ROYAL AMERICAN",
            "country": "United States"
        },
        {
            "name": "Royal Aruban Airline",
            "code": "V5",
            "auxcode": "RYL",
            "companyName": "ROYAL ARUBAN",
            "country": "Aruba"
        },
        {
            "name": "Royal Australian Air Force",
            "code": "",
            "auxcode": "ASY",
            "companyName": "AUSSIE",
            "country": "Australia"
        },
        {
            "name": "Royal Aviation Express",
            "code": "",
            "auxcode": "RXP",
            "companyName": "ROY EXPRESS",
            "country": "Canada"
        },
        {
            "name": "Royal Bahrain Airlines",
            "code": "",
            "auxcode": "RYB",
            "companyName": "ROYAL BAHRAIN",
            "country": "Bahrain"
        },
        {
            "name": "Royal Brunei Airlines",
            "code": "BI",
            "auxcode": "RBA",
            "companyName": "BRUNEI",
            "country": "Brunei"
        },
        {
            "name": "Royal Daisy Airlines",
            "code": "",
            "auxcode": "KDR",
            "companyName": "DARLINES",
            "country": "Uganda"
        },
        {
            "name": "Royal Ghanaian Airlines",
            "code": "",
            "auxcode": "RGA",
            "companyName": "ROYAL GHANA",
            "country": "Ghana"
        },
        {
            "name": "Royal Jet",
            "code": "",
            "auxcode": "ROJ",
            "companyName": "ROYALJET",
            "country": "United Arab Emirates"
        },
        {
            "name": "Royal Jordanian",
            "code": "RJ",
            "auxcode": "RJA",
            "companyName": "JORDANIAN",
            "country": "Jordan"
        },
        {
            "name": "Royal Jordanian Air Force",
            "code": "",
            "auxcode": "RJZ",
            "companyName": "JORDAN AIR FORCE",
            "country": "Jordan"
        },
        {
            "name": "Royal Khmer Airlines",
            "code": "RK",
            "auxcode": "RKH",
            "companyName": "KHMER AIR",
            "country": "Cambodia"
        },
        {
            "name": "Royal Malaysian Air Force",
            "code": "",
            "auxcode": "RMF",
            "companyName": "ANGKASA",
            "country": "Malaysia"
        },
        {
            "name": "Royal Navy",
            "code": "",
            "auxcode": "NVY",
            "companyName": "NAVY",
            "country": "United Kingdom"
        },
        {
            "name": "Royal Nepal Airlines",
            "code": "RA",
            "auxcode": "RNA",
            "companyName": "ROYAL NEPAL",
            "country": "Nepal"
        },
        {
            "name": "Royal Netherland Navy",
            "code": "",
            "auxcode": "NRN",
            "companyName": "NETHERLANDS NAVY",
            "country": "Netherlands"
        },
        {
            "name": "Royal Netherlands Air Force",
            "code": "",
            "auxcode": "NAF",
            "companyName": "NETHERLANDS AIR FORCE",
            "country": "Netherlands"
        },
        {
            "name": "Royal New Zealand Air Force",
            "code": "",
            "auxcode": "KIW",
            "companyName": "KIWI",
            "country": "New Zealand"
        },
        {
            "name": "Royal Norwegian Air Force",
            "code": "",
            "auxcode": "NOW",
            "companyName": "NORWEGIAN",
            "country": "Norway"
        },
        {
            "name": "Royal Oman Police",
            "code": "",
            "auxcode": "ROP",
            "companyName": "",
            "country": "Oman"
        },
        {
            "name": "Royal Phnom Penh Airways",
            "code": "",
            "auxcode": "PPW",
            "companyName": "PHNOMcodePENH AIR",
            "country": "Cambodia"
        },
        {
            "name": "Royal Rwanda Airlines",
            "code": "",
            "auxcode": "RRA",
            "companyName": "ROYAL RWANDA",
            "country": "Rwanda"
        },
        {
            "name": "Royal Saudi Air Force",
            "code": "",
            "auxcode": "RSF",
            "companyName": "ARSAF",
            "country": "Saudi Arabia"
        },
        {
            "name": "Royal Sky",
            "code": "",
            "auxcode": "RYS",
            "companyName": "ROYAL SKY",
            "country": "Thailand"
        },
        {
            "name": "Royal Swazi National Airways",
            "code": "",
            "auxcode": "RSN",
            "companyName": "SWAZI NATIONAL",
            "country": "Swaziland"
        },
        {
            "name": "Royal Tongan Airlines",
            "code": "WR",
            "auxcode": "HRH",
            "companyName": "TONGA ROYAL",
            "country": "Tonga"
        },
        {
            "name": "Royal West Airlines",
            "code": "",
            "auxcode": "RWE",
            "companyName": "ROYAL WEST",
            "country": "United States"
        },
        {
            "name": "Rubystar",
            "code": "",
            "auxcode": "RSB",
            "companyName": "RUBYSTAR",
            "country": "Belarus"
        },
        {
            "name": "Rumugu Air & Space Nigeria",
            "code": "",
            "auxcode": "RMG",
            "companyName": "RUMUGU AIR",
            "country": "Nigeria"
        },
        {
            "name": "Rusaero",
            "code": "",
            "auxcode": "RUR",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Rusaero",
            "code": "",
            "auxcode": "KLE",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Rusair JSAC",
            "code": "",
            "auxcode": "CGI",
            "companyName": "CGIcodeRUSAIR",
            "country": "Russia"
        },
        {
            "name": "RusichcodeT",
            "code": "",
            "auxcode": "RUH",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Rusline",
            "code": "",
            "auxcode": "RLU",
            "companyName": "RUSLINE AIR",
            "country": "Russia"
        },
        {
            "name": "Russian Aircraft CorporationcodeMiG",
            "code": "",
            "auxcode": "MIG",
            "companyName": "MIG AVIA",
            "country": "Russia"
        },
        {
            "name": "Russian Federation Air Force",
            "code": "",
            "auxcode": "RFF",
            "companyName": "RUSSIAN AIRFORCE",
            "country": "Russia"
        },
        {
            "name": "Russian Sky Airlines",
            "code": "P7",
            "auxcode": "ESL",
            "companyName": "RADUGA",
            "country": "Russia"
        },
        {
            "name": "Rusuertol",
            "code": "",
            "auxcode": "RUZ",
            "companyName": "ROSTUERTOL",
            "country": "Russia"
        },
        {
            "name": "Rutas Aereas",
            "code": "",
            "auxcode": "RUC",
            "companyName": "RUTACA",
            "country": "Venezuela"
        },
        {
            "name": "Rutland Aviation",
            "code": "",
            "auxcode": "RND",
            "companyName": "RUTLAND",
            "country": "United Kingdom"
        },
        {
            "name": "Rwanda Airlines",
            "code": "",
            "auxcode": "RUA",
            "companyName": "",
            "country": "Rwanda"
        },
        {
            "name": "Rwanda Airways",
            "code": "",
            "auxcode": "RWA",
            "companyName": "",
            "country": "Rwanda"
        },
        {
            "name": "Rwandair Express",
            "code": "WB",
            "auxcode": "RWD",
            "companyName": "RWANDAIR",
            "country": "Rwanda"
        },
        {
            "name": "Ryan Air Service",
            "code": "",
            "auxcode": "RCT",
            "companyName": "ARCTIC TRANPORT",
            "country": "United States"
        },
        {
            "name": "Ryan Air Services",
            "code": "",
            "auxcode": "RYA",
            "companyName": "RYAN AIR",
            "country": "United States"
        },
        {
            "name": "Ryan International Airlines",
            "code": "RD",
            "auxcode": "RYN",
            "companyName": "RYAN INTERNATIONAL",
            "country": "United States"
        },
        {
            "name": "Ryanair",
            "code": "FR",
            "auxcode": "RYR",
            "companyName": "RYANAIR",
            "country": "Ireland"
        },
        {
            "name": "Ryazan State Air Enterprise",
            "code": "",
            "auxcode": "RYZ",
            "companyName": "RYAZAN AIR",
            "country": "Russia"
        },
        {
            "name": "Rynes Aviation",
            "code": "",
            "auxcode": "RAA",
            "companyName": "RYNES AVIATION",
            "country": "United States"
        },
        {
            "name": "Régional",
            "code": "YS",
            "auxcode": "RAE",
            "companyName": "REGIONAL EUROPE",
            "country": "France"
        },
        {
            "name": "South Asian Airlines",
            "code": "",
            "auxcode": "BDS",
            "companyName": "SOUTH ASIAN",
            "country": "Bangladesh"
        },
        {
            "name": "State Air Company Berkut",
            "code": "",
            "auxcode": "BEC",
            "companyName": "",
            "country": "Kazakhstan"
        },
        {
            "name": "SATA International",
            "code": "S4",
            "auxcode": "RZO",
            "companyName": "AIR AZORES",
            "country": "Portugal"
        },
        {
            "name": "South African Airways",
            "code": "SA",
            "auxcode": "SAA",
            "companyName": "SPRINGBOK",
            "country": "South Africa"
        },
        {
            "name": "Sky Way Air",
            "code": "",
            "auxcode": "SAB",
            "companyName": "SKY WORKER",
            "country": "Kyrgyzstan"
        },
        {
            "name": "SASCO Airlines",
            "code": "",
            "auxcode": "SAC",
            "companyName": "SASCO",
            "country": "Sudan"
        },
        {
            "name": "Republic of Singapore Air Force",
            "code": "",
            "auxcode": "SAF",
            "companyName": "SINGA",
            "country": "Singapore"
        },
        {
            "name": "SOS Flygambulans",
            "code": "",
            "auxcode": "SAG",
            "companyName": "MEDICAL AIR",
            "country": "Sweden"
        },
        {
            "name": "Sayakhat Airlines",
            "code": "",
            "auxcode": "SAH",
            "companyName": "SAYAKHAT",
            "country": "Kazakhstan"
        },
        {
            "name": "Shaheen Air International",
            "code": "NL",
            "auxcode": "SAI",
            "companyName": "SHAHEEN AIR",
            "country": "Pakistan"
        },
        {
            "name": "Golden Eagle Air Services (FAA)",
            "code": "",
            "auxcode": "SAJ",
            "companyName": "SAJEN",
            "country": "Canada"
        },
        {
            "name": "Red Arrows Display Squadron",
            "code": "",
            "auxcode": "SAK",
            "companyName": "RED ARROWS",
            "country": "United Kingdom"
        },
        {
            "name": "SAM Colombia",
            "code": "",
            "auxcode": "SAM",
            "companyName": "SAM",
            "country": "Colombia"
        },
        {
            "name": "Servicios Aereos Nacionales (SAN)",
            "code": "",
            "auxcode": "SAN",
            "companyName": "AEREOS",
            "country": "Ecuador"
        },
        {
            "name": "Sahel Aviation Service",
            "code": "",
            "auxcode": "SAO",
            "companyName": "SAVSER",
            "country": "Mali"
        },
        {
            "name": "Secretaria de Marina",
            "code": "",
            "auxcode": "ANX",
            "companyName": "SECRETARIA DEMARINA",
            "country": "Mexico"
        },
        {
            "name": "Springbank Aviation",
            "code": "",
            "auxcode": "SAQ",
            "companyName": "SPRINGBANK",
            "country": "Canada"
        },
        {
            "name": "Scandinavian Airlines System",
            "code": "SK",
            "auxcode": "SAS",
            "companyName": "SCANDINAVIAN",
            "country": "Sweden"
        },
        {
            "name": "Samal Air",
            "code": "",
            "auxcode": "SAV",
            "companyName": "",
            "country": "Kazakhstan"
        },
        {
            "name": "Sham Wing Airlines",
            "code": "",
            "auxcode": "SAW",
            "companyName": "SHAMWING",
            "country": "Syrian Arab Republic"
        },
        {
            "name": "Sabah Air",
            "code": "",
            "auxcode": "SAX",
            "companyName": "SABAH AIR",
            "country": "Malaysia"
        },
        {
            "name": "ScotAirways",
            "code": "",
            "auxcode": "SAY",
            "companyName": "SUCKLING",
            "country": "United Kingdom"
        },
        {
            "name": "Swiss AircodeAmbulance",
            "code": "",
            "auxcode": "SAZ",
            "companyName": "SWISS AMBULANCE",
            "country": "Switzerland"
        },
        {
            "name": "STAcodeMALI",
            "code": "",
            "auxcode": "SBA",
            "companyName": "STAcodeMALI",
            "country": "Mali"
        },
        {
            "name": "Steinman Aviation",
            "code": "",
            "auxcode": "SBB",
            "companyName": "SABER EXPRESS",
            "country": "United States"
        },
        {
            "name": "Seven Bar Flying Service",
            "code": "",
            "auxcode": "SBF",
            "companyName": "ScodeBAR",
            "country": "United States"
        },
        {
            "name": "Sabre Incorporated",
            "code": "",
            "auxcode": "SBG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "S7 Airlines",
            "code": "S7",
            "auxcode": "SBI",
            "companyName": "SIBERIAN AIRLINES",
            "country": "Russia"
        },
        {
            "name": "Sobel Airlines of Ghana",
            "code": "",
            "auxcode": "SBL",
            "companyName": "SOBGHANA",
            "country": "Ghana"
        },
        {
            "name": "Sky Bahamas",
            "code": "",
            "auxcode": "SBM",
            "companyName": "SKY BAHAMAS",
            "country": "Bahamas"
        },
        {
            "name": "Stabo Air",
            "code": "",
            "auxcode": "SBO",
            "companyName": "STABAIR",
            "country": "Zambia"
        },
        {
            "name": "Smithkline Beacham Clinical Labs",
            "code": "",
            "auxcode": "SBQ",
            "companyName": "SKIBBLE",
            "country": "United States"
        },
        {
            "name": "Saber Aviation",
            "code": "",
            "auxcode": "SBR",
            "companyName": "FREIGHTER",
            "country": "United States"
        },
        {
            "name": "Seaborne Airlines",
            "code": "BB",
            "auxcode": "SBS",
            "companyName": "SEABORNE",
            "country": "United States"
        },
        {
            "name": "Saint Barth Commuter",
            "code": "",
            "auxcode": "SBU",
            "companyName": "BLACK FIN",
            "country": "France"
        },
        {
            "name": "Star Air",
            "code": "",
            "auxcode": "URJ",
            "companyName": "STARAV",
            "country": "Pakistan"
        },
        {
            "name": "Scibe Airlift",
            "code": "",
            "auxcode": "SBZ",
            "companyName": "SCIBE AIRLIFT",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Spanish Air Force",
            "code": "",
            "auxcode": "AME",
            "companyName": "AIRMIL",
            "country": "Spain"
        },
        {
            "name": "South Central Air",
            "code": "",
            "auxcode": "SCA",
            "companyName": "SOUTH CENTRAL",
            "country": "United States"
        },
        {
            "name": "Seacoast Airlines",
            "code": "",
            "auxcode": "SCC",
            "companyName": "SEAcodeCOASTER",
            "country": "United States"
        },
        {
            "name": "Scenic Airlines",
            "code": "",
            "auxcode": "SCE",
            "companyName": "SCENIC",
            "country": "United States"
        },
        {
            "name": "Socofer",
            "code": "",
            "auxcode": "SCF",
            "companyName": "SOCOFER",
            "country": "Angola"
        },
        {
            "name": "Servicios Aereos San Cristobal",
            "code": "",
            "auxcode": "SCI",
            "companyName": "SAN CRISTOBAL",
            "country": "Mexico"
        },
        {
            "name": "Sky Cam",
            "code": "",
            "auxcode": "SCK",
            "companyName": "SKYCAM",
            "country": "France"
        },
        {
            "name": "Switfair Cargo",
            "code": "",
            "auxcode": "SCL",
            "companyName": "SWIFTAIR",
            "country": "Canada"
        },
        {
            "name": "South American Airlines",
            "code": "",
            "auxcode": "SCN",
            "companyName": "SOUTH AMERICAN",
            "country": "Peru"
        },
        {
            "name": "Servicios Aereos de Chihuahua Aerochisa",
            "code": "",
            "auxcode": "AHI",
            "companyName": "AEROCHISA",
            "country": "Mexico"
        },
        {
            "name": "SriLankan Airlines",
            "code": "UL",
            "auxcode": "ALK",
            "companyName": "SRILANKAN",
            "country": "Sri Lanka"
        },
        {
            "name": "Sunbird Airlines",
            "code": "",
            "auxcode": "CDL",
            "companyName": "CAROLINA",
            "country": "United States"
        },
        {
            "name": "Scorpio",
            "code": "",
            "auxcode": "SCP",
            "companyName": "SCORPIO",
            "country": "Egypt"
        },
        {
            "name": "SicodeChang Flying Service",
            "code": "",
            "auxcode": "SCR",
            "companyName": "SICHART",
            "country": "Thailand"
        },
        {
            "name": "South African Non Scheduled Airways",
            "code": "",
            "auxcode": "SCS",
            "companyName": "SOUTHERN CHARTERS",
            "country": "South Africa"
        },
        {
            "name": "SAABcodeAircraft",
            "code": "",
            "auxcode": "SCT",
            "companyName": "SAABcodeCRAFT",
            "country": "Sweden"
        },
        {
            "name": "Servicios Aereos Del Centro",
            "code": "",
            "auxcode": "SCV",
            "companyName": "SACSA",
            "country": "Mexico"
        },
        {
            "name": "Sun Country Airlines",
            "code": "SY",
            "auxcode": "SCX",
            "companyName": "SUN COUNTRY",
            "country": "United States"
        },
        {
            "name": "St. Andrews Airways",
            "code": "",
            "auxcode": "SDA",
            "companyName": "SAINT ANDREWS",
            "country": "Canada"
        },
        {
            "name": "Sukhoi Design Bureau Company",
            "code": "",
            "auxcode": "SDB",
            "companyName": "SUcodeCRAFT",
            "country": "Russia"
        },
        {
            "name": "Sunrise Airlines",
            "code": "",
            "auxcode": "SDC",
            "companyName": "SUNDANCE",
            "country": "United States"
        },
        {
            "name": "Skymaster Air Taxi",
            "code": "",
            "auxcode": "SDD",
            "companyName": "SKY DANCE",
            "country": "United States"
        },
        {
            "name": "Sundorph Aeronautical Corporation",
            "code": "",
            "auxcode": "SDF",
            "companyName": "SUNDORPH",
            "country": "United States"
        },
        {
            "name": "Servicio De Helicopteros",
            "code": "",
            "auxcode": "SDH",
            "companyName": "ARCOS",
            "country": "Spain"
        },
        {
            "name": "SADELCA code Sociedad Aerea Del Caqueta",
            "code": "",
            "auxcode": "SDK",
            "companyName": "SADELCA",
            "country": "Colombia"
        },
        {
            "name": "Skydrift",
            "code": "",
            "auxcode": "SDL",
            "companyName": "SKYDRIFT",
            "country": "United Kingdom"
        },
        {
            "name": "Spirit of Africa Airlines",
            "code": "",
            "auxcode": "SDN",
            "companyName": "BLUE NILE",
            "country": "Sudan"
        },
        {
            "name": "Sud Airlines",
            "code": "",
            "auxcode": "SDU",
            "companyName": "SUD LINES",
            "country": "France"
        },
        {
            "name": "Servicios Aereos Del Vaupes",
            "code": "",
            "auxcode": "SDV",
            "companyName": "SELVA",
            "country": "Colombia"
        },
        {
            "name": "Servicio Tecnico Aero De Mexico",
            "code": "",
            "auxcode": "SDX",
            "companyName": "SERVICIO TECNICO",
            "country": "Mexico"
        },
        {
            "name": "Sudan Pezetel for Aviation",
            "code": "",
            "auxcode": "SDZ",
            "companyName": "SUDANA",
            "country": "Sudan"
        },
        {
            "name": "Southeast Air",
            "code": "",
            "auxcode": "SEA",
            "companyName": "SOUTHEAST AIR",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Luce",
            "code": "",
            "auxcode": "SEB",
            "companyName": "SERVILUCE",
            "country": "Mexico"
        },
        {
            "name": "Sedona Air Center",
            "code": "",
            "auxcode": "SED",
            "companyName": "SEDONA AIR",
            "country": "United States"
        },
        {
            "name": "Shaheen Air Cargo",
            "code": "",
            "auxcode": "SEE",
            "companyName": "SHAHEEN CARGO",
            "country": "Pakistan"
        },
        {
            "name": "Sky Express",
            "code": "G3",
            "auxcode": "SEH",
            "companyName": "AIR CRETE",
            "country": "Greece"
        },
        {
            "name": "Spicejet",
            "code": "SG",
            "auxcode": "SEJ",
            "companyName": "SPICEJET",
            "country": "India"
        },
        {
            "name": "Skyjet",
            "code": "",
            "auxcode": "SEK",
            "companyName": "SKALA",
            "country": "Kazakhstan"
        },
        {
            "name": "Sentel Corporation",
            "code": "",
            "auxcode": "SEL",
            "companyName": "SENTEL",
            "country": "United States"
        },
        {
            "name": "Selcon Airlines",
            "code": "",
            "auxcode": "SEO",
            "companyName": "SELCON AIR",
            "country": "Nigeria"
        },
        {
            "name": "Sky Eyes",
            "code": "I6",
            "auxcode": "SEQ",
            "companyName": "SKY EYES",
            "country": "Thailand"
        },
        {
            "name": "Servicio Aereo Saltillo",
            "code": "",
            "auxcode": "SES",
            "companyName": "SERVISAL",
            "country": "Mexico"
        },
        {
            "name": "SAETA",
            "code": "EH",
            "auxcode": "SET",
            "companyName": "SAETA",
            "country": "Ecuador"
        },
        {
            "name": "Serair Transworld Press",
            "code": "",
            "auxcode": "SEV",
            "companyName": "CARGOPRESS",
            "country": "Spain"
        },
        {
            "name": "SEFA",
            "code": "",
            "auxcode": "SFA",
            "companyName": "SEFA",
            "country": "France"
        },
        {
            "name": "Shuswap Flight Centre",
            "code": "",
            "auxcode": "SFC",
            "companyName": "SHUSWAP",
            "country": "Canada"
        },
        {
            "name": "Sefofane Air Charters",
            "code": "",
            "auxcode": "SFE",
            "companyName": "SEFOFANE",
            "country": "South Africa"
        },
        {
            "name": "Safewings Aviation Company",
            "code": "",
            "auxcode": "SFF",
            "companyName": "SWIFTWING",
            "country": "United States"
        },
        {
            "name": "Sun Freight Logistics",
            "code": "",
            "auxcode": "SFG",
            "companyName": "AERO GULF",
            "country": "Thailand"
        },
        {
            "name": "Star Flyer",
            "code": "7G",
            "auxcode": "SFJ",
            "companyName": "STARFLYER",
            "country": "Japan"
        },
        {
            "name": "Southflight Aviation",
            "code": "",
            "auxcode": "SFL",
            "companyName": "SOUTHFLIGHT",
            "country": "New Zealand"
        },
        {
            "name": "Safiran Airlines",
            "code": "",
            "auxcode": "SFN",
            "companyName": "SAFIRAN",
            "country": "Iran"
        },
        {
            "name": "Safe Air",
            "code": "",
            "auxcode": "SFP",
            "companyName": "SAFE AIR",
            "country": "Pakistan"
        },
        {
            "name": "Safair",
            "code": "FA",
            "auxcode": "SFR",
            "companyName": "CARGO",
            "country": "South Africa"
        },
        {
            "name": "Southern Frontier Air Transport",
            "code": "",
            "auxcode": "SFS",
            "companyName": "SOUTHERN FRONTIER",
            "country": "Canada"
        },
        {
            "name": "Skyfreight",
            "code": "",
            "auxcode": "SFT",
            "companyName": "SKYFREIGHT",
            "country": "United States"
        },
        {
            "name": "Solent Flight",
            "code": "",
            "auxcode": "SFU",
            "companyName": "SAINTS",
            "country": "United Kingdom"
        },
        {
            "name": "S.K. Logistics",
            "code": "",
            "auxcode": "SFX",
            "companyName": "SWAMP FOX",
            "country": "United States"
        },
        {
            "name": "Sky King",
            "code": "",
            "auxcode": "SGB",
            "companyName": "SONGBIRD",
            "country": "United States"
        },
        {
            "name": "Southern Right Air Charter",
            "code": "",
            "auxcode": "SGC",
            "companyName": "SOUTHERNRIGHT",
            "country": "South Africa"
        },
        {
            "name": "Sky Gate International Aviation",
            "code": "",
            "auxcode": "SGD",
            "companyName": "AIR BISHKEK",
            "country": "Kyrgyzstan"
        },
        {
            "name": "STAC Swiss Government Flights",
            "code": "",
            "auxcode": "SGF",
            "companyName": "STAC",
            "country": "Switzerland"
        },
        {
            "name": "Servisair",
            "code": "",
            "auxcode": "SGH",
            "companyName": "SERVISAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Servicios Aereos Agricolas",
            "code": "",
            "auxcode": "SGI",
            "companyName": "SERAGRI",
            "country": "Chile"
        },
        {
            "name": "Skyward Aviation",
            "code": "",
            "auxcode": "SGK",
            "companyName": "SKYWARD",
            "country": "Canada"
        },
        {
            "name": "Sky Aircraft Service",
            "code": "",
            "auxcode": "SGM",
            "companyName": "SIGMA",
            "country": "Netherlands"
        },
        {
            "name": "Siam GA",
            "code": "",
            "auxcode": "SGN",
            "companyName": "SIAM",
            "country": "Thailand"
        },
        {
            "name": "Sagolair Transportes Ejecutivos",
            "code": "",
            "auxcode": "SGP",
            "companyName": "SAGOLAIR",
            "country": "Spain"
        },
        {
            "name": "Saskatchewan Government Executive Air Service",
            "code": "",
            "auxcode": "SGS",
            "companyName": "SASKATCHEWAN",
            "country": "Canada"
        },
        {
            "name": "Skygate",
            "code": "",
            "auxcode": "SGT",
            "companyName": "SKYGATE",
            "country": "Netherlands"
        },
        {
            "name": "Samgau",
            "code": "",
            "auxcode": "SGU",
            "companyName": "RAUSHAN",
            "country": "Kazakhstan"
        },
        {
            "name": "Saga Airlines",
            "code": "",
            "auxcode": "SGX",
            "companyName": "",
            "country": "Turkey"
        },
        {
            "name": "Skagway Air Service",
            "code": "N5",
            "auxcode": "SGY",
            "companyName": "SKAGWAY AIR",
            "country": "United States"
        },
        {
            "name": "Shabair",
            "code": "",
            "auxcode": "SHB",
            "companyName": "SHABAIR",
            "country": "Democratic Republic of the Congo"
        },
        {
            "name": "Sky Harbor Air Service",
            "code": "",
            "auxcode": "SHC",
            "companyName": "SKY HARBOR CHEYENNE",
            "country": "United States"
        },
        {
            "name": "Sahara Airlines",
            "code": "",
            "auxcode": "SHD",
            "companyName": "",
            "country": "Algeria"
        },
        {
            "name": "Shell Aircraft",
            "code": "",
            "auxcode": "SHE",
            "companyName": "SHELL",
            "country": "United Kingdom"
        },
        {
            "name": "Shoprite Group",
            "code": "",
            "auxcode": "SHG",
            "companyName": "SHOP AIR",
            "country": "United Kingdom"
        },
        {
            "name": "Seoul Air International",
            "code": "",
            "auxcode": "SHI",
            "companyName": "SEOUL AIR",
            "country": "Republic of Korea"
        },
        {
            "name": "Sharjah Ruler's Flight",
            "code": "",
            "auxcode": "SHJ",
            "companyName": "SHARJAH",
            "country": "United Arab Emirates"
        },
        {
            "name": "Shorouk Air",
            "code": "",
            "auxcode": "SHK",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Samson Aviation",
            "code": "",
            "auxcode": "SHL",
            "companyName": "SAMSON",
            "country": "United Kingdom"
        },
        {
            "name": "Sheltam Aviation",
            "code": "",
            "auxcode": "SHM",
            "companyName": "SHELTAM",
            "country": "South Africa"
        },
        {
            "name": "Shaheen Airport Services",
            "code": "",
            "auxcode": "SHN",
            "companyName": "SUGAR ALFA",
            "country": "Pakistan"
        },
        {
            "name": "SheremetyevocodeCargo",
            "code": "",
            "auxcode": "SHO",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Service Aerien Francais",
            "code": "",
            "auxcode": "SHP",
            "companyName": "SAF",
            "country": "France"
        },
        {
            "name": "Shanghai Airlines Cargo",
            "code": "",
            "auxcode": "SHQ",
            "companyName": "SHANGHAI CARGO",
            "country": "China"
        },
        {
            "name": "Shooter Air Courier",
            "code": "",
            "auxcode": "SHR",
            "companyName": "SHOOTER",
            "country": "Canada"
        },
        {
            "name": "Shura Air Transport Services",
            "code": "",
            "auxcode": "SHS",
            "companyName": "SHURA AIR",
            "country": "Ethiopia"
        },
        {
            "name": "Sakhalinskie Aviatrassy (SAT)",
            "code": "",
            "auxcode": "SHU",
            "companyName": "SATAIR",
            "country": "Russia"
        },
        {
            "name": "SATA Air Acores",
            "code": "SP",
            "auxcode": "SAT",
            "companyName": "SATA",
            "country": "Portugal"
        },
        {
            "name": "Scorpio Aviation",
            "code": "8S",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "Shavano Air",
            "code": "",
            "auxcode": "SHV",
            "companyName": "SHAVANO",
            "country": "United States"
        },
        {
            "name": "Shawnee Airline",
            "code": "",
            "auxcode": "SHW",
            "companyName": "SHAWNEE",
            "country": "United States"
        },
        {
            "name": "Slim Aviation Services",
            "code": "",
            "auxcode": "SHX",
            "companyName": "SLIM AIR",
            "country": "Nigeria"
        },
        {
            "name": "Sky Airlines",
            "code": "",
            "auxcode": "SHY",
            "companyName": "ANTALYA BIRD",
            "country": "Turkey"
        },
        {
            "name": "Singapore Airlines",
            "code": "SQ",
            "auxcode": "SIA",
            "companyName": "SINGAPORE",
            "country": "Singapore"
        },
        {
            "name": "Sibaviatrans",
            "code": "5M",
            "auxcode": "SIB",
            "companyName": "SIBAVIA",
            "country": "Russia"
        },
        {
            "name": "Sierra Express",
            "code": "",
            "auxcode": "SIE",
            "companyName": "SEREX",
            "country": "United States"
        },
        {
            "name": "Skynet Airlines",
            "code": "SI",
            "auxcode": "SIH",
            "companyName": "BLUEJET",
            "country": "Ireland"
        },
        {
            "name": "Seco International",
            "code": "",
            "auxcode": "SIJ",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Servicios Aeronauticos Integrales",
            "code": "",
            "auxcode": "SIL",
            "companyName": "SERVICIOS INTEGRALES",
            "country": "Mexico"
        },
        {
            "name": "Star Air",
            "code": "",
            "auxcode": "SIM",
            "companyName": "",
            "country": "Sierra Leone"
        },
        {
            "name": "Sirio",
            "code": "",
            "auxcode": "SIO",
            "companyName": "SIRIO",
            "country": "Italy"
        },
        {
            "name": "Salair",
            "code": "",
            "auxcode": "SIR",
            "companyName": "SALAIR",
            "country": "United States"
        },
        {
            "name": "Saber Airlines",
            "code": "",
            "auxcode": "SIS",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "SITA",
            "code": "XS",
            "auxcode": "SIT",
            "companyName": "",
            "country": "Belgium"
        },
        {
            "name": "Slovenian Armed Forces",
            "code": "",
            "auxcode": "SIV",
            "companyName": "SLOVENIAN",
            "country": "Slovenia"
        },
        {
            "name": "Sirio Executive",
            "code": "",
            "auxcode": "SIW",
            "companyName": "SIRIO EXECUTIVE",
            "country": "Italy"
        },
        {
            "name": "Servicios Aereos Especiales De Jalisco",
            "code": "",
            "auxcode": "SJA",
            "companyName": "SERVICIOJAL",
            "country": "Mexico"
        },
        {
            "name": "Servicios Ejecutivos Continental",
            "code": "",
            "auxcode": "SJC",
            "companyName": "SERVIEJECUTIVO",
            "country": "Mexico"
        },
        {
            "name": "Sunair 2001",
            "code": "",
            "auxcode": "SJE",
            "companyName": "SUNBIZ",
            "country": "South Africa"
        },
        {
            "name": "Spirit Aviation",
            "code": "",
            "auxcode": "SJJ",
            "companyName": "SPIRIT JET",
            "country": "United States"
        },
        {
            "name": "Servicios Especiales Del Pacifico Jalisco",
            "code": "",
            "auxcode": "SJL",
            "companyName": "SERVICIOS JALISCO",
            "country": "Mexico"
        },
        {
            "name": "Swiss Jet",
            "code": "",
            "auxcode": "SJT",
            "companyName": "SWISS JET",
            "country": "Switzerland"
        },
        {
            "name": "Sriwijaya Air",
            "code": "SJ",
            "auxcode": "SJY",
            "companyName": "SRIWIJAYA",
            "country": "Indonesia"
        },
        {
            "name": "Sama Airlines",
            "code": "ZS",
            "auxcode": "SMY",
            "companyName": "NAJIM",
            "country": "Saudi Arabia"
        },
        {
            "name": "Southern Jersey Airways",
            "code": "",
            "auxcode": "ALC",
            "companyName": " Inc.",
            "country": "ACOM"
        },
        {
            "name": "SPASA",
            "code": "",
            "auxcode": "SPS",
            "companyName": "SALDUERO",
            "country": "Spain"
        },
        {
            "name": "Speed Aviation",
            "code": "",
            "auxcode": "SPT",
            "companyName": "SPEED AVIATION",
            "country": "Bangladesh"
        },
        {
            "name": "Southeast Airmotive",
            "code": "",
            "auxcode": "SPU",
            "companyName": "SPUTTER",
            "country": "United States"
        },
        {
            "name": "Servicios Privados De Aviacion",
            "code": "",
            "auxcode": "SPV",
            "companyName": "SERVICIOS PRIVADOS",
            "country": "Mexico"
        },
        {
            "name": "Speedwings",
            "code": "",
            "auxcode": "SPW",
            "companyName": "SPEEDWING",
            "country": "Switzerland"
        },
        {
            "name": "Service People Gesselschaft fur Charter und Service",
            "code": "",
            "auxcode": "SPX",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Slovak National Aeroclub",
            "code": "",
            "auxcode": "SQA",
            "companyName": "SLOVAK AEROCLUB",
            "country": "Slovakia"
        },
        {
            "name": "Singapore Airlines Cargo",
            "code": "SQ",
            "auxcode": "SQC",
            "companyName": "SINGCARGO",
            "country": "Singapore"
        },
        {
            "name": "Slovak Air Force",
            "code": "",
            "auxcode": "SQF",
            "companyName": "SLOVAK AIRFORCE",
            "country": "Slovakia"
        },
        {
            "name": "Servicos De Alquiler",
            "code": "",
            "auxcode": "SQL",
            "companyName": "ALQUILER",
            "country": "Mexico"
        },
        {
            "name": "Sair Aviation",
            "code": "",
            "auxcode": "SRA",
            "companyName": "SAIR",
            "country": "Canada"
        },
        {
            "name": "Searca",
            "code": "",
            "auxcode": "SRC",
            "companyName": "SEARCA",
            "country": "Colombia"
        },
        {
            "name": "Siem Reap Airways",
            "code": "FT",
            "auxcode": "SRH",
            "companyName": "SIEMREAP AIR",
            "country": "Cambodia"
        },
        {
            "name": "Sky Work Airlines",
            "code": "SX",
            "auxcode": "SRK",
            "companyName": "SKYFOX",
            "country": "Switzerland"
        },
        {
            "name": "Swedline Express",
            "code": "SM",
            "auxcode": "SRL",
            "companyName": "Starline",
            "country": "Sweden"
        },
        {
            "name": "Servicios Aeronauticos Aero Personal",
            "code": "",
            "auxcode": "SRL",
            "companyName": "SERVICIOS PERSONAL",
            "country": "Mexico"
        },
        {
            "name": "Sirair",
            "code": "",
            "auxcode": "SRN",
            "companyName": "SIRAIR",
            "country": "Russia"
        },
        {
            "name": "Servicios Aereos Ejecutivos Saereo",
            "code": "",
            "auxcode": "SRO",
            "companyName": "SAEREO",
            "country": "Ecuador"
        },
        {
            "name": "South East Asian Airlines",
            "code": "DG",
            "auxcode": "SRQ",
            "companyName": "SEAIR",
            "country": "Philippines"
        },
        {
            "name": "Star Air",
            "code": "",
            "auxcode": "SRR",
            "companyName": "WHITESTAR",
            "country": "Denmark"
        },
        {
            "name": "Selkirk Remote Sensing",
            "code": "",
            "auxcode": "SRS",
            "companyName": "PHOTO CHARLIE",
            "country": "Canada"
        },
        {
            "name": "Star Up",
            "code": "",
            "auxcode": "SRU",
            "companyName": "STARcodeUP",
            "country": "Peru"
        },
        {
            "name": "Sarit Airlines",
            "code": "",
            "auxcode": "SRW",
            "companyName": "SARIA",
            "country": "Sudan"
        },
        {
            "name": "Sierra Expressway Airlines",
            "code": "",
            "auxcode": "SRX",
            "companyName": "SIERRA EX",
            "country": "United States"
        },
        {
            "name": "Strato Air Services",
            "code": "",
            "auxcode": "SRZ",
            "companyName": "STRATO",
            "country": "South Africa"
        },
        {
            "name": "Sasair",
            "code": "",
            "auxcode": "SSB",
            "companyName": "SASIR",
            "country": "Canada"
        },
        {
            "name": "Southern Seaplane",
            "code": "",
            "auxcode": "SSC",
            "companyName": "SOUTHERN SKIES",
            "country": "United States"
        },
        {
            "name": "Star Service International",
            "code": "",
            "auxcode": "SSD",
            "companyName": "STAR SERVICE",
            "country": "France"
        },
        {
            "name": "Servicios Aereos Sunset",
            "code": "",
            "auxcode": "SSE",
            "companyName": "SUNSET",
            "country": "Mexico"
        },
        {
            "name": "Severstal",
            "code": "",
            "auxcode": "SSF",
            "companyName": "SEVERSTAL",
            "country": "Russia"
        },
        {
            "name": "Slovak Government Flying Service",
            "code": "",
            "auxcode": "SSG",
            "companyName": "SLOVAK GOVERNMENT",
            "country": "Slovakia"
        },
        {
            "name": "SwedJet Airways",
            "code": "VD",
            "auxcode": "BBB",
            "companyName": "BLACKBIRD",
            "country": "Sweden"
        },
        {
            "name": "Skystar International",
            "code": "",
            "auxcode": "SSK",
            "companyName": "SKYSTAR",
            "country": "United States"
        },
        {
            "name": "Special Scope Limited",
            "code": "",
            "auxcode": "SSO",
            "companyName": "DOPE",
            "country": "United Kingdom"
        },
        {
            "name": "Starspeed",
            "code": "",
            "auxcode": "SSP",
            "companyName": "STARSPEED",
            "country": "United Kingdom"
        },
        {
            "name": "Sunstate Airlines",
            "code": "",
            "auxcode": "SSQ",
            "companyName": "SUNSTATE",
            "country": "Australia"
        },
        {
            "name": "SAESA",
            "code": "",
            "auxcode": "SSS",
            "companyName": "SAESA",
            "country": "Spain"
        },
        {
            "name": "Sunwest Airlines",
            "code": "",
            "auxcode": "SST",
            "companyName": "SUNFLIGHT",
            "country": "Canada"
        },
        {
            "name": "SASCA",
            "code": "",
            "auxcode": "SSU",
            "companyName": "SASCA",
            "country": "Venezuela"
        },
        {
            "name": "Skyservice Airlines",
            "code": "5G",
            "auxcode": "SSV",
            "companyName": "SKYTOUR",
            "country": "Canada"
        },
        {
            "name": "Streamline Aviation",
            "code": "",
            "auxcode": "SSW",
            "companyName": "STREAMLINE",
            "country": "United Kingdom"
        },
        {
            "name": "Sky Aviation",
            "code": "",
            "auxcode": "SSY",
            "companyName": "SIERRA SKY",
            "country": "Sierra Leone"
        },
        {
            "name": "Specsavers Aviation",
            "code": "",
            "auxcode": "SSZ",
            "companyName": "SPECSAVERS",
            "country": "United Kingdom"
        },
        {
            "name": "Star Aviation",
            "code": "",
            "auxcode": "STA",
            "companyName": "STAR",
            "country": "United Kingdom"
        },
        {
            "name": "StatuscodeAlpha Airline",
            "code": "",
            "auxcode": "STB",
            "companyName": "STATUScodeALPHA",
            "country": "Ukraine"
        },
        {
            "name": "Stadium City Limited",
            "code": "",
            "auxcode": "STC",
            "companyName": "STADIUM",
            "country": "United Kingdom"
        },
        {
            "name": "Servicios De Aerotransportacion De Aguascalientes",
            "code": "",
            "auxcode": "STD",
            "companyName": "AERO AGUASCALINETES",
            "country": "Mexico"
        },
        {
            "name": "Semitool Europe",
            "code": "",
            "auxcode": "STE",
            "companyName": "SEMITRANS",
            "country": "United Kingdom"
        },
        {
            "name": "SFTcodeSudanese Flight",
            "code": "",
            "auxcode": "STF",
            "companyName": "",
            "country": "Sudan"
        },
        {
            "name": "Sedalia",
            "code": "",
            "auxcode": "STG",
            "companyName": " Marshall",
            "country": " Boonville Stage Line"
        },
        {
            "name": "SouthcodeAirlines",
            "code": "",
            "auxcode": "STH",
            "companyName": "",
            "country": "Armenia"
        },
        {
            "name": "Sontair",
            "code": "",
            "auxcode": "STI",
            "companyName": "SONTAIR",
            "country": "Canada"
        },
        {
            "name": "Sella Aviation",
            "code": "",
            "auxcode": "STJ",
            "companyName": "STELLAVIA",
            "country": "Netherlands"
        },
        {
            "name": "Stapleford Flight Centre",
            "code": "",
            "auxcode": "STL",
            "companyName": "STAPLEFORD",
            "country": "United Kingdom"
        },
        {
            "name": "Streamline Ops",
            "code": "",
            "auxcode": "STO",
            "companyName": "SLOPS",
            "country": "Russia"
        },
        {
            "name": "Star Air",
            "code": "",
            "auxcode": "STQ",
            "companyName": "STERA",
            "country": "Indonesia"
        },
        {
            "name": "Servicios de Transportes A",
            "code": "FS",
            "auxcode": "STU",
            "companyName": "FUEGUINO",
            "country": "Argentina"
        },
        {
            "name": "Star African Air",
            "code": "",
            "auxcode": "STU",
            "companyName": "STARSOM",
            "country": "Somali Republic"
        },
        {
            "name": "Southern Aviation",
            "code": "",
            "auxcode": "STV",
            "companyName": "SOUTHERN AVIATION",
            "country": "Ghana"
        },
        {
            "name": "South West Air Corporation",
            "code": "",
            "auxcode": "STW",
            "companyName": "SIERRA WHISKEY",
            "country": "Philippines"
        },
        {
            "name": "Stars Away Aviation",
            "code": "",
            "auxcode": "STX",
            "companyName": "STARSAWAY",
            "country": "South Africa"
        },
        {
            "name": "Styrian Airways",
            "code": "",
            "auxcode": "STY",
            "companyName": "STYRIAN",
            "country": "Austria"
        },
        {
            "name": "Silesia Air",
            "code": "",
            "auxcode": "SUA",
            "companyName": "AIR SILESIA",
            "country": "Czech Republic"
        },
        {
            "name": "Suburban Air Freight",
            "code": "",
            "auxcode": "SUB",
            "companyName": "SUB AIR",
            "country": "United States"
        },
        {
            "name": "Sudan Airways",
            "code": "SD",
            "auxcode": "SUD",
            "companyName": "SUDANAIR",
            "country": "Sudan"
        },
        {
            "name": "Sun Air (Fiji)",
            "code": "PI",
            "auxcode": "SUF",
            "companyName": "SUNFLOWER",
            "country": "Fiji"
        },
        {
            "name": "Sunu Air",
            "code": "",
            "auxcode": "SUG",
            "companyName": "SUNU AIR",
            "country": "Senegal"
        },
        {
            "name": "Sun Light",
            "code": "",
            "auxcode": "SUH",
            "companyName": "LIGHT AIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Swiss Air Force",
            "code": "",
            "auxcode": "SUI",
            "companyName": "SWISS AIR FORCE",
            "country": "Switzerland"
        },
        {
            "name": "Superior Aviation Services",
            "code": "",
            "auxcode": "SUK",
            "companyName": "SKYCARGO",
            "country": "Kenya"
        },
        {
            "name": "State Unitary Air Enterprise",
            "code": "",
            "auxcode": "SUM",
            "companyName": "SUMES",
            "country": "Russia"
        },
        {
            "name": "Sun Air",
            "code": "",
            "auxcode": "SUR",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Sun Air of Scandinavia",
            "code": "EZ",
            "auxcode": "SUS",
            "companyName": "SUNSCAN",
            "country": "Denmark"
        },
        {
            "name": "Sistemas Aeronauuticos 2000",
            "code": "",
            "auxcode": "SUT",
            "companyName": "SISTEMAS AERONAUTICOS",
            "country": "Mexico"
        },
        {
            "name": "Star West Aviation",
            "code": "",
            "auxcode": "SUU",
            "companyName": "SUNSTAR",
            "country": "United States"
        },
        {
            "name": "Sundance Air",
            "code": "",
            "auxcode": "SUV",
            "companyName": "DANCEAIR",
            "country": "Venezuela"
        },
        {
            "name": "Saudi Arabian Airlines",
            "code": "SV",
            "auxcode": "SVA",
            "companyName": "SAUDIA",
            "country": "Saudi Arabia"
        },
        {
            "name": "St. Vincent Grenadines Air (1990)",
            "code": "",
            "auxcode": "SVD",
            "companyName": "GRENADINES",
            "country": "Saint Vincent and the Grenadines"
        },
        {
            "name": "Swedish Armed Forces",
            "code": "",
            "auxcode": "SVF",
            "companyName": "SWEDEFORCE",
            "country": "Sweden"
        },
        {
            "name": "Sahel Airlines",
            "code": "",
            "auxcode": "AWJ",
            "companyName": "SAHEL AIRLINES",
            "country": "Niger"
        },
        {
            "name": "Sterling Helicopters",
            "code": "",
            "auxcode": "SVH",
            "companyName": "SILVER",
            "country": "United Kingdom"
        },
        {
            "name": "Servicios De Transporte Aereo",
            "code": "",
            "auxcode": "SVI",
            "companyName": "SETRA",
            "country": "Mexico"
        },
        {
            "name": "Sabre Pacific",
            "code": "",
            "auxcode": "APD",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Silver Air",
            "code": "",
            "auxcode": "SVJ",
            "companyName": "",
            "country": "Djibouti"
        },
        {
            "name": "SevastopolcodeAvia",
            "code": "",
            "auxcode": "SVL",
            "companyName": "SEVAVIA",
            "country": "Ukraine"
        },
        {
            "name": "Savanair (Angola)",
            "code": "",
            "auxcode": "SVN",
            "companyName": "SAVANAIR",
            "country": "Angola"
        },
        {
            "name": "Servicios Aeronauticos De Oriente",
            "code": "",
            "auxcode": "SVO",
            "companyName": "SERVIORIENTE",
            "country": "Mexico"
        },
        {
            "name": "Servicios Aereos Saar",
            "code": "",
            "auxcode": "SVS",
            "companyName": "AEREOS SAAR",
            "country": "Mexico"
        },
        {
            "name": "Seven Four Eight Air Services",
            "code": "",
            "auxcode": "SVT",
            "companyName": "SIERRA SERVICES",
            "country": "Luxembourg"
        },
        {
            "name": "Security Aviation",
            "code": "",
            "auxcode": "SVX",
            "companyName": "SECURITY AIR",
            "country": "United States"
        },
        {
            "name": "Southwest Airlines",
            "code": "WN",
            "auxcode": "SWA",
            "companyName": "SOUTHWEST",
            "country": "United States"
        },
        {
            "name": "Swissboogie Parapro",
            "code": "",
            "auxcode": "SWB",
            "companyName": "SWISSBOOGIE",
            "country": "Switzerland"
        },
        {
            "name": "South West Air",
            "code": "",
            "auxcode": "SWC",
            "companyName": "SAINT CLAIR",
            "country": "Canada"
        },
        {
            "name": "Southern Winds Airlines",
            "code": "A4",
            "auxcode": "SWD",
            "companyName": "SOUTHERN WINDS",
            "country": "Argentina"
        },
        {
            "name": "Swedeways",
            "code": "",
            "auxcode": "SWE",
            "companyName": "SWEDELINE",
            "country": "Sweden"
        },
        {
            "name": "Spurling Aviation",
            "code": "",
            "auxcode": "ASL",
            "companyName": "AIR SEATTLE",
            "country": "United States"
        },
        {
            "name": "Sunwing Airlines",
            "code": "WG",
            "auxcode": "SWG",
            "companyName": "SUNWING",
            "country": "Canada"
        },
        {
            "name": "Sunworld Airlines",
            "code": "",
            "auxcode": "SWI",
            "companyName": "SUNWORLD",
            "country": "United States"
        },
        {
            "name": "Stateswest Airlines",
            "code": "",
            "auxcode": "SWJ",
            "companyName": "STATES",
            "country": "United States"
        },
        {
            "name": "Surninam International Victory Airline",
            "code": "",
            "auxcode": "SWO",
            "companyName": "SIVA",
            "country": "Suriname"
        },
        {
            "name": "Star Work Sky",
            "code": "",
            "auxcode": "SWP",
            "companyName": "STAR WORK",
            "country": "Italy"
        },
        {
            "name": "Swift Air (Interstate Equipment Leasing)",
            "code": "",
            "auxcode": "SWQ",
            "companyName": "SWIFTFLIGHT",
            "country": "United States"
        },
        {
            "name": "Swiss International Air Lines",
            "code": "LX",
            "auxcode": "SWR",
            "companyName": "SWISS",
            "country": "Switzerland"
        },
        {
            "name": "Swissair",
            "code": "SR",
            "auxcode": "SWR",
            "companyName": "Swissair",
            "country": "Switzerland"
        },
        {
            "name": "Sunwest Aviation (Lindquist Investment)",
            "code": "",
            "auxcode": "SWS",
            "companyName": "SUNNY WEST",
            "country": "United States"
        },
        {
            "name": "Swiftair",
            "code": "",
            "auxcode": "SWT",
            "companyName": "SWIFT",
            "country": "Spain"
        },
        {
            "name": "Swiss European Air Lines",
            "code": "",
            "auxcode": "SWU",
            "companyName": "EUROSWISS",
            "country": "Switzerland"
        },
        {
            "name": "Swe Fly",
            "code": "WV",
            "auxcode": "SWV",
            "companyName": "FLYING SWEDE",
            "country": "Sweden"
        },
        {
            "name": "Shovkoviy Shlyah",
            "code": "S8",
            "auxcode": "SWW",
            "companyName": "WAY AERO",
            "country": "Ukraine"
        },
        {
            "name": "Swazi Express Airways",
            "code": "",
            "auxcode": "SWX",
            "companyName": "SWAZI EXPRESS",
            "country": "Swaziland"
        },
        {
            "name": "Sky Jet",
            "code": "",
            "auxcode": "SWY",
            "companyName": "SWISSLINK",
            "country": "Switzerland"
        },
        {
            "name": "Servair",
            "code": "",
            "auxcode": "SWZ",
            "companyName": " Private Charter",
            "country": "SWISSBIRD"
        },
        {
            "name": "Southern Cross Aviation",
            "code": "",
            "auxcode": "SXA",
            "companyName": "FERRY",
            "country": "United States"
        },
        {
            "name": "Sky Exec Aviation Services",
            "code": "",
            "auxcode": "SXC",
            "companyName": "SKY EXEC",
            "country": "Nigeria"
        },
        {
            "name": "Southeast Express Airlines",
            "code": "",
            "auxcode": "SXE",
            "companyName": "DOGWOOD EXPRESS",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Sepecializados Mexicanos",
            "code": "",
            "auxcode": "SXM",
            "companyName": "SERVIMEX",
            "country": "Mexico"
        },
        {
            "name": "SunExpress",
            "code": "XQ",
            "auxcode": "SXS",
            "companyName": "SUNEXPRESS",
            "country": "Turkey"
        },
        {
            "name": "Servicios De Taxi Aereo",
            "code": "",
            "auxcode": "SXT",
            "companyName": "SERTA",
            "country": "Mexico"
        },
        {
            "name": "Satellite Aero",
            "code": "",
            "auxcode": "SXX",
            "companyName": "SATELLITE EXPRESS",
            "country": "United States"
        },
        {
            "name": "Safari Express Cargo",
            "code": "",
            "auxcode": "SXY",
            "companyName": "SAFARI EXPRESS",
            "country": "Kenya"
        },
        {
            "name": "Skyways",
            "code": "",
            "auxcode": "SYA",
            "companyName": "LINEAS CARDINAL",
            "country": "Argentina"
        },
        {
            "name": "Systec 2000",
            "code": "",
            "auxcode": "SYC",
            "companyName": "SYSTEC",
            "country": "United States"
        },
        {
            "name": "Sheba Aviation",
            "code": "",
            "auxcode": "SYE",
            "companyName": "",
            "country": "Yemen"
        },
        {
            "name": "Sky One Express Airlines",
            "code": "",
            "auxcode": "SYF",
            "companyName": "SKY FIRST",
            "country": "United States"
        },
        {
            "name": "Synergy Aviation",
            "code": "",
            "auxcode": "SYG",
            "companyName": "SYNERGY",
            "country": "United Kingdom"
        },
        {
            "name": "Sonalysts",
            "code": "",
            "auxcode": "SYI",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Slate Falls Airways",
            "code": "",
            "auxcode": "SYJ",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Satsair",
            "code": "",
            "auxcode": "SYK",
            "companyName": "AEROCAB",
            "country": "United States"
        },
        {
            "name": "Syncrude Canada",
            "code": "",
            "auxcode": "SYN",
            "companyName": "SYNCRUDE",
            "country": "Canada"
        },
        {
            "name": "Syrian Arab Airlines",
            "code": "RB",
            "auxcode": "SYR",
            "companyName": "SYRIANAIR",
            "country": "Syrian Arab Republic"
        },
        {
            "name": "Shawbury Flying Training Unit",
            "code": "",
            "auxcode": "SYS",
            "companyName": "SHAWBURY",
            "country": "United Kingdom"
        },
        {
            "name": "Special Aviation Systems",
            "code": "",
            "auxcode": "SYV",
            "companyName": "SPECIAL SYSTEM",
            "country": "United States"
        },
        {
            "name": "Skywalk Airlines",
            "code": "AL",
            "auxcode": "SYX",
            "companyName": "SKYWAYcodeEX",
            "country": "United States"
        },
        {
            "name": "Silk Way Airlines",
            "code": "ZP",
            "auxcode": "AZQ",
            "companyName": "SILK LINE",
            "country": "Azerbaijan"
        },
        {
            "name": "South African Historic Flight",
            "code": "",
            "auxcode": "SYY",
            "companyName": "SKY COACH",
            "country": "South Africa"
        },
        {
            "name": "Servicios Aeronauticos Z",
            "code": "",
            "auxcode": "SZT",
            "companyName": "AERO ZEE",
            "country": "Mexico"
        },
        {
            "name": "Specavia Air Company",
            "code": "",
            "auxcode": "BHV",
            "companyName": "AVIASPEC",
            "country": "Russia"
        },
        {
            "name": "Starair",
            "code": "",
            "auxcode": "BLY",
            "companyName": "BLARNEY",
            "country": "Ireland"
        },
        {
            "name": "Sundance Air",
            "code": "",
            "auxcode": "BNC",
            "companyName": "BARNACLE AIR",
            "country": "United States"
        },
        {
            "name": "Samara Airlines",
            "code": "E5",
            "auxcode": "BRZ",
            "companyName": "BERYOZA",
            "country": "Russia"
        },
        {
            "name": "Spark+ JointcodeStock Company",
            "code": "",
            "auxcode": "BVV",
            "companyName": "SPARK",
            "country": "Russia"
        },
        {
            "name": "Swedish Civil Aviation Administration",
            "code": "",
            "auxcode": "CBN",
            "companyName": "CALIBRATION",
            "country": "Sweden"
        },
        {
            "name": "Shandong Airlines",
            "code": "SC",
            "auxcode": "CDG",
            "companyName": "SHANDONG",
            "country": "China"
        },
        {
            "name": "Spectrem Air",
            "code": "",
            "auxcode": "CDS",
            "companyName": "SPECDAS",
            "country": "South Africa"
        },
        {
            "name": "Servicios Aereos Centrales",
            "code": "",
            "auxcode": "CEE",
            "companyName": "CENTRA AEREOS",
            "country": "Mexico"
        },
        {
            "name": "Swedish Airlines",
            "code": "",
            "auxcode": "CFL",
            "companyName": "SWEDISH",
            "country": "Sweden"
        },
        {
            "name": "Seagle Air",
            "code": "",
            "auxcode": "CGL",
            "companyName": "SEAGLE",
            "country": "Slovakia"
        },
        {
            "name": "SiriuscodeAero",
            "code": "",
            "auxcode": "CIG",
            "companyName": "SIRIUS AERO",
            "country": "Russia"
        },
        {
            "name": "Sunwest Home Aviation",
            "code": "",
            "auxcode": "CNK",
            "companyName": "CHINOOK",
            "country": "Canada"
        },
        {
            "name": "SAS Braathens",
            "code": "",
            "auxcode": "CNO",
            "companyName": "SCANOR",
            "country": "Norway"
        },
        {
            "name": "Spring Airlines",
            "code": "9S",
            "auxcode": "CQH",
            "companyName": "AIR SPRING",
            "country": "China"
        },
        {
            "name": "Sichuan Airlines",
            "code": "3U",
            "auxcode": "CSC",
            "companyName": "SI CHUAN",
            "country": "China"
        },
        {
            "name": "Shanghai Airlines",
            "code": "FM",
            "auxcode": "CSH",
            "companyName": "SHANGHAI AIR",
            "country": "China"
        },
        {
            "name": "Shuangyang General Aviation",
            "code": "",
            "auxcode": "CSY",
            "companyName": "SHUANGYANG",
            "country": "China"
        },
        {
            "name": "Shenzhen Airlines",
            "code": "ZH",
            "auxcode": "CSZ",
            "companyName": "SHENZHEN AIR",
            "country": "China"
        },
        {
            "name": "Shanxi Airlines",
            "code": "8C",
            "auxcode": "CXI",
            "companyName": "SHANXI",
            "country": "China"
        },
        {
            "name": "Sioux Falls Aviation",
            "code": "",
            "auxcode": "DKT",
            "companyName": "DAKOTA",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Elite",
            "code": "",
            "auxcode": "DKY",
            "companyName": "DAKOY",
            "country": "Spain"
        },
        {
            "name": "Servicios Aereos Denim",
            "code": "",
            "auxcode": "DNI",
            "companyName": "AERO DENIM",
            "country": "Mexico"
        },
        {
            "name": "Swiss Eagle",
            "code": "",
            "auxcode": "EAB",
            "companyName": "SWISS EAGLE",
            "country": "Switzerland"
        },
        {
            "name": "Skypower Express Airways",
            "code": "",
            "auxcode": "EAN",
            "companyName": "NIGERIA EXPRESS",
            "country": "Nigeria"
        },
        {
            "name": "Scenic Air",
            "code": "",
            "auxcode": "ENR",
            "companyName": "",
            "country": "Namibia"
        },
        {
            "name": "Sun D'Or",
            "code": "7L",
            "auxcode": "ERO",
            "companyName": "ECHO ROMEO",
            "country": "Israel"
        },
        {
            "name": "SkyEurope",
            "code": "NE",
            "auxcode": "ESK",
            "companyName": "RELAX",
            "country": "Slovakia"
        },
        {
            "name": "Sunshine Express Airlines",
            "code": "CQ",
            "auxcode": "EXL",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "South African Express",
            "code": "",
            "auxcode": "EXY",
            "companyName": "EXPRESSWAYS",
            "country": "South Africa"
        },
        {
            "name": "Stuttgarter Flugdienst",
            "code": "",
            "auxcode": "FFD",
            "companyName": "FIRST FLIGHT",
            "country": "Germany"
        },
        {
            "name": "Shalom Air Services",
            "code": "",
            "auxcode": "FFH",
            "companyName": "PEACE AIR",
            "country": "Nigeria"
        },
        {
            "name": "Silverjet",
            "code": "",
            "auxcode": "FJE",
            "companyName": "ENVOY",
            "country": "United Kingdom"
        },
        {
            "name": "Sky Bus",
            "code": "",
            "auxcode": "FLH",
            "companyName": "MILE HIGH",
            "country": "United States"
        },
        {
            "name": "South Coast Aviation",
            "code": "",
            "auxcode": "GAD",
            "companyName": "SOUTHCOAST",
            "country": "United Kingdom"
        },
        {
            "name": "Servicios Aereos Gadel",
            "code": "",
            "auxcode": "GDE",
            "companyName": "GADEL",
            "country": "Mexico"
        },
        {
            "name": "S.P. Aviation",
            "code": "",
            "auxcode": "GDG",
            "companyName": "GOLDEN GATE",
            "country": "United States"
        },
        {
            "name": "Seba Airlines",
            "code": "",
            "auxcode": "GIK",
            "companyName": "SEBA",
            "country": "Guinea"
        },
        {
            "name": "Servicios Aereos Gana",
            "code": "",
            "auxcode": "GNA",
            "companyName": "SERVIGANA",
            "country": "Mexico"
        },
        {
            "name": "Sky Wings Airlines",
            "code": "",
            "auxcode": "GSW",
            "companyName": "",
            "country": "Greece"
        },
        {
            "name": "Star XL German Airlines",
            "code": "",
            "auxcode": "GXL",
            "companyName": "STARDUST",
            "country": "Germany"
        },
        {
            "name": "Skyhaul",
            "code": "",
            "auxcode": "HAU",
            "companyName": "SKYHAUL",
            "country": "Japan"
        },
        {
            "name": "Starship",
            "code": "",
            "auxcode": "HIP",
            "companyName": "STARSA",
            "country": "Mexico"
        },
        {
            "name": "Servicios Ejecutivos Gosa",
            "code": "",
            "auxcode": "HJE",
            "companyName": "GOSA",
            "country": "Mexico"
        },
        {
            "name": "Superior Aviation",
            "code": "SO",
            "auxcode": "HKA",
            "companyName": "SPEND AIR",
            "country": "United States"
        },
        {
            "name": "Samaritan Air Service",
            "code": "",
            "auxcode": "HLO",
            "companyName": "HALO",
            "country": "Canada"
        },
        {
            "name": "Skyraidybos Mokymo Centras",
            "code": "",
            "auxcode": "HRI",
            "companyName": "HELIRIM",
            "country": "Lithuania"
        },
        {
            "name": "Sky Europe Airlines",
            "code": "",
            "auxcode": "HSK",
            "companyName": "MATRA",
            "country": "Slovakia"
        },
        {
            "name": "Svenska Direktflyg",
            "code": "",
            "auxcode": "HSV",
            "companyName": "HIGHSWEDE",
            "country": "Sweden"
        },
        {
            "name": "Sky Helicopteros",
            "code": "",
            "auxcode": "HSY",
            "companyName": "HELISKY",
            "country": "Spain"
        },
        {
            "name": "Skytaxi",
            "code": "",
            "auxcode": "IGA",
            "companyName": "IGUANA",
            "country": "Poland"
        },
        {
            "name": "Silvair",
            "code": "",
            "auxcode": "IJS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Ilsa",
            "code": "",
            "auxcode": "ILS",
            "companyName": "SERVICIOS ILSA",
            "country": "Mexico"
        },
        {
            "name": "SincomcodeAvia",
            "code": "",
            "auxcode": "INK",
            "companyName": "SINCOM AVIA",
            "country": "Ukraine"
        },
        {
            "name": "Safat Airlines",
            "code": "",
            "auxcode": "IRV",
            "companyName": "SAFAT AIR",
            "country": "Iran"
        },
        {
            "name": "Saha Airlines Services",
            "code": "",
            "auxcode": "IRZ",
            "companyName": "SAHA",
            "country": "Iran"
        },
        {
            "name": "Sunline Express",
            "code": "",
            "auxcode": "JAM",
            "companyName": "SUNTRACK",
            "country": "Kenya"
        },
        {
            "name": "Secure Air Charter",
            "code": "",
            "auxcode": "JCM",
            "companyName": "SECUREAIR",
            "country": "United States"
        },
        {
            "name": "Sark International Airways",
            "code": "",
            "auxcode": "JIM",
            "companyName": "SARK",
            "country": "United Kingdom"
        },
        {
            "name": "Spanair",
            "code": "JK",
            "auxcode": "JKK",
            "companyName": "SPANAIR",
            "country": "Spain"
        },
        {
            "name": "Salem",
            "code": "",
            "auxcode": "KKS",
            "companyName": "KOKSHE",
            "country": "Kazakhstan"
        },
        {
            "name": "Servicios Aereos Copters",
            "code": "",
            "auxcode": "KOP",
            "companyName": "COPTERS",
            "country": "Chile"
        },
        {
            "name": "Servicios Aereos Expecializados En Transportes Petroleros",
            "code": "",
            "auxcode": "KSP",
            "companyName": "SAEP",
            "country": "Colombia"
        },
        {
            "name": "Skybridge Airops",
            "code": "",
            "auxcode": "KYB",
            "companyName": "SKY AIROPS",
            "country": "Italy"
        },
        {
            "name": "Sky Aeronautical Services",
            "code": "",
            "auxcode": "KYR",
            "companyName": "SKY AERONAUTICAL",
            "country": "Mexico"
        },
        {
            "name": "Servicios Aereos Ejecutivos De La Laguna",
            "code": "",
            "auxcode": "LGU",
            "companyName": "LAGUNA",
            "country": "Mexico"
        },
        {
            "name": "Servico Leo Lopex",
            "code": "",
            "auxcode": "LLA",
            "companyName": "LEO LOPOZ",
            "country": "Mexico"
        },
        {
            "name": "Servicios Aereos Estrella",
            "code": "",
            "auxcode": "LLS",
            "companyName": "SERVIESTRELLA",
            "country": "Mexico"
        },
        {
            "name": "South African Air Force",
            "code": "",
            "auxcode": "LMG",
            "companyName": "SOUTH AFRICAN",
            "country": "South Africa"
        },
        {
            "name": "Sky Limo Corporation",
            "code": "",
            "auxcode": "LMO",
            "companyName": "SKY LIMO",
            "country": "United States"
        },
        {
            "name": "SANSA",
            "code": "",
            "auxcode": "LRS",
            "companyName": "",
            "country": "Costa Rica"
        },
        {
            "name": "Spectrum Aviation Incorporated",
            "code": "",
            "auxcode": "LSP",
            "companyName": "AIR TONY",
            "country": "United Kingdom"
        },
        {
            "name": "SOS Helikoptern Gotland",
            "code": "",
            "auxcode": "MCG",
            "companyName": "MEDICOPTER",
            "country": "Sweden"
        },
        {
            "name": "Sundt Air",
            "code": "",
            "auxcode": "MDT",
            "companyName": "MIDNIGHT",
            "country": "Norway"
        },
        {
            "name": "Servicios Aereos Milenio",
            "code": "",
            "auxcode": "MLO",
            "companyName": "MILENIO",
            "country": "Mexico"
        },
        {
            "name": "SAAD (A320) Limited",
            "code": "",
            "auxcode": "MMS",
            "companyName": "MUSAAD AIR",
            "country": "Cayman Islands"
        },
        {
            "name": "Servicios Aereos Moritani",
            "code": "",
            "auxcode": "MRI",
            "companyName": "MORITANI",
            "country": "Mexico"
        },
        {
            "name": "San Juan Airlines",
            "code": "2G",
            "auxcode": "MRR",
            "companyName": "MARINER",
            "country": "United States"
        },
        {
            "name": "Servico Aereo Regional",
            "code": "",
            "auxcode": "MSG",
            "companyName": "SARcodeREGIONAL",
            "country": "Mozambique"
        },
        {
            "name": "Servicio De Viglancia Aerea Del Ministerio De Seguridad Publica",
            "code": "",
            "auxcode": "MSP",
            "companyName": "SEGURIDAD",
            "country": "Costa Rica"
        },
        {
            "name": "Servicios Aereos MTT",
            "code": "",
            "auxcode": "MTG",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "Sabre Pacific",
            "code": "1Z",
            "auxcode": "",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Sabre",
            "code": "1S",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Sierra Nevada Airlines",
            "code": "1I",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "SirencodeTravel",
            "code": "1H",
            "auxcode": "",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Sirena",
            "code": "1Q",
            "auxcode": "",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Sky Trek International Airlines",
            "code": "1I",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "Southern Cross Distribution",
            "code": "1K",
            "auxcode": "",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Sutra",
            "code": "1K",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "SNCF",
            "code": "2C",
            "auxcode": "",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Star Equatorial Airlines",
            "code": "2S",
            "auxcode": "",
            "companyName": "",
            "country": "Guinea"
        },
        {
            "name": "Seulawah Nad Air",
            "code": "",
            "auxcode": "NAD",
            "companyName": "SEULAWAH",
            "country": "Indonesia"
        },
        {
            "name": "Servicios Aereos del Nazas SA de CV",
            "code": "",
            "auxcode": "NAZ",
            "companyName": "NAZAS",
            "country": "Mexico"
        },
        {
            "name": "Simpson Air Ltd",
            "code": "",
            "auxcode": "NCS",
            "companyName": "COMMUTERcodeCANADA",
            "country": "Canada"
        },
        {
            "name": "Spirit Airlines",
            "code": "NK",
            "auxcode": "NKS",
            "companyName": "SPIRIT WINGS",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Latinoamericanos",
            "code": "",
            "auxcode": "NON",
            "companyName": "SERVICIOS LATINO",
            "country": "Mexico"
        },
        {
            "name": "Servicios Aereos Monarrez",
            "code": "",
            "auxcode": "NRZ",
            "companyName": "MONARREZ",
            "country": "Mexico"
        },
        {
            "name": "Societe De Transport Aerien De Mauritanie",
            "code": "",
            "auxcode": "NSC",
            "companyName": "TRANScodeSOCIETE",
            "country": "Mauritania"
        },
        {
            "name": "SATENA",
            "code": "9R",
            "auxcode": "NSE",
            "companyName": "SATENA",
            "country": "Colombia"
        },
        {
            "name": "Servicios Aereos Del Norte",
            "code": "",
            "auxcode": "NTB",
            "companyName": "SERVINORTE",
            "country": "Mexico"
        },
        {
            "name": "Servicios Intergrales De Aviacion",
            "code": "",
            "auxcode": "NTG",
            "companyName": "INTERGRALES",
            "country": "Mexico"
        },
        {
            "name": "Slok Air Gambia",
            "code": "S0",
            "auxcode": "OKS",
            "companyName": "SLOK GAMBIA",
            "country": "Gambia"
        },
        {
            "name": "Soko Aviation",
            "code": "",
            "auxcode": "OKT",
            "companyName": "SOKO AIR",
            "country": "Spain"
        },
        {
            "name": "Solar Cargo",
            "code": "",
            "auxcode": "OLC",
            "companyName": "SOLARCARGO",
            "country": "Venezuela"
        },
        {
            "name": "Soloflight",
            "code": "",
            "auxcode": "OLO",
            "companyName": "SOLO",
            "country": "United Kingdom"
        },
        {
            "name": "Sonnig SA",
            "code": "",
            "auxcode": "ONG",
            "companyName": "SONNIG",
            "country": "Switzerland"
        },
        {
            "name": "Sosoliso Airlines",
            "code": "SO",
            "auxcode": "OSL",
            "companyName": "SOSOLISO",
            "country": "Nigeria"
        },
        {
            "name": "Servicios Aereos Noticiosos",
            "code": "",
            "auxcode": "OSS",
            "companyName": "NOTICIOSOS",
            "country": "Mexico"
        },
        {
            "name": "South Airlines",
            "code": "",
            "auxcode": "OTL",
            "companyName": "SOUTHLINE",
            "country": "Ukraine"
        },
        {
            "name": "Skywest Airlines",
            "code": "",
            "auxcode": "OZW",
            "companyName": "OZWEST",
            "country": "Australia"
        },
        {
            "name": "Sokol",
            "code": "",
            "auxcode": "PIV",
            "companyName": "AEROSOKOL",
            "country": "Russia"
        },
        {
            "name": "South Carolina Aeronautics Commission",
            "code": "",
            "auxcode": "PLT",
            "companyName": "PALMETTO",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Premier",
            "code": "",
            "auxcode": "PMR",
            "companyName": "SERVICIOS PREMIER",
            "country": "Mexico"
        },
        {
            "name": "Survey Udara (Penas)",
            "code": "",
            "auxcode": "PNS",
            "companyName": "PENAS",
            "country": "Indonesia"
        },
        {
            "name": "Servicios Aereos Poblanos",
            "code": "",
            "auxcode": "POB",
            "companyName": "POBLANOS",
            "country": "Mexico"
        },
        {
            "name": "Servicios Aereos Profesionales",
            "code": "",
            "auxcode": "PSV",
            "companyName": "PROSERVICIOS",
            "country": "Dominican Republic"
        },
        {
            "name": "Southeastern Airways",
            "code": "",
            "auxcode": "PTM",
            "companyName": "POSTMAN",
            "country": "United States"
        },
        {
            "name": "Spurwing Airlines",
            "code": "",
            "auxcode": "PUR",
            "companyName": "SPURWING",
            "country": "South Africa"
        },
        {
            "name": "Sky Trek International Airlines",
            "code": "1I",
            "auxcode": "PZR",
            "companyName": "PHAZER",
            "country": "United States"
        },
        {
            "name": "Shandong Airlines Rainbow Jet",
            "code": "",
            "auxcode": "RBW",
            "companyName": "CAI HONG",
            "country": "China"
        },
        {
            "name": "SA Airlink Regional",
            "code": "",
            "auxcode": "REJ",
            "companyName": "REGIONAL LINK",
            "country": "South Africa"
        },
        {
            "name": "Servicio Aereo Regional Regair",
            "code": "",
            "auxcode": "RER",
            "companyName": "REGAIR",
            "country": "Ecuador"
        },
        {
            "name": "Scoala Superioara De Aviatie Civila",
            "code": "",
            "auxcode": "RFT",
            "companyName": "ROMANIAN ACADEMY",
            "country": "Romania"
        },
        {
            "name": "Servicios Aereos Regiomontanos",
            "code": "",
            "auxcode": "RGC",
            "companyName": "REGIOMONTANO",
            "country": "Mexico"
        },
        {
            "name": "ScodeAir",
            "code": "",
            "auxcode": "RLS",
            "companyName": "ScodeAIRLINES",
            "country": "Russia"
        },
        {
            "name": "Servicios De Rampa Y Mostrador",
            "code": "",
            "auxcode": "RMP",
            "companyName": "SERAMSA",
            "country": "Mexico"
        },
        {
            "name": "SNAS Aviation",
            "code": "",
            "auxcode": "RSE",
            "companyName": "RED SEA",
            "country": "Saudi Arabia"
        },
        {
            "name": "Skybus Airlines",
            "code": "SX",
            "auxcode": "SKB",
            "companyName": "SKYBUS",
            "country": "United States"
        },
        {
            "name": "Skymaster Air Lines",
            "code": "",
            "auxcode": "SKC",
            "companyName": "SKYMASTER AIR",
            "country": "United States"
        },
        {
            "name": "Sky Harbor Air Service",
            "code": "",
            "auxcode": "SKD",
            "companyName": "SKY DAWG",
            "country": "United States"
        },
        {
            "name": "Sky Tours",
            "code": "",
            "auxcode": "SKE",
            "companyName": "SKYISLE",
            "country": "United States"
        },
        {
            "name": "Sakaviaservice",
            "code": "",
            "auxcode": "AZG",
            "companyName": "SAKSERVICE",
            "country": "Georgia"
        },
        {
            "name": "Skycraft",
            "code": "",
            "auxcode": "SKF",
            "companyName": "SKYCRAFT",
            "country": "United States"
        },
        {
            "name": "Skycraft Air Transport",
            "code": "",
            "auxcode": "SKG",
            "companyName": "SKYCRAFTcodeCANADA",
            "country": "Canada"
        },
        {
            "name": "Skyking",
            "code": "",
            "auxcode": "SKI",
            "companyName": "SKYKING",
            "country": "United Kingdom"
        },
        {
            "name": "SkyKing Turks and Caicos Airways",
            "code": "RU",
            "auxcode": "SKI",
            "companyName": "SKYKING",
            "country": "Turks and Caicos Islands"
        },
        {
            "name": "Skylink Aviation",
            "code": "",
            "auxcode": "SKK",
            "companyName": "SKYLINK",
            "country": "Canada"
        },
        {
            "name": "Skycharter (Malton)",
            "code": "",
            "auxcode": "SKL",
            "companyName": "SKYCHARTER",
            "country": "Canada"
        },
        {
            "name": "Skyline Aviation Services",
            "code": "",
            "auxcode": "SKN",
            "companyName": "SKYLINER",
            "country": "United States"
        },
        {
            "name": "Scottish Airways Flyers",
            "code": "",
            "auxcode": "SKO",
            "companyName": "SKYWORK",
            "country": "United Kingdom"
        },
        {
            "name": "Skyscapes Air Charters",
            "code": "",
            "auxcode": "SKR",
            "companyName": "SKYSCAPES",
            "country": "South Africa"
        },
        {
            "name": "Sky Service",
            "code": "",
            "auxcode": "SKS",
            "companyName": "SKY SERVICE",
            "country": "Belgium"
        },
        {
            "name": "Santa Barbara Airlines",
            "code": "S3",
            "auxcode": "BBR",
            "companyName": "SANTA BARBARA",
            "country": "Venezuela"
        },
        {
            "name": "Skystar Airways",
            "code": "",
            "auxcode": "SKT",
            "companyName": "SKY YOU",
            "country": "Thailand"
        },
        {
            "name": "Sky Airline",
            "code": "H2",
            "auxcode": "SKU",
            "companyName": "AEROSKY",
            "country": "Chile"
        },
        {
            "name": "SkyWest",
            "code": "OO",
            "auxcode": "SKW",
            "companyName": "SKYWEST",
            "country": "United States"
        },
        {
            "name": "Skyways Express",
            "code": "JZ",
            "auxcode": "SKX",
            "companyName": "SKY EXPRESS",
            "country": "Sweden"
        },
        {
            "name": "Skymark Airlines",
            "code": "BC",
            "auxcode": "SKY",
            "companyName": "SKYMARK",
            "country": "Japan"
        },
        {
            "name": "Skyway Enterprises",
            "code": "",
            "auxcode": "SKZ",
            "companyName": "SKYWAYcodeINC",
            "country": "United States"
        },
        {
            "name": "Sierra National Airlines",
            "code": "LJ",
            "auxcode": "SLA",
            "companyName": "SELAIR",
            "country": "Sierra Leone"
        },
        {
            "name": "Slok Air",
            "code": "",
            "auxcode": "SLB",
            "companyName": "SLOK AIR",
            "country": "Nigeria"
        },
        {
            "name": "Silver Air",
            "code": "",
            "auxcode": "SLD",
            "companyName": "SILVERLINE",
            "country": "Czech Republic"
        },
        {
            "name": "Streamline",
            "code": "",
            "auxcode": "SLE",
            "companyName": "SLIPSTREAM",
            "country": "South Africa"
        },
        {
            "name": "Starfly",
            "code": "",
            "auxcode": "SLF",
            "companyName": "ELISTARFLY",
            "country": "Italy"
        },
        {
            "name": "Saskatchewan Government",
            "code": "",
            "auxcode": "SLG",
            "companyName": "LIFEGUARD",
            "country": "Canada"
        },
        {
            "name": "Silverhawk Aviation",
            "code": "",
            "auxcode": "SLH",
            "companyName": "SILVERHAWK",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos de Los Angeles",
            "code": "",
            "auxcode": "AGE",
            "companyName": "AEROANGEL",
            "country": "Mexico"
        },
        {
            "name": "SilkAir",
            "code": "MI",
            "auxcode": "SLK",
            "companyName": "SILKAIR",
            "country": "Singapore"
        },
        {
            "name": "Slovak Airlines",
            "code": "6Q",
            "auxcode": "SLL",
            "companyName": "SLOV LINE",
            "country": "Slovakia"
        },
        {
            "name": "Surinam Airways",
            "code": "PY",
            "auxcode": "SLM",
            "companyName": "SURINAM",
            "country": "Suriname"
        },
        {
            "name": "Sloane Aviation",
            "code": "",
            "auxcode": "SLN",
            "companyName": "SLOANE",
            "country": "United Kingdom"
        },
        {
            "name": "Salpa Aviation",
            "code": "",
            "auxcode": "SLP",
            "companyName": "SALPA",
            "country": "Sudan"
        },
        {
            "name": "Servicios Aereos Slainte",
            "code": "",
            "auxcode": "SLS",
            "companyName": "SERVICIOS SLAINTE",
            "country": "Mexico"
        },
        {
            "name": "Stella Aviation",
            "code": "",
            "auxcode": "SLV",
            "companyName": "AVISTELLA",
            "country": "Mauritania"
        },
        {
            "name": "Salama Airlines Nigeria",
            "code": "",
            "auxcode": "SLW",
            "companyName": "SALMA AIR",
            "country": "Nigeria"
        },
        {
            "name": "Sete Linhas Aereas",
            "code": "",
            "auxcode": "SLX",
            "companyName": "SETE",
            "country": "Brazil"
        },
        {
            "name": "Sky Line for Air Services",
            "code": "",
            "auxcode": "SLY",
            "companyName": "SKYCO",
            "country": "Sudan"
        },
        {
            "name": "Super Luza",
            "code": "",
            "auxcode": "SLZ",
            "companyName": "LUZA",
            "country": "Angola"
        },
        {
            "name": "SMA Airlines",
            "code": "",
            "auxcode": "SMA",
            "companyName": "SESAME",
            "country": "Nigeria"
        },
        {
            "name": "Sabang Merauke Raya Air Charter",
            "code": "",
            "auxcode": "SMC",
            "companyName": "SAMER",
            "country": "Indonesia"
        },
        {
            "name": "Servicios Aereos La Marquesa",
            "code": "",
            "auxcode": "SMD",
            "companyName": "SERVICIOS MARQUESA",
            "country": "Mexico"
        },
        {
            "name": "Servant Air",
            "code": "8D",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Semos",
            "code": "",
            "auxcode": "SME",
            "companyName": "SEMICH",
            "country": "Kazakhstan"
        },
        {
            "name": "Smalandsflyg",
            "code": "",
            "auxcode": "SMF",
            "companyName": "GORDON",
            "country": "Sweden"
        },
        {
            "name": "Smithair",
            "code": "",
            "auxcode": "SMH",
            "companyName": "SMITHAIR",
            "country": "United States"
        },
        {
            "name": "Semeyavia",
            "code": "",
            "auxcode": "SMK",
            "companyName": "ERTIS",
            "country": "Kazakhstan"
        },
        {
            "name": "Smith Air (1976)",
            "code": "",
            "auxcode": "SML",
            "companyName": "SMITH AIR",
            "country": "Canada"
        },
        {
            "name": "Summit Airlines",
            "code": "",
            "auxcode": "SMM",
            "companyName": "SUMMITcodeAIR",
            "country": "United States"
        },
        {
            "name": "Samar Air",
            "code": "",
            "auxcode": "SMQ",
            "companyName": "SAMAR AIR",
            "country": "Tajikistan"
        },
        {
            "name": "Somon Air",
            "code": "",
            "auxcode": "SMR",
            "companyName": "SOMON AIR",
            "country": "Tajikistan"
        },
        {
            "name": "Skyline",
            "code": "",
            "auxcode": "SMT",
            "companyName": "SKYLIMIT",
            "country": "Nigeria"
        },
        {
            "name": "Servicios Aerecs Del Sol",
            "code": "",
            "auxcode": "AOS",
            "companyName": " S.A. de C.V.",
            "country": "AEROSOL"
        },
        {
            "name": "Senator Aviation Charter",
            "code": "",
            "auxcode": "SNA",
            "companyName": "SENATOR",
            "country": "Germany"
        },
        {
            "name": "Sterling Airlines",
            "code": "NB",
            "auxcode": "SNB",
            "companyName": "STERLING",
            "country": "Denmark"
        },
        {
            "name": "Servicios Aereos De Nicaragua (SANSA)",
            "code": "",
            "auxcode": "SNE",
            "companyName": "SANSA",
            "country": "Nicaragua"
        },
        {
            "name": "Shans Air",
            "code": "",
            "auxcode": "SNF",
            "companyName": "SHANS AIR",
            "country": "Russia"
        },
        {
            "name": "Senair Services",
            "code": "",
            "auxcode": "SNH",
            "companyName": "SENSERVICE",
            "country": "Senegal"
        },
        {
            "name": "Savanah Airlines",
            "code": "",
            "auxcode": "SNI",
            "companyName": "SAVANAHLINE",
            "country": "Nigeria"
        },
        {
            "name": "Skynet Asia Airways",
            "code": "6J",
            "auxcode": "SNJ",
            "companyName": "NEWSKY",
            "country": "Japan"
        },
        {
            "name": "Southeast Airlines (Sun Jet International)",
            "code": "",
            "auxcode": "SNK",
            "companyName": "SUN KING",
            "country": "United States"
        },
        {
            "name": "Soonair Lines",
            "code": "",
            "auxcode": "SNL",
            "companyName": "SOONAIR",
            "country": "United States"
        },
        {
            "name": "Servizi Aerei",
            "code": "",
            "auxcode": "SNM",
            "companyName": "SERVIZI AEREI",
            "country": "Italy"
        },
        {
            "name": "Sun Pacific International",
            "code": "",
            "auxcode": "SNP",
            "companyName": "SUN PACIFIC",
            "country": "United States"
        },
        {
            "name": "Sun Quest Executive Air Charter",
            "code": "",
            "auxcode": "SNQ",
            "companyName": "EXECUcodeQUEST",
            "country": "United States"
        },
        {
            "name": "Societe Centrafricaine De Transport Aerien",
            "code": "",
            "auxcode": "SNS",
            "companyName": "",
            "country": "Central African Republic"
        },
        {
            "name": "Suncoast Aviation",
            "code": "",
            "auxcode": "SNT",
            "companyName": "SUNCOAST",
            "country": "United States"
        },
        {
            "name": "Snunit Aviation",
            "code": "",
            "auxcode": "SNU",
            "companyName": "",
            "country": "Israel"
        },
        {
            "name": "Sudanese States Aviation",
            "code": "",
            "auxcode": "SNV",
            "companyName": "SUDANESE",
            "country": "Sudan"
        },
        {
            "name": "Sun West Airlines",
            "code": "",
            "auxcode": "SNW",
            "companyName": "SUN WEST",
            "country": "United States"
        },
        {
            "name": "Sun Air Aviation Services",
            "code": "",
            "auxcode": "SNX",
            "companyName": "SUNEX",
            "country": "Canada"
        },
        {
            "name": "Stabo Freight",
            "code": "",
            "auxcode": "SOB",
            "companyName": "STABO",
            "country": "Zambia"
        },
        {
            "name": "Southern Cargo Air Lines",
            "code": "",
            "auxcode": "SOC",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Southern Ohio Aviation Sales",
            "code": "",
            "auxcode": "SOH",
            "companyName": "SOUTHERN OHIO",
            "country": "United States"
        },
        {
            "name": "Southern Aviation",
            "code": "",
            "auxcode": "SOI",
            "companyName": "SOAVAIR",
            "country": "Zambia"
        },
        {
            "name": "Solomon Airlines",
            "code": "IE",
            "auxcode": "SOL",
            "companyName": "SOLOMON",
            "country": "Solomon Islands"
        },
        {
            "name": "Somali Airlines",
            "code": "",
            "auxcode": "SOM",
            "companyName": "SOMALAIR",
            "country": "Somali Republic"
        },
        {
            "name": "Sunshine Air Tours",
            "code": "",
            "auxcode": "SON",
            "companyName": "SUNSHINE TOURS",
            "country": "United States"
        },
        {
            "name": "Southern Air",
            "code": "",
            "auxcode": "SOO",
            "companyName": "SOUTHERN AIR",
            "country": "United States"
        },
        {
            "name": "Solinair",
            "code": "",
            "auxcode": "SOP",
            "companyName": "SOLINAIR",
            "country": "Slovenia"
        },
        {
            "name": "Sonair Servico Aereo",
            "code": "",
            "auxcode": "SOR",
            "companyName": "SONAIR",
            "country": "Angola"
        },
        {
            "name": "Southeast Correct Craft",
            "code": "",
            "auxcode": "SOT",
            "companyName": "SOUTH COURIER",
            "country": "United States"
        },
        {
            "name": "Southern Airways",
            "code": "",
            "auxcode": "SOU",
            "companyName": "SOUTHERN EXPRESS",
            "country": "United States"
        },
        {
            "name": "Saratov Aviation Division",
            "code": "6W",
            "auxcode": "SOV",
            "companyName": "SARATOV AIR",
            "country": "Russia"
        },
        {
            "name": "Sowind Air",
            "code": "",
            "auxcode": "SOW",
            "companyName": "SOWIND",
            "country": "Canada"
        },
        {
            "name": "Solid Air",
            "code": "",
            "auxcode": "SOX",
            "companyName": "SOLIDAIR",
            "country": "Netherland"
        },
        {
            "name": "Sat Airlines",
            "code": "HZ",
            "auxcode": "SOZ",
            "companyName": "SATCO",
            "country": "Kazakhstan"
        },
        {
            "name": "Sierra Pacific Airlines",
            "code": "",
            "auxcode": "SPA",
            "companyName": "SIERRA PACIFIC",
            "country": "United States"
        },
        {
            "name": "Springbok Classic Air",
            "code": "",
            "auxcode": "SPB",
            "companyName": "SPRING CLASSIC",
            "country": "South Africa"
        },
        {
            "name": "Skyworld Airlines",
            "code": "",
            "auxcode": "SPC",
            "companyName": "PORT",
            "country": "United States"
        },
        {
            "name": "Sprague Electric Company",
            "code": "",
            "auxcode": "SPE",
            "companyName": "SPRAGUE",
            "country": "United States"
        },
        {
            "name": "Space World Airline",
            "code": "",
            "auxcode": "SPF",
            "companyName": "SPACE WORLD",
            "country": "Nigeria"
        },
        {
            "name": "Springdale Air Service",
            "code": "",
            "auxcode": "SPG",
            "companyName": "SPRING AIR",
            "country": "United States"
        },
        {
            "name": "Sapphire Executive Air",
            "code": "",
            "auxcode": "SPH",
            "companyName": "SAPPHIREcodeCHARTER",
            "country": "South Africa"
        },
        {
            "name": "South Pacific Island Airways",
            "code": "",
            "auxcode": "SPI",
            "companyName": "SOUTH PACIFIC",
            "country": "United States"
        },
        {
            "name": "Servicios Corporativos Aereos De La Laguna",
            "code": "",
            "auxcode": "SPL",
            "companyName": "CORPORATIVOS LAGUNA",
            "country": "Mexico"
        },
        {
            "name": "Skorpion Air",
            "code": "",
            "auxcode": "SPN",
            "companyName": "AIR SKORPIO",
            "country": "Bulgaria"
        },
        {
            "name": "Sapphire Aviation",
            "code": "",
            "auxcode": "SPP",
            "companyName": "SAPPHIRE",
            "country": "United States"
        },
        {
            "name": "Servicios Aereos Palenque",
            "code": "",
            "auxcode": "SPQ",
            "companyName": "SERVICOS PALENQUE",
            "country": "Mexico"
        },
        {
            "name": "Servicios Aereos Tribasa",
            "code": "",
            "auxcode": "TBS",
            "companyName": "TRIBASA",
            "country": "Mexico"
        },
        {
            "name": "Shuttle America",
            "code": "S5",
            "auxcode": "TCF",
            "companyName": "MERCURY",
            "country": "United States"
        },
        {
            "name": "SAAB Nyge Aero",
            "code": "",
            "auxcode": "TGT",
            "companyName": "TARGET",
            "country": "Sweden"
        },
        {
            "name": "Spark Air",
            "code": "",
            "auxcode": "THB",
            "companyName": "THAI SABAI",
            "country": "Thailand"
        },
        {
            "name": "S C Ion Tiriac",
            "code": "",
            "auxcode": "TIH",
            "companyName": "TIRIAC AIR",
            "country": "Romania"
        },
        {
            "name": "Starlite Aviation",
            "code": "",
            "auxcode": "TRL",
            "companyName": "STARSTREAM",
            "country": "South Africa"
        },
        {
            "name": "Servicios Aereos Corporativos",
            "code": "",
            "auxcode": "TRN",
            "companyName": "AEROTRON",
            "country": "Mauritania"
        },
        {
            "name": "Societe Tout Tranport Mauritanien",
            "code": "",
            "auxcode": "TTM",
            "companyName": "TOUTcodeAIR",
            "country": "Mauritania"
        },
        {
            "name": "Servicios Aereos Tamazula",
            "code": "",
            "auxcode": "TZU",
            "companyName": "TAMAZULA",
            "country": "Mexico"
        },
        {
            "name": "Shar Ink",
            "code": "",
            "auxcode": "UGP",
            "companyName": "SHARINK",
            "country": "Russia"
        },
        {
            "name": "Second Sverdlovsk Air Enterprise",
            "code": "",
            "auxcode": "UKU",
            "companyName": "PYSHMA",
            "country": "Russia"
        },
        {
            "name": "Servicios Aereos Universitarios",
            "code": "",
            "auxcode": "UNT",
            "companyName": "UNIVERSITARIO",
            "country": "Mexico"
        },
        {
            "name": "SkifcodeAir",
            "code": "",
            "auxcode": "USK",
            "companyName": "SKIFcodeAIR",
            "country": "Ukraine"
        },
        {
            "name": "Smarkand Aero Servise",
            "code": "",
            "auxcode": "USN",
            "companyName": "SAMAS",
            "country": "Uzbekistan"
        },
        {
            "name": "Samarkand Airways",
            "code": "",
            "auxcode": "UZS",
            "companyName": "SOGDIANA",
            "country": "Uzbekistan"
        },
        {
            "name": "Servicios Aereos Avandaro",
            "code": "",
            "auxcode": "VDO",
            "companyName": "AVANDARO",
            "country": "Mexico"
        },
        {
            "name": "Stichting Vliegschool 16Hoven",
            "code": "",
            "auxcode": "VGS",
            "companyName": "SMART",
            "country": "Netherlands"
        },
        {
            "name": "Silverback Cargo Freighters",
            "code": "",
            "auxcode": "VRB",
            "companyName": "SILVERBACK",
            "country": "Rwanda"
        },
        {
            "name": "Sirvair",
            "code": "",
            "auxcode": "VRS",
            "companyName": "VAIRSA",
            "country": "Mexico"
        },
        {
            "name": "Scat Air",
            "code": "DV",
            "auxcode": "VSV",
            "companyName": "VLASTA",
            "country": "Kazakhstan"
        },
        {
            "name": "Sunset Aviation",
            "code": "",
            "auxcode": "VXN",
            "companyName": "VIXEN",
            "country": "United States"
        },
        {
            "name": "Sport Air Travel",
            "code": "",
            "auxcode": "WCC",
            "companyName": "WEST COAST",
            "country": "United States"
        },
        {
            "name": "Swift Copters",
            "code": "",
            "auxcode": "WFC",
            "companyName": "SWIFTCOPTERS",
            "country": "Switzerland"
        },
        {
            "name": "Skyrover CC",
            "code": "",
            "auxcode": "WLK",
            "companyName": "SKYWATCH",
            "country": "South Africa"
        },
        {
            "name": "Safarilink Aviation",
            "code": "",
            "auxcode": "XLK",
            "companyName": "SAFARILINK",
            "country": "Kenya"
        },
        {
            "name": "SENEAM",
            "code": "",
            "auxcode": "XMX",
            "companyName": "SENEAM",
            "country": "Mexico"
        },
        {
            "name": "Southport Air Service",
            "code": "",
            "auxcode": "XPG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Spectrum Air Service",
            "code": "",
            "auxcode": "XSA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Stephenville Aviation Services",
            "code": "",
            "auxcode": "XSN",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Servicios Aereos Textra",
            "code": "",
            "auxcode": "XTA",
            "companyName": "TEXTRA",
            "country": "Mexico"
        },
        {
            "name": "Sector Airlines",
            "code": "",
            "auxcode": "XTR",
            "companyName": "EXTER",
            "country": "Canada"
        },
        {
            "name": "Skyplan Services",
            "code": "",
            "auxcode": "XXS",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Stewart Aviation Services",
            "code": "",
            "auxcode": "YBE",
            "companyName": "YELLOW BIRD",
            "country": "United States"
        },
        {
            "name": "Sirin",
            "code": "R1",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "Star Air",
            "code": "S6",
            "auxcode": "",
            "companyName": "",
            "country": "Denmark"
        },
        {
            "name": "Servicios Aereos Integrales / Flyant",
            "code": "",
            "auxcode": "FYA",
            "companyName": "FLYANT",
            "country": "Spain"
        },
        {
            "name": "State Flight Academy of Ukraine",
            "code": "",
            "auxcode": "UFA",
            "companyName": "FLIGHT ACADEMY",
            "country": "Ukraine"
        },
        {
            "name": "Trans Jet Airways",
            "code": "",
            "auxcode": "SWL",
            "companyName": "TRANSJET",
            "country": "Sweden"
        },
        {
            "name": "Turbot Air Cargo",
            "code": "",
            "auxcode": "TAC",
            "companyName": "TURBOT",
            "country": "Senegal"
        },
        {
            "name": "Tranporte Aereo Dominicano",
            "code": "",
            "auxcode": "TAD",
            "companyName": "TRANS DOMINICAN",
            "country": "Dominican Republic"
        },
        {
            "name": "TAME",
            "code": "EQ",
            "auxcode": "TAE",
            "companyName": "TAME",
            "country": "Ecuador"
        },
        {
            "name": "Trans International Express Aviation",
            "code": "",
            "auxcode": "BAP",
            "companyName": "BIG APPLE",
            "country": "United States"
        },
        {
            "name": "TAG Aviation USA",
            "code": "",
            "auxcode": "TAG",
            "companyName": "TAG UcodeS",
            "country": "United States"
        },
        {
            "name": "Talair",
            "code": "",
            "auxcode": "TAL",
            "companyName": "TALAIR",
            "country": "Papua New Guinea"
        },
        {
            "name": "TAM Brazilian Airlines",
            "code": "JJ",
            "auxcode": "TAM",
            "companyName": "TAM",
            "country": "Brazil"
        },
        {
            "name": "Transaustralian Air Express",
            "code": "",
            "auxcode": "AUC",
            "companyName": "AUSCARGO",
            "country": "Australia"
        },
        {
            "name": "TAP Portugal",
            "code": "TP",
            "auxcode": "TAP",
            "companyName": "AIR PORTUGAL",
            "country": "Portugal"
        },
        {
            "name": "Tunisair",
            "code": "TU",
            "auxcode": "TAR",
            "companyName": "TUNAIR",
            "country": "Tunisia"
        },
        {
            "name": "Transportes Aereos Tauro",
            "code": "",
            "auxcode": "TAU",
            "companyName": "TRANSTAURO",
            "country": "Mexico"
        },
        {
            "name": "Travelair",
            "code": "",
            "auxcode": "TAX",
            "companyName": "TRAVELAIR",
            "country": "Germany"
        },
        {
            "name": "TNT Airways",
            "code": "3V",
            "auxcode": "TAY",
            "companyName": "QUALITY",
            "country": "Belgium"
        },
        {
            "name": "Transbrasil",
            "code": "",
            "auxcode": "TBA",
            "companyName": "TRANSBRASIL",
            "country": "Brazil"
        },
        {
            "name": "Turbine Air Cargo UK",
            "code": "",
            "auxcode": "TBC",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Thunderbird Tours",
            "code": "",
            "auxcode": "TBD",
            "companyName": "ORCA",
            "country": "Canada"
        },
        {
            "name": "Tropical Airways",
            "code": "M7",
            "auxcode": "TBG",
            "companyName": "",
            "country": "Haiti"
        },
        {
            "name": "Trinity Air Bahamas",
            "code": "",
            "auxcode": "TBH",
            "companyName": "",
            "country": "Bahamas"
        },
        {
            "name": "TAB Express International",
            "code": "",
            "auxcode": "TBI",
            "companyName": "TAB INTERNATIONAL",
            "country": "United States"
        },
        {
            "name": "Taban Air Lines",
            "code": "",
            "auxcode": "TBM",
            "companyName": "TABAN AIR",
            "country": "Iran"
        },
        {
            "name": "Teebah Airlines",
            "code": "",
            "auxcode": "TBN",
            "companyName": "TEEBAH",
            "country": "Sierra Leone"
        },
        {
            "name": "Tubelair",
            "code": "",
            "auxcode": "TBR",
            "companyName": "TUBELAIR",
            "country": "Tunisia"
        },
        {
            "name": "Tobago Express",
            "code": "",
            "auxcode": "TBX",
            "companyName": "TABEX",
            "country": "Trinidad and Tobago"
        },
        {
            "name": "Tropican Air Services",
            "code": "",
            "auxcode": "TCA",
            "companyName": "TROPICANA",
            "country": "Egypt"
        },
        {
            "name": "Transporte del Caribe",
            "code": "",
            "auxcode": "TCB",
            "companyName": "TRANSCARIBE",
            "country": "Colombia"
        },
        {
            "name": "Trans Continental Airlines",
            "code": "",
            "auxcode": "TCC",
            "companyName": "TRANSCAL",
            "country": "Sudan"
        },
        {
            "name": "Tchad Airlines",
            "code": "",
            "auxcode": "TCD",
            "companyName": "TCHADLINES",
            "country": "Chad"
        },
        {
            "name": "TranscodeColorado Airlines",
            "code": "",
            "auxcode": "TCE",
            "companyName": "TRANScodeCOLORADO",
            "country": "United States"
        },
        {
            "name": "Thai Air Cargo",
            "code": "T2",
            "auxcode": "TCG",
            "companyName": "THAI CARGO",
            "country": "Thailand"
        },
        {
            "name": "Transcontinental Air",
            "code": "",
            "auxcode": "TCH",
            "companyName": "TRANS GULF",
            "country": "Bahrain"
        },
        {
            "name": "Teledyne Continental Motors",
            "code": "",
            "auxcode": "TCM",
            "companyName": "TELEDYNE",
            "country": "United States"
        },
        {
            "name": "Trans Continental Airlines",
            "code": "",
            "auxcode": "TCN",
            "companyName": "TRANSCON",
            "country": "United States"
        },
        {
            "name": "Transcorp Airways",
            "code": "",
            "auxcode": "TCP",
            "companyName": "TRANSCORP",
            "country": "United Kingdom"
        },
        {
            "name": "Transcontinental Sur",
            "code": "",
            "auxcode": "TCT",
            "companyName": "TRANScodeCONT",
            "country": "Uruguay"
        },
        {
            "name": "Transglobal Airways Corporation",
            "code": "",
            "auxcode": "TCU",
            "companyName": "TRANSGLOBAL",
            "country": "Philippines"
        },
        {
            "name": "Thomas Cook Airlines",
            "code": "FQ",
            "auxcode": "TCW",
            "companyName": "THOMAS COOK",
            "country": "Belgium"
        },
        {
            "name": "Thomas Cook Airlines",
            "code": "MT",
            "auxcode": "TCX",
            "companyName": "KESTREL",
            "country": "United Kingdom"
        },
        {
            "name": "Twin Cities Air Service",
            "code": "",
            "auxcode": "TCY",
            "companyName": "TWIN CITY",
            "country": "United States"
        },
        {
            "name": "Tadair",
            "code": "",
            "auxcode": "TDC",
            "companyName": "TADAIR",
            "country": "Spain"
        },
        {
            "name": "Tellavia / Flight One",
            "code": "",
            "auxcode": "TDE",
            "companyName": "TELLURIDE",
            "country": "United States"
        },
        {
            "name": "Transportes Aereos de Ixtlan",
            "code": "",
            "auxcode": "TDI",
            "companyName": "TRANSIXTLAN",
            "country": "Mexico"
        },
        {
            "name": "Tandem Aero",
            "code": "TQ",
            "auxcode": "TDM",
            "companyName": "TANDEM",
            "country": "Moldova"
        },
        {
            "name": "TRADO",
            "code": "",
            "auxcode": "TDO",
            "companyName": "TRADO",
            "country": "Dominican Republic"
        },
        {
            "name": "Trade Air",
            "code": "",
            "auxcode": "TDR",
            "companyName": "TRADEAIR",
            "country": "Croatia"
        },
        {
            "name": "Taxi Aero Nacional Del Evora",
            "code": "",
            "auxcode": "TDV",
            "companyName": "TAXI EVORA",
            "country": "Mexico"
        },
        {
            "name": "Tradewinds Airlines",
            "code": "",
            "auxcode": "TDX",
            "companyName": "TRADEWINDS EXPRESS",
            "country": "United States"
        },
        {
            "name": "Tenir Airlines",
            "code": "",
            "auxcode": "TEB",
            "companyName": "TENIR AIR",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Teamline Air",
            "code": "L9",
            "auxcode": "TLW",
            "companyName": "Teamline",
            "country": "Austria"
        },
        {
            "name": "Tecnicas Fotograficas",
            "code": "",
            "auxcode": "TEF",
            "companyName": "TECFOTO",
            "country": "Spain"
        },
        {
            "name": "Tempelhof Airways",
            "code": "",
            "auxcode": "TEH",
            "companyName": "TEMPELHOF",
            "country": "United States"
        },
        {
            "name": "Telford Aviation",
            "code": "",
            "auxcode": "TEL",
            "companyName": "TELFORD",
            "country": "United States"
        },
        {
            "name": "TechcodeMont Helicopter Company",
            "code": "",
            "auxcode": "TEM",
            "companyName": "TECHMONT",
            "country": "Slovakia"
        },
        {
            "name": "Tennessee Airways",
            "code": "",
            "auxcode": "TEN",
            "companyName": "TENNESSEE",
            "country": "United States"
        },
        {
            "name": "Territorial Airlines",
            "code": "",
            "auxcode": "TER",
            "companyName": "TERRIcodeAIRE",
            "country": "United States"
        },
        {
            "name": "TAES Transportes Aereos de El Salvador",
            "code": "",
            "auxcode": "TES",
            "companyName": "TAES",
            "country": "El Salvador"
        },
        {
            "name": "Transeuropean Airlines",
            "code": "UE",
            "auxcode": "TEP",
            "companyName": "TRANSEURLINE",
            "country": "Russia"
        },
        {
            "name": "TepaviacodeTrans Airlines",
            "code": "",
            "auxcode": "TET",
            "companyName": "TEPAVIA",
            "country": "Moldova"
        },
        {
            "name": "TranscodeFlorida Airlines",
            "code": "",
            "auxcode": "TFA",
            "companyName": "TRANS FLORIDA",
            "country": "United States"
        },
        {
            "name": "Tair Airways",
            "code": "",
            "auxcode": "TFB",
            "companyName": "ROYAL TEEcodeAIR",
            "country": "Philippines"
        },
        {
            "name": "Talon Air",
            "code": "",
            "auxcode": "TFF",
            "companyName": "TALON FLIGHT",
            "country": "United States"
        },
        {
            "name": "Trabajos Aereos Murcianos",
            "code": "",
            "auxcode": "AIM",
            "companyName": "PIJO",
            "country": "Spain"
        },
        {
            "name": "Thai Flying Helicopter Service",
            "code": "",
            "auxcode": "TFH",
            "companyName": "THAI HELICOPTER",
            "country": "Thailand"
        },
        {
            "name": "Transport Facilitators",
            "code": "",
            "auxcode": "TFI",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Transafrik International",
            "code": "",
            "auxcode": "TFK",
            "companyName": "",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "Transportes Aereos del Pacifico",
            "code": "",
            "auxcode": "TFO",
            "companyName": "TRANSPORTES PACIFICO",
            "country": "Mexico"
        },
        {
            "name": "Thai Flying Service",
            "code": "",
            "auxcode": "TFT",
            "companyName": "THAI FLYING",
            "country": "Thailand"
        },
        {
            "name": "Transportes Aereos San Rafael",
            "code": "",
            "auxcode": "SRF",
            "companyName": "SAN RAFEAL",
            "country": "Chile"
        },
        {
            "name": "Tayside Aviation",
            "code": "",
            "auxcode": "TFY",
            "companyName": "TAYSIDE",
            "country": "United Kingdom"
        },
        {
            "name": "TG Aviation",
            "code": "",
            "auxcode": "TGC",
            "companyName": "THANET",
            "country": "United Kingdom"
        },
        {
            "name": "Trabajos Aereos",
            "code": "",
            "auxcode": "TGE",
            "companyName": "TASA",
            "country": "Spain"
        },
        {
            "name": "Transportes Aereos Regionales",
            "code": "",
            "auxcode": "TGI",
            "companyName": "TRANSPORTE REGIONAL",
            "country": "Mexico"
        },
        {
            "name": "TAG Aviation Espana",
            "code": "",
            "auxcode": "TGM",
            "companyName": "TAG ESPANA",
            "country": "Spain"
        },
        {
            "name": "Trigana Air Service",
            "code": "",
            "auxcode": "TGN",
            "companyName": "TRIGANA",
            "country": "Indonesia"
        },
        {
            "name": "Titan Airways",
            "code": "ZT",
            "auxcode": "AWC",
            "companyName": "ZAP",
            "country": "United Kingdom"
        },
        {
            "name": "Transport Canada",
            "code": "",
            "auxcode": "TGO",
            "companyName": "TRANSPORT",
            "country": "Canada"
        },
        {
            "name": "Tiger Airways",
            "code": "TR",
            "auxcode": "TGW",
            "companyName": "GO CAT",
            "country": "Singapore"
        },
        {
            "name": "Tiger Airways Australia",
            "code": "TT",
            "auxcode": "TGW",
            "companyName": "GO CAT",
            "country": "Australia"
        },
        {
            "name": "Transair Gabon]",
            "code": "",
            "auxcode": "TGX",
            "companyName": "TRANSGABON",
            "country": "Gabon"
        },
        {
            "name": "Trans Guyana Airways",
            "code": "",
            "auxcode": "TGY",
            "companyName": "TRANS GUYANA",
            "country": "Guyana"
        },
        {
            "name": "Thai Airways International",
            "code": "TG",
            "auxcode": "THA",
            "companyName": "THAI",
            "country": "Thailand"
        },
        {
            "name": "Tar Heel Aviation",
            "code": "",
            "auxcode": "THC",
            "companyName": "TARHEEL",
            "country": "United States"
        },
        {
            "name": "Toumai Air Tchad",
            "code": "",
            "auxcode": "THE",
            "companyName": "TOUMAI AIR",
            "country": "Chad"
        },
        {
            "name": "Touraine Helicoptere",
            "code": "",
            "auxcode": "THF",
            "companyName": "TOURAINE HELICO",
            "country": "France"
        },
        {
            "name": "Thai Global Airline",
            "code": "",
            "auxcode": "THG",
            "companyName": "THAI GLOBAL",
            "country": "Thailand"
        },
        {
            "name": "Thai Jet Intergroup",
            "code": "",
            "auxcode": "THJ",
            "companyName": "THAI JET",
            "country": "Thailand"
        },
        {
            "name": "Turk Hava Kurumu Hava Taksi Isletmesi",
            "code": "",
            "auxcode": "THK",
            "companyName": "HUR KUS",
            "country": "Turkey"
        },
        {
            "name": "Thai AirAsia",
            "code": "FD",
            "auxcode": "AIQ",
            "companyName": "THAI ASIA",
            "country": "Thailand"
        },
        {
            "name": "TACA De Honduras",
            "code": "",
            "auxcode": "THO",
            "companyName": "LEMPIRA",
            "country": "Honduras"
        },
        {
            "name": "Tehran Airline",
            "code": "",
            "auxcode": "THR",
            "companyName": "TEHRAN AIR",
            "country": "Iran"
        },
        {
            "name": "Thunder Airlines",
            "code": "",
            "auxcode": "THU",
            "companyName": "AIR THUNDER",
            "country": "Canada"
        },
        {
            "name": "Turkish Airlines",
            "code": "TK",
            "auxcode": "THY",
            "companyName": "TURKAIR",
            "country": "Turkey"
        },
        {
            "name": "Trans Helicoptere Service",
            "code": "",
            "auxcode": "THZ",
            "companyName": "LYON HELIJET",
            "country": "France"
        },
        {
            "name": "Trans International Airlines",
            "code": "",
            "auxcode": "TIA",
            "companyName": "TRANS INTERNATIONAL",
            "country": "United States"
        },
        {
            "name": "Travel International Air Charters",
            "code": "",
            "auxcode": "TIC",
            "companyName": "TRAVEL INTERNATIONAL",
            "country": "Zambia"
        },
        {
            "name": "Time Air",
            "code": "",
            "auxcode": "TIE",
            "companyName": "TIME AIR",
            "country": "Czech Republic"
        },
        {
            "name": "Tic Air",
            "code": "",
            "auxcode": "TIK",
            "companyName": "TICAIR",
            "country": "Australia"
        },
        {
            "name": "Tajikistan International Airlines",
            "code": "",
            "auxcode": "TIL",
            "companyName": "TIL",
            "country": "Tajikistan"
        },
        {
            "name": "TEAM Transportes Aereos",
            "code": "",
            "auxcode": "TIM",
            "companyName": "TEAM BRASIL",
            "country": "Brazil"
        },
        {
            "name": "Taino Tours",
            "code": "",
            "auxcode": "TIN",
            "companyName": "TAINO",
            "country": "Dominican Republic"
        },
        {
            "name": "Tesis",
            "code": "",
            "auxcode": "TIS",
            "companyName": "TESIS",
            "country": "Russia"
        },
        {
            "name": "Transcarga Intl Airways",
            "code": "",
            "auxcode": "TIW",
            "companyName": "TIACA",
            "country": "Venezuela"
        },
        {
            "name": "Tajikair",
            "code": "",
            "auxcode": "TJK",
            "companyName": "TAJIKAIR",
            "country": "Tajikistan"
        },
        {
            "name": "TiencodeShan",
            "code": "",
            "auxcode": "TJN",
            "companyName": "NERON",
            "country": "Kazakhstan"
        },
        {
            "name": "Tyrolean Jet Service",
            "code": "",
            "auxcode": "TJS",
            "companyName": "TYROLJET",
            "country": "Austria"
        },
        {
            "name": "Twin Jet",
            "code": "T7",
            "auxcode": "TJT",
            "companyName": "TWINJET",
            "country": "France"
        },
        {
            "name": "Tikal Jets Airlines",
            "code": "",
            "auxcode": "TKC",
            "companyName": "TIKAL",
            "country": "Guatemala"
        },
        {
            "name": "Take Air Line",
            "code": "",
            "auxcode": "TKE",
            "companyName": "ISLAND BIRD",
            "country": "France"
        },
        {
            "name": "Tropical International Airways",
            "code": "",
            "auxcode": "TKX",
            "companyName": "TROPEXPRESS",
            "country": "Saint Kitts and Nevis"
        },
        {
            "name": "Thai Sky Airlines",
            "code": "9I",
            "auxcode": "TKY",
            "companyName": "THAI SKY",
            "country": "Thailand"
        },
        {
            "name": "Translift Airways",
            "code": "",
            "auxcode": "TLA",
            "companyName": "TRANSLIFT",
            "country": "Ireland"
        },
        {
            "name": "Transport Africa",
            "code": "",
            "auxcode": "TLF",
            "companyName": "TRANScodeLEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Trans Atlantic Airlines",
            "code": "",
            "auxcode": "TLL",
            "companyName": "ATLANTIC LEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "Eagle Canyon Airlines",
            "code": "",
            "auxcode": "TLO",
            "companyName": "TALON AIR",
            "country": "United States"
        },
        {
            "name": "Tulip Air",
            "code": "TD",
            "auxcode": "TLP",
            "companyName": "TULIPAIR",
            "country": "Netherland"
        },
        {
            "name": "Tunisavia",
            "code": "",
            "auxcode": "TAJ",
            "companyName": "TUNISAVIA",
            "country": "Tunisia"
        },
        {
            "name": "TLC Air",
            "code": "",
            "auxcode": "TLS",
            "companyName": "TEALSY",
            "country": "United States"
        },
        {
            "name": "Turtle Airways",
            "code": "",
            "auxcode": "TLT",
            "companyName": "TURTLE",
            "country": "Fiji"
        },
        {
            "name": "Travelair",
            "code": "",
            "auxcode": "TLV",
            "companyName": "PAJAROS",
            "country": "Uruguay"
        },
        {
            "name": "Telesis Transair",
            "code": "",
            "auxcode": "TLX",
            "companyName": "TELESIS",
            "country": "United States"
        },
        {
            "name": "TopcodeFly",
            "code": "",
            "auxcode": "TLY",
            "companyName": "TOPFLY",
            "country": "Spain"
        },
        {
            "name": "Trans Mediterranean Airlines",
            "code": "TL",
            "auxcode": "TMA",
            "companyName": "TANGO LIMA",
            "country": "Lebanon"
        },
        {
            "name": "Transmed Airlines",
            "code": "",
            "auxcode": "TMD",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "TricodeMG Intra Asia Airlines",
            "code": "GY",
            "auxcode": "TMG",
            "companyName": "TRILINES",
            "country": "Indonesia"
        },
        {
            "name": "Taxis Turisticos Marakame",
            "code": "",
            "auxcode": "TMH",
            "companyName": "TAXIMARAKAME",
            "country": "Mexico"
        },
        {
            "name": "Tamir Airways",
            "code": "",
            "auxcode": "TMI",
            "companyName": "TAMIRWAYS",
            "country": "Israel"
        },
        {
            "name": "Tomahawk Airways",
            "code": "",
            "auxcode": "TMK",
            "companyName": "TOMAHAWK",
            "country": "United States"
        },
        {
            "name": "TAM",
            "code": "",
            "auxcode": "TML",
            "companyName": "TAM AIRLINE",
            "country": "Madagascar"
        },
        {
            "name": "TMC Airlines",
            "code": "",
            "auxcode": "TMM",
            "companyName": "WILLOW RUN",
            "country": "United States"
        },
        {
            "name": "TRAM",
            "code": "",
            "auxcode": "TMQ",
            "companyName": "TRAM AIR",
            "country": "Mauritania"
        },
        {
            "name": "Timberline Air",
            "code": "",
            "auxcode": "TMR",
            "companyName": "TIMBER",
            "country": "Canada"
        },
        {
            "name": "Temsco Helicopters",
            "code": "",
            "auxcode": "TMS",
            "companyName": "TEMSCO",
            "country": "United States"
        },
        {
            "name": "Trans Midwest Airlines",
            "code": "",
            "auxcode": "TMT",
            "companyName": "TRANS MIDWEST",
            "country": "United States"
        },
        {
            "name": "Tramon Air",
            "code": "",
            "auxcode": "TMX",
            "companyName": "TRAMON",
            "country": "South Africa"
        },
        {
            "name": "Transportes Aereos del Mundo Maya",
            "code": "",
            "auxcode": "TMY",
            "companyName": "MUNDO MAYA",
            "country": "Mexico"
        },
        {
            "name": "Tranporte Amazonair",
            "code": "",
            "auxcode": "TMZ",
            "companyName": "TRANS AMAZON",
            "country": "Venezuela"
        },
        {
            "name": "Trans AircodeBenin",
            "code": "",
            "auxcode": "TNB",
            "companyName": "TRANScodeBENIN",
            "country": "Benin"
        },
        {
            "name": "Taxis Aereos del Noroeste",
            "code": "",
            "auxcode": "TNE",
            "companyName": "TAXINOROESTE",
            "country": "Mexico"
        },
        {
            "name": "Transafricaine",
            "code": "",
            "auxcode": "TNF",
            "companyName": "TRANSFAS",
            "country": "Burkina Faso"
        },
        {
            "name": "Tennessee Air National Guard 164th Airlift Group",
            "code": "",
            "auxcode": "TNG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Transair International Linhas Aereas",
            "code": "",
            "auxcode": "TNI",
            "companyName": "TRANSINTER",
            "country": "United States"
        },
        {
            "name": "Tengeriyn Ulaach Shine",
            "code": "",
            "auxcode": "TNL",
            "companyName": "SKY HORSE",
            "country": "Mongolia"
        },
        {
            "name": "Tiara Air",
            "code": "3P",
            "auxcode": "TNM",
            "companyName": "TIARA",
            "country": "Aruba"
        },
        {
            "name": "Transped Aviation",
            "code": "",
            "auxcode": "TNP",
            "companyName": "TRANSPED",
            "country": "Austria"
        },
        {
            "name": "Tanana Air Services",
            "code": "",
            "auxcode": "TNR",
            "companyName": "TAN AIR",
            "country": "United States"
        },
        {
            "name": "Trans North Turbo Air",
            "code": "",
            "auxcode": "TNT",
            "companyName": "TRANS NORTH",
            "country": "Canada"
        },
        {
            "name": "Transnorthern",
            "code": "",
            "auxcode": "TNV",
            "companyName": "TRANSNORTHERN",
            "country": "United States"
        },
        {
            "name": "Trans Nation Airways",
            "code": "",
            "auxcode": "TNW",
            "companyName": "TRANScodeNATION",
            "country": "Ethiopia"
        },
        {
            "name": "Trener Ltd",
            "code": "",
            "auxcode": "TNX",
            "companyName": "TRAINER",
            "country": "Hungary"
        },
        {
            "name": "Twin Town Leasing Company",
            "code": "",
            "auxcode": "TNY",
            "companyName": "TWINCAL",
            "country": "United States"
        },
        {
            "name": "Tobruk Air",
            "code": "7T",
            "auxcode": "TOB",
            "companyName": "TOBRUK AIR",
            "country": "Libya"
        },
        {
            "name": "TOJ Airlines",
            "code": "",
            "auxcode": "TOJ",
            "companyName": "TOJ AIRLINE",
            "country": "Tajikistan"
        },
        {
            "name": "TolcodeAir Services",
            "code": "TI",
            "auxcode": "TOL",
            "companyName": "TOL AIR",
            "country": "United States"
        },
        {
            "name": "Thomsonfly",
            "code": "BY",
            "auxcode": "TOM",
            "companyName": "TOMSON",
            "country": "United Kingdom"
        },
        {
            "name": "Top Air",
            "code": "",
            "auxcode": "TOP",
            "companyName": "AIR TOP",
            "country": "Turkey"
        },
        {
            "name": "Toronto Airways",
            "code": "",
            "auxcode": "TOR",
            "companyName": "TORONTAIR",
            "country": "Canada"
        },
        {
            "name": "Tropic Air",
            "code": "PM",
            "auxcode": "TOS",
            "companyName": "TROPISER",
            "country": "Belize"
        },
        {
            "name": "Totavia",
            "code": "",
            "auxcode": "TOT",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Tower Air",
            "code": "FF",
            "auxcode": "TOW",
            "companyName": "TEE AIR",
            "country": "United States"
        },
        {
            "name": "Toyota Canada",
            "code": "",
            "auxcode": "TOY",
            "companyName": "TOYOTA",
            "country": "Canada"
        },
        {
            "name": "TAMPA",
            "code": "QT",
            "auxcode": "TPA",
            "companyName": "TAMPA",
            "country": "Colombia"
        },
        {
            "name": "Top Speed",
            "code": "",
            "auxcode": "TPD",
            "companyName": "TOP SPEED",
            "country": "Austria"
        },
        {
            "name": "Taxis Aereos del Pacifico",
            "code": "",
            "auxcode": "TPF",
            "companyName": "TAXIPACIFICO",
            "country": "Mexico"
        },
        {
            "name": "Transportes Aereos Pegaso",
            "code": "",
            "auxcode": "TPG",
            "companyName": "TRANSPEGASO",
            "country": "Mexico"
        },
        {
            "name": "TAR Interpilot",
            "code": "",
            "auxcode": "TPL",
            "companyName": "INTERPILOT",
            "country": "Mauritania"
        },
        {
            "name": "Transpais Aereo",
            "code": "",
            "auxcode": "TPM",
            "companyName": "TRANSPAIS",
            "country": "Mexico"
        },
        {
            "name": "Transportacion Aerea del Norte",
            "code": "",
            "auxcode": "TPN",
            "companyName": "AEREA DELNORTE",
            "country": "Mexico"
        },
        {
            "name": "Transpac Express",
            "code": "",
            "auxcode": "TPP",
            "companyName": "TRANS EXPRESS",
            "country": "Australia"
        },
        {
            "name": "Taxis Aereos De Parral",
            "code": "",
            "auxcode": "TPR",
            "companyName": "TAXIS PARRAL",
            "country": "Mexico"
        },
        {
            "name": "TAPSA Transportes Aereos Petroleros",
            "code": "",
            "auxcode": "TPS",
            "companyName": "TAPSA",
            "country": "Argentina"
        },
        {
            "name": "Transportes Aereo del Sureste",
            "code": "",
            "auxcode": "TPT",
            "companyName": "TASSA",
            "country": "Mexico"
        },
        {
            "name": "Trans American Airlines (Trans Am)",
            "code": "",
            "auxcode": "TPU",
            "companyName": "TRANS PERU",
            "country": "Peru"
        },
        {
            "name": "Thai Pacific Airlines Business",
            "code": "",
            "auxcode": "TPV",
            "companyName": "THAI PACIFIC",
            "country": "Thailand"
        },
        {
            "name": "Transportes Aereos De Xalapa",
            "code": "",
            "auxcode": "TPX",
            "companyName": "TRANSXALAPA",
            "country": "Mexico"
        },
        {
            "name": "TranscodeProvincial Airlines",
            "code": "",
            "auxcode": "TPY",
            "companyName": "TRANS PROVINCIAL",
            "country": "Canada"
        },
        {
            "name": "Transportes La Paz",
            "code": "",
            "auxcode": "TPZ",
            "companyName": "TRANSPAZ",
            "country": "Mexico"
        },
        {
            "name": "Taquan Air Services",
            "code": "",
            "auxcode": "TQN",
            "companyName": "TAQUAN",
            "country": "United States"
        },
        {
            "name": "Transportacion Aerea De Queretaro",
            "code": "",
            "auxcode": "TQR",
            "companyName": "TRANSQUERETARO",
            "country": "Mexico"
        },
        {
            "name": "TransAsia Airways",
            "code": "GE",
            "auxcode": "TNA",
            "companyName": "TransAsia",
            "country": "Taiwan"
        },
        {
            "name": "Transavia Holland",
            "code": "HV",
            "auxcode": "TRA",
            "companyName": "TRANSAVIA",
            "country": "Netherlands"
        },
        {
            "name": "Trans Air Charter",
            "code": "",
            "auxcode": "TRC",
            "companyName": "TRACKER",
            "country": "United States"
        },
        {
            "name": "TACV",
            "code": "VR",
            "auxcode": "TCV",
            "companyName": "CABOVERDE",
            "country": "Portugal"
        },
        {
            "name": "Trans Island Air",
            "code": "",
            "auxcode": "TRD",
            "companyName": "TRANS ISLAND",
            "country": "Barbados"
        },
        {
            "name": "Taxi Air Fret",
            "code": "",
            "auxcode": "TRF",
            "companyName": "TAXI JET",
            "country": "France"
        },
        {
            "name": "TRAGSA (Medios Aereos)",
            "code": "",
            "auxcode": "TRG",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Trans Euro Air",
            "code": "",
            "auxcode": "TRJ",
            "companyName": "HIGH TIDE",
            "country": "United Kingdom"
        },
        {
            "name": "Transport Aerien de Mauritanie",
            "code": "",
            "auxcode": "TRM",
            "companyName": "SOTRANS",
            "country": "Mauritania"
        },
        {
            "name": "Tropic AirlinescodeAir Molokai",
            "code": "",
            "auxcode": "TRO",
            "companyName": "MOLOKAI",
            "country": "United States"
        },
        {
            "name": "Turdus Airways",
            "code": "",
            "auxcode": "TRQ",
            "companyName": "HUNTER",
            "country": "Netherland"
        },
        {
            "name": "Tramson Limited",
            "code": "",
            "auxcode": "TRR",
            "companyName": "TRAMSON",
            "country": "Sudan"
        },
        {
            "name": "Trans Arabian Air Transport",
            "code": "",
            "auxcode": "TRT",
            "companyName": "TRANS ARABIAN",
            "country": "Sudan"
        },
        {
            "name": "Triangle Airline (Uganda)",
            "code": "",
            "auxcode": "TRU",
            "companyName": "TRI AIR",
            "country": "Uganda"
        },
        {
            "name": "Transwestern Airlines of Utah",
            "code": "",
            "auxcode": "TRW",
            "companyName": "TRANScodeWEST",
            "country": "United States"
        },
        {
            "name": "Tristar Airlines",
            "code": "",
            "auxcode": "TRY",
            "companyName": "TRISTAR AIR",
            "country": "United States"
        },
        {
            "name": "TransMeridian Airlines",
            "code": "T9",
            "auxcode": "TRZ",
            "companyName": "TRANScodeMERIDIAN",
            "country": "United States"
        },
        {
            "name": "Transair France",
            "code": "",
            "auxcode": "TSA",
            "companyName": "AIRTRAF",
            "country": "France"
        },
        {
            "name": "TAFcodeLinhas Aereas",
            "code": "",
            "auxcode": "TSD",
            "companyName": "TAFI",
            "country": "Brazil"
        },
        {
            "name": "Transmile Air Services",
            "code": "TH",
            "auxcode": "TSE",
            "companyName": "TRANSMILE",
            "country": "Malaysia"
        },
        {
            "name": "TranscodeAircodeCongo",
            "code": "",
            "auxcode": "TSG",
            "companyName": "TRANScodeCONGO",
            "country": "Republic of the Congo"
        },
        {
            "name": "Transport'air",
            "code": "",
            "auxcode": "TSI",
            "companyName": "TRANSPORTAIR",
            "country": "France"
        },
        {
            "name": "Trast Aero",
            "code": "S5",
            "auxcode": "TSJ",
            "companyName": "TRAST AERO",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Trast Aero",
            "code": "",
            "auxcode": "TSK",
            "companyName": "TOMSKcodeAVIA",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Thai Aviation Services",
            "code": "",
            "auxcode": "TSL",
            "companyName": "THAI AVIATION",
            "country": "Thailand"
        },
        {
            "name": "Taftan Airlines",
            "code": "",
            "auxcode": "SBT",
            "companyName": "TAFTAN",
            "country": "Iran"
        },
        {
            "name": "Transwest Air",
            "code": "9T",
            "auxcode": "ABS",
            "companyName": "ATHABASKA",
            "country": "Canada"
        },
        {
            "name": "Trans Sayegh Airport Services",
            "code": "",
            "auxcode": "TSM",
            "companyName": "",
            "country": "Lebanon"
        },
        {
            "name": "TranscodeAir Services",
            "code": "",
            "auxcode": "TSN",
            "companyName": "AIR TRANS",
            "country": "Nigeria"
        },
        {
            "name": "Transaero Airlines",
            "code": "UN",
            "auxcode": "TSO",
            "companyName": "TRANSOVIET",
            "country": "Russia"
        },
        {
            "name": "Transportes Aereos Inter",
            "code": "",
            "auxcode": "TSP",
            "companyName": "TRANSPOcodeINTER",
            "country": "Guatemala"
        },
        {
            "name": "Trans Service Airlift",
            "code": "",
            "auxcode": "TSR",
            "companyName": "TRANS SERVICE",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "TricodeState Aero",
            "code": "",
            "auxcode": "TSS",
            "companyName": "TRIcodeSTATE",
            "country": "United States"
        },
        {
            "name": "TRAST",
            "code": "",
            "auxcode": "TST",
            "companyName": "TRAST",
            "country": "Kazakhstan"
        },
        {
            "name": "Tropair Airservices",
            "code": "",
            "auxcode": "TSV",
            "companyName": "TROPIC",
            "country": "United Kingdom"
        },
        {
            "name": "Transwings",
            "code": "",
            "auxcode": "TSW",
            "companyName": "SWISSTRANS",
            "country": "Switzerland"
        },
        {
            "name": "Thai Star Airlines",
            "code": "T9",
            "auxcode": "TSX",
            "companyName": "THAI STAR",
            "country": "Thailand"
        },
        {
            "name": "Tristar Air",
            "code": "",
            "auxcode": "TSY",
            "companyName": "TRIPLE STAR",
            "country": "Egypt"
        },
        {
            "name": "TTA code Sociedade de Transporte e Trabalho Aereo",
            "code": "",
            "auxcode": "TTA",
            "companyName": "KANIMANBO",
            "country": "Mozambique"
        },
        {
            "name": "Transteco",
            "code": "",
            "auxcode": "TTC",
            "companyName": "TRANSTECO",
            "country": "Angola"
        },
        {
            "name": "Tarhan Tower Airlines",
            "code": "",
            "auxcode": "TTH",
            "companyName": "",
            "country": "Turkey"
        },
        {
            "name": "Total Linhas Aereas",
            "code": "",
            "auxcode": "TTL",
            "companyName": "TOTAL",
            "country": "Brazil"
        },
        {
            "name": "Triple O Aviation",
            "code": "",
            "auxcode": "TTP",
            "companyName": "MIGHTY WING",
            "country": "Nigeria"
        },
        {
            "name": "Transportaciones Y Servicios Aereos",
            "code": "",
            "auxcode": "TTR",
            "companyName": "TRANSPORTACIONES",
            "country": "Mexico"
        },
        {
            "name": "Transporte Aereo Tecnico Ejecutivo",
            "code": "",
            "auxcode": "TTS",
            "companyName": "TECNICO",
            "country": "Mexico"
        },
        {
            "name": "Turkmenistan Airlines",
            "code": "T5",
            "auxcode": "TUA",
            "companyName": "TURKMENISTAN",
            "country": "Turkmenistan"
        },
        {
            "name": "Turismo Aereo de Chile",
            "code": "",
            "auxcode": "TUC",
            "companyName": "TURICHILE",
            "country": "Chile"
        },
        {
            "name": "Tuninter",
            "code": "UG",
            "auxcode": "TUI",
            "companyName": "",
            "country": "Tunisia"
        },
        {
            "name": "Tulpar Air",
            "code": "",
            "auxcode": "TUL",
            "companyName": "URSAL",
            "country": "Russia"
        },
        {
            "name": "Tyumenspecavia",
            "code": "",
            "auxcode": "TUM",
            "companyName": "TUMTEL",
            "country": "Russia"
        },
        {
            "name": "Taxi Aereo Turistico",
            "code": "",
            "auxcode": "TUO",
            "companyName": "TURISTICO",
            "country": "Mexico"
        },
        {
            "name": "Tulpar Air Service",
            "code": "",
            "auxcode": "TUX",
            "companyName": "TULPA",
            "country": "Kazakhstan"
        },
        {
            "name": "Tuna Aero",
            "code": "",
            "auxcode": "TUZ",
            "companyName": "TUNA",
            "country": "Sweden"
        },
        {
            "name": "Trans America Airlines",
            "code": "",
            "auxcode": "TVA",
            "companyName": "TRANScodeAMERICA",
            "country": "United States"
        },
        {
            "name": "Trabajos Aereos Vascongados",
            "code": "",
            "auxcode": "TVH",
            "companyName": "TRAVASA",
            "country": "Spain"
        },
        {
            "name": "Tiramavia",
            "code": "",
            "auxcode": "TVI",
            "companyName": "TIRAMAVIA",
            "country": "Moldova"
        },
        {
            "name": "Travel Service",
            "code": "",
            "auxcode": "TVL",
            "companyName": "TRAVEL SERVICE",
            "country": "Hungary"
        },
        {
            "name": "Transavio",
            "code": "",
            "auxcode": "TVO",
            "companyName": "TRANScodeBALLERIO",
            "country": "Italy"
        },
        {
            "name": "Tavrey Airlines",
            "code": "T6",
            "auxcode": "TVR",
            "companyName": "TAVREY",
            "country": "Ukraine"
        },
        {
            "name": "Travel Service",
            "code": "QS",
            "auxcode": "TVS",
            "companyName": "SKYTRAVEL",
            "country": "Czech Republic"
        },
        {
            "name": "Trans World Airlines",
            "code": "TW",
            "auxcode": "TWA",
            "companyName": "TWA",
            "country": "United States"
        },
        {
            "name": "Transwede Airways",
            "code": "",
            "auxcode": "TWE",
            "companyName": "TRANSWEDE",
            "country": "Sweden"
        },
        {
            "name": "Twinjet Aircraft Sales",
            "code": "",
            "auxcode": "TWJ",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Tradewinds Aviation",
            "code": "",
            "auxcode": "TWL",
            "companyName": "TRADEWINDS CANADA",
            "country": "Canada"
        },
        {
            "name": "Transairways",
            "code": "",
            "auxcode": "TWM",
            "companyName": "",
            "country": "Mozambique"
        },
        {
            "name": "Twente Airlines",
            "code": "",
            "auxcode": "TWO",
            "companyName": "COLIBRI",
            "country": "Netherlands"
        },
        {
            "name": "Trans Air Welwitchia",
            "code": "",
            "auxcode": "TWW",
            "companyName": "WELWITCHIA",
            "country": "Angola"
        },
        {
            "name": "Texair Charter",
            "code": "",
            "auxcode": "TXA",
            "companyName": "OKAY AIR",
            "country": "United States"
        },
        {
            "name": "Transaviaexport",
            "code": "AL",
            "auxcode": "TXC",
            "companyName": "TRANSEXPORT",
            "country": "Belarus"
        },
        {
            "name": "Transilvania Express",
            "code": "",
            "auxcode": "TXE",
            "companyName": "TRANSAIR EXPRESS",
            "country": "Romania"
        },
        {
            "name": "Taxi Aereo Cozatl",
            "code": "",
            "auxcode": "TXL",
            "companyName": "TAXI COZATL",
            "country": "Mexico"
        },
        {
            "name": "Taxi Aereo de Mexico",
            "code": "",
            "auxcode": "TXM",
            "companyName": "TAXIMEX",
            "country": "Mexico"
        },
        {
            "name": "Texas National Airlines",
            "code": "",
            "auxcode": "TXN",
            "companyName": "TEXAS NATIONAL",
            "country": "United States"
        },
        {
            "name": "Taxis Aereos de Sinaloa",
            "code": "",
            "auxcode": "TXO",
            "companyName": "TAXIS SINALOA",
            "country": "Mexico"
        },
        {
            "name": "Taxirey",
            "code": "",
            "auxcode": "TXR",
            "companyName": "TAXIREY",
            "country": "Mexico"
        },
        {
            "name": "Texas Airlines",
            "code": "",
            "auxcode": "TXS",
            "companyName": "TEXAIR",
            "country": "United States"
        },
        {
            "name": "Texas Air Charters",
            "code": "",
            "auxcode": "TXT",
            "companyName": "TEXAS CHARTER",
            "country": "United States"
        },
        {
            "name": "Tex Star Air Freight",
            "code": "",
            "auxcode": "TXZ",
            "companyName": "TEX STAR",
            "country": "United States"
        },
        {
            "name": "Tayflite",
            "code": "",
            "auxcode": "TYF",
            "companyName": "TAYFLITE",
            "country": "United Kingdom"
        },
        {
            "name": "TryggcodeFlyg",
            "code": "",
            "auxcode": "TYG",
            "companyName": "TRYGG",
            "country": "Sweden"
        },
        {
            "name": "Tyrol Air Ambulance",
            "code": "",
            "auxcode": "TYW",
            "companyName": "TYROL AMBULANCE",
            "country": "Austria"
        },
        {
            "name": "Transporte Aereo Ernesto Saenz",
            "code": "",
            "auxcode": "TZE",
            "companyName": "TRANSPORTE SAENZ",
            "country": "Mexico"
        },
        {
            "name": "Tajikstan",
            "code": "",
            "auxcode": "TZK",
            "companyName": "TAJIKSTAN",
            "country": "Tajikistan"
        },
        {
            "name": "Thyssen Krupp AG",
            "code": "",
            "auxcode": "BLI",
            "companyName": "BLUELINE",
            "country": "Germany"
        },
        {
            "name": "TUIfly Nordic",
            "code": "6B",
            "auxcode": "BLX",
            "companyName": "BLUESCAN",
            "country": "Sweden"
        },
        {
            "name": "Transportes Aereos Bolivianos",
            "code": "",
            "auxcode": "BOL",
            "companyName": "BOL",
            "country": "Bolivia"
        },
        {
            "name": "Tiphook PLC",
            "code": "",
            "auxcode": "BOX",
            "companyName": "BOX",
            "country": "United Kingdom"
        },
        {
            "name": "Top Flight Air Service",
            "code": "",
            "auxcode": "CHE",
            "companyName": "CHECK AIR",
            "country": "United States"
        },
        {
            "name": "Trans America",
            "code": "",
            "auxcode": "CLR",
            "companyName": "CLINTON AIRWAYS",
            "country": "United States"
        },
        {
            "name": "Triple Alpha",
            "code": "",
            "auxcode": "CLU",
            "companyName": "CAROLUS",
            "country": "Germany"
        },
        {
            "name": "Tashkent Aircraft Production Corporation",
            "code": "",
            "auxcode": "CTP",
            "companyName": "CORTAS",
            "country": "Uzbekistan"
        },
        {
            "name": "Texas Airways",
            "code": "",
            "auxcode": "CWT",
            "companyName": "TEXAS AIRWAYS",
            "country": "United States"
        },
        {
            "name": "Transportes Aereos Don Carlos",
            "code": "",
            "auxcode": "DCL",
            "companyName": "DON CARLOS",
            "country": "Chile"
        },
        {
            "name": "Telnic Limited",
            "code": "",
            "auxcode": "DOT",
            "companyName": "DOT TEL",
            "country": "United Kingdom"
        },
        {
            "name": "Triton Airlines",
            "code": "",
            "auxcode": "DRC",
            "companyName": "TRITON AIR",
            "country": "Canada"
        },
        {
            "name": "TAAG Angola Airlines",
            "code": "DT",
            "auxcode": "DTA",
            "companyName": "DTA",
            "country": "Angola"
        },
        {
            "name": "Tassili Airlines",
            "code": "SF",
            "auxcode": "DTH",
            "companyName": "TASSILI AIR",
            "country": "Algeria"
        },
        {
            "name": "Transporte Ejecutivo Aereo",
            "code": "",
            "auxcode": "EAR",
            "companyName": "EJECUTIVOcodeAEREO",
            "country": "Mexico"
        },
        {
            "name": "Transportes Aereos Nacionales De Selva Tans",
            "code": "",
            "auxcode": "ELV",
            "companyName": "AEREOS SELVA",
            "country": "Peru"
        },
        {
            "name": "TAG Farnborough Airport",
            "code": "",
            "auxcode": "FBO",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Transaviaservice",
            "code": "",
            "auxcode": "FNV",
            "companyName": "TRANSAVIASERVICE",
            "country": "Georgia"
        },
        {
            "name": "TAG Aviation",
            "code": "",
            "auxcode": "FPG",
            "companyName": "TAG AVIATION",
            "country": "Switzerland"
        },
        {
            "name": "The 955 Preservation Group",
            "code": "",
            "auxcode": "GFN",
            "companyName": "GRIFFON",
            "country": "United Kingdom"
        },
        {
            "name": "TranscodeAircodeLink",
            "code": "",
            "auxcode": "GJB",
            "companyName": "SKY TRUCK",
            "country": "United States"
        },
        {
            "name": "Tradewind Aviation",
            "code": "",
            "auxcode": "GPD",
            "companyName": "GOODSPEED",
            "country": "United States"
        },
        {
            "name": "Trail Lake Flying Service",
            "code": "",
            "auxcode": "HBA",
            "companyName": "HARBOR AIR",
            "country": "United States"
        },
        {
            "name": "TAF Helicopters",
            "code": "",
            "auxcode": "HET",
            "companyName": "HELITAF",
            "country": "Spain"
        },
        {
            "name": "Tango Bravo",
            "code": "",
            "auxcode": "HTO",
            "companyName": "HELI TANGO",
            "country": "France"
        },
        {
            "name": "Turkish Air Force",
            "code": "",
            "auxcode": "HVK",
            "companyName": "TURKISH AIRFORCE",
            "country": "Turkey"
        },
        {
            "name": "Thryluthjonustan",
            "code": "",
            "auxcode": "IHS",
            "companyName": "",
            "country": "Iceland"
        },
        {
            "name": "TAcodeAir Airline",
            "code": "",
            "auxcode": "IRF",
            "companyName": "TAcodeAIR",
            "country": "Iran"
        },
        {
            "name": "Tara Air Line",
            "code": "",
            "auxcode": "IRR",
            "companyName": "TARAIR",
            "country": "Iran"
        },
        {
            "name": "Trading Air Cargo",
            "code": "",
            "auxcode": "JCH",
            "companyName": "TRADING CARGO",
            "country": "Mauritania"
        },
        {
            "name": "TranscodeKiev",
            "code": "",
            "auxcode": "KCA",
            "companyName": "TRANScodeKIEV",
            "country": "Ukraine"
        },
        {
            "name": "Tal Air Charters",
            "code": "",
            "auxcode": "JEL",
            "companyName": "JETEL",
            "country": "Canada"
        },
        {
            "name": "Transcontinental Airlines",
            "code": "",
            "auxcode": "KRA",
            "companyName": "REGATA",
            "country": "Kazakhstan"
        },
        {
            "name": "Transaviabaltika",
            "code": "",
            "auxcode": "KTB",
            "companyName": "TRANSBALTIKA",
            "country": "Lithuania"
        },
        {
            "name": "TransaircodeGyraintiee",
            "code": "",
            "auxcode": "KTS",
            "companyName": "KOTAIR",
            "country": "Russia"
        },
        {
            "name": "TAM Mercosur",
            "code": "PZ",
            "auxcode": "LAP",
            "companyName": "PARAGUAYA",
            "country": "Paraguay"
        },
        {
            "name": "The Lancair Company",
            "code": "",
            "auxcode": "LCC",
            "companyName": "LANCAIR",
            "country": "United States"
        },
        {
            "name": "The Army Aviation Heritage Foundation",
            "code": "",
            "auxcode": "LEG",
            "companyName": "LEGACY",
            "country": "United States"
        },
        {
            "name": "Top Sky International",
            "code": "",
            "auxcode": "LKW",
            "companyName": "TOPINTER",
            "country": "Indonesia"
        },
        {
            "name": "Trans States Airlines",
            "code": "AX",
            "auxcode": "LOF",
            "companyName": "WATERSKI",
            "country": "United States"
        },
        {
            "name": "Trans Atlantis",
            "code": "",
            "auxcode": "LTA",
            "companyName": "LANTRA",
            "country": "Canada"
        },
        {
            "name": "Transportacion Aerea Del Mar De Cortes",
            "code": "",
            "auxcode": "MCT",
            "companyName": "TRANS CORTES",
            "country": "Mexico"
        },
        {
            "name": "Transporte Aero MGM",
            "code": "",
            "auxcode": "MGM",
            "companyName": "AERO EMMcodeGEEcodeEMM",
            "country": "Mexico"
        },
        {
            "name": "Tigerfly",
            "code": "",
            "auxcode": "MOH",
            "companyName": "MOTH",
            "country": "United Kingdom"
        },
        {
            "name": "Transportes Aereos Amparo",
            "code": "",
            "auxcode": "MPO",
            "companyName": "AMPARO",
            "country": "Mexico"
        },
        {
            "name": "Trans Air",
            "code": "",
            "auxcode": "MUI",
            "companyName": "MAUI",
            "country": "United States"
        },
        {
            "name": "Transportes Aereos Mexiquenses",
            "code": "",
            "auxcode": "MXQ",
            "companyName": "MEXIQUENSES",
            "country": "Mexico"
        },
        {
            "name": "Travelsky Technology",
            "code": "1E",
            "auxcode": "",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Thalys",
            "code": "2H",
            "auxcode": "",
            "companyName": "",
            "country": "Belgium"
        },
        {
            "name": "TNT International Aviation",
            "code": "",
            "auxcode": "NTR",
            "companyName": "NITRO",
            "country": "United Kingdom"
        },
        {
            "name": "Open Skies Consultative Commission",
            "code": "1L",
            "auxcode": "OSY",
            "companyName": "OPEN SKIES",
            "country": "United States"
        },
        {
            "name": "TranscodePacific Orient Airways",
            "code": "",
            "auxcode": "PCW",
            "companyName": "PACIFIC ORIENT",
            "country": "Philippines"
        },
        {
            "name": "Tauranga Aer Club",
            "code": "",
            "auxcode": "PGS",
            "companyName": "",
            "country": "New Zealand"
        },
        {
            "name": "TSSKBcodeProgress",
            "code": "",
            "auxcode": "PSS",
            "companyName": "PROGRESS",
            "country": "Russia"
        },
        {
            "name": "Trans World Express",
            "code": "",
            "auxcode": "RBD",
            "companyName": "RED BIRD",
            "country": "United States"
        },
        {
            "name": "Trans Reco",
            "code": "",
            "auxcode": "REC",
            "companyName": "TRANScodeRECO",
            "country": "Mauritania"
        },
        {
            "name": "Tas Aviation",
            "code": "",
            "auxcode": "RMS",
            "companyName": "TASS AIR",
            "country": "United States"
        },
        {
            "name": "Tarom",
            "code": "RO",
            "auxcode": "ROT",
            "companyName": "TAROM",
            "country": "Romania"
        },
        {
            "name": "Transportes Aereos I.R. Crusoe",
            "code": "",
            "auxcode": "ROU",
            "companyName": "ROBINSON CRUSOE",
            "country": "Chile"
        },
        {
            "name": "Transportes Aereos Sierra",
            "code": "",
            "auxcode": "RRT",
            "companyName": "SIERRA ALTA",
            "country": "Mexico"
        },
        {
            "name": "Tbilisi Aviation University",
            "code": "",
            "auxcode": "RRY",
            "companyName": "AIRFERRY",
            "country": "Georgia"
        },
        {
            "name": "Trans Am Compania",
            "code": "",
            "auxcode": "RTM",
            "companyName": "AERO TRANSAM",
            "country": "Ecuador"
        },
        {
            "name": "Trans Sahara Air",
            "code": "",
            "auxcode": "SBJ",
            "companyName": "TRANS SAHARA",
            "country": "Nigeria"
        },
        {
            "name": "Transportes Aereos Sierra Madre",
            "code": "",
            "auxcode": "SEI",
            "companyName": "TRANSPORTE SIERRA",
            "country": "Mexico"
        },
        {
            "name": "Trans Asian Airlines",
            "code": "",
            "auxcode": "SRT",
            "companyName": "TRASER",
            "country": "Kazakhstan"
        },
        {
            "name": "Turan Air",
            "code": "3T",
            "auxcode": "URN",
            "companyName": "TURAN",
            "country": "Azerbaijan"
        },
        {
            "name": "TRIP Linhas A",
            "code": "8R",
            "auxcode": "TIB",
            "companyName": "TRIP",
            "country": "Brazil"
        },
        {
            "name": "Tusheti",
            "code": "",
            "auxcode": "USB",
            "companyName": "TUSHETI",
            "country": "Georgia"
        },
        {
            "name": "TAPC Aviatrans Aircompany",
            "code": "",
            "auxcode": "UTM",
            "companyName": "AVIATAPS",
            "country": "Uzbekistan"
        },
        {
            "name": "TranscodeUlgii",
            "code": "",
            "auxcode": "UTN",
            "companyName": "TRANScodeULGII",
            "country": "Mongolia"
        },
        {
            "name": "Transarabian Transportation Services",
            "code": "",
            "auxcode": "UTT",
            "companyName": "ARABIAN TRANSPORT",
            "country": "Uganda"
        },
        {
            "name": "Transaven",
            "code": "",
            "auxcode": "VEN",
            "companyName": "TRANSAVEN AIRLINE",
            "country": "Venezuela"
        },
        {
            "name": "Tag Aviation UK",
            "code": "",
            "auxcode": "VIP",
            "companyName": "SOVEREIGN",
            "country": "United Kingdom"
        },
        {
            "name": "Tbilaviamsheni",
            "code": "",
            "auxcode": "VNZ",
            "companyName": "TBILAVIA",
            "country": "Georgia"
        },
        {
            "name": "Taxi de Veracruz",
            "code": "",
            "auxcode": "VRC",
            "companyName": "VERACRUZ",
            "country": "Mexico"
        },
        {
            "name": "Travel Express Aviation Services",
            "code": "",
            "auxcode": "XAR",
            "companyName": "TRAVEL EXPRESS",
            "country": "Indonesia"
        },
        {
            "name": "Taxi Aero Del Norte",
            "code": "",
            "auxcode": "XNR",
            "companyName": "TAXI NORTE",
            "country": "Mexico"
        },
        {
            "name": "Transports et Travaux A",
            "code": "OF",
            "auxcode": "",
            "companyName": "",
            "country": ""
        },
        {
            "name": "USAF Air Mobility Operations Control Center",
            "code": "",
            "auxcode": "DOD",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "U.S. Department of the Interior",
            "code": "",
            "auxcode": "DOI",
            "companyName": "INTERIOR",
            "country": "United States"
        },
        {
            "name": "United Kingdom Civil Aviation Authority",
            "code": "",
            "auxcode": "EXM",
            "companyName": "EXAM",
            "country": "United Kingdom"
        },
        {
            "name": "Union des Transports Africains de Guinee",
            "code": "",
            "auxcode": "GIH",
            "companyName": "TRANSPORT AFRICAIN",
            "country": "Guinea"
        },
        {
            "name": "US Army Parachute Team",
            "code": "",
            "auxcode": "GKA",
            "companyName": "GOLDEN KNIGHTS",
            "country": "United States"
        },
        {
            "name": "USA3000 Airlines",
            "code": "U5",
            "auxcode": "GWY",
            "companyName": "GETAWAY",
            "country": "United States"
        },
        {
            "name": "United Arabian Airlines",
            "code": "",
            "auxcode": "UAB",
            "companyName": "UNITED ARABIAN",
            "country": "Sudan"
        },
        {
            "name": "United Airlines",
            "code": "UA",
            "auxcode": "UAL",
            "companyName": "UNITED",
            "country": "United States"
        },
        {
            "name": "United Air Charters",
            "code": "",
            "auxcode": "UAC",
            "companyName": "UNITAIR",
            "country": "Zimbabwe"
        },
        {
            "name": "United Carriers Systems",
            "code": "",
            "auxcode": "UCS",
            "companyName": "UNITED CARRIERS",
            "country": "United States"
        },
        {
            "name": "United Eagle Airlines",
            "code": "",
            "auxcode": "UEA",
            "companyName": "UNITED EAGLE",
            "country": "China"
        },
        {
            "name": "United Feeder Service",
            "code": "U2",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "United Kingdom Civil Aviation Authority",
            "code": "",
            "auxcode": "CFU",
            "companyName": "MINAIR",
            "country": "United Kingdom"
        },
        {
            "name": "United Kingdom Royal VIP Flights",
            "code": "",
            "auxcode": "KRF",
            "companyName": "KITTYHAWK",
            "country": "United Kingdom"
        },
        {
            "name": "United Kingdom Royal VIP Flight",
            "code": "",
            "auxcode": "KRH",
            "companyName": "SPARROWHAWK",
            "country": "United Kingdom"
        },
        {
            "name": "United Kingdom Civil Aviation Authority",
            "code": "",
            "auxcode": "SDS",
            "companyName": "STANDARDS",
            "country": "United Kingdom"
        },
        {
            "name": "United Kingdom Royal VIP Flights",
            "code": "",
            "auxcode": "TQF",
            "companyName": "RAINBOW",
            "country": "United Kingdom"
        },
        {
            "name": "United States Coast Guard Auxiliary",
            "code": "",
            "auxcode": "CGX",
            "companyName": "COASTGUARD AUXAIR",
            "country": "United States"
        },
        {
            "name": "United States Department Of Agriculture",
            "code": "",
            "auxcode": "AGR",
            "companyName": "AGRICULTURE",
            "country": "United States"
        },
        {
            "name": "University Air Squadron",
            "code": "",
            "auxcode": "UAD",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "University Air Squadron",
            "code": "",
            "auxcode": "UAJ",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "University Air Squadron",
            "code": "",
            "auxcode": "UAA",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "University Air Squadron",
            "code": "",
            "auxcode": "UAH",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Universal Avia",
            "code": "",
            "auxcode": "HBU",
            "companyName": "KHARKIV UNIVERSAL",
            "country": "Ukraine"
        },
        {
            "name": "UK HEMS",
            "code": "",
            "auxcode": "HLE",
            "companyName": "HELIMED",
            "country": "United Kingdom"
        },
        {
            "name": "USA Jet Airlines",
            "code": "U7",
            "auxcode": "JUS",
            "companyName": "JET USA",
            "country": "United States"
        },
        {
            "name": "Unijet",
            "code": "",
            "auxcode": "LEA",
            "companyName": "LEADAIR",
            "country": "France"
        },
        {
            "name": "US Marshals Service",
            "code": "",
            "auxcode": "MSH",
            "companyName": "MARSHALAIR",
            "country": "United States"
        },
        {
            "name": "University of North Dakota",
            "code": "",
            "auxcode": "NDU",
            "companyName": "SIOUX",
            "country": "United States"
        },
        {
            "name": "Universal Airlines",
            "code": "",
            "auxcode": "PNA",
            "companyName": "PACIFIC NORTHERN",
            "country": "United States"
        },
        {
            "name": "Uganda Royal Airways",
            "code": "",
            "auxcode": "RAU",
            "companyName": "UGANDA ROYAL",
            "country": "Uganda"
        },
        {
            "name": "United Aviation Services",
            "code": "",
            "auxcode": "SAU",
            "companyName": "UNISERVE",
            "country": "Spain"
        },
        {
            "name": "Ural Airlines",
            "code": "U6",
            "auxcode": "SVR",
            "companyName": "SVERDLOVSK AIR",
            "country": "Russia"
        },
        {
            "name": "Ukraine Transavia",
            "code": "",
            "auxcode": "TRB",
            "companyName": "KIROVTRANS",
            "country": "Ukraine"
        },
        {
            "name": "United Arab Emirates Air Force",
            "code": "",
            "auxcode": "UAF",
            "companyName": "UNIFORCE",
            "country": "United Arab Emirates"
        },
        {
            "name": "Union Africaine des Transports",
            "code": "",
            "auxcode": "UAI",
            "companyName": "UNAIR",
            "country": "Ivory Coast"
        },
        {
            "name": "Uganda Air Cargo",
            "code": "",
            "auxcode": "UCC",
            "companyName": "UGANDA CARGO",
            "country": "Uganda"
        },
        {
            "name": "US Airports Air Charter",
            "code": "",
            "auxcode": "UCH",
            "companyName": "US CHARTER",
            "country": "United States"
        },
        {
            "name": "Ucoaviacion",
            "code": "",
            "auxcode": "UCO",
            "companyName": "UCOAVIACION",
            "country": "Spain"
        },
        {
            "name": "UescodeAvia Aircompany",
            "code": "",
            "auxcode": "UES",
            "companyName": "AVIASYSTEM",
            "country": "Ukraine"
        },
        {
            "name": "UFS",
            "code": "",
            "auxcode": "UFS",
            "companyName": "FEEDER EXPRESS",
            "country": "United States"
        },
        {
            "name": "Uganda Airlines",
            "code": "",
            "auxcode": "UGA",
            "companyName": "UGANDA",
            "country": "Uganda"
        },
        {
            "name": "Urgemer Canarias",
            "code": "",
            "auxcode": "UGC",
            "companyName": "URGEMER",
            "country": "Spain"
        },
        {
            "name": "Ukrainian Helicopters",
            "code": "",
            "auxcode": "UHL",
            "companyName": "UKRAINE COPTERS",
            "country": "Ukraine"
        },
        {
            "name": "Ulyanovsk Higher Civil Aviation School",
            "code": "",
            "auxcode": "UHS",
            "companyName": "PILOT AIR",
            "country": "Russia"
        },
        {
            "name": "Universal Jet Rental de Mexico",
            "code": "",
            "auxcode": "UJR",
            "companyName": "UNIVERSAL JET",
            "country": "Mexico"
        },
        {
            "name": "Universal Jet Aviation",
            "code": "",
            "auxcode": "UJT",
            "companyName": "UNIcodeJET",
            "country": "United States"
        },
        {
            "name": "UK International Airlines",
            "code": "",
            "auxcode": "UKI",
            "companyName": "KHALIQ",
            "country": "United Kingdom"
        },
        {
            "name": "Ukraine Air Alliance",
            "code": "",
            "auxcode": "UKL",
            "companyName": "UKRAINE ALLIANCE",
            "country": "Ukraine"
        },
        {
            "name": "UM Airlines",
            "code": "UF",
            "auxcode": "UKM",
            "companyName": "UKRAINE MEDITERRANEE",
            "country": "Ukraine"
        },
        {
            "name": "Ukraine Air Enterprise",
            "code": "",
            "auxcode": "UKN",
            "companyName": "ENTERPRISE UKRAINE",
            "country": "Ukraine"
        },
        {
            "name": "United Kingdom Home Office",
            "code": "",
            "auxcode": "UKP",
            "companyName": "POLICE",
            "country": "United Kingdom"
        },
        {
            "name": "Ukrainian Cargo Airways",
            "code": "6Z",
            "auxcode": "UKS",
            "companyName": "CARGOTRANS",
            "country": "Ukraine"
        },
        {
            "name": "Ultrair",
            "code": "",
            "auxcode": "ULT",
            "companyName": "ULTRAIR",
            "country": "United States"
        },
        {
            "name": "UnitempcodeM",
            "code": "",
            "auxcode": "UMS",
            "companyName": "TOPAZ",
            "country": "Russia"
        },
        {
            "name": "UnicodeFly",
            "code": "",
            "auxcode": "UNC",
            "companyName": "UNICOPTER",
            "country": "Denmark"
        },
        {
            "name": "Union Flights",
            "code": "",
            "auxcode": "UNF",
            "companyName": "UNION FLIGHTS",
            "country": "United States"
        },
        {
            "name": "Universal Jet",
            "code": "",
            "auxcode": "UNJ",
            "companyName": "PROJET",
            "country": "Spain"
        },
        {
            "name": "Unsped Paket Servisi",
            "code": "",
            "auxcode": "UNS",
            "companyName": "UNSPED",
            "country": "Turkey"
        },
        {
            "name": "Unifly Servizi Aerei",
            "code": "",
            "auxcode": "UNU",
            "companyName": "UNIEURO",
            "country": "Italy"
        },
        {
            "name": "Ukrainian Pilot School",
            "code": "",
            "auxcode": "UPL",
            "companyName": "PILOT SCHOOL",
            "country": "Ukraine"
        },
        {
            "name": "United Parcel Service",
            "code": "5X",
            "auxcode": "UPS",
            "companyName": "UPS",
            "country": "United States"
        },
        {
            "name": "Uraiavia",
            "code": "",
            "auxcode": "URV",
            "companyName": "URAI",
            "country": "Russia"
        },
        {
            "name": "US Airways",
            "code": "US",
            "auxcode": "USA",
            "companyName": "U S AIR",
            "country": "United States"
        },
        {
            "name": "US Check Airlines",
            "code": "",
            "auxcode": "USC",
            "companyName": "STAR CHECK",
            "country": "United States"
        },
        {
            "name": "USAfrica Airways",
            "code": "",
            "auxcode": "USF",
            "companyName": "AFRICA EXPRESS",
            "country": "United States"
        },
        {
            "name": "US Helicopter",
            "code": "",
            "auxcode": "USH",
            "companyName": "UScodeHELI",
            "country": "United States"
        },
        {
            "name": "US Jet",
            "code": "",
            "auxcode": "USJ",
            "companyName": "USJET",
            "country": "United States"
        },
        {
            "name": "US Express",
            "code": "",
            "auxcode": "USX",
            "companyName": "AIR EXPRESS",
            "country": "United States"
        },
        {
            "name": "UTair Aviation",
            "code": "UT",
            "auxcode": "UTA",
            "companyName": "UTAIR",
            "country": "Russia"
        },
        {
            "name": "UTAGE",
            "code": "",
            "auxcode": "UTG",
            "companyName": "UTAGE",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Utair South Africa",
            "code": "",
            "auxcode": "UTR",
            "companyName": "AIRUT",
            "country": "South Africa"
        },
        {
            "name": "Ukrainian State Air Traffic Service Enterprise",
            "code": "",
            "auxcode": "UTS",
            "companyName": "AIRRUH",
            "country": "Ukraine"
        },
        {
            "name": "UrartucodeAir",
            "code": "",
            "auxcode": "UTU",
            "companyName": "",
            "country": "Armenia"
        },
        {
            "name": "Universal Airways",
            "code": "",
            "auxcode": "UVA",
            "companyName": "UNIVERSAL",
            "country": "United States"
        },
        {
            "name": "Universal Airlines",
            "code": "",
            "auxcode": "UVG",
            "companyName": "GUYANA JET",
            "country": "Guyana"
        },
        {
            "name": "Uvavemex",
            "code": "",
            "auxcode": "UVM",
            "companyName": "UVAVEMEX",
            "country": "Mexico"
        },
        {
            "name": "United States Air Force",
            "code": "",
            "auxcode": "AIO",
            "companyName": "AIR CHIEF",
            "country": "United States"
        },
        {
            "name": "United Aviation",
            "code": "",
            "auxcode": "UVN",
            "companyName": "UNITED AVIATION",
            "country": "Kuwait"
        },
        {
            "name": "Uzbekistan Airways",
            "code": "HY",
            "auxcode": "UZB",
            "companyName": "UZBEK",
            "country": "Uzbekistan"
        },
        {
            "name": "Ukraine International Airlines",
            "code": "PS",
            "auxcode": "AUI",
            "companyName": "UKRAINE INTERNATIONAL",
            "country": "Ukraine"
        },
        {
            "name": "Universal Airlines",
            "code": "",
            "auxcode": "WEC",
            "companyName": "AIRGO",
            "country": "United States"
        },
        {
            "name": "US Helicopter Corporation",
            "code": "UH",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "V Australia Airlines",
            "code": "VA",
            "auxcode": "VAU",
            "companyName": "KANGA",
            "country": "Australia"
        },
        {
            "name": "V Bird Airlines Netherlands",
            "code": "",
            "auxcode": "VBA",
            "companyName": "VEEBEE",
            "country": "Netherlands"
        },
        {
            "name": "Vcodeavia Airline",
            "code": "",
            "auxcode": "WIW",
            "companyName": "VEEcodeAVIA",
            "country": "Ukraine"
        },
        {
            "name": "VcodeBerdcodeAvia",
            "code": "",
            "auxcode": "VBD",
            "companyName": "VEEBIRDcodeAVIA",
            "country": "Armenia"
        },
        {
            "name": "Vacationair",
            "code": "",
            "auxcode": "VAC",
            "companyName": "VACATIONAIR",
            "country": "Canada"
        },
        {
            "name": "Valair AG (Helicoptere)",
            "code": "",
            "auxcode": "RDW",
            "companyName": "ROADWATCH",
            "country": "Switzerland"
        },
        {
            "name": "Valan International Cargo Charter",
            "code": "",
            "auxcode": "VLA",
            "companyName": "NALAU",
            "country": "South Africa"
        },
        {
            "name": "Valan Limited",
            "code": "",
            "auxcode": "VLN",
            "companyName": "VALAN",
            "country": "Moldova"
        },
        {
            "name": "ValfellcodeVerkflug",
            "code": "",
            "auxcode": "EHR",
            "companyName": "ROTOR",
            "country": "Iceland"
        },
        {
            "name": "Valuair",
            "code": "VF",
            "auxcode": "VLU",
            "companyName": "VALUAIR",
            "country": "Singapore"
        },
        {
            "name": "Van Air Europe",
            "code": "",
            "auxcode": "VAA",
            "companyName": "EUROVAN",
            "country": "Czech Republic"
        },
        {
            "name": "Vanguardia en Aviacion en Colima",
            "code": "",
            "auxcode": "VGC",
            "companyName": "VANGUARDIA COLIMA",
            "country": "Mexico"
        },
        {
            "name": "Vanguard Airlines",
            "code": "",
            "auxcode": "VGD",
            "companyName": "VANGUARD AIR",
            "country": "United States"
        },
        {
            "name": "Vasco Air",
            "code": "",
            "auxcode": "VFC",
            "companyName": "VASCO AIR",
            "country": "Vietnam"
        },
        {
            "name": "Vega",
            "code": "",
            "auxcode": "VAG",
            "companyName": "SEGA",
            "country": "Kazakhstan"
        },
        {
            "name": "Vega Air Company",
            "code": "",
            "auxcode": "WGA",
            "companyName": "WEGA FRANKO",
            "country": "Ukraine"
        },
        {
            "name": "Veles",
            "code": "",
            "auxcode": "WEL",
            "companyName": " Ukrainian Aviation Company",
            "country": "VELES"
        },
        {
            "name": "Verataxis",
            "code": "",
            "auxcode": "VTX",
            "companyName": "VERATAXIS",
            "country": "Mexico"
        },
        {
            "name": "Veritair Ltd",
            "code": "",
            "auxcode": "BTP",
            "companyName": "NET RAIL",
            "country": "United Kingdom"
        },
        {
            "name": "Voyageur Airways",
            "code": "VC",
            "auxcode": "VAL",
            "companyName": "VOYAGEUR",
            "country": "Canada"
        },
        {
            "name": "Vernicos Aviation",
            "code": "",
            "auxcode": "GRV",
            "companyName": "NIGHT RIDER",
            "country": "Greece"
        },
        {
            "name": "Vietnam Airlines",
            "code": "VN",
            "auxcode": "HVN",
            "companyName": "VIET NAM AIRLINES",
            "country": "Vietnam"
        },
        {
            "name": "Vozdushnaya Academy",
            "code": "",
            "auxcode": "KWA",
            "companyName": "VOZAIR",
            "country": "Kazakhstan"
        },
        {
            "name": "VIM Airlines",
            "code": "NN",
            "auxcode": "MOV",
            "companyName": "MOV AIR",
            "country": "Russia"
        },
        {
            "name": "VIMcodeAviaservice",
            "code": "",
            "auxcode": "MVY",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "VIA Rail Canada",
            "code": "2R",
            "auxcode": "",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Victoria Aviation",
            "code": "",
            "auxcode": "ENV",
            "companyName": "ENDEAVOUR",
            "country": "United Kingdom"
        },
        {
            "name": "Viscount Air Service",
            "code": "",
            "auxcode": "VCT",
            "companyName": "VISCOUNT AIR",
            "country": "United States"
        },
        {
            "name": "Vision Airlines",
            "code": "",
            "auxcode": "SSI",
            "companyName": "SUPER JET",
            "country": "Nigeria"
        },
        {
            "name": "VIP Air Charter",
            "code": "",
            "auxcode": "FXF",
            "companyName": "FOX FLIGHT",
            "country": "United States"
        },
        {
            "name": "VIP Avia",
            "code": "",
            "auxcode": "PAV",
            "companyName": "NICOL",
            "country": "Kazakhstan"
        },
        {
            "name": "VIP Avia",
            "code": "",
            "auxcode": "PRX",
            "companyName": "PAREX",
            "country": "Latvia"
        },
        {
            "name": "Visionair",
            "code": "",
            "auxcode": "VAT",
            "companyName": "VISIONAIR",
            "country": "Ireland"
        },
        {
            "name": "Viasa",
            "code": "VA",
            "auxcode": "",
            "companyName": "",
            "country": "Venezuela"
        },
        {
            "name": "VICA code Viacao Charter Aereos",
            "code": "",
            "auxcode": "VCA",
            "companyName": "VICA",
            "country": "Brazil"
        },
        {
            "name": "Volare Air Charter Company",
            "code": "",
            "auxcode": "VCM",
            "companyName": "CARMEN",
            "country": "United States"
        },
        {
            "name": "Volaris",
            "code": "Y4",
            "auxcode": "VOI",
            "companyName": "VOLARIS",
            "country": "Mexico"
        },
        {
            "name": "VolgacodeDnepr Airlines",
            "code": "VI",
            "auxcode": "VDA",
            "companyName": "VOLGAcodeDNEPR",
            "country": "Russia"
        },
        {
            "name": "Vega Airlines",
            "code": "",
            "auxcode": "VEA",
            "companyName": "VEGA AIRLINES",
            "country": "Bulgaria"
        },
        {
            "name": "Venescar Internacional",
            "code": "",
            "auxcode": "VEC",
            "companyName": "VECAR",
            "country": "Venezuela"
        },
        {
            "name": "Victor Echo",
            "code": "",
            "auxcode": "VEE",
            "companyName": "VICTOR ECHO",
            "country": "Spain"
        },
        {
            "name": "Virgin Express Ireland",
            "code": "",
            "auxcode": "VEI",
            "companyName": "GREEN ISLE",
            "country": "Ireland"
        },
        {
            "name": "Virgin America",
            "code": "VX",
            "auxcode": "VRD",
            "companyName": "REDWOOD",
            "country": "United States"
        },
        {
            "name": "Vieques Air Link",
            "code": "",
            "auxcode": "VES",
            "companyName": "VIEQUES",
            "country": "United States"
        },
        {
            "name": "Virgin Express",
            "code": "TV",
            "auxcode": "VEX",
            "companyName": "VIRGIN EXPRESS",
            "country": "Belgium"
        },
        {
            "name": "VZ Flights",
            "code": "",
            "auxcode": "VFT",
            "companyName": "ZETA FLIGHTS",
            "country": "Mexico"
        },
        {
            "name": "Virgin Nigeria Airways",
            "code": "VK",
            "auxcode": "VGN",
            "companyName": "VIRGIN NIGERIA",
            "country": "Nigeria"
        },
        {
            "name": "Vologda State Air Enterprise",
            "code": "",
            "auxcode": "VGV",
            "companyName": "VOLOGDA AIR",
            "country": "Russia"
        },
        {
            "name": "VHcodeAir Industrie",
            "code": "",
            "auxcode": "VHA",
            "companyName": "AIR VcodeH",
            "country": "Angola"
        },
        {
            "name": "VHM SchulcodeundcodeCharterflug",
            "code": "",
            "auxcode": "VHM",
            "companyName": "EARLY BIRD",
            "country": "Germany"
        },
        {
            "name": "Vibroair Flugservice",
            "code": "",
            "auxcode": "VIB",
            "companyName": "VITUS",
            "country": "Germany"
        },
        {
            "name": "VIP Servicios Aereos Ejecutivos",
            "code": "",
            "auxcode": "VIC",
            "companyName": "VIPcodeEJECUTIVO",
            "country": "Mexico"
        },
        {
            "name": "VIP Empresarial",
            "code": "",
            "auxcode": "VIE",
            "companyName": "VIP EMPRESARIAL",
            "country": "Mexico"
        },
        {
            "name": "VIF Luftahrt",
            "code": "",
            "auxcode": "VIF",
            "companyName": "VIENNA FLIGHT",
            "country": "Austria"
        },
        {
            "name": "Vega Aviation",
            "code": "",
            "auxcode": "VIG",
            "companyName": "VEGA AVIATION",
            "country": "Sudan"
        },
        {
            "name": "Vichi",
            "code": "",
            "auxcode": "VIH",
            "companyName": "VICHI",
            "country": "Moldova"
        },
        {
            "name": "Viking Airlines",
            "code": "",
            "auxcode": "VIK",
            "companyName": "SWEDJET",
            "country": "Sweden"
        },
        {
            "name": "Vinair code Helicoptereos",
            "code": "",
            "auxcode": "VIN",
            "companyName": "VINAIR",
            "country": "Portugal"
        },
        {
            "name": "Virgin Atlantic Airways",
            "code": "VS",
            "auxcode": "VIR",
            "companyName": "VIRGIN",
            "country": "United Kingdom"
        },
        {
            "name": "Viajes Ejecutivos Mexicanos",
            "code": "",
            "auxcode": "VJM",
            "companyName": "VIAJES MEXICANOS",
            "country": "Mexico"
        },
        {
            "name": "Vistajet",
            "code": "",
            "auxcode": "VJT",
            "companyName": "VISTA",
            "country": "Canada"
        },
        {
            "name": "Viva Macau",
            "code": "ZG",
            "auxcode": "VVM",
            "companyName": "JACKPOT",
            "country": "Macao"
        },
        {
            "name": "Volare Airlines",
            "code": "VE",
            "auxcode": "VLE",
            "companyName": "VOLA",
            "country": "Italy"
        },
        {
            "name": "Vueling Airlines",
            "code": "VY",
            "auxcode": "VLG",
            "companyName": "VUELING",
            "country": "Spain"
        },
        {
            "name": "Vladivostok Air",
            "code": "XF",
            "auxcode": "VLK",
            "companyName": "VLADAIR",
            "country": "Russia"
        },
        {
            "name": "Varig Log",
            "code": "LC",
            "auxcode": "VLO",
            "companyName": "VELOG",
            "country": "Brazil"
        },
        {
            "name": "VerticalcodeT Air Company",
            "code": "",
            "auxcode": "VLT",
            "companyName": "VERTICAL",
            "country": "Russia"
        },
        {
            "name": "Vero Monmouth Airlines",
            "code": "",
            "auxcode": "VMA",
            "companyName": "VERO MONMOUTH",
            "country": "United States"
        },
        {
            "name": "Vipport Joint Stock Company",
            "code": "",
            "auxcode": "VNK",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Viaggio Air",
            "code": "VM",
            "auxcode": "VOA",
            "companyName": "VIAGGIO",
            "country": "Bulgaria"
        },
        {
            "name": "Voyager Airlines",
            "code": "",
            "auxcode": "VOG",
            "companyName": "VOYAGER AIR",
            "country": "Bangladesh"
        },
        {
            "name": "Virgin Australia",
            "code": "VA",
            "auxcode": "VOZ",
            "companyName": "VIRGIN",
            "country": "Australia"
        },
        {
            "name": "Virgin Blue",
            "code": "DJ",
            "auxcode": "VBI",
            "companyName": "BLUEY",
            "country": "Australia"
        },
        {
            "name": "VIP Air",
            "code": "",
            "auxcode": "VPA",
            "companyName": "VIP TAXI",
            "country": "Slovakia"
        },
        {
            "name": "Veteran Air",
            "code": "",
            "auxcode": "VPB",
            "companyName": "VETERAN",
            "country": "Ukraine"
        },
        {
            "name": "VIPcodeAvia",
            "code": "",
            "auxcode": "VPV",
            "companyName": "VIP AVIA",
            "country": "Georgia"
        },
        {
            "name": "Vertair",
            "code": "",
            "auxcode": "VRA",
            "companyName": "VERITAIR",
            "country": "United Kingdom"
        },
        {
            "name": "Volare Airlines",
            "code": "",
            "auxcode": "VRE",
            "companyName": "UKRAINE VOLARE",
            "country": "Ukraine"
        },
        {
            "name": "Voar Lda",
            "code": "",
            "auxcode": "VRL",
            "companyName": "VOAR LINHAS",
            "country": "Angola"
        },
        {
            "name": "VRG Linhas Aereas",
            "code": "RG",
            "auxcode": "VRN",
            "companyName": "VARIG",
            "country": "Brazil"
        },
        {
            "name": "Vickers Limited",
            "code": "",
            "auxcode": "VSB",
            "companyName": "VICKERS",
            "country": "United Kingdom"
        },
        {
            "name": "Visig Operaciones Aereas",
            "code": "",
            "auxcode": "VSG",
            "companyName": "VISIG",
            "country": "Spain"
        },
        {
            "name": "Vision Airways Corporation",
            "code": "",
            "auxcode": "VSN",
            "companyName": "VISION",
            "country": "Canada"
        },
        {
            "name": "Voronezh Aircraft Manufacturing Society",
            "code": "",
            "auxcode": "VSO",
            "companyName": "VASO",
            "country": "Russia"
        },
        {
            "name": "VASP",
            "code": "VP",
            "auxcode": "VSP",
            "companyName": "VASP",
            "country": "Brazil"
        },
        {
            "name": "Virign Islands Seaplane Shuttle",
            "code": "",
            "auxcode": "VSS",
            "companyName": "WATERBIRD",
            "country": "United States"
        },
        {
            "name": "Vuelos Especializados Tollocan",
            "code": "",
            "auxcode": "VTC",
            "companyName": "VUELOS TOLLOCAN",
            "country": "Mexico"
        },
        {
            "name": "Vuelos Corporativos de Tehuacan",
            "code": "",
            "auxcode": "VTH",
            "companyName": "VUELOS TEHUACAN",
            "country": "Mexico"
        },
        {
            "name": "Vostok Airlines",
            "code": "",
            "auxcode": "VTK",
            "companyName": "VOSTOK",
            "country": "Russia"
        },
        {
            "name": "Victor Tagle Larrain",
            "code": "",
            "auxcode": "VTL",
            "companyName": "VITALA",
            "country": "Chile"
        },
        {
            "name": "Vointeh",
            "code": "",
            "auxcode": "VTV",
            "companyName": "VOINTEH",
            "country": "Bulgaria"
        },
        {
            "name": "Vuelos Internos Privados VIP",
            "code": "",
            "auxcode": "VUR",
            "companyName": "VIPEC",
            "country": "Ecuador"
        },
        {
            "name": "Vuela Bus",
            "code": "",
            "auxcode": "VUS",
            "companyName": "VUELA BUS",
            "country": "Mexico"
        },
        {
            "name": "Vzlyet",
            "code": "",
            "auxcode": "VZL",
            "companyName": "VZLYET",
            "country": "Russia"
        },
        {
            "name": "VLM Airlines",
            "code": "VG",
            "auxcode": "VLM",
            "companyName": "RUBENS",
            "country": "Belgium"
        },
        {
            "name": "Viking Express",
            "code": "",
            "auxcode": "WCY",
            "companyName": "TITAN AIR",
            "country": "United States"
        },
        {
            "name": "Victoria International Airways",
            "code": "",
            "auxcode": "WEV",
            "companyName": "VICTORIA UGANDA",
            "country": "Uganda"
        },
        {
            "name": "Volga Aviaexpress",
            "code": "",
            "auxcode": "WLG",
            "companyName": "GOUMRAK",
            "country": "Russia"
        },
        {
            "name": "WDL Aviation",
            "code": "",
            "auxcode": "WDL",
            "companyName": "WDL",
            "country": "Germany"
        },
        {
            "name": "WRA Inc",
            "code": "",
            "auxcode": "WRR",
            "companyName": "WRAP AIR",
            "country": "United States"
        },
        {
            "name": "WSI Corporation",
            "code": "",
            "auxcode": "XWS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Walmart Aviation",
            "code": "",
            "auxcode": "CGG",
            "companyName": "CHARGE",
            "country": "United States"
        },
        {
            "name": "Walsten Air Services",
            "code": "",
            "auxcode": "WAS",
            "companyName": "WALSTEN",
            "country": "Canada"
        },
        {
            "name": "Walter I Linkoping",
            "code": "",
            "auxcode": "GOT",
            "companyName": "GOTHIC",
            "country": "Sweden"
        },
        {
            "name": "Wapiti Aviation",
            "code": "",
            "auxcode": "WPT",
            "companyName": "WAPITI",
            "country": "Canada"
        },
        {
            "name": "Warbelow's Air Ventures",
            "code": "",
            "auxcode": "WAV",
            "companyName": "WARBELOW",
            "country": "United States"
        },
        {
            "name": "Warwickshire Aerocentre Ltd.",
            "code": "",
            "auxcode": "ATX",
            "companyName": "AIRTAX",
            "country": "United Kingdom"
        },
        {
            "name": "Wasaya Airways",
            "code": "",
            "auxcode": "WSG",
            "companyName": "WASAYA",
            "country": "Canada"
        },
        {
            "name": "Wayraper",
            "code": "7W",
            "auxcode": "",
            "companyName": "WAYRAPER",
            "country": "Peru"
        },
        {
            "name": "Weasua Air Transport Company",
            "code": "",
            "auxcode": "WTC",
            "companyName": "WATCO",
            "country": "Liberia"
        },
        {
            "name": "WebJet Linhas A",
            "code": "WJ",
            "auxcode": "WEB",
            "companyName": "WEBcodeBRASIL",
            "country": "Brazil"
        },
        {
            "name": "Welch Aviation",
            "code": "",
            "auxcode": "TDB",
            "companyName": "THUNDER BAY",
            "country": "United States"
        },
        {
            "name": "Welcome Air",
            "code": "2W",
            "auxcode": "WLC",
            "companyName": "WELCOMEAIR",
            "country": "Austria"
        },
        {
            "name": "Wermlandsflyg AB",
            "code": "",
            "auxcode": "BLW",
            "companyName": "BLUESTAR",
            "country": "Sweden"
        },
        {
            "name": "West Africa Airlines",
            "code": "",
            "auxcode": "WCB",
            "companyName": "KILO YANKEE",
            "country": "Ghana"
        },
        {
            "name": "West African Air Transport",
            "code": "",
            "auxcode": "WTF",
            "companyName": "WESTAF AIRTRANS",
            "country": "Senegal"
        },
        {
            "name": "West African Airlines",
            "code": "",
            "auxcode": "WSF",
            "companyName": "",
            "country": "Benin"
        },
        {
            "name": "West African Cargo Airlines",
            "code": "",
            "auxcode": "WAC",
            "companyName": "WESTAF CARGO",
            "country": "Mauritania"
        },
        {
            "name": "West Air Luxembourg",
            "code": "",
            "auxcode": "WLX",
            "companyName": "WEST LUX",
            "country": "Luxembourg"
        },
        {
            "name": "West Air Sweden",
            "code": "PT",
            "auxcode": "SWN",
            "companyName": "AIR SWEDEN",
            "country": "Sweden"
        },
        {
            "name": "West Caribbean Airways",
            "code": "",
            "auxcode": "WCW",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "West Caribbean Costa Rica",
            "code": "",
            "auxcode": "WCR",
            "companyName": "WEST CARIBBEAN",
            "country": "Costa Rica"
        },
        {
            "name": "West Coast Air",
            "code": "8O",
            "auxcode": "",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "West Coast Airlines",
            "code": "",
            "auxcode": "WCG",
            "companyName": "WHISKY INDIA",
            "country": "Ghana"
        },
        {
            "name": "West Coast Airways",
            "code": "",
            "auxcode": "WCA",
            "companyName": "WESTcodeLEONE",
            "country": "Sierra Leone"
        },
        {
            "name": "West Freugh DTEO",
            "code": "",
            "auxcode": "TEE",
            "companyName": "TEEBIRD",
            "country": "United Kingdom"
        },
        {
            "name": "West Wind Aviation",
            "code": "",
            "auxcode": "WEW",
            "companyName": "WESTWIND",
            "country": "Canada"
        },
        {
            "name": "WestJet",
            "code": "WS",
            "auxcode": "WJA",
            "companyName": "WESTJET",
            "country": "Canada"
        },
        {
            "name": "Westair Aviation",
            "code": "",
            "auxcode": "WAA",
            "companyName": "WESTAIR WINGS",
            "country": "Namibia"
        },
        {
            "name": "Westair Cargo Airlines",
            "code": "",
            "auxcode": "WSC",
            "companyName": "WESTCAR",
            "country": "Cote d'Ivoire"
        },
        {
            "name": "Westair Industries",
            "code": "",
            "auxcode": "PCM",
            "companyName": "PAC VALLEY",
            "country": "United States"
        },
        {
            "name": "Westcoast Energy",
            "code": "",
            "auxcode": "BLK",
            "companyName": "BLUE FLAME",
            "country": "Canada"
        },
        {
            "name": "Western Air",
            "code": "",
            "auxcode": "WST",
            "companyName": "WESTERN BAHAMAS",
            "country": "Bahamas"
        },
        {
            "name": "Western Air Couriers",
            "code": "",
            "auxcode": "NPC",
            "companyName": "NORPAC",
            "country": "United States"
        },
        {
            "name": "Western Air Express",
            "code": "",
            "auxcode": "WAE",
            "companyName": "WESTERN EXPRESS",
            "country": "United States"
        },
        {
            "name": "Western Airlines",
            "code": "WA",
            "auxcode": "WAL",
            "companyName": "WESTERN",
            "country": "United States"
        },
        {
            "name": "Western Arctic Air",
            "code": "",
            "auxcode": "WAL",
            "companyName": "WESTERN ARCTIC",
            "country": "Canada"
        },
        {
            "name": "Western Aviators",
            "code": "",
            "auxcode": "WTV",
            "companyName": "WESTAVIA",
            "country": "United States"
        },
        {
            "name": "Western Express Air Lines",
            "code": "",
            "auxcode": "WES",
            "companyName": "WEST EX",
            "country": "Canada"
        },
        {
            "name": "Western Pacific Airlines",
            "code": "",
            "auxcode": "KMR",
            "companyName": "KOMSTAR",
            "country": "United States"
        },
        {
            "name": "Western Pacific Airservice",
            "code": "",
            "auxcode": "WPA",
            "companyName": "WESTPAC",
            "country": "Solomon Islands"
        },
        {
            "name": "Westflight Aviation",
            "code": "",
            "auxcode": "WSL",
            "companyName": "WEST LINE",
            "country": "United Kingdom"
        },
        {
            "name": "Westgates Airlines",
            "code": "",
            "auxcode": "WSA",
            "companyName": "WESTATES",
            "country": "United States"
        },
        {
            "name": "Westland Helicopters",
            "code": "",
            "auxcode": "WHE",
            "companyName": "WESTLAND",
            "country": "United Kingdom"
        },
        {
            "name": "Westpoint Air",
            "code": "",
            "auxcode": "WTP",
            "companyName": "WESTPOINT",
            "country": "Canada"
        },
        {
            "name": "Westward Airways",
            "code": "CN",
            "auxcode": "WWD",
            "companyName": "WESTWARD",
            "country": "United States"
        },
        {
            "name": "White",
            "code": "",
            "auxcode": "WHT",
            "companyName": "YOUNG SKY",
            "country": "Portugal"
        },
        {
            "name": "White Eagle Aviation",
            "code": "",
            "auxcode": "WEA",
            "companyName": "WHITE EAGLE",
            "country": "Poland"
        },
        {
            "name": "White River Air Services",
            "code": "",
            "auxcode": "WRA",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Whyalla Airlines",
            "code": "",
            "auxcode": "WWL",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Widerøe",
            "code": "WF",
            "auxcode": "WIF",
            "companyName": "WIDEROE",
            "country": "Norway"
        },
        {
            "name": "Wiggins Airways",
            "code": "",
            "auxcode": "WIG",
            "companyName": "WIGGINS AIRWAYS",
            "country": "United States"
        },
        {
            "name": "Wiking Helikopter Service",
            "code": "",
            "auxcode": "WHS",
            "companyName": "WEEKING",
            "country": "Germany"
        },
        {
            "name": "Wilbur's Flight Operations",
            "code": "",
            "auxcode": "WFO",
            "companyName": "WILBURS",
            "country": "United States"
        },
        {
            "name": "Williams Air",
            "code": "",
            "auxcode": "WLS",
            "companyName": "WILLIAMS AIR",
            "country": "United States"
        },
        {
            "name": "Williams Grand Prix Engineering",
            "code": "",
            "auxcode": "WGP",
            "companyName": "GRAND PRIX",
            "country": "United Kingdom"
        },
        {
            "name": "Wimbi Dira Airways",
            "code": "",
            "auxcode": "WDA",
            "companyName": "WIMBI DIRA",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Winair",
            "code": "",
            "auxcode": "WNA",
            "companyName": "WINAIR",
            "country": "United States"
        },
        {
            "name": "Wind Jet",
            "code": "IV",
            "auxcode": "JET",
            "companyName": "GHIBLI",
            "country": "Italy"
        },
        {
            "name": "Wind Spirit Air",
            "code": "",
            "auxcode": "WSI",
            "companyName": "WIND SPIRIT",
            "country": "United States"
        },
        {
            "name": "Windrose Air",
            "code": "",
            "auxcode": "QGA",
            "companyName": "QUADRIGA",
            "country": "Germany"
        },
        {
            "name": "Windward Islands Airways International",
            "code": "",
            "auxcode": "WIA",
            "companyName": "WINDWARD",
            "country": "Netherland"
        },
        {
            "name": "Wings Air",
            "code": "IW",
            "auxcode": "WON",
            "companyName": "WINGS ABADI",
            "country": "Indonesia"
        },
        {
            "name": "Wings Air Transport",
            "code": "",
            "auxcode": "WAT",
            "companyName": "",
            "country": "Sudan"
        },
        {
            "name": "Wings Airways",
            "code": "",
            "auxcode": "WAW",
            "companyName": "WING SHUTTLE",
            "country": "United States"
        },
        {
            "name": "Wings Aviation",
            "code": "",
            "auxcode": "WOL",
            "companyName": "WINGJET",
            "country": "Guyana"
        },
        {
            "name": "Wings Express",
            "code": "",
            "auxcode": "WEX",
            "companyName": "WINGS EXPRESS",
            "country": "United States"
        },
        {
            "name": "Wings of Alaska",
            "code": "K5",
            "auxcode": "WAK",
            "companyName": "WINGS ALASKA",
            "country": "United States"
        },
        {
            "name": "Wings of Lebanon Aviation",
            "code": "",
            "auxcode": "WLB",
            "companyName": "WING LEBANON",
            "country": "Lebanon"
        },
        {
            "name": "Winlink",
            "code": "",
            "auxcode": "WIN",
            "companyName": "WINLINK",
            "country": "Saint Lucia"
        },
        {
            "name": "Wisconsin Air National Guard",
            "code": "",
            "auxcode": "WAG",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Wisman Aviation",
            "code": "",
            "auxcode": "WSM",
            "companyName": "WISMAN",
            "country": "United States"
        },
        {
            "name": "Wizz Air",
            "code": "W6",
            "auxcode": "WZZ",
            "companyName": "WIZZ AIR",
            "country": "Hungary"
        },
        {
            "name": "Wizz Air Hungary",
            "code": "8Z",
            "auxcode": "WVL",
            "companyName": "WIZZBUL",
            "country": "Bulgaria"
        },
        {
            "name": "Wondair on Demand Aviation",
            "code": "",
            "auxcode": "WNR",
            "companyName": "WONDAIR",
            "country": "Spain"
        },
        {
            "name": "Woodgate Executive Air Charter",
            "code": "",
            "auxcode": "CWY",
            "companyName": "CAUSEWAY",
            "country": "United Kingdom"
        },
        {
            "name": "World Airways",
            "code": "WO",
            "auxcode": "WOA",
            "companyName": "WORLD",
            "country": "United States"
        },
        {
            "name": "World Weatherwatch",
            "code": "",
            "auxcode": "XWW",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "World Wing Aviation",
            "code": "",
            "auxcode": "WWM",
            "companyName": "MANAS WING",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Worldspan",
            "code": "1P",
            "auxcode": "",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Worldwide Air Charter Systems",
            "code": "",
            "auxcode": "CSW",
            "companyName": "CHARTER SYSTEMS",
            "country": "Canada"
        },
        {
            "name": "Worldwide Aviation Services",
            "code": "",
            "auxcode": "WWS",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Worldwide Jet Charter",
            "code": "",
            "auxcode": "WWI",
            "companyName": "WORLDWIDE",
            "country": "United States"
        },
        {
            "name": "Wright Air Lines",
            "code": "",
            "auxcode": "WRT",
            "companyName": "WRIGHTcodeAIR",
            "country": "United States"
        },
        {
            "name": "Wright Air Service",
            "code": "8V",
            "auxcode": "WRF",
            "companyName": "WRIGHT FLYER",
            "country": "United States"
        },
        {
            "name": "Wuhan Airlines",
            "code": "",
            "auxcode": "CWU",
            "companyName": "WUHAN AIR",
            "country": "China"
        },
        {
            "name": "Wycombe Air Centre",
            "code": "",
            "auxcode": "WYC",
            "companyName": "WYCOMBE",
            "country": "United Kingdom"
        },
        {
            "name": "Wyoming Airlines",
            "code": "",
            "auxcode": "WYG",
            "companyName": "WYOMING",
            "country": "United States"
        },
        {
            "name": "XL Airways France",
            "code": "SE",
            "auxcode": "SEU",
            "companyName": "STARWAY",
            "country": "France"
        },
        {
            "name": "XP Internation",
            "code": "",
            "auxcode": "XPS",
            "companyName": "XP PARCEL",
            "country": "Netherlands"
        },
        {
            "name": "Xabre Aerolineas",
            "code": "",
            "auxcode": "XAB",
            "companyName": "AERO XABRE",
            "country": "Mexico"
        },
        {
            "name": "Xclusive Jet Charter Limited",
            "code": "",
            "auxcode": "XJC",
            "companyName": "EXCLUSIVE JET",
            "country": "United Kingdom"
        },
        {
            "name": "Xerox Corporation",
            "code": "",
            "auxcode": "XER",
            "companyName": "XEROX",
            "country": "United States"
        },
        {
            "name": "Xiamen Airlines",
            "code": "MF",
            "auxcode": "CXA",
            "companyName": "XIAMEN AIR",
            "country": "China"
        },
        {
            "name": "Xinjiang Airlines",
            "code": "",
            "auxcode": "CXJ",
            "companyName": "XINJIANG",
            "country": "China"
        },
        {
            "name": "Xjet Limited",
            "code": "",
            "auxcode": "XJT",
            "companyName": "XRAY",
            "country": "United Kingdom"
        },
        {
            "name": "Xtra Airways",
            "code": "XP",
            "auxcode": "CXP",
            "companyName": "RUBY MOUNTAIN",
            "country": "United States"
        },
        {
            "name": "YakcodeService",
            "code": "",
            "auxcode": "AKY",
            "companyName": "YAKcodeSERVICE",
            "country": "Russia"
        },
        {
            "name": "Yakolev",
            "code": "",
            "auxcode": "YAK",
            "companyName": "YAK AVIA",
            "country": "Russia"
        },
        {
            "name": "Yamal Airlines",
            "code": "YL",
            "auxcode": "LLM",
            "companyName": "YAMAL",
            "country": "Russia"
        },
        {
            "name": "Yana Airlines",
            "code": "",
            "auxcode": "CYG",
            "companyName": "VICAIR",
            "country": "Cambodia"
        },
        {
            "name": "Yangtze River Express",
            "code": "Y8",
            "auxcode": "YZR",
            "companyName": "YANGTZE RIVER",
            "country": "China"
        },
        {
            "name": "Yellow Wings Air Services",
            "code": "",
            "auxcode": "ELW",
            "companyName": "YELLOW WINGS",
            "country": "Kenya"
        },
        {
            "name": "Yemenia",
            "code": "IY",
            "auxcode": "IYE",
            "companyName": "YEMENI",
            "country": "Yemen"
        },
        {
            "name": "YerevancodeAvia",
            "code": "",
            "auxcode": "ERV",
            "companyName": "YEREVANcodeAVIA",
            "country": "Armenia"
        },
        {
            "name": "Young Flying Service",
            "code": "",
            "auxcode": "YFS",
            "companyName": "YOUNG AIR",
            "country": "United States"
        },
        {
            "name": "Yuhi Air Lines",
            "code": "",
            "auxcode": "AYU",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Yuzhnaya Aircompany",
            "code": "",
            "auxcode": "UGN",
            "companyName": "PLUTON",
            "country": "Kazakhstan"
        },
        {
            "name": "Yuzhmashavia",
            "code": "",
            "auxcode": "UMK",
            "companyName": "YUZMASH",
            "country": "Ukraine"
        },
        {
            "name": "ZcodeAvia",
            "code": "",
            "auxcode": "RZV",
            "companyName": "ZEDAVIA",
            "country": "Armenia"
        },
        {
            "name": "Zagros Airlines",
            "code": "",
            "auxcode": "IZG",
            "companyName": "ZAGROS",
            "country": "Iran"
        },
        {
            "name": "Zaire Aero Service",
            "code": "",
            "auxcode": "ZAI",
            "companyName": "ZASAIR",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Zairean Airlines",
            "code": "",
            "auxcode": "ZAR",
            "companyName": "ZAIREAN",
            "country": "Democratic Republic of Congo"
        },
        {
            "name": "Zambian Airways",
            "code": "Q3",
            "auxcode": "MBN",
            "companyName": "ZAMBIANA",
            "country": "Zambia"
        },
        {
            "name": "Zambezi Airlines",
            "code": "",
            "auxcode": "ZMA",
            "companyName": "ZAMBEZI WINGS",
            "country": "Zambia"
        },
        {
            "name": "Zanair",
            "code": "",
            "auxcode": "TAN",
            "companyName": "ZANAIR",
            "country": "Tanzania"
        },
        {
            "name": "Zantop International Airlines",
            "code": "",
            "auxcode": "ZAN",
            "companyName": "ZANTOP",
            "country": "United States"
        },
        {
            "name": "ZAS Airlines of Egypt",
            "code": "",
            "auxcode": "ZAS",
            "companyName": "ZAS AIRLINES",
            "country": "Egypt"
        },
        {
            "name": "Zenith Air",
            "code": "",
            "auxcode": "AZR",
            "companyName": "ZENAIR",
            "country": "South Africa"
        },
        {
            "name": "Zenmour Airlines",
            "code": "",
            "auxcode": "EMR",
            "companyName": "ZENMOUR",
            "country": "Mauritania"
        },
        {
            "name": "Zephyr Express",
            "code": "",
            "auxcode": "RZR",
            "companyName": "RECOVERY",
            "country": "United States"
        },
        {
            "name": "Zhejiang Airlines",
            "code": "",
            "auxcode": "CJG",
            "companyName": "ZHEJIANG",
            "country": "China"
        },
        {
            "name": "Zhetysu",
            "code": "",
            "auxcode": "JTU",
            "companyName": "ZHETYSU",
            "country": "Kazakhstan"
        },
        {
            "name": "Zhez Air",
            "code": "",
            "auxcode": "KZH",
            "companyName": "",
            "country": "Kazakhstan"
        },
        {
            "name": "Zhongfei General Aviation",
            "code": "",
            "auxcode": "CFZ",
            "companyName": "ZHONGFEI",
            "country": "China"
        },
        {
            "name": "Zhongyuan Aviation",
            "code": "",
            "auxcode": "CYN",
            "companyName": "ZHONGYUAN",
            "country": "China"
        },
        {
            "name": "Zip",
            "code": "3J",
            "auxcode": "WZP",
            "companyName": "ZIPPER",
            "country": "Canada"
        },
        {
            "name": "Zimex Aviation",
            "code": "C4",
            "auxcode": "IMX",
            "companyName": "ZIMEX",
            "country": "Switzerland"
        },
        {
            "name": "Zoom Airlines",
            "code": "Z4",
            "auxcode": "OOM",
            "companyName": "ZOOM",
            "country": "Canada"
        },
        {
            "name": "Zoom Airways",
            "code": "",
            "auxcode": "ZAW",
            "companyName": "ZED AIR",
            "country": "Bangladesh"
        },
        {
            "name": "Zorex",
            "code": "",
            "auxcode": "ORZ",
            "companyName": "ZOREX",
            "country": "Spain"
        },
        {
            "name": "Zracno Pristaniste Mali Losinj",
            "code": "",
            "auxcode": "MLU",
            "companyName": "MALI LOSINJ",
            "country": "Croatia"
        },
        {
            "name": "Tyrolean Airways",
            "code": null,
            "auxcode": "TYR",
            "companyName": "TYROLEAN",
            "country": null
        },
        {
            "name": "buzz",
            "code": "UK",
            "auxcode": "BUZ",
            "companyName": null,
            "country": null
        },
        {
            "name": "Maldivian Air Taxi",
            "code": "8Q",
            "auxcode": null,
            "companyName": null,
            "country": "Maldives"
        },
        {
            "name": "Sky Express",
            "code": "XW",
            "auxcode": "SXR",
            "companyName": "SKYSTORM",
            "country": "Russia"
        },
        {
            "name": "Yellow Air Taxi",
            "code": "Y0",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Royal Air Cambodge",
            "code": "VJ",
            "auxcode": "RAC",
            "companyName": "",
            "country": "Cambodia"
        },
        {
            "name": "Air Mandalay",
            "code": "6T",
            "auxcode": null,
            "companyName": "Six Tango",
            "country": "Burma"
        },
        {
            "name": "TEA Switzerland",
            "code": "",
            "auxcode": "4SW",
            "companyName": "",
            "country": "Switzerland"
        },
        {
            "name": "TANcodeSAHSA",
            "code": "SH",
            "auxcode": null,
            "companyName": "Sierra Hotel",
            "country": "Honduras"
        },
        {
            "name": "Air Busan",
            "code": "BX",
            "auxcode": "ABL",
            "companyName": "Air Busan",
            "country": "Republic of Korea"
        },
        {
            "name": "TUI Airlines Belgium",
            "code": "TB",
            "auxcode": "TUB",
            "companyName": "BEAUTY",
            "country": "Belgium"
        },
        {
            "name": "Arctic Air",
            "code": "",
            "auxcode": "AKR",
            "companyName": "Arctic Norway",
            "country": "Norway"
        },
        {
            "name": "Braathens",
            "code": "BU",
            "auxcode": "BRA",
            "companyName": "Braathens",
            "country": "Norway"
        },
        {
            "name": "Globus",
            "code": "GH",
            "auxcode": "GLP",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Kazakhstan",
            "code": "9Y",
            "auxcode": "KZK",
            "companyName": "Kazakh",
            "country": "Kazakhstan"
        },
        {
            "name": "Japan Air System",
            "code": "JD",
            "auxcode": "JAS",
            "companyName": "Air System",
            "country": "Japan"
        },
        {
            "name": "Annsett New Zealand (NZA)",
            "code": "ZQ",
            "auxcode": null,
            "companyName": "",
            "country": "New Zealand"
        },
        {
            "name": "EasyJet (DS)",
            "code": "DS",
            "auxcode": null,
            "companyName": "",
            "country": "Switzerland"
        },
        {
            "name": "Rheintalflug",
            "code": "",
            "auxcode": "RTL",
            "companyName": "Rheintal",
            "country": "Austria"
        },
        {
            "name": "DancodeAir London",
            "code": "",
            "auxcode": "DAN",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Fred. Olsen",
            "code": "",
            "auxcode": "FOF",
            "companyName": "",
            "country": "Norway"
        },
        {
            "name": "Star Peru (2I)",
            "code": "2I",
            "auxcode": null,
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "Robin Hood Aviation",
            "code": "",
            "auxcode": "RHA",
            "companyName": "Sherwood",
            "country": "Austria"
        },
        {
            "name": "Carnival Air Lines",
            "code": "KW",
            "auxcode": null,
            "companyName": "Carnival Air",
            "country": "United States"
        },
        {
            "name": "United Airways",
            "code": "4H",
            "auxcode": "UBD",
            "companyName": "UNITED BANGLADESH",
            "country": "Bangladesh"
        },
        {
            "name": "Inter European Airways",
            "code": "",
            "auxcode": "IEA",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Trans Maldivian Airways",
            "code": "M8",
            "auxcode": "TMW",
            "companyName": "",
            "country": "Maldives"
        },
        {
            "name": "Fly540",
            "code": "5H",
            "auxcode": "FFV",
            "companyName": "SWIFT TANGO",
            "country": "Kenya"
        },
        {
            "name": "Transavia France",
            "code": "TO",
            "auxcode": "TVF",
            "companyName": "FRENCH SUN",
            "country": "France"
        },
        {
            "name": "Island Air (WP)",
            "code": "WP",
            "auxcode": "MKU",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "1code2codego",
            "code": "OG",
            "auxcode": null,
            "companyName": "",
            "country": "Thailand"
        },
        {
            "name": "Uni Air",
            "code": "B7",
            "auxcode": "UIA",
            "companyName": "Glory",
            "country": "Taiwan"
        },
        {
            "name": "Gomelavia",
            "code": "YD",
            "auxcode": null,
            "companyName": "",
            "country": "Belarus"
        },
        {
            "name": "NordWind Airlines",
            "code": "",
            "auxcode": "NWS",
            "companyName": "NORDLAND",
            "country": "Russia"
        },
        {
            "name": "Red Wings",
            "code": "WZ",
            "auxcode": "RWZ",
            "companyName": "AIR RED",
            "country": "Russia"
        },
        {
            "name": "Hellenic Star Airways",
            "code": "",
            "auxcode": "HST",
            "companyName": "Hellenic Star",
            "country": "Greece"
        },
        {
            "name": "BAE Systems Flight Training",
            "code": "",
            "auxcode": "BAZ",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "TUIfly (X3)",
            "code": "11",
            "auxcode": null,
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Felix Airways",
            "code": "FU",
            "auxcode": "FXX",
            "companyName": "",
            "country": "Yemen"
        },
        {
            "name": "Kostromskie avialinii",
            "code": "K1",
            "auxcode": "KOQ",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Greenfly",
            "code": "XX",
            "auxcode": "GFY",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Tajik Air",
            "code": "7J",
            "auxcode": null,
            "companyName": "",
            "country": "Tajikistan"
        },
        {
            "name": "Air Mozambique",
            "code": "TM",
            "auxcode": null,
            "companyName": "",
            "country": "Mozambique"
        },
        {
            "name": "ELK Airways",
            "code": "codecode",
            "auxcode": "ELK",
            "companyName": "",
            "country": "Estonia"
        },
        {
            "name": "Gabon Airlines",
            "code": "GY",
            "auxcode": "GBK",
            "companyName": "GABON AIRLINES",
            "country": "Gabon"
        },
        {
            "name": "MCA Airlines",
            "code": "",
            "auxcode": "MCA",
            "companyName": "CALSON",
            "country": "Sweden"
        },
        {
            "name": "Maldivo Airlines",
            "code": "ML",
            "auxcode": "MAV",
            "companyName": "Maldivo",
            "country": "Maldives"
        },
        {
            "name": "Virgin Pacific",
            "code": "VH",
            "auxcode": "VNP",
            "companyName": "",
            "country": "Fiji"
        },
        {
            "name": "Zest Air",
            "code": "Z2",
            "auxcode": null,
            "companyName": "",
            "country": "Philippines"
        },
        {
            "name": "Yangon Airways",
            "code": "HK",
            "auxcode": null,
            "companyName": "Hotel Kilo",
            "country": "Burma"
        },
        {
            "name": "Transport Aérien Transrégional",
            "code": "IJ",
            "auxcode": null,
            "companyName": "",
            "country": "France"
        },
        {
            "name": "LÍO Flugmennt",
            "code": "",
            "auxcode": "LIO",
            "companyName": "",
            "country": "Iceland"
        },
        {
            "name": "Minerva Airlines",
            "code": "N4",
            "auxcode": null,
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Eastar Jet",
            "code": "ZE",
            "auxcode": "ESR",
            "companyName": "Eastar",
            "country": "South Korea"
        },
        {
            "name": "Jin Air",
            "code": "LJ",
            "auxcode": "JNA",
            "companyName": "Jin Air",
            "country": "South Korea"
        },
        {
            "name": "Wataniya Airways",
            "code": "",
            "auxcode": "KW1",
            "companyName": "",
            "country": "Kuwait"
        },
        {
            "name": "Aéris (Priv)",
            "code": "SH",
            "auxcode": null,
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Air Arabia Maroc",
            "code": "3O",
            "auxcode": null,
            "companyName": "Air Arabia",
            "country": "Morocco"
        },
        {
            "name": "Baltic Air lines",
            "code": "B1",
            "auxcode": "BA1",
            "companyName": "Baltic",
            "country": "Latvia"
        },
        {
            "name": "Ciel Canadien",
            "code": "YC",
            "auxcode": "YCC",
            "companyName": "Ciel",
            "country": "Canada"
        },
        {
            "name": "Canadian National Airways",
            "code": "CN",
            "auxcode": "YCP",
            "companyName": "CaNational",
            "country": "Canada"
        },
        {
            "name": "Epic Holiday",
            "code": "FA",
            "auxcode": "4AA",
            "companyName": "Epic",
            "country": "United States"
        },
        {
            "name": "Indochina Airlines",
            "code": "",
            "auxcode": "AXC",
            "companyName": "Airspup",
            "country": "Vietnam"
        },
        {
            "name": "JetWind",
            "code": "",
            "auxcode": "JWW",
            "companyName": "Jetwind",
            "country": "United States"
        },
        {
            "name": "Air Comet Chile",
            "code": "3I",
            "auxcode": null,
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "Nazca",
            "code": "code.",
            "auxcode": null,
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "German Air Force code FLB",
            "code": "",
            "auxcode": "FLB",
            "companyName": "FLB",
            "country": "Germany"
        },
        {
            "name": "CitycodeAir Germany",
            "code": "",
            "auxcode": "CIP",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Voronezhskie Airlanes",
            "code": "DN",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Line Blue",
            "code": "L8",
            "auxcode": "LBL",
            "companyName": "Bluebird",
            "country": "Germany"
        },
        {
            "name": "FlyLAL Charters",
            "code": "",
            "auxcode": "LLC",
            "companyName": "",
            "country": "Lithuania"
        },
        {
            "name": "Blue Sky America",
            "code": "BU",
            "auxcode": "BKY",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Texas Spirit",
            "code": "XS",
            "auxcode": "TXP",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Illinois Airways",
            "code": "IL",
            "auxcode": "ILW",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Salzburg arrows",
            "code": "SZ",
            "auxcode": null,
            "companyName": "SZA",
            "country": "Austria"
        },
        {
            "name": "Texas Wings",
            "code": "TQ",
            "auxcode": "TXW",
            "companyName": "TXW",
            "country": "United States"
        },
        {
            "name": "California Western",
            "code": "KT",
            "auxcode": "CWS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Dennis Sky",
            "code": "DH",
            "auxcode": "DSY",
            "companyName": "DSY",
            "country": "Israel"
        },
        {
            "name": "Zz",
            "code": "ZZ",
            "auxcode": null,
            "companyName": "",
            "country": "Belgium"
        },
        {
            "name": "Atifly",
            "code": "A1",
            "auxcode": "A1F",
            "companyName": "atifly",
            "country": "United States"
        },
        {
            "name": "Air UK",
            "code": "UK",
            "auxcode": null,
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Suckling Airways",
            "code": "CB",
            "auxcode": null,
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Reno Sky",
            "code": "RY",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Aerolineas heredas santa maria",
            "code": "",
            "auxcode": "SZB",
            "companyName": "",
            "country": "Dominican Republic"
        },
        {
            "name": "Ciao Air",
            "code": "99",
            "auxcode": null,
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Jc royal.britannica",
            "code": "",
            "auxcode": "JRB",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Birmingham European",
            "code": "VB",
            "auxcode": null,
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Pal airlines",
            "code": "5P",
            "auxcode": null,
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "CanXpress",
            "code": "C1",
            "auxcode": "CA1",
            "companyName": "CAX",
            "country": "Canada"
        },
        {
            "name": "Danube Wings (V5)",
            "code": "V5",
            "auxcode": null,
            "companyName": "",
            "country": "Slovakia"
        },
        {
            "name": "Sharp Airlines",
            "code": "SH",
            "auxcode": "SHA",
            "companyName": "SHARP",
            "country": "Australia"
        },
        {
            "name": "CanXplorer",
            "code": "C2",
            "auxcode": "CAP",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Click (Mexicana)",
            "code": "QA",
            "auxcode": null,
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "World Experience Airline",
            "code": "W1",
            "auxcode": "WE1",
            "companyName": "WEA",
            "country": "Canada"
        },
        {
            "name": "ALAK",
            "code": "J4",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "AJT Air International",
            "code": "E9",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Choice One",
            "code": "3E",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Tianjin Airlines",
            "code": "",
            "auxcode": "GCR",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "China United",
            "code": "KN",
            "auxcode": null,
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Locair",
            "code": "ZQ",
            "auxcode": "LOC",
            "companyName": "LOCAIR",
            "country": "United States"
        },
        {
            "name": "Safi Airlines",
            "code": "4Q",
            "auxcode": null,
            "companyName": "",
            "country": "Afghanistan"
        },
        {
            "name": "SeaPort Airlines",
            "code": "K5",
            "auxcode": "SQH",
            "companyName": "SASQUATCH",
            "country": "United States"
        },
        {
            "name": "Salmon Air",
            "code": "S6",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Fly Illi",
            "code": "IL",
            "auxcode": "ILY",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Bobb Air Freight",
            "code": "1",
            "auxcode": null,
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Star1 Airlines",
            "code": "V9",
            "auxcode": "HCW",
            "companyName": "",
            "country": "Lithuania"
        },
        {
            "name": "Pelita",
            "code": "6D",
            "auxcode": null,
            "companyName": "",
            "country": "Indonesia"
        },
        {
            "name": "Alpi Eagles (E8)",
            "code": "E8",
            "auxcode": null,
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Alaska Seaplane Service",
            "code": "J5",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "TAN",
            "code": "T8",
            "auxcode": null,
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Enerjet",
            "code": "",
            "auxcode": "ENJ",
            "companyName": "ENERJET AIR",
            "country": "Canada"
        },
        {
            "name": "MexicanaLink",
            "code": "I6",
            "auxcode": "MXI",
            "companyName": "LINK",
            "country": "Mexico"
        },
        {
            "name": "Island Spirit",
            "code": "IP",
            "auxcode": "ISX",
            "companyName": "",
            "country": "Iceland"
        },
        {
            "name": "TACA Peru",
            "code": "T0",
            "auxcode": null,
            "companyName": "TACA PERU",
            "country": "Peru"
        },
        {
            "name": "Orbest",
            "code": "",
            "auxcode": "OBS",
            "companyName": "ORBEST",
            "country": "Portugal"
        },
        {
            "name": "Southern Air Charter",
            "code": "",
            "auxcode": "SOA",
            "companyName": "",
            "country": "Bahamas"
        },
        {
            "name": "SVG Air",
            "code": "",
            "auxcode": "SVG",
            "companyName": "Grenadines",
            "country": "Saint Vincent and the Grenadines"
        },
        {
            "name": "Air Century",
            "code": "",
            "auxcode": "CEY",
            "companyName": "",
            "country": "Dominican Republic"
        },
        {
            "name": "Pan Am World Airways Dominicana",
            "code": "7Q",
            "auxcode": null,
            "companyName": "PAWA",
            "country": "Dominican Republic"
        },
        {
            "name": "Primera Air",
            "code": "PF",
            "auxcode": null,
            "companyName": "PRIMERA",
            "country": "Iceland"
        },
        {
            "name": "Air Antilles Express",
            "code": "3S",
            "auxcode": null,
            "companyName": "GREEN BIRD",
            "country": "Guadeloupe"
        },
        {
            "name": "Sol Lineas Aereas",
            "code": "",
            "auxcode": "OLS",
            "companyName": "FLIGHT SOL",
            "country": "Argentina"
        },
        {
            "name": "Regional Paraguaya",
            "code": "P7",
            "auxcode": "REP",
            "companyName": "REGIOPAR",
            "country": "Paraguay"
        },
        {
            "name": "VIP Ecuador",
            "code": "V6",
            "auxcode": null,
            "companyName": "",
            "country": "Ecuador"
        },
        {
            "name": "Transportes Aereos Cielos Andinos",
            "code": "",
            "auxcode": "NDN",
            "companyName": "ANDINOS",
            "country": "Peru"
        },
        {
            "name": "Peruvian Airlines",
            "code": "P9",
            "auxcode": null,
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "EasyFly",
            "code": "",
            "auxcode": "EFY",
            "companyName": "EASYFLY",
            "country": "Colombia"
        },
        {
            "name": "Polar Airlines",
            "code": "ЯП",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Catovair",
            "code": "OC",
            "auxcode": null,
            "companyName": "CATOVAIR",
            "country": "Mauritius"
        },
        {
            "name": "Andalus Lineas Aereas",
            "code": "",
            "auxcode": "ANU",
            "companyName": "Andalus",
            "country": "Spain"
        },
        {
            "name": "Air 26",
            "code": "",
            "auxcode": "DCD",
            "companyName": "DUCARD",
            "country": "Angola"
        },
        {
            "name": "Mauritania Airways",
            "code": "",
            "auxcode": "MTW",
            "companyName": "MAURITANIA AIRWAYS",
            "country": "Mauritania"
        },
        {
            "name": "CEIBA Intercontinental",
            "code": "",
            "auxcode": "CEL",
            "companyName": "CEIBA LINE",
            "country": "Equatorial Guinea"
        },
        {
            "name": "Halcyonair",
            "code": "7Z",
            "auxcode": null,
            "companyName": "CREOLE",
            "country": "Cape Verde"
        },
        {
            "name": "Business Aviation",
            "code": "4P",
            "auxcode": null,
            "companyName": "AFRICAN BUSINESS",
            "country": "Congo (Kinshasa)"
        },
        {
            "name": "Compagnie Africaine d\\\\'Aviation",
            "code": "E9",
            "auxcode": null,
            "companyName": "AFRICOMPANY",
            "country": "Congo (Kinshasa)"
        },
        {
            "name": "Zambia Skyways",
            "code": "K8",
            "auxcode": null,
            "companyName": "ZAMBIA SKIES",
            "country": "Zambia"
        },
        {
            "name": "AlMasria Universal Airlines",
            "code": "UJ",
            "auxcode": "LMU",
            "companyName": "ALMASRIA",
            "country": "Egypt"
        },
        {
            "name": "EgyptAir Express",
            "code": "",
            "auxcode": "MSE",
            "companyName": "EGYPTAIR EXPRESS",
            "country": "Egypt"
        },
        {
            "name": "SmartLynx Airlines",
            "code": "6Y",
            "auxcode": null,
            "companyName": "",
            "country": "Latvia"
        },
        {
            "name": "Air Italy Egypt",
            "code": "",
            "auxcode": "EUD",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "KoralBlue Airlines",
            "code": "K7",
            "auxcode": "KBR",
            "companyName": "KORAL BLUE",
            "country": "Egypt"
        },
        {
            "name": "Wind Rose Aviation",
            "code": "",
            "auxcode": "WRC",
            "companyName": "WIND ROSE",
            "country": "Ukraine"
        },
        {
            "name": "Elysian Airlines",
            "code": "E4",
            "auxcode": "GIE",
            "companyName": "",
            "country": "Cameroon"
        },
        {
            "name": "Sevenair",
            "code": "",
            "auxcode": "SEN",
            "companyName": "SEVENAIR",
            "country": "Tunisia"
        },
        {
            "name": "Hellenic Imperial Airways",
            "code": "HT",
            "auxcode": "IMP",
            "companyName": "IMPERIAL",
            "country": "Greece"
        },
        {
            "name": "Amsterdam Airlines",
            "code": "WD",
            "auxcode": "AAN",
            "companyName": "AMSTEL",
            "country": "Netherlands"
        },
        {
            "name": "Arik Niger",
            "code": "Q9",
            "auxcode": "NAK",
            "companyName": "",
            "country": "Niger"
        },
        {
            "name": "Dana Air",
            "code": "DA",
            "auxcode": null,
            "companyName": "DANACO",
            "country": "Nigeria"
        },
        {
            "name": "STP Airways",
            "code": "8F",
            "auxcode": "STP",
            "companyName": "SAOTOME AIRWAYS",
            "country": "Sao Tome and Principe"
        },
        {
            "name": "Med Airways",
            "code": "7Y",
            "auxcode": null,
            "companyName": "FLYING CARPET",
            "country": "Lebanon"
        },
        {
            "name": "Skyjet Airlines",
            "code": "UQ",
            "auxcode": "SJU",
            "companyName": "SKYJET",
            "country": "Uganda"
        },
        {
            "name": "Air Volga",
            "code": "G6",
            "auxcode": null,
            "companyName": "GOUMRAK",
            "country": "Russia"
        },
        {
            "name": "Transavia Denmark",
            "code": "",
            "auxcode": "TDK",
            "companyName": "",
            "country": "Denmark"
        },
        {
            "name": "Royal Falcon",
            "code": "RL",
            "auxcode": "RFJ",
            "companyName": "",
            "country": "Jordan"
        },
        {
            "name": "Euroline",
            "code": "4L",
            "auxcode": "MJX",
            "companyName": "GEOcodeLINE",
            "country": "Georgia"
        },
        {
            "name": "Worldways",
            "code": "WG",
            "auxcode": "WGC",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Turkuaz Airlines",
            "code": "",
            "auxcode": "TRK",
            "companyName": "TURKU",
            "country": "Turkey"
        },
        {
            "name": "Athens Airways",
            "code": "ZF",
            "auxcode": null,
            "companyName": "ATHENSAIR",
            "country": "Greece"
        },
        {
            "name": "Viking Hellas",
            "code": "VQ",
            "auxcode": "VKH",
            "companyName": "DELPHI",
            "country": "Greece"
        },
        {
            "name": "Norlandair",
            "code": "",
            "auxcode": "FNA",
            "companyName": "NORLAND",
            "country": "Iceland"
        },
        {
            "name": "Flugfelag Vestmannaeyja",
            "code": "",
            "auxcode": "FVM",
            "companyName": "ELEGANT",
            "country": "Iceland"
        },
        {
            "name": "Starline.kz",
            "code": "DZ",
            "auxcode": null,
            "companyName": "ALUNK",
            "country": "Kazakhstan"
        },
        {
            "name": "Euro Harmony",
            "code": "EH",
            "auxcode": "EHM",
            "companyName": "",
            "country": "Portugal"
        },
        {
            "name": "Lugansk Airlines",
            "code": "L7",
            "auxcode": null,
            "companyName": "ENTERPRISE LUHANSK",
            "country": "Ukraine"
        },
        {
            "name": "Gryphon Airlines",
            "code": "6P",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Gadair European Airlines",
            "code": "GP",
            "auxcode": "GDR",
            "companyName": "GADAIR",
            "country": "Spain"
        },
        {
            "name": "Spirit of Manila Airlines",
            "code": "SM",
            "auxcode": "MNP",
            "companyName": "MANILA SKY",
            "country": "Philippines"
        },
        {
            "name": "Chongqing Airlines",
            "code": "OQ",
            "auxcode": "CQN",
            "companyName": "CHONG QING",
            "country": "China"
        },
        {
            "name": "Grand China Air",
            "code": "",
            "auxcode": "GDC",
            "companyName": "GRAND CHINA",
            "country": "China"
        },
        {
            "name": "West Air China",
            "code": "PN",
            "auxcode": "CHB",
            "companyName": "WEST CHINA",
            "country": "China"
        },
        {
            "name": "Falcon Air (IH)",
            "code": "IH",
            "auxcode": null,
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "QatXpress",
            "code": "C3",
            "auxcode": "QAX",
            "companyName": "",
            "country": "Qatar"
        },
        {
            "name": "OneChina",
            "code": "1C",
            "auxcode": "1CH",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "NordStar Airlines",
            "code": "Y7",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Joy Air",
            "code": "JR",
            "auxcode": "JOY",
            "companyName": "JOY AIR",
            "country": "China"
        },
        {
            "name": "Air India Regional",
            "code": "CD",
            "auxcode": null,
            "companyName": "ALLIED",
            "country": "India"
        },
        {
            "name": "MDLR Airlines",
            "code": "9H",
            "auxcode": null,
            "companyName": "MDLR",
            "country": "India"
        },
        {
            "name": "Jagson Airlines",
            "code": "",
            "auxcode": "JGN",
            "companyName": "JAGSON",
            "country": "India"
        },
        {
            "name": "Maldivian",
            "code": "Q2",
            "auxcode": null,
            "companyName": "ISLAND AVIATION",
            "country": "Maldives"
        },
        {
            "name": "Xpressair",
            "code": "XN",
            "auxcode": null,
            "companyName": "",
            "country": "Indonesia"
        },
        {
            "name": "Strategic Airlines",
            "code": "VC",
            "auxcode": null,
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Fars Air Qeshm",
            "code": "",
            "auxcode": "QFZ",
            "companyName": "FARS AIR",
            "country": "Iran"
        },
        {
            "name": "Eastok Avia",
            "code": "",
            "auxcode": "EAA",
            "companyName": "",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Jupiter Airlines",
            "code": "",
            "auxcode": "JPU",
            "companyName": "JUPITERAIR",
            "country": "United Arab Emirates"
        },
        {
            "name": "Vision Air International",
            "code": "",
            "auxcode": "VIS",
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "AlcodeNaser Airlines",
            "code": "NA",
            "auxcode": null,
            "companyName": "",
            "country": "Iraq"
        },
        {
            "name": "Fuji Dream Airlines",
            "code": "JH",
            "auxcode": null,
            "companyName": "FUJI DREAM",
            "country": "Japan"
        },
        {
            "name": "Korea Express Air",
            "code": "",
            "auxcode": "KEA",
            "companyName": "",
            "country": "South Korea"
        },
        {
            "name": "Eznis Airways",
            "code": "",
            "auxcode": "EZA",
            "companyName": "EZNIS",
            "country": "Mongolia"
        },
        {
            "name": "Pacific Flier",
            "code": "",
            "auxcode": "PFL",
            "companyName": "KOROR",
            "country": "Palau"
        },
        {
            "name": "Syrian Pearl Airlines",
            "code": "",
            "auxcode": "PSB",
            "companyName": "",
            "country": "Syria"
        },
        {
            "name": "SGA Airlines",
            "code": "5E",
            "auxcode": null,
            "companyName": "SIAM",
            "country": "Thailand"
        },
        {
            "name": "Air2there",
            "code": "F8",
            "auxcode": null,
            "companyName": "",
            "country": "New Zealand"
        },
        {
            "name": "Avianova (Russia)",
            "code": "AO",
            "auxcode": null,
            "companyName": "Nova",
            "country": "Russia"
        },
        {
            "name": "Parmiss Airlines (IPV)",
            "code": "PA",
            "auxcode": "IPV",
            "companyName": "IPV",
            "country": "Iran"
        },
        {
            "name": "EuropeSky",
            "code": "ES",
            "auxcode": "EUV",
            "companyName": "EuropeSky",
            "country": "Germany"
        },
        {
            "name": "BRAZIL AIR",
            "code": "GB",
            "auxcode": "BZE",
            "companyName": "BRAZIL AIR",
            "country": "Brazil"
        },
        {
            "name": "Homer Air",
            "code": "MR",
            "auxcode": "OME",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Court Line",
            "code": "??",
            "auxcode": "???",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "South West Africa Territory Force",
            "code": "??",
            "auxcode": "***",
            "companyName": "",
            "country": "Namibia"
        },
        {
            "name": "Lombards Air",
            "code": "++",
            "auxcode": "codecodecode",
            "companyName": "",
            "country": "South Africa"
        },
        {
            "name": "U.S. Air",
            "code": "code+",
            "auxcode": "codecode+",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Flitestar",
            "code": "GM",
            "auxcode": "Err:510",
            "companyName": "",
            "country": "South Africa"
        },
        {
            "name": "Jayrow",
            "code": "\\\\'",
            "auxcode": "\\\\'\\\\",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Llloyd Helicopters",
            "code": "::",
            "auxcode": ":::",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Wilderness Air",
            "code": ";;",
            "auxcode": null,
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Whitaker Air",
            "code": "^^",
            "auxcode": null,
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "PanAm World Airways",
            "code": "WQ",
            "auxcode": "PQW",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Virginwings",
            "code": "YY",
            "auxcode": "VWA",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "KSY",
            "code": "KY",
            "auxcode": "KSY",
            "companyName": "KSY",
            "country": "Greece"
        },
        {
            "name": "Buquebus Líneas Aéreas",
            "code": "BQ",
            "auxcode": "BQB",
            "companyName": "",
            "country": "Uruguay"
        },
        {
            "name": "SOCHI AIR",
            "code": "CQ",
            "auxcode": "KOL",
            "companyName": "SLOW FROG",
            "country": "Russia"
        },
        {
            "name": "Wizz Air Ukraine",
            "code": "WU",
            "auxcode": "WAU",
            "companyName": "WIZZAIR UKRAINE",
            "country": "Ukraine"
        },
        {
            "name": "88",
            "code": "47",
            "auxcode": "VVN",
            "companyName": "",
            "country": "Cyprus"
        },
        {
            "name": "LCM AIRLINES",
            "code": "LQ",
            "auxcode": "LMM",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Aero Brazil",
            "code": "BZ",
            "auxcode": "BZL",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "Cambodia Angkor Air (K6)",
            "code": "K6",
            "auxcode": null,
            "companyName": "",
            "country": "Cambodia"
        },
        {
            "name": "Skyline nepc",
            "code": "D5",
            "auxcode": null,
            "companyName": "",
            "country": "India"
        },
        {
            "name": "THREE",
            "code": "H3",
            "auxcode": "T33",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Royal European Airlines",
            "code": "69",
            "auxcode": null,
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Tom\\\\'s & co airliners",
            "code": "&T",
            "auxcode": "T&O",
            "companyName": "T&",
            "country": "France"
        },
        {
            "name": "Azul",
            "code": "AD",
            "auxcode": "AZU",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "LSM Airlines",
            "code": "PQ",
            "auxcode": "LOO",
            "companyName": "slowbird",
            "country": "Russia"
        },
        {
            "name": "Zapolyarie Airlines",
            "code": "",
            "auxcode": "PZY",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Finlandian",
            "code": "",
            "auxcode": "FN1",
            "companyName": "",
            "country": "Finland"
        },
        {
            "name": "LionXpress",
            "code": "C4",
            "auxcode": "LIX",
            "companyName": "LIX",
            "country": "Cameroon"
        },
        {
            "name": "Nik Airways",
            "code": "X1",
            "auxcode": null,
            "companyName": "",
            "country": "Saudi Arabia"
        },
        {
            "name": "Genesis",
            "code": "GK",
            "auxcode": null,
            "companyName": "",
            "country": "Pakistan"
        },
        {
            "name": "Congo Express",
            "code": "XZ",
            "auxcode": null,
            "companyName": "EXPRESSWAYS",
            "country": "Congo (Kinshasa)"
        },
        {
            "name": "Fly Dubai",
            "code": "FZ",
            "auxcode": "FDB",
            "companyName": "",
            "country": "United Arab Emirates"
        },
        {
            "name": "Domenican Airlines",
            "code": "D1",
            "auxcode": "MDO",
            "companyName": "Domenican",
            "country": "Dominican Republic"
        },
        {
            "name": "ConneX European Airline",
            "code": "",
            "auxcode": "2CO",
            "companyName": "ConneX",
            "country": "Austria"
        },
        {
            "name": "Air Atlantic",
            "code": "9A",
            "auxcode": null,
            "companyName": "Atlantic",
            "country": "Canada"
        },
        {
            "name": "Air Ops",
            "code": "CR",
            "auxcode": null,
            "companyName": "",
            "country": "Sweden"
        },
        {
            "name": "Aereonautica militare",
            "code": "JY",
            "auxcode": "AXZ",
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Kal Star Aviation",
            "code": "",
            "auxcode": "KLS",
            "companyName": "",
            "country": "Indonesia"
        },
        {
            "name": "LSM AIRLINES ",
            "code": "YZ",
            "auxcode": "YZZ",
            "companyName": "Moscow frog ",
            "country": "Russia"
        },
        {
            "name": "Aero Lloyd (YP)",
            "code": "YP",
            "auxcode": "AEF",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "UTaircodeExpress",
            "code": "UR",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Huaxia",
            "code": "G5",
            "auxcode": null,
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Zabaykalskii Airlines",
            "code": "ZP",
            "auxcode": "ZZZ",
            "companyName": "Lakeair",
            "country": "Russia"
        },
        {
            "name": "CBM America",
            "code": "",
            "auxcode": "XBM",
            "companyName": "AIRMAX",
            "country": "United States"
        },
        {
            "name": "Marysya Airlines",
            "code": "M4",
            "auxcode": "1QA",
            "companyName": "MARSHAK AIR",
            "country": "Russia"
        },
        {
            "name": "N1",
            "code": "N1",
            "auxcode": null,
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "Airlink (SAA)",
            "code": "4Z",
            "auxcode": null,
            "companyName": "",
            "country": "South Africa"
        },
        {
            "name": "Westfalia Express VA",
            "code": "",
            "auxcode": "WFX",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "JobAir",
            "code": "3B",
            "auxcode": null,
            "companyName": "",
            "country": "Czech Republic"
        },
        {
            "name": "Zuliana de Aviacion",
            "code": "OD",
            "auxcode": "ULA",
            "companyName": "",
            "country": "Venezuela"
        },
        {
            "name": "Black Stallion Airways",
            "code": "BZ",
            "auxcode": "BSA",
            "companyName": "Stallion",
            "country": "United States"
        },
        {
            "name": "German International Air Lines",
            "code": "GM",
            "auxcode": "GER",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "TrasBrasil",
            "code": "TB",
            "auxcode": "TBZ",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "TransBrasil Airlines",
            "code": "TH",
            "auxcode": "THS",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "China SSS",
            "code": "9C",
            "auxcode": null,
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Nihon.jet",
            "code": "NJ",
            "auxcode": null,
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "AIR INDOCHINE",
            "code": "",
            "auxcode": "IIA",
            "companyName": "",
            "country": "Vietnam"
        },
        {
            "name": "Transportes Aéreos Nacionales de Selva",
            "code": "TJ",
            "auxcode": null,
            "companyName": "Aereos Selva",
            "country": "Peru"
        },
        {
            "name": "Happy Air",
            "code": "",
            "auxcode": "HPY",
            "companyName": "",
            "country": "Thailand"
        },
        {
            "name": "Solar Air",
            "code": "",
            "auxcode": "SRB",
            "companyName": "Solar Air",
            "country": "Thailand"
        },
        {
            "name": "Air Mekong",
            "code": "P8",
            "auxcode": "MKG",
            "companyName": "Air Mekong",
            "country": "Vietnam"
        },
        {
            "name": "Harbour Air (Priv)",
            "code": "H3",
            "auxcode": null,
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Air Hamburg (AHO)",
            "code": "HH",
            "auxcode": "AHO",
            "companyName": "Air Hamburg",
            "country": "Germany"
        },
        {
            "name": "ZABAIKAL AIRLINES",
            "code": "Z6",
            "auxcode": "ZTT",
            "companyName": "BAIKAL ",
            "country": "Russia"
        },
        {
            "name": "TransHolding",
            "code": "TI",
            "auxcode": "THI",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "SUR Lineas Aereas",
            "code": "",
            "auxcode": "SZZ",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Aerolineas Africanas",
            "code": "",
            "auxcode": "AA1",
            "companyName": "",
            "country": "Guinea"
        },
        {
            "name": "Yeti Airways",
            "code": "YT",
            "auxcode": null,
            "companyName": "",
            "country": "Nepal"
        },
        {
            "name": "Georgian Airlines",
            "code": "",
            "auxcode": "GEG",
            "companyName": "",
            "country": "Georgia"
        },
        {
            "name": "Yellowstone Club Private Shuttle",
            "code": "Y1",
            "auxcode": null,
            "companyName": "YCS",
            "country": "United States"
        },
        {
            "name": "Caucasus Airlines",
            "code": "NS",
            "auxcode": null,
            "companyName": "",
            "country": "Georgia"
        },
        {
            "name": "Serbian Airlines",
            "code": "S1",
            "auxcode": "SA1",
            "companyName": "",
            "country": "Serbia"
        },
        {
            "name": "Windward Islands Airways",
            "code": "WM",
            "auxcode": null,
            "companyName": "Winair",
            "country": "Netherlands Antilles"
        },
        {
            "name": "TransHolding System",
            "code": "YO",
            "auxcode": "TYS",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "CCML Airlines",
            "code": "CB",
            "auxcode": "CCC",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "Air Charter International",
            "code": "SF",
            "auxcode": null,
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Small Planet Airlines",
            "code": "",
            "auxcode": "ELC",
            "companyName": "",
            "country": "Lithuania"
        },
        {
            "name": "Fly Brasil",
            "code": "F1",
            "auxcode": "FBL",
            "companyName": "FBL",
            "country": "Brazil"
        },
        {
            "name": "AUOS",
            "code": "",
            "auxcode": "AUK",
            "companyName": "AUOS",
            "country": "United Kingdom"
        },
        {
            "name": "CB Airways UK ( Interliging Flights )",
            "code": "1F",
            "auxcode": "CIF",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Fly Colombia ( Interliging Flights )",
            "code": "3F",
            "auxcode": "3FF",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "Trans Pas Air",
            "code": "T6",
            "auxcode": "TP6",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "KMV",
            "code": "МИ",
            "auxcode": null,
            "companyName": "Air Minvody",
            "country": "Russia"
        },
        {
            "name": "Himalayan Airlines",
            "code": "HC",
            "auxcode": "HYM",
            "companyName": "Himalayan",
            "country": "Nepal"
        },
        {
            "name": "Indya Airline Group",
            "code": "G1",
            "auxcode": "IG1",
            "companyName": "Indya1",
            "country": "India"
        },
        {
            "name": "Sunwing",
            "code": "WG",
            "auxcode": null,
            "companyName": "sunwing",
            "country": "Canada"
        },
        {
            "name": "Turkish Wings Domestic",
            "code": "",
            "auxcode": "TWD",
            "companyName": "TWD",
            "country": "Turkey"
        },
        {
            "name": "Japan Regio",
            "code": "ZX",
            "auxcode": "ZXY",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "OCEAN AIR CARGO",
            "code": "",
            "auxcode": "IXO",
            "companyName": "",
            "country": "India"
        },
        {
            "name": "Norte Lineas Aereas",
            "code": "N0",
            "auxcode": null,
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Austral Brasil",
            "code": "W7",
            "auxcode": null,
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "PEGASUS AIRLINEScode",
            "code": "H9",
            "auxcode": null,
            "companyName": "",
            "country": "Turkey"
        },
        {
            "name": "AirLiberté",
            "code": "IJ",
            "auxcode": null,
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Nihon.jet connect",
            "code": "",
            "auxcode": "NX1",
            "companyName": "",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Camaircodeco",
            "code": "QC",
            "auxcode": null,
            "companyName": "",
            "country": "Cameroon"
        },
        {
            "name": "Aerocontinente (Priv)",
            "code": "N6",
            "auxcode": null,
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "Sky Regional",
            "code": "RS",
            "auxcode": "SKV",
            "companyName": "Sky Regional",
            "country": "Canada"
        },
        {
            "name": "TUR Avrupa Hava YollarÄ±",
            "code": "YI",
            "auxcode": null,
            "companyName": "TurAvrupa",
            "country": "Turkey"
        },
        {
            "name": "Cruzeiro do Sul Servicos Aereos",
            "code": "",
            "auxcode": "CRZ",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "LSM International ",
            "code": "II",
            "auxcode": "UWW",
            "companyName": "moose",
            "country": "Russia"
        },
        {
            "name": "Baikotovitchestrian Airlines ",
            "code": "BU",
            "auxcode": "BUU",
            "companyName": "",
            "country": "American Samoa"
        },
        {
            "name": "Luchsh Airlines ",
            "code": "L4",
            "auxcode": "LJJ",
            "companyName": "russian sky",
            "country": "Russia"
        },
        {
            "name": "ENTERair",
            "code": "",
            "auxcode": "QQQ",
            "companyName": "",
            "country": "Poland"
        },
        {
            "name": "Zimbabwean Airlines",
            "code": "Z7",
            "auxcode": null,
            "companyName": "",
            "country": "Zimbabwe"
        },
        {
            "name": "Air Cargo Germany",
            "code": "6U",
            "auxcode": null,
            "companyName": "Loadmaster",
            "country": "Germany"
        },
        {
            "name": "Mongolian International Air Lines ",
            "code": "7M",
            "auxcode": "ZTF",
            "companyName": "Mongol_AIr ",
            "country": "Mongolia"
        },
        {
            "name": "Alaniya Airlines",
            "code": "2D",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Tway Airlines",
            "code": "TW",
            "auxcode": "TWB",
            "companyName": "TWAY AIR",
            "country": "South Korea"
        },
        {
            "name": "Papillon Grand Canyon Helicopters",
            "code": "HI",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Jusur airways",
            "code": "JX",
            "auxcode": "JSR",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "NEXT Brasil",
            "code": "XB",
            "auxcode": "NXB",
            "companyName": "XB",
            "country": "Brazil"
        },
        {
            "name": "AeroWorld ",
            "code": "W4",
            "auxcode": "WER",
            "companyName": "sovet",
            "country": "Russia"
        },
        {
            "name": "Cook Island Air",
            "code": "KH",
            "auxcode": null,
            "companyName": "",
            "country": "Cook Islands"
        },
        {
            "name": "US Africa Airways",
            "code": "E8",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "GNB Linhas Aereas",
            "code": "GN",
            "auxcode": null,
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "Usa Sky Cargo",
            "code": "E1",
            "auxcode": "ES2",
            "companyName": "USKY",
            "country": "United States"
        },
        {
            "name": "Hankook Airline",
            "code": "HN",
            "auxcode": "HNX",
            "companyName": "HNX",
            "country": "South Korea"
        },
        {
            "name": "Red Jet America",
            "code": "RR",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "REDjet",
            "code": "Z7",
            "auxcode": null,
            "companyName": "",
            "country": "Barbados"
        },
        {
            "name": "Hellenic Airways",
            "code": "1H",
            "auxcode": "HEY",
            "companyName": "Hellenic",
            "country": "Greece"
        },
        {
            "name": "Red Jet Andes",
            "code": "PT",
            "auxcode": null,
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "Red Jet Canada",
            "code": "QY",
            "auxcode": null,
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Sprintair",
            "code": "",
            "auxcode": "SRN",
            "companyName": "",
            "country": "Poland"
        },
        {
            "name": "Red Jet Mexico",
            "code": "4X",
            "auxcode": null,
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "Marusya Airways",
            "code": "Y8",
            "auxcode": "MRS",
            "companyName": "snowball",
            "country": "Russia"
        },
        {
            "name": "Era Alaska",
            "code": "7H",
            "auxcode": "ERR",
            "companyName": "ERAH",
            "country": "United States"
        },
        {
            "name": "AirRussia",
            "code": "R8",
            "auxcode": "RRJ",
            "companyName": "russiancloud",
            "country": "Russia"
        },
        {
            "name": "Hankook Air US",
            "code": "H1",
            "auxcode": "HA1",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "NEPC Airlines",
            "code": "D5",
            "auxcode": null,
            "companyName": "",
            "country": "India"
        },
        {
            "name": "Canadian World",
            "code": "10",
            "auxcode": "CNN",
            "companyName": "Canadian",
            "country": "Canada"
        },
        {
            "name": "Pim Air",
            "code": "",
            "auxcode": "PHJ",
            "companyName": "Pim Air",
            "country": "Netherlands"
        },
        {
            "name": "Carpatair Flight Training",
            "code": "",
            "auxcode": "SMW",
            "companyName": "Smartwings",
            "country": "Romania"
        },
        {
            "name": "IcodeFly",
            "code": "H5",
            "auxcode": "RSY",
            "companyName": "RUSSIAN SKY",
            "country": "Russia"
        },
        {
            "name": "T.A.T",
            "code": "IJ",
            "auxcode": null,
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Compania de Aviacion Faucett",
            "code": "",
            "auxcode": "CFP",
            "companyName": "",
            "country": "Peru"
        },
        {
            "name": "KarcodeAir",
            "code": "",
            "auxcode": "KAR",
            "companyName": "",
            "country": "Finland"
        },
        {
            "name": "Alinord",
            "code": "DN",
            "auxcode": null,
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Pacific Express",
            "code": "VB",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Whitejets",
            "code": "",
            "auxcode": "WTJ",
            "companyName": "WHITEJET",
            "country": "Brazil"
        },
        {
            "name": "VickJet",
            "code": "KT",
            "auxcode": "VKJ",
            "companyName": "Vickjet",
            "country": "France"
        },
        {
            "name": "BVI Airways",
            "code": "XV",
            "auxcode": null,
            "companyName": "",
            "country": "British Virgin Islands"
        },
        {
            "name": "Hamburg Airways",
            "code": "",
            "auxcode": "HAY",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Salsa d\\\\'Haiti",
            "code": "SO",
            "auxcode": "SLC",
            "companyName": "SALSA",
            "country": "Haiti"
        },
        {
            "name": "Zambezi Airlines (ZMA)",
            "code": "ZJ",
            "auxcode": null,
            "companyName": "",
            "country": "Zambia"
        },
        {
            "name": "Kan Air",
            "code": "",
            "auxcode": "KND",
            "companyName": "Kan Air",
            "country": "Thailand"
        },
        {
            "name": "Air Cudlua",
            "code": "",
            "auxcode": "CUD",
            "companyName": "Cudlua",
            "country": "United Kingdom"
        },
        {
            "name": "Polet Airlines (Priv)",
            "code": "YQ",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Air Explore",
            "code": "",
            "auxcode": "AXE",
            "companyName": "",
            "country": "Slovakia"
        },
        {
            "name": "TROPICAL LINHAS AEREAS",
            "code": "T1",
            "auxcode": "TP3",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "12 North",
            "code": "12",
            "auxcode": "N12",
            "companyName": "12N",
            "country": "India"
        },
        {
            "name": "Holidays Czech Airlines",
            "code": "",
            "auxcode": "HCC",
            "companyName": "",
            "country": "Czech Republic"
        },
        {
            "name": "Comtel Air",
            "code": "",
            "auxcode": "COE",
            "companyName": "",
            "country": "Austria"
        },
        {
            "name": "Mint Airways",
            "code": "",
            "auxcode": "MIC",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Orbit Airlines",
            "code": "",
            "auxcode": "OBT",
            "companyName": "Orbit",
            "country": "United States"
        },
        {
            "name": "Air Bucharest",
            "code": "",
            "auxcode": "BUR",
            "companyName": "",
            "country": "Romania"
        },
        {
            "name": "AlbaStar",
            "code": "",
            "auxcode": "LAV",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Mauritania Airlines International",
            "code": "L6",
            "auxcode": "MAI",
            "companyName": "",
            "country": "Mauritania"
        },
        {
            "name": "MAT Airways",
            "code": "6F",
            "auxcode": "MKD",
            "companyName": "",
            "country": "Macedonia"
        },
        {
            "name": "Asian Wings Airways",
            "code": "AW",
            "auxcode": "AWM",
            "companyName": "Asian Star",
            "country": "Burma"
        },
        {
            "name": "Air Arabia Egypt",
            "code": "E5",
            "auxcode": "RBG",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "Eagles Airlines",
            "code": "",
            "auxcode": "EGS",
            "companyName": "EAGLES",
            "country": "Italy"
        },
        {
            "name": "YES Airways",
            "code": "",
            "auxcode": "YEP",
            "companyName": "",
            "country": "Poland"
        },
        {
            "name": "Alitalia Cityliner",
            "code": "CT",
            "auxcode": null,
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Direct Aero Services",
            "code": "",
            "auxcode": "DSV",
            "companyName": "",
            "country": "Romania"
        },
        {
            "name": "Medallion Air",
            "code": "",
            "auxcode": "MDP",
            "companyName": "MEDALS",
            "country": "Romania"
        },
        {
            "name": "Orchid Airlines",
            "code": "OI",
            "auxcode": "ORC",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Asia Wings",
            "code": "Y5",
            "auxcode": "AWA",
            "companyName": "",
            "country": "Kazakhstan"
        },
        {
            "name": "Georgian International Airlines",
            "code": "",
            "auxcode": "GNN",
            "companyName": "GEOcodeLINE",
            "country": "Georgia"
        },
        {
            "name": "Air Batumi",
            "code": "",
            "auxcode": "BTM",
            "companyName": "",
            "country": "Georgia"
        },
        {
            "name": "Skywest Australia",
            "code": "XR",
            "auxcode": null,
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Nile Air",
            "code": "NP",
            "auxcode": "NIA",
            "companyName": "NILEBIRD",
            "country": "Egypt"
        },
        {
            "name": "Feeder Airlines",
            "code": "",
            "auxcode": "FDD",
            "companyName": "",
            "country": "Sudan"
        },
        {
            "name": "Senegal Airlines",
            "code": "DN",
            "auxcode": "SGG",
            "companyName": "",
            "country": "Senegal"
        },
        {
            "name": "Fly 6ix",
            "code": "6I",
            "auxcode": null,
            "companyName": "",
            "country": "Sierra Leone"
        },
        {
            "name": "Starbow Airlines",
            "code": "S9",
            "auxcode": null,
            "companyName": "",
            "country": "Ghana"
        },
        {
            "name": "Copenhagen Express",
            "code": "0X",
            "auxcode": "CX0",
            "companyName": "Copex",
            "country": "Denmark"
        },
        {
            "name": "BusinessAir",
            "code": "8B",
            "auxcode": "BCC",
            "companyName": "",
            "country": "Thailand"
        },
        {
            "name": "SENIC AIRLINES",
            "code": "YR",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Compass Airlines (Australia)",
            "code": "",
            "auxcode": "CYM",
            "companyName": "Compair",
            "country": "Australia"
        },
        {
            "name": "XOJET",
            "code": "",
            "auxcode": "XOJ",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Dexter (DXT)",
            "code": "",
            "auxcode": "DXT",
            "companyName": "Dexter",
            "country": "Russia"
        },
        {
            "name": "Sky Wing Pacific",
            "code": "C7",
            "auxcode": "CR7",
            "companyName": "",
            "country": "South Korea"
        },
        {
            "name": "Bateleur Air",
            "code": "",
            "auxcode": "BEU",
            "companyName": "",
            "country": "South Africa"
        },
        {
            "name": "Air Indus",
            "code": "PP",
            "auxcode": "AI0",
            "companyName": "AIPL",
            "country": "Pakistan"
        },
        {
            "name": "Samurai Airlines",
            "code": "7",
            "auxcode": "770",
            "companyName": "Sam",
            "country": "Pakistan"
        },
        {
            "name": "AirOne Continental",
            "code": "0",
            "auxcode": "0",
            "companyName": "Eastern",
            "country": "Slovakia"
        },
        {
            "name": "AirOne Polska",
            "code": "U1",
            "auxcode": "1",
            "companyName": "AOC",
            "country": "Poland"
        },
        {
            "name": "Orbit International Airlines",
            "code": "",
            "auxcode": "OAI",
            "companyName": "OA",
            "country": "United States"
        },
        {
            "name": "Orbit Regional Airlines",
            "code": "",
            "auxcode": "OAR",
            "companyName": "OA",
            "country": "United States"
        },
        {
            "name": "Orbit Atlantic Airways",
            "code": "",
            "auxcode": "OAN",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Volotea",
            "code": "",
            "auxcode": "VOO",
            "companyName": "Volotea",
            "country": "Spain"
        },
        {
            "name": "Go Fly (United Kingdom)",
            "code": "",
            "auxcode": "GOE",
            "companyName": "Go Flight",
            "country": "United Kingdom"
        },
        {
            "name": "Peach Aviation",
            "code": "MM",
            "auxcode": null,
            "companyName": "Air Peach",
            "country": "Japan"
        },
        {
            "name": "Helitt Líneas Aéreas",
            "code": "",
            "auxcode": "HTH",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Russia State Transport",
            "code": "",
            "auxcode": "RSD",
            "companyName": "STATE AERO",
            "country": "Russia"
        },
        {
            "name": "Malaysia Wings",
            "code": "",
            "auxcode": "MWI",
            "companyName": "MWI",
            "country": "Malaysia"
        },
        {
            "name": "Aviabus",
            "code": "U1",
            "auxcode": "ABI",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Michael Airlines",
            "code": "DF",
            "auxcode": "MJG",
            "companyName": "MJG",
            "country": "Puerto Rico"
        },
        {
            "name": "Korongo Airlines",
            "code": "ZC",
            "auxcode": "KGO",
            "companyName": "KORONGO",
            "country": "Congo (Kinshasa)"
        },
        {
            "name": "Indonesia Sky",
            "code": "I5",
            "auxcode": "IDS",
            "companyName": "",
            "country": "Indonesia"
        },
        {
            "name": "Pelangi ",
            "code": "9P",
            "auxcode": null,
            "companyName": "",
            "country": "Malaysia"
        },
        {
            "name": "Aws express",
            "code": "B0",
            "auxcode": "666",
            "companyName": "aws",
            "country": "United States"
        },
        {
            "name": "Southjet",
            "code": "76",
            "auxcode": "SJS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Southjet connect",
            "code": "77",
            "auxcode": "ZCS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Air Cape",
            "code": "KP",
            "auxcode": null,
            "companyName": "",
            "country": "South Africa"
        },
        {
            "name": "Southjet cargo",
            "code": "78",
            "auxcode": "XAN",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Iberia Express",
            "code": "I2",
            "auxcode": "IBS",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Interjet (ABC Aerolineas)",
            "code": "4O",
            "auxcode": null,
            "companyName": "INTERJET",
            "country": "Mexico"
        },
        {
            "name": "AirOnix",
            "code": "OG",
            "auxcode": null,
            "companyName": "",
            "country": "Ukraine"
        },
        {
            "name": "Nordic Global Airlines",
            "code": "NJ",
            "auxcode": "NGB",
            "companyName": "Nordic Global",
            "country": "Finland"
        },
        {
            "name": "Scoot",
            "code": "TZ",
            "auxcode": "SCO",
            "companyName": "",
            "country": "Singapore"
        },
        {
            "name": "Starling Airlines Spain",
            "code": "SX",
            "auxcode": "STS",
            "companyName": "STARLING",
            "country": "Spain"
        },
        {
            "name": "Hi Fly (5K)",
            "code": "5K",
            "auxcode": null,
            "companyName": "",
            "country": "Portugal"
        },
        {
            "name": "China Northwest Airlines (WH)",
            "code": "WH",
            "auxcode": null,
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Zenith International Airline",
            "code": "ZN",
            "auxcode": "ZNA",
            "companyName": "ZENITH",
            "country": "Thailand"
        },
        {
            "name": "Orbit Airlines Azerbaijan",
            "code": "O1",
            "auxcode": "OAB",
            "companyName": "Orbitaz",
            "country": "Azerbaijan"
        },
        {
            "name": "Air EngiadinacodeBRN",
            "code": "",
            "auxcode": "RQX",
            "companyName": "Engiadina",
            "country": "Switzerland"
        },
        {
            "name": "VG Airlines (IV)",
            "code": "",
            "auxcode": "FVG",
            "companyName": "Nico",
            "country": "Belgium"
        },
        {
            "name": "Air Alps Aviation (A6)",
            "code": "A6",
            "auxcode": null,
            "companyName": "ALPAV",
            "country": "Austria"
        },
        {
            "name": "Austrian Airtransport",
            "code": "",
            "auxcode": "AAT",
            "companyName": "",
            "country": "Austria"
        },
        {
            "name": "Flying kangaroo Airline",
            "code": "",
            "auxcode": "FKA",
            "companyName": "Skippy",
            "country": "Australia"
        },
        {
            "name": "RusJet",
            "code": "",
            "auxcode": "RSJ",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "VietJet Air",
            "code": "",
            "auxcode": "VJC",
            "companyName": "VIETJETAIR",
            "country": "Vietnam"
        },
        {
            "name": "Spantax S.A.",
            "code": "",
            "auxcode": "BXS",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Patriot Airways",
            "code": "P4",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Vision Airlines (V2)",
            "code": "V2",
            "auxcode": "RBY",
            "companyName": "RUBY",
            "country": "United States"
        },
        {
            "name": "Chicago Express (C8)",
            "code": "C8",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "BQB Lineas Aereas",
            "code": "5Q",
            "auxcode": null,
            "companyName": "",
            "country": "Uruguay"
        },
        {
            "name": "AirAsia Japan",
            "code": "",
            "auxcode": "WAJ",
            "companyName": "WING ASIA",
            "country": "Japan"
        },
        {
            "name": "Yellowtail",
            "code": "YE",
            "auxcode": "YEL",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Royal Airways",
            "code": "KG",
            "auxcode": "RAW",
            "companyName": "RAW",
            "country": "United States"
        },
        {
            "name": "FlyHigh Airlines Ireland (FH)",
            "code": "FH",
            "auxcode": "FHI",
            "companyName": "FLYHIRELAND",
            "country": "Ireland"
        },
        {
            "name": "Executive AirShare",
            "code": "",
            "auxcode": "XSR",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Hebei Airlines",
            "code": "",
            "auxcode": "HBH",
            "companyName": "Hebei Air",
            "country": "China"
        },
        {
            "name": "Air KBZ",
            "code": "",
            "auxcode": "KBZ",
            "companyName": "Air KBZ",
            "country": "Burma"
        },
        {
            "name": "Aero VIP (2D)",
            "code": "2D",
            "auxcode": null,
            "companyName": "",
            "country": "Portugal"
        },
        {
            "name": "Yangon Airways Ltd.",
            "code": "YH",
            "auxcode": null,
            "companyName": "",
            "country": "Burma"
        },
        {
            "name": "T.J. Air",
            "code": "TJ",
            "auxcode": "TJA",
            "companyName": "T.J. Air",
            "country": "United States"
        },
        {
            "name": "SkyWork Airlines ",
            "code": "SX",
            "auxcode": null,
            "companyName": "SKYFOX",
            "country": "Switzerland"
        },
        {
            "name": "ValueJet",
            "code": "J7",
            "auxcode": "VJA",
            "companyName": "CRITTER",
            "country": "United States"
        },
        {
            "name": "Maastricht Airlines",
            "code": "W2",
            "auxcode": null,
            "companyName": "",
            "country": "Netherlands"
        },
        {
            "name": "CheapFlyingInternational",
            "code": "WL",
            "auxcode": "FQR",
            "companyName": "cheapflying",
            "country": "France"
        },
        {
            "name": "Aviaexpresscruise",
            "code": "E6",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Euro Jet",
            "code": "24",
            "auxcode": null,
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Ukraine Atlantic",
            "code": "",
            "auxcode": "UAT",
            "companyName": "",
            "country": "Ukraine"
        },
        {
            "name": "AirOne Atlantic",
            "code": "0",
            "auxcode": "AO1",
            "companyName": "",
            "country": "Slovakia"
        },
        {
            "name": "HQcode Business Express",
            "code": "HQ",
            "auxcode": null,
            "companyName": "BizEx",
            "country": "United States"
        },
        {
            "name": "Nesma Airlines",
            "code": "",
            "auxcode": "NMA",
            "companyName": "Nesma Airlines",
            "country": "Egypt"
        },
        {
            "name": "East Horizon",
            "code": "",
            "auxcode": "EHN",
            "companyName": "EAST HORIZON",
            "country": "Afghanistan"
        },
        {
            "name": "Royal Southern Airlines.",
            "code": "R1",
            "auxcode": "RS1",
            "companyName": "RSA",
            "country": "Colombia"
        },
        {
            "name": "Air Majoro",
            "code": "",
            "auxcode": "MJP",
            "companyName": "Air Majoro",
            "country": "Peru"
        },
        {
            "name": "Fly Zoom",
            "code": "",
            "auxcode": "UKZ",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Rotana Jet",
            "code": "",
            "auxcode": "RJD",
            "companyName": "ROTANA",
            "country": "United Arab Emirates"
        },
        {
            "name": "SOCHI AIR CHATER",
            "code": "Q3",
            "auxcode": "QER",
            "companyName": "russian doll",
            "country": "Russia"
        },
        {
            "name": "Denim Air ",
            "code": "J7",
            "auxcode": null,
            "companyName": "DNM",
            "country": "Norway"
        },
        {
            "name": "WestAir",
            "code": "OE",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "WestAir Airlines",
            "code": "OE",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "WestAir Airlines ",
            "code": "OE",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "North Pacific Airlines",
            "code": "NO",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Malindo Air",
            "code": "OD",
            "auxcode": "MXD",
            "companyName": "Malindo",
            "country": "Malaysia"
        },
        {
            "name": "Tramm Airlines",
            "code": "9F",
            "auxcode": "TLM",
            "companyName": "9F",
            "country": "Netherlands Antilles"
        },
        {
            "name": "Lina Congo",
            "code": "GC",
            "auxcode": null,
            "companyName": "",
            "country": "Congo (Brazzaville)"
        },
        {
            "name": "Hermes Airlines",
            "code": "",
            "auxcode": "HRM",
            "companyName": "HERMES",
            "country": "Greece"
        },
        {
            "name": "Flightlink Tanzania",
            "code": "Z9",
            "auxcode": null,
            "companyName": "",
            "country": "Tanzania"
        },
        {
            "name": "IzAvia",
            "code": "I8",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Катэкавиа",
            "code": "",
            "auxcode": "КТК",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Псковавиа",
            "code": "",
            "auxcode": "PKV",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "3 Valleys Airlines",
            "code": "3V",
            "auxcode": "VA3",
            "companyName": "3 Valleys",
            "country": "France"
        },
        {
            "name": "Maryland Air",
            "code": "M1",
            "auxcode": "M1F",
            "companyName": "Maryland Flight",
            "country": "United States"
        },
        {
            "name": "Insel Air (7I/INC) (Priv)",
            "code": "7I",
            "auxcode": null,
            "companyName": "",
            "country": "Netherlands Antilles"
        },
        {
            "name": "VivaColombia",
            "code": "5Z",
            "auxcode": "VVC",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "Flybe Finland Oy",
            "code": "",
            "auxcode": "FCM",
            "companyName": "FINNCOMM",
            "country": "Finland"
        },
        {
            "name": "Bingo Airways",
            "code": "",
            "auxcode": "BGY",
            "companyName": "",
            "country": "Poland"
        },
        {
            "name": "Bluebird Airways (BZ)",
            "code": "",
            "auxcode": "BBG",
            "companyName": "",
            "country": "Greece"
        },
        {
            "name": "Apache Air",
            "code": "ZM",
            "auxcode": "IWA",
            "companyName": "APACHE",
            "country": "United States"
        },
        {
            "name": "Taunus Air Gmbh",
            "code": "",
            "auxcode": "TAQ",
            "companyName": "Taunusair",
            "country": "Germany"
        },
        {
            "name": "MHS Aviation GmbH",
            "code": "M2",
            "auxcode": null,
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Jettor Airlines",
            "code": "NR",
            "auxcode": "JTO",
            "companyName": "JETHAPPY",
            "country": "Hong Kong"
        },
        {
            "name": "Eastern Atlantic Charters",
            "code": "",
            "auxcode": "EDI",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "GoDutch",
            "code": "GD",
            "auxcode": "GOD",
            "companyName": "",
            "country": "Netherlands"
        },
        {
            "name": "Flyme (VP)",
            "code": "",
            "auxcode": "VQI",
            "companyName": "",
            "country": "Maldives"
        },
        {
            "name": "Thai Lion Air",
            "code": "SL",
            "auxcode": null,
            "companyName": "",
            "country": "Thailand"
        },
        {
            "name": "Deutsche Luftverkehrsgesellschaft",
            "code": "DW",
            "auxcode": "DLT",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Nürnberger Flugdienst",
            "code": "",
            "auxcode": "NFD",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Golden Myanmar Airlines",
            "code": "",
            "auxcode": "GMR",
            "companyName": "Golden Myanmar",
            "country": "Burma"
        },
        {
            "name": "ViznAir",
            "code": "",
            "auxcode": "VZA",
            "companyName": "Brian",
            "country": "United States"
        },
        {
            "name": "Canaryfly",
            "code": "",
            "auxcode": "CNF",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Sunrise Airways",
            "code": "",
            "auxcode": "KSZ",
            "companyName": "",
            "country": "Haiti"
        },
        {
            "name": "National Air Cargo",
            "code": "N8",
            "auxcode": "NCR",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Eastern Atlantic Virtual Airlines",
            "code": "13",
            "auxcode": "EAV",
            "companyName": "EAVA",
            "country": "United States"
        },
        {
            "name": "Citilink Indonesia",
            "code": "QG",
            "auxcode": null,
            "companyName": "SUPERGREEN",
            "country": "Indonesia"
        },
        {
            "name": "Gulisano airways",
            "code": "GU",
            "auxcode": "GU1",
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Transair",
            "code": "",
            "auxcode": "TTZ",
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Comfort Express Virtual Charters Albany",
            "code": "",
            "auxcode": "EVC",
            "companyName": "Comfort Express",
            "country": "United States"
        },
        {
            "name": "Comfort Express Virtual Charters",
            "code": "",
            "auxcode": "CEO",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Caribbean Wings",
            "code": "XP",
            "auxcode": "ZYZ",
            "companyName": "caribbean Wings",
            "country": "Turks and Caicos Islands"
        },
        {
            "name": "FLYJET",
            "code": "",
            "auxcode": "FYJ",
            "companyName": "Fast Jet",
            "country": "Poland"
        },
        {
            "name": "Snowbird Airlines",
            "code": "S8",
            "auxcode": "SBD",
            "companyName": "",
            "country": "Finland"
        },
        {
            "name": "Russkie Krylya",
            "code": "",
            "auxcode": "KRY",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Kharkiv Airlines",
            "code": "KH",
            "auxcode": "KHK",
            "companyName": "",
            "country": "Ukraine"
        },
        {
            "name": "Key Air",
            "code": "",
            "auxcode": "KWY",
            "companyName": "KeyAir",
            "country": "United States"
        },
        {
            "name": "XAIR USA",
            "code": "XA",
            "auxcode": "XAU",
            "companyName": "XAIR",
            "country": "United States"
        },
        {
            "name": "Air Costa",
            "code": "LB",
            "auxcode": null,
            "companyName": "",
            "country": "India"
        },
        {
            "name": "Simrik Airlines",
            "code": "",
            "auxcode": "RMK",
            "companyName": "",
            "country": "Nepal"
        },
        {
            "name": "Global Freightways",
            "code": "F5",
            "auxcode": "GF5",
            "companyName": "Freight",
            "country": "United States"
        },
        {
            "name": "XPTO",
            "code": "XP",
            "auxcode": "XPT",
            "companyName": "XPTO",
            "country": "Portugal"
        },
        {
            "name": "Royal Flight",
            "code": "",
            "auxcode": "DME",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "BBNcodeAirways",
            "code": "",
            "auxcode": "EGH",
            "companyName": "BBN",
            "country": "United Kingdom"
        },
        {
            "name": "TomskcodeAvia",
            "code": "",
            "auxcode": "TKS",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Vintage Props and Jets",
            "code": "",
            "auxcode": "VPP",
            "companyName": "VINTAGE",
            "country": "United States"
        },
        {
            "name": "Malawian Airlines",
            "code": "3W",
            "auxcode": null,
            "companyName": "",
            "country": "Malawi"
        },
        {
            "name": "Yeti Airlines ",
            "code": "",
            "auxcode": "NYT",
            "companyName": "",
            "country": "Nepal"
        },
        {
            "name": "Avilu",
            "code": "..",
            "auxcode": "...",
            "companyName": "",
            "country": "Switzerland"
        },
        {
            "name": "Air Serbia",
            "code": "JU",
            "auxcode": "ASL",
            "companyName": "AIR SERBIA",
            "country": "Serbia"
        },
        {
            "name": "Skyline Ulasim Ticaret A.S.",
            "code": "",
            "auxcode": "KCU",
            "companyName": "Kocoglu",
            "country": "Turkey"
        },
        {
            "name": "Air Lituanica",
            "code": "LT",
            "auxcode": "LTU",
            "companyName": "LITUANICA",
            "country": "Lithuania"
        },
        {
            "name": "Envoy Air",
            "code": "",
            "auxcode": "ENY",
            "companyName": "Envoy",
            "country": "United States"
        },
        {
            "name": "CARICOM AIRWAYS (BARBADOS) INC.",
            "code": "",
            "auxcode": "CCB",
            "companyName": "",
            "country": "Barbados"
        },
        {
            "name": "Rainbow Air (RAI)",
            "code": "RN",
            "auxcode": "RAB",
            "companyName": "Rainbow",
            "country": "United States"
        },
        {
            "name": "Rainbow Air Canada",
            "code": "RY",
            "auxcode": "RAY",
            "companyName": "Rainbow CAN",
            "country": "Canada"
        },
        {
            "name": "Rainbow Air Polynesia",
            "code": "RX",
            "auxcode": "RPO",
            "companyName": "Rainbow Air",
            "country": "United States"
        },
        {
            "name": "Rainbow Air Euro",
            "code": "RU",
            "auxcode": "RUE",
            "companyName": "Rainbow Air",
            "country": "United Kingdom"
        },
        {
            "name": "Rainbow Air US",
            "code": "RM",
            "auxcode": "RNY",
            "companyName": "Rainbow Air",
            "country": "United States"
        },
        {
            "name": "Transilvania",
            "code": "",
            "auxcode": "TNS",
            "companyName": "",
            "country": "Romania"
        },
        {
            "name": "Dobrolet",
            "code": "QD",
            "auxcode": "DOB",
            "companyName": "DOBROLET",
            "country": "Russia"
        },
        {
            "name": "Spike Airlines",
            "code": "S0",
            "auxcode": "SAL",
            "companyName": "Spike Air",
            "country": "United States"
        },
        {
            "name": "Grand Cru Airlines",
            "code": "",
            "auxcode": "GCA",
            "companyName": "",
            "country": "Lithuania"
        },
        {
            "name": "Go2Sky",
            "code": "",
            "auxcode": "RLX",
            "companyName": "RELAX",
            "country": "Slovakia"
        },
        {
            "name": "All Argentina",
            "code": "L1",
            "auxcode": "AL1",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "All America",
            "code": "A2",
            "auxcode": "AL2",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "All Asia",
            "code": "L9",
            "auxcode": "AL3",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "All Africa",
            "code": "9A",
            "auxcode": "99F",
            "companyName": "",
            "country": "South Africa"
        },
        {
            "name": "Regionalia México",
            "code": "N4",
            "auxcode": "J88",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "All Europe",
            "code": "N9",
            "auxcode": "N99",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "All Spain",
            "code": "N7",
            "auxcode": "N77",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Regional Air Iceland",
            "code": "9N",
            "auxcode": "N78",
            "companyName": "",
            "country": "Iceland"
        },
        {
            "name": "British Air Ferries",
            "code": "??",
            "auxcode": "??!",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Voestar",
            "code": "8K",
            "auxcode": "K88",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "All Colombia",
            "code": "7O",
            "auxcode": "7KK",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "Regionalia Uruguay",
            "code": "2X",
            "auxcode": "2K2",
            "companyName": "",
            "country": "Uruguay"
        },
        {
            "name": "Regionalia Venezuela",
            "code": "9X",
            "auxcode": "9XX",
            "companyName": "",
            "country": "Venezuela"
        },
        {
            "name": "Regionalia Chile",
            "code": "9J",
            "auxcode": "CR1",
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "Vuela Cuba",
            "code": "6C",
            "auxcode": "6CC",
            "companyName": "",
            "country": "Cuba"
        },
        {
            "name": "All Australia",
            "code": "88",
            "auxcode": "8K8",
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Fly Europa",
            "code": "ER",
            "auxcode": "RWW",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "FlyPortugal",
            "code": "PO",
            "auxcode": "FPT",
            "companyName": "FlyPortugal",
            "country": "Portugal"
        },
        {
            "name": "FTI Fluggesellschaft",
            "code": "",
            "auxcode": "FTI",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Spring Airlines Japan",
            "code": "IJ",
            "auxcode": "SJO",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Dense Airways",
            "code": "KP",
            "auxcode": "DWA",
            "companyName": "DENSE",
            "country": "United States"
        },
        {
            "name": "Dense Connection",
            "code": "KZ",
            "auxcode": "DC2",
            "companyName": "DC2",
            "country": "United States"
        },
        {
            "name": "Vuola Italia",
            "code": "4S",
            "auxcode": "VI4",
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Jet Suite",
            "code": "",
            "auxcode": "RSP",
            "companyName": "Red Stripe",
            "country": "United States"
        },
        {
            "name": "Fly Jamaica Airways",
            "code": "",
            "auxcode": "FJM",
            "companyName": "Greenheart",
            "country": "Jamaica"
        },
        {
            "name": "Island Express Air",
            "code": "1X",
            "auxcode": null,
            "companyName": "Abby Air",
            "country": "Canada"
        },
        {
            "name": "All Argentina Express",
            "code": "Z0",
            "auxcode": "Z9H",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Thai Smile Airways",
            "code": "WE",
            "auxcode": null,
            "companyName": "THAI SMILE",
            "country": "Thailand"
        },
        {
            "name": "International AirLink",
            "code": "I4",
            "auxcode": null,
            "companyName": "",
            "country": "Jamaica"
        },
        {
            "name": "Real Tonga",
            "code": "RT",
            "auxcode": null,
            "companyName": "",
            "country": "Tonga"
        },
        {
            "name": "All America AR",
            "code": "2R",
            "auxcode": "M7A",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "All America CL",
            "code": "1R",
            "auxcode": "R1R",
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "SOCHI AIR EXPRESS",
            "code": "Q4",
            "auxcode": "SAE",
            "companyName": "ADLER EXPRESS",
            "country": "Russia"
        },
        {
            "name": "All America BR",
            "code": "1Y",
            "auxcode": "A9B",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "FRA Air",
            "code": "X9",
            "auxcode": "X9F",
            "companyName": "FA",
            "country": "Germany"
        },
        {
            "name": "Royal",
            "code": "QN",
            "auxcode": null,
            "companyName": "Roy",
            "country": "Canada"
        },
        {
            "name": "GREAT LAKES (GX)",
            "code": "GX",
            "auxcode": null,
            "companyName": "",
            "country": "Canada"
        },
        {
            "name": "Volotea Costa Rica",
            "code": "9V",
            "auxcode": "VC9",
            "companyName": "",
            "country": "Costa Rica"
        },
        {
            "name": "Fly Romania",
            "code": "X5",
            "auxcode": "OTJ",
            "companyName": "TENDER AIR",
            "country": "Romania"
        },
        {
            "name": "Eagle Atlantic Airlines",
            "code": "E2",
            "auxcode": null,
            "companyName": "",
            "country": "Ghana"
        },
        {
            "name": "Fly Africa Zimbabwe",
            "code": "",
            "auxcode": "FZW",
            "companyName": "Fresh Express",
            "country": "Zimbabwe"
        },
        {
            "name": "San Dima Air",
            "code": "",
            "auxcode": "SDI",
            "companyName": "FAREFLIGHT",
            "country": "United States"
        },
        {
            "name": "All America CO",
            "code": "0Y",
            "auxcode": "7ZC",
            "companyName": "",
            "country": "Colombia"
        },
        {
            "name": "All America MX",
            "code": "0M",
            "auxcode": "0MM",
            "companyName": "",
            "country": "Mexico"
        },
        {
            "name": "FOX Linhas Aereas",
            "code": "FX",
            "auxcode": "FOX",
            "companyName": "",
            "country": "Brazil"
        },
        {
            "name": "Wings of England",
            "code": "EX",
            "auxcode": "..,",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "Air Kenya (Priv)",
            "code": "QP",
            "auxcode": null,
            "companyName": "",
            "country": "Kenya"
        },
        {
            "name": "Via Conectia Airlines",
            "code": "6V",
            "auxcode": "CZV",
            "companyName": "",
            "country": "Uruguay"
        },
        {
            "name": "Hebradran Air Services",
            "code": "",
            "auxcode": "HBR",
            "companyName": "Hebradran",
            "country": "United Kingdom"
        },
        {
            "name": "Pobeda",
            "code": "",
            "auxcode": "PBD",
            "companyName": "POBEDA",
            "country": "Russia"
        },
        {
            "name": "City Airways",
            "code": "E8",
            "auxcode": "GTA",
            "companyName": "CITY AIR",
            "country": "Thailand"
        },
        {
            "name": "Norwegian Long Haul AS",
            "code": "DU",
            "auxcode": "NLH",
            "companyName": "NORSTAR",
            "country": "Norway"
        },
        {
            "name": "BA101",
            "code": "",
            "auxcode": "710",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "TransNusa Air",
            "code": "M8",
            "auxcode": "TNU",
            "companyName": "TRANSNUSA",
            "country": "Indonesia"
        },
        {
            "name": "Tomp Airlines",
            "code": "ZT",
            "auxcode": "T9P",
            "companyName": "",
            "country": "Chile"
        },
        {
            "name": "Fuzhou Airlines",
            "code": "",
            "auxcode": "FZA",
            "companyName": "",
            "country": "China"
        },
        {
            "name": "Sky Angkor Airlines (ZA)",
            "code": "",
            "auxcode": "SWM",
            "companyName": "SKY ANGKOR",
            "country": "Cambodia"
        },
        {
            "name": "OneJet",
            "code": "J1",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Global Airlines",
            "code": "0G",
            "auxcode": "GA0",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Mann Yadanarpon Airlines",
            "code": "",
            "auxcode": "MYP",
            "companyName": "MANN ROYAL",
            "country": "Burma"
        },
        {
            "name": "International Flying Service",
            "code": "F4",
            "auxcode": null,
            "companyName": "",
            "country": "Italy"
        },
        {
            "name": "Air Vistara",
            "code": "UK",
            "auxcode": "VTI",
            "companyName": "",
            "country": "India"
        },
        {
            "name": "TransRussiaAirlines",
            "code": "1E",
            "auxcode": "RGG",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Severstal Air Company",
            "code": "D2",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "REXAIR VIRTUEL",
            "code": "RR",
            "auxcode": "RXR",
            "companyName": "Rexair",
            "country": "France"
        },
        {
            "name": "WestJet Encore",
            "code": "WR",
            "auxcode": "WEN",
            "companyName": "Encore",
            "country": "Canada"
        },
        {
            "name": "Air Pegasus",
            "code": "OP",
            "auxcode": "PPL",
            "companyName": "",
            "country": "India"
        },
        {
            "name": "International Europe",
            "code": "9I",
            "auxcode": "INE",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Sky Regional Airlines",
            "code": "",
            "auxcode": "SKV",
            "companyName": "Maple",
            "country": "Canada"
        },
        {
            "name": "V Air",
            "code": "ZV",
            "auxcode": "VAX",
            "companyName": "",
            "country": "Taiwan"
        },
        {
            "name": "Cello Aviation",
            "code": "",
            "auxcode": "CLJ",
            "companyName": "CELLOJET",
            "country": "United Kingdom"
        },
        {
            "name": "Naka Nihon Airlines Service",
            "code": "NV",
            "auxcode": "ALS",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Pouya Air",
            "code": "",
            "auxcode": "PYA",
            "companyName": "",
            "country": "Iran"
        },
        {
            "name": "Islands Express",
            "code": "X9",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Atlantic Air Services",
            "code": "X7",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Boutique Air (Priv)",
            "code": "4B",
            "auxcode": "BTQ",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "FlyEgypt",
            "code": "",
            "auxcode": "FEG",
            "companyName": "",
            "country": "Egypt"
        },
        {
            "name": "VOLOTEA Airways",
            "code": "V7",
            "auxcode": "VOE",
            "companyName": "",
            "country": "Spain"
        },
        {
            "name": "Aguilar Connect",
            "code": "3X",
            "auxcode": "GUI",
            "companyName": "Moonexpress",
            "country": "Chile"
        },
        {
            "name": "ROYAL BRITAIN",
            "code": "7V",
            "auxcode": "ROB",
            "companyName": "",
            "country": "United Kingdom"
        },
        {
            "name": "INAVIA Internacional",
            "code": "Z5",
            "auxcode": "IIR",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Liberty Airways",
            "code": "LE",
            "auxcode": "LTY",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Аэросервис",
            "code": "АЯ",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Aeronautica Militare",
            "code": "",
            "auxcode": "IAM",
            "companyName": "Italian Airforce",
            "country": "Italy"
        },
        {
            "name": "Bassaka airlines",
            "code": "5B",
            "auxcode": "BSX",
            "companyName": "5B",
            "country": "Cambodia"
        },
        {
            "name": "Jetstar Japan ",
            "code": "",
            "auxcode": "JJP",
            "companyName": "ORANGE LINER",
            "country": "Japan"
        },
        {
            "name": "SkyBahamas Airlines",
            "code": "Q7",
            "auxcode": null,
            "companyName": "TROPICAL SKY",
            "country": "Bahamas"
        },
        {
            "name": "UVT Aero",
            "code": "UW",
            "auxcode": null,
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "First Flying",
            "code": "",
            "auxcode": "DAK",
            "companyName": "",
            "country": "Japan"
        },
        {
            "name": "Silver Airways (3M)",
            "code": "3M",
            "auxcode": null,
            "companyName": "Silver Wings",
            "country": "United States"
        },
        {
            "name": "AtlasGlobal Ukraine",
            "code": "",
            "auxcode": "UJX",
            "companyName": "Atlas Ukraine",
            "country": "Ukraine"
        },
        {
            "name": "Boliviana de Aviacion (OB)",
            "code": "",
            "auxcode": "BOV",
            "companyName": "BOLIVIANA",
            "country": "Bolivia"
        },
        {
            "name": "UScodeBangla Airlines",
            "code": "",
            "auxcode": "UBG",
            "companyName": "",
            "country": "Bangladesh"
        },
        {
            "name": "Norwegian Air International (D8)",
            "code": "",
            "auxcode": "IBK",
            "companyName": "NORTRANS",
            "country": "Norway"
        },
        {
            "name": "ATA Airlines (Iran)",
            "code": "I3",
            "auxcode": null,
            "companyName": "ATALAR",
            "country": "Iran"
        },
        {
            "name": "VIA Líneas Aéreas",
            "code": "V1",
            "auxcode": "VIA",
            "companyName": "",
            "country": "Argentina"
        },
        {
            "name": "Palair Macedonia",
            "code": "3D",
            "auxcode": "PMK",
            "companyName": "",
            "country": "Macedonia"
        },
        {
            "name": "Palair Macedonia (3D)",
            "code": "3D",
            "auxcode": null,
            "companyName": "",
            "country": "Macedonia"
        },
        {
            "name": "GermanXL",
            "code": "GX",
            "auxcode": "GXG",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Alghanim",
            "code": "",
            "auxcode": "KYA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Indonesa Air Aisa X",
            "code": "",
            "auxcode": "IDX",
            "companyName": "Red Phoenix",
            "country": "Indonesia"
        },
        {
            "name": "Fly France",
            "code": "FF",
            "auxcode": "FRF",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "Jota Aviation",
            "code": "",
            "auxcode": "ENZ",
            "companyName": "ENZO",
            "country": "United Kingdom"
        },
        {
            "name": "Europe Jet",
            "code": "EX",
            "auxcode": "EU9",
            "companyName": "",
            "country": "France"
        },
        {
            "name": "COBALT",
            "code": "",
            "auxcode": "FCB",
            "companyName": "COBALT",
            "country": "Cyprus"
        },
        {
            "name": "Southern Airways Express",
            "code": "",
            "auxcode": "LTD",
            "companyName": "LIGHTSPEED",
            "country": "United States"
        },
        {
            "name": "Deutsche Luftverkehrsgesellschaft (DLT)",
            "code": "DW",
            "auxcode": "DWD",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Orenburzhie",
            "code": "",
            "auxcode": "ORG",
            "companyName": "",
            "country": "Russia"
        },
        {
            "name": "Atlantic Air Cargo",
            "code": "K3",
            "auxcode": null,
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "World Scale Airlines",
            "code": "W3",
            "auxcode": "WSS",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "All America US",
            "code": "AG",
            "auxcode": "SSA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "University of Birmingham Air Squadron (RAF)",
            "code": "",
            "auxcode": "UAY",
            "companyName": "UAY",
            "country": "United Kingdom"
        },
        {
            "name": "Choice Airways",
            "code": "",
            "auxcode": "CSX",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Aruba Airlines",
            "code": "",
            "auxcode": "ARU",
            "companyName": "",
            "country": "Aruba"
        },
        {
            "name": "BudgetAir",
            "code": "1K",
            "auxcode": "BG1",
            "companyName": "",
            "country": "Germany"
        },
        {
            "name": "Dynamic Airways",
            "code": "",
            "auxcode": "DYA",
            "companyName": "",
            "country": "United States"
        },
        {
            "name": "Fly One",
            "code": "F5",
            "auxcode": "FI5",
            "companyName": "",
            "country": "Moldova"
        },
        {
            "name": "Nordica",
            "code": "EE",
            "auxcode": null,
            "companyName": "REVAL",
            "country": "Estonia"
        },
        {
            "name": "Tez Jet Airlines",
            "code": "",
            "auxcode": "TEZ",
            "companyName": "",
            "country": "Kyrgyzstan"
        },
        {
            "name": "Kush Air",
            "code": "",
            "auxcode": "KUH",
            "companyName": "",
            "country": "South Sudan"
        },
        {
            "name": "Dummy",
            "code": "0E",
            "auxcode": null,
            "companyName": "",
            "country": "France"
        },
        {
            "name": "ViaAir",
            "code": "",
            "auxcode": "SRY",
            "companyName": "Stingray",
            "country": "United States"
        },
        {
            "name": "All America BOPY",
            "code": "0P",
            "auxcode": "PYB",
            "companyName": "BOPY",
            "country": "Paraguay"
        },
        {
            "name": "Thai Vietjet Air",
            "code": "",
            "auxcode": "TVJ",
            "companyName": "THAIVIET JET",
            "country": "Thailand"
        },
        {
            "name": "Air Andaman (2Y)",
            "code": "2Y",
            "auxcode": "AOW",
            "companyName": "AIR ANDAMAN",
            "country": "Thailand"
        },
        {
            "name": "TDA Toa Domestic Airlines",
            "code": "",
            "auxcode": "TDA",
            "companyName": "Toa Domes",
            "country": "Japan"
        },
        {
            "name": "GX Airlines",
            "code": "",
            "auxcode": "CBG",
            "companyName": "SPRAY",
            "country": "China"
        },
        {
            "name": "Lynx Aviation (L3/SSX)",
            "code": "",
            "auxcode": "SSX",
            "companyName": "Shasta",
            "country": "United States"
        },
        {
            "name": "Jetgo Australia",
            "code": "JG",
            "auxcode": null,
            "companyName": "",
            "country": "Australia"
        },
        {
            "name": "Air Carnival",
            "code": "2S",
            "auxcode": null,
            "companyName": "",
            "country": "India"
        },
        {
            "name": "Svyaz Rossiya",
            "code": "7R",
            "auxcode": "SJM",
            "companyName": "RussianConnecty",
            "country": "Russia"
        }
    ]
};
