import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FerryBookingComponent } from './ferry-booking.component';
import { Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';

const routes: Routes = [
    {
        path: 'create',
        component: FerryBookingComponent,
    },
    {
        path: 'edit/:id',
        component: FerryBookingComponent,
    }
];

@NgModule({
    declarations: [FerryBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
    ],
    exports: [FerryBookingComponent]
})
export class FerryBookingModule {
}
