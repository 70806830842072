import {Component, Input, OnInit} from '@angular/core';
import { BookEditComponent } from '../../book-edit.component';
import { InsurancesBookServices } from '../services/insurances-book.services';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { TecnoturisService } from '../../../tecnoturis.service';
import { Client } from '../../../lib-shared/interfaces/index';
import { AuthenticationService, BasketManager, ClientServices, NotificationPopupService } from '../../../lib-shared/services/index';
import { AgencyChangeService } from '../../../agency-change/index';
import { BaseEntity } from '../../../lib-shared/Entities/index';
import { ROLES } from '../../../const';
import { safeNumber } from '../../../lib-shared/functions/index';

@Component({
    selector: 'lib-insurances-book-edit',
    templateUrl: './insurances-book-edit.component.html',
    styleUrls: ['./insurances-book-edit.component.scss'],
    providers: [
        InsurancesBookServices,
        ClientServices
    ]
})
export class InsurancesBookEditComponent extends BookEditComponent implements OnInit {

    insuranceData: any;
    nationalities: any;
    newClient: boolean;
    insuranceFormItem: FormGroup;
    documentTypes = [
        {value: 'NIF'},
        {value: 'IT/CF'},
        {value: 'CIF'},
        {value: 'NIE'},
        {value: 'Comunitario'},
        {value: 'Resto del mundo'},
        {value: 'Menor sin NIF'}
    ];
    toppingsDefault = [];
    totalPrice: number;
    arrForDistribution = [];
    checkTopping;
    container;
    auth = AuthenticationService.getUser();

    constructor(
        protected _insurancesBookServices: InsurancesBookServices,
        private activatedRoute: ActivatedRoute,
        protected _basketManager: BasketManager,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected location: Location,
        protected notification: NotificationPopupService,
        protected agencyChangeService: AgencyChangeService,
        protected tecnoturisService: TecnoturisService,
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
        this.baseEntity = new BaseEntity(this.http, tecnoturisService);
        this.newClient = false;
    }

    ngOnInit() {
        [this.existingClientForm, this.newClientForm, this.clientList$] = this.clientServices.initClientsForm();
        this.clientList$ = this.setClientListenerForAutocomplete(this.existingClientForm);
        const nationalitiesEntity = this.baseEntity.getEntity(this.tecnoturisService.config.nationalityHost, 'nationality');
        nationalitiesEntity.list(1, 300, null, {sortField: 'name', sortDirection: 'ASC'}).subscribe(nationalities => {
            this.nationalities = nationalities.rows;
            // todas las nacionalidades posibles
            this._insurancesBookServices.nationalities = this.nationalities;
            this.newClientForm.controls['nationality'].setValue(this.nationalities[207].code);
        });
        this.activatedRoute.paramMap
            .pipe(map(() => window.history.state))
            .subscribe(state => {
                if (state.insurance) {
                    this._insurancesBookServices.insurance = state.insurance;
                    this.initForm();
                }
            });
        const uuid = this.activatedRoute.snapshot.params.id;

        this._basketManager.getList().then(data => {
            const insurance = data.find(item => item.uuid === uuid);
            if (insurance) {
                this._insurancesBookServices.insurance = { ...insurance, integrationType: 'INSURANCE' };
                this.initForm();
                if (this.insuranceData.insuranceBookingPostReqDto.toppingsExtendable) {
                    this.addResumeToppings();
                }
            } else {
                if (this.insuranceData.insurance.toppings) {
                    this.checkToppings();
                    this.selectToppingsDefault();
                }
            }
            this.arrForDistribution = this._insurancesBookServices.generateArray();
        });
    }

    get insuredFormArray(): FormArray {
        return (this.insuranceFormItem.get('insureds') as FormArray);
    }

    addResumeToppings(): void {
        this.toppingsDefault = this.insuranceData.insuranceBookingPostReqDto.toppingsExtendable;
    }

    checkToppings(): void {
        for (const topping of this.insuranceData.insurance.toppings) {
            if (topping.valores.length === 1) {
                const obj = {
                    prima: 0,
                    limite: 'Ninguno',
                };
                topping.valores.push(obj);
            }
        }
    }

    selectToppingsDefault(): void {
        for (const topping of this.insuranceData.insurance.toppings) {
            for (const value of topping.valores) {
                if (value.prima === 0) {
                    value.checked = true;
                    if (value.prima === 0 && value.limite !== 'Ninguno') {
                        this.addToppingsDefaultBooking(topping, value);
                    }
                }
            }
        }
    }

    addToppingsDefaultBooking(topping, value): void {
        const obj = {
            id_cruce: topping.id_cruce,
            id_poliza: topping.id_poliza,
            tipo: topping.tipo,
            nombre: topping.nombre,
            valores: [
                {
                    id: value.id,
                    limite: value.limite,
                    prima: value.prima
                }
            ]
        };
        this.toppingsDefault.push(obj);
        this._insurancesBookServices.insurance.insurance.totalPrice = this._insurancesBookServices.insurance.insurance.totalPrice
            + value.prima;
        this.insuranceData.insurance.toppingsPrice = value.prima;
        this._insurancesBookServices.insurance.insurance.toppingsPrice = value.prima;
        this._insurancesBookServices.insurance.insurance.toppingsExtendable = this.toppingsDefault;
    }

    initForm(): void {
        this.insuranceData = this._insurancesBookServices.insurance;
        if (this._insurancesBookServices.insurance.insuranceBookingPostReqDto) {
            this.totalPrice = safeNumber(this._insurancesBookServices.insurance.insurance.commission.pvp) +
                safeNumber(this._insurancesBookServices.insurance.insurance.toppingsPrice);
        } else {
            this.totalPrice = this._insurancesBookServices.insurance.insurance.totalPrice;
        }
        this.insuranceFormItem = this._insurancesBookServices.initInsuredsForm();

        this.clientService.subscription
            .pipe(filter(client => client && !(Object.keys(client).length === 0) && client.isPax))
            .subscribe((client: Client) => {
                if (client && !(Object.keys(client).length === 0)) {
                    const occupants = ((this.insuranceFormItem.get('insureds') as FormArray)).at(0);
                    if (occupants.get('name') && occupants.get('name').value) {
                        occupants.get('name').setValue(client.name);
                        occupants.get('surname').setValue(client.surname);
                        occupants.get('documentNumber').setValue(client.document);
                    }
                }
            });
    }

    goToSummaryOrConfirmation(): void {
        if (super.checkClient()) {
            this._insurancesBookServices.goToSummaryOrConfirmation(this.microsite);
        }
    }

    addToBasketAndRedirect(): void {
        if (super.checkClient()) {
            this._insurancesBookServices.addToBasketAndRedirect(this.microsite);
        }
    }

    onChangeToppingsExtendable(value, topping): void {
        const toppingFind: any = this.toppingsDefault.filter(toppingSearched => {
            return toppingSearched.id_cruce === topping.id_cruce;
        });
        if (this.toppingsDefault.length === 0) {
            const objTopping = {
                id_cruce: topping.id_cruce,
                id_poliza: topping.id_poliza,
                tipo: topping.tipo,
                valores: [value],
                nombre: topping.nombre
            };
            this.toppingsDefault.push(objTopping);
        }

        this.toppingsDefault.forEach(element => {
            if (element.id_cruce === topping.id_cruce) {
                this.checkTopping = topping.id_cruce;
                element.valores.forEach(elemV => {
                    if (value.limite !== 'Ninguno') {
                        if (elemV.id !== value.id) {
                            elemV = {
                                id: value.id,
                                limite: value.limite,
                                prima: value.prima,
                                nombre: topping.nombre
                            };
                            element.valores = [elemV];
                        }
                    } else {
                        this.deleteTopping(topping.id_cruce);
                    }
                });
            } else {
                const objTopping = {
                    id_cruce: topping.id_cruce,
                    id_poliza: topping.id_poliza,
                    tipo: topping.tipo,
                    valores: [value],
                    nombre: topping.nombre,
                };
                if (toppingFind.length === 0) {
                    this.checkTopping = topping.id_cruce;
                    this.toppingsDefault.push(objTopping);
                }
            }
        });

        this.sumePrice();

        this.insuranceData.insurance.toppings.forEach(toppingT => {
            topping.valores.forEach(valueT => {
                if (toppingT.id_cruce === topping.id_cruce) {
                    if (valueT.checked === true) {
                        valueT.checked = false;
                    }
                }
                if (valueT.id === value.id) {
                    valueT.checked = true;
                }
            });
        });
    }

    deleteTopping(toppingId) {
        this.toppingsDefault = this.toppingsDefault.filter(e => {
            return e.id_cruce !== toppingId;
        });
    }

    sumePrice(): void {
        let sumePricesToppings = 0;
        for (const topping of this.toppingsDefault) {
            for (const value of topping.valores) {
                sumePricesToppings += value.prima;
            }
        }
        if (this.insuranceData.query.insureds > 1 && this.insuranceData.insurance.insuranceRate === true) {
            const multiply = sumePricesToppings * this.insuranceData.query.insureds;
            sumePricesToppings = multiply;
        }
        this.totalPrice = this.insuranceData.insurance.totalPrice + sumePricesToppings;
        this._insurancesBookServices.insurance.insurance.toppingsPrice = sumePricesToppings;
        this._insurancesBookServices.insurance.insurance.toppingsExtendable = this.toppingsDefault;
    }

    getDateCorrectFormat(date) {
        const newDate = new Date(date);
        const options = {day: 'numeric', month: 'numeric', year: 'numeric'};
        return newDate.toLocaleDateString("es-ES", options);
    }

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }
}
