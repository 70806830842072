import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FlightSearchDto } from '../flight-search.component';

@Component({
    selector: 'lib-flight-search-container',
    templateUrl: './flight-search-container.component.html',
    styleUrls: ['./flight-search-container.component.scss']
})
export class FlightSearchContainerComponent implements OnInit, OnDestroy {
    tabs = [
        {
            slug: 'flights',
            name: 'Ida y vuelta'
        },
        {
            slug: 'multiflights',
            name: 'Multivuelos'
        },
    ];

    selectedTab: number;

    tabControl: FormControl = new FormControl('vuelos');

    @Input() isSmall = false;
    @Input() inputFields: FlightSearchDto;
    @Input() parsedOrigin: any;
    @Input() parsedDestination: any;
    @Input() routerPath = '/integration/flights/search';
    @Input() microsite = false;
    @Input() showTourIncluded = true;
    @Input() lowCostChecked = true;

    @Output() dataChanged = new EventEmitter<any>(); // TODO: Define type

    unsubscribe = new Subject();

    constructor(private router: Router, private route: ActivatedRoute) {
        this.route.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                if (params['type']) {
                    this.tabControl.setValue(params['type']);
                } else {
                    this.tabControl.setValue('flights');
                }
            });
    }

    ngOnInit() {
    }

    handleTab(tab): void {
        this.selectedTab = tab.index;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
