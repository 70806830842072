import { AfterContentInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SearchNavbarService } from '../../../lib-shared/services/index';


@Component({
    selector: 'lib-search-navbar',
    templateUrl: './search-navbar.component.html',
    styleUrls: ['./search-navbar.component.scss'],
    animations: [
        trigger('scrollAnimation', [
            state('show', style({
                opacity: 1,
                height: '129px',
                transform: "translateY(0)"
            })),
            state('hide', style({
                opacity: 0,
                height: '0',
                transform: "translateY(-100%)"
            })),
            transition('show => hide', animate('500ms ease-out')),
            transition('hide => show', animate('500ms ease-in'))
        ])
    ]
})
export class SearchNavbarComponent implements OnInit, AfterContentInit {
    @Input() searchCount: number;
    @Input() page: string;
    backgroundImage: string;
    state = 'hide';

    constructor(
        public el: ElementRef,
        public navbarService: SearchNavbarService,
        private render: Renderer2) {
    }

    ngOnInit() {
        this.navbarService.getHideProperty().subscribe(isVisible => {
            this.state = isVisible ? this.state = 'show' : this.state = 'hide';
        });
    }

    ngAfterContentInit(): void {
        switch (this.page) {
            case "circuitos":
                this.backgroundImage = "assets/images/cabecera_listadecircuitos.jpeg";
                break;
            case "hotels":
                this.backgroundImage = "assets/images/cabecera_listadehoteles.jpeg";
                break;
            case "basket":
                this.backgroundImage = "assets/images/cabecera_carrito.jpeg";
                break;
            case "flights":
                this.backgroundImage = "assets/images/cabecera_vuelos.jpg";
                break;
            case "packages":
                this.backgroundImage = "assets/images/cabecera_paquetes.jpg";
                break;
            case "activities":
                this.backgroundImage = "assets/images/cabecera_actividades.jpg";
                break;
            case "transportes":
                this.backgroundImage = "assets/images/cabecera_transportes.jpg";
                break;
            default:
                this.backgroundImage = "assets/images/cabecera_carrito.jpeg";
                break;
        }
    }
}
