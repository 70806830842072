import { Component } from '@angular/core';

@Component({
    selector: 'lib-transport-search',
    templateUrl: './transport-search.component.html',
    styleUrls: ['./transport-search.component.scss'],
})
export class TransportSearchComponent {

    tabs = [{name: 'Trenes'}, {name: 'Buses'}, {name: 'Coches'}, {name:'Traslados'}];
    currentTab = 0;

    handleTab(event) {
        this.currentTab = event.index;
    }
}

