import { FieldType, IDynamicCrud } from '../../lib-shared/interfaces/index';

export const activityFormMetaData: IDynamicCrud = {
    crud: 'activity-booking',
    name: 'Reserva',
    urlPath: 'booking-service/',
    urlPathList: 'clients/dossier/id/activity-booking/',
    extraKeySave: ['activities'],
    crudFiles: {
        path: 'booking-service/files/',
        urlPathList: 'booking-service/id/files/',
        key: 'booking_service',
    },
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: 'Datos generales',
            fieldChilds: [
                {
                    keyName: 'code',
                    name: 'N° Servicio',
                    type: FieldType.CHAR,
                    removeOnCreate: true,
                    readonly: true
                },
                {
                    keyName: 'confirmed_by',
                    name: 'Confirmado por',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'locator',
                    name: 'Localizador',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'confirmed_date',
                    name: 'Fecha de confirmación',
                    type: FieldType.DATE,
                    showInTable: true
                },
                {
                    keyName: 'fiscal_location',
                    name: 'Localización fiscal (destino)',
                    type: FieldType.LIST,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'description',
                    name: 'Descripción',
                    type: FieldType.CHAR,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos del proveedor',
            fieldChilds: [
                {
                    keyName: 'provider_accountant_account',
                    name: 'Cuenta contable',
                    type: FieldType.CHAR,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'provider',
                    name: 'Nombre del proveedor',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'payment_method',
                    name: 'Forma de Pago',
                    type: FieldType.LIST,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'payday_limit',
                    name: 'Fecha límite de pago',
                    type: FieldType.DATE,
                    disabled: false
                },
                {
                    keyName: 'product_type',
                    name: 'Tipo de producto',
                    type: FieldType.LIST
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos de comisiones',
            fieldChilds: [
                {
                    keyName: 'fee_apply',
                    name: 'Tipo comisión',
                    type: FieldType.LIST
                },
                {
                    keyName: 'comission',
                    name: '% Comisión',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 100
                },
                {
                    keyName: 'iva',
                    name: 'IVA',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva',
                    name: 'IVA de Compra',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva_pay',
                    name: 'IVA de Venta',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'pvp_fee',
                    name: 'Importe Comisionable',
                    type: FieldType.NUMBER,
                    min: 0,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'not_fee_amount',
                    name: 'Importe no comisionable (tasas)',
                    type: FieldType.NUMBER,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'total_pvp',
                    name: 'Total PVP',
                    type: FieldType.NUMBER,
                    readonly: true
                },
                {
                    keyName: 'discount',
                    name: 'Descuentos',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'fee',
                    name: 'Fee Agencia',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'total_net',
                    name: 'Total Neto a pagar',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'profit_gross',
                    name: 'Margen Bruto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'profit',
                    name: 'Margen Neto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones generales',
            fieldChilds: [
                {
                    keyName: 'general_observation',
                    name: '',
                    type: FieldType.TEXTAREA
                }
            ]
        }
    ],
    dynamicCruds: [
        {
            crud: 'activity-travelers',
            name: 'Pasajeros',
            urlPath: 'booking-service/pax/',
            extractDataFrom: 'pax',
            extractIsArray: false,
            appendKey: 'booking_service',
            fields: [
                {
                    isGroup: true,
                    keyName: '',
                    name: 'Pasajeros',
                    fieldChilds: [
                        {
                            keyName: 'name',
                            name: 'Nombre',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'last_name',
                            name: 'Apellidos',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                    ]
                }
            ],
        },
        {
            crud: 'activity-segments',
            name: 'Actividades',
            urlPath: 'booking-service/activity/',
            extractIsArray: true,
            extractDataFrom: 'activity_booking',
            fields: [
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: []
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: 'Actividad',
                    fieldChilds: [
                        {
                            keyName: 'name',
                            name: 'Nombre',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'modality_name',
                            name: 'Tipo Modalidad',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'rate_code',
                            name: 'Tipo Tarifa',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                    ]
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: [
                        {
                            keyName: 'date_from',
                            name: 'Desde',
                            type: FieldType.DATE,
                            showInTable: true
                        },
                        {
                            keyName: 'date_to',
                            name: 'Hasta',
                            type: FieldType.DATE,
                            showInTable: true
                        }
                    ]
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: [
                        {
                            keyName: 'activity_booking_service',
                            name: '',
                            hidden: true,
                            type: FieldType.CHAR
                        }
                    ]
                },
            ],
        }
    ]
};
