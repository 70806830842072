import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, of } from "rxjs"
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { first, tap } from "rxjs/operators"
import {
  packageDestinationDisney,
  packageParameters,
  PackageNameEnum,
  DestinationType,
} from "../../../mock/packages"
import { FLIGHTS_AIRPORTS } from "../../../mock/flights"
import * as uuidv1_ from "uuid/v1"
import * as moment_ from "moment"
import { ClientServices } from "./client.services"
import { ManagementService } from "./management.service"
import { TecnoturisService } from "../../tecnoturis.service"
import {
  DestinationRequest,
  ReferencePricesRequest,
  PackagesRequest,
  PackagesDetailsRequest,
  PreBookingsRequest,
  BookingsRequest,
  ShoppingBasketPackage,
  IntegrationTypeEnum,
  ResponseData,
  PackagePax,
} from "../interfaces/index"
import { BasketManager } from "./BasketManager"
import { IntegrationsService } from "./integrations.service"
import { PackageVacationPackageBooking } from "@vecib2c/frontend-dto"
import { AuthenticationService } from "./authentication.service"

const moment = moment_
const uuidv1 = uuidv1_

@Injectable({
  providedIn: "root",
})
export class PackagesProviderService {
  language = "ES"
  packageSession$: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  constructor(
    private http: HttpClient,
    private basketManager: BasketManager,
    private clientService: ClientServices,
    private managementService: ManagementService,
    private tecnoturisService: TecnoturisService,
    private integrationsService: IntegrationsService
  ) {}
  static generateResponseCalendar(packageName, rows: any[]): any {
    let list = []
    if (packageName === "DISNEY") {
      list = rows.map((r) => {
        const row = <any>{}
        row.fromDate = moment(r.fromDate, "DD/MM/YYYY").format("L")
        row.referencePricePerPassenger = 0
        row.season = r.season
        return row
      })
    } else {
      list = rows
    }
    return list
  }

  static validateProvider(name: string): boolean {
    const nameFinal = name.trim().toLowerCase()
    return nameFinal.indexOf("disney") > -1
  }

  private getPath(action: string, id?: any) {
    const host = this.tecnoturisService.config["packages"]["providers"]
    const list = [`${host}`, "api/v1", action]
    if (id) {
      list.push(`${id}`)
    }
    return list.join("/")
  }

  list(parameters: PackagesRequest): Observable<any> {
    let params: HttpParams = new HttpParams()
    const headers = new HttpHeaders().set(
      "authorization",
      AuthenticationService.getToken()
    )
    Object.keys(parameters).forEach(function (key) {
      params = params.append(key, parameters[key])
    })
    const path = this.getPath("packages")
    return this.http.get<any>(path, { params: params, headers })
  }

  get(parameters: PackagesDetailsRequest, packageId: string): Observable<any> {
    parameters.roomId = parameters.roomId || " "
    const headers = new HttpHeaders().set(
      "authorization",
      AuthenticationService.getToken()
    )

    let params: HttpParams = new HttpParams()
    Object.keys(parameters).forEach(function (key) {
      params = params.append(key, parameters[key])
    })

    const path = this.getPath("packages", packageId)

    return this.http.get<any>(path, { params: params, headers })
  }

  getOtherFlights(reqParams: { providerToken: string }): Observable<any> {
    let params: HttpParams = new HttpParams()
    Object.keys(reqParams).forEach(function (key) {
      params = params.append(key, reqParams[key])
    })
    // This endpoint works for Disney
    const host = this.tecnoturisService.config["packages"]["disney"]
    const url = host + "/api/v1/packages/listOtherFlights"
    return this.http.get<any>(url, { params: params })
  }

  updatePackage(hotel: any): Observable<any> {
    // This endpoint works for Disney
    const host = this.tecnoturisService.config["packages"]["disney"]
    const url = host + "/api/v1/packages/updatePackage"
    return this.http.post<any>(url, hotel)
  }

  getDestinations(packageName: PackageNameEnum, request: DestinationRequest) {
    let params: HttpParams = new HttpParams()
    let response$: Observable<any>
    let path = ""

    switch (packageName) {
      case PackageNameEnum.DISNEY:
        response$ = of({
          rows: packageDestinationDisney,
          count: packageDestinationDisney.length,
        })
        break
      default:
        path = this.getPath("destinations")
        Object.keys(request).forEach(function (key) {
          params = params.append(key, request[key])
        })
        response$ = this.http.get<any>(path, { params: params })
    }
    return response$
  }

  getReferencePrices(request: ReferencePricesRequest): Observable<any> {
    let params: HttpParams = new HttpParams()
    Object.keys(request).forEach(function (key) {
      params = params.append(key, request[key])
    })
    const path = this.getPath("referencePrices")

    return this.http.get<any>(path, { params: params })
  }

  getPackageSession(): Observable<any> {
    return this.packageSession$.asObservable().pipe(first())
  }

  setPackageSession(params: any): void {
    params.uuid = uuidv1()
    localStorage.setItem("session-packages", JSON.stringify(params))
    this.packageSession$.next(params)
  }

  preBookTickets(
    reqParams: any,
    packageType: string,
    parameters: any
  ): Observable<any> {
    let params: HttpParams = new HttpParams()
    Object.keys(parameters).forEach(function (key) {
      params = params.append(key, parameters[key])
    })
    // This endpoint works for Disney
    const host = this.tecnoturisService.config["packages"]["disney"]
    const url = host + "/api/v1/pre-booking-tickets"
    return this.http.post<any>(url, reqParams, { params: params })
  }

  preBook(
    parameters: PreBookingsRequest
  ): Observable<ResponseData<PackageVacationPackageBooking>> {
    const url = this.getPath("pre-bookings")
    return this.http.post<any>(url, parameters)
  }

  async book(
    parameters: BookingsRequest,
  ): Promise<ResponseData<PackageVacationPackageBooking | any>> {
    const url = this.getPath("bookings")
    return await this.http.post<any>(url, parameters).toPromise()
    
    /*.pipe(
      tap(async (response) => {
        if (saveBudget) {
          const client = await this.clientService.getClient()
          const dossier = this.integrationsService.validAndGetDossier()
          const data = {
            packageData: [response.data],
            client: client.id,
            dossier: dossier,
          }

          await this.managementService.addServicesToDossier(data).toPromise()
        }
      })
    )*/
  }

  getBooking(bookUUID: string) {
    const url = this.getPath("bookings") + `/getBooking?bookUUID=${bookUUID}`
    console.log(url);
    
    return this.http.get<any>(url)
  }

  /*bookFromBasket(item: ShoppingBasketPackage): Promise<ShoppingBasketPackage> {
    const params = {
      requestToken: item.requestToken,
      providerToken: item.providerToken,
      paxes: item.paxes,
      packageClient: item.packageClient,
      BookingData: item,
    }
    return this.book(params)
      .toPromise()
      .then((data) => {
        if (data.status == 200) {
          item.failed = false
          item.bookId = data.data.bookId
          item.passengers = data.data.passengers
          item = { ...item, ...data }
          return Promise.resolve(item)
        } else {
          item = { ...item, error: data.data.error, failed: true }
          return Promise.resolve(item)
        }
      })
      .catch((err) => {
        item.failed = true
        return Promise.reject(item)
      })
  }*/

  getParams(event): HttpParams {
    let query = new HttpParams()

    packageParameters.forEach((parameter) => {
      if (event[parameter]) {
        query = query.set(
          parameter,
          parameter === "filter" ||
            parameter === "distribution" ||
            parameter === "rooms" ||
            parameter === "origin" ||
            parameter === "options" ||
            parameter === "options_combo"
            ? JSON.stringify(event[parameter])
            : event[parameter]
        )
      }
    })

    return query
  }

  parseDto(query) {
    const packageListReq = {}
    Object.keys(query).forEach((key) => {
      packageListReq[key] =
        key === "distribution" ||
        key === "filter" ||
        key === "origin" ||
        key === "options" ||
        key === "options_combo"
          ? JSON.parse(query[key])
          : query[key]
    })
    return packageListReq
  }

  buildDto(event) {
    const query = <any>{}
    packageParameters.forEach((parameter) => {
      if (event[parameter]) {
        query[parameter] =
          parameter === "filter" ||
          parameter === "distribution" ||
          parameter === "rooms" ||
          parameter === "options" ||
          parameter === "options_combo"
            ? JSON.stringify(event[parameter])
            : parameter === "origin"
            ? event[parameter].iata
            : event[parameter]
      }
    })
    return query
  }

  public getAirportInfo(key: string) {
    return FLIGHTS_AIRPORTS[key]
  }

  transformPackageNameToDestinationType(
    packageName: PackageNameEnum
  ): DestinationType {
    if (packageName === PackageNameEnum.CARIBBEAN) {
      return DestinationType.CARIBBEAN
    }
    if (packageName === PackageNameEnum.ISLAND) {
      return DestinationType.ISLAND
    }
    return DestinationType.DISNEY
  }
}
