import { Component, Input, OnInit } from "@angular/core";
import { ShoppingBasketActivity } from "../../../lib-shared/interfaces/index";

@Component({
    selector: 'lib-activity-book-summary',
    templateUrl: './activity-book-summary.component.html',
    styleUrls: ['./activity-book-summary.component.scss'],
})

export class ActivityBookSummaryComponent implements  OnInit {

    @Input() shoppingBasket: ShoppingBasketActivity;

    constructor() {
    }
    ngOnInit(): void {
  
    
    }

    

}
