import { HttpParams } from '@angular/common/http';
import { Distribution } from '../interfaces/index';
import * as moment_ from 'moment';

const moment = moment_;

export class Utils {

    public static flatten(arr) {
        const res = arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? Utils.flatten(toFlatten) : toFlatten);
        }, []);
        return res;
    }

    public static isNumber(val): boolean {
        const regexNumber = /\d+/;
        return regexNumber.test(val);
    }

    public static objectToHttpParams(req, parseRooms = true): HttpParams {
        let params: HttpParams = new HttpParams();

        Object.keys(req).forEach(function (key) {
            const value = parseRooms ? key === 'rooms' || typeof req[key] === 'object' ? JSON.stringify(req[key]) : req[key] : req[key];
            params = params.append(key, value);
        });

        return params;
    }
}

export function safeNumber(value: string | number, defaultValue = 0): number {
  const sanitizedValue = (value + '').replace(',', '.').trim();
  return Number(sanitizedValue) || parseFloat(sanitizedValue) || defaultValue;
}

export function roundNumber(value: string | number, decimals = 2): number {
  const safeValue = safeNumber(value);
  return safeNumber(safeValue.toFixed(decimals));
}

export function encode(key: string): string {
  if (!key) { return null; }
  return btoa(key);
}

export function decode(key: string): string {
  try {
    return atob(key);
  } catch (e) {
    return null;
  }
}

export function randomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

export function splitDistributionsByPaxType(distributions: Distribution[]) {
  return distributions.reduce((acc, cur) => {

    acc.adults = acc.adults + cur.adults;
    let children = 0;
    let infants = 0;
    cur.children.forEach(child => {
      if (child > 1) {
        children += 1;
      } else {
        infants += 1;
      }
    });
    acc.children = acc.children + children;
    acc.infants = acc.infants + infants;
    return acc;
  }, { adults: 0, children: 0, infants: 0});
}

export function splitLastName(lastName: string): string[] {
  if (!lastName) { return ['', '']; }
  const splittedLastName = lastName.split(' ');
  if (splittedLastName.length >= 2) {
    return [splittedLastName[0], splittedLastName[1]];
  }
  return [splittedLastName[0] || '', ''];
}

export function calculateAge(birthdate: string, format = 'YYYY/MM/DD'): number {
  return moment().diff(moment(birthdate, format), 'years');
}
