import { Component, OnInit } from '@angular/core';
import { TecnoturisService } from "../../tecnoturis.service";
import { transferFormMetaData } from './transfer-booking.form';

@Component({
  selector: 'lib-transfer-booking',
  templateUrl: './transfer-booking.component.html',
  styleUrls: ['./transfer-booking.component.scss']
})
export class TransferBookingComponent implements OnInit {
    transferCrud = transferFormMetaData;
  

  constructor(private tecnoturisService: TecnoturisService) {
    if (this.tecnoturisService.config.forms.transferForm) {
      this.transferCrud = this.tecnoturisService.config.forms.transferForm;
    }
  }

  ngOnInit() {
  }

}
