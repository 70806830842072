import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'differentialAmount'
})
export class DifferentialAmountPipe implements PipeTransform {

    transform(value: string): string {
        const safeNumber = Number(value);
        if (!isNaN(safeNumber)) {
            return safeNumber === 0 ? '+0.00' : value;
        }
        return value;
    }
}
