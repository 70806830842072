import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FormlyForm } from '@ngx-formly/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynamicCrud } from '../../../lib-shared/interfaces/index';
import { clientCommercialActivitiesForm } from '../client-commercial-activities.form';
import { BaseAddEdit } from '../../../base-add-edit';
import { DynamicCrudService, ManagementService } from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-client-commercial-activities-add-edit',
    templateUrl: './client-commercial-activities-add-edit.component.html',
    styleUrls: ['./client-commercial-activities-add-edit.component.scss']
})
export class ClientCommercialActivitiesAddEditComponent extends BaseAddEdit implements OnInit, AfterViewInit {
    unsubscribe = new Subject();
    dynamicCrud: IDynamicCrud = clientCommercialActivitiesForm;

    client;

    disableRouteChange = true;

    @ViewChild(FormlyForm, { static: false }) public formlyForm: FormlyForm;

    // createInPathList = true;

    constructor(public dialogRef: MatDialogRef<ClientCommercialActivitiesAddEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                route: ActivatedRoute,
                router: Router,
                dynamicCrudService: DynamicCrudService,
                managementService: ManagementService) {
        super(route, router, dynamicCrudService, managementService);
        this.setup = data.setup;
        this.client = data.client;
        this.customObjectToSave = {client: this.client};
        if (data['id']) {
            this.id = data.id;
        }
        this.init();
    }

    ngAfterViewInit(): void {
        if (this.id) {
            setTimeout(() => {
                const opening_date = this.formlyForm.form.get('opening_date').value;
                this.formlyForm.form.get('schedule_notice').setValue(!!opening_date);
                this.model['schedule_notice'] = !!opening_date;
            });
        }
    }

    postSaveSuccess<T>(response: T): void {
        this.dialogRef.close(true);
    }

}
