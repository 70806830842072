import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import * as moment_ from 'moment';

const moment = moment_;

export enum ValidationsFields {
    EMAIL = 'email',
    NIF = 'nif',
    NIE = 'nie',
    CIF = 'cif',
    MINLENGTH = 'minlength',
    MAXLENGTH = 'maxlength',
    BIRTHDATE = 'birthdate',
    POSTALCODE = 'postalcode',
    PHONE = 'phone',
    DATE = 'date',
    BEGINDATE = 'begindate',
    ENDDATE = 'enddate',
    NUMBER = 'number',
    STRING = 'string',
    BANKACCOUNT = 'bacnkaccount',
    WEBPAGE = 'webpage',
    FILE = 'file',
    MAXSIZE = 'maxsize',
    MINSIZE = 'minsize',
    MINPERCENTAGE = 'minpercentage',
    MAXPERCENTAGE = 'maxperecentage',
    NAME = 'name',
    SURNAME = 'surname',
    UNIQUE_VALIDATOR = 'unique_validator'
}

export function NifValidatorIntranet(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const str = control.value.document;
        const check = nameRe.test(str);
        let dni = str;
        let numero, lets, letra;

        dni = dni.toUpperCase();

        if (check === true) {
            numero = dni.substr(0, dni.length - 1);
            numero = numero.replace('X', 0);
            numero = numero.replace('Y', 1);
            numero = numero.replace('Z', 2);
            lets = dni.substr(dni.length - 1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero + 1);
            if (letra === lets) {
                return;
            } else {
                return {'nif': {value: control.value}};
            }
        } else {
            return {'nif': {value: control.value}};
        }
    };
}

export function NifValidator(control: FormControl): ValidationErrors {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = control.value;

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return {'nif': false};
    }
    const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;
    if (validChars.charAt(charIndex) === letter) {
        return;
    }
    return {'nif': false};
}

export function NifValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" No es correcto`;
}

export function CityOrPostalCodeValidator(control: FormControl): ValidationErrors {
    return !control.value || /^([0-9]{5}|[a-zA-Z][a-zA-Z ]{0,49})$/.test(control.value) ? null : {'postalcode': false};
}

export function CityOrPostalCodeValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" No es correcto`;
}

export function uniqueValidatorMessage(err, field: FormlyFieldConfig) {
    return `Intente con otro ${field.templateOptions.placeholder}`;
}

export class CustomValidators {

    static dni(control: AbstractControl) {
        const dni_regex = /^\d{8}[a-zA-Z]$/;
        if (dni_regex.test(control.value) === true) {
            return null;
        } else {
            return {errorDNI: true};
        }
    }


    static phoneNumber(control: AbstractControl) {

    }

    static checkPasswords(group: FormGroup) {
        const pass = group.controls["password"].value;

        const confirmPassword = group.controls["confirmPassword"].value;
        // const validPassword = pass === confirmPassword ? null : {errorConfirmPass: true};
        return pass === confirmPassword ? null : {errorConfirmPass: true};
    }

    static checkEmail(form: AbstractControl) {
        return form.get('client_email').value == form.get('confirm_email').value ? null : {equals: true};
    }

    static dateLessThan(from: string, to: string): (AbstractControl) => ValidationErrors | null {
        const control = (group: FormGroup): { [key: string]: any } => {
            const f = group.controls[from];
            const t = group.controls[to];
            if (f.value > t.value) {
                const errorField = {};
                errorField[from] = {
                    isLess: true
                };

                return errorField;
            }
            return null;
        };
        return control;
    }

    static isURL(control: AbstractControl): { [key: string]: boolean } | null {
        if (!control.value) {
            return null;
        }
        if (!urlExpression.test(control.value)) {
            return {'invalidURL': true};
        }
        return null;
    }

    static minDate(minDate: Date): ValidatorFn {
        const getControl = (control: AbstractControl): { [key: string]: boolean } | null => {
            const minDateFromMoment = moment(minDate);
            if (control && control.value && moment(control.value).isBefore(minDateFromMoment)) {
                return {'minDateValidator': true};
            }
            return null;
        };
        return getControl;
    }
    static maxDates(minDate: number, checkIn): ValidatorFn {
 
        const getControl = (control: AbstractControl): { [key: string]: boolean } | null => {

            var dateB = moment(checkIn); 
            const [dd,mm,yyyy] = control.value.split("/")
            const  dateBirth = yyyy+"-"+mm+"-"+dd
            var year =dateB.diff(dateBirth, 'years')
 
            if (control && control.value && minDate !== year ) {
                    return {'minDateValidator': true};
            }
            return null;
        };
        return getControl;
    }

    static maxDate(maxDate: Date): ValidatorFn {
        const getControl = (control: AbstractControl): { [key: string]: boolean } | null => {
            const maxDateFromMoment = moment(maxDate);
            if (control && control.value && moment(control.value).isAfter(maxDateFromMoment)) {
                return {'maxDateValidator': true};
            }
            return null;
        };
        return getControl;
    }

}

export const emailExpression = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
export const urlExpression = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

export function minlengthValidationMessage(err, field) {
    return `Debería haber al menos ${field.templateOptions.minLength} caracteres`;
}

export function maxlengthValidationMessage(err, field) {
    return `Este valor debe tener menos de ${field.templateOptions.maxLength} caracteres`;
}

export function minValidationMessage(err, field) {
    return `Este valor debe ser mayor de ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
    return `Este valor debe ser menor de ${field.templateOptions.max}`;
}
