import { Pipe, PipeTransform } from "@angular/core";
import { PaymentDossier, PaymentOption } from '../interfaces/management.interface';
import { t } from 'typy';

@Pipe({
  name: 'paymentOptionToDescription'
})
export class PaymentOptionToDescriptionPipe implements PipeTransform {
  transform(
    paymentDossier: PaymentDossier,
    cashOptions: PaymentOption[] = [],
    bankAccountOptions: PaymentOption[] = [],
    creditCardOptions: PaymentOption[] = [],
  ): string {
    if (!paymentDossier) { return; }
    let result = '';
    let option;
    switch (paymentDossier.payment_method) {
      case 1:
        option = cashOptions.find(option => option.id === paymentDossier.cash);
        result = t(option, 'description').safeObject;
        break;
      case 2:
        option = bankAccountOptions.find(option => option.id === paymentDossier.bank_account);
        result = t(option, 'bank_name').safeObject + "-" + t(option, 'description').safeObject;
        break;
      case 4:
        option = creditCardOptions.find(option => option.id === paymentDossier.credit_card);
        result = t(option, 'description').safeObject;
        break;
      default:
        option = null;
    }
    if (!option) { return ''; }

    return result;
  }
}
