import { NgModule } from "@angular/core";
import { LibSharedModule} from "../../../lib-shared/lib-shared.module";
import { InvalidPriceAlertComponent } from "./invalid-price-alert.component";
import { InvalidAlertModule } from "../invalid-alert/invalid-alert.module";

@NgModule({
    declarations: [
        InvalidPriceAlertComponent,
    ],
    imports: [
        LibSharedModule,
        InvalidAlertModule,
    ],
    exports: [
        InvalidPriceAlertComponent,
    ]
})
export class InvalidPriceAlertModule {

}
