import { Component, Input, OnInit } from '@angular/core';
import { ShopingBasketCircuit } from '../../../integrations/circuits/index';
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-circuit-cart-item',
    templateUrl: './circuit-cart-item.component.html',
    styleUrls: ['./circuit-cart-item.component.scss']
})
export class CircuitCartItemComponent implements OnInit {

    @Input() item: ShopingBasketCircuit;

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }

    ngOnInit() {
    }

}
