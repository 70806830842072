import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule } from "@angular/material";
import { IonicModule } from "@ionic/angular";
import { MaterialDatePickerModule } from "../../../../material-date-picker/index";
import { LibSharedModule } from "../../../../lib-shared/index";
import { SupplierInvoiceModalComponent } from "./supplier-invoice-modal.component";

@NgModule({
    declarations: [
        SupplierInvoiceModalComponent,
    ],
    imports: [
        LibSharedModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        MatSelectModule,
        MaterialDatePickerModule,
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
    ],
    exports: [
        SupplierInvoiceModalComponent,
    ]
})
export class SupplierInvoiceModalModule {

}
