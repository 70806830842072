import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { IonicModule } from '@ionic/angular';
import { TransportSearchComponent } from './transport-search.component';
import { TransportSearchContainerComponent } from './transport-search-container/transport-search-container.component';
import { CochesSearchComponent } from './coches-search/coches-search.component';
import { AllLocationPipe } from '../../lib-shared/pipes/allLocation.pipe';
import { TrainsSearchComponent } from './trains-search/trains-search.component';
import { TrainStationAutocompleteModule } from '../../train-station-autocomplete/train-station-autocomplete.module';
import { LibSharedModule } from '../../lib-shared/index';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MaterialDatePickerModule } from "../../material-date-picker/material-date-picker.module";
import { TransferSearchComponent } from './transfers-search/transfers-search.component';
import { TransferLocationAutocompleteModule } from '../../transfers-location-autocomplete/transfer-location-autocomplete.module';
import { MatExpansionModule } from '@angular/material';

@NgModule({
    declarations: [
        TransportSearchComponent,
        TransportSearchContainerComponent,
        CochesSearchComponent,
        AllLocationPipe, 
        TrainsSearchComponent,
        TransferSearchComponent,
    ],
    imports: [
        MatTabsModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatIconModule,
        TrainStationAutocompleteModule,
        IonicModule,
        LibSharedModule,
        MatCheckboxModule,
        FormsModule,
        MaterialDatePickerModule,
        TransferLocationAutocompleteModule,
        MatExpansionModule
        
    ],
    exports: [
        TransportSearchComponent,
        TransportSearchContainerComponent,
        CochesSearchComponent, TrainsSearchComponent]
})
export class TransportsSearchModule {
}
