import { Component, OnInit, Input } from "@angular/core"
import { PackageVacationPackageBooking } from "@vecib2c/frontend-dto"

@Component({
  selector: "lib-packages-book-confirmation",
  templateUrl: "./packages-book-confirmation.component.html",
  styleUrls: ["./packages-book-confirmation.component.scss"],
})
export class PackagesBookConfirmationComponent implements OnInit {
  @Input() package: PackageVacationPackageBooking

  constructor() {}

  ngOnInit() {}
}
