import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'tabIdToNamePipe'
})
export class TabIdToNamePipe implements PipeTransform {

    transform(tabId: string, tabs: { id: number, name: string }[]): string {
        if (!tabId) {
            return;
        }
        const tab = tabs.find(tab => tab.id === Number(tabId));
        if (!tab) {
            return;
        }
        return tab.name;
    }
}
