import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAddEditModule } from "../../form-add-edit/index";
import { BusBookingComponent } from './bus-booking.component';

@NgModule({
  declarations: [BusBookingComponent],
  imports: [
    CommonModule,
    FormAddEditModule
  ]
})
export class BusBookingModule { }
