import { Directive, ElementRef, HostListener } from '@angular/core';
import { SearchNavbarService } from '../services/index';

@Directive({
    selector: '[libHideTopScroll]',
})
export class HideTopScroll2Directive {
    constructor(public el: ElementRef, public navbarService: SearchNavbarService) {
    }

    @HostListener('ionScroll', ['$event'])
    checkScroll($event) {
        const hide = !$event.detail.scrollTop ? true : false;
        this.navbarService.showHideNavbar(hide);
    }
}
