export class TrainsRequestDto {
    public origin: string;
    public originName?: string;
    public checkIn: string;
    public checkOut: string;
    public numberOfAdults: number;
    public numberOfChildren: number;
    public numberOfInfants: number;
    public page: number;
    public pageSize: number;
    public destination: string;
    public destinationName?: string;
    public paginateTo?: string;
    public minPrice?: number;
    public maxPrice?: number;

    constructor() {
        this.origin = '';
        this.originName = '';
        this.destination = '';
        this.destinationName = '';
        this.numberOfAdults = 1;
        this.numberOfChildren = 0;
        this.numberOfInfants = 0;
        this.page = 1;
        this.pageSize = 10;
    }

}

export enum ApplyEnum {
    'OUTWARD' = 'OUTWARD',
    'RETURN' = 'RETURN',
    'ALL' = 'OUTWARD,RETURN',
}
