import { ActivityDetailGetResDto } from "@vecib2c/frontend-dto";
import { IActivityPaxAmount, ICancellationPolicy, IUrlDome } from "@vecib2c/frontend-dto/dist/dto/Activities/Search/common/index";
import { ActivityImageMapper } from "./activity-image.mapper";

export class ActivityPreBookingParameter {

     code: string;
    rateKey: string;
    from: string;
    to: string;
    paxes: number[];
    modalityCode: string;
    modalityName: string;
    contentName: string;
    partialTotal: number;
    totalPrice: number;
    rateCode: string;
    paxAmounts: IActivityPaxAmount[];
    cancellationPolicies: ICancellationPolicy[];
    image: string;
 

 /*    code: string;
    rateKey: string;
    from: string;
    to: string;
    paxes: number[];
    modalityCode: string;
    modalityName: string;
    contentName: string;
    partialTotal: number;
    totalPrice: number;
    rateCode: string;
    paxAmounts?: IActivityPaxAmount[];
    cancellationPolicies?: ICancellationPolicy[];
    image?:any;
    pickUpName?:string;
    pickUpDate?:string;
    excursionLanguage?:string;
    currency:string; */
 
   static map(activityDetail: ActivityDetailGetResDto, paxes: number[]): ActivityPreBookingParameter {
        const obj = {
            code: activityDetail.code,
            rateKey: activityDetail.modalities[0].rates[0].rateDetails[0].rateKey,
            from: activityDetail.modalities[0].rates[0].rateDetails[0].operationDates[0].from,
            to: activityDetail.modalities[0].rates[0].rateDetails[0].operationDates[0].to,
            paxes: paxes.sort((a, b) => +b - +a),
            modalityCode: activityDetail.modalities[0].code,
            modalityName: activityDetail.modalities[0].name,
            contentName: activityDetail.content.name,
            partialTotal: activityDetail.modalities[0].rates[0].rateDetails[0].totalAmount.amount,
            totalPrice: activityDetail.modalities[0].rates[0].rateDetails[0].totalAmount.commission.pvp,
            rateCode: activityDetail.modalities[0].rates[0].rateCode,
            paxAmounts: activityDetail.modalities[0].rates[0].rateDetails[0].paxAmounts,
            cancellationPolicies: activityDetail.modalities[0].rates[0].rateDetails[0].operationDates[0].cancellationPolicies,
            image: ActivityImageMapper.getImageURLBySize(activityDetail.content.media),
        };
        return obj;
    } 
 
   /*  static map(activityDetail: ActivityDetailGetResDto, paxes: number[]): ActivityPreBookingParameter {
        const obj = {
            code: activityDetail.locator,
            rateKey: activityDetail.locator,
            paxAmounts:[],
            from: activityDetail.excursionDate,
            paxes:paxes.sort((a, b) => +b - +a),
            to:activityDetail.excursionDate,
            modalityCode:activityDetail.modalityCode,
            modalityName: activityDetail.modelityName,
            contentName:  activityDetail.excursionName,
            excursionLanguage:  activityDetail.excursionLanguage,
            pickUpDate:  activityDetail.pickUpDate,
            pickUpName:  activityDetail.pickUpName,
            partialTotal: activityDetail.totalNet,
            totalPrice:activityDetail.totalPrice,
            currency:activityDetail.currency,
            rateCode:activityDetail.modalityCode,
            image: activityDetail.images,
            cancellationPolicies: activityDetail.cancellationPolicies,
            sessionId:activityDetail.sessionId
        }; 
        return obj;
    } */
}
