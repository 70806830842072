import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import ResponseData from "@vecib2c/frontend-dto/dist/dto/Circuits/ResponseData";
import { IAirport } from '../interfaces/index';
import { TecnoturisService } from '../../tecnoturis.service';

@Injectable({
    providedIn: "root"
})
export class AirportService {

    constructor(
        public http: HttpClient,
        private tecnoturisService: TecnoturisService
    ) {
    }

    private getPath(action) {
        const list = [`${this.tecnoturisService.config.airports}`, "api/v1", action];
        return list.join("/");
    }

    search(text): Observable<IAirport[]> {
        return this.http.get(this.getPath("airports") + `?text=${text}`)
            .pipe(
                map((res: ResponseData): IAirport[] => {
                    return res.data['rows']
                        .map((airport): IAirport => {
                            const mappedAirport: IAirport = {
                                iata: airport.iata,
                                name: airport.name,
                                cityCode: airport.code_city,
                                city: airport.city,
                                country: airport.country,
                                latitude: airport.latitude,
                                longitude: airport.longitude,
                            };
                            return mappedAirport;
                        });
                })
            );
    }

}
