import {Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {Observable, Subject} from 'rxjs';
import * as moment_ from 'moment/moment';

const moment = moment_;
import {MatSnackBar} from '@angular/material/snack-bar';
import {
    IClient,
    ICommercialActivityEvent, ICommercialActivityPersonal, ICommercialActivityStatus,
    ICommercialActivityType, IDialogData, IRecurrenceDay, IRecurrenceType
} from '../../lib-shared/interfaces/commercial-event.interface';
import {AppSettings} from '../../lib-shared/functions/commercial-event.settings';
import {CommercialEventService} from '../../lib-shared/services/commercial-event.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {concatMap, debounceTime, map, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'lib-commercial-event-form',
    templateUrl: './commercial-event.component.html',
    styleUrls: ['./commercial-event.component.scss']
})
export class CommercialEventComponent implements OnInit {
    @ViewChild('searchInput', { static: false }) searchInputNative: ElementRef<HTMLInputElement>;
    model: ICommercialActivityEvent = null;
    types: ICommercialActivityType[] = [];
    statuses: ICommercialActivityStatus[] = [];
    coworkers: ICommercialActivityPersonal[] = [];
    recurrenceDays: IRecurrenceDay[] = [];
    recurrenceTypes: IRecurrenceType[] = [];
    clients: IClient[] = [];
    selectedClient: IClient = null;
    filteredClients: Observable<IClient[]> = new Observable<IClient[]>();
    unsubscribe: Subject<any> = new Subject();
    searchClient: FormControl = new FormControl();
    form: FormGroup = new FormGroup({
        id: new FormControl({value: null, disabled: false}),
        commercial_activities_type: new FormControl({value: null, disabled: false}),
        commercial_activities_status: new FormControl({value: 2, disabled: false}),
        subject: new FormControl({value: null, disabled: false}, [Validators.required]),
        description: new FormControl({value: null, disabled: false}, [Validators.required]),
        date: new FormControl({value: new Date(), disabled: false}, [Validators.required]),
        start_time: new FormControl({value: null, disabled: false}, [Validators.required]),
        end_time: new FormControl({value: null, disabled: false}, [Validators.required]),
        client: new FormControl({value: null, disabled: false}),
        personal: new FormControl({value: null, disabled: false}),
        location: new FormControl(),
        recurrence: new FormControl({value: null, disabled: false}),
        recurrence_type: new FormControl({value: null, disabled: false}),
        recurrence_every: new FormControl({value: null, disabled: false}),
        recurrence_days: new FormControl({value: null, disabled: false}),
        is_not_end_date: new FormControl({value: null, disabled: false}),
        end_after: new FormControl({value: null, disabled: false}),
        end_by: new FormControl({value: null, disabled: false}),
    });

    constructor(@Inject(LOCALE_ID) public locale: string,
                @Inject(MAT_DIALOG_DATA) public data: IDialogData<ICommercialActivityEvent>,
                public dialogRef: MatDialogRef<boolean>,
                private dateAdapter: DateAdapter<any>,
                private agendaService: CommercialEventService,
                private snackBar: MatSnackBar) {
        this.dateAdapter.setLocale(locale);
        this.model = this.data && this.data.meta ? this.data.meta : null;
        this.types = this.data && this.data.setup ? this.data.setup['commercial_activities_type'] : [];
        this.statuses = this.data && this.data.setup ? this.data.setup['commercial_activities_status'] : [];
        this.coworkers = this.data && this.data.personal ? this.data.personal : [];
        this.recurrenceTypes = this.data && this.data.setup ? this.data.setup['recurrence_type'] : [];
        this.recurrenceDays = this.data && this.data.setup ? this.data.setup['recurrence_days'] : [];
        this.validateFormState();
        this.loadForm(this.data.meta);
    }

    ngOnInit() {
        this.filteredClients = this.searchClient.valueChanges.pipe(
            debounceTime(500),
            switchMap(criteria => this.searchCriteria(criteria)),
        );
    }

    searchCriteria(criteria) {
        if (criteria && typeof criteria === 'string') {
            // this.loading = true;
            return this.agendaService.getClients(criteria);
        } else {
            return [];
        }
    }

    getStatus(): string {
        const current = this.form.get('commercial_activities_status').value;
        const found = this.statuses.find(status => status.id === Number(current));
        return found ? found.name : '';
    }

    async getClient(id: number): Promise<void> {
        if (id) {
            const result = await this.agendaService.getClient(id).toPromise();
            if (result) {
                this.selectedClient = result;
            }
        }
    }

    async processing(): Promise<void> {
        const payload = {
            // arrival_time: moment().format(AppSettings.DATE_FORMATS.FULL_DATE_TIME),
            commercial_activities_status: 4
        };
        const result = await this.agendaService.updateEvent(this.form.get('id').value, payload).toPromise();
        if (result) {
            this.snackBar.open('El evento se grabo exitosamente', null, { duration: 2000 });
            this.dialogRef.close(true);
        }
    }

    async closed(): Promise<void> {
        const payload = {
            arrival_time: moment().format(AppSettings.DATE_FORMATS.FULL_DATE_TIME),
            commercial_activities_status: 1
        };
        const result = await this.agendaService.updateEvent(this.form.get('id').value, payload).toPromise();
        if (result) {
            this.snackBar.open('El evento se grabo exitosamente', null, { duration: 2000 });
            this.dialogRef.close(true);
        }
    }

    async cancel(): Promise<void> {
        const payload = {
            arrival_time: moment().format(AppSettings.DATE_FORMATS.FULL_DATE_TIME),
            commercial_activities_status: 3
        };
        const result = await this.agendaService.updateEvent(this.form.get('id').value, payload).toPromise();
        if (result) {
            await this.agendaService.deleteEvent(this.form.get('id').value).toPromise();
            this.snackBar.open('El evento se grabo exitosamente', null, { duration: 2000 });
            this.dialogRef.close(true);
        }
    }

    async saved(): Promise<void> {
        if (this.form.valid) {
            const payload = this.preparePayload();
            if (payload.id) {
                const response = await (this.agendaService.updateEvent(payload.id, payload).toPromise());
                if (response) {
                    this.dialogRef.close(true);
                } else {
                    this.snackBar.open('Error durante el proceso, intente nuevamente', null, { duration: 3000 });
                }
            } else {
                const createResponse = await (this.agendaService.createEvent(payload).toPromise());
                if (createResponse) {
                    this.dialogRef.close(true);
                } else {
                    this.snackBar.open('Error durante el proceso, intente nuevamente', null, { duration: 3000 });
                }
            }
        } else {
            this.snackBar.open('Los datos ingresados son incorrectos', null, { duration: 3000 });
        }
    }

    preparePayload(): ICommercialActivityEvent {
        const data = this.form.getRawValue();
        const date = data['date'];
        const dateEndBy = data['end_by'];
        const startTime = moment(data['start_time'], AppSettings.DATE_FORMATS.TIME_24);
        const endTime = moment(data['end_time'], AppSettings.DATE_FORMATS.TIME_24);
        const days = this.recurrenceDays.filter(item => item.selected === true).map(item => item.id);
        const payload: ICommercialActivityEvent = {
            id: data['id'],
            subject: data['subject'],
            description: data['description'],
            start_date: moment(date).format(AppSettings.DATE_FORMATS.FULL_DATE),
            start_time: startTime.format(AppSettings.DATE_FORMATS.TIME_24),
            end_date: moment(date).format(AppSettings.DATE_FORMATS.FULL_DATE),
            end_time: endTime.format(AppSettings.DATE_FORMATS.TIME_24),
            commercial_activities_type: data['commercial_activities_type'],
            commercial_activities_status: data['commercial_activities_status'],
            personal: data['personal'],
            client: data['client'],
            recurrence: data['recurrence'],
            recurrence_type: data['recurrence_type'],
            recurrence_every: data['recurrence_every'],
            recurrence_days: days,
            is_not_end_date: data['is_not_end_date'],
            end_after: data['end_after'],
            end_by: dateEndBy ? moment(dateEndBy).format(AppSettings.DATE_FORMATS.FULL_DATE) : null
        };
        return payload;
    }

    async loadForm(model: ICommercialActivityEvent): Promise<void> {
        this.recurrenceDays.forEach(item => item.selected = false);
        if (model) {
            await this.getClient(model.client);
            const date = moment(model.start_date, AppSettings.DATE_FORMATS.FULL_DATE_TIME_ISO_FULL);
            const startTime = moment(model.start_time, AppSettings.DATE_FORMATS.FULL_TIME);
            const endTime = moment(model.end_time, AppSettings.DATE_FORMATS.FULL_TIME);
            this.form.setValue({
                id: model.id,
                commercial_activities_type: model.commercial_activities_type,
                commercial_activities_status: model.commercial_activities_status,
                subject: model.subject,
                description: model.description,
                date: date.toDate(),
                start_time: startTime.format(AppSettings.DATE_FORMATS.TIME_24),
                end_time: endTime.format(AppSettings.DATE_FORMATS.TIME_24),
                personal: model.personal,
                client: model.client,
                location: model.location,
                recurrence: model.recurrence,
                recurrence_type: model.recurrence_type,
                recurrence_every: model.recurrence_every,
                recurrence_days: model.recurrence_days,
                is_not_end_date: model.is_not_end_date,
                end_after: model.end_after,
                end_by: model.end_by
            });
            // this.recurrenceDays.filter(item => !model.recurrence_days.includes(item.id)).forEach(item => item.selected = true);
            model.recurrence_days.forEach(selected => {
                const found = this.recurrenceDays.find(day => day.id === selected);
                if (found) {
                    found.selected = true;
                }
            });
            const current = this.form.get('commercial_activities_status').value;
            if (Number(current) === 3) {
                this.form.disable();
            }
        } else {
            this.form.patchValue({
                recurrence: false
            });
            if (this.data.suggestedHour) {
                const date = this.data.suggestedHour;
                this.form.patchValue({
                    date: date,
                    start_time: moment(date).format(AppSettings.DATE_FORMATS.TIME_24),
                    recurrence: false
                });
            }
            if (this.data.suggestedClient) {
                const date = this.data.suggestedHour;
                this.selectedClient = this.data.suggestedClient;
                this.form.patchValue({
                    date: date,
                    start_time: date ? moment(date).format(AppSettings.DATE_FORMATS.TIME_24) : null,
                    recurrence: false,
                    client: this.data.suggestedClient.id
                });
            }
        }
    }

    validateFormState(): void {
        this.form.get('recurrence').valueChanges.subscribe(value => {
            if (value === true) {
                this.form.get('recurrence_type').enable();
                this.form.get('recurrence_every').enable();
                this.form.get('recurrence_days').enable();
                this.form.get('is_not_end_date').enable();
                this.form.get('end_after').enable();
                this.form.get('end_by').enable();
            }
            if (value === false) {
                this.form.get('recurrence_type').disable();
                this.form.get('recurrence_every').disable();
                this.form.get('recurrence_days').disable();
                this.form.get('is_not_end_date').disable();
                this.form.get('end_after').disable();
                this.form.get('end_by').disable();
            }
        });
        this.form.get('is_not_end_date').valueChanges.subscribe(value => {
            const recurrence = this.form.get('recurrence').value;
            if (recurrence === true) {
                if (value === true) {
                    this.form.get('end_after').disable();
                    this.form.get('end_by').disable();
                }
                if (value === false) {
                    this.form.get('end_after').enable();
                    this.form.get('end_by').enable();
                }
            }
        });
    }

    getFrecuency(): string {
        const frecuency = this.form.get('recurrence_type').value;
        if (frecuency) {
            const check = this.recurrenceTypes.find(item => item.id === frecuency);
            return check ? String(check.name) : '';
        }
        return '';
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const selected: IClient = event.option.value;
        this.selectedClient = selected;
        this.form.get('client').setValue(selected.id);
        this.searchClient.setValue(null);
        this.searchInputNative.nativeElement.value = '';
    }

    dayClicked(day: IRecurrenceDay): void {
        day.selected = !day.selected;
    }

    removeClient(): void {
        this.selectedClient = null;
        this.form.get('client').setValue(null);
    }

    compareFn(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

}
