import { EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CRUD_TYPE, FieldType, IDynamicCrud, Setup } from './lib-shared/interfaces/index';
import { DynamicCrudService, ManagementService } from './lib-shared/services/index';
import { DynamicCrudUtils } from './utils';
import { ValidationsFields } from './lib-shared/validators/customValidators';

export class BaseAddEdit implements OnInit, OnDestroy {
    unsubscribe = new Subject();
    crud: string;
    id: any;
    dynamicCrud: IDynamicCrud;

    formlyFields: FormlyFieldConfig[];

    form = new FormGroup({});

    model: any = {};

    setup: Setup = {};

    queryParams;

    countries: any;

    customObjectToSave: object = {};

    createInPathList = false;

    disableRouteChange = false;

    postSave = new Subject();
    postEdit = new Subject();
    idChange = new Subject();

    getObjectChange = new Subject();

    responseData;
    formCreated = new Subject();

    idValidateField: any;


    @Output() changeData: EventEmitter<any> = new EventEmitter<any>();

    constructor(protected route: ActivatedRoute,
                protected router: Router,
                protected dynamicCrudService: DynamicCrudService,
                protected managementService: ManagementService,
                public readonly location?: Location) {

    }

    ngOnInit() {
        this.route.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (!this.disableRouteChange) {
                    this.setup = {...this.setup, ...value.setup};
                    this.getOptions();
                }
            });

        this.route.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                if (!this.disableRouteChange) {
                    this.id = params['id'];
                    this.init();
                    this.idChange.next(this.id);
                }
            });
        this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(queryParams => this.queryParams = queryParams);
    }

    init(): void {
        this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
        if (this.id) {
            this.getOne();
        } else {
            this.createFormlyFields();
        }
    }

    createFormlyFields(): void {
        this.formlyFields = DynamicCrudUtils.generateFormlyConfig(this.dynamicCrud, this.id ? CRUD_TYPE.EDIT : CRUD_TYPE.CREATE);
        this.getOptions();
        setTimeout(() => {
            this.formCreated.next();
            this.initValidateFields();
        }, 200);
    }

    initValidateFields(): void {
        const validateFields = this.getFieldOptions(FieldType.VALIDATE);
        validateFields.forEach(v => {
            const field = DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, v);
            this.form.get(v)
                .valueChanges
                .pipe(takeUntil(this.unsubscribe), debounceTime(350))
                .subscribe(value => {
                    let urlPath = field.validateUrlPath.replace('{id}', this.idValidateField);
                    urlPath = urlPath.replace('{value}', value);
                    if (this.model && Object.keys(this.model).length) {
                        if (this.model.id) {
                            urlPath = urlPath + `?exclude_id=${this.model.id}`;
                        }
                    }
                    this.dynamicCrudService.setHost(urlPath);
                    this.dynamicCrudService.list()
                        .subscribe(data => {
                            const uniqueValidator = ValidationsFields.UNIQUE_VALIDATOR;
                            let errors = {};
                            if ((Array.isArray(data) && data.length)) {
                                errors[uniqueValidator] = true;
                            } else {
                                errors = null;
                            }
                            this.form.get(v).setErrors(errors);
                            this.form.get(v).markAsDirty();
                            this.form.get(v).markAsPristine();
                            this.form.get(v).markAsTouched();
                        });
                });
        });
    }

    setData(): void {
        let data = JSON.parse(JSON.stringify(this.responseData));
        if (this.dynamicCrud.extraKeySave) {
            this.dynamicCrud.extraKeySave.forEach(k => {
                if (this.responseData[k] && this.responseData[k].length) {
                    if (data['id']) {
                        delete data['id'];
                    }
                    delete data[k];
                    data = {...data, ...this.responseData[k][0], ...{id: this.responseData['id']}};
                } else {
                    data = this.mapSaveAsToKeyName(this.responseData, this.dynamicCrud, data);
                }
            });
        } else {
            data = this.mapSaveAsToKeyName(this.responseData, this.dynamicCrud, data);
        }
        return data;
    }

    setIdFilter(id: number): void {
        const crudData = JSON.parse(JSON.stringify(this.dynamicCrud));
        crudData.urlPathList = crudData.urlPathList.replace('id', id);
        this.dynamicCrud = crudData;
    }

    getOne(): void {
        this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
        this.dynamicCrudService.get(+this.id)
            .subscribe(value => {
                this.responseData = value;
                this.model = this.setData();
                this.createFormlyFields();
                this.getObjectChange.next(value);
            });
    }

    submit(queryParams = null, saveAsNew = false): void {
        const data = DynamicCrudUtils.dateFieldToDefaultFormat({
            ...this.form.getRawValue(),
            ...this.queryParams,
            ...this.customObjectToSave
        }, this.getFieldOptions(FieldType.DATE));
        if (this.createInPathList) {
            this.dynamicCrudService.setHost(this.dynamicCrud.urlPathList);
        } else {
            this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
        }
        if (this.id && !saveAsNew) {
            this.dynamicCrudService.put(+this.id, this.parseData(data))
                .subscribe(value => {
                    this.postSaveSuccess(value, queryParams);
                    this.postEdit.next(value);
                });
        } else {
            this.dynamicCrudService.add(this.parseData(data))
                .subscribe((value: any) => {
                    this.postSaveSuccess(value, queryParams, saveAsNew);
                    this.postSave.next(value);
                });
        }
    }

    parseData(dataForm) {
        const data = {};
        const formData = dataForm;
        if (this.dynamicCrud.extraKeySave) {
            Object.keys(formData)
                .forEach(k => {
                    const field = DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, k);
                    if (field) {
                        if (field.saveInKey) {
                            if (!(data[field.saveInKey])) {
                                if (!field.replaceKey) {
                                    data[field.saveInKey] = [{}];
                                    data[field.saveInKey][0][field.replaceKey || k] = (formData[k] || null);
                                } else {
                                    data[field.saveInKey] = [{}];
                                    data[field.saveInKey][0][field.replaceKey || k] = (formData[k] || null);
                                }
                            } else {
                                data[field.saveInKey][0][field.replaceKey || k] = (formData[k] || null);
                                if (this.responseData && this.responseData[field.saveInKey].length
                                    && this.responseData[field.saveInKey][0]['id']) {
                                    data[field.saveInKey][0]['id'] = this.responseData[field.saveInKey][0]['id'];
                                }
                            }
                        } else if (field.saveAs) {
                            data[field.saveAs] = (formData[k] || null);
                        } else {
                            data[field.replaceKey || k] = (formData[k] || null);
                        }
                    } else {
                        data[k] = (formData[k] || null);
                    }
                });
        } else {
            Object.keys(formData)
                .forEach(k => {
                    const field = DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, k);
                    if (field) {
                        data[field.saveAs || field.keyName] = (formData[k] || null);
                    }
                });
        }
        return {...data, ...this.customObjectToSave};
    }

    postSaveSuccess<T>(response: T, queryParams, saveAsNew = false): void {
        if (saveAsNew) {
            this.router.navigate(['intranet', 'management', this.dynamicCrud.crud]);
        } else {
            this.router.navigate(['intranet', 'management', this.dynamicCrud.crud, 'edit', (response as any).id],
                {queryParams: queryParams});
        }
    }

    getOptions(): void {
        const options = this.getFieldOptions();
        if (this.setup && this.formlyFields) {
            options.forEach(o => {
                const field = DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, o);
                const option = (field.optionKey || o).replace('Id', '');
                if (this.setup[option]) {
                    DynamicCrudUtils.setDinamycallyOptions(this.formlyFields, o, this.setup[option]);
                }
            });
        }
    }

    getFieldOptions(fieldType = FieldType.LIST): string[] {
        const options = [];
        this.dynamicCrud.fields.forEach(f => {
            if (f.isGroup) {
                options.push(...f.fieldChilds.filter(fc => fc.type === fieldType).map(fc => fc.keyName));
            } else {
                if (f.type === fieldType) {
                    options.push(f.keyName);
                }
            }
        });
        return options;
    }

    protected getPostSave<T>(): Observable<T> {
        return this.postSave.asObservable() as Observable<T>;
    }

    changeFormlyFieldsProperties(key: string, templateOptions: FormlyTemplateOptions, hidden = false): void {
        this.formlyFields.forEach((f, index) => {
            if (key === f.key) {
                if (templateOptions) {
                    this.formlyFields[index].templateOptions = {...this.formlyFields[index].templateOptions, ...templateOptions};
                }
                f.hideExpression = hidden;
            }
            if (f.fieldGroup && f.fieldGroup.length) {
                f.fieldGroup.forEach((fg, i) => {
                    if (key === fg.key) {
                        if (templateOptions) {
                            this.formlyFields[index].fieldGroup[i].templateOptions = {
                                ...this.formlyFields[index].fieldGroup[i].templateOptions,
                                ...templateOptions
                            };
                        }
                        this.formlyFields[index].fieldGroup[i].hideExpression = hidden;
                    }
                });
            }
        });
    }

    cancel(route: string[] = null): void {
        if (route) {
            this.router.navigate(route);
        } else {
            this.location.back();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.postSave.complete();
        this.postEdit.complete();
    }

    private mapSaveAsToKeyName(responseData, dynamicCrud, data) {
        const mappedData = {};
        Object.keys(responseData)
            .forEach(k => {
                const field = DynamicCrudUtils.getFieldBySaveAs(dynamicCrud, k);
                if (field) {
                    mappedData[field.keyName] = responseData[field.saveAs];
                }
            });
        return {...data, ...mappedData};
    }
}
