import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material";
import * as moment_ from "moment";
import * as _ from "lodash";
import { PriceGroup, Segment } from '@vecib2c/frontend-dto/dist';
import { FlightFilter } from "../../../lib-shared/interfaces/index";

const moment = moment_;

@Component({
    selector: "lib-flight-filter",
    templateUrl: "./flight-filter.component.html",
    styleUrls: ["./flight-filter.component.scss"]
})
export class FlightFilterComponent implements OnInit {

    @Input() priceGroups: PriceGroup[];
    @Input() includeInitializedFilterFields = false;
    @Input() minPrice = 10000;
    @Input() maxPrice = 0;
    @Input() companies: string[][] = [];
    @Input() baggages: number[][] = [];
    @Input() scales: number[][] = [];
    @Input() waitingTimes: number[][] = [];
    @Input() minWaitingTimes: number[] = [];
    @Input() maxWaitingTimes: number[] = [];
    @Input() departureTimes: number[][] = [];
    @Input() minDepartureTimes: number[] = [];
    @Input() maxDepartureTimes: number[] = [];
    @Input() searchButtonIsAvailable = false;

    @Output() filteredObject = new EventEmitter<any>();
    @Output() filter = new EventEmitter<FlightFilter>();

    numberOfSegments: number;
    selectedCompanies: string[][] = [];
    selectedBaggages: number[][] = [];
    selectedScales: number[][] = [];
    selectedWaitingTime: number[][] = [];
    showWaitingTime: boolean[] = [];
    selectedDepartureTime: number[][] = [];
    selectedPrices: number[] = [];

    constructor() {
    }

    ngOnInit(): void {
        if (this.priceGroups && this.priceGroups.length > 0) {
            this.initFilter();
            this.numberOfSegments = this.departureTimes.length;
            this.initFilterFields(this.numberOfSegments);
        }
    }

    initFilter(): void {
        if (!this.includeInitializedFilterFields) {
            this.priceGroups.forEach(priceGroup => {
                this.minPrice = this.minPrice <= priceGroup.total ? this.minPrice : priceGroup.total;
                this.maxPrice = this.maxPrice >= priceGroup.total ? this.maxPrice : priceGroup.total;

                priceGroup.itineraries.forEach(itinerary => {
                    const index = itinerary.segmentNumber - 1;
                    itinerary.segments.forEach(segment => {
                        if (!this.companies[index]) { this.companies[index] = []; }
                        this.companies[index].push(segment.marketingCarrierCode);
                    });
                    itinerary.pricedSegments.forEach(pricedSegment => {
                        if (!this.baggages[index]) { this.baggages[index] = []; }
                        this.baggages[index].push(pricedSegment.baggageQuantity);
                    });
                    const waitingTimes = this.setWaitingTime(itinerary.segments);

                    if (!this.scales[index]) { this.scales[index] = []; }
                    this.scales[index].push(itinerary.segments.length);

                    if (waitingTimes.length > 0) {
                    if (!this.waitingTimes[index]) { this.waitingTimes[index] = []; }
                    this.waitingTimes[index] = [...this.waitingTimes[index], ...waitingTimes];
                    }

                    if (!this.departureTimes[index]) { this.departureTimes[index] = []; }
                    this.departureTimes[index].push(moment(itinerary.originDateTime.split(" ")[1], "hhmm").valueOf());
                });
            });

            this.companies = this.companies.map(companiesBySegment => {
            return _.uniq(companiesBySegment);
            });

            this.baggages = this.baggages.map(baggagesBySegment => {
            return _.uniq(baggagesBySegment);
            });

            this.scales = this.scales.map(scalesBySegment => {
            return _.uniq(scalesBySegment);
            });

            this.waitingTimes = this.waitingTimes.map(waitingTimesBySegment => {
            return _.uniq(waitingTimesBySegment);
            });

            this.waitingTimes.forEach((waitingTime: number[], index: number) => {
            this.minWaitingTimes[index] = Math.min(...waitingTime);
            this.maxWaitingTimes[index] = Math.max(...waitingTime);
            });

            this.departureTimes = this.departureTimes.map(departureTimesBySegment => {
            return _.uniq(departureTimesBySegment);
            });

            this.departureTimes.forEach((departureTime: number[], index: number) => {
            this.minDepartureTimes[index] = Math.min(...departureTime);
            this.maxDepartureTimes[index] = Math.max(...departureTime);
            });

            this.maxPrice = this.maxPrice + 20;
        }
    }

    selectByPriceRange({lower, upper}): void {
        this.selectedPrices = [lower, upper];
        if (this.searchButtonIsAvailable) { return; }
        this.handleFilter();
    }

    selectByCompanies(event: MatCheckboxChange, index: number): void {
        event.checked
            ? this.selectedCompanies[index].push(event.source.value)
            : (this.selectedCompanies[index] = this.selectedCompanies[index].filter(el => el !== event.source.value));

        if (this.searchButtonIsAvailable) { return; }
        this.handleFilter();
    }

    selectByBaggage(event: MatCheckboxChange, index: number): void {
        event.checked
            ? this.selectedBaggages[index].push(Number(event.source.value))
            : (this.selectedBaggages[index] = this.selectedBaggages[index].filter(el => el !== Number(event.source.value)));
        if (this.searchButtonIsAvailable) { return; }
        this.handleFilter();
    }

    selectByScales(event: MatCheckboxChange, index: number): void {
        if (event.checked) {
            this.selectedScales[index].push(Number(event.source.value));
        } else {
            this.selectedScales[index] = this.selectedScales[index].filter(el => el !== Number(event.source.value));
            this.selectedWaitingTime[index] = [];
        }

        this.showWaitingTime[index] = this.selectedScales[index].some(scale => scale > 1);
        if (this.searchButtonIsAvailable) { return; }
        this.handleFilter();
    }

    selectWaitingTimeRange({lower, upper}, index: number): void {
        if (!!lower && !!upper) {
            this.selectedWaitingTime[index] = [lower, upper];
            if (this.searchButtonIsAvailable) { return; }
            this.handleFilter();
        }
    }

    selectByDepartureTimeRange({lower, upper}, index: number): void {
        if (!!lower && !!upper) {
            this.selectedDepartureTime[index] = [lower, upper];
            if (this.searchButtonIsAvailable) { return; }
            this.handleFilter();
        }
    }

    search(): void {
        const [minPrice, maxPrice] = this.selectedPrices;

        this.filter.emit({
            minPrice,
            maxPrice,
            companies: this.selectedCompanies,
            baggages: this.selectedBaggages,
            scales: this.selectedScales,
            waitingTimes: this.selectedWaitingTime,
            departureTimes: this.selectedDepartureTime,
        });
    }

    private handleFilter(): void {
      let priceGroups = this.priceGroups;

      if (this.selectedPrices[0] && this.selectedPrices[1]) {
          priceGroups = this.filterByPrice(priceGroups, this.selectedPrices[0], this.selectedPrices[1]);
      }

      if (this.selectedCompanies.some(s => s.length > 0)) {
          priceGroups = this.filterByCompany(priceGroups, this.selectedCompanies);
      }

      if (this.selectedBaggages.some(s => s.length > 0)) {
          priceGroups = this.filterByBaggage(priceGroups, this.selectedBaggages);
      }

      if (this.selectedScales.some(s => s.length > 0)) {
          priceGroups = this.filterByScale(priceGroups, this.selectedScales);
      }

      if (this.selectedWaitingTime.some(s => s.length > 0)) {
          priceGroups = this.filterByWaitingTime(priceGroups, this.selectedWaitingTime);
      }

      if (this.selectedDepartureTime.some(s => s.length > 0)) {
          priceGroups = this.filterByDepartureTime(priceGroups, this.selectedDepartureTime);
      }

      priceGroups = this.ensureNumberOfSegments(priceGroups, this.numberOfSegments);

      this.filteredObject.emit({
          rows: priceGroups,
          totalItems: priceGroups.length
      });
    }

    private filterByPrice(priceGroups: PriceGroup[], min: number, max: number): PriceGroup[] {
        return priceGroups.filter(el => {
            return el.total <= max && el.total >= min;
        });
    }

    private filterByCompany(priceGroups: PriceGroup[], companies: string[][]): PriceGroup[] {
        return priceGroups.map(priceGroup => {
            const itineraries = priceGroup.itineraries.filter(itinerary => {
                return itinerary.segments.some(segment => {
                    return companies[itinerary.segmentNumber - 1].length === 0 ||
                        companies[itinerary.segmentNumber - 1].includes(segment.marketingCarrierCode);
                });
            });
            if (itineraries.length > 0) {
                const filteredPriceGroup: PriceGroup = Object.assign({}, priceGroup, { itineraries });
                return filteredPriceGroup;
            }
        }).filter(Boolean);
    }

    private filterByBaggage(priceGroups: PriceGroup[], baggages: number[][]): PriceGroup[] {
        return priceGroups.map(priceGroup => {
            const itineraries = priceGroup.itineraries.filter(itinerary => {
                return itinerary.pricedSegments.some(pricedSegment => {
                    return baggages[itinerary.segmentNumber - 1].length === 0 ||
                        baggages[itinerary.segmentNumber - 1].includes(pricedSegment.baggageQuantity);
                });
            });
            if (itineraries.length > 0) {
                const filteredPriceGroup: PriceGroup = Object.assign({}, priceGroup, { itineraries });
                return filteredPriceGroup;
            }
        }).filter(Boolean);
    }

    private filterByScale(priceGroups: PriceGroup[], scales: number[][]): PriceGroup[] {
        return priceGroups.map(priceGroup => {
            const itineraries = priceGroup.itineraries.filter(itinerary => {
                return scales[itinerary.segmentNumber - 1].length === 0 ||
                    scales[itinerary.segmentNumber - 1].includes(itinerary.segments.length);
            });
            if (itineraries.length > 0) {
                const filteredPriceGroup: PriceGroup = Object.assign({}, priceGroup, { itineraries });
                return filteredPriceGroup;
            }
        }).filter(Boolean);
    }

    private filterByWaitingTime(priceGroups: PriceGroup[], waitingTimes: number[][]): PriceGroup[] {
      return priceGroups.map(priceGroup => {
          const itineraries = priceGroup.itineraries.filter(itinerary => {
              return itinerary.segments.some((segment: Segment, i: number) => {
                  if (i + 1 < itinerary.segments.length) {
                    const waitingTime = this.calculateWaitingTime(segment, itinerary.segments[i + 1]);

                    return waitingTimes[itinerary.segmentNumber - 1].length === 0 ||
                        waitingTime >= waitingTimes[itinerary.segmentNumber - 1][0] &&
                        waitingTime <= waitingTimes[itinerary.segmentNumber - 1][1];
                  }
              });
          });
          if (itineraries.length > 0) {
              const filteredPriceGroup: PriceGroup = Object.assign({}, priceGroup, { itineraries });
              return filteredPriceGroup;
          }
      }).filter(Boolean);
    }

    private filterByDepartureTime(priceGroups: PriceGroup[], departureTimes: number[][]): PriceGroup[] {
      return priceGroups.map(priceGroup => {
          const itineraries = priceGroup.itineraries.filter(itinerary => {
              const departureTime = moment(itinerary.originDateTime.split(" ")[1], "hhmm").valueOf();
              return departureTimes[itinerary.segmentNumber - 1].length === 0 ||
                  departureTime >= departureTimes[itinerary.segmentNumber - 1][0] &&
                  departureTime <= departureTimes[itinerary.segmentNumber - 1][1];
          });
          if (itineraries.length > 0) {
              const filteredPriceGroup: PriceGroup = Object.assign({}, priceGroup, { itineraries });
              return filteredPriceGroup;
          }
      }).filter(Boolean);
    }

    private ensureNumberOfSegments(priceGroups: PriceGroup[], numberOfSegments: number): PriceGroup[] {
      return priceGroups.filter(p => {
        let n = 0;
        for (let i = 1; i <= numberOfSegments; i++) {
          let segmentNumberIsChecked = false;
          p.itineraries.forEach(itinerary => {
            if (itinerary.segmentNumber === i && !segmentNumberIsChecked) {
              n = n + 1;
              segmentNumberIsChecked = true;
            }
          });
        }
        return n === numberOfSegments;
      });
    }

    private calculateWaitingTime(segment: Segment, nextSegment: Segment): number {
        const originMoment = moment(nextSegment.originDateTime, "DD/MM/YYYY kk:mm");
        const destinationMoment = moment(
            segment.destinationDateTime,
            "DD/MM/YYYY kk:mm"
        );

        const diff = originMoment.diff(destinationMoment);
        return diff;
    }

    private setWaitingTime(segments: Segment[]) {
        const segmentsWaitingTime: number[] = [];
        if (segments.length > 0) {
            for (let i = 0; i < segments.length; i++) {
                if (i + 1 < segments.length) {
                    const waitingTime = this.calculateWaitingTime(segments[i], segments[i + 1]);
                    if (waitingTime > 0) {
                        segmentsWaitingTime.push(waitingTime);
                    }
                }
            }
        }

        return segmentsWaitingTime;
    }

    private initFilterFields(numberOfSegments: number = 0): void {
        for (let i = 0; i < numberOfSegments; i++) {
            this.selectedCompanies[i] = [];
            this.selectedBaggages[i] = [];
            this.selectedScales[i] = [];
            this.selectedDepartureTime[i] = [];
            this.showWaitingTime[i] = false;
            this.selectedWaitingTime[i] = [];
        }
    }
}
