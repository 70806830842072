import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelBookingListComponent } from './hotel-booking-list.component';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DynamicCrudModule } from '../../../dynamic-crud/index';
import { LibSharedModule } from '../../../lib-shared/index';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [HotelBookingListComponent],
    imports: [
        CommonModule,
        DynamicCrudModule,
        FormlyModule,
        ReactiveFormsModule,
        IonicModule,
        LibSharedModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatCheckboxModule,
        MatTooltipModule
    ],
    exports: [HotelBookingListComponent]
})
export class HotelBookingListModule {
}
