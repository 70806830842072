import { CarAvailabilityRes } from "./availability.res.model";

export class AvailabilityResMapper {

    static calculatePVP(carResponse: CarAvailabilityRes): number {
        if (!carResponse || !carResponse.CarSet || carResponse.CarSet.length === 0) { return 0; }
        const carPrice = carResponse.CarSet[0].TotalCharge;
        return carPrice;
    }
}
