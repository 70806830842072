import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class VeciService {
  constructor(private http: HttpClient) {}

  getHtml<T>(): Observable<any> {
    const url = `${environment.microsites}/api/v1/common/html/`;
    return this.http.get<any>(url);
  }

  getRGPD<T>(site: string): Observable<any> {
    const url = `${environment.microsites}/api/v1/common/rgpd/data/`;
    return this.http.get<any>(url, { params: { site } });
  }
}
