import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {takeUntil} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {bookingFormMetaData} from '../booking.form';
import {BookingList, IBookingService, SupplierInvoice} from '../../../lib-shared/interfaces/index';
import {DynamicCrudService} from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-hotel-booking-list',
    templateUrl: './hotel-booking-list.component.html',
    styleUrls: ['./hotel-booking-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelBookingListComponent implements OnInit, AfterViewInit, OnDestroy {

    dynamicCrud = bookingFormMetaData;

    unsubscribe = new Subject();

    @Input() dossier: any;
    @Input() type: any;
    @Input() bookingServices: IBookingService[];
    @Input() supplierInvoices: SupplierInvoice[];

    @Output() bookingsSelected: EventEmitter<BookingList[]> = new EventEmitter<BookingList[]>();

    hotelBookings = [];

    data: BookingList[] = [];
    // displayedColumns = ['selectable', 'service', 'provider', 'total_pvp', 'total_net', 'total_abono', 'comission', 'fee'];
    displayedColumns = ['provider', 'hotel', 'city', 'check_in', 'check_out', 'total_pvp'];

    columnsToDisplay: string[] = this.displayedColumns.slice();

    lastSelected;

    expandedElement: any | null;

    @Output() delete: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() editAbono: EventEmitter<any> = new EventEmitter<IBookingService>();
    @Output() prepaid = new EventEmitter<IBookingService>();
    @Output() registerSupplierInvoice = new EventEmitter<IBookingService>();

    selection = new SelectionModel<BookingList>(true, []);

    fields = {
        "id": "ID",
        "veci_dossier": "Nº Expediente VECI",
        "veci_user": "Agente",
        "provider": "Proveedor",
        "locator": "Localizador",  
        'check_in': "Check In",
        'check_out': "Check Out",
        'hotel': "Hotel",
        'city': "Destino",
        "total_pvp": "Total PVP",
    };

    hotel = false;
    fligth = false;
    cancelKeyState = 'can_cancel';

    constructor(
        private route: ActivatedRoute,
        private _cdRef: ChangeDetectorRef,
        private dynamicCrudService: DynamicCrudService,
        private router: Router) 
    {
        this.selection.changed
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                const list = value.source.selected;
                const length = list.length;
                this.lastSelected = length > 0 ? list[length - 1] : null;
                this.changeValuesRadio();
                this.bookingsSelected.emit(value.source.selected);
            });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.getDisplayColumnsFields();
        this._cdRef.detectChanges();
    }

    private getDisplayColumnsFields() {

        if (this.type === 1) {
            this.displayedColumns.unshift('veci_user'); 
            this.displayedColumns.unshift('veci_dossier');
            this.displayedColumns.push('locator');
        }

        // this.displayedColumns.push('buttons');
        this.columnsToDisplay = this.displayedColumns.slice();
    }

    editItem(route: string, id): void {
        this.router.navigate(['intranet', 'management', `${route}-booking`, 'edit', id]);
    }

    deleteBooking(): void {
        this.dynamicCrudService.setHost(`clients/dossier/${this.dossier}/booking-service/`);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private changeValuesRadio() {
        if (this.lastSelected) {
            if (this.type === 1) {
                if (this.lastSelected.has_invoice) {
                    this.bookingServices.map(service => {
                        service.disabled = !service.has_invoice;
                        return service;
                    });
                } else {
                    if (this.lastSelected.fee_apply) {
                        this.bookingServices.map(service => {
                            service.disabled = service.fee_apply !== this.lastSelected.fee_apply;
                            return service;
                        });
                    } else {
                        this.bookingServices.map(service => service.disabled = false);
                    }
                }
            }
        } else {
            this.bookingServices.map(service => service.disabled = false);
        }
    }

    disabledRows(row) {
        return row.disabled;
    }

    getCancelStateShow(element: any): boolean {
        return this.cancelKeyState in element ? element[this.cancelKeyState] : false;
    }

}
