import {
    FilterPrice,
    HotelFilter,
    HotelFilterAccommodation,
    HotelFilterRate,
} from "@vecib2c/frontend-dto";
import { IFilter } from "../filter.interface";

export class HotelFilterMapper {

    static build(filter: IFilter): HotelFilter {

        const hotelFilter = new HotelFilter();

        if (filter.hasOwnProperty('accommodations') || filter.hasOwnProperty('stars')) {
            hotelFilter.accommodation = new HotelFilterAccommodation();
            hotelFilter.accommodation.type = filter.accommodations;
            hotelFilter.accommodation.category = filter.stars;
        }
        if (filter.hasOwnProperty('rates') || filter.hasOwnProperty('prices')) {
            hotelFilter.rate = new HotelFilterRate();
            hotelFilter.rate.category = filter.rates;
            if (filter.hasOwnProperty('prices')) {
                hotelFilter.rate.price = new FilterPrice();
                hotelFilter.rate.price.min = filter.prices.min;
                hotelFilter.rate.price.max = filter.prices.max;
            }

        }

        return hotelFilter;
    }
}
