import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormAddEditComponent } from './form-add-edit.component';
import { ManagementFilesModule } from '../management-files/index';
import { CrudWithListModule } from '../crud-with-list/index';
import { HotelBookingRoomsModule } from './hotel-booking-rooms/hotel-booking-rooms.module';
import { LibSharedModule } from '../lib-shared/index';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [FormAddEditComponent],
    imports: [
        CommonModule,
        FormlyModule,
        IonicModule,
        ManagementFilesModule,
        CrudWithListModule,
        LibSharedModule,
        HotelBookingRoomsModule,
        RouterModule,
        MatCardModule
    ],
    exports: [FormAddEditComponent]
})
export class FormAddEditModule {
}
