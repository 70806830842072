import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, startWith, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BaseAddEdit } from '../base-add-edit';
import { IDynamicCrud } from '../lib-shared/interfaces/index';
import { AuthenticationService, DynamicCrudService, ManagementService } from '../lib-shared/services/index';
import { DialogComponent } from '../dialog/dialog.component';
import { DynamicCrudUtils } from '../utils';
import * as moment_ from 'moment';

const moment = moment_;

@Component({
    selector: 'lib-crud-with-list',
    templateUrl: './crud-with-list.component.html',
    styleUrls: ['./crud-with-list.component.scss']
})
export class CrudWithListComponent extends BaseAddEdit implements OnInit, OnChanges {
    @Input() dynamicCrud: IDynamicCrud;
    formlyFields: FormlyFieldConfig[];

    auth = AuthenticationService.getUser();

    form = new FormGroup({});
    @Input() textAddButton = 'Añadir';

    @Input() appendKey: string;

    @Input() idFilter: any;

    @Input() custom = false;

    @Input() selectable = false;

    @Output() itemsSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

    @Input() changeUrlPathList = true;

    @Input() excludeFromData: number[];

    @Output() dataList: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Input() tableTemplate: TemplateRef<any>;

    @Input() customEdit = false;

    @Input() showEdit = false;

    @Input() showDelete = true;

    @Input() showDownload = false;

    @Input() downloadKeyName: string;

    @Output() customEditEvent: EventEmitter<number> = new EventEmitter<number>();

    @Input() data: any;

    @Input() disableEmit = false;

    @Output() add: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @Output() delete: EventEmitter<any> = new EventEmitter<any>();

    disableRouteChange = true;

    constructor(route: ActivatedRoute,
                router: Router,
                dynamicCrudService: DynamicCrudService,
                managementService: ManagementService,
                public location: Location,
                private dialog: MatDialog) {
        super(route, router, dynamicCrudService, managementService, location);
    }

    ngOnInit() {
        this.route.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                this.setup = value.setup;
                this.getOptions();
            });
        this.init();

        this.postSave
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.add.emit(res);
            });

        this.formCreated.pipe(takeUntil(this.unsubscribe)).subscribe(_ => {
            this.validateDatesInFlight();
            this.validateDatesInActivity();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.idFilter) {
            this.customObjectToSave = {};
            this.customObjectToSave[this.appendKey] = this.idFilter;
        }
    }

    postSaveSuccess<T>(response: T): void {
        if (this.disableEmit) {
            this.data.push(response);
            this.data = [...this.data];
        } else {
            this.changeData.emit(response);
        }
        this.form.reset();
    }

    editCrud(): void {

    }

    deleteCrud(id: number): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar el registro?', id: id}
        });

        dialogRef.afterClosed().pipe(
            concatMap((param: number) => {
                this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
                return param ? this.dynamicCrudService.delete(id) : of();
            }))
            .subscribe(res => {
                if (this.disableEmit) {
                    this.data = this.data.filter(d => d.id !== id);
                    this.data = [...this.data];
                } else {
                    this.delete.emit(res);
                }
            });
    }

    private validateDatesInFlight(): void {
        if (!this.form.get('departure_at')) { return; }
        this.form.get('departure_at')
        .valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(departureAt => {
            const mappedDepartureAt = moment(departureAt).format('YYYY/MM/DD HH:mm:ss');
            const arrivalDate = this.form.get('arrival_at').value;
            if (departureAt.isAfter(arrivalDate)) {
                this.form.get('arrival_at').setValue(departureAt);
            }
            DynamicCrudUtils.setDinamycallyMin(this.formlyFields, 'arrival_at', mappedDepartureAt);
        });
    }

    private validateDatesInActivity(): void {
        if (!this.form.get('date_from')) { return; }
        this.form.get('date_from')
        .valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(dateFrom => {
            const dateTo = this.form.get('date_to').value;
            if (moment(dateFrom).isAfter(moment(dateTo))) {
                this.form.get('date_to').setValue(dateFrom);
            }
            DynamicCrudUtils.setDinamycallyMin(this.formlyFields, 'date_to', dateFrom);
        });
    }

}
