import { PrebookResDto, PriceGroup } from "@vecib2c/frontend-dto";
import { IFlightPax } from "../flightPax.interface";

export class FlightState {
    priceGroup: PriceGroup;
    distribution: IFlightPax;
    residents: IFlightPax;
    lowCostPreBooking?: PrebookResDto;
    uuid: string;

    static build(
        priceGroup: PriceGroup,
        itineraries: number[],
        pax: IFlightPax,
        adultResidents: number,
        childrenResidents: number,
        infantResidents: number,
        uuid: string,
        lowCostPreBookingResponse?: PrebookResDto,
    ): FlightState {
        const selectedItineraries = priceGroup.itineraries.filter(itinerary =>
            itineraries.includes(itinerary.id)
        );

        const mappedPriceGroup = Object.assign({}, priceGroup, { itineraries: selectedItineraries });

        const flightState: FlightState = {
            priceGroup: mappedPriceGroup,
            distribution: {
                adults: pax.adults,
                children: pax.children,
                infants: pax.infants,
            },
            residents: {
                adults: adultResidents || 0,
                children: childrenResidents || 0,
                infants: infantResidents || 0,
            },
            uuid: uuid,
        };

        if (lowCostPreBookingResponse) {
            flightState.lowCostPreBooking = lowCostPreBookingResponse;
        }

       return flightState;
    }
}
