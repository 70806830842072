import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementFilesComponent } from './management-files.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicCrudModule } from '../dynamic-crud/index';

@NgModule({
    declarations: [ManagementFilesComponent],
    imports: [
        CommonModule,
        DynamicCrudModule,
        IonicModule,
        ReactiveFormsModule
    ],
    exports: [ManagementFilesComponent]
})
export class ManagementFilesModule {
}
