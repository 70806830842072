import { FieldType, IDynamicCrud } from '../../lib-shared/interfaces/index';

export const clientFiles: IDynamicCrud = {
    showFilters: false,
    crud: 'dossier',
    name: 'Ficheros adjuntos',
    urlPath: 'clients/files/',
    urlPathList: 'clients/id/files/',
    fields: [
        {
            isGroup: true,
            name: '',
            keyName: '',
            fieldChilds: [
                {
                    keyName: 'file',
                    name: 'Nombre',
                    type: FieldType.CHAR,
                    showInTable: false
                },
                {
                    keyName: 'name',
                    name: 'Nombre',
                    type: FieldType.CHAR,
                    showInTable: true,
                },
                {
                    keyName: 'is_for_client',
                    name: 'Visualiza Cliente',
                    type: FieldType.CHECKBOX,
                    showInTable: true,
                }
            ]
        },
    ]
};
