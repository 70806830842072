import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from "@angular/core";
import { NgbCalendar, NgbDate, NgbInputDatepicker, } from "@ng-bootstrap/ng-bootstrap";
import * as moment_ from 'moment';

const moment = moment_;

@Component({
    selector: "lib-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements OnInit {

    @Input() minDate;
    @Input() format;
    @ViewChild("datepicker", { static: false }) datepicker: NgbInputDatepicker;
    @Input() placeholder: string;
    @Input() hideMinDate: boolean;
    @Input() navigation: string;
    @Input() maxDate;
    @Input() fromDate: NgbDate;
    hoveredDate: NgbDate;
    formattedValue: string;

    @Output() from: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private calendar: NgbCalendar,
    ) {
    }

    ngOnInit() {
        if (!this.hideMinDate) {
            this.minDate = this.calendar.getToday();
        }
        if (!this.fromDate) {
            this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", 1);
        }
        if (!this.navigation) {
            this.navigation = "arrows";
        }
        this.setDateValue(this.setDateInput());
        this.changeDates(this.format);
    }

    toggle() {
        this.datepicker.toggle();
    }

    onSelection(date: NgbDate) {
        this.fromDate = date;
        this.setDateValue(this.setDateInput());
        this.changeDates(this.format);
        this.datepicker.close();
    }

    setDateValue(value) {
        this.formattedValue = value;
    }

    setDateInput(): string {
        const dateToString = (date: NgbDate): string => {
            return `${date.day}/${date.month}/${date.year}`;
        };

        if (this.fromDate) {
            return dateToString(this.fromDate);
        } else {
            return "";
        }
    }

    formatDate(date: NgbDate, format: string = "YYYY-MM-DD"): string {
        if (date) {
            const mdt = moment([date.year, date.month - 1, date.day]);
            if (!mdt.isValid()) {
                return '';
            }
            return mdt.format(format);
        } else {
            return '';
        }
    }

    private changeDates(format): void {
        this.from.emit(this.formatDate(this.fromDate, format));
    }

}
