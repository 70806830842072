import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatOptionModule } from '@angular/material';
import { TransferLocationAutocomplete } from './transfer-location-autocomplete.component';
import { TransferService } from '../lib-shared/services/transfer.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        TransferLocationAutocomplete,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
    ],
    providers: [TransferService],
    exports: [
        TransferLocationAutocomplete
    ]
})
export class TransferLocationAutocompleteModule {
}