import { Component, OnInit } from '@angular/core';
import { TecnoturisService } from "../../tecnoturis.service";
import { otherServiceFormMetaData } from './other-services-booking.form';

@Component({
  selector: 'lib-other-service-booking',
  templateUrl: './other-services-booking.component.html',
  styleUrls: ['./other-services-booking.component.scss']
})
export class OtherServiceBookingComponent implements OnInit {
    otherServiceCrud = otherServiceFormMetaData;
  

  constructor(private tecnoturisService: TecnoturisService) {
    if (this.tecnoturisService.config.forms.otherServiceForm) {
      this.otherServiceCrud = this.tecnoturisService.config.forms.otherServiceForm;
    }
  }

  ngOnInit() {
  }

}
