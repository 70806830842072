import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IBookingService, ManagementService} from "../../../../lib-shared/index";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'lib-abono-modal',
    templateUrl: './abono-modal.component.html',
    styleUrls: ['./abono-modal.component.scss']
})
export class AbonoModalComponent {

    form: FormGroup;
    totalAbono: number;

    constructor(public dialogRef: MatDialogRef<AbonoModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: IBookingService,
                private managementService: ManagementService) {

        this.totalAbono = data.total_abono === 0 ? data.total_pvp : data.total_abono;

        this.form = new FormGroup({
            abono: new FormControl(this.totalAbono, [
                Validators.required,
                Validators.min(1),
                Validators.max(data.total_pvp)])
        });

        this.form.get('abono').markAsTouched();
    }

    saveAbono() {
        const totalAbono = this.form.get('abono').value;
        this.managementService.updateBookingService(this.data.id, { total_abono: totalAbono }).subscribe(data => {
            this.dialogRef.close(true);
        });
    }
}
