import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from "./date-picker.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { MatFormFieldModule, MatIconModule, MatInputModule } from "@angular/material";

@NgModule({
    declarations: [
        DatePickerComponent
    ],
    imports: [
        CommonModule,
        NgbDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    exports: [
        DatePickerComponent
    ]
})
export class DatePickerModule {
}
