import { Component, Input } from "@angular/core";
import { ShoppingBasketFlight } from '../../../integrations/hotels/index';
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-flight-cart-item',
    templateUrl: './flight-cart-item.component.html',
    styleUrls: ['./flight-cart-item.component.scss']
})

export class FlightCartItemComponent {
    @Input() item: ShoppingBasketFlight;

    constructor(public shoppingCartSummaryComponent: ShoppingCartSummaryComponent) {
    }
}
