import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { InsuranceSearchDto } from '@vecib2c/frontend-dto';
import { TecnoturisService } from '../../tecnoturis.service';
import { AutocompleteService } from '../../lib-shared/services/index';
import { BaseEntity } from '../../lib-shared/Entities/index';
import Pax from '../../paxes/Pax';
import { InsurancesProviderService } from '../../lib-shared/services/insurance-provider.service';

@Component({
    selector: 'lib-insurance-search',
    templateUrl: './insurance-search.component.html',
    styleUrls: ['./insurance-search.component.scss'],
    providers: [
        AutocompleteService,
        InsurancesProviderService,
        {provide: 'insurance-provider', useValue: 'insurances'}
    ]
})
export class InsurancesSearchComponent extends BaseEntity implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
    @ViewChild('dateRange', { static: false }) dateRange;
    @Input() inputFields;
    @Input() isSmall = false;
    @Output() dataChanged = new EventEmitter<InsuranceSearchDto>();
    public MIN_INSUREDS = 1;
    public MAX_INSUREDS = 100;
    public nInsureds: number;

    optionsOrigin$: Observable<any>;
    optionsDestination$: Observable<any>;
    form: FormGroup;
    insuranceSearchDto: InsuranceSearchDto;
    pax: Pax;
    paxes: Array<Pax> = [];
    nationalities;
    loaded: boolean;
    addressSelected: any;
    listResidences;
    myControlOrigin = new FormControl('', Validators.required);
    myControlDestination = new FormControl('', Validators.required);
    filteredOptionsOrigin: Observable<any>;
    filteredOptionsDestination: Observable<any>;
    countryOriginSelected: any;
    countryDestinationSelected: any;

    constructor(
        public formBuilder: FormBuilder,
        public autocomplete: AutocompleteService,
        public router: Router,
        public translate: TranslateService,
        private cdRef: ChangeDetectorRef,
        public http: HttpClient,
        public insuranceProviderService: InsurancesProviderService,
        public tecnoturisService: TecnoturisService
    ) {
        super(http, tecnoturisService);
        this.insuranceSearchDto = new InsuranceSearchDto();
        this.insuranceSearchDto.residence = 'ESP';
        this.loaded = false;
        this.nInsureds = 1;
        this.initForm();
        this.insuranceProviderService.listResidences()
            .subscribe(data => {
                this.listResidences = data;
                this.filteredOptionsOrigin = this.myControlOrigin.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterOrigin(value))
                );
                this.filteredOptionsDestination = this.myControlDestination.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterDestination(value))
                );

                if (this.insuranceSearchDto.residence) {
                    const residence = this.listResidences.find(d => d.countryCode === this.insuranceSearchDto.residence);
                    if (residence) {
                        this.form.get('residence').setValue(residence);
                    }
                }
                if (this.insuranceSearchDto.origin) {
                    const origin = this.listResidences.find(country => country.name === this.insuranceSearchDto.origin);
                    if (origin) {
                        this.myControlOrigin.setValue(origin);
                    }
                }
                if (this.insuranceSearchDto.destination) {
                    const destination = this.listResidences.find(country => country.name === this.insuranceSearchDto.destination);
                    if (destination) {
                        this.myControlDestination.setValue(destination);
                    }
                }
            });
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');
    }

    private _filterOrigin(value): any {
        let filterValue;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.name.toLowerCase();
            return;
        }
        if (filterValue.length > 1) {
            return this.listResidences.filter(option => {
                return option.name.toLowerCase().indexOf(filterValue) === 0;
            });
        }
    }

    private _filterDestination(value): any {
        let filterValue;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.name.toLowerCase();
            return;
        }
        if (filterValue.length > 1) {
            return this.listResidences.filter(option => {
                return option.name.toLowerCase().indexOf(filterValue) === 0;
            });
        }
    }

    ngAfterViewInit() {
        this.loaded = true;
        if (this.inputFields) {
            if (Object.keys(this.inputFields).length === 0) {
                this.insuranceSearchDto = undefined;
            } else {
                this.insuranceSearchDto = this.inputFields;
                this.setDateFromQuery(this.insuranceSearchDto.checkIn);
                this.setDateFromQuery(this.insuranceSearchDto.checkOut);
                this.setNumberInsuredsFromQuery();
            }
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    initForm() {
        this.form = this.formBuilder.group({
            insureds: [this.nInsureds, Validators.required],
            residence: ['', Validators.required],
        });
    }


    setDateFromQuery(inputDate) {
        const date = new Date(inputDate);
        this.dateRange.onSelection(
            new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
        );
    }

    setNumberInsuredsFromQuery() {
        this.form.controls['insureds'].setValue(this.insuranceSearchDto.insureds);
        this.nInsureds = this.insuranceSearchDto.insureds;
    }

    incInsureds() {
        this.nInsureds++;
    }

    decInsureds() {
        this.nInsureds--;
    }

    search() {
        if (!this.form.valid || !this.myControlOrigin.valid || !this.myControlDestination.valid) {
            return;
        } else {
            this.setDates();
            this.setOrigin();
            this.setDestination();
            this.setNumberInsureds();
            this.setResidence();
            this.setCodeCountry();
            this.setCodeCountryAlpha();
            this.setAddress().then(() => {
                this.dataChanged.emit(this.insuranceSearchDto);
                const navigationExtras: NavigationExtras = {
                    queryParams: {
                        uri: this.nInsureds
                    }
                };
                this.router.navigateByUrl(
                    '/integration/insurances/search?' + this.buildQueryString(), navigationExtras);
            });
        }
    }

    setCodeCountry() {
        this.insuranceSearchDto.codeCountry = this.form.value.residence.countryCode;
    }

    setCodeCountryAlpha() {
        this.insuranceSearchDto.codeCountryAlpha = this.form.value.residence.countryCodeAlpha2;
    }

    setResidence() {
        this.insuranceSearchDto.residence = this.form.value.residence.countryCode;
    }

    setAddress() {
        return new Promise((resolve, reject) => {
            this.getGeocodes().subscribe(
                address => {
                    this.insuranceSearchDto.country = address.address_components.country;
                    this.insuranceSearchDto.province = address.address_components.province;
                    this.insuranceSearchDto.locality = address.address_components.locality;
                    this.insuranceSearchDto.latitude = address.geometry.location.lat;
                    this.insuranceSearchDto.longitude = address.geometry.location.lng;
                    resolve();
                },
                error => console.log(error)
            );
        });
    }

    getGeocodes(): Observable<any> {
        return this.autocomplete.details(this.myControlDestination.value.name);
    }

    setOrigin() {
        this.insuranceSearchDto.origin = this.myControlOrigin.value.name;
    }

    setDestination() {
        this.insuranceSearchDto.destination = this.myControlDestination.value.name;
    }

    setNumberInsureds() {
        this.insuranceSearchDto.insureds = this.nInsureds;
    }

    setDates() {
        this.insuranceSearchDto.checkIn = this.dateRange.formatDate(this.dateRange.fromDate);
        this.insuranceSearchDto.checkOut = this.dateRange.formatDate(this.dateRange.toDate);
    }

    private buildQueryString(): HttpParams {
        const query = new HttpParams()
            .set('origin', this.insuranceSearchDto.origin)
            .set('destination', this.insuranceSearchDto.destination)
            .set('checkIn', this.insuranceSearchDto.checkIn)
            .set('checkOut', this.insuranceSearchDto.checkOut)
            .set('insureds', this.insuranceSearchDto.insureds.toString())
            .set('latitude', this.insuranceSearchDto.latitude.toString())
            .set('longitude', this.insuranceSearchDto.longitude.toString())
            .set('country', this.insuranceSearchDto.country)
            .set('locality', this.insuranceSearchDto.locality)
            .set('province', this.insuranceSearchDto.province)
            .set('residence', this.insuranceSearchDto.residence)
            .set('codeCountry', this.insuranceSearchDto.codeCountry)
            .set('codeCountryAlpha', this.insuranceSearchDto.codeCountryAlpha);
        return query;
    }

    displayFnOrigin = (option) => {
        if (option) {
            this.countryOriginSelected = option;
            return option.name;
        }
    }

    displayFnDestination = (option) => {
        if (option) {
            this.countryDestinationSelected = option;
            return option.name;
        }
    }

    ngOnDestroy(): void {
        this.loaded = false;
    }
}
