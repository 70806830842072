import { Component, Input } from "@angular/core";
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';
import { ShoppingBasketHFS } from '../../../integrations/hotels/dto/ShoppingBasketHFS';

@Component({
    selector: 'lib-hotel-flight-cart-item',
    templateUrl: './hotel-flight-cart-item.component.html',
    styleUrls: ['./hotel-flight-cart-item.component.scss']
})

export class HotelFlightCartItemComponent {
    @Input() item: ShoppingBasketHFS;

    constructor(public shoppingCartSummaryComponent: ShoppingCartSummaryComponent) {
    }

    getCategory(category): Array<Number> | void {
        if (category === undefined) {
            return;
        }
        const regexNumber = /\d+/;

        if (!category || !regexNumber.test(category.value)) {
            return;
        } else {
            const value = category.value.match(regexNumber)[0];
            const integerValue = parseInt(value, 10);
            return new Array(integerValue);
        }
    }
}
