import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
    HotelListReqDto,
    HotelRoomDistribution
} from '@vecib2c/frontend-dto';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';
import { TecnoturisService } from '../../tecnoturis.service';
import { AutocompleteService } from '../../lib-shared/services/index';
import { BaseEntity } from '../../lib-shared/Entities/index';
import { IFilter, KindOfRate, HotelFilterMapper } from '../../lib-shared/interfaces/index';
import Pax from '../../paxes/Pax';
import { rateTypes } from '../../../mock/rateTypes';
import * as moment_ from 'moment';

const moment = moment_;

@Component({
    selector: 'lib-hotel-search',
    templateUrl: './hotel-search.component.html',
    styleUrls: ['./hotel-search.component.scss'],
    providers: [
        AutocompleteService,
    ]
})
export class HotelSearchComponent extends BaseEntity implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {

    @Input() inputFields;
    @Input() isSmall = false;
    @Input() filters: IFilter = {};
    @Input() routerPath = '/integration/hotels/search?';
    @Input() microsite = false;
    @Input() showRateType = true;

    options$: Observable<any>;
    form: FormGroup;
    hotelListReq: HotelListReqDto;
    pax: Pax;
    paxes: Array<Pax> = [];
    paxesNumber: number;
    nationalities;
    loaded: boolean;
    addressSelected: any;
    disabledButtonAdd: boolean;
    rateTypes = rateTypes;

    @Output() queryParams: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
    @Output() dataChanged = new EventEmitter<HotelListReqDto>();

    @ViewChild('paxesComp', { static: false }) paxesComp;
    @ViewChild('dateRange', { static: false }) dateRange;

    constructor(
        public formBuilder: FormBuilder,
        public autocomplete: AutocompleteService,
        public router: Router,
        public translate: TranslateService,
        private cdRef: ChangeDetectorRef,
        public http: HttpClient,
        private navCtrl: NavController,
        public tecnoturisService: TecnoturisService
    ) {
        super(http, tecnoturisService);
        this.hotelListReq = new HotelListReqDto();
        const nationalitiesEntity = this.getEntity(this.tecnoturisService.config.nationalityHost, 'nationality');
        nationalitiesEntity.list(1, 300, null, {sortField: 'name', sortDirection: 'ASC'}).subscribe(nationalities => {
            this.nationalities = nationalities.rows;
        });
        this.loaded = false;
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');
        this.paxes.push(new Pax(1, 2, []));
        this.initForm();
    }

    ngAfterViewInit() {
        this.loaded = true;
        if (this.inputFields) {
            if (Object.keys(this.inputFields).length === 0) {
                this.hotelListReq = undefined;
            } else {
                this.hotelListReq = this.inputFields;
                this.setPlaceFromQuery();
                this.setDateFromQuery(this.hotelListReq.checkIn);
                this.setDateFromQuery(this.hotelListReq.checkOut);
                this.setPaxFromQuery(this.hotelListReq.distribution);
                this.setNationalityFromQuery();
                this.setRateTypeFromQuery(this.hotelListReq.rateType);
            }
        }
        this.form
            .get('place')
            .valueChanges.pipe(debounceTime(800))
            .subscribe(() => this.getAddressAutocompleteData());
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.filters) {
            return;
        }
        return (
            changes.filters &&
            !changes.filters.firstChange &&
            this.search()
        );
    }

    initForm() {
        this.form = this.formBuilder.group({
            place: ['', Validators.required],
            // adults: [2, Validators.required],
            // children: [0, Validators.required],
            // rooms: [1, Validators.required],
            distribution: [this.paxes, Validators.required],
            nationality: ['ES', Validators.required],
            rateType: ['public', Validators.required],
        });
    }

    getAddressAutocompleteData() {
        if (this.form.controls['place'].value.length > 1) {
            this.options$ = this.autocomplete.search(
                this.form.controls['place'].value
            );
        } else {
            this.options$ = null;
        }
    }

    onPaxChanged(pax) {
        this.paxes[pax.position] = pax.newPax;
    }

    search() {
        if (!this.form.valid) {
            return;
        }
        this.setPaxes();
        this.setDates();
        this.setNationality();
        this.setFilter();
        this.setRateType();
        this.setAddress().then(() => {
            this.dataChanged.emit(this.hotelListReq);
            if (this.microsite) {
                this.queryParams.emit(this.getParams());
                return;
            }
            this.router.navigateByUrl(this.routerPath + this.getParams());
        });
    }

    setNationality() {
        this.hotelListReq.nationality = this.form.value.nationality;
    }

    setPaxes() {
        this.hotelListReq.distribution = [];
        this.paxes.map((pax: Pax) => {
            this.hotelListReq.distribution.push(<HotelRoomDistribution>{
                'rooms': pax.rooms,
                'adults': pax.adults,
                'children': pax.children
            });
        });
    }

    setDates() {
        this.hotelListReq.checkIn = this.dateRange.formatDate(this.dateRange.fromDate);
        this.hotelListReq.checkOut = this.dateRange.formatDate(this.dateRange.toDate);
    }

    setRateType() {
        this.hotelListReq.rateType = this.form.get('rateType').value;
    }

    setFilter() {
        this.hotelListReq.filter = HotelFilterMapper.build(this.filters);
        // this.hotelListReq.filter = new HotelFilter();

        // if (this.filters.hasOwnProperty('accommodations') || this.filters.hasOwnProperty('stars')) {
        //     this.hotelListReq.filter.accommodation = new HotelFilterAccommodation();
        //     this.hotelListReq.filter.accommodation.type = this.filters.accommodations;
        //     this.hotelListReq.filter.accommodation.category = this.filters.stars;
        // }
        // if (this.filters.hasOwnProperty('rates') || this.filters.hasOwnProperty('prices')) {
        //     this.hotelListReq.filter.rate = new HotelFilterRate();
        //     this.hotelListReq.filter.rate.category = this.filters.rates;
        //     if (this.filters.hasOwnProperty('prices')) {
        //         this.hotelListReq.filter.rate.price = new FilterPrice();
        //         this.hotelListReq.filter.rate.price.min = this.filters.prices.min;
        //         this.hotelListReq.filter.rate.price.max = this.filters.prices.max;
        //     }
        // }
        // if (this.filters.hasOwnProperty('rooms')) {
        //   this.hotelListReq.filter.room = new FilterRoom();
        //   this.hotelListReq.filter.room.category = this.filters.rooms;
        // }
    }

    setAddress() {
        return new Promise((resolve, reject) => {
            this.getGeocodes().subscribe(
                address => {
                    this.hotelListReq.country = address.address_components.country;
                    this.hotelListReq.province = address.address_components.province;
                    this.hotelListReq.locality = address.address_components.locality;
                    this.hotelListReq.latitude = address.geometry.location.lat;
                    this.hotelListReq.longitude = address.geometry.location.lng;
                    resolve();
                },
                error => console.log(error)
            );
        });
    }

    getGeocodes(): Observable<any> {
        return this.autocomplete.details(this.form.value['place']);
    }

    setPlaceFromQuery() {
        this.form.controls['place'].setValue(
            (this.hotelListReq.locality && this.hotelListReq.locality !== 'undefined'
                ? this.hotelListReq.locality + ', '
                : '') +
            (this.hotelListReq.province &&
            this.hotelListReq.province !== 'undefined'
                ? this.hotelListReq.province + ', '
                : '') +
            (this.hotelListReq.country && this.hotelListReq.country !== 'undefined'
                ? this.hotelListReq.country
                : '')
        );
    }

    setDateFromQuery(inputDate) {
        const date = moment(inputDate, 'YYYY-MM-DD');
        this.dateRange.onSelection(
            new NgbDate(date.year(), date.month() + 1, date.date()),
        );
    }

    setPaxFromQuery(distribution) {
        this.paxes = distribution;
        // this.paxService.changePax({
        // adults: this.hotelListReq.adults,
        // children: this.hotelListReq.children,
        // rooms: this.hotelListReq.rooms
        // });
    }

    setNationalityFromQuery() {
        const nationality = this.hotelListReq.nationality ? this.hotelListReq.nationality : 'ES';
        this.form.patchValue({'nationality': nationality});
    }

    setRateTypeFromQuery(rateType: KindOfRate) {
        this.form.get('rateType').setValue(rateType);
    }

    getParams(): HttpParams {
        const query = new HttpParams()
            .set('checkIn', this.hotelListReq.checkIn)
            .set('checkOut', this.hotelListReq.checkOut)
            .set('distribution', JSON.stringify(this.hotelListReq.distribution))
            // .set("rooms", this.hotelListReq.rooms.toString())
            // .set("adults", this.hotelListReq.adults.toString())
            // .set("children", this.hotelListReq.children.toString())
            .set('latitude', this.hotelListReq.latitude.toString())
            .set('longitude', this.hotelListReq.longitude.toString())
            .set('country', this.hotelListReq.country)
            .set('locality', this.hotelListReq.locality)
            .set('province', this.hotelListReq.province)
            .set('nationality', this.hotelListReq.nationality)
            .set('rateType', this.hotelListReq.rateType)
            .set('filter', JSON.stringify(this.hotelListReq.filter));
        return query;
    }

    addPax() {
        this.paxes.push(new Pax(1, 2, []));
        this.paxesNumber = this.paxes.length;

        if (this.paxesNumber >= 3) {
            this.disabledButtonAdd = true;
        } else {
            this.disabledButtonAdd = false;
        }

    }

    onRemovePax(position) {

        const newPaxes = [];
        this.paxes.forEach((pax: Pax, index) => {
            if (index !== position) {
                newPaxes.push(pax);
            }
        });

        this.paxes = newPaxes;

        if (this.paxes.length > 1) {
            this.disabledButtonAdd = false;
        }
    }

    displayFn(address) {
        this.addressSelected = address;
        return address.label || this.addressSelected;
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this.loaded = false;
    }
}
