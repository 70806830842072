import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'lib-others-package-search',
    templateUrl: './others-package-search.component.html',
    styleUrls: ['./others-package-search.component.scss']
})
export class OthersPackageSearchComponent {

    @Input() inputFields;
    @Input() isSmall;
    @Input() currentTab: number;

    @Output() dataChanged = new EventEmitter<any>();

    @ViewChild("dateRange", { static: false }) dateRange;
    @Input() microsite;
    @Output() queryParams: EventEmitter<any> = new EventEmitter<any>();

    packageType = 'AVORIS';

    constructor() {
    }

    onDataChanged(params: HttpParams) {
        this.dataChanged.emit(params);
    }

}
