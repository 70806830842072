import { IPaxConfirm } from "@vecib2c/frontend-dto/dist/dto/Activities/Booking/common";
import { safeNumber } from "../../functions/index";
import { ManagementPax } from "../../interfaces/management.interface";

export class ActivityPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialActivityPaxes: IPaxConfirm[],
    ): IPaxConfirm[] {
        const arr = paxes.map((pax, i) => {
            const initialActivityPax = initialActivityPaxes[i] || {} as IPaxConfirm;

            const {
                age: initialAge,
                surname: initialSurname,
                type: initialType,
            } = initialActivityPax;

            const {
                name,
                last_name: lastName,
                age,
                type: paxType,
            } = pax;

            const mappedPaxType = this.buildPaxType(paxType, initialType);

            const bookingPax: IPaxConfirm = {
                age: this.buildAge(age, initialAge, mappedPaxType),
                name,
                surname: this.buildSurname(lastName, initialSurname),
                type: mappedPaxType,

            };

            return bookingPax;
        });
        return arr;
    }

    private static buildAge(
        age: number,
        initialAge: number,
        paxType: string,
    ): number {
        const defaultAge = paxType === 'CHILD' ? 5 : 30;
        if (!age) { return safeNumber(initialAge, defaultAge); }
        return safeNumber(age, defaultAge);
    }

    private static buildSurname(
        surname: string,
        initialSurname = '',
    ): string {
        if (!surname) { return initialSurname; }
        return initialSurname;
    }

    private static buildPaxType(
        paxType: string,
        initialPaxType = 'ADULT',
    ): string {
        if (!paxType) { return initialPaxType; }
        const mappedPaxType = paxType.toLowerCase();
        if (['ad', 'adult', 'adulto'].includes(mappedPaxType)) { return 'ADULT'; }
        if (['ch', 'child', 'niño'].includes(mappedPaxType)) { return 'CHILD'; }
        if (['in', 'infant', 'infante'].includes(mappedPaxType)) { return 'CHILD'; }
        return 'ADULT';
    }
}
