import { Pipe, PipeTransform } from "@angular/core";
import * as moment_ from 'moment/moment';

const moment = moment_;

@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
    transform(value: string, outputFormat?: string, inputFormat: string = null, locale: string = 'es', utc = false) {
        moment.locale(locale);
        if (!utc) { return moment(value, inputFormat).format(outputFormat); }
        return moment(value, inputFormat).utc().format(outputFormat);
    }
}
