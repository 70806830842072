import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerLoaderService {

    private notShowSpinner = false;
    isLoading = new BehaviorSubject(false);

    show() {
        if (!this.notShowSpinner) {
            this.isLoading.next(true);
        }
    }

    hide() {
        this.isLoading.next(false);
    }

    disableSpinner() { // dont show spinner
        this.notShowSpinner = true;
    }

    enableSpinner() { // reset to show spinner
        this.notShowSpinner = false;
    }
}
