import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementClientModule } from './management-client.module';
import { RouterModule, Routes } from '@angular/router';
import { ClientsAddEditComponent } from './clients-add-edit/clients-add-edit.component';
import { SetupResolver } from '../setup.resolve';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'edit/:id',
                component: ClientsAddEditComponent
            },
        ],
        resolve: {
            setup: SetupResolver
        }
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ManagementClientModule,
        RouterModule.forChild(routes)
    ]
})
export class ManagementClientWithRoutingModule {
}
