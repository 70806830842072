import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Field } from '../../lib-shared/interfaces/index';
import { AuthenticationService } from '../../lib-shared/services/index';

@Component({
    selector: 'lib-dynamic-filters',
    templateUrl: './dynamic-filters.component.html',
    styleUrls: ['./dynamic-filters.component.scss']
})
export class DynamicFiltersComponent implements OnInit {
    @Input() filters: Field[];
    @Input() filtersForm: FormGroup;

    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    @Input() formlyFields: FormlyFieldConfig[];

    user = AuthenticationService.getUser();

    constructor(private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        // this.filtersForm.addControl('agency_chain', new FormControl());
        // this.filtersForm.addControl('agency', new FormControl());
    }

}
