import {FieldType, IDynamicCrud} from '../../lib-shared/interfaces/index';

export const clientCommercialActivitiesForm: IDynamicCrud = {
    showFilters: false,
    crud: 'commercial_activities',
    name: 'Ficheros adjuntos',
    urlPath: 'clients/commercial_activities/',
    urlPathList: 'clients/commercial_activities/?client=id',
    extraKeySave: ['client'],
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'subject',
                    name: 'Asunto',
                    type: FieldType.CHAR,
                    showInTable: true,
                    required: true,
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'commercial_activities_type',
                    name: 'Tipo',
                    type: FieldType.LIST,
                    showInTable: true,
                    required: true,
                },
                {
                    keyName: 'commercial_activities_status',
                    name: 'Estado',
                    type: FieldType.LIST,
                    showInTable: true,
                    required: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'start_date',
                    name: 'Fecha inicio',
                    type: FieldType.DATE,
                    showInTable: true,
                    defaultValue: true
                },
                {
                    keyName: 'start_time',
                    name: 'Hora inicio',
                    type: FieldType.CHAR,
                    showInTable: true,
                    required: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'end_date',
                    name: 'Fecha fin',
                    type: FieldType.DATE,
                    showInTable: true,
                    defaultValue: true
                },
                {
                    keyName: 'end_time',
                    name: 'Hora fin',
                    type: FieldType.CHAR,
                    showInTable: true,
                    required: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'personal',
                    name: 'Personal',
                    type: FieldType.LIST,
                    showInTable: true,
                    defaultValue: true
                },
            ]
        }
    ]
};
