import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lib-footer-extranet',
    templateUrl: './footer-extranet.component.html',
    styleUrls: ['./footer-extranet.component.scss']
})
export class FooterExtranetComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
