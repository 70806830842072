import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment, host } from '../../../environments/environment';
import { AuthenticationService, NotificationPopupService } from '../../../../projects/tecnoturis/src/public-api'
import { SpinnerLoaderService } from './SpinnerLoader.service';

/*
  Este interceptor fue difícil de picar y será dificil de entender, permite que multiples peticiones se ejecuten
  simultaneamente, crear el loader, tarda, mostrarlo tarda y hacer la petición tarda, la sucesión de eventos
  puede ser cualquiera, ten en cuenta todo esto y pruebalo bien antes de tocarlo
*/

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    requests: HttpRequest<any>[] = [];

    /**
     *
     * @todo refactor to white list file
     */
    domainsMockup = ['restcountries.eu', 'openflights.org',
        'es.wikipedia.org',
        'upload.wikimedia.org', 'thawing-coast-22978.herokuapp.com'];
    loginUrl = environment.logginURL;
    redirectStatus = [401, 403];

    constructor(
        private spinnerLoader: SpinnerLoaderService,
        private notificationPopupService: NotificationPopupService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
    }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
    }

    getHost(): string {
        return window.location.origin;
    }

    replaceWithDomain(url: string): string {
        if (url.includes(host)) {
            const newUrl = url.replace(host, window.location.hostname);
            // console.log('URL', url);
            // console.log('REEMPLZADO', newUrl);
            return newUrl;
        }
        return url;
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const matches = req.url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        const domain = matches && matches[1];

        // console.log('REQUEST', req.url);

        req = req.clone({
            withCredentials: !this.domainsMockup.includes(domain) ? true : false,
            url: this.getHost().indexOf('localhost') > -1 ? req.url : this.replaceWithDomain(req.url),
        });
        this.spinnerLoader.show();
        this.requests.push(req);

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    this.removeRequest(req);
                    // ñapita, we dosent show the popup error on booking services post
                    if (req.url.match(/\/booking/gm) && req.method === 'POST') {
                        return throwError(error);
                    }
                    if (error.status === 401) {
                        if ((AuthenticationService.isAuthenticated())) {
                            // this.authenticationService.refreshToken().subscribe(_ => {
                            // }, err => {
                            //     this.authenticationService.logout(false);
                            //     this.spinnerLoader.hide();
                            //     // this.router.navigate(['login']);
                            // });
                            this.spinnerLoader.hide();
                        } else {
                            this.spinnerLoader.hide();
                            // this.router.navigate(['login']);
                        }
                    } else {
                        return throwError(error);
                    }

                }
                return throwError(error);
            }),
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (!this.isLogoutPath(req) && this.redirectStatus.includes(event.status)) {
                        // this.authService.logout();
                    }
                    this.removeRequest(req);
                }
            }),
            finalize(() => {
                if (!this.requests.length) {
                    this.spinnerLoader.hide();
                }
            }));
    }

    isLogoutPath(req) {
        return req.url.includes(this.loginUrl) && req.method === 'DELETE';
    }
}
