import {
    InsuranceCommission,
    InsuranceDetailedPricing,
    InsuranceToppingsExtendable,
    InsuranceValues
} from "@vecib2c/frontend-dto";
import { ShopingBasketInsurance } from "../../../integrations/insurances/dto/ShopingBasketInsurance";
import { safeNumber } from "../../functions/index";
import { t } from 'typy';

export class PolicyCheckedRateReq {
    options: ProductOptionReq[];
    insuredCount: number;
    addons: PolicyAddonsReq[];

    static buildFromBasket(item: ShopingBasketInsurance) {
        const { params, insuredsTotal } = item.insuranceBookingPostReqDto;
        const options: ProductOptionReq[] = params.map(param => {
            const { idParam, idValue } = param;
            return {
                paramId: idParam + '',
                valueId: idValue + '',
            };
        });
        const rawAddons = t(item, 'insuranceBookingPostReqDto.toppingsExtendable').safeArray;
        return {
            options,
            insuredCount: safeNumber(insuredsTotal),
            addons: this.buildAddons(rawAddons),
        };
    }

    private static buildAddons(addons: InsuranceToppingsExtendable[]): PolicyAddonsReq[] {
        const arr = addons.map(addon => {
            const rawValues = t(addon, 'valores').safeArray;
            return {
                cruceId: addon.id_cruce,
                valueIds: this.buildValuesIds(rawValues),
            };
        });

        return arr;
    }

    private static buildValuesIds(values: InsuranceValues[] = []): string[] {
        const arr = values.map(v => v.id);
        return arr;
    }
}

export class ProductOptionReq {
    paramId: string;
    valueId: string;
}

export class PolicyAddonsReq {
    cruceId: string;
    valueIds: string[];
}

export class PolicyCheckedRateRes {
    basePrice: number;
    commission: InsuranceCommission;
    detailedPricing: InsuranceDetailedPricing;
    expandableCoverages: ExpandableCoverage[];
}

export class ExpandableCoverage {
    id: string;
    cruceId: string;
    policyId: string;
    name: string;
    description: string;
    type: string;
    values: ExpandableCoverageValue[];
}

export class ExpandableCoverageValue {
    id: string;
    isIncluded: boolean;
    bounty: number;
    limit: number;
}
