import { Component, OnInit, Inject, OnDestroy, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    PaymentMethod,
    IBookingService,
    SupplierInvoiceForm, Prepaid,
} from '../../../../lib-shared/interfaces/management.interface';
import * as moment_ from 'moment';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { roundNumber, safeNumber } from "../../../../lib-shared/functions/index";

const moment = moment_;

interface ModalData {
    paymentMethods: PaymentMethod[];
    bookingService: IBookingService;
    prepaids: Prepaid[];
}

@Component({
    selector: 'lib-supplier-invoice-modal',
    templateUrl: './supplier-invoice-modal.component.html',
    styleUrls: ['./supplier-invoice-modal.component.scss']
})
export class SupplierInvoiceModalComponent implements OnInit, OnDestroy {

    form: FormGroup;
    paymentMethods: PaymentMethod[];
    bookingService: IBookingService;
    remainingSupplierInvoice = 0;
    prepaids: Prepaid[];

    @Output() save = new EventEmitter<SupplierInvoiceForm>();

    private unsubscribe = new Subject();

    get paymentMethod() {
        return this.form.get('paymentMethod');
    }

    get paymentAmount() {
        return this.form.get('paymentAmount');
    }

    get paymentDate() {
        return this.form.get('paymentDate');
    }

    get createNewPayment() {
        return this.form.get('createNewPayment');
    }

    constructor(
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        public dialogRef: MatDialogRef<SupplierInvoiceModalComponent>,
    ) {
        const { paymentMethods, bookingService, prepaids } = data;
        this.paymentMethods = paymentMethods;
        this.bookingService = bookingService;
        this.prepaids = prepaids;
    }

    ngOnInit(): void {
        this.remainingSupplierInvoice = this.calculateRemainingPrepaid(this.prepaids);
        this.initializeForm(this.remainingSupplierInvoice, this.bookingService.iva);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private calculateRemainingPrepaid(prepaids: Prepaid[] = []): number {
        const prepaid_total = prepaids.reduce((acc, prepaid) => {
            return acc + prepaid.payment_amount;
        }, 0);
        return roundNumber(this.bookingService.price_from_provider - prepaid_total);
    }

    setPaymentDate(date: Date): void {
        const validatedDate = date ? moment(date).format('YYYY-MM-DD') : null;
        this.form.get('paymentDate').setValue(validatedDate);
        this.form.get('paymentDate').markAsTouched();
    }

    setEmissionDate(date: Date): void {
        const validatedDate = date ? moment(date).format('YYYY-MM-DD') : null;
        this.form.get('emissionDate').setValue(validatedDate);
        this.form.get('emissionDate').markAsTouched();
    }

    setRegistrationDate(date: Date): void {
        const validatedDate = date ? moment(date).format('YYYY-MM-DD') : null;
        this.form.get('registrationDate').setValue(validatedDate);
        this.form.get('registrationDate').markAsTouched();
    }

    private initializeForm(
        remainingSupplierInvoice: number,
        iva: number,
    ): void {
        this.form = this.fb.group({
            code: ['', Validators.required],
            emissionDate: ['', Validators.required],
            registrationDate: ['', Validators.required],
            createNewPayment: [false],
            paymentMethod: [null],
            paymentAmount: [remainingSupplierInvoice],
            paymentDate: [''],
            nonDeductibleTax: [true],
            netCommission: [safeNumber(iva, 21), Validators.required],
            intracommunityOperation: [false],
        });

        this.createNewPayment.valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(createNewPaymentIsAvailable => {
            if (createNewPaymentIsAvailable) {
                this.paymentMethod.setValidators([Validators.required]);
                this.paymentAmount.setValidators([
                    Validators.required,
                    Validators.min(0.1),
                    Validators.max(remainingSupplierInvoice)
                ]);
                this.paymentDate.setValidators([Validators.required]);
            } else {
                this.paymentMethod.clearValidators();
                this.paymentAmount.clearValidators();
                this.paymentDate.clearValidators();
            }
            this.paymentMethod.updateValueAndValidity();
            this.paymentAmount.updateValueAndValidity();
            this.paymentDate.updateValueAndValidity();
        });
    }
}
