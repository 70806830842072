import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import Pax from './Pax';
import { t } from 'typy';

@Injectable({
    providedIn: "root"
})
export class PaxesService {
    private paxSource = new BehaviorSubject<Pax>(new Pax(1, 1, []));
    currentPax = this.paxSource.asObservable();

    constructor() {
    }

    changePax(pax: Pax) {
        const safePax: Pax = Object.assign({}, pax, {
            adults: Number(pax.adults) || 1,
            children: t(pax, 'children').safeArray,
            rooms: Number(pax.rooms) || 1,
        });
        this.paxSource.next(safePax);
    }
}
