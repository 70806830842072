import {FlightPhoneType, IFlightBookingPax } from "@vecib2c/frontend-dto";
import { safeNumber } from "../../functions/index";
import { MultidestinationPassenger } from "../../interfaces/multidestination/multidestination-pre-booking.model";

export class FlightBookingPaxFromMultidestinationFactory {

    static buildFromMultidestinationPassengers(
        passengers: MultidestinationPassenger[],
        destinationIndex: number,
    ): IFlightBookingPax[] {
        const arr = passengers.map(passenger => {
            const {
                abbreviation,
                name,
                lastname,
                phone,
                email,
                documentType,
                documentNumber,
                birthdate,
                documentExpirationDate,
                nationality,
                phoneNumberCode,
                type,
                phoneType,
                luggages,
            } = passenger;

            const bookingPax: IFlightBookingPax = {
                abbreviation,
                name,
                lastName: lastname,
                phone,
                email,
                typeDocument: documentType,
                documentNumber,
                birthdate,
                expirationDate: documentExpirationDate,
                nationality,
                internationalCode: safeNumber(phoneNumberCode),
                residentCityCode: '',
                typePax: type,
                phoneType: phoneType as FlightPhoneType,
            };

            if (!luggages) { return bookingPax; }

            const luggageFound = luggages.find(luggage => luggage.destinationIndex === destinationIndex);
            if (luggageFound) {
                bookingPax.luggageNumberOption = luggageFound.luggageNumberOption;
                bookingPax.luggageOption = luggageFound.luggageOption;
                bookingPax.luggageId = luggageFound.luggageId;
            }
            return bookingPax;
        });
        return arr;
    }
}
