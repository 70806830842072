import { Pipe, PipeTransform } from "@angular/core";
import { roundNumber } from "../../functions/index";

@Pipe({
    name: 'getCarPriceByDay'
})
export class GetCarPriceByDayPipe implements PipeTransform {

    transform(price: number, pickUpDate: string, dropOffDate: string): number {
        if (!price) { return; }
       // tslint:disable-next-line:max-line-length
       const pickup = new Date(pickUpDate.split('/')[2] + '-' + pickUpDate.split('/')[1] + '-' + pickUpDate.split('/')[0]);
       // tslint:disable-next-line:max-line-length
       const dropoff = new Date(dropOffDate.split('/')[2] + '-' + dropOffDate.split('/')[1] + '-' + dropOffDate.split('/')[0]);
       const diff = dropoff.getTime() - pickup.getTime();

       if (diff !== 0) {
           return roundNumber(price / Math.round(diff / (1000 * 60 * 60 * 24)));
       } else {
           return roundNumber(price);
       }
    }
}
