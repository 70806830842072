import { Component, Input } from "@angular/core";
import { ShoppingBasketTrains } from "../../../lib-shared/interfaces/index";
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-trains-cart-item',
    templateUrl: './trains-cart-item.component.html',
    styleUrls: ['./trains-cart-item.component.scss']
})
export class TrainsCartItemComponent {

    @Input() item: ShoppingBasketTrains;

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }
}
