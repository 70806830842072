import { Component, OnInit } from '@angular/core';
import { TecnoturisService } from '../../tecnoturis.service';
import { hotelAndFlightFormMetaData } from './hotel-and-flight-booking.form';

@Component({
    selector: 'lib-hotel-and-flight-booking',
    templateUrl: './hotel-and-flight-booking.component.html',
    styleUrls: ['./hotel-and-flight-booking.component.scss']
})
export class HotelAndFlightBookingComponent implements OnInit {
    hotelAndFlightCrud = hotelAndFlightFormMetaData;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.hotelAndFlight) {
            this.hotelAndFlightCrud = this.tecnoturisService.config.forms.hotelAndFlight;
        }
    }

    ngOnInit() {
    }

}
