import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancellationPoliciesButtonComponent } from './cancellation-policies-button.component';
import { MatCardModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { MdePopoverModule } from '@material-extended/mde';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [
        CancellationPoliciesButtonComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatToolbarModule,
        MdePopoverModule,
        MatTooltipModule,
        LibSharedModule,
    ],
    exports: [
        CancellationPoliciesButtonComponent,
        MatTooltipModule
    ]
})
export class CancellationPoliciesButtonModule {
}
