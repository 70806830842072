import { Component, Input, OnInit, } from "@angular/core";
import { FlightItinerary } from "@vecib2c/frontend-dto";
import { Segment } from "@vecib2c/frontend-dto/dist/dto/Flights/Flight/list/flight.entity";
import * as moment_ from "moment";
import { FLIGHTS_AIRPORTS } from '../../../../mock/flights';

const moment = moment_;

@Component({
    selector: "lib-flight-detail",
    templateUrl: "./flight-detail.component.html",
    styleUrls: ["./flight-detail.component.scss"]
})
export class FlightDetailComponent implements OnInit {
    @Input() segments: Segment[];
    @Input() itinerary: FlightItinerary;
    @Input() available: boolean;
    @Input() airStops: number;

    isDirectFlight: boolean;
    airports: any;

    constructor() {

    }

    ngOnInit(): void {
        this.airports = FLIGHTS_AIRPORTS;
        this.isDirectFlight = this.airStops === 0;
    }

    calculateWaitingTime(segment: Segment, nextSegment: Segment): string {
        const originMoment = moment(nextSegment.originDateTime, "DD/MM/YYYY kk:mm");
        const destinationMoment = moment(
            segment.destinationDateTime,
            "DD/MM/YYYY kk:mm"
        );
        const diff = originMoment.diff(destinationMoment);
        const diffDuration = moment.duration(diff);
        const hours = diffDuration.days() * 24 + diffDuration.hours();
        const minutes = diffDuration.minutes();

        return `${hours > 0 ? `${hours}h ` : ""}${
            minutes > 0 ? `${minutes}m` : ""
        }`;
    }
}
