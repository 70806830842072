import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'replaceHttps'
})
export class ReplaceHttpsPipe implements PipeTransform {
    transform(url: string): string {
        if (!url) { return; }
        return url.replace('https://', 'http://');
    }
}
