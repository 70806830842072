import { Component, OnInit } from '@angular/core';
import { TecnoturisService } from "../../tecnoturis.service";
import { busFormMetaData } from './bus-booking.form';

@Component({
  selector: 'lib-bus-booking',
  templateUrl: './bus-booking.component.html',
  styleUrls: ['./bus-booking.component.scss']
})
export class BusBookingComponent implements OnInit {
    busCrud = busFormMetaData;
  

  constructor(private tecnoturisService: TecnoturisService) {
    if (this.tecnoturisService.config.forms.busForm) {
      this.busCrud = this.tecnoturisService.config.forms.busForm;
    }
  }

  ngOnInit() {
  }

}
