import { NgModule } from '@angular/core';
import { SearchNavbarComponent } from './search-navbar.component';
import { MatToolbarModule } from '@angular/material';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [
        SearchNavbarComponent
    ],
    imports: [
        LibSharedModule,
        MatToolbarModule
    ],
    exports: [
        SearchNavbarComponent
    ]
})
export class SearchNavbarModule {
}
