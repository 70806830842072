import {
    HotelBookingPostResDto,
    FlightBookingPostResDto,
    PackageBookingPostResDto,
    ActivityBookingPutResDto,
    BookingProviderResDto,
} from "@vecib2c/frontend-dto";
import { ShoppingBasketType } from "..";
import { safeNumber } from "../functions/index";
import { CarBookingResDto } from "./cars/booking.res.model";
import { CircuitBooking } from "./circuits/circuit-booking.model";
import { MultidestinationBookingCreateResponse } from "./multidestination/multidestination-booking.model";
import { ShoppingBasketTrains } from './shopping-basket-trains.model';
import { ShoppingBasketTransfer } from './shopping-basket-transfers.model';

export interface TypeDocument {
    id: number;
    active: boolean;
    creation_date: Date;
    update_date: Date;
    name: string;
}

export interface ClientType {
    id: number;
    active: boolean;
    creation_date: Date;
    update_date: Date;
    name: string;
}

export interface CommercialActivitiesType {
    id: number;
    active: boolean;
    creation_date: Date;
    update_date: Date;
    name: string;
}

export interface DossierShort {
    id: number;
    code: string;
    observation: string;
}

export interface CommercialActivitiesStatus {
    id: number;
    active: boolean;
    creation_date: Date;
    update_date: Date;
    name: string;
}

export interface Setup {
    [k: string]: { id: number, name: string }[];
}

export interface HotelRoom {
    id: number;
    active: boolean;
    creation_date: Date;
    created_by?: any;
    update_date: Date;
    update_by?: any;
    checkIn?: any;
    checkOut?: any;
    price?: any;
    rateId?: any;
    roomId?: any;
    quantity: number;
    hotel_booking: number;
    room_type: { id: number, name: string };
    pax: number[];
    cancellation_amount: number;
    cancellation_from: Date;
}

export interface BookingHotel {
    id: number;
    hotel_rooms: HotelRoom[];
    active: boolean;
    creation_date: Date;
    created_by?: any;
    update_date: Date;
    update_by?: any;
    hotelId: number;
    language?: any;
    hotelName: string;
    price: number;
    tolerance?: any;
    full_name?: any;
    destination: string;
    checkIn: string;
    checkOut: string;
    regimen?: any;
    cancelation_to?: any;
    accommodation_name: string;
    accomodation_address: string;
    postal_code?: any;
    country: string;
    province: string;
    locality: string;
    phone?: any;
    pay_by?: any;
    valorate_bono?: any;
    hotel_observation?: any;
    agency_provider_service?: any;
    dossier: any;
    price_pvp: number;
}

export interface BudgetHotel {
    id: number;
    booking_hotel: BookingHotel;
    active: boolean;
    creation_date: Date;
    created_by?: any;
    update_date: Date;
    update_by?: any;
    budget: number;
}

export interface BookingService {
    id: number;
    description: string;
    total_pvp: number;
    total_abono: number;
    provider_service_id: number;
}

export interface BudgetBookingService {
    id: number;
    booking_service: BookingService;
}

export interface Budget {
    id: number;
    code: string;
    budget_type?: any;
    creation_date: Date;
    total_pvp: number;
    total_abono: number;
    budget_booking_service: BudgetBookingService[];
    active: boolean;
    number: number;
    is_cancel: boolean;
    include_parent_service_in_the_calculation: boolean;
}

export interface BudgetFlight {
    "id": number;
    "booking_flight": {
        "id": number;
        "price_pvp": number;
        "flight_booking_segment": FlightBookingSegment[];
    };
    "budget": number;
}

export interface FlightBookingSegment {
    "id": number;
    "flight_number": string;
    "segment_number": number;
    "departure": string;
    "arrival": string;
    "departure_at": Date;
    "arrival_at": Date;
    "airline": string;
}

export interface BudgetDetail {
    budget: Budget;
    client: IClientBudget;
    dossier: DossierShort;
}

export interface IClientBudget {
    "id": number;
    "agency": any;
    "name": string;
    "last_name": string;
    "home_phone": string;
    "phone": string;
    "dni": string;
    "address": string;
    "email": string;
    "birthdate": string;
    "active": boolean;
    "business_name": string;
    "accountant_account": string;
    "country": string;
    "province": string;
    "locality": string;
    "phone2": string;
    "down_notification": string;
    "code": string;
    "points": string;
    "observation": string;
    "permit_email": string;
    "postal_code": string;
    "nationality": string;
    "city": string;
    "type_document": number;
    "client_type": number;
    "fiscal_location": string;
    "payment_method": number;
    age?: number;
    final_name?: string;
}

export interface BookingList {
    id: number;
    service: string;
    destination: string;
    checkIn: string;
    checkOut: string;
    provider: string;
    localizador: string;
    price_pvp: number;
    price: number;
    fee: number;
    fee_agency: number;
    flight?: boolean;
    hotel?: boolean;
    package?: boolean;
}

export interface IService {
    id: number;
    name: string;
    code: string;
}

export interface IFlightCategory {
    id: number;
    active: boolean;
    name: string;
}

export interface ICommercialActivity {
    id: number;
    active: boolean;
    creation_date: string;
    created_by: string;
    update_date: string;
    update_by: string;
    observations: string;
    opening_date: string;
    client: number;
    commercial_activities_type: number;
    commercial_activities_status: number;
    subject: string;
    description: string;
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
}

export interface ICalendarAlert {
    count: number;
    next: number;
    previous: number;
    result: ICommercialActivity[];
}

export interface PaymentOption {
  id?: number;
  bank_name?: string;
  description: string;
  accountant_account?: string;
  commission?: number;
  commission_rate?: number;
}

export class PaymentDossier {
  id?: number;
  payment_method: number;
  cash: number;
  bank_account: number;
  credit_card: number;
  paid_amount: number;
  paid_date: string;
  observation?: string;
  dossier: number;
  is_abono: boolean;
}

export interface CashOutProvider {
    id: number;
    name: string;
}

export interface IBookingService {
    id: number;
    service: {
        "id": number,
        "name": string;
    };
    provider: {
        "id": number;
        "name": string;
    };
    locator: string;
    total_pvp: number;
    total_net: number;
    total_abono: number;
    comission: number;
    discount: number;
    iva: number;
    has_invoice: boolean;
    fee_apply: boolean;
    disabled: boolean;
    price_from_provider: number;
    raw_data: any;
}

export interface PaymentMethod {
  id: number;
  name: string;
  accountant_code: string;
  initial_code: number;
  active: boolean;
}

export interface TecnoturisPartner {
    tecnotravel: TecnoturisPartnerCredential;
    tecnoflight: TecnoturisPartnerCredential;
}

export interface TecnoturisPartnerCredential {
    url: string;
    user: string;
    pass: string;
}

export interface BookingDosierRequest {
    hotelData?: HotelBookingPostResDto[];
    flightData?: FlightBookingPostResDto[];
    packageData?: PackageBookingPostResDto[];
    circuitData?: CircuitBooking[];
    safeData?: BookingProviderResDto[];
    carData?: CarBookingResDto[];
    trainData?: ShoppingBasketTrains[];
    transferData?: ShoppingBasketTransfer[];
    hfData?: any[];
    activityData?: ActivityBookingPutResDto[];
    multidestinationData?: MultidestinationBookingCreateResponse[];
    client: number;
    dossier?: number | null;
}

export interface BookingDossier {
    description: string;
    id: number;
    locator:string;
    total_abono: number;
    total_pvp: number;
}

export class Prepaid {
    payment_method: number;
    payment_amount: number;
    payment_date: string;
    booking_service: number;
    active: boolean;
    created_by: string;
    creation_date: string;
    id: number;
    update_by: string;
    update_date: string;
    booking_service_supplier_invoice: number;
}

export class PrepaidRequest {
    payment_method: number;
    payment_amount: number;
    payment_date: string;

    static buildFromForm(form: PrepaidForm): PrepaidRequest {
        const { paymentMethod, paymentAmount, paymentDate} = form;
        return {
            payment_method: paymentMethod,
            payment_amount: safeNumber(paymentAmount),
            payment_date: paymentDate,
        };
    }
}

export interface PrepaidForm {
    paymentMethod: number;
    paymentAmount: string;
    paymentDate: string;
}

export class SupplierInvoice {
    id: number;
    booking_service: number;
    code: string;
    emission_date: string;
    registration_date: string;
    payment_method?: number;
    payment_amount?: number;
    payment_date?: string;
    non_deductible_tax: boolean;
    net_commission: number;
    prepaids: Prepaid[];
    intracommunity_operation: boolean;
}

export class SupplierInvoiceRequest {
    code: string;
    emission_date: string;
    registration_date: string;
    payment_method?: number;
    payment_amount?: number;
    payment_date?: string;
    non_deductible_tax: boolean;
    net_commission: number;
    intracommunity_operation: boolean;

    static buildFromForm(form: SupplierInvoiceForm): SupplierInvoiceRequest {
        const {
            code,
            emissionDate,
            registrationDate,
            createNewPayment,
            paymentMethod,
            paymentAmount,
            paymentDate,
            nonDeductibleTax,
            netCommission,
            intracommunityOperation,
        } = form;
        const params: SupplierInvoiceRequest = {
            code,
            emission_date: emissionDate,
            registration_date: registrationDate,
            non_deductible_tax: nonDeductibleTax,
            net_commission: safeNumber(netCommission),
            intracommunity_operation: intracommunityOperation,
        };

        if (createNewPayment) {
            params.payment_method = paymentMethod;
            params.payment_amount = safeNumber(paymentAmount);
            params.payment_date = paymentDate;
        }
        return params;
    }
}

export interface SupplierInvoiceForm {
    code: string;
    emissionDate: string;
    registrationDate: string;
    createNewPayment: boolean;
    paymentMethod?: number;
    paymentAmount?: string;
    paymentDate?: string;
    nonDeductibleTax: boolean;
    netCommission: string;
    intracommunityOperation: boolean;
}

export interface RawBookingService {
    id: number;
    service: number;
    type: number;
    raw_data: ShoppingBasketType;
    paxes: ManagementPax[];
}

export interface ManagementPax {
    id: number;
    name: string;
    last_name: string;
    home_phone: string;
    phone: string;
    dni: string;
    address: string;
    email: string;
    birthdate: string;
    active: boolean;
    creation_date: string;
    created_by: string;
    update_date: string;
    update_by: string;
    expiration_document: string;
    age: number;
    ages: number;
    code:string;
    type: string;
    person_title: string;
    type_document: number;
}
