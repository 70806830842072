import { TrainDocument } from "../lib/lib-shared/interfaces/index";

export const trainDocuments: TrainDocument[] = [
    {
        id: 'DNI', value: 'DNI',
    },
    {
        id: 'PAS', value: 'PAS',
    },
    {
        id: 'NIE', value: 'NIE',
    }
];
