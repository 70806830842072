export * from './flight-package-paxes/flight-package-paxes.component';
export * from './flight-packages/flight-packages.component';
export * from './flight-packages-selection/flight-packages-selection.component';
export * from './item-flight-packages/item-flight-packages.component';
export * from './item-package-card/item-package-card.component';
export * from './item-package-ticket-card/item-package-ticket-card.component';
export * from './item-package-card-detail/item-package-card-detail.component';
export * from './item-package-card-detail/addons/addons.component';
export * from './item-package-card-detail/detail-information/detail-information.component';
export * from './item-package-card-detail/detail-room/detail-room.component';
export * from './item-package-card-detail/detail-service/detail-service.component';
export * from './packages.module';
