import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { TecnoturisService } from '../../tecnoturis.service';

@Injectable()
export class FilterService {

    constructor(
        private http: HttpClient,
        @Optional() @Inject("filterHost") private provider,
        private tecnoturisService: TecnoturisService
    ) {
    }

    private getPath(action) {
        const list = [`${this.tecnoturisService.config.filterHost}`, "api/v1", action];
        return list.join("/");
    }

    listAccomodations(): Observable<any> {
        const url = this.getPath("accommodations");
        return of({
            "accommodations": [
                {
                    "accommodation": "Hotel"
                },
                {
                    "accommodation": "Hostal"
                },
                {
                    "accommodation": "Apartahotel"
                },
                {
                    "accommodation": "Apartamento"
                },
                {
                    "accommodation": "Casa Rural"
                },
                {
                    "accommodation": "Cámping"
                },
                {
                    "accommodation": "Pensión"
                },
                {
                    "accommodation": "Residencia"
                },
                {
                    "accommodation": "Resort"
                },
                {
                    "accommodation": "Villa"
                }
            ]
        })
    }

    listCategories(): Observable<any> {
        const url = this.getPath("rates");
        return of({
            "rates": [
                {
                    "rateName": "Solo alojamiento"
                },
                {
                    "rateName": "Desayuno incluido"
                },
                {
                    "rateName": "Media Pensión"
                },
                {
                    "rateName": "Pensión completa"
                },
                {
                    "rateName": "Todo incluido"
                }
            ]
        })
    }

    listRooms(): Observable<any> {
        const url = this.getPath("rooms");
        return this.http.get<any>(url);
    }
}
