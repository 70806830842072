import { Component, Input, OnInit } from '@angular/core';
import { ShoppingBasketPackage } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-packages-book-summary',
    templateUrl: './packages-book-summary.component.html',
    styleUrls: ['./packages-book-summary.component.scss']
})
export class PackagesBookSummaryComponent implements OnInit {

    @Input() set pack(shoppingBasket: ShoppingBasketPackage) {
        this._pack = shoppingBasket;
        if (shoppingBasket) {
            this.total = shoppingBasket.totalAmount;
        }
    }

    get pack(): ShoppingBasketPackage {
        return this._pack;
    }

    total = 0;

    private _pack: ShoppingBasketPackage;

    constructor() {
    }

    ngOnInit() {
    }

    getCategory(starsNumber: any): Array<number> {

        if (starsNumber == undefined) {
            return [1]
        }
        
        const stars = [1, 2, 3, 4, 5];
        return stars.filter((star) => {
            return star <= starsNumber.slice(0, 1);
        });
    }
}
