import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-page-not-found',
  template: `<h1>Page Not Found</h1>`,
})
export class PageNotFoundComponent {

  constructor() {
      
  }

  ngOnInit(){
    window.location.href = environment.veciHome;
  }

}