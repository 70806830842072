import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {
    IActivityDestination,
    IActivitySearchResponse,
    ICountry,
    ISegmentationGroup,
    ActivityBookingDeleteReqDto,
    ActivityBookingDeleteResDto,
    ActivityBookingGetReqDto,
    ActivityBookingGetResDto,
    ActivityBookingPutReqDto,
    ActivityBookingPutResDto,
    ActivityDetailGetReqDto,
    ActivityDetailGetResDto,
    ActivitySearchGetReqDto,
    ActivitySearchGetResDto,
    IActivityDestinationRQ,
    IActivityDestinationW2M
} from '@vecib2c/frontend-dto';
import { delayedRetry, objectToQuerystringWithArrayByKey } from '../functions/index';
import { Dtos } from '@vecib2c/frontend-dto/dist/dto/Activities/Dtos';
import { TecnoturisService } from '../../tecnoturis.service';
import { filters } from '../../../mock/activities';
import { ActivityFilterType } from '../interfaces/activities/activity-filter.model';
import {BasketManager} from "./BasketManager";
import {ManagementService} from "./management.service";
import {ClientServices} from "./client.services";
import {IntegrationsService} from "./integrations.service";
import { ShoppingBasketActivity } from '..';
import { Rerate } from '../interfaces/rerate.model';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {

    private endpoint;
    private management;
    
    private readonly MAX_RETRIES = 6;
    private readonly DELAY_MS = 500;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService,
                private readonly basketManager: BasketManager,
                private managementService: ManagementService,
                private clientService: ClientServices,
                private integrationsService: IntegrationsService) {
        this.endpoint = `${this.tecnoturisService.config.activities}/api/v1`; 
        this.management = `${this.tecnoturisService.config.management}`;
  /*       this.endpoint = `${this.tecnoturisService.config.activitiesw2m}/api/v1`;
         */
    }

    getCountries(lang = 'es'): Observable<ICountry[]> {
        const url = `${this.endpoint}/contents/countries/${lang}`;
        return this.http.get<ICountry[]>(url)
            .pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    }
    /* getDestinations(request): Observable<IActivityDestinationW2M> {
        let params = new HttpParams();
        Object.keys(request).forEach(key => {
            params = params.append(key, request[key]);
        });
        const url = `${this.endpoint}/location/GetDestinationsList`;
        return this.http.get<IActivityDestinationW2M>(url,{ 
            params: params,
        })
    } */

    getDestinations(country: string, lang = 'ES'): Observable<IActivityDestination[]> {
        const url = `${this.endpoint}/contents/destinations/${lang}/${country}`;
        return this.http.get<IActivityDestination[]>(url)
            .pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    }

    getSegments(lang = 'es'): Observable<ISegmentationGroup[]> {
        const url = `${this.endpoint}/contents/segments/${lang}`;
        return this.http.get<ISegmentationGroup[]>(url)
            .pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    }

    getFilters(): Observable<ActivityFilterType> {
        return of(filters);
    }

    search(queryParams: ActivitySearchGetReqDto): Observable<ActivitySearchGetResDto> {
        // delete queryParams.priceTo;
        // delete queryParams.priceFrom;
        delete queryParams.segment;
        const queryString = objectToQuerystringWithArrayByKey(queryParams);
        return this.http
            .get<Dtos<IActivitySearchResponse>>(`${this.endpoint}/activities${queryString}`)
            .pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    }

  /*   search(queryParams: ActivitySearchGetReqDto): Observable<ActivitySearchGetResDto> {
        // delete queryParams.priceTo;
        // delete queryParams.priceFrom;
        delete queryParams.segment;
        const queryString = objectToQuerystringWithArrayByKey(queryParams);
        return this.http
            .get<Dtos<IActivitySearchResponse>>(`${this.endpoint}/availability_w2m${queryString}`)
            .pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    }
 */

    activityDetail(data: Partial<ActivityDetailGetReqDto>): Observable<ActivityDetailGetResDto> {
        const activityCode = data.code;
        delete data.code;
        const queryString = objectToQuerystringWithArrayByKey(data);
        return this.http.get<ActivityDetailGetResDto>(`${this.endpoint}/activities/${activityCode + queryString}`)
            .pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    } 
    
    preBooking(data: any): Observable<ActivityDetailGetResDto> {
        return this.http.post<any>(`${this.endpoint}/select/prebooking`,data).pipe(delayedRetry(this.DELAY_MS, this.MAX_RETRIES), catchError(e => EMPTY), shareReplay());
    }
  /*   confirmBooking(booking: ActivityBookingPutReqDto, saveBudget = false): Observable<ActivityBookingPutResDto> {
        return this.http.post<ActivityBookingPutResDto>(`${this.endpoint}/confirm/booking`, booking).pipe(tap(async response => {
            if (saveBudget) {
                if (response) {
                    const client = await this.clientService.getClient();
                    const dossier = this.integrationsService.validAndGetDossier();
                    const data = {activityData: [response], client: client.id, dossier: dossier};   
                  const newdata:any =  await this.managementService.addServicesToDossier(data).toPromise();
                  return Object.assign(
                    response,
                    newdata[0]
                );
                  
                }
            }
        }));
    } */
    confirmBooking(booking: ActivityBookingPutReqDto, saveBudget = false): Observable<ActivityBookingPutResDto> {
        return this.http.post<ActivityBookingPutResDto>(`${this.endpoint}/bookings`, booking).pipe(tap(async response => {
            if (saveBudget) {
                if (response) {
                    const client = await this.clientService.getClient();
                    const dossier = this.integrationsService.validAndGetDossier();
                    const data = {activityData: [response], client: client.id, dossier: dossier};
                    const dossierInfo =await this.managementService.addServicesToDossier(data).toPromise();
                    
                    return Object.assign(response,dossierInfo[0]);
                }
            }
        }));
    }

    bookFromBasket(item: ShoppingBasketActivity) {
        return this.confirmBooking(item.bookingParameters).toPromise()
        .then(res => {
            if (res) {
                return Promise.resolve(res);
            }
            item.failed = true;
            return Promise.reject(item);
        }).catch(err => {
            item.failed = true;
            return Promise.reject(item);
        });
    }

    bookingDetail(booking: ActivityBookingGetReqDto): Observable<ActivityBookingGetResDto> {
        const url = `${this.endpoint}/bookings/${booking.language}/${booking.referenceCode}`;
        return this.http.get<ActivityBookingGetResDto>(url);
    }

    cancelBooking(booking: ActivityBookingDeleteReqDto): Observable<ActivityBookingDeleteResDto> {
        const url = `${this.endpoint}/bookings/${booking.language}/${booking.referenceCode}`;
        return this.http.delete<ActivityBookingGetResDto>(url);
    }

   /*  cancelBooking(booking: ActivityBookingDeleteReqDto): Observable<ActivityBookingDeleteResDto> {
        const url = `${this.endpoint}/cancel/booking`;
        return this.http.post<any>(url,booking);
    } */
    RemoveDossier(id: any): Observable<any> {
        const url = `${this.management}/booking-service/${id}/`;
        return this.http.delete<any>(url);
    }

    rerate(
        params: Partial<ActivityDetailGetReqDto>,
        modalityCode: string,
        rateCode: string,
    ): Promise<Rerate> {
        return this.activityDetail(params).toPromise()
        .then(res => {
            const modalityFound = res.modalities.find(m => m.code === modalityCode);
            if (!modalityFound) { return undefined; }
            const rateFound = modalityFound.rates.find(r => r.rateCode === rateCode);
            if (!rateFound) { return undefined; }
            return Rerate.buildFromActivity(rateFound.rateDetails[0]);
        }).catch(err => {
            return undefined;
        });
    }

    rerateFromBasket(item: ShoppingBasketActivity): Promise<Rerate> {
        const { code, from, to, modalityCode, paxes, rateCode } = item;
        const params: Partial<ActivityDetailGetReqDto> = {
            code,
            from,
            to,
            language: 'es',
            paxes,
            full: true,
        };
        return this.rerate(params, modalityCode, rateCode);
    }

}
