import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightBookingComponent } from './flight-booking.component';
import { Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';

const routes: Routes = [
    {
        path: 'create',
        component: FlightBookingComponent,
    },
    {
        path: 'edit/:id',
        component: FlightBookingComponent,
    }
];

@NgModule({
    declarations: [FlightBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
        // RouterModule.forChild(routes)
    ],
    exports: [FlightBookingComponent]
})
export class FlightBookingModule {
}
