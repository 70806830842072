import {ROLES} from '../../const';

export enum FieldType {
    DATE = 'date',
    CHAR = 'input',
    NUMBER = 'number',
    CHECKBOX = 'boolean',
    IMAGE = 'file',
    EMAIL = 'email',
    LIST = 'list',
    TEXTAREA = 'textarea',
    VALIDATE = 'validate',
    DATETIME = 'datetime',
    PASSWORD = 'password',
    SEARCH_SELECT = 'search_select',
    LIST_TRANSFER = 'list'
}

export enum CRUD_TYPE {
    EDIT = 'edit',
    CREATE = 'create'
}

export interface Field extends RolePermissions {
    isGroup?: boolean;
    keyName: string;
    name: string;
    type?: FieldType;
    showInTable?: boolean;
    fieldChilds?: Field[];
    hideWhen?: { model: string, value: any };
    hideNotWhen?: { model: string, value: any };
    validators?: string[];
    removeOnCreate?: boolean;
    hideOnForm?: boolean;
    disabled?: boolean;
    required?: boolean;
    defaultValue?: any;
    saveInKey?: string;
    getIn?: string;
    replaceKey?: string;
    hidden?: boolean;
    readonly?: boolean;
    validations?: IValidatorField[];
    optionKey?: string;
    validateUrlPath?: string;
    saveAs?: string;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    conditionEnabled?: any;
    multiple?: boolean;
    formats?: string[];
}

export interface IDynamicCrud<T = any> extends RolePermissions {
    showFilters?: boolean;
    crud: string;
    name: string;
    filters?: Field[];
    fields: Field[];
    urlPath: string;
    urlPathList?: string;
    modal?: boolean;
    modalConfig?: any;
    dynamicCruds?: IDynamicCrud[];
    extraKeySave?: string[];
    crudFiles?: IDynamicCrudFiles;
    extractDataFrom?: string;
    extractIsArray?: boolean;
    appendKey?: string;
    deleteUrl?: string;
}

export interface RolePermissions {
    cantAdd?: ROLES[];
    cantEdit?: ROLES[];
    cantDelete?: ROLES[];
    cantShow?: ROLES[];
}

export interface IDynamicCrudFiles {
    idFilter?: any;
    key: string;
    path: string;
    urlPathList: string;
    roles?: ROLES[];
}

export interface IDynamicDataTable {
    [key: string]: any;
}

export interface IDynamicCrudQueryParams {
    [key: string]: string;
}

export interface IPaginate<T> {
    count: number;
    next: string;
    previous: string;
    results: T[];
}

export interface IValidatorField {
    key: string;
    message: string;
    expression: RegExp;
}

