import { NgModule } from "@angular/core";
import { LibSharedModule } from "../../../lib-shared/index";
import { PackagePriceSummaryComponent } from "./package-price-summary.component";

@NgModule({
    declarations: [
        PackagePriceSummaryComponent
    ],
    imports: [
        LibSharedModule,
    ],
    exports: [
        PackagePriceSummaryComponent
    ]
})
export class PackagePriceSummaryModule {

}
