import { NgModule } from "@angular/core";
import { InvalidAlertComponent } from "./invalid-alert.component";
import { LibSharedModule} from "../../../lib-shared/lib-shared.module";

@NgModule({
    declarations: [
        InvalidAlertComponent,
    ],
    imports: [
        LibSharedModule,
    ],
    exports: [
        InvalidAlertComponent
    ]
})
export class InvalidAlertModule {

}
