import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthResponse, IUserInfo } from "../interfaces/index";
import { AUTH_TOKEN_KEY_STORAGE, AUTH_USER_KEY_STORAGE } from "../../tokens";
import { ROLES } from "../../const";
import { TecnoturisService } from "../../tecnoturis.service";
import { Storage } from "@ionic/storage";
import { BasketManager } from "./BasketManager";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  protected LOGIN_URL;
  protected LOGOUT_URL;
  protected me;

  protected cookieRefreshUrl;

  protected isAuthenticated = new BehaviorSubject(
    AuthenticationService.isAuthenticated()
  );

  private LAST_USER_STORAGE_KEY = "last_user";

  constructor(
    private http: HttpClient,
    private router: Router,
    private tecnoturisService: TecnoturisService,
    protected storage: Storage,
    protected basketManager: BasketManager
  ) {
    const managementHost = this.tecnoturisService.config.management;
    this.LOGIN_URL = managementHost + "/user/auth/token-auth/";
    this.LOGOUT_URL = managementHost + "/auth/logout/";
    this.me = managementHost + "/user/me/";

    this.cookieRefreshUrl = managementHost + "/user/auth/token-refresh/";

    this.isAuthenticated = new BehaviorSubject(
      AuthenticationService.isAuthenticated()
    );
  }

  static getUser(): IUserInfo {
    if (AuthenticationService.isAuthenticated()) {
      return JSON.parse(localStorage.getItem(AUTH_USER_KEY_STORAGE));
    }
    return null;
  }

  static isAuthenticated(): boolean {
    return !!localStorage.getItem(AUTH_USER_KEY_STORAGE);
  }

  static setToken(token: string): void {
    localStorage.setItem(AUTH_TOKEN_KEY_STORAGE, token);
  }

  static getToken(): string {
    return localStorage.getItem(AUTH_TOKEN_KEY_STORAGE);
  }

  static getTokenWithBearer(): string {
    return `Bearer ${AuthenticationService.getToken()}`;
  }

  static hasPermissions(roles: ROLES[]): boolean {
    const role = AuthenticationService.getUser().role.id;
    return roles.indexOf(role) > -1;
  }

  setUser(user): void {
    localStorage.setItem(AUTH_USER_KEY_STORAGE, JSON.stringify(user));
    this.isAuthenticated.next(AuthenticationService.isAuthenticated());
  }

  isAuthenticatedSubject(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  async login(
    username: string,
    password: string,
    microsite = false
  ): Promise<AuthResponse> {
    const response: AuthResponse = await this.http
      .post<AuthResponse>(
        this.LOGIN_URL,
        { username, password },
        { withCredentials: true }
      )
      .toPromise();
      if(response){
        AuthenticationService.setToken(response.token);
        this.getSession(!microsite).subscribe();
      }
    return response;
  }

  logout(redirect = true) {
    this.http.delete(this.LOGOUT_URL).subscribe(
      (data) => {
        localStorage.clear();
        this.isAuthenticated.next(false);
        if (redirect) {
          this.router.navigate(["login"]);
        }
      },
      (error) => {
        localStorage.clear();
        this.isAuthenticated.next(false);
        if (redirect) {
          this.router.navigate(["login"]);
        }
      }
    );
  }

  getSession(redirect = true): Observable<any> {
    return this.http.get(this.me).pipe(
      tap((response) => {
        this.setUser(response);
        this.storage.get(this.LAST_USER_STORAGE_KEY).then((lastUser) => {
          if (response.id !== lastUser) {
            this.basketManager.removeBasket();
          }

          this.storage.set(this.LAST_USER_STORAGE_KEY, response.id);

          if (redirect) {
            this.router.navigate(["intranet", "search"]);
          }
        });
      })
    );
  }

  refreshToken(): Observable<AuthResponse> {
    return this.http
      .post<AuthResponse>(this.cookieRefreshUrl, {
        token: AuthenticationService.getToken(),
      })
      .pipe(
        tap((response) => {
          AuthenticationService.setToken(response.token);
          this.getSession(true).subscribe();
        })
      );
  }
}
