import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatSelectChange} from "@angular/material/select/typings/select";

@Component({
    selector: 'lib-addons',
    templateUrl: './addons.component.html',
    styleUrls: ['./addons.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class AddonsComponent implements OnInit, OnDestroy {

    @Input() optionals: any;
    @Input() hotel: any;
    @Output() emitReserve = new EventEmitter();

    fields = {
        "id": "ID",
        "description": "Descripción",
        "unit_price": "Precio Unitario",
        "distribution": "Adultos / Niños / Infantes",
        "available_date": "Fecha Disponible",
    };

    displayedColumns = ['selectable', 'description', 'unit_price', 'distribution', 'available_date'];
    columnsToDisplay: string[] = this.displayedColumns.slice();

    selection = new SelectionModel<any>(true, []);

    expandedElement: any | null;

    lastSelected;

    unsubscribe = new Subject();

    airports = [
        {id: 'CDG', name: 'Charles de Gaulle'},
        {id: 'ORY', name: 'Orly'},
    ];

    constructor() {
    }

    ngOnInit() {
        this.selection.changed
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                const list = value.source.selected;
                const length = list.length;
                this.lastSelected = length > 0 ? list[length - 1] : null;
                this.expandedElement = this.lastSelected && this.lastSelected['DatosTRF'] ? this.lastSelected : null;
                if (value.added.length > 0) {
                    value.added[0].selected = true;
                }
                if (value.removed.length > 0) {
                    value.removed[0].selected = false;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    changeSelectionDate(event: MatSelectChange, iOption, indexOptionValue,  objName, optValue, objSubName = null) {
        if (this.optionals.length > 0) {
            this.optionals.map((option, indexOption) => {
                return option.Opciones.map((object, indOptionVal) => {
                    if (objSubName) {
                        object[objName][objSubName].map((objDate) => {
                            if (indexOption === iOption  && indOptionVal === indexOptionValue) {
                                objDate.selected = objDate[optValue] === event.value && event.source.selected['selected'];
                            }
                            return objDate;
                        });
                    } else {
                        object[objName].map((objDate) => {
                            if (indexOption === iOption) {
                                objDate.selected = objDate[optValue] === event.value && event.source.selected['selected'];
                            }
                            return objDate;
                        });
                    }
                    return object;
                });
            });
        }
    }
}
