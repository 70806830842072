import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getNameById'
})
export class GetNameByIdPipe implements PipeTransform {

    constructor() {
    }

    public transform(value: number, list: Array<{ id: number, name: string }>): any {
        const val = list.find(v => v.id === value);
        if (val) {
            return list.find(v => v.id === value).name;
        }
        return value;
    }
}
