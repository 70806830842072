import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'cleanImage'
})
export class CleanImagePipe implements PipeTransform {

    transform(url: string, imageNotFound = '/assets/images/not-found.png'): string {
        if (!url) { return imageNotFound; }
        return url;
    }
}
