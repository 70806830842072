import { OnDestroy, OnInit } from '@angular/core';
import {
    Class,
    IPriceRangeFilter,
    Itinerary2,
    PreBookingResponse,
    PreBookingsCreateRequestDto,
    SalesGroup,
    ShoppingBasketTrains,
    Trains,
    TrainsRequestDto
} from '../lib-shared/interfaces/index';
import {
    IntegrationPageMetaData,
    IntegrationsService,
    NotificationPopupService,
    TrainsProviderService
} from '../lib-shared/services/index';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import typy_ from "typy";
import * as uuidv1_ from 'uuid/v1';

const typy = typy_;
const uuidv1 = uuidv1_;

const PAGE_START = 0;

const PAGE_SIZE = 10;

export class TrainsSearchList implements OnInit, OnDestroy {

    parametersForTrains = [
        'origin',
        'originName',
        'destination',
        'destinationName',
        'checkIn',
        'checkOut',
        'numberOfAdults',
        'numberOfChildren',
        'numberOfInfants',
        'page',
        'pageSize',
    ];
    inputFields: TrainsRequestDto;
    searchShowHide = false;
    filtersShowHide = false;
    trains: Trains;
    totalOutwards: number;
    totalReturns: number;
    currentPageNumberForOutward: number = PAGE_START;
    currentPageSizeForOutward: number = PAGE_SIZE;
    currentPageNumberForReturn: number = PAGE_START;
    currentPageSizeForReturn: number = PAGE_SIZE;
    currentPageNumberForAll: number = PAGE_START;
    currentPageSizeForAll: number = PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 15];
    selectedSalesGroups: SalesGroup[] = new Array<SalesGroup>();
    selectedTrainClasses: Class[] = new Array<Class>();
    selectedItineraries: Itinerary2[] = new Array<Itinerary2>();
    currency = 'EUR';
    minPrice = 0;
    maxPrice = 350;
    currentKindOfTransport = 'trenes';
    integrationMetaData: IntegrationPageMetaData;
    onlyDeparture: boolean;

    private unsubscribe = new Subject<void>();

    constructor(
        public readonly trainsService: TrainsProviderService,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly notificationService: NotificationPopupService,
        public integrationService: IntegrationsService,
    ) {
        this.integrationMetaData = {category: 'Transportes', name: 'transportes'};
        this.integrationService.setMetaData(this.integrationMetaData);
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(
                takeUntil(this.unsubscribe),
            ).subscribe(params => {
            if (this.isValidQueryString(params)) {
                this.inputFields = this.buildRequestDto(params);
                this.onlyDeparture = !!!this.inputFields.checkOut;
                this.getTrains();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    pageChanged($event, container, type: string): void {
        container.el.scrollIntoView({block: "start", behavior: "smooth"});
        if (type === 'outward') {
            this.currentPageNumberForOutward = $event.pageIndex;
            this.currentPageSizeForOutward = $event.pageSize;
        } else {
            this.currentPageNumberForReturn = $event.pageIndex;
            this.currentPageSizeForReturn = $event.pageSize;
        }
        this.getTrains(type);
    }

    selectItinerary(data: { salesGroup: SalesGroup, trainClass: Class }, itinerary: Itinerary2, typeOfItinerary: number) {
        let selectedItineraries;
        if (data.salesGroup.salesGroupCode === 'I') {
            selectedItineraries = this.selectedItineraries
                .map(i => typy(i, 'classes[0].salesGroups[0].salesGroupCode').safeObject === 'I' ? i : null);
        } else {
            selectedItineraries = this.selectedItineraries
                .map(i => typy(i, 'classes[0].salesGroups[0].salesGroupCode').safeObject !== 'I' ? i : null);
        }
        const selectedTrainClass = Object.assign({}, data.trainClass, {salesGroups: [data.salesGroup]});
        const selectedItinerary: Itinerary2 = Object.assign({}, itinerary, {classes: [selectedTrainClass]});

        selectedItineraries[typeOfItinerary] = selectedItinerary;
        this.selectedItineraries = [...selectedItineraries];
    }

    changePriceFilter(priceRange: IPriceRangeFilter): void {
        this.resetPagination();
        this.getTrains('all', priceRange);
    }

    selectKindOfTransport(kindOfTransport: string): void {
        this.currentKindOfTransport = kindOfTransport;
    }

    toggleSearcher(nameElement: string) {
        switch (nameElement) {
            case 'search':
                this.searchShowHide = !this.searchShowHide;
                this.filtersShowHide = false;
                break;
            case 'filters':
                this.filtersShowHide = !this.filtersShowHide;
                this.searchShowHide = false;
                break;
        }
    }

    goToBookingPage(): void {
        const preBookingRequest = this.buildPreBookingsCreateRequest(
            this.trains.providerToken,
            this.selectedItineraries[0],
            this.selectedItineraries[1],
            this.trains.requestToken,
        );

        this.trainsService.preBookTrains(preBookingRequest)
            .pipe(
                map(res => res.data),
                takeUntil(this.unsubscribe),
            ).subscribe((res: PreBookingResponse) => {
            if (!res.error) {
                const uuid = uuidv1();
                const shoppingBasket: ShoppingBasketTrains = this.buildShoppingBasket(
                    this.trains.providerToken,
                    this.selectedItineraries[0],
                    this.selectedItineraries[1],
                    res,
                    this.trains.requestToken,
                    uuid,
                );


                const navigationExtras: NavigationExtras = {
                    state: {
                        trains: shoppingBasket,
                    }
                };
                this.router.navigate(['integration', 'bookings', 'book-edit', 'trains', uuid], navigationExtras);
            } else {
                this.notificationService.openPopup(res.error.join(','));
            }
        });
    }


    private getTrains(kindOfItinerary: string = 'all', priceRange: IPriceRangeFilter = null): void {
        if (kindOfItinerary === 'outward') {
            this.inputFields.page = this.currentPageNumberForOutward + 1;
            this.inputFields.pageSize = this.currentPageSizeForOutward;
        } else if (kindOfItinerary === 'return') {
            this.inputFields.page = this.currentPageNumberForReturn + 1;
            this.inputFields.pageSize = this.currentPageSizeForReturn;
        } else {
            this.inputFields.page = this.currentPageNumberForAll + 1;
            this.inputFields.pageSize = this.currentPageSizeForAll;
        }
        this.inputFields.paginateTo = kindOfItinerary.toUpperCase();

        if (priceRange) {
            this.inputFields.minPrice = priceRange.lower;
            this.inputFields.maxPrice = priceRange.upper;
        }

        this.trainsService.listTrains(this.inputFields)
            .pipe(
                map(res => res.data),
                takeUntil(this.unsubscribe),
            )
            .subscribe(res => {
                if (res) {
                    this.trains = res;
                    this.totalOutwards = this.trains.outwardTotal;
                    this.totalReturns = this.trains.returnTotal;
                    this.minPrice = this.trains.minPrice;
                    this.maxPrice = this.trains.maxPrice;
                }
            });
    }

    private isValidQueryString(query: Params): boolean {
        this.parametersForTrains.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                return false;
            }
        });
        return true;
    }

    private buildRequestDto(query: Params): TrainsRequestDto {
        const trainsRequestDto = new TrainsRequestDto();
        trainsRequestDto.origin = query.origin;
        trainsRequestDto.originName = query.originName;
        trainsRequestDto.destination = query.destination;
        trainsRequestDto.destinationName = query.destinationName;
        trainsRequestDto.checkIn = query.checkIn;
        trainsRequestDto.checkOut = query.checkOut;
        trainsRequestDto.numberOfAdults = query.numberOfAdults;
        trainsRequestDto.numberOfChildren = query.numberOfChildren;
        trainsRequestDto.numberOfInfants = query.numberOfInfants;
        return trainsRequestDto;
    }

    private resetPagination(): void {
        this.currentPageNumberForOutward = PAGE_START;
        this.currentPageSizeForOutward = PAGE_SIZE;
        this.currentPageNumberForReturn = PAGE_START;
        this.currentPageSizeForReturn = PAGE_SIZE;
        this.currentPageNumberForAll = PAGE_START;
        this.currentPageSizeForAll = PAGE_SIZE;
    }

    private buildPreBookingsCreateRequest(
        providerToken: string,
        selectedOutward: Itinerary2,
        selectedReturn: Itinerary2,
        requestToken: string,
    ): PreBookingsCreateRequestDto {
        const preBookingRequest = new PreBookingsCreateRequestDto();
        preBookingRequest.providerToken = providerToken;
        preBookingRequest.trainTypeForOutward = selectedOutward.trainTypeDescription;
        preBookingRequest.trainNumberForOutward = selectedOutward.trainNumber;
        preBookingRequest.classCodeOutward = selectedOutward.classes[0].classCode;
        preBookingRequest.salesGroupCodeOutward = selectedOutward.classes[0].salesGroups[0].salesGroupCode;
        if (this.existReturnedItinerary(this.onlyDeparture, selectedReturn)) {
            preBookingRequest.trainTypeForReturn = selectedReturn.trainTypeDescription;
            preBookingRequest.trainNumberForReturn = selectedReturn.trainNumber;
            preBookingRequest.classCodeReturn = selectedReturn.classes[0].classCode;
            preBookingRequest.salesGroupCodeReturn = selectedReturn.classes[0].salesGroups[0].salesGroupCode;
        }
        preBookingRequest.requestToken = requestToken;
        return preBookingRequest;
    }

    private buildShoppingBasket(
        providerToken: string,
        selectedOutward: Itinerary2,
        selectedReturn: Itinerary2,
        preBookingResponse: PreBookingResponse,
        requestToken: string,
        uuid: string,
    ): ShoppingBasketTrains {
        const shoppingBasket = new ShoppingBasketTrains();
        if (this.existReturnedItinerary(this.onlyDeparture, selectedReturn)) {
            shoppingBasket.trains = [selectedOutward, selectedReturn];
        } else {
            shoppingBasket.trains = [selectedOutward];
        }
        shoppingBasket.providerToken = providerToken;
        shoppingBasket.totalPrice = preBookingResponse.commission.pvp;
        shoppingBasket.partialTotal = preBookingResponse.amount;
        shoppingBasket.paxes = preBookingResponse.passengers;
        shoppingBasket.limitDate = preBookingResponse.limitDate;
        shoppingBasket.requestToken = requestToken;
        shoppingBasket.uuid = uuid;
        shoppingBasket.integrationType = 'TRAIN';
        return shoppingBasket;
    }

    private existReturnedItinerary(isOnlyDeparture: boolean, selectedReturn: Itinerary2): boolean {
        return !isOnlyDeparture && !!selectedReturn;
    }

}
