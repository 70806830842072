import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'lib-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DatetimePickerComponent extends FieldType implements OnInit {
    ngOnInit() {
    }

}
