import { IBookingItem } from "./bookingItem.interface";
import { Client } from "./client.model";
import { Itinerary2 as TrainItinerary, Trains } from './trains/response/trains.response.dto';
import { Passenger as TrainPassenger } from './trains/response/pre-bookings.response.dto';
import { IntegrationType } from "./integration.types";
import { roundNumber, safeNumber } from "../functions/index";
import { Rerate } from "./rerate.model";
import { ShoppingBasketComment } from "./shopping-basket.model";
import { Location } from "./multidestination/multidestination-list.model";
import { MultidestinationPreBookingByLocation, TrainPreBookingResponse } from "./multidestination/multidestination-pre-booking.model";
import * as  uuidv1_ from 'uuid/v1';
import { ManagementPax } from "./management.interface";
import { TrainPaxFromManagementFactory } from "../factories/trains/train-pax-from-management.factory";

const uuidv1 = uuidv1_;

export class ShoppingBasketTrains implements IBookingItem {
    uuid: string;
    trains: TrainItinerary[];
    providerToken: string;
    totalPrice: number;
    paxes: TrainPassenger[];
    limitDate: string;
    requestToken: string;
    client: Client;
    fileId?: string;
    ticketReference?: string;
    trainTicketUrl?: string;
    safeTrainTicketUrl?: string;
    partialTotal: number;
    comments?: ShoppingBasketComment;
    integrationType: IntegrationType;
    validateMessage?: string;
    checkForm?: boolean;
    reratedPrice?: number;
    originalPrice?: number;
    failed?: boolean;
    isSelected?: boolean;
    canBeBooked?: boolean;

    static rerate(
        product: ShoppingBasketTrains,
        reratingResponse: Rerate,
    ): ShoppingBasketTrains {
        const originalPrice = roundNumber(product.totalPrice);
        product.originalPrice = originalPrice;
        if (reratingResponse) {
            const { pvp, rateKey, commissionableRate, nonCommissionableRate, canBeBooked } = reratingResponse;
            product.reratedPrice = pvp;
            product.totalPrice = pvp;
            product.partialTotal = commissionableRate + nonCommissionableRate;
            product.providerToken = rateKey;
            product.canBeBooked = canBeBooked;
        }
        return product;
    }

    static buildFromShoppingBasketByDestination(
        location: Location,
        client: Client,
        comments: ShoppingBasketComment,
        trainBookingPaxes: TrainPassenger[],
        preBookingResponse: MultidestinationPreBookingByLocation,
    ): ShoppingBasketTrains[] {
        if (!Location) { return []; }
        const { trainResponse } = location;
        if (trainResponse) { return []; }
        const { trainResponse: trainPreBookingResponse } = preBookingResponse;
        return this.buildShoppingBaskets(trainResponse, client, trainBookingPaxes, comments, trainPreBookingResponse);
    }

    static buildShoppingBaskets(
        trainResponse: Trains,
        client: Client,
        trainBookingPaxes: TrainPassenger[],
        comments: ShoppingBasketComment,
        preBookingResponse: TrainPreBookingResponse,
    ): ShoppingBasketTrains[] {
        if (!trainResponse) { return []; }
        const { outward, providerToken, requestToken } = trainResponse;
        if (!preBookingResponse) { return[]; }
        if (!outward) { return []; }
        const train = outward[0];
        if (!train ) { return []; }
        const { classes } = train;
        if (!classes) { return []; }
        const trainClass = classes[0];
        if (!trainClass) { return []; }
        const { salesGroups } = trainClass;
        if (!salesGroups) { return[]; }
        const saleGroup = salesGroups[0];
        if (!saleGroup) { return []; }
        const pvp = preBookingResponse.commission.pvp;
        const netPrice = preBookingResponse.amount;
        const limitDate = preBookingResponse.limitDate;
        return [{
            uuid: uuidv1(),
            trains: [train],
            providerToken,
            totalPrice: pvp,
            partialTotal: netPrice,
            paxes: trainBookingPaxes,
            limitDate,
            requestToken,
            comments,
            integrationType: 'TRAIN',
            client,
        }];
    }

    static initializeComments(
        item: ShoppingBasketTrains,
    ): ShoppingBasketTrains {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.totalPrice),
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShoppingBasketTrains,
        paxes: ManagementPax[],
    ): ShoppingBasketTrains {
        const mappedPaxes = TrainPaxFromManagementFactory.build(paxes, item.paxes);
        return {
            ...item,
            paxes: mappedPaxes,
        };
    }
}
