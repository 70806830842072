import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {IClient, ICommercialActivityEvent, ICommercialActivityPersonal} from '../interfaces/commercial-event.interface';
import {TecnoturisService} from "../../tecnoturis.service";

@Injectable({
    providedIn: 'root'
})
export class CommercialEventService {
    protected host = this.tecnoturisService.config.management;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
    }

    getClient(id: number): Observable<IClient> {
        return this.http.get<IClient>(`${this.host}/clients/${id}/`);
    }

    getClients(criteria: string): Observable<IClient[]> {
        let query = new HttpParams();
        query = query.append('minified', 'true');
        query = query.append('search', criteria);
        return this.http.get<IClient[]>(`${this.host}/clients/`, {params: query});
    }

    getCoworkers(): Observable<ICommercialActivityPersonal[]> {
        return this.http.get<ICommercialActivityPersonal[]>(`${this.host}/user/list/`);
    }

    updateEvent(id: number, payload: ICommercialActivityEvent | any): Observable<ICommercialActivityEvent> {
        return this.http.patch<ICommercialActivityEvent>(`${this.host}/clients/commercial_activities/${id}/`, payload);
    }

    deleteEvent(id: number): Observable<any> {
        return this.http.delete(`${this.host}/clients/commercial_activities/${id}/`);
    }

    createEvent(payload: ICommercialActivityEvent): Observable<ICommercialActivityEvent> {
        return this.http.post<ICommercialActivityEvent>(`${this.host}/clients/commercial_activities/`, payload);
    }

}
