import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { PackageNameEnum } from "../../../../mock/packages";

@Component({
  selector: "lib-caribe-package-search",
  templateUrl: "./caribe-package-search.component.html",
  styleUrls: ["./caribe-package-search.component.scss"],
})
export class CaribePackageSearchComponent implements OnInit {
  @Input() inputFields;
  @Input() isSmall = false;
  @Input() currentTab: number;
  @Input() showProviders = true;
  @Output() dataChanged = new EventEmitter<any>();
  @Input() microsite;
  @Output() queryParams: EventEmitter<any> = new EventEmitter<any>();
  packageName = PackageNameEnum.CARIBBEAN;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  search(event) {
    this.dataChanged.emit(event);
  }
}
