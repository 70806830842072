import { Pipe, PipeTransform } from "@angular/core";
import * as moment_ from 'moment/moment';

// | momentDate : '' : 'YYYY-MM-DD HHmm'
// | momentDate : 'LT' : 'YYYY-MM-DD HHmm'
// https://momentjs.com/docs/#/displaying/
const moment = moment_;

@Pipe({
    name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
    transform(value: string, inputFormat: string = 'YYYY-DD-MMMM', locale: string = 'es', utc = false) {
        moment.locale(locale);
        if (!utc) { return moment(value, inputFormat).toDate(); }
        return moment(value, inputFormat).utc().toDate();
    }
}
