export class PackagesDetailsRequest {
  requestToken: string;
  providerCode: string;
  hotelId: string;
  roomId: string;
  idProduct?: string;
  hotelName?: string;
  midInsuranceToken?: string;
  roomName?: string;
  price?: string;
}
