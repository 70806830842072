import { Component, Input } from "@angular/core";
import { ShoppingBasketFlight } from '../../../integrations/hotels/index';
import {MicrositeService} from "../../../lib-shared/index";

@Component({
    selector: 'lib-flight-book-summary',
    templateUrl: './flight-book-summary.component.html',
    styleUrls: ['./flight-book-summary.component.scss'],
})

export class FlightBookSummaryComponent {

    @Input() flight: ShoppingBasketFlight;

    constructor(public micrositeService: MicrositeService) {
    }
}
