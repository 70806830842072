import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Pax from '../../../paxes/Pax';

@Injectable({
    providedIn: 'root'
})
export class PaxesHotelService {
    private paxSource = new BehaviorSubject<Pax>(new Pax(1, 2, []));

    constructor() {
    }

    changePax(pax: Pax) {
        const nowPax = new BehaviorSubject<Pax>(pax);

        // if (nowPax !== this.paxSource) {
        this.paxSource.next(pax);
        // }
    }

    currentPax() {
        return this.paxSource;
    }
}
