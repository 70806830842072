import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import Pax from '../../../paxes/Pax';

@Component({
    selector: 'lib-paxes-hotel',
    templateUrl: './paxes-hotel.component.html',
    styleUrls: ['./paxes-hotel.component.scss']
})
export class PaxesHotelComponent implements OnInit, OnDestroy, OnChanges {
    @Output() result = new EventEmitter();
    @Input() isSmall;
    @Input() paxesNumber;
    @Input() newPax: Pax;
    @Input() position;
    @Output() remove = new EventEmitter();
    paxes: Array<Pax> = [];

    disabledButtonDelete: boolean;

    rooms;
    public MIN_ROOMS = 1;
    public MAX_ROOMS = 30;
    public MIN_ADULTS = 1;
    public MAX_ADULTS = 30;
    public MIN_CHILDREN = 0;
    public MAX_CHILDREN = 10;
    public MIN_AGE = 0;
    public MAX_AGE = 17;
    public childrenAge = 12;

    constructor() {

    }

    ngOnInit() {
        this.emitPax();
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.position) {
            if (changes.position.currentValue < 1) {
                this.disabledButtonDelete = true;
            } else {
                this.disabledButtonDelete = false;
            }
        }
    }


    incRooms() {
        this.newPax.rooms++;
        this.emitPax();
    }

    decRooms() {
        this.newPax.rooms--;
        this.emitPax();
    }

    incAdults() {
        this.newPax.adults++;
        this.emitPax();
    }

    decAdults() {
        this.newPax.adults--;
        this.emitPax();
    }

    incChildren() {
        this.newPax.children.push(12);
        // this.incChildrenAges();
        this.emitPax();
    }

    decChildren() {
        this.newPax.children.pop();
        // this.decChildrenAges();
        this.emitPax();
    }

    /* incChildrenAges() {
      const child = new Child(this.childrenAges.length, this.DEFAULT_CHILD_AGE);
      this.childrenAges = [...this.childrenAges, child];
      this.emitPax();
    } */

    downChildrenAge(i) {
        this.newPax.children[i]--;
        this.emitPax();
    }

    upChildrenAge(i) {
        this.newPax.children[i]++;
        this.emitPax();
    }

    onAgeChanged(i) {
        this.newPax.children[i] = this.childrenAge;
        this.emitPax();
    }

    emitPax() {
        this.result.emit({'newPax': this.newPax, 'position': this.position});
    }

    removePax() {
        this.remove.emit(this.position);
    }

}
