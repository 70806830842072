import { NgModule } from '@angular/core';
import { PaxesComponent } from './paxes.component';
import { MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LibSharedModule } from '../lib-shared/index';

@NgModule({
    declarations: [
        PaxesComponent
    ],
    imports: [
        LibSharedModule,
        MatInputModule,
        FormsModule,
        IonicModule
    ],
    exports: [
        PaxesComponent
    ]
})
export class PaxesModule {
}
