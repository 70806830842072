import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[libScrollTopButton]',
})
export class ScrollTopButton2Directive implements OnInit {

    @Input('libScrollTopButton') scrollTopButton;
    @Input() minHeight = 170;

    public constructor() {
    }

    public ngOnInit(): void {
        this.scrollTopButton.hide();
    }

    @HostListener('ionScroll', ['$event'])
    onScroll(event): void {
        (event.target.offsetHeight - event.detail.scrollTop < this.minHeight) ? this.scrollTopButton.show() : this.scrollTopButton.hide();
    }
}
