import { PackageVacationPackageBooking } from "@vecib2c/frontend-dto";
import { splitDistributionsByPaxType } from "../../../../functions/index";

export class PackageState {

    uuid: string;
    preBookingResponse: PackageVacationPackageBooking;
    packageType: string;
    adults: number;
    children: number;
    infants: number;

    static build(
        uuid: string,
        preBookingResponse: PackageVacationPackageBooking,
        packageType: string,
    ): PackageState {
        const { adults, children, infants } = splitDistributionsByPaxType(preBookingResponse.distribution);

        return {
            uuid,
            preBookingResponse,
            packageType,
            adults,
            children,
            infants,
        };
    }
}
