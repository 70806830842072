import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { integrationTypes } from '../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-failed-bookings-dialog',
    templateUrl: './failed-bookings-dialog.component.html',
    styleUrls: ['./failed-bookings-dialog.component.scss']
})

export class FailedBookingDialogComponent {
    integrationTypes = integrationTypes;

    constructor(
        public dialogRef: MatDialogRef<FailedBookingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) 
        public data) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
