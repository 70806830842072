import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IBookingService, PaymentOption } from '../../../../lib-shared/interfaces/index';
import * as _moment from 'moment';
import { ManagementService } from '../../../../lib-shared/services/index';
import { PaymentDossier, PaymentMethod } from '../../../../lib-shared/interfaces/management.interface';
import { CustomValidators } from '../../../../lib-shared/index';

const moment = _moment;

interface ModalData {
    paymentMethods: PaymentMethod[];
    dossierId: number;
    agencyId: number;
    bookingServices: IBookingService[];
    payments: PaymentDossier[];
    cashOptions: PaymentOption[];
    bankOptions: PaymentOption[];
    creditCardOptions: PaymentOption[];
    totalAmountWithoutService: number;
    isAbono: boolean;
}

@Component({
    selector: 'lib-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit, OnDestroy {

    paymentMethods: PaymentMethod[];
    form: FormGroup;
    paidDateControl: FormControl = new FormControl();
    availableCashField = false;
    availableBankAccountField = false;
    availableCreditCardField = false;
    displayedColumns = ['payment_method', 'cash', 'bank_account', 'paid_amount', 'paid_date', 'observation', 'actions'];
    payments: PaymentDossier[];
    action = 'create';
    dossierId: number;
    agencyId: number;
    cashOptions: PaymentOption[] = [];
    bankOptions: PaymentOption[] = [];
    bookingServices: IBookingService[] = [];
    total = 0;
    totalPaidAmount = 0;
    maxDate: Date;
    creditCardOptions: PaymentOption[] = [];
    isAbono: boolean;

    private unsubscribe = new Subject<void>();

    title: string;
    totalTitle: string;
    saldoTitle: string;
    pagadoTitle: string;
    pagosTitle: string;
    cantidadPlaceholder: string;

    labelCaja: string;

    @Output() savePaymentDossier = new EventEmitter<PaymentDossier[]>();

    constructor(
        public dialogRef: MatDialogRef<PaymentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private readonly formBuilder: FormBuilder,
        private readonly managementService: ManagementService,
    ) {
        this.paymentMethods = data.paymentMethods;
        this.dossierId = data.dossierId;
        this.agencyId = data.agencyId;
        this.bookingServices = data.bookingServices;
        this.payments = data.payments;
        this.cashOptions = data.cashOptions;
        this.bankOptions = data.bankOptions;
        this.creditCardOptions = data.creditCardOptions;
        this.isAbono = data.isAbono;

        this.title = data.isAbono ? 'Entregar a cuenta / Abono' : 'Entregar a cuenta';
        this.totalTitle = data.isAbono ? 'Total Abono por los servicios' : 'Total por los servicios';
        this.saldoTitle = data.isAbono ? 'Saldo a abonar' : 'Saldo a pagar';
        this.pagadoTitle = data.isAbono ? 'Abonado' : 'Pagado';
        this.pagosTitle = data.isAbono ? 'Abonos' : 'Pagos';
        this.cantidadPlaceholder = data.isAbono ? 'Cantidad abonada' : 'Cantidad pagada';
        this.labelCaja = 'Opciones de caja';

    }

    ngOnInit(): void {
        this.total = this.bookingServices.length > 0 ?
            this.bookingServices.reduce((acc, current) => acc + current.total_pvp, 0) : this.data.totalAmountWithoutService;
        this.totalPaidAmount = this.calculateTotalPaidAmount(this.payments);

        if (this.isAbono) {
            this.total = this.bookingServices.reduce((acc, current) => acc + current.total_abono, 0);
        }

        this.maxDate = new Date();
        const paymentDossier = new PaymentDossier();
        paymentDossier.paid_date = moment().toISOString();
        paymentDossier.is_abono = this.isAbono;
        this.initForm(paymentDossier);
    }

    closeDialog(): void {
        this.dialogRef.close(this.payments);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    initForm(payment: PaymentDossier): void {
        this.form = this.formBuilder.group({
            id: [payment.id],
            payment_method: [payment.payment_method, Validators.required],
            cash: [payment.cash],
            bank_account: [payment.bank_account],
            credit_card: [payment.credit_card],
            paid_amount: [payment.paid_amount],
            paid_date: [moment(payment.paid_date).toDate(), [
                Validators.required,
                CustomValidators.maxDate(this.maxDate)
            ]
            ],
            observation: [payment.observation],
            is_abono: [payment.is_abono, Validators.required]
        });
        const cashControl = this.form.get('cash');
        const bankAccountControl = this.form.get('bank_account');
        const creditCardControl = this.form.get('credit_card');
        const paidAmountControl = this.form.get('paid_amount');

        this.form.get('payment_method').valueChanges
            .pipe(
                startWith(this.form.get('payment_method').value),
                takeUntil(this.unsubscribe),
            ).subscribe(option => {
            const formattedOption = Number(option);
            this.labelCaja = 'Opciones de caja referencia';
            if (formattedOption === 1) {
                this.availableCashField = true;
                this.availableBankAccountField = false;
                this.availableCreditCardField = false;
                bankAccountControl.setValue(null);
                creditCardControl.setValue(null);
                cashControl.setValidators([Validators.required]);
                bankAccountControl.clearValidators();
                creditCardControl.clearValidators();
                this.labelCaja = 'Opciones de caja';
            } else if (formattedOption === 2) {
                this.availableCashField = true;
                this.availableBankAccountField = true;
                this.availableCreditCardField = false;
                cashControl.setValue(null);
                creditCardControl.setValue(null);
                cashControl.clearValidators();
                bankAccountControl.setValidators([Validators.required]);
                creditCardControl.clearValidators();
            } else if (formattedOption === 3) {
                this.availableCashField = true;
                this.availableBankAccountField = false;
                this.availableCreditCardField = false;
                bankAccountControl.setValue(null);
                creditCardControl.setValue(null);
                cashControl.setValidators([Validators.required]);
                bankAccountControl.clearValidators();
                creditCardControl.clearValidators();
            } else if (formattedOption === 4) {
                this.availableCashField = true;
                this.availableBankAccountField = false;
                this.availableCreditCardField = true;
                cashControl.setValue(null);
                bankAccountControl.setValue(null);
                cashControl.clearValidators();
                bankAccountControl.clearValidators();
                creditCardControl.setValidators([Validators.required]);
            } else {
                this.availableCashField = false;
                this.availableBankAccountField = false;
                this.availableCreditCardField = false;
                cashControl.setValue(null);
                bankAccountControl.setValue(null);
                creditCardControl.setValue(null);
                cashControl.clearValidators();
                bankAccountControl.clearValidators();
                creditCardControl.clearValidators();
            }
            cashControl.updateValueAndValidity();
            bankAccountControl.updateValueAndValidity();
            creditCardControl.updateValueAndValidity();
        });

        paidAmountControl.valueChanges
            .pipe(
                startWith(paidAmountControl.value),
                takeUntil(this.unsubscribe),
            ).subscribe(val => {
            let maxValue = 0;
            if (this.action === 'create') {
                maxValue = this.total - this.totalPaidAmount;
            } else {
                maxValue = this.payments.length === 1 ? this.total : this.total - this.totalPaidAmount;
            }

            paidAmountControl.setValidators(
                [
                    Validators.required,
                    Validators.min(0),
                    Validators.max(maxValue)
                ]
            );
        });
    }

    savePayment() {
        if (this.action === 'create') {
            this.addPayment();
        } else {
            this.updatePayment();
        }
    }

    editPayment(paymentId: number): void {
        const payment = this.payments.find(payment => payment.id === paymentId);
        const formattedPayment = Object.assign(
            {},
            payment,
            {paid_date: moment(payment.paid_date, 'YYYY-MM-DD').toISOString()}
        );
        this.action = 'update';
        this.initForm(formattedPayment);
    }

    deletePayment(paymentId: number): void {
        this.managementService.deletePayment(paymentId)
            .pipe(
                takeUntil(this.unsubscribe),
            )
            .subscribe(res => {
                this.payments = this.payments.filter(payment => payment.id !== paymentId);
                this.totalPaidAmount = this.calculateTotalPaidAmount(this.payments);
                this.savePaymentDossier.emit(this.payments);
            });
    }

    downloadPayment(paymentId: number): void {
        this.managementService.downloadPaymentDossier(paymentId);
    }

    setPaidDate(date: Date): void {
        const validatedDate = date ? moment(date).toISOString() : null;
        this.form.get('paid_date').setValue(validatedDate);
        this.form.get('paid_date').markAsTouched();
    }

    private async addPayment(): Promise<void> {
        const rawForm = this.form.getRawValue();
        const paymentDossierRequest = this.buildPaymentDossierRequest(rawForm);
        paymentDossierRequest.dossier = this.dossierId;
        paymentDossierRequest.id = undefined;
        this.managementService.addPayment(paymentDossierRequest)
            .pipe(
                takeUntil(this.unsubscribe),
            )
            .subscribe(paymentDossier => {
                this.payments = [...this.payments, paymentDossier].filter(item => item.is_abono === this.isAbono);
                this.form.reset();
                this.form.patchValue({ is_abono: this.isAbono, paid_date: moment(moment().toISOString()).toDate() });
                this.totalPaidAmount = this.calculateTotalPaidAmount(this.payments);
                this.savePaymentDossier.emit(this.payments);
            });
    }

    private updatePayment(): void {
        const rawForm = this.form.getRawValue();
        const paymentDossierRequest = this.buildPaymentDossierRequest(rawForm);
        paymentDossierRequest.dossier = this.dossierId;
        paymentDossierRequest.id = undefined;

        const payment = this.payments.find(payment => payment.id === rawForm.id);

        this.managementService.updatePayment(paymentDossierRequest, payment.id)
            .pipe(
                takeUntil(this.unsubscribe),
            )
            .subscribe(paymentDossier => {
                this.payments = [
                    ...this.payments.filter(payment => payment.id !== rawForm.id),
                    paymentDossier,
                ];
                this.form.reset();
                this.form.patchValue({ is_abono: this.isAbono, paid_date: moment(moment().toISOString()).toDate() });
                this.action = 'create';
                this.totalPaidAmount = this.calculateTotalPaidAmount(this.payments);
                this.savePaymentDossier.emit(this.payments);
            });
    }

    private buildPaymentDossierRequest(rawForm): PaymentDossier {
        const paymentDossier = Object.assign({}, rawForm, {
            paid_date: moment(rawForm.paid_date).format('YYYY-MM-DD'),
        });

        return paymentDossier;
    }

    private calculateTotalPaidAmount(payments: PaymentDossier[]): number {
        return payments.reduce((acc, current) => acc + current.paid_amount, 0);
    }

}
