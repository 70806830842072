import { FieldType, IDynamicCrud } from '../../lib-shared/interfaces/index';

export const transportsForm: IDynamicCrud = {
    crud: 'safes-booking',
    name: 'Seguros',
    urlPath: 'booking-service/',
    urlPathList: 'clients/dossier/id/flight-booking/',
    extraKeySave: ['cars'],
    crudFiles: {
        path: 'booking-service/files/',
        urlPathList: 'booking-service/id/files/',
        key: 'booking_service',
    },
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: 'Datos generales',
            fieldChilds: [
                {
                    keyName: 'code',
                    name: 'N° Servicio',
                    type: FieldType.CHAR,
                    removeOnCreate: true,
                    readonly: true
                },
                {
                    keyName: 'confirmed_by',
                    name: 'Confirmado por',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'locator',
                    name: 'Localizador',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'confirmed_date',
                    name: 'Fecha de confirmación',
                    type: FieldType.DATE,
                    showInTable: true
                },
                {
                    keyName: 'fiscal_location',
                    name: 'Localización fiscal (destino)',
                    type: FieldType.LIST,
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'description',
                    name: 'Descripción',
                    type: FieldType.CHAR,
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos de la reserva',
            fieldChilds: [
                {
                    keyName: 'pickup_date',
                    name: 'Fecha y hora de recogida',
                    type: FieldType.DATETIME,
                    showInTable: true,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'pickup_location',
                    name: 'Lugar de recogida',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: null,
            fieldChilds: [
                {
                    keyName: 'dropoff_date',
                    name: 'Fecha y hora de entrega',
                    type: FieldType.DATETIME,
                    showInTable: true,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'dropoff_location',
                    name: 'Lugar de entrega',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'cancellation_to',
                    name: 'Cancelación hasta',
                    type: FieldType.DATE,
                    showInTable: true,
                    saveInKey: 'cars'
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos del bono',
            fieldChilds: [
                {
                    keyName: 'supplier_name',
                    name: 'Proveedor del aqluiler',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'days',
                    name: 'Dias de alquiler',
                    type: FieldType.NUMBER,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'dni',
                    name: 'N° de Vuelo o Tren',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'driver_name',
                    name: 'Nombre del conductor',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'provider_info',
                    name: 'Referencia del proveedor',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'car_type',
                    name: 'Tipo de coche',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'valued_bond',
                    name: 'Bono valorado',
                    type: FieldType.CHECKBOX,
                    saveInKey: 'cars'
                },
                {
                    keyName: 'price_bond',
                    name: 'Precio bono',
                    type: FieldType.NUMBER,
                    saveInKey: 'cars',
                    disabled: true
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'extra_info',
                    name: 'Extras',
                    type: FieldType.CHAR,
                    saveInKey: 'cars'
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Descripción del producto alquilado',
            fieldChilds: [
                {
                    keyName: 'product_description',
                    name: '',
                    type: FieldType.TEXTAREA,
                    saveInKey: 'cars'
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Lo que incluye',
            fieldChilds: [
                {
                    keyName: 'product_include',
                    name: '',
                    type: FieldType.TEXTAREA,
                    saveInKey: 'cars'
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones',
            fieldChilds: [
                {
                    keyName: 'observation',
                    name: '',
                    type: FieldType.TEXTAREA,
                    saveInKey: 'cars'
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos del proveedor',
            fieldChilds: [
                {
                    keyName: 'provider_accountant_account',
                    name: 'Cuenta contable',
                    type: FieldType.CHAR,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'provider',
                    name: 'Nombre del proveedor',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'payment_method',
                    name: 'Forma de Pago',
                    type: FieldType.LIST,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'payday_limit',
                    name: 'Fecha límite de pago',
                    type: FieldType.DATE,
                    disabled: false
                },
                {
                    keyName: 'product_type',
                    name: 'Tipo de producto',
                    type: FieldType.LIST
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos de comisiones',
            fieldChilds: [
                {
                    keyName: 'fee_apply',
                    name: 'Tipo comisión',
                    type: FieldType.LIST
                },
                {
                    keyName: 'comission',
                    name: '% Comisión',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 100
                },
                {
                    keyName: 'iva',
                    name: 'IVA',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva',
                    name: 'IVA de Compra',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva_pay',
                    name: 'IVA de Venta',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'pvp_fee',
                    name: 'Importe Comisionable',
                    type: FieldType.NUMBER,
                    min: 0,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'not_fee_amount',
                    name: 'Importe no comisionable (tasas)',
                    type: FieldType.NUMBER,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'total_pvp',
                    name: 'Total PVP',
                    type: FieldType.NUMBER,
                    readonly: true
                },
                {
                    keyName: 'discount',
                    name: 'Descuentos',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'fee',
                    name: 'Fee Agencia',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'total_net',
                    name: 'Total Neto a pagar',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'profit_gross',
                    name: 'Margen Bruto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'profit',
                    name: 'Margen Neto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones generales',
            fieldChilds: [
                {
                    keyName: 'observation',
                    name: '',
                    type: FieldType.TEXTAREA
                }
            ]
        }
    ],
    dynamicCruds: [
        {
            crud: 'flight-travelers',
            name: 'Pasajeros',
            urlPath: 'booking-service/pax/',
            extractDataFrom: 'pax',
            extractIsArray: false,
            appendKey: 'booking_service',
            fields: [
                {
                    isGroup: true,
                    keyName: '',
                    name: 'Pasajeros',
                    fieldChilds: [
                        {
                            keyName: 'name',
                            name: 'Nombre',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'last_name',
                            name: 'Apellidos',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'age',
                            name: 'Edad',
                            type: FieldType.NUMBER,
                            showInTable: true
                        },
                    ]
                },
            ],
        },
    ]
};
