import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Setup} from '../../lib-shared/interfaces/index';
import {DynamicCrudComponent} from '../../dynamic-crud/index';
import {clientCommercialActivitiesForm} from './client-commercial-activities.form';
import {AgendaService} from "../../lib-shared/services/agenda.service";
import {CommercialEventComponent} from '../../management/commercial-event/commercial-event.component';
import {ICommercialActivityPersonal} from '../../lib-shared/interfaces/commercial-event.interface';
import {ICommercialActivityEvent, IDialogData} from '../../lib-shared/interfaces/agenda-calendar.interface';
import {MessageProviderService} from '../../lib-shared/services/message-provider.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'lib-client-commercial-activities',
    templateUrl: './client-commercial-activities.component.html',
    styleUrls: ['./client-commercial-activities.component.scss']
})
export class ClientCommercialActivitiesComponent implements OnInit, OnDestroy {
    coworkers: ICommercialActivityPersonal[] = [];
    dynamicCrud;

    setup: Setup;
    @Input() client: any;

    @ViewChild(DynamicCrudComponent, { static: false }) crudComponent: DynamicCrudComponent;

    unsubscribe = new Subject();

    constructor(private matDialog: MatDialog,
                private route: ActivatedRoute,
                private agendaService: AgendaService,
                private messageProviderService: MessageProviderService,
                private snackBar: MatSnackBar) {
        this.route.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data.setup) {
                    this.setup = data.setup;
                }
            });
        // this.route.params.subscribe(params => this.client = params['id']);
    }

    async ngOnInit() {
        const personal = await this.agendaService.getCoworkers().pipe(
            map(result => {
                const data = result.map(item => {
                    return {
                        id: item.id,
                        name: item.username
                    };
                });
                return data;
            })
        ).toPromise();
        this.setup['personal'] = personal;
        const crudData = JSON.parse(JSON.stringify(clientCommercialActivitiesForm));
        crudData.urlPathList = crudData.urlPathList.replace('id', this.client);
        this.dynamicCrud = crudData;
    }

    add(): void {
        /*const dialogRef = this.matDialog.open(ClientCommercialActivitiesAddEditComponent, {
            panelClass: 'dialog-commercial-activities',
            data: {
                setup: this.setup,
                client: this.client
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.crudComponent.list();
            }
        });*/
    }

    async getParams(): Promise<void> {
        if (this.coworkers.length === 0) {
            const response = await this.agendaService.getCoworkers().toPromise();
            if (response) {
                this.coworkers = response;
            }
        }
    }

    async edit(id: number): Promise<void> {
        // CommercialEventComponent
        await this.getParams();
        const response = await this.agendaService.getEvent(id).toPromise();
        if (response) {
            const dialogData: IDialogData<ICommercialActivityEvent> = {
                meta: response,
                setup: this.setup,
                personal: this.coworkers
            };
            const operation = await this.messageProviderService.showModalPromise(CommercialEventComponent, dialogData);
            if (operation) {
                this.snackBar.open('El evento se ha grabado', null, { duration: 2000 });
                this.crudComponent.list();
            }
        } else {
            this.snackBar.open('Error durante la recuperacion del evento', null, { duration: 3000 });
        }
        /*const dialogRef = this.matDialog.open(ClientCommercialActivitiesAddEditComponent, {
            panelClass: 'dialog-commercial-activities',
            data: {
                setup: this.setup,
                client: this.client,
                id: id
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.crudComponent.list();
            }
        });*/
    }

    eventUpdate() {
        return this.crudComponent.list();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
