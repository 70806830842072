import { Component, Input, OnInit } from "@angular/core";
import t from "typy";
import {TecnoturisService} from "../../../tecnoturis.service";
import {MicrositeService} from "../../../lib-shared/index";

@Component({
    selector: 'lib-hotel-book-summary',
    templateUrl: './hotel-book-summary.component.html',
    styleUrls: ['./hotel-book-summary.component.scss']
})

export class HotelBookSummaryComponent implements OnInit {

    @Input() hotel;

    constructor(public tecnoturisService: TecnoturisService,
                public micrositeService: MicrositeService) {
    }

    ngOnInit() {

    }

    getPrice(hotel) {
        const getNumber = (value) => {
            return value === '' ? 0 : parseFloat(value);
        };

        const roomPrice = getNumber(t(hotel, 'roomList[0].rooms[0].rates[0].price').safeString);
        const roomQuantity = getNumber(t(hotel, 'roomList[0].distribution.rooms').safeString);
        return roomPrice * roomQuantity;
    }

    getCategory(starsNumber: number): Array<number> {
        const stars = [1, 2, 3, 4, 5];
        return stars.filter((star) => {
            return star <= starsNumber;
        });
    }
}
