import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { ManagementService } from './management.service';
import { ClientServices } from './client.services';
import { CarAvailabilityResDto, CarBookingResDto, ShoppingBasketCars } from '../interfaces/index';
import { TecnoturisService } from '../../tecnoturis.service';
import { IntegrationsService } from "./integrations.service";
import { CarAvailabilityReq } from "../interfaces/cars/availability.req.model";
import { CarBookingDtoReq } from "../interfaces/cars/booking.req.model";

@Injectable()
export class TransportProviderService {

    supplierInfo: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    paramsDetail: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient, private clientService: ClientServices,
                private managementService: ManagementService, private tecnoturisService: TecnoturisService,
                private integrationsService: IntegrationsService) {
    }

    private getPath(action: string, id: number = null): string {
        const host = this.tecnoturisService.config.flexibleAutos;

        let list: any = [`${host}`, "api/v1", action];
        // let list: any = [`${host}`, action];
        if (id) {
            list = [...list, id];
        }
        return list.join("/");
    }

    listCars(req: CarAvailabilityReq, safeResponse = true): Observable<CarAvailabilityResDto> {
        const path = this.getPath("bookingprocess/cars/");
        return this.http.post<CarAvailabilityResDto>(path, req).pipe(
            catchError(err => {
                if (safeResponse) {
                    return of({ data: undefined, status: 500 });
                }
                throw err;
            }),
        );
    }


    setSupplierInfo(supplierInfo: any[]) {
        localStorage.setItem('supplierInfo', JSON.stringify(supplierInfo));
        this.supplierInfo.next(supplierInfo);
    }

    setParamsDetail(params: HttpParams) {
        localStorage.setItem('paramsDetail', JSON.stringify(params));
        this.paramsDetail.next(params);
    }

    searchCities(text): Observable<any> {
        return this.http.get(this.getPath('essentialinformation/locations') + `?search=${text}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    confirmBooking(booking: CarBookingDtoReq, saveBudget = true, shoppingBasket?: ShoppingBasketCars): Observable<CarBookingResDto> {
        booking.flightNumber = booking.flightNumber || null;
        const path = this.getPath("bookingprocess/PlaceBooking");
        return this.http.post<CarBookingResDto>(path, booking).pipe(tap(async response => {
            if (saveBudget) {
                const client = await this.clientService.getClient();
                const dossier = this.integrationsService.validAndGetDossier();
                const data = {carData: [response], client: client.id, dossier: dossier};
                if (shoppingBasket) {
                    data.carData[0].data.shoppingBasket = shoppingBasket;
                }
                await this.managementService.addServicesToDossier(data).toPromise();
            }
        }));
    }

    bookFromBasket(item: ShoppingBasketCars): Promise<ShoppingBasketCars> {
        const params = CarBookingDtoReq.build(item, item.uuid, item.client);
        return this.confirmBooking(params, false).toPromise()
            .then(data => {
                const status = [200, 201];
                if (status.includes(data.status)) {
                    item.response = data;
                    return Promise.resolve(item);
                }
                item.failed = true;
                item.response = data;
                return Promise.reject(item);
            })
            .catch(err => {
                item.failed = true;
                return Promise.reject(item);
            });
    }

    cancelBooking(bookingCancel: any): Observable<any> {
        const path = this.getPath("bookingprocess/CancelBooking");
        return this.http.post<any>(path, bookingCancel);
    }
}
