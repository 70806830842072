import { NgModule } from "@angular/core";
import { LibSharedModule } from "../../lib-shared/lib-shared.module";
import { DocumentHeaderComponent } from './document-header.component' ;

@NgModule({
    declarations: [
        DocumentHeaderComponent,
    ],
    imports: [
        LibSharedModule,
    ],
    exports: [
        DocumentHeaderComponent,
    ]

})
export class DocumentHeaderModule {
}
