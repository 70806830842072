import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SearchNavbarService } from '../../../lib-shared/services/search-navbar.services';

@Component({
    selector: 'lib-hotels-detail-navbar',
    templateUrl: './hotels-detail-navbar.component.html',
    styleUrls: ['./hotels-detail-navbar.component.scss'],
    animations: [
        trigger('scrollAnimation', [
            state('show', style({
                opacity: 1,
                height: '129px',
                transform: "translateY(0)"
            })),
            state('hide', style({
                opacity: 0,
                height: '0',
                transform: "translateY(-100%)"
            })),
            transition('show => hide', animate('500ms ease-out')),
            transition('hide => show', animate('500ms ease-in'))
        ])
    ]
})
export class HotelsDetailNavbarComponent implements OnInit {
    @Input() name = '';
    @Input() stars = [];
    state = 'hide';

    constructor(
        public el: ElementRef,
        public navbarService: SearchNavbarService,
        private render: Renderer2) {
    }

    ngOnInit() {
        this.navbarService.getHideProperty().subscribe(isVisible => {
            this.state = isVisible ? this.state = 'show' : this.state = 'hide';
        });
    }
}
