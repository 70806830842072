import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";

import { MatCheckbox, MatCheckboxChange } from "@angular/material";
import { fromEvent, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AutocompleteService, FilterService } from '../lib-shared/services/index';
import { Accommodation, AccommodationGroup, IFilter, Rate2 } from '../lib-shared/interfaces/index';

@Component({
    selector: "lib-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
    providers: [
        FilterService,
    ]
})
export class FilterComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Input() maxDistance: number;
    @Input() filterExcluded: string[];
    stars: Array<any>;
    key: any;
    hotelsSelected = true;
    apatamentsSelected = false;
    accommodations: Accommodation[];
    accommodationsGroups: AccommodationGroup[] = [
        {type: "Hoteles", items: ["Hotel", "Apartahotel"]},
        {type: "Hostales y pensiones", items: ["Hostal"]},
        {type: "Apartamentos", items: ["Apartamento", "Residencia"]},
        {type: "Casas rurales", items: ["Casa Rural"]},
        {type: "Villa", items: ["Villa"]},
        {type: "Camping", items: ["Cámping"]}
    ];
    rates: Rate2[];
    prices: any = {};
    distance: any;
    filters: IFilter = {};
    @Output() filterSelected = new EventEmitter<any>();
    @ViewChildren('parent') parentCheckboxes;
    apartamentosCheckbox: MatCheckbox;
    @Output() textNameFilter = new EventEmitter<string>();
    @ViewChild('hotelNameInput', { static: false }) hotelNameInput: ElementRef;
    @Output() textPlaceInterestingFilter = new EventEmitter<any>();
    @ViewChild('placeInterestingNameInput', { static: false }) placeInterestingNameInput: ElementRef;
    optionsPlaceInteresting$: Observable<any>;
    placeInterestingSelected: any;
    objPlaceInteresting: any;
    form: FormGroup;
    currentDistanceToCenter: number;

    hiddenProperties = {
        distance: false,
        name: false,
        category: false,
        accommodationType: false,
        regime: false,
        priceRange: false,
        placeInteresting: false,
    };

    eventRangePrice$ = new Subject();

    constructor(
        private filterService: FilterService,
        public autocomplete: AutocompleteService,
        public route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.stars = [
            {id: 5, number: 'cinco', selected: false},
            {id: 4, number: 'cuatro', selected: false},
            {id: 3, number: 'tres', selected: false},
            {id: 2, number: 'dos', selected: false},
            {id: 1, number: 'una', selected: false}
        ];

        this.key = {selected: false};

        this.eventRangePrice$.pipe(
            debounceTime(600),
            distinctUntilChanged(),
        ).subscribe(({lower, upper}) => {
            if (lower && upper) {
                this.handleInputValue('price', null);
            }
        });

        this.filterService.listAccomodations().subscribe(data => {
            this.accommodations = data.accommodations;
        });

        this.filterService.listCategories().subscribe(data => {
            this.rates = data.rates;
        });

        this.initValues();
    }

    initValues() {
        this.prices = {};
        this.filters.accommodations = [];
        this.prices.lower = this.minPrice;
        this.prices.upper = this.maxPrice;
        this.distance = 20;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterExcluded && changes.filterExcluded.currentValue) {
            changes.filterExcluded.currentValue.forEach(key => {
                this.hiddenProperties[key] = true;
            });
        }

        if (changes.minPrice && changes.minPrice.currentValue) {
            setTimeout(() => this.prices.lower = changes.minPrice.currentValue, 100);
        }

        if (changes.maxPrice && changes.maxPrice.currentValue) {
            setTimeout(() => this.prices.upper = changes.maxPrice.currentValue, 100);
        }
    }

    ngAfterViewInit() {
        this.getFiltersName();
        this.getFiltersPlaceInteresting();
    }

    getFiltersName() {
        const filteredCheckboxes: MatCheckbox[] = this.parentCheckboxes
            .filter(checkbox => checkbox._elementRef.nativeElement.innerText === "Apartamentos");
        fromEvent(this.hotelNameInput.nativeElement, 'keyup')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                filter(res => res.length > 1 || !res),
                debounceTime(1200),
                distinctUntilChanged()
            )
            .subscribe(text => {
                this.textNameFilter.emit(text);
            });
        if (filteredCheckboxes && filteredCheckboxes.length > 0) {
            this.apartamentosCheckbox = filteredCheckboxes[0];
        }
    }

    getFiltersPlaceInteresting() {
        fromEvent(this.placeInterestingNameInput.nativeElement, 'keyup')
            .pipe(
                map((event: any) => {

                    return event.target.value;
                }),
                filter(res => res.length > 2 || !res),
                debounceTime(1200),
                distinctUntilChanged()
            )
            .subscribe(text => {
                if (text.length === 0) {
                    this.currentDistanceToCenter = undefined;
                    this.route.queryParams.subscribe(params => {
                        const objInitSearch = {
                            name: params.locality,
                            geo: {lng: params.longitude, lat: params.latitude},
                            country: params.country,
                            province: params.province,
                            locality: params.locality,
                            radius: 20,
                        };
                        this.textPlaceInterestingFilter.emit(objInitSearch);
                    });
                } else {
                    this.getAddressAutocompleteData(text);
                }
            });
    }

    getGeoPositionInteresting(option) {
        this.getGeoPlace(option).subscribe(
            addressDetail => {
                this.objPlaceInteresting = {
                    name: option,
                    geo: addressDetail.geometry.location,
                    country: addressDetail.address_components.country,
                    province: addressDetail.address_components.province,
                    locality: addressDetail.address_components.locality,
                    radius: 1,
                };
                this.currentDistanceToCenter = 1;
                this.textPlaceInterestingFilter.emit(this.objPlaceInteresting);
            });
    }

    getAddressAutocompleteData(text) {
        this.optionsPlaceInteresting$ = this.autocomplete.search(text);
    }

    displayFn(address) {
        this.placeInterestingSelected = address;
        return address;
    }

    getGeoPlace = (text): Observable<any> => {
        return this.autocomplete.details(text);
    }

    handleStar(star, button) {
        star.selected = !star.selected;
        this.handleInputValue('star', button);
    }

    handleKey(key) {
        if (key.selected === false) {
            this.handleFilterType('key');
            key.selected = true;
            return;
        } else {
            this.handleFilterType('Deselectkey');
            this.apartamentosCheckbox.checked = false;
            key.selected = false;
        }
    }

    checkApartments() {
        const selectApartment = this.filters.accommodations.find(e => e === "Apartamento" || e === "Apartment");
        if (selectApartment === "Apartamento" || selectApartment === "Apartment") {
            this.key.selected = true;
        } else {
            this.key.selected = false;
        }

    }

    handleInputValue(type, input, parent?) {
        if (parent !== undefined) {
            parent.checked = false;
        }
        this.distance = input;
        this.filters = this.handleFilterType(type);
        this.filterSelected.emit(this.filters);
    }

    setChecks(accommodationType: string, accommodationItems: string[], isChecked: boolean) {
        this.accommodations.forEach((accommodation, i) => {
            accommodationItems.forEach((accommodationItem) => {
                if (accommodationItem === accommodation.accommodation) {
                    accommodation.checked = isChecked;
                }
            });
        });
        accommodationItems.forEach(accommodationItem => {
            if (!isChecked) {
                this.filters.accommodations.push(accommodationItem);
            } else {
                Array.from(this.filters.accommodations).forEach((filter, i) => {
                    if (filter.indexOf(accommodationItem) !== -1) {
                        this.filters.accommodations.splice(i);
                    }
                });
            }
        });
        if (accommodationItems[0] === "Apartamento") {
            this.accommodationsGroups.forEach(acc => {
                if (acc.type === 'Apartamentos') {
                    acc.checked = isChecked;
                }
            });
        }
    }

    handleInputGroupValue(accommodationType, isChecked) {
        const accommodation: AccommodationGroup = this.accommodationsGroups.find(accommodation => accommodation.type === accommodationType);
        this.setChecks(accommodation.type, accommodation.items, isChecked);
        this.handleInputValue('accommodation', null);
    }

    handleFilterType(type) {
        switch (type) {
            case 'star':
                return Object.assign(
                    {},
                    this.filters,
                    {stars: this.stars.filter(el => el.selected === true).map(el => el.id), type}
                );
            case 'key':
                this.handleInputGroupValue('Apartamentos', true);
                return;
            case 'accommodation':
                const a = Object.assign(
                    {},
                    this.filters,
                    {accommodations: this.accommodations.filter(el => el.checked === true).map(el => el.accommodation), type},
                );
                this.filters = a;
                this.checkApartments();
                return a;
            case 'rate':
                return Object.assign(
                    {},
                    this.filters,
                    {rates: this.rates.filter(el => el.checked === true).map(el => el.rateName), type}
                );
            case 'price':
                return Object.assign(
                    {},
                    this.filters,
                    {prices: {min: this.prices.lower, max: this.prices.upper}, type}
                );
            case 'radius':
                return Object.assign(
                    {},
                    this.filters,
                    {radius: this.distance, type}
                );
            case 'Deselectkey':
                this.handleInputGroupValue('Apartamentos', false);
                return;
        }
    }

    selectByDistanceToCenter(event: MatCheckboxChange, distanceToCenter: number) {
        if (!event.checked) {
            this.handleInputValue('radius', 20);
            return;
        }
        this.currentDistanceToCenter = distanceToCenter;
        switch (distanceToCenter) {
            case 1:
                this.handleInputValue('radius', this.currentDistanceToCenter);
                break;
            case 3:
                this.handleInputValue('radius', this.currentDistanceToCenter);
                break;
            case 5:
                this.handleInputValue('radius', this.currentDistanceToCenter);
                break;
        }
    }

    selectByPriceRange({lower, upper}) {
        this.prices.lower = lower;
        this.prices.upper = upper;
        this.eventRangePrice$.next({lower, upper});
    }

    selectByDistanceRange(value) {
        this.distance.upper = value;
    }


}
