import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseEntity } from '../lib-shared/Entities/index';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ClientServices, IntegrationsService, NotificationPopupService } from '../lib-shared/services/index';
import { TranslateService } from '@ngx-translate/core';
import { TecnoturisService } from '../tecnoturis.service';
import { AgencyChangeService } from '../agency-change/index';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DossierPerClientsComponent } from '../dossier-per-clients/index';
import { Client } from '../lib-shared/interfaces/index';

@Component({
    selector: 'lib-client-autocomplete',
    templateUrl: './client-autocomplete.component.html',
    styleUrls: ['./client-autocomplete.component.scss']
})
export class ClientAutocompleteComponent {

    @Input() id: string;
    @Input() dossierIsAvailable = true;

    newClient: boolean;
    newClientForm: any;
    client: Client;
    existingClientForm: FormGroup;
    clientList$;
    baseEntity: BaseEntity;
    nationalities: any;
    agency: number;
    agencyId;


    host: string;

    clientDelete;

    constructor(
        protected location: Location,
        protected http: HttpClient,
        protected clientServices: ClientServices,
        protected notificationPopupService: NotificationPopupService,
        protected translate: TranslateService,
        protected tecnoturisService: TecnoturisService,
        protected agencyChangeService: AgencyChangeService,
        protected dialog: MatDialog,
        private integrationsService: IntegrationsService
    ) {
        this.newClient = false;
        this.baseEntity = new BaseEntity(this.http, this.tecnoturisService);

        if (this.agencyChangeService) {
            this.agencyChangeService.getAgency().subscribe(value => this.agency = value);
        }

        this.clientServices.getClient().then(data => {
            this.client = data;
            [this.existingClientForm, this.newClientForm, this.clientList$] = this.clientServices.initClientsForm();
            this.clientList$ = this.setClientListenerForAutocomplete(this.existingClientForm);
         /*    const nationalitiesEntity = this.baseEntity.getEntity(this.tecnoturisService.config.nationalityHost, 'nationality');
            nationalitiesEntity.list(1, 300, null, {sortField: 'name', sortDirection: 'ASC'}).subscribe(nationalities => {
                this.nationalities = nationalities.rows;
                // todas las nacionalidades posibles
                // this._hotelBookService.nationalities = this.nationalities;
                //this.newClientForm.controls['nationality'].setValue(this.nationalities[207].code);
            }); */
        });

        this.host = tecnoturisService.config.management
    }

    cancel() {
        this.location.back();
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    async saveClientBase() {
        Object.keys(this.newClientForm.controls).forEach(field => {
            const control = this.newClientForm.get(field);
            control.markAsTouched({onlySelf: true});
        });

        if (this.newClientForm.valid) {

            const clientData = this.newClientForm.value;

            delete clientData.id;

            const data = {...clientData, ...{agency: this.agency}};

            if(data.document == ""){
                data.document = "00000001W"
            }
            return this.clientServices.clientEntity.create(data).pipe(map(data => {
                this.client = data;
                this.clientServices.addClient({...this.client, isPax: clientData['isPax']});
                return data;
            })).subscribe();
        }
    }

    toogleClientForm() {
        this.newClient = !this.newClient;
    }

    public setClientListenerForAutocomplete(existingClientForm: FormGroup): Observable<any> {
        return existingClientForm
            .get('clientAutocomplete')
            .valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                map((value: string) => value.trim()),
                filter((value: string) => value.length > 3),
                switchMap((term: string, agencyId) => 
                    this.clientServices.clientEntity.list(1, 10, term)
                    .pipe(
                        map(data => data['rows']
                        )),
                ));
    }

    displayFn(client: Client) {
        return client && client.final_name ? client.final_name : '';
    }

    clientSelected(data) {
        const rawClient = data.option.value as Client;
        const { name, surname, final_name } = rawClient;
        this.client = {
            ...rawClient,
            name: name ? name : final_name,
            surname: surname ? surname : final_name,
        };
        this.clientServices.addClient(this.client);
    }

    checkClient(): boolean {
        if (!this.client) {
            this.notificationPopupService.openPopup(this.translate.instant('ERROR.HOLDER'));
            return false;
        }

        return true;
    }

    removeCurrentClient(existingClientForm) {
        // clientAutocomplete
        // this.deleteClient()
        this.client = null;
        this.clientServices.removeClient();
        existingClientForm.controls['clientAutocomplete'].setValue(null);

        // remove client from all items on basket
    }

    async deleteClient(){
        this.clientDelete = await this.clientServices.getClient();
        return this.http.delete(`${this.host}/clients/${this.clientDelete.id}/`).subscribe();
    }

    async saveClient() {
        this.saveClientBase().then(
            savedClient => {}
        );
    }

    showClient() {}

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    showDossiers(): void {
        const dialogRef = this.dialog.open(DossierPerClientsComponent, {
            data: {
                client: this.client.id
            },
            width: '80%'
        });
        dialogRef.afterClosed()
            .subscribe(value => {
                if (value) {
                    this.integrationsService.setDossier(value);
                }
            });
    }

}
