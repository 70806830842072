import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierComponent } from './dossier.component';
import { DossierAddEditComponent } from './dossier-add-edit/dossier-add-edit.component';
import { DossierPaxComponent } from './dossier-pax/dossier-pax.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BudgetListComponent } from './budget-list/budget-list.component';
import { DynamicCrudModule } from '../../dynamic-crud/index';
import { ManagementFilesModule } from '../../management-files/index';
import { PaymentModalComponent } from './dossier-add-edit/payment-modal/payment-modal.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { LibSharedModule } from '../../lib-shared/index';
import { BillsComponent } from './bills/bills.component';
import { ServiceTypeModalComponent } from './dossier-add-edit/service-type-modal/service-type-modal.component';
import { DossierListModule } from '../../dossier-list/index';
import { HotelBookingListModule } from '../hotel-booking/index';
import { MaterialDatePickerModule } from '../../material-date-picker/index';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import {AbonoListComponent} from "./abono-list/abono-list.component";
import {AbonoModalComponent} from "./dossier-add-edit/abono-modal/abono-modal.component";
import { DocumentHeaderModule } from '../document-header/document-header.module';
import { PrepaidModalComponent } from './dossier-add-edit/prepaid-modal/prepaid-modal.component';
import { PrepaidModalModule } from './dossier-add-edit/prepaid-modal/prepaid-modal.module';
import { PrepaidListModule } from './dossier-add-edit/prepaid-list/prepaid-list.module';
import { SupplierInvoiceModalModule } from './dossier-add-edit/supplier-invoice-modal/supplier-invoice-modal.module';
import { SupplierInvoiceModalComponent } from './dossier-add-edit/supplier-invoice-modal/supplier-invoice-modal.component';
import { SupplierInvoiceListModule } from './dossier-add-edit/supplier-invoice-list/supplier-invoice-list.module';

@NgModule({
    declarations: [
        DossierComponent,
        DossierAddEditComponent,
        DossierPaxComponent,
        BillsComponent,
        ServiceTypeModalComponent,
        BudgetListComponent,
        AbonoListComponent,
        PaymentModalComponent,
        PaymentListComponent,
        AbonoModalComponent,
    ],
    imports: [
        CommonModule,
        DossierListModule,
        DynamicCrudModule,
        FormlyModule,
        ReactiveFormsModule,
        IonicModule,
        HotelBookingListModule,
        ManagementFilesModule,
        LibSharedModule,
        FormsModule,
        MaterialDatePickerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatSelectModule,
        RouterModule,
        DocumentHeaderModule,
        PrepaidModalModule,
        PrepaidListModule,
        SupplierInvoiceModalModule,
        SupplierInvoiceListModule,
    ],
    entryComponents: [
        ServiceTypeModalComponent,
        PaymentModalComponent,
        AbonoModalComponent,
        PrepaidModalComponent,
        SupplierInvoiceModalComponent,
    ],
    exports: [
        DossierComponent,
        DossierAddEditComponent,
        DossierPaxComponent,
        BillsComponent,
        ServiceTypeModalComponent,
        BudgetListComponent,
        AbonoListComponent,
        PaymentModalComponent,
        PaymentListComponent,
        AbonoModalComponent]
})
export class DossierModule {
}
