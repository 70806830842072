import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { PriceGroup } from '@vecib2c/frontend-dto/dist';
import { IFlightPax } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-flight-price-summary',
    templateUrl: './flight-price-summary.component.html',
    styleUrls: ['./flight-price-summary.component.scss']
})
export class FlightPriceSummaryComponent implements OnInit, OnDestroy {

    @Input() priceGroup: PriceGroup;
    @Input() pax: IFlightPax;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

}
