import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import * as uuidv1_ from 'uuid/v1';
import {PriceGroup, FlightItinerary, PrebookResDto} from '@vecib2c/frontend-dto/dist';
import {FlightProviderService, NotificationPopupService} from '../../../lib-shared/services/index';
import {FLIGHTS_AIRPORTS} from '../../../../mock/flights';
import {SERVICES} from '../../../const';
import {MicrositeService} from '../../../lib-shared/services/microsite.service';
import { FlightState, IFlightPax, PreBookingMapperRequest } from '../../../lib-shared/interfaces/index';

const uuidv1 = uuidv1_;

export interface FlightExtended extends PriceGroup {
    distribution?: { adults: number, children: number, infants: number };
    uuid?: string;
    commission?: number;
    commissionRate?: number;
    fee?: number;
}

interface IPlaceFlight {
    origin: string;
    destination: string;
}

@Component({
    selector: 'lib-flight-multiflight-item-card',
    templateUrl: './flight-multiflight-item-card.component.html',
    styleUrls: ['./flight-multiflight-item-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FlightMultiflightItemCardComponent implements OnInit {

    @Input() item: FlightExtended;
    @Input() index: any;
    @Input() itinerariesMax: number;
    @Input() bookingIsDisabled: boolean;
    @Input() selectByRadio: boolean;
    @Input() checked: boolean;
    @Input() selectByButton: boolean;
    @Input() onlyGoing = false;
    @Input() microsites = false;
    @Input() selectedItinerary: FlightItinerary;
    @Input() selectedFlightPrice: number;
    @Input() showPricePerPerson = true;
    @Input() showTotalPrice = false;
    @Input() showDifferentialPrice = false;
    @Input() selectedPriceGroup: PriceGroup;
    @Input() set segments(value: IPlaceFlight[]) {
        this._segments = value;
        this._segments.forEach((segment: IPlaceFlight) => {
            this.origins.push(segment.origin.split(',')[0]);
            this.destinations.push(segment.destination.split(',')[0]);
        });
    }
    @Input() set pax(pax: IFlightPax) {
        this._pax = pax;
        if (pax) {
            this.numberOfPaxes = pax.adults + pax.children + pax.infants;
        }
    }

    airports;
    _segments: IPlaceFlight[];
    origins: string[] = [];
    destinations: string[] = [];
    numberOfPaxes: number;

    get segments(): IPlaceFlight[] {
        return this._segments;
    }
    get pax(): IFlightPax {
        return this._pax;
    }

    @Output() clearItineraries = new EventEmitter<number>();

    private _pax: IFlightPax;

    constructor(
        public router: Router,
        public notification: NotificationPopupService,
        private micrositeService: MicrositeService,
        private readonly flightService: FlightProviderService,
    ) {
    }

    ngOnInit(): void {
        this.airports = FLIGHTS_AIRPORTS;
    }

    async goToBookingPage(): Promise<void> {

        if (!this.validateItinerariesBelongToSamePriceGroup()) {
            this.notification.openPopup("Los vuelos deben pertenecer al mismo grupo");
            return;
        }
        if (!this.validateCountItineraries()) {
            this.notification.openPopup("Debe completar la selección de vuelos");
            return;
        }

        const uuid = uuidv1();

        let lowCostPreBookingResponse: PrebookResDto;

        const itineraryIds = this.selectedPriceGroup.itineraries.map(i => i.id);

        if (this.item.isLowCost) {
            const preBookingParameters = PreBookingMapperRequest.build(
                this.item.id,
                this.item.requestId,
                itineraryIds,
                this.pax.adults,
                this.pax.children,
                this.pax.infants,
            );

            lowCostPreBookingResponse = await this.flightService.preBookLowCost(preBookingParameters).toPromise();
        }

        const flightState = FlightState.build(
            this.item,
            itineraryIds,
            this.pax,
            0,
            0,
            0,
            uuid,
            lowCostPreBookingResponse,
        );

        const navigationExtras: NavigationExtras = {
            state: {
                flight: flightState,
            }
        };

        if (this.micrositeService.isMicrosite()) {
            this.micrositeService.setBookingData(SERVICES.FLIGHT, {uuid, navigationExtras, item: this.item});
            return;
        } else {
            this.router.navigate(['integration', 'bookings', 'book-edit', 'flights', uuid], navigationExtras);
        }
    }

    selectItinerary(props: { itineraryId: number, segmentNumber: number }): void {
        const index = this.item.itineraries.findIndex(i => i.id === props.itineraryId);
        this.clearItineraries.emit(index);
    }

    private validateItinerariesBelongToSamePriceGroup(): boolean {
        return this.selectedPriceGroup && this.selectedPriceGroup.id === this.item.id;
    }

    private validateCountItineraries(): boolean {
        return this.selectedPriceGroup && this.selectedPriceGroup.itineraries.every(i => !!i);
    }

}
