import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NotificationPopupService } from '../../../lib-shared/services/index';
import { ShoppingBasketTransfer } from '../../../lib-shared/interfaces/shopping-basket-transfers.model';
import { TransferService } from "../../../lib-shared/services/transfer.service";
import { CancelTransferReq } from "../../../lib-shared/interfaces/transfers/request/cancel-request.dto";
import { CancelTransfer } from "../../../lib-shared";

@Component({
    selector: 'lib-transfer-book-confirmation',
    templateUrl: './transfer-book-confirmation.component.html',
    styleUrls: ['./transfer-book-confirmation.component.scss'],
    providers: [
        TransferService
    ]
})

export class TransferBookConfirmationComponent implements OnInit, OnDestroy {

    @Input() shoppingBasket: ShoppingBasketTransfer;

    bookingId: number;
    test: number;

    isCancelled: boolean;

    cancelled: CancelTransfer;
    status: number;
    render: boolean;
    roundtrip: string; 
    fromTime: string;
    toTime: string;

    children: number
    infants: number

    private unbsubscribe = new Subject<void>();

    constructor(
        private readonly notificationPopupService: NotificationPopupService,
        private readonly transferService: TransferService,
    ) {
    }

    async ngOnInit() {
        this.renderFromAndToDestination(this.shoppingBasket.transfer[0].transferTypeCode);
        this.renderFromTime(this.shoppingBasket.from.dateTime);
        this.renderToTime(this.shoppingBasket.to.dateTime);

        this.children = parseInt(this.shoppingBasket.occupancy.children)
        this.infants = parseInt(this.shoppingBasket.occupancy.infants)
    }

    ngOnDestroy(): void {
        this.unbsubscribe.next();
        this.unbsubscribe.complete();
    }

    cancel() {
        const bookingDeleteRequest: CancelTransferReq = {
            provider: this.shoppingBasket.provider,
            bookingID: this.shoppingBasket.locator,
        };
        this.transferService.cancelBooking(bookingDeleteRequest)
            .pipe(
                map(res => {
                    this.cancelled = res.data
                    this.status = res.status
                }),
                takeUntil(this.unbsubscribe),
            )
            .subscribe(async(bookingDeleteRes) => {
                if(this.status == 200){
                    this.isCancelled = true;
                    this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_CANCEL_BOOKING");
                    await this.transferService.RemoveDossier(this.shoppingBasket.id).toPromise()
                            .then(res => console.log(res))
                            .catch(err => console.error(err))
                } else {
                    this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.ERROR_CANCEL_BOOKING");
                    throw Error ("We can not cancell your transfer booking. Sorry, it's not our foult.")
                }
            });
    }

    renderFromAndToDestination(type: string){
        if(type == "ROU"){
            this.render = true;
            this.roundtrip = "IDA Y VUELTA"
        } else {
            if(type == "ARR"){
                this.render = true
                this.roundtrip = "SOLO IDA"
            } 
            if(type == "DEP"){
                this.render = false
                this.roundtrip = "SOLO IDA"
            }
        }
    }

    renderFromTime(input){
        const date = input;
        const [yyyy,mm,dd,hh,mi] = date.split(/[/:\-T]/)
        this.fromTime = hh+":"+mi
        return this.fromTime
    }

    renderToTime(input){
        const date = input;
        const [yyyy,mm,dd,hh,mi] = date.split(/[/:\-T]/)
        this.toTime = hh+":"+mi
        return this.toTime
    }

}