import { Pipe, PipeTransform } from "@angular/core";
import { Pax, SalesGroup } from '../interfaces/index';

@Pipe({
    name: 'totalByCabinPipe'
})
export class TotalByCabinPipe implements PipeTransform {

    transform(salesGroup: SalesGroup): number {
        if (!salesGroup) {
            return 0;
        }
        const total = salesGroup.cabins[0].paxes.reduce((accumulator: number, pax: Pax) => {
            accumulator = accumulator + (pax.fares[0].commission.pvp * pax.paxNumber);
            return accumulator;
        }, 0);

        return total;
    }
}
