import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FlightItinerary } from "@vecib2c/frontend-dto";
import { PricedSegment } from "@vecib2c/frontend-dto/dist/dto/Flights/Flight/list/flight.entity";

@Component({
  selector: "lib-flights-item-itinerary",
  templateUrl: "./flights-item-itinerary.component.html",
  styleUrls: ["./flights-item-itinerary.component.scss"]
})
export class FlightsItemItineraryComponent implements OnInit {

  @Input() itinerary: FlightItinerary;
  @Input() itineraries: FlightItinerary[];
  @Input() priceGroupId: number;
  @Input() selectByRadio = true;
  @Input() checked: boolean;
  @Input() selectByButton: boolean;
  @Input() selectedItinerary: FlightItinerary;
  @Input() selectedPriceGroupId: number;

  airStops: number;
  availableSeats: number;
  cabinClass: string;
  baggageQuantity: number;
  dspNone: boolean;
  changeIcon: boolean;
  transformIcon: boolean;

  @Output() selectItinerary = new EventEmitter<{ itineraryId: number, segmentNumber: number }>();

  constructor() {}

  ngOnInit(): void {
    this.dspNone = true;
    this.transformIcon = false;
    this.airStops = this.itinerary.segments.length - 1;
    this.availableSeats = this.calculateAvailableSeats(
      this.itinerary.pricedSegments
    );
    this.cabinClass = this.calculateCabinClass(this.itinerary.pricedSegments);
    this.baggageQuantity = this.calculateBaggageQuantity(
      this.itinerary.pricedSegments
    );
  }

  calculateAvailableSeats(segments: Array<PricedSegment>): number {
    return Math.min.apply(Math, segments.map(e => e.availableSeats));
  }

  calculateCabinClass(segments: Array<PricedSegment>): string {
    // TODO: Define how to calculate cabinClass
    return segments[0].cabinClass;
  }

  calculateBaggageQuantity(segments: Array<PricedSegment>): number {
    return Math.min.apply(Math, segments.map(e => e.baggageQuantity));
  }

  selectFlight(): void {
    this.selectItinerary.emit({
      itineraryId: this.itinerary.id,
      segmentNumber: this.itinerary.segmentNumber
    });
  }

  toggleSegment(): void {
    this.dspNone = !this.dspNone;
    this.changeIcon = !this.changeIcon;
  }
}
