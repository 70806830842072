import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {
    MultidestinationBookingResponse,
    ShoppingBasketMultidestination
} from "../../../../lib-shared/interfaces/index";

interface BookingErrorModal {
    shoppingBasket: ShoppingBasketMultidestination;
    bookingResponse: MultidestinationBookingResponse;
}

@Component({
    selector: "lib-multidestination-booking-error-modal",
    templateUrl: "./booking-error-modal.component.html",
})
export class MultidestinationBookingErrorModalComponent {

    shoppingBasket: ShoppingBasketMultidestination;
    bookingResponse: MultidestinationBookingResponse;

    constructor(
        public dialogRef: MatDialogRef<MultidestinationBookingErrorModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BookingErrorModal
    ) {
      this.shoppingBasket = data.shoppingBasket;
      this.bookingResponse = data.bookingResponse;

    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
