import { Component, OnInit } from '@angular/core';
import { TecnoturisService } from "../../tecnoturis.service";
import { cruiseFormMetaData } from './cruise-booking.form';

@Component({
  selector: 'lib-cruise-booking',
  templateUrl: './cruise-booking.component.html',
  styleUrls: ['./cruise-booking.component.scss']
})
export class CruiseBookingComponent implements OnInit {
    cruiseCrud = cruiseFormMetaData;
  

  constructor(private tecnoturisService: TecnoturisService) {
    if (this.tecnoturisService.config.forms.cruiseForm) {
      this.cruiseCrud = this.tecnoturisService.config.forms.cruiseForm;
    }
  }

  ngOnInit() {
  }

}
