import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DossierListComponent } from './dossier-list.component';
import { DynamicCrudModule } from '../dynamic-crud/index';

@NgModule({
    declarations: [DossierListComponent],
    imports: [
        CommonModule,
        DynamicCrudModule,
        FormlyModule,
        ReactiveFormsModule,
        IonicModule
    ],
    exports: [DossierListComponent]
})
export class DossierListModule {
}
