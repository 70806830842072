import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { PaxesHotelComponent } from './paxes-hotel.component';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [PaxesHotelComponent],
    imports: [
        CommonModule,
        LibSharedModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule
    ],
    exports: [PaxesHotelComponent]
})
export class PaxesHotelModule {
}
