import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BookingsDeleteRequestDto, ShoppingBasketTrains } from '../../../lib-shared/interfaces/index';
import { NotificationPopupService, TrainsProviderService } from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-trains-book-confirmation',
    templateUrl: './trains-book-confirmation.component.html',
    styleUrls: ['./trains-book-confirmation.component.scss'],
})

export class TrainsBookConfirmationComponent implements OnInit, OnDestroy {

    @Input() shoppingBasket: ShoppingBasketTrains;

    isCancelled: boolean;

    private unbsubscribe = new Subject<void>();

    constructor(
        private readonly notificationPopupService: NotificationPopupService,
        private readonly trainsService: TrainsProviderService,
    ) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.unbsubscribe.next();
        this.unbsubscribe.complete();
    }

    cancel() {
        const bookingDeleteRequest: BookingsDeleteRequestDto = {
            fileId: this.shoppingBasket.fileId,
            ticketReference: this.shoppingBasket.ticketReference
        };

        this.trainsService.cancelBooking(bookingDeleteRequest)
            .pipe(
                map(res => res.data),
                takeUntil(this.unbsubscribe),
            )
            .subscribe(bookingDeleteRes => {
                this.isCancelled = true;
                this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_CANCEL_BOOKING");
            });
    }

}
