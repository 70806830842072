import { IFlightBookingPax } from '@vecib2c/frontend-dto';
import Pax from '../../paxes/Pax';
import { safeNumber } from '../functions/index';


export interface IFlightPax {
  adults: number;
  children: number;
  infants: number;
}

export class FlightPaxMapper {

  static buildFromPax(paxes: Pax[]): IFlightPax {
    const obj = paxes.reduce((acc, cur) => {
      let numberOfChildren = 0;
      let numberOfInfants = 0;
      cur.children.forEach(element => {
        if (element > 1) {
          numberOfChildren ++;
        } else {
          numberOfInfants ++;
        }
      });
      return {
        adults: safeNumber(acc.adults) + safeNumber(cur.adults),
        children: safeNumber(acc.children) + numberOfChildren,
        infants: safeNumber(acc.infants) + numberOfInfants,
      };
    }, {} as IFlightPax);
    return obj;
  }

  static buildFromFlightBookingPaxes(
      bookingPaxes: IFlightBookingPax[],
  ): IFlightPax {
    const obj = bookingPaxes.reduce((acc, pax) => {
      if (pax.typePax === 'Adult') {
        return {
          ...acc,
          adults: acc.adults + 1,
        };
      }
      if (pax.typePax === 'Child') {
        return {
          ...acc,
          children: acc.children + 1,
        };
      }
      if (pax.typePax === 'Infant') {
        return {
          ...acc,
          infants: acc.infants + 1,
        };
      }
    }, { adults: 0, children: 0, infants: 0 } as IFlightPax);
    return obj;
  }
}

export enum FlightPaxEnum {
  'adults' = 'Adult',
  'children' = 'Child',
  'infants' = 'Infant',
}
