export * from './management-client.module';
export * from './client-commercial-activities/client-commercial-activities.form';
export * from './client-commercial-activities/client-commercial-activities.component';
export * from './client-commercial-activities/client-commercial-activities-add-edit/client-commercial-activities-add-edit.component';

export * from './client-files/client-files.component';
export * from './client-files/client-files.form';

export * from './clients-add-edit/clients-add-edit.component';
export * from './management-client-with-routing.module';
export * from './contact-person/contact-person.component';
export * from './management.client.service';
