import { Component, OnInit } from '@angular/core';
import { flightFormMetaData } from './flight-booking.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-flight-booking',
    templateUrl: './flight-booking.component.html',
    styleUrls: ['./flight-booking.component.scss']
})
export class FlightBookingComponent implements OnInit {
    flightCrud = flightFormMetaData;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.flightForm) {
            this.flightCrud = this.tecnoturisService.config.forms.circuitForm;
        }
    }

    ngOnInit() {
    }

}
