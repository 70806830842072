import { Pipe, PipeTransform } from "@angular/core";
import { HotelProvider } from "@vecib2c/frontend-dto";

@Pipe({
    name: 'cheapestProvider'
})

export class CheapestProviderPipe implements PipeTransform {
    transform(providers: HotelProvider[]): any {
        let provider = {};
        if (providers.length) {
            providers.forEach(element => {
                if (element['cheapest']) {
                    provider = element;
                }
            });
        }

        return provider;
    }
}
