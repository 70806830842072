import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IonicModule } from '@ionic/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MultiflightItemComponent } from './multiflight-item/multiflight-item.component';
import { MultiflightComponent } from './multiflight.component';
import { PaxesModule } from '../paxes/index';
import { LibSharedModule } from '../lib-shared/index';
import { MaterialDatePickerModule } from "../material-date-picker/material-date-picker.module";

@NgModule({
    declarations: [MultiflightComponent, MultiflightItemComponent],
    imports: [
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        IonicModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        PaxesModule,
        MatExpansionModule,
        LibSharedModule,
        MaterialDatePickerModule,
    ],
    exports: [MultiflightComponent, MultiflightItemComponent]
})
export class MultiflightModule {
}
