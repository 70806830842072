import {
    FlightItinerary,
    FlightRateUpdateReqDto,
    FlightItineraryRate,
    PricedSegment,
    Segment,
    FlightRateSegment,
    FlightRateBaggage,
    IFlightBookingPax,
    PrebookResDto,
    ICabinBaggage,
} from "@vecib2c/frontend-dto";
import { ShoppingBasketFlight } from "../../../integrations/hotels/dto/ShoppingBasketFlight";

export class FlightRateReqMapper {

    static buildFromBasket(item: ShoppingBasketFlight): FlightRateUpdateReqDto {
        const { clonedData:
            {
                id,
                itineraries,
                isLowCost,
                requestToken,
                requestId,
                commissionableRate,
                nonCommissionableRate,
                tripType,
            },
            paxes,
            lowCostPreBooking,
            cabinBaggage,
        } = item;

        const params: FlightRateUpdateReqDto =  {
            priceGroupId: id,
            itineraries: this.buildRateItinerary(itineraries),
            isLowCost,
            requestToken,
            requestId,
            commissionableRate,
            nonCommissionableRate,
            kindOfTravel: tripType,
        };

        const baggages = this.buildRateBaggages(paxes, lowCostPreBooking, cabinBaggage);
        if (baggages && baggages.length > 0) {
            params.baggages = baggages;
        }

        return params;
    }

    static buildRateItinerary(itineraries: FlightItinerary[]): FlightItineraryRate[] {
        const arr = itineraries.map(itinerary => {
            return {
                itineraryId: itinerary.id,
                segments: this.buildSegments(itinerary.pricedSegments, itinerary.segments),
            };
        });
        return arr;
    }

    static buildSegments(
        pricedSegments: PricedSegment[],
        segments: Segment[],
    ): FlightRateSegment[] {
        const arr = pricedSegments.reduce((acc, pricedSegment) => {
            const { baggageQuantity, baggageWeight, cabinClass, cabinType } = pricedSegment;
            const segmentFound = segments.find(segment => segment.id === pricedSegment.itinerarySegmentId);
            if (!segmentFound) { return acc; }
            return [...acc, {
                baggageQuantity,
                baggageWeight,
                cabinClass,
                cabinType,
                flightNumber: segmentFound.flightNumber,
            }];
        }, []);
        return arr;
    }

    static buildRateBaggages(
        paxes: IFlightBookingPax[],
        lowCostPreBooking: PrebookResDto,
        cabinBaggage: ICabinBaggage,
    ): FlightRateBaggage[] {
        if (!lowCostPreBooking) { return; }
        const { listAirBookBaggages } = lowCostPreBooking;
        if (!listAirBookBaggages) { return; }
        const passengerBaggages = paxes.reduce((acc, pax) => {
            const {luggageNumberOption, luggageOption } = pax;
            const bagggeFound = listAirBookBaggages.find(b => b.luggageOption === luggageOption && b.numberOption === luggageNumberOption);
            if (!bagggeFound) { return acc; }
            if (pax.luggageId && pax.luggageNumberOption && pax.luggageOption) {
                return [...acc, {
                    numberOption: luggageNumberOption,
                    luggageOption,
                    isByPax: true,
                    price: bagggeFound.price,
                }];
            }
            return acc;
        }, [] as FlightRateBaggage[]);

        if (!cabinBaggage) { return passengerBaggages; }
        const cabinBagggeFound = listAirBookBaggages.find(b => {
            return b.luggageOption === cabinBaggage.luggageOption && b.numberOption === cabinBaggage.numberOption;
        });

        const cabinBaggages: FlightRateBaggage[]  = [{
            numberOption: cabinBaggage.numberOption,
            luggageOption: cabinBaggage.luggageOption,
            isByPax: false,
            price: cabinBagggeFound.price,
        }];

        return [...passengerBaggages, ...cabinBaggages];
    }
}
