import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {
    AutocompleteTypeComponent,
    CityOrPostalCodeValidator,
    CityOrPostalCodeValidatorMessage,
    CustomFormlyTemplatesModule,
    DatePickerComponent,
    DatetimePickerComponent,
    FormlyFieldFile,
    maxlengthValidationMessage,
    maxValidationMessage,
    minlengthValidationMessage,
    minValidationMessage,
    NifValidator,
    NifValidatorMessage,
    packageFormMetaData,
    SearchSelectComponent,
    TecnoturisModule,
    uniqueValidatorMessage,
    ValidationsFields
} from '../../projects/tecnoturis/src/public-api'
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from './shared/shared.module';
import { AlertConfirmComponent } from './shared/components/alert-confirm/alert-confirm.component';
import { AlertMessageComponent } from './shared/components/alert-message/alert-message.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpInterceptorService } from './shared/service/http.interceptor.service';
import { MatNativeDateModule } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClientAuthModule } from '../../projects/client-auth/src/lib/client-auth.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {ToastrModule} from 'ngx-toastr';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeEs, 'es');

const clientHost = environment.clientHost;

@NgModule({
    declarations: [AppComponent],
    entryComponents: [
        AlertConfirmComponent,
        AlertMessageComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__mydb',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TecnoturisModule.forRoot(environment as any),
        // TecnoturisModule.forRoot({
        //     ...environment as any, forms: {
        //         clientForm,
        //         flightForm,
        //         hotelForm,
        //         packageForm: packageFormMetaData,
        //         circuitForm,
        //         safesForm,
        //         transportForm: transportsForm
        //     },
        //     actions: {
        //         client: {
        //             back: false,
        //             dossier: true,
        //             dossiers: true,
        //             commercialAction: false,
        //             save: false,
        //             presupuesto: false
        //         },
        //     }
        // }),
        SharedModule.forRoot(),
        ReactiveFormsModule,
        PdfViewerModule,
        MatNativeDateModule,
        ColorPickerModule,
        ClientAuthModule.forRoot({
            authTokenEndpoint: clientHost + '/api/v1/user/auth/client/token-auth/',
            clientRestEndpoint: clientHost + '/api/v1/client/external/',
            confirmPasswordEndpoint: clientHost + '/api/v1/password_reset/confirm/',
            resetPasswordEndpoint: clientHost + '/api/v1/client_password_reset/',
            meEndpoint: clientHost + '/api/v1/user/me/',
            redirectToAfterLogin: '/admin/'
        }),
        CustomFormlyTemplatesModule,
        FormlyMaterialModule,
        ToastrModule.forRoot(),
        FormlyModule.forRoot({
            validators: [
                {name: 'nif', validation: NifValidator},
                {name: 'postalcode', validation: CityOrPostalCodeValidator}
            ],
            validationMessages: [
                {name: 'nif', message: NifValidatorMessage},
                {name: 'postalcode', message: CityOrPostalCodeValidatorMessage},
                {name: 'required', message: 'Tiene que rellenar el campo obligatorio'},
                {name: ValidationsFields.UNIQUE_VALIDATOR, message: uniqueValidatorMessage},
                {name: 'minlength', message: minlengthValidationMessage},
                {name: 'maxlength', message: maxlengthValidationMessage},
                {name: 'min', message: minValidationMessage},
                {name: 'max', message: maxValidationMessage},
            ],
            types: [
                {
                    name: 'datetime',
                    component: DatetimePickerComponent,
                    wrappers: ['form-field']
                },
                {
                    name: 'file',
                    component: FormlyFieldFile,
                    wrappers: ['form-field']
                },
                {
                    name: 'datepicker',
                    component: DatePickerComponent,
                },
                {
                    name: 'autocomplete',
                    component: AutocompleteTypeComponent,
                },
                {
                    name: 'search-select',
                    component: SearchSelectComponent,
                    wrappers: ['form-field']
                },
            ]
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'es'
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
