import 'hammerjs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { NgxHmCarouselModule } from "ngx-hm-carousel";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from "@angular/platform-browser";
import { Carousel2Component } from './carousel2.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';
import { LibSharedModule } from '../lib-shared/index';

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pan': {threshold: 5},
        'swipe': {
            velocity: 0.4,
            threshold: 20,
            direction: 31
        }
    };
}

@NgModule({
    declarations: [CarouselComponent, Carousel2Component],
    imports: [
        CommonModule,
        NgxHmCarouselModule,
        FormsModule,
        TranslateModule,
        MatDialogModule,
        MatIconModule,
        IonicModule,
        LibSharedModule,
    ],
    exports: [CarouselComponent, Carousel2Component],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        }
    ]
})
export class CarouselModule {
}
