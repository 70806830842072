import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import {AgencyChain, BudgetDetail} from '../../../lib-shared/interfaces/index';
import {AgencyProviderService, AuthenticationService, ManagementService} from '../../../lib-shared/services/index';


@Component({
    selector: 'lib-bills',
    templateUrl: './bills.component.html',
    styleUrls: ['./bills.component.scss']
})

export class BillsComponent implements OnInit, OnDestroy {
    data: BudgetDetail;

    date = new Date();

    displayedColumns = ['description', 'quantity', 'price', 'amount'];

    unsubscribe = new Subject();

    budget_type: string;

    id: number;

    selectedChain: AgencyChain;

    titleSeparator: string;


    constructor(private route: ActivatedRoute,
                private router: Router,
                private managementService: ManagementService,
                private agencyProvider: AgencyProviderService,
                public location: Location) {
        this.route.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                this.id = params['id'];
                this.getBudget(params['id']);
            });
    }

    async ngOnInit() {
        const user = AuthenticationService.getUser();
        this.selectedChain = await this.agencyProvider.getChain(user.agency_chain.id).toPromise();
    }

    async getBudget(id: number): Promise<void> {
        this.data = await this.managementService.getBudget(id).toPromise();
        switch (this.data.budget.budget_type) {
            case 1:
                this.budget_type = 'factura';
                this.titleSeparator = 'de la';
                break;
            case 2:
                this.budget_type = 'presupuesto';
                this.titleSeparator = 'del';
                break;
            case 4:
                this.budget_type = 'abono';
                this.titleSeparator = 'del';
                break;
        }
    }

    async generatePdf(): Promise<void> {
        return this.managementService.generateBillingPdf(this.id);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
