import { Component, Input } from "@angular/core";
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';
import { ShoppingBasketActivity } from "../../../lib-shared/interfaces/index";

@Component({
    selector: 'lib-activity-cart-item',
    templateUrl: './activity-cart-item.component.html',
    styleUrls: ['./activity-cart-item.component.scss']
})

export class ActivityCartItemComponent {

    @Input() item: ShoppingBasketActivity;

    constructor(public shoppingCartSummaryComponent: ShoppingCartSummaryComponent) {
    }
}
