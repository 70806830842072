import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DynamicCrudComponent } from './dynamic-crud.component';
import { DynamicFiltersComponent } from './dynamic-filters/dynamic-filters.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { GetDescriptorFromIdPipe } from './get-descriptor-from-id.pipe';
import { GetNameFromFieldPipe } from './get-name-from-field.pipe';
import { DynamicCrudService, LibSharedModule } from '../lib-shared/index';
import { DialogComponent, DialogModule } from '../dialog/index';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AgencyChangeModule } from '../agency-change/index';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    declarations: [DynamicCrudComponent, DynamicFiltersComponent, DynamicTableComponent,
        GetNameFromFieldPipe, GetDescriptorFromIdPipe],
    exports: [
        DynamicCrudComponent, DynamicFiltersComponent, DynamicTableComponent, GetNameFromFieldPipe,
        GetDescriptorFromIdPipe
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormlyModule,
        MatTableModule,
        MatIconModule,
        LibSharedModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        DialogModule,
        MatPaginatorModule,
        AgencyChangeModule,
        MatRadioModule,
    ],
    providers: [DynamicCrudService],
    entryComponents: [DialogComponent]
})
export class DynamicCrudModule {
}
