import { PackageAddon } from "@vecib2c/frontend-dto";
import { safeNumber } from "../../../../functions/index";

export class PackageAddonMapper {

    static calculateAddonsPrice(addons: PackageAddon[]): number {
        if (!addons) { return 0; }
        return addons.reduce((acc, addon) => {
            const totalOptions = addon.Opciones.reduce((optionAcc, option) => {
                if (!option.selected) { return optionAcc; }
                const adults = option.PaxServicio.Adultos.filter(adult => adult.selected);
                const children = option.PaxServicio.Childs.filter(child => child.selected);
                const infants = option.PaxServicio.Bebes.filter(infant => infant.selected);
                const unitPrice = option.selected ? safeNumber(option.PrecioPax) : 0;
                let total = 0;
                if (adults && adults[0] && adults[0].selected) {
                    total += unitPrice * safeNumber(adults[0].adult);
                }
                if (children && children[0] && children[0].selected) {
                    total += unitPrice * safeNumber(children[0].child);
                }
                if (infants && infants[0] && infants[0].selected) {
                    total += unitPrice * safeNumber(infants[0].infant);
                }
                return optionAcc + total;
            }, 0);
            return acc + totalOptions;
        }, 0);
    }
}
