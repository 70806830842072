import { Subject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import * as moment_ from 'moment';

import { TransferRequestDto } from '../lib-shared/interfaces/transfers/request/transfer.request.dto';
import { Images, Search, Extras, Transfers, TransfersAvailability } from '../lib-shared/interfaces/transfers/response/transfer.response.dto';
import { TransferService } from '../lib-shared/services/transfer.service';
import { IntegrationPageMetaData, IntegrationsService } from '../lib-shared/services/integrations.service';
import { NotificationPopupService } from '../lib-shared/services/notification-popup.service';

import * as uuidv1_ from 'uuid/v1';

const _agency = JSON.parse(localStorage.getItem("_login"))

const uuidv1 = uuidv1_;
const moment = moment_;

const PAGE_START = 0;
const PAGE_SIZE = 10;

export class TransferSearchLit implements OnInit, OnDestroy {

    parametresForTransfers = [
        'language',
        'typeArrived',
        'codeArrived',
        'typeDeparture',
        'codeDeparture',
        'arrDateTime',
        'depDateTime',
        'totalAdults',
        'totalChildrens',
        'totalBabies',
        'dataAges'
    ]
    inputFields: TransferRequestDto;

    search: Search;
    items: Transfers[] = []
    extras: Extras;
    photos: Images;
    
    photoUrl: string;
    onlyDeparture: boolean;
    searchShowHide = false;
    filtersShowHide = false;
    currentKindOfTransport = 'traslados';
    results: number;
    integrationMetaData: IntegrationPageMetaData;

    totalTransfers: number;
    currentPageNumber: number = PAGE_START;
    currentPageSize: number = PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 15];
    rows: Transfers[] = [];

    private unsubscribe = new Subject<void>();

    constructor(
        public readonly transferService: TransferService,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly notificationService: NotificationPopupService,
        public integrationService: IntegrationsService,
    ){
        this.integrationMetaData = {category: 'Transportes', name: 'transportes'};
        this.integrationService.setMetaData(this.integrationMetaData);
    }

    ngOnInit(): void{
        this.route.queryParams
            .pipe(takeUntil(this.unsubscribe))
            .subscribe( params => {
                if(this.isValidQueryString(params)){
                    this.inputFields = this.buildRequestDto(params);
                    this.onlyDeparture = !!!this.inputFields.depDateTime;
                    this.getTransfers();
                    this.filterTransfers();
                }
                this.currentPageNumber = 0;
            })
    }

    ngOnDestroy(): void{
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getTransfers(): void{
        this.transferService.getTransfers(this.inputFields)
            .pipe(
                map(res => {
                    return res}),
                takeUntil(this.unsubscribe),
            )
            .subscribe(res => {
                if(res){
                    if(res.count > 0){
                        res.rows.resAvailTransfers.forEach(item => {
                            this.items.push(item)
                        })
                        this.rows = this.items
                        this.search = res.rows.search;
                        this.integrationService.setMetaData({...this.integrationMetaData, searchCount: res.count});
                        this.filterTransfers();
                    }
                    if(res.count == 0){
                        this.notificationService.openPopup("No tenemos resultados para tu busqueda.");
                    }
                }
                this.currentPageNumber = 0;
                
            })
    }

    private buildRequestDto (query: Params): TransferRequestDto{
        const transferRequest = new TransferRequestDto();
        transferRequest.language = query.language;
        transferRequest.fromType = query.fromType;
        transferRequest.fromCode = query.fromCode;
        transferRequest.toType = query.toType; 
        transferRequest.toCode = query.toCode; 
        transferRequest.arrDateTime = `${query.arrDateTime}T${moment(query.timeDeparture, "HH:mm").format("HH:mm:ss")}`;
        if(query.depDateTime == ""){
            transferRequest.depDateTime = query.depDateTime;
        } 
        if(query.depDateTime != ""){
            transferRequest.depDateTime = `${query.depDateTime}T${moment(query.timeReturn, "HH:mm").format("HH:mm:ss")}`;
        }
        transferRequest.totalAdults = query.totalAdults; 
        transferRequest.totalChildren = query.totalChildren;
        transferRequest.totalBabies = query.totalBabies;
        transferRequest.fromName = query.fromName;
        transferRequest.toName = query.toName;
        transferRequest.typeTransfer = query.typeTransfer;
        transferRequest.timeDeparture = query.timeDeparture;
        transferRequest.timeReturn = query.timeReturn
        transferRequest.listAges = query.listAges
        transferRequest.agencyId = _agency.agency.id

        if(transferRequest.totalChildren > 0 || transferRequest.totalBabies > 0){
            transferRequest.dataAge = `[${query.dataAge}]`
        }
        return transferRequest;
    }

    toggleSearcher(nameElement: string) {
        switch (nameElement) {
            case 'search':
                this.searchShowHide = !this.searchShowHide;
                this.filtersShowHide = false;
                break;
            case 'filters':
                this.filtersShowHide = !this.filtersShowHide;
                this.searchShowHide = false;
                break;
        }
    }

    selectKindOfTransport(kindOfTransport: string): void {
        this.currentKindOfTransport = kindOfTransport;
    }

    private isValidQueryString(query: Params): boolean {
        this.parametresForTransfers.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                return false;
            }
        });
        return true;
    }

    filterTransfers(): void {
        if(this.items) {
            this.totalTransfers = this.items.length;
            this.rows = this.paginate(this.currentPageNumber, this.currentPageSize);
        }
        this.integrationService.setMetaData({...this.integrationMetaData, searchCount: this.totalTransfers});
    }

    pageChanged($event, container): void {
        container.el.scrollIntoView({block: "start", behavior: "smooth"});

        this.setPagination($event.pageIndex, $event.pageSize);

        this.rows = this.paginate($event.pageIndex, $event.pageSize);
    }
    setPagination(pageNumber: number, pageSize: number) {
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
    }
    paginate(index, size) {
        index = Math.abs(parseInt(index, 10));
        size = parseInt(size, 10);
        size = size < 1 ? 1 : size;

        return [
            ...this.items.filter((__, n) => {
                return n >= index * size && n < (index + 1) * size;
            })
        ];
    }
}
