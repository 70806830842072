import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { Itinerary2 } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-train-itinerary-summary',
    templateUrl: './train-itinerary-summary.component.html',
    styleUrls: ['./train-itinerary-summary.component.scss']
})
export class TrainItinerarySummaryComponent implements OnInit, OnDestroy {

    @Input() itinerary: Itinerary2;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }
}
