import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { AgencyChangeService } from './agency-change.service';
import { AgencyProviderService, AuthenticationService } from '../lib-shared/services/index';
import { AgencyChain, CommonEntity, IUserInfo } from '../lib-shared/interfaces/index';
import { ROLES } from '../const';

@Component({
    selector: 'lib-agency-change',
    templateUrl: './agency-change.component.html',
    styleUrls: ['./agency-change.component.scss']
})
export class AgencyChangeComponent implements OnInit, OnChanges, OnDestroy {

    @Input() user: IUserInfo;

    @Input() emitGlobal = true;

    ROLES = ROLES;

    agencyForm: FormGroup;

    agencyChainForm: FormControl = new FormControl();

    agencyChains: AgencyChain[];

    agencies: CommonEntity[];

    filteredAgencyChains: Observable<AgencyChain[]>;

    selectedChain: AgencyChain;

    unsubscribe = new Subject();

    @Output() getAgencyChain: EventEmitter<number> = new EventEmitter<number>();
    @Output() getAgency: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private fb: FormBuilder,
        private cdref: ChangeDetectorRef,
        private agencyProvider: AgencyProviderService,
        private authenticationService: AuthenticationService,
        private agencyChangeService: AgencyChangeService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.setInitialAgencyChain();
        this.agencyForm.get('agency_chain').setValue(this.getInitialAgencyChain());
        // this.getAgencyChains();
        this.agencyChainForm.valueChanges.pipe(debounceTime(1000), map(value => this._filter(value))).subscribe();
    }

    async setInitialAgencyChain(): Promise<void> {
        if (this.user && this.user.agency_chain && this.user.role.id !== this.ROLES.SUPER_USER) {
            const agencyChain = await this.agencyProvider.getChain(this.user.agency_chain.id).toPromise();
            // this.agencyChains = agencyChains;
            this.selectedChain = agencyChain;
            this.agencyChainForm.setValue(this.selectedChain);
            this.filteredAgencyChains = of([this.selectedChain]);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes.user && changes.user.currentValue) {
        //     this.getAgencyChains();
        // }
    }

    createForm(): void {
        this.agencyForm = this.fb.group({
            agency_chain: [this.getInitialAgencyChain()],
            agency: [this.getInitialAgency()]
        });

        this.agencyForm.get('agency_chain')
            .valueChanges
            .pipe(takeUntil(this.unsubscribe), startWith(this.agencyForm.get('agency_chain').value))
            .subscribe(value => {
                this.emitGlobal ? this.agencyChangeService.setAgencyChain(value) : this.getAgencyChain.emit(value);
                if (value) {
                    if (this.selectedChain) {
                        // const chain = this.agencyChains.find(d => d.id === value);
                        this.agencies = this.selectedChain.agencies;
                    }
                }
            });
        this.agencyForm.get('agency').valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => this.emitGlobal ? this.agencyChangeService.setAgency(value) : this.getAgency.emit(value));
    }

    async getAgencyChains(): Promise<void> {
        this.agencyChains = await this.agencyProvider.getChains().toPromise();
        this.agencyChangeService.setAgencyChainsList(this.agencyChains);
        this.agencyForm.get('agency_chain').setValue(this.user && this.user.agency_chain ? this.user.agency_chain.id : null);
    }

    _filter(search: any) {
        if (typeof search === 'object') {
            this.selectedChain = search;
            this.agencyForm.get('agency_chain').setValue(search.id);
        } else {
            this.selectedChain = null;
            this.filteredAgencyChains = this.agencyProvider.searchChains(search);
        }
    }

    displayFn(chain: AgencyChain): string {
        return chain ? (typeof chain === 'object' ? chain.name : '') : '';
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getInitialAgencyChain(): number {
        if (this.user && this.user.agency_chain && this.user.role && this.user.role.id !== this.ROLES.SUPER_USER) {
            return this.user.agency_chain.id;
        }
        return null;
    }

    private getInitialAgency(): number {
        if (this.user && this.user.agency && this.user.role && this.user.role.id !== this.ROLES.SUPER_USER) {
            return this.user.agency.id;
        }
        return null;
    }

}
