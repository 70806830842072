import { Pipe, PipeTransform } from "@angular/core";
import { t } from 'typy';

@Pipe({
    name: 'includes'
})
export class IncludesPipe implements PipeTransform {

    transform(array: any[], field: string, value: string, childIsArray = false): boolean {
        if (!array || !value) { return; }
        if (childIsArray) {
            return array.some(item => {
                return t(item).safeArray.includes(value);
            });
        }
        return array.some(item => {
            return t(item, field).safeString.toLowerCase() === (value + '').toLowerCase();
        });
    }
}
