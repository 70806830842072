import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from "@angular/core";
import {BookEditComponent} from "../../book-edit.component";
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {filter, map, takeUntil} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ICabinBaggage, IFlightBookingPax, Segment} from "@vecib2c/frontend-dto";
import * as moment_ from 'moment';
import {FlightBookServices} from "../../hotels/services/flight-book.services";
import {HotelBookServices} from '../../hotels/services/hotel-book.services';
import {HotelBookingPostReqDtoOccupantType, ListAirBookBaggage} from '@vecib2c/frontend-dto/dist';
import {TecnoturisService} from '../../../tecnoturis.service';
import {Client, FlightBaggage} from '../../../lib-shared/interfaces/index';
import {
    AuthenticationService,
    BasketManager,
    ClientServices,
    NotificationPopupService
} from '../../../lib-shared/services/index';
import { AgencyChangeService } from '../../../agency-change/index';
import { ShoppingBasketFlight } from '../../../integrations/hotels/index';
import { CustomValidators } from '../../../lib-shared/validators/customValidators';
import {
    FlightAbbreviationType,
    FlightPaxEnum,
    FlightDocumentType,
    FlightPhoneType,
} from '../../../lib-shared/interfaces/index';
import { ROLES } from '../../../const';
import { t } from 'typy';
import { abbreviations, documentTypes, phoneTypes } from "../../../../mock/flights";

const moment = moment_;

@Component({
    selector: 'lib-flight-book-edit',
    templateUrl: './flight-book-edit.component.html',
    styleUrls: ['./flight-book-edit.component.scss'],
    providers: [
        ClientServices,
        FlightBookServices,
        HotelBookServices,
    ]
})

export class FlightBookEditComponent extends BookEditComponent implements OnInit, OnDestroy {

    dspNone: boolean;
    changeIcon: boolean;
    form: FormGroup;
    cancellationPolicies: string[] = [];
    abbreviations: FlightAbbreviationType[] = abbreviations;
    documentTypes: FlightDocumentType[] = documentTypes;
    phoneTypes: FlightPhoneType[] = phoneTypes;
    hotelData;
    confirmedPrivacyPolicy: boolean;
    auth = AuthenticationService.getUser();
    luggagesByBooking: ListAirBookBaggage[] = [];
    luggagesByPax: ListAirBookBaggage[] = [];
    shoppingBasket: ShoppingBasketFlight;
    roles = ROLES;
    cabinLuggagePrice = 0;
    luggagePrices = 0;
    totalPrice = 0;
    @Input() onlyResumeInfo = false;


    get adultsFormArray(): FormArray {
        return this.form.controls['adults'] as FormArray;
    }
    get childrenFormArray(): FormArray {
        return this.form.controls['children'] as FormArray;
    }
    get infantsFormArray(): FormArray {
        return this.form.controls['infants'] as FormArray;
    }
    get cabinBaggageFormGroup(): FormGroup {
        return this.form.controls['cabinBaggage'] as FormGroup;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        public formBuilder: FormBuilder,
        protected location: Location,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected notification: NotificationPopupService,
        public router: Router,
        protected _hotelBookService: HotelBookServices,
        protected _basketManager: BasketManager,
        private _flightBookService: FlightBookServices,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
    }

    ngOnInit(): void {
        this.dspNone = true;
        this.translate.setDefaultLang('es');

        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.activatedRoute.paramMap
            .pipe(
                map(() => window.history.state),
                takeUntil(this._unsubscribe)
            )
            .subscribe(async(state) => {

                if (state.flight) {
                    this.shoppingBasket = ShoppingBasketFlight.initialize(state.flight);
                    this.totalPrice = this.shoppingBasket.price;
                    this.cabinLuggagePrice = this.shoppingBasket.cabinLuggagePrice;
                    this.luggagePrices = this.shoppingBasket.luggagePrices;
                    this.initializeLuggages();
                    this.initializeForm();
                    this.initializeClient();
                    this.loadCancellationPolicies();

                    if (state.hotel) {
                        this._hotelBookService.hotel = state.hotel;
                        this.createHotelForm();
                    }

                } else {
                    const uuid = this.activatedRoute.snapshot.params['id'];

                    const itemsFromStorage = await this._basketManager.getList();
                    this.shoppingBasket = itemsFromStorage.find(item => item.uuid === uuid);

                    if (this.shoppingBasket) {
                        this.totalPrice = this.shoppingBasket.price;
                        this.cabinLuggagePrice = this.shoppingBasket.cabinLuggagePrice;
                        this.luggagePrices = this.shoppingBasket.luggagePrices;
                        this.initializeLuggages();
                        this.initializeForm();
                        this.initializeClient();
                        this.loadCancellationPolicies();
                    }

                }

            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    async addToBasketAndRedirect() {
        const adults = this.adultsFormArray.value as IFlightBookingPax[];
        const children = this.childrenFormArray.value as IFlightBookingPax[];
        const infants = this.infantsFormArray.value as IFlightBookingPax[];
        const cabinBaggage = this.cabinBaggageFormGroup.value as ICabinBaggage;

        this.completeShoppingBasket(
            adults,
            children,
            infants,
            cabinBaggage,
            this.cancellationPolicies,
            this.cabinLuggagePrice,
            this.luggagePrices,
            this.totalPrice,
        );
        this.buildOccupantsForHotel(adults, children);

        if (super.checkClient()) {
            await this._basketManager.addOrUpdate(this.shoppingBasket).then(() => {
                if (this.hotelData) {
                    this._hotelBookService.addToBasketAndRedirect(true, this.microsite);
                } else {
                    const redirectToPath = this.redirectToPath();
                    return this.router.navigateByUrl(redirectToPath, {replaceUrl: true});
                }
            });
        }
    }

    /**
     *
     * @TODO same as addToBasketAndRedirect, but redirect to buying if there is one item on basket
     * @memberof FlightBookEditComponent
     */
    goToSummaryOrConfirmation() {
        if (super.checkClient()) {
            const adults = this.adultsFormArray.value as IFlightBookingPax[];
            const children = this.childrenFormArray.value as IFlightBookingPax[];
            const infants = this.infantsFormArray.value as IFlightBookingPax[];
            const cabinBaggage = this.cabinBaggageFormGroup.value as ICabinBaggage;
            this.completeShoppingBasket(
                adults,
                children,
                infants,
                cabinBaggage,
                this.cancellationPolicies,
                this.cabinLuggagePrice,
                this.luggagePrices,
                this.totalPrice,
            );
            this.buildOccupantsForHotel(adults, children);
            this._basketManager.addOrUpdate(this.shoppingBasket).then(() => {
                if (this.hotelData) {
                    this._hotelBookService.addToBasketAndRedirect(false, this.microsite, null)
                        .then(() => {
                            this._flightBookService.goToSummaryOrConfirmation(this.shoppingBasket, true, this.microsite);
                        });
                } else {
                    this._flightBookService.goToSummaryOrConfirmation(this.shoppingBasket, true, this.microsite);
                }
            });
        }
    }

    toggleSegment() {
        this.dspNone = !this.dspNone;
        this.changeIcon = !this.changeIcon;
    }

    selectCabinLuggage(luggage: FlightBaggage): void {
        this.form.get('cabinBaggage').setValue({
            numberOption: luggage ? luggage.numberOption : null,
            luggageOption: luggage ? luggage.luggageOption : null,
            description: luggage ? luggage.description : null,
            luggageId: luggage ? luggage.id : null,
        });

        if (luggage) {
            this.cabinLuggagePrice = luggage.priceWithCommission;
        } else {
            this.cabinLuggagePrice = 0;
        }

        this.totalPrice = this.calculateTotalPrice(
            this.shoppingBasket.clonedData.total,
            this.cabinLuggagePrice,
            this.luggagePrices,
        );
    }

    getCategory() {
        this._hotelBookService.getCategory();
    }

    selectLuggages(luggages: FlightBaggage[]): void {
        this.luggagePrices = luggages.reduce((acc, cur) => {
            const price = cur ? cur.priceWithCommission : 0;
            return acc + price;
        }, 0);
        this.totalPrice = this.calculateTotalPrice(
            this.shoppingBasket.clonedData.total,
            this.cabinLuggagePrice,
            this.luggagePrices,
        );
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            adults: this.formBuilder.array([]),
            children: this.formBuilder.array([]),
            infants: this.formBuilder.array([]),
            cabinBaggage: this.formBuilder.group({
                numberOption: [t(this.shoppingBasket, 'cabinBaggage.numberOption').safeObject],
                luggageOption: [t(this.shoppingBasket, 'cabinBaggage.luggageOption').safeObject],
                description: [t(this.shoppingBasket, 'cabinBaggage.description').safeObject],
                luggageId: [t(this.shoppingBasket, 'cabinBaggage.luggageId').safeObject],
            }),
        });

        let adults = [];
        let children = [];
        let infants = [];
        if (this.shoppingBasket.paxes) {
            adults = this.shoppingBasket.paxes.filter(pax => pax.typePax === 'Adult') as IFlightBookingPax[];
            children = this.shoppingBasket.paxes.filter(pax => pax.typePax === 'Child') as IFlightBookingPax[];
            infants = this.shoppingBasket.paxes.filter(pax => pax.typePax === 'Infant') as IFlightBookingPax[];
        }

        for (let i = 0; i < this.shoppingBasket.distribution.adults; i++) {
            this.initializeFormByPaxType('adults', adults[i]);
        }

        for (let i = 0; i < this.shoppingBasket.distribution.children; i++) {
            this.initializeFormByPaxType('children', children[i]);
        }

        for (let i = 0; i < this.shoppingBasket.distribution.infants; i++) {
            this.initializeFormByPaxType('infants', infants[i]);
        }
    }

    private initializeClient(): void {
        this.clientService.subscription
        .pipe(
            filter(client => client && !(Object.keys(client).length === 0) && client.isPax),
            takeUntil(this._unsubscribe),
        ).subscribe((client: Client) => {
            this.adultsFormArray.at(0).get('name').setValue(client.name);
            this.adultsFormArray.at(0).get('lastName').setValue(client.surname);
            this.adultsFormArray.at(0).get('email').setValue(client.email);
            this.adultsFormArray.at(0).get('documentNumber').setValue(client.document);
            this.adultsFormArray.at(0).get('nationality').setValue(client.nationality);
            this.adultsFormArray.at(0).get('phone').setValue(client.phoneNumber);
        });
    }

    private initializeFormByPaxType(arrayName: string, flightBookingPax: IFlightBookingPax): void {
        const maxBirthdate = arrayName === 'adults' ? moment().subtract(18, 'year').toDate() : moment().toDate();
        const minExpirationDate = moment().add(1, 'years').toDate();

        (this.form.controls[arrayName] as FormArray).push(this.formBuilder.group({
            abbreviation: [t(flightBookingPax, 'abbreviation').safeString, Validators.required],
            name: [t(flightBookingPax, 'name').safeString, Validators.required],
            lastName: [t(flightBookingPax, 'lastName').safeString, Validators.required],
            phone: [t(flightBookingPax, 'phone').safeString, Validators.required],
            email: [t(flightBookingPax, 'email').safeString],
            typeDocument: [t(flightBookingPax, 'typeDocument').safeString || 'PAS', Validators.required],
            documentNumber: [t(flightBookingPax, 'documentNumber').safeString, Validators.required],
            birthdate: [t(flightBookingPax, 'birthdate').safeString, [
                Validators.required,
                CustomValidators.maxDate(maxBirthdate)
            ]
            ],
            expirationDate: [t(flightBookingPax, 'expirationDate').safeString, [
                Validators.required,
                CustomValidators.minDate(minExpirationDate)
            ]
            ],
            nationality: [t(flightBookingPax, 'nationality').safeString || 'ES', Validators.required],
            internationalCode: [t(flightBookingPax, 'internationalCode').safeObject || 34, Validators.required],
            residentCityCode: [t(flightBookingPax, 'residentCityCode').safeString],
            luggageNumberOption: [t(flightBookingPax, 'luggageNumberOption').safeObject],
            luggageOption: [t(flightBookingPax, 'luggageOption').safeObject],
            luggageId: [t(flightBookingPax, 'luggageId').safeObject],
            typePax: [FlightPaxEnum[arrayName]],
            phoneType: [t(flightBookingPax, 'phoneType').safeString, Validators.required]
        }));
    }

    private loadCancellationPolicies(): void {
        if (!this.shoppingBasket.clonedData.fareNotes) {
            this.cancellationPolicies.push('No se puede cancelar el vuelo');
            return;
        }
        this.shoppingBasket.clonedData.fareNotes.forEach(e => {
            if (e.code === "LTD") {
                const description = e.description.split(' ');
                const str = description[2].split('');
                const datePolicy = `Fecha limite para cancelar: ${str[3]}${str[4]} de ${str[5]}${str[6]}${str[7]} del 20${str[8]}${str[9]}`;
                this.cancellationPolicies.unshift(datePolicy);
            } else {
                this.cancellationPolicies.push(e.description);
            }

        });
    }

    private initializeLuggages(): void {
        if (this.shoppingBasket.lowCostPreBooking && this.shoppingBasket.lowCostPreBooking.listAirBookBaggages) {
            this.shoppingBasket.lowCostPreBooking.listAirBookBaggages.forEach(baggage => {
                if (baggage.byPax) {
                    this.luggagesByPax.push(baggage);
                } else {
                    this.luggagesByBooking.push(baggage);
                }
            });
        }

    }
    private completeShoppingBasket(
        adults: IFlightBookingPax[],
        children: IFlightBookingPax[],
        infants: IFlightBookingPax[],
        cabinBaggage: ICabinBaggage,
        cancellationPolicies: string[],
        cabinLuggagePrice: number,
        luggagePrices: number,
        totalPrice: number,
    ): void {
        this.shoppingBasket.paxes = [
            ...adults,
            ...children,
            ...infants
        ];
        this.shoppingBasket.cancelPolicies = cancellationPolicies;
        this.shoppingBasket.cabinLuggagePrice = cabinLuggagePrice || 0;
        this.shoppingBasket.luggagePrices = luggagePrices || 0;
        this.shoppingBasket.price = totalPrice;

        if (cabinBaggage && cabinBaggage.luggageOption && cabinBaggage.numberOption) {
            this.shoppingBasket.cabinBaggage = cabinBaggage;
        } else {
            this.shoppingBasket.cabinBaggage = undefined;
        }
    }

    private buildOccupantsForHotel(adults: IFlightBookingPax[], children: IFlightBookingPax[]) {
        if (this.hotelData) {
            const getAgeFromDate = (date) => {
                const age = moment().diff(moment(date, 'DD/MM/YYYY'), 'years');
                return age;
            };
            (this._hotelBookService.occupants.controls['hotelData'] as FormArray)
                .controls['roomList'].controls[0].controls.adultsOccupants
                .setValue(adults.map(a => {
                    return {
                        age: getAgeFromDate(a.birthdate),
                        name: a.name,
                        surname: a.lastName,
                        type: HotelBookingPostReqDtoOccupantType.Adult
                    };
                }));
            (this._hotelBookService.occupants.controls['hotelData'] as FormArray)
                .controls['roomList'].controls[0].controls.childrensOccupants
                .setValue(children.map(c => {
                    return {
                        age: getAgeFromDate(c.birthdate),
                        name: c.name,
                        surname: c.lastName,
                        type: HotelBookingPostReqDtoOccupantType.Child
                    };
                }));
        }
    }

    private createHotelForm(): void {
        this.hotelData = this._hotelBookService.hotel;
        this._hotelBookService.setDistribution();
        const hotelForm = this._hotelBookService.initOccupantsForm();
    }

    private calculateTotalPrice(partialPrice: number, luggagePrices = 0, cabinLuggagePrice = 0): number {
        return partialPrice + luggagePrices + cabinLuggagePrice;
    }
}
