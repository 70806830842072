import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getDecimalPart'
})
export class GetDecimalPartPipe implements PipeTransform {

    transform(price: any, flightPrice?: any, safePrice?: any): any {
        price = Number(price);

        if (flightPrice) {
            price += Number(flightPrice);
        }

        if (safePrice) {
            price += Number(safePrice);
        }

        const stringGetPrice = price.toFixed(2).toString();
        if (stringGetPrice.indexOf('.') > -1) {
            return stringGetPrice.substring(
                stringGetPrice.indexOf('.') + 1,
                stringGetPrice.length
            );
        } else {
            return '.00';
        }
    }

}
