import { Component, Input } from "@angular/core";
import { ShoppingBasketMultidestination } from "../../../lib-shared/interfaces/shopping-basket-multidestination.model";

@Component({
  selector: 'lib-multidestination-book-summary',
  templateUrl: './multidestination-book-summary.component.html',
  styleUrls: ['./multidestination-book-summary.component.scss'],
})

export class MultidestinationBookSummaryComponent {

  @Input() shoppingBasketMultidestination: ShoppingBasketMultidestination;

  constructor() {
  }

}
