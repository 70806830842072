import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HotelCancellationPolicies, HotelRoom, HotelRoomPutReqDto } from '@vecib2c/frontend-dto';
import { TranslateService } from '@ngx-translate/core';
import { MdePopover, MdePopoverTrigger } from '@material-extended/mde';
import { CancellationPoliciesService, SpinnerLoaderService } from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-cancellation-policies-button',
    templateUrl: './cancellation-policies-button.component.html',
    styleUrls: ['./cancellation-policies-button.component.scss']
})
export class CancellationPoliciesButtonComponent implements OnChanges {

    @Input() hotelId: any; // string or number it depends
    @Input() query;
    @Input() room: HotelRoom;
    @Input() provider: string;
    @ViewChild(MdePopoverTrigger, { static: false }) mdePopoverTrigger: MdePopoverTrigger;
    @ViewChild(MdePopover, { static: false }) mdePopover: MdePopover;

    rateKey: string;
    hotelRoomPutReqDto = new HotelRoomPutReqDto();
    gotCancellationPolicies = false;
    cancellationPolicies: HotelCancellationPolicies[];
    leave = false;

    constructor(
        private cancellationPolicies$: CancellationPoliciesService,
        public translate: TranslateService,
        public spinnerLoader$: SpinnerLoaderService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.room) {
            this.rateKey = this.room.rates[0].rateId;
            if (this.room.rates[0].cancellationPolicies && this.room.rates[0].cancellationPolicies.length > 0) {
                this.gotCancellationPolicies = true;
                this.cancellationPolicies = this.room.rates[0].cancellationPolicies;
            }
        }
    }

    setText() {
        this.leave = false;
        if (!this.cancellationPolicies || this.cancellationPolicies.length < 1) {
            this.setHotelRoomPutReqDto();
            this.spinnerLoader$.disableSpinner(); // dont show the spinner effect for that request
            this.cancellationPolicies$
                .get(this.rateKey, this.hotelRoomPutReqDto)
                .subscribe(
                    data => {
                        this.cancellationPolicies = data.cancellationPolicies;
                    },
                    err => {
                        console.log(`Error: ${err}`);
                    },
                    () => { // complete callback
                        this.checkPopoverOpening();
                        this.spinnerLoader$.enableSpinner(); // rehabilitate the spinner effect after complete the request
                    }
                );
        } else {
            this.checkPopoverOpening();
        }
    }

    checkPopoverOpening(): void {
        this.leave ? this.mdePopoverTrigger.closePopover() : this.mdePopoverTrigger.openPopover();
    }

    setHotelRoomPutReqDto() {
        this.hotelRoomPutReqDto.checkIn = this.query.checkIn;
        this.hotelRoomPutReqDto.checkOut = this.query.checkOut;
        this.hotelRoomPutReqDto.currency = '€';
        this.hotelRoomPutReqDto.language = 'ES'; /* TODO: not provided */
        this.hotelRoomPutReqDto.hotelId = this.hotelId.toString();
        this.hotelRoomPutReqDto.cancellationPolicies = this.room.rates[0].cancellationPolicies;
        this.hotelRoomPutReqDto.providerName = this.provider;
    }

    mouseLeave(): void {
        this.leave = true;
        this.mdePopoverTrigger.closePopover();
    }

    mouseEnter(): void {
        this.leave = false;
    }

}
