export class FlightAbbreviationType {
    id: string;
    name: string;
}

export class FlightDocumentType {
    id: string;
    name: string;
}

export class FlightPhoneType {
    id: string;
    name: string;
}
