import { NgModule } from "@angular/core"
import { NoPreloading, RouterModule, Routes } from "@angular/router"
import { AgencyHostGuard } from "./shared/guards/agency-host.guard"
import { SetupResolver } from "../../projects/tecnoturis/src/public-api"
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component"

const routes: Routes = [
  {
    path: "",
    canActivate: [AgencyHostGuard],
    children: [
      {
        path: "packages",
        loadChildren: () =>
          import("./pages/packages/packages.module").then(
            (m) => m.PackagesPageModule
          ),
      },
      {
        path: "register",
        loadChildren: () =>
          import("./pages/register/register.module").then(
            (m) => m.RegisterPageModule
          ),
      },
      {
        path: "",
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: NoPreloading,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
