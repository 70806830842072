import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Subject } from "rxjs";
import { IDynamicCrud, IProvider } from '../../lib-shared/interfaces/index';
import { bookingFormMetaData } from './booking.form';
import { BaseAddEdit } from '../../base-add-edit';
import { AgencyProviderService, AuthenticationService, DynamicCrudService, ManagementService } from '../../lib-shared/services/index';
import { DynamicCrudUtils } from '../../utils';
import { TecnoturisService } from '../../tecnoturis.service';
import * as moment_ from 'moment';

const moment = moment_;

@Component({
    selector: 'lib-hotel-booking',
    templateUrl: './hotel-booking.component.html',
    styleUrls: ['./hotel-booking.component.scss']
})
export class HotelBookingComponent extends BaseAddEdit {
    dynamicCrud: IDynamicCrud = bookingFormMetaData;

    auth = AuthenticationService.getUser();

    providers: IProvider[];

    fees: any[];

    agency_provider: any;

    booking: any;

    calculatedMargin$ = new Subject();
    fiscalUbicationChanges$ = new Subject();

    optionsValue = {
        onlySelf: true,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: false
    };

    constructor(route: ActivatedRoute,
                router: Router,
                dynamicCrudService: DynamicCrudService,
                managementService: ManagementService,
                public location: Location,
                private agencyProviderService: AgencyProviderService,
                private tecnoturisService: TecnoturisService) {
        super(route, router, dynamicCrudService, managementService, location);
        if (this.tecnoturisService.config.forms.hotelForm) {
            this.dynamicCrud = this.tecnoturisService.config.forms.hotelForm;
        }
        this.formCreated
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async (value: any) => {
                await this.getProviders();
                this.providerChanges();

                this.form.get('valued_bond')
                    .valueChanges
                    .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('valued_bond').value))
                    .subscribe(value1 => {
                        if (value1) {
                            this.form.get('price_bond').enable();
                        } else {
                            this.form.get('price_bond').disable();
                            this.form.get('price_bond').setValue(null);
                        }
                    });

                this.form.get('check_in')
                    .valueChanges
                    .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('check_in').value))
                    .subscribe(checkIn => {
                        const checkOut = this.form.get('check_out').value;
                        const mappedCheckIn = moment(checkIn, 'YYYY-MM-DD');
                        const mappedCheckOut = moment(checkOut, 'YYYY-MM-DD');
                        if (mappedCheckIn.isAfter(mappedCheckOut)) {
                            this.form.get('check_out').setValue(checkIn);
                        }
                        DynamicCrudUtils.setDinamycallyMin(this.formlyFields, 'check_out', checkIn);
                    });
            });
        this.comissionChanges();

        this.getObjectChange.subscribe(response => this.booking = response);

        this.calculatedMargin$.pipe(
            debounceTime(500),
            takeUntil(this.unsubscribe),
        ).subscribe(key => {
            this.calculateMargin(key);
        });


        this.fiscalUbicationChanges$.pipe(
            debounceTime(100),
            takeUntil(this.unsubscribe),
        ).subscribe(async res => {
            return await this.fiscalUbicationChanges();
        });
    }

    providerChanges(): void {
        DynamicCrudUtils.setDinamycallyOptions(this.formlyFields, 'provider', this.providers);
        this.form.get('provider')
            .valueChanges
            .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('provider').value))
            .subscribe(value => {
                const provider = this.providers.find(p => p.id === +value);
                if (provider) {
                    this.getAgencyProvider(provider.id);
                    this.form.get('provider_accountant_account').setValue(provider.accountant_account);
                }
            });

        this.form.get('product_type')
            .valueChanges
            .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('product_type').value))
            .subscribe(value => {
                if (this.fees) {
                    const fee = this.fees.find(f => f.id === +value);
                    if (fee) {
                        this.form.get('fee_apply').setValue(fee.fee_apply);
                        this.form.get('comission').setValue(fee.comission);
                        this.form.get('iva').setValue(fee.iva);
                    }
                }
            });

        const keysFiscalLocation = ['fiscal_location', 'product_type', 'fee_apply', 'provider'];
        keysFiscalLocation.forEach(k => {
            this.form.get(k)
                .valueChanges
                .pipe(
                    takeUntil(this.unsubscribe),
                    startWith(this.form.get(k).value),
                ).subscribe(value => {
                this.fiscalUbicationChanges$.next();
            });
        });

        const keysCalculatedPrices = ['total_net', 'comission', 'iva', 'pvp_fee', 'not_fee_amount', 'discount', 'fee'];
        keysCalculatedPrices.forEach(k => {
            this.form.get(k)
                .valueChanges
                .pipe(
                    takeUntil(this.unsubscribe),
                    startWith(this.form.get(k).value))
                .subscribe(value => {
                    this.calculatedMargin$.next(k);
                });
        });
    }

    comissionChanges(): void {
        let countChangesComissions = 0;
        const keysChangeNeto = ['comission', 'iva', 'fee', 'discount', 'pvp_fee', 'not_fee_amount', 'total_net'];
        this.formCreated.subscribe(_ => {
            this.changeFormlyFieldsProperties('total_pvp', {readonly: true});
            this.form.get('fee_apply')
                .valueChanges
                .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('fee_apply').value))
                .subscribe(value => {
                    // NETO
                    if (value === 1) {
                        this.changeFormlyFieldsProperties('pvp_fee', {readonly: true});
                        this.changeFormlyFieldsProperties('not_fee_amount', {readonly: true});
                        this.changeFormlyFieldsProperties('total_pvp', {readonly: false});
                        // this.changeFormlyFieldsProperties('total_net', {readonly: false});
                    } else if (value === 2) {
                        this.changeFormlyFieldsProperties('pvp_fee', {readonly: false});
                        this.changeFormlyFieldsProperties('not_fee_amount', {readonly: false});
                        this.changeFormlyFieldsProperties('total_pvp', {readonly: true});
                        // this.changeFormlyFieldsProperties('total_net', {readonly: true});
                    }
                    countChangesComissions++;
                });
        });
    }

    async getProviders(): Promise<void> {
        this.providers = await this.agencyProviderService.getProviders(this.auth.agency.id, false, true).toPromise();
    }

    async getAgencyProvider(provider: number): Promise<void> {
        this.agency_provider = await this.agencyProviderService.getAgencyProvider(this.auth.agency.id, provider).toPromise();
        this.getFees();
    }

    async getFees(): Promise<void> {
        this.fees = await this.agencyProviderService.getFees(this.agency_provider.id).toPromise();
        DynamicCrudUtils.setDinamycallyOptions(this.formlyFields, 'product_type', this.fees.map(f => {
            return {id: +f.id, name: f.product};
        }));
        this.form.get('product_type').setValue(this.form.get('product_type').value);
    }

    private async fiscalUbicationChanges(): Promise<void> {
        const formValues = this.form.getRawValue();
        const serviceId = this.booking.service.id;
        if (formValues['fee_apply'] && formValues['fiscal_location'] && formValues['provider'] && serviceId) {
            const request = {
                fee_apply_id: formValues['fee_apply'],
                fiscal_location_id: formValues['fiscal_location'],
                provider_id: formValues['provider'],
                service_id: serviceId,
            };
            const taxRegimeIvaApply = await this.managementService.getTaxRegimeIvaApply(request).toPromise();

            if (taxRegimeIvaApply && taxRegimeIvaApply.iva_commission) {
                this.form.get('iva').setValue(taxRegimeIvaApply.iva_commission);
                this.calculatedMargin$.next();
            }
        }
    }

    private calculateMargin(key?: any) {
        const formValues = this.form.getRawValue();

        if (formValues['fee_apply']) {

            const iva = formValues['iva'] ? formValues['iva'] : 0;
            const discounts = -(formValues['discount'] ? formValues['discount'] : 0);
            const fees = +(formValues['fee'] ? formValues['fee'] : 0);

            let comission = formValues['comission'] ? formValues['comission'] : 0;
            let total_net = formValues['total_net'] ? formValues['total_net'] : 0;
            let total_pvp = formValues['total_pvp'] ? formValues['total_pvp'] : 0;
            let profit = formValues['profit'] ? formValues['profit'] : 0;
            let profit_gross = formValues['profit_gross'] ? formValues['profit_gross'] : 0;

            let pvp_fee = formValues['pvp_fee'] ? formValues['pvp_fee'] : 0;
            const not_fee_amount = formValues['not_fee_amount'] ? formValues['not_fee_amount'] : 0;


            if (formValues['fee_apply'] === 1 || formValues['fee_apply'] === 3) {
                // if (total_pvp > 0) {
                //     total_net = total_pvp - profit_gross;
                // }


                profit = (total_net * comission) / 100;
                profit_gross = profit * (1 + iva / 100);
                total_pvp = total_net + profit_gross;


            } else if (formValues['fee_apply'] === 2) {
                if (key !== 'total_net') {
                    total_pvp = (pvp_fee + not_fee_amount);
                    if (comission === 0) {
                        profit = total_pvp - pvp_fee;
                    } else {
                        profit = (pvp_fee * comission) / 100;
                    }
                    profit_gross = profit * (1 + iva / 100);
                    total_net = (total_pvp - profit_gross);
                } else {
                    total_pvp = total_net * (1 + iva / 100);
                    total_pvp += -formValues['fee'] + formValues['discount'];
                    pvp_fee = (total_pvp - formValues['not_fee_amount']);
                    comission = (profit * 100) / pvp_fee;
                    this.form.get('pvp_fee').setValue(+pvp_fee.toFixed(2), this.optionsValue);
                }
            } else {
                console.log('opcion incorrecta...');
            }

            total_pvp += discounts + fees;

            this.form.get('comission').setValue(+comission.toFixed(2), this.optionsValue);
            this.form.get('profit').setValue(+profit.toFixed(2), this.optionsValue);
            this.form.get('profit_gross').setValue(+profit_gross.toFixed(2), this.optionsValue);
            this.form.get('total_pvp').setValue(+total_pvp.toFixed(2), this.optionsValue);
            this.form.get('total_net').setValue(+total_net.toFixed(2), this.optionsValue);
        }
    }
}
