import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import * as moment_ from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const moment = moment_;

@Component({
    selector: 'lib-date-picker-formly',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent extends FieldType implements OnInit {

    date: string;

    ngOnInit(): void {
        if (this.formControl.value) {
            this.date = moment(this.formControl.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
    }

    changeDate(date: string) {
        this.date = date;
        const formattedDate = moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const validatedDate = !moment(formattedDate).isValid() ? null : formattedDate;
        this.formControl.setValue(validatedDate);
    }

    setDateValue(date: MatDatepickerInputEvent<Date>) {
        this.date = moment(date.value).format('DD/MM/YYYY');
        const formattedDate = moment(date.value).format('YYYY-MM-DD');
        this.formControl.setValue(formattedDate);
    }

}
