import { Component, Input, OnInit } from "@angular/core";
import { ShoppingBasketCars } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-cars-book-summary',
    templateUrl: './cars-book-summary.component.html',
    styleUrls: ['./cars-book-summary.component.scss']
})

export class CarsBookSummaryComponent implements OnInit {
    @Input() car: ShoppingBasketCars;

    constructor() {
    }

    ngOnInit(): void {
        this.car.urlImage = this.car.urlImage;
    }

    getForTimePeriod(time): string {
        if (time === "Daily") {
            return "/ Día";
        }
    }
}
