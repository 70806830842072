import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CircuitFlightPack, CircuitGetReqDto, CircuitListReqDto } from '@vecib2c/frontend-dto/dist';
import { map } from 'rxjs/operators';
import { TecnoturisService } from '../../tecnoturis.service';
import { CircuitsFlightReqDto, Circuitv2ListResDto, ICircuit } from '../interfaces/circuits.interface';
import { Utils } from '../functions/index';

@Injectable({
    providedIn: 'root'
})
export class Circuitsv2Service {
    protected host;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
        this.host = this.tecnoturisService.config.circuitsv2;
    }

    list(req: CircuitListReqDto): Observable<Circuitv2ListResDto> {
        return this.http.get<Circuitv2ListResDto>(`${this.host}/circuits/`, {params: Utils.objectToHttpParams(req)});
    }

    get(req: CircuitGetReqDto, idCircuits: string): Observable<ICircuit> {
        const reqClone = {...req, idCircuits};
        return this.http.get<Circuitv2ListResDto>(`${this.host}/circuits/`, {params: Utils.objectToHttpParams(reqClone, false)})
            .pipe(map(circuits => circuits.rows[0]));
    }

    getFlights(req: CircuitsFlightReqDto): Observable<CircuitFlightPack[]> {
        return this.http.get<{ data: CircuitFlightPack[] }>(`${this.host}/flights/`, {params: Utils.objectToHttpParams(req)})
            .pipe(map(data => data.data));
    }

    getDestinations(): Observable<any[]> {
        return this.http.get<{ rows: any[] }>(`${this.host}/destinations/`, {})
            .pipe(map(data => data.rows));
    }
}
