import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportsBookingComponent } from './transports-booking.component';
import { Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';

const routes: Routes = [
    {
        path: 'create',
        component: TransportsBookingComponent,
    },
    {
        path: 'edit/:id',
        component: TransportsBookingComponent,
    }
];

@NgModule({
    declarations: [TransportsBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
        // RouterModule.forChild(routes)
    ],
    exports: [TransportsBookingComponent]
})
export class TransportsBookingModule {
}
