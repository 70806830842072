import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {
    private openShoppingCart: Subject<any> = new Subject();

    constructor() {
    }

    getEventShoppingCart(): Subject<any> {
        return this.openShoppingCart;
    }

    setEventOpenShoppingCart(): void {
        this.openShoppingCart.next(true);
    }
}
