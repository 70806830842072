import { Component, Input, OnInit } from "@angular/core";

/** @ToDo analizar si incluir la directiva en shared */
@Component({
    selector: "lib-scroll-top-button",
    templateUrl: "./scroll-top-button.component.html",
    styleUrls: ["./scroll-top-button.component.scss"]
})
export class ScrollTopButtonComponent implements OnInit {

    @Input() element;
    @Input() styles = {bottom: '100px', right: '170px'};
    availableButton = false;

    constructor() {
    }

    ngOnInit() {
    }

    handleClick() {
        this.element.el.scrollIntoView(true);
    }

    hide() {
        this.availableButton = false;
    }

    show() {
        this.availableButton = true;
    }

}
