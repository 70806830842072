import { ActivityFilterType } from "../lib/lib-shared/interfaces/activities/activity-filter.model";

export const filters: ActivityFilterType = {
  categories: [
      { id: 'cityTours', value: 'Tours por la ciudad' },
      { id: 'amusementParks', value: 'Parques de atracciones' },
      { id: 'artAndCulture', value: 'Arte y Cultura' },
      { id: 'showsAndEvents', value: 'Espectáculos y eventos' },
      { id: 'gastronomy', value: 'Gastronomía' },
      { id: 'toursAndActivities', value: 'Tours y actividades' },
  ],
  durations: [
      { id: 'flexible', value: 'Flexible' },
      { id: 'fullDay', value: 'Dia completo' },
      { id: 'halfDay', value: 'Medio día' },
      { id: 'multiDay', value: 'Multidía'},
      { id: 'night', value: 'Noche' },
  ]
};
