
export class TransferLocationDtoRes extends Response {
    rows: LocationsTransfers[];
}

export class LocationsTransfers {
    code: string;
    destination: string;
    name: string;
    type: string;
}
