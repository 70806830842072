import { NgModule } from '@angular/core';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatOptionModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { DefaultPackageSearchComponent } from './default-package-search.component';
import { DateRangeModule } from '../../../date-range/index';
import { PaxesModule } from '../../../paxes/index';
import { PaxesPackageModule } from '../paxes-package/paxes-package.module';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [DefaultPackageSearchComponent],
    imports: [
        LibSharedModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        MatOptionModule,
        DateRangeModule,
        PaxesModule,
        ReactiveFormsModule,
        PaxesPackageModule
    ],
    exports: [DefaultPackageSearchComponent]
})
export class DefaultPackageSearchModule {
}
