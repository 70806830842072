import { FlightRateUpdateResDto, HotelRoomRate, IOperationDate, IRateDetail } from "@vecib2c/frontend-dto";
import { roundNumber } from "../functions/index";
import { ExpandableCoverage, PolicyCheckedRateRes } from "../interfaces/insurance/checked-rate.model";
import * as moment_ from 'moment';
import { TrainCheckedRateResponse } from "./trains/response/checked-rate.response.dto";

const moment = moment_;

export type RawResponse =
    IRateDetail &
    PolicyCheckedRateRes &
    TrainCheckedRateResponse &
    FlightRateUpdateResDto &
    HotelRoomRate;

export class Rerate {
    pvp: number;
    commissionableRate: number;
    nonCommissionableRate: number;
    addonsPrice: number;
    cancellationPolicies: RateCancellationPolicy[];
    rateKey: string;
    canBeBooked: boolean;
    rawResponse?: RawResponse;

    static buildFromActivity(rateDetail: IRateDetail): Rerate {
        const obj = {
            pvp: roundNumber(rateDetail.totalAmount.commission.pvp),
            commissionableRate: roundNumber(rateDetail.totalAmount.amount),
            nonCommissionableRate: 0,
            addonsPrice: 0,
            cancellationPolicies: this.buildCancellationPolicyFromActivity(rateDetail.operationDates),
            rateKey: rateDetail.rateKey,
            canBeBooked: true,
        };
        return obj;
    }

    static buildFromInsurance(policyCheckedRes: PolicyCheckedRateRes): Rerate {
        const obj = {
            pvp: roundNumber(policyCheckedRes.commission.pvp),
            commissionableRate: roundNumber(policyCheckedRes.detailedPricing.commissionableRate),
            nonCommissionableRate: roundNumber(policyCheckedRes.detailedPricing.nonCommissionableRate),
            addonsPrice: this.calculateAddonPriceFromInsurance(policyCheckedRes.expandableCoverages),
            cancellationPolicies: [],
            rateKey: null,
            canBeBooked: true,
        };
        return obj;
    }

    static buildFromTrain(trainCheckedRes: TrainCheckedRateResponse): Rerate {
        const { commission, netPrice, providerToken } = trainCheckedRes;
        return {
            pvp: roundNumber(commission.pvp),
            commissionableRate: netPrice,
            nonCommissionableRate: 0,
            addonsPrice: 0,
            cancellationPolicies: [],
            rateKey: providerToken,
            canBeBooked: true,
        };
    }
    static buildFromFlight(flightRateRes: FlightRateUpdateResDto): Rerate {
        const {
            commission,
            requestId,
            detailedPricing: { commissionableRate, nonCommissionableRate },
        } = flightRateRes;
        return {
            pvp: roundNumber(commission.pvp),
            commissionableRate,
            nonCommissionableRate,
            addonsPrice: 0,
            cancellationPolicies: [],
            rateKey: requestId + '',
            canBeBooked: true,
            rawResponse: flightRateRes as RawResponse,
        };
    }

    static buildFromHotel(hotelRateRes: HotelRoomRate): Rerate {
        const {
            price,
            rateId,
            cancellationPolicies,
            detailedPricing: {
                commissionableRate,
                nonCommissionableRate,
            },
            status,
        } = hotelRateRes;
        return {
            pvp: roundNumber(price),
            commissionableRate,
            nonCommissionableRate,
            addonsPrice: 0,
            cancellationPolicies: cancellationPolicies,
            rateKey: rateId,
            canBeBooked: !status || status === 'BOOKABLE' || status === 'SALE',
            rawResponse: hotelRateRes as RawResponse,
        };
    }

    private static buildCancellationPolicyFromActivity(
        operationDates: IOperationDate[] = [],
    ): RateCancellationPolicy[] {
        const arr = operationDates.reduce((acc, operationDate) => {
            const cancellationPolicies: RateCancellationPolicy[] = operationDate.cancellationPolicies.map(cancellationPolicy => {
                return {
                    startDate: moment(cancellationPolicy.dateFrom).format('YYYY-MM-DD HH:mm'),
                    amount: cancellationPolicy.amount,
                };
            });
            return [...acc,
                ...cancellationPolicies,
            ];
        }, []);
        return arr;
    }

    private static calculateAddonPriceFromInsurance(
        expandableCoverages: ExpandableCoverage[],
    ): number {
        const arr = expandableCoverages.reduce((acc, expandableCoverage) => {
            return acc + expandableCoverage.values[0].bounty;
        }, 0);
        return arr;
    }
}

export class RateCancellationPolicy {
    startDate?: string;
    endDate?: string;
    amount?: number;
    text?: string;
}
