import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-safe-bond',
    templateUrl: './safe-bond.component.html',
    styleUrls: ['./safe-bond.component.scss']
})
export class SafeBondComponent implements OnInit {

    @Input() bond: any;

    constructor() {
    }

    ngOnInit() {
    }

}
