import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {BasketManager} from '../lib-shared/services/index';
import {ShoppingCartService} from '../lib-shared/services/index';
import {CheckBasketItemTypePipe} from '../lib-shared/pipes/index';
import {ShopingBasketHotel} from '../integrations/hotels/index';
import {integrationTypes} from '../lib-shared/interfaces/index';
import { safeNumber } from '../lib-shared/functions/index';

@Component({
    selector: 'lib-shopping-cart-summary',
    templateUrl: './shopping-cart-summary.component.html',
    styleUrls: ['./shopping-cart-summary.component.scss'],
    providers: [CheckBasketItemTypePipe]
})
export class ShoppingCartSummaryComponent implements OnInit {

    basketObserver$: BehaviorSubject<any>;
    totalPrice;
    @Input() microsite = false;
    @Input() disableRouting = false;
    basketHotel: ShopingBasketHotel;
    integrationTypes = integrationTypes;

    constructor(
        private basketManager: BasketManager,
        public router: Router,
        private shoppingCartService: ShoppingCartService,
        protected checkBasketItemType: CheckBasketItemTypePipe
    ) {
        this.basketObserver$ = this.basketManager.getSubscription();
    }

    ngOnInit() {
        this.getTotalPrice();
    }

    getTotalPrice() {
        this.basketObserver$.subscribe(data => {
            let totalPrice = 0;
            if (data.length <= 0) {
                this.totalPrice = totalPrice;
                return;
            }
            data.forEach((item, i) => {
                switch (this.checkBasketItemType.transform(item)) {
                    case integrationTypes[0]:
                        const price = safeNumber(item.roomList[0].rooms[0].rates[0].price) * item.roomList.length;
                        totalPrice += price;
                        break;
                    case integrationTypes[1]:
                        totalPrice += safeNumber(item.price);
                        break;
                    case integrationTypes[2]:
                        totalPrice += safeNumber(item.rateSelected.totalPrice);
                        break;
                    case integrationTypes[3]:
                        totalPrice += safeNumber(item.price);
                        break;
                    case integrationTypes[4]:
                        totalPrice += safeNumber(item.insurance.totalPrice) + safeNumber(item.insurance.toppingsPrice);
                        break;
                    case integrationTypes[5]:
                        totalPrice += safeNumber(item.totalAmount);
                        break;
                    case integrationTypes[6]:
                        totalPrice += safeNumber(item.totalCharge);
                        break;
                    case integrationTypes[7]:
                        totalPrice += safeNumber(item.totalPrice);
                        break;
                    case integrationTypes[8]:
                        totalPrice += safeNumber(item.totalPriceIncludingLuggage);
                        break;
                    case integrationTypes[9]:
                        totalPrice += parseFloat(item.totalPrice);
                        break;
                    case integrationTypes[10]:
                        totalPrice += parseFloat(item.totalPrice);
                        break;
                    default:
                        console.log('no match');
                        break;
                }
            });
            this.totalPrice = totalPrice.toFixed(2);
        });
    }

    removeFromBasket(item) {
        // event.stopPropagation();
        this.basketManager.remove(item.uuid);
    }

    emptyCart() {
        this.basketManager.removeBasket();
    }

    editBasketItem(item) {
        // event.stopPropagation();
        this.router.navigate(['integration', 'hotels', 'bookings', 'book-edit', item.uuid]);
    }

    editYisusBasketItem(item) {
        const path = this.redirectToEdit(this.checkBasketItemType.transform(item), item.uuid);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(path));
    }

    redirectToEdit(integration: string, uuid: string): string[] {
        if (this.microsite) { return ['bookings', 'edit', integration, encodeURI(uuid)]; }
        return ['integration', 'bookings', 'book-edit', integration, encodeURI(uuid)];
    }

    redirectToSummary(): string {
        return this.microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
    }

    redirectToBudgets(): string {
        return this.microsite ? 'budgets' : '/integration/budgets';
    }

    book() {
        // this dosent needed
        // this.shoppingCartService.setEventOpenShoppingCart();
        // if (!this.disableRouting) {
        const path = this.microsite ? '/bookings/summary' : '/integration/hotels/bookings/summary';
        this.router.navigateByUrl(path);
        // }
    }

    yisusCarritoResume() {
        const path = this.redirectToSummary();
        this.router.navigateByUrl(path);
    }

    trackByFn(index, item) {
        return index; // or item.id
    }

    goToBudget() {
        const path = this.redirectToBudgets();
        this.router.navigateByUrl(path);
    }
}
