import { NgModule } from '@angular/core';
import { FilterPackagesSearchComponent } from './filter-packages-search.component';
import {
    MatAutocompleteModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { PaxesPackageModule } from '../paxes-package/paxes-package.module';
import { FlightsSearchModule } from '../../flights/index';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [FilterPackagesSearchComponent],
    exports: [FilterPackagesSearchComponent],
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        PaxesPackageModule,
        FormsModule,
        MatExpansionModule,
        FlightsSearchModule,
        MatRadioModule,
        LibSharedModule
    ]
})
export class FilterPackagesSearchModule {
}
