import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment_ from 'moment';

const moment = moment_;

@Component({
    selector: 'lib-item-flight-packages',
    templateUrl: './item-flight-packages.component.html',
    styleUrls: ['./item-flight-packages.component.scss']
})
export class ItemFlightPackagesComponent implements OnInit {

    @Input() typeFlight: string;
    @Input() type: number;
    @Input() flightCode: string;
    @Input() item: any;

    @Output() emitItineraries = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    changeRadio(event) {
        this.emitItineraries.emit(event.value);
    }

    getDuration(item, type) {
        const dateInit = type === 1 ? item.departure.date : item.arrival.date;
        const dateEnd = type === 1 ? item.arrival.date : item.departure.date;
        const init = moment(dateInit);
        const end = moment(dateEnd);
        // @ts-ignore
        return moment.utc(moment(end, "DD/MM/YYYY HH:mm:ss").diff(moment(init, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
    }
}
