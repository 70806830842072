import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from "@angular/core";
import { NgbCalendar, NgbDate, NgbInputDatepicker, } from "@ng-bootstrap/ng-bootstrap";
import * as moment_ from 'moment/moment';

const moment = moment_;

@Component({
    selector: "lib-date-range",
    templateUrl: "./date-range.component.html",
    styleUrls: ["./date-range.component.scss"],
})

export class DateRangeComponent implements OnInit {

    @Input() minDate;
    @Input() format;
    @Input() defaultDates = true;
    @Input() canBeSelectedInSameDay: boolean;
    @Input() fromDate: NgbDate;
    @Input() toDate: NgbDate;

    @ViewChild("datepicker", { static: false }) datepicker: NgbInputDatepicker;

    hoveredDate: NgbDate;

    formattedValue: string = null;
    countDateSettings = 0;

    @Output() from: EventEmitter<string> = new EventEmitter<string>();
    @Output() to: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private calendar: NgbCalendar
    ) {
    }

    ngOnInit() {
        this.minDate = this.calendar.getToday();
        if (this.defaultDates) {
            if (this.countDateSettings < 2) { // arreglo para packages
                this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", 1);
                this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", 11);
                this.setDateValue(this.setDateInput());
                this.changeDates(this.format);
            }
        }

        if (this.fromDate && this.toDate) {
            this.setDateValue(this.setDateInput());
        }
    }

    toggle() {
        this.datepicker.toggle();
    }

    isHovered(date: NgbDate) {
        return (
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            date.after(this.fromDate) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return (
            date.equals(this.fromDate) ||
            date.equals(this.toDate) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    onSelection(date: NgbDate) {
        this.countDateSettings++;
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && this.dateAfterFromDate(date, this.fromDate)) {
            this.toDate = date;
            // closing datepicker on second date selected
            this.datepicker.close();
        } else {
            this.fromDate = date;
            this.toDate = null;
        }
        this.setDateValue(this.setDateInput());
        this.changeDates(this.format);
    }

    setDateValue(value) {
        this.formattedValue = value;
    }

    setDateInput(): string {
        const dateToString = (date: NgbDate): string => {
            return `${date.day}/${date.month}/${date.year}`;
        };

        if (!this.fromDate && !this.toDate) {
            return "";
        } else if (this.fromDate && !this.toDate) {
            return dateToString(this.fromDate) + " - ";
        }
        return dateToString(this.fromDate) + " - " + dateToString(this.toDate);
    }

    formatDate(date: NgbDate, format: string = "YYYY-MM-DD"): string {
        if (date) {
            const mdt = moment([date.year, date.month - 1, date.day]);
            if (!mdt.isValid()) {
                return '';
            }
            return mdt.format(format);
        } else {
            return '';
        }
    }

    private dateAfterFromDate(date: NgbDate, fromDate: NgbDate): boolean {
        return this.canBeSelectedInSameDay ? date.after(fromDate) || date.equals(fromDate) : date.after(fromDate);
    }

    private changeDates(format): void {
        this.from.emit(this.formatDate(this.fromDate, format));
        this.to.emit(this.formatDate(this.toDate, format));
    }
}
