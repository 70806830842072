import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment_ from 'moment';
import {t} from 'typy';

import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Ages, LocationsTransfers, TransferRequestDto } from '../../../lib-shared/interfaces/index'
import { TransferService } from '../../../lib-shared/services/transfer.service';
import { fakeAsync } from '@angular/core/testing';

const moment = moment_;

@Component({
    selector: 'lib-transfers-search',
    templateUrl: './transfers-search.component.html',
    styleUrls: ['./transfers-search.component.scss'],
    providers: [TransferService]
})

export class TransferSearchComponent {

    @Input() transferParams: TransferRequestDto; 
    @Input() isSmall: false;
    @Input() currentTab: number;

    form: FormGroup;
    dateDepartureCtrl: FormControl = new FormControl();
    dateReturnCtrl: FormControl = new FormControl();
    timeDepartureCtrl: FormControl = new FormControl();
    timeReturnCtrl: FormControl = new FormControl();

    onlyAdults: boolean = true

    onlyDeparture: boolean;

    private unsubscribe = new Subject<void>();

    @Input() routerPath = 'integration/transports/transfers';
    defaultRouting = 'integration/transports/transfers'; 

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private cdRef: ChangeDetectorRef,
    ) {

    }

    ngOnInit(): void{
        if(!this.transferParams){
            this.transferParams = new TransferRequestDto(); 
            this.onlyDeparture = false;
        } else {
            this.onlyDeparture = !!!this.transferParams.depDateTime 
        }
        this.initForm();
        
        
    }

    ngAfterViewInit(): void {
        if (this.transferParams) {
            this.setParamsFromQuery(this.transferParams);
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    search(): void {
        (this.dateReturnCtrl.value == "Invalid Date")
            ? this.dateReturnCtrl.setValue("")
            : this.dateDepartureCtrl
        if (this.form.valid) {
            const request = this.buildRequest();
            this.router.navigate([this.getRouting()], {
                queryParams: request
            })
        }
        
    }
    

    private buildRequest(): TransferRequestDto {
        const rawForm = this.form.getRawValue();
        const transferRequestDto = new TransferRequestDto();

        transferRequestDto.totalAdults = this.transferParams.totalAdults;
        transferRequestDto.totalChildren = this.transferParams.totalChildren;
        transferRequestDto.totalBabies = this.transferParams.totalBabies;
        transferRequestDto.dataAge = this.transferParams.listAges;
        transferRequestDto.listAges = this.transferParams.listAges;

        Object.keys(rawForm).forEach(key => {
            transferRequestDto[key] = rawForm[key]
        })
        if(this.onlyDeparture == false){
            transferRequestDto.typeTransfer = "ROU"
        } else {
            if(rawForm.fromType == "HT"){
                transferRequestDto.typeTransfer = "DEP"
            } 
            if(rawForm.fromType == "A"){
                transferRequestDto.typeTransfer = "ARR"
            }
        }       

        return transferRequestDto;
    }


    addAge(input: number | string, index: number | string) {
        this.transferParams.listAges[index] = input
    }

    addChildrens() {
        this.transferParams.totalChildren++
        this.transferParams.listAges.push(12);
    }

    addInfants() {
        this.transferParams.totalBabies++;
        this.transferParams.listAges.push(3);
    }

    deleteChildren(index: number) {
        this.transferParams.totalChildren--
        this.transferParams.listAges.pop();
    }

    deleteInfants(index: number) {
        this.transferParams.totalBabies--
        this.transferParams.listAges.pop();
    }

    incAdults() {
        this.transferParams.totalAdults++
    }

    decAdults() {
        this.transferParams.totalAdults--;
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            fromName: [this.transferParams.fromName],
            fromType: [this.transferParams.fromType, Validators.required],
            fromCode: [this.transferParams.fromCode, Validators.required],
            toName: [this.transferParams.toName],
            toType: [this.transferParams.toType, Validators.required],
            toCode: [this.transferParams.toCode, Validators.required],
            arrDateTime: [this.transferParams.arrDateTime, Validators.required],
            timeDeparture: [this.transferParams.timeDeparture, Validators.required],
            depDateTime: [this.transferParams.depDateTime, !this.onlyDeparture ? Validators.required : null],
            timeReturn: [this.transferParams.timeReturn, !this.onlyDeparture ? Validators.required : null],
            // ages: [this.transferParams.listAges.length > 0 ? Validators.required : null]
        })

        //FUNCIONES Y VARIABLES PARA SETEAR FECHAS\\

        const checkDepartureDate = !this.transferParams.arrDateTime 
            ? moment().add(1,'days').format('YYYY/MM/DD')
            : moment(this.transferParams.arrDateTime, 'YYYY/MM/DD').format('YYYY/MM/DD');
        
       
        const checkReturnDate = !this.transferParams.depDateTime
            ? (!this.onlyDeparture ? moment().add(3, 'days').format('YYYY/MM/DD') : '') 
            : moment(this.transferParams.depDateTime, 'YYYY/MM/DD').format('YYYY/MM/DD');
        
        this.initValueDateControl(this.dateDepartureCtrl, "arrDateTime", checkDepartureDate);
        this.initValueDateControl(this.dateReturnCtrl, "depDateTime", checkReturnDate);

        this.setValueDateControl(this.dateDepartureCtrl, "arrDateTime");
        this.setValueDateControl(this.dateReturnCtrl, "depDateTime");

        //FUNCIONES Y VARIABLES PARA SETEAR HORAS\\

        const checkDepartureTime = !this.transferParams.timeDeparture 
            ? ''
            : moment(this.transferParams.timeDeparture, 'hh:mm').format('HH:mm');
        
        const checkReturnTime = !this.transferParams.timeReturn
            ? (!this.onlyDeparture ? '12:00' : '') 
            : moment(this.transferParams.timeReturn, 'hh:mm').format('HH:mm');

        this.initValueTimeControl(this.timeDepartureCtrl, "timeDeparture", checkDepartureTime);
        this.initValueDateControl(this.timeReturnCtrl, "timeReturn", checkReturnTime);

        this.setValueTimeControl(this.timeDepartureCtrl, "timeDeparture");
        this.setValueTimeControl(this.timeReturnCtrl, "timeReturn"); 
    }

    private initValueDateControl(control: FormControl, formControlName: string, date: string){
        control.setValue(new Date(date));
        const newDate = moment(date, "YYYY/MM/DD").format("YYYY-MM-DD");
        this.form.get(formControlName).setValue(newDate);
    }

    private setValueDateControl(control: FormControl, formControlName: string): void {
        control.valueChanges
            .pipe(takeUntil(this.unsubscribe)).subscribe(value => {
                const newDate = !value ? "" : moment(value).format("YYYY-MM-DD");
                this.form.get(formControlName).setValue(newDate)
            })

    }

    private initValueTimeControl(control: FormControl, formControlName: string, value: string){
        control.setValue(value);
        const newTime = value;
        this.form.get(formControlName).setValue(newTime);
    }

    private setValueTimeControl(control: FormControl, formControlName: string): void {
        control.valueChanges
            .pipe(takeUntil(this.unsubscribe)).subscribe(value => {
                const newTime = !value ? "" : value;
                this.form.get(formControlName).setValue(newTime)
            })

    }

    getRouting(): string {
        return this.routerPath ? this.routerPath : this.defaultRouting;
    }

    selectDeparture(locationTransfer: LocationsTransfers): void {
        this.form.get('fromName').setValue(locationTransfer.name)
        this.form.get('fromType').setValue(locationTransfer.type);
        this.form.get('fromCode').setValue(locationTransfer.code);
    }

    selectArrival(locationTransfer: LocationsTransfers): void {
        this.form.get('toName').setValue(locationTransfer.name);
        this.form.get('toType').setValue(locationTransfer.type);
        this.form.get('toCode').setValue(locationTransfer.code);
    }

    
    selectDepartureDay(date: Date): void {
        this.dateDepartureCtrl.setValue(date);
        this.dateDepartureCtrl.markAsTouched();
    }

    selectReturnDay(date: Date): void {
        this.dateReturnCtrl.setValue(date);
        this.dateReturnCtrl.markAsTouched();
    }

    setParamsFromQuery(params: any) {
        this.form.get('timeReturn').setValue(params.timeReturn);
    }


    changeOnlyDeparture(): void{
        this.dateReturnCtrl.setValue("");
        this.timeReturnCtrl.setValue("");
        this.form.controls["depDateTime"].clearValidators();
        this.form.controls["timeReturn"].clearValidators();
        if(!this.onlyDeparture){
            this.form.controls["depDateTime"].setValidators([Validators.required]);
            this.form.controls["timeReturn"].setValidators([Validators.required]);
        }
        this.form.controls["depDateTime"].updateValueAndValidity();
        this.form.controls["timeReturn"].updateValueAndValidity();
    }

}