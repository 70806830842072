import { Commission as TrainCommission } from './trains.response.dto';
import { Response } from './response';

export class TrainCheckedRateDtoRes extends Response<TrainCheckedRateResponse> {
}

export class TrainCheckedRateResponse {
    netPrice: number;
    commission: TrainCommission;
    providerToken: string;
    requestToken: string;
}
