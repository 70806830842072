import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'getCarFilterTitle'
})
export class GetCarFilterTitlePipe implements PipeTransform {

    transform(rawTitle: string): string {
        if (!rawTitle) { return; }
        if (rawTitle === 'products') { return 'productos'; }
        if (rawTitle === 'franchiseConditions') { return 'condiciones de franquicia'; }
        if (rawTitle === 'suppliers') { return 'proveedores'; }
        if (rawTitle === 'seats') { return 'plazas'; }
        if (rawTitle === 'doors') { return 'puertas'; }
        if (rawTitle === 'airConditioners') { return 'aire acondicionado'; }
        if (rawTitle === 'transmissions') { return 'transmisión'; }
        if (rawTitle === 'types') { return 'tipo de coche'; }
        if (rawTitle === 'sizes') { return 'tamaño de coche'; }
        return;
    }
}
