import { Component, Input } from '@angular/core';
import { ShopingBasketInsurance } from '../../../integrations/insurances/index';

@Component({
    selector: 'lib-insurances-book-summary',
    templateUrl: './insurances-book-summary.component.html',
    styleUrls: ['./insurances-book-summary.component.scss']
})
export class InsurancesBookSummaryComponent {

    @Input() insurance: ShopingBasketInsurance;

    constructor() {
    }

}
