import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AgencyService } from '../service/agency.service';

@Injectable({
    providedIn: 'root'
})
export class AgencyHostGuard implements CanActivate {
    constructor(private agencyService: AgencyService) {
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        if (next.params.agency) {
            await this.agencyService.getBySlug(next.params.agency).toPromise()
        } else {
            await this.agencyService.getBySlug(null, window.location.hostname).toPromise()
        }
        return true;
    }
}
