import { t } from 'typy';

export default class Pax {
    public rooms?: number;
    public adults: number;
    public children: Array<number>;

    public constructor(rooms: number, adults: number, children: Array<number>) {
        this.rooms = rooms;
        this.adults = adults;
        this.children = children;
    }

    static fillPaxes(paxes: Pax[]): number[] {
        const arr = paxes.reduce((acc, cur) => {
            const adults = new Array(cur.adults).fill(30);
            const children = t(cur, 'children').safeArray;
            acc = [...acc, ...adults, ...children];
            return acc;
        }, []);
        return arr;
    }
}
