import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'chartremove'
    })
    export class ChartremovePipe implements PipeTransform {

      transform(value: string, separator): string {
        let valueFinal = '</br>';
        const arrayStrings = value.split(/[,y]+/); // split by commas and 'y' character
        for (let x = 0; x < arrayStrings.length; x++) {
            if (x === arrayStrings.length - 1) {
              arrayStrings[x] = '</br>y ' + arrayStrings[x];
              valueFinal += arrayStrings[x] + '.';
            } else if (x === arrayStrings.length - 2) {
              valueFinal += arrayStrings[x] + ' '; // after the'y' character don't put the comma
            } else {
              valueFinal += arrayStrings[x] + `${separator}</br>`;
            }
        }
        return valueFinal;
      }
    }
