import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageBookingComponent } from './package-booking.component';
import { RouterModule, Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';

const routes: Routes = [
    {
        path: 'create',
        component: PackageBookingComponent,
    },
    {
        path: 'edit/:id',
        component: PackageBookingComponent,
    }
];

@NgModule({
    declarations: [PackageBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
        // RouterModule.forChild(routes)
    ], exports: [PackageBookingComponent]
})
export class PackageBookingModule {
}
