import { Pipe, PipeTransform } from "@angular/core";
import { HotelPhotos } from "@vecib2c/frontend-dto";
import {TecnoturisServiceConfig} from "../../../tecnoturis.service";

@Pipe({
    name: 'clearHotelPhotoUrl',
})
export class ClearHotelPhotoUrlPipe implements PipeTransform {

    transform(hotelPhoto: HotelPhotos | string, env: TecnoturisServiceConfig, microsite = false): string {
        if (!hotelPhoto) { return; }
        if ((hotelPhoto as HotelPhotos).fileName) {
            if (microsite) {
                const providerPath = env.providers.default
                    .replace('https://', 'http://')
                    .replace('http://', '');

                const host = window.location.hostname.indexOf('localhost') > -1 ? 'microsites.tecnoturis.es' : window.location.hostname;
                return (hotelPhoto as HotelPhotos).fileName.replace(host, providerPath);
            }

            return (hotelPhoto as HotelPhotos).fileName;
        }
        return hotelPhoto as string;
    }
}
