import { Component, Input } from "@angular/core";
import { ShoppingBasketHFS } from '../../../integrations/hotels/index';
import {TecnoturisService} from "../../../tecnoturis.service";
import {MicrositeService} from "../../../lib-shared/index";

@Component({
    selector: 'lib-hf-book-summary',
    templateUrl: './hf-book-summary.component.html',
    styleUrls: ['./hf-book-summary.component.scss'],
})

export class HFBookSummaryComponent {

    @Input() hf: ShoppingBasketHFS;

    constructor(public tecnoturisService: TecnoturisService,
                public micrositeService: MicrositeService) {
    }

    getCategory(starsNumber: number): Array<number> {
        const stars = [1, 2, 3, 4, 5];
        return stars.filter((star) => {
            return star <= starsNumber;
        });
    }
}
