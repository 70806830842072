import { Pipe, PipeTransform } from '@angular/core';
import { integrationTypes } from '../interfaces/integration.types';
import { ShoppingBasketTransfer } from '../interfaces/shopping-basket-transfers.model';
import {
    FlightBookingPostResDto,
    HotelBookingPostResDto,
    InsurancesBookingPostResDto,
    ActivityBookingPutResDto, PackageVacationPackageBooking
} from '@vecib2c/frontend-dto';
import {
  ShoppingBasketTrains,
  ShoppingBasketMultidestination,
  CircuitBooking,
  CarBookingRes,
} from '../interfaces/index';

@Pipe({
    name: 'checkConfirmationType'
})
export class CheckConfirmationTypePipe implements PipeTransform {
    transform(item): string {

        const hotelConfirmationCheck = (item: any) => {
            if ((item as HotelBookingPostResDto).hotel &&
                (item as HotelBookingPostResDto).bookId &&
                (item as HotelBookingPostResDto).providerName) {
                return true;
            }
            return false;
        };

        const flightConfirmationCheck = (item: any) => {
            if (
                (item as FlightBookingPostResDto).bookingId &&
                (item as FlightBookingPostResDto).travelers &&
                (item as FlightBookingPostResDto).airBookingFlightSegments) {
                return true;
            }
            return false;
        };

        const circuitConfirmationCheck = (item: CircuitBooking) => {
            return item.integrationType === 'CIRCUIT';
        };

        const hfConfirmationCheck = (item: any) => {
            if (
                item.flight && item.hotels && item.insurance
            ) {
                return true;
            }
            return false;
        };

        if (hotelConfirmationCheck(item)) {
            return integrationTypes[0];
        }

        if (hfConfirmationCheck(item)) {
            return integrationTypes[3];
        }

        if (this.insuranceConfirmationCheck(item)) {
            return integrationTypes[4];
        }

        if (flightConfirmationCheck(item)) {
            return integrationTypes[1];
        }

        if (circuitConfirmationCheck(item)) {
            return integrationTypes[2];
        }

        if (this.insuranceConfirmationCheck(item)) {
            return integrationTypes[4];
        }

        if (this.trainCheck(item)) {
            return integrationTypes[7];
        }

        if (this.activityCheck(item)) {
            return integrationTypes[9];
        }

        if (this.packagesConfirmationCheck(item)) {
            return integrationTypes[5];
        }

        if (this.transportCarCheck(item)) {
            return integrationTypes[6];
        }

        if (this.multidestinationCheck(item)) {
            return integrationTypes[8];
        }

        if (this.transferCheck(item)) {
            return integrationTypes[10];
        }

        console.error('badddddd');

        throw new Error('confirmation type unexpected');
    }

    insuranceConfirmationCheck = (item: any) => {
        if (
            (item as InsurancesBookingPostResDto).totalInsureds
            &&
            (item as InsurancesBookingPostResDto).codeBook
        ) {
            return true;
        }
        return false;
    }

    transportCarCheck = (item: CarBookingRes): item is CarBookingRes => {
        return item.integrationType === 'CAR';
    }

    trainCheck = (item: any): item is ShoppingBasketTrains => {
        return !!(item as ShoppingBasketTrains).trains;
    }

    packagesConfirmationCheck = (item: any): item is PackageVacationPackageBooking => {
        if ((item as PackageVacationPackageBooking).passengers &&
            (item as PackageVacationPackageBooking).passengers[0] &&
            (item as PackageVacationPackageBooking).flights &&
            (item as PackageVacationPackageBooking).flights[0] &&
            (item as PackageVacationPackageBooking).hotels &&
            (item as PackageVacationPackageBooking).hotels[0]) {
            return true;
        }
        return false;
    }

    multidestinationCheck = (item: any): item is ShoppingBasketMultidestination => {
        return !!(item as ShoppingBasketMultidestination).locations;
    }

    activityCheck = (item: any): item is ActivityBookingPutResDto => {
        return !!(item as ActivityBookingPutResDto).activities;
    }

    transferCheck = (item: any): item is ShoppingBasketTransfer => {
        return !!(item as ShoppingBasketTransfer)
    }
}
