import { NgModule } from "@angular/core";
import { LibSharedModule} from "../../lib-shared/lib-shared.module";
import { ReratedPriceComponent } from "./rerated-price.component";

@NgModule({
    declarations: [
        ReratedPriceComponent,
    ],
    imports: [
        LibSharedModule,
    ],
    exports: [
        ReratedPriceComponent,
    ]
})
export class ReratedPriceModule {

}
