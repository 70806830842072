import {
  ActivityErrorResponse,
  FlightErrorResponse,
  HotelErrorResponse,
  IBookingResponse,
  Luggage,
  MultidestinationPassenger,
  MultidestinationPreBookingByLocation,
  TrainErrorResponse,
  CarErrorResponse,
} from "./multidestination-pre-booking.model";
import { Client } from "../client.model";
import { CustomError } from "./custom-error.model";
import {
  HotelBooking,
  FlightBookingPostResDto,
  ActivityBookingPutResDto,
} from "@vecib2c/frontend-dto";
import { BookingResponse } from '../trains/response/bookings.response.dto';
import { ShoppingBasketMultidestination } from "../shopping-basket-multidestination.model";
import { CarBookingResponse as CarBookingRes } from "../cars/booking.res.model";
import { Location } from "./multidestination-list.model";

export class MultidestinationBooking {
  requestToken: string;
  preBookingToken: string;
  paxes: MultidestinationPassenger[];
  cabinLuggages: Luggage[];
  holder: MultidestinationClient;

  constructor() {
    this.paxes = [];
  }

  static build(
    passengers: MultidestinationPassenger[],
    requestToken: string,
    preBookingToken: string,
    cabinLuggages: Luggage[],
    holder: Client,
  ): MultidestinationBooking {
    const bookingParameters = new MultidestinationBooking();
    bookingParameters.requestToken = requestToken;
    bookingParameters.preBookingToken = preBookingToken;
    bookingParameters.paxes = passengers;
    bookingParameters.holder = MultidestinationClient.buildFromClient(holder);
    bookingParameters.cabinLuggages = cabinLuggages;

    return bookingParameters;
  }
}

export class MultidestinationBookingCreateResponse {
  status: number;
  data: MultidestinationBookingResponse;
  shoppingBasket?: ShoppingBasketMultidestination;
}

export class MultidestinationBookingResponse {
  bookingResponses: BookingByLocation[];
  preBookingResponses?: MultidestinationPreBookingByLocation[];
  locations: Location[];
  requestToken: string;
  preBookingToken: string;
  errors?: CustomError[];
  totalPrice: number;
  passengers: MultidestinationPassenger[];
}

export class  BookingByLocation {
  origin: string;
  destination: string;
  hotelResponses: MultidestinationHotelBookingResponse[];
  flightResponse: MultidestinationFlightBookingResponse;
  trainResponse: MultidestinationTrainBookingResponse;
  activityResponse: MultidestinationActivityBookingResponse;
  carResponse: MultidestinationCarBookingResponse;
  hotelErrorResponses: HotelErrorResponse[];
  flightErrorResponses: FlightErrorResponse[];
  trainErrorResponses: TrainErrorResponse[];
  activityErrorResponses: ActivityErrorResponse[];
  carErrorResponses: CarErrorResponse[];
  checkIn: string;
  checkOut: string;
  arrivalDate: string;
}

export class MultidestinationHotelBookingResponse extends HotelBooking implements IBookingResponse {
  destinationIndex: number;
  roomIndex: number;
}

export class MultidestinationFlightBookingResponse extends FlightBookingPostResDto implements IBookingResponse {
  destinationIndex: number;
}

export class MultidestinationTrainBookingResponse extends BookingResponse implements IBookingResponse {
  destinationIndex: number;
}

export class MultidestinationActivityBookingResponse extends ActivityBookingPutResDto implements IBookingResponse {
  destinationIndex: number;
}

export class MultidestinationCarBookingResponse extends CarBookingRes implements IBookingResponse {
  destinationIndex: number;
}

export class MultidestinationClient {

  id: number;
  address?: string;
  agency: number;
  agencyChain: number;
  birthdate: string;
  city: string;
  createdAt: string;
  document: string;
  email: string;
  name: string;
  nationality: string;
  phoneNumber: string;
  surname: string;
  updatedAt: string;

  static buildFromClient(client: Client): MultidestinationClient {
    const {
      id,
      address,
      agency,
      agency_chain: agencyChain,
      birthDate: birthdate,
      city,
      createdAt,
      document,
      email,
      name,
      nationality,
      phoneNumber,
      surname,
      updatedAt,
    } = client;
    return {
      id,
      address,
      agency,
      agencyChain,
      birthdate,
      city,
      createdAt,
      document,
      email,
      name,
      nationality,
      phoneNumber,
      surname,
      updatedAt,
    };
  }
}
