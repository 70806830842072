import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PackageTicket } from '@vecib2c/frontend-dto';

@Component({
    selector: 'lib-item-package-ticket-card',
    templateUrl: './item-package-ticket-card.component.html',
    styleUrls: ['./item-package-ticket-card.component.scss']
})
export class ItemPackageTicketCardComponent implements OnInit {

    @Input() ticket: PackageTicket;
    @Input() passengerCount: number;
    @Input() microsites = false;

    @Output() emitBooking = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    booking(item) {
        item.selected = !item.selected;
        this.emitBooking.emit(item);
    }

}
