import { Component, OnInit } from '@angular/core';
import { dossierFormMetaData } from './dossier.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-dossier',
    templateUrl: './dossier.component.html',
    styleUrls: ['./dossier.component.scss']
})
export class DossierComponent implements OnInit {
    dynamicCrud = dossierFormMetaData;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.dossier) {
            this.dynamicCrud = this.tecnoturisService.config.forms.dossier;
        }
    }

    ngOnInit() {
    }

}
