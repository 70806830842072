import { Pipe, PipeTransform } from "@angular/core";
import { TecnoturisService } from '../../tecnoturis.service';

@Pipe({
    name: 'carsProviderLogo'
})
export class CarsProviderLogoPipe implements PipeTransform {
    protected environment;

    constructor(private tecnoturisService: TecnoturisService) {
        this.environment = this.tecnoturisService.config.flexibleAutos;
    }

    transform(relativePath: string): string {
        return this.environment + '/' + relativePath;
    }
}
