import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'distance'
})
export class DistanceMeters implements PipeTransform {
    transform(distance: any): string {

        const separe = distance.toString().split('.');
        distance = distance + ' Km';
        if (separe[0] === '0') { distance = separe[1] + ' Mts'; }
        return distance ;
    }
}
