import { ComissionsTransfers } from '../../..';
import { Extras, Occupancy, Vehicle } from './transfer.response.dto';

export class PreBookingResponseTransferDto{
    data: PreBookingResponseTransfer;
    status: number
}

export class PreBookingResponseTransfer {
    amount: number;
    totalNet: string | number;
    commission: ComissionsTransfers;
    provider: string;
    currencyId: string;
    locator: string;
    sessionId: string;
    from: FromPreBooking;
    to: ToPreBooking;
    occupancy: Occupancy;

    extra?: Extras;

    errorMessage?: string;
}

export class FromPreBooking {
    amount: string;
    dateTime: string;
    transferTypeCode: string;
    code: string;
    name: string;
}

export class ToPreBooking {
    amount: string;
    dateTime: string;
    transferTypeCode: string;
    code: string;
    name: string;
}

