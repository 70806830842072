import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DossierPerClientsComponent } from './dossier-per-clients.component';
import { MatCardModule } from '@angular/material/card';
import { DynamicCrudModule } from '../dynamic-crud/index';
import { LibSharedModule } from '../lib-shared/index';

@NgModule({
    declarations: [DossierPerClientsComponent],
    imports: [
        CommonModule,
        MatCardModule,
        DynamicCrudModule,
        LibSharedModule
    ],
    exports: [DossierPerClientsComponent],
    entryComponents: [DossierPerClientsComponent]
})
export class DossierPerClientsModule {
}
