import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { numberNigths, packageDestinationComb, radioGroupDisney, PackageNameEnum } from "../../../../mock/packages";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import { PackagesProviderService } from "../../../lib-shared/services/packages-provider.service";
import { CalendarPackagesComponent } from "../calendar-packages/calendar-packages.component";
import * as moment_ from "moment";
import { NotificationPopupService } from "../../../lib-shared/services/notification-popup.service";
import { MatDialog } from "@angular/material/dialog";
import Pax from "../../../paxes/Pax";
import { AirportService } from '../../../lib-shared/services/index';
import {
  ReferencePricesRequest,
} from '../../../lib-shared/interfaces/index';

const moment = moment_;

@Component({
    selector: 'lib-filter-packages-disney-search',
    templateUrl: './filter-packages-disney-search.component.html',
    styleUrls: ['./filter-packages-disney-search.component.scss']
})
export class FilterPackagesDisneySearchComponent implements OnInit {

    @Input() inputFields: any;
    @Input() packageName: PackageNameEnum;
    @Input() isSmall = false;
    @Input() customize = false;
    @Input() microsite;

    @Output() eventForm = new EventEmitter<any>();
    @Output() queryParams = new EventEmitter<any>();

    filteredOrigin: Observable<any[]>;
    radioGroupDisney = radioGroupDisney;
    radioSelected = radioGroupDisney[0].id;
    numberNigths = numberNigths;
    packageDestinationComb = packageDestinationComb;
    paxes: any = [];
    pax: Pax;
    form: FormGroup;

    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private airportService: AirportService,
        private packageService: PackagesProviderService,
        private notificationPopupService: NotificationPopupService,
    ) {
    }

    ngOnInit() {

        this.paxes.push(new Pax(1, 2, []));

        this.form = this.formBuilder.group({
            origin: [''],
            first_instance: [''],
            nights: [''],
            nights_disney: [''],
            nights_paris: [''],
            checkIn: ['', Validators.required],
            distribution: [this.paxes],
            options: [this.radioSelected],
            options_combo: [null],
            destination: ['disney']
        });

        this.filteredOrigin = this.form.get('origin').valueChanges
            .pipe(
                debounceTime(800),
                filter(value => value && value.length > 1),
                switchMap(city => this.filterCities(city))
            );

        // this.form.get('options').valueChanges.pipe(
        //     tap(res => this.resetForm()),
        //     distinctUntilChanged()
        // ).subscribe(radioSelected => {
        //     this.radioSelected = radioSelected;
        // });

        if (this.inputFields) {
            this.initValues(this.inputFields);
        }
    }

    initValues(keyFields: any) {
        Object.keys(keyFields).forEach(key => {
            if (Object.keys(this.form.getRawValue()).some(keyForm => keyForm === key)) {
                this.form.get(key).setValue(key === 'nights' || key === 'nights_disney' || key === 'nights_paris' ?
                    +keyFields[key] : keyFields[key]);
            }
        });

        this.radioSelected = this.form.get('options').value;

        this.paxes = [];
        keyFields['distribution'].forEach(d => {
            this.paxes.push(new Pax(1, d.adults, d.children));
        });
        // if (!this.isSmall) {

        // }
    }

    filterCities(city: string) {
        return this.airportService.search(city);
    }


    onEnterOrigin(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.origin = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['origin'].setValue(reqIATA);
        }
    }

    autoCompleteDisplayFn(option) {
        if (option == null || undefined) {
            return '';
        }
        if (option !== '' || null || undefined) {
            return option.city + ' | ' + option.name + ' | ' + option.country;
        }
    }

    openCalendar(): void {
        const request = this.buildReferencePriceRequest();
        let providers: string[] = [];

        this.packageService.getReferencePrices(request).pipe(
            tap(response => providers = response.data.providers),
            map(res => PackagesProviderService.generateResponseCalendar(this.packageName, res.data.rows)),
            distinctUntilChanged(),
        ).subscribe(events => {
            if (events && events.length > 0) {
                return this.displayDialogCalendar(events);
            } else {
                return this.notificationPopupService.openPopup('no cuenta con paquetes disponibles para este destino');
            }
        });
    }


    displayDialogCalendar(events?: any) {
        const dialogRef = this.dialog.open(CalendarPackagesComponent, {
            width: '75em',
            height: '40em',
        });

        dialogRef.componentInstance.dataEvent = events;
        dialogRef.componentInstance.isPackageDisney = true;

        dialogRef.componentInstance.emitEvent.pipe(
            distinctUntilChanged()
        ).subscribe(res => {
            this.selectionEvent('checkIn', moment(res.date).format('DD-MM-YYYY'));
            setTimeout(() => {
                this.search();
            }, 500);
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    search() {
        if (this.form.valid) {
            this.form.get('destination').setValue('Disney');
            this.eventForm.emit(this.form.getRawValue());
        } else {
            return this.notificationPopupService.openPopup('Por favor debe ingresar todos los campos obligatorios');
        }
    }

    resetForm(): void {
        this.form.reset({
            options: this.form.get('options').value,
            distribution: this.form.get('distribution').value
        });
    }

    selectionEvent(key: string, value): void {
        this.form.get(key).setValue(value);
    }

    onPaxChanged(pax: any) {
        this.paxes[pax.position] = pax.newPax;
        this.form.get('distribution').setValue(this.paxes);
    }

    onRemovePax(position) {
        const newPaxes = [];
        this.paxes.forEach((pax: Pax, index) => {
            if (index !== position) {
                newPaxes.push(pax);
            }
        });

        this.paxes = newPaxes;
    }

    addPax() {
        this.paxes.push(new Pax(1, 2, []));
    }

    filterCombo(radioGroupDisney): any[] {
        const combo = [...radioGroupDisney];
        return combo.filter((radio, i) => i === 2 || i === 3);
    }

    changeForm(event) {
        this.radioSelected = event;
        this.resetForm();
    }

    private buildReferencePriceRequest(): ReferencePricesRequest {
        const request = new ReferencePricesRequest();
        const origin = this.form.get('origin').value;
        if (origin) {
            request.originCode = origin.iata;
        }
        request.destinationCode = 'disney';
        request.numberOfNights = this.form.get('nights').value;
        request.destinationType = this.packageService.transformPackageNameToDestinationType(this.packageName);

        return request;
    }

}
