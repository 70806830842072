import {Pipe, PipeTransform} from "@angular/core";
import {Commission, Itinerary, Itinerary2, Pax, SalesGroup} from '../interfaces/index';
import t from "typy";

@Pipe({
    name: 'calculateTotalPricePipe'
})
export class CalculateTotalPricePipe implements PipeTransform {

    transform(itineraries: Itinerary2[]): number {
        const salesGroup = t(itineraries, '[0].classes[0].salesGroups[0]').safeObject;
        const commisionGroup = t(salesGroup, 'cabins[0].paxes[0].fares[0].commission').safeObject as Commission;
        const totalByCabinOne = this.getTotalByCabin(salesGroup);
        const totalByCabinTwo = this.getTotalByCabin(t(itineraries, '[1].classes[0].salesGroups[0]').safeObject);
        const totalNet = totalByCabinOne + totalByCabinTwo;
        if (commisionGroup) {
            const {commissionRate, fee, iva, commissionPercent, ivaPercent} = this.calculatePercent(commisionGroup);
            const commission = this.calculateCommission(totalNet, commissionPercent, ivaPercent);
            const pvp = this.calculatePVP(totalNet, commission, fee);
            return pvp || 0;
        }
        return totalNet;
    }

    getTotalByCabin(salesGroup) {
        if (!salesGroup) {
            return 0;
        }
        const total = salesGroup.cabins[0].paxes.reduce((accumulator: number, pax: Pax) => {
            accumulator = accumulator + (pax.fares[0].curNetTa * pax.paxNumber);
            return accumulator;
        }, 0);

        return total;
    }

    calculatePercent(agencyCommission: Commission): Commission {
        return {
            ...agencyCommission,
            commissionPercent: (agencyCommission.commission || 0) / 100,
            ivaPercent: 1 + (agencyCommission.iva || 0) / 100,
        };
    }

    calculateCommission(price: number, commissionPercent = 0, ivaPercent = 0): number {
        return price * commissionPercent * ivaPercent;
    }

    calculatePVP(price: number, commission = 0, fee = 0): number {
        if (price === 0) {
            return 0;
        }
        return this.roundNumber(price + commission + fee);
    }

    roundNumber(value: number): number {
        return Number(value.toFixed(2));
    }
}
