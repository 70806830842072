import { HotelRoomPutReqDto } from "@vecib2c/frontend-dto";
import { ShopingBasketHotel } from "../../../integrations";

export class HotelRoomPutReqMapper {

    static buildFromBasket(item: ShopingBasketHotel): HotelRoomPutReqDto {
        const {
            bookingPostReqDto: {
                roomDetails,
            },
            providers,
            providerName,
        } = item;

        if (!roomDetails) { return; }
        const roomDetail = roomDetails[0];
        if (!roomDetail) { return; }

        const { checkIn, checkOut, hotelId } = roomDetail;

        if (!providers) { return; }
        const provider = providers[0];
        if (!provider) { return; }
        const { requestToken } = provider;

        return {
            checkIn,
            checkOut,
            hotelId: hotelId + '',
            providerName,
            requestToken,
        };
    }
}
