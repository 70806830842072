import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CircuitRate } from '@vecib2c/frontend-dto/dist/dto/Circuits/Circuit';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'lib-circuits-pick-date',
    templateUrl: './circuits-pick-date.component.html',
    styleUrls: ['./circuits-pick-date.component.scss']
})
export class CircuitsPickDateComponent implements OnInit, OnDestroy, OnChanges {
    @Input() departures: any;

    @Input() date: string;

    departureControl = new FormControl();

    @Output() rateSelected: EventEmitter<CircuitRate> = new EventEmitter<CircuitRate>();


    allRates: CircuitRate[];

    unsubscribe = new Subject();

    constructor() {
        this.departureControl.valueChanges
            .subscribe(value => {

            });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.departures) {
            this.allRates = [];
            if (this.departures) {

                this.departures.forEach(d => this.allRates.push(...d.rates.map(r => {

                    let tasas = 0;
                    if (r.flights) {
                        tasas = parseFloat(r.flights.opcion.tasas._);
                    }

                    return {
                        ...r, checked: false,
                        date: d.date,
                        tasasPrice: tasas,
                        totalPrice: parseFloat(r.price) + tasas
                    };
                })));

                this.rateSelected.emit(this.allRates[0]);
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
