import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-circuits-book-confirmation',
    templateUrl: './circuits-book-confirmation.component.html',
    styleUrls: ['./circuits-book-confirmation.component.scss']
})
export class CircuitsBookConfirmationComponent {

    @Input() circuitsBookingConfirmation: any;

    constructor() {
    }

}
