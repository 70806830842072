import { Pipe, PipeTransform } from "@angular/core";
import Pax from '../../paxes/Pax';
import { IFlightPax } from '../../lib-shared/interfaces/index';

@Pipe({
    name: 'paxToFlightPax'
})
export class PaxToFlightPaxPipe implements PipeTransform {

    infantAge = 1;

    transform(pax: Pax): IFlightPax {
        if (!pax) {
            return;
        }
        const adultsCount = pax.adults;
        let childrenCount = 0;
        let infantCount = 0;

        for (let i = 0; i < pax.children.length; i++) {
            if (pax.children[i] <= this.infantAge) {
                infantCount++;
            } else {
                childrenCount++;
            }
        }

        return {adults: adultsCount, children: childrenCount, infants: infantCount};
    }
}
