import { Injectable } from '@angular/core';
import { HotelProviderService } from './hotel-provider.service';
import { HotelRoomPutReqDto } from '@vecib2c/frontend-dto';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CancellationPoliciesService {

    constructor(
        private hotelProvider$: HotelProviderService
    ) {
    }

    get(rateKey: string, hotelRoomPutReqDto: HotelRoomPutReqDto): Observable<any> {
        return this.hotelProvider$.checkRate(rateKey, hotelRoomPutReqDto);
    }

}
