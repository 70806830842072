import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {IonicModule} from '@ionic/angular';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CommercialEventComponent} from './commercial-event.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
    declarations: [
        CommercialEventComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        IonicModule,
        MatSnackBarModule,
        MatChipsModule,
        MatAutocompleteModule
    ],
    exports: [
        CommercialEventComponent
    ]
})
export class CommercialEventModule {
}
