import { Client } from "./client.model";
import { IBookingItem } from './bookingItem.interface';
import { CarAvailabilityRes, CarAvailabilityResDto, Cost, DetailedPricing } from "./cars/availability.res.model";
import {
    CarBookingResDto,
    CarForm,
} from "./cars/booking.res.model";
import { IntegrationType } from "./integration.types";
import { ShoppingBasketComment } from "./shopping-basket.model";
import { safeNumber } from "../functions/index";
import { CarAvailabilityReq } from "./cars/availability.req.model";
import { Params } from "@angular/router";
import { CarBookingDriver, CarBookingDtoReq, CarExtraInfo, CarState } from "./cars/booking.req.model";
import { Location } from './multidestination/multidestination-list.model';
import * as  uuidv1_ from 'uuid/v1';
import { MultidestinationPassenger } from "./multidestination/multidestination-pre-booking.model";
import { ManagementPax } from "./management.interface";
import { CarPaxFromManagementFactory } from "../factories/cars/car-pax-from-management.factory";

const uuidv1 = uuidv1_;

export class CocheListReqDto {
    PickupLocationID: string;
    DropoffLocationID: string;
    PickupDate: string;
    DropoffDate: string;
    PickupTime: string;
    DropoffTime: string;
    ProductID: number;
    DriversAge: number;
}

export class CocheSearchDto {
    PickupLocationID: string;
    DropoffLocationID: string;
    PickupDate: string;
    DropoffDate: string;
    PickupTime: string;
    DropoffTime: string;
    ProductID: number;
    PickupLocationName: string;
    PickupLocationCountry: string;
    DropoffLocationName: string;
    DropoffLocationCountry: string;
    DriversAge: number;

    static buildFromCarAvailabilityReq(listParams: CarAvailabilityReq): CocheSearchDto {
        if (!listParams) { return; }
        const {
            PickupLocationID,
            DropoffLocationID,
            PickupDate,
            DropoffDate,
            PickupTime,
            DropoffTime,
            ProductID,
            PickUpLocationName,
            DropOffLocationName,
            PickUpLocationCountry,
            DropOffLocationCountry,
            DriversAge,
        } = listParams;
        const params: CocheSearchDto = {
            PickupLocationID: PickupLocationID + '',
            DropoffLocationID: DropoffLocationID + '',
            PickupDate,
            DropoffDate,
            PickupTime,
            DropoffTime,
            ProductID,
            PickupLocationName: PickUpLocationName,
            DropoffLocationName: DropOffLocationName,
            PickupLocationCountry: PickUpLocationCountry,
            DropoffLocationCountry: DropOffLocationCountry,
            DriversAge,
        };

        return params;
    }

    static buildFromQueryParams(query: Params): CocheSearchDto {
        return {
            PickupLocationID: query['PickupLocationID'],
            DropoffLocationID: query['DropoffLocationID'],
            PickupDate: query['PickupDate'],
            DropoffDate: query['DropoffDate'],
            PickupTime: query['PickupTime'],
            DropoffTime: query['DropoffTime'],
            PickupLocationName: query["PickupLocationName"],
            PickupLocationCountry: query["PickupLocationCountry"],
            DropoffLocationName: query["DropoffLocationName"],
            DropoffLocationCountry: query["DropoffLocationCountry"],
            ProductID: query['ProductID'],
            DriversAge : query['DriversAge'],
        };
    }
}

export class DetailCocheSearchDto {
    PickupLocationID: string;
    DropoffLocationID: string;
    PickupDate: string;
    DropoffDate: string;
    PickupTime: string;
    DropoffTime: string;
    ProductID: number;
    PickupLocationName: string;
    PickupLocationCountry: string;
    DropoffLocationName: string;
    DropoffLocationCountry: string;
    Name: string;
    Url: string;
    Passenger: number;
    Doors: number;
    TotalCharge: string;
    IsAirCon: string;
    IsAutomatic: string;
    Supplier: string;
    CarType: string;
    RateQualifier: string;
    SupplierCode: string;
    Currency: string;
}

export class ShoppingBasketCars extends CarBookingDtoReq implements IBookingItem {
    client: Client;
    uuid: string;
    validateMessage?: string;
    checkForm?: boolean;
    rate: Cost;
    integrationType: IntegrationType;
    detailedPricing: DetailedPricing;
    doors: number;
    isAirCon: boolean;
    isAutomatic: boolean;
    luggage: number;
    fuel: string;
    reratedPrice?: number;
    originalPrice?: number;
    response?: CarBookingResDto;
    failed?: boolean;
    isSelected?: boolean;
    canBeBooked?: boolean;
    comments?: ShoppingBasketComment;

    static initializeBasket(
        carState: CarState,
        uuid: string,
        extras: CarExtraInfo[],
        client: Client,
    ): ShoppingBasketCars {
        return {
            pickUpLocationID: safeNumber(carState.pickUpLocationID),
            dropOffLocationID: safeNumber(carState.dropOffLocationID),
            pickUpLocationName: carState.pickUpLocationName,
            dropOffLocationName: carState.dropOffLocationName,
            pickUpLocationCountry: carState.pickUpLocationCountry,
            dropOffLocationCountry: carState.dropOffLocationCountry,
            pickUpDate: carState.pickUpDate,
            dropOffDate: carState.dropOffDate,
            pickUpTime: carState.pickUpTime,
            dropOffTime: carState.dropOffTime,
            carType: carState.carType,
            rateQualifier: carState.rateQualifier,
            supplierCode: carState.supplierCode,
            supplierName: carState.supplier,
            productID: safeNumber(carState.productID, 1),
            name: carState.name,
            nameRate: carState.nameRate,
            additionalParameters: carState.additionalParameters,
            totalCharge: carState.totalCharge,
            extras: carState.extras,
            extrasInfo: extras,
            uuid,
            client: client,
            urlImage: atob(carState.url),
            driverAge: null,
            driverTitle: '',
            driverInitial: '',
            driverLastName: '',
            rate: carState.rate,
            integrationType: 'CAR',
            detailedPricing: carState.rate.detailedPricing,
            doors: carState.doors,
            isAirCon: carState.isAirCon === 'Y',
            isAutomatic: carState.isAutomatic === 'Y',
            luggage: carState.luggage,
            fuel: carState.fuel,
        };
    }

    static addPassenger(initialShoppingBasket: ShoppingBasketCars, form: CarForm): ShoppingBasketCars {
        return {
            ...initialShoppingBasket,
            driverAge: safeNumber(form.driverAge),
            driverInitial: form.driverInitial,
            driverTitle: form.driverTitle,
            driverLastName: form.driverLastName,
        };
    }

    static initializeComments(
        item: ShoppingBasketCars,
    ): ShoppingBasketCars {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.totalCharge),
        };

        return item;
    }

    static buildFromShoppingBasketByDestination(
        location: Location,
        passenger: MultidestinationPassenger,
        client: Client,
        comments: ShoppingBasketComment,
    ): ShoppingBasketCars[] {
        if (!location) { return []; }
        const { carRequest, carResponse } = location;
        if (!carRequest || !carResponse) { return []; }
        const driver =  CarBookingDriver.buildDriverFromMultidestinationPassenger(passenger);
        return this.buildShoppingBaskets(carRequest, carResponse, driver, client, comments);
    }

    static buildShoppingBaskets(
        carRequest: CarAvailabilityReq,
        carResponse: CarAvailabilityRes,
        driver: CarBookingDriver,
        client: Client,
        comments: ShoppingBasketComment,
    ): ShoppingBasketCars[] {
        if (!carResponse) { return []; }
        const { CarSet } = carResponse;
        if (!CarSet || CarSet.length === 0 ) { return []; }
        const car = CarSet[0];
        const {
            Costs: { Cost: rates },
            Doors: doors,
            IsAirCon: isAirCon,
            IsAutomatic: isAutomatic,
            Luggage: luggage,

        } = car;
        const rate = rates[0];
        const { detailedPricing } = rate;
        const partialBookingReq = CarBookingDtoReq.buildFromCarRequestAndResponse(carRequest, carResponse) as CarBookingDtoReq;

        return [{
            client,
            uuid: uuidv1(),
            rate,
            integrationType: 'CAR',
            detailedPricing,
            doors: safeNumber(doors),
            isAirCon: isAirCon === 'Y',
            isAutomatic: isAutomatic === 'Y',
            luggage: safeNumber(luggage),
            fuel: 'Gasolina',
            comments,
            ...partialBookingReq,
            ...driver,
        }];
    }

    static updateFromManagementBudget(
        item: ShoppingBasketCars,
        paxes: ManagementPax[],
    ): ShoppingBasketCars {
        const { driverTitle, driverInitial, driverLastName, driverAge } = item;
        const mappedPax = CarPaxFromManagementFactory.build(
            paxes,
            { driverTitle, driverInitial, driverLastName, driverAge },
        );
        return {
            ...item,
            ...mappedPax,
        };
    }
}

export class CarBookingRes extends ShoppingBasketCars {
    response: CarBookingResDto;
    failed = false;
}

export interface IFilterCoches {
    typeCars?: string[];
}

export class TypeCar {
    typeName: string;
    checked: boolean | null;
}

export class SizeCar {
    typeName: string;
    checked: boolean | null;
}

export class FilterCoches {
    typeCars: FilterTypeCar;
}

export class FilterTypeCar {
    type: string[];
    typeCar: string;
}

export const FILTER_COCHES = [
    {type: 'Productos', items: []},
    {type: 'Condiciones Franquicia', items: []},
    {type: 'Proveedores', items: []},
    {type: 'Plazas', items: []},
    {type: 'Puertas', items: []},
    {type: 'Aire Acondicionado', items: [{value: 'Con Aire', checked: false}, {value: 'Sin Aire', checked: false}]},
    {type: 'Transmisión', items: [{value: 'Automática', checked: false}, {value: 'Manual', checked: false}]},
    {
        type: 'Tipo de Coche',
        items: [{value: 'Car', checked: false}, {value: 'SUV', checked: false}, {value: 'Van', checked: false}, {
            value: 'Convertible',
            checked: false
        }, {value: 'Sport', checked: false}]
    },
    {
        type: 'Tamaño de Coche',
        items: [{value: 'Economy', checked: false}, {value: 'Compact', checked: false}, {
            value: 'Intermediate',
            checked: false
        }, {value: 'Standard', checked: false}, {value: 'Full Size', checked: false}, {value: 'Mini', checked: false}, {
            value: 'Premium',
            checked: false
        }, {value: 'Luxury', checked: false}, {value: 'Luxury Elite', checked: false}]
    }
];
