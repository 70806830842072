import { NgModule } from '@angular/core';
import { MatAutocompleteModule, MatInputModule, MatSelectModule, MatTabsModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ActivitiesSearchComponent } from './activities-search.component';
import { DateRangeModule } from '../../../date-range/index';
import { ActivityService } from '../../../lib-shared/services/index';
import { LibSharedModule } from '../../../lib-shared/index';
import { PaxesModule } from '../../../paxes/index';
import { ActivitySearchFilterModule } from '../activity-search-filter/index';
import { ActivitiesPaxesModule } from '../activities-paxes/index';

@NgModule({
    declarations: [
        ActivitiesSearchComponent
    ],
    imports: [
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatTabsModule,
        MatInputModule,
        MatAutocompleteModule,
        DateRangeModule,
        PaxesModule,
        ActivitySearchFilterModule,
        LibSharedModule,
        ActivitiesPaxesModule,
    ],
    exports: [
        ActivitiesSearchComponent
    ],
    providers: [
        ActivityService
    ]
})
export class ActivitiesSearchModule {
}
