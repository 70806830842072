import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: "root"
})
export class NotificationPopupService {

    constructor(private alertController: AlertController, private translate: TranslateService) {
    }

    private async presentAlert(message) {
        const alert = await this.alertController.create({
            message: message,
            buttons: ["OK"]
        });

        return await alert.present();
    }

    async openPopup(message) {
        await this.translate.get(message).subscribe(async (message) => {
            return await this.presentAlert(message);
        });
    }
}
