import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'lib-service-type-modal',
    templateUrl: './service-type-modal.component.html',
    styleUrls: ['./service-type-modal.component.scss']
})
export class ServiceTypeModalComponent implements OnInit, OnDestroy {
    unsubscribe = new Subject();
    services: any[];

    serviceControl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<ServiceTypeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.services = data['services'];
    }

    ngOnInit(): void {

    }

    closeDialog(): void {
        this.dialogRef.close(this.serviceControl.value);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
