import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, of, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { DateRangeComponent } from '../../date-range/index';
import { PackagesProviderService } from '../../lib-shared/services/packages-provider.service';
import { IFilter } from '../../lib-shared/interfaces/index';
import { packageOptionsVal } from '../../../mock/packages';
import Pax from '../../paxes/Pax';
import {HttpParams} from "@angular/common/http";

export interface PackageSearchDto {
    origin: any;
    destination: string;

    checkIn: string;
    checkOut?: string;
    nights: number;

    package: string;
    packageType: string;

    distribution?: RoomDistribution[];


    /** @ToDo eliminar variables */
    adults?: number;
    children?: number[];
    rooms?: number;
}

export class RoomDistribution {
    public rooms: number;
    public adults: number;
    public children: number[];
}

export class Autocomplete {
    public name: string;
    public city: string;
    public country: string;
    public iata: string;
    public icao: string;
    public latitude: string;
    public longitude: string;
    public altitude: string;
    public timezone: string;
    public dst: string;
    public tz_name: string;
}

@Component({
    selector: "lib-package-search",
    templateUrl: "./package-search.component.html",
    styleUrls: ["./package-search.component.scss"],
    providers: [PackagesProviderService]
})
export class PackageSearchComponent implements OnInit, AfterViewChecked, OnDestroy {

    @Input() isSmall = false;
    @Input() inputFields: any;
    @Input() filters: IFilter = {};
    @Input() routerPath = '/integration/packages/search?';
    @Input() microsite = false;
    @Input() showProviders = true;
    tabs = [];
    currentTab = 1;
    buttonPushed = 1;
    packageListReq: PackageSearchDto;
    form: FormGroup;
    packageOptions: Observable<any>;
    dateRange: DateRangeComponent;
    pax: Pax;
    destinations: any[];
    public showFilter: boolean = true;

    @Output() queryParams: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
    @Output() dataChanged = new EventEmitter<any>(); // TODO: Define type

    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public router: Router,
        private cdRef: ChangeDetectorRef,
        public dialog: MatDialog,
        private packageService: PackagesProviderService,
    ) {
        this.initFormVal();
    }

    ngOnInit(): void {
        // if (!this.isSmall) { this.selectedTab = 'CARIBE' };

        if (this.inputFields) {
            const findIndex = packageOptionsVal.findIndex(f => f.name === this.inputFields.package);
            this.currentTab = findIndex;
            this.form.get('package').setValue(this.tabs[findIndex].name);
            this.handleTab(this.currentTab);
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onDataChanged(params) {
        params.package = this.tabs[this.currentTab].name;
        const paramsUpdating = this.setFilter(params);
        this.dataChanged.emit(paramsUpdating);

        if (this.microsite) {
            this.queryParams.emit(this.packageService.getParams(paramsUpdating));
            return;
        }

        this.router.navigateByUrl(this.routerPath + this.packageService.getParams(paramsUpdating));
    }

    handleTab(index: number) {
        this.currentTab = index;
    }

    optionSelected(event) {
        const findIndex = this.tabs.findIndex(f => f.name === event.option.value.name);
        this.form.get('package').setValue(this.tabs[findIndex].name);
        this.currentTab = findIndex;
    }

    initFormVal(): void {
        this.form = this.formBuilder.group({
            'package': new FormControl('')
        });
        this.packageOptions = of(packageOptionsVal);
        this.tabs = packageOptionsVal;
    }

    setFilter(packageListReq) {

        if (this.filters && (Object.keys(this.filters).length === 0)) {
            return packageListReq;
        }

        packageListReq.filter = <any>{};

        if (this.filters.hasOwnProperty('accommodations') || this.filters.hasOwnProperty('stars')) {
            packageListReq.filter.accommodation = <any>{};
            packageListReq.filter.accommodation.type = this.filters.accommodations;
            packageListReq.filter.accommodation.category = this.filters.stars;
        }
        if (this.filters.hasOwnProperty('rates') || this.filters.hasOwnProperty('prices')) {
            packageListReq.filter.rate = <any>{};
            packageListReq.filter.rate.category = this.filters.rates;
            if (this.filters.hasOwnProperty('prices')) {
                packageListReq.filter.rate.price = <any>{};
                packageListReq.filter.rate.price.min = this.filters.prices.min;
                packageListReq.filter.rate.price.max = this.filters.prices.max;
            }
        }
        if (this.filters.hasOwnProperty('name')) {
            packageListReq.filter.name = this.filters.name;
        }
        return packageListReq;
    }

    displayFn(): string {
        return "";
    }
}
