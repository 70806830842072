import { Response } from './response';

export class TrainStationsDtoRes extends Response {
    data: { rows: TrainStation[], count: number };
}

export class TrainStation {
    id: string;
    name: string;
}
