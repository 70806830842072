import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Hotel, HotelProvider, HotelRoom, HotelListReqDto } from '@vecib2c/frontend-dto';
import { HotelSearchCardComponent } from '../hotel-search-card/hotel-search-card.component';

export interface IDataDialog {
    hotel: Hotel;
    query: any;
    selectIsAvailable: boolean;
    microsites: boolean;
}

@Component({
    selector: 'lib-item-provider-dialog',
    templateUrl: './item-provider-dialog.component.html',
    styleUrls: ['./item-provider-dialog.component.scss'],
})
export class ItemProviderDialogComponent {

    hotel: Hotel;
    providers: HotelProvider[];
    query: HotelListReqDto;
    selectedRooms: HotelRoom[] = [];
    selectedProviders: HotelProvider[] = [];
    selectIsAvailable: boolean;
    numberOfRooms = 1;
    roomsFromDifferentProviders = false;

    @Output() sendPreBook = new EventEmitter<{ provider: HotelProvider, selectedRooms: HotelRoom[], select: boolean }>();

    constructor(
        public dialogRef: MatDialogRef<HotelSearchCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDataDialog,
    ) {
        this.hotel = data.hotel;
        this.query = data.query;
        this.numberOfRooms = data.query.distribution.length;

        this.selectIsAvailable = data.selectIsAvailable;
        this.providers = this.hotel.providers.slice(1);
    }


    selectRoom(provider: HotelProvider, props: { room: HotelRoom, roomListIndex: number, roomIndex: number }) {
        if (provider.multidistribution) {
            const rate = Object.assign({}, props.room.rates[0]);
            new Array(this.numberOfRooms).fill(undefined).forEach((_, i) => {
                const foundRoom = this.hotel.roomList[i].rooms[props.roomIndex];
                this.selectedRooms[i] = Object.assign({}, foundRoom, { rates: [rate]});
                this.selectedProviders[i] = provider;
            });
        } else {
            this.selectedRooms[props.roomListIndex] = JSON.parse(JSON.stringify(props.room));
            this.selectedProviders[props.roomListIndex] = provider;
        }

        this.roomsFromDifferentProviders = this.hasDifferentProviders(this.selectedProviders);
    }

    select() {
        this.sendPreBook.emit({
            provider: this.selectedProviders[0],
            selectedRooms: this.selectedRooms,
            select: true,
        });
        this.closeDialog();
    }

    preBook() {
        this.sendPreBook.emit({
            provider: this.selectedProviders[0],
            selectedRooms: this.selectedRooms,
            select: false,
        });
        this.closeDialog();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private hasDifferentProviders(selectedProviders: HotelProvider[]): boolean {
        const providerByDefault = selectedProviders[0];
        let hasDifferentProviders = false;
        for (let i = 0; i < selectedProviders.length; i++) {
            if (
                providerByDefault.providerName !== selectedProviders[i].providerName ||
                providerByDefault.hotelId !== selectedProviders[i].hotelId
            ) {
                hasDifferentProviders = true;
                break;
            }
        }

        return hasDifferentProviders;
    }
}
