import { NgModule } from '@angular/core';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatOptionModule, MatTabsModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SkyPackageSearchComponent } from './sky-package-search.component';
import { PaxesPackageModule } from '../paxes-package/paxes-package.module';
import { PaxesModule } from '../../../paxes/index';
import { DateRangeModule } from '../../../date-range/index';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [
        SkyPackageSearchComponent
    ],
    imports: [
        LibSharedModule,
        PaxesPackageModule,
        MatTabsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        PaxesModule,
        DateRangeModule,
        IonicModule,
    ],
    exports: [
        SkyPackageSearchComponent
    ]
})
export class SkyPackageSearchModule {
}
