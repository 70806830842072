import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafesBookingComponent } from './safes-booking.component';
import { Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';

const routes: Routes = [
    {
        path: 'create',
        component: SafesBookingComponent,
    },
    {
        path: 'edit/:id',
        component: SafesBookingComponent,
    }
];

@NgModule({
    declarations: [SafesBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
        // RouterModule.forChild(routes)
    ],
    exports: [SafesBookingComponent]
})
export class SafesBookingModule {
}
