import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
    BookResDto,
    FlightBookingEntity,
    FlightBookingPostReqDto,
    FlightBookingPostResDto,
    FlightListReqDto,
    FlightListResDto,
    FlightRateUpdateReqDto,
    FlightRateUpdateResDto,
    LowCostBookReqDto,
    PrebookReqDto,
    PrebookResDto,
    PriceGroup,
} from "@vecib2c/frontend-dto";
import { map, mergeMap, reduce, switchMap, tap } from 'rxjs/operators';
import { ILocation } from "../interfaces/location.interface";
import { ManagementService } from './management.service';
import { ClientServices } from './client.services';
import { TecnoturisService } from '../../tecnoturis.service';
import { FlightRateReqMapper, IAirport, Rerate } from '../interfaces/index';
import { IntegrationsService } from "./integrations.service";
import { ShoppingBasketFlight } from "../../integrations";
import { delayedRetry } from '../functions/index';

@Injectable()
export class FlightProviderService {
    flightBooks$: BehaviorSubject<Array<any>>;
    flightBooksTotal$: BehaviorSubject<number>;

    private readonly MAX_RETRIES = 0;
    private readonly DELAY_MS = 45000;

    constructor(private http: HttpClient,
                private managementService: ManagementService,
                private clientService: ClientServices,
                private tecnoturisService: TecnoturisService,
                private integrationsService: IntegrationsService) {

        const flights = JSON.parse(localStorage.getItem("flightBooks") || null);
        this.flightBooks$ = new BehaviorSubject(flights || []);
        this.flightBooks$.subscribe(data => {
            localStorage.setItem("flightBooks", JSON.stringify(data));
        });

        this.flightBooksTotal$ = new BehaviorSubject(this.setFlightTotal());
    }

    private getPath(action: string, id: number = null): string {
        const host = this.tecnoturisService.config.flights;

        let list: any = [`${host}`, "api/v1", action];
        if (id) {
            list = [...list, id];
        }
        return list.join("/");
    }

    list(req: FlightListReqDto): Observable<FlightListResDto> {
        req.cabin = "NotSet";
        req.ndc = "SinNDC";
        const path = this.getPath("flights");
        return this.http.post<any>(path, req);
    }

    findBooking(bookingId: number): Observable<any> {
        const path = this.getPath("booking", bookingId);
        return this.http.get<any>(path);
    }

    book(reqParams: FlightBookingPostReqDto, saveBudget = true): Observable<FlightBookingPostResDto> {
        const url = this.getPath("booking");
        return this.http.post<any>(url, reqParams).pipe(tap(async response => {
            if (saveBudget) {
                const client = await this.clientService.getClient();
                const dossier = this.integrationsService.validAndGetDossier();
                const data = {flightData: [response], client: client.id, dossier: dossier};
                await this.managementService.addServicesToDossier(data).toPromise();
            }
        }));
    }

    cancelBooking(bookingId: number): Observable<any> {
        const path = this.getPath("booking", bookingId);
        return this.http.delete<any>(path);
    }

    addShoppingCart(flight) {
        const flights = JSON.parse(localStorage.getItem("flightBooks") || null);
        if (!flights) {
            this.flightBooks$.next([flight]);
        } else {
            this.flightBooks$.next([...flights, flight]);
        }
        this.flightBooksTotal$.next(this.setFlightTotal());
    }

    removeShoppingCart(bookingId: number) {
        const flights = JSON.parse(localStorage.getItem("flightBooks") || null);
        if (!flights) {
            return;
        }
        this.flightBooks$.next(flights.filter(el => el.bookingId !== bookingId));
        this.flightBooksTotal$.next(this.setFlightTotal());
    }

    setFlightTotal() {
        const flights = JSON.parse(localStorage.getItem("flightBooks"));
        const totalFlights = flights.reduce((total, el) => {
            return total + el.totalAgencyAmount;
        }, 0);
        return totalFlights;
    }

    multipleFlights(req: FlightListReqDto): Observable<FlightListResDto> {
        req.cabin = "NotSet";
        req.includedLowCost = false;
        req.includedTourOperation = false;
        req.ndc = "SinNDC";
        const path = this.getPath('multiflights');
        return this.http.post<any>(path, req);
    }

    preBookLowCost(req: PrebookReqDto): Observable<PrebookResDto> {
        const url = this.getPath("lowCostFlights/prebook");
        return this.http.post<any>(url, req);
    }

    bookLowCost(req: LowCostBookReqDto, saveBudget = true): Observable<BookResDto> {
        const url = this.getPath("lowCostFlights/book");
        return this.http.post<any>(url, req).pipe(tap(async response => {
            if (saveBudget) {
                const client = await this.clientService.getClient();
                const dossier = this.integrationsService.validAndGetDossier();
                const data = {flightData: [response], client: client.id, dossier: dossier};
                await this.managementService.addServicesToDossier(data).toPromise();
            }
        }));
    }

    lastBookingFlight(): FlightBookingEntity {
        const bookingFlights = JSON.parse(localStorage.getItem('flightBooks')) as FlightBookingEntity[];
        return bookingFlights && bookingFlights[bookingFlights.length - 1] || null;
    }

    checkRate(reqParams: FlightRateUpdateReqDto): Observable<FlightRateUpdateResDto> {
        const url = this.getPath("rates");
        return this.http.patch<FlightRateUpdateResDto>(url, reqParams).pipe(
            delayedRetry(this.DELAY_MS, this.MAX_RETRIES),
        );
    }

    rerate(reqParams: FlightRateUpdateReqDto) {
        return this.checkRate(reqParams).toPromise()
        .then(res => {
            return Rerate.buildFromFlight(res);
        })
        .catch(err => {
            return undefined;
        });
    }

    rerateFromBasket(item: ShoppingBasketFlight): Promise<Rerate> {
        const reqParams = FlightRateReqMapper.buildFromBasket(item);
        return this.rerate(reqParams);
    }

}
