import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { Itinerary2, SalesGroup } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-trains-item-card',
    templateUrl: './trains-item-card.component.html',
    styleUrls: ['./trains-item-card.component.scss']
})
export class TrainsItemCardComponent implements OnInit, OnDestroy {

    @Input() itinerary: Itinerary2;
    @Input() selectableIsDisabled: boolean;
    @Input() selectedItinerary: Itinerary2;
    @Input() selectedTrainPrice: number;
    @Input() showDifferentialPrice = false;

    changeIcon: boolean;
    dspNone: boolean;
    selectedSalesGroup: SalesGroup[];

    @Output() selectItinerary = new EventEmitter<{ salesGroup: SalesGroup, trainClass }>();

    constructor() {
    }

    ngOnInit(): void {
        this.dspNone = true;
    }

    ngOnDestroy(): void {

    }

    selectTrain(salesGroup: SalesGroup, trainClass) {
        this.selectItinerary.emit({salesGroup, trainClass});
    }

    toggleClasses() {
        this.dspNone = !this.dspNone;
        this.changeIcon = !this.changeIcon;
    }

}
