import { CarExtra } from "./booking.req.model";
import { CarCommission } from "./commission.model";

export class CarAvailabilityResDto {
    data: CarAvailabilityRes;
    status: number;
}

export class CarAvailabilityRes {
    requestId: string;
    requestHash: string;
    ReturnCode: string;
    ErrorMessage: string;
    Count: number;
    Suppliers: Supplier[];
    SupplierInfo: SupplierInfoDetail[];
    FlexibleAutosInfo: FlexibleAutosInfo;
    CarSet: Car[];
    filters: CarFilter[];
    minPrice: number;
    maxPrice: number;
}

export class CarFilter {
    type: string;
    items: CarFilterItem[];
}

export class CarFilterItem {
    value: string | number;
    checked?: boolean;
}

export class Supplier {
    Supplier: string;
    Status: string;
    Count: string;
}

export class SupplierInfoDetail {
    Supplier: string;
    Inclusions: Inclusions;
    FuelPolicy: string;
    ExcessPolicy: string;
    PickUpDetails: TravelDetail;
    DropOffDetails: TravelDetail;
    TandCURL: string;
    TermsAndConditions: TermsAndCondition;
    AvailableExtras: AvailableExtras;
}

export class AvailableExtras {
    ExtraDetails: ExtraDetail[];
}

export class ExtraDetail {
    Currency: string;
    ExtraID: string;
    Information: string;
    MaxAmount: string;
    Name: string;
    Period: string;
    Price: string;
    SupplierCode: string;
}

export class Inclusions {
    Inclusions: Inclusion[];
}

export class Inclusion {
    Product: string;
    Inclusion: string;
}

export class TravelDetail {
    GPSCoordinates: GPSCoordinate;
    LocationType: string;
    LocationInformation: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    ZipCode: string;
    PhoneNo: string;
    OpeningHours: OpeningHour;
}

export class GPSCoordinate {
    Longitude: string;
    Latitude: string;
}

export class OpeningHour {
    Mon_Open: string;
    Mon_Close: string;
    Tue_Open: string;
    Tue_Close: string;
    Wed_Open: string;
    Wed_Close: string;
    Thu_Open: string;
    Thu_Close: string;
    Fri_Open: string;
    Fri_Close: string;
    Sat_Open: string;
    Sat_Close: string;
    Sun_Open: string;
    Sun_Close: string;
}

export class TermsAndCondition {
    TandCs: TandCs[];
}

export class TandCs {
    Header: string;
    Paragraph: string;
}

export class FlexibleAutosInfo {
    FADetails: FADetails;
}

export class FADetails {
    Title: string;
    Information: Information;
}

export class Information {
    string: string[];
}

export class Car {
    Status: string;
    Category: string;
    Size: string;
    Name: string;
    Code: string;
    URL: string;
    Luggage: string;
    Passenger: string;
    Doors: string;
    Currency: string;
    TotalChargeLC: number;
    TotalCharge: number;
    Costs: Costs;
    RateQualifier: string;
    IsAirCon: string;
    IsAutomatic: string;
    CarType: string;
    CarDescription: string;
    SupplierCode: string;
    Supplier: string;
    DropCharge: string;
    DropChargeCurrency: string;
    ERP: string;
    Information: Information;
    AdditionalParameters?: string[];
    imageSupplier?: string;
    extras: CarExtra[];
    extraDetails?: ExtraDetail[];
}

export class Costs {
    Cost: Cost[];
}

export class Cost {
    Product: string;
    Price: number;
    basePrice: number;
    commission: CarCommission;
    detailedPricing: DetailedPricing;
    checked?: boolean;
}

export class DetailedPricing {
    commissionableRate: number;
    nonCommissionableRate: number;
}
