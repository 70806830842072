import { HotelBookingPostReqDtoOccupant, HotelBookingPostReqDtoOccupantType, HotelRoomDistribution } from "@vecib2c/frontend-dto";

export class HotelDistributionMapper {

    static buildFromOccupants(
        occupants: HotelBookingPostReqDtoOccupant[] = []
    ): HotelRoomDistribution {
        const obj = occupants.reduce((acc, occupant) => {
            if (occupant.type === HotelBookingPostReqDtoOccupantType.Adult) {
                return {
                    ...acc,
                    adults: acc.adults + 1,
                };
            }
            if ([HotelBookingPostReqDtoOccupantType.Child, HotelBookingPostReqDtoOccupantType.Infant].includes(occupant.type)) {
                return {
                    ...acc,
                    children: [...acc.children, occupant.age],
                };
            }
            return acc;
        }, { rooms: 1, adults: 0, children: [] } as HotelRoomDistribution);
        return obj;
    }
}
