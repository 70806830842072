import { Pipe, PipeTransform } from "@angular/core";
import { ShoppingBasketByDestination, ShoppingBasketCars } from '../../interfaces/index';

@Pipe({
    name: 'fetchCarShoppingBasketsByDestination'
})
export class FetchCarShoppingBasketsByDestination implements PipeTransform {

    transform(
        shoppingBasketsByDestination: ShoppingBasketByDestination[],
        destinationIndex: number,
    ): ShoppingBasketCars[] {
    if (!shoppingBasketsByDestination) { return; }
    const shoppingBaskets = shoppingBasketsByDestination.find(s => s.destinationIndex === destinationIndex);
    if (!shoppingBaskets) { return; }
    return shoppingBaskets.cars;
  }
}
