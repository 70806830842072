import { Component, Input } from "@angular/core";
import { ShoppingBasketCars } from '../../../lib-shared/interfaces/index';
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-cars-cart-item',
    templateUrl: './cars-cart-item.component.html',
    styleUrls: ['./cars-cart-item.component.scss']
})
export class CarsCartItemComponent {
    @Input() item: ShoppingBasketCars;

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }

    getForTimePeriod(time): string {
        if ( time === "Daily") {
            return "/ Día";
        }
    }
}
