import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { TecnoturisService } from '../../tecnoturis.service';

@Injectable()

export class EntityService {

    host;
    entity;

    constructor(
        private http: HttpClient,
        @Inject("host") public _host,
        @Inject("entity") public _entity,
        private tecnoturisService: TecnoturisService
    ) {
        this.host = _host;
        this.entity = _entity;
    }


    private getPath(path, id?) {
        const list = [`${this.host}`, this.tecnoturisService.config.defaultVersion, path];
        if (id) {
            list.push(id);
        }
        return list.join("/");
    }

    get(id: string): Observable<any> {
        return this.http.get(this.getPath(this.entity, id));
    }

    list(page?: Number, pageSize?: Number, filters?, sortOptions?): Observable<any> {
        const parameterList = [];
        if (page) {
            parameterList.push(`page=${page}`);
        }
        if (pageSize) {
            parameterList.push(`pageSize=${pageSize}`);
        }
        if (filters) {
            parameterList.push(`search=${filters}`);
        }
        /*
        Esperamos que sortOptions sea un objeto {sortField: "fieldname", sortDirection: "ASC|DESC"}
        */
        if (sortOptions) {
            parameterList.push(`sortField=${sortOptions.sortField}`);
            parameterList.push(`sortDirection=${sortOptions.sortDirection}`);
        }

        const parameters = parameterList.length > 0 ? "?" + parameterList.join("&") : "";

        return this.http
            .get<any>(this.getPath(this.entity) + parameters).pipe(map(data => {
                if (data && data.results) {
                    data.rows = [...data.results];
                    delete data.results;
                }
                return data;
            }));
    }

    create(user): Observable<any> {
        const headers = new HttpHeaders().set("Content-Type", "application/json");
        return this.http
            .post<any>(this.getPath(this.entity), JSON.stringify(user), {
                headers: headers
            });
    }

    update(id, user) {
        const headers = new HttpHeaders().set("Content-Type", "application/json");
        return this.http
            .put<any>(this.getPath(this.entity, id), JSON.stringify(user), {
                headers: headers
            });
    }

    delete(id): Observable<any> {
        return this.http.delete<any>(this.getPath(this.entity, id));
    }
}
