import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { getCurrencySymbol } from "@angular/common";
import { MonthViewDayInheritance } from './calendar-interface';

@Component({
    selector: 'lib-angular-calendar',
    templateUrl: './angular-calendar.component.html',
    styleUrls: ['./angular-calendar.component.scss']
})
export class AngularCalendarComponent implements OnInit, OnChanges {

    @Input() cellTemplatePriceMonth: TemplateRef<any>;
    @Input() cellTemplateLeft: TemplateRef<any>;
    @Input() cellTemplateRight: TemplateRef<any>;
    @Input() locale: string;
    @Input() events: CalendarEvent[] = [];
    @Input() view: CalendarView;
    @Input() viewDate: Date;
    @Input() currency = 'EUR';
    @Input() emptyDayCanBeSelected: boolean;

    clickedColumn: number;
    selectedMonthViewDay: CalendarMonthViewDay;
    selectedDays: CalendarMonthViewDay[] = [];
    refresh = new Subject();
    cssClass: any = 'cal-day-selected';

    @Output() eventDate: EventEmitter<any> = new EventEmitter<any>();

    constructor() {

    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {}

    clickedColumnEvent(event): void {
        this.clickedColumn = event;
    }

    selectedDayEvent({day}: { day: MonthViewDayInheritance }): void {
        if (!this.emptyDayCanBeSelected && day.events.length === 0) {
            return;
        }
        this.selectedDays = [];
        const validateSelection = day.cssClass === 'cal-day-selected';
        this.cssClass = validateSelection ? 'FFFFFF' : 'cal-day-selected';
        day.cssClass = this.cssClass;
        this.selectedMonthViewDay = day;
        this.selectedDays = !validateSelection ? [...this.selectedDays, this.selectedMonthViewDay] : [...this.selectedDays];
        this.refreshView();
        this.eventDate.emit(day);
    }

    beforeMonthViewRender({ body }: { body: MonthViewDayInheritance[] }): void {
        let lowestDayPerMonth: { [key: string]: MonthViewDayInheritance } = {};
    
        body.forEach(day => {
            const monthKey = `${day.date.getMonth()}_${day.date.getFullYear()}`;
    
            if (!lowestDayPerMonth[monthKey]) {
                lowestDayPerMonth[monthKey] = day;
            } else {
                const lowestPrice = lowestDayPerMonth[monthKey].events && lowestDayPerMonth[monthKey].events[0] ? lowestDayPerMonth[monthKey].events[0].price : undefined;
                const currentPrice = day.events && day.events[0] ? day.events[0].price : undefined;
                
                if (currentPrice !== undefined && (lowestPrice === undefined || currentPrice < lowestPrice)) {
                    lowestDayPerMonth[monthKey] = day;
                }
            }
            
            if (this.selectedDays.some(selectedDay => selectedDay.date.getTime() === day.date.getTime())) {
                day.cssClass = this.cssClass;
            }
            if (day.isPast === true || (day.events && day.events.length === 0)) {
                day.cssClass = 'block-date';
            }
        });
    
        Object.values(lowestDayPerMonth).forEach(lowestDay => {
            if (lowestDay) {
                lowestDay.isLowcost = true;
            }
        });
    }

    refreshView(): void {
        this.refresh.next();
    }

    getCurrency(currencyCode) {
        return getCurrencySymbol(currencyCode.trim().toUpperCase(), "narrow");
    }

    validateDay(day): boolean {
        return day.events.every(d => d.price >= 0);
    }
}
