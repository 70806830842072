import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'allAirports'
})
export class AllAirportsPipe implements PipeTransform {
    transform(airports: any[]) {
        if (!airports) {
            return;
        }
        const locations = {};
        airports.forEach((airport) => {
            const key = airport.city + "-" + airport.country + "-" + (airport.cityCode || '');
            if (locations[key]) {
                locations[key] = [...locations[key], airport.iata];
            } else {
                locations[key] = [airport.iata];
            }
        });

        Object.entries(locations).forEach((location: Array<any>) => {
            const splitLocation = location[0].split('-');
            const iatas = location[1];
            const cityCode = splitLocation[2];
            if (iatas.length > 1 && !!cityCode) {
                const allAirports = {
                    city: splitLocation[0],
                    name: 'Todos',
                    country: splitLocation[1],
                    iata: iatas.join(','),
                    cityCode: cityCode,
                };
                const indexAirport = airports.findIndex((el) => el.country === splitLocation[1]);
                airports.splice(indexAirport, 0, allAirports);
            }
        });

        return airports;
    }
}
