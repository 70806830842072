import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ShoppingBasketTrains } from "../../../lib-shared/interfaces/shopping-basket-trains.model";

@Component({
    selector: 'lib-trains-book-summary',
    templateUrl: './trains-book-summary.component.html',
    styleUrls: ['./trains-book-summary.component.scss']
})

export class TrainsBookSummaryComponent implements OnInit, OnDestroy {

    @Input() shoppingBasket: ShoppingBasketTrains;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
}
