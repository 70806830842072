import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'cleanSpaces'
})
export class CleanSpacesPipe implements PipeTransform {

    transform(value: string): string {
        const str = value.replace(/ /g, '');
        return str.trim();
    }
}
