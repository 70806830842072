import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactPerson } from '../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-contact-person',
    templateUrl: './contact-person.component.html',
    styleUrls: ['./contact-person.component.scss']
})
export class ContactPersonComponent implements OnInit, OnChanges {
    @Input() contactPersons: ContactPerson[];
    @Input() agency_provider_id: number;

    contactPersonForm: FormGroup;

    @Output() addContactPerson: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() removeContactPerson: EventEmitter<number> = new EventEmitter<number>();

    displayedColumns = ['name', 'position', 'email', 'phone', 'actions'];

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.createForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.provider) {
            if (this.contactPersonForm) {
                this.contactPersonForm.get('provider').setValue(this.agency_provider_id);
            }
        }
    }

    createForm(): void {
        this.contactPersonForm = this.fb.group({
            name: [null, Validators.required],
            position: [null, Validators.required],
            email: [null, Validators.email],
            phone: [null, Validators.required],
            agency_provider: [this.agency_provider_id, !this.agency_provider_id ? null : Validators.required],
        });
    }

    add(): void {
        this.addContactPerson.emit(this.contactPersonForm.getRawValue());
        this.contactPersonForm.reset({agency_provider: this.agency_provider_id});
    }

    remove(id: number): void {
        this.removeContactPerson.emit(id);
    }

}
