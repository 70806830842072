import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { Router } from "@angular/router";
import { Budget } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-budget-list',
    templateUrl: './budget-list.component.html',
    styleUrls: ['./budget-list.component.scss']
})
export class BudgetListComponent implements OnInit {

    displayedColumns = ['id', 'code', 'number', 'creation_date', 'total_pvp', 'actions'];

    @Input() dossier: any;
    @Input() budgets: Budget[];

    @Output() removeBudget = new EventEmitter<number>();

    fields = {
        "id": "ID",
        "code": "Codigo",
        "number": "N° de servicios",
        "creation_date": "Creacion",
        "total_pvp": "Total PVP",
        "actions": "Acciones"
    };

    constructor(
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    viewBudget(id) {
        return this.router.navigate(['intranet', 'management', 'dossier', 'budget', id]);
    }

}
