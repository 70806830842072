import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { LuggageSelectComponent } from './luggage-select.component';

@NgModule({
    declarations: [
        LuggageSelectComponent
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        MatFormFieldModule,
    ],
    exports: [
        LuggageSelectComponent
    ]
})
export class LuggageSelectModule {
}
