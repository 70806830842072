import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselImagesComponent } from './carousel-images.component';
import { NgxHmCarouselModule } from "ngx-hm-carousel";
import { FormsModule } from "@angular/forms";
import { NgxGalleryModule } from "ngx-gallery";
import { LibSharedModule } from '../lib-shared/index';

@NgModule({
    declarations: [CarouselImagesComponent],
    imports: [
        CommonModule,
        NgxHmCarouselModule,
        NgxGalleryModule,
        FormsModule,
        LibSharedModule
    ],
    exports: [CarouselImagesComponent],
    entryComponents: [CarouselImagesComponent]
})
export class CarouselImagesModule {
}
