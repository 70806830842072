import {
    HotelBookingPostReqDtoOccupant,
    HotelBookingPostReqDtoOccupantType,
} from "@vecib2c/frontend-dto";
import { calculateAge, safeNumber } from "../../functions/index";
import { ManagementPax } from "../../interfaces/management.interface";

export class HotelBookingPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialHotelPaxes: HotelBookingPostReqDtoOccupant[],
    ): HotelBookingPostReqDtoOccupant[] {
        const arr = paxes.map((pax, i) => {
            const initialHotelPax = initialHotelPaxes[i] || {} as HotelBookingPostReqDtoOccupant;
            const {
                name: initialName,
                surname: initialSurname,
            } = initialHotelPax;

            const { name, last_name: lastName, age, type, birthdate } = pax;
            return {
                age: this.buildAge(age, birthdate, type),
                name: this.buildName(name, initialName),
                surname: this.buildSurname(lastName, initialSurname),
                type: this.buildPaxType(type),
            };
        });
        return arr;
    }

    static buildName(
        name: string,
        initialName = '',
    ): string {
        if (!name) { return initialName; }
        return name;
    }

    static buildSurname(
        surname: string,
        initialSurname = '',
    ): string {
        if (!surname) { return initialSurname; }
        return surname;
    }

    private static buildPaxType(
        type: string,
    ): HotelBookingPostReqDtoOccupantType {
        if (!type) { return HotelBookingPostReqDtoOccupantType.Adult; }
        const mappedType = type.toLowerCase();
        if (['ad', 'adult'].includes(mappedType)) { return HotelBookingPostReqDtoOccupantType.Adult; }
        if (['ch', 'child'].includes(mappedType)) { return HotelBookingPostReqDtoOccupantType.Child; }
        return HotelBookingPostReqDtoOccupantType.Adult;
    }

    private static buildAge(
        age: number,
        birthdate: string,
        type: string,
    ): number {
        if (!age && birthdate) {
            const mappedAge = calculateAge(birthdate, 'YYYY-MM-DD');
            return mappedAge;
        }
        if (!age && !type) { return 30; }
        const mappedType = type.toLowerCase();
        if (['ad', 'adult'].includes(mappedType)) { return safeNumber(age, 30); }
        if (['ch', 'child'].includes(mappedType)) { return safeNumber(age, 10); }
        return 30;
    }
}
