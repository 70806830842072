import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAddEditModule } from "../../form-add-edit/index";
import { CruiseBookingComponent } from './cruise-booking.component';

@NgModule({
  declarations: [CruiseBookingComponent],
  imports: [
    CommonModule,
    FormAddEditModule
  ]
})
export class CruiseBookingModule { }
