import { IBookingItem } from "./bookingItem.interface";
import { Client } from './client.model';
import { ActivityPreBookingParameter } from "./activities/pre-booking.model";
import { ActivityBookingPutReqDto, ActivityDetailGetResDto } from "@vecib2c/frontend-dto";
import { IntegrationType } from "./integration.types";
import { roundNumber, safeNumber } from "../functions/index";
import { Rerate } from "./rerate.model";
import { ShoppingBasketComment } from "./shopping-basket.model";
import { MultidestinationPreBookingByLocation } from "./multidestination/multidestination-pre-booking.model";
import * as  uuidv1_ from 'uuid/v1';
import { t } from 'typy';
import { ActivityImageMapper } from "./activities/activity-image.mapper";
import { ActivityBookingParameterMapper } from "./activities/activity-booking-parameter.mapper";
import { ManagementPax } from "./management.interface";
import { ActivityPaxFromManagementFactory } from '../factories/activities/activity-pax-from-management.factory';

const uuidv1 = uuidv1_;

export class ShoppingBasketActivity extends ActivityPreBookingParameter implements IBookingItem {
    uuid: string;
    client: Client;
    integrationType: IntegrationType;
    bookingParameters?: ActivityBookingPutReqDto;
    checkForm?: boolean;
    validateMessage?: string;
    failed?: boolean;
    reratedPrice?: number;
    originalPrice?: number;
    isSelected?: boolean;
    canBeBooked?: boolean;
    comments?: ShoppingBasketComment;
    sessionId?: string;
    currency?: string;

    static rerate(
        product: ShoppingBasketActivity,
        reratingResponse: Rerate,
    ): ShoppingBasketActivity {
        const originalPrice = roundNumber(product.totalPrice);
        product.originalPrice = originalPrice;
        if (reratingResponse) {
            const { pvp, cancellationPolicies, rateKey, canBeBooked } = reratingResponse;
            product.reratedPrice = pvp;
            product.totalPrice = pvp;
            product.cancellationPolicies = cancellationPolicies.map(cp => {
                const { startDate, amount } = cp;
                return { dateFrom: startDate, amount };
            });
            product.bookingParameters.activities[0].rateKey = rateKey;
            product.rateKey = rateKey;
            product.canBeBooked = canBeBooked;
        }
        return product;
    }

    static buildFromShoppingBasketByDestination(
        client: Client,
        comments: ShoppingBasketComment,
        preBookingResponse: MultidestinationPreBookingByLocation,
    ): ShoppingBasketActivity[] {
        const { activityResponses } = preBookingResponse;

        return this.buildShoppingBaskets(client, comments, activityResponses);
    }

    static buildShoppingBaskets(
        client: Client,
        comments: ShoppingBasketComment,
        activityResponses: ActivityDetailGetResDto[] = [],
    ): ShoppingBasketActivity[] {
        const arr = activityResponses.map(activity => {

            const { modalities, content, code, selectedPaxes,sessionId,pickUpDate,pickUpName,excursionLanguage,currency } = activity;
            const modality = modalities[0];
            const { rates, name: modalityName, code: modalityCode } = modality;
            const rate = rates[0];
            const { rateDetails, rateCode } = rate;
            const rateDetail = rateDetails[0];
            const { rateKey, operationDates, totalAmount, paxAmounts } = rateDetail;
            const operationDate = operationDates[0];
            const { from, to, cancellationPolicies } = operationDate;
            const { name: contentName, media } = content;
            const { amount, commission: { pvp } } = totalAmount;

            const basket: ShoppingBasketActivity = {
                uuid: uuidv1(),
                client,
                rateKey,
                code,
                from,
                to,
                sessionId,
                modalityName,
                contentName,
                partialTotal: amount,
                totalPrice: pvp,
                rateCode,
                paxAmounts,
                cancellationPolicies,
                image: ActivityImageMapper.getImageURLBySize(media),
                integrationType: 'ACTIVITY',
                bookingParameters: ActivityBookingParameterMapper.build(client, from, to,rateKey, sessionId),
                comments,
                modalityCode,
                paxes: selectedPaxes, 
            };

            /* const basket: ShoppingBasketActivity = {
                uuid: uuidv1(),
                client,
                rateKey,
                code,
                from,
                to,
                currency,
                pickUpDate,
                pickUpName,
                sessionId,
                modalityName,
                contentName,
                excursionLanguage,
                partialTotal: amount,
                totalPrice: pvp,
                rateCode,
                paxAmounts,
                cancellationPolicies,
                image: ActivityImageMapper.getImageURLBySize(media),
                integrationType: 'ACTIVITY',
                bookingParameters: ActivityBookingParameterMapper.build(client, from, to,rateKey, sessionId),
                comments,
                modalityCode,
                paxes: selectedPaxes, 
            }; */

            return basket;
        });
        return arr;
    }

    static initializeComments(
        item: ShoppingBasketActivity,
    ): ShoppingBasketActivity {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.totalPrice),
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShoppingBasketActivity,
        paxes: ManagementPax[],
    ): ShoppingBasketActivity {
        const initialBookingParameters = item.bookingParameters;
        const initialPaxes = initialBookingParameters.activities[0].paxes;
        const mappedPaxes = ActivityPaxFromManagementFactory.build(paxes, initialPaxes);
        const mappedActivities = initialBookingParameters.activities.map((activity, i) => {
            if (i > 0) { return { ...activity }; }
            return {
                ...activity,
                paxes: mappedPaxes,
            };
        });
        const mappedBookingParameters: ActivityBookingPutReqDto = Object.assign({},
            initialBookingParameters,
            { activities: mappedActivities },
        );
        const obj = {
            ...item,
            bookingParameters: mappedBookingParameters,
            paxes: mappedPaxes.map(p => p.age),
        };
        return obj;
    }
}
