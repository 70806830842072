import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'lib-formly-field-file',
    template: `
        <div class="container-file-button">
            <input type="file" class="input-file" id="logoFile"
                   (change)="addFile($event)" [accept]="field.templateOptions.accept"
                   [formControl]="formControl" [formlyAttributes]="field">
            <span>{{ logo || field.templateOptions.placeholder }}</span>
            <span class="text-right">
            <label class="bcancelar" for="logoFile">{{"Seleccionar logo" }}</label>
        </span>
        </div>
    `,
})
export class FormlyFieldFile extends FieldType {

    filename;
    logo;

    addFile(event): void {
        this.filename = event.target.files[0];
        this.logo = this.filename.name;
    }
}
