import { Component, Input, OnInit } from "@angular/core";
import { FlightBookServices } from "../../hotels/services/flight-book.services";
import { NotificationPopupService } from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-flight-book-confirmation',
    templateUrl: './flight-book-confirmation.component.html',
    styleUrls: ['./flight-book-confirmation.component.scss']
})

export class FlightBookConfirmationComponent implements OnInit {

    @Input() flightBookingConfirmation;
    isCancelled: boolean;

    constructor(
        private notificationPopupService: NotificationPopupService,
        public flightBookingService: FlightBookServices
    ) {
    }

    ngOnInit() {
        this.isCancelled = false;
    }

    async cancel() {
        this.flightBookingService.cancel(this.flightBookingConfirmation.bookingId)
            .subscribe(() => {
                this.isCancelled = true;
                this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_CANCEL_BOOKING");
            });
    }
}
