import {Response} from './response';
import {Commission} from "./trains.response.dto";

export class PreBookingsDtoRes extends Response {
    data: PreBookingResponse;
}

export class PreBookingResponse {
    providerToken: string;
    amount: number;
    amountToPay: number;
    insurancePrice: number;
    limitDate: string;
    passengers: Passenger[];
    error?: string[];
    requestToken: string;
    commission?: Commission;
}

export class Passenger {
    name: string;
    firstSurname: string;
    secondSurname: string;
    email: string;
    paxType: TrainPaxType;
    documentType: TrainDocumentType;
    documentNumber: string;
    age: number;
    phone: string;
}

export type TrainPaxType = 'Adulto' | 'Niño' | 'Infante';

export type TrainDocumentType = 'DNI' | 'PAS' | 'NIE';

export interface TrainDocument {
    id: TrainDocumentType;
    value: string;
}
