import { NgModule } from '@angular/core';
import { OthersPackageSearchComponent } from './others-package-search.component';
import { DefaultPackageSearchModule } from '../default-package-search/default-package-search.module';
import {LibSharedModule} from "../../../lib-shared/index";

@NgModule({
    declarations: [OthersPackageSearchComponent],
    imports: [
        LibSharedModule,
        DefaultPackageSearchModule
    ],
    exports: [OthersPackageSearchComponent]
})
export class OthersPackageSearchModule {
}
