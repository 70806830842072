import { Injectable } from "@angular/core";
import {Observable, from} from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { TrainStationRequestDto } from '../interfaces/trains/request/stations.request.dto';
import { TecnoturisService } from '../../tecnoturis.service';
import { TrainStationsDtoRes } from '../interfaces/trains/response/stations.response.dto';
import { TrainsDtoRes } from '../interfaces/trains/response/trains.response.dto';
import { TrainsRequestDto } from '../interfaces/trains/request/trains.request.dto';
import { PreBookingsCreateRequestDto } from '../interfaces/trains/request/pre-bookings-create.request.dto';
import { PreBookingsDtoRes } from '../interfaces/trains/response/pre-bookings.response.dto';
import { BookingsCreateRequestDto } from '../interfaces/trains/request/bookings-create.request.dto';
import { BookingsDtoRes } from '../interfaces/trains/response/bookings.response.dto';
import {concatMap, map, tap} from 'rxjs/operators';
import { BookingsDeleteRequestDto } from '../interfaces/trains/request/bookings-delete.request.dto';
import { BookingsDeleteDtoRes } from '../interfaces/trains/response/bookings-delete.response.dto';
import { BasketManager } from './BasketManager';
import { Rerate, ShoppingBasketTrains, TrainCheckedRateDtoRes, TrainCheckedRateReq, TrainCheckedRateResponse } from '../interfaces/index';
import {ManagementService} from "./management.service";
import {ClientServices} from "./client.services";
import {IntegrationsService} from "./integrations.service";

@Injectable()
export class TrainsProviderService {

    constructor(
        private readonly http: HttpClient,
        private tecnoturisService: TecnoturisService,
        private readonly basketManager: BasketManager,
        private managementService: ManagementService,
        private clientService: ClientServices,
        private integrationsService: IntegrationsService
    ) {
    }

    public getTrainStations(request: TrainStationRequestDto): Observable<TrainStationsDtoRes> {
        let params = new HttpParams();

        Object.keys(request).forEach(key => {
            params = params.append(key, request[key]);
        });
        const path = this.getPath("stations");

        return this.http.get<TrainStationsDtoRes>(path, {params: params});
    }

    public listTrains(request: TrainsRequestDto): Observable<TrainsDtoRes> {
        let params = new HttpParams();

        Object.keys(request).forEach(key => {
            params = params.append(key, request[key]);
        });
        const path = this.getPath("trains");

        return this.http.get<TrainsDtoRes>(path, {params: params});
    }

    public preBookTrains(request: PreBookingsCreateRequestDto): Observable<PreBookingsDtoRes> {
        const path = this.getPath("pre-bookings");

        return this.http.post<PreBookingsDtoRes>(path, request);
    }

    public bookTrains(request: BookingsCreateRequestDto, saveBudget = false): Observable<BookingsDtoRes> {
        const path = this.getPath('bookings');

        return this.http.post<BookingsDtoRes>(path, request).pipe(tap(async response => {
            if (saveBudget) {
                if (!response.data.error) {
                    const itemsInBasket = await this.basketManager.getList();
                    const shoppingBasketTrain = itemsInBasket.find(item => item.requestToken === response.data.requestToken);
                    const { ticketReference, fileId, trainTicketUrl, safeTrainTicketUrl} = response.data;
                    const mappedShoppingBasket: ShoppingBasketTrains = Object.assign(
                        {},
                        shoppingBasketTrain,
                        {  ticketReference, fileId, trainTicketUrl, safeTrainTicketUrl }
                    );

                    const client = await this.clientService.getClient();
                    const dossier = this.integrationsService.validAndGetDossier();
                    const data = {trainData: [mappedShoppingBasket], client: client.id, dossier: dossier};
                    await this.managementService.addServicesToDossier(data).toPromise();
                }
            }
        }));
    }

    public bookFromBasket(item: ShoppingBasketTrains): Promise<ShoppingBasketTrains> {
        const bookingRequest: BookingsCreateRequestDto = Object.assign({},
            {
                passengers: item.paxes,
                providerToken: item.providerToken,
                requestToken: item.requestToken
            }
        );
        return this.bookTrains(bookingRequest).toPromise()
        .then(res => {
            if (res && res.data && !res.data.error) {
                const { ticketReference, fileId, trainTicketUrl, safeTrainTicketUrl} = res.data;
                const mappedItem = Object.assign({}, item, {  ticketReference, fileId, trainTicketUrl, safeTrainTicketUrl });
                return Promise.resolve(mappedItem);
            }
            item.failed = true;
            return Promise.reject(item);
        }).catch(err => {
            item.failed = true;
            return Promise.reject(item);
        });
    }

    public cancelBooking(request: BookingsDeleteRequestDto): Observable<BookingsDeleteDtoRes> {
        const path = this.getPath(`bookings?fileId=${request.fileId}&ticketReference=${request.ticketReference}`);
        return this.http.delete<BookingsDeleteDtoRes>(path);
    }

    public checkRate(reqParams: TrainCheckedRateReq): Observable<TrainCheckedRateDtoRes> {
        const url = this.getPath("trains/rates");
        return this.http.patch<TrainCheckedRateDtoRes>(url, reqParams);
    }

    public rerate(reqParams: TrainCheckedRateReq) {
        return this.checkRate(reqParams).toPromise()
        .then(res => {
            return Rerate.buildFromTrain(res.data);
        })
        .catch(err => {
            return undefined;
        });
    }

    public rerateFromBasket(item: ShoppingBasketTrains): Promise<Rerate>  {
        const reqParams = TrainCheckedRateReq.buildFromBasket(item);
        return this.rerate(reqParams);
    }

    private getPath(action: string, id: number = null): string {
        const host = this.tecnoturisService.config.trains;

        let list: any = [`${host}`, "api/v1", action];
        if (id) {
            list = [...list, id];
        }
        return list.join("/");
    }


}
