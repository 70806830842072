import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PackageNameEnum } from '../../../../mock/packages';
import { IFilter } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-filter-packages',
    templateUrl: './filter-packages.component.html',
    styleUrls: ['./filter-packages.component.scss'],
})
export class FilterPackagesComponent implements OnInit {

    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Input() packageName: PackageNameEnum;

    @Output() filterSelected = new EventEmitter<IFilter>();

    packageFilter: IFilter = {};
    excludingFilters: string[];

    constructor() {
    }

    ngOnInit() {
      this.excludingFilters = ['distance', 'accommodationType', 'placeInteresting'];

      if (this.packageName !== PackageNameEnum.ISLAND) {
          this.excludingFilters.push('regime');
      }
    }

    filtersEvent(filter?: IFilter) {
        if (filter && Object.keys(filter) && Object.keys(filter).length > 0) {
            this.packageFilter = filter;
        }

        this.filterSelected.emit(this.packageFilter);
    }


    filterNameEmit(name: string) {
        this.packageFilter.name = name || '';
        this.filtersEvent();
    }

}
