import { Pipe, PipeTransform } from '@angular/core';
import { PriceGroup } from '@vecib2c/frontend-dto/dist';
import { AmountByPassengerPipe } from './amount-by-passenger.pipe';

@Pipe({
    name: 'totalAmountByPassenger'
})
export class TotalAmountByPassengerPipe implements PipeTransform {

    constructor(private readonly amountByPassengerPipe: AmountByPassengerPipe) {
    }

    transform(priceGroup: PriceGroup, kindOfPassenger: string, numberOfPassenger: number): number {
        return this.amountByPassengerPipe.transform(priceGroup, kindOfPassenger) * numberOfPassenger;
    }

}
