import { NgModule } from '@angular/core';
import { CalendarPackagesComponent } from './calendar-packages.component';
import { IonicModule } from "@ionic/angular";
import { CalendarCommonModule } from "angular-calendar";
import { MatToolbarModule } from "@angular/material";
import { NgxHmCarouselModule } from "ngx-hm-carousel";
import { LibSharedModule } from '../../../lib-shared/index';
import { CarouselModule } from '../../../carousel/index';
import { AngularCalendarModule } from '../../../angular-calendar/index';

@NgModule({
    declarations: [CalendarPackagesComponent],
    imports: [
        LibSharedModule,
        AngularCalendarModule,
        MatToolbarModule,
        IonicModule,
        CalendarCommonModule,
        CarouselModule,
        NgxHmCarouselModule
    ],
    exports: [CalendarPackagesComponent],
    entryComponents: [CalendarPackagesComponent]
})
export class CalendarPackagesModule {
}
