import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getCurrencySymbol, Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
import {
  Hotel,
  HotelGetReqDto,
  HotelRoom,
  HotelRoomDistribution,
  HotelRoomPutReqDto,
  HotelProvider,
  HotelListReqDto,
  HotelRoomRate
} from '@vecib2c/frontend-dto';
import * as  uuidv1_ from 'uuid/v1';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HotelProviderService, SpinnerLoaderService, NotificationPopupService } from '../../../lib-shared/services/index';
import { CarouselImagesComponent } from '../../../carousel-images/index';
import { ShopingBasketHotel } from '../dto/ShopingBasketHotel';
import { ItemProviderDialogComponent } from '../item-provider-dialog/item-provider-dialog.component';
import { CheapestProviderPipe, ReplaceHttpsPipe } from '../../../lib-shared/pipes/index';
import { MicrositeService } from '../../../lib-shared/services/microsite.service';
import { SERVICES } from '../../../const';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {TecnoturisService} from "../../../tecnoturis.service";

const uuidv1 = uuidv1_;

@Component({
    selector: 'lib-hotel-search-card',
    templateUrl: './hotel-search-card.component.html',
    styleUrls: ['./hotel-search-card.component.scss'],
    providers: [CheapestProviderPipe, ReplaceHttpsPipe],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class HotelSearchCardComponent implements OnInit, OnDestroy {

    @Input() flightIncluded = false;
    @Input() set item(hotel: Hotel) {
        this._item = hotel;
        this.sanitizeHotel();
        this.selectRooms();
        this.setTotalPrice();
        this.setCommission();
        this.countRoomList = hotel.roomList.length;
    }
    @Input() set query(query: HotelListReqDto) {
        this._query = query;
        if (query) {
            this.urlLatitude = this.query.latitude;
            this.urlLongitude = this.query.longitude;
        }

    }
    @Input() routingOnBook = true;
    @Input() disabledButton = false;
    @Input() selectIsAvailable: boolean;
    @Input() microsites = false;
    @Input() flightPrice: number;
    @Input() safePrice: number;
    @Input() preBookingIsAvailable: boolean;
    @Input() showMoreProvidersIsAvailable: boolean;
    @Input() showMoreRoomsIsAvailable: boolean;
    @Input() selectedHotel: Hotel;
    @Input() selectedHotelPrice: number;

    get item(): Hotel {
        return this._item;
    }

    get query(): HotelListReqDto {
        return this._query;
    }

    currency = '€';
    description = false;
    rooms = false;
    othersrooms = false;
    mediumDate: String;
    loading = true;
    photos = false;
    maps = false;
    changeIcon: boolean;
    hideMoreRooms: boolean;
    totalPrice: number;
    commission: any;
    countRoomList: number;
    currentCancellationPolicyFailed: boolean;
    refundable: boolean;
    urlLatitude: number;
    urlLongitude: number;
    farHotelSelected: any = 20;
    roomNames: string[];
    selectedRooms: HotelRoom[] = [];
    distribution: HotelRoomDistribution[] = [];
    hoverPrice = false;

    @Output() hotelSelected: EventEmitter<Hotel[]> = new EventEmitter<Hotel[]>();
    @Output() farHotel = new EventEmitter<any>();
    @Output() selectHotel: EventEmitter<Hotel> = new EventEmitter<Hotel>();
    @Output() cancellationPolciesFailed: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _item: Hotel;
    private _query: HotelListReqDto;
    private unsubscribe = new Subject();

    constructor(
        public dialog: MatDialog,
        public router: Router,
        public location: Location,
        private hotelService: HotelProviderService,
        protected cheapestProvider: CheapestProviderPipe,
        public spinnerLoader$: SpinnerLoaderService,
        private micrositeService: MicrositeService,
        private readonly notificationService: NotificationPopupService,
        private readonly replaceHttpsPipe: ReplaceHttpsPipe,
        public tecnoturisService: TecnoturisService
    ) {
        this.currentCancellationPolicyFailed = true;
    }

    ngOnInit(): void {
        this.setRoomsCancellationPolicies();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onLoad() {
        this.loading = false;
    }

    getCategory(category): Array<Number> | void {
        if (category === undefined) {
            return;
        }
        const regexNumber = /\d+/;

        if (!category || !regexNumber.test(category.value)) {
            return;
        } else {
            const value = category.value.match(regexNumber)[0];
            const integerValue = parseInt(value, 10);
            return new Array(integerValue);
        }
    }

    async preBook(hotel: Hotel, provider: HotelProvider, selectedRooms: HotelRoom[], distributions: HotelRoomDistribution[]) {

        const hotelsToBook = [];
        const uuid = uuidv1();

        const providerName = provider.providerName;
        const hotelGetReqParameters = this.buildHotelGetReqDto(this.query, providerName);
        const checkRateParameters = this.buildCheckRateParameters(
            this.query,
            provider.hotelId,
            providerName,
            (provider as any).requestToken,
        );

        if (provider.multidistribution) {
            const rateKey = selectedRooms[0].rates[0].rateId;
            const roomRate = await this.hotelService.checkRate(rateKey, checkRateParameters).toPromise();

            const commissionPrice = Number(selectedRooms[0].rates[0].price.toFixed(2));
            const shoppingBasket = this.buildHotelShoppingBasket(
                hotel,
                selectedRooms,
                distributions,
                roomRate,
                commissionPrice,
                hotelGetReqParameters,
                providerName,
            );
            hotelsToBook.push(shoppingBasket);
            const navigationExtras: NavigationExtras = {
                state: {
                    hotel: hotelsToBook,
                    indexDistribution: 0
                }
            };

            if (this.routingOnBook) {
                if (this.micrositeService.isMicrosite()) {
                    this.micrositeService.setBookingData(SERVICES.HOTEL, {uuid, navigationExtras, item: this.item});
                    return;
                } else {
                    this.router.navigate(['integration', 'bookings', 'book-edit', 'hotel', uuid], navigationExtras);
                }
            } else {
                this.hotelSelected.emit(hotelsToBook);
            }

        } else {
            const promises: Promise<any>[] = [];
            for (let i = 0; i < selectedRooms.length; i++) {
                const rateKey = selectedRooms[i].rates[0].rateId;
                const checkRatePromise = this.hotelService.checkRate(rateKey, checkRateParameters).toPromise().then(response => {
                    const commissionPrice = Number(selectedRooms[i].rates[0].price.toFixed(2));
                    const shoppingBasket = this.buildHotelShoppingBasket(
                        hotel,
                        [selectedRooms[i]],
                        [distributions[i]],
                        response,
                        commissionPrice,
                        hotelGetReqParameters,
                        providerName,
                    );
                    hotelsToBook.push(shoppingBasket);
                });
                promises.push(checkRatePromise);
            }
            Promise.all(promises).then(() => {
                const navigationExtras: NavigationExtras = {
                    state: {
                        hotel: hotelsToBook,
                        indexDistribution: 0
                    }
                };
                if (this.routingOnBook) {
                    if (this.micrositeService.isMicrosite()) {
                        this.micrositeService.setBookingData(SERVICES.HOTEL, {uuid, navigationExtras, item: this.item});
                        return;
                    } else {
                        this.router.navigate(['integration', 'bookings', 'book-edit', 'hotel', uuid], navigationExtras);
                    }
                } else {
                    this.hotelSelected.emit(hotelsToBook);
                }
            }).catch(e => {
                this.notificationService.openPopup('No se puede continuar con la reserva');
            });
        }
    }

    buildCheckRateParameters(query, hotelId: string, providerName: string, requestToken: string): HotelRoomPutReqDto {
        return {
            checkIn: this.query.checkIn,
            checkOut: this.query.checkOut,
            hotelId: hotelId.toString(),
            providerName: providerName,
            requestToken: requestToken,
            language: undefined,
            cancellationPolicies: undefined,
        };
    }

    buildHotelGetReqDto(query: HotelListReqDto, providerName: string): HotelGetReqDto {
        const hotelGetReqDto = new HotelGetReqDto();
        hotelGetReqDto.checkIn = query.checkIn;
        hotelGetReqDto.checkOut = query.checkOut;
        hotelGetReqDto.distribution = query.distribution;
        hotelGetReqDto.providerName = providerName;
        hotelGetReqDto.nationality = query.nationality;

        return hotelGetReqDto;
    }

    buildHotelShoppingBasket(
        hotel,
        selectedRooms: HotelRoom[],
        distributions: HotelRoomDistribution[],
        roomRate: HotelRoomRate,
        commissionPrice: number,
        hotelGetReqParameters: HotelGetReqDto,
        providerName: string,
    ): ShopingBasketHotel {
        const shoppingBasket: ShopingBasketHotel = JSON.parse(JSON.stringify(hotel));
        shoppingBasket.roomList = selectedRooms.map((r, i) => {
            const mappedRate = Object.assign({}, r.rates[0], {
                rateId: roomRate.rateId,
                price: commissionPrice,
                rateCode: roomRate.rateCode,
            });
            if (roomRate.cancellationPolicies) {
                mappedRate.cancellationPolicies = roomRate.cancellationPolicies;
            }
            const mappedRoom = Object.assign({}, r, {rates: [mappedRate]});
            return {
                rooms: [mappedRoom],
                distribution: distributions[i],
            };
        });

        shoppingBasket.hotelGetReq = hotelGetReqParameters;
        shoppingBasket.distributionSelected = distributions[0];
        shoppingBasket.uuid = uuidv1();
        shoppingBasket.providerName = providerName;
        shoppingBasket.providerHasMultidistributionOption = hotel.providers[0].multidistribution;
        shoppingBasket.integrationType = 'HOTEL';

        return shoppingBasket;
    }

    getNumber(phones) {
        if (phones !== undefined && phones.length > 0 && phones[0].number) {
            return phones[0].number;
        } else {
            return '';
        }
    }

    gestionCard(nameElement) {
        switch (nameElement) {
            case 'othersrooms':
                this.othersrooms = !this.othersrooms;
                this.photos = false;
                this.maps = false;
                this.description = false;
                break;

            case 'maps':
                this.maps = !this.maps;
                this.photos = false;
                this.othersrooms = false;
                this.description = false;
                break;

            case 'photos':
                this.openDialog();
                break;

            case 'description':
                this.description = !this.description;
                this.maps = false;
                this.othersrooms = false;
                this.photos = false;
                break;
        }

    }

    getKm(lat1: number, lon1: number, lat2: any, lon2: any): number | string {
        const rad = function (x) {
            return x * Math.PI / 180;
        };
        const R = 6378.137;
        const dLat = rad(lat2 - lat1);
        const dLong = rad(lon2 - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(lat1)) * Math.cos(rad(lat2)) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d.toFixed(3);
    }

    getIntegerPart(price): Number {
        const _price = this.flightPrice ? price + this.flightPrice + this.safePrice : price;
        const stringGetPrice = _price.toString();
        if (stringGetPrice.indexOf('.') > -1) {
            return stringGetPrice.substring(0, stringGetPrice.indexOf('.') + 1);
        } else {
            return price;
        }
    }

    getDecimalPart(price): String {
        price = Number(price);
        const stringGetPrice = price.toFixed(2).toString();
        if (stringGetPrice.indexOf('.') > -1) {
            return stringGetPrice.substring(
                stringGetPrice.indexOf('.') + 1,
                stringGetPrice.length
            );
        } else {
            return '.00';
        }
    }

    openDialog() {
        const photoArr = [];

        (this.item.photos as any).forEach((photo, index) => {
            if (index !== 0) {
                const mappedFileName = this.replaceHttpsPipe.transform(photo.fileName);
                photoArr.push({
                    small: mappedFileName,
                    medium: mappedFileName,
                    big: mappedFileName,
                });
                const image = new Image();
                image.onload = (source) => {};
                image.src = mappedFileName;
            }
        });

        const dialogRef = this.dialog.open(CarouselImagesComponent, {
            panelClass: [ 'custom-dialog-container', 'custom-dialog-gallery-hotel' ],
            width: '40%'
        });

        dialogRef.componentInstance.galleryImages = photoArr;
    }

    getCurrency(currencyCode) {
        return getCurrencySymbol(currencyCode, 'narrow');
    }

    open(id) {
        this.router.navigateByUrl('/integration/hotels/detail/' + id);
    }

    showMoreProviders() {
        const dialogRef = this.dialog.open(ItemProviderDialogComponent, {
            panelClass: 'dialog_multilogin_xl',
            data: { hotel: this.item, query: this.query, selectIsAvailable: this.selectIsAvailable, microsites: this.microsites }
        });

        const subscribeDialogPreBook = dialogRef.componentInstance.sendPreBook.subscribe(({provider, selectedRooms, select}) => {
            if (!select) {
                this.preBook(this.item, provider, selectedRooms, this.distribution);
            } else {
                const mappedHotel = Object.assign({}, this.item, { providers: [provider] });
                this.select(mappedHotel, selectedRooms, this.distribution);
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            subscribeDialogPreBook.unsubscribe();
        });
    }

    isActiveMap(): boolean {
        return this.maps;
    }

    setRoomsCancellationPolicies() {
        const rooms = this.selectedRooms.map(room => {
            if (room.rates[0].cancellationPolicies.length < 1) {
                room.rates[0].cancellationPolicies = [];
            } else {
                this.currentCancellationPolicyFailed = false;
                this.cancellationPolciesFailed.emit(this.currentCancellationPolicyFailed);
            }
            return room.rates[0];
        });

        rooms.forEach((room, i) => {
            if (this.item.providers[0].multidistribution && i > 0) { return; }

            if (room.cancellationPolicies.length < 1) {
                // this.spinnerLoader$.disableSpinner();
                const hotelRoomPutReqDto = new HotelRoomPutReqDto();
                hotelRoomPutReqDto.checkIn = this.query.checkIn;
                hotelRoomPutReqDto.checkOut = this.query.checkOut;
                hotelRoomPutReqDto.hotelId = this.item.hotelId.toString();
                hotelRoomPutReqDto.providerName = this.item.providers[0].providerName;
                hotelRoomPutReqDto.requestToken = (this.item.providers[0] as any).requestToken;
                this.hotelService.checkRate(room.rateId, hotelRoomPutReqDto)
                .pipe(
                    takeUntil(this.unsubscribe)
                ).subscribe(
                    data => {
                        if (data.cancellationPolicies) {
                            Object.keys(data.cancellationPolicies).forEach(key => {
                                this.selectedRooms[i].rates[0].cancellationPolicies.push(data.cancellationPolicies[key]);
                            });
                            this.currentCancellationPolicyFailed = false;
                            this.cancellationPolciesFailed.emit(this.currentCancellationPolicyFailed);
                        } else {
                            this.currentCancellationPolicyFailed = true;
                            this.cancellationPolciesFailed.emit(this.currentCancellationPolicyFailed);
                        }
                    },
                    err => {
                        this.currentCancellationPolicyFailed = true;
                        this.cancellationPolciesFailed.emit(this.currentCancellationPolicyFailed);
                    },
                    () => { // complete
                        // this.spinnerLoader$.enableSpinner();
                    }
                );
            }
        });
    }

    selectRoom(props: { room: HotelRoom, roomListIndex: number, roomIndex: number }) {

        if (this.item.providers[0].multidistribution) {
            this.selectedRooms = this.selectedRooms.map((r, i) => {
                const rate = Object.assign({}, props.room.rates[0]);
                const foundRoom = this.item.roomList[i].rooms[props.roomIndex];
                if (!foundRoom) {
                    return;
                }
                return {...foundRoom, rates: [rate]};
            });
        } else {
            this.selectedRooms = this.selectedRooms.map((r, i) => {
                return i === props.roomListIndex ? {...props.room} : r;
            });
        }


        this.setTotalPrice();
        this.setCommission();
        this.setRoomsCancellationPolicies();
        if (this.selectIsAvailable) {
            this.select(this.item, this.selectedRooms, this.distribution);
        }
    }

    setTotalPrice() {
        this.totalPrice = 0;
        this.totalPrice = this.selectedRooms.reduce((acc, cur) => acc + Number(cur.rates[0].price), 0);
    }

    setCommission() {
        if (this.selectedRooms.length > 0) {
            this.commission = this.selectedRooms[0].rates[0].commission;
        }
    }

    select(hotel: Hotel, selectedRooms: HotelRoom[], selectedDistribution: HotelRoomDistribution[]) {
        if (this.micrositeService.isMicrosite()) {
            this.micrositeService.setBookingData(SERVICES.HOTEL, this.item);
        } else {
            const mappedRoomsList = selectedRooms.map((selectedRoom, index) => {
                return {
                    distribution: selectedDistribution[index],
                    rooms: [{...selectedRoom, rates: [selectedRoom.rates[0]] }],
                };
            });
            const mappedHotel = Object.assign({}, hotel, { roomList: mappedRoomsList } );
            this.selectHotel.emit(mappedHotel);
        }
    }


    private sanitizeHotel(): void {
        if (this.item.address && this.item.address.indexOf(' ') === -1) {
            this.item.address = this.item.address.substring(0, 40);
        }

        if (this.item.email && this.item.email.indexOf(' ') === -1) {
            this.item.email = this.item.email.substring(0, 40);
        }
        if (this.item.photos && this.item.photos.length === 0) {
            this.item.photos = Array(
                '/assets/images/img_General_Detalle_Hotel.jpg'
            );
        }
    }

    private selectRooms(): void {
        this.hideMoreRooms = this.item.roomList.every(list => list.rooms.length === 1);

        this.selectedRooms = this.item.roomList.reduce((acc, cur) => {
          return [...acc, cur.rooms[0]];
        }, []);

        this.distribution = this.item.roomList.reduce((acc, cur) => {
          return [...acc, cur.distribution];
        }, []);
    }
}
