import { Component, OnInit } from '@angular/core';
import {trainFormMetaData} from "./train-booking.form";
import {TecnoturisService} from "../../tecnoturis.service";

@Component({
  selector: 'lib-train-booking',
  templateUrl: './train-booking.component.html',
  styleUrls: ['./train-booking.component.scss']
})
export class TrainBookingComponent implements OnInit {
  trainCrud = trainFormMetaData;

  constructor(private tecnoturisService: TecnoturisService) {
    if (this.tecnoturisService.config.forms.flightForm) {
      this.trainCrud = this.tecnoturisService.config.forms.trainForm;
    }
  }

  ngOnInit() {
  }

}
