import { Pipe, PipeTransform } from "@angular/core";
import { PassengerType } from "../../interfaces/multidestination/multidestination-pre-booking.model";

@Pipe({
    name: 'displayPassengerTypeForMultidestination'
})
export class DisplayPassengerTypeForMultidestination implements PipeTransform {

  transform(passengerType: PassengerType): string {
    if (!passengerType) { return; }
    if (passengerType === 'Adult') { return 'Adulto'; }
    if (passengerType === 'Child') { return 'Niño'; }
    if (passengerType === 'Infant') { return 'Infante'; }
    return;
  }
}
