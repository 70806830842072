import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'lib-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})

export class MessagesComponent implements OnInit {
  @Input() messageMain: string;
  @Input() messageSecondary: string;

  constructor() { }

  ngOnInit() {
    if ( this.messageMain === "" || this.messageMain === undefined ) {
      this.messageMain = 'El proceso no se ha completado';
    }

    if ( this.messageSecondary === "" || this.messageSecondary === undefined ) {
      this.messageSecondary = 'Vuelva a realizar la búsqueda';
    }
  }
}
