import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MultidestinationServicesSummaryComponent } from './services-summary.component';
import { MatCardModule } from '@angular/material';
import { LibSharedModule } from '../../lib-shared/lib-shared.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    MultidestinationServicesSummaryComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    LibSharedModule,
    IonicModule,
  ],
  exports: [
    MultidestinationServicesSummaryComponent,
  ]
})
export class MultidestinationServicesSummaryModule {

}
