import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";
import {
    BookingProviderResDto,
    InsuranceBookReqDto,
    InsuranceExpandableToppings,
    InsurancesBookingPostResDto,
    InsuranceSearchDto,
    ListCountryResDto,
    PoliciesRecheckReq,
    PoliciesRecheckRes,
    PolicyDto,
} from "@vecib2c/frontend-dto";
import { ClientServices } from './client.services';
import { ManagementService } from './management.service';
import { TecnoturisService } from '../../tecnoturis.service';
import { IntegrationsService } from "./integrations.service";
import { PolicyCheckedRateReq, PolicyCheckedRateRes } from "../interfaces/index";
import { ShopingBasketInsurance } from "../../integrations/insurances/dto/ShopingBasketInsurance";
import { Rerate } from "../interfaces/rerate.model";
import { t } from 'typy';

@Injectable({
    providedIn: 'root',
})
export class InsurancesProviderService {
    language = "ES";

    constructor(
        private http: HttpClient,
        private managementService: ManagementService,
        private clientService: ClientServices,
        private tecnoturisService: TecnoturisService,
        private integrationsService: IntegrationsService
    ) {
    }

    private getPath(action) {
        const list = [`${this.tecnoturisService.config.insurance}`, action];
        return list.join("/");
    }

    list(req: InsuranceSearchDto): Observable<PolicyDto> {
        let params: HttpParams = new HttpParams();
        Object.keys(req).forEach(function (key) {
            const value = key === 'filter' || typeof req[key] === 'object' ? JSON.stringify(req[key]) : req[key];
            params = params.append(key, value);
        });
        const path = this.getPath("policies/list");
        const url = path;
        return this.http.get<any>(url, {params: params});
    }

    onChangePrice(req: PoliciesRecheckReq): Observable<PoliciesRecheckRes> {
        const path = this.getPath("policies/recheck");
        const url = path;
        return this.http.post<any>(url, {body: {req}});
    }

    listResidences(): Observable<ListCountryResDto[]> {
        const path = this.getPath("countries/list");
        const url = path;
        return this.http.get<any>(url);
    }

    book(reqParams: InsuranceBookReqDto, saveBudget = true): Observable<BookingProviderResDto> {
        const url = this.getPath("booking");
        return this.http.post<any>(url, reqParams).pipe(tap(async response => {
            if (saveBudget) {
                const client = await this.clientService.getClient();
                const dossier = this.integrationsService.validAndGetDossier();
                const data = {safeData: [response], client: client.id, dossier: dossier};
                await this.managementService.addServicesToDossier(data).toPromise();
            }
        }));
    }

    cancel(reqParams: InsurancesBookingPostResDto) {
        const path = this.getPath("insurances/");
        const url = path;
        return this.http.delete<any>(url + reqParams.codeBook.id);
    }

    download(bookingId: string) {
        const path = this.getPath("booking/");
        const url = path + bookingId + '/download';
        return this.http.get(url, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
            },
        });
    }

    checkRate(reqParams: PolicyCheckedRateReq, policyId: string): Observable<PolicyCheckedRateRes> {
        const url = this.getPath("policies/") + policyId + '/checkedRate';
        return this.http.patch<PolicyCheckedRateRes>(url, reqParams);
    }

    rerate(
        reqParams: PolicyCheckedRateReq,
        policyId: string,
    ): Promise<Rerate> {
        return this.checkRate(reqParams, policyId).toPromise()
        .then(res => {
            return Rerate.buildFromInsurance(res);
        })
        .catch(err => {
            return undefined;
        });
    }

    rerateFromBasket(item: ShopingBasketInsurance): Promise<Rerate> {
        const reqParams = PolicyCheckedRateReq.buildFromBasket(item);
        return this.rerate(reqParams, item.insuranceBookingPostReqDto.policyId + '');
    }
}
