import { NgModule } from "@angular/core";
import { LibSharedModule } from "../../../lib-shared/index";
import { TransportsPriceSummaryComponent } from "./transports-price-summary.component";

@NgModule({
    declarations: [
        TransportsPriceSummaryComponent
    ],
    imports: [
        LibSharedModule,
    ],
    exports: [
        TransportsPriceSummaryComponent
    ]
})
export class TransportsPriceSummaryModule {

}
