import { NgModule } from '@angular/core';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FormlyModule } from '@ngx-formly/core';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyFieldFile } from "./form-file/formly-field-file";
import { FileValueAccessor } from "./form-file/file-value-accesor";
import { FileValidator } from "./form-file/file-input-validator";
import { NgxMaskModule } from 'ngx-mask';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatePickerModule } from '../date-picker/index';
import { DateAdapterModule } from '../lib-shared/date-adapter.module';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {AutocompleteTypeComponent} from "./autocomplete/autocomplete-type.component";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material';
import { SearchSelectComponent } from './search-select/search-select.component';
import { LibSharedModule } from '../lib-shared/lib-shared.module';

@NgModule({
    declarations: [
        DatetimePickerComponent,
        FormlyFieldFile,
        FileValueAccessor,
        FileValidator,
        DatePickerComponent,
        AutocompleteTypeComponent,
        SearchSelectComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMatTimepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        NgxMatDatetimePickerModule,
        FormlyModule,
        DateAdapterModule,
        NgxMaskModule.forRoot({ validation: true }),
        DatePickerModule,
        MatAutocompleteModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        LibSharedModule,
    ],
    exports: [
        DatetimePickerComponent,
        FormlyFieldFile,
        FileValueAccessor,
        FileValidator,
        DatePickerComponent,
        AutocompleteTypeComponent,
        SearchSelectComponent,
    ],
})
export class CustomFormlyTemplatesModule {
}
