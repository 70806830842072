import { Pipe, PipeTransform } from '@angular/core';
import { CardResponsiveProperties } from '../interfaces/webpage.interface';

@Pipe({
    name: 'getCardsPerRow'
})
export class GetCardsPerRowPipe implements PipeTransform {

    transform(cards: CardResponsiveProperties[], row: number, isHome: boolean): CardResponsiveProperties[] {
        return cards.filter(c => c.row === row).sort((a, b) => {
            if (isHome) {
                return a.homeOrder - b.homeOrder;
            } else {
                return a.order - b.order;
            }
        });
    }

}
