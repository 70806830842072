import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Hotel, HotelListReqDto, PriceGroup } from '@vecib2c/frontend-dto/dist';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'lib-hf-hotels-list',
    templateUrl: './hf-hotels-list.component.html',
    styleUrls: ['./hf-hotels-list.component.scss']
})
export class HfHotelsListComponent implements OnChanges {
    @Input() hotels: Hotel[];

    @Output() doBook: EventEmitter<Hotel> = new EventEmitter<Hotel>();

    @Input() disabledButton = false;

    @Input() flightSelected: PriceGroup;

    @Input() preBookingIsAvailable = true;

    params: HotelListReqDto;

    flightPrice: number;
    @Input() safeRequired: any;
    safePrice: number;

    @Input() microsites = false;

    constructor(private route: ActivatedRoute) {
        this.params = this.buildHotelQueryFromQueryParams(this.route.snapshot.queryParams);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.flightSelected && changes.safeRequired) {
            if (this.flightSelected && this.safeRequired) {
                this.flightPrice = this.flightSelected.total;
                this.safePrice = this.safeRequired.rows[0].totalPrice;
            } else {
                this.flightPrice = null;
                this.safePrice = null;
            }
        }
    }

    buildHotelQueryFromQueryParams(queryParams: Params): HotelListReqDto {
        const listReq = new HotelListReqDto();
        for (const key in queryParams) {
            if (key === 'distribution') {
                listReq[key] = JSON.parse(queryParams.distribution);
            } else {
                listReq[key] = queryParams[key];
            }
        }

        return listReq;
    }

}
