import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'lib-steps-line',
    templateUrl: './steps-line.component.html',
    styleUrls: ['./steps-line.component.scss'],
})
export class StepsLineComponent implements OnInit {
    @Input() active = 0;

    constructor() {
    }

    ngOnInit() {
    }


}
