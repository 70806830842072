import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
    name: 'setItineraryDate'
})
export class SetItineraryDatePipe implements PipeTransform {

    transform(date: string, index: number): string {
        return moment(date, 'DD/MM/YYYY').add(index, 'days').format('DD/MM/YYYY').toString();
    }

}
