import { Component, OnInit } from '@angular/core';
import { circuitForm } from './circuit.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-circuits-booking',
    templateUrl: './circuits-booking.component.html',
    styleUrls: ['./circuits-booking.component.scss']
})
export class CircuitsBookingComponent implements OnInit {
    circuitCrud = circuitForm;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.circuitForm) {
            this.circuitCrud = this.tecnoturisService.config.forms.circuitForm;
        }
    }

    ngOnInit() {
    }

}
