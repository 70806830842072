import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class MessageProviderService {

  constructor(public dialog: MatDialog) {
  }

  showModal(component, config) {
    return this.dialog.open(component, config);
  }

  showModalPromise(component, dialogData, width?) {
    return this.dialog.open(component, {width: (width ? width : '800px'), disableClose: false, data: dialogData}).afterClosed().toPromise();
  }
}
