import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './components/list/list.component';
import { SelectedComponent } from './components/selected/selected.component';
import { CircuitsFlightsExpansionPanelComponent } from './circuits-flights-expansion-panel.component';
import { MatExpansionModule, MatRadioModule } from '@angular/material';
import { IonicModule } from '@ionic/angular';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [
        CircuitsFlightsExpansionPanelComponent,
        ListComponent,
        SelectedComponent
    ],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatRadioModule,
        IonicModule,
        LibSharedModule
    ],
    exports: [
        CircuitsFlightsExpansionPanelComponent,
        ListComponent,
        SelectedComponent
    ]
})
export class CircuitsFlightsExpansionPanelModule {
}
