import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientAutocompleteComponent } from './client-autocomplete.component';
import { LibSharedModule } from '../lib-shared/index';
import { MatCardModule } from '@angular/material/card';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { DossierPerClientsModule } from '../dossier-per-clients/dossier-per-clients.module';

@NgModule({
    declarations: [ClientAutocompleteComponent],
    imports: [
        CommonModule,
        LibSharedModule,
        MatCardModule,
        IonicModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatSelectModule,
        DossierPerClientsModule
    ],
    exports: [ClientAutocompleteComponent]
})
export class ClientAutocompleteModule {
}
