import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeComponent } from "./date-range.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { MatFormFieldModule, MatIconModule, MatInputModule } from "@angular/material";

@NgModule({
    declarations: [
        DateRangeComponent
    ],
    imports: [
        CommonModule,
        NgbDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    exports: [
        DateRangeComponent
    ]
})
export class DateRangeModule {
}
