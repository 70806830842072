import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecnoturisNavbarComponent } from './tecnoturis-navbar.component';
import { MatBadgeModule, MatMenuModule, MatToolbarModule } from '@angular/material';
import { ShoppingCartSummaryModule } from '../../../shopping-cart-summary/shopping-cart-summary.module';

@NgModule({
    declarations: [
        TecnoturisNavbarComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatBadgeModule,
        MatMenuModule,
        ShoppingCartSummaryModule
    ],
    exports: [
        TecnoturisNavbarComponent
    ]
})
export class TecnoturisNavbarModule {
}
