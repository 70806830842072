import { Component, Input, OnInit } from '@angular/core';
import { ShopingBasketInsurance } from '../../../integrations/insurances/index';
import { safeNumber } from '../../../lib-shared/functions/index';
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-insurance-cart-item',
    templateUrl: './insurance-cart-item.component.html',
    styleUrls: ['./insurance-cart-item.component.scss']
})
export class InsuranceCartItemComponent implements OnInit {

    @Input() set item(item: ShopingBasketInsurance) {
        this._item = item;

        if (item) {
            this.totalPrice = safeNumber(item.insurance.totalPrice) + safeNumber(item.insurance.toppingsPrice);
        }
    }

    get item(): ShopingBasketInsurance {
        return this._item;
    }

    totalPrice = 0;

    private _item: ShopingBasketInsurance;

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }

    ngOnInit() {

    }
}
