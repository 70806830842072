import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { MunicipalityService } from '../lib-shared/services/municipality.service';
import { IMunicipality } from '../lib-shared/interfaces/index';

@Component({
    selector: 'lib-municipality-autocomplete',
    templateUrl: './municipality-autocomplete.component.html',
    styleUrls: ['./municipality-autocomplete.component.scss'],
})
export class MunicipalityAutocompleteComponent implements OnInit, OnDestroy {

    @Input() label: string;
    @Input() placeholder: string;
    @Input() defaultValue: string;

    @Output() select = new EventEmitter<any>();

    @ViewChild('autocompleteInput', { static: false }) autocompleteInput: ElementRef;

    options$: Observable<any>;

    constructor(
        private readonly muncipalityService: MunicipalityService,
    ) {

    }

    ngOnInit(): void {
        this.options$ =
            fromEvent(this.autocompleteInput.nativeElement, 'keyup')
                .pipe(
                    map((event: any) => event.target.value),
                    debounceTime(800),
                    distinctUntilChanged(),
                    filter(value => value.length > 3),
                    switchMap(search => this.muncipalityService.search(search)),
                );
    }

    ngOnDestroy(): void {

    }

    displayFn = (municipality: IMunicipality) => {
        this.select.emit(municipality);
        return municipality ? (municipality.code + " - " + municipality.name) : undefined;
    }
}
