import {FormlyFieldConfig} from '@ngx-formly/core';
import * as moment_ from 'moment';
import {CRUD_TYPE, Field, FieldType, IDynamicCrud} from './lib-shared/interfaces/index';
import {AuthenticationService} from "./lib-shared/services/index";
import { MatPaginatorIntl } from '@angular/material';

const moment = moment_;

export class DynamicCrudUtils {
    static generateFormlyConfig(dynamicCrud: IDynamicCrud, crud_type?: CRUD_TYPE): FormlyFieldConfig[] {
        const fieldsFormly = [];

        dynamicCrud.fields.forEach(f => {
            if (f.isGroup) {
                if (f.name) {
                    fieldsFormly.push({
                        className: '',
                        template: `<h3>${f.name.toUpperCase()}</h3>`,
                    });
                }
            }
            fieldsFormly.push(DynamicCrudUtils.generateFormlyField(f));
        });
        return fieldsFormly;
    }

    static generateFormlyField(fieldf: Field) {
        const generateInputType = (type: FieldType) => {
            let result;
            switch (type) {
                case FieldType.CHAR:
                    result = 'input';
                    break;
                case FieldType.CHECKBOX:
                    result = 'checkbox';
                    break;
                case FieldType.DATE:
                    result = 'datepicker';
                    break;
                case FieldType.EMAIL:
                    result = 'input';
                    break;
                case FieldType.LIST:
                    result = 'select';
                    break;
                case FieldType.NUMBER:
                    result = 'input';
                    break;
                case FieldType.TEXTAREA:
                    result = 'textarea';
                    break;
                case FieldType.VALIDATE:
                    result = 'input';
                    break;
                case FieldType.DATETIME:
                    result = 'datetime';
                    break;
                case FieldType.PASSWORD:
                    result = 'password';
                    break;
                case FieldType.IMAGE:
                    result = 'file';
                    break;
                case FieldType.SEARCH_SELECT:
                    result = 'search-select';
                    break;
            }
            return result;
        };
        const validate = (f: Field) => {
            if (f.validations) {
                return f.validations.map((validation) => {
                    const validationBuilder = {};
                    const key = validation.key;
                    validationBuilder[key] = {
                        expression: (control) => !control.value ? true : validation.expression.test(control.value),
                        message: (error, field: FormlyFieldConfig) => `Este campo ${validation.message}`
                    };
                    return {
                        ...validationBuilder
                    };
                }).reduce((result, item) => {
                        const key = Object.keys(item)[0];
                        result[key] = item[key];
                        return result;
                    }, {}
                );
            }
            return undefined;
        };

        const generateTemplateOptions = (f: Field, type, cantEdit = false) => {

            const templateOptions: any = {
                label: f.name,
                placeholder: f.name,
                type: f.type,
                hide: f.hidden,
                required: f.required,
                appearance: 'outline',
                min: f.min,
                max: f.max,
                minLength: f.minLength,
                maxLength: f.maxLength
            };

            if (f.type === FieldType.DATE) {
                templateOptions.readonly = true;
            }

            if (cantEdit || f.removeOnCreate || f.disabled) {
                templateOptions.disabled = true;
            }

            if (f.type === FieldType.TEXTAREA) {
                templateOptions.rows = 5;
            }

            if (f.type === FieldType.LIST || f.type === FieldType.SEARCH_SELECT) {
                templateOptions.options = [];

                if (f.multiple) {
                    templateOptions.multiple = true;
                }

            }

            if (f.type === FieldType.IMAGE && f.formats) {
                templateOptions.accept = f.formats.join(',');
            } else {
                templateOptions.accept = 'image/*';
            }

            return templateOptions;
        };

        const generateField = (field: Field) => {
            if (field.isGroup) {
                return {
                    fieldGroupClassName: field.isGroup ? 'dsp-grid' : undefined,
                    fieldGroup: [
                        ...field.fieldChilds
                            .map(f => {
                                let hideExpression = {};
                                let cantEdit = false;
                                if (f.hideNotWhen) {
                                    hideExpression = {
                                        hideExpression: `model.${f.hideNotWhen.model} != ${f.hideNotWhen.value}`
                                    };
                                }
                                if (f.hideWhen) {
                                    hideExpression = {
                                        hideExpression: `model.${f.hideWhen.model} == ${f.hideWhen.value}`
                                    };
                                }
                                if (f.hidden) {
                                    hideExpression = {
                                        hideExpression: true
                                    };
                                }
                                if (f.cantEdit) {
                                    cantEdit = !(f.cantEdit.indexOf(AuthenticationService.getUser().role.id) > -1);
                                }
                                const type = generateInputType(f.type);

                                return {
                                    className: 'column-form-grid',
                                    key: f.keyName,
                                    type: type === FieldType.PASSWORD ? 'input' : type,
                                    defaultValue: f.defaultValue,
                                    ...hideExpression,
                                    templateOptions: generateTemplateOptions(f, type, cantEdit),
                                    validators: validate(f)
                                };
                            })
                    ],
                };
            } else {
                const type = generateInputType(field.type);
                let fieldConfig = {
                    className: 'column-form-grid',
                    key: field.keyName,
                    type: 'input',
                    defaultValue: field.defaultValue,
                    templateOptions: generateTemplateOptions(field, type),
                    validators: validate(field),
                };
                if (field.hideWhen) {
                    const hideExpression = {
                        hideExpression: (model: any, formState: any, _formlyField: FormlyFieldConfig) => {
                            if (field.hideWhen) {
                                return model[field.hideWhen.model] === field.hideWhen.value;
                            }
                        }
                    };
                    fieldConfig = {...fieldConfig, ...hideExpression};
                }
                return {
                    ...fieldConfig
                };
            }
        };
        return generateField(fieldf);
    }

    static generateFormlyFromFields(fields: Field[]): FormlyFieldConfig[] {
        const fieldsFormly = [];

        fields.forEach(f => {
            if (f.isGroup) {
                if (f.name) {
                    fieldsFormly.push({
                        className: '',
                        template: `<h3>${f.name.toUpperCase()}</h3>`,
                    });
                }
            }
            fieldsFormly.push(DynamicCrudUtils.generateFormlyField(f));
        });
        return fieldsFormly;
    }

    static setDinamycallyOptions(formlyFields: FormlyFieldConfig[], key: string, value): void {
        formlyFields.forEach(f => {
            if (Array.isArray(f.fieldGroup) && f.fieldGroup.length) {
                f.fieldGroup.forEach(fg => {
                    if (fg.key === key) {
                        fg.templateOptions.options = value.map(v => {
                            return {
                                value: v.id,
                                label: v.name
                            };
                        });
                    }
                });
            } else {
                if (f.key === key) {
                    f.templateOptions.options = value.map(v => {
                        return {
                            value: v.id,
                            label: v.name
                        };
                    });
                }
            }
        });
    }

    static setDinamycallyMin(formlyFields: FormlyFieldConfig[], key: string, value): void {
        formlyFields.forEach(f => {
            if (Array.isArray(f.fieldGroup) && f.fieldGroup.length) {
                f.fieldGroup.forEach(fg => {
                    if (fg.key === key) {
                        fg.templateOptions.minDate = moment(value).toDate();
                    }
                });
            } else {
                if (f.key === key) {
                    f.templateOptions.minDate = moment(value).toDate();
                }
            }
        });
    }

    static dateFieldToDefaultFormat(data, dateKeys: string[]): any {
        dateKeys.forEach(k => {
            data[k] = data[k] ? moment(data[k]).format('YYYY-MM-DD') : null;
        });
        return data;
    }

    static getFieldByKeyName(dynamicCrud: IDynamicCrud, field: string): Field {
        let fieldData = null;
        dynamicCrud.fields
            .forEach(d => {
                if (d.isGroup) {
                    const fieldChild = d.fieldChilds.find(fc => fc.keyName === field);
                    if (fieldChild) {
                        fieldData = fieldChild;
                    }
                } else {
                    if (d.keyName === field) {
                        fieldData = d;
                    }
                }
            });
        return fieldData;
    }

    static getFieldBySaveAs(dynamicCrud: IDynamicCrud, field: string): Field {
        let fieldData = null;
        dynamicCrud.fields
            .forEach(d => {
                if (d.isGroup) {
                    const fieldChild = d.fieldChilds.find(fc => fc.saveAs === field);
                    if (fieldChild) {
                        fieldData = fieldChild;
                    }
                } else {
                    if (d.saveAs === field) {
                        fieldData = d;
                    }
                }
            });
        return fieldData;
    }


}

export function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();
  
    customPaginatorIntl.itemsPerPageLabel = 'Elementos por página:';
    customPaginatorIntl.lastPageLabel = 'Ultima página';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    customPaginatorIntl.nextPageLabel = 'Siguiente página';
    customPaginatorIntl.firstPageLabel = 'Primera página';
  
    return customPaginatorIntl;
  }