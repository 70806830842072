import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { PackagesProviderService } from '../../../lib-shared/services/packages-provider.service';
import { PackageNameEnum } from '../../../../mock/packages';

@Component({
    selector: 'lib-disney-package-search',
    templateUrl: './disney-package-search.component.html',
    styleUrls: ['./disney-package-search.component.scss'],
    providers: [PackagesProviderService]
})
export class DisneyPackageSearchComponent implements OnInit {

    @Input() inputFields;
    @Input() isSmall = false;
    @Input() currentTab: number;
    @Input() microsite;

    @Output() dataChanged = new EventEmitter<any>();
    @Output() queryParams = new EventEmitter<any>();

    packageName = PackageNameEnum.DISNEY;

    constructor(
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public router: Router,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit(): void { }

    search(event) {
        this.dataChanged.emit(event);
    }

}
