import { Pipe, PipeTransform } from "@angular/core";
import { IBookingService } from "../../interfaces/index";

@Pipe({
  name: 'bookingServiceIdToDescription'
})
export class BookingServiceIdToDescriptionPipe implements PipeTransform {
  transform(bookingServiceId: number, bookingServices: IBookingService[]): string {
    if (!bookingServices) { return; }
    const bookingServiceFound = bookingServices.find(bookingService => bookingService.id === bookingServiceId);
    if (!bookingServiceFound) { return ''; }
    return bookingServiceFound.service.name;
  }
}
