import { Pipe, PipeTransform } from "@angular/core";
import t from 'typy';

@Pipe({
    name: 'safeObject'
})
export class SafeObjectPipe implements PipeTransform {

    transform(object: any, columns: string, defaultValue: string = ''): string {
        return t(object, columns).safeObject || defaultValue;
    }
}
