import { Component, Input, OnInit } from '@angular/core';
import { NgxGalleryAction, NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";

export interface ImgInt {
    url: string;
    title: string;
}

@Component({
    selector: 'lib-carousel-images',
    templateUrl: './carousel-images.component.html',
    styleUrls: ['./carousel-images.component.scss']
})

export class CarouselImagesComponent implements OnInit {

    @Input() galleryImages: NgxGalleryImage[] = [];
    galleryOptions: NgxGalleryOptions[];
    imageActions: NgxGalleryAction[];

    ngOnInit(): void {
        if(this.galleryImages.length > 37)
            this.galleryImages = this.galleryImages.slice(0, 37);
        const showArrows = this.galleryImages.length > 1;
        this.galleryOptions = [
            {
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                previewKeyboardNavigation: true,
                imageBullets: true,
                imageAutoPlay: true,
                imageAutoPlayInterval: 5000,
                width: '100%',
                height: '600px',
                arrowPrevIcon: showArrows ? 'fa fa-angle-left' : null,
                arrowNextIcon: showArrows ? 'fa fa-angle-right' : null,
                thumbnailsColumns: this.galleryImages.length > 5 ? 5 : this.galleryImages.length,
                imageAnimation: NgxGalleryAnimation.Slide,
                thumbnailsSwipe: true,
                thumbnails: this.galleryImages.length > 1,
            },
            // max-width 800
            {
                breakpoint: 800,
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                width: '100%',
                height: '400px',
                arrowPrevIcon: showArrows ? 'fa fa-angle-left' : null,
                arrowNextIcon: showArrows ? 'fa fa-angle-right' : null,
                imagePercent: 90,
                thumbnailsPercent: 10,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                thumbnailsColumns: this.galleryImages.length > 5 ? 5 : this.galleryImages.length,
                imageAnimation: NgxGalleryAnimation.Slide,
                thumbnails: this.galleryImages.length > 1,
            },
            // max-width 600
            {
                breakpoint: 600,
                thumbnails: false,
            },
            // max-width 400
            {
                breakpoint: 400,
                thumbnails: false,
                preview: false
            }
        ];


    }
}
