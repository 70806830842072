import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IDynamicCrud } from '../../lib-shared/interfaces/index';
import { DynamicCrudComponent } from '../../dynamic-crud/index';
import { ManagementService } from '../../lib-shared/services/index';
import { clientFiles } from './client-files.form';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'lib-client-files',
    templateUrl: './client-files.component.html',
    styleUrls: ['./client-files.component.scss']
})
export class ClientFilesComponent implements OnInit {
    @Input() client: any;
    dynamicCrud: IDynamicCrud;
    chkClientCtrl: FormControl = new FormControl(false);

    @ViewChild(DynamicCrudComponent, { static: false }) crudComponent: DynamicCrudComponent;

    constructor(private managementService: ManagementService) {
    }

    ngOnInit() {
        // const crudData = JSON.parse(JSON.stringify(clientFiles));
        // crudData.urlPathList = crudData.urlPathList.replace('id', this.client);
        this.dynamicCrud = clientFiles;
    }

    addFile(event): void {
        const formData = new FormData();
        formData.append('client', this.client);
        formData.append('file', event.target.files[0]);
        formData.append('is_for_client', this.chkClientCtrl.value);
        this.managementService.uploadFilesToClient(formData)
            .subscribe(value => {
                this.crudComponent.list();
            });
    }


}
