import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import {
    MatFormFieldModule,
    MatInputModule
} from '@angular/material';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialDatePickerComponent } from './material-date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LibSharedModule } from '../lib-shared/lib-shared.module';

@NgModule({
    declarations: [
        MaterialDatePickerComponent,
    ],
    imports: [
        NgxMaskModule.forRoot({ validation: true }),
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        LibSharedModule,
    ],
    exports: [
        MaterialDatePickerComponent
    ]
})
export class MaterialDatePickerModule {

}
