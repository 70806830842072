import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {BookEditComponent} from '../../book-edit.component';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {HotelBookServices} from '../services/hotel-book.services';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';
import {HotelBookEditComponent} from '../book-edit/hotel-book-edit.component';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {t} from "typy";
import {TecnoturisService} from '../../../tecnoturis.service';
import {
    AuthenticationService,
    BasketManager,
    ClientServices,
    NotificationPopupService
} from '../../../lib-shared/services/index';
import {ROLES} from '../../../const';
import {ShopingBasketHotel} from '../../../integrations/index';

@Component({
    selector: 'lib-hotel-wrapper-book-edit',
    templateUrl: './hotel-book-edit-wrapper.component.html',
    styleUrls: ['./hotel-book-edit-wrapper.component.scss']
})
export class HotelWrapperBookEdit extends BookEditComponent implements OnInit {
    @Input() hotel;
    hotels = [];
    @ViewChildren(HotelBookEditComponent) childs;
    formsValidates = [];
    checkBtnBook: boolean;
    formValidate: FormArray | FormGroup;
    termsCheck: boolean;
    auth = AuthenticationService.getUser();
    rawShoppingBasket: ShopingBasketHotel;
    @Input() onlyResumeInfo = false;

    constructor(
        public http: HttpClient,
        private activatedRoute: ActivatedRoute,
        protected location: Location,
        protected _hotelBookService: HotelBookServices,
        protected _basketManager: BasketManager,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected notification: NotificationPopupService,
        protected router: Router,
        protected _formBuilder: FormBuilder,
        public tecnoturisService: TecnoturisService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService);
        this.checkBtnBook = true;
        this.termsCheck = false;
    }

    ngOnInit(): void {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.activatedRoute.paramMap
            .pipe(map(() => window.history.state))
            .subscribe(state => {
                if (state.hotel) {
                    if (state.hotel[0].providerHasMultidistributionOption) {
                        this.rawShoppingBasket = { ...state.hotel[0] };
                        this.hotels = this.splitInHotelsByRoomList(state.hotel);
                    } else {
                        this.hotels = state.hotel;
                    }
                    this.checkFormsInit();
                } else {
                    this.id = this.activatedRoute.snapshot.params['id'];
                    this._basketManager.getList().then(data => {
                        const hotel = data.find(item => item.uuid === this.id);
                        if (hotel) {
                            if (hotel.providerHasMultidistributionOption) {
                                this.rawShoppingBasket = hotel;
                                this.hotels = this.splitInHotelsByRoomList([hotel]);
                            } else {
                                this.hotels.push(hotel);
                            }
                            this.checkFormsInit();
                        }
                    });
                }
            });
    }

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    checkFormsInit() {
        const occupantList = [];
        if (this.hotels[0].bookingPostReqDto) {
            const occupants = this.hotels[0].bookingPostReqDto.roomDetails[0].roomList[0].occupants;
            const generateOccupant = (occupant) => {
                return this._formBuilder.group({
                    name: [t(occupant, 'name').safeString, Validators.required],
                    surname: [t(occupant, 'surname').safeString, Validators.required],
                    type: [t(occupant, 'type').safeString, Validators.required],
                    age: [t(occupant, 'age').safeObject, Validators.required]
                });
            };
            occupants.forEach(occupant => {
                occupantList.push(generateOccupant(occupant));
            });
            this.formValidate = this._formBuilder.array(occupantList);
            if (this.formValidate.status === 'VALID') {
                this.checkBtnBook = false;
            }
        }
    }

    async addToBasketAndRedirect(redirectPath?: string) {
        if (super.checkClient()) {
            if (this.hotels[0].providerHasMultidistributionOption && this.hotels.length > 1) {
                await this._hotelBookService.addMultidistributionToBasket(this.hotels, this.formsValidates, this.rawShoppingBasket);
            } else {
                for (const child of this.childs.toArray()) {
                    await child.addToBasket();
                }
            }
            const redirectToPath = !redirectPath ? this.redirectToPath() : redirectPath;
            return this.router.navigateByUrl(redirectToPath, {replaceUrl: true});
        }
    }

    async goToSummaryOrConfirmation() {
        if (super.checkClient()) {
            const childsArray = this.childs.toArray();
            if (this.hotels[0].providerHasMultidistributionOption && this.hotels.length > 1) {
                await this._hotelBookService.addMultidistributionToBasket(this.hotels, this.formsValidates, this.rawShoppingBasket);
            } else {
                for (const child of childsArray) {
                    await child.addToBasket();
                }
            }

            if (childsArray.length === 1) {
                await this._basketManager.getList().then(data => {
                    if (data.length === 1) {
                        this.clientService.getClient().then(client => {
                            childsArray[0]._hotelBookService
                                .booking(data[0], client)
                                .then(res => {
                                    const navigationExtras: NavigationExtras = {
                                        state: {
                                            bookingPostResDto: [res]
                                        }
                                    };

                                    const path = this.redirectToConfirmation();
                                    this.router.navigate(
                                        [path],
                                        navigationExtras
                                    );
                                });
                        });
                    } else {
                        if (data.length) {
                            const path = this.redirectToSummary();
                            this.router.navigateByUrl(path);
                        }
                    }
                });
            } else {
                const path = this.microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
                this.router.navigateByUrl(path);
            }
        }
    }

    getOcuppantsForms(formOccupant): void {
        if (this.hotels.length > 1) {
            const check = this.checkForm(formOccupant);
            if (check) {
                this.formsValidates.push(formOccupant);
            }
            if (this.formsValidates.length === this.hotels.length) {
                const checkedStatusForms = this.formsValidates.find(form => form.form.status === "INVALID");
                if (checkedStatusForms) {
                    this.checkBtnBook = true;
                } else {
                    this.checkBtnBook = false;
                }
            }
        } else {
            if (formOccupant.form.status === "VALID") {
                this.checkBtnBook = false;
            } else {
                this.checkBtnBook = true;
            }
        }
    }

    checkForm(formOccupant): boolean {
        let match = this.formsValidates.find(e => {
            if (e.id === formOccupant.id) {
                return e = formOccupant;
            }
        });
        if (match) {
            match = false;
        } else {
            match = true;
        }
        return match;
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }

    private splitInHotelsByRoomList(hotels: ShopingBasketHotel[]): ShopingBasketHotel[] {
        return hotels.reduce((acc, cur) => {
            const mappedHotels = cur.roomList.map((list, i) => {

                const bookingPostReqDto = cur.bookingPostReqDto ?
                    Object.assign({}, cur.bookingPostReqDto, {
                        roomDetails: [{...cur.bookingPostReqDto.roomDetails[i]}],
                    }) :
                    undefined;

                const mappedHotel = Object.assign({}, cur, {
                    roomList: [list],
                    distributionSelected: list.distribution,
                    integrationType: 'HOTEL'
                });
                if (bookingPostReqDto) {
                    mappedHotel.bookingPostReqDto = bookingPostReqDto;
                }

                return mappedHotel;
            });

            return [...acc, ...mappedHotels];
        }, []);
    }
}
