import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-car-bond',
    templateUrl: './car-bond.component.html',
    styleUrls: ['./car-bond.component.scss']
})
export class CarBondComponent implements OnInit {

    @Input() bond: any;
    mainPax: any[];
    secondaryPax: any[];

    constructor() {
    }

    ngOnInit() {
        this.mainPax = this.bond.pax.slice(0, 1);
        this.secondaryPax = this.bond.pax.slice(1);
    }

}
