import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
    IAgendaCalendarEvent,
    ICommercialActivityEvent, ICommercialActivityEventMinified, ICommercialActivityPersonal, ICommercialActivityType,
    IPagination,
    IWeekRange
} from '../interfaces/agenda-calendar.interface';
import * as moment_ from 'moment/moment';
const moment = moment_;
import {AppSettings} from '../../app.settings';
import {IUserInfo, Setup} from '../interfaces/index';
import {CalendarEvent} from 'angular-calendar';
import {EventColor} from 'calendar-utils';
import {TecnoturisService} from "../../tecnoturis.service";

@Injectable({
    providedIn: 'root'
})
export class AgendaService {
    protected host = this.tecnoturisService.config.management;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
    }

    getClients(): void {

    }

    getStateIcon(event: ICommercialActivityEvent): string {
        const found = AppSettings.CALENDAR_EVENT_TYPE.find(item => item.type === event.commercial_activities_type);
        return found ? found.icon : 'info';
    }

    getTypes(types: ICommercialActivityType[]): ICommercialActivityType[] {
        // const types: ICommercialActivityType[] = setup['commercial_activities_type'];
        const defaultColor = this.getColor(null);
        types.forEach(item => {
            item.color = item.color && typeof item.color === 'string' ? item.color : defaultColor.primary;
        });
        return types;
    }

    getColor(color: string): EventColor {
        const defaultColor = JSON.parse(JSON.stringify(AppSettings.EVENT_COLORS.find(c => c.state === 0).color));
        if (color) {
            defaultColor.primary = color;
        }
        return defaultColor;
    }

    getTransformedEvents(corporate: ICommercialActivityEvent[],
                         user: IUserInfo,
                         types: ICommercialActivityType[]): CalendarEvent<IAgendaCalendarEvent>[] {
        // const defaultColor = AppSettings.EVENT_COLORS.find(c => c.state === 0).color;
        const events: CalendarEvent<IAgendaCalendarEvent>[] = [];
        corporate.forEach(item => {
            const type: ICommercialActivityType = types.find(type => type.id === item.commercial_activities_type);
            const color = type ? this.getColor(type.color) : this.getColor(null);
            const event: CalendarEvent<IAgendaCalendarEvent> = {
                title: item.subject,
                color: color,
                start: moment(`${item.start_date} ${item.start_time}`, AppSettings.DATE_FORMATS.FULL_DATE_TIME).toDate(),
                end: moment(`${item.end_date} ${item.end_time}`, AppSettings.DATE_FORMATS.FULL_DATE_TIME).toDate(),
                meta: {
                    id: item.id,
                    start: moment(`${item.start_date} ${item.start_time}`, AppSettings.DATE_FORMATS.FULL_DATE_TIME).toDate(),
                    end: moment(`${item.end_date} ${item.end_time}`, AppSettings.DATE_FORMATS.FULL_DATE_TIME).toDate(),
                    state: item.commercial_activities_status,
                    type: type ? type.name : 'Otros',
                    user: {
                        id: user.id,
                        name: `${user.first_name} ${user.last_name}`,
                        type: user.role.name,
                        color: color
                    }
                },
                resizable: {
                    beforeStart: false,
                    afterEnd: false,
                },
                draggable: false,
            };
            events.push(event);
        });
        return events;
    }

    getDateRangeWeek(date: Date): IWeekRange {
        const transformed = moment(date);
        const start = moment().isoWeek(transformed.isoWeek()).day('Monday');
        const end = moment().isoWeek(transformed.isoWeek() + 1).day('Sunday');
        return {start: start, end: end};
    }

    getCoworkers(): Observable<ICommercialActivityPersonal[]> {
        return this.http.get<ICommercialActivityPersonal[]>(`${this.host}/user/list/`);
    }

    getEvent(id: number): Observable<ICommercialActivityEvent> {
        return this.http.get<ICommercialActivityEvent>(`${this.host}/clients/commercial_activities/${id}/`);
    }

    updateEvent(id: number, payload: ICommercialActivityEvent | any): Observable<ICommercialActivityEvent> {
        return this.http.patch<ICommercialActivityEvent>(`${this.host}/clients/commercial_activities/${id}/`, payload);
    }

    deleteEvent(id: number): Observable<any> {
        return this.http.delete(`${this.host}/clients/commercial_activities/${id}/`);
    }

    createEvent(payload: ICommercialActivityEvent): Observable<ICommercialActivityEvent> {
        return this.http.post<ICommercialActivityEvent>(`${this.host}/clients/commercial_activities/`, payload);
    }

    getEvents(from: moment.Moment, to: moment.Moment, status?: number): Observable<IPagination<ICommercialActivityEvent>> {
        let query = new HttpParams();
        query = query.append('start_date_after', from.format(AppSettings.DATE_FORMATS.FULL_DATE));
        query = query.append('start_date_before', to.format(AppSettings.DATE_FORMATS.FULL_DATE));
        if (status !== undefined && String(status)) {
            query = query.append('commercial_activities_status', String(status));
        }
        return this.http.get<IPagination<ICommercialActivityEvent>>(`${this.host}/clients/commercial_activities/`, {params: query});
    }

    getEventsMinified(from: moment.Moment, to: moment.Moment, status?: number): Observable<ICommercialActivityEventMinified[]> {
        let query = new HttpParams();
        query = query.append('minified', 'true');
        query = query.append('start_date_after', from.format(AppSettings.DATE_FORMATS.FULL_DATE));
        query = query.append('start_date_before', to.format(AppSettings.DATE_FORMATS.FULL_DATE));
        if (status !== undefined && String(status)) {
            query = query.append('commercial_activities_status', String(status));
        }
        return this.http.get<ICommercialActivityEvent[]>(`${this.host}/clients/commercial_activities/`, {params: query});
    }


}
