import {Pipe, PipeTransform} from "@angular/core";
import {Commission, Itinerary2, Pax, SalesGroup} from '../interfaces/index';
import t from "typy";

@Pipe({
    name: 'CommissionByCabinPipe'
})
export class CommissionByCabinPipe implements PipeTransform {

    transform(itineraries: Itinerary2[] = []): Commission {

        let commission = {} as Commission;
        commission.commissionRate = 0;
        commission.fee = 0;
        commission.iva = 0;

        if (!itineraries || itineraries.length === 0) {
            return commission;
        }
        const salesGroup = t(itineraries, '[0].classes[0].salesGroups[0]').safeObject as SalesGroup;
        commission = t(salesGroup, 'cabins[0].paxes[0].fares[0].commission').safeObject as Commission;
        commission.commissionRate = commission.commissionRate ? commission.commissionRate : 0;
        commission.fee = commission.fee ? commission.fee : 0;
        commission.iva = commission.iva ? commission.iva : 0;
        return commission;
    }
}
