import { FieldType, IDynamicCrud } from '../../lib-shared/interfaces/index';

export const dossierFormMetaData: IDynamicCrud = {
    crud: 'dossier',
    name: 'Expedientes',
    urlPath: 'clients/dossier/',
    urlPathList: 'clients/id/dossier/',
    extraKeySave: ['client'],
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                // {
                //     keyName: 'code',
                //     name: 'Id',
                //     type: FieldType.CHAR,
                //     showInTable: true,
                //     removeOnCreate: true
                // },
                {
                    keyName: 'code',
                    name: 'Id',
                    type: FieldType.CHAR,
                    showInTable: true,
                    removeOnCreate: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'total_amount',
                    name: 'Total PVP',
                    type: FieldType.NUMBER,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true,
                    hidden: true
                },
                {
                    keyName: 'total_services',
                    name: 'Servicios',
                    type: FieldType.NUMBER,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
                {
                    keyName: 'created_by',
                    name: 'Usuario',
                    type: FieldType.CHAR,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones',
            fieldChilds: [
                {
                    keyName: 'observation',
                    name: '',
                    type: FieldType.TEXTAREA,
                    removeOnCreate: false,
                },
            ]
        },
    ]
};
