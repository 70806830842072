import { NgModule } from '@angular/core';
import { HotelBookingComponent } from './hotel-booking.component';
import { RouterModule, Routes } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BondComponent } from './bond/bond.component';
import { CarBondComponent } from './bond/components/car-bond/car-bond.component';
import { CircuitBondComponent } from './bond/components/circuit-bond/circuit-bond.component';
import { SafeBondComponent } from './bond/components/safe-bond/safe-bond.component';
import { PackageBondComponent } from './bond/components/package-bond/package-bond.component';
import { HotelBondComponent } from "./bond/components/hotel-bond/hotel-bond.component";
import { DynamicCrudModule } from '../../dynamic-crud/index';
import { ManagementFilesModule } from '../../management-files/index';
import { HotelBookingRoomsModule } from '../../form-add-edit/index';
import { DateAdapterModule, LibSharedModule } from '../../lib-shared/index';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DocumentHeaderModule } from '../document-header/document-header.module';

const routes: Routes = [
    {
        path: 'create',
        component: HotelBookingComponent,
    },
    {
        path: 'edit/:id',
        component: HotelBookingComponent,
    },
    {
        path: 'bond/:id',
        component: BondComponent,
    }
];

@NgModule({
    declarations: [HotelBookingComponent, BondComponent, HotelBondComponent, CarBondComponent,
        CircuitBondComponent, SafeBondComponent, PackageBondComponent
    ],
    imports: [
        // RouterModule.forChild(routes),
        DynamicCrudModule,
        FormlyModule,
        ReactiveFormsModule,
        IonicModule,
        ManagementFilesModule,
        HotelBookingRoomsModule,
        DateAdapterModule,
        MatCardModule,
        LibSharedModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        RouterModule,
        DocumentHeaderModule,
    ],
    exports: [HotelBookingComponent, BondComponent, HotelBondComponent, CarBondComponent,
        CircuitBondComponent, SafeBondComponent, PackageBondComponent]
})
export class HotelBookingModule {
}
