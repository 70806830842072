// import { TransferServiceResponse } from '../../..';
import { StringifyOptions } from 'querystring';
import { BookingDossier } from '../../..';
import { Contact, PassengerTransfer, TransportInfo } from '../request/booking-create.request.dto';
import { Extras, Vehicle } from './transfer.response.dto';



export class BookingResponseDto {
   data: BookingTransferResponse;
   status: number;
   transferData: any;
   client: any;
   dossier: any;
}

export class BookingTransferResponse {
   totalPrice: number;
   contact: Contact;
   currencyId: string;
   locator: string;
   transferServicesBooked: TransferServicesBooked
}

export class TransferServicesBooked{
   amount: string;
   arrivDateTime: string;
   baseCode: string;
   depDateTime: string;
   hotelCode: string;
   hotelName: string;
   transferTypeCode: string;
   vehicle: Vehicle
}
