import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DynamicCrudModule } from '../../dynamic-crud/index';
import { HotelBookingRoomsComponent } from './hotel-booking-rooms.component';
import { HotelBookingRoomsPaxComponent } from '../hotel-booking-rooms-pax/hotel-booking-rooms-pax.component';
import { LibSharedModule } from '../../lib-shared/index';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from "@angular/material/input";

@NgModule({
    declarations: [HotelBookingRoomsComponent, HotelBookingRoomsPaxComponent],
    imports: [
        DynamicCrudModule,
        FormlyModule,
        ReactiveFormsModule,
        IonicModule,
        LibSharedModule,
        MatSelectModule,
        MatTableModule,
        MatInputModule
    ],
    exports: [HotelBookingRoomsComponent, HotelBookingRoomsPaxComponent]
})
export class HotelBookingRoomsModule {
}
