import { Pipe, PipeTransform } from "@angular/core";
import { Budget, BudgetBookingService } from "../../interfaces/index";

@Pipe({
    name: 'excludeParentServiceFromDossier'
})
export class ExcludeParentServiceFromDossierPipe implements PipeTransform {

  excludedProviderServiceIds = [23];

  transform(services: BudgetBookingService[], budget: Budget): BudgetBookingService[] {
    if (!services || !budget  ) { return []; }
    if (!budget.include_parent_service_in_the_calculation) {
        return services.filter(s => !this.excludedProviderServiceIds.includes(s.booking_service.provider_service_id));
    }
    return services;
  }
}
