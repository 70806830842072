import { HttpClient } from "@angular/common/http";
import { TecnoturisService } from '../../tecnoturis.service';
import { EntityService } from '../services/index';

export class BaseEntity {
    constructor(public http: HttpClient, public tecnoturisService: TecnoturisService) {
    }

    getEntity(host, endpoint): EntityService {
        return new EntityService(this.http, host, endpoint, this.tecnoturisService);
    }

    getNameFromEntity(id, entity): string {
        if (this[entity]) {
            const result = this[entity].filter(item => item.id === id);
            return result[0] ? result[0].name : "";
        }
        return "";
    }
}
