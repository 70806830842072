import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircuitsBookingComponent } from './circuits-booking.component';
import { Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';

const routes: Routes = [
    {
        path: 'create',
        component: CircuitsBookingComponent,
    },
    {
        path: 'edit/:id',
        component: CircuitsBookingComponent,
    }
];

@NgModule({
    declarations: [CircuitsBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
        // RouterModule.forChild(routes)
    ],
    exports: [CircuitsBookingComponent]
})
export class CircuitsBookingModule {
}
