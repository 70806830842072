import { Component, OnInit } from '@angular/core';
import { packageFormMetaData } from './package.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-package-booking',
    templateUrl: './package-booking.component.html',
    styleUrls: ['./package-booking.component.scss']
})
export class PackageBookingComponent implements OnInit {
    packageCrud = packageFormMetaData;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.packageForm) {
            this.packageCrud = this.tecnoturisService.config.forms.packageForm;
        }
    }

    ngOnInit() {
    }

}
