import { calculateAge, splitLastName } from "../../../functions/index";
import { MultidestinationPassenger } from "../../multidestination/multidestination-pre-booking.model";
import { Passenger as TrainPassenger, TrainPaxType } from "../response/pre-bookings.response.dto";

export class TrainBookingPaxMapper {

    static buildFromMultidestinationPassenger(
        passengers: MultidestinationPassenger[]
    ): TrainPassenger[] {
        const arr = passengers.map(passenger => {
            const {
                type,
                name,
                lastname,
                email,
                documentType,
                documentNumber,
                birthdate,
                phone,
            } = passenger;
            const [firstSurname, secondSurname] = splitLastName(lastname);
            return {
                paxType: this.getTrainPassengerType(type),
                name,
                firstSurname,
                secondSurname,
                email,
                documentType,
                documentNumber,
                age: calculateAge(birthdate),
                phone,
            };
        });
        return arr;
    }

    private static getTrainPassengerType(type: string): TrainPaxType {
        if (type === 'Adult') {
            return 'Adulto';
        }
        if (type === 'Child') {
            return 'Niño';
        }
        if (type === 'Infant') {
            return 'Infante';
        }
    }


}
