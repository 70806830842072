import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import * as moment_ from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PackageSearchDto } from '../package-search.component';
import { PaxesService } from '../../../paxes/index';
import Pax from '../../../paxes/Pax';

const moment = moment_;


@Component({
    selector: 'lib-default-package-search',
    templateUrl: './default-package-search.component.html',
    styleUrls: ['./default-package-search.component.scss']
})
export class DefaultPackageSearchComponent implements OnInit {

    form: FormGroup;
    originOptions$: any;
    destinationOptions$: any;
    pax: Pax;
    paxes: any = [];
    packageListReq: PackageSearchDto;
    @ViewChild('dateRange', { static: false }) dateRange;
    @Input() inputFields;
    @Input() isSmall;
    @Output() dataChanged = new EventEmitter<any>();
    @Input() microsite;

    constructor(
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public paxService: PaxesService,
        public router: Router) {
    }

    ngOnInit(): void {
        this.paxes.push(new Pax(1, 2, []));
        this.initForm();
        this.form.get('origin').valueChanges.pipe(debounceTime(250)).subscribe(() => this.getOriginAutocompleteData());
        this.form.get('destination').valueChanges.pipe(debounceTime(250)).subscribe(() => this.getDestinationAutocompleteData());
    }

    private initForm() {
        this.form = this.formBuilder.group({
            origin: ['', Validators.required],
            destination: ['', Validators.required],
            distribution: [this.paxes, Validators.required]
            // rooms: [0, Validators.required],
            // adults: [2, Validators.required],
            // children: [0, Validators.required]
        });

        if (this.inputFields) {
            this.setParamsFromQuery(this.inputFields);
            this.setPaxFromQuery(this.inputFields.distribution);
        }
        this.packageListReq = this.form.value;
    }

    private setPaxFromQuery(distribution) {
        this.paxes = distribution;
    }

    private setParamsFromQuery(inputFields: PackageSearchDto) {
        this.form.controls['origin'].setValue(inputFields.origin);
        this.form.controls['destination'].setValue(inputFields.destination);
        this.setDateFromQuery(inputFields.checkIn);
        this.setDateFromQuery(inputFields.checkOut);
        this.paxService.changePax({
            rooms: inputFields.rooms,
            adults: inputFields.adults,
            children: inputFields.children
        });
    }

    private setDateFromQuery(inputDate: string) {
        /* const date = moment(inputDate, "DD-MM-YYYY").toDate();
        this.dateRange.onSelection(
          new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDay())
        ); */
        const date = moment(inputDate).format('DD-MM-YYYY');
        const dateArray = date.split('-');
        this.dateRange.onSelection(new NgbDate(Number(dateArray[2]), Number(dateArray[1]), Number(dateArray[0])));
    }

    search() {
        if (!this.form.valid) {
            return;
        }
        this.setDates();
        this.setPlaces();
        this.setPaxes();
        this.dataChanged.emit(this.getParams());
        if (this.microsite) {
            return;
        }
        this.router.navigateByUrl('/integration/packages/search?' + this.getParams());
    }

    getParams(): HttpParams {
        const query = new HttpParams()
            .set('origin', this.packageListReq.origin)
            .set('destination', this.packageListReq.destination)
            .set('checkIn', this.packageListReq.checkIn)
            .set('checkOut', this.packageListReq.checkOut)
            .set('rooms', this.packageListReq.rooms.toString())
            .set('adults', this.packageListReq.adults.toString())
            .set('children', this.packageListReq.children.toString());
        return query;
    }

    setDates() {
        this.packageListReq.checkIn = this.dateRange.formatDate(this.dateRange.fromDate);
        this.packageListReq.checkOut = this.dateRange.formatDate(this.dateRange.toDate);
    }

    setPlaces() {
        this.packageListReq.origin = this.form.controls['origin'].value;
        this.packageListReq.destination = this.form.controls['destination'].value;
    }

    setPaxes() {
        this.packageListReq.distribution = this.paxes;
        // this.packageListReq.adults = this.pax.adults;
        // this.packageListReq.children = this.pax.children;
        // this.packageListReq.rooms = this.pax.rooms;
    }

    getOriginAutocompleteData() {
        this.originOptions$ = of([
            {id: 'MAD', name: 'Madrid'},
            {id: 'BIO', name: 'Bilbao'},
            {id: 'LUQ', name: 'San Luis'},
            {id: 'RSA', name: 'Santa Rosa'},
            {id: 'IRJ', name: 'La Rioja'},
            {id: 'REL', name: 'Trelew'}
        ]);
    }

    getDestinationAutocompleteData() {
        this.destinationOptions$ = of([
            {id: 'MAD', name: 'Madrid'},
            {id: 'BIO', name: 'Bilbao'},
            {id: 'LUQ', name: 'San Luis'},
            {id: 'RSA', name: 'Santa Rosa'},
            {id: 'IRJ', name: 'La Rioja'},
            {id: 'REL', name: 'Trelew'}
        ]);
    }

    onPaxChanged(pax: any) {
        this.paxes[pax.position] = pax.newPax;
        // this.pax = pax;
        // this.form.controls['rooms'].setValue(pax.rooms);
        // this.form.controls['adults'].setValue(pax.adults);
        // this.form.controls['children'].setValue(pax.children.length);
    }

    onRemovePax(position) {
        const newPaxes = [];
        this.paxes.forEach((pax: Pax, index) => {
            if (index !== position) {
                newPaxes.push(pax);
            }
        });

        this.paxes = newPaxes;
    }

    addPax() {
        this.paxes.push(new Pax(1, 2, []));
    }
}
