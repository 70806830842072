import {
    HotelBookingPostReqDtoOccupant,
    HotelBookingPostReqDtoRoomDetail,
    HotelRoomList,
} from "@vecib2c/frontend-dto";

export class HotelBookingReqRoomDetailMapper {

    static build(
        roomList: HotelRoomList[],
        occupants: HotelBookingPostReqDtoOccupant[],
        hotelId: string,
        checkIn: string,
        checkOut: string,
    ): HotelBookingPostReqDtoRoomDetail[] {
        const arr = roomList.map((rl, i) => {
            const { rooms } = rl;
            return {
                roomId: '1',
                rateId: rooms[0].rates[0].rateId,
                roomList: [{
                    occupants: occupants.filter(occupant => occupant.roomNumber === i + 1),
                }],
                hotelId,
                checkIn,
                checkOut,
                currency: 'EUR',
                price: rooms[0].rates[0].price,
            };
        });
        return arr;
    }
}
