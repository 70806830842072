import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { PackageCommission } from '@vecib2c/frontend-dto';

@Component({
    selector: 'lib-package-price-summary',
    templateUrl: './package-price-summary.component.html',
    styleUrls: ['./package-price-summary.component.scss']
})
export class PackagePriceSummaryComponent implements OnInit, OnDestroy {

    @Input() passengerCount: number;
    @Input() commission: PackageCommission;
    @Input() commissionableRate: number;
    @Input() nonCommissionableRate: number;
    @Input() NetAmountAndTaxes: any;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

}
