import { Pipe, PipeTransform } from '@angular/core';
import { FlightItinerary } from '@vecib2c/frontend-dto/dist';

@Pipe({
    name: 'filterBySegment'
})
export class FilterBySegmentPipe implements PipeTransform {

    transform(itineraries: FlightItinerary[], segmentNumber: number): any {
        return itineraries.filter(i => i.segmentNumber === segmentNumber);
    }

}
