import { FieldType, IDynamicCrud } from '../../lib-shared/interfaces/index';

export const bookingFormMetaData: IDynamicCrud = {
    crud: 'hotel-booking',
    name: 'Reserva',
    urlPath: 'booking-service/',
    urlPathList: 'clients/dossier/id/hotel-booking/',
    extraKeySave: ['hotels'],
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: 'Datos generales',
            fieldChilds: [
                {
                    keyName: 'code',
                    name: 'N° Servicio',
                    type: FieldType.CHAR,
                    removeOnCreate: true,
                    readonly: true
                },
                {
                    keyName: 'confirmed_by',
                    name: 'Confirmado por',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'locator',
                    name: 'Localizador',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'confirmed_date',
                    name: 'Fecha de confirmación',
                    type: FieldType.DATE,
                    showInTable: true
                },
                {
                    keyName: 'fiscal_location',
                    name: 'Localización fiscal (destino)',
                    type: FieldType.LIST,
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'description',
                    name: 'Descripción',
                    type: FieldType.CHAR,
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos de la reserva',
            fieldChilds: [
                {
                    keyName: 'check_in',
                    name: 'Fecha entrada',
                    type: FieldType.DATE,
                    showInTable: true,
                    saveInKey: 'hotels'
                },
                {
                    keyName: 'check_out',
                    name: 'Fecha salida',
                    type: FieldType.DATE,
                    showInTable: true,
                    saveInKey: 'hotels'
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: null,
            fieldChilds: [
                {
                    keyName: 'entry_with',
                    name: 'Inicio de servicio',
                    type: FieldType.LIST,
                    saveInKey: 'hotels',
                    optionKey: 'hotel_input_option'
                },
                {
                    keyName: 'exit_with',
                    name: 'Fin de servicio',
                    type: FieldType.LIST,
                    saveInKey: 'hotels',
                    optionKey: 'hotel_output_option'
                },
                {
                    keyName: 'cancellation_to',
                    name: 'Cancelación hasta',
                    type: FieldType.DATE,
                    showInTable: true,
                    saveInKey: 'hotels'
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos del bono',
            fieldChilds: [
                {
                    keyName: 'name',
                    name: 'Nombre de alojamiento',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels'
                },
                {
                    keyName: 'address',
                    name: 'Dirección de alojamiento',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels'
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'postal_code',
                    name: 'Código postal',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels',
                    minLength: 1,
                    maxLength: 10
                },
                {
                    keyName: 'country',
                    name: 'Pais',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels'
                },
                {
                    keyName: 'province',
                    name: 'Provincia',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels'
                },
                {
                    keyName: 'locality',
                    name: 'Localidad',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels'
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'phone',
                    name: 'Teléfono',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels',
                    minLength: 1,
                    maxLength: 20
                },
                {
                    keyName: 'pay_by',
                    name: 'Pagado por',
                    type: FieldType.CHAR,
                    saveInKey: 'hotels'
                },
                {
                    keyName: 'valued_bond',
                    name: 'Bono valorado',
                    type: FieldType.CHECKBOX,
                    saveInKey: 'hotels'
                },
                {
                    keyName: 'price_bond',
                    name: 'Precio bono',
                    type: FieldType.NUMBER,
                    saveInKey: 'hotels',
                    disabled: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: null,
            fieldChilds: [
                {
                    keyName: 'observation',
                    name: 'Observaciones para el hotel',
                    type: FieldType.TEXTAREA,
                    saveInKey: 'hotels'
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos del proveedor',
            fieldChilds: [
                {
                    keyName: 'provider_accountant_account',
                    name: 'Cuenta contable',
                    type: FieldType.CHAR,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'provider',
                    name: 'Nombre del proveedor',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'payment_method',
                    name: 'Forma de Pago',
                    type: FieldType.LIST,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'payday_limit',
                    name: 'Fecha límite de pago',
                    type: FieldType.DATE,
                    disabled: false
                },
                {
                    keyName: 'product_type',
                    name: 'Tipo de producto',
                    type: FieldType.LIST
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos de comisiones',
            fieldChilds: [
                {
                    keyName: 'fee_apply',
                    name: 'Tipo comisión',
                    type: FieldType.LIST,
                    defaultValue: 1
                },
                {
                    keyName: 'comission',
                    name: '% Comisión',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 100
                },
                {
                    keyName: 'iva',
                    name: 'IVA',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva',
                    name: 'IVA de Compra',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva_pay',
                    name: 'IVA de Venta',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'pvp_fee',
                    name: 'Importe Comisionable',
                    type: FieldType.NUMBER,
                    min: 0,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'not_fee_amount',
                    name: 'Importe no comisionable (tasas)',
                    type: FieldType.NUMBER,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'total_pvp',
                    name: 'Total PVP',
                    type: FieldType.NUMBER,
                    readonly: true
                },
                {
                    keyName: 'discount',
                    name: 'Descuentos',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'fee',
                    name: 'Fee Agencia',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'total_net',
                    name: 'Total Neto a pagar',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'profit_gross',
                    name: 'Margen Bruto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'profit',
                    name: 'Margen Neto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones generales',
            fieldChilds: [
                {
                    keyName: 'general_observation',
                    name: '',
                    type: FieldType.TEXTAREA
                }
            ]
        }
    ]
};
