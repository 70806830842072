import { Injectable, Optional } from '@angular/core';
import { IDynamicCrud } from './lib-shared/interfaces/index';

export class TecnoturisServiceConfig {
    production?: boolean;
    logginURL: string;
    host: string;
    defaultVersion: string;
    autocompleteEntity: string;
    user: string;
    provider: string;
    agency: string;
    userHost: string;
    providerHost: string;
    agencyHost: string;
    provinceHost: string;
    graphql: string;
    filterHost: string;
    clientHost: string;
    nationalityHost: string;
    circuitsPana: string;
    circuitsv2: string;
    circuits: string;
    flights: string;
    'hotel-book': string;
    packages: {
        disney: string,
        avoris: string,
        soltour: string,
        providers: string,

    };
    hotelsAndFlights: string;
    lefleat: {
        tilesLayer: string,
    };
    pelias: string;
    activities: string;
    activitiesw2m: string;
    insurance: string;
    providers: {
        default: string,
        BedsOnline: string,
        olympia: string,
        W2M: string,
        TOUR10: string,
    };
    airports: string;
    management: string;
    exportServices: string;
    ckeditor: {
        basicstyles: {
            toolbar: [
                {
                    name: string,
                    items: string[]
                }
            ]
        }
    };
    flexibleAutos: string;
    pageSizeTable: number;
    externalServices: string;
    microsites: string;
    trains: string;
    forms?: {
        circuitForm?: IDynamicCrud,
        trainForm?: IDynamicCrud,
        flightForm?: IDynamicCrud;
        clientForm?: IDynamicCrud;
        dossier?: IDynamicCrud;
        hotelForm?: IDynamicCrud;
        packageForm?: IDynamicCrud;
        safesForm?: IDynamicCrud;
        transportForm?: IDynamicCrud;
        activityForm?: IDynamicCrud;
        transferForm?: IDynamicCrud;
        cruiseForm?: IDynamicCrud;
        otherServiceForm?: IDynamicCrud;
        busForm?: IDynamicCrud;
        ferryForm?: IDynamicCrud;
        hotelAndFlight?: IDynamicCrud;
    };
    actions?: {
        client?: {
            back: boolean,
            commercialAction: boolean,
            dossier: boolean,
            presupuesto: boolean,
            save: boolean,
            files: boolean,
            dossiers: boolean,
            commercialActivities: boolean,
            contactPersons: boolean,
        },
        dossier?: {
            back: boolean,
            budget: boolean,
            bond: boolean,
            abono: boolean,
            presupuesto: boolean,
            save: boolean,
            bookingList: boolean,
            paymentList: boolean,
            budgetList: boolean,
            abonoList: boolean,
            managementFiles: boolean
        }
    };
    multidestination: string;
    transfers: string;
}

@Injectable({
    providedIn: 'root'
})
export class TecnoturisService {
    protected _config: TecnoturisServiceConfig;

    constructor(@Optional() config?: TecnoturisServiceConfig) {
        if (config) {
            this._config = config;
        } else {
            throw new Error(
                'config not provided. Please, add environment config in forRoot of TecnoturisModule');
        }
    }

    get config() {
        return this._config;
    }
}
