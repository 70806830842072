

export class TransferRequestDto {
    public language: string;
    public typeTransfer: string;
    public fromName?: string;
    public fromCode: string;
    public fromType: string;
    public toName?: string;
    public toCode: string; 
    public toType: string;
    public arrDateTime: string;
    public depDateTime?: string;
    public timeDeparture: string;
    public timeReturn?: string;
    public totalAdults: number;
    public totalChildren: number;
    public totalBabies: number;
    public listAges: any[];
    public dataAge: number[] | number | string | string[] 
    public agencyId: string | number

    constructor() {
        this.language = 'ES';
        this.typeTransfer = '';
        this.fromName = '';
        this.fromCode = '';
        this.fromType = '';
        this.toCode = '';
        this.toType = '';
        this.toName = '';
        this.arrDateTime = '';
        this.timeDeparture = ''
        this.depDateTime = '';
        this.timeReturn = '';
        this.totalAdults = 1;
        this.totalChildren = 0;
        this.totalBabies = 0;       
        this.dataAge = []; 
        this.listAges = [];
        this.agencyId = ''
       }
}

export class Ages {
    age: string | number; 
}

