import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Pipe({
    name: 'segmentForm',
    pure: true
})
export class SegmentFormPipe implements PipeTransform {
    transform(id: number, forms: FormArray): AbstractControl {
        const index = forms.controls.findIndex(c => {
            return c.value.id === id;
        });
        if (index === -1) {
            return null;
        }
        return forms.at(index).get('selected');
    }
}
