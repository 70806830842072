import { forwardRef, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AngularCalendarComponent } from './angular-calendar.component';
import { CalendarModule, DateAdapter, MOMENT } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { TranslateModule } from "@ngx-translate/core";
import * as moment_ from 'moment';
import { IonicModule } from "@ionic/angular";
import {MatTooltipModule} from '@angular/material/tooltip';
const moment = moment_;

registerLocaleData(localeEs);
registerLocaleData(localeEn);

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

@NgModule({
    declarations: [AngularCalendarComponent],
    imports: [
        IonicModule,
        CalendarModule.forRoot(
            {
                provide: forwardRef(() => DateAdapter),
                useFactory: forwardRef(() => momentAdapterFactory)
            }
        ),
        TranslateModule,
        CommonModule,
        MatTooltipModule
    ],
    providers: [
        {
            provide: MOMENT,
            useValue: moment
        }
    ],
    exports: [AngularCalendarComponent],
    entryComponents: [AngularCalendarComponent]
})
export class AngularCalendarModule {
}
