import { safeNumber } from '../../functions/index';
import { CocheSearchDto } from '../transports.model';

export class CarFilterReq {
    products?: string[];
    franchiseConditions?: string[];
    suppliers?: string[];
    seats?: number[];
    doors?: number[];
    airConditioners?: string[];
    transmissions?: string[];
    types?: string[];
    sizes?: string[];
    minPrice?: number;
    maxPrice?: number;
}

export class CarAvailabilityReq implements CarFilterReq {
    PickupLocationID: number;
    DropoffLocationID: number;
    PickupDate: string;
    DropoffDate: string;
    PickupTime: string;
    DropoffTime: string;
    ProductID?: number;
    DriversAge?: number;
    PickUpLocationName?: string;
    DropOffLocationName?: string;
    PickUpLocationCountry?: string;
    DropOffLocationCountry?: string;
    minPrice?: number;
    maxPrice?: number;
    page?: number;
    pageSize?: number;
    requestId?: string;
    products?: string[];
    franchiseConditions?: string[];
    suppliers?: string[];
    seats?: number[];
    doors?: number[];
    airConditioners?: string[];
    transmissions?: string[];
    types?: string[];
    sizes?: string[];

    static buildFromURLParams(
        urlParams: CocheSearchDto,
        page = 1,
        pageSize = 10,
        filter?: CarFilterReq,
        requestId?: string,
    ): CarAvailabilityReq {
        const {
            PickupLocationID,
            DropoffLocationID,
            PickupDate,
            DropoffDate,
            PickupTime,
            DropoffTime,
            ProductID,
            DriversAge,
            PickupLocationName,
            PickupLocationCountry,
            DropoffLocationName,
            DropoffLocationCountry,
        } = urlParams;
        const params = new CarAvailabilityReq();
        params.PickupLocationID = safeNumber(PickupLocationID);
        params.DropoffLocationID = safeNumber(DropoffLocationID);
        params.PickupDate = PickupDate;
        params.DropoffDate = DropoffDate;
        params.PickupTime = PickupTime;
        params.DropoffTime = DropoffTime;
        params.ProductID = ProductID;
        params.PickUpLocationName = PickupLocationName;
        params.PickUpLocationCountry = PickupLocationCountry;
        params.DropOffLocationName = DropoffLocationName;
        params.DropOffLocationCountry = DropoffLocationCountry;
        if (DriversAge) {
            params.DriversAge = safeNumber(DriversAge);
        }
        params.page = page;
        params.pageSize = pageSize;
        if (requestId) {
            params.requestId = requestId;
        }
        if (filter) {
            const {
                products,
                franchiseConditions,
                suppliers,
                seats,
                doors,
                airConditioners,
                transmissions,
                types,
                sizes,
                minPrice,
                maxPrice
            } = filter;

            if (products && products.length > 0) {
                params.products = products;
            }
            if (franchiseConditions && franchiseConditions.length > 0) {
                params.franchiseConditions = franchiseConditions;
            }
            if (suppliers && suppliers.length > 0) {
                params.suppliers = suppliers;
            }
            if (seats && seats.length > 0) {
                params.seats = seats;
            }
            if (doors && doors.length > 0) {
                params.doors = doors;
            }
            if (airConditioners && airConditioners.length > 0) {
                params.airConditioners = airConditioners;
            }
            if (transmissions && transmissions.length > 0) {
                params.transmissions = transmissions;
            }
            if (types && types.length > 0) {
                params.types = types;
            }
            if (sizes && sizes.length > 0) {
                params.sizes = sizes;
            }
            if (minPrice && minPrice > 0) {
                params.minPrice = minPrice;
            }
            if (maxPrice && maxPrice > 0) {
                params.maxPrice = maxPrice;
            }
        }
        return params;
    }

    static mergeParamsWithFilters(
        originalRequest: CarAvailabilityReq,
        carListParams: CarAvailabilityReq,
    ): CarAvailabilityReq {
        if (!originalRequest) { return carListParams; }
        const {
          products,
          franchiseConditions,
          suppliers,
          seats,
          doors,
          airConditioners,
          transmissions,
          sizes,
          types,
          minPrice,
          maxPrice
        } = originalRequest;
        const mappedCarListParams: CarAvailabilityReq = Object.assign({}, carListParams, {
          products,
          franchiseConditions,
          suppliers,
          seats,
          doors,
          airConditioners,
          transmissions,
          sizes,
          types,
          minPrice,
          maxPrice
        });

        return mappedCarListParams;
    }
}
