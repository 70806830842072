import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, Validators } from "@angular/forms";
import { Storage } from "@ionic/storage";
import { BehaviorSubject } from "rxjs";
import { TecnoturisService } from '../../tecnoturis.service';
import { EntityService } from './Entity.service';
import { Client } from '../interfaces/client.model';
import { NifValidatorIntranet } from "../validators/customValidators";

@Injectable({
    providedIn: 'root'
})

export class ClientServices {
    clientEntity: EntityService;
    client: Client;
    CLIENT_STORAGE_KEY = 'client';
    AGENCY_ID = '_login';
    subscription: BehaviorSubject<any> = new BehaviorSubject({});


    constructor(
        public http: HttpClient,
        protected _formBuilder: FormBuilder,
        protected storage: Storage,
        public tecnoturisService: TecnoturisService,
    ) {
        this.clientEntity = new EntityService(this.http, this.tecnoturisService.config.clientHost,
            'client/', tecnoturisService);
        this.getClient();
        this.subscription.subscribe(value => {});
    }

    initClientsForm(): any[] {
        const existingClientForm = this._formBuilder.group({
            clientAutocomplete: [''],
        });

        const newClientForm = this._formBuilder.group({
                id: [''],
                email: ['', [Validators.required, Validators.email]],
                name: ['', Validators.required],
                surname: ['', Validators.required],
                //document: ['', Validators.required],
                document: ['', ],
                phoneNumber: ['', Validators.required],
                //nationality: ['', Validators.required],
                isPax: [false, Validators.required]
            },
            // {
            //     validators: NifValidatorIntranet(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i)
            // }
            );
        // whats this ?
        if (this.client) {
            existingClientForm.controls['clientAutocomplete'].setValue(this.client);
        }
        return [existingClientForm, newClientForm];
    }

    // @TODO if client is changed, must be changed over all items, or get client from this manager
    addClient(client) {
        this.storage.get(this.CLIENT_STORAGE_KEY).then(storedClient => {
            this.storage.set(this.CLIENT_STORAGE_KEY, client);
            this.subscription.next(client);
            this.client = client;
        });
    }

    removeClient() {
        this.storage.get(this.CLIENT_STORAGE_KEY).then(() => {
            this.storage.set(this.CLIENT_STORAGE_KEY, null);
            this.subscription.next(null);
            this.client = new Client();
        });
    }

    getClient() {
        return this.storage.get(this.CLIENT_STORAGE_KEY).then(storedClient => {
            this.client = storedClient;
            this.subscription.next(this.client);
            return this.client;
        });
    }
}
