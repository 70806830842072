import { CircuitBookingPostReqDtoPassengerType, Passenger } from "@vecib2c/frontend-dto";
import { safeNumber } from "../../functions/index";
import { ManagementPax } from "../../interfaces/management.interface";

export class CircuitPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialCircuitPaxes: Passenger[],
    ): Passenger[] {
        const arr = paxes.map((pax, i) => {
            const initialCircuitPax = initialCircuitPaxes[i] || {} as Passenger;
            const {
                age: initialAge,
                documentId: initialDocumentId,
                gender: initialGender,
                type: initialPaxType,
            } = initialCircuitPax;

            const {
                name,
                last_name: lastName,
                dni,
                age,
                type,
                person_title: personTitle,
            } = pax;

            const bookingPax: Passenger = {
                gender: this.buildGender(personTitle, initialGender),
                name,
                surname: lastName,
                documentId: this.buildDocumentId(dni, initialDocumentId),
                age: this.buildAge(age, initialAge),
                type: this.buildPaxType(type, initialPaxType),
            };

            return bookingPax;
        });
        return arr;
    }

    private static buildGender(
        personTitle: string,
        initialGender = 'M',
    ): string {
        if (!personTitle) { return initialGender; }
        if (personTitle === 'Señor') { return 'M'; }
        if (['Señora', 'Señorita'].includes(personTitle)) { return 'F'; }
        return 'M';
    }

    private static buildDocumentId(
        documentNumber: string,
        initialDocumentId: string,
    ): string {
        if (!documentNumber) { return initialDocumentId; }
        return documentNumber;
    }

    private static buildAge(
        age: number,
        initialAge: number,
    ): number {
        if (!age) { return safeNumber(initialAge, 30); }
        return safeNumber(age, 30);
    }

    private static buildPaxType(
        paxType: string,
        initialPaxType = CircuitBookingPostReqDtoPassengerType.Adult,
    ): CircuitBookingPostReqDtoPassengerType {
        if (!paxType) { return initialPaxType; }
        const mappedPaxType = paxType.toLowerCase();
        if (['ad', 'adult'].includes(mappedPaxType)) { return CircuitBookingPostReqDtoPassengerType.Adult; }
        if (['ch', 'child'].includes(mappedPaxType)) { return CircuitBookingPostReqDtoPassengerType.Child; }
        if (['in', 'infant'].includes(mappedPaxType)) { return CircuitBookingPostReqDtoPassengerType.Child; }
        return CircuitBookingPostReqDtoPassengerType.Adult;
    }
}
