import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainBookingComponent } from './train-booking.component';
import {FormAddEditModule} from "../../form-add-edit/index";

@NgModule({
  declarations: [TrainBookingComponent],
  imports: [
    CommonModule,
    FormAddEditModule
  ]
})
export class TrainBookingModule { }
