import {Injectable} from "@angular/core";
import {
    HotelBookingPostReqDto,
    HotelBookingPostReqDtoOccupant,
    HotelBookingPostReqDtoRoomDetail,
    HotelBookingPostResDto,
} from "@vecib2c/frontend-dto";
import t from "typy";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ShopingBasketHotel, RoomList} from '../../../integrations/hotels/index';
import {BasketManager, HotelProviderService} from '../../../lib-shared/services/index';

const ADULT = 'AD';
const CHILDREN = 'CH';

@Injectable()
export class HotelBookServices {
    hotel: ShopingBasketHotel;
    occupants: FormGroup;
    nationalities = [];

    constructor(
        protected _formBuilder: FormBuilder,
        protected http: HttpClient,
        protected basketManager: BasketManager,
        protected router: Router,
        protected hotelProviderService: HotelProviderService,
    ) {
    }

    initOccupantsForm() {
        // @TODO refactor if possible
        // occupant base
        const generateOccupant = (type, defaultValue: HotelBookingPostReqDtoOccupant) => {

            return this._formBuilder.group({
                name: [t(defaultValue, 'name').safeString, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
                surname: [t(defaultValue, 'surname').safeString, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
                type: [type],
                age: [t(defaultValue, 'age').safeObject, Validators.required]
            });
        };

        // const hotel = this.hotel;
        const roomList = [];
        let storedRoomList;
        let obj;
        let occupantsDetails;
        if (this.hotel.bookingPostReqDto) {
            storedRoomList = this.hotel.bookingPostReqDto.roomDetails[0].roomList;
        } else {
            obj = new HotelBookingPostReqDtoOccupant();
        }

        const parseOccupants = (occupants) => {
            return {
                adults: occupants.filter(occupant => occupant.type === ADULT),
                children: occupants.filter(occupant => occupant.type === CHILDREN)
            };
        };

        this.hotel.roomList[0].distribution = this.hotel.distributionSelected;

        for (let i = 0; i < this.hotel.roomList[0].distribution.rooms; i++) {

            if (storedRoomList) {
                occupantsDetails = parseOccupants(storedRoomList[i].occupants);
            }

            const AdultsOccupantList = [];
            const childrenOccupantList = [];


            const adults = this.hotel.roomList[0].distribution.adults;

            for (let j = 0; j < adults; j++) {
                let occupant = t(occupantsDetails, `adults[${j}]`).safeObject;
                if (!occupant) {
                    occupant = new HotelBookingPostReqDtoOccupant();
                    occupant.age = 30;
                }
                AdultsOccupantList.push(generateOccupant(ADULT, occupant));
            }

            const childrenList = this.hotel.roomList[0].distribution.children;

            for (let k = 0; k < childrenList.length; k++) {
                childrenOccupantList.push(generateOccupant(CHILDREN, t(occupantsDetails, `children[${k}]`).safeObject));
            }

            roomList.push(this._formBuilder.group({
                adultsOccupants: this._formBuilder.array(AdultsOccupantList),
                childrensOccupants: this._formBuilder.array(childrenOccupantList),
            }));

        }

        // is it needed? maybe the only needed is roomList, the others can be gettend from this.hotel
        const hotelFormItem = this._formBuilder.group({
            // rateId: [this.hotel.roomList[0].rooms[0].rates[0].rateId, Validators.required],
            // roomId: [1, Validators.required],
            // hotelId: [this.hotel.hotelId, Validators.required],
            // checkIn: [this.hotel.hotelGetReq.checkIn, Validators.required],
            // checkOut: [this.hotel.hotelGetReq.checkOut, Validators.required],
            // price: [this.hotel.roomList[0].rooms[0].rates[0].price, Validators.required],
            // currency: ['EUR', Validators.required],
            roomList: this._formBuilder.array(roomList),
            // to show aditional data in the form
            hotelData: this.hotel
        });

        this.occupants = this._formBuilder.group({
            tolerance: 2,
            language: 'CAS',
            remarks: [''],
            hotelData: hotelFormItem
        });

        return hotelFormItem;
    }

    setDistribution() {
        if (typeof this.hotel.hotelGetReq.distribution === 'object') {
            return;
        }

        this.hotel.hotelGetReq.distribution = JSON.parse(this.hotel.hotelGetReq.distribution);
    }

    generateArray(): number[] {
        if (this.hotel.hasOwnProperty("bookingPostReqDto")) {
            const n = this.hotel.bookingPostReqDto.roomDetails[0].roomList.length;
            const arr = new Array(n);
            return arr;

        } else {
            // wired change for dto
            const n = parseInt(this.hotel.distributionSelected.rooms.toString(), 10);
            const arr = new Array(n);
            return arr;
        }
    }

    getCategory() {
        const stars = [1, 2, 3, 4, 5];
        return stars.filter((star: any) => {
            return star <= this.hotel.category.value;
        });
    }

    protected initBookingPost(occupants?): boolean {
        // if (this.occupants.valid) {
        const formValue = this.occupants.value;
        const bookingPost = new HotelBookingPostReqDto();

        bookingPost.language = formValue.language;
        bookingPost.providerName = formValue.hotelData.hotelData.providerName;
        bookingPost.remarks = formValue.remarks;
        bookingPost.tolerance = 100;
        bookingPost.roomDetails = [this.initPostReqDtoRoomDetail(occupants)];

        this.hotel.bookingPostReqDto = bookingPost;
        // return true;
        // }

        return false;
    }

    initBookingPostV2(occupants?) {
        this.initBookingPost(occupants);
    }

    async addToBasketAndRedirect(redirect = false, isMicrosite = false, occupants?) {
        // if (this.initBookingPost(occupants)) {
        this.initBookingPost(occupants);
        return await this.basketManager.addOrUpdate(this.hotel).then((data) => {
            if (redirect) {
                const path = isMicrosite ? '/home' : '/intranet';
                this.router.navigateByUrl(path, {replaceUrl: true});
            }
        }).catch(err => console.log(err));
        // } else {
        //     return await this.notificationPopupService.openPopup("OCCUPANTS_ERROR");
        // }
    }

    async addMultidistributionToBasket(
        shoppingBaskets: ShopingBasketHotel[],
        occupantForms: { form: FormGroup, id: number }[],
        rawShoppingBasket: ShopingBasketHotel,
    ) {
        const roomList: RoomList[] = occupantForms.map(f => {
            return f.form.value.roomList[0];
        });

        const bookingParameters = this.buildBookingParameters(shoppingBaskets, roomList);

        const mappedShoppingBasket: ShopingBasketHotel = Object.assign({}, rawShoppingBasket, {
            bookingPostReqDto: bookingParameters,
            integrationType: 'HOTEL'
        });

        return await this.basketManager.addOrUpdate(mappedShoppingBasket).then((data) => {
            return data;
        }).catch(err => console.log(err));
    }

    private buildBookingParameters(shoppingBaskets: ShopingBasketHotel[], roomList: RoomList[]): HotelBookingPostReqDto {
        const bookingParameters = new HotelBookingPostReqDto();
        bookingParameters.language = 'CAS';
        bookingParameters.providerName = shoppingBaskets[0].providerName;
        bookingParameters.remarks = '';
        bookingParameters.tolerance = 100;
        bookingParameters.roomDetails = shoppingBaskets.map((shoppingBasket, i) => {
            return this.buildRoomDetailParameters(shoppingBasket, roomList[i]);
        });
        return bookingParameters;
    }

    private buildRoomDetailParameters(shoppingBasket: ShopingBasketHotel, roomList: RoomList): HotelBookingPostReqDtoRoomDetail {
        const roomDetailParameters = new HotelBookingPostReqDtoRoomDetail();
        roomDetailParameters.checkIn = shoppingBasket.hotelGetReq.checkIn;
        roomDetailParameters.checkIn = shoppingBasket.hotelGetReq.checkIn;
        roomDetailParameters.currency = 'EUR';
        roomDetailParameters.hotelId = shoppingBasket.hotelId;
        roomDetailParameters.price = shoppingBasket.roomList[0].rooms[0].rates[0].price;
        roomDetailParameters.rateId = shoppingBasket.roomList[0].rooms[0].rates[0].rateId;
        roomDetailParameters.roomId = '1';
        roomDetailParameters.roomList = [{occupants: this.buildOccupants(roomList)}];
        return roomDetailParameters;
    }

    private buildOccupants(roomList: RoomList): HotelBookingPostReqDtoOccupant[] {
        const adults = t(roomList, 'adultsOccupants').safeArray;
        const children = t(roomList, 'childrensOccupants').safeArray;
        return [...adults, ...children];
    }

    initPostReqDtoRoomDetail(occupants?): HotelBookingPostReqDtoRoomDetail {
        const formValue = this.occupants.value;
        const bookingPostReqDtoRoomDetail = new HotelBookingPostReqDtoRoomDetail();
        // const hotel = formValue.hotelData;
        bookingPostReqDtoRoomDetail.checkIn = this.hotel.hotelGetReq.checkIn;
        bookingPostReqDtoRoomDetail.checkOut = this.hotel.hotelGetReq.checkOut;
        bookingPostReqDtoRoomDetail.currency = 'EUR';
        bookingPostReqDtoRoomDetail.hotelId = this.hotel.hotelId;
        bookingPostReqDtoRoomDetail.price = this.hotel.roomList[0].rooms[0].rates[0].price;
        bookingPostReqDtoRoomDetail.rateId = this.hotel.roomList[0].rooms[0].rates[0].rateId;
        bookingPostReqDtoRoomDetail.roomId = '1';
        bookingPostReqDtoRoomDetail.roomList = occupants ? occupants : [];

        // fill roomList occupants
        formValue.hotelData.roomList.forEach((person, i) => {
            const occupants = [];

            // fill occupants from component form
            person.adultsOccupants.forEach(occupant => {
                const bookingPostReqDtoOccupant = new HotelBookingPostReqDtoOccupant();
                bookingPostReqDtoOccupant.age = occupant.age;
                bookingPostReqDtoOccupant.name = occupant.name;
                bookingPostReqDtoOccupant.surname = occupant.surname;
                bookingPostReqDtoOccupant.type = occupant.type;
                occupants.push(bookingPostReqDtoOccupant);
            });

            // fill childrens from component form
            if (person.childrensOccupants.length > 0) {

                person.childrensOccupants.forEach(occupant => {
                    const bookingPostReqDtoOccupant = new HotelBookingPostReqDtoOccupant();
                    bookingPostReqDtoOccupant.age = occupant.age;
                    bookingPostReqDtoOccupant.name = occupant.name;
                    bookingPostReqDtoOccupant.surname = occupant.surname;
                    bookingPostReqDtoOccupant.type = occupant.type;
                    occupants.push(bookingPostReqDtoOccupant);
                });
            }
            bookingPostReqDtoRoomDetail.roomList[i] = {'occupants': occupants};
        });

        return bookingPostReqDtoRoomDetail;
    }

    booking(item: ShopingBasketHotel, client, saveBudget = true) {
        const provider = item.providers.find(p => p.providerName === item.providerName);
        item.bookingPostReqDto.holder = client;
        item.bookingPostReqDto.holderId = client.id;
        item.bookingPostReqDto.categoryRoom = item.roomList[0].rooms[0].rates[0].category;
        item.bookingPostReqDto.requestToken = provider ? provider.requestToken : undefined;
        return this.hotelProviderService.book(item.bookingPostReqDto, item, saveBudget)
            .toPromise()
            .catch(err => {
                console.log('err on hotel-booking', err);
                Promise.reject();
                item.failed = true;
                item.holder = client.id;
                return item;
            });
    }

    cancel(item: HotelBookingPostResDto) {
        return this.hotelProviderService.cancel(item).toPromise();
    }
}
