import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {of, Subject} from 'rxjs';
import {MatDialog} from '@angular/material';
import {concatMap, debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AuthenticationService, DynamicCrudService, ManagementService} from '../lib-shared/services/index';
import {FieldType, IDynamicCrud, IDynamicCrudQueryParams, Setup} from '../lib-shared/interfaces/index';
import {MANAGEMENT_MODULES, ROLES} from '../const';
import {AgencyChangeService} from '../agency-change/agency-change.service';
import {DynamicCrudUtils} from '../utils';
import {DialogComponent} from '../dialog';
import {PageEvent} from "@angular/material/paginator";
import {HttpParams} from "@angular/common/http";
import {DynamicTableComponent} from "./dynamic-table/dynamic-table.component";

@Component({
    selector: 'lib-dynamic-crud',
    templateUrl: './dynamic-crud.component.html',
    styleUrls: ['./dynamic-crud.component.scss'],
    providers: [DynamicCrudService],
    encapsulation: ViewEncapsulation.None
})
export class DynamicCrudComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('dynamicTable', { static: false }) dTable: DynamicTableComponent;

    @Output() addCommercialEvent: EventEmitter<number> = new EventEmitter<number>();
    @Input() dynamicCrud: IDynamicCrud;

    @Input() addButtonTemplate: TemplateRef<any>;
    @Input() descriptionTemplate: TemplateRef<any>;
    @Input() queryParams = null;
    @Input() extraDeleteQueryParams: HttpParams;

    filtersForm: FormGroup;

    data: Array<any>;

    dataFiltered: Array<any>;

    entityId: number;

    @Input() idFilter: any;

    @Input() custom = false;

    @Input() selectable = false;

    @Input() selectableUnique = false;

    @Output() itemsSelected: EventEmitter<number[]> = new EventEmitter<number[]>();

    @Input() changeUrlPathList = true;

    @Input() excludeFromData: number[];

    @Output() dataList: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Input() tableTemplate: TemplateRef<any>;

    @Input() customEdit = false;

    @Input() showEdit = true;

    @Input() showDelete = true;

    @Input() showAdd = true;

    @Input() showDownload = false;

    @Input() downloadKeyName: string;

    @Output() customEditEvent: EventEmitter<number> = new EventEmitter<number>();

    @Output() saveOuputData: EventEmitter<any> = new EventEmitter<any>();

    @Output() saveChildOutputData: EventEmitter<{ crud: string, data: any }> = new EventEmitter<{ crud: string, data: any }>();

    formlyFieldsFilter: FormlyFieldConfig[];

    @Input() setup: Setup = {};
    @Input() paginator = false;
    @Input() filterByBackend = false;
    @Input() changePageByBackend = true;
    @Input() managementModule: MANAGEMENT_MODULES;

    @Input() isDeletePathCustomize = false;

    @Output() emitDelete: EventEmitter<any> = new EventEmitter<any>();

    private unsubscribe: Subject<void> = new Subject();

    @Input() markAsSelected: any[] = [];

    auth = AuthenticationService.getUser();

    ROLES = ROLES;

    page: PageEvent;
    count = 10;


    constructor(
        private fb: FormBuilder,
        private dynamicCrudService: DynamicCrudService,
        private router: Router,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private agencyChangeService: AgencyChangeService,
        private managementService: ManagementService
    ) {
        this.agencyChangeService.getAgencyChainList()
            .pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(agencyChains => {
            const agencies = [];
            agencyChains.forEach(a => agencies.push(...a.agencies));
            this.setup = {...this.setup, ...{agency_chain: agencyChains, agency: agencies}};
        });
        this.route.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data['setup']) {
                    this.setup = {...this.setup, ...data['setup']};
                }
            });
        this.managementService.getCommercialEvent()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                this.addCommercialEvent.emit(value);
            });
    }

    ngOnInit() {
        this.setIdFilter();
        this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
        if (this.dynamicCrud.filters) {
            this.filtersForm = this.getFiltersForm();
            setTimeout(() => {
                this.filterData();
                this.getOptions();
                // if (this.auth && this.auth.role && this.auth.role.id !== ROLES.SUPER_USER) {
                //     this.filterAgency();
                // }
            }, 50);
        }
        this.list();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dynamicCrud && !changes.dynamicCrud.firstChange) {
            if (this.dynamicCrud) {
                this.setIdFilter();
                this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
                if (this.dynamicCrud.filters) {
                    this.filtersForm = this.getFiltersForm();
                }
                this.list();
            }
        }
    }

    setIdFilter(): void {
        if (this.idFilter) {
            const crudData = JSON.parse(JSON.stringify(this.dynamicCrud));
            if (this.changeUrlPathList) {
                crudData.urlPathList = crudData.urlPathList.replace('id', this.idFilter);
            }
            this.dynamicCrud = crudData;
        }
    }

    filterAgency(): void {
        if (DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, 'agency_chain')
            && DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, 'agency')) {
            this.agencyChangeService.getAgencyChain()
                .subscribe(value => {
                    if (value && this.auth && this.auth.role && this.auth.role.id !== ROLES.SUPER_USER) {
                        this.dataFiltered = this.filterByKey('agency_chain', value);
                    }
                });

            this.agencyChangeService.getAgency()
                .subscribe(value => {
                    if (value && this.auth && this.auth.role && this.auth.role.id !== ROLES.SUPER_USER) {
                        this.dataFiltered = this.filterByKey('agency', value);
                    }
                });
        }
    }

    private getFiltersForm(): FormGroup {
        this.formlyFieldsFilter = DynamicCrudUtils.generateFormlyFromFields(this.dynamicCrud.filters);
        const defaultForm = {};
        return this.fb.group(defaultForm);
    }

    filterData(): void {
        this.filtersForm
            .valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(r => this.filterByBackend)
            ).subscribe(value => {
            this.list(value);
        });

        Object.keys(this.filtersForm.controls).forEach(k => {
            this.filtersForm.get(k)
                .valueChanges
                .pipe(
                    filter(r => !this.filterByBackend)
                )
                .subscribe(value => {
                    this.dataFiltered = this.filterByKey(k, value);
                });
        });
    }

    private filterByKey(key: string, value): any[] {
        return this.data ? this.data.filter(d => {
            if (value) {
                if (typeof value === 'string') {
                    return (d[key] as string).toLowerCase().trim().includes(value.toString().toLocaleLowerCase());
                } else if (typeof value === 'number') {
                    return d[key] === value;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }) : this.data;
    }

    getOptions(): void {
        const options = this.getFieldOptions();
        if (this.setup && this.formlyFieldsFilter) {
            options.forEach(o => {
                const field = DynamicCrudUtils.getFieldByKeyName(this.dynamicCrud, o);
                const option = (field.optionKey || o).replace('Id', '');
                if (this.setup[option]) {
                    DynamicCrudUtils.setDinamycallyOptions(this.formlyFieldsFilter, o, this.setup[option]);
                }
            });
        }
    }

    getFieldOptions(fieldType = FieldType.LIST): string[] {
        const options = [];
        this.dynamicCrud.fields.forEach(f => {
            if (f.isGroup) {
                options.push(...f.fieldChilds.filter(fc => fc.type === fieldType).map(fc => fc.keyName));
            } else {
                if (f.type === fieldType) {
                    options.push(f.keyName);
                }
            }
        });
        return options;
    }

    list(queryParams?: IDynamicCrudQueryParams): void {
        if (this.dynamicCrud.urlPathList) {
            this.dynamicCrudService.setHost(this.dynamicCrud.urlPathList);
        }
        this.dynamicCrudService.list<Array<any>>(queryParams, this.page ? this.page.pageIndex + 1 : 1)
            .subscribe((data: any) => {
                this.data = data && data.results ? data.results : data;
                this.count = data.count;
                if (this.excludeFromData) {
                    this.data = this.data.filter(d => !(this.excludeFromData.indexOf(d.id) > -1));
                }
                this.dataFiltered = [...this.data];
                this.dataList.emit(this.data);
                // this.data = data.results;
            }, error => {
                this.page = null;
                this.dTable.paginator.firstPage();
                this.list(queryParams);
            });
        this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
    }

    getDataFromFilters() {
        const dataForm = {};
        Object.keys(this.filtersForm.controls)
            .filter(k => this.filtersForm.get(k).value)
            .forEach(k => dataForm[k] = this.filtersForm.get(k).value);
        return dataForm;
    }

    add(): void {
        if (this.entityId) {
            this.router.navigate(['intranet', 'management', this.dynamicCrud.crud, this.entityId, 'create']);
        } else {
            this.router.navigate(['intranet', 'management', this.dynamicCrud.crud, 'create']);
        }
    }

    searchByQueryParams(): void {
        this.list(this.getDataFromFilters());
    }

    edit(id: number): void {
        if (this.customEdit) {
            this.customEditEvent.emit(id);
        } else {
            this.router.navigate(['intranet', 'management', this.dynamicCrud.crud, 'edit', id], {
                queryParams: this.queryParams
            });
        }
    }

    delete(id: number): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar el registro?', id: id}
        });

        dialogRef.afterClosed().pipe(
            concatMap((param: number) => {
                if (this.dynamicCrud.deleteUrl) {
                    this.dynamicCrudService.setHost(this.dynamicCrud.deleteUrl);
                }

                return param ? this.dynamicCrudService.delete(param, this.extraDeleteQueryParams) : of();
            }),
            takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.dynamicCrudService.setHost(this.dynamicCrud.urlPath);
                this.data = this.data.filter(el => el.id !== id);
                this.dataFiltered = [...this.data];
                this.emitDelete.emit();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    changePage(event: PageEvent) {
        this.page = event;
        if (this.filterByBackend) {
            this.list(this.getDataFromFilters());
        } else {
            if (this.changePageByBackend) {
                this.list();
            }
        }
    }

}
