import { HotelListResDto } from "@vecib2c/frontend-dto";

export class HotelListResMapper {

    static calculatePVP(hotelList: HotelListResDto): number {
        if (!hotelList) { return 0; }
        return hotelList.rows[0].roomList.reduce((roomListAccumulator, list) => roomListAccumulator + list.rooms[0].rates[0].price, 0 );
    }

}
