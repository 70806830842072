import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FieldType, IDynamicCrud } from '../lib-shared/interfaces/index';
import { DynamicCrudComponent } from '../dynamic-crud/index';
import { DynamicCrudService, ManagementService } from '../lib-shared/services/index';

const dynamicCrud: IDynamicCrud = {
    showFilters: false,
    crud: 'files',
    name: 'Ficheros adjuntos',
    urlPath: 'booking-service/files/',
    urlPathList: 'booking-service/id/files/',
    fields: [
        {
            isGroup: true,
            name: '',
            keyName: '',
            fieldChilds: [
                {
                    keyName: 'file',
                    name: 'Nombre',
                    type: FieldType.CHAR,
                    showInTable: false
                },
                {
                    keyName: 'name',
                    name: 'Nombre',
                    type: FieldType.CHAR,
                    showInTable: true,
                },
            ]
        },
    ]
};

@Component({
    selector: 'lib-management-files',
    templateUrl: './management-files.component.html',
    styleUrls: ['./management-files.component.scss']
})
export class ManagementFilesComponent implements OnInit {
    @Input() idFilter: any;
    @Input() key: string;

    @Input() path: string;
    @Input() urlPathList: string;
    dynamicCrud: IDynamicCrud;

    @ViewChild(DynamicCrudComponent, { static: false }) crudComponent: DynamicCrudComponent;

    constructor(private managementService: ManagementService, private dynamicCrudService: DynamicCrudService) {
    }

    ngOnInit() {
        dynamicCrud.urlPath = this.path;
        dynamicCrud.urlPathList = this.urlPathList;
        this.dynamicCrud = dynamicCrud;
    }

    addFile(event): void {
        const formData = new FormData();
        formData.append(this.key, this.idFilter);
        formData.append('file', event.target.files[0]);
        this.dynamicCrudService.setHost(this.path);
        this.dynamicCrudService.add(formData)
            .subscribe(value => {
                this.crudComponent.list();
            });
    }

}
