import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatOptionModule } from '@angular/material';
import { MunicipalityAutocompleteComponent } from './municipality-autocomplete.component';
import {LibSharedModule, MunicipalityService} from "../lib-shared/index";

@NgModule({
    declarations: [
        MunicipalityAutocompleteComponent,
    ],
    imports: [
        LibSharedModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        FormsModule,
        MatInputModule,
    ],
    providers: [MunicipalityService],
    exports: [
        MunicipalityAutocompleteComponent
    ]
})
export class MunicipalityAutocompleteModule {
}
