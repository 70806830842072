import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BookEditComponent } from '../../book-edit.component';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { CircuitsBookServices } from '../services/circuits-book.services';
import { FormArray } from '@angular/forms';
import { TecnoturisService } from '../../../tecnoturis.service';
import saveAs from 'file-saver';
import { Client } from '../../../lib-shared/interfaces/index';
import { AuthenticationService, BasketManager, ClientServices, NotificationPopupService } from '../../../lib-shared/services/index';
import { BaseEntity } from '../../../lib-shared/Entities/index';
import { ROLES } from '../../../const';
import { AgencyChangeService } from '../../../agency-change/index';

@Component({
    selector: 'lib-circuits-book-edit',
    templateUrl: './circuits-book-edit.component.html',
    styleUrls: ['./circuits-book-edit.component.scss'],
    providers: [
        CircuitsBookServices,
        ClientServices
    ]
})
export class CircuitsBookEditComponent extends BookEditComponent implements OnInit {

    circuitData: any;
    nationalities: any;
    genders = [
        {value: 'M', name: 'Masculino'},
        {value: 'F', name: 'Femenino'}
    ];
    baseEntity: BaseEntity;
    newClient: boolean;
    arrForDistribution = [];
    circuitFormItem: any;
    resignationSafeCheck = false;
    auth = AuthenticationService.getUser();
    @Input() onlyResumeInfo = false;

    constructor(
        protected _circuitsBookServices: CircuitsBookServices,
        private activatedRoute: ActivatedRoute,
        protected _basketManager: BasketManager,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected location: Location,
        protected notification: NotificationPopupService,
        protected agencyChangeService: AgencyChangeService,
        protected tecnoturisService: TecnoturisService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
        this.baseEntity = new BaseEntity(this.http, this.tecnoturisService);
        this.newClient = false;
    }

    ngOnInit() {
        [this.existingClientForm, this.newClientForm, this.clientList$] = this.clientServices.initClientsForm();
        this.clientList$ = this.setClientListenerForAutocomplete(this.existingClientForm);
        const environment = this.tecnoturisService.config;
        const nationalitiesEntity = this.baseEntity.getEntity(environment.nationalityHost, 'nationality');
        nationalitiesEntity.list(1, 300, null, {sortField: 'name', sortDirection: 'ASC'}).subscribe(nationalities => {
            this.nationalities = nationalities.rows;
            // todas las nacionalidades posibles
            this._circuitsBookServices.nationalities = this.nationalities;
            this.newClientForm.controls['nationality'].setValue(this.nationalities[207].code);
        });

        this.activatedRoute.paramMap
            .pipe(map(() => window.history.state))
            .subscribe(state => {
                if (state.circuit) {
                    this._circuitsBookServices.circuit = { ...state.circuit, integrationType: 'CIRCUIT' };
                    this.initForm();
                }

                const uuid = this.activatedRoute.snapshot.params['id'];

                this._basketManager.getList().then(data => {
                    const circuit = data.find(item => item.uuid === uuid);
                    if (circuit) {
                        this._circuitsBookServices.circuit = circuit;
                        this.initForm();
                    }
                    this.arrForDistribution = this._circuitsBookServices.generateArray();
                });
            });
    }

    checkSafeCheck(value) {
        if (value === false) {
            this.downloadPdfResignationSafe();
        }
    }

    downloadPdfResignationSafe() {
        const pdfUrl = 'assets/files/pdf/renuncia-al-seguro.pdf';
        const pdfName = 'renuncia-al-seguro.pdf';
        saveAs(pdfUrl, pdfName);
    }

    initForm() {
        this.circuitData = this._circuitsBookServices.circuit;
        this._circuitsBookServices.setDistribution();
        this.circuitFormItem = this._circuitsBookServices.initOccupantsForm();

        this.clientService.subscription
            .pipe(filter(client => client && !(Object.keys(client).length === 0) && client.isPax))
            .subscribe((client: Client) => {
                const occupants = ((this.circuitFormItem.get('roomList') as FormArray).at(0).get('adultsOccupants') as FormArray).at(0);
                if (occupants.get('name').value === '') {
                    occupants.get('name').setValue(client.name);
                    occupants.get('surname').setValue(client.surname);
                    occupants.get('documentId').setValue(client.document);
                }
            });
    }

    goToSummaryOrConfirmation() {
        if (super.checkClient()) {
            this._circuitsBookServices.goToSummaryOrConfirmation(this.microsite);
        }
    }

    addToBasketAndRedirect(redirectPath?: string) {
        if (super.checkClient()) {
            const path = !redirectPath ? this.redirectToPath() : redirectPath;
            this._circuitsBookServices.addToBasketAndRedirect(this.microsite, path);
        }
    }

    getDateCircuit() {
        const dateCircuit = JSON.parse(atob(this.circuitData.rateSelected.rateKey));
        return dateCircuit.fecha_salida;
    }

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }

}
