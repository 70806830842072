import {
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatOptionModule,
    MatSidenavModule,
    MatDividerModule,
    MatSortModule,
    MatToolbarModule,
    MatBadgeModule,
    MatExpansionModule,
    MatGridListModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatRadioModule
} from "@angular/material";
import {NgModule} from "@angular/core";

@NgModule({
    exports: [
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
        MatButtonModule,
        MatDialogModule,
        MatOptionModule,
        MatSidenavModule,
        MatDividerModule,
        MatSortModule,
        MatToolbarModule,
        MatBadgeModule,
        MatExpansionModule,
        MatGridListModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatMenuModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatRadioModule
    ]
})
export class MaterialModule {
}
