import { Component, OnInit, Inject, OnDestroy, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    PaymentMethod,
    IBookingService,
    PrepaidForm,
    Prepaid,
} from '../../../../lib-shared/interfaces/management.interface';
import * as moment_ from 'moment';
import { roundNumber } from "../../../../lib-shared/functions/index";

const moment = moment_;

interface ModalData {
    paymentMethods: PaymentMethod[];
    bookingService: IBookingService;
    prepaids: Prepaid[];
}

@Component({
    selector: 'lib-prepaid-modal',
    templateUrl: './prepaid-modal.component.html',
    styleUrls: ['./prepaid-modal.component.scss']
})
export class PrepaidModalComponent implements OnInit, OnDestroy {

    form: FormGroup;
    paymentMethods: PaymentMethod[];
    bookingService: IBookingService;
    prepaids: Prepaid[];
    remainingPrepaid = 0;

    @Output() save = new EventEmitter<PrepaidForm>();

    constructor(
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        public dialogRef: MatDialogRef<PrepaidModalComponent>,
    ) {
        const { paymentMethods, bookingService, prepaids } = data;
        this.paymentMethods = paymentMethods;
        this.bookingService = bookingService;
        this.prepaids = prepaids;
    }

    ngOnInit(): void {
        this.remainingPrepaid = this.calculateRemainingPrepaid(this.prepaids);
        this.initializeForm(this.remainingPrepaid);
    }

    ngOnDestroy(): void {

    }

    setPaymentDate(date: Date): void {
        const validatedDate = date ? moment(date).format('YYYY-MM-DD') : null;
        this.form.get('paymentDate').setValue(validatedDate);
        this.form.get('paymentDate').markAsTouched();
    }

    private initializeForm(remainingPrepaid: number): void {
        this.form = this.fb.group({
            paymentMethod: ['', Validators.required],
            paymentAmount: [remainingPrepaid, [
                Validators.required,
                Validators.min(0.1),
                Validators.max(remainingPrepaid),
            ]],
            paymentDate: ['', Validators.required],
        });
    }

    private calculateRemainingPrepaid(prepaids: Prepaid[] = []): number {
        const prepaid_total = prepaids.reduce((acc, prepaid) => {
            return acc + prepaid.payment_amount;
        }, 0);
        return roundNumber(this.bookingService.price_from_provider - prepaid_total);
    }

}
