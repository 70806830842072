import { HotelBookingPostReqDtoOccupant, HotelBookingPostReqDtoOccupantType } from "@vecib2c/frontend-dto";
import { MultidestinationPassenger } from '../multidestination/multidestination-pre-booking.model';
import * as moment_ from 'moment';

const moment = moment_;

export class HotelBookingPostReqOccupantMapper {

    static buildFromPassengers(
        passengers: MultidestinationPassenger[] = [],
    ): HotelBookingPostReqDtoOccupant[] {
        const arr = passengers.map(passenger => {
            const { roomNumber, birthdate, name, lastname, type } = passenger;
            return {
                age: moment().diff(moment(birthdate, 'YYYY/MM/DD'), 'years'),
                name,
                surname: lastname,
                type: HotelBookingPostReqDtoOccupantType[type],
                roomNumber,
            };
        });
        return arr;
    }
}
