import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HotelFlightReqDto, HotelFlightsListResDto } from '@vecib2c/frontend-dto/dist/dto/HotelFlight';
import { concatMap, tap } from 'rxjs/operators';
import { HotelProviderService } from './hotel-provider.service';
import { TecnoturisService } from '../../tecnoturis.service';
import { objectToQuerystring } from '../functions/index';
import {ClientServices} from "./client.services";
import {ManagementService} from "./management.service";
import {IntegrationsService} from "./integrations.service";

@Injectable({
    providedIn: 'root'
})
export class HotelFlightService {

    constructor(
        private http: HttpClient,
        private hotelProviderService: HotelProviderService,
        private tecnoturisService: TecnoturisService,
        private clientService: ClientServices,
        private managementService: ManagementService,
        private integrationsService: IntegrationsService
    ) {
    }

    private getPath(action: string, id: number = null): string {
        const host = this.tecnoturisService.config.hotelsAndFlights;

        let list: any = [`${host}`, action];
        if (id) {
            list = [...list, id];
        }
        return list.join("/");
    }

    public list(req: Partial<HotelFlightReqDto>): Observable<HotelFlightsListResDto> {
        const path = this.getPath("search/list");
        return this.hotelProviderService.auth().pipe(
            concatMap(() => {
                return this.http.get<HotelFlightsListResDto>(path + objectToQuerystring(req));
            })
        );

    }

    book(reqParams: any, saveBudget = true): Observable<any> {
        const url = this.getPath("booking");
        return this.http.post<any>(url, reqParams).pipe(tap(async response => {
            if (saveBudget) {
                const client = await this.clientService.getClient();
                const dossier = this.integrationsService.validAndGetDossier();
                await this.managementService.addServicesToDossier({hfData: [response], client: client.id, dossier: dossier}).toPromise();
            }
        }));
    }

}
