import { DestinationType } from "../../../../../../mock/packages";

export class DestinationRequest {
    destinationType: DestinationType;
}

export class InfoAirportsPackage{
    airportOriginCity?: string;
    airportOriginName?: string;
    airportArrivalCity?: string;
    airportArrivalName?: string;
    scaleOutwardCity?: string;
    scaleReturnCity?: string
}
