import { NgModule } from '@angular/core';
import { MatFormFieldModule, MatInputModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LibSharedModule } from '../../../lib-shared/index';
import { PaxesPackageComponent } from './paxes-package.component';

@NgModule({
    declarations: [PaxesPackageComponent],
    imports: [
        LibSharedModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [PaxesPackageComponent]
})
export class PaxesPackageModule {
}
