import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getIntegerPart'
})
export class GetIntegerPartPipe implements PipeTransform {

    transform(price: any, withDecimal = false, flightPrice?, safePrice?): any {
        price = Number(price);

        if (flightPrice) {
            price += Number(flightPrice);
        }

        if (safePrice) {
            price += Number(safePrice);
        }

        const stringGetPrice = price.toString();
        if (stringGetPrice.indexOf('.') > -1) {
            //return stringGetPrice.substring(0, stringGetPrice.indexOf('.') + 1);
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR'}).format(price).slice(0, -4)
        } else {
            if (withDecimal) {
                //return price.toString() + '.';
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR'}).format(price).slice(0, -4)
            }
            //return price;
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR'}).format(price).slice(0, -4)
        }
    }

}
