import { safeNumber } from '../../../lib-shared/functions/index';
import { IBookingItem, IntegrationType, ManagementPax } from '../../../lib-shared/interfaces/index';
import { ShopingBasketInsurance } from '../../insurances';
import { ShopingBasketHotel } from './ShopingBasketHotel';
import { ShoppingBasketFlight } from './ShoppingBasketFlight';

export class ShoppingBasketHFS implements IBookingItem {
    uuid: string;
    client: any;
    price: number;
    clonedData: any;
    cancelPolicies: [];
    validateMessage?: string;
    checkForm?: boolean;
    comments?: { clientComment?: string, agentComment?: string, updatedPrice: number };
    totalPrice?: number;
    hfs?: boolean;
    integrationType: IntegrationType;
    reratedPrice?: number;
    originalPrice?: number;
    isSelected?: boolean;
    canBeBooked?: boolean;

    static initializeComments(
        item: ShoppingBasketHFS,
    ): ShoppingBasketHFS {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.price),
        };

        return item;
    }


    static updateFromManagementBudget(
        item: ShoppingBasketHFS,
        paxes: ManagementPax[],
    ): ShoppingBasketHFS {
        if (!item.clonedData || !item.clonedData[0]) { return { ...item }; }
        const { hotel, flight, safe: insurance } = item.clonedData[0];
        const hotelBasket = ShopingBasketHotel.updateFromManagementBudget(hotel[0], paxes);
        const flightBasket = ShoppingBasketFlight.updateFromManagementBudget(flight, paxes);
        const insuranceBasket = ShopingBasketInsurance.updateFromManagementBudget(insurance, paxes);

        return {
            ...item,
            clonedData: [
                {
                    hotel: [hotelBasket],
                    flight: flightBasket,
                    safe: insuranceBasket,
                },
            ],
        };
    }
}
