import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TecnoturisService } from '../../tecnoturis.service';
import { Observable } from 'rxjs';
import { IBudget, IBudgetResponse } from '../interfaces/budget.interface';

@Injectable({
  providedIn: "root"
})
export class BudgetService {

  constructor(
    private readonly http: HttpClient,
    private readonly tecnoturisService: TecnoturisService
  ) {}

  book(reqParams: IBudget): Observable<IBudgetResponse> {
    const url = this.getPath("booking/budget/");
    return this.http.post<IBudgetResponse>(url, reqParams);
  }

  private getPath(action: string, id: number = null): string {
      const host = this.tecnoturisService.config.management;

      let list: any = [`${host}`, action];
      if (id) {
          list = [...list, id];
      }
      return list.join("/");
  }



}
