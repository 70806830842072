import { safeNumber } from "../functions/utils";

export class AgencyCommission {

    static calculatePVP(price: number, commissionRate: number, fee: number, iva: number, nonCommissionableRate = 0): number {
        const safeCommissionRate = safeNumber(commissionRate);
        const safeFee = safeNumber(fee);
        const safeIva = safeNumber(iva);
        const safePrice = safeNumber(price);

        return safePrice + (safePrice * safeCommissionRate / 100 * (1 + safeIva / 100)) + safeFee + nonCommissionableRate;
    }

    static calculateCommission(price: number, commissionRate: number, iva: number): number {
        const safeCommissionRate = safeNumber(commissionRate);
        const safeIva = safeNumber(iva);
        const safePrice = safeNumber(price);
        return safePrice * safeCommissionRate / 100 * (1 + safeIva / 100);
    }

    static calculatePVPFromCalculatedCommission(price: number, commision: number, fee: number, nonCommissionableRate = 0) {
        const safeFee = safeNumber(fee);
        const safePrice = safeNumber(price);

        return safePrice + commision + safeFee + nonCommissionableRate;
    }
}
