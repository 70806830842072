import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ServiceTypeModalComponent } from './service-type-modal/service-type-modal.component';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { BasketManager } from "../../../lib-shared/services/BasketManager";
import { ClientServices } from "../../../lib-shared/services/client.services";
import { ManagementClientService } from "../../../management-client/management.client.service";
import { PaymentModalComponent } from "./payment-modal/payment-modal.component";
import { AuthenticationService } from '../../../lib-shared/services/authentication.service';
import {
    BookingList,
    Budget,
    IBookingService,
    IDynamicCrud,
    IService,
    IUserInfo,
    PaymentDossier,
    PaymentOption,
    Prepaid,
    PrepaidRequest,
    SupplierInvoice,
    SupplierInvoiceRequest,
} from '../../../lib-shared/interfaces/index';
import { t } from 'typy';
import { of } from 'rxjs';
import { BaseAddEdit } from '../../../base-add-edit';
import { dossierFormMetaData } from '../dossier.form';
import { DynamicCrudService, ManagementService } from '../../../lib-shared/services/index';
import { DialogComponent } from '../../../dialog/index';
import { TecnoturisService } from '../../../tecnoturis.service';
import {AbonoModalComponent} from "./abono-modal/abono-modal.component";
import { PrepaidModalComponent } from './prepaid-modal/prepaid-modal.component';
import { SupplierInvoiceModalComponent } from './supplier-invoice-modal/supplier-invoice-modal.component';
import {DynamicCrudUtils} from "../../../utils";
import { ShoppingBasketFactory } from '../../../lib-shared/factories/shopping-baskets/shopping-basket.factory';

@Component({
    selector: 'lib-dossier-add-edit',
    templateUrl: './dossier-add-edit.component.html',
    styleUrls: ['./dossier-add-edit.component.scss']
})
export class DossierAddEditComponent extends BaseAddEdit implements OnInit {
    dynamicCrud: IDynamicCrud = dossierFormMetaData;

    bookings: BookingList[] = [];

    services: IService[];
    client;
    type;

    list = true;

    bookingServices: IBookingService[] = [];
    payments: PaymentDossier[] = [];
    cashOptions: PaymentOption[] = [];
    bankOptions: PaymentOption[] = [];
    userInfo: IUserInfo;
    creditCardOptions: PaymentOption[] = [];
    budgets: Budget[] = [];
    abonos: Budget[] = [];
    prepaids: Prepaid[] = [];
    supplierInvoices: SupplierInvoice[] = [];

    @Input() showActions = {
        back: true,
        budget: true,
        bond: true,
        abono: true,
        presupuesto: true,
        save: true,
        bookingList: true,
        paymentList: true,
        budgetList: true,
        abonoList: true,
        managementFiles: true
    };

    constructor(route: ActivatedRoute,
                router: Router,
                dynamicCrudService: DynamicCrudService,
                managementService: ManagementService,
                private managementClientService: ManagementClientService,
                private basketManagerService: BasketManager,
                private clientService: ClientServices,
                private readonly matDialog: MatDialog,
                public location: Location,
                private tecnoturisService: TecnoturisService) {
        super(route, router, dynamicCrudService, managementService, location);
        if (this.tecnoturisService.config.forms.dossier) {
            this.dynamicCrud = this.tecnoturisService.config.forms.dossier;
        }
        if (this.tecnoturisService.config.actions.dossier) {
            this.showActions = this.tecnoturisService.config.actions.dossier;
        }
        this.services = this.route.snapshot.data.setup['service'];
        this.client = this.route.snapshot.queryParamMap.get('client');
        this.type = this.route.snapshot.queryParamMap.get('type');
        if (this.type) {
            this.changeInputLabel(+this.type);
            this.changeValuesStatus(+this.type);
        }

        if (this.client) {
            this.customObjectToSave = {client: this.client};
        }

        this.getObjectChange.subscribe((dossier: any) => {
            this.type = dossier.type;
            this.changeInputLabel(+this.type);
            this.changeValuesStatus(+this.type);
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.userInfo = AuthenticationService.getUser();
        if (this.id) {
            this.getBookings();
            this.getPayments();
            this.getCashOptions();
            this.getBankOptions();
            this.getCreditCardOptions();
            this.getBudgets();
            this.getAbonos();
            this.getPrepaids();
            this.getSupplierInvoices();
        }
    }

    goBack(){
        this.location.back()
    }

    addNewService(): void {

        const dialogRef = this.matDialog.open(ServiceTypeModalComponent, {
            panelClass: 'dialog-commercial-activities',
            data: {
                services: this.services
            }
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res: IService) => {
                if (res) {
                    this.managementService.addServiceBooking({dossier: this.model.id, service: res.id})
                        .subscribe(hotelBooking => {
                            this.router.navigate(['intranet', 'management', `${res.name.toLowerCase().trim()}-booking`,
                                'edit', hotelBooking.id], {
                                queryParams: {
                                    dossier: this.id,
                                }
                            });
                        });
                }
            });
    }

    getBookingsSelected(bookings): void {
        this.bookings = bookings;
    }

    generateBudget(type: number): void {
        if (this.bookings.length) {
            if (this.bookings.length > 0) {
                this.generateBudgetForSelectedService(type);
            }
        } else {
            this.generateBudgetForAllServices();
        }
    }

    generateAbono(type: number): void {
        if (this.bookings.length) {
            if (this.bookings.length > 0) {
                this.generateAbonoForSelectedService(type);
            }
        } else {
            this.generateAbonoForAllServices();
        }
    }

    delete(service: any): void {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar el registro?', id: service.id}
        });

        dialogRef.afterClosed()
            .pipe(
                concatMap((serviceId: number) => {
                    if (serviceId) {
                        if (service.is_parent_group) {
                            try {
                                service.children.map(async srv => {
                                    this.dynamicCrudService.setHost('booking-service/');
                                    await this.dynamicCrudService.delete(srv.id).toPromise();
                                });
                                return this.dynamicCrudService.delete(service.id).pipe(map(res => 'deleted'));
                            } catch (e) {
                                throw new Error('error in deleted service:' + e);
                            }
                        } else {
                            this.dynamicCrudService.setHost('booking-service/');
                            return this.dynamicCrudService.delete(service.id).pipe(map(res => 'deleted'));
                        }
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.unsubscribe),
            )
            .subscribe(value => {
                if (value) {
                    this.bookingServices = this.bookingServices.filter(bookingService => bookingService.id !== service.id);
                    this.list = true;
                    this.getBudgets();
                    this.getAbonos();
                }
            });
    }

    cancelService(service: any) {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres cancelar el servicio?', id: service.id}
        });

        dialogRef.afterClosed().subscribe(id => {
            if (id) {
                this.dynamicCrudService.setHost(`booking-service/${id}/cancel/`);
                this.dynamicCrudService.add(null).subscribe(result => {
                    if (result) {
                        this.getBookings();
                    }
                });
            }
        });
    }

    openEditAbono(service: IBookingService) {
        const dialogRef = this.matDialog.open(AbonoModalComponent, {
            data: service
        });

        dialogRef.afterClosed().subscribe(value => {
            if (value) {
                this.getBookings();
                this.getAbonos();
            }
        });
    }

    getTypeDossier(type: number) {
        return type === 1 ? 'Expediente' : 'Presupuesto';
    }

    changeInputLabel(type: number) {
        this.dynamicCrud.fields.map(field => {
            if (field.fieldChilds && field.fieldChilds.length > 0) {
                const codeField = field.fieldChilds.find(f => f.keyName === 'code');
                if (codeField) {
                    codeField.name = 'Id';
                }
            }
        });
    }

    changeValuesStatus(type: number) {

        this.dynamicCrud.fields.map(field => {
            if (field.fieldChilds && field.fieldChilds.length > 0) {
                if (type === 1) {
                    this.hiddenOrShowField(field.fieldChilds, 'dossier_budget_status', true);
                    this.hiddenOrShowField(field.fieldChilds, 'dossier_status');
                    this.hiddenOrShowField(field.fieldChilds, 'dossier_situation');
                    this.createFormlyFields();
                } else {
                    this.hiddenOrShowField(field.fieldChilds, 'dossier_budget_status', false);
                    this.hiddenOrShowField(field.fieldChilds, 'dossier_status', true);
                    this.hiddenOrShowField(field.fieldChilds, 'dossier_situation', true);
                    this.createFormlyFields();
                    // this.changeFormlyFieldsProperties('dossier_status', null, true);
                    // this.changeFormlyFieldsProperties('dossier_situation', null, true);
                }
            }
        });
    }

    hiddenOrShowField(list, keyName, status = false) {
        const field = list.find(f => f.keyName === keyName);
        if (field) {
            field.hidden = status;
            field.removeOnCreate = status;
        }
    }


    generateProcceding(type: number) {
        this.managementService.addProcceding({
            dossier: this.id,
            booking_service: this.bookings.map(b => `${b.id}`),
        }).subscribe(async bookingServices => {
            const client = await this.managementClientService.getClient(this.model.client).toPromise();
            const clientStorage = await this.clientService.addClient(client);
            const mappedBaskets = bookingServices.map(bookingService =>  {
                const { paxes, raw_data: rawBasket } = bookingService;
                const basket = ShoppingBasketFactory.updateFromManagementBudget(paxes, rawBasket);
                return basket;
            });
            const bookings = await this.basketManagerService.setDataBasket(mappedBaskets);
            return this.router.navigate(['integration', 'bookings', 'book-summary']);
        });
    }

    saveDossier() {
        const queryParams = {
            type: this.type
        };
        this.submit(queryParams);
    }

    openPaymentModal(isAbono: boolean): void {
        const form = this.form.getRawValue();
        const dialogRef = this.matDialog.open(PaymentModalComponent, {
            panelClass: ['large-modal'],
            data: {
                paymentMethods: this.setup.payment_method,
                dossierId: Number(this.id),
                agencyId: t(this.userInfo, 'agency.id').safeObject,
                bookingServices: this.bookingServices,
                payments: this.payments.filter(item => item.is_abono === isAbono),
                cashOptions: this.cashOptions,
                bankOptions: this.bankOptions,
                creditCardOptions: this.creditCardOptions,
                totalAmountWithoutService: form.total_amount_without_service,
                isAbono: isAbono
            }
        });

        dialogRef.componentInstance.savePaymentDossier
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(payments => {
                this.getPayments();
            });
    }

    downloadPayment(paymentId: number): void {
        this.managementService.downloadPaymentDossier(paymentId);
    }

    cancelBudget(budgetId: number): void {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres canelar la facutra?', id: budgetId}
        });

        dialogRef.afterClosed()
            .pipe(
                concatMap((id: number) => {
                    if (id) {
                        return this.managementService.cancelBudget(budgetId);
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.unsubscribe),
            )
            .subscribe(updatedBudget => {
                if (updatedBudget) {
                    this.budgets = this.budgets.map(budget => budget.id === budgetId ? {...budget, is_cancel: true} : budget);
                }
            });

    }

    cancelAbono(budgetId: number): void {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres canelar el abono?', id: budgetId}
        });

        dialogRef.afterClosed()
            .pipe(
                concatMap((id: number) => {
                    if (id) {
                        return this.managementService.cancelBudget(budgetId);
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.unsubscribe),
            )
            .subscribe(updatedBudget => {
                if (updatedBudget) {
                    this.abonos = this.abonos.map(budget => budget.id === budgetId ? {...budget, is_cancel: true} : budget);
                }
            });

    }

    openPrepaidModal(bookingService: IBookingService) {
        const dialogRef = this.matDialog.open(PrepaidModalComponent, {
            panelClass: ['large-modal'],
            data: {
                paymentMethods: this.setup.payment_method,
                bookingService: bookingService,
                prepaids: this.prepaids.filter(item => item.booking_service === bookingService.id),
            }
        });

        dialogRef.componentInstance.save
        .pipe(
            concatMap(prepaidForm => {
                const prepaidRequest = PrepaidRequest.buildFromForm(prepaidForm);
                return this.managementService.addPrepaid(prepaidRequest, bookingService.id);
            }),
            takeUntil(this.unsubscribe),
        )
        .subscribe(prepaid => {
            this.prepaids = [prepaid, ...this.prepaids];
            dialogRef.close();
            this.getBookings();
        });
    }

    removePrepaid(prepaid: Prepaid) {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar el prepago?', id: prepaid.id}
        });

        dialogRef.afterClosed()
        .pipe(
            concatMap((id: number) => {
                if (id) {
                    return this.managementService.removePrepaid(prepaid.booking_service, prepaid.id);
                } else {
                    return of(null);
                }
            }),
            takeUntil(this.unsubscribe),
        )
        .subscribe(res => {
            if (res) {
                this.prepaids = this.prepaids.filter(p => p.id !== prepaid.id);
                this.getBookings();
            }
        });
    }

    openSupplierInvoiceModal(bookingService: IBookingService) {
        const dialogRef = this.matDialog.open(SupplierInvoiceModalComponent, {
            panelClass: ['large-modal'],
            data: {
                paymentMethods: this.setup.payment_method,
                bookingService: bookingService,
                prepaids: this.prepaids.filter(item => item.booking_service === bookingService.id)
            }
        });

        dialogRef.componentInstance.save
        .pipe(
            concatMap(supplierInvoiceForm => {
                const supplierInvoiceRequest = SupplierInvoiceRequest.buildFromForm(supplierInvoiceForm);
                return this.managementService.addSupplierInvoice(supplierInvoiceRequest, bookingService.id);
            }),
            takeUntil(this.unsubscribe),
        )
        .subscribe(supplierInvoice => {
            this.supplierInvoices = [supplierInvoice, ...this.supplierInvoices];
            supplierInvoice.prepaids.forEach(prepaid => {
                this.prepaids = [prepaid, ...this.prepaids];
            });
            dialogRef.close();
            this.getBookings();
        });
    }


    removeSupplierInvoice(supplierInvoice: SupplierInvoice) {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar la factura?', id: supplierInvoice.id}
        });

        dialogRef.afterClosed()
        .pipe(
            concatMap((id: number) => {
                if (id) {
                    return this.managementService.removeSupplierInvoice(supplierInvoice.booking_service, supplierInvoice.id);
                } else {
                    return of(null);
                }
            }),
            takeUntil(this.unsubscribe),
        )
        .subscribe(res => {
            if (res) {
                this.prepaids = this.prepaids.filter(p => p.booking_service_supplier_invoice !== supplierInvoice.id);
                this.supplierInvoices = this.supplierInvoices.filter(p => p.id !== supplierInvoice.id);
                this.getBookings();
            }
        });
    }

    disabledButtonAbono() {
        return true;
    }

    getPaymentsList(isAbono: boolean) {
        return this.payments.filter(item => item.is_abono === isAbono);
    }

    async getBookings(): Promise<void> {
        this.dynamicCrudService.setHost(`clients/dossier/${this.id}/booking-service/`);
        this.bookingServices = await this.dynamicCrudService.list<IBookingService>().toPromise();

        this.dynamicCrud.fields.map(filter => {
            return filter.fieldChilds.map(values => {
                if (values['keyName'] === 'total_amount') {
                    values.hidden = !(this.bookingServices.length > 0);
                }

                if (values['keyName'] === 'total_abono') {
                    values.hidden = !(this.bookingServices.length > 0);
                }

                if (values['keyName'] === 'total_amount_without_service') {
                    values.hidden = this.bookingServices.length > 0;
                }

                return values;
            });
        });

        this.formlyFields = DynamicCrudUtils.generateFormlyConfig(this.dynamicCrud);
    }

    async getPayments(): Promise<void> {
        this.payments = await this.managementService.getPayments(Number(this.id)).toPromise();
    }

    private async getCashOptions(): Promise<void> {
        const agencyId = t(this.userInfo, 'agency.id').safeObject;
        this.cashOptions = await this.managementService.getCashOptions(agencyId).toPromise();
    }

    private async getBankOptions(): Promise<void> {
        const agencyId = t(this.userInfo, 'agency.id').safeObject;
        this.bankOptions = await this.managementService.getBankOptions(agencyId).toPromise();
    }

    private async getCreditCardOptions(): Promise<void> {
        const agencyId = t(this.userInfo, 'agency.id').safeObject;
        this.creditCardOptions = await this.managementService.getCreditCardOptions(agencyId).toPromise();
    }

    private async getBudgets(): Promise<void> {
        this.dynamicCrudService.setHost(`clients/dossier/${this.id}/invoices/`);
        this.budgets = await this.dynamicCrudService.list<any[]>().toPromise();
    }

    private async getAbonos(): Promise<void> {
        this.dynamicCrudService.setHost(`clients/dossier/${this.id}/abonos/`);
        this.abonos = await this.dynamicCrudService.list<any[]>().toPromise();
    }

    private async getPrepaids(): Promise<void> {
        this.prepaids = await this.managementService.getPrepaids(this.id).toPromise();
    }

    private async getSupplierInvoices(): Promise<void> {
        this.supplierInvoices = await this.managementService.getSupplierInvoices(this.id).toPromise();
    }

    private generateBudgetForSelectedService(type: number): void {
        this.managementService.addBudget({
            booking_service: this.bookings.map(b => b.id),
            type,
        }).pipe(
            takeUntil(this.unsubscribe),
        ).subscribe(value => {
            this.router.navigate(['intranet', 'management', 'dossier', 'budget', value.id]);
        });
    }

    private generateBudgetForAllServices(): void {
        const budget = this.type === 1 ? 'factura' : 'presupuesto';
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {
                question: `¿Quieres generar ${budget} para todos los servicios?`,
                label: `Generación de ${budget}`,
                id: this.id,
                hideIcon: true,
            }
        });

        dialogRef.afterClosed()
            .pipe(
                concatMap((dossierId: number) => {
                    if (dossierId) {
                        return this.managementService.generateInvoiceForAllServices(Number(this.id), this.type);
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.unsubscribe),
            ).subscribe(budgets => {
            if (budgets) {
                const bookingServices = this.bookingServices.filter(service => {
                    return budgets.some(
                        budget => budget.budget_booking_service.some(
                            budgetBookingService => budgetBookingService.booking_service.id === service.id));
                });
                this.bookingServices = bookingServices.map(bookingService => {
                    return {
                        ...bookingService,
                        has_invoice: true,
                    };
                });
                this.budgets = [...this.budgets, ...budgets];

                if (budgets.length > 0) {
                    this.router.navigate(['intranet', 'management', 'dossier', 'budget', budgets[0].id]);
                }
            }
        });
    }

    private generateAbonoForSelectedService(type: number): void {
        this.managementService.addBudget({
            booking_service: this.bookings.map(b => b.id),
            type,
        }).pipe(
            takeUntil(this.unsubscribe),
        ).subscribe(value => {
            this.router.navigate(['intranet', 'management', 'dossier', 'abono', value.id]);
        });
    }

    private generateAbonoForAllServices(): void {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {
                question: `¿Quieres generar abono para todos los servicios?`,
                label: `Generación de abono`,
                id: this.id,
                hideIcon: true,
            }
        });

        dialogRef.afterClosed()
            .pipe(
                concatMap((dossierId: number) => {
                    if (dossierId) {
                        return this.managementService.generateInvoiceForAllServices(Number(this.id), 4);
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.unsubscribe),
            ).subscribe(budgets => {
            if (budgets) {
                const bookingServices = this.bookingServices.filter(service => {
                    return budgets.some(
                        budget => budget.budget_booking_service.some(
                            budgetBookingService => budgetBookingService.booking_service.id === service.id));
                });
                this.bookingServices = bookingServices.map(bookingService => {
                    return {
                        ...bookingService,
                        has_invoice: true,
                    };
                });
                this.budgets = [...this.budgets, ...budgets];

                if (budgets.length > 0) {
                    this.router.navigate(['intranet', 'management', 'dossier', 'abono', budgets[0].id]);
                }
            }
        });
    }
}
