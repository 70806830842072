import { ShoppingBasketCars } from "../transports.model";
import { DetailedPricing } from "./availability.res.model";
import { CarBookingDtoReq } from "./booking.req.model";
import { CarCommission } from "./commission.model";

export class CarForm {
    driverTitle: string;
    driverInitial: string;
    driverLastName: string;
    driverAge: string;
}

export class CarBookingResDto {
    status: number;
    data: CarBookingResponse;
}
export class CarBookingResponse {
    bookingNumber: string;
    supplierRef: number;
    detailedPricing: DetailedPricing;
    commission: CarCommission;
    placeBooking: CarBookingDtoReq;
    shoppingBasket?: ShoppingBasketCars;
}
