import { HotelBookingPostReqDto, HotelBookingPostReqDtoOccupant, HotelRoomList } from "@vecib2c/frontend-dto";
import { HotelBookingReqRoomDetailMapper } from "./hotel-booking-req-room-detail-mapper";

export class HotelBookingPostReqMapper {

    static buildFromRoomList(
        roomList: HotelRoomList[],
        occupants: HotelBookingPostReqDtoOccupant[],
        providerName: string,
        hotelId: string,
        checkIn: string,
        checkOut: string,
    ): HotelBookingPostReqDto {
        return {
            language: 'CAS',
            providerName,
            remarks: '',
            roomDetails: HotelBookingReqRoomDetailMapper.build(
                roomList,
                occupants,
                hotelId,
                checkIn,
                checkOut,
            ),
            tolerance: 100,
            holderId: null,
            holder: null,
        };
    }
}
