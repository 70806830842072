import { Component, Input } from "@angular/core";


@Component({
    selector: 'lib-hf-book-confirmation',
    templateUrl: './hf-book-confirmation.component.html',
    styleUrls: ['./hf-book-confirmation.component.scss']
})

export class HFBookConfirmationComponent {
    @Input() hf: any;

    constructor() {
    }
}
