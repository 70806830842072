import {Pipe, PipeTransform} from '@angular/core';
import * as moment_ from 'moment';
import {Field, FieldType, Setup} from '../lib-shared/interfaces/index';

const moment = moment_;

@Pipe({
    name: 'getDescriptorFromId'
})
export class GetDescriptorFromIdPipe implements PipeTransform {
    moreThanOneDossier: boolean

    transform(value: any, column: string, fields: Field[], setup?: Setup): any {
        let field: Field = null;
        fields.forEach(f => {
            if (f.isGroup) {
                f.fieldChilds.forEach(fc => {
                    if (fc.keyName === column) {
                        field = fc;
                    }
                });
            } else {
                if (f.keyName === column) {
                    field = f;
                }
            }
        });
        // if(setup && (field.keyName === 'total_services')){
        //     value > 1 
        //         ? this.moreThanOneDossier = true
        //         : this.moreThanOneDossier = false
        // }
        // if(setup && (field.keyName === 'veci_dossier')){
        //     this.moreThanOneDossier
        //         ? value = "Contiene varios expedientes"
        //         : value
        // }
        if (setup && (field.type === FieldType.DATE || field.type === FieldType.DATETIME)) {
            const val = field.type === FieldType.DATE ? moment(value).format('DD/MM/YYYY')
                : field.type === FieldType.DATETIME ? moment(value).format('DD/MM/YYYY HH:mm') : null;
            if (val === 'Invalid date') {
                return '';
            }
            if (val) {
                return val;
            }
        }
        if (setup && field.type === FieldType.LIST) {
            if (setup[field.optionKey || field.keyName]) {
                if (value && value.length > 0) {
                    const listValues = [];
                    value.map(item => {
                        const val = setup[field.optionKey || field.keyName].find(v => v.id === +item);
                        listValues.push(val.name);
                    });
                    return listValues;
                } else {
                    const val = setup[field.optionKey || field.keyName].find(v => v.id === +value);
                    if (val) {
                        return val.name;
                    }
                }
            }
        }
        if (field.type === FieldType.CHECKBOX) {
            value = value ? 'Si' : 'No';
        }
        return value;
    }

}
