import { DestinationType } from "../../../../../../mock/packages";

export class ReferencePricesRequest {
    originCode?: string;
    destinationCode: string;
    numberOfNights: number;
    destinationType?: DestinationType;
    distribution?:any;
    forApp?: boolean;
}
