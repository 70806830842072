/**
 *  Convert object to queryString, not support nested object
 * */
export const objectToQuerystringWithArrayByKey = (object, key = 'id'): string => {
    let queryParameter = '?';
    for (const k in object) {
        if (object[k]) {
            if (object[k] instanceof Array) {
                if (object[k].length) {
                    queryParameter += object[k].map(item => item[key] ? `${k}=${item[key]}&` : `${k}=${item}&`).join('');
                }
            } else {
                queryParameter += `${k}=${typeof object[k] === 'boolean' ? object[k] ? 1 : 0 : object[k]}&`;
            }
        }
    }
    return queryParameter.slice(0, queryParameter.length - 1);
};

export const objectToQuerystring = (object): string => {
    let queryParameter = '?';
    for (const k in object) {
        if (object[k]) {
            if (object[k] instanceof Array) {
                if (object[k].length) {
                    queryParameter += JSON.stringify(object[k]);
                }
            } else if (object[k] instanceof Object) {
                if (object[k]) {
                    queryParameter += JSON.stringify(object[k]);
                }
            } else {
                queryParameter += `${k}=${typeof object[k] === 'boolean' ? object[k] ? 1 : 0 : object[k]}&`;
            }
        }
    }
    return queryParameter.slice(0, queryParameter.length - 1);
};

export * from './checkParameters';
export * from './rxjs-operators';
export * from './utils';
export * from './format-datepicker';
