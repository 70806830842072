import { ListAirBookBaggage, PrebookReqDto } from "@vecib2c/frontend-dto";
import { safeNumber } from "../../functions/index";

export class PreBookingMapperRequest {

    public static build(
        id: number,
        requestId: number,
        itineraryIds: number[],
        adults: number,
        children: number,
        infants: number,
    ): PrebookReqDto {
        return {
            requestId: safeNumber(requestId),
            priceGroupId: safeNumber(id),
            itinerariesId: itineraryIds.join(','),
            adultNumber: safeNumber(adults),
            childrenNumber: safeNumber(children),
            infantNumber: safeNumber(infants),
        };
    }
}

export class FlightBaggage extends ListAirBookBaggage {
    priceWithCommission: number;
}

export enum LuggageOptionEnum {
    'outward' = 'ida',
    'return' = 'vuelta',
    'both' = 'ida y vuelta',
    'ancillary' = 'secundario',
}
