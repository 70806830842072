import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment_ from 'moment';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { HotelFlightReqDto } from '@vecib2c/frontend-dto/dist/dto/HotelFlight';
import { FLIGHTS_AIRPORTS } from '../../../mock/flights';
import Pax from '../../paxes/Pax';
import { BaseEntity } from '../../lib-shared/Entities/index';
import { TecnoturisService } from '../../tecnoturis.service';
import { AirportService, AutocompleteService, IntegrationPageMetaData, IntegrationsService } from '../../lib-shared/services/index';

const moment = moment_;

interface HotelFlightSearch extends HotelFlightReqDto {
    originCity: string;
    originCountry: string;
    originAirport: string;
    destinationCity: string;
    destinationCountry: string;
    destinationAirport: string;
}

@Component({
    selector: 'lib-hotel-and-flight-search',
    templateUrl: './hotel-and-flight-search.component.html',
    styleUrls: ['./hotel-and-flight-search.component.scss']
})
export class HotelAndFlightSearchComponent extends BaseEntity implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    @Input() isSmall = false;
    @Input() inputFields: any;
    @Output() dataChanged = new EventEmitter<any>(); // TODO: Define type
    form: FormGroup;

    @Input() routerPath = '/integration/hotel-and-flight/search?';

    originCtrl: FormControl;
    filteredOrigin: Observable<any[]>;

    destinationCtrl: FormControl;
    filteredDestination: Observable<any[]>;

    cities = Object.keys(FLIGHTS_AIRPORTS).map(key => {
        const data = FLIGHTS_AIRPORTS[key];
        return data;
    });

    @ViewChild('dateRange', { static: false }) dateRange: any;
    @Input() parsedOrigin: any;
    @Input() parsedDestination: any;
    @Input() microsite = false;
    @Output() queryParams: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
    @Input() showTourIncluded = true;

    lowCostCtrl: FormControl;
    tourOrientationCtrl: FormControl;
    oneWayCtrl: FormControl;
    directFlyCtrl: FormControl;
    directFlyEEUUCtrl: FormControl;

    nationalities: any;

    paxes: Array<Pax> = [];

    hotelFlightSearchReq: any;
    integrationMetaData: IntegrationPageMetaData;

    constructor(
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        public http: HttpClient,
        public autocomplete: AutocompleteService,
        private airportService: AirportService,
        private integrationService: IntegrationsService,
        public tecnoturisService: TecnoturisService,
    ) {
        super(http, tecnoturisService);

        this.integrationMetaData = {category: 'Vuelo + Hotel', name: 'Vuelo + Hotel'};
        this.integrationService.setMetaData(this.integrationMetaData);

        this.originCtrl = new FormControl();
        this.destinationCtrl = new FormControl();

        this.lowCostCtrl = new FormControl();
        this.tourOrientationCtrl = new FormControl();
        this.oneWayCtrl = new FormControl();
        this.directFlyCtrl = new FormControl();
        this.directFlyEEUUCtrl = new FormControl();

        this.lowCostCtrl.setValue(false);
        this.tourOrientationCtrl.setValue(false);
        this.oneWayCtrl.setValue(false);
        this.directFlyCtrl.setValue(false);
        this.directFlyEEUUCtrl.setValue(false);

        const nationalitiesEntity = this.getEntity(this.tecnoturisService.config.nationalityHost, 'nationality');
        nationalitiesEntity.list(1, 300, null, {sortField: 'name', sortDirection: 'ASC'}).subscribe(nationalities => {
            this.nationalities = nationalities.rows;
        });
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');
        this.paxes.push(new Pax(1, 2, []));
        this.initForm();
    }

    ngAfterViewInit() {
        if (this.inputFields) {
            this.setParamsFromQuery(this.inputFields);

            if (typeof this.inputFields.includedLowCost === 'string') {
                this.inputFields.includedLowCost =
                    this.inputFields.includedLowCost === 'true';
                this.lowCostCtrl.setValue(this.inputFields.includedLowCost);
            }
            if (typeof this.inputFields.includedTourOperation === 'string') {
                this.inputFields.includedTourOperation =
                    this.inputFields.includedTourOperation === 'true';
                this.tourOrientationCtrl.setValue(
                    this.inputFields.includedTourOperation
                );
            }
        }

        this.filteredOrigin = this.originCtrl.valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(value => value.length > 1),
                switchMap(city => this.filterCities(city))
            );
        this.filteredDestination = this.destinationCtrl.valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(value => value.length > 1),
                switchMap(city => this.filterCities(city))
            );
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {

    }

    filterCities(city: string) {
        return this.airportService.search(city);
    }

    getGeocodes(): Observable<any> {
        return this.autocomplete.details(this.form.controls['destination'].value.city
            + ',' + this.form.controls['destination'].value.country);
    }

    setAddress() {
        return new Promise((resolve, reject) => {
            this.getGeocodes().subscribe(
                address => {
                    const inputFields = {} as any;
                    const fields = this.buildRequest();
                    inputFields.country = address.address_components.country;
                    inputFields.province = address.address_components.province;
                    inputFields.locality = address.address_components.locality;
                    inputFields.latitude = address.geometry.location.lat;
                    inputFields.longitude = address.geometry.location.lng;
                    this.hotelFlightSearchReq = Object.assign({}, inputFields, fields);
                    resolve();
                },
                error => console.log(error)
            );
        });
    }

    onEnterDestination(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.destination = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['destination'].setValue(reqIATA);
        }
    }

    onEnterOrigin(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.origin = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['origin'].setValue(reqIATA);
        }
    }

    private initForm() {
        this.form = this.formBuilder.group({
            origin: ['', Validators.required],
            destination: ['', Validators.required],
            distribution: [this.paxes, Validators.required],
            lowCost: this.lowCostCtrl,
            tourOrientation: this.tourOrientationCtrl,
            oneWay: this.oneWayCtrl,
            directFly: this.directFlyCtrl,
            directFlyEEUU: this.directFlyEEUUCtrl,
            nationality: ['ES', Validators.required],
            place: [''],
        });
    }

    autoCompleteDisplayFn(option) {
        if (option == null || undefined) {
            return '';
        }
        if (option !== '' || null || undefined) {
            return option.city + ' | ' + option.name + ' | ' + option.country;
        }
    }

    onPaxChanged(pax) {
        this.paxes[pax.position] = pax.newPax;
    }

    onRemovePax(position) {
        const newPaxes = [];
        this.paxes.forEach((pax: Pax, index) => {
            if (index !== position) {
                newPaxes.push(pax);
            }
        });

        this.paxes = newPaxes;
    }

    addPax() {
        this.paxes.push(new Pax(1, 2, []));
    }

    search() {
        if (!this.form.valid) {
            return;
        }

        this.setAddress().then(() => {
            // this.dataChanged.emit(this.hotelFlightSearchReq);

            if (this.microsite) {
                this.queryParams.emit(this.buildQueryString(this.hotelFlightSearchReq));
                return;
            }

            this.router.navigateByUrl(
                this.routerPath + this.buildQueryString(this.hotelFlightSearchReq)
            );
        });
    }

    private buildRequest() {
        const searchRequest: Partial<HotelFlightSearch> = {
            originIata: this.form.controls['origin'].value.iata,
            destinyIata: this.form.controls['destination'].value.iata,
            checkIn: this.dateRange.formatDate(this.dateRange.fromDate, 'YYYY-MM-DD'),
            checkOut: this.dateRange.formatDate(this.dateRange.toDate, 'YYYY-MM-DD'),
            distribution: this.form.controls['distribution'].value,
            includedLowCost: this.form.controls['lowCost'].value,
            includedTourOperation: this.form.controls['tourOrientation'].value,
            nationality: this.form.controls['nationality'].value,
            originCity: this.form.controls['origin'].value.city,
            originCountry: this.form.controls['origin'].value.country,
            originAirport: this.form.controls['origin'].value.name,
            destinationCity: this.form.controls['destination'].value.city,
            destinationCountry: this.form.controls['destination'].value.country,
            destinationAirport: this.form.controls['destination'].value.name,
            departureIsCity: false,
            arrivalIsCity: false,
        };

        if (this.form.controls['origin'].value.name === 'Todos') {
            searchRequest.departureIsCity = true;
            if (this.form.controls['origin'].value.cityCode !== undefined) {
                searchRequest.destinyIata = this.form.controls['origin'].value.cityCode;
            } else {
                searchRequest.destinyIata = this.form.controls['origin'].value.iata;
            }
        }

        if (this.form.controls['destination'].value.name === 'Todos') {
            searchRequest.arrivalIsCity = true;
            if (this.form.controls['destination'].value.cityCode !== undefined) {
                searchRequest.destinyIata = this.form.controls['destination'].value.cityCode;
            } else {
                searchRequest.destinyIata = this.form.controls['destination'].value.iata;
            }
        }

        return searchRequest;
    }

    private setParamsFromQuery(inputFields) {
        this.form.controls['origin'].setValue(
            {
                iata: inputFields.originIata,
                city: inputFields.originCity,
                country: inputFields.originCountry,
                name: inputFields.originAirport,
                cityCode: inputFields.origin,
            }
        );
        this.form.controls['destination'].setValue(
            {
                iata: inputFields.destinyIata,
                city: inputFields.destinationCity,
                country: inputFields.destinationCountry,
                name: inputFields.destinationAirport,
                cityCode: inputFields.destination,
            }
        );
        this.setDateFromQuery(inputFields.checkIn);
        this.setDateFromQuery(inputFields.checkOut);
        this.setPaxFromQuery(inputFields.distribution);
        this.setNationalityFromQuery(inputFields);

        this.form.controls.lowCost.setValue(inputFields.includedLowCost);
        this.form.controls.tourOrientation.setValue(
            inputFields.includedTourOperation
        );
    }

    private setPaxFromQuery(distribution) {
        this.paxes = distribution;
        this.form.controls.distribution.setValue(this.paxes);
    }

    setNationalityFromQuery(inputFields) {
        const nationality = inputFields.nationality ? inputFields.nationality : 'ES';
        this.form.patchValue({'nationality': nationality});
    }

    private setDateFromQuery(inputDate: string) {
        const date = moment(inputDate, 'YYYY-MM-DD').toDate();
        this.dateRange.onSelection(
            new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
        );
    }

    private buildQueryString(request): HttpParams {
        const query = new HttpParams()
            .append('includedLowCost', this.form.controls.lowCost.value)
            .append('includedTourOperation', this.form.controls.tourOrientation.value)
            .set('originIata', request.originIata)
            .set('destinyIata', request.destinyIata)
            .set('checkIn', request.checkIn)
            .set('checkOut', request.checkOut)
            .set('distribution', JSON.stringify(request.distribution))
            .set('nationality', request.nationality)
            .set('country', request.country)
            .set('province', request.province)
            .set('locality', request.locality)
            .set('latitude', request.latitude)
            .set('longitude', request.longitude)
            .set('originCity', request.originCity)
            .set('originCountry', request.originCountry)
            .set('originAirport', request.originAirport)
            .set('destinationCity', request.destinationCity)
            .set('destinationCountry', request.destinationCountry)
            .set('destinationAirport', request.destinationAirport)
            .set('departureIsCity', request.departureIsCity)
            .set('arrivalIsCity', request.arrivalIsCity);
        return query;
    }

    setLowCost() {
        this.buildRequest();
    }

    setTourOrientation() {
        this.buildRequest();
    }
}
