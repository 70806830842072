import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lib-booking-commission',
    templateUrl: './booking-commission.component.html',
    styleUrls: ['./booking-commission.component.scss']
})
export class BookingCommissionComponent implements OnInit {


    constructor() {
    }

    ngOnInit() {
    }

}
