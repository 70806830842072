import { IBookingItem } from "./bookingItem.interface";
import { BookingsRequest, PackagePax } from "./packages/bookings/request/bookings-request";
import { Client } from './client.model';
import { Distribution } from "./distribution.interface";
import { PackageState } from "./packages/pre-bookings/request/package-state.model";
import { PackageBookingCancellationPolicies, PackageDetailedPricing, PackageFlight, PackageHotel } from "@vecib2c/frontend-dto";
import { PackageAddonOption } from "@vecib2c/frontend-dto/dist/dto/Packages/PackageAddon";
import { IntegrationType } from "./integration.types";
import { safeNumber, splitDistributionsByPaxType } from "../functions/index";
import { ManagementPax } from "./management.interface";
import { PackagePaxFromManagementFactory } from "../factories/packages/package-pax-from-management.factory";
import { PackageDistributionFactory } from "../factories/packages/package-distribution.factory";
import { InfoAirportsPackage } from "../interfaces/packages/destinations/request/destinations-request";

import { FLIGHTS_AIRPORTS } from '../../../mock/flights';

const airports = FLIGHTS_AIRPORTS;

export class ShoppingBasketPackage extends BookingsRequest implements IBookingItem {
    uuid: string;
    client: Client;
    totalAmount?: number;
    hotels?: PackageHotel[];
    flights?: PackageFlight[];
    tickets?: any[];
    transfers?: any[];
    optionals?: PackageAddonOption[];
    distribution?: Distribution[];
    checkIn?: string;
    checkOut?: string;
    cancellationPolicyList?: PackageBookingCancellationPolicies[];
    currency?: string;
    comments?: { clientComment?: string, agentComment?: string, updatedPrice: number };
    totalPrice?: number;
    checkForm?: boolean;
    validateMessage?: string;
    paxes: PackagePax[];
    integrationType: IntegrationType;
    adults: number;
    children: number;
    infants: number;
    packageCategory?: string;
    partialTotal: number;
    providerName: string;
    requestToken: string;
    bookProcessId: string;
    detailedPricing?: PackageDetailedPricing;
    detailPricing?: any;
    reratedPrice?: number;
    originalPrice?: number;
    failed?: boolean;
    error?: string[];
    isSelected?: boolean;
    canBeBooked?: boolean;
    distributionRooms:any;
    productTokenNewblue:string;
    passengers:any;
    commission:any;
    agencyInfo?:any;
    bookId?:any;
    flightDurationOutward?: any;
    flightDurationReturn?: any;
    infoAirports?: InfoAirportsPackage;
    preBookingData?: string;

    static initialize(state: PackageState): ShoppingBasketPackage {
        const {
            hotels,
            flights,
            transfers,
            tickets,
            optionals,
            distribution,
            productTokenNewblue,
            passengers,
            distributionRooms,
            checkIn,
            checkOut,
            cancellationPolicyList,
            currency,
            providerToken,
            requestToken,
            packageCategory,
            totalAmount,
            commission: { pvp },
            providerName,
            bookProcessId,
            detailedPricing,
            detailPricing,
            agencyInfo,
            preBookingData,
        } = state.preBookingResponse;
        return {
            uuid: state.uuid,
            client: null,
            totalAmount: pvp,
            hotels,
            flights,
            transfers,
            tickets,
            optionals,
            distribution,
            productTokenNewblue,
            passengers,
            commission:{pvp},
            distributionRooms,
            checkIn,
            checkOut,
            cancellationPolicyList,
            currency,
            paxes: [],
            integrationType: 'PACKAGE',
            adults: state.adults,
            children: state.children,
            infants: state.infants,
            requestToken,
            bookProcessId,
            packageCategory,
            partialTotal: totalAmount,
            providerName,
            detailedPricing,
            detailPricing,
            agencyInfo,
            infoAirports: this.getInfoAirports(flights),
            preBookingData
        };
    }

    static initializeComments(
        item: ShoppingBasketPackage,
    ): ShoppingBasketPackage {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.totalAmount),
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShoppingBasketPackage,
        paxes: ManagementPax[],
    ): ShoppingBasketPackage {
        const mappedPaxes = PackagePaxFromManagementFactory.build(paxes, item.paxes);
        const { adults, children, infants } = PackageDistributionFactory.build(mappedPaxes);
        return {
            ...item,
            paxes: mappedPaxes,
            adults,
            children,
            infants,
        };
    }

    static getInfoAirports(flights: PackageFlight[]){
       let infoAirports:  InfoAirportsPackage = new InfoAirportsPackage()
       infoAirports.airportOriginCity = airports[flights[0].outward[0].departure.airportCode].city
       infoAirports.airportOriginName = airports[flights[0].outward[0].departure.airportCode].name

       infoAirports.airportArrivalCity = airports[flights[0].return[0].departure.airportCode].city
       infoAirports.airportArrivalName = airports[flights[0].return[0].departure.airportCode].name
       
        if(flights[0].outward[0].segmentList.length > 1){
           infoAirports.scaleOutwardCity = airports[flights[0].outward[0].segmentList[0].destination].city
        }

        if(flights[0].return[0].segmentList.length > 1){
           infoAirports.scaleReturnCity = airports[flights[0].return[0].segmentList[0].destination].city
        }

        return infoAirports
    }
}
