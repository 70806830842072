import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: 'lib-rerated-price',
    templateUrl: './rerated-price.component.html',
    styleUrls: ['./rerated-price.component.scss']
})
export class ReratedPriceComponent implements OnInit {

    @Input() originalPrice: number;
    @Input() reratedPrice: number;

    constructor() {

    }

    ngOnInit(): void {

    }

}
