import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import * as moment_ from 'moment';
import { AirportService, AutocompleteService } from '../../lib-shared/services/index';

const moment = moment_;

@Component({
    selector: 'lib-multiflight-item',
    templateUrl: './multiflight-item.component.html',
    styleUrls: ['./multiflight-item.component.scss']
})
export class MultiflightItemComponent implements OnInit, OnDestroy {
    @Input() segmentForm: FormGroup;

    @Output() removeSegment: EventEmitter<any> = new EventEmitter<any>();

    @Input() isSmall;

    @Input() canRemove = true;

    filteredOrigin$: Observable<any>;

    filteredDestination$: Observable<any>;

    originControl = new FormControl();
    destinationControl = new FormControl();

    dateControl = new FormControl();

    unsubscribe = new Subject();

    date = new Date();

    constructor(private autocompleteService: AutocompleteService, private airportService: AirportService) {

    }

    ngOnInit() {
        this.initControl();
        this.originControl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value) {
                    this.segmentForm.get('origin').setValue(value);
                } else {
                    this.segmentForm.get('origin').setValue(null);
                }
            });

        this.destinationControl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value) {
                    this.segmentForm.get('destination').setValue(value);
                } else {
                    this.segmentForm.get('destination').setValue(null);
                }
            });

        this.dateControl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value) {
                    const date = moment(value).format('DD/MM/YYYY');
                    this.segmentForm.get('dateAt').setValue(date);
                } else {
                    this.segmentForm.get('dateAt').setValue(null);
                }
            });

        this.filteredOrigin$ = this.getCitiesAutocomplete(this.originControl);
        this.filteredDestination$ = this.getCitiesAutocomplete(this.destinationControl);
    }

    initControl(): void {
        if (this.segmentForm.get('origin')) {
            this.originControl.setValue(this.segmentForm.get('origin').value);
        }

        if (this.segmentForm.get('destination')) {
            this.destinationControl.setValue(this.segmentForm.get('destination').value);
        }

        if (this.segmentForm.get('dateAt')) {
            const date = this.segmentForm.get('dateAt').value;
            this.dateControl.setValue(moment(date, 'DD/MM/YYYY').toDate());
        }
    }

    autocomplete(search: string): Observable<any> {
        // return this.autocompleteService.searchPelias(search);
        return this.airportService.search(search);
    }

    getCitiesAutocomplete(control: FormControl): Observable<any> {
        return control
            .valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(value => value.length >= 3),
                switchMap(search => this.autocomplete(search))
            );
    }

    autoCompleteDisplayFn(option) {
        if (option == null || undefined) {
            return '';
        }
        if (option !== '' || null || undefined) {
            return option.city + ' | ' + option.name + ' | ' + option.country;
        }
    }

    selectCheckIn(date: Date): void {
        this.dateControl.setValue(date);
        this.dateControl.markAsTouched();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
