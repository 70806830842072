import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Setup } from '../../lib-shared/interfaces/index';
import { ManagementService } from '../../lib-shared/services/index';

@Injectable({providedIn: 'root'})
export class ServicesResolve implements Resolve<Setup> {
    constructor(private service: ManagementService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.service.getServices()
            .pipe(
                catchError(err => {
                    return of(null);
                }));
    }
}
