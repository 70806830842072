export const radioGroupDisney = [
  { id: 1, name: "Con Aereo", disabled: false },
  { id: 2, name: "Hotel + Entradas", disabled: false },
  { id: 3, name: "Entradas", disabled: false },
  // {id: 4, name: 'Combinados', disabled: true},
];

export const packageOrigins = [
  { id: "MAD", name: "Madrid" },
  { id: "BIO", name: "Bilbao" },
  { id: "LUQ", name: "San Luis" },
  { id: "RSA", name: "Santa Rosa" },
  { id: "IRJ", name: "La Rioja" },
  { id: "REL", name: "Trelew" },
];

export const packageDestinations = [
  { id: "MAD", name: "Madrid" },
  { id: "BIO", name: "Bilbao" },
  { id: "LUQ", name: "San Luis" },
  { id: "RSA", name: "Santa Rosa" },
  { id: "IRJ", name: "La Rioja" },
  { id: "REL", name: "Trelew" },
];

export enum PackageNameEnum {
  CARIBBEAN = "Caribe",
  ISLAND = "Islas",
  DISNEY = "Disney",
  OTHER = "Otros",
}

export enum DestinationType {
  "DISNEY" = "DISNEY",
  "ISLAND" = "ISLAND",
  "CARIBBEAN" = "CARIBBEAN",
}

export const packageOptionsVal = [
  { id: 0, name: PackageNameEnum.OTHER },
  { id: 1, name: PackageNameEnum.CARIBBEAN },
  { id: 2, name: PackageNameEnum.ISLAND },
  { id: 3, name: PackageNameEnum.DISNEY },
];

export const packageDestinationComb = [
  { id: "PARIS", name: "PARIS" },
  { id: "DIS", name: "DISNEY" },
];

export const packageParameters = [
  "origin",
  "destination",
  "checkIn",
  "checkOut",
  "distribution",
  "nights",
  "package",
  "page",
  "pageSize",
  "adults",
  "children",
  "roomId",
  "packageId",
  "filter",
  "requestToken",
  "providerCode",
  "options",
  "options_combo",
  "nights_disney",
  "nights_paris",
  "first_instance",
  "hotelId",
  "ticketId",
  "productID",
  "productIdTourMundial",
  "providers",
  "idProduct",
  "hotelName",
  "roomName",
  "price",
];

/* export const numberNigths = [
    {value: 2, name: '2 noches'},
    {value: 3, name: '3 noches'},
    {value: 4, name: '4 noches'},
    {value: 5, name: '5 noches'},
    {value: 6, name: '6 noches'},
    {value: 7, name: '7 noches'},
    {value: 8, name: '8 noches'},
    {value: 9, name: '9 noches'},
    {value: 10, name: '10 noches'},
    {value: 11, name: '11 noches'},
    {value: 12, name: '12 noches'},
    {value: 13, name: '13 noches'},
    {value: 14, name: '14 noches'},
    {value: 15, name: '15 noches'},
    {value: 16, name: '16 noches'},
    {value: 17, name: '17 noches'},
    {value: 18, name: '18 noches'},
    {value: 19, name: '19 noches'},
    {value: 20, name: '20 noches'},
];
 */

export const numberNigths = [
  { value: 2, name: "2 noches" },
  { value: 3, name: "3 noches" },
  { value: 4, name: "4 noches" },
  { value: 5, name: "5 noches" },
  { value: 6, name: "6 noches" },
  { value: 7, name: "7 noches" },
  { value: 8, name: "8 noches" },
  { value: 9, name: "9 noches" },
  { value: 10, name: "10 noches" },
  { value: 11, name: "11 noches" },
  { value: 12, name: "12 noches" },
  { value: 13, name: "13 noches" },
  { value: 14, name: "14 noches" },
];

export const packageDestinationDisney = [
  { code: "DISNEY", description: "Disney" },
];

export const solTourDestinations = [
  { code: "PMICIUDAD", description: "Palma Ciudad, Cala Mayor e Illetas" },
  {
    code: "PMIOESTE",
    description: "Palma Nova, Magalluf, Santa Ponsa, Cala Viñas y Paguera",
  },
  {
    code: "PMIARENAL",
    description: "Ciudad Jardin, Ca'n Pastilla y El Arenal",
  },
];
