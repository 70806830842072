import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Optional } from '@angular/core';
import { fromEvent, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";

import { TransferService } from '../lib-shared/services/transfer.service';
import { LocationsTransfers } from '../lib-shared';

@Component({
    selector: 'lib-transfer-location-autocomplete',
    templateUrl: './transfer-location-autocomplete.component.html',
    styleUrls: ['./transfer-location-autocomplete.component.scss']
})

export class TransferLocationAutocomplete {
    
    @Input() label: string;
    @Input() placeholder: string;
    @Input() defaultValue: string;

    @Output() select = new EventEmitter<any>();

    @ViewChild('autocompleteInput', { static: false }) autocompleteInput: ElementRef;

    options$: Observable<any>;
    finalList = [];

    constructor(
        private readonly transferService: TransferService,
    ) {}

    ngAfterViewInit() {
        this.options$ = fromEvent(this.autocompleteInput.nativeElement, 'keyup')
            .pipe(
                map((event: any) => event.target.value),
                debounceTime(1000),
                distinctUntilChanged(),
                filter(value => value.length > 3),
                switchMap((location) => this.filterLocation(location)),
                map(res => res.rows),
            );
    }

    filterLocation(location: string){
        return this.transferService.getLocation({search: location})
    }

    displayFn = (location: LocationsTransfers) => {
        this.select.emit(location);
        return location ? location.name : undefined;
    }
}