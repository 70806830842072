import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import {
    SupplierInvoice,
} from "../../../../lib-shared/index";

@Component({
    selector: 'lib-supplier-invoice-list',
    templateUrl: './supplier-invoice-list.component.html',
    styleUrls: ['./supplier-invoice-list.component.scss']
})
export class SupplierInvoiceListComponent implements OnInit, OnDestroy {

    @Input() supplierInvoices: SupplierInvoice[];

    fields = {
        'code': 'No factura / ref',
        "emission_date": "Fecha de emisión",
        "registration_date": "Fecha de creación",
        "non_deductible_tax": "Fact. REAV imp. no deducibles",
        "net_commission": "Comisión neta",
        "intracommunity_operation": "Operación intracomunitaria",
        "actions": "Acciones"
    };
    displayedColumns = [
        'code',
        'emission_date',
        'registration_date',
        'non_deductible_tax',
        'net_commission',
        'intracommunity_operation',
        'actions'
    ];

    @Output() remove = new EventEmitter<SupplierInvoice>();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

    }


}
