import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface IonRange {
  lower: number;
  upper: number;
}

@Component({
  selector: 'lib-circuits-filter',
  templateUrl: './circuits-filter.component.html',
  styleUrls: ['./circuits-filter.component.scss']
})
export class CircuitsFilterComponent implements OnInit {

  @Input() maxPrice: number;
  @Input() minPrice: number;
  @Input() filteredMinPrice: number;
  @Input() filteredMaxPrice: number;
  @Input() totalRange: number;
  @Output() sendPrices = new EventEmitter<any>();
  range: IonRange;

  constructor() { }

  ngOnInit() {
    this.range = { lower: this.minPrice, upper: this.maxPrice };
  }

  changePrices(newRange) {
    this.range = newRange;
    this.sendPrices.emit(newRange);
  }

}
