import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import * as moment_ from "moment"
import { CONFIG_DEFAULT_CAROUSEL } from "../../../const"
import { PackageHotel, PackageAddon, PackageRoom } from "@vecib2c/frontend-dto"
import {
  AgencyCommission,
  PackageAddonMapper,
} from "../../../lib-shared/interfaces/index"

const moment = moment_

@Component({
  selector: "lib-item-package-card-detail",
  templateUrl: "./item-package-card-detail.component.html",
  styleUrls: ["./item-package-card-detail.component.scss"],
})
export class ItemPackageCardDetailComponent implements OnInit {
  @Input() infoPackage: any
  @Input() vacationPackage: any

  @Input() set hotel(hotel: PackageHotel) {
    this._hotel = hotel
    if (hotel) {
      this.selectedRoom = hotel.rooms.find((room) => room.selected)
      this.total = this.selectedRoom
        ? this.selectedRoom.rates[0].commission.pvp
        : 0
    }
  }

  @Input() galleryImages: any[]
  @Input() selectedFlight: any

  @Input() set optionals(optionals) {
    this._optionals = optionals
    if (optionals) {
      this.contractedOptions = this.buildContractedOptions(optionals)
    }
  }

  @Input() microsites: boolean

  get hotel(): PackageHotel {
    return this._hotel
  }

  get optionals() {
    return this._optionals
  }

  selected = 0
  displayedColumns = ["description", "price", "actions"]
  optionsCarousel = CONFIG_DEFAULT_CAROUSEL
  contractedOptions
  total = 0
  selectedRoom: PackageRoom

  @Output() emitActionBooking = new EventEmitter()
  @Output() emitUpdatePackage = new EventEmitter()

  private _hotel: PackageHotel
  private _optionals

  constructor() {
    this.optionsCarousel.breakpoint = [
      {
        width: 500,
        number: 1,
      },
    ]
  }

  ngOnInit() {}

  getCategory(category): Array<Number> {
    if (category === undefined) {
      return
    }
    const regexNumber = /\d+/

    if (!category || !regexNumber.test(category.value)) {
      return
    } else {
      const value = category.value.match(regexNumber)[0]
      const integerValue = parseInt(value, 10)
      return new Array(integerValue)
    }
  }

  getCheckout(req) {
    const checkout = moment(req.checkIn, "DD-MM-YYYY").add(+req.nights, "days")
    return moment(checkout).format("DD-MM-YYYY")
  }

  getActionBtnBooking(roomId) {
    this.emitActionBooking.emit(roomId)
  }

  emitReserve(addons: PackageAddon[]) {
    this.selected = 0
    this.optionals = addons
    this.contractedOptions = this.buildContractedOptions(addons)
    const addonsPrice = PackageAddonMapper.calculateAddonsPrice(addons)
    const { fee, iva, commissionRate } = this.selectedRoom.rates[0].commission
    const { commissionableRate, nonCommissionableRate } =
      this.selectedRoom.rates[0].detailedPricing
    const netPrice = commissionableRate + addonsPrice
    this.total = AgencyCommission.calculatePVP(
      netPrice,
      commissionRate,
      fee,
      iva,
      nonCommissionableRate
    )

    this.emitUpdatePackage.emit(addons)
  }

  private buildContractedOptions(rawOptions) {
    const optionalList = []

    rawOptions.forEach((option) => {
      option.Opciones.forEach((item) => {
        if (item.selected) {
          optionalList.push(item)
        }
      })
    })
    return optionalList
  }
}
