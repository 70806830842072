import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import * as uuidv1_ from 'uuid/v1';
import * as moment_ from 'moment';
import { Cost, Car, SupplierInfoDetail, TandCs, ExtraDetail } from "../../../lib-shared/interfaces/cars/availability.res.model";
import { safeNumber } from "../../../lib-shared/functions/index";
import { MatCheckboxChange } from "@angular/material";
import { CarExtra, CarState } from "../../../lib-shared/interfaces/cars/booking.req.model";

const moment = moment_;
const uuidv1 = uuidv1_;

@Component({
    selector: 'lib-transports-item-card',
    templateUrl: './transports-item-card.component.html',
    styleUrls: ['transports-item-card.component.scss'],
})

export class TransportsItemCardComponent implements OnInit {

    @Input() item: Car;
    @Input() index: number;
    @Input() formsParams: any;
    @Input() supplierInfo: SupplierInfoDetail[];
    @Input() microsites = false;
    @Input() selectedCar: Car;
    @Input() preBookingIsAvailable = true;
    @Input() selectIsAvailable = false;
    @Input() showSupplements = true;

    supplier: SupplierInfoDetail;
    termCond: TandCs[] = [];
    supplementPanel = false;
    restrictionPanel = true;
    extras: ExtraDetail[] = [];

    @Output() selectCar = new EventEmitter<Car>();

    constructor(
        private readonly router: Router,
    ) {
    }

    ngOnInit(): void {
        this.extras = this.item.extraDetails ? this.item.extraDetails : [];
        this.getSupplier();
        this.initializeRate();
    }

    goToBookingPage() {

        const query = this.buildCarState();
        const uuid = uuidv1();
        const navigationExtras: NavigationExtras = {
            state: {
                cars: query,
                extras: this.extras,
            }
        };

        return this.router.navigate(['integration', 'bookings', 'book-edit', 'transport-cars', uuid], navigationExtras);
    }

    select(): void {
        const selectedRate = this.item.Costs.Cost.find(cost => cost.checked);
        const mappedCar: Car = Object.assign({},
            this.item,
            {
                Costs: {
                    Cost: [selectedRate],
                },
                extraDetails: this.extras,
            }
        );
        this.selectCar.emit(mappedCar);
    }

    buildCarState(): CarState {
        const selectedRate = this.item.Costs.Cost.find(cost => cost.checked);

        return {
            pickUpLocationID: this.formsParams.PickupLocationID,
            dropOffLocationID: this.formsParams.DropoffLocationID,
            pickUpLocationName: this.formsParams.PickupLocationName,
            dropOffLocationName: this.formsParams.DropoffLocationName,
            pickUpLocationCountry: this.formsParams.PickupLocationCountry,
            dropOffLocationCountry: this.formsParams.DropoffLocationCountry,
            pickUpDate: this.formsParams.PickupDate,
            dropOffDate: this.formsParams.DropoffDate,
            pickUpTime: this.formsParams.PickupTime,
            dropOffTime: this.formsParams.DropoffTime,
            name: this.item.Name,
            url: btoa(this.item.URL),
            passenger: this.item.Passenger,
            doors: safeNumber(this.item.Doors, 4),
            totalCharge: this.item.TotalCharge,
            isAirCon: this.item.IsAirCon,
            isAutomatic: this.item.IsAutomatic,
            supplier: this.item.Supplier,
            carType: this.item.Code,
            rateQualifier: this.item.RateQualifier,
            supplierCode: this.item.SupplierCode,
            productID: this.formsParams.ProductID,
            currency: this.item.Currency,
            additionalParameters: this.item.AdditionalParameters,
            nameRate: selectedRate.Product,
            extras: this.item.extras,
            rate: selectedRate,
            luggage: safeNumber(this.item.Luggage),
            fuel: 'Gasolina',
        };
    }

    onChangePriceRate(rate: Cost) {
        this.item.TotalCharge = rate.Price;
    }

    selectRate(rate: Cost, rateIndex: number): void {
        this.updateRate(rateIndex);
        this.updateTotalCharge();
        this.updateAdditionalParameters(rate);
        this.updateSelectedCar();
    }

    selectExtraDetail(event: MatCheckboxChange, extraDetail: ExtraDetail) {
        if (event.checked) {
            this.extras = [...this.extras, extraDetail];
        } else {
            this.extras = this.extras.filter(e => e.Name !== extraDetail.Name);
        }
        this.item.extras = CarExtra.buildExtraFromExtraDetail(this.extras);

        this.updateRate();
        this.updateTotalCharge();

        this.updateSelectedCar();
    }

    selectPanel(panelName: string): void {
        switch (panelName) {
            case 'restriction':
                this.restrictionPanel = !this.restrictionPanel;
                this.supplementPanel = false;
                break;
            case 'supplement':
                this.supplementPanel = !this.supplementPanel;
                this.restrictionPanel = false;
                break;
        }
    }

    private initializeRate(): void {
        const rateIndex = this.item.Costs.Cost.findIndex(rate => {
            return rate.Price === this.item.TotalCharge;
        });

        if (rateIndex > -1) {
            const rate = this.item.Costs.Cost[rateIndex];
            this.updateRate(rateIndex);
            this.updateTotalCharge();
            this.updateAdditionalParameters(rate);
        }
    }

    private updateRate(rateIndex?: number): void {
        const days = this.getDays(this.formsParams.PickupDate, this.formsParams.DropoffDate);
        const extraPrice = this.calculateExtraPrice(this.extras, days);
        const costs: Cost[] = this.item.Costs.Cost.map((cost, i) => {
            const mappedCost: Cost = {
                ...cost,
                Price: safeNumber(cost.basePrice) + extraPrice,
            };
            if (rateIndex >= 0) {
                return {
                    ...mappedCost,
                    checked: i === rateIndex,
                };
            }
            return mappedCost;
        });

        this.item.Costs.Cost = costs;
    }

    private updateTotalCharge(): void {
        const selectedRate = this.item.Costs.Cost.find(cost => cost.checked);
        this.item.TotalCharge = safeNumber(selectedRate.Price);
    }

    private updateAdditionalParameters(rate: Cost): void {
        const { Product: rateName } = rate;
        if (rateName && rateName.includes('Low Cost')) {
            this.item.AdditionalParameters = ['GType:LC'];
        } else {
            this.item.AdditionalParameters = [];
        }
    }

    private calculateExtraPrice(extraDetails: ExtraDetail[], days = 1): number {
        return extraDetails.reduce((acc, cur) => {
            const safePrice = safeNumber(cur.Price);
            const calculatedPrice = cur.Period === 'Daily' ? safePrice * days : safePrice;
            return acc + calculatedPrice;
        }, 0);
    }

    private getDays(start: string, end: string): number {
        const startTime = moment(moment(start, 'DD/MM/YYYY').format('YYYY-MM-DD').valueOf());
        const endTime = moment(moment(end, 'DD/MM/YYYY').format('YYYY-MM-DD').valueOf());
        return endTime.diff(startTime, 'days');
    }

    private getSupplier(): void {
        this.supplier = this.supplierInfo.find(item => item.Supplier === this.item.Supplier);
        if (this.supplier) {
            this.getTermCond();
        }
    }

    private getTermCond(): void {
        const list = this.supplier.TermsAndConditions.TandCs.filter(item => {
            return item.Header === 'GASOLINA' || item.Header === 'RESTRICCIONES A LA CONDUCCIÓN';
        });
        this.termCond = [...list];
    }

    private updateSelectedCar(): void {
        const shouldUpdate = this.selectedCar && this.item.RateQualifier === this.selectedCar.RateQualifier;
        if (shouldUpdate) {
            this.select();
        }
    }

}
