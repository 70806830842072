import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { PackageSearchDto } from '../package-search.component';
import { PackagesProviderService } from '../../../lib-shared/services/packages-provider.service';
import { PackageNameEnum } from '../../../../mock/packages';

@Component({
    selector: 'lib-island-package-search',
    templateUrl: './island-package-search.component.html',
    styleUrls: ['./island-package-search.component.scss'],
    providers: [PackagesProviderService]
})
export class IslandPackageSearchComponent implements OnInit {

    packageListReq: PackageSearchDto;

    @Input() inputFields;
    @Input() isSmall = false;
    @Input() currentTab: number;
    @Input() microsite;
    @Input() showProviders = true;

    @Output() dataChanged = new EventEmitter<any>();
    @Output() queryParams = new EventEmitter<any>();

    packageName = PackageNameEnum.ISLAND;

    constructor(
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public router: Router,
        public dialog: MatDialog,
        private packageService: PackagesProviderService,
    ) {

    }

    ngOnInit(): void {
    }


    search(event) {
        this.dataChanged.emit(event);
    }


}
