import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { concatMap, map, tap } from "rxjs/operators";
import {
    Hotel,
    HotelBookingPostReqDto,
    HotelBookingPostResDto,
    HotelGetReqDto,
    HotelGetResDto,
    HotelListReqDto,
    HotelListResDto,
    HotelMultidestinationReqDto,
    HotelPutReqDto,
    HotelPutResDto,
    HotelRoomPutReqDto,
    HotelRoomRate,
} from "@vecib2c/frontend-dto/dist";
import { ClientServices } from './client.services';
import { ManagementService } from './management.service';
import { TecnoturisService } from '../../tecnoturis.service';
import { IntegrationsService } from "./integrations.service";
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from "./authentication.service";
import { HotelRoomPutReqMapper } from "../interfaces/hotel/hotel-room-put-req-mapper";
import { Rerate } from '../interfaces/rerate.model';
import { ShopingBasketHotel } from '../../integrations/hotels/dto/ShopingBasketHotel';

@Injectable({
    providedIn: 'root'
})
export class HotelProviderService {
    language = "ES";
    provider;

    constructor(
        private http: HttpClient,
        private managementService: ManagementService,
        private clientService: ClientServices,
        private tecnoturisService: TecnoturisService,
        private integrationsService: IntegrationsService
    ) {
    }

    public setProvider(providerHost: string) {
        this.provider = providerHost;
    }

    private getPath(action) {
        const host = this.tecnoturisService.config.providers[this.provider] ||
            this.tecnoturisService.config.providers.default;
        const list = [`${host}`, "api/v1", action];
        return list.join("/");
    }

    list(req: HotelListReqDto): Observable<HotelListResDto> {
        let params: HttpParams = new HttpParams();
        Object.keys(req).forEach(function (key) {

            const value = key === 'filter' || typeof req[key] === 'object' ? JSON.stringify(req[key]) : req[key];

            params = params.append(key, value);
        });
        params = params.append("language", this.language).append('traking', 'True').append('reqId', uuidv4());
        const path = this.getPath("hotels");
        const url = path;
        return this.http.get<HotelListResDto>(url, {params: params});
    }

    get(req: HotelGetReqDto, id: number): Observable<HotelGetResDto> {
        let params: HttpParams = new HttpParams();
        Object.keys(req).forEach(function (key) {
            params = params.append(key, req[key]);
        });
        params = params.append("language", this.language);

        const path = this.getPath("hotels/" + id);
        const url = path;
        return this.http.get<any>(url, {params: params});
    }

    book(reqParams: HotelBookingPostReqDto, item: ShopingBasketHotel = null, saveBudget = true): Observable<HotelBookingPostResDto> {
        const url = this.getPath("booking");
        reqParams.language = this.language || reqParams.language;
        return this.http.post<HotelBookingPostResDto>(url, reqParams)
            .pipe(
                map(r => {
                    if (item && !r.hotel.roomList || r.hotel.roomList.length === 0) {
                        const mappedHotel: Hotel = Object.assign({}, r.hotel, {
                            roomList: item.roomList,
                        });
                        return {  ...r, hotel: mappedHotel };
                    }
                    return r;
                }),
                tap(async response => {
                    if (saveBudget) {
                        const client = await this.clientService.getClient();
                        const dossier = this.integrationsService.validAndGetDossier();
                        const data = {hotelData: [response], client: client.id, dossier: dossier};
                        await this.managementService.addServicesToDossier(data).toPromise();
                    }
                }
            ));
    }

    checkRate(rateKey: string, hotelRoomPutReqDto: HotelRoomPutReqDto): Observable<HotelRoomRate> {
        const url = this.getPath("hotels/room/" + btoa(rateKey));
        return this.http.put<any>(url, hotelRoomPutReqDto, {headers: { 'Authorization': AuthenticationService.getToken()}});
    }

    recheck(reqParams: HotelPutReqDto, id: number): Observable<HotelPutResDto> {
        const url = this.getPath("hotels/" + id);
        reqParams.language = this.language;
        return this.http.put<any>(url, reqParams);
    }

    auth(id?: String, pagination?: boolean): Observable<any> {
        const obj: any = {id: 0};
        if (pagination === true) {
            obj.pagination = true;
        }
        return this.http
            .post(
                this.getPath("auth"),
                obj,
                {responseType: "text", withCredentials: true}
            )
            .pipe(
                map(response => {
                    return response;
                })
            );
    }

    multipleHotels(req: HotelMultidestinationReqDto): Observable<HotelListResDto[]> {
        let params: HttpParams = new HttpParams();
        Object.keys(req).forEach(function (key) {
            const value = key === 'distribution' ? JSON.stringify(req[key]) : req[key];
            params = params.append(key, value);
        });
        params = params.append("language", this.language);
        const path = this.getPath("multihotels");
        const url = path;
        return this.auth().pipe(
            concatMap(
                () => {
                    return this.http.get<any>(url, {params: params});
                }
            )
        );
    }

    cancel(reqParams: HotelBookingPostResDto) {
        const path = this.getPath("booking/" + reqParams.bookId);
        const params = {
            'providerName': reqParams.providerName,
            'language': this.language
        };
        return this.http.request('delete', path, {body: params});
    }

    rerate(rateKey: string, reqParams: HotelRoomPutReqDto) {
        return this.checkRate(rateKey, reqParams).toPromise()
        .then(res => {
            return Rerate.buildFromHotel(res);
        })
        .catch(err => {
            return undefined;
        });
    }

    rerateFromBasket(item: ShopingBasketHotel): Promise<Rerate> {
        const reqParams = HotelRoomPutReqMapper.buildFromBasket(item);
        const rateId = item.roomList[0].rooms[0].rates[0].rateId;
        const rateCode = item.roomList[0].rooms[0].rates[0].rateCode;
        const rateTokenFromRequest = item.roomList[0].rooms[0].rates[0].rateTokenFromRequest;
        const rateKey = rateCode || rateTokenFromRequest || rateId;
        return this.rerate(rateKey, reqParams);
    }
}
