import { ManagementPax } from "../../interfaces/management.interface";
import { PackagePax } from "../../interfaces/packages/bookings/request/bookings-request";
import * as moment_ from 'moment';
import { safeNumber } from "../../functions/index";

const moment = moment_;

export class PackagePaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialPackagePaxes: PackagePax[],
    ): PackagePax[] {
        const arr = paxes.map((pax, i) => {
            const initialPackagePax = initialPackagePaxes[i] || {} as PackagePax;
            const {
                abbreviation: initialAbbreviation,
                documentType: initialDocumentType,
                documentNumber: initialDocumentNumber,
                birthdate: initialBirthdate,
                documentExpirationDate: initialExpirationDate,
                nationality: initialNationality,
                phoneNumberCode: initialPhoneNumberCode,
                type: initialPaxType,
            } = initialPackagePax;

            const {
                name,
                last_name: lastName,
                email,
                phone,
                dni,
                code,
                ages,
                birthdate,
                expiration_document: expirationDate,
                type,
                type_document: documentType,
                person_title: personTitle,
            } = pax;

            const bookingPax: PackagePax = {
                abbreviation: this.buildAbbreviation(personTitle, initialAbbreviation),
                name,
                lastname: lastName,
                phone,
                email,
                code,
                ages,
                documentType: this.buildDocumentType(documentType, initialDocumentType),
                documentNumber: this.buildDocumentNumber(dni, initialDocumentNumber),
                birthdate: this.buildBirthdate(birthdate, initialBirthdate),
                documentExpirationDate: this.buildExpirationDate(expirationDate, initialExpirationDate),
                nationality: this.buildNationality(initialNationality),
                phoneNumberCode: this.buildPhoneNumberCode(initialPhoneNumberCode),
                type: this.buildPaxType(type, initialPaxType),
            };

            return bookingPax;
        });
        return arr;
    }

    private static buildAbbreviation(
        personTitle: string,
        initialAbbreviation = 'Mr',
    ): string {
        if (!personTitle) { return initialAbbreviation; }
        if (personTitle === 'Señor') { return 'Mr'; }
        if (['Señora', 'Señorita'].includes(personTitle)) { return 'Ms'; }
        return 'Mr';
    }

    private static buildDocumentType(
        documentType: number,
        initialDocumentType: string,
    ): string {
        if (!documentType) { return initialDocumentType; }
        if (documentType === 1) { return 'DNI'; }
        if (documentType === 2) { return 'PAS'; }
        if (documentType === 3) { return 'NIE'; }
    }

    private static buildDocumentNumber(
        documentNumber: string,
        initialDocumentNumber: string,
    ): string {
        if (!documentNumber) { return initialDocumentNumber; }
        return documentNumber;
    }

    private static buildBirthdate(
        birthdate: string,
        initialBirthdate: string,
    ): string {
        if (!birthdate) { return initialBirthdate; }
        return moment(birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    private static buildExpirationDate(
        expirationDate: string,
        initialExpirationDate: string,
    ): string {
        if (!expirationDate) { return initialExpirationDate; }
        return moment(expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    private static buildNationality(
        initialNationality: string,
    ): string {
        if (!initialNationality) { return 'ES'; }
        return initialNationality;
    }

    private static buildPhoneNumberCode(
        initialPhoneNumberCode: number,
    ): number {
        return safeNumber(initialPhoneNumberCode, 34);
    }

    private static buildPaxType(
        paxType: string,
        initialPaxType = 'Adult',
    ): string {
        if (!paxType) { return initialPaxType; }
        const mappedPaxType = paxType.toLowerCase();
        if (['ad', 'adult'].includes(mappedPaxType)) { return 'Adult'; }
        if (['ch', 'child'].includes(mappedPaxType)) { return 'Child'; }
        if (['in', 'infant'].includes(mappedPaxType)) { return 'Infant'; }
        return 'Adult';
    }


}
