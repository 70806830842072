import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingCommissionComponent } from './booking-commission.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [BookingCommissionComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [BookingCommissionComponent]
})
export class BookingCommissionModule {
}
