import { Component, OnInit, OnDestroy } from "@angular/core";
import { BookEditComponent } from "../../book-edit.component";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil, map, catchError} from "rxjs/operators";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { FormArray, FormBuilder, Validators, FormGroup } from "@angular/forms";
import * as moment_ from 'moment';
import { TecnoturisService } from '../../../tecnoturis.service';
import { BasketManager, ClientServices, NotificationPopupService } from '../../../lib-shared/services/index';
import { AgencyChangeService } from '../../../agency-change/index';
import {
  CustomValidators,
  MultidestinationService,
  MultidestinationPreBooking,
  decode,
  TransformPassengerTypeToConditionDate,
  MultidestinationBooking,
  FlightBaggage,
  SelectedService,
} from '../../../lib-shared/index';
import { ShoppingBasketMultidestination } from "../../../lib-shared/interfaces/shopping-basket-multidestination.model";
import { MultidestinationPassenger, Luggage } from "../../../lib-shared/interfaces/multidestination/multidestination-pre-booking.model";
import { t } from 'typy';
import { COUNTRIES } from '../../../../mock/countries';
import { phoneTypes } from "../../../../mock/flights";
import { ListAirBookBaggage } from "@vecib2c/frontend-dto";

const moment = moment_;

@Component({
  selector: 'lib-multidestination-book-edit',
  templateUrl: './multidestination-book-edit.component.html',
  styleUrls: ['./multidestination-book-edit.component.scss'],
  providers: [
    ClientServices,
    MultidestinationService,
    TransformPassengerTypeToConditionDate,
  ]
})

export class MultidestinationBookEditComponent extends BookEditComponent implements OnInit, OnDestroy {

  form: FormGroup;
  shoppingBasket: ShoppingBasketMultidestination;
  abbreviations = [ {id: 'Mr', name: 'Sr'}, {id: 'Ms', name: 'Sra'} ];
  kindOfDocuments = [ {id: 'DNI', name: 'DNI'}, {id: 'NIE', name: 'NIE'}, {id: 'PAS', name: 'PASAPORTE'} ];
  phoneTypes = phoneTypes;
  countries = COUNTRIES;
  minDocumentExpirationDate = moment().add(1, 'years').toDate();
  confirmedPrivacyPolicy = false;
  luggagesByPax: ListAirBookBaggage[][] = [];
  luggagesByBooking: ListAirBookBaggage[][] = [];
  totalPriceIncludingLuggage = 0;

  get passengers(): FormArray {
    return <FormArray>this.form.get('passengers');
  }
  get cabinLuggages(): FormArray {
    return <FormArray>this.form.get('cabinLuggages');
  }

  constructor(
      private readonly route: ActivatedRoute,
      private readonly formBuilder: FormBuilder,
      protected location: Location,
      public http: HttpClient,
      protected clientService: ClientServices,
      protected translate: TranslateService,
      protected notification: NotificationPopupService,
      public router: Router,
      protected _basketManager: BasketManager,
      protected agencyChangeService: AgencyChangeService,
      public tecnoturisService: TecnoturisService,
      private readonly multidestinationService: MultidestinationService,
      private readonly transformPassengerTypeToConditionDatePipe: TransformPassengerTypeToConditionDate,
  ) {
      super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
  }

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');

    this.shoppingBasket = await this.getShoppingBasketMultidestination(id);

    if (this.shoppingBasket) {
      this.initForm(this.shoppingBasket.passengers, this.shoppingBasket.cabinLuggages);
      this.totalPriceIncludingLuggage =
        this.shoppingBasket.totalPriceIncludingLuggage ?
        this.shoppingBasket.totalPriceIncludingLuggage :
        this.calculateTotalPriceIncludingLuggage(this.shoppingBasket.totalPrice);

      const { luggagesByPax, luggagesByBooking } = ShoppingBasketMultidestination.buildLuggages(this.shoppingBasket);
      this.luggagesByPax = luggagesByPax;
      this.luggagesByBooking = luggagesByBooking;

    } else {
      const preBookingParameters = this.getPreBookingParametersFromURL(id);

      this.route.paramMap.pipe(
        map(() => window.history.state),
        takeUntil(this._unsubscribe)
      ).subscribe(async(state) => {
        const selectedServices: SelectedService[] = state.multidestination ? state.multidestination : [];
        const res = await this.multidestinationService.preBook(preBookingParameters).toPromise();

        if (res.data && !res.data.errors) {
          this.shoppingBasket = ShoppingBasketMultidestination.initialize(id, res.data, selectedServices);
          this.initForm(this.shoppingBasket.passengers, this.shoppingBasket.cabinLuggages);
          this.totalPriceIncludingLuggage =
            this.shoppingBasket.totalPriceIncludingLuggage ?
            this.shoppingBasket.totalPriceIncludingLuggage :
            this.calculateTotalPriceIncludingLuggage(this.shoppingBasket.totalPrice);

          const { luggagesByPax, luggagesByBooking } = ShoppingBasketMultidestination.buildLuggages(this.shoppingBasket);
          this.luggagesByPax = luggagesByPax;
          this.luggagesByBooking = luggagesByBooking;

        } else {
          this.location.back();
        }
      });
    }

  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  addDrivingDestination(locationIndex: number, passengerIndex: number): void {
    const destinationIndex = locationIndex + 1;

    this.passengers.value.forEach((passenger: MultidestinationPassenger, i: number) => {
      passenger.drivingDestinations.forEach((d, drivingDestinationIndex) => {
        if (d.destinationIndex === destinationIndex) {
          (this.passengers.controls[i].get('drivingDestinations') as FormArray).removeAt(drivingDestinationIndex);
        }
      });
    });

    (this.passengers.controls[passengerIndex].get('drivingDestinations') as FormArray).push(this.formBuilder.group({ destinationIndex }));
  }

  setBirthdate(index: number, date: Date): void {
    const formattedDate = date ? moment(date).format('YYYY/MM/DD') : null;
    this.passengers.controls[index].get('birthdate').setValue(formattedDate);
    this.passengers.controls[index].get('birthdate').markAsTouched();
  }

  setDocumentExpirationDate(index: number, date: Date): void {
    const formattedDate = date ? moment(date).format('YYYY/MM/DD') : null;
    this.passengers.controls[index].get('documentExpirationDate').setValue(formattedDate);
    this.passengers.controls[index].get('documentExpirationDate').markAsTouched();
  }

  async addToShoppingCart(): Promise<void> {

    this.completeShoppingBasket();
    const listFromStorage = await this._basketManager.addOrUpdate(this.shoppingBasket);
    this.router.navigateByUrl('/intranet', { replaceUrl: true });
  }

  book(): void {
    if (super.checkClient()) {
      this.completeShoppingBasket();

      const bookingParameters = MultidestinationBooking.build(
        this.shoppingBasket.passengers,
        this.shoppingBasket.requestToken,
        this.shoppingBasket.preBookingToken,
        this.shoppingBasket.cabinLuggages,
        this.client,
      );

      this.multidestinationService.book(bookingParameters, true, this.shoppingBasket).toPromise().then(async(res) => {
        const navigationExtras: NavigationExtras = {};

        if (res.data && !res.data.errors) {
          navigationExtras.state = {
            bookingPostResDto: [res.data],
            shoppingBasket: this.shoppingBasket
          };
          const listFromStorage = await this._basketManager.addOrUpdate(this.shoppingBasket);
        } else {
          navigationExtras.state = {
            bookingPostResDto: [{ failed: true, locations: [] }],
            shoppingBasket: this.shoppingBasket
          };
        }
        this.router.navigate(
          ['/integration/bookings/book-confirmation'],
          navigationExtras
        );
      });
    }
  }

  selectPaxLuggage(baggage: FlightBaggage, passengerIndex: number, destinationIndex: number): void {

      const luggagesByDestination: Luggage[] = this.passengers.controls[passengerIndex].get('luggages').value;

      const index = luggagesByDestination.findIndex(f => f.destinationIndex === destinationIndex);
      if (index !== -1) {
        (this.passengers.controls[passengerIndex].get('luggages') as FormArray).removeAt(index);
      }

      if (baggage) {
        (this.passengers.controls[passengerIndex].get('luggages') as FormArray).push(
          this.formBuilder.group({
            luggageNumberOption: baggage.numberOption,
            luggageOption: baggage.luggageOption,
            destinationIndex: destinationIndex,
            luggageId: baggage.id,
            priceWithCommission: baggage.priceWithCommission,
          })
        );
      }

      this.totalPriceIncludingLuggage = this.calculateTotalPriceIncludingLuggage(this.shoppingBasket.totalPrice);
  }

  selectCabinLuggage(baggage: FlightBaggage, destinationIndex: number): void {
    const luggagesByDestination: Luggage[] = this.cabinLuggages.value;

    const index = luggagesByDestination.findIndex(f => f.destinationIndex === destinationIndex);
    if (index !== -1) {
      this.cabinLuggages.removeAt(index);
    }

    if (baggage) {
      this.cabinLuggages.push(
        this.formBuilder.group({
          luggageNumberOption: baggage.numberOption,
          luggageOption: baggage.luggageOption,
          destinationIndex: destinationIndex,
          luggageId: baggage.id,
          priceWithCommission: baggage.priceWithCommission,
        })
      );
    }

    this.totalPriceIncludingLuggage = this.calculateTotalPriceIncludingLuggage(this.shoppingBasket.totalPrice);

  }

  private getPreBookingParametersFromURL(id: string): MultidestinationPreBooking {
    const decodedRawId = decode(id);

    const decodedId: { preBookingParameters: MultidestinationPreBooking, pageId: string } = decodedRawId ? JSON.parse(decodedRawId) : null;
    if (!decodedId) { return; }
    const preBookingPrameters = decodedId.preBookingParameters;

    return preBookingPrameters;
  }

  private initForm(passengers: MultidestinationPassenger[], cabinLuggages: Luggage[]): void {
    this.form = this.formBuilder.group({
      passengers: this.formBuilder.array([]),
      cabinLuggages: this.formBuilder.array(t(cabinLuggages).safeArray.map(luggage => {
        return this.formBuilder.group({
          luggageNumberOption: [t(luggage, 'luggageNumberOption').safeObject],
          luggageOption: [t(luggage, 'luggageOption').safeObject],
          destinationIndex: [t(luggage, 'destinationIndex').safeObject],
          luggageId: [t(luggage, 'luggageId').safeObject],
          priceWithCommission: [t(luggage, 'priceWithCommission').safeObject],
        });
      }))
    });

    passengers.forEach(passenger => {
      this.passengers.push(this.formBuilder.group({
        type: [t(passenger, 'type').safeObject, Validators.required],
        name: [t(passenger, 'name').safeObject, Validators.required],
        lastname: [t(passenger, 'lastname').safeObject, Validators.required],
        email: [t(passenger, 'email').safeObject, Validators.required],
        birthdate: [t(passenger, 'birthdate').safeObject,
          [ Validators.required,
            CustomValidators.minDate(this.transformPassengerTypeToConditionDatePipe.transform(passenger.type, 'min')),
            CustomValidators.maxDate(this.transformPassengerTypeToConditionDatePipe.transform(passenger.type, 'max')) ]
        ],
        abbreviation: [t(passenger, 'abbreviation').safeObject, Validators.required],
        phoneNumberCode: [t(passenger, 'phoneNumberCode').safeObject, Validators.required],
        phoneType: [t(passenger, 'phoneType').safeObject, Validators.required],
        phone: [t(passenger, 'phone').safeObject, Validators.required],
        documentType: [t(passenger, 'documentType').safeObject, Validators.required],
        documentNumber: [t(passenger, 'documentNumber').safeObject, Validators.required],
        documentExpirationDate: [t(passenger, 'documentExpirationDate').safeObject, [
          Validators.required, CustomValidators.minDate(this.minDocumentExpirationDate)]
        ],
        nationality: [t(passenger, 'nationality').safeObject, Validators.required],
        roomNumber: [t(passenger, 'roomNumber').safeObject, Validators.required],
        luggages: this.formBuilder.array(t(passenger, 'luggages').safeArray.map(luggage => {
          return this.formBuilder.group({
            luggageNumberOption: [t(luggage, 'luggageNumberOption').safeObject],
            luggageOption: [t(luggage, 'luggageOption').safeObject],
            destinationIndex: [t(luggage, 'destinationIndex').safeObject],
            luggageId: [t(luggage, 'luggageId').safeObject],
            priceWithCommission: [t(luggage, 'priceWithCommission').safeObject]
          });
        })),
        drivingDestinations: this.formBuilder.array(t(passenger, 'drivingDestinations').safeArray.map(drivingDestination => {
          return this.formBuilder.group({
            destinationIndex: [t(drivingDestination, 'destinationIndex').safeObject],
          });
        })),
      }));
    });
  }

  private async getShoppingBasketMultidestination(id: string): Promise<ShoppingBasketMultidestination> {
    const shoppingBaskets = await this._basketManager.getList();
    return shoppingBaskets.find(s => s.uuid === id);
  }

  private completeShoppingBasket(): void {
    const rawForm: { passengers: MultidestinationPassenger[], cabinLuggages: Luggage[] } = this.form.getRawValue();
    this.shoppingBasket.passengers = MultidestinationPassenger.completePassengerFromLocations(
      rawForm.passengers,
      this.shoppingBasket.locations,
    );
    this.shoppingBasket.client = this.client;
    this.shoppingBasket.totalPriceIncludingLuggage = this.totalPriceIncludingLuggage;
    this.shoppingBasket.cabinLuggages = rawForm.cabinLuggages;
  }

  private calculateTotalPriceIncludingLuggage(rawPrice: number): number {
    const rawForm: { passengers: MultidestinationPassenger[], cabinLuggages: Luggage[] } = this.form.getRawValue();
    const passengers = rawForm.passengers;
    const cabins = rawForm.cabinLuggages || [];

    const luggageTotalPrice = passengers.reduce((acc, passenger) => {
      const luggages = passenger.luggages || [];
      const luggagePrice = luggages.reduce((luggageAccumulator, luggage) => {
        return luggageAccumulator + luggage.priceWithCommission;
      }, 0);

      return acc + luggagePrice;
    }, 0);

    const cabinPrice = cabins.reduce((acc, cabin) => {
      return acc + cabin.priceWithCommission;
    }, 0);

    return rawPrice + luggageTotalPrice + cabinPrice;

  }

}
