import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstLetterUpperCase'
})
export class FirstLetterUpperCasePipe implements PipeTransform {

    transform(string: any, args?: any): any {
        if ((string === 'select') || (string === 'mismaHabitacion') || (string === 'icono')) {
            return;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}
