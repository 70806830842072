import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'cleanCarRateName'
})
export class CleanCarRateNamePipe implements PipeTransform {

    transform(rateName: string): string {
        if (!rateName) { return; }
        const position = rateName.indexOf('-');
        return rateName.substr(position + 1, rateName.length);
    }
}
