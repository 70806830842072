import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormlyField} from '@ngx-formly/core';
import {debounceTime, startWith, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {BaseAddEdit} from '../base-add-edit';
import {IDynamicCrud, IProvider} from '../lib-shared/interfaces/index';
import {
    AgencyProviderService,
    AuthenticationService,
    DynamicCrudService,
    ManagementService,
} from '../lib-shared/services/index';
import {DynamicCrudUtils} from '../utils';
import {Subject} from 'rxjs';
import * as moment_ from 'moment';

const moment = moment_;

@Component({
    selector: 'lib-form-add-edit',
    templateUrl: './form-add-edit.component.html',
    styleUrls: ['./form-add-edit.component.scss']
})
export class FormAddEditComponent extends BaseAddEdit implements OnChanges, OnInit {
    @Input() form: FormGroup;
    @Input() formlyFields: FormlyField[];
    @Input() model: any;
    @Input() dynamicCrud: IDynamicCrud;
    @Input() showCancel = true;

    @Input() childsContainerTemplate: TemplateRef<any>;

    @Input() appendKey: string;

    @Input() extractDataFromKey: string;

    @Input() includeRooms = false;

    @Input() serviceText: string;

    @Input() icon: string;

    @Input() showEdit: boolean;

    auth = AuthenticationService.getUser();

    providers: IProvider[] = [];

    fees: any[];

    agency_provider: any;
    @Input() rowDetail = true;

    @Input() bond = false;

    @Input() defaultValueChanges = true;

    @Input() defaultSaveEvent = false;

    booking: any;

    calculatedMargin$ = new Subject();
    fiscalUbicationChanges$ = new Subject();

    optionsValue = {
        onlySelf: true,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: false
    };

    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @Output() addItem: EventEmitter<any> = new EventEmitter<any>();

    @Output() saveChildData: EventEmitter<{ crud: string, data: any }> = new EventEmitter<{ crud: string, data: any }>();

    constructor(route: ActivatedRoute,
                router: Router,
                dynamicCrudService: DynamicCrudService,
                managementService: ManagementService,
                private agencyProviderService: AgencyProviderService,
                public location: Location) {
        super(route, router, dynamicCrudService, managementService, location);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.defaultValueChanges) {
            this.changeDefaultValue();
        }

        this.getObjectChange.subscribe(response => this.booking = response);
        this.postSave.subscribe(res => {
            this.addItem.emit(res);
        });

        this.calculatedMargin$.pipe(
            debounceTime(700),
            takeUntil(this.unsubscribe),
        ).subscribe(key => {
            this.calculateMargin(key);
        });

        this.fiscalUbicationChanges$.pipe(
            debounceTime(100),
            takeUntil(this.unsubscribe),
        ).subscribe(async res => {
            return await this.fiscalUbicationChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dynamicCrud && changes.dynamicCrud.currentValue) {
            // this.customObjectToSave = {};
        }
    }

    editEvent(data) {
        this.edit.emit(data);
    }

    postSaveSuccess<T>(response: T, queryParams): void {
        if (this.defaultSaveEvent) {
            super.postSaveSuccess(response, queryParams);
        } else {
            this.changeData.emit(response);
        }
    }

    async providerChanges(): Promise<void> {
        await this.getProviders();
        DynamicCrudUtils.setDinamycallyOptions(this.formlyFields, 'provider', this.providers);
        this.form.get('provider')
            .valueChanges
            .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('provider').value))
            .subscribe(value => {
                const provider = this.providers.find(p => p.id === +value);
                if (provider) {
                    this.getAgencyProvider(provider.id);
                    this.form.get('provider_accountant_account').setValue(provider.accountant_account);
                }
            });

        this.form.get('product_type')
            .valueChanges
            .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('product_type').value))
            .subscribe(value => {
                if (this.fees) {
                    const fee = this.fees.find(f => f.id === +value);
                    if (fee) {
                        this.form.get('fee_apply').setValue(fee.fee_apply);
                        this.form.get('comission').setValue(fee.comission);
                        this.form.get('iva').setValue(fee.iva);
                    }
                }
            });

        const keysFiscalLocation = ['fiscal_location', 'product_type', 'fee_apply', 'provider'];
        keysFiscalLocation.forEach(k => {
            this.form.get(k)
                .valueChanges
                .pipe(
                    takeUntil(this.unsubscribe),
                    startWith(this.form.get(k).value),
                ).subscribe(value => {
                this.fiscalUbicationChanges$.next();
            });
        });

        const keysCalculatedPrices = [
            'fee_apply',
            'total_net',
            'total_pvp',
            'comission',
            'iva',
            'pvp_fee',
            'not_fee_amount',
            'discount',
            'fee',
        ];
        keysCalculatedPrices.forEach(k => {
            this.form.get(k)
                .valueChanges
                .pipe(
                    takeUntil(this.unsubscribe),
                    startWith(this.form.get(k).value))
                .subscribe(value => {
                    this.calculatedMargin$.next(k);
                });
        });

        if (this.form.get('valued_bond')) {
            this.form.get('valued_bond')
                .valueChanges
                .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('valued_bond').value))
                .subscribe(value1 => {
                    if (value1) {
                        this.form.get('price_bond').enable();
                    } else {
                        this.form.get('price_bond').disable();
                        this.form.get('price_bond').setValue(null);
                    }
                });
        }

        // this.form.get('payment_method')
        //     .valueChanges
        //     .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('payment_method').value))
        //     .subscribe(value1 => {
        //         if (value1 === 4) {
        //             this.form.get('payday_limit').enable();
        //         } else {
        //             this.form.get('payday_limit').disable();
        //             this.form.get('payday_limit').setValue(null);
        //         }
        //     });
    }

    private async fiscalUbicationChanges(): Promise<void> {
        const formValues = this.form.getRawValue();
        const serviceId = this.booking.service.id;
        if (formValues['fee_apply'] && formValues['fiscal_location'] && formValues['provider'] && serviceId) {
            const request = {
                fee_apply_id: formValues['fee_apply'],
                fiscal_location_id: formValues['fiscal_location'],
                provider_id: formValues['provider'],
                service_id: serviceId,
            };
            const taxRegimeIvaApply = await this.managementService.getTaxRegimeIvaApply(request).toPromise();
            if (taxRegimeIvaApply) {
                this.form.get('iva').setValue(taxRegimeIvaApply.iva_commission);
                this.calculatedMargin$.next();
            }
        }
    }

    private calculateMargin(key?: any) {

        const formValues = this.form.getRawValue();

        const commissionType = formValues['fee_apply'];

        if (commissionType) {
            const iva = formValues['iva'] ? formValues['iva'] : 0;
            const discounts = -(formValues['discount'] ? formValues['discount'] : 0);
            const fees = +(formValues['fee'] ? formValues['fee'] : 0);
            const comission = formValues['comission'] ? formValues['comission'] : 0;

            let non_commissionable_rate = formValues['not_fee_amount'] ? formValues['not_fee_amount'] : 0;
            let total_net = formValues['total_net'] ? formValues['total_net'] : 0;
            let pvp_fee = formValues['pvp_fee'] ? formValues['pvp_fee'] : 0;
            pvp_fee = total_net;
            if (this.isCommissionable(commissionType)) {
                if (key !== 'total_net') {
                    total_net = pvp_fee;
                } else {
                    pvp_fee = total_net;
                }
            } else {
                pvp_fee = total_net;
                non_commissionable_rate = 0;
            }

            const rawCommissionRate = (pvp_fee * comission) / 100;
            const profit = rawCommissionRate + fees;
            const profit_gross = rawCommissionRate * (1 + iva / 100) + fees;
            const total_pvp = pvp_fee + non_commissionable_rate + profit_gross + discounts;

            this.form.get('comission').setValue(+comission.toFixed(2), this.optionsValue);
            this.form.get('profit_gross').setValue(+profit_gross.toFixed(2), this.optionsValue);
            this.form.get('profit').setValue(+profit.toFixed(2), this.optionsValue);
            this.form.get('total_pvp').setValue(+total_pvp.toFixed(2), this.optionsValue);
            this.form.get('total_net').setValue(+total_net.toFixed(2), this.optionsValue);
            this.form.get('pvp_fee').setValue(+pvp_fee.toFixed(2), this.optionsValue);
            this.form.get('not_fee_amount').setValue(+non_commissionable_rate.toFixed(2), this.optionsValue);
        }
    }

    changeDefaultValue(): void {
        let countChangesComissions = 0;
        // const keysChangeNeto = ['comission', 'iva', 'discount', 'fee', 'pvp_fee', 'not_fee_amount', 'total_net'];
        this.formCreated.subscribe(_ => {
            this.providerChanges();
            // this.changeFormlyFieldsProperties('total_pvp', {readonly: true});
            this.form.get('fee_apply')
                .valueChanges
                .pipe(takeUntil(this.unsubscribe), startWith(this.form.get('fee_apply').value))
                .subscribe(value => {
                    // NETO
                    if (value === 1 || value === 3) {
                        this.changeFormlyFieldsProperties('pvp_fee', {readonly: true});
                        this.changeFormlyFieldsProperties('not_fee_amount', {readonly: true});
                        this.changeFormlyFieldsProperties('total_pvp', {readonly: false});
                    } else if (value === 2) {
                        this.changeFormlyFieldsProperties('pvp_fee', {readonly: false});
                        this.changeFormlyFieldsProperties('not_fee_amount', {readonly: false});
                        this.changeFormlyFieldsProperties('total_pvp', {readonly: true});
                    }
                    countChangesComissions++;
                });

            this.validateDatesInCar();
            this.validateDatesInInsuranceAndPackage();
        });
    }


    async getProviders(): Promise<void> {
        this.providers = this.auth.agency && this.auth.agency.id ?
            await this.agencyProviderService.getProviders(this.auth.agency.id, false, true).toPromise() : [];
    }

    async getAgencyProvider(provider: number): Promise<void> {
        this.agency_provider = await this.agencyProviderService.getAgencyProvider(this.auth.agency.id, provider).toPromise();
        this.getFees();
    }

    async getFees(): Promise<void> {
        this.fees = await this.agencyProviderService.getFees(this.agency_provider.id).toPromise();
        DynamicCrudUtils.setDinamycallyOptions(this.formlyFields, 'product_type', this.fees.map(f => {
            return {id: +f.id, name: f.product};
        }));
        this.form.get('product_type').setValue(this.form.get('product_type').value);
    }

    private isCommissionable(commissionType: number) {
        return commissionType === 2;
    }

    private validateDatesInCar(): void {
        if (!this.form.get('pickup_date')) { return; }
        this.form.get('pickup_date')
        .valueChanges
        .pipe(startWith(this.form.get('pickup_date').value), takeUntil(this.unsubscribe))
        .subscribe(pickUpDate => {
            const mappedPickUpDate = moment(pickUpDate).format('YYYY/MM/DD HH:mm:ss');
            const dropOffDate = this.form.get('dropoff_date').value;
            if (pickUpDate.isAfter(dropOffDate)) {
                this.form.get('dropoff_date').setValue(pickUpDate);
            }
            DynamicCrudUtils.setDinamycallyMin(this.formlyFields, 'dropoff_date', mappedPickUpDate);
        });
    }

    private validateDatesInInsuranceAndPackage(): void {
        if (!this.form.get('check_in')) { return; }
        this.form.get('check_in')
        .valueChanges
        .pipe(startWith(this.form.get('check_in').value), takeUntil(this.unsubscribe))
        .subscribe(checkIn => {
            const checkOut = this.form.get('check_out').value;
            if (moment(checkIn).isAfter(moment(checkOut))) {
                this.form.get('check_out').setValue(checkIn);
            }
            DynamicCrudUtils.setDinamycallyMin(this.formlyFields, 'check_out', checkIn);
        });
    }

}
