import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FlightBaggage, LuggageOptionEnum } from '../lib-shared/interfaces/flight/low-cost.model';
import { ListAirBookBaggage } from "@vecib2c/frontend-dto";

@Component({
    selector: "lib-luggage-select",
    templateUrl: "./luggage-select.component.html",
    styleUrls: ["./luggage-select.component.scss"],
})
export class LuggageSelectComponent implements OnInit {

    @Input() label: string;
    @Input() luggages: ListAirBookBaggage[];
    @Input() selectedLuggageId: string;
    @Input() commissionRate: number;
    @Input() iva: number;

    luggagesOptions = LuggageOptionEnum;

    @Output() handleSelect = new EventEmitter<FlightBaggage>();

    constructor() {
    }

    ngOnInit(): void {
    }

    select(luggageId: string): void {
        const luggage = this.luggages.find(l => l.id === luggageId);
        const mappedLuggage: FlightBaggage = luggage && Object.assign({}, luggage, {
            priceWithCommission: this.priceWithCommission(luggage.price, this.commissionRate, this.iva)
        });
        this.handleSelect.emit(mappedLuggage);
    }


    private priceWithCommission(price: number, commissionRate = 0, iva = 0): number {
        return price + (price * commissionRate / 100 * ( 1 + iva / 100 ));
    }

}
