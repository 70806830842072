import { Response } from './response';

export class BookingsDeleteDtoRes extends Response {
    data: BookingsDeleteResponse;
}

export class BookingsDeleteResponse {
    fileId: string;
    ticketReference: string;
    outwardTicketPrices: TicketPrice[];
    returnTicketPrices: TicketPrice[];
    error?: string[];
}

export class TicketPrice {
    seatTicketId: string;
    price: number;
}
