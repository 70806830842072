import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecnoturisServiceConfig } from './tecnoturis.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class TecnoturisModule {
    constructor(@Optional() @SkipSelf() parentModule?: TecnoturisModule) {
        if (parentModule) {
            throw new Error(
                'TecnoturisModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config: TecnoturisServiceConfig): ModuleWithProviders {
        return {
            ngModule: TecnoturisModule,
            providers: [
                {provide: TecnoturisServiceConfig, useValue: config}
            ]
        };
    }
}
