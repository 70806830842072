import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
    selector: 'lib-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

    @Input() cellTemplate: TemplateRef<any>;
    @Input() dataEvent: any[];
    @Input() options: any;
    @Input() classCarousel = 'main-carousel';

    @Output() eventItemSelected: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    eventItem(item) {
        this.eventItemSelected.emit(item);
    }

}
