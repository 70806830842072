import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetCardsPerRowPipe } from './pipes/get-cards-per-row.pipe';
import { SanitizerTextPipe } from './pipes/sanitizer-text.pipe';
import { AlertConfirmComponent } from './components/alert-confirm/alert-confirm.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { AlertSnackComponent } from './components/alert-snack/alert-snack.component';
import { ModalProviderService } from './service/ModalProvider.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SpinnerLoadingComponent } from './components/spinner-loading/spinner-loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerLoaderService } from './service/SpinnerLoader.service';
import { SetItineraryDatePipe } from './pipes/set-itinerary-date.pipe';
import { NumberTypePipe } from './pipes/numberType.pipe';
import {PriceTextPipe} from './pipes/price-text.pipe';
import { SafeHtmlPipe } from './pipes/html-pipe';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';


@NgModule({
    declarations: [
        GetCardsPerRowPipe,
        SanitizerTextPipe,
        AlertConfirmComponent,
        AlertMessageComponent,
        AlertSnackComponent,
        SpinnerLoadingComponent,
        SetItineraryDatePipe,
        NumberTypePipe,
        PriceTextPipe,
        SafeHtmlPipe,
        PageNotFoundComponent,
    ],
    exports: [
        GetCardsPerRowPipe,
        SanitizerTextPipe,
        AlertConfirmComponent,
        AlertMessageComponent,
        AlertSnackComponent,
        SpinnerLoadingComponent,
        SetItineraryDatePipe,
        NumberTypePipe,
        PriceTextPipe,
        SafeHtmlPipe,
        PageNotFoundComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                ModalProviderService,
                SpinnerLoaderService
            ]
        };
    }
}
