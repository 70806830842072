import { Component, Input } from '@angular/core';
import { ShoppingBasketPackage } from '../../../../lib-shared/interfaces/index';
import { ShoppingCartSummaryComponent } from '../../../shopping-cart-summary.component';

@Component({
    selector: 'lib-package-cart-item',
    templateUrl: './package-cart-item.component.html',
    styleUrls: ['./package-cart-item.component.scss']
})
export class PackageCartItemComponent {

    @Input() set item(item: ShoppingBasketPackage) {
        this._item = item;
        if (item) {
            this.total = item.totalAmount;
        }
    }

    get item(): ShoppingBasketPackage {
        return this._item;
    }

    total = 0;

    private _item: ShoppingBasketPackage;

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }

    getCategory(starsNumber: any): Array<any> {
        if (starsNumber == undefined) {
            return [1]
        }
        const stars = [1, 2, 3, 4, 5];
        return stars.filter((star) => {
            return star <= starsNumber;
        });
    }
}
