import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TecnoturisService } from "../../tecnoturis.service";
import { AuthenticationService } from "..";
import { timeout } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class InsuranceService {
  host: string;
  constructor(
    public http: HttpClient,
    private tecnoturisService: TecnoturisService
  ) {
    this.host = this.tecnoturisService.config.packages.providers;
  }

  public getInsurance(insuranceToken: string) {
    return this.http.get(
      this.host + "/api/v1/insurance" + `?midInsuranceToken=${insuranceToken}`
    );
  }
}
