import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterImagesToShow'
})
export class FilterImagesToShowPipe implements PipeTransform {

    transform(images: (string)[], indexActive?: number, returnSize = 5): (string)[] {
        indexActive -= 1;
        returnSize += indexActive;
        if (images.length < returnSize) {
            returnSize = images.length;
            indexActive = images.length - 5;
        } else if (indexActive < 5) {
            indexActive = 0;
            returnSize = 5;
        }

        return images.slice(indexActive, returnSize);
    }

}
