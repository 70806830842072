import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'filterArraySelected'
})
export class FilterArraySelectedPipe implements PipeTransform {
    transform(list: any[]): any[] {
        return list && list.length > 0 ? list.filter(item => item.selected) : [];
    }
}
