import { Component, Input } from "@angular/core"
import { VeciService } from "../../service/veci.service"

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  htmlFooter: any

  constructor(private veciService: VeciService) {
    this.veciService.getHtml().subscribe((response: any) => {
      this.htmlFooter = response.results[0].footer
    })
  }
}
