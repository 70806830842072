import { Pipe, PipeTransform } from '@angular/core';
import { PriceGroup } from '@vecib2c/frontend-dto/dist';

@Pipe({
    name: 'amountByPassenger'
})
export class AmountByPassengerPipe implements PipeTransform {

    transform(priceGroup: PriceGroup, kindOfPassenger: string): number {
        if (kindOfPassenger === 'adult') {
            return priceGroup.adultTicketAmount;
        } else if (kindOfPassenger === 'children') {
            return priceGroup.childrenTicketAmount;
        } else {
            return priceGroup.infantTicketAmount;
        }
    }

}
