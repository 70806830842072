import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { TecnoturisService } from '../../tecnoturis.service';
import {
  MultidestinationList,
  MultidestiantionListResponse,
  MultidestinationPreBooking,
  MultidestinationPreBookingCreateResponse,
  MultidestinationBooking,
  MultidestinationBookingCreateResponse,
} from "../interfaces";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { ShoppingBasketMultidestination } from "../interfaces/shopping-basket-multidestination.model";
import * as jsPDF from 'jspdf';
import { ClientServices } from "./client.services";
import { IntegrationsService } from "./integrations.service";
import { ManagementService } from "./management.service";
import { tap } from "rxjs/operators";

@Injectable()
export class MultidestinationService {

  constructor(
    private readonly http: HttpClient,
    private readonly tecnoturisService: TecnoturisService,
    private readonly clientService: ClientServices,
    private readonly integrationsService: IntegrationsService,
    private readonly managementService: ManagementService,
  ) {
  }

  list(req: MultidestinationList): Observable<MultidestiantionListResponse> {
    const headers = new HttpHeaders().set('authorization', AuthenticationService.getToken());

    const path = this.getPath("multidestinations");

    return this.http.post<MultidestiantionListResponse>(path, req, {...headers });
  }

  preBook(req: MultidestinationPreBooking): Observable<MultidestinationPreBookingCreateResponse> {
    const headers = new HttpHeaders().set('authorization', AuthenticationService.getToken());

    const path = this.getPath("pre-bookings");

    return this.http.post<MultidestinationPreBookingCreateResponse>(path, req, {...headers });
  }

  book(
    req: MultidestinationBooking,
    saveBudget = true,
    shoppingBasket?: ShoppingBasketMultidestination,
  ): Observable<MultidestinationBookingCreateResponse> {
    const headers = new HttpHeaders().set('authorization', AuthenticationService.getToken());

    const path = this.getPath("bookings");

    return this.http.post<MultidestinationBookingCreateResponse>(path, req, {...headers }).pipe(tap( async response => {
      if (saveBudget && response.data && response.data.totalPrice > 0) {
        const client = await this.clientService.getClient();
        const dossier = this.integrationsService.validAndGetDossier();
        const mappedResponse = {multidestinationData: [response], client: client.id, dossier: dossier};
        if (shoppingBasket) {
          mappedResponse.multidestinationData[0].data.preBookingResponses = shoppingBasket.preBookingResponses;
          mappedResponse.multidestinationData[0].data.locations = shoppingBasket.locations;
        }
        await this.managementService.addServicesToDossier(mappedResponse).toPromise();
      }
    }));
  }

  bookFromShoppingBasket(parameters: ShoppingBasketMultidestination): Observable<MultidestinationBookingCreateResponse> {
    const bookingParameters = MultidestinationBooking.build(
      parameters.passengers,
      parameters.requestToken,
      parameters.preBookingToken,
      parameters.cabinLuggages,
      parameters.client,
    );
    return this.book(bookingParameters);
  }

  bookFromBasket(item: ShoppingBasketMultidestination) {
    return this.bookFromShoppingBasket(item).toPromise()
    .then(res => {
        if (res.data && !res.data.errors) {
            return Promise.resolve(res);
        }
        item.failed = true;
        return Promise.reject(res);
    })
    .catch(err => {
        return Promise.reject(item);
    });
  }

  generateInitialBudgetPDF(element: HTMLElement): void {
    const doc = new jsPDF('p', 'pt', 'a4');
    const specialElementHandlers = {
      '#bypassme': function(element, renderer) {
        return true;
      }
    };
    doc.fromHTML(
      element,
      15,
      15, {
        elementHandlers: specialElementHandlers
      }
    );
    doc.save('Multidestino.pdf');
  }

  private getPath(action: string): string {
    const host = this.tecnoturisService.config.multidestination;

    const list: string[] = [`${host}`, "api/v1", action];
    return list.join("/");
  }
}
