import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactPerson } from '../lib-shared/interfaces/index';
import { TecnoturisService } from '../tecnoturis.service';

@Injectable({
    providedIn: 'root'
})
export class ManagementClientService {
    protected host;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
        this.host = this.tecnoturisService.config.management;
    }

    getClient(id: number) {
        return this.http.get(`${this.host}/clients/${id}/`);
    }

    clientTypes(): Observable<any> {
        return this.http.get(this.host + '/client/types/');
    }

    typeDocuments(): Observable<any> {
        return this.http.get(this.host + '/client/type-document/');
    }

    listContacts(clientId: number): Observable<any> {
        return this.http.get(`${this.host}/clients/${clientId}/contact-persons/`);
    }

    addContact(contact: Partial<ContactPerson>): Observable<any> {
        return this.http.post(this.host + '/clients/contact-person/', contact);
    }

    removeContact(contactId: number): Observable<any> {
        return this.http.delete(`${this.host}/clients/contact-person/${contactId}/`);
    }
}
