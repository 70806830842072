import { NgModule } from '@angular/core';
import { FilterPackagesComponent } from './filter-packages.component';
import { MatCheckboxModule } from "@angular/material";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { FilterModule } from '../../../filter/index';
import {LibSharedModule} from "../../../lib-shared/index";

@NgModule({
    declarations: [FilterPackagesComponent],
    exports: [
        FilterPackagesComponent
    ],
    imports: [
        LibSharedModule,
        MatCheckboxModule,
        FormsModule,
        IonicModule,
        FilterModule
    ]
})
export class FilterPackagesModule {
}
