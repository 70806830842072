import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IAgency } from '../interfaces/agency.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {AuthenticationService, objectToQuerystring, packageDestinationDisney} from '../../../../projects/tecnoturis/src/public-api'
import { tap } from 'rxjs/operators';
import {BasketActions} from '../../pages/home/home.page';

@Injectable({
    providedIn: 'root'
})
export class AgencyService {
    agencySubject = new BehaviorSubject<IAgency>(null);
    updateAgencySubject = new Subject<number>();
    carouselAutoPlaySubject = new Subject<boolean>();
    screenBreakpointsSubject = new Subject();
    updateBasketSubject = new Subject<BasketActions>();

    constructor(private http: HttpClient) {
    }

    static getHostBack(): string {
        return environment.microsites;
    }

    static getHostBackManagement(): string {
        return environment.management;
    }

    static setAgencyData(data): void {
        localStorage.setItem('agency', JSON.stringify(data));
    }

    static getAgencySession() {
        return JSON.parse(localStorage.getItem('agency'));
    }

    private getPath(action, id = null) {
        const host = AgencyService.getHostBack();

        let list = [`${host}`, 'api/v1', action];
        if (id) {
            list = [...list, id];
        }
        return list.join('/').concat('/');
    }

    // get() {
    //     const path = this.getPath('agency/agency', 1);
    //     return this.http.get<IAgency>(path);
    // }

    patch(id: number, body: any) {
        const path = this.getPath('agency/agency', id);
        return this.http.patch(path, body);
    }

    getBySlug(slug?: string, url?: string): Observable<IAgency> {
        const queryParams = slug ? {slug} : {url};
        const path = AgencyService.getHostBack() + '/api/v1/agency/agency/agency-tecnoturis/' + objectToQuerystring(queryParams);

        return this.http.get<IAgency>(path).pipe(tap(data => {
            AuthenticationService.setToken(undefined);
            AgencyService.setAgencyData(data);
            this.agencySubject.next(data);
        }));
    }

    getAgenciesByUser(user: number): Observable<IAgency[]> {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/agency-by-user/${user}/`;
        return this.http.get<IAgency[]>(path);
    }

    getAgenciesByChain(chain: number): Observable<IAgency[]> {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/agency-by-chain/${chain}/`;
        return this.http.get<IAgency[]>(path);
    }

    // getAgenciesByAgency(agency: number): Observable<IAgency[]> {
    //     const path = AgencyService.getHostBack() + `/api/v1/agency/agency/agency-by-agency/${agency}/`;
    //     return this.http.get<IAgency[]>(path);
    // }

    getAgencyByAdmin(): Observable<IAgency> {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/agency-by-admin/`;
        return this.http.get<IAgency>(path);
    }

    deleteImageSlideCarousel(id: number) {
        const path = this.getPath(`agency/agency-carousel`, id);
        return this.http.delete(path);
    }

    getGeneralConditions(id: number): Observable<any> {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/${id}/general-conditions/`;
        return this.http.get<any>(path);
    }

    saveRedsysOrder(data): Observable<any> {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/save-order-redsys/`;
        return this.http.post(path, data);
    }

    getOrdersByClient(agencyId: number, clientId: number) {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/${agencyId}/client/${clientId}/orders/`;
        return this.http.get<any[]>(path);
    }

    getDocumentsByClient(agencyId: number, clientId: number) {
        const path = AgencyService.getHostBack() + `/api/v1/agency/agency/${agencyId}/client/${clientId}/documents/`;
        return this.http.get<any[]>(path);
    }

    saveRedsysPaymentResponse(body: any) {
        // const path = AgencyService.getHostBack() + `/api/v1/agency/agency/save-redsys-response/`;
        const path = AgencyService.getHostBackManagement() + `/redsys/payment-response/`;
        return this.http.post<boolean>(path, body);
    }
}
