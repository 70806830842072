export class Client {
    email?: string;
    document: string;
    id: number;
    name: string;
    surname: string;
    nationality: string;
    phoneNumber: string;
    address?: string;
    agency: number;
    agency_chain: number;
    birthDate?: string;
    city?: string;
    final_name: string;
    createdAt: string;
    updatedAt: string;
}
export class ClientReservation {
    email?: string;
    document: string;
    id: number;
    name: string;
    surname: string;
    surname2?: string;
    nationality: string;
    phoneNumber: string;
    address?: string;
    agency: number;
    agency_chain: number;
    birthDate?: string;
    city?: string;
    final_name: string;
    crmNo?: string;
    createdAt: string;
    updatedAt: string;
}
