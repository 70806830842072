import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CalendarEvent, CalendarView } from "angular-calendar";
import * as moment_ from "moment";
import { AngularCalendarComponent } from '../../../angular-calendar/index';
import {CONFIG_DEFAULT_CAROUSEL} from "../../../const";

const moment = moment_;
const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    }
};

export enum SeasonTypeEnum {
    SUPER_LOW = 'SUPERVALUE',
    LOW = 'VALUE',
    MODERATE = 'MODERATE',
    REGULAR = 'REGULAR',
    HIGH = 'HIGH',
}
export enum ProviderEnum {
    AVORIS = 'avoris',
    SOLTOUR = 'soltour',
    NEWBLUE = 'newblue',
    TOURMUNDIAL = 'tourmundial'
}

@Component({
    selector: 'lib-calendar-packages',
    templateUrl: './calendar-packages.component.html',
    styleUrls: ['./calendar-packages.component.scss']
})
export class CalendarPackagesComponent implements OnInit {

    @ViewChild(AngularCalendarComponent, { static: false }) angularCalendarComponent: AngularCalendarComponent;
    @Input() dataEvent: any[];
    @Input() isPackageDisney = false;
    @Input() set providers(providers: string[]) {
        this.includeAvoris = providers.includes(ProviderEnum.AVORIS);
        this.includeSoltour = providers.includes(ProviderEnum.SOLTOUR);
        this.includeNewblue = providers.includes(ProviderEnum.NEWBLUE);
        this._providers = providers;
        this.includeTourmundial = providers.includes(ProviderEnum.TOURMUNDIAL);
    }

    get providers() {
        return this._providers;
    }

    @Input() showProviders = true;

    @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();

    date : Date
    date1 : Date
    viewDate: Date;
    viewDate2: Date;
    view: CalendarView = CalendarView.Month;
    events: CalendarEvent[] = [];
    locale = 'es';
    currentIndex = 0;
    dataEventCarousel: any[] = [];
    optionsCarousel = CONFIG_DEFAULT_CAROUSEL;
    includeAvoris: boolean;
    includeSoltour: boolean;
    includeNewblue: boolean;
    includeTourmundial: boolean;
    _providers;

    constructor(
        public dialogRef: MatDialogRef<CalendarPackagesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.date = new Date()
        this.date1 = new Date()
        this.viewDate = this.date
        this.viewDate2 = new Date(this.date1.setMonth(this.date1.getMonth() + 1))
        this.optionsCarousel.breakpoint = [
            {
                width: 1024,
                number: 1
            },
            {
                width: 1024,
                number: 2
            },
            {
                width: 1024,
                number: 3
            },
        ];
    }

    ngOnInit(): void {
        this.events = this.convertDataEvent(this.dataEvent)
        this.dataEventCarousel = this.convertDataCarousel(this.dataEvent)
        this.events.sort((a, b) => {
            const fechaA = new Date(a.start);
            const fechaB = new Date(b.start);
            return fechaA.getTime() - fechaB.getTime();
        });
        this.date = new Date(this.events[0].start)
        this.date1 = new Date(this.events[0].start)
        this.viewDate = new Date(this.date.setMonth(this.date.getMonth()))
        this.viewDate2 = new Date(this.date1.setMonth(this.date1.getMonth() + 1))
    
        moment.updateLocale("en", {
            week: {
                dow: 1,
                doy: 0,
            },
        })
    }

    convertDataCarousel(data): any[] {
        const arrayMonths = [];
        const event = {};
        data.forEach(e => {
            const dateEvent = moment(e.fromDate).locale(this.locale);
            if (!event[dateEvent.format('MMMM')]) {
                event[dateEvent.format('MMMM')] = [
                    {
                        date: e.fromDate,
                        price: parseInt(e.referencePricePerPassenger, 0)
                    }
                ];
            } else {
                event[dateEvent.format('MMMM')].push({date: e.fromDate, price: parseInt(e.referencePricePerPassenger, 0)});
            }
        });

        Object.keys(event).forEach(key => {
            const maxNumber = event[key][0].price;
            const month: any = {};
            event[key].forEach((val, index) => {
                if (event[key][index].price <= maxNumber) {
                    month.description = key;
                    month.year = moment(val.date).format('YYYY');
                    month.price = val.price;
                    month.date = val.date;
                }
            });
            arrayMonths.push(month);
        });

        return arrayMonths;
    }

    convertDataEvent(data: any[]): CalendarEvent[] {
        const arrayEvents: CalendarEvent[] = [];
        data.forEach(e => {
            const event: any = {
                start: moment(e.fromDate, 'YYYY-MM-DD').toDate(),
                title: 'A long event that spans 2 months',
                color: colors.yellow,
                allDay: true,
                price: parseInt(e.referencePricePerPassenger, 0),
                productID: e.productIdNewblue,
                directFligth: e.directFlight,
                productIdTourMundial: e.productIdTourMundial,
                providerFlight: e.providerFlight,
                providers: e.providers,
                colorByDay: this.getColorByDay(e),
            };
            arrayEvents.push(event);
        });
        return arrayEvents;
    }

    emitEventDate(event): void {
        this.emitEvent.emit(event);
        this.dialogRef.close();
    }

    eventDate(event, sum: boolean): void {
        this.viewDate = sum ? new Date(this.viewDate.setMonth(this.viewDate.getMonth() + 1)) :
            new Date(this.viewDate.setMonth(this.viewDate.getMonth() - 1));
        this.viewDate2 = sum ? new Date(this.viewDate2.setMonth(this.viewDate2.getMonth() + 1)) :
            new Date(this.viewDate2.setMonth(this.viewDate2.getMonth() - 1));
    }

    eventItem(event) {
        const units: moment_.unitOfTime.DurationConstructor = 'month';
        const date1 = new Date(moment(event.date).format('L'));
        const date2 = new Date(moment(event.date).add(1, units).format('L'));
        this.viewDate = date1;
        this.viewDate2 = date2;
        this.angularCalendarComponent.refresh.next();
    }

    validateShowCarousel(events: any[]): boolean {
        return events.every(event => event.price > 0) || false;
    }

    getColorByDay(day): string {
        const color = this.isPackageDisney ? this.getColorBySeason(day.season) : this.getColorByProvider(day.providers);

        return color;
    }

    getColorBySeason(season: string): string {
        switch (season) {
            case SeasonTypeEnum.SUPER_LOW:
                return 'back-color-super-low';
            case SeasonTypeEnum.LOW:
                return 'back-color-low';
            case SeasonTypeEnum.MODERATE:
                return 'back-color-half';
            case SeasonTypeEnum.REGULAR:
                return 'back-color-moderate';
            case SeasonTypeEnum.HIGH:
                return 'back-color-high';
            default:
                return 'back-color-white';
        }
    }

    getColorByProvider(providers: string[]): string {
        if (providers.length > 1) { return 'multiple-color'; }
        if (providers.includes(ProviderEnum.AVORIS)) { return 'avoris-color'; }
        if (providers.includes(ProviderEnum.SOLTOUR)) { return 'soltour-color'; }
        if (providers.includes(ProviderEnum.NEWBLUE)) { return 'newblue-color'; }
        if (providers.includes(ProviderEnum.TOURMUNDIAL)) { return 'tourmundial-color'; }
    }

}
