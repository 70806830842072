import { OnInit, OnDestroy } from '@angular/core';
import { CarAvailabilityResDto, CocheSearchDto, SupplierInfoDetail } from '../lib-shared/interfaces/index';
import { IntegrationPageMetaData, IntegrationsService, TransportProviderService } from '../lib-shared/services/index';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CarAvailabilityReq, CarFilterReq } from '../lib-shared/interfaces/cars/availability.req.model';
import { PageEvent } from '@angular/material';
import { safeNumber } from '../lib-shared/functions/index';

export class TransportSearchList implements OnInit, OnDestroy {

    PAGE_START = 0;
    PAGE_SIZE = 5;

    inputFields: CocheSearchDto = new CocheSearchDto();
    mandatoryQueryParameters = [
        'PickupLocationID',
        'DropoffLocationID',
        'PickupDate',
        'DropoffDate',
        'PickupTime',
        'DropoffTime',
        'ProductID',
        'PickupLocationName',
        'PickupLocationCountry',
        'DropoffLocationName',
        'DropoffLocationCountry',
    ];
    supplierInfo: SupplierInfoDetail[];
    resAvailability: CarAvailabilityResDto;
    requestId: string;
    totalItems: number;
    minPrice: number;
    maxPrice: number;
    carFilter: CarFilterReq;
    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    productID: number;
    searchShowHide = true;
    filtersShowHide = false;
    integrationMetaData: IntegrationPageMetaData;

    private unsubscribe = new Subject();

    constructor(
        public route: ActivatedRoute,
        public transportService: TransportProviderService,
        public integrationService: IntegrationsService,
    ) {
        this.integrationMetaData = {category: 'Transportes', name: 'transportes'};
        this.integrationService.setMetaData(this.integrationMetaData);
    }

    ngOnInit(): void {
        this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
            this.requestId = null;
            if (this.isValidQueryString(params)) {
                this.productID = safeNumber(params.ProductID);
                this.inputFields = CocheSearchDto.buildFromQueryParams(params);
                this.initializeCarList();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    toggleSearcher(elementName: string): void {

        if (elementName === 'search') {
            this.searchShowHide = !this.searchShowHide;
            this.filtersShowHide = false;
            return;
        }

        if (elementName === 'filters') {
            this.filtersShowHide = !this.filtersShowHide;
            this.searchShowHide = false;
            return;
        }
    }

    changePage(event: PageEvent, container): void {
        container.el.scrollIntoView({block: "start", behavior: "smooth"});
        this.setPagination(event.pageIndex, event.pageSize);
        this.paginate(event.pageIndex + 1, event.pageSize);
    }


    handleFilter(carFilter: CarFilterReq): void {
        this.carFilter = carFilter;
        this.setPagination(0, 5);
        const request = CarAvailabilityReq.buildFromURLParams(
            this.inputFields,
            this.currentPageNumber + 1,
            this.currentPageSize,
            this.carFilter,
            this.requestId
        );
        this.listCars(request);
    }

    private initializeCarList(): void {
        const request = CarAvailabilityReq.buildFromURLParams(
            this.inputFields,
            this.currentPageNumber + 1,
            this.currentPageSize,
            this.carFilter,
            this.requestId
        );

        this.listCars(request);
    }

    private listCars(request: CarAvailabilityReq): void {
        this.transportService.listCars(request)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
            if (response.status === 200 && response.data) {
                const { SupplierInfo, Count, requestId, minPrice, maxPrice } = response.data;
                this.resAvailability = response;
                this.supplierInfo = SupplierInfo;
                this.totalItems = Count || 0;
                this.integrationService.setMetaData({...this.integrationMetaData, searchCount: this.totalItems});
                this.minPrice = minPrice;
                this.maxPrice = maxPrice;
                this.requestId = requestId;
            } else {
                this.totalItems = 0;
            }
        });
    }

    private setPagination(pageNumber: number, pageSize: number): void {
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
    }

    private paginate(page: number, pageSize: number): void {
        const request = CarAvailabilityReq.buildFromURLParams(
            this.inputFields,
            page,
            pageSize,
            this.carFilter,
            this.requestId
        );

        this.listCars(request);
    }


    private isValidQueryString(query: Params): boolean {
        return this.mandatoryQueryParameters.every(element => {
            return query.hasOwnProperty(element);
        });
    }
}
