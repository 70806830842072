import { Pipe, PipeTransform } from '@angular/core';
import { Field, FieldType, Setup } from '../lib-shared/interfaces/index';

@Pipe({
    name: 'getNameFromField'
})
export class GetNameFromFieldPipe implements PipeTransform {

    transform(value: string, fields: Field[], setup?: Setup): any {
        let field: Field = null;
        fields.forEach(f => {
            if (f.isGroup) {
                f.fieldChilds.forEach(fc => {
                    if (fc.keyName === value) {
                        field = fc;
                    }
                });
            } else {
                if (f.keyName === value) {
                    field = f;
                }
            }
        });
        if (setup && field.type === FieldType.LIST) {
            if (setup[field.keyName]) {
                const val = setup[field.keyName].find(v => v.id === +value);
                if (val) {
                    return val.name;
                }
            }
        }
        return field.name;
    }

}
