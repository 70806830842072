import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { ShoppingBasketMultidestination } from '../../lib-shared/interfaces/index';

@Component({
  selector: 'lib-multidestination-services-summary',
  templateUrl: './services-summary.component.html',
  styleUrls: ['./services-summary.component.scss']
})
export class MultidestinationServicesSummaryComponent implements OnInit, OnDestroy {

  @Input() shoppingBasketMultidestination: ShoppingBasketMultidestination;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
