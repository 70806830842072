import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlightSearchComponent } from './flight-search.component';
import { FlightSearchContainerComponent } from './flight-search-container/flight-search-container.component';
import { DateRangeModule } from '../../date-range/index';
import { PaxesModule } from '../../paxes/index';
import { MultiflightModule } from '../../multiflight/multiflight.module';
import { LibSharedModule } from '../../lib-shared/index';
import { ResidentsModule } from '../../residents/residents.module';
import { PaxToFlightPaxPipe } from './paxToFlightPax.pipe';
import { IonicModule } from '@ionic/angular';
import { MaterialDatePickerModule } from "../../material-date-picker/material-date-picker.module";

@NgModule({
    declarations: [
        FlightSearchComponent,
        FlightSearchContainerComponent
    ],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatCheckboxModule,
        DateRangeModule,
        PaxesModule,
        MatExpansionModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MultiflightModule,
        MatSelectModule,
        MatDatepickerModule,
        LibSharedModule,
        ResidentsModule,
        IonicModule,
        MaterialDatePickerModule,
    ],
    providers: [
      PaxToFlightPaxPipe,
    ],
    exports: [
        FlightSearchComponent,
        FlightSearchContainerComponent,
    ]
})
export class FlightsSearchModule {
}
