import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IntegrationPageMetaData {
    category?: string;
    name?: string;
    searchCount?: number;
    starts?: string;
    detail?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {
    private dossierSubject: BehaviorSubject<number>;
    private _integrationsMetaData: BehaviorSubject<IntegrationPageMetaData> = new BehaviorSubject<IntegrationPageMetaData>(null);
    protected DOSSIER_KEY_STORAGE = '__DOSSIER_ID';

    constructor() {
        this.dossierSubject = new BehaviorSubject<number>(this.validAndGetDossier());
    }

    get integrationMetaData(): Observable<IntegrationPageMetaData> {
        return this._integrationsMetaData.asObservable();
    }

    setMetaData(metaData: IntegrationPageMetaData) {
        this._integrationsMetaData.next(metaData);
    }

    setDossier(dossier: number): void {
        localStorage.setItem(this.DOSSIER_KEY_STORAGE, `${dossier}`);
        this.dossierSubject.next(this.validAndGetDossier());
    }

    validAndGetDossier(): number {
        const dossier = localStorage.getItem(this.DOSSIER_KEY_STORAGE);
        return dossier ? +dossier : null;
    }

    removeDossier(): void {
        localStorage.removeItem(this.DOSSIER_KEY_STORAGE);
        this.dossierSubject.next(this.validAndGetDossier());
    }
}
