import { NgModule } from '@angular/core';
import { ActivitiesPaxesComponent } from "./activities-paxes.component";
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { LibSharedModule } from '../../../lib-shared/index';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        ActivitiesPaxesComponent,
    ],
    imports: [
        MatButtonModule,
        MatInputModule,
        LibSharedModule,
        IonicModule,
    ],
    exports: [
        ActivitiesPaxesComponent,
    ]
})
export class ActivitiesPaxesModule {

}
