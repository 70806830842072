import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemPackageCardComponent } from './item-package-card/item-package-card.component';
import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatLineModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule
} from '@angular/material';
import { IonicModule } from '@ionic/angular';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LeafletMapModule } from '../shared/leaflet-map/leaflet-map.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlightPackagesComponent } from './flight-packages/flight-packages.component';
import { FlightPackagesSelectionComponent } from './flight-packages-selection/flight-packages-selection.component';
import { ItemFlightPackagesComponent } from './item-flight-packages/item-flight-packages.component';
import { FlightPackagePaxesComponent } from './flight-package-paxes/flight-package-paxes.component';
import { CarouselImagesModule } from '../../carousel-images/index';
import { LibSharedModule } from '../../lib-shared/index';
import {
    ItemPackageExpansionPanelComponent,
} from './item-package-card/item-package-expansion-panel/item-package-expansion-panel.component';
import {
    ItemPackageTicketCardComponent,
} from './item-package-ticket-card/item-package-ticket-card.component';
import {
    ItemPackageCardDetailComponent,
} from './item-package-card-detail/item-package-card-detail.component';
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
import { CarouselModule } from "../../carousel/index";
import { MaterialDatePickerModule } from "../../material-date-picker/material-date-picker.module";
import { AddonsComponent } from './item-package-card-detail/addons/addons.component';
import { DetailServiceComponent } from './item-package-card-detail/detail-service/detail-service.component';
import { DetailRoomComponent} from "./item-package-card-detail/detail-room/detail-room.component";
import { DetailInformationComponent } from './item-package-card-detail/detail-information/detail-information.component';
import { MdePopoverModule } from '@material-extended/mde';
import { PackagePriceSummaryModule } from './package-price-summary/package-price-summary.module';

@NgModule({
    declarations: [
        ItemPackageCardComponent,
        FlightPackagesComponent,
        FlightPackagesSelectionComponent,
        ItemFlightPackagesComponent,
        FlightPackagePaxesComponent,
        ItemPackageExpansionPanelComponent,
        ItemPackageTicketCardComponent,
        ItemPackageCardDetailComponent,
        AddonsComponent,
        DetailServiceComponent,
        DetailRoomComponent,
        DetailInformationComponent,
    ],
    imports: [
        MatCardModule,
        MatIconModule,
        MatLineModule,
        MatExpansionModule,
        MatTableModule,
        LeafletMapModule,
        MatButtonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatRadioModule,
        CarouselImagesModule,
        LibSharedModule,
        MatTabsModule,
        MatGridListModule,
        CarouselModule,
        MatCheckboxModule,
        MaterialDatePickerModule,
        MdePopoverModule,
        PackagePriceSummaryModule,
    ],
    exports: [
        ItemPackageCardComponent,
        FlightPackagesComponent,
        FlightPackagesSelectionComponent,
        FlightPackagePaxesComponent,
        ItemPackageTicketCardComponent,
        ItemPackageCardDetailComponent
    ],
    entryComponents: [
        FlightPackagesSelectionComponent
    ],
    providers: [
        TranslateService,
        {provide: MAT_DATE_LOCALE, useValue: MAT_DATE_FORMATS}
    ]
})
export class PackagesModule {
}
