import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { CarCommission } from '../../../lib-shared/index';

@Component({
    selector: 'lib-transports-price-summary',
    templateUrl: './transports-price-summary.component.html',
    styleUrls: ['./transports-price-summary.component.scss']
})
export class TransportsPriceSummaryComponent implements OnInit, OnDestroy {

    @Input() commission: CarCommission;
    @Input() commissionableRate: number;
    @Input() nonCommissionableRate: number;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

}
