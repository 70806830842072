import { Component, OnInit } from '@angular/core';
import { ferryForm } from './ferry-booking.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-ferry-booking',
    templateUrl: './ferry-booking.component.html',
    styleUrls: ['./ferry-booking.component.scss']
})
export class FerryBookingComponent implements OnInit {
    ferryCrud = ferryForm;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.ferryForm) {
            this.ferryCrud = this.tecnoturisService.config.forms.ferryForm;
        }
    }

    ngOnInit() {
    }

}
