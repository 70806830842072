import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subject } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { map, takeUntil, tap } from "rxjs/operators";
import * as moment_ from "moment";
import { MatDialog } from "@angular/material";
import { PackagesProviderService } from "../../../lib-shared/services/packages-provider.service";
import { PackageSearchDto } from "../package-search.component";
import {
  numberNigths,
  packageOptionsVal,
  packageOrigins,
  radioGroupDisney,
} from "../../../../mock/packages";
import { NotificationPopupService } from "../../../lib-shared/services/notification-popup.service";
import { AirportService } from "../../../lib-shared/services/index";
import Pax from "../../../paxes/Pax";
import { CalendarPackagesComponent } from "../calendar-packages/calendar-packages.component";
import { PackageNameEnum } from "../../../../mock/packages";
import {
  DestinationRequest,
  ReferencePricesRequest,
} from "../../../lib-shared/interfaces/index";

const moment = moment_;

interface Night {
  value: number;
  name: string;
}

@Component({
  selector: "lib-filter-packages-search",
  templateUrl: "./filter-packages-search.component.html",
  styleUrls: ["./filter-packages-search.component.scss"],
  providers: [PackagesProviderService],
})
export class FilterPackagesSearchComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() inputFields: any;
  @Input() packageName: PackageNameEnum;
  @Input() isSmall = false;
  @Input() customize = false;
  @Input() currentTab: number;
  @Input() microsite;
  @Input() showProviders = true;

  @Output() eventForm = new EventEmitter<any>();
  @Output() queryParams: EventEmitter<any> = new EventEmitter<any>();

  packageNameEnum = PackageNameEnum;
  filteredOrigin: any;
  originCtrl: FormControl;
  destinationOptions: any[];
  originOptions: any[];
  numberNigths: Night[];
  packageListReq: PackageSearchDto;
  form: FormGroup;
  paxes: any = [];
  pax: Pax;
  destinations: any[];
  radioGroupDisney = radioGroupDisney;

  private unsusbcribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private notificationPopupService: NotificationPopupService,
    private packageService: PackagesProviderService,
    private airportService: AirportService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.changeValues(), 1000);
  }

  ngOnInit() {
    this.originOptions = packageOrigins;

    this.numberNigths =
      this.packageName === PackageNameEnum.ISLAND
        ? numberNigths
        : numberNigths.filter((n) => n.value > 6);

    this.paxes.push(new Pax(1, 2, []));

    this.form = this.formBuilder.group({
      origin: ["", Validators.required],
      destination: ["", Validators.required],
      nights: [7, Validators.required],
      checkIn: ["", Validators.nullValidator],
      distribution: [this.paxes, Validators.required],
      options: [],
      productID: ["", Validators.nullValidator],
      providers: ["", Validators.nullValidator],
      productIdTourMundial: ["", Validators.nullValidator],
    });

    this.filteredOrigin = [
      {
        city: "Madrid",
        code_city: null,
        continent: "EU",
        country: "España",
        country_code: "ES",
        iata: "MAD",
        icao: "LEMD",
        is_city: false,
        latitude: 40.471926,
        longitude: -3.56264,
        name: "Adolfo Suárez Madrid–Barajas Aeropuerto",
        priority: 0,
      },
      {
        city: "Barcelona",
        code_city: null,
        continent: "EU",
        country: "España",
        country_code: "ES",
        iata: "BCN",
        icao: "LEBL",
        is_city: false,
        latitude: 41.2971,
        longitude: 2.07846,
        name: "Barcelona International Aeropuerto",
        priority: 0,
      },
      {
        city: "Lisboa",
        code_city: null,
        continent: "EU",
        country: "Portugal",
        country_code: "PT",
        iata: "LIS",
        icao: "LPPT",
        is_city: false,
        latitude: 38.7813,
        longitude: -9.13592,
        name: "Humberto Delgado Aeropuerto (Lisboa Portela Aeropuerto)",
        priority: 0,
      } /* ,{
            city: "Porto",
            code_city: "OPO",
            continent: "EU",
            country: "Portugal",
            country_code: "PT",
            iata: "OPO",
            icao: "LPPR",
            is_city: true,
            latitude: 41.2481002808,
            longitude: -8.68138980865,
            name: "Francisco de Sá Carneiro Airport",
            priority: 0
        },{
            city: "Porto Santo",
            code_city: "OPO",
            continent: "EU",
            country: "Portugal",
            country_code: "PT",
            iata: "PXO",
            icao: "LPPS",
            is_city: false,
            latitude: 33.0733985901,
            longitude: -16.3500003815,
            name: "Porto Santo Airport",
            priority: 0
        } */,
    ];

    if (this.inputFields) {
      this.initValues(this.inputFields);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentTab && changes.currentTab.currentValue) {
      const tab = packageOptionsVal.find(
        (el) => el.id === changes.currentTab.currentValue
      );
      const tabName = tab.name;
      if (tabName === this.packageName && !this.destinations) {
        this.getDestinationAutocompleteData();
      }
    }
  }

  filterCities(city: string) {
    return this.airportService.search(city);
  }

  onEnterOrigin(evt: any, reqIATA) {
    if (evt.source.selected) {
      const newValueForm = this.form.value;
      newValueForm.origin = this.autoCompleteDisplayFn(reqIATA);
      this.form.setValue(newValueForm);
      this.form.controls["origin"].setValue(reqIATA);
    }
  }

  initValues(keyFields: any) {
    Object.keys(keyFields).forEach((key) => {
      if (
        Object.keys(this.form.getRawValue()).some((keyForm) => keyForm === key)
      ) {
        this.form
          .get(key)
          .setValue(key === "nights" ? +keyFields[key] : keyFields[key]);
      }
    });

    this.paxes = [];
    keyFields["distribution"].forEach((d) => {
      this.paxes.push(new Pax(1, d.adults, d.children));
    });
  }

  changeValues() {
    this.form
      .get("origin")
      .valueChanges.pipe(takeUntil(this.unsusbcribe$))
      .subscribe((res) => this.resetKeyForm("checkIn"));
    this.form
      .get("destination")
      .valueChanges.pipe(takeUntil(this.unsusbcribe$))
      .subscribe((res) => this.resetKeyForm("checkIn"));
    this.form
      .get("nights")
      .valueChanges.pipe(takeUntil(this.unsusbcribe$))
      .subscribe((res) => this.resetKeyForm("checkIn"));
  }

  getDestinationAutocompleteData() {
    const request = this.buildDestinationRequest();

    this.packageService
      .getDestinations(this.packageName, request)
      .pipe(
        takeUntil(this.unsusbcribe$),
        map((res) => (res.rows ? res.rows : res.data))
      )
      .subscribe((res) => {
        this.destinations = res;
        if (this.packageName === "Disney") {
          this.form.get("destination").setValue(this.destinations[0].code);
          this.form.get("destination").disable();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsusbcribe$.next();
    this.unsusbcribe$.complete();
  }

  selectionEvent(key: string, value): void {
    this.form.get(key).setValue(value);
  }
  selectionIDEvent(key: string, value): void {
    const id = value[0].productID;
    this.form.get(key).setValue(id);
  }
  selectionIdTourmundialEvent(key: string, value): void {
    const id = value[0].productIdTourMundial;
    this.form.get(key).setValue(id);
  }
  selectionProviders(key: string, value): void {
    const id = value[0].providers;
    this.form.get(key).setValue(id);
  }

  openCalendar(): void {
    if (this.form.invalid) {
      return;
    }

    const request = this.buildReferencePriceRequest();
    let providers: string[] = [];

    this.packageService
      .getReferencePrices(request)
      .pipe(
        tap((response) => (providers = response.data.providers)),
        map((response) => {
          return PackagesProviderService.generateResponseCalendar(
            this.packageName,
            response.data.rows
          );
        }),
        takeUntil(this.unsusbcribe$)
      )
      .subscribe((events) => {
        return this.displayDialogCalendar(providers, events);
      });
  }

  displayDialogCalendar(providers: string[], events?: any) {
    const dialogRef = this.dialog.open(CalendarPackagesComponent, {
      width: "75em",
      height: "45em",
    });

    dialogRef.componentInstance.dataEvent = events;
    dialogRef.componentInstance.providers = providers;
    dialogRef.componentInstance.showProviders = this.showProviders;

    dialogRef.componentInstance.emitEvent
      .pipe(takeUntil(this.unsusbcribe$))
      .subscribe((res) => {
        this.selectionEvent("checkIn", moment(res.date).format("DD-MM-YYYY"));
        this.selectionIdTourmundialEvent("productIdTourMundial", res.events);
        this.selectionIDEvent("productID", res.events);
        this.selectionProviders("providers", res.events);
        setTimeout(() => {
          this.search();
        }, 500);
      });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  search() {
    if (this.form.valid) {
      this.eventForm.emit(this.form.getRawValue());
    } else {
      return this.notificationPopupService.openPopup(
        "Por favor debe ingresar todos los campos obligatorios"
      );
    }
  }

  onPaxChanged(pax: any) {
    this.paxes[pax.position] = pax.newPax;
    this.form.get("distribution").setValue(this.paxes);
  }

  onRemovePax(position) {
    const newPaxes = [];
    this.paxes.forEach((pax: Pax, index) => {
      if (index !== position) {
        newPaxes.push(pax);
      }
    });

    this.paxes = newPaxes;
    this.form.get("distribution").setValue(newPaxes);
  }

  addPax() {
    this.paxes.push(new Pax(1, 2, []));
  }

  resetKeyForm(key) {
    this.form.get(key).reset();
  }

  autoCompleteDisplayFn(option) {
    if (option == null || undefined) {
      return "";
    }
    if (option !== "" || null || undefined) {
      return option.name + " | " + option.city + " | " + option.country;
    }
  }

  private buildDestinationRequest(): DestinationRequest {
    const request = new DestinationRequest();
    request.destinationType =
      this.packageService.transformPackageNameToDestinationType(
        this.packageName
      );
    return request;
  }

  private buildReferencePriceRequest(): ReferencePricesRequest {
    const request = new ReferencePricesRequest();
    request.originCode = this.form.get("origin").value.iata;
    request.destinationCode = this.form.get("destination").value;
    request.numberOfNights = this.form.get("nights").value;
    request.distribution = JSON.stringify(this.form.get("distribution").value);
    request.destinationType =
      this.packageService.transformPackageNameToDestinationType(
        this.packageName
      );

    return request;
  }
}
