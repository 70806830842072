export class AppSettings {
    public static DATE_FORMATS = {
        FULL_DATE: 'YYYY-MM-DD',
        TIME: 'H:mm',
        TIME_24: 'HH:mm',
        FULL_TIME: 'HH:mm:ss',
        FULL_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
        FULL_DATE_TIME_ISO: 'YYYY-MM-DDTHH:mm:ss',
        FULL_DATE_TIME_ISO_MLS: 'YYYY-MM-DD HH:mm:ss.SSS',
        FULL_DATE_TIME_ISO_FULL: 'YYYY-MM-DDTHH:mm:ss.SSSZ'
    };
    public static DAYS = [
        {weekDay: 'Lunes', id: 1},
        {weekDay: 'Martes', id: 2},
        {weekDay: 'Miércoles', id: 3},
        {weekDay: 'Jueves', id: 4},
        {weekDay: 'Viernes', id: 5},
        {weekDay: 'Sábado', id: 6},
        {weekDay: 'Domingo', id: 7}
    ];
    public static EVENT_COLORS = [
        {
            state: 0,
            color: {
                primary: '#fffbe9',
                secondary: '#d8d8d8'
            }
        },
        {
            state: 1, /*Realizado*/
            color: {
                primary: '#4CAF50',
                secondary: '#d8d8d8'
            }
        },
        {
            state: 2, /*Pendiente*/
            color: {
                primary: '#ffffff',
                secondary: '#d8d8d8'
            }
        },
        {
            state: 3, /*Cancelado*/
            color: {
                primary: '#c7c7c7',
                secondary: '#d8d8d8'
            }
        },
        {
            state: 4, /*Cancelado*/
            color: {
                primary: '#ffdf68',
                secondary: '#d8d8d8'
            }
        }
    ];
    public static CALENDAR_EVENT_TYPE = [
        {
            type: 4,
            name: 'Cita',
            icon: 'event',
            color: '#ef119f'
        },
        {
            type: 3,
            name: 'Reunión',
            icon: 'meeting_room',
            color: '#1960ab'
        },
        {
            type: 2,
            name: 'LLamada',
            icon: 'call',
            color: ''
        },
        {
            type: 1,
            name: 'Email',
            icon: 'email',
            color: ''
        }
    ];
}
