import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule
} from '@angular/material';
import { LibSharedModule } from '../../lib-shared/index';
import { HotelSearchComponent } from './hotel-search.component';
import { DateRangeModule } from '../../date-range/index';
import { PaxesHotelModule } from './paxes-hotel/paxes-hotel.module';

@NgModule({
    declarations: [HotelSearchComponent],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        MatOptionModule,
        DateRangeModule,
        MatSelectModule,
        MatExpansionModule,
        PaxesHotelModule,
        LibSharedModule
    ],
    exports: [HotelSearchComponent]
})
export class HotelsSearchModule {
}
