import { NgModule } from '@angular/core';
import { MessagesComponent } from './messages.component';
import { CommonModule } from '@angular/common';
import { LibSharedModule } from '../lib-shared/index';

@NgModule({
    declarations: [
        MessagesComponent
    ],
    imports: [
        CommonModule,
        LibSharedModule
    ],
    exports: [
        MessagesComponent
    ]
})
export class MessagesModule {
}
