import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';

import { 
    BookingRequestTransfer, 
    TransferRequestDto,
    TransferDtoRes,
    TransferLocationDtoRes, 
    TransferLocation, 
    PreBookingTransferRequestDto, 
    PreBookingResponseTransferDto, 
    BookingResponseDto, 
    ShoppingBasketTransfer, 
    CancelTransferResponseDto,
    CancelTransferReq
    } from "../interfaces";

import { TecnoturisService } from '../../tecnoturis.service';

const _token = localStorage.getItem("__token") 

interface Header {
    "Authorization": string,
    "Agency"?: Agency
}

interface Agency {
    id: number,
    name: string,
    agency_chain_id: number
}

@Injectable({
    providedIn: "root"
})
export class TransferService {

    basketList;
    private management;
    constructor(
        public http: HttpClient,
        private tecnoturisService: TecnoturisService,
    ) {
        this.management = `${this.tecnoturisService.config.management}`;
    }
  
    private getPath(action: string): string {
        const host = this.tecnoturisService.config.transfers;
        let list: any = [`${host}`, action];
        return list.join("/");
    }

    public getLocation(request: TransferLocation): Observable<TransferLocationDtoRes> {
        let params = new HttpParams();
        Object.keys(request).forEach(key => {
            params = params.append(key, request[key]);
        });
        const path = this.getPath(`destinations`);      
        return this.http.get<TransferLocationDtoRes>(path, { params })
    }


    public getTransfers(request: TransferRequestDto): Observable<TransferDtoRes>{
        let params = new HttpParams();
        let headers = new HttpHeaders();
        Object.keys(request).forEach(key => {
            if(request[key] != ""){
                params = params.append(key, request[key]);
            }
        });
        const path = this.getPath("transfers")
        return this.http.get<TransferDtoRes>(path, { 
            params: params,
        })
    }

    public preBook(request: PreBookingTransferRequestDto): Observable<PreBookingResponseTransferDto> {
        const path = this.getPath("api/v1/select/transferSelect")
        return this.http.post<PreBookingResponseTransferDto>(path, request);
    }

    public bookTransfer(request: BookingRequestTransfer, saveBudget = false): Observable<BookingResponseDto> {

        const path = this.getPath("api/v1/booking")
        let headers = new HttpHeaders();
        let setHeaders: Header = {
            "Authorization": `Bearer ${_token}`
        }
        Object.keys(setHeaders).forEach(key => {
            headers = headers.append(key, setHeaders[key])
        }) 
        return this.http.post<BookingResponseDto>(path, request)
            .pipe(tap(async response => {
                if(saveBudget){
                    return response
                }
        }))
    } 

    public bookTransferFromBasket(item: ShoppingBasketTransfer): Promise<any> {
        const bookingRequest =  item.requestFromBasket
        return this.bookTransfer(bookingRequest).toPromise()
                .then(res => {
                    if(res.status == 200) {
                        const { totalPrice, locator, transferServicesBooked }  = res.data;
                        item.totalPrice = totalPrice;
                        item.locator = locator;
                        item.transfer = transferServicesBooked;
                        delete item.requestFromBasket
                        return Promise.resolve(item)
                    }
                    item.failed = true;
                    return Promise.reject(item)
                }).catch(err => {
                    item.failed = true;
                    return Promise.reject(item)
                })

    }

    public cancelBooking(request: CancelTransferReq): Observable<CancelTransferResponseDto>{
        const path = this.getPath("api/v1/cancel")
        return this.http.post<CancelTransferResponseDto>(path, request)
    }

    RemoveDossier(id: any): Observable<any> {
        const url = `${this.management}/booking-service/${id}/`;
        return this.http.delete<any>(url);
    }
       
}
