import { Response } from './response';

export class BookingsDtoRes extends Response {
    data: BookingResponse;
}

export class BookingResponse {
    providerToken: string;
    status: string;
    ticketReference: string;
    error?: string[];
    outwardTickets: Ticket[];
    returnTickets: Ticket[];
    requestToken: string;
    fileId: string;
    trainTicketUrl: string;
    safeTrainTicketUrl: string;
}

export class Ticket {
    arrivalStation: string;
    cabinGroup: string;
    cabinNumber: string;
    cardNumber: string;
    cardType: string;
    classCode: string;
    departureStation: string;
    passengerEmail: string;
    passengerFirstSurname: string;
    passengerName: string;
    passengerPhoneNumber: string;
    passengerSecondSurname: string;
    seatNumber: string;
    seatTicketId: string;
    ticketStatus: string;
    trainNumber: string;
}
