import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import {
    BookReqDto,
    CircuitGetReqDto,
    CircuitGetResDto,
    CircuitListReqDto,
    CircuitListResDto
} from '@vecib2c/frontend-dto';
import { tap } from 'rxjs/operators';
import { ManagementService } from './management.service';
import { ClientServices } from './client.services';
import { TecnoturisService } from '../../tecnoturis.service';
import { IntegrationsService } from "./integrations.service";
import { CircuitBooking } from "../interfaces/circuits/circuit-booking.model";

@Injectable({
    providedIn: 'root',
})
export class CircuitsProviderService {
    language = "ES";

    constructor(private http: HttpClient, private managementService: ManagementService,
                private clientService: ClientServices, private tecnoturisService: TecnoturisService,
                private integrationsService: IntegrationsService) {
    }

    private getPath(action, id = null) {
        const host = this.tecnoturisService.config.circuits;

        let list = [`${host}`, 'api/v1', action];
        if (id) {
            list = [...list, id];
        }
        return list.join("/");
    }

    list(req: CircuitListReqDto): Observable<CircuitListResDto> {
        let params: HttpParams = new HttpParams();

        Object.keys(req).forEach(function (key) {
            const value = key === 'rooms' || typeof req[key] === 'object' ? JSON.stringify(req[key]) : req[key];
            params = params.append(key, value);
        });

        params.append('language', this.language);
        const path = this.getPath('circuits');
        return this.http.get<any>(path, {params: params});
    }

    get(req: CircuitGetReqDto, id: string): Observable<CircuitGetResDto> {
        let params: HttpParams = new HttpParams();
        Object.keys(req).forEach(function (key) {
            params = params.append(key, req[key]);
        });
        const path = this.getPath("circuits", id);
        return this.http.get<any>(path, {params: params});
    }

    cancelBooking(bookingId: number): Observable<any> {
        const path = this.getPath("booking", bookingId);
        return this.http.delete<any>(path);
    }

    book(reqParams: BookReqDto, saveBudget = false): Observable<CircuitBooking> {
        const url = this.getPath("booking");
        return this.http.post<any>(url, reqParams).pipe(tap(async response => {
            if (saveBudget) {
                const client = await this.clientService.getClient();
                const dossier = this.integrationsService.validAndGetDossier();
                const data = {circuitData: [response], client: client.id, dossier: dossier};
                await this.managementService.addServicesToDossier(data).toPromise();
            }
        }));
    }

}
