import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {BookEditComponent} from "../../book-edit.component";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {Location} from "@angular/common";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {filter, map, takeUntil} from "rxjs/operators";
import {ShoppingBasketTrains} from "../../../lib-shared/interfaces/shopping-basket-trains.model";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {t} from 'typy';
import {Subject} from 'rxjs';
import {NotificationPopupService} from '../../../lib-shared/services/notification-popup.service';
import {ClientServices} from '../../../lib-shared/services/client.services';
import {BasketManager} from '../../../lib-shared/services/BasketManager';
import {AgencyChangeService} from '../../../agency-change/agency-change.service';
import {TecnoturisService} from '../../../tecnoturis.service';
import {BookingsCreateRequestDto} from '../../../lib-shared/interfaces/trains/request/bookings-create.request.dto';
import {Client, Passenger, Trains, TrainDocument} from '../../../lib-shared/interfaces/index';
import {AuthenticationService, TrainsProviderService} from '../../../lib-shared/services/index';
import {ROLES} from '../../../const';
import {trainDocuments} from '../../../../mock/trains';

@Component({
    selector: 'lib-trains-book-edit',
    templateUrl: './trains-book-edit.component.html',
    styleUrls: ['./trains-book-edit.component.scss'],
    providers: [
        TrainsProviderService
    ]
})
export class TrainsBookEditComponent extends BookEditComponent implements OnInit, OnDestroy {

    shoppingBasket: ShoppingBasketTrains;
    form: any;
    trainsResponse: Trains;
    confirmedPrivacyPolicy: boolean;
    trainDocuments: TrainDocument[] = trainDocuments;

    private unsubscribe = new Subject<void>();
    auth = AuthenticationService.getUser();

    constructor(
        protected location: Location,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected notification: NotificationPopupService,
        protected translate: TranslateService,
        protected activatedRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly trainsService: TrainsProviderService,
        protected router: Router,
        protected _basketManager: BasketManager,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(
                map(() => window.history.state),
                takeUntil(this.unsubscribe),
            )
            .subscribe(async (state) => {
                if (state.trains) {
                    this.shoppingBasket = state.trains;
                    if (this.shoppingBasket) {
                        this.initForm();
                    }
                } else {
                    const uuid = this.activatedRoute.snapshot.params['id'];
                    const response = await this._basketManager.getList();
                    const train = response.find(item => item.uuid === uuid);
                    this.shoppingBasket = train;
                }

                if (this.shoppingBasket) {
                    this.initForm();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get paxes(): FormArray {
        return <FormArray>this.form.get('paxes');
    }

    addToBasketAndRedirect(): void {
        if (super.checkClient()) {
            const request = this.buildBookingRequest(
                this.shoppingBasket.providerToken,
                this.form,
                this.shoppingBasket.requestToken,
            );

            this.shoppingBasket.paxes = request.passengers;

            this._basketManager.addOrUpdate(this.shoppingBasket)
                .then(() => {
                    const path = this.microsite ? '/home' : '/intranet';
                    this.router.navigateByUrl(path, {replaceUrl: true});
                });
        }
    }

    redirectToConfirmation(): string {
        return this.microsite ? '/bookings/confirmation' : '/integration/bookings/book-confirmation';
    }

    redirectToSummary(): string {
        return this.microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
    }

    goToSummaryOrConfirmation(): void {
        if (!super.checkClient()) {
            return;
        }
        const request = this.buildBookingRequest(
            this.shoppingBasket.providerToken,
            this.form,
            this.shoppingBasket.requestToken,
        );
        this.shoppingBasket.paxes = request.passengers;

        this._basketManager.addOrUpdate(this.shoppingBasket).then(() => {
            this._basketManager.getList().then(basket => {
                if (basket.length === 1 && basket[0].uuid === this.shoppingBasket.uuid) {

                    this.trainsService.bookTrains(request, true)
                        .pipe(
                            map(res => res.data),
                            takeUntil(this.unsubscribe),
                        )
                        .subscribe(async (bookingResponse) => {
                            if (bookingResponse.error) {
                                this.notification.openPopup(bookingResponse.error.join(','));
                                this.location.back();
                            } else {
                                const {ticketReference, fileId, trainTicketUrl, safeTrainTicketUrl} = bookingResponse;
                                this.shoppingBasket.ticketReference = ticketReference;
                                this.shoppingBasket.fileId = fileId;
                                this.shoppingBasket.trainTicketUrl = trainTicketUrl;
                                this.shoppingBasket.safeTrainTicketUrl = safeTrainTicketUrl;

                                const navigationExtras: NavigationExtras = {
                                    state: {
                                        bookingPostResDto: [this.shoppingBasket]
                                    }
                                };

                                const path = this.redirectToConfirmation();
                                this.router.navigate(
                                    [path],
                                    navigationExtras
                                );
                            }
                        });
                } else {
                    if (basket.length) {
                        const path = this.redirectToSummary();
                        this.router.navigateByUrl(path);
                    } else {
                        this.notificationPopupService.openPopup("EMPTY_BASKET");
                    }
                }
            });
        });
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            paxes: this.formBuilder.array([]),
        });

        for (let i = 0; i < this.shoppingBasket.paxes.length; i++) {
            this.addPax(this.shoppingBasket.paxes[i]);
        }
        this.clientService.subscription
            .pipe(
                filter(client => client && !(Object.keys(client).length === 0) && client.isPax))
            .subscribe((client: Client) => {
                if (client && !(Object.keys(client).length === 0)) {
                    const occupants = ((this.form.get('paxes') as FormArray)).at(0);
                    occupants.get('name').setValue(client.name);
                    occupants.get('secondSurname').setValue(client.surname);
                    occupants.get('email').setValue(client.email);
                }
            });
    }

    private addPax(passenger: Passenger): void {
        this.paxes.push(this.formBuilder.group({
            paxType: [t(passenger, 'paxType').safeObject, Validators.required],
            name: [t(passenger, 'name').safeObject, Validators.required],
            firstSurname: [t(passenger, 'firstSurname').safeObject, Validators.required],
            secondSurname: [t(passenger, 'secondSurname').safeObject, Validators.required],
            email: [t(passenger, 'email').safeObject, Validators.required],
            documentType: [t(passenger, 'documentType').safeObject, Validators.required],
            documentNumber: [t(passenger, 'documentNumber').safeObject, Validators.required],
            age: [t(passenger, 'age').safeObject, Validators.required],
            phone: [t(passenger, 'phone').safeObject, Validators.required],
        }));
    }

    private buildBookingRequest(
        providerToken: string,
        form: FormGroup,
        requestToken: string,
    ): BookingsCreateRequestDto {
        const bookingCreateRequestDto = new BookingsCreateRequestDto();
        bookingCreateRequestDto.providerToken = providerToken;

        const rawForm = form.getRawValue();
        bookingCreateRequestDto.passengers = rawForm.paxes.map(pax => {
            return {...pax} as Passenger;
        });

        bookingCreateRequestDto.requestToken = requestToken;

        return bookingCreateRequestDto;

    }

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }
}
