import { Pipe, PipeTransform } from "@angular/core";
import { ShoppingBasketActivity, ShoppingBasketByDestination } from '../../interfaces/index';

@Pipe({
    name: 'fetchActivityShoppingBasketsByDestination'
})
export class FetchActivityShoppingBasketsByDestination implements PipeTransform {

    transform(
        shoppingBasketsByDestination: ShoppingBasketByDestination[],
        destinationIndex: number,
    ): ShoppingBasketActivity[] {
    if (!shoppingBasketsByDestination) { return; }
    const shoppingBaskets = shoppingBasketsByDestination.find(s => s.destinationIndex === destinationIndex);
    if (!shoppingBaskets) { return; }
    return shoppingBaskets.activities;
  }
}
