import { Apollo } from "apollo-angular";
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { FetchPolicy } from "apollo-client";

@Injectable({
    providedIn: "root"
})
export class GraphqlService {
    private apollo: Apollo;

    constructor(private _apollo: Apollo) {
        this.apollo = _apollo;
    }

    public query(query, variables = null, fetchPolicy: FetchPolicy = 'no-cache') {
        return this.apollo.query({
            query: gql`${query}`,
            variables: variables,
            fetchPolicy: fetchPolicy
        });
    }

    public mutation(mutation, variables = null) {
        return this.apollo.mutate({
            mutation: gql`${mutation}`,
            variables: variables
        });
    }
}
