import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'numberOfStars'
})
export class NumberOfStarsPipe implements PipeTransform {

    transform(rawValue: string | number): Number[] {
        const safeRawValue = rawValue + '';

        if (safeRawValue === undefined) {
            return;
        }
        const regexNumber = /\d+/;

        if (!safeRawValue || !regexNumber.test(safeRawValue)) {
            return;
        } else {
            const value = safeRawValue.match(regexNumber)[0];
            const integerValue = Number(value);
            return new Array(integerValue);
        }
    }
}
