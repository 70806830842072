import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { IonicModule } from '@ionic/angular';
import { CircuitsItemCardComponent } from './circuits-item-card/circuits-item-card.component';
import { CircuitsFilterComponent } from './circuits-filter/circuits-filter.component';
import { CircuitsFlightsExpansionPanelModule } from './circuits-flights-expansion-panel/circuits-flights-expansion-panel.module';
import { CircuitsPickDateComponent } from './circuits-pick-date/circuits-pick-date.component';
import { MatRadioModule } from '@angular/material/radio';
import { MdePopoverModule } from '@material-extended/mde';
import { ReactiveFormsModule } from '@angular/forms';
import { LibSharedModule } from '../../lib-shared/index';
import { CircuitsPriceSummaryModule } from './circuits-price-summary/circuits-price-summary.module';

@NgModule({
    declarations: [
        CircuitsItemCardComponent,
        CircuitsFilterComponent,
        CircuitsPickDateComponent
    ],
    imports: [
        MatCardModule,
        IonicModule,
        CircuitsFlightsExpansionPanelModule,
        MatRadioModule,
        ReactiveFormsModule,
        LibSharedModule,
        MdePopoverModule,
        CircuitsPriceSummaryModule,
    ],
    exports: [
        CircuitsItemCardComponent,
        CircuitsFilterComponent,
        CircuitsFlightsExpansionPanelModule,
        CircuitsPickDateComponent
    ]
})
export class CircuitsModule {
}
