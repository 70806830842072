import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-hotel-bond',
    templateUrl: './hotel-bond.component.html',
    styleUrls: ['./hotel-bond.component.scss']
})
export class HotelBondComponent implements OnInit {

    @Input() bond: any;

    constructor() {
    }

    ngOnInit() {
    }

}
