import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material";
import { CarAvailabilityRes, CarFilter, IPriceRangeFilter } from "../../../lib-shared/interfaces/index";
import { CarFilterReq } from '../../../lib-shared/interfaces/cars/availability.req.model';
import { safeNumber } from "../../../lib-shared/functions/index";

@Component({
    selector: 'lib-transports-filter',
    templateUrl: 'transports-filter.component.html',
    styleUrls: ['transports-filter.component.scss']
})

export class TransportsFilter implements OnInit {

    @Input() filters: CarFilter[];
    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Input() lowerUpperPrices: { lower: number, upper: number };

    carFilter: CarFilterReq = {};

    @Output() filter = new EventEmitter<CarFilterReq>();

    constructor() {
    }

    ngOnInit(): void {
    }

    filterByType(
        type: string,
        itemValue: string | number,
        event: MatCheckboxChange,
    ): void {
        this.handleFilterByType(type, itemValue, event.checked);
    }

    filterByPrice(price: IPriceRangeFilter): void {
        const { lower, upper } = price;
        this.carFilter.minPrice = lower;
        this.carFilter.maxPrice = upper;
    }

    private handleFilterByType(
        type: string,
        itemValue: string | number,
        selected: boolean,
    ): void {
        const safeValue = ['seats', 'doors'].includes(type) ? safeNumber(itemValue) : itemValue;
        if (selected) {
            if (!this.carFilter[type]) {
                this.carFilter[type] = [safeValue];
            } else {
                this.carFilter[type] = [...this.carFilter[type], safeValue];
            }
        } else {
            this.carFilter[type] = this.carFilter[type].filter(value => value !== safeValue);
        }
    }
}
