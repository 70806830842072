import {
    DestinationType,
    PackageNameEnum,
} from '../../../../../../mock/packages';
import { Distribution } from '../../../distribution.interface';

export class PackagesRequest {
    origin?: string;
    checkIn: string;
    resetDate?:string;
    nights: number;
    distribution: string;
    page: number;
    pageSize: number;
    destination: string;
    filter: string;
    option: number;
    destinationType: DestinationType;
    options?: any;
    providers?: string[];
    productId?:string;
    productIdTourMundial?: string;
}

export class PackageRequestFromUrl {
    origin: { iata: string, name: string, cityCode: string, country: string };
    destination: string;
    checkIn: string;
    distribution: Distribution[];
    nights: number;
    package: PackageNameEnum;
    options?: any;
    productID?:string;
    providers?: string[];
    productIdTourMundial?: string;
}
