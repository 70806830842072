import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LibSharedModule } from '../lib-shared/index';
import { MatInputModule } from '@angular/material';
import { ResidentsComponent } from './residents.component';

@NgModule({
  declarations: [
    ResidentsComponent,
  ],
  imports: [
    FormsModule,
    IonicModule,
    LibSharedModule,
    MatInputModule,
  ],
  exports: [
    ResidentsComponent,
  ]
})
export class ResidentsModule {

}
