import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { FlightBookingPostReqDto, PrebookReqDto } from "@vecib2c/frontend-dto";
import { tap } from "rxjs/operators";
import { BasketManager, ClientServices, FlightProviderService } from '../../../lib-shared/services/index';
import { ShoppingBasketFlight } from '../../../integrations/hotels/index';

@Injectable()
export class FlightBookServices {
    constructor(
        protected _flightProviderService: FlightProviderService,
        protected _basketManager: BasketManager,
        protected clientService: ClientServices,
        protected router: Router
    ) {

    }

    redirectToConfirmation(microsite = false): string {
        return microsite ? '/bookings/confirmation' : '/integration/bookings/book-confirmation';
    }

    redirectToSummary(microsite = false): string {
        return microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
    }

    goToSummaryOrConfirmation(item: ShoppingBasketFlight, redirect = true, microsite = false): Promise<any> {
        return this._basketManager.getList().then(basket => {
            if (basket.length === 1
                &&
                item.uuid === basket[0].uuid) {
                this.booking(item).then(res => {
                    const navigationExtras: NavigationExtras = {
                        state: {
                            bookingPostResDto: [res]
                        }
                    };
                    if (redirect) {
                        const path = this.redirectToConfirmation(microsite);
                        this.router.navigate(
                            [path],
                            navigationExtras
                        );
                    } else {
                        return Promise.resolve();
                    }
                });
            } else {
                if (basket.length) {
                    if (redirect) {
                        const path = this.redirectToSummary(microsite);
                        this.router.navigateByUrl(path);
                    } else {
                        return Promise.resolve();
                    }
                }
            }
        });
    }

    booking(item: ShoppingBasketFlight, saveBudget = true) {
        const { requestId, priceGroupId, itinerariesId, paxes, cabinBaggage } = item;
        let bookingPromise;

        if (item.clonedData.isLowCost) {
            const { luggageRequestId } = item.lowCostPreBooking;
            bookingPromise = this._flightProviderService.bookLowCost({
                requestId,
                priceGroupId,
                itinerariesId,
                paxes,
                luggageRequestId,
                cabinBaggages: cabinBaggage && [cabinBaggage],
            }, saveBudget)
            .toPromise();
        } else {
            bookingPromise = this._flightProviderService.book({
                requestId,
                priceGroupId,
                itinerariesId,
                paxes,
            }, saveBudget)
            .toPromise();
        }

        return bookingPromise.catch(err => {
            Promise.reject();
            item.failed = true;
            return item;
        });

    }

    cancel(bookingId) {
        return this._flightProviderService.cancelBooking(bookingId)
            .pipe(tap());
    }

    preBookLowCost(parameters: PrebookReqDto) {
        return this._flightProviderService.preBookLowCost(parameters).toPromise();
    }

}
