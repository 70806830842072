import { Pipe, PipeTransform } from "@angular/core";
import { ShopingBasketHotel } from "../../../integrations/hotels/dto/ShopingBasketHotel";
import { ShoppingBasketByDestination, } from '../../interfaces/index';

@Pipe({
    name: 'fetchHotelShoppingBasketsByDestination'
})
export class FetchHotelShoppingBasketsByDestination implements PipeTransform {

    transform(
        shoppingBasketsByDestination: ShoppingBasketByDestination[],
        destinationIndex: number,
    ): ShopingBasketHotel[] {
    if (!shoppingBasketsByDestination) { return; }
    const shoppingBaskets = shoppingBasketsByDestination.find(s => s.destinationIndex === destinationIndex);
    if (!shoppingBaskets) { return; }
    return shoppingBaskets.hotels;
  }
}
