import { NgModule } from "@angular/core";
import { TrainsItemCardComponent } from "./trains-item-card/trains-item-card.component";
import { MatCardModule } from "@angular/material/card";
import { IonicModule } from "@ionic/angular";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { TrainSummaryComponent } from './train-summary/train-summary.component';
import { TrainItinerarySummaryComponent } from './train-itinerary-summary/train-itinerary-summary.component';
import { TrainFilterComponent } from './train-filter/train-filter.component';
import { PriceRangeModule } from '../../price-range/price-range.module';
import { LibSharedModule } from '../../lib-shared/index';
import {HotelsModule} from "../hotels/hotels.module";

@NgModule({
    declarations: [
        TrainsItemCardComponent,
        TrainSummaryComponent,
        TrainItinerarySummaryComponent,
        TrainFilterComponent,
    ],
    imports: [
        MatCardModule,
        IonicModule,
        MatCheckboxModule,
        FormsModule,
        PriceRangeModule,
        LibSharedModule,
        HotelsModule,
    ],
    exports: [
        TrainsItemCardComponent,
        TrainSummaryComponent,
        TrainItinerarySummaryComponent,
        TrainFilterComponent,
    ]
})
export class TrainsIntegrationModule {
}
