import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from "rxjs/operators";
import * as moment_ from "moment";
import { AirportService } from '../../../lib-shared/services/index';
import { TransportProviderService } from '../../../lib-shared/services/transport-provider.service';
import { CocheListReqDto, CocheSearchDto, IFilterCoches } from '../../../lib-shared/interfaces/index';

const moment = moment_;

@Component({
    selector: 'lib-coches-search',
    templateUrl: './coches-search.component.html',
    styleUrls: ['./coches-search.component.scss'],
    providers: [TransportProviderService],
})
export class CochesSearchComponent implements OnInit, OnDestroy {

    @Input() isSmall = false;
    @Input() currentTab: number;
    @Input() set params(params: CocheSearchDto) {
        this._params = params;
        if (params) {
            this.setParamsFromQuery(params);
        }
    }
    @Input() filters: IFilterCoches = {};
    @Input() shouldUpdateRouteURL = true;
    @Input() routerPath = '/integration/transports/coches';
    @Input() set fromDate (fromDate: string) {
        this._fromDate = fromDate;
        if (fromDate) {
            const mappedFromDate = moment(fromDate).toDate();
            this.minPickUpDate = mappedFromDate;
            this.minDropOffDate = mappedFromDate;
        }
      }
    @Input() set toDate (toDate: string) {
        this._toDate = toDate;
        if (toDate) {
            const mappedToDate = moment(toDate).toDate();
            this.maxPickUpDate = mappedToDate;
            this.maxDropOffDate = mappedToDate;
        }
    }

    get params(): CocheSearchDto {
        return this._params;
    }
    get fromDate(): string {
        return this._fromDate;
    }
    get toDate(): string {
        return this._toDate;
    }


    // formulario
    form: FormGroup;
    pickUpLocationCtrl: FormControl;
    dropOffLocationCtrl: FormControl;
    pickUpDateCtrl: FormControl;
    dropOffDateCtrl: FormControl;
    pickUpTimeCtrl: FormControl;
    dropOffTimeCtrl: FormControl;
    driversAgeCtrl: FormControl;

    // filtros Airports
    filterPickup: Observable<any[]>;
    filterDropoff: Observable<any[]>;
    cocheListReqDto: CocheListReqDto;

    date = new Date();
    defaultRouting = '/integration/transports/coches';
    minPickUpDate: Date;
    maxPickUpDate: Date;
    minDropOffDate: Date;
    maxDropOffDate: Date;

    @Output() searchCars = new EventEmitter<CocheSearchDto>();

    private unsubscribe = new Subject();
    private _params: CocheSearchDto;
    private _fromDate: string;
    private _toDate: string;

    constructor(private formBuilder: FormBuilder,
                private airportService: AirportService,
                private transportProviderService: TransportProviderService,
                private router: Router,
                private cdRef: ChangeDetectorRef) {
        this.initForm();
    }

    ngOnInit() {
        this.cocheListReqDto = new CocheListReqDto();
        this.initFilters();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    initForm() {

        this.pickUpLocationCtrl = new FormControl();
        this.dropOffLocationCtrl = new FormControl();
        this.pickUpDateCtrl = new FormControl();
        this.dropOffDateCtrl = new FormControl();
        this.pickUpTimeCtrl = new FormControl();
        this.dropOffTimeCtrl = new FormControl();
        this.driversAgeCtrl = new FormControl();

        this.form = this.formBuilder.group({
            pickupLocation: ['', Validators.required],
            dropoffLocation: ['', Validators.required],
            pickupDate: ['', Validators.required],
            dropoffDate: ['', Validators.required],
            pickupTime: [''],
            dropoffTime: [''],
            driversAge: ['']
        });


        const date = moment().format('DD/MM/YYYY');
        this.form.get('pickupDate').setValue(date);
        this.form.get('dropoffDate').setValue(date);

        const datePickup = moment().format('YYYY/MM/DD');
        this.pickUpDateCtrl.setValue(new Date(datePickup));
        this.dropOffDateCtrl.setValue(new Date(datePickup));

        // const time = moment(this.date).format('HH:mm');
        this.form.get('pickupTime').setValue('08:00');
        this.form.get('dropoffTime').setValue('23:59');

        this.pickUpDateCtrl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            const date = moment(value).format('DD/MM/YYYY');
            this.form.get('pickupDate').setValue(date);
        });
        this.dropOffDateCtrl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            const date = moment(value).format('DD/MM/YYYY');
            this.form.get('dropoffDate').setValue(date);
        });
        this.pickUpTimeCtrl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            this.form.get('pickupTime').setValue(value);
        });
        this.dropOffTimeCtrl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            this.form.get('dropoffTime').setValue(value);
        });
        this.driversAgeCtrl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            this.form.get('driversAge').setValue(value);
        });
    }

    filterLocation(localtion: string) {
        return this.transportProviderService.searchCities(localtion);
    }

    initFilters() {
        this.filterPickup = this.pickUpLocationCtrl.valueChanges.pipe(
            debounceTime(800),
            distinctUntilChanged(),
            filter(value => value ? value.length > 2 : false),
            switchMap(city => {
                return this.filterLocation(city);
            })
        );
        this.filterDropoff = this.dropOffLocationCtrl.valueChanges.pipe(
            debounceTime(800),
            distinctUntilChanged(),
            filter(value => value ? value.length > 2 : false),
            switchMap(city => this.filterLocation(city))
        );
    }

    autoCompleteDisplayFn(option) {
        if (!option) {
            return '';
        }
        if (option && !option.LocationName || !option.LocationCountry) {
            return '';
        }
        return option.LocationName || +' | ' + option.LocationCountry;
    }

    onEnterPickUp(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.pickupLocation = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['pickupLocation'].setValue(reqIATA);
        }
    }

    onEnterDropOff(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.dropoffLocation = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['dropoffLocation'].setValue(reqIATA);
        }
    }

    resetInput(form: FormControl, container, keyForm: string): void {
        form.reset();
        this.form.get(keyForm).reset();
        container.focus();
    }

    buildRequest(): CocheSearchDto {
        return {
            PickupLocationID: this.form.controls['pickupLocation'].value.LocationID,
            DropoffLocationID: this.form.controls['dropoffLocation'].value.LocationID,
            PickupDate: this.form.controls['pickupDate'].value,
            DropoffDate: this.form.controls['dropoffDate'].value,
            PickupTime: this.form.controls['pickupTime'].value,
            DropoffTime: this.form.controls['dropoffTime'].value,
            ProductID: 1,
            PickupLocationName: this.form.controls['pickupLocation'].value.LocationName,
            PickupLocationCountry: this.form.controls['pickupLocation'].value.LocationCountry,
            DropoffLocationName: this.form.controls['dropoffLocation'].value.LocationName,
            DropoffLocationCountry: this.form.controls['dropoffLocation'].value.LocationCountry,
            DriversAge: this.form.controls['driversAge'].value,
        };
    }

    getRouting(): string {
        return this.routerPath ? this.routerPath : this.defaultRouting;
    }

    search(): void {
        if (!this.form.valid) { return; }
        const request = this.buildRequest();
        if (this.shouldUpdateRouteURL) {
            this.router.navigate([this.getRouting()], {
                queryParams: request
            });
        } else {
            this.searchCars.emit(request);
        }
    }

    setParamsFromQuery(params: CocheSearchDto) {
        const {
            PickupLocationID,
            PickupLocationName,
            PickupLocationCountry,
            DropoffLocationID,
            DropoffLocationName,
            DropoffLocationCountry,
            PickupTime,
            DropoffTime,
            PickupDate,
            DropoffDate,
            DriversAge,
        } = params;

        this.form.get('pickupLocation').setValue({
            LocationID: PickupLocationID,
            LocationName: PickupLocationName,
            LocationCountry: PickupLocationCountry,
        });
        this.form.get('dropoffLocation').setValue({
            LocationID: DropoffLocationID,
            LocationName: DropoffLocationName,
            LocationCountry: DropoffLocationCountry
        });
        this.form.get('pickupTime').setValue(PickupTime);
        this.form.get('dropoffTime').setValue(DropoffTime);
        this.form.get('pickupDate').setValue(PickupDate);
        this.form.get('dropoffDate').setValue(DropoffDate);

        const pickupDate = moment(PickupDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        const dropoffDate = moment(DropoffDate, 'DD/MM/YYYY').format('YYYY/MM/DD');

        this.pickUpDateCtrl.setValue(new Date(pickupDate));
        this.pickUpTimeCtrl.setValue(PickupTime);
        this.dropOffDateCtrl.setValue(new Date(dropoffDate));
        this.dropOffTimeCtrl.setValue(DropoffTime);
        this.driversAgeCtrl.setValue(DriversAge);
        this.pickUpLocationCtrl.setValue({
            LocationID: PickupLocationID,
            LocationName: PickupLocationName,
            LocationCountry: PickupLocationCountry,
        });
        this.dropOffLocationCtrl.setValue({
            LocationID: DropoffLocationID,
            LocationName: DropoffLocationName,
            LocationCountry: DropoffLocationCountry
        });
    }

    selectPickUpDate(date: Date): void {
        this.pickUpDateCtrl.setValue(date);
        this.pickUpDateCtrl.markAsTouched();
    }

    selectDropOffDate(date: Date): void {
        this.dropOffDateCtrl.setValue(date);
        this.dropOffDateCtrl.markAsTouched();
    }
}
