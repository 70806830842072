import { Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { HotelRoomDistribution, PriceGroup } from '@vecib2c/frontend-dto/dist';
import { ActivatedRoute } from '@angular/router';
import { FlightProviderService } from '../../../lib-shared/services/index';
import { FlightsItemCardComponent } from '../../flights/index';

@Component({
    selector: 'lib-hf-flights-list',
    templateUrl: './hf-flights-list.component.html',
    styleUrls: ['./hf-flights-list.component.scss'],
    providers: [FlightProviderService]
})
export class HfFlightsListComponent implements OnInit {
    @Input() flights: PriceGroup[];

    @Output() flightSelected: EventEmitter<PriceGroup> = new EventEmitter<PriceGroup>();

    @Input() paginationTemplate: TemplateRef<any>;

    @ViewChildren(FlightsItemCardComponent) flightItemComponent: QueryList<FlightsItemCardComponent>;

    @Input() flightComparePrice: PriceGroup;

    @Input() microsites = false;

    PAGE_START = 0;
    PAGE_SIZE = 10;

    totalItems: number;
    rows: any;
    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 15];

    pax: HotelRoomDistribution[];

    paxFlight;

    constructor(private route: ActivatedRoute) {
        this.route.queryParams
            .subscribe(params => {
                if (params['distribution']) {
                    this.pax = JSON.parse(params['distribution']);
                    this.paxFlight = this.getPaxFlightFromHotel();
                }
            });
    }

    getPaxFlightFromHotel() {
        return {
            "adults": this.pax[0].adults,
            "children": this.pax[0].children.length,
            "infants": 0
        };
    }

    ngOnInit() {
    }

}
