import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule, MatInputModule, MatSelectModule } from "@angular/material";
import { IonicModule } from "@ionic/angular";
import { MaterialDatePickerModule } from "../../../../material-date-picker/index";
import { LibSharedModule } from "../../../../lib-shared/lib-shared.module";
import { PrepaidModalComponent } from "./prepaid-modal.component";

@NgModule({
    declarations: [
        PrepaidModalComponent,
    ],
    imports: [
        LibSharedModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        MatSelectModule,
        MaterialDatePickerModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    exports: [
        PrepaidModalComponent,
    ]
})
export class PrepaidModalModule {

}
