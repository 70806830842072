import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AgencyChain, ContactPerson, IEconomicInfo, IProvider, IProviderService } from '../interfaces/index';
import { TecnoturisService } from '../../tecnoturis.service';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AgencyProviderService {
    protected host;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
        this.host = this.tecnoturisService.config.management;
    }

    getChains(): Observable<AgencyChain[]> {
        return this.http.get<AgencyChain[]>(`${this.host}/chain/list/`).pipe(share());
    }

    getChain(id: number): Observable<AgencyChain> {
        return this.http.get<AgencyChain>(`${this.host}/chain/${id}/`);
    }

    searchChains(search: string): Observable<AgencyChain[]> {
        return this.http.get<AgencyChain[]>(`${this.host}/chain/search/?search=${search}`);
    }

    getAgencies(): Observable<any> {
        return this.http.get<any>(`${this.host}/agency/`);
    }

    getProvider(id: number): Observable<IProvider> {
        return this.http.get<IProvider>(`${this.host}/provider/${id}/`);
    }

    getProviders(agency: number, integrated = true, all= true): Observable<IProvider[]> {
        const addIntegrated = `${integrated ? '&is_integrated=true' : ''}`;
        return this.http.get<IProvider[]>(`${this.host}/agency/${agency}/providers/?all=${all}${addIntegrated}`);
    }

    getContactPersons(agency_provider: number): Observable<ContactPerson[]> {
        return this.http.get<ContactPerson[]>(`${this.host}/agency/provider/${agency_provider}/contact/`);
    }

    getFiles(agency_provider: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.host}/agency/provider/${agency_provider}/files/`);
    }

    getServices(provider: number): Observable<IProviderService[]> {
        return this.http.get<IProviderService[]>(`${this.host}/provider/${provider}/provider-services/`);
    }

    getAgencyProvider(agency: number, provider: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.host}/agency/provider/${agency}/agency-provider/${provider}/`);
    }

    getAgencyProviders(agency: number, provider: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.host}/agency/provider/${agency}/agency-provider-services/${provider}/`);
    }

    getFees(agency_provider: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.host}/agency/provider/${agency_provider}/comission/`);
    }

    addContactPerson(data: any): Observable<any> {
        return this.http.post(`${this.host}/agency/provider/contact/`, data);
    }

    removeContactPerson(id: number, create = false): Observable<any> {
        const url = `${this.host}/agency/provider/contact/${id}/?create=${create}`;
        return this.http.delete(url);
    }

    addAgencyProvider(data: any): Observable<any> {
        return this.http.post(`${this.host}/agency/provider/`, data);
    }

    addAgencyProviderService(data: any): Observable<any> {
        return this.http.post(`${this.host}/agency_provider_service/`, data);
    }

    addFee(data: any): Observable<any> {
        return this.http.post(`${this.host}/agency/provider/comission/`, data);
    }

    removeFee(id: number, create = false): Observable<any> {
        const url = `${this.host}/agency/provider/comission/${id}/?create=${create}`;
        return this.http.delete(url);
    }

    addEconomicInfo(data: any): Observable<any> {
        return this.http.post(`${this.host}/agency/provider/economic-info/`, data);
    }

    removeEconomicInfo(id: number, create= false): Observable<any> {
        const url = `${this.host}/agency/provider/economic-info/${id}/?create=${create}`;
        return this.http.delete(url);
    }

    getEconomicInfo(agency_provider): Observable<IEconomicInfo[]> {
        return this.http.get<IEconomicInfo[]>(`${this.host}/agency/provider/${agency_provider}/economic-info/`);
    }

    uploadFilesToProvider(data: any): Observable<any> {
        return this.http.post(this.host + '/agency/provider/files/', data);
    }
}
