import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'lib-search-select',
    templateUrl: './search-select.component.html',
    styleUrls: ['./search-select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchSelectComponent extends FieldType implements OnInit, OnDestroy {
    to: any;

    fieldFilterCtrl: FormControl = new FormControl();
    term: string;
    items: any[];

    private unsubscribe = new Subject<void>();

    ngOnInit(): void {
      setTimeout(() => this.items = this.to.options as any[], 500);

        this.fieldFilterCtrl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe),
            )
            .subscribe((val: string) => {
                this.term = val;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
