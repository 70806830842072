import { ShoppingBasketTrains } from '../../shopping-basket-trains.model';
import { PreBookingsCreateRequestDto } from './pre-bookings-create.request.dto';

export class TrainCheckedRateReq extends PreBookingsCreateRequestDto {

    static buildFromBasket(item: ShoppingBasketTrains): TrainCheckedRateReq {
        const { providerToken, trains, requestToken } = item;
        const selectedOutward = trains[0];
        const selectedReturn = trains[1];

        const params = new TrainCheckedRateReq();
        params.providerToken = providerToken;
        params.trainTypeForOutward = selectedOutward.trainTypeDescription;
        params.trainNumberForOutward = selectedOutward.trainNumber;
        params.classCodeOutward = selectedOutward.classes[0].classCode;
        params.salesGroupCodeOutward = selectedOutward.classes[0].salesGroups[0].salesGroupCode;
        if (selectedReturn) {
            params.trainTypeForReturn = selectedReturn.trainTypeDescription;
            params.trainNumberForReturn = selectedReturn.trainNumber;
            params.classCodeReturn = selectedReturn.classes[0].classCode;
            params.salesGroupCodeReturn = selectedReturn.classes[0].salesGroups[0].salesGroupCode;
        }
        params.requestToken = requestToken;

        return params;
    }
}
