import { Pipe, PipeTransform } from "@angular/core";
import { companyList as companiesList } from "../../integrations/flights/flight-filter/company-list";

@Pipe({
    name: 'airlineCompanyName'
})
export class AirlineCompanyNamePipe implements PipeTransform {
    transform(companyCode: string): string {
        const attr = companyCode.length === 2 ? 'code' : 'aux';
        const company = companiesList.companiesList.find(el => el[attr] === companyCode);
        if (!company) { return; }
        return company.name;
    }
}
