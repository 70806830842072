import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PackageHotel } from '@vecib2c/frontend-dto';

@Component({
    selector: 'lib-item-package-expansion-panel',
    templateUrl: './item-package-expansion-panel.component.html',
    styleUrls: ['./item-package-expansion-panel.component.scss']
})
export class ItemPackageExpansionPanelComponent implements OnInit {

    @Input() hotel: PackageHotel;
    @Input() passengerCount: number;
    @Input() displayRooms = false;
    @Input() displayMap = false;
    @Input() displayDescription = false;
    @Input() microsites = false;
    @Input() optionsRooms
    @Input() moreInfo
    @Input() map


    displayedColumns = ['Habitacion', 'Tarifa', 'Precio', 'Reservar'];

    @Output() emitUrl = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {        
    }

    getUrl(element) {
        this.emitUrl.emit(element);
    }

}
