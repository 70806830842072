import { Injectable } from "@angular/core";


/**
 * Avoid at any cost using this service
 * a global variable is never a good idea
 * https://es.wikipedia.org/wiki/Variable_global
 */
@Injectable({
    providedIn: "root"
})
export class ShareService {
    globalVars = {};

    constructor() {
    }

    get(key: string): any {
        if (key in this.globalVars && this.globalVars[key] !== undefined) {
            return this.globalVars[key];
        } else {
            return null;
        }
    }

    set(key: string, value): any {
        this.globalVars[key] = value;
        return true;
    }

    empty() {
        return this.globalVars = {} as any;
    }
}
