import { Pipe, PipeTransform } from '@angular/core';
import { FlightItinerary } from '@vecib2c/frontend-dto/dist';

@Pipe({
    name: 'getDateBySegment'
})
export class GetDateBySegmentPipe implements PipeTransform {

    transform(iata: string, itineraries: FlightItinerary[]): any {
        const itinerary = itineraries.find(i => iata.split(',').includes(i.originIata));
        return itinerary ? itinerary.originDateTime : '';
    }

}
