import { Component, Input, OnInit } from '@angular/core';
import { getCurrencySymbol } from "@angular/common";
import { Circuit, Photos } from '@vecib2c/frontend-dto';
import { NavigationExtras, Router } from '@angular/router';
import { ShareService } from '../../../lib-shared/services/index';

@Component({
    selector: "lib-circuits-item-card",
    templateUrl: "./circuits-item-card.component.html",
    styleUrls: ["./circuits-item-card.component.scss"]
})
export class CircuitsItemCardComponent implements OnInit {

    @Input() item: Circuit;
    @Input() data: any;
    @Input() nights: any;
    @Input() from: any;
    @Input() microsites = false;
    fligth;
    days;
    route;
    price;
    commission;
    currency: string;
    rateToFlight: string;

    iata = [
        {iata: 'MAD', name: 'Madrid'},
        {iata: 'BIO', name: 'Bilbao'},
        {iata: 'LUQ', name: 'San Luis'},
        {iata: 'RSA', name: 'Santa Rosa'},
        {iata: 'IRJ', name: 'La Rioja'},
        {iata: 'REL', name: 'Trelew'}
    ];
    photo: Photos;
    mockPhoto = "/assets/images/img_Circuitos_General.jpg"; // por si acaso

    constructor(
        public share: ShareService,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.getIncludedFligth();
        this.getTotalDays();
        this.getRoute();
        this.getPrice();
        this.getCommission();
        this.getCurrency(this.item.currency);
        this.getFrom();
        this.getPhoto();
        this.getRateToFlight();
    }

    getFrom() {
        this.from = this.iata.filter(item => this.from === item.iata)[0].name;
    }

    getIncludedFligth() {
        if (this.item.flight) {
            this.fligth = 'Incluido';
            return;
        }
        this.fligth = 'No Incluido';
    }

    getTotalDays() {
        this.days = this.item.itinerary.length;
    }

    getRoute() {
        /** TODO: better on a pipe */
        const splittedRouteItem = this.item.route.split(' ');
        splittedRouteItem.forEach((element, i) => {
            // if first char is a number add the string
            if (/^([0-9])*$/.test(element.charAt(0))) {
                let noches = '';
                element.charAt(0) === '1' ? noches = ' noche en ' : noches = ' noches en ';
                splittedRouteItem[i] = element + noches;
            }
        });
        this.route = splittedRouteItem.join('');
        if (this.route.length >= 60) {
            this.route = this.route.slice(0, 59) + '...';
        }
    }


    getPhoto() {
        this.photo = this.item.photos;
    }

    getRateToFlight() {
        this.rateToFlight = this.item.rateToFlight;
    }

    getPrice() {
        const departures = this.item.departures;
        const prices = [];
        if (departures) {
            departures.forEach(departure => {
                (departure.rates).forEach(rate => {
                    prices.push(rate.price);
                });
            });
            this.price = Math.min(...prices);
        } else {
            this.price = 0;
        }
    }

    getCommission() {
        const departures = this.item.departures;
        const commissions = [];
        if (departures) {
            departures.forEach(departure => {
                (departure.rates).forEach(rate => {
                    commissions.push(rate.commission);
                });
            });
            this.commission = commissions;
        } else {
            this.commission = 0;
        }
    }

    getCurrency(currencyCode) {
        return this.currency = getCurrencySymbol(currencyCode, "narrow");
    }

    navigateToDetail(): void {
        const id = this.item.circuitId;

        const extras: NavigationExtras = {
            state: {
                circuit: this.item,
            },
            queryParams: {
                date: this.share.get("date"),
                nights: this.share.get("nights"),
                rooms: JSON.stringify(this.share.get("rooms")),
                language: this.share.get("language"),
                departure: this.share.get("departure"),
                category: this.share.get("category"),
                provider: this.item.provider,
                country: this.share.get('country'),
                rateToFlight: this.item.rateToFlight,
            }
        };

        if (this.microsites) {
            this.router.navigate([`/circuits/detail/${id}`], extras);
            return;
        }

        this.router.navigate([`integration/circuits/detail/${id}`], extras);
    }
}
