import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    BookingDosierRequest,
    BookingDossier,
    Budget,
    BudgetDetail, CashOutProvider,
    IBanner,
    IMicrosite,
    IPaginate,
    IService,
    PaymentDossier,
    PaymentOption,
    Prepaid,
    PrepaidRequest,
    RawBookingService,
    Setup,
    SupplierInvoice,
    SupplierInvoiceRequest,
    TecnoturisPartner
} from '../interfaces/index';
import {TecnoturisService} from '../../tecnoturis.service';
import { decode } from '../functions/index';

@Injectable({
    providedIn: 'root'
})
export class ManagementService {
    protected addCommercialEvent: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    protected host;
    protected hostExport;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
        this.host = this.tecnoturisService.config.management;
        this.hostExport = this.tecnoturisService.config.exportServices;
    }

    getCommercialEvent(): Observable<number> {
        return this.addCommercialEvent.asObservable();
    }

    emitCommercialEvent(id: number): void {
        this.addCommercialEvent.next(id);
    }

    saveBooking(booking: any): Observable<any> {
        return this.http.post(this.host + '/hotel-book/', booking);
    }

    uploadFilesToClient(data: any): Observable<any> {
        return this.http.post(this.host + '/clients/files/', data);
    }

    getProviders(): Observable<any> {
        return this.http.get<any>(this.host + '/provider/').pipe(
            map(res => res.results)
        );
    }

    getProvidersIntegrated(): Observable<any> {
        return this.http.get<any>(this.host + '/provider/integrated/');
    }

    getSetup(): Observable<Setup> {
        return this.http.get<Setup>(this.host + '/setup/');
    }

    addDossierPax(data): Observable<any> {
        return this.http.post(this.host + '/clients/dossier_pax/', data);
    }

    getPaxesFromDossier(dossier: number): Observable<any> {
        return this.http.get(this.host + `/dossiers/${dossier}/pax`);
    }

    addPax(data): Observable<any> {
        return this.http.post(this.host + '/pax/', data);
    }

    addServiceBooking(data: { dossier: number, service: number }): Observable<any> {
        return this.http.post(this.host + '/booking-service/', data);
    }

    updateBookingService(id: number, data: any) {
        return this.http.patch(`${this.host}/booking-service/${id}/`, data);
    }

    addRoom(data): Observable<any> {
        return this.http.post(this.host + '/hotel-booking-rooms/', data);
    }

    deleteRoomPax(room: number, pax: number): Observable<any> {
        return this.http.delete(this.host + `/hotel-booking-rooms-pax/${room}/${pax}/`);
    }

    addBudget(bookings: { booking_service: number[], type: number }): Observable<any> {
        return this.http.post<any>(this.host + '/budget-create/', bookings);
    }

    addProcceding(request: { booking_service: string[], dossier: string }, type = '3') {
        return this.http.get<RawBookingService[]>(this.host + '/clients/dossier/' + request.dossier + '/booking-service/raw/', {
            params: {
                type: type,
                id: request.booking_service
            }
        });
    }

    addDossierBooking(data): Observable<any> {
        return this.http.post<any>(this.host + '/static/dossier-booking-hotels-static/', data);
    }

    getBudget(id: number): Observable<BudgetDetail> {
        return this.http.get<BudgetDetail>(this.host + `/budget/${id}/`);
    }

    addServicesToDossier(data: BookingDosierRequest): Observable<BookingDossier[]> {
        return this.http.post<BookingDossier[]>(this.host + '/booking/', data);
    }

    generateBudgetPdf(id: number) {
        window.open(this.hostExport + '/pretty-budget/' + id + '/');
    }

    generateBillingPdf(id: number) {
        window.open(this.hostExport + '/billing/' + id + '/');
    }

    generateBondPdf(id: number) {
        window.open(this.hostExport + '/bond/' + id + '/');
    }

    getServices(): Observable<IService[]> {
        return this.http.get<IPaginate<IService>>(this.host + `/services/`)
            .pipe(map(response => response.results));
    }

    getRole(): Observable<any[]> {
        return this.http.get<any[]>(this.host + '/user/roles/');
    }


    multiloginService(queryParams?: any): Observable<any> {
        return this.http.get(this.host + '/multilogin/', {
            params: queryParams
        });
    }

    providerKeys(isMultilogin = true, agency?: number, provider?: number): Observable<any> {
        const url = provider && agency ? this.host + `/multilogin/${provider}/agency/${agency}/` :
            provider ? this.host + `/multilogin/${provider}` : this.host + '/multilogin/';
        return this.http.get(url);
    }

    postSaveMultilogin(data: any) {
        return this.http.patch(this.host + `/multilogin/${data.agency}/`, data);
    }

    getCalendarAlerts(): Observable<any> {
        return this.http.get(this.host + '/clients/commercial_activities/');
    }

    deleteCalendarAlert(id: number): Observable<any> {
        return this.http.delete(this.host + `/clients/commercial_activities/${id}/`);
    }

    getTaxRegimeIvaApply(request: any): Observable<any> {
        return this.http.get(this.host +
            `/tax/regime/iva-apply/${request.fee_apply_id}/${request.service_id}/${request.fiscal_location_id}/${request.provider_id}/`);
    }

    getCashOptions(agencyId: number): Observable<PaymentOption[]> {
        const path = this.host + `/cash/payment/cash/${agencyId}/`;
        return this.http.get<PaymentOption[]>(path);
    }

    getCashOutProviders(agencyId: number): Observable<CashOutProvider[]> {
        const path = this.host + `/cash/providers/?agency=${agencyId}`;
        return this.http.get<CashOutProvider[]>(path);
    }

    getBankOptions(agencyId: number): Observable<PaymentOption[]> {
        const path = this.host + `/cash/payment/bank-account/${agencyId}/`;
        return this.http.get<PaymentOption[]>(path);
    }

    getPayments(dossierId: number): Observable<PaymentDossier[]> {
        const path = this.host + `/cash/payment/${dossierId}/payment/`;
        return this.http.get<PaymentDossier[]>(path);
    }

    addPayment(paymentRequest: PaymentDossier): Observable<PaymentDossier> {
        const path = this.host + `/cash/payment/`;
        return this.http.post<PaymentDossier>(path, paymentRequest);
    }

    updatePayment(paymentRequest: PaymentDossier, paymentId: number): Observable<PaymentDossier> {
        const path = this.host + `/cash/payment/${paymentId}/`;
        return this.http.patch<PaymentDossier>(path, paymentRequest);
    }

    deletePayment(paymentId: number): Observable<any> {
        const path = this.host + `/cash/payment/${paymentId}/`;
        return this.http.delete<any>(path);
    }

    getCreditCardOptions(agencyId: number): Observable<PaymentOption[]> {
        const path = this.host + `/cash/payment/credit-card/${agencyId}/`;
        return this.http.get<PaymentOption[]>(path);
    }

    downloadPaymentDossier(paymentId: number) {
        window.open(this.hostExport + '/receipt/' + paymentId + '/');
    }

    addPaymentOption(
        paymentOption: PaymentOption,
        agencyId: number,
        optionName: string
    ): Observable<PaymentOption> {
        if (optionName === 'cash') {
            return this.addCashOption(paymentOption, agencyId);
        }
        if (optionName === 'bankAccount') {
            return this.addBankAccountOption(paymentOption, agencyId);
        }
        return this.addCreditCardOption(paymentOption, agencyId);
    }

    updatePaymentOption(
        paymentOption: PaymentOption,
        agencyId: number,
        paymentOptionId: number,
        optionName: string
    ): Observable<PaymentOption> {
        if (optionName === 'cash') {
            return this.updateCashOption(paymentOption, agencyId, paymentOptionId);
        }
        if (optionName === 'bankAccount') {
            return this.updateBankAccountOption(paymentOption, agencyId, paymentOptionId);
        }
        return this.updateCreditCardOption(paymentOption, agencyId, paymentOptionId);
    }

    deletePaymentOption(paymentOptionId, optionName: string): Observable<string> {
        if (optionName === 'cash') {
            return this.deleteCashOption(paymentOptionId).pipe(map(res => 'deleted'));
        }
        if (optionName === 'bankAccount') {
            return this.deleteBankAccountOption(paymentOptionId).pipe(map(res => 'deleted'));
        }
        return this.deleteCreditCardOption(paymentOptionId).pipe(map(res => 'deleted'));
    }

    addCashOption(
        cashOptionRequest: PaymentOption,
        agencyId: number
    ): Observable<PaymentOption> {
        const path = this.host + `/cash/cash/`;
        return this.http.post<PaymentOption>(path, Object.assign({}, cashOptionRequest, {
            agency: agencyId,
            payment_method: 1
        }));
    }

    updateCashOption(
        cashOptionRequest: PaymentOption,
        agencyId: number,
        cashOptionId: number
    ): Observable<PaymentOption> {
        const path = this.host + `/cash/cash/${cashOptionId}/`;
        return this.http.patch<PaymentOption>(path, Object.assign({}, cashOptionRequest, {
            agency: agencyId,
            payment_method: 1
        }));
    }

    deleteCashOption(cashOptionId: number): Observable<any> {
        const path = this.host + `/cash/cash/${cashOptionId}/`;
        return this.http.delete<any>(path);
    }

    getCashByAgency(agencyId: number, all: number = 0): Observable<any> {
        const path = this.host + `/cash/cash/by_agency/${agencyId}/${all}/`;
        return this.http.get<any>(path);
    }

    addDailyMovementCashAmount(data: any) {
        const path = this.host + `/cash/daily-cash/`;
        return this.http.post<any>(path, data);
    }

    updateDailyMovement(id: number, data: any) {
        const path = this.host + `/cash/daily-cash/${id}/`;
        return this.http.patch<any>(path, data);
    }

    addCashClosing(data: any) {
        const path = this.host + `/cash/cash-closing/`;
        return this.http.post<any>(path, data);
    }

    updateCashClosing(id: number, data: any) {
        const path = this.host + `/cash/cash-closing/${id}/`;
        return this.http.patch(path, data);
    }

    getResumeBalance(cashId: number, operationDate: string) {
        const path = this.host + `/cash/cash-closing/resume-balance/${cashId}/${operationDate}/`;
        return this.http.get<any>(path);
    }

    retrivePdf(id: number) {
        return this.host + `/cash/generate-pdf/${id}/`;
    }

    getPdfPreview(cashId: number, operationDate: string) {
        return this.host + `/cash/generate-pdf/preview/${cashId}/${operationDate}/`;
    }

    addBankAccountOption(
        bankAccountOptionRequest: PaymentOption,
        agencyId: number
    ): Observable<PaymentOption> {
        const path = this.host + `/cash/bank-account/`;
        return this.http.post<PaymentOption>(path, Object.assign({}, bankAccountOptionRequest, {
            agency: agencyId,
            payment_method: 2
        }));
    }

    updateBankAccountOption(
        bankAccountOptionRequest: PaymentOption,
        agencyId: number,
        bankAccountOptionId: number
    ): Observable<PaymentOption> {
        const path = this.host + `/cash/bank-account/${bankAccountOptionId}/`;
        return this.http.patch<PaymentOption>(path, Object.assign({}, bankAccountOptionRequest, {
            agency: agencyId,
            payment_method: 2
        }));
    }

    deleteBankAccountOption(bankAccountOptionId: number): Observable<any> {
        const path = this.host + `/cash/bank-account/${bankAccountOptionId}/`;
        return this.http.delete<any>(path);
    }

    addCreditCardOption(
        creditCardOptionRequest: PaymentOption,
        agencyId: number
    ): Observable<PaymentOption> {
        const path = this.host + `/cash/credit-card/`;
        return this.http.post<PaymentOption>(path, Object.assign({}, creditCardOptionRequest, {
            agency: agencyId,
            payment_method: 4
        }));
    }

    updateCreditCardOption(
        creditCardOptionRequest: PaymentOption,
        agencyId: number,
        creditCardOptionId: number
    ): Observable<PaymentOption> {
        const path = this.host + `/cash/credit-card/${creditCardOptionId}/`;
        return this.http.patch<PaymentOption>(path, Object.assign({}, creditCardOptionRequest, {
            agency: agencyId,
            payment_method: 4
        }));
    }

    deleteCreditCardOption(creditCardOptionId: number): Observable<any> {
        const path = this.host + `/cash/credit-card/${creditCardOptionId}/`;
        return this.http.delete<any>(path);
    }

    generateInvoiceForAllServices(dossierId: number, bugdetType: number): Observable<Budget[]> {
        const path = this.host + `/budget-create/dossier/`;
        const params = {
            dossier: dossierId,
            budget_type: bugdetType
        };
        return this.http.post<Budget[]>(path, params);
    }

    cancelBudget(budgetId: number): Observable<Budget> {
        const path = `${this.host}/budget/${budgetId}/cancel/`;
        return this.http.patch<Budget>(path, {is_cancel: true});
    }

    getAllProviderServices(id: any): Observable<any> {
        return this.http.get(`${this.host}/provider/${id}/provider-services/`);
    }

    addProviderServices(id: any, data) {
        return this.http.post(`${this.host}/provider/${id}/provider-services/`, data);
    }

    dropProviderService(id, service) {
        return this.http.delete(`${this.host}/provider/${id}/provider-services/${service}/`);
    }

    getMicrositesDomains(agencyChainId: number) {
        return this.http.get<IMicrosite[]>(`${this.host}/chain/${agencyChainId}/microsite/`);
    }

    getBanners() {
        return this.http.get<IBanner[]>(`${this.host}/banner/`);
    }

    saveBanner(id, body) {
        if (id > 0) {
            return this.http.patch<any>(`${this.host}/banner/${id}/`, body);
        } else {
            return this.http.post<any>(`${this.host}/banner/`, body);
        }
    }

    addPrepaid(params: PrepaidRequest, bookingServiceId: number): Observable<Prepaid> {
        const url = `${this.host}/booking-service/${bookingServiceId}/prepaids/`;
        return this.http.post<Prepaid>(url, params);
    }

    getPrepaids(dossierId: number): Observable<Prepaid[]>  {
        const url = `${this.host}/clients/dossier/${dossierId}/prepaids/`;
        return this.http.get<Prepaid[]>(url);
    }

    removePrepaid(bookingServiceId: number, prepaidId: number): Observable<string> {
        const url = `${this.host}/booking-service/${bookingServiceId}/prepaids/${prepaidId}/`;
        return this.http.delete(url).pipe(map(_ => 'deleted'));
    }

    addSupplierInvoice(params: SupplierInvoiceRequest, bookingServiceId: number): Observable<SupplierInvoice> {
        const url = `${this.host}/booking-service/${bookingServiceId}/supplier_invoices/`;
        return this.http.post<SupplierInvoice>(url, params);
    }

    getSupplierInvoices(dossierId: number): Observable<SupplierInvoice[]> {
        const url = `${this.host}/clients/dossier/${dossierId}/supplier_invoices/`;
        return this.http.get<SupplierInvoice[]>(url);
    }

    removeSupplierInvoice(bookingServiceId: number, supplierInvoiceId: number): Observable<string> {
        const url = `${this.host}/booking-service/${bookingServiceId}/supplier_invoices/${supplierInvoiceId}/`;
        return this.http.delete(url).pipe(map(_ => 'deleted'));
    }

    getTecnoturisPartners(): Observable<TecnoturisPartner> {
        // tslint:disable-next-line
        const encodedPartners = "ewogICAgICAgICJ0ZWNub3RyYXZlbCI6IHsKICAgICAgICAgICAgInVybCI6ICJodHRwOi8vZ2VzZ3JvdXBzLmVzL2xvZ2luLnBocCIsCiAgICAgICAgICAgICJ1c2VyIjogIkI2OTgyMzY5OCIsCiAgICAgICAgICAgICJwYXNzIjogImljbUppNVFTU3lpbmgiCiAgICAgICAgfSwKICAgICAgICAidGVjbm9mbGlnaHQiOiB7CiAgICAgICAgICAgICJ1cmwiOiAiaHR0cDovL3RlY25vZmxpZ2h0cy5ncnVwb3ZkdC5jb20vd3RjbS9ici92dWVsb3MvQXBwTG9naW4uYXNweD9Gb2xsb3c9YnIvdnVlbG9zL0RlZmF1bHQuYXNweCIsCiAgICAgICAgICAgICJ1c2VyIjogIkI2OTgyMzY5OCIsCiAgICAgICAgICAgICJwYXNzIjogImljbUppNVFTU3lpbmgiCiAgICAgICAgfQogICAgfQ==";
        const decodedPartners = decode(encodedPartners);
        if (!decodedPartners) { return; }
        const partners = JSON.parse(decodedPartners);

        return of(partners);
    }

}
