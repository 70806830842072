import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { AlertConfirmComponent } from '../components/alert-confirm/alert-confirm.component';
import { AlertSnackComponent } from '../components/alert-snack/alert-snack.component';
import { AlertMessageComponent } from '../components/alert-message/alert-message.component';


@Injectable()
export class ModalProviderService {

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar) {
    }

    showModal(component, config): any {
        return this.dialog.open(component, config);
    }

    showConfirm(textmessage) {
        return this.dialog.open(AlertConfirmComponent, {
            width: '450px',
            minHeight: '140px',
            maxHeight: '260px',
            data: {
                message: textmessage
            }
        });
    }

    showAlert(textmessage) {
        return this.dialog.open(AlertMessageComponent, {
            width: '450px',
            minHeight: '140px',
            maxHeight: '260px',
            data: {
                message: textmessage
            }
        });
    }

    showSnack(textmessage) {
        this.snackBar.openFromComponent(AlertSnackComponent, {
            data: {
                message: textmessage
            },
            duration: 6000,
            verticalPosition: 'top'
        });
    }

    closeAll() {
        return this.dialog.closeAll();
    }

}
