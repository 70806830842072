import { NgModule } from "@angular/core";
import { TransportsItemCardComponent } from "./transports-item-card/transports-item-card.component";
import { MatCardModule } from "@angular/material/card";
import { IonicModule } from "@ionic/angular";
import { TransportsFilter } from "./transports-filter/transports-filter.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { PriceRangeModule } from "../../price-range/price-range.module";
import { MatExpansionModule, MatRadioModule } from "@angular/material";
import { LibSharedModule } from '../../lib-shared/index';
import { TransportsPriceSummaryModule } from "./transports-price-summary/transports-price-summary.module";
import { MdePopoverModule } from "@material-extended/mde";

@NgModule({
    declarations: [
        TransportsItemCardComponent,
        TransportsFilter
    ],
    imports: [
        MatCardModule,
        IonicModule,
        MatCheckboxModule,
        MatRadioModule,
        FormsModule,
        PriceRangeModule,
        MatExpansionModule,
        LibSharedModule,
        TransportsPriceSummaryModule,
        MdePopoverModule,
    ],
    exports: [
        TransportsItemCardComponent,
        TransportsFilter
    ]
})

export class TransportsIntegrationModule {
}
