import { CountryBook, InsuredBook } from "@vecib2c/frontend-dto";
import { ManagementPax } from "../../interfaces/management.interface";
import { t } from 'typy';

export class InsurancePaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialInsurancePaxes: InsuredBook[],
    ): InsuredBook[] {
        const baseCountry = this.buildBaseCountry(initialInsurancePaxes);

        return paxes.map((pax, i) => {
            const initialInsurancePax = initialInsurancePaxes[i] || {} as InsuredBook;

            const {
                documentNumber: initialDocumentNumber,
                documentType: initialDocumentType,
                surname: initialSurname,
            } = initialInsurancePax;

            const {
                name,
                last_name: lastName,
                dni,
                type_document: documentType,
            } = pax;

            const bookingPax: InsuredBook = {
                country: baseCountry,
                documentNumber: this.buildDocumentNumber(dni, initialDocumentNumber),
                documentType: this.buildDocumentType(documentType, initialDocumentType),
                name,
                surname: this.buildSurname(lastName, initialSurname),
                isMain: i === 0,
            };

            return bookingPax;
        });
    }

    private static buildBaseCountry(
        initialInsurancePaxes: InsuredBook[] = [],
    ): CountryBook {
        const baseCountry = t(initialInsurancePaxes[0], 'country').safeObject;
        if (baseCountry) { return baseCountry; }
        return {
            countryCode: 'ESP',
            countryCodeAlpha: 'ES',
        };
    }

    private static buildDocumentNumber(
        documentNumber: string,
        initialDocumentNumber = '',
    ): string {
        if (!documentNumber) { return initialDocumentNumber; }
        return documentNumber;
    }

    private static buildDocumentType(
        documentType: number,
        initialDocumentType = 'NIF',
    ): string {
        if (!documentType) { return initialDocumentType; }
        if (documentType === 1) { return 'NIF'; }
        if (documentType === 3) { return 'NIE'; }
        return 'NIF';
    }

    private static buildSurname(
        surname: string,
        initialSurname = '',
    ): string {
        if (!surname) { return initialSurname; }
        return initialSurname;
    }
}
