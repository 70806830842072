import { ComissionsTransfers } from "../../..";
import { Occupancy } from "../response/transfer.response.dto";

export class BookingRequestTransfer {
    provider: string;
    languaje: string;
    refNumberKey: string;
    sessionId: string;
    transferType: string;
    arrivalInfo?: TransportInfo;
    departureInfo?: TransportInfo;
    contact: Contact;
    pax: PassengerTransfer[];
    occupancy: Occupancy;    
    totalPrice: number;
    commission: ComissionsTransfers

    constructor(){
        this.provider = "",
        this.languaje = "es",
        this.refNumberKey = "";
        this.sessionId = "";
        this.transferType = "";
        this.arrivalInfo = {
            companyCode: "",
            arrivalTime: "",
            arrivalCode: "",
            transportNumber: ""
        },
        this.departureInfo = {
            companyCode: "",
            departureTime: "",
            departureCode: "",
            transportNumber: ""
        },
        this.contact = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        },
        this.pax = [{
            id: "",
            typeId: "Adulto",
            age: 0,
            phone: "",
            email: "",
            firstName: "",
            lastName: "",
            documentationNumber: "",
            documentType: "DNI"
        }]
        this.occupancy = {
            adults: "0",
            children: "0",
            infants: "0",
        }      
        this.totalPrice = 0;
        this.commission = {
            price: 0,
            pvp: 0,
            iva: 0,
            fee: 0,
            commissionRate: 0,
            commission: 0,
        }

    }
}

export class TransportInfo {
    companyCode: string;
    arrivalTime?: string; // time of the arrival of the flight
    arrivalCode?: string; // Time of the departure of the flight
    departureCode?: string;
    departureTime?: string;
    transportNumber: string; // flight number

    constructor() {
        this.companyCode = "",
        this.arrivalTime = "",
        this.arrivalCode = "",
        this.departureCode = "",
        this.departureTime = "",
        this.transportNumber = ""
    }
}

export class Contact {
    firstName: string;
    lastName: string;
    middleName?: string;
    email?: string;
    phone?: string

    constructor(){
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = "";
    }
}

export class PassengerTransfer {
    id?: string;
    typeId?: TransferPaxType;
    age: number | string;
    phone?: string;
    email?: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    documentType: TransferDocumentType;
    documentationNumber?: string; // En la respuesta al prebook diran si esta info es obligatoria o no. 

    constructor(){
        this.id = "",
        this.age = 0,
        this.phone = "",
        this.email = "",
        this.firstName = "",
        this.lastName = "",
        this.documentationNumber = "",
        this.typeId = "Adulto",
        this.documentType = "DNI"
    }
}

export type TransferPaxType = 'Adulto' | 'Niño' | 'Bebe' | 'Senior' | "";

export const transferPaxType: TransferPax[] = [
    {
        id: 'Adulto',
        value: 'A'
    },
    {
        id: 'Niño',
        value: 'C'
    },
    {
        id: 'Bebe',
        value: 'B'
    },
    {
        id: 'Senior',
        value: 'S'
    }
]

export interface TransferPax {
    id: TransferPaxType;
    value: string;
}

export type TransferDocumentType = 'DNI' | 'PAS' | 'NIE' | "";

export interface TransferDocument {
    id: TransferDocumentType;
    value: string;
}

export const transferDocuments: TransferDocument[] = [
    {
        id: 'DNI', value: 'DNI',
    },
    {
        id: 'PAS', value: 'PAS',
    },
    {
        id: 'NIE', value: 'NIE',
    }
];

