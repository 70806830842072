import {Response} from './response';

export class TrainsDtoRes extends Response {
    data: Trains;
}

export class Trains {
    outward: Itinerary2[];
    return: Itinerary2[];
    providerToken: string;
    outwardTotal: number;
    returnTotal: number;
    minPrice: number;
    maxPrice: number;
    requestToken: string;
}

export class Itinerary2 {
    classes: Class[];
    trainNumber: string;
    trainType: string;
    trainTypeDescription: string;
    departureStation: string;
    arrivalStation: string;
    departureStationName: string;
    arrivalStationName: string;
    departureTime: string;
    arrivalTime: string;
    travelDuration: number;
    travelDate: string;
    transportistId: string;
    timeTableType: number;
    remainingSeats: number;
    trainDirection: number;
    isLongDistanceTrain: boolean;
    isAve: boolean;
}

export class Class {
    classCode: string;
    className: string;
    salesGroups: SalesGroup[];
    remainingSeats: number;
}

export class SalesGroup {
    salesGroupCode: string;
    salesGroupName: string;
    cabins: Cabin[];
    remainingSeats: number;
    allowChange: boolean;
}

export class Cabin {
    cabinCode: string;
    cabinName: string;
    paxes: Pax[];
    remainingSeats: number;
}

export class Pax {
    paxType: string;
    paxNumber: number;
    fares: Fare[];
    remainingSeats: number;
    restrictions: Restriction[];
    seats: Seat[];
}

export class Restriction {
    anticipationDaysFrom: number;
    anticipationDaysTo: number;
    anulationFee: number;
    feeForChange: number;
    anulationMarkup: number;
    markupForChange: number;
    partialCancellationPercentage: number;
    totalCancellationPercentage: number;
    percentageForChange: number;
    partialPercentageForTotalCancellation: number;
    totalPercentageForTotalCancellation: number;
}

export class Commission {
    public commission: number;
    public commissionRate: number;
    public fee: number;
    public iva: number;
    public pvp: number;
    public commissionPercent?: number;
    public ivaPercent?: number;
}

export class Fare {
    fareId: string;
    availability: string;
    fareName: string;
    fareCode: string;
    saleCurrency: string;
    fareGroupId: number;
    fareCombinationId: number;
    allowChanges: boolean;
    allowCancellations: boolean;
    commentRestrictionES: string;
    restrictions: Restriction[];
    curNetTa: number;
    commission: Commission;
}

export class Seat {
    cabinGroup: string;
    cabinNumber: string;
    cardNumber: string;
    cardType: string;
    passengerEmail: string;
    passengerFirstSurname: string;
    passengerName: string;
    passengerSecondSurname: string;
    renfePointsCardNumber: string;
    seatNumber: string;
    seatType: string;
}
