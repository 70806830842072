import { Component, Input } from "@angular/core";
import { NotificationPopupService, TransportProviderService } from '../../../lib-shared/services/index';
import { CarBookingRes } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-car-book-confirmation',
    templateUrl: './cars-book-confirmation.html',
    styleUrls: ['./cars-book-confirmation.scss'],
    providers: [
        TransportProviderService
    ]
})

export class CarsBookConfirmation {

    @Input() car: CarBookingRes;

    isCancelled: boolean;

    constructor(
        protected _transportProvider: TransportProviderService,
        protected _notificationPopupService: NotificationPopupService
    ) {
        this.isCancelled = false;
    }

    cancel() {
        const request = {
            FCHReference: this.car.response.data.bookingNumber,
            IgnorePayment: null,
            AdditionalParameters: null,
        };
        this._transportProvider.cancelBooking(request)
            .subscribe((data) => {
                const status = [200, 201];
                if (status.includes(data.status)) {
                    this.isCancelled = true;
                    return this._notificationPopupService.openPopup('Tu reserva ha sido cancelada correctamente');
                }
                this.isCancelled = false;
                return this._notificationPopupService.openPopup('Error al cancelar la reserva');
            });
    }

    getForTimePeriod(time): string {
        if (time === "Daily") {
            return "/ Día";
        }
    }
}
