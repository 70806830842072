import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { CrudWithListComponent } from './crud-with-list.component';
import { DynamicCrudModule } from '../dynamic-crud/dynamic-crud.module';

@NgModule({
    declarations: [CrudWithListComponent],
    imports: [
        CommonModule,
        DynamicCrudModule,
        IonicModule,
        ReactiveFormsModule,
        FormlyModule
    ],
    exports: [CrudWithListComponent]
})
export class CrudWithListModule {
}
