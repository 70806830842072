import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'paxType'
})
export class PaxTypePipe implements PipeTransform {

    transform(paxType: string): string {
        if (!paxType) { return; }
        if (paxType === 'ADULT') { return 'Adulto'; }
        if (paxType === 'CHILD') { return 'Niño'; }
        return paxType;
    }
}
