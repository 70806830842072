import { Component, Input } from '@angular/core';
import { CircuitFlightPack } from '@vecib2c/frontend-dto';

@Component({
  selector: 'lib-selected',
  templateUrl: './selected.component.html',
  styleUrls: ['./selected.component.scss']
})
export class SelectedComponent {

  @Input() flight: CircuitFlightPack;

  constructor() { }

}
