import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { InsuranceSearchDto } from '@vecib2c/frontend-dto';
import { CircuitListReqDto, HotelRoomDistribution } from '@vecib2c/frontend-dto';
import { PaxesService } from '../../paxes/index';
import Pax from '../../paxes/Pax';
import { AirportService, AutocompleteService, Circuitsv2Service } from '../../lib-shared/services/index';
import { numberNigthsCircuits } from '../../../mock/circuits';
import * as moment_ from 'moment';
import { InsurancesProviderService } from '../../lib-shared/services/insurance-provider.service';

const moment = moment_;

@Component({
    selector: 'lib-circuit-search',
    templateUrl: './circuit-search.component.html',
    styleUrls: ['./circuit-search.component.scss'],
    providers: [
        AutocompleteService,
        InsurancesProviderService,
        { provide: 'circuits-provider', useValue: 'globalia' },
    ]
})
export class CircuitSearchComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @Input() isSmall = false;
    @Input() inputFields;
    @Input() routerPath = '/integration/circuits/search?';
    @Input() microsite = false;

    form: FormGroup;
    originOptions$: Observable<any>;
    countryOptions: any;
    continents: Array<any>;
    categorySelect: Array<any>;
    insuranceSearchDto: InsuranceSearchDto;
    occupantsValue = '';
    pax: Pax;
    circuitListReq: CircuitListReqDto;
    iataSelected: string;
    checkInCtrl: FormControl;
    selectedDate;
    listResidences;
    minDate = new Date();
    paxes: Array<Pax> = [];
    originCtrl: FormControl;
    countryCtrl = new FormControl('', Validators.required);
    filteredOrigin: Observable<any[]>;
    filteredOptionsDestination: Observable<any>;
    valueOrigin: string;
    originSend: any;
    numberNigthsCircuits: any[];

    @Output() queryParams: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
    @Output() dataChanged = new EventEmitter<CircuitListReqDto>();

    constructor(
        public formBuilder: FormBuilder,
        public autocomplete: AutocompleteService,
        public translate: TranslateService,
        public paxService: PaxesService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private airportService: AirportService,
        private circuitsv2Service: Circuitsv2Service,
        public insuranceProviderService: InsurancesProviderService,
    ) {
        this.originCtrl = new FormControl();
        this.insuranceSearchDto = new InsuranceSearchDto();
        this.insuranceSearchDto.residence = 'ESP';
        this.circuitListReq = new CircuitListReqDto();
        this.checkInCtrl = new FormControl(new Date(), Validators.required);
        this.circuitsv2Service.getDestinations()
            .subscribe(data => {
                this.listResidences = data;
                this.filteredOptionsDestination = this.countryCtrl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterDestination(value))
                );
            });
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');
        this.paxes.push(new Pax(1, 2, []));
        this.initForm();

        this.continents = [
            { name: 'America' },
            { name: 'Europa' },
            { name: 'Asia' },
            { name: 'Africa' },
            { name: 'Ocean�a' },
        ];

        this.categorySelect = [
            { name: 'NORMAL' },
            { name: 'OFERTA' },
            { name: 'SENIOR' },
            { name: 'PRODPLUS' },
            { name: 'TODAS' }
        ];

        this.numberNigthsCircuits = numberNigthsCircuits;

        this.getCountryAutocompleteData();
    }

    displayFnDestination = (option) => {
        if (!option) { return; }
        if (typeof option !== 'string') {
            return option.nombre;
        } else {
            return option;
        }
    }

    private _filterDestination(value): any {
        let filterValue;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.nombre.toLowerCase();
            return;
        }
        if (filterValue.length > 1) {
            return this.listResidences.filter(option => {
                return option.nombre.toLowerCase().indexOf(filterValue) === 0;
            });
        }
    }

    ngAfterViewInit() {

        if (this.inputFields) {
            this.circuitListReq = this.inputFields;
            this.setPaxFromQuery(this.circuitListReq.rooms);
            this.setDatesFromQuery();
            this.setNightsFromQuery();
            this.setPlacesFromQuery();
            this.setCategoryFromQuery();
            this.setCountryFromQuery();
        }
        this.filteredOrigin = this.originCtrl.valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(value => value.length > 1),
                switchMap(city => this.filterCities(city))
            );

    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    filterCities(city: string) {
        return this.airportService.search(city);
    }

    initForm() {
        this.form = this.formBuilder.group({
            origin: ['', Validators.required],
            // continent: ['', Validators.required],
            category: ['', Validators.required],
            country: [''],
            duration: [''],
            rooms: [this.paxes, Validators.required],
        });
    }

    getCountryAutocompleteData() {
        this.circuitsv2Service.getDestinations().subscribe(data => {
            this.countryOptions = data;
        });
    }

    search() {

        if (!this.form.valid) {
            return;
        }

        this.setPaxes();
        this.setCountry();
        this.setDates();
        this.setNights();
        this.setPlaces();
        this.setCategory();
        this.dataChanged.emit(this.circuitListReq);

        if (this.microsite) {
            this.queryParams.emit(this.getParams());
            return;
        }

        this.router.navigateByUrl(this.routerPath + this.getParams());
    }

    setCountry() {
        if (this.countryCtrl.value.nombre) {
            this.circuitListReq.country = this.countryCtrl.value.nombre;
        } else {
            this.circuitListReq.country = this.inputFields.country;
        }
    }

    setPaxes() {
        const circuitRooms: HotelRoomDistribution[] = [];
        this.paxes.map((pax: Pax) => {
            circuitRooms.push({
                'rooms': pax.rooms,
                'adults': pax.adults,
                'children': pax.children
            });
        });
        this.circuitListReq.rooms = circuitRooms;
    }

    setDates() {
        this.circuitListReq.date = moment(this.checkInCtrl.value).format('YYYY-MM-DD');
    }

    setNights() {
        this.circuitListReq.nights = this.form.controls['duration'].value === 'TODAS' ? '' : this.form.controls['duration'].value;
    }

    setPlaces() {
        this.circuitListReq.departure = this.iataSelected;
    }

    setCategory() {
        this.circuitListReq.category = this.form.controls['category'].value === 'TODAS' ? '' : this.form.controls['category'].value;
    }

    setPaxFromQuery(distribution) {
        this.paxes = distribution;
    }

    setDatesFromQuery() {
        this.selectCheckIn(moment(this.circuitListReq.date, 'YYYY-MM-DD').toDate());
    }

    setNightsFromQuery() {
        this.form.controls['duration'].setValue(parseInt(this.circuitListReq.nights.toString(), 10) || '');
    }

    setPlacesFromQuery() {
        const origin = this.inputFields.origin.split(',');
        if (origin) {
            this.form.controls['origin'].setValue({
                'city': origin[1],
                'name': origin[0],
                'iata': this.circuitListReq.departure,
                'country': origin[2]
            });
        }

        this.originSend = this.inputFields.origin;
    }

    setCategoryFromQuery() {
        this.form.controls['category'].setValue(this.circuitListReq.category);
    }

    setCountryFromQuery() {
        this.form.controls['country'].setValue(this.circuitListReq.country);
    }

    selectionEvent(key: string, value): void {
        this.form.get(key).setValue(value);
    }

    getParams(): HttpParams {
        const query = new HttpParams()
            .set('nights', this.circuitListReq.nights.toString())
            .set('date', this.circuitListReq.date)
            .set('language', this.translate.currentLang || 'ESP')
            .set('country', this.circuitListReq.country)
            .set('category', this.circuitListReq.category)
            .set('departure', this.circuitListReq.departure)
            .set('rooms', JSON.stringify(this.circuitListReq.rooms))
            .set('origin', this.originSend);
        return query;
    }

    displayFn = (origin) => {
        if (origin == null) {
            return;
        }
        this.iataSelected = origin.iata;
        return origin.name + ", " + origin.city + ", " + origin.country;
    }

    addPax() {
        this.paxes.push(new Pax(1, 2, []));
    }

    onPaxChanged(pax) {
        this.paxes[pax.position] = pax.newPax;
    }

    onRemovePax(position) {
        const newPaxes = [];
        this.paxes.forEach((pax: Pax, index) => {
            if (index !== position) {
                newPaxes.push(pax);
            }
        });
        this.paxes = newPaxes;
    }

    onEnterOrigin(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.origin = this.displayFn(reqIATA);
            this.originSend = newValueForm.origin;
            this.form.setValue(newValueForm);
            this.form.controls['origin'].setValue(reqIATA);
        }
    }

    selectCheckIn(date: Date): void {
        this.checkInCtrl.setValue(date);
        this.checkInCtrl.markAsTouched();
    }

}
