import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, } from '@angular/core';
import { IPriceRangeFilter } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-train-filter',
    templateUrl: './train-filter.component.html',
    styleUrls: ['./train-filter.component.scss']
})
export class TrainFilterComponent implements OnInit, OnChanges, OnDestroy {

    @Input() currency: string;
    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Output() changePriceFilter = new EventEmitter<IPriceRangeFilter>();

    lowerUpperPrices: IPriceRangeFilter;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.minPrice && changes.maxPrice) {
            this.lowerUpperPrices = {
                lower: changes.minPrice.currentValue,
                upper: changes.maxPrice.currentValue,
            };
        }
    }

    ngOnDestroy(): void {
    }

    updatePriceRange(priceFilter: IPriceRangeFilter) {
        this.changePriceFilter.emit(priceFilter);
    }

}
