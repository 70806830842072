import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAddEditModule } from "../../form-add-edit/index";
import { OtherServiceBookingComponent } from './other-services-booking.component';

@NgModule({
  declarations: [OtherServiceBookingComponent],
  imports: [
    CommonModule,
    FormAddEditModule
  ]
})
export class OtherServiceBookingModule { }
