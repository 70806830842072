import { IMedia } from "@vecib2c/frontend-dto";

export class ActivityImageMapper {

    static getImageURLBySize(media: IMedia, size = 'RAW'): string {
        if (!media) { return ''; }
        const { images } = media;
        if (!images) { return ''; }
        const image = images[0];
        if (!image) { return ''; }
        const urlFound = image.urls.find(url => url.sizeType === size);
        if (!urlFound) { return ''; }
        return urlFound.resource;
    }

 
}
