import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-activities-paxes',
  templateUrl: './activities-paxes.component.html',
  styleUrls: ['./activities-paxes.component.scss']
})
export class ActivitiesPaxesComponent implements OnInit {

    @Input() paxes: number[];
    @Input() isSmall: boolean;

    @Output() updatePaxes = new EventEmitter<number[]>();

    constructor() {
    }

    ngOnInit(): void {
    }

    updateAgePax(position: number, age: number): void {
        this.paxes[position] = age;
        this.updatePaxes.emit(this.paxes);
    }

    addPax(): void {
        this.paxes = [...this.paxes, 30];
        this.updatePaxes.emit(this.paxes);
    }

    deletePax(position: number): void {
        this.paxes = this.paxes.filter((pax, i) =>  i !== position);
        this.updatePaxes.emit(this.paxes);
    }

    increaseAge(position: number, age: number): void {
        this.updateAgePax(position, age + 1);
    }

    decreaseAge(position: number, age: number): void {
        if (age <= 18 && position === 0) { return; }
        if (age <= 0) { return; }
        this.updateAgePax(position, age - 1);
    }
}
