import { Pipe, PipeTransform } from "@angular/core";
import { safeNumber } from "../functions/index";

@Pipe({
    name: 'safeNumber'
})
export class SafeNumberPipe implements PipeTransform {

    transform(val: string | number): number {
        if (!val) { return; }
        return safeNumber(val);
    }
}
