import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import {_MatMenuDirectivesModule} from '@angular/material/menu';
import { SharedModule } from '../../shared.module';
import { IonicModule } from '@ionic/angular';


@NgModule({
    declarations: [FooterComponent],
    imports: [
        SharedModule,
        IonicModule
    ],
    exports: [
        FooterComponent
    ]
})
export class FooterModule {
}
