import { NgModule } from '@angular/core';
import { FlightFilterComponent } from './flight-filter/flight-filter.component';
import { FlightPaxesComponent } from './flight-paxes/flight-paxes.component';
import { FlightsItemCardComponent } from './flights-item-card/flights-item-card.component';
import { FlightsItemItineraryComponent } from './flights-item-itinerary/flights-item-itinerary.component';
import { SegmentItemItinaryComponent } from './segment-item-itinerary/segment-item-itinerary.component';
import { IonicModule } from '@ionic/angular';
import {
    MatCardModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlightDetailComponent } from './flight-detail/flight-detail.component';
import { FlightMultiflightItemCardComponent } from './flight-multiflight-item-card/flight-multiflight-item-card.component';
import { MdePopoverModule } from '@material-extended/mde';
import { GetDateBySegmentPipe } from './get-date-by-segment.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FilterBySegmentPipe } from './filter-by-segment.pipe';
import { DatePickerModule } from '../../date-picker/index';
import { LuggageSelectModule } from '../../luggage-select/index';
import { FlightProviderService, LibSharedModule } from '../../lib-shared/index';
import { FlightPriceSummaryComponent } from './flight-price-summary/flight-price-summary.component';
import { AmountByPassengerPipe } from './amount-by-passenger.pipe';
import { TotalAmountByPassengerPipe } from './total-amount-by-passenger.pipe';
import { TotalByFlightPipe } from './total-by-flight.pipe';
import { MunicipalityAutocompleteModule } from "../../municipality-autocomplete/municipality-autocomplete.module";
import { FlightFilterHeaderComponent } from './flight-filter/flight-filter-header/flight-filter-header.component';
import { MaterialDatePickerModule } from "../../material-date-picker/material-date-picker.module";

@NgModule({
    declarations: [
        FlightFilterComponent,
        FlightPaxesComponent,
        FlightsItemCardComponent,
        FlightsItemItineraryComponent,
        SegmentItemItinaryComponent,
        FlightDetailComponent,
        FlightMultiflightItemCardComponent,
        FilterBySegmentPipe,
        GetDateBySegmentPipe,
        FlightPriceSummaryComponent,
        AmountByPassengerPipe,
        TotalAmountByPassengerPipe,
        TotalByFlightPipe,
        FlightFilterHeaderComponent,
    ],
    imports: [
        ReactiveFormsModule,
        IonicModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        DatePickerModule,
        LuggageSelectModule,
        MatTooltipModule,
        MatToolbarModule,
        MdePopoverModule,
        MatDatepickerModule,
        LibSharedModule,
        MunicipalityAutocompleteModule,
        FormsModule,
        MaterialDatePickerModule,
    ],
    providers: [
        AmountByPassengerPipe,
        TotalAmountByPassengerPipe,
        FlightProviderService,
    ],
    exports: [
        FlightFilterComponent,
        FlightPaxesComponent,
        FlightsItemCardComponent,
        FlightsItemItineraryComponent,
        SegmentItemItinaryComponent,
        FlightDetailComponent,
        FlightMultiflightItemCardComponent,
        FlightPriceSummaryComponent,
        FlightFilterHeaderComponent,
    ]
})
export class FlightsIntegrationModule {
}
