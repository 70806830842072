import { ActivityBookingPutReqDto } from "@vecib2c/frontend-dto";
import { Client } from "../client.model";

export class ActivityBookingParameterMapper {

    static build(
        client: Client,
        startDate: string,
        endDate: string,
        rateKey: string,
        sessionId:string,
    ): ActivityBookingPutReqDto {
        const { name, email, address } = client;
        return {
            language: 'es',
            clientReference: '',
            holder: {
                name,
                email,
                address,
                telephones: [''],
            },
            activities: [
                {
                    preferedLanguage: 'es',
                    serviceLanguage: 'es',
                    from: startDate,
                    to: endDate,
                    rateKey,
                    paxes: [],
                    sessionId: ""
                },
            ],
        };
    }
}
