import {
    FlightBookingPostReqDto,
    FlightRateUpdateResDto,
    ICabinBaggage,
    IFlightBookingPax,
    PrebookResDto,
    PriceGroup,
} from "@vecib2c/frontend-dto";
import { roundNumber, safeNumber } from "../../../lib-shared/functions/index";
import { IBookingItem } from '../../../lib-shared/interfaces/bookingItem.interface';
import { ShoppingBasketComment } from "../../../lib-shared/interfaces/shopping-basket.model";
import { FlightPaxMapper, IFlightPax } from "../../../lib-shared/interfaces/flightPax.interface";
import { IntegrationType } from "../../../lib-shared/interfaces/integration.types";
import { FlightState } from "../../../lib-shared/interfaces/flight/flight-state.model";
import { Rerate } from "../../../lib-shared/interfaces/rerate.model";
import { Client } from "../../../lib-shared/interfaces/client.model";
import {
    FlightPreBookingResponse,
    MultidestinationPassenger,
    MultidestinationPreBookingByLocation,
} from "../../../lib-shared/interfaces/multidestination/multidestination-pre-booking.model";
import { Location } from "../../../lib-shared/interfaces/multidestination/multidestination-list.model";
import * as  uuidv1_ from 'uuid/v1';
import {
    FlightBookingPaxFromMultidestinationFactory,
} from "../../../lib-shared/factories/flights/flight-booking-pax-from-multidestination.factory";
import { ManagementPax } from "../../../lib-shared";
import { FlightBookingPaxFromManagementFactory } from "../../../lib-shared/factories/flights/flight-booking-pax-from-management.factory";

const uuidv1 = uuidv1_;

export class ShoppingBasketFlight extends FlightBookingPostReqDto implements IBookingItem {
    uuid: string;
    client: any;
    price: number;
    clonedData: PriceGroup;
    cancelPolicies: string[];
    validateMessage?: string;
    checkForm?: boolean;
    comments?: ShoppingBasketComment;
    totalPrice?: number;
    cloned?: boolean;
    lowCostPreBooking?: PrebookResDto;
    distribution: IFlightPax;
    residents?: IFlightPax;
    failed?: boolean;
    cabinBaggage?: ICabinBaggage;
    cabinLuggagePrice?: number;
    luggagePrices?: number;
    integrationType: IntegrationType;
    reratedPrice?: number;
    originalPrice?: number;
    isSelected?: boolean;
    canBeBooked?: boolean;

    static initialize(flightState: FlightState, paxes?: IFlightBookingPax[]): ShoppingBasketFlight {
        const obj = {
            uuid: flightState.uuid,
            requestId: flightState.priceGroup.requestId,
            priceGroupId: flightState.priceGroup.id,
            itinerariesId: flightState.priceGroup.itineraries.map(i => i.id).join(','),
            client: {},
            paxes: paxes || [],
            price: flightState.priceGroup.total,
            clonedData: flightState.priceGroup,
            cancelPolicies: [],
            lowCostPreBooking: flightState.lowCostPreBooking,
            distribution: flightState.distribution,
            residents: flightState.residents,
            integrationType: 'FLIGHT',
        };
        return obj as ShoppingBasketFlight;
    }

    static rerate(
        product: ShoppingBasketFlight,
        reratingResponse: Rerate,
    ): ShoppingBasketFlight {
        const originalPrice = roundNumber(product.price);
        product.originalPrice = originalPrice;
        if (reratingResponse) {
            const { pvp, rateKey, canBeBooked, rawResponse } = reratingResponse;
            const { priceGroupId, lowCostPreBookingResponse } = rawResponse;
            const requestId = safeNumber(rateKey);
            product.reratedPrice = pvp;
            product.price = pvp;
            product.clonedData = this.buildPriceGroupFromRarating(
                product.clonedData,
                rawResponse,
            );
            product.requestId = requestId;
            product.priceGroupId = priceGroupId;
            product.lowCostPreBooking = lowCostPreBookingResponse;
            product.canBeBooked = canBeBooked;
        }
        return product;
    }

    static buildPriceGroupFromRarating(
        rawPriceGroup: PriceGroup,
        rawResponse: FlightRateUpdateResDto,
    ): PriceGroup {
        const {
            priceGroupId,
            commission,
            detailedPricing,
            cabinBaggagePrice,
            passengerBaggagePrice,
            requestId,
            ticketDetail,
            itineraries
        } = rawResponse;
        const {
            commissionRate,
            iva,
            fee,
        } = commission;
        const {
            commissionableRate,
            nonCommissionableRate,
        } = detailedPricing;
        const safeCommissionRate = safeNumber(commissionRate);
        const safeIva = safeNumber(iva);
        const safeFee = safeNumber(fee);
        const commissionableRateWithoutBaggage = this.calculateCommissionableRateWithoutBaggage(
            commissionableRate,
            cabinBaggagePrice,
            passengerBaggagePrice,
        );
        const calculatedCommission = commissionableRateWithoutBaggage * (safeCommissionRate / 100) * (1 + safeIva / 100);
        const calculatedPvp = commissionableRate + calculatedCommission + safeFee;

        const obj = {
            ...rawPriceGroup,
            ...ticketDetail,
            id: priceGroupId,
            requestId,
            iva,
            fee,
            commissionRate,
            commission: calculatedCommission,
            total: calculatedPvp,
            commissionableRate,
            nonCommissionableRate,
            itineraries: itineraries ? itineraries : rawPriceGroup.itineraries,
        };
        return obj;
    }

    static buildFromShoppingBasketByDestination(
        location: Location,
        client: Client,
        passengers: MultidestinationPassenger[],
        comments: ShoppingBasketComment,
        preBookingResponse: MultidestinationPreBookingByLocation,
        destinationIndex: number,
    ): ShoppingBasketFlight[] {
        if (!location) { return []; }
        const { flightResponse } = location;
        if (!flightResponse) { return []; }
        const flight = flightResponse.rows[0];
        if (!flight) { return []; }
        const flightPaxes = FlightBookingPaxFromMultidestinationFactory.buildFromMultidestinationPassengers(passengers, destinationIndex);
        const { lowCostFlightResponse } = preBookingResponse;
        return this.buildShoppingBaskets(flight, client, flightPaxes, comments, lowCostFlightResponse);
    }

    static buildShoppingBaskets(
        flight: PriceGroup,
        client: Client,
        flightPaxes: IFlightBookingPax[],
        comments: ShoppingBasketComment,
        lowCostFlightResponse: FlightPreBookingResponse,
    ): ShoppingBasketFlight[] {
        if (!flight) { return []; }
        const { requestId, id, itineraries, total, fareNotes } = flight;
        if (flight.isLowCost && !lowCostFlightResponse) { return []; }
        const arr = [{
            uuid: uuidv1(),
            requestId,
            priceGroupId: id,
            itinerariesId: itineraries.map(i => i.id).join(','),
            paxes: flightPaxes,
            price: total,
            clonedData: flight,
            cancelPolicies: fareNotes ? fareNotes.map(f => f.description) : [],
            distribution: FlightPaxMapper.buildFromFlightBookingPaxes(flightPaxes),
            totalPrice: total,
            comments,
            integrationType: 'FLIGHT',
            client,
            lowCostPreBooking: lowCostFlightResponse,
        }] as ShoppingBasketFlight[];
        return arr;
    }

    static initializeComments(
        item: ShoppingBasketFlight,
    ): ShoppingBasketFlight {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.price),
        };
        return item;
    }

    static splitByItinerary(
        item: ShoppingBasketFlight,
    ): ShoppingBasketFlight[] {
        if (item.clonedData.itineraries.length > 1) {
            const splittedBaskets = item.clonedData.itineraries.map((itinerary, i) => {
                const mappedClonedData: PriceGroup = Object.assign({}, item.clonedData, {
                    itineraries: [itinerary],
                });
                const mappedBasket: ShoppingBasketFlight = Object.assign({}, item, {
                    clonedData: mappedClonedData,
                    cloned: i > 0,
                });
                return mappedBasket;
            });

            return splittedBaskets;
        }
        return [item];
    }

    static updateFromManagementBudget(
        item: ShoppingBasketFlight,
        paxes: ManagementPax[],
    ): ShoppingBasketFlight {
        const mappedPaxes = FlightBookingPaxFromManagementFactory.build(paxes, item.paxes);
        const distribution = FlightPaxMapper.buildFromFlightBookingPaxes(mappedPaxes);

        const obj = {
            ...item,
            distribution,
            paxes: mappedPaxes,
        };
        return obj;
    }

    private static calculateCommissionableRateWithoutBaggage(
        rawCommissionableRate: number,
        cabinBaggagePrice = 0,
        passengerBaggagePrice = 0
    ): number {
        return rawCommissionableRate - cabinBaggagePrice - passengerBaggagePrice;
    }

}
