import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IMunicipality } from '../interfaces/index';
import { TecnoturisService } from '../../tecnoturis.service';
import ResponseData from "@vecib2c/frontend-dto/dist/dto/Circuits/ResponseData";

@Injectable()
export class MunicipalityService {

    constructor(
        public http: HttpClient,
        private tecnoturisService: TecnoturisService
    ) {
    }

    search(text): Observable<IMunicipality[]> {
        return this.http.get(this.getPath("municipalities") + `?text=${text}`)
            .pipe(
                map((res: ResponseData): IMunicipality[] => {
                    return res.data['rows'];
                })
            );
    }

    private getPath(action) {
        const list = [`${this.tecnoturisService.config.flights}`, "api/v1", action];
        return list.join("/");
    }
}
