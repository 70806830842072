import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getIntegerPartPrice'
})
export class GetIntegerPartPricePipe implements PipeTransform {

    transform(price: any, withDecimal = false, flightPrice?, safePrice?): any {
        price = Number(price);

        if (flightPrice)
            price += Number(flightPrice);
        if (safePrice)
            price += Number(safePrice);
        const stringGetPrice = price.toString();
        if (stringGetPrice.indexOf('.') > -1) {
            return stringGetPrice.substring(0, stringGetPrice.indexOf('.') );
        } else {
            if (withDecimal) {
                return price.toString()
            }
            return price;
        }
    }

}
