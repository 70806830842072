import { Component, Input } from "@angular/core";
import { ShopingBasketHotel } from '../../../integrations/hotels/dto/ShopingBasketHotel';
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-hotel-cart-item',
    templateUrl: './hotel-cart-item.component.html',
    styleUrls: ['./hotel-cart-item.component.scss']
})
export class HotelCartItemComponent {

    @Input() item: ShopingBasketHotel;

    constructor(public shoppingCartSummaryComponent: ShoppingCartSummaryComponent) {
    }

    getCategory(category): Array<Number> | void {
        if (category === undefined) {
            return;
        }
        const regexNumber = /\d+/;

        if (!category || !regexNumber.test(category.value)) {
            return;
        } else {
            const value = category.value.match(regexNumber)[0];
            const integerValue = parseInt(value, 10);
            return new Array(integerValue);
        }
    }
}
