import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import {
    IBookingService,
    PaymentMethod,
    Prepaid,
} from "../../../../lib-shared/index";

@Component({
    selector: 'lib-prepaid-list',
    templateUrl: './prepaid-list.component.html',
    styleUrls: ['./prepaid-list.component.scss']
})
export class PrepaidListComponent implements OnInit, OnDestroy {

    @Input() prepaids: Prepaid[];
    @Input() paymentMethods: PaymentMethod[];
    @Input() bookingServices: IBookingService[];

    fields = {
        "payment_method": "Método de pago",
        "payment_amount": "Importe pagado",
        "payment_date": "Fecha de pago",
        "booking_service": "Servicio",
        "actions": "Acciones"
    };
    displayedColumns = ['booking_service', 'payment_method', 'payment_amount', 'payment_date', 'actions'];

    @Output() remove = new EventEmitter<Prepaid>();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

    }


}
