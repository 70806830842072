import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-detail-service',
  templateUrl: './detail-service.component.html',
  styleUrls: ['./detail-service.component.scss']
})
export class DetailServiceComponent implements OnInit {

  @Input() hotel: any;

  constructor() { }

  ngOnInit() {
  }

  filterServiceFacilities(facilities) {
    return facilities.filter(facility => facility.groupId === "SERVICES"
        || facility.groupId === "SPORT" || facility.groupId === "RESTAURANT" || facility.groupId === 'OTHERS');
  }
}
