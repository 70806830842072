import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAddEditModule } from "../../form-add-edit/index";
import { TransferBookingComponent } from './transfer-booking.component';

@NgModule({
  declarations: [TransferBookingComponent],
  imports: [
    CommonModule,
    FormAddEditModule
  ]
})
export class TransferBookingModule { }
