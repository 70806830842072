import { Component, OnInit } from '@angular/core';
import { safesForm } from './safes.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-safes-booking',
    templateUrl: './safes-booking.component.html',
    styleUrls: ['./safes-booking.component.scss']
})
export class SafesBookingComponent implements OnInit {
    safesCrud = safesForm;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.safesForm) {
            this.safesCrud = this.tecnoturisService.config.forms.safesForm;
        }
    }

    ngOnInit() {
    }

}
