import { NgModule } from '@angular/core';
import { LibSharedModule } from '../../../lib-shared/index';
import { FilterPackagesSearchModule } from '../filter-packages-search/filter-packages-search.module';
import { FilterPackagesDisneySearchModule } from '../filter-packages-disney-search/filter-packages-disney-search.module';
import { DisneyPackageSearchComponent } from './disney-package-search.component';

@NgModule({
    declarations: [DisneyPackageSearchComponent],
    imports: [
        LibSharedModule,
        FilterPackagesSearchModule,
        FilterPackagesDisneySearchModule
    ],
    exports: [DisneyPackageSearchComponent]
})
export class DisneyPackageSearchModule {
}
