import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material';
import { CircuitFlightPack } from '@vecib2c/frontend-dto/dist';

@Component({
    selector: 'lib-circuits-flights-expansion-panel',
    templateUrl: './circuits-flights-expansion-panel.component.html',
    styleUrls: ['./circuits-flights-expansion-panel.component.scss']
})
export class CircuitsFlightsExpansionPanelComponent implements OnChanges {

    @Input() flights: CircuitFlightPack[];
    selectedFlight: CircuitFlightPack;
    @Output('sendSelectedFlight') sendSelectedFlight = new EventEmitter<CircuitFlightPack>();
    @ViewChild('matExpansionPanel', { static: false }) matExpansionPanel: MatExpansionPanel;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.flights) {
            if (this.flights && this.flights.length) {
                this.loadMatExpansionPanel();
            }
        }
    }

    loadMatExpansionPanel(index = 0): void {
        this.selectedFlight = this.flights[index];
    }

    select(index): void {
        this.loadMatExpansionPanel(index);
        this.matExpansionPanel.close();
        this.sendSelectedFlight.emit(this.flights[index]);
    }

    collapseHeight() {
        let size = '140px';
        if (window.innerWidth > 992) {
            size = size;
        } else {
            size = '200px';
        }
        return size;
    }

}
