import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TecnoturisService } from '../../../tecnoturis.service';

declare let L: any;

@Component({
    selector: "lib-leaflet-map",
    templateUrl: "./leaflet-map.component.html",
    styleUrls: ["./leaflet-map.component.scss"]
})
export class LeafletMapComponent implements OnInit {
    @ViewChild("map", { static: true }) public mapElement: ElementRef;
    @Input("latitude") public latitude: string;
    @Input("longitude") public longitude: string;

    private map: any;

    constructor(private tecnoturisService: TecnoturisService) {
    }

    ngOnInit(): void {
        const latLng: Array<string> = [this.latitude, this.longitude];
        this.map = L.map(this.mapElement.nativeElement,
            {
                center: latLng,
                zoom: 18,
                layers: [L.tileLayer(this.tecnoturisService.config.lefleat.tilesLayer)],
                zoomControl: true
            });
        new L.Marker(latLng).addTo(this.map);
    }

}
