import { OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlightSearchDto } from '../searches/flights/index';
import { FlightItinerary, FlightListReqDto, FlightListResDto, PriceGroup } from '@vecib2c/frontend-dto/dist';
import { Subject } from 'rxjs';
import { IAirport } from '../lib-shared/interfaces/index';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FlightProviderService, IntegrationsService } from '../lib-shared/services/index';
import { takeUntil } from 'rxjs/operators';
import { MatRadioButton } from '@angular/material/radio';

export class FlightSearchList implements OnInit, OnDestroy {
    PAGE_START = 0;
    PAGE_SIZE = 10;

    inputFields: FlightSearchDto;
    search = false;
    filters = false;
    UrlSearchParameters = [
        "origin",
        "destination",
        "checkIn",
        // "checkOut",
        "adults",
        // "children",
        "originCity",
        "originCountry",
        "originAirport",
        "destinationCity",
        "destinationCountry",
        "destinationAirport",
        "departureIsCity",
        "arrivalIsCity",
        "adultResidents",
        "childrenResidents",
        "infantResidents",
    ];
    totalItems: number;
    rows: PriceGroup[];
    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 15];
    request: FlightListReqDto = new FlightListReqDto();
    lowCost = false;
    tourOrientation = false;
    oldRows: any = [];
    staticRows: PriceGroup[];
    multiflight = false;
    itinerariesMax: number;
    segmentsMultiflights: { origin: string, destination: string }[];
    onlyGoing = false;
    unsubscribe = new Subject();
    segmentsFromQuery: { origin: IAirport, destination: IAirport, dateAt: string }[];
    selectedPriceGroup: PriceGroup;


    @ViewChild("segmentOrigin", { static: false }) segmentOrigin: MatRadioButton;
    @ViewChild("segmentDestination", { static: false }) segmentDestination: MatRadioButton;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService,
        public flightService: FlightProviderService,
        public integrationService: IntegrationsService
    ) {
        this.integrationService.setMetaData({
            name: "flights",
            category: "Vuelos"
        });
    }

    ngOnInit() {
        this.translate.setDefaultLang("es");
        this.route.queryParams
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                if (this.isAValidQueryString(params)) {
                    this.inputFields = this.buildDto(params);
                    this.onlyGoing = this.inputFields.going;
                    if (this.onlyGoing) {
                        this.segmentsMultiflights = [{origin: this.inputFields.origin, destination: this.inputFields.destination}];
                    }
                    this.lowCost = this.inputFields.includedLowCost;
                    this.tourOrientation = this.inputFields.includedTourOperation;

                    this.getList();
                } else {
                    this.segmentsFromQuery = JSON.parse(params['segments'] || null) || [];
                    this.getMultiflights(params);
                }
            });
    }

    getMultiflights(params): void {
        this.clearFlights();
        this.multiflight = true;

        this.request = this.mapMultiflightRequest(params);
        this.segmentsMultiflights = this.segmentsFromQuery.map(s => {
            return {origin: s.origin.iata, destination: s.destination.iata};
        });
        this.flightService.multipleFlights(this.request)
            .subscribe(value => {
                this.totalItems = value.count;
                this.staticRows = value.rows;
                this.oldRows = value.rows;
                this.rows = this.paginate(this.currentPageNumber, this.currentPageSize);
            });
    }

    mapMultiflightRequest(params): FlightListReqDto {
        this.multiflight = true;
        const multiflightQueryParam: FlightListReqDto = new FlightListReqDto();

        multiflightQueryParam.pax = JSON.parse(params['pax']);
        multiflightQueryParam.segments = this.segmentsFromQuery.map((segmentFromQuery) => {
            const segment = {
                origin: segmentFromQuery.origin.iata,
                destination: segmentFromQuery.destination.iata,
                dateAt: segmentFromQuery.dateAt,
                departureIsCity: false,
                arrivalIsCity: false,
            };

            if (segmentFromQuery.origin.name === 'Todos') {
                segment.origin = segmentFromQuery.origin.cityCode;
                segment.departureIsCity = true;
            }

            if (segmentFromQuery.destination.name === 'Todos') {
                segment.destination = segmentFromQuery.destination.cityCode;
                segment.arrivalIsCity = true;
            }

            return segment;
        });

        return multiflightQueryParam;
    }

    private clearFlights(): void {
        this.staticRows = [];
        this.oldRows = [];
        this.rows = [];
    }

    mapSegments(segments: { origin: string, destination: string, dateAt: string }[]) {
        return [segments];
    }

    isAValidQueryString(query: Params): boolean {
        let allParams = true;

        this.UrlSearchParameters.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                allParams = false;
            }
        });
        return allParams;
    }

        buildDto(query: Params): FlightSearchDto {
        let dto: FlightSearchDto = {
            origin: query["origin"],
            destination: query["destination"],
            checkIn: query["checkIn"],
            adults: Number(query["adults"]),
            children: query["children"],
            includedLowCost: JSON.parse(query["includedLowCost"]),
            includedTourOperation: JSON.parse(query["includedTourOperation"]),
            originCity: query["originCity"],
            originCountry: query["originCountry"],
            originAirport: query["originAirport"],
            destinationCity: query["destinationCity"],
            destinationCountry: query["destinationCountry"],
            destinationAirport: query["destinationAirport"],
            departureIsCity: JSON.parse(query["departureIsCity"]),
            arrivalIsCity: JSON.parse(query["arrivalIsCity"]),
            adultResidents: Number(query["adultResidents"]),
            childrenResidents: Number(query["childrenResidents"]),
            infantResidents: Number(query["infantResidents"]),
        };
        if (query['going']) {
            dto = {...dto, going: true};
        }
        if (query['checkOut']) {
            dto = {...dto, checkOut: query['checkOut']};
        }
        return dto;
    }

    getList() {
        this.clearFlights();
        this.multiflight = false;
        this.request.origin = this.inputFields.origin;
        this.request.destination = this.inputFields.destination;
        this.request.checkIn = this.inputFields.checkIn;
        this.request.departureIsCity = this.inputFields.departureIsCity;
        this.request.arrivalIsCity = this.inputFields.arrivalIsCity;
        this.request.checkOut = this.inputFields.checkOut;

        this.request.pax = this.buildPaxForRequest(
            this.inputFields.adults,
            this.inputFields.children
        );

        this.request.includedLowCost = this.inputFields.includedLowCost;
        this.request.includedTourOperation = this.inputFields.includedTourOperation;

        this.request.adultResidents = this.inputFields.adultResidents;
        this.request.childrenResidents = this.inputFields.childrenResidents;
        this.request.infantResidents = this.inputFields.infantResidents;

        this.flightService.list(this.request)
            .subscribe((data: FlightListResDto) => {
                    this.totalItems = data.count;

                    this.staticRows = data.rows;
                    // this.oldRows = data.rows.filter(el => el.itineraries.every(itinerary => itinerary.segments.length === 1));
                    this.oldRows = data.rows;
                    this.rows = this.paginate(this.currentPageNumber, this.currentPageSize);
                },
                err => {
                    console.log(err);
                }
            );
    }

    private buildPaxForRequest(adults: number, children: number[]) {
        let childrenCount = 0;
        let infantsCount = 0;
        const childrenAsString = Array.isArray(children)
            ? children.join()
            : children;
        const isInfant = (age: number) => age <= 1;
        const updateChildrenCount = (age: string) => {
            if (isInfant(Number.parseInt(age, 10))) {
                infantsCount++;
            } else {
                childrenCount++;
            }
        };

        if (
            children &&
            children.length !== 0 &&
            childrenAsString.indexOf(",") === -1
        ) {
            updateChildrenCount(childrenAsString);
        } else if (childrenAsString && childrenAsString.indexOf(",") > -1) {
            childrenAsString.split(",").forEach((item: string) => {
                if (item.trim().length) {
                    updateChildrenCount(item);
                }
            });
        }

        return {
            adults: Number.parseInt(adults.toString(), 10),
            children: childrenCount,
            infants: infantsCount
        };
    }

    pageChanged($event, container) {
        container.el.scrollIntoView({block: "start", behavior: "smooth"});
        this.setPagination($event.pageIndex, $event.pageSize);
        this.paginate($event.pageIndex, $event.pageSize);
        this.rows = this.paginate($event.pageIndex, $event.pageSize);
    }

    paginate(index, size) {
        // transform values
        index = Math.abs(parseInt(index, 10));
        size = parseInt(size, 10);
        size = size < 1 ? 1 : size;

        // filter
        return [
            ...this.oldRows.filter((__, n) => {
                return n >= index * size && n < (index + 1) * size;
            })
        ];
    }

    onDataChanged($event) {
        this.staticRows = [];
        this.inputFields = $event;
        this.setPagination(this.PAGE_START, this.PAGE_SIZE);
        this.getList();
    }

    setPagination(pageNumber: number, pageSize: number) {
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
        this.paginate(pageNumber, pageSize);
    }

    toggleSearcher(nameElement) {
        switch (nameElement) {
            case 'search':
                this.search = !this.search;
                this.filters = false;
                break;
            case 'filters':
                this.filters = !this.filters;
                this.search = false;
                break;
        }
    }

    clearItineraries(itineraries: Array<number>) {
        if (itineraries[0] === undefined) {
            this.segmentOrigin.checked = true;
        } else if (itineraries[1] === undefined) {
            this.segmentDestination.checked = true;
        }
    }

    filteredObject(data) {
        this.oldRows = [];
        this.oldRows = data.rows;
        this.currentPageNumber = this.PAGE_START;
        this.rows = this.paginate(this.currentPageNumber, this.currentPageSize);
        this.totalItems = data.totalItems;
    }

    clearItinerariesMultiflight(priceGroup: PriceGroup, itineraryIndex: number): void {
        const selectedItinerary = priceGroup.itineraries[itineraryIndex];
        if (!this.selectedPriceGroup || this.selectedPriceGroup.id !== priceGroup.id) {
            this.selectedPriceGroup = Object.assign({}, priceGroup, {
                itineraries: new Array(this.segmentsMultiflights.length).fill(undefined),
            });
        }
        this.selectedPriceGroup.itineraries[selectedItinerary.segmentNumber - 1] = selectedItinerary;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
