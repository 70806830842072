import { Pipe, PipeTransform } from "@angular/core";
import { IAirport, City } from '../interfaces/index';

@Pipe({
    name: 'airportsToCities'
})
export class AirportsToCitiesPipe implements PipeTransform {

    transform(airports: IAirport[]): City[] {
        if (!airports) {
            return;
        }
        const cities = [];
        const cityCodes = {};
        airports.forEach((airport: IAirport) => {
            const key = airport.city + "-" + airport.country;
            if (airport.cityCode) {
                if (!cityCodes[key]) {
                    cityCodes[key] = airport.cityCode;
                    const city = {
                        code: airport.cityCode,
                        name: airport.city,
                        country: airport.country,
                        iata: null,
                        latitude: airport.latitude,
                        longitude: airport.longitude,

                    };
                    cities.push(city);
                }
            } else {
                if (!cityCodes[key]) {
                    const city = {
                        code: null,
                        name: airport.city,
                        country: airport.country,
                        iata: airport.iata,
                        latitude: airport.latitude,
                        longitude: airport.longitude,
                    };
                    cities.push(city);
                }
            }
        });

        return cities;
    }
}
