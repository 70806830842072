import { NgModule } from '@angular/core';
import { FilterPackagesDisneySearchComponent } from './filter-packages-disney-search.component';
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { PaxesPackageModule } from "../paxes-package/paxes-package.module";
import { MatExpansionModule } from "@angular/material/expansion";
import { FlightsSearchModule } from '../../flights/index';
import { LibSharedModule } from '../../../lib-shared/index';

@NgModule({
    declarations: [FilterPackagesDisneySearchComponent],
    exports: [
        FilterPackagesDisneySearchComponent
    ],
    imports: [
        LibSharedModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        FlightsSearchModule,
        MatInputModule,
        MatIconModule,
        PaxesPackageModule,
        MatExpansionModule
    ]
})
export class FilterPackagesDisneySearchModule {
}
