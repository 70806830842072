import {Component, Input, OnInit, OnDestroy} from "@angular/core";
import {BookEditComponent} from "../../book-edit.component";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {Location} from "@angular/common";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {map, takeUntil} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {TecnoturisService} from '../../../tecnoturis.service';
import {
    AuthenticationService,
    BasketManager,
    ClientServices,
    NotificationPopupService,
    TransportProviderService
} from '../../../lib-shared/services/index';
import {CarBookingRes, CarForm, ShoppingBasketCars} from '../../../lib-shared/interfaces/index';
import {AgencyChangeService} from '../../../agency-change/index';
import {ROLES} from '../../../const';
import t from 'typy';
import { CarBookingDtoReq } from "../../../lib-shared/interfaces/cars/booking.req.model";

@Component({
    selector: 'lib-cars-book-edit',
    templateUrl: './cars-book-edit.component.html',
    styleUrls: ['./cars-book-edit.component.scss'],
    providers: [
        TransportProviderService
    ]
})

export class CarsBookEditComponent extends BookEditComponent implements OnInit, OnDestroy {

    @Input() id;

    shoppingBasket: ShoppingBasketCars;
    form: FormGroup;
    gender: { value: string, label: string }[] = [
        {value: 'Mr.', label: 'Señor.'},
        {value: 'Mrs.', label: 'Señora.'},
    ];
    auth = AuthenticationService.getUser();

    private unsubscribe = new Subject();

    constructor(
        protected location: Location,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected notification: NotificationPopupService,
        protected translate: TranslateService,
        protected activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private transportProviderService: TransportProviderService,
        protected router: Router,
        protected _basketManager: BasketManager,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
        this.form = new FormGroup({
            driverTitle: new FormControl(),
            driverInitial: new FormControl(),
            driverLastName: new FormControl(),
            driverAge: new FormControl(),
        });
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(
                map(() => window.history.state),
                takeUntil(this.unsubscribe),
            )
            .subscribe(state => {
                if (state.cars) {
                    this.shoppingBasket = ShoppingBasketCars.initializeBasket(state.cars, this.id, state.extras, this.client);
                    this.initForm();
                } else {
                    const uuid = this.activatedRoute.snapshot.params['id'];
                    this._basketManager.getList().then(data => {
                        this.shoppingBasket = data.find(item => item.uuid === uuid);
                        if (this.shoppingBasket) {
                            this.initForm(this.shoppingBasket);
                        }
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    initForm(item?: ShoppingBasketCars): void {
        this.form = this.formBuilder.group({
            driverTitle: [t(item, 'driverTitle').safeString, Validators.required],
            driverInitial: [t(item, 'driverInitial').safeString, Validators.required],
            driverLastName: [t(item, 'driverLastName').safeString, Validators.required],
            driverAge: [t(item, 'driverAge').safeObject, Validators.required],
        });
    }

    addToBasketAndRedirect(redirectPath?: string): void {
        if (super.checkClient()) {
            this.shoppingBasket = this.completeBasket();

            this._basketManager.addOrUpdate(this.shoppingBasket)
                .then(() => {
                    const path = !redirectPath ? this.redirectToPath() : redirectPath;
                    this.router.navigateByUrl(path, {replaceUrl: true});
                });
        }
    }

    redirectToConfirmation(): string {
        return this.microsite ? '/bookings/confirmation' : '/integration/bookings/book-confirmation';
    }

    redirectToSummary(): string {
        return this.microsite ? '/bookings/summary' : '/intranet';
    }

    goToSummaryOrConfirmation() {
        this.shoppingBasket = this.completeBasket();

        const params = CarBookingDtoReq.build(
            this.shoppingBasket,
            this.id,
            this.client
        );

        this._basketManager.addOrUpdate(this.shoppingBasket)
            .then((data: Array<any>) => {
                if (data.length === 1) {
                    this.transportProviderService.confirmBooking(params, true, this.shoppingBasket)
                        .subscribe(data => {
                            let mappedBookingRes: CarBookingRes = new CarBookingRes();
                            const status = [200, 201];
                            mappedBookingRes.response = data;
                            if (!status.includes(data.status)) {
                                mappedBookingRes.failed = true;
                            }

                            mappedBookingRes = Object.assign(mappedBookingRes, this.shoppingBasket);

                            const navigationExtras: NavigationExtras = {
                                state: {
                                    bookingPostResDto: [mappedBookingRes]
                                }
                            };

                            const path = this.redirectToConfirmation();
                            this.router.navigate(
                                [path],
                                navigationExtras
                            );
                        });
                } else {
                    const path = this.redirectToSummary();
                    this.router.navigateByUrl(path);
                }
            });
    }

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    getForTimePeriod(time): string {
        if (time === "Daily") {
            return "/ Día";
        }
    }

    getPrice() {
        return Number(this.shoppingBasket.totalCharge).toFixed(2);
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }

    private completeBasket(): ShoppingBasketCars {
        const rawForm = this.form.getRawValue() as CarForm;
        return ShoppingBasketCars.addPassenger(this.shoppingBasket, rawForm);
    }
}
