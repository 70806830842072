import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { dossier2FormMetaData } from './form';

@Component({
    selector: 'lib-dossier-list',
    templateUrl: './dossier-list.component.html',
    styleUrls: ['./dossier-list.component.scss']
})
export class DossierListComponent implements OnInit, OnChanges {
    dynamicCrud;

    @Input() client: any;
    @Input() type = 1;

    queryParams = null;

    constructor(private router: Router) {
    }

    ngOnInit() {
        // dossierFormMetaData.urlPathList = dossierFormMetaData.urlPathList.replace('id', this.client);
        // this.dynamicCrud = dossierFormMetaData;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.client) {
            this.dynamicCrud = null;
            const crudData = JSON.parse(JSON.stringify(dossier2FormMetaData));
            crudData.urlPathList = crudData.urlPathList.replace('id', this.client);
            crudData.urlPathList = crudData.urlPathList + '?type=' + this.type;
            this.dynamicCrud = crudData;
            this.changeInputLabel(this.type);
            this.changeValuesStatus(+this.type);
        }
    }

    addDossier(): void {
        this.router.navigate(['intranet', 'management', 'dossier', 'create'], {
            queryParams: {
                client: this.client
            }
        });
    }

    changeInputLabel(type: number) {
        this.dynamicCrud.fields.map(field => {
            if (field.fieldChilds && field.fieldChilds.length > 0) {
                const codeField = field.fieldChilds.find(f => f.keyName === 'code');
                if (codeField) {
                    codeField.name = 'N° ' + this.getTypeDossier(+this.type);
                }
            }
        });
    }

    changeValuesStatus(type: number) {
        this.dynamicCrud.fields.map(field => {
            if (field.fieldChilds && field.fieldChilds.length > 0) {
                if (type === 1) {
                    this.hiddenOrShowTable(field.fieldChilds, 'dossier_budget_status', false);
                    this.hiddenOrShowTable(field.fieldChilds, 'dossier_status');
                    this.hiddenOrShowTable(field.fieldChilds, 'dossier_situation');
                } else {
                    this.hiddenOrShowTable(field.fieldChilds, 'dossier_budget_status');
                    this.hiddenOrShowTable(field.fieldChilds, 'dossier_status', false);
                    this.hiddenOrShowTable(field.fieldChilds, 'dossier_situation', false);
                }
            }
        });
    }

    hiddenOrShowTable(list, keyName, status = true) {
        const field = list.find(f => f.keyName === keyName);
        if (field) {
            field.showInTable = status;
        }
    }

    getTypeDossier(type: number) {
        return type === 1 ? 'Expediente' : 'Presupuesto';
    }
}
