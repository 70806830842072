import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
} from "@angular/core";
import Child from "./Child";
import Pax from "./Pax";
import { PaxesService } from './paxes.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "lib-paxes",
    templateUrl: "./paxes.component.html",
    styleUrls: ["./paxes.component.scss"]
})
export class PaxesComponent implements OnInit, OnDestroy {

    MIN_ROOMS = 1;
    MIN_AGE = 0;
    MAX_AGE = 11;
    MIN_ADULTS = 1;
    MIN_CHILDREN = 0;
    MAX_ROOMS = 30;
    MAX_ADULTS = 30;
    MAX_CHILDREN = 10;
    DEFAULT_CHILD_AGE = 11;

    @Input() isSmall = false;
    @Input() includeRooms = true;

    childrenAges: Array<Child>;

    private _rooms: number;
    private _adults: number;
    private _children: number;
    private pax;
    private unsubscribe = new Subject<void>();

    @Output() paxEvent = new EventEmitter<Pax>();

    constructor(
      private readonly paxesService: PaxesService,
    ) {
    }

    ngOnInit() {
        this.paxesService.currentPax
        .pipe(
            takeUntil(this.unsubscribe),
        ).subscribe(pax => {
            this.pax = pax;
            this.adults = this.pax.adults;
            this.children = this.pax.children.length;
            this.childrenAges = this.pax.children.map((age: number, index: number) => {
                return new Child(index, Number(age));
            });
            this.paxEvent.emit(this.pax);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get rooms() {
        return this._rooms;
    }

    set rooms(rooms: number) {
        this._rooms = this.range(rooms, this.MIN_ROOMS, this.MAX_ROOMS);
    }

    get adults() {
        return this._adults;
    }

    set adults(adults: number) {
        this._adults = this.range(adults, this.MIN_ADULTS, this.MAX_ADULTS);
    }

    get children() {
        return this._children;
    }

    set children(children: number) {
        this._children = this.range(children, this.MIN_CHILDREN, this.MAX_CHILDREN);
    }

    private range(value: number, min: number, max: number) {
        return Math.max(min, Math.min(value, max));
    }

    incRooms() {
        this.rooms++;
        this.emitPax();
    }

    decRooms() {
        this.rooms--;
        this.emitPax();
    }

    incAdults() {
        this.adults++;
        this.emitPax();
    }

    decAdults() {
        this.adults--;
        this.emitPax();
    }

    incChildren() {
        this.children++;
        this.incChildrenAges();
    }

    decChildren() {
        this.children--;
        this.decChildrenAges();
    }

    incChildrenAges() {
        const child = new Child(this.childrenAges.length, this.DEFAULT_CHILD_AGE);
        this.childrenAges = [...this.childrenAges, child];
        this.emitPax();
    }

    decChildrenAges() {
        this.childrenAges = this.childrenAges.slice(
            0,
            this.childrenAges.length - 1
        );
        this.emitPax();
    }

    downChildrenAge(i) {
        this.childrenAges[i].value--;
        this.emitPax();
    }

    upChildrenAge(i) {
        this.childrenAges[i].value++;
        this.emitPax();
    }

    onAgeChanged() {
        this.emitPax();
    }

    mapChildrenAges() {
        return this.childrenAges.map(it => it.value);
    }

    emitPax() {
        this.pax = new Pax(this.rooms, this.adults, this.mapChildrenAges());
        this.paxEvent.emit(this.pax);
    }


}
