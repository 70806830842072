import { PackageDistribution } from "../../interfaces/packages/bookings/request/bookings-request";
import { PackagePax } from "../../interfaces/packages/bookings/request/bookings-request";

export class PackageDistributionFactory {

  static build(
      packagePaxes: PackagePax[] = [],
  ): PackageDistribution {
    const obj = packagePaxes.reduce((acc, pax) => {
      if (pax.type === 'Adult') {
        return {
          ...acc,
          adults: acc.adults + 1,
        };
      }
      if (pax.type === 'Child') {
        return {
          ...acc,
          children: acc.children + 1,
        };
      }
      if (pax.type === 'Infant') {
        return {
          ...acc,
          infants: acc.infants + 1,
        };
      }
      return acc;
    }, { adults: 0, children: 0, infants: 0 } as PackageDistribution);
    return obj;
  }
}
