import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManagementService } from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-dossier-pax',
    templateUrl: './dossier-pax.component.html',
    styleUrls: ['./dossier-pax.component.scss']
})
export class DossierPaxComponent implements OnInit, OnChanges {
    @Input() client;

    @Input() paxes: Array<any>;

    paxForm: FormGroup;

    @Input() dossier: number;

    displayedColumns = ['name', 'last_name', 'type', 'actions'];

    @Output() added: EventEmitter<any> = new EventEmitter<any>();

    constructor(private fb: FormBuilder, private managementService: ManagementService) {
    }

    ngOnInit() {
        this.paxForm = this.createPaxForm();
        // this.getPaxes();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    createPaxForm(): FormGroup {
        return this.fb.group({
            name: [null, Validators.required],
            last_name: [null, Validators.required],
            type: [null, Validators.required],
        });
    }

    async getPaxes(): Promise<void> {
        this.paxes = await this.managementService.getPaxesFromDossier(this.dossier).toPromise();
    }

    addPax(): void {
        this.managementService.addPax(this.paxForm.getRawValue()).subscribe(pax => {
            this.managementService.addDossierPax({dossier: this.dossier, pax: pax.id})
                .subscribe(value => {
                    this.added.emit(true);
                });
        });
    }

}
