import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from '@angular/core';

@Component({
  selector: 'lib-flight-filter-header',
  templateUrl: './flight-filter-header.component.html',
  styleUrls: ['./flight-filter-header.component.scss']
})
export class FlightFilterHeaderComponent implements OnInit, OnDestroy {

  @Input() numberOfSegments: number;
  @Input() currentNumberOfSegment: number;
  @Input() label: string;
  @Input() showTitle: boolean;

  constructor(
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
