import { Component, OnInit, OnDestroy } from "@angular/core";
import { BookEditComponent } from "../../book-edit.component";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { FormArray, FormBuilder, Validators, FormGroup } from "@angular/forms";
import * as moment_ from 'moment';
import { TecnoturisService } from '../../../tecnoturis.service';
import { BasketManager, ClientServices, NotificationPopupService, ActivityService } from '../../../lib-shared/services/index';
import { AgencyChangeService } from '../../../agency-change/index';
import { ShoppingBasketActivity, ActivityPreBookingParameter, IntegrationTypeEnum } from "../../../lib-shared/interfaces/index";
import { t } from 'typy';
import { IActivityPaxAmount, ActivityBookingPutReqDto } from "@vecib2c/frontend-dto";

const moment = moment_;

@Component({
    selector: 'lib-activity-book-edit',
    templateUrl: './activity-book-edit.component.html',
    styleUrls: ['./activity-book-edit.component.scss'],
    providers: [
        ClientServices,
        ActivityService,
    ]
})
export class ActivityBookEditComponent extends BookEditComponent implements OnInit, OnDestroy {

    form: FormGroup;
    shoppingBasket: ShoppingBasketActivity;
    confirmedPrivacyPolicy = false;
    passengers: IActivityPaxAmount[] = [];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        protected location: Location,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected notification: NotificationPopupService,
        public router: Router,
        protected _basketManager: BasketManager,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService,
        private readonly activityService: ActivityService,
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
    }

    async ngOnInit(): Promise<void> {
        const id = this.route.snapshot.paramMap.get('id');
        this.shoppingBasket = await this.getShoppingBasketActivity(id);
        if (this.shoppingBasket) {
            this.initForm(this.shoppingBasket.bookingParameters);
        } else {
            const preBookingParameters = this.getPreBookingParametersFromURL(id);
            this.shoppingBasket = Object.assign({}, preBookingParameters, {
              uuid: id,
              client: null,
              integrationType: IntegrationTypeEnum.ACTIVITY,
            });
            this.initForm();
        }

        this.clientServices.subscription
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(client => {
            if (client) {
                this.form.controls['holder'].get('name').setValue(client.name);
                this.form.controls['holder'].get('surname').setValue(client.surname);
                this.form.controls['holder'].get('email').setValue(client.email);
                this.form.controls['holder'].get('address').setValue(client.address || '');
                this.form.controls['holder'].get('telephones').setValue([client.phoneNumber || '']);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    get activities(): FormArray {
        return <FormArray>this.form.get('activities');
    }

    getHolder(activityPosition: number, passengerPosition: number): void {
        if (this.client) {
            (this.activities.controls[activityPosition].get('paxes') as FormArray)
            .controls[passengerPosition].get('name').setValue(this.client.name);
            (this.activities.controls[activityPosition].get('paxes') as FormArray)
            .controls[passengerPosition].get('surname').setValue(this.client.surname);
        }
    }

    async addToShoppingCart(): Promise<void> {
        if (super.checkClient()) {
            this.completeShoppingBasket();
            const listFromStorage = await this._basketManager.addOrUpdate(this.shoppingBasket);
            this.router.navigateByUrl('/intranet', { replaceUrl: true });
        }
    }

    book(): void {
      if (super.checkClient()) {
          this.completeShoppingBasket();
          this._basketManager.addOrUpdate(this.shoppingBasket).then(() => {
              this._basketManager.getList().then(basket => {
                  if (basket.length === 1 && basket[0].uuid === this.shoppingBasket.uuid) {
                      this.activityService.confirmBooking(this.shoppingBasket.bookingParameters, true).toPromise().then(async(res) => {
 
                          const navigationExtras: NavigationExtras = {};
                          navigationExtras.state = {
                            bookingPostResDto: [res],
                            shoppingBasket: this.shoppingBasket
                          };

                          this.router.navigate(
                              ['/integration/bookings/book-confirmation'],
                              navigationExtras
                          );
                      }).catch(e => {
                          const navigationExtras: NavigationExtras = {};
                          navigationExtras.state = {
                              bookingPostResDto: [{ failed: true }],
                              shoppingBasket: this.shoppingBasket
                          };
                          this.router.navigate(
                              ['/integration/bookings/book-confirmation'],
                              navigationExtras
                          );
                      });
                  } else {
                      if (basket.length) {
                          const path = this.redirectToSummary();
                          this.router.navigateByUrl(path);
                      } else {
                          this.notificationPopupService.openPopup("EMPTY_BASKET");
                      }
                  }
              });
          });
      }
    }

    private async getShoppingBasketActivity(id: string): Promise<ShoppingBasketActivity> {
        const shoppingBaskets = await this._basketManager.getList() || [];
        return shoppingBaskets.find(s => s.uuid === id);
    }

    private getPreBookingParametersFromURL(id: string): ActivityPreBookingParameter {
      const decodedRawId = atob(id);
      const decodedId: { preBookingParameters: ActivityPreBookingParameter, pageId: string } = decodedRawId ?
          JSON.parse(decodedRawId) :
          null;
      const preBookingPrameters = decodedId.preBookingParameters;
      return preBookingPrameters;
    }

    private initForm(initialBookingParameters: ActivityBookingPutReqDto = null): void {
        this.form = this.formBuilder.group({
            language: [t(initialBookingParameters, 'language').safeObject || 'es', Validators.required],
            clientReference: [t(initialBookingParameters, 'clientReference').safeString],
            
            holder: this.formBuilder.group({
                name: [t(initialBookingParameters, 'holder.name').safeString, Validators.required],
                email: [t(initialBookingParameters, 'holder.email').safeString, Validators.required],
                address: [t(initialBookingParameters, 'holder.address').safeString],
                surname: [t(initialBookingParameters, 'holder.surname').safeString, Validators.required],
                telephones: [t(initialBookingParameters, 'holder.telephones').safeArray, Validators.required],
            }),
            activities: this.formBuilder.array([]),
        });

        if (initialBookingParameters && initialBookingParameters.activities) {
            initialBookingParameters.activities.forEach(activity => {
                this.activities.push(
                    this.formBuilder.group({
                        preferedLanguage: [activity.preferedLanguage, Validators.required],
                        serviceLanguage: [activity.serviceLanguage, Validators.required],
                        from: [activity.from, Validators.required],
                        to: [activity.to, Validators.required],
                        rateKey: [activity.rateKey, Validators.required],
                        paxes: this.formBuilder.array(activity.paxes.map(pax => {
                            return this.formBuilder.group({
                                name: [pax.name, Validators.required],
                                type: [pax.type, Validators.required],
                                surname: [pax.surname, Validators.required],
                                age: [pax.age, Validators.required],
                            });
                        })),
                    })
                );
            });
        } else {
            const paxesForm = this.shoppingBasket.paxes.map(age => {
                const paxAmount = this.shoppingBasket.paxAmounts.find(p => +age >= p.ageFrom && +age <= p.ageTo);
                return this.formBuilder.group({
                    name: ['', Validators.required],
                    surname: ['', Validators.required],
                    type: [paxAmount ? paxAmount.paxType : null, Validators.required],
                    age: [+age, Validators.required],
                });
            });

            this.activities.push(
                this.formBuilder.group({
                    preferedLanguage: ['es'],
                    serviceLanguage: ['es'],
                    name:  [this.shoppingBasket.contentName, Validators.required],
                    totalprice: [this.shoppingBasket.totalPrice, Validators.required],
                    from: [this.shoppingBasket.from, Validators.required],
                    to: [this.shoppingBasket.to, Validators.required],
                    rateKey: [this.shoppingBasket.rateKey, Validators.required],
                    paxes: this.formBuilder.array(paxesForm),
                   /*  sessionId:  [this.shoppingBasket.sessionId, Validators.required],
                    modalityName:  [this.shoppingBasket.modalityName, Validators.required],
                    pickUpDate:  [this.shoppingBasket.pickUpDate, Validators.required],
                    pickUpName:  [this.shoppingBasket.pickUpName, Validators.required], */
                })
            );    
        }
    }

    private completeShoppingBasket(): void {
        const rawForm = this.form.getRawValue() as ActivityBookingPutReqDto;
        this.shoppingBasket.bookingParameters = rawForm;
    }


}
