import { Component, Input } from "@angular/core";
import { HotelBookServices } from "../services/hotel-book.services";
import { NotificationPopupService } from '../../../lib-shared/services/index';

@Component({
    selector: 'lib-hotel-book-confirmation',
    templateUrl: './hotel-book-confirmation.component.html',
    styleUrls: ['./hotel-book-confirmation.component.scss']
})

export class HotelBookConfirmationComponent {
    @Input() hotelBookingConfirmation;
    isCancelled: boolean;

    constructor(
        private notificationPopupService: NotificationPopupService,
        protected _hotelBookServices: HotelBookServices
    ) {
        this.isCancelled = false;
    }

    cancelBooking() {
        this._hotelBookServices.cancel(this.hotelBookingConfirmation)
            .then((data) => {
                this.isCancelled = true;
                this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_CANCEL_BOOKING");
            })
            .catch(err => {
                this.isCancelled = false;
                console.error(err);
            });
    }
}
