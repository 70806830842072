import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightProviderService } from '../lib-shared/services/flight-provider.service';
import Pax from '../paxes/Pax';

const DEFAULT_NUMBERS_FLIGHTS = 2;
const DEFAULT_MAX_FLIGHTS = 5;

export interface IMultiflightReq {
    pax: { adults: number, children: number, infants: number };
    segments: [{ origin: string, destination: string, dateAt: string }, { origin: string, destination: string, dateAt: string }];
}

@Component({
    selector: 'lib-multiflight',
    templateUrl: './multiflight.component.html',
    styleUrls: ['./multiflight.component.scss'],
    providers: [FlightProviderService]
})
export class MultiflightComponent implements OnInit {
    form: FormGroup;

    @Input() isSmall;

    constructor(private fb: FormBuilder, private flightProviderService: FlightProviderService,
                private router: Router, private route: ActivatedRoute) {
        this.form = this.createForm();
        this.buildFromQueryParams();
    }

    ngOnInit() {
    }

    get segments(): FormArray {
        return this.form.get('segments') as FormArray;
    }

    get pax(): FormGroup {
        return this.form.get('pax') as FormGroup;
    }

    createForm(): FormGroup {
        const data = this.dataFromQuery();
        if (data) {
            return this.fb.group({
                pax: this.fb.group({
                    adults: [data['pax']['adults']],
                    children: [data['pax']['adults']],
                    infants: [0],
                }),
                segments: this.fb.array(data['segments'].map(segment => this.fb.group({
                    origin: [segment.origin, Validators.required],
                    destination: [segment.destination, Validators.required],
                    dateAt: [segment.dateAt, Validators.required]
                })))
            });
        }
        return this.fb.group({
            pax: this.fb.group({
                adults: [],
                children: [],
                infants: [0],
            }),
            segments: this.fb.array([])
        });
    }

    dataFromQuery() {
        if (this.route.snapshot.queryParams['pax']) {
            const multiflightQueryParam = {} as any;
            Object.keys(this.route.snapshot.queryParams)
                .forEach(k => multiflightQueryParam[k] = JSON.parse(this.route.snapshot.queryParams[k]));
            return multiflightQueryParam;
        }
        return null;
    }

    createDefaultSegments(): void {
        const segments = this.form.get('segments') as FormArray;
        for (let i = 0; i < DEFAULT_NUMBERS_FLIGHTS; i++) {
            segments.push(this.emptySegment());
        }
    }

    private emptySegment(): FormGroup {
        return this.fb.group({
            origin: [null, Validators.required],
            destination: [null, Validators.required],
            dateAt: [null, Validators.required]
        });
    }

    addSegment(): void {
        this.segments.push(this.emptySegment());
    }

    onPaxChanged(pax: Pax) {
        this.pax.get('adults').setValue(pax.adults);
        this.pax.get('children').setValue(pax.children.length);
    }

    removeSegment(index: number): void {
        this.segments.removeAt(index);
    }

    search(): void {
        const formData = this.form.getRawValue();
        formData.segments = formData.segments.map((segment) => {
            segment.origin = {
                id: segment.origin.id,
                iata: segment.origin.iata,
                city: segment.origin.city,
                country: segment.origin.country,
                name: segment.origin.name,
                cityCode: segment.origin.cityCode,
            };
            segment.destination = {
                id: segment.destination.id,
                iata: segment.destination.iata,
                city: segment.destination.city,
                country: segment.destination.country,
                name: segment.destination.name,
                cityCode: segment.destination.cityCode,
            };
            return segment;
        });
        formData.pax = JSON.stringify(formData.pax);
        formData.segments = JSON.stringify(formData.segments);
        this.router.navigate(['/integration/flights/multiflights/search'], {
            queryParams: formData
        });
    }

    buildFromQueryParams(): void {
        if (!this.route.snapshot.queryParams['pax']) {
            this.createDefaultSegments();
        }
    }


}
