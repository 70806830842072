import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyChangeComponent } from './agency-change.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { LibSharedModule } from '../lib-shared/index';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [AgencyChangeComponent],
    imports: [
        CommonModule,
        IonicModule,
        LibSharedModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule
    ],
    exports: [AgencyChangeComponent]
})
export class AgencyChangeModule {
}
