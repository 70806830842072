import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {NavigationExtras, Router} from "@angular/router";
import {FLIGHTS_AIRPORTS} from "../../../../mock/flights";
import * as  uuidv1_ from 'uuid/v1';
import {PrebookResDto, PriceGroup} from '@vecib2c/frontend-dto';
import {NotificationPopupService, FlightProviderService, MicrositeService} from '../../../lib-shared/services/index';
import {SERVICES} from '../../../const';
import { FlightState, PreBookingMapperRequest } from '../../../lib-shared/interfaces/index';

const uuidv1 = uuidv1_;

@Component({
    selector: "lib-flights-item-card",
    templateUrl: "./flights-item-card.component.html",
    styleUrls: ["./flights-item-card.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        FlightProviderService,
    ]
})
export class FlightsItemCardComponent implements OnInit {

    @Input() item: PriceGroup;
    @Input() pax;
    @Input() index: any;
    @Input() showPrice = true;
    @Input() flight: PriceGroup;
    @Input() adultResidents: number;
    @Input() childrenResidents: number;
    @Input() infantResidents: number;
    @Input() microsites = false;
    @Input() preBookingIsAvailable = true;

    itineraries: Array<number> = [];
    itineraryFrom: number;
    itineraryTo: number;
    airports;
    lowCostPreBookingResponse: PrebookResDto;

    @Output() flightSelected: EventEmitter<PriceGroup> = new EventEmitter<PriceGroup>();
    @Output() clearItineraries = new EventEmitter<any>();

    constructor(
        public router: Router,
        public notification: NotificationPopupService,
        private micrositeService: MicrositeService,
        private readonly flightService: FlightProviderService,
    ) {
    }

    ngOnInit() {
        this.airports = FLIGHTS_AIRPORTS;
    }

    async goToBookingPage(): Promise<void> {
        if (!this.validateCountItineraries(this.itineraries)) {
            this.notification.openPopup("Debe seleccionar ambos vuelos");
            return;
        }

        if (this.item.isLowCost) {
            const preBookingParameters = PreBookingMapperRequest.build(
                this.item.id,
                this.item.requestId,
                this.itineraries,
                this.pax.adults,
                this.pax.children,
                this.pax.infants,
            );
            this.lowCostPreBookingResponse = await this.flightService.preBookLowCost(preBookingParameters).toPromise();
        }

        const navigationExtras = this.buildNavigationExtras();

        if (this.micrositeService.isMicrosite()) {
            this.micrositeService.setBookingData(SERVICES.FLIGHT, {
                uuid: navigationExtras.state.flight.uuid,
                navigationExtras,
                item: this.item
            });
        } else {
            this.router.navigate(['integration', 'bookings', 'book-edit', 'flights', navigationExtras.state.flight.uuid], navigationExtras);
        }
    }

    buildNavigationExtras(): NavigationExtras {

        const flightState = FlightState.build(
            this.item,
            this.itineraries,
            this.pax,
            this.adultResidents,
            this.childrenResidents,
            this.infantResidents,
            uuidv1(),
            this.lowCostPreBookingResponse,
        );

        const navigationExtras: NavigationExtras = {
            state: {
                flight: flightState,
            }
        };
        return navigationExtras;
    }

    selectItinerary(props: { itineraryId: number, segmentNumber: number }): void {
        const { itineraryId, segmentNumber } = props;

        if (segmentNumber === 1) {
            this.itineraryFrom = itineraryId;
            const itineraryIndex = this.item.itineraries.findIndex(i => i.id === itineraryId);
            this.item.itineraries[itineraryIndex].checked = true;
        } else if (segmentNumber === 2) {
            const itineraryIndex = this.item.itineraries.findIndex(i => i.id === itineraryId);
            this.item.itineraries[itineraryIndex].checked = true;
            this.itineraryTo = itineraryId;
        }

        this.itineraries = [this.itineraryFrom, this.itineraryTo];
        this.clearItineraries.emit(this.itineraries);
        if (this.validateCountItineraries(this.itineraries)) {
            this.flightSelected.emit(this.item);
        }
    }

    private validateCountItineraries(itineraries: Array<number>): boolean {
        return itineraries.filter(Boolean).length === 2;
    }
}
