import { NgModule } from '@angular/core';
// import {
//     // CancellationPoliciesButtonModule,
//     // HotelDetailCardComponent,
//     // HotelsDetailNavbarComponent,
//     // HotelSearchCardComponent,
//     // ItemProviderDialogComponent
// } from '../hotels/index';

import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MdePopoverModule } from '@material-extended/mde';
import { MatToolbarModule } from '@angular/material';
import { HotelDetailUrlPipe, HotelProviderLogoPipe, LibSharedModule } from '../../lib-shared/index';
import { FilterModule } from '../../filter/filter.module';
import { CarouselImagesModule } from '../../carousel-images/carousel-images.module';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { LeafletMapModule } from '../shared/leaflet-map/leaflet-map.module';
import { CarouselModule } from '../../carousel/carousel.module';
import { PopoverPriceComponent } from './popover-price/popover-price.component';
import { RoomTableComponent } from './hotel-search-card/room-table/room-table.component';
import { HotelDetailCardComponent } from './hotel-detail-card/hotel-detail-card.component';
import { HotelSearchCardComponent } from './hotel-search-card/hotel-search-card.component';
import { ItemProviderDialogComponent } from './item-provider-dialog/item-provider-dialog.component';
import { CancellationPoliciesButtonModule } from './cancellation-policies-button/cancellation-policies-button.module';
import { HotelsDetailNavbarComponent } from './hotels-detail-navbar/hotels-detail-navbar.component';

@NgModule({
    declarations: [
        HotelDetailCardComponent,
        HotelSearchCardComponent,
        ItemProviderDialogComponent,
        HotelDetailUrlPipe,
        HotelProviderLogoPipe,
        HotelsDetailNavbarComponent,
        PopoverPriceComponent,
        RoomTableComponent,
    ],
    imports: [
        FormsModule,
        FilterModule,
        IonicModule,
        LeafletMapModule,
        CarouselModule,
        CancellationPoliciesButtonModule,
        CarouselImagesModule,
        MatToolbarModule,
        MdePopoverModule,
        LibSharedModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        MatDialogModule
    ],
    exports: [
        HotelDetailCardComponent,
        HotelSearchCardComponent,
        ItemProviderDialogComponent,
        CancellationPoliciesButtonModule,
        HotelsDetailNavbarComponent,
        RoomTableComponent,
        PopoverPriceComponent,
    ],
    providers: [
        TranslateService,
        {provide: MAT_DATE_LOCALE, useValue: MAT_DATE_FORMATS}
    ],
    entryComponents: [
        ItemProviderDialogComponent
    ]
})
export class HotelsModule {
}
