import { Component, Input } from "@angular/core";
import { ShoppingBasketTransfer } from "../../../lib-shared/interfaces/index";
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-transfer-cart-item',
    templateUrl: './transfer-cart-item.component.html',
    styleUrls: ['./transfer-cart-item.component.scss']
})
export class TransferCartItemComponent {

    @Input() item: ShoppingBasketTransfer;

    render: boolean;
    roundtrip: string; 

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }

    ngOnInit(){
        this.renderFromAndToDestination(this.item.from.transferTypeCode)
    }

    renderFromAndToDestination(type: string){
        if(type == "ROU"){
            this.render = true;
            this.roundtrip = "IDA Y VUELTA"
        } else {
            if(type == "ARR"){
                this.render = true
                this.roundtrip = "SOLO IDA"
            } 
            if(type == "DEP"){
                this.render = false
                this.roundtrip = "SOLO IDA"
            }
        }
    }
}