import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientCommercialActivitiesComponent } from './client-commercial-activities/client-commercial-activities.component';
// tslint:disable-next-line:max-line-length
import { ClientCommercialActivitiesAddEditComponent } from './client-commercial-activities/client-commercial-activities-add-edit/client-commercial-activities-add-edit.component';
import { ClientFilesComponent } from './client-files/client-files.component';
import { ClientsAddEditComponent } from './clients-add-edit/clients-add-edit.component';
import { DynamicCrudModule } from '../dynamic-crud/index';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DossierListModule } from '../dossier-list/dossier-list.module';
import { DateAdapterModule, LibSharedModule } from '../lib-shared/index';
import { ContactPersonComponent } from './contact-person/contact-person.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import {CommercialEventModule} from '../management/commercial-event/commercial-event.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CommercialEventComponent} from '../management/commercial-event/commercial-event.component';
import {MessageProviderService} from '../lib-shared/services/message-provider.service';

@NgModule({
    declarations: [
        ClientCommercialActivitiesComponent,
        ClientCommercialActivitiesAddEditComponent,
        ClientFilesComponent,
        ClientsAddEditComponent,
        ContactPersonComponent],
    imports: [
        CommonModule,
        DynamicCrudModule,
        FormlyModule,
        ReactiveFormsModule,
        IonicModule,
        DossierListModule,
        DateAdapterModule,
        LibSharedModule,
        MatCardModule,
        MatInputModule,
        RouterModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        CommercialEventModule,
        MatSnackBarModule
    ],
    exports: [
        ClientCommercialActivitiesComponent,
        ClientCommercialActivitiesAddEditComponent,
        ClientFilesComponent,
        ClientsAddEditComponent,
        ContactPersonComponent
    ],
    entryComponents: [
        ClientCommercialActivitiesAddEditComponent,
        CommercialEventComponent
    ],
    providers: [
        MessageProviderService
    ]
})
export class ManagementClientModule {
}
