import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { Hotel, HotelRoom, HotelRoomRate, HotelProvider, HotelRoomList } from '@vecib2c/frontend-dto';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'lib-room-table',
    templateUrl: './room-table.component.html',
    styleUrls: ['./room-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class RoomTableComponent implements OnInit, OnDestroy {

    @Input() hotel: Hotel;
    @Input() query;
    @Input() multidistribution: boolean;
    @Input() microsites: boolean;
    @Input() provider: HotelProvider;
    @Input() firstRoomIsCheckedByDefault = true;
    @Input() roomList: HotelRoomList[];

    displayedColumns = ['select', 'name', 'rate', 'price', 'cancellationPolicy', 'icon'];
    displayedSubColumns = ['select', 'sameRoom', 'rate', 'price', 'cancellationPolicy'];
    expandedElement: HotelRoom;
    moreRateButton = [];

    @Output() selectRoom = new EventEmitter<{ room: HotelRoom, roomListIndex: number, roomIndex: number }>();

    constructor(
    ) {
    }

    ngOnInit(): void {
      this.moreRateButton = this.hotel.roomList.map(list => {
        return list.rooms.map(r => false);
      });
    }

    ngOnDestroy(): void {

    }

    chooseRoom(room: HotelRoom, rate: HotelRoomRate, roomListIndex: number): void {
        const roomIndex = this.hotel.roomList[roomListIndex].rooms.indexOf(room);
        const mappedRoom = Object.assign({}, room, { rates: [rate] });
        this.selectRoom.emit({ room: mappedRoom, roomListIndex, roomIndex });
    }

    toggleMoreRates(roomListIndex: number, roomIndex: number, room: HotelRoom): void {
        this.moreRateButton = this.moreRateButton.map((buttons, i) => {
            return buttons.map((button, j) => {
                if (i === roomListIndex && j === roomIndex) {
                    return !button;
                }
                if (button) { return false; }
                return button;
            });
        });
        this.expandedElement = this.expandedElement === room ? null : room;
    }


}
