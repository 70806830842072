import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { ValidationMessage } from '../lib-shared/interfaces/index';

@Component({
    selector: 'lib-material-date-picker',
    templateUrl: './material-date-picker.component.html',
    styleUrls: ['./material-date-picker.component.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
})
export class MaterialDatePickerComponent implements OnInit, OnDestroy {

    @Input() label: string;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() defaultDate: Date;
    @Input() dateCtrl: FormControl;
    @Input() validations: ValidationMessage[];
    @Input() readonly: boolean;
    @Input() showValidation: boolean;
    @Input() allowedDays: number[];

    @Output() selectDate = new EventEmitter<Date>();

    constructor() {
    }

    ngOnInit(): void {
        if (!this.validations) {
            this.validations = [
                {key: 'required', message: 'Este campo es obligatorio'},
                {key: 'minDateValidator', message: 'Fecha inválida'},
                {key: 'maxDateValidator', message: 'Fecha inválida'},
            ];
        }
        const today = new Date();
        this.maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    }

    ngOnDestroy(): void {

    }

    setDateFromCalendar(matDatePicker: any): void {
        let formattedDate: Date;
        if (matDatePicker.value) {
            formattedDate = matDatePicker.value.toDate();
        }
        this.selectDate.emit(formattedDate);
    }

    openPicker(picker: MatDatepicker<any>): void {
        if (this.readonly) {
            picker.open();
        }
    }

    dateFilter = (d: moment.Moment) => {
        if (!this.allowedDays) { return true; }
            return this.allowedDays.indexOf(+d.toDate().getDate()) !== -1;
    }
}
