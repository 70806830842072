/* import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { IBookingItem } from '../interfaces/index';
import { ToastrService } from 'ngx-toastr';
import { MicrositeService } from './microsite.service';

// Service to manage the basket
// keep in storage a hotel list with one room and one rate per basket room,
// will be needed in next screen

@Injectable({
    providedIn: 'root'
})
export class BasketManager {
    private BASKET_STORAGE_KEY = 'basket';
    private subscription: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(private storage: Storage, private toastrService: ToastrService, private micrositeService: MicrositeService) {
        this.initializeBasket();
    }

    private clone(data) {
        return JSON.parse(JSON.stringify(data));
    }

    getSubscription() {
        return this.subscription;
    }

    initializeBasket() {
        this.storage.get(this.BASKET_STORAGE_KEY).then(data => {

            if (!data) {
                this.storage.set(this.BASKET_STORAGE_KEY, []);
                this.subscription.next([]);
            } else {
                this.subscription.next(data);
            }
        });
    }

    async validateMaxSizeBasket(): Promise<boolean> {
        const microsite = this.micrositeService.isMicrosite();
        if (microsite) {
            const basketList: any[] = await this.getList();
            if (basketList.length > 0) {
                return Promise.reject("No puede tener mas de un servicio");
            }
            return this.getList();
        }
        return this.getList();
    }

    // add new element or sum 1 to the existing one
    public async addOrUpdate(item: IBookingItem) {
        // @TODO esto tiene que manejar todo no solo un hotel
        return new Promise((resolve) => {
            this.validateMaxSizeBasket()
                .then(async (itemList: any = []) => {
                    const itemCloned = this.clone(item);
                    const uuid = itemCloned.uuid;
                    // update
                    let found = false;

                    for (let index = 0; index < itemList.length; index++) {
                        if (itemList[index].uuid === uuid) {
                            itemList[index] = itemCloned;
                            found = true;
                        }
                    }

                    // add if not found
                    if (!found) {
                        itemList.push(itemCloned);
                    }
                    this.subscription.next(itemList);
                    const list = await this.setDataBasket(itemList);
                    resolve(list);
                }).catch(e => {
                    this.toastrService.error("Solo puede agregar un item a la cesta!");
            });
        });
    }

    public setDataBasket(list): Promise<any> {
        this.subscription.next(list);
        return this.storage.set(this.BASKET_STORAGE_KEY, list);
    }

    public removeBasket() {
        this.storage.get(this.BASKET_STORAGE_KEY).then(() => {
            this.storage.set(this.BASKET_STORAGE_KEY, []);
            this.subscription.next([]);
        });
    }

    public getList(): Promise<any> {
        return this.storage.get(this.BASKET_STORAGE_KEY);
    }

    public remove(uuid) {
        this.getList().then(hotelList => {
            const hotels = hotelList.filter(hotel => hotel.uuid !== uuid);
            this.storage.set(this.BASKET_STORAGE_KEY, hotels);
            this.subscription.next(hotels);
        });
    }
}
 */

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { IBookingItem, ShoppingBasketType } from '../interfaces/index';
import { ToastrService } from 'ngx-toastr';
import { MicrositeService } from './microsite.service';

// Service to manage the basket
// keep in storage a hotel list with one room and one rate per basket room,
// will be needed in next screen

@Injectable({
    providedIn: 'root'
})
// CANDAMENA \\
export class BasketManager {
    private BASKET_STORAGE_KEY = 'basket';
    private BASKETPRE_STORAGE_KEY = 'basketpre';
    private subscription: BehaviorSubject<any> = new BehaviorSubject([]);
    private subscriptionPre: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(private storage: Storage, private toastrService: ToastrService, private micrositeService: MicrositeService) {
        this.initializeBasket();
    }

    private clone(data) {
        return JSON.parse(JSON.stringify(data));
    }

    getSubscription() {
        return this.subscription;
    }

    initializeBasket() {
        this.storage.get(this.BASKET_STORAGE_KEY).then(data => {
            
            if (!data) {
                this.storage.set(this.BASKET_STORAGE_KEY, []);
                this.subscription.next([]);
            } else {
                this.subscription.next(data);
            }
        });
        this.storage.get(this.BASKETPRE_STORAGE_KEY).then(data => {
            if (!data) {
                this.storage.set(this.BASKETPRE_STORAGE_KEY, []);
                this.subscriptionPre.next([]);
            } else {
                this.subscriptionPre.next(data);
            }
        });
    }

    async validateMaxSizeBasket(): Promise<ShoppingBasketType[]> {
        const microsite = this.micrositeService.isMicrosite();
        if (microsite) {
            const basketList = await this.getList();
            if (basketList.length > 0) {
                return Promise.reject("No puede tener mas de un servicio");
            }
            return this.getList();
        }
        return this.getList();
    }

    async validateMaxSizeBasketPrebooking(): Promise<ShoppingBasketType[]> {
        /* const microsite = this.micrositeService.isMicrosite();
        if (microsite) {
            const basketList = await this.getListPrebooking();
            if (basketList.length > 0) {
                return Promise.reject("No puede tener mas de un servicio");
            }
            return this.getListPrebooking();
        } */
        return this.getListPrebooking();
    }

    // add new element or sum 1 to the existing one
    public async addOrUpdate(item: IBookingItem) {
        // @TODO esto tiene que manejar todo no solo un hotel
        return new Promise((resolve) => {
            this.validateMaxSizeBasket()
                .then(async (itemList = []) => {
                    const itemCloned = this.clone(item);
                    const uuid = itemCloned.uuid;
                    // update
                    let found = false;

                    for (let index = 0; index < itemList.length; index++) {
                        if (itemList[index].uuid === uuid) {
                            itemList[index] = itemCloned;
                            found = true;
                        }
                    }

                    // add if not found
                    if (!found) {
                        itemList.push(itemCloned);
                    }
                    this.subscription.next(itemList);
                    const list = await this.setDataBasket(itemList);
                    resolve(list);
                }).catch(e => {
                    this.toastrService.error("Solo puede agregar un item a la cesta!");
            });
        });
    }
    public async addPrebooking(item: IBookingItem) {
        // @TODO esto tiene que manejar todo no solo un hotel
        return new Promise((resolve) => {
            this.validateMaxSizeBasketPrebooking()
                .then(async (itemList = []) => {
                    const itemCloned = this.clone(item);
                    const uuid = itemCloned.uuid;
                    
                    // update
                    let found = false;

                    for (let index = 0; index < itemList.length; index++) {
                        if (itemList[index].uuid === uuid) {
                            itemList[index] = itemCloned;
                            found = true;
                        }
                    }

                    // add if not found
                    if (!found) {
                        itemList.push(itemCloned);
                    }
                    this.subscriptionPre.next(itemList);
                    const list = await this.setDataBasketPrebooking(itemList);
                    resolve(list);
                }).catch(e => {
                    this.toastrService.error("Solo puede agregar un item a la cesta!");
            });
        });
    }

    public async addPrebookingMicrosites(item: IBookingItem) {
        // @TODO esto tiene que manejar todo no solo un hotel
        return new Promise((resolve) => {
            this.validateMaxSizeBasketPrebooking()
                .then(async (itemList = []) => {
                    const itemCloned = this.clone(item);
                    const uuid = itemCloned.uuid;
                    
                    // update
                    let found = false;

                    for (let index = 0; index < itemList.length; index++) {
                        if (itemList[index].uuid === uuid) {
                            itemList[index] = Object.assign({},itemList[index],itemCloned )
                            found = true;
                        }
                    }

                    // add if not found
                    if (!found) {
                        itemList.push(itemCloned);
                    }
                    this.subscriptionPre.next(itemList);
                    const list = await this.setDataBasketPrebooking(itemList);
                    resolve(list);
                }).catch(e => {
                    this.toastrService.error("Solo puede agregar un item a la cesta!");
            });
        });
    }

    public setDataBasket(list: ShoppingBasketType[]): Promise<any> {
        this.subscription.next(list);
        return this.storage.set(this.BASKET_STORAGE_KEY, list);
    }
    public setDataBasketPrebooking(list: ShoppingBasketType[]): Promise<any> {
        this.subscriptionPre.next(list);
        return this.storage.set(this.BASKETPRE_STORAGE_KEY, list);
    }

    public removeBasket() {
        this.storage.get(this.BASKET_STORAGE_KEY).then(() => {
            this.storage.set(this.BASKET_STORAGE_KEY, []);
            this.subscription.next([]);
        });
    }
    public removeBasketPreboking() {
        this.storage.get(this.BASKETPRE_STORAGE_KEY).then(() => {
            this.storage.set(this.BASKETPRE_STORAGE_KEY, []);
            this.subscription.next([]);
        });
    }

    public getList(): Promise<any[]> {
        return this.storage.get(this.BASKET_STORAGE_KEY);
    }
    public getListPrebooking(): Promise<any[]> {
        return this.storage.get(this.BASKETPRE_STORAGE_KEY);
    }

    public remove(uuid: string) {
        this.getList().then(hotelList => {
            const hotels = hotelList.filter(hotel => hotel.uuid !== uuid);
            this.storage.set(this.BASKET_STORAGE_KEY, hotels);
            this.subscription.next(hotels);
        });
    }
    public removePrebooking(uuid: string) {
        this.getListPrebooking().then(PrebookingList => {
            const Prebooking = PrebookingList.filter(hotel => hotel.uuid !== uuid);
            if (Prebooking.length === 0) {
                this.storage.set(this.BASKETPRE_STORAGE_KEY, []);
                
            } else {
                this.storage.set(this.BASKETPRE_STORAGE_KEY, Prebooking);
                this.subscription.next(Prebooking);
            }
        });
    }
}

