import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFilter } from '../lib-shared/interfaces/index';
import { HotelProviderService, IntegrationPageMetaData, IntegrationsService } from '../lib-shared/services/index';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelListReqDto } from '@vecib2c/frontend-dto/dist';
import { OnInit } from '@angular/core';

export class HotelSearchList implements OnInit {
    PAGE_START = 0;
    PAGE_SIZE = 10;

    options$: Observable<any>;
    data: any;
    form: FormGroup;
    rows;
    entity;
    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;
    totalItems = 0;
    pageSizeOptions: number[] = [5, 10, 15];
    hotelListReq: HotelListReqDto;
    searchShowHide = false;
    filtersShowHide = false;
    // Calendar range (can be isolated to a component), dont touch below this line unless related with the component
    urlSearchParameters = [
        'checkIn',
        'checkOut',
        'distribution',
        'locality',
        'province',
        'country',
        'latitude',
        'longitude'
    ];
    maxDistance;

    providers = [
        {
            id: 1,
            name: 'Todos los proveedores',
            entity: 'default'
        }
    ];

    filters: IFilter = {};
    minPrice: number;
    maxPrice: number;

    integrationMetaData: IntegrationPageMetaData;

    checkMessage: boolean;

    constructor(
        public hotelProviderService: HotelProviderService,
        public formBuilder: FormBuilder,
        public calendar: NgbCalendar,
        public route: ActivatedRoute,
        public router: Router,
        public integrationService: IntegrationsService
    ) {

    }

    ngOnInit() {
        this.integrationMetaData = {category: 'Hoteles', name: 'hotels'};
        this.integrationService.setMetaData(this.integrationMetaData);
        this.hotelListReq = new HotelListReqDto();
        this.checkMessage = false;

        if (this.hasAllParameters(this.route.snapshot.queryParams)) {
            this.setReqFromUrl(this.route.snapshot.queryParams);
            this.getList();
        } else {
            // TODO mostrar mensaje de error en lugar de redireccionar al usuario
            this.router.navigateByUrl('/intranet', {replaceUrl: true});
        }
    }

    hasAllParameters(query): boolean {
        let allParams = true;
        this.urlSearchParameters.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                allParams = false;
            }
        });
        return allParams;
    }

    setReqFromUrl(query) {
        Object.keys(query).forEach(key => {
            if (key === 'filter') {
                if (typeof query[key]  === 'string') {
                    this.hotelListReq[key] = JSON.parse(query[key]);
                } else {
                    this.hotelListReq[key] = query[key];
                }
            } else {
                if (key  === 'distribution') {
                    this.hotelListReq[key] = JSON.parse(query[key]);
                } else {
                    this.hotelListReq[key] = query[key];
                }
            }
        });
    }

    getList(pagination?) {
        this.hotelListReq.page = this.currentPageNumber + 1;
        this.hotelListReq.pageSize = this.currentPageSize;
        if (pagination) {
            this.hotelProviderService.auth(null, true).subscribe(
                () => {
                    this.hotelProviderService.list(this.hotelListReq).subscribe(data => {
                        this.data = data;
                        this.rows = this.data.rows;
                        this.checkHotel(data);
                        this.minPrice = this.data.minPrice;
                        this.maxPrice = this.data.maxPrice;
                        this.maxDistance = Math.trunc(this.data.maxDistance) + 1;
                        this.totalItems = data.count;
                        this.integrationService.setMetaData({...this.integrationMetaData, searchCount: this.totalItems});
                    });
                },
                err => {
                    console.log(err);
                }
            );
        } else {
            this.hotelProviderService.auth().subscribe(
                () => {
                    this.hotelProviderService.list(this.hotelListReq).subscribe(data => {
                        this.data = data;
                        this.rows = this.data.rows;
                        this.checkHotel(data);
                        this.minPrice = this.data.minPrice;
                        this.maxPrice = this.data.maxPrice;
                        this.maxDistance = Math.trunc(this.data.maxDistance) + 1;
                        this.totalItems = data.count;
                        this.integrationService.setMetaData({...this.integrationMetaData, searchCount: this.totalItems});
                    });
                },
                err => {
                    console.log(err);
                }
            );
        }
        // TODO what if I dont find a location
    }

    checkHotel(data) {
        if (data.count === 0) {
            this.checkMessage = true;
        } else {
            this.checkMessage = false;
        }
    }

    pageChanged($event, container) {
        container.el.scrollIntoView({block: "start", behavior: "smooth"});
        this.currentPageNumber = $event.pageIndex;
        this.currentPageSize = $event.pageSize;
        this.getList('pagination');
    }

    setPagination() {
        this.currentPageNumber = this.PAGE_START;
        this.currentPageSize = this.PAGE_SIZE;
    }

    onDataChanged($event) {
        this.hotelListReq = $event;
        this.setPagination();
        this.getList();
    }

    onFilterChanged($event) {
        this.filters = $event;
        if ($event.radius) {
            this.hotelListReq.radius = $event.radius;
        } else {
            this.hotelListReq.radius = 20;
        }
    }

    toggleSearcher(nameElement) {
        switch (nameElement) {
            case 'search':
                this.searchShowHide = !this.searchShowHide;
                this.filtersShowHide = false;
                break;
            case 'filters':
                this.filtersShowHide = !this.filtersShowHide;
                this.searchShowHide = false;
                break;
        }
    }

    onHotelNameChanged(textHotelName) {
        this.hotelListReq.hotelName = textHotelName;
        this.currentPageNumber = 0;
        this.getList();
    }

    onPlaceInterestingChanged(textPlaceInteresting) {
        this.hotelListReq.placeInteresting = textPlaceInteresting.name;
        this.hotelListReq.country = textPlaceInteresting.country;
        this.hotelListReq.locality = textPlaceInteresting.locality;
        this.hotelListReq.province = textPlaceInteresting.province;
        this.hotelListReq.latitude = textPlaceInteresting.geo.lat;
        this.hotelListReq.longitude = textPlaceInteresting.geo.lng;
        this.hotelListReq.radius = textPlaceInteresting.radius;
        this.currentPageNumber = 0;
        this.getList();
    }
}
