import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'checkLogoChain'
})
export class CheckLogoChainPipe implements PipeTransform {
    transform(logo: string): string {
        return logo || 'assets/images/firma_Tecnoturis.png';
    }
}
