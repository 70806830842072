import { Pipe, PipeTransform } from "@angular/core";
import { roundNumber } from "../functions/index";

@Pipe({
    name: 'roundingDecimals'
})
export class RoudingDecimalsPipe implements PipeTransform {

    transform(value: number | string, decimals: number = 2): number {
        return roundNumber(value, decimals);
    }
}
