import {Injectable} from '@angular/core';
import t from 'typy';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigationExtras, Router} from '@angular/router';
import {
    CountryBook,
    InsuranceBookReqDto,
    InsurancesBookingPostResDto,
    Insured,
    InsuredBook
} from '@vecib2c/frontend-dto';
import {ShopingBasketInsurance} from '../../../integrations/insurances/index';
import {BasketManager, InsurancesProviderService, NotificationPopupService} from '../../../lib-shared/services/index';
import { safeNumber } from '../../../lib-shared/functions/index';

@Injectable()
export class InsurancesBookServices {
    nationalities = [];
    insurance: ShopingBasketInsurance;
    insureds: FormGroup;

    constructor(
        protected _formBuilder: FormBuilder,
        protected router: Router,
        protected basketManager: BasketManager,
        private notificationPopupService: NotificationPopupService,
        protected insurancesProviderService: InsurancesProviderService,
    ) {
    }

    protected initBookingPost(): boolean {
        // if (this.insureds.valid) {
        const formValue = this.insureds.value;
        const bookingPost = new InsuranceBookReqDto();
        bookingPost.name = this.insurance.insurance.name;
        bookingPost.checkIn = this.insurance.query.checkIn;
        bookingPost.checkOut = this.insurance.query.checkOut;
        bookingPost.insuredsTotal = this.insurance.query.insureds;
        bookingPost.basePrice = this.insurance.insurance.detailedPricing.commissionableRate;
        bookingPost.totalPrice = this.insurance.insurance.detailedPricing.commissionableRate;
        bookingPost.policyId = safeNumber(this.insurance.insurance.id);
        bookingPost.insureds = this.getInsuredsMap(formValue.insuranceData.insureds);
        bookingPost.params = this.insurance.insurance.options;
        bookingPost.comission = this.insurance.insurance.comission;
        if (this.insurance.insurance.toppingsExtendable) {
            bookingPost.totalPrice = this.insurance.insurance.detailedPricing.commissionableRate + this.insurance.insurance.toppingsPrice;
            bookingPost.basePrice = this.insurance.insurance.detailedPricing.commissionableRate;
            bookingPost.toppingsPrice = this.insurance.insurance.toppingsPrice;
            bookingPost.toppingsExtendable = this.insurance.insurance.toppingsExtendable;
        }
        this.insurance.insuranceBookingPostReqDto = bookingPost;
        return true;
        // }
        // return false;
    }

    getInsuredsMap(insureds): InsuredBook[] {
        const arrayInsureds: InsuredBook[] = [];
        insureds.forEach((element: InsuredBook, index: number) => {
            if (index === 0) {
                element.isMain = true;
            } else {
                element.isMain = false;
            }
            const country: CountryBook = {
                countryCode: this.insurance.query.codeCountry,
                countryCodeAlpha: this.insurance.query.codeCountryAlpha,
            };
            element.country = country;
            arrayInsureds.push(element);
        });
        return arrayInsureds;
    }

    redirectToConfirmation(microsite = false): string {
        return microsite ? '/bookings/confirmation' : '/integration/bookings/book-confirmation';
    }

    redirectToSummary(microsite = false): string {
        return microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
    }

    goToSummaryOrConfirmation(microsite = false) {
        if (this.initBookingPost()) {
            this.redirectBack(microsite);
        } else {
            this.notificationPopupService.openPopup("OCCUPANTS_ERROR");
        }
    }

    redirectBack(microsite = false) {
        this.basketManager.addOrUpdate(this.insurance).then(() => {
            this.basketManager.getList().then(basket => {
                if (basket.length === 1 && basket[0].uuid === this.insurance.uuid) {
                    this.booking(this.insurance).then(res => {
                        const navigationExtras: NavigationExtras = {
                            state: {
                                bookingPostResDto: [res]
                            }
                        };
                        const path = this.redirectToConfirmation(microsite);
                        this.router.navigate(
                            [path],
                            navigationExtras
                        );
                    });
                } else {
                    if (basket.length) {
                        const path = this.redirectToSummary(microsite);
                        this.router.navigateByUrl(path);
                    } else {
                        this.notificationPopupService.openPopup("EMPTY_BASKET");
                    }
                }
            });
        });
    }

    booking(item, saveBudget = true) {
        return this.insurancesProviderService.book(item.insuranceBookingPostReqDto as InsuranceBookReqDto, saveBudget)
            .toPromise()
            .then(data => {
                data.status = data.status || 'ok';
                data.shoppingBasketInsurance = item;
                return data;
            })
            .catch(err => {
                Promise.reject();
                item.failed = true;
                return item;
            });
    }

    initInsuredsForm() {
        const generateInsureds = (defaultValue: Insured) => {
            return this._formBuilder.group({
                name: [t(defaultValue, 'name').safeString, Validators.required],
                surname: [t(defaultValue, 'surname').safeString, Validators.required],
                documentType: [t(defaultValue, 'documentType').safeString, Validators.required],
                documentNumber: [t(defaultValue, 'documentNumber').safeString, Validators.required],
            });
        };
        const insuredsList = [];
        const nInsureds = this.insurance.query.insureds;
        let storedInsuredList;
        let obj;
        if (this.insurance.insuranceBookingPostReqDto) {
            storedInsuredList = this.insurance.insuranceBookingPostReqDto.insureds;
        } else {
            obj = new Insured();
        }
        for (let i = 0; i < nInsureds; i++) {
            if (storedInsuredList) {
                insuredsList.push(generateInsureds(storedInsuredList[i]));
            } else {
                insuredsList.push(generateInsureds(null));
            }
        }
        const insuranceFormItem = this._formBuilder.group({
            insureds: this._formBuilder.array(insuredsList)
        });
        this.insureds = this._formBuilder.group({
            tolerance: 2,
            language: 'CAS',
            remarks: [''],
            insuranceData: insuranceFormItem
        });
        return insuranceFormItem;
    }

    addToBasketAndRedirect(isMicrosite = false) {
        this.initBookingPost();
        // if (this.initBookingPost()) {
        this.basketManager.addOrUpdate(this.insurance).then((data) => {
            const path = isMicrosite ? '/home' : '/intranet';
            this.router.navigateByUrl(path, {replaceUrl: true});
        });
        /* } else {
          this.notificationPopupService.openPopup("OCCUPANTS_ERROR");
        } */
    }

    generateArray(): number[] {
        if (this.insurance.insuranceBookingPostReqDto) {
            const n = this.insurance.insuranceBookingPostReqDto.insureds.length;
            const arr = new Array(n);
            return arr;
        } else {
            const n = this.insurance.query.insureds;
            const arr = new Array(n);
            return arr;
        }
    }

    cancel(item: InsurancesBookingPostResDto) {
        return this.insurancesProviderService.cancel(item).toPromise();
    }

    download(bookingId: string) {
        return this.insurancesProviderService.download(bookingId).toPromise();
    }
}
