import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormAddEditModule } from '../../form-add-edit/index';
import { HotelAndFlightBookingComponent } from './hotel-and-flight-booking.component';

const routes: Routes = [
    {
        path: 'create',
        component: HotelAndFlightBookingComponent,
    },
    {
        path: 'edit/:id',
        component: HotelAndFlightBookingComponent,
    }
];

@NgModule({
    declarations: [HotelAndFlightBookingComponent],
    imports: [
        CommonModule,
        FormAddEditModule,
        // RouterModule.forChild(routes)
    ], exports: [HotelAndFlightBookingComponent]
})
export class HotelAndFlightBookingModule {
}
