import { NgModule } from '@angular/core';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatTabsModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisneyPackageSearchModule } from './disney-package-search/disney-package-search.module';
import { SkyPackageSearchModule } from './sky-package-search/sky-package-search.module';
import { OthersPackageSearchModule } from './others-package-search/others-package-search.module';
import { CaribePackageSearchModule } from './caribe-package-search/caribe-package-search.module';
import { IslandPackageSearchModule } from './island-package-search/island-package-search.module';
import { DefaultPackageSearchModule } from './default-package-search/default-package-search.module';
import { PaxesPackageModule } from './paxes-package/paxes-package.module';
import { CalendarPackagesModule } from './calendar-packages/calendar-packages.module';
import { PackageSearchComponent } from './package-search.component';
import { PaxesModule } from '../../paxes/index';
import { DateRangeModule } from '../../date-range/index';
import { LibSharedModule } from '../../lib-shared/index';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        PackageSearchComponent
    ],
    imports: [
        MatTabsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        PaxesModule,
        DateRangeModule,
        DisneyPackageSearchModule,
        SkyPackageSearchModule,
        OthersPackageSearchModule,
        CaribePackageSearchModule,
        IslandPackageSearchModule,
        DefaultPackageSearchModule,
        PaxesPackageModule,
        MatButtonModule,
        CalendarPackagesModule,
        LibSharedModule,
        MatIconModule,
    ],
    exports: [
        PackageSearchComponent
    ]
})
export class PackageSearchModule {
}
