import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ActivityService } from '../../../lib-shared/services/index';
import { takeUntil } from 'rxjs/operators';
import { ActivityFilter } from '../../../lib-shared/interfaces/index';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'lib-activity-search-filter',
    templateUrl: './activity-search-filter.component.html',
    styleUrls: ['./activity-search-filter.component.scss']
})

export class ActivitySearchFilterComponent implements OnInit, OnDestroy {

    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Input() lowerUpperPrices: { lower: number, upper: number };
    @Input() selectedCategories: string[] = [];
    @Input() selectedDurations: string[] = [];
    @Input() triggerWhenEveryFilterWillBeSelected: boolean;

    categories: ActivityFilter[];
    durations: ActivityFilter[];
    price: { lower: number, upper: number };

    @Output() selectCategories = new EventEmitter<string []>();
    @Output() selectDurations = new EventEmitter<string []>();
    @Output() selectPrices = new EventEmitter<{ lower: number, upper: number }>();
    @Output() filter = new EventEmitter<{categories: string[], durations: string[], price: { lower: number, upper: number }}>();

    private unsubscribe = new Subject();

    constructor(
        private activityService: ActivityService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
    ) {

    }

    ngOnInit(): void {
        this.initFilters();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    toggleCategoryFilter(category: ActivityFilter, event: MatCheckboxChange): void {
        if (event.checked) {
            this.selectedCategories = [...this.selectedCategories, category.id];
        } else {
            this.selectedCategories = this.selectedCategories.filter(s => s !== category.id);
        }
        if (!this.triggerWhenEveryFilterWillBeSelected) {
            this.selectCategories.emit(this.selectedCategories);
        }
    }

    toggleDurationFilter(duration: ActivityFilter, event: MatCheckboxChange): void {
        if (event.checked) {
            this.selectedDurations = [...this.selectedDurations, duration.id];
        } else {
            this.selectedDurations = this.selectedDurations.filter(s => s !== duration.id);
        }
        if (!this.triggerWhenEveryFilterWillBeSelected) {
            this.selectDurations.emit(this.selectedDurations);
        }
    }

    rangePriceChange(prices: { lower: number, upper: number }): void {
        this.price = prices;
        if (!this.triggerWhenEveryFilterWillBeSelected) {
            this.selectPrices.emit(prices);
        }
    }

    search(): void {
        this.filter.emit({
            categories: this.selectedCategories,
            durations: this.selectedDurations,
            price: this.price,
        });
    }

    private initFilters(): void {
        this.activityService.getFilters()
        .pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(({categories, durations}) => {
            this.categories = categories;
            this.durations = durations;
            this.categories = this.categories.map(category => {
              return {
                ...category,
                selected: this.selectedCategories.includes(category.id)
              };
            });
            this.durations = this.durations.map(duration => {
              return {
                ...duration,
                selected: this.selectedDurations.includes(duration.id)
              };
            });

        });
    }
}
