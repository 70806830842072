import { Pipe, PipeTransform } from "@angular/core";
import { PaymentOption } from '../interfaces/management.interface';

@Pipe({
  name: 'paymentOptionIdToDescription'
})
export class PaymentOptionIdToDescriptionPipe implements PipeTransform {
  transform(paymentOptionId: number, paymentOptions: PaymentOption[]): string {
    if (!paymentOptions) { return; }
    const option = paymentOptions.find(option => option.id === paymentOptionId);
    if (!option) { return ''; }
    return option.description;
  }
}
