import { Component, Input } from '@angular/core';
import { InsurancesBookServices } from '../services/insurances-book.services';
import { InsurancesBookingPostResDto } from '@vecib2c/frontend-dto';
import { NotificationPopupService } from '../../../lib-shared/services/index';
import { saveAs } from 'file-saver';

@Component({
    selector: 'lib-insurances-book-confirmation',
    templateUrl: './insurances-book-confirmation.component.html',
    styleUrls: ['./insurances-book-confirmation.component.scss']
})
export class InsurancesBookConfirmationComponent {

    @Input() insurancesBookingConfirmation: InsurancesBookingPostResDto;
    isCancelled: boolean;

    constructor(
        private notificationPopupService: NotificationPopupService,
        protected _insuranceBookServices: InsurancesBookServices
    ) {
        this.isCancelled = false;
    }

    cancelBooking() {
        this._insuranceBookServices.cancel(this.insurancesBookingConfirmation)
            .then((data) => {
                this.isCancelled = true;
                this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_CANCEL_BOOKING");
            })
            .catch(err => {
                this.isCancelled = false;
            });
    }

    download(bookingId: string) {
        this._insuranceBookServices.download(bookingId)
            .then(res => {
                saveAs.saveAs(res, 'certificado.pdf');
            })
            .catch(err => {
                this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_INVALID_DOWNLOAD");
            });
    }
}
