import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {Field, Setup} from '../../lib-shared/interfaces/index';
import {AgencyChangeService} from '../../agency-change/agency-change.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {MANAGEMENT_MODULES, ROLES} from "../../const";
import {AuthenticationService} from "../../lib-shared/services/index";
import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from '../../utils';

@Component({
    selector: 'lib-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ]
})
export class DynamicTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() fields: Field[];

    @Input() data: any;

    @Input() selectable = false;

    @Input() selectableUnique = false;

    @Input() markAsSelected: number[] = [];

    @Output() edit: EventEmitter<number> = new EventEmitter<number>();

    @Output() delete: EventEmitter<number> = new EventEmitter<number>();

    @Output() download: EventEmitter<number> = new EventEmitter<number>();

    @Output() cancel: EventEmitter<number> = new EventEmitter<number>();

    @Input() showEdit = true;
    @Input() showDelete = true;
    @Input() showDownload = true;
    @Input() showCancel = false;
    @Input() cancelKeyState: string;
    @Input() count = 10;
    @Input() paginatorHttp = false;
    @Input() customDownload = false;
    @Input() managementModule: MANAGEMENT_MODULES;
    auth = AuthenticationService.getUser();
    PAGE_START = 0;
    PAGE_SIZE = 10;
    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;

    displayedColumns: string[] = [];

    unsubscribe = new Subject();

    setup: Setup = {};

    selection = new SelectionModel<number>(true, []);

    @Input() downloadKeyName: string;

    @Output() itemsSelected: EventEmitter<number[] | any> = new EventEmitter<number[] | any>();

    @Output() pageEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    selectableUniqueControl = new FormControl();

    @Output() selectedUniqueValue = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private agencyChangeService: AgencyChangeService
    ) {
        this.agencyChangeService.getAgencyChainList()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(agencyChains => {
                const agencies = [];
                agencyChains.forEach(a => agencies.push(...a.agencies));
                this.setup = {...this.setup, ...{agency_chain: agencyChains, agency: agencies}};
            });
        this.route.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value.setup) {
                    this.setup = {...this.setup, ...value.setup};
                }
            });
        this.selection.changed
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                this.itemsSelected.emit(value.source.selected);
            });

        this.selectableUniqueControl.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => this.itemsSelected.emit(value));
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        setTimeout(() => {
            if (this.paginatorHttp) {
                this.paginator.length = this.count;
                this.paginator.pageIndex = this.currentPageNumber;
            }
        });
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
        if (changes.data || changes.fields) {
            if (this.data) {
                this.buildDataTable();
            }
        }

        if (changes.markAsSelected) {
            if (changes.markAsSelected.currentValue) {
                this.selection.clear();
                changes.markAsSelected.currentValue.forEach(item => {
                    this.selection.toggle(item);
                });
            }
        }
    }

    ngAfterViewInit(): void {
    }

    buildDataTable(): void {
        this.displayedColumns = [];
        if (this.selectable || this.selectableUnique) {
            this.displayedColumns.push('selectable');
        }
        this.fields
            .forEach(f => {
                if (f.isGroup) {
                    this.displayedColumns.push(...f.fieldChilds.filter(f => f.showInTable).map(fc => fc.keyName));
                } else {
                    this.displayedColumns.push(f.keyName);
                }
            });
        this.displayedColumns.push(...['buttons']);
    }

    openDownloadKeyName(element): void {
        if (this.customDownload) {
            this.download.emit(element.id);
            return;
        }
        window.open(element[this.downloadKeyName], '_blank');
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    pageChanged($event: PageEvent) {
        this.currentPageNumber = $event.pageIndex;
        this.currentPageSize = $event.pageSize;
        this.pageEvent.emit($event);
    }

    getCancelStateShow(element: any): boolean {
        if (this.cancelKeyState) {
            return this.cancelKeyState in element ? this.showCancel && element[this.cancelKeyState] : this.showCancel;
        } else {
            return this.showCancel;
        }
    }

    getShowDelete(element: any) {
        switch (this.managementModule) {
            case MANAGEMENT_MODULES.COMMISSION:
                return !(this.auth.role.id === +ROLES.DIRECTOR_AGENCIA && element.is_global);
            case MANAGEMENT_MODULES.DAILY_CASH:
                return [1, 3].indexOf(element.type_id) === -1;
            default:
                return this.showDelete;
        }
    }

    getShowEdit(element: any) {
        switch (this.managementModule) {
            case MANAGEMENT_MODULES.COMMISSION:
                return !(this.auth.role.id === +ROLES.DIRECTOR_AGENCIA && element.is_global);
            default:
                return this.showEdit;
        }
    }

    onSelectableChange(event, row) {
        return event ? this.selection.toggle(row) : null;
    }

}
