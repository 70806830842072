export const categoryMenu = [
    //{id: 0, name: 'Hoteles', selected: false, router: '/intranet/search/hotels'},
    //{id: 1, name: 'Vuelos', selected: false, router: '/intranet/search/flights'},
    //{id: 2, name: 'Vuelo + Hotel', selected: false, router: '/intranet/search/hotel-and-flight'},
    {id: 3, name: 'Paquetes', selected: false, router: '/intranet/search/packages'},
    //{id: 4, name: 'Circuitos', selected: false, router: '/intranet/search/circuits'},
    // {id: 5, name: 'Multidestino', selected: false, router: '/integration/multidestination/map'},
    //{id: 6, name: 'Transportes', selected: false, router: '/intranet/search/transports'},
    //{id: 7, name: 'Actividades', selected: false, router: '/intranet/search/activities'},
    //{id: 8, name: 'Seguros', selected: false, router: '/intranet/search/insurances'},
];
