import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment_ from 'moment';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import Pax from '../../paxes/Pax';
import { PaxesService } from '../../paxes/index';
import { AirportService, IFlightPax } from '../../lib-shared/index';
import { FLIGHTS_AIRPORTS } from '../../../mock/flights';
import { PaxToFlightPaxPipe } from './paxToFlightPax.pipe';
import { IAirport } from '../../lib-shared/interfaces/airport.interface';
const moment = moment_;

export interface FlightSearchDto {
    origin: string;
    destination: string;
    checkIn: string;
    checkOut?: string;
    adults: number;
    children: number[];
    includedLowCost: boolean;
    includedTourOperation: boolean;
    originCity: string;
    originCountry: string;
    originAirport: string;
    destinationCity: string;
    destinationCountry: string;
    destinationAirport: string;
    going?: any;
    departureIsCity: boolean;
    arrivalIsCity: boolean;
    adultResidents: number;
    childrenResidents: number;
    infantResidents: number;
}

@Component({
    selector: 'lib-flight-search',
    templateUrl: './flight-search.component.html',
    styleUrls: ['./flight-search.component.scss']
})
export class FlightSearchComponent
    implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {

    @Input() isSmall = false;
    @Input() inputFields: FlightSearchDto;
    @Input() parsedOrigin: any;
    @Input() parsedDestination: any;

    form: FormGroup;
    originCtrl: FormControl;
    filteredOrigin: Observable<any[]>;
    destinationCtrl: FormControl;
    filteredDestination: Observable<any[]>;
    cities = Object.keys(FLIGHTS_AIRPORTS).map(key => {
        const data = FLIGHTS_AIRPORTS[key];
        return data;
    });
    pax: Pax;
    lowCostCtrl: FormControl;
    tourOrientationCtrl: FormControl;
    oneWayCtrl: FormControl;
    directFlyCtrl: FormControl;
    directFlyEEUUCtrl: FormControl;
    dateGoingControl = new FormControl();
    date = new Date();
    adultResidents = 0;
    childrenResidents = 0;
    infantResidents = 0;
    flightPax: IFlightPax;
    originIsIsland: boolean;
    destinationIsIsland: boolean;
    islandIatas = ['PMI', 'IBZ', 'MAH', 'LPA', 'ACE', 'FUE', 'TCI', 'TFN', 'TFS', 'SPC', 'GMZ', 'VDE', 'JCU', 'MLN'];
    residentsControl = new FormControl();

    @Output() dataChanged = new EventEmitter<any>(); // TODO: Define type

    @ViewChild('dateRange', { static: false }) dateRange: any;

    @Input() routerPath = '/integration/flights/search';
    @Input() microsite = false;
    @Output() queryParams: EventEmitter<FlightSearchDto> = new EventEmitter<FlightSearchDto>();
    @Input() showTourIncluded = true;
    @Input() lowCostChecked = true;

    private unsubscribe = new Subject();

    constructor(
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public paxService: PaxesService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private airportService: AirportService,
        private readonly paxToFlightPaxPipe: PaxToFlightPaxPipe,
    ) {
        this.originCtrl = new FormControl();
        this.destinationCtrl = new FormControl();

        this.lowCostCtrl = new FormControl();
        this.tourOrientationCtrl = new FormControl();
        this.oneWayCtrl = new FormControl();
        this.directFlyCtrl = new FormControl();
        this.directFlyEEUUCtrl = new FormControl();

        this.lowCostCtrl.setValue(true);
        this.tourOrientationCtrl.setValue(true);
        this.oneWayCtrl.setValue(false);
        this.directFlyCtrl.setValue(false);
        this.directFlyEEUUCtrl.setValue(false);
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');
        this.initForm();
        this.residentsControl.valueChanges.pipe(
            takeUntil(this.unsubscribe),
        ).subscribe(residentControlIsChecked => {
            if (residentControlIsChecked) {
                this.includeResidentsInForm(this.flightPax.adults, this.flightPax.children, this.flightPax.infants);
            } else {
                this.adultResidents = 0;
                this.childrenResidents = 0;
                this.infantResidents = 0;
                this.includeResidentsInForm(this.adultResidents, this.childrenResidents, this.infantResidents);
            }
        });
    }

    ngAfterViewInit() {
        this.lowCostCtrl.setValue(this.lowCostChecked);
        if (this.inputFields) {
            this.setParamsFromQuery(this.inputFields);
            this.setOnlyGoing();

            if (typeof this.inputFields.includedLowCost === 'string') {
                this.inputFields.includedLowCost =
                    this.inputFields.includedLowCost === 'true' ? true : false;
                this.lowCostCtrl.setValue(this.inputFields.includedLowCost);
            }
            if (typeof this.inputFields.includedTourOperation === 'string') {
                this.inputFields.includedTourOperation =
                    this.inputFields.includedTourOperation === 'true' ? true : false;
                this.tourOrientationCtrl.setValue(
                    this.inputFields.includedTourOperation
                );
            }
            this.setResidentControl(this.adultResidents, this.childrenResidents, this.infantResidents);
        }

        this.filteredOrigin = this.originCtrl.valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(value => value ? value.length > 1 : false),
                switchMap(city => this.filterCities(city))
            );
        this.filteredDestination = this.destinationCtrl.valueChanges
            .pipe(
                debounceTime(800),
                distinctUntilChanged(),
                filter(value => value ? value.length > 1 : false),
                switchMap(city => this.filterCities(city))
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.inputFields) {
            if (this.inputFields) {
                if ((this.inputFields as any).going) {
                    if (this.form) {
                        this.setOnlyGoing();
                    }
                }
                this.setResidentControl(
                    this.inputFields.adultResidents,
                    this.inputFields.childrenResidents,
                    this.inputFields.infantResidents
                );
            }
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    setOnlyGoing(): void {
        if ((this.inputFields as any).going) {
            this.form.get('going').setValue(true);
            this.dateGoingControl.setValue(moment(this.inputFields.checkIn, 'DD/MM/YYYY').toDate());
            this.dateGoingControl.markAsTouched();
        }
    }

    filterCities(city: string) {
        return this.airportService.search(city);
    }

    onEnterDestination(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.destination = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['destination'].setValue(reqIATA);
        }
    }

    onEnterOrigin(evt: any, reqIATA) {
        if (evt.source.selected) {
            const newValueForm = this.form.value;
            newValueForm.origin = this.autoCompleteDisplayFn(reqIATA);
            this.form.setValue(newValueForm);
            this.form.controls['origin'].setValue(reqIATA);
        }
    }

    private initForm() {
        this.form = this.formBuilder.group({
            origin: ['', Validators.required],
            destination: ['', Validators.required],
            adults: [2, Validators.required],
            children: [0, Validators.required],
            lowCost: this.lowCostCtrl,
            tourOrientation: this.tourOrientationCtrl,
            oneWay: this.oneWayCtrl,
            directFly: this.directFlyCtrl,
            directFlyEEUU: this.directFlyEEUUCtrl,
            going: [],
            adultResidents: [this.adultResidents],
            childrenResidents: [this.childrenResidents],
            infantResidents: [this.infantResidents],
        });
        this.form.get('going').valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value) {
                    this.dateGoingControl.setValidators([Validators.required]);
                } else {
                    this.dateGoingControl.setValidators(null);
                    this.dateGoingControl.updateValueAndValidity();
                }
            });

        this.form.get('origin').valueChanges
            .pipe(
                filter((value: string | IAirport) => value && (value as IAirport).iata !== undefined),
                takeUntil(this.unsubscribe)
            )
            .subscribe((airport: IAirport) => {
                const iatas = airport.iata.split(',');
                this.originIsIsland = iatas.some(iata => this.islandIatas.includes(iata));
                this.performResetResidents();
                this.setResidentControl(this.adultResidents, this.childrenResidents, this.infantResidents);
            });

        this.form.get('destination').valueChanges
            .pipe(
                filter((value: string | IAirport) => value && (value as IAirport).iata !== undefined),
                takeUntil(this.unsubscribe)
            )
            .subscribe((airport: IAirport) => {
                const iatas = airport.iata.split(',');
                this.destinationIsIsland = iatas.some(iata => this.islandIatas.includes(iata));
                this.performResetResidents();
                this.setResidentControl(this.adultResidents, this.childrenResidents, this.infantResidents);
            });
    }

    autoCompleteDisplayFn(option) {
        if (option == null || undefined) {
            return '';
        }
        if (option !== '' || null || undefined) {
            return option.city + ' | ' + option.name + ' | ' + option.country;
        }
    }

    onPaxChanged(pax: Pax) {
        this.pax = pax;
        this.flightPax = this.paxToFlightPaxPipe.transform(this.pax);
        const residentsIsChecked = this.residentsControl.value;
        if (residentsIsChecked) {
            this.includeResidentsInForm(this.flightPax.adults, this.flightPax.children, this.flightPax.infants);
        } else {
            this.adultResidents = 0;
            this.childrenResidents = 0;
            this.infantResidents = 0;
            this.includeResidentsInForm(this.adultResidents, this.childrenResidents, this.infantResidents);
        }
        this.form.controls['adults'].setValue(pax.adults);
        this.form.controls['children'].setValue(pax.children.length);
    }

    search() {
        if (!this.form.valid) {
            return;
        }
        if ((this.form.get('going').value && !this.dateGoingControl.value)) {
            return;
        }
        const request = this.buildRequest();

        this.dataChanged.emit(request);

        if (this.microsite) {
            this.queryParams.emit(request);
            return;
        }

        this.router.navigate([this.routerPath], {
            queryParams: request
        });
    }

    selectCheckIn(checkIn: Date): void {
        this.dateGoingControl.setValue(checkIn);
        this.dateGoingControl.markAsTouched();
    }

    private buildRequest() {
        const searchRequest: FlightSearchDto = {
            origin: this.form.controls['origin'].value.iata,
            destination: this.form.controls['destination'].value.iata,
            checkIn: this.form.get('going').value ? moment(this.dateGoingControl.value).format('DD/MM/YYYY') :
                this.dateRange.formatDate(this.dateRange.fromDate, 'DD/MM/YYYY'),
            checkOut: this.form.get('going').value ? undefined
                : this.dateRange.formatDate(this.dateRange.toDate, 'DD/MM/YYYY'),
            adults: this.pax.adults,
            children: this.pax.children || [0],
            includedLowCost: this.form.controls.lowCost.value,
            includedTourOperation: this.form.controls.tourOrientation.value,
            originCity: this.form.controls['origin'].value.city,
            originCountry: this.form.controls['origin'].value.country,
            originAirport: this.form.controls['origin'].value.name,
            destinationCity: this.form.controls['destination'].value.city,
            destinationCountry: this.form.controls['destination'].value.country,
            destinationAirport: this.form.controls['destination'].value.name,
            departureIsCity: false,
            arrivalIsCity: false,
            adultResidents: this.form.controls['adultResidents'].value,
            childrenResidents: this.form.controls['childrenResidents'].value,
            infantResidents: this.form.controls['infantResidents'].value
        };

        if (this.form.controls['origin'].value.name === 'Todos') {
            searchRequest.origin = this.form.controls['origin'].value.cityCode;
            searchRequest.departureIsCity = true;
        }

        if (this.form.controls['destination'].value.name === 'Todos') {
            searchRequest.destination = this.form.controls['destination'].value.cityCode;
            searchRequest.arrivalIsCity = true;
        }

        if (this.form.get('going').value) {
            return {...searchRequest, going: this.form.get('going').value};
        }

        return searchRequest;
    }

    private setParamsFromQuery(inputFields: FlightSearchDto) {
        this.form.controls['origin'].setValue(
            {
                iata: inputFields.origin,
                city: inputFields.originCity,
                country: inputFields.originCountry,
                name: inputFields.originAirport,
                cityCode: inputFields.origin,
            }
        );
        this.form.controls['destination'].setValue(
            {
                iata: inputFields.destination,
                city: inputFields.destinationCity,
                country: inputFields.destinationCountry,
                name: inputFields.destinationAirport,
                cityCode: inputFields.destination,
            }
        );
        this.setDateFromQuery(inputFields.checkIn);
        this.setDateFromQuery(inputFields.checkOut);
        this.paxService.changePax({
            adults: Number(inputFields.adults) || 1,
            children: inputFields.children || []
        });

        this.form.controls.lowCost.setValue(inputFields.includedLowCost);
        this.form.controls.tourOrientation.setValue(
            inputFields.includedTourOperation
        );
        this.form.controls['adultResidents'].setValue(this.inputFields.adultResidents);
        this.form.controls['childrenResidents'].setValue(this.inputFields.childrenResidents);
        this.form.controls['infantResidents'].setValue(this.inputFields.infantResidents);
        this.adultResidents = this.inputFields.adultResidents;
        this.childrenResidents = this.inputFields.childrenResidents;
        this.infantResidents = this.inputFields.infantResidents;
    }

    private setDateFromQuery(inputDate: string) {
        const date = moment(inputDate, 'DD/MM/YYYY').toDate();
        this.dateRange.onSelection(
            new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
        );
    }

    private buildQueryString(request: FlightSearchDto): HttpParams {
        const query = new HttpParams()
            .append('includedLowCost', this.form.controls.lowCost.value)
            .append('includedTourOperation', this.form.controls.tourOrientation.value)
            .set('origin', request.origin)
            .set('destination', request.destination)
            .set('checkIn', request.checkIn)
            .set('adults', request.adults.toString())
            .set('children', request.children.join())
            .set('originCity', request.originCity)
            .set('originCountry', request.originCountry)
            .set('originAirport', request.originAirport)
            .set('destinationCity', request.destinationCity)
            .set('destinationCountry', request.destinationCountry)
            .set('destinationAirport', request.destinationAirport);
        if (request.checkOut) {
            query.set('checkOut', request.checkOut);
        }
        if (this.form.get('going').value) {
            query.set('going', this.form.get('going').value);
        }
        return query;
    }

    setLowCost() {
        this.buildRequest();
    }

    setTourOrientation() {
        this.buildRequest();
    }

    resetInput(form: FormControl, container, keyForm: string): void {
        form.reset();
        this.form.get(keyForm).reset();
        container.focus();
        if (keyForm === 'origin') {
            this.originIsIsland = false;
        } else {
            this.destinationIsIsland = false;
        }
        this.performResetResidents();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    updateResidents(kindOfOperation: string, resident: string): void {
        if (kindOfOperation === 'increment') {
            this[resident]++;
        } else {
            this[resident]--;
        }
        this.includeResidentsInForm(this.adultResidents, this.childrenResidents, this.infantResidents);
    }

    adjustResidents(resident: string, typeOfPax: string): void {
        this[resident] = this.flightPax[typeOfPax];
        this.includeResidentsInForm(this.adultResidents, this.childrenResidents, this.infantResidents);
    }

    private includeResidentsInForm(adultResidents: number, childrenResidents: number, infantResidents: number): void {
        this.form.controls['adultResidents'].setValue(adultResidents);
        this.form.controls['childrenResidents'].setValue(childrenResidents);
        this.form.controls['infantResidents'].setValue(infantResidents);
    }

    private resetResidents(): void {
        this.adultResidents = 0;
        this.childrenResidents = 0;
        this.infantResidents = 0;
    }

    private performResetResidents(): void {
        if (!this.originIsIsland && !this.destinationIsIsland) {
            this.resetResidents();
            this.includeResidentsInForm(this.adultResidents, this.childrenResidents, this.infantResidents);
        }
    }

    private setResidentControl(adultResidents: number, childrenResidents: number, infantResidents: number): void {
        const thereAreResidents = adultResidents > 0 || childrenResidents > 0 || infantResidents > 0;
        this.residentsControl.setValue(thereAreResidents);
    }



}
