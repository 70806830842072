import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    selector: "lib-dialog",
    templateUrl: "./dialog.component.html",
})
export class DialogComponent {
    label: string;
    icon: string;
    hideIcon: boolean;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.label = data.label || "Aviso de eliminación";
      this.icon = data.icon || "assets/images/img2.jpg";
      this.hideIcon = data.hideIcon;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
