import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'sortByField'
})
export class SortByFieldPipe implements PipeTransform {
    transform(array, field: string = "") {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (!field) {
                if (a < b) {
                    return -1;
                } else if (a > b) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if (a[field] < b[field]) {
                    return -1;
                } else if (a[field] > b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }


        });
        return array;
    }
}
