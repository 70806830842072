import { Pipe, PipeTransform } from "@angular/core";
import { PaymentMethod } from '../interfaces/management.interface';

@Pipe({
  name: 'paymentMethodIdToName'
})
export class PaymentMethodIdToNamePipe implements PipeTransform {
  transform(paymentId: number, paymentMethods: PaymentMethod[]): string {
    if (!paymentMethods) { return; }
    const option = paymentMethods.find(option => option.id === paymentId);
    if (!option) { return ''; }
    return option.name;
  }
}
