import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { categoryMenu } from "../../../../mock/main";

@Component({
    selector: "lib-category-navbar",
    templateUrl: "./category-navbar.component.html",
    styleUrls: ["./category-navbar.component.scss"]
})
export class CategoryNavbarComponent implements OnInit, OnDestroy {
    @Input() category: String;

    categories = categoryMenu;

    unsubscribe = new Subject();
    selectedIndexTab = 0;

    constructor(public router: Router) {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe)
            )
            .subscribe((navigation: NavigationEnd) => {
                this.getSelectedInitTab(navigation);
            });
    }

    ngOnInit() {
        this.getCategory();
    }

    getCategory() {
        this.categories.forEach(element => {
            if (element.name === this.category) {
                element.selected = true;
            }
        });
    }

    private getSelectedInitTab(navigation: NavigationEnd): void {
        const tab = this.categories.findIndex(
            t => t.router === navigation.urlAfterRedirects
        );
        if (tab > -1) {
            this.selectedIndexTab = tab;
        }
    }

    changeSelectedTab(categoryId: number): void {
        const activeCategory = this.categories.find(category => category.selected);
        if (activeCategory) {
            activeCategory.selected = false;
        }
        const tab = this.categories.find(category => category.id === categoryId);
        if (!tab) { return; }
        this.router.navigate([tab.router]);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
