import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'allLocations'
})

export class AllLocationPipe implements PipeTransform {
    transform(points: any[]) {

        if (!points) {
            return;
        }
        const locations = {};
        points.forEach((point) => {
            if (locations[point.LocationName + '-' + point.LocationCountry]) {
                // tslint:disable-next-line:max-line-length
                locations[point.LocationName + '-' + point.LocationCountry] = [...locations[point.LocationName + '-' + point.LocationCountry], point.LocationID];
            } else {
                locations[point.LocationName + '-' + point.LocationCountry] = [point.LocationID];
            }
        });
        return points;
    }
}
