import { Contact, PassengerTransfer } from "../request/booking-create.request.dto";
// import { TransferServiceResponse } from "./prebooking-transfer-response.dto"

export class CancelTransferResponseDto{
    data: CancelTransfer;
    status: number;
}

export class CancelTransfer {
    cancellationCosts: string;
    locator: string;
    sessionId: string;
    transferBooking: TransferBooking
}

export class TransferBooking {
    amount: string;
    cancelLocator: string;
    contact: Contact;
    creationDate: string;
    locator: string;
    office: string;
    pax: PassengerTransfer | PassengerTransfer[];
    refNumber: string;
    status: string;
    system: string;
    transferRemarks: string;
    // transferService: TransferServiceResponse | TransferServiceResponse[]
}