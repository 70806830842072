import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'numberSymbol'
})
export class NumberSymbolPipe implements PipeTransform {

    transform(value: string | number): string {
        const safeNumber = Number(value);
        if (safeNumber > 0) { return '+' + safeNumber.toFixed(2); }
        if (safeNumber <= 0) { return safeNumber.toFixed(2); }
    }
}
