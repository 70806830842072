import { Pipe, PipeTransform } from "@angular/core";
import * as moment_ from 'moment';

const moment = moment_;

@Pipe({
    name: 'diffTime'
})
export class DiffTimePipe implements PipeTransform {

    transform(time: number): string {
        const diffDuration = moment.duration(time);
        const hours = diffDuration.days() * 24 + diffDuration.hours();
        const minutes = diffDuration.minutes();

        return `${hours > 0 ? `${hours}h ` : ""}${
            minutes > 0 ? `${minutes}m` : ""
        }`;
    }
}
