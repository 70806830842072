import { BookReqDto, CircuitGetReqDto, CircuitRoom, HotelRoomDistribution } from "@vecib2c/frontend-dto";
import { safeNumber } from "../../../lib-shared/functions/index";
import {
    CircuitDistributionFromPassengerFactory
} from "../../../lib-shared/factories/circuits/circuit-distribution-from-passenger.factory";
import { CircuitPaxFromManagementFactory } from "../../../lib-shared/factories/circuits/circuit-pax-from-management.factory";
import { IBookingItem, IntegrationType, ManagementPax, ShoppingBasketComment } from '../../../lib-shared/interfaces/index';

export class ShopingBasketCircuit implements IBookingItem {
    uuid: string;
    circuitGetReq: CircuitGetReqDto;
    client: any;
    distributionSelected?: HotelRoomDistribution[];
    circuitBookingPostReqDto?: BookReqDto; // Quizá habría que cambiarle el nombre para evitar confusión
    rateSelected: any;
    circuit: any;
    validateMessage?: string;
    checkForm?: boolean;
    integrationType: IntegrationType;
    reratedPrice?: number;
    originalPrice?: number;
    isSelected?: boolean;
    canBeBooked?: boolean;
    comments?: ShoppingBasketComment;

    static initializeComments(
        item: ShopingBasketCircuit,
    ): ShopingBasketCircuit {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.rateSelected.totalPrice),
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShopingBasketCircuit,
        paxes: ManagementPax[],
    ): ShopingBasketCircuit {
        const initialRoom = item.circuitBookingPostReqDto.rooms[0];
        const initialPaxes = initialRoom.passengers;
        const mappedPaxes = CircuitPaxFromManagementFactory.build(paxes, initialPaxes);
        const mappedRoom: CircuitRoom = Object.assign({}, initialRoom, { passengers: mappedPaxes });
        const mappedBookingPostReq: BookReqDto = Object.assign({}, item.circuitBookingPostReqDto, {
            rooms: [mappedRoom]
        });
        const distribution = CircuitDistributionFromPassengerFactory.build(mappedPaxes);
        return {
            ...item,
            circuitBookingPostReqDto: mappedBookingPostReq,
            distributionSelected: [distribution],
            circuitGetReq: {
                ...item.circuitGetReq,
                rooms: [distribution],
            },
        };
    }
}
