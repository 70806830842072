import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getDecimalPartPrice'
})
export class GetDecimalPartPricePipe implements PipeTransform {

    transform(price: any, flightPrice?: any, safePrice?: any): any {
        price = Number(price);

        if (flightPrice)
            price += Number(flightPrice);
        if (safePrice)
            price += Number(safePrice);
        const decimalPart = price.toFixed(2).split('.')[1];
        return decimalPart ? ',' + decimalPart : ',00';
    }
}
