import { OnInit } from '@angular/core';
import {
    CircuitsProviderService,
    Circuitsv2Service,
    IntegrationPageMetaData,
    IntegrationsService,
    ShareService
} from '../lib-shared/services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CircuitListReqDto } from '@vecib2c/frontend-dto/dist';

export class CircuitSearchList implements OnInit {

    PAGE_START = 0;
    PAGE_SIZE = 10;
    data: any;
    rows;
    circuitListReq: CircuitListReqDto;
    totalItems;
    nights: any;
    from: any;

    maxPrice = 0;
    minPrice = 0;
    filteredMaxPrice = 0;
    filteredMinPrice = 0;
    prices: number[] = [];
    totalRange = 0;

    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 15];

    searchShowHide = false;
    filtersShowHide = false;

    integrationMetaData: IntegrationPageMetaData;

    UrlSearchParameters = [
        'date',
        'nights',
        'language',
        'country',
        'category',
        'departure',
        'rooms',
    ];

    rowsToShow: any[];

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public CircuitsProviderService: CircuitsProviderService,
        public share: ShareService,
        public translate: TranslateService,
        public integrationService: IntegrationsService,
        public circuitsv2Service: Circuitsv2Service
    ) {
        this.integrationMetaData = { category: 'Circuitos', name: 'circuitos' };
        this.integrationService.setMetaData(this.integrationMetaData);
        this.circuitListReq = new CircuitListReqDto();
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');

        if (this.hasAllParameters(this.route.snapshot.queryParams)) {
            this.setReqFromUrl(this.route.snapshot.queryParams);
            this.getList();

        }

    }

    hasAllParameters(query): boolean {
        let allParams = true;

        this.UrlSearchParameters.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                allParams = false;
            }
        });
        return allParams;
    }

    setReqFromUrl(query) {
        const noAdd = ['adults', 'children'];
        Object.keys(query).forEach(key => {
            if (!noAdd.includes(key)) {
                this.circuitListReq[key] = query[key];
                if (key === 'rooms') {
                    this.circuitListReq[key] = JSON.parse(query[key]);
                }
            }
        });
        this.nights = this.circuitListReq.nights;
        this.from = this.circuitListReq.departure;
    }

    getList() {
        this.circuitsv2Service.list(this.circuitListReq).subscribe(data => {
            this.saveResponseData();
            this.data = data;
            this.rows = this.data.rows;
            this.setPricesForFilter();
            this.filterRows({ upper: this.filteredMaxPrice, lower: this.filteredMinPrice });
            this.totalItems = data.count;
            this.integrationService.setMetaData({ ...this.integrationMetaData, searchCount: this.totalItems });
        });
    }

    saveResponseData() {
        this.share.set('date', this.circuitListReq.date);
        this.share.set('nights', this.circuitListReq.nights);
        this.share.set('rooms', this.circuitListReq.rooms);
        this.share.set('language', this.circuitListReq.language);
        this.share.set('country', this.circuitListReq.country);
        this.share.set('departure', this.circuitListReq.departure);
        this.share.set('category', this.circuitListReq.category);
    }

    pageChanged($event) {
        this.currentPageNumber = $event.pageIndex;
        this.currentPageSize = $event.pageSize;
        this.getList();
    }

    setPagination() {
        this.currentPageNumber = this.PAGE_START;
        this.currentPageSize = this.PAGE_SIZE;
    }

    onDataChanged($event) {
        this.circuitListReq = $event;
        this.setPagination();
        this.getList();
    }

    toggleSearcher(nameElement) {
        switch (nameElement) {
            case 'search':
                this.searchShowHide = !this.searchShowHide;
                this.filtersShowHide = false;
                break;
            case 'filters':
                this.filtersShowHide = !this.filtersShowHide;
                this.searchShowHide = false;
                break;
        }
    }

    filterRows(range) {
        this.rowsToShow = [];
        if (this.rows.length > 0) {
            this.rows.forEach(row => {
                const ratePrice = this.setCheapestPrice(row.departures);
                if (ratePrice >= range.lower && ratePrice <= range.upper) {
                    this.rowsToShow.push(row);
                }
            });
        }
        this.integrationService.setMetaData({ ...this.integrationMetaData, searchCount: this.rowsToShow.length });
    }

    setPricesForFilter() {
        this.prices = [];
        if (this.rows.length > 0) {
            this.rows.forEach((__, i) => {
                this.prices.push(this.setCheapestPrice(this.rows[i].departures));
            });
            this.maxPrice = Math.max(...this.prices);
            this.filteredMaxPrice = this.maxPrice;
        } else {
            this.maxPrice = 0;
            this.filteredMaxPrice = this.maxPrice;
        }
        this.minPrice = 0;
        this.filteredMinPrice = this.minPrice;
        this.setPricesRange();
    }

    setPricesRange() {
        this.totalRange = this.maxPrice;
    }

    setCheapestPrice(departures): number {
        const prices = [];
        departures.forEach(departure => {
            prices.push(departure.rates[0].price);
        });
        return Math.min(...prices);
    }

}
