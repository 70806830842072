import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'lib-carousel2',
    templateUrl: './carousel2.component.html',
})
export class Carousel2Component {

    @ViewChild('carousel', { static: false }) carousel;
    images;
    slideNumber = 0;
    slideActive = 1;
    totalSlide;


    slideOpts = {
        effect: 'cube',
        pagination: false
    };

    constructor(
        public dialogRef: MatDialogRef<Carousel2Component>,
        @Inject(MAT_DIALOG_DATA) public data: (string)[]
    ) {
        this.images = data;
        this.totalSlide = this.images.length;
    }


    SlideNext(images) {
        this.slideActive++;
        this.slideNumber++;
        let slide = this.slideNumber;
        images = this.images.slice();
        while (slide <= this.slideNumber + 3 && this.totalSlide > slide) {
            if (images[slide][1] === false) {
                images[slide][1] = true;
            }
            slide++;
        }
    }

    SlidePrev() {
        this.slideActive--;
    }

    next() {
        this.carousel.slideNext();
    }

    prev() {
        this.carousel.slidePrev();
    }

    changeImage(fileName) {
        this.dialogRef.close(fileName);
    }

    selectImageToPreview(imageSelected) {
        const finded = this.images.indexOf(imageSelected);

        this.slideActive = finded;
        this.carousel.slideTo(finded);
    }
}
