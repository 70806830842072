import { Component, OnInit } from '@angular/core';
import {TecnoturisService} from "../../tecnoturis.service";
import {activityFormMetaData} from "./activity-booking.form";

@Component({
  selector: 'lib-activity-booking',
  templateUrl: './activity-booking.component.html',
  styleUrls: ['./activity-booking.component.scss']
})
export class ActivityBookingComponent implements OnInit {

  activityCrud = activityFormMetaData;

  constructor(private tecnoturisService: TecnoturisService) {
    if (this.tecnoturisService.config.forms.activityForm) {
      this.activityCrud = this.tecnoturisService.config.forms.activityForm;
    }
  }

  ngOnInit() {
  }

}
