import {Pipe, PipeTransform} from "@angular/core";
import t from "typy";

@Pipe({
    name: 'changeTextToArray'
})
export class ChangeTextToArrayPipe implements PipeTransform {
    transform(value: any, ...args): any {
        return t(value).safeArray;
    }
}
