import { Input, Directive, OnInit, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[libDigitOnly]',
})
export class Digit2OnlyDirective implements OnInit {

  @Input('decimals') decimals = 0;

  private regex: RegExp = new RegExp(/^[0-9]*$/);
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];

  constructor(private el: ElementRef) { }

  ngOnInit() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(e.key) !== -1) {
        return;
    }
    // Do not use e.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.el.nativeElement.value;
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    const next: string = current.concat(e.key);
    // if (next && !String(next).match(this.regex)) {
    //     e.preventDefault();
    // }
    if (next && this.check(next, this.decimals)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
     document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer
      .getData('text').replace(/\D/g, '');
    this.el.nativeElement.focus();
    document.execCommand('insertText', false, textData);
  }

  private check(value: string, decimals: number) {
    if (decimals <= 0) {
     return !String(value).match(this.regex);
    } else {
      const regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$";
      return !String(value).match(new RegExp(regExpString));
    }
  }

}
