import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getNumberPhone'
})
export class GetNumberPhonePipe implements PipeTransform {

    transform(phones: any, args?: any): any {
        if (phones !== undefined && phones.length > 0 && phones[0].number) {
            return phones[0].number;
        } else {
            return '';
        }
    }

}
