import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../lib-shared/services/index';
import { AgencyChain, IUserInfo } from '../lib-shared/interfaces/index';

@Injectable({
    providedIn: 'root'
})
export class AgencyChangeService {

    auth = AuthenticationService.getUser();
    protected agencyChain: BehaviorSubject<number>;
    protected agency: BehaviorSubject<number>;
    protected agencyChainList: BehaviorSubject<AgencyChain[]>;

    constructor() {
        this.agencyChain = new BehaviorSubject<number>(this.auth && this.auth.agency_chain ? this.auth.agency_chain.id : null);
        this.agency = new BehaviorSubject<number>(this.auth && this.auth.agency ? this.auth.agency.id : null);
        this.agencyChainList = new BehaviorSubject<AgencyChain[]>([]);
    }

    setAgencyChain(value): void {
        this.agencyChain.next(value);
    }

    setAgency(value): void {
        this.agency.next(value);
    }

    getAgencyChain(): Observable<number> {
        return this.agencyChain.asObservable();
    }

    getAgency(): Observable<number> {
        return this.agency.asObservable();
    }

    setAgencyChainsList(data): void {
        this.agencyChainList.next(data);
    }

    getAgencyChainList(): Observable<AgencyChain[]> {
        return this.agencyChainList.asObservable();
    }

    setAgenciesUpdate(user: IUserInfo) {
        if (user.agency) {
            this.setAgency(user.agency.id);
        }
        if (user.agency_chain) {
            this.setAgencyChain(user.agency_chain.id);
        }
    }
}
