import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsLineComponent } from './steps-line.component';

@NgModule({
    declarations: [
        StepsLineComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        StepsLineComponent
    ]
})
export class StepsLineModule {
}
