import {OnInit, ViewChild, OnDestroy, HostListener, ElementRef} from '@angular/core';
import {
    IntegrationPageMetaData,
    IntegrationsService,
    NotificationPopupService,
    PackagesProviderService,
    ShareService,
    SpinnerLoaderService
} from '../lib-shared/services/index';
import {PackagesRequest, PackageRequestFromUrl, IFilter, Distribution, PackageState, IFilterPrice, IFilterPackage} from '../lib-shared/interfaces/index';
import {
  PackageSearchComponent,
  PackageSearchDto
} from '../searches/packages/index';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {map, takeUntil} from 'rxjs/operators';
import {packageParameters} from '../../mock/packages';
import {PackageListReqDto, PackageVacationPackageBooking} from '@vecib2c/frontend-dto';
import {Location} from "@angular/common";
import { splitDistributionsByPaxType } from '../lib-shared/functions/index';
import { Subject } from 'rxjs';
import * as  uuidv1_ from 'uuid/v1';
import { IDataPackage, IPackage } from '../lib-shared/interfaces/package.interface';

const uuidv1 = uuidv1_;

export class PackageSearchList implements OnInit, OnDestroy {
    @ViewChild("searchContainer",null) searchContainer: ElementRef;
    @ViewChild("stepperContainer",null) stepperContainer: ElementRef;

    private PAGE_START:number = 0;
    private PAGE_SIZE:number = 10000;
    public data: IDataPackage;
    public rows: IPackage[] = [];
    public pendingRows: IPackage[] = [];
    public resetaDate?:string;
    private currentPageNumber:number = this.PAGE_START;
    private currentPageSize:number = this.PAGE_SIZE;
    public totalItems: number = 0;   
    public packageListReq: PackageRequestFromUrl;
    public parsedOrigin: number;
    public inputFields: any;
    public passengerCount:number = 1;
    public searchShowHide:boolean = false;
    public filtersShowHide:boolean = false;
    public urlSearchParameters: string[] = [
        "origin",
        "destination",
        "checkIn",
        "checkOut",
        "nights",
        "distribution"
    ];
    public integrationMetaData: IntegrationPageMetaData;
    public filters: IFilter = {};
    public minPrice: number = 0;
    public maxPrice: number = 10000;
    public packageType: string;
    public checkMessage: string;
    public providers: string[];
    public resetDate:string= ""
    @ViewChild(PackageSearchComponent, { static: false }) packageSearchComponent: PackageSearchComponent;

    private unsubscribe = new Subject();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public share: ShareService,
        public translate: TranslateService,
        public packageService: PackagesProviderService,
        public integrationService: IntegrationsService,
        public notificationPopupService: NotificationPopupService,
        public loaderService: SpinnerLoaderService,
        public readonly location: Location,
    ) {
        this.packageListReq = new PackageRequestFromUrl();
        this.inputFields = new PackageListReqDto();
        this.integrationService.setMetaData({
            name: "packages",
            category: "Paquetes"
        });
        this.checkMessage = "dspNone";
    }

    public ngOnInit(): void {
        this.translate.setDefaultLang("es");
        this.setReqFromUrl(this.route.snapshot.queryParams);
        this.getList();
        this.passengerCount = this.getPassengerCount(this.packageListReq.distribution);
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public hasAllParameters(query): boolean {
        let allParams = true;
        this.urlSearchParameters.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                allParams = false;
            }
        });
        return allParams;
    }

    public setReqFromUrl(query) :void {
        
        Object.keys(query).forEach(key => {
            this.packageListReq[key] = key === "distribution" || key === "filter" || key === 'origin' || key === "options"
            || key === "options_combo" ? JSON.parse(query[key]) : query[key];
        });
    }

    public getList() :void {

        const request = this.buildPackageRequest();

        this.packageService.list(request)
            .pipe(
                map(res => res.data ? res.data : res),
                takeUntil(this.unsubscribe),
            )
            .subscribe(data => {
                    this.saveResponseData();
                    this.data = data;
                    if (this.packageListReq["filterName"])
                    this.data.rows = this.data.rows.filter((x) => x.name.toLowerCase().includes(this.packageListReq["filterName"].toLowerCase()));
                    this.rows = this.data.rows.length > 10 ? this.data.rows.slice(0, 10) : this.data.rows;
                    this.pendingRows = this.data.rows.length > 10 ? this.data.rows.slice(10, this.data.rows.length) : [];
                    this.minPrice = this.data.minPrice || this.minPrice;
                    this.maxPrice = this.data.maxPrice || this.maxPrice;
                    this.providers = this.data.providers;
                    this.resetDate = this.data.resetaDate;
                    // this.totalItems = this.data.rows.length;
                    // TODO El count no esta pasando. Falta por implementarlo en el package dto
                    this.totalItems = data.count;
                    if (data.count === 0) {
                        this.checkMessage = "dsp-block";
                    } else {
                        this.checkMessage = "dspNone";
                    }
                },
                err => {
                    console.log(err);
                });
    }

    public saveResponseData() :void {
        this.share.empty();
        const dtoRequest = this.packageService.buildDto(this.packageListReq);

        packageParameters.forEach(parameter => {
            if (parameter === 'filter') {
                return false;
            }
            if (dtoRequest[parameter]) {
                this.share.set(parameter, dtoRequest[parameter]);
            }
        });


    }

    public pageChanged($event) :void {
        this.currentPageNumber = $event.pageIndex;
        this.currentPageSize = $event.pageSize;
        this.getList();
    }

    public setPagination() :void {
        this.currentPageNumber = this.PAGE_START;
        this.currentPageSize = this.PAGE_SIZE;
    }

    public onDataChanged(params: PackageSearchDto) :void {
        Object.keys(params).forEach(key => {
            this.packageListReq[key] = params[key];
        });
        this.setPagination();
        this.getList();
        this.passengerCount = this.getPassengerCount(this.packageListReq.distribution);
    }

    public toggleSearcher(nameElement) :void {
        switch (nameElement) {
            case 'search':
                this.searchShowHide = !this.searchShowHide;
                this.filtersShowHide = false;
                break;
            case 'filters':
                this.filtersShowHide = !this.filtersShowHide;
                this.searchShowHide = false;
                break;
        }
    }

    public onFilterChanged(filter: IFilterPackage): void {
        let filteredRows: IPackage[] = JSON.parse(JSON.stringify(this.data.rows));
        filteredRows = filter.stars && filter.stars.length !== 0 ? this.filterStars(filter.stars, filteredRows) : filteredRows;
        filteredRows = filter.rates && filter.rates.length != 0 ? this.filterRate(filter.rates, filteredRows) : filteredRows;
        filteredRows = filter.name && filter.name !== "" ? this.filterHotelName(filter.name, filteredRows) : filteredRows;
        filteredRows = filter.prices ? this.filterPrice(filter.prices, filteredRows) : filteredRows;
        this.rows = filteredRows.length > 10 ? filteredRows.slice(0, 10) : filteredRows;
        this.pendingRows = filteredRows.length > 10 ? filteredRows.slice(10, filteredRows.length) : [];
    }


    public selectedPackageType(event) :void {
        this.packageType = event;
    }

    public emitBookingTicket(row) :void {
        this.loaderService.show();
        this.packageService
            .preBookTickets(row, row.packageType, this.packageService.buildDto(this.packageListReq))
            .pipe(
                map(res => res.data ? res.data : res),
            ).subscribe(response => {
            if (response && !response.error) {
                const navigationExtras = this.buildNavigationExtras(
                    response,
                    row.packageType,
                );

                this.router.navigate(
                    ['integration', 'bookings', 'book-edit', 'packages', navigationExtras.state.package.uuid],
                    navigationExtras
                );
            } else if (response && response.error) {
                this.notificationPopupService.openPopup(response.error.join(','));
                this.location.back();
            } else {
                this.notificationPopupService.openPopup("SERVER_ERROR");
            }
        }, err => {
            this.loaderService.hide();
            console.log(err);
        });
    }

    private buildPackageRequest(): PackagesRequest {
        const request = new PackagesRequest();

        
        if (this.packageListReq.origin) {
            request.origin = this.packageListReq.origin.iata;
        }
        if (this.packageListReq.options) {
            request.options = this.packageListReq.options;
        }
        request.checkIn = this.packageListReq.checkIn;
        request.destination = this.packageListReq.destination;
        request.distribution = JSON.stringify(this.packageListReq.distribution);
        request.nights = this.packageListReq.nights;
        request.page = this.currentPageNumber + 1;
        request.pageSize = this.currentPageSize;
        request.destination = this.packageListReq.destination;
        request.filter = JSON.stringify(this.filters);
        request.productId=this.packageListReq.productID
        request.productIdTourMundial = this.packageListReq.productIdTourMundial
        request.resetDate=this.resetDate || this.packageListReq.checkIn
        request.destinationType = this.packageService.transformPackageNameToDestinationType(this.packageListReq.package);

        request.providers =this.packageListReq.providers;
        
        return request;
    }

    private getPassengerCount(distributions: Distribution[]): number {
        const { adults, children, infants } = splitDistributionsByPaxType(distributions);
        return adults + children + infants;
    }

    private buildNavigationExtras(
        preBookingResponse: PackageVacationPackageBooking,
        packageType: string,
    ): NavigationExtras {

        const packageState: PackageState = PackageState.build(
            uuidv1(),
            preBookingResponse,
            packageType,
        );

        const navigationExtras: NavigationExtras = {
            state: {
                package: packageState,
            }
        };
        return navigationExtras;
    }

    public goTop(): void {
        this.stepperContainer.nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }

    private filterStars(stars: string[], filteredRows: IPackage[]): IPackage[] {
        this.rows = filteredRows.filter((x) => stars.map((x) => String(x)).includes((x.category.value)));
        return this.rows;
    }
    
    private filterRate(regimes: string[], filteredRows: IPackage[]): IPackage[] {
        regimes = regimes.map((regime) =>
            regime
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
        );
        let rows: IPackage[] = filteredRows;

        rows.forEach((hotel) => {
            hotel.rooms.forEach((room) => {
            let tmpRates = [];
            room.rates
                .filter((rate) =>
                regimes.includes(
                    rate.category
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, ""),
                ),
                )
                .forEach((r, rIndex) =>
                tmpRates.push(
                    room.rates.filter((rate) =>
                    regimes.includes(
                        rate.category
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, ""),
                    ),
                    )[rIndex],
                ),
                );
            room.rates = tmpRates;
            });
            hotel.rooms = [...hotel.rooms.filter((room) => room.rates.length !== 0)];
        });
        rows = [...rows.filter((hotel) => hotel.rooms.length !== 0)];

        return rows;
    }

    private filterHotelName(hotelName: string, filteredRows: IPackage[]): IPackage[] {
        this.rows = filteredRows.filter((row) => row.name.toLowerCase().includes(hotelName.toLowerCase()));
        return this.rows;
    }

    private filterPrice(priceRange: IFilterPrice, filteredRows: IPackage[]): IPackage[] {
        let rows: IPackage[] = filteredRows;

        rows.forEach((hotel) => {
            hotel.rooms.forEach((room) => {
            let tmpRates = [];
            room.rates
                .filter((rate) => rate.price >= priceRange.min && rate.price <= priceRange.max)
                .forEach((r, rIndex) =>
                tmpRates.push(room.rates.filter((rate) => rate.price >= priceRange.min && rate.price <= priceRange.max)[rIndex]),
                );
            room.rates = tmpRates;
            });
            hotel.rooms = [...hotel.rooms.filter((room) => room.rates.length !== 0)];
        });
        rows = [...rows.filter((hotel) => hotel.rooms.length !== 0)];

        return rows;
    }

    @HostListener("scroll", ["$event"])
    public scrollDown(event): void {
        if (
        event.target.offsetParent.offsetHeight + event.srcElement.scrollTop >
            this.searchContainer.nativeElement.offsetHeight - window.innerHeight * 0.1 &&
        this.pendingRows.length > 0
        ) {
        const pushRows: IPackage[] = this.pendingRows.length > 10 ? this.pendingRows.slice(0, 10) : this.pendingRows;
        this.pendingRows = this.pendingRows.length > 10 ? this.pendingRows.slice(10, this.pendingRows.length) : [];
        this.rows.push(...pushRows);
        }
    }
}
