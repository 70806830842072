export class FlightFilter {
    minPrice?: number;
    maxPrice?: number;
    companies?: string[][];
    baggages?: number[][];
    scales?: number[][];
    waitingTimes?: number[][];
    minWaitingTimes?: number[];
    maxWaitingTimes?: number[];
    departureTimes?: number[][];
    minDepartureTimes?: number[];
    maxDepartureTimes?: number[];
}
