import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule
} from '@angular/material';
import { IonicModule } from '@ionic/angular';
import { CircuitSearchComponent } from './circuit-search.component';
import { PaxesHotelModule } from '../hotels/index';
import { LibSharedModule } from '../../lib-shared/index';
import { MaterialDatePickerModule } from "../../material-date-picker/material-date-picker.module";

@NgModule({
    declarations: [
        CircuitSearchComponent
    ],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        PaxesHotelModule,
        MatExpansionModule,
        IonicModule,
        LibSharedModule,
        MaterialDatePickerModule,
    ],
    exports: [
        CircuitSearchComponent
    ]
})
export class CircuitsSearchModule {
}
