import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ShoppingBasketTransfer } from '../../../lib-shared/interfaces/shopping-basket-transfers.model';
import { transferPaxType, TransferPax } from '../../../lib-shared/interfaces/transfers/request/booking-create.request.dto';

@Component({
    selector: 'lib-transfer-book-summary',
    templateUrl: './transfer-book-summary.component.html',
    styleUrls: ['./transfer-book-summary.component.scss']
})

export class TransferBookSummaryComponent implements OnInit, OnDestroy {

    @Input() shoppingBasket: ShoppingBasketTransfer;

    transferPaxType: TransferPax[] =  transferPaxType; 
    render: boolean;
    roundtrip: string; 
    fromTime: string;
    toTime: string;

    constructor() {
    }

    ngOnInit(): void {
        this.renderFromTime(this.shoppingBasket.from.dateTime);
        this.renderToTime(this.shoppingBasket.to.dateTime);
    }

    ngOnDestroy(): void {
        
    }

    changePaxType(input: string){
        switch(input){
            case "A":
                return "Adulto"
            case "C":
                return "Niño"
            case "B":
                return "Niño menor de 4 años"
            case "S":
                return "Senior"
        }
    }

    renderFromAndToDestination(type: string){
        if(type == "ROU"){
            this.render = true;
            this.roundtrip = "IDA Y VUELTA"
        } else {
            if(type == "ARR"){
                this.render = true
                this.roundtrip = "SOLO IDA"
            } 
            if(type == "DEP"){
                this.render = false
                this.roundtrip = "SOLO IDA"
            }
        }
    }

    renderFromTime(input){
        const date = input;
        const [yyyy,mm,dd,hh,mi] = date.split(/[/:\-T]/)
        this.fromTime = hh+":"+mi
        return this.fromTime
    }

    renderToTime(input){
        const date = input;
        const [yyyy,mm,dd,hh,mi] = date.split(/[/:\-T]/)
        this.toTime = hh+":"+mi
        return this.toTime
    }

}