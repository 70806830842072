import { NativeDateAdapter } from '@angular/material';
import { MatDateFormats } from '@angular/material/core';
import { formatDate } from '@angular/common';
import * as moment_ from 'moment';

const moment = moment_;

export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            return formatDate(date, 'dd/MM/yyyy', 'es');
        } else {
            return date.toDateString();
        }
    }

    parse(value: any): Date | null {
        const date = moment(value, 'DD/MM/YYYY');
        return date.isValid() ? date.toDate() : null;
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    },
    display: {
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'numeric'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
};
