import { NgModule } from "@angular/core";
import { MatTableModule } from "@angular/material";
import { LibSharedModule } from "../../../../lib-shared/lib-shared.module";
import { PrepaidListComponent } from "./prepaid-list.component";

@NgModule({
    declarations: [
        PrepaidListComponent,
    ],
    imports: [
        LibSharedModule,
        MatTableModule,
    ],
    exports: [
        PrepaidListComponent,
    ]
})
export class PrepaidListModule {

}
