import { Pipe, PipeTransform } from "@angular/core";
import { ExtraDetail, SupplierInfoDetail } from "../../interfaces/cars/availability.res.model";

@Pipe({
    name: 'getExtraDetailsBySupplierCar'
})
export class GetExtraDetailsBySupplierCarPipe implements PipeTransform {

    transform(supplierInfo: SupplierInfoDetail[], supplierCarName: string): ExtraDetail[] {
        if (!supplierCarName) { return; }
        const supplierInfoFound = supplierInfo.find(s => s.Supplier === supplierCarName);
        if (!supplierInfoFound || !supplierInfoFound.AvailableExtras) { return; }
        return supplierInfoFound.AvailableExtras.ExtraDetails;
    }
}
