import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterRoomByMultidistribution'
})
export class FilterRoomByMultidistributionPipe implements PipeTransform {

    transform(rooms: any[], providerHasMultidistribution: boolean): any[] {
        if (!rooms) { return; }

        if (providerHasMultidistribution) {
          return rooms.slice(0, 1);
        }

        return rooms;
    }
}
