import { AfterContentInit, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatPaginator } from '@angular/material';

import { TranslateService } from '@ngx-translate/core';

import { EntityService } from '../services/index';
import t from 'typy';
import { DialogComponent } from '../../dialog/dialog.component';
import { GraphqlService } from '../services/graphql.service';

export class GraphQlListEntity
    implements OnInit, AfterContentInit {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    protected graphQlQuery: String = '';
    protected dataPath = '';
    data: [];
    currentPageNumber = 0;
    currentPageSize = 10;
    totalItems = 0;
    pageSizeOptions: number[] = [5, 10, 15];
    dataSource;
    displayedColumns = [];
    entityNavigate;
    public entityService: EntityService;

    constructor(
        public dialog: MatDialog,
        public translate: TranslateService,
        public router: Router,
        public graphql: GraphqlService
    ) {
    }

    ngOnInit() {
        this.translate.setDefaultLang('es');
    }

    ngAfterContentInit() {
        this.paginator._intl.itemsPerPageLabel = this.translate.instant(
            'elementos por página'
        );
    }

    add() {
        this.router.navigateByUrl(this.entityNavigate + '/create');
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    openDetail(item) {
        this.router.navigateByUrl(this.entityNavigate + '/edit/' + item.id);
    }

    public ionViewWillEnter(): void {
        this.getList();
    }

    getList() {
        this.graphql.query(this.graphQlQuery,
            {
                page: (this.currentPageNumber + 1),
                pageSize: this.currentPageSize
            }).subscribe((graphQlData: any) => {
            const data = t(graphQlData, this.dataPath).safeObject;
            this.totalItems = data.count;
            this.data = data.rows;

        });
    }

    pageChanged($event) {
        this.currentPageNumber = $event.pageIndex;
        this.currentPageSize = $event.pageSize;
        this.getList();
    }

    openDialog(id): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {question: 'Quieres eliminar el registro?', id: id}
        });

        dialogRef.afterClosed().subscribe(id => {
            if (id) {
                this.entityService.delete(id).subscribe(data => {
                    this.getList();
                });
            }
        });
    }

    back() {
        this.router.navigateByUrl('intranet');
    }

    navigateTo(route: string): void {
        this.router.navigateByUrl(route);
    }
}
