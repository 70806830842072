import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { PackageCommission } from '@vecib2c/frontend-dto';

@Component({
    selector: 'lib-circuits-price-summary',
    templateUrl: './circuits-price-summary.component.html',
    styleUrls: ['./circuits-price-summary.component.scss']
})
export class CircuitsPriceSummaryComponent implements OnInit, OnDestroy {

    @Input() commission: PackageCommission;
    @Input() price: number;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

    }

}
