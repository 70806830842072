export const FLIGHTS_AIRPORTS = {
    "GKA": {
        "name": "Goroka Airport",
        "city": "Goroka",
        "country": "Papua New Guinea",
        "iata": "GKA",
        "icao": "AYGA",
        "latitude": "-6.081689834590001",
        "longitude": "145.391998291",
        "altitude": "5282",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "MAG": {
        "name": "Madang Airport",
        "city": "Madang",
        "country": "Papua New Guinea",
        "iata": "MAG",
        "icao": "AYMD",
        "latitude": "-5.20707988739",
        "longitude": "145.789001465",
        "altitude": "20",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "HGU": {
        "name": "Mount Hagen Kagamuga Airport",
        "city": "Mount Hagen",
        "country": "Papua New Guinea",
        "iata": "HGU",
        "icao": "AYMH",
        "latitude": "-5.826789855957031",
        "longitude": "144.29600524902344",
        "altitude": "5388",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "LAE": {
        "name": "Nadzab Airport",
        "city": "Nadzab",
        "country": "Papua New Guinea",
        "iata": "LAE",
        "icao": "AYNZ",
        "latitude": "-6.569803",
        "longitude": "146.725977",
        "altitude": "239",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "POM": {
        "name": "Port Moresby Jacksons International Airport",
        "city": "Port Moresby",
        "country": "Papua New Guinea",
        "iata": "POM",
        "icao": "AYPY",
        "latitude": "-9.443380355834961",
        "longitude": "147.22000122070312",
        "altitude": "146",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "WWK": {
        "name": "Wewak International Airport",
        "city": "Wewak",
        "country": "Papua New Guinea",
        "iata": "WWK",
        "icao": "AYWK",
        "latitude": "-3.58383011818",
        "longitude": "143.669006348",
        "altitude": "19",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "UAK": {
        "name": "Narsarsuaq Airport",
        "city": "Narssarssuaq",
        "country": "Greenland",
        "iata": "UAK",
        "icao": "BGBW",
        "latitude": "61.1604995728",
        "longitude": "-45.4259986877",
        "altitude": "112",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "GOH": {
        "name": "Godthaab / Nuuk Airport",
        "city": "Godthaab",
        "country": "Greenland",
        "iata": "GOH",
        "icao": "BGGH",
        "latitude": "64.19090271",
        "longitude": "-51.6781005859",
        "altitude": "283",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "SFJ": {
        "name": "Kangerlussuaq Airport",
        "city": "Sondrestrom",
        "country": "Greenland",
        "iata": "SFJ",
        "icao": "BGSF",
        "latitude": "67.0122218992",
        "longitude": "-50.7116031647",
        "altitude": "165",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "THU": {
        "name": "Thule Air Base",
        "city": "Thule",
        "country": "Greenland",
        "iata": "THU",
        "icao": "BGTL",
        "latitude": "76.5311965942",
        "longitude": "-68.7032012939",
        "altitude": "251",
        "timezone": "-4",
        "dst": "E",
        "tz_name": "America/Thule"
    },
    "AEY": {
        "name": "Akureyri Airport",
        "city": "Akureyri",
        "country": "Iceland",
        "iata": "AEY",
        "icao": "BIAR",
        "latitude": "65.66000366210938",
        "longitude": "-18.07270050048828",
        "altitude": "6",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "EGS": {
        "name": "Egilsstaðir Airport",
        "city": "Egilsstadir",
        "country": "Iceland",
        "iata": "EGS",
        "icao": "BIEG",
        "latitude": "65.2833023071289",
        "longitude": "-14.401399612426758",
        "altitude": "76",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "HFN": {
        "name": "Hornafjörður Airport",
        "city": "Hofn",
        "country": "Iceland",
        "iata": "HFN",
        "icao": "BIHN",
        "latitude": "64.295601",
        "longitude": "-15.2272",
        "altitude": "24",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "HZK": {
        "name": "Húsavík Airport",
        "city": "Husavik",
        "country": "Iceland",
        "iata": "HZK",
        "icao": "BIHU",
        "latitude": "65.952301",
        "longitude": "-17.426001",
        "altitude": "48",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "IFJ": {
        "name": "Ísafjörður Airport",
        "city": "Isafjordur",
        "country": "Iceland",
        "iata": "IFJ",
        "icao": "BIIS",
        "latitude": "66.05809783935547",
        "longitude": "-23.135299682617188",
        "altitude": "8",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "KEF": {
        "name": "Keflavik International Airport",
        "city": "Keflavik",
        "country": "Iceland",
        "iata": "KEF",
        "icao": "BIKF",
        "latitude": "63.985000610352",
        "longitude": "-22.605600357056",
        "altitude": "171",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "PFJ": {
        "name": "Patreksfjörður Airport",
        "city": "Patreksfjordur",
        "country": "Iceland",
        "iata": "PFJ",
        "icao": "BIPA",
        "latitude": "65.555801",
        "longitude": "-23.965",
        "altitude": "11",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "RKV": {
        "name": "Reykjavik Airport",
        "city": "Reykjavik",
        "country": "Iceland",
        "iata": "RKV",
        "icao": "BIRK",
        "latitude": "64.1299972534",
        "longitude": "-21.9405994415",
        "altitude": "48",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "SIJ": {
        "name": "Siglufjörður Airport",
        "city": "Siglufjordur",
        "country": "Iceland",
        "iata": "SIJ",
        "icao": "BISI",
        "latitude": "66.133301",
        "longitude": "-18.9167",
        "altitude": "10",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "VEY": {
        "name": "Vestmannaeyjar Airport",
        "city": "Vestmannaeyjar",
        "country": "Iceland",
        "iata": "VEY",
        "icao": "BIVM",
        "latitude": "63.42430114746094",
        "longitude": "-20.278900146484375",
        "altitude": "326",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "YAM": {
        "name": "Sault Ste Marie Airport",
        "city": "Sault Sainte Marie",
        "country": "Canada",
        "iata": "YAM",
        "icao": "CYAM",
        "latitude": "46.48500061035156",
        "longitude": "-84.5093994140625",
        "altitude": "630",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YAY": {
        "name": "St. Anthony Airport",
        "city": "St. Anthony",
        "country": "Canada",
        "iata": "YAY",
        "icao": "CYAY",
        "latitude": "51.3918991089",
        "longitude": "-56.083099365200006",
        "altitude": "108",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YAZ": {
        "name": "Tofino / Long Beach Airport",
        "city": "Tofino",
        "country": "Canada",
        "iata": "YAZ",
        "icao": "CYAZ",
        "latitude": "49.079833",
        "longitude": "-125.775583",
        "altitude": "80",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YBB": {
        "name": "Kugaaruk Airport",
        "city": "Pelly Bay",
        "country": "Canada",
        "iata": "YBB",
        "icao": "CYBB",
        "latitude": "68.534401",
        "longitude": "-89.808098",
        "altitude": "56",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YBC": {
        "name": "Baie Comeau Airport",
        "city": "Baie Comeau",
        "country": "Canada",
        "iata": "YBC",
        "icao": "CYBC",
        "latitude": "49.13249969482422",
        "longitude": "-68.20439910888672",
        "altitude": "71",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YBG": {
        "name": "CFB Bagotville",
        "city": "Bagotville",
        "country": "Canada",
        "iata": "YBG",
        "icao": "CYBG",
        "latitude": "48.33060073852539",
        "longitude": "-70.99639892578125",
        "altitude": "522",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YBK": {
        "name": "Baker Lake Airport",
        "city": "Baker Lake",
        "country": "Canada",
        "iata": "YBK",
        "icao": "CYBK",
        "latitude": "64.29889678960001",
        "longitude": "-96.077796936",
        "altitude": "59",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YBL": {
        "name": "Campbell River Airport",
        "city": "Campbell River",
        "country": "Canada",
        "iata": "YBL",
        "icao": "CYBL",
        "latitude": "49.950801849365234",
        "longitude": "-125.27100372314453",
        "altitude": "346",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YBR": {
        "name": "Brandon Municipal Airport",
        "city": "Brandon",
        "country": "Canada",
        "iata": "YBR",
        "icao": "CYBR",
        "latitude": "49.91",
        "longitude": "-99.951897",
        "altitude": "1343",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YCB": {
        "name": "Cambridge Bay Airport",
        "city": "Cambridge Bay",
        "country": "Canada",
        "iata": "YCB",
        "icao": "CYCB",
        "latitude": "69.1081008911",
        "longitude": "-105.138000488",
        "altitude": "90",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YCD": {
        "name": "Nanaimo Airport",
        "city": "Nanaimo",
        "country": "Canada",
        "iata": "YCD",
        "icao": "CYCD",
        "latitude": "49.054970224899996",
        "longitude": "-123.869862556",
        "altitude": "92",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YCG": {
        "name": "Castlegar/West Kootenay Regional Airport",
        "city": "Castlegar",
        "country": "Canada",
        "iata": "YCG",
        "icao": "CYCG",
        "latitude": "49.2963981628",
        "longitude": "-117.632003784",
        "altitude": "1624",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YCH": {
        "name": "Miramichi Airport",
        "city": "Chatham",
        "country": "Canada",
        "iata": "YCH",
        "icao": "CYCH",
        "latitude": "47.007801",
        "longitude": "-65.449203",
        "altitude": "108",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YCL": {
        "name": "Charlo Airport",
        "city": "Charlo",
        "country": "Canada",
        "iata": "YCL",
        "icao": "CYCL",
        "latitude": "47.990799",
        "longitude": "-66.330299",
        "altitude": "132",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YCO": {
        "name": "Kugluktuk Airport",
        "city": "Coppermine",
        "country": "Canada",
        "iata": "YCO",
        "icao": "CYCO",
        "latitude": "67.816704",
        "longitude": "-115.143997",
        "altitude": "74",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YCT": {
        "name": "Coronation Airport",
        "city": "Coronation",
        "country": "Canada",
        "iata": "YCT",
        "icao": "CYCT",
        "latitude": "52.0750007629",
        "longitude": "-111.444999695",
        "altitude": "2595",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YCW": {
        "name": "Chilliwack Airport",
        "city": "Chilliwack",
        "country": "Canada",
        "iata": "YCW",
        "icao": "CYCW",
        "latitude": "49.1528015137",
        "longitude": "-121.939002991",
        "altitude": "32",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YCY": {
        "name": "Clyde River Airport",
        "city": "Clyde River",
        "country": "Canada",
        "iata": "YCY",
        "icao": "CYCY",
        "latitude": "70.4860992432",
        "longitude": "-68.5167007446",
        "altitude": "87",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YZS": {
        "name": "Coral Harbour Airport",
        "city": "Coral Harbour",
        "country": "Canada",
        "iata": "YZS",
        "icao": "CYZS",
        "latitude": "64.1932983398",
        "longitude": "-83.3593978882",
        "altitude": "210",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Coral_Harbour"
    },
    "YDA": {
        "name": "Dawson City Airport",
        "city": "Dawson",
        "country": "Canada",
        "iata": "YDA",
        "icao": "CYDA",
        "latitude": "64.04309844970703",
        "longitude": "-139.1280059814453",
        "altitude": "1215",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YDB": {
        "name": "Burwash Airport",
        "city": "Burwash",
        "country": "Canada",
        "iata": "YDB",
        "icao": "CYDB",
        "latitude": "61.37110137939453",
        "longitude": "-139.04100036621094",
        "altitude": "2647",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YDF": {
        "name": "Deer Lake Airport",
        "city": "Deer Lake",
        "country": "Canada",
        "iata": "YDF",
        "icao": "CYDF",
        "latitude": "49.21080017089844",
        "longitude": "-57.39139938354492",
        "altitude": "72",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YDL": {
        "name": "Dease Lake Airport",
        "city": "Dease Lake",
        "country": "Canada",
        "iata": "YDL",
        "icao": "CYDL",
        "latitude": "58.4221992493",
        "longitude": "-130.031997681",
        "altitude": "2600",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YDN": {
        "name": "Dauphin Barker Airport",
        "city": "Dauphin",
        "country": "Canada",
        "iata": "YDN",
        "icao": "CYDN",
        "latitude": "51.100799560546875",
        "longitude": "-100.052001953125",
        "altitude": "999",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YDQ": {
        "name": "Dawson Creek Airport",
        "city": "Dawson Creek",
        "country": "Canada",
        "iata": "YDQ",
        "icao": "CYDQ",
        "latitude": "55.7422981262207",
        "longitude": "-120.18299865722656",
        "altitude": "2148",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Dawson_Creek"
    },
    "YEG": {
        "name": "Edmonton International Airport",
        "city": "Edmonton",
        "country": "Canada",
        "iata": "YEG",
        "icao": "CYEG",
        "latitude": "53.309700012200004",
        "longitude": "-113.580001831",
        "altitude": "2373",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YEK": {
        "name": "Arviat Airport",
        "city": "Eskimo Point",
        "country": "Canada",
        "iata": "YEK",
        "icao": "CYEK",
        "latitude": "61.0942001343",
        "longitude": "-94.07080078119999",
        "altitude": "32",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YEN": {
        "name": "Estevan Airport",
        "city": "Estevan",
        "country": "Canada",
        "iata": "YEN",
        "icao": "CYEN",
        "latitude": "49.2103004456",
        "longitude": "-102.966003418",
        "altitude": "1905",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YET": {
        "name": "Edson Airport",
        "city": "Edson",
        "country": "Canada",
        "iata": "YET",
        "icao": "CYET",
        "latitude": "53.578899383499994",
        "longitude": "-116.464996338",
        "altitude": "3043",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YEU": {
        "name": "Eureka Airport",
        "city": "Eureka",
        "country": "Canada",
        "iata": "YEU",
        "icao": "CYEU",
        "latitude": "79.9946975708",
        "longitude": "-85.814201355",
        "altitude": "256",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YEV": {
        "name": "Inuvik Mike Zubko Airport",
        "city": "Inuvik",
        "country": "Canada",
        "iata": "YEV",
        "icao": "CYEV",
        "latitude": "68.30419921880001",
        "longitude": "-133.483001709",
        "altitude": "224",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YFB": {
        "name": "Iqaluit Airport",
        "city": "Iqaluit",
        "country": "Canada",
        "iata": "YFB",
        "icao": "CYFB",
        "latitude": "63.756402",
        "longitude": "-68.555801",
        "altitude": "110",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YFC": {
        "name": "Fredericton Airport",
        "city": "Fredericton",
        "country": "Canada",
        "iata": "YFC",
        "icao": "CYFC",
        "latitude": "45.868900299072266",
        "longitude": "-66.53720092773438",
        "altitude": "68",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YFE": {
        "name": "Forestville Airport",
        "city": "Forestville",
        "country": "Canada",
        "iata": "YFE",
        "icao": "CYFE",
        "latitude": "48.74610137939453",
        "longitude": "-69.09719848632812",
        "altitude": "293",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YFO": {
        "name": "Flin Flon Airport",
        "city": "Flin Flon",
        "country": "Canada",
        "iata": "YFO",
        "icao": "CYFO",
        "latitude": "54.6781005859375",
        "longitude": "-101.68199920654297",
        "altitude": "997",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YFR": {
        "name": "Fort Resolution Airport",
        "city": "Fort Resolution",
        "country": "Canada",
        "iata": "YFR",
        "icao": "CYFR",
        "latitude": "61.1808013916",
        "longitude": "-113.690002441",
        "altitude": "526",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YFS": {
        "name": "Fort Simpson Airport",
        "city": "Fort Simpson",
        "country": "Canada",
        "iata": "YFS",
        "icao": "CYFS",
        "latitude": "61.76020050048828",
        "longitude": "-121.23699951171875",
        "altitude": "555",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YGK": {
        "name": "Kingston Norman Rogers Airport",
        "city": "Kingston",
        "country": "Canada",
        "iata": "YGK",
        "icao": "CYGK",
        "latitude": "44.22529983520508",
        "longitude": "-76.5969009399414",
        "altitude": "305",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGL": {
        "name": "La Grande Rivière Airport",
        "city": "La Grande Riviere",
        "country": "Canada",
        "iata": "YGL",
        "icao": "CYGL",
        "latitude": "53.625301361083984",
        "longitude": "-77.7042007446289",
        "altitude": "639",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGP": {
        "name": "Gaspé (Michel-Pouliot) Airport",
        "city": "Gaspe",
        "country": "Canada",
        "iata": "YGP",
        "icao": "CYGP",
        "latitude": "48.7752990723",
        "longitude": "-64.4785995483",
        "altitude": "112",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGQ": {
        "name": "Geraldton Greenstone Regional Airport",
        "city": "Geraldton",
        "country": "Canada",
        "iata": "YGQ",
        "icao": "CYGQ",
        "latitude": "49.77830123901367",
        "longitude": "-86.93939971923828",
        "altitude": "1144",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGR": {
        "name": "Îles-de-la-Madeleine Airport",
        "city": "Iles De La Madeleine",
        "country": "Canada",
        "iata": "YGR",
        "icao": "CYGR",
        "latitude": "47.42470169067383",
        "longitude": "-61.778099060058594",
        "altitude": "35",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHB": {
        "name": "Hudson Bay Airport",
        "city": "Hudson Bay",
        "country": "Canada",
        "iata": "YHB",
        "icao": "CYHB",
        "latitude": "52.8166999817",
        "longitude": "-102.310997009",
        "altitude": "1175",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YHD": {
        "name": "Dryden Regional Airport",
        "city": "Dryden",
        "country": "Canada",
        "iata": "YHD",
        "icao": "CYHD",
        "latitude": "49.831699",
        "longitude": "-92.744202",
        "altitude": "1354",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YHI": {
        "name": "Ulukhaktok Holman Airport",
        "city": "Holman Island",
        "country": "Canada",
        "iata": "YHI",
        "icao": "CYHI",
        "latitude": "70.76280212402344",
        "longitude": "-117.80599975585938",
        "altitude": "117",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YHK": {
        "name": "Gjoa Haven Airport",
        "city": "Gjoa Haven",
        "country": "Canada",
        "iata": "YHK",
        "icao": "CYHK",
        "latitude": "68.635597229",
        "longitude": "-95.84970092770001",
        "altitude": "152",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YHM": {
        "name": "John C. Munro Hamilton International Airport",
        "city": "Hamilton",
        "country": "Canada",
        "iata": "YHM",
        "icao": "CYHM",
        "latitude": "43.173599243199995",
        "longitude": "-79.93499755859999",
        "altitude": "780",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHU": {
        "name": "Montréal / Saint-Hubert Airport",
        "city": "Montreal",
        "country": "Canada",
        "iata": "YHU",
        "icao": "CYHU",
        "latitude": "45.5175018311",
        "longitude": "-73.4169006348",
        "altitude": "90",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHY": {
        "name": "Hay River / Merlyn Carter Airport",
        "city": "Hay River",
        "country": "Canada",
        "iata": "YHY",
        "icao": "CYHY",
        "latitude": "60.8396987915",
        "longitude": "-115.782997131",
        "altitude": "541",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YHZ": {
        "name": "Halifax / Stanfield International Airport",
        "city": "Halifax",
        "country": "Canada",
        "iata": "YHZ",
        "icao": "CYHZ",
        "latitude": "44.8807983398",
        "longitude": "-63.5085983276",
        "altitude": "477",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YIB": {
        "name": "Atikokan Municipal Airport",
        "city": "Atikokan",
        "country": "Canada",
        "iata": "YIB",
        "icao": "CYIB",
        "latitude": "48.7738990784",
        "longitude": "-91.6386032104",
        "altitude": "1408",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Coral_Harbour"
    },
    "YIO": {
        "name": "Pond Inlet Airport",
        "city": "Pond Inlet",
        "country": "Canada",
        "iata": "YIO",
        "icao": "CYIO",
        "latitude": "72.6832962036",
        "longitude": "-77.9666976929",
        "altitude": "181",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YJN": {
        "name": "St Jean Airport",
        "city": "St. Jean",
        "country": "Canada",
        "iata": "YJN",
        "icao": "CYJN",
        "latitude": "45.29439926147461",
        "longitude": "-73.28109741210938",
        "altitude": "136",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YJT": {
        "name": "Stephenville Airport",
        "city": "Stephenville",
        "country": "Canada",
        "iata": "YJT",
        "icao": "CYJT",
        "latitude": "48.5442008972168",
        "longitude": "-58.54999923706055",
        "altitude": "84",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YKA": {
        "name": "Kamloops Airport",
        "city": "Kamloops",
        "country": "Canada",
        "iata": "YKA",
        "icao": "CYKA",
        "latitude": "50.7022018433",
        "longitude": "-120.444000244",
        "altitude": "1133",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YKF": {
        "name": "Waterloo Airport",
        "city": "Waterloo",
        "country": "Canada",
        "iata": "YKF",
        "icao": "CYKF",
        "latitude": "43.460800170899994",
        "longitude": "-80.3786010742",
        "altitude": "1055",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YKL": {
        "name": "Schefferville Airport",
        "city": "Schefferville",
        "country": "Canada",
        "iata": "YKL",
        "icao": "CYKL",
        "latitude": "54.805301666259766",
        "longitude": "-66.8052978515625",
        "altitude": "1709",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YKY": {
        "name": "Kindersley Airport",
        "city": "Kindersley",
        "country": "Canada",
        "iata": "YKY",
        "icao": "CYKY",
        "latitude": "51.5175018311",
        "longitude": "-109.180999756",
        "altitude": "2277",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YKZ": {
        "name": "Buttonville Municipal Airport",
        "city": "Toronto",
        "country": "Canada",
        "iata": "YKZ",
        "icao": "CYKZ",
        "latitude": "43.86220169067383",
        "longitude": "-79.37000274658203",
        "altitude": "650",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YLD": {
        "name": "Chapleau Airport",
        "city": "Chapleau",
        "country": "Canada",
        "iata": "YLD",
        "icao": "CYLD",
        "latitude": "47.81999969482422",
        "longitude": "-83.3467025756836",
        "altitude": "1470",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YLJ": {
        "name": "Meadow Lake Airport",
        "city": "Meadow Lake",
        "country": "Canada",
        "iata": "YLJ",
        "icao": "CYLJ",
        "latitude": "54.125301361083984",
        "longitude": "-108.52300262451172",
        "altitude": "1576",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YLL": {
        "name": "Lloydminster Airport",
        "city": "Lloydminster",
        "country": "Canada",
        "iata": "YLL",
        "icao": "CYLL",
        "latitude": "53.309200286865234",
        "longitude": "-110.072998046875",
        "altitude": "2193",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YLT": {
        "name": "Alert Airport",
        "city": "Alert",
        "country": "Canada",
        "iata": "YLT",
        "icao": "CYLT",
        "latitude": "82.51779937740001",
        "longitude": "-62.2806015015",
        "altitude": "100",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YLW": {
        "name": "Kelowna International Airport",
        "city": "Kelowna",
        "country": "Canada",
        "iata": "YLW",
        "icao": "CYLW",
        "latitude": "49.9561004639",
        "longitude": "-119.377998352",
        "altitude": "1421",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YMA": {
        "name": "Mayo Airport",
        "city": "Mayo",
        "country": "Canada",
        "iata": "YMA",
        "icao": "CYMA",
        "latitude": "63.61640167236328",
        "longitude": "-135.8679962158203",
        "altitude": "1653",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YMJ": {
        "name": "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
        "city": "Moose Jaw",
        "country": "Canada",
        "iata": "YMJ",
        "icao": "CYMJ",
        "latitude": "50.330299377441406",
        "longitude": "-105.55899810791016",
        "altitude": "1892",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YMM": {
        "name": "Fort McMurray Airport",
        "city": "Fort Mcmurray",
        "country": "Canada",
        "iata": "YMM",
        "icao": "CYMM",
        "latitude": "56.653301239",
        "longitude": "-111.222000122",
        "altitude": "1211",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YMO": {
        "name": "Moosonee Airport",
        "city": "Moosonee",
        "country": "Canada",
        "iata": "YMO",
        "icao": "CYMO",
        "latitude": "51.291099548339844",
        "longitude": "-80.60780334472656",
        "altitude": "30",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YMW": {
        "name": "Maniwaki Airport",
        "city": "Maniwaki",
        "country": "Canada",
        "iata": "YMW",
        "icao": "CYMW",
        "latitude": "46.2728004456",
        "longitude": "-75.9906005859",
        "altitude": "656",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YMX": {
        "name": "Montreal International (Mirabel) Airport",
        "city": "Montreal",
        "country": "Canada",
        "iata": "YMX",
        "icao": "CYMX",
        "latitude": "45.679501",
        "longitude": "-74.038696",
        "altitude": "270",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YNA": {
        "name": "Natashquan Airport",
        "city": "Natashquan",
        "country": "Canada",
        "iata": "YNA",
        "icao": "CYNA",
        "latitude": "50.189998626708984",
        "longitude": "-61.78919982910156",
        "altitude": "39",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YND": {
        "name": "Ottawa / Gatineau Airport",
        "city": "Gatineau",
        "country": "Canada",
        "iata": "YND",
        "icao": "CYND",
        "latitude": "45.521701812699995",
        "longitude": "-75.5635986328",
        "altitude": "211",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YNM": {
        "name": "Matagami Airport",
        "city": "Matagami",
        "country": "Canada",
        "iata": "YNM",
        "icao": "CYNM",
        "latitude": "49.76169967651367",
        "longitude": "-77.80280303955078",
        "altitude": "918",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YOC": {
        "name": "Old Crow Airport",
        "city": "Old Crow",
        "country": "Canada",
        "iata": "YOC",
        "icao": "CYOC",
        "latitude": "67.57060241699219",
        "longitude": "-139.83900451660156",
        "altitude": "824",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YOD": {
        "name": "CFB Cold Lake",
        "city": "Cold Lake",
        "country": "Canada",
        "iata": "YOD",
        "icao": "CYOD",
        "latitude": "54.404998779296875",
        "longitude": "-110.27899932861328",
        "altitude": "1775",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YOJ": {
        "name": "High Level Airport",
        "city": "High Level",
        "country": "Canada",
        "iata": "YOJ",
        "icao": "CYOJ",
        "latitude": "58.62139892578125",
        "longitude": "-117.16500091552734",
        "altitude": "1110",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YOW": {
        "name": "Ottawa Macdonald-Cartier International Airport",
        "city": "Ottawa",
        "country": "Canada",
        "iata": "YOW",
        "icao": "CYOW",
        "latitude": "45.3224983215332",
        "longitude": "-75.66919708251953",
        "altitude": "374",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPA": {
        "name": "Prince Albert Glass Field",
        "city": "Prince Albert",
        "country": "Canada",
        "iata": "YPA",
        "icao": "CYPA",
        "latitude": "53.214199066199996",
        "longitude": "-105.672996521",
        "altitude": "1405",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YPE": {
        "name": "Peace River Airport",
        "city": "Peace River",
        "country": "Canada",
        "iata": "YPE",
        "icao": "CYPE",
        "latitude": "56.226898",
        "longitude": "-117.446999",
        "altitude": "1873",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YPG": {
        "name": "Southport Airport",
        "city": "Portage-la-prairie",
        "country": "Canada",
        "iata": "YPG",
        "icao": "CYPG",
        "latitude": "49.903099",
        "longitude": "-98.273817",
        "altitude": "885",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YPL": {
        "name": "Pickle Lake Airport",
        "city": "Pickle Lake",
        "country": "Canada",
        "iata": "YPL",
        "icao": "CYPL",
        "latitude": "51.4463996887207",
        "longitude": "-90.21420288085938",
        "altitude": "1267",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Coral_Harbour"
    },
    "YPN": {
        "name": "Port Menier Airport",
        "city": "Port Menier",
        "country": "Canada",
        "iata": "YPN",
        "icao": "CYPN",
        "latitude": "49.83639907836914",
        "longitude": "-64.2885971069336",
        "altitude": "167",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPQ": {
        "name": "Peterborough Airport",
        "city": "Peterborough",
        "country": "Canada",
        "iata": "YPQ",
        "icao": "CYPQ",
        "latitude": "44.22999954223633",
        "longitude": "-78.36329650878906",
        "altitude": "628",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPR": {
        "name": "Prince Rupert Airport",
        "city": "Prince Pupert",
        "country": "Canada",
        "iata": "YPR",
        "icao": "CYPR",
        "latitude": "54.286098480199996",
        "longitude": "-130.445007324",
        "altitude": "116",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YPY": {
        "name": "Fort Chipewyan Airport",
        "city": "Fort Chipewyan",
        "country": "Canada",
        "iata": "YPY",
        "icao": "CYPY",
        "latitude": "58.7672004699707",
        "longitude": "-111.11699676513672",
        "altitude": "761",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YQA": {
        "name": "Muskoka Airport",
        "city": "Muskoka",
        "country": "Canada",
        "iata": "YQA",
        "icao": "CYQA",
        "latitude": "44.974700927734375",
        "longitude": "-79.30329895019531",
        "altitude": "925",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YQB": {
        "name": "Quebec Jean Lesage International Airport",
        "city": "Quebec",
        "country": "Canada",
        "iata": "YQB",
        "icao": "CYQB",
        "latitude": "46.7911",
        "longitude": "-71.393303",
        "altitude": "244",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YQF": {
        "name": "Red Deer Regional Airport",
        "city": "Red Deer Industrial",
        "country": "Canada",
        "iata": "YQF",
        "icao": "CYQF",
        "latitude": "52.18220138549805",
        "longitude": "-113.89399719238281",
        "altitude": "2968",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YQG": {
        "name": "Windsor Airport",
        "city": "Windsor",
        "country": "Canada",
        "iata": "YQG",
        "icao": "CYQG",
        "latitude": "42.27560043334961",
        "longitude": "-82.95559692382812",
        "altitude": "622",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YQH": {
        "name": "Watson Lake Airport",
        "city": "Watson Lake",
        "country": "Canada",
        "iata": "YQH",
        "icao": "CYQH",
        "latitude": "60.11640167236328",
        "longitude": "-128.82200622558594",
        "altitude": "2255",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YQK": {
        "name": "Kenora Airport",
        "city": "Kenora",
        "country": "Canada",
        "iata": "YQK",
        "icao": "CYQK",
        "latitude": "49.788299560546875",
        "longitude": "-94.36309814453125",
        "altitude": "1332",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YQL": {
        "name": "Lethbridge County Airport",
        "city": "Lethbridge",
        "country": "Canada",
        "iata": "YQL",
        "icao": "CYQL",
        "latitude": "49.6302986145",
        "longitude": "-112.800003052",
        "altitude": "3048",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YQM": {
        "name": "Greater Moncton International Airport",
        "city": "Moncton",
        "country": "Canada",
        "iata": "YQM",
        "icao": "CYQM",
        "latitude": "46.11220169067383",
        "longitude": "-64.67859649658203",
        "altitude": "232",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YQQ": {
        "name": "Comox Airport",
        "city": "Comox",
        "country": "Canada",
        "iata": "YQQ",
        "icao": "CYQQ",
        "latitude": "49.71080017089844",
        "longitude": "-124.88700103759766",
        "altitude": "84",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YQR": {
        "name": "Regina International Airport",
        "city": "Regina",
        "country": "Canada",
        "iata": "YQR",
        "icao": "CYQR",
        "latitude": "50.43190002441406",
        "longitude": "-104.66600036621094",
        "altitude": "1894",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YQT": {
        "name": "Thunder Bay Airport",
        "city": "Thunder Bay",
        "country": "Canada",
        "iata": "YQT",
        "icao": "CYQT",
        "latitude": "48.37189865112305",
        "longitude": "-89.32389831542969",
        "altitude": "653",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YQU": {
        "name": "Grande Prairie Airport",
        "city": "Grande Prairie",
        "country": "Canada",
        "iata": "YQU",
        "icao": "CYQU",
        "latitude": "55.1796989441",
        "longitude": "-118.885002136",
        "altitude": "2195",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YQV": {
        "name": "Yorkton Municipal Airport",
        "city": "Yorkton",
        "country": "Canada",
        "iata": "YQV",
        "icao": "CYQV",
        "latitude": "51.26470184326172",
        "longitude": "-102.46199798583984",
        "altitude": "1635",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YQW": {
        "name": "North Battleford Airport",
        "city": "North Battleford",
        "country": "Canada",
        "iata": "YQW",
        "icao": "CYQW",
        "latitude": "52.76919937133789",
        "longitude": "-108.24400329589844",
        "altitude": "1799",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YQX": {
        "name": "Gander International Airport",
        "city": "Gander",
        "country": "Canada",
        "iata": "YQX",
        "icao": "CYQX",
        "latitude": "48.9369010925293",
        "longitude": "-54.56809997558594",
        "altitude": "496",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YQY": {
        "name": "Sydney / J.A. Douglas McCurdy Airport",
        "city": "Sydney",
        "country": "Canada",
        "iata": "YQY",
        "icao": "CYQY",
        "latitude": "46.1614",
        "longitude": "-60.047798",
        "altitude": "203",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YQZ": {
        "name": "Quesnel Airport",
        "city": "Quesnel",
        "country": "Canada",
        "iata": "YQZ",
        "icao": "CYQZ",
        "latitude": "53.026100158691406",
        "longitude": "-122.51000213623047",
        "altitude": "1789",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YRB": {
        "name": "Resolute Bay Airport",
        "city": "Resolute",
        "country": "Canada",
        "iata": "YRB",
        "icao": "CYRB",
        "latitude": "74.7169036865",
        "longitude": "-94.9693984985",
        "altitude": "215",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YRI": {
        "name": "Rivière-du-Loup Airport",
        "city": "Riviere Du Loup",
        "country": "Canada",
        "iata": "YRI",
        "icao": "CYRI",
        "latitude": "47.764400482177734",
        "longitude": "-69.58470153808594",
        "altitude": "427",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YRJ": {
        "name": "Roberval Airport",
        "city": "Roberval",
        "country": "Canada",
        "iata": "YRJ",
        "icao": "CYRJ",
        "latitude": "48.52000045776367",
        "longitude": "-72.2656021118164",
        "altitude": "586",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YRM": {
        "name": "Rocky Mountain House Airport",
        "city": "Rocky Mountain House",
        "country": "Canada",
        "iata": "YRM",
        "icao": "CYRM",
        "latitude": "52.4296989441",
        "longitude": "-114.903999329",
        "altitude": "3244",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YRT": {
        "name": "Rankin Inlet Airport",
        "city": "Rankin Inlet",
        "country": "Canada",
        "iata": "YRT",
        "icao": "CYRT",
        "latitude": "62.8114013672",
        "longitude": "-92.1157989502",
        "altitude": "94",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YSB": {
        "name": "Sudbury Airport",
        "city": "Sudbury",
        "country": "Canada",
        "iata": "YSB",
        "icao": "CYSB",
        "latitude": "46.625",
        "longitude": "-80.79889678955078",
        "altitude": "1141",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YSC": {
        "name": "Sherbrooke Airport",
        "city": "Sherbrooke",
        "country": "Canada",
        "iata": "YSC",
        "icao": "CYSC",
        "latitude": "45.4385986328125",
        "longitude": "-71.69139862060547",
        "altitude": "792",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YSJ": {
        "name": "Saint John Airport",
        "city": "St. John",
        "country": "Canada",
        "iata": "YSJ",
        "icao": "CYSJ",
        "latitude": "45.31610107421875",
        "longitude": "-65.89029693603516",
        "altitude": "357",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YSM": {
        "name": "Fort Smith Airport",
        "city": "Fort Smith",
        "country": "Canada",
        "iata": "YSM",
        "icao": "CYSM",
        "latitude": "60.020301818847656",
        "longitude": "-111.96199798583984",
        "altitude": "671",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YSR": {
        "name": "Nanisivik Airport",
        "city": "Nanisivik",
        "country": "Canada",
        "iata": "YSR",
        "icao": "CYSR",
        "latitude": "72.982201",
        "longitude": "-84.613602",
        "altitude": "2106",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YSU": {
        "name": "Summerside Airport",
        "city": "Summerside",
        "country": "Canada",
        "iata": "YSU",
        "icao": "CYSU",
        "latitude": "46.44060134887695",
        "longitude": "-63.83359909057617",
        "altitude": "56",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YSY": {
        "name": "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
        "city": "Sachs Harbour",
        "country": "Canada",
        "iata": "YSY",
        "icao": "CYSY",
        "latitude": "71.9938964844",
        "longitude": "-125.242996216",
        "altitude": "282",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YTE": {
        "name": "Cape Dorset Airport",
        "city": "Cape Dorset",
        "country": "Canada",
        "iata": "YTE",
        "icao": "CYTE",
        "latitude": "64.2300033569",
        "longitude": "-76.5267028809",
        "altitude": "164",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YTH": {
        "name": "Thompson Airport",
        "city": "Thompson",
        "country": "Canada",
        "iata": "YTH",
        "icao": "CYTH",
        "latitude": "55.80110168457031",
        "longitude": "-97.86419677734375",
        "altitude": "729",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YTR": {
        "name": "CFB Trenton",
        "city": "Trenton",
        "country": "Canada",
        "iata": "YTR",
        "icao": "CYTR",
        "latitude": "44.118900299072266",
        "longitude": "-77.5280990600586",
        "altitude": "283",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YTS": {
        "name": "Timmins/Victor M. Power",
        "city": "Timmins",
        "country": "Canada",
        "iata": "YTS",
        "icao": "CYTS",
        "latitude": "48.569698333699996",
        "longitude": "-81.376701355",
        "altitude": "967",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YTZ": {
        "name": "Billy Bishop Toronto City Centre Airport",
        "city": "Toronto",
        "country": "Canada",
        "iata": "YTZ",
        "icao": "CYTZ",
        "latitude": "43.627499",
        "longitude": "-79.396202",
        "altitude": "252",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YUB": {
        "name": "Tuktoyaktuk Airport",
        "city": "Tuktoyaktuk",
        "country": "Canada",
        "iata": "YUB",
        "icao": "CYUB",
        "latitude": "69.43329620361328",
        "longitude": "-133.0260009765625",
        "altitude": "15",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YUL": {
        "name": "Montreal / Pierre Elliott Trudeau International Airport",
        "city": "Montreal",
        "country": "Canada",
        "iata": "YUL",
        "icao": "CYUL",
        "latitude": "45.4706001282",
        "longitude": "-73.7407989502",
        "altitude": "118",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YUT": {
        "name": "Repulse Bay Airport",
        "city": "Repulse Bay",
        "country": "Canada",
        "iata": "YUT",
        "icao": "CYUT",
        "latitude": "66.5214004517",
        "longitude": "-86.22470092770001",
        "altitude": "80",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YUX": {
        "name": "Hall Beach Airport",
        "city": "Hall Beach",
        "country": "Canada",
        "iata": "YUX",
        "icao": "CYUX",
        "latitude": "68.77610015869999",
        "longitude": "-81.2425",
        "altitude": "30",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YUY": {
        "name": "Rouyn Noranda Airport",
        "city": "Rouyn",
        "country": "Canada",
        "iata": "YUY",
        "icao": "CYUY",
        "latitude": "48.20610046386719",
        "longitude": "-78.83560180664062",
        "altitude": "988",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YVC": {
        "name": "La Ronge Airport",
        "city": "La Ronge",
        "country": "Canada",
        "iata": "YVC",
        "icao": "CYVC",
        "latitude": "55.151401519800004",
        "longitude": "-105.262001038",
        "altitude": "1242",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YVG": {
        "name": "Vermilion Airport",
        "city": "Vermillion",
        "country": "Canada",
        "iata": "YVG",
        "icao": "CYVG",
        "latitude": "53.355800628699996",
        "longitude": "-110.823997498",
        "altitude": "2025",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YVM": {
        "name": "Qikiqtarjuaq Airport",
        "city": "Broughton Island",
        "country": "Canada",
        "iata": "YVM",
        "icao": "CYVM",
        "latitude": "67.5457992554",
        "longitude": "-64.03140258789999",
        "altitude": "21",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YVO": {
        "name": "Val-d'Or Airport",
        "city": "Val D'or",
        "country": "Canada",
        "iata": "YVO",
        "icao": "CYVO",
        "latitude": "48.0532989502",
        "longitude": "-77.7827987671",
        "altitude": "1107",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YVP": {
        "name": "Kuujjuaq Airport",
        "city": "Quujjuaq",
        "country": "Canada",
        "iata": "YVP",
        "icao": "CYVP",
        "latitude": "58.096099853515625",
        "longitude": "-68.4269027709961",
        "altitude": "129",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YVQ": {
        "name": "Norman Wells Airport",
        "city": "Norman Wells",
        "country": "Canada",
        "iata": "YVQ",
        "icao": "CYVQ",
        "latitude": "65.28160095214844",
        "longitude": "-126.7979965209961",
        "altitude": "238",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YVR": {
        "name": "Vancouver International Airport",
        "city": "Vancouver",
        "country": "Canada",
        "iata": "YVR",
        "icao": "CYVR",
        "latitude": "49.193901062",
        "longitude": "-123.183998108",
        "altitude": "14",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YVT": {
        "name": "Buffalo Narrows Airport",
        "city": "Buffalo Narrows",
        "country": "Canada",
        "iata": "YVT",
        "icao": "CYVT",
        "latitude": "55.8418998718",
        "longitude": "-108.417999268",
        "altitude": "1423",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YVV": {
        "name": "Wiarton Airport",
        "city": "Wiarton",
        "country": "Canada",
        "iata": "YVV",
        "icao": "CYVV",
        "latitude": "44.7458",
        "longitude": "-81.107201",
        "altitude": "729",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YWA": {
        "name": "Petawawa Airport",
        "city": "Petawawa",
        "country": "Canada",
        "iata": "YWA",
        "icao": "CYWA",
        "latitude": "45.95220184326172",
        "longitude": "-77.31919860839844",
        "altitude": "427",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YWG": {
        "name": "Winnipeg / James Armstrong Richardson International Airport",
        "city": "Winnipeg",
        "country": "Canada",
        "iata": "YWG",
        "icao": "CYWG",
        "latitude": "49.909999847399995",
        "longitude": "-97.2398986816",
        "altitude": "783",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YWK": {
        "name": "Wabush Airport",
        "city": "Wabush",
        "country": "Canada",
        "iata": "YWK",
        "icao": "CYWK",
        "latitude": "52.92190170288086",
        "longitude": "-66.8644027709961",
        "altitude": "1808",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YWL": {
        "name": "Williams Lake Airport",
        "city": "Williams Lake",
        "country": "Canada",
        "iata": "YWL",
        "icao": "CYWL",
        "latitude": "52.1831016541",
        "longitude": "-122.054000854",
        "altitude": "3085",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YWY": {
        "name": "Wrigley Airport",
        "city": "Wrigley",
        "country": "Canada",
        "iata": "YWY",
        "icao": "CYWY",
        "latitude": "63.20940017700195",
        "longitude": "-123.43699645996094",
        "altitude": "489",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YXC": {
        "name": "Cranbrook/Canadian Rockies International Airport",
        "city": "Cranbrook",
        "country": "Canada",
        "iata": "YXC",
        "icao": "CYXC",
        "latitude": "49.610801696777",
        "longitude": "-115.78199768066",
        "altitude": "3082",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YXD": {
        "name": "Edmonton City Centre (Blatchford Field) Airport",
        "city": "Edmonton",
        "country": "Canada",
        "iata": "YXD",
        "icao": "CYXD",
        "latitude": "53.5724983215",
        "longitude": "-113.521003723",
        "altitude": "2202",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YXE": {
        "name": "Saskatoon John G. Diefenbaker International Airport",
        "city": "Saskatoon",
        "country": "Canada",
        "iata": "YXE",
        "icao": "CYXE",
        "latitude": "52.170799255371094",
        "longitude": "-106.69999694824219",
        "altitude": "1653",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YXH": {
        "name": "Medicine Hat Airport",
        "city": "Medicine Hat",
        "country": "Canada",
        "iata": "YXH",
        "icao": "CYXH",
        "latitude": "50.01890182495117",
        "longitude": "-110.72100067138672",
        "altitude": "2352",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YXJ": {
        "name": "Fort St John Airport",
        "city": "Fort Saint John",
        "country": "Canada",
        "iata": "YXJ",
        "icao": "CYXJ",
        "latitude": "56.23809814453125",
        "longitude": "-120.73999786376953",
        "altitude": "2280",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Dawson_Creek"
    },
    "YXL": {
        "name": "Sioux Lookout Airport",
        "city": "Sioux Lookout",
        "country": "Canada",
        "iata": "YXL",
        "icao": "CYXL",
        "latitude": "50.11389923095703",
        "longitude": "-91.9052963256836",
        "altitude": "1258",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YXP": {
        "name": "Pangnirtung Airport",
        "city": "Pangnirtung",
        "country": "Canada",
        "iata": "YXP",
        "icao": "CYXP",
        "latitude": "66.1449966431",
        "longitude": "-65.71360015869999",
        "altitude": "75",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YXR": {
        "name": "Earlton (Timiskaming Regional) Airport",
        "city": "Earlton",
        "country": "Canada",
        "iata": "YXR",
        "icao": "CYXR",
        "latitude": "47.697400654599996",
        "longitude": "-79.8473453522",
        "altitude": "800",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YXS": {
        "name": "Prince George Airport",
        "city": "Prince George",
        "country": "Canada",
        "iata": "YXS",
        "icao": "CYXS",
        "latitude": "53.8894004822",
        "longitude": "-122.679000854",
        "altitude": "2267",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YXT": {
        "name": "Northwest Regional Airport Terrace-Kitimat",
        "city": "Terrace",
        "country": "Canada",
        "iata": "YXT",
        "icao": "CYXT",
        "latitude": "54.468498",
        "longitude": "-128.576009",
        "altitude": "713",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YXU": {
        "name": "London Airport",
        "city": "London",
        "country": "Canada",
        "iata": "YXU",
        "icao": "CYXU",
        "latitude": "43.035599",
        "longitude": "-81.1539",
        "altitude": "912",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YXX": {
        "name": "Abbotsford Airport",
        "city": "Abbotsford",
        "country": "Canada",
        "iata": "YXX",
        "icao": "CYXX",
        "latitude": "49.025299072265625",
        "longitude": "-122.36100006103516",
        "altitude": "195",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YXY": {
        "name": "Whitehorse / Erik Nielsen International Airport",
        "city": "Whitehorse",
        "country": "Canada",
        "iata": "YXY",
        "icao": "CYXY",
        "latitude": "60.7095985413",
        "longitude": "-135.067001343",
        "altitude": "2317",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YYB": {
        "name": "North Bay Jack Garland Airport",
        "city": "North Bay",
        "country": "Canada",
        "iata": "YYB",
        "icao": "CYYB",
        "latitude": "46.363602",
        "longitude": "-79.422798",
        "altitude": "1215",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YYC": {
        "name": "Calgary International Airport",
        "city": "Calgary",
        "country": "Canada",
        "iata": "YYC",
        "icao": "CYYC",
        "latitude": "51.113899231",
        "longitude": "-114.019996643",
        "altitude": "3557",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YYD": {
        "name": "Smithers Airport",
        "city": "Smithers",
        "country": "Canada",
        "iata": "YYD",
        "icao": "CYYD",
        "latitude": "54.82469940185547",
        "longitude": "-127.18299865722656",
        "altitude": "1712",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YYE": {
        "name": "Fort Nelson Airport",
        "city": "Fort Nelson",
        "country": "Canada",
        "iata": "YYE",
        "icao": "CYYE",
        "latitude": "58.8363990784",
        "longitude": "-122.597000122",
        "altitude": "1253",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YYF": {
        "name": "Penticton Airport",
        "city": "Penticton",
        "country": "Canada",
        "iata": "YYF",
        "icao": "CYYF",
        "latitude": "49.46310043334961",
        "longitude": "-119.60199737548828",
        "altitude": "1129",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YYG": {
        "name": "Charlottetown Airport",
        "city": "Charlottetown",
        "country": "Canada",
        "iata": "YYG",
        "icao": "CYYG",
        "latitude": "46.290000915527344",
        "longitude": "-63.12110137939453",
        "altitude": "160",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YYH": {
        "name": "Taloyoak Airport",
        "city": "Spence Bay",
        "country": "Canada",
        "iata": "YYH",
        "icao": "CYYH",
        "latitude": "69.5466995239",
        "longitude": "-93.5766983032",
        "altitude": "92",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YYJ": {
        "name": "Victoria International Airport",
        "city": "Victoria",
        "country": "Canada",
        "iata": "YYJ",
        "icao": "CYYJ",
        "latitude": "48.646900177",
        "longitude": "-123.426002502",
        "altitude": "63",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YYL": {
        "name": "Lynn Lake Airport",
        "city": "Lynn Lake",
        "country": "Canada",
        "iata": "YYL",
        "icao": "CYYL",
        "latitude": "56.86389923095703",
        "longitude": "-101.07599639892578",
        "altitude": "1170",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YYN": {
        "name": "Swift Current Airport",
        "city": "Swift Current",
        "country": "Canada",
        "iata": "YYN",
        "icao": "CYYN",
        "latitude": "50.291900634799994",
        "longitude": "-107.691001892",
        "altitude": "2680",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YYQ": {
        "name": "Churchill Airport",
        "city": "Churchill",
        "country": "Canada",
        "iata": "YYQ",
        "icao": "CYYQ",
        "latitude": "58.739200592041016",
        "longitude": "-94.06500244140625",
        "altitude": "94",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YYR": {
        "name": "Goose Bay Airport",
        "city": "Goose Bay",
        "country": "Canada",
        "iata": "YYR",
        "icao": "CYYR",
        "latitude": "53.3191986084",
        "longitude": "-60.4258003235",
        "altitude": "160",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YYT": {
        "name": "St. John's International Airport",
        "city": "St. John's",
        "country": "Canada",
        "iata": "YYT",
        "icao": "CYYT",
        "latitude": "47.618598938",
        "longitude": "-52.7518997192",
        "altitude": "461",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YYU": {
        "name": "Kapuskasing Airport",
        "city": "Kapuskasing",
        "country": "Canada",
        "iata": "YYU",
        "icao": "CYYU",
        "latitude": "49.41389846801758",
        "longitude": "-82.46749877929688",
        "altitude": "743",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YYW": {
        "name": "Armstrong Airport",
        "city": "Armstrong",
        "country": "Canada",
        "iata": "YYW",
        "icao": "CYYW",
        "latitude": "50.29029846191406",
        "longitude": "-88.90969848632812",
        "altitude": "1058",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YYY": {
        "name": "Mont Joli Airport",
        "city": "Mont Joli",
        "country": "Canada",
        "iata": "YYY",
        "icao": "CYYY",
        "latitude": "48.60860061645508",
        "longitude": "-68.20809936523438",
        "altitude": "172",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YYZ": {
        "name": "Lester B. Pearson International Airport",
        "city": "Toronto",
        "country": "Canada",
        "iata": "YYZ",
        "icao": "CYYZ",
        "latitude": "43.6772003174",
        "longitude": "-79.63059997559999",
        "altitude": "569",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YZD": {
        "name": "Downsview Airport",
        "city": "Toronto",
        "country": "Canada",
        "iata": "YZD",
        "icao": "CYZD",
        "latitude": "43.74250030517578",
        "longitude": "-79.4655990600586",
        "altitude": "652",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YZE": {
        "name": "Gore Bay Manitoulin Airport",
        "city": "Gore Bay",
        "country": "Canada",
        "iata": "YZE",
        "icao": "CYZE",
        "latitude": "45.88529968261719",
        "longitude": "-82.56780242919922",
        "altitude": "635",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YZF": {
        "name": "Yellowknife Airport",
        "city": "Yellowknife",
        "country": "Canada",
        "iata": "YZF",
        "icao": "CYZF",
        "latitude": "62.462799072265625",
        "longitude": "-114.44000244140625",
        "altitude": "675",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YZH": {
        "name": "Slave Lake Airport",
        "city": "Slave Lake",
        "country": "Canada",
        "iata": "YZH",
        "icao": "CYZH",
        "latitude": "55.2930984497",
        "longitude": "-114.777000427",
        "altitude": "1912",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YZP": {
        "name": "Sandspit Airport",
        "city": "Sandspit",
        "country": "Canada",
        "iata": "YZP",
        "icao": "CYZP",
        "latitude": "53.25429916379999",
        "longitude": "-131.813995361",
        "altitude": "21",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YZR": {
        "name": "Chris Hadfield Airport",
        "city": "Sarnia",
        "country": "Canada",
        "iata": "YZR",
        "icao": "CYZR",
        "latitude": "42.9994010925293",
        "longitude": "-82.30889892578125",
        "altitude": "594",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YZT": {
        "name": "Port Hardy Airport",
        "city": "Port Hardy",
        "country": "Canada",
        "iata": "YZT",
        "icao": "CYZT",
        "latitude": "50.680599212646484",
        "longitude": "-127.36699676513672",
        "altitude": "71",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YZU": {
        "name": "Whitecourt Airport",
        "city": "Whitecourt",
        "country": "Canada",
        "iata": "YZU",
        "icao": "CYZU",
        "latitude": "54.14390182495117",
        "longitude": "-115.78700256347656",
        "altitude": "2567",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YZV": {
        "name": "Sept-Îles Airport",
        "city": "Sept-iles",
        "country": "Canada",
        "iata": "YZV",
        "icao": "CYZV",
        "latitude": "50.22330093383789",
        "longitude": "-66.2656021118164",
        "altitude": "180",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YZW": {
        "name": "Teslin Airport",
        "city": "Teslin",
        "country": "Canada",
        "iata": "YZW",
        "icao": "CYZW",
        "latitude": "60.17279815673828",
        "longitude": "-132.7429962158203",
        "altitude": "2313",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YZX": {
        "name": "CFB Greenwood",
        "city": "Greenwood",
        "country": "Canada",
        "iata": "YZX",
        "icao": "CYZX",
        "latitude": "44.98440170288086",
        "longitude": "-64.91690063476562",
        "altitude": "92",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "ZFA": {
        "name": "Faro Airport",
        "city": "Faro",
        "country": "Canada",
        "iata": "ZFA",
        "icao": "CZFA",
        "latitude": "62.20750045776367",
        "longitude": "-133.37600708007812",
        "altitude": "2351",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "ZFM": {
        "name": "Fort Mcpherson Airport",
        "city": "Fort Mcpherson",
        "country": "Canada",
        "iata": "ZFM",
        "icao": "CZFM",
        "latitude": "67.40750122070312",
        "longitude": "-134.86099243164062",
        "altitude": "116",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "QLD": {
        "name": "Blida Airport",
        "city": "Blida",
        "country": "Algeria",
        "iata": "QLD",
        "icao": "DAAB",
        "latitude": "36.50360107421875",
        "longitude": "2.8141698837280273",
        "altitude": "535",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "BUJ": {
        "name": "Bou Saada Airport",
        "city": "Bou Saada",
        "country": "Algeria",
        "iata": "BUJ",
        "icao": "DAAD",
        "latitude": "35.33250045776367",
        "longitude": "4.206389904022217",
        "altitude": "1506",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "BJA": {
        "name": "Soummam Airport",
        "city": "Bejaja",
        "country": "Algeria",
        "iata": "BJA",
        "icao": "DAAE",
        "latitude": "36.7120018005",
        "longitude": "5.0699200630200005",
        "altitude": "20",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "ALG": {
        "name": "Houari Boumediene Airport",
        "city": "Algier",
        "country": "Algeria",
        "iata": "ALG",
        "icao": "DAAG",
        "latitude": "36.691001892089844",
        "longitude": "3.215409994125366",
        "altitude": "82",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "DJG": {
        "name": "Djanet Inedbirene Airport",
        "city": "Djanet",
        "country": "Algeria",
        "iata": "DJG",
        "icao": "DAAJ",
        "latitude": "24.292801",
        "longitude": "9.45244",
        "altitude": "3176",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "VVZ": {
        "name": "Illizi Takhamalt Airport",
        "city": "Illizi",
        "country": "Algeria",
        "iata": "VVZ",
        "icao": "DAAP",
        "latitude": "26.7234992981",
        "longitude": "8.62265014648",
        "altitude": "1778",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TMR": {
        "name": "Aguenar – Hadj Bey Akhamok Airport",
        "city": "Tamanrasset",
        "country": "Algeria",
        "iata": "TMR",
        "icao": "DAAT",
        "latitude": "22.8115005493",
        "longitude": "5.45107984543",
        "altitude": "4518",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "GJL": {
        "name": "Jijel Ferhat Abbas Airport",
        "city": "Jijel",
        "country": "Algeria",
        "iata": "GJL",
        "icao": "DAAV",
        "latitude": "36.7951011658",
        "longitude": "5.87361001968",
        "altitude": "36",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "MZW": {
        "name": "Mecheria Airport",
        "city": "Mecheria",
        "country": "Algeria",
        "iata": "MZW",
        "icao": "DAAY",
        "latitude": "33.535900116",
        "longitude": "-0.242353007197",
        "altitude": "3855",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "AAE": {
        "name": "Rabah Bitat Airport",
        "city": "Annaba",
        "country": "Algeria",
        "iata": "AAE",
        "icao": "DABB",
        "latitude": "36.822201",
        "longitude": "7.809174",
        "altitude": "16",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "CZL": {
        "name": "Mohamed Boudiaf International Airport",
        "city": "Constantine",
        "country": "Algeria",
        "iata": "CZL",
        "icao": "DABC",
        "latitude": "36.2760009765625",
        "longitude": "6.620389938354492",
        "altitude": "2265",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TEE": {
        "name": "Cheikh Larbi Tébessi Airport",
        "city": "Tebessa",
        "country": "Algeria",
        "iata": "TEE",
        "icao": "DABS",
        "latitude": "35.4315986633",
        "longitude": "8.12071990967",
        "altitude": "2661",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "HRM": {
        "name": "Hassi R'Mel Airport",
        "city": "Tilrempt",
        "country": "Algeria",
        "iata": "HRM",
        "icao": "DAFH",
        "latitude": "32.93040084838867",
        "longitude": "3.311539888381958",
        "altitude": "2540",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TID": {
        "name": "Bou Chekif Airport",
        "city": "Tiaret",
        "country": "Algeria",
        "iata": "TID",
        "icao": "DAOB",
        "latitude": "35.3410987854",
        "longitude": "1.46315002441",
        "altitude": "3245",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TIN": {
        "name": "Tindouf Airport",
        "city": "Tindouf",
        "country": "Algeria",
        "iata": "TIN",
        "icao": "DAOF",
        "latitude": "27.7003993988",
        "longitude": "-8.167099952700001",
        "altitude": "1453",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "CFK": {
        "name": "Ech Cheliff Airport",
        "city": "Ech-cheliff",
        "country": "Algeria",
        "iata": "CFK",
        "icao": "DAOI",
        "latitude": "36.2126998901",
        "longitude": "1.33176994324",
        "altitude": "463",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TAF": {
        "name": "Tafaraoui Airport",
        "city": "Oran",
        "country": "Algeria",
        "iata": "TAF",
        "icao": "DAOL",
        "latitude": "35.54240036010742",
        "longitude": "-0.5322780013084412",
        "altitude": "367",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TLM": {
        "name": "Zenata – Messali El Hadj Airport",
        "city": "Tlemcen",
        "country": "Algeria",
        "iata": "TLM",
        "icao": "DAON",
        "latitude": "35.0167007446",
        "longitude": "-1.45000004768",
        "altitude": "814",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "ORN": {
        "name": "Es Senia Airport",
        "city": "Oran",
        "country": "Algeria",
        "iata": "ORN",
        "icao": "DAOO",
        "latitude": "35.6239013672",
        "longitude": "-0.6211829781529999",
        "altitude": "295",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "BFW": {
        "name": "Sidi Bel Abbes Airport",
        "city": "Sidi Bel Abbes",
        "country": "Algeria",
        "iata": "BFW",
        "icao": "DAOS",
        "latitude": "35.1717987061",
        "longitude": "-0.593275010586",
        "altitude": "1614",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "MUW": {
        "name": "Ghriss Airport",
        "city": "Ghriss",
        "country": "Algeria",
        "iata": "MUW",
        "icao": "DAOV",
        "latitude": "35.207698822021484",
        "longitude": "0.14714199304580688",
        "altitude": "1686",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "AZR": {
        "name": "Touat Cheikh Sidi Mohamed Belkebir Airport",
        "city": "Adrar",
        "country": "Algeria",
        "iata": "AZR",
        "icao": "DAUA",
        "latitude": "27.837600708007812",
        "longitude": "-0.18641400337219238",
        "altitude": "919",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "BSK": {
        "name": "Biskra Airport",
        "city": "Biskra",
        "country": "Algeria",
        "iata": "BSK",
        "icao": "DAUB",
        "latitude": "34.793300628699996",
        "longitude": "5.73823022842",
        "altitude": "289",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "ELG": {
        "name": "El Golea Airport",
        "city": "El Golea",
        "country": "Algeria",
        "iata": "ELG",
        "icao": "DAUE",
        "latitude": "30.571300506591797",
        "longitude": "2.8595900535583496",
        "altitude": "1306",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "GHA": {
        "name": "Noumérat - Moufdi Zakaria Airport",
        "city": "Ghardaia",
        "country": "Algeria",
        "iata": "GHA",
        "icao": "DAUG",
        "latitude": "32.38410186767578",
        "longitude": "3.794110059738159",
        "altitude": "1512",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "HME": {
        "name": "Oued Irara Airport",
        "city": "Hassi Messaoud",
        "country": "Algeria",
        "iata": "HME",
        "icao": "DAUH",
        "latitude": "31.673000335699996",
        "longitude": "6.140439987180001",
        "altitude": "463",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "INZ": {
        "name": "In Salah Airport",
        "city": "In Salah",
        "country": "Algeria",
        "iata": "INZ",
        "icao": "DAUI",
        "latitude": "27.250999450699997",
        "longitude": "2.51202011108",
        "altitude": "896",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TGR": {
        "name": "Touggourt Sidi Madhi Airport",
        "city": "Touggourt",
        "country": "Algeria",
        "iata": "TGR",
        "icao": "DAUK",
        "latitude": "33.06779861450195",
        "longitude": "6.088669776916504",
        "altitude": "279",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "LOO": {
        "name": "Laghouat Airport",
        "city": "Laghouat",
        "country": "Algeria",
        "iata": "LOO",
        "icao": "DAUL",
        "latitude": "33.764400482199996",
        "longitude": "2.92833995819",
        "altitude": "2510",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "TMX": {
        "name": "Timimoun Airport",
        "city": "Timimoun",
        "country": "Algeria",
        "iata": "TMX",
        "icao": "DAUT",
        "latitude": "29.2371006012",
        "longitude": "0.276033014059",
        "altitude": "1027",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "OGX": {
        "name": "Ain el Beida Airport",
        "city": "Ouargla",
        "country": "Algeria",
        "iata": "OGX",
        "icao": "DAUU",
        "latitude": "31.917200088500977",
        "longitude": "5.412779808044434",
        "altitude": "492",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "IAM": {
        "name": "In Aménas Airport",
        "city": "Zarzaitine",
        "country": "Algeria",
        "iata": "IAM",
        "icao": "DAUZ",
        "latitude": "28.0515",
        "longitude": "9.64291",
        "altitude": "1847",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "COO": {
        "name": "Cadjehoun Airport",
        "city": "Cotonou",
        "country": "Benin",
        "iata": "COO",
        "icao": "DBBB",
        "latitude": "6.357230186462402",
        "longitude": "2.384350061416626",
        "altitude": "19",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Porto-Novo"
    },
    "OUA": {
        "name": "Ouagadougou Airport",
        "city": "Ouagadougou",
        "country": "Burkina Faso",
        "iata": "OUA",
        "icao": "DFFD",
        "latitude": "12.3532",
        "longitude": "-1.51242",
        "altitude": "1037",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Ouagadougou"
    },
    "BOY": {
        "name": "Bobo Dioulasso Airport",
        "city": "Bobo-dioulasso",
        "country": "Burkina Faso",
        "iata": "BOY",
        "icao": "DFOO",
        "latitude": "11.160099983215332",
        "longitude": "-4.33096981048584",
        "altitude": "1511",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Ouagadougou"
    },
    "ACC": {
        "name": "Kotoka International Airport",
        "city": "Accra",
        "country": "Ghana",
        "iata": "ACC",
        "icao": "DGAA",
        "latitude": "5.605189800262451",
        "longitude": "-0.16678600013256073",
        "altitude": "205",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Accra"
    },
    "TML": {
        "name": "Tamale Airport",
        "city": "Tamale",
        "country": "Ghana",
        "iata": "TML",
        "icao": "DGLE",
        "latitude": "9.55718994140625",
        "longitude": "-0.8632140159606934",
        "altitude": "553",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Accra"
    },
    "NYI": {
        "name": "Sunyani Airport",
        "city": "Sunyani",
        "country": "Ghana",
        "iata": "NYI",
        "icao": "DGSN",
        "latitude": "7.361830234527588",
        "longitude": "-2.3287599086761475",
        "altitude": "1014",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Accra"
    },
    "TKD": {
        "name": "Takoradi Airport",
        "city": "Takoradi",
        "country": "Ghana",
        "iata": "TKD",
        "icao": "DGTK",
        "latitude": "4.896059989929199",
        "longitude": "-1.7747600078582764",
        "altitude": "21",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Accra"
    },
    "ABJ": {
        "name": "Port Bouet Airport",
        "city": "Abidjan",
        "country": "Cote d'Ivoire",
        "iata": "ABJ",
        "icao": "DIAP",
        "latitude": "5.261390209197998",
        "longitude": "-3.9262900352478027",
        "altitude": "21",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "BYK": {
        "name": "Bouaké Airport",
        "city": "Bouake",
        "country": "Cote d'Ivoire",
        "iata": "BYK",
        "icao": "DIBK",
        "latitude": "7.738800048828125",
        "longitude": "-5.073669910430908",
        "altitude": "1230",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "DJO": {
        "name": "Daloa Airport",
        "city": "Daloa",
        "country": "Cote d'Ivoire",
        "iata": "DJO",
        "icao": "DIDL",
        "latitude": "6.792809963226318",
        "longitude": "-6.473189830780029",
        "altitude": "823",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "HGO": {
        "name": "Korhogo Airport",
        "city": "Korhogo",
        "country": "Cote d'Ivoire",
        "iata": "HGO",
        "icao": "DIKO",
        "latitude": "9.38718032837",
        "longitude": "-5.55666017532",
        "altitude": "1214",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "MJC": {
        "name": "Man Airport",
        "city": "Man",
        "country": "Cote d'Ivoire",
        "iata": "MJC",
        "icao": "DIMN",
        "latitude": "7.272069931030273",
        "longitude": "-7.58735990524292",
        "altitude": "1089",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "SPY": {
        "name": "San Pedro Airport",
        "city": "San Pedro",
        "country": "Cote d'Ivoire",
        "iata": "SPY",
        "icao": "DISP",
        "latitude": "4.746719837188721",
        "longitude": "-6.660820007324219",
        "altitude": "26",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "ASK": {
        "name": "Yamoussoukro Airport",
        "city": "Yamoussoukro",
        "country": "Cote d'Ivoire",
        "iata": "ASK",
        "icao": "DIYO",
        "latitude": "6.9031701088",
        "longitude": "-5.36558008194",
        "altitude": "699",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Abidjan"
    },
    "ABV": {
        "name": "Nnamdi Azikiwe International Airport",
        "city": "Abuja",
        "country": "Nigeria",
        "iata": "ABV",
        "icao": "DNAA",
        "latitude": "9.006790161132812",
        "longitude": "7.263169765472412",
        "altitude": "1123",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "AKR": {
        "name": "Akure Airport",
        "city": "Akure",
        "country": "Nigeria",
        "iata": "AKR",
        "icao": "DNAK",
        "latitude": "7.246739864349365",
        "longitude": "5.3010101318359375",
        "altitude": "1100",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "BNI": {
        "name": "Benin Airport",
        "city": "Benin",
        "country": "Nigeria",
        "iata": "BNI",
        "icao": "DNBE",
        "latitude": "6.316979885101318",
        "longitude": "5.5995001792907715",
        "altitude": "258",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "CBQ": {
        "name": "Margaret Ekpo International Airport",
        "city": "Calabar",
        "country": "Nigeria",
        "iata": "CBQ",
        "icao": "DNCA",
        "latitude": "4.976019859313965",
        "longitude": "8.347200393676758",
        "altitude": "210",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "ENU": {
        "name": "Akanu Ibiam International Airport",
        "city": "Enugu",
        "country": "Nigeria",
        "iata": "ENU",
        "icao": "DNEN",
        "latitude": "6.474269866943359",
        "longitude": "7.561960220336914",
        "altitude": "466",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "IBA": {
        "name": "Ibadan Airport",
        "city": "Ibadan",
        "country": "Nigeria",
        "iata": "IBA",
        "icao": "DNIB",
        "latitude": "7.362460136413574",
        "longitude": "3.97832989692688",
        "altitude": "725",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "ILR": {
        "name": "Ilorin International Airport",
        "city": "Ilorin",
        "country": "Nigeria",
        "iata": "ILR",
        "icao": "DNIL",
        "latitude": "8.440210342407227",
        "longitude": "4.493919849395752",
        "altitude": "1126",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "JOS": {
        "name": "Yakubu Gowon Airport",
        "city": "Jos",
        "country": "Nigeria",
        "iata": "JOS",
        "icao": "DNJO",
        "latitude": "9.639829635620117",
        "longitude": "8.869050025939941",
        "altitude": "4232",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "KAD": {
        "name": "Kaduna Airport",
        "city": "Kaduna",
        "country": "Nigeria",
        "iata": "KAD",
        "icao": "DNKA",
        "latitude": "10.696000099182129",
        "longitude": "7.320109844207764",
        "altitude": "2073",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "KAN": {
        "name": "Mallam Aminu International Airport",
        "city": "Kano",
        "country": "Nigeria",
        "iata": "KAN",
        "icao": "DNKN",
        "latitude": "12.0476",
        "longitude": "8.52462",
        "altitude": "1562",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "MIU": {
        "name": "Maiduguri International Airport",
        "city": "Maiduguri",
        "country": "Nigeria",
        "iata": "MIU",
        "icao": "DNMA",
        "latitude": "11.855299949645996",
        "longitude": "13.080900192260742",
        "altitude": "1099",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "MDI": {
        "name": "Makurdi Airport",
        "city": "Makurdi",
        "country": "Nigeria",
        "iata": "MDI",
        "icao": "DNMK",
        "latitude": "7.70388",
        "longitude": "8.61394",
        "altitude": "371",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "LOS": {
        "name": "Murtala Muhammed International Airport",
        "city": "Lagos",
        "country": "Nigeria",
        "iata": "LOS",
        "icao": "DNMM",
        "latitude": "6.5773701667785645",
        "longitude": "3.321160078048706",
        "altitude": "135",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "MXJ": {
        "name": "Minna Airport",
        "city": "Minna",
        "country": "Nigeria",
        "iata": "MXJ",
        "icao": "DNMN",
        "latitude": "9.652170181274414",
        "longitude": "6.462259769439697",
        "altitude": "834",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "PHC": {
        "name": "Port Harcourt International Airport",
        "city": "Port Hartcourt",
        "country": "Nigeria",
        "iata": "PHC",
        "icao": "DNPO",
        "latitude": "5.0154900550842285",
        "longitude": "6.94959020614624",
        "altitude": "87",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "SKO": {
        "name": "Sadiq Abubakar III International Airport",
        "city": "Sokoto",
        "country": "Nigeria",
        "iata": "SKO",
        "icao": "DNSO",
        "latitude": "12.916299819946289",
        "longitude": "5.207190036773682",
        "altitude": "1010",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "YOL": {
        "name": "Yola Airport",
        "city": "Yola",
        "country": "Nigeria",
        "iata": "YOL",
        "icao": "DNYO",
        "latitude": "9.257550239562988",
        "longitude": "12.430399894714355",
        "altitude": "599",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "ZAR": {
        "name": "Zaria Airport",
        "city": "Zaria",
        "country": "Nigeria",
        "iata": "ZAR",
        "icao": "DNZA",
        "latitude": "11.1302",
        "longitude": "7.68581",
        "altitude": "2170",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "MFQ": {
        "name": "Maradi Airport",
        "city": "Maradi",
        "country": "Niger",
        "iata": "MFQ",
        "icao": "DRRM",
        "latitude": "13.5024995803833",
        "longitude": "7.1267499923706055",
        "altitude": "1240",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Niamey"
    },
    "NIM": {
        "name": "Diori Hamani International Airport",
        "city": "Niamey",
        "country": "Niger",
        "iata": "NIM",
        "icao": "DRRN",
        "latitude": "13.4815",
        "longitude": "2.18361",
        "altitude": "732",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Niamey"
    },
    "THZ": {
        "name": "Tahoua Airport",
        "city": "Tahoua",
        "country": "Niger",
        "iata": "THZ",
        "icao": "DRRT",
        "latitude": "14.875699996948242",
        "longitude": "5.265359878540039",
        "altitude": "1266",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Niamey"
    },
    "AJY": {
        "name": "Mano Dayak International Airport",
        "city": "Agadez",
        "country": "Niger",
        "iata": "AJY",
        "icao": "DRZA",
        "latitude": "16.965999603271484",
        "longitude": "8.000109672546387",
        "altitude": "1657",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Niamey"
    },
    "ZND": {
        "name": "Zinder Airport",
        "city": "Zinder",
        "country": "Niger",
        "iata": "ZND",
        "icao": "DRZR",
        "latitude": "13.779000282287598",
        "longitude": "8.983759880065918",
        "altitude": "1516",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Niamey"
    },
    "MIR": {
        "name": "Monastir Habib Bourguiba International Airport",
        "city": "Monastir",
        "country": "Tunisia",
        "iata": "MIR",
        "icao": "DTMB",
        "latitude": "35.75809860229492",
        "longitude": "10.75469970703125",
        "altitude": "9",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "TUN": {
        "name": "Tunis Carthage International Airport",
        "city": "Tunis",
        "country": "Tunisia",
        "iata": "TUN",
        "icao": "DTTA",
        "latitude": "36.85100173950195",
        "longitude": "10.22719955444336",
        "altitude": "22",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "GAF": {
        "name": "Gafsa Ksar International Airport",
        "city": "Gafsa",
        "country": "Tunisia",
        "iata": "GAF",
        "icao": "DTTF",
        "latitude": "34.422000885009766",
        "longitude": "8.822500228881836",
        "altitude": "1060",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "GAE": {
        "name": "Gabès Matmata International Airport",
        "city": "Gabes",
        "country": "Tunisia",
        "iata": "GAE",
        "icao": "DTTG",
        "latitude": "33.87689971923828",
        "longitude": "10.103300094604492",
        "altitude": "26",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "DJE": {
        "name": "Djerba Zarzis International Airport",
        "city": "Djerba",
        "country": "Tunisia",
        "iata": "DJE",
        "icao": "DTTJ",
        "latitude": "33.875",
        "longitude": "10.775500297546387",
        "altitude": "19",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "EBM": {
        "name": "El Borma Airport",
        "city": "El Borma",
        "country": "Tunisia",
        "iata": "EBM",
        "icao": "DTTR",
        "latitude": "31.704299926757812",
        "longitude": "9.254619598388672",
        "altitude": "827",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "SFA": {
        "name": "Sfax Thyna International Airport",
        "city": "Sfax",
        "country": "Tunisia",
        "iata": "SFA",
        "icao": "DTTX",
        "latitude": "34.71799850463867",
        "longitude": "10.690999984741211",
        "altitude": "85",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "TOE": {
        "name": "Tozeur Nefta International Airport",
        "city": "Tozeur",
        "country": "Tunisia",
        "iata": "TOE",
        "icao": "DTTZ",
        "latitude": "33.939701080322266",
        "longitude": "8.110560417175293",
        "altitude": "287",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "LRL": {
        "name": "Niamtougou International Airport",
        "city": "Niatougou",
        "country": "Togo",
        "iata": "LRL",
        "icao": "DXNG",
        "latitude": "9.767330169677734",
        "longitude": "1.091249942779541",
        "altitude": "1515",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Lome"
    },
    "LFW": {
        "name": "Lomé-Tokoin Airport",
        "city": "Lome",
        "country": "Togo",
        "iata": "LFW",
        "icao": "DXXX",
        "latitude": "6.165609836578369",
        "longitude": "1.2545100450515747",
        "altitude": "72",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Lome"
    },
    "ANR": {
        "name": "Antwerp International Airport (Deurne)",
        "city": "Antwerp",
        "country": "Belgium",
        "iata": "ANR",
        "icao": "EBAW",
        "latitude": "51.1893997192",
        "longitude": "4.46027994156",
        "altitude": "39",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "BRU": {
        "name": "Brussels Airport",
        "city": "Brussels",
        "country": "Belgium",
        "iata": "BRU",
        "icao": "EBBR",
        "latitude": "50.901401519800004",
        "longitude": "4.48443984985",
        "altitude": "184",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "CRL": {
        "name": "Brussels South Charleroi Airport",
        "city": "Charleroi",
        "country": "Belgium",
        "iata": "CRL",
        "icao": "EBCI",
        "latitude": "50.459202",
        "longitude": "4.45382",
        "altitude": "614",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "KJK": {
        "name": "Wevelgem Airport",
        "city": "Kortrijk-vevelgem",
        "country": "Belgium",
        "iata": "KJK",
        "icao": "EBKT",
        "latitude": "50.817199707",
        "longitude": "3.20472002029",
        "altitude": "64",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "LGG": {
        "name": "Liège Airport",
        "city": "Liege",
        "country": "Belgium",
        "iata": "LGG",
        "icao": "EBLG",
        "latitude": "50.63740158081055",
        "longitude": "5.443220138549805",
        "altitude": "659",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "OST": {
        "name": "Ostend-Bruges International Airport",
        "city": "Ostend",
        "country": "Belgium",
        "iata": "OST",
        "icao": "EBOS",
        "latitude": "51.198898315399994",
        "longitude": "2.8622200489",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "OBL": {
        "name": "Zoersel (Oostmalle) Airfield",
        "city": "Zoersel",
        "country": "Belgium",
        "iata": "OBL",
        "icao": "EBZR",
        "latitude": "51.264702",
        "longitude": "4.75333",
        "altitude": "53",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Brussels"
    },
    "AOC": {
        "name": "Altenburg-Nobitz Airport",
        "city": "Altenburg",
        "country": "Germany",
        "iata": "AOC",
        "icao": "EDAC",
        "latitude": "50.981945",
        "longitude": "12.506389",
        "altitude": "640",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "IES": {
        "name": "Riesa-Göhlis Airport",
        "city": "Riesa",
        "country": "Germany",
        "iata": "IES",
        "icao": "EDAU",
        "latitude": "51.2936096191",
        "longitude": "13.3561105728",
        "altitude": "322",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "REB": {
        "name": "Rechlin-Lärz Airport",
        "city": "Rechlin-laerz",
        "country": "Germany",
        "iata": "REB",
        "icao": "EDAX",
        "latitude": "53.306388855",
        "longitude": "12.7522220612",
        "altitude": "220",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "QXH": {
        "name": "Schönhagen Airport",
        "city": "Schoenhagen",
        "country": "Germany",
        "iata": "QXH",
        "icao": "EDAZ",
        "latitude": "52.203609",
        "longitude": "13.156389",
        "altitude": "152",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BBH": {
        "name": "Barth Airport",
        "city": "Barth",
        "country": "Germany",
        "iata": "BBH",
        "icao": "EDBH",
        "latitude": "54.338253",
        "longitude": "12.710515",
        "altitude": "23",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ZMG": {
        "name": "Magdeburg \"City\" Airport",
        "city": "Magdeburg",
        "country": "Germany",
        "iata": "ZMG",
        "icao": "EDBM",
        "latitude": "52.073612",
        "longitude": "11.626389",
        "altitude": "259",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "CBU": {
        "name": "Cottbus-Drewitz Airport",
        "city": "Cottbus",
        "country": "Germany",
        "iata": "CBU",
        "icao": "EDCD",
        "latitude": "51.889442",
        "longitude": "14.531944",
        "altitude": "272",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "SXF": {
        "name": "Berlin-Schönefeld Airport",
        "city": "Berlin",
        "country": "Germany",
        "iata": "SXF",
        "icao": "EDDB",
        "latitude": "52.380001",
        "longitude": "13.5225",
        "altitude": "157",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "DRS": {
        "name": "Dresden Airport",
        "city": "Dresden",
        "country": "Germany",
        "iata": "DRS",
        "icao": "EDDC",
        "latitude": "51.1328010559082",
        "longitude": "13.767200469970703",
        "altitude": "755",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ERF": {
        "name": "Erfurt Airport",
        "city": "Erfurt",
        "country": "Germany",
        "iata": "ERF",
        "icao": "EDDE",
        "latitude": "50.979801177978516",
        "longitude": "10.958100318908691",
        "altitude": "1036",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FRA": {
        "name": "Frankfurt am Main Airport",
        "city": "Frankfurt",
        "country": "Germany",
        "iata": "FRA",
        "icao": "EDDF",
        "latitude": "50.033333",
        "longitude": "8.570556",
        "altitude": "364",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FMO": {
        "name": "Münster Osnabrück Airport",
        "city": "Munster",
        "country": "Germany",
        "iata": "FMO",
        "icao": "EDDG",
        "latitude": "52.134601593",
        "longitude": "7.68483018875",
        "altitude": "160",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "HAM": {
        "name": "Hamburg Airport",
        "city": "Hamburg",
        "country": "Germany",
        "iata": "HAM",
        "icao": "EDDH",
        "latitude": "53.630401611328",
        "longitude": "9.9882297515869",
        "altitude": "53",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "THF": {
        "name": "Berlin-Tempelhof International Airport",
        "city": "Berlin",
        "country": "Germany",
        "iata": "THF",
        "icao": "EDDI",
        "latitude": "52.472999572753906",
        "longitude": "13.403900146484375",
        "altitude": "167",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "CGN": {
        "name": "Cologne Bonn Airport",
        "city": "Cologne",
        "country": "Germany",
        "iata": "CGN",
        "icao": "EDDK",
        "latitude": "50.8658981323",
        "longitude": "7.1427397728",
        "altitude": "302",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "DUS": {
        "name": "Düsseldorf Airport",
        "city": "Duesseldorf",
        "country": "Germany",
        "iata": "DUS",
        "icao": "EDDL",
        "latitude": "51.289501",
        "longitude": "6.76678",
        "altitude": "147",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "MUC": {
        "name": "Munich Airport",
        "city": "Munich",
        "country": "Germany",
        "iata": "MUC",
        "icao": "EDDM",
        "latitude": "48.353802",
        "longitude": "11.7861",
        "altitude": "1487",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "NUE": {
        "name": "Nuremberg Airport",
        "city": "Nuernberg",
        "country": "Germany",
        "iata": "NUE",
        "icao": "EDDN",
        "latitude": "49.498699",
        "longitude": "11.078056",
        "altitude": "1046",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "LEJ": {
        "name": "Leipzig/Halle Airport",
        "city": "Leipzig",
        "country": "Germany",
        "iata": "LEJ",
        "icao": "EDDP",
        "latitude": "51.423889",
        "longitude": "12.236389",
        "altitude": "465",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "SCN": {
        "name": "Saarbrücken Airport",
        "city": "Saarbruecken",
        "country": "Germany",
        "iata": "SCN",
        "icao": "EDDR",
        "latitude": "49.214599609400004",
        "longitude": "7.10950994492",
        "altitude": "1058",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "STR": {
        "name": "Stuttgart Airport",
        "city": "Stuttgart",
        "country": "Germany",
        "iata": "STR",
        "icao": "EDDS",
        "latitude": "48.689899444599995",
        "longitude": "9.22196006775",
        "altitude": "1276",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "TXL": {
        "name": "Berlin-Tegel Airport",
        "city": "Berlin",
        "country": "Germany",
        "iata": "TXL",
        "icao": "EDDT",
        "latitude": "52.5597",
        "longitude": "13.2877",
        "altitude": "122",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "HAJ": {
        "name": "Hannover Airport",
        "city": "Hannover",
        "country": "Germany",
        "iata": "HAJ",
        "icao": "EDDV",
        "latitude": "52.461101532",
        "longitude": "9.685079574580001",
        "altitude": "183",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BRE": {
        "name": "Bremen Airport",
        "city": "Bremen",
        "country": "Germany",
        "iata": "BRE",
        "icao": "EDDW",
        "latitude": "53.0475006104",
        "longitude": "8.78666973114",
        "altitude": "14",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "QEF": {
        "name": "Frankfurt-Egelsbach Airport",
        "city": "Egelsbach",
        "country": "Germany",
        "iata": "QEF",
        "icao": "EDFE",
        "latitude": "49.959999",
        "longitude": "8.645833",
        "altitude": "384",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "HHN": {
        "name": "Frankfurt-Hahn Airport",
        "city": "Hahn",
        "country": "Germany",
        "iata": "HHN",
        "icao": "EDFH",
        "latitude": "49.9487",
        "longitude": "7.26389",
        "altitude": "1649",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "MHG": {
        "name": "Mannheim-City Airport",
        "city": "Mannheim",
        "country": "Germany",
        "iata": "MHG",
        "icao": "EDFM",
        "latitude": "49.473057",
        "longitude": "8.514167",
        "altitude": "308",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "EIB": {
        "name": "Eisenach-Kindel Airport",
        "city": "Eisenach",
        "country": "Germany",
        "iata": "EIB",
        "icao": "EDGE",
        "latitude": "50.991604",
        "longitude": "10.47973",
        "altitude": "1112",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "SGE": {
        "name": "Siegerland Airport",
        "city": "Siegerland",
        "country": "Germany",
        "iata": "SGE",
        "icao": "EDGS",
        "latitude": "50.707698822021484",
        "longitude": "8.082969665527344",
        "altitude": "1966",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "XFW": {
        "name": "Hamburg-Finkenwerder Airport",
        "city": "Hamburg",
        "country": "Germany",
        "iata": "XFW",
        "icao": "EDHI",
        "latitude": "53.5352783203125",
        "longitude": "9.835556030273438",
        "altitude": "23",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "KEL": {
        "name": "Kiel-Holtenau Airport",
        "city": "Kiel",
        "country": "Germany",
        "iata": "KEL",
        "icao": "EDHK",
        "latitude": "54.37944412231445",
        "longitude": "10.145277976989746",
        "altitude": "102",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "LBC": {
        "name": "Lübeck Blankensee Airport",
        "city": "Luebeck",
        "country": "Germany",
        "iata": "LBC",
        "icao": "EDHL",
        "latitude": "53.8054008484",
        "longitude": "10.7192001343",
        "altitude": "53",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ESS": {
        "name": "Essen Mulheim Airport",
        "city": "Essen",
        "country": "Germany",
        "iata": "ESS",
        "icao": "EDLE",
        "latitude": "51.40230178833008",
        "longitude": "6.9373297691345215",
        "altitude": "424",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BFE": {
        "name": "Bielefeld Airport",
        "city": "Bielefeld",
        "country": "Germany",
        "iata": "BFE",
        "icao": "EDLI",
        "latitude": "51.964722",
        "longitude": "8.544444",
        "altitude": "454",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "MGL": {
        "name": "Mönchengladbach Airport",
        "city": "Moenchengladbach",
        "country": "Germany",
        "iata": "MGL",
        "icao": "EDLN",
        "latitude": "51.230278",
        "longitude": "6.504444",
        "altitude": "125",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "PAD": {
        "name": "Paderborn Lippstadt Airport",
        "city": "Paderborn",
        "country": "Germany",
        "iata": "PAD",
        "icao": "EDLP",
        "latitude": "51.614101409899995",
        "longitude": "8.616319656369999",
        "altitude": "699",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "DTM": {
        "name": "Dortmund Airport",
        "city": "Dortmund",
        "country": "Germany",
        "iata": "DTM",
        "icao": "EDLW",
        "latitude": "51.51829910279999",
        "longitude": "7.61223983765",
        "altitude": "425",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "AGB": {
        "name": "Augsburg Airport",
        "city": "Augsburg",
        "country": "Germany",
        "iata": "AGB",
        "icao": "EDMA",
        "latitude": "48.425278",
        "longitude": "10.931667",
        "altitude": "1516",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "OBF": {
        "name": "Oberpfaffenhofen Airport",
        "city": "Oberpfaffenhofen",
        "country": "Germany",
        "iata": "OBF",
        "icao": "EDMO",
        "latitude": "48.081402",
        "longitude": "11.2831",
        "altitude": "1947",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "RBM": {
        "name": "Straubing Airport",
        "city": "Straubing",
        "country": "Germany",
        "iata": "RBM",
        "icao": "EDMS",
        "latitude": "48.90083312988281",
        "longitude": "12.516667366027832",
        "altitude": "1047",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FDH": {
        "name": "Friedrichshafen Airport",
        "city": "Friedrichshafen",
        "country": "Germany",
        "iata": "FDH",
        "icao": "EDNY",
        "latitude": "47.671298980699994",
        "longitude": "9.51148986816",
        "altitude": "1367",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "SZW": {
        "name": "Schwerin Parchim Airport",
        "city": "Parchim",
        "country": "Germany",
        "iata": "SZW",
        "icao": "EDOP",
        "latitude": "53.426998",
        "longitude": "11.7834",
        "altitude": "166",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BYU": {
        "name": "Bayreuth Airport",
        "city": "Bayreuth",
        "country": "Germany",
        "iata": "BYU",
        "icao": "EDQD",
        "latitude": "49.985001",
        "longitude": "11.64",
        "altitude": "1601",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "URD": {
        "name": "Burg Feuerstein Airport",
        "city": "Burg Feuerstein",
        "country": "Germany",
        "iata": "URD",
        "icao": "EDQE",
        "latitude": "49.794166564941",
        "longitude": "11.133610725403",
        "altitude": "1673",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "HOQ": {
        "name": "Hof-Plauen Airport",
        "city": "Hof",
        "country": "Germany",
        "iata": "HOQ",
        "icao": "EDQM",
        "latitude": "50.288612365722656",
        "longitude": "11.856389045715332",
        "altitude": "1959",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ZQW": {
        "name": "Zweibrücken Airport",
        "city": "Zweibruecken",
        "country": "Germany",
        "iata": "ZQW",
        "icao": "EDRZ",
        "latitude": "49.2094",
        "longitude": "7.40056",
        "altitude": "1132",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ZQL": {
        "name": "Donaueschingen-Villingen Airport",
        "city": "Donaueschingen",
        "country": "Germany",
        "iata": "ZQL",
        "icao": "EDTD",
        "latitude": "47.97333145139999",
        "longitude": "8.52222156525",
        "altitude": "2231",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BWE": {
        "name": "Braunschweig-Wolfsburg Airport",
        "city": "Braunschweig",
        "country": "Germany",
        "iata": "BWE",
        "icao": "EDVE",
        "latitude": "52.319199",
        "longitude": "10.5561",
        "altitude": "295",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "KSF": {
        "name": "Kassel-Calden Airport",
        "city": "Kassel",
        "country": "Germany",
        "iata": "KSF",
        "icao": "EDVK",
        "latitude": "51.417273",
        "longitude": "9.384967",
        "altitude": "820",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BRV": {
        "name": "Bremerhaven Airport",
        "city": "Bremerhaven",
        "country": "Germany",
        "iata": "BRV",
        "icao": "EDWB",
        "latitude": "53.506943",
        "longitude": "8.572778",
        "altitude": "10",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "EME": {
        "name": "Emden Airport",
        "city": "Emden",
        "country": "Germany",
        "iata": "EME",
        "icao": "EDWE",
        "latitude": "53.391109",
        "longitude": "7.2275",
        "altitude": "3",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "WVN": {
        "name": "Wilhelmshaven-Mariensiel Airport",
        "city": "Wilhelmshaven",
        "country": "Germany",
        "iata": "WVN",
        "icao": "EDWI",
        "latitude": "53.502220153808594",
        "longitude": "8.05222225189209",
        "altitude": "16",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BMK": {
        "name": "Borkum Airport",
        "city": "Borkum",
        "country": "Germany",
        "iata": "BMK",
        "icao": "EDWR",
        "latitude": "53.59639",
        "longitude": "6.709167",
        "altitude": "3",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "NRD": {
        "name": "Norderney Airport",
        "city": "Norderney",
        "country": "Germany",
        "iata": "NRD",
        "icao": "EDWY",
        "latitude": "53.70694351196289",
        "longitude": "7.230000019073486",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FLF": {
        "name": "Flensburg-Schäferhaus Airport",
        "city": "Flensburg",
        "country": "Germany",
        "iata": "FLF",
        "icao": "EDXF",
        "latitude": "54.77333450317383",
        "longitude": "9.378889083862305",
        "altitude": "131",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "GWT": {
        "name": "Westerland Sylt Airport",
        "city": "Westerland",
        "country": "Germany",
        "iata": "GWT",
        "icao": "EDXW",
        "latitude": "54.9132003784",
        "longitude": "8.34047031403",
        "altitude": "51",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "KDL": {
        "name": "Kärdla Airport",
        "city": "Kardla",
        "country": "Estonia",
        "iata": "KDL",
        "icao": "EEKA",
        "latitude": "58.99079895019531",
        "longitude": "22.830699920654297",
        "altitude": "18",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Tallinn"
    },
    "URE": {
        "name": "Kuressaare Airport",
        "city": "Kuressaare",
        "country": "Estonia",
        "iata": "URE",
        "icao": "EEKE",
        "latitude": "58.22990036010742",
        "longitude": "22.50950050354004",
        "altitude": "14",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Tallinn"
    },
    "EPU": {
        "name": "Pärnu Airport",
        "city": "Parnu",
        "country": "Estonia",
        "iata": "EPU",
        "icao": "EEPU",
        "latitude": "58.41899871826172",
        "longitude": "24.47279930114746",
        "altitude": "47",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Tallinn"
    },
    "TLL": {
        "name": "Lennart Meri Tallinn Airport",
        "city": "Tallinn-ulemiste International",
        "country": "Estonia",
        "iata": "TLL",
        "icao": "EETN",
        "latitude": "59.41329956049999",
        "longitude": "24.832799911499997",
        "altitude": "131",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Tallinn"
    },
    "TAY": {
        "name": "Tartu Airport",
        "city": "Tartu",
        "country": "Estonia",
        "iata": "TAY",
        "icao": "EETU",
        "latitude": "58.3074989319",
        "longitude": "26.690399169900004",
        "altitude": "219",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Tallinn"
    },
    "ENF": {
        "name": "Enontekio Airport",
        "city": "Enontekio",
        "country": "Finland",
        "iata": "ENF",
        "icao": "EFET",
        "latitude": "68.362602233887",
        "longitude": "23.424299240112",
        "altitude": "1005",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KEV": {
        "name": "Halli Airport",
        "city": "Halli",
        "country": "Finland",
        "iata": "KEV",
        "icao": "EFHA",
        "latitude": "61.856039",
        "longitude": "24.786686",
        "altitude": "479",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "HEM": {
        "name": "Helsinki Malmi Airport",
        "city": "Helsinki",
        "country": "Finland",
        "iata": "HEM",
        "icao": "EFHF",
        "latitude": "60.254600524902344",
        "longitude": "25.042800903320312",
        "altitude": "57",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "HEL": {
        "name": "Helsinki Vantaa Airport",
        "city": "Helsinki",
        "country": "Finland",
        "iata": "HEL",
        "icao": "EFHK",
        "latitude": "60.317199707031",
        "longitude": "24.963300704956",
        "altitude": "179",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "HYV": {
        "name": "Hyvinkää Airfield",
        "city": "Hyvinkaa",
        "country": "Finland",
        "iata": "HYV",
        "icao": "EFHV",
        "latitude": "60.6543998718",
        "longitude": "24.8810997009",
        "altitude": "430",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KTQ": {
        "name": "Kitee Airport",
        "city": "Kitee",
        "country": "Finland",
        "iata": "KTQ",
        "icao": "EFIT",
        "latitude": "62.1661",
        "longitude": "30.073601",
        "altitude": "364",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "IVL": {
        "name": "Ivalo Airport",
        "city": "Ivalo",
        "country": "Finland",
        "iata": "IVL",
        "icao": "EFIV",
        "latitude": "68.607299804688",
        "longitude": "27.405300140381",
        "altitude": "481",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "JOE": {
        "name": "Joensuu Airport",
        "city": "Joensuu",
        "country": "Finland",
        "iata": "JOE",
        "icao": "EFJO",
        "latitude": "62.662899017334",
        "longitude": "29.607500076294",
        "altitude": "398",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "JYV": {
        "name": "Jyvaskyla Airport",
        "city": "Jyvaskyla",
        "country": "Finland",
        "iata": "JYV",
        "icao": "EFJY",
        "latitude": "62.399501800537",
        "longitude": "25.678300857544",
        "altitude": "459",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KAU": {
        "name": "Kauhava Airport",
        "city": "Kauhava",
        "country": "Finland",
        "iata": "KAU",
        "icao": "EFKA",
        "latitude": "63.127102",
        "longitude": "23.051399",
        "altitude": "151",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KEM": {
        "name": "Kemi-Tornio Airport",
        "city": "Kemi",
        "country": "Finland",
        "iata": "KEM",
        "icao": "EFKE",
        "latitude": "65.778701782227",
        "longitude": "24.582099914551",
        "altitude": "61",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KAJ": {
        "name": "Kajaani Airport",
        "city": "Kajaani",
        "country": "Finland",
        "iata": "KAJ",
        "icao": "EFKI",
        "latitude": "64.285499572754",
        "longitude": "27.692399978638",
        "altitude": "483",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KHJ": {
        "name": "Kauhajoki Airport",
        "city": "Kauhajoki",
        "country": "Finland",
        "iata": "KHJ",
        "icao": "EFKJ",
        "latitude": "62.4625015259",
        "longitude": "22.3931007385",
        "altitude": "407",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KOK": {
        "name": "Kokkola-Pietarsaari Airport",
        "city": "Kruunupyy",
        "country": "Finland",
        "iata": "KOK",
        "icao": "EFKK",
        "latitude": "63.721199035645",
        "longitude": "23.143100738525",
        "altitude": "84",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KAO": {
        "name": "Kuusamo Airport",
        "city": "Kuusamo",
        "country": "Finland",
        "iata": "KAO",
        "icao": "EFKS",
        "latitude": "65.987602233887",
        "longitude": "29.239400863647",
        "altitude": "866",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KTT": {
        "name": "Kittilä Airport",
        "city": "Kittila",
        "country": "Finland",
        "iata": "KTT",
        "icao": "EFKT",
        "latitude": "67.700996398926",
        "longitude": "24.846799850464",
        "altitude": "644",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "KUO": {
        "name": "Kuopio Airport",
        "city": "Kuopio",
        "country": "Finland",
        "iata": "KUO",
        "icao": "EFKU",
        "latitude": "63.007099151611",
        "longitude": "27.797800064087",
        "altitude": "323",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "QLF": {
        "name": "Lahti Vesivehmaa Airport",
        "city": "Vesivehmaa",
        "country": "Finland",
        "iata": "QLF",
        "icao": "EFLA",
        "latitude": "61.144199",
        "longitude": "25.693501",
        "altitude": "502",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "LPP": {
        "name": "Lappeenranta Airport",
        "city": "Lappeenranta",
        "country": "Finland",
        "iata": "LPP",
        "icao": "EFLP",
        "latitude": "61.044601",
        "longitude": "28.144743",
        "altitude": "349",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "MHQ": {
        "name": "Mariehamn Airport",
        "city": "Mariehamn",
        "country": "Finland",
        "iata": "MHQ",
        "icao": "EFMA",
        "latitude": "60.122200012207",
        "longitude": "19.898199081421",
        "altitude": "17",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Mariehamn"
    },
    "MIK": {
        "name": "Mikkeli Airport",
        "city": "Mikkeli",
        "country": "Finland",
        "iata": "MIK",
        "icao": "EFMI",
        "latitude": "61.6866",
        "longitude": "27.201799",
        "altitude": "329",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "OUL": {
        "name": "Oulu Airport",
        "city": "Oulu",
        "country": "Finland",
        "iata": "OUL",
        "icao": "EFOU",
        "latitude": "64.930099487305",
        "longitude": "25.354600906372",
        "altitude": "47",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "POR": {
        "name": "Pori Airport",
        "city": "Pori",
        "country": "Finland",
        "iata": "POR",
        "icao": "EFPO",
        "latitude": "61.461700439453",
        "longitude": "21.799999237061",
        "altitude": "44",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "RVN": {
        "name": "Rovaniemi Airport",
        "city": "Rovaniemi",
        "country": "Finland",
        "iata": "RVN",
        "icao": "EFRO",
        "latitude": "66.564796447754",
        "longitude": "25.830400466919",
        "altitude": "642",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "SVL": {
        "name": "Savonlinna Airport",
        "city": "Savonlinna",
        "country": "Finland",
        "iata": "SVL",
        "icao": "EFSA",
        "latitude": "61.943099975586",
        "longitude": "28.945100784302",
        "altitude": "311",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "SOT": {
        "name": "Sodankyla Airport",
        "city": "Sodankyla",
        "country": "Finland",
        "iata": "SOT",
        "icao": "EFSO",
        "latitude": "67.3949966431",
        "longitude": "26.6191005707",
        "altitude": "602",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "TMP": {
        "name": "Tampere-Pirkkala Airport",
        "city": "Tampere",
        "country": "Finland",
        "iata": "TMP",
        "icao": "EFTP",
        "latitude": "61.414100646973",
        "longitude": "23.604400634766",
        "altitude": "390",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "TKU": {
        "name": "Turku Airport",
        "city": "Turku",
        "country": "Finland",
        "iata": "TKU",
        "icao": "EFTU",
        "latitude": "60.514099121094",
        "longitude": "22.262800216675",
        "altitude": "161",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "UTI": {
        "name": "Utti Air Base",
        "city": "Utti",
        "country": "Finland",
        "iata": "UTI",
        "icao": "EFUT",
        "latitude": "60.89640045166",
        "longitude": "26.938400268555",
        "altitude": "339",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "VAA": {
        "name": "Vaasa Airport",
        "city": "Vaasa",
        "country": "Finland",
        "iata": "VAA",
        "icao": "EFVA",
        "latitude": "63.050701141357",
        "longitude": "21.762199401855",
        "altitude": "19",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "VRK": {
        "name": "Varkaus Airport",
        "city": "Varkaus",
        "country": "Finland",
        "iata": "VRK",
        "icao": "EFVR",
        "latitude": "62.171100616455",
        "longitude": "27.868600845337",
        "altitude": "286",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "YLI": {
        "name": "Ylivieska Airfield",
        "city": "Ylivieska-raudaskyla",
        "country": "Finland",
        "iata": "YLI",
        "icao": "EFYL",
        "latitude": "64.0547222",
        "longitude": "24.7252778",
        "altitude": "252",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "BFS": {
        "name": "Belfast International Airport",
        "city": "Belfast",
        "country": "United Kingdom",
        "iata": "BFS",
        "icao": "EGAA",
        "latitude": "54.6575012207",
        "longitude": "-6.2158298492399995",
        "altitude": "268",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ENK": {
        "name": "St Angelo Airport",
        "city": "Enniskillen",
        "country": "United Kingdom",
        "iata": "ENK",
        "icao": "EGAB",
        "latitude": "54.39889907836914",
        "longitude": "-7.651669979095459",
        "altitude": "155",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BHD": {
        "name": "George Best Belfast City Airport",
        "city": "Belfast",
        "country": "United Kingdom",
        "iata": "BHD",
        "icao": "EGAC",
        "latitude": "54.618099212646484",
        "longitude": "-5.872499942779541",
        "altitude": "15",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LDY": {
        "name": "City of Derry Airport",
        "city": "Londonderry",
        "country": "United Kingdom",
        "iata": "LDY",
        "icao": "EGAE",
        "latitude": "55.04280090332031",
        "longitude": "-7.161109924316406",
        "altitude": "22",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BHX": {
        "name": "Birmingham International Airport",
        "city": "Birmingham",
        "country": "United Kingdom",
        "iata": "BHX",
        "icao": "EGBB",
        "latitude": "52.453899383499994",
        "longitude": "-1.74802994728",
        "altitude": "327",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CVT": {
        "name": "Coventry Airport",
        "city": "Coventry",
        "country": "United Kingdom",
        "iata": "CVT",
        "icao": "EGBE",
        "latitude": "52.3697013855",
        "longitude": "-1.4797199964499999",
        "altitude": "267",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "GLO": {
        "name": "Gloucestershire Airport",
        "city": "Golouchestershire",
        "country": "United Kingdom",
        "iata": "GLO",
        "icao": "EGBJ",
        "latitude": "51.89419937133789",
        "longitude": "-2.167220115661621",
        "altitude": "101",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "GBA": {
        "name": "Cotswold Airport",
        "city": "Pailton",
        "country": "United Kingdom",
        "iata": "GBA",
        "icao": "EGBP",
        "latitude": "51.668095",
        "longitude": "-2.05694",
        "altitude": "433",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "MAN": {
        "name": "Manchester Airport",
        "city": "Manchester",
        "country": "United Kingdom",
        "iata": "MAN",
        "icao": "EGCC",
        "latitude": "53.35369873046875",
        "longitude": "-2.2749500274658203",
        "altitude": "257",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "NQY": {
        "name": "Newquay Cornwall Airport",
        "city": "Newquai",
        "country": "United Kingdom",
        "iata": "NQY",
        "icao": "EGHQ",
        "latitude": "50.44060134887695",
        "longitude": "-4.995409965515137",
        "altitude": "390",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LYE": {
        "name": "RAF Lyneham",
        "city": "Lyneham",
        "country": "United Kingdom",
        "iata": "LYE",
        "icao": "EGDL",
        "latitude": "51.5051",
        "longitude": "-1.99343",
        "altitude": "513",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "YEO": {
        "name": "RNAS Yeovilton",
        "city": "Yeovilton",
        "country": "United Kingdom",
        "iata": "YEO",
        "icao": "EGDY",
        "latitude": "51.0093994140625",
        "longitude": "-2.638819932937622",
        "altitude": "75",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "HAW": {
        "name": "Haverfordwest Airport",
        "city": "Haverfordwest",
        "country": "United Kingdom",
        "iata": "HAW",
        "icao": "EGFE",
        "latitude": "51.833099365234375",
        "longitude": "-4.9611101150512695",
        "altitude": "159",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CWL": {
        "name": "Cardiff International Airport",
        "city": "Cardiff",
        "country": "United Kingdom",
        "iata": "CWL",
        "icao": "EGFF",
        "latitude": "51.39670181274414",
        "longitude": "-3.343329906463623",
        "altitude": "220",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SWS": {
        "name": "Swansea Airport",
        "city": "Swansea",
        "country": "United Kingdom",
        "iata": "SWS",
        "icao": "EGFH",
        "latitude": "51.60530090332031",
        "longitude": "-4.0678300857543945",
        "altitude": "299",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BRS": {
        "name": "Bristol Airport",
        "city": "Bristol",
        "country": "United Kingdom",
        "iata": "BRS",
        "icao": "EGGD",
        "latitude": "51.382702",
        "longitude": "-2.71909",
        "altitude": "622",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LPL": {
        "name": "Liverpool John Lennon Airport",
        "city": "Liverpool",
        "country": "United Kingdom",
        "iata": "LPL",
        "icao": "EGGP",
        "latitude": "53.33359909057617",
        "longitude": "-2.849720001220703",
        "altitude": "80",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LTN": {
        "name": "London Luton Airport",
        "city": "London",
        "country": "United Kingdom",
        "iata": "LTN",
        "icao": "EGGW",
        "latitude": "51.874698638916016",
        "longitude": "-0.36833301186561584",
        "altitude": "526",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "PLH": {
        "name": "Plymouth City Airport",
        "city": "Plymouth",
        "country": "United Kingdom",
        "iata": "PLH",
        "icao": "EGHD",
        "latitude": "50.422798",
        "longitude": "-4.10583",
        "altitude": "476",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BOH": {
        "name": "Bournemouth Airport",
        "city": "Bournemouth",
        "country": "United Kingdom",
        "iata": "BOH",
        "icao": "EGHH",
        "latitude": "50.779998779296875",
        "longitude": "-1.8424999713897705",
        "altitude": "38",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SOU": {
        "name": "Southampton Airport",
        "city": "Southampton",
        "country": "United Kingdom",
        "iata": "SOU",
        "icao": "EGHI",
        "latitude": "50.95029830932617",
        "longitude": "-1.3567999601364136",
        "altitude": "44",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "QLA": {
        "name": "Lasham Airport",
        "city": "Lasham",
        "country": "United Kingdom",
        "iata": "QLA",
        "icao": "EGHL",
        "latitude": "51.187198638916016",
        "longitude": "-1.0334999561309814",
        "altitude": "618",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ACI": {
        "name": "Alderney Airport",
        "city": "Alderney",
        "country": "Guernsey",
        "iata": "ACI",
        "icao": "EGJA",
        "latitude": "49.706104",
        "longitude": "-2.21472",
        "altitude": "290",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Guernsey"
    },
    "GCI": {
        "name": "Guernsey Airport",
        "city": "Guernsey",
        "country": "Guernsey",
        "iata": "GCI",
        "icao": "EGJB",
        "latitude": "49.435001373291016",
        "longitude": "-2.6019699573516846",
        "altitude": "336",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Guernsey"
    },
    "JER": {
        "name": "Jersey Airport",
        "city": "Jersey",
        "country": "Jersey",
        "iata": "JER",
        "icao": "EGJJ",
        "latitude": "49.20790100097656",
        "longitude": "-2.195509910583496",
        "altitude": "277",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Jersey"
    },
    "ESH": {
        "name": "Shoreham Airport",
        "city": "Shoreham By Sea",
        "country": "United Kingdom",
        "iata": "ESH",
        "icao": "EGKA",
        "latitude": "50.835602",
        "longitude": "-0.297222",
        "altitude": "7",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BQH": {
        "name": "London Biggin Hill Airport",
        "city": "Biggin Hill",
        "country": "United Kingdom",
        "iata": "BQH",
        "icao": "EGKB",
        "latitude": "51.33079910279999",
        "longitude": "0.0324999988079",
        "altitude": "598",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LGW": {
        "name": "London Gatwick Airport",
        "city": "London",
        "country": "United Kingdom",
        "iata": "LGW",
        "icao": "EGKK",
        "latitude": "51.148102",
        "longitude": "-0.190278",
        "altitude": "202",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LCY": {
        "name": "London City Airport",
        "city": "London",
        "country": "United Kingdom",
        "iata": "LCY",
        "icao": "EGLC",
        "latitude": "51.505299",
        "longitude": "0.055278",
        "altitude": "19",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "FAB": {
        "name": "Farnborough Airport",
        "city": "Farnborough",
        "country": "United Kingdom",
        "iata": "FAB",
        "icao": "EGLF",
        "latitude": "51.2757987976",
        "longitude": "-0.776332974434",
        "altitude": "238",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BBS": {
        "name": "Blackbushe Airport",
        "city": "Blackbushe",
        "country": "United Kingdom",
        "iata": "BBS",
        "icao": "EGLK",
        "latitude": "51.32389831542969",
        "longitude": "-0.8475000262260437",
        "altitude": "325",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LHR": {
        "name": "London Heathrow Airport",
        "city": "London",
        "country": "United Kingdom",
        "iata": "LHR",
        "icao": "EGLL",
        "latitude": "51.4706",
        "longitude": "-0.461941",
        "altitude": "83",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SEN": {
        "name": "Southend Airport",
        "city": "Southend",
        "country": "United Kingdom",
        "iata": "SEN",
        "icao": "EGMC",
        "latitude": "51.5713996887207",
        "longitude": "0.6955559849739075",
        "altitude": "49",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LYX": {
        "name": "Lydd Airport",
        "city": "Lydd",
        "country": "United Kingdom",
        "iata": "LYX",
        "icao": "EGMD",
        "latitude": "50.95610046386719",
        "longitude": "0.9391670227050781",
        "altitude": "13",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "MSE": {
        "name": "Kent International Airport",
        "city": "Manston",
        "country": "United Kingdom",
        "iata": "MSE",
        "icao": "EGMH",
        "latitude": "51.342201",
        "longitude": "1.34611",
        "altitude": "178",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CAX": {
        "name": "Carlisle Airport",
        "city": "Carlisle",
        "country": "United Kingdom",
        "iata": "CAX",
        "icao": "EGNC",
        "latitude": "54.9375",
        "longitude": "-2.8091700077056885",
        "altitude": "190",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BLK": {
        "name": "Blackpool International Airport",
        "city": "Blackpool",
        "country": "United Kingdom",
        "iata": "BLK",
        "icao": "EGNH",
        "latitude": "53.77170181274414",
        "longitude": "-3.0286099910736084",
        "altitude": "34",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "HUY": {
        "name": "Humberside Airport",
        "city": "Humberside",
        "country": "United Kingdom",
        "iata": "HUY",
        "icao": "EGNJ",
        "latitude": "53.57440185546875",
        "longitude": "-0.350832998752594",
        "altitude": "121",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BWF": {
        "name": "Barrow Walney Island Airport",
        "city": "Barrow Island",
        "country": "United Kingdom",
        "iata": "BWF",
        "icao": "EGNL",
        "latitude": "54.1286111",
        "longitude": "-3.2675",
        "altitude": "173",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LBA": {
        "name": "Leeds Bradford Airport",
        "city": "Leeds",
        "country": "United Kingdom",
        "iata": "LBA",
        "icao": "EGNM",
        "latitude": "53.86589813232422",
        "longitude": "-1.6605700254440308",
        "altitude": "681",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "WRT": {
        "name": "Warton Airport",
        "city": "Warton",
        "country": "United Kingdom",
        "iata": "WRT",
        "icao": "EGNO",
        "latitude": "53.745098",
        "longitude": "-2.88306",
        "altitude": "55",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CEG": {
        "name": "Hawarden Airport",
        "city": "Hawarden",
        "country": "United Kingdom",
        "iata": "CEG",
        "icao": "EGNR",
        "latitude": "53.1781005859375",
        "longitude": "-2.9777801036834717",
        "altitude": "45",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "IOM": {
        "name": "Isle of Man Airport",
        "city": "Isle Of Man",
        "country": "Isle of Man",
        "iata": "IOM",
        "icao": "EGNS",
        "latitude": "54.08330154418945",
        "longitude": "-4.623889923095703",
        "altitude": "52",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Isle_of_Man"
    },
    "NCL": {
        "name": "Newcastle Airport",
        "city": "Newcastle",
        "country": "United Kingdom",
        "iata": "NCL",
        "icao": "EGNT",
        "latitude": "55.037498474121094",
        "longitude": "-1.6916699409484863",
        "altitude": "266",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "MME": {
        "name": "Durham Tees Valley Airport",
        "city": "Teesside",
        "country": "United Kingdom",
        "iata": "MME",
        "icao": "EGNV",
        "latitude": "54.50920104980469",
        "longitude": "-1.4294099807739258",
        "altitude": "120",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "EMA": {
        "name": "East Midlands Airport",
        "city": "East Midlands",
        "country": "United Kingdom",
        "iata": "EMA",
        "icao": "EGNX",
        "latitude": "52.8311004639",
        "longitude": "-1.32806003094",
        "altitude": "306",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "KOI": {
        "name": "Kirkwall Airport",
        "city": "Kirkwall",
        "country": "United Kingdom",
        "iata": "KOI",
        "icao": "EGPA",
        "latitude": "58.957801818847656",
        "longitude": "-2.9049999713897705",
        "altitude": "50",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LSI": {
        "name": "Sumburgh Airport",
        "city": "Sumburgh",
        "country": "United Kingdom",
        "iata": "LSI",
        "icao": "EGPB",
        "latitude": "59.87889862060547",
        "longitude": "-1.2955600023269653",
        "altitude": "20",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "WIC": {
        "name": "Wick Airport",
        "city": "Wick",
        "country": "United Kingdom",
        "iata": "WIC",
        "icao": "EGPC",
        "latitude": "58.458900451660156",
        "longitude": "-3.09306001663208",
        "altitude": "126",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ABZ": {
        "name": "Aberdeen Dyce Airport",
        "city": "Aberdeen",
        "country": "United Kingdom",
        "iata": "ABZ",
        "icao": "EGPD",
        "latitude": "57.201900482177734",
        "longitude": "-2.197779893875122",
        "altitude": "215",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "INV": {
        "name": "Inverness Airport",
        "city": "Inverness",
        "country": "United Kingdom",
        "iata": "INV",
        "icao": "EGPE",
        "latitude": "57.54249954223633",
        "longitude": "-4.047500133514404",
        "altitude": "31",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "GLA": {
        "name": "Glasgow International Airport",
        "city": "Glasgow",
        "country": "United Kingdom",
        "iata": "GLA",
        "icao": "EGPF",
        "latitude": "55.8718986511",
        "longitude": "-4.43306016922",
        "altitude": "26",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "EDI": {
        "name": "Edinburgh Airport",
        "city": "Edinburgh",
        "country": "United Kingdom",
        "iata": "EDI",
        "icao": "EGPH",
        "latitude": "55.95000076293945",
        "longitude": "-3.372499942779541",
        "altitude": "135",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ILY": {
        "name": "Islay Airport",
        "city": "Islay",
        "country": "United Kingdom",
        "iata": "ILY",
        "icao": "EGPI",
        "latitude": "55.68190002441406",
        "longitude": "-6.256669998168945",
        "altitude": "56",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "PIK": {
        "name": "Glasgow Prestwick Airport",
        "city": "Prestwick",
        "country": "United Kingdom",
        "iata": "PIK",
        "icao": "EGPK",
        "latitude": "55.5093994140625",
        "longitude": "-4.586669921875",
        "altitude": "65",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BEB": {
        "name": "Benbecula Airport",
        "city": "Benbecula",
        "country": "United Kingdom",
        "iata": "BEB",
        "icao": "EGPL",
        "latitude": "57.48109817504883",
        "longitude": "-7.3627800941467285",
        "altitude": "19",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SCS": {
        "name": "Scatsta Airport",
        "city": "Scatsta",
        "country": "United Kingdom",
        "iata": "SCS",
        "icao": "EGPM",
        "latitude": "60.43280029296875",
        "longitude": "-1.2961100339889526",
        "altitude": "81",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "DND": {
        "name": "Dundee Airport",
        "city": "Dundee",
        "country": "United Kingdom",
        "iata": "DND",
        "icao": "EGPN",
        "latitude": "56.45249938964844",
        "longitude": "-3.025830030441284",
        "altitude": "17",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SYY": {
        "name": "Stornoway Airport",
        "city": "Stornoway",
        "country": "United Kingdom",
        "iata": "SYY",
        "icao": "EGPO",
        "latitude": "58.215599060058594",
        "longitude": "-6.331110000610352",
        "altitude": "26",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "TRE": {
        "name": "Tiree Airport",
        "city": "Tiree",
        "country": "United Kingdom",
        "iata": "TRE",
        "icao": "EGPU",
        "latitude": "56.49919891357422",
        "longitude": "-6.869170188903809",
        "altitude": "38",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ADX": {
        "name": "RAF Leuchars",
        "city": "Leuchars",
        "country": "United Kingdom",
        "iata": "ADX",
        "icao": "EGQL",
        "latitude": "56.37289810180664",
        "longitude": "-2.8684399127960205",
        "altitude": "38",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LMO": {
        "name": "RAF Lossiemouth",
        "city": "Lossiemouth",
        "country": "United Kingdom",
        "iata": "LMO",
        "icao": "EGQS",
        "latitude": "57.7052001953125",
        "longitude": "-3.339169979095459",
        "altitude": "42",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CBG": {
        "name": "Cambridge Airport",
        "city": "Cambridge",
        "country": "United Kingdom",
        "iata": "CBG",
        "icao": "EGSC",
        "latitude": "52.205002",
        "longitude": "0.175",
        "altitude": "47",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "NWI": {
        "name": "Norwich International Airport",
        "city": "Norwich",
        "country": "United Kingdom",
        "iata": "NWI",
        "icao": "EGSH",
        "latitude": "52.6758003235",
        "longitude": "1.28278005123",
        "altitude": "117",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "STN": {
        "name": "London Stansted Airport",
        "city": "London",
        "country": "United Kingdom",
        "iata": "STN",
        "icao": "EGSS",
        "latitude": "51.8849983215",
        "longitude": "0.234999999404",
        "altitude": "348",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "EXT": {
        "name": "Exeter International Airport",
        "city": "Exeter",
        "country": "United Kingdom",
        "iata": "EXT",
        "icao": "EGTE",
        "latitude": "50.73440170288086",
        "longitude": "-3.4138898849487305",
        "altitude": "102",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "FZO": {
        "name": "Bristol Filton Airport",
        "city": "Bristol",
        "country": "United Kingdom",
        "iata": "FZO",
        "icao": "EGTG",
        "latitude": "51.5194015503",
        "longitude": "-2.59083008766",
        "altitude": "226",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "OXF": {
        "name": "Oxford (Kidlington) Airport",
        "city": "Oxford",
        "country": "United Kingdom",
        "iata": "OXF",
        "icao": "EGTK",
        "latitude": "51.8368988037",
        "longitude": "-1.32000005245",
        "altitude": "270",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BEX": {
        "name": "RAF Benson",
        "city": "Benson",
        "country": "United Kingdom",
        "iata": "BEX",
        "icao": "EGUB",
        "latitude": "51.616401672399995",
        "longitude": "-1.09582996368",
        "altitude": "226",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LKZ": {
        "name": "RAF Lakenheath",
        "city": "Lakenheath",
        "country": "United Kingdom",
        "iata": "LKZ",
        "icao": "EGUL",
        "latitude": "52.409301757799994",
        "longitude": "0.56099998951",
        "altitude": "32",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "MHZ": {
        "name": "RAF Mildenhall",
        "city": "Mildenhall",
        "country": "United Kingdom",
        "iata": "MHZ",
        "icao": "EGUN",
        "latitude": "52.361900329589844",
        "longitude": "0.48640599846839905",
        "altitude": "33",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "QUY": {
        "name": "RAF Wyton",
        "city": "Wyton",
        "country": "United Kingdom",
        "iata": "QUY",
        "icao": "EGUY",
        "latitude": "52.3572006226",
        "longitude": "-0.107832998037",
        "altitude": "135",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "FFD": {
        "name": "RAF Fairford",
        "city": "Fairford",
        "country": "United Kingdom",
        "iata": "FFD",
        "icao": "EGVA",
        "latitude": "51.6822013855",
        "longitude": "-1.7900300025900002",
        "altitude": "286",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BZZ": {
        "name": "RAF Brize Norton",
        "city": "Brize Norton",
        "country": "United Kingdom",
        "iata": "BZZ",
        "icao": "EGVN",
        "latitude": "51.75",
        "longitude": "-1.58362",
        "altitude": "288",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ODH": {
        "name": "RAF Odiham",
        "city": "Odiham",
        "country": "United Kingdom",
        "iata": "ODH",
        "icao": "EGVO",
        "latitude": "51.2341003418",
        "longitude": "-0.94282501936",
        "altitude": "405",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "NHT": {
        "name": "RAF Northolt",
        "city": "Northolt",
        "country": "United Kingdom",
        "iata": "NHT",
        "icao": "EGWU",
        "latitude": "51.553001403799996",
        "longitude": "-0.418166995049",
        "altitude": "124",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "QCY": {
        "name": "RAF Coningsby",
        "city": "Coningsby",
        "country": "United Kingdom",
        "iata": "QCY",
        "icao": "EGXC",
        "latitude": "53.0929985046",
        "longitude": "-0.166014000773",
        "altitude": "25",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BEQ": {
        "name": "RAF Honington",
        "city": "Honington",
        "country": "United Kingdom",
        "iata": "BEQ",
        "icao": "EGXH",
        "latitude": "52.34260177612305",
        "longitude": "0.7729390263557434",
        "altitude": "174",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SQZ": {
        "name": "RAF Scampton",
        "city": "Scampton",
        "country": "United Kingdom",
        "iata": "SQZ",
        "icao": "EGXP",
        "latitude": "53.307800293",
        "longitude": "-0.550832986832",
        "altitude": "202",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "HRT": {
        "name": "RAF Linton-On-Ouse",
        "city": "Linton-on-ouse",
        "country": "United Kingdom",
        "iata": "HRT",
        "icao": "EGXU",
        "latitude": "54.0489006042",
        "longitude": "-1.2527500391",
        "altitude": "53",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "WTN": {
        "name": "RAF Waddington",
        "city": "Waddington",
        "country": "United Kingdom",
        "iata": "WTN",
        "icao": "EGXW",
        "latitude": "53.1661987305",
        "longitude": "-0.523810982704",
        "altitude": "231",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "KNF": {
        "name": "RAF Marham",
        "city": "Marham",
        "country": "United Kingdom",
        "iata": "KNF",
        "icao": "EGYM",
        "latitude": "52.648395",
        "longitude": "0.550692",
        "altitude": "75",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "MPN": {
        "name": "Mount Pleasant Airport",
        "city": "Mount Pleasant",
        "country": "Falkland Islands",
        "iata": "MPN",
        "icao": "EGYP",
        "latitude": "-51.82279968261719",
        "longitude": "-58.447200775146484",
        "altitude": "244",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "Atlantic/Stanley"
    },
    "AMS": {
        "name": "Amsterdam Airport Schiphol",
        "city": "Amsterdam",
        "country": "Netherlands",
        "iata": "AMS",
        "icao": "EHAM",
        "latitude": "52.308601",
        "longitude": "4.76389",
        "altitude": "-11",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "MST": {
        "name": "Maastricht Aachen Airport",
        "city": "Maastricht",
        "country": "Netherlands",
        "iata": "MST",
        "icao": "EHBK",
        "latitude": "50.911701",
        "longitude": "5.77014",
        "altitude": "375",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "EIN": {
        "name": "Eindhoven Airport",
        "city": "Eindhoven",
        "country": "Netherlands",
        "iata": "EIN",
        "icao": "EHEH",
        "latitude": "51.4500999451",
        "longitude": "5.37452983856",
        "altitude": "74",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "GRQ": {
        "name": "Eelde Airport",
        "city": "Groningen",
        "country": "Netherlands",
        "iata": "GRQ",
        "icao": "EHGG",
        "latitude": "53.1197013855",
        "longitude": "6.57944011688",
        "altitude": "17",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "GLZ": {
        "name": "Gilze Rijen Air Base",
        "city": "Gilze-rijen",
        "country": "Netherlands",
        "iata": "GLZ",
        "icao": "EHGR",
        "latitude": "51.56740188598633",
        "longitude": "4.931829929351807",
        "altitude": "49",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "DHR": {
        "name": "De Kooy Airport",
        "city": "De Kooy",
        "country": "Netherlands",
        "iata": "DHR",
        "icao": "EHKD",
        "latitude": "52.92340087890625",
        "longitude": "4.780620098114014",
        "altitude": "3",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "LEY": {
        "name": "Lelystad Airport",
        "city": "Lelystad",
        "country": "Netherlands",
        "iata": "LEY",
        "icao": "EHLE",
        "latitude": "52.4603",
        "longitude": "5.52722",
        "altitude": "-13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "LWR": {
        "name": "Leeuwarden Air Base",
        "city": "Leeuwarden",
        "country": "Netherlands",
        "iata": "LWR",
        "icao": "EHLW",
        "latitude": "53.228599548339844",
        "longitude": "5.760560035705566",
        "altitude": "3",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "RTM": {
        "name": "Rotterdam The Hague Airport",
        "city": "Rotterdam",
        "country": "Netherlands",
        "iata": "RTM",
        "icao": "EHRD",
        "latitude": "51.956902",
        "longitude": "4.43722",
        "altitude": "-15",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "UTC": {
        "name": "Soesterberg Air Base",
        "city": "Soesterberg",
        "country": "Netherlands",
        "iata": "UTC",
        "icao": "EHSB",
        "latitude": "52.1273002625",
        "longitude": "5.27618980408",
        "altitude": "66",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "ENS": {
        "name": "Twente Airport",
        "city": "Enschede",
        "country": "Netherlands",
        "iata": "ENS",
        "icao": "EHTW",
        "latitude": "52.275833",
        "longitude": "6.889167",
        "altitude": "114",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "LID": {
        "name": "Valkenburg Naval Air Base",
        "city": "Valkenburg",
        "country": "Netherlands",
        "iata": "LID",
        "icao": "EHVB",
        "latitude": "52.166099548300004",
        "longitude": "4.41794013977",
        "altitude": "1",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "WOE": {
        "name": "Woensdrecht Air Base",
        "city": "Woensdrecht",
        "country": "Netherlands",
        "iata": "WOE",
        "icao": "EHWO",
        "latitude": "51.4491",
        "longitude": "4.34203",
        "altitude": "63",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Amsterdam"
    },
    "ORK": {
        "name": "Cork Airport",
        "city": "Cork",
        "country": "Ireland",
        "iata": "ORK",
        "icao": "EICK",
        "latitude": "51.84130096435547",
        "longitude": "-8.491109848022461",
        "altitude": "502",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "GWY": {
        "name": "Galway Airport",
        "city": "Galway",
        "country": "Ireland",
        "iata": "GWY",
        "icao": "EICM",
        "latitude": "53.300201416015625",
        "longitude": "-8.941590309143066",
        "altitude": "81",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "DUB": {
        "name": "Dublin Airport",
        "city": "Dublin",
        "country": "Ireland",
        "iata": "DUB",
        "icao": "EIDW",
        "latitude": "53.421299",
        "longitude": "-6.27007",
        "altitude": "242",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "NOC": {
        "name": "Ireland West Knock Airport",
        "city": "Connaught",
        "country": "Ireland",
        "iata": "NOC",
        "icao": "EIKN",
        "latitude": "53.910301208496094",
        "longitude": "-8.818490028381348",
        "altitude": "665",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "KIR": {
        "name": "Kerry Airport",
        "city": "Kerry",
        "country": "Ireland",
        "iata": "KIR",
        "icao": "EIKY",
        "latitude": "52.18090057373047",
        "longitude": "-9.52377986907959",
        "altitude": "112",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "SNN": {
        "name": "Shannon Airport",
        "city": "Shannon",
        "country": "Ireland",
        "iata": "SNN",
        "icao": "EINN",
        "latitude": "52.702",
        "longitude": "-8.92482",
        "altitude": "46",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "SXL": {
        "name": "Sligo Airport",
        "city": "Sligo",
        "country": "Ireland",
        "iata": "SXL",
        "icao": "EISG",
        "latitude": "54.280200958252",
        "longitude": "-8.5992097854614",
        "altitude": "11",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "WAT": {
        "name": "Waterford Airport",
        "city": "Waterford",
        "country": "Ireland",
        "iata": "WAT",
        "icao": "EIWF",
        "latitude": "52.187198638916016",
        "longitude": "-7.0869598388671875",
        "altitude": "119",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "AAR": {
        "name": "Aarhus Airport",
        "city": "Aarhus",
        "country": "Denmark",
        "iata": "AAR",
        "icao": "EKAH",
        "latitude": "56.2999992371",
        "longitude": "10.619000434899998",
        "altitude": "82",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "BLL": {
        "name": "Billund Airport",
        "city": "Billund",
        "country": "Denmark",
        "iata": "BLL",
        "icao": "EKBI",
        "latitude": "55.7402992249",
        "longitude": "9.15178012848",
        "altitude": "247",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "CPH": {
        "name": "Copenhagen Kastrup Airport",
        "city": "Copenhagen",
        "country": "Denmark",
        "iata": "CPH",
        "icao": "EKCH",
        "latitude": "55.617900848389",
        "longitude": "12.656000137329",
        "altitude": "17",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "EBJ": {
        "name": "Esbjerg Airport",
        "city": "Esbjerg",
        "country": "Denmark",
        "iata": "EBJ",
        "icao": "EKEB",
        "latitude": "55.525902",
        "longitude": "8.5534",
        "altitude": "97",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "KRP": {
        "name": "Karup Airport",
        "city": "Karup",
        "country": "Denmark",
        "iata": "KRP",
        "icao": "EKKA",
        "latitude": "56.29750061035156",
        "longitude": "9.124629974365234",
        "altitude": "170",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "BYR": {
        "name": "Læsø Airport",
        "city": "Laeso",
        "country": "Denmark",
        "iata": "BYR",
        "icao": "EKLS",
        "latitude": "57.277198791503906",
        "longitude": "11.000100135803223",
        "altitude": "25",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "MRW": {
        "name": "Lolland Falster Maribo Airport",
        "city": "Maribo",
        "country": "Denmark",
        "iata": "MRW",
        "icao": "EKMB",
        "latitude": "54.699299",
        "longitude": "11.4401",
        "altitude": "16",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "ODE": {
        "name": "Odense Airport",
        "city": "Odense",
        "country": "Denmark",
        "iata": "ODE",
        "icao": "EKOD",
        "latitude": "55.47669982910156",
        "longitude": "10.330900192260742",
        "altitude": "56",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "RKE": {
        "name": "Copenhagen Roskilde Airport",
        "city": "Copenhagen",
        "country": "Denmark",
        "iata": "RKE",
        "icao": "EKRK",
        "latitude": "55.585601806640625",
        "longitude": "12.131400108337402",
        "altitude": "146",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "RNN": {
        "name": "Bornholm Airport",
        "city": "Ronne",
        "country": "Denmark",
        "iata": "RNN",
        "icao": "EKRN",
        "latitude": "55.06330108642578",
        "longitude": "14.759599685668945",
        "altitude": "52",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "SGD": {
        "name": "Sønderborg Airport",
        "city": "Soenderborg",
        "country": "Denmark",
        "iata": "SGD",
        "icao": "EKSB",
        "latitude": "54.96440124511719",
        "longitude": "9.791729927062988",
        "altitude": "24",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "SKS": {
        "name": "Skrydstrup Air Base",
        "city": "Skrydstrup",
        "country": "Denmark",
        "iata": "SKS",
        "icao": "EKSP",
        "latitude": "55.221048",
        "longitude": "9.26702",
        "altitude": "141",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "SQW": {
        "name": "Skive Airport",
        "city": "Skive",
        "country": "Denmark",
        "iata": "SQW",
        "icao": "EKSV",
        "latitude": "56.550201416015625",
        "longitude": "9.172980308532715",
        "altitude": "74",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "TED": {
        "name": "Thisted Airport",
        "city": "Thisted",
        "country": "Denmark",
        "iata": "TED",
        "icao": "EKTS",
        "latitude": "57.06880187988281",
        "longitude": "8.705220222473145",
        "altitude": "23",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "FAE": {
        "name": "Vagar Airport",
        "city": "Vagar",
        "country": "Faroe Islands",
        "iata": "FAE",
        "icao": "EKVG",
        "latitude": "62.0635986328125",
        "longitude": "-7.277219772338867",
        "altitude": "280",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Faeroe"
    },
    "STA": {
        "name": "Stauning Airport",
        "city": "Stauning",
        "country": "Denmark",
        "iata": "STA",
        "icao": "EKVJ",
        "latitude": "55.9901008605957",
        "longitude": "8.353910446166992",
        "altitude": "17",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "AAL": {
        "name": "Aalborg Airport",
        "city": "Aalborg",
        "country": "Denmark",
        "iata": "AAL",
        "icao": "EKYT",
        "latitude": "57.0927589138",
        "longitude": "9.84924316406",
        "altitude": "10",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Copenhagen"
    },
    "LUX": {
        "name": "Luxembourg-Findel International Airport",
        "city": "Luxemburg",
        "country": "Luxembourg",
        "iata": "LUX",
        "icao": "ELLX",
        "latitude": "49.6233333",
        "longitude": "6.2044444",
        "altitude": "1234",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Luxembourg"
    },
    "AES": {
        "name": "Ålesund Airport",
        "city": "Alesund",
        "country": "Norway",
        "iata": "AES",
        "icao": "ENAL",
        "latitude": "62.5625",
        "longitude": "6.119699954986572",
        "altitude": "69",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "ANX": {
        "name": "Andøya Airport",
        "city": "Andoya",
        "country": "Norway",
        "iata": "ANX",
        "icao": "ENAN",
        "latitude": "69.292503356934",
        "longitude": "16.144199371338",
        "altitude": "43",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "ALF": {
        "name": "Alta Airport",
        "city": "Alta",
        "country": "Norway",
        "iata": "ALF",
        "icao": "ENAT",
        "latitude": "69.976097106934",
        "longitude": "23.371700286865",
        "altitude": "9",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BNN": {
        "name": "Brønnøysund Airport",
        "city": "Bronnoysund",
        "country": "Norway",
        "iata": "BNN",
        "icao": "ENBN",
        "latitude": "65.461097717285",
        "longitude": "12.217499732971",
        "altitude": "25",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BOO": {
        "name": "Bodø Airport",
        "city": "Bodo",
        "country": "Norway",
        "iata": "BOO",
        "icao": "ENBO",
        "latitude": "67.26920318603516",
        "longitude": "14.365300178527832",
        "altitude": "42",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BGO": {
        "name": "Bergen Airport Flesland",
        "city": "Bergen",
        "country": "Norway",
        "iata": "BGO",
        "icao": "ENBR",
        "latitude": "60.29339981",
        "longitude": "5.218140125",
        "altitude": "170",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BJF": {
        "name": "Båtsfjord Airport",
        "city": "Batsfjord",
        "country": "Norway",
        "iata": "BJF",
        "icao": "ENBS",
        "latitude": "70.60050201416",
        "longitude": "29.691400527954",
        "altitude": "490",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "KRS": {
        "name": "Kristiansand Airport",
        "city": "Kristiansand",
        "country": "Norway",
        "iata": "KRS",
        "icao": "ENCN",
        "latitude": "58.204201",
        "longitude": "8.08537",
        "altitude": "57",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "DLD": {
        "name": "Geilo Airport Dagali",
        "city": "Geilo",
        "country": "Norway",
        "iata": "DLD",
        "icao": "ENDI",
        "latitude": "60.417301177978516",
        "longitude": "8.518349647521973",
        "altitude": "2618",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BDU": {
        "name": "Bardufoss Airport",
        "city": "Bardufoss",
        "country": "Norway",
        "iata": "BDU",
        "icao": "ENDU",
        "latitude": "69.055801391602",
        "longitude": "18.540399551392",
        "altitude": "252",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "EVE": {
        "name": "Harstad/Narvik Airport, Evenes",
        "city": "Harstad/Narvik",
        "country": "Norway",
        "iata": "EVE",
        "icao": "ENEV",
        "latitude": "68.491302490234",
        "longitude": "16.678100585938",
        "altitude": "84",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "VDB": {
        "name": "Leirin Airport",
        "city": "Fagernes",
        "country": "Norway",
        "iata": "VDB",
        "icao": "ENFG",
        "latitude": "61.015598297119",
        "longitude": "9.2880601882935",
        "altitude": "2697",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "FRO": {
        "name": "Florø Airport",
        "city": "Floro",
        "country": "Norway",
        "iata": "FRO",
        "icao": "ENFL",
        "latitude": "61.583599090576",
        "longitude": "5.0247201919556",
        "altitude": "37",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "OSL": {
        "name": "Oslo Lufthavn",
        "city": "Oslo",
        "country": "Norway",
        "iata": "OSL",
        "icao": "ENGM",
        "latitude": "60.121",
        "longitude": "11.0502",
        "altitude": "681",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "HAU": {
        "name": "Haugesund Airport",
        "city": "Haugesund",
        "country": "Norway",
        "iata": "HAU",
        "icao": "ENHD",
        "latitude": "59.34529876709",
        "longitude": "5.2083601951599",
        "altitude": "86",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "HAA": {
        "name": "Hasvik Airport",
        "city": "Hasvik",
        "country": "Norway",
        "iata": "HAA",
        "icao": "ENHK",
        "latitude": "70.486701965332",
        "longitude": "22.139699935913",
        "altitude": "21",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "KSU": {
        "name": "Kristiansund Airport (Kvernberget)",
        "city": "Kristiansund",
        "country": "Norway",
        "iata": "KSU",
        "icao": "ENKB",
        "latitude": "63.111801147461",
        "longitude": "7.824520111084",
        "altitude": "204",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "KKN": {
        "name": "Kirkenes Airport (Høybuktmoen)",
        "city": "Kirkenes",
        "country": "Norway",
        "iata": "KKN",
        "icao": "ENKR",
        "latitude": "69.725799560547",
        "longitude": "29.891300201416",
        "altitude": "283",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "FAN": {
        "name": "Lista Airport",
        "city": "Farsund",
        "country": "Norway",
        "iata": "FAN",
        "icao": "ENLI",
        "latitude": "58.0994987487793",
        "longitude": "6.626049995422363",
        "altitude": "29",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "MOL": {
        "name": "Molde Airport",
        "city": "Molde",
        "country": "Norway",
        "iata": "MOL",
        "icao": "ENML",
        "latitude": "62.744701385498",
        "longitude": "7.2624998092651",
        "altitude": "10",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "MJF": {
        "name": "Mosjøen Airport (Kjærstad)",
        "city": "Mosjoen",
        "country": "Norway",
        "iata": "MJF",
        "icao": "ENMS",
        "latitude": "65.783996582031",
        "longitude": "13.214900016785",
        "altitude": "237",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "LKL": {
        "name": "Banak Airport",
        "city": "Lakselv",
        "country": "Norway",
        "iata": "LKL",
        "icao": "ENNA",
        "latitude": "70.068801879883",
        "longitude": "24.973499298096",
        "altitude": "25",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "NTB": {
        "name": "Notodden Airport",
        "city": "Notodden",
        "country": "Norway",
        "iata": "NTB",
        "icao": "ENNO",
        "latitude": "59.565701",
        "longitude": "9.21222",
        "altitude": "63",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "OLA": {
        "name": "Ørland Airport",
        "city": "Orland",
        "country": "Norway",
        "iata": "OLA",
        "icao": "ENOL",
        "latitude": "63.69889831542969",
        "longitude": "9.604000091552734",
        "altitude": "28",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "RRS": {
        "name": "Røros Airport",
        "city": "Roros",
        "country": "Norway",
        "iata": "RRS",
        "icao": "ENRO",
        "latitude": "62.578399658203",
        "longitude": "11.342300415039",
        "altitude": "2054",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "RYG": {
        "name": "Moss Airport, Rygge",
        "city": "Rygge",
        "country": "Norway",
        "iata": "RYG",
        "icao": "ENRY",
        "latitude": "59.378817",
        "longitude": "10.785439",
        "altitude": "174",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "LYR": {
        "name": "Svalbard Airport, Longyear",
        "city": "Svalbard",
        "country": "Norway",
        "iata": "LYR",
        "icao": "ENSB",
        "latitude": "78.246101379395",
        "longitude": "15.465600013733",
        "altitude": "88",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Arctic/Longyearbyen"
    },
    "SKE": {
        "name": "Skien Airport",
        "city": "Skien",
        "country": "Norway",
        "iata": "SKE",
        "icao": "ENSN",
        "latitude": "59.185001373291016",
        "longitude": "9.566940307617188",
        "altitude": "463",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SRP": {
        "name": "Stord Airport",
        "city": "Stord",
        "country": "Norway",
        "iata": "SRP",
        "icao": "ENSO",
        "latitude": "59.791900634765625",
        "longitude": "5.340849876403809",
        "altitude": "160",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SSJ": {
        "name": "Sandnessjøen Airport (Stokka)",
        "city": "Sandnessjoen",
        "country": "Norway",
        "iata": "SSJ",
        "icao": "ENST",
        "latitude": "65.956802368164",
        "longitude": "12.468899726868",
        "altitude": "56",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "TOS": {
        "name": "Tromsø Airport,",
        "city": "Tromso",
        "country": "Norway",
        "iata": "TOS",
        "icao": "ENTC",
        "latitude": "69.68329620361328",
        "longitude": "18.918899536132812",
        "altitude": "31",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "TRF": {
        "name": "Sandefjord Airport, Torp",
        "city": "Sandefjord",
        "country": "Norway",
        "iata": "TRF",
        "icao": "ENTO",
        "latitude": "59.1866989136",
        "longitude": "10.258600235",
        "altitude": "286",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "TRD": {
        "name": "Trondheim Airport Værnes",
        "city": "Trondheim",
        "country": "Norway",
        "iata": "TRD",
        "icao": "ENVA",
        "latitude": "63.4578018",
        "longitude": "10.9239998",
        "altitude": "56",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SVG": {
        "name": "Stavanger Airport Sola",
        "city": "Stavanger",
        "country": "Norway",
        "iata": "SVG",
        "icao": "ENZV",
        "latitude": "58.876701354",
        "longitude": "5.6377801895",
        "altitude": "29",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "GDN": {
        "name": "Gdańsk Lech Wałęsa Airport",
        "city": "Gdansk",
        "country": "Poland",
        "iata": "GDN",
        "icao": "EPGD",
        "latitude": "54.377601623535156",
        "longitude": "18.46619987487793",
        "altitude": "489",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "KRK": {
        "name": "Kraków John Paul II International Airport",
        "city": "Krakow",
        "country": "Poland",
        "iata": "KRK",
        "icao": "EPKK",
        "latitude": "50.077702",
        "longitude": "19.7848",
        "altitude": "791",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "KTW": {
        "name": "Katowice International Airport",
        "city": "Katowice",
        "country": "Poland",
        "iata": "KTW",
        "icao": "EPKT",
        "latitude": "50.4743",
        "longitude": "19.08",
        "altitude": "995",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "POZ": {
        "name": "Poznań-Ławica Airport",
        "city": "Poznan",
        "country": "Poland",
        "iata": "POZ",
        "icao": "EPPO",
        "latitude": "52.421001434299995",
        "longitude": "16.8262996674",
        "altitude": "308",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "RZE": {
        "name": "Rzeszów-Jasionka Airport",
        "city": "Rzeszow",
        "country": "Poland",
        "iata": "RZE",
        "icao": "EPRZ",
        "latitude": "50.1100006104",
        "longitude": "22.0189990997",
        "altitude": "675",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "SZZ": {
        "name": "Szczecin-Goleniów \"Solidarność\" Airport",
        "city": "Szczecin",
        "country": "Poland",
        "iata": "SZZ",
        "icao": "EPSC",
        "latitude": "53.584701538100006",
        "longitude": "14.902199745199999",
        "altitude": "154",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "OSP": {
        "name": "Redzikowo Air Base",
        "city": "Slupsk",
        "country": "Poland",
        "iata": "OSP",
        "icao": "EPSK",
        "latitude": "54.47890090942383",
        "longitude": "17.107500076293945",
        "altitude": "217",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "WAW": {
        "name": "Warsaw Chopin Airport",
        "city": "Warsaw",
        "country": "Poland",
        "iata": "WAW",
        "icao": "EPWA",
        "latitude": "52.1656990051",
        "longitude": "20.967100143399996",
        "altitude": "362",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "WRO": {
        "name": "Copernicus Wrocław Airport",
        "city": "Wroclaw",
        "country": "Poland",
        "iata": "WRO",
        "icao": "EPWR",
        "latitude": "51.1026992798",
        "longitude": "16.885799408",
        "altitude": "404",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "IEG": {
        "name": "Zielona Góra-Babimost Airport",
        "city": "Zielona Gora",
        "country": "Poland",
        "iata": "IEG",
        "icao": "EPZG",
        "latitude": "52.138500213600004",
        "longitude": "15.7986001968",
        "altitude": "194",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "RNB": {
        "name": "Ronneby Airport",
        "city": "Ronneby",
        "country": "Sweden",
        "iata": "RNB",
        "icao": "ESDF",
        "latitude": "56.266700744629",
        "longitude": "15.265000343323",
        "altitude": "191",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "GOT": {
        "name": "Gothenburg-Landvetter Airport",
        "city": "Gothenborg",
        "country": "Sweden",
        "iata": "GOT",
        "icao": "ESGG",
        "latitude": "57.662799835205",
        "longitude": "12.279800415039",
        "altitude": "506",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "JKG": {
        "name": "Jönköping Airport",
        "city": "Joenkoeping",
        "country": "Sweden",
        "iata": "JKG",
        "icao": "ESGJ",
        "latitude": "57.757598876953125",
        "longitude": "14.068699836730957",
        "altitude": "741",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "LDK": {
        "name": "Lidköping-Hovby Airport",
        "city": "Lidkoping",
        "country": "Sweden",
        "iata": "LDK",
        "icao": "ESGL",
        "latitude": "58.4655",
        "longitude": "13.1744",
        "altitude": "200",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "GSE": {
        "name": "Gothenburg City Airport",
        "city": "Gothenborg",
        "country": "Sweden",
        "iata": "GSE",
        "icao": "ESGP",
        "latitude": "57.7747",
        "longitude": "11.8704",
        "altitude": "59",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KVB": {
        "name": "Skövde Airport",
        "city": "Skovde",
        "country": "Sweden",
        "iata": "KVB",
        "icao": "ESGR",
        "latitude": "58.45640182495117",
        "longitude": "13.972700119018555",
        "altitude": "324",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "THN": {
        "name": "Trollhättan-Vänersborg Airport",
        "city": "Trollhattan",
        "country": "Sweden",
        "iata": "THN",
        "icao": "ESGT",
        "latitude": "58.31809997558594",
        "longitude": "12.345000267028809",
        "altitude": "137",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KSK": {
        "name": "Karlskoga Airport",
        "city": "Karlskoga",
        "country": "Sweden",
        "iata": "KSK",
        "icao": "ESKK",
        "latitude": "59.34590148925781",
        "longitude": "14.49590015411377",
        "altitude": "400",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "MXX": {
        "name": "Mora Airport",
        "city": "Mora",
        "country": "Sweden",
        "iata": "MXX",
        "icao": "ESKM",
        "latitude": "60.957901",
        "longitude": "14.5114",
        "altitude": "634",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "NYO": {
        "name": "Stockholm Skavsta Airport",
        "city": "Stockholm",
        "country": "Sweden",
        "iata": "NYO",
        "icao": "ESKN",
        "latitude": "58.78860092163086",
        "longitude": "16.912200927734375",
        "altitude": "140",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KID": {
        "name": "Kristianstad Airport",
        "city": "Kristianstad",
        "country": "Sweden",
        "iata": "KID",
        "icao": "ESMK",
        "latitude": "55.92169952392578",
        "longitude": "14.08549976348877",
        "altitude": "76",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "OSK": {
        "name": "Oskarshamn Airport",
        "city": "Oskarshamn",
        "country": "Sweden",
        "iata": "OSK",
        "icao": "ESMO",
        "latitude": "57.350498",
        "longitude": "16.497999",
        "altitude": "96",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KLR": {
        "name": "Kalmar Airport",
        "city": "Kalkmar",
        "country": "Sweden",
        "iata": "KLR",
        "icao": "ESMQ",
        "latitude": "56.68550109863281",
        "longitude": "16.287599563598633",
        "altitude": "17",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "MMX": {
        "name": "Malmö Sturup Airport",
        "city": "Malmoe",
        "country": "Sweden",
        "iata": "MMX",
        "icao": "ESMS",
        "latitude": "55.536305364",
        "longitude": "13.376197814900001",
        "altitude": "236",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "HAD": {
        "name": "Halmstad Airport",
        "city": "Halmstad",
        "country": "Sweden",
        "iata": "HAD",
        "icao": "ESMT",
        "latitude": "56.69110107421875",
        "longitude": "12.820199966430664",
        "altitude": "101",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "VXO": {
        "name": "Växjö Kronoberg Airport",
        "city": "Vaxjo",
        "country": "Sweden",
        "iata": "VXO",
        "icao": "ESMX",
        "latitude": "56.929100036621094",
        "longitude": "14.727999687194824",
        "altitude": "610",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "EVG": {
        "name": "Sveg Airport",
        "city": "Sveg",
        "country": "Sweden",
        "iata": "EVG",
        "icao": "ESND",
        "latitude": "62.04779815673828",
        "longitude": "14.422900199890137",
        "altitude": "1178",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "GEV": {
        "name": "Gällivare Airport",
        "city": "Gallivare",
        "country": "Sweden",
        "iata": "GEV",
        "icao": "ESNG",
        "latitude": "67.13240051269531",
        "longitude": "20.814599990844727",
        "altitude": "1027",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "HUV": {
        "name": "Hudiksvall Airport",
        "city": "Hudiksvall",
        "country": "Sweden",
        "iata": "HUV",
        "icao": "ESNH",
        "latitude": "61.7681007385",
        "longitude": "17.0806999207",
        "altitude": "95",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KRF": {
        "name": "Kramfors Sollefteå Airport",
        "city": "Kramfors",
        "country": "Sweden",
        "iata": "KRF",
        "icao": "ESNK",
        "latitude": "63.04859924316406",
        "longitude": "17.76889991760254",
        "altitude": "34",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "LYC": {
        "name": "Lycksele Airport",
        "city": "Lycksele",
        "country": "Sweden",
        "iata": "LYC",
        "icao": "ESNL",
        "latitude": "64.54830169677734",
        "longitude": "18.71619987487793",
        "altitude": "705",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "SDL": {
        "name": "Sundsvall-Härnösand Airport",
        "city": "Sundsvall",
        "country": "Sweden",
        "iata": "SDL",
        "icao": "ESNN",
        "latitude": "62.528099060058594",
        "longitude": "17.443899154663086",
        "altitude": "16",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "OER": {
        "name": "Örnsköldsvik Airport",
        "city": "Ornskoldsvik",
        "country": "Sweden",
        "iata": "OER",
        "icao": "ESNO",
        "latitude": "63.40829849243164",
        "longitude": "18.989999771118164",
        "altitude": "354",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KRN": {
        "name": "Kiruna Airport",
        "city": "Kiruna",
        "country": "Sweden",
        "iata": "KRN",
        "icao": "ESNQ",
        "latitude": "67.821998596191",
        "longitude": "20.336799621582",
        "altitude": "1508",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "SFT": {
        "name": "Skellefteå Airport",
        "city": "Skelleftea",
        "country": "Sweden",
        "iata": "SFT",
        "icao": "ESNS",
        "latitude": "64.62480163574219",
        "longitude": "21.076900482177734",
        "altitude": "157",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "UME": {
        "name": "Umeå Airport",
        "city": "Umea",
        "country": "Sweden",
        "iata": "UME",
        "icao": "ESNU",
        "latitude": "63.791801452637",
        "longitude": "20.282800674438",
        "altitude": "24",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "VHM": {
        "name": "Vilhelmina Airport",
        "city": "Vilhelmina",
        "country": "Sweden",
        "iata": "VHM",
        "icao": "ESNV",
        "latitude": "64.5791015625",
        "longitude": "16.833599090576172",
        "altitude": "1140",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "AJR": {
        "name": "Arvidsjaur Airport",
        "city": "Arvidsjaur",
        "country": "Sweden",
        "iata": "AJR",
        "icao": "ESNX",
        "latitude": "65.59030151367188",
        "longitude": "19.28190040588379",
        "altitude": "1245",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "ORB": {
        "name": "Örebro Airport",
        "city": "Orebro",
        "country": "Sweden",
        "iata": "ORB",
        "icao": "ESOE",
        "latitude": "59.22370147705078",
        "longitude": "15.038000106811523",
        "altitude": "188",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "VST": {
        "name": "Stockholm Västerås Airport",
        "city": "Vasteras",
        "country": "Sweden",
        "iata": "VST",
        "icao": "ESOW",
        "latitude": "59.58940124511719",
        "longitude": "16.63360023498535",
        "altitude": "21",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "LLA": {
        "name": "Luleå Airport",
        "city": "Lulea",
        "country": "Sweden",
        "iata": "LLA",
        "icao": "ESPA",
        "latitude": "65.543800354004",
        "longitude": "22.121999740601",
        "altitude": "65",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "ARN": {
        "name": "Stockholm-Arlanda Airport",
        "city": "Stockholm",
        "country": "Sweden",
        "iata": "ARN",
        "icao": "ESSA",
        "latitude": "59.651901245117",
        "longitude": "17.918600082397",
        "altitude": "137",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "BMA": {
        "name": "Stockholm-Bromma Airport",
        "city": "Stockholm",
        "country": "Sweden",
        "iata": "BMA",
        "icao": "ESSB",
        "latitude": "59.354400634765625",
        "longitude": "17.941699981689453",
        "altitude": "47",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "BLE": {
        "name": "Borlange Airport",
        "city": "Borlange",
        "country": "Sweden",
        "iata": "BLE",
        "icao": "ESSD",
        "latitude": "60.422000885009766",
        "longitude": "15.515199661254883",
        "altitude": "503",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "HLF": {
        "name": "Hultsfred Airport",
        "city": "Hultsfred",
        "country": "Sweden",
        "iata": "HLF",
        "icao": "ESSF",
        "latitude": "57.525799",
        "longitude": "15.8233",
        "altitude": "366",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "GVX": {
        "name": "Gävle Sandviken Airport",
        "city": "Gavle",
        "country": "Sweden",
        "iata": "GVX",
        "icao": "ESSK",
        "latitude": "60.593299865722656",
        "longitude": "16.951400756835938",
        "altitude": "224",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "LPI": {
        "name": "Linköping City Airport",
        "city": "Linkoeping",
        "country": "Sweden",
        "iata": "LPI",
        "icao": "ESSL",
        "latitude": "58.4062004089",
        "longitude": "15.680500030500001",
        "altitude": "172",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "NRK": {
        "name": "Norrköping Airport",
        "city": "Norrkoeping",
        "country": "Sweden",
        "iata": "NRK",
        "icao": "ESSP",
        "latitude": "58.586299896240234",
        "longitude": "16.250600814819336",
        "altitude": "32",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "EKT": {
        "name": "Eskilstuna Airport",
        "city": "Eskilstuna",
        "country": "Sweden",
        "iata": "EKT",
        "icao": "ESSU",
        "latitude": "59.35110092163086",
        "longitude": "16.70840072631836",
        "altitude": "139",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "VBY": {
        "name": "Visby Airport",
        "city": "Visby",
        "country": "Sweden",
        "iata": "VBY",
        "icao": "ESSV",
        "latitude": "57.662799835205",
        "longitude": "18.346200942993",
        "altitude": "164",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "SPM": {
        "name": "Spangdahlem Air Base",
        "city": "Spangdahlem",
        "country": "Germany",
        "iata": "SPM",
        "icao": "ETAD",
        "latitude": "49.9726982117",
        "longitude": "6.69250011444",
        "altitude": "1197",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "RMS": {
        "name": "Ramstein Air Base",
        "city": "Ramstein",
        "country": "Germany",
        "iata": "RMS",
        "icao": "ETAR",
        "latitude": "49.4369010925293",
        "longitude": "7.600279808044434",
        "altitude": "776",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "GHF": {
        "name": "[Duplicate] Giebelstadt Army Air Field",
        "city": "Giebelstadt",
        "country": "Germany",
        "iata": "GHF",
        "icao": "ETEU",
        "latitude": "49.648101806599996",
        "longitude": "9.966489791870002",
        "altitude": "980",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ZCN": {
        "name": "Celle Airport",
        "city": "Celle",
        "country": "Germany",
        "iata": "ZCN",
        "icao": "ETHC",
        "latitude": "52.59120178222656",
        "longitude": "10.022100448608398",
        "altitude": "129",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FRZ": {
        "name": "Fritzlar Airport",
        "city": "Fritzlar",
        "country": "Germany",
        "iata": "FRZ",
        "icao": "ETHF",
        "latitude": "51.1146",
        "longitude": "9.286",
        "altitude": "1345",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ZNF": {
        "name": "Hanau Army Air Field",
        "city": "Hanau",
        "country": "Germany",
        "iata": "ZNF",
        "icao": "ETID",
        "latitude": "50.169201",
        "longitude": "8.96159",
        "altitude": "368",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "KZG": {
        "name": "Flugplatz Kitzingen",
        "city": "Kitzingen",
        "country": "Germany",
        "iata": "KZG",
        "icao": "ETIN",
        "latitude": "49.743099",
        "longitude": "10.2006",
        "altitude": "689",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FCN": {
        "name": "Nordholz Naval Airbase",
        "city": "Nordholz",
        "country": "Germany",
        "iata": "FCN",
        "icao": "ETMN",
        "latitude": "53.7677001953",
        "longitude": "8.658499717709999",
        "altitude": "74",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "GKE": {
        "name": "Geilenkirchen Air Base",
        "city": "Geilenkirchen",
        "country": "Germany",
        "iata": "GKE",
        "icao": "ETNG",
        "latitude": "50.9608",
        "longitude": "6.04242",
        "altitude": "296",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "RLG": {
        "name": "Rostock-Laage Airport",
        "city": "Laage",
        "country": "Germany",
        "iata": "RLG",
        "icao": "ETNL",
        "latitude": "53.9182014465",
        "longitude": "12.278300285299999",
        "altitude": "138",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "WBG": {
        "name": "Schleswig Air Base",
        "city": "Schleswig",
        "country": "Germany",
        "iata": "WBG",
        "icao": "ETNS",
        "latitude": "54.459301",
        "longitude": "9.51633",
        "altitude": "70",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "WIE": {
        "name": "Wiesbaden Army Airfield",
        "city": "Wiesbaden",
        "country": "Germany",
        "iata": "WIE",
        "icao": "ETOU",
        "latitude": "50.049800872802734",
        "longitude": "8.325400352478027",
        "altitude": "461",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FEL": {
        "name": "Fürstenfeldbruck Air Base",
        "city": "Fuerstenfeldbruck",
        "country": "Germany",
        "iata": "FEL",
        "icao": "ETSF",
        "latitude": "48.205555",
        "longitude": "11.266944",
        "altitude": "1703",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "IGS": {
        "name": "Ingolstadt Manching Airport",
        "city": "Ingolstadt",
        "country": "Germany",
        "iata": "IGS",
        "icao": "ETSI",
        "latitude": "48.7156982421875",
        "longitude": "11.534000396728516",
        "altitude": "1202",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "GUT": {
        "name": "Gütersloh Air Base",
        "city": "Guetersloh",
        "country": "Germany",
        "iata": "GUT",
        "icao": "ETUO",
        "latitude": "51.922798",
        "longitude": "8.30633",
        "altitude": "236",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "ALJ": {
        "name": "Alexander Bay Airport",
        "city": "Alexander Bay",
        "country": "South Africa",
        "iata": "ALJ",
        "icao": "FAAB",
        "latitude": "-28.575001",
        "longitude": "16.5333",
        "altitude": "98",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "AGZ": {
        "name": "Aggeneys Airport",
        "city": "Aggeneys",
        "country": "South Africa",
        "iata": "AGZ",
        "icao": "FAAG",
        "latitude": "-29.28179931640625",
        "longitude": "18.813899993896484",
        "altitude": "2648",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "BIY": {
        "name": "Bisho Airport",
        "city": "Bisho",
        "country": "South Africa",
        "iata": "BIY",
        "icao": "FABE",
        "latitude": "-32.8970985413",
        "longitude": "27.279100418099997",
        "altitude": "1950",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "BFN": {
        "name": "Bram Fischer International Airport",
        "city": "Bloemfontein",
        "country": "South Africa",
        "iata": "BFN",
        "icao": "FABL",
        "latitude": "-29.092699050900002",
        "longitude": "26.302400589",
        "altitude": "4458",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "CPT": {
        "name": "Cape Town International Airport",
        "city": "Cape Town",
        "country": "South Africa",
        "iata": "CPT",
        "icao": "FACT",
        "latitude": "-33.9648017883",
        "longitude": "18.6016998291",
        "altitude": "151",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "DUR": {
        "name": "King Shaka International Airport",
        "city": "Durban",
        "country": "South Africa",
        "iata": "DUR",
        "icao": "FALE",
        "latitude": "-29.6144444444",
        "longitude": "31.1197222222",
        "altitude": "295",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "ELS": {
        "name": "Ben Schoeman Airport",
        "city": "East London",
        "country": "South Africa",
        "iata": "ELS",
        "icao": "FAEL",
        "latitude": "-33.0355987549",
        "longitude": "27.825899124099998",
        "altitude": "435",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "FCB": {
        "name": "Ficksburg Sentraoes Airport",
        "city": "Ficksburg",
        "country": "South Africa",
        "iata": "FCB",
        "icao": "FAFB",
        "latitude": "-28.82309913635254",
        "longitude": "27.908899307250977",
        "altitude": "5315",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "GCJ": {
        "name": "Grand Central Airport",
        "city": "Johannesburg",
        "country": "South Africa",
        "iata": "GCJ",
        "icao": "FAGC",
        "latitude": "-25.986299514799995",
        "longitude": "28.1401004791",
        "altitude": "5325",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "GRJ": {
        "name": "George Airport",
        "city": "George",
        "country": "South Africa",
        "iata": "GRJ",
        "icao": "FAGG",
        "latitude": "-34.0056",
        "longitude": "22.378902",
        "altitude": "648",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "HRS": {
        "name": "Harrismith Airport",
        "city": "Harrismith",
        "country": "South Africa",
        "iata": "HRS",
        "icao": "FAHR",
        "latitude": "-28.23509979248047",
        "longitude": "29.106199264526367",
        "altitude": "5585",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "HDS": {
        "name": "Hoedspruit Air Force Base Airport",
        "city": "Hoedspruit",
        "country": "South Africa",
        "iata": "HDS",
        "icao": "FAHS",
        "latitude": "-24.368600845299998",
        "longitude": "31.0487003326",
        "altitude": "1743",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "JNB": {
        "name": "OR Tambo International Airport",
        "city": "Johannesburg",
        "country": "South Africa",
        "iata": "JNB",
        "icao": "FAOR",
        "latitude": "-26.1392",
        "longitude": "28.246",
        "altitude": "5558",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "KXE": {
        "name": "P C Pelser Airport",
        "city": "Klerksdorp",
        "country": "South Africa",
        "iata": "KXE",
        "icao": "FAKD",
        "latitude": "-26.8710994720459",
        "longitude": "26.718000411987305",
        "altitude": "4444",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "KIM": {
        "name": "Kimberley Airport",
        "city": "Kimberley",
        "country": "South Africa",
        "iata": "KIM",
        "icao": "FAKM",
        "latitude": "-28.802799224900003",
        "longitude": "24.7651996613",
        "altitude": "3950",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "KMH": {
        "name": "Johan Pienaar Airport",
        "city": "Kuruman",
        "country": "South Africa",
        "iata": "KMH",
        "icao": "FAKU",
        "latitude": "-27.45669937133789",
        "longitude": "23.411399841308594",
        "altitude": "4382",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "KLZ": {
        "name": "Kleinsee Airport",
        "city": "Kleinsee",
        "country": "South Africa",
        "iata": "KLZ",
        "icao": "FAKZ",
        "latitude": "-29.6884002686",
        "longitude": "17.093999862700002",
        "altitude": "270",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "HLA": {
        "name": "Lanseria Airport",
        "city": "Johannesburg",
        "country": "South Africa",
        "iata": "HLA",
        "icao": "FALA",
        "latitude": "-25.938499450699997",
        "longitude": "27.9260997772",
        "altitude": "4517",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "SDB": {
        "name": "Langebaanweg Airport",
        "city": "Langebaanweg",
        "country": "South Africa",
        "iata": "SDB",
        "icao": "FALW",
        "latitude": "-32.968898773199996",
        "longitude": "18.1602993011",
        "altitude": "108",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "LAY": {
        "name": "Ladysmith Airport",
        "city": "Ladysmith",
        "country": "South Africa",
        "iata": "LAY",
        "icao": "FALY",
        "latitude": "-28.5816993713",
        "longitude": "29.749700546299998",
        "altitude": "3548",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "MGH": {
        "name": "Margate Airport",
        "city": "Margate",
        "country": "South Africa",
        "iata": "MGH",
        "icao": "FAMG",
        "latitude": "-30.8574008942",
        "longitude": "30.343000412",
        "altitude": "495",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "LLE": {
        "name": "Riverside Airport",
        "city": "Malalane",
        "country": "South Africa",
        "iata": "LLE",
        "icao": "FAMN",
        "latitude": "-25.4300003052",
        "longitude": "31.5767002106",
        "altitude": "1024",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "MZQ": {
        "name": "Mkuze Airport",
        "city": "Mkuze",
        "country": "South Africa",
        "iata": "MZQ",
        "icao": "FAMU",
        "latitude": "-27.626100540161133",
        "longitude": "32.0443000793457",
        "altitude": "400",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "NCS": {
        "name": "Newcastle Airport",
        "city": "Newcastle",
        "country": "South Africa",
        "iata": "NCS",
        "icao": "FANC",
        "latitude": "-27.7705993652",
        "longitude": "29.976900100699996",
        "altitude": "4074",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "OVG": {
        "name": "Overberg Airport",
        "city": "Overberg",
        "country": "South Africa",
        "iata": "OVG",
        "icao": "FAOB",
        "latitude": "-34.554901123",
        "longitude": "20.250699996900003",
        "altitude": "52",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "OUH": {
        "name": "Oudtshoorn Airport",
        "city": "Oudtshoorn",
        "country": "South Africa",
        "iata": "OUH",
        "icao": "FAOH",
        "latitude": "-33.6069984436",
        "longitude": "22.188999176",
        "altitude": "1063",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PLZ": {
        "name": "Port Elizabeth Airport",
        "city": "Port Elizabeth",
        "country": "South Africa",
        "iata": "PLZ",
        "icao": "FAPE",
        "latitude": "-33.9849014282",
        "longitude": "25.6173000336",
        "altitude": "226",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PBZ": {
        "name": "Plettenberg Bay Airport",
        "city": "Plettenberg Bay",
        "country": "South Africa",
        "iata": "PBZ",
        "icao": "FAPG",
        "latitude": "-34.0881601675",
        "longitude": "23.3287234306",
        "altitude": "465",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PHW": {
        "name": "Hendrik Van Eck Airport",
        "city": "Phalaborwa",
        "country": "South Africa",
        "iata": "PHW",
        "icao": "FAPH",
        "latitude": "-23.937200546299998",
        "longitude": "31.1553993225",
        "altitude": "1432",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "JOH": {
        "name": "Port St Johns Airport",
        "city": "Port Saint Johns",
        "country": "South Africa",
        "iata": "JOH",
        "icao": "FAPJ",
        "latitude": "-31.605899810791016",
        "longitude": "29.519800186157227",
        "altitude": "1227",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PZB": {
        "name": "Pietermaritzburg Airport",
        "city": "Pietermaritzburg",
        "country": "South Africa",
        "iata": "PZB",
        "icao": "FAPM",
        "latitude": "-29.649000167799997",
        "longitude": "30.3987007141",
        "altitude": "2423",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "NTY": {
        "name": "Pilanesberg International Airport",
        "city": "Pilanesberg",
        "country": "South Africa",
        "iata": "NTY",
        "icao": "FAPN",
        "latitude": "-25.333799362199997",
        "longitude": "27.173400878900004",
        "altitude": "3412",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PTG": {
        "name": "Polokwane International Airport",
        "city": "Potgietersrus",
        "country": "South Africa",
        "iata": "PTG",
        "icao": "FAPP",
        "latitude": "-23.845269",
        "longitude": "29.458615",
        "altitude": "4076",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PCF": {
        "name": "Potchefstroom Airport",
        "city": "Potchefstroom",
        "country": "South Africa",
        "iata": "PCF",
        "icao": "FAPS",
        "latitude": "-26.670999527",
        "longitude": "27.0818996429",
        "altitude": "4520",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "UTW": {
        "name": "Queenstown Airport",
        "city": "Queenstown",
        "country": "South Africa",
        "iata": "UTW",
        "icao": "FAQT",
        "latitude": "-31.92020034790039",
        "longitude": "26.882200241088867",
        "altitude": "3637",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "RCB": {
        "name": "Richards Bay Airport",
        "city": "Richard's Bay",
        "country": "South Africa",
        "iata": "RCB",
        "icao": "FARB",
        "latitude": "-28.740999221800003",
        "longitude": "32.0920982361",
        "altitude": "109",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "ROD": {
        "name": "Robertson Airport",
        "city": "Robertson",
        "country": "South Africa",
        "iata": "ROD",
        "icao": "FARS",
        "latitude": "-33.812198638916016",
        "longitude": "19.902799606323242",
        "altitude": "640",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "SBU": {
        "name": "Springbok Airport",
        "city": "Springbok",
        "country": "South Africa",
        "iata": "SBU",
        "icao": "FASB",
        "latitude": "-29.689300537109375",
        "longitude": "17.939599990844727",
        "altitude": "2690",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "ZEC": {
        "name": "Secunda Airport",
        "city": "Secunda",
        "country": "South Africa",
        "iata": "ZEC",
        "icao": "FASC",
        "latitude": "-26.52409935",
        "longitude": "29.170099258399997",
        "altitude": "5250",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "SIS": {
        "name": "Sishen Airport",
        "city": "Sishen",
        "country": "South Africa",
        "iata": "SIS",
        "icao": "FASS",
        "latitude": "-27.6485996246",
        "longitude": "22.9993000031",
        "altitude": "3848",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "SZK": {
        "name": "Skukuza Airport",
        "city": "Skukuza",
        "country": "South Africa",
        "iata": "SZK",
        "icao": "FASZ",
        "latitude": "-24.960899353",
        "longitude": "31.5886993408",
        "altitude": "1020",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "LTA": {
        "name": "Tzaneen Airport",
        "city": "Tzaneen",
        "country": "South Africa",
        "iata": "LTA",
        "icao": "FATZ",
        "latitude": "-23.8243999481",
        "longitude": "30.329299926799997",
        "altitude": "1914",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "ULD": {
        "name": "Prince Mangosuthu Buthelezi Airport",
        "city": "Ulundi",
        "country": "South Africa",
        "iata": "ULD",
        "icao": "FAUL",
        "latitude": "-28.3206005096",
        "longitude": "31.4165000916",
        "altitude": "1720",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "UTN": {
        "name": "Pierre Van Ryneveld Airport",
        "city": "Upington",
        "country": "South Africa",
        "iata": "UTN",
        "icao": "FAUP",
        "latitude": "-28.39909935",
        "longitude": "21.260200500499998",
        "altitude": "2782",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "UTT": {
        "name": "K. D. Matanzima Airport",
        "city": "Umtata",
        "country": "South Africa",
        "iata": "UTT",
        "icao": "FAUT",
        "latitude": "-31.546363184900002",
        "longitude": "28.6733551025",
        "altitude": "2400",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "VRU": {
        "name": "Vryburg Airport",
        "city": "Vryburg",
        "country": "South Africa",
        "iata": "VRU",
        "icao": "FAVB",
        "latitude": "-26.9824008942",
        "longitude": "24.7287998199",
        "altitude": "3920",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "VIR": {
        "name": "Virginia Airport",
        "city": "Durban",
        "country": "South Africa",
        "iata": "VIR",
        "icao": "FAVG",
        "latitude": "-29.770599365234375",
        "longitude": "31.058399200439453",
        "altitude": "20",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "VRE": {
        "name": "Vredendal Airport",
        "city": "Vredendal",
        "country": "South Africa",
        "iata": "VRE",
        "icao": "FAVR",
        "latitude": "-31.641000747680664",
        "longitude": "18.5447998046875",
        "altitude": "330",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "PRY": {
        "name": "Wonderboom Airport",
        "city": "Pretoria",
        "country": "South Africa",
        "iata": "PRY",
        "icao": "FAWB",
        "latitude": "-25.6539",
        "longitude": "28.224199",
        "altitude": "4095",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "WKF": {
        "name": "Waterkloof Air Force Base",
        "city": "Waterkloof",
        "country": "South Africa",
        "iata": "WKF",
        "icao": "FAWK",
        "latitude": "-25.829999923699997",
        "longitude": "28.222499847399998",
        "altitude": "4940",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "FRW": {
        "name": "Francistown Airport",
        "city": "Francistown",
        "country": "Botswana",
        "iata": "FRW",
        "icao": "FBFT",
        "latitude": "-21.15959930419922",
        "longitude": "27.47450065612793",
        "altitude": "3283",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "JWA": {
        "name": "Jwaneng Airport",
        "city": "Jwaneng",
        "country": "Botswana",
        "iata": "JWA",
        "icao": "FBJW",
        "latitude": "-24.6023006439209",
        "longitude": "24.69099998474121",
        "altitude": "3900",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "BBK": {
        "name": "Kasane Airport",
        "city": "Kasane",
        "country": "Botswana",
        "iata": "BBK",
        "icao": "FBKE",
        "latitude": "-17.83289909362793",
        "longitude": "25.162399291992188",
        "altitude": "3289",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "MUB": {
        "name": "Maun Airport",
        "city": "Maun",
        "country": "Botswana",
        "iata": "MUB",
        "icao": "FBMN",
        "latitude": "-19.97260093688965",
        "longitude": "23.431100845336914",
        "altitude": "3093",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "GBE": {
        "name": "Sir Seretse Khama International Airport",
        "city": "Gaberone",
        "country": "Botswana",
        "iata": "GBE",
        "icao": "FBSK",
        "latitude": "-24.555201",
        "longitude": "25.9182",
        "altitude": "3299",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "PKW": {
        "name": "Selebi Phikwe Airport",
        "city": "Selebi-phikwe",
        "country": "Botswana",
        "iata": "PKW",
        "icao": "FBSP",
        "latitude": "-22.0583",
        "longitude": "27.8288",
        "altitude": "2925",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "BZV": {
        "name": "Maya-Maya Airport",
        "city": "Brazzaville",
        "country": "Congo (Brazzaville)",
        "iata": "BZV",
        "icao": "FCBB",
        "latitude": "-4.251699924468994",
        "longitude": "15.253000259399414",
        "altitude": "1048",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Brazzaville"
    },
    "FTX": {
        "name": "Owando Airport",
        "city": "Owando",
        "country": "Congo (Kinshasa)",
        "iata": "FTX",
        "icao": "FCOO",
        "latitude": "-0.5313500165939331",
        "longitude": "15.95009994506836",
        "altitude": "1214",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Brazzaville"
    },
    "OUE": {
        "name": "Ouesso Airport",
        "city": "Ouesso",
        "country": "Congo (Kinshasa)",
        "iata": "OUE",
        "icao": "FCOU",
        "latitude": "1.6159900426899998",
        "longitude": "16.0379009247",
        "altitude": "1158",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Brazzaville"
    },
    "PNR": {
        "name": "Pointe Noire Airport",
        "city": "Pointe-noire",
        "country": "Congo (Brazzaville)",
        "iata": "PNR",
        "icao": "FCPP",
        "latitude": "-4.816030025482178",
        "longitude": "11.88659954071045",
        "altitude": "55",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Brazzaville"
    },
    "MTS": {
        "name": "Matsapha Airport",
        "city": "Manzini",
        "country": "Swaziland",
        "iata": "MTS",
        "icao": "FDMS",
        "latitude": "-26.52899932861328",
        "longitude": "31.3075008392334",
        "altitude": "2075",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Mbabane"
    },
    "BGF": {
        "name": "Bangui M'Poko International Airport",
        "city": "Bangui",
        "country": "Central African Republic",
        "iata": "BGF",
        "icao": "FEFF",
        "latitude": "4.39847993850708",
        "longitude": "18.518800735473633",
        "altitude": "1208",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Bangui"
    },
    "BBT": {
        "name": "Berbérati Airport",
        "city": "Berberati",
        "country": "Central African Republic",
        "iata": "BBT",
        "icao": "FEFT",
        "latitude": "4.2215800285339355",
        "longitude": "15.786399841308594",
        "altitude": "1929",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Bangui"
    },
    "BSG": {
        "name": "Bata Airport",
        "city": "Bata",
        "country": "Equatorial Guinea",
        "iata": "BSG",
        "icao": "FGBT",
        "latitude": "1.9054700136184692",
        "longitude": "9.805680274963379",
        "altitude": "13",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Malabo"
    },
    "SSG": {
        "name": "Malabo Airport",
        "city": "Malabo",
        "country": "Equatorial Guinea",
        "iata": "SSG",
        "icao": "FGSL",
        "latitude": "3.755270004272461",
        "longitude": "8.708720207214355",
        "altitude": "76",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Malabo"
    },
    "ASI": {
        "name": "RAF Ascension Island",
        "city": "Wide Awake",
        "country": "Saint Helena",
        "iata": "ASI",
        "icao": "FHAW",
        "latitude": "-7.9696",
        "longitude": "-14.3937",
        "altitude": "278",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/St_Helena"
    },
    "MRU": {
        "name": "Sir Seewoosagur Ramgoolam International Airport",
        "city": "Plaisance",
        "country": "Mauritius",
        "iata": "MRU",
        "icao": "FIMP",
        "latitude": "-20.430201",
        "longitude": "57.683601",
        "altitude": "186",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Indian/Mauritius"
    },
    "RRG": {
        "name": "Sir Charles Gaetan Duval Airport",
        "city": "Rodriguez Island",
        "country": "Mauritius",
        "iata": "RRG",
        "icao": "FIMR",
        "latitude": "-19.7577",
        "longitude": "63.361",
        "altitude": "95",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Indian/Mauritius"
    },
    "NKW": {
        "name": "Diego Garcia Naval Support Facility",
        "city": "Diego Garcia Island",
        "country": "British Indian Ocean Territory",
        "iata": "NKW",
        "icao": "FJDG",
        "latitude": "-7.31327",
        "longitude": "72.411102",
        "altitude": "9",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Indian/Chagos"
    },
    "TKC": {
        "name": "Tiko Airport",
        "city": "Tiko",
        "country": "Cameroon",
        "iata": "TKC",
        "icao": "FKKC",
        "latitude": "4.08919000626",
        "longitude": "9.360529899600001",
        "altitude": "151",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "DLA": {
        "name": "Douala International Airport",
        "city": "Douala",
        "country": "Cameroon",
        "iata": "DLA",
        "icao": "FKKD",
        "latitude": "4.0060801506",
        "longitude": "9.719479560849999",
        "altitude": "33",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "MVR": {
        "name": "Salak Airport",
        "city": "Maroua",
        "country": "Cameroon",
        "iata": "MVR",
        "icao": "FKKL",
        "latitude": "10.451399803161621",
        "longitude": "14.257399559020996",
        "altitude": "1390",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "FOM": {
        "name": "Foumban Nkounja Airport",
        "city": "Foumban",
        "country": "Cameroon",
        "iata": "FOM",
        "icao": "FKKM",
        "latitude": "5.636919975280762",
        "longitude": "10.750800132751465",
        "altitude": "3963",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "NGE": {
        "name": "N'Gaoundéré Airport",
        "city": "N'gaoundere",
        "country": "Cameroon",
        "iata": "NGE",
        "icao": "FKKN",
        "latitude": "7.3570098876953125",
        "longitude": "13.559200286865234",
        "altitude": "3655",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "GOU": {
        "name": "Garoua International Airport",
        "city": "Garoua",
        "country": "Cameroon",
        "iata": "GOU",
        "icao": "FKKR",
        "latitude": "9.33588981628418",
        "longitude": "13.370100021362305",
        "altitude": "794",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "BFX": {
        "name": "Bafoussam Airport",
        "city": "Bafoussam",
        "country": "Cameroon",
        "iata": "BFX",
        "icao": "FKKU",
        "latitude": "5.536920070650001",
        "longitude": "10.354599952700001",
        "altitude": "4347",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "BPC": {
        "name": "Bamenda Airport",
        "city": "Bamenda",
        "country": "Cameroon",
        "iata": "BPC",
        "icao": "FKKV",
        "latitude": "6.039239883422852",
        "longitude": "10.122599601745605",
        "altitude": "4065",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "YAO": {
        "name": "Yaoundé Airport",
        "city": "Yaounde",
        "country": "Cameroon",
        "iata": "YAO",
        "icao": "FKKY",
        "latitude": "3.8360400199890137",
        "longitude": "11.523500442504883",
        "altitude": "2464",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "CGJ": {
        "name": "Kasompe Airport",
        "city": "Kasompe",
        "country": "Zambia",
        "iata": "CGJ",
        "icao": "FLKE",
        "latitude": "-12.572799682617",
        "longitude": "27.893899917603",
        "altitude": "4636",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "LVI": {
        "name": "Livingstone Airport",
        "city": "Livingstone",
        "country": "Zambia",
        "iata": "LVI",
        "icao": "FLLI",
        "latitude": "-17.821800231933594",
        "longitude": "25.82270050048828",
        "altitude": "3302",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "LUN": {
        "name": "Kenneth Kaunda International Airport Lusaka",
        "city": "Lusaka",
        "country": "Zambia",
        "iata": "LUN",
        "icao": "FLLS",
        "latitude": "-15.3308000565",
        "longitude": "28.4526004791",
        "altitude": "3779",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "MFU": {
        "name": "Mfuwe Airport",
        "city": "Mfuwe",
        "country": "Zambia",
        "iata": "MFU",
        "icao": "FLMF",
        "latitude": "-13.258899688720703",
        "longitude": "31.936599731445312",
        "altitude": "1853",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "MNR": {
        "name": "Mongu Airport",
        "city": "Mongu",
        "country": "Zambia",
        "iata": "MNR",
        "icao": "FLMG",
        "latitude": "-15.254500389099121",
        "longitude": "23.16230010986328",
        "altitude": "3488",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "NLA": {
        "name": "Simon Mwansa Kapwepwe International Airport",
        "city": "Ndola",
        "country": "Zambia",
        "iata": "NLA",
        "icao": "FLND",
        "latitude": "-12.998100280762",
        "longitude": "28.66489982605",
        "altitude": "4167",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "KIW": {
        "name": "Southdowns Airport",
        "city": "Southdowns",
        "country": "Zambia",
        "iata": "KIW",
        "icao": "FLSO",
        "latitude": "-12.900500297546387",
        "longitude": "28.149900436401367",
        "altitude": "4145",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "HAH": {
        "name": "Prince Said Ibrahim International Airport",
        "city": "Moroni",
        "country": "Comoros",
        "iata": "HAH",
        "icao": "FMCH",
        "latitude": "-11.5337",
        "longitude": "43.2719",
        "altitude": "93",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Comoro"
    },
    "NWA": {
        "name": "Mohéli Bandar Es Eslam Airport",
        "city": "Moheli",
        "country": "Comoros",
        "iata": "NWA",
        "icao": "FMCI",
        "latitude": "-12.298100471496582",
        "longitude": "43.76639938354492",
        "altitude": "46",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Comoro"
    },
    "AJN": {
        "name": "Ouani Airport",
        "city": "Anjouan",
        "country": "Comoros",
        "iata": "AJN",
        "icao": "FMCV",
        "latitude": "-12.131699562072754",
        "longitude": "44.430301666259766",
        "altitude": "62",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Comoro"
    },
    "DZA": {
        "name": "Dzaoudzi Pamandzi International Airport",
        "city": "Dzaoudzi",
        "country": "Mayotte",
        "iata": "DZA",
        "icao": "FMCZ",
        "latitude": "-12.804699897766113",
        "longitude": "45.28110122680664",
        "altitude": "23",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Mayotte"
    },
    "RUN": {
        "name": "Roland Garros Airport",
        "city": "St.-denis",
        "country": "Reunion",
        "iata": "RUN",
        "icao": "FMEE",
        "latitude": "-20.887100219726562",
        "longitude": "55.51029968261719",
        "altitude": "66",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Indian/Reunion"
    },
    "ZSE": {
        "name": "Pierrefonds Airport",
        "city": "St.-pierre",
        "country": "Reunion",
        "iata": "ZSE",
        "icao": "FMEP",
        "latitude": "-21.320899963378906",
        "longitude": "55.42499923706055",
        "altitude": "59",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Indian/Reunion"
    },
    "TNR": {
        "name": "Ivato Airport",
        "city": "Antananarivo",
        "country": "Madagascar",
        "iata": "TNR",
        "icao": "FMMI",
        "latitude": "-18.7969",
        "longitude": "47.478802",
        "altitude": "4198",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "ZVA": {
        "name": "Miandrivazo Airport",
        "city": "Miandrivazo",
        "country": "Madagascar",
        "iata": "ZVA",
        "icao": "FMMN",
        "latitude": "-19.56279945373535",
        "longitude": "45.450801849365234",
        "altitude": "203",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "SMS": {
        "name": "Sainte Marie Airport",
        "city": "Sainte Marie",
        "country": "Madagascar",
        "iata": "SMS",
        "icao": "FMMS",
        "latitude": "-17.093900680541992",
        "longitude": "49.815799713134766",
        "altitude": "7",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "TMM": {
        "name": "Toamasina Airport",
        "city": "Toamasina",
        "country": "Madagascar",
        "iata": "TMM",
        "icao": "FMMT",
        "latitude": "-18.109500885009766",
        "longitude": "49.39250183105469",
        "altitude": "22",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "MOQ": {
        "name": "Morondava Airport",
        "city": "Morondava",
        "country": "Madagascar",
        "iata": "MOQ",
        "icao": "FMMV",
        "latitude": "-20.284700393676758",
        "longitude": "44.31760025024414",
        "altitude": "30",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "DIE": {
        "name": "Arrachart Airport",
        "city": "Antsiranana",
        "country": "Madagascar",
        "iata": "DIE",
        "icao": "FMNA",
        "latitude": "-12.34939956665039",
        "longitude": "49.29169845581055",
        "altitude": "374",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WMR": {
        "name": "Mananara Nord Airport",
        "city": "Mananara",
        "country": "Madagascar",
        "iata": "WMR",
        "icao": "FMNC",
        "latitude": "-16.16390037536621",
        "longitude": "49.773799896240234",
        "altitude": "9",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "ZWA": {
        "name": "Andapa Airport",
        "city": "Andapa",
        "country": "Madagascar",
        "iata": "ZWA",
        "icao": "FMND",
        "latitude": "-14.651700019836426",
        "longitude": "49.620601654052734",
        "altitude": "1552",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "AMB": {
        "name": "Ambilobe Airport",
        "city": "Ambilobe",
        "country": "Madagascar",
        "iata": "AMB",
        "icao": "FMNE",
        "latitude": "-13.188400268554688",
        "longitude": "48.987998962402344",
        "altitude": "72",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "ANM": {
        "name": "Antsirabato Airport",
        "city": "Antalaha",
        "country": "Madagascar",
        "iata": "ANM",
        "icao": "FMNH",
        "latitude": "-14.99940013885498",
        "longitude": "50.3202018737793",
        "altitude": "20",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "HVA": {
        "name": "Analalava Airport",
        "city": "Analalava",
        "country": "Madagascar",
        "iata": "HVA",
        "icao": "FMNL",
        "latitude": "-14.62969970703125",
        "longitude": "47.76380157470703",
        "altitude": "345",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "MJN": {
        "name": "Amborovy Airport",
        "city": "Mahajanga",
        "country": "Madagascar",
        "iata": "MJN",
        "icao": "FMNM",
        "latitude": "-15.6668417421",
        "longitude": "46.351232528699995",
        "altitude": "87",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "NOS": {
        "name": "Fascene Airport",
        "city": "Nosy-be",
        "country": "Madagascar",
        "iata": "NOS",
        "icao": "FMNN",
        "latitude": "-13.3121004105",
        "longitude": "48.3148002625",
        "altitude": "36",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "BPY": {
        "name": "Besalampy Airport",
        "city": "Besalampy",
        "country": "Madagascar",
        "iata": "BPY",
        "icao": "FMNQ",
        "latitude": "-16.744530296500002",
        "longitude": "44.4824838638",
        "altitude": "125",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WMN": {
        "name": "Maroantsetra Airport",
        "city": "Maroantsetra",
        "country": "Madagascar",
        "iata": "WMN",
        "icao": "FMNR",
        "latitude": "-15.436699867248535",
        "longitude": "49.68830108642578",
        "altitude": "13",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "SVB": {
        "name": "Sambava Airport",
        "city": "Sambava",
        "country": "Madagascar",
        "iata": "SVB",
        "icao": "FMNS",
        "latitude": "-14.278599739074707",
        "longitude": "50.17470169067383",
        "altitude": "20",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "VOH": {
        "name": "Vohimarina Airport",
        "city": "Vohemar",
        "country": "Madagascar",
        "iata": "VOH",
        "icao": "FMNV",
        "latitude": "-13.375800132751465",
        "longitude": "50.00279998779297",
        "altitude": "19",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WAI": {
        "name": "Ambalabe Airport",
        "city": "Antsohihy",
        "country": "Madagascar",
        "iata": "WAI",
        "icao": "FMNW",
        "latitude": "-14.898799896240234",
        "longitude": "47.993900299072266",
        "altitude": "92",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "IVA": {
        "name": "Ampampamena Airport",
        "city": "Ampampamena",
        "country": "Madagascar",
        "iata": "IVA",
        "icao": "FMNZ",
        "latitude": "-13.484816",
        "longitude": "48.632702",
        "altitude": "49",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "FTU": {
        "name": "Tôlanaro Airport",
        "city": "Tolagnaro",
        "country": "Madagascar",
        "iata": "FTU",
        "icao": "FMSD",
        "latitude": "-25.03809928894043",
        "longitude": "46.95610046386719",
        "altitude": "29",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WFI": {
        "name": "Fianarantsoa Airport",
        "city": "Fianarantsoa",
        "country": "Madagascar",
        "iata": "WFI",
        "icao": "FMSF",
        "latitude": "-21.441600799560547",
        "longitude": "47.111698150634766",
        "altitude": "3658",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "RVA": {
        "name": "Farafangana Airport",
        "city": "Farafangana",
        "country": "Madagascar",
        "iata": "RVA",
        "icao": "FMSG",
        "latitude": "-22.805299758911133",
        "longitude": "47.82059860229492",
        "altitude": "26",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WVK": {
        "name": "Manakara Airport",
        "city": "Manakara",
        "country": "Madagascar",
        "iata": "WVK",
        "icao": "FMSK",
        "latitude": "-22.119699478149414",
        "longitude": "48.02170181274414",
        "altitude": "33",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "MNJ": {
        "name": "Mananjary Airport",
        "city": "Mananjary",
        "country": "Madagascar",
        "iata": "MNJ",
        "icao": "FMSM",
        "latitude": "-21.201799392700195",
        "longitude": "48.358299255371094",
        "altitude": "20",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "MXM": {
        "name": "Morombe Airport",
        "city": "Morombe",
        "country": "Madagascar",
        "iata": "MXM",
        "icao": "FMSR",
        "latitude": "-21.7539005279541",
        "longitude": "43.3754997253418",
        "altitude": "16",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "TLE": {
        "name": "Toliara Airport",
        "city": "Toliara",
        "country": "Madagascar",
        "iata": "TLE",
        "icao": "FMST",
        "latitude": "-23.383399963378906",
        "longitude": "43.72850036621094",
        "altitude": "29",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "SSY": {
        "name": "Mbanza Congo Airport",
        "city": "M'banza-congo",
        "country": "Angola",
        "iata": "SSY",
        "icao": "FNBC",
        "latitude": "-6.269899845123291",
        "longitude": "14.246999740600586",
        "altitude": "1860",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "BUG": {
        "name": "Benguela Airport",
        "city": "Benguela",
        "country": "Angola",
        "iata": "BUG",
        "icao": "FNBG",
        "latitude": "-12.609000206",
        "longitude": "13.4036998749",
        "altitude": "118",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "CAB": {
        "name": "Cabinda Airport",
        "city": "Cabinda",
        "country": "Angola",
        "iata": "CAB",
        "icao": "FNCA",
        "latitude": "-5.59699010848999",
        "longitude": "12.188400268554688",
        "altitude": "66",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "NOV": {
        "name": "Nova Lisboa Airport",
        "city": "Huambo",
        "country": "Angola",
        "iata": "NOV",
        "icao": "FNHU",
        "latitude": "-12.808899879455566",
        "longitude": "15.760499954223633",
        "altitude": "5587",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "SVP": {
        "name": "Kuito Airport",
        "city": "Kuito",
        "country": "Angola",
        "iata": "SVP",
        "icao": "FNKU",
        "latitude": "-12.404600143433",
        "longitude": "16.947399139404",
        "altitude": "5618",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "LAD": {
        "name": "Quatro de Fevereiro Airport",
        "city": "Luanda",
        "country": "Angola",
        "iata": "LAD",
        "icao": "FNLU",
        "latitude": "-8.85837",
        "longitude": "13.2312",
        "altitude": "243",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "MEG": {
        "name": "Malanje Airport",
        "city": "Malanje",
        "country": "Angola",
        "iata": "MEG",
        "icao": "FNMA",
        "latitude": "-9.525090217590332",
        "longitude": "16.312400817871094",
        "altitude": "3868",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "SPP": {
        "name": "Menongue Airport",
        "city": "Menongue",
        "country": "Angola",
        "iata": "SPP",
        "icao": "FNME",
        "latitude": "-14.657600402832031",
        "longitude": "17.71980094909668",
        "altitude": "4469",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "GXG": {
        "name": "Negage Airport",
        "city": "Negage",
        "country": "Angola",
        "iata": "GXG",
        "icao": "FNNG",
        "latitude": "-7.754509925842285",
        "longitude": "15.287699699401855",
        "altitude": "4105",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "PBN": {
        "name": "Porto Amboim Airport",
        "city": "Porto Amboim",
        "country": "Angola",
        "iata": "PBN",
        "icao": "FNPA",
        "latitude": "-10.722000122070312",
        "longitude": "13.76550006866455",
        "altitude": "16",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "VHC": {
        "name": "Saurimo Airport",
        "city": "Saurimo",
        "country": "Angola",
        "iata": "VHC",
        "icao": "FNSA",
        "latitude": "-9.689069747924805",
        "longitude": "20.431900024414062",
        "altitude": "3584",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "SZA": {
        "name": "Soyo Airport",
        "city": "Soyo",
        "country": "Angola",
        "iata": "SZA",
        "icao": "FNSO",
        "latitude": "-6.141089916229248",
        "longitude": "12.371800422668457",
        "altitude": "15",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "SDD": {
        "name": "Lubango Airport",
        "city": "Lubango",
        "country": "Angola",
        "iata": "SDD",
        "icao": "FNUB",
        "latitude": "-14.924699783325195",
        "longitude": "13.574999809265137",
        "altitude": "5778",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "LUO": {
        "name": "Luena Airport",
        "city": "Luena",
        "country": "Angola",
        "iata": "LUO",
        "icao": "FNUE",
        "latitude": "-11.768099784851074",
        "longitude": "19.8976993560791",
        "altitude": "4360",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "UGO": {
        "name": "Uige Airport",
        "city": "Uige",
        "country": "Angola",
        "iata": "UGO",
        "icao": "FNUG",
        "latitude": "-7.60306978225708",
        "longitude": "15.027799606323242",
        "altitude": "2720",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "XGN": {
        "name": "Xangongo Airport",
        "city": "Xangongo",
        "country": "Angola",
        "iata": "XGN",
        "icao": "FNXA",
        "latitude": "-16.755399703979492",
        "longitude": "14.965299606323242",
        "altitude": "3635",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "OYE": {
        "name": "Oyem Airport",
        "city": "Oyem",
        "country": "Gabon",
        "iata": "OYE",
        "icao": "FOGO",
        "latitude": "1.5431100130081177",
        "longitude": "11.581399917602539",
        "altitude": "2158",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "OKN": {
        "name": "Okondja Airport",
        "city": "Okondja",
        "country": "Gabon",
        "iata": "OKN",
        "icao": "FOGQ",
        "latitude": "-0.6652140021324158",
        "longitude": "13.673100471496582",
        "altitude": "1325",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "LBQ": {
        "name": "Lambarene Airport",
        "city": "Lambarene",
        "country": "Gabon",
        "iata": "LBQ",
        "icao": "FOGR",
        "latitude": "-0.7043889760971069",
        "longitude": "10.245699882507324",
        "altitude": "82",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "BMM": {
        "name": "Bitam Airport",
        "city": "Bitam",
        "country": "Gabon",
        "iata": "BMM",
        "icao": "FOOB",
        "latitude": "2.0756399631500244",
        "longitude": "11.493200302124023",
        "altitude": "1969",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "POG": {
        "name": "Port Gentil Airport",
        "city": "Port Gentil",
        "country": "Gabon",
        "iata": "POG",
        "icao": "FOOG",
        "latitude": "-0.7117390036582947",
        "longitude": "8.754380226135254",
        "altitude": "13",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "OMB": {
        "name": "Omboue Hopital Airport",
        "city": "Omboue Hospial",
        "country": "Gabon",
        "iata": "OMB",
        "icao": "FOOH",
        "latitude": "-1.5747300386428833",
        "longitude": "9.262689590454102",
        "altitude": "33",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "MKU": {
        "name": "Makokou Airport",
        "city": "Makokou",
        "country": "Gabon",
        "iata": "MKU",
        "icao": "FOOK",
        "latitude": "0.5792109966278076",
        "longitude": "12.890899658203125",
        "altitude": "1726",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "LBV": {
        "name": "Libreville Leon M'ba International Airport",
        "city": "Libreville",
        "country": "Gabon",
        "iata": "LBV",
        "icao": "FOOL",
        "latitude": "0.458600014448",
        "longitude": "9.412280082699999",
        "altitude": "39",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "MVB": {
        "name": "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
        "city": "Franceville",
        "country": "Gabon",
        "iata": "MVB",
        "icao": "FOON",
        "latitude": "-1.6561599969863892",
        "longitude": "13.437999725341797",
        "altitude": "1450",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "PCP": {
        "name": "Principe Airport",
        "city": "Principe",
        "country": "Sao Tome and Principe",
        "iata": "PCP",
        "icao": "FPPR",
        "latitude": "1.6629400253295898",
        "longitude": "7.411739826202393",
        "altitude": "591",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Sao_Tome"
    },
    "TMS": {
        "name": "São Tomé International Airport",
        "city": "Sao Tome",
        "country": "Sao Tome and Principe",
        "iata": "TMS",
        "icao": "FPST",
        "latitude": "0.3781749904155731",
        "longitude": "6.7121500968933105",
        "altitude": "33",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Sao_Tome"
    },
    "BEW": {
        "name": "Beira Airport",
        "city": "Beira",
        "country": "Mozambique",
        "iata": "BEW",
        "icao": "FQBR",
        "latitude": "-19.79640007019043",
        "longitude": "34.90760040283203",
        "altitude": "33",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "INH": {
        "name": "Inhambane Airport",
        "city": "Inhambane",
        "country": "Mozambique",
        "iata": "INH",
        "icao": "FQIN",
        "latitude": "-23.876399993896484",
        "longitude": "35.40850067138672",
        "altitude": "30",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "VXC": {
        "name": "Lichinga Airport",
        "city": "Lichinga",
        "country": "Mozambique",
        "iata": "VXC",
        "icao": "FQLC",
        "latitude": "-13.274",
        "longitude": "35.2663",
        "altitude": "4505",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "LFB": {
        "name": "Lumbo Airport",
        "city": "Lumbo",
        "country": "Mozambique",
        "iata": "LFB",
        "icao": "FQLU",
        "latitude": "-15.0331001282",
        "longitude": "40.671699523899996",
        "altitude": "33",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "MPM": {
        "name": "Maputo Airport",
        "city": "Maputo",
        "country": "Mozambique",
        "iata": "MPM",
        "icao": "FQMA",
        "latitude": "-25.920799",
        "longitude": "32.572601",
        "altitude": "145",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "MUD": {
        "name": "Mueda Airport",
        "city": "Mueda",
        "country": "Mozambique",
        "iata": "MUD",
        "icao": "FQMD",
        "latitude": "-11.672900199890137",
        "longitude": "39.5630989074707",
        "altitude": "2789",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "MZB": {
        "name": "Mocímboa da Praia Airport",
        "city": "Mocimboa Da Praia",
        "country": "Mozambique",
        "iata": "MZB",
        "icao": "FQMP",
        "latitude": "-11.361800193786621",
        "longitude": "40.35490036010742",
        "altitude": "89",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "MNC": {
        "name": "Nacala Airport",
        "city": "Nacala",
        "country": "Mozambique",
        "iata": "MNC",
        "icao": "FQNC",
        "latitude": "-14.488200187683105",
        "longitude": "40.71220016479492",
        "altitude": "410",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "APL": {
        "name": "Nampula Airport",
        "city": "Nampula",
        "country": "Mozambique",
        "iata": "APL",
        "icao": "FQNP",
        "latitude": "-15.105600357055664",
        "longitude": "39.28179931640625",
        "altitude": "1444",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "POL": {
        "name": "Pemba Airport",
        "city": "Pemba",
        "country": "Mozambique",
        "iata": "POL",
        "icao": "FQPB",
        "latitude": "-12.991762161254883",
        "longitude": "40.52401351928711",
        "altitude": "331",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "UEL": {
        "name": "Quelimane Airport",
        "city": "Quelimane",
        "country": "Mozambique",
        "iata": "UEL",
        "icao": "FQQL",
        "latitude": "-17.855499267578125",
        "longitude": "36.86909866333008",
        "altitude": "36",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "TET": {
        "name": "Chingozi Airport",
        "city": "Tete",
        "country": "Mozambique",
        "iata": "TET",
        "icao": "FQTT",
        "latitude": "-16.104799270629883",
        "longitude": "33.640201568603516",
        "altitude": "525",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "VNX": {
        "name": "Vilankulo Airport",
        "city": "Vilankulu",
        "country": "Mozambique",
        "iata": "VNX",
        "icao": "FQVL",
        "latitude": "-22.018400192260742",
        "longitude": "35.31330108642578",
        "altitude": "46",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "DES": {
        "name": "Desroches Airport",
        "city": "Desroches",
        "country": "Seychelles",
        "iata": "DES",
        "icao": "FSDR",
        "latitude": "-5.6967",
        "longitude": "53.6558",
        "altitude": "10",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Indian/Mahe"
    },
    "SEZ": {
        "name": "Seychelles International Airport",
        "city": "Mahe",
        "country": "Seychelles",
        "iata": "SEZ",
        "icao": "FSIA",
        "latitude": "-4.67434",
        "longitude": "55.521801",
        "altitude": "10",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Indian/Mahe"
    },
    "PRI": {
        "name": "Praslin Airport",
        "city": "Praslin",
        "country": "Seychelles",
        "iata": "PRI",
        "icao": "FSPP",
        "latitude": "-4.3192901611328125",
        "longitude": "55.69139862060547",
        "altitude": "10",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Indian/Mahe"
    },
    "AEH": {
        "name": "Abeche Airport",
        "city": "Abeche",
        "country": "Chad",
        "iata": "AEH",
        "icao": "FTTC",
        "latitude": "13.847000122070312",
        "longitude": "20.84429931640625",
        "altitude": "1788",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Ndjamena"
    },
    "MQQ": {
        "name": "Moundou Airport",
        "city": "Moundou",
        "country": "Chad",
        "iata": "MQQ",
        "icao": "FTTD",
        "latitude": "8.624409675598145",
        "longitude": "16.071399688720703",
        "altitude": "1407",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Ndjamena"
    },
    "NDJ": {
        "name": "N'Djamena International Airport",
        "city": "N'djamena",
        "country": "Chad",
        "iata": "NDJ",
        "icao": "FTTJ",
        "latitude": "12.1337",
        "longitude": "15.034",
        "altitude": "968",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Ndjamena"
    },
    "FYT": {
        "name": "Faya Largeau Airport",
        "city": "Faya-largeau",
        "country": "Chad",
        "iata": "FYT",
        "icao": "FTTY",
        "latitude": "17.91710090637207",
        "longitude": "19.111099243164062",
        "altitude": "771",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Ndjamena"
    },
    "BUQ": {
        "name": "Joshua Mqabuko Nkomo International Airport",
        "city": "Bulawayo",
        "country": "Zimbabwe",
        "iata": "BUQ",
        "icao": "FVBU",
        "latitude": "-20.017401",
        "longitude": "28.617901",
        "altitude": "4359",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "BFO": {
        "name": "Buffalo Range Airport",
        "city": "Chiredzi",
        "country": "Zimbabwe",
        "iata": "BFO",
        "icao": "FVCZ",
        "latitude": "-21.008101",
        "longitude": "31.5786",
        "altitude": "1421",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "VFA": {
        "name": "Victoria Falls International Airport",
        "city": "Victoria Falls",
        "country": "Zimbabwe",
        "iata": "VFA",
        "icao": "FVFA",
        "latitude": "-18.09589958190918",
        "longitude": "25.839000701904297",
        "altitude": "3490",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "HRE": {
        "name": "Robert Gabriel Mugabe International Airport",
        "city": "Harare",
        "country": "Zimbabwe",
        "iata": "HRE",
        "icao": "FVHA",
        "latitude": "-17.931801",
        "longitude": "31.0928",
        "altitude": "4887",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "KAB": {
        "name": "Kariba International Airport",
        "city": "Kariba",
        "country": "Zimbabwe",
        "iata": "KAB",
        "icao": "FVKB",
        "latitude": "-16.519800186157227",
        "longitude": "28.885000228881836",
        "altitude": "1706",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "UTA": {
        "name": "Mutare Airport",
        "city": "Mutare",
        "country": "Zimbabwe",
        "iata": "UTA",
        "icao": "FVMU",
        "latitude": "-18.997499465942",
        "longitude": "32.627201080322",
        "altitude": "3410",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "MVZ": {
        "name": "Masvingo International Airport",
        "city": "Masvingo",
        "country": "Zimbabwe",
        "iata": "MVZ",
        "icao": "FVMV",
        "latitude": "-20.055299758911133",
        "longitude": "30.859100341796875",
        "altitude": "3595",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "GWE": {
        "name": "Thornhill Air Base",
        "city": "Gwert",
        "country": "Zimbabwe",
        "iata": "GWE",
        "icao": "FVTL",
        "latitude": "-19.436399459838867",
        "longitude": "29.861900329589844",
        "altitude": "4680",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "HWN": {
        "name": "Hwange National Park Airport",
        "city": "Hwange National Park",
        "country": "Zimbabwe",
        "iata": "HWN",
        "icao": "FVWN",
        "latitude": "-18.629899978637695",
        "longitude": "27.020999908447266",
        "altitude": "3543",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Harare"
    },
    "BLZ": {
        "name": "Chileka International Airport",
        "city": "Blantyre",
        "country": "Malawi",
        "iata": "BLZ",
        "icao": "FWCL",
        "latitude": "-15.679100036621094",
        "longitude": "34.9739990234375",
        "altitude": "2555",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Blantyre"
    },
    "KGJ": {
        "name": "Karonga Airport",
        "city": "Karonga",
        "country": "Malawi",
        "iata": "KGJ",
        "icao": "FWKA",
        "latitude": "-9.953570365905762",
        "longitude": "33.893001556396484",
        "altitude": "1765",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Blantyre"
    },
    "KBQ": {
        "name": "Kasungu Airport",
        "city": "Kasungu",
        "country": "Malawi",
        "iata": "KBQ",
        "icao": "FWKG",
        "latitude": "-13.014599800109863",
        "longitude": "33.46860122680664",
        "altitude": "3470",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Blantyre"
    },
    "LLW": {
        "name": "Lilongwe International Airport",
        "city": "Lilongwe",
        "country": "Malawi",
        "iata": "LLW",
        "icao": "FWKI",
        "latitude": "-13.7894001007",
        "longitude": "33.78099823",
        "altitude": "4035",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Blantyre"
    },
    "ZZU": {
        "name": "Mzuzu Airport",
        "city": "Mzuzu",
        "country": "Malawi",
        "iata": "ZZU",
        "icao": "FWUU",
        "latitude": "-11.444700241088867",
        "longitude": "34.01179885864258",
        "altitude": "4115",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Blantyre"
    },
    "MSU": {
        "name": "Moshoeshoe I International Airport",
        "city": "Maseru",
        "country": "Lesotho",
        "iata": "MSU",
        "icao": "FXMM",
        "latitude": "-29.462299346923828",
        "longitude": "27.552499771118164",
        "altitude": "5348",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maseru"
    },
    "FIH": {
        "name": "Ndjili International Airport",
        "city": "Kinshasa",
        "country": "Congo (Kinshasa)",
        "iata": "FIH",
        "icao": "FZAA",
        "latitude": "-4.38575",
        "longitude": "15.4446",
        "altitude": "1027",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "NLO": {
        "name": "Ndolo Airport",
        "city": "Kinshasa",
        "country": "Congo (Kinshasa)",
        "iata": "NLO",
        "icao": "FZAB",
        "latitude": "-4.32666015625",
        "longitude": "15.327500343323",
        "altitude": "915",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "MNB": {
        "name": "Muanda Airport",
        "city": "Muanda",
        "country": "Congo (Kinshasa)",
        "iata": "MNB",
        "icao": "FZAG",
        "latitude": "-5.9308600425720215",
        "longitude": "12.351799964904785",
        "altitude": "89",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "FDU": {
        "name": "Bandundu Airport",
        "city": "Bandoundu",
        "country": "Congo (Kinshasa)",
        "iata": "FDU",
        "icao": "FZBO",
        "latitude": "-3.3113200664520264",
        "longitude": "17.38170051574707",
        "altitude": "1063",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "KKW": {
        "name": "Kikwit Airport",
        "city": "Kikwit",
        "country": "Congo (Kinshasa)",
        "iata": "KKW",
        "icao": "FZCA",
        "latitude": "-5.035769939422607",
        "longitude": "18.785600662231445",
        "altitude": "1572",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "MDK": {
        "name": "Mbandaka Airport",
        "city": "Mbandaka",
        "country": "Congo (Kinshasa)",
        "iata": "MDK",
        "icao": "FZEA",
        "latitude": "0.0226000007242",
        "longitude": "18.2887001038",
        "altitude": "1040",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "BDT": {
        "name": "Gbadolite Airport",
        "city": "Gbadolite",
        "country": "Congo (Kinshasa)",
        "iata": "BDT",
        "icao": "FZFD",
        "latitude": "4.253210067749023",
        "longitude": "20.975299835205078",
        "altitude": "1509",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "GMA": {
        "name": "Gemena Airport",
        "city": "Gemena",
        "country": "Congo (Kinshasa)",
        "iata": "GMA",
        "icao": "FZFK",
        "latitude": "3.2353699207299997",
        "longitude": "19.771299362199997",
        "altitude": "1378",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "KLI": {
        "name": "Kotakoli Airport",
        "city": "Kotakoli",
        "country": "Congo (Kinshasa)",
        "iata": "KLI",
        "icao": "FZFP",
        "latitude": "4.157639980316162",
        "longitude": "21.65089988708496",
        "altitude": "1801",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "LIQ": {
        "name": "Lisala Airport",
        "city": "Lisala",
        "country": "Congo (Kinshasa)",
        "iata": "LIQ",
        "icao": "FZGA",
        "latitude": "2.17066",
        "longitude": "21.496901",
        "altitude": "1509",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "FKI": {
        "name": "Bangoka International Airport",
        "city": "Kisangani",
        "country": "Congo (Kinshasa)",
        "iata": "FKI",
        "icao": "FZIC",
        "latitude": "0.481638997793",
        "longitude": "25.3379993439",
        "altitude": "1417",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "IRP": {
        "name": "Matari Airport",
        "city": "Isiro",
        "country": "Congo (Kinshasa)",
        "iata": "IRP",
        "icao": "FZJH",
        "latitude": "2.8276100158691406",
        "longitude": "27.588300704956055",
        "altitude": "2438",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "BUX": {
        "name": "Bunia Airport",
        "city": "Bunia",
        "country": "Congo (Kinshasa)",
        "iata": "BUX",
        "icao": "FZKA",
        "latitude": "1.5657199621200562",
        "longitude": "30.220800399780273",
        "altitude": "4045",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "BZU": {
        "name": "Buta Zega Airport",
        "city": "Buta Zega",
        "country": "Congo (Kinshasa)",
        "iata": "BZU",
        "icao": "FZKJ",
        "latitude": "2.818350076675415",
        "longitude": "24.793699264526367",
        "altitude": "1378",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "BKY": {
        "name": "Bukavu Kavumu Airport",
        "city": "Bukavu/kavumu",
        "country": "Congo (Kinshasa)",
        "iata": "BKY",
        "icao": "FZMA",
        "latitude": "-2.3089799880981445",
        "longitude": "28.808799743652344",
        "altitude": "5643",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "GOM": {
        "name": "Goma International Airport",
        "city": "Goma",
        "country": "Congo (Kinshasa)",
        "iata": "GOM",
        "icao": "FZNA",
        "latitude": "-1.6708099842071533",
        "longitude": "29.238500595092773",
        "altitude": "5089",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Kigali"
    },
    "KND": {
        "name": "Kindu Airport",
        "city": "Kindu",
        "country": "Congo (Kinshasa)",
        "iata": "KND",
        "icao": "FZOA",
        "latitude": "-2.91917991638",
        "longitude": "25.915399551399997",
        "altitude": "1630",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "FBM": {
        "name": "Lubumbashi International Airport",
        "city": "Lubumashi",
        "country": "Congo (Kinshasa)",
        "iata": "FBM",
        "icao": "FZQA",
        "latitude": "-11.5913000107",
        "longitude": "27.5308990479",
        "altitude": "4295",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "KWZ": {
        "name": "Kolwezi Airport",
        "city": "Kolwezi",
        "country": "Congo (Kinshasa)",
        "iata": "KWZ",
        "icao": "FZQM",
        "latitude": "-10.765899658203125",
        "longitude": "25.505699157714844",
        "altitude": "5007",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "FMI": {
        "name": "Kalemie Airport",
        "city": "Kalemie",
        "country": "Congo (Kinshasa)",
        "iata": "FMI",
        "icao": "FZRF",
        "latitude": "-5.8755598068237305",
        "longitude": "29.25",
        "altitude": "2569",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "KMN": {
        "name": "Kamina Base Airport",
        "city": "Kamina Base",
        "country": "Congo (Kinshasa)",
        "iata": "KMN",
        "icao": "FZSA",
        "latitude": "-8.642020225524902",
        "longitude": "25.252899169921875",
        "altitude": "3543",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "KGA": {
        "name": "Kananga Airport",
        "city": "Kananga",
        "country": "Congo (Kinshasa)",
        "iata": "KGA",
        "icao": "FZUA",
        "latitude": "-5.90005016327",
        "longitude": "22.4692001343",
        "altitude": "2139",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "MJM": {
        "name": "Mbuji Mayi Airport",
        "city": "Mbuji-mayi",
        "country": "Congo (Kinshasa)",
        "iata": "MJM",
        "icao": "FZWA",
        "latitude": "-6.121240139010001",
        "longitude": "23.569000244099996",
        "altitude": "2221",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lubumbashi"
    },
    "BKO": {
        "name": "Modibo Keita International Airport",
        "city": "Bamako",
        "country": "Mali",
        "iata": "BKO",
        "icao": "GABS",
        "latitude": "12.5335",
        "longitude": "-7.94994",
        "altitude": "1247",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bamako"
    },
    "GAQ": {
        "name": "Gao Airport",
        "city": "Gao",
        "country": "Mali",
        "iata": "GAQ",
        "icao": "GAGO",
        "latitude": "16.24839973449707",
        "longitude": "-0.005456000100821257",
        "altitude": "870",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bamako"
    },
    "KYS": {
        "name": "Kayes Dag Dag Airport",
        "city": "Kayes",
        "country": "Mali",
        "iata": "KYS",
        "icao": "GAKY",
        "latitude": "14.481200218200684",
        "longitude": "-11.404399871826172",
        "altitude": "164",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bamako"
    },
    "MZI": {
        "name": "Mopti Airport",
        "city": "Mopti",
        "country": "Mali",
        "iata": "MZI",
        "icao": "GAMB",
        "latitude": "14.5128002167",
        "longitude": "-4.0795598030099995",
        "altitude": "906",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bamako"
    },
    "TOM": {
        "name": "Timbuktu Airport",
        "city": "Tombouctou",
        "country": "Mali",
        "iata": "TOM",
        "icao": "GATB",
        "latitude": "16.730499267578125",
        "longitude": "-3.007580041885376",
        "altitude": "863",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bamako"
    },
    "BJL": {
        "name": "Banjul International Airport",
        "city": "Banjul",
        "country": "Gambia",
        "iata": "BJL",
        "icao": "GBYD",
        "latitude": "13.338000297546387",
        "longitude": "-16.65220069885254",
        "altitude": "95",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Banjul"
    },
    "FUE": {
        "name": "Fuerteventura Airport",
        "city": "Fuerteventura",
        "country": "Spain",
        "iata": "FUE",
        "icao": "GCFV",
        "latitude": "28.452699661254883",
        "longitude": "-13.863800048828125",
        "altitude": "85",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "VDE": {
        "name": "Hierro Airport",
        "city": "Hierro",
        "country": "Spain",
        "iata": "VDE",
        "icao": "GCHI",
        "latitude": "27.814800262451172",
        "longitude": "-17.887100219726562",
        "altitude": "103",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "SPC": {
        "name": "La Palma Airport",
        "city": "Santa Cruz De La Palma",
        "country": "Spain",
        "iata": "SPC",
        "icao": "GCLA",
        "latitude": "28.626499",
        "longitude": "-17.7556",
        "altitude": "107",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "LPA": {
        "name": "Gran Canaria Airport",
        "city": "Gran Canaria",
        "country": "Spain",
        "iata": "LPA",
        "icao": "GCLP",
        "latitude": "27.931900024414062",
        "longitude": "-15.38659954071045",
        "altitude": "78",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "ACE": {
        "name": "Lanzarote Airport",
        "city": "Arrecife",
        "country": "Spain",
        "iata": "ACE",
        "icao": "GCRR",
        "latitude": "28.945499",
        "longitude": "-13.6052",
        "altitude": "46",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "TFS": {
        "name": "Tenerife South Airport",
        "city": "Tenerife",
        "country": "Spain",
        "iata": "TFS",
        "icao": "GCTS",
        "latitude": "28.044500351",
        "longitude": "-16.5725002289",
        "altitude": "209",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "TFN": {
        "name": "Tenerife Norte Airport",
        "city": "Tenerife",
        "country": "Spain",
        "iata": "TFN",
        "icao": "GCXO",
        "latitude": "28.4827003479",
        "longitude": "-16.3414993286",
        "altitude": "2076",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "MLN": {
        "name": "Melilla Airport",
        "city": "Melilla",
        "country": "Spain",
        "iata": "MLN",
        "icao": "GEML",
        "latitude": "35.279800415",
        "longitude": "-2.9562599659",
        "altitude": "156",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "FNA": {
        "name": "Lungi International Airport",
        "city": "Freetown",
        "country": "Sierra Leone",
        "iata": "FNA",
        "icao": "GFLL",
        "latitude": "8.61644",
        "longitude": "-13.1955",
        "altitude": "84",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Freetown"
    },
    "MLW": {
        "name": "Spriggs Payne Airport",
        "city": "Monrovia",
        "country": "Liberia",
        "iata": "MLW",
        "icao": "GLMR",
        "latitude": "6.289060115814209",
        "longitude": "-10.758700370788574",
        "altitude": "25",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Monrovia"
    },
    "ROB": {
        "name": "Roberts International Airport",
        "city": "Monrovia",
        "country": "Liberia",
        "iata": "ROB",
        "icao": "GLRB",
        "latitude": "6.23379",
        "longitude": "-10.3623",
        "altitude": "31",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Monrovia"
    },
    "AGA": {
        "name": "Al Massira Airport",
        "city": "Agadir",
        "country": "Morocco",
        "iata": "AGA",
        "icao": "GMAD",
        "latitude": "30.325000762939453",
        "longitude": "-9.413069725036621",
        "altitude": "250",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "TTA": {
        "name": "Tan Tan Airport",
        "city": "Tan Tan",
        "country": "Morocco",
        "iata": "TTA",
        "icao": "GMAT",
        "latitude": "28.448200225830078",
        "longitude": "-11.161299705505371",
        "altitude": "653",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "FEZ": {
        "name": "Saïss Airport",
        "city": "Fes",
        "country": "Morocco",
        "iata": "FEZ",
        "icao": "GMFF",
        "latitude": "33.9272994995",
        "longitude": "-4.977960109709999",
        "altitude": "1900",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "ERH": {
        "name": "Moulay Ali Cherif Airport",
        "city": "Er-rachidia",
        "country": "Morocco",
        "iata": "ERH",
        "icao": "GMFK",
        "latitude": "31.9475002289",
        "longitude": "-4.39833021164",
        "altitude": "3428",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "MEK": {
        "name": "Bassatine Airport",
        "city": "Meknes",
        "country": "Morocco",
        "iata": "MEK",
        "icao": "GMFM",
        "latitude": "33.87910079956055",
        "longitude": "-5.515120029449463",
        "altitude": "1890",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "OUD": {
        "name": "Angads Airport",
        "city": "Oujda",
        "country": "Morocco",
        "iata": "OUD",
        "icao": "GMFO",
        "latitude": "34.787200927734375",
        "longitude": "-1.92399001121521",
        "altitude": "1535",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "GMD": {
        "name": "Ben Slimane Airport",
        "city": "Ben Slimane",
        "country": "Morocco",
        "iata": "GMD",
        "icao": "GMMB",
        "latitude": "33.655399",
        "longitude": "-7.22145",
        "altitude": "627",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "RBA": {
        "name": "Rabat-Salé Airport",
        "city": "Rabat",
        "country": "Morocco",
        "iata": "RBA",
        "icao": "GMME",
        "latitude": "34.051498",
        "longitude": "-6.75152",
        "altitude": "276",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "CMN": {
        "name": "Mohammed V International Airport",
        "city": "Casablanca",
        "country": "Morocco",
        "iata": "CMN",
        "icao": "GMMN",
        "latitude": "33.36750030517578",
        "longitude": "-7.589970111846924",
        "altitude": "656",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "RAK": {
        "name": "Menara Airport",
        "city": "Marrakech",
        "country": "Morocco",
        "iata": "RAK",
        "icao": "GMMX",
        "latitude": "31.606899261499997",
        "longitude": "-8.03629970551",
        "altitude": "1545",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "NNA": {
        "name": "Kenitra Airport",
        "city": "Kentira",
        "country": "Morocco",
        "iata": "NNA",
        "icao": "GMMY",
        "latitude": "34.29890060424805",
        "longitude": "-6.595880031585693",
        "altitude": "16",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "OZZ": {
        "name": "Ouarzazate Airport",
        "city": "Ouarzazate",
        "country": "Morocco",
        "iata": "OZZ",
        "icao": "GMMZ",
        "latitude": "30.9391002655",
        "longitude": "-6.909430027010001",
        "altitude": "3782",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "AHU": {
        "name": "Cherif Al Idrissi Airport",
        "city": "Al Hociema",
        "country": "Morocco",
        "iata": "AHU",
        "icao": "GMTA",
        "latitude": "35.177101135253906",
        "longitude": "-3.83951997756958",
        "altitude": "95",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "TTU": {
        "name": "Saniat R'mel Airport",
        "city": "Tetouan",
        "country": "Morocco",
        "iata": "TTU",
        "icao": "GMTN",
        "latitude": "35.594299316406",
        "longitude": "-5.320020198822",
        "altitude": "10",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "TNG": {
        "name": "Ibn Batouta Airport",
        "city": "Tanger",
        "country": "Morocco",
        "iata": "TNG",
        "icao": "GMTT",
        "latitude": "35.726898193400004",
        "longitude": "-5.91689014435",
        "altitude": "62",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "ZIG": {
        "name": "Ziguinchor Airport",
        "city": "Ziguinchor",
        "country": "Senegal",
        "iata": "ZIG",
        "icao": "GOGG",
        "latitude": "12.5556",
        "longitude": "-16.281799",
        "altitude": "75",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "CSK": {
        "name": "Cap Skirring Airport",
        "city": "Cap Skiring",
        "country": "Senegal",
        "iata": "CSK",
        "icao": "GOGS",
        "latitude": "12.39533",
        "longitude": "-16.748",
        "altitude": "52",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "KLC": {
        "name": "Kaolack Airport",
        "city": "Kaolack",
        "country": "Senegal",
        "iata": "KLC",
        "icao": "GOOK",
        "latitude": "14.146900177001953",
        "longitude": "-16.051300048828125",
        "altitude": "26",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "DKR": {
        "name": "Léopold Sédar Senghor International Airport",
        "city": "Dakar",
        "country": "Senegal",
        "iata": "DKR",
        "icao": "GOOY",
        "latitude": "14.739700317382812",
        "longitude": "-17.49020004272461",
        "altitude": "85",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "XLS": {
        "name": "Saint Louis Airport",
        "city": "St. Louis",
        "country": "Senegal",
        "iata": "XLS",
        "icao": "GOSS",
        "latitude": "16.050800323486328",
        "longitude": "-16.463199615478516",
        "altitude": "9",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "BXE": {
        "name": "Bakel Airport",
        "city": "Bakel",
        "country": "Senegal",
        "iata": "BXE",
        "icao": "GOTB",
        "latitude": "14.847299575805664",
        "longitude": "-12.468299865722656",
        "altitude": "98",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "KGG": {
        "name": "Kédougou Airport",
        "city": "Kedougou",
        "country": "Senegal",
        "iata": "KGG",
        "icao": "GOTK",
        "latitude": "12.57229995727539",
        "longitude": "-12.22029972076416",
        "altitude": "584",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "TUD": {
        "name": "Tambacounda Airport",
        "city": "Tambacounda",
        "country": "Senegal",
        "iata": "TUD",
        "icao": "GOTT",
        "latitude": "13.736800193786621",
        "longitude": "-13.65310001373291",
        "altitude": "161",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Dakar"
    },
    "AEO": {
        "name": "Aioun el Atrouss Airport",
        "city": "Aioun El Atrouss",
        "country": "Mauritania",
        "iata": "AEO",
        "icao": "GQNA",
        "latitude": "16.711299896240234",
        "longitude": "-9.637880325317383",
        "altitude": "951",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "TIY": {
        "name": "Tidjikja Airport",
        "city": "Tidjikja",
        "country": "Mauritania",
        "iata": "TIY",
        "icao": "GQND",
        "latitude": "18.570100784301758",
        "longitude": "-11.423500061035156",
        "altitude": "1363",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "KFA": {
        "name": "Kiffa Airport",
        "city": "Kiffa",
        "country": "Mauritania",
        "iata": "KFA",
        "icao": "GQNF",
        "latitude": "16.59000015258789",
        "longitude": "-11.406200408935547",
        "altitude": "424",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "EMN": {
        "name": "Néma Airport",
        "city": "Nema",
        "country": "Mauritania",
        "iata": "EMN",
        "icao": "GQNI",
        "latitude": "16.621999740600586",
        "longitude": "-7.3165998458862305",
        "altitude": "751",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "KED": {
        "name": "Kaédi Airport",
        "city": "Kaedi",
        "country": "Mauritania",
        "iata": "KED",
        "icao": "GQNK",
        "latitude": "16.159500122070312",
        "longitude": "-13.507599830627441",
        "altitude": "66",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "NKC": {
        "name": "Nouakchott–Oumtounsy International Airport",
        "city": "Nouakschott",
        "country": "Mauritania",
        "iata": "NKC",
        "icao": "GQNO",
        "latitude": "18.31",
        "longitude": "-15.9697222",
        "altitude": "9",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "SEY": {
        "name": "Sélibaby Airport",
        "city": "Selibabi",
        "country": "Mauritania",
        "iata": "SEY",
        "icao": "GQNS",
        "latitude": "15.179699897766113",
        "longitude": "-12.207300186157227",
        "altitude": "219",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "ATR": {
        "name": "Atar International Airport",
        "city": "Atar",
        "country": "Mauritania",
        "iata": "ATR",
        "icao": "GQPA",
        "latitude": "20.506799697875977",
        "longitude": "-13.04319953918457",
        "altitude": "734",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "NDB": {
        "name": "Nouadhibou International Airport",
        "city": "Nouadhibou",
        "country": "Mauritania",
        "iata": "NDB",
        "icao": "GQPP",
        "latitude": "20.9330997467041",
        "longitude": "-17.030000686645508",
        "altitude": "24",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "FIG": {
        "name": "Fria Airport",
        "city": "Fira",
        "country": "Guinea",
        "iata": "FIG",
        "icao": "GUFA",
        "latitude": "10.350600242615",
        "longitude": "-13.569199562073",
        "altitude": "499",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Conakry"
    },
    "FAA": {
        "name": "Faranah Airport",
        "city": "Faranah",
        "country": "Guinea",
        "iata": "FAA",
        "icao": "GUFH",
        "latitude": "10.0354995728",
        "longitude": "-10.7698001862",
        "altitude": "1476",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Conakry"
    },
    "LEK": {
        "name": "Tata Airport",
        "city": "Labe",
        "country": "Guinea",
        "iata": "LEK",
        "icao": "GULB",
        "latitude": "11.326100349426",
        "longitude": "-12.286800384521",
        "altitude": "3396",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Conakry"
    },
    "SID": {
        "name": "Amílcar Cabral International Airport",
        "city": "Amilcar Cabral",
        "country": "Cape Verde",
        "iata": "SID",
        "icao": "GVAC",
        "latitude": "16.7414",
        "longitude": "-22.9494",
        "altitude": "177",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "BVC": {
        "name": "Rabil Airport",
        "city": "Boa Vista",
        "country": "Cape Verde",
        "iata": "BVC",
        "icao": "GVBA",
        "latitude": "16.136499404907227",
        "longitude": "-22.888900756835938",
        "altitude": "69",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "MMO": {
        "name": "Maio Airport",
        "city": "Maio",
        "country": "Cape Verde",
        "iata": "MMO",
        "icao": "GVMA",
        "latitude": "15.155900001525879",
        "longitude": "-23.213699340820312",
        "altitude": "36",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "SNE": {
        "name": "Preguiça Airport",
        "city": "Sao Nocolau Island",
        "country": "Cape Verde",
        "iata": "SNE",
        "icao": "GVSN",
        "latitude": "16.58839988708496",
        "longitude": "-24.284700393676758",
        "altitude": "669",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "VXE": {
        "name": "São Pedro Airport",
        "city": "Sao Vicente Island",
        "country": "Cape Verde",
        "iata": "VXE",
        "icao": "GVSV",
        "latitude": "16.833200454711914",
        "longitude": "-25.055299758911133",
        "altitude": "66",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "ADD": {
        "name": "Addis Ababa Bole International Airport",
        "city": "Addis Ababa",
        "country": "Ethiopia",
        "iata": "ADD",
        "icao": "HAAB",
        "latitude": "8.97789001465",
        "longitude": "38.799301147499996",
        "altitude": "7630",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "AMH": {
        "name": "Arba Minch Airport",
        "city": "Arba Minch",
        "country": "Ethiopia",
        "iata": "AMH",
        "icao": "HAAM",
        "latitude": "6.0393900871276855",
        "longitude": "37.59049987792969",
        "altitude": "3901",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "AXU": {
        "name": "Axum Airport",
        "city": "Axum",
        "country": "Ethiopia",
        "iata": "AXU",
        "icao": "HAAX",
        "latitude": "14.14680004119873",
        "longitude": "38.77280044555664",
        "altitude": "6959",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "BJR": {
        "name": "Bahir Dar Airport",
        "city": "Bahar Dar",
        "country": "Ethiopia",
        "iata": "BJR",
        "icao": "HABD",
        "latitude": "11.608099937438965",
        "longitude": "37.32160186767578",
        "altitude": "5978",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "DIR": {
        "name": "Aba Tenna Dejazmach Yilma International Airport",
        "city": "Dire Dawa",
        "country": "Ethiopia",
        "iata": "DIR",
        "icao": "HADR",
        "latitude": "9.624699592590332",
        "longitude": "41.85419845581055",
        "altitude": "3827",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "GMB": {
        "name": "Gambella Airport",
        "city": "Gambella",
        "country": "Ethiopia",
        "iata": "GMB",
        "icao": "HAGM",
        "latitude": "8.12876033782959",
        "longitude": "34.5630989074707",
        "altitude": "1614",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "GDQ": {
        "name": "Gonder Airport",
        "city": "Gondar",
        "country": "Ethiopia",
        "iata": "GDQ",
        "icao": "HAGN",
        "latitude": "12.51990032196045",
        "longitude": "37.433998107910156",
        "altitude": "6449",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "JIM": {
        "name": "Jimma Airport",
        "city": "Jimma",
        "country": "Ethiopia",
        "iata": "JIM",
        "icao": "HAJM",
        "latitude": "7.66609001159668",
        "longitude": "36.81660079956055",
        "altitude": "5500",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "LLI": {
        "name": "Lalibella Airport",
        "city": "Lalibella",
        "country": "Ethiopia",
        "iata": "LLI",
        "icao": "HALL",
        "latitude": "11.975000381469727",
        "longitude": "38.97999954223633",
        "altitude": "6506",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "MQX": {
        "name": "Mekele Airport",
        "city": "Makale",
        "country": "Ethiopia",
        "iata": "MQX",
        "icao": "HAMK",
        "latitude": "13.467399597167969",
        "longitude": "39.53350067138672",
        "altitude": "7396",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "ASO": {
        "name": "Asosa Airport",
        "city": "Asosa",
        "country": "Ethiopia",
        "iata": "ASO",
        "icao": "HASO",
        "latitude": "10.018500328063965",
        "longitude": "34.586299896240234",
        "altitude": "5100",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "BJM": {
        "name": "Bujumbura International Airport",
        "city": "Bujumbura",
        "country": "Burundi",
        "iata": "BJM",
        "icao": "HBBA",
        "latitude": "-3.3240199089050293",
        "longitude": "29.318500518798828",
        "altitude": "2582",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Bujumbura"
    },
    "HGA": {
        "name": "Egal International Airport",
        "city": "Hargeisa",
        "country": "Somalia",
        "iata": "HGA",
        "icao": "HCMH",
        "latitude": "9.518170356750488",
        "longitude": "44.08879852294922",
        "altitude": "4423",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "BBO": {
        "name": "Berbera Airport",
        "city": "Berbera",
        "country": "Somalia",
        "iata": "BBO",
        "icao": "HCMI",
        "latitude": "10.389200210571289",
        "longitude": "44.94110107421875",
        "altitude": "30",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "KMU": {
        "name": "Kisimayu Airport",
        "city": "Kismayu",
        "country": "Somalia",
        "iata": "KMU",
        "icao": "HCMK",
        "latitude": "-0.3773530125617981",
        "longitude": "42.45920181274414",
        "altitude": "49",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "ALY": {
        "name": "El Nouzha Airport",
        "city": "Alexandria",
        "country": "Egypt",
        "iata": "ALY",
        "icao": "HEAX",
        "latitude": "31.183900833129883",
        "longitude": "29.94890022277832",
        "altitude": "-6",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "ABS": {
        "name": "Abu Simbel Airport",
        "city": "Abu Simbel",
        "country": "Egypt",
        "iata": "ABS",
        "icao": "HEBL",
        "latitude": "22.375999450699997",
        "longitude": "31.611700058",
        "altitude": "616",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "CAI": {
        "name": "Cairo International Airport",
        "city": "Cairo",
        "country": "Egypt",
        "iata": "CAI",
        "icao": "HECA",
        "latitude": "30.12190055847168",
        "longitude": "31.40559959411621",
        "altitude": "382",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "CWE": {
        "name": "Cairo West Airport",
        "city": "Cairo",
        "country": "Egypt",
        "iata": "CWE",
        "icao": "HECW",
        "latitude": "30.116399765",
        "longitude": "30.915399551399997",
        "altitude": "550",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "HRG": {
        "name": "Hurghada International Airport",
        "city": "Hurghada",
        "country": "Egypt",
        "iata": "HRG",
        "icao": "HEGN",
        "latitude": "27.178300857543945",
        "longitude": "33.799400329589844",
        "altitude": "52",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "EGH": {
        "name": "El Gora Airport",
        "city": "El Gorah",
        "country": "Egypt",
        "iata": "EGH",
        "icao": "HEGR",
        "latitude": "31.068559",
        "longitude": "34.129629",
        "altitude": "324",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "LXR": {
        "name": "Luxor International Airport",
        "city": "Luxor",
        "country": "Egypt",
        "iata": "LXR",
        "icao": "HELX",
        "latitude": "25.671",
        "longitude": "32.7066",
        "altitude": "294",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "MUH": {
        "name": "Mersa Matruh Airport",
        "city": "Mersa-matruh",
        "country": "Egypt",
        "iata": "MUH",
        "icao": "HEMM",
        "latitude": "31.3253993988",
        "longitude": "27.221700668300002",
        "altitude": "94",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "PSD": {
        "name": "Port Said Airport",
        "city": "Port Said",
        "country": "Egypt",
        "iata": "PSD",
        "icao": "HEPS",
        "latitude": "31.279399871826172",
        "longitude": "32.2400016784668",
        "altitude": "8",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "SKV": {
        "name": "St Catherine International Airport",
        "city": "St. Catherine",
        "country": "Egypt",
        "iata": "SKV",
        "icao": "HESC",
        "latitude": "28.685300827",
        "longitude": "34.0625",
        "altitude": "4368",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "ASW": {
        "name": "Aswan International Airport",
        "city": "Aswan",
        "country": "Egypt",
        "iata": "ASW",
        "icao": "HESN",
        "latitude": "23.9643993378",
        "longitude": "32.8199996948",
        "altitude": "662",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "ELT": {
        "name": "El Tor Airport",
        "city": "El-tor",
        "country": "Egypt",
        "iata": "ELT",
        "icao": "HETR",
        "latitude": "28.208999633789062",
        "longitude": "33.64550018310547",
        "altitude": "115",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "EDL": {
        "name": "Eldoret International Airport",
        "city": "Eldoret",
        "country": "Kenya",
        "iata": "EDL",
        "icao": "HKEL",
        "latitude": "0.4044579863548279",
        "longitude": "35.23889923095703",
        "altitude": "6941",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "GGM": {
        "name": "Kakamega Airport",
        "city": "Kakamega",
        "country": "Kenya",
        "iata": "GGM",
        "icao": "HKKG",
        "latitude": "0.271342009306",
        "longitude": "34.7873001099",
        "altitude": "5020",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "KIS": {
        "name": "Kisumu Airport",
        "city": "Kisumu",
        "country": "Kenya",
        "iata": "KIS",
        "icao": "HKKI",
        "latitude": "-0.0861390009522438",
        "longitude": "34.72890090942383",
        "altitude": "3734",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "KTL": {
        "name": "Kitale Airport",
        "city": "Kitale",
        "country": "Kenya",
        "iata": "KTL",
        "icao": "HKKT",
        "latitude": "0.9719889760017395",
        "longitude": "34.95859909057617",
        "altitude": "6070",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "LOK": {
        "name": "Lodwar Airport",
        "city": "Lodwar",
        "country": "Kenya",
        "iata": "LOK",
        "icao": "HKLO",
        "latitude": "3.1219699382781982",
        "longitude": "35.608699798583984",
        "altitude": "1715",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "LAU": {
        "name": "Manda Airstrip",
        "city": "Lamu",
        "country": "Kenya",
        "iata": "LAU",
        "icao": "HKLU",
        "latitude": "-2.252419948577881",
        "longitude": "40.91310119628906",
        "altitude": "20",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "MBA": {
        "name": "Mombasa Moi International Airport",
        "city": "Mombasa",
        "country": "Kenya",
        "iata": "MBA",
        "icao": "HKMO",
        "latitude": "-4.034830093383789",
        "longitude": "39.594200134277344",
        "altitude": "200",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "WIL": {
        "name": "Nairobi Wilson Airport",
        "city": "Nairobi",
        "country": "Kenya",
        "iata": "WIL",
        "icao": "HKNW",
        "latitude": "-1.321720004081726",
        "longitude": "36.81480026245117",
        "altitude": "5536",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "WJR": {
        "name": "Wajir Airport",
        "city": "Wajir",
        "country": "Kenya",
        "iata": "WJR",
        "icao": "HKWJ",
        "latitude": "1.73324",
        "longitude": "40.091599",
        "altitude": "770",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "GHT": {
        "name": "Ghat Airport",
        "city": "Ghat",
        "country": "Libya",
        "iata": "GHT",
        "icao": "HLGT",
        "latitude": "25.1455993652",
        "longitude": "10.142600059500001",
        "altitude": "2296",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "AKF": {
        "name": "Kufra Airport",
        "city": "Kufra",
        "country": "Libya",
        "iata": "AKF",
        "icao": "HLKF",
        "latitude": "24.178699493408203",
        "longitude": "23.31399917602539",
        "altitude": "1367",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "BEN": {
        "name": "Benina International Airport",
        "city": "Benghazi",
        "country": "Libya",
        "iata": "BEN",
        "icao": "HLLB",
        "latitude": "32.096801757799994",
        "longitude": "20.2695007324",
        "altitude": "433",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "SEB": {
        "name": "Sabha Airport",
        "city": "Sebha",
        "country": "Libya",
        "iata": "SEB",
        "icao": "HLLS",
        "latitude": "26.98699951171875",
        "longitude": "14.47249984741211",
        "altitude": "1427",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "TIP": {
        "name": "Tripoli International Airport",
        "city": "Tripoli",
        "country": "Libya",
        "iata": "TIP",
        "icao": "HLLT",
        "latitude": "32.6635017395",
        "longitude": "13.1590003967",
        "altitude": "263",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "LMQ": {
        "name": "Marsa Brega Airport",
        "city": "Marsa Brega",
        "country": "Libya",
        "iata": "LMQ",
        "icao": "HLMB",
        "latitude": "30.37809944152832",
        "longitude": "19.576400756835938",
        "altitude": "50",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "HUQ": {
        "name": "Hon Airport",
        "city": "Hon",
        "country": "Libya",
        "iata": "HUQ",
        "icao": "HLON",
        "latitude": "29.11009979248047",
        "longitude": "15.96560001373291",
        "altitude": "919",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "LTD": {
        "name": "Ghadames East Airport",
        "city": "Ghadames",
        "country": "Libya",
        "iata": "LTD",
        "icao": "HLTD",
        "latitude": "30.15169906616211",
        "longitude": "9.715310096740723",
        "altitude": "1122",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "GYI": {
        "name": "Gisenyi Airport",
        "city": "Gisenyi",
        "country": "Rwanda",
        "iata": "GYI",
        "icao": "HRYG",
        "latitude": "-1.6771999597549438",
        "longitude": "29.258899688720703",
        "altitude": "5082",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Kigali"
    },
    "KGL": {
        "name": "Kigali International Airport",
        "city": "Kigali",
        "country": "Rwanda",
        "iata": "KGL",
        "icao": "HRYR",
        "latitude": "-1.96863",
        "longitude": "30.1395",
        "altitude": "4859",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Kigali"
    },
    "KME": {
        "name": "Kamembe Airport",
        "city": "Kamembe",
        "country": "Rwanda",
        "iata": "KME",
        "icao": "HRZA",
        "latitude": "-2.462239980697632",
        "longitude": "28.907899856567383",
        "altitude": "5192",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Kigali"
    },
    "DOG": {
        "name": "Dongola Airport",
        "city": "Dongola",
        "country": "Sudan",
        "iata": "DOG",
        "icao": "HSDN",
        "latitude": "19.153900146499996",
        "longitude": "30.430099487299998",
        "altitude": "772",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "RSS": {
        "name": "Damazin Airport",
        "city": "Damazin",
        "country": "Sudan",
        "iata": "RSS",
        "icao": "HSDZ",
        "latitude": "11.7859",
        "longitude": "34.3367",
        "altitude": "1582",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "ELF": {
        "name": "El Fasher Airport",
        "city": "El Fasher",
        "country": "Sudan",
        "iata": "ELF",
        "icao": "HSFS",
        "latitude": "13.614899635314941",
        "longitude": "25.324600219726562",
        "altitude": "2393",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "KSL": {
        "name": "Kassala Airport",
        "city": "Kassala",
        "country": "Sudan",
        "iata": "KSL",
        "icao": "HSKA",
        "latitude": "15.387499809265137",
        "longitude": "36.328800201416016",
        "altitude": "1671",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "KDX": {
        "name": "Kadugli Airport",
        "city": "Kadugli",
        "country": "Sudan",
        "iata": "KDX",
        "icao": "HSLI",
        "latitude": "11.137999534600002",
        "longitude": "29.7010993958",
        "altitude": "1848",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "EBD": {
        "name": "El Obeid Airport",
        "city": "El Obeid",
        "country": "Sudan",
        "iata": "EBD",
        "icao": "HSOB",
        "latitude": "13.153200149536133",
        "longitude": "30.23270034790039",
        "altitude": "1927",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "JUB": {
        "name": "Juba International Airport",
        "city": "Juba",
        "country": "South Sudan",
        "iata": "JUB",
        "icao": "HSSJ",
        "latitude": "4.87201023102",
        "longitude": "31.6011009216",
        "altitude": "1513",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Juba"
    },
    "MAK": {
        "name": "Malakal Airport",
        "city": "Malakal",
        "country": "Sudan",
        "iata": "MAK",
        "icao": "HSSM",
        "latitude": "9.55897045135498",
        "longitude": "31.65220069885254",
        "altitude": "1291",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Juba"
    },
    "KRT": {
        "name": "Khartoum International Airport",
        "city": "Khartoum",
        "country": "Sudan",
        "iata": "KRT",
        "icao": "HSSS",
        "latitude": "15.589500427246094",
        "longitude": "32.553199768066406",
        "altitude": "1265",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "ARK": {
        "name": "Arusha Airport",
        "city": "Arusha",
        "country": "Tanzania",
        "iata": "ARK",
        "icao": "HTAR",
        "latitude": "-3.3677899837493896",
        "longitude": "36.63330078125",
        "altitude": "4550",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "DAR": {
        "name": "Julius Nyerere International Airport",
        "city": "Dar Es Salaam",
        "country": "Tanzania",
        "iata": "DAR",
        "icao": "HTDA",
        "latitude": "-6.87811",
        "longitude": "39.202599",
        "altitude": "182",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "DOD": {
        "name": "Dodoma Airport",
        "city": "Dodoma",
        "country": "Tanzania",
        "iata": "DOD",
        "icao": "HTDO",
        "latitude": "-6.170440196990967",
        "longitude": "35.752601623535156",
        "altitude": "3673",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "IRI": {
        "name": "Iringa Airport",
        "city": "Iringa",
        "country": "Tanzania",
        "iata": "IRI",
        "icao": "HTIR",
        "latitude": "-7.668630123138428",
        "longitude": "35.75210189819336",
        "altitude": "4678",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "JRO": {
        "name": "Kilimanjaro International Airport",
        "city": "Kilimanjaro",
        "country": "Tanzania",
        "iata": "JRO",
        "icao": "HTKJ",
        "latitude": "-3.42940998077",
        "longitude": "37.0745010376",
        "altitude": "2932",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "LKY": {
        "name": "Lake Manyara Airport",
        "city": "Lake Manyara",
        "country": "Tanzania",
        "iata": "LKY",
        "icao": "HTLM",
        "latitude": "-3.376310110092163",
        "longitude": "35.81829833984375",
        "altitude": "4150",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "MYW": {
        "name": "Mtwara Airport",
        "city": "Mtwara",
        "country": "Tanzania",
        "iata": "MYW",
        "icao": "HTMT",
        "latitude": "-10.339099884033203",
        "longitude": "40.181800842285156",
        "altitude": "371",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "MWZ": {
        "name": "Mwanza Airport",
        "city": "Mwanza",
        "country": "Tanzania",
        "iata": "MWZ",
        "icao": "HTMW",
        "latitude": "-2.4444899559020996",
        "longitude": "32.932701110839844",
        "altitude": "3763",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "PMA": {
        "name": "Pemba Airport",
        "city": "Pemba",
        "country": "Tanzania",
        "iata": "PMA",
        "icao": "HTPE",
        "latitude": "-5.257259845733643",
        "longitude": "39.8114013671875",
        "altitude": "80",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "TGT": {
        "name": "Tanga Airport",
        "city": "Tanga",
        "country": "Tanzania",
        "iata": "TGT",
        "icao": "HTTG",
        "latitude": "-5.092360019683838",
        "longitude": "39.07120132446289",
        "altitude": "129",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "ZNZ": {
        "name": "Abeid Amani Karume International Airport",
        "city": "Zanzibar",
        "country": "Tanzania",
        "iata": "ZNZ",
        "icao": "HTZA",
        "latitude": "-6.22202",
        "longitude": "39.224899",
        "altitude": "54",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "EBB": {
        "name": "Entebbe International Airport",
        "city": "Entebbe",
        "country": "Uganda",
        "iata": "EBB",
        "icao": "HUEN",
        "latitude": "0.042386",
        "longitude": "32.443501",
        "altitude": "3782",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Kampala"
    },
    "SRT": {
        "name": "Soroti Airport",
        "city": "Soroti",
        "country": "Uganda",
        "iata": "SRT",
        "icao": "HUSO",
        "latitude": "1.7276899814605713",
        "longitude": "33.622798919677734",
        "altitude": "3697",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Kampala"
    },
    "TIA": {
        "name": "Tirana International Airport Mother Teresa",
        "city": "Tirana",
        "country": "Albania",
        "iata": "TIA",
        "icao": "LATI",
        "latitude": "41.4146995544",
        "longitude": "19.7206001282",
        "altitude": "126",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Tirane"
    },
    "BOJ": {
        "name": "Burgas Airport",
        "city": "Bourgas",
        "country": "Bulgaria",
        "iata": "BOJ",
        "icao": "LBBG",
        "latitude": "42.56959915161133",
        "longitude": "27.515199661254883",
        "altitude": "135",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Sofia"
    },
    "GOZ": {
        "name": "Gorna Oryahovitsa Airport",
        "city": "Gorna Orechovica",
        "country": "Bulgaria",
        "iata": "GOZ",
        "icao": "LBGO",
        "latitude": "43.15140151977539",
        "longitude": "25.712900161743164",
        "altitude": "285",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Sofia"
    },
    "PDV": {
        "name": "Plovdiv International Airport",
        "city": "Plovdiv",
        "country": "Bulgaria",
        "iata": "PDV",
        "icao": "LBPD",
        "latitude": "42.067799",
        "longitude": "24.8508",
        "altitude": "597",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Sofia"
    },
    "SOF": {
        "name": "Sofia Airport",
        "city": "Sofia",
        "country": "Bulgaria",
        "iata": "SOF",
        "icao": "LBSF",
        "latitude": "42.696693420410156",
        "longitude": "23.411436080932617",
        "altitude": "1742",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Sofia"
    },
    "SZR": {
        "name": "Stara Zagora Airport",
        "city": "Stara Zagora",
        "country": "Bulgaria",
        "iata": "SZR",
        "icao": "LBSZ",
        "latitude": "42.3766667",
        "longitude": "25.655",
        "altitude": "558",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Sofia"
    },
    "VAR": {
        "name": "Varna Airport",
        "city": "Varna",
        "country": "Bulgaria",
        "iata": "VAR",
        "icao": "LBWN",
        "latitude": "43.232101",
        "longitude": "27.8251",
        "altitude": "230",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Sofia"
    },
    "LCA": {
        "name": "Larnaca International Airport",
        "city": "Larnaca",
        "country": "Cyprus",
        "iata": "LCA",
        "icao": "LCLK",
        "latitude": "34.875099182128906",
        "longitude": "33.624900817871094",
        "altitude": "8",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Nicosia"
    },
    "PFO": {
        "name": "Paphos International Airport",
        "city": "Paphos",
        "country": "Cyprus",
        "iata": "PFO",
        "icao": "LCPH",
        "latitude": "34.71799850463867",
        "longitude": "32.48569869995117",
        "altitude": "41",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Nicosia"
    },
    "AKT": {
        "name": "RAF Akrotiri",
        "city": "Akrotiri",
        "country": "Cyprus",
        "iata": "AKT",
        "icao": "LCRA",
        "latitude": "34.590401",
        "longitude": "32.9879",
        "altitude": "76",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "DBV": {
        "name": "Dubrovnik Airport",
        "city": "Dubrovnik",
        "country": "Croatia",
        "iata": "DBV",
        "icao": "LDDU",
        "latitude": "42.5614013671875",
        "longitude": "18.268199920654297",
        "altitude": "527",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "OSI": {
        "name": "Osijek Airport",
        "city": "Osijek",
        "country": "Croatia",
        "iata": "OSI",
        "icao": "LDOS",
        "latitude": "45.46269989013672",
        "longitude": "18.810199737548828",
        "altitude": "290",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "PUY": {
        "name": "Pula Airport",
        "city": "Pula",
        "country": "Croatia",
        "iata": "PUY",
        "icao": "LDPL",
        "latitude": "44.89350128173828",
        "longitude": "13.922200202941895",
        "altitude": "274",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "RJK": {
        "name": "Rijeka Airport",
        "city": "Rijeka",
        "country": "Croatia",
        "iata": "RJK",
        "icao": "LDRI",
        "latitude": "45.21689987182617",
        "longitude": "14.570300102233887",
        "altitude": "278",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "SPU": {
        "name": "Split Airport",
        "city": "Split",
        "country": "Croatia",
        "iata": "SPU",
        "icao": "LDSP",
        "latitude": "43.53889846801758",
        "longitude": "16.29800033569336",
        "altitude": "79",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "ZAG": {
        "name": "Zagreb Airport",
        "city": "Zagreb",
        "country": "Croatia",
        "iata": "ZAG",
        "icao": "LDZA",
        "latitude": "45.7429008484",
        "longitude": "16.0687999725",
        "altitude": "353",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "ZAD": {
        "name": "Zadar Airport",
        "city": "Zadar",
        "country": "Croatia",
        "iata": "ZAD",
        "icao": "LDZD",
        "latitude": "44.108299",
        "longitude": "15.3467",
        "altitude": "289",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "ABC": {
        "name": "Albacete-Los Llanos Airport",
        "city": "Albacete",
        "country": "Spain",
        "iata": "ABC",
        "icao": "LEAB",
        "latitude": "38.9485015869",
        "longitude": "-1.8635200262099998",
        "altitude": "2302",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "ALC": {
        "name": "Alicante International Airport",
        "city": "Alicante",
        "country": "Spain",
        "iata": "ALC",
        "icao": "LEAL",
        "latitude": "38.28219985961914",
        "longitude": "-0.5581560134887695",
        "altitude": "142",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "LEI": {
        "name": "Almería International Airport",
        "city": "Almeria",
        "country": "Spain",
        "iata": "LEI",
        "icao": "LEAM",
        "latitude": "36.84389877319336",
        "longitude": "-2.3701000213623047",
        "altitude": "70",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "OVD": {
        "name": "Asturias Airport",
        "city": "Aviles",
        "country": "Spain",
        "iata": "OVD",
        "icao": "LEAS",
        "latitude": "43.5635986328125",
        "longitude": "-6.0346198081970215",
        "altitude": "416",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "ODB": {
        "name": "Córdoba Airport",
        "city": "Cordoba",
        "country": "Spain",
        "iata": "ODB",
        "icao": "LEBA",
        "latitude": "37.84199905395508",
        "longitude": "-4.848879814147949",
        "altitude": "297",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "BIO": {
        "name": "Bilbao Airport",
        "city": "Bilbao",
        "country": "Spain",
        "iata": "BIO",
        "icao": "LEBB",
        "latitude": "43.30110168457031",
        "longitude": "-2.9106099605560303",
        "altitude": "138",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "BCN": {
        "name": "Barcelona International Airport",
        "city": "Barcelona",
        "country": "Spain",
        "iata": "BCN",
        "icao": "LEBL",
        "latitude": "41.2971",
        "longitude": "2.07846",
        "altitude": "12",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "BJZ": {
        "name": "Badajoz Airport",
        "city": "Badajoz",
        "country": "Spain",
        "iata": "BJZ",
        "icao": "LEBZ",
        "latitude": "38.891300201416016",
        "longitude": "-6.8213300704956055",
        "altitude": "609",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "LCG": {
        "name": "A Coruña Airport",
        "city": "La Coruna",
        "country": "Spain",
        "iata": "LCG",
        "icao": "LECO",
        "latitude": "43.302101",
        "longitude": "-8.37726",
        "altitude": "326",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "GRO": {
        "name": "Girona Airport",
        "city": "Gerona",
        "country": "Spain",
        "iata": "GRO",
        "icao": "LEGE",
        "latitude": "41.901000977",
        "longitude": "2.7605500221",
        "altitude": "468",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "GRX": {
        "name": "Federico Garcia Lorca Airport",
        "city": "Granada",
        "country": "Spain",
        "iata": "GRX",
        "icao": "LEGR",
        "latitude": "37.18870162963867",
        "longitude": "-3.777359962463379",
        "altitude": "1860",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "IBZ": {
        "name": "Ibiza Airport",
        "city": "Ibiza",
        "country": "Spain",
        "iata": "IBZ",
        "icao": "LEIB",
        "latitude": "38.872898101800004",
        "longitude": "1.3731199502899998",
        "altitude": "24",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "XRY": {
        "name": "Jerez Airport",
        "city": "Jerez",
        "country": "Spain",
        "iata": "XRY",
        "icao": "LEJR",
        "latitude": "36.744598388671875",
        "longitude": "-6.060110092163086",
        "altitude": "93",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "MJV": {
        "name": "San Javier Airport",
        "city": "Murcia",
        "country": "Spain",
        "iata": "MJV",
        "icao": "LELC",
        "latitude": "37.775001525878906",
        "longitude": "-0.8123890161514282",
        "altitude": "11",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "MAD": {
        "name": "Adolfo Suárez Madrid–Barajas Airport",
        "city": "Madrid",
        "country": "Spain",
        "iata": "MAD",
        "icao": "LEMD",
        "latitude": "40.471926",
        "longitude": "-3.56264",
        "altitude": "1998",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "AGP": {
        "name": "Málaga Airport",
        "city": "Malaga",
        "country": "Spain",
        "iata": "AGP",
        "icao": "LEMG",
        "latitude": "36.67490005493164",
        "longitude": "-4.499110221862793",
        "altitude": "53",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "MAH": {
        "name": "Menorca Airport",
        "city": "Menorca",
        "country": "Spain",
        "iata": "MAH",
        "icao": "LEMH",
        "latitude": "39.86259841918945",
        "longitude": "4.218649864196777",
        "altitude": "302",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "OZP": {
        "name": "Moron Air Base",
        "city": "Sevilla",
        "country": "Spain",
        "iata": "OZP",
        "icao": "LEMO",
        "latitude": "37.17490005493164",
        "longitude": "-5.615940093994141",
        "altitude": "285",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "PNA": {
        "name": "Pamplona Airport",
        "city": "Pamplona",
        "country": "Spain",
        "iata": "PNA",
        "icao": "LEPP",
        "latitude": "42.77000045776367",
        "longitude": "-1.6463299989700317",
        "altitude": "1504",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "REU": {
        "name": "Reus Air Base",
        "city": "Reus",
        "country": "Spain",
        "iata": "REU",
        "icao": "LERS",
        "latitude": "41.14739990234375",
        "longitude": "1.1671700477600098",
        "altitude": "233",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "ROZ": {
        "name": "Rota Naval Station Airport",
        "city": "Rota",
        "country": "Spain",
        "iata": "ROZ",
        "icao": "LERT",
        "latitude": "36.645198822",
        "longitude": "-6.34946012497",
        "altitude": "86",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "SLM": {
        "name": "Salamanca Airport",
        "city": "Salamanca",
        "country": "Spain",
        "iata": "SLM",
        "icao": "LESA",
        "latitude": "40.95209884643555",
        "longitude": "-5.501989841461182",
        "altitude": "2595",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "EAS": {
        "name": "San Sebastian Airport",
        "city": "San Sebastian",
        "country": "Spain",
        "iata": "EAS",
        "icao": "LESO",
        "latitude": "43.35649871826172",
        "longitude": "-1.7906099557876587",
        "altitude": "16",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "SCQ": {
        "name": "Santiago de Compostela Airport",
        "city": "Santiago",
        "country": "Spain",
        "iata": "SCQ",
        "icao": "LEST",
        "latitude": "42.89630126953125",
        "longitude": "-8.415140151977539",
        "altitude": "1213",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "LEU": {
        "name": "Pirineus - la Seu d'Urgel Airport",
        "city": "Seo De Urgel",
        "country": "Spain",
        "iata": "LEU",
        "icao": "LESU",
        "latitude": "42.3386",
        "longitude": "1.40917",
        "altitude": "2625",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "TOJ": {
        "name": "Torrejón Airport",
        "city": "Madrid",
        "country": "Spain",
        "iata": "TOJ",
        "icao": "LETO",
        "latitude": "40.496700286865234",
        "longitude": "-3.4458699226379395",
        "altitude": "2026",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "VLC": {
        "name": "Valencia Airport",
        "city": "Valencia",
        "country": "Spain",
        "iata": "VLC",
        "icao": "LEVC",
        "latitude": "39.4893",
        "longitude": "-0.481625",
        "altitude": "240",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "VLL": {
        "name": "Valladolid Airport",
        "city": "Valladolid",
        "country": "Spain",
        "iata": "VLL",
        "icao": "LEVD",
        "latitude": "41.7061004639",
        "longitude": "-4.85194015503",
        "altitude": "2776",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "VIT": {
        "name": "Vitoria/Foronda Airport",
        "city": "Vitoria",
        "country": "Spain",
        "iata": "VIT",
        "icao": "LEVT",
        "latitude": "42.8828010559082",
        "longitude": "-2.7244699001312256",
        "altitude": "1682",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "VGO": {
        "name": "Vigo Airport",
        "city": "Vigo",
        "country": "Spain",
        "iata": "VGO",
        "icao": "LEVX",
        "latitude": "42.2318000793457",
        "longitude": "-8.62677001953125",
        "altitude": "856",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "SDR": {
        "name": "Santander Airport",
        "city": "Santander",
        "country": "Spain",
        "iata": "SDR",
        "icao": "LEXJ",
        "latitude": "43.427101135253906",
        "longitude": "-3.82000994682312",
        "altitude": "16",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "ZAZ": {
        "name": "Zaragoza Air Base",
        "city": "Zaragoza",
        "country": "Spain",
        "iata": "ZAZ",
        "icao": "LEZG",
        "latitude": "41.66619873046875",
        "longitude": "-1.0415500402450562",
        "altitude": "863",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "SVQ": {
        "name": "Sevilla Airport",
        "city": "Sevilla",
        "country": "Spain",
        "iata": "SVQ",
        "icao": "LEZL",
        "latitude": "37.417999267578125",
        "longitude": "-5.8931097984313965",
        "altitude": "112",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "CQF": {
        "name": "Calais-Dunkerque Airport",
        "city": "Calais",
        "country": "France",
        "iata": "CQF",
        "icao": "LFAC",
        "latitude": "50.962100982666016",
        "longitude": "1.954759955406189",
        "altitude": "12",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BYF": {
        "name": "Albert-Bray Airport",
        "city": "Albert",
        "country": "France",
        "iata": "BYF",
        "icao": "LFAQ",
        "latitude": "49.9715003967",
        "longitude": "2.69765996933",
        "altitude": "364",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LTQ": {
        "name": "Le Touquet-Côte d'Opale Airport",
        "city": "Le Tourquet",
        "country": "France",
        "iata": "LTQ",
        "icao": "LFAT",
        "latitude": "50.517398834228516",
        "longitude": "1.6205899715423584",
        "altitude": "36",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XVS": {
        "name": "Valenciennes-Denain Airport",
        "city": "Valenciennes",
        "country": "France",
        "iata": "XVS",
        "icao": "LFAV",
        "latitude": "50.325801849365234",
        "longitude": "3.4612600803375244",
        "altitude": "177",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "AGF": {
        "name": "Agen-La Garenne Airport",
        "city": "Agen",
        "country": "France",
        "iata": "AGF",
        "icao": "LFBA",
        "latitude": "44.17470169067383",
        "longitude": "0.5905560255050659",
        "altitude": "204",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BOD": {
        "name": "Bordeaux-Mérignac Airport",
        "city": "Bordeaux",
        "country": "France",
        "iata": "BOD",
        "icao": "LFBD",
        "latitude": "44.828300476100004",
        "longitude": "-0.715556025505",
        "altitude": "162",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "EGC": {
        "name": "Bergerac-Roumanière Airport",
        "city": "Bergerac",
        "country": "France",
        "iata": "EGC",
        "icao": "LFBE",
        "latitude": "44.82529830932617",
        "longitude": "0.5186110138893127",
        "altitude": "171",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CNG": {
        "name": "Cognac-Châteaubernard (BA 709) Air Base",
        "city": "Cognac",
        "country": "France",
        "iata": "CNG",
        "icao": "LFBG",
        "latitude": "45.65829849243164",
        "longitude": "-0.3174999952316284",
        "altitude": "102",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "PIS": {
        "name": "Poitiers-Biard Airport",
        "city": "Poitiers",
        "country": "France",
        "iata": "PIS",
        "icao": "LFBI",
        "latitude": "46.58769989013672",
        "longitude": "0.30666598677635193",
        "altitude": "423",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MCU": {
        "name": "Montluçon-Guéret Airport",
        "city": "Montlucon-gueret",
        "country": "France",
        "iata": "MCU",
        "icao": "LFBK",
        "latitude": "46.222599029541016",
        "longitude": "2.363960027694702",
        "altitude": "1497",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LIG": {
        "name": "Limoges Airport",
        "city": "Limoges",
        "country": "France",
        "iata": "LIG",
        "icao": "LFBL",
        "latitude": "45.86280059814453",
        "longitude": "1.1794400215148926",
        "altitude": "1300",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "NIT": {
        "name": "Niort-Souché Airport",
        "city": "Niort",
        "country": "France",
        "iata": "NIT",
        "icao": "LFBN",
        "latitude": "46.313477",
        "longitude": "-0.394529",
        "altitude": "203",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "TLS": {
        "name": "Toulouse-Blagnac Airport",
        "city": "Toulouse",
        "country": "France",
        "iata": "TLS",
        "icao": "LFBO",
        "latitude": "43.629101",
        "longitude": "1.36382",
        "altitude": "499",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "PUF": {
        "name": "Pau Pyrénées Airport",
        "city": "Pau",
        "country": "France",
        "iata": "PUF",
        "icao": "LFBP",
        "latitude": "43.380001068115234",
        "longitude": "-0.41861099004745483",
        "altitude": "616",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LDE": {
        "name": "Tarbes-Lourdes-Pyrénées Airport",
        "city": "Tarbes",
        "country": "France",
        "iata": "LDE",
        "icao": "LFBT",
        "latitude": "43.1786994934082",
        "longitude": "-0.006438999902456999",
        "altitude": "1260",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ANG": {
        "name": "Angoulême-Brie-Champniers Airport",
        "city": "Angouleme",
        "country": "France",
        "iata": "ANG",
        "icao": "LFBU",
        "latitude": "45.72919845581055",
        "longitude": "0.22145600616931915",
        "altitude": "436",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BVE": {
        "name": "Brive Souillac Airport",
        "city": "Brive",
        "country": "France",
        "iata": "BVE",
        "icao": "LFSL",
        "latitude": "45.039722",
        "longitude": "1.485556",
        "altitude": "1016",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "PGX": {
        "name": "Périgueux-Bassillac Airport",
        "city": "Perigueux",
        "country": "France",
        "iata": "PGX",
        "icao": "LFBX",
        "latitude": "45.19810104370117",
        "longitude": "0.815555989742279",
        "altitude": "328",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BIQ": {
        "name": "Biarritz-Anglet-Bayonne Airport",
        "city": "Biarritz-bayonne",
        "country": "France",
        "iata": "BIQ",
        "icao": "LFBZ",
        "latitude": "43.4683333",
        "longitude": "-1.5311111",
        "altitude": "245",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ZAO": {
        "name": "Cahors-Lalbenque Airport",
        "city": "Cahors",
        "country": "France",
        "iata": "ZAO",
        "icao": "LFCC",
        "latitude": "44.35139846801758",
        "longitude": "1.4752800464630127",
        "altitude": "912",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LBI": {
        "name": "Albi-Le Séquestre Airport",
        "city": "Albi",
        "country": "France",
        "iata": "LBI",
        "icao": "LFCI",
        "latitude": "43.91389846801758",
        "longitude": "2.1130599975585938",
        "altitude": "564",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "DCM": {
        "name": "Castres-Mazamet Airport",
        "city": "Castres",
        "country": "France",
        "iata": "DCM",
        "icao": "LFCK",
        "latitude": "43.55630111694336",
        "longitude": "2.289180040359497",
        "altitude": "788",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "RDZ": {
        "name": "Rodez-Marcillac Airport",
        "city": "Rodez",
        "country": "France",
        "iata": "RDZ",
        "icao": "LFCR",
        "latitude": "44.407901763916016",
        "longitude": "2.4826700687408447",
        "altitude": "1910",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "RYN": {
        "name": "Royan-Médis Airport",
        "city": "Royan",
        "country": "France",
        "iata": "RYN",
        "icao": "LFCY",
        "latitude": "45.62810134887695",
        "longitude": "-0.9725000262260437",
        "altitude": "72",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XMW": {
        "name": "Montauban Airport",
        "city": "Montauban",
        "country": "France",
        "iata": "XMW",
        "icao": "LFDB",
        "latitude": "44.025699615478516",
        "longitude": "1.3780399560928345",
        "altitude": "351",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "RCO": {
        "name": "Rochefort-Saint-Agnant (BA 721) Airport",
        "city": "Rochefort",
        "country": "France",
        "iata": "RCO",
        "icao": "LFDN",
        "latitude": "45.88779830932617",
        "longitude": "-0.9830560088157654",
        "altitude": "60",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CMR": {
        "name": "Colmar-Houssen Airport",
        "city": "Colmar",
        "country": "France",
        "iata": "CMR",
        "icao": "LFGA",
        "latitude": "48.109901428222656",
        "longitude": "7.359010219573975",
        "altitude": "628",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "DLE": {
        "name": "Dole-Tavaux Airport",
        "city": "Dole",
        "country": "France",
        "iata": "DLE",
        "icao": "LFGJ",
        "latitude": "47.042686",
        "longitude": "5.435063",
        "altitude": "645",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "OBS": {
        "name": "Aubenas-Ardèche Méridional Airport",
        "city": "Aubenas-vals-lanas",
        "country": "France",
        "iata": "OBS",
        "icao": "LFHO",
        "latitude": "44.544203",
        "longitude": "4.372192",
        "altitude": "923",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LPY": {
        "name": "Le Puy-Loudes Airport",
        "city": "Le Puy",
        "country": "France",
        "iata": "LPY",
        "icao": "LFHP",
        "latitude": "45.0806999206543",
        "longitude": "3.762890100479126",
        "altitude": "2731",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ETZ": {
        "name": "Metz-Nancy-Lorraine Airport",
        "city": "Metz",
        "country": "France",
        "iata": "ETZ",
        "icao": "LFJL",
        "latitude": "48.9821014404",
        "longitude": "6.25131988525",
        "altitude": "870",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BIA": {
        "name": "Bastia-Poretta Airport",
        "city": "Bastia",
        "country": "France",
        "iata": "BIA",
        "icao": "LFKB",
        "latitude": "42.55270004272461",
        "longitude": "9.48373031616211",
        "altitude": "26",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CLY": {
        "name": "Calvi-Sainte-Catherine Airport",
        "city": "Calvi",
        "country": "France",
        "iata": "CLY",
        "icao": "LFKC",
        "latitude": "42.5244444",
        "longitude": "8.7930556",
        "altitude": "209",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "FSC": {
        "name": "Figari Sud-Corse Airport",
        "city": "Figari",
        "country": "France",
        "iata": "FSC",
        "icao": "LFKF",
        "latitude": "41.5005989074707",
        "longitude": "9.097780227661133",
        "altitude": "87",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "AJA": {
        "name": "Ajaccio-Napoléon Bonaparte Airport",
        "city": "Ajaccio",
        "country": "France",
        "iata": "AJA",
        "icao": "LFKJ",
        "latitude": "41.92359924316406",
        "longitude": "8.8029203414917",
        "altitude": "18",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "PRP": {
        "name": "Propriano Airport",
        "city": "Propriano",
        "country": "France",
        "iata": "PRP",
        "icao": "LFKO",
        "latitude": "41.66059875488281",
        "longitude": "8.889749526977539",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SOZ": {
        "name": "Solenzara (BA 126) Air Base",
        "city": "Solenzara",
        "country": "France",
        "iata": "SOZ",
        "icao": "LFKS",
        "latitude": "41.924400329589844",
        "longitude": "9.406000137329102",
        "altitude": "28",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "AUF": {
        "name": "Auxerre-Branches Airport",
        "city": "Auxerre",
        "country": "France",
        "iata": "AUF",
        "icao": "LFLA",
        "latitude": "47.85020065307617",
        "longitude": "3.497109889984131",
        "altitude": "523",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CMF": {
        "name": "Chambéry-Savoie Airport",
        "city": "Chambery",
        "country": "France",
        "iata": "CMF",
        "icao": "LFLB",
        "latitude": "45.638099670410156",
        "longitude": "5.880229949951172",
        "altitude": "779",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CFE": {
        "name": "Clermont-Ferrand Auvergne Airport",
        "city": "Clermont-Ferrand",
        "country": "France",
        "iata": "CFE",
        "icao": "LFLC",
        "latitude": "45.7867012024",
        "longitude": "3.1691699028",
        "altitude": "1090",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BOU": {
        "name": "Bourges Airport",
        "city": "Bourges",
        "country": "France",
        "iata": "BOU",
        "icao": "LFLD",
        "latitude": "47.058101654052734",
        "longitude": "2.3702800273895264",
        "altitude": "529",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "QNJ": {
        "name": "Annemasse Airport",
        "city": "Annemasse",
        "country": "France",
        "iata": "QNJ",
        "icao": "LFLI",
        "latitude": "46.1920013428",
        "longitude": "6.268390178680001",
        "altitude": "1620",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LYS": {
        "name": "Lyon Saint-Exupéry Airport",
        "city": "Lyon",
        "country": "France",
        "iata": "LYS",
        "icao": "LFLL",
        "latitude": "45.725556",
        "longitude": "5.081111",
        "altitude": "821",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SYT": {
        "name": "Saint-Yan Airport",
        "city": "St.-yan",
        "country": "France",
        "iata": "SYT",
        "icao": "LFLN",
        "latitude": "46.412498474121094",
        "longitude": "4.0132598876953125",
        "altitude": "796",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "RNE": {
        "name": "Roanne-Renaison Airport",
        "city": "Roanne",
        "country": "France",
        "iata": "RNE",
        "icao": "LFLO",
        "latitude": "46.05830001831055",
        "longitude": "4.001389980316162",
        "altitude": "1106",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "NCY": {
        "name": "Annecy-Haute-Savoie-Mont Blanc Airport",
        "city": "Annecy",
        "country": "France",
        "iata": "NCY",
        "icao": "LFLP",
        "latitude": "45.9308333",
        "longitude": "6.1063889",
        "altitude": "1521",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "GNB": {
        "name": "Grenoble-Isère Airport",
        "city": "Grenoble",
        "country": "France",
        "iata": "GNB",
        "icao": "LFLS",
        "latitude": "45.36289978027344",
        "longitude": "5.329370021820068",
        "altitude": "1302",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "VAF": {
        "name": "Valence-Chabeuil Airport",
        "city": "Valence",
        "country": "France",
        "iata": "VAF",
        "icao": "LFLU",
        "latitude": "44.9216",
        "longitude": "4.9699",
        "altitude": "525",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "VHY": {
        "name": "Vichy-Charmeil Airport",
        "city": "Vichy",
        "country": "France",
        "iata": "VHY",
        "icao": "LFLV",
        "latitude": "46.169700622558594",
        "longitude": "3.4037399291992188",
        "altitude": "817",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "AUR": {
        "name": "Aurillac Airport",
        "city": "Aurillac",
        "country": "France",
        "iata": "AUR",
        "icao": "LFLW",
        "latitude": "44.89139938354492",
        "longitude": "2.4219400882720947",
        "altitude": "2096",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CHR": {
        "name": "Châteauroux-Déols \"Marcel Dassault\" Airport",
        "city": "Chateauroux",
        "country": "France",
        "iata": "CHR",
        "icao": "LFLX",
        "latitude": "46.860278",
        "longitude": "1.721111",
        "altitude": "529",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LYN": {
        "name": "Lyon-Bron Airport",
        "city": "Lyon",
        "country": "France",
        "iata": "LYN",
        "icao": "LFLY",
        "latitude": "45.72719955444336",
        "longitude": "4.944270133972168",
        "altitude": "659",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CEQ": {
        "name": "Cannes-Mandelieu Airport",
        "city": "Cannes",
        "country": "France",
        "iata": "CEQ",
        "icao": "LFMD",
        "latitude": "43.542",
        "longitude": "6.95348",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "EBU": {
        "name": "Saint-Étienne-Bouthéon Airport",
        "city": "St-Etienne",
        "country": "France",
        "iata": "EBU",
        "icao": "LFMH",
        "latitude": "45.54059982299805",
        "longitude": "4.296390056610107",
        "altitude": "1325",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CCF": {
        "name": "Carcassonne Airport",
        "city": "Carcassonne",
        "country": "France",
        "iata": "CCF",
        "icao": "LFMK",
        "latitude": "43.215999603271484",
        "longitude": "2.3063199520111084",
        "altitude": "433",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MRS": {
        "name": "Marseille Provence Airport",
        "city": "Marseille",
        "country": "France",
        "iata": "MRS",
        "icao": "LFML",
        "latitude": "43.439271922",
        "longitude": "5.22142410278",
        "altitude": "74",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "NCE": {
        "name": "Nice-Côte d'Azur Airport",
        "city": "Nice",
        "country": "France",
        "iata": "NCE",
        "icao": "LFMN",
        "latitude": "43.6584014893",
        "longitude": "7.215869903560001",
        "altitude": "12",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XOG": {
        "name": "Orange-Caritat (BA 115) Air Base",
        "city": "Orange",
        "country": "France",
        "iata": "XOG",
        "icao": "LFMO",
        "latitude": "44.140499114990234",
        "longitude": "4.866720199584961",
        "altitude": "197",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "PGF": {
        "name": "Perpignan-Rivesaltes (Llabanère) Airport",
        "city": "Perpignan",
        "country": "France",
        "iata": "PGF",
        "icao": "LFMP",
        "latitude": "42.74039840698242",
        "longitude": "2.8706700801849365",
        "altitude": "144",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CTT": {
        "name": "Le Castellet Airport",
        "city": "Le Castellet",
        "country": "France",
        "iata": "CTT",
        "icao": "LFMQ",
        "latitude": "43.252498626708984",
        "longitude": "5.785190105438232",
        "altitude": "1391",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MPL": {
        "name": "Montpellier-Méditerranée Airport",
        "city": "Montpellier",
        "country": "France",
        "iata": "MPL",
        "icao": "LFMT",
        "latitude": "43.57619857788086",
        "longitude": "3.96301007270813",
        "altitude": "17",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BZR": {
        "name": "Béziers-Vias Airport",
        "city": "Beziers",
        "country": "France",
        "iata": "BZR",
        "icao": "LFMU",
        "latitude": "43.32350158691406",
        "longitude": "3.3538999557495117",
        "altitude": "56",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "AVN": {
        "name": "Avignon-Caumont Airport",
        "city": "Avignon",
        "country": "France",
        "iata": "AVN",
        "icao": "LFMV",
        "latitude": "43.90729904174805",
        "longitude": "4.901830196380615",
        "altitude": "124",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MEN": {
        "name": "Mende-Brenoux Airfield",
        "city": "Mende",
        "country": "France",
        "iata": "MEN",
        "icao": "LFNB",
        "latitude": "44.502102",
        "longitude": "3.53282",
        "altitude": "3362",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BVA": {
        "name": "Paris Beauvais Tillé Airport",
        "city": "Beauvais",
        "country": "France",
        "iata": "BVA",
        "icao": "LFOB",
        "latitude": "49.45439910888672",
        "longitude": "2.1127800941467285",
        "altitude": "359",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "EVX": {
        "name": "Évreux-Fauville (BA 105) Air Base",
        "city": "Evreux",
        "country": "France",
        "iata": "EVX",
        "icao": "LFOE",
        "latitude": "49.02870178222656",
        "longitude": "1.2198599576950073",
        "altitude": "464",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LEH": {
        "name": "Le Havre Octeville Airport",
        "city": "Le Havre",
        "country": "France",
        "iata": "LEH",
        "icao": "LFOH",
        "latitude": "49.53390121459961",
        "longitude": "0.08805599808692932",
        "altitude": "313",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XAB": {
        "name": "Abbeville",
        "city": "Abbeville",
        "country": "France",
        "iata": "XAB",
        "icao": "LFOI",
        "latitude": "50.143501",
        "longitude": "1.831891",
        "altitude": "220",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ORE": {
        "name": "Orléans-Bricy (BA 123) Air Base",
        "city": "Orleans",
        "country": "France",
        "iata": "ORE",
        "icao": "LFOJ",
        "latitude": "47.9878005981",
        "longitude": "1.7605600357100002",
        "altitude": "412",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XCR": {
        "name": "Châlons-Vatry Airport",
        "city": "Chalons",
        "country": "France",
        "iata": "XCR",
        "icao": "LFOK",
        "latitude": "48.7733333333",
        "longitude": "4.206111111",
        "altitude": "587",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "URO": {
        "name": "Rouen Airport",
        "city": "Rouen",
        "country": "France",
        "iata": "URO",
        "icao": "LFOP",
        "latitude": "49.38420104980469",
        "longitude": "1.1748000383377075",
        "altitude": "512",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "TUF": {
        "name": "Tours-Val-de-Loire Airport",
        "city": "Tours",
        "country": "France",
        "iata": "TUF",
        "icao": "LFOT",
        "latitude": "47.4322013855",
        "longitude": "0.727605998516",
        "altitude": "357",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CET": {
        "name": "Cholet Le Pontreau Airport",
        "city": "Cholet",
        "country": "France",
        "iata": "CET",
        "icao": "LFOU",
        "latitude": "47.08209991455078",
        "longitude": "-0.8770639896392822",
        "altitude": "443",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LVA": {
        "name": "Laval-Entrammes Airport",
        "city": "Laval",
        "country": "France",
        "iata": "LVA",
        "icao": "LFOV",
        "latitude": "48.03139877319336",
        "longitude": "-0.7429860234260559",
        "altitude": "330",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LBG": {
        "name": "Paris-Le Bourget Airport",
        "city": "Paris",
        "country": "France",
        "iata": "LBG",
        "icao": "LFPB",
        "latitude": "48.969398498535156",
        "longitude": "2.441390037536621",
        "altitude": "218",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CSF": {
        "name": "Creil Air Base",
        "city": "Creil",
        "country": "France",
        "iata": "CSF",
        "icao": "LFPC",
        "latitude": "49.253501892089844",
        "longitude": "2.5191400051116943",
        "altitude": "291",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CDG": {
        "name": "Charles de Gaulle International Airport",
        "city": "Paris",
        "country": "France",
        "iata": "CDG",
        "icao": "LFPG",
        "latitude": "49.012798",
        "longitude": "2.55",
        "altitude": "392",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "TNF": {
        "name": "Toussus-le-Noble Airport",
        "city": "Toussous-le-noble",
        "country": "France",
        "iata": "TNF",
        "icao": "LFPN",
        "latitude": "48.75189971923828",
        "longitude": "2.1061899662017822",
        "altitude": "538",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ORY": {
        "name": "Paris-Orly Airport",
        "city": "Paris",
        "country": "France",
        "iata": "ORY",
        "icao": "LFPO",
        "latitude": "48.7233333",
        "longitude": "2.3794444",
        "altitude": "291",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "POX": {
        "name": "Pontoise - Cormeilles-en-Vexin Airport",
        "city": "Pontoise",
        "country": "France",
        "iata": "POX",
        "icao": "LFPT",
        "latitude": "49.096667",
        "longitude": "2.040833",
        "altitude": "325",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "VIY": {
        "name": "Villacoublay-Vélizy (BA 107) Air Base",
        "city": "Villacoublay",
        "country": "France",
        "iata": "VIY",
        "icao": "LFPV",
        "latitude": "48.7741667",
        "longitude": "2.1916667",
        "altitude": "584",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "NVS": {
        "name": "Nevers-Fourchambault Airport",
        "city": "Nevers",
        "country": "France",
        "iata": "NVS",
        "icao": "LFQG",
        "latitude": "47.002601623535156",
        "longitude": "3.1133298873901367",
        "altitude": "602",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XME": {
        "name": "Maubeuge-Élesmes Airport",
        "city": "Maubeuge",
        "country": "France",
        "iata": "XME",
        "icao": "LFQJ",
        "latitude": "50.31050109863281",
        "longitude": "4.033120155334473",
        "altitude": "452",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LIL": {
        "name": "Lille-Lesquin Airport",
        "city": "Lille",
        "country": "France",
        "iata": "LIL",
        "icao": "LFQQ",
        "latitude": "50.563332",
        "longitude": "3.086886",
        "altitude": "157",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "HZB": {
        "name": "Merville-Calonne Airport",
        "city": "Merville",
        "country": "France",
        "iata": "HZB",
        "icao": "LFQT",
        "latitude": "50.61840057373047",
        "longitude": "2.642240047454834",
        "altitude": "61",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "XCZ": {
        "name": "Charleville-Mézières Airport",
        "city": "Charleville",
        "country": "France",
        "iata": "XCZ",
        "icao": "LFQV",
        "latitude": "49.78390121459961",
        "longitude": "4.647079944610596",
        "altitude": "492",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BES": {
        "name": "Brest Bretagne Airport",
        "city": "Brest",
        "country": "France",
        "iata": "BES",
        "icao": "LFRB",
        "latitude": "48.447898864746094",
        "longitude": "-4.418540000915527",
        "altitude": "325",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CER": {
        "name": "Cherbourg-Maupertus Airport",
        "city": "Cherbourg",
        "country": "France",
        "iata": "CER",
        "icao": "LFRC",
        "latitude": "49.65010070800781",
        "longitude": "-1.4702800512313843",
        "altitude": "459",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "DNR": {
        "name": "Dinard-Pleurtuit-Saint-Malo Airport",
        "city": "Dinard",
        "country": "France",
        "iata": "DNR",
        "icao": "LFRD",
        "latitude": "48.58769989013672",
        "longitude": "-2.0799601078033447",
        "altitude": "219",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LBY": {
        "name": "La Baule-Escoublac Airport",
        "city": "La Baule",
        "country": "France",
        "iata": "LBY",
        "icao": "LFRE",
        "latitude": "47.289398193359375",
        "longitude": "-2.3463900089263916",
        "altitude": "105",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "GFR": {
        "name": "Granville Airport",
        "city": "Granville",
        "country": "France",
        "iata": "GFR",
        "icao": "LFRF",
        "latitude": "48.88309860229492",
        "longitude": "-1.564170002937317",
        "altitude": "45",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "DOL": {
        "name": "Deauville-Saint-Gatien Airport",
        "city": "Deauville",
        "country": "France",
        "iata": "DOL",
        "icao": "LFRG",
        "latitude": "49.3652992249",
        "longitude": "0.154305994511",
        "altitude": "479",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LRT": {
        "name": "Lorient South Brittany (Bretagne Sud) Airport",
        "city": "Lorient",
        "country": "France",
        "iata": "LRT",
        "icao": "LFRH",
        "latitude": "47.76060104370117",
        "longitude": "-3.440000057220459",
        "altitude": "160",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "EDM": {
        "name": "La Roche-sur-Yon Airport",
        "city": "La Roche-sur-yon",
        "country": "France",
        "iata": "EDM",
        "icao": "LFRI",
        "latitude": "46.701900482177734",
        "longitude": "-1.3786300420761108",
        "altitude": "299",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LDV": {
        "name": "Landivisiau Air Base",
        "city": "Landivisiau",
        "country": "France",
        "iata": "LDV",
        "icao": "LFRJ",
        "latitude": "48.53030014038086",
        "longitude": "-4.151639938354492",
        "altitude": "348",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "CFR": {
        "name": "Caen-Carpiquet Airport",
        "city": "Caen",
        "country": "France",
        "iata": "CFR",
        "icao": "LFRK",
        "latitude": "49.173301696777344",
        "longitude": "-0.44999998807907104",
        "altitude": "256",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LME": {
        "name": "Le Mans-Arnage Airport",
        "city": "Le Mans",
        "country": "France",
        "iata": "LME",
        "icao": "LFRM",
        "latitude": "47.94860076904297",
        "longitude": "0.20166699588298798",
        "altitude": "194",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "RNS": {
        "name": "Rennes-Saint-Jacques Airport",
        "city": "Rennes",
        "country": "France",
        "iata": "RNS",
        "icao": "LFRN",
        "latitude": "48.069499969499994",
        "longitude": "-1.73478996754",
        "altitude": "124",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LAI": {
        "name": "Lannion-Côte de Granit Airport",
        "city": "Lannion",
        "country": "France",
        "iata": "LAI",
        "icao": "LFRO",
        "latitude": "48.754398",
        "longitude": "-3.47166",
        "altitude": "290",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "UIP": {
        "name": "Quimper-Cornouaille Airport",
        "city": "Quimper",
        "country": "France",
        "iata": "UIP",
        "icao": "LFRQ",
        "latitude": "47.974998474121094",
        "longitude": "-4.167789936065674",
        "altitude": "297",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "NTE": {
        "name": "Nantes Atlantique Airport",
        "city": "Nantes",
        "country": "France",
        "iata": "NTE",
        "icao": "LFRS",
        "latitude": "47.153198242200006",
        "longitude": "-1.61073005199",
        "altitude": "90",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SBK": {
        "name": "Saint-Brieuc-Armor Airport",
        "city": "St.-brieuc Armor",
        "country": "France",
        "iata": "SBK",
        "icao": "LFRT",
        "latitude": "48.5378",
        "longitude": "-2.85444",
        "altitude": "453",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MXN": {
        "name": "Morlaix-Ploujean Airport",
        "city": "Morlaix",
        "country": "France",
        "iata": "MXN",
        "icao": "LFRU",
        "latitude": "48.6031990051",
        "longitude": "-3.81577992439",
        "altitude": "272",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "VNE": {
        "name": "Vannes-Meucon Airport",
        "city": "Vannes",
        "country": "France",
        "iata": "VNE",
        "icao": "LFRV",
        "latitude": "47.72330093383789",
        "longitude": "-2.718559980392456",
        "altitude": "446",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SNR": {
        "name": "Saint-Nazaire-Montoir Airport",
        "city": "St.-nazaire",
        "country": "France",
        "iata": "SNR",
        "icao": "LFRZ",
        "latitude": "47.3105556",
        "longitude": "-2.1566667",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "BSL": {
        "name": "EuroAirport Basel-Mulhouse-Freiburg Airport",
        "city": "Mulhouse",
        "country": "France",
        "iata": "BSL",
        "icao": "LFSB",
        "latitude": "47.59",
        "longitude": "7.5291667",
        "altitude": "885",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "DIJ": {
        "name": "Dijon-Bourgogne Airport",
        "city": "Dijon",
        "country": "France",
        "iata": "DIJ",
        "icao": "LFSD",
        "latitude": "47.268902",
        "longitude": "5.09",
        "altitude": "726",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MZM": {
        "name": "Metz-Frescaty (BA 128) Air Base",
        "city": "Metz",
        "country": "France",
        "iata": "MZM",
        "icao": "LFSF",
        "latitude": "49.07170104980469",
        "longitude": "6.131669998168945",
        "altitude": "629",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "EPL": {
        "name": "Épinal-Mirecourt Airport",
        "city": "Epinal",
        "country": "France",
        "iata": "EPL",
        "icao": "LFSG",
        "latitude": "48.325001",
        "longitude": "6.06998",
        "altitude": "1084",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ENC": {
        "name": "Nancy-Essey Airport",
        "city": "Nancy",
        "country": "France",
        "iata": "ENC",
        "icao": "LFSN",
        "latitude": "48.692100524902344",
        "longitude": "6.230460166931152",
        "altitude": "751",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "RHE": {
        "name": "Reims-Champagne (BA 112) Air Base",
        "city": "Reims",
        "country": "France",
        "iata": "RHE",
        "icao": "LFSR",
        "latitude": "49.310001",
        "longitude": "4.05",
        "altitude": "312",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SXB": {
        "name": "Strasbourg Airport",
        "city": "Strasbourg",
        "country": "France",
        "iata": "SXB",
        "icao": "LFST",
        "latitude": "48.538299560546875",
        "longitude": "7.628230094909668",
        "altitude": "505",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "TLN": {
        "name": "Toulon-Hyères Airport",
        "city": "Hyeres",
        "country": "France",
        "iata": "TLN",
        "icao": "LFTH",
        "latitude": "43.0973014832",
        "longitude": "6.14602994919",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "FNI": {
        "name": "Nîmes-Arles-Camargue Airport",
        "city": "Nimes",
        "country": "France",
        "iata": "FNI",
        "icao": "LFTW",
        "latitude": "43.75740051269531",
        "longitude": "4.4163498878479",
        "altitude": "309",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "MQC": {
        "name": "Miquelon Airport",
        "city": "Miquelon",
        "country": "Saint Pierre and Miquelon",
        "iata": "MQC",
        "icao": "LFVM",
        "latitude": "47.095500946",
        "longitude": "-56.3802986145",
        "altitude": "10",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Miquelon"
    },
    "FSP": {
        "name": "St Pierre Airport",
        "city": "St.-pierre",
        "country": "Saint Pierre and Miquelon",
        "iata": "FSP",
        "icao": "LFVP",
        "latitude": "46.762901306152344",
        "longitude": "-56.173099517822266",
        "altitude": "27",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Miquelon"
    },
    "PYR": {
        "name": "Andravida Air Base",
        "city": "Andravida",
        "country": "Greece",
        "iata": "PYR",
        "icao": "LGAD",
        "latitude": "37.9207",
        "longitude": "21.292601",
        "altitude": "55",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "AGQ": {
        "name": "Agrinion Air Base",
        "city": "Agrinion",
        "country": "Greece",
        "iata": "AGQ",
        "icao": "LGAG",
        "latitude": "38.602001",
        "longitude": "21.3512001",
        "altitude": "154",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "AXD": {
        "name": "Dimokritos Airport",
        "city": "Alexandroupolis",
        "country": "Greece",
        "iata": "AXD",
        "icao": "LGAL",
        "latitude": "40.855899810791016",
        "longitude": "25.956300735473633",
        "altitude": "24",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "VOL": {
        "name": "Nea Anchialos Airport",
        "city": "Nea Anghialos",
        "country": "Greece",
        "iata": "VOL",
        "icao": "LGBL",
        "latitude": "39.219600677490234",
        "longitude": "22.794300079345703",
        "altitude": "83",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JKH": {
        "name": "Chios Island National Airport",
        "city": "Chios",
        "country": "Greece",
        "iata": "JKH",
        "icao": "LGHI",
        "latitude": "38.34320068359375",
        "longitude": "26.140600204467773",
        "altitude": "15",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "IOA": {
        "name": "Ioannina Airport",
        "city": "Ioannina",
        "country": "Greece",
        "iata": "IOA",
        "icao": "LGIO",
        "latitude": "39.6963996887207",
        "longitude": "20.822500228881836",
        "altitude": "1558",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "HER": {
        "name": "Heraklion International Nikos Kazantzakis Airport",
        "city": "Heraklion",
        "country": "Greece",
        "iata": "HER",
        "icao": "LGIR",
        "latitude": "35.3396987915",
        "longitude": "25.180299758900002",
        "altitude": "115",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KSO": {
        "name": "Kastoria National Airport",
        "city": "Kastoria",
        "country": "Greece",
        "iata": "KSO",
        "icao": "LGKA",
        "latitude": "40.4463005066",
        "longitude": "21.2821998596",
        "altitude": "2167",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KIT": {
        "name": "Kithira Airport",
        "city": "Kithira",
        "country": "Greece",
        "iata": "KIT",
        "icao": "LGKC",
        "latitude": "36.2742996216",
        "longitude": "23.0170001984",
        "altitude": "1045",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "EFL": {
        "name": "Kefallinia Airport",
        "city": "Keffallinia",
        "country": "Greece",
        "iata": "EFL",
        "icao": "LGKF",
        "latitude": "38.12009811401367",
        "longitude": "20.500499725341797",
        "altitude": "59",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KLX": {
        "name": "Kalamata Airport",
        "city": "Kalamata",
        "country": "Greece",
        "iata": "KLX",
        "icao": "LGKL",
        "latitude": "37.06829833984375",
        "longitude": "22.02549934387207",
        "altitude": "26",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KGS": {
        "name": "Kos Airport",
        "city": "Kos",
        "country": "Greece",
        "iata": "KGS",
        "icao": "LGKO",
        "latitude": "36.79330062866211",
        "longitude": "27.091699600219727",
        "altitude": "412",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "AOK": {
        "name": "Karpathos Airport",
        "city": "Karpathos",
        "country": "Greece",
        "iata": "AOK",
        "icao": "LGKP",
        "latitude": "35.4213981628418",
        "longitude": "27.145999908447266",
        "altitude": "66",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "CFU": {
        "name": "Ioannis Kapodistrias International Airport",
        "city": "Kerkyra/corfu",
        "country": "Greece",
        "iata": "CFU",
        "icao": "LGKR",
        "latitude": "39.601898193359375",
        "longitude": "19.911699295043945",
        "altitude": "6",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KSJ": {
        "name": "Kasos Airport",
        "city": "Kasos",
        "country": "Greece",
        "iata": "KSJ",
        "icao": "LGKS",
        "latitude": "35.4213981628",
        "longitude": "26.909999847399998",
        "altitude": "35",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KVA": {
        "name": "Alexander the Great International Airport",
        "city": "Kavala",
        "country": "Greece",
        "iata": "KVA",
        "icao": "LGKV",
        "latitude": "40.913299560546875",
        "longitude": "24.619199752807617",
        "altitude": "18",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KZI": {
        "name": "Filippos Airport",
        "city": "Kozani",
        "country": "Greece",
        "iata": "KZI",
        "icao": "LGKZ",
        "latitude": "40.28609848022461",
        "longitude": "21.84079933166504",
        "altitude": "2059",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "LRS": {
        "name": "Leros Airport",
        "city": "Leros",
        "country": "Greece",
        "iata": "LRS",
        "icao": "LGLE",
        "latitude": "37.184898",
        "longitude": "26.800301",
        "altitude": "39",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "LXS": {
        "name": "Limnos Airport",
        "city": "Limnos",
        "country": "Greece",
        "iata": "LXS",
        "icao": "LGLM",
        "latitude": "39.917098999",
        "longitude": "25.236299514799995",
        "altitude": "14",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "LRA": {
        "name": "Larisa Airport",
        "city": "Larissa",
        "country": "Greece",
        "iata": "LRA",
        "icao": "LGLR",
        "latitude": "39.650253",
        "longitude": "22.4655",
        "altitude": "241",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JMK": {
        "name": "Mikonos Airport",
        "city": "Mykonos",
        "country": "Greece",
        "iata": "JMK",
        "icao": "LGMK",
        "latitude": "37.43510055541992",
        "longitude": "25.348100662231445",
        "altitude": "405",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "MJT": {
        "name": "Mytilene International Airport",
        "city": "Mytilini",
        "country": "Greece",
        "iata": "MJT",
        "icao": "LGMT",
        "latitude": "39.0567016602",
        "longitude": "26.5983009338",
        "altitude": "60",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "PVK": {
        "name": "Aktion National Airport",
        "city": "Preveza",
        "country": "Greece",
        "iata": "PVK",
        "icao": "LGPZ",
        "latitude": "38.925498962402344",
        "longitude": "20.765300750732422",
        "altitude": "11",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "RHO": {
        "name": "Diagoras Airport",
        "city": "Rhodos",
        "country": "Greece",
        "iata": "RHO",
        "icao": "LGRP",
        "latitude": "36.405399322509766",
        "longitude": "28.086200714111328",
        "altitude": "17",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "GPA": {
        "name": "Araxos Airport",
        "city": "Patras",
        "country": "Greece",
        "iata": "GPA",
        "icao": "LGRX",
        "latitude": "38.1511",
        "longitude": "21.4256",
        "altitude": "46",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "CHQ": {
        "name": "Chania International Airport",
        "city": "Chania",
        "country": "Greece",
        "iata": "CHQ",
        "icao": "LGSA",
        "latitude": "35.531700134277344",
        "longitude": "24.149700164794922",
        "altitude": "490",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JSI": {
        "name": "Skiathos Island National Airport",
        "city": "Skiathos",
        "country": "Greece",
        "iata": "JSI",
        "icao": "LGSK",
        "latitude": "39.177101135253906",
        "longitude": "23.503700256347656",
        "altitude": "54",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "SMI": {
        "name": "Samos Airport",
        "city": "Samos",
        "country": "Greece",
        "iata": "SMI",
        "icao": "LGSM",
        "latitude": "37.689998626708984",
        "longitude": "26.911699295043945",
        "altitude": "19",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "SPJ": {
        "name": "Sparti Airport",
        "city": "Sparti",
        "country": "Greece",
        "iata": "SPJ",
        "icao": "LGSP",
        "latitude": "36.973899841308594",
        "longitude": "22.52630043029785",
        "altitude": "500",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JTR": {
        "name": "Santorini Airport",
        "city": "Thira",
        "country": "Greece",
        "iata": "JTR",
        "icao": "LGSR",
        "latitude": "36.399200439453125",
        "longitude": "25.479299545288086",
        "altitude": "127",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JSH": {
        "name": "Sitia Airport",
        "city": "Sitia",
        "country": "Greece",
        "iata": "JSH",
        "icao": "LGST",
        "latitude": "35.21609878540039",
        "longitude": "26.101299285888672",
        "altitude": "376",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "SKU": {
        "name": "Skiros Airport",
        "city": "Skiros",
        "country": "Greece",
        "iata": "SKU",
        "icao": "LGSY",
        "latitude": "38.9676017761",
        "longitude": "24.4871997833",
        "altitude": "44",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "SKG": {
        "name": "Thessaloniki Macedonia International Airport",
        "city": "Thessaloniki",
        "country": "Greece",
        "iata": "SKG",
        "icao": "LGTS",
        "latitude": "40.51969909667969",
        "longitude": "22.97089958190918",
        "altitude": "22",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "ZTH": {
        "name": "Zakynthos International Airport \"Dionysios Solomos\"",
        "city": "Zakynthos",
        "country": "Greece",
        "iata": "ZTH",
        "icao": "LGZA",
        "latitude": "37.7509",
        "longitude": "20.8843",
        "altitude": "15",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "BUD": {
        "name": "Budapest Liszt Ferenc International Airport",
        "city": "Budapest",
        "country": "Hungary",
        "iata": "BUD",
        "icao": "LHBP",
        "latitude": "47.42976",
        "longitude": "19.261093",
        "altitude": "495",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Budapest"
    },
    "DEB": {
        "name": "Debrecen International Airport",
        "city": "Debrecen",
        "country": "Hungary",
        "iata": "DEB",
        "icao": "LHDC",
        "latitude": "47.48889923095703",
        "longitude": "21.615299224853516",
        "altitude": "359",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Budapest"
    },
    "CRV": {
        "name": "Crotone Airport",
        "city": "Crotone",
        "country": "Italy",
        "iata": "CRV",
        "icao": "LIBC",
        "latitude": "38.9972",
        "longitude": "17.0802",
        "altitude": "522",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "BRI": {
        "name": "Bari Karol Wojtyła Airport",
        "city": "Bari",
        "country": "Italy",
        "iata": "BRI",
        "icao": "LIBD",
        "latitude": "41.138901",
        "longitude": "16.760599",
        "altitude": "177",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "FOG": {
        "name": "Foggia \"Gino Lisa\" Airport",
        "city": "Foggia",
        "country": "Italy",
        "iata": "FOG",
        "icao": "LIBF",
        "latitude": "41.432899",
        "longitude": "15.535",
        "altitude": "265",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TAR": {
        "name": "Taranto-Grottaglie \"Marcello Arlotta\" Airport",
        "city": "Grottaglie",
        "country": "Italy",
        "iata": "TAR",
        "icao": "LIBG",
        "latitude": "40.517502",
        "longitude": "17.4032",
        "altitude": "215",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "LCC": {
        "name": "Lecce Galatina Air Base",
        "city": "Lecce",
        "country": "Italy",
        "iata": "LCC",
        "icao": "LIBN",
        "latitude": "40.239201",
        "longitude": "18.133301",
        "altitude": "156",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "PSR": {
        "name": "Pescara International Airport",
        "city": "Pescara",
        "country": "Italy",
        "iata": "PSR",
        "icao": "LIBP",
        "latitude": "42.431702",
        "longitude": "14.1811",
        "altitude": "48",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "BDS": {
        "name": "Brindisi – Salento Airport",
        "city": "Brindisi",
        "country": "Italy",
        "iata": "BDS",
        "icao": "LIBR",
        "latitude": "40.6576",
        "longitude": "17.947001",
        "altitude": "47",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "SUF": {
        "name": "Lamezia Terme Airport",
        "city": "Lamezia",
        "country": "Italy",
        "iata": "SUF",
        "icao": "LICA",
        "latitude": "38.905399",
        "longitude": "16.2423",
        "altitude": "39",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "CTA": {
        "name": "Catania-Fontanarossa Airport",
        "city": "Catania",
        "country": "Italy",
        "iata": "CTA",
        "icao": "LICC",
        "latitude": "37.466801",
        "longitude": "15.0664",
        "altitude": "39",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "LMP": {
        "name": "Lampedusa Airport",
        "city": "Lampedusa",
        "country": "Italy",
        "iata": "LMP",
        "icao": "LICD",
        "latitude": "35.497898",
        "longitude": "12.6181",
        "altitude": "70",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "PNL": {
        "name": "Pantelleria Airport",
        "city": "Pantelleria",
        "country": "Italy",
        "iata": "PNL",
        "icao": "LICG",
        "latitude": "36.816502",
        "longitude": "11.9689",
        "altitude": "628",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "PMO": {
        "name": "Falcone–Borsellino Airport",
        "city": "Palermo",
        "country": "Italy",
        "iata": "PMO",
        "icao": "LICJ",
        "latitude": "38.175999",
        "longitude": "13.091",
        "altitude": "65",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "REG": {
        "name": "Reggio Calabria Airport",
        "city": "Reggio Calabria",
        "country": "Italy",
        "iata": "REG",
        "icao": "LICR",
        "latitude": "38.071201",
        "longitude": "15.6516",
        "altitude": "96",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TPS": {
        "name": "Vincenzo Florio Airport Trapani-Birgi",
        "city": "Trapani",
        "country": "Italy",
        "iata": "TPS",
        "icao": "LICT",
        "latitude": "37.9114",
        "longitude": "12.488",
        "altitude": "25",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "NSY": {
        "name": "Sigonella Navy Air Base",
        "city": "Sigonella",
        "country": "Italy",
        "iata": "NSY",
        "icao": "LICZ",
        "latitude": "37.401699",
        "longitude": "14.9224",
        "altitude": "79",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "AHO": {
        "name": "Alghero-Fertilia Airport",
        "city": "Alghero",
        "country": "Italy",
        "iata": "AHO",
        "icao": "LIEA",
        "latitude": "40.632099",
        "longitude": "8.29077",
        "altitude": "87",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "DCI": {
        "name": "Decimomannu Air Base",
        "city": "Decimomannu",
        "country": "Italy",
        "iata": "DCI",
        "icao": "LIED",
        "latitude": "39.354198",
        "longitude": "8.97248",
        "altitude": "100",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "CAG": {
        "name": "Cagliari Elmas Airport",
        "city": "Cagliari",
        "country": "Italy",
        "iata": "CAG",
        "icao": "LIEE",
        "latitude": "39.251499",
        "longitude": "9.05428",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "OLB": {
        "name": "Olbia Costa Smeralda Airport",
        "city": "Olbia",
        "country": "Italy",
        "iata": "OLB",
        "icao": "LIEO",
        "latitude": "40.898701",
        "longitude": "9.51763",
        "altitude": "37",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TTB": {
        "name": "Tortolì Airport",
        "city": "Tortoli",
        "country": "Italy",
        "iata": "TTB",
        "icao": "LIET",
        "latitude": "39.9188",
        "longitude": "9.68298",
        "altitude": "23",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "MXP": {
        "name": "Malpensa International Airport",
        "city": "Milano",
        "country": "Italy",
        "iata": "MXP",
        "icao": "LIMC",
        "latitude": "45.6306",
        "longitude": "8.72811",
        "altitude": "768",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "BGY": {
        "name": "Il Caravaggio International Airport",
        "city": "Bergamo",
        "country": "Italy",
        "iata": "BGY",
        "icao": "LIME",
        "latitude": "45.673901",
        "longitude": "9.70417",
        "altitude": "782",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TRN": {
        "name": "Turin Airport",
        "city": "Torino",
        "country": "Italy",
        "iata": "TRN",
        "icao": "LIMF",
        "latitude": "45.200802",
        "longitude": "7.64963",
        "altitude": "989",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "ALL": {
        "name": "Villanova D'Albenga International Airport",
        "city": "Albenga",
        "country": "Italy",
        "iata": "ALL",
        "icao": "LIMG",
        "latitude": "44.050598",
        "longitude": "8.12743",
        "altitude": "148",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "GOA": {
        "name": "Genoa Cristoforo Colombo Airport",
        "city": "Genoa",
        "country": "Italy",
        "iata": "GOA",
        "icao": "LIMJ",
        "latitude": "44.4133",
        "longitude": "8.8375",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "LIN": {
        "name": "Milano Linate Airport",
        "city": "Milan",
        "country": "Italy",
        "iata": "LIN",
        "icao": "LIML",
        "latitude": "45.445099",
        "longitude": "9.27674",
        "altitude": "353",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "PMF": {
        "name": "Parma Airport",
        "city": "Parma",
        "country": "Italy",
        "iata": "PMF",
        "icao": "LIMP",
        "latitude": "44.824501",
        "longitude": "10.2964",
        "altitude": "161",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "CUF": {
        "name": "Cuneo International Airport",
        "city": "Cuneo",
        "country": "Italy",
        "iata": "CUF",
        "icao": "LIMZ",
        "latitude": "44.547001",
        "longitude": "7.62322",
        "altitude": "1267",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "AVB": {
        "name": "Aviano Air Base",
        "city": "Aviano",
        "country": "Italy",
        "iata": "AVB",
        "icao": "LIPA",
        "latitude": "46.031898",
        "longitude": "12.596503",
        "altitude": "410",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "BZO": {
        "name": "Bolzano Airport",
        "city": "Bolzano",
        "country": "Italy",
        "iata": "BZO",
        "icao": "LIPB",
        "latitude": "46.460201",
        "longitude": "11.3264",
        "altitude": "789",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "BLQ": {
        "name": "Bologna Guglielmo Marconi Airport",
        "city": "Bologna",
        "country": "Italy",
        "iata": "BLQ",
        "icao": "LIPE",
        "latitude": "44.5354",
        "longitude": "11.2887",
        "altitude": "123",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TSF": {
        "name": "Treviso-Sant'Angelo Airport",
        "city": "Treviso",
        "country": "Italy",
        "iata": "TSF",
        "icao": "LIPH",
        "latitude": "45.648399",
        "longitude": "12.1944",
        "altitude": "59",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "FRL": {
        "name": "Forlì Airport",
        "city": "Forli",
        "country": "Italy",
        "iata": "FRL",
        "icao": "LIPK",
        "latitude": "44.194801",
        "longitude": "12.0701",
        "altitude": "97",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "VBS": {
        "name": "Brescia Airport",
        "city": "Brescia",
        "country": "Italy",
        "iata": "VBS",
        "icao": "LIPO",
        "latitude": "45.428902",
        "longitude": "10.3306",
        "altitude": "355",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TRS": {
        "name": "Trieste–Friuli Venezia Giulia Airport",
        "city": "Ronchi De Legionari",
        "country": "Italy",
        "iata": "TRS",
        "icao": "LIPQ",
        "latitude": "45.827499",
        "longitude": "13.4722",
        "altitude": "39",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "RMI": {
        "name": "Federico Fellini International Airport",
        "city": "Rimini",
        "country": "Italy",
        "iata": "RMI",
        "icao": "LIPR",
        "latitude": "44.020302",
        "longitude": "12.6117",
        "altitude": "40",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "VIC": {
        "name": "Vicenza Airport",
        "city": "Vicenza",
        "country": "Italy",
        "iata": "VIC",
        "icao": "LIPT",
        "latitude": "45.573399",
        "longitude": "11.5295",
        "altitude": "128",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "QPA": {
        "name": "Padova Airport",
        "city": "Padova",
        "country": "Italy",
        "iata": "QPA",
        "icao": "LIPU",
        "latitude": "45.395802",
        "longitude": "11.8479",
        "altitude": "44",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "VRN": {
        "name": "Verona Villafranca Airport",
        "city": "Villafranca",
        "country": "Italy",
        "iata": "VRN",
        "icao": "LIPX",
        "latitude": "45.395699",
        "longitude": "10.8885",
        "altitude": "239",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "VCE": {
        "name": "Venice Marco Polo Airport",
        "city": "Venice",
        "country": "Italy",
        "iata": "VCE",
        "icao": "LIPZ",
        "latitude": "45.505299",
        "longitude": "12.3519",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "SAY": {
        "name": "Siena-Ampugnano Airport",
        "city": "Siena",
        "country": "Italy",
        "iata": "SAY",
        "icao": "LIQS",
        "latitude": "43.256302",
        "longitude": "11.255",
        "altitude": "634",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "CIA": {
        "name": "Ciampino–G. B. Pastine International Airport",
        "city": "Rome",
        "country": "Italy",
        "iata": "CIA",
        "icao": "LIRA",
        "latitude": "41.7994",
        "longitude": "12.5949",
        "altitude": "427",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "FCO": {
        "name": "Leonardo da Vinci–Fiumicino Airport",
        "city": "Rome",
        "country": "Italy",
        "iata": "FCO",
        "icao": "LIRF",
        "latitude": "41.8002778",
        "longitude": "12.2388889",
        "altitude": "13",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "EBA": {
        "name": "Marina Di Campo Airport",
        "city": "Marina Di Campo",
        "country": "Italy",
        "iata": "EBA",
        "icao": "LIRJ",
        "latitude": "42.7603",
        "longitude": "10.2394",
        "altitude": "31",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "QLT": {
        "name": "Latina Air Base",
        "city": "Latina",
        "country": "Italy",
        "iata": "QLT",
        "icao": "LIRL",
        "latitude": "41.5424",
        "longitude": "12.909",
        "altitude": "94",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "NAP": {
        "name": "Naples International Airport",
        "city": "Naples",
        "country": "Italy",
        "iata": "NAP",
        "icao": "LIRN",
        "latitude": "40.886002",
        "longitude": "14.2908",
        "altitude": "294",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "PSA": {
        "name": "Pisa International Airport",
        "city": "Pisa",
        "country": "Italy",
        "iata": "PSA",
        "icao": "LIRP",
        "latitude": "43.683899",
        "longitude": "10.3927",
        "altitude": "6",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "FLR": {
        "name": "Peretola Airport",
        "city": "Florence",
        "country": "Italy",
        "iata": "FLR",
        "icao": "LIRQ",
        "latitude": "43.810001",
        "longitude": "11.2051",
        "altitude": "142",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "GRS": {
        "name": "Grosseto Air Base",
        "city": "Grosseto",
        "country": "Italy",
        "iata": "GRS",
        "icao": "LIRS",
        "latitude": "42.759701",
        "longitude": "11.0719",
        "altitude": "17",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "PEG": {
        "name": "Perugia San Francesco d'Assisi – Umbria International Airport",
        "city": "Perugia",
        "country": "Italy",
        "iata": "PEG",
        "icao": "LIRZ",
        "latitude": "43.095901",
        "longitude": "12.5132",
        "altitude": "697",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "LJU": {
        "name": "Ljubljana Jože Pučnik Airport",
        "city": "Ljubljana",
        "country": "Slovenia",
        "iata": "LJU",
        "icao": "LJLJ",
        "latitude": "46.223701",
        "longitude": "14.4576",
        "altitude": "1273",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Ljubljana"
    },
    "MBX": {
        "name": "Maribor Airport",
        "city": "Maribor",
        "country": "Slovenia",
        "iata": "MBX",
        "icao": "LJMB",
        "latitude": "46.47990036010742",
        "longitude": "15.686100006103516",
        "altitude": "876",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Ljubljana"
    },
    "POW": {
        "name": "Portoroz Airport",
        "city": "Portoroz",
        "country": "Slovenia",
        "iata": "POW",
        "icao": "LJPZ",
        "latitude": "45.4734001159668",
        "longitude": "13.614999771118164",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Ljubljana"
    },
    "UHE": {
        "name": "Kunovice Airport",
        "city": "Kunovice",
        "country": "Czech Republic",
        "iata": "UHE",
        "icao": "LKKU",
        "latitude": "49.02939987182617",
        "longitude": "17.439699172973633",
        "altitude": "581",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "KLV": {
        "name": "Karlovy Vary International Airport",
        "city": "Karlovy Vary",
        "country": "Czech Republic",
        "iata": "KLV",
        "icao": "LKKV",
        "latitude": "50.202999114990234",
        "longitude": "12.914999961853027",
        "altitude": "1989",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "OSR": {
        "name": "Ostrava Leos Janáček Airport",
        "city": "Ostrava",
        "country": "Czech Republic",
        "iata": "OSR",
        "icao": "LKMT",
        "latitude": "49.6963005065918",
        "longitude": "18.111099243164062",
        "altitude": "844",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "PED": {
        "name": "Pardubice Airport",
        "city": "Pardubice",
        "country": "Czech Republic",
        "iata": "PED",
        "icao": "LKPD",
        "latitude": "50.01340103149414",
        "longitude": "15.73859977722168",
        "altitude": "741",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "PRV": {
        "name": "Přerov Air Base",
        "city": "Prerov",
        "country": "Czech Republic",
        "iata": "PRV",
        "icao": "LKPO",
        "latitude": "49.42580032348633",
        "longitude": "17.404699325561523",
        "altitude": "676",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "PRG": {
        "name": "Václav Havel Airport Prague",
        "city": "Prague",
        "country": "Czech Republic",
        "iata": "PRG",
        "icao": "LKPR",
        "latitude": "50.1008",
        "longitude": "14.26",
        "altitude": "1247",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "BRQ": {
        "name": "Brno-Tuřany Airport",
        "city": "Brno",
        "country": "Czech Republic",
        "iata": "BRQ",
        "icao": "LKTB",
        "latitude": "49.15129852294922",
        "longitude": "16.694400787353516",
        "altitude": "778",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "VOD": {
        "name": "Vodochody Airport",
        "city": "Vodochody",
        "country": "Czech Republic",
        "iata": "VOD",
        "icao": "LKVO",
        "latitude": "50.216599",
        "longitude": "14.3958",
        "altitude": "919",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Prague"
    },
    "TLV": {
        "name": "Ben Gurion International Airport",
        "city": "Tel-aviv",
        "country": "Israel",
        "iata": "TLV",
        "icao": "LLBG",
        "latitude": "32.01139831542969",
        "longitude": "34.88669967651367",
        "altitude": "135",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "BEV": {
        "name": "Beersheba (Teyman) Airport",
        "city": "Beer-sheba",
        "country": "Israel",
        "iata": "BEV",
        "icao": "LLBS",
        "latitude": "31.287000656128",
        "longitude": "34.722999572754",
        "altitude": "656",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "ETH": {
        "name": "Eilat Airport",
        "city": "Elat",
        "country": "Israel",
        "iata": "ETH",
        "icao": "LLET",
        "latitude": "29.56130027770996",
        "longitude": "34.96009826660156",
        "altitude": "42",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "EIY": {
        "name": "Ein Yahav Airfield",
        "city": "Eyn-yahav",
        "country": "Israel",
        "iata": "EIY",
        "icao": "LLEY",
        "latitude": "30.621700286865234",
        "longitude": "35.20330047607422",
        "altitude": "-164",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "HFA": {
        "name": "Haifa International Airport",
        "city": "Haifa",
        "country": "Israel",
        "iata": "HFA",
        "icao": "LLHA",
        "latitude": "32.80939865112305",
        "longitude": "35.04309844970703",
        "altitude": "28",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "RPN": {
        "name": "Ben Ya'akov Airport",
        "city": "Rosh Pina",
        "country": "Israel",
        "iata": "RPN",
        "icao": "LLIB",
        "latitude": "32.98099899291992",
        "longitude": "35.5718994140625",
        "altitude": "922",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "MTZ": {
        "name": "Bar Yehuda Airfield",
        "city": "Metzada",
        "country": "Israel",
        "iata": "MTZ",
        "icao": "LLMZ",
        "latitude": "31.32819938659668",
        "longitude": "35.38859939575195",
        "altitude": "-1266",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "VTM": {
        "name": "Nevatim Air Base",
        "city": "Nevatim",
        "country": "Israel",
        "iata": "VTM",
        "icao": "LLNV",
        "latitude": "31.208299636799996",
        "longitude": "35.012298584",
        "altitude": "1330",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "VDA": {
        "name": "Ovda International Airport",
        "city": "Ovda",
        "country": "Israel",
        "iata": "VDA",
        "icao": "LLOV",
        "latitude": "29.94029998779297",
        "longitude": "34.93579864501953",
        "altitude": "1492",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "MIP": {
        "name": "Ramon Air Base",
        "city": "Ramon",
        "country": "Israel",
        "iata": "MIP",
        "icao": "LLRM",
        "latitude": "30.776100158691406",
        "longitude": "34.66669845581055",
        "altitude": "2126",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "SDV": {
        "name": "Sde Dov Airport",
        "city": "Tel-aviv",
        "country": "Israel",
        "iata": "SDV",
        "icao": "LLSD",
        "latitude": "32.11470031738281",
        "longitude": "34.78219985961914",
        "altitude": "43",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Jerusalem"
    },
    "MLA": {
        "name": "Malta International Airport",
        "city": "Malta",
        "country": "Malta",
        "iata": "MLA",
        "icao": "LMML",
        "latitude": "35.857498",
        "longitude": "14.4775",
        "altitude": "300",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Malta"
    },
    "GRZ": {
        "name": "Graz Airport",
        "city": "Graz",
        "country": "Austria",
        "iata": "GRZ",
        "icao": "LOWG",
        "latitude": "46.9911003112793",
        "longitude": "15.439599990844727",
        "altitude": "1115",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "INN": {
        "name": "Innsbruck Airport",
        "city": "Innsbruck",
        "country": "Austria",
        "iata": "INN",
        "icao": "LOWI",
        "latitude": "47.260201",
        "longitude": "11.344",
        "altitude": "1907",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "LNZ": {
        "name": "Linz Hörsching Airport",
        "city": "Linz",
        "country": "Austria",
        "iata": "LNZ",
        "icao": "LOWL",
        "latitude": "48.2332",
        "longitude": "14.1875",
        "altitude": "980",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "SZG": {
        "name": "Salzburg Airport",
        "city": "Salzburg",
        "country": "Austria",
        "iata": "SZG",
        "icao": "LOWS",
        "latitude": "47.793300628699996",
        "longitude": "13.0043001175",
        "altitude": "1411",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "VIE": {
        "name": "Vienna International Airport",
        "city": "Vienna",
        "country": "Austria",
        "iata": "VIE",
        "icao": "LOWW",
        "latitude": "48.110298156738",
        "longitude": "16.569700241089",
        "altitude": "600",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "AVR": {
        "name": "Alverca Air Base",
        "city": "Alverca",
        "country": "Portugal",
        "iata": "AVR",
        "icao": "LPAR",
        "latitude": "38.883301",
        "longitude": "-9.0301",
        "altitude": "11",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "SMA": {
        "name": "Santa Maria Airport",
        "city": "Santa Maria (island)",
        "country": "Portugal",
        "iata": "SMA",
        "icao": "LPAZ",
        "latitude": "36.97140121459961",
        "longitude": "-25.17060089111328",
        "altitude": "308",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "BGC": {
        "name": "Bragança Airport",
        "city": "Braganca",
        "country": "Portugal",
        "iata": "BGC",
        "icao": "LPBG",
        "latitude": "41.8578",
        "longitude": "-6.70713",
        "altitude": "2241",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "BYJ": {
        "name": "Beja Airport / Airbase",
        "city": "Beja (madeira)",
        "country": "Portugal",
        "iata": "BYJ",
        "icao": "LPBJ",
        "latitude": "38.078899",
        "longitude": "-7.9324",
        "altitude": "636",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "BGZ": {
        "name": "Braga Municipal Aerodrome",
        "city": "Braga",
        "country": "Portugal",
        "iata": "BGZ",
        "icao": "LPBR",
        "latitude": "41.5871009827",
        "longitude": "-8.445139884949999",
        "altitude": "247",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "CAT": {
        "name": "Cascais Airport",
        "city": "Cascais",
        "country": "Portugal",
        "iata": "CAT",
        "icao": "LPCS",
        "latitude": "38.724998",
        "longitude": "-9.35523",
        "altitude": "325",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "FLW": {
        "name": "Flores Airport",
        "city": "Flores",
        "country": "Portugal",
        "iata": "FLW",
        "icao": "LPFL",
        "latitude": "39.455299377441406",
        "longitude": "-31.131399154663086",
        "altitude": "112",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "FAO": {
        "name": "Faro Airport",
        "city": "Faro",
        "country": "Portugal",
        "iata": "FAO",
        "icao": "LPFR",
        "latitude": "37.0144004822",
        "longitude": "-7.96590995789",
        "altitude": "24",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "GRW": {
        "name": "Graciosa Airport",
        "city": "Graciosa Island",
        "country": "Portugal",
        "iata": "GRW",
        "icao": "LPGR",
        "latitude": "39.092201232910156",
        "longitude": "-28.029800415039062",
        "altitude": "86",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "HOR": {
        "name": "Horta Airport",
        "city": "Horta",
        "country": "Portugal",
        "iata": "HOR",
        "icao": "LPHR",
        "latitude": "38.519901275634766",
        "longitude": "-28.715900421142578",
        "altitude": "118",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "TER": {
        "name": "Lajes Airport",
        "city": "Lajes (terceira Island)",
        "country": "Portugal",
        "iata": "TER",
        "icao": "LPLA",
        "latitude": "38.761799",
        "longitude": "-27.090799",
        "altitude": "180",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "QLR": {
        "name": "Monte Real Air Base",
        "city": "Monte Real",
        "country": "Portugal",
        "iata": "QLR",
        "icao": "LPMR",
        "latitude": "39.828335",
        "longitude": "-8.8875",
        "altitude": "187",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "PDL": {
        "name": "João Paulo II Airport",
        "city": "Ponta Delgada",
        "country": "Portugal",
        "iata": "PDL",
        "icao": "LPPD",
        "latitude": "37.7411994934",
        "longitude": "-25.6979007721",
        "altitude": "259",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "PIX": {
        "name": "Pico Airport",
        "city": "Pico",
        "country": "Portugal",
        "iata": "PIX",
        "icao": "LPPI",
        "latitude": "38.554298",
        "longitude": "-28.441299",
        "altitude": "109",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "PRM": {
        "name": "Portimão Airport",
        "city": "Portimao",
        "country": "Portugal",
        "iata": "PRM",
        "icao": "LPPM",
        "latitude": "37.1493",
        "longitude": "-8.58396",
        "altitude": "5",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "OPO": {
        "name": "Francisco de Sá Carneiro Airport",
        "city": "Porto",
        "country": "Portugal",
        "iata": "OPO",
        "icao": "LPPR",
        "latitude": "41.2481002808",
        "longitude": "-8.68138980865",
        "altitude": "228",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "PXO": {
        "name": "Porto Santo Airport",
        "city": "Porto Santo",
        "country": "Portugal",
        "iata": "PXO",
        "icao": "LPPS",
        "latitude": "33.0733985901",
        "longitude": "-16.3500003815",
        "altitude": "341",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "LIS": {
        "name": "Humberto Delgado Airport (Lisbon Portela Airport)",
        "city": "Lisboa",
        "country": "Portugal",
        "iata": "LIS",
        "icao": "LPPT",
        "latitude": "38.7813",
        "longitude": "-9.13592",
        "altitude": "374",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "SJZ": {
        "name": "São Jorge Airport",
        "city": "Sao Jorge Island",
        "country": "Portugal",
        "iata": "SJZ",
        "icao": "LPSJ",
        "latitude": "38.66550064086914",
        "longitude": "-28.175800323486328",
        "altitude": "311",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "VRL": {
        "name": "Vila Real Airport",
        "city": "Vila Real",
        "country": "Portugal",
        "iata": "VRL",
        "icao": "LPVR",
        "latitude": "41.2743",
        "longitude": "-7.72047",
        "altitude": "1805",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "VSE": {
        "name": "Aerodromo Goncalves Lobato (Viseu Airport)",
        "city": "Viseu",
        "country": "Portugal",
        "iata": "VSE",
        "icao": "LPVZ",
        "latitude": "40.725498",
        "longitude": "-7.88899",
        "altitude": "2060",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "OMO": {
        "name": "Mostar International Airport",
        "city": "Mostar",
        "country": "Bosnia and Herzegovina",
        "iata": "OMO",
        "icao": "LQMO",
        "latitude": "43.282901763916016",
        "longitude": "17.84589958190918",
        "altitude": "156",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Sarajevo"
    },
    "SJJ": {
        "name": "Sarajevo International Airport",
        "city": "Sarajevo",
        "country": "Bosnia and Herzegovina",
        "iata": "SJJ",
        "icao": "LQSA",
        "latitude": "43.82460021972656",
        "longitude": "18.331499099731445",
        "altitude": "1708",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Sarajevo"
    },
    "ARW": {
        "name": "Arad International Airport",
        "city": "Arad",
        "country": "Romania",
        "iata": "ARW",
        "icao": "LRAR",
        "latitude": "46.17660140991211",
        "longitude": "21.261999130249023",
        "altitude": "352",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "BCM": {
        "name": "Bacău Airport",
        "city": "Bacau",
        "country": "Romania",
        "iata": "BCM",
        "icao": "LRBC",
        "latitude": "46.52190017700195",
        "longitude": "26.91029930114746",
        "altitude": "607",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "BAY": {
        "name": "Tautii Magheraus Airport",
        "city": "Baia Mare",
        "country": "Romania",
        "iata": "BAY",
        "icao": "LRBM",
        "latitude": "47.65840148925781",
        "longitude": "23.469999313354492",
        "altitude": "605",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "BBU": {
        "name": "Băneasa International Airport",
        "city": "Bucharest",
        "country": "Romania",
        "iata": "BBU",
        "icao": "LRBS",
        "latitude": "44.50320053100586",
        "longitude": "26.102100372314453",
        "altitude": "297",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "CND": {
        "name": "Mihail Kogălniceanu International Airport",
        "city": "Constanta",
        "country": "Romania",
        "iata": "CND",
        "icao": "LRCK",
        "latitude": "44.36220169067383",
        "longitude": "28.488300323486328",
        "altitude": "353",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "CLJ": {
        "name": "Cluj-Napoca International Airport",
        "city": "Cluj-napoca",
        "country": "Romania",
        "iata": "CLJ",
        "icao": "LRCL",
        "latitude": "46.78519821166992",
        "longitude": "23.686199188232422",
        "altitude": "1036",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "CSB": {
        "name": "Caransebeş Airport",
        "city": "Caransebes",
        "country": "Romania",
        "iata": "CSB",
        "icao": "LRCS",
        "latitude": "45.41999816894531",
        "longitude": "22.253299713134766",
        "altitude": "866",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "CRA": {
        "name": "Craiova Airport",
        "city": "Craiova",
        "country": "Romania",
        "iata": "CRA",
        "icao": "LRCV",
        "latitude": "44.31809997558594",
        "longitude": "23.888599395751953",
        "altitude": "626",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "IAS": {
        "name": "Iaşi Airport",
        "city": "Iasi",
        "country": "Romania",
        "iata": "IAS",
        "icao": "LRIA",
        "latitude": "47.17850112915039",
        "longitude": "27.6205997467041",
        "altitude": "397",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "OMR": {
        "name": "Oradea International Airport",
        "city": "Oradea",
        "country": "Romania",
        "iata": "OMR",
        "icao": "LROD",
        "latitude": "47.025299072265625",
        "longitude": "21.90250015258789",
        "altitude": "465",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "OTP": {
        "name": "Henri Coandă International Airport",
        "city": "Bucharest",
        "country": "Romania",
        "iata": "OTP",
        "icao": "LROP",
        "latitude": "44.5711111",
        "longitude": "26.085",
        "altitude": "314",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "SBZ": {
        "name": "Sibiu International Airport",
        "city": "Sibiu",
        "country": "Romania",
        "iata": "SBZ",
        "icao": "LRSB",
        "latitude": "45.78559875488281",
        "longitude": "24.091299057006836",
        "altitude": "1496",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "SUJ": {
        "name": "Satu Mare Airport",
        "city": "Satu Mare",
        "country": "Romania",
        "iata": "SUJ",
        "icao": "LRSM",
        "latitude": "47.70330047607422",
        "longitude": "22.885700225830078",
        "altitude": "405",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "SCV": {
        "name": "Suceava Stefan cel Mare Airport",
        "city": "Suceava",
        "country": "Romania",
        "iata": "SCV",
        "icao": "LRSV",
        "latitude": "47.6875",
        "longitude": "26.35409927368164",
        "altitude": "1375",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "TCE": {
        "name": "Tulcea Airport",
        "city": "Tulcea",
        "country": "Romania",
        "iata": "TCE",
        "icao": "LRTC",
        "latitude": "45.0625",
        "longitude": "28.71430015563965",
        "altitude": "200",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "TGM": {
        "name": "Transilvania Târgu Mureş International Airport",
        "city": "Tirgu Mures",
        "country": "Romania",
        "iata": "TGM",
        "icao": "LRTM",
        "latitude": "46.46770095825195",
        "longitude": "24.412500381469727",
        "altitude": "963",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "TSR": {
        "name": "Timişoara Traian Vuia Airport",
        "city": "Timisoara",
        "country": "Romania",
        "iata": "TSR",
        "icao": "LRTR",
        "latitude": "45.809898376464844",
        "longitude": "21.337900161743164",
        "altitude": "348",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Bucharest"
    },
    "GVA": {
        "name": "Geneva Cointrin International Airport",
        "city": "Geneva",
        "country": "Switzerland",
        "iata": "GVA",
        "icao": "LSGG",
        "latitude": "46.23809814453125",
        "longitude": "6.108950138092041",
        "altitude": "1411",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SIR": {
        "name": "Sion Airport",
        "city": "Sion",
        "country": "Switzerland",
        "iata": "SIR",
        "icao": "LSGS",
        "latitude": "46.219600677500004",
        "longitude": "7.326759815220001",
        "altitude": "1585",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "EML": {
        "name": "Emmen Air Base",
        "city": "Emmen",
        "country": "Switzerland",
        "iata": "EML",
        "icao": "LSME",
        "latitude": "47.092444",
        "longitude": "8.305184",
        "altitude": "1400",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "LUG": {
        "name": "Lugano Airport",
        "city": "Lugano",
        "country": "Switzerland",
        "iata": "LUG",
        "icao": "LSZA",
        "latitude": "46.00429916379999",
        "longitude": "8.9105796814",
        "altitude": "915",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "BRN": {
        "name": "Bern Belp Airport",
        "city": "Bern",
        "country": "Switzerland",
        "iata": "BRN",
        "icao": "LSZB",
        "latitude": "46.914100647",
        "longitude": "7.497149944309999",
        "altitude": "1674",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "ZHI": {
        "name": "Grenchen Airport",
        "city": "Grenchen",
        "country": "Switzerland",
        "iata": "ZHI",
        "icao": "LSZG",
        "latitude": "47.181599",
        "longitude": "7.41719",
        "altitude": "1411",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "ZRH": {
        "name": "Zürich Airport",
        "city": "Zurich",
        "country": "Switzerland",
        "iata": "ZRH",
        "icao": "LSZH",
        "latitude": "47.464699",
        "longitude": "8.54917",
        "altitude": "1416",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "ACH": {
        "name": "St Gallen Altenrhein Airport",
        "city": "Altenrhein",
        "country": "Switzerland",
        "iata": "ACH",
        "icao": "LSZR",
        "latitude": "47.4850006104",
        "longitude": "9.560770034789998",
        "altitude": "1306",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "SMV": {
        "name": "Samedan Airport",
        "city": "Samedan",
        "country": "Switzerland",
        "iata": "SMV",
        "icao": "LSZS",
        "latitude": "46.53409957885742",
        "longitude": "9.884110450744629",
        "altitude": "5600",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "ESB": {
        "name": "Esenboğa International Airport",
        "city": "Ankara",
        "country": "Turkey",
        "iata": "ESB",
        "icao": "LTAC",
        "latitude": "40.128101348899996",
        "longitude": "32.995098114",
        "altitude": "3125",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ANK": {
        "name": "Etimesgut Air Base",
        "city": "Ankara",
        "country": "Turkey",
        "iata": "ANK",
        "icao": "LTAD",
        "latitude": "39.949798584",
        "longitude": "32.6885986328",
        "altitude": "2653",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ADA": {
        "name": "Adana Airport",
        "city": "Adana",
        "country": "Turkey",
        "iata": "ADA",
        "icao": "LTAF",
        "latitude": "36.9822006226",
        "longitude": "35.280399322499996",
        "altitude": "65",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "UAB": {
        "name": "İncirlik Air Base",
        "city": "Adana",
        "country": "Turkey",
        "iata": "UAB",
        "icao": "LTAG",
        "latitude": "37.002101898199996",
        "longitude": "35.4258995056",
        "altitude": "238",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "AFY": {
        "name": "Afyon Airport",
        "city": "Afyon",
        "country": "Turkey",
        "iata": "AFY",
        "icao": "LTAH",
        "latitude": "38.726398468",
        "longitude": "30.6011009216",
        "altitude": "3310",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "AYT": {
        "name": "Antalya International Airport",
        "city": "Antalya",
        "country": "Turkey",
        "iata": "AYT",
        "icao": "LTAI",
        "latitude": "36.898701",
        "longitude": "30.800501",
        "altitude": "177",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "GZT": {
        "name": "Gaziantep International Airport",
        "city": "Gaziantep",
        "country": "Turkey",
        "iata": "GZT",
        "icao": "LTAJ",
        "latitude": "36.9472007751",
        "longitude": "37.4786987305",
        "altitude": "2315",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "KYA": {
        "name": "Konya Airport",
        "city": "Konya",
        "country": "Turkey",
        "iata": "KYA",
        "icao": "LTAN",
        "latitude": "37.979",
        "longitude": "32.561901",
        "altitude": "3392",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "MZH": {
        "name": "Amasya Merzifon Airport",
        "city": "Merzifon",
        "country": "Turkey",
        "iata": "MZH",
        "icao": "LTAP",
        "latitude": "40.829399",
        "longitude": "35.521999",
        "altitude": "1758",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "VAS": {
        "name": "Sivas Nuri Demirağ Airport",
        "city": "Sivas",
        "country": "Turkey",
        "iata": "VAS",
        "icao": "LTAR",
        "latitude": "39.813801",
        "longitude": "36.9035",
        "altitude": "5239",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "MLX": {
        "name": "Malatya Erhaç Airport",
        "city": "Malatya",
        "country": "Turkey",
        "iata": "MLX",
        "icao": "LTAT",
        "latitude": "38.435298919699996",
        "longitude": "38.0909996033",
        "altitude": "2828",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ASR": {
        "name": "Kayseri Erkilet Airport",
        "city": "Kayseri",
        "country": "Turkey",
        "iata": "ASR",
        "icao": "LTAU",
        "latitude": "38.770401001",
        "longitude": "35.4953994751",
        "altitude": "3463",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "TJK": {
        "name": "Tokat Airport",
        "city": "Tokat",
        "country": "Turkey",
        "iata": "TJK",
        "icao": "LTAW",
        "latitude": "40.307430267333984",
        "longitude": "36.367408752441406",
        "altitude": "1831",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "DNZ": {
        "name": "Çardak Airport",
        "city": "Denizli",
        "country": "Turkey",
        "iata": "DNZ",
        "icao": "LTAY",
        "latitude": "37.7855987549",
        "longitude": "29.7012996674",
        "altitude": "2795",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ISL": {
        "name": "Atatürk International Airport",
        "city": "Istanbul",
        "country": "Turkey",
        "iata": "ISL",
        "icao": "LTBA",
        "latitude": "40.976898",
        "longitude": "28.8146",
        "altitude": "163",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "BZI": {
        "name": "Balıkesir Merkez Airport",
        "city": "Balikesir",
        "country": "Turkey",
        "iata": "BZI",
        "icao": "LTBF",
        "latitude": "39.619300842285156",
        "longitude": "27.926000595092773",
        "altitude": "340",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "BDM": {
        "name": "Bandırma Airport",
        "city": "Bandirma",
        "country": "Turkey",
        "iata": "BDM",
        "icao": "LTBG",
        "latitude": "40.31800079345703",
        "longitude": "27.977699279785156",
        "altitude": "170",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ESK": {
        "name": "Eskişehir Air Base",
        "city": "Eskisehir",
        "country": "Turkey",
        "iata": "ESK",
        "icao": "LTBI",
        "latitude": "39.7840995789",
        "longitude": "30.582099914599997",
        "altitude": "2581",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ADB": {
        "name": "Adnan Menderes International Airport",
        "city": "Izmir",
        "country": "Turkey",
        "iata": "ADB",
        "icao": "LTBJ",
        "latitude": "38.2924003601",
        "longitude": "27.156999588",
        "altitude": "412",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "IGL": {
        "name": "Çiğli Airport",
        "city": "Izmir",
        "country": "Turkey",
        "iata": "IGL",
        "icao": "LTBL",
        "latitude": "38.513000488299994",
        "longitude": "27.010099411",
        "altitude": "16",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "KCO": {
        "name": "Cengiz Topel Airport",
        "city": "Topel",
        "country": "Turkey",
        "iata": "KCO",
        "icao": "LTBQ",
        "latitude": "40.73500061035156",
        "longitude": "30.08329963684082",
        "altitude": "182",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "DLM": {
        "name": "Dalaman International Airport",
        "city": "Dalaman",
        "country": "Turkey",
        "iata": "DLM",
        "icao": "LTBS",
        "latitude": "36.7131004333",
        "longitude": "28.7924995422",
        "altitude": "20",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "BXN": {
        "name": "Imsık Airport",
        "city": "Bodrum",
        "country": "Turkey",
        "iata": "BXN",
        "icao": "LTBV",
        "latitude": "37.140098571777344",
        "longitude": "27.669700622558594",
        "altitude": "202",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "EZS": {
        "name": "Elazığ Airport",
        "city": "Elazig",
        "country": "Turkey",
        "iata": "EZS",
        "icao": "LTCA",
        "latitude": "38.6068992615",
        "longitude": "39.2914009094",
        "altitude": "2927",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "DIY": {
        "name": "Diyarbakir Airport",
        "city": "Diyabakir",
        "country": "Turkey",
        "iata": "DIY",
        "icao": "LTCC",
        "latitude": "37.893901825",
        "longitude": "40.201000213600004",
        "altitude": "2251",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ERC": {
        "name": "Erzincan Airport",
        "city": "Erzincan",
        "country": "Turkey",
        "iata": "ERC",
        "icao": "LTCD",
        "latitude": "39.7102012634",
        "longitude": "39.527000427199994",
        "altitude": "3783",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ERZ": {
        "name": "Erzurum International Airport",
        "city": "Erzurum",
        "country": "Turkey",
        "iata": "ERZ",
        "icao": "LTCE",
        "latitude": "39.9565010071",
        "longitude": "41.17020034789999",
        "altitude": "5763",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "TZX": {
        "name": "Trabzon International Airport",
        "city": "Trabzon",
        "country": "Turkey",
        "iata": "TZX",
        "icao": "LTCG",
        "latitude": "40.99509811401367",
        "longitude": "39.78969955444336",
        "altitude": "104",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "VAN": {
        "name": "Van Ferit Melen Airport",
        "city": "Van",
        "country": "Turkey",
        "iata": "VAN",
        "icao": "LTCI",
        "latitude": "38.46820068359375",
        "longitude": "43.332298278808594",
        "altitude": "5480",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "BAL": {
        "name": "Batman Airport",
        "city": "Batman",
        "country": "Turkey",
        "iata": "BAL",
        "icao": "LTCJ",
        "latitude": "37.929000854499996",
        "longitude": "41.1166000366",
        "altitude": "1822",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "SXZ": {
        "name": "Siirt Airport",
        "city": "Siirt",
        "country": "Turkey",
        "iata": "SXZ",
        "icao": "LTCL",
        "latitude": "37.97890090942383",
        "longitude": "41.84040069580078",
        "altitude": "2001",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "BZY": {
        "name": "Bălți International Airport",
        "city": "Saltsy",
        "country": "Moldova",
        "iata": "BZY",
        "icao": "LUBL",
        "latitude": "47.843056",
        "longitude": "27.777222",
        "altitude": "758",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Chisinau"
    },
    "KIV": {
        "name": "Chişinău International Airport",
        "city": "Chisinau",
        "country": "Moldova",
        "iata": "KIV",
        "icao": "LUKK",
        "latitude": "46.92770004272461",
        "longitude": "28.930999755859375",
        "altitude": "399",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Chisinau"
    },
    "OHD": {
        "name": "Ohrid St. Paul the Apostle Airport",
        "city": "Ohrid",
        "country": "Macedonia",
        "iata": "OHD",
        "icao": "LWOH",
        "latitude": "41.18",
        "longitude": "20.7423",
        "altitude": "2313",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Skopje"
    },
    "SKP": {
        "name": "Skopje Alexander the Great Airport",
        "city": "Skopje",
        "country": "Macedonia",
        "iata": "SKP",
        "icao": "LWSK",
        "latitude": "41.961601",
        "longitude": "21.621401",
        "altitude": "781",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Skopje"
    },
    "GIB": {
        "name": "Gibraltar Airport",
        "city": "Gibraltar",
        "country": "Gibraltar",
        "iata": "GIB",
        "icao": "LXGB",
        "latitude": "36.1511993408",
        "longitude": "-5.3496599197400005",
        "altitude": "15",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Europe/Gibraltar"
    },
    "BEG": {
        "name": "Belgrade Nikola Tesla Airport",
        "city": "Belgrade",
        "country": "Serbia",
        "iata": "BEG",
        "icao": "LYBE",
        "latitude": "44.8184013367",
        "longitude": "20.3090991974",
        "altitude": "335",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Belgrade"
    },
    "INI": {
        "name": "Nis Airport",
        "city": "Nis",
        "country": "Serbia",
        "iata": "INI",
        "icao": "LYNI",
        "latitude": "43.337299",
        "longitude": "21.853701",
        "altitude": "648",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Belgrade"
    },
    "TGD": {
        "name": "Podgorica Airport",
        "city": "Podgorica",
        "country": "Montenegro",
        "iata": "TGD",
        "icao": "LYPG",
        "latitude": "42.359402",
        "longitude": "19.2519",
        "altitude": "141",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Podgorica"
    },
    "PRN": {
        "name": "Priština International Airport",
        "city": "Pristina",
        "country": "Serbia",
        "iata": "PRN",
        "icao": "BKPR",
        "latitude": "42.5728",
        "longitude": "21.035801",
        "altitude": "1789",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Belgrade"
    },
    "TIV": {
        "name": "Tivat Airport",
        "city": "Tivat",
        "country": "Montenegro",
        "iata": "TIV",
        "icao": "LYTV",
        "latitude": "42.404701232910156",
        "longitude": "18.72330093383789",
        "altitude": "20",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Podgorica"
    },
    "BTS": {
        "name": "M. R. Štefánik Airport",
        "city": "Bratislava",
        "country": "Slovakia",
        "iata": "BTS",
        "icao": "LZIB",
        "latitude": "48.17020034790039",
        "longitude": "17.21269989013672",
        "altitude": "436",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Bratislava"
    },
    "KSC": {
        "name": "Košice Airport",
        "city": "Kosice",
        "country": "Slovakia",
        "iata": "KSC",
        "icao": "LZKZ",
        "latitude": "48.66310119628906",
        "longitude": "21.241100311279297",
        "altitude": "755",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Bratislava"
    },
    "PZY": {
        "name": "Piešťany Airport",
        "city": "Piestany",
        "country": "Slovakia",
        "iata": "PZY",
        "icao": "LZPP",
        "latitude": "48.62519836425781",
        "longitude": "17.828399658203125",
        "altitude": "545",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Bratislava"
    },
    "SLD": {
        "name": "Sliač Airport",
        "city": "Sliac",
        "country": "Slovakia",
        "iata": "SLD",
        "icao": "LZSL",
        "latitude": "48.63779830932617",
        "longitude": "19.13409996032715",
        "altitude": "1043",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Bratislava"
    },
    "TAT": {
        "name": "Poprad-Tatry Airport",
        "city": "Poprad",
        "country": "Slovakia",
        "iata": "TAT",
        "icao": "LZTT",
        "latitude": "49.073600769",
        "longitude": "20.2411003113",
        "altitude": "2356",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Bratislava"
    },
    "NCA": {
        "name": "North Caicos Airport",
        "city": "North Caicos",
        "country": "Turks and Caicos Islands",
        "iata": "NCA",
        "icao": "MBNC",
        "latitude": "21.9175",
        "longitude": "-71.939598",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grand_Turk"
    },
    "PLS": {
        "name": "Providenciales Airport",
        "city": "Providenciales",
        "country": "Turks and Caicos Islands",
        "iata": "PLS",
        "icao": "MBPV",
        "latitude": "21.77359962463379",
        "longitude": "-72.26589965820312",
        "altitude": "15",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grand_Turk"
    },
    "XSC": {
        "name": "South Caicos Airport",
        "city": "South Caicos",
        "country": "Turks and Caicos Islands",
        "iata": "XSC",
        "icao": "MBSC",
        "latitude": "21.515699386599998",
        "longitude": "-71.528503418",
        "altitude": "6",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grand_Turk"
    },
    "BRX": {
        "name": "Maria Montez International Airport",
        "city": "Barahona",
        "country": "Dominican Republic",
        "iata": "BRX",
        "icao": "MDBH",
        "latitude": "18.25149917602539",
        "longitude": "-71.12039947509766",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "CBJ": {
        "name": "Cabo Rojo Airport",
        "city": "Cabo Rojo",
        "country": "Dominican Republic",
        "iata": "CBJ",
        "icao": "MDCR",
        "latitude": "17.929000854492188",
        "longitude": "-71.6447982788086",
        "altitude": "262",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "LRM": {
        "name": "Casa De Campo International Airport",
        "city": "La Romana",
        "country": "Dominican Republic",
        "iata": "LRM",
        "icao": "MDLR",
        "latitude": "18.450700759887695",
        "longitude": "-68.91179656982422",
        "altitude": "240",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "PUJ": {
        "name": "Punta Cana International Airport",
        "city": "Punta Cana",
        "country": "Dominican Republic",
        "iata": "PUJ",
        "icao": "MDPC",
        "latitude": "18.567399978599997",
        "longitude": "-68.36340332030001",
        "altitude": "47",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "POP": {
        "name": "Gregorio Luperon International Airport",
        "city": "Puerto Plata",
        "country": "Dominican Republic",
        "iata": "POP",
        "icao": "MDPP",
        "latitude": "19.75790023803711",
        "longitude": "-70.56999969482422",
        "altitude": "15",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "SDQ": {
        "name": "Las Américas International Airport",
        "city": "Santo Domingo",
        "country": "Dominican Republic",
        "iata": "SDQ",
        "icao": "MDSD",
        "latitude": "18.42970085144",
        "longitude": "-69.668899536133",
        "altitude": "59",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "STI": {
        "name": "Cibao International Airport",
        "city": "Santiago",
        "country": "Dominican Republic",
        "iata": "STI",
        "icao": "MDST",
        "latitude": "19.406099319458008",
        "longitude": "-70.60469818115234",
        "altitude": "565",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "CBV": {
        "name": "Coban Airport",
        "city": "Coban",
        "country": "Guatemala",
        "iata": "CBV",
        "icao": "MGCB",
        "latitude": "15.468999862670898",
        "longitude": "-90.40670013427734",
        "altitude": "4339",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "GUA": {
        "name": "La Aurora Airport",
        "city": "Guatemala City",
        "country": "Guatemala",
        "iata": "GUA",
        "icao": "MGGT",
        "latitude": "14.5833",
        "longitude": "-90.527496",
        "altitude": "4952",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "RER": {
        "name": "Retalhuleu Airport",
        "city": "Retalhuleu",
        "country": "Guatemala",
        "iata": "RER",
        "icao": "MGRT",
        "latitude": "14.520999908447266",
        "longitude": "-91.69730377197266",
        "altitude": "656",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "GSJ": {
        "name": "San José Airport",
        "city": "San Jose",
        "country": "Guatemala",
        "iata": "GSJ",
        "icao": "MGSJ",
        "latitude": "13.936200141899999",
        "longitude": "-90.83580017090001",
        "altitude": "29",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "LCE": {
        "name": "Goloson International Airport",
        "city": "La Ceiba",
        "country": "Honduras",
        "iata": "LCE",
        "icao": "MHLC",
        "latitude": "15.7425",
        "longitude": "-86.852997",
        "altitude": "39",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "SAP": {
        "name": "Ramón Villeda Morales International Airport",
        "city": "San Pedro Sula",
        "country": "Honduras",
        "iata": "SAP",
        "icao": "MHLM",
        "latitude": "15.4526",
        "longitude": "-87.923599",
        "altitude": "91",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "GJA": {
        "name": "La Laguna Airport",
        "city": "Guanaja",
        "country": "Honduras",
        "iata": "GJA",
        "icao": "MHNJ",
        "latitude": "16.4454",
        "longitude": "-85.906601",
        "altitude": "49",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "RTB": {
        "name": "Juan Manuel Galvez International Airport",
        "city": "Roatan",
        "country": "Honduras",
        "iata": "RTB",
        "icao": "MHRO",
        "latitude": "16.316799",
        "longitude": "-86.523003",
        "altitude": "39",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "TEA": {
        "name": "Tela Airport",
        "city": "Tela",
        "country": "Honduras",
        "iata": "TEA",
        "icao": "MHTE",
        "latitude": "15.7759",
        "longitude": "-87.4758",
        "altitude": "7",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "TGU": {
        "name": "Toncontín International Airport",
        "city": "Tegucigalpa",
        "country": "Honduras",
        "iata": "TGU",
        "icao": "MHTG",
        "latitude": "14.06089973449707",
        "longitude": "-87.21720123291016",
        "altitude": "3294",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "TJI": {
        "name": "Trujillo Airport",
        "city": "Trujillo",
        "country": "Honduras",
        "iata": "TJI",
        "icao": "MHTJ",
        "latitude": "15.9268",
        "longitude": "-85.938202",
        "altitude": "3",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "OCJ": {
        "name": "Boscobel Aerodrome",
        "city": "Ocho Rios",
        "country": "Jamaica",
        "iata": "OCJ",
        "icao": "MKBS",
        "latitude": "18.404199600219727",
        "longitude": "-76.96900177001953",
        "altitude": "90",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Jamaica"
    },
    "KIN": {
        "name": "Norman Manley International Airport",
        "city": "Kingston",
        "country": "Jamaica",
        "iata": "KIN",
        "icao": "MKJP",
        "latitude": "17.935699462890625",
        "longitude": "-76.7874984741211",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Jamaica"
    },
    "MBJ": {
        "name": "Sangster International Airport",
        "city": "Montego Bay",
        "country": "Jamaica",
        "iata": "MBJ",
        "icao": "MKJS",
        "latitude": "18.503700256347656",
        "longitude": "-77.91339874267578",
        "altitude": "4",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Jamaica"
    },
    "POT": {
        "name": "Ken Jones Airport",
        "city": "Port Antonio",
        "country": "Jamaica",
        "iata": "POT",
        "icao": "MKKJ",
        "latitude": "18.1987991333",
        "longitude": "-76.53450012210001",
        "altitude": "20",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Jamaica"
    },
    "KTP": {
        "name": "Tinson Pen Airport",
        "city": "Kingston",
        "country": "Jamaica",
        "iata": "KTP",
        "icao": "MKTP",
        "latitude": "17.98859977722168",
        "longitude": "-76.82379913330078",
        "altitude": "16",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Jamaica"
    },
    "ACA": {
        "name": "General Juan N Alvarez International Airport",
        "city": "Acapulco",
        "country": "Mexico",
        "iata": "ACA",
        "icao": "MMAA",
        "latitude": "16.757099151611328",
        "longitude": "-99.75399780273438",
        "altitude": "16",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "NTR": {
        "name": "Del Norte International Airport",
        "city": "Monterrey",
        "country": "Mexico",
        "iata": "NTR",
        "icao": "MMAN",
        "latitude": "25.865601",
        "longitude": "-100.237",
        "altitude": "1476",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "AGU": {
        "name": "Jesús Terán Paredo International Airport",
        "city": "Aguascalientes",
        "country": "Mexico",
        "iata": "AGU",
        "icao": "MMAS",
        "latitude": "21.705601",
        "longitude": "-102.318001",
        "altitude": "6112",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "HUX": {
        "name": "Bahías de Huatulco International Airport",
        "city": "Huatulco",
        "country": "Mexico",
        "iata": "HUX",
        "icao": "MMBT",
        "latitude": "15.7753",
        "longitude": "-96.262604",
        "altitude": "464",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CVJ": {
        "name": "General Mariano Matamoros Airport",
        "city": "Cuernavaca",
        "country": "Mexico",
        "iata": "CVJ",
        "icao": "MMCB",
        "latitude": "18.834800720214844",
        "longitude": "-99.26129913330078",
        "altitude": "4277",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "ACN": {
        "name": "Ciudad Acuña New International Airport",
        "city": "Ciudad Acuna",
        "country": "Mexico",
        "iata": "ACN",
        "icao": "MMCC",
        "latitude": "29.332899",
        "longitude": "-101.098998",
        "altitude": "1410",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CME": {
        "name": "Ciudad del Carmen International Airport",
        "city": "Ciudad Del Carmen",
        "country": "Mexico",
        "iata": "CME",
        "icao": "MMCE",
        "latitude": "18.65369987487793",
        "longitude": "-91.79900360107422",
        "altitude": "10",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "NCG": {
        "name": "Nuevo Casas Grandes Airport",
        "city": "Nuevo Casas Grandes",
        "country": "Mexico",
        "iata": "NCG",
        "icao": "MMCG",
        "latitude": "30.3974",
        "longitude": "-107.875",
        "altitude": "4850",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "CUL": {
        "name": "Bachigualato Federal International Airport",
        "city": "Culiacan",
        "country": "Mexico",
        "iata": "CUL",
        "icao": "MMCL",
        "latitude": "24.7644996643",
        "longitude": "-107.474998474",
        "altitude": "108",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "CTM": {
        "name": "Chetumal International Airport",
        "city": "Chetumal",
        "country": "Mexico",
        "iata": "CTM",
        "icao": "MMCM",
        "latitude": "18.50469970703125",
        "longitude": "-88.32679748535156",
        "altitude": "39",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Cancun"
    },
    "CEN": {
        "name": "Ciudad Obregón International Airport",
        "city": "Ciudad Obregon",
        "country": "Mexico",
        "iata": "CEN",
        "icao": "MMCN",
        "latitude": "27.39259910583496",
        "longitude": "-109.83300018310547",
        "altitude": "243",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Hermosillo"
    },
    "CPE": {
        "name": "Ingeniero Alberto Acuña Ongay International Airport",
        "city": "Campeche",
        "country": "Mexico",
        "iata": "CPE",
        "icao": "MMCP",
        "latitude": "19.816799163800003",
        "longitude": "-90.5002975464",
        "altitude": "34",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CJS": {
        "name": "Abraham González International Airport",
        "city": "Ciudad Juarez",
        "country": "Mexico",
        "iata": "CJS",
        "icao": "MMCS",
        "latitude": "31.63610076904297",
        "longitude": "-106.42900085449219",
        "altitude": "3904",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "CUU": {
        "name": "General Roberto Fierro Villalobos International Airport",
        "city": "Chihuahua",
        "country": "Mexico",
        "iata": "CUU",
        "icao": "MMCU",
        "latitude": "28.702899932900003",
        "longitude": "-105.964996338",
        "altitude": "4462",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "CVM": {
        "name": "General Pedro Jose Mendez International Airport",
        "city": "Ciudad Victoria",
        "country": "Mexico",
        "iata": "CVM",
        "icao": "MMCV",
        "latitude": "23.7033004761",
        "longitude": "-98.9564971924",
        "altitude": "761",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CZM": {
        "name": "Cozumel International Airport",
        "city": "Cozumel",
        "country": "Mexico",
        "iata": "CZM",
        "icao": "MMCZ",
        "latitude": "20.52239990234375",
        "longitude": "-86.92559814453125",
        "altitude": "15",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Cancun"
    },
    "DGO": {
        "name": "General Guadalupe Victoria International Airport",
        "city": "Durango",
        "country": "Mexico",
        "iata": "DGO",
        "icao": "MMDO",
        "latitude": "24.1242008209",
        "longitude": "-104.527999878",
        "altitude": "6104",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TPQ": {
        "name": "Amado Nervo National Airport",
        "city": "Tepic",
        "country": "Mexico",
        "iata": "TPQ",
        "icao": "MMEP",
        "latitude": "21.4195",
        "longitude": "-104.843002",
        "altitude": "3020",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "ESE": {
        "name": "Ensenada International Airport",
        "city": "Ensenada",
        "country": "Mexico",
        "iata": "ESE",
        "icao": "MMES",
        "latitude": "31.7953",
        "longitude": "-116.602997",
        "altitude": "66",
        "timezone": "-8",
        "dst": "S",
        "tz_name": "America/Tijuana"
    },
    "GDL": {
        "name": "Don Miguel Hidalgo Y Costilla International Airport",
        "city": "Guadalajara",
        "country": "Mexico",
        "iata": "GDL",
        "icao": "MMGL",
        "latitude": "20.521799087524414",
        "longitude": "-103.31099700927734",
        "altitude": "5016",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "GYM": {
        "name": "General José María Yáñez International Airport",
        "city": "Guaymas",
        "country": "Mexico",
        "iata": "GYM",
        "icao": "MMGM",
        "latitude": "27.9689998626709",
        "longitude": "-110.92500305175781",
        "altitude": "59",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Hermosillo"
    },
    "TCN": {
        "name": "Tehuacan Airport",
        "city": "Tehuacan",
        "country": "Mexico",
        "iata": "TCN",
        "icao": "MMHC",
        "latitude": "18.49720001220703",
        "longitude": "-97.4198989868164",
        "altitude": "5509",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "HMO": {
        "name": "General Ignacio P. Garcia International Airport",
        "city": "Hermosillo",
        "country": "Mexico",
        "iata": "HMO",
        "icao": "MMHO",
        "latitude": "29.095899581900003",
        "longitude": "-111.047996521",
        "altitude": "627",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Hermosillo"
    },
    "CLQ": {
        "name": "Licenciado Miguel de la Madrid Airport",
        "city": "Colima",
        "country": "Mexico",
        "iata": "CLQ",
        "icao": "MMIA",
        "latitude": "19.277",
        "longitude": "-103.577002",
        "altitude": "2467",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "ISJ": {
        "name": "Isla Mujeres Airport",
        "city": "Isla Mujeres",
        "country": "Mexico",
        "iata": "ISJ",
        "icao": "MMIM",
        "latitude": "21.2450008392334",
        "longitude": "-86.73999786376953",
        "altitude": "7",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Cancun"
    },
    "SLW": {
        "name": "Plan De Guadalupe International Airport",
        "city": "Saltillo",
        "country": "Mexico",
        "iata": "SLW",
        "icao": "MMIO",
        "latitude": "25.54949951171875",
        "longitude": "-100.92900085449219",
        "altitude": "4778",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "IZT": {
        "name": "Ixtepec Airport",
        "city": "Iztepec",
        "country": "Mexico",
        "iata": "IZT",
        "icao": "MMIT",
        "latitude": "16.449301",
        "longitude": "-95.093697",
        "altitude": "164",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "LZC": {
        "name": "Lázaro Cárdenas Airport",
        "city": "Lazard Cardenas",
        "country": "Mexico",
        "iata": "LZC",
        "icao": "MMLC",
        "latitude": "18.0016994476",
        "longitude": "-102.221000671",
        "altitude": "39",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "LMM": {
        "name": "Valle del Fuerte International Airport",
        "city": "Los Mochis",
        "country": "Mexico",
        "iata": "LMM",
        "icao": "MMLM",
        "latitude": "25.6851997375",
        "longitude": "-109.081001282",
        "altitude": "16",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "BJX": {
        "name": "Del Bajío International Airport",
        "city": "Del Bajio",
        "country": "Mexico",
        "iata": "BJX",
        "icao": "MMLO",
        "latitude": "20.9935",
        "longitude": "-101.481003",
        "altitude": "5956",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "LAP": {
        "name": "Manuel Márquez de León International Airport",
        "city": "La Paz",
        "country": "Mexico",
        "iata": "LAP",
        "icao": "MMLP",
        "latitude": "24.072700500499998",
        "longitude": "-110.361999512",
        "altitude": "69",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "LTO": {
        "name": "Loreto International Airport",
        "city": "Loreto",
        "country": "Mexico",
        "iata": "LTO",
        "icao": "MMLT",
        "latitude": "25.989200592041016",
        "longitude": "-111.3479995727539",
        "altitude": "34",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "MAM": {
        "name": "General Servando Canales International Airport",
        "city": "Matamoros",
        "country": "Mexico",
        "iata": "MAM",
        "icao": "MMMA",
        "latitude": "25.7698993683",
        "longitude": "-97.5252990723",
        "altitude": "25",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "MID": {
        "name": "Licenciado Manuel Crescencio Rejon Int Airport",
        "city": "Merida",
        "country": "Mexico",
        "iata": "MID",
        "icao": "MMMD",
        "latitude": "20.937000274699997",
        "longitude": "-89.657699585",
        "altitude": "38",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "MXL": {
        "name": "General Rodolfo Sánchez Taboada International Airport",
        "city": "Mexicali",
        "country": "Mexico",
        "iata": "MXL",
        "icao": "MMML",
        "latitude": "32.6306",
        "longitude": "-115.241997",
        "altitude": "74",
        "timezone": "-8",
        "dst": "S",
        "tz_name": "America/Tijuana"
    },
    "MLM": {
        "name": "General Francisco J. Mujica International Airport",
        "city": "Morelia",
        "country": "Mexico",
        "iata": "MLM",
        "icao": "MMMM",
        "latitude": "19.849899292",
        "longitude": "-101.025001526",
        "altitude": "6033",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "MTT": {
        "name": "Minatitlán/Coatzacoalcos National Airport",
        "city": "Minatitlan",
        "country": "Mexico",
        "iata": "MTT",
        "icao": "MMMT",
        "latitude": "18.1033992767",
        "longitude": "-94.58070373540001",
        "altitude": "36",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "LOV": {
        "name": "Monclova International Airport",
        "city": "Monclova",
        "country": "Mexico",
        "iata": "LOV",
        "icao": "MMMV",
        "latitude": "26.9557",
        "longitude": "-101.470001",
        "altitude": "1864",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "MEX": {
        "name": "Licenciado Benito Juarez International Airport",
        "city": "Mexico City",
        "country": "Mexico",
        "iata": "MEX",
        "icao": "MMMX",
        "latitude": "19.4363",
        "longitude": "-99.072098",
        "altitude": "7316",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "MTY": {
        "name": "General Mariano Escobedo International Airport",
        "city": "Monterrey",
        "country": "Mexico",
        "iata": "MTY",
        "icao": "MMMY",
        "latitude": "25.7784996033",
        "longitude": "-100.107002258",
        "altitude": "1278",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "MZT": {
        "name": "General Rafael Buelna International Airport",
        "city": "Mazatlan",
        "country": "Mexico",
        "iata": "MZT",
        "icao": "MMMZ",
        "latitude": "23.1613998413",
        "longitude": "-106.26599884",
        "altitude": "38",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "NOG": {
        "name": "Nogales International Airport",
        "city": "Nogales",
        "country": "Mexico",
        "iata": "NOG",
        "icao": "MMNG",
        "latitude": "31.22610092163086",
        "longitude": "-110.97599792480469",
        "altitude": "3990",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Hermosillo"
    },
    "NLD": {
        "name": "Quetzalcóatl International Airport",
        "city": "Nuevo Laredo",
        "country": "Mexico",
        "iata": "NLD",
        "icao": "MMNL",
        "latitude": "27.4438991547",
        "longitude": "-99.5705032349",
        "altitude": "484",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "OAX": {
        "name": "Xoxocotlán International Airport",
        "city": "Oaxaca",
        "country": "Mexico",
        "iata": "OAX",
        "icao": "MMOX",
        "latitude": "16.9999008179",
        "longitude": "-96.726600647",
        "altitude": "4989",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "PAZ": {
        "name": "El Tajín National Airport",
        "city": "Poza Rico",
        "country": "Mexico",
        "iata": "PAZ",
        "icao": "MMPA",
        "latitude": "20.6026992798",
        "longitude": "-97.46080017090001",
        "altitude": "497",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "PBC": {
        "name": "Hermanos Serdán International Airport",
        "city": "Puebla",
        "country": "Mexico",
        "iata": "PBC",
        "icao": "MMPB",
        "latitude": "19.1581001282",
        "longitude": "-98.3713989258",
        "altitude": "7361",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "PPE": {
        "name": "Mar de Cortés International Airport",
        "city": "Punta Penasco",
        "country": "Mexico",
        "iata": "PPE",
        "icao": "MMPE",
        "latitude": "31.351987",
        "longitude": "-113.305177",
        "altitude": "71",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Hermosillo"
    },
    "PDS": {
        "name": "Piedras Negras International Airport",
        "city": "Piedras Negras",
        "country": "Mexico",
        "iata": "PDS",
        "icao": "MMPG",
        "latitude": "28.627399",
        "longitude": "-100.535004",
        "altitude": "901",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "UPN": {
        "name": "Licenciado y General Ignacio Lopez Rayon Airport",
        "city": "Uruapan",
        "country": "Mexico",
        "iata": "UPN",
        "icao": "MMPN",
        "latitude": "19.396699905395508",
        "longitude": "-102.03900146484375",
        "altitude": "5258",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "PVR": {
        "name": "Licenciado Gustavo Díaz Ordaz International Airport",
        "city": "Puerto Vallarta",
        "country": "Mexico",
        "iata": "PVR",
        "icao": "MMPR",
        "latitude": "20.680099487304688",
        "longitude": "-105.25399780273438",
        "altitude": "23",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "PXM": {
        "name": "Puerto Escondido International Airport",
        "city": "Puerto Escondido",
        "country": "Mexico",
        "iata": "PXM",
        "icao": "MMPS",
        "latitude": "15.8769",
        "longitude": "-97.089103",
        "altitude": "294",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "QRO": {
        "name": "Querétaro Intercontinental Airport",
        "city": "Queretaro",
        "country": "Mexico",
        "iata": "QRO",
        "icao": "MMQT",
        "latitude": "20.6173",
        "longitude": "-100.185997",
        "altitude": "6296",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "REX": {
        "name": "General Lucio Blanco International Airport",
        "city": "Reynosa",
        "country": "Mexico",
        "iata": "REX",
        "icao": "MMRX",
        "latitude": "26.0089",
        "longitude": "-98.2285",
        "altitude": "139",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "SJD": {
        "name": "Los Cabos International Airport",
        "city": "San Jose Del Cabo",
        "country": "Mexico",
        "iata": "SJD",
        "icao": "MMSD",
        "latitude": "23.15180015563965",
        "longitude": "-109.72100067138672",
        "altitude": "374",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "SFH": {
        "name": "San Felipe International Airport",
        "city": "San Filipe",
        "country": "Mexico",
        "iata": "SFH",
        "icao": "MMSF",
        "latitude": "30.930200576782",
        "longitude": "-114.80899810791",
        "altitude": "148",
        "timezone": "-8",
        "dst": "S",
        "tz_name": "America/Tijuana"
    },
    "SLP": {
        "name": "Ponciano Arriaga International Airport",
        "city": "San Luis Potosi",
        "country": "Mexico",
        "iata": "SLP",
        "icao": "MMSP",
        "latitude": "22.254299163800003",
        "longitude": "-100.930999756",
        "altitude": "6035",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TRC": {
        "name": "Francisco Sarabia International Airport",
        "city": "Torreon",
        "country": "Mexico",
        "iata": "TRC",
        "icao": "MMTC",
        "latitude": "25.568300247199996",
        "longitude": "-103.411003113",
        "altitude": "3688",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TGZ": {
        "name": "Angel Albino Corzo International Airport",
        "city": "Tuxtla Gutierrez",
        "country": "Mexico",
        "iata": "TGZ",
        "icao": "MMTG",
        "latitude": "16.5636005402",
        "longitude": "-93.02249908450001",
        "altitude": "1499",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TIJ": {
        "name": "General Abelardo L. Rodríguez International Airport",
        "city": "Tijuana",
        "country": "Mexico",
        "iata": "TIJ",
        "icao": "MMTJ",
        "latitude": "32.541099548339844",
        "longitude": "-116.97000122070312",
        "altitude": "489",
        "timezone": "-8",
        "dst": "S",
        "tz_name": "America/Tijuana"
    },
    "TAM": {
        "name": "General Francisco Javier Mina International Airport",
        "city": "Tampico",
        "country": "Mexico",
        "iata": "TAM",
        "icao": "MMTM",
        "latitude": "22.2964000702",
        "longitude": "-97.8658981323",
        "altitude": "80",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TSL": {
        "name": "Tamuin Airport",
        "city": "Tamuin",
        "country": "Mexico",
        "iata": "TSL",
        "icao": "MMTN",
        "latitude": "22.0383",
        "longitude": "-98.806502",
        "altitude": "164",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TLC": {
        "name": "Licenciado Adolfo Lopez Mateos International Airport",
        "city": "Toluca",
        "country": "Mexico",
        "iata": "TLC",
        "icao": "MMTO",
        "latitude": "19.3370990753",
        "longitude": "-99.56600189210002",
        "altitude": "8466",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "TAP": {
        "name": "Tapachula International Airport",
        "city": "Tapachula",
        "country": "Mexico",
        "iata": "TAP",
        "icao": "MMTP",
        "latitude": "14.7943000793",
        "longitude": "-92.3700027466",
        "altitude": "97",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CUN": {
        "name": "Cancún International Airport",
        "city": "Cancun",
        "country": "Mexico",
        "iata": "CUN",
        "icao": "MMUN",
        "latitude": "21.036500930800003",
        "longitude": "-86.8770980835",
        "altitude": "22",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Cancun"
    },
    "VSA": {
        "name": "Carlos Rovirosa Pérez International Airport",
        "city": "Villahermosa",
        "country": "Mexico",
        "iata": "VSA",
        "icao": "MMVA",
        "latitude": "17.996999740600586",
        "longitude": "-92.81739807128906",
        "altitude": "46",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "VER": {
        "name": "General Heriberto Jara International Airport",
        "city": "Vera Cruz",
        "country": "Mexico",
        "iata": "VER",
        "icao": "MMVR",
        "latitude": "19.1459007263",
        "longitude": "-96.1873016357",
        "altitude": "90",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "ZCL": {
        "name": "General Leobardo C. Ruiz International Airport",
        "city": "Zacatecas",
        "country": "Mexico",
        "iata": "ZCL",
        "icao": "MMZC",
        "latitude": "22.8971004486",
        "longitude": "-102.68699646",
        "altitude": "7141",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "ZIH": {
        "name": "Ixtapa Zihuatanejo International Airport",
        "city": "Zihuatanejo",
        "country": "Mexico",
        "iata": "ZIH",
        "icao": "MMZH",
        "latitude": "17.601600647",
        "longitude": "-101.460998535",
        "altitude": "26",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "ZMM": {
        "name": "Zamora Airport",
        "city": "Zamora",
        "country": "Mexico",
        "iata": "ZMM",
        "icao": "MMZM",
        "latitude": "20.045",
        "longitude": "-102.276001",
        "altitude": "5141",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "ZLO": {
        "name": "Playa De Oro International Airport",
        "city": "Manzanillo",
        "country": "Mexico",
        "iata": "ZLO",
        "icao": "MMZO",
        "latitude": "19.144800186199998",
        "longitude": "-104.558998108",
        "altitude": "30",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "BEF": {
        "name": "Bluefields Airport",
        "city": "Bluefields",
        "country": "Nicaragua",
        "iata": "BEF",
        "icao": "MNBL",
        "latitude": "11.991000175476074",
        "longitude": "-83.77410125732422",
        "altitude": "20",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "MGA": {
        "name": "Augusto C. Sandino (Managua) International Airport",
        "city": "Managua",
        "country": "Nicaragua",
        "iata": "MGA",
        "icao": "MNMG",
        "latitude": "12.141500473022461",
        "longitude": "-86.16819763183594",
        "altitude": "194",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "PUZ": {
        "name": "Puerto Cabezas Airport",
        "city": "Puerto Cabezas",
        "country": "Nicaragua",
        "iata": "PUZ",
        "icao": "MNPC",
        "latitude": "14.047200202941895",
        "longitude": "-83.38670349121094",
        "altitude": "52",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "BOC": {
        "name": "Bocas Del Toro International Airport",
        "city": "Bocas Del Toro",
        "country": "Panama",
        "iata": "BOC",
        "icao": "MPBO",
        "latitude": "9.340849876403809",
        "longitude": "-82.25080108642578",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "CHX": {
        "name": "Cap Manuel Niño International Airport",
        "city": "Changuinola",
        "country": "Panama",
        "iata": "CHX",
        "icao": "MPCH",
        "latitude": "9.458962",
        "longitude": "-82.515062",
        "altitude": "19",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "DAV": {
        "name": "Enrique Malek International Airport",
        "city": "David",
        "country": "Panama",
        "iata": "DAV",
        "icao": "MPDA",
        "latitude": "8.390999794006348",
        "longitude": "-82.43499755859375",
        "altitude": "89",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "BLB": {
        "name": "Panama Pacific International Airport",
        "city": "Howard",
        "country": "Panama",
        "iata": "BLB",
        "icao": "MPHO",
        "latitude": "8.91479",
        "longitude": "-79.599602",
        "altitude": "52",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "PAC": {
        "name": "Marcos A. Gelabert International Airport",
        "city": "Panama",
        "country": "Panama",
        "iata": "PAC",
        "icao": "MPMG",
        "latitude": "8.973340034484863",
        "longitude": "-79.55560302734375",
        "altitude": "31",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "SYP": {
        "name": "Ruben Cantu Airport",
        "city": "Santiago",
        "country": "Panama",
        "iata": "SYP",
        "icao": "MPSA",
        "latitude": "8.085599899291992",
        "longitude": "-80.94529724121094",
        "altitude": "272",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "PTY": {
        "name": "Tocumen International Airport",
        "city": "Panama City",
        "country": "Panama",
        "iata": "PTY",
        "icao": "MPTO",
        "latitude": "9.0713596344",
        "longitude": "-79.3834991455",
        "altitude": "135",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "BAI": {
        "name": "Buenos Aires Airport",
        "city": "Buenos Aires",
        "country": "Costa Rica",
        "iata": "BAI",
        "icao": "MRBA",
        "latitude": "9.163949",
        "longitude": "-83.330171",
        "altitude": "1214",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "OTR": {
        "name": "Coto 47 Airport",
        "city": "Coto 47",
        "country": "Costa Rica",
        "iata": "OTR",
        "icao": "MRCC",
        "latitude": "8.60155963897705",
        "longitude": "-82.96859741210938",
        "altitude": "26",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "JAP": {
        "name": "Chacarita Airport",
        "city": "Chacarita",
        "country": "Costa Rica",
        "iata": "JAP",
        "icao": "MRCH",
        "latitude": "9.98141002655",
        "longitude": "-84.7726974487",
        "altitude": "7",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "GLF": {
        "name": "Golfito Airport",
        "city": "Golfito",
        "country": "Costa Rica",
        "iata": "GLF",
        "icao": "MRGF",
        "latitude": "8.654009819030762",
        "longitude": "-83.18219757080078",
        "altitude": "49",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "GPL": {
        "name": "Guapiles Airport",
        "city": "Guapiles",
        "country": "Costa Rica",
        "iata": "GPL",
        "icao": "MRGP",
        "latitude": "10.2172002792",
        "longitude": "-83.79699707030001",
        "altitude": "883",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "LIR": {
        "name": "Daniel Oduber Quiros International Airport",
        "city": "Liberia",
        "country": "Costa Rica",
        "iata": "LIR",
        "icao": "MRLB",
        "latitude": "10.5933",
        "longitude": "-85.544403",
        "altitude": "270",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "LSL": {
        "name": "Los Chiles Airport",
        "city": "Los Chiles",
        "country": "Costa Rica",
        "iata": "LSL",
        "icao": "MRLC",
        "latitude": "11.035300254821777",
        "longitude": "-84.70610046386719",
        "altitude": "131",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "LIO": {
        "name": "Limon International Airport",
        "city": "Limon",
        "country": "Costa Rica",
        "iata": "LIO",
        "icao": "MRLM",
        "latitude": "9.95796012878418",
        "longitude": "-83.02200317382812",
        "altitude": "7",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "NOB": {
        "name": "Nosara Airport",
        "city": "Nosara Beach",
        "country": "Costa Rica",
        "iata": "NOB",
        "icao": "MRNS",
        "latitude": "9.976490020750001",
        "longitude": "-85.65299987790002",
        "altitude": "33",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "SJO": {
        "name": "Juan Santamaria International Airport",
        "city": "San Jose",
        "country": "Costa Rica",
        "iata": "SJO",
        "icao": "MROC",
        "latitude": "9.993860244750977",
        "longitude": "-84.20880126953125",
        "altitude": "3021",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "PMZ": {
        "name": "Palmar Sur Airport",
        "city": "Palmar Sur",
        "country": "Costa Rica",
        "iata": "PMZ",
        "icao": "MRPM",
        "latitude": "8.951029777526855",
        "longitude": "-83.46859741210938",
        "altitude": "49",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "XQP": {
        "name": "Quepos Managua Airport",
        "city": "Quepos",
        "country": "Costa Rica",
        "iata": "XQP",
        "icao": "MRQP",
        "latitude": "9.443160057067871",
        "longitude": "-84.12979888916016",
        "altitude": "85",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "TOO": {
        "name": "San Vito De Java Airport",
        "city": "San Vito De Jaba",
        "country": "Costa Rica",
        "iata": "TOO",
        "icao": "MRSV",
        "latitude": "8.826109886169434",
        "longitude": "-82.95890045166016",
        "altitude": "3228",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "SAL": {
        "name": "Monseñor Óscar Arnulfo Romero International Airport",
        "city": "San Salvador",
        "country": "El Salvador",
        "iata": "SAL",
        "icao": "MSLP",
        "latitude": "13.4409",
        "longitude": "-89.055702",
        "altitude": "101",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/El_Salvador"
    },
    "CYA": {
        "name": "Les Cayes Airport",
        "city": "Cayes",
        "country": "Haiti",
        "iata": "CYA",
        "icao": "MTCA",
        "latitude": "18.271099090576172",
        "longitude": "-73.78829956054688",
        "altitude": "203",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Port-au-Prince"
    },
    "CAP": {
        "name": "Cap Haitien International Airport",
        "city": "Cap Haitien",
        "country": "Haiti",
        "iata": "CAP",
        "icao": "MTCH",
        "latitude": "19.733",
        "longitude": "-72.194702",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Port-au-Prince"
    },
    "JAK": {
        "name": "Jacmel Airport",
        "city": "Jacmel",
        "country": "Haiti",
        "iata": "JAK",
        "icao": "MTJA",
        "latitude": "18.241100311279297",
        "longitude": "-72.51850128173828",
        "altitude": "167",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Port-au-Prince"
    },
    "PAP": {
        "name": "Toussaint Louverture International Airport",
        "city": "Port-au-prince",
        "country": "Haiti",
        "iata": "PAP",
        "icao": "MTPP",
        "latitude": "18.579999923706055",
        "longitude": "-72.2925033569336",
        "altitude": "122",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Port-au-Prince"
    },
    "BCA": {
        "name": "Gustavo Rizo Airport",
        "city": "Baracoa Playa",
        "country": "Cuba",
        "iata": "BCA",
        "icao": "MUBA",
        "latitude": "20.365299224853516",
        "longitude": "-74.5062026977539",
        "altitude": "26",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "BYM": {
        "name": "Carlos Manuel de Cespedes Airport",
        "city": "Bayamo",
        "country": "Cuba",
        "iata": "BYM",
        "icao": "MUBY",
        "latitude": "20.396400451660156",
        "longitude": "-76.62139892578125",
        "altitude": "203",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "AVI": {
        "name": "Maximo Gomez Airport",
        "city": "Ciego De Avila",
        "country": "Cuba",
        "iata": "AVI",
        "icao": "MUCA",
        "latitude": "22.027099609375",
        "longitude": "-78.78959655761719",
        "altitude": "335",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "CCC": {
        "name": "Jardines Del Rey Airport",
        "city": "Cunagua",
        "country": "Cuba",
        "iata": "CCC",
        "icao": "MUCC",
        "latitude": "22.461000442499998",
        "longitude": "-78.32839965820001",
        "altitude": "13",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "CFG": {
        "name": "Jaime Gonzalez Airport",
        "city": "Cienfuegos",
        "country": "Cuba",
        "iata": "CFG",
        "icao": "MUCF",
        "latitude": "22.149999618530273",
        "longitude": "-80.41419982910156",
        "altitude": "102",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "CYO": {
        "name": "Vilo Acuña International Airport",
        "city": "Cayo Largo del Sur",
        "country": "Cuba",
        "iata": "CYO",
        "icao": "MUCL",
        "latitude": "21.6165008545",
        "longitude": "-81.5459976196",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "CMW": {
        "name": "Ignacio Agramonte International Airport",
        "city": "Camaguey",
        "country": "Cuba",
        "iata": "CMW",
        "icao": "MUCM",
        "latitude": "21.420299530029297",
        "longitude": "-77.84750366210938",
        "altitude": "413",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "SCU": {
        "name": "Antonio Maceo International Airport",
        "city": "Santiago De Cuba",
        "country": "Cuba",
        "iata": "SCU",
        "icao": "MUCU",
        "latitude": "19.96980094909668",
        "longitude": "-75.83540344238281",
        "altitude": "249",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "NBW": {
        "name": "Leeward Point Field",
        "city": "Guantanamo",
        "country": "Cuba",
        "iata": "NBW",
        "icao": "MUGM",
        "latitude": "19.906499862671",
        "longitude": "-75.207099914551",
        "altitude": "56",
        "timezone": "-5",
        "dst": "U",
        "tz_name": null
    },
    "GAO": {
        "name": "Mariana Grajales Airport",
        "city": "Guantanamo",
        "country": "Cuba",
        "iata": "GAO",
        "icao": "MUGT",
        "latitude": "20.08530044555664",
        "longitude": "-75.1583023071289",
        "altitude": "56",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "HAV": {
        "name": "José Martí International Airport",
        "city": "La Havana",
        "country": "Cuba",
        "iata": "HAV",
        "icao": "MUHA",
        "latitude": "22.989200592041016",
        "longitude": "-82.40910339355469",
        "altitude": "210",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "HOG": {
        "name": "Frank Pais International Airport",
        "city": "Holguin",
        "country": "Cuba",
        "iata": "HOG",
        "icao": "MUHG",
        "latitude": "20.785600662231445",
        "longitude": "-76.31510162353516",
        "altitude": "361",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "LCL": {
        "name": "La Coloma Airport",
        "city": "La Coloma",
        "country": "Cuba",
        "iata": "LCL",
        "icao": "MULM",
        "latitude": "22.33609962463379",
        "longitude": "-83.64189910888672",
        "altitude": "131",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "MOA": {
        "name": "Orestes Acosta Airport",
        "city": "Moa",
        "country": "Cuba",
        "iata": "MOA",
        "icao": "MUMO",
        "latitude": "20.653900146484375",
        "longitude": "-74.92220306396484",
        "altitude": "16",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "MZO": {
        "name": "Sierra Maestra Airport",
        "city": "Manzanillo",
        "country": "Cuba",
        "iata": "MZO",
        "icao": "MUMZ",
        "latitude": "20.28809928894043",
        "longitude": "-77.08920288085938",
        "altitude": "112",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "GER": {
        "name": "Rafael Cabrera Airport",
        "city": "Nueva Gerona",
        "country": "Cuba",
        "iata": "GER",
        "icao": "MUNG",
        "latitude": "21.834699630737305",
        "longitude": "-82.78379821777344",
        "altitude": "79",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "UPB": {
        "name": "Playa Baracoa Airport",
        "city": "Baracoa Playa",
        "country": "Cuba",
        "iata": "UPB",
        "icao": "MUPB",
        "latitude": "23.032800674399997",
        "longitude": "-82.5793991089",
        "altitude": "102",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "QPD": {
        "name": "Pinar Del Rio Airport",
        "city": "Pinar Del Rio Norte",
        "country": "Cuba",
        "iata": "QPD",
        "icao": "MUPR",
        "latitude": "22.42140007019043",
        "longitude": "-83.67839813232422",
        "altitude": "131",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "SNU": {
        "name": "Abel Santamaria Airport",
        "city": "Santa Clara",
        "country": "Cuba",
        "iata": "SNU",
        "icao": "MUSC",
        "latitude": "22.49220085144043",
        "longitude": "-79.943603515625",
        "altitude": "338",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "SZJ": {
        "name": "Siguanea Airport",
        "city": "Siguanea",
        "country": "Cuba",
        "iata": "SZJ",
        "icao": "MUSN",
        "latitude": "21.642499923706055",
        "longitude": "-82.9551010131836",
        "altitude": "39",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "USS": {
        "name": "Sancti Spiritus Airport",
        "city": "Sancti Spiritus",
        "country": "Cuba",
        "iata": "USS",
        "icao": "MUSS",
        "latitude": "21.9704",
        "longitude": "-79.442703",
        "altitude": "295",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "VRA": {
        "name": "Juan Gualberto Gomez International Airport",
        "city": "Varadero",
        "country": "Cuba",
        "iata": "VRA",
        "icao": "MUVR",
        "latitude": "23.034400939941406",
        "longitude": "-81.435302734375",
        "altitude": "210",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "VTU": {
        "name": "Hermanos Ameijeiras Airport",
        "city": "Las Tunas",
        "country": "Cuba",
        "iata": "VTU",
        "icao": "MUVT",
        "latitude": "20.987600326538086",
        "longitude": "-76.93579864501953",
        "altitude": "328",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "CYB": {
        "name": "Gerrard Smith International Airport",
        "city": "Cayman Brac",
        "country": "Cayman Islands",
        "iata": "CYB",
        "icao": "MWCB",
        "latitude": "19.687000274658203",
        "longitude": "-79.88279724121094",
        "altitude": "8",
        "timezone": "-5",
        "dst": "N",
        "tz_name": "America/Cayman"
    },
    "GCM": {
        "name": "Owen Roberts International Airport",
        "city": "Georgetown",
        "country": "Cayman Islands",
        "iata": "GCM",
        "icao": "MWCR",
        "latitude": "19.292800903299998",
        "longitude": "-81.3576965332",
        "altitude": "8",
        "timezone": "-5",
        "dst": "N",
        "tz_name": "America/Cayman"
    },
    "MAY": {
        "name": "Clarence A. Bain Airport",
        "city": "Clarence Bain",
        "country": "Bahamas",
        "iata": "MAY",
        "icao": "MYAB",
        "latitude": "24.287700653076172",
        "longitude": "-77.68460083007812",
        "altitude": "19",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "ASD": {
        "name": "Andros Town Airport",
        "city": "Andros Town",
        "country": "Bahamas",
        "iata": "ASD",
        "icao": "MYAF",
        "latitude": "24.697900772094727",
        "longitude": "-77.79560089111328",
        "altitude": "5",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "MHH": {
        "name": "Leonard M Thompson International Airport",
        "city": "Marsh Harbor",
        "country": "Bahamas",
        "iata": "MHH",
        "icao": "MYAM",
        "latitude": "26.5114",
        "longitude": "-77.083503",
        "altitude": "6",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "SAQ": {
        "name": "San Andros Airport",
        "city": "San Andros",
        "country": "Bahamas",
        "iata": "SAQ",
        "icao": "MYAN",
        "latitude": "25.053800582885742",
        "longitude": "-78.04900360107422",
        "altitude": "5",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "AXP": {
        "name": "Spring Point Airport",
        "city": "Spring Point",
        "country": "Bahamas",
        "iata": "AXP",
        "icao": "MYAP",
        "latitude": "22.441799163800003",
        "longitude": "-73.97090148930002",
        "altitude": "11",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "TCB": {
        "name": "Treasure Cay Airport",
        "city": "Treasure Cay",
        "country": "Bahamas",
        "iata": "TCB",
        "icao": "MYAT",
        "latitude": "26.745300293",
        "longitude": "-77.3912963867",
        "altitude": "8",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "CCZ": {
        "name": "Chub Cay Airport",
        "city": "Chub Cay",
        "country": "Bahamas",
        "iata": "CCZ",
        "icao": "MYBC",
        "latitude": "25.41710090637207",
        "longitude": "-77.88089752197266",
        "altitude": "5",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "GHC": {
        "name": "Great Harbour Cay Airport",
        "city": "Bullocks Harbour",
        "country": "Bahamas",
        "iata": "GHC",
        "icao": "MYBG",
        "latitude": "25.7383",
        "longitude": "-77.840103",
        "altitude": "18",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "BIM": {
        "name": "South Bimini Airport",
        "city": "Alice Town",
        "country": "Bahamas",
        "iata": "BIM",
        "icao": "MYBS",
        "latitude": "25.6998996735",
        "longitude": "-79.2647018433",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "GGT": {
        "name": "Exuma International Airport",
        "city": "Great Exuma",
        "country": "Bahamas",
        "iata": "GGT",
        "icao": "MYEF",
        "latitude": "23.5625991821",
        "longitude": "-75.8779983521",
        "altitude": "9",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "ELH": {
        "name": "North Eleuthera Airport",
        "city": "North Eleuthera",
        "country": "Bahamas",
        "iata": "ELH",
        "icao": "MYEH",
        "latitude": "25.474899292",
        "longitude": "-76.6835021973",
        "altitude": "13",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "GHB": {
        "name": "Governor's Harbour Airport",
        "city": "Governor's Harbor",
        "country": "Bahamas",
        "iata": "GHB",
        "icao": "MYEM",
        "latitude": "25.2847003937",
        "longitude": "-76.3310012817",
        "altitude": "26",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "NMC": {
        "name": "Normans Cay Airport",
        "city": "Norman's Cay",
        "country": "Bahamas",
        "iata": "NMC",
        "icao": "MYEN",
        "latitude": "24.59429931640625",
        "longitude": "-76.82019805908203",
        "altitude": "8",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "RSD": {
        "name": "Rock Sound Airport",
        "city": "Rock Sound",
        "country": "Bahamas",
        "iata": "RSD",
        "icao": "MYER",
        "latitude": "24.8950787333",
        "longitude": "-76.1768817902",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "TYM": {
        "name": "Staniel Cay Airport",
        "city": "Staniel Cay",
        "country": "Bahamas",
        "iata": "TYM",
        "icao": "MYES",
        "latitude": "24.169099807739258",
        "longitude": "-76.43910217285156",
        "altitude": "5",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "FPO": {
        "name": "Grand Bahama International Airport",
        "city": "Freeport",
        "country": "Bahamas",
        "iata": "FPO",
        "icao": "MYGF",
        "latitude": "26.5587005615",
        "longitude": "-78.695602417",
        "altitude": "7",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "IGA": {
        "name": "Inagua Airport",
        "city": "Matthew Town",
        "country": "Bahamas",
        "iata": "IGA",
        "icao": "MYIG",
        "latitude": "20.975000381469727",
        "longitude": "-73.66690063476562",
        "altitude": "8",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "LGI": {
        "name": "Deadman's Cay Airport",
        "city": "Dead Man's Cay",
        "country": "Bahamas",
        "iata": "LGI",
        "icao": "MYLD",
        "latitude": "23.1790008545",
        "longitude": "-75.09359741210001",
        "altitude": "9",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "SML": {
        "name": "Stella Maris Airport",
        "city": "Stella Maris",
        "country": "Bahamas",
        "iata": "SML",
        "icao": "MYLS",
        "latitude": "23.582317",
        "longitude": "-75.268621",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "MYG": {
        "name": "Mayaguana Airport",
        "city": "Mayaguana",
        "country": "Bahamas",
        "iata": "MYG",
        "icao": "MYMM",
        "latitude": "22.379499",
        "longitude": "-73.013494",
        "altitude": "11",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "NAS": {
        "name": "Lynden Pindling International Airport",
        "city": "Nassau",
        "country": "Bahamas",
        "iata": "NAS",
        "icao": "MYNN",
        "latitude": "25.0389995575",
        "longitude": "-77.46620178219999",
        "altitude": "16",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "DCT": {
        "name": "Duncan Town Airport",
        "city": "Duncan Town",
        "country": "Bahamas",
        "iata": "DCT",
        "icao": "MYRD",
        "latitude": "22.181800842285156",
        "longitude": "-75.72949981689453",
        "altitude": "6",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "RCY": {
        "name": "Rum Cay Airport",
        "city": "Port Nelson",
        "country": "Bahamas",
        "iata": "RCY",
        "icao": "MYRP",
        "latitude": "23.68440055847168",
        "longitude": "-74.83619689941406",
        "altitude": "15",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "ZSA": {
        "name": "San Salvador Airport",
        "city": "Cockburn Town",
        "country": "Bahamas",
        "iata": "ZSA",
        "icao": "MYSM",
        "latitude": "24.06329917907715",
        "longitude": "-74.52400207519531",
        "altitude": "24",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "BZE": {
        "name": "Philip S. W. Goldson International Airport",
        "city": "Belize City",
        "country": "Belize",
        "iata": "BZE",
        "icao": "MZBZ",
        "latitude": "17.539100646972656",
        "longitude": "-88.30819702148438",
        "altitude": "15",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Belize"
    },
    "AIT": {
        "name": "Aitutaki Airport",
        "city": "Aitutaki",
        "country": "Cook Islands",
        "iata": "AIT",
        "icao": "NCAI",
        "latitude": "-18.830900192260742",
        "longitude": "-159.76400756835938",
        "altitude": "14",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "RAR": {
        "name": "Rarotonga International Airport",
        "city": "Avarua",
        "country": "Cook Islands",
        "iata": "RAR",
        "icao": "NCRG",
        "latitude": "-21.2026996613",
        "longitude": "-159.805999756",
        "altitude": "19",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "NAN": {
        "name": "Nadi International Airport",
        "city": "Nandi",
        "country": "Fiji",
        "iata": "NAN",
        "icao": "NFFN",
        "latitude": "-17.755399703979492",
        "longitude": "177.4429931640625",
        "altitude": "59",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "SUV": {
        "name": "Nausori International Airport",
        "city": "Nausori",
        "country": "Fiji",
        "iata": "SUV",
        "icao": "NFNA",
        "latitude": "-18.04330062866211",
        "longitude": "178.5590057373047",
        "altitude": "17",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "TBU": {
        "name": "Fua'amotu International Airport",
        "city": "Tongatapu",
        "country": "Tonga",
        "iata": "TBU",
        "icao": "NFTF",
        "latitude": "-21.241199493408203",
        "longitude": "-175.14999389648438",
        "altitude": "126",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Tongatapu"
    },
    "VAV": {
        "name": "Vava'u International Airport",
        "city": "Vava'u",
        "country": "Tonga",
        "iata": "VAV",
        "icao": "NFTV",
        "latitude": "-18.58530044555664",
        "longitude": "-173.96200561523438",
        "altitude": "236",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Tongatapu"
    },
    "TRW": {
        "name": "Bonriki International Airport",
        "city": "Tarawa",
        "country": "Kiribati",
        "iata": "TRW",
        "icao": "NGTA",
        "latitude": "1.3816399574279785",
        "longitude": "173.14700317382812",
        "altitude": "9",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "TBF": {
        "name": "Tabiteuea North Airport",
        "city": "Tabiteuea North",
        "country": "Kiribati",
        "iata": "TBF",
        "icao": "NGTE",
        "latitude": "-1.2244700193405151",
        "longitude": "174.7760009765625",
        "altitude": "7",
        "timezone": "12",
        "dst": "U",
        "tz_name": null
    },
    "WLS": {
        "name": "Hihifo Airport",
        "city": "Wallis",
        "country": "Wallis and Futuna",
        "iata": "WLS",
        "icao": "NLWW",
        "latitude": "-13.2383003235",
        "longitude": "-176.199005127",
        "altitude": "79",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Wallis"
    },
    "APW": {
        "name": "Faleolo International Airport",
        "city": "Faleolo",
        "country": "Samoa",
        "iata": "APW",
        "icao": "NSFA",
        "latitude": "-13.829999923706055",
        "longitude": "-172.00799560546875",
        "altitude": "58",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Apia"
    },
    "PPG": {
        "name": "Pago Pago International Airport",
        "city": "Pago Pago",
        "country": "American Samoa",
        "iata": "PPG",
        "icao": "NSTU",
        "latitude": "-14.3310003281",
        "longitude": "-170.710006714",
        "altitude": "32",
        "timezone": "-11",
        "dst": "U",
        "tz_name": "Pacific/Pago_Pago"
    },
    "RUR": {
        "name": "Rurutu Airport",
        "city": "Rurutu",
        "country": "French Polynesia",
        "iata": "RUR",
        "icao": "NTAR",
        "latitude": "-22.434099197387695",
        "longitude": "-151.36099243164062",
        "altitude": "18",
        "timezone": "-10",
        "dst": "U",
        "tz_name": null
    },
    "TUB": {
        "name": "Tubuai Airport",
        "city": "Tubuai",
        "country": "French Polynesia",
        "iata": "TUB",
        "icao": "NTAT",
        "latitude": "-23.365400314331055",
        "longitude": "-149.5240020751953",
        "altitude": "7",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "AAA": {
        "name": "Anaa Airport",
        "city": "Anaa",
        "country": "French Polynesia",
        "iata": "AAA",
        "icao": "NTGA",
        "latitude": "-17.35260009765625",
        "longitude": "-145.50999450683594",
        "altitude": "10",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "FGU": {
        "name": "Fangatau Airport",
        "city": "Fangatau",
        "country": "French Polynesia",
        "iata": "FGU",
        "icao": "NTGB",
        "latitude": "-15.819899559020996",
        "longitude": "-140.88699340820312",
        "altitude": "9",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "TIH": {
        "name": "Tikehau Airport",
        "city": "Tikehau",
        "country": "French Polynesia",
        "iata": "TIH",
        "icao": "NTGC",
        "latitude": "-15.119600296020508",
        "longitude": "-148.2310028076172",
        "altitude": "6",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "REA": {
        "name": "Reao Airport",
        "city": "Reao",
        "country": "French Polynesia",
        "iata": "REA",
        "icao": "NTGE",
        "latitude": "-18.465900421142578",
        "longitude": "-136.44000244140625",
        "altitude": "12",
        "timezone": "-10",
        "dst": "U",
        "tz_name": null
    },
    "FAV": {
        "name": "Fakarava Airport",
        "city": "Fakarava",
        "country": "French Polynesia",
        "iata": "FAV",
        "icao": "NTGF",
        "latitude": "-16.054100036621094",
        "longitude": "-145.65699768066406",
        "altitude": "13",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "XMH": {
        "name": "Manihi Airport",
        "city": "Manihi",
        "country": "French Polynesia",
        "iata": "XMH",
        "icao": "NTGI",
        "latitude": "-14.436800003051758",
        "longitude": "-146.07000732421875",
        "altitude": "14",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "GMR": {
        "name": "Totegegie Airport",
        "city": "Totegegie",
        "country": "French Polynesia",
        "iata": "GMR",
        "icao": "NTGJ",
        "latitude": "-23.07990074157715",
        "longitude": "-134.88999938964844",
        "altitude": "7",
        "timezone": "-9",
        "dst": "U",
        "tz_name": "Pacific/Gambier"
    },
    "KKR": {
        "name": "Kaukura Airport",
        "city": "Kaukura Atoll",
        "country": "French Polynesia",
        "iata": "KKR",
        "icao": "NTGK",
        "latitude": "-15.663299560546875",
        "longitude": "-146.88499450683594",
        "altitude": "11",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "MKP": {
        "name": "Makemo Airport",
        "city": "Makemo",
        "country": "French Polynesia",
        "iata": "MKP",
        "icao": "NTGM",
        "latitude": "-16.583900451660156",
        "longitude": "-143.6580047607422",
        "altitude": "3",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "PKP": {
        "name": "Puka Puka Airport",
        "city": "Puka Puka",
        "country": "French Polynesia",
        "iata": "PKP",
        "icao": "NTGP",
        "latitude": "-14.809499740600586",
        "longitude": "-138.81300354003906",
        "altitude": "5",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "TKP": {
        "name": "Takapoto Airport",
        "city": "Takapoto",
        "country": "French Polynesia",
        "iata": "TKP",
        "icao": "NTGT",
        "latitude": "-14.709500312805176",
        "longitude": "-145.24600219726562",
        "altitude": "12",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "AXR": {
        "name": "Arutua Airport",
        "city": "Arutua",
        "country": "French Polynesia",
        "iata": "AXR",
        "icao": "NTGU",
        "latitude": "-15.248299598693848",
        "longitude": "-146.61700439453125",
        "altitude": "9",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "MVT": {
        "name": "Mataiva Airport",
        "city": "Mataiva",
        "country": "French Polynesia",
        "iata": "MVT",
        "icao": "NTGV",
        "latitude": "-14.8681001663208",
        "longitude": "-148.7169952392578",
        "altitude": "11",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "TKX": {
        "name": "Takaroa Airport",
        "city": "Takaroa",
        "country": "French Polynesia",
        "iata": "TKX",
        "icao": "NTKR",
        "latitude": "-14.45580005645752",
        "longitude": "-145.02499389648438",
        "altitude": "13",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "NHV": {
        "name": "Nuku Hiva Airport",
        "city": "Nuku Hiva",
        "country": "French Polynesia",
        "iata": "NHV",
        "icao": "NTMD",
        "latitude": "-8.795599937438965",
        "longitude": "-140.22900390625",
        "altitude": "220",
        "timezone": "-9.5",
        "dst": "U",
        "tz_name": "Pacific/Marquesas"
    },
    "BOB": {
        "name": "Bora Bora Airport",
        "city": "Bora Bora",
        "country": "French Polynesia",
        "iata": "BOB",
        "icao": "NTTB",
        "latitude": "-16.444400787353516",
        "longitude": "-151.75100708007812",
        "altitude": "10",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "RGI": {
        "name": "Rangiroa Airport",
        "city": "Rangiroa",
        "country": "French Polynesia",
        "iata": "RGI",
        "icao": "NTTG",
        "latitude": "-14.954299926757812",
        "longitude": "-147.66099548339844",
        "altitude": "10",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "HUH": {
        "name": "Huahine-Fare Airport",
        "city": "Huahine Island",
        "country": "French Polynesia",
        "iata": "HUH",
        "icao": "NTTH",
        "latitude": "-16.68720054626465",
        "longitude": "-151.02200317382812",
        "altitude": "7",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "MOZ": {
        "name": "Moorea Airport",
        "city": "Moorea",
        "country": "French Polynesia",
        "iata": "MOZ",
        "icao": "NTTM",
        "latitude": "-17.49",
        "longitude": "-149.761993",
        "altitude": "9",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "HOI": {
        "name": "Hao Airport",
        "city": "Hao Island",
        "country": "French Polynesia",
        "iata": "HOI",
        "icao": "NTTO",
        "latitude": "-18.074800491333008",
        "longitude": "-140.9459991455078",
        "altitude": "10",
        "timezone": "-10",
        "dst": "U",
        "tz_name": null
    },
    "MAU": {
        "name": "Maupiti Airport",
        "city": "Maupiti",
        "country": "French Polynesia",
        "iata": "MAU",
        "icao": "NTTP",
        "latitude": "-16.42650032043457",
        "longitude": "-152.24400329589844",
        "altitude": "15",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "RFP": {
        "name": "Raiatea Airport",
        "city": "Raiatea Island",
        "country": "French Polynesia",
        "iata": "RFP",
        "icao": "NTTR",
        "latitude": "-16.7229",
        "longitude": "-151.466003",
        "altitude": "3",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "VLI": {
        "name": "Bauerfield International Airport",
        "city": "Port-vila",
        "country": "Vanuatu",
        "iata": "VLI",
        "icao": "NVVV",
        "latitude": "-17.699300765991",
        "longitude": "168.32000732422",
        "altitude": "70",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "KNQ": {
        "name": "Koné Airport",
        "city": "Kone",
        "country": "New Caledonia",
        "iata": "KNQ",
        "icao": "NWWD",
        "latitude": "-21.05430030822754",
        "longitude": "164.83700561523438",
        "altitude": "23",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "KOC": {
        "name": "Koumac Airport",
        "city": "Koumac",
        "country": "New Caledonia",
        "iata": "KOC",
        "icao": "NWWK",
        "latitude": "-20.546300888061523",
        "longitude": "164.25599670410156",
        "altitude": "42",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "LIF": {
        "name": "Lifou Airport",
        "city": "Lifou",
        "country": "New Caledonia",
        "iata": "LIF",
        "icao": "NWWL",
        "latitude": "-20.774799346923828",
        "longitude": "167.24000549316406",
        "altitude": "92",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "GEA": {
        "name": "Nouméa Magenta Airport",
        "city": "Noumea",
        "country": "New Caledonia",
        "iata": "GEA",
        "icao": "NWWM",
        "latitude": "-22.25830078125",
        "longitude": "166.47300720214844",
        "altitude": "10",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "MEE": {
        "name": "Maré Airport",
        "city": "Mare",
        "country": "New Caledonia",
        "iata": "MEE",
        "icao": "NWWR",
        "latitude": "-21.481700897216797",
        "longitude": "168.03799438476562",
        "altitude": "141",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "TOU": {
        "name": "Touho Airport",
        "city": "Touho",
        "country": "New Caledonia",
        "iata": "TOU",
        "icao": "NWWU",
        "latitude": "-20.790000915527344",
        "longitude": "165.25900268554688",
        "altitude": "10",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "UVE": {
        "name": "Ouvéa Airport",
        "city": "Ouvea",
        "country": "New Caledonia",
        "iata": "UVE",
        "icao": "NWWV",
        "latitude": "-20.640600204467773",
        "longitude": "166.572998046875",
        "altitude": "23",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "NOU": {
        "name": "La Tontouta International Airport",
        "city": "Noumea",
        "country": "New Caledonia",
        "iata": "NOU",
        "icao": "NWWW",
        "latitude": "-22.01460075378418",
        "longitude": "166.21299743652344",
        "altitude": "52",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "AKL": {
        "name": "Auckland International Airport",
        "city": "Auckland",
        "country": "New Zealand",
        "iata": "AKL",
        "icao": "NZAA",
        "latitude": "-37.008098602299995",
        "longitude": "174.792007446",
        "altitude": "23",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "TUO": {
        "name": "Taupo Airport",
        "city": "Taupo",
        "country": "New Zealand",
        "iata": "TUO",
        "icao": "NZAP",
        "latitude": "-38.73970031738281",
        "longitude": "176.08399963378906",
        "altitude": "1335",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "AMZ": {
        "name": "Ardmore Airport",
        "city": "Ardmore",
        "country": "New Zealand",
        "iata": "AMZ",
        "icao": "NZAR",
        "latitude": "-37.029701232910156",
        "longitude": "174.97300720214844",
        "altitude": "111",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "CHC": {
        "name": "Christchurch International Airport",
        "city": "Christchurch",
        "country": "New Zealand",
        "iata": "CHC",
        "icao": "NZCH",
        "latitude": "-43.48939895629883",
        "longitude": "172.53199768066406",
        "altitude": "123",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "CHT": {
        "name": "Chatham Islands-Tuuta Airport",
        "city": "Chatham Island",
        "country": "New Zealand",
        "iata": "CHT",
        "icao": "NZCI",
        "latitude": "-43.810001373291016",
        "longitude": "-176.45700073242188",
        "altitude": "43",
        "timezone": "12.75",
        "dst": "Z",
        "tz_name": "Pacific/Chatham"
    },
    "DUD": {
        "name": "Dunedin Airport",
        "city": "Dunedin",
        "country": "New Zealand",
        "iata": "DUD",
        "icao": "NZDN",
        "latitude": "-45.9281005859375",
        "longitude": "170.197998046875",
        "altitude": "4",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "GIS": {
        "name": "Gisborne Airport",
        "city": "Gisborne",
        "country": "New Zealand",
        "iata": "GIS",
        "icao": "NZGS",
        "latitude": "-38.663299560546875",
        "longitude": "177.97799682617188",
        "altitude": "15",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "GTN": {
        "name": "Glentanner Airport",
        "city": "Glentanner",
        "country": "New Zealand",
        "iata": "GTN",
        "icao": "NZGT",
        "latitude": "-43.906700134277344",
        "longitude": "170.1280059814453",
        "altitude": "1824",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "HKK": {
        "name": "Hokitika Airfield",
        "city": "Hokitika",
        "country": "New Zealand",
        "iata": "HKK",
        "icao": "NZHK",
        "latitude": "-42.713600158691406",
        "longitude": "170.98500061035156",
        "altitude": "146",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "HLZ": {
        "name": "Hamilton International Airport",
        "city": "Hamilton",
        "country": "New Zealand",
        "iata": "HLZ",
        "icao": "NZHN",
        "latitude": "-37.8666992188",
        "longitude": "175.332000732",
        "altitude": "172",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "KKE": {
        "name": "Kerikeri Airport",
        "city": "Kerikeri",
        "country": "New Zealand",
        "iata": "KKE",
        "icao": "NZKK",
        "latitude": "-35.26279830932617",
        "longitude": "173.91200256347656",
        "altitude": "492",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "KAT": {
        "name": "Kaitaia Airport",
        "city": "Kaitaia",
        "country": "New Zealand",
        "iata": "KAT",
        "icao": "NZKT",
        "latitude": "-35.06999969482422",
        "longitude": "173.28500366210938",
        "altitude": "270",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "ALR": {
        "name": "Alexandra Airport",
        "city": "Alexandra",
        "country": "New Zealand",
        "iata": "ALR",
        "icao": "NZLX",
        "latitude": "-45.211700439453125",
        "longitude": "169.3730010986328",
        "altitude": "752",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "MON": {
        "name": "Mount Cook Airport",
        "city": "Mount Cook",
        "country": "New Zealand",
        "iata": "MON",
        "icao": "NZMC",
        "latitude": "-43.76499938964844",
        "longitude": "170.13299560546875",
        "altitude": "2153",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "TEU": {
        "name": "Manapouri Airport",
        "city": "Manapouri",
        "country": "New Zealand",
        "iata": "TEU",
        "icao": "NZMO",
        "latitude": "-45.53310012817383",
        "longitude": "167.64999389648438",
        "altitude": "687",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "MRO": {
        "name": "Hood Airport",
        "city": "Masterton",
        "country": "New Zealand",
        "iata": "MRO",
        "icao": "NZMS",
        "latitude": "-40.97330093383789",
        "longitude": "175.63400268554688",
        "altitude": "364",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "NPL": {
        "name": "New Plymouth Airport",
        "city": "New Plymouth",
        "country": "New Zealand",
        "iata": "NPL",
        "icao": "NZNP",
        "latitude": "-39.00859832763672",
        "longitude": "174.1790008544922",
        "altitude": "97",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "NSN": {
        "name": "Nelson Airport",
        "city": "Nelson",
        "country": "New Zealand",
        "iata": "NSN",
        "icao": "NZNS",
        "latitude": "-41.298301696777344",
        "longitude": "173.2209930419922",
        "altitude": "17",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "IVC": {
        "name": "Invercargill Airport",
        "city": "Invercargill",
        "country": "New Zealand",
        "iata": "IVC",
        "icao": "NZNV",
        "latitude": "-46.41239929199219",
        "longitude": "168.31300354003906",
        "altitude": "5",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "OHA": {
        "name": "RNZAF Base Ohakea",
        "city": "Ohakea",
        "country": "New Zealand",
        "iata": "OHA",
        "icao": "NZOH",
        "latitude": "-40.20600128173828",
        "longitude": "175.38800048828125",
        "altitude": "164",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "OAM": {
        "name": "Oamaru Airport",
        "city": "Oamaru",
        "country": "New Zealand",
        "iata": "OAM",
        "icao": "NZOU",
        "latitude": "-44.970001220703125",
        "longitude": "171.08200073242188",
        "altitude": "99",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "PMR": {
        "name": "Palmerston North Airport",
        "city": "Palmerston North",
        "country": "New Zealand",
        "iata": "PMR",
        "icao": "NZPM",
        "latitude": "-40.32059860229492",
        "longitude": "175.61700439453125",
        "altitude": "151",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "PPQ": {
        "name": "Paraparaumu Airport",
        "city": "Paraparaumu",
        "country": "New Zealand",
        "iata": "PPQ",
        "icao": "NZPP",
        "latitude": "-40.904701232910156",
        "longitude": "174.98899841308594",
        "altitude": "22",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "ZQN": {
        "name": "Queenstown International Airport",
        "city": "Queenstown International",
        "country": "New Zealand",
        "iata": "ZQN",
        "icao": "NZQN",
        "latitude": "-45.0210990906",
        "longitude": "168.738998413",
        "altitude": "1171",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "ROT": {
        "name": "Rotorua Regional Airport",
        "city": "Rotorua",
        "country": "New Zealand",
        "iata": "ROT",
        "icao": "NZRO",
        "latitude": "-38.10919952392578",
        "longitude": "176.31700134277344",
        "altitude": "935",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "TRG": {
        "name": "Tauranga Airport",
        "city": "Tauranga",
        "country": "New Zealand",
        "iata": "TRG",
        "icao": "NZTG",
        "latitude": "-37.67190170288086",
        "longitude": "176.1959991455078",
        "altitude": "13",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "TIU": {
        "name": "Timaru Airport",
        "city": "Timaru",
        "country": "New Zealand",
        "iata": "TIU",
        "icao": "NZTU",
        "latitude": "-44.302799224853516",
        "longitude": "171.22500610351562",
        "altitude": "89",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "TWZ": {
        "name": "Pukaki Airport",
        "city": "Pukaki",
        "country": "New Zealand",
        "iata": "TWZ",
        "icao": "NZUK",
        "latitude": "-44.2350006104",
        "longitude": "170.117996216",
        "altitude": "1575",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "BHE": {
        "name": "Woodbourne Airport",
        "city": "Woodbourne",
        "country": "New Zealand",
        "iata": "BHE",
        "icao": "NZWB",
        "latitude": "-41.5182991027832",
        "longitude": "173.8699951171875",
        "altitude": "109",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WKA": {
        "name": "Wanaka Airport",
        "city": "Wanaka",
        "country": "New Zealand",
        "iata": "WKA",
        "icao": "NZWF",
        "latitude": "-44.722198486328",
        "longitude": "169.24600219727",
        "altitude": "1142",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WHK": {
        "name": "Whakatane Airport",
        "city": "Whakatane",
        "country": "New Zealand",
        "iata": "WHK",
        "icao": "NZWK",
        "latitude": "-37.92060089111328",
        "longitude": "176.91400146484375",
        "altitude": "20",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WLG": {
        "name": "Wellington International Airport",
        "city": "Wellington",
        "country": "New Zealand",
        "iata": "WLG",
        "icao": "NZWN",
        "latitude": "-41.3272018433",
        "longitude": "174.804992676",
        "altitude": "41",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WIR": {
        "name": "Wairoa Airport",
        "city": "Wairoa",
        "country": "New Zealand",
        "iata": "WIR",
        "icao": "NZWO",
        "latitude": "-39.006900787353516",
        "longitude": "177.40699768066406",
        "altitude": "42",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WRE": {
        "name": "Whangarei Airport",
        "city": "Whangarei",
        "country": "New Zealand",
        "iata": "WRE",
        "icao": "NZWR",
        "latitude": "-35.7682991027832",
        "longitude": "174.36500549316406",
        "altitude": "133",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WSZ": {
        "name": "Westport Airport",
        "city": "Westport",
        "country": "New Zealand",
        "iata": "WSZ",
        "icao": "NZWS",
        "latitude": "-41.73809814453125",
        "longitude": "171.58099365234375",
        "altitude": "13",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WAG": {
        "name": "Wanganui Airport",
        "city": "Wanganui",
        "country": "New Zealand",
        "iata": "WAG",
        "icao": "NZWU",
        "latitude": "-39.96220016479492",
        "longitude": "175.02499389648438",
        "altitude": "27",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "HEA": {
        "name": "Herat Airport",
        "city": "Herat",
        "country": "Afghanistan",
        "iata": "HEA",
        "icao": "OAHR",
        "latitude": "34.209999084472656",
        "longitude": "62.22829818725586",
        "altitude": "3206",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "JAA": {
        "name": "Jalalabad Airport",
        "city": "Jalalabad",
        "country": "Afghanistan",
        "iata": "JAA",
        "icao": "OAJL",
        "latitude": "34.39979934692383",
        "longitude": "70.49859619140625",
        "altitude": "1814",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "KBL": {
        "name": "Hamid Karzai International Airport",
        "city": "Kabul",
        "country": "Afghanistan",
        "iata": "KBL",
        "icao": "OAKB",
        "latitude": "34.565899",
        "longitude": "69.212303",
        "altitude": "5877",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "KDH": {
        "name": "Kandahar Airport",
        "city": "Kandahar",
        "country": "Afghanistan",
        "iata": "KDH",
        "icao": "OAKN",
        "latitude": "31.505800247192383",
        "longitude": "65.8478012084961",
        "altitude": "3337",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "MMZ": {
        "name": "Maimana Airport",
        "city": "Maimama",
        "country": "Afghanistan",
        "iata": "MMZ",
        "icao": "OAMN",
        "latitude": "35.93080139160156",
        "longitude": "64.76090240478516",
        "altitude": "2743",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "MZR": {
        "name": "Mazar I Sharif Airport",
        "city": "Mazar-i-sharif",
        "country": "Afghanistan",
        "iata": "MZR",
        "icao": "OAMS",
        "latitude": "36.70690155029297",
        "longitude": "67.20970153808594",
        "altitude": "1284",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "OAH": {
        "name": "Shindand Airport",
        "city": "Shindand",
        "country": "Afghanistan",
        "iata": "OAH",
        "icao": "OASD",
        "latitude": "33.3913002014",
        "longitude": "62.2610015869",
        "altitude": "3773",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "UND": {
        "name": "Konduz Airport",
        "city": "Kunduz",
        "country": "Afghanistan",
        "iata": "UND",
        "icao": "OAUZ",
        "latitude": "36.66510009765625",
        "longitude": "68.91079711914062",
        "altitude": "1457",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "BAH": {
        "name": "Bahrain International Airport",
        "city": "Bahrain",
        "country": "Bahrain",
        "iata": "BAH",
        "icao": "OBBI",
        "latitude": "26.27079963684082",
        "longitude": "50.63359832763672",
        "altitude": "6",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Bahrain"
    },
    "AHB": {
        "name": "Abha Regional Airport",
        "city": "Abha",
        "country": "Saudi Arabia",
        "iata": "AHB",
        "icao": "OEAB",
        "latitude": "18.240400314299997",
        "longitude": "42.65660095210001",
        "altitude": "6858",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "HOF": {
        "name": "Al Ahsa Airport",
        "city": "Al-ahsa",
        "country": "Saudi Arabia",
        "iata": "HOF",
        "icao": "OEAH",
        "latitude": "25.28529930114746",
        "longitude": "49.485198974609375",
        "altitude": "588",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "ABT": {
        "name": "Al Baha Airport",
        "city": "El-baha",
        "country": "Saudi Arabia",
        "iata": "ABT",
        "icao": "OEBA",
        "latitude": "20.2961006165",
        "longitude": "41.6343002319",
        "altitude": "5486",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "BHH": {
        "name": "Bisha Airport",
        "city": "Bisha",
        "country": "Saudi Arabia",
        "iata": "BHH",
        "icao": "OEBH",
        "latitude": "19.984399795532227",
        "longitude": "42.62089920043945",
        "altitude": "3887",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "DMM": {
        "name": "King Fahd International Airport",
        "city": "Dammam",
        "country": "Saudi Arabia",
        "iata": "DMM",
        "icao": "OEDF",
        "latitude": "26.471200942993164",
        "longitude": "49.79790115356445",
        "altitude": "72",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "DHA": {
        "name": "King Abdulaziz Air Base",
        "city": "Dhahran",
        "country": "Saudi Arabia",
        "iata": "DHA",
        "icao": "OEDR",
        "latitude": "26.265399932900003",
        "longitude": "50.152000427199994",
        "altitude": "84",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "GIZ": {
        "name": "Jizan Regional Airport",
        "city": "Gizan",
        "country": "Saudi Arabia",
        "iata": "GIZ",
        "icao": "OEGN",
        "latitude": "16.901100158691406",
        "longitude": "42.58580017089844",
        "altitude": "20",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "ELQ": {
        "name": "Gassim Airport",
        "city": "Gassim",
        "country": "Saudi Arabia",
        "iata": "ELQ",
        "icao": "OEGS",
        "latitude": "26.302799224853516",
        "longitude": "43.77439880371094",
        "altitude": "2126",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "URY": {
        "name": "Gurayat Domestic Airport",
        "city": "Guriat",
        "country": "Saudi Arabia",
        "iata": "URY",
        "icao": "OEGT",
        "latitude": "31.412413",
        "longitude": "37.278898",
        "altitude": "1672",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "HAS": {
        "name": "Ha'il Airport",
        "city": "Hail",
        "country": "Saudi Arabia",
        "iata": "HAS",
        "icao": "OEHL",
        "latitude": "27.437901",
        "longitude": "41.686298",
        "altitude": "3331",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "QJB": {
        "name": "Jubail Airport",
        "city": "Jubail",
        "country": "Saudi Arabia",
        "iata": "QJB",
        "icao": "OEJB",
        "latitude": "27.038999557495117",
        "longitude": "49.40510177612305",
        "altitude": "26",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "JED": {
        "name": "King Abdulaziz International Airport",
        "city": "Jeddah",
        "country": "Saudi Arabia",
        "iata": "JED",
        "icao": "OEJN",
        "latitude": "21.6796",
        "longitude": "39.156502",
        "altitude": "48",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "KMC": {
        "name": "King Khaled Military City Airport",
        "city": "King Khalid Mil.city",
        "country": "Saudi Arabia",
        "iata": "KMC",
        "icao": "OEKK",
        "latitude": "27.9009",
        "longitude": "45.528198",
        "altitude": "1352",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "MED": {
        "name": "Prince Mohammad Bin Abdulaziz Airport",
        "city": "Madinah",
        "country": "Saudi Arabia",
        "iata": "MED",
        "icao": "OEMA",
        "latitude": "24.5534",
        "longitude": "39.705101",
        "altitude": "2151",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "EAM": {
        "name": "Nejran Airport",
        "city": "Nejran",
        "country": "Saudi Arabia",
        "iata": "EAM",
        "icao": "OENG",
        "latitude": "17.611400604248047",
        "longitude": "44.4192008972168",
        "altitude": "3982",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "AQI": {
        "name": "Al Qaisumah/Hafr Al Batin Airport",
        "city": "Hafr Al-batin",
        "country": "Saudi Arabia",
        "iata": "AQI",
        "icao": "OEPA",
        "latitude": "28.335199",
        "longitude": "46.125099",
        "altitude": "1174",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "RAH": {
        "name": "Rafha Domestic Airport",
        "city": "Rafha",
        "country": "Saudi Arabia",
        "iata": "RAH",
        "icao": "OERF",
        "latitude": "29.626399993896484",
        "longitude": "43.4906005859375",
        "altitude": "1474",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "RUH": {
        "name": "King Khaled International Airport",
        "city": "Riyadh",
        "country": "Saudi Arabia",
        "iata": "RUH",
        "icao": "OERK",
        "latitude": "24.957599639892578",
        "longitude": "46.69879913330078",
        "altitude": "2049",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "RAE": {
        "name": "Arar Domestic Airport",
        "city": "Arar",
        "country": "Saudi Arabia",
        "iata": "RAE",
        "icao": "OERR",
        "latitude": "30.906600952148438",
        "longitude": "41.13819885253906",
        "altitude": "1813",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "SHW": {
        "name": "Sharurah Airport",
        "city": "Sharurah",
        "country": "Saudi Arabia",
        "iata": "SHW",
        "icao": "OESH",
        "latitude": "17.466899871826172",
        "longitude": "47.12139892578125",
        "altitude": "2363",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "SLF": {
        "name": "Sulayel Airport",
        "city": "Sulayel",
        "country": "Saudi Arabia",
        "iata": "SLF",
        "icao": "OESL",
        "latitude": "20.46470069885254",
        "longitude": "45.619598388671875",
        "altitude": "2021",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "TUU": {
        "name": "Tabuk Airport",
        "city": "Tabuk",
        "country": "Saudi Arabia",
        "iata": "TUU",
        "icao": "OETB",
        "latitude": "28.3654",
        "longitude": "36.6189",
        "altitude": "2551",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "TIF": {
        "name": "Ta’if Regional Airport",
        "city": "Taif",
        "country": "Saudi Arabia",
        "iata": "TIF",
        "icao": "OETF",
        "latitude": "21.483001",
        "longitude": "40.543442",
        "altitude": "4848",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "TUI": {
        "name": "Turaif Domestic Airport",
        "city": "Turaif",
        "country": "Saudi Arabia",
        "iata": "TUI",
        "icao": "OETR",
        "latitude": "31.692188",
        "longitude": "38.731544",
        "altitude": "2803",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "EJH": {
        "name": "Al Wajh Domestic Airport",
        "city": "Wejh",
        "country": "Saudi Arabia",
        "iata": "EJH",
        "icao": "OEWJ",
        "latitude": "26.19860076904297",
        "longitude": "36.47639846801758",
        "altitude": "66",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "YNB": {
        "name": "Prince Abdulmohsin Bin Abdulaziz Airport",
        "city": "Yenbo",
        "country": "Saudi Arabia",
        "iata": "YNB",
        "icao": "OEYN",
        "latitude": "24.144199",
        "longitude": "38.0634",
        "altitude": "26",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "ABD": {
        "name": "Abadan Airport",
        "city": "Abadan",
        "country": "Iran",
        "iata": "ABD",
        "icao": "OIAA",
        "latitude": "30.371099472",
        "longitude": "48.2282981873",
        "altitude": "10",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "DEF": {
        "name": "Dezful Airport",
        "city": "Dezful",
        "country": "Iran",
        "iata": "DEF",
        "icao": "OIAD",
        "latitude": "32.434399",
        "longitude": "48.397598",
        "altitude": "474",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AKW": {
        "name": "Aghajari Airport",
        "city": "Aghajari",
        "country": "Iran",
        "iata": "AKW",
        "icao": "OIAG",
        "latitude": "30.7444",
        "longitude": "49.6772",
        "altitude": "88",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "GCH": {
        "name": "Gachsaran Airport",
        "city": "Gachsaran",
        "country": "Iran",
        "iata": "GCH",
        "icao": "OIAH",
        "latitude": "30.337600708",
        "longitude": "50.827999115",
        "altitude": "2414",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "OMI": {
        "name": "Omidiyeh Airport",
        "city": "Omidyeh",
        "country": "Iran",
        "iata": "OMI",
        "icao": "OIAJ",
        "latitude": "30.8351993560791",
        "longitude": "49.5349006652832",
        "altitude": "85",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "MRX": {
        "name": "Mahshahr Airport",
        "city": "Bandar Mahshahr",
        "country": "Iran",
        "iata": "MRX",
        "icao": "OIAM",
        "latitude": "30.55620002746582",
        "longitude": "49.15190124511719",
        "altitude": "8",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AWZ": {
        "name": "Ahwaz Airport",
        "city": "Ahwaz",
        "country": "Iran",
        "iata": "AWZ",
        "icao": "OIAW",
        "latitude": "31.337400436399996",
        "longitude": "48.7620010376",
        "altitude": "66",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AEU": {
        "name": "Abu Musa Island Airport",
        "city": "Abumusa I.",
        "country": "Iran",
        "iata": "AEU",
        "icao": "OIBA",
        "latitude": "25.8757",
        "longitude": "55.033001",
        "altitude": "23",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "BUZ": {
        "name": "Bushehr Airport",
        "city": "Bushehr",
        "country": "Iran",
        "iata": "BUZ",
        "icao": "OIBB",
        "latitude": "28.9447994232",
        "longitude": "50.8345985413",
        "altitude": "68",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KIH": {
        "name": "Kish International Airport",
        "city": "Kish Island",
        "country": "Iran",
        "iata": "KIH",
        "icao": "OIBK",
        "latitude": "26.5261993408",
        "longitude": "53.980201721200004",
        "altitude": "101",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "BDH": {
        "name": "Bandar Lengeh Airport",
        "city": "Bandar Lengeh",
        "country": "Iran",
        "iata": "BDH",
        "icao": "OIBL",
        "latitude": "26.531999588",
        "longitude": "54.824798584",
        "altitude": "67",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KHK": {
        "name": "Khark Island Airport",
        "city": "Khark Island",
        "country": "Iran",
        "iata": "KHK",
        "icao": "OIBQ",
        "latitude": "29.260299682617188",
        "longitude": "50.32389831542969",
        "altitude": "17",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "SXI": {
        "name": "Sirri Island Airport",
        "city": "Siri Island",
        "country": "Iran",
        "iata": "SXI",
        "icao": "OIBS",
        "latitude": "25.908899307250977",
        "longitude": "54.539398193359375",
        "altitude": "43",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "LVP": {
        "name": "Lavan Island Airport",
        "city": "Lavan Island",
        "country": "Iran",
        "iata": "LVP",
        "icao": "OIBV",
        "latitude": "26.810300827026367",
        "longitude": "53.356300354003906",
        "altitude": "76",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KSH": {
        "name": "Shahid Ashrafi Esfahani Airport",
        "city": "Bakhtaran",
        "country": "Iran",
        "iata": "KSH",
        "icao": "OICC",
        "latitude": "34.3459014893",
        "longitude": "47.1581001282",
        "altitude": "4307",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "SDG": {
        "name": "Sanandaj Airport",
        "city": "Sanandaj",
        "country": "Iran",
        "iata": "SDG",
        "icao": "OICS",
        "latitude": "35.24589920043945",
        "longitude": "47.00920104980469",
        "altitude": "4522",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "IFH": {
        "name": "Hesa Airport",
        "city": "Daran",
        "country": "Iran",
        "iata": "IFH",
        "icao": "OIFE",
        "latitude": "32.92890167236328",
        "longitude": "51.561100006103516",
        "altitude": "5256",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KKS": {
        "name": "Kashan Airport",
        "city": "Kashan",
        "country": "Iran",
        "iata": "KKS",
        "icao": "OIFK",
        "latitude": "33.895302",
        "longitude": "51.577",
        "altitude": "3465",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "IFN": {
        "name": "Esfahan Shahid Beheshti International Airport",
        "city": "Esfahan",
        "country": "Iran",
        "iata": "IFN",
        "icao": "OIFM",
        "latitude": "32.75080108642578",
        "longitude": "51.86130142211914",
        "altitude": "5059",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "RAS": {
        "name": "Sardar-e-Jangal Airport",
        "city": "Rasht",
        "country": "Iran",
        "iata": "RAS",
        "icao": "OIGG",
        "latitude": "37.323333",
        "longitude": "49.617778",
        "altitude": "-40",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AJK": {
        "name": "Arak Airport",
        "city": "Arak",
        "country": "Iran",
        "iata": "AJK",
        "icao": "OIHR",
        "latitude": "34.138099670410156",
        "longitude": "49.8473014831543",
        "altitude": "5440",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "THR": {
        "name": "Mehrabad International Airport",
        "city": "Teheran",
        "country": "Iran",
        "iata": "THR",
        "icao": "OIII",
        "latitude": "35.68920135498047",
        "longitude": "51.31340026855469",
        "altitude": "3962",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "GZW": {
        "name": "Qazvin Airport",
        "city": "Ghazvin",
        "country": "Iran",
        "iata": "GZW",
        "icao": "OIIK",
        "latitude": "36.240101",
        "longitude": "50.0471",
        "altitude": "4184",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "BND": {
        "name": "Bandar Abbas International Airport",
        "city": "Bandar Abbas",
        "country": "Iran",
        "iata": "BND",
        "icao": "OIKB",
        "latitude": "27.218299865722656",
        "longitude": "56.37779998779297",
        "altitude": "22",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "JYR": {
        "name": "Jiroft Airport",
        "city": "Jiroft",
        "country": "Iran",
        "iata": "JYR",
        "icao": "OIKJ",
        "latitude": "28.726900100699996",
        "longitude": "57.67029953",
        "altitude": "2663",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KER": {
        "name": "Kerman Airport",
        "city": "Kerman",
        "country": "Iran",
        "iata": "KER",
        "icao": "OIKK",
        "latitude": "30.274400711099997",
        "longitude": "56.9510993958",
        "altitude": "5741",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "HDR": {
        "name": "Havadarya Airport",
        "city": "Bandar Abbas",
        "country": "Iran",
        "iata": "HDR",
        "icao": "OIKP",
        "latitude": "27.158300399780273",
        "longitude": "56.17250061035156",
        "altitude": "19",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "SYJ": {
        "name": "Sirjan Airport",
        "city": "Sirjan",
        "country": "Iran",
        "iata": "SYJ",
        "icao": "OIKY",
        "latitude": "29.550899505615234",
        "longitude": "55.672698974609375",
        "altitude": "5846",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "XBJ": {
        "name": "Birjand Airport",
        "city": "Birjand",
        "country": "Iran",
        "iata": "XBJ",
        "icao": "OIMB",
        "latitude": "32.898101806640625",
        "longitude": "59.2661018371582",
        "altitude": "4952",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "CKT": {
        "name": "Sarakhs Airport",
        "city": "Sarakhs",
        "country": "Iran",
        "iata": "CKT",
        "icao": "OIMC",
        "latitude": "36.50120162963867",
        "longitude": "61.06489944458008",
        "altitude": "945",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "RUD": {
        "name": "Shahroud Airport",
        "city": "Emam Shahr",
        "country": "Iran",
        "iata": "RUD",
        "icao": "OIMJ",
        "latitude": "36.4253005981",
        "longitude": "55.104198455799995",
        "altitude": "4215",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "TCX": {
        "name": "Tabas Airport",
        "city": "Tabas",
        "country": "Iran",
        "iata": "TCX",
        "icao": "OIMT",
        "latitude": "33.6678009033",
        "longitude": "56.8927001953",
        "altitude": "2312",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KLM": {
        "name": "Kalaleh Airport",
        "city": "Kalaleh",
        "country": "Iran",
        "iata": "KLM",
        "icao": "OINE",
        "latitude": "37.3833007812",
        "longitude": "55.4519996643",
        "altitude": "425",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "RZR": {
        "name": "Ramsar Airport",
        "city": "Ramsar",
        "country": "Iran",
        "iata": "RZR",
        "icao": "OINR",
        "latitude": "36.9099006652832",
        "longitude": "50.67959976196289",
        "altitude": "-70",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "FAZ": {
        "name": "Fasa Airport",
        "city": "Fasa",
        "country": "Iran",
        "iata": "FAZ",
        "icao": "OISF",
        "latitude": "28.891799926757812",
        "longitude": "53.72330093383789",
        "altitude": "4261",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "JAR": {
        "name": "Jahrom Airport",
        "city": "Jahrom",
        "country": "Iran",
        "iata": "JAR",
        "icao": "OISJ",
        "latitude": "28.586700439499996",
        "longitude": "53.5791015625",
        "altitude": "3358",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "LFM": {
        "name": "Lamerd Airport",
        "city": "Lamerd",
        "country": "Iran",
        "iata": "LFM",
        "icao": "OISR",
        "latitude": "27.3726997375",
        "longitude": "53.18880081179999",
        "altitude": "1337",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "SYZ": {
        "name": "Shiraz Shahid Dastghaib International Airport",
        "city": "Shiraz",
        "country": "Iran",
        "iata": "SYZ",
        "icao": "OISS",
        "latitude": "29.539199829101562",
        "longitude": "52.58980178833008",
        "altitude": "4920",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "KHY": {
        "name": "Khoy Airport",
        "city": "Khoy",
        "country": "Iran",
        "iata": "KHY",
        "icao": "OITK",
        "latitude": "38.4275016784668",
        "longitude": "44.97359848022461",
        "altitude": "3981",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "TBZ": {
        "name": "Tabriz International Airport",
        "city": "Tabriz",
        "country": "Iran",
        "iata": "TBZ",
        "icao": "OITT",
        "latitude": "38.1338996887207",
        "longitude": "46.23500061035156",
        "altitude": "4459",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "JWN": {
        "name": "Zanjan Airport",
        "city": "Zanjan",
        "country": "Iran",
        "iata": "JWN",
        "icao": "OITZ",
        "latitude": "36.7737007141",
        "longitude": "48.3594017029",
        "altitude": "5382",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AZD": {
        "name": "Shahid Sadooghi Airport",
        "city": "Yazd",
        "country": "Iran",
        "iata": "AZD",
        "icao": "OIYY",
        "latitude": "31.9048995972",
        "longitude": "54.2765007019",
        "altitude": "4054",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "ACZ": {
        "name": "Zabol Airport",
        "city": "Zabol",
        "country": "Iran",
        "iata": "ACZ",
        "icao": "OIZB",
        "latitude": "31.09830093383789",
        "longitude": "61.54389953613281",
        "altitude": "1628",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "ZBR": {
        "name": "Konarak Airport",
        "city": "Chah Bahar",
        "country": "Iran",
        "iata": "ZBR",
        "icao": "OIZC",
        "latitude": "25.443300247199996",
        "longitude": "60.3820991516",
        "altitude": "43",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "ZAH": {
        "name": "Zahedan International Airport",
        "city": "Zahedan",
        "country": "Iran",
        "iata": "ZAH",
        "icao": "OIZH",
        "latitude": "29.47570037841797",
        "longitude": "60.90620040893555",
        "altitude": "4564",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "IHR": {
        "name": "Iran Shahr Airport",
        "city": "Iran Shahr",
        "country": "Iran",
        "iata": "IHR",
        "icao": "OIZI",
        "latitude": "27.2360992432",
        "longitude": "60.7200012207",
        "altitude": "2040",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AMM": {
        "name": "Queen Alia International Airport",
        "city": "Amman",
        "country": "Jordan",
        "iata": "AMM",
        "icao": "OJAI",
        "latitude": "31.7226009369",
        "longitude": "35.9931983948",
        "altitude": "2395",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Amman"
    },
    "ADJ": {
        "name": "Amman-Marka International Airport",
        "city": "Amman",
        "country": "Jordan",
        "iata": "ADJ",
        "icao": "OJAM",
        "latitude": "31.972700119018555",
        "longitude": "35.991600036621094",
        "altitude": "2555",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Amman"
    },
    "AQJ": {
        "name": "Aqaba King Hussein International Airport",
        "city": "Aqaba",
        "country": "Jordan",
        "iata": "AQJ",
        "icao": "OJAQ",
        "latitude": "29.611600875854492",
        "longitude": "35.01810073852539",
        "altitude": "175",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Amman"
    },
    "OMF": {
        "name": "King Hussein Air College",
        "city": "Mafraq",
        "country": "Jordan",
        "iata": "OMF",
        "icao": "OJMF",
        "latitude": "32.3564",
        "longitude": "36.259201",
        "altitude": "2240",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Amman"
    },
    "KWI": {
        "name": "Kuwait International Airport",
        "city": "Kuwait",
        "country": "Kuwait",
        "iata": "KWI",
        "icao": "OKBK",
        "latitude": "29.226600646972656",
        "longitude": "47.96889877319336",
        "altitude": "206",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Kuwait"
    },
    "BEY": {
        "name": "Beirut Rafic Hariri International Airport",
        "city": "Beirut",
        "country": "Lebanon",
        "iata": "BEY",
        "icao": "OLBA",
        "latitude": "33.820899963378906",
        "longitude": "35.488399505615234",
        "altitude": "87",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Beirut"
    },
    "KYE": {
        "name": "Rene Mouawad Air Base",
        "city": "Kleiat",
        "country": "Lebanon",
        "iata": "KYE",
        "icao": "OLKA",
        "latitude": "34.589298248291016",
        "longitude": "36.01129913330078",
        "altitude": "75",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Beirut"
    },
    "AUH": {
        "name": "Abu Dhabi International Airport",
        "city": "Abu Dhabi",
        "country": "United Arab Emirates",
        "iata": "AUH",
        "icao": "OMAA",
        "latitude": "24.433000564575195",
        "longitude": "54.651100158691406",
        "altitude": "88",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "AZI": {
        "name": "Bateen Airport",
        "city": "Abu Dhabi",
        "country": "United Arab Emirates",
        "iata": "AZI",
        "icao": "OMAD",
        "latitude": "24.428300857543945",
        "longitude": "54.458099365234375",
        "altitude": "16",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "DHF": {
        "name": "Al Dhafra Air Base",
        "city": "Abu Dhabi",
        "country": "United Arab Emirates",
        "iata": "DHF",
        "icao": "OMAM",
        "latitude": "24.248199462900004",
        "longitude": "54.547698974599996",
        "altitude": "77",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "DXB": {
        "name": "Dubai International Airport",
        "city": "Dubai",
        "country": "United Arab Emirates",
        "iata": "DXB",
        "icao": "OMDB",
        "latitude": "25.2527999878",
        "longitude": "55.3643989563",
        "altitude": "62",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "FJR": {
        "name": "Fujairah International Airport",
        "city": "Fujeirah",
        "country": "United Arab Emirates",
        "iata": "FJR",
        "icao": "OMFJ",
        "latitude": "25.112199783325195",
        "longitude": "56.32400131225586",
        "altitude": "152",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "RKT": {
        "name": "Ras Al Khaimah International Airport",
        "city": "Ras Al Khaimah",
        "country": "United Arab Emirates",
        "iata": "RKT",
        "icao": "OMRK",
        "latitude": "25.613500595092773",
        "longitude": "55.93880081176758",
        "altitude": "102",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "SHJ": {
        "name": "Sharjah International Airport",
        "city": "Sharjah",
        "country": "United Arab Emirates",
        "iata": "SHJ",
        "icao": "OMSJ",
        "latitude": "25.32859992980957",
        "longitude": "55.5172004699707",
        "altitude": "111",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "KHS": {
        "name": "Khasab Air Base",
        "city": "Khasab",
        "country": "Oman",
        "iata": "KHS",
        "icao": "OOKB",
        "latitude": "26.17099952697754",
        "longitude": "56.2406005859375",
        "altitude": "100",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Muscat"
    },
    "MSH": {
        "name": "Masirah Air Base",
        "city": "Masirah",
        "country": "Oman",
        "iata": "MSH",
        "icao": "OOMA",
        "latitude": "20.675399780273438",
        "longitude": "58.890499114990234",
        "altitude": "64",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Muscat"
    },
    "MCT": {
        "name": "Muscat International Airport",
        "city": "Muscat",
        "country": "Oman",
        "iata": "MCT",
        "icao": "OOMS",
        "latitude": "23.593299865722656",
        "longitude": "58.284400939941406",
        "altitude": "48",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Muscat"
    },
    "SLL": {
        "name": "Salalah Airport",
        "city": "Salalah",
        "country": "Oman",
        "iata": "SLL",
        "icao": "OOSA",
        "latitude": "17.038700103759766",
        "longitude": "54.09130096435547",
        "altitude": "73",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Muscat"
    },
    "TTH": {
        "name": "Thumrait Air Base",
        "city": "Thumrait",
        "country": "Oman",
        "iata": "TTH",
        "icao": "OOTH",
        "latitude": "17.666000366210938",
        "longitude": "54.024600982666016",
        "altitude": "1570",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Muscat"
    },
    "BHW": {
        "name": "Bhagatanwala Airport",
        "city": "Bhagtanwala",
        "country": "Pakistan",
        "iata": "BHW",
        "icao": "OPBG",
        "latitude": "32.056098938",
        "longitude": "72.94840240479999",
        "altitude": "600",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "LYP": {
        "name": "Faisalabad International Airport",
        "city": "Faisalabad",
        "country": "Pakistan",
        "iata": "LYP",
        "icao": "OPFA",
        "latitude": "31.364999771118164",
        "longitude": "72.99479675292969",
        "altitude": "591",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "GWD": {
        "name": "Gwadar International Airport",
        "city": "Gwadar",
        "country": "Pakistan",
        "iata": "GWD",
        "icao": "OPGD",
        "latitude": "25.233299255371094",
        "longitude": "62.329498291015625",
        "altitude": "36",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "GIL": {
        "name": "Gilgit Airport",
        "city": "Gilgit",
        "country": "Pakistan",
        "iata": "GIL",
        "icao": "OPGT",
        "latitude": "35.918800354003906",
        "longitude": "74.33360290527344",
        "altitude": "4796",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "JAG": {
        "name": "Shahbaz Air Base",
        "city": "Jacobsbad",
        "country": "Pakistan",
        "iata": "JAG",
        "icao": "OPJA",
        "latitude": "28.28420066833496",
        "longitude": "68.44969940185547",
        "altitude": "185",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "KHI": {
        "name": "Jinnah International Airport",
        "city": "Karachi",
        "country": "Pakistan",
        "iata": "KHI",
        "icao": "OPKC",
        "latitude": "24.9065",
        "longitude": "67.160797",
        "altitude": "100",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "LHE": {
        "name": "Alama Iqbal International Airport",
        "city": "Lahore",
        "country": "Pakistan",
        "iata": "LHE",
        "icao": "OPLA",
        "latitude": "31.5216007232666",
        "longitude": "74.40360260009766",
        "altitude": "712",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "XJM": {
        "name": "Mangla Airport",
        "city": "Mangla",
        "country": "Pakistan",
        "iata": "XJM",
        "icao": "OPMA",
        "latitude": "33.05009841918945",
        "longitude": "73.63839721679688",
        "altitude": "902",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "MFG": {
        "name": "Muzaffarabad Airport",
        "city": "Muzaffarabad",
        "country": "Pakistan",
        "iata": "MFG",
        "icao": "OPMF",
        "latitude": "34.3390007019043",
        "longitude": "73.50859832763672",
        "altitude": "2691",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "MWD": {
        "name": "Mianwali Air Base",
        "city": "Mianwali",
        "country": "Pakistan",
        "iata": "MWD",
        "icao": "OPMI",
        "latitude": "32.5630989074707",
        "longitude": "71.5707015991211",
        "altitude": "690",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "MJD": {
        "name": "Moenjodaro Airport",
        "city": "Moenjodaro",
        "country": "Pakistan",
        "iata": "MJD",
        "icao": "OPMJ",
        "latitude": "27.3351993560791",
        "longitude": "68.14309692382812",
        "altitude": "154",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "MUX": {
        "name": "Multan International Airport",
        "city": "Multan",
        "country": "Pakistan",
        "iata": "MUX",
        "icao": "OPMT",
        "latitude": "30.20319938659668",
        "longitude": "71.41909790039062",
        "altitude": "403",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "WNS": {
        "name": "Shaheed Benazirabad Airport",
        "city": "Nawabshah",
        "country": "Pakistan",
        "iata": "WNS",
        "icao": "OPNH",
        "latitude": "26.2194",
        "longitude": "68.390099",
        "altitude": "95",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "PJG": {
        "name": "Panjgur Airport",
        "city": "Panjgur",
        "country": "Pakistan",
        "iata": "PJG",
        "icao": "OPPG",
        "latitude": "26.954500198364258",
        "longitude": "64.13249969482422",
        "altitude": "3289",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "PSI": {
        "name": "Pasni Airport",
        "city": "Pasni",
        "country": "Pakistan",
        "iata": "PSI",
        "icao": "OPPI",
        "latitude": "25.29050064086914",
        "longitude": "63.34510040283203",
        "altitude": "33",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "PEW": {
        "name": "Peshawar International Airport",
        "city": "Peshawar",
        "country": "Pakistan",
        "iata": "PEW",
        "icao": "OPPS",
        "latitude": "33.993900299072266",
        "longitude": "71.51460266113281",
        "altitude": "1158",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "UET": {
        "name": "Quetta International Airport",
        "city": "Quetta",
        "country": "Pakistan",
        "iata": "UET",
        "icao": "OPQT",
        "latitude": "30.251399993896484",
        "longitude": "66.93779754638672",
        "altitude": "5267",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "RYK": {
        "name": "Shaikh Zaid Airport",
        "city": "Rahim Yar Khan",
        "country": "Pakistan",
        "iata": "RYK",
        "icao": "OPRK",
        "latitude": "28.383899688720703",
        "longitude": "70.27960205078125",
        "altitude": "271",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "RAZ": {
        "name": "Rawalakot Airport",
        "city": "Rawala Kot",
        "country": "Pakistan",
        "iata": "RAZ",
        "icao": "OPRT",
        "latitude": "33.849700927734375",
        "longitude": "73.79810333251953",
        "altitude": "5479",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "SKZ": {
        "name": "Sukkur Airport",
        "city": "Sukkur",
        "country": "Pakistan",
        "iata": "SKZ",
        "icao": "OPSK",
        "latitude": "27.722000122070312",
        "longitude": "68.79170227050781",
        "altitude": "196",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "SDT": {
        "name": "Saidu Sharif Airport",
        "city": "Saidu Sharif",
        "country": "Pakistan",
        "iata": "SDT",
        "icao": "OPSS",
        "latitude": "34.8135986328125",
        "longitude": "72.35279846191406",
        "altitude": "3183",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "SUL": {
        "name": "Sui Airport",
        "city": "Sui",
        "country": "Pakistan",
        "iata": "SUL",
        "icao": "OPSU",
        "latitude": "28.645099639892578",
        "longitude": "69.1769027709961",
        "altitude": "763",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "BDN": {
        "name": "Talhar Airport",
        "city": "Talhar",
        "country": "Pakistan",
        "iata": "BDN",
        "icao": "OPTH",
        "latitude": "24.84149932861328",
        "longitude": "68.8384017944336",
        "altitude": "28",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "WAF": {
        "name": "Wana Airport",
        "city": "Wana",
        "country": "Pakistan",
        "iata": "WAF",
        "icao": "OPWN",
        "latitude": "32.3046989440918",
        "longitude": "69.57039642333984",
        "altitude": "4550",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "PZH": {
        "name": "Zhob Airport",
        "city": "Zhob",
        "country": "Pakistan",
        "iata": "PZH",
        "icao": "OPZB",
        "latitude": "31.358400344848633",
        "longitude": "69.4636001586914",
        "altitude": "4728",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "BSR": {
        "name": "Basrah International Airport",
        "city": "Basrah",
        "country": "Iraq",
        "iata": "BSR",
        "icao": "ORMM",
        "latitude": "30.549100875854492",
        "longitude": "47.66210174560547",
        "altitude": "11",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "ALP": {
        "name": "Aleppo International Airport",
        "city": "Aleppo",
        "country": "Syria",
        "iata": "ALP",
        "icao": "OSAP",
        "latitude": "36.18069839477539",
        "longitude": "37.22439956665039",
        "altitude": "1276",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Damascus"
    },
    "DAM": {
        "name": "Damascus International Airport",
        "city": "Damascus",
        "country": "Syria",
        "iata": "DAM",
        "icao": "OSDI",
        "latitude": "33.4114990234375",
        "longitude": "36.51559829711914",
        "altitude": "2020",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Damascus"
    },
    "DEZ": {
        "name": "Deir ez-Zor Air Base",
        "city": "Deire Zor",
        "country": "Syria",
        "iata": "DEZ",
        "icao": "OSDZ",
        "latitude": "35.2854",
        "longitude": "40.175999",
        "altitude": "700",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Damascus"
    },
    "LTK": {
        "name": "Bassel Al-Assad International Airport",
        "city": "Latakia",
        "country": "Syria",
        "iata": "LTK",
        "icao": "OSLK",
        "latitude": "35.401100158691406",
        "longitude": "35.948699951171875",
        "altitude": "157",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Damascus"
    },
    "PMS": {
        "name": "Palmyra Airport",
        "city": "Palmyra",
        "country": "Syria",
        "iata": "PMS",
        "icao": "OSPR",
        "latitude": "34.5574",
        "longitude": "38.316898",
        "altitude": "1322",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Damascus"
    },
    "DOH": {
        "name": "Hamad International Airport",
        "city": "Doha",
        "country": "Qatar",
        "iata": "DOH",
        "icao": "OTHH",
        "latitude": "25.273056",
        "longitude": "51.608056",
        "altitude": "13",
        "timezone": "3",
        "dst": "N",
        "tz_name": null
    },
    "CIS": {
        "name": "Canton Island Airport",
        "city": "Canton Island",
        "country": "Kiribati",
        "iata": "CIS",
        "icao": "PCIS",
        "latitude": "-2.7681200504300003",
        "longitude": "-171.710006714",
        "altitude": "9",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Enderbury"
    },
    "ROP": {
        "name": "Rota International Airport",
        "city": "Rota",
        "country": "Northern Mariana Islands",
        "iata": "ROP",
        "icao": "PGRO",
        "latitude": "14.174300193786621",
        "longitude": "145.2429962158203",
        "altitude": "607",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Saipan"
    },
    "SPN": {
        "name": "Saipan International Airport",
        "city": "Saipan",
        "country": "Northern Mariana Islands",
        "iata": "SPN",
        "icao": "PGSN",
        "latitude": "15.119",
        "longitude": "145.729004",
        "altitude": "215",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Saipan"
    },
    "UAM": {
        "name": "Andersen Air Force Base",
        "city": "Andersen",
        "country": "Guam",
        "iata": "UAM",
        "icao": "PGUA",
        "latitude": "13.584",
        "longitude": "144.929998",
        "altitude": "627",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Guam"
    },
    "GUM": {
        "name": "Antonio B. Won Pat International Airport",
        "city": "Agana",
        "country": "Guam",
        "iata": "GUM",
        "icao": "PGUM",
        "latitude": "13.4834003448",
        "longitude": "144.796005249",
        "altitude": "298",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Guam"
    },
    "TIQ": {
        "name": "Tinian International Airport",
        "city": "West Tinian",
        "country": "Northern Mariana Islands",
        "iata": "TIQ",
        "icao": "PGWT",
        "latitude": "14.999199867248535",
        "longitude": "145.61900329589844",
        "altitude": "271",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Saipan"
    },
    "MAJ": {
        "name": "Marshall Islands International Airport",
        "city": "Majuro",
        "country": "Marshall Islands",
        "iata": "MAJ",
        "icao": "PKMJ",
        "latitude": "7.064760208129883",
        "longitude": "171.27200317382812",
        "altitude": "6",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "KWA": {
        "name": "Bucholz Army Air Field",
        "city": "Kwajalein",
        "country": "Marshall Islands",
        "iata": "KWA",
        "icao": "PKWA",
        "latitude": "8.720120429992676",
        "longitude": "167.73199462890625",
        "altitude": "9",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "CXI": {
        "name": "Cassidy International Airport",
        "city": "Kiritimati",
        "country": "Kiribati",
        "iata": "CXI",
        "icao": "PLCH",
        "latitude": "1.9861600399017334",
        "longitude": "-157.35000610351562",
        "altitude": "5",
        "timezone": "-12",
        "dst": "U",
        "tz_name": null
    },
    "MDY": {
        "name": "Henderson Field",
        "city": "Midway",
        "country": "Midway Islands",
        "iata": "MDY",
        "icao": "PMDY",
        "latitude": "28.20170021057129",
        "longitude": "-177.38099670410156",
        "altitude": "13",
        "timezone": "-11",
        "dst": "U",
        "tz_name": "Pacific/Midway"
    },
    "TKK": {
        "name": "Chuuk International Airport",
        "city": "Chuuk",
        "country": "Micronesia",
        "iata": "TKK",
        "icao": "PTKK",
        "latitude": "7.461870193481445",
        "longitude": "151.84300231933594",
        "altitude": "11",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Truk"
    },
    "PNI": {
        "name": "Pohnpei International Airport",
        "city": "Pohnpei",
        "country": "Micronesia",
        "iata": "PNI",
        "icao": "PTPN",
        "latitude": "6.985099792480469",
        "longitude": "158.20899963378906",
        "altitude": "10",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Ponape"
    },
    "ROR": {
        "name": "Babelthuap Airport",
        "city": "Babelthuap",
        "country": "Palau",
        "iata": "ROR",
        "icao": "PTRO",
        "latitude": "7.36731",
        "longitude": "134.544236",
        "altitude": "176",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Pacific/Palau"
    },
    "KSA": {
        "name": "Kosrae International Airport",
        "city": "Kosrae",
        "country": "Micronesia",
        "iata": "KSA",
        "icao": "PTSA",
        "latitude": "5.35698",
        "longitude": "162.957993",
        "altitude": "11",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Kosrae"
    },
    "YAP": {
        "name": "Yap International Airport",
        "city": "Yap",
        "country": "Micronesia",
        "iata": "YAP",
        "icao": "PTYA",
        "latitude": "9.49891",
        "longitude": "138.082993",
        "altitude": "91",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Truk"
    },
    "KNH": {
        "name": "Kinmen Airport",
        "city": "Kinmen",
        "country": "Taiwan",
        "iata": "KNH",
        "icao": "RCBS",
        "latitude": "24.4279",
        "longitude": "118.359001",
        "altitude": "93",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "TTT": {
        "name": "Taitung Airport",
        "city": "Fengnin",
        "country": "Taiwan",
        "iata": "TTT",
        "icao": "RCFN",
        "latitude": "22.7549991607666",
        "longitude": "121.10199737548828",
        "altitude": "143",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "GNI": {
        "name": "Lyudao Airport",
        "city": "Green Island",
        "country": "Taiwan",
        "iata": "GNI",
        "icao": "RCGI",
        "latitude": "22.673900604248047",
        "longitude": "121.46600341796875",
        "altitude": "28",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "KHH": {
        "name": "Kaohsiung International Airport",
        "city": "Kaohsiung",
        "country": "Taiwan",
        "iata": "KHH",
        "icao": "RCKH",
        "latitude": "22.57710075378418",
        "longitude": "120.3499984741211",
        "altitude": "31",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "CYI": {
        "name": "Chiayi Airport",
        "city": "Chiayi",
        "country": "Taiwan",
        "iata": "CYI",
        "icao": "RCKU",
        "latitude": "23.46179962158203",
        "longitude": "120.39299774169922",
        "altitude": "85",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "KYD": {
        "name": "Lanyu Airport",
        "city": "Lanyu",
        "country": "Taiwan",
        "iata": "KYD",
        "icao": "RCLY",
        "latitude": "22.027000427246094",
        "longitude": "121.53500366210938",
        "altitude": "44",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "RMQ": {
        "name": "Taichung Ching Chuang Kang Airport",
        "city": "Taichung",
        "country": "Taiwan",
        "iata": "RMQ",
        "icao": "RCMQ",
        "latitude": "24.264699935913086",
        "longitude": "120.62100219726562",
        "altitude": "663",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Taipei"
    },
    "TNN": {
        "name": "Tainan Airport",
        "city": "Tainan",
        "country": "Taiwan",
        "iata": "TNN",
        "icao": "RCNN",
        "latitude": "22.95039939880371",
        "longitude": "120.20600128173828",
        "altitude": "63",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "HSZ": {
        "name": "Hsinchu Air Base",
        "city": "Hsinchu",
        "country": "Taiwan",
        "iata": "HSZ",
        "icao": "RCPO",
        "latitude": "24.8180007935",
        "longitude": "120.939002991",
        "altitude": "26",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "MZG": {
        "name": "Makung Airport",
        "city": "Makung",
        "country": "Taiwan",
        "iata": "MZG",
        "icao": "RCQC",
        "latitude": "23.568700790405273",
        "longitude": "119.62799835205078",
        "altitude": "103",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "PIF": {
        "name": "Pingtung North Airport",
        "city": "Pingtung",
        "country": "Taiwan",
        "iata": "PIF",
        "icao": "RCSQ",
        "latitude": "22.700199127197266",
        "longitude": "120.48200225830078",
        "altitude": "97",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "TSA": {
        "name": "Taipei Songshan Airport",
        "city": "Taipei",
        "country": "Taiwan",
        "iata": "TSA",
        "icao": "RCSS",
        "latitude": "25.069400787353516",
        "longitude": "121.552001953125",
        "altitude": "18",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "TPE": {
        "name": "Taiwan Taoyuan International Airport",
        "city": "Taipei",
        "country": "Taiwan",
        "iata": "TPE",
        "icao": "RCTP",
        "latitude": "25.0777",
        "longitude": "121.233002",
        "altitude": "106",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "WOT": {
        "name": "Wang-an Airport",
        "city": "Wang An",
        "country": "Taiwan",
        "iata": "WOT",
        "icao": "RCWA",
        "latitude": "23.367372512817383",
        "longitude": "119.50277709960938",
        "altitude": "115",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "HUN": {
        "name": "Hualien Airport",
        "city": "Hualien",
        "country": "Taiwan",
        "iata": "HUN",
        "icao": "RCYU",
        "latitude": "24.023099899291992",
        "longitude": "121.61799621582031",
        "altitude": "52",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "NRT": {
        "name": "Narita International Airport",
        "city": "Tokyo",
        "country": "Japan",
        "iata": "NRT",
        "icao": "RJAA",
        "latitude": "35.7647018433",
        "longitude": "140.386001587",
        "altitude": "141",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MMJ": {
        "name": "Matsumoto Airport",
        "city": "Matsumoto",
        "country": "Japan",
        "iata": "MMJ",
        "icao": "RJAF",
        "latitude": "36.16680145263672",
        "longitude": "137.92300415039062",
        "altitude": "2182",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "IBR": {
        "name": "Hyakuri Airport",
        "city": "Ibaraki",
        "country": "Japan",
        "iata": "IBR",
        "icao": "RJAH",
        "latitude": "36.181098938",
        "longitude": "140.414993286",
        "altitude": "105",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MUS": {
        "name": "Minami Torishima Airport",
        "city": "Minami Tori Shima",
        "country": "Japan",
        "iata": "MUS",
        "icao": "RJAM",
        "latitude": "24.2896995544",
        "longitude": "153.979003906",
        "altitude": "22",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "IWO": {
        "name": "Iwo Jima Airport",
        "city": "Iwojima",
        "country": "Japan",
        "iata": "IWO",
        "icao": "RJAW",
        "latitude": "24.784000396728516",
        "longitude": "141.322998046875",
        "altitude": "384",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "SHM": {
        "name": "Nanki Shirahama Airport",
        "city": "Nanki-shirahama",
        "country": "Japan",
        "iata": "SHM",
        "icao": "RJBD",
        "latitude": "33.6622009277",
        "longitude": "135.363998413",
        "altitude": "298",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OBO": {
        "name": "Tokachi-Obihiro Airport",
        "city": "Obihiro",
        "country": "Japan",
        "iata": "OBO",
        "icao": "RJCB",
        "latitude": "42.7332992554",
        "longitude": "143.216995239",
        "altitude": "505",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "CTS": {
        "name": "New Chitose Airport",
        "city": "Sapporo",
        "country": "Japan",
        "iata": "CTS",
        "icao": "RJCC",
        "latitude": "42.77519989013672",
        "longitude": "141.69200134277344",
        "altitude": "82",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HKD": {
        "name": "Hakodate Airport",
        "city": "Hakodate",
        "country": "Japan",
        "iata": "HKD",
        "icao": "RJCH",
        "latitude": "41.7700004578",
        "longitude": "140.822006226",
        "altitude": "151",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MMB": {
        "name": "Memanbetsu Airport",
        "city": "Memanbetsu",
        "country": "Japan",
        "iata": "MMB",
        "icao": "RJCM",
        "latitude": "43.8805999756",
        "longitude": "144.164001465",
        "altitude": "135",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "SHB": {
        "name": "Nakashibetsu Airport",
        "city": "Nakashibetsu",
        "country": "Japan",
        "iata": "SHB",
        "icao": "RJCN",
        "latitude": "43.5774993896",
        "longitude": "144.960006714",
        "altitude": "234",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "WKJ": {
        "name": "Wakkanai Airport",
        "city": "Wakkanai",
        "country": "Japan",
        "iata": "WKJ",
        "icao": "RJCW",
        "latitude": "45.4042015076",
        "longitude": "141.800994873",
        "altitude": "30",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "IKI": {
        "name": "Iki Airport",
        "city": "Iki",
        "country": "Japan",
        "iata": "IKI",
        "icao": "RJDB",
        "latitude": "33.7490005493",
        "longitude": "129.785003662",
        "altitude": "41",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "UBJ": {
        "name": "Yamaguchi Ube Airport",
        "city": "Yamaguchi",
        "country": "Japan",
        "iata": "UBJ",
        "icao": "RJDC",
        "latitude": "33.930000305200004",
        "longitude": "131.279006958",
        "altitude": "23",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TSJ": {
        "name": "Tsushima Airport",
        "city": "Tsushima",
        "country": "Japan",
        "iata": "TSJ",
        "icao": "RJDT",
        "latitude": "34.2849006653",
        "longitude": "129.330993652",
        "altitude": "213",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MBE": {
        "name": "Monbetsu Airport",
        "city": "Monbetsu",
        "country": "Japan",
        "iata": "MBE",
        "icao": "RJEB",
        "latitude": "44.303901672399995",
        "longitude": "143.404006958",
        "altitude": "80",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "AKJ": {
        "name": "Asahikawa Airport",
        "city": "Asahikawa",
        "country": "Japan",
        "iata": "AKJ",
        "icao": "RJEC",
        "latitude": "43.670799255371094",
        "longitude": "142.44700622558594",
        "altitude": "721",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OIR": {
        "name": "Okushiri Airport",
        "city": "Okushiri",
        "country": "Japan",
        "iata": "OIR",
        "icao": "RJEO",
        "latitude": "42.0717010498",
        "longitude": "139.432998657",
        "altitude": "161",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "RIS": {
        "name": "Rishiri Airport",
        "city": "Rishiri Island",
        "country": "Japan",
        "iata": "RIS",
        "icao": "RJER",
        "latitude": "45.2420005798",
        "longitude": "141.186004639",
        "altitude": "112",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KUM": {
        "name": "Yakushima Airport",
        "city": "Yakushima",
        "country": "Japan",
        "iata": "KUM",
        "icao": "RJFC",
        "latitude": "30.3855991364",
        "longitude": "130.658996582",
        "altitude": "124",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "FUJ": {
        "name": "Fukue Airport",
        "city": "Fukue",
        "country": "Japan",
        "iata": "FUJ",
        "icao": "RJFE",
        "latitude": "32.66630172729492",
        "longitude": "128.83299255371094",
        "altitude": "273",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "FUK": {
        "name": "Fukuoka Airport",
        "city": "Fukuoka",
        "country": "Japan",
        "iata": "FUK",
        "icao": "RJFF",
        "latitude": "33.585899353027344",
        "longitude": "130.4510040283203",
        "altitude": "32",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TNE": {
        "name": "New Tanegashima Airport",
        "city": "Tanegashima",
        "country": "Japan",
        "iata": "TNE",
        "icao": "RJFG",
        "latitude": "30.605100631699997",
        "longitude": "130.990997314",
        "altitude": "768",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KOJ": {
        "name": "Kagoshima Airport",
        "city": "Kagoshima",
        "country": "Japan",
        "iata": "KOJ",
        "icao": "RJFK",
        "latitude": "31.80340003967285",
        "longitude": "130.718994140625",
        "altitude": "906",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KMI": {
        "name": "Miyazaki Airport",
        "city": "Miyazaki",
        "country": "Japan",
        "iata": "KMI",
        "icao": "RJFM",
        "latitude": "31.877199173",
        "longitude": "131.449005127",
        "altitude": "20",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OIT": {
        "name": "Oita Airport",
        "city": "Oita",
        "country": "Japan",
        "iata": "OIT",
        "icao": "RJFO",
        "latitude": "33.479400634799994",
        "longitude": "131.736999512",
        "altitude": "19",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KKJ": {
        "name": "Kitakyūshū Airport",
        "city": "Kitakyushu",
        "country": "Japan",
        "iata": "KKJ",
        "icao": "RJFR",
        "latitude": "33.8459014893",
        "longitude": "131.035003662",
        "altitude": "21",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KMJ": {
        "name": "Kumamoto Airport",
        "city": "Kumamoto",
        "country": "Japan",
        "iata": "KMJ",
        "icao": "RJFT",
        "latitude": "32.83729934692383",
        "longitude": "130.85499572753906",
        "altitude": "642",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "NGS": {
        "name": "Nagasaki Airport",
        "city": "Nagasaki",
        "country": "Japan",
        "iata": "NGS",
        "icao": "RJFU",
        "latitude": "32.916900634799994",
        "longitude": "129.914001465",
        "altitude": "15",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "ASJ": {
        "name": "Amami Airport",
        "city": "Amami",
        "country": "Japan",
        "iata": "ASJ",
        "icao": "RJKA",
        "latitude": "28.430599212646484",
        "longitude": "129.71299743652344",
        "altitude": "27",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OKE": {
        "name": "Okierabu Airport",
        "city": "Okierabu",
        "country": "Japan",
        "iata": "OKE",
        "icao": "RJKB",
        "latitude": "27.4255008698",
        "longitude": "128.701004028",
        "altitude": "101",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TKN": {
        "name": "Tokunoshima Airport",
        "city": "Tokunoshima",
        "country": "Japan",
        "iata": "TKN",
        "icao": "RJKN",
        "latitude": "27.83639907836914",
        "longitude": "128.88099670410156",
        "altitude": "17",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "FKJ": {
        "name": "Fukui Airport",
        "city": "Fukui",
        "country": "Japan",
        "iata": "FKJ",
        "icao": "RJNF",
        "latitude": "36.1427993774",
        "longitude": "136.223999023",
        "altitude": "19",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "QGU": {
        "name": "Gifu Airport",
        "city": "Gifu",
        "country": "Japan",
        "iata": "QGU",
        "icao": "RJNG",
        "latitude": "35.394100189208984",
        "longitude": "136.8699951171875",
        "altitude": "128",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KMQ": {
        "name": "Komatsu Airport",
        "city": "Kanazawa",
        "country": "Japan",
        "iata": "KMQ",
        "icao": "RJNK",
        "latitude": "36.39459991455078",
        "longitude": "136.40699768066406",
        "altitude": "36",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OKI": {
        "name": "Oki Airport",
        "city": "Oki Island",
        "country": "Japan",
        "iata": "OKI",
        "icao": "RJNO",
        "latitude": "36.18109893798828",
        "longitude": "133.3249969482422",
        "altitude": "311",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TOY": {
        "name": "Toyama Airport",
        "city": "Toyama",
        "country": "Japan",
        "iata": "TOY",
        "icao": "RJNT",
        "latitude": "36.64830017089844",
        "longitude": "137.18800354003906",
        "altitude": "95",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HIJ": {
        "name": "Hiroshima Airport",
        "city": "Hiroshima",
        "country": "Japan",
        "iata": "HIJ",
        "icao": "RJOA",
        "latitude": "34.4361000061",
        "longitude": "132.919006348",
        "altitude": "1088",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OKJ": {
        "name": "Okayama Airport",
        "city": "Okayama",
        "country": "Japan",
        "iata": "OKJ",
        "icao": "RJOB",
        "latitude": "34.7569007874",
        "longitude": "133.854995728",
        "altitude": "806",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "IZO": {
        "name": "Izumo Airport",
        "city": "Izumo",
        "country": "Japan",
        "iata": "IZO",
        "icao": "RJOC",
        "latitude": "35.4136009216",
        "longitude": "132.88999939",
        "altitude": "15",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "YGJ": {
        "name": "Miho Yonago Airport",
        "city": "Miho",
        "country": "Japan",
        "iata": "YGJ",
        "icao": "RJOH",
        "latitude": "35.4921989440918",
        "longitude": "133.23599243164062",
        "altitude": "20",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KCZ": {
        "name": "Kōchi Ryōma Airport",
        "city": "Kochi",
        "country": "Japan",
        "iata": "KCZ",
        "icao": "RJOK",
        "latitude": "33.546101",
        "longitude": "133.669006",
        "altitude": "42",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MYJ": {
        "name": "Matsuyama Airport",
        "city": "Matsuyama",
        "country": "Japan",
        "iata": "MYJ",
        "icao": "RJOM",
        "latitude": "33.82720184326172",
        "longitude": "132.6999969482422",
        "altitude": "25",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "ITM": {
        "name": "Osaka International Airport",
        "city": "Osaka",
        "country": "Japan",
        "iata": "ITM",
        "icao": "RJOO",
        "latitude": "34.785499572753906",
        "longitude": "135.43800354003906",
        "altitude": "50",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TTJ": {
        "name": "Tottori Airport",
        "city": "Tottori",
        "country": "Japan",
        "iata": "TTJ",
        "icao": "RJOR",
        "latitude": "35.5301017761",
        "longitude": "134.167007446",
        "altitude": "65",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TKS": {
        "name": "Tokushima Airport/JMSDF Air Base",
        "city": "Tokushima",
        "country": "Japan",
        "iata": "TKS",
        "icao": "RJOS",
        "latitude": "34.132801",
        "longitude": "134.606995",
        "altitude": "26",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TAK": {
        "name": "Takamatsu Airport",
        "city": "Takamatsu",
        "country": "Japan",
        "iata": "TAK",
        "icao": "RJOT",
        "latitude": "34.214199066199996",
        "longitude": "134.01600647",
        "altitude": "607",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "AOJ": {
        "name": "Aomori Airport",
        "city": "Aomori",
        "country": "Japan",
        "iata": "AOJ",
        "icao": "RJSA",
        "latitude": "40.73469924926758",
        "longitude": "140.6909942626953",
        "altitude": "664",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "GAJ": {
        "name": "Yamagata Airport",
        "city": "Yamagata",
        "country": "Japan",
        "iata": "GAJ",
        "icao": "RJSC",
        "latitude": "38.411899566699994",
        "longitude": "140.371002197",
        "altitude": "353",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "SDS": {
        "name": "Sado Airport",
        "city": "Sado",
        "country": "Japan",
        "iata": "SDS",
        "icao": "RJSD",
        "latitude": "38.0601997375",
        "longitude": "138.414001465",
        "altitude": "88",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HHE": {
        "name": "Hachinohe Airport",
        "city": "Hachinoe",
        "country": "Japan",
        "iata": "HHE",
        "icao": "RJSH",
        "latitude": "40.556400299072266",
        "longitude": "141.46600341796875",
        "altitude": "152",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HNA": {
        "name": "Hanamaki Airport",
        "city": "Hanamaki",
        "country": "Japan",
        "iata": "HNA",
        "icao": "RJSI",
        "latitude": "39.4286003112793",
        "longitude": "141.13499450683594",
        "altitude": "297",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "AXT": {
        "name": "Akita Airport",
        "city": "Akita",
        "country": "Japan",
        "iata": "AXT",
        "icao": "RJSK",
        "latitude": "39.6156005859375",
        "longitude": "140.218994140625",
        "altitude": "313",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MSJ": {
        "name": "Misawa Air Base",
        "city": "Misawa",
        "country": "Japan",
        "iata": "MSJ",
        "icao": "RJSM",
        "latitude": "40.703201293899994",
        "longitude": "141.367996216",
        "altitude": "119",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "SDJ": {
        "name": "Sendai Airport",
        "city": "Sendai",
        "country": "Japan",
        "iata": "SDJ",
        "icao": "RJSS",
        "latitude": "38.1397018433",
        "longitude": "140.917007446",
        "altitude": "15",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "NJA": {
        "name": "Atsugi Naval Air Facility",
        "city": "Atsugi",
        "country": "Japan",
        "iata": "NJA",
        "icao": "RJTA",
        "latitude": "35.4546012878418",
        "longitude": "139.4499969482422",
        "altitude": "205",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HAC": {
        "name": "Hachijojima Airport",
        "city": "Hachijojima",
        "country": "Japan",
        "iata": "HAC",
        "icao": "RJTH",
        "latitude": "33.1150016785",
        "longitude": "139.785995483",
        "altitude": "303",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OIM": {
        "name": "Oshima Airport",
        "city": "Oshima",
        "country": "Japan",
        "iata": "OIM",
        "icao": "RJTO",
        "latitude": "34.782001495399996",
        "longitude": "139.36000061",
        "altitude": "130",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HND": {
        "name": "Tokyo Haneda International Airport",
        "city": "Tokyo",
        "country": "Japan",
        "iata": "HND",
        "icao": "RJTT",
        "latitude": "35.552299",
        "longitude": "139.779999",
        "altitude": "35",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OKO": {
        "name": "Yokota Air Base",
        "city": "Yokota",
        "country": "Japan",
        "iata": "OKO",
        "icao": "RJTY",
        "latitude": "35.74850082397461",
        "longitude": "139.34800720214844",
        "altitude": "463",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KWJ": {
        "name": "Gwangju Airport",
        "city": "Kwangju",
        "country": "South Korea",
        "iata": "KWJ",
        "icao": "RKJJ",
        "latitude": "35.123173",
        "longitude": "126.805444",
        "altitude": "39",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "CHN": {
        "name": "Jeon Ju Airport (G-703)",
        "city": "Jhunju",
        "country": "South Korea",
        "iata": "CHN",
        "icao": "RKJU",
        "latitude": "35.87808",
        "longitude": "127.119112",
        "altitude": "96",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "RSU": {
        "name": "Yeosu Airport",
        "city": "Yeosu",
        "country": "South Korea",
        "iata": "RSU",
        "icao": "RKJY",
        "latitude": "34.84230041503906",
        "longitude": "127.61699676513672",
        "altitude": "53",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "KAG": {
        "name": "Gangneung Airport (K-18)",
        "city": "Kangnung",
        "country": "South Korea",
        "iata": "KAG",
        "icao": "RKNN",
        "latitude": "37.753601",
        "longitude": "128.943915",
        "altitude": "35",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "CJU": {
        "name": "Jeju International Airport",
        "city": "Cheju",
        "country": "South Korea",
        "iata": "CJU",
        "icao": "RKPC",
        "latitude": "33.51129913330078",
        "longitude": "126.49299621582031",
        "altitude": "118",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "CHF": {
        "name": "Jinhae Airbase/Airport (G-813/K-10)",
        "city": "Chinhae",
        "country": "South Korea",
        "iata": "CHF",
        "icao": "RKPE",
        "latitude": "35.140248",
        "longitude": "128.696229",
        "altitude": "8",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "PUS": {
        "name": "Gimhae International Airport",
        "city": "Busan",
        "country": "South Korea",
        "iata": "PUS",
        "icao": "RKPK",
        "latitude": "35.1795005798",
        "longitude": "128.93800354",
        "altitude": "6",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "USN": {
        "name": "Ulsan Airport",
        "city": "Ulsan",
        "country": "South Korea",
        "iata": "USN",
        "icao": "RKPU",
        "latitude": "35.59349823",
        "longitude": "129.352005005",
        "altitude": "45",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "SSN": {
        "name": "Seoul Air Base (K-16)",
        "city": "Seoul East",
        "country": "South Korea",
        "iata": "SSN",
        "icao": "RKSM",
        "latitude": "37.444744",
        "longitude": "127.112718",
        "altitude": "92",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "OSN": {
        "name": "Osan Air Base",
        "city": "Osan",
        "country": "South Korea",
        "iata": "OSN",
        "icao": "RKSO",
        "latitude": "37.090599",
        "longitude": "127.029999",
        "altitude": "38",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "GMP": {
        "name": "Gimpo International Airport",
        "city": "Seoul",
        "country": "South Korea",
        "iata": "GMP",
        "icao": "RKSS",
        "latitude": "37.5583",
        "longitude": "126.791",
        "altitude": "59",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "SWU": {
        "name": "Suwon Airport",
        "city": "Suwon",
        "country": "South Korea",
        "iata": "SWU",
        "icao": "RKSW",
        "latitude": "37.23939895629883",
        "longitude": "127.00700378417969",
        "altitude": "88",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "KPO": {
        "name": "Pohang Airport (G-815/K-3)",
        "city": "Pohang",
        "country": "South Korea",
        "iata": "KPO",
        "icao": "RKTH",
        "latitude": "35.987955",
        "longitude": "129.420383",
        "altitude": "70",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "TAE": {
        "name": "Daegu Airport",
        "city": "Taegu",
        "country": "South Korea",
        "iata": "TAE",
        "icao": "RKTN",
        "latitude": "35.896872",
        "longitude": "128.65531",
        "altitude": "116",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "YEC": {
        "name": "Yecheon Airbase",
        "city": "Yechon",
        "country": "South Korea",
        "iata": "YEC",
        "icao": "RKTY",
        "latitude": "36.630373",
        "longitude": "128.34971",
        "altitude": "354",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "OKA": {
        "name": "Naha Airport",
        "city": "Okinawa",
        "country": "Japan",
        "iata": "OKA",
        "icao": "ROAH",
        "latitude": "26.1958007812",
        "longitude": "127.646003723",
        "altitude": "12",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Tokyo"
    },
    "DNA": {
        "name": "Kadena Air Base",
        "city": "Kadena",
        "country": "Japan",
        "iata": "DNA",
        "icao": "RODN",
        "latitude": "26.3556",
        "longitude": "127.767998",
        "altitude": "143",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "ISG": {
        "name": "New Ishigaki Airport",
        "city": "Ishigaki",
        "country": "Japan",
        "iata": "ISG",
        "icao": "ROIG",
        "latitude": "24.396389",
        "longitude": "124.245",
        "altitude": "102",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "UEO": {
        "name": "Kumejima Airport",
        "city": "Kumejima",
        "country": "Japan",
        "iata": "UEO",
        "icao": "ROKJ",
        "latitude": "26.363500595092773",
        "longitude": "126.71399688720703",
        "altitude": "23",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MMD": {
        "name": "Minami-Daito Airport",
        "city": "Minami Daito",
        "country": "Japan",
        "iata": "MMD",
        "icao": "ROMD",
        "latitude": "25.8465003967",
        "longitude": "131.263000488",
        "altitude": "167",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MMY": {
        "name": "Miyako Airport",
        "city": "Miyako",
        "country": "Japan",
        "iata": "MMY",
        "icao": "ROMY",
        "latitude": "24.782800674399997",
        "longitude": "125.294998169",
        "altitude": "150",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KTD": {
        "name": "Kitadaito Airport",
        "city": "Kitadaito",
        "country": "Japan",
        "iata": "KTD",
        "icao": "RORK",
        "latitude": "25.9447002411",
        "longitude": "131.32699585",
        "altitude": "80",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "SHI": {
        "name": "Shimojishima Airport",
        "city": "Shimojishima",
        "country": "Japan",
        "iata": "SHI",
        "icao": "RORS",
        "latitude": "24.8267002106",
        "longitude": "125.144996643",
        "altitude": "54",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "TRA": {
        "name": "Tarama Airport",
        "city": "Tarama",
        "country": "Japan",
        "iata": "TRA",
        "icao": "RORT",
        "latitude": "24.653900146499996",
        "longitude": "124.675003052",
        "altitude": "36",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "RNJ": {
        "name": "Yoron Airport",
        "city": "Yoron",
        "country": "Japan",
        "iata": "RNJ",
        "icao": "RORY",
        "latitude": "27.0440006256",
        "longitude": "128.401992798",
        "altitude": "52",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OGN": {
        "name": "Yonaguni Airport",
        "city": "Yonaguni Jima",
        "country": "Japan",
        "iata": "OGN",
        "icao": "ROYN",
        "latitude": "24.466899871826172",
        "longitude": "122.97799682617188",
        "altitude": "70",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MNL": {
        "name": "Ninoy Aquino International Airport",
        "city": "Manila",
        "country": "Philippines",
        "iata": "MNL",
        "icao": "RPLL",
        "latitude": "14.5086",
        "longitude": "121.019997",
        "altitude": "75",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "CBO": {
        "name": "Awang Airport",
        "city": "Cotabato",
        "country": "Philippines",
        "iata": "CBO",
        "icao": "RPMC",
        "latitude": "7.1652398109436035",
        "longitude": "124.20999908447266",
        "altitude": "189",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "PAG": {
        "name": "Pagadian Airport",
        "city": "Pagadian",
        "country": "Philippines",
        "iata": "PAG",
        "icao": "RPMP",
        "latitude": "7.83073144787",
        "longitude": "123.461179733",
        "altitude": "5",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "GES": {
        "name": "General Santos International Airport",
        "city": "Romblon",
        "country": "Philippines",
        "iata": "GES",
        "icao": "RPMR",
        "latitude": "6.05800008774",
        "longitude": "125.096000671",
        "altitude": "505",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "ZAM": {
        "name": "Zamboanga International Airport",
        "city": "Zamboanga",
        "country": "Philippines",
        "iata": "ZAM",
        "icao": "RPMZ",
        "latitude": "6.922420024871826",
        "longitude": "122.05999755859375",
        "altitude": "33",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "BAG": {
        "name": "Loakan Airport",
        "city": "Baguio",
        "country": "Philippines",
        "iata": "BAG",
        "icao": "RPUB",
        "latitude": "16.375099182128906",
        "longitude": "120.62000274658203",
        "altitude": "4251",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "DTE": {
        "name": "Daet Airport",
        "city": "Daet",
        "country": "Philippines",
        "iata": "DTE",
        "icao": "RPUD",
        "latitude": "14.129199981689453",
        "longitude": "122.9800033569336",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "SJI": {
        "name": "San Jose Airport",
        "city": "San Jose",
        "country": "Philippines",
        "iata": "SJI",
        "icao": "RPUH",
        "latitude": "12.361499786399998",
        "longitude": "121.04699707",
        "altitude": "14",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "MBO": {
        "name": "Mamburao Airport",
        "city": "Mamburao",
        "country": "Philippines",
        "iata": "MBO",
        "icao": "RPUM",
        "latitude": "13.208100318908691",
        "longitude": "120.6050033569336",
        "altitude": "13",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "BQA": {
        "name": "Dr.Juan C. Angara Airport",
        "city": "Baler",
        "country": "Philippines",
        "iata": "BQA",
        "icao": "RPUR",
        "latitude": "15.729309",
        "longitude": "121.500034",
        "altitude": "108",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "TAC": {
        "name": "Daniel Z. Romualdez Airport",
        "city": "Tacloban",
        "country": "Philippines",
        "iata": "TAC",
        "icao": "RPVA",
        "latitude": "11.228035",
        "longitude": "125.027761",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "BCD": {
        "name": "Bacolod-Silay Airport",
        "city": "Bacolod",
        "country": "Philippines",
        "iata": "BCD",
        "icao": "RPVB",
        "latitude": "10.7764",
        "longitude": "123.014999",
        "altitude": "82",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "DGT": {
        "name": "Sibulan Airport",
        "city": "Dumaguete",
        "country": "Philippines",
        "iata": "DGT",
        "icao": "RPVD",
        "latitude": "9.3337097168",
        "longitude": "123.300003052",
        "altitude": "15",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "MPH": {
        "name": "Godofredo P. Ramos Airport",
        "city": "Caticlan",
        "country": "Philippines",
        "iata": "MPH",
        "icao": "RPVE",
        "latitude": "11.9245",
        "longitude": "121.954002",
        "altitude": "7",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "ILO": {
        "name": "Iloilo International Airport",
        "city": "Iloilo",
        "country": "Philippines",
        "iata": "ILO",
        "icao": "RPVI",
        "latitude": "10.833017",
        "longitude": "122.493358",
        "altitude": "27",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "KLO": {
        "name": "Kalibo International Airport",
        "city": "Kalibo",
        "country": "Philippines",
        "iata": "KLO",
        "icao": "RPVK",
        "latitude": "11.679400444",
        "longitude": "122.375999451",
        "altitude": "14",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "PPS": {
        "name": "Puerto Princesa Airport",
        "city": "Puerto Princesa",
        "country": "Philippines",
        "iata": "PPS",
        "icao": "RPVP",
        "latitude": "9.742119789123535",
        "longitude": "118.75900268554688",
        "altitude": "71",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "EUQ": {
        "name": "Evelio Javier Airport",
        "city": "San Jose",
        "country": "Philippines",
        "iata": "EUQ",
        "icao": "RPVS",
        "latitude": "10.765999794",
        "longitude": "121.932998657",
        "altitude": "23",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "COC": {
        "name": "Comodoro Pierrestegui Airport",
        "city": "Concordia",
        "country": "Argentina",
        "iata": "COC",
        "icao": "SAAC",
        "latitude": "-31.2969",
        "longitude": "-57.9966",
        "altitude": "112",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "GHU": {
        "name": "Gualeguaychu Airport",
        "city": "Gualeguaychu",
        "country": "Argentina",
        "iata": "GHU",
        "icao": "SAAG",
        "latitude": "-33.0103",
        "longitude": "-58.6131",
        "altitude": "75",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "JNI": {
        "name": "Junin Airport",
        "city": "Junin",
        "country": "Argentina",
        "iata": "JNI",
        "icao": "SAAJ",
        "latitude": "-34.5459",
        "longitude": "-60.9306",
        "altitude": "262",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "PRA": {
        "name": "General Urquiza Airport",
        "city": "Parana",
        "country": "Argentina",
        "iata": "PRA",
        "icao": "SAAP",
        "latitude": "-31.7948",
        "longitude": "-60.4804",
        "altitude": "242",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "ROS": {
        "name": "Islas Malvinas Airport",
        "city": "Rosario",
        "country": "Argentina",
        "iata": "ROS",
        "icao": "SAAR",
        "latitude": "-32.9036",
        "longitude": "-60.785",
        "altitude": "85",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "SFN": {
        "name": "Sauce Viejo Airport",
        "city": "Santa Fe",
        "country": "Argentina",
        "iata": "SFN",
        "icao": "SAAV",
        "latitude": "-31.7117",
        "longitude": "-60.8117",
        "altitude": "55",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "AEP": {
        "name": "Jorge Newbery Airpark",
        "city": "Buenos Aires",
        "country": "Argentina",
        "iata": "AEP",
        "icao": "SABE",
        "latitude": "-34.5592",
        "longitude": "-58.4156",
        "altitude": "18",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "COR": {
        "name": "Ingeniero Ambrosio Taravella Airport",
        "city": "Cordoba",
        "country": "Argentina",
        "iata": "COR",
        "icao": "SACO",
        "latitude": "-31.323601",
        "longitude": "-64.208",
        "altitude": "1604",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "FDO": {
        "name": "San Fernando Airport",
        "city": "San Fernando",
        "country": "Argentina",
        "iata": "FDO",
        "icao": "SADF",
        "latitude": "-34.4532",
        "longitude": "-58.5896",
        "altitude": "10",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "LPG": {
        "name": "La Plata Airport",
        "city": "La Plata",
        "country": "Argentina",
        "iata": "LPG",
        "icao": "SADL",
        "latitude": "-34.9722",
        "longitude": "-57.8947",
        "altitude": "72",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "EPA": {
        "name": "El Palomar Airport",
        "city": "El Palomar",
        "country": "Argentina",
        "iata": "EPA",
        "icao": "SADP",
        "latitude": "-34.6099",
        "longitude": "-58.6126",
        "altitude": "59",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "HOS": {
        "name": "Chos Malal Airport",
        "city": "Chosmadal",
        "country": "Argentina",
        "iata": "HOS",
        "icao": "SAHC",
        "latitude": "-37.444698333699996",
        "longitude": "-70.2225036621",
        "altitude": "2788",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "GNR": {
        "name": "Dr. Arturo H. Illia Airport",
        "city": "Fuerte Gral Roca",
        "country": "Argentina",
        "iata": "GNR",
        "icao": "SAHR",
        "latitude": "-39.0007019043",
        "longitude": "-67.6204986572",
        "altitude": "852",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "MDZ": {
        "name": "El Plumerillo Airport",
        "city": "Mendoza",
        "country": "Argentina",
        "iata": "MDZ",
        "icao": "SAME",
        "latitude": "-32.8316993713",
        "longitude": "-68.7929000854",
        "altitude": "2310",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Mendoza"
    },
    "LGS": {
        "name": "Comodoro D.R. Salomón Airport",
        "city": "Malargue",
        "country": "Argentina",
        "iata": "LGS",
        "icao": "SAMM",
        "latitude": "-35.493598938",
        "longitude": "-69.5743026733",
        "altitude": "4685",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Mendoza"
    },
    "AFA": {
        "name": "Suboficial Ay Santiago Germano Airport",
        "city": "San Rafael",
        "country": "Argentina",
        "iata": "AFA",
        "icao": "SAMR",
        "latitude": "-34.588299",
        "longitude": "-68.4039",
        "altitude": "2470",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Mendoza"
    },
    "CTC": {
        "name": "Catamarca Airport",
        "city": "Catamarca",
        "country": "Argentina",
        "iata": "CTC",
        "icao": "SANC",
        "latitude": "-28.5956001282",
        "longitude": "-65.751701355",
        "altitude": "1522",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "SDE": {
        "name": "Vicecomodoro Angel D. La Paz Aragonés Airport",
        "city": "Santiago Del Estero",
        "country": "Argentina",
        "iata": "SDE",
        "icao": "SANE",
        "latitude": "-27.765556335399996",
        "longitude": "-64.3099975586",
        "altitude": "656",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "IRJ": {
        "name": "Capitan V A Almonacid Airport",
        "city": "La Rioja",
        "country": "Argentina",
        "iata": "IRJ",
        "icao": "SANL",
        "latitude": "-29.3815994263",
        "longitude": "-66.7957992554",
        "altitude": "1437",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/La_Rioja"
    },
    "TUC": {
        "name": "Teniente Benjamin Matienzo Airport",
        "city": "Tucuman",
        "country": "Argentina",
        "iata": "TUC",
        "icao": "SANT",
        "latitude": "-26.8409",
        "longitude": "-65.104897",
        "altitude": "1493",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Tucuman"
    },
    "UAQ": {
        "name": "Domingo Faustino Sarmiento Airport",
        "city": "San Juan",
        "country": "Argentina",
        "iata": "UAQ",
        "icao": "SANU",
        "latitude": "-31.571501",
        "longitude": "-68.418198",
        "altitude": "1958",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/San_Juan"
    },
    "RCU": {
        "name": "Area De Material Airport",
        "city": "Rio Cuarto",
        "country": "Argentina",
        "iata": "RCU",
        "icao": "SAOC",
        "latitude": "-33.0850982666",
        "longitude": "-64.2612991333",
        "altitude": "1380",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "VDR": {
        "name": "Villa Dolores Airport",
        "city": "Villa Dolores",
        "country": "Argentina",
        "iata": "VDR",
        "icao": "SAOD",
        "latitude": "-31.9451999664",
        "longitude": "-65.1463012695",
        "altitude": "1847",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "VME": {
        "name": "Villa Reynolds Airport",
        "city": "Villa Reynolds",
        "country": "Argentina",
        "iata": "VME",
        "icao": "SAOR",
        "latitude": "-33.7299003601",
        "longitude": "-65.3873977661",
        "altitude": "1591",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/San_Luis"
    },
    "LUQ": {
        "name": "Brigadier Mayor D Cesar Raul Ojeda Airport",
        "city": "San Luis",
        "country": "Argentina",
        "iata": "LUQ",
        "icao": "SAOU",
        "latitude": "-33.2732009888",
        "longitude": "-66.3563995361",
        "altitude": "2328",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/San_Luis"
    },
    "CNQ": {
        "name": "Corrientes Airport",
        "city": "Corrientes",
        "country": "Argentina",
        "iata": "CNQ",
        "icao": "SARC",
        "latitude": "-27.4455",
        "longitude": "-58.7619",
        "altitude": "202",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "RES": {
        "name": "Resistencia International Airport",
        "city": "Resistencia",
        "country": "Argentina",
        "iata": "RES",
        "icao": "SARE",
        "latitude": "-27.45",
        "longitude": "-59.0561",
        "altitude": "173",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "FMA": {
        "name": "Formosa Airport",
        "city": "Formosa",
        "country": "Argentina",
        "iata": "FMA",
        "icao": "SARF",
        "latitude": "-26.2127",
        "longitude": "-58.2281",
        "altitude": "193",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "IGR": {
        "name": "Cataratas Del Iguazú International Airport",
        "city": "Iguazu Falls",
        "country": "Argentina",
        "iata": "IGR",
        "icao": "SARI",
        "latitude": "-25.737301",
        "longitude": "-54.4734",
        "altitude": "916",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "AOL": {
        "name": "Paso De Los Libres Airport",
        "city": "Paso De Los Libres",
        "country": "Argentina",
        "iata": "AOL",
        "icao": "SARL",
        "latitude": "-29.6894",
        "longitude": "-57.1521",
        "altitude": "230",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "MCS": {
        "name": "Monte Caseros Airport",
        "city": "Monte Caseros",
        "country": "Argentina",
        "iata": "MCS",
        "icao": "SARM",
        "latitude": "-30.2719",
        "longitude": "-57.6402",
        "altitude": "170",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "PSS": {
        "name": "Libertador Gral D Jose De San Martin Airport",
        "city": "Posadas",
        "country": "Argentina",
        "iata": "PSS",
        "icao": "SARP",
        "latitude": "-27.3858",
        "longitude": "-55.9707",
        "altitude": "430",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "PRQ": {
        "name": "Termal Airport",
        "city": "Presidencia R.s.pena",
        "country": "Argentina",
        "iata": "PRQ",
        "icao": "SARS",
        "latitude": "-26.753611",
        "longitude": "-60.492222",
        "altitude": "308",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "SLA": {
        "name": "Martin Miguel De Guemes International Airport",
        "city": "Salta",
        "country": "Argentina",
        "iata": "SLA",
        "icao": "SASA",
        "latitude": "-24.856000900299996",
        "longitude": "-65.4861984253",
        "altitude": "4088",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "JUJ": {
        "name": "Gobernador Horacio Guzman International Airport",
        "city": "Jujuy",
        "country": "Argentina",
        "iata": "JUJ",
        "icao": "SASJ",
        "latitude": "-24.392799",
        "longitude": "-65.097801",
        "altitude": "3019",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Jujuy"
    },
    "ORA": {
        "name": "Orán Airport",
        "city": "Oran",
        "country": "Argentina",
        "iata": "ORA",
        "icao": "SASO",
        "latitude": "-23.1527996063",
        "longitude": "-64.3292007446",
        "altitude": "1171",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "ELO": {
        "name": "El Dorado Airport",
        "city": "El Dorado",
        "country": "Argentina",
        "iata": "ELO",
        "icao": "SATD",
        "latitude": "-26.3974990845",
        "longitude": "-54.5746994019",
        "altitude": "685",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "OYA": {
        "name": "Goya Airport",
        "city": "Goya",
        "country": "Argentina",
        "iata": "OYA",
        "icao": "SATG",
        "latitude": "-29.1058",
        "longitude": "-59.2189",
        "altitude": "128",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "RCQ": {
        "name": "Reconquista Airport",
        "city": "Reconquista",
        "country": "Argentina",
        "iata": "RCQ",
        "icao": "SATR",
        "latitude": "-29.2103",
        "longitude": "-59.68",
        "altitude": "160",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "UZU": {
        "name": "Curuzu Cuatia Airport",
        "city": "Curuzu Cuatia",
        "country": "Argentina",
        "iata": "UZU",
        "icao": "SATU",
        "latitude": "-29.7706",
        "longitude": "-57.9789",
        "altitude": "229",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Cordoba"
    },
    "EHL": {
        "name": "El Bolson Airport",
        "city": "El Bolson",
        "country": "Argentina",
        "iata": "EHL",
        "icao": "SAVB",
        "latitude": "-41.9431991577",
        "longitude": "-71.5323028564",
        "altitude": "1141",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "CRD": {
        "name": "General E. Mosconi Airport",
        "city": "Comodoro Rivadavia",
        "country": "Argentina",
        "iata": "CRD",
        "icao": "SAVC",
        "latitude": "-45.7853",
        "longitude": "-67.4655",
        "altitude": "189",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "EQS": {
        "name": "Brigadier Antonio Parodi Airport",
        "city": "Esquel",
        "country": "Argentina",
        "iata": "EQS",
        "icao": "SAVE",
        "latitude": "-42.908000946",
        "longitude": "-71.139503479",
        "altitude": "2621",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "REL": {
        "name": "Almirante Marco Andres Zar Airport",
        "city": "Trelew",
        "country": "Argentina",
        "iata": "REL",
        "icao": "SAVT",
        "latitude": "-43.2105",
        "longitude": "-65.2703",
        "altitude": "141",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "VDM": {
        "name": "Gobernador Castello Airport",
        "city": "Viedma",
        "country": "Argentina",
        "iata": "VDM",
        "icao": "SAVV",
        "latitude": "-40.8692",
        "longitude": "-63.0004",
        "altitude": "20",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "PMY": {
        "name": "El Tehuelche Airport",
        "city": "Puerto Madryn",
        "country": "Argentina",
        "iata": "PMY",
        "icao": "SAVY",
        "latitude": "-42.7592",
        "longitude": "-65.1027",
        "altitude": "427",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "PUD": {
        "name": "Puerto Deseado Airport",
        "city": "Puerto Deseado",
        "country": "Argentina",
        "iata": "PUD",
        "icao": "SAWD",
        "latitude": "-47.7353",
        "longitude": "-65.9041",
        "altitude": "268",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "RGA": {
        "name": "Hermes Quijada International Airport",
        "city": "Rio Grande",
        "country": "Argentina",
        "iata": "RGA",
        "icao": "SAWE",
        "latitude": "-53.7777",
        "longitude": "-67.7494",
        "altitude": "65",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Ushuaia"
    },
    "RGL": {
        "name": "Piloto Civil N. Fernández Airport",
        "city": "Rio Gallegos",
        "country": "Argentina",
        "iata": "RGL",
        "icao": "SAWG",
        "latitude": "-51.6089",
        "longitude": "-69.3126",
        "altitude": "61",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "USH": {
        "name": "Malvinas Argentinas Airport",
        "city": "Ushuaia",
        "country": "Argentina",
        "iata": "USH",
        "icao": "SAWH",
        "latitude": "-54.8433",
        "longitude": "-68.2958",
        "altitude": "102",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Ushuaia"
    },
    "ULA": {
        "name": "Capitan D Daniel Vazquez Airport",
        "city": "San Julian",
        "country": "Argentina",
        "iata": "ULA",
        "icao": "SAWJ",
        "latitude": "-49.3068",
        "longitude": "-67.8026",
        "altitude": "203",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "PMQ": {
        "name": "Perito Moreno Airport",
        "city": "Perito Moreno",
        "country": "Argentina",
        "iata": "PMQ",
        "icao": "SAWP",
        "latitude": "-46.537899",
        "longitude": "-70.978699",
        "altitude": "1410",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "RZA": {
        "name": "Santa Cruz Airport",
        "city": "Santa Cruz",
        "country": "Argentina",
        "iata": "RZA",
        "icao": "SAWU",
        "latitude": "-50.0165",
        "longitude": "-68.5792",
        "altitude": "364",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "BHI": {
        "name": "Comandante Espora Airport",
        "city": "Bahia Blanca",
        "country": "Argentina",
        "iata": "BHI",
        "icao": "SAZB",
        "latitude": "-38.725",
        "longitude": "-62.1693",
        "altitude": "246",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "CSZ": {
        "name": "Brigadier D.H.E. Ruiz Airport",
        "city": "Colonel Suarez",
        "country": "Argentina",
        "iata": "CSZ",
        "icao": "SAZC",
        "latitude": "-37.446098",
        "longitude": "-61.889301",
        "altitude": "767",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "OVR": {
        "name": "Olavarria Airport",
        "city": "Olavarria",
        "country": "Argentina",
        "iata": "OVR",
        "icao": "SAZF",
        "latitude": "-36.8899993896",
        "longitude": "-60.216598510699995",
        "altitude": "551",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "GPO": {
        "name": "General Pico Airport",
        "city": "General Pico",
        "country": "Argentina",
        "iata": "GPO",
        "icao": "SAZG",
        "latitude": "-35.6962013245",
        "longitude": "-63.7583007812",
        "altitude": "459",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "OYO": {
        "name": "Tres Arroyos Airport",
        "city": "Tres Arroyos",
        "country": "Argentina",
        "iata": "OYO",
        "icao": "SAZH",
        "latitude": "-38.3869",
        "longitude": "-60.3297",
        "altitude": "400",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "MDQ": {
        "name": "Ástor Piazzola International Airport",
        "city": "Mar Del Plata",
        "country": "Argentina",
        "iata": "MDQ",
        "icao": "SAZM",
        "latitude": "-37.9342",
        "longitude": "-57.5733",
        "altitude": "72",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "NQN": {
        "name": "Presidente Peron Airport",
        "city": "Neuquen",
        "country": "Argentina",
        "iata": "NQN",
        "icao": "SAZN",
        "latitude": "-38.949001",
        "longitude": "-68.155701",
        "altitude": "895",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "PEH": {
        "name": "Comodoro Pedro Zanni Airport",
        "city": "Pehuajo",
        "country": "Argentina",
        "iata": "PEH",
        "icao": "SAZP",
        "latitude": "-35.8446",
        "longitude": "-61.8576",
        "altitude": "278",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "RSA": {
        "name": "Santa Rosa Airport",
        "city": "Santa Rosa",
        "country": "Argentina",
        "iata": "RSA",
        "icao": "SAZR",
        "latitude": "-36.588299",
        "longitude": "-64.275703",
        "altitude": "630",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "BRC": {
        "name": "San Carlos De Bariloche Airport",
        "city": "San Carlos De Bariloche",
        "country": "Argentina",
        "iata": "BRC",
        "icao": "SAZS",
        "latitude": "-41.151199",
        "longitude": "-71.157501",
        "altitude": "2774",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "TDL": {
        "name": "Héroes De Malvinas Airport",
        "city": "Tandil",
        "country": "Argentina",
        "iata": "TDL",
        "icao": "SAZT",
        "latitude": "-37.2374000549",
        "longitude": "-59.2279014587",
        "altitude": "574",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "VLG": {
        "name": "Villa Gesell Airport",
        "city": "Villa Gesell",
        "country": "Argentina",
        "iata": "VLG",
        "icao": "SAZV",
        "latitude": "-37.2354",
        "longitude": "-57.0292",
        "altitude": "32",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "CUT": {
        "name": "Cutral-Co Airport",
        "city": "Cutralco",
        "country": "Argentina",
        "iata": "CUT",
        "icao": "SAZW",
        "latitude": "-38.939701080300004",
        "longitude": "-69.2646026611",
        "altitude": "2132",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "CPC": {
        "name": "Aviador C. Campos Airport",
        "city": "San Martin Des Andes",
        "country": "Argentina",
        "iata": "CPC",
        "icao": "SAZY",
        "latitude": "-40.075401",
        "longitude": "-71.137299",
        "altitude": "2569",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "CDJ": {
        "name": "Conceição do Araguaia Airport",
        "city": "Conceicao Do Araguaia",
        "country": "Brazil",
        "iata": "CDJ",
        "icao": "SBAA",
        "latitude": "-8.348349571228027",
        "longitude": "-49.30149841308594",
        "altitude": "653",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "AQA": {
        "name": "Araraquara Airport",
        "city": "Araracuara",
        "country": "Brazil",
        "iata": "AQA",
        "icao": "SBAQ",
        "latitude": "-21.812000274699997",
        "longitude": "-48.1329994202",
        "altitude": "2334",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "AJU": {
        "name": "Santa Maria Airport",
        "city": "Aracaju",
        "country": "Brazil",
        "iata": "AJU",
        "icao": "SBAR",
        "latitude": "-10.984000206",
        "longitude": "-37.0703010559",
        "altitude": "23",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "AFL": {
        "name": "Piloto Osvaldo Marques Dias Airport",
        "city": "Alta Floresta",
        "country": "Brazil",
        "iata": "AFL",
        "icao": "SBAT",
        "latitude": "-9.8663892746",
        "longitude": "-56.1049995422",
        "altitude": "948",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "ARU": {
        "name": "Araçatuba Airport",
        "city": "Aracatuba",
        "country": "Brazil",
        "iata": "ARU",
        "icao": "SBAU",
        "latitude": "-21.1413002014",
        "longitude": "-50.4247016907",
        "altitude": "1361",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BEL": {
        "name": "Val de Cans/Júlio Cezar Ribeiro International Airport",
        "city": "Belem",
        "country": "Brazil",
        "iata": "BEL",
        "icao": "SBBE",
        "latitude": "-1.3792500495900002",
        "longitude": "-48.4762992859",
        "altitude": "54",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "BGX": {
        "name": "Comandante Gustavo Kraemer Airport",
        "city": "Bage",
        "country": "Brazil",
        "iata": "BGX",
        "icao": "SBBG",
        "latitude": "-31.39049911499",
        "longitude": "-54.112201690674",
        "altitude": "600",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "PLU": {
        "name": "Pampulha - Carlos Drummond de Andrade Airport",
        "city": "Belo Horizonte",
        "country": "Brazil",
        "iata": "PLU",
        "icao": "SBBH",
        "latitude": "-19.851200103759766",
        "longitude": "-43.950599670410156",
        "altitude": "2589",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BFH": {
        "name": "Bacacheri Airport",
        "city": "Curitiba",
        "country": "Brazil",
        "iata": "BFH",
        "icao": "SBBI",
        "latitude": "-25.4050998688",
        "longitude": "-49.23199844359999",
        "altitude": "3057",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BSB": {
        "name": "Presidente Juscelino Kubistschek International Airport",
        "city": "Brasilia",
        "country": "Brazil",
        "iata": "BSB",
        "icao": "SBBR",
        "latitude": "-15.86916732788086",
        "longitude": "-47.920833587646484",
        "altitude": "3497",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BAU": {
        "name": "Bauru Airport",
        "city": "Bauru",
        "country": "Brazil",
        "iata": "BAU",
        "icao": "SBBU",
        "latitude": "-22.3449993134",
        "longitude": "-49.0537986755",
        "altitude": "2025",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BVB": {
        "name": "Atlas Brasil Cantanhede Airport",
        "city": "Boa Vista",
        "country": "Brazil",
        "iata": "BVB",
        "icao": "SBBV",
        "latitude": "2.84138894081",
        "longitude": "-60.6922225952",
        "altitude": "276",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "BPG": {
        "name": "Barra do Garças Airport",
        "city": "Barra Do Garcas",
        "country": "Brazil",
        "iata": "BPG",
        "icao": "SBBW",
        "latitude": "-15.861300468400001",
        "longitude": "-52.3889007568",
        "altitude": "1147",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "CAC": {
        "name": "Cascavel Airport",
        "city": "Cascavel",
        "country": "Brazil",
        "iata": "CAC",
        "icao": "SBCA",
        "latitude": "-25.0002994537",
        "longitude": "-53.500801086399996",
        "altitude": "2473",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CNF": {
        "name": "Tancredo Neves International Airport",
        "city": "Belo Horizonte",
        "country": "Brazil",
        "iata": "CNF",
        "icao": "SBCF",
        "latitude": "-19.62444305419922",
        "longitude": "-43.97194290161133",
        "altitude": "2715",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CGR": {
        "name": "Campo Grande Airport",
        "city": "Campo Grande",
        "country": "Brazil",
        "iata": "CGR",
        "icao": "SBCG",
        "latitude": "-20.468700408900002",
        "longitude": "-54.6725006104",
        "altitude": "1833",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "XAP": {
        "name": "Serafin Enoss Bertaso Airport",
        "city": "Chapeco",
        "country": "Brazil",
        "iata": "XAP",
        "icao": "SBCH",
        "latitude": "-27.134199142456",
        "longitude": "-52.656600952148",
        "altitude": "2146",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CLN": {
        "name": "Brig. Lysias Augusto Rodrigues Airport",
        "city": "Carolina",
        "country": "Brazil",
        "iata": "CLN",
        "icao": "SBCI",
        "latitude": "-7.32043981552124",
        "longitude": "-47.45869827270508",
        "altitude": "565",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CCM": {
        "name": "Diomício Freitas Airport",
        "city": "Criciuma",
        "country": "Brazil",
        "iata": "CCM",
        "icao": "SBCM",
        "latitude": "-28.7244434357",
        "longitude": "-49.4213905334",
        "altitude": "93",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CAW": {
        "name": "Bartolomeu Lisandro Airport",
        "city": "Campos",
        "country": "Brazil",
        "iata": "CAW",
        "icao": "SBCP",
        "latitude": "-21.698299408",
        "longitude": "-41.301700592",
        "altitude": "57",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CMG": {
        "name": "Corumbá International Airport",
        "city": "Corumba",
        "country": "Brazil",
        "iata": "CMG",
        "icao": "SBCR",
        "latitude": "-19.0119438171",
        "longitude": "-57.6713905334",
        "altitude": "461",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "CWB": {
        "name": "Afonso Pena Airport",
        "city": "Curitiba",
        "country": "Brazil",
        "iata": "CWB",
        "icao": "SBCT",
        "latitude": "-25.5284996033",
        "longitude": "-49.1758003235",
        "altitude": "2988",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CRQ": {
        "name": "Caravelas Airport",
        "city": "Caravelas",
        "country": "Brazil",
        "iata": "CRQ",
        "icao": "SBCV",
        "latitude": "-17.652299880981",
        "longitude": "-39.253101348877",
        "altitude": "36",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CXJ": {
        "name": "Hugo Cantergiani Regional Airport",
        "city": "Caxias Do Sul",
        "country": "Brazil",
        "iata": "CXJ",
        "icao": "SBCX",
        "latitude": "-29.197099685699996",
        "longitude": "-51.1875",
        "altitude": "2472",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CGB": {
        "name": "Marechal Rondon Airport",
        "city": "Cuiaba",
        "country": "Brazil",
        "iata": "CGB",
        "icao": "SBCY",
        "latitude": "-15.6528997421",
        "longitude": "-56.1166992188",
        "altitude": "617",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "CZS": {
        "name": "Cruzeiro do Sul Airport",
        "city": "Cruzeiro do Sul",
        "country": "Brazil",
        "iata": "CZS",
        "icao": "SBCZ",
        "latitude": "-7.59990978241",
        "longitude": "-72.7695007324",
        "altitude": "637",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Rio_Branco"
    },
    "PPB": {
        "name": "Presidente Prudente Airport",
        "city": "President Prudente",
        "country": "Brazil",
        "iata": "PPB",
        "icao": "SBDN",
        "latitude": "-22.1751003265",
        "longitude": "-51.4245986938",
        "altitude": "1477",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MAO": {
        "name": "Eduardo Gomes International Airport",
        "city": "Manaus",
        "country": "Brazil",
        "iata": "MAO",
        "icao": "SBEG",
        "latitude": "-3.0386099815368652",
        "longitude": "-60.04970169067383",
        "altitude": "264",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "JCR": {
        "name": "Jacareacanga Airport",
        "city": "Jacare-acanga",
        "country": "Brazil",
        "iata": "JCR",
        "icao": "SBEK",
        "latitude": "-6.233160018920898",
        "longitude": "-57.77690124511719",
        "altitude": "323",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "IGU": {
        "name": "Cataratas International Airport",
        "city": "Foz Do Iguacu",
        "country": "Brazil",
        "iata": "IGU",
        "icao": "SBFI",
        "latitude": "-25.600278854370117",
        "longitude": "-54.48500061035156",
        "altitude": "786",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "FLN": {
        "name": "Hercílio Luz International Airport",
        "city": "Florianopolis",
        "country": "Brazil",
        "iata": "FLN",
        "icao": "SBFL",
        "latitude": "-27.670278549194336",
        "longitude": "-48.5525016784668",
        "altitude": "16",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "FEN": {
        "name": "Fernando de Noronha Airport",
        "city": "Fernando Do Noronha",
        "country": "Brazil",
        "iata": "FEN",
        "icao": "SBFN",
        "latitude": "-3.85493",
        "longitude": "-32.423302",
        "altitude": "193",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "FOR": {
        "name": "Pinto Martins International Airport",
        "city": "Fortaleza",
        "country": "Brazil",
        "iata": "FOR",
        "icao": "SBFZ",
        "latitude": "-3.776279926300049",
        "longitude": "-38.53260040283203",
        "altitude": "82",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "GIG": {
        "name": "Rio Galeão – Tom Jobim International Airport",
        "city": "Rio De Janeiro",
        "country": "Brazil",
        "iata": "GIG",
        "icao": "SBGL",
        "latitude": "-22.8099994659",
        "longitude": "-43.2505569458",
        "altitude": "28",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "GJM": {
        "name": "Guajará-Mirim Airport",
        "city": "Guajara-mirim",
        "country": "Brazil",
        "iata": "GJM",
        "icao": "SBGM",
        "latitude": "-10.786399841308594",
        "longitude": "-65.28479766845703",
        "altitude": "478",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "GYN": {
        "name": "Santa Genoveva Airport",
        "city": "Goiania",
        "country": "Brazil",
        "iata": "GYN",
        "icao": "SBGO",
        "latitude": "-16.631999969482422",
        "longitude": "-49.220699310302734",
        "altitude": "2450",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "GRU": {
        "name": "Guarulhos - Governador André Franco Montoro International Airport",
        "city": "Sao Paulo",
        "country": "Brazil",
        "iata": "GRU",
        "icao": "SBGR",
        "latitude": "-23.435556411743164",
        "longitude": "-46.47305679321289",
        "altitude": "2459",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "GUJ": {
        "name": "Guaratinguetá Airport",
        "city": "Guaratingueta",
        "country": "Brazil",
        "iata": "GUJ",
        "icao": "SBGW",
        "latitude": "-22.79159927368164",
        "longitude": "-45.20479965209961",
        "altitude": "1761",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "ATM": {
        "name": "Altamira Airport",
        "city": "Altamira",
        "country": "Brazil",
        "iata": "ATM",
        "icao": "SBHT",
        "latitude": "-3.2539100646973",
        "longitude": "-52.254001617432",
        "altitude": "369",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "ITA": {
        "name": "Itacoatiara Airport",
        "city": "Itaituba",
        "country": "Brazil",
        "iata": "ITA",
        "icao": "SBIC",
        "latitude": "-3.1272599697113037",
        "longitude": "-58.481201171875",
        "altitude": "142",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "ITB": {
        "name": "Itaituba Airport",
        "city": "Itaituba",
        "country": "Brazil",
        "iata": "ITB",
        "icao": "SBIH",
        "latitude": "-4.2423400878906",
        "longitude": "-56.000701904297",
        "altitude": "110",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "IOS": {
        "name": "Bahia - Jorge Amado Airport",
        "city": "Ilheus",
        "country": "Brazil",
        "iata": "IOS",
        "icao": "SBIL",
        "latitude": "-14.815999984741",
        "longitude": "-39.033199310303",
        "altitude": "15",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "IPN": {
        "name": "Usiminas Airport",
        "city": "Ipatinga",
        "country": "Brazil",
        "iata": "IPN",
        "icao": "SBIP",
        "latitude": "-19.470699310303",
        "longitude": "-42.487598419189",
        "altitude": "784",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "ITR": {
        "name": "Francisco Vilela do Amaral Airport",
        "city": "Itumbiara",
        "country": "Brazil",
        "iata": "ITR",
        "icao": "SBIT",
        "latitude": "-18.4447002411",
        "longitude": "-49.2134017944",
        "altitude": "1630",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "IMP": {
        "name": "Prefeito Renato Moreira Airport",
        "city": "Imperatriz",
        "country": "Brazil",
        "iata": "IMP",
        "icao": "SBIZ",
        "latitude": "-5.53129",
        "longitude": "-47.459999",
        "altitude": "432",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "JDF": {
        "name": "Francisco de Assis Airport",
        "city": "Juiz De Fora",
        "country": "Brazil",
        "iata": "JDF",
        "icao": "SBJF",
        "latitude": "-21.791500091552734",
        "longitude": "-43.38679885864258",
        "altitude": "2989",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "JPA": {
        "name": "Presidente Castro Pinto International Airport",
        "city": "Joao Pessoa",
        "country": "Brazil",
        "iata": "JPA",
        "icao": "SBJP",
        "latitude": "-7.145833015440001",
        "longitude": "-34.9486122131",
        "altitude": "217",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "JOI": {
        "name": "Lauro Carneiro de Loyola Airport",
        "city": "Joinville",
        "country": "Brazil",
        "iata": "JOI",
        "icao": "SBJV",
        "latitude": "-26.22450065612793",
        "longitude": "-48.797401428222656",
        "altitude": "15",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CPV": {
        "name": "Presidente João Suassuna Airport",
        "city": "Campina Grande",
        "country": "Brazil",
        "iata": "CPV",
        "icao": "SBKG",
        "latitude": "-7.26992",
        "longitude": "-35.8964",
        "altitude": "1646",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "VCP": {
        "name": "Viracopos International Airport",
        "city": "Campinas",
        "country": "Brazil",
        "iata": "VCP",
        "icao": "SBKP",
        "latitude": "-23.0074005127",
        "longitude": "-47.1344985962",
        "altitude": "2170",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "LAJ": {
        "name": "Lages Airport",
        "city": "Lajes",
        "country": "Brazil",
        "iata": "LAJ",
        "icao": "SBLJ",
        "latitude": "-27.782100677499997",
        "longitude": "-50.28150177",
        "altitude": "3065",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "LIP": {
        "name": "Lins Airport",
        "city": "Lins",
        "country": "Brazil",
        "iata": "LIP",
        "icao": "SBLN",
        "latitude": "-21.663999557495",
        "longitude": "-49.730499267578",
        "altitude": "1559",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "LDB": {
        "name": "Governador José Richa Airport",
        "city": "Londrina",
        "country": "Brazil",
        "iata": "LDB",
        "icao": "SBLO",
        "latitude": "-23.333599090599996",
        "longitude": "-51.1301002502",
        "altitude": "1867",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "LAZ": {
        "name": "Bom Jesus da Lapa Airport",
        "city": "Bom Jesus Da Lapa",
        "country": "Brazil",
        "iata": "LAZ",
        "icao": "SBLP",
        "latitude": "-13.2621002197",
        "longitude": "-43.4081001282",
        "altitude": "1454",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "MAB": {
        "name": "João Correa da Rocha Airport",
        "city": "Maraba",
        "country": "Brazil",
        "iata": "MAB",
        "icao": "SBMA",
        "latitude": "-5.36858987808",
        "longitude": "-49.138000488299994",
        "altitude": "357",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "MEU": {
        "name": "Monte Dourado Airport",
        "city": "Almeirim",
        "country": "Brazil",
        "iata": "MEU",
        "icao": "SBMD",
        "latitude": "-0.889839",
        "longitude": "-52.6022",
        "altitude": "677",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "MGF": {
        "name": "Regional de Maringá - Sílvio Nane Junior Airport",
        "city": "Maringa",
        "country": "Brazil",
        "iata": "MGF",
        "icao": "SBMG",
        "latitude": "-23.479444503799996",
        "longitude": "-52.01222229",
        "altitude": "1788",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MOC": {
        "name": "Mário Ribeiro Airport",
        "city": "Montes Claros",
        "country": "Brazil",
        "iata": "MOC",
        "icao": "SBMK",
        "latitude": "-16.706899642899998",
        "longitude": "-43.818901062",
        "altitude": "2191",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "PLL": {
        "name": "Ponta Pelada Airport",
        "city": "Manaus",
        "country": "Brazil",
        "iata": "PLL",
        "icao": "SBMN",
        "latitude": "-3.1460399627685547",
        "longitude": "-59.98630142211914",
        "altitude": "267",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "MCZ": {
        "name": "Zumbi dos Palmares Airport",
        "city": "Maceio",
        "country": "Brazil",
        "iata": "MCZ",
        "icao": "SBMO",
        "latitude": "-9.510809898376465",
        "longitude": "-35.79169845581055",
        "altitude": "387",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "MCP": {
        "name": "Alberto Alcolumbre Airport",
        "city": "Macapa",
        "country": "Brazil",
        "iata": "MCP",
        "icao": "SBMQ",
        "latitude": "0.0506640002131",
        "longitude": "-51.0722007751",
        "altitude": "56",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "MVF": {
        "name": "Dix-Sept Rosado Airport",
        "city": "Mocord",
        "country": "Brazil",
        "iata": "MVF",
        "icao": "SBMS",
        "latitude": "-5.2019200324999995",
        "longitude": "-37.3642997742",
        "altitude": "76",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "MNX": {
        "name": "Manicoré Airport",
        "city": "Manicore",
        "country": "Brazil",
        "iata": "MNX",
        "icao": "SBMY",
        "latitude": "-5.8113799095154",
        "longitude": "-61.278301239014",
        "altitude": "174",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "NVT": {
        "name": "Ministro Victor Konder International Airport",
        "city": "Navegantes",
        "country": "Brazil",
        "iata": "NVT",
        "icao": "SBNF",
        "latitude": "-26.879999",
        "longitude": "-48.651402",
        "altitude": "18",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "GEL": {
        "name": "Santo Ângelo Airport",
        "city": "Santo Angelo",
        "country": "Brazil",
        "iata": "GEL",
        "icao": "SBNM",
        "latitude": "-28.2817",
        "longitude": "-54.169102",
        "altitude": "1056",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "NAT": {
        "name": "Governador Aluízio Alves International Airport",
        "city": "Natal",
        "country": "Brazil",
        "iata": "NAT",
        "icao": "SBSG",
        "latitude": "-5.768056",
        "longitude": "-35.376111",
        "altitude": "272",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "OYK": {
        "name": "Oiapoque Airport",
        "city": "Oioiapoque",
        "country": "Brazil",
        "iata": "OYK",
        "icao": "SBOI",
        "latitude": "3.85548996925354",
        "longitude": "-51.79690170288086",
        "altitude": "63",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "POA": {
        "name": "Salgado Filho Airport",
        "city": "Porto Alegre",
        "country": "Brazil",
        "iata": "POA",
        "icao": "SBPA",
        "latitude": "-29.994400024414062",
        "longitude": "-51.1713981628418",
        "altitude": "11",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "PHB": {
        "name": "Prefeito Doutor João Silva Filho Airport",
        "city": "Parnaiba",
        "country": "Brazil",
        "iata": "PHB",
        "icao": "SBPB",
        "latitude": "-2.89374995232",
        "longitude": "-41.73199844359999",
        "altitude": "16",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "POO": {
        "name": "Poços de Caldas - Embaixador Walther Moreira Salles Airport",
        "city": "Pocos De Caldas",
        "country": "Brazil",
        "iata": "POO",
        "icao": "SBPC",
        "latitude": "-21.843000411987",
        "longitude": "-46.567901611328",
        "altitude": "4135",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "PFB": {
        "name": "Lauro Kurtz Airport",
        "city": "Passo Fundo",
        "country": "Brazil",
        "iata": "PFB",
        "icao": "SBPF",
        "latitude": "-28.243999",
        "longitude": "-52.326599",
        "altitude": "2376",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "PET": {
        "name": "João Simões Lopes Neto International Airport",
        "city": "Pelotas",
        "country": "Brazil",
        "iata": "PET",
        "icao": "SBPK",
        "latitude": "-31.718399",
        "longitude": "-52.327702",
        "altitude": "59",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "PNZ": {
        "name": "Senador Nilo Coelho Airport",
        "city": "Petrolina",
        "country": "Brazil",
        "iata": "PNZ",
        "icao": "SBPL",
        "latitude": "-9.362409591674805",
        "longitude": "-40.56909942626953",
        "altitude": "1263",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "PNB": {
        "name": "Porto Nacional Airport",
        "city": "Porto Nacional",
        "country": "Brazil",
        "iata": "PNB",
        "icao": "SBPN",
        "latitude": "-10.719400405883789",
        "longitude": "-48.39970016479492",
        "altitude": "870",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "PMG": {
        "name": "Ponta Porã Airport",
        "city": "Ponta Pora",
        "country": "Brazil",
        "iata": "PMG",
        "icao": "SBPP",
        "latitude": "-22.54960060119629",
        "longitude": "-55.702598571777344",
        "altitude": "2156",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "PVH": {
        "name": "Governador Jorge Teixeira de Oliveira Airport",
        "city": "Porto Velho",
        "country": "Brazil",
        "iata": "PVH",
        "icao": "SBPV",
        "latitude": "-8.70928955078125",
        "longitude": "-63.90230178833008",
        "altitude": "290",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "RBR": {
        "name": "Plácido de Castro Airport",
        "city": "Rio Branco",
        "country": "Brazil",
        "iata": "RBR",
        "icao": "SBRB",
        "latitude": "-9.868888854980469",
        "longitude": "-67.89805603027344",
        "altitude": "633",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Rio_Branco"
    },
    "REC": {
        "name": "Guararapes - Gilberto Freyre International Airport",
        "city": "Recife",
        "country": "Brazil",
        "iata": "REC",
        "icao": "SBRF",
        "latitude": "-8.126489639282227",
        "longitude": "-34.92359924316406",
        "altitude": "33",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "SDU": {
        "name": "Santos Dumont Airport",
        "city": "Rio De Janeiro",
        "country": "Brazil",
        "iata": "SDU",
        "icao": "SBRJ",
        "latitude": "-22.910499572799996",
        "longitude": "-43.1631011963",
        "altitude": "11",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "RAO": {
        "name": "Leite Lopes Airport",
        "city": "Ribeirao Preto",
        "country": "Brazil",
        "iata": "RAO",
        "icao": "SBRP",
        "latitude": "-21.136388778686523",
        "longitude": "-47.776668548583984",
        "altitude": "1806",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SNZ": {
        "name": "Santa Cruz Air Force Base",
        "city": "Rio De Janeiro",
        "country": "Brazil",
        "iata": "SNZ",
        "icao": "SBSC",
        "latitude": "-22.9324",
        "longitude": "-43.719101",
        "altitude": "10",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SJK": {
        "name": "Professor Urbano Ernesto Stumpf Airport",
        "city": "Sao Jose Dos Campos",
        "country": "Brazil",
        "iata": "SJK",
        "icao": "SBSJ",
        "latitude": "-23.22920036315918",
        "longitude": "-45.86149978637695",
        "altitude": "2120",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SLZ": {
        "name": "Marechal Cunha Machado International Airport",
        "city": "Sao Luis",
        "country": "Brazil",
        "iata": "SLZ",
        "icao": "SBSL",
        "latitude": "-2.585360050201416",
        "longitude": "-44.234100341796875",
        "altitude": "178",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CGH": {
        "name": "Congonhas Airport",
        "city": "Sao Paulo",
        "country": "Brazil",
        "iata": "CGH",
        "icao": "SBSP",
        "latitude": "-23.626110076904297",
        "longitude": "-46.65638732910156",
        "altitude": "2631",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SJP": {
        "name": "Prof. Eribelto Manoel Reino State Airport",
        "city": "Sao Jose Do Rio Preto",
        "country": "Brazil",
        "iata": "SJP",
        "icao": "SBSR",
        "latitude": "-20.816600799599996",
        "longitude": "-49.40650177",
        "altitude": "1784",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SSZ": {
        "name": "Base Aérea de Santos Airport",
        "city": "Santos",
        "country": "Brazil",
        "iata": "SSZ",
        "icao": "SBST",
        "latitude": "-23.928056716918945",
        "longitude": "-46.299720764160156",
        "altitude": "10",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SSA": {
        "name": "Deputado Luiz Eduardo Magalhães International Airport",
        "city": "Salvador",
        "country": "Brazil",
        "iata": "SSA",
        "icao": "SBSV",
        "latitude": "-12.9086112976",
        "longitude": "-38.3224983215",
        "altitude": "64",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "TMT": {
        "name": "Trombetas Airport",
        "city": "Oriximina",
        "country": "Brazil",
        "iata": "TMT",
        "icao": "SBTB",
        "latitude": "-1.489599943161",
        "longitude": "-56.396800994873",
        "altitude": "287",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "THE": {
        "name": "Senador Petrônio Portela Airport",
        "city": "Teresina",
        "country": "Brazil",
        "iata": "THE",
        "icao": "SBTE",
        "latitude": "-5.0599398613",
        "longitude": "-42.8235015869",
        "altitude": "219",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "TFF": {
        "name": "Tefé Airport",
        "city": "Tefe",
        "country": "Brazil",
        "iata": "TFF",
        "icao": "SBTF",
        "latitude": "-3.38294005394",
        "longitude": "-64.7240982056",
        "altitude": "188",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "TRQ": {
        "name": "Tarauacá Airport",
        "city": "Tarauaca",
        "country": "Brazil",
        "iata": "TRQ",
        "icao": "SBTK",
        "latitude": "-8.1552600860596",
        "longitude": "-70.783302307129",
        "altitude": "646",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Rio_Branco"
    },
    "TEC": {
        "name": "Telêmaco Borba Airport",
        "city": "Telemaco Borba",
        "country": "Brazil",
        "iata": "TEC",
        "icao": "SBTL",
        "latitude": "-24.317800521850586",
        "longitude": "-50.6515998840332",
        "altitude": "2610",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "TBT": {
        "name": "Tabatinga Airport",
        "city": "Tabatinga",
        "country": "Brazil",
        "iata": "TBT",
        "icao": "SBTT",
        "latitude": "-4.2556700706482",
        "longitude": "-69.93579864502",
        "altitude": "279",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "TUR": {
        "name": "Tucuruí Airport",
        "city": "Tucurui",
        "country": "Brazil",
        "iata": "TUR",
        "icao": "SBTU",
        "latitude": "-3.7860100269318",
        "longitude": "-49.72029876709",
        "altitude": "830",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "SJL": {
        "name": "São Gabriel da Cachoeira Airport",
        "city": "Sao Gabriel",
        "country": "Brazil",
        "iata": "SJL",
        "icao": "SBUA",
        "latitude": "-0.14835",
        "longitude": "-66.9855",
        "altitude": "251",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "PAV": {
        "name": "Paulo Afonso Airport",
        "city": "Paulo Alfonso",
        "country": "Brazil",
        "iata": "PAV",
        "icao": "SBUF",
        "latitude": "-9.4008798599243",
        "longitude": "-38.250598907471",
        "altitude": "883",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "URG": {
        "name": "Rubem Berta Airport",
        "city": "Uruguaiana",
        "country": "Brazil",
        "iata": "URG",
        "icao": "SBUG",
        "latitude": "-29.7821998596",
        "longitude": "-57.0382003784",
        "altitude": "256",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "UDI": {
        "name": "Ten. Cel. Aviador César Bombonato Airport",
        "city": "Uberlandia",
        "country": "Brazil",
        "iata": "UDI",
        "icao": "SBUL",
        "latitude": "-18.883612",
        "longitude": "-48.225277",
        "altitude": "3094",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "UBA": {
        "name": "Mário de Almeida Franco Airport",
        "city": "Uberaba",
        "country": "Brazil",
        "iata": "UBA",
        "icao": "SBUR",
        "latitude": "-19.764722824097",
        "longitude": "-47.966110229492",
        "altitude": "2655",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "VAG": {
        "name": "Major Brigadeiro Trompowsky Airport",
        "city": "Varginha",
        "country": "Brazil",
        "iata": "VAG",
        "icao": "SBVG",
        "latitude": "-21.5900993347",
        "longitude": "-45.4733009338",
        "altitude": "3025",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BVH": {
        "name": "Brigadeiro Camarão Airport",
        "city": "Vilhena",
        "country": "Brazil",
        "iata": "BVH",
        "icao": "SBVH",
        "latitude": "-12.694399833679",
        "longitude": "-60.098300933838",
        "altitude": "2018",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "VIX": {
        "name": "Eurico de Aguiar Salles Airport",
        "city": "Vitoria",
        "country": "Brazil",
        "iata": "VIX",
        "icao": "SBVT",
        "latitude": "-20.258057",
        "longitude": "-40.286388",
        "altitude": "11",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "QPS": {
        "name": "Campo Fontenelle Airport",
        "city": "Piracununga",
        "country": "Brazil",
        "iata": "QPS",
        "icao": "SBYS",
        "latitude": "-21.984600067138672",
        "longitude": "-47.334800720214844",
        "altitude": "1968",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "ARI": {
        "name": "Chacalluta Airport",
        "city": "Arica",
        "country": "Chile",
        "iata": "ARI",
        "icao": "SCAR",
        "latitude": "-18.348499",
        "longitude": "-70.338699",
        "altitude": "167",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "BBA": {
        "name": "Balmaceda Airport",
        "city": "Balmaceda",
        "country": "Chile",
        "iata": "BBA",
        "icao": "SCBA",
        "latitude": "-45.916099548339844",
        "longitude": "-71.68949890136719",
        "altitude": "1722",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "CCH": {
        "name": "Chile Chico Airport",
        "city": "Chile Chico",
        "country": "Chile",
        "iata": "CCH",
        "icao": "SCCC",
        "latitude": "-46.58330154418945",
        "longitude": "-71.6874008178711",
        "altitude": "1070",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "CJC": {
        "name": "El Loa Airport",
        "city": "Calama",
        "country": "Chile",
        "iata": "CJC",
        "icao": "SCCF",
        "latitude": "-22.498199462890625",
        "longitude": "-68.90360260009766",
        "altitude": "7543",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "YAI": {
        "name": "Gral. Bernardo O´Higgins Airport",
        "city": "Chillan",
        "country": "Chile",
        "iata": "YAI",
        "icao": "SCCH",
        "latitude": "-36.58250045776367",
        "longitude": "-72.03140258789062",
        "altitude": "495",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "PUQ": {
        "name": "Pdte. Carlos Ibañez del Campo Airport",
        "city": "Punta Arenas",
        "country": "Chile",
        "iata": "PUQ",
        "icao": "SCCI",
        "latitude": "-53.002602",
        "longitude": "-70.854599",
        "altitude": "139",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "GXQ": {
        "name": "Teniente Vidal Airport",
        "city": "Coyhaique",
        "country": "Chile",
        "iata": "GXQ",
        "icao": "SCCY",
        "latitude": "-45.594200134277344",
        "longitude": "-72.1061019897461",
        "altitude": "1020",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "IQQ": {
        "name": "Diego Aracena Airport",
        "city": "Iquique",
        "country": "Chile",
        "iata": "IQQ",
        "icao": "SCDA",
        "latitude": "-20.535200119018555",
        "longitude": "-70.1812973022461",
        "altitude": "155",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "SCL": {
        "name": "Comodoro Arturo Merino Benítez International Airport",
        "city": "Santiago",
        "country": "Chile",
        "iata": "SCL",
        "icao": "SCEL",
        "latitude": "-33.393001556396484",
        "longitude": "-70.78579711914062",
        "altitude": "1555",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "ANF": {
        "name": "Andrés Sabella Gálvez International Airport",
        "city": "Antofagasta",
        "country": "Chile",
        "iata": "ANF",
        "icao": "SCFA",
        "latitude": "-23.444501",
        "longitude": "-70.445099",
        "altitude": "455",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "WPR": {
        "name": "Capitan Fuentes Martinez Airport Airport",
        "city": "Porvenir",
        "country": "Chile",
        "iata": "WPR",
        "icao": "SCFM",
        "latitude": "-53.253700256347656",
        "longitude": "-70.31919860839844",
        "altitude": "104",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "FFU": {
        "name": "Futaleufú Airport",
        "city": "Futaleufu",
        "country": "Chile",
        "iata": "FFU",
        "icao": "SCFT",
        "latitude": "-43.18920135498047",
        "longitude": "-71.8510971069336",
        "altitude": "1148",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "LSQ": {
        "name": "María Dolores Airport",
        "city": "Los Angeles",
        "country": "Chile",
        "iata": "LSQ",
        "icao": "SCGE",
        "latitude": "-37.40169906616211",
        "longitude": "-72.42539978027344",
        "altitude": "374",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "WPU": {
        "name": "Guardiamarina Zañartu Airport",
        "city": "Puerto Williams",
        "country": "Chile",
        "iata": "WPU",
        "icao": "SCGZ",
        "latitude": "-54.93109893798828",
        "longitude": "-67.62629699707031",
        "altitude": "88",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "CCP": {
        "name": "Carriel Sur Airport",
        "city": "Concepcion",
        "country": "Chile",
        "iata": "CCP",
        "icao": "SCIE",
        "latitude": "-36.772701",
        "longitude": "-73.063103",
        "altitude": "26",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "IPC": {
        "name": "Mataveri Airport",
        "city": "Easter Island",
        "country": "Chile",
        "iata": "IPC",
        "icao": "SCIP",
        "latitude": "-27.1648006439",
        "longitude": "-109.42199707",
        "altitude": "227",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "Pacific/Easter"
    },
    "ZOS": {
        "name": "Cañal Bajo Carlos - Hott Siebert Airport",
        "city": "Osorno",
        "country": "Chile",
        "iata": "ZOS",
        "icao": "SCJO",
        "latitude": "-40.61119842529297",
        "longitude": "-73.06099700927734",
        "altitude": "187",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "VLR": {
        "name": "Vallenar Airport",
        "city": "Vallenar",
        "country": "Chile",
        "iata": "VLR",
        "icao": "SCLL",
        "latitude": "-28.596399307250977",
        "longitude": "-70.75599670410156",
        "altitude": "1725",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "QRC": {
        "name": "De La Independencia Airport",
        "city": "Rancagua",
        "country": "Chile",
        "iata": "QRC",
        "icao": "SCRG",
        "latitude": "-34.17369842529297",
        "longitude": "-70.77570343017578",
        "altitude": "1446",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "TNM": {
        "name": "Teniente Rodolfo Marsh Martin Base",
        "city": "Isla Rey Jorge",
        "country": "Antarctica",
        "iata": "TNM",
        "icao": "SCRM",
        "latitude": "-62.1907997131",
        "longitude": "-58.9866981506",
        "altitude": "147",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Antarctica/South_Pole"
    },
    "LSC": {
        "name": "La Florida Airport",
        "city": "La Serena",
        "country": "Chile",
        "iata": "LSC",
        "icao": "SCSE",
        "latitude": "-29.916201",
        "longitude": "-71.199501",
        "altitude": "481",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "PZS": {
        "name": "Maquehue Airport",
        "city": "Temuco",
        "country": "Chile",
        "iata": "PZS",
        "icao": "SCTC",
        "latitude": "-38.766799926758",
        "longitude": "-72.637100219727",
        "altitude": "304",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "PMC": {
        "name": "El Tepual Airport",
        "city": "Puerto Montt",
        "country": "Chile",
        "iata": "PMC",
        "icao": "SCTE",
        "latitude": "-41.438899993896484",
        "longitude": "-73.09400177001953",
        "altitude": "294",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "WCH": {
        "name": "Chaitén Airport",
        "city": "Chaiten",
        "country": "Chile",
        "iata": "WCH",
        "icao": "SCTN",
        "latitude": "-42.93280029296875",
        "longitude": "-72.6990966796875",
        "altitude": "13",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "ZAL": {
        "name": "Pichoy Airport",
        "city": "Valdivia",
        "country": "Chile",
        "iata": "ZAL",
        "icao": "SCVD",
        "latitude": "-39.6500015259",
        "longitude": "-73.0860977173",
        "altitude": "59",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "ATF": {
        "name": "Chachoán Airport",
        "city": "Ambato",
        "country": "Ecuador",
        "iata": "ATF",
        "icao": "SEAM",
        "latitude": "-1.2120699882507324",
        "longitude": "-78.57460021972656",
        "altitude": "8502",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "OCC": {
        "name": "Francisco De Orellana Airport",
        "city": "Coca",
        "country": "Ecuador",
        "iata": "OCC",
        "icao": "SECO",
        "latitude": "-0.4628860056400299",
        "longitude": "-76.98680114746094",
        "altitude": "834",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "CUE": {
        "name": "Mariscal Lamar Airport",
        "city": "Cuenca",
        "country": "Ecuador",
        "iata": "CUE",
        "icao": "SECU",
        "latitude": "-2.88947",
        "longitude": "-78.984398",
        "altitude": "8306",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "GPS": {
        "name": "Seymour Airport",
        "city": "Galapagos",
        "country": "Ecuador",
        "iata": "GPS",
        "icao": "SEGS",
        "latitude": "-0.45375800132751465",
        "longitude": "-90.26589965820312",
        "altitude": "207",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "Pacific/Galapagos"
    },
    "GYE": {
        "name": "José Joaquín de Olmedo International Airport",
        "city": "Guayaquil",
        "country": "Ecuador",
        "iata": "GYE",
        "icao": "SEGU",
        "latitude": "-2.1574199199699997",
        "longitude": "-79.88359832760001",
        "altitude": "19",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "LTX": {
        "name": "Cotopaxi International Airport",
        "city": "Latacunga",
        "country": "Ecuador",
        "iata": "LTX",
        "icao": "SELT",
        "latitude": "-0.906833",
        "longitude": "-78.615799",
        "altitude": "9205",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "MRR": {
        "name": "Jose Maria Velasco Ibarra Airport",
        "city": "Macara",
        "country": "Ecuador",
        "iata": "MRR",
        "icao": "SEMA",
        "latitude": "-4.37823009491",
        "longitude": "-79.94100189210002",
        "altitude": "1508",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "XMS": {
        "name": "Coronel E Carvajal Airport",
        "city": "Macas",
        "country": "Ecuador",
        "iata": "XMS",
        "icao": "SEMC",
        "latitude": "-2.2991700172424316",
        "longitude": "-78.12079620361328",
        "altitude": "3452",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "MCH": {
        "name": "General Manuel Serrano Airport",
        "city": "Machala",
        "country": "Ecuador",
        "iata": "MCH",
        "icao": "SEMH",
        "latitude": "-3.2689",
        "longitude": "-79.961601",
        "altitude": "11",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "MEC": {
        "name": "Eloy Alfaro International Airport",
        "city": "Manta",
        "country": "Ecuador",
        "iata": "MEC",
        "icao": "SEMT",
        "latitude": "-0.9460780024528503",
        "longitude": "-80.67880249023438",
        "altitude": "48",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "PVO": {
        "name": "Reales Tamarindos Airport",
        "city": "Portoviejo",
        "country": "Ecuador",
        "iata": "PVO",
        "icao": "SEPV",
        "latitude": "-1.0416500568389893",
        "longitude": "-80.47219848632812",
        "altitude": "130",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "UIO": {
        "name": "Mariscal Sucre International Airport",
        "city": "Quito",
        "country": "Ecuador",
        "iata": "UIO",
        "icao": "SEQM",
        "latitude": "-0.129166666667",
        "longitude": "-78.3575",
        "altitude": "7841",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "ETR": {
        "name": "Santa Rosa International Airport",
        "city": "Santa Rosa",
        "country": "Ecuador",
        "iata": "ETR",
        "icao": "SERO",
        "latitude": "-3.441986",
        "longitude": "-79.996957",
        "altitude": "20",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "SNC": {
        "name": "General Ulpiano Paez Airport",
        "city": "Salinas",
        "country": "Ecuador",
        "iata": "SNC",
        "icao": "SESA",
        "latitude": "-2.20499",
        "longitude": "-80.988899",
        "altitude": "18",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "TPC": {
        "name": "Tarapoa Airport",
        "city": "Tarapoa",
        "country": "Ecuador",
        "iata": "TPC",
        "icao": "SETR",
        "latitude": "-0.12295600026845932",
        "longitude": "-76.33779907226562",
        "altitude": "814",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "TUA": {
        "name": "Teniente Coronel Luis a Mantilla Airport",
        "city": "Tulcan",
        "country": "Ecuador",
        "iata": "TUA",
        "icao": "SETU",
        "latitude": "0.8095059990882874",
        "longitude": "-77.70809936523438",
        "altitude": "9649",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "ASU": {
        "name": "Silvio Pettirossi International Airport",
        "city": "Asuncion",
        "country": "Paraguay",
        "iata": "ASU",
        "icao": "SGAS",
        "latitude": "-25.239999771118164",
        "longitude": "-57.52000045776367",
        "altitude": "292",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Asuncion"
    },
    "AYO": {
        "name": "Juan De Ayolas Airport",
        "city": "Ayolas",
        "country": "Paraguay",
        "iata": "AYO",
        "icao": "SGAY",
        "latitude": "-27.370554",
        "longitude": "-56.854064",
        "altitude": "223",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Asuncion"
    },
    "CIO": {
        "name": "Teniente Col Carmelo Peralta Airport",
        "city": "Conception",
        "country": "Paraguay",
        "iata": "CIO",
        "icao": "SGCO",
        "latitude": "-23.442363",
        "longitude": "-57.427253",
        "altitude": "253",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Asuncion"
    },
    "ESG": {
        "name": "Dr. Luis Maria Argaña International Airport",
        "city": "Mariscal Estigarribia",
        "country": "Paraguay",
        "iata": "ESG",
        "icao": "SGME",
        "latitude": "-22.049999237060547",
        "longitude": "-60.619998931884766",
        "altitude": "553",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Asuncion"
    },
    "PIL": {
        "name": "Carlos Miguel Gimenez Airport",
        "city": "Pilar",
        "country": "Paraguay",
        "iata": "PIL",
        "icao": "SGPI",
        "latitude": "-26.881224",
        "longitude": "-58.318026",
        "altitude": "249",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Asuncion"
    },
    "AXM": {
        "name": "El Eden Airport",
        "city": "Armenia",
        "country": "Colombia",
        "iata": "AXM",
        "icao": "SKAR",
        "latitude": "4.45278",
        "longitude": "-75.7664",
        "altitude": "3990",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PUU": {
        "name": "Tres De Mayo Airport",
        "city": "Puerto Asis",
        "country": "Colombia",
        "iata": "PUU",
        "icao": "SKAS",
        "latitude": "0.505228",
        "longitude": "-76.5008",
        "altitude": "815",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "ELB": {
        "name": "Las Flores Airport",
        "city": "El Banco",
        "country": "Colombia",
        "iata": "ELB",
        "icao": "SKBC",
        "latitude": "9.04554",
        "longitude": "-73.9749",
        "altitude": "111",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BGA": {
        "name": "Palonegro Airport",
        "city": "Bucaramanga",
        "country": "Colombia",
        "iata": "BGA",
        "icao": "SKBG",
        "latitude": "7.1265",
        "longitude": "-73.1848",
        "altitude": "3897",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BOG": {
        "name": "El Dorado International Airport",
        "city": "Bogota",
        "country": "Colombia",
        "iata": "BOG",
        "icao": "SKBO",
        "latitude": "4.70159",
        "longitude": "-74.1469",
        "altitude": "8361",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BAQ": {
        "name": "Ernesto Cortissoz International Airport",
        "city": "Barranquilla",
        "country": "Colombia",
        "iata": "BAQ",
        "icao": "SKBQ",
        "latitude": "10.8896",
        "longitude": "-74.7808",
        "altitude": "98",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BSC": {
        "name": "José Celestino Mutis Airport",
        "city": "Bahia Solano",
        "country": "Colombia",
        "iata": "BSC",
        "icao": "SKBS",
        "latitude": "6.20292",
        "longitude": "-77.3947",
        "altitude": "80",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BUN": {
        "name": "Gerardo Tobar López Airport",
        "city": "Buenaventura",
        "country": "Colombia",
        "iata": "BUN",
        "icao": "SKBU",
        "latitude": "3.81963",
        "longitude": "-76.9898",
        "altitude": "48",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "CUC": {
        "name": "Camilo Daza International Airport",
        "city": "Cucuta",
        "country": "Colombia",
        "iata": "CUC",
        "icao": "SKCC",
        "latitude": "7.92757",
        "longitude": "-72.5115",
        "altitude": "1096",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "CTG": {
        "name": "Rafael Nuñez International Airport",
        "city": "Cartagena",
        "country": "Colombia",
        "iata": "CTG",
        "icao": "SKCG",
        "latitude": "10.4424",
        "longitude": "-75.513",
        "altitude": "4",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "CLO": {
        "name": "Alfonso Bonilla Aragon International Airport",
        "city": "Cali",
        "country": "Colombia",
        "iata": "CLO",
        "icao": "SKCL",
        "latitude": "3.54322",
        "longitude": "-76.3816",
        "altitude": "3162",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "TCO": {
        "name": "La Florida Airport",
        "city": "Tumaco",
        "country": "Colombia",
        "iata": "TCO",
        "icao": "SKCO",
        "latitude": "1.81442",
        "longitude": "-78.7492",
        "altitude": "8",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "CZU": {
        "name": "Las Brujas Airport",
        "city": "Corozal",
        "country": "Colombia",
        "iata": "CZU",
        "icao": "SKCZ",
        "latitude": "9.33274",
        "longitude": "-75.2856",
        "altitude": "528",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "EJA": {
        "name": "Yariguíes Airport",
        "city": "Barrancabermeja",
        "country": "Colombia",
        "iata": "EJA",
        "icao": "SKEJ",
        "latitude": "7.02433",
        "longitude": "-73.8068",
        "altitude": "412",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "FLA": {
        "name": "Gustavo Artunduaga Paredes Airport",
        "city": "Florencia",
        "country": "Colombia",
        "iata": "FLA",
        "icao": "SKFL",
        "latitude": "1.58919",
        "longitude": "-75.5644",
        "altitude": "803",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "GIR": {
        "name": "Santiago Vila Airport",
        "city": "Girardot",
        "country": "Colombia",
        "iata": "GIR",
        "icao": "SKGI",
        "latitude": "4.27624",
        "longitude": "-74.7967",
        "altitude": "900",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "GPI": {
        "name": "Juan Casiano Airport",
        "city": "Guapi",
        "country": "Colombia",
        "iata": "GPI",
        "icao": "SKGP",
        "latitude": "2.57013",
        "longitude": "-77.8986",
        "altitude": "164",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "IBE": {
        "name": "Perales Airport",
        "city": "Ibague",
        "country": "Colombia",
        "iata": "IBE",
        "icao": "SKIB",
        "latitude": "4.42161",
        "longitude": "-75.1333",
        "altitude": "2999",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "IPI": {
        "name": "San Luis Airport",
        "city": "Ipiales",
        "country": "Colombia",
        "iata": "IPI",
        "icao": "SKIP",
        "latitude": "0.861925",
        "longitude": "-77.6718",
        "altitude": "9765",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "APO": {
        "name": "Antonio Roldan Betancourt Airport",
        "city": "Carepa",
        "country": "Colombia",
        "iata": "APO",
        "icao": "SKLC",
        "latitude": "7.81196",
        "longitude": "-76.7164",
        "altitude": "46",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MCJ": {
        "name": "Jorge Isaac Airport",
        "city": "La Mina",
        "country": "Colombia",
        "iata": "MCJ",
        "icao": "SKLM",
        "latitude": "11.2325",
        "longitude": "-72.4901",
        "altitude": "281",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "LET": {
        "name": "Alfredo Vásquez Cobo International Airport",
        "city": "Leticia",
        "country": "Colombia",
        "iata": "LET",
        "icao": "SKLT",
        "latitude": "-4.19355",
        "longitude": "-69.9432",
        "altitude": "277",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "EOH": {
        "name": "Enrique Olaya Herrera Airport",
        "city": "Medellin",
        "country": "Colombia",
        "iata": "EOH",
        "icao": "SKMD",
        "latitude": "6.220549",
        "longitude": "-75.590582",
        "altitude": "4949",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MGN": {
        "name": "Baracoa Airport",
        "city": "Magangue",
        "country": "Colombia",
        "iata": "MGN",
        "icao": "SKMG",
        "latitude": "9.28474",
        "longitude": "-74.8461",
        "altitude": "178",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MTR": {
        "name": "Los Garzones Airport",
        "city": "Monteria",
        "country": "Colombia",
        "iata": "MTR",
        "icao": "SKMR",
        "latitude": "8.82374",
        "longitude": "-75.8258",
        "altitude": "36",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MVP": {
        "name": "Fabio Alberto Leon Bentley Airport",
        "city": "Mitu",
        "country": "Colombia",
        "iata": "MVP",
        "icao": "SKMU",
        "latitude": "1.25366",
        "longitude": "-70.2339",
        "altitude": "680",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MZL": {
        "name": "La Nubia Airport",
        "city": "Manizales",
        "country": "Colombia",
        "iata": "MZL",
        "icao": "SKMZ",
        "latitude": "5.0296",
        "longitude": "-75.4647",
        "altitude": "6871",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "NVA": {
        "name": "Benito Salas Airport",
        "city": "Neiva",
        "country": "Colombia",
        "iata": "NVA",
        "icao": "SKNV",
        "latitude": "2.95015",
        "longitude": "-75.294",
        "altitude": "1464",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "OCV": {
        "name": "Aguas Claras Airport",
        "city": "Ocana",
        "country": "Colombia",
        "iata": "OCV",
        "icao": "SKOC",
        "latitude": "8.31506",
        "longitude": "-73.3583",
        "altitude": "3850",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "OTU": {
        "name": "Otu Airport",
        "city": "Otu",
        "country": "Colombia",
        "iata": "OTU",
        "icao": "SKOT",
        "latitude": "7.01037",
        "longitude": "-74.7155",
        "altitude": "2060",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PCR": {
        "name": "German Olano Airport",
        "city": "Puerto Carreno",
        "country": "Colombia",
        "iata": "PCR",
        "icao": "SKPC",
        "latitude": "6.18472",
        "longitude": "-67.4932",
        "altitude": "177",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PEI": {
        "name": "Matecaña International Airport",
        "city": "Pereira",
        "country": "Colombia",
        "iata": "PEI",
        "icao": "SKPE",
        "latitude": "4.81267",
        "longitude": "-75.7395",
        "altitude": "4416",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PTX": {
        "name": "Pitalito Airport",
        "city": "Pitalito",
        "country": "Colombia",
        "iata": "PTX",
        "icao": "SKPI",
        "latitude": "1.85777",
        "longitude": "-76.0857",
        "altitude": "4212",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PPN": {
        "name": "Guillermo León Valencia Airport",
        "city": "Popayan",
        "country": "Colombia",
        "iata": "PPN",
        "icao": "SKPP",
        "latitude": "2.4544",
        "longitude": "-76.6093",
        "altitude": "5687",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PSO": {
        "name": "Antonio Narino Airport",
        "city": "Pasto",
        "country": "Colombia",
        "iata": "PSO",
        "icao": "SKPS",
        "latitude": "1.39625",
        "longitude": "-77.2915",
        "altitude": "5951",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PVA": {
        "name": "El Embrujo Airport",
        "city": "Providencia",
        "country": "Colombia",
        "iata": "PVA",
        "icao": "SKPV",
        "latitude": "13.3569",
        "longitude": "-81.3583",
        "altitude": "10",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MQU": {
        "name": "Mariquita Airport",
        "city": "Mariquita",
        "country": "Colombia",
        "iata": "MQU",
        "icao": "SKQU",
        "latitude": "5.21256",
        "longitude": "-74.8836",
        "altitude": "1531",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "MDE": {
        "name": "Jose Maria Córdova International Airport",
        "city": "Rio Negro",
        "country": "Colombia",
        "iata": "MDE",
        "icao": "SKRG",
        "latitude": "6.16454",
        "longitude": "-75.4231",
        "altitude": "6955",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "RCH": {
        "name": "Almirante Padilla Airport",
        "city": "Rio Hacha",
        "country": "Colombia",
        "iata": "RCH",
        "icao": "SKRH",
        "latitude": "11.5262",
        "longitude": "-72.926",
        "altitude": "43",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "SJE": {
        "name": "Jorge E. Gonzalez Torres Airport",
        "city": "San Jose Del Guaviare",
        "country": "Colombia",
        "iata": "SJE",
        "icao": "SKSJ",
        "latitude": "2.57969",
        "longitude": "-72.6394",
        "altitude": "605",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "SMR": {
        "name": "Simón Bolívar International Airport",
        "city": "Santa Marta",
        "country": "Colombia",
        "iata": "SMR",
        "icao": "SKSM",
        "latitude": "11.1196",
        "longitude": "-74.2306",
        "altitude": "22",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "ADZ": {
        "name": "Gustavo Rojas Pinilla International Airport",
        "city": "San Andres Island",
        "country": "Colombia",
        "iata": "ADZ",
        "icao": "SKSP",
        "latitude": "12.5836",
        "longitude": "-81.7112",
        "altitude": "19",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "SVI": {
        "name": "Eduardo Falla Solano Airport",
        "city": "San Vincente De Caguan",
        "country": "Colombia",
        "iata": "SVI",
        "icao": "SKSV",
        "latitude": "2.15217",
        "longitude": "-74.7663",
        "altitude": "920",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "TME": {
        "name": "Gustavo Vargas Airport",
        "city": "Tame",
        "country": "Colombia",
        "iata": "TME",
        "icao": "SKTM",
        "latitude": "6.45108",
        "longitude": "-71.7603",
        "altitude": "1050",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "AUC": {
        "name": "Santiago Perez Airport",
        "city": "Arauca",
        "country": "Colombia",
        "iata": "AUC",
        "icao": "SKUC",
        "latitude": "7.06888",
        "longitude": "-70.7369",
        "altitude": "420",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "UIB": {
        "name": "El Caraño Airport",
        "city": "Quibdo",
        "country": "Colombia",
        "iata": "UIB",
        "icao": "SKUI",
        "latitude": "5.69076",
        "longitude": "-76.6412",
        "altitude": "204",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "ULQ": {
        "name": "Heriberto Gíl Martínez Airport",
        "city": "Tulua",
        "country": "Colombia",
        "iata": "ULQ",
        "icao": "SKUL",
        "latitude": "4.08836",
        "longitude": "-76.2351",
        "altitude": "3132",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "VUP": {
        "name": "Alfonso López Pumarejo Airport",
        "city": "Valledupar",
        "country": "Colombia",
        "iata": "VUP",
        "icao": "SKVP",
        "latitude": "10.435",
        "longitude": "-73.2495",
        "altitude": "483",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "VVC": {
        "name": "Vanguardia Airport",
        "city": "Villavicencio",
        "country": "Colombia",
        "iata": "VVC",
        "icao": "SKVV",
        "latitude": "4.16787",
        "longitude": "-73.6138",
        "altitude": "1394",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BJO": {
        "name": "Bermejo Airport",
        "city": "Bermejo",
        "country": "Bolivia",
        "iata": "BJO",
        "icao": "SLBJ",
        "latitude": "-22.7733001709",
        "longitude": "-64.31289672850001",
        "altitude": "1249",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "CBB": {
        "name": "Jorge Wilsterman International Airport",
        "city": "Cochabamba",
        "country": "Bolivia",
        "iata": "CBB",
        "icao": "SLCB",
        "latitude": "-17.421100616455078",
        "longitude": "-66.1771011352539",
        "altitude": "8360",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "CCA": {
        "name": "Chimore Airport",
        "city": "Chapacura",
        "country": "Bolivia",
        "iata": "CCA",
        "icao": "SLCH",
        "latitude": "-16.976834",
        "longitude": "-65.145568",
        "altitude": "875",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "CIJ": {
        "name": "Capitán Aníbal Arab Airport",
        "city": "Cobija",
        "country": "Bolivia",
        "iata": "CIJ",
        "icao": "SLCO",
        "latitude": "-11.040399551400002",
        "longitude": "-68.7829971313",
        "altitude": "889",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "LPB": {
        "name": "El Alto International Airport",
        "city": "La Paz",
        "country": "Bolivia",
        "iata": "LPB",
        "icao": "SLLP",
        "latitude": "-16.5132999420166",
        "longitude": "-68.19229888916016",
        "altitude": "13355",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "ORU": {
        "name": "Juan Mendoza Airport",
        "city": "Oruro",
        "country": "Bolivia",
        "iata": "ORU",
        "icao": "SLOR",
        "latitude": "-17.962600708",
        "longitude": "-67.0762023926",
        "altitude": "12152",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "POI": {
        "name": "Capitan Nicolas Rojas Airport",
        "city": "Potosi",
        "country": "Bolivia",
        "iata": "POI",
        "icao": "SLPO",
        "latitude": "-19.5431003571",
        "longitude": "-65.72370147710001",
        "altitude": "12913",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "PSZ": {
        "name": "Capitán Av. Salvador Ogaya G. airport",
        "city": "Puerto Suarez",
        "country": "Bolivia",
        "iata": "PSZ",
        "icao": "SLPS",
        "latitude": "-18.975301",
        "longitude": "-57.820599",
        "altitude": "440",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "SBL": {
        "name": "Santa Ana Del Yacuma Airport",
        "city": "Santa Ana",
        "country": "Bolivia",
        "iata": "SBL",
        "icao": "SLSA",
        "latitude": "-13.762200355500001",
        "longitude": "-65.4352035522",
        "altitude": "472",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "SRE": {
        "name": "Juana Azurduy De Padilla Airport",
        "city": "Sucre",
        "country": "Bolivia",
        "iata": "SRE",
        "icao": "SLSU",
        "latitude": "-19.007099151611328",
        "longitude": "-65.2886962890625",
        "altitude": "9540",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "TJA": {
        "name": "Capitan Oriel Lea Plaza Airport",
        "city": "Tarija",
        "country": "Bolivia",
        "iata": "TJA",
        "icao": "SLTJ",
        "latitude": "-21.5557003021",
        "longitude": "-64.7013015747",
        "altitude": "6079",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "TDD": {
        "name": "Teniente Av. Jorge Henrich Arauz Airport",
        "city": "Trinidad",
        "country": "Bolivia",
        "iata": "TDD",
        "icao": "SLTR",
        "latitude": "-14.8186998367",
        "longitude": "-64.9179992676",
        "altitude": "509",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "VLM": {
        "name": "Teniente Coronel Rafael Pabón Airport",
        "city": "Villa Montes",
        "country": "Bolivia",
        "iata": "VLM",
        "icao": "SLVM",
        "latitude": "-21.255199432399998",
        "longitude": "-63.4056015015",
        "altitude": "1305",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "VVI": {
        "name": "Viru Viru International Airport",
        "city": "Santa Cruz",
        "country": "Bolivia",
        "iata": "VVI",
        "icao": "SLVR",
        "latitude": "-17.6448",
        "longitude": "-63.135399",
        "altitude": "1224",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "BYC": {
        "name": "Yacuiba Airport",
        "city": "Yacuiba",
        "country": "Bolivia",
        "iata": "BYC",
        "icao": "SLYA",
        "latitude": "-21.960899353027344",
        "longitude": "-63.65169906616211",
        "altitude": "2112",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "PBM": {
        "name": "Johan Adolf Pengel International Airport",
        "city": "Zandery",
        "country": "Suriname",
        "iata": "PBM",
        "icao": "SMJP",
        "latitude": "5.4528298377999995",
        "longitude": "-55.1878013611",
        "altitude": "59",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "CAY": {
        "name": "Cayenne-Rochambeau Airport",
        "city": "Cayenne",
        "country": "French Guiana",
        "iata": "CAY",
        "icao": "SOCA",
        "latitude": "4.819809913639999",
        "longitude": "-52.360401153599994",
        "altitude": "26",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Cayenne"
    },
    "OYP": {
        "name": "Saint-Georges-de-l'Oyapock Airport",
        "city": "St.-georges Oyapock",
        "country": "French Guiana",
        "iata": "OYP",
        "icao": "SOOG",
        "latitude": "3.89759993553",
        "longitude": "-51.8041000366",
        "altitude": "46",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Cayenne"
    },
    "AOP": {
        "name": "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
        "city": "Andoas",
        "country": "Peru",
        "iata": "AOP",
        "icao": "SPAS",
        "latitude": "-2.79612994194",
        "longitude": "-76.46659851070001",
        "altitude": "728",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "IBP": {
        "name": "Iberia Airport",
        "city": "Iberia",
        "country": "Peru",
        "iata": "IBP",
        "icao": "SPBR",
        "latitude": "-11.411600112915039",
        "longitude": "-69.48870086669922",
        "altitude": "750",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "PCL": {
        "name": "Cap FAP David Abenzur Rengifo International Airport",
        "city": "Pucallpa",
        "country": "Peru",
        "iata": "PCL",
        "icao": "SPCL",
        "latitude": "-8.37794017791748",
        "longitude": "-74.57430267333984",
        "altitude": "513",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "CHM": {
        "name": "Teniente FAP Jaime A De Montreuil Morales Airport",
        "city": "Chimbote",
        "country": "Peru",
        "iata": "CHM",
        "icao": "SPEO",
        "latitude": "-9.149609565734863",
        "longitude": "-78.5238037109375",
        "altitude": "69",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "CIX": {
        "name": "Capitan FAP Jose A Quinones Gonzales International Airport",
        "city": "Chiclayo",
        "country": "Peru",
        "iata": "CIX",
        "icao": "SPHI",
        "latitude": "-6.787479877471924",
        "longitude": "-79.8281021118164",
        "altitude": "97",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "AYP": {
        "name": "Coronel FAP Alfredo Mendivil Duarte Airport",
        "city": "Ayacucho",
        "country": "Peru",
        "iata": "AYP",
        "icao": "SPHO",
        "latitude": "-13.154800415039062",
        "longitude": "-74.20439910888672",
        "altitude": "8917",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "ANS": {
        "name": "Andahuaylas Airport",
        "city": "Andahuaylas",
        "country": "Peru",
        "iata": "ANS",
        "icao": "SPHY",
        "latitude": "-13.706399917602539",
        "longitude": "-73.35040283203125",
        "altitude": "11300",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "ATA": {
        "name": "Comandante FAP German Arias Graziani Airport",
        "city": "Anta",
        "country": "Peru",
        "iata": "ATA",
        "icao": "SPHZ",
        "latitude": "-9.347439765930176",
        "longitude": "-77.59839630126953",
        "altitude": "9097",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "LIM": {
        "name": "Jorge Chávez International Airport",
        "city": "Lima",
        "country": "Peru",
        "iata": "LIM",
        "icao": "SPIM",
        "latitude": "-12.0219",
        "longitude": "-77.114305",
        "altitude": "113",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "JJI": {
        "name": "Juanjui Airport",
        "city": "Juanjui",
        "country": "Peru",
        "iata": "JJI",
        "icao": "SPJI",
        "latitude": "-7.169099807739258",
        "longitude": "-76.72859954833984",
        "altitude": "1148",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "JAU": {
        "name": "Francisco Carle Airport",
        "city": "Jauja",
        "country": "Peru",
        "iata": "JAU",
        "icao": "SPJJ",
        "latitude": "-11.7831001282",
        "longitude": "-75.47339630130001",
        "altitude": "11034",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "JUL": {
        "name": "Inca Manco Capac International Airport",
        "city": "Juliaca",
        "country": "Peru",
        "iata": "JUL",
        "icao": "SPJL",
        "latitude": "-15.467100143432617",
        "longitude": "-70.158203125",
        "altitude": "12552",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "ILQ": {
        "name": "Ilo Airport",
        "city": "Ilo",
        "country": "Peru",
        "iata": "ILQ",
        "icao": "SPLO",
        "latitude": "-17.69499969482422",
        "longitude": "-71.34400177001953",
        "altitude": "72",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "TBP": {
        "name": "Capitan FAP Pedro Canga Rodriguez Airport",
        "city": "Tumbes",
        "country": "Peru",
        "iata": "TBP",
        "icao": "SPME",
        "latitude": "-3.55253005027771",
        "longitude": "-80.38140106201172",
        "altitude": "115",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "YMS": {
        "name": "Moises Benzaquen Rengifo Airport",
        "city": "Yurimaguas",
        "country": "Peru",
        "iata": "YMS",
        "icao": "SPMS",
        "latitude": "-5.893770217895508",
        "longitude": "-76.11820220947266",
        "altitude": "587",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "CHH": {
        "name": "Chachapoyas Airport",
        "city": "Chachapoyas",
        "country": "Peru",
        "iata": "CHH",
        "icao": "SPPY",
        "latitude": "-6.201809883117676",
        "longitude": "-77.8561019897461",
        "altitude": "8333",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "IQT": {
        "name": "Coronel FAP Francisco Secada Vignetta International Airport",
        "city": "Iquitos",
        "country": "Peru",
        "iata": "IQT",
        "icao": "SPQT",
        "latitude": "-3.7847399711608887",
        "longitude": "-73.30879974365234",
        "altitude": "306",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "AQP": {
        "name": "Rodríguez Ballón International Airport",
        "city": "Arequipa",
        "country": "Peru",
        "iata": "AQP",
        "icao": "SPQU",
        "latitude": "-16.3411006927",
        "longitude": "-71.5830993652",
        "altitude": "8405",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "TRU": {
        "name": "Capitan FAP Carlos Martinez De Pinillos International Airport",
        "city": "Trujillo",
        "country": "Peru",
        "iata": "TRU",
        "icao": "SPRU",
        "latitude": "-8.08141040802002",
        "longitude": "-79.10880279541016",
        "altitude": "106",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "PIO": {
        "name": "Capitán FAP Renán Elías Olivera International Airport",
        "city": "Pisco",
        "country": "Peru",
        "iata": "PIO",
        "icao": "SPSO",
        "latitude": "-13.74489974975586",
        "longitude": "-76.22029876708984",
        "altitude": "39",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "TPP": {
        "name": "Cadete FAP Guillermo Del Castillo Paredes Airport",
        "city": "Tarapoto",
        "country": "Peru",
        "iata": "TPP",
        "icao": "SPST",
        "latitude": "-6.508739948272705",
        "longitude": "-76.37319946289062",
        "altitude": "869",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "TCQ": {
        "name": "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
        "city": "Tacna",
        "country": "Peru",
        "iata": "TCQ",
        "icao": "SPTN",
        "latitude": "-18.053300857500002",
        "longitude": "-70.2758026123",
        "altitude": "1538",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "PEM": {
        "name": "Padre Aldamiz International Airport",
        "city": "Puerto Maldonado",
        "country": "Peru",
        "iata": "PEM",
        "icao": "SPTU",
        "latitude": "-12.6135997772",
        "longitude": "-69.2285995483",
        "altitude": "659",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "PIU": {
        "name": "Capitán FAP Guillermo Concha Iberico International Airport",
        "city": "Piura",
        "country": "Peru",
        "iata": "PIU",
        "icao": "SPUR",
        "latitude": "-5.20574998856",
        "longitude": "-80.61640167239999",
        "altitude": "120",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "TYL": {
        "name": "Capitan Montes Airport",
        "city": "Talara",
        "country": "Peru",
        "iata": "TYL",
        "icao": "SPYL",
        "latitude": "-4.5766401290894",
        "longitude": "-81.254096984863",
        "altitude": "282",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "CUZ": {
        "name": "Alejandro Velasco Astete International Airport",
        "city": "Cuzco",
        "country": "Peru",
        "iata": "CUZ",
        "icao": "SPZO",
        "latitude": "-13.535699844400002",
        "longitude": "-71.9387969971",
        "altitude": "10860",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "DZO": {
        "name": "Santa Bernardina International Airport",
        "city": "Durazno",
        "country": "Uruguay",
        "iata": "DZO",
        "icao": "SUDU",
        "latitude": "-33.3588981628418",
        "longitude": "-56.49919891357422",
        "altitude": "305",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Montevideo"
    },
    "MVD": {
        "name": "Carrasco International /General C L Berisso Airport",
        "city": "Montevideo",
        "country": "Uruguay",
        "iata": "MVD",
        "icao": "SUMU",
        "latitude": "-34.838402",
        "longitude": "-56.0308",
        "altitude": "105",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Montevideo"
    },
    "STY": {
        "name": "Nueva Hesperides International Airport",
        "city": "Salto",
        "country": "Uruguay",
        "iata": "STY",
        "icao": "SUSO",
        "latitude": "-31.438499450683594",
        "longitude": "-57.98529815673828",
        "altitude": "187",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Montevideo"
    },
    "AGV": {
        "name": "Oswaldo Guevara Mujica Airport",
        "city": "Acarigua",
        "country": "Venezuela",
        "iata": "AGV",
        "icao": "SVAC",
        "latitude": "9.553375244140625",
        "longitude": "-69.23786926269531",
        "altitude": "640",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "AAO": {
        "name": "Anaco Airport",
        "city": "Anaco",
        "country": "Venezuela",
        "iata": "AAO",
        "icao": "SVAN",
        "latitude": "9.430225372314453",
        "longitude": "-64.4707260131836",
        "altitude": "721",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "BLA": {
        "name": "General José Antonio Anzoategui International Airport",
        "city": "Barcelona",
        "country": "Venezuela",
        "iata": "BLA",
        "icao": "SVBC",
        "latitude": "10.111111",
        "longitude": "-64.692222",
        "altitude": "30",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "BNS": {
        "name": "Barinas Airport",
        "city": "Barinas",
        "country": "Venezuela",
        "iata": "BNS",
        "icao": "SVBI",
        "latitude": "8.615",
        "longitude": "-70.21416667",
        "altitude": "615",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "BRM": {
        "name": "Barquisimeto International Airport",
        "city": "Barquisimeto",
        "country": "Venezuela",
        "iata": "BRM",
        "icao": "SVBM",
        "latitude": "10.042746543884277",
        "longitude": "-69.3586196899414",
        "altitude": "2042",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CBL": {
        "name": "Aeropuerto \"General Tomas de Heres\". Ciudad Bolivar",
        "city": "Ciudad Bolivar",
        "country": "Venezuela",
        "iata": "CBL",
        "icao": "SVCB",
        "latitude": "8.12216091156",
        "longitude": "-63.5369567871",
        "altitude": "197",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CXA": {
        "name": "Caicara del Orinoco Airport",
        "city": "Caicara De Orinoco",
        "country": "Venezuela",
        "iata": "CXA",
        "icao": "SVCD",
        "latitude": "7.625510215759277",
        "longitude": "-66.16280364990234",
        "altitude": "141",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CLZ": {
        "name": "Calabozo Airport",
        "city": "Calabozo",
        "country": "Venezuela",
        "iata": "CLZ",
        "icao": "SVCL",
        "latitude": "8.92465591430664",
        "longitude": "-67.4170913696289",
        "altitude": "328",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CAJ": {
        "name": "Canaima Airport",
        "city": "Canaima",
        "country": "Venezuela",
        "iata": "CAJ",
        "icao": "SVCN",
        "latitude": "6.231988906860352",
        "longitude": "-62.85443115234375",
        "altitude": "1450",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "VCR": {
        "name": "Carora Airport",
        "city": "Carora",
        "country": "Venezuela",
        "iata": "VCR",
        "icao": "SVCO",
        "latitude": "10.175602912902832",
        "longitude": "-70.06521606445312",
        "altitude": "1437",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CUP": {
        "name": "General Francisco Bermúdez Airport",
        "city": "Carupano",
        "country": "Venezuela",
        "iata": "CUP",
        "icao": "SVCP",
        "latitude": "10.660014152526855",
        "longitude": "-63.261680603027344",
        "altitude": "33",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CZE": {
        "name": "José Leonardo Chirinos Airport",
        "city": "Coro",
        "country": "Venezuela",
        "iata": "CZE",
        "icao": "SVCR",
        "latitude": "11.41494369506836",
        "longitude": "-69.68090057373047",
        "altitude": "52",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CUM": {
        "name": "Cumaná (Antonio José de Sucre) Airport",
        "city": "Cumana",
        "country": "Venezuela",
        "iata": "CUM",
        "icao": "SVCU",
        "latitude": "10.450332641601562",
        "longitude": "-64.1304702758789",
        "altitude": "14",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "EOR": {
        "name": "El Dorado Airport",
        "city": "El Dorado",
        "country": "Venezuela",
        "iata": "EOR",
        "icao": "SVED",
        "latitude": "6.733333110809326",
        "longitude": "-61.58333206176758",
        "altitude": "318",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "EOZ": {
        "name": "Elorza Airport",
        "city": "Elorza",
        "country": "Venezuela",
        "iata": "EOZ",
        "icao": "SVEZ",
        "latitude": "7.0833330154418945",
        "longitude": "-69.53333282470703",
        "altitude": "295",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "GDO": {
        "name": "Guasdalito Airport",
        "city": "Guasdualito",
        "country": "Venezuela",
        "iata": "GDO",
        "icao": "SVGD",
        "latitude": "7.233333110809326",
        "longitude": "-70.80000305175781",
        "altitude": "426",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "GUI": {
        "name": "Guiria Airport",
        "city": "Guiria",
        "country": "Venezuela",
        "iata": "GUI",
        "icao": "SVGI",
        "latitude": "10.574077606200001",
        "longitude": "-62.3126678467",
        "altitude": "42",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "GUQ": {
        "name": "Guanare Airport",
        "city": "Guanare",
        "country": "Venezuela",
        "iata": "GUQ",
        "icao": "SVGU",
        "latitude": "9.026944160461426",
        "longitude": "-69.7551498413086",
        "altitude": "606",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "HGE": {
        "name": "Higuerote Airport",
        "city": "Higuerote",
        "country": "Venezuela",
        "iata": "HGE",
        "icao": "SVHG",
        "latitude": "10.462474",
        "longitude": "-66.092779",
        "altitude": "12",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "ICC": {
        "name": "Andrés Miguel Salazar Marcano Airport",
        "city": "Isla De Coche",
        "country": "Venezuela",
        "iata": "ICC",
        "icao": "SVIE",
        "latitude": "10.794432",
        "longitude": "-63.98159",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "LSP": {
        "name": "Josefa Camejo International Airport",
        "city": "Paraguana",
        "country": "Venezuela",
        "iata": "LSP",
        "icao": "SVJC",
        "latitude": "11.78077507019043",
        "longitude": "-70.15149688720703",
        "altitude": "75",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "LFR": {
        "name": "La Fria Airport",
        "city": "La Fria",
        "country": "Venezuela",
        "iata": "LFR",
        "icao": "SVLF",
        "latitude": "8.239167213439941",
        "longitude": "-72.27102661132812",
        "altitude": "305",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "MAR": {
        "name": "La Chinita International Airport",
        "city": "Maracaibo",
        "country": "Venezuela",
        "iata": "MAR",
        "icao": "SVMC",
        "latitude": "10.5582084656",
        "longitude": "-71.7278594971",
        "altitude": "239",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "MRD": {
        "name": "Alberto Carnevalli Airport",
        "city": "Merida",
        "country": "Venezuela",
        "iata": "MRD",
        "icao": "SVMD",
        "latitude": "8.582078",
        "longitude": "-71.161041",
        "altitude": "5007",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "PMV": {
        "name": "Del Caribe Santiago Mariño International Airport",
        "city": "Porlamar",
        "country": "Venezuela",
        "iata": "PMV",
        "icao": "SVMG",
        "latitude": "10.912603378295898",
        "longitude": "-63.96659851074219",
        "altitude": "74",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "CCS": {
        "name": "Simón Bolívar International Airport",
        "city": "Caracas",
        "country": "Venezuela",
        "iata": "CCS",
        "icao": "SVMI",
        "latitude": "10.601194",
        "longitude": "-66.991222",
        "altitude": "234",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "MUN": {
        "name": "Maturín Airport",
        "city": "Maturin",
        "country": "Venezuela",
        "iata": "MUN",
        "icao": "SVMT",
        "latitude": "9.75452995300293",
        "longitude": "-63.14739990234375",
        "altitude": "224",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "PYH": {
        "name": "Cacique Aramare Airport",
        "city": "Puerto Ayacucho",
        "country": "Venezuela",
        "iata": "PYH",
        "icao": "SVPA",
        "latitude": "5.6199898719788",
        "longitude": "-67.606101989746",
        "altitude": "245",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "PBL": {
        "name": "General Bartolome Salom International Airport",
        "city": "Puerto Cabello",
        "country": "Venezuela",
        "iata": "PBL",
        "icao": "SVPC",
        "latitude": "10.480500221252441",
        "longitude": "-68.072998046875",
        "altitude": "32",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SCI": {
        "name": "Paramillo Airport",
        "city": "San Cristobal",
        "country": "Venezuela",
        "iata": "SCI",
        "icao": "SVPM",
        "latitude": "7.8013200759887695",
        "longitude": "-72.2029037475586",
        "altitude": "3314",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "PZO": {
        "name": "General Manuel Carlos Piar International Airport",
        "city": "Guayana",
        "country": "Venezuela",
        "iata": "PZO",
        "icao": "SVPR",
        "latitude": "8.288530349731445",
        "longitude": "-62.760398864746094",
        "altitude": "472",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "PTM": {
        "name": "Palmarito Airport",
        "city": "Palmarito",
        "country": "Venezuela",
        "iata": "PTM",
        "icao": "SVPT",
        "latitude": "7.566669940948486",
        "longitude": "-70.18329620361328",
        "altitude": "347",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SVZ": {
        "name": "San Antonio Del Tachira Airport",
        "city": "San Antonio",
        "country": "Venezuela",
        "iata": "SVZ",
        "icao": "SVSA",
        "latitude": "7.840829849243164",
        "longitude": "-72.439697265625",
        "altitude": "1312",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SBB": {
        "name": "Santa Bárbara de Barinas Airport",
        "city": "Santa Barbara",
        "country": "Venezuela",
        "iata": "SBB",
        "icao": "SVSB",
        "latitude": "7.803514003753662",
        "longitude": "-71.16571807861328",
        "altitude": "590",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SNV": {
        "name": "Santa Elena de Uairen Airport",
        "city": "Santa Ana De Uairen",
        "country": "Venezuela",
        "iata": "SNV",
        "icao": "SVSE",
        "latitude": "4.554999828338623",
        "longitude": "-61.150001525878906",
        "altitude": "2938",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "STD": {
        "name": "Mayor Buenaventura Vivas International Airport",
        "city": "Santo Domingo",
        "country": "Venezuela",
        "iata": "STD",
        "icao": "SVSO",
        "latitude": "7.56538",
        "longitude": "-72.035103",
        "altitude": "1083",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SNF": {
        "name": "Sub Teniente Nestor Arias Airport",
        "city": "San Felipe",
        "country": "Venezuela",
        "iata": "SNF",
        "icao": "SVSP",
        "latitude": "10.2787",
        "longitude": "-68.755203",
        "altitude": "761",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SFD": {
        "name": "San Fernando De Apure Airport",
        "city": "San Fernando De Apure",
        "country": "Venezuela",
        "iata": "SFD",
        "icao": "SVSR",
        "latitude": "7.883319854736328",
        "longitude": "-67.44400024414062",
        "altitude": "154",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "SOM": {
        "name": "San Tomé Airport",
        "city": "San Tome",
        "country": "Venezuela",
        "iata": "SOM",
        "icao": "SVST",
        "latitude": "8.9451465606689",
        "longitude": "-64.151084899902",
        "altitude": "861",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "STB": {
        "name": "Santa Bárbara del Zulia Airport",
        "city": "Santa Barbara",
        "country": "Venezuela",
        "iata": "STB",
        "icao": "SVSZ",
        "latitude": "8.974550247192383",
        "longitude": "-71.94325256347656",
        "altitude": "32",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "TUV": {
        "name": "Tucupita Airport",
        "city": "Tucupita",
        "country": "Venezuela",
        "iata": "TUV",
        "icao": "SVTC",
        "latitude": "9.088994026184082",
        "longitude": "-62.094173431396484",
        "altitude": "16",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "TMO": {
        "name": "Tumeremo Airport",
        "city": "Tumeremo",
        "country": "Venezuela",
        "iata": "TMO",
        "icao": "SVTM",
        "latitude": "7.24938",
        "longitude": "-61.52893",
        "altitude": "345",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "VLN": {
        "name": "Arturo Michelena International Airport",
        "city": "Valencia",
        "country": "Venezuela",
        "iata": "VLN",
        "icao": "SVVA",
        "latitude": "10.14973258972168",
        "longitude": "-67.92839813232422",
        "altitude": "1411",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "VLV": {
        "name": "Dr. Antonio Nicolás Briceño Airport",
        "city": "Valera",
        "country": "Venezuela",
        "iata": "VLV",
        "icao": "SVVL",
        "latitude": "9.34047794342041",
        "longitude": "-70.58406066894531",
        "altitude": "2060",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "VDP": {
        "name": "Valle de La Pascua Airport",
        "city": "Valle De La Pascua",
        "country": "Venezuela",
        "iata": "VDP",
        "icao": "SVVP",
        "latitude": "9.22202777863",
        "longitude": "-65.9935836792",
        "altitude": "410",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "LTM": {
        "name": "Lethem Airport",
        "city": "Lethem",
        "country": "Guyana",
        "iata": "LTM",
        "icao": "SYLT",
        "latitude": "3.37276",
        "longitude": "-59.789398",
        "altitude": "351",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "ANU": {
        "name": "V.C. Bird International Airport",
        "city": "Antigua",
        "country": "Antigua and Barbuda",
        "iata": "ANU",
        "icao": "TAPA",
        "latitude": "17.1367",
        "longitude": "-61.792702",
        "altitude": "62",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Antigua"
    },
    "BGI": {
        "name": "Sir Grantley Adams International Airport",
        "city": "Bridgetown",
        "country": "Barbados",
        "iata": "BGI",
        "icao": "TBPB",
        "latitude": "13.0746002197",
        "longitude": "-59.4925003052",
        "altitude": "169",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Barbados"
    },
    "DCF": {
        "name": "Canefield Airport",
        "city": "Canefield",
        "country": "Dominica",
        "iata": "DCF",
        "icao": "TDCF",
        "latitude": "15.336700439453125",
        "longitude": "-61.3922004699707",
        "altitude": "13",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Dominica"
    },
    "DOM": {
        "name": "Douglas-Charles Airport",
        "city": "Dominica",
        "country": "Dominica",
        "iata": "DOM",
        "icao": "TDPD",
        "latitude": "15.547",
        "longitude": "-61.299999",
        "altitude": "73",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Dominica"
    },
    "FDF": {
        "name": "Martinique Aimé Césaire International Airport",
        "city": "Fort-de-france",
        "country": "Martinique",
        "iata": "FDF",
        "icao": "TFFF",
        "latitude": "14.590999603271484",
        "longitude": "-61.00320053100586",
        "altitude": "16",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Martinique"
    },
    "SFG": {
        "name": "L'Espérance Airport",
        "city": "St. Martin",
        "country": "Guadeloupe",
        "iata": "SFG",
        "icao": "TFFG",
        "latitude": "18.099899291992",
        "longitude": "-63.047199249268",
        "altitude": "7",
        "timezone": "-4",
        "dst": "U",
        "tz_name": null
    },
    "PTP": {
        "name": "Pointe-à-Pitre Le Raizet",
        "city": "Pointe-a-pitre",
        "country": "Guadeloupe",
        "iata": "PTP",
        "icao": "TFFR",
        "latitude": "16.265301",
        "longitude": "-61.531799",
        "altitude": "36",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guadeloupe"
    },
    "GND": {
        "name": "Point Salines International Airport",
        "city": "Point Salines",
        "country": "Grenada",
        "iata": "GND",
        "icao": "TGPY",
        "latitude": "12.004199981689453",
        "longitude": "-61.78620147705078",
        "altitude": "41",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grenada"
    },
    "STT": {
        "name": "Cyril E. King Airport",
        "city": "St. Thomas",
        "country": "Virgin Islands",
        "iata": "STT",
        "icao": "TIST",
        "latitude": "18.337299346923828",
        "longitude": "-64.97339630126953",
        "altitude": "23",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Thomas"
    },
    "STX": {
        "name": "Henry E Rohlsen Airport",
        "city": "St. Croix Island",
        "country": "Virgin Islands",
        "iata": "STX",
        "icao": "TISX",
        "latitude": "17.701900482177734",
        "longitude": "-64.79859924316406",
        "altitude": "74",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Thomas"
    },
    "BQN": {
        "name": "Rafael Hernandez Airport",
        "city": "Aguadilla",
        "country": "Puerto Rico",
        "iata": "BQN",
        "icao": "TJBQ",
        "latitude": "18.49489974975586",
        "longitude": "-67.12940216064453",
        "altitude": "237",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "FAJ": {
        "name": "Diego Jimenez Torres Airport",
        "city": "Fajardo",
        "country": "Puerto Rico",
        "iata": "FAJ",
        "icao": "TJFA",
        "latitude": "18.308900833129883",
        "longitude": "-65.66190338134766",
        "altitude": "64",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "SIG": {
        "name": "Fernando Luis Ribas Dominicci Airport",
        "city": "San Juan",
        "country": "Puerto Rico",
        "iata": "SIG",
        "icao": "TJIG",
        "latitude": "18.45680046081543",
        "longitude": "-66.09809875488281",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "MAZ": {
        "name": "Eugenio Maria De Hostos Airport",
        "city": "Mayaguez",
        "country": "Puerto Rico",
        "iata": "MAZ",
        "icao": "TJMZ",
        "latitude": "18.255699157714844",
        "longitude": "-67.14849853515625",
        "altitude": "28",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "PSE": {
        "name": "Mercedita Airport",
        "city": "Ponce",
        "country": "Puerto Rico",
        "iata": "PSE",
        "icao": "TJPS",
        "latitude": "18.00830078125",
        "longitude": "-66.56300354003906",
        "altitude": "29",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "SJU": {
        "name": "Luis Munoz Marin International Airport",
        "city": "San Juan",
        "country": "Puerto Rico",
        "iata": "SJU",
        "icao": "TJSJ",
        "latitude": "18.4393997192",
        "longitude": "-66.0018005371",
        "altitude": "9",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "SKB": {
        "name": "Robert L. Bradshaw International Airport",
        "city": "Basse Terre",
        "country": "Saint Kitts and Nevis",
        "iata": "SKB",
        "icao": "TKPK",
        "latitude": "17.311199188232422",
        "longitude": "-62.71870040893555",
        "altitude": "170",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Kitts"
    },
    "SLU": {
        "name": "George F. L. Charles Airport",
        "city": "Castries",
        "country": "Saint Lucia",
        "iata": "SLU",
        "icao": "TLPC",
        "latitude": "14.0202",
        "longitude": "-60.992901",
        "altitude": "22",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Lucia"
    },
    "UVF": {
        "name": "Hewanorra International Airport",
        "city": "Hewandorra",
        "country": "Saint Lucia",
        "iata": "UVF",
        "icao": "TLPL",
        "latitude": "13.7332",
        "longitude": "-60.952599",
        "altitude": "14",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Lucia"
    },
    "AUA": {
        "name": "Queen Beatrix International Airport",
        "city": "Oranjestad",
        "country": "Aruba",
        "iata": "AUA",
        "icao": "TNCA",
        "latitude": "12.5014",
        "longitude": "-70.015198",
        "altitude": "60",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Aruba"
    },
    "BON": {
        "name": "Flamingo International Airport",
        "city": "Kralendijk",
        "country": "Netherlands Antilles",
        "iata": "BON",
        "icao": "TNCB",
        "latitude": "12.130999565124512",
        "longitude": "-68.26850128173828",
        "altitude": "20",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Curacao"
    },
    "CUR": {
        "name": "Hato International Airport",
        "city": "Willemstad",
        "country": "Netherlands Antilles",
        "iata": "CUR",
        "icao": "TNCC",
        "latitude": "12.1889",
        "longitude": "-68.959801",
        "altitude": "29",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Curacao"
    },
    "EUX": {
        "name": "F. D. Roosevelt Airport",
        "city": "Oranjestad",
        "country": "Netherlands Antilles",
        "iata": "EUX",
        "icao": "TNCE",
        "latitude": "17.49650001525879",
        "longitude": "-62.979400634765625",
        "altitude": "129",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Curacao"
    },
    "SXM": {
        "name": "Princess Juliana International Airport",
        "city": "Philipsburg",
        "country": "Netherlands Antilles",
        "iata": "SXM",
        "icao": "TNCM",
        "latitude": "18.041000366200002",
        "longitude": "-63.1088981628",
        "altitude": "13",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Curacao"
    },
    "AXA": {
        "name": "Clayton J Lloyd International Airport",
        "city": "The Valley",
        "country": "Anguilla",
        "iata": "AXA",
        "icao": "TQPF",
        "latitude": "18.2048",
        "longitude": "-63.055099",
        "altitude": "127",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Anguilla"
    },
    "TAB": {
        "name": "Tobago-Crown Point Airport",
        "city": "Scarborough",
        "country": "Trinidad and Tobago",
        "iata": "TAB",
        "icao": "TTCP",
        "latitude": "11.149700164794922",
        "longitude": "-60.83219909667969",
        "altitude": "38",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Port_of_Spain"
    },
    "POS": {
        "name": "Piarco International Airport",
        "city": "Port-of-spain",
        "country": "Trinidad and Tobago",
        "iata": "POS",
        "icao": "TTPP",
        "latitude": "10.595399856567383",
        "longitude": "-61.33720016479492",
        "altitude": "58",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Port_of_Spain"
    },
    "EIS": {
        "name": "Terrance B. Lettsome International Airport",
        "city": "Tortola",
        "country": "British Virgin Islands",
        "iata": "EIS",
        "icao": "TUPJ",
        "latitude": "18.444799423217773",
        "longitude": "-64.54299926757812",
        "altitude": "15",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Tortola"
    },
    "CIW": {
        "name": "Canouan Airport",
        "city": "Canouan Island",
        "country": "Saint Vincent and the Grenadines",
        "iata": "CIW",
        "icao": "TVSC",
        "latitude": "12.699",
        "longitude": "-61.3424",
        "altitude": "11",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Vincent"
    },
    "MQS": {
        "name": "Mustique Airport",
        "city": "Mustique",
        "country": "Saint Vincent and the Grenadines",
        "iata": "MQS",
        "icao": "TVSM",
        "latitude": "12.887900352478027",
        "longitude": "-61.180198669433594",
        "altitude": "8",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Vincent"
    },
    "SVD": {
        "name": "Argyle International Airport",
        "city": "Kingstown",
        "country": "Saint Vincent and the Grenadines",
        "iata": "SVD",
        "icao": "TVSA",
        "latitude": "13.156695",
        "longitude": "-61.149945",
        "altitude": "136",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Vincent"
    },
    "ALA": {
        "name": "Almaty Airport",
        "city": "Alma-ata",
        "country": "Kazakhstan",
        "iata": "ALA",
        "icao": "UAAA",
        "latitude": "43.35210037231445",
        "longitude": "77.04049682617188",
        "altitude": "2234",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "BXH": {
        "name": "Balkhash Airport",
        "city": "Balkhash",
        "country": "Kazakhstan",
        "iata": "BXH",
        "icao": "UAAH",
        "latitude": "46.8932991027832",
        "longitude": "75.00499725341797",
        "altitude": "1446",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "TSE": {
        "name": "Astana International Airport",
        "city": "Tselinograd",
        "country": "Kazakhstan",
        "iata": "TSE",
        "icao": "UACC",
        "latitude": "51.02220153808594",
        "longitude": "71.46690368652344",
        "altitude": "1165",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "DMB": {
        "name": "Taraz Airport",
        "city": "Dzhambul",
        "country": "Kazakhstan",
        "iata": "DMB",
        "icao": "UADD",
        "latitude": "42.853599548339844",
        "longitude": "71.30359649658203",
        "altitude": "2184",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "FRU": {
        "name": "Manas International Airport",
        "city": "Bishkek",
        "country": "Kyrgyzstan",
        "iata": "FRU",
        "icao": "UAFM",
        "latitude": "43.0612983704",
        "longitude": "74.4776000977",
        "altitude": "2058",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Bishkek"
    },
    "OSS": {
        "name": "Osh Airport",
        "city": "Osh",
        "country": "Kyrgyzstan",
        "iata": "OSS",
        "icao": "UAFO",
        "latitude": "40.6090011597",
        "longitude": "72.793296814",
        "altitude": "2927",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Bishkek"
    },
    "CIT": {
        "name": "Shymkent Airport",
        "city": "Chimkent",
        "country": "Kazakhstan",
        "iata": "CIT",
        "icao": "UAII",
        "latitude": "42.364200592041016",
        "longitude": "69.47889709472656",
        "altitude": "1385",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "URA": {
        "name": "Uralsk Airport",
        "city": "Uralsk",
        "country": "Kazakhstan",
        "iata": "URA",
        "icao": "UARR",
        "latitude": "51.15079879760742",
        "longitude": "51.54309844970703",
        "altitude": "125",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Oral"
    },
    "PWQ": {
        "name": "Pavlodar Airport",
        "city": "Pavlodar",
        "country": "Kazakhstan",
        "iata": "PWQ",
        "icao": "UASP",
        "latitude": "52.19499969482422",
        "longitude": "77.07389831542969",
        "altitude": "410",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "PLX": {
        "name": "Semipalatinsk Airport",
        "city": "Semiplatinsk",
        "country": "Kazakhstan",
        "iata": "PLX",
        "icao": "UASS",
        "latitude": "50.351295",
        "longitude": "80.234398",
        "altitude": "761",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "AKX": {
        "name": "Aktobe Airport",
        "city": "Aktyubinsk",
        "country": "Kazakhstan",
        "iata": "AKX",
        "icao": "UATT",
        "latitude": "50.2458",
        "longitude": "57.206699",
        "altitude": "738",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Oral"
    },
    "GYD": {
        "name": "Heydar Aliyev International Airport",
        "city": "Baku",
        "country": "Azerbaijan",
        "iata": "GYD",
        "icao": "UBBB",
        "latitude": "40.467498779296875",
        "longitude": "50.04669952392578",
        "altitude": "10",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Baku"
    },
    "YKS": {
        "name": "Yakutsk Airport",
        "city": "Yakutsk",
        "country": "Russia",
        "iata": "YKS",
        "icao": "UEEE",
        "latitude": "62.093299865722656",
        "longitude": "129.77099609375",
        "altitude": "325",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "MJZ": {
        "name": "Mirny Airport",
        "city": "Mirnyj",
        "country": "Russia",
        "iata": "MJZ",
        "icao": "UERR",
        "latitude": "62.534698486328125",
        "longitude": "114.03900146484375",
        "altitude": "1156",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "BQS": {
        "name": "Ignatyevo Airport",
        "city": "Blagoveschensk",
        "country": "Russia",
        "iata": "BQS",
        "icao": "UHBB",
        "latitude": "50.42539978027344",
        "longitude": "127.41200256347656",
        "altitude": "638",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "KHV": {
        "name": "Khabarovsk-Novy Airport",
        "city": "Khabarovsk",
        "country": "Russia",
        "iata": "KHV",
        "icao": "UHHH",
        "latitude": "48.52799987793",
        "longitude": "135.18800354004",
        "altitude": "244",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "PVS": {
        "name": "Provideniya Bay Airport",
        "city": "Provideniya Bay",
        "country": "Russia",
        "iata": "PVS",
        "icao": "UHMD",
        "latitude": "64.37809753417969",
        "longitude": "-173.2429962158203",
        "altitude": "72",
        "timezone": "12",
        "dst": "N",
        "tz_name": "Asia/Anadyr"
    },
    "GDX": {
        "name": "Sokol Airport",
        "city": "Magadan",
        "country": "Russia",
        "iata": "GDX",
        "icao": "UHMM",
        "latitude": "59.9109992980957",
        "longitude": "150.72000122070312",
        "altitude": "574",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "PWE": {
        "name": "Pevek Airport",
        "city": "Pevek",
        "country": "Russia",
        "iata": "PWE",
        "icao": "UHMP",
        "latitude": "69.783302307129",
        "longitude": "170.59700012207",
        "altitude": "11",
        "timezone": "12",
        "dst": "N",
        "tz_name": null
    },
    "PKC": {
        "name": "Yelizovo Airport",
        "city": "Petropavlovsk",
        "country": "Russia",
        "iata": "PKC",
        "icao": "UHPP",
        "latitude": "53.16790008544922",
        "longitude": "158.45399475097656",
        "altitude": "131",
        "timezone": "12",
        "dst": "N",
        "tz_name": "Asia/Anadyr"
    },
    "UUS": {
        "name": "Yuzhno-Sakhalinsk Airport",
        "city": "Yuzhno-sakhalinsk",
        "country": "Russia",
        "iata": "UUS",
        "icao": "UHSS",
        "latitude": "46.88869857788086",
        "longitude": "142.71800231933594",
        "altitude": "59",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "VVO": {
        "name": "Vladivostok International Airport",
        "city": "Vladivostok",
        "country": "Russia",
        "iata": "VVO",
        "icao": "UHWW",
        "latitude": "43.39899826049805",
        "longitude": "132.1479949951172",
        "altitude": "46",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "HTA": {
        "name": "Chita-Kadala Airport",
        "city": "Chita",
        "country": "Russia",
        "iata": "HTA",
        "icao": "UIAA",
        "latitude": "52.026299",
        "longitude": "113.306",
        "altitude": "2272",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "BTK": {
        "name": "Bratsk Airport",
        "city": "Bratsk",
        "country": "Russia",
        "iata": "BTK",
        "icao": "UIBB",
        "latitude": "56.370601654052734",
        "longitude": "101.697998046875",
        "altitude": "1610",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "IKT": {
        "name": "Irkutsk Airport",
        "city": "Irkutsk",
        "country": "Russia",
        "iata": "IKT",
        "icao": "UIII",
        "latitude": "52.268001556396",
        "longitude": "104.38899993896",
        "altitude": "1675",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "UUD": {
        "name": "Ulan-Ude Airport (Mukhino)",
        "city": "Ulan-ude",
        "country": "Russia",
        "iata": "UUD",
        "icao": "UIUU",
        "latitude": "51.80780029296875",
        "longitude": "107.43800354003906",
        "altitude": "1690",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "KBP": {
        "name": "Boryspil International Airport",
        "city": "Kiev",
        "country": "Ukraine",
        "iata": "KBP",
        "icao": "UKBB",
        "latitude": "50.345001220703125",
        "longitude": "30.894699096679688",
        "altitude": "427",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "DOK": {
        "name": "Donetsk International Airport",
        "city": "Donetsk",
        "country": "Ukraine",
        "iata": "DOK",
        "icao": "UKCC",
        "latitude": "48.07360076904297",
        "longitude": "37.73970031738281",
        "altitude": "791",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "DNK": {
        "name": "Dnipropetrovsk International Airport",
        "city": "Dnepropetrovsk",
        "country": "Ukraine",
        "iata": "DNK",
        "icao": "UKDD",
        "latitude": "48.357200622558594",
        "longitude": "35.10060119628906",
        "altitude": "481",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "SIP": {
        "name": "Simferopol International Airport",
        "city": "Simferopol",
        "country": "Ukraine",
        "iata": "SIP",
        "icao": "UKFF",
        "latitude": "45.05220031738281",
        "longitude": "33.975101470947266",
        "altitude": "639",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Simferopol"
    },
    "IEV": {
        "name": "Kiev Zhuliany International Airport",
        "city": "Kiev",
        "country": "Ukraine",
        "iata": "IEV",
        "icao": "UKKK",
        "latitude": "50.40194",
        "longitude": "30.45194",
        "altitude": "587",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "LWO": {
        "name": "Lviv International Airport",
        "city": "Lvov",
        "country": "Ukraine",
        "iata": "LWO",
        "icao": "UKLL",
        "latitude": "49.8125",
        "longitude": "23.956100463867188",
        "altitude": "1071",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "ODS": {
        "name": "Odessa International Airport",
        "city": "Odessa",
        "country": "Ukraine",
        "iata": "ODS",
        "icao": "UKOO",
        "latitude": "46.42679977416992",
        "longitude": "30.67650032043457",
        "altitude": "172",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "LED": {
        "name": "Pulkovo Airport",
        "city": "St. Petersburg",
        "country": "Russia",
        "iata": "LED",
        "icao": "ULLI",
        "latitude": "59.80030059814453",
        "longitude": "30.262500762939453",
        "altitude": "78",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "MMK": {
        "name": "Murmansk Airport",
        "city": "Murmansk",
        "country": "Russia",
        "iata": "MMK",
        "icao": "ULMM",
        "latitude": "68.78170013427734",
        "longitude": "32.75080108642578",
        "altitude": "266",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "GME": {
        "name": "Gomel Airport",
        "city": "Gomel",
        "country": "Belarus",
        "iata": "GME",
        "icao": "UMGG",
        "latitude": "52.527000427246094",
        "longitude": "31.016700744628906",
        "altitude": "472",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "VTB": {
        "name": "Vitebsk Vostochny Airport",
        "city": "Vitebsk",
        "country": "Belarus",
        "iata": "VTB",
        "icao": "UMII",
        "latitude": "55.126499176025",
        "longitude": "30.349599838257",
        "altitude": "682",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "KGD": {
        "name": "Khrabrovo Airport",
        "city": "Kaliningrad",
        "country": "Russia",
        "iata": "KGD",
        "icao": "UMKK",
        "latitude": "54.88999938964844",
        "longitude": "20.592599868774414",
        "altitude": "42",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Europe/Kaliningrad"
    },
    "MHP": {
        "name": "Minsk 1 Airport",
        "city": "Minsk",
        "country": "Belarus",
        "iata": "MHP",
        "icao": "UMMM",
        "latitude": "53.864498138427734",
        "longitude": "27.53969955444336",
        "altitude": "748",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "MSQ": {
        "name": "Minsk National Airport",
        "city": "Minsk 2",
        "country": "Belarus",
        "iata": "MSQ",
        "icao": "UMMS",
        "latitude": "53.882499694824",
        "longitude": "28.030700683594",
        "altitude": "670",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "ABA": {
        "name": "Abakan Airport",
        "city": "Abakan",
        "country": "Russia",
        "iata": "ABA",
        "icao": "UNAA",
        "latitude": "53.7400016784668",
        "longitude": "91.38500213623047",
        "altitude": "831",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "BAX": {
        "name": "Barnaul Airport",
        "city": "Barnaul",
        "country": "Russia",
        "iata": "BAX",
        "icao": "UNBB",
        "latitude": "53.363800048828125",
        "longitude": "83.53849792480469",
        "altitude": "837",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "KEJ": {
        "name": "Kemerovo Airport",
        "city": "Kemorovo",
        "country": "Russia",
        "iata": "KEJ",
        "icao": "UNEE",
        "latitude": "55.27009963989258",
        "longitude": "86.1072006225586",
        "altitude": "863",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "OMS": {
        "name": "Omsk Central Airport",
        "city": "Omsk",
        "country": "Russia",
        "iata": "OMS",
        "icao": "UNOO",
        "latitude": "54.96699905395508",
        "longitude": "73.31050109863281",
        "altitude": "311",
        "timezone": "6",
        "dst": "N",
        "tz_name": "Asia/Omsk"
    },
    "KRR": {
        "name": "Krasnodar Pashkovsky International Airport",
        "city": "Krasnodar",
        "country": "Russia",
        "iata": "KRR",
        "icao": "URKK",
        "latitude": "45.034698486328",
        "longitude": "39.170501708984",
        "altitude": "118",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "MCX": {
        "name": "Uytash Airport",
        "city": "Makhachkala",
        "country": "Russia",
        "iata": "MCX",
        "icao": "URML",
        "latitude": "42.81679916381836",
        "longitude": "47.65230178833008",
        "altitude": "12",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "MRV": {
        "name": "Mineralnyye Vody Airport",
        "city": "Mineralnye Vody",
        "country": "Russia",
        "iata": "MRV",
        "icao": "URMM",
        "latitude": "44.225101470947266",
        "longitude": "43.08190155029297",
        "altitude": "1054",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "STW": {
        "name": "Stavropol Shpakovskoye Airport",
        "city": "Stavropol",
        "country": "Russia",
        "iata": "STW",
        "icao": "URMT",
        "latitude": "45.10919952392578",
        "longitude": "42.11280059814453",
        "altitude": "1486",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ROV": {
        "name": "Platov International Airport",
        "city": "Rostov",
        "country": "Russia",
        "iata": "ROV",
        "icao": "URRP",
        "latitude": "47.493888",
        "longitude": "39.924722",
        "altitude": "213",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "AER": {
        "name": "Sochi International Airport",
        "city": "Sochi",
        "country": "Russia",
        "iata": "AER",
        "icao": "URSS",
        "latitude": "43.449902",
        "longitude": "39.9566",
        "altitude": "89",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ASF": {
        "name": "Astrakhan Airport",
        "city": "Astrakhan",
        "country": "Russia",
        "iata": "ASF",
        "icao": "URWA",
        "latitude": "46.2832984924",
        "longitude": "48.0063018799",
        "altitude": "-65",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Europe/Samara"
    },
    "VOG": {
        "name": "Volgograd International Airport",
        "city": "Volgograd",
        "country": "Russia",
        "iata": "VOG",
        "icao": "URWW",
        "latitude": "48.782501220703125",
        "longitude": "44.34550094604492",
        "altitude": "482",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "CEK": {
        "name": "Chelyabinsk Balandino Airport",
        "city": "Chelyabinsk",
        "country": "Russia",
        "iata": "CEK",
        "icao": "USCC",
        "latitude": "55.305801",
        "longitude": "61.5033",
        "altitude": "769",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "MQF": {
        "name": "Magnitogorsk International Airport",
        "city": "Magnetiogorsk",
        "country": "Russia",
        "iata": "MQF",
        "icao": "USCM",
        "latitude": "53.39310073852539",
        "longitude": "58.755699157714844",
        "altitude": "1430",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "NJC": {
        "name": "Nizhnevartovsk Airport",
        "city": "Nizhnevartovsk",
        "country": "Russia",
        "iata": "NJC",
        "icao": "USNN",
        "latitude": "60.94929885864258",
        "longitude": "76.48359680175781",
        "altitude": "177",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "PEE": {
        "name": "Bolshoye Savino Airport",
        "city": "Perm",
        "country": "Russia",
        "iata": "PEE",
        "icao": "USPP",
        "latitude": "57.914501190186",
        "longitude": "56.021198272705",
        "altitude": "404",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "SGC": {
        "name": "Surgut Airport",
        "city": "Surgut",
        "country": "Russia",
        "iata": "SGC",
        "icao": "USRR",
        "latitude": "61.34370040893555",
        "longitude": "73.40180206298828",
        "altitude": "200",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "SVX": {
        "name": "Koltsovo Airport",
        "city": "Yekaterinburg",
        "country": "Russia",
        "iata": "SVX",
        "icao": "USSS",
        "latitude": "56.743099212646",
        "longitude": "60.802700042725",
        "altitude": "764",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "ASB": {
        "name": "Ashgabat International Airport",
        "city": "Ashkhabad",
        "country": "Turkmenistan",
        "iata": "ASB",
        "icao": "UTAA",
        "latitude": "37.986801",
        "longitude": "58.361",
        "altitude": "692",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Ashgabat"
    },
    "KRW": {
        "name": "Turkmenbashi Airport",
        "city": "Krasnovodsk",
        "country": "Turkmenistan",
        "iata": "KRW",
        "icao": "UTAK",
        "latitude": "40.063301",
        "longitude": "53.007198",
        "altitude": "279",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Ashgabat"
    },
    "CRZ": {
        "name": "Turkmenabat Airport",
        "city": "Chardzhou",
        "country": "Turkmenistan",
        "iata": "CRZ",
        "icao": "UTAV",
        "latitude": "39.08330154418945",
        "longitude": "63.61330032348633",
        "altitude": "630",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Ashgabat"
    },
    "DYU": {
        "name": "Dushanbe Airport",
        "city": "Dushanbe",
        "country": "Tajikistan",
        "iata": "DYU",
        "icao": "UTDD",
        "latitude": "38.543300628699996",
        "longitude": "68.8249969482",
        "altitude": "2575",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Dushanbe"
    },
    "BHK": {
        "name": "Bukhara Airport",
        "city": "Bukhara",
        "country": "Uzbekistan",
        "iata": "BHK",
        "icao": "UTSB",
        "latitude": "39.775001525878906",
        "longitude": "64.4832992553711",
        "altitude": "751",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "SKD": {
        "name": "Samarkand Airport",
        "city": "Samarkand",
        "country": "Uzbekistan",
        "iata": "SKD",
        "icao": "UTSS",
        "latitude": "39.70050048828125",
        "longitude": "66.98380279541016",
        "altitude": "2224",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "TAS": {
        "name": "Tashkent International Airport",
        "city": "Tashkent",
        "country": "Uzbekistan",
        "iata": "TAS",
        "icao": "UTTT",
        "latitude": "41.257900238",
        "longitude": "69.2811965942",
        "altitude": "1417",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "BZK": {
        "name": "Bryansk Airport",
        "city": "Bryansk",
        "country": "Russia",
        "iata": "BZK",
        "icao": "UUBP",
        "latitude": "53.214199",
        "longitude": "34.176399",
        "altitude": "663",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "SVO": {
        "name": "Sheremetyevo International Airport",
        "city": "Moscow",
        "country": "Russia",
        "iata": "SVO",
        "icao": "UUEE",
        "latitude": "55.972599",
        "longitude": "37.4146",
        "altitude": "622",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "KLD": {
        "name": "Migalovo Air Base",
        "city": "Tver",
        "country": "Russia",
        "iata": "KLD",
        "icao": "UUEM",
        "latitude": "56.82469940185547",
        "longitude": "35.7577018737793",
        "altitude": "469",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "VOZ": {
        "name": "Voronezh International Airport",
        "city": "Voronezh",
        "country": "Russia",
        "iata": "VOZ",
        "icao": "UUOO",
        "latitude": "51.81420135498047",
        "longitude": "39.22959899902344",
        "altitude": "514",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "VKO": {
        "name": "Vnukovo International Airport",
        "city": "Moscow",
        "country": "Russia",
        "iata": "VKO",
        "icao": "UUWW",
        "latitude": "55.5914993286",
        "longitude": "37.2615013123",
        "altitude": "685",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "SCW": {
        "name": "Syktyvkar Airport",
        "city": "Syktyvkar",
        "country": "Russia",
        "iata": "SCW",
        "icao": "UUYY",
        "latitude": "61.64699935913086",
        "longitude": "50.84510040283203",
        "altitude": "342",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "KZN": {
        "name": "Kazan International Airport",
        "city": "Kazan",
        "country": "Russia",
        "iata": "KZN",
        "icao": "UWKD",
        "latitude": "55.606201171875",
        "longitude": "49.278701782227",
        "altitude": "411",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "REN": {
        "name": "Orenburg Central Airport",
        "city": "Orenburg",
        "country": "Russia",
        "iata": "REN",
        "icao": "UWOO",
        "latitude": "51.795799255371094",
        "longitude": "55.45669937133789",
        "altitude": "387",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "UFA": {
        "name": "Ufa International Airport",
        "city": "Ufa",
        "country": "Russia",
        "iata": "UFA",
        "icao": "UWUU",
        "latitude": "54.557498931885",
        "longitude": "55.874401092529",
        "altitude": "449",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "KUF": {
        "name": "Kurumoch International Airport",
        "city": "Samara",
        "country": "Russia",
        "iata": "KUF",
        "icao": "UWWW",
        "latitude": "53.504901885986",
        "longitude": "50.16429901123",
        "altitude": "477",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Europe/Samara"
    },
    "AMD": {
        "name": "Sardar Vallabhbhai Patel International Airport",
        "city": "Ahmedabad",
        "country": "India",
        "iata": "AMD",
        "icao": "VAAH",
        "latitude": "23.0771999359",
        "longitude": "72.63469696039999",
        "altitude": "189",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "AKD": {
        "name": "Akola Airport",
        "city": "Akola",
        "country": "India",
        "iata": "AKD",
        "icao": "VAAK",
        "latitude": "20.698999",
        "longitude": "77.058601",
        "altitude": "999",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXU": {
        "name": "Aurangabad Airport",
        "city": "Aurangabad",
        "country": "India",
        "iata": "IXU",
        "icao": "VAAU",
        "latitude": "19.862699508666992",
        "longitude": "75.39810180664062",
        "altitude": "1911",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BOM": {
        "name": "Chhatrapati Shivaji International Airport",
        "city": "Mumbai",
        "country": "India",
        "iata": "BOM",
        "icao": "VABB",
        "latitude": "19.0886993408",
        "longitude": "72.8678970337",
        "altitude": "39",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PAB": {
        "name": "Bilaspur Airport",
        "city": "Bilaspur",
        "country": "India",
        "iata": "PAB",
        "icao": "VABI",
        "latitude": "21.9884",
        "longitude": "82.111",
        "altitude": "899",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BHJ": {
        "name": "Bhuj Airport",
        "city": "Bhuj",
        "country": "India",
        "iata": "BHJ",
        "icao": "VABJ",
        "latitude": "23.2877998352",
        "longitude": "69.6701965332",
        "altitude": "268",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXG": {
        "name": "Belgaum Airport",
        "city": "Belgaum",
        "country": "India",
        "iata": "IXG",
        "icao": "VABM",
        "latitude": "15.859299659700001",
        "longitude": "74.6183013916",
        "altitude": "2487",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BDQ": {
        "name": "Vadodara Airport",
        "city": "Baroda",
        "country": "India",
        "iata": "BDQ",
        "icao": "VABO",
        "latitude": "22.336201",
        "longitude": "73.226303",
        "altitude": "129",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BHO": {
        "name": "Raja Bhoj International Airport",
        "city": "Bhopal",
        "country": "India",
        "iata": "BHO",
        "icao": "VABP",
        "latitude": "23.2875003815",
        "longitude": "77.3374023438",
        "altitude": "1711",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BHU": {
        "name": "Bhavnagar Airport",
        "city": "Bhaunagar",
        "country": "India",
        "iata": "BHU",
        "icao": "VABV",
        "latitude": "21.752199173",
        "longitude": "72.1852035522",
        "altitude": "44",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "NMB": {
        "name": "Daman Airport",
        "city": "Daman",
        "country": "India",
        "iata": "NMB",
        "icao": "VADN",
        "latitude": "20.43440055847168",
        "longitude": "72.84320068359375",
        "altitude": "33",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "GUX": {
        "name": "Guna Airport",
        "city": "Guna",
        "country": "India",
        "iata": "GUX",
        "icao": "VAGN",
        "latitude": "24.654699",
        "longitude": "77.347298",
        "altitude": "1600",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "GOI": {
        "name": "Dabolim Airport",
        "city": "Goa",
        "country": "India",
        "iata": "GOI",
        "icao": "VAGO",
        "latitude": "15.3808002472",
        "longitude": "73.8313980103",
        "altitude": "150",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IDR": {
        "name": "Devi Ahilyabai Holkar Airport",
        "city": "Indore",
        "country": "India",
        "iata": "IDR",
        "icao": "VAID",
        "latitude": "22.7217998505",
        "longitude": "75.8011016846",
        "altitude": "1850",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "JLR": {
        "name": "Jabalpur Airport",
        "city": "Jabalpur",
        "country": "India",
        "iata": "JLR",
        "icao": "VAJB",
        "latitude": "23.177799224853516",
        "longitude": "80.052001953125",
        "altitude": "1624",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "JGA": {
        "name": "Jamnagar Airport",
        "city": "Jamnagar",
        "country": "India",
        "iata": "JGA",
        "icao": "VAJM",
        "latitude": "22.465499877929688",
        "longitude": "70.01260375976562",
        "altitude": "69",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXY": {
        "name": "Kandla Airport",
        "city": "Kandla",
        "country": "India",
        "iata": "IXY",
        "icao": "VAKE",
        "latitude": "23.1127",
        "longitude": "70.100304",
        "altitude": "96",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "HJR": {
        "name": "Khajuraho Airport",
        "city": "Khajuraho",
        "country": "India",
        "iata": "HJR",
        "icao": "VAKJ",
        "latitude": "24.817199707",
        "longitude": "79.91860198970001",
        "altitude": "728",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "KLH": {
        "name": "Kolhapur Airport",
        "city": "Kolhapur",
        "country": "India",
        "iata": "KLH",
        "icao": "VAKP",
        "latitude": "16.6646995544",
        "longitude": "74.2893981934",
        "altitude": "1996",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXK": {
        "name": "Keshod Airport",
        "city": "Keshod",
        "country": "India",
        "iata": "IXK",
        "icao": "VAKS",
        "latitude": "21.317100524902344",
        "longitude": "70.27040100097656",
        "altitude": "167",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "NAG": {
        "name": "Dr. Babasaheb Ambedkar International Airport",
        "city": "Nagpur",
        "country": "India",
        "iata": "NAG",
        "icao": "VANP",
        "latitude": "21.092199325561523",
        "longitude": "79.04720306396484",
        "altitude": "1033",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "ISK": {
        "name": "Nashik Airport",
        "city": "Nasik Road",
        "country": "India",
        "iata": "ISK",
        "icao": "VAOZ",
        "latitude": "20.119101",
        "longitude": "73.912903",
        "altitude": "1900",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PNQ": {
        "name": "Pune Airport",
        "city": "Pune",
        "country": "India",
        "iata": "PNQ",
        "icao": "VAPO",
        "latitude": "18.58209991455078",
        "longitude": "73.9197006225586",
        "altitude": "1942",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PBD": {
        "name": "Porbandar Airport",
        "city": "Porbandar",
        "country": "India",
        "iata": "PBD",
        "icao": "VAPR",
        "latitude": "21.6487007141",
        "longitude": "69.65720367429999",
        "altitude": "23",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "RAJ": {
        "name": "Rajkot Airport",
        "city": "Rajkot",
        "country": "India",
        "iata": "RAJ",
        "icao": "VARK",
        "latitude": "22.3092002869",
        "longitude": "70.77950286869999",
        "altitude": "441",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "RPR": {
        "name": "Raipur Airport",
        "city": "Raipur",
        "country": "India",
        "iata": "RPR",
        "icao": "VARP",
        "latitude": "21.180401",
        "longitude": "81.7388",
        "altitude": "1041",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "SSE": {
        "name": "Solapur Airport",
        "city": "Sholapur",
        "country": "India",
        "iata": "SSE",
        "icao": "VASL",
        "latitude": "17.6280002594",
        "longitude": "75.93479919430001",
        "altitude": "1584",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "STV": {
        "name": "Surat Airport",
        "city": "Surat",
        "country": "India",
        "iata": "STV",
        "icao": "VASU",
        "latitude": "21.1140995026",
        "longitude": "72.7417984009",
        "altitude": "16",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "UDR": {
        "name": "Maharana Pratap Airport",
        "city": "Udaipur",
        "country": "India",
        "iata": "UDR",
        "icao": "VAUD",
        "latitude": "24.617700576799997",
        "longitude": "73.89610290530001",
        "altitude": "1684",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CMB": {
        "name": "Bandaranaike International Colombo Airport",
        "city": "Colombo",
        "country": "Sri Lanka",
        "iata": "CMB",
        "icao": "VCBI",
        "latitude": "7.180759906768799",
        "longitude": "79.88410186767578",
        "altitude": "30",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "ACJ": {
        "name": "Anuradhapura Air Force Base",
        "city": "Anuradhapura",
        "country": "Sri Lanka",
        "iata": "ACJ",
        "icao": "VCCA",
        "latitude": "8.30148983002",
        "longitude": "80.42790222170001",
        "altitude": "324",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "BTC": {
        "name": "Batticaloa Airport",
        "city": "Batticaloa",
        "country": "Sri Lanka",
        "iata": "BTC",
        "icao": "VCCB",
        "latitude": "7.70576",
        "longitude": "81.678802",
        "altitude": "20",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "RML": {
        "name": "Colombo Ratmalana Airport",
        "city": "Colombo",
        "country": "Sri Lanka",
        "iata": "RML",
        "icao": "VCCC",
        "latitude": "6.821990013122559",
        "longitude": "79.88619995117188",
        "altitude": "22",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "ADP": {
        "name": "Ampara Airport",
        "city": "Galoya",
        "country": "Sri Lanka",
        "iata": "ADP",
        "icao": "VCCG",
        "latitude": "7.33776",
        "longitude": "81.62594",
        "altitude": "150",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "JAF": {
        "name": "Kankesanturai Airport",
        "city": "Jaffna",
        "country": "Sri Lanka",
        "iata": "JAF",
        "icao": "VCCJ",
        "latitude": "9.792329788208008",
        "longitude": "80.07009887695312",
        "altitude": "33",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "TRR": {
        "name": "China Bay Airport",
        "city": "Trinciomalee",
        "country": "Sri Lanka",
        "iata": "TRR",
        "icao": "VCCT",
        "latitude": "8.5385103225708",
        "longitude": "81.18190002441406",
        "altitude": "6",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "KZC": {
        "name": "Kampong Chhnang Airport",
        "city": "Kompong Chnang",
        "country": "Cambodia",
        "iata": "KZC",
        "icao": "VDKH",
        "latitude": "12.255200386",
        "longitude": "104.564002991",
        "altitude": "56",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Phnom_Penh"
    },
    "PNH": {
        "name": "Phnom Penh International Airport",
        "city": "Phnom-penh",
        "country": "Cambodia",
        "iata": "PNH",
        "icao": "VDPP",
        "latitude": "11.546600341796875",
        "longitude": "104.84400177001953",
        "altitude": "40",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Phnom_Penh"
    },
    "REP": {
        "name": "Siem Reap International Airport",
        "city": "Siem-reap",
        "country": "Cambodia",
        "iata": "REP",
        "icao": "VDSR",
        "latitude": "13.410699844400002",
        "longitude": "103.81300354",
        "altitude": "60",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Phnom_Penh"
    },
    "TNX": {
        "name": "Stung Treng Airport",
        "city": "Stung Treng",
        "country": "Cambodia",
        "iata": "TNX",
        "icao": "VDST",
        "latitude": "13.531900405883789",
        "longitude": "106.01499938964844",
        "altitude": "203",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Phnom_Penh"
    },
    "IXV": {
        "name": "Along Airport",
        "city": "Along",
        "country": "India",
        "iata": "IXV",
        "icao": "VEAN",
        "latitude": "28.17530059814453",
        "longitude": "94.802001953125",
        "altitude": "900",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXA": {
        "name": "Agartala Airport",
        "city": "Agartala",
        "country": "India",
        "iata": "IXA",
        "icao": "VEAT",
        "latitude": "23.8869991302",
        "longitude": "91.24040222170001",
        "altitude": "46",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "AJL": {
        "name": "Lengpui Airport",
        "city": "Aizwal",
        "country": "India",
        "iata": "AJL",
        "icao": "VELP",
        "latitude": "23.840599060099997",
        "longitude": "92.6196975708",
        "altitude": "1398",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXB": {
        "name": "Bagdogra Airport",
        "city": "Baghdogra",
        "country": "India",
        "iata": "IXB",
        "icao": "VEBD",
        "latitude": "26.68120002746582",
        "longitude": "88.32859802246094",
        "altitude": "412",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BBI": {
        "name": "Biju Patnaik Airport",
        "city": "Bhubaneswar",
        "country": "India",
        "iata": "BBI",
        "icao": "VEBS",
        "latitude": "20.244400024399997",
        "longitude": "85.8178024292",
        "altitude": "138",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CCU": {
        "name": "Netaji Subhash Chandra Bose International Airport",
        "city": "Kolkata",
        "country": "India",
        "iata": "CCU",
        "icao": "VECC",
        "latitude": "22.654699325561523",
        "longitude": "88.44670104980469",
        "altitude": "16",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "COH": {
        "name": "Cooch Behar Airport",
        "city": "Cooch-behar",
        "country": "India",
        "iata": "COH",
        "icao": "VECO",
        "latitude": "26.330499649",
        "longitude": "89.4672012329",
        "altitude": "138",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "DBD": {
        "name": "Dhanbad Airport",
        "city": "Dhanbad",
        "country": "India",
        "iata": "DBD",
        "icao": "VEDB",
        "latitude": "23.833999633789062",
        "longitude": "86.42530059814453",
        "altitude": "847",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "GAY": {
        "name": "Gaya Airport",
        "city": "Gaya",
        "country": "India",
        "iata": "GAY",
        "icao": "VEGY",
        "latitude": "24.744300842285156",
        "longitude": "84.95120239257812",
        "altitude": "380",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IMF": {
        "name": "Imphal Airport",
        "city": "Imphal",
        "country": "India",
        "iata": "IMF",
        "icao": "VEIM",
        "latitude": "24.7600002289",
        "longitude": "93.896697998",
        "altitude": "2540",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXW": {
        "name": "Sonari Airport",
        "city": "Jamshedpur",
        "country": "India",
        "iata": "IXW",
        "icao": "VEJS",
        "latitude": "22.8132",
        "longitude": "86.1688",
        "altitude": "475",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "JRH": {
        "name": "Jorhat Airport",
        "city": "Jorhat",
        "country": "India",
        "iata": "JRH",
        "icao": "VEJT",
        "latitude": "26.7315006256",
        "longitude": "94.1754989624",
        "altitude": "311",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXH": {
        "name": "Kailashahar Airport",
        "city": "Kailashahar",
        "country": "India",
        "iata": "IXH",
        "icao": "VEKR",
        "latitude": "24.30820083618164",
        "longitude": "92.0072021484375",
        "altitude": "79",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXS": {
        "name": "Silchar Airport",
        "city": "Silchar",
        "country": "India",
        "iata": "IXS",
        "icao": "VEKU",
        "latitude": "24.9129009247",
        "longitude": "92.97869873050001",
        "altitude": "352",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXI": {
        "name": "North Lakhimpur Airport",
        "city": "Lilabari",
        "country": "India",
        "iata": "IXI",
        "icao": "VELR",
        "latitude": "27.295499801635742",
        "longitude": "94.09760284423828",
        "altitude": "330",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "DIB": {
        "name": "Dibrugarh Airport",
        "city": "Mohanbari",
        "country": "India",
        "iata": "DIB",
        "icao": "VEMN",
        "latitude": "27.4839000702",
        "longitude": "95.0168991089",
        "altitude": "362",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "MZU": {
        "name": "Muzaffarpur Airport",
        "city": "Mazuffarpur",
        "country": "India",
        "iata": "MZU",
        "icao": "VEMZ",
        "latitude": "26.11910057067871",
        "longitude": "85.3136978149414",
        "altitude": "174",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PAT": {
        "name": "Lok Nayak Jayaprakash Airport",
        "city": "Patina",
        "country": "India",
        "iata": "PAT",
        "icao": "VEPT",
        "latitude": "25.591299057",
        "longitude": "85.0879974365",
        "altitude": "170",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXR": {
        "name": "Birsa Munda Airport",
        "city": "Ranchi",
        "country": "India",
        "iata": "IXR",
        "icao": "VERC",
        "latitude": "23.314300537100003",
        "longitude": "85.3217010498",
        "altitude": "2148",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "RRK": {
        "name": "Rourkela Airport",
        "city": "Rourkela",
        "country": "India",
        "iata": "RRK",
        "icao": "VERK",
        "latitude": "22.256701",
        "longitude": "84.814598",
        "altitude": "659",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "VTZ": {
        "name": "Vishakhapatnam Airport",
        "city": "Vishakhapatnam",
        "country": "India",
        "iata": "VTZ",
        "icao": "VEVZ",
        "latitude": "17.721200943",
        "longitude": "83.2245025635",
        "altitude": "15",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "ZER": {
        "name": "Ziro Airport",
        "city": "Zero",
        "country": "India",
        "iata": "ZER",
        "icao": "VEZO",
        "latitude": "27.588301",
        "longitude": "93.828102",
        "altitude": "5403",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CXB": {
        "name": "Cox's Bazar Airport",
        "city": "Cox's Bazar",
        "country": "Bangladesh",
        "iata": "CXB",
        "icao": "VGCB",
        "latitude": "21.452199935913086",
        "longitude": "91.96389770507812",
        "altitude": "12",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "CGP": {
        "name": "Shah Amanat International Airport",
        "city": "Chittagong",
        "country": "Bangladesh",
        "iata": "CGP",
        "icao": "VGEG",
        "latitude": "22.24959945678711",
        "longitude": "91.81330108642578",
        "altitude": "12",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "IRD": {
        "name": "Ishurdi Airport",
        "city": "Ishurdi",
        "country": "Bangladesh",
        "iata": "IRD",
        "icao": "VGIS",
        "latitude": "24.15250015258789",
        "longitude": "89.04940032958984",
        "altitude": "45",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "JSR": {
        "name": "Jessore Airport",
        "city": "Jessore",
        "country": "Bangladesh",
        "iata": "JSR",
        "icao": "VGJR",
        "latitude": "23.183799743652344",
        "longitude": "89.16079711914062",
        "altitude": "20",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "RJH": {
        "name": "Shah Mokhdum Airport",
        "city": "Rajshahi",
        "country": "Bangladesh",
        "iata": "RJH",
        "icao": "VGRJ",
        "latitude": "24.43720054626465",
        "longitude": "88.61650085449219",
        "altitude": "64",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "SPD": {
        "name": "Saidpur Airport",
        "city": "Saidpur",
        "country": "Bangladesh",
        "iata": "SPD",
        "icao": "VGSD",
        "latitude": "25.759199142456055",
        "longitude": "88.90889739990234",
        "altitude": "125",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "ZYL": {
        "name": "Osmany International Airport",
        "city": "Sylhet Osmani",
        "country": "Bangladesh",
        "iata": "ZYL",
        "icao": "VGSY",
        "latitude": "24.963199615478516",
        "longitude": "91.8667984008789",
        "altitude": "50",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "DAC": {
        "name": "Hazrat Shahjalal International Airport",
        "city": "Dhaka",
        "country": "Bangladesh",
        "iata": "DAC",
        "icao": "VGZR",
        "latitude": "23.843347",
        "longitude": "90.397783",
        "altitude": "30",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "HKG": {
        "name": "Hong Kong International Airport",
        "city": "Hong Kong",
        "country": "Hong Kong",
        "iata": "HKG",
        "icao": "VHHH",
        "latitude": "22.308901",
        "longitude": "113.915001",
        "altitude": "28",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Hong_Kong"
    },
    "AGR": {
        "name": "Agra Airport",
        "city": "Agra",
        "country": "India",
        "iata": "AGR",
        "icao": "VIAG",
        "latitude": "27.155799865722656",
        "longitude": "77.96089935302734",
        "altitude": "551",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXD": {
        "name": "Allahabad Airport",
        "city": "Allahabad",
        "country": "India",
        "iata": "IXD",
        "icao": "VIAL",
        "latitude": "25.440099716186523",
        "longitude": "81.73390197753906",
        "altitude": "322",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "ATQ": {
        "name": "Sri Guru Ram Dass Jee International Airport",
        "city": "Amritsar",
        "country": "India",
        "iata": "ATQ",
        "icao": "VIAR",
        "latitude": "31.7096004486",
        "longitude": "74.7973022461",
        "altitude": "756",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BKB": {
        "name": "Nal Airport",
        "city": "Bikaner",
        "country": "India",
        "iata": "BKB",
        "icao": "VIBK",
        "latitude": "28.070600509643555",
        "longitude": "73.20719909667969",
        "altitude": "750",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "VNS": {
        "name": "Lal Bahadur Shastri Airport",
        "city": "Varanasi",
        "country": "India",
        "iata": "VNS",
        "icao": "VIBN",
        "latitude": "25.4524002075",
        "longitude": "82.8592987061",
        "altitude": "266",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "KUU": {
        "name": "Kullu Manali Airport",
        "city": "Kulu",
        "country": "India",
        "iata": "KUU",
        "icao": "VIBR",
        "latitude": "31.876699447631836",
        "longitude": "77.15440368652344",
        "altitude": "3573",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BUP": {
        "name": "Bhatinda Air Force Station",
        "city": "Bhatinda",
        "country": "India",
        "iata": "BUP",
        "icao": "VIBT",
        "latitude": "30.270099639892578",
        "longitude": "74.75579833984375",
        "altitude": "662",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BEK": {
        "name": "Bareilly Air Force Station",
        "city": "Bareilly",
        "country": "India",
        "iata": "BEK",
        "icao": "VIBY",
        "latitude": "28.4221000671",
        "longitude": "79.45079803470001",
        "altitude": "580",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXC": {
        "name": "Chandigarh Airport",
        "city": "Chandigarh",
        "country": "India",
        "iata": "IXC",
        "icao": "VICG",
        "latitude": "30.673500061035156",
        "longitude": "76.78849792480469",
        "altitude": "1012",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "KNU": {
        "name": "Kanpur Airport",
        "city": "Kanpur",
        "country": "India",
        "iata": "KNU",
        "icao": "VICX",
        "latitude": "26.404301",
        "longitude": "80.410103",
        "altitude": "410",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "DED": {
        "name": "Dehradun Airport",
        "city": "Dehra Dun",
        "country": "India",
        "iata": "DED",
        "icao": "VIDN",
        "latitude": "30.189699",
        "longitude": "78.180298",
        "altitude": "1831",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "DEL": {
        "name": "Indira Gandhi International Airport",
        "city": "Delhi",
        "country": "India",
        "iata": "DEL",
        "icao": "VIDP",
        "latitude": "28.5665",
        "longitude": "77.103104",
        "altitude": "777",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "GWL": {
        "name": "Gwalior Airport",
        "city": "Gwalior",
        "country": "India",
        "iata": "GWL",
        "icao": "VIGR",
        "latitude": "26.29330062866211",
        "longitude": "78.22779846191406",
        "altitude": "617",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "HSS": {
        "name": "Hissar Airport",
        "city": "Hissar",
        "country": "India",
        "iata": "HSS",
        "icao": "VIHR",
        "latitude": "29.179399490356445",
        "longitude": "75.75530242919922",
        "altitude": "700",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "JDH": {
        "name": "Jodhpur Airport",
        "city": "Jodhpur",
        "country": "India",
        "iata": "JDH",
        "icao": "VIJO",
        "latitude": "26.251100540161133",
        "longitude": "73.04889678955078",
        "altitude": "717",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "JAI": {
        "name": "Jaipur International Airport",
        "city": "Jaipur",
        "country": "India",
        "iata": "JAI",
        "icao": "VIJP",
        "latitude": "26.8242",
        "longitude": "75.812202",
        "altitude": "1263",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "JSA": {
        "name": "Jaisalmer Airport",
        "city": "Jaisalmer",
        "country": "India",
        "iata": "JSA",
        "icao": "VIJR",
        "latitude": "26.888700485229492",
        "longitude": "70.86499786376953",
        "altitude": "751",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXJ": {
        "name": "Jammu Airport",
        "city": "Jammu",
        "country": "India",
        "iata": "IXJ",
        "icao": "VIJU",
        "latitude": "32.6890983582",
        "longitude": "74.8374023438",
        "altitude": "1029",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "KTU": {
        "name": "Kota Airport",
        "city": "Kota",
        "country": "India",
        "iata": "KTU",
        "icao": "VIKO",
        "latitude": "25.160200119",
        "longitude": "75.84559631350001",
        "altitude": "896",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "LUH": {
        "name": "Ludhiana Airport",
        "city": "Ludhiaha",
        "country": "India",
        "iata": "LUH",
        "icao": "VILD",
        "latitude": "30.854700088500977",
        "longitude": "75.95259857177734",
        "altitude": "834",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXL": {
        "name": "Leh Kushok Bakula Rimpochee Airport",
        "city": "Leh",
        "country": "India",
        "iata": "IXL",
        "icao": "VILH",
        "latitude": "34.1358985901",
        "longitude": "77.5465011597",
        "altitude": "10682",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "LKO": {
        "name": "Chaudhary Charan Singh International Airport",
        "city": "Lucknow",
        "country": "India",
        "iata": "LKO",
        "icao": "VILK",
        "latitude": "26.7605991364",
        "longitude": "80.8892974854",
        "altitude": "410",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXP": {
        "name": "Pathankot Airport",
        "city": "Pathankot",
        "country": "India",
        "iata": "IXP",
        "icao": "VIPK",
        "latitude": "32.233611",
        "longitude": "75.634444",
        "altitude": "1017",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PGH": {
        "name": "Pantnagar Airport",
        "city": "Nainital",
        "country": "India",
        "iata": "PGH",
        "icao": "VIPT",
        "latitude": "29.03339958190918",
        "longitude": "79.47370147705078",
        "altitude": "769",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "SXR": {
        "name": "Sheikh ul Alam Airport",
        "city": "Srinagar",
        "country": "India",
        "iata": "SXR",
        "icao": "VISR",
        "latitude": "33.987098693847656",
        "longitude": "74.77420043945312",
        "altitude": "5429",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "TNI": {
        "name": "Satna Airport",
        "city": "Satna",
        "country": "India",
        "iata": "TNI",
        "icao": "VIST",
        "latitude": "24.5623",
        "longitude": "80.854897",
        "altitude": "1060",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "LPQ": {
        "name": "Luang Phabang International Airport",
        "city": "Luang Prabang",
        "country": "Laos",
        "iata": "LPQ",
        "icao": "VLLB",
        "latitude": "19.897300720214844",
        "longitude": "102.16100311279297",
        "altitude": "955",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "PKZ": {
        "name": "Pakse International Airport",
        "city": "Pakse",
        "country": "Laos",
        "iata": "PKZ",
        "icao": "VLPS",
        "latitude": "15.132100105285645",
        "longitude": "105.78099822998047",
        "altitude": "351",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "ZVK": {
        "name": "Savannakhet Airport",
        "city": "Savannakhet",
        "country": "Laos",
        "iata": "ZVK",
        "icao": "VLSK",
        "latitude": "16.55660057067871",
        "longitude": "104.76000213623047",
        "altitude": "509",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "NEU": {
        "name": "Sam Neua Airport",
        "city": "Sam Neua",
        "country": "Laos",
        "iata": "NEU",
        "icao": "VLSN",
        "latitude": "20.418399810791016",
        "longitude": "104.06700134277344",
        "altitude": "3281",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "VTE": {
        "name": "Wattay International Airport",
        "city": "Vientiane",
        "country": "Laos",
        "iata": "VTE",
        "icao": "VLVT",
        "latitude": "17.988300323500003",
        "longitude": "102.56300354",
        "altitude": "564",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "MFM": {
        "name": "Macau International Airport",
        "city": "Macau",
        "country": "Macau",
        "iata": "MFM",
        "icao": "VMMC",
        "latitude": "22.149599",
        "longitude": "113.592003",
        "altitude": "20",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Macau"
    },
    "BWA": {
        "name": "Gautam Buddha Airport",
        "city": "Bhairawa",
        "country": "Nepal",
        "iata": "BWA",
        "icao": "VNBW",
        "latitude": "27.505685",
        "longitude": "83.416293",
        "altitude": "358",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "JKR": {
        "name": "Janakpur Airport",
        "city": "Janakpur",
        "country": "Nepal",
        "iata": "JKR",
        "icao": "VNJP",
        "latitude": "26.708799362199997",
        "longitude": "85.9224014282",
        "altitude": "256",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "KTM": {
        "name": "Tribhuvan International Airport",
        "city": "Kathmandu",
        "country": "Nepal",
        "iata": "KTM",
        "icao": "VNKT",
        "latitude": "27.6966",
        "longitude": "85.3591",
        "altitude": "4390",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "PKR": {
        "name": "Pokhara Airport",
        "city": "Pokhara",
        "country": "Nepal",
        "iata": "PKR",
        "icao": "VNPK",
        "latitude": "28.200899124145508",
        "longitude": "83.98210144042969",
        "altitude": "2712",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "SIF": {
        "name": "Simara Airport",
        "city": "Simara",
        "country": "Nepal",
        "iata": "SIF",
        "icao": "VNSI",
        "latitude": "27.159500122070312",
        "longitude": "84.9801025390625",
        "altitude": "450",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "BIR": {
        "name": "Biratnagar Airport",
        "city": "Biratnagar",
        "country": "Nepal",
        "iata": "BIR",
        "icao": "VNVT",
        "latitude": "26.48150062561035",
        "longitude": "87.26399993896484",
        "altitude": "236",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "AGX": {
        "name": "Agatti Airport",
        "city": "Agatti Island",
        "country": "India",
        "iata": "AGX",
        "icao": "VOAT",
        "latitude": "10.823699951171875",
        "longitude": "72.1760025024414",
        "altitude": "14",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BLR": {
        "name": "Kempegowda International Airport",
        "city": "Bangalore",
        "country": "India",
        "iata": "BLR",
        "icao": "VOBL",
        "latitude": "13.1979",
        "longitude": "77.706299",
        "altitude": "3000",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BEP": {
        "name": "Bellary Airport",
        "city": "Bellary",
        "country": "India",
        "iata": "BEP",
        "icao": "VOBI",
        "latitude": "15.162799835205078",
        "longitude": "76.88279724121094",
        "altitude": "30",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "VGA": {
        "name": "Vijayawada Airport",
        "city": "Vijayawada",
        "country": "India",
        "iata": "VGA",
        "icao": "VOBZ",
        "latitude": "16.530399322509766",
        "longitude": "80.79679870605469",
        "altitude": "82",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CJB": {
        "name": "Coimbatore International Airport",
        "city": "Coimbatore",
        "country": "India",
        "iata": "CJB",
        "icao": "VOCB",
        "latitude": "11.029999733",
        "longitude": "77.0434036255",
        "altitude": "1324",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "COK": {
        "name": "Cochin International Airport",
        "city": "Kochi",
        "country": "India",
        "iata": "COK",
        "icao": "VOCI",
        "latitude": "10.152",
        "longitude": "76.401901",
        "altitude": "30",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CCJ": {
        "name": "Calicut International Airport",
        "city": "Calicut",
        "country": "India",
        "iata": "CCJ",
        "icao": "VOCL",
        "latitude": "11.1367998123",
        "longitude": "75.95529937740001",
        "altitude": "342",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CDP": {
        "name": "Kadapa Airport",
        "city": "Cuddapah",
        "country": "India",
        "iata": "CDP",
        "icao": "VOCP",
        "latitude": "14.51",
        "longitude": "78.772778",
        "altitude": "430",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "CBD": {
        "name": "Car Nicobar Air Force Station",
        "city": "Carnicobar",
        "country": "India",
        "iata": "CBD",
        "icao": "VOCX",
        "latitude": "9.152509689331055",
        "longitude": "92.8196029663086",
        "altitude": "5",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BPM": {
        "name": "Begumpet Airport",
        "city": "Hyderabad",
        "country": "India",
        "iata": "BPM",
        "icao": "VOHY",
        "latitude": "17.4531002045",
        "longitude": "78.4675979614",
        "altitude": "1742",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXM": {
        "name": "Madurai Airport",
        "city": "Madurai",
        "country": "India",
        "iata": "IXM",
        "icao": "VOMD",
        "latitude": "9.83450984955",
        "longitude": "78.09339904790001",
        "altitude": "459",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXE": {
        "name": "Mangalore International Airport",
        "city": "Mangalore",
        "country": "India",
        "iata": "IXE",
        "icao": "VOML",
        "latitude": "12.9612998962",
        "longitude": "74.8900985718",
        "altitude": "337",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "MAA": {
        "name": "Chennai International Airport",
        "city": "Madras",
        "country": "India",
        "iata": "MAA",
        "icao": "VOMM",
        "latitude": "12.990005493164062",
        "longitude": "80.16929626464844",
        "altitude": "52",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IXZ": {
        "name": "Vir Savarkar International Airport",
        "city": "Port Blair",
        "country": "India",
        "iata": "IXZ",
        "icao": "VOPB",
        "latitude": "11.641200065612793",
        "longitude": "92.72969818115234",
        "altitude": "14",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PNY": {
        "name": "Pondicherry Airport",
        "city": "Pendicherry",
        "country": "India",
        "iata": "PNY",
        "icao": "VOPC",
        "latitude": "11.968",
        "longitude": "79.812",
        "altitude": "134",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "RJA": {
        "name": "Rajahmundry Airport",
        "city": "Rajahmundry",
        "country": "India",
        "iata": "RJA",
        "icao": "VORY",
        "latitude": "17.1103992462",
        "longitude": "81.81819915770001",
        "altitude": "151",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "SXV": {
        "name": "Salem Airport",
        "city": "Salem",
        "country": "India",
        "iata": "SXV",
        "icao": "VOSM",
        "latitude": "11.78330039978",
        "longitude": "78.06559753418",
        "altitude": "1008",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "TJV": {
        "name": "Tanjore Air Force Base",
        "city": "Tanjore",
        "country": "India",
        "iata": "TJV",
        "icao": "VOTJ",
        "latitude": "10.722399711608887",
        "longitude": "79.10160064697266",
        "altitude": "253",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "TIR": {
        "name": "Tirupati Airport",
        "city": "Tirupeti",
        "country": "India",
        "iata": "TIR",
        "icao": "VOTP",
        "latitude": "13.632499694800002",
        "longitude": "79.543296814",
        "altitude": "350",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "TRZ": {
        "name": "Tiruchirapally Civil Airport Airport",
        "city": "Tiruchirappalli",
        "country": "India",
        "iata": "TRZ",
        "icao": "VOTR",
        "latitude": "10.765399932861328",
        "longitude": "78.70970153808594",
        "altitude": "288",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "TRV": {
        "name": "Trivandrum International Airport",
        "city": "Trivandrum",
        "country": "India",
        "iata": "TRV",
        "icao": "VOTV",
        "latitude": "8.48211956024",
        "longitude": "76.9200973511",
        "altitude": "15",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "PBH": {
        "name": "Paro Airport",
        "city": "Thimphu",
        "country": "Bhutan",
        "iata": "PBH",
        "icao": "VQPR",
        "latitude": "27.403200149499998",
        "longitude": "89.42459869380001",
        "altitude": "7332",
        "timezone": "6",
        "dst": "N",
        "tz_name": "Asia/Thimphu"
    },
    "MLE": {
        "name": "Malé International Airport",
        "city": "Male",
        "country": "Maldives",
        "iata": "MLE",
        "icao": "VRMM",
        "latitude": "4.191830158233643",
        "longitude": "73.52909851074219",
        "altitude": "6",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "DMK": {
        "name": "Don Mueang International Airport",
        "city": "Bangkok",
        "country": "Thailand",
        "iata": "DMK",
        "icao": "VTBD",
        "latitude": "13.9125995636",
        "longitude": "100.607002258",
        "altitude": "9",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "KDT": {
        "name": "Kamphaeng Saen Airport",
        "city": "Nakhon Pathom",
        "country": "Thailand",
        "iata": "KDT",
        "icao": "VTBK",
        "latitude": "14.1020002365",
        "longitude": "99.9171981812",
        "altitude": "30",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "UTP": {
        "name": "U-Tapao International Airport",
        "city": "Pattaya",
        "country": "Thailand",
        "iata": "UTP",
        "icao": "VTBU",
        "latitude": "12.679900169372559",
        "longitude": "101.00499725341797",
        "altitude": "42",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "LPT": {
        "name": "Lampang Airport",
        "city": "Lampang",
        "country": "Thailand",
        "iata": "LPT",
        "icao": "VTCL",
        "latitude": "18.27090072631836",
        "longitude": "99.50420379638672",
        "altitude": "811",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "PRH": {
        "name": "Phrae Airport",
        "city": "Phrae",
        "country": "Thailand",
        "iata": "PRH",
        "icao": "VTCP",
        "latitude": "18.132200241088867",
        "longitude": "100.16500091552734",
        "altitude": "538",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "HHQ": {
        "name": "Hua Hin Airport",
        "city": "Prachuap Khiri Khan",
        "country": "Thailand",
        "iata": "HHQ",
        "icao": "VTPH",
        "latitude": "12.6361999512",
        "longitude": "99.951499939",
        "altitude": "62",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "TKH": {
        "name": "Takhli Airport",
        "city": "Nakhon Sawan",
        "country": "Thailand",
        "iata": "TKH",
        "icao": "VTPI",
        "latitude": "15.277299881",
        "longitude": "100.29599762",
        "altitude": "107",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "PHS": {
        "name": "Phitsanulok Airport",
        "city": "Phitsanulok",
        "country": "Thailand",
        "iata": "PHS",
        "icao": "VTPP",
        "latitude": "16.782899856567383",
        "longitude": "100.27899932861328",
        "altitude": "154",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "NAW": {
        "name": "Narathiwat Airport",
        "city": "Narathiwat",
        "country": "Thailand",
        "iata": "NAW",
        "icao": "VTSC",
        "latitude": "6.5199198722839355",
        "longitude": "101.74299621582031",
        "altitude": "16",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "KBV": {
        "name": "Krabi Airport",
        "city": "Krabi",
        "country": "Thailand",
        "iata": "KBV",
        "icao": "VTSG",
        "latitude": "8.09912014008",
        "longitude": "98.9861984253",
        "altitude": "82",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "SGZ": {
        "name": "Songkhla Airport",
        "city": "Songkhla",
        "country": "Thailand",
        "iata": "SGZ",
        "icao": "VTSH",
        "latitude": "7.186560153961182",
        "longitude": "100.60800170898438",
        "altitude": "12",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "PAN": {
        "name": "Pattani Airport",
        "city": "Pattani",
        "country": "Thailand",
        "iata": "PAN",
        "icao": "VTSK",
        "latitude": "6.785459995269775",
        "longitude": "101.15399932861328",
        "altitude": "8",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "USM": {
        "name": "Samui Airport",
        "city": "Ko Samui",
        "country": "Thailand",
        "iata": "USM",
        "icao": "VTSM",
        "latitude": "9.547789573669998",
        "longitude": "100.06199646",
        "altitude": "64",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "HKT": {
        "name": "Phuket International Airport",
        "city": "Phuket",
        "country": "Thailand",
        "iata": "HKT",
        "icao": "VTSP",
        "latitude": "8.1132",
        "longitude": "98.316902",
        "altitude": "82",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "UNN": {
        "name": "Ranong Airport",
        "city": "Ranong",
        "country": "Thailand",
        "iata": "UNN",
        "icao": "VTSR",
        "latitude": "9.777620315551758",
        "longitude": "98.58550262451172",
        "altitude": "57",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "HDY": {
        "name": "Hat Yai International Airport",
        "city": "Hat Yai",
        "country": "Thailand",
        "iata": "HDY",
        "icao": "VTSS",
        "latitude": "6.93320989609",
        "longitude": "100.392997742",
        "altitude": "90",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "TST": {
        "name": "Trang Airport",
        "city": "Trang",
        "country": "Thailand",
        "iata": "TST",
        "icao": "VTST",
        "latitude": "7.508739948272705",
        "longitude": "99.6166000366211",
        "altitude": "67",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "UTH": {
        "name": "Udon Thani Airport",
        "city": "Udon Thani",
        "country": "Thailand",
        "iata": "UTH",
        "icao": "VTUD",
        "latitude": "17.386400222800003",
        "longitude": "102.788002014",
        "altitude": "579",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "SNO": {
        "name": "Sakon Nakhon Airport",
        "city": "Sakon Nakhon",
        "country": "Thailand",
        "iata": "SNO",
        "icao": "VTUI",
        "latitude": "17.195100784301758",
        "longitude": "104.11900329589844",
        "altitude": "529",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "PXR": {
        "name": "Surin Airport",
        "city": "Surin",
        "country": "Thailand",
        "iata": "PXR",
        "icao": "VTUJ",
        "latitude": "14.868300437927",
        "longitude": "103.49800109863",
        "altitude": "478",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "LOE": {
        "name": "Loei Airport",
        "city": "Loei",
        "country": "Thailand",
        "iata": "LOE",
        "icao": "VTUL",
        "latitude": "17.43910026550293",
        "longitude": "101.72200012207031",
        "altitude": "860",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "DAD": {
        "name": "Da Nang International Airport",
        "city": "Danang",
        "country": "Vietnam",
        "iata": "DAD",
        "icao": "VVDN",
        "latitude": "16.043899536132812",
        "longitude": "108.1989974975586",
        "altitude": "33",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "HAN": {
        "name": "Noi Bai International Airport",
        "city": "Hanoi",
        "country": "Vietnam",
        "iata": "HAN",
        "icao": "VVNB",
        "latitude": "21.221200942993164",
        "longitude": "105.80699920654297",
        "altitude": "39",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "NHA": {
        "name": "Nha Trang Air Base",
        "city": "Nhatrang",
        "country": "Vietnam",
        "iata": "NHA",
        "icao": "VVNT",
        "latitude": "12.2275",
        "longitude": "109.192001",
        "altitude": "20",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "HUI": {
        "name": "Phu Bai Airport",
        "city": "Hue",
        "country": "Vietnam",
        "iata": "HUI",
        "icao": "VVPB",
        "latitude": "16.401500701899998",
        "longitude": "107.70300293",
        "altitude": "48",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "PQC": {
        "name": "Phu Quoc International Airport",
        "city": "Phuquoc",
        "country": "Vietnam",
        "iata": "PQC",
        "icao": "VVPQ",
        "latitude": "10.1698",
        "longitude": "103.9931",
        "altitude": "37",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "SGN": {
        "name": "Tan Son Nhat International Airport",
        "city": "Ho Chi Minh City",
        "country": "Vietnam",
        "iata": "SGN",
        "icao": "VVTS",
        "latitude": "10.8187999725",
        "longitude": "106.652000427",
        "altitude": "33",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VBA": {
        "name": "Ann Airport",
        "city": "Ann",
        "country": "Burma",
        "iata": "VBA",
        "icao": "VYAN",
        "latitude": "19.769199",
        "longitude": "94.0261",
        "altitude": "74",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "NYU": {
        "name": "Bagan Airport",
        "city": "Bagan",
        "country": "Burma",
        "iata": "NYU",
        "icao": "VYBG",
        "latitude": "21.178800582885742",
        "longitude": "94.9301986694336",
        "altitude": "312",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "HEH": {
        "name": "Heho Airport",
        "city": "Heho",
        "country": "Burma",
        "iata": "HEH",
        "icao": "VYHH",
        "latitude": "20.746999740600586",
        "longitude": "96.79199981689453",
        "altitude": "3858",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "HOX": {
        "name": "Hommalinn Airport",
        "city": "Hommalin",
        "country": "Burma",
        "iata": "HOX",
        "icao": "VYHL",
        "latitude": "24.899599075317383",
        "longitude": "94.91400146484375",
        "altitude": "534",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "KET": {
        "name": "Kengtung Airport",
        "city": "Kengtung",
        "country": "Burma",
        "iata": "KET",
        "icao": "VYKG",
        "latitude": "21.301599502563477",
        "longitude": "99.63600158691406",
        "altitude": "2798",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "KYP": {
        "name": "Kyaukpyu Airport",
        "city": "Kyaukpyu",
        "country": "Burma",
        "iata": "KYP",
        "icao": "VYKP",
        "latitude": "19.42639923095703",
        "longitude": "93.53479766845703",
        "altitude": "20",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "LSH": {
        "name": "Lashio Airport",
        "city": "Lashio",
        "country": "Burma",
        "iata": "LSH",
        "icao": "VYLS",
        "latitude": "22.9778995513916",
        "longitude": "97.752197265625",
        "altitude": "2450",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "MDL": {
        "name": "Mandalay International Airport",
        "city": "Mandalay",
        "country": "Burma",
        "iata": "MDL",
        "icao": "VYMD",
        "latitude": "21.702199935913086",
        "longitude": "95.97789764404297",
        "altitude": "300",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "MGZ": {
        "name": "Myeik Airport",
        "city": "Myeik",
        "country": "Burma",
        "iata": "MGZ",
        "icao": "VYME",
        "latitude": "12.439800262451172",
        "longitude": "98.62149810791016",
        "altitude": "75",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "MYT": {
        "name": "Myitkyina Airport",
        "city": "Myitkyina",
        "country": "Burma",
        "iata": "MYT",
        "icao": "VYMK",
        "latitude": "25.38360023498535",
        "longitude": "97.35189819335938",
        "altitude": "475",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "MOE": {
        "name": "Momeik Airport",
        "city": "Momeik",
        "country": "Burma",
        "iata": "MOE",
        "icao": "VYMO",
        "latitude": "23.092500686645508",
        "longitude": "96.64530181884766",
        "altitude": "600",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "MOG": {
        "name": "Mong Hsat Airport",
        "city": "Mong Hsat",
        "country": "Burma",
        "iata": "MOG",
        "icao": "VYMS",
        "latitude": "20.516799926757812",
        "longitude": "99.25679779052734",
        "altitude": "1875",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "NMS": {
        "name": "Namsang Airport",
        "city": "Namsang",
        "country": "Burma",
        "iata": "NMS",
        "icao": "VYNS",
        "latitude": "20.890499114990234",
        "longitude": "97.73590087890625",
        "altitude": "3100",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "PAA": {
        "name": "Hpa-N Airport",
        "city": "Hpa-an",
        "country": "Burma",
        "iata": "PAA",
        "icao": "VYPA",
        "latitude": "16.893699645996094",
        "longitude": "97.67459869384766",
        "altitude": "150",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "PBU": {
        "name": "Putao Airport",
        "city": "Putao",
        "country": "Burma",
        "iata": "PBU",
        "icao": "VYPT",
        "latitude": "27.32990074157715",
        "longitude": "97.42630004882812",
        "altitude": "1500",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "PRU": {
        "name": "Pyay Airport",
        "city": "Pyay",
        "country": "Burma",
        "iata": "PRU",
        "icao": "VYPY",
        "latitude": "18.824499130249023",
        "longitude": "95.26599884033203",
        "altitude": "120",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "AKY": {
        "name": "Sittwe Airport",
        "city": "Sittwe",
        "country": "Burma",
        "iata": "AKY",
        "icao": "VYSW",
        "latitude": "20.132699966430664",
        "longitude": "92.87259674072266",
        "altitude": "27",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "SNW": {
        "name": "Thandwe Airport",
        "city": "Thandwe",
        "country": "Burma",
        "iata": "SNW",
        "icao": "VYTD",
        "latitude": "18.4606990814209",
        "longitude": "94.30010223388672",
        "altitude": "20",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "THL": {
        "name": "Tachileik Airport",
        "city": "Tachilek",
        "country": "Burma",
        "iata": "THL",
        "icao": "VYTL",
        "latitude": "20.483800888061523",
        "longitude": "99.9354019165039",
        "altitude": "1280",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "RGN": {
        "name": "Yangon International Airport",
        "city": "Yangon",
        "country": "Burma",
        "iata": "RGN",
        "icao": "VYYY",
        "latitude": "16.907300949099998",
        "longitude": "96.1332015991",
        "altitude": "109",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "UPG": {
        "name": "Hasanuddin International Airport",
        "city": "Ujung Pandang",
        "country": "Indonesia",
        "iata": "UPG",
        "icao": "WAAA",
        "latitude": "-5.061629772186279",
        "longitude": "119.55400085449219",
        "altitude": "47",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BIK": {
        "name": "Frans Kaisiepo Airport",
        "city": "Biak",
        "country": "Indonesia",
        "iata": "BIK",
        "icao": "WABB",
        "latitude": "-1.190019965171814",
        "longitude": "136.10800170898438",
        "altitude": "46",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "NBX": {
        "name": "Nabire Airport",
        "city": "Nabire",
        "country": "Indonesia",
        "iata": "NBX",
        "icao": "WABI",
        "latitude": "-3.36818",
        "longitude": "135.496002",
        "altitude": "20",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "TIM": {
        "name": "Moses Kilangin Airport",
        "city": "Timika",
        "country": "Indonesia",
        "iata": "TIM",
        "icao": "WABP",
        "latitude": "-4.528279781341553",
        "longitude": "136.88699340820312",
        "altitude": "103",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "DJJ": {
        "name": "Sentani International Airport",
        "city": "Jayapura",
        "country": "Indonesia",
        "iata": "DJJ",
        "icao": "WAJJ",
        "latitude": "-2.5769500733",
        "longitude": "140.5160064698",
        "altitude": "289",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "WMX": {
        "name": "Wamena Airport",
        "city": "Wamena",
        "country": "Indonesia",
        "iata": "WMX",
        "icao": "WAJW",
        "latitude": "-4.10251",
        "longitude": "138.957001",
        "altitude": "5085",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "MKQ": {
        "name": "Mopah Airport",
        "city": "Merauke",
        "country": "Indonesia",
        "iata": "MKQ",
        "icao": "WAKK",
        "latitude": "-8.52029037475586",
        "longitude": "140.41799926757812",
        "altitude": "10",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "GTO": {
        "name": "Jalaluddin Airport",
        "city": "Gorontalo",
        "country": "Indonesia",
        "iata": "GTO",
        "icao": "WAMG",
        "latitude": "0.63711899519",
        "longitude": "122.849998474",
        "altitude": "105",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "PLW": {
        "name": "Mutiara Airport",
        "city": "Palu",
        "country": "Indonesia",
        "iata": "PLW",
        "icao": "WAML",
        "latitude": "-0.9185420274734497",
        "longitude": "119.91000366210938",
        "altitude": "284",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "MDC": {
        "name": "Sam Ratulangi Airport",
        "city": "Manado",
        "country": "Indonesia",
        "iata": "MDC",
        "icao": "WAMM",
        "latitude": "1.5492600202560425",
        "longitude": "124.9260025024414",
        "altitude": "264",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "PSJ": {
        "name": "Kasiguncu Airport",
        "city": "Poso",
        "country": "Indonesia",
        "iata": "PSJ",
        "icao": "WAMP",
        "latitude": "-1.41674995422",
        "longitude": "120.657997131",
        "altitude": "174",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "OTI": {
        "name": "Pitu Airport",
        "city": "Morotai Island",
        "country": "Indonesia",
        "iata": "OTI",
        "icao": "WAMR",
        "latitude": "2.0459899902300003",
        "longitude": "128.324996948",
        "altitude": "49",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "TTE": {
        "name": "Sultan Khairun Babullah Airport",
        "city": "Ternate",
        "country": "Indonesia",
        "iata": "TTE",
        "icao": "WAMT",
        "latitude": "0.831413984298706",
        "longitude": "127.38099670410156",
        "altitude": "49",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "LUW": {
        "name": "Syukuran Aminuddin Amir Airport",
        "city": "Luwuk",
        "country": "Indonesia",
        "iata": "LUW",
        "icao": "WAMW",
        "latitude": "-1.03892",
        "longitude": "122.772003",
        "altitude": "56",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "AMQ": {
        "name": "Pattimura Airport, Ambon",
        "city": "Ambon",
        "country": "Indonesia",
        "iata": "AMQ",
        "icao": "WAPP",
        "latitude": "-3.7102599144",
        "longitude": "128.089004517",
        "altitude": "33",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "FKQ": {
        "name": "Fakfak Airport",
        "city": "Fak Fak",
        "country": "Indonesia",
        "iata": "FKQ",
        "icao": "WASF",
        "latitude": "-2.9201900959014893",
        "longitude": "132.26699829101562",
        "altitude": "462",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "KNG": {
        "name": "Kaimana Airport",
        "city": "Kaimana",
        "country": "Indonesia",
        "iata": "KNG",
        "icao": "WASK",
        "latitude": "-3.6445200443267822",
        "longitude": "133.6959991455078",
        "altitude": "19",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "BXB": {
        "name": "Babo Airport",
        "city": "Babo",
        "country": "Indonesia",
        "iata": "BXB",
        "icao": "WASO",
        "latitude": "-2.5322399139404297",
        "longitude": "133.43899536132812",
        "altitude": "10",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "MKW": {
        "name": "Rendani Airport",
        "city": "Manokwari",
        "country": "Indonesia",
        "iata": "MKW",
        "icao": "WASR",
        "latitude": "-0.8918330073356628",
        "longitude": "134.0489959716797",
        "altitude": "23",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "SOQ": {
        "name": "Dominique Edward Osok Airport",
        "city": "Sorong",
        "country": "Indonesia",
        "iata": "SOQ",
        "icao": "WAXX",
        "latitude": "-0.894",
        "longitude": "131.287",
        "altitude": "10",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "BTU": {
        "name": "Bintulu Airport",
        "city": "Bintulu",
        "country": "Malaysia",
        "iata": "BTU",
        "icao": "WBGB",
        "latitude": "3.12385010719",
        "longitude": "113.019996643",
        "altitude": "74",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "KCH": {
        "name": "Kuching International Airport",
        "city": "Kuching",
        "country": "Malaysia",
        "iata": "KCH",
        "icao": "WBGG",
        "latitude": "1.4846999645233154",
        "longitude": "110.34700012207031",
        "altitude": "89",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LMN": {
        "name": "Limbang Airport",
        "city": "Limbang",
        "country": "Malaysia",
        "iata": "LMN",
        "icao": "WBGJ",
        "latitude": "4.808300018310547",
        "longitude": "115.01000213623047",
        "altitude": "14",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "MUR": {
        "name": "Marudi Airport",
        "city": "Marudi",
        "country": "Malaysia",
        "iata": "MUR",
        "icao": "WBGM",
        "latitude": "4.178979873657227",
        "longitude": "114.3290023803711",
        "altitude": "103",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "MYY": {
        "name": "Miri Airport",
        "city": "Miri",
        "country": "Malaysia",
        "iata": "MYY",
        "icao": "WBGR",
        "latitude": "4.322010040283203",
        "longitude": "113.98699951171875",
        "altitude": "59",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "SBW": {
        "name": "Sibu Airport",
        "city": "Sibu",
        "country": "Malaysia",
        "iata": "SBW",
        "icao": "WBGS",
        "latitude": "2.2616000175476074",
        "longitude": "111.98500061035156",
        "altitude": "122",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LDU": {
        "name": "Lahad Datu Airport",
        "city": "Lahad Datu",
        "country": "Malaysia",
        "iata": "LDU",
        "icao": "WBKD",
        "latitude": "5.032249927520752",
        "longitude": "118.3239974975586",
        "altitude": "45",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "BKI": {
        "name": "Kota Kinabalu International Airport",
        "city": "Kota Kinabalu",
        "country": "Malaysia",
        "iata": "BKI",
        "icao": "WBKK",
        "latitude": "5.9372100830078125",
        "longitude": "116.0510025024414",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LBU": {
        "name": "Labuan Airport",
        "city": "Labuan",
        "country": "Malaysia",
        "iata": "LBU",
        "icao": "WBKL",
        "latitude": "5.300680160522461",
        "longitude": "115.25",
        "altitude": "101",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "TWU": {
        "name": "Tawau Airport",
        "city": "Tawau",
        "country": "Malaysia",
        "iata": "TWU",
        "icao": "WBKW",
        "latitude": "4.320159912109375",
        "longitude": "118.12799835205078",
        "altitude": "57",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "BWN": {
        "name": "Brunei International Airport",
        "city": "Bandar Seri Begawan",
        "country": "Brunei",
        "iata": "BWN",
        "icao": "WBSB",
        "latitude": "4.944200038909912",
        "longitude": "114.9280014038086",
        "altitude": "73",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Brunei"
    },
    "PKU": {
        "name": "Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
        "city": "Pekanbaru",
        "country": "Indonesia",
        "iata": "PKU",
        "icao": "WIBB",
        "latitude": "0.46078601479530334",
        "longitude": "101.44499969482422",
        "altitude": "102",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "DUM": {
        "name": "Pinang Kampai Airport",
        "city": "Dumai",
        "country": "Indonesia",
        "iata": "DUM",
        "icao": "WIBD",
        "latitude": "1.60919",
        "longitude": "101.433998",
        "altitude": "55",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "CGK": {
        "name": "Soekarno-Hatta International Airport",
        "city": "Jakarta",
        "country": "Indonesia",
        "iata": "CGK",
        "icao": "WIII",
        "latitude": "-6.1255698204",
        "longitude": "106.65599823",
        "altitude": "34",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "GNS": {
        "name": "Binaka Airport",
        "city": "Gunung Sitoli",
        "country": "Indonesia",
        "iata": "GNS",
        "icao": "WIMB",
        "latitude": "1.16638",
        "longitude": "97.704697",
        "altitude": "20",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "AEG": {
        "name": "Aek Godang Airport",
        "city": "Padang Sidempuan",
        "country": "Indonesia",
        "iata": "AEG",
        "icao": "WIME",
        "latitude": "1.4001",
        "longitude": "99.430496",
        "altitude": "922",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PDG": {
        "name": "Minangkabau International Airport",
        "city": "Padang",
        "country": "Indonesia",
        "iata": "PDG",
        "icao": "WIPT",
        "latitude": "-0.786917",
        "longitude": "100.280998",
        "altitude": "18",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "MES": {
        "name": "Soewondo Air Force Base",
        "city": "Medan",
        "country": "Indonesia",
        "iata": "MES",
        "icao": "WIMK",
        "latitude": "3.559167",
        "longitude": "98.671111",
        "altitude": "114",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "FLZ": {
        "name": "Dr Ferdinand Lumban Tobing Airport",
        "city": "Sibolga",
        "country": "Indonesia",
        "iata": "FLZ",
        "icao": "WIMS",
        "latitude": "1.55594",
        "longitude": "98.888901",
        "altitude": "33",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "NPO": {
        "name": "Nanga Pinoh Airport",
        "city": "Nangapinoh",
        "country": "Indonesia",
        "iata": "NPO",
        "icao": "WIOG",
        "latitude": "-0.34886899590492",
        "longitude": "111.74800109863",
        "altitude": "123",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "KTG": {
        "name": "Ketapang(Rahadi Usman) Airport",
        "city": "Ketapang",
        "country": "Indonesia",
        "iata": "KTG",
        "icao": "WIOK",
        "latitude": "-1.816640019416809",
        "longitude": "109.96299743652344",
        "altitude": "46",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PNK": {
        "name": "Supadio Airport",
        "city": "Pontianak",
        "country": "Indonesia",
        "iata": "PNK",
        "icao": "WIOO",
        "latitude": "-0.15071099996566772",
        "longitude": "109.40399932861328",
        "altitude": "10",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "DJB": {
        "name": "Sultan Thaha Airport",
        "city": "Jambi",
        "country": "Indonesia",
        "iata": "DJB",
        "icao": "WIPA",
        "latitude": "-1.6380200386047363",
        "longitude": "103.64399719238281",
        "altitude": "82",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "BKS": {
        "name": "Fatmawati Soekarno Airport",
        "city": "Bengkulu",
        "country": "Indonesia",
        "iata": "BKS",
        "icao": "WIPL",
        "latitude": "-3.8637",
        "longitude": "102.338997",
        "altitude": "50",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PLM": {
        "name": "Sultan Mahmud Badaruddin II Airport",
        "city": "Palembang",
        "country": "Indonesia",
        "iata": "PLM",
        "icao": "WIPP",
        "latitude": "-2.8982501029968",
        "longitude": "104.69999694824",
        "altitude": "49",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "RGT": {
        "name": "Japura Airport",
        "city": "Rengat",
        "country": "Indonesia",
        "iata": "RGT",
        "icao": "WIPR",
        "latitude": "-0.35280799865722656",
        "longitude": "102.33499908447266",
        "altitude": "62",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "LSX": {
        "name": "Lhok Sukon Airport",
        "city": "Lhok Sukon",
        "country": "Indonesia",
        "iata": "LSX",
        "icao": "WITL",
        "latitude": "5.069509983062744",
        "longitude": "97.25920104980469",
        "altitude": "28",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "BTJ": {
        "name": "Sultan Iskandar Muda International Airport",
        "city": "Banda Aceh",
        "country": "Indonesia",
        "iata": "BTJ",
        "icao": "WITT",
        "latitude": "5.522872024010001",
        "longitude": "95.42063713070002",
        "altitude": "65",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "AOR": {
        "name": "Sultan Abdul Halim Airport",
        "city": "Alor Setar",
        "country": "Malaysia",
        "iata": "AOR",
        "icao": "WMKA",
        "latitude": "6.189670085906982",
        "longitude": "100.39800262451172",
        "altitude": "15",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "BWH": {
        "name": "Butterworth Airport",
        "city": "Butterworth",
        "country": "Malaysia",
        "iata": "BWH",
        "icao": "WMKB",
        "latitude": "5.4659199714660645",
        "longitude": "100.39099884033203",
        "altitude": "11",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "KBR": {
        "name": "Sultan Ismail Petra Airport",
        "city": "Kota Bahru",
        "country": "Malaysia",
        "iata": "KBR",
        "icao": "WMKC",
        "latitude": "6.1668500900268555",
        "longitude": "102.29299926757812",
        "altitude": "16",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "KUA": {
        "name": "Kuantan Airport",
        "city": "Kuantan",
        "country": "Malaysia",
        "iata": "KUA",
        "icao": "WMKD",
        "latitude": "3.7753899097442627",
        "longitude": "103.20899963378906",
        "altitude": "58",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "KTE": {
        "name": "Kerteh Airport",
        "city": "Kerteh",
        "country": "Malaysia",
        "iata": "KTE",
        "icao": "WMKE",
        "latitude": "4.537220001220703",
        "longitude": "103.427001953125",
        "altitude": "18",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "IPH": {
        "name": "Sultan Azlan Shah Airport",
        "city": "Ipoh",
        "country": "Malaysia",
        "iata": "IPH",
        "icao": "WMKI",
        "latitude": "4.567969799041748",
        "longitude": "101.09200286865234",
        "altitude": "130",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "JHB": {
        "name": "Senai International Airport",
        "city": "Johor Bahru",
        "country": "Malaysia",
        "iata": "JHB",
        "icao": "WMKJ",
        "latitude": "1.64131",
        "longitude": "103.669998",
        "altitude": "135",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "KUL": {
        "name": "Kuala Lumpur International Airport",
        "city": "Kuala Lumpur",
        "country": "Malaysia",
        "iata": "KUL",
        "icao": "WMKK",
        "latitude": "2.745579957962",
        "longitude": "101.70999908447",
        "altitude": "69",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LGK": {
        "name": "Langkawi International Airport",
        "city": "Langkawi",
        "country": "Malaysia",
        "iata": "LGK",
        "icao": "WMKL",
        "latitude": "6.329730033874512",
        "longitude": "99.72869873046875",
        "altitude": "29",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "MKZ": {
        "name": "Malacca Airport",
        "city": "Malacca",
        "country": "Malaysia",
        "iata": "MKZ",
        "icao": "WMKM",
        "latitude": "2.2633600235",
        "longitude": "102.251998901",
        "altitude": "35",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "TGG": {
        "name": "Sultan Mahmud Airport",
        "city": "Kuala Terengganu",
        "country": "Malaysia",
        "iata": "TGG",
        "icao": "WMKN",
        "latitude": "5.3826398849487305",
        "longitude": "103.10299682617188",
        "altitude": "21",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "PEN": {
        "name": "Penang International Airport",
        "city": "Penang",
        "country": "Malaysia",
        "iata": "PEN",
        "icao": "WMKP",
        "latitude": "5.297140121459961",
        "longitude": "100.2770004272461",
        "altitude": "11",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "UAI": {
        "name": "Suai Airport",
        "city": "Suai",
        "country": "East Timor",
        "iata": "UAI",
        "icao": "WPDB",
        "latitude": "-9.30331039428711",
        "longitude": "125.28700256347656",
        "altitude": "96",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Dili"
    },
    "DIL": {
        "name": "Presidente Nicolau Lobato International Airport",
        "city": "Dili",
        "country": "East Timor",
        "iata": "DIL",
        "icao": "WPDL",
        "latitude": "-8.54640007019",
        "longitude": "125.526000977",
        "altitude": "154",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Dili"
    },
    "BCH": {
        "name": "Cakung Airport",
        "city": "Baucau",
        "country": "East Timor",
        "iata": "BCH",
        "icao": "WPEC",
        "latitude": "-8.489029884339999",
        "longitude": "126.401000977",
        "altitude": "1771",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Dili"
    },
    "QPG": {
        "name": "Paya Lebar Air Base",
        "city": "Paya Lebar",
        "country": "Singapore",
        "iata": "QPG",
        "icao": "WSAP",
        "latitude": "1.3604199886322021",
        "longitude": "103.91000366210938",
        "altitude": "65",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Singapore"
    },
    "TGA": {
        "name": "Tengah Air Base",
        "city": "Tengah",
        "country": "Singapore",
        "iata": "TGA",
        "icao": "WSAT",
        "latitude": "1.38725996017",
        "longitude": "103.708999634",
        "altitude": "50",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Singapore"
    },
    "XSP": {
        "name": "Seletar Airport",
        "city": "Singapore",
        "country": "Singapore",
        "iata": "XSP",
        "icao": "WSSL",
        "latitude": "1.416949987411499",
        "longitude": "103.86799621582031",
        "altitude": "36",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Singapore"
    },
    "SIN": {
        "name": "Singapore Changi Airport",
        "city": "Singapore",
        "country": "Singapore",
        "iata": "SIN",
        "icao": "WSSS",
        "latitude": "1.35019",
        "longitude": "103.994003",
        "altitude": "22",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Singapore"
    },
    "ACF": {
        "name": "Brisbane Archerfield Airport",
        "city": "Brisbane",
        "country": "Australia",
        "iata": "ACF",
        "icao": "YBAF",
        "latitude": "-27.5702991486",
        "longitude": "153.007995605",
        "altitude": "63",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "ABM": {
        "name": "Northern Peninsula Airport",
        "city": "Amberley",
        "country": "Australia",
        "iata": "ABM",
        "icao": "YBAM",
        "latitude": "-10.9508",
        "longitude": "142.459",
        "altitude": "34",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "ASP": {
        "name": "Alice Springs Airport",
        "city": "Alice Springs",
        "country": "Australia",
        "iata": "ASP",
        "icao": "YBAS",
        "latitude": "-23.806699752807617",
        "longitude": "133.90199279785156",
        "altitude": "1789",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": "Australia/Darwin"
    },
    "BNE": {
        "name": "Brisbane International Airport",
        "city": "Brisbane",
        "country": "Australia",
        "iata": "BNE",
        "icao": "YBBN",
        "latitude": "-27.384199142456055",
        "longitude": "153.11700439453125",
        "altitude": "13",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "OOL": {
        "name": "Gold Coast Airport",
        "city": "Coolangatta",
        "country": "Australia",
        "iata": "OOL",
        "icao": "YBCG",
        "latitude": "-28.1644001007",
        "longitude": "153.505004883",
        "altitude": "21",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "CNS": {
        "name": "Cairns International Airport",
        "city": "Cairns",
        "country": "Australia",
        "iata": "CNS",
        "icao": "YBCS",
        "latitude": "-16.885799408",
        "longitude": "145.755004883",
        "altitude": "10",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "CTL": {
        "name": "Charleville Airport",
        "city": "Charlieville",
        "country": "Australia",
        "iata": "CTL",
        "icao": "YBCV",
        "latitude": "-26.4132995605",
        "longitude": "146.261993408",
        "altitude": "1003",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "ISA": {
        "name": "Mount Isa Airport",
        "city": "Mount Isa",
        "country": "Australia",
        "iata": "ISA",
        "icao": "YBMA",
        "latitude": "-20.663900375399997",
        "longitude": "139.488998413",
        "altitude": "1121",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MCY": {
        "name": "Sunshine Coast Airport",
        "city": "Maroochydore",
        "country": "Australia",
        "iata": "MCY",
        "icao": "YBMC",
        "latitude": "-26.6033",
        "longitude": "153.091003",
        "altitude": "15",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MKY": {
        "name": "Mackay Airport",
        "city": "Mackay",
        "country": "Australia",
        "iata": "MKY",
        "icao": "YBMK",
        "latitude": "-21.171699523900003",
        "longitude": "149.179992676",
        "altitude": "19",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "PPP": {
        "name": "Proserpine Whitsunday Coast Airport",
        "city": "Prosserpine",
        "country": "Australia",
        "iata": "PPP",
        "icao": "YBPN",
        "latitude": "-20.4950008392",
        "longitude": "148.552001953",
        "altitude": "82",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "ROK": {
        "name": "Rockhampton Airport",
        "city": "Rockhampton",
        "country": "Australia",
        "iata": "ROK",
        "icao": "YBRK",
        "latitude": "-23.3819007874",
        "longitude": "150.475006104",
        "altitude": "34",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "TSV": {
        "name": "Townsville Airport",
        "city": "Townsville",
        "country": "Australia",
        "iata": "TSV",
        "icao": "YBTL",
        "latitude": "-19.252500534057617",
        "longitude": "146.76499938964844",
        "altitude": "18",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "WEI": {
        "name": "Weipa Airport",
        "city": "Weipa",
        "country": "Australia",
        "iata": "WEI",
        "icao": "YBWP",
        "latitude": "-12.6786003113",
        "longitude": "141.925003052",
        "altitude": "63",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "AVV": {
        "name": "Avalon Airport",
        "city": "Avalon",
        "country": "Australia",
        "iata": "AVV",
        "icao": "YMAV",
        "latitude": "-38.039398",
        "longitude": "144.468994",
        "altitude": "35",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "ABX": {
        "name": "Albury Airport",
        "city": "Albury",
        "country": "Australia",
        "iata": "ABX",
        "icao": "YMAY",
        "latitude": "-36.06779861450195",
        "longitude": "146.95799255371094",
        "altitude": "539",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "MEB": {
        "name": "Melbourne Essendon Airport",
        "city": "Melbourne",
        "country": "Australia",
        "iata": "MEB",
        "icao": "YMEN",
        "latitude": "-37.7281",
        "longitude": "144.901993",
        "altitude": "282",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "HBA": {
        "name": "Hobart International Airport",
        "city": "Hobart",
        "country": "Australia",
        "iata": "HBA",
        "icao": "YMHB",
        "latitude": "-42.836101532",
        "longitude": "147.509994507",
        "altitude": "13",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "LST": {
        "name": "Launceston Airport",
        "city": "Launceston",
        "country": "Australia",
        "iata": "LST",
        "icao": "YMLT",
        "latitude": "-41.54529953",
        "longitude": "147.214004517",
        "altitude": "562",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "MBW": {
        "name": "Melbourne Moorabbin Airport",
        "city": "Melbourne",
        "country": "Australia",
        "iata": "MBW",
        "icao": "YMMB",
        "latitude": "-37.975799560546875",
        "longitude": "145.1020050048828",
        "altitude": "50",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "MEL": {
        "name": "Melbourne International Airport",
        "city": "Melbourne",
        "country": "Australia",
        "iata": "MEL",
        "icao": "YMML",
        "latitude": "-37.673302",
        "longitude": "144.843002",
        "altitude": "434",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "ADL": {
        "name": "Adelaide International Airport",
        "city": "Adelaide",
        "country": "Australia",
        "iata": "ADL",
        "icao": "YPAD",
        "latitude": "-34.945",
        "longitude": "138.531006",
        "altitude": "20",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "JAD": {
        "name": "Perth Jandakot Airport",
        "city": "Perth",
        "country": "Australia",
        "iata": "JAD",
        "icao": "YPJT",
        "latitude": "-32.09749984741211",
        "longitude": "115.88099670410156",
        "altitude": "99",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "KTA": {
        "name": "Karratha Airport",
        "city": "Karratha",
        "country": "Australia",
        "iata": "KTA",
        "icao": "YPKA",
        "latitude": "-20.712200164799995",
        "longitude": "116.773002625",
        "altitude": "29",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "KGI": {
        "name": "Kalgoorlie Boulder Airport",
        "city": "Kalgoorlie",
        "country": "Australia",
        "iata": "KGI",
        "icao": "YPKG",
        "latitude": "-30.789400100699996",
        "longitude": "121.461997986",
        "altitude": "1203",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "KNX": {
        "name": "Kununurra Airport",
        "city": "Kununurra",
        "country": "Australia",
        "iata": "KNX",
        "icao": "YPKU",
        "latitude": "-15.7781000137",
        "longitude": "128.707992554",
        "altitude": "145",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "LEA": {
        "name": "Learmonth Airport",
        "city": "Learmonth",
        "country": "Australia",
        "iata": "LEA",
        "icao": "YPLM",
        "latitude": "-22.235599517799997",
        "longitude": "114.088996887",
        "altitude": "19",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "PHE": {
        "name": "Port Hedland International Airport",
        "city": "Port Hedland",
        "country": "Australia",
        "iata": "PHE",
        "icao": "YPPD",
        "latitude": "-20.3777999878",
        "longitude": "118.625999451",
        "altitude": "33",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "PER": {
        "name": "Perth International Airport",
        "city": "Perth",
        "country": "Australia",
        "iata": "PER",
        "icao": "YPPH",
        "latitude": "-31.94029998779297",
        "longitude": "115.96700286865234",
        "altitude": "67",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Australia/Perth"
    },
    "UMR": {
        "name": "Woomera Airfield",
        "city": "Woomera",
        "country": "Australia",
        "iata": "UMR",
        "icao": "YPWR",
        "latitude": "-31.14419937133789",
        "longitude": "136.81700134277344",
        "altitude": "548",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "XCH": {
        "name": "Christmas Island Airport",
        "city": "Christmas Island",
        "country": "Christmas Island",
        "iata": "XCH",
        "icao": "YPXM",
        "latitude": "-10.450599670410156",
        "longitude": "105.69000244140625",
        "altitude": "916",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Indian/Christmas"
    },
    "BWU": {
        "name": "Sydney Bankstown Airport",
        "city": "Sydney",
        "country": "Australia",
        "iata": "BWU",
        "icao": "YSBK",
        "latitude": "-33.924400329589844",
        "longitude": "150.98800659179688",
        "altitude": "29",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CBR": {
        "name": "Canberra International Airport",
        "city": "Canberra",
        "country": "Australia",
        "iata": "CBR",
        "icao": "YSCB",
        "latitude": "-35.30690002441406",
        "longitude": "149.19500732421875",
        "altitude": "1886",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CFS": {
        "name": "Coffs Harbour Airport",
        "city": "Coff's Harbour",
        "country": "Australia",
        "iata": "CFS",
        "icao": "YSCH",
        "latitude": "-30.3206005096",
        "longitude": "153.115997314",
        "altitude": "18",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CDU": {
        "name": "Camden Airport",
        "city": "Camden",
        "country": "Australia",
        "iata": "CDU",
        "icao": "YSCN",
        "latitude": "-34.04029846191406",
        "longitude": "150.68699645996094",
        "altitude": "230",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "DBO": {
        "name": "Dubbo City Regional Airport",
        "city": "Dubbo",
        "country": "Australia",
        "iata": "DBO",
        "icao": "YSDU",
        "latitude": "-32.2167015076",
        "longitude": "148.574996948",
        "altitude": "935",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "NLK": {
        "name": "Norfolk Island International Airport",
        "city": "Norfolk Island",
        "country": "Norfolk Island",
        "iata": "NLK",
        "icao": "YSNF",
        "latitude": "-29.04159927368164",
        "longitude": "167.93899536132812",
        "altitude": "371",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Norfolk"
    },
    "XRH": {
        "name": "RAAF Base Richmond",
        "city": "Richmond",
        "country": "Australia",
        "iata": "XRH",
        "icao": "YSRI",
        "latitude": "-33.600601",
        "longitude": "150.781006",
        "altitude": "67",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "SYD": {
        "name": "Sydney Kingsford Smith International Airport",
        "city": "Sydney",
        "country": "Australia",
        "iata": "SYD",
        "icao": "YSSY",
        "latitude": "-33.94609832763672",
        "longitude": "151.177001953125",
        "altitude": "21",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "TMW": {
        "name": "Tamworth Airport",
        "city": "Tamworth",
        "country": "Australia",
        "iata": "TMW",
        "icao": "YSTW",
        "latitude": "-31.0839004517",
        "longitude": "150.847000122",
        "altitude": "1334",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "WGA": {
        "name": "Wagga Wagga City Airport",
        "city": "Wagga Wagga",
        "country": "Australia",
        "iata": "WGA",
        "icao": "YSWG",
        "latitude": "-35.1652984619",
        "longitude": "147.466003418",
        "altitude": "724",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "PEK": {
        "name": "Beijing Capital International Airport",
        "city": "Beijing",
        "country": "China",
        "iata": "PEK",
        "icao": "ZBAA",
        "latitude": "40.080101013183594",
        "longitude": "116.58499908447266",
        "altitude": "116",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HLD": {
        "name": "Dongshan Airport",
        "city": "Hailar",
        "country": "China",
        "iata": "HLD",
        "icao": "ZBLA",
        "latitude": "49.2050018311",
        "longitude": "119.824996948",
        "altitude": "2169",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TSN": {
        "name": "Tianjin Binhai International Airport",
        "city": "Tianjin",
        "country": "China",
        "iata": "TSN",
        "icao": "ZBTJ",
        "latitude": "39.124401092499994",
        "longitude": "117.346000671",
        "altitude": "10",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TYN": {
        "name": "Taiyuan Wusu Airport",
        "city": "Taiyuan",
        "country": "China",
        "iata": "TYN",
        "icao": "ZBYN",
        "latitude": "37.74689865112305",
        "longitude": "112.62799835205078",
        "altitude": "2575",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CAN": {
        "name": "Guangzhou Baiyun International Airport",
        "city": "Guangzhou",
        "country": "China",
        "iata": "CAN",
        "icao": "ZGGG",
        "latitude": "23.39240074157715",
        "longitude": "113.29900360107422",
        "altitude": "50",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CSX": {
        "name": "Changsha Huanghua International Airport",
        "city": "Changcha",
        "country": "China",
        "iata": "CSX",
        "icao": "ZGHA",
        "latitude": "28.189199447599997",
        "longitude": "113.220001221",
        "altitude": "217",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KWL": {
        "name": "Guilin Liangjiang International Airport",
        "city": "Guilin",
        "country": "China",
        "iata": "KWL",
        "icao": "ZGKL",
        "latitude": "25.21809959411621",
        "longitude": "110.03900146484375",
        "altitude": "570",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NNG": {
        "name": "Nanning Wuxu Airport",
        "city": "Nanning",
        "country": "China",
        "iata": "NNG",
        "icao": "ZGNN",
        "latitude": "22.608299255371094",
        "longitude": "108.1719970703125",
        "altitude": "421",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "SZX": {
        "name": "Shenzhen Bao'an International Airport",
        "city": "Shenzhen",
        "country": "China",
        "iata": "SZX",
        "icao": "ZGSZ",
        "latitude": "22.639299392700195",
        "longitude": "113.81099700927734",
        "altitude": "13",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CGO": {
        "name": "Zhengzhou Xinzheng International Airport",
        "city": "Zhengzhou",
        "country": "China",
        "iata": "CGO",
        "icao": "ZHCC",
        "latitude": "34.519699096699995",
        "longitude": "113.841003418",
        "altitude": "495",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WUH": {
        "name": "Wuhan Tianhe International Airport",
        "city": "Wuhan",
        "country": "China",
        "iata": "WUH",
        "icao": "ZHHH",
        "latitude": "30.7838",
        "longitude": "114.208",
        "altitude": "113",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "FNJ": {
        "name": "Pyongyang Sunan International Airport",
        "city": "Pyongyang",
        "country": "North Korea",
        "iata": "FNJ",
        "icao": "ZKPY",
        "latitude": "39.224098",
        "longitude": "125.669998",
        "altitude": "117",
        "timezone": "8.5",
        "dst": "U",
        "tz_name": "Asia/Pyongyang"
    },
    "LHW": {
        "name": "Lanzhou Zhongchuan Airport",
        "city": "Lanzhou",
        "country": "China",
        "iata": "LHW",
        "icao": "ZLLL",
        "latitude": "36.5152015686",
        "longitude": "103.620002747",
        "altitude": "6388",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "XIY": {
        "name": "Xi'an Xianyang International Airport",
        "city": "Xi'an",
        "country": "China",
        "iata": "XIY",
        "icao": "ZLXY",
        "latitude": "34.447102",
        "longitude": "108.751999",
        "altitude": "1572",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ULN": {
        "name": "Chinggis Khaan International Airport",
        "city": "Ulan Bator",
        "country": "Mongolia",
        "iata": "ULN",
        "icao": "ZMUB",
        "latitude": "47.843102",
        "longitude": "106.766998",
        "altitude": "4364",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "JHG": {
        "name": "Xishuangbanna Gasa Airport",
        "city": "Jinghonggasa",
        "country": "China",
        "iata": "JHG",
        "icao": "ZPJH",
        "latitude": "21.973899841308594",
        "longitude": "100.76000213623047",
        "altitude": "1815",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KMG": {
        "name": "Kunming Changshui International Airport",
        "city": "Kunming",
        "country": "China",
        "iata": "KMG",
        "icao": "ZPPP",
        "latitude": "25.1019444",
        "longitude": "102.9291667",
        "altitude": "6903",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "XMN": {
        "name": "Xiamen Gaoqi International Airport",
        "city": "Xiamen",
        "country": "China",
        "iata": "XMN",
        "icao": "ZSAM",
        "latitude": "24.54400062561035",
        "longitude": "118.12799835205078",
        "altitude": "59",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KHN": {
        "name": "Nanchang Changbei International Airport",
        "city": "Nanchang",
        "country": "China",
        "iata": "KHN",
        "icao": "ZSCN",
        "latitude": "28.864999771118164",
        "longitude": "115.9000015258789",
        "altitude": "143",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "FOC": {
        "name": "Fuzhou Changle International Airport",
        "city": "Fuzhou",
        "country": "China",
        "iata": "FOC",
        "icao": "ZSFZ",
        "latitude": "25.935100555419922",
        "longitude": "119.66300201416016",
        "altitude": "46",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HGH": {
        "name": "Hangzhou Xiaoshan International Airport",
        "city": "Hangzhou",
        "country": "China",
        "iata": "HGH",
        "icao": "ZSHC",
        "latitude": "30.22949981689453",
        "longitude": "120.43399810791016",
        "altitude": "23",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NGB": {
        "name": "Ningbo Lishe International Airport",
        "city": "Ninbo",
        "country": "China",
        "iata": "NGB",
        "icao": "ZSNB",
        "latitude": "29.82670021057129",
        "longitude": "121.46199798583984",
        "altitude": "13",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NKG": {
        "name": "Nanjing Lukou Airport",
        "city": "Nanjing",
        "country": "China",
        "iata": "NKG",
        "icao": "ZSNJ",
        "latitude": "31.742000579833984",
        "longitude": "118.86199951171875",
        "altitude": "49",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HFE": {
        "name": "Hefei Luogang International Airport",
        "city": "Hefei",
        "country": "China",
        "iata": "HFE",
        "icao": "ZSOF",
        "latitude": "31.780000686645508",
        "longitude": "117.2979965209961",
        "altitude": "108",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TAO": {
        "name": "Liuting Airport",
        "city": "Qingdao",
        "country": "China",
        "iata": "TAO",
        "icao": "ZSQD",
        "latitude": "36.2661018372",
        "longitude": "120.374000549",
        "altitude": "33",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "SHA": {
        "name": "Shanghai Hongqiao International Airport",
        "city": "Shanghai",
        "country": "China",
        "iata": "SHA",
        "icao": "ZSSS",
        "latitude": "31.197900772094727",
        "longitude": "121.33599853515625",
        "altitude": "10",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YNT": {
        "name": "Yantai Laishan Airport",
        "city": "Yantai",
        "country": "China",
        "iata": "YNT",
        "icao": "ZSYT",
        "latitude": "37.40169906616211",
        "longitude": "121.37200164794922",
        "altitude": "59",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CKG": {
        "name": "Chongqing Jiangbei International Airport",
        "city": "Chongqing",
        "country": "China",
        "iata": "CKG",
        "icao": "ZUCK",
        "latitude": "29.719200134277344",
        "longitude": "106.64199829101562",
        "altitude": "1365",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KWE": {
        "name": "Longdongbao Airport",
        "city": "Guiyang",
        "country": "China",
        "iata": "KWE",
        "icao": "ZUGY",
        "latitude": "26.53849983215332",
        "longitude": "106.8010025024414",
        "altitude": "3736",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CTU": {
        "name": "Chengdu Shuangliu International Airport",
        "city": "Chengdu",
        "country": "China",
        "iata": "CTU",
        "icao": "ZUUU",
        "latitude": "30.578500747680664",
        "longitude": "103.9469985961914",
        "altitude": "1625",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "XIC": {
        "name": "Xichang Qingshan Airport",
        "city": "Xichang",
        "country": "China",
        "iata": "XIC",
        "icao": "ZUXC",
        "latitude": "27.989099502563477",
        "longitude": "102.18399810791016",
        "altitude": "5112",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KHG": {
        "name": "Kashgar Airport",
        "city": "Kashi",
        "country": "China",
        "iata": "KHG",
        "icao": "ZWSH",
        "latitude": "39.5429000854",
        "longitude": "76.0199966431",
        "altitude": "4529",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HTN": {
        "name": "Hotan Airport",
        "city": "Hotan",
        "country": "China",
        "iata": "HTN",
        "icao": "ZWTN",
        "latitude": "37.03850173950195",
        "longitude": "79.86489868164062",
        "altitude": "4672",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "URC": {
        "name": "Ürümqi Diwopu International Airport",
        "city": "Urumqi",
        "country": "China",
        "iata": "URC",
        "icao": "ZWWW",
        "latitude": "43.907100677490234",
        "longitude": "87.47419738769531",
        "altitude": "2125",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HRB": {
        "name": "Taiping Airport",
        "city": "Harbin",
        "country": "China",
        "iata": "HRB",
        "icao": "ZYHB",
        "latitude": "45.6234016418457",
        "longitude": "126.25",
        "altitude": "457",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MDG": {
        "name": "Mudanjiang Hailang International Airport",
        "city": "Mudanjiang",
        "country": "China",
        "iata": "MDG",
        "icao": "ZYMD",
        "latitude": "44.5241012573",
        "longitude": "129.569000244",
        "altitude": "883",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "DLC": {
        "name": "Zhoushuizi Airport",
        "city": "Dalian",
        "country": "China",
        "iata": "DLC",
        "icao": "ZYTL",
        "latitude": "38.9656982421875",
        "longitude": "121.53900146484375",
        "altitude": "107",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "PVG": {
        "name": "Shanghai Pudong International Airport",
        "city": "Shanghai",
        "country": "China",
        "iata": "PVG",
        "icao": "ZSPD",
        "latitude": "31.143400192260742",
        "longitude": "121.80500030517578",
        "altitude": "13",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TOD": {
        "name": "Pulau Tioman Airport",
        "city": "Tioman",
        "country": "Malaysia",
        "iata": "TOD",
        "icao": "WMBT",
        "latitude": "2.8181800842285156",
        "longitude": "104.16000366210938",
        "altitude": "15",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "SZB": {
        "name": "Sultan Abdul Aziz Shah International Airport",
        "city": "Kuala Lumpur",
        "country": "Malaysia",
        "iata": "SZB",
        "icao": "WMSA",
        "latitude": "3.130579948425293",
        "longitude": "101.54900360107422",
        "altitude": "90",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "NTQ": {
        "name": "Noto Airport",
        "city": "Wajima",
        "country": "Japan",
        "iata": "NTQ",
        "icao": "RJNW",
        "latitude": "37.2930984497",
        "longitude": "136.962005615",
        "altitude": "718",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HBE": {
        "name": "Borg El Arab International Airport",
        "city": "Alexandria",
        "country": "Egypt",
        "iata": "HBE",
        "icao": "HEBA",
        "latitude": "30.917699813842773",
        "longitude": "29.696399688720703",
        "altitude": "177",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "BTI": {
        "name": "Barter Island LRRS Airport",
        "city": "Barter Island",
        "country": "United States",
        "iata": "BTI",
        "icao": "PABA",
        "latitude": "70.1340026855",
        "longitude": "-143.582000732",
        "altitude": "2",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LUR": {
        "name": "Cape Lisburne LRRS Airport",
        "city": "Cape Lisburne",
        "country": "United States",
        "iata": "LUR",
        "icao": "PALU",
        "latitude": "68.87509918",
        "longitude": "-166.1100006",
        "altitude": "16",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PIZ": {
        "name": "Point Lay LRRS Airport",
        "city": "Point Lay",
        "country": "United States",
        "iata": "PIZ",
        "icao": "PPIZ",
        "latitude": "69.73290253",
        "longitude": "-163.0050049",
        "altitude": "22",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ITO": {
        "name": "Hilo International Airport",
        "city": "Hilo",
        "country": "United States",
        "iata": "ITO",
        "icao": "PHTO",
        "latitude": "19.721399307250977",
        "longitude": "-155.04800415039062",
        "altitude": "38",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "ORL": {
        "name": "Orlando Executive Airport",
        "city": "Orlando",
        "country": "United States",
        "iata": "ORL",
        "icao": "KORL",
        "latitude": "28.5455",
        "longitude": "-81.332901",
        "altitude": "113",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BTT": {
        "name": "Bettles Airport",
        "city": "Bettles",
        "country": "United States",
        "iata": "BTT",
        "icao": "PABT",
        "latitude": "66.91390228",
        "longitude": "-151.529007",
        "altitude": "647",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "UTO": {
        "name": "Indian Mountain LRRS Airport",
        "city": "Indian Mountains",
        "country": "United States",
        "iata": "UTO",
        "icao": "PAIM",
        "latitude": "65.99279785",
        "longitude": "-153.7039948",
        "altitude": "1273",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "FYU": {
        "name": "Fort Yukon Airport",
        "city": "Fort Yukon",
        "country": "United States",
        "iata": "FYU",
        "icao": "PFYU",
        "latitude": "66.57150268554688",
        "longitude": "-145.25",
        "altitude": "433",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SVW": {
        "name": "Sparrevohn LRRS Airport",
        "city": "Sparrevohn",
        "country": "United States",
        "iata": "SVW",
        "icao": "PASV",
        "latitude": "61.09740067",
        "longitude": "-155.5740051",
        "altitude": "1585",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "FRN": {
        "name": "Bryant Army Heliport",
        "city": "Fort Richardson",
        "country": "United States",
        "iata": "FRN",
        "icao": "PAFR",
        "latitude": "61.26639938",
        "longitude": "-149.6529999",
        "altitude": "378",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TLJ": {
        "name": "Tatalina LRRS Airport",
        "city": "Tatalina",
        "country": "United States",
        "iata": "TLJ",
        "icao": "PATL",
        "latitude": "62.894401550299996",
        "longitude": "-155.977005005",
        "altitude": "964",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CZF": {
        "name": "Cape Romanzof LRRS Airport",
        "city": "Cape Romanzof",
        "country": "United States",
        "iata": "CZF",
        "icao": "PACZ",
        "latitude": "61.78030014",
        "longitude": "-166.0390015",
        "altitude": "464",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "BED": {
        "name": "Laurence G Hanscom Field",
        "city": "Bedford",
        "country": "United States",
        "iata": "BED",
        "icao": "KBED",
        "latitude": "42.47000122",
        "longitude": "-71.28900146",
        "altitude": "133",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SNP": {
        "name": "St Paul Island Airport",
        "city": "St. Paul Island",
        "country": "United States",
        "iata": "SNP",
        "icao": "PASN",
        "latitude": "57.167301177978516",
        "longitude": "-170.22000122070312",
        "altitude": "63",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "EHM": {
        "name": "Cape Newenham LRRS Airport",
        "city": "Cape Newenham",
        "country": "United States",
        "iata": "EHM",
        "icao": "PAEH",
        "latitude": "58.646400451699996",
        "longitude": "-162.06300354",
        "altitude": "541",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "STG": {
        "name": "St George Airport",
        "city": "Point Barrow",
        "country": "United States",
        "iata": "STG",
        "icao": "PAPB",
        "latitude": "56.578300476100004",
        "longitude": "-169.662002563",
        "altitude": "125",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ILI": {
        "name": "Iliamna Airport",
        "city": "Iliamna",
        "country": "United States",
        "iata": "ILI",
        "icao": "PAIL",
        "latitude": "59.75439835",
        "longitude": "-154.9109955",
        "altitude": "192",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PTU": {
        "name": "Platinum Airport",
        "city": "Port Moller",
        "country": "United States",
        "iata": "PTU",
        "icao": "PAPM",
        "latitude": "59.01139831542969",
        "longitude": "-161.82000732421875",
        "altitude": "15",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "BMX": {
        "name": "Big Mountain Airport",
        "city": "Big Mountain",
        "country": "United States",
        "iata": "BMX",
        "icao": "PABM",
        "latitude": "59.3611984253",
        "longitude": "-155.259002686",
        "altitude": "663",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OSC": {
        "name": "Oscoda Wurtsmith Airport",
        "city": "Oscoda",
        "country": "United States",
        "iata": "OSC",
        "icao": "KOSC",
        "latitude": "44.451599",
        "longitude": "-83.394096",
        "altitude": "633",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OAR": {
        "name": "Marina Municipal Airport",
        "city": "Fort Ord",
        "country": "United States",
        "iata": "OAR",
        "icao": "KOAR",
        "latitude": "36.68190002",
        "longitude": "-121.762001",
        "altitude": "137",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MHR": {
        "name": "Sacramento Mather Airport",
        "city": "Sacramento",
        "country": "United States",
        "iata": "MHR",
        "icao": "KMHR",
        "latitude": "38.55390167",
        "longitude": "-121.2979965",
        "altitude": "98",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BYS": {
        "name": "Bicycle Lake Army Air Field",
        "city": "Fort Irwin",
        "country": "United States",
        "iata": "BYS",
        "icao": "KBYS",
        "latitude": "35.2804985046",
        "longitude": "-116.629997253",
        "altitude": "2350",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "FSM": {
        "name": "Fort Smith Regional Airport",
        "city": "Fort Smith",
        "country": "United States",
        "iata": "FSM",
        "icao": "KFSM",
        "latitude": "35.33660125732422",
        "longitude": "-94.36740112304688",
        "altitude": "469",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MRI": {
        "name": "Merrill Field",
        "city": "Anchorage",
        "country": "United States",
        "iata": "MRI",
        "icao": "PAMR",
        "latitude": "61.2135009765625",
        "longitude": "-149.843994140625",
        "altitude": "137",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "GNT": {
        "name": "Grants-Milan Municipal Airport",
        "city": "Grants",
        "country": "United States",
        "iata": "GNT",
        "icao": "KGNT",
        "latitude": "35.167301178",
        "longitude": "-107.902000427",
        "altitude": "6537",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PNC": {
        "name": "Ponca City Regional Airport",
        "city": "Ponca City",
        "country": "United States",
        "iata": "PNC",
        "icao": "KPNC",
        "latitude": "36.73199844",
        "longitude": "-97.09980011",
        "altitude": "1008",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SVN": {
        "name": "Hunter Army Air Field",
        "city": "Hunter Aaf",
        "country": "United States",
        "iata": "SVN",
        "icao": "KSVN",
        "latitude": "32.00999832",
        "longitude": "-81.14569855",
        "altitude": "41",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GFK": {
        "name": "Grand Forks International Airport",
        "city": "Grand Forks",
        "country": "United States",
        "iata": "GFK",
        "icao": "KGFK",
        "latitude": "47.949299",
        "longitude": "-97.176102",
        "altitude": "845",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PBF": {
        "name": "Pine Bluff Regional Airport, Grider Field",
        "city": "Pine Bluff",
        "country": "United States",
        "iata": "PBF",
        "icao": "KPBF",
        "latitude": "34.1731",
        "longitude": "-91.9356",
        "altitude": "206",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NSE": {
        "name": "Whiting Field Naval Air Station - North",
        "city": "Milton",
        "country": "United States",
        "iata": "NSE",
        "icao": "KNSE",
        "latitude": "30.7241993",
        "longitude": "-87.02189636",
        "altitude": "199",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HNM": {
        "name": "Hana Airport",
        "city": "Hana",
        "country": "United States",
        "iata": "HNM",
        "icao": "PHHN",
        "latitude": "20.79560089111328",
        "longitude": "-156.01400756835938",
        "altitude": "78",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "PRC": {
        "name": "Ernest A. Love Field",
        "city": "Prescott",
        "country": "United States",
        "iata": "PRC",
        "icao": "KPRC",
        "latitude": "34.65449905",
        "longitude": "-112.4199982",
        "altitude": "5045",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "TTN": {
        "name": "Trenton Mercer Airport",
        "city": "Trenton",
        "country": "United States",
        "iata": "TTN",
        "icao": "KTTN",
        "latitude": "40.27669906616211",
        "longitude": "-74.8134994506836",
        "altitude": "213",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BOS": {
        "name": "General Edward Lawrence Logan International Airport",
        "city": "Boston",
        "country": "United States",
        "iata": "BOS",
        "icao": "KBOS",
        "latitude": "42.36429977",
        "longitude": "-71.00520325",
        "altitude": "20",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SUU": {
        "name": "Travis Air Force Base",
        "city": "Fairfield",
        "country": "United States",
        "iata": "SUU",
        "icao": "KSUU",
        "latitude": "38.262699127197",
        "longitude": "-121.92700195312",
        "altitude": "62",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RME": {
        "name": "Griffiss International Airport",
        "city": "Rome",
        "country": "United States",
        "iata": "RME",
        "icao": "KRME",
        "latitude": "43.23379898",
        "longitude": "-75.40699768",
        "altitude": "504",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ENV": {
        "name": "Wendover Airport",
        "city": "Wendover",
        "country": "United States",
        "iata": "ENV",
        "icao": "KENV",
        "latitude": "40.7187004089",
        "longitude": "-114.03099823",
        "altitude": "4237",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BFM": {
        "name": "Mobile Downtown Airport",
        "city": "Mobile",
        "country": "United States",
        "iata": "BFM",
        "icao": "KBFM",
        "latitude": "30.626800537100003",
        "longitude": "-88.06809997559999",
        "altitude": "26",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OAK": {
        "name": "Metropolitan Oakland International Airport",
        "city": "Oakland",
        "country": "United States",
        "iata": "OAK",
        "icao": "KOAK",
        "latitude": "37.721298",
        "longitude": "-122.221001",
        "altitude": "9",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "OMA": {
        "name": "Eppley Airfield",
        "city": "Omaha",
        "country": "United States",
        "iata": "OMA",
        "icao": "KOMA",
        "latitude": "41.3032",
        "longitude": "-95.894096",
        "altitude": "984",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OGG": {
        "name": "Kahului Airport",
        "city": "Kahului",
        "country": "United States",
        "iata": "OGG",
        "icao": "PHOG",
        "latitude": "20.8986",
        "longitude": "-156.429993",
        "altitude": "54",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "ICT": {
        "name": "Wichita Eisenhower National Airport",
        "city": "Wichita",
        "country": "United States",
        "iata": "ICT",
        "icao": "KICT",
        "latitude": "37.649899",
        "longitude": "-97.433098",
        "altitude": "1333",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MCI": {
        "name": "Kansas City International Airport",
        "city": "Kansas City",
        "country": "United States",
        "iata": "MCI",
        "icao": "KMCI",
        "latitude": "39.2976",
        "longitude": "-94.713898",
        "altitude": "1026",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MSN": {
        "name": "Dane County Regional Truax Field",
        "city": "Madison",
        "country": "United States",
        "iata": "MSN",
        "icao": "KMSN",
        "latitude": "43.13990020751953",
        "longitude": "-89.3375015258789",
        "altitude": "887",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DLG": {
        "name": "Dillingham Airport",
        "city": "Dillingham",
        "country": "United States",
        "iata": "DLG",
        "icao": "PADL",
        "latitude": "59.04470062",
        "longitude": "-158.5050049",
        "altitude": "81",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HRO": {
        "name": "Boone County Airport",
        "city": "Harrison",
        "country": "United States",
        "iata": "HRO",
        "icao": "KHRO",
        "latitude": "36.26150131225586",
        "longitude": "-93.15470123291016",
        "altitude": "1365",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PHX": {
        "name": "Phoenix Sky Harbor International Airport",
        "city": "Phoenix",
        "country": "United States",
        "iata": "PHX",
        "icao": "KPHX",
        "latitude": "33.43429946899414",
        "longitude": "-112.01200103759766",
        "altitude": "1135",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "BGR": {
        "name": "Bangor International Airport",
        "city": "Bangor",
        "country": "United States",
        "iata": "BGR",
        "icao": "KBGR",
        "latitude": "44.80739974975586",
        "longitude": "-68.8281021118164",
        "altitude": "192",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FXE": {
        "name": "Fort Lauderdale Executive Airport",
        "city": "Fort Lauderdale",
        "country": "United States",
        "iata": "FXE",
        "icao": "KFXE",
        "latitude": "26.1972999573",
        "longitude": "-80.1707000732",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GGG": {
        "name": "East Texas Regional Airport",
        "city": "Longview",
        "country": "United States",
        "iata": "GGG",
        "icao": "KGGG",
        "latitude": "32.38399887084961",
        "longitude": "-94.71150207519531",
        "altitude": "365",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AND": {
        "name": "Anderson Regional Airport",
        "city": "Andersen",
        "country": "United States",
        "iata": "AND",
        "icao": "KAND",
        "latitude": "34.4945983887",
        "longitude": "-82.70939636230001",
        "altitude": "782",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GEG": {
        "name": "Spokane International Airport",
        "city": "Spokane",
        "country": "United States",
        "iata": "GEG",
        "icao": "KGEG",
        "latitude": "47.61989974975586",
        "longitude": "-117.53399658203125",
        "altitude": "2376",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HWO": {
        "name": "North Perry Airport",
        "city": "Hollywood",
        "country": "United States",
        "iata": "HWO",
        "icao": "KHWO",
        "latitude": "26.0012",
        "longitude": "-80.2407",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SFO": {
        "name": "San Francisco International Airport",
        "city": "San Francisco",
        "country": "United States",
        "iata": "SFO",
        "icao": "KSFO",
        "latitude": "37.61899948120117",
        "longitude": "-122.375",
        "altitude": "13",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CTB": {
        "name": "Cut Bank International Airport",
        "city": "Cutbank",
        "country": "United States",
        "iata": "CTB",
        "icao": "KCTB",
        "latitude": "48.6083984375",
        "longitude": "-112.375999451",
        "altitude": "3854",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ARA": {
        "name": "Acadiana Regional Airport",
        "city": "Louisiana",
        "country": "United States",
        "iata": "ARA",
        "icao": "KARA",
        "latitude": "30.0378",
        "longitude": "-91.883904",
        "altitude": "24",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GNV": {
        "name": "Gainesville Regional Airport",
        "city": "Gainesville",
        "country": "United States",
        "iata": "GNV",
        "icao": "KGNV",
        "latitude": "29.6900997162",
        "longitude": "-82.2717971802",
        "altitude": "152",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MEM": {
        "name": "Memphis International Airport",
        "city": "Memphis",
        "country": "United States",
        "iata": "MEM",
        "icao": "KMEM",
        "latitude": "35.04240036010742",
        "longitude": "-89.97669982910156",
        "altitude": "341",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DUG": {
        "name": "Bisbee Douglas International Airport",
        "city": "Douglas",
        "country": "United States",
        "iata": "DUG",
        "icao": "KDUG",
        "latitude": "31.4689998627",
        "longitude": "-109.603996277",
        "altitude": "4154",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "BIG": {
        "name": "Allen Army Airfield",
        "city": "Delta Junction",
        "country": "United States",
        "iata": "BIG",
        "icao": "PABI",
        "latitude": "63.9944992065",
        "longitude": "-145.722000122",
        "altitude": "1291",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CNW": {
        "name": "TSTC Waco Airport",
        "city": "Waco",
        "country": "United States",
        "iata": "CNW",
        "icao": "KCNW",
        "latitude": "31.637800216699997",
        "longitude": "-97.0740966797",
        "altitude": "470",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ANN": {
        "name": "Annette Island Airport",
        "city": "Annette Island",
        "country": "United States",
        "iata": "ANN",
        "icao": "PANT",
        "latitude": "55.04240036010742",
        "longitude": "-131.57200622558594",
        "altitude": "119",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CAR": {
        "name": "Caribou Municipal Airport",
        "city": "Caribou",
        "country": "United States",
        "iata": "CAR",
        "icao": "KCAR",
        "latitude": "46.871498107899995",
        "longitude": "-68.0178985596",
        "altitude": "626",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LRF": {
        "name": "Little Rock Air Force Base",
        "city": "Jacksonville",
        "country": "United States",
        "iata": "LRF",
        "icao": "KLRF",
        "latitude": "34.916900634799994",
        "longitude": "-92.14969635010002",
        "altitude": "311",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HUA": {
        "name": "Redstone Army Air Field",
        "city": "Redstone",
        "country": "United States",
        "iata": "HUA",
        "icao": "KHUA",
        "latitude": "34.67869949",
        "longitude": "-86.68479919",
        "altitude": "684",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "POB": {
        "name": "Pope Field",
        "city": "Fort Bragg",
        "country": "United States",
        "iata": "POB",
        "icao": "KPOB",
        "latitude": "35.1708984375",
        "longitude": "-79.014503479004",
        "altitude": "217",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DHT": {
        "name": "Dalhart Municipal Airport",
        "city": "Dalhart",
        "country": "United States",
        "iata": "DHT",
        "icao": "KDHT",
        "latitude": "36.0225982666",
        "longitude": "-102.54699707",
        "altitude": "3991",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DLF": {
        "name": "DLF Airport",
        "city": "Del Rio",
        "country": "United States",
        "iata": "DLF",
        "icao": "KDLF",
        "latitude": "29.359501",
        "longitude": "-100.778002",
        "altitude": "1082",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LAX": {
        "name": "Los Angeles International Airport",
        "city": "Los Angeles",
        "country": "United States",
        "iata": "LAX",
        "icao": "KLAX",
        "latitude": "33.94250107",
        "longitude": "-118.4079971",
        "altitude": "125",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ANB": {
        "name": "Anniston Regional Airport",
        "city": "Anniston",
        "country": "United States",
        "iata": "ANB",
        "icao": "KANB",
        "latitude": "33.5882",
        "longitude": "-85.8581",
        "altitude": "612",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CLE": {
        "name": "Cleveland Hopkins International Airport",
        "city": "Cleveland",
        "country": "United States",
        "iata": "CLE",
        "icao": "KCLE",
        "latitude": "41.4117012024",
        "longitude": "-81.8498001099",
        "altitude": "791",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DOV": {
        "name": "Dover Air Force Base",
        "city": "Dover",
        "country": "United States",
        "iata": "DOV",
        "icao": "KDOV",
        "latitude": "39.12950134",
        "longitude": "-75.46600342",
        "altitude": "24",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CVG": {
        "name": "Cincinnati Northern Kentucky International Airport",
        "city": "Cincinnati",
        "country": "United States",
        "iata": "CVG",
        "icao": "KCVG",
        "latitude": "39.0488014221",
        "longitude": "-84.6678009033",
        "altitude": "896",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FME": {
        "name": "Tipton Airport",
        "city": "Fort Meade",
        "country": "United States",
        "iata": "FME",
        "icao": "KFME",
        "latitude": "39.08539962769999",
        "longitude": "-76.7593994141",
        "altitude": "150",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HON": {
        "name": "Huron Regional Airport",
        "city": "Huron",
        "country": "United States",
        "iata": "HON",
        "icao": "KHON",
        "latitude": "44.38520050048828",
        "longitude": "-98.22850036621094",
        "altitude": "1289",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JNU": {
        "name": "Juneau International Airport",
        "city": "Juneau",
        "country": "United States",
        "iata": "JNU",
        "icao": "PAJN",
        "latitude": "58.35499954223633",
        "longitude": "-134.5760040283203",
        "altitude": "21",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LFT": {
        "name": "Lafayette Regional Airport",
        "city": "Lafayette",
        "country": "United States",
        "iata": "LFT",
        "icao": "KLFT",
        "latitude": "30.20529938",
        "longitude": "-91.98760223",
        "altitude": "42",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EWR": {
        "name": "Newark Liberty International Airport",
        "city": "Newark",
        "country": "United States",
        "iata": "EWR",
        "icao": "KEWR",
        "latitude": "40.692501068115234",
        "longitude": "-74.168701171875",
        "altitude": "18",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BOI": {
        "name": "Boise Air Terminal/Gowen Field",
        "city": "Boise",
        "country": "United States",
        "iata": "BOI",
        "icao": "KBOI",
        "latitude": "43.5644",
        "longitude": "-116.223",
        "altitude": "2871",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "INS": {
        "name": "Creech Air Force Base",
        "city": "Indian Springs",
        "country": "United States",
        "iata": "INS",
        "icao": "KINS",
        "latitude": "36.587200164799995",
        "longitude": "-115.672996521",
        "altitude": "3133",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GCK": {
        "name": "Garden City Regional Airport",
        "city": "Garden City",
        "country": "United States",
        "iata": "GCK",
        "icao": "KGCK",
        "latitude": "37.9275016785",
        "longitude": "-100.723999023",
        "altitude": "2891",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MOT": {
        "name": "Minot International Airport",
        "city": "Minot",
        "country": "United States",
        "iata": "MOT",
        "icao": "KMOT",
        "latitude": "48.2593994140625",
        "longitude": "-101.27999877929688",
        "altitude": "1716",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HHI": {
        "name": "Wheeler Army Airfield",
        "city": "Wahiawa",
        "country": "United States",
        "iata": "HHI",
        "icao": "PHHI",
        "latitude": "21.48349953",
        "longitude": "-158.0399933",
        "altitude": "837",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "MXF": {
        "name": "Maxwell Air Force Base",
        "city": "Montgomery",
        "country": "United States",
        "iata": "MXF",
        "icao": "KMXF",
        "latitude": "32.3829",
        "longitude": "-86.365799",
        "altitude": "171",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DAL": {
        "name": "Dallas Love Field",
        "city": "Dallas",
        "country": "United States",
        "iata": "DAL",
        "icao": "KDAL",
        "latitude": "32.847099",
        "longitude": "-96.851799",
        "altitude": "487",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FCS": {
        "name": "Butts AAF (Fort Carson) Air Field",
        "city": "Fort Carson",
        "country": "United States",
        "iata": "FCS",
        "icao": "KFCS",
        "latitude": "38.67839813",
        "longitude": "-104.7570038",
        "altitude": "5838",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "HLN": {
        "name": "Helena Regional Airport",
        "city": "Helena",
        "country": "United States",
        "iata": "HLN",
        "icao": "KHLN",
        "latitude": "46.6068000793457",
        "longitude": "-111.98300170898438",
        "altitude": "3877",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "NKX": {
        "name": "Miramar Marine Corps Air Station - Mitscher Field",
        "city": "Miramar",
        "country": "United States",
        "iata": "NKX",
        "icao": "KNKX",
        "latitude": "32.86840057",
        "longitude": "-117.1429977",
        "altitude": "477",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LUF": {
        "name": "Luke Air Force Base",
        "city": "Phoenix",
        "country": "United States",
        "iata": "LUF",
        "icao": "KLUF",
        "latitude": "33.534999847399995",
        "longitude": "-112.383003235",
        "altitude": "1085",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "HHR": {
        "name": "Jack Northrop Field Hawthorne Municipal Airport",
        "city": "Hawthorne",
        "country": "United States",
        "iata": "HHR",
        "icao": "KHHR",
        "latitude": "33.922798",
        "longitude": "-118.334999",
        "altitude": "66",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HUL": {
        "name": "Houlton International Airport",
        "city": "Houlton",
        "country": "United States",
        "iata": "HUL",
        "icao": "KHUL",
        "latitude": "46.1231002808",
        "longitude": "-67.792098999",
        "altitude": "489",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "END": {
        "name": "Vance Air Force Base",
        "city": "Enid",
        "country": "United States",
        "iata": "END",
        "icao": "KEND",
        "latitude": "36.339199066199996",
        "longitude": "-97.9164962769",
        "altitude": "1307",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NTD": {
        "name": "Point Mugu Naval Air Station (Naval Base Ventura Co)",
        "city": "Point Mugu",
        "country": "United States",
        "iata": "NTD",
        "icao": "KNTD",
        "latitude": "34.120300293",
        "longitude": "-119.121002197",
        "altitude": "13",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "EDW": {
        "name": "Edwards Air Force Base",
        "city": "Edwards Afb",
        "country": "United States",
        "iata": "EDW",
        "icao": "KEDW",
        "latitude": "34.905399",
        "longitude": "-117.884003",
        "altitude": "2312",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LCH": {
        "name": "Lake Charles Regional Airport",
        "city": "Lake Charles",
        "country": "United States",
        "iata": "LCH",
        "icao": "KLCH",
        "latitude": "30.126100540161133",
        "longitude": "-93.22329711914062",
        "altitude": "15",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KOA": {
        "name": "Ellison Onizuka Kona International At Keahole Airport",
        "city": "Kona",
        "country": "United States",
        "iata": "KOA",
        "icao": "PHKO",
        "latitude": "19.738783",
        "longitude": "-156.045603",
        "altitude": "47",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "MYR": {
        "name": "Myrtle Beach International Airport",
        "city": "Myrtle Beach",
        "country": "United States",
        "iata": "MYR",
        "icao": "KMYR",
        "latitude": "33.6796989441",
        "longitude": "-78.9282989502",
        "altitude": "25",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NLC": {
        "name": "Lemoore Naval Air Station (Reeves Field) Airport",
        "city": "Lemoore",
        "country": "United States",
        "iata": "NLC",
        "icao": "KNLC",
        "latitude": "36.33300018",
        "longitude": "-119.9520035",
        "altitude": "232",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ACK": {
        "name": "Nantucket Memorial Airport",
        "city": "Nantucket",
        "country": "United States",
        "iata": "ACK",
        "icao": "KACK",
        "latitude": "41.25310135",
        "longitude": "-70.06020355",
        "altitude": "47",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FAF": {
        "name": "Felker Army Air Field",
        "city": "Fort Eustis",
        "country": "United States",
        "iata": "FAF",
        "icao": "KFAF",
        "latitude": "37.132499694799996",
        "longitude": "-76.60880279540001",
        "altitude": "12",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HOP": {
        "name": "Campbell AAF (Fort Campbell) Air Field",
        "city": "Hopkinsville",
        "country": "United States",
        "iata": "HOP",
        "icao": "KHOP",
        "latitude": "36.668598175",
        "longitude": "-87.49620056150002",
        "altitude": "573",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DCA": {
        "name": "Ronald Reagan Washington National Airport",
        "city": "Washington",
        "country": "United States",
        "iata": "DCA",
        "icao": "KDCA",
        "latitude": "38.8521",
        "longitude": "-77.037697",
        "altitude": "15",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NHK": {
        "name": "Patuxent River Naval Air Station (Trapnell Field)",
        "city": "Patuxent River",
        "country": "United States",
        "iata": "NHK",
        "icao": "KNHK",
        "latitude": "38.285999",
        "longitude": "-76.411797",
        "altitude": "39",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PSX": {
        "name": "Palacios Municipal Airport",
        "city": "Palacios",
        "country": "United States",
        "iata": "PSX",
        "icao": "KPSX",
        "latitude": "28.727500915527",
        "longitude": "-96.250999450684",
        "altitude": "14",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BYH": {
        "name": "Arkansas International Airport",
        "city": "Blytheville",
        "country": "United States",
        "iata": "BYH",
        "icao": "KBYH",
        "latitude": "35.9642982483",
        "longitude": "-89.94400024410001",
        "altitude": "254",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ACY": {
        "name": "Atlantic City International Airport",
        "city": "Atlantic City",
        "country": "United States",
        "iata": "ACY",
        "icao": "KACY",
        "latitude": "39.45759963989258",
        "longitude": "-74.57720184326172",
        "altitude": "75",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TIK": {
        "name": "Tinker Air Force Base",
        "city": "Oklahoma City",
        "country": "United States",
        "iata": "TIK",
        "icao": "KTIK",
        "latitude": "35.414699554443",
        "longitude": "-97.386596679688",
        "altitude": "1291",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ECG": {
        "name": "Elizabeth City Regional Airport & Coast Guard Air Station",
        "city": "Elizabeth City",
        "country": "United States",
        "iata": "ECG",
        "icao": "KECG",
        "latitude": "36.26060104",
        "longitude": "-76.17459869",
        "altitude": "12",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PUB": {
        "name": "Pueblo Memorial Airport",
        "city": "Pueblo",
        "country": "United States",
        "iata": "PUB",
        "icao": "KPUB",
        "latitude": "38.289100646972656",
        "longitude": "-104.49700164794922",
        "altitude": "4726",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PQI": {
        "name": "Northern Maine Regional Airport at Presque Isle",
        "city": "Presque Isle",
        "country": "United States",
        "iata": "PQI",
        "icao": "KPQI",
        "latitude": "46.68899918",
        "longitude": "-68.0447998",
        "altitude": "534",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GRF": {
        "name": "Gray Army Air Field",
        "city": "Fort Lewis",
        "country": "United States",
        "iata": "GRF",
        "icao": "KGRF",
        "latitude": "47.07920074",
        "longitude": "-122.5810013",
        "altitude": "300",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ADQ": {
        "name": "Kodiak Airport",
        "city": "Kodiak",
        "country": "United States",
        "iata": "ADQ",
        "icao": "PADQ",
        "latitude": "57.75",
        "longitude": "-152.4940033",
        "altitude": "78",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "UPP": {
        "name": "Upolu Airport",
        "city": "Opolu",
        "country": "United States",
        "iata": "UPP",
        "icao": "PHUP",
        "latitude": "20.265300750732422",
        "longitude": "-155.86000061035156",
        "altitude": "96",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "FLL": {
        "name": "Fort Lauderdale Hollywood International Airport",
        "city": "Fort Lauderdale",
        "country": "United States",
        "iata": "FLL",
        "icao": "KFLL",
        "latitude": "26.072599",
        "longitude": "-80.152702",
        "altitude": "9",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "INL": {
        "name": "Falls International Airport",
        "city": "International Falls",
        "country": "United States",
        "iata": "INL",
        "icao": "KINL",
        "latitude": "48.566200256347656",
        "longitude": "-93.4030990600586",
        "altitude": "1185",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SLC": {
        "name": "Salt Lake City International Airport",
        "city": "Salt Lake City",
        "country": "United States",
        "iata": "SLC",
        "icao": "KSLC",
        "latitude": "40.78839874267578",
        "longitude": "-111.97799682617188",
        "altitude": "4227",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CDS": {
        "name": "Childress Municipal Airport",
        "city": "Childress",
        "country": "United States",
        "iata": "CDS",
        "icao": "KCDS",
        "latitude": "34.4337997437",
        "longitude": "-100.288002014",
        "altitude": "1954",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BIX": {
        "name": "Keesler Air Force Base",
        "city": "Biloxi",
        "country": "United States",
        "iata": "BIX",
        "icao": "KBIX",
        "latitude": "30.4104003906",
        "longitude": "-88.92440032959999",
        "altitude": "33",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LSF": {
        "name": "Lawson Army Air Field (Fort Benning)",
        "city": "Fort Benning",
        "country": "United States",
        "iata": "LSF",
        "icao": "KLSF",
        "latitude": "32.337299346900004",
        "longitude": "-84.9913024902",
        "altitude": "232",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NQI": {
        "name": "Kingsville Naval Air Station",
        "city": "Kingsville",
        "country": "United States",
        "iata": "NQI",
        "icao": "KNQI",
        "latitude": "27.5072002411",
        "longitude": "-97.8097000122",
        "altitude": "50",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FRI": {
        "name": "Marshall Army Air Field",
        "city": "Fort Riley",
        "country": "United States",
        "iata": "FRI",
        "icao": "KFRI",
        "latitude": "39.05530167",
        "longitude": "-96.76450348",
        "altitude": "1065",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MDT": {
        "name": "Harrisburg International Airport",
        "city": "Harrisburg",
        "country": "United States",
        "iata": "MDT",
        "icao": "KMDT",
        "latitude": "40.1935005188",
        "longitude": "-76.7633972168",
        "altitude": "310",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LNK": {
        "name": "Lincoln Airport",
        "city": "Lincoln",
        "country": "United States",
        "iata": "LNK",
        "icao": "KLNK",
        "latitude": "40.85100173950195",
        "longitude": "-96.75920104980469",
        "altitude": "1219",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LAN": {
        "name": "Capital City Airport",
        "city": "Lansing",
        "country": "United States",
        "iata": "LAN",
        "icao": "KLAN",
        "latitude": "42.77870178222656",
        "longitude": "-84.58740234375",
        "altitude": "861",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MUE": {
        "name": "Waimea Kohala Airport",
        "city": "Kamuela",
        "country": "United States",
        "iata": "MUE",
        "icao": "PHMU",
        "latitude": "20.001300811767578",
        "longitude": "-155.66799926757812",
        "altitude": "2671",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "MSS": {
        "name": "Massena International Richards Field",
        "city": "Massena",
        "country": "United States",
        "iata": "MSS",
        "icao": "KMSS",
        "latitude": "44.93579864501953",
        "longitude": "-74.84559631347656",
        "altitude": "215",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HKY": {
        "name": "Hickory Regional Airport",
        "city": "Hickory",
        "country": "United States",
        "iata": "HKY",
        "icao": "KHKY",
        "latitude": "35.74110031",
        "longitude": "-81.38950348",
        "altitude": "1190",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SPG": {
        "name": "Albert Whitted Airport",
        "city": "St. Petersburg",
        "country": "United States",
        "iata": "SPG",
        "icao": "KSPG",
        "latitude": "27.7651",
        "longitude": "-82.626999",
        "altitude": "7",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FMY": {
        "name": "Page Field",
        "city": "Fort Myers",
        "country": "United States",
        "iata": "FMY",
        "icao": "KFMY",
        "latitude": "26.58659935",
        "longitude": "-81.86329650879999",
        "altitude": "17",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "IAH": {
        "name": "George Bush Intercontinental Houston Airport",
        "city": "Houston",
        "country": "United States",
        "iata": "IAH",
        "icao": "KIAH",
        "latitude": "29.984399795532227",
        "longitude": "-95.34140014648438",
        "altitude": "97",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ADW": {
        "name": "Joint Base Andrews",
        "city": "Camp Springs",
        "country": "United States",
        "iata": "ADW",
        "icao": "KADW",
        "latitude": "38.810799",
        "longitude": "-76.866997",
        "altitude": "280",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "INT": {
        "name": "Smith Reynolds Airport",
        "city": "Winston-salem",
        "country": "United States",
        "iata": "INT",
        "icao": "KINT",
        "latitude": "36.13370132446289",
        "longitude": "-80.22200012207031",
        "altitude": "969",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "VCV": {
        "name": "Southern California Logistics Airport",
        "city": "Victorville",
        "country": "United States",
        "iata": "VCV",
        "icao": "KVCV",
        "latitude": "34.597499847399995",
        "longitude": "-117.383003235",
        "altitude": "2885",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CEW": {
        "name": "Bob Sikes Airport",
        "city": "Crestview",
        "country": "United States",
        "iata": "CEW",
        "icao": "KCEW",
        "latitude": "30.778799057",
        "longitude": "-86.522102356",
        "altitude": "213",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PHN": {
        "name": "St Clair County International Airport",
        "city": "Port Huron",
        "country": "United States",
        "iata": "PHN",
        "icao": "KPHN",
        "latitude": "42.9109993",
        "longitude": "-82.52890015",
        "altitude": "650",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BFL": {
        "name": "Meadows Field",
        "city": "Bakersfield",
        "country": "United States",
        "iata": "BFL",
        "icao": "KBFL",
        "latitude": "35.43360138",
        "longitude": "-119.0569992",
        "altitude": "510",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ELP": {
        "name": "El Paso International Airport",
        "city": "El Paso",
        "country": "United States",
        "iata": "ELP",
        "icao": "KELP",
        "latitude": "31.80719948",
        "longitude": "-106.3779984",
        "altitude": "3959",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "HRL": {
        "name": "Valley International Airport",
        "city": "Harlingen",
        "country": "United States",
        "iata": "HRL",
        "icao": "KHRL",
        "latitude": "26.228500366210938",
        "longitude": "-97.65440368652344",
        "altitude": "36",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CAE": {
        "name": "Columbia Metropolitan Airport",
        "city": "Columbia",
        "country": "United States",
        "iata": "CAE",
        "icao": "KCAE",
        "latitude": "33.93880081176758",
        "longitude": "-81.11949920654297",
        "altitude": "236",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DMA": {
        "name": "Davis Monthan Air Force Base",
        "city": "Tucson",
        "country": "United States",
        "iata": "DMA",
        "icao": "KDMA",
        "latitude": "32.1665000916",
        "longitude": "-110.883003235",
        "altitude": "2704",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "NPA": {
        "name": "Pensacola Naval Air Station/Forrest Sherman Field",
        "city": "Pensacola",
        "country": "United States",
        "iata": "NPA",
        "icao": "KNPA",
        "latitude": "30.35269928",
        "longitude": "-87.31860352",
        "altitude": "28",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PNS": {
        "name": "Pensacola Regional Airport",
        "city": "Pensacola",
        "country": "United States",
        "iata": "PNS",
        "icao": "KPNS",
        "latitude": "30.473400115967",
        "longitude": "-87.186599731445",
        "altitude": "121",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RDR": {
        "name": "Grand Forks Air Force Base",
        "city": "Red River",
        "country": "United States",
        "iata": "RDR",
        "icao": "KRDR",
        "latitude": "47.961101532",
        "longitude": "-97.4011993408",
        "altitude": "913",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HOU": {
        "name": "William P Hobby Airport",
        "city": "Houston",
        "country": "United States",
        "iata": "HOU",
        "icao": "KHOU",
        "latitude": "29.64539909",
        "longitude": "-95.27890015",
        "altitude": "46",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BFK": {
        "name": "Buckley Air Force Base",
        "city": "Buckley",
        "country": "United States",
        "iata": "BFK",
        "icao": "KBKF",
        "latitude": "39.701698303200004",
        "longitude": "-104.751998901",
        "altitude": "5662",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ORT": {
        "name": "Northway Airport",
        "city": "Northway",
        "country": "United States",
        "iata": "ORT",
        "icao": "PAOR",
        "latitude": "62.9612999",
        "longitude": "-141.9290009",
        "altitude": "1715",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PAQ": {
        "name": "Warren \"Bud\" Woods Palmer Municipal Airport",
        "city": "Palmer",
        "country": "United States",
        "iata": "PAQ",
        "icao": "PAAQ",
        "latitude": "61.594898",
        "longitude": "-149.08901",
        "altitude": "242",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PIT": {
        "name": "Pittsburgh International Airport",
        "city": "Pittsburgh",
        "country": "United States",
        "iata": "PIT",
        "icao": "KPIT",
        "latitude": "40.49150085",
        "longitude": "-80.23290253",
        "altitude": "1203",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BRW": {
        "name": "Wiley Post Will Rogers Memorial Airport",
        "city": "Barrow",
        "country": "United States",
        "iata": "BRW",
        "icao": "PABR",
        "latitude": "71.285402",
        "longitude": "-156.766008",
        "altitude": "44",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "EFD": {
        "name": "Ellington Airport",
        "city": "Houston",
        "country": "United States",
        "iata": "EFD",
        "icao": "KEFD",
        "latitude": "29.607299804700002",
        "longitude": "-95.1587982178",
        "altitude": "32",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NUW": {
        "name": "Whidbey Island Naval Air Station (Ault Field)",
        "city": "Whidbey Island",
        "country": "United States",
        "iata": "NUW",
        "icao": "KNUW",
        "latitude": "48.351799",
        "longitude": "-122.655998",
        "altitude": "47",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ALI": {
        "name": "Alice International Airport",
        "city": "Alice",
        "country": "United States",
        "iata": "ALI",
        "icao": "KALI",
        "latitude": "27.740900039699998",
        "longitude": "-98.02690124510002",
        "altitude": "178",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "VAD": {
        "name": "Moody Air Force Base",
        "city": "Valdosta",
        "country": "United States",
        "iata": "VAD",
        "icao": "KVAD",
        "latitude": "30.9678001404",
        "longitude": "-83.1930007935",
        "altitude": "233",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MIA": {
        "name": "Miami International Airport",
        "city": "Miami",
        "country": "United States",
        "iata": "MIA",
        "icao": "KMIA",
        "latitude": "25.79319953918457",
        "longitude": "-80.29060363769531",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SEA": {
        "name": "Seattle Tacoma International Airport",
        "city": "Seattle",
        "country": "United States",
        "iata": "SEA",
        "icao": "KSEA",
        "latitude": "47.449001",
        "longitude": "-122.308998",
        "altitude": "433",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CHA": {
        "name": "Lovell Field",
        "city": "Chattanooga",
        "country": "United States",
        "iata": "CHA",
        "icao": "KCHA",
        "latitude": "35.035301208496094",
        "longitude": "-85.20379638671875",
        "altitude": "683",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BDR": {
        "name": "Igor I Sikorsky Memorial Airport",
        "city": "Stratford",
        "country": "United States",
        "iata": "BDR",
        "icao": "KBDR",
        "latitude": "41.16350173950195",
        "longitude": "-73.1261978149414",
        "altitude": "9",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JAN": {
        "name": "Jackson-Medgar Wiley Evers International Airport",
        "city": "Jackson",
        "country": "United States",
        "iata": "JAN",
        "icao": "KJAN",
        "latitude": "32.3111991882",
        "longitude": "-90.0758972168",
        "altitude": "346",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GLS": {
        "name": "Scholes International At Galveston Airport",
        "city": "Galveston",
        "country": "United States",
        "iata": "GLS",
        "icao": "KGLS",
        "latitude": "29.265300750732422",
        "longitude": "-94.86039733886719",
        "altitude": "6",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LGB": {
        "name": "Long Beach /Daugherty Field/ Airport",
        "city": "Long Beach",
        "country": "United States",
        "iata": "LGB",
        "icao": "KLGB",
        "latitude": "33.81769943",
        "longitude": "-118.1520004",
        "altitude": "60",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HDH": {
        "name": "Dillingham Airfield",
        "city": "Dillingham",
        "country": "United States",
        "iata": "HDH",
        "icao": "PHDH",
        "latitude": "21.5795001984",
        "longitude": "-158.197006226",
        "altitude": "14",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "IPT": {
        "name": "Williamsport Regional Airport",
        "city": "Williamsport",
        "country": "United States",
        "iata": "IPT",
        "icao": "KIPT",
        "latitude": "41.241798400878906",
        "longitude": "-76.92109680175781",
        "altitude": "529",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "IND": {
        "name": "Indianapolis International Airport",
        "city": "Indianapolis",
        "country": "United States",
        "iata": "IND",
        "icao": "KIND",
        "latitude": "39.7173",
        "longitude": "-86.294403",
        "altitude": "797",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SZL": {
        "name": "Whiteman Air Force Base",
        "city": "Knobnoster",
        "country": "United States",
        "iata": "SZL",
        "icao": "KSZL",
        "latitude": "38.73030090332",
        "longitude": "-93.547897338867",
        "altitude": "870",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AKC": {
        "name": "Akron Fulton International Airport",
        "city": "Akron",
        "country": "United States",
        "iata": "AKC",
        "icao": "KAKR",
        "latitude": "41.0374984741",
        "longitude": "-81.4669036865",
        "altitude": "1067",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GWO": {
        "name": "Greenwood–Leflore Airport",
        "city": "Greenwood",
        "country": "United States",
        "iata": "GWO",
        "icao": "KGWO",
        "latitude": "33.4943008423",
        "longitude": "-90.0847015381",
        "altitude": "162",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HPN": {
        "name": "Westchester County Airport",
        "city": "White Plains",
        "country": "United States",
        "iata": "HPN",
        "icao": "KHPN",
        "latitude": "41.06700134277344",
        "longitude": "-73.70760345458984",
        "altitude": "439",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FOK": {
        "name": "Francis S Gabreski Airport",
        "city": "West Hampton Beach",
        "country": "United States",
        "iata": "FOK",
        "icao": "KFOK",
        "latitude": "40.8437004089",
        "longitude": "-72.6317977905",
        "altitude": "67",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JBR": {
        "name": "Jonesboro Municipal Airport",
        "city": "Jonesboro",
        "country": "United States",
        "iata": "JBR",
        "icao": "KJBR",
        "latitude": "35.83169937133789",
        "longitude": "-90.64640045166016",
        "altitude": "262",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "XSD": {
        "name": "Tonopah Test Range Airport",
        "city": "Tonopah",
        "country": "United States",
        "iata": "XSD",
        "icao": "KTNX",
        "latitude": "37.7988014221",
        "longitude": "-116.78099823",
        "altitude": "5549",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LNA": {
        "name": "Palm Beach County Park Airport",
        "city": "West Palm Beach",
        "country": "United States",
        "iata": "LNA",
        "icao": "KLNA",
        "latitude": "26.59300041",
        "longitude": "-80.08509827",
        "altitude": "14",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NZY": {
        "name": "North Island Naval Air Station-Halsey Field",
        "city": "San Diego",
        "country": "United States",
        "iata": "NZY",
        "icao": "KNZY",
        "latitude": "32.69919968",
        "longitude": "-117.2149963",
        "altitude": "26",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BIF": {
        "name": "Biggs Army Air Field (Fort Bliss)",
        "city": "El Paso",
        "country": "United States",
        "iata": "BIF",
        "icao": "KBIF",
        "latitude": "31.84950066",
        "longitude": "-106.3799973",
        "altitude": "3946",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "YUM": {
        "name": "Yuma MCAS/Yuma International Airport",
        "city": "Yuma",
        "country": "United States",
        "iata": "YUM",
        "icao": "KNYL",
        "latitude": "32.65660095",
        "longitude": "-114.6060028",
        "altitude": "213",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "CNM": {
        "name": "Cavern City Air Terminal",
        "city": "Carlsbad",
        "country": "United States",
        "iata": "CNM",
        "icao": "KCNM",
        "latitude": "32.337501525878906",
        "longitude": "-104.26300048828125",
        "altitude": "3295",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "DLH": {
        "name": "Duluth International Airport",
        "city": "Duluth",
        "country": "United States",
        "iata": "DLH",
        "icao": "KDLH",
        "latitude": "46.8420982361",
        "longitude": "-92.19360351559999",
        "altitude": "1428",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BET": {
        "name": "Bethel Airport",
        "city": "Bethel",
        "country": "United States",
        "iata": "BET",
        "icao": "PABE",
        "latitude": "60.77980042",
        "longitude": "-161.8379974",
        "altitude": "126",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LOU": {
        "name": "Bowman Field",
        "city": "Louisville",
        "country": "United States",
        "iata": "LOU",
        "icao": "KLOU",
        "latitude": "38.2280006409",
        "longitude": "-85.6636962891",
        "altitude": "546",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FHU": {
        "name": "Sierra Vista Municipal Libby Army Air Field",
        "city": "Fort Huachuca",
        "country": "United States",
        "iata": "FHU",
        "icao": "KFHU",
        "latitude": "31.588499069213867",
        "longitude": "-110.34400177001953",
        "altitude": "4719",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "LIH": {
        "name": "Lihue Airport",
        "city": "Lihue",
        "country": "United States",
        "iata": "LIH",
        "icao": "PHLI",
        "latitude": "21.97599983215332",
        "longitude": "-159.33900451660156",
        "altitude": "153",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "HUF": {
        "name": "Terre Haute Regional Airport, Hulman Field",
        "city": "Terre Haute",
        "country": "United States",
        "iata": "HUF",
        "icao": "KHUF",
        "latitude": "39.4515",
        "longitude": "-87.307602",
        "altitude": "589",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HVR": {
        "name": "Havre City County Airport",
        "city": "Havre",
        "country": "United States",
        "iata": "HVR",
        "icao": "KHVR",
        "latitude": "48.54299927",
        "longitude": "-109.762001",
        "altitude": "2591",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MWH": {
        "name": "Grant County International Airport",
        "city": "Grant County Airport",
        "country": "United States",
        "iata": "MWH",
        "icao": "KMWH",
        "latitude": "47.20769882",
        "longitude": "-119.3199997",
        "altitude": "1189",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MPV": {
        "name": "Edward F Knapp State Airport",
        "city": "Montpelier",
        "country": "United States",
        "iata": "MPV",
        "icao": "KMPV",
        "latitude": "44.20349884",
        "longitude": "-72.56230164",
        "altitude": "1166",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RIC": {
        "name": "Richmond International Airport",
        "city": "Richmond",
        "country": "United States",
        "iata": "RIC",
        "icao": "KRIC",
        "latitude": "37.50519943237305",
        "longitude": "-77.3197021484375",
        "altitude": "167",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SHV": {
        "name": "Shreveport Regional Airport",
        "city": "Shreveport",
        "country": "United States",
        "iata": "SHV",
        "icao": "KSHV",
        "latitude": "32.446602",
        "longitude": "-93.8256",
        "altitude": "258",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CDV": {
        "name": "Merle K (Mudhole) Smith Airport",
        "city": "Cordova",
        "country": "United States",
        "iata": "CDV",
        "icao": "PACV",
        "latitude": "60.4917984",
        "longitude": "-145.4779968",
        "altitude": "54",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ORF": {
        "name": "Norfolk International Airport",
        "city": "Norfolk",
        "country": "United States",
        "iata": "ORF",
        "icao": "KORF",
        "latitude": "36.89459991455078",
        "longitude": "-76.20120239257812",
        "altitude": "26",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BPT": {
        "name": "Southeast Texas Regional Airport",
        "city": "Beaumont",
        "country": "United States",
        "iata": "BPT",
        "icao": "KBPT",
        "latitude": "29.9507999420166",
        "longitude": "-94.02069854736328",
        "altitude": "15",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SAV": {
        "name": "Savannah Hilton Head International Airport",
        "city": "Savannah",
        "country": "United States",
        "iata": "SAV",
        "icao": "KSAV",
        "latitude": "32.12760162",
        "longitude": "-81.20210266",
        "altitude": "50",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HIF": {
        "name": "Hill Air Force Base",
        "city": "Ogden",
        "country": "United States",
        "iata": "HIF",
        "icao": "KHIF",
        "latitude": "41.12403",
        "longitude": "-111.973086",
        "altitude": "4789",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "OME": {
        "name": "Nome Airport",
        "city": "Nome",
        "country": "United States",
        "iata": "OME",
        "icao": "PAOM",
        "latitude": "64.51219940185547",
        "longitude": "-165.44500732421875",
        "altitude": "37",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PIE": {
        "name": "St Petersburg Clearwater International Airport",
        "city": "St. Petersburg",
        "country": "United States",
        "iata": "PIE",
        "icao": "KPIE",
        "latitude": "27.91020012",
        "longitude": "-82.68740082",
        "altitude": "11",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MNM": {
        "name": "Menominee Regional Airport",
        "city": "Macon",
        "country": "United States",
        "iata": "MNM",
        "icao": "KMNM",
        "latitude": "45.126701",
        "longitude": "-87.638397",
        "altitude": "625",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CXO": {
        "name": "Conroe-North Houston Regional Airport",
        "city": "Conroe",
        "country": "United States",
        "iata": "CXO",
        "icao": "KCXO",
        "latitude": "30.351801",
        "longitude": "-95.414497",
        "altitude": "245",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SCC": {
        "name": "Deadhorse Airport",
        "city": "Deadhorse",
        "country": "United States",
        "iata": "SCC",
        "icao": "PASC",
        "latitude": "70.19470215",
        "longitude": "-148.4649963",
        "altitude": "65",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SAT": {
        "name": "San Antonio International Airport",
        "city": "San Antonio",
        "country": "United States",
        "iata": "SAT",
        "icao": "KSAT",
        "latitude": "29.533700942993164",
        "longitude": "-98.46980285644531",
        "altitude": "809",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ROC": {
        "name": "Greater Rochester International Airport",
        "city": "Rochester",
        "country": "United States",
        "iata": "ROC",
        "icao": "KROC",
        "latitude": "43.118900299072266",
        "longitude": "-77.67240142822266",
        "altitude": "559",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "COF": {
        "name": "Patrick Air Force Base",
        "city": "Coco Beach",
        "country": "United States",
        "iata": "COF",
        "icao": "KCOF",
        "latitude": "28.2348995209",
        "longitude": "-80.6100997925",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TEB": {
        "name": "Teterboro Airport",
        "city": "Teterboro",
        "country": "United States",
        "iata": "TEB",
        "icao": "KTEB",
        "latitude": "40.85010147089999",
        "longitude": "-74.060798645",
        "altitude": "9",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RCA": {
        "name": "Ellsworth Air Force Base",
        "city": "Rapid City",
        "country": "United States",
        "iata": "RCA",
        "icao": "KRCA",
        "latitude": "44.14500046",
        "longitude": "-103.1039963",
        "altitude": "3276",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "RDU": {
        "name": "Raleigh Durham International Airport",
        "city": "Raleigh-durham",
        "country": "United States",
        "iata": "RDU",
        "icao": "KRDU",
        "latitude": "35.877601623535156",
        "longitude": "-78.7874984741211",
        "altitude": "435",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DAY": {
        "name": "James M Cox Dayton International Airport",
        "city": "Dayton",
        "country": "United States",
        "iata": "DAY",
        "icao": "KDAY",
        "latitude": "39.902400970458984",
        "longitude": "-84.21939849853516",
        "altitude": "1009",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ENA": {
        "name": "Kenai Municipal Airport",
        "city": "Kenai",
        "country": "United States",
        "iata": "ENA",
        "icao": "PAEN",
        "latitude": "60.57310104370117",
        "longitude": "-151.2449951171875",
        "altitude": "99",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MLC": {
        "name": "Mc Alester Regional Airport",
        "city": "Mcalester",
        "country": "United States",
        "iata": "MLC",
        "icao": "KMLC",
        "latitude": "34.882401",
        "longitude": "-95.783501",
        "altitude": "770",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "IAG": {
        "name": "Niagara Falls International Airport",
        "city": "Niagara Falls",
        "country": "United States",
        "iata": "IAG",
        "icao": "KIAG",
        "latitude": "43.1072998046875",
        "longitude": "-78.94619750976562",
        "altitude": "589",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CFD": {
        "name": "Coulter Field",
        "city": "Bryan",
        "country": "United States",
        "iata": "CFD",
        "icao": "KCFD",
        "latitude": "30.715700149499998",
        "longitude": "-96.3313980103",
        "altitude": "367",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LIY": {
        "name": "Wright AAF (Fort Stewart)/Midcoast Regional Airport",
        "city": "Wright",
        "country": "United States",
        "iata": "LIY",
        "icao": "KLHW",
        "latitude": "31.889099",
        "longitude": "-81.562303",
        "altitude": "45",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PHF": {
        "name": "Newport News Williamsburg International Airport",
        "city": "Newport News",
        "country": "United States",
        "iata": "PHF",
        "icao": "KPHF",
        "latitude": "37.13190079",
        "longitude": "-76.49299622",
        "altitude": "42",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ESF": {
        "name": "Esler Regional Airport",
        "city": "Alexandria",
        "country": "United States",
        "iata": "ESF",
        "icao": "KESF",
        "latitude": "31.3948993683",
        "longitude": "-92.2957992554",
        "altitude": "112",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LTS": {
        "name": "Altus Air Force Base",
        "city": "Altus",
        "country": "United States",
        "iata": "LTS",
        "icao": "KLTS",
        "latitude": "34.667098999",
        "longitude": "-99.2667007446",
        "altitude": "1382",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TUS": {
        "name": "Tucson International Airport",
        "city": "Tucson",
        "country": "United States",
        "iata": "TUS",
        "icao": "KTUS",
        "latitude": "32.1161003112793",
        "longitude": "-110.94100189208984",
        "altitude": "2643",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "MIB": {
        "name": "Minot Air Force Base",
        "city": "Minot",
        "country": "United States",
        "iata": "MIB",
        "icao": "KMIB",
        "latitude": "48.4156",
        "longitude": "-101.358002",
        "altitude": "1667",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BAB": {
        "name": "Beale Air Force Base",
        "city": "Marysville",
        "country": "United States",
        "iata": "BAB",
        "icao": "KBAB",
        "latitude": "39.136100769",
        "longitude": "-121.43699646",
        "altitude": "113",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "IKK": {
        "name": "Greater Kankakee Airport",
        "city": "Kankakee",
        "country": "United States",
        "iata": "IKK",
        "icao": "KIKK",
        "latitude": "41.07139968869999",
        "longitude": "-87.8462982178",
        "altitude": "630",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GSB": {
        "name": "Seymour Johnson Air Force Base",
        "city": "Goldsboro",
        "country": "United States",
        "iata": "GSB",
        "icao": "KGSB",
        "latitude": "35.33940125",
        "longitude": "-77.96060181",
        "altitude": "109",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PVD": {
        "name": "Theodore Francis Green State Airport",
        "city": "Providence",
        "country": "United States",
        "iata": "PVD",
        "icao": "KPVD",
        "latitude": "41.732601",
        "longitude": "-71.420403",
        "altitude": "55",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SBY": {
        "name": "Salisbury Ocean City Wicomico Regional Airport",
        "city": "Salisbury",
        "country": "United States",
        "iata": "SBY",
        "icao": "KSBY",
        "latitude": "38.34049987792969",
        "longitude": "-75.51029968261719",
        "altitude": "52",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BUR": {
        "name": "Bob Hope Airport",
        "city": "Burbank",
        "country": "United States",
        "iata": "BUR",
        "icao": "KBUR",
        "latitude": "34.20069885253906",
        "longitude": "-118.35900115966797",
        "altitude": "778",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "DTW": {
        "name": "Detroit Metropolitan Wayne County Airport",
        "city": "Detroit",
        "country": "United States",
        "iata": "DTW",
        "icao": "KDTW",
        "latitude": "42.212398529052734",
        "longitude": "-83.35340118408203",
        "altitude": "645",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TPA": {
        "name": "Tampa International Airport",
        "city": "Tampa",
        "country": "United States",
        "iata": "TPA",
        "icao": "KTPA",
        "latitude": "27.975500106811523",
        "longitude": "-82.533203125",
        "altitude": "26",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PMB": {
        "name": "Pembina Municipal Airport",
        "city": "Pembina",
        "country": "United States",
        "iata": "PMB",
        "icao": "KPMB",
        "latitude": "48.9425010681",
        "longitude": "-97.2407989502",
        "altitude": "795",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "POE": {
        "name": "Polk Army Air Field",
        "city": "Fort Polk",
        "country": "United States",
        "iata": "POE",
        "icao": "KPOE",
        "latitude": "31.0447998",
        "longitude": "-93.1917038",
        "altitude": "330",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EIL": {
        "name": "Eielson Air Force Base",
        "city": "Fairbanks",
        "country": "United States",
        "iata": "EIL",
        "icao": "PAEI",
        "latitude": "64.66570282",
        "longitude": "-147.102005",
        "altitude": "547",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HIB": {
        "name": "Range Regional Airport",
        "city": "Hibbing",
        "country": "United States",
        "iata": "HIB",
        "icao": "KHIB",
        "latitude": "47.38660049",
        "longitude": "-92.83899689",
        "altitude": "1354",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LFK": {
        "name": "Angelina County Airport",
        "city": "Lufkin",
        "country": "United States",
        "iata": "LFK",
        "icao": "KLFK",
        "latitude": "31.2339992523",
        "longitude": "-94.75",
        "altitude": "296",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MAF": {
        "name": "Midland International Airport",
        "city": "Midland",
        "country": "United States",
        "iata": "MAF",
        "icao": "KMAF",
        "latitude": "31.9424991607666",
        "longitude": "-102.2020034790039",
        "altitude": "2871",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GRB": {
        "name": "Austin Straubel International Airport",
        "city": "Green Bay",
        "country": "United States",
        "iata": "GRB",
        "icao": "KGRB",
        "latitude": "44.48509979248047",
        "longitude": "-88.12960052490234",
        "altitude": "695",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ADM": {
        "name": "Ardmore Municipal Airport",
        "city": "Ardmore",
        "country": "United States",
        "iata": "ADM",
        "icao": "KADM",
        "latitude": "34.30301",
        "longitude": "-97.0196342",
        "altitude": "777",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "WRI": {
        "name": "Mc Guire Air Force Base",
        "city": "Wrightstown",
        "country": "United States",
        "iata": "WRI",
        "icao": "KWRI",
        "latitude": "40.0155983",
        "longitude": "-74.59169769",
        "altitude": "131",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AGS": {
        "name": "Augusta Regional At Bush Field",
        "city": "Bush Field",
        "country": "United States",
        "iata": "AGS",
        "icao": "KAGS",
        "latitude": "33.36989974975586",
        "longitude": "-81.9645004272461",
        "altitude": "144",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ISN": {
        "name": "Sloulin Field International Airport",
        "city": "Williston",
        "country": "United States",
        "iata": "ISN",
        "icao": "KISN",
        "latitude": "48.177898407",
        "longitude": "-103.641998291",
        "altitude": "1982",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LIT": {
        "name": "Bill & Hillary Clinton National Airport/Adams Field",
        "city": "Little Rock",
        "country": "United States",
        "iata": "LIT",
        "icao": "KLIT",
        "latitude": "34.729400634799994",
        "longitude": "-92.2242965698",
        "altitude": "262",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SWF": {
        "name": "Stewart International Airport",
        "city": "Newburgh",
        "country": "United States",
        "iata": "SWF",
        "icao": "KSWF",
        "latitude": "41.50410079956055",
        "longitude": "-74.10479736328125",
        "altitude": "491",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BDE": {
        "name": "Baudette International Airport",
        "city": "Baudette",
        "country": "United States",
        "iata": "BDE",
        "icao": "KBDE",
        "latitude": "48.7284011841",
        "longitude": "-94.612197876",
        "altitude": "1086",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SAC": {
        "name": "Sacramento Executive Airport",
        "city": "Sacramento",
        "country": "United States",
        "iata": "SAC",
        "icao": "KSAC",
        "latitude": "38.5125007629",
        "longitude": "-121.492996216",
        "altitude": "24",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HOM": {
        "name": "Homer Airport",
        "city": "Homer",
        "country": "United States",
        "iata": "HOM",
        "icao": "PAHO",
        "latitude": "59.645599365234375",
        "longitude": "-151.4770050048828",
        "altitude": "84",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TBN": {
        "name": "Waynesville-St. Robert Regional Forney field",
        "city": "Fort Leonardwood",
        "country": "United States",
        "iata": "TBN",
        "icao": "KTBN",
        "latitude": "37.74160004",
        "longitude": "-92.14070129",
        "altitude": "1159",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MGE": {
        "name": "Dobbins Air Reserve Base",
        "city": "Marietta",
        "country": "United States",
        "iata": "MGE",
        "icao": "KMGE",
        "latitude": "33.91540146",
        "longitude": "-84.51629639",
        "altitude": "1068",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SKA": {
        "name": "Fairchild Air Force Base",
        "city": "Spokane",
        "country": "United States",
        "iata": "SKA",
        "icao": "KSKA",
        "latitude": "47.6151008606",
        "longitude": "-117.65599823",
        "altitude": "2461",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HTL": {
        "name": "Roscommon County - Blodgett Memorial Airport",
        "city": "Houghton Lake",
        "country": "United States",
        "iata": "HTL",
        "icao": "KHTL",
        "latitude": "44.359798",
        "longitude": "-84.671095",
        "altitude": "1150",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PAM": {
        "name": "Tyndall Air Force Base",
        "city": "Panama City",
        "country": "United States",
        "iata": "PAM",
        "icao": "KPAM",
        "latitude": "30.0695991516",
        "longitude": "-85.57540130619999",
        "altitude": "17",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DFW": {
        "name": "Dallas Fort Worth International Airport",
        "city": "Dallas-Fort Worth",
        "country": "United States",
        "iata": "DFW",
        "icao": "KDFW",
        "latitude": "32.896801",
        "longitude": "-97.038002",
        "altitude": "607",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MLB": {
        "name": "Melbourne International Airport",
        "city": "Melbourne",
        "country": "United States",
        "iata": "MLB",
        "icao": "KMLB",
        "latitude": "28.102800369262695",
        "longitude": "-80.64530181884766",
        "altitude": "33",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TCM": {
        "name": "McChord Air Force Base",
        "city": "Tacoma",
        "country": "United States",
        "iata": "TCM",
        "icao": "KTCM",
        "latitude": "47.1376991272",
        "longitude": "-122.475997925",
        "altitude": "322",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "AUS": {
        "name": "Austin Bergstrom International Airport",
        "city": "Austin",
        "country": "United States",
        "iata": "AUS",
        "icao": "KAUS",
        "latitude": "30.194499969482422",
        "longitude": "-97.6698989868164",
        "altitude": "542",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LCK": {
        "name": "Rickenbacker International Airport",
        "city": "Columbus",
        "country": "United States",
        "iata": "LCK",
        "icao": "KLCK",
        "latitude": "39.813801",
        "longitude": "-82.927803",
        "altitude": "744",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MQT": {
        "name": "Sawyer International Airport",
        "city": "Gwinn",
        "country": "United States",
        "iata": "MQT",
        "icao": "KSAW",
        "latitude": "46.353599548300004",
        "longitude": "-87.395401001",
        "altitude": "1221",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TYS": {
        "name": "McGhee Tyson Airport",
        "city": "Knoxville",
        "country": "United States",
        "iata": "TYS",
        "icao": "KTYS",
        "latitude": "35.81100082",
        "longitude": "-83.9940033",
        "altitude": "981",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HLR": {
        "name": "Hood Army Air Field",
        "city": "Fort Hood",
        "country": "United States",
        "iata": "HLR",
        "icao": "KHLR",
        "latitude": "31.138700485199998",
        "longitude": "-97.71450042720001",
        "altitude": "924",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "STL": {
        "name": "St Louis Lambert International Airport",
        "city": "St. Louis",
        "country": "United States",
        "iata": "STL",
        "icao": "KSTL",
        "latitude": "38.748697",
        "longitude": "-90.370003",
        "altitude": "618",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MIV": {
        "name": "Millville Municipal Airport",
        "city": "Millville",
        "country": "United States",
        "iata": "MIV",
        "icao": "KMIV",
        "latitude": "39.367802",
        "longitude": "-75.072197",
        "altitude": "85",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SPS": {
        "name": "Sheppard Air Force Base-Wichita Falls Municipal Airport",
        "city": "Wichita Falls",
        "country": "United States",
        "iata": "SPS",
        "icao": "KSPS",
        "latitude": "33.9888",
        "longitude": "-98.491898",
        "altitude": "1019",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LUK": {
        "name": "Cincinnati Municipal Airport Lunken Field",
        "city": "Cincinnati",
        "country": "United States",
        "iata": "LUK",
        "icao": "KLUK",
        "latitude": "39.10329819",
        "longitude": "-84.41860199",
        "altitude": "483",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ATL": {
        "name": "Hartsfield Jackson Atlanta International Airport",
        "city": "Atlanta",
        "country": "United States",
        "iata": "ATL",
        "icao": "KATL",
        "latitude": "33.6367",
        "longitude": "-84.428101",
        "altitude": "1026",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MER": {
        "name": "Castle Airport",
        "city": "Merced",
        "country": "United States",
        "iata": "MER",
        "icao": "KMER",
        "latitude": "37.38050079",
        "longitude": "-120.5680008",
        "altitude": "191",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MCC": {
        "name": "Mc Clellan Airfield",
        "city": "Sacramento",
        "country": "United States",
        "iata": "MCC",
        "icao": "KMCC",
        "latitude": "38.66759872",
        "longitude": "-121.401001",
        "altitude": "77",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GRR": {
        "name": "Gerald R. Ford International Airport",
        "city": "Grand Rapids",
        "country": "United States",
        "iata": "GRR",
        "icao": "KGRR",
        "latitude": "42.88079834",
        "longitude": "-85.52279663",
        "altitude": "794",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "INK": {
        "name": "Winkler County Airport",
        "city": "Wink",
        "country": "United States",
        "iata": "INK",
        "icao": "KINK",
        "latitude": "31.779600143399996",
        "longitude": "-103.200996399",
        "altitude": "2822",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FAT": {
        "name": "Fresno Yosemite International Airport",
        "city": "Fresno",
        "country": "United States",
        "iata": "FAT",
        "icao": "KFAT",
        "latitude": "36.77619934082031",
        "longitude": "-119.71800231933594",
        "altitude": "336",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "VRB": {
        "name": "Vero Beach Regional Airport",
        "city": "Vero Beach",
        "country": "United States",
        "iata": "VRB",
        "icao": "KVRB",
        "latitude": "27.6556",
        "longitude": "-80.417901",
        "altitude": "24",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "IPL": {
        "name": "Imperial County Airport",
        "city": "Imperial",
        "country": "United States",
        "iata": "IPL",
        "icao": "KIPL",
        "latitude": "32.834201812699995",
        "longitude": "-115.57900238",
        "altitude": "-54",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BNA": {
        "name": "Nashville International Airport",
        "city": "Nashville",
        "country": "United States",
        "iata": "BNA",
        "icao": "KBNA",
        "latitude": "36.1245002746582",
        "longitude": "-86.6781997680664",
        "altitude": "599",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LRD": {
        "name": "Laredo International Airport",
        "city": "Laredo",
        "country": "United States",
        "iata": "LRD",
        "icao": "KLRD",
        "latitude": "27.543800354003906",
        "longitude": "-99.46160125732422",
        "altitude": "508",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EDF": {
        "name": "Elmendorf Air Force Base",
        "city": "Anchorage",
        "country": "United States",
        "iata": "EDF",
        "icao": "PAED",
        "latitude": "61.250999450683594",
        "longitude": "-149.8070068359375",
        "altitude": "212",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OTZ": {
        "name": "Ralph Wien Memorial Airport",
        "city": "Kotzebue",
        "country": "United States",
        "iata": "OTZ",
        "icao": "PAOT",
        "latitude": "66.88469696",
        "longitude": "-162.598999",
        "altitude": "14",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "AOO": {
        "name": "Altoona Blair County Airport",
        "city": "Altoona",
        "country": "United States",
        "iata": "AOO",
        "icao": "KAOO",
        "latitude": "40.29639816",
        "longitude": "-78.31999969",
        "altitude": "1503",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DYS": {
        "name": "Dyess Air Force Base",
        "city": "Abilene",
        "country": "United States",
        "iata": "DYS",
        "icao": "KDYS",
        "latitude": "32.4207992554",
        "longitude": "-99.854598999",
        "altitude": "1789",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ELD": {
        "name": "South Arkansas Regional At Goodwin Field",
        "city": "El Dorado",
        "country": "United States",
        "iata": "ELD",
        "icao": "KELD",
        "latitude": "33.22100067138672",
        "longitude": "-92.81330108642578",
        "altitude": "277",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LGA": {
        "name": "La Guardia Airport",
        "city": "New York",
        "country": "United States",
        "iata": "LGA",
        "icao": "KLGA",
        "latitude": "40.77719879",
        "longitude": "-73.87259674",
        "altitude": "21",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TLH": {
        "name": "Tallahassee Regional Airport",
        "city": "Tallahassee",
        "country": "United States",
        "iata": "TLH",
        "icao": "KTLH",
        "latitude": "30.396499633789062",
        "longitude": "-84.35030364990234",
        "altitude": "81",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DPA": {
        "name": "Dupage Airport",
        "city": "West Chicago",
        "country": "United States",
        "iata": "DPA",
        "icao": "KDPA",
        "latitude": "41.90779877",
        "longitude": "-88.24859619",
        "altitude": "759",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ACT": {
        "name": "Waco Regional Airport",
        "city": "Waco",
        "country": "United States",
        "iata": "ACT",
        "icao": "KACT",
        "latitude": "31.611299514770508",
        "longitude": "-97.23049926757812",
        "altitude": "516",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AUG": {
        "name": "Augusta State Airport",
        "city": "Augusta",
        "country": "United States",
        "iata": "AUG",
        "icao": "KAUG",
        "latitude": "44.320598602299995",
        "longitude": "-69.7973022461",
        "altitude": "352",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NIP": {
        "name": "Jacksonville Naval Air Station (Towers Field)",
        "city": "Jacksonville",
        "country": "United States",
        "iata": "NIP",
        "icao": "KNIP",
        "latitude": "30.2358",
        "longitude": "-81.680603",
        "altitude": "21",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MKL": {
        "name": "McKellar-Sipes Regional Airport",
        "city": "Jackson",
        "country": "United States",
        "iata": "MKL",
        "icao": "KMKL",
        "latitude": "35.599899",
        "longitude": "-88.915604",
        "altitude": "434",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MKK": {
        "name": "Molokai Airport",
        "city": "Molokai",
        "country": "United States",
        "iata": "MKK",
        "icao": "PHMK",
        "latitude": "21.15290069580078",
        "longitude": "-157.0959930419922",
        "altitude": "454",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "FTK": {
        "name": "Godman Army Air Field",
        "city": "Fort Knox",
        "country": "United States",
        "iata": "FTK",
        "icao": "KFTK",
        "latitude": "37.907100677500004",
        "longitude": "-85.9720993042",
        "altitude": "756",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SJT": {
        "name": "San Angelo Regional Mathis Field",
        "city": "San Angelo",
        "country": "United States",
        "iata": "SJT",
        "icao": "KSJT",
        "latitude": "31.35770034790039",
        "longitude": "-100.49600219726562",
        "altitude": "1919",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CXL": {
        "name": "Calexico International Airport",
        "city": "Calexico",
        "country": "United States",
        "iata": "CXL",
        "icao": "KCXL",
        "latitude": "32.6694984436",
        "longitude": "-115.513000488",
        "altitude": "4",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CIC": {
        "name": "Chico Municipal Airport",
        "city": "Chico",
        "country": "United States",
        "iata": "CIC",
        "icao": "KCIC",
        "latitude": "39.79539871",
        "longitude": "-121.8580017",
        "altitude": "240",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BTV": {
        "name": "Burlington International Airport",
        "city": "Burlington",
        "country": "United States",
        "iata": "BTV",
        "icao": "KBTV",
        "latitude": "44.471900939899996",
        "longitude": "-73.15329742429999",
        "altitude": "335",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JAX": {
        "name": "Jacksonville International Airport",
        "city": "Jacksonville",
        "country": "United States",
        "iata": "JAX",
        "icao": "KJAX",
        "latitude": "30.49410057067871",
        "longitude": "-81.68789672851562",
        "altitude": "30",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DRO": {
        "name": "Durango La Plata County Airport",
        "city": "Durango",
        "country": "United States",
        "iata": "DRO",
        "icao": "KDRO",
        "latitude": "37.1515007019",
        "longitude": "-107.753997803",
        "altitude": "6685",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "IAD": {
        "name": "Washington Dulles International Airport",
        "city": "Washington",
        "country": "United States",
        "iata": "IAD",
        "icao": "KIAD",
        "latitude": "38.94449997",
        "longitude": "-77.45580292",
        "altitude": "312",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CLL": {
        "name": "Easterwood Field",
        "city": "College Station",
        "country": "United States",
        "iata": "CLL",
        "icao": "KCLL",
        "latitude": "30.58860016",
        "longitude": "-96.36380005",
        "altitude": "320",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SFF": {
        "name": "Felts Field",
        "city": "Spokane",
        "country": "United States",
        "iata": "SFF",
        "icao": "KSFF",
        "latitude": "47.682800292969",
        "longitude": "-117.32299804688",
        "altitude": "1953",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MKE": {
        "name": "General Mitchell International Airport",
        "city": "Milwaukee",
        "country": "United States",
        "iata": "MKE",
        "icao": "KMKE",
        "latitude": "42.947200775146484",
        "longitude": "-87.89659881591797",
        "altitude": "723",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ABI": {
        "name": "Abilene Regional Airport",
        "city": "Abilene",
        "country": "United States",
        "iata": "ABI",
        "icao": "KABI",
        "latitude": "32.4113006592",
        "longitude": "-99.68190002440001",
        "altitude": "1791",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "COU": {
        "name": "Columbia Regional Airport",
        "city": "Columbia",
        "country": "United States",
        "iata": "COU",
        "icao": "KCOU",
        "latitude": "38.81809997558594",
        "longitude": "-92.21959686279297",
        "altitude": "889",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PDX": {
        "name": "Portland International Airport",
        "city": "Portland",
        "country": "United States",
        "iata": "PDX",
        "icao": "KPDX",
        "latitude": "45.58869934",
        "longitude": "-122.5979996",
        "altitude": "31",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "TNT": {
        "name": "Dade Collier Training and Transition Airport",
        "city": "Miami",
        "country": "United States",
        "iata": "TNT",
        "icao": "KTNT",
        "latitude": "25.861799240112",
        "longitude": "-80.897003173828",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PBI": {
        "name": "Palm Beach International Airport",
        "city": "West Palm Beach",
        "country": "United States",
        "iata": "PBI",
        "icao": "KPBI",
        "latitude": "26.68320083618164",
        "longitude": "-80.09559631347656",
        "altitude": "19",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FTW": {
        "name": "Fort Worth Meacham International Airport",
        "city": "Fort Worth",
        "country": "United States",
        "iata": "FTW",
        "icao": "KFTW",
        "latitude": "32.819801",
        "longitude": "-97.362396",
        "altitude": "710",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OGS": {
        "name": "Ogdensburg International Airport",
        "city": "Ogdensburg",
        "country": "United States",
        "iata": "OGS",
        "icao": "KOGS",
        "latitude": "44.6819000244",
        "longitude": "-75.46549987790002",
        "altitude": "297",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FMH": {
        "name": "Cape Cod Coast Guard Air Station",
        "city": "Falmouth",
        "country": "United States",
        "iata": "FMH",
        "icao": "KFMH",
        "latitude": "41.6584014893",
        "longitude": "-70.5214004517",
        "altitude": "131",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BFI": {
        "name": "Boeing Field King County International Airport",
        "city": "Seattle",
        "country": "United States",
        "iata": "BFI",
        "icao": "KBFI",
        "latitude": "47.529998779296875",
        "longitude": "-122.302001953125",
        "altitude": "21",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SKF": {
        "name": "Lackland Air Force Base",
        "city": "San Antonio",
        "country": "United States",
        "iata": "SKF",
        "icao": "KSKF",
        "latitude": "29.38419914",
        "longitude": "-98.58110046",
        "altitude": "691",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HNL": {
        "name": "Daniel K Inouye International Airport",
        "city": "Honolulu",
        "country": "United States",
        "iata": "HNL",
        "icao": "PHNL",
        "latitude": "21.32062",
        "longitude": "-157.924228",
        "altitude": "13",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "DSM": {
        "name": "Des Moines International Airport",
        "city": "Des Moines",
        "country": "United States",
        "iata": "DSM",
        "icao": "KDSM",
        "latitude": "41.534000396728516",
        "longitude": "-93.66310119628906",
        "altitude": "958",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EWN": {
        "name": "Coastal Carolina Regional Airport",
        "city": "New Bern",
        "country": "United States",
        "iata": "EWN",
        "icao": "KEWN",
        "latitude": "35.0730018616",
        "longitude": "-77.04290008539999",
        "altitude": "18",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SAN": {
        "name": "San Diego International Airport",
        "city": "San Diego",
        "country": "United States",
        "iata": "SAN",
        "icao": "KSAN",
        "latitude": "32.7336006165",
        "longitude": "-117.190002441",
        "altitude": "17",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MLU": {
        "name": "Monroe Regional Airport",
        "city": "Monroe",
        "country": "United States",
        "iata": "MLU",
        "icao": "KMLU",
        "latitude": "32.51089859008789",
        "longitude": "-92.0376968383789",
        "altitude": "79",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SSC": {
        "name": "Shaw Air Force Base",
        "city": "Sumter",
        "country": "United States",
        "iata": "SSC",
        "icao": "KSSC",
        "latitude": "33.97269821",
        "longitude": "-80.47059631",
        "altitude": "241",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ONT": {
        "name": "Ontario International Airport",
        "city": "Ontario",
        "country": "United States",
        "iata": "ONT",
        "icao": "KONT",
        "latitude": "34.055999755859375",
        "longitude": "-117.60099792480469",
        "altitude": "944",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GVT": {
        "name": "Majors Airport",
        "city": "Greenvile",
        "country": "United States",
        "iata": "GVT",
        "icao": "KGVT",
        "latitude": "33.0677986145",
        "longitude": "-96.0652999878",
        "altitude": "535",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ROW": {
        "name": "Roswell International Air Center Airport",
        "city": "Roswell",
        "country": "United States",
        "iata": "ROW",
        "icao": "KROW",
        "latitude": "33.30160140991211",
        "longitude": "-104.53099822998047",
        "altitude": "3671",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "DET": {
        "name": "Coleman A. Young Municipal Airport",
        "city": "Detroit",
        "country": "United States",
        "iata": "DET",
        "icao": "KDET",
        "latitude": "42.40919876",
        "longitude": "-83.00990295",
        "altitude": "626",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BRO": {
        "name": "Brownsville South Padre Island International Airport",
        "city": "Brownsville",
        "country": "United States",
        "iata": "BRO",
        "icao": "KBRO",
        "latitude": "25.90679931640625",
        "longitude": "-97.4259033203125",
        "altitude": "22",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DHN": {
        "name": "Dothan Regional Airport",
        "city": "Dothan",
        "country": "United States",
        "iata": "DHN",
        "icao": "KDHN",
        "latitude": "31.321300506591797",
        "longitude": "-85.44960021972656",
        "altitude": "401",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "WWD": {
        "name": "Cape May County Airport",
        "city": "Wildwood",
        "country": "United States",
        "iata": "WWD",
        "icao": "KWWD",
        "latitude": "39.008499145500004",
        "longitude": "-74.9083023071",
        "altitude": "23",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NFL": {
        "name": "Fallon Naval Air Station",
        "city": "Fallon",
        "country": "United States",
        "iata": "NFL",
        "icao": "KNFL",
        "latitude": "39.41659927",
        "longitude": "-118.7009964",
        "altitude": "3934",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MTC": {
        "name": "Selfridge Air National Guard Base Airport",
        "city": "Mount Clemens",
        "country": "United States",
        "iata": "MTC",
        "icao": "KMTC",
        "latitude": "42.613463",
        "longitude": "-82.836919",
        "altitude": "580",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FMN": {
        "name": "Four Corners Regional Airport",
        "city": "Farmington",
        "country": "United States",
        "iata": "FMN",
        "icao": "KFMN",
        "latitude": "36.741199493399996",
        "longitude": "-108.230003357",
        "altitude": "5506",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CRP": {
        "name": "Corpus Christi International Airport",
        "city": "Corpus Christi",
        "country": "United States",
        "iata": "CRP",
        "icao": "KCRP",
        "latitude": "27.77039909362793",
        "longitude": "-97.5011978149414",
        "altitude": "44",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SYR": {
        "name": "Syracuse Hancock International Airport",
        "city": "Syracuse",
        "country": "United States",
        "iata": "SYR",
        "icao": "KSYR",
        "latitude": "43.11119842529297",
        "longitude": "-76.1063003540039",
        "altitude": "421",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NQX": {
        "name": "Naval Air Station Key West/Boca Chica Field",
        "city": "Key West",
        "country": "United States",
        "iata": "NQX",
        "icao": "KNQX",
        "latitude": "24.57579994",
        "longitude": "-81.68890381",
        "altitude": "6",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MDW": {
        "name": "Chicago Midway International Airport",
        "city": "Chicago",
        "country": "United States",
        "iata": "MDW",
        "icao": "KMDW",
        "latitude": "41.785999",
        "longitude": "-87.752403",
        "altitude": "620",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SJC": {
        "name": "Norman Y. Mineta San Jose International Airport",
        "city": "San Jose",
        "country": "United States",
        "iata": "SJC",
        "icao": "KSJC",
        "latitude": "37.362598",
        "longitude": "-121.929001",
        "altitude": "62",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HOB": {
        "name": "Lea County Regional Airport",
        "city": "Hobbs",
        "country": "United States",
        "iata": "HOB",
        "icao": "KHOB",
        "latitude": "32.6875",
        "longitude": "-103.2170029",
        "altitude": "3661",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PNE": {
        "name": "Northeast Philadelphia Airport",
        "city": "Philadelphia",
        "country": "United States",
        "iata": "PNE",
        "icao": "KPNE",
        "latitude": "40.081902",
        "longitude": "-75.010597",
        "altitude": "120",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DEN": {
        "name": "Denver International Airport",
        "city": "Denver",
        "country": "United States",
        "iata": "DEN",
        "icao": "KDEN",
        "latitude": "39.861698150635",
        "longitude": "-104.672996521",
        "altitude": "5431",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PHL": {
        "name": "Philadelphia International Airport",
        "city": "Philadelphia",
        "country": "United States",
        "iata": "PHL",
        "icao": "KPHL",
        "latitude": "39.87189865112305",
        "longitude": "-75.24109649658203",
        "altitude": "36",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SUX": {
        "name": "Sioux Gateway Col. Bud Day Field",
        "city": "Sioux City",
        "country": "United States",
        "iata": "SUX",
        "icao": "KSUX",
        "latitude": "42.40259933",
        "longitude": "-96.38439941",
        "altitude": "1098",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MCN": {
        "name": "Middle Georgia Regional Airport",
        "city": "Macon",
        "country": "United States",
        "iata": "MCN",
        "icao": "KMCN",
        "latitude": "32.69279861450195",
        "longitude": "-83.64920043945312",
        "altitude": "354",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TCS": {
        "name": "Truth Or Consequences Municipal Airport",
        "city": "Truth Or Consequences",
        "country": "United States",
        "iata": "TCS",
        "icao": "KTCS",
        "latitude": "33.2369003296",
        "longitude": "-107.272003174",
        "altitude": "4853",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PMD": {
        "name": "Palmdale Regional/USAF Plant 42 Airport",
        "city": "Palmdale",
        "country": "United States",
        "iata": "PMD",
        "icao": "KPMD",
        "latitude": "34.62939835",
        "longitude": "-118.0849991",
        "altitude": "2543",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RND": {
        "name": "Randolph Air Force Base",
        "city": "San Antonio",
        "country": "United States",
        "iata": "RND",
        "icao": "KRND",
        "latitude": "29.52969933",
        "longitude": "-98.27890015",
        "altitude": "761",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NJK": {
        "name": "El Centro NAF Airport (Vraciu Field)",
        "city": "El Centro",
        "country": "United States",
        "iata": "NJK",
        "icao": "KNJK",
        "latitude": "32.829201",
        "longitude": "-115.671996",
        "altitude": "-42",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CMH": {
        "name": "John Glenn Columbus International Airport",
        "city": "Columbus",
        "country": "United States",
        "iata": "CMH",
        "icao": "KCMH",
        "latitude": "39.998001",
        "longitude": "-82.891899",
        "altitude": "815",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FYV": {
        "name": "Drake Field",
        "city": "Fayetteville",
        "country": "United States",
        "iata": "FYV",
        "icao": "KFYV",
        "latitude": "36.00510025024414",
        "longitude": "-94.17009735107422",
        "altitude": "1251",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FSI": {
        "name": "Henry Post Army Air Field (Fort Sill)",
        "city": "Fort Sill",
        "country": "United States",
        "iata": "FSI",
        "icao": "KFSI",
        "latitude": "34.64979935",
        "longitude": "-98.40219879",
        "altitude": "1189",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FFO": {
        "name": "Wright-Patterson Air Force Base",
        "city": "Dayton",
        "country": "United States",
        "iata": "FFO",
        "icao": "KFFO",
        "latitude": "39.8260993958",
        "longitude": "-84.0483016968",
        "altitude": "823",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GAL": {
        "name": "Edward G. Pitka Sr Airport",
        "city": "Galena",
        "country": "United States",
        "iata": "GAL",
        "icao": "PAGA",
        "latitude": "64.73619843",
        "longitude": "-156.9369965",
        "altitude": "153",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MWL": {
        "name": "Mineral Wells Airport",
        "city": "Mineral Wells",
        "country": "United States",
        "iata": "MWL",
        "icao": "KMWL",
        "latitude": "32.7816009521",
        "longitude": "-98.0602035522",
        "altitude": "974",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "IAB": {
        "name": "Mc Connell Air Force Base",
        "city": "Wichita",
        "country": "United States",
        "iata": "IAB",
        "icao": "KIAB",
        "latitude": "37.62189865",
        "longitude": "-97.26820374",
        "altitude": "1371",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NBG": {
        "name": "New Orleans NAS JRB/Alvin Callender Field",
        "city": "New Orleans",
        "country": "United States",
        "iata": "NBG",
        "icao": "KNBG",
        "latitude": "29.82530022",
        "longitude": "-90.03500366",
        "altitude": "2",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BFT": {
        "name": "Beaufort County Airport",
        "city": "Beaufort",
        "country": "United States",
        "iata": "BFT",
        "icao": "KARW",
        "latitude": "32.4122009277",
        "longitude": "-80.6343994141",
        "altitude": "10",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TXK": {
        "name": "Texarkana Regional Webb Field",
        "city": "Texarkana",
        "country": "United States",
        "iata": "TXK",
        "icao": "KTXK",
        "latitude": "33.45370101928711",
        "longitude": "-93.99099731445312",
        "altitude": "390",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PBG": {
        "name": "Plattsburgh International Airport",
        "city": "Plattsburgh",
        "country": "United States",
        "iata": "PBG",
        "icao": "KPBG",
        "latitude": "44.650901794433594",
        "longitude": "-73.46810150146484",
        "altitude": "234",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "APG": {
        "name": "Phillips Army Air Field",
        "city": "Aberdeen",
        "country": "United States",
        "iata": "APG",
        "icao": "KAPG",
        "latitude": "39.466202",
        "longitude": "-76.1688",
        "altitude": "57",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TCC": {
        "name": "Tucumcari Municipal Airport",
        "city": "Tucumcari",
        "country": "United States",
        "iata": "TCC",
        "icao": "KTCC",
        "latitude": "35.182800293",
        "longitude": "-103.602996826",
        "altitude": "4065",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ANC": {
        "name": "Ted Stevens Anchorage International Airport",
        "city": "Anchorage",
        "country": "United States",
        "iata": "ANC",
        "icao": "PANC",
        "latitude": "61.174400329589844",
        "longitude": "-149.99600219726562",
        "altitude": "152",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "GRK": {
        "name": "Robert Gray  Army Air Field Airport",
        "city": "Killeen",
        "country": "United States",
        "iata": "GRK",
        "icao": "KGRK",
        "latitude": "31.067199707",
        "longitude": "-97.82890319820001",
        "altitude": "1015",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BLI": {
        "name": "Bellingham International Airport",
        "city": "Bellingham",
        "country": "United States",
        "iata": "BLI",
        "icao": "KBLI",
        "latitude": "48.79280090332031",
        "longitude": "-122.53800201416016",
        "altitude": "170",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "NQA": {
        "name": "Millington-Memphis Airport",
        "city": "Millington",
        "country": "United States",
        "iata": "NQA",
        "icao": "KNQA",
        "latitude": "35.356701",
        "longitude": "-89.8703",
        "altitude": "320",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EKN": {
        "name": "Elkins-Randolph Co-Jennings Randolph Field",
        "city": "Elkins",
        "country": "United States",
        "iata": "EKN",
        "icao": "KEKN",
        "latitude": "38.88940048",
        "longitude": "-79.85710144",
        "altitude": "1987",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HFD": {
        "name": "Hartford Brainard Airport",
        "city": "Hartford",
        "country": "United States",
        "iata": "HFD",
        "icao": "KHFD",
        "latitude": "41.736698150635",
        "longitude": "-72.649398803711",
        "altitude": "18",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SFZ": {
        "name": "North Central State Airport",
        "city": "Smithfield",
        "country": "United States",
        "iata": "SFZ",
        "icao": "KSFZ",
        "latitude": "41.9207992554",
        "longitude": "-71.49140167239999",
        "altitude": "441",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MOB": {
        "name": "Mobile Regional Airport",
        "city": "Mobile",
        "country": "United States",
        "iata": "MOB",
        "icao": "KMOB",
        "latitude": "30.691200256348",
        "longitude": "-88.242797851562",
        "altitude": "219",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NUQ": {
        "name": "Moffett Federal Airfield",
        "city": "Mountain View",
        "country": "United States",
        "iata": "NUQ",
        "icao": "KNUQ",
        "latitude": "37.4161",
        "longitude": "-122.049004",
        "altitude": "32",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SAF": {
        "name": "Santa Fe Municipal Airport",
        "city": "Santa Fe",
        "country": "United States",
        "iata": "SAF",
        "icao": "KSAF",
        "latitude": "35.617099762",
        "longitude": "-106.088996887",
        "altitude": "6348",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BKH": {
        "name": "Barking Sands Airport",
        "city": "Barking Sands",
        "country": "United States",
        "iata": "BKH",
        "icao": "PHBK",
        "latitude": "22.022800445599998",
        "longitude": "-159.785003662",
        "altitude": "23",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "DRI": {
        "name": "Beauregard Regional Airport",
        "city": "Deridder",
        "country": "United States",
        "iata": "DRI",
        "icao": "KDRI",
        "latitude": "30.8316993713",
        "longitude": "-93.33989715579999",
        "altitude": "202",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BSF": {
        "name": "Bradshaw Army Airfield",
        "city": "Bradshaw Field",
        "country": "United States",
        "iata": "BSF",
        "icao": "PHSF",
        "latitude": "19.760099411",
        "longitude": "-155.554000854",
        "altitude": "6190",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "OLS": {
        "name": "Nogales International Airport",
        "city": "Nogales",
        "country": "United States",
        "iata": "OLS",
        "icao": "KOLS",
        "latitude": "31.4177",
        "longitude": "-110.848",
        "altitude": "3955",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "MCF": {
        "name": "Mac Dill Air Force Base",
        "city": "Tampa",
        "country": "United States",
        "iata": "MCF",
        "icao": "KMCF",
        "latitude": "27.84930038",
        "longitude": "-82.52120209",
        "altitude": "14",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BLV": {
        "name": "Scott AFB/Midamerica Airport",
        "city": "Belleville",
        "country": "United States",
        "iata": "BLV",
        "icao": "KBLV",
        "latitude": "38.5452",
        "longitude": "-89.835197",
        "altitude": "459",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OPF": {
        "name": "Opa-locka Executive Airport",
        "city": "Miami",
        "country": "United States",
        "iata": "OPF",
        "icao": "KOPF",
        "latitude": "25.907",
        "longitude": "-80.278397",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DRT": {
        "name": "Del Rio International Airport",
        "city": "Del Rio",
        "country": "United States",
        "iata": "DRT",
        "icao": "KDRT",
        "latitude": "29.3742008209",
        "longitude": "-100.927001953",
        "altitude": "1002",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RSW": {
        "name": "Southwest Florida International Airport",
        "city": "Fort Myers",
        "country": "United States",
        "iata": "RSW",
        "icao": "KRSW",
        "latitude": "26.53619956970215",
        "longitude": "-81.75520324707031",
        "altitude": "30",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AKN": {
        "name": "King Salmon Airport",
        "city": "King Salmon",
        "country": "United States",
        "iata": "AKN",
        "icao": "PAKN",
        "latitude": "58.67679977",
        "longitude": "-156.6490021",
        "altitude": "73",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MUI": {
        "name": "Muir Army Air Field (Fort Indiantown Gap) Airport",
        "city": "Muir",
        "country": "United States",
        "iata": "MUI",
        "icao": "KMUI",
        "latitude": "40.434799",
        "longitude": "-76.569397",
        "altitude": "488",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JHM": {
        "name": "Kapalua Airport",
        "city": "Lahania-kapalua",
        "country": "United States",
        "iata": "JHM",
        "icao": "PHJH",
        "latitude": "20.962900161743164",
        "longitude": "-156.67300415039062",
        "altitude": "256",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "JFK": {
        "name": "John F Kennedy International Airport",
        "city": "New York",
        "country": "United States",
        "iata": "JFK",
        "icao": "KJFK",
        "latitude": "40.63980103",
        "longitude": "-73.77890015",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HST": {
        "name": "Homestead ARB Airport",
        "city": "Homestead",
        "country": "United States",
        "iata": "HST",
        "icao": "KHST",
        "latitude": "25.48859978",
        "longitude": "-80.38359833",
        "altitude": "5",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RAL": {
        "name": "Riverside Municipal Airport",
        "city": "Riverside",
        "country": "United States",
        "iata": "RAL",
        "icao": "KRAL",
        "latitude": "33.95190048",
        "longitude": "-117.4449997",
        "altitude": "819",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "FLV": {
        "name": "Sherman Army Air Field",
        "city": "Fort Leavenworth",
        "country": "United States",
        "iata": "FLV",
        "icao": "KFLV",
        "latitude": "39.3683013916",
        "longitude": "-94.9147033691",
        "altitude": "772",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "WAL": {
        "name": "Wallops Flight Facility Airport",
        "city": "Wallops Island",
        "country": "United States",
        "iata": "WAL",
        "icao": "KWAL",
        "latitude": "37.9402008057",
        "longitude": "-75.4664001465",
        "altitude": "40",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HMN": {
        "name": "Holloman Air Force Base",
        "city": "Alamogordo",
        "country": "United States",
        "iata": "HMN",
        "icao": "KHMN",
        "latitude": "32.8525009155",
        "longitude": "-106.107002258",
        "altitude": "4093",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "NXX": {
        "name": "Willow Grove Naval Air Station/Joint Reserve Base",
        "city": "Willow Grove",
        "country": "United States",
        "iata": "NXX",
        "icao": "KNXX",
        "latitude": "40.19979858",
        "longitude": "-75.14820099",
        "altitude": "358",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CYS": {
        "name": "Cheyenne Regional Jerry Olson Field",
        "city": "Cheyenne",
        "country": "United States",
        "iata": "CYS",
        "icao": "KCYS",
        "latitude": "41.15570068",
        "longitude": "-104.8119965",
        "altitude": "6159",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SCK": {
        "name": "Stockton Metropolitan Airport",
        "city": "Stockton",
        "country": "United States",
        "iata": "SCK",
        "icao": "KSCK",
        "latitude": "37.894199371338",
        "longitude": "-121.2379989624",
        "altitude": "33",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CHS": {
        "name": "Charleston Air Force Base-International Airport",
        "city": "Charleston",
        "country": "United States",
        "iata": "CHS",
        "icao": "KCHS",
        "latitude": "32.89860153",
        "longitude": "-80.04049683",
        "altitude": "46",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RNO": {
        "name": "Reno Tahoe International Airport",
        "city": "Reno",
        "country": "United States",
        "iata": "RNO",
        "icao": "KRNO",
        "latitude": "39.49909973144531",
        "longitude": "-119.76799774169922",
        "altitude": "4415",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "KTN": {
        "name": "Ketchikan International Airport",
        "city": "Ketchikan",
        "country": "United States",
        "iata": "KTN",
        "icao": "PAKT",
        "latitude": "55.35559845",
        "longitude": "-131.7140045",
        "altitude": "89",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "YIP": {
        "name": "Willow Run Airport",
        "city": "Detroit",
        "country": "United States",
        "iata": "YIP",
        "icao": "KYIP",
        "latitude": "42.23789978",
        "longitude": "-83.53040314",
        "altitude": "716",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "VBG": {
        "name": "Vandenberg Air Force Base",
        "city": "Lompoc",
        "country": "United States",
        "iata": "VBG",
        "icao": "KVBG",
        "latitude": "34.7373008728",
        "longitude": "-120.583999634",
        "altitude": "369",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BHM": {
        "name": "Birmingham-Shuttlesworth International Airport",
        "city": "Birmingham",
        "country": "United States",
        "iata": "BHM",
        "icao": "KBHM",
        "latitude": "33.56290054",
        "longitude": "-86.75350189",
        "altitude": "650",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NEL": {
        "name": "Lakehurst Maxfield Field Airport",
        "city": "Lakehurst",
        "country": "United States",
        "iata": "NEL",
        "icao": "KNEL",
        "latitude": "40.03329849",
        "longitude": "-74.353302",
        "altitude": "101",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SYA": {
        "name": "Eareckson Air Station",
        "city": "Shemya",
        "country": "United States",
        "iata": "SYA",
        "icao": "PASY",
        "latitude": "52.71229935",
        "longitude": "174.1139984",
        "altitude": "95",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "America/Adak"
    },
    "LSV": {
        "name": "Nellis Air Force Base",
        "city": "Las Vegas",
        "country": "United States",
        "iata": "LSV",
        "icao": "KLSV",
        "latitude": "36.2361984253",
        "longitude": "-115.033996582",
        "altitude": "1870",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RIV": {
        "name": "March ARB Airport",
        "city": "Riverside",
        "country": "United States",
        "iata": "RIV",
        "icao": "KRIV",
        "latitude": "33.880699",
        "longitude": "-117.259003",
        "altitude": "1536",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MOD": {
        "name": "Modesto City Co-Harry Sham Field",
        "city": "Modesto",
        "country": "United States",
        "iata": "MOD",
        "icao": "KMOD",
        "latitude": "37.62580109",
        "longitude": "-120.9540024",
        "altitude": "97",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SMF": {
        "name": "Sacramento International Airport",
        "city": "Sacramento",
        "country": "United States",
        "iata": "SMF",
        "icao": "KSMF",
        "latitude": "38.69540023803711",
        "longitude": "-121.59100341796875",
        "altitude": "27",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "UGN": {
        "name": "Waukegan National Airport",
        "city": "Chicago",
        "country": "United States",
        "iata": "UGN",
        "icao": "KUGN",
        "latitude": "42.422199249268",
        "longitude": "-87.867897033691",
        "altitude": "727",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "COS": {
        "name": "City of Colorado Springs Municipal Airport",
        "city": "Colorado Springs",
        "country": "United States",
        "iata": "COS",
        "icao": "KCOS",
        "latitude": "38.805801",
        "longitude": "-104.700996",
        "altitude": "6187",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BUF": {
        "name": "Buffalo Niagara International Airport",
        "city": "Buffalo",
        "country": "United States",
        "iata": "BUF",
        "icao": "KBUF",
        "latitude": "42.94049835",
        "longitude": "-78.73220062",
        "altitude": "728",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SKY": {
        "name": "Griffing Sandusky Airport",
        "city": "Sandusky",
        "country": "United States",
        "iata": "SKY",
        "icao": "KSKY",
        "latitude": "41.4333992004",
        "longitude": "-82.6522979736",
        "altitude": "580",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PAE": {
        "name": "Snohomish County (Paine Field) Airport",
        "city": "Everett",
        "country": "United States",
        "iata": "PAE",
        "icao": "KPAE",
        "latitude": "47.90629959",
        "longitude": "-122.2819977",
        "altitude": "606",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MUO": {
        "name": "Mountain Home Air Force Base",
        "city": "Mountain Home",
        "country": "United States",
        "iata": "MUO",
        "icao": "KMUO",
        "latitude": "43.043598",
        "longitude": "-115.872002",
        "altitude": "2996",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CDC": {
        "name": "Cedar City Regional Airport",
        "city": "Cedar City",
        "country": "United States",
        "iata": "CDC",
        "icao": "KCDC",
        "latitude": "37.70100021362305",
        "longitude": "-113.0989990234375",
        "altitude": "5622",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BDL": {
        "name": "Bradley International Airport",
        "city": "Windsor Locks",
        "country": "United States",
        "iata": "BDL",
        "icao": "KBDL",
        "latitude": "41.9388999939",
        "longitude": "-72.68319702149999",
        "altitude": "173",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MFE": {
        "name": "Mc Allen Miller International Airport",
        "city": "Mcallen",
        "country": "United States",
        "iata": "MFE",
        "icao": "KMFE",
        "latitude": "26.17580032",
        "longitude": "-98.23860168",
        "altitude": "107",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NGU": {
        "name": "Norfolk Naval Station (Chambers Field)",
        "city": "Norfolk",
        "country": "United States",
        "iata": "NGU",
        "icao": "KNGU",
        "latitude": "36.937599",
        "longitude": "-76.289299",
        "altitude": "17",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CEF": {
        "name": "Westover ARB/Metropolitan Airport",
        "city": "Chicopee Falls",
        "country": "United States",
        "iata": "CEF",
        "icao": "KCEF",
        "latitude": "42.19400024",
        "longitude": "-72.53479767",
        "altitude": "241",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LBB": {
        "name": "Lubbock Preston Smith International Airport",
        "city": "Lubbock",
        "country": "United States",
        "iata": "LBB",
        "icao": "KLBB",
        "latitude": "33.663601",
        "longitude": "-101.822998",
        "altitude": "3282",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ORD": {
        "name": "Chicago O'Hare International Airport",
        "city": "Chicago",
        "country": "United States",
        "iata": "ORD",
        "icao": "KORD",
        "latitude": "41.9786",
        "longitude": "-87.9048",
        "altitude": "672",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BCT": {
        "name": "Boca Raton Airport",
        "city": "Boca Raton",
        "country": "United States",
        "iata": "BCT",
        "icao": "KBCT",
        "latitude": "26.3784999847",
        "longitude": "-80.1076965332",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FAI": {
        "name": "Fairbanks International Airport",
        "city": "Fairbanks",
        "country": "United States",
        "iata": "FAI",
        "icao": "PAFA",
        "latitude": "64.81510162",
        "longitude": "-147.8560028",
        "altitude": "439",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CVS": {
        "name": "Cannon Air Force Base",
        "city": "Clovis",
        "country": "United States",
        "iata": "CVS",
        "icao": "KCVS",
        "latitude": "34.3828010559",
        "longitude": "-103.321998596",
        "altitude": "4295",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "NGF": {
        "name": "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
        "city": "Kaneohe Bay",
        "country": "United States",
        "iata": "NGF",
        "icao": "PHNG",
        "latitude": "21.4505004883",
        "longitude": "-157.768005371",
        "altitude": "24",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "OFF": {
        "name": "Offutt Air Force Base",
        "city": "Omaha",
        "country": "United States",
        "iata": "OFF",
        "icao": "KOFF",
        "latitude": "41.118301391602",
        "longitude": "-95.912498474121",
        "altitude": "1052",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GKN": {
        "name": "Gulkana Airport",
        "city": "Gulkana",
        "country": "United States",
        "iata": "GKN",
        "icao": "PAGK",
        "latitude": "62.1548996",
        "longitude": "-145.4570007",
        "altitude": "1586",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ART": {
        "name": "Watertown International Airport",
        "city": "Watertown",
        "country": "United States",
        "iata": "ART",
        "icao": "KART",
        "latitude": "43.99190139770508",
        "longitude": "-76.02169799804688",
        "altitude": "325",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PSP": {
        "name": "Palm Springs International Airport",
        "city": "Palm Springs",
        "country": "United States",
        "iata": "PSP",
        "icao": "KPSP",
        "latitude": "33.8297004699707",
        "longitude": "-116.50700378417969",
        "altitude": "477",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "AMA": {
        "name": "Rick Husband Amarillo International Airport",
        "city": "Amarillo",
        "country": "United States",
        "iata": "AMA",
        "icao": "KAMA",
        "latitude": "35.219398",
        "longitude": "-101.706001",
        "altitude": "3607",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FOD": {
        "name": "Fort Dodge Regional Airport",
        "city": "Fort Dodge",
        "country": "United States",
        "iata": "FOD",
        "icao": "KFOD",
        "latitude": "42.55149841",
        "longitude": "-94.19259644",
        "altitude": "1156",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BAD": {
        "name": "Barksdale Air Force Base",
        "city": "Shreveport",
        "country": "United States",
        "iata": "BAD",
        "icao": "KBAD",
        "latitude": "32.5018005371",
        "longitude": "-93.6626968384",
        "altitude": "166",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FOE": {
        "name": "Topeka Regional Airport - Forbes Field",
        "city": "Topeka",
        "country": "United States",
        "iata": "FOE",
        "icao": "KFOE",
        "latitude": "38.950901031499995",
        "longitude": "-95.66359710690001",
        "altitude": "1078",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "COT": {
        "name": "Cotulla-La Salle County Airport",
        "city": "Cotulla",
        "country": "United States",
        "iata": "COT",
        "icao": "KCOT",
        "latitude": "28.45669937",
        "longitude": "-99.22029877",
        "altitude": "474",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ILM": {
        "name": "Wilmington International Airport",
        "city": "Wilmington",
        "country": "United States",
        "iata": "ILM",
        "icao": "KILM",
        "latitude": "34.270599365234375",
        "longitude": "-77.90260314941406",
        "altitude": "32",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BTR": {
        "name": "Baton Rouge Metropolitan Airport",
        "city": "Baton Rouge",
        "country": "United States",
        "iata": "BTR",
        "icao": "KBTR",
        "latitude": "30.533199",
        "longitude": "-91.149597",
        "altitude": "70",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TYR": {
        "name": "Tyler Pounds Regional Airport",
        "city": "Tyler",
        "country": "United States",
        "iata": "TYR",
        "icao": "KTYR",
        "latitude": "32.35409927368164",
        "longitude": "-95.40239715576172",
        "altitude": "544",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BWI": {
        "name": "Baltimore/Washington International Thurgood Marshall Airport",
        "city": "Baltimore",
        "country": "United States",
        "iata": "BWI",
        "icao": "KBWI",
        "latitude": "39.1754",
        "longitude": "-76.668297",
        "altitude": "146",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HBR": {
        "name": "Hobart Regional Airport",
        "city": "Hobart",
        "country": "United States",
        "iata": "HBR",
        "icao": "KHBR",
        "latitude": "34.991317",
        "longitude": "-99.051313",
        "altitude": "1563",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LNY": {
        "name": "Lanai Airport",
        "city": "Lanai",
        "country": "United States",
        "iata": "LNY",
        "icao": "PHNY",
        "latitude": "20.785600662231445",
        "longitude": "-156.9510040283203",
        "altitude": "1308",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "AEX": {
        "name": "Alexandria International Airport",
        "city": "Alexandria",
        "country": "United States",
        "iata": "AEX",
        "icao": "KAEX",
        "latitude": "31.32740020751953",
        "longitude": "-92.54979705810547",
        "altitude": "89",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "WSD": {
        "name": "Condron Army Air Field",
        "city": "White Sands",
        "country": "United States",
        "iata": "WSD",
        "icao": "KWSD",
        "latitude": "32.34149933",
        "longitude": "-106.4029999",
        "altitude": "3934",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CDB": {
        "name": "Cold Bay Airport",
        "city": "Cold Bay",
        "country": "United States",
        "iata": "CDB",
        "icao": "PACD",
        "latitude": "55.20610046386719",
        "longitude": "-162.72500610351562",
        "altitude": "96",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TUL": {
        "name": "Tulsa International Airport",
        "city": "Tulsa",
        "country": "United States",
        "iata": "TUL",
        "icao": "KTUL",
        "latitude": "36.19839859008789",
        "longitude": "-95.88809967041016",
        "altitude": "677",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SIT": {
        "name": "Sitka Rocky Gutierrez Airport",
        "city": "Sitka",
        "country": "United States",
        "iata": "SIT",
        "icao": "PASI",
        "latitude": "57.04710006713867",
        "longitude": "-135.36199951171875",
        "altitude": "21",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ISP": {
        "name": "Long Island Mac Arthur Airport",
        "city": "Islip",
        "country": "United States",
        "iata": "ISP",
        "icao": "KISP",
        "latitude": "40.79520035",
        "longitude": "-73.10019684",
        "altitude": "99",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MSP": {
        "name": "Minneapolis-St Paul International/Wold-Chamberlain Airport",
        "city": "Minneapolis",
        "country": "United States",
        "iata": "MSP",
        "icao": "KMSP",
        "latitude": "44.882",
        "longitude": "-93.221802",
        "altitude": "841",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ILG": {
        "name": "New Castle Airport",
        "city": "Wilmington",
        "country": "United States",
        "iata": "ILG",
        "icao": "KILG",
        "latitude": "39.67869949",
        "longitude": "-75.60649872",
        "altitude": "80",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DUT": {
        "name": "Unalaska Airport",
        "city": "Unalaska",
        "country": "United States",
        "iata": "DUT",
        "icao": "PADU",
        "latitude": "53.900100708",
        "longitude": "-166.544006348",
        "altitude": "22",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MSY": {
        "name": "Louis Armstrong New Orleans International Airport",
        "city": "New Orleans",
        "country": "United States",
        "iata": "MSY",
        "icao": "KMSY",
        "latitude": "29.99340057373047",
        "longitude": "-90.25800323486328",
        "altitude": "4",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PWM": {
        "name": "Portland International Jetport Airport",
        "city": "Portland",
        "country": "United States",
        "iata": "PWM",
        "icao": "KPWM",
        "latitude": "43.64619827",
        "longitude": "-70.30930328",
        "altitude": "76",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OKC": {
        "name": "Will Rogers World Airport",
        "city": "Oklahoma City",
        "country": "United States",
        "iata": "OKC",
        "icao": "KOKC",
        "latitude": "35.39310073852539",
        "longitude": "-97.60070037841797",
        "altitude": "1295",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ALB": {
        "name": "Albany International Airport",
        "city": "Albany",
        "country": "United States",
        "iata": "ALB",
        "icao": "KALB",
        "latitude": "42.74829864501953",
        "longitude": "-73.80169677734375",
        "altitude": "285",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "VDZ": {
        "name": "Valdez Pioneer Field",
        "city": "Valdez",
        "country": "United States",
        "iata": "VDZ",
        "icao": "PAVD",
        "latitude": "61.13389969",
        "longitude": "-146.2480011",
        "altitude": "121",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LFI": {
        "name": "Langley Air Force Base",
        "city": "Hampton",
        "country": "United States",
        "iata": "LFI",
        "icao": "KLFI",
        "latitude": "37.082901001",
        "longitude": "-76.360496521",
        "altitude": "11",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SNA": {
        "name": "John Wayne Airport-Orange County Airport",
        "city": "Santa Ana",
        "country": "United States",
        "iata": "SNA",
        "icao": "KSNA",
        "latitude": "33.67570114",
        "longitude": "-117.8679962",
        "altitude": "56",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CBM": {
        "name": "Columbus Air Force Base",
        "city": "Colombus",
        "country": "United States",
        "iata": "CBM",
        "icao": "KCBM",
        "latitude": "33.6437988281",
        "longitude": "-88.44380187990001",
        "altitude": "219",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TMB": {
        "name": "Kendall-Tamiami Executive Airport",
        "city": "Kendall-tamiami",
        "country": "United States",
        "iata": "TMB",
        "icao": "KTMB",
        "latitude": "25.6478996277",
        "longitude": "-80.432800293",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NTU": {
        "name": "Oceana Naval Air Station",
        "city": "Oceana",
        "country": "United States",
        "iata": "NTU",
        "icao": "KNTU",
        "latitude": "36.820702",
        "longitude": "-76.033501",
        "altitude": "23",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GUS": {
        "name": "Grissom Air Reserve Base",
        "city": "Peru",
        "country": "United States",
        "iata": "GUS",
        "icao": "KGUS",
        "latitude": "40.648101806599996",
        "longitude": "-86.1520996094",
        "altitude": "812",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CPR": {
        "name": "Casper-Natrona County International Airport",
        "city": "Casper",
        "country": "United States",
        "iata": "CPR",
        "icao": "KCPR",
        "latitude": "42.908001",
        "longitude": "-106.463997",
        "altitude": "5350",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "VPS": {
        "name": "Destin-Ft Walton Beach Airport",
        "city": "Valparaiso",
        "country": "United States",
        "iata": "VPS",
        "icao": "KVPS",
        "latitude": "30.4832",
        "longitude": "-86.525398",
        "altitude": "87",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SEM": {
        "name": "Craig Field",
        "city": "Selma",
        "country": "United States",
        "iata": "SEM",
        "icao": "KSEM",
        "latitude": "32.343898773193",
        "longitude": "-86.987800598145",
        "altitude": "166",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EYW": {
        "name": "Key West International Airport",
        "city": "Key West",
        "country": "United States",
        "iata": "EYW",
        "icao": "KEYW",
        "latitude": "24.556100845336914",
        "longitude": "-81.75959777832031",
        "altitude": "3",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CLT": {
        "name": "Charlotte Douglas International Airport",
        "city": "Charlotte",
        "country": "United States",
        "iata": "CLT",
        "icao": "KCLT",
        "latitude": "35.2140007019043",
        "longitude": "-80.94309997558594",
        "altitude": "748",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LAS": {
        "name": "McCarran International Airport",
        "city": "Las Vegas",
        "country": "United States",
        "iata": "LAS",
        "icao": "KLAS",
        "latitude": "36.08010101",
        "longitude": "-115.1520004",
        "altitude": "2181",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MCO": {
        "name": "Orlando International Airport",
        "city": "Orlando",
        "country": "United States",
        "iata": "MCO",
        "icao": "KMCO",
        "latitude": "28.429399490356445",
        "longitude": "-81.30899810791016",
        "altitude": "96",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FLO": {
        "name": "Florence Regional Airport",
        "city": "Florence",
        "country": "United States",
        "iata": "FLO",
        "icao": "KFLO",
        "latitude": "34.18539810180664",
        "longitude": "-79.7238998413086",
        "altitude": "146",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GTF": {
        "name": "Great Falls International Airport",
        "city": "Great Falls",
        "country": "United States",
        "iata": "GTF",
        "icao": "KGTF",
        "latitude": "47.48199844",
        "longitude": "-111.3710022",
        "altitude": "3680",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "YNG": {
        "name": "Youngstown Warren Regional Airport",
        "city": "Youngstown",
        "country": "United States",
        "iata": "YNG",
        "icao": "KYNG",
        "latitude": "41.26070023",
        "longitude": "-80.67910004",
        "altitude": "1192",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FBK": {
        "name": "Ladd AAF Airfield",
        "city": "Fort Wainwright",
        "country": "United States",
        "iata": "FBK",
        "icao": "PAFB",
        "latitude": "64.83750153",
        "longitude": "-147.6139984",
        "altitude": "454",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WRB": {
        "name": "Robins Air Force Base",
        "city": "Macon",
        "country": "United States",
        "iata": "WRB",
        "icao": "KWRB",
        "latitude": "32.6400985718",
        "longitude": "-83.5919036865",
        "altitude": "294",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BKK": {
        "name": "Suvarnabhumi Airport",
        "city": "Bangkok",
        "country": "Thailand",
        "iata": "BKK",
        "icao": "VTBS",
        "latitude": "13.681099891662598",
        "longitude": "100.74700164794922",
        "altitude": "5",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "NAH": {
        "name": "Naha Airport",
        "city": "Naha",
        "country": "Indonesia",
        "iata": "NAH",
        "icao": "WAMH",
        "latitude": "3.6832098960876465",
        "longitude": "125.52799987792969",
        "altitude": "16",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "MXB": {
        "name": "Andi Jemma Airport",
        "city": "Masamba",
        "country": "Indonesia",
        "iata": "MXB",
        "icao": "WAWM",
        "latitude": "-2.55803",
        "longitude": "120.323997",
        "altitude": "164",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "SQR": {
        "name": "Soroako Airport",
        "city": "Soroako",
        "country": "Indonesia",
        "iata": "SQR",
        "icao": "WAWS",
        "latitude": "-2.5311999320983887",
        "longitude": "121.35800170898438",
        "altitude": "1388",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "TTR": {
        "name": "Pongtiku Airport",
        "city": "Makale",
        "country": "Indonesia",
        "iata": "TTR",
        "icao": "WAWT",
        "latitude": "-3.0447399616241",
        "longitude": "119.82199859619",
        "altitude": "2884",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "KDI": {
        "name": "Wolter Monginsidi Airport",
        "city": "Kendari",
        "country": "Indonesia",
        "iata": "KDI",
        "icao": "WAWW",
        "latitude": "-4.081610202789307",
        "longitude": "122.41799926757812",
        "altitude": "538",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "SBG": {
        "name": "Maimun Saleh Airport",
        "city": "Sabang",
        "country": "Indonesia",
        "iata": "SBG",
        "icao": "WITB",
        "latitude": "5.87412977219",
        "longitude": "95.33969879150001",
        "altitude": "393",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "TSY": {
        "name": "Cibeureum Airport",
        "city": "Tasikmalaya",
        "country": "Indonesia",
        "iata": "TSY",
        "icao": "WICM",
        "latitude": "-7.346600055690001",
        "longitude": "108.246002197",
        "altitude": "1148",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "MLG": {
        "name": "Abdul Rachman Saleh Airport",
        "city": "Malang",
        "country": "Indonesia",
        "iata": "MLG",
        "icao": "WARA",
        "latitude": "-7.926559925079999",
        "longitude": "112.714996338",
        "altitude": "1726",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "BDO": {
        "name": "Husein Sastranegara International Airport",
        "city": "Bandung",
        "country": "Indonesia",
        "iata": "BDO",
        "icao": "WICC",
        "latitude": "-6.900629997253418",
        "longitude": "107.57599639892578",
        "altitude": "2436",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "CBN": {
        "name": "Penggung Airport",
        "city": "Cirebon",
        "country": "Indonesia",
        "iata": "CBN",
        "icao": "WICD",
        "latitude": "-6.756140232090001",
        "longitude": "108.540000916",
        "altitude": "89",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "JOG": {
        "name": "Adi Sutjipto International Airport",
        "city": "Yogyakarta",
        "country": "Indonesia",
        "iata": "JOG",
        "icao": "WARJ",
        "latitude": "-7.788179874420166",
        "longitude": "110.43199920654297",
        "altitude": "350",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "CXP": {
        "name": "Tunggul Wulung Airport",
        "city": "Cilacap",
        "country": "Indonesia",
        "iata": "CXP",
        "icao": "WIHL",
        "latitude": "-7.645060062410001",
        "longitude": "109.033996582",
        "altitude": "69",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PCB": {
        "name": "Pondok Cabe Air Base",
        "city": "Jakarta",
        "country": "Indonesia",
        "iata": "PCB",
        "icao": "WIHP",
        "latitude": "-6.3369598388671875",
        "longitude": "106.76499938964844",
        "altitude": "200",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "SRG": {
        "name": "Achmad Yani Airport",
        "city": "Semarang",
        "country": "Indonesia",
        "iata": "SRG",
        "icao": "WARS",
        "latitude": "-6.97273",
        "longitude": "110.375",
        "altitude": "10",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "BTH": {
        "name": "Hang Nadim International Airport",
        "city": "Batam",
        "country": "Indonesia",
        "iata": "BTH",
        "icao": "WIDD",
        "latitude": "1.12102997303",
        "longitude": "104.119003296",
        "altitude": "126",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "TJQ": {
        "name": "Buluh Tumbang (H A S Hanandjoeddin) Airport",
        "city": "Tanjung Pandan",
        "country": "Indonesia",
        "iata": "TJQ",
        "icao": "WIOD",
        "latitude": "-2.74571990967",
        "longitude": "107.754997253",
        "altitude": "164",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PGK": {
        "name": "Pangkal Pinang (Depati Amir) Airport",
        "city": "Pangkal Pinang",
        "country": "Indonesia",
        "iata": "PGK",
        "icao": "WIPK",
        "latitude": "-2.16219997406",
        "longitude": "106.138999939",
        "altitude": "109",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "TNJ": {
        "name": "Raja Haji Fisabilillah International Airport",
        "city": "Tanjung Pinang",
        "country": "Indonesia",
        "iata": "TNJ",
        "icao": "WIDN",
        "latitude": "0.922683000565",
        "longitude": "104.531997681",
        "altitude": "52",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "SIQ": {
        "name": "Dabo Airport",
        "city": "Singkep",
        "country": "Indonesia",
        "iata": "SIQ",
        "icao": "WIDS",
        "latitude": "-0.47918900847435",
        "longitude": "104.5790023803711",
        "altitude": "95",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "BDJ": {
        "name": "Syamsudin Noor Airport",
        "city": "Banjarmasin",
        "country": "Indonesia",
        "iata": "BDJ",
        "icao": "WAOO",
        "latitude": "-3.4423599243164062",
        "longitude": "114.76300048828125",
        "altitude": "66",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BTW": {
        "name": "Batu Licin Airport",
        "city": "Batu Licin",
        "country": "Indonesia",
        "iata": "BTW",
        "icao": "WAOC",
        "latitude": "-3.4124100208300003",
        "longitude": "115.995002747",
        "altitude": "3",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "PKN": {
        "name": "Iskandar Airport",
        "city": "Pangkalan Bun",
        "country": "Indonesia",
        "iata": "PKN",
        "icao": "WAOI",
        "latitude": "-2.70519995689",
        "longitude": "111.672996521",
        "altitude": "75",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PKY": {
        "name": "Tjilik Riwut Airport",
        "city": "Palangkaraya",
        "country": "Indonesia",
        "iata": "PKY",
        "icao": "WAOP",
        "latitude": "-2.22513008118",
        "longitude": "113.943000793",
        "altitude": "82",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "MOF": {
        "name": "Maumere(Wai Oti) Airport",
        "city": "Maumere",
        "country": "Indonesia",
        "iata": "MOF",
        "icao": "WATC",
        "latitude": "-8.64064979553",
        "longitude": "122.236999512",
        "altitude": "115",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "ENE": {
        "name": "Ende (H Hasan Aroeboesman) Airport",
        "city": "Ende",
        "country": "Indonesia",
        "iata": "ENE",
        "icao": "WATE",
        "latitude": "-8.8492898941",
        "longitude": "121.661003113",
        "altitude": "49",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "RTG": {
        "name": "Frans Sales Lega Airport",
        "city": "Ruteng",
        "country": "Indonesia",
        "iata": "RTG",
        "icao": "WATG",
        "latitude": "-8.5970096588135",
        "longitude": "120.47699737549",
        "altitude": "3510",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "KOE": {
        "name": "El Tari Airport",
        "city": "Kupang",
        "country": "Indonesia",
        "iata": "KOE",
        "icao": "WATT",
        "latitude": "-10.171600341796875",
        "longitude": "123.6709976196289",
        "altitude": "335",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "LBJ": {
        "name": "Komodo Airport",
        "city": "Labuhan Bajo",
        "country": "Indonesia",
        "iata": "LBJ",
        "icao": "WATO",
        "latitude": "-8.48666",
        "longitude": "119.889",
        "altitude": "66",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BPN": {
        "name": "Sultan Aji Muhamad Sulaiman Airport",
        "city": "Balikpapan",
        "country": "Indonesia",
        "iata": "BPN",
        "icao": "WALL",
        "latitude": "-1.26827001572",
        "longitude": "116.893997192",
        "altitude": "12",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "TRK": {
        "name": "Juwata Airport",
        "city": "Taraken",
        "country": "Indonesia",
        "iata": "TRK",
        "icao": "WALR",
        "latitude": "3.326667",
        "longitude": "117.569444",
        "altitude": "23",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "SRI": {
        "name": "Temindung Airport",
        "city": "Samarinda",
        "country": "Indonesia",
        "iata": "SRI",
        "icao": "WALS",
        "latitude": "-0.484530985355",
        "longitude": "117.156997681",
        "altitude": "33",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "TSX": {
        "name": "Tanjung Santan Airport",
        "city": "Tanjung Santan",
        "country": "Indonesia",
        "iata": "TSX",
        "icao": "WALT",
        "latitude": "-0.0929730013013",
        "longitude": "117.45300293",
        "altitude": "121",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "AMI": {
        "name": "Selaparang Airport",
        "city": "Mataram",
        "country": "Indonesia",
        "iata": "AMI",
        "icao": "WADA",
        "latitude": "-8.560709953309999",
        "longitude": "116.095001221",
        "altitude": "52",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BMU": {
        "name": "Muhammad Salahuddin Airport",
        "city": "Bima",
        "country": "Indonesia",
        "iata": "BMU",
        "icao": "WADB",
        "latitude": "-8.5396499633789",
        "longitude": "118.68699645996",
        "altitude": "3",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "WGP": {
        "name": "Umbu Mehang Kunda Airport",
        "city": "Waingapu",
        "country": "Indonesia",
        "iata": "WGP",
        "icao": "WADW",
        "latitude": "-9.66922",
        "longitude": "120.302002",
        "altitude": "33",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "SUB": {
        "name": "Juanda International Airport",
        "city": "Surabaya",
        "country": "Indonesia",
        "iata": "SUB",
        "icao": "WARR",
        "latitude": "-7.3798298835754395",
        "longitude": "112.78700256347656",
        "altitude": "9",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "SOC": {
        "name": "Adi Sumarmo Wiryokusumo Airport",
        "city": "Solo City",
        "country": "Indonesia",
        "iata": "SOC",
        "icao": "WARQ",
        "latitude": "-7.516089916229248",
        "longitude": "110.75700378417969",
        "altitude": "421",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "ICN": {
        "name": "Incheon International Airport",
        "city": "Seoul",
        "country": "South Korea",
        "iata": "ICN",
        "icao": "RKSI",
        "latitude": "37.46910095214844",
        "longitude": "126.45099639892578",
        "altitude": "23",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "CNX": {
        "name": "Chiang Mai International Airport",
        "city": "Chiang Mai",
        "country": "Thailand",
        "iata": "CNX",
        "icao": "VTCC",
        "latitude": "18.766799926799997",
        "longitude": "98.962600708",
        "altitude": "1036",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "CEI": {
        "name": "Chiang Rai International Airport",
        "city": "Chiang Rai",
        "country": "Thailand",
        "iata": "CEI",
        "icao": "VTCT",
        "latitude": "19.952299118",
        "longitude": "99.88289642330001",
        "altitude": "1280",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "NST": {
        "name": "Nakhon Si Thammarat Airport",
        "city": "Nakhon Si Thammarat",
        "country": "Thailand",
        "iata": "NST",
        "icao": "VTSF",
        "latitude": "8.539620399475098",
        "longitude": "99.9447021484375",
        "altitude": "13",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "NAK": {
        "name": "Nakhon Ratchasima Airport",
        "city": "Nakhon Ratchasima",
        "country": "Thailand",
        "iata": "NAK",
        "icao": "VTUQ",
        "latitude": "14.94950008392334",
        "longitude": "102.31300354003906",
        "altitude": "765",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "KOP": {
        "name": "Nakhon Phanom Airport",
        "city": "Nakhon Phanom",
        "country": "Thailand",
        "iata": "KOP",
        "icao": "VTUW",
        "latitude": "17.383800506591797",
        "longitude": "104.64299774169922",
        "altitude": "587",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "UBP": {
        "name": "Ubon Ratchathani Airport",
        "city": "Ubon Ratchathani",
        "country": "Thailand",
        "iata": "UBP",
        "icao": "VTUU",
        "latitude": "15.2512998581",
        "longitude": "104.870002747",
        "altitude": "406",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "KKC": {
        "name": "Khon Kaen Airport",
        "city": "Khon Kaen",
        "country": "Thailand",
        "iata": "KKC",
        "icao": "VTUK",
        "latitude": "16.466600418099997",
        "longitude": "102.783996582",
        "altitude": "670",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "THS": {
        "name": "Sukhothai Airport",
        "city": "Sukhothai",
        "country": "Thailand",
        "iata": "THS",
        "icao": "VTPO",
        "latitude": "17.238000869750977",
        "longitude": "99.81819915771484",
        "altitude": "179",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "DPS": {
        "name": "Ngurah Rai (Bali) International Airport",
        "city": "Denpasar",
        "country": "Indonesia",
        "iata": "DPS",
        "icao": "WADD",
        "latitude": "-8.7481698989868",
        "longitude": "115.16699981689",
        "altitude": "14",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "ATH": {
        "name": "Eleftherios Venizelos International Airport",
        "city": "Athens",
        "country": "Greece",
        "iata": "ATH",
        "icao": "LGAV",
        "latitude": "37.9364013672",
        "longitude": "23.9444999695",
        "altitude": "308",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "NGO": {
        "name": "Chubu Centrair International Airport",
        "city": "Nagoya",
        "country": "Japan",
        "iata": "NGO",
        "icao": "RJGG",
        "latitude": "34.8583984375",
        "longitude": "136.80499267578125",
        "altitude": "15",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "UKB": {
        "name": "Kobe Airport",
        "city": "Kobe",
        "country": "Japan",
        "iata": "UKB",
        "icao": "RJBE",
        "latitude": "34.6328010559082",
        "longitude": "135.2239990234375",
        "altitude": "22",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "PUW": {
        "name": "Pullman Moscow Regional Airport",
        "city": "Pullman",
        "country": "United States",
        "iata": "PUW",
        "icao": "KPUW",
        "latitude": "46.7439",
        "longitude": "-117.110001",
        "altitude": "2556",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LWS": {
        "name": "Lewiston Nez Perce County Airport",
        "city": "Lewiston",
        "country": "United States",
        "iata": "LWS",
        "icao": "KLWS",
        "latitude": "46.3745002746582",
        "longitude": "-117.01499938964844",
        "altitude": "1442",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ELM": {
        "name": "Elmira Corning Regional Airport",
        "city": "Elmira",
        "country": "United States",
        "iata": "ELM",
        "icao": "KELM",
        "latitude": "42.1599006652832",
        "longitude": "-76.8916015625",
        "altitude": "954",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ITH": {
        "name": "Ithaca Tompkins Regional Airport",
        "city": "Ithaca",
        "country": "United States",
        "iata": "ITH",
        "icao": "KITH",
        "latitude": "42.49100112915039",
        "longitude": "-76.4583969116211",
        "altitude": "1099",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MRY": {
        "name": "Monterey Peninsula Airport",
        "city": "Monterey",
        "country": "United States",
        "iata": "MRY",
        "icao": "KMRY",
        "latitude": "36.58700180053711",
        "longitude": "-121.84300231933594",
        "altitude": "257",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SBA": {
        "name": "Santa Barbara Municipal Airport",
        "city": "Santa Barbara",
        "country": "United States",
        "iata": "SBA",
        "icao": "KSBA",
        "latitude": "34.42620087",
        "longitude": "-119.8399963",
        "altitude": "13",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "DAB": {
        "name": "Daytona Beach International Airport",
        "city": "Daytona Beach",
        "country": "United States",
        "iata": "DAB",
        "icao": "KDAB",
        "latitude": "29.179899",
        "longitude": "-81.058098",
        "altitude": "34",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LPX": {
        "name": "Liepāja International Airport",
        "city": "Liepaja",
        "country": "Latvia",
        "iata": "LPX",
        "icao": "EVLA",
        "latitude": "56.51750183105469",
        "longitude": "21.096900939941406",
        "altitude": "16",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Riga"
    },
    "RIX": {
        "name": "Riga International Airport",
        "city": "Riga",
        "country": "Latvia",
        "iata": "RIX",
        "icao": "EVRA",
        "latitude": "56.92359924316406",
        "longitude": "23.971099853515625",
        "altitude": "36",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Riga"
    },
    "SQQ": {
        "name": "Šiauliai International Airport",
        "city": "Siauliai",
        "country": "Lithuania",
        "iata": "SQQ",
        "icao": "EYSA",
        "latitude": "55.89390182495117",
        "longitude": "23.395000457763672",
        "altitude": "443",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Vilnius"
    },
    "HLJ": {
        "name": "Barysiai Airport",
        "city": "Barysiai",
        "country": "Lithuania",
        "iata": "HLJ",
        "icao": "EYSB",
        "latitude": "56.07059860229492",
        "longitude": "23.5580997467041",
        "altitude": "270",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Vilnius"
    },
    "KUN": {
        "name": "Kaunas International Airport",
        "city": "Kaunas",
        "country": "Lithuania",
        "iata": "KUN",
        "icao": "EYKA",
        "latitude": "54.96390151977539",
        "longitude": "24.084800720214844",
        "altitude": "256",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Vilnius"
    },
    "PLQ": {
        "name": "Palanga International Airport",
        "city": "Palanga",
        "country": "Lithuania",
        "iata": "PLQ",
        "icao": "EYPA",
        "latitude": "55.973201751708984",
        "longitude": "21.093900680541992",
        "altitude": "33",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Vilnius"
    },
    "VNO": {
        "name": "Vilnius International Airport",
        "city": "Vilnius",
        "country": "Lithuania",
        "iata": "VNO",
        "icao": "EYVI",
        "latitude": "54.634102",
        "longitude": "25.285801",
        "altitude": "648",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Vilnius"
    },
    "PNV": {
        "name": "Panevėžys Air Base",
        "city": "Panevezys",
        "country": "Lithuania",
        "iata": "PNV",
        "icao": "EYPP",
        "latitude": "55.729400634765625",
        "longitude": "24.460800170898438",
        "altitude": "197",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Vilnius"
    },
    "EVN": {
        "name": "Zvartnots International Airport",
        "city": "Yerevan",
        "country": "Armenia",
        "iata": "EVN",
        "icao": "UDYZ",
        "latitude": "40.1473007202",
        "longitude": "44.3959007263",
        "altitude": "2838",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Yerevan"
    },
    "LWN": {
        "name": "Gyumri Shirak Airport",
        "city": "Gyumri",
        "country": "Armenia",
        "iata": "LWN",
        "icao": "UDSG",
        "latitude": "40.7504005432",
        "longitude": "43.859298706100006",
        "altitude": "5000",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Yerevan"
    },
    "ASA": {
        "name": "Assab International Airport",
        "city": "Assab",
        "country": "Eritrea",
        "iata": "ASA",
        "icao": "HHSB",
        "latitude": "13.071800231933594",
        "longitude": "42.64500045776367",
        "altitude": "46",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Asmera"
    },
    "ASM": {
        "name": "Asmara International Airport",
        "city": "Asmara",
        "country": "Eritrea",
        "iata": "ASM",
        "icao": "HHAS",
        "latitude": "15.291899681091309",
        "longitude": "38.910701751708984",
        "altitude": "7661",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Asmera"
    },
    "MSW": {
        "name": "Massawa International Airport",
        "city": "Massawa",
        "country": "Eritrea",
        "iata": "MSW",
        "icao": "HHMS",
        "latitude": "15.670000076293945",
        "longitude": "39.37009811401367",
        "altitude": "194",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Asmera"
    },
    "GZA": {
        "name": "Yasser Arafat International Airport",
        "city": "Gaza",
        "country": "Palestine",
        "iata": "GZA",
        "icao": "LVGZ",
        "latitude": "31.24640083313",
        "longitude": "34.276100158691",
        "altitude": "320",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Asia/Gaza"
    },
    "BUS": {
        "name": "Batumi International Airport",
        "city": "Batumi",
        "country": "Georgia",
        "iata": "BUS",
        "icao": "UGSB",
        "latitude": "41.6102981567",
        "longitude": "41.5997009277",
        "altitude": "105",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Asia/Tbilisi"
    },
    "KUT": {
        "name": "Kopitnari Airport",
        "city": "Kutaisi",
        "country": "Georgia",
        "iata": "KUT",
        "icao": "UGKO",
        "latitude": "42.176700592",
        "longitude": "42.4826011658",
        "altitude": "223",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Asia/Tbilisi"
    },
    "TBS": {
        "name": "Tbilisi International Airport",
        "city": "Tbilisi",
        "country": "Georgia",
        "iata": "TBS",
        "icao": "UGTB",
        "latitude": "41.6692008972",
        "longitude": "44.95470047",
        "altitude": "1624",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Asia/Tbilisi"
    },
    "RIY": {
        "name": "Mukalla International Airport",
        "city": "Mukalla",
        "country": "Yemen",
        "iata": "RIY",
        "icao": "OYRN",
        "latitude": "14.662599563598633",
        "longitude": "49.375",
        "altitude": "54",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "TAI": {
        "name": "Ta'izz International Airport",
        "city": "Taiz",
        "country": "Yemen",
        "iata": "TAI",
        "icao": "OYTZ",
        "latitude": "13.6859998703",
        "longitude": "44.139099121099996",
        "altitude": "4838",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "HOD": {
        "name": "Hodeidah International Airport",
        "city": "Hodeidah",
        "country": "Yemen",
        "iata": "HOD",
        "icao": "OYHD",
        "latitude": "14.753000259399414",
        "longitude": "42.97629928588867",
        "altitude": "41",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "ADE": {
        "name": "Aden International Airport",
        "city": "Aden",
        "country": "Yemen",
        "iata": "ADE",
        "icao": "OYAA",
        "latitude": "12.829500198364258",
        "longitude": "45.02880096435547",
        "altitude": "7",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "AXK": {
        "name": "Ataq Airport",
        "city": "Ataq",
        "country": "Yemen",
        "iata": "AXK",
        "icao": "OYAT",
        "latitude": "14.551300048828125",
        "longitude": "46.82619857788086",
        "altitude": "3735",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "AAY": {
        "name": "Al Ghaidah International Airport",
        "city": "Al Ghaidah Intl",
        "country": "Yemen",
        "iata": "AAY",
        "icao": "OYGD",
        "latitude": "16.191699981689453",
        "longitude": "52.17499923706055",
        "altitude": "134",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "SAH": {
        "name": "Sana'a International Airport",
        "city": "Sanaa",
        "country": "Yemen",
        "iata": "SAH",
        "icao": "OYSN",
        "latitude": "15.476300239562988",
        "longitude": "44.21969985961914",
        "altitude": "7216",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "BHN": {
        "name": "Beihan Airport",
        "city": "Beihan",
        "country": "Yemen",
        "iata": "BHN",
        "icao": "OYBN",
        "latitude": "14.781999588012695",
        "longitude": "45.72010040283203",
        "altitude": "3800",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "SCT": {
        "name": "Socotra International Airport",
        "city": "Socotra",
        "country": "Yemen",
        "iata": "SCT",
        "icao": "OYSQ",
        "latitude": "12.63070011138916",
        "longitude": "53.905799865722656",
        "altitude": "146",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "FMM": {
        "name": "Memmingen Allgau Airport",
        "city": "Memmingen",
        "country": "Germany",
        "iata": "FMM",
        "icao": "EDJA",
        "latitude": "47.988800048799995",
        "longitude": "10.2395000458",
        "altitude": "2077",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "NAV": {
        "name": "Nevşehir Kapadokya Airport",
        "city": "Nevsehir",
        "country": "Turkey",
        "iata": "NAV",
        "icao": "LTAZ",
        "latitude": "38.7719",
        "longitude": "34.5345",
        "altitude": "3100",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "EZE": {
        "name": "Ministro Pistarini International Airport",
        "city": "Buenos Aires",
        "country": "Argentina",
        "iata": "EZE",
        "icao": "SAEZ",
        "latitude": "-34.8222",
        "longitude": "-58.5358",
        "altitude": "67",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "EBL": {
        "name": "Erbil International Airport",
        "city": "Erbil",
        "country": "Iraq",
        "iata": "EBL",
        "icao": "ORER",
        "latitude": "36.23759841918945",
        "longitude": "43.963199615478516",
        "altitude": "1341",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "EMD": {
        "name": "Emerald Airport",
        "city": "Emerald",
        "country": "Australia",
        "iata": "EMD",
        "icao": "YEML",
        "latitude": "-23.5674991608",
        "longitude": "148.179000854",
        "altitude": "624",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "HEW": {
        "name": "Athen Helenikon Airport",
        "city": "Athens",
        "country": "Greece",
        "iata": "HEW",
        "icao": "LGAT",
        "latitude": "37.893299",
        "longitude": "23.726101",
        "altitude": "69",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KIX": {
        "name": "Kansai International Airport",
        "city": "Osaka",
        "country": "Japan",
        "iata": "KIX",
        "icao": "RJBB",
        "latitude": "34.42729949951172",
        "longitude": "135.24400329589844",
        "altitude": "26",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "JRB": {
        "name": "Downtown-Manhattan/Wall St Heliport",
        "city": "New York",
        "country": "United States",
        "iata": "JRB",
        "icao": "KJRB",
        "latitude": "40.70119858",
        "longitude": "-74.00900269",
        "altitude": "7",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TAG": {
        "name": "Tagbilaran Airport",
        "city": "Tagbilaran",
        "country": "Philippines",
        "iata": "TAG",
        "icao": "RPVT",
        "latitude": "9.665442",
        "longitude": "123.853533",
        "altitude": "38",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "JAV": {
        "name": "Ilulissat Airport",
        "city": "Ilulissat",
        "country": "Greenland",
        "iata": "JAV",
        "icao": "BGJN",
        "latitude": "69.2432022095",
        "longitude": "-51.0570983887",
        "altitude": "95",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JCH": {
        "name": "Qasigiannguit Heliport",
        "city": "Qasigiannguit",
        "country": "Greenland",
        "iata": "JCH",
        "icao": "BGCH",
        "latitude": "68.822815547",
        "longitude": "-51.1734473705",
        "altitude": "70",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JEG": {
        "name": "Aasiaat Airport",
        "city": "Aasiaat",
        "country": "Greenland",
        "iata": "JEG",
        "icao": "BGAA",
        "latitude": "68.7218017578",
        "longitude": "-52.7846984863",
        "altitude": "74",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "PMI": {
        "name": "Palma De Mallorca Airport",
        "city": "Palma de Mallorca",
        "country": "Spain",
        "iata": "PMI",
        "icao": "LEPA",
        "latitude": "39.551700592",
        "longitude": "2.73881006241",
        "altitude": "27",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "DRW": {
        "name": "Darwin International Airport",
        "city": "Darwin",
        "country": "Australia",
        "iata": "DRW",
        "icao": "YPDN",
        "latitude": "-12.41469955444336",
        "longitude": "130.8769989013672",
        "altitude": "103",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": "Australia/Darwin"
    },
    "URT": {
        "name": "Surat Thani Airport",
        "city": "Surat Thani",
        "country": "Thailand",
        "iata": "URT",
        "icao": "VTSB",
        "latitude": "9.13259983063",
        "longitude": "99.135597229",
        "altitude": "20",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "TKA": {
        "name": "Talkeetna Airport",
        "city": "Talkeetna",
        "country": "United States",
        "iata": "TKA",
        "icao": "PATK",
        "latitude": "62.320499420166",
        "longitude": "-150.09399414062",
        "altitude": "358",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "GZM": {
        "name": "Xewkija Heliport",
        "city": "Gozo",
        "country": "Malta",
        "iata": "GZM",
        "icao": "LMMG",
        "latitude": "36.027199",
        "longitude": "14.2728",
        "altitude": "0",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Malta"
    },
    "HVN": {
        "name": "Tweed New Haven Airport",
        "city": "New Haven",
        "country": "United States",
        "iata": "HVN",
        "icao": "KHVN",
        "latitude": "41.26369858",
        "longitude": "-72.88680267",
        "altitude": "12",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AVL": {
        "name": "Asheville Regional Airport",
        "city": "Asheville",
        "country": "United States",
        "iata": "AVL",
        "icao": "KAVL",
        "latitude": "35.43619918823242",
        "longitude": "-82.54180145263672",
        "altitude": "2165",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GSO": {
        "name": "Piedmont Triad International Airport",
        "city": "Greensboro",
        "country": "United States",
        "iata": "GSO",
        "icao": "KGSO",
        "latitude": "36.097801208496094",
        "longitude": "-79.93730163574219",
        "altitude": "925",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FSD": {
        "name": "Joe Foss Field Airport",
        "city": "Sioux Falls",
        "country": "United States",
        "iata": "FSD",
        "icao": "KFSD",
        "latitude": "43.582000732400004",
        "longitude": "-96.741897583",
        "altitude": "1429",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AYQ": {
        "name": "Ayers Rock Connellan Airport",
        "city": "Uluru",
        "country": "Australia",
        "iata": "AYQ",
        "icao": "YAYE",
        "latitude": "-25.1861",
        "longitude": "130.975998",
        "altitude": "1626",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": "Australia/Darwin"
    },
    "MHT": {
        "name": "Manchester-Boston Regional Airport",
        "city": "Manchester NH",
        "country": "United States",
        "iata": "MHT",
        "icao": "KMHT",
        "latitude": "42.932598",
        "longitude": "-71.435699",
        "altitude": "266",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "APF": {
        "name": "Naples Municipal Airport",
        "city": "Naples",
        "country": "United States",
        "iata": "APF",
        "icao": "KAPF",
        "latitude": "26.1525993347",
        "longitude": "-81.7752990723",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RDN": {
        "name": "LTS Pulau Redang Airport",
        "city": "Redang",
        "country": "Malaysia",
        "iata": "RDN",
        "icao": "WMPR",
        "latitude": "5.765279769897461",
        "longitude": "103.00700378417969",
        "altitude": "36",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "SDF": {
        "name": "Louisville International Standiford Field",
        "city": "Louisville",
        "country": "United States",
        "iata": "SDF",
        "icao": "KSDF",
        "latitude": "38.1744",
        "longitude": "-85.736",
        "altitude": "501",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CHO": {
        "name": "Charlottesville Albemarle Airport",
        "city": "Charlottesville VA",
        "country": "United States",
        "iata": "CHO",
        "icao": "KCHO",
        "latitude": "38.13859939575195",
        "longitude": "-78.4529037475586",
        "altitude": "639",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ROA": {
        "name": "Roanoke–Blacksburg Regional Airport",
        "city": "Roanoke VA",
        "country": "United States",
        "iata": "ROA",
        "icao": "KROA",
        "latitude": "37.3255",
        "longitude": "-79.975403",
        "altitude": "1175",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LEX": {
        "name": "Blue Grass Airport",
        "city": "Lexington KY",
        "country": "United States",
        "iata": "LEX",
        "icao": "KLEX",
        "latitude": "38.0364990234375",
        "longitude": "-84.60590362548828",
        "altitude": "979",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "EVV": {
        "name": "Evansville Regional Airport",
        "city": "Evansville",
        "country": "United States",
        "iata": "EVV",
        "icao": "KEVV",
        "latitude": "38.0369987488",
        "longitude": "-87.5324020386",
        "altitude": "418",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ABQ": {
        "name": "Albuquerque International Sunport",
        "city": "Albuquerque",
        "country": "United States",
        "iata": "ABQ",
        "icao": "KABQ",
        "latitude": "35.040199",
        "longitude": "-106.609001",
        "altitude": "5355",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BZN": {
        "name": "Gallatin Field",
        "city": "Bozeman",
        "country": "United States",
        "iata": "BZN",
        "icao": "KBZN",
        "latitude": "45.77750015",
        "longitude": "-111.1529999",
        "altitude": "4473",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BIL": {
        "name": "Billings Logan International Airport",
        "city": "Billings",
        "country": "United States",
        "iata": "BIL",
        "icao": "KBIL",
        "latitude": "45.807701110839844",
        "longitude": "-108.54299926757812",
        "altitude": "3652",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BTM": {
        "name": "Bert Mooney Airport",
        "city": "Butte",
        "country": "United States",
        "iata": "BTM",
        "icao": "KBTM",
        "latitude": "45.95479965209961",
        "longitude": "-112.49700164794922",
        "altitude": "5550",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "TVC": {
        "name": "Cherry Capital Airport",
        "city": "Traverse City",
        "country": "United States",
        "iata": "TVC",
        "icao": "KTVC",
        "latitude": "44.74140167236328",
        "longitude": "-85.58219909667969",
        "altitude": "624",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FRS": {
        "name": "Mundo Maya International Airport",
        "city": "Flores",
        "country": "Guatemala",
        "iata": "FRS",
        "icao": "MGTK",
        "latitude": "16.913799285899998",
        "longitude": "-89.86640167239999",
        "altitude": "427",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "BHB": {
        "name": "Hancock County-Bar Harbor Airport",
        "city": "Bar Harbor",
        "country": "United States",
        "iata": "BHB",
        "icao": "KBHB",
        "latitude": "44.45000076",
        "longitude": "-68.3615036",
        "altitude": "83",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RKD": {
        "name": "Knox County Regional Airport",
        "city": "Rockland",
        "country": "United States",
        "iata": "RKD",
        "icao": "KRKD",
        "latitude": "44.06010056",
        "longitude": "-69.09919739",
        "altitude": "56",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JAC": {
        "name": "Jackson Hole Airport",
        "city": "Jacksn Hole",
        "country": "United States",
        "iata": "JAC",
        "icao": "KJAC",
        "latitude": "43.6072998046875",
        "longitude": "-110.73799896240234",
        "altitude": "6451",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "RFD": {
        "name": "Chicago Rockford International Airport",
        "city": "Rockford",
        "country": "United States",
        "iata": "RFD",
        "icao": "KRFD",
        "latitude": "42.19540023803711",
        "longitude": "-89.09719848632812",
        "altitude": "742",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DME": {
        "name": "Domodedovo International Airport",
        "city": "Moscow",
        "country": "Russia",
        "iata": "DME",
        "icao": "UUDD",
        "latitude": "55.40879821777344",
        "longitude": "37.90629959106445",
        "altitude": "588",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "SYX": {
        "name": "Sanya Phoenix International Airport",
        "city": "Sanya",
        "country": "China",
        "iata": "SYX",
        "icao": "ZJSY",
        "latitude": "18.302900314331055",
        "longitude": "109.41200256347656",
        "altitude": "92",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MFN": {
        "name": "Milford Sound Airport",
        "city": "Milford Sound",
        "country": "New Zealand",
        "iata": "MFN",
        "icao": "NZMF",
        "latitude": "-44.673301696777344",
        "longitude": "167.92300415039062",
        "altitude": "10",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "LJG": {
        "name": "Lijiang Airport",
        "city": "Lijiang",
        "country": "China",
        "iata": "LJG",
        "icao": "ZPLJ",
        "latitude": "26.6800003052",
        "longitude": "100.246002197",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "GSP": {
        "name": "Greenville Spartanburg International Airport",
        "city": "Greenville",
        "country": "United States",
        "iata": "GSP",
        "icao": "KGSP",
        "latitude": "34.8956985474",
        "longitude": "-82.2189025879",
        "altitude": "964",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BMI": {
        "name": "Central Illinois Regional Airport at Bloomington-Normal",
        "city": "Bloomington",
        "country": "United States",
        "iata": "BMI",
        "icao": "KBMI",
        "latitude": "40.47710037",
        "longitude": "-88.91590118",
        "altitude": "871",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GPT": {
        "name": "Gulfport Biloxi International Airport",
        "city": "Gulfport",
        "country": "United States",
        "iata": "GPT",
        "icao": "KGPT",
        "latitude": "30.40730094909668",
        "longitude": "-89.07009887695312",
        "altitude": "28",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AZO": {
        "name": "Kalamazoo Battle Creek International Airport",
        "city": "Kalamazoo",
        "country": "United States",
        "iata": "AZO",
        "icao": "KAZO",
        "latitude": "42.234901428222656",
        "longitude": "-85.5521011352539",
        "altitude": "874",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TOL": {
        "name": "Toledo Express Airport",
        "city": "Toledo",
        "country": "United States",
        "iata": "TOL",
        "icao": "KTOL",
        "latitude": "41.58679962",
        "longitude": "-83.80780029",
        "altitude": "683",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FWA": {
        "name": "Fort Wayne International Airport",
        "city": "Fort Wayne",
        "country": "United States",
        "iata": "FWA",
        "icao": "KFWA",
        "latitude": "40.97850037",
        "longitude": "-85.19509888",
        "altitude": "814",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DEC": {
        "name": "Decatur Airport",
        "city": "Decatur",
        "country": "United States",
        "iata": "DEC",
        "icao": "KDEC",
        "latitude": "39.834598541259766",
        "longitude": "-88.8656997680664",
        "altitude": "682",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CID": {
        "name": "The Eastern Iowa Airport",
        "city": "Cedar Rapids",
        "country": "United States",
        "iata": "CID",
        "icao": "KCID",
        "latitude": "41.884700775146484",
        "longitude": "-91.71080017089844",
        "altitude": "869",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LSE": {
        "name": "La Crosse Municipal Airport",
        "city": "La Crosse",
        "country": "United States",
        "iata": "LSE",
        "icao": "KLSE",
        "latitude": "43.879002",
        "longitude": "-91.256699",
        "altitude": "655",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CWA": {
        "name": "Central Wisconsin Airport",
        "city": "Wassau",
        "country": "United States",
        "iata": "CWA",
        "icao": "KCWA",
        "latitude": "44.7775993347",
        "longitude": "-89.6668014526",
        "altitude": "1277",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PIA": {
        "name": "General Wayne A. Downing Peoria International Airport",
        "city": "Peoria",
        "country": "United States",
        "iata": "PIA",
        "icao": "KPIA",
        "latitude": "40.664199829100006",
        "longitude": "-89.6932983398",
        "altitude": "660",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ATW": {
        "name": "Appleton International Airport",
        "city": "Appleton",
        "country": "United States",
        "iata": "ATW",
        "icao": "KATW",
        "latitude": "44.258098602299995",
        "longitude": "-88.5190963745",
        "altitude": "918",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RST": {
        "name": "Rochester International Airport",
        "city": "Rochester",
        "country": "United States",
        "iata": "RST",
        "icao": "KRST",
        "latitude": "43.90829849243164",
        "longitude": "-92.5",
        "altitude": "1317",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CMI": {
        "name": "University of Illinois Willard Airport",
        "city": "Champaign",
        "country": "United States",
        "iata": "CMI",
        "icao": "KCMI",
        "latitude": "40.03919983",
        "longitude": "-88.27809906",
        "altitude": "755",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MHK": {
        "name": "Manhattan Regional Airport",
        "city": "Manhattan",
        "country": "United States",
        "iata": "MHK",
        "icao": "KMHK",
        "latitude": "39.14099884033203",
        "longitude": "-96.6707992553711",
        "altitude": "1057",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KGC": {
        "name": "Kingscote Airport",
        "city": "Kingscote",
        "country": "Australia",
        "iata": "KGC",
        "icao": "YKSC",
        "latitude": "-35.71390151977539",
        "longitude": "137.52099609375",
        "altitude": "24",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "HVB": {
        "name": "Hervey Bay Airport",
        "city": "Hervey Bay",
        "country": "Australia",
        "iata": "HVB",
        "icao": "YHBA",
        "latitude": "-25.3188991547",
        "longitude": "152.880004883",
        "altitude": "60",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "DLU": {
        "name": "Dali Airport",
        "city": "Dali",
        "country": "China",
        "iata": "DLU",
        "icao": "ZPDL",
        "latitude": "25.649401",
        "longitude": "100.319",
        "altitude": "7050",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MZV": {
        "name": "Mulu Airport",
        "city": "Mulu",
        "country": "Malaysia",
        "iata": "MZV",
        "icao": "WBMU",
        "latitude": "4.048329830169678",
        "longitude": "114.80500030517578",
        "altitude": "80",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "SSH": {
        "name": "Sharm El Sheikh International Airport",
        "city": "Sharm El Sheikh",
        "country": "Egypt",
        "iata": "SSH",
        "icao": "HESH",
        "latitude": "27.9773006439",
        "longitude": "34.3950004578",
        "altitude": "143",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Cairo"
    },
    "FKL": {
        "name": "Venango Regional Airport",
        "city": "Franklin",
        "country": "United States",
        "iata": "FKL",
        "icao": "KFKL",
        "latitude": "41.3778991699",
        "longitude": "-79.8603973389",
        "altitude": "1540",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NBO": {
        "name": "Jomo Kenyatta International Airport",
        "city": "Nairobi",
        "country": "Kenya",
        "iata": "NBO",
        "icao": "HKJK",
        "latitude": "-1.31923997402",
        "longitude": "36.9277992249",
        "altitude": "5330",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Africa/Nairobi"
    },
    "SEU": {
        "name": "Seronera Airport",
        "city": "Seronera",
        "country": "Tanzania",
        "iata": "SEU",
        "icao": "HTSN",
        "latitude": "-2.45806",
        "longitude": "34.822498",
        "altitude": "5080",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "FTE": {
        "name": "El Calafate Airport",
        "city": "El Calafate",
        "country": "Argentina",
        "iata": "FTE",
        "icao": "SAWC",
        "latitude": "-50.2803",
        "longitude": "-72.053101",
        "altitude": "669",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "ARM": {
        "name": "Armidale Airport",
        "city": "Armidale",
        "country": "Australia",
        "iata": "ARM",
        "icao": "YARM",
        "latitude": "-30.528099060099997",
        "longitude": "151.617004395",
        "altitude": "3556",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "GJT": {
        "name": "Grand Junction Regional Airport",
        "city": "Grand Junction",
        "country": "United States",
        "iata": "GJT",
        "icao": "KGJT",
        "latitude": "39.1223983765",
        "longitude": "-108.527000427",
        "altitude": "4858",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SGU": {
        "name": "St George Municipal Airport",
        "city": "Saint George",
        "country": "United States",
        "iata": "SGU",
        "icao": "KSGU",
        "latitude": "37.036389",
        "longitude": "-113.510306",
        "altitude": "2941",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "DWH": {
        "name": "David Wayne Hooks Memorial Airport",
        "city": "Houston",
        "country": "United States",
        "iata": "DWH",
        "icao": "KDWH",
        "latitude": "30.0618000031",
        "longitude": "-95.55280303960001",
        "altitude": "152",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SRQ": {
        "name": "Sarasota Bradenton International Airport",
        "city": "Sarasota",
        "country": "United States",
        "iata": "SRQ",
        "icao": "KSRQ",
        "latitude": "27.39539909362793",
        "longitude": "-82.55439758300781",
        "altitude": "30",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BDA": {
        "name": "L.F. Wade International International Airport",
        "city": "Bermuda",
        "country": "Bermuda",
        "iata": "BDA",
        "icao": "TXKF",
        "latitude": "32.36399841308594",
        "longitude": "-64.67870330810547",
        "altitude": "12",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "Atlantic/Bermuda"
    },
    "VNY": {
        "name": "Van Nuys Airport",
        "city": "Van Nuys",
        "country": "United States",
        "iata": "VNY",
        "icao": "KVNY",
        "latitude": "34.209800720215",
        "longitude": "-118.48999786377",
        "altitude": "802",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MLI": {
        "name": "Quad City International Airport",
        "city": "Moline",
        "country": "United States",
        "iata": "MLI",
        "icao": "KMLI",
        "latitude": "41.44850158691406",
        "longitude": "-90.50749969482422",
        "altitude": "590",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PFN": {
        "name": "Panama City-Bay Co International Airport",
        "city": "Panama City",
        "country": "United States",
        "iata": "PFN",
        "icao": "KPFN",
        "latitude": "30.212099",
        "longitude": "-85.6828",
        "altitude": "20",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HIR": {
        "name": "Honiara International Airport",
        "city": "Honiara",
        "country": "Solomon Islands",
        "iata": "HIR",
        "icao": "AGGH",
        "latitude": "-9.4280004501343",
        "longitude": "160.05499267578",
        "altitude": "28",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "PPT": {
        "name": "Faa'a International Airport",
        "city": "Papeete",
        "country": "French Polynesia",
        "iata": "PPT",
        "icao": "NTAA",
        "latitude": "-17.553699",
        "longitude": "-149.606995",
        "altitude": "5",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "INU": {
        "name": "Nauru International Airport",
        "city": "Nauru",
        "country": "Nauru",
        "iata": "INU",
        "icao": "ANYN",
        "latitude": "-0.547458",
        "longitude": "166.919006",
        "altitude": "22",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Nauru"
    },
    "FUN": {
        "name": "Funafuti International Airport",
        "city": "Funafuti",
        "country": "Tuvalu",
        "iata": "FUN",
        "icao": "NGFU",
        "latitude": "-8.525",
        "longitude": "179.195999",
        "altitude": "9",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Funafuti"
    },
    "OVB": {
        "name": "Tolmachevo Airport",
        "city": "Novosibirsk",
        "country": "Russia",
        "iata": "OVB",
        "icao": "UNNT",
        "latitude": "55.012599945068",
        "longitude": "82.650703430176",
        "altitude": "365",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "XKH": {
        "name": "Xieng Khouang Airport",
        "city": "Phon Savan",
        "country": "Laos",
        "iata": "XKH",
        "icao": "VLXK",
        "latitude": "19.450001",
        "longitude": "103.157997",
        "altitude": "3445",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "BIS": {
        "name": "Bismarck Municipal Airport",
        "city": "Bismarck",
        "country": "United States",
        "iata": "BIS",
        "icao": "KBIS",
        "latitude": "46.772701263427734",
        "longitude": "-100.74600219726562",
        "altitude": "1661",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TEX": {
        "name": "Telluride Regional Airport",
        "city": "Telluride",
        "country": "United States",
        "iata": "TEX",
        "icao": "KTEX",
        "latitude": "37.9538002",
        "longitude": "-107.9079971",
        "altitude": "9070",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "HGN": {
        "name": "Mae Hong Son Airport",
        "city": "Mae Hong Son",
        "country": "Thailand",
        "iata": "HGN",
        "icao": "VTCH",
        "latitude": "19.301300048828125",
        "longitude": "97.97579956054688",
        "altitude": "929",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "RAP": {
        "name": "Rapid City Regional Airport",
        "city": "Rapid City",
        "country": "United States",
        "iata": "RAP",
        "icao": "KRAP",
        "latitude": "44.0452995300293",
        "longitude": "-103.05699920654297",
        "altitude": "3204",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CLD": {
        "name": "Mc Clellan-Palomar Airport",
        "city": "Carlsbad",
        "country": "United States",
        "iata": "CLD",
        "icao": "KCRQ",
        "latitude": "33.12829971",
        "longitude": "-117.2799988",
        "altitude": "331",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "FNT": {
        "name": "Bishop International Airport",
        "city": "Flint",
        "country": "United States",
        "iata": "FNT",
        "icao": "KFNT",
        "latitude": "42.96540069580078",
        "longitude": "-83.74359893798828",
        "altitude": "782",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DVO": {
        "name": "Francisco Bangoy International Airport",
        "city": "Davao",
        "country": "Philippines",
        "iata": "DVO",
        "icao": "RPMD",
        "latitude": "7.1255202293396",
        "longitude": "125.64600372314453",
        "altitude": "96",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "FNC": {
        "name": "Madeira Airport",
        "city": "Funchal",
        "country": "Portugal",
        "iata": "FNC",
        "icao": "LPMA",
        "latitude": "32.697899",
        "longitude": "-16.7745",
        "altitude": "192",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Lisbon"
    },
    "STM": {
        "name": "Maestro Wilson Fonseca Airport",
        "city": "Santarem",
        "country": "Brazil",
        "iata": "STM",
        "icao": "SBSN",
        "latitude": "-2.4247219562530518",
        "longitude": "-54.785831451416016",
        "altitude": "198",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "KOS": {
        "name": "Sihanoukville International Airport",
        "city": "Sihanoukville",
        "country": "Cambodia",
        "iata": "KOS",
        "icao": "VDSV",
        "latitude": "10.57970047",
        "longitude": "103.637001038",
        "altitude": "33",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Phnom_Penh"
    },
    "YOA": {
        "name": "Ekati Airport",
        "city": "Ekati",
        "country": "Canada",
        "iata": "YOA",
        "icao": "CYOA",
        "latitude": "64.6988983154",
        "longitude": "-110.614997864",
        "altitude": "1536",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "NPE": {
        "name": "Hawke's Bay Airport",
        "city": "NAPIER",
        "country": "New Zealand",
        "iata": "NPE",
        "icao": "NZNR",
        "latitude": "-39.465801",
        "longitude": "176.869995",
        "altitude": "6",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "LEV": {
        "name": "Levuka Airfield",
        "city": "Levuka",
        "country": "Fiji",
        "iata": "LEV",
        "icao": "NFNB",
        "latitude": "-17.7110996246",
        "longitude": "178.759002686",
        "altitude": "11",
        "timezone": "12",
        "dst": "N",
        "tz_name": "Pacific/Fiji"
    },
    "LXA": {
        "name": "Lhasa Gonggar Airport",
        "city": "Lhasa",
        "country": "China",
        "iata": "LXA",
        "icao": "ZULS",
        "latitude": "29.2978000641",
        "longitude": "90.91190338130001",
        "altitude": "11713",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "RDD": {
        "name": "Redding Municipal Airport",
        "city": "Redding",
        "country": "United States",
        "iata": "RDD",
        "icao": "KRDD",
        "latitude": "40.50899887",
        "longitude": "-122.2929993",
        "altitude": "505",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "EUG": {
        "name": "Mahlon Sweet Field",
        "city": "Eugene",
        "country": "United States",
        "iata": "EUG",
        "icao": "KEUG",
        "latitude": "44.12459945678711",
        "longitude": "-123.21199798583984",
        "altitude": "374",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "IDA": {
        "name": "Idaho Falls Regional Airport",
        "city": "Idaho Falls",
        "country": "United States",
        "iata": "IDA",
        "icao": "KIDA",
        "latitude": "43.514599",
        "longitude": "-112.070999",
        "altitude": "4744",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MFR": {
        "name": "Rogue Valley International Medford Airport",
        "city": "Medford",
        "country": "United States",
        "iata": "MFR",
        "icao": "KMFR",
        "latitude": "42.37419891357422",
        "longitude": "-122.87300109863281",
        "altitude": "1335",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "KBZ": {
        "name": "Kaikoura Airport",
        "city": "Kaikoura",
        "country": "New Zealand",
        "iata": "KBZ",
        "icao": "NZKI",
        "latitude": "-42.42499923706055",
        "longitude": "173.60499572753906",
        "altitude": "20",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "RDM": {
        "name": "Roberts Field",
        "city": "Redmond-Bend",
        "country": "United States",
        "iata": "RDM",
        "icao": "KRDM",
        "latitude": "44.2541008",
        "longitude": "-121.1500015",
        "altitude": "3080",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "PCN": {
        "name": "Picton Aerodrome",
        "city": "Picton",
        "country": "New Zealand",
        "iata": "PCN",
        "icao": "NZPN",
        "latitude": "-41.346099853516",
        "longitude": "173.95599365234",
        "altitude": "161",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "WDH": {
        "name": "Hosea Kutako International Airport",
        "city": "Windhoek",
        "country": "Namibia",
        "iata": "WDH",
        "icao": "FYWH",
        "latitude": "-22.4799",
        "longitude": "17.4709",
        "altitude": "5640",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "YWH": {
        "name": "Victoria Harbour Seaplane Base",
        "city": "Victoria",
        "country": "Canada",
        "iata": "YWH",
        "icao": "CYWH",
        "latitude": "48.4249858939",
        "longitude": "-123.388867378",
        "altitude": "0",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "TNA": {
        "name": "Yaoqiang Airport",
        "city": "Jinan",
        "country": "China",
        "iata": "TNA",
        "icao": "ZSJN",
        "latitude": "36.857200622558594",
        "longitude": "117.21600341796875",
        "altitude": "76",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CZX": {
        "name": "Changzhou Benniu Airport",
        "city": "Changzhou",
        "country": "China",
        "iata": "CZX",
        "icao": "ZSCG",
        "latitude": "31.919701",
        "longitude": "119.778999",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YBP": {
        "name": "Yibin Caiba Airport",
        "city": "Yibin",
        "country": "China",
        "iata": "YBP",
        "icao": "ZUYB",
        "latitude": "28.8005555556",
        "longitude": "104.545",
        "altitude": "924",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TJM": {
        "name": "Roshchino International Airport",
        "city": "Tyumen",
        "country": "Russia",
        "iata": "TJM",
        "icao": "USTR",
        "latitude": "57.189601898199996",
        "longitude": "65.3243026733",
        "altitude": "378",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "CAK": {
        "name": "Akron Canton Regional Airport",
        "city": "Akron",
        "country": "United States",
        "iata": "CAK",
        "icao": "KCAK",
        "latitude": "40.916099548339844",
        "longitude": "-81.44219970703125",
        "altitude": "1228",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HSV": {
        "name": "Huntsville International Carl T Jones Field",
        "city": "Huntsville",
        "country": "United States",
        "iata": "HSV",
        "icao": "KHSV",
        "latitude": "34.637199401855",
        "longitude": "-86.775100708008",
        "altitude": "629",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PKB": {
        "name": "Mid Ohio Valley Regional Airport",
        "city": "PARKERSBURG",
        "country": "United States",
        "iata": "PKB",
        "icao": "KPKB",
        "latitude": "39.34510040283203",
        "longitude": "-81.43920135498047",
        "altitude": "858",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MGM": {
        "name": "Montgomery Regional (Dannelly Field) Airport",
        "city": "MONTGOMERY",
        "country": "United States",
        "iata": "MGM",
        "icao": "KMGM",
        "latitude": "32.30059814",
        "longitude": "-86.39399719",
        "altitude": "221",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TRI": {
        "name": "Tri-Cities Regional TN/VA Airport",
        "city": "BRISTOL",
        "country": "United States",
        "iata": "TRI",
        "icao": "KTRI",
        "latitude": "36.475201",
        "longitude": "-82.407401",
        "altitude": "1519",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PAH": {
        "name": "Barkley Regional Airport",
        "city": "PADUCAH",
        "country": "United States",
        "iata": "PAH",
        "icao": "KPAH",
        "latitude": "37.06079864501953",
        "longitude": "-88.7738037109375",
        "altitude": "410",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JIB": {
        "name": "Djibouti-Ambouli Airport",
        "city": "Djibouti",
        "country": "Djibouti",
        "iata": "JIB",
        "icao": "HDAM",
        "latitude": "11.547300338745117",
        "longitude": "43.15950012207031",
        "altitude": "49",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Djibouti"
    },
    "HAK": {
        "name": "Haikou Meilan International Airport",
        "city": "Haikou",
        "country": "China",
        "iata": "HAK",
        "icao": "ZJHK",
        "latitude": "19.934900283813477",
        "longitude": "110.45899963378906",
        "altitude": "75",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MFA": {
        "name": "Mafia Island Airport",
        "city": "Mafia Island",
        "country": "Tanzania",
        "iata": "MFA",
        "icao": "HTMA",
        "latitude": "-7.917478",
        "longitude": "39.668502",
        "altitude": "60",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "PGA": {
        "name": "Page Municipal Airport",
        "city": "Page",
        "country": "United States",
        "iata": "PGA",
        "icao": "KPGA",
        "latitude": "36.92610168",
        "longitude": "-111.447998",
        "altitude": "4316",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "UII": {
        "name": "Utila Airport",
        "city": "Utila",
        "country": "Honduras",
        "iata": "UII",
        "icao": "MHUT",
        "latitude": "16.1131",
        "longitude": "-86.880302",
        "altitude": "29",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "FCA": {
        "name": "Glacier Park International Airport",
        "city": "Kalispell",
        "country": "United States",
        "iata": "FCA",
        "icao": "KGPI",
        "latitude": "48.31050109863281",
        "longitude": "-114.25599670410156",
        "altitude": "2977",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MBS": {
        "name": "MBS International Airport",
        "city": "Saginaw",
        "country": "United States",
        "iata": "MBS",
        "icao": "KMBS",
        "latitude": "43.532901763916016",
        "longitude": "-84.07959747314453",
        "altitude": "668",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BGM": {
        "name": "Greater Binghamton/Edwin A Link field",
        "city": "Binghamton",
        "country": "United States",
        "iata": "BGM",
        "icao": "KBGM",
        "latitude": "42.20869827",
        "longitude": "-75.97979736",
        "altitude": "1636",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BGW": {
        "name": "Baghdad International Airport",
        "city": "Baghdad",
        "country": "Iraq",
        "iata": "BGW",
        "icao": "ORBI",
        "latitude": "33.262500762900004",
        "longitude": "44.2346000671",
        "altitude": "114",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "NNT": {
        "name": "Nan Airport",
        "city": "Nan",
        "country": "Thailand",
        "iata": "NNT",
        "icao": "VTCN",
        "latitude": "18.807899475097656",
        "longitude": "100.78299713134766",
        "altitude": "685",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "ROI": {
        "name": "Roi Et Airport",
        "city": "Roi Et",
        "country": "Thailand",
        "iata": "ROI",
        "icao": "VTUV",
        "latitude": "16.11680030822754",
        "longitude": "103.77400207519531",
        "altitude": "451",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "BFV": {
        "name": "Buri Ram Airport",
        "city": "Buri Ram",
        "country": "Thailand",
        "iata": "BFV",
        "icao": "VTUO",
        "latitude": "15.229499816894531",
        "longitude": "103.25299835205078",
        "altitude": "590",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "TDX": {
        "name": "Trat Airport",
        "city": "Trat",
        "country": "Thailand",
        "iata": "TDX",
        "icao": "VTBO",
        "latitude": "12.274600029",
        "longitude": "102.319000244",
        "altitude": "105",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "BLH": {
        "name": "Blythe Airport",
        "city": "Blythe",
        "country": "United States",
        "iata": "BLH",
        "icao": "KBLH",
        "latitude": "33.6192016602",
        "longitude": "-114.717002869",
        "altitude": "399",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "IQA": {
        "name": "Al Asad Air Base",
        "city": "Al Asad",
        "country": "Iraq",
        "iata": "IQA",
        "icao": "ORAA",
        "latitude": "33.7855987549",
        "longitude": "42.4412002563",
        "altitude": "618",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "TQD": {
        "name": "Al Taqaddum Air Base",
        "city": "Al Taqaddum",
        "country": "Iraq",
        "iata": "TQD",
        "icao": "ORAT",
        "latitude": "33.33810043335",
        "longitude": "43.597099304199",
        "altitude": "275",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "XQC": {
        "name": "Joint Base Balad",
        "city": "Al Bakr",
        "country": "Iraq",
        "iata": "XQC",
        "icao": "ORBD",
        "latitude": "33.940200805699995",
        "longitude": "44.361598968500005",
        "altitude": "161",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "CRK": {
        "name": "Diosdado Macapagal International Airport",
        "city": "Angeles City",
        "country": "Philippines",
        "iata": "CRK",
        "icao": "RPLC",
        "latitude": "15.186",
        "longitude": "120.559998",
        "altitude": "484",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "SDK": {
        "name": "Sandakan Airport",
        "city": "Sandakan",
        "country": "Malaysia",
        "iata": "SDK",
        "icao": "WBKS",
        "latitude": "5.900899887084961",
        "longitude": "118.05899810791016",
        "altitude": "46",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LXG": {
        "name": "Luang Namtha Airport",
        "city": "Luang Namtha",
        "country": "Laos",
        "iata": "LXG",
        "icao": "VLLN",
        "latitude": "20.966999",
        "longitude": "101.400002",
        "altitude": "1968",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "ODY": {
        "name": "Oudomsay Airport",
        "city": "Muang Xay",
        "country": "Laos",
        "iata": "ODY",
        "icao": "VLOS",
        "latitude": "20.68269920349121",
        "longitude": "101.99400329589844",
        "altitude": "1804",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Vientiane"
    },
    "SHE": {
        "name": "Taoxian Airport",
        "city": "Shenyang",
        "country": "China",
        "iata": "SHE",
        "icao": "ZYTX",
        "latitude": "41.639801025390625",
        "longitude": "123.48300170898438",
        "altitude": "198",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "DOY": {
        "name": "Dongying Shengli Airport",
        "city": "Dongying",
        "country": "China",
        "iata": "DOY",
        "icao": "ZSDY",
        "latitude": "37.5085983276",
        "longitude": "118.788002014",
        "altitude": "15",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MNI": {
        "name": "John A. Osborne Airport",
        "city": "Geralds",
        "country": "Montserrat",
        "iata": "MNI",
        "icao": "TRPG",
        "latitude": "16.791400909423828",
        "longitude": "-62.19329833984375",
        "altitude": "550",
        "timezone": "-4",
        "dst": "N",
        "tz_name": "America/Montserrat"
    },
    "PSG": {
        "name": "Petersburg James A Johnson Airport",
        "city": "Petersburg",
        "country": "United States",
        "iata": "PSG",
        "icao": "PAPG",
        "latitude": "56.80170059",
        "longitude": "-132.9450073",
        "altitude": "111",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LYA": {
        "name": "Luoyang Airport",
        "city": "Luoyang",
        "country": "China",
        "iata": "LYA",
        "icao": "ZHLY",
        "latitude": "34.741100311299995",
        "longitude": "112.388000488",
        "altitude": "840",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "XUZ": {
        "name": "Xuzhou Guanyin Airport",
        "city": "Xuzhou",
        "country": "China",
        "iata": "XUZ",
        "icao": "ZSXZ",
        "latitude": "34.059056",
        "longitude": "117.555278",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MWQ": {
        "name": "Magway Airport",
        "city": "Magwe",
        "country": "Burma",
        "iata": "MWQ",
        "icao": "VYMW",
        "latitude": "20.1656",
        "longitude": "94.941399",
        "altitude": "279",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "KHM": {
        "name": "Kanti Airport",
        "city": "Khamti",
        "country": "Burma",
        "iata": "KHM",
        "icao": "VYKI",
        "latitude": "25.988300323486328",
        "longitude": "95.67440032958984",
        "altitude": "6000",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "DLI": {
        "name": "Lien Khuong Airport",
        "city": "Dalat",
        "country": "Vietnam",
        "iata": "DLI",
        "icao": "VVDL",
        "latitude": "11.75",
        "longitude": "108.366997",
        "altitude": "3156",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VDH": {
        "name": "Dong Hoi Airport",
        "city": "Dong Hoi",
        "country": "Vietnam",
        "iata": "VDH",
        "icao": "VVDH",
        "latitude": "17.515",
        "longitude": "106.590556",
        "altitude": "59",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VKG": {
        "name": "Rach Gia Airport",
        "city": "Rach Gia",
        "country": "Vietnam",
        "iata": "VKG",
        "icao": "VVRG",
        "latitude": "9.95802997234",
        "longitude": "105.132379532",
        "altitude": "7",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "CAH": {
        "name": "Cà Mau Airport",
        "city": "Ca Mau",
        "country": "Vietnam",
        "iata": "CAH",
        "icao": "VVCM",
        "latitude": "9.177667",
        "longitude": "105.177778",
        "altitude": "6",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VCL": {
        "name": "Chu Lai International Airport",
        "city": "Chu Lai",
        "country": "Vietnam",
        "iata": "VCL",
        "icao": "VVCA",
        "latitude": "15.403300285299999",
        "longitude": "108.706001282",
        "altitude": "10",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "TBB": {
        "name": "Dong Tac Airport",
        "city": "Tuy Hoa",
        "country": "Vietnam",
        "iata": "TBB",
        "icao": "VVTH",
        "latitude": "13.049599647500001",
        "longitude": "109.333999634",
        "altitude": "20",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "PYY": {
        "name": "Mae Hong Son Airport",
        "city": "Pai",
        "country": "Thailand",
        "iata": "PYY",
        "icao": "VTCI",
        "latitude": "19.3719997406",
        "longitude": "98.43699646",
        "altitude": "1271",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "BWK": {
        "name": "Bol Airport",
        "city": "Brac",
        "country": "Croatia",
        "iata": "BWK",
        "icao": "LDSB",
        "latitude": "43.285701751708984",
        "longitude": "16.67970085144043",
        "altitude": "1776",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "NSI": {
        "name": "Yaoundé Nsimalen International Airport",
        "city": "Yaounde",
        "country": "Cameroon",
        "iata": "NSI",
        "icao": "FKYS",
        "latitude": "3.722559928894043",
        "longitude": "11.553299903869629",
        "altitude": "2278",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Douala"
    },
    "CKY": {
        "name": "Conakry International Airport",
        "city": "Conakry",
        "country": "Guinea",
        "iata": "CKY",
        "icao": "GUCY",
        "latitude": "9.57689",
        "longitude": "-13.612",
        "altitude": "72",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Conakry"
    },
    "AAH": {
        "name": "Aachen-Merzbrück Airport",
        "city": "Aachen",
        "country": "Germany",
        "iata": "AAH",
        "icao": "EDKA",
        "latitude": "50.823055267333984",
        "longitude": "6.186388969421387",
        "altitude": "623",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "FKB": {
        "name": "Karlsruhe Baden-Baden Airport",
        "city": "Karlsruhe/Baden-Baden",
        "country": "Germany",
        "iata": "FKB",
        "icao": "EDSB",
        "latitude": "48.7793998718",
        "longitude": "8.08049964905",
        "altitude": "408",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "SFB": {
        "name": "Orlando Sanford International Airport",
        "city": "Sanford",
        "country": "United States",
        "iata": "SFB",
        "icao": "KSFB",
        "latitude": "28.777599334716797",
        "longitude": "-81.23750305175781",
        "altitude": "55",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JST": {
        "name": "John Murtha Johnstown Cambria County Airport",
        "city": "Johnstown",
        "country": "United States",
        "iata": "JST",
        "icao": "KJST",
        "latitude": "40.31610107421875",
        "longitude": "-78.83390045166016",
        "altitude": "2284",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LUA": {
        "name": "Lukla Airport",
        "city": "Lukla",
        "country": "Nepal",
        "iata": "LUA",
        "icao": "VNLK",
        "latitude": "27.686899185180664",
        "longitude": "86.72969818115234",
        "altitude": "9380",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "BHP": {
        "name": "Bhojpur Airport",
        "city": "Bhojpur",
        "country": "Nepal",
        "iata": "BHP",
        "icao": "VNBJ",
        "latitude": "27.14739990234375",
        "longitude": "87.05079650878906",
        "altitude": "4000",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "LDN": {
        "name": "Lamidanda Airport",
        "city": "Lamidanda",
        "country": "Nepal",
        "iata": "LDN",
        "icao": "VNLD",
        "latitude": "27.25309944152832",
        "longitude": "86.66999816894531",
        "altitude": "4100",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "JMO": {
        "name": "Jomsom Airport",
        "city": "Jomsom",
        "country": "Nepal",
        "iata": "JMO",
        "icao": "VNJS",
        "latitude": "28.780426",
        "longitude": "83.723",
        "altitude": "8976",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "NGX": {
        "name": "Manang Airport",
        "city": "Manang",
        "country": "Nepal",
        "iata": "NGX",
        "icao": "VNMA",
        "latitude": "28.641399",
        "longitude": "84.089203",
        "altitude": "11001",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "PPL": {
        "name": "Phaplu Airport",
        "city": "Phaplu",
        "country": "Nepal",
        "iata": "PPL",
        "icao": "VNPL",
        "latitude": "27.517787",
        "longitude": "86.584454",
        "altitude": "7918",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "RUM": {
        "name": "Rumjatar Airport",
        "city": "Rumjatar",
        "country": "Nepal",
        "iata": "RUM",
        "icao": "VNRT",
        "latitude": "27.303499221801758",
        "longitude": "86.55039978027344",
        "altitude": "4500",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "DNP": {
        "name": "Tulsipur Airport",
        "city": "Dang",
        "country": "Nepal",
        "iata": "DNP",
        "icao": "VNDG",
        "latitude": "28.111099243164062",
        "longitude": "82.29419708251953",
        "altitude": "2100",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "RUK": {
        "name": "Rukum Chaurjahari Airport",
        "city": "Rukumkot",
        "country": "Nepal",
        "iata": "RUK",
        "icao": "VNRK",
        "latitude": "28.627001",
        "longitude": "82.195",
        "altitude": "2500",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "JUM": {
        "name": "Jumla Airport",
        "city": "Jumla",
        "country": "Nepal",
        "iata": "JUM",
        "icao": "VNJL",
        "latitude": "29.2742",
        "longitude": "82.193298",
        "altitude": "7700",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "TPJ": {
        "name": "Taplejung Airport",
        "city": "Taplejung",
        "country": "Nepal",
        "iata": "TPJ",
        "icao": "VNTJ",
        "latitude": "27.3509",
        "longitude": "87.69525",
        "altitude": "7990",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "TMI": {
        "name": "Tumling Tar Airport",
        "city": "Tumling Tar",
        "country": "Nepal",
        "iata": "TMI",
        "icao": "VNTR",
        "latitude": "27.315001",
        "longitude": "87.193298",
        "altitude": "1700",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "SKH": {
        "name": "Surkhet Airport",
        "city": "Surkhet",
        "country": "Nepal",
        "iata": "SKH",
        "icao": "VNSK",
        "latitude": "28.586",
        "longitude": "81.636002",
        "altitude": "2400",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "IMK": {
        "name": "Simikot Airport",
        "city": "Simikot",
        "country": "Nepal",
        "iata": "IMK",
        "icao": "VNST",
        "latitude": "29.971099853515625",
        "longitude": "81.81890106201172",
        "altitude": "9246",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "DOP": {
        "name": "Dolpa Airport",
        "city": "Dolpa",
        "country": "Nepal",
        "iata": "DOP",
        "icao": "VNDP",
        "latitude": "28.985700607299805",
        "longitude": "82.81909942626953",
        "altitude": "8200",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "BJH": {
        "name": "Bajhang Airport",
        "city": "Bajhang",
        "country": "Nepal",
        "iata": "BJH",
        "icao": "VNBG",
        "latitude": "29.538999557495117",
        "longitude": "81.1854019165039",
        "altitude": "4100",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "DHI": {
        "name": "Dhangarhi Airport",
        "city": "Dhangarhi",
        "country": "Nepal",
        "iata": "DHI",
        "icao": "VNDH",
        "latitude": "28.753299713134766",
        "longitude": "80.58190155029297",
        "altitude": "690",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "MWX": {
        "name": "Muan International Airport",
        "city": "Muan",
        "country": "South Korea",
        "iata": "MWX",
        "icao": "RKJB",
        "latitude": "34.991406",
        "longitude": "126.382814",
        "altitude": "35",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "JTY": {
        "name": "Astypalaia Airport",
        "city": "Astypalaia",
        "country": "Greece",
        "iata": "JTY",
        "icao": "LGPL",
        "latitude": "36.5798988342",
        "longitude": "26.3757991791",
        "altitude": "165",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JIK": {
        "name": "Ikaria Airport",
        "city": "Ikaria",
        "country": "Greece",
        "iata": "JIK",
        "icao": "LGIK",
        "latitude": "37.6827011108",
        "longitude": "26.3470993042",
        "altitude": "79",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JKL": {
        "name": "Kalymnos Airport",
        "city": "Kalymnos",
        "country": "Greece",
        "iata": "JKL",
        "icao": "LGKY",
        "latitude": "36.9632987976",
        "longitude": "26.9405994415",
        "altitude": "771",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "MLO": {
        "name": "Milos Airport",
        "city": "Milos",
        "country": "Greece",
        "iata": "MLO",
        "icao": "LGML",
        "latitude": "36.696899",
        "longitude": "24.4769",
        "altitude": "10",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "JNX": {
        "name": "Naxos Airport",
        "city": "Cyclades Islands",
        "country": "Greece",
        "iata": "JNX",
        "icao": "LGNX",
        "latitude": "37.0811004639",
        "longitude": "25.3680992126",
        "altitude": "10",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "PAS": {
        "name": "Paros National Airport",
        "city": "Paros",
        "country": "Greece",
        "iata": "PAS",
        "icao": "LGPA",
        "latitude": "37.020495",
        "longitude": "25.113195",
        "altitude": "131",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KZS": {
        "name": "Kastelorizo Airport",
        "city": "Kastelorizo",
        "country": "Greece",
        "iata": "KZS",
        "icao": "LGKJ",
        "latitude": "36.1417007446",
        "longitude": "29.576400756799995",
        "altitude": "489",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "RMF": {
        "name": "Marsa Alam International Airport",
        "city": "Marsa Alam",
        "country": "Egypt",
        "iata": "RMF",
        "icao": "HEMA",
        "latitude": "25.557100296",
        "longitude": "34.5836982727",
        "altitude": "251",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "NRN": {
        "name": "Weeze Airport",
        "city": "Weeze",
        "country": "Germany",
        "iata": "NRN",
        "icao": "EDLV",
        "latitude": "51.6024017334",
        "longitude": "6.14216995239",
        "altitude": "106",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "USU": {
        "name": "Francisco B. Reyes Airport",
        "city": "Busuanga",
        "country": "Philippines",
        "iata": "USU",
        "icao": "RPVV",
        "latitude": "12.1215000153",
        "longitude": "120.099998474",
        "altitude": "148",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "BXU": {
        "name": "Bancasi Airport",
        "city": "Butuan",
        "country": "Philippines",
        "iata": "BXU",
        "icao": "RPME",
        "latitude": "8.9515",
        "longitude": "125.4788",
        "altitude": "141",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "DPL": {
        "name": "Dipolog Airport",
        "city": "Dipolog",
        "country": "Philippines",
        "iata": "DPL",
        "icao": "RPMG",
        "latitude": "8.60198349877",
        "longitude": "123.341875076",
        "altitude": "12",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "LAO": {
        "name": "Laoag International Airport",
        "city": "Laoag",
        "country": "Philippines",
        "iata": "LAO",
        "icao": "RPLI",
        "latitude": "18.1781005859375",
        "longitude": "120.53199768066406",
        "altitude": "25",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "LGP": {
        "name": "Legazpi City International Airport",
        "city": "Legazpi",
        "country": "Philippines",
        "iata": "LGP",
        "icao": "RPLP",
        "latitude": "13.1575",
        "longitude": "123.735",
        "altitude": "66",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "OZC": {
        "name": "Labo Airport",
        "city": "Ozamis",
        "country": "Philippines",
        "iata": "OZC",
        "icao": "RPMO",
        "latitude": "8.178509712219238",
        "longitude": "123.84200286865234",
        "altitude": "75",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "CEB": {
        "name": "Mactan Cebu International Airport",
        "city": "Cebu",
        "country": "Philippines",
        "iata": "CEB",
        "icao": "RPVM",
        "latitude": "10.307499885559",
        "longitude": "123.97899627686",
        "altitude": "31",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "NOD": {
        "name": "Norden-Norddeich Airport",
        "city": "Norden",
        "country": "Germany",
        "iata": "NOD",
        "icao": "EDWS",
        "latitude": "53.633056640599996",
        "longitude": "7.19027805328",
        "altitude": "3",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "JUI": {
        "name": "Juist Airport",
        "city": "Juist",
        "country": "Germany",
        "iata": "JUI",
        "icao": "EDWJ",
        "latitude": "53.68111038208008",
        "longitude": "7.055832862854004",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BPS": {
        "name": "Porto Seguro Airport",
        "city": "Porto Seguro",
        "country": "Brazil",
        "iata": "BPS",
        "icao": "SBPS",
        "latitude": "-16.438601",
        "longitude": "-39.080898",
        "altitude": "168",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "QIG": {
        "name": "Iguatu Airport",
        "city": "Iguatu",
        "country": "Brazil",
        "iata": "QIG",
        "icao": "SNIG",
        "latitude": "-6.346640110015869",
        "longitude": "-39.293800354003906",
        "altitude": "699",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "PMW": {
        "name": "Brigadeiro Lysias Rodrigues Airport",
        "city": "Palmas",
        "country": "Brazil",
        "iata": "PMW",
        "icao": "SBPJ",
        "latitude": "-10.291500091600001",
        "longitude": "-48.35699844359999",
        "altitude": "774",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CLV": {
        "name": "Nelson Ribeiro Guimarães Airport",
        "city": "Caldas Novas",
        "country": "Brazil",
        "iata": "CLV",
        "icao": "SBCN",
        "latitude": "-17.725299835205",
        "longitude": "-48.607498168945",
        "altitude": "2247",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MSO": {
        "name": "Missoula International Airport",
        "city": "Missoula",
        "country": "United States",
        "iata": "MSO",
        "icao": "KMSO",
        "latitude": "46.91630173",
        "longitude": "-114.0910034",
        "altitude": "3206",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BKQ": {
        "name": "Blackall Airport",
        "city": "Blackall",
        "country": "Australia",
        "iata": "BKQ",
        "icao": "YBCK",
        "latitude": "-24.427799224900003",
        "longitude": "145.429000854",
        "altitude": "928",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BDB": {
        "name": "Bundaberg Airport",
        "city": "Bundaberg",
        "country": "Australia",
        "iata": "BDB",
        "icao": "YBUD",
        "latitude": "-24.903900146499996",
        "longitude": "152.319000244",
        "altitude": "107",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "GCN": {
        "name": "Grand Canyon National Park Airport",
        "city": "Grand Canyon",
        "country": "United States",
        "iata": "GCN",
        "icao": "KGCN",
        "latitude": "35.95240020751953",
        "longitude": "-112.14700317382812",
        "altitude": "6609",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "SGR": {
        "name": "Sugar Land Regional Airport",
        "city": "Sugar Land",
        "country": "United States",
        "iata": "SGR",
        "icao": "KSGR",
        "latitude": "29.622299194336",
        "longitude": "-95.65650177002",
        "altitude": "82",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "APA": {
        "name": "Centennial Airport",
        "city": "Denver",
        "country": "United States",
        "iata": "APA",
        "icao": "KAPA",
        "latitude": "39.57009888",
        "longitude": "-104.848999",
        "altitude": "5885",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CVN": {
        "name": "Clovis Municipal Airport",
        "city": "Clovis",
        "country": "United States",
        "iata": "CVN",
        "icao": "KCVN",
        "latitude": "34.4250984192",
        "longitude": "-103.07900238",
        "altitude": "4216",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "FST": {
        "name": "Fort Stockton Pecos County Airport",
        "city": "Fort Stockton",
        "country": "United States",
        "iata": "FST",
        "icao": "KFST",
        "latitude": "30.9157009125",
        "longitude": "-102.916000366",
        "altitude": "3011",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LVS": {
        "name": "Las Vegas Municipal Airport",
        "city": "Las Vegas",
        "country": "United States",
        "iata": "LVS",
        "icao": "KLVS",
        "latitude": "35.6542015076",
        "longitude": "-105.141998291",
        "altitude": "6877",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "IWS": {
        "name": "West Houston Airport",
        "city": "Houston",
        "country": "United States",
        "iata": "IWS",
        "icao": "KIWS",
        "latitude": "29.818199157699997",
        "longitude": "-95.67259979250001",
        "altitude": "111",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LRU": {
        "name": "Las Cruces International Airport",
        "city": "Las Cruces",
        "country": "United States",
        "iata": "LRU",
        "icao": "KLRU",
        "latitude": "32.289398193359375",
        "longitude": "-106.9219970703125",
        "altitude": "4456",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BKD": {
        "name": "Stephens County Airport",
        "city": "Breckenridge",
        "country": "United States",
        "iata": "BKD",
        "icao": "KBKD",
        "latitude": "32.71900177",
        "longitude": "-98.89099884030001",
        "altitude": "1284",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TPL": {
        "name": "Draughon Miller Central Texas Regional Airport",
        "city": "Temple",
        "country": "United States",
        "iata": "TPL",
        "icao": "KTPL",
        "latitude": "31.15250015258789",
        "longitude": "-97.40779876708984",
        "altitude": "682",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OZA": {
        "name": "Ozona Municipal Airport",
        "city": "Ozona",
        "country": "United States",
        "iata": "OZA",
        "icao": "KOZA",
        "latitude": "30.735300064087",
        "longitude": "-101.20300292969",
        "altitude": "2381",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KDM": {
        "name": "Kaadedhdhoo Airport",
        "city": "Kaadedhdhoo",
        "country": "Maldives",
        "iata": "KDM",
        "icao": "VRMT",
        "latitude": "0.48813098669052124",
        "longitude": "72.99690246582031",
        "altitude": "2",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "LAK": {
        "name": "Aklavik/Freddie Carmichael Airport",
        "city": "Aklavik",
        "country": "Canada",
        "iata": "LAK",
        "icao": "CYKD",
        "latitude": "68.223297",
        "longitude": "-135.00599",
        "altitude": "23",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YWJ": {
        "name": "Déline Airport",
        "city": "Deline",
        "country": "Canada",
        "iata": "YWJ",
        "icao": "CYWJ",
        "latitude": "65.21109771728516",
        "longitude": "-123.43599700927734",
        "altitude": "703",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "ZFN": {
        "name": "Tulita Airport",
        "city": "Tulita",
        "country": "Canada",
        "iata": "ZFN",
        "icao": "CZFN",
        "latitude": "64.909697",
        "longitude": "-125.572998",
        "altitude": "332",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YGH": {
        "name": "Fort Good Hope Airport",
        "city": "Fort Good Hope",
        "country": "Canada",
        "iata": "YGH",
        "icao": "CYGH",
        "latitude": "66.24079895019531",
        "longitude": "-128.6510009765625",
        "altitude": "268",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "TAH": {
        "name": "Tanna Airport",
        "city": "Tanna",
        "country": "Vanuatu",
        "iata": "TAH",
        "icao": "NVVW",
        "latitude": "-19.45509910583496",
        "longitude": "169.2239990234375",
        "altitude": "19",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "YPC": {
        "name": "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
        "city": "Paulatuk",
        "country": "Canada",
        "iata": "YPC",
        "icao": "CYPC",
        "latitude": "69.3608381154",
        "longitude": "-124.075469971",
        "altitude": "15",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "SRZ": {
        "name": "El Trompillo Airport",
        "city": "Santa Cruz",
        "country": "Bolivia",
        "iata": "SRZ",
        "icao": "SLET",
        "latitude": "-17.8115997314",
        "longitude": "-63.1715011597",
        "altitude": "1371",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "SAB": {
        "name": "Juancho E. Yrausquin Airport",
        "city": "Saba",
        "country": "Netherlands Antilles",
        "iata": "SAB",
        "icao": "TNCS",
        "latitude": "17.645000457763672",
        "longitude": "-63.220001220703125",
        "altitude": "60",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Curacao"
    },
    "EGE": {
        "name": "Eagle County Regional Airport",
        "city": "Vail",
        "country": "United States",
        "iata": "EGE",
        "icao": "KEGE",
        "latitude": "39.64260101",
        "longitude": "-106.9179993",
        "altitude": "6548",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SKN": {
        "name": "Stokmarknes Skagen Airport",
        "city": "Stokmarknes",
        "country": "Norway",
        "iata": "SKN",
        "icao": "ENSK",
        "latitude": "68.578826904297",
        "longitude": "15.033416748047",
        "altitude": "11",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "CGF": {
        "name": "Cuyahoga County Airport",
        "city": "Richmond Heights",
        "country": "United States",
        "iata": "CGF",
        "icao": "KCGF",
        "latitude": "41.5651016235",
        "longitude": "-81.4863967896",
        "altitude": "879",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MFD": {
        "name": "Mansfield Lahm Regional Airport",
        "city": "Mansfield",
        "country": "United States",
        "iata": "MFD",
        "icao": "KMFD",
        "latitude": "40.82139968869999",
        "longitude": "-82.5166015625",
        "altitude": "1297",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CSG": {
        "name": "Columbus Metropolitan Airport",
        "city": "Columbus",
        "country": "United States",
        "iata": "CSG",
        "icao": "KCSG",
        "latitude": "32.516300201416016",
        "longitude": "-84.93890380859375",
        "altitude": "397",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LAW": {
        "name": "Lawton Fort Sill Regional Airport",
        "city": "Lawton",
        "country": "United States",
        "iata": "LAW",
        "icao": "KLAW",
        "latitude": "34.5676994324",
        "longitude": "-98.4166030884",
        "altitude": "1110",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FNL": {
        "name": "Northern Colorado Regional Airport",
        "city": "Fort Collins",
        "country": "United States",
        "iata": "FNL",
        "icao": "KFNL",
        "latitude": "40.451804",
        "longitude": "-105.011",
        "altitude": "5016",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "FLG": {
        "name": "Flagstaff Pulliam Airport",
        "city": "Flagstaff",
        "country": "United States",
        "iata": "FLG",
        "icao": "KFLG",
        "latitude": "35.13850021",
        "longitude": "-111.6709976",
        "altitude": "7014",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "TVL": {
        "name": "Lake Tahoe Airport",
        "city": "South Lake Tahoe",
        "country": "United States",
        "iata": "TVL",
        "icao": "KTVL",
        "latitude": "38.89390182495117",
        "longitude": "-119.99500274658203",
        "altitude": "6264",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "TWF": {
        "name": "Joslin Field Magic Valley Regional Airport",
        "city": "Twin Falls",
        "country": "United States",
        "iata": "TWF",
        "icao": "KTWF",
        "latitude": "42.4818",
        "longitude": "-114.487999",
        "altitude": "4154",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MVY": {
        "name": "Martha's Vineyard Airport",
        "city": "Vineyard Haven MA",
        "country": "United States",
        "iata": "MVY",
        "icao": "KMVY",
        "latitude": "41.3931007385",
        "longitude": "-70.6143035889",
        "altitude": "67",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CON": {
        "name": "Concord Municipal Airport",
        "city": "Concord NH",
        "country": "United States",
        "iata": "CON",
        "icao": "KCON",
        "latitude": "43.20270157",
        "longitude": "-71.50229645",
        "altitude": "342",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GON": {
        "name": "Groton New London Airport",
        "city": "Groton CT",
        "country": "United States",
        "iata": "GON",
        "icao": "KGON",
        "latitude": "41.330101013183594",
        "longitude": "-72.04509735107422",
        "altitude": "9",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "STC": {
        "name": "St Cloud Regional Airport",
        "city": "Saint Cloud",
        "country": "United States",
        "iata": "STC",
        "icao": "KSTC",
        "latitude": "45.546600341796875",
        "longitude": "-94.05989837646484",
        "altitude": "1031",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BPE": {
        "name": "Qinhuangdao Beidaihe Airport",
        "city": "Bagan",
        "country": "Burma",
        "iata": "BPE",
        "icao": "ZBDH",
        "latitude": "39.666389",
        "longitude": "119.058889",
        "altitude": "46",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "GTR": {
        "name": "Golden Triangle Regional Airport",
        "city": "Columbus Mississippi",
        "country": "United States",
        "iata": "GTR",
        "icao": "KGTR",
        "latitude": "33.450298309299995",
        "longitude": "-88.5914001465",
        "altitude": "264",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GOJ": {
        "name": "Nizhny Novgorod Strigino International Airport",
        "city": "Nizhniy Novgorod",
        "country": "Russia",
        "iata": "GOJ",
        "icao": "UWGG",
        "latitude": "56.230098724365",
        "longitude": "43.784000396729",
        "altitude": "256",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "HQM": {
        "name": "Bowerman Airport",
        "city": "Hoquiam",
        "country": "United States",
        "iata": "HQM",
        "icao": "KHQM",
        "latitude": "46.971199035599994",
        "longitude": "-123.93699646",
        "altitude": "18",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ERI": {
        "name": "Erie International Tom Ridge Field",
        "city": "Erie",
        "country": "United States",
        "iata": "ERI",
        "icao": "KERI",
        "latitude": "42.0831270134",
        "longitude": "-80.1738667488",
        "altitude": "732",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HYA": {
        "name": "Barnstable Municipal Boardman Polando Field",
        "city": "Barnstable",
        "country": "United States",
        "iata": "HYA",
        "icao": "KHYA",
        "latitude": "41.66930008",
        "longitude": "-70.28040314",
        "altitude": "54",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SPR": {
        "name": "San Pedro Airport",
        "city": "San Pedro",
        "country": "Belize",
        "iata": "SPR",
        "icao": "MZSP",
        "latitude": "17.9139",
        "longitude": "-87.9711",
        "altitude": "4",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Belize"
    },
    "SDX": {
        "name": "Sedona Airport",
        "city": "Sedona",
        "country": "United States",
        "iata": "SDX",
        "icao": "KSEZ",
        "latitude": "34.848598480225",
        "longitude": "-111.78800201416",
        "altitude": "4830",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "MGW": {
        "name": "Morgantown Municipal Walter L. Bill Hart Field",
        "city": "Morgantown",
        "country": "United States",
        "iata": "MGW",
        "icao": "KMGW",
        "latitude": "39.64289856",
        "longitude": "-79.91629791",
        "altitude": "1248",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CRW": {
        "name": "Yeager Airport",
        "city": "Charleston",
        "country": "United States",
        "iata": "CRW",
        "icao": "KCRW",
        "latitude": "38.37310028076172",
        "longitude": "-81.59320068359375",
        "altitude": "981",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AVP": {
        "name": "Wilkes Barre Scranton International Airport",
        "city": "Scranton",
        "country": "United States",
        "iata": "AVP",
        "icao": "KAVP",
        "latitude": "41.338500976599995",
        "longitude": "-75.72339630130001",
        "altitude": "962",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BJI": {
        "name": "Bemidji Regional Airport",
        "city": "Bemidji",
        "country": "United States",
        "iata": "BJI",
        "icao": "KBJI",
        "latitude": "47.50939941",
        "longitude": "-94.93370056",
        "altitude": "1391",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "THG": {
        "name": "Thangool Airport",
        "city": "Biloela",
        "country": "Australia",
        "iata": "THG",
        "icao": "YTNG",
        "latitude": "-24.493900299072266",
        "longitude": "150.5760040283203",
        "altitude": "644",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "FGI": {
        "name": "Fagali'i Airport",
        "city": "Apia",
        "country": "Samoa",
        "iata": "FGI",
        "icao": "NSFI",
        "latitude": "-13.848699569699999",
        "longitude": "-171.740005493",
        "altitude": "131",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Apia"
    },
    "BNK": {
        "name": "Ballina Byron Gateway Airport",
        "city": "Ballina Byron Bay",
        "country": "Australia",
        "iata": "BNK",
        "icao": "YBNA",
        "latitude": "-28.8339004517",
        "longitude": "153.56199646",
        "altitude": "7",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "FAR": {
        "name": "Hector International Airport",
        "city": "Fargo",
        "country": "United States",
        "iata": "FAR",
        "icao": "KFAR",
        "latitude": "46.92070007324219",
        "longitude": "-96.81580352783203",
        "altitude": "902",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MKC": {
        "name": "Charles B. Wheeler Downtown Airport",
        "city": "Kansas City",
        "country": "United States",
        "iata": "MKC",
        "icao": "KMKC",
        "latitude": "39.123199462890625",
        "longitude": "-94.5927963256836",
        "altitude": "759",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RBE": {
        "name": "Ratanakiri Airport",
        "city": "Ratanakiri",
        "country": "Cambodia",
        "iata": "RBE",
        "icao": "VDRK",
        "latitude": "13.729999542236328",
        "longitude": "106.98699951171875",
        "altitude": "0",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Phnom_Penh"
    },
    "GCC": {
        "name": "Gillette Campbell County Airport",
        "city": "Gillette",
        "country": "United States",
        "iata": "GCC",
        "icao": "KGCC",
        "latitude": "44.348899841299996",
        "longitude": "-105.539001465",
        "altitude": "4365",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "TOF": {
        "name": "Bogashevo Airport",
        "city": "Tomsk",
        "country": "Russia",
        "iata": "TOF",
        "icao": "UNTT",
        "latitude": "56.380298614502",
        "longitude": "85.208297729492",
        "altitude": "597",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "NZJ": {
        "name": "El Toro Marine Corps Air Station",
        "city": "Santa Ana",
        "country": "United States",
        "iata": "NZJ",
        "icao": "KNZJ",
        "latitude": "33.67610168457031",
        "longitude": "-117.73100280761719",
        "altitude": "383",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "PHY": {
        "name": "Phetchabun Airport",
        "city": "Phetchabun",
        "country": "Thailand",
        "iata": "PHY",
        "icao": "VTPB",
        "latitude": "16.6760005951",
        "longitude": "101.194999695",
        "altitude": "450",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "CJM": {
        "name": "Chumphon Airport",
        "city": "Chumphon",
        "country": "Thailand",
        "iata": "CJM",
        "icao": "VTSE",
        "latitude": "10.711199760437012",
        "longitude": "99.36170196533203",
        "altitude": "18",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "JZH": {
        "name": "Jiuzhai Huanglong Airport",
        "city": "Jiuzhaigou",
        "country": "China",
        "iata": "JZH",
        "icao": "ZUJZ",
        "latitude": "32.8533333333",
        "longitude": "103.682222222",
        "altitude": "11327",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "SWA": {
        "name": "Jieyang Chaoshan International Airport",
        "city": "Shantou",
        "country": "China",
        "iata": "SWA",
        "icao": "ZGOW",
        "latitude": "23.552",
        "longitude": "116.5033",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "GEO": {
        "name": "Cheddi Jagan International Airport",
        "city": "Georgetown",
        "country": "Guyana",
        "iata": "GEO",
        "icao": "SYCJ",
        "latitude": "6.498549938201904",
        "longitude": "-58.25410079956055",
        "altitude": "95",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "AGT": {
        "name": "Guarani International Airport",
        "city": "Ciudad del Este",
        "country": "Paraguay",
        "iata": "AGT",
        "icao": "SGES",
        "latitude": "-25.454516",
        "longitude": "-54.842682",
        "altitude": "846",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Asuncion"
    },
    "OGL": {
        "name": "Eugene F. Correira International Airport",
        "city": "Georgetown",
        "country": "Guyana",
        "iata": "OGL",
        "icao": "SYGO",
        "latitude": "6.80628",
        "longitude": "-58.1059",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "KAI": {
        "name": "Kaieteur International Airport",
        "city": "Kaieteur",
        "country": "Guyana",
        "iata": "KAI",
        "icao": "PKSA",
        "latitude": "5.17275476456",
        "longitude": "-59.491481781",
        "altitude": "1520",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "DNH": {
        "name": "Dunhuang Airport",
        "city": "Dunhuang",
        "country": "China",
        "iata": "DNH",
        "icao": "ZLDH",
        "latitude": "40.16109848022461",
        "longitude": "94.80919647216797",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "AOI": {
        "name": "Ancona Falconara Airport",
        "city": "Ancona",
        "country": "Italy",
        "iata": "AOI",
        "icao": "LIPY",
        "latitude": "43.616299",
        "longitude": "13.3623",
        "altitude": "49",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "TCP": {
        "name": "Taba International Airport",
        "city": "Taba",
        "country": "Egypt",
        "iata": "TCP",
        "icao": "HETB",
        "latitude": "29.587799072299998",
        "longitude": "34.7780990601",
        "altitude": "2415",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "LYB": {
        "name": "Edward Bodden Airfield",
        "city": "Little Cayman",
        "country": "Cayman Islands",
        "iata": "LYB",
        "icao": "MWCL",
        "latitude": "19.660161",
        "longitude": "-80.088826",
        "altitude": "3",
        "timezone": "-5",
        "dst": "N",
        "tz_name": "America/Cayman"
    },
    "BJV": {
        "name": "Milas Bodrum International Airport",
        "city": "Bodrum",
        "country": "Turkey",
        "iata": "BJV",
        "icao": "LTFE",
        "latitude": "37.25059890749999",
        "longitude": "27.6643009186",
        "altitude": "21",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "TBJ": {
        "name": "Tabarka 7 Novembre Airport",
        "city": "Tabarka",
        "country": "Tunisia",
        "iata": "TBJ",
        "icao": "DTKA",
        "latitude": "36.97999954223633",
        "longitude": "8.87693977355957",
        "altitude": "230",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Africa/Tunis"
    },
    "SAW": {
        "name": "Sabiha Gökçen International Airport",
        "city": "Istanbul",
        "country": "Turkey",
        "iata": "SAW",
        "icao": "LTFJ",
        "latitude": "40.898601532",
        "longitude": "29.3092002869",
        "altitude": "312",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "SCE": {
        "name": "University Park Airport",
        "city": "State College Pennsylvania",
        "country": "United States",
        "iata": "SCE",
        "icao": "KUNV",
        "latitude": "40.8493003845",
        "longitude": "-77.84870147710001",
        "altitude": "1239",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BME": {
        "name": "Broome International Airport",
        "city": "Broome",
        "country": "Australia",
        "iata": "BME",
        "icao": "YBRM",
        "latitude": "-17.944700241088867",
        "longitude": "122.23200225830078",
        "altitude": "56",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "NTL": {
        "name": "Newcastle Airport",
        "city": "Newcastle",
        "country": "Australia",
        "iata": "NTL",
        "icao": "YWLM",
        "latitude": "-32.79499816894531",
        "longitude": "151.83399963378906",
        "altitude": "31",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "KLU": {
        "name": "Klagenfurt Airport",
        "city": "Klagenfurt",
        "country": "Austria",
        "iata": "KLU",
        "icao": "LOWK",
        "latitude": "46.642502",
        "longitude": "14.3377",
        "altitude": "1472",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "HFT": {
        "name": "Hammerfest Airport",
        "city": "Hammerfest",
        "country": "Norway",
        "iata": "HFT",
        "icao": "ENHF",
        "latitude": "70.679702758789",
        "longitude": "23.668600082397",
        "altitude": "266",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "HVG": {
        "name": "Valan Airport",
        "city": "Honningsvag",
        "country": "Norway",
        "iata": "HVG",
        "icao": "ENHV",
        "latitude": "71.009696960449",
        "longitude": "25.983600616455",
        "altitude": "44",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "MEH": {
        "name": "Mehamn Airport",
        "city": "Mehamn",
        "country": "Norway",
        "iata": "MEH",
        "icao": "ENMH",
        "latitude": "71.02970123291",
        "longitude": "27.826700210571",
        "altitude": "39",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "VDS": {
        "name": "Vadsø Airport",
        "city": "Vadsø",
        "country": "Norway",
        "iata": "VDS",
        "icao": "ENVD",
        "latitude": "70.065299987793",
        "longitude": "29.844699859619",
        "altitude": "127",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "IKA": {
        "name": "Imam Khomeini International Airport",
        "city": "Tehran",
        "country": "Iran",
        "iata": "IKA",
        "icao": "OIIE",
        "latitude": "35.416099548339844",
        "longitude": "51.152198791503906",
        "altitude": "3305",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "MHD": {
        "name": "Mashhad International Airport",
        "city": "Mashhad",
        "country": "Iran",
        "iata": "MHD",
        "icao": "OIMM",
        "latitude": "36.235198974609375",
        "longitude": "59.64099884033203",
        "altitude": "3263",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "UIK": {
        "name": "Ust-Ilimsk Airport",
        "city": "Ust Ilimsk",
        "country": "Russia",
        "iata": "UIK",
        "icao": "UIBS",
        "latitude": "58.13610076904297",
        "longitude": "102.56500244140625",
        "altitude": "1339",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "MEI": {
        "name": "Key Field",
        "city": "Meridian",
        "country": "United States",
        "iata": "MEI",
        "icao": "KMEI",
        "latitude": "32.33259963989258",
        "longitude": "-88.75189971923828",
        "altitude": "297",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SPI": {
        "name": "Abraham Lincoln Capital Airport",
        "city": "Springfield",
        "country": "United States",
        "iata": "SPI",
        "icao": "KSPI",
        "latitude": "39.84410095",
        "longitude": "-89.67790222",
        "altitude": "598",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CEZ": {
        "name": "Cortez Municipal Airport",
        "city": "Cortez",
        "country": "United States",
        "iata": "CEZ",
        "icao": "KCEZ",
        "latitude": "37.3030014038",
        "longitude": "-108.627998352",
        "altitude": "5918",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "HDN": {
        "name": "Yampa Valley Airport",
        "city": "Hayden",
        "country": "United States",
        "iata": "HDN",
        "icao": "KHDN",
        "latitude": "40.48120117",
        "longitude": "-107.2180023",
        "altitude": "6606",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "GUP": {
        "name": "Gallup Municipal Airport",
        "city": "Gallup",
        "country": "United States",
        "iata": "GUP",
        "icao": "KGUP",
        "latitude": "35.511100769",
        "longitude": "-108.789001465",
        "altitude": "6472",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "LBL": {
        "name": "Liberal Mid-America Regional Airport",
        "city": "Liberal",
        "country": "United States",
        "iata": "LBL",
        "icao": "KLBL",
        "latitude": "37.0442009",
        "longitude": "-100.9599991",
        "altitude": "2885",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LAA": {
        "name": "Lamar Municipal Airport",
        "city": "Lamar",
        "country": "United States",
        "iata": "LAA",
        "icao": "KLAA",
        "latitude": "38.069698333699996",
        "longitude": "-102.68800354",
        "altitude": "3706",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "GLD": {
        "name": "Renner Field-Goodland Municipal Airport",
        "city": "Goodland",
        "country": "United States",
        "iata": "GLD",
        "icao": "KGLD",
        "latitude": "39.37060165",
        "longitude": "-101.6989975",
        "altitude": "3656",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "COD": {
        "name": "Yellowstone Regional Airport",
        "city": "Cody",
        "country": "United States",
        "iata": "COD",
        "icao": "KCOD",
        "latitude": "44.520198822",
        "longitude": "-109.024002075",
        "altitude": "5102",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "HOV": {
        "name": "Ørsta-Volda Airport, Hovden",
        "city": "Orsta-Volda",
        "country": "Norway",
        "iata": "HOV",
        "icao": "ENOV",
        "latitude": "62.180000305176",
        "longitude": "6.0741000175476",
        "altitude": "243",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "ISC": {
        "name": "St. Mary's Airport",
        "city": "ST MARY\\'S",
        "country": "United Kingdom",
        "iata": "ISC",
        "icao": "EGHE",
        "latitude": "49.913299560546875",
        "longitude": "-6.291669845581055",
        "altitude": "116",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SGF": {
        "name": "Springfield Branson National Airport",
        "city": "Springfield",
        "country": "United States",
        "iata": "SGF",
        "icao": "KSGF",
        "latitude": "37.24570084",
        "longitude": "-93.38860321",
        "altitude": "1268",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NVK": {
        "name": "Narvik Framnes Airport",
        "city": "Narvik",
        "country": "Norway",
        "iata": "NVK",
        "icao": "ENNK",
        "latitude": "68.436897277832",
        "longitude": "17.386699676514",
        "altitude": "95",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BVG": {
        "name": "Berlevåg Airport",
        "city": "Berlevag",
        "country": "Norway",
        "iata": "BVG",
        "icao": "ENBV",
        "latitude": "70.871399",
        "longitude": "29.034201",
        "altitude": "42",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "FBU": {
        "name": "Oslo, Fornebu Airport",
        "city": "Oslo",
        "country": "Norway",
        "iata": "FBU",
        "icao": "ENFB",
        "latitude": "59.89580154418945",
        "longitude": "10.617199897766113",
        "altitude": "0",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "NSK": {
        "name": "Norilsk-Alykel Airport",
        "city": "Norilsk",
        "country": "Russia",
        "iata": "NSK",
        "icao": "UOOO",
        "latitude": "69.31109619140625",
        "longitude": "87.33219909667969",
        "altitude": "574",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "AAQ": {
        "name": "Anapa Vityazevo Airport",
        "city": "Anapa",
        "country": "Russia",
        "iata": "AAQ",
        "icao": "URKA",
        "latitude": "45.002101898193",
        "longitude": "37.347301483154",
        "altitude": "174",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "JLN": {
        "name": "Joplin Regional Airport",
        "city": "Joplin",
        "country": "United States",
        "iata": "JLN",
        "icao": "KJLN",
        "latitude": "37.151798248291016",
        "longitude": "-94.49829864501953",
        "altitude": "981",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ABE": {
        "name": "Lehigh Valley International Airport",
        "city": "Allentown",
        "country": "United States",
        "iata": "ABE",
        "icao": "KABE",
        "latitude": "40.652099609375",
        "longitude": "-75.44080352783203",
        "altitude": "393",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "XNA": {
        "name": "Northwest Arkansas Regional Airport",
        "city": "Bentonville",
        "country": "United States",
        "iata": "XNA",
        "icao": "KXNA",
        "latitude": "36.281898",
        "longitude": "-94.306801",
        "altitude": "1287",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GUW": {
        "name": "Atyrau Airport",
        "city": "Atyrau",
        "country": "Kazakhstan",
        "iata": "GUW",
        "icao": "UATG",
        "latitude": "47.12189865112305",
        "longitude": "51.8213996887207",
        "altitude": "-72",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Oral"
    },
    "KZO": {
        "name": "Kzyl-Orda Southwest Airport",
        "city": "Kzyl-Orda",
        "country": "Kazakhstan",
        "iata": "KZO",
        "icao": "UAOO",
        "latitude": "44.706902",
        "longitude": "65.592499",
        "altitude": "433",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "SBN": {
        "name": "South Bend Regional Airport",
        "city": "South Bend",
        "country": "United States",
        "iata": "SBN",
        "icao": "KSBN",
        "latitude": "41.70869827270508",
        "longitude": "-86.31729888916016",
        "altitude": "799",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BKA": {
        "name": "Bykovo Airport",
        "city": "Moscow",
        "country": "Russia",
        "iata": "BKA",
        "icao": "UUBB",
        "latitude": "55.6171989441",
        "longitude": "38.0600013733",
        "altitude": "427",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ARH": {
        "name": "Talagi Airport",
        "city": "Arkhangelsk",
        "country": "Russia",
        "iata": "ARH",
        "icao": "ULAA",
        "latitude": "64.60030364990234",
        "longitude": "40.71670150756836",
        "altitude": "62",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "RTW": {
        "name": "Saratov Central Airport",
        "city": "Saratov",
        "country": "Russia",
        "iata": "RTW",
        "icao": "UWSS",
        "latitude": "51.564998626708984",
        "longitude": "46.04669952392578",
        "altitude": "499",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "NUX": {
        "name": "Novy Urengoy Airport",
        "city": "Novy Urengoy",
        "country": "Russia",
        "iata": "NUX",
        "icao": "USMU",
        "latitude": "66.06939697265625",
        "longitude": "76.52030181884766",
        "altitude": "210",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "NOJ": {
        "name": "Noyabrsk Airport",
        "city": "Noyabrsk",
        "country": "Russia",
        "iata": "NOJ",
        "icao": "USRO",
        "latitude": "63.18330001831055",
        "longitude": "75.2699966430664",
        "altitude": "446",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "SCO": {
        "name": "Aktau Airport",
        "city": "Aktau",
        "country": "Kazakhstan",
        "iata": "SCO",
        "icao": "UATE",
        "latitude": "43.8601",
        "longitude": "51.091999",
        "altitude": "73",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Oral"
    },
    "UCT": {
        "name": "Ukhta Airport",
        "city": "Ukhta",
        "country": "Russia",
        "iata": "UCT",
        "icao": "UUYH",
        "latitude": "63.566898345947266",
        "longitude": "53.8046989440918",
        "altitude": "482",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "USK": {
        "name": "Usinsk Airport",
        "city": "Usinsk",
        "country": "Russia",
        "iata": "USK",
        "icao": "UUYS",
        "latitude": "66.00469970703125",
        "longitude": "57.3671989440918",
        "altitude": "262",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "PEX": {
        "name": "Pechora Airport",
        "city": "Pechora",
        "country": "Russia",
        "iata": "PEX",
        "icao": "UUYP",
        "latitude": "65.12110137939453",
        "longitude": "57.13079833984375",
        "altitude": "98",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "NNM": {
        "name": "Naryan Mar Airport",
        "city": "Naryan-Mar",
        "country": "Russia",
        "iata": "NNM",
        "icao": "ULAM",
        "latitude": "67.63999938964844",
        "longitude": "53.12189865112305",
        "altitude": "36",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "PKV": {
        "name": "Pskov Airport",
        "city": "Pskov",
        "country": "Russia",
        "iata": "PKV",
        "icao": "ULOO",
        "latitude": "57.78390121459961",
        "longitude": "28.395599365234375",
        "altitude": "154",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "KGP": {
        "name": "Kogalym International Airport",
        "city": "Kogalym",
        "country": "Russia",
        "iata": "KGP",
        "icao": "USRK",
        "latitude": "62.190399169921875",
        "longitude": "74.53379821777344",
        "altitude": "220",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "KJA": {
        "name": "Yemelyanovo Airport",
        "city": "Krasnoyarsk",
        "country": "Russia",
        "iata": "KJA",
        "icao": "UNKL",
        "latitude": "56.172901",
        "longitude": "92.493301",
        "altitude": "942",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "KGF": {
        "name": "Sary-Arka Airport",
        "city": "Karaganda",
        "country": "Kazakhstan",
        "iata": "KGF",
        "icao": "UAKK",
        "latitude": "49.670799255371094",
        "longitude": "73.33439636230469",
        "altitude": "1765",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "URJ": {
        "name": "Uray Airport",
        "city": "Uraj",
        "country": "Russia",
        "iata": "URJ",
        "icao": "USHU",
        "latitude": "60.10329818725586",
        "longitude": "64.82669830322266",
        "altitude": "190",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "IWA": {
        "name": "Ivanovo South Airport",
        "city": "Ivanovo",
        "country": "Russia",
        "iata": "IWA",
        "icao": "UUBI",
        "latitude": "56.93939971923828",
        "longitude": "40.940799713134766",
        "altitude": "410",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "CGQ": {
        "name": "Longjia Airport",
        "city": "Changchun",
        "country": "China",
        "iata": "CGQ",
        "icao": "ZYCC",
        "latitude": "43.9962005615",
        "longitude": "125.684997559",
        "altitude": "706",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KIJ": {
        "name": "Niigata Airport",
        "city": "Niigata",
        "country": "Japan",
        "iata": "KIJ",
        "icao": "RJSN",
        "latitude": "37.9558982849",
        "longitude": "139.121002197",
        "altitude": "29",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "JON": {
        "name": "Johnston Atoll Airport",
        "city": "Johnston Island",
        "country": "Johnston Atoll",
        "iata": "JON",
        "icao": "PJON",
        "latitude": "16.7285995483",
        "longitude": "-169.533996582",
        "altitude": "7",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Johnston"
    },
    "SMD": {
        "name": "Smith Field",
        "city": "Fort Wayne IN",
        "country": "United States",
        "iata": "SMD",
        "icao": "KSMD",
        "latitude": "41.14339828",
        "longitude": "-85.15280151",
        "altitude": "835",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ACV": {
        "name": "California Redwood Coast-Humboldt County Airport",
        "city": "Arcata CA",
        "country": "United States",
        "iata": "ACV",
        "icao": "KACV",
        "latitude": "40.978101",
        "longitude": "-124.109",
        "altitude": "221",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "OAJ": {
        "name": "Albert J Ellis Airport",
        "city": "Jacksonville NC",
        "country": "United States",
        "iata": "OAJ",
        "icao": "KOAJ",
        "latitude": "34.8292007446",
        "longitude": "-77.61209869380001",
        "altitude": "94",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TCL": {
        "name": "Tuscaloosa Regional Airport",
        "city": "Tuscaloosa AL",
        "country": "United States",
        "iata": "TCL",
        "icao": "KTCL",
        "latitude": "33.220600128174",
        "longitude": "-87.611396789551",
        "altitude": "170",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DBQ": {
        "name": "Dubuque Regional Airport",
        "city": "Dubuque IA",
        "country": "United States",
        "iata": "DBQ",
        "icao": "KDBQ",
        "latitude": "42.40200043",
        "longitude": "-90.70950317",
        "altitude": "1077",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HHP": {
        "name": "Shun Tak Heliport",
        "city": "Hong Kong",
        "country": "Hong Kong",
        "iata": "HHP",
        "icao": "VHST",
        "latitude": "22.2893714905",
        "longitude": "114.152153015",
        "altitude": "107",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Hong_Kong"
    },
    "ATD": {
        "name": "Uru Harbour Airport",
        "city": "Atoifi",
        "country": "Solomon Islands",
        "iata": "ATD",
        "icao": "AGAT",
        "latitude": "-8.87333",
        "longitude": "161.011002",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "AKS": {
        "name": "Gwaunaru'u Airport",
        "city": "Auki",
        "country": "Solomon Islands",
        "iata": "AKS",
        "icao": "AGGA",
        "latitude": "-8.70257",
        "longitude": "160.682007",
        "altitude": "5",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "BAS": {
        "name": "Ballalae Airport",
        "city": "Ballalae",
        "country": "Solomon Islands",
        "iata": "BAS",
        "icao": "AGGE",
        "latitude": "-6.990745",
        "longitude": "155.886656",
        "altitude": "5",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "FRE": {
        "name": "Fera/Maringe Airport",
        "city": "Fera Island",
        "country": "Solomon Islands",
        "iata": "FRE",
        "icao": "AGGF",
        "latitude": "-8.1075",
        "longitude": "159.576996",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "MBU": {
        "name": "Babanakira Airport",
        "city": "Mbambanakira",
        "country": "Solomon Islands",
        "iata": "MBU",
        "icao": "AGGI",
        "latitude": "-9.7475004196167",
        "longitude": "159.83900451660156",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "IRA": {
        "name": "Ngorangora Airport",
        "city": "Kirakira",
        "country": "Solomon Islands",
        "iata": "IRA",
        "icao": "AGGK",
        "latitude": "-10.449700355500001",
        "longitude": "161.897994995",
        "altitude": "54",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "SCZ": {
        "name": "Santa Cruz/Graciosa Bay/Luova Airport",
        "city": "Santa Cruz/Graciosa Bay/Luova",
        "country": "Solomon Islands",
        "iata": "SCZ",
        "icao": "AGGL",
        "latitude": "-10.72029972076416",
        "longitude": "165.7949981689453",
        "altitude": "18",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "MUA": {
        "name": "Munda Airport",
        "city": "Munda",
        "country": "Solomon Islands",
        "iata": "MUA",
        "icao": "AGGM",
        "latitude": "-8.327969551086426",
        "longitude": "157.26300048828125",
        "altitude": "10",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "GZO": {
        "name": "Nusatupe Airport",
        "city": "Gizo",
        "country": "Solomon Islands",
        "iata": "GZO",
        "icao": "AGGN",
        "latitude": "-8.09778022766",
        "longitude": "156.863998413",
        "altitude": "13",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "MNY": {
        "name": "Mono Airport",
        "city": "Stirling Island",
        "country": "Solomon Islands",
        "iata": "MNY",
        "icao": "AGGO",
        "latitude": "-7.416940212249756",
        "longitude": "155.56500244140625",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "RNL": {
        "name": "Rennell/Tingoa Airport",
        "city": "Rennell Island",
        "country": "Solomon Islands",
        "iata": "RNL",
        "icao": "AGGR",
        "latitude": "-11.533900260925293",
        "longitude": "160.06300354003906",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "RUS": {
        "name": "Marau Airport",
        "city": "Marau",
        "country": "Solomon Islands",
        "iata": "RUS",
        "icao": "AGGU",
        "latitude": "-9.861669540409999",
        "longitude": "160.824996948",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "VAO": {
        "name": "Suavanao Airport",
        "city": "Suavanao",
        "country": "Solomon Islands",
        "iata": "VAO",
        "icao": "AGGV",
        "latitude": "-7.585559844970703",
        "longitude": "158.7310028076172",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "KGE": {
        "name": "Kaghau Airport",
        "city": "Kagau Island",
        "country": "Solomon Islands",
        "iata": "KGE",
        "icao": "AGKG",
        "latitude": "-7.3305",
        "longitude": "157.585",
        "altitude": "30",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "RBV": {
        "name": "Ramata Airport",
        "city": "Ramata",
        "country": "Solomon Islands",
        "iata": "RBV",
        "icao": "AGRM",
        "latitude": "-8.168060302734375",
        "longitude": "157.64300537109375",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "BUA": {
        "name": "Buka Airport",
        "city": "Buka Island",
        "country": "Papua New Guinea",
        "iata": "BUA",
        "icao": "AYBK",
        "latitude": "-5.4223198890686035",
        "longitude": "154.67300415039062",
        "altitude": "11",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "CMU": {
        "name": "Chimbu Airport",
        "city": "Kundiawa",
        "country": "Papua New Guinea",
        "iata": "CMU",
        "icao": "AYCH",
        "latitude": "-6.024290084838867",
        "longitude": "144.9709930419922",
        "altitude": "4974",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "DAU": {
        "name": "Daru Airport",
        "city": "Daru",
        "country": "Papua New Guinea",
        "iata": "DAU",
        "icao": "AYDU",
        "latitude": "-9.08675956726",
        "longitude": "143.207992554",
        "altitude": "20",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "GUR": {
        "name": "Gurney Airport",
        "city": "Gurney",
        "country": "Papua New Guinea",
        "iata": "GUR",
        "icao": "AYGN",
        "latitude": "-10.3114995956",
        "longitude": "150.333999634",
        "altitude": "88",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "PNP": {
        "name": "Girua Airport",
        "city": "Girua",
        "country": "Papua New Guinea",
        "iata": "PNP",
        "icao": "AYGR",
        "latitude": "-8.80453968048",
        "longitude": "148.309005737",
        "altitude": "311",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "HKN": {
        "name": "Kimbe Airport",
        "city": "Hoskins",
        "country": "Papua New Guinea",
        "iata": "HKN",
        "icao": "AYHK",
        "latitude": "-5.462170124053955",
        "longitude": "150.40499877929688",
        "altitude": "66",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "UNG": {
        "name": "Kiunga Airport",
        "city": "Kiunga",
        "country": "Papua New Guinea",
        "iata": "UNG",
        "icao": "AYKI",
        "latitude": "-6.1257100105285645",
        "longitude": "141.28199768066406",
        "altitude": "88",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "KRI": {
        "name": "Kikori Airport",
        "city": "Kikori",
        "country": "Papua New Guinea",
        "iata": "KRI",
        "icao": "AYKK",
        "latitude": "-7.424379825592041",
        "longitude": "144.2500762939453",
        "altitude": "50",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "KMA": {
        "name": "Kerema Airport",
        "city": "Kerema",
        "country": "Papua New Guinea",
        "iata": "KMA",
        "icao": "AYKM",
        "latitude": "-7.96361017227",
        "longitude": "145.770996094",
        "altitude": "10",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "KVG": {
        "name": "Kavieng Airport",
        "city": "Kavieng",
        "country": "Papua New Guinea",
        "iata": "KVG",
        "icao": "AYKV",
        "latitude": "-2.57940006256",
        "longitude": "150.807998657",
        "altitude": "7",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "MDU": {
        "name": "Mendi Airport",
        "city": "Mendi",
        "country": "Papua New Guinea",
        "iata": "MDU",
        "icao": "AYMN",
        "latitude": "-6.14774",
        "longitude": "143.656998",
        "altitude": "5680",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "MAS": {
        "name": "Momote Airport",
        "city": "Momote",
        "country": "Papua New Guinea",
        "iata": "MAS",
        "icao": "AYMO",
        "latitude": "-2.06189",
        "longitude": "147.423996",
        "altitude": "12",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "MXH": {
        "name": "Moro Airport",
        "city": "Moro",
        "country": "Papua New Guinea",
        "iata": "MXH",
        "icao": "AYMR",
        "latitude": "-6.36332988739",
        "longitude": "143.238006592",
        "altitude": "2740",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "MIS": {
        "name": "Misima Island Airport",
        "city": "Misima Island",
        "country": "Papua New Guinea",
        "iata": "MIS",
        "icao": "AYMS",
        "latitude": "-10.689200401299999",
        "longitude": "152.837997437",
        "altitude": "26",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "TIZ": {
        "name": "Tari Airport",
        "city": "Tari",
        "country": "Papua New Guinea",
        "iata": "TIZ",
        "icao": "AYTA",
        "latitude": "-5.84499979019",
        "longitude": "142.947998047",
        "altitude": "5500",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "TBG": {
        "name": "Tabubil Airport",
        "city": "Tabubil",
        "country": "Papua New Guinea",
        "iata": "TBG",
        "icao": "AYTB",
        "latitude": "-5.27861",
        "longitude": "141.225998",
        "altitude": "1570",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "RAB": {
        "name": "Tokua Airport",
        "city": "Tokua",
        "country": "Papua New Guinea",
        "iata": "RAB",
        "icao": "AYTK",
        "latitude": "-4.34045982361",
        "longitude": "152.380004883",
        "altitude": "32",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "VAI": {
        "name": "Vanimo Airport",
        "city": "Vanimo",
        "country": "Papua New Guinea",
        "iata": "VAI",
        "icao": "AYVN",
        "latitude": "-2.6926",
        "longitude": "141.3028",
        "altitude": "10",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "WBM": {
        "name": "Wapenamanda Airport",
        "city": "Wapenamanda",
        "country": "Papua New Guinea",
        "iata": "WBM",
        "icao": "AYWD",
        "latitude": "-5.6433000564575195",
        "longitude": "143.89500427246094",
        "altitude": "5889",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "LLU": {
        "name": "Alluitsup Paa Heliport",
        "city": "Alluitsup Paa",
        "country": "Greenland",
        "iata": "LLU",
        "icao": "BGAP",
        "latitude": "60.46445",
        "longitude": "-45.56917",
        "altitude": "54",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "CNP": {
        "name": "Neerlerit Inaat Airport",
        "city": "Neerlerit Inaat",
        "country": "Greenland",
        "iata": "CNP",
        "icao": "BGCO",
        "latitude": "70.7431030273",
        "longitude": "-22.6504993439",
        "altitude": "45",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "America/Scoresbysund"
    },
    "JFR": {
        "name": "Paamiut Heliport",
        "city": "Paamiut",
        "country": "Greenland",
        "iata": "JFR",
        "icao": "BGFH",
        "latitude": "61.9921989441",
        "longitude": "-49.6624984741",
        "altitude": "63",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JGO": {
        "name": "Qeqertarsuaq Heliport",
        "city": "Qeqertarsuaq Airport",
        "country": "Greenland",
        "iata": "JGO",
        "icao": "BGGN",
        "latitude": "69.251181993",
        "longitude": "-53.5148763657",
        "altitude": "9",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JJU": {
        "name": "Qaqortoq Heliport",
        "city": "Qaqortoq",
        "country": "Greenland",
        "iata": "JJU",
        "icao": "BGJH",
        "latitude": "60.715684155299996",
        "longitude": "-46.0299186409",
        "altitude": "53",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JSU": {
        "name": "Maniitsoq Airport",
        "city": "Maniitsoq",
        "country": "Greenland",
        "iata": "JSU",
        "icao": "BGMQ",
        "latitude": "65.4124984741",
        "longitude": "-52.9393997192",
        "altitude": "91",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JNN": {
        "name": "Nanortalik Heliport",
        "city": "Nanortalik",
        "country": "Greenland",
        "iata": "JNN",
        "icao": "BGNN",
        "latitude": "60.141883975899994",
        "longitude": "-45.232976675",
        "altitude": "17",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JNS": {
        "name": "Narsaq Heliport",
        "city": "Narsaq",
        "country": "Greenland",
        "iata": "JNS",
        "icao": "BGNS",
        "latitude": "60.9172827256",
        "longitude": "-46.059923172",
        "altitude": "83",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "NAQ": {
        "name": "Qaanaaq Airport",
        "city": "Qaanaaq",
        "country": "Greenland",
        "iata": "NAQ",
        "icao": "BGQQ",
        "latitude": "77.4886016846",
        "longitude": "-69.3887023926",
        "altitude": "51",
        "timezone": "-4",
        "dst": "E",
        "tz_name": "America/Thule"
    },
    "JHS": {
        "name": "Sisimiut Airport",
        "city": "Sisimiut",
        "country": "Greenland",
        "iata": "JHS",
        "icao": "BGSS",
        "latitude": "66.9513015747",
        "longitude": "-53.7293014526",
        "altitude": "33",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JUV": {
        "name": "Upernavik Airport",
        "city": "Upernavik",
        "country": "Greenland",
        "iata": "JUV",
        "icao": "BGUK",
        "latitude": "72.7901992798",
        "longitude": "-56.1305999756",
        "altitude": "414",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "JQA": {
        "name": "Qaarsut Airport",
        "city": "Uummannaq",
        "country": "Greenland",
        "iata": "JQA",
        "icao": "BGUQ",
        "latitude": "70.7341995239",
        "longitude": "-52.6962013245",
        "altitude": "289",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "GRY": {
        "name": "Grímsey Airport",
        "city": "Grímsey",
        "country": "Iceland",
        "iata": "GRY",
        "icao": "BIGR",
        "latitude": "66.5458",
        "longitude": "-18.0173",
        "altitude": "66",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "THO": {
        "name": "Thorshofn Airport",
        "city": "Thorshofn",
        "country": "Iceland",
        "iata": "THO",
        "icao": "BITN",
        "latitude": "66.21849822998047",
        "longitude": "-15.335599899291992",
        "altitude": "65",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "VPN": {
        "name": "Vopnafjörður Airport",
        "city": "Vopnafjörður",
        "country": "Iceland",
        "iata": "VPN",
        "icao": "BIVO",
        "latitude": "65.72059631347656",
        "longitude": "-14.850600242614746",
        "altitude": "16",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "YWS": {
        "name": "Whistler/Green Lake Water Aerodrome",
        "city": "Whistler",
        "country": "Canada",
        "iata": "YWS",
        "icao": "CAE5",
        "latitude": "50.1436004639",
        "longitude": "-122.948997498",
        "altitude": "2100",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YAA": {
        "name": "Anahim Lake Airport",
        "city": "Anahim Lake",
        "country": "Canada",
        "iata": "YAA",
        "icao": "CAJ4",
        "latitude": "52.45249938964844",
        "longitude": "-125.3030014038086",
        "altitude": "3635",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YWM": {
        "name": "Williams Harbour Airport",
        "city": "Williams Harbour",
        "country": "Canada",
        "iata": "YWM",
        "icao": "CCA6",
        "latitude": "52.566898345947266",
        "longitude": "-55.784698486328125",
        "altitude": "70",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YFX": {
        "name": "St. Lewis (Fox Harbour) Airport",
        "city": "St. Lewis",
        "country": "Canada",
        "iata": "YFX",
        "icao": "CCK4",
        "latitude": "52.372798919677734",
        "longitude": "-55.67390060424805",
        "altitude": "74",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YHA": {
        "name": "Port Hope Simpson Airport",
        "city": "Port Hope Simpson",
        "country": "Canada",
        "iata": "YHA",
        "icao": "CCP4",
        "latitude": "52.528099060058594",
        "longitude": "-56.28609848022461",
        "altitude": "347",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YRG": {
        "name": "Rigolet Airport",
        "city": "Rigolet",
        "country": "Canada",
        "iata": "YRG",
        "icao": "CCZ2",
        "latitude": "54.1796989440918",
        "longitude": "-58.45750045776367",
        "altitude": "180",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YCK": {
        "name": "Colville Lake Airport",
        "city": "Colville Lake",
        "country": "Canada",
        "iata": "YCK",
        "icao": "CEB3",
        "latitude": "67.0392",
        "longitude": "-126.08",
        "altitude": "850",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YLE": {
        "name": "Whatì Airport",
        "city": "Whatì",
        "country": "Canada",
        "iata": "YLE",
        "icao": "CEM3",
        "latitude": "63.13169860839844",
        "longitude": "-117.24600219726562",
        "altitude": "882",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "SUR": {
        "name": "Summer Beaver Airport",
        "city": "Summer Beaver",
        "country": "Canada",
        "iata": "SUR",
        "icao": "CJV7",
        "latitude": "52.70859909057617",
        "longitude": "-88.54190063476562",
        "altitude": "832",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YAX": {
        "name": "Wapekeka Airport",
        "city": "Angling Lake",
        "country": "Canada",
        "iata": "YAX",
        "icao": "CKB6",
        "latitude": "53.84920120239258",
        "longitude": "-89.57939910888672",
        "altitude": "712",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "WNN": {
        "name": "Wunnumin Lake Airport",
        "city": "Wunnumin Lake",
        "country": "Canada",
        "iata": "WNN",
        "icao": "CKL3",
        "latitude": "52.89390182495117",
        "longitude": "-89.28919982910156",
        "altitude": "819",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YNO": {
        "name": "North Spirit Lake Airport",
        "city": "North Spirit Lake",
        "country": "Canada",
        "iata": "YNO",
        "icao": "CKQ3",
        "latitude": "52.4900016784668",
        "longitude": "-92.97109985351562",
        "altitude": "1082",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "XBE": {
        "name": "Bearskin Lake Airport",
        "city": "Bearskin Lake",
        "country": "Canada",
        "iata": "XBE",
        "icao": "CNE3",
        "latitude": "53.965599060058594",
        "longitude": "-91.0271987915039",
        "altitude": "800",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "KIF": {
        "name": "Kingfisher Lake Airport",
        "city": "Kingfisher Lake",
        "country": "Canada",
        "iata": "KIF",
        "icao": "CNM5",
        "latitude": "53.01250076293945",
        "longitude": "-89.85530090332031",
        "altitude": "866",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YOG": {
        "name": "Ogoki Post Airport",
        "city": "Ogoki Post",
        "country": "Canada",
        "iata": "YOG",
        "icao": "CNT3",
        "latitude": "51.6585998535",
        "longitude": "-85.9017028809",
        "altitude": "594",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHP": {
        "name": "Poplar Hill Airport",
        "city": "Poplar Hill",
        "country": "Canada",
        "iata": "YHP",
        "icao": "CPV7",
        "latitude": "52.1133",
        "longitude": "-94.2556",
        "altitude": "1095",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YKU": {
        "name": "Chisasibi Airport",
        "city": "Chisasibi",
        "country": "Canada",
        "iata": "YKU",
        "icao": "CSU2",
        "latitude": "53.805599212646484",
        "longitude": "-78.91690063476562",
        "altitude": "43",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "ZTB": {
        "name": "Tête-à-la-Baleine Airport",
        "city": "Tête-à-la-Baleine",
        "country": "Canada",
        "iata": "ZTB",
        "icao": "CTB6",
        "latitude": "50.674400329589844",
        "longitude": "-59.38359832763672",
        "altitude": "107",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "ZLT": {
        "name": "La Tabatière Airport",
        "city": "La Tabatière",
        "country": "Canada",
        "iata": "ZLT",
        "icao": "CTU5",
        "latitude": "50.8307991027832",
        "longitude": "-58.97560119628906",
        "altitude": "102",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "YAC": {
        "name": "Cat Lake Airport",
        "city": "Cat Lake",
        "country": "Canada",
        "iata": "YAC",
        "icao": "CYAC",
        "latitude": "51.72719955444336",
        "longitude": "-91.82440185546875",
        "altitude": "1344",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YAG": {
        "name": "Fort Frances Municipal Airport",
        "city": "Fort Frances",
        "country": "Canada",
        "iata": "YAG",
        "icao": "CYAG",
        "latitude": "48.65420150756836",
        "longitude": "-93.439697265625",
        "altitude": "1125",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "XKS": {
        "name": "Kasabonika Airport",
        "city": "Kasabonika",
        "country": "Canada",
        "iata": "XKS",
        "icao": "CYAQ",
        "latitude": "53.52470016479492",
        "longitude": "-88.6427993774414",
        "altitude": "672",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YKG": {
        "name": "Kangirsuk Airport",
        "city": "Kangirsuk",
        "country": "Canada",
        "iata": "YKG",
        "icao": "CYAS",
        "latitude": "60.027198791503906",
        "longitude": "-69.99919891357422",
        "altitude": "403",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YAT": {
        "name": "Attawapiskat Airport",
        "city": "Attawapiskat",
        "country": "Canada",
        "iata": "YAT",
        "icao": "CYAT",
        "latitude": "52.9275016784668",
        "longitude": "-82.43190002441406",
        "altitude": "31",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YBE": {
        "name": "Uranium City Airport",
        "city": "Uranium City",
        "country": "Canada",
        "iata": "YBE",
        "icao": "CYBE",
        "latitude": "59.5614013671875",
        "longitude": "-108.48100280761719",
        "altitude": "1044",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YBX": {
        "name": "Lourdes de Blanc Sablon Airport",
        "city": "Lourdes-De-Blanc-Sablon",
        "country": "Canada",
        "iata": "YBX",
        "icao": "CYBX",
        "latitude": "51.443599700899995",
        "longitude": "-57.185298919699996",
        "altitude": "121",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "YRF": {
        "name": "Cartwright Airport",
        "city": "Cartwright",
        "country": "Canada",
        "iata": "YRF",
        "icao": "CYCA",
        "latitude": "53.68280029296875",
        "longitude": "-57.041900634765625",
        "altitude": "40",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YCS": {
        "name": "Chesterfield Inlet Airport",
        "city": "Chesterfield Inlet",
        "country": "Canada",
        "iata": "YCS",
        "icao": "CYCS",
        "latitude": "63.346900939899996",
        "longitude": "-90.73110198970001",
        "altitude": "32",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YDP": {
        "name": "Nain Airport",
        "city": "Nain",
        "country": "Canada",
        "iata": "YDP",
        "icao": "CYDP",
        "latitude": "56.549198150634766",
        "longitude": "-61.680301666259766",
        "altitude": "22",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YER": {
        "name": "Fort Severn Airport",
        "city": "Fort Severn",
        "country": "Canada",
        "iata": "YER",
        "icao": "CYER",
        "latitude": "56.01890182495117",
        "longitude": "-87.67610168457031",
        "altitude": "48",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YFA": {
        "name": "Fort Albany Airport",
        "city": "Fort Albany",
        "country": "Canada",
        "iata": "YFA",
        "icao": "CYFA",
        "latitude": "52.20140075683594",
        "longitude": "-81.6968994140625",
        "altitude": "48",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YFH": {
        "name": "Fort Hope Airport",
        "city": "Fort Hope",
        "country": "Canada",
        "iata": "YFH",
        "icao": "CYFH",
        "latitude": "51.5619010925293",
        "longitude": "-87.90779876708984",
        "altitude": "899",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YMN": {
        "name": "Makkovik Airport",
        "city": "Makkovik",
        "country": "Canada",
        "iata": "YMN",
        "icao": "CYFT",
        "latitude": "55.076900482177734",
        "longitude": "-59.1864013671875",
        "altitude": "234",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YGB": {
        "name": "Texada Gillies Bay Airport",
        "city": "Texada",
        "country": "Canada",
        "iata": "YGB",
        "icao": "CYGB",
        "latitude": "49.69419860839844",
        "longitude": "-124.51799774169922",
        "altitude": "326",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YGO": {
        "name": "Gods Lake Narrows Airport",
        "city": "Gods Lake Narrows",
        "country": "Canada",
        "iata": "YGO",
        "icao": "CYGO",
        "latitude": "54.55889892578125",
        "longitude": "-94.49140167236328",
        "altitude": "617",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YGT": {
        "name": "Igloolik Airport",
        "city": "Igloolik",
        "country": "Canada",
        "iata": "YGT",
        "icao": "CYGT",
        "latitude": "69.3647003174",
        "longitude": "-81.8161010742",
        "altitude": "174",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGW": {
        "name": "Kuujjuarapik Airport",
        "city": "Kuujjuarapik",
        "country": "Canada",
        "iata": "YGW",
        "icao": "CYGW",
        "latitude": "55.281898498535156",
        "longitude": "-77.76529693603516",
        "altitude": "34",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGX": {
        "name": "Gillam Airport",
        "city": "Gillam",
        "country": "Canada",
        "iata": "YGX",
        "icao": "CYGX",
        "latitude": "56.35749816894531",
        "longitude": "-94.71060180664062",
        "altitude": "476",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YGZ": {
        "name": "Grise Fiord Airport",
        "city": "Grise Fiord",
        "country": "Canada",
        "iata": "YGZ",
        "icao": "CYGZ",
        "latitude": "76.4261016846",
        "longitude": "-82.90920257570001",
        "altitude": "146",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YQC": {
        "name": "Quaqtaq Airport",
        "city": "Quaqtaq",
        "country": "Canada",
        "iata": "YQC",
        "icao": "CYHA",
        "latitude": "61.0463981628418",
        "longitude": "-69.6177978515625",
        "altitude": "103",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "CXH": {
        "name": "Vancouver Harbour Water Aerodrome",
        "city": "Vancouver",
        "country": "Canada",
        "iata": "CXH",
        "icao": "CYHC",
        "latitude": "49.2943992615",
        "longitude": "-123.111000061",
        "altitude": "0",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YNS": {
        "name": "Nemiscau Airport",
        "city": "Nemiscau",
        "country": "Canada",
        "iata": "YNS",
        "icao": "CYHH",
        "latitude": "51.69110107421875",
        "longitude": "-76.1355972290039",
        "altitude": "802",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHO": {
        "name": "Hopedale Airport",
        "city": "Hopedale",
        "country": "Canada",
        "iata": "YHO",
        "icao": "CYHO",
        "latitude": "55.448299407958984",
        "longitude": "-60.228599548339844",
        "altitude": "39",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YHR": {
        "name": "Chevery Airport",
        "city": "Chevery",
        "country": "Canada",
        "iata": "YHR",
        "icao": "CYHR",
        "latitude": "50.46889877319336",
        "longitude": "-59.63669967651367",
        "altitude": "39",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "YIK": {
        "name": "Ivujivik Airport",
        "city": "Ivujivik",
        "country": "Canada",
        "iata": "YIK",
        "icao": "CYIK",
        "latitude": "62.417301177978516",
        "longitude": "-77.92530059814453",
        "altitude": "126",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YIV": {
        "name": "Island Lake Airport",
        "city": "Island Lake",
        "country": "Canada",
        "iata": "YIV",
        "icao": "CYIV",
        "latitude": "53.857200622558594",
        "longitude": "-94.65360260009766",
        "altitude": "770",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "AKV": {
        "name": "Akulivik Airport",
        "city": "Akulivik",
        "country": "Canada",
        "iata": "AKV",
        "icao": "CYKO",
        "latitude": "60.818599700927734",
        "longitude": "-78.14859771728516",
        "altitude": "75",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YKQ": {
        "name": "Waskaganish Airport",
        "city": "Waskaganish",
        "country": "Canada",
        "iata": "YKQ",
        "icao": "CYKQ",
        "latitude": "51.47330093383789",
        "longitude": "-78.75830078125",
        "altitude": "80",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPJ": {
        "name": "Aupaluk Airport",
        "city": "Aupaluk",
        "country": "Canada",
        "iata": "YPJ",
        "icao": "CYLA",
        "latitude": "59.29669952392578",
        "longitude": "-69.59970092773438",
        "altitude": "119",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YLC": {
        "name": "Kimmirut Airport",
        "city": "Kimmirut",
        "country": "Canada",
        "iata": "YLC",
        "icao": "CYLC",
        "latitude": "62.8499984741",
        "longitude": "-69.88330078119999",
        "altitude": "175",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YLH": {
        "name": "Lansdowne House Airport",
        "city": "Lansdowne House",
        "country": "Canada",
        "iata": "YLH",
        "icao": "CYLH",
        "latitude": "52.19559860229492",
        "longitude": "-87.93419647216797",
        "altitude": "834",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "XGR": {
        "name": "Kangiqsualujjuaq (Georges River) Airport",
        "city": "Kangiqsualujjuaq",
        "country": "Canada",
        "iata": "XGR",
        "icao": "CYLU",
        "latitude": "58.71139907836914",
        "longitude": "-65.9927978515625",
        "altitude": "215",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YMH": {
        "name": "Mary's Harbour Airport",
        "city": "Mary's Harbour",
        "country": "Canada",
        "iata": "YMH",
        "icao": "CYMH",
        "latitude": "52.302799224853516",
        "longitude": "-55.847198486328125",
        "altitude": "38",
        "timezone": "-3.5",
        "dst": "A",
        "tz_name": "America/St_Johns"
    },
    "YMT": {
        "name": "Chapais Airport",
        "city": "Chibougamau",
        "country": "Canada",
        "iata": "YMT",
        "icao": "CYMT",
        "latitude": "49.77190017700195",
        "longitude": "-74.5280990600586",
        "altitude": "1270",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YUD": {
        "name": "Umiujaq Airport",
        "city": "Umiujaq",
        "country": "Canada",
        "iata": "YUD",
        "icao": "CYMU",
        "latitude": "56.53609848022461",
        "longitude": "-76.51830291748047",
        "altitude": "250",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YNC": {
        "name": "Wemindji Airport",
        "city": "Wemindji",
        "country": "Canada",
        "iata": "YNC",
        "icao": "CYNC",
        "latitude": "53.01060104370117",
        "longitude": "-78.83110046386719",
        "altitude": "66",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YNE": {
        "name": "Norway House Airport",
        "city": "Norway House",
        "country": "Canada",
        "iata": "YNE",
        "icao": "CYNE",
        "latitude": "53.95830154418945",
        "longitude": "-97.84420013427734",
        "altitude": "734",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YNL": {
        "name": "Points North Landing Airport",
        "city": "Points North Landing",
        "country": "Canada",
        "iata": "YNL",
        "icao": "CYNL",
        "latitude": "58.27669906616211",
        "longitude": "-104.08200073242188",
        "altitude": "1605",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YOH": {
        "name": "Oxford House Airport",
        "city": "Oxford House",
        "country": "Canada",
        "iata": "YOH",
        "icao": "CYOH",
        "latitude": "54.93330001831055",
        "longitude": "-95.27890014648438",
        "altitude": "663",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YPH": {
        "name": "Inukjuak Airport",
        "city": "Inukjuak",
        "country": "Canada",
        "iata": "YPH",
        "icao": "CYPH",
        "latitude": "58.471900939941406",
        "longitude": "-78.07689666748047",
        "altitude": "83",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPM": {
        "name": "Pikangikum Airport",
        "city": "Pikangikum",
        "country": "Canada",
        "iata": "YPM",
        "icao": "CYPM",
        "latitude": "51.819698333740234",
        "longitude": "-93.97329711914062",
        "altitude": "1114",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YPO": {
        "name": "Peawanuck Airport",
        "city": "Peawanuck",
        "country": "Canada",
        "iata": "YPO",
        "icao": "CYPO",
        "latitude": "54.98809814453125",
        "longitude": "-85.44329833984375",
        "altitude": "173",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPW": {
        "name": "Powell River Airport",
        "city": "Powell River",
        "country": "Canada",
        "iata": "YPW",
        "icao": "CYPW",
        "latitude": "49.83420181274414",
        "longitude": "-124.5",
        "altitude": "425",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YQD": {
        "name": "The Pas Airport",
        "city": "The Pas",
        "country": "Canada",
        "iata": "YQD",
        "icao": "CYQD",
        "latitude": "53.97140121459961",
        "longitude": "-101.09100341796875",
        "altitude": "887",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YQN": {
        "name": "Nakina Airport",
        "city": "Nakina",
        "country": "Canada",
        "iata": "YQN",
        "icao": "CYQN",
        "latitude": "50.18280029296875",
        "longitude": "-86.69640350341797",
        "altitude": "1057",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YRA": {
        "name": "Rae Lakes Airport",
        "city": "Gamètì",
        "country": "Canada",
        "iata": "YRA",
        "icao": "CYRA",
        "latitude": "64.11609649658203",
        "longitude": "-117.30999755859375",
        "altitude": "723",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YRL": {
        "name": "Red Lake Airport",
        "city": "Red Lake",
        "country": "Canada",
        "iata": "YRL",
        "icao": "CYRL",
        "latitude": "51.066898345947266",
        "longitude": "-93.79309844970703",
        "altitude": "1265",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YSF": {
        "name": "Stony Rapids Airport",
        "city": "Stony Rapids",
        "country": "Canada",
        "iata": "YSF",
        "icao": "CYSF",
        "latitude": "59.250301361083984",
        "longitude": "-105.84100341796875",
        "altitude": "805",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "YSK": {
        "name": "Sanikiluaq Airport",
        "city": "Sanikiluaq",
        "country": "Canada",
        "iata": "YSK",
        "icao": "CYSK",
        "latitude": "56.5377998352",
        "longitude": "-79.2466964722",
        "altitude": "104",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "YST": {
        "name": "St. Theresa Point Airport",
        "city": "St. Theresa Point",
        "country": "Canada",
        "iata": "YST",
        "icao": "CYST",
        "latitude": "53.84560012817383",
        "longitude": "-94.85189819335938",
        "altitude": "773",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YTL": {
        "name": "Big Trout Lake Airport",
        "city": "Big Trout Lake",
        "country": "Canada",
        "iata": "YTL",
        "icao": "CYTL",
        "latitude": "53.81779861450195",
        "longitude": "-89.89689636230469",
        "altitude": "729",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YVZ": {
        "name": "Deer Lake Airport",
        "city": "Deer Lake",
        "country": "Canada",
        "iata": "YVZ",
        "icao": "CYVZ",
        "latitude": "52.655799865722656",
        "longitude": "-94.0614013671875",
        "altitude": "1092",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YWP": {
        "name": "Webequie Airport",
        "city": "Webequie",
        "country": "Canada",
        "iata": "YWP",
        "icao": "CYWP",
        "latitude": "52.9593933975",
        "longitude": "-87.3748683929",
        "altitude": "685",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YXN": {
        "name": "Whale Cove Airport",
        "city": "Whale Cove",
        "country": "Canada",
        "iata": "YXN",
        "icao": "CYXN",
        "latitude": "62.24000167849999",
        "longitude": "-92.59809875490001",
        "altitude": "40",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YZG": {
        "name": "Salluit Airport",
        "city": "Salluit",
        "country": "Canada",
        "iata": "YZG",
        "icao": "CYZG",
        "latitude": "62.17940139770508",
        "longitude": "-75.66719818115234",
        "altitude": "743",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "ZAC": {
        "name": "York Landing Airport",
        "city": "York Landing",
        "country": "Canada",
        "iata": "ZAC",
        "icao": "CZAC",
        "latitude": "56.08940124511719",
        "longitude": "-96.08920288085938",
        "altitude": "621",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ILF": {
        "name": "Ilford Airport",
        "city": "Ilford",
        "country": "Canada",
        "iata": "ILF",
        "icao": "CZBD",
        "latitude": "56.0614013672",
        "longitude": "-95.613899231",
        "altitude": "642",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZBF": {
        "name": "Bathurst Airport",
        "city": "Bathurst",
        "country": "Canada",
        "iata": "ZBF",
        "icao": "CZBF",
        "latitude": "47.629699707",
        "longitude": "-65.738899231",
        "altitude": "193",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "ZEM": {
        "name": "Eastmain River Airport",
        "city": "Eastmain River",
        "country": "Canada",
        "iata": "ZEM",
        "icao": "CZEM",
        "latitude": "52.22639846801758",
        "longitude": "-78.52249908447266",
        "altitude": "24",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "ZFD": {
        "name": "Fond-Du-Lac Airport",
        "city": "Fond-Du-Lac",
        "country": "Canada",
        "iata": "ZFD",
        "icao": "CZFD",
        "latitude": "59.33440017700195",
        "longitude": "-107.18199920654297",
        "altitude": "814",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "ZGI": {
        "name": "Gods River Airport",
        "city": "Gods River",
        "country": "Canada",
        "iata": "ZGI",
        "icao": "CZGI",
        "latitude": "54.839698791503906",
        "longitude": "-94.07859802246094",
        "altitude": "627",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZJN": {
        "name": "Swan River Airport",
        "city": "Swan River",
        "country": "Canada",
        "iata": "ZJN",
        "icao": "CZJN",
        "latitude": "52.120601654052734",
        "longitude": "-101.23600006103516",
        "altitude": "1100",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZKE": {
        "name": "Kashechewan Airport",
        "city": "Kashechewan",
        "country": "Canada",
        "iata": "ZKE",
        "icao": "CZKE",
        "latitude": "52.282501220703125",
        "longitude": "-81.67780303955078",
        "altitude": "35",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "MSA": {
        "name": "Muskrat Dam Airport",
        "city": "Muskrat Dam",
        "country": "Canada",
        "iata": "MSA",
        "icao": "CZMD",
        "latitude": "53.44139862060547",
        "longitude": "-91.76280212402344",
        "altitude": "911",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZMT": {
        "name": "Masset Airport",
        "city": "Masset",
        "country": "Canada",
        "iata": "ZMT",
        "icao": "CZMT",
        "latitude": "54.02750015258789",
        "longitude": "-132.125",
        "altitude": "25",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "ZPB": {
        "name": "Sachigo Lake Airport",
        "city": "Sachigo Lake",
        "country": "Canada",
        "iata": "ZPB",
        "icao": "CZPB",
        "latitude": "53.8911018371582",
        "longitude": "-92.19640350341797",
        "altitude": "876",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZRJ": {
        "name": "Round Lake (Weagamow Lake) Airport",
        "city": "Round Lake",
        "country": "Canada",
        "iata": "ZRJ",
        "icao": "CZRJ",
        "latitude": "52.943599700927734",
        "longitude": "-91.31279754638672",
        "altitude": "974",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZSJ": {
        "name": "Sandy Lake Airport",
        "city": "Sandy Lake",
        "country": "Canada",
        "iata": "ZSJ",
        "icao": "CZSJ",
        "latitude": "53.06420135498047",
        "longitude": "-93.34439849853516",
        "altitude": "951",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZTM": {
        "name": "Shamattawa Airport",
        "city": "Shamattawa",
        "country": "Canada",
        "iata": "ZTM",
        "icao": "CZTM",
        "latitude": "55.8656005859375",
        "longitude": "-92.0813980102539",
        "altitude": "289",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZUM": {
        "name": "Churchill Falls Airport",
        "city": "Churchill Falls",
        "country": "Canada",
        "iata": "ZUM",
        "icao": "CZUM",
        "latitude": "53.5619010925293",
        "longitude": "-64.10639953613281",
        "altitude": "1442",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "ZWL": {
        "name": "Wollaston Lake Airport",
        "city": "Wollaston Lake",
        "country": "Canada",
        "iata": "ZWL",
        "icao": "CZWL",
        "latitude": "58.10689926147461",
        "longitude": "-103.1719970703125",
        "altitude": "1360",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Regina"
    },
    "BLJ": {
        "name": "Batna Airport",
        "city": "Batna",
        "country": "Algeria",
        "iata": "BLJ",
        "icao": "DABT",
        "latitude": "35.752101898199996",
        "longitude": "6.308589935300001",
        "altitude": "2697",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "CBH": {
        "name": "Béchar Boudghene Ben Ali Lotfi Airport",
        "city": "Béchar",
        "country": "Algeria",
        "iata": "CBH",
        "icao": "DAOR",
        "latitude": "31.645700454711914",
        "longitude": "-2.269860029220581",
        "altitude": "2661",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "BMW": {
        "name": "Bordj Badji Mokhtar Airport",
        "city": "Bordj Badji Mokhtar",
        "country": "Algeria",
        "iata": "BMW",
        "icao": "DATM",
        "latitude": "21.375",
        "longitude": "0.923888981342",
        "altitude": "1303",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "ELU": {
        "name": "Guemar Airport",
        "city": "Guemar",
        "country": "Algeria",
        "iata": "ELU",
        "icao": "DAUO",
        "latitude": "33.5113983154",
        "longitude": "6.77679014206",
        "altitude": "203",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Algiers"
    },
    "KMS": {
        "name": "Kumasi Airport",
        "city": "Kumasi",
        "country": "Ghana",
        "iata": "KMS",
        "icao": "DGSI",
        "latitude": "6.714560031890869",
        "longitude": "-1.5908199548721313",
        "altitude": "942",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Accra"
    },
    "HDF": {
        "name": "Heringsdorf Airport",
        "city": "Heringsdorf",
        "country": "Germany",
        "iata": "HDF",
        "icao": "EDAH",
        "latitude": "53.8787002563",
        "longitude": "14.152299881",
        "altitude": "93",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "HEI": {
        "name": "Heide-Büsum Airport",
        "city": "Büsum",
        "country": "Germany",
        "iata": "HEI",
        "icao": "EDXB",
        "latitude": "54.153331756600004",
        "longitude": "8.90166664124",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "HGL": {
        "name": "Helgoland-Düne Airport",
        "city": "Helgoland",
        "country": "Germany",
        "iata": "HGL",
        "icao": "EDXH",
        "latitude": "54.185279846200004",
        "longitude": "7.91583299637",
        "altitude": "8",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "SJY": {
        "name": "Seinäjoki Airport",
        "city": "Seinäjoki / Ilmajoki",
        "country": "Finland",
        "iata": "SJY",
        "icao": "EFSI",
        "latitude": "62.692101",
        "longitude": "22.8323",
        "altitude": "302",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Helsinki"
    },
    "NQT": {
        "name": "Nottingham Airport",
        "city": "Nottingham",
        "country": "United Kingdom",
        "iata": "NQT",
        "icao": "EGBN",
        "latitude": "52.91999816894531",
        "longitude": "-1.0791699886322021",
        "altitude": "138",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "DSA": {
        "name": "Robin Hood Doncaster Sheffield Airport",
        "city": "Doncaster, Sheffield",
        "country": "United Kingdom",
        "iata": "DSA",
        "icao": "EGCN",
        "latitude": "53.4805378105",
        "longitude": "-1.01065635681",
        "altitude": "55",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CAL": {
        "name": "Campbeltown Airport",
        "city": "Campbeltown",
        "country": "United Kingdom",
        "iata": "CAL",
        "icao": "EGEC",
        "latitude": "55.437198638916016",
        "longitude": "-5.686389923095703",
        "altitude": "42",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "EOI": {
        "name": "Eday Airport",
        "city": "Eday",
        "country": "United Kingdom",
        "iata": "EOI",
        "icao": "EGED",
        "latitude": "59.19060134887695",
        "longitude": "-2.7722198963165283",
        "altitude": "10",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "FIE": {
        "name": "Fair Isle Airport",
        "city": "Fair Isle",
        "country": "United Kingdom",
        "iata": "FIE",
        "icao": "EGEF",
        "latitude": "59.53580093383789",
        "longitude": "-1.628059983253479",
        "altitude": "223",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "NRL": {
        "name": "North Ronaldsay Airport",
        "city": "North Ronaldsay",
        "country": "United Kingdom",
        "iata": "NRL",
        "icao": "EGEN",
        "latitude": "59.3675003052",
        "longitude": "-2.43443989754",
        "altitude": "40",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "PPW": {
        "name": "Papa Westray Airport",
        "city": "Papa Westray",
        "country": "United Kingdom",
        "iata": "PPW",
        "icao": "EGEP",
        "latitude": "59.351699829100006",
        "longitude": "-2.9002799987800003",
        "altitude": "91",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SOY": {
        "name": "Stronsay Airport",
        "city": "Stronsay",
        "country": "United Kingdom",
        "iata": "SOY",
        "icao": "EGER",
        "latitude": "59.1553001404",
        "longitude": "-2.64139008522",
        "altitude": "39",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "NDY": {
        "name": "Sanday Airport",
        "city": "Sanday",
        "country": "United Kingdom",
        "iata": "NDY",
        "icao": "EGES",
        "latitude": "59.250301361083984",
        "longitude": "-2.576669931411743",
        "altitude": "68",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LWK": {
        "name": "Lerwick / Tingwall Airport",
        "city": "Lerwick",
        "country": "United Kingdom",
        "iata": "LWK",
        "icao": "EGET",
        "latitude": "60.192199707",
        "longitude": "-1.24361002445",
        "altitude": "43",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "WRY": {
        "name": "Westray Airport",
        "city": "Westray",
        "country": "United Kingdom",
        "iata": "WRY",
        "icao": "EGEW",
        "latitude": "59.3502998352",
        "longitude": "-2.95000004768",
        "altitude": "29",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "LEQ": {
        "name": "Land's End Airport",
        "city": "Land's End",
        "country": "United Kingdom",
        "iata": "LEQ",
        "icao": "EGHC",
        "latitude": "50.102798",
        "longitude": "-5.67056",
        "altitude": "398",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "PZE": {
        "name": "Penzance Heliport",
        "city": "Penzance",
        "country": "United Kingdom",
        "iata": "PZE",
        "icao": "EGHK",
        "latitude": "50.128101",
        "longitude": "-5.51845",
        "altitude": "14",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "VLY": {
        "name": "Anglesey Airport",
        "city": "Angelsey",
        "country": "United Kingdom",
        "iata": "VLY",
        "icao": "EGOV",
        "latitude": "53.2481002808",
        "longitude": "-4.53533983231",
        "altitude": "37",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BRR": {
        "name": "Barra Airport",
        "city": "Barra",
        "country": "United Kingdom",
        "iata": "BRR",
        "icao": "EGPR",
        "latitude": "57.0228",
        "longitude": "-7.44306",
        "altitude": "5",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "CFN": {
        "name": "Donegal Airport",
        "city": "Dongloe",
        "country": "Ireland",
        "iata": "CFN",
        "icao": "EIDL",
        "latitude": "55.0442008972168",
        "longitude": "-8.340999603271484",
        "altitude": "30",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "CNL": {
        "name": "Sindal Airport",
        "city": "Sindal",
        "country": "Denmark",
        "iata": "CNL",
        "icao": "EKSN",
        "latitude": "57.503502",
        "longitude": "10.2294",
        "altitude": "92",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Europe/Copenhagen"
    },
    "LKN": {
        "name": "Leknes Airport",
        "city": "Leknes",
        "country": "Norway",
        "iata": "LKN",
        "icao": "ENLK",
        "latitude": "68.152496337891",
        "longitude": "13.609399795532",
        "altitude": "78",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "OSY": {
        "name": "Namsos Høknesøra Airport",
        "city": "Namsos",
        "country": "Norway",
        "iata": "OSY",
        "icao": "ENNM",
        "latitude": "64.472198486328",
        "longitude": "11.57859992981",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "MQN": {
        "name": "Mo i Rana Airport, Røssvoll",
        "city": "Mo i Rana",
        "country": "Norway",
        "iata": "MQN",
        "icao": "ENRA",
        "latitude": "66.363899230957",
        "longitude": "14.301400184631",
        "altitude": "229",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "RVK": {
        "name": "Rørvik Airport, Ryum",
        "city": "Rørvik",
        "country": "Norway",
        "iata": "RVK",
        "icao": "ENRM",
        "latitude": "64.838302612305",
        "longitude": "11.14610004425",
        "altitude": "14",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "RET": {
        "name": "Røst Airport",
        "city": "Røst",
        "country": "Norway",
        "iata": "RET",
        "icao": "ENRS",
        "latitude": "67.527801513672",
        "longitude": "12.103300094604",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SDN": {
        "name": "Sandane Airport (Anda)",
        "city": "Sandane",
        "country": "Norway",
        "iata": "SDN",
        "icao": "ENSD",
        "latitude": "61.830001831055",
        "longitude": "6.1058301925659",
        "altitude": "196",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SOG": {
        "name": "Sogndal Airport",
        "city": "Sogndal",
        "country": "Norway",
        "iata": "SOG",
        "icao": "ENSG",
        "latitude": "61.156101",
        "longitude": "7.13778",
        "altitude": "1633",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SVJ": {
        "name": "Svolvær Helle Airport",
        "city": "Svolvær",
        "country": "Norway",
        "iata": "SVJ",
        "icao": "ENSH",
        "latitude": "68.243301391602",
        "longitude": "14.669199943542",
        "altitude": "27",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "SOJ": {
        "name": "Sørkjosen Airport",
        "city": "Sorkjosen",
        "country": "Norway",
        "iata": "SOJ",
        "icao": "ENSR",
        "latitude": "69.786796569824",
        "longitude": "20.959400177002",
        "altitude": "16",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "VAW": {
        "name": "Vardø Airport, Svartnes",
        "city": "Vardø",
        "country": "Norway",
        "iata": "VAW",
        "icao": "ENSS",
        "latitude": "70.355400085449",
        "longitude": "31.044900894165",
        "altitude": "42",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "VRY": {
        "name": "Værøy Heliport",
        "city": "Værøy",
        "country": "Norway",
        "iata": "VRY",
        "icao": "ENVR",
        "latitude": "67.654555",
        "longitude": "12.727257",
        "altitude": "12",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "BZG": {
        "name": "Bydgoszcz Ignacy Jan Paderewski Airport",
        "city": "Bydgoszcz",
        "country": "Poland",
        "iata": "BZG",
        "icao": "EPBY",
        "latitude": "53.096801757799994",
        "longitude": "17.9776992798",
        "altitude": "235",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "LCJ": {
        "name": "Łódź Władysław Reymont Airport",
        "city": "Lodz",
        "country": "Poland",
        "iata": "LCJ",
        "icao": "EPLL",
        "latitude": "51.721900939899996",
        "longitude": "19.3980998993",
        "altitude": "604",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "OSD": {
        "name": "Åre Östersund Airport",
        "city": "Östersund",
        "country": "Sweden",
        "iata": "OSD",
        "icao": "ESNZ",
        "latitude": "63.194400787354",
        "longitude": "14.50030040741",
        "altitude": "1233",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "HFS": {
        "name": "Hagfors Airport",
        "city": "Hagfors",
        "country": "Sweden",
        "iata": "HFS",
        "icao": "ESOH",
        "latitude": "60.02009963989258",
        "longitude": "13.578900337219238",
        "altitude": "474",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "KSD": {
        "name": "Karlstad Airport",
        "city": "Karlstad",
        "country": "Sweden",
        "iata": "KSD",
        "icao": "ESOK",
        "latitude": "59.444698333699996",
        "longitude": "13.337400436400001",
        "altitude": "352",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "TYF": {
        "name": "Torsby Airport",
        "city": "Torsby",
        "country": "Sweden",
        "iata": "TYF",
        "icao": "ESST",
        "latitude": "60.1576004028",
        "longitude": "12.991299629199998",
        "altitude": "393",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "AGH": {
        "name": "Ängelholm-Helsingborg Airport",
        "city": "Ängelholm",
        "country": "Sweden",
        "iata": "AGH",
        "icao": "ESTA",
        "latitude": "56.29610061645508",
        "longitude": "12.847100257873535",
        "altitude": "68",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "SQO": {
        "name": "Storuman Airport",
        "city": "Mohed",
        "country": "Sweden",
        "iata": "SQO",
        "icao": "ESUD",
        "latitude": "64.96089935302734",
        "longitude": "17.69659996032715",
        "altitude": "915",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "HMV": {
        "name": "Hemavan Airport",
        "city": "Hemavan",
        "country": "Sweden",
        "iata": "HMV",
        "icao": "ESUT",
        "latitude": "65.80609893798828",
        "longitude": "15.082799911499023",
        "altitude": "1503",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "VNT": {
        "name": "Ventspils International Airport",
        "city": "Ventspils",
        "country": "Latvia",
        "iata": "VNT",
        "icao": "EVVA",
        "latitude": "57.35779953",
        "longitude": "21.5442008972",
        "altitude": "19",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Riga"
    },
    "QRA": {
        "name": "Rand Airport",
        "city": "Johannesburg",
        "country": "South Africa",
        "iata": "QRA",
        "icao": "FAGM",
        "latitude": "-26.2425003052",
        "longitude": "28.1511993408",
        "altitude": "5483",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "MQP": {
        "name": "Kruger Mpumalanga International Airport",
        "city": "Mpumalanga",
        "country": "South Africa",
        "iata": "MQP",
        "icao": "FAKN",
        "latitude": "-25.3831996918",
        "longitude": "31.1056003571",
        "altitude": "2829",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "AAM": {
        "name": "Malamala Airport",
        "city": "Malamala",
        "country": "South Africa",
        "iata": "AAM",
        "icao": "FAMD",
        "latitude": "-24.818099975585938",
        "longitude": "31.544599533081055",
        "altitude": "1124",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "MBD": {
        "name": "Mmabatho International Airport",
        "city": "Mafeking",
        "country": "South Africa",
        "iata": "MBD",
        "icao": "FAMM",
        "latitude": "-25.798400878900004",
        "longitude": "25.548000335699996",
        "altitude": "4181",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "GNZ": {
        "name": "Ghanzi Airport",
        "city": "Ghanzi",
        "country": "Botswana",
        "iata": "GNZ",
        "icao": "FBGZ",
        "latitude": "-21.6924991607666",
        "longitude": "21.658100128173828",
        "altitude": "3730",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "ORP": {
        "name": "Orapa Airport",
        "city": "Orapa",
        "country": "Botswana",
        "iata": "ORP",
        "icao": "FBOR",
        "latitude": "-21.266701",
        "longitude": "25.3167",
        "altitude": "3100",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "SWX": {
        "name": "Shakawe Airport",
        "city": "Shakawe",
        "country": "Botswana",
        "iata": "SWX",
        "icao": "FBSW",
        "latitude": "-18.373899459838867",
        "longitude": "21.832599639892578",
        "altitude": "3379",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "TLD": {
        "name": "Limpopo Valley Airport",
        "city": "Tuli Lodge",
        "country": "Botswana",
        "iata": "TLD",
        "icao": "FBTL",
        "latitude": "-22.189199447599997",
        "longitude": "29.126899719199997",
        "altitude": "1772",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Gaborone"
    },
    "DIS": {
        "name": "Ngot Nzoungou Airport",
        "city": "Loubomo",
        "country": "Congo (Brazzaville)",
        "iata": "DIS",
        "icao": "FCPL",
        "latitude": "-4.20635",
        "longitude": "12.6599",
        "altitude": "1079",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Brazzaville"
    },
    "CIP": {
        "name": "Chipata Airport",
        "city": "Chipata",
        "country": "Zambia",
        "iata": "CIP",
        "icao": "FLCP",
        "latitude": "-13.558300018310547",
        "longitude": "32.58720016479492",
        "altitude": "3360",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "YVA": {
        "name": "Iconi Airport",
        "city": "Moroni",
        "country": "Comoros",
        "iata": "YVA",
        "icao": "FMCN",
        "latitude": "-11.710800170899999",
        "longitude": "43.2439002991",
        "altitude": "33",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Comoro"
    },
    "WAQ": {
        "name": "Antsalova Airport",
        "city": "Antsalova",
        "country": "Madagascar",
        "iata": "WAQ",
        "icao": "FMMG",
        "latitude": "-18.701273",
        "longitude": "44.614921",
        "altitude": "551",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "JVA": {
        "name": "Ankavandra Airport",
        "city": "Ankavandra",
        "country": "Madagascar",
        "iata": "JVA",
        "icao": "FMMK",
        "latitude": "-18.80501",
        "longitude": "45.273467",
        "altitude": "427",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "BMD": {
        "name": "Belo sur Tsiribihina Airport",
        "city": "Belo sur Tsiribihina",
        "country": "Madagascar",
        "iata": "BMD",
        "icao": "FMML",
        "latitude": "-19.6867008209",
        "longitude": "44.541900634799994",
        "altitude": "154",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "MXT": {
        "name": "Maintirano Airport",
        "city": "Maintirano",
        "country": "Madagascar",
        "iata": "MXT",
        "icao": "FMMO",
        "latitude": "-18.049999",
        "longitude": "44.033001",
        "altitude": "95",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "TVA": {
        "name": "Morafenobe Airport",
        "city": "Morafenobe",
        "country": "Madagascar",
        "iata": "TVA",
        "icao": "FMMR",
        "latitude": "-17.850083",
        "longitude": "44.920467",
        "altitude": "748",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WTA": {
        "name": "Tambohorano Airport",
        "city": "Tambohorano",
        "country": "Madagascar",
        "iata": "WTA",
        "icao": "FMMU",
        "latitude": "-17.47610092163086",
        "longitude": "43.972801208496094",
        "altitude": "23",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WTS": {
        "name": "Tsiroanomandidy Airport",
        "city": "Tsiroanomandidy",
        "country": "Madagascar",
        "iata": "WTS",
        "icao": "FMMX",
        "latitude": "-18.759677",
        "longitude": "46.054065",
        "altitude": "2776",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WAM": {
        "name": "Ambatondrazaka Airport",
        "city": "Ambatondrazaka",
        "country": "Madagascar",
        "iata": "WAM",
        "icao": "FMMZ",
        "latitude": "-17.795378",
        "longitude": "48.442583",
        "altitude": "2513",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WPB": {
        "name": "Port Bergé Airport",
        "city": "Port Bergé",
        "country": "Madagascar",
        "iata": "WPB",
        "icao": "FMNG",
        "latitude": "-15.584286",
        "longitude": "47.623587",
        "altitude": "213",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "DWB": {
        "name": "Soalala Airport",
        "city": "Soalala",
        "country": "Madagascar",
        "iata": "DWB",
        "icao": "FMNO",
        "latitude": "-16.10169",
        "longitude": "45.358837",
        "altitude": "141",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WMP": {
        "name": "Mampikony Airport",
        "city": "Mampikony",
        "country": "Madagascar",
        "iata": "WMP",
        "icao": "FMNP",
        "latitude": "-16.0722693402",
        "longitude": "47.644164562200004",
        "altitude": "0",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "WMA": {
        "name": "Mandritsara Airport",
        "city": "Mandritsara",
        "country": "Madagascar",
        "iata": "WMA",
        "icao": "FMNX",
        "latitude": "-15.833049",
        "longitude": "48.833284",
        "altitude": "1007",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "MJA": {
        "name": "Manja Airport",
        "city": "Manja",
        "country": "Madagascar",
        "iata": "MJA",
        "icao": "FMSJ",
        "latitude": "-21.426105",
        "longitude": "44.316509",
        "altitude": "787",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "CBT": {
        "name": "Catumbela Airport",
        "city": "Catumbela",
        "country": "Angola",
        "iata": "CBT",
        "icao": "FNCT",
        "latitude": "-12.4792",
        "longitude": "13.4869",
        "altitude": "23",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "DUE": {
        "name": "Dundo Airport",
        "city": "Dundo",
        "country": "Angola",
        "iata": "DUE",
        "icao": "FNDU",
        "latitude": "-7.400889873504639",
        "longitude": "20.818500518798828",
        "altitude": "2451",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "VPE": {
        "name": "Ngjiva Pereira Airport",
        "city": "Ondjiva",
        "country": "Angola",
        "iata": "VPE",
        "icao": "FNGI",
        "latitude": "-17.0435009003",
        "longitude": "15.683799743700002",
        "altitude": "3566",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "MSZ": {
        "name": "Namibe Airport",
        "city": "Mocamedes",
        "country": "Angola",
        "iata": "MSZ",
        "icao": "FNMO",
        "latitude": "-15.261199951171875",
        "longitude": "12.14680004119873",
        "altitude": "210",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Luanda"
    },
    "KOU": {
        "name": "Koulamoutou Mabimbi Airport",
        "city": "Koulamoutou",
        "country": "Gabon",
        "iata": "KOU",
        "icao": "FOGK",
        "latitude": "-1.1846100091934",
        "longitude": "12.441300392151",
        "altitude": "1070",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "MJL": {
        "name": "Mouilla Ville Airport",
        "city": "Mouila",
        "country": "Gabon",
        "iata": "MJL",
        "icao": "FOGM",
        "latitude": "-1.845139980316162",
        "longitude": "11.056699752807617",
        "altitude": "295",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "TCH": {
        "name": "Tchibanga Airport",
        "city": "Tchibanga",
        "country": "Gabon",
        "iata": "TCH",
        "icao": "FOOT",
        "latitude": "-2.8499999046325684",
        "longitude": "11.017000198364258",
        "altitude": "269",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Libreville"
    },
    "VPY": {
        "name": "Chimoio Airport",
        "city": "Chimoio",
        "country": "Mozambique",
        "iata": "VPY",
        "icao": "FQCH",
        "latitude": "-19.15130043029785",
        "longitude": "33.42900085449219",
        "altitude": "2287",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "SRH": {
        "name": "Sarh Airport",
        "city": "Sarh",
        "country": "Chad",
        "iata": "SRH",
        "icao": "FTTA",
        "latitude": "9.14444",
        "longitude": "18.374399",
        "altitude": "1198",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Ndjamena"
    },
    "CMK": {
        "name": "Club Makokola Airport",
        "city": "Club Makokola",
        "country": "Malawi",
        "iata": "CMK",
        "icao": "FWCM",
        "latitude": "-14.306900024414062",
        "longitude": "35.13249969482422",
        "altitude": "1587",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Blantyre"
    },
    "LUD": {
        "name": "Luderitz Airport",
        "city": "Luderitz",
        "country": "Namibia",
        "iata": "LUD",
        "icao": "FYLZ",
        "latitude": "-26.687400817871094",
        "longitude": "15.242899894714355",
        "altitude": "457",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "OND": {
        "name": "Ondangwa Airport",
        "city": "Ondangwa",
        "country": "Namibia",
        "iata": "OND",
        "icao": "FYOA",
        "latitude": "-17.878201",
        "longitude": "15.9526",
        "altitude": "3599",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "OMD": {
        "name": "Oranjemund Airport",
        "city": "Oranjemund",
        "country": "Namibia",
        "iata": "OMD",
        "icao": "FYOG",
        "latitude": "-28.5847",
        "longitude": "16.446699",
        "altitude": "14",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "SWP": {
        "name": "Swakopmund Airport",
        "city": "Swakopmund",
        "country": "Namibia",
        "iata": "SWP",
        "icao": "FYSM",
        "latitude": "-22.66189956665",
        "longitude": "14.568099975586",
        "altitude": "207",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "ERS": {
        "name": "Eros Airport",
        "city": "Windhoek",
        "country": "Namibia",
        "iata": "ERS",
        "icao": "FYWE",
        "latitude": "-22.612199783325195",
        "longitude": "17.080400466918945",
        "altitude": "5575",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "BOA": {
        "name": "Boma Airport",
        "city": "Boma",
        "country": "Congo (Kinshasa)",
        "iata": "BOA",
        "icao": "FZAJ",
        "latitude": "-5.854000091552734",
        "longitude": "13.064000129699707",
        "altitude": "26",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "MAT": {
        "name": "Tshimpi Airport",
        "city": "Matadi",
        "country": "Congo (Kinshasa)",
        "iata": "MAT",
        "icao": "FZAM",
        "latitude": "-5.799610137939453",
        "longitude": "13.440400123596191",
        "altitude": "1115",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "INO": {
        "name": "Inongo Airport",
        "city": "Inongo",
        "country": "Congo (Kinshasa)",
        "iata": "INO",
        "icao": "FZBA",
        "latitude": "-1.947219967842102",
        "longitude": "18.28580093383789",
        "altitude": "1040",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "NIO": {
        "name": "Nioki Airport",
        "city": "Nioki",
        "country": "Congo (Kinshasa)",
        "iata": "NIO",
        "icao": "FZBI",
        "latitude": "-2.7174999713897705",
        "longitude": "17.68470001220703",
        "altitude": "1043",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "KRZ": {
        "name": "Basango Mboliasa Airport",
        "city": "Kiri",
        "country": "Congo (Kinshasa)",
        "iata": "KRZ",
        "icao": "FZBT",
        "latitude": "-1.434999942779541",
        "longitude": "19.02400016784668",
        "altitude": "1013",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "BSU": {
        "name": "Basankusu Airport",
        "city": "Basankusu",
        "country": "Congo (Kinshasa)",
        "iata": "BSU",
        "icao": "FZEN",
        "latitude": "1.2247200012207031",
        "longitude": "19.78890037536621",
        "altitude": "1217",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Kinshasa"
    },
    "TSH": {
        "name": "Tshikapa Airport",
        "city": "Tshikapa",
        "country": "Congo (Kinshasa)",
        "iata": "TSH",
        "icao": "FZUK",
        "latitude": "-6.43833",
        "longitude": "20.794701",
        "altitude": "1595",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Lubumbashi"
    },
    "LJA": {
        "name": "Lodja Airport",
        "city": "Lodja",
        "country": "Congo (Kinshasa)",
        "iata": "LJA",
        "icao": "FZVA",
        "latitude": "-3.4170000553131104",
        "longitude": "23.450000762939453",
        "altitude": "1647",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Lubumbashi"
    },
    "PFR": {
        "name": "Ilebo Airport",
        "city": "Ilebo",
        "country": "Congo (Kinshasa)",
        "iata": "PFR",
        "icao": "FZVS",
        "latitude": "-4.329919",
        "longitude": "20.590124",
        "altitude": "1450",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Lubumbashi"
    },
    "OUK": {
        "name": "Outer Skerries Airport",
        "city": "Outer Skerries",
        "country": "United Kingdom",
        "iata": "OUK",
        "icao": "EGOU",
        "latitude": "60.4252",
        "longitude": "-0.75",
        "altitude": "76",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "GMZ": {
        "name": "La Gomera Airport",
        "city": "La Gomera",
        "country": "Spain",
        "iata": "GMZ",
        "icao": "GCGM",
        "latitude": "28.029600143432617",
        "longitude": "-17.214599609375",
        "altitude": "716",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Canary"
    },
    "BTE": {
        "name": "Sherbro International Airport",
        "city": "Bonthe",
        "country": "Sierra Leone",
        "iata": "BTE",
        "icao": "GFBN",
        "latitude": "7.5324201583862305",
        "longitude": "-12.518899917602539",
        "altitude": "14",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Freetown"
    },
    "KBS": {
        "name": "Bo Airport",
        "city": "Bo",
        "country": "Sierra Leone",
        "iata": "KBS",
        "icao": "GFBO",
        "latitude": "7.944399833679199",
        "longitude": "-11.76099967956543",
        "altitude": "328",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Freetown"
    },
    "KEN": {
        "name": "Kenema Airport",
        "city": "Kenema",
        "country": "Sierra Leone",
        "iata": "KEN",
        "icao": "GFKE",
        "latitude": "7.891290187835693",
        "longitude": "-11.176600456237793",
        "altitude": "485",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Freetown"
    },
    "OXB": {
        "name": "Osvaldo Vieira International Airport",
        "city": "Bissau",
        "country": "Guinea-Bissau",
        "iata": "OXB",
        "icao": "GGOV",
        "latitude": "11.894800186157227",
        "longitude": "-15.65369987487793",
        "altitude": "129",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bissau"
    },
    "SMW": {
        "name": "Smara Airport",
        "city": "Smara",
        "country": "Western Sahara",
        "iata": "SMW",
        "icao": "GMMA",
        "latitude": "26.7318",
        "longitude": "-11.6847",
        "altitude": "350",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/El_Aaiun"
    },
    "VIL": {
        "name": "Dakhla Airport",
        "city": "Dakhla",
        "country": "Western Sahara",
        "iata": "VIL",
        "icao": "GMMH",
        "latitude": "23.7183",
        "longitude": "-15.932",
        "altitude": "36",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/El_Aaiun"
    },
    "ESU": {
        "name": "Mogador Airport",
        "city": "Essadouira",
        "country": "Morocco",
        "iata": "ESU",
        "icao": "GMMI",
        "latitude": "31.3974990845",
        "longitude": "-9.6816701889",
        "altitude": "384",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "EUN": {
        "name": "Hassan I Airport",
        "city": "El Aaiún",
        "country": "Western Sahara",
        "iata": "EUN",
        "icao": "GMML",
        "latitude": "27.151699",
        "longitude": "-13.2192",
        "altitude": "207",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/El_Aaiun"
    },
    "NDR": {
        "name": "Nador International Airport",
        "city": "El Aroui",
        "country": "Morocco",
        "iata": "NDR",
        "icao": "GMMW",
        "latitude": "34.988800048799995",
        "longitude": "-3.0282099247",
        "altitude": "574",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "RAI": {
        "name": "Praia International Airport",
        "city": "Praia, Santiago Island",
        "country": "Cape Verde",
        "iata": "RAI",
        "icao": "GVNP",
        "latitude": "14.924500465393066",
        "longitude": "-23.493499755859375",
        "altitude": "230",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "SFL": {
        "name": "São Filipe Airport",
        "city": "Sao Filipe, Fogo Island",
        "country": "Cape Verde",
        "iata": "SFL",
        "icao": "GVSF",
        "latitude": "14.8850002289",
        "longitude": "-24.4799995422",
        "altitude": "617",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "Atlantic/Cape_Verde"
    },
    "BCO": {
        "name": "Baco Airport",
        "city": "Baco",
        "country": "Ethiopia",
        "iata": "BCO",
        "icao": "HABC",
        "latitude": "5.78287",
        "longitude": "36.562",
        "altitude": "4580",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "BEI": {
        "name": "Beica Airport",
        "city": "Beica",
        "country": "Ethiopia",
        "iata": "BEI",
        "icao": "HABE",
        "latitude": "9.38638973236084",
        "longitude": "34.52190017700195",
        "altitude": "5410",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "DSE": {
        "name": "Combolcha Airport",
        "city": "Dessie",
        "country": "Ethiopia",
        "iata": "DSE",
        "icao": "HADC",
        "latitude": "11.082500457763672",
        "longitude": "39.71139907836914",
        "altitude": "6117",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "DEM": {
        "name": "Dembidollo Airport",
        "city": "Dembidollo",
        "country": "Ethiopia",
        "iata": "DEM",
        "icao": "HADD",
        "latitude": "8.553999900817871",
        "longitude": "34.858001708984375",
        "altitude": "5200",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "GDE": {
        "name": "Gode Airport",
        "city": "Gode",
        "country": "Ethiopia",
        "iata": "GDE",
        "icao": "HAGO",
        "latitude": "5.93513011932",
        "longitude": "43.5786018372",
        "altitude": "834",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "GOR": {
        "name": "Gore Airport",
        "city": "Gore",
        "country": "Ethiopia",
        "iata": "GOR",
        "icao": "HAGR",
        "latitude": "8.1614",
        "longitude": "35.5529",
        "altitude": "6580",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "ABK": {
        "name": "Kabri Dehar Airport",
        "city": "Kabri Dehar",
        "country": "Ethiopia",
        "iata": "ABK",
        "icao": "HAKD",
        "latitude": "6.734",
        "longitude": "44.252998",
        "altitude": "1800",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "MTF": {
        "name": "Mizan Teferi Airport",
        "city": "Mizan Teferi",
        "country": "Ethiopia",
        "iata": "MTF",
        "icao": "HAMT",
        "latitude": "6.9571",
        "longitude": "35.5547",
        "altitude": "4396",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "TIE": {
        "name": "Tippi Airport",
        "city": "Tippi",
        "country": "Ethiopia",
        "iata": "TIE",
        "icao": "HATP",
        "latitude": "7.2024",
        "longitude": "35.415",
        "altitude": "1100",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "ALU": {
        "name": "Alula Airport",
        "city": "Alula",
        "country": "Somalia",
        "iata": "ALU",
        "icao": "HCMA",
        "latitude": "11.9582",
        "longitude": "50.748",
        "altitude": "6",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "BSA": {
        "name": "Bosaso Airport",
        "city": "Bosaso",
        "country": "Somalia",
        "iata": "BSA",
        "icao": "HCMF",
        "latitude": "11.275300025939941",
        "longitude": "49.14939880371094",
        "altitude": "3",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "MGQ": {
        "name": "Aden Adde International Airport",
        "city": "Mogadishu",
        "country": "Somalia",
        "iata": "MGQ",
        "icao": "HCMM",
        "latitude": "2.0144400596618652",
        "longitude": "45.3046989440918",
        "altitude": "29",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "GLK": {
        "name": "Galcaio Airport",
        "city": "Galcaio",
        "country": "Somalia",
        "iata": "GLK",
        "icao": "HCMR",
        "latitude": "6.78082990646",
        "longitude": "47.45470047",
        "altitude": "975",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "BUO": {
        "name": "Burao Airport",
        "city": "Burao",
        "country": "Somalia",
        "iata": "BUO",
        "icao": "HCMV",
        "latitude": "9.5275",
        "longitude": "45.5549",
        "altitude": "3400",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Mogadishu"
    },
    "AAC": {
        "name": "El Arish International Airport",
        "city": "El Arish",
        "country": "Egypt",
        "iata": "AAC",
        "icao": "HEAR",
        "latitude": "31.073299408",
        "longitude": "33.8358001709",
        "altitude": "121",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "ATZ": {
        "name": "Assiut International Airport",
        "city": "Asyut",
        "country": "Egypt",
        "iata": "ATZ",
        "icao": "HEAT",
        "latitude": "27.0464992523",
        "longitude": "31.0119991302",
        "altitude": "772",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "ASV": {
        "name": "Amboseli Airport",
        "city": "Amboseli National Park",
        "country": "Kenya",
        "iata": "ASV",
        "icao": "HKAM",
        "latitude": "-2.645050048828125",
        "longitude": "37.25310134887695",
        "altitude": "3755",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "LKG": {
        "name": "Lokichoggio Airport",
        "city": "Lokichoggio",
        "country": "Kenya",
        "iata": "LKG",
        "icao": "HKLK",
        "latitude": "4.20412015914917",
        "longitude": "34.348201751708984",
        "altitude": "2074",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "MYD": {
        "name": "Malindi Airport",
        "city": "Malindi",
        "country": "Kenya",
        "iata": "MYD",
        "icao": "HKML",
        "latitude": "-3.2293100357055664",
        "longitude": "40.10169982910156",
        "altitude": "80",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "NYK": {
        "name": "Nanyuki Airport",
        "city": "Nanyuki",
        "country": "Kenya",
        "iata": "NYK",
        "icao": "HKNY",
        "latitude": "-0.06239889934659004",
        "longitude": "37.04100799560547",
        "altitude": "6250",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "SRX": {
        "name": "Gardabya Airport",
        "city": "Sirt",
        "country": "Libya",
        "iata": "SRX",
        "icao": "HLGD",
        "latitude": "31.063499450699997",
        "longitude": "16.5949993134",
        "altitude": "267",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "TOB": {
        "name": "Gamal Abdel Nasser Airport",
        "city": "Tobruk",
        "country": "Libya",
        "iata": "TOB",
        "icao": "HLGN",
        "latitude": "31.861",
        "longitude": "23.907",
        "altitude": "519",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "MJI": {
        "name": "Mitiga Airport",
        "city": "Tripoli",
        "country": "Libya",
        "iata": "MJI",
        "icao": "HLLM",
        "latitude": "32.894100189208984",
        "longitude": "13.276000022888184",
        "altitude": "36",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "LAQ": {
        "name": "La Abraq Airport",
        "city": "Al Bayda'",
        "country": "Libya",
        "iata": "LAQ",
        "icao": "HLLQ",
        "latitude": "32.788700103759766",
        "longitude": "21.96430015563965",
        "altitude": "2157",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Tripoli"
    },
    "ATB": {
        "name": "Atbara Airport",
        "city": "Atbara",
        "country": "Sudan",
        "iata": "ATB",
        "icao": "HSAT",
        "latitude": "17.710344314575195",
        "longitude": "34.0570182800293",
        "altitude": "1181",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "UYL": {
        "name": "Nyala Airport",
        "city": "Nyala",
        "country": "Sudan",
        "iata": "UYL",
        "icao": "HSNN",
        "latitude": "12.053500175476074",
        "longitude": "24.956199645996094",
        "altitude": "2106",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "PZU": {
        "name": "Port Sudan New International Airport",
        "city": "Port Sudan",
        "country": "Sudan",
        "iata": "PZU",
        "icao": "HSPN",
        "latitude": "19.4335994720459",
        "longitude": "37.234100341796875",
        "altitude": "135",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "BKZ": {
        "name": "Bukoba Airport",
        "city": "Bukoba",
        "country": "Tanzania",
        "iata": "BKZ",
        "icao": "HTBU",
        "latitude": "-1.332",
        "longitude": "31.8212",
        "altitude": "3784",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "TKQ": {
        "name": "Kigoma Airport",
        "city": "Kigoma",
        "country": "Tanzania",
        "iata": "TKQ",
        "icao": "HTKA",
        "latitude": "-4.8862",
        "longitude": "29.6709",
        "altitude": "2700",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "LDI": {
        "name": "Lindi Airport",
        "city": "Lindi",
        "country": "Tanzania",
        "iata": "LDI",
        "icao": "HTLI",
        "latitude": "-9.85111",
        "longitude": "39.757801",
        "altitude": "100",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "MUZ": {
        "name": "Musoma Airport",
        "city": "Musoma",
        "country": "Tanzania",
        "iata": "MUZ",
        "icao": "HTMU",
        "latitude": "-1.503",
        "longitude": "33.8021",
        "altitude": "3806",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "SHY": {
        "name": "Shinyanga Airport",
        "city": "Shinyanga",
        "country": "Tanzania",
        "iata": "SHY",
        "icao": "HTSY",
        "latitude": "-3.6093",
        "longitude": "33.5035",
        "altitude": "3800",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "TBO": {
        "name": "Tabora Airport",
        "city": "Tabora",
        "country": "Tanzania",
        "iata": "TBO",
        "icao": "HTTB",
        "latitude": "-5.076389789581299",
        "longitude": "32.83330154418945",
        "altitude": "3868",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "RUA": {
        "name": "Arua Airport",
        "city": "Arua",
        "country": "Uganda",
        "iata": "RUA",
        "icao": "HUAR",
        "latitude": "3.049999952316284",
        "longitude": "30.91699981689453",
        "altitude": "3951",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Kampala"
    },
    "ULU": {
        "name": "Gulu Airport",
        "city": "Gulu",
        "country": "Uganda",
        "iata": "ULU",
        "icao": "HUGU",
        "latitude": "2.8055601119995117",
        "longitude": "32.27180099487305",
        "altitude": "3510",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Kampala"
    },
    "DIU": {
        "name": "Diu Airport",
        "city": "Diu",
        "country": "India",
        "iata": "DIU",
        "icao": "VA1P",
        "latitude": "20.71310043334961",
        "longitude": "70.92109680175781",
        "altitude": "31",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "ABR": {
        "name": "Aberdeen Regional Airport",
        "city": "Aberdeen",
        "country": "United States",
        "iata": "ABR",
        "icao": "KABR",
        "latitude": "45.449100494384766",
        "longitude": "-98.42179870605469",
        "altitude": "1302",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ABY": {
        "name": "Southwest Georgia Regional Airport",
        "city": "Albany",
        "country": "United States",
        "iata": "ABY",
        "icao": "KABY",
        "latitude": "31.535499572753906",
        "longitude": "-84.19450378417969",
        "altitude": "197",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AHN": {
        "name": "Athens Ben Epps Airport",
        "city": "Athens",
        "country": "United States",
        "iata": "AHN",
        "icao": "KAHN",
        "latitude": "33.94860076904297",
        "longitude": "-83.32630157470703",
        "altitude": "808",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ALM": {
        "name": "Alamogordo White Sands Regional Airport",
        "city": "Alamogordo",
        "country": "United States",
        "iata": "ALM",
        "icao": "KALM",
        "latitude": "32.8399009705",
        "longitude": "-105.990997314",
        "altitude": "4200",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ALO": {
        "name": "Waterloo Regional Airport",
        "city": "Waterloo",
        "country": "United States",
        "iata": "ALO",
        "icao": "KALO",
        "latitude": "42.557098388671875",
        "longitude": "-92.40029907226562",
        "altitude": "873",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ALW": {
        "name": "Walla Walla Regional Airport",
        "city": "Walla Walla",
        "country": "United States",
        "iata": "ALW",
        "icao": "KALW",
        "latitude": "46.09489822",
        "longitude": "-118.288002",
        "altitude": "1194",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "APN": {
        "name": "Alpena County Regional Airport",
        "city": "Alpena",
        "country": "United States",
        "iata": "APN",
        "icao": "KAPN",
        "latitude": "45.0780983",
        "longitude": "-83.56030273",
        "altitude": "690",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ATY": {
        "name": "Watertown Regional Airport",
        "city": "Watertown",
        "country": "United States",
        "iata": "ATY",
        "icao": "KATY",
        "latitude": "44.91400146",
        "longitude": "-97.15470123",
        "altitude": "1749",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BFD": {
        "name": "Bradford Regional Airport",
        "city": "Bradford",
        "country": "United States",
        "iata": "BFD",
        "icao": "KBFD",
        "latitude": "41.8031005859375",
        "longitude": "-78.64009857177734",
        "altitude": "2143",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BFF": {
        "name": "Western Neb. Rgnl/William B. Heilig Airport",
        "city": "Scottsbluff",
        "country": "United States",
        "iata": "BFF",
        "icao": "KBFF",
        "latitude": "41.87400055",
        "longitude": "-103.5960007",
        "altitude": "3967",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BKW": {
        "name": "Raleigh County Memorial Airport",
        "city": "Beckley",
        "country": "United States",
        "iata": "BKW",
        "icao": "KBKW",
        "latitude": "37.787300109899995",
        "longitude": "-81.1241989136",
        "altitude": "2504",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BQK": {
        "name": "Brunswick Golden Isles Airport",
        "city": "Brunswick",
        "country": "United States",
        "iata": "BQK",
        "icao": "KBQK",
        "latitude": "31.258800506591797",
        "longitude": "-81.46649932861328",
        "altitude": "26",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BRL": {
        "name": "Southeast Iowa Regional Airport",
        "city": "Burlington",
        "country": "United States",
        "iata": "BRL",
        "icao": "KBRL",
        "latitude": "40.783199310302734",
        "longitude": "-91.12550354003906",
        "altitude": "698",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CEC": {
        "name": "Jack Mc Namara Field Airport",
        "city": "Crescent City",
        "country": "United States",
        "iata": "CEC",
        "icao": "KCEC",
        "latitude": "41.78020096",
        "longitude": "-124.2369995",
        "altitude": "61",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CGI": {
        "name": "Cape Girardeau Regional Airport",
        "city": "Cape Girardeau",
        "country": "United States",
        "iata": "CGI",
        "icao": "KCGI",
        "latitude": "37.22529983520508",
        "longitude": "-89.57080078125",
        "altitude": "342",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CIU": {
        "name": "Chippewa County International Airport",
        "city": "Sault Ste Marie",
        "country": "United States",
        "iata": "CIU",
        "icao": "KCIU",
        "latitude": "46.25080108642578",
        "longitude": "-84.47239685058594",
        "altitude": "800",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CKB": {
        "name": "North Central West Virginia Airport",
        "city": "Clarksburg",
        "country": "United States",
        "iata": "CKB",
        "icao": "KCKB",
        "latitude": "39.2966003418",
        "longitude": "-80.2281036377",
        "altitude": "1217",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CLM": {
        "name": "William R Fairchild International Airport",
        "city": "Port Angeles",
        "country": "United States",
        "iata": "CLM",
        "icao": "KCLM",
        "latitude": "48.120201110839844",
        "longitude": "-123.5",
        "altitude": "291",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CMX": {
        "name": "Houghton County Memorial Airport",
        "city": "Hancock",
        "country": "United States",
        "iata": "CMX",
        "icao": "KCMX",
        "latitude": "47.168399810791016",
        "longitude": "-88.48909759521484",
        "altitude": "1095",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DDC": {
        "name": "Dodge City Regional Airport",
        "city": "Dodge City",
        "country": "United States",
        "iata": "DDC",
        "icao": "KDDC",
        "latitude": "37.76340103149414",
        "longitude": "-99.9655990600586",
        "altitude": "2594",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DUJ": {
        "name": "DuBois Regional Airport",
        "city": "Du Bois",
        "country": "United States",
        "iata": "DUJ",
        "icao": "KDUJ",
        "latitude": "41.17829895",
        "longitude": "-78.8986969",
        "altitude": "1817",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "EAU": {
        "name": "Chippewa Valley Regional Airport",
        "city": "Eau Claire",
        "country": "United States",
        "iata": "EAU",
        "icao": "KEAU",
        "latitude": "44.86579895019531",
        "longitude": "-91.48429870605469",
        "altitude": "913",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EKO": {
        "name": "Elko Regional Airport",
        "city": "Elko",
        "country": "United States",
        "iata": "EKO",
        "icao": "KEKO",
        "latitude": "40.82490158081055",
        "longitude": "-115.79199981689453",
        "altitude": "5140",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "EWB": {
        "name": "New Bedford Regional Airport",
        "city": "New Bedford",
        "country": "United States",
        "iata": "EWB",
        "icao": "KEWB",
        "latitude": "41.67610168457031",
        "longitude": "-70.95690155029297",
        "altitude": "80",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FAY": {
        "name": "Fayetteville Regional Grannis Field",
        "city": "Fayetteville",
        "country": "United States",
        "iata": "FAY",
        "icao": "KFAY",
        "latitude": "34.9911994934082",
        "longitude": "-78.88030242919922",
        "altitude": "189",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GGW": {
        "name": "Wokal Field Glasgow International Airport",
        "city": "Glasgow",
        "country": "United States",
        "iata": "GGW",
        "icao": "KGGW",
        "latitude": "48.212502",
        "longitude": "-106.614998",
        "altitude": "2296",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "GRI": {
        "name": "Central Nebraska Regional Airport",
        "city": "Grand Island",
        "country": "United States",
        "iata": "GRI",
        "icao": "KGRI",
        "latitude": "40.967498779296875",
        "longitude": "-98.30960083007812",
        "altitude": "1847",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HOT": {
        "name": "Memorial Field",
        "city": "Hot Springs",
        "country": "United States",
        "iata": "HOT",
        "icao": "KHOT",
        "latitude": "34.47800064086914",
        "longitude": "-93.09619903564453",
        "altitude": "540",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HTS": {
        "name": "Tri-State/Milton J. Ferguson Field",
        "city": "Huntington",
        "country": "United States",
        "iata": "HTS",
        "icao": "KHTS",
        "latitude": "38.36669922",
        "longitude": "-82.55799866",
        "altitude": "828",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "KIO": {
        "name": "Kili Airport",
        "city": "Kili Island",
        "country": "Marshall Islands",
        "iata": "KIO",
        "icao": "Q51",
        "latitude": "5.644515",
        "longitude": "169.119507",
        "altitude": "5",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "IRK": {
        "name": "Kirksville Regional Airport",
        "city": "Kirksville",
        "country": "United States",
        "iata": "IRK",
        "icao": "KIRK",
        "latitude": "40.09349822998047",
        "longitude": "-92.5448989868164",
        "altitude": "966",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JMS": {
        "name": "Jamestown Regional Airport",
        "city": "Jamestown",
        "country": "United States",
        "iata": "JMS",
        "icao": "KJMS",
        "latitude": "46.92969894",
        "longitude": "-98.67819977",
        "altitude": "1500",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LAR": {
        "name": "Laramie Regional Airport",
        "city": "Laramie",
        "country": "United States",
        "iata": "LAR",
        "icao": "KLAR",
        "latitude": "41.31209945678711",
        "longitude": "-105.67500305175781",
        "altitude": "7284",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "LBE": {
        "name": "Arnold Palmer Regional Airport",
        "city": "Latrobe",
        "country": "United States",
        "iata": "LBE",
        "icao": "KLBE",
        "latitude": "40.27590179",
        "longitude": "-79.40480042",
        "altitude": "1199",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LBF": {
        "name": "North Platte Regional Airport Lee Bird Field",
        "city": "North Platte",
        "country": "United States",
        "iata": "LBF",
        "icao": "KLBF",
        "latitude": "41.12620163",
        "longitude": "-100.6839981",
        "altitude": "2777",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LEB": {
        "name": "Lebanon Municipal Airport",
        "city": "Lebanon",
        "country": "United States",
        "iata": "LEB",
        "icao": "KLEB",
        "latitude": "43.626098632799994",
        "longitude": "-72.30419921880001",
        "altitude": "603",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LMT": {
        "name": "Crater Lake-Klamath Regional Airport",
        "city": "Klamath Falls",
        "country": "United States",
        "iata": "LMT",
        "icao": "KLMT",
        "latitude": "42.156101",
        "longitude": "-121.733002",
        "altitude": "4095",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LNS": {
        "name": "Lancaster Airport",
        "city": "Lancaster",
        "country": "United States",
        "iata": "LNS",
        "icao": "KLNS",
        "latitude": "40.121700286865234",
        "longitude": "-76.29609680175781",
        "altitude": "403",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LWT": {
        "name": "Lewistown Municipal Airport",
        "city": "Lewistown",
        "country": "United States",
        "iata": "LWT",
        "icao": "KLWT",
        "latitude": "47.04930114746094",
        "longitude": "-109.46700286865234",
        "altitude": "4170",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "LYH": {
        "name": "Lynchburg Regional Preston Glenn Field",
        "city": "Lynchburg",
        "country": "United States",
        "iata": "LYH",
        "icao": "KLYH",
        "latitude": "37.326698303222656",
        "longitude": "-79.20040130615234",
        "altitude": "938",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MKG": {
        "name": "Muskegon County Airport",
        "city": "Muskegon",
        "country": "United States",
        "iata": "MKG",
        "icao": "KMKG",
        "latitude": "43.16949844",
        "longitude": "-86.23819733",
        "altitude": "629",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MLS": {
        "name": "Frank Wiley Field",
        "city": "Miles City",
        "country": "United States",
        "iata": "MLS",
        "icao": "KMLS",
        "latitude": "46.428001403808594",
        "longitude": "-105.88600158691406",
        "altitude": "2630",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MSL": {
        "name": "Northwest Alabama Regional Airport",
        "city": "Muscle Shoals",
        "country": "United States",
        "iata": "MSL",
        "icao": "KMSL",
        "latitude": "34.74530029",
        "longitude": "-87.61019897",
        "altitude": "551",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OTH": {
        "name": "Southwest Oregon Regional Airport",
        "city": "North Bend",
        "country": "United States",
        "iata": "OTH",
        "icao": "KOTH",
        "latitude": "43.41709899902344",
        "longitude": "-124.24600219726562",
        "altitude": "17",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "OWB": {
        "name": "Owensboro Daviess County Airport",
        "city": "Owensboro",
        "country": "United States",
        "iata": "OWB",
        "icao": "KOWB",
        "latitude": "37.74010086",
        "longitude": "-87.16680145",
        "altitude": "407",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PIB": {
        "name": "Hattiesburg Laurel Regional Airport",
        "city": "Hattiesburg/Laurel",
        "country": "United States",
        "iata": "PIB",
        "icao": "KPIB",
        "latitude": "31.467100143432617",
        "longitude": "-89.33709716796875",
        "altitude": "298",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PIH": {
        "name": "Pocatello Regional Airport",
        "city": "Pocatello",
        "country": "United States",
        "iata": "PIH",
        "icao": "KPIH",
        "latitude": "42.9098014831543",
        "longitude": "-112.59600067138672",
        "altitude": "4452",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PIR": {
        "name": "Pierre Regional Airport",
        "city": "Pierre",
        "country": "United States",
        "iata": "PIR",
        "icao": "KPIR",
        "latitude": "44.38270187",
        "longitude": "-100.2860031",
        "altitude": "1744",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PLN": {
        "name": "Pellston Regional Airport of Emmet County Airport",
        "city": "Pellston",
        "country": "United States",
        "iata": "PLN",
        "icao": "KPLN",
        "latitude": "45.57089996",
        "longitude": "-84.79669952",
        "altitude": "721",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PSM": {
        "name": "Portsmouth International at Pease Airport",
        "city": "Portsmouth",
        "country": "United States",
        "iata": "PSM",
        "icao": "KPSM",
        "latitude": "43.0778999329",
        "longitude": "-70.8233032227",
        "altitude": "100",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RDG": {
        "name": "Reading Regional Carl A Spaatz Field",
        "city": "Reading",
        "country": "United States",
        "iata": "RDG",
        "icao": "KRDG",
        "latitude": "40.378501892089844",
        "longitude": "-75.96520233154297",
        "altitude": "344",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RHI": {
        "name": "Rhinelander Oneida County Airport",
        "city": "Rhinelander",
        "country": "United States",
        "iata": "RHI",
        "icao": "KRHI",
        "latitude": "45.63119888305664",
        "longitude": "-89.46749877929688",
        "altitude": "1624",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RKS": {
        "name": "Southwest Wyoming Regional Airport",
        "city": "Rock Springs",
        "country": "United States",
        "iata": "RKS",
        "icao": "KRKS",
        "latitude": "41.5942",
        "longitude": "-109.065001",
        "altitude": "6764",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "RUT": {
        "name": "Rutland - Southern Vermont Regional Airport",
        "city": "Rutland",
        "country": "United States",
        "iata": "RUT",
        "icao": "KRUT",
        "latitude": "43.52939987",
        "longitude": "-72.94960022",
        "altitude": "787",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SBP": {
        "name": "San Luis County Regional Airport",
        "city": "San Luis Obispo",
        "country": "United States",
        "iata": "SBP",
        "icao": "KSBP",
        "latitude": "35.236801147499996",
        "longitude": "-120.641998291",
        "altitude": "212",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SHR": {
        "name": "Sheridan County Airport",
        "city": "Sheridan",
        "country": "United States",
        "iata": "SHR",
        "icao": "KSHR",
        "latitude": "44.76919937133789",
        "longitude": "-106.9800033569336",
        "altitude": "4021",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SLK": {
        "name": "Adirondack Regional Airport",
        "city": "Saranac Lake",
        "country": "United States",
        "iata": "SLK",
        "icao": "KSLK",
        "latitude": "44.38529968261719",
        "longitude": "-74.2061996459961",
        "altitude": "1663",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SLN": {
        "name": "Salina Municipal Airport",
        "city": "Salina",
        "country": "United States",
        "iata": "SLN",
        "icao": "KSLN",
        "latitude": "38.79100036621094",
        "longitude": "-97.6521987915039",
        "altitude": "1288",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SMX": {
        "name": "Santa Maria Pub/Capt G Allan Hancock Field",
        "city": "Santa Maria",
        "country": "United States",
        "iata": "SMX",
        "icao": "KSMX",
        "latitude": "34.89889908",
        "longitude": "-120.4570007",
        "altitude": "261",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "TUP": {
        "name": "Tupelo Regional Airport",
        "city": "Tupelo",
        "country": "United States",
        "iata": "TUP",
        "icao": "KTUP",
        "latitude": "34.26810073852539",
        "longitude": "-88.7698974609375",
        "altitude": "346",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "UIN": {
        "name": "Quincy Regional Baldwin Field",
        "city": "Quincy",
        "country": "United States",
        "iata": "UIN",
        "icao": "KUIN",
        "latitude": "39.94269943",
        "longitude": "-91.19460297",
        "altitude": "768",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "VCT": {
        "name": "Victoria Regional Airport",
        "city": "Victoria",
        "country": "United States",
        "iata": "VCT",
        "icao": "KVCT",
        "latitude": "28.85260009765625",
        "longitude": "-96.91850280761719",
        "altitude": "115",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "VLD": {
        "name": "Valdosta Regional Airport",
        "city": "Valdosta",
        "country": "United States",
        "iata": "VLD",
        "icao": "KVLD",
        "latitude": "30.782499313354492",
        "longitude": "-83.27670288085938",
        "altitude": "203",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "WRL": {
        "name": "Worland Municipal Airport",
        "city": "Worland",
        "country": "United States",
        "iata": "WRL",
        "icao": "KWRL",
        "latitude": "43.9656982421875",
        "longitude": "-107.95099639892578",
        "altitude": "4227",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "YKM": {
        "name": "Yakima Air Terminal McAllister Field",
        "city": "Yakima",
        "country": "United States",
        "iata": "YKM",
        "icao": "KYKM",
        "latitude": "46.56819916",
        "longitude": "-120.5439987",
        "altitude": "1099",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ECN": {
        "name": "Ercan International Airport",
        "city": "Nicosia",
        "country": "Cyprus",
        "iata": "ECN",
        "icao": "LCEN",
        "latitude": "35.154701232910156",
        "longitude": "33.49610137939453",
        "altitude": "404",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Nicosia"
    },
    "RJL": {
        "name": "Logroño-Agoncillo Airport",
        "city": "Logroño-Agoncillo",
        "country": "Spain",
        "iata": "RJL",
        "icao": "LELO",
        "latitude": "42.4609534888",
        "longitude": "-2.32223510742",
        "altitude": "1161",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "IDY": {
        "name": "Île d'Yeu Airport",
        "city": "Île d'Yeu",
        "country": "France",
        "iata": "IDY",
        "icao": "LFEY",
        "latitude": "46.71860122680664",
        "longitude": "-2.3911099433898926",
        "altitude": "79",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "ANE": {
        "name": "Angers-Loire Airport",
        "city": "Angers/Marcé",
        "country": "France",
        "iata": "ANE",
        "icao": "LFJR",
        "latitude": "47.560299",
        "longitude": "-0.312222",
        "altitude": "194",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "LTT": {
        "name": "La Môle Airport",
        "city": "La Môle",
        "country": "France",
        "iata": "LTT",
        "icao": "LFTZ",
        "latitude": "43.205399",
        "longitude": "6.482",
        "altitude": "59",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "JSY": {
        "name": "Syros Airport",
        "city": "Syros Island",
        "country": "Greece",
        "iata": "JSY",
        "icao": "LGSO",
        "latitude": "37.4227981567",
        "longitude": "24.950899124099998",
        "altitude": "236",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "PEV": {
        "name": "Pécs-Pogány Airport",
        "city": "Pécs-Pogány",
        "country": "Hungary",
        "iata": "PEV",
        "icao": "LHPP",
        "latitude": "45.990898",
        "longitude": "18.240996",
        "altitude": "1000",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Europe/Budapest"
    },
    "SOB": {
        "name": "Sármellék International Airport",
        "city": "Sármellék",
        "country": "Hungary",
        "iata": "SOB",
        "icao": "LHSM",
        "latitude": "46.686391",
        "longitude": "17.159084",
        "altitude": "408",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Europe/Budapest"
    },
    "AOT": {
        "name": "Aosta Airport",
        "city": "Aosta",
        "country": "Italy",
        "iata": "AOT",
        "icao": "LIMW",
        "latitude": "45.738499",
        "longitude": "7.36872",
        "altitude": "1791",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "QSR": {
        "name": "Salerno Costa d'Amalfi Airport",
        "city": "Salerno",
        "country": "Italy",
        "iata": "QSR",
        "icao": "LIRI",
        "latitude": "40.620399",
        "longitude": "14.9113",
        "altitude": "119",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "CVU": {
        "name": "Corvo Airport",
        "city": "Corvo",
        "country": "Portugal",
        "iata": "CVU",
        "icao": "LPCR",
        "latitude": "39.671501",
        "longitude": "-31.1136",
        "altitude": "0",
        "timezone": "-1",
        "dst": "E",
        "tz_name": "Atlantic/Azores"
    },
    "BNX": {
        "name": "Banja Luka International Airport",
        "city": "Banja Luka",
        "country": "Bosnia and Herzegovina",
        "iata": "BNX",
        "icao": "LQBK",
        "latitude": "44.94139862060547",
        "longitude": "17.297500610351562",
        "altitude": "400",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Sarajevo"
    },
    "USQ": {
        "name": "Uşak Airport",
        "city": "Usak",
        "country": "Turkey",
        "iata": "USQ",
        "icao": "LTBO",
        "latitude": "38.68149948120117",
        "longitude": "29.47170066833496",
        "altitude": "2897",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "KSY": {
        "name": "Kars Airport",
        "city": "Kars",
        "country": "Turkey",
        "iata": "KSY",
        "icao": "LTCF",
        "latitude": "40.562198638916016",
        "longitude": "43.1150016784668",
        "altitude": "5889",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "SFQ": {
        "name": "Şanlıurfa Airport",
        "city": "Sanliurfa",
        "country": "Turkey",
        "iata": "SFQ",
        "icao": "LTCH",
        "latitude": "37.09429931640625",
        "longitude": "38.84709930419922",
        "altitude": "1483",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "KCM": {
        "name": "Kahramanmaraş Airport",
        "city": "Kahramanmaras",
        "country": "Turkey",
        "iata": "KCM",
        "icao": "LTCN",
        "latitude": "37.5388259888",
        "longitude": "36.9535217285",
        "altitude": "1723",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "AJI": {
        "name": "Ağrı Airport",
        "city": "Agri",
        "country": "Turkey",
        "iata": "AJI",
        "icao": "LTCO",
        "latitude": "39.654541015625",
        "longitude": "43.025978088378906",
        "altitude": "5462",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ADF": {
        "name": "Adıyaman Airport",
        "city": "Adiyaman",
        "country": "Turkey",
        "iata": "ADF",
        "icao": "LTCP",
        "latitude": "37.7313995361",
        "longitude": "38.4688987732",
        "altitude": "2216",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ISE": {
        "name": "Süleyman Demirel International Airport",
        "city": "Isparta",
        "country": "Turkey",
        "iata": "ISE",
        "icao": "LTFC",
        "latitude": "37.8554000854",
        "longitude": "30.368400573699997",
        "altitude": "2835",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "EDO": {
        "name": "Balıkesir Körfez Airport",
        "city": "Balikesir Korfez",
        "country": "Turkey",
        "iata": "EDO",
        "icao": "LTFD",
        "latitude": "39.554599762",
        "longitude": "27.0137996674",
        "altitude": "50",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "SZF": {
        "name": "Samsun Çarşamba Airport",
        "city": "Samsun",
        "country": "Turkey",
        "iata": "SZF",
        "icao": "LTFH",
        "latitude": "41.254501",
        "longitude": "36.567101",
        "altitude": "18",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "ILZ": {
        "name": "Žilina Airport",
        "city": "Žilina",
        "country": "Slovakia",
        "iata": "ILZ",
        "icao": "LZZI",
        "latitude": "49.231498718299996",
        "longitude": "18.6135005951",
        "altitude": "1020",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Bratislava"
    },
    "GDT": {
        "name": "JAGS McCartney International Airport",
        "city": "Cockburn Town",
        "country": "Turks and Caicos Islands",
        "iata": "GDT",
        "icao": "MBGT",
        "latitude": "21.444499969482422",
        "longitude": "-71.14230346679688",
        "altitude": "13",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grand_Turk"
    },
    "MDS": {
        "name": "Middle Caicos Airport",
        "city": "Middle Caicos",
        "country": "Turks and Caicos Islands",
        "iata": "MDS",
        "icao": "MBMC",
        "latitude": "21.82602",
        "longitude": "-71.8025",
        "altitude": "9",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grand_Turk"
    },
    "SLX": {
        "name": "Salt Cay Airport",
        "city": "Salt Cay",
        "country": "Turks and Caicos Islands",
        "iata": "SLX",
        "icao": "MBSY",
        "latitude": "21.333000183099998",
        "longitude": "-71.1999969482",
        "altitude": "3",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Grand_Turk"
    },
    "AZS": {
        "name": "Samaná El Catey International Airport",
        "city": "Samana",
        "country": "Dominican Republic",
        "iata": "AZS",
        "icao": "MDCY",
        "latitude": "19.2670001984",
        "longitude": "-69.7419967651",
        "altitude": "30",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "JBQ": {
        "name": "La Isabela International Airport",
        "city": "La Isabela",
        "country": "Dominican Republic",
        "iata": "JBQ",
        "icao": "MDJB",
        "latitude": "18.572500228881836",
        "longitude": "-69.98560333251953",
        "altitude": "98",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "PBR": {
        "name": "Puerto Barrios Airport",
        "city": "Puerto Barrios",
        "country": "Guatemala",
        "iata": "PBR",
        "icao": "MGPB",
        "latitude": "15.7309",
        "longitude": "-88.583801",
        "altitude": "33",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "AAZ": {
        "name": "Quezaltenango Airport",
        "city": "Quezaltenango",
        "country": "Guatemala",
        "iata": "AAZ",
        "icao": "MGQZ",
        "latitude": "14.8656",
        "longitude": "-91.501999",
        "altitude": "7779",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Guatemala"
    },
    "UTK": {
        "name": "Utirik Airport",
        "city": "Utirik Island",
        "country": "Marshall Islands",
        "iata": "UTK",
        "icao": "03N",
        "latitude": "11.222",
        "longitude": "169.852005",
        "altitude": "4",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "AHS": {
        "name": "Ahuas Airport",
        "city": "Ahuas",
        "country": "Honduras",
        "iata": "AHS",
        "icao": "MHAH",
        "latitude": "15.4722",
        "longitude": "-84.352203",
        "altitude": "249",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "PEU": {
        "name": "Puerto Lempira Airport",
        "city": "Puerto Lempira",
        "country": "Honduras",
        "iata": "PEU",
        "icao": "MHPL",
        "latitude": "15.2622",
        "longitude": "-83.781197",
        "altitude": "33",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "MIJ": {
        "name": "Mili Island Airport",
        "city": "Mili Island",
        "country": "Marshall Islands",
        "iata": "MIJ",
        "icao": "MLIP",
        "latitude": "6.08333",
        "longitude": "171.733002",
        "altitude": "4",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "CYW": {
        "name": "Captain Rogelio Castillo National Airport",
        "city": "Celaya",
        "country": "Mexico",
        "iata": "CYW",
        "icao": "MMCY",
        "latitude": "20.546",
        "longitude": "-100.887001",
        "altitude": "5709",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CUA": {
        "name": "Ciudad Constitución Airport",
        "city": "Ciudad Constitución",
        "country": "Mexico",
        "iata": "CUA",
        "icao": "MMDA",
        "latitude": "25.053800582886",
        "longitude": "-111.61499786377",
        "altitude": "213",
        "timezone": "-7",
        "dst": "S",
        "tz_name": "America/Mazatlan"
    },
    "GUB": {
        "name": "Guerrero Negro Airport",
        "city": "Guerrero Negro",
        "country": "Mexico",
        "iata": "GUB",
        "icao": "MMGR",
        "latitude": "28.0261",
        "longitude": "-114.024002",
        "altitude": "59",
        "timezone": "-8",
        "dst": "S",
        "tz_name": "America/Tijuana"
    },
    "JAL": {
        "name": "El Lencero Airport",
        "city": "Jalapa",
        "country": "Mexico",
        "iata": "JAL",
        "icao": "MMJA",
        "latitude": "19.4750995636",
        "longitude": "-96.7975006104",
        "altitude": "3127",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "CTD": {
        "name": "Alonso Valderrama Airport",
        "city": "Chitré",
        "country": "Panama",
        "iata": "CTD",
        "icao": "MPCE",
        "latitude": "7.987840175628662",
        "longitude": "-80.40969848632812",
        "altitude": "33",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "ONX": {
        "name": "Enrique Adolfo Jimenez Airport",
        "city": "Colón",
        "country": "Panama",
        "iata": "ONX",
        "icao": "MPEJ",
        "latitude": "9.356639862060547",
        "longitude": "-79.86740112304688",
        "altitude": "25",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "JQE": {
        "name": "Jaqué Airport",
        "city": "Jaqué",
        "country": "Panama",
        "iata": "JQE",
        "icao": "MPJE",
        "latitude": "7.51777982711792",
        "longitude": "-78.1572036743164",
        "altitude": "29",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "PLP": {
        "name": "Captain Ramon Xatruch Airport",
        "city": "La Palma",
        "country": "Panama",
        "iata": "PLP",
        "icao": "MPLP",
        "latitude": "8.406669616699219",
        "longitude": "-78.1417007446289",
        "altitude": "30",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "TTQ": {
        "name": "Aerotortuguero Airport",
        "city": "Roxana",
        "country": "Costa Rica",
        "iata": "TTQ",
        "icao": "MRAO",
        "latitude": "10.42",
        "longitude": "-83.6095",
        "altitude": "92",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "BCL": {
        "name": "Barra del Colorado Airport",
        "city": "Pococi",
        "country": "Costa Rica",
        "iata": "BCL",
        "icao": "MRBC",
        "latitude": "10.768699645996094",
        "longitude": "-83.58560180664062",
        "altitude": "3",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "PBP": {
        "name": "Islita Airport",
        "city": "Nandayure",
        "country": "Costa Rica",
        "iata": "PBP",
        "icao": "MRIA",
        "latitude": "9.856109619140625",
        "longitude": "-85.37079620361328",
        "altitude": "7",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "PJM": {
        "name": "Puerto Jimenez Airport",
        "city": "Puerto Jimenez",
        "country": "Costa Rica",
        "iata": "PJM",
        "icao": "MRPJ",
        "latitude": "8.533329963684082",
        "longitude": "-83.30000305175781",
        "altitude": "7",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "SYQ": {
        "name": "Tobias Bolanos International Airport",
        "city": "San Jose",
        "country": "Costa Rica",
        "iata": "SYQ",
        "icao": "MRPV",
        "latitude": "9.957050323486328",
        "longitude": "-84.13980102539062",
        "altitude": "3287",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "JEE": {
        "name": "Jérémie Airport",
        "city": "Jeremie",
        "country": "Haiti",
        "iata": "JEE",
        "icao": "MTJE",
        "latitude": "18.66309928894043",
        "longitude": "-74.17030334472656",
        "altitude": "147",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Port-au-Prince"
    },
    "PAX": {
        "name": "Port-de-Paix Airport",
        "city": "Port-de-Paix",
        "country": "Haiti",
        "iata": "PAX",
        "icao": "MTPX",
        "latitude": "19.9335994720459",
        "longitude": "-72.84860229492188",
        "altitude": "9",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Port-au-Prince"
    },
    "TND": {
        "name": "Alberto Delgado Airport",
        "city": "Trinidad",
        "country": "Cuba",
        "iata": "TND",
        "icao": "MUTD",
        "latitude": "21.788299560546875",
        "longitude": "-79.99720001220703",
        "altitude": "125",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "COX": {
        "name": "Congo Town Airport",
        "city": "Andros",
        "country": "Bahamas",
        "iata": "COX",
        "icao": "MYAK",
        "latitude": "24.158701",
        "longitude": "-77.589798",
        "altitude": "15",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "ATC": {
        "name": "Arthur's Town Airport",
        "city": "Arthur's Town",
        "country": "Bahamas",
        "iata": "ATC",
        "icao": "MYCA",
        "latitude": "24.6294",
        "longitude": "-75.673797",
        "altitude": "18",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "TBI": {
        "name": "New Bight Airport",
        "city": "Cat Island",
        "country": "Bahamas",
        "iata": "TBI",
        "icao": "MYCB",
        "latitude": "24.3153",
        "longitude": "-75.452301",
        "altitude": "5",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "CRI": {
        "name": "Colonel Hill Airport",
        "city": "Colonel Hill",
        "country": "Bahamas",
        "iata": "CRI",
        "icao": "MYCI",
        "latitude": "22.745599746699998",
        "longitude": "-74.1824035645",
        "altitude": "5",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "PID": {
        "name": "Nassau Paradise Island Airport",
        "city": "Nassau",
        "country": "Bahamas",
        "iata": "PID",
        "icao": "MYPI",
        "latitude": "25.08300018310547",
        "longitude": "-77.30000305175781",
        "altitude": "0",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Nassau"
    },
    "AIU": {
        "name": "Enua Airport",
        "city": "Atiu Island",
        "country": "Cook Islands",
        "iata": "AIU",
        "icao": "NCAT",
        "latitude": "-19.96780014038086",
        "longitude": "-158.11900329589844",
        "altitude": "36",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "MGS": {
        "name": "Mangaia Island Airport",
        "city": "Mangaia Island",
        "country": "Cook Islands",
        "iata": "MGS",
        "icao": "NCMG",
        "latitude": "-21.895986557006836",
        "longitude": "-157.9066619873047",
        "altitude": "45",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "MHX": {
        "name": "Manihiki Island Airport",
        "city": "Manihiki Island",
        "country": "Cook Islands",
        "iata": "MHX",
        "icao": "NCMH",
        "latitude": "-10.376700401306152",
        "longitude": "-161.0019989013672",
        "altitude": "0",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "MUK": {
        "name": "Mauke Airport",
        "city": "Mauke Island",
        "country": "Cook Islands",
        "iata": "MUK",
        "icao": "NCMK",
        "latitude": "-20.13610076904297",
        "longitude": "-157.34500122070312",
        "altitude": "26",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "MOI": {
        "name": "Mitiaro Island Airport",
        "city": "Mitiaro Island",
        "country": "Cook Islands",
        "iata": "MOI",
        "icao": "NCMR",
        "latitude": "-19.842500686645508",
        "longitude": "-157.7030029296875",
        "altitude": "25",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "PYE": {
        "name": "Tongareva Airport",
        "city": "Penrhyn Island",
        "country": "Cook Islands",
        "iata": "PYE",
        "icao": "NCPY",
        "latitude": "-9.01436996459961",
        "longitude": "-158.03240966796875",
        "altitude": "8",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Rarotonga"
    },
    "ICI": {
        "name": "Cicia Airport",
        "city": "Cicia",
        "country": "Fiji",
        "iata": "ICI",
        "icao": "NFCI",
        "latitude": "-17.7432994843",
        "longitude": "-179.341995239",
        "altitude": "13",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "PTF": {
        "name": "Malolo Lailai Island Airport",
        "city": "Malolo Lailai Island",
        "country": "Fiji",
        "iata": "PTF",
        "icao": "NFFO",
        "latitude": "-17.7779006958",
        "longitude": "177.197006226",
        "altitude": "10",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "KDV": {
        "name": "Vunisea Airport",
        "city": "Vunisea",
        "country": "Fiji",
        "iata": "KDV",
        "icao": "NFKD",
        "latitude": "-19.058099746699998",
        "longitude": "178.156997681",
        "altitude": "6",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "MNF": {
        "name": "Mana Island Airport",
        "city": "Mana Island",
        "country": "Fiji",
        "iata": "MNF",
        "icao": "NFMA",
        "latitude": "-17.6730995178",
        "longitude": "177.098007202",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "MFJ": {
        "name": "Moala Airport",
        "city": "Moala",
        "country": "Fiji",
        "iata": "MFJ",
        "icao": "NFMO",
        "latitude": "-18.566699981699998",
        "longitude": "179.951004028",
        "altitude": "13",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "NGI": {
        "name": "Ngau Airport",
        "city": "Ngau",
        "country": "Fiji",
        "iata": "NGI",
        "icao": "NFNG",
        "latitude": "-18.115600585899998",
        "longitude": "179.339996338",
        "altitude": "50",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "LKB": {
        "name": "Lakeba Island Airport",
        "city": "Lakeba Island",
        "country": "Fiji",
        "iata": "LKB",
        "icao": "NFNK",
        "latitude": "-18.1991996765",
        "longitude": "-178.817001343",
        "altitude": "280",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "LBS": {
        "name": "Labasa Airport",
        "city": "Lambasa",
        "country": "Fiji",
        "iata": "LBS",
        "icao": "NFNL",
        "latitude": "-16.466699600219727",
        "longitude": "179.33999633789062",
        "altitude": "44",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "TVU": {
        "name": "Matei Airport",
        "city": "Matei",
        "country": "Fiji",
        "iata": "TVU",
        "icao": "NFNM",
        "latitude": "-16.6905994415",
        "longitude": "-179.876998901",
        "altitude": "60",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "KXF": {
        "name": "Koro Island Airport",
        "city": "Koro Island",
        "country": "Fiji",
        "iata": "KXF",
        "icao": "NFNO",
        "latitude": "-17.3458003998",
        "longitude": "179.42199707",
        "altitude": "358",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "RTA": {
        "name": "Rotuma Airport",
        "city": "Rotuma",
        "country": "Fiji",
        "iata": "RTA",
        "icao": "NFNR",
        "latitude": "-12.482500076293945",
        "longitude": "177.0709991455078",
        "altitude": "22",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "SVU": {
        "name": "Savusavu Airport",
        "city": "Savusavu",
        "country": "Fiji",
        "iata": "SVU",
        "icao": "NFNS",
        "latitude": "-16.8027992249",
        "longitude": "179.341003418",
        "altitude": "17",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "EUA": {
        "name": "Kaufana Airport",
        "city": "Eua Island",
        "country": "Tonga",
        "iata": "EUA",
        "icao": "NFTE",
        "latitude": "-21.378299713100002",
        "longitude": "-174.957992554",
        "altitude": "325",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Tongatapu"
    },
    "HPA": {
        "name": "Lifuka Island Airport",
        "city": "Lifuka",
        "country": "Tonga",
        "iata": "HPA",
        "icao": "NFTL",
        "latitude": "-19.777000427246094",
        "longitude": "-174.34100341796875",
        "altitude": "31",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Tongatapu"
    },
    "NFO": {
        "name": "Mata'aho Airport",
        "city": "Angaha, Niuafo'ou Island",
        "country": "Tonga",
        "iata": "NFO",
        "icao": "NFTO",
        "latitude": "-15.5707998276",
        "longitude": "-175.632995605",
        "altitude": "160",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Tongatapu"
    },
    "NTT": {
        "name": "Kuini Lavenia Airport",
        "city": "Niuatoputapu",
        "country": "Tonga",
        "iata": "NTT",
        "icao": "NFTP",
        "latitude": "-15.977297",
        "longitude": "-173.791089",
        "altitude": "30",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Tongatapu"
    },
    "VBV": {
        "name": "Vanua Balavu Airport",
        "city": "Vanua Balavu",
        "country": "Fiji",
        "iata": "VBV",
        "icao": "NFVB",
        "latitude": "-17.268999099731445",
        "longitude": "-178.9759979248047",
        "altitude": "76",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Fiji"
    },
    "IUE": {
        "name": "Niue International Airport",
        "city": "Alofi",
        "country": "Niue",
        "iata": "IUE",
        "icao": "NIUE",
        "latitude": "-19.079030990600586",
        "longitude": "-169.92559814453125",
        "altitude": "209",
        "timezone": "-11",
        "dst": "U",
        "tz_name": "Pacific/Niue"
    },
    "FUT": {
        "name": "Pointe Vele Airport",
        "city": "Futuna Island",
        "country": "Wallis and Futuna",
        "iata": "FUT",
        "icao": "NLWF",
        "latitude": "-14.3114004135",
        "longitude": "-178.065994263",
        "altitude": "20",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Wallis"
    },
    "MXS": {
        "name": "Maota Airport",
        "city": "Savaii Island",
        "country": "Samoa",
        "iata": "MXS",
        "icao": "NSMA",
        "latitude": "-13.742300033569336",
        "longitude": "-172.25799560546875",
        "altitude": "0",
        "timezone": "13",
        "dst": "U",
        "tz_name": "Pacific/Apia"
    },
    "APK": {
        "name": "Apataki Airport",
        "city": "Apataki",
        "country": "French Polynesia",
        "iata": "APK",
        "icao": "NTGD",
        "latitude": "-15.5736",
        "longitude": "-146.414993",
        "altitude": "8",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "AHE": {
        "name": "Ahe Airport",
        "city": "Ahe",
        "country": "French Polynesia",
        "iata": "AHE",
        "icao": "NTHE",
        "latitude": "-14.428099632263184",
        "longitude": "-146.2570037841797",
        "altitude": "11",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "AUQ": {
        "name": "Hiva Oa-Atuona Airport",
        "city": "Hiva-oa",
        "country": "French Polynesia",
        "iata": "AUQ",
        "icao": "NTMN",
        "latitude": "-9.76879024506",
        "longitude": "-139.011001587",
        "altitude": "1481",
        "timezone": "-9.5",
        "dst": "U",
        "tz_name": "Pacific/Marquesas"
    },
    "UAP": {
        "name": "Ua Pou Airport",
        "city": "Ua Pou",
        "country": "French Polynesia",
        "iata": "UAP",
        "icao": "NTMP",
        "latitude": "-9.35167",
        "longitude": "-140.078003",
        "altitude": "16",
        "timezone": "-9.5",
        "dst": "U",
        "tz_name": "Pacific/Marquesas"
    },
    "UAH": {
        "name": "Ua Huka Airport",
        "city": "Ua Huka",
        "country": "French Polynesia",
        "iata": "UAH",
        "icao": "NTMU",
        "latitude": "-8.93611",
        "longitude": "-139.552002",
        "altitude": "160",
        "timezone": "-9.5",
        "dst": "U",
        "tz_name": "Pacific/Marquesas"
    },
    "MTV": {
        "name": "Mota Lava Airport",
        "city": "Ablow",
        "country": "Vanuatu",
        "iata": "MTV",
        "icao": "NVSA",
        "latitude": "-13.6660003662",
        "longitude": "167.712005615",
        "altitude": "63",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "SLH": {
        "name": "Sola Airport",
        "city": "Sola",
        "country": "Vanuatu",
        "iata": "SLH",
        "icao": "NVSC",
        "latitude": "-13.8516998291",
        "longitude": "167.537002563",
        "altitude": "7",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "TOH": {
        "name": "Torres Airstrip",
        "city": "Loh/Linua",
        "country": "Vanuatu",
        "iata": "TOH",
        "icao": "NVSD",
        "latitude": "-13.3280000687",
        "longitude": "166.638000488",
        "altitude": "75",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "EAE": {
        "name": "Siwo Airport",
        "city": "Sangafa",
        "country": "Vanuatu",
        "iata": "EAE",
        "icao": "NVSE",
        "latitude": "-17.0902996063",
        "longitude": "168.343002319",
        "altitude": "7",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "CCV": {
        "name": "Craig Cove Airport",
        "city": "Craig Cove",
        "country": "Vanuatu",
        "iata": "CCV",
        "icao": "NVSF",
        "latitude": "-16.264999",
        "longitude": "167.923996",
        "altitude": "69",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "LOD": {
        "name": "Longana Airport",
        "city": "Longana",
        "country": "Vanuatu",
        "iata": "LOD",
        "icao": "NVSG",
        "latitude": "-15.3066997528",
        "longitude": "167.966995239",
        "altitude": "167",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "SSR": {
        "name": "Sara Airport",
        "city": "Pentecost Island",
        "country": "Vanuatu",
        "iata": "SSR",
        "icao": "NVSH",
        "latitude": "-15.4708003998",
        "longitude": "168.151992798",
        "altitude": "493",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "PBJ": {
        "name": "Tavie Airport",
        "city": "Paama Island",
        "country": "Vanuatu",
        "iata": "PBJ",
        "icao": "NVSI",
        "latitude": "-16.438999176",
        "longitude": "168.257003784",
        "altitude": "160",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "LPM": {
        "name": "Lamap Airport",
        "city": "Lamap",
        "country": "Vanuatu",
        "iata": "LPM",
        "icao": "NVSL",
        "latitude": "-16.4611228",
        "longitude": "167.829253",
        "altitude": "7",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "LNB": {
        "name": "Lamen Bay Airport",
        "city": "Lamen Bay",
        "country": "Vanuatu",
        "iata": "LNB",
        "icao": "NVSM",
        "latitude": "-16.584199905400002",
        "longitude": "168.158996582",
        "altitude": "7",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "MWF": {
        "name": "Maewo-Naone Airport",
        "city": "Maewo Island",
        "country": "Vanuatu",
        "iata": "MWF",
        "icao": "NVSN",
        "latitude": "-15",
        "longitude": "168.082992554",
        "altitude": "509",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "LNE": {
        "name": "Lonorore Airport",
        "city": "Lonorore",
        "country": "Vanuatu",
        "iata": "LNE",
        "icao": "NVSO",
        "latitude": "-15.865599632299999",
        "longitude": "168.17199707",
        "altitude": "43",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "NUS": {
        "name": "Norsup Airport",
        "city": "Norsup",
        "country": "Vanuatu",
        "iata": "NUS",
        "icao": "NVSP",
        "latitude": "-16.0797",
        "longitude": "167.401001",
        "altitude": "23",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "ZGU": {
        "name": "Gaua Island Airport",
        "city": "Gaua Island",
        "country": "Vanuatu",
        "iata": "ZGU",
        "icao": "NVSQ",
        "latitude": "-14.218099594099998",
        "longitude": "167.587005615",
        "altitude": "100",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "RCL": {
        "name": "Redcliffe Airport",
        "city": "Redcliffe",
        "country": "Vanuatu",
        "iata": "RCL",
        "icao": "NVSR",
        "latitude": "-15.472000122099999",
        "longitude": "167.835006714",
        "altitude": "36",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "SON": {
        "name": "Santo Pekoa International Airport",
        "city": "Santo",
        "country": "Vanuatu",
        "iata": "SON",
        "icao": "NVSS",
        "latitude": "-15.505000114399998",
        "longitude": "167.220001221",
        "altitude": "184",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "TGH": {
        "name": "Tongoa Airport",
        "city": "Tongoa Island",
        "country": "Vanuatu",
        "iata": "TGH",
        "icao": "NVST",
        "latitude": "-16.8910999298",
        "longitude": "168.550994873",
        "altitude": "443",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "ULB": {
        "name": "Uléi Airport",
        "city": "Ambryn Island",
        "country": "Vanuatu",
        "iata": "ULB",
        "icao": "NVSU",
        "latitude": "-16.3297",
        "longitude": "168.3011",
        "altitude": "170",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "VLS": {
        "name": "Valesdir Airport",
        "city": "Valesdir",
        "country": "Vanuatu",
        "iata": "VLS",
        "icao": "NVSV",
        "latitude": "-16.796100616500002",
        "longitude": "168.177001953",
        "altitude": "10",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "SWJ": {
        "name": "Southwest Bay Airport",
        "city": "Malekula Island",
        "country": "Vanuatu",
        "iata": "SWJ",
        "icao": "NVSX",
        "latitude": "-16.4864",
        "longitude": "167.4472",
        "altitude": "68",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "OLJ": {
        "name": "North West Santo Airport",
        "city": "Olpoi",
        "country": "Vanuatu",
        "iata": "OLJ",
        "icao": "NVSZ",
        "latitude": "-14.881699562099998",
        "longitude": "166.557998657",
        "altitude": "50",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "AUY": {
        "name": "Aneityum Airport",
        "city": "Anelghowhat",
        "country": "Vanuatu",
        "iata": "AUY",
        "icao": "NVVA",
        "latitude": "-20.2492008209",
        "longitude": "169.770996094",
        "altitude": "7",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "AWD": {
        "name": "Aniwa Airport",
        "city": "Aniwa",
        "country": "Vanuatu",
        "iata": "AWD",
        "icao": "NVVB",
        "latitude": "-19.2346",
        "longitude": "169.6009",
        "altitude": "69",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "DLY": {
        "name": "Dillon's Bay Airport",
        "city": "Dillon's Bay",
        "country": "Vanuatu",
        "iata": "DLY",
        "icao": "NVVD",
        "latitude": "-18.7693996429",
        "longitude": "169.00100708",
        "altitude": "630",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "FTA": {
        "name": "Futuna Airport",
        "city": "Futuna Island",
        "country": "Vanuatu",
        "iata": "FTA",
        "icao": "NVVF",
        "latitude": "-19.516399383499998",
        "longitude": "170.231994629",
        "altitude": "95",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "IPA": {
        "name": "Ipota Airport",
        "city": "Ipota",
        "country": "Vanuatu",
        "iata": "IPA",
        "icao": "NVVI",
        "latitude": "-18.856389",
        "longitude": "169.283333",
        "altitude": "23",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "TGJ": {
        "name": "Tiga Airport",
        "city": "Tiga",
        "country": "New Caledonia",
        "iata": "TGJ",
        "icao": "NWWA",
        "latitude": "-21.096099853515625",
        "longitude": "167.8040008544922",
        "altitude": "128",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "BMY": {
        "name": "Île Art - Waala Airport",
        "city": "Waala",
        "country": "New Caledonia",
        "iata": "BMY",
        "icao": "NWWC",
        "latitude": "-19.720600128173828",
        "longitude": "163.66099548339844",
        "altitude": "306",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "ILP": {
        "name": "Île des Pins Airport",
        "city": "Île des Pins",
        "country": "New Caledonia",
        "iata": "ILP",
        "icao": "NWWE",
        "latitude": "-22.588899612426758",
        "longitude": "167.45599365234375",
        "altitude": "315",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Noumea"
    },
    "FBD": {
        "name": "Fayzabad Airport",
        "city": "Faizabad",
        "country": "Afghanistan",
        "iata": "FBD",
        "icao": "OAFZ",
        "latitude": "37.121101",
        "longitude": "70.518097",
        "altitude": "3872",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "AJF": {
        "name": "Al-Jawf Domestic Airport",
        "city": "Al-Jawf",
        "country": "Saudi Arabia",
        "iata": "AJF",
        "icao": "OESK",
        "latitude": "29.78510093688965",
        "longitude": "40.099998474121094",
        "altitude": "2261",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "WAE": {
        "name": "Wadi Al Dawasir Airport",
        "city": "Wadi-al-dawasir",
        "country": "Saudi Arabia",
        "iata": "WAE",
        "icao": "OEWD",
        "latitude": "20.504299163800003",
        "longitude": "45.199600219699995",
        "altitude": "2062",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Riyadh"
    },
    "KHD": {
        "name": "Khoram Abad Airport",
        "city": "Khorram Abad",
        "country": "Iran",
        "iata": "KHD",
        "icao": "OICK",
        "latitude": "33.43539810180664",
        "longitude": "48.282901763916016",
        "altitude": "3782",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "BXR": {
        "name": "Bam Airport",
        "city": "Bam",
        "country": "Iran",
        "iata": "BXR",
        "icao": "OIKM",
        "latitude": "29.084199905395508",
        "longitude": "58.45000076293945",
        "altitude": "3231",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "RJN": {
        "name": "Rafsanjan Airport",
        "city": "Rafsanjan",
        "country": "Iran",
        "iata": "RJN",
        "icao": "OIKR",
        "latitude": "30.297700881958008",
        "longitude": "56.05110168457031",
        "altitude": "5298",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "BJB": {
        "name": "Bojnord Airport",
        "city": "Bojnourd",
        "country": "Iran",
        "iata": "BJB",
        "icao": "OIMN",
        "latitude": "37.49300003051758",
        "longitude": "57.30820083618164",
        "altitude": "3499",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AFZ": {
        "name": "Sabzevar National Airport",
        "city": "Sabzevar",
        "country": "Iran",
        "iata": "AFZ",
        "icao": "OIMS",
        "latitude": "36.16809844970703",
        "longitude": "57.59519958496094",
        "altitude": "3010",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "NSH": {
        "name": "Noshahr Airport",
        "city": "Noshahr",
        "country": "Iran",
        "iata": "NSH",
        "icao": "OINN",
        "latitude": "36.663299560546875",
        "longitude": "51.464698791503906",
        "altitude": "-61",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "SRY": {
        "name": "Dasht-e Naz Airport",
        "city": "Dasht-e-naz",
        "country": "Iran",
        "iata": "SRY",
        "icao": "OINZ",
        "latitude": "36.635799408",
        "longitude": "53.193599700899995",
        "altitude": "35",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "LRR": {
        "name": "Lar Airport",
        "city": "Lar",
        "country": "Iran",
        "iata": "LRR",
        "icao": "OISL",
        "latitude": "27.6746997833",
        "longitude": "54.3833007812",
        "altitude": "2641",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "ADU": {
        "name": "Ardabil Airport",
        "city": "Ardabil",
        "country": "Iran",
        "iata": "ADU",
        "icao": "OITL",
        "latitude": "38.3256988525",
        "longitude": "48.4244003296",
        "altitude": "4315",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "OMH": {
        "name": "Urmia Airport",
        "city": "Uromiyeh",
        "country": "Iran",
        "iata": "OMH",
        "icao": "OITR",
        "latitude": "37.6680984497",
        "longitude": "45.0686988831",
        "altitude": "4343",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "AAN": {
        "name": "Al Ain International Airport",
        "city": "Al Ain",
        "country": "United Arab Emirates",
        "iata": "AAN",
        "icao": "OMAL",
        "latitude": "24.261699676513672",
        "longitude": "55.60919952392578",
        "altitude": "869",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "BNP": {
        "name": "Bannu Airport",
        "city": "Bannu",
        "country": "Pakistan",
        "iata": "BNP",
        "icao": "OPBN",
        "latitude": "32.9729",
        "longitude": "70.527901",
        "altitude": "1325",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "BHV": {
        "name": "Bahawalpur Airport",
        "city": "Bahawalpur",
        "country": "Pakistan",
        "iata": "BHV",
        "icao": "OPBW",
        "latitude": "29.348100662231445",
        "longitude": "71.71800231933594",
        "altitude": "392",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "CJL": {
        "name": "Chitral Airport",
        "city": "Chitral",
        "country": "Pakistan",
        "iata": "CJL",
        "icao": "OPCH",
        "latitude": "35.886600494384766",
        "longitude": "71.80059814453125",
        "altitude": "4920",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "DBA": {
        "name": "Dalbandin Airport",
        "city": "Dalbandin",
        "country": "Pakistan",
        "iata": "DBA",
        "icao": "OPDB",
        "latitude": "28.878299713100002",
        "longitude": "64.3998031616",
        "altitude": "2800",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "DEA": {
        "name": "Dera Ghazi Khan Airport",
        "city": "Dera Ghazi Khan",
        "country": "Pakistan",
        "iata": "DEA",
        "icao": "OPDG",
        "latitude": "29.961000442504883",
        "longitude": "70.48590087890625",
        "altitude": "492",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "DSK": {
        "name": "Dera Ismael Khan Airport",
        "city": "Dera Ismael Khan",
        "country": "Pakistan",
        "iata": "DSK",
        "icao": "OPDI",
        "latitude": "31.909400939941406",
        "longitude": "70.89659881591797",
        "altitude": "594",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "JIW": {
        "name": "Jiwani Airport",
        "city": "Jiwani",
        "country": "Pakistan",
        "iata": "JIW",
        "icao": "OPJI",
        "latitude": "25.067800521900004",
        "longitude": "61.8054008484",
        "altitude": "186",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "HDD": {
        "name": "Hyderabad Airport",
        "city": "Hyderabad",
        "country": "Pakistan",
        "iata": "HDD",
        "icao": "OPKD",
        "latitude": "25.3181",
        "longitude": "68.366096",
        "altitude": "130",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "KDD": {
        "name": "Khuzdar Airport",
        "city": "Khuzdar",
        "country": "Pakistan",
        "iata": "KDD",
        "icao": "OPKH",
        "latitude": "27.790599823",
        "longitude": "66.6473007202",
        "altitude": "4012",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "ORW": {
        "name": "Ormara Airport",
        "city": "Ormara Raik",
        "country": "Pakistan",
        "iata": "ORW",
        "icao": "OPOR",
        "latitude": "25.274700164799995",
        "longitude": "64.58599853519999",
        "altitude": "10",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "PAJ": {
        "name": "Parachinar Airport",
        "city": "Parachinar",
        "country": "Pakistan",
        "iata": "PAJ",
        "icao": "OPPC",
        "latitude": "33.902099609400004",
        "longitude": "70.0716018677",
        "altitude": "5800",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "KDU": {
        "name": "Skardu Airport",
        "city": "Skardu",
        "country": "Pakistan",
        "iata": "KDU",
        "icao": "OPSD",
        "latitude": "35.33549880981445",
        "longitude": "75.53600311279297",
        "altitude": "7316",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "SYW": {
        "name": "Sehwan Sharif Airport",
        "city": "Sehwan Sharif",
        "country": "Pakistan",
        "iata": "SYW",
        "icao": "OPSN",
        "latitude": "26.473100662231445",
        "longitude": "67.71720123291016",
        "altitude": "121",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "TUK": {
        "name": "Turbat International Airport",
        "city": "Turbat",
        "country": "Pakistan",
        "iata": "TUK",
        "icao": "OPTU",
        "latitude": "25.986400604248047",
        "longitude": "63.03020095825195",
        "altitude": "498",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "ISU": {
        "name": "Sulaymaniyah International Airport",
        "city": "Sulaymaniyah",
        "country": "Iraq",
        "iata": "ISU",
        "icao": "ORSU",
        "latitude": "35.5617485046",
        "longitude": "45.316738128699996",
        "altitude": "2494",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "KAC": {
        "name": "Kamishly Airport",
        "city": "Kamishly",
        "country": "Syria",
        "iata": "KAC",
        "icao": "OSKL",
        "latitude": "37.020599365234375",
        "longitude": "41.19139862060547",
        "altitude": "1480",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Asia/Damascus"
    },
    "GXF": {
        "name": "Sayun International Airport",
        "city": "Sayun Intl",
        "country": "Yemen",
        "iata": "GXF",
        "icao": "OYSY",
        "latitude": "15.9660997391",
        "longitude": "48.78829956049999",
        "altitude": "2097",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Aden"
    },
    "ADK": {
        "name": "Adak Airport",
        "city": "Adak Island",
        "country": "United States",
        "iata": "ADK",
        "icao": "PADK",
        "latitude": "51.87799835205078",
        "longitude": "-176.64599609375",
        "altitude": "18",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "America/Adak"
    },
    "GST": {
        "name": "Gustavus Airport",
        "city": "Gustavus",
        "country": "United States",
        "iata": "GST",
        "icao": "PAGS",
        "latitude": "58.4253006",
        "longitude": "-135.7070007",
        "altitude": "35",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SGY": {
        "name": "Skagway Airport",
        "city": "Skagway",
        "country": "United States",
        "iata": "SGY",
        "icao": "PAGY",
        "latitude": "59.46009826660156",
        "longitude": "-135.3159942626953",
        "altitude": "44",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HCR": {
        "name": "Holy Cross Airport",
        "city": "Holy Cross",
        "country": "United States",
        "iata": "HCR",
        "icao": "PAHC",
        "latitude": "62.18830108642578",
        "longitude": "-159.77499389648438",
        "altitude": "70",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HNS": {
        "name": "Haines Airport",
        "city": "Haines",
        "country": "United States",
        "iata": "HNS",
        "icao": "PAHN",
        "latitude": "59.24380111694336",
        "longitude": "-135.5240020751953",
        "altitude": "15",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KLG": {
        "name": "Kalskag Airport",
        "city": "Kalskag",
        "country": "United States",
        "iata": "KLG",
        "icao": "PALG",
        "latitude": "61.53630065917969",
        "longitude": "-160.34100341796875",
        "altitude": "55",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MCG": {
        "name": "McGrath Airport",
        "city": "Mcgrath",
        "country": "United States",
        "iata": "MCG",
        "icao": "PAMC",
        "latitude": "62.95289993",
        "longitude": "-155.6060028",
        "altitude": "341",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MOU": {
        "name": "Mountain Village Airport",
        "city": "Mountain Village",
        "country": "United States",
        "iata": "MOU",
        "icao": "PAMO",
        "latitude": "62.095401763916016",
        "longitude": "-163.6820068359375",
        "altitude": "337",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ANI": {
        "name": "Aniak Airport",
        "city": "Aniak",
        "country": "United States",
        "iata": "ANI",
        "icao": "PANI",
        "latitude": "61.581600189208984",
        "longitude": "-159.54299926757812",
        "altitude": "88",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "VAK": {
        "name": "Chevak Airport",
        "city": "Chevak",
        "country": "United States",
        "iata": "VAK",
        "icao": "PAVA",
        "latitude": "61.5409",
        "longitude": "-165.6005",
        "altitude": "75",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WRG": {
        "name": "Wrangell Airport",
        "city": "Wrangell",
        "country": "United States",
        "iata": "WRG",
        "icao": "PAWG",
        "latitude": "56.48429871",
        "longitude": "-132.3699951",
        "altitude": "49",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OPU": {
        "name": "Balimo Airport",
        "city": "Balimo",
        "country": "Papua New Guinea",
        "iata": "OPU",
        "icao": "AYBM",
        "latitude": "-8.05000019073",
        "longitude": "142.932998657",
        "altitude": "51",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "VMU": {
        "name": "Baimuru Airport",
        "city": "Baimuru",
        "country": "Papua New Guinea",
        "iata": "VMU",
        "icao": "AYBA",
        "latitude": "-7.49686002731",
        "longitude": "144.819900513",
        "altitude": "27",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Pacific/Port_Moresby"
    },
    "LUP": {
        "name": "Kalaupapa Airport",
        "city": "Molokai",
        "country": "United States",
        "iata": "LUP",
        "icao": "PHLU",
        "latitude": "21.21100044",
        "longitude": "-156.973999",
        "altitude": "24",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "Pacific/Honolulu"
    },
    "ENT": {
        "name": "Eniwetok Airport",
        "city": "Eniwetok Atoll",
        "country": "Marshall Islands",
        "iata": "ENT",
        "icao": "PKMA",
        "latitude": "11.340700149536133",
        "longitude": "162.3280029296875",
        "altitude": "13",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "LZN": {
        "name": "Matsu Nangan Airport",
        "city": "Matsu Islands",
        "country": "Taiwan",
        "iata": "LZN",
        "icao": "RCFG",
        "latitude": "26.1598",
        "longitude": "119.958",
        "altitude": "232",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "HCN": {
        "name": "Hengchun Airport",
        "city": "Hengchun",
        "country": "Taiwan",
        "iata": "HCN",
        "icao": "RCKW",
        "latitude": "22.041099548339844",
        "longitude": "120.7300033569336",
        "altitude": "46",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "MFK": {
        "name": "Matsu Beigan Airport",
        "city": "Matsu Islands",
        "country": "Taiwan",
        "iata": "MFK",
        "icao": "RCMT",
        "latitude": "26.224199",
        "longitude": "120.002998",
        "altitude": "41",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "KUH": {
        "name": "Kushiro Airport",
        "city": "Kushiro",
        "country": "Japan",
        "iata": "KUH",
        "icao": "RJCK",
        "latitude": "43.041000366199995",
        "longitude": "144.192993164",
        "altitude": "327",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "OKD": {
        "name": "Okadama Airport",
        "city": "Sapporo",
        "country": "Japan",
        "iata": "OKD",
        "icao": "RJCO",
        "latitude": "43.117447",
        "longitude": "141.38134",
        "altitude": "25",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "HSG": {
        "name": "Saga Airport",
        "city": "Saga",
        "country": "Japan",
        "iata": "HSG",
        "icao": "RJFS",
        "latitude": "33.149700164799995",
        "longitude": "130.302001953",
        "altitude": "6",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Tokyo"
    },
    "NKM": {
        "name": "Nagoya Airport",
        "city": "Nagoya",
        "country": "Japan",
        "iata": "NKM",
        "icao": "RJNA",
        "latitude": "35.255001068115234",
        "longitude": "136.9239959716797",
        "altitude": "52",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "IWJ": {
        "name": "Iwami Airport",
        "city": "Iwami",
        "country": "Japan",
        "iata": "IWJ",
        "icao": "RJOW",
        "latitude": "34.676399231",
        "longitude": "131.789993286",
        "altitude": "184",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "FKS": {
        "name": "Fukushima Airport",
        "city": "Fukushima",
        "country": "Japan",
        "iata": "FKS",
        "icao": "RJSF",
        "latitude": "37.22740173339844",
        "longitude": "140.43099975585938",
        "altitude": "1221",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "ONJ": {
        "name": "Odate Noshiro Airport",
        "city": "Odate Noshiro",
        "country": "Japan",
        "iata": "ONJ",
        "icao": "RJSR",
        "latitude": "40.1918983459",
        "longitude": "140.371002197",
        "altitude": "292",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "SYO": {
        "name": "Shonai Airport",
        "city": "Shonai",
        "country": "Japan",
        "iata": "SYO",
        "icao": "RJSY",
        "latitude": "38.81219863889999",
        "longitude": "139.787002563",
        "altitude": "86",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "MYE": {
        "name": "Miyakejima Airport",
        "city": "Miyakejima",
        "country": "Japan",
        "iata": "MYE",
        "icao": "RJTQ",
        "latitude": "34.073600769",
        "longitude": "139.559997559",
        "altitude": "67",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KUV": {
        "name": "Kunsan Air Base",
        "city": "Kunsan",
        "country": "South Korea",
        "iata": "KUV",
        "icao": "RKJK",
        "latitude": "35.90380096435547",
        "longitude": "126.61599731445312",
        "altitude": "29",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "MPK": {
        "name": "Mokpo Heliport",
        "city": "Mokpo",
        "country": "South Korea",
        "iata": "MPK",
        "icao": "RKJM",
        "latitude": "34.7588996887",
        "longitude": "126.379997253",
        "altitude": "23",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "WJU": {
        "name": "Wonju/Hoengseong Air Base (K-38/K-46)",
        "city": "Wonju",
        "country": "South Korea",
        "iata": "WJU",
        "icao": "RKNW",
        "latitude": "37.441201",
        "longitude": "127.963858",
        "altitude": "329",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "YNY": {
        "name": "Yangyang International Airport",
        "city": "Sokcho / Gangneung",
        "country": "South Korea",
        "iata": "YNY",
        "icao": "RKNY",
        "latitude": "38.061298",
        "longitude": "128.669006",
        "altitude": "241",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "HIN": {
        "name": "Sacheon Air Base/Airport",
        "city": "Sacheon",
        "country": "South Korea",
        "iata": "HIN",
        "icao": "RKPS",
        "latitude": "35.088591",
        "longitude": "128.071747",
        "altitude": "25",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "CJJ": {
        "name": "Cheongju International Airport/Cheongju Air Base (K-59/G-513)",
        "city": "Chongju",
        "country": "South Korea",
        "iata": "CJJ",
        "icao": "RKTU",
        "latitude": "36.717008",
        "longitude": "127.498741",
        "altitude": "191",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "SFS": {
        "name": "Subic Bay International Airport",
        "city": "Olongapo City",
        "country": "Philippines",
        "iata": "SFS",
        "icao": "RPLB",
        "latitude": "14.794400215148926",
        "longitude": "120.27100372314453",
        "altitude": "64",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "CYU": {
        "name": "Cuyo Airport",
        "city": "Cuyo",
        "country": "Philippines",
        "iata": "CYU",
        "icao": "RPLO",
        "latitude": "10.858099937438965",
        "longitude": "121.06900024414062",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "CGM": {
        "name": "Camiguin Airport",
        "city": "Camiguin",
        "country": "Philippines",
        "iata": "CGM",
        "icao": "RPMH",
        "latitude": "9.253520011901855",
        "longitude": "124.70700073242188",
        "altitude": "53",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "JOL": {
        "name": "Jolo Airport",
        "city": "Jolo",
        "country": "Philippines",
        "iata": "JOL",
        "icao": "RPMJ",
        "latitude": "6.0536699295043945",
        "longitude": "121.01100158691406",
        "altitude": "118",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "TWT": {
        "name": "Sanga Sanga Airport",
        "city": "Sanga Sanga",
        "country": "Philippines",
        "iata": "TWT",
        "icao": "RPMN",
        "latitude": "5.046991",
        "longitude": "119.742996",
        "altitude": "15",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "SUG": {
        "name": "Surigao Airport",
        "city": "Sangley Point",
        "country": "Philippines",
        "iata": "SUG",
        "icao": "RPMS",
        "latitude": "9.755838325629998",
        "longitude": "125.480947495",
        "altitude": "20",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "TDG": {
        "name": "Tandag Airport",
        "city": "Tandag",
        "country": "Philippines",
        "iata": "TDG",
        "icao": "RPMW",
        "latitude": "9.072110176086426",
        "longitude": "126.1709976196289",
        "altitude": "16",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "WNP": {
        "name": "Naga Airport",
        "city": "Naga",
        "country": "Philippines",
        "iata": "WNP",
        "icao": "RPUN",
        "latitude": "13.58489990234375",
        "longitude": "123.2699966430664",
        "altitude": "142",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "BSO": {
        "name": "Basco Airport",
        "city": "Basco",
        "country": "Philippines",
        "iata": "BSO",
        "icao": "RPUO",
        "latitude": "20.4513",
        "longitude": "121.980003",
        "altitude": "291",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "SFE": {
        "name": "San Fernando Airport",
        "city": "San Fernando",
        "country": "Philippines",
        "iata": "SFE",
        "icao": "RPUS",
        "latitude": "16.595600128173828",
        "longitude": "120.3030014038086",
        "altitude": "13",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "TUG": {
        "name": "Tuguegarao Airport",
        "city": "Tuguegarao",
        "country": "Philippines",
        "iata": "TUG",
        "icao": "RPUT",
        "latitude": "17.6433676823",
        "longitude": "121.733150482",
        "altitude": "70",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "VRC": {
        "name": "Virac Airport",
        "city": "Virac",
        "country": "Philippines",
        "iata": "VRC",
        "icao": "RPUV",
        "latitude": "13.576399803161621",
        "longitude": "124.20600128173828",
        "altitude": "121",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "CYP": {
        "name": "Calbayog Airport",
        "city": "Calbayog City",
        "country": "Philippines",
        "iata": "CYP",
        "icao": "RPVC",
        "latitude": "12.072699546813965",
        "longitude": "124.54499816894531",
        "altitude": "12",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "CRM": {
        "name": "Catarman National Airport",
        "city": "Catarman",
        "country": "Philippines",
        "iata": "CRM",
        "icao": "RPVF",
        "latitude": "12.502400398254395",
        "longitude": "124.63600158691406",
        "altitude": "6",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "MBT": {
        "name": "Moises R. Espinosa Airport",
        "city": "Masbate",
        "country": "Philippines",
        "iata": "MBT",
        "icao": "RPVJ",
        "latitude": "12.369682",
        "longitude": "123.630095",
        "altitude": "49",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "RXS": {
        "name": "Roxas Airport",
        "city": "Roxas City",
        "country": "Philippines",
        "iata": "RXS",
        "icao": "RPVR",
        "latitude": "11.597700119018555",
        "longitude": "122.75199890136719",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "TTG": {
        "name": "General Enrique Mosconi Airport",
        "city": "Tartagal",
        "country": "Argentina",
        "iata": "TTG",
        "icao": "SAST",
        "latitude": "-22.619600296",
        "longitude": "-63.7937011719",
        "altitude": "1472",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "LHS": {
        "name": "Las Heras Airport",
        "city": "Las Heras",
        "country": "Argentina",
        "iata": "LHS",
        "icao": "SAVH",
        "latitude": "-46.53829956049999",
        "longitude": "-68.9653015137",
        "altitude": "1082",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "OES": {
        "name": "Antoine de Saint Exupéry Airport",
        "city": "San Antonio Oeste",
        "country": "Argentina",
        "iata": "OES",
        "icao": "SAVN",
        "latitude": "-40.7512",
        "longitude": "-65.0343",
        "altitude": "85",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "ING": {
        "name": "Lago Argentino Airport",
        "city": "El Calafate",
        "country": "Argentina",
        "iata": "ING",
        "icao": "SAWA",
        "latitude": "-50.336102",
        "longitude": "-72.248596",
        "altitude": "732",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "GGS": {
        "name": "Gobernador Gregores Airport",
        "city": "Gobernador Gregores",
        "country": "Argentina",
        "iata": "GGS",
        "icao": "SAWR",
        "latitude": "-48.7831",
        "longitude": "-70.150002",
        "altitude": "356",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Rio_Gallegos"
    },
    "SST": {
        "name": "Santa Teresita Airport",
        "city": "Santa Teresita",
        "country": "Argentina",
        "iata": "SST",
        "icao": "SAZL",
        "latitude": "-36.5423",
        "longitude": "-56.7218",
        "altitude": "9",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "NEC": {
        "name": "Necochea Airport",
        "city": "Necochea",
        "country": "Argentina",
        "iata": "NEC",
        "icao": "SAZO",
        "latitude": "-38.4831",
        "longitude": "-58.8172",
        "altitude": "72",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Buenos_Aires"
    },
    "JDO": {
        "name": "Orlando Bezerra de Menezes Airport",
        "city": "Juazeiro Do Norte",
        "country": "Brazil",
        "iata": "JDO",
        "icao": "SBJU",
        "latitude": "-7.21895980835",
        "longitude": "-39.270099639899996",
        "altitude": "1392",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "LEC": {
        "name": "Coronel Horácio de Mattos Airport",
        "city": "Lençóis",
        "country": "Brazil",
        "iata": "LEC",
        "icao": "SBLE",
        "latitude": "-12.4822998047",
        "longitude": "-41.2770004272",
        "altitude": "1676",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "MEA": {
        "name": "Macaé Airport",
        "city": "Macaé",
        "country": "Brazil",
        "iata": "MEA",
        "icao": "SBME",
        "latitude": "-22.343000412",
        "longitude": "-41.7659988403",
        "altitude": "8",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MII": {
        "name": "Frank Miloye Milenkowichi–Marília State Airport",
        "city": "Marília",
        "country": "Brazil",
        "iata": "MII",
        "icao": "SBML",
        "latitude": "-22.1968994141",
        "longitude": "-49.926399231",
        "altitude": "2122",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "VDC": {
        "name": "Vitória da Conquista Airport",
        "city": "Vitória Da Conquista",
        "country": "Brazil",
        "iata": "VDC",
        "icao": "SBQV",
        "latitude": "-14.8627996445",
        "longitude": "-40.8630981445",
        "altitude": "3002",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "RIA": {
        "name": "Santa Maria Airport",
        "city": "Santa Maria",
        "country": "Brazil",
        "iata": "RIA",
        "icao": "SBSM",
        "latitude": "-29.711399",
        "longitude": "-53.688202",
        "altitude": "287",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "TOW": {
        "name": "Toledo Airport",
        "city": "Toledo",
        "country": "Brazil",
        "iata": "TOW",
        "icao": "SBTD",
        "latitude": "-24.6863",
        "longitude": "-53.697498",
        "altitude": "1843",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "ESR": {
        "name": "Ricardo García Posada Airport",
        "city": "El Salvador",
        "country": "Chile",
        "iata": "ESR",
        "icao": "SCES",
        "latitude": "-26.311100006103516",
        "longitude": "-69.76519775390625",
        "altitude": "5240",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "ZPC": {
        "name": "Pucón Airport",
        "city": "Pucon",
        "country": "Chile",
        "iata": "ZPC",
        "icao": "SCPC",
        "latitude": "-39.29280090332031",
        "longitude": "-71.91590118408203",
        "altitude": "853",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "SOD": {
        "name": "Sorocaba Airport",
        "city": "Sorocaba",
        "country": "Brazil",
        "iata": "SOD",
        "icao": "SDCO",
        "latitude": "-23.478001",
        "longitude": "-47.490002",
        "altitude": "2077",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SCY": {
        "name": "San Cristóbal Airport",
        "city": "San Cristóbal",
        "country": "Ecuador",
        "iata": "SCY",
        "icao": "SEST",
        "latitude": "-0.910206",
        "longitude": "-89.617401",
        "altitude": "62",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "Pacific/Galapagos"
    },
    "LOH": {
        "name": "Camilo Ponce Enriquez Airport",
        "city": "La Toma (Catamayo)",
        "country": "Ecuador",
        "iata": "LOH",
        "icao": "SETM",
        "latitude": "-3.99589",
        "longitude": "-79.371902",
        "altitude": "4056",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "ESM": {
        "name": "General Rivadeneira Airport",
        "city": "Esmeraldas",
        "country": "Ecuador",
        "iata": "ESM",
        "icao": "SETN",
        "latitude": "0.9785190224647522",
        "longitude": "-79.62660217285156",
        "altitude": "32",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Guayaquil"
    },
    "PSY": {
        "name": "Port Stanley Airport",
        "city": "Stanley",
        "country": "Falkland Islands",
        "iata": "PSY",
        "icao": "SFAL",
        "latitude": "-51.685699462891",
        "longitude": "-57.777599334717",
        "altitude": "75",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "Atlantic/Stanley"
    },
    "CRC": {
        "name": "Santa Ana Airport",
        "city": "Cartago",
        "country": "Colombia",
        "iata": "CRC",
        "icao": "SKGO",
        "latitude": "4.75818",
        "longitude": "-75.9557",
        "altitude": "2979",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "LQM": {
        "name": "Caucaya Airport",
        "city": "Puerto Leguízamo",
        "country": "Colombia",
        "iata": "LQM",
        "icao": "SKLG",
        "latitude": "-0.182278",
        "longitude": "-74.7708",
        "altitude": "573",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "LPD": {
        "name": "La Pedrera Airport",
        "city": "La Pedrera",
        "country": "Colombia",
        "iata": "LPD",
        "icao": "SKLP",
        "latitude": "-1.32861",
        "longitude": "-69.5797",
        "altitude": "590",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "NQU": {
        "name": "Reyes Murillo Airport",
        "city": "Nuquí",
        "country": "Colombia",
        "iata": "NQU",
        "icao": "SKNQ",
        "latitude": "5.6964",
        "longitude": "-77.2806",
        "altitude": "12",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "PDA": {
        "name": "Obando Airport",
        "city": "Puerto Inírida",
        "country": "Colombia",
        "iata": "PDA",
        "icao": "SKPD",
        "latitude": "3.85353",
        "longitude": "-67.9062",
        "altitude": "460",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "EYP": {
        "name": "El Yopal Airport",
        "city": "Yopal",
        "country": "Colombia",
        "iata": "EYP",
        "icao": "SKYP",
        "latitude": "5.31911",
        "longitude": "-72.384",
        "altitude": "1028",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "GYA": {
        "name": "Capitán de Av. Emilio Beltrán Airport",
        "city": "Guayaramerín",
        "country": "Bolivia",
        "iata": "GYA",
        "icao": "SLGY",
        "latitude": "-10.820599556",
        "longitude": "-65.3455963135",
        "altitude": "557",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "PUR": {
        "name": "Puerto Rico Airport",
        "city": "Puerto Rico/Manuripi",
        "country": "Bolivia",
        "iata": "PUR",
        "icao": "SLPR",
        "latitude": "-11.107663",
        "longitude": "-67.551155",
        "altitude": "597",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "RIB": {
        "name": "Capitán Av. Selin Zeitun Lopez Airport",
        "city": "Riberalta",
        "country": "Bolivia",
        "iata": "RIB",
        "icao": "SLRI",
        "latitude": "-11",
        "longitude": "-66",
        "altitude": "462",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "REY": {
        "name": "Reyes Airport",
        "city": "Reyes",
        "country": "Bolivia",
        "iata": "REY",
        "icao": "SLRY",
        "latitude": "-14.3044",
        "longitude": "-67.353401",
        "altitude": "935",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "SRJ": {
        "name": "Capitán Av. German Quiroga G. Airport",
        "city": "San Borja",
        "country": "Bolivia",
        "iata": "SRJ",
        "icao": "SLSB",
        "latitude": "-14.859199523925781",
        "longitude": "-66.73750305175781",
        "altitude": "633",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "ORG": {
        "name": "Zorg en Hoop Airport",
        "city": "Paramaribo",
        "country": "Suriname",
        "iata": "ORG",
        "icao": "SMZO",
        "latitude": "5.81108",
        "longitude": "-55.190701",
        "altitude": "10",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "MVS": {
        "name": "Mucuri Airport",
        "city": "Mucuri",
        "country": "Brazil",
        "iata": "MVS",
        "icao": "SNMU",
        "latitude": "-18.048900604248047",
        "longitude": "-39.864200592041016",
        "altitude": "276",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CJA": {
        "name": "Mayor General FAP Armando Revoredo Iglesias Airport",
        "city": "Cajamarca",
        "country": "Peru",
        "iata": "CJA",
        "icao": "SPJR",
        "latitude": "-7.1391801834106445",
        "longitude": "-78.4894027709961",
        "altitude": "8781",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "HUU": {
        "name": "Alferez Fap David Figueroa Fernandini Airport",
        "city": "Huánuco",
        "country": "Peru",
        "iata": "HUU",
        "icao": "SPNC",
        "latitude": "-9.878809928894043",
        "longitude": "-76.20480346679688",
        "altitude": "6070",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "NZC": {
        "name": "Maria Reiche Neuman Airport",
        "city": "Nazca",
        "country": "Peru",
        "iata": "NZC",
        "icao": "SPZA",
        "latitude": "-14.854000091600001",
        "longitude": "-74.9615020752",
        "altitude": "1860",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Lima"
    },
    "SRA": {
        "name": "Santa Rosa Airport",
        "city": "Santa Rosa",
        "country": "Brazil",
        "iata": "SRA",
        "icao": "SSZR",
        "latitude": "-27.9067",
        "longitude": "-54.520401",
        "altitude": "984",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MYC": {
        "name": "Escuela Mariscal Sucre Airport",
        "city": "Maracay",
        "country": "Venezuela",
        "iata": "MYC",
        "icao": "SVBS",
        "latitude": "10.249978065490723",
        "longitude": "-67.64942169189453",
        "altitude": "1338",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "VIG": {
        "name": "Juan Pablo Pérez Alfonso Airport",
        "city": "El Vigía",
        "country": "Venezuela",
        "iata": "VIG",
        "icao": "SVVG",
        "latitude": "8.624139",
        "longitude": "-71.672668",
        "altitude": "250",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Caracas"
    },
    "JPR": {
        "name": "Ji-Paraná Airport",
        "city": "Ji-Paraná",
        "country": "Brazil",
        "iata": "JPR",
        "icao": "SWJI",
        "latitude": "-10.870800018299999",
        "longitude": "-61.8465003967",
        "altitude": "598",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "BBQ": {
        "name": "Codrington Airport",
        "city": "Codrington",
        "country": "Antigua and Barbuda",
        "iata": "BBQ",
        "icao": "TAPH",
        "latitude": "17.635799",
        "longitude": "-61.828602",
        "altitude": "15",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Antigua"
    },
    "DSD": {
        "name": "La Désirade Airport",
        "city": "Grande Anse",
        "country": "Guadeloupe",
        "iata": "DSD",
        "icao": "TFFA",
        "latitude": "16.296902",
        "longitude": "-61.0844",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guadeloupe"
    },
    "BBR": {
        "name": "Baillif Airport",
        "city": "Basse Terre",
        "country": "Guadeloupe",
        "iata": "BBR",
        "icao": "TFFB",
        "latitude": "16.0132999420166",
        "longitude": "-61.7421989440918",
        "altitude": "59",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guadeloupe"
    },
    "SFC": {
        "name": "St-François Airport",
        "city": "St-François",
        "country": "Guadeloupe",
        "iata": "SFC",
        "icao": "TFFC",
        "latitude": "16.25779914855957",
        "longitude": "-61.26250076293945",
        "altitude": "10",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guadeloupe"
    },
    "GBJ": {
        "name": "Les Bases Airport",
        "city": "Grand Bourg",
        "country": "Guadeloupe",
        "iata": "GBJ",
        "icao": "TFFM",
        "latitude": "15.86870002746582",
        "longitude": "-61.27000045776367",
        "altitude": "16",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guadeloupe"
    },
    "NEV": {
        "name": "Vance W. Amory International Airport",
        "city": "Charlestown",
        "country": "Saint Kitts and Nevis",
        "iata": "NEV",
        "icao": "TKPN",
        "latitude": "17.205699920654297",
        "longitude": "-62.589900970458984",
        "altitude": "14",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Kitts"
    },
    "VIJ": {
        "name": "Virgin Gorda Airport",
        "city": "Spanish Town",
        "country": "British Virgin Islands",
        "iata": "VIJ",
        "icao": "TUPW",
        "latitude": "18.446399688720703",
        "longitude": "-64.42749786376953",
        "altitude": "9",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Tortola"
    },
    "BQU": {
        "name": "J F Mitchell Airport",
        "city": "Bequia",
        "country": "Saint Vincent and the Grenadines",
        "iata": "BQU",
        "icao": "TVSB",
        "latitude": "12.9884004593",
        "longitude": "-61.2620010376",
        "altitude": "15",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Vincent"
    },
    "UNI": {
        "name": "Union Island International Airport",
        "city": "Union Island",
        "country": "Saint Vincent and the Grenadines",
        "iata": "UNI",
        "icao": "TVSU",
        "latitude": "12.60013484954834",
        "longitude": "-61.41194534301758",
        "altitude": "16",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/St_Vincent"
    },
    "KOV": {
        "name": "Kokshetau Airport",
        "city": "Kokshetau",
        "country": "Kazakhstan",
        "iata": "KOV",
        "icao": "UACK",
        "latitude": "53.329102",
        "longitude": "69.594597",
        "altitude": "900",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "PPK": {
        "name": "Petropavlosk South Airport",
        "city": "Petropavlosk",
        "country": "Kazakhstan",
        "iata": "PPK",
        "icao": "UACP",
        "latitude": "54.77470016479492",
        "longitude": "69.18389892578125",
        "altitude": "453",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "DZN": {
        "name": "Zhezkazgan Airport",
        "city": "Zhezkazgan",
        "country": "Kazakhstan",
        "iata": "DZN",
        "icao": "UAKD",
        "latitude": "47.708302",
        "longitude": "67.733299",
        "altitude": "1250",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "UKK": {
        "name": "Ust-Kamennogorsk Airport",
        "city": "Ust Kamenogorsk",
        "country": "Kazakhstan",
        "iata": "UKK",
        "icao": "UASK",
        "latitude": "50.036598205566406",
        "longitude": "82.49420166015625",
        "altitude": "939",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "KSN": {
        "name": "Kostanay West Airport",
        "city": "Kostanay",
        "country": "Kazakhstan",
        "iata": "KSN",
        "icao": "UAUU",
        "latitude": "53.20690155029297",
        "longitude": "63.55030059814453",
        "altitude": "595",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "KVD": {
        "name": "Ganja Airport",
        "city": "Ganja",
        "country": "Azerbaijan",
        "iata": "KVD",
        "icao": "UBBG",
        "latitude": "40.737701416015625",
        "longitude": "46.31760025024414",
        "altitude": "1083",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Baku"
    },
    "NAJ": {
        "name": "Nakhchivan Airport",
        "city": "Nakhchivan",
        "country": "Azerbaijan",
        "iata": "NAJ",
        "icao": "UBBN",
        "latitude": "39.18880081176758",
        "longitude": "45.45840072631836",
        "altitude": "2863",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Baku"
    },
    "NER": {
        "name": "Chulman Airport",
        "city": "Neryungri",
        "country": "Russia",
        "iata": "NER",
        "icao": "UELL",
        "latitude": "56.913898468018",
        "longitude": "124.91400146484",
        "altitude": "2812",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "PYJ": {
        "name": "Polyarny Airport",
        "city": "Yakutia",
        "country": "Russia",
        "iata": "PYJ",
        "icao": "UERP",
        "latitude": "66.4003982544",
        "longitude": "112.029998779",
        "altitude": "1660",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "CKH": {
        "name": "Chokurdakh Airport",
        "city": "Chokurdah",
        "country": "Russia",
        "iata": "CKH",
        "icao": "UESO",
        "latitude": "70.62310028076172",
        "longitude": "147.90199279785156",
        "altitude": "151",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "CYX": {
        "name": "Cherskiy Airport",
        "city": "Cherskiy",
        "country": "Russia",
        "iata": "CYX",
        "icao": "UESS",
        "latitude": "68.7406005859375",
        "longitude": "161.33799743652344",
        "altitude": "20",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "IKS": {
        "name": "Tiksi Airport",
        "city": "Tiksi",
        "country": "Russia",
        "iata": "IKS",
        "icao": "UEST",
        "latitude": "71.697700500488",
        "longitude": "128.90299987793",
        "altitude": "26",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "KXK": {
        "name": "Komsomolsk-on-Amur Airport",
        "city": "Komsomolsk-on-Amur",
        "country": "Russia",
        "iata": "KXK",
        "icao": "UHKK",
        "latitude": "50.409000396728516",
        "longitude": "136.9340057373047",
        "altitude": "92",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "DYR": {
        "name": "Ugolny Airport",
        "city": "Anadyr",
        "country": "Russia",
        "iata": "DYR",
        "icao": "UHMA",
        "latitude": "64.734902",
        "longitude": "177.740997",
        "altitude": "194",
        "timezone": "12",
        "dst": "N",
        "tz_name": "Asia/Anadyr"
    },
    "OHO": {
        "name": "Okhotsk Airport",
        "city": "Okhotsk",
        "country": "Russia",
        "iata": "OHO",
        "icao": "UHOO",
        "latitude": "59.410064697265625",
        "longitude": "143.05650329589844",
        "altitude": "0",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "UJE": {
        "name": "Ujae Atoll Airport",
        "city": "Ujae Atoll",
        "country": "Marshall Islands",
        "iata": "UJE",
        "icao": "UJAP",
        "latitude": "8.92805957794",
        "longitude": "165.761993408",
        "altitude": "29",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Majuro"
    },
    "MPW": {
        "name": "Mariupol International Airport",
        "city": "Mariupol International",
        "country": "Ukraine",
        "iata": "MPW",
        "icao": "UKCM",
        "latitude": "47.07609939575195",
        "longitude": "37.44960021972656",
        "altitude": "251",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "VSG": {
        "name": "Luhansk International Airport",
        "city": "Lugansk",
        "country": "Ukraine",
        "iata": "VSG",
        "icao": "UKCW",
        "latitude": "48.4174003601",
        "longitude": "39.3740997314",
        "altitude": "636",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "OZH": {
        "name": "Zaporizhzhia International Airport",
        "city": "Zaporozhye",
        "country": "Ukraine",
        "iata": "OZH",
        "icao": "UKDE",
        "latitude": "47.867000579833984",
        "longitude": "35.31570053100586",
        "altitude": "373",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "KWG": {
        "name": "Kryvyi Rih International Airport",
        "city": "Krivoy Rog",
        "country": "Ukraine",
        "iata": "KWG",
        "icao": "UKDR",
        "latitude": "48.04330062866211",
        "longitude": "33.209999084472656",
        "altitude": "408",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "HRK": {
        "name": "Kharkiv International Airport",
        "city": "Kharkov",
        "country": "Ukraine",
        "iata": "HRK",
        "icao": "UKHH",
        "latitude": "49.924800872802734",
        "longitude": "36.290000915527344",
        "altitude": "508",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "IFO": {
        "name": "Ivano-Frankivsk International Airport",
        "city": "Ivano-Frankivsk",
        "country": "Ukraine",
        "iata": "IFO",
        "icao": "UKLI",
        "latitude": "48.88420104980469",
        "longitude": "24.686100006103516",
        "altitude": "919",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "CWC": {
        "name": "Chernivtsi International Airport",
        "city": "Chernovtsk",
        "country": "Ukraine",
        "iata": "CWC",
        "icao": "UKLN",
        "latitude": "48.259300231933594",
        "longitude": "25.98080062866211",
        "altitude": "826",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "RWN": {
        "name": "Rivne International Airport",
        "city": "Rivne",
        "country": "Ukraine",
        "iata": "RWN",
        "icao": "UKLR",
        "latitude": "50.60710144042969",
        "longitude": "26.141599655151367",
        "altitude": "755",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "UDJ": {
        "name": "Uzhhorod International Airport",
        "city": "Uzhgorod",
        "country": "Ukraine",
        "iata": "UDJ",
        "icao": "UKLU",
        "latitude": "48.634300231933594",
        "longitude": "22.263399124145508",
        "altitude": "383",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "CSH": {
        "name": "Solovki Airport",
        "city": "Solovetsky Islands",
        "country": "Russia",
        "iata": "CSH",
        "icao": "ULAS",
        "latitude": "65.0299987793",
        "longitude": "35.7333335876",
        "altitude": "60",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "CEE": {
        "name": "Cherepovets Airport",
        "city": "Cherepovets",
        "country": "Russia",
        "iata": "CEE",
        "icao": "ULBC",
        "latitude": "59.273601532",
        "longitude": "38.015800476100004",
        "altitude": "377",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "AMV": {
        "name": "Amderma Airport",
        "city": "Amderma",
        "country": "Russia",
        "iata": "AMV",
        "icao": "ULDD",
        "latitude": "69.76329803466797",
        "longitude": "61.556400299072266",
        "altitude": "13",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "KSZ": {
        "name": "Kotlas Airport",
        "city": "Kotlas",
        "country": "Russia",
        "iata": "KSZ",
        "icao": "ULKK",
        "latitude": "61.235801696777344",
        "longitude": "46.6974983215332",
        "altitude": "184",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "PES": {
        "name": "Petrozavodsk Airport",
        "city": "Petrozavodsk",
        "country": "Russia",
        "iata": "PES",
        "icao": "ULPB",
        "latitude": "61.88520050048828",
        "longitude": "34.154701232910156",
        "altitude": "151",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "GNA": {
        "name": "Hrodna Airport",
        "city": "Hrodna",
        "country": "Belarus",
        "iata": "GNA",
        "icao": "UMMG",
        "latitude": "53.60200119018555",
        "longitude": "24.053800582885742",
        "altitude": "443",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "MVQ": {
        "name": "Mogilev Airport",
        "city": "Mogilev",
        "country": "Belarus",
        "iata": "MVQ",
        "icao": "UMOO",
        "latitude": "53.954898834228516",
        "longitude": "30.09510040283203",
        "altitude": "637",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "EIE": {
        "name": "Yeniseysk Airport",
        "city": "Yeniseysk",
        "country": "Russia",
        "iata": "EIE",
        "icao": "UNII",
        "latitude": "58.47420120239258",
        "longitude": "92.11250305175781",
        "altitude": "253",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "KYZ": {
        "name": "Kyzyl Airport",
        "city": "Kyzyl",
        "country": "Russia",
        "iata": "KYZ",
        "icao": "UNKY",
        "latitude": "51.66939926147461",
        "longitude": "94.40059661865234",
        "altitude": "2123",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "NOZ": {
        "name": "Spichenkovo Airport",
        "city": "Novokuznetsk",
        "country": "Russia",
        "iata": "NOZ",
        "icao": "UNWW",
        "latitude": "53.811401",
        "longitude": "86.877197",
        "altitude": "1024",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "HTG": {
        "name": "Khatanga Airport",
        "city": "Khatanga",
        "country": "Russia",
        "iata": "HTG",
        "icao": "UOHH",
        "latitude": "71.97810363769531",
        "longitude": "102.49099731445312",
        "altitude": "95",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "IAA": {
        "name": "Igarka Airport",
        "city": "Igarka",
        "country": "Russia",
        "iata": "IAA",
        "icao": "UOII",
        "latitude": "67.43720245361328",
        "longitude": "86.62190246582031",
        "altitude": "82",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "NAL": {
        "name": "Nalchik Airport",
        "city": "Nalchik",
        "country": "Russia",
        "iata": "NAL",
        "icao": "URMN",
        "latitude": "43.512901306152344",
        "longitude": "43.636600494384766",
        "altitude": "1461",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "OGZ": {
        "name": "Beslan Airport",
        "city": "Beslan",
        "country": "Russia",
        "iata": "OGZ",
        "icao": "URMO",
        "latitude": "43.2051010132",
        "longitude": "44.6066017151",
        "altitude": "1673",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ESL": {
        "name": "Elista Airport",
        "city": "Elista",
        "country": "Russia",
        "iata": "ESL",
        "icao": "URWI",
        "latitude": "46.3739013671875",
        "longitude": "44.33089828491211",
        "altitude": "501",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "WKK": {
        "name": "Aleknagik / New Airport",
        "city": "Aleknagik",
        "country": "United States",
        "iata": "WKK",
        "icao": "5A8",
        "latitude": "59.2826004028",
        "longitude": "-158.617996216",
        "altitude": "66",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "BLF": {
        "name": "Mercer County Airport",
        "city": "Bluefield",
        "country": "United States",
        "iata": "BLF",
        "icao": "KBLF",
        "latitude": "37.295799255371094",
        "longitude": "-81.20770263671875",
        "altitude": "2857",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GLH": {
        "name": "Mid Delta Regional Airport",
        "city": "Greenville",
        "country": "United States",
        "iata": "GLH",
        "icao": "KGLH",
        "latitude": "33.4828987121582",
        "longitude": "-90.98560333251953",
        "altitude": "131",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PSC": {
        "name": "Tri Cities Airport",
        "city": "Pasco",
        "country": "United States",
        "iata": "PSC",
        "icao": "KPSC",
        "latitude": "46.26470184326172",
        "longitude": "-119.11900329589844",
        "altitude": "410",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "KQA": {
        "name": "Akutan Seaplane Base",
        "city": "Akutan",
        "country": "United States",
        "iata": "KQA",
        "icao": "KQA",
        "latitude": "54.1337704415",
        "longitude": "-165.778895617",
        "altitude": "0",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LPS": {
        "name": "Lopez Island Airport",
        "city": "Lopez",
        "country": "United States",
        "iata": "LPS",
        "icao": "S31",
        "latitude": "48.4838981628418",
        "longitude": "-122.93800354003906",
        "altitude": "209",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SLY": {
        "name": "Salekhard Airport",
        "city": "Salekhard",
        "country": "Russia",
        "iata": "SLY",
        "icao": "USDD",
        "latitude": "66.5907974243164",
        "longitude": "66.61100006103516",
        "altitude": "218",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "HMA": {
        "name": "Khanty Mansiysk Airport",
        "city": "Khanty-Mansiysk",
        "country": "Russia",
        "iata": "HMA",
        "icao": "USHH",
        "latitude": "61.028499603271484",
        "longitude": "69.08609771728516",
        "altitude": "76",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "NYA": {
        "name": "Nyagan Airport",
        "city": "Nyagan",
        "country": "Russia",
        "iata": "NYA",
        "icao": "USHN",
        "latitude": "62.110001",
        "longitude": "65.614998",
        "altitude": "361",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "OVS": {
        "name": "Sovetskiy Airport",
        "city": "Sovetskiy",
        "country": "Russia",
        "iata": "OVS",
        "icao": "USHS",
        "latitude": "61.326622009277344",
        "longitude": "63.60191345214844",
        "altitude": "351",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "IJK": {
        "name": "Izhevsk Airport",
        "city": "Izhevsk",
        "country": "Russia",
        "iata": "IJK",
        "icao": "USII",
        "latitude": "56.82809829711914",
        "longitude": "53.45750045776367",
        "altitude": "531",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Europe/Samara"
    },
    "KVX": {
        "name": "Pobedilovo Airport",
        "city": "Kirov",
        "country": "Russia",
        "iata": "KVX",
        "icao": "USKK",
        "latitude": "58.503299713135",
        "longitude": "49.348300933838",
        "altitude": "479",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "NYM": {
        "name": "Nadym Airport",
        "city": "Nadym",
        "country": "Russia",
        "iata": "NYM",
        "icao": "USMM",
        "latitude": "65.48090362548828",
        "longitude": "72.69889831542969",
        "altitude": "49",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "RAT": {
        "name": "Raduzhny Airport",
        "city": "Raduzhnyi",
        "country": "Russia",
        "iata": "RAT",
        "icao": "USNR",
        "latitude": "62.1585998535",
        "longitude": "77.32890319820001",
        "altitude": "250",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "NFG": {
        "name": "Nefteyugansk Airport",
        "city": "Nefteyugansk",
        "country": "Russia",
        "iata": "NFG",
        "icao": "USRN",
        "latitude": "61.108299255371094",
        "longitude": "72.6500015258789",
        "altitude": "115",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "KRO": {
        "name": "Kurgan Airport",
        "city": "Kurgan",
        "country": "Russia",
        "iata": "KRO",
        "icao": "USUU",
        "latitude": "55.47529983520508",
        "longitude": "65.41560363769531",
        "altitude": "240",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "LBD": {
        "name": "Khudzhand Airport",
        "city": "Khudzhand",
        "country": "Tajikistan",
        "iata": "LBD",
        "icao": "UTDL",
        "latitude": "40.21540069580078",
        "longitude": "69.6947021484375",
        "altitude": "1450",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Dushanbe"
    },
    "AZN": {
        "name": "Andizhan Airport",
        "city": "Andizhan",
        "country": "Uzbekistan",
        "iata": "AZN",
        "icao": "UTKA",
        "latitude": "40.7276992798",
        "longitude": "72.2939987183",
        "altitude": "1515",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "FEG": {
        "name": "Fergana International Airport",
        "city": "Fergana",
        "country": "Uzbekistan",
        "iata": "FEG",
        "icao": "UTKF",
        "latitude": "40.358798980699994",
        "longitude": "71.7450027466",
        "altitude": "1980",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "NMA": {
        "name": "Namangan Airport",
        "city": "Namangan",
        "country": "Uzbekistan",
        "iata": "NMA",
        "icao": "UTKN",
        "latitude": "40.9846000671",
        "longitude": "71.5567016602",
        "altitude": "1555",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "NCU": {
        "name": "Nukus Airport",
        "city": "Nukus",
        "country": "Uzbekistan",
        "iata": "NCU",
        "icao": "UTNN",
        "latitude": "42.488399505615234",
        "longitude": "59.62329864501953",
        "altitude": "246",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "UGC": {
        "name": "Urgench Airport",
        "city": "Urgench",
        "country": "Uzbekistan",
        "iata": "UGC",
        "icao": "UTNU",
        "latitude": "41.58430099487305",
        "longitude": "60.641700744628906",
        "altitude": "320",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "KSQ": {
        "name": "Karshi Khanabad Airport",
        "city": "Khanabad",
        "country": "Uzbekistan",
        "iata": "KSQ",
        "icao": "UTSL",
        "latitude": "38.8335990906",
        "longitude": "65.9215011597",
        "altitude": "1365",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "TMJ": {
        "name": "Termez Airport",
        "city": "Termez",
        "country": "Uzbekistan",
        "iata": "TMJ",
        "icao": "UTST",
        "latitude": "37.28670120239258",
        "longitude": "67.30999755859375",
        "altitude": "1027",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "RYB": {
        "name": "Staroselye Airport",
        "city": "Rybinsk",
        "country": "Russia",
        "iata": "RYB",
        "icao": "UUBK",
        "latitude": "58.10419845581055",
        "longitude": "38.92940139770508",
        "altitude": "423",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "EGO": {
        "name": "Belgorod International Airport",
        "city": "Belgorod",
        "country": "Russia",
        "iata": "EGO",
        "icao": "UUOB",
        "latitude": "50.643798828125",
        "longitude": "36.5900993347168",
        "altitude": "735",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "URS": {
        "name": "Kursk East Airport",
        "city": "Kursk",
        "country": "Russia",
        "iata": "URS",
        "icao": "UUOK",
        "latitude": "51.7505989074707",
        "longitude": "36.29560089111328",
        "altitude": "686",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "LPK": {
        "name": "Lipetsk Airport",
        "city": "Lipetsk",
        "country": "Russia",
        "iata": "LPK",
        "icao": "UUOL",
        "latitude": "52.70280075073242",
        "longitude": "39.53779983520508",
        "altitude": "584",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "VKT": {
        "name": "Vorkuta Airport",
        "city": "Vorkuta",
        "country": "Russia",
        "iata": "VKT",
        "icao": "UUYW",
        "latitude": "67.48860168457031",
        "longitude": "63.993099212646484",
        "altitude": "604",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "UUA": {
        "name": "Bugulma Airport",
        "city": "Bugulma",
        "country": "Russia",
        "iata": "UUA",
        "icao": "UWKB",
        "latitude": "54.63999938964844",
        "longitude": "52.801700592041016",
        "altitude": "991",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "JOK": {
        "name": "Yoshkar-Ola Airport",
        "city": "Yoshkar-Ola",
        "country": "Russia",
        "iata": "JOK",
        "icao": "UWKJ",
        "latitude": "56.700599670410156",
        "longitude": "47.904701232910156",
        "altitude": "348",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "CSY": {
        "name": "Cheboksary Airport",
        "city": "Cheboksary",
        "country": "Russia",
        "iata": "CSY",
        "icao": "UWKS",
        "latitude": "56.090301513671875",
        "longitude": "47.3473014831543",
        "altitude": "558",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ULY": {
        "name": "Ulyanovsk East Airport",
        "city": "Ulyanovsk",
        "country": "Russia",
        "iata": "ULY",
        "icao": "UWLW",
        "latitude": "54.4010009765625",
        "longitude": "48.80270004272461",
        "altitude": "252",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Europe/Samara"
    },
    "OSW": {
        "name": "Orsk Airport",
        "city": "Orsk",
        "country": "Russia",
        "iata": "OSW",
        "icao": "UWOR",
        "latitude": "51.0724983215332",
        "longitude": "58.59560012817383",
        "altitude": "909",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "PEZ": {
        "name": "Penza Airport",
        "city": "Penza",
        "country": "Russia",
        "iata": "PEZ",
        "icao": "UWPP",
        "latitude": "53.110599517822266",
        "longitude": "45.02109909057617",
        "altitude": "614",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "SKX": {
        "name": "Saransk Airport",
        "city": "Saransk",
        "country": "Russia",
        "iata": "SKX",
        "icao": "UWPS",
        "latitude": "54.12512969970703",
        "longitude": "45.212257385253906",
        "altitude": "676",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "BWO": {
        "name": "Balakovo Airport",
        "city": "Balakovo",
        "country": "Russia",
        "iata": "BWO",
        "icao": "UWSB",
        "latitude": "51.8582992554",
        "longitude": "47.7456016541",
        "altitude": "95",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "HBX": {
        "name": "Hubli Airport",
        "city": "Hubli",
        "country": "India",
        "iata": "HBX",
        "icao": "VAHB",
        "latitude": "15.361700058",
        "longitude": "75.08489990230001",
        "altitude": "2171",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "KCT": {
        "name": "Koggala Airport",
        "city": "Koggala",
        "country": "Sri Lanka",
        "iata": "KCT",
        "icao": "VCCK",
        "latitude": "5.993680000305176",
        "longitude": "80.32029724121094",
        "altitude": "10",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "WRZ": {
        "name": "Weerawila Airport",
        "city": "Wirawila",
        "country": "Sri Lanka",
        "iata": "WRZ",
        "icao": "VCCW",
        "latitude": "6.25448989868",
        "longitude": "81.23519897460001",
        "altitude": "50",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Colombo"
    },
    "BBM": {
        "name": "Battambang Airport",
        "city": "Battambang",
        "country": "Cambodia",
        "iata": "BBM",
        "icao": "VDBG",
        "latitude": "13.095600128173828",
        "longitude": "103.2239990234375",
        "altitude": "59",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Phnom_Penh"
    },
    "SHL": {
        "name": "Shillong Airport",
        "city": "Shillong",
        "country": "India",
        "iata": "SHL",
        "icao": "VEBI",
        "latitude": "25.70359992980957",
        "longitude": "91.97869873046875",
        "altitude": "2910",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "GAU": {
        "name": "Lokpriya Gopinath Bordoloi International Airport",
        "city": "Guwahati",
        "country": "India",
        "iata": "GAU",
        "icao": "VEGT",
        "latitude": "26.10610008239746",
        "longitude": "91.58589935302734",
        "altitude": "162",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "DMU": {
        "name": "Dimapur Airport",
        "city": "Dimapur",
        "country": "India",
        "iata": "DMU",
        "icao": "VEMR",
        "latitude": "25.883899688699998",
        "longitude": "93.77110290530001",
        "altitude": "487",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "TEZ": {
        "name": "Tezpur Airport",
        "city": "Tezpur",
        "country": "India",
        "iata": "TEZ",
        "icao": "VETZ",
        "latitude": "26.7091007232666",
        "longitude": "92.78469848632812",
        "altitude": "240",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "BZL": {
        "name": "Barisal Airport",
        "city": "Barisal",
        "country": "Bangladesh",
        "iata": "BZL",
        "icao": "VGBR",
        "latitude": "22.801000595092773",
        "longitude": "90.30120086669922",
        "altitude": "23",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Dhaka"
    },
    "HOE": {
        "name": "Ban Huoeisay Airport",
        "city": "Huay Xai",
        "country": "Laos",
        "iata": "HOE",
        "icao": "VLHS",
        "latitude": "20.2572994232",
        "longitude": "100.43699646",
        "altitude": "1380",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Vientiane"
    },
    "BHR": {
        "name": "Bharatpur Airport",
        "city": "Bharatpur",
        "country": "Nepal",
        "iata": "BHR",
        "icao": "VNBP",
        "latitude": "27.678101",
        "longitude": "84.429398",
        "altitude": "600",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "BDP": {
        "name": "Bhadrapur Airport",
        "city": "Chandragarhi",
        "country": "Nepal",
        "iata": "BDP",
        "icao": "VNCG",
        "latitude": "26.5708007812",
        "longitude": "88.07959747310001",
        "altitude": "300",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "MEY": {
        "name": "Meghauli Airport",
        "city": "Meghauli",
        "country": "Nepal",
        "iata": "MEY",
        "icao": "VNMG",
        "latitude": "27.5774",
        "longitude": "84.22875",
        "altitude": "600",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "KEP": {
        "name": "Nepalgunj Airport",
        "city": "Nepalgunj",
        "country": "Nepal",
        "iata": "KEP",
        "icao": "VNNG",
        "latitude": "28.103599548339844",
        "longitude": "81.66699981689453",
        "altitude": "540",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "GAN": {
        "name": "Gan International Airport",
        "city": "Gan Island",
        "country": "Maldives",
        "iata": "GAN",
        "icao": "VRMG",
        "latitude": "-0.693342",
        "longitude": "73.155602",
        "altitude": "6",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "HAQ": {
        "name": "Hanimaadhoo Airport",
        "city": "Haa Dhaalu Atoll",
        "country": "Maldives",
        "iata": "HAQ",
        "icao": "VRMH",
        "latitude": "6.744229793548584",
        "longitude": "73.17050170898438",
        "altitude": "4",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "KDO": {
        "name": "Kadhdhoo Airport",
        "city": "Laamu Atoll",
        "country": "Maldives",
        "iata": "KDO",
        "icao": "VRMK",
        "latitude": "1.8591699600219727",
        "longitude": "73.52189636230469",
        "altitude": "4",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "MAQ": {
        "name": "Mae Sot Airport",
        "city": "Tak",
        "country": "Thailand",
        "iata": "MAQ",
        "icao": "VTPM",
        "latitude": "16.699899673461914",
        "longitude": "98.54509735107422",
        "altitude": "690",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Bangkok"
    },
    "BMV": {
        "name": "Buon Ma Thuot Airport",
        "city": "Buonmethuot",
        "country": "Vietnam",
        "iata": "BMV",
        "icao": "VVBM",
        "latitude": "12.668299675",
        "longitude": "108.120002747",
        "altitude": "1729",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "HPH": {
        "name": "Cat Bi International Airport",
        "city": "Haiphong",
        "country": "Vietnam",
        "iata": "HPH",
        "icao": "VVCI",
        "latitude": "20.819400787353516",
        "longitude": "106.7249984741211",
        "altitude": "6",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "CXR": {
        "name": "Cam Ranh Airport",
        "city": "Nha Trang",
        "country": "Vietnam",
        "iata": "CXR",
        "icao": "VVCR",
        "latitude": "11.998200416564941",
        "longitude": "109.21900177001953",
        "altitude": "40",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VCS": {
        "name": "Co Ong Airport",
        "city": "Conson",
        "country": "Vietnam",
        "iata": "VCS",
        "icao": "VVCS",
        "latitude": "8.73183",
        "longitude": "106.633003",
        "altitude": "20",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VCA": {
        "name": "Can Tho International Airport",
        "city": "Can Tho",
        "country": "Vietnam",
        "iata": "VCA",
        "icao": "VVCT",
        "latitude": "10.085100174",
        "longitude": "105.711997986",
        "altitude": "9",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "DIN": {
        "name": "Dien Bien Phu Airport",
        "city": "Dienbienphu",
        "country": "Vietnam",
        "iata": "DIN",
        "icao": "VVDB",
        "latitude": "21.3974990845",
        "longitude": "103.008003235",
        "altitude": "1611",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "UIH": {
        "name": "Phu Cat Airport",
        "city": "Phucat",
        "country": "Vietnam",
        "iata": "UIH",
        "icao": "VVPC",
        "latitude": "13.955",
        "longitude": "109.042",
        "altitude": "80",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "PXU": {
        "name": "Pleiku Airport",
        "city": "Pleiku",
        "country": "Vietnam",
        "iata": "PXU",
        "icao": "VVPK",
        "latitude": "14.004500389099121",
        "longitude": "108.01699829101562",
        "altitude": "2434",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "VII": {
        "name": "Vinh Airport",
        "city": "Vinh",
        "country": "Vietnam",
        "iata": "VII",
        "icao": "VVVH",
        "latitude": "18.7376003265",
        "longitude": "105.67099762",
        "altitude": "23",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Saigon"
    },
    "BMO": {
        "name": "Banmaw Airport",
        "city": "Banmaw",
        "country": "Burma",
        "iata": "BMO",
        "icao": "VYBM",
        "latitude": "24.268999099731445",
        "longitude": "97.24620056152344",
        "altitude": "370",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "TVY": {
        "name": "Dawei Airport",
        "city": "Dawei",
        "country": "Burma",
        "iata": "TVY",
        "icao": "VYDW",
        "latitude": "14.103899955749512",
        "longitude": "98.20359802246094",
        "altitude": "84",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "KAW": {
        "name": "Kawthoung Airport",
        "city": "Kawthoung",
        "country": "Burma",
        "iata": "KAW",
        "icao": "VYKT",
        "latitude": "10.049300193786621",
        "longitude": "98.53800201416016",
        "altitude": "180",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "LIW": {
        "name": "Loikaw Airport",
        "city": "Loikaw",
        "country": "Burma",
        "iata": "LIW",
        "icao": "VYLK",
        "latitude": "19.691499710083008",
        "longitude": "97.21479797363281",
        "altitude": "2940",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "MNU": {
        "name": "Mawlamyine Airport",
        "city": "Mawlamyine",
        "country": "Burma",
        "iata": "MNU",
        "icao": "VYMM",
        "latitude": "16.444700241088867",
        "longitude": "97.66069793701172",
        "altitude": "52",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "BSX": {
        "name": "Pathein Airport",
        "city": "Pathein",
        "country": "Burma",
        "iata": "BSX",
        "icao": "VYPN",
        "latitude": "16.815201",
        "longitude": "94.7799",
        "altitude": "20",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "PKK": {
        "name": "Pakhokku Airport",
        "city": "Pakhokku",
        "country": "Burma",
        "iata": "PKK",
        "icao": "VYPU",
        "latitude": "21.4043",
        "longitude": "95.11125",
        "altitude": "151",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "SWQ": {
        "name": "Sumbawa Besar Airport",
        "city": "Sumbawa Island",
        "country": "Indonesia",
        "iata": "SWQ",
        "icao": "WADS",
        "latitude": "-8.48904037475586",
        "longitude": "117.41200256347656",
        "altitude": "16",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "TMC": {
        "name": "Tambolaka Airport",
        "city": "Waikabubak-Sumba Island",
        "country": "Indonesia",
        "iata": "TMC",
        "icao": "WADT",
        "latitude": "-9.40972",
        "longitude": "119.244003",
        "altitude": "161",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BUI": {
        "name": "Bokondini Airport",
        "city": "Bokondini-Papua Island",
        "country": "Indonesia",
        "iata": "BUI",
        "icao": "WAJB",
        "latitude": "-3.6822",
        "longitude": "138.6755",
        "altitude": "4550",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "SEH": {
        "name": "Senggeh Airport",
        "city": "Senggeh-Papua Island",
        "country": "Indonesia",
        "iata": "SEH",
        "icao": "WAJS",
        "latitude": "-3.45",
        "longitude": "140.779",
        "altitude": "914",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "TJS": {
        "name": "Tanjung Harapan Airport",
        "city": "Tanjung Selor-Borneo Island",
        "country": "Indonesia",
        "iata": "TJS",
        "icao": "WALG",
        "latitude": "2.83583333333",
        "longitude": "117.373611111",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "DTD": {
        "name": "Datadawai Airport",
        "city": "Datadawai-Borneo Island",
        "country": "Indonesia",
        "iata": "DTD",
        "icao": "WALJ",
        "latitude": "0.8106",
        "longitude": "114.5306",
        "altitude": "508",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BEJ": {
        "name": "Kalimarau Airport",
        "city": "Tanjung Redep-Borneo Island",
        "country": "Indonesia",
        "iata": "BEJ",
        "icao": "WALK",
        "latitude": "2.1555",
        "longitude": "117.431999",
        "altitude": "59",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "TJG": {
        "name": "Warukin Airport",
        "city": "Tanjung-Borneo Island",
        "country": "Indonesia",
        "iata": "TJG",
        "icao": "WAON",
        "latitude": "-2.21655988693",
        "longitude": "115.435997009",
        "altitude": "197",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "SMQ": {
        "name": "Sampit(Hasan) Airport",
        "city": "Sampit-Borneo Island",
        "country": "Indonesia",
        "iata": "SMQ",
        "icao": "WAOS",
        "latitude": "-2.49919009209",
        "longitude": "112.974998474",
        "altitude": "50",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "LUV": {
        "name": "Dumatumbun Airport",
        "city": "Langgur-Kei Islands",
        "country": "Indonesia",
        "iata": "LUV",
        "icao": "WAPL",
        "latitude": "-5.661620140075684",
        "longitude": "132.7310028076172",
        "altitude": "10",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "ARD": {
        "name": "Mali Airport",
        "city": "Alor Island",
        "country": "Indonesia",
        "iata": "ARD",
        "icao": "WATM",
        "latitude": "-8.132340431213379",
        "longitude": "124.59700012207031",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "BLG": {
        "name": "Belaga Airport",
        "city": "Belaga",
        "country": "Malaysia",
        "iata": "BLG",
        "icao": "WBGC",
        "latitude": "2.65000009537",
        "longitude": "113.766998291",
        "altitude": "200",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LGL": {
        "name": "Long Lellang Airport",
        "city": "Long Datih",
        "country": "Malaysia",
        "iata": "LGL",
        "icao": "WBGF",
        "latitude": "3.4210000038099997",
        "longitude": "115.153999329",
        "altitude": "1400",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "ODN": {
        "name": "Long Seridan Airport",
        "city": "Long Seridan",
        "country": "Malaysia",
        "iata": "ODN",
        "icao": "WBGI",
        "latitude": "3.9670000076293945",
        "longitude": "115.05000305175781",
        "altitude": "607",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "MKM": {
        "name": "Mukah Airport",
        "city": "Mukah",
        "country": "Malaysia",
        "iata": "MKM",
        "icao": "WBGK",
        "latitude": "2.90639",
        "longitude": "112.080002",
        "altitude": "13",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "BKM": {
        "name": "Bakalalan Airport",
        "city": "Bakalalan",
        "country": "Malaysia",
        "iata": "BKM",
        "icao": "WBGQ",
        "latitude": "3.9739999771118164",
        "longitude": "115.61799621582031",
        "altitude": "2900",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LWY": {
        "name": "Lawas Airport",
        "city": "Lawas",
        "country": "Malaysia",
        "iata": "LWY",
        "icao": "WBGW",
        "latitude": "4.84917",
        "longitude": "115.407997",
        "altitude": "5",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "BBN": {
        "name": "Bario Airport",
        "city": "Bario",
        "country": "Malaysia",
        "iata": "BBN",
        "icao": "WBGZ",
        "latitude": "3.7338900566101074",
        "longitude": "115.47899627685547",
        "altitude": "3350",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "TMG": {
        "name": "Tomanggong Airport",
        "city": "Tomanggong",
        "country": "Malaysia",
        "iata": "TMG",
        "icao": "WBKM",
        "latitude": "5.40257",
        "longitude": "118.65763",
        "altitude": "26",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "KUD": {
        "name": "Kudat Airport",
        "city": "Kudat",
        "country": "Malaysia",
        "iata": "KUD",
        "icao": "WBKT",
        "latitude": "6.922500133514404",
        "longitude": "116.83599853515625",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "TKG": {
        "name": "Radin Inten II (Branti) Airport",
        "city": "Bandar Lampung-Sumatra Island",
        "country": "Indonesia",
        "iata": "TKG",
        "icao": "WIAT",
        "latitude": "-5.240556",
        "longitude": "105.175556",
        "altitude": "282",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "HLP": {
        "name": "Halim Perdanakusuma International Airport",
        "city": "Jakarta",
        "country": "Indonesia",
        "iata": "HLP",
        "icao": "WIHH",
        "latitude": "-6.266610145568848",
        "longitude": "106.89099884033203",
        "altitude": "84",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "NTX": {
        "name": "Ranai Airport",
        "city": "Ranai-Natuna Besar Island",
        "country": "Indonesia",
        "iata": "NTX",
        "icao": "WION",
        "latitude": "3.90871",
        "longitude": "108.388",
        "altitude": "7",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PSU": {
        "name": "Pangsuma Airport",
        "city": "Putussibau-Borneo Island",
        "country": "Indonesia",
        "iata": "PSU",
        "icao": "WIOP",
        "latitude": "0.8355780243873596",
        "longitude": "112.93699645996094",
        "altitude": "297",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "SQG": {
        "name": "Sintang(Susilo) Airport",
        "city": "Sintang-Borneo Island",
        "country": "Indonesia",
        "iata": "SQG",
        "icao": "WIOS",
        "latitude": "0.06361900269985199",
        "longitude": "111.4729995727539",
        "altitude": "98",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PDO": {
        "name": "Pendopo Airport",
        "city": "Talang Gudang-Sumatra Island",
        "country": "Indonesia",
        "iata": "PDO",
        "icao": "WIPQ",
        "latitude": "-3.2860701084136963",
        "longitude": "103.87999725341797",
        "altitude": "184",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "LSW": {
        "name": "Malikus Saleh Airport",
        "city": "Lhok Seumawe-Sumatra Island",
        "country": "Indonesia",
        "iata": "LSW",
        "icao": "WITM",
        "latitude": "5.226679801940918",
        "longitude": "96.95030212402344",
        "altitude": "90",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PKG": {
        "name": "Pulau Pangkor Airport",
        "city": "Pangkor Island",
        "country": "Malaysia",
        "iata": "PKG",
        "icao": "WMPA",
        "latitude": "4.244719982147217",
        "longitude": "100.5530014038086",
        "altitude": "19",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "LBW": {
        "name": "Long Bawan Airport",
        "city": "Long Bawan-Borneo Island",
        "country": "Indonesia",
        "iata": "LBW",
        "icao": "WRLB",
        "latitude": "3.9028",
        "longitude": "115.6921",
        "altitude": "3165",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "NNX": {
        "name": "Nunukan Airport",
        "city": "Nunukan-Nunukan Island",
        "country": "Indonesia",
        "iata": "NNX",
        "icao": "WRLF",
        "latitude": "4.13333333333",
        "longitude": "117.666666667",
        "altitude": "30",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "LPU": {
        "name": "Long Apung Airport",
        "city": "Long Apung-Borneo Island",
        "country": "Indonesia",
        "iata": "LPU",
        "icao": "WRLP",
        "latitude": "1.704486",
        "longitude": "114.970297",
        "altitude": "627",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "ALH": {
        "name": "Albany Airport",
        "city": "Albany",
        "country": "Australia",
        "iata": "ALH",
        "icao": "YABA",
        "latitude": "-34.94329833984375",
        "longitude": "117.80899810791016",
        "altitude": "233",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "GYL": {
        "name": "Argyle Airport",
        "city": "Argyle",
        "country": "Australia",
        "iata": "GYL",
        "icao": "YARG",
        "latitude": "-16.6369",
        "longitude": "128.451004",
        "altitude": "522",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "AUU": {
        "name": "Aurukun Airport",
        "city": "Aurukun",
        "country": "Australia",
        "iata": "AUU",
        "icao": "YAUR",
        "latitude": "-13.354067",
        "longitude": "141.72065",
        "altitude": "31",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BCI": {
        "name": "Barcaldine Airport",
        "city": "Barcaldine",
        "country": "Australia",
        "iata": "BCI",
        "icao": "YBAR",
        "latitude": "-23.5652999878",
        "longitude": "145.307006836",
        "altitude": "878",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BDD": {
        "name": "Badu Island Airport",
        "city": "Badu Island",
        "country": "Australia",
        "iata": "BDD",
        "icao": "YBAU",
        "latitude": "-10.149999618499999",
        "longitude": "142.1734",
        "altitude": "14",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BVI": {
        "name": "Birdsville Airport",
        "city": "Birdsville",
        "country": "Australia",
        "iata": "BVI",
        "icao": "YBDV",
        "latitude": "-25.897499084472656",
        "longitude": "139.34800720214844",
        "altitude": "159",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BHQ": {
        "name": "Broken Hill Airport",
        "city": "Broken Hill",
        "country": "Australia",
        "iata": "BHQ",
        "icao": "YBHI",
        "latitude": "-32.0013999939",
        "longitude": "141.472000122",
        "altitude": "958",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "HTI": {
        "name": "Hamilton Island Airport",
        "city": "Hamilton Island",
        "country": "Australia",
        "iata": "HTI",
        "icao": "YBHM",
        "latitude": "-20.3581008911",
        "longitude": "148.95199585",
        "altitude": "15",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BEU": {
        "name": "Bedourie Airport",
        "city": "Bedourie",
        "country": "Australia",
        "iata": "BEU",
        "icao": "YBIE",
        "latitude": "-24.346099853515625",
        "longitude": "139.4600067138672",
        "altitude": "300",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BRK": {
        "name": "Bourke Airport",
        "city": "Bourke",
        "country": "Australia",
        "iata": "BRK",
        "icao": "YBKE",
        "latitude": "-30.039199829101562",
        "longitude": "145.95199584960938",
        "altitude": "352",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "BUC": {
        "name": "Burketown Airport",
        "city": "Burketown",
        "country": "Australia",
        "iata": "BUC",
        "icao": "YBKT",
        "latitude": "-17.748600006103516",
        "longitude": "139.53399658203125",
        "altitude": "21",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "GIC": {
        "name": "Boigu Airport",
        "city": "Boigu",
        "country": "Australia",
        "iata": "GIC",
        "icao": "YBOI",
        "latitude": "-9.23278045654",
        "longitude": "142.218002319",
        "altitude": "23",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "OKY": {
        "name": "Oakey Airport",
        "city": "Oakey",
        "country": "Australia",
        "iata": "OKY",
        "icao": "YBOK",
        "latitude": "-27.411399841308594",
        "longitude": "151.73500061035156",
        "altitude": "1335",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BQL": {
        "name": "Boulia Airport",
        "city": "Boulia",
        "country": "Australia",
        "iata": "BQL",
        "icao": "YBOU",
        "latitude": "-22.913299560546875",
        "longitude": "139.89999389648438",
        "altitude": "542",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BHS": {
        "name": "Bathurst Airport",
        "city": "Bathurst",
        "country": "Australia",
        "iata": "BHS",
        "icao": "YBTH",
        "latitude": "-33.4094009399",
        "longitude": "149.651992798",
        "altitude": "2435",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "BLT": {
        "name": "Blackwater Airport",
        "city": "Blackwater",
        "country": "Australia",
        "iata": "BLT",
        "icao": "YBTR",
        "latitude": "-23.603099822998047",
        "longitude": "148.8070068359375",
        "altitude": "657",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "CVQ": {
        "name": "Carnarvon Airport",
        "city": "Carnarvon",
        "country": "Australia",
        "iata": "CVQ",
        "icao": "YCAR",
        "latitude": "-24.880211",
        "longitude": "113.67174",
        "altitude": "13",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "CAZ": {
        "name": "Cobar Airport",
        "city": "Cobar",
        "country": "Australia",
        "iata": "CAZ",
        "icao": "YCBA",
        "latitude": "-31.538299560546875",
        "longitude": "145.79400634765625",
        "altitude": "724",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CPD": {
        "name": "Coober Pedy Airport",
        "city": "Coober Pedy",
        "country": "Australia",
        "iata": "CPD",
        "icao": "YCBP",
        "latitude": "-29.040000915527344",
        "longitude": "134.7209930419922",
        "altitude": "740",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "CNC": {
        "name": "Coconut Island Airport",
        "city": "Coconut Island",
        "country": "Australia",
        "iata": "CNC",
        "icao": "YCCT",
        "latitude": "-10.050000190734863",
        "longitude": "143.07000732421875",
        "altitude": "3",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "CNJ": {
        "name": "Cloncurry Airport",
        "city": "Cloncurry",
        "country": "Australia",
        "iata": "CNJ",
        "icao": "YCCY",
        "latitude": "-20.668600082399998",
        "longitude": "140.503997803",
        "altitude": "616",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "CED": {
        "name": "Ceduna Airport",
        "city": "Ceduna",
        "country": "Australia",
        "iata": "CED",
        "icao": "YCDU",
        "latitude": "-32.13059997558594",
        "longitude": "133.7100067138672",
        "altitude": "77",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "CTN": {
        "name": "Cooktown Airport",
        "city": "Cooktown",
        "country": "Australia",
        "iata": "CTN",
        "icao": "YCKN",
        "latitude": "-15.444700241088867",
        "longitude": "145.1840057373047",
        "altitude": "26",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "CMA": {
        "name": "Cunnamulla Airport",
        "city": "Cunnamulla",
        "country": "Australia",
        "iata": "CMA",
        "icao": "YCMU",
        "latitude": "-28.030000686645508",
        "longitude": "145.6219940185547",
        "altitude": "630",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "CNB": {
        "name": "Coonamble Airport",
        "city": "Coonamble",
        "country": "Australia",
        "iata": "CNB",
        "icao": "YCNM",
        "latitude": "-30.983299255371094",
        "longitude": "148.37600708007812",
        "altitude": "604",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CUQ": {
        "name": "Coen Airport",
        "city": "Coen",
        "country": "Australia",
        "iata": "CUQ",
        "icao": "YCOE",
        "latitude": "-13.761133",
        "longitude": "143.113311",
        "altitude": "532",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "OOM": {
        "name": "Cooma Snowy Mountains Airport",
        "city": "Cooma",
        "country": "Australia",
        "iata": "OOM",
        "icao": "YCOM",
        "latitude": "-36.3005981445",
        "longitude": "148.973999023",
        "altitude": "3088",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "DMD": {
        "name": "Doomadgee Airport",
        "city": "Doomadgee",
        "country": "Australia",
        "iata": "DMD",
        "icao": "YDMG",
        "latitude": "-17.9403",
        "longitude": "138.822006",
        "altitude": "153",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "NLF": {
        "name": "Darnley Island Airport",
        "city": "Darnley Island",
        "country": "Australia",
        "iata": "NLF",
        "icao": "YDNI",
        "latitude": "-9.583330154418945",
        "longitude": "143.76699829101562",
        "altitude": "0",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "DPO": {
        "name": "Devonport Airport",
        "city": "Devonport",
        "country": "Australia",
        "iata": "DPO",
        "icao": "YDPO",
        "latitude": "-41.1697006226",
        "longitude": "146.429992676",
        "altitude": "33",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "ELC": {
        "name": "Elcho Island Airport",
        "city": "Elcho Island",
        "country": "Australia",
        "iata": "ELC",
        "icao": "YELD",
        "latitude": "-12.019399642899998",
        "longitude": "135.570999146",
        "altitude": "101",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "EPR": {
        "name": "Esperance Airport",
        "city": "Esperance",
        "country": "Australia",
        "iata": "EPR",
        "icao": "YESP",
        "latitude": "-33.684399",
        "longitude": "121.822998",
        "altitude": "470",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "FLS": {
        "name": "Flinders Island Airport",
        "city": "Flinders Island",
        "country": "Australia",
        "iata": "FLS",
        "icao": "YFLI",
        "latitude": "-40.0917015076",
        "longitude": "147.992996216",
        "altitude": "10",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "GET": {
        "name": "Geraldton Airport",
        "city": "Geraldton",
        "country": "Australia",
        "iata": "GET",
        "icao": "YGEL",
        "latitude": "-28.796101",
        "longitude": "114.707001",
        "altitude": "121",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "GLT": {
        "name": "Gladstone Airport",
        "city": "Gladstone",
        "country": "Australia",
        "iata": "GLT",
        "icao": "YGLA",
        "latitude": "-23.869699",
        "longitude": "151.223007",
        "altitude": "64",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "GTE": {
        "name": "Groote Eylandt Airport",
        "city": "Groote Eylandt",
        "country": "Australia",
        "iata": "GTE",
        "icao": "YGTE",
        "latitude": "-13.975000381500001",
        "longitude": "136.460006714",
        "altitude": "53",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "GFF": {
        "name": "Griffith Airport",
        "city": "Griffith",
        "country": "Australia",
        "iata": "GFF",
        "icao": "YGTH",
        "latitude": "-34.2508010864",
        "longitude": "146.067001343",
        "altitude": "439",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "HID": {
        "name": "Horn Island Airport",
        "city": "Horn Island",
        "country": "Australia",
        "iata": "HID",
        "icao": "YHID",
        "latitude": "-10.586400032",
        "longitude": "142.289993286",
        "altitude": "43",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "HOK": {
        "name": "Hooker Creek Airport",
        "city": "Hooker Creek",
        "country": "Australia",
        "iata": "HOK",
        "icao": "YHOO",
        "latitude": "-18.3367004395",
        "longitude": "130.638000488",
        "altitude": "320",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "MHU": {
        "name": "Mount Hotham Airport",
        "city": "Mount Hotham",
        "country": "Australia",
        "iata": "MHU",
        "icao": "YHOT",
        "latitude": "-37.0475006104",
        "longitude": "147.333999634",
        "altitude": "4260",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "HGD": {
        "name": "Hughenden Airport",
        "city": "Hughenden",
        "country": "Australia",
        "iata": "HGD",
        "icao": "YHUG",
        "latitude": "-20.815000534057617",
        "longitude": "144.22500610351562",
        "altitude": "1043",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "JCK": {
        "name": "Julia Creek Airport",
        "city": "Julia Creek",
        "country": "Australia",
        "iata": "JCK",
        "icao": "YJLC",
        "latitude": "-20.66830062866211",
        "longitude": "141.72300720214844",
        "altitude": "404",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "KAX": {
        "name": "Kalbarri Airport",
        "city": "Kalbarri",
        "country": "Australia",
        "iata": "KAX",
        "icao": "YKBR",
        "latitude": "-27.692813",
        "longitude": "114.259169",
        "altitude": "157",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "KNS": {
        "name": "King Island Airport",
        "city": "King Island",
        "country": "Australia",
        "iata": "KNS",
        "icao": "YKII",
        "latitude": "-39.877498626708984",
        "longitude": "143.8780059814453",
        "altitude": "132",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "KFG": {
        "name": "Kalkgurung Airport",
        "city": "Kalkgurung",
        "country": "Australia",
        "iata": "KFG",
        "icao": "YKKG",
        "latitude": "-17.431900024414062",
        "longitude": "130.80799865722656",
        "altitude": "646",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "KRB": {
        "name": "Karumba Airport",
        "city": "Karumba",
        "country": "Australia",
        "iata": "KRB",
        "icao": "YKMB",
        "latitude": "-17.45669937133789",
        "longitude": "140.8300018310547",
        "altitude": "5",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "KWM": {
        "name": "Kowanyama Airport",
        "city": "Kowanyama",
        "country": "Australia",
        "iata": "KWM",
        "icao": "YKOW",
        "latitude": "-15.485600471496582",
        "longitude": "141.75100708007812",
        "altitude": "35",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "KUG": {
        "name": "Kubin Airport",
        "city": "Kubin",
        "country": "Australia",
        "iata": "KUG",
        "icao": "YKUB",
        "latitude": "-10.225000381500001",
        "longitude": "142.218002319",
        "altitude": "15",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "LNO": {
        "name": "Leonora Airport",
        "city": "Leonora",
        "country": "Australia",
        "iata": "LNO",
        "icao": "YLEO",
        "latitude": "-28.87809944152832",
        "longitude": "121.31500244140625",
        "altitude": "1217",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "LEL": {
        "name": "Lake Evella Airport",
        "city": "Lake Evella",
        "country": "Australia",
        "iata": "LEL",
        "icao": "YLEV",
        "latitude": "-12.498900413513184",
        "longitude": "135.80599975585938",
        "altitude": "256",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "LDH": {
        "name": "Lord Howe Island Airport",
        "city": "Lord Howe Island",
        "country": "Australia",
        "iata": "LDH",
        "icao": "YLHI",
        "latitude": "-31.5382995605",
        "longitude": "159.07699585",
        "altitude": "5",
        "timezone": "10.5",
        "dst": "O",
        "tz_name": "Australia/Lord_Howe"
    },
    "IRG": {
        "name": "Lockhart River Airport",
        "city": "Lockhart River",
        "country": "Australia",
        "iata": "IRG",
        "icao": "YLHR",
        "latitude": "-12.7869",
        "longitude": "143.304993",
        "altitude": "77",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "LSY": {
        "name": "Lismore Airport",
        "city": "Lismore",
        "country": "Australia",
        "iata": "LSY",
        "icao": "YLIS",
        "latitude": "-28.8302993774",
        "longitude": "153.259994507",
        "altitude": "35",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "LHG": {
        "name": "Lightning Ridge Airport",
        "city": "Lightning Ridge",
        "country": "Australia",
        "iata": "LHG",
        "icao": "YLRD",
        "latitude": "-29.45669937133789",
        "longitude": "147.98399353027344",
        "altitude": "540",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "LRE": {
        "name": "Longreach Airport",
        "city": "Longreach",
        "country": "Australia",
        "iata": "LRE",
        "icao": "YLRE",
        "latitude": "-23.4342002869",
        "longitude": "144.279998779",
        "altitude": "627",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "LER": {
        "name": "Leinster Airport",
        "city": "Leinster",
        "country": "Australia",
        "iata": "LER",
        "icao": "YLST",
        "latitude": "-27.843299865722656",
        "longitude": "120.7030029296875",
        "altitude": "1631",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "LVO": {
        "name": "Laverton Airport",
        "city": "Laverton",
        "country": "Australia",
        "iata": "LVO",
        "icao": "YLTN",
        "latitude": "-28.61359977722168",
        "longitude": "122.42400360107422",
        "altitude": "1530",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "UBB": {
        "name": "Mabuiag Island Airport",
        "city": "Mabuiag Island",
        "country": "Australia",
        "iata": "UBB",
        "icao": "YMAA",
        "latitude": "-9.949999809265137",
        "longitude": "142.18299865722656",
        "altitude": "0",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MKR": {
        "name": "Meekatharra Airport",
        "city": "Meekatharra",
        "country": "Australia",
        "iata": "MKR",
        "icao": "YMEK",
        "latitude": "-26.6117000579834",
        "longitude": "118.5479965209961",
        "altitude": "1713",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "MIM": {
        "name": "Merimbula Airport",
        "city": "Merimbula",
        "country": "Australia",
        "iata": "MIM",
        "icao": "YMER",
        "latitude": "-36.9085998535",
        "longitude": "149.901000977",
        "altitude": "7",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "MGT": {
        "name": "Milingimbi Airport",
        "city": "Milingimbi",
        "country": "Australia",
        "iata": "MGT",
        "icao": "YMGB",
        "latitude": "-12.0944004059",
        "longitude": "134.893997192",
        "altitude": "53",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "MNG": {
        "name": "Maningrida Airport",
        "city": "Maningrida",
        "country": "Australia",
        "iata": "MNG",
        "icao": "YMGD",
        "latitude": "-12.0560998917",
        "longitude": "134.23399353",
        "altitude": "123",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "MCV": {
        "name": "McArthur River Mine Airport",
        "city": "McArthur River Mine",
        "country": "Australia",
        "iata": "MCV",
        "icao": "YMHU",
        "latitude": "-16.4424991608",
        "longitude": "136.083999634",
        "altitude": "131",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "MQL": {
        "name": "Mildura Airport",
        "city": "Mildura",
        "country": "Australia",
        "iata": "MQL",
        "icao": "YMIA",
        "latitude": "-34.229198455799995",
        "longitude": "142.085998535",
        "altitude": "167",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "MMG": {
        "name": "Mount Magnet Airport",
        "city": "Mount Magnet",
        "country": "Australia",
        "iata": "MMG",
        "icao": "YMOG",
        "latitude": "-28.116100311279297",
        "longitude": "117.84200286865234",
        "altitude": "1354",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "MRZ": {
        "name": "Moree Airport",
        "city": "Moree",
        "country": "Australia",
        "iata": "MRZ",
        "icao": "YMOR",
        "latitude": "-29.498899459799997",
        "longitude": "149.845001221",
        "altitude": "701",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "MOV": {
        "name": "Moranbah Airport",
        "city": "Moranbah",
        "country": "Australia",
        "iata": "MOV",
        "icao": "YMRB",
        "latitude": "-22.057800293",
        "longitude": "148.07699585",
        "altitude": "770",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MYA": {
        "name": "Moruya Airport",
        "city": "Moruya",
        "country": "Australia",
        "iata": "MYA",
        "icao": "YMRY",
        "latitude": "-35.8978004456",
        "longitude": "150.143997192",
        "altitude": "14",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "MGB": {
        "name": "Mount Gambier Airport",
        "city": "Mount Gambier",
        "country": "Australia",
        "iata": "MGB",
        "icao": "YMTG",
        "latitude": "-37.745601654052734",
        "longitude": "140.78500366210938",
        "altitude": "212",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "ONG": {
        "name": "Mornington Island Airport",
        "city": "Mornington Island",
        "country": "Australia",
        "iata": "ONG",
        "icao": "YMTI",
        "latitude": "-16.662500381469727",
        "longitude": "139.17799377441406",
        "altitude": "33",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MYI": {
        "name": "Murray Island Airport",
        "city": "Murray Island",
        "country": "Australia",
        "iata": "MYI",
        "icao": "YMUI",
        "latitude": "-9.91666984558",
        "longitude": "144.054992676",
        "altitude": "300",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MBH": {
        "name": "Maryborough Airport",
        "city": "Maryborough",
        "country": "Australia",
        "iata": "MBH",
        "icao": "YMYB",
        "latitude": "-25.5133",
        "longitude": "152.714996",
        "altitude": "38",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "NRA": {
        "name": "Narrandera Airport",
        "city": "Narrandera",
        "country": "Australia",
        "iata": "NRA",
        "icao": "YNAR",
        "latitude": "-34.7022018433",
        "longitude": "146.511993408",
        "altitude": "474",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "NAA": {
        "name": "Narrabri Airport",
        "city": "Narrabri",
        "country": "Australia",
        "iata": "NAA",
        "icao": "YNBR",
        "latitude": "-30.3192005157",
        "longitude": "149.82699585",
        "altitude": "788",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "NTN": {
        "name": "Normanton Airport",
        "city": "Normanton",
        "country": "Australia",
        "iata": "NTN",
        "icao": "YNTN",
        "latitude": "-17.68409",
        "longitude": "141.069664",
        "altitude": "73",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "ZNE": {
        "name": "Newman Airport",
        "city": "Newman",
        "country": "Australia",
        "iata": "ZNE",
        "icao": "YNWN",
        "latitude": "-23.417800903299998",
        "longitude": "119.803001404",
        "altitude": "1724",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "OLP": {
        "name": "Olympic Dam Airport",
        "city": "Olympic Dam",
        "country": "Australia",
        "iata": "OLP",
        "icao": "YOLD",
        "latitude": "-30.485000610399997",
        "longitude": "136.876998901",
        "altitude": "343",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "PUG": {
        "name": "Port Augusta Airport",
        "city": "Argyle",
        "country": "Australia",
        "iata": "PUG",
        "icao": "YPAG",
        "latitude": "-32.506900787353516",
        "longitude": "137.7169952392578",
        "altitude": "56",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "PMK": {
        "name": "Palm Island Airport",
        "city": "Palm Island",
        "country": "Australia",
        "iata": "PMK",
        "icao": "YPAM",
        "latitude": "-18.755300521850586",
        "longitude": "146.58099365234375",
        "altitude": "28",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "PBO": {
        "name": "Paraburdoo Airport",
        "city": "Paraburdoo",
        "country": "Australia",
        "iata": "PBO",
        "icao": "YPBO",
        "latitude": "-23.1711006165",
        "longitude": "117.745002747",
        "altitude": "1406",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "CCK": {
        "name": "Cocos (Keeling) Islands Airport",
        "city": "Cocos Keeling Island",
        "country": "Cocos (Keeling) Islands",
        "iata": "CCK",
        "icao": "YPCC",
        "latitude": "-12.1883001328",
        "longitude": "96.8339004517",
        "altitude": "10",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Indian/Cocos"
    },
    "GOV": {
        "name": "Gove Airport",
        "city": "Gove",
        "country": "Australia",
        "iata": "GOV",
        "icao": "YPGV",
        "latitude": "-12.269399642899998",
        "longitude": "136.817993164",
        "altitude": "192",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "PKE": {
        "name": "Parkes Airport",
        "city": "Parkes",
        "country": "Australia",
        "iata": "PKE",
        "icao": "YPKS",
        "latitude": "-33.131401062",
        "longitude": "148.238998413",
        "altitude": "1069",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "PLO": {
        "name": "Port Lincoln Airport",
        "city": "Port Lincoln",
        "country": "Australia",
        "iata": "PLO",
        "icao": "YPLC",
        "latitude": "-34.6053009033",
        "longitude": "135.880004883",
        "altitude": "36",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "EDR": {
        "name": "Pormpuraaw Airport",
        "city": "Pormpuraaw",
        "country": "Australia",
        "iata": "EDR",
        "icao": "YPMP",
        "latitude": "-14.896451",
        "longitude": "141.60908",
        "altitude": "10",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "PQQ": {
        "name": "Port Macquarie Airport",
        "city": "Port Macquarie",
        "country": "Australia",
        "iata": "PQQ",
        "icao": "YPMQ",
        "latitude": "-31.4358005524",
        "longitude": "152.863006592",
        "altitude": "12",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "PTJ": {
        "name": "Portland Airport",
        "city": "Portland",
        "country": "Australia",
        "iata": "PTJ",
        "icao": "YPOD",
        "latitude": "-38.31809997558594",
        "longitude": "141.4709930419922",
        "altitude": "265",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "ULP": {
        "name": "Quilpie Airport",
        "city": "Quilpie",
        "country": "Australia",
        "iata": "ULP",
        "icao": "YQLP",
        "latitude": "-26.612199783325195",
        "longitude": "144.2530059814453",
        "altitude": "655",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "RAM": {
        "name": "Ramingining Airport",
        "city": "Ramingining",
        "country": "Australia",
        "iata": "RAM",
        "icao": "YRNG",
        "latitude": "-12.356399536132812",
        "longitude": "134.8979949951172",
        "altitude": "206",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "RMA": {
        "name": "Roma Airport",
        "city": "Roma",
        "country": "Australia",
        "iata": "RMA",
        "icao": "YROM",
        "latitude": "-26.545000076300003",
        "longitude": "148.774993896",
        "altitude": "1032",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "SGO": {
        "name": "St George Airport",
        "city": "St George",
        "country": "Australia",
        "iata": "SGO",
        "icao": "YSGE",
        "latitude": "-28.049699783325195",
        "longitude": "148.59500122070312",
        "altitude": "656",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MJK": {
        "name": "Shark Bay Airport",
        "city": "Shark Bay",
        "country": "Australia",
        "iata": "MJK",
        "icao": "YSHK",
        "latitude": "-25.8938999176",
        "longitude": "113.577003479",
        "altitude": "111",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "SBR": {
        "name": "Saibai Island Airport",
        "city": "Saibai Island",
        "country": "Australia",
        "iata": "SBR",
        "icao": "YSII",
        "latitude": "-9.378330230710002",
        "longitude": "142.625",
        "altitude": "15",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "SRN": {
        "name": "Strahan Airport",
        "city": "Strahan",
        "country": "Australia",
        "iata": "SRN",
        "icao": "YSRN",
        "latitude": "-42.154998779296875",
        "longitude": "145.29200744628906",
        "altitude": "20",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "XTG": {
        "name": "Thargomindah Airport",
        "city": "Thargomindah",
        "country": "Australia",
        "iata": "XTG",
        "icao": "YTGM",
        "latitude": "-27.986400604248047",
        "longitude": "143.81100463867188",
        "altitude": "433",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "TCA": {
        "name": "Tennant Creek Airport",
        "city": "Tennant Creek",
        "country": "Australia",
        "iata": "TCA",
        "icao": "YTNK",
        "latitude": "-19.6343994140625",
        "longitude": "134.18299865722656",
        "altitude": "1236",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "VCD": {
        "name": "Victoria River Downs Airport",
        "city": "Victoria River Downs",
        "country": "Australia",
        "iata": "VCD",
        "icao": "YVRD",
        "latitude": "-16.402124404907227",
        "longitude": "131.00497436523438",
        "altitude": "89",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "SYU": {
        "name": "Warraber Island Airport",
        "city": "Sue Islet",
        "country": "Australia",
        "iata": "SYU",
        "icao": "YWBS",
        "latitude": "-10.20829963684082",
        "longitude": "142.8249969482422",
        "altitude": "3",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "WNR": {
        "name": "Windorah Airport",
        "city": "Windorah",
        "country": "Australia",
        "iata": "WNR",
        "icao": "YWDH",
        "latitude": "-25.41309928894043",
        "longitude": "142.66700744628906",
        "altitude": "452",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "WYA": {
        "name": "Whyalla Airport",
        "city": "Whyalla",
        "country": "Australia",
        "iata": "WYA",
        "icao": "YWHA",
        "latitude": "-33.05889892578125",
        "longitude": "137.51400756835938",
        "altitude": "41",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "WUN": {
        "name": "Wiluna Airport",
        "city": "Wiluna",
        "country": "Australia",
        "iata": "WUN",
        "icao": "YWLU",
        "latitude": "-26.629199981689453",
        "longitude": "120.22100067138672",
        "altitude": "1649",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "WOL": {
        "name": "Wollongong Airport",
        "city": "Wollongong",
        "country": "Australia",
        "iata": "WOL",
        "icao": "YWOL",
        "latitude": "-34.561100006103516",
        "longitude": "150.78900146484375",
        "altitude": "31",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "WIN": {
        "name": "Winton Airport",
        "city": "Winton",
        "country": "Australia",
        "iata": "WIN",
        "icao": "YWTN",
        "latitude": "-22.36359977722168",
        "longitude": "143.08599853515625",
        "altitude": "638",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "BWT": {
        "name": "Wynyard Airport",
        "city": "Burnie",
        "country": "Australia",
        "iata": "BWT",
        "icao": "YWYY",
        "latitude": "-40.9989013671875",
        "longitude": "145.7310028076172",
        "altitude": "62",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Melbourne"
    },
    "OKR": {
        "name": "Yorke Island Airport",
        "city": "Yorke Island",
        "country": "Australia",
        "iata": "OKR",
        "icao": "YYKI",
        "latitude": "-9.752801",
        "longitude": "143.405673",
        "altitude": "10",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "XMY": {
        "name": "Yam Island Airport",
        "city": "Yam Island",
        "country": "Australia",
        "iata": "XMY",
        "icao": "YYMI",
        "latitude": "-9.90110969543457",
        "longitude": "142.7760009765625",
        "altitude": "0",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "NAY": {
        "name": "Beijing Nanyuan Airport",
        "city": "Beijing",
        "country": "China",
        "iata": "NAY",
        "icao": "ZBNY",
        "latitude": "39.782798767089844",
        "longitude": "116.38800048828125",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CIF": {
        "name": "Chifeng Airport",
        "city": "Chifeng",
        "country": "China",
        "iata": "CIF",
        "icao": "ZBCF",
        "latitude": "42.23500061035156",
        "longitude": "118.90799713134766",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CIH": {
        "name": "Changzhi Airport",
        "city": "Changzhi",
        "country": "China",
        "iata": "CIH",
        "icao": "ZBCZ",
        "latitude": "36.247501",
        "longitude": "113.125999",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "DAT": {
        "name": "Datong Airport",
        "city": "Datong",
        "country": "China",
        "iata": "DAT",
        "icao": "ZBDT",
        "latitude": "40.060299",
        "longitude": "113.482002",
        "altitude": "3442",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HET": {
        "name": "Baita International Airport",
        "city": "Hohhot",
        "country": "China",
        "iata": "HET",
        "icao": "ZBHH",
        "latitude": "40.851398",
        "longitude": "111.823997",
        "altitude": "3556",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "BAV": {
        "name": "Baotou Airport",
        "city": "Baotou",
        "country": "China",
        "iata": "BAV",
        "icao": "ZBOW",
        "latitude": "40.560001373291016",
        "longitude": "109.99700164794922",
        "altitude": "3321",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "SJW": {
        "name": "Shijiazhuang Daguocun International Airport",
        "city": "Shijiazhuang",
        "country": "China",
        "iata": "SJW",
        "icao": "ZBSJ",
        "latitude": "38.28070068359375",
        "longitude": "114.6969985961914",
        "altitude": "233",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TGO": {
        "name": "Tongliao Airport",
        "city": "Tongliao",
        "country": "China",
        "iata": "TGO",
        "icao": "ZBTL",
        "latitude": "43.556702",
        "longitude": "122.199997",
        "altitude": "2395",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HLH": {
        "name": "Ulanhot Airport",
        "city": "Ulanhot",
        "country": "China",
        "iata": "HLH",
        "icao": "ZBUL",
        "latitude": "46.195333",
        "longitude": "122.008333",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "XIL": {
        "name": "Xilinhot Airport",
        "city": "Xilinhot",
        "country": "China",
        "iata": "XIL",
        "icao": "ZBXH",
        "latitude": "43.91559982299805",
        "longitude": "115.96399688720703",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "BHY": {
        "name": "Beihai Airport",
        "city": "Beihai",
        "country": "China",
        "iata": "BHY",
        "icao": "ZGBH",
        "latitude": "21.5394",
        "longitude": "109.293999",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CGD": {
        "name": "Changde Airport",
        "city": "Changde",
        "country": "China",
        "iata": "CGD",
        "icao": "ZGCD",
        "latitude": "28.9188995361",
        "longitude": "111.63999939",
        "altitude": "128",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "DYG": {
        "name": "Dayong Airport",
        "city": "Dayong",
        "country": "China",
        "iata": "DYG",
        "icao": "ZGDY",
        "latitude": "29.1028",
        "longitude": "110.443001",
        "altitude": "692",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MXZ": {
        "name": "Meixian Airport",
        "city": "Meixian",
        "country": "China",
        "iata": "MXZ",
        "icao": "ZGMX",
        "latitude": "24.350000381469727",
        "longitude": "116.13300323486328",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ZUH": {
        "name": "Zhuhai Jinwan Airport",
        "city": "Zhuhai",
        "country": "China",
        "iata": "ZUH",
        "icao": "ZGSD",
        "latitude": "22.006399",
        "longitude": "113.375999",
        "altitude": "23",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LZH": {
        "name": "Liuzhou Bailian Airport",
        "city": "Liuzhou",
        "country": "China",
        "iata": "LZH",
        "icao": "ZGZH",
        "latitude": "24.2075",
        "longitude": "109.390999",
        "altitude": "295",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ZHA": {
        "name": "Zhanjiang Airport",
        "city": "Zhanjiang",
        "country": "China",
        "iata": "ZHA",
        "icao": "ZGZJ",
        "latitude": "21.214399",
        "longitude": "110.358002",
        "altitude": "125",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ENH": {
        "name": "Enshi Airport",
        "city": "Enshi",
        "country": "China",
        "iata": "ENH",
        "icao": "ZHES",
        "latitude": "30.3202991486",
        "longitude": "109.48500061",
        "altitude": "1605",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NNY": {
        "name": "Nanyang Jiangying Airport",
        "city": "Nanyang",
        "country": "China",
        "iata": "NNY",
        "icao": "ZHNY",
        "latitude": "32.980801",
        "longitude": "112.614998",
        "altitude": "840",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "XFN": {
        "name": "Xiangyang Liuji Airport",
        "city": "Xiangfan",
        "country": "China",
        "iata": "XFN",
        "icao": "ZHXF",
        "latitude": "32.1506",
        "longitude": "112.291",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YIH": {
        "name": "Yichang Sanxia Airport",
        "city": "Yichang",
        "country": "China",
        "iata": "YIH",
        "icao": "ZHYC",
        "latitude": "30.55655",
        "longitude": "111.479988",
        "altitude": "673",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "AKA": {
        "name": "Ankang Wulipu Airport",
        "city": "Ankang",
        "country": "China",
        "iata": "AKA",
        "icao": "ZLAK",
        "latitude": "32.708099",
        "longitude": "108.931",
        "altitude": "860",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "GOQ": {
        "name": "Golmud Airport",
        "city": "Golmud",
        "country": "China",
        "iata": "GOQ",
        "icao": "ZLGM",
        "latitude": "36.4006",
        "longitude": "94.786102",
        "altitude": "9334",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HZG": {
        "name": "Hanzhong Chenggu Airport",
        "city": "Hanzhong",
        "country": "China",
        "iata": "HZG",
        "icao": "ZLHZ",
        "latitude": "33.134136",
        "longitude": "107.206014",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "IQN": {
        "name": "Qingyang Airport",
        "city": "Qingyang",
        "country": "China",
        "iata": "IQN",
        "icao": "ZLQY",
        "latitude": "35.799702",
        "longitude": "107.602997",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "XNN": {
        "name": "Xining Caojiabu Airport",
        "city": "Xining",
        "country": "China",
        "iata": "XNN",
        "icao": "ZLXN",
        "latitude": "36.5275",
        "longitude": "102.042999",
        "altitude": "7119",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ENY": {
        "name": "Yan'an Ershilipu Airport",
        "city": "Yan'an",
        "country": "China",
        "iata": "ENY",
        "icao": "ZLYA",
        "latitude": "36.636902",
        "longitude": "109.554001",
        "altitude": "3100",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "UYN": {
        "name": "Yulin Yuyang Airport",
        "city": "Yulin",
        "country": "China",
        "iata": "UYN",
        "icao": "ZLYL",
        "latitude": "38.35971",
        "longitude": "109.590927",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "AVK": {
        "name": "Arvaikheer Airport",
        "city": "Arvaikheer",
        "country": "Mongolia",
        "iata": "AVK",
        "icao": "ZMAH",
        "latitude": "46.250301361083984",
        "longitude": "102.802001953125",
        "altitude": "5932",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "LTI": {
        "name": "Altai Airport",
        "city": "Altai",
        "country": "Mongolia",
        "iata": "LTI",
        "icao": "ZMAT",
        "latitude": "46.376399993896484",
        "longitude": "96.22109985351562",
        "altitude": "7260",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "BYN": {
        "name": "Bayankhongor Airport",
        "city": "Bayankhongor",
        "country": "Mongolia",
        "iata": "BYN",
        "icao": "ZMBH",
        "latitude": "46.163299560546875",
        "longitude": "100.7040023803711",
        "altitude": "6085",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "DLZ": {
        "name": "Dalanzadgad Airport",
        "city": "Dalanzadgad",
        "country": "Mongolia",
        "iata": "DLZ",
        "icao": "ZMDZ",
        "latitude": "43.59170150756836",
        "longitude": "104.43000030517578",
        "altitude": "4787",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "HVD": {
        "name": "Khovd Airport",
        "city": "Khovd",
        "country": "Mongolia",
        "iata": "HVD",
        "icao": "ZMKD",
        "latitude": "47.9541015625",
        "longitude": "91.6281967163086",
        "altitude": "4898",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Hovd"
    },
    "MXV": {
        "name": "Mörön Airport",
        "city": "Muren",
        "country": "Mongolia",
        "iata": "MXV",
        "icao": "ZMMN",
        "latitude": "49.663299560546875",
        "longitude": "100.0989990234375",
        "altitude": "4272",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "DIG": {
        "name": "Diqing Airport",
        "city": "Shangri-La",
        "country": "China",
        "iata": "DIG",
        "icao": "ZPDQ",
        "latitude": "27.7936",
        "longitude": "99.6772",
        "altitude": "10761",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LUM": {
        "name": "Mangshi Airport",
        "city": "Luxi",
        "country": "China",
        "iata": "LUM",
        "icao": "ZPLX",
        "latitude": "24.4011",
        "longitude": "98.5317",
        "altitude": "2890",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "SYM": {
        "name": "Pu'er Simao Airport",
        "city": "Simao",
        "country": "China",
        "iata": "SYM",
        "icao": "ZPSM",
        "latitude": "22.793301",
        "longitude": "100.959",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ZAT": {
        "name": "Zhaotong Airport",
        "city": "Zhaotong",
        "country": "China",
        "iata": "ZAT",
        "icao": "ZPZT",
        "latitude": "27.325599670410156",
        "longitude": "103.75499725341797",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KOW": {
        "name": "Ganzhou Airport",
        "city": "Ganzhou",
        "country": "China",
        "iata": "KOW",
        "icao": "ZSGZ",
        "latitude": "25.853333",
        "longitude": "114.778889",
        "altitude": "387",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JDZ": {
        "name": "Jingdezhen Airport",
        "city": "Jingdezhen",
        "country": "China",
        "iata": "JDZ",
        "icao": "ZSJD",
        "latitude": "29.3386001587",
        "longitude": "117.176002502",
        "altitude": "112",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JIU": {
        "name": "Jiujiang Lushan Airport",
        "city": "Jiujiang",
        "country": "China",
        "iata": "JIU",
        "icao": "ZSJJ",
        "latitude": "29.476944",
        "longitude": "115.801111",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JUZ": {
        "name": "Quzhou Airport",
        "city": "Quzhou",
        "country": "China",
        "iata": "JUZ",
        "icao": "ZSJU",
        "latitude": "28.965799",
        "longitude": "118.899002",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LYG": {
        "name": "Lianyungang Airport",
        "city": "Lianyungang",
        "country": "China",
        "iata": "LYG",
        "icao": "ZSLG",
        "latitude": "34.571667",
        "longitude": "118.873611",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HYN": {
        "name": "Huangyan Luqiao Airport",
        "city": "Huangyan",
        "country": "China",
        "iata": "HYN",
        "icao": "ZSLQ",
        "latitude": "28.56220054626465",
        "longitude": "121.42900085449219",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LYI": {
        "name": "Shubuling Airport",
        "city": "Linyi",
        "country": "China",
        "iata": "LYI",
        "icao": "ZSLY",
        "latitude": "35.04610061645508",
        "longitude": "118.41200256347656",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JJN": {
        "name": "Quanzhou Jinjiang International Airport",
        "city": "Quanzhou",
        "country": "China",
        "iata": "JJN",
        "icao": "ZSQZ",
        "latitude": "24.7964",
        "longitude": "118.589996",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TXN": {
        "name": "Tunxi International Airport",
        "city": "Huangshan",
        "country": "China",
        "iata": "TXN",
        "icao": "ZSTX",
        "latitude": "29.733299255371094",
        "longitude": "118.25599670410156",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WEF": {
        "name": "Weifang Airport",
        "city": "Weifang",
        "country": "China",
        "iata": "WEF",
        "icao": "ZSWF",
        "latitude": "36.646702",
        "longitude": "119.119003",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WEH": {
        "name": "Weihai Airport",
        "city": "Weihai",
        "country": "China",
        "iata": "WEH",
        "icao": "ZSWH",
        "latitude": "37.18709945678711",
        "longitude": "122.22899627685547",
        "altitude": "145",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WUX": {
        "name": "Sunan Shuofang International Airport",
        "city": "Wuxi",
        "country": "China",
        "iata": "WUX",
        "icao": "ZSWX",
        "latitude": "31.494400024399997",
        "longitude": "120.429000854",
        "altitude": "24",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WUS": {
        "name": "Nanping Wuyishan Airport",
        "city": "Wuyishan",
        "country": "China",
        "iata": "WUS",
        "icao": "ZSWY",
        "latitude": "27.7019",
        "longitude": "118.000999",
        "altitude": "614",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WNZ": {
        "name": "Wenzhou Longwan International Airport",
        "city": "Wenzhou",
        "country": "China",
        "iata": "WNZ",
        "icao": "ZSWZ",
        "latitude": "27.912201",
        "longitude": "120.851997",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YNZ": {
        "name": "Yancheng Airport",
        "city": "Yancheng",
        "country": "China",
        "iata": "YNZ",
        "icao": "ZSYN",
        "latitude": "33.425833",
        "longitude": "120.203056",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YIW": {
        "name": "Yiwu Airport",
        "city": "Yiwu",
        "country": "China",
        "iata": "YIW",
        "icao": "ZSYW",
        "latitude": "29.3446998596",
        "longitude": "120.031997681",
        "altitude": "262",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HSN": {
        "name": "Zhoushan Airport",
        "city": "Zhoushan",
        "country": "China",
        "iata": "HSN",
        "icao": "ZSZS",
        "latitude": "29.9342002869",
        "longitude": "122.361999512",
        "altitude": "3",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "BPX": {
        "name": "Qamdo Bangda Airport",
        "city": "Bangda",
        "country": "China",
        "iata": "BPX",
        "icao": "ZUBD",
        "latitude": "30.553600311279297",
        "longitude": "97.1082992553711",
        "altitude": "14219",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "DAX": {
        "name": "Dachuan Airport",
        "city": "Dazhou",
        "country": "China",
        "iata": "DAX",
        "icao": "ZUDX",
        "latitude": "31.1302",
        "longitude": "107.4295",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "GYS": {
        "name": "Guangyuan Airport",
        "city": "Guangyuan",
        "country": "China",
        "iata": "GYS",
        "icao": "ZUGU",
        "latitude": "32.3911018371582",
        "longitude": "105.7020034790039",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LZO": {
        "name": "Luzhou Airport",
        "city": "Luzhou",
        "country": "China",
        "iata": "LZO",
        "icao": "ZULZ",
        "latitude": "28.85219955444336",
        "longitude": "105.39299774169922",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MIG": {
        "name": "Mianyang Airport",
        "city": "Mianyang",
        "country": "China",
        "iata": "MIG",
        "icao": "ZUMY",
        "latitude": "31.4281005859375",
        "longitude": "104.74099731445312",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NAO": {
        "name": "Nanchong Airport",
        "city": "Nanchong",
        "country": "China",
        "iata": "NAO",
        "icao": "ZUNC",
        "latitude": "30.79545",
        "longitude": "106.1626",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LZY": {
        "name": "Nyingchi Airport",
        "city": "Nyingchi",
        "country": "China",
        "iata": "LZY",
        "icao": "ZUNZ",
        "latitude": "29.303300857543945",
        "longitude": "94.33529663085938",
        "altitude": "9675",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WXN": {
        "name": "Wanxian Airport",
        "city": "Wanxian",
        "country": "China",
        "iata": "WXN",
        "icao": "ZUWX",
        "latitude": "30.8017",
        "longitude": "108.433",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "AKU": {
        "name": "Aksu Airport",
        "city": "Aksu",
        "country": "China",
        "iata": "AKU",
        "icao": "ZWAK",
        "latitude": "41.262501",
        "longitude": "80.291702",
        "altitude": "3816",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "IQM": {
        "name": "Qiemo Yudu Airport",
        "city": "Qiemo",
        "country": "China",
        "iata": "IQM",
        "icao": "ZWCM",
        "latitude": "38.233611",
        "longitude": "85.465556",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KCA": {
        "name": "Kuqa Airport",
        "city": "Kuqa",
        "country": "China",
        "iata": "KCA",
        "icao": "ZWKC",
        "latitude": "41.677856",
        "longitude": "82.872917",
        "altitude": "3524",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KRL": {
        "name": "Korla Airport",
        "city": "Korla",
        "country": "China",
        "iata": "KRL",
        "icao": "ZWKL",
        "latitude": "41.69779968261719",
        "longitude": "86.12889862060547",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "KRY": {
        "name": "Karamay Airport",
        "city": "Karamay",
        "country": "China",
        "iata": "KRY",
        "icao": "ZWKM",
        "latitude": "45.46655",
        "longitude": "84.9527",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YIN": {
        "name": "Yining Airport",
        "city": "Yining",
        "country": "China",
        "iata": "YIN",
        "icao": "ZWYN",
        "latitude": "43.955799",
        "longitude": "81.330299",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HEK": {
        "name": "Heihe Airport",
        "city": "Heihe",
        "country": "China",
        "iata": "HEK",
        "icao": "ZYHE",
        "latitude": "50.1716209371",
        "longitude": "127.308883667",
        "altitude": "8530",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JMU": {
        "name": "Jiamusi Airport",
        "city": "Jiamusi",
        "country": "China",
        "iata": "JMU",
        "icao": "ZYJM",
        "latitude": "46.84339904789999",
        "longitude": "130.464996338",
        "altitude": "262",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JNZ": {
        "name": "Jinzhou Airport",
        "city": "Jinzhou",
        "country": "China",
        "iata": "JNZ",
        "icao": "ZYJZ",
        "latitude": "41.10139846801758",
        "longitude": "121.06199645996094",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NDG": {
        "name": "Qiqihar Sanjiazi Airport",
        "city": "Qiqihar",
        "country": "China",
        "iata": "NDG",
        "icao": "ZYQQ",
        "latitude": "47.239601135253906",
        "longitude": "123.91799926757812",
        "altitude": "477",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YNJ": {
        "name": "Yanji Chaoyangchuan Airport",
        "city": "Yanji",
        "country": "China",
        "iata": "YNJ",
        "icao": "ZYYJ",
        "latitude": "42.8828010559",
        "longitude": "129.451004028",
        "altitude": "624",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WKL": {
        "name": "Waikoloa Heliport",
        "city": "Waikoloa Village",
        "country": "United States",
        "iata": "WKL",
        "icao": "HI07",
        "latitude": "19.9205",
        "longitude": "-155.8607",
        "altitude": "119",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Honolulu"
    },
    "WME": {
        "name": "Mount Keith Airport",
        "city": "Mount Keith",
        "country": "Australia",
        "iata": "WME",
        "icao": "YMNE",
        "latitude": "-27.286399841308594",
        "longitude": "120.55500030517578",
        "altitude": "1792",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "LRV": {
        "name": "Los Roques Airport",
        "city": "Los Roques",
        "country": "Venezuela",
        "iata": "LRV",
        "icao": "SVRS",
        "latitude": "11.9499998093",
        "longitude": "-66.66999816890001",
        "altitude": "17",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Caracas"
    },
    "IOR": {
        "name": "Inishmore Aerodrome",
        "city": "Inis Mor",
        "country": "Ireland",
        "iata": "IOR",
        "icao": "EIIM",
        "latitude": "53.1067008972168",
        "longitude": "-9.653610229492188",
        "altitude": "24",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Europe/Dublin"
    },
    "NNR": {
        "name": "Connemara Regional Airport",
        "city": "Indreabhan",
        "country": "Ireland",
        "iata": "NNR",
        "icao": "EICA",
        "latitude": "53.23030090332031",
        "longitude": "-9.467780113220215",
        "altitude": "70",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Europe/Dublin"
    },
    "GTI": {
        "name": "Rügen Airport",
        "city": "Ruegen",
        "country": "Germany",
        "iata": "GTI",
        "icao": "EDCG",
        "latitude": "54.3833312988",
        "longitude": "13.3255558014",
        "altitude": "69",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Europe/Berlin"
    },
    "EZV": {
        "name": "Berezovo Airport",
        "city": "Berezovo",
        "country": "Russia",
        "iata": "EZV",
        "icao": "USHB",
        "latitude": "63.92100143432617",
        "longitude": "65.03050231933594",
        "altitude": "98",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "ORH": {
        "name": "Worcester Regional Airport",
        "city": "Worcester",
        "country": "United States",
        "iata": "ORH",
        "icao": "KORH",
        "latitude": "42.26729965209961",
        "longitude": "-71.87570190429688",
        "altitude": "1009",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AQG": {
        "name": "Anqing Tianzhushan Airport",
        "city": "Anqing",
        "country": "China",
        "iata": "AQG",
        "icao": "ZSAQ",
        "latitude": "30.582199",
        "longitude": "117.050003",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "SHP": {
        "name": "Shanhaiguan Airport",
        "city": "Qinhuangdao",
        "country": "China",
        "iata": "SHP",
        "icao": "ZBSH",
        "latitude": "39.968102",
        "longitude": "119.731003",
        "altitude": "30",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "YCU": {
        "name": "Yuncheng Guangong Airport",
        "city": "Yuncheng",
        "country": "China",
        "iata": "YCU",
        "icao": "ZBYC",
        "latitude": "35.116391",
        "longitude": "111.031388889",
        "altitude": "1242",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "JGN": {
        "name": "Jiayuguan Airport",
        "city": "Jiayuguan",
        "country": "China",
        "iata": "JGN",
        "icao": "ZLJQ",
        "latitude": "39.856899",
        "longitude": "98.3414",
        "altitude": "5112",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "DDG": {
        "name": "Dandong Airport",
        "city": "Dandong",
        "country": "China",
        "iata": "DDG",
        "icao": "ZYDD",
        "latitude": "40.0247",
        "longitude": "124.286003",
        "altitude": "30",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "DSN": {
        "name": "Ordos Ejin Horo Airport",
        "city": "Dongsheng",
        "country": "China",
        "iata": "DSN",
        "icao": "ZBDS",
        "latitude": "39.49",
        "longitude": "109.861388889",
        "altitude": "4557",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "PZI": {
        "name": "Bao'anying Airport",
        "city": "Panzhihua",
        "country": "China",
        "iata": "PZI",
        "icao": "ZUZH",
        "latitude": "26.54",
        "longitude": "101.79852",
        "altitude": "1620",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "PWT": {
        "name": "Bremerton National Airport",
        "city": "Bremerton",
        "country": "United States",
        "iata": "PWT",
        "icao": "KPWT",
        "latitude": "47.490200042725",
        "longitude": "-122.76499938965",
        "altitude": "444",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SPW": {
        "name": "Spencer Municipal Airport",
        "city": "Spencer",
        "country": "United States",
        "iata": "SPW",
        "icao": "KSPW",
        "latitude": "43.165500640869",
        "longitude": "-95.202796936035",
        "altitude": "1339",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JEF": {
        "name": "Jefferson City Memorial Airport",
        "city": "Jefferson City",
        "country": "United States",
        "iata": "JEF",
        "icao": "KJEF",
        "latitude": "38.5912017822",
        "longitude": "-92.15609741210001",
        "altitude": "549",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "UNT": {
        "name": "Unst Airport",
        "city": "Unst",
        "country": "United Kingdom",
        "iata": "UNT",
        "icao": "EGPW",
        "latitude": "60.74720001220703",
        "longitude": "-0.8538500070571899",
        "altitude": "0",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "PVC": {
        "name": "Provincetown Municipal Airport",
        "city": "Provincetown",
        "country": "United States",
        "iata": "PVC",
        "icao": "KPVC",
        "latitude": "42.0718994141",
        "longitude": "-70.2213973999",
        "altitude": "9",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SBH": {
        "name": "Gustaf III Airport",
        "city": "Gustavia",
        "country": "France",
        "iata": "SBH",
        "icao": "TFFJ",
        "latitude": "17.904399871826172",
        "longitude": "-62.84360122680664",
        "altitude": "49",
        "timezone": "-4",
        "dst": "E",
        "tz_name": null
    },
    "KMW": {
        "name": "Kostroma Sokerkino Airport",
        "city": "Kostroma",
        "country": "Russia",
        "iata": "KMW",
        "icao": "UUBA",
        "latitude": "57.7969017029",
        "longitude": "41.019401550299996",
        "altitude": "446",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "SUI": {
        "name": "Sukhumi Dranda Airport",
        "city": "Sukhumi",
        "country": "Georgia",
        "iata": "SUI",
        "icao": "UGSS",
        "latitude": "42.8582000732",
        "longitude": "41.128101348899996",
        "altitude": "53",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Asia/Tbilisi"
    },
    "TBW": {
        "name": "Donskoye Airport",
        "city": "Tambow",
        "country": "Russia",
        "iata": "TBW",
        "icao": "UUOT",
        "latitude": "52.806098937988",
        "longitude": "41.482799530029",
        "altitude": "413",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "OBN": {
        "name": "Oban Airport",
        "city": "North Connel",
        "country": "United Kingdom",
        "iata": "OBN",
        "icao": "EGEO",
        "latitude": "56.4635009765625",
        "longitude": "-5.399670124053955",
        "altitude": "20",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "ERM": {
        "name": "Erechim Airport",
        "city": "Erechim",
        "country": "Brazil",
        "iata": "ERM",
        "icao": "SSER",
        "latitude": "-27.66189956665039",
        "longitude": "-52.2682991027832",
        "altitude": "2498",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CVF": {
        "name": "Courchevel Airport",
        "city": "Courcheval",
        "country": "France",
        "iata": "CVF",
        "icao": "LFLJ",
        "latitude": "45.39670181274414",
        "longitude": "6.6347198486328125",
        "altitude": "6588",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "FUL": {
        "name": "Fullerton Municipal Airport",
        "city": "Fullerton",
        "country": "United States",
        "iata": "FUL",
        "icao": "KFUL",
        "latitude": "33.8720016479",
        "longitude": "-117.980003357",
        "altitude": "96",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "USA": {
        "name": "Concord-Padgett Regional Airport",
        "city": "Concord",
        "country": "United States",
        "iata": "USA",
        "icao": "KJQF",
        "latitude": "35.387798",
        "longitude": "-80.709099",
        "altitude": "705",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NVI": {
        "name": "Navoi Airport",
        "city": "Navoi",
        "country": "Uzbekistan",
        "iata": "NVI",
        "icao": "UTSA",
        "latitude": "40.1171989440918",
        "longitude": "65.1707992553711",
        "altitude": "0",
        "timezone": "5",
        "dst": "E",
        "tz_name": "Asia/Samarkand"
    },
    "QSF": {
        "name": "Ain Arnat Airport",
        "city": "Setif",
        "country": "Algeria",
        "iata": "QSF",
        "icao": "DAAS",
        "latitude": "36.178100585900005",
        "longitude": "5.3244900703399995",
        "altitude": "3360",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Algiers"
    },
    "LRH": {
        "name": "La Rochelle-Île de Ré Airport",
        "city": "La Rochelle",
        "country": "France",
        "iata": "LRH",
        "icao": "LFBH",
        "latitude": "46.17919921875",
        "longitude": "-1.1952799558639526",
        "altitude": "74",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "SUN": {
        "name": "Friedman Memorial Airport",
        "city": "Hailey",
        "country": "United States",
        "iata": "SUN",
        "icao": "KSUN",
        "latitude": "43.50439835",
        "longitude": "-114.2959976",
        "altitude": "5318",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MCW": {
        "name": "Mason City Municipal Airport",
        "city": "Mason City",
        "country": "United States",
        "iata": "MCW",
        "icao": "KMCW",
        "latitude": "43.157798767100005",
        "longitude": "-93.3312988281",
        "altitude": "1213",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AZA": {
        "name": "Phoenix-Mesa-Gateway Airport",
        "city": "Mesa",
        "country": "United States",
        "iata": "AZA",
        "icao": "KIWA",
        "latitude": "33.30780029",
        "longitude": "-111.6549988",
        "altitude": "1382",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "XAU": {
        "name": "Saúl Airport",
        "city": "Saul",
        "country": "French Guiana",
        "iata": "XAU",
        "icao": "SOOS",
        "latitude": "3.61361",
        "longitude": "-53.204201",
        "altitude": "656",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Cayenne"
    },
    "AKP": {
        "name": "Anaktuvuk Pass Airport",
        "city": "Anaktuvuk Pass",
        "country": "United States",
        "iata": "AKP",
        "icao": "PAKP",
        "latitude": "68.13359833",
        "longitude": "-151.7429962",
        "altitude": "2102",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ANV": {
        "name": "Anvik Airport",
        "city": "Anvik",
        "country": "United States",
        "iata": "ANV",
        "icao": "PANV",
        "latitude": "62.646702",
        "longitude": "-160.190994",
        "altitude": "291",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ATK": {
        "name": "Atqasuk Edward Burnell Sr Memorial Airport",
        "city": "Atqasuk",
        "country": "United States",
        "iata": "ATK",
        "icao": "PATQ",
        "latitude": "70.46730041503906",
        "longitude": "-157.43600463867188",
        "altitude": "96",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "GAM": {
        "name": "Gambell Airport",
        "city": "Gambell",
        "country": "United States",
        "iata": "GAM",
        "icao": "PAGM",
        "latitude": "63.76679992675781",
        "longitude": "-171.73300170898438",
        "altitude": "27",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HPB": {
        "name": "Hooper Bay Airport",
        "city": "Hooper Bay",
        "country": "United States",
        "iata": "HPB",
        "icao": "PAHP",
        "latitude": "61.52389908",
        "longitude": "-166.1470032",
        "altitude": "13",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KAL": {
        "name": "Kaltag Airport",
        "city": "Kaltag",
        "country": "United States",
        "iata": "KAL",
        "icao": "PAKV",
        "latitude": "64.31909943",
        "longitude": "-158.7409973",
        "altitude": "181",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KSM": {
        "name": "St Mary's Airport",
        "city": "St Mary's",
        "country": "United States",
        "iata": "KSM",
        "icao": "PASM",
        "latitude": "62.0605011",
        "longitude": "-163.302002",
        "altitude": "312",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KVL": {
        "name": "Kivalina Airport",
        "city": "Kivalina",
        "country": "United States",
        "iata": "KVL",
        "icao": "PAVL",
        "latitude": "67.73619842529297",
        "longitude": "-164.56300354003906",
        "altitude": "13",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MYU": {
        "name": "Mekoryuk Airport",
        "city": "Mekoryuk",
        "country": "United States",
        "iata": "MYU",
        "icao": "PAMY",
        "latitude": "60.37139892578125",
        "longitude": "-166.27099609375",
        "altitude": "48",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "RBY": {
        "name": "Ruby Airport",
        "city": "Ruby",
        "country": "United States",
        "iata": "RBY",
        "icao": "PARY",
        "latitude": "64.72720337",
        "longitude": "-155.4700012",
        "altitude": "658",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SHH": {
        "name": "Shishmaref Airport",
        "city": "Shishmaref",
        "country": "United States",
        "iata": "SHH",
        "icao": "PASH",
        "latitude": "66.249604",
        "longitude": "-166.089112",
        "altitude": "12",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SVA": {
        "name": "Savoonga Airport",
        "city": "Savoonga",
        "country": "United States",
        "iata": "SVA",
        "icao": "PASA",
        "latitude": "63.6864013671875",
        "longitude": "-170.4929962158203",
        "altitude": "53",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WTK": {
        "name": "Noatak Airport",
        "city": "Noatak",
        "country": "United States",
        "iata": "WTK",
        "icao": "PAWN",
        "latitude": "67.56610107421875",
        "longitude": "-162.97500610351562",
        "altitude": "88",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OMC": {
        "name": "Ormoc Airport",
        "city": "Ormoc City",
        "country": "Philippines",
        "iata": "OMC",
        "icao": "RPVO",
        "latitude": "11.057999610900879",
        "longitude": "124.56500244140625",
        "altitude": "83",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "YPX": {
        "name": "Puvirnituq Airport",
        "city": "Puvirnituq",
        "country": "Canada",
        "iata": "YPX",
        "icao": "CYPX",
        "latitude": "60.05059814453125",
        "longitude": "-77.28690338134766",
        "altitude": "74",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YTQ": {
        "name": "Tasiujaq Airport",
        "city": "Tasiujaq",
        "country": "Canada",
        "iata": "YTQ",
        "icao": "CYTQ",
        "latitude": "58.66780090332031",
        "longitude": "-69.95580291748047",
        "altitude": "122",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "ARC": {
        "name": "Arctic Village Airport",
        "city": "Arctic Village",
        "country": "United States",
        "iata": "ARC",
        "icao": "PARC",
        "latitude": "68.1147",
        "longitude": "-145.578995",
        "altitude": "2092",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "QOW": {
        "name": "Sam Mbakwe International Airport",
        "city": "Imo",
        "country": "Nigeria",
        "iata": "QOW",
        "icao": "DNIM",
        "latitude": "5.427060127258301",
        "longitude": "7.206029891967773",
        "altitude": "373",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "FON": {
        "name": "Arenal Airport",
        "city": "La Fortuna/San Carlos",
        "country": "Costa Rica",
        "iata": "FON",
        "icao": "MRAN",
        "latitude": "10.478",
        "longitude": "-84.634499",
        "altitude": "342",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "TMU": {
        "name": "Tambor Airport",
        "city": "Nicoya",
        "country": "Costa Rica",
        "iata": "TMU",
        "icao": "MRTR",
        "latitude": "9.73852",
        "longitude": "-85.013802",
        "altitude": "33",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "CYZ": {
        "name": "Cauayan Airport",
        "city": "Cauayan",
        "country": "Philippines",
        "iata": "CYZ",
        "icao": "RPUY",
        "latitude": "16.9298992157",
        "longitude": "121.752998352",
        "altitude": "200",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "KVK": {
        "name": "Kirovsk-Apatity Airport",
        "city": "Apatity",
        "country": "Russia",
        "iata": "KVK",
        "icao": "ULMK",
        "latitude": "67.46330261230469",
        "longitude": "33.58829879760742",
        "altitude": "515",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "GVR": {
        "name": "Coronel Altino Machado de Oliveira Airport",
        "city": "Governador Valadares",
        "country": "Brazil",
        "iata": "GVR",
        "icao": "SBGV",
        "latitude": "-18.89520072937",
        "longitude": "-41.982200622559",
        "altitude": "561",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "KPC": {
        "name": "Port Clarence Coast Guard Station",
        "city": "Port Clarence",
        "country": "United States",
        "iata": "KPC",
        "icao": "PAPC",
        "latitude": "65.2537002563",
        "longitude": "-166.85899353",
        "altitude": "10",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PJA": {
        "name": "Pajala Airport",
        "city": "Pajala",
        "country": "Sweden",
        "iata": "PJA",
        "icao": "ESUP",
        "latitude": "67.24559783935547",
        "longitude": "23.068899154663086",
        "altitude": "542",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "QBC": {
        "name": "Bella Coola Airport",
        "city": "Bella Coola",
        "country": "Canada",
        "iata": "QBC",
        "icao": "CYBD",
        "latitude": "52.387501",
        "longitude": "-126.596001",
        "altitude": "117",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "HGR": {
        "name": "Hagerstown Regional Richard A Henson Field",
        "city": "Hagerstown",
        "country": "United States",
        "iata": "HGR",
        "icao": "KHGR",
        "latitude": "39.707901",
        "longitude": "-77.72949982",
        "altitude": "703",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ACR": {
        "name": "Araracuara Airport",
        "city": "Araracuara",
        "country": "Colombia",
        "iata": "ACR",
        "icao": "SKAC",
        "latitude": "-0.5833",
        "longitude": "-72.4083",
        "altitude": "1250",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "GOP": {
        "name": "Gorakhpur Airport",
        "city": "Gorakhpur",
        "country": "India",
        "iata": "GOP",
        "icao": "VEGK",
        "latitude": "26.739700317399997",
        "longitude": "83.4496994019",
        "altitude": "259",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "SDP": {
        "name": "Sand Point Airport",
        "city": "Sand Point",
        "country": "United States",
        "iata": "SDP",
        "icao": "PASD",
        "latitude": "55.314998626708984",
        "longitude": "-160.5229949951172",
        "altitude": "21",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HMI": {
        "name": "Hami Airport",
        "city": "Hami",
        "country": "China",
        "iata": "HMI",
        "icao": "ZWHM",
        "latitude": "42.8414",
        "longitude": "93.669197",
        "altitude": "2703",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WUZ": {
        "name": "Wuzhou Changzhoudao Airport",
        "city": "Wuzhou",
        "country": "China",
        "iata": "WUZ",
        "icao": "ZGWZ",
        "latitude": "23.456699",
        "longitude": "111.248001",
        "altitude": "89",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TBH": {
        "name": "Tugdan Airport",
        "city": "Romblon",
        "country": "Philippines",
        "iata": "TBH",
        "icao": "RPVU",
        "latitude": "12.3109998703",
        "longitude": "122.084999084",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "ACP": {
        "name": "Sahand Airport",
        "city": "Maragheh",
        "country": "Iran",
        "iata": "ACP",
        "icao": "OITM",
        "latitude": "37.347999572753906",
        "longitude": "46.127899169921875",
        "altitude": "4396",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "GBT": {
        "name": "Gorgan Airport",
        "city": "Gorgan",
        "country": "Iran",
        "iata": "GBT",
        "icao": "OING",
        "latitude": "36.909400939899996",
        "longitude": "54.4012985229",
        "altitude": "-24",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "IIL": {
        "name": "Ilam Airport",
        "city": "Ilam",
        "country": "Iran",
        "iata": "IIL",
        "icao": "OICI",
        "latitude": "33.58660125732422",
        "longitude": "46.40480041503906",
        "altitude": "4404",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "PFQ": {
        "name": "Parsabade Moghan Airport",
        "city": "Parsabad",
        "country": "Iran",
        "iata": "PFQ",
        "icao": "OITP",
        "latitude": "39.60359954834",
        "longitude": "47.881500244141",
        "altitude": "251",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "TCG": {
        "name": "Tacheng Airport",
        "city": "Tacheng",
        "country": "China",
        "iata": "TCG",
        "icao": "ZWTC",
        "latitude": "46.67250061035156",
        "longitude": "83.3407974243164",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "MQM": {
        "name": "Mardin Airport",
        "city": "Mardin",
        "country": "Turkey",
        "iata": "MQM",
        "icao": "LTCR",
        "latitude": "37.223300933800004",
        "longitude": "40.6316986084",
        "altitude": "1729",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "AFS": {
        "name": "Sugraly Airport",
        "city": "Zarafshan",
        "country": "Uzbekistan",
        "iata": "AFS",
        "icao": "UTSN",
        "latitude": "41.61389923095703",
        "longitude": "64.23320007324219",
        "altitude": "1396",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Samarkand"
    },
    "DRG": {
        "name": "Deering Airport",
        "city": "Deering",
        "country": "United States",
        "iata": "DRG",
        "icao": "PADE",
        "latitude": "66.0696029663",
        "longitude": "-162.76600647",
        "altitude": "21",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LEN": {
        "name": "Leon Airport",
        "city": "Leon",
        "country": "Spain",
        "iata": "LEN",
        "icao": "LELN",
        "latitude": "42.5890007019043",
        "longitude": "-5.65556001663208",
        "altitude": "3006",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "RGS": {
        "name": "Burgos Airport",
        "city": "Burgos",
        "country": "Spain",
        "iata": "RGS",
        "icao": "LEBG",
        "latitude": "42.357601",
        "longitude": "-3.62076",
        "altitude": "2945",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "EGM": {
        "name": "Sege Airport",
        "city": "Sege",
        "country": "Solomon Islands",
        "iata": "EGM",
        "icao": "AGGS",
        "latitude": "-8.578889846801758",
        "longitude": "157.87600708007812",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "CQD": {
        "name": "Shahrekord Airport",
        "city": "Shahre Kord",
        "country": "Iran",
        "iata": "CQD",
        "icao": "OIFS",
        "latitude": "32.2971992493",
        "longitude": "50.842201232899995",
        "altitude": "6723",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "DHM": {
        "name": "Kangra Airport",
        "city": "Kangra",
        "country": "India",
        "iata": "DHM",
        "icao": "VIGG",
        "latitude": "32.16510009765625",
        "longitude": "76.26339721679688",
        "altitude": "2525",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "NDC": {
        "name": "Nanded Airport",
        "city": "Nanded",
        "country": "India",
        "iata": "NDC",
        "icao": "VAND",
        "latitude": "19.1833000183",
        "longitude": "77.31670379639999",
        "altitude": "1250",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "SLV": {
        "name": "Shimla Airport",
        "city": "Shimla",
        "country": "India",
        "iata": "SLV",
        "icao": "VISM",
        "latitude": "31.0818",
        "longitude": "77.068001",
        "altitude": "5072",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Calcutta"
    },
    "IGG": {
        "name": "Igiugig Airport",
        "city": "Igiugig",
        "country": "United States",
        "iata": "IGG",
        "icao": "PAIG",
        "latitude": "59.32400131225586",
        "longitude": "-155.90199279785156",
        "altitude": "90",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KNW": {
        "name": "New Stuyahok Airport",
        "city": "New Stuyahok",
        "country": "United States",
        "iata": "KNW",
        "icao": "PANW",
        "latitude": "59.4499015808",
        "longitude": "-157.32800293",
        "altitude": "364",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KVC": {
        "name": "King Cove Airport",
        "city": "King Cove",
        "country": "United States",
        "iata": "KVC",
        "icao": "PAVC",
        "latitude": "55.11629867553711",
        "longitude": "-162.26600646972656",
        "altitude": "155",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PTH": {
        "name": "Port Heiden Airport",
        "city": "Port Heiden",
        "country": "United States",
        "iata": "PTH",
        "icao": "PAPH",
        "latitude": "56.95909881591797",
        "longitude": "-158.63299560546875",
        "altitude": "95",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TOG": {
        "name": "Togiak Airport",
        "city": "Togiak Village",
        "country": "United States",
        "iata": "TOG",
        "icao": "PATG",
        "latitude": "59.052799224853516",
        "longitude": "-160.39700317382812",
        "altitude": "21",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "EGN": {
        "name": "Geneina Airport",
        "city": "Geneina",
        "country": "Sudan",
        "iata": "EGN",
        "icao": "HSGN",
        "latitude": "13.48169994354248",
        "longitude": "22.467199325561523",
        "altitude": "2650",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "LKH": {
        "name": "Long Akah Airport",
        "city": "Long Akah",
        "country": "Malaysia",
        "iata": "LKH",
        "icao": "WBGL",
        "latitude": "3.299999952316284",
        "longitude": "114.78299713134766",
        "altitude": "289",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Kuala_Lumpur"
    },
    "WLH": {
        "name": "Walaha Airport",
        "city": "Walaha",
        "country": "Vanuatu",
        "iata": "WLH",
        "icao": "NVSW",
        "latitude": "-15.411999702500001",
        "longitude": "167.690994263",
        "altitude": "151",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Efate"
    },
    "CHG": {
        "name": "Chaoyang Airport",
        "city": "Chaoyang",
        "country": "China",
        "iata": "CHG",
        "icao": "ZYCY",
        "latitude": "41.538101",
        "longitude": "120.434998",
        "altitude": "568",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "UAS": {
        "name": "Buffalo Spring",
        "city": "Samburu South",
        "country": "Kenya",
        "iata": "UAS",
        "icao": "HKSB",
        "latitude": "0.530583",
        "longitude": "37.534195",
        "altitude": "3295",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "BHG": {
        "name": "Brus Laguna Airport",
        "city": "Brus Laguna",
        "country": "Honduras",
        "iata": "BHG",
        "icao": "MHBL",
        "latitude": "15.7631",
        "longitude": "-84.543602",
        "altitude": "19",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Tegucigalpa"
    },
    "YVB": {
        "name": "Bonaventure Airport",
        "city": "Bonaventure",
        "country": "Canada",
        "iata": "YVB",
        "icao": "CYVB",
        "latitude": "48.07109832763672",
        "longitude": "-65.46029663085938",
        "altitude": "123",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "SKT": {
        "name": "Sialkot Airport",
        "city": "Sialkot",
        "country": "Pakistan",
        "iata": "SKT",
        "icao": "OPST",
        "latitude": "32.5355567932",
        "longitude": "74.3638916016",
        "altitude": "837",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Karachi"
    },
    "PDP": {
        "name": "Capitan Corbeta CA Curbelo International Airport",
        "city": "Punta del Este",
        "country": "Uruguay",
        "iata": "PDP",
        "icao": "SULS",
        "latitude": "-34.855098724365234",
        "longitude": "-55.09429931640625",
        "altitude": "95",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Montevideo"
    },
    "WVB": {
        "name": "Walvis Bay Airport",
        "city": "Walvis Bay",
        "country": "Namibia",
        "iata": "WVB",
        "icao": "FYWB",
        "latitude": "-22.9799",
        "longitude": "14.6453",
        "altitude": "299",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "MPA": {
        "name": "Katima Mulilo Airport",
        "city": "Mpacha",
        "country": "Namibia",
        "iata": "MPA",
        "icao": "FYKM",
        "latitude": "-17.634399",
        "longitude": "24.176701",
        "altitude": "3144",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "AOE": {
        "name": "Anadolu Airport",
        "city": "Eskissehir",
        "country": "Turkey",
        "iata": "AOE",
        "icao": "LTBY",
        "latitude": "39.809898",
        "longitude": "30.5194",
        "altitude": "2588",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "CKZ": {
        "name": "Çanakkale Airport",
        "city": "Canakkale",
        "country": "Turkey",
        "iata": "CKZ",
        "icao": "LTBH",
        "latitude": "40.1376991272",
        "longitude": "26.4267997742",
        "altitude": "23",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "MSR": {
        "name": "Muş Airport",
        "city": "Mus",
        "country": "Turkey",
        "iata": "MSR",
        "icao": "LTCK",
        "latitude": "38.747798919677734",
        "longitude": "41.66120147705078",
        "altitude": "4157",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "NOP": {
        "name": "Sinop Airport",
        "city": "Sinop",
        "country": "Turkey",
        "iata": "NOP",
        "icao": "LTCM",
        "latitude": "42.015800476074",
        "longitude": "35.066398620605",
        "altitude": "20",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "TEQ": {
        "name": "Tekirdağ Çorlu Airport",
        "city": "Çorlu",
        "country": "Turkey",
        "iata": "TEQ",
        "icao": "LTBU",
        "latitude": "41.13819885253906",
        "longitude": "27.919099807739258",
        "altitude": "574",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "YEI": {
        "name": "Bursa Yenişehir Airport",
        "city": "Yenisehir",
        "country": "Turkey",
        "iata": "YEI",
        "icao": "LTBR",
        "latitude": "40.2551994324",
        "longitude": "29.5625991821",
        "altitude": "764",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "LSS": {
        "name": "Terre-de-Haut Airport",
        "city": "Les Saintes",
        "country": "Guadeloupe",
        "iata": "LSS",
        "icao": "TFFS",
        "latitude": "15.86439991",
        "longitude": "-61.5806007385",
        "altitude": "46",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guadeloupe"
    },
    "KMV": {
        "name": "Kalay Airport",
        "city": "Kalemyo",
        "country": "Myanmar",
        "iata": "KMV",
        "icao": "VYKL",
        "latitude": "23.188800811767578",
        "longitude": "94.05110168457031",
        "altitude": "499",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "VQS": {
        "name": "Vieques Airport",
        "city": "Vieques Island",
        "country": "Puerto Rico",
        "iata": "VQS",
        "icao": "TJCG",
        "latitude": "18.115800857500002",
        "longitude": "-65.4226989746",
        "altitude": "19",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "YIF": {
        "name": "St Augustin Airport",
        "city": "St-Augustin",
        "country": "Canada",
        "iata": "YIF",
        "icao": "CYIF",
        "latitude": "51.2117004395",
        "longitude": "-58.6582984924",
        "altitude": "20",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "HDM": {
        "name": "Hamadan Airport",
        "city": "Hamadan",
        "country": "Iran",
        "iata": "HDM",
        "icao": "OIHH",
        "latitude": "34.86920166015625",
        "longitude": "48.5525016784668",
        "altitude": "5755",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": "Asia/Tehran"
    },
    "MRQ": {
        "name": "Marinduque Airport",
        "city": "Gasan",
        "country": "Philippines",
        "iata": "MRQ",
        "icao": "RPUW",
        "latitude": "13.361000061035156",
        "longitude": "121.82599639892578",
        "altitude": "32",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "GFN": {
        "name": "Grafton Airport",
        "city": "Grafton",
        "country": "Australia",
        "iata": "GFN",
        "icao": "YGFN",
        "latitude": "-29.7593994140625",
        "longitude": "153.02999877929688",
        "altitude": "110",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Sydney"
    },
    "OAG": {
        "name": "Orange Airport",
        "city": "Orange",
        "country": "Australia",
        "iata": "OAG",
        "icao": "YORG",
        "latitude": "-33.3816986084",
        "longitude": "149.132995605",
        "altitude": "3115",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Sydney"
    },
    "TRO": {
        "name": "Taree Airport",
        "city": "Taree",
        "country": "Australia",
        "iata": "TRO",
        "icao": "YTRE",
        "latitude": "-31.8885993958",
        "longitude": "152.514007568",
        "altitude": "38",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Sydney"
    },
    "COQ": {
        "name": "Choibalsan Airport",
        "city": "Choibalsan",
        "country": "Mongolia",
        "iata": "COQ",
        "icao": "ZMCD",
        "latitude": "48.13570022583008",
        "longitude": "114.64600372314453",
        "altitude": "2457",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "HOH": {
        "name": "Hohenems-Dornbirn Airport",
        "city": "Hohenems",
        "country": "Austria",
        "iata": "HOH",
        "icao": "LOIH",
        "latitude": "47.3849983215",
        "longitude": "9.69999980927",
        "altitude": "1352",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Vienna"
    },
    "ESC": {
        "name": "Delta County Airport",
        "city": "Escanaba",
        "country": "United States",
        "iata": "ESC",
        "icao": "KESC",
        "latitude": "45.7226982117",
        "longitude": "-87.0936965942",
        "altitude": "609",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "YAK": {
        "name": "Yakutat Airport",
        "city": "Yakutat",
        "country": "United States",
        "iata": "YAK",
        "icao": "PAYA",
        "latitude": "59.5032997131",
        "longitude": "-139.660003662",
        "altitude": "33",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "GUL": {
        "name": "Goulburn Airport",
        "city": "Goulburn",
        "country": "Australia",
        "iata": "GUL",
        "icao": "YGLB",
        "latitude": "-34.810298919677734",
        "longitude": "149.7259979248047",
        "altitude": "2141",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CES": {
        "name": "Cessnock Airport",
        "city": "Cessnock",
        "country": "Australia",
        "iata": "CES",
        "icao": "YCNK",
        "latitude": "-32.787498",
        "longitude": "151.341995",
        "altitude": "210",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "NSO": {
        "name": "Scone Airport",
        "city": "Scone",
        "country": "Australia",
        "iata": "NSO",
        "icao": "YSCO",
        "latitude": "-32.037200927734375",
        "longitude": "150.83200073242188",
        "altitude": "745",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "DGE": {
        "name": "Mudgee Airport",
        "city": "Mudgee",
        "country": "Australia",
        "iata": "DGE",
        "icao": "YMDG",
        "latitude": "-32.5625",
        "longitude": "149.610992432",
        "altitude": "1545",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "MTL": {
        "name": "Maitland Airport",
        "city": "Maitland",
        "country": "Australia",
        "iata": "MTL",
        "icao": "YMND",
        "latitude": "-32.701265",
        "longitude": "151.492912",
        "altitude": "85",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "CPX": {
        "name": "Benjamin Rivera Noriega Airport",
        "city": "Culebra Island",
        "country": "Puerto Rico",
        "iata": "CPX",
        "icao": "TJCP",
        "latitude": "18.313289",
        "longitude": "-65.304324",
        "altitude": "49",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Puerto_Rico"
    },
    "MWA": {
        "name": "Williamson County Regional Airport",
        "city": "Marion",
        "country": "United States",
        "iata": "MWA",
        "icao": "KMWA",
        "latitude": "37.75500107",
        "longitude": "-89.01110077",
        "altitude": "472",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OCN": {
        "name": "Oceanside Municipal Airport",
        "city": "Fraser Island",
        "country": "Australia",
        "iata": "OCN",
        "icao": "KOKB",
        "latitude": "33.217300415039",
        "longitude": "-117.35399627686",
        "altitude": "28",
        "timezone": "-8",
        "dst": "O",
        "tz_name": "America/Los_Angeles"
    },
    "KIK": {
        "name": "Kirkuk Air Base",
        "city": "Kirkuk",
        "country": "Iraq",
        "iata": "KIK",
        "icao": "ORKK",
        "latitude": "35.46950149536133",
        "longitude": "44.348899841308594",
        "altitude": "1061",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Asia/Baghdad"
    },
    "XJD": {
        "name": "Al Udeid Air Base",
        "city": "Doha",
        "country": "Qatar",
        "iata": "XJD",
        "icao": "OTBH",
        "latitude": "25.1173000336",
        "longitude": "51.3149986267",
        "altitude": "130",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Asia/Qatar"
    },
    "GBZ": {
        "name": "Great Barrier Aerodrome",
        "city": "Claris",
        "country": "New Zealand",
        "iata": "GBZ",
        "icao": "NZGB",
        "latitude": "-36.24140167236328",
        "longitude": "175.4720001220703",
        "altitude": "20",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "IMT": {
        "name": "Ford Airport",
        "city": "Iron Mountain",
        "country": "United States",
        "iata": "IMT",
        "icao": "KIMT",
        "latitude": "45.8184013367",
        "longitude": "-88.1145019531",
        "altitude": "1182",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AET": {
        "name": "Allakaket Airport",
        "city": "Allakaket",
        "country": "United States",
        "iata": "AET",
        "icao": "PFAL",
        "latitude": "66.5518035889",
        "longitude": "-152.621994019",
        "altitude": "441",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MGC": {
        "name": "Michigan City Municipal Airport",
        "city": "Michigan City",
        "country": "United States",
        "iata": "MGC",
        "icao": "KMGC",
        "latitude": "41.703300476100004",
        "longitude": "-86.8211975098",
        "altitude": "655",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SWD": {
        "name": "Seward Airport",
        "city": "Seward",
        "country": "United States",
        "iata": "SWD",
        "icao": "PAWD",
        "latitude": "60.12689971923828",
        "longitude": "-149.41900634765625",
        "altitude": "22",
        "timezone": "-9",
        "dst": "U",
        "tz_name": "America/Anchorage"
    },
    "GRM": {
        "name": "Grand Marais Cook County Airport",
        "city": "Grand Marais",
        "country": "United States",
        "iata": "GRM",
        "icao": "KCKC",
        "latitude": "47.8382987976",
        "longitude": "-90.38289642330001",
        "altitude": "1799",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Chicago"
    },
    "AUW": {
        "name": "Wausau Downtown Airport",
        "city": "Wausau",
        "country": "United States",
        "iata": "AUW",
        "icao": "KAUW",
        "latitude": "44.9262008667",
        "longitude": "-89.6266021729",
        "altitude": "1201",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Chicago"
    },
    "MYP": {
        "name": "Mary Airport",
        "city": "Mary",
        "country": "Turkmenistan",
        "iata": "MYP",
        "icao": "UTAM",
        "latitude": "37.6194",
        "longitude": "61.896702",
        "altitude": "728",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Ashgabat"
    },
    "MVA": {
        "name": "Reykjahlíð Airport",
        "city": "Myvatn",
        "country": "Iceland",
        "iata": "MVA",
        "icao": "BIRL",
        "latitude": "65.65579986572266",
        "longitude": "-16.918100357055664",
        "altitude": "1030",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "QSA": {
        "name": "Sabadell Airport",
        "city": "Sabadell",
        "country": "Spain",
        "iata": "QSA",
        "icao": "LELL",
        "latitude": "41.52090072631836",
        "longitude": "2.1050798892974854",
        "altitude": "485",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "WSY": {
        "name": "Whitsunday Island Airport",
        "city": "Airlie Beach",
        "country": "Australia",
        "iata": "WSY",
        "icao": "YWHI",
        "latitude": "-20.27611",
        "longitude": "148.755",
        "altitude": "40",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "MIE": {
        "name": "Delaware County Johnson Field",
        "city": "Muncie",
        "country": "United States",
        "iata": "MIE",
        "icao": "KMIE",
        "latitude": "40.2422981262207",
        "longitude": "-85.3958969116211",
        "altitude": "937",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "LAF": {
        "name": "Purdue University Airport",
        "city": "Lafayette",
        "country": "United States",
        "iata": "LAF",
        "icao": "KLAF",
        "latitude": "40.41230010986328",
        "longitude": "-86.93689727783203",
        "altitude": "606",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "VGT": {
        "name": "North Las Vegas Airport",
        "city": "Las Vegas",
        "country": "United States",
        "iata": "VGT",
        "icao": "KVGT",
        "latitude": "36.21070098877",
        "longitude": "-115.19400024414",
        "altitude": "2205",
        "timezone": "-8",
        "dst": "U",
        "tz_name": "America/Los_Angeles"
    },
    "ENW": {
        "name": "Kenosha Regional Airport",
        "city": "Kenosha",
        "country": "United States",
        "iata": "ENW",
        "icao": "KENW",
        "latitude": "42.59569931",
        "longitude": "-87.92780304",
        "altitude": "742",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MTJ": {
        "name": "Montrose Regional Airport",
        "city": "Montrose CO",
        "country": "United States",
        "iata": "MTJ",
        "icao": "KMTJ",
        "latitude": "38.509799957300004",
        "longitude": "-107.893997192",
        "altitude": "5759",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "RIW": {
        "name": "Riverton Regional Airport",
        "city": "Riverton WY",
        "country": "United States",
        "iata": "RIW",
        "icao": "KRIW",
        "latitude": "43.064201355",
        "longitude": "-108.459999084",
        "altitude": "5525",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PDT": {
        "name": "Eastern Oregon Regional At Pendleton Airport",
        "city": "Pendleton",
        "country": "United States",
        "iata": "PDT",
        "icao": "KPDT",
        "latitude": "45.695098877",
        "longitude": "-118.841003418",
        "altitude": "1497",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LYM": {
        "name": "Lympne Airport",
        "city": "Lympne",
        "country": "United Kingdom",
        "iata": "LYM",
        "icao": "EGMK",
        "latitude": "51.08",
        "longitude": "1.013",
        "altitude": "351",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "PKH": {
        "name": "Porto Cheli Airport",
        "city": "Porto Heli",
        "country": "Greece",
        "iata": "PKH",
        "icao": "LGHL",
        "latitude": "37.297501",
        "longitude": "23.1478",
        "altitude": "69",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Athens"
    },
    "KTR": {
        "name": "Tindal Airport",
        "city": "Katherine",
        "country": "Australia",
        "iata": "KTR",
        "icao": "YPTN",
        "latitude": "-14.521100044250488",
        "longitude": "132.3780059814453",
        "altitude": "443",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "NOA": {
        "name": "Nowra Airport",
        "city": "Nowra",
        "country": "Australia",
        "iata": "NOA",
        "icao": "YSNW",
        "latitude": "-34.94889831542969",
        "longitude": "150.53700256347656",
        "altitude": "400",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "UCK": {
        "name": "Lutsk Airport",
        "city": "Lutsk",
        "country": "Ukraine",
        "iata": "UCK",
        "icao": "UKLC",
        "latitude": "50.678404",
        "longitude": "25.487165",
        "altitude": "774",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Europe/Kiev"
    },
    "CEJ": {
        "name": "Chernihiv Shestovitsa Airport",
        "city": "Chernigov",
        "country": "Ukraine",
        "iata": "CEJ",
        "icao": "UKRR",
        "latitude": "51.4021987915",
        "longitude": "31.1583003998",
        "altitude": "446",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "BQT": {
        "name": "Brest Airport",
        "city": "Brest",
        "country": "Belarus",
        "iata": "BQT",
        "icao": "UMBB",
        "latitude": "52.108299",
        "longitude": "23.8981",
        "altitude": "468",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Minsk"
    },
    "OSH": {
        "name": "Wittman Regional Airport",
        "city": "Oshkosh",
        "country": "United States",
        "iata": "OSH",
        "icao": "KOSH",
        "latitude": "43.98440170288086",
        "longitude": "-88.55699920654297",
        "altitude": "808",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "AGE": {
        "name": "Wangerooge Airport",
        "city": "Wangerooge",
        "country": "Germany",
        "iata": "AGE",
        "icao": "EDWG",
        "latitude": "53.782779693603516",
        "longitude": "7.913888931274414",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "BXG": {
        "name": "Bendigo Airport",
        "city": "Bendigo",
        "country": "Australia",
        "iata": "BXG",
        "icao": "YBDG",
        "latitude": "-36.7393989563",
        "longitude": "144.330001831",
        "altitude": "705",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Hobart"
    },
    "EAT": {
        "name": "Pangborn Memorial Airport",
        "city": "Wenatchee",
        "country": "United States",
        "iata": "EAT",
        "icao": "KEAT",
        "latitude": "47.3988990784",
        "longitude": "-120.207000732",
        "altitude": "1249",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ARE": {
        "name": "Antonio Nery Juarbe Pol Airport",
        "city": "Arecibo",
        "country": "Puerto Rico",
        "iata": "ARE",
        "icao": "TJAB",
        "latitude": "18.4500007629",
        "longitude": "-66.6753005981",
        "altitude": "23",
        "timezone": "-4",
        "dst": "N",
        "tz_name": "America/Puerto_Rico"
    },
    "RIN": {
        "name": "Ringi Cove Airport",
        "city": "Ringi Cove",
        "country": "Solomon Islands",
        "iata": "RIN",
        "icao": "AGRC",
        "latitude": "-8.12639045715332",
        "longitude": "157.14300537109375",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "KCK": {
        "name": "Kirensk Airport",
        "city": "Kirensk",
        "country": "Russia",
        "iata": "KCK",
        "icao": "UIKK",
        "latitude": "57.773",
        "longitude": "108.064",
        "altitude": "840",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "UKX": {
        "name": "Ust-Kut Airport",
        "city": "Ust-Kut",
        "country": "Russia",
        "iata": "UKX",
        "icao": "UITT",
        "latitude": "56.8567008972168",
        "longitude": "105.7300033569336",
        "altitude": "2188",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "RMT": {
        "name": "Rimatara Airport",
        "city": "Rimatara",
        "country": "French Polynesia",
        "iata": "RMT",
        "icao": "NTAM",
        "latitude": "-22.63725",
        "longitude": "-152.8059",
        "altitude": "60",
        "timezone": "-10",
        "dst": "U",
        "tz_name": null
    },
    "QLS": {
        "name": "Lausanne-Blécherette Airport",
        "city": "Lausanne",
        "country": "Switzerland",
        "iata": "QLS",
        "icao": "LSGL",
        "latitude": "46.54529953",
        "longitude": "6.61667013168",
        "altitude": "2041",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "ZJI": {
        "name": "Locarno Airport",
        "city": "Locarno",
        "country": "Switzerland",
        "iata": "ZJI",
        "icao": "LSZL",
        "latitude": "46.160800933800004",
        "longitude": "8.87860965729",
        "altitude": "650",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "QNC": {
        "name": "Neuchatel Airport",
        "city": "Neuchatel",
        "country": "Switzerland",
        "iata": "QNC",
        "icao": "LSGN",
        "latitude": "46.9575004578",
        "longitude": "6.86471986771",
        "altitude": "1427",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "TGK": {
        "name": "Taganrog Yuzhny Airport",
        "city": "Taganrog",
        "country": "Russia",
        "iata": "TGK",
        "icao": "URRT",
        "latitude": "47.1983333",
        "longitude": "38.8491667",
        "altitude": "117",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "GDZ": {
        "name": "Gelendzhik Airport",
        "city": "Gelendzhik",
        "country": "Russia",
        "iata": "GDZ",
        "icao": "URKG",
        "latitude": "44.5820926295",
        "longitude": "38.0124807358",
        "altitude": "98",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ZIA": {
        "name": "Zhukovsky International Airport",
        "city": "Ramenskoe",
        "country": "Russia",
        "iata": "ZIA",
        "icao": "UUBW",
        "latitude": "55.553299",
        "longitude": "38.150002",
        "altitude": "377",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "IAR": {
        "name": "Tunoshna Airport",
        "city": "Yaroslavl",
        "country": "Russia",
        "iata": "IAR",
        "icao": "UUDL",
        "latitude": "57.560699462890625",
        "longitude": "40.15739822387695",
        "altitude": "287",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "OHE": {
        "name": "Gu-Lian Airport",
        "city": "Mohe County",
        "country": "China",
        "iata": "OHE",
        "icao": "ZYMH",
        "latitude": "52.912777777799995",
        "longitude": "122.43",
        "altitude": "1836",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JNG": {
        "name": "Jining Qufu Airport",
        "city": "Jining",
        "country": "China",
        "iata": "JNG",
        "icao": "ZLJN",
        "latitude": "35.292778",
        "longitude": "116.346667",
        "altitude": "134",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "DRK": {
        "name": "Drake Bay Airport",
        "city": "Puntarenas",
        "country": "Costa Rica",
        "iata": "DRK",
        "icao": "MRDK",
        "latitude": "8.71889019012",
        "longitude": "-83.6417007446",
        "altitude": "12",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Costa_Rica"
    },
    "AAT": {
        "name": "Altay Air Base",
        "city": "Altay",
        "country": "China",
        "iata": "AAT",
        "icao": "ZWAT",
        "latitude": "47.7498855591",
        "longitude": "88.08580780030002",
        "altitude": "2460",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TZL": {
        "name": "Tuzla International Airport",
        "city": "Null",
        "country": "Bosnia and Herzegovina",
        "iata": "TZL",
        "icao": "LQTZ",
        "latitude": "44.45869827270508",
        "longitude": "18.72480010986328",
        "altitude": "784",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Sarajevo"
    },
    "FWH": {
        "name": "NAS Fort Worth JRB/Carswell Field",
        "city": "Dallas",
        "country": "United States",
        "iata": "FWH",
        "icao": "KNFW",
        "latitude": "32.76919937",
        "longitude": "-97.4414978",
        "altitude": "650",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NYT": {
        "name": "Naypyidaw Airport",
        "city": "Naypyidaw",
        "country": "Burma",
        "iata": "NYT",
        "icao": "VYEL",
        "latitude": "19.623501",
        "longitude": "96.200996",
        "altitude": "302",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "VBP": {
        "name": "Bokpyinn Airport",
        "city": "Bokepyin",
        "country": "Burma",
        "iata": "VBP",
        "icao": "VYBP",
        "latitude": "11.1494",
        "longitude": "98.735901",
        "altitude": "100",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "NZH": {
        "name": "Manzhouli Xijiao Airport",
        "city": "Manzhouli",
        "country": "China",
        "iata": "NZH",
        "icao": "ZBMZ",
        "latitude": "49.566667",
        "longitude": "117.33",
        "altitude": "2231",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "WUA": {
        "name": "Wuhai Airport",
        "city": "Wuhai",
        "country": "China",
        "iata": "WUA",
        "icao": "ZBUH",
        "latitude": "39.7934",
        "longitude": "106.7993",
        "altitude": "3650",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "GYY": {
        "name": "Gary Chicago International Airport",
        "city": "Gary",
        "country": "United States",
        "iata": "GYY",
        "icao": "KGYY",
        "latitude": "41.61629867553711",
        "longitude": "-87.41280364990234",
        "altitude": "591",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BRD": {
        "name": "Brainerd Lakes Regional Airport",
        "city": "Brainerd",
        "country": "United States",
        "iata": "BRD",
        "icao": "KBRD",
        "latitude": "46.39830017",
        "longitude": "-94.13809967",
        "altitude": "1232",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Chicago"
    },
    "LWB": {
        "name": "Greenbrier Valley Airport",
        "city": "Lewisburg",
        "country": "United States",
        "iata": "LWB",
        "icao": "KLWB",
        "latitude": "37.8582992554",
        "longitude": "-80.3994979858",
        "altitude": "2302",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "PGV": {
        "name": "Pitt Greenville Airport",
        "city": "Greenville",
        "country": "United States",
        "iata": "PGV",
        "icao": "KPGV",
        "latitude": "35.6352005",
        "longitude": "-77.38529968",
        "altitude": "26",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CYF": {
        "name": "Chefornak Airport",
        "city": "Chefornak",
        "country": "United States",
        "iata": "CYF",
        "icao": "PACK",
        "latitude": "60.1492004395",
        "longitude": "-164.285995483",
        "altitude": "40",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OXR": {
        "name": "Oxnard Airport",
        "city": "Oxnard",
        "country": "United States",
        "iata": "OXR",
        "icao": "KOXR",
        "latitude": "34.200801849365",
        "longitude": "-119.20700073242",
        "altitude": "45",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BKG": {
        "name": "Branson Airport",
        "city": "Branson",
        "country": "United States",
        "iata": "BKG",
        "icao": "KBBG",
        "latitude": "36.532082",
        "longitude": "-93.200544",
        "altitude": "1302",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TEN": {
        "name": "Tongren Fenghuang Airport",
        "city": "Tongren",
        "country": "China",
        "iata": "TEN",
        "icao": "ZUTR",
        "latitude": "27.883333",
        "longitude": "109.308889",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "JGS": {
        "name": "Jinggangshan Airport",
        "city": "Jian",
        "country": "China",
        "iata": "JGS",
        "icao": "ZSJA",
        "latitude": "26.856899261499997",
        "longitude": "114.736999512",
        "altitude": "281",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "NIU": {
        "name": "Naiu Airport",
        "city": "Niau",
        "country": "French Polynesia",
        "iata": "NIU",
        "icao": "NTKN",
        "latitude": "-16.1191",
        "longitude": "-146.3683",
        "altitude": "50",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Tahiti"
    },
    "SCH": {
        "name": "Schenectady County Airport",
        "city": "Scotia NY",
        "country": "United States",
        "iata": "SCH",
        "icao": "KSCH",
        "latitude": "42.852500915527",
        "longitude": "-73.928901672363",
        "altitude": "378",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NBC": {
        "name": "Begishevo Airport",
        "city": "Nizhnekamsk",
        "country": "Russia",
        "iata": "NBC",
        "icao": "UWKE",
        "latitude": "55.564701080322266",
        "longitude": "52.092498779296875",
        "altitude": "643",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "QRW": {
        "name": "Warri Airport",
        "city": "Osubi",
        "country": "Nigeria",
        "iata": "QRW",
        "icao": "DNSU",
        "latitude": "5.59611",
        "longitude": "5.81778",
        "altitude": "242",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Lagos"
    },
    "IAO": {
        "name": "Siargao Airport",
        "city": "Siargao",
        "country": "Philippines",
        "iata": "IAO",
        "icao": "RPNS",
        "latitude": "9.8591",
        "longitude": "126.014",
        "altitude": "10",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "LGO": {
        "name": "Langeoog Airport",
        "city": "Langeoog",
        "country": "Germany",
        "iata": "LGO",
        "icao": "EDWL",
        "latitude": "53.74250030517578",
        "longitude": "7.497777938842773",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "NLP": {
        "name": "Nelspruit Airport",
        "city": "Nelspruit",
        "country": "South Africa",
        "iata": "NLP",
        "icao": "FANS",
        "latitude": "-25.5",
        "longitude": "30.9137992859",
        "altitude": "2875",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "CKC": {
        "name": "Cherkasy International Airport",
        "city": "Cherkassy",
        "country": "Ukraine",
        "iata": "CKC",
        "icao": "UKKE",
        "latitude": "49.41559982299805",
        "longitude": "31.99530029296875",
        "altitude": "375",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "UST": {
        "name": "Northeast Florida Regional Airport",
        "city": "St. Augustine Airport",
        "country": "United States",
        "iata": "UST",
        "icao": "KSGJ",
        "latitude": "29.9592",
        "longitude": "-81.339798",
        "altitude": "10",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NLV": {
        "name": "Mykolaiv International Airport",
        "city": "Nikolayev",
        "country": "Ukraine",
        "iata": "NLV",
        "icao": "UKON",
        "latitude": "47.057899475097656",
        "longitude": "31.9197998046875",
        "altitude": "184",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "RHP": {
        "name": "Ramechhap Airport",
        "city": "Ramechhap",
        "country": "Nepal",
        "iata": "RHP",
        "icao": "VNRC",
        "latitude": "27.393999099731445",
        "longitude": "86.0614013671875",
        "altitude": "1555",
        "timezone": "5.75",
        "dst": "U",
        "tz_name": "Asia/Katmandu"
    },
    "STS": {
        "name": "Charles M. Schulz Sonoma County Airport",
        "city": "Santa Rosa",
        "country": "United States",
        "iata": "STS",
        "icao": "KSTS",
        "latitude": "38.50899887",
        "longitude": "-122.8130035",
        "altitude": "128",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ISM": {
        "name": "Kissimmee Gateway Airport",
        "city": "Kissimmee",
        "country": "United States",
        "iata": "ISM",
        "icao": "KISM",
        "latitude": "28.2898006439",
        "longitude": "-81.4371032715",
        "altitude": "82",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LCQ": {
        "name": "Lake City Gateway Airport",
        "city": "Lake City",
        "country": "United States",
        "iata": "LCQ",
        "icao": "KLCQ",
        "latitude": "30.1819992065",
        "longitude": "-82.57689666750001",
        "altitude": "201",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LGU": {
        "name": "Logan-Cache Airport",
        "city": "Logan",
        "country": "United States",
        "iata": "LGU",
        "icao": "KLGU",
        "latitude": "41.7911987305",
        "longitude": "-111.851997375",
        "altitude": "4457",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BMC": {
        "name": "Brigham City Regional Airport",
        "city": "Brigham City",
        "country": "United States",
        "iata": "BMC",
        "icao": "KBMC",
        "latitude": "41.552399",
        "longitude": "-112.061996",
        "altitude": "4229",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ASE": {
        "name": "Aspen-Pitkin Co/Sardy Field",
        "city": "Aspen",
        "country": "United States",
        "iata": "ASE",
        "icao": "KASE",
        "latitude": "39.22320175",
        "longitude": "-106.8690033",
        "altitude": "7820",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ULV": {
        "name": "Ulyanovsk Baratayevka Airport",
        "city": "Ulyanovsk",
        "country": "Russia",
        "iata": "ULV",
        "icao": "UWLL",
        "latitude": "54.26829910279999",
        "longitude": "48.226699829100006",
        "altitude": "463",
        "timezone": "4",
        "dst": "N",
        "tz_name": "Europe/Samara"
    },
    "ERV": {
        "name": "Kerrville Municipal Louis Schreiner Field",
        "city": "Kerrville",
        "country": "United States",
        "iata": "ERV",
        "icao": "KERV",
        "latitude": "29.9766998291",
        "longitude": "-99.08570098879999",
        "altitude": "1617",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GED": {
        "name": "Sussex County Airport",
        "city": "Georgetown",
        "country": "United States",
        "iata": "GED",
        "icao": "KGED",
        "latitude": "38.68920135",
        "longitude": "-75.35890198",
        "altitude": "53",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ZSW": {
        "name": "Prince Rupert/Seal Cove Seaplane Base",
        "city": "Prince Rupert",
        "country": "Canada",
        "iata": "ZSW",
        "icao": "CZSW",
        "latitude": "54.33330154418945",
        "longitude": "-130.2830047607422",
        "altitude": "0",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "GBD": {
        "name": "Great Bend Municipal Airport",
        "city": "Great Bend",
        "country": "United States",
        "iata": "GBD",
        "icao": "KGBD",
        "latitude": "38.3442993164",
        "longitude": "-98.8591995239",
        "altitude": "1887",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HYS": {
        "name": "Hays Regional Airport",
        "city": "Hays",
        "country": "United States",
        "iata": "HYS",
        "icao": "KHYS",
        "latitude": "38.84220123",
        "longitude": "-99.27320099",
        "altitude": "1999",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SUS": {
        "name": "Spirit of St Louis Airport",
        "city": "Null",
        "country": "United States",
        "iata": "SUS",
        "icao": "KSUS",
        "latitude": "38.662101745605",
        "longitude": "-90.652000427246",
        "altitude": "463",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LYU": {
        "name": "Ely Municipal Airport",
        "city": "Ely",
        "country": "United States",
        "iata": "LYU",
        "icao": "KELO",
        "latitude": "47.82450104",
        "longitude": "-91.83070374",
        "altitude": "1456",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GPZ": {
        "name": "Grand Rapids Itasca Co-Gordon Newstrom field",
        "city": "Grand Rapids MN",
        "country": "United States",
        "iata": "GPZ",
        "icao": "KGPZ",
        "latitude": "47.21110153",
        "longitude": "-93.50980377",
        "altitude": "1355",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TVF": {
        "name": "Thief River Falls Regional Airport",
        "city": "Thief River Falls",
        "country": "United States",
        "iata": "TVF",
        "icao": "KTVF",
        "latitude": "48.06570053",
        "longitude": "-96.18499756",
        "altitude": "1119",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EGV": {
        "name": "Eagle River Union Airport",
        "city": "Eagle River",
        "country": "United States",
        "iata": "EGV",
        "icao": "KEGV",
        "latitude": "45.932300567599995",
        "longitude": "-89.26830291750001",
        "altitude": "1642",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ARV": {
        "name": "Lakeland-Noble F. Lee Memorial field",
        "city": "Minocqua - Woodruff",
        "country": "United States",
        "iata": "ARV",
        "icao": "KARV",
        "latitude": "45.92789841",
        "longitude": "-89.73090363",
        "altitude": "1629",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "YBV": {
        "name": "Berens River Airport",
        "city": "Berens River",
        "country": "Canada",
        "iata": "YBV",
        "icao": "CYBV",
        "latitude": "52.358898",
        "longitude": "-97.018303",
        "altitude": "728",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "AVX": {
        "name": "Catalina Airport",
        "city": "Catalina Island",
        "country": "United States",
        "iata": "AVX",
        "icao": "KAVX",
        "latitude": "33.4049",
        "longitude": "-118.416",
        "altitude": "1602",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MHV": {
        "name": "Mojave Airport",
        "city": "Mojave",
        "country": "United States",
        "iata": "MHV",
        "icao": "KMHV",
        "latitude": "35.05939865",
        "longitude": "-118.1520004",
        "altitude": "2801",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ZIN": {
        "name": "Interlaken Air Base",
        "city": "Interlaken",
        "country": "Switzerland",
        "iata": "ZIN",
        "icao": "LSMI",
        "latitude": "46.6766014",
        "longitude": "7.8790798",
        "altitude": "0",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "INQ": {
        "name": "Inisheer Aerodrome",
        "city": "Inisheer",
        "country": "Ireland",
        "iata": "INQ",
        "icao": "EIIR",
        "latitude": "53.064701080322266",
        "longitude": "-9.510899543762207",
        "altitude": "40",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "SWT": {
        "name": "Strezhevoy Airport",
        "city": "Strezhevoy",
        "country": "Russia",
        "iata": "SWT",
        "icao": "UNSS",
        "latitude": "60.709400177",
        "longitude": "77.66000366210001",
        "altitude": "164",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "HUT": {
        "name": "Hutchinson Municipal Airport",
        "city": "Hutchinson",
        "country": "United States",
        "iata": "HUT",
        "icao": "KHUT",
        "latitude": "38.0654983521",
        "longitude": "-97.86060333250002",
        "altitude": "1543",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OAI": {
        "name": "Bagram Air Base",
        "city": "Kabul",
        "country": "Afghanistan",
        "iata": "OAI",
        "icao": "OAIX",
        "latitude": "34.9460983276",
        "longitude": "69.26499938959999",
        "altitude": "4895",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "AKH": {
        "name": "Prince Sultan Air Base",
        "city": "Al Kharj",
        "country": "Saudi Arabia",
        "iata": "AKH",
        "icao": "OEPS",
        "latitude": "24.062700271606445",
        "longitude": "47.580501556396484",
        "altitude": "1651",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Asia/Riyadh"
    },
    "STJ": {
        "name": "Rosecrans Memorial Airport",
        "city": "Rosecrans",
        "country": "United States",
        "iata": "STJ",
        "icao": "KSTJ",
        "latitude": "39.771900177002",
        "longitude": "-94.909698486328",
        "altitude": "826",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "VOK": {
        "name": "Volk Field",
        "city": "Camp Douglas",
        "country": "United States",
        "iata": "VOK",
        "icao": "KVOK",
        "latitude": "43.938999176025",
        "longitude": "-90.253402709961",
        "altitude": "912",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GUC": {
        "name": "Gunnison Crested Butte Regional Airport",
        "city": "Gunnison",
        "country": "United States",
        "iata": "GUC",
        "icao": "KGUC",
        "latitude": "38.53390121",
        "longitude": "-106.9329987",
        "altitude": "7680",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SIA": {
        "name": "Xi'an Xiguan Airport",
        "city": "Xi\\'AN",
        "country": "China",
        "iata": "SIA",
        "icao": "ZLSN",
        "latitude": "34.376701",
        "longitude": "109.120003",
        "altitude": "0",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "TOA": {
        "name": "Zamperini Field",
        "city": "Torrance",
        "country": "United States",
        "iata": "TOA",
        "icao": "KTOA",
        "latitude": "33.803398132324",
        "longitude": "-118.33999633789",
        "altitude": "103",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MBL": {
        "name": "Manistee Co Blacker Airport",
        "city": "Manistee",
        "country": "United States",
        "iata": "MBL",
        "icao": "KMBL",
        "latitude": "44.2723999",
        "longitude": "-86.24690247",
        "altitude": "621",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PGD": {
        "name": "Charlotte County Airport",
        "city": "Punta Gorda",
        "country": "United States",
        "iata": "PGD",
        "icao": "KPGD",
        "latitude": "26.92020035",
        "longitude": "-81.9905014",
        "altitude": "26",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "WFK": {
        "name": "Northern Aroostook Regional Airport",
        "city": "Frenchville",
        "country": "United States",
        "iata": "WFK",
        "icao": "KFVE",
        "latitude": "47.2854995728",
        "longitude": "-68.31279754639999",
        "altitude": "988",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "JHW": {
        "name": "Chautauqua County-Jamestown Airport",
        "city": "Jamestown",
        "country": "United States",
        "iata": "JHW",
        "icao": "KJHW",
        "latitude": "42.15340042",
        "longitude": "-79.25800323",
        "altitude": "1723",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "YTM": {
        "name": "La Macaza / Mont-Tremblant International Inc Airport",
        "city": "Mont-Tremblant",
        "country": "Canada",
        "iata": "YTM",
        "icao": "CYFJ",
        "latitude": "46.409400939899996",
        "longitude": "-74.7799987793",
        "altitude": "827",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "SME": {
        "name": "Lake Cumberland Regional Airport",
        "city": "Somerset",
        "country": "United States",
        "iata": "SME",
        "icao": "KSME",
        "latitude": "37.053398132299996",
        "longitude": "-84.6158981323",
        "altitude": "927",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SHD": {
        "name": "Shenandoah Valley Regional Airport",
        "city": "Weyers Cave",
        "country": "United States",
        "iata": "SHD",
        "icao": "KSHD",
        "latitude": "38.2638015747",
        "longitude": "-78.8964004517",
        "altitude": "1201",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DVL": {
        "name": "Devils Lake Regional Airport",
        "city": "Devils Lake",
        "country": "United States",
        "iata": "DVL",
        "icao": "KDVL",
        "latitude": "48.11420059",
        "longitude": "-98.90879822",
        "altitude": "1456",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DIK": {
        "name": "Dickinson Theodore Roosevelt Regional Airport",
        "city": "Dickinson",
        "country": "United States",
        "iata": "DIK",
        "icao": "KDIK",
        "latitude": "46.7974014282",
        "longitude": "-102.802001953",
        "altitude": "2592",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SDY": {
        "name": "Sidney - Richland Regional Airport",
        "city": "Sidney",
        "country": "United States",
        "iata": "SDY",
        "icao": "KSDY",
        "latitude": "47.706902",
        "longitude": "-104.193001",
        "altitude": "1985",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CDR": {
        "name": "Chadron Municipal Airport",
        "city": "Chadron",
        "country": "United States",
        "iata": "CDR",
        "icao": "KCDR",
        "latitude": "42.837600708",
        "longitude": "-103.095001221",
        "altitude": "3297",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "AIA": {
        "name": "Alliance Municipal Airport",
        "city": "Alliance",
        "country": "United States",
        "iata": "AIA",
        "icao": "KAIA",
        "latitude": "42.0531997681",
        "longitude": "-102.804000854",
        "altitude": "3931",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MCK": {
        "name": "Mc Cook Ben Nelson Regional Airport",
        "city": "McCook",
        "country": "United States",
        "iata": "MCK",
        "icao": "KMCK",
        "latitude": "40.20629883",
        "longitude": "-100.5920029",
        "altitude": "2583",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MTH": {
        "name": "The Florida Keys Marathon Airport",
        "city": "Marathon",
        "country": "United States",
        "iata": "MTH",
        "icao": "KMTH",
        "latitude": "24.726101",
        "longitude": "-81.051399",
        "altitude": "5",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GDV": {
        "name": "Dawson Community Airport",
        "city": "Glendive",
        "country": "United States",
        "iata": "GDV",
        "icao": "KGDV",
        "latitude": "47.13869858",
        "longitude": "-104.8069992",
        "altitude": "2458",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "OLF": {
        "name": "L M Clayton Airport",
        "city": "Wolf Point",
        "country": "United States",
        "iata": "OLF",
        "icao": "KOLF",
        "latitude": "48.094501495399996",
        "longitude": "-105.574996948",
        "altitude": "1986",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "WYS": {
        "name": "Yellowstone Airport",
        "city": "West Yellowstone",
        "country": "United States",
        "iata": "WYS",
        "icao": "KWYS",
        "latitude": "44.68840027",
        "longitude": "-111.1179962",
        "altitude": "6649",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ALS": {
        "name": "San Luis Valley Regional Bergman Field",
        "city": "Alamosa",
        "country": "United States",
        "iata": "ALS",
        "icao": "KALS",
        "latitude": "37.434898",
        "longitude": "-105.866997",
        "altitude": "7539",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CNY": {
        "name": "Canyonlands Field",
        "city": "Moab",
        "country": "United States",
        "iata": "CNY",
        "icao": "KCNY",
        "latitude": "38.75500107",
        "longitude": "-109.7549973",
        "altitude": "4557",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "ELY": {
        "name": "Ely Airport Yelland Field",
        "city": "Ely",
        "country": "United States",
        "iata": "ELY",
        "icao": "KELY",
        "latitude": "39.29970169",
        "longitude": "-114.8420029",
        "altitude": "6259",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "VEL": {
        "name": "Vernal Regional Airport",
        "city": "Vernal",
        "country": "United States",
        "iata": "VEL",
        "icao": "KVEL",
        "latitude": "40.4408989",
        "longitude": "-109.5100021",
        "altitude": "5278",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "RUI": {
        "name": "Sierra Blanca Regional Airport",
        "city": "Ruidoso",
        "country": "United States",
        "iata": "RUI",
        "icao": "KSRR",
        "latitude": "33.462799072266",
        "longitude": "-105.53500366211",
        "altitude": "6814",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SOW": {
        "name": "Show Low Regional Airport",
        "city": "Show Low",
        "country": "United States",
        "iata": "SOW",
        "icao": "KSOW",
        "latitude": "34.265499115",
        "longitude": "-110.005996704",
        "altitude": "6415",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "MYL": {
        "name": "McCall Municipal Airport",
        "city": "McCall",
        "country": "United States",
        "iata": "MYL",
        "icao": "KMYL",
        "latitude": "44.88970184",
        "longitude": "-116.1009979",
        "altitude": "5024",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SMN": {
        "name": "Lemhi County Airport",
        "city": "Salmon",
        "country": "United States",
        "iata": "SMN",
        "icao": "KSMN",
        "latitude": "45.1237983704",
        "longitude": "-113.880996704",
        "altitude": "4043",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "MMH": {
        "name": "Mammoth Yosemite Airport",
        "city": "Mammoth Lakes",
        "country": "United States",
        "iata": "MMH",
        "icao": "KMMH",
        "latitude": "37.62409973",
        "longitude": "-118.8379974",
        "altitude": "7135",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "FRD": {
        "name": "Friday Harbor Airport",
        "city": "Friday Harbor",
        "country": "United States",
        "iata": "FRD",
        "icao": "KFHR",
        "latitude": "48.5219993591",
        "longitude": "-123.024002075",
        "altitude": "113",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ESD": {
        "name": "Orcas Island Airport",
        "city": "Eastsound",
        "country": "United States",
        "iata": "ESD",
        "icao": "KORS",
        "latitude": "48.7081985474",
        "longitude": "-122.910003662",
        "altitude": "31",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "AST": {
        "name": "Astoria Regional Airport",
        "city": "Astoria",
        "country": "United States",
        "iata": "AST",
        "icao": "KAST",
        "latitude": "46.158000946",
        "longitude": "-123.878997803",
        "altitude": "15",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ONP": {
        "name": "Newport Municipal Airport",
        "city": "Newport",
        "country": "United States",
        "iata": "ONP",
        "icao": "KONP",
        "latitude": "44.580399",
        "longitude": "-124.057999",
        "altitude": "160",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "EMK": {
        "name": "Emmonak Airport",
        "city": "Emmonak",
        "country": "United States",
        "iata": "EMK",
        "icao": "PAEM",
        "latitude": "62.78609848",
        "longitude": "-164.4909973",
        "altitude": "13",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "UNK": {
        "name": "Unalakleet Airport",
        "city": "Unalakleet",
        "country": "United States",
        "iata": "UNK",
        "icao": "PAUN",
        "latitude": "63.88840103",
        "longitude": "-160.798996",
        "altitude": "27",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "UUK": {
        "name": "Ugnu-Kuparuk Airport",
        "city": "Kuparuk",
        "country": "United States",
        "iata": "UUK",
        "icao": "PAKU",
        "latitude": "70.33080291750001",
        "longitude": "-149.598007202",
        "altitude": "67",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SHX": {
        "name": "Shageluk Airport",
        "city": "Shageluk",
        "country": "United States",
        "iata": "SHX",
        "icao": "PAHX",
        "latitude": "62.6922988892",
        "longitude": "-159.569000244",
        "altitude": "79",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CHU": {
        "name": "Chuathbaluk Airport",
        "city": "Chuathbaluk",
        "country": "United States",
        "iata": "CHU",
        "icao": "PACH",
        "latitude": "61.579102",
        "longitude": "-159.216003",
        "altitude": "244",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "NUI": {
        "name": "Nuiqsut Airport",
        "city": "Nuiqsut",
        "country": "United States",
        "iata": "NUI",
        "icao": "PAQT",
        "latitude": "70.2099990845",
        "longitude": "-151.005996704",
        "altitude": "38",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "EEK": {
        "name": "Eek Airport",
        "city": "Eek",
        "country": "United States",
        "iata": "EEK",
        "icao": "PAEE",
        "latitude": "60.21367264",
        "longitude": "-162.0438843",
        "altitude": "12",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KUK": {
        "name": "Kasigluk Airport",
        "city": "Kasigluk",
        "country": "United States",
        "iata": "KUK",
        "icao": "PFKA",
        "latitude": "60.87440109",
        "longitude": "-162.5240021",
        "altitude": "48",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KWT": {
        "name": "Kwethluk Airport",
        "city": "Kwethluk",
        "country": "United States",
        "iata": "KWT",
        "icao": "PFKW",
        "latitude": "60.790298461899994",
        "longitude": "-161.444000244",
        "altitude": "25",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KWK": {
        "name": "Kwigillingok Airport",
        "city": "Kwigillingok",
        "country": "United States",
        "iata": "KWK",
        "icao": "PAGG",
        "latitude": "59.876499",
        "longitude": "-163.169005",
        "altitude": "18",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MLL": {
        "name": "Marshall Don Hunter Sr Airport",
        "city": "Marshall",
        "country": "United States",
        "iata": "MLL",
        "icao": "PADM",
        "latitude": "61.8642997742",
        "longitude": "-162.026000977",
        "altitude": "103",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "RSH": {
        "name": "Russian Mission Airport",
        "city": "Russian Mission",
        "country": "United States",
        "iata": "RSH",
        "icao": "PARS",
        "latitude": "61.7788848877",
        "longitude": "-161.319458008",
        "altitude": "51",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KGK": {
        "name": "Koliganek Airport",
        "city": "Koliganek",
        "country": "United States",
        "iata": "KGK",
        "icao": "PAJZ",
        "latitude": "59.726600647",
        "longitude": "-157.259002686",
        "altitude": "269",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KMO": {
        "name": "Manokotak Airport",
        "city": "Manokotak",
        "country": "United States",
        "iata": "KMO",
        "icao": "PAMB",
        "latitude": "58.990200042699996",
        "longitude": "-159.050003052",
        "altitude": "100",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CIK": {
        "name": "Chalkyitsik Airport",
        "city": "Chalkyitsik",
        "country": "United States",
        "iata": "CIK",
        "icao": "PACI",
        "latitude": "66.6449966431",
        "longitude": "-143.740005493",
        "altitude": "544",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "EAA": {
        "name": "Eagle Airport",
        "city": "Eagle",
        "country": "United States",
        "iata": "EAA",
        "icao": "PAEG",
        "latitude": "64.77639771",
        "longitude": "-141.151001",
        "altitude": "908",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HUS": {
        "name": "Hughes Airport",
        "city": "Hughes",
        "country": "United States",
        "iata": "HUS",
        "icao": "PAHU",
        "latitude": "66.04109955",
        "longitude": "-154.2630005",
        "altitude": "299",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HSL": {
        "name": "Huslia Airport",
        "city": "Huslia",
        "country": "United States",
        "iata": "HSL",
        "icao": "PAHL",
        "latitude": "65.69789886",
        "longitude": "-156.3509979",
        "altitude": "220",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "NUL": {
        "name": "Nulato Airport",
        "city": "Nulato",
        "country": "United States",
        "iata": "NUL",
        "icao": "PANU",
        "latitude": "64.729301",
        "longitude": "-158.074005",
        "altitude": "399",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "VEE": {
        "name": "Venetie Airport",
        "city": "Venetie",
        "country": "United States",
        "iata": "VEE",
        "icao": "PAVE",
        "latitude": "67.0086975098",
        "longitude": "-146.365997314",
        "altitude": "574",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WBQ": {
        "name": "Beaver Airport",
        "city": "Beaver",
        "country": "United States",
        "iata": "WBQ",
        "icao": "PAWB",
        "latitude": "66.362197876",
        "longitude": "-147.406997681",
        "altitude": "359",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CEM": {
        "name": "Central Airport",
        "city": "Central",
        "country": "United States",
        "iata": "CEM",
        "icao": "PACE",
        "latitude": "65.57379913",
        "longitude": "-144.7830048",
        "altitude": "937",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SHG": {
        "name": "Shungnak Airport",
        "city": "Shungnak",
        "country": "United States",
        "iata": "SHG",
        "icao": "PAGH",
        "latitude": "66.88809967041",
        "longitude": "-157.16200256348",
        "altitude": "197",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "IYK": {
        "name": "Inyokern Airport",
        "city": "Inyokern",
        "country": "United States",
        "iata": "IYK",
        "icao": "KIYK",
        "latitude": "35.65879822",
        "longitude": "-117.8300018",
        "altitude": "2457",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "VIS": {
        "name": "Visalia Municipal Airport",
        "city": "Visalia",
        "country": "United States",
        "iata": "VIS",
        "icao": "KVIS",
        "latitude": "36.3186988831",
        "longitude": "-119.392997742",
        "altitude": "295",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MCE": {
        "name": "Merced Regional Macready Field",
        "city": "Merced",
        "country": "United States",
        "iata": "MCE",
        "icao": "KMCE",
        "latitude": "37.28469849",
        "longitude": "-120.5139999",
        "altitude": "155",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CYR": {
        "name": "Laguna de Los Patos International Airport",
        "city": "Colonia",
        "country": "Uruguay",
        "iata": "CYR",
        "icao": "SUCA",
        "latitude": "-34.456401824951",
        "longitude": "-57.770599365234",
        "altitude": "66",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Montevideo"
    },
    "CPQ": {
        "name": "Amarais Airport",
        "city": "Campinas",
        "country": "Brazil",
        "iata": "CPQ",
        "icao": "SDAM",
        "latitude": "-22.85919952392578",
        "longitude": "-47.10820007324219",
        "altitude": "2008",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "GYR": {
        "name": "Phoenix Goodyear Airport",
        "city": "Goodyear",
        "country": "United States",
        "iata": "GYR",
        "icao": "KGYR",
        "latitude": "33.4225006104",
        "longitude": "-112.375999451",
        "altitude": "968",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "TWB": {
        "name": "Toowoomba Airport",
        "city": "Toowoomba",
        "country": "Australia",
        "iata": "TWB",
        "icao": "YTWB",
        "latitude": "-27.542801",
        "longitude": "151.916",
        "altitude": "2086",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "BBL": {
        "name": "Ballera Airport",
        "city": "Ballera",
        "country": "Australia",
        "iata": "BBL",
        "icao": "YLLE",
        "latitude": "-27.405633",
        "longitude": "141.809458",
        "altitude": "385",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "AYK": {
        "name": "Arkalyk North Airport",
        "city": "Arkalyk",
        "country": "Kazakhstan",
        "iata": "AYK",
        "icao": "UAUR",
        "latitude": "50.318599700927734",
        "longitude": "66.95279693603516",
        "altitude": "1266",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "AGN": {
        "name": "Angoon Seaplane Base",
        "city": "Angoon",
        "country": "United States",
        "iata": "AGN",
        "icao": "PAGN",
        "latitude": "57.503601",
        "longitude": "-134.585007",
        "altitude": "0",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ELV": {
        "name": "Elfin Cove Seaplane Base",
        "city": "Elfin Cove",
        "country": "United States",
        "iata": "ELV",
        "icao": "PAEL",
        "latitude": "58.195201873799995",
        "longitude": "-136.347000122",
        "altitude": "0",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "FNR": {
        "name": "Funter Bay Seaplane Base",
        "city": "Funter Bay",
        "country": "United States",
        "iata": "FNR",
        "icao": "PANR",
        "latitude": "58.2543983459",
        "longitude": "-134.897994995",
        "altitude": "0",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HNH": {
        "name": "Hoonah Airport",
        "city": "Hoonah",
        "country": "United States",
        "iata": "HNH",
        "icao": "PAOH",
        "latitude": "58.0961",
        "longitude": "-135.410111",
        "altitude": "19",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MTM": {
        "name": "Metlakatla Seaplane Base",
        "city": "Metakatla",
        "country": "United States",
        "iata": "MTM",
        "icao": "PAMM",
        "latitude": "55.131001",
        "longitude": "-131.578003",
        "altitude": "0",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "HYG": {
        "name": "Hydaburg Seaplane Base",
        "city": "Hydaburg",
        "country": "United States",
        "iata": "HYG",
        "icao": "PAHY",
        "latitude": "55.206298828125",
        "longitude": "-132.8280029296875",
        "altitude": "0",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "EGX": {
        "name": "Egegik Airport",
        "city": "Egegik",
        "country": "United States",
        "iata": "EGX",
        "icao": "PAII",
        "latitude": "58.1855010986",
        "longitude": "-157.375",
        "altitude": "92",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KPV": {
        "name": "Perryville Airport",
        "city": "Perryville",
        "country": "United States",
        "iata": "KPV",
        "icao": "PAPE",
        "latitude": "55.905998",
        "longitude": "-159.162993",
        "altitude": "29",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PIP": {
        "name": "Pilot Point Airport",
        "city": "Pilot Point",
        "country": "United States",
        "iata": "PIP",
        "icao": "PAPN",
        "latitude": "57.5803985596",
        "longitude": "-157.572006226",
        "altitude": "57",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WSN": {
        "name": "South Naknek Nr 2 Airport",
        "city": "South Naknek",
        "country": "United States",
        "iata": "WSN",
        "icao": "PFWS",
        "latitude": "58.7033996582",
        "longitude": "-157.007995605",
        "altitude": "162",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "AKK": {
        "name": "Akhiok Airport",
        "city": "Akhiok",
        "country": "United States",
        "iata": "AKK",
        "icao": "PAKH",
        "latitude": "56.9387016296",
        "longitude": "-154.182998657",
        "altitude": "44",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KYK": {
        "name": "Karluk Airport",
        "city": "Karluk",
        "country": "United States",
        "iata": "KYK",
        "icao": "PAKY",
        "latitude": "57.5671005249",
        "longitude": "-154.449996948",
        "altitude": "137",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KLN": {
        "name": "Larsen Bay Airport",
        "city": "Larsen Bay",
        "country": "United States",
        "iata": "KLN",
        "icao": "PALB",
        "latitude": "57.5350990295",
        "longitude": "-153.977996826",
        "altitude": "87",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ABL": {
        "name": "Ambler Airport",
        "city": "Ambler",
        "country": "United States",
        "iata": "ABL",
        "icao": "PAFM",
        "latitude": "67.1063",
        "longitude": "-157.856989",
        "altitude": "334",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "BKC": {
        "name": "Buckland Airport",
        "city": "Buckland",
        "country": "United States",
        "iata": "BKC",
        "icao": "PABL",
        "latitude": "65.9815979004",
        "longitude": "-161.149002075",
        "altitude": "31",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "IAN": {
        "name": "Bob Baker Memorial Airport",
        "city": "Kiana",
        "country": "United States",
        "iata": "IAN",
        "icao": "PAIK",
        "latitude": "66.9759979248",
        "longitude": "-160.43699646",
        "altitude": "166",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OBU": {
        "name": "Kobuk Airport",
        "city": "Kobuk",
        "country": "United States",
        "iata": "OBU",
        "icao": "PAOB",
        "latitude": "66.9123001099",
        "longitude": "-156.897003174",
        "altitude": "137",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ORV": {
        "name": "Robert (Bob) Curtis Memorial Airport",
        "city": "Noorvik",
        "country": "United States",
        "iata": "ORV",
        "icao": "PFNO",
        "latitude": "66.81790161",
        "longitude": "-161.0189972",
        "altitude": "55",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WLK": {
        "name": "Selawik Airport",
        "city": "Selawik",
        "country": "United States",
        "iata": "WLK",
        "icao": "PASK",
        "latitude": "66.60009766",
        "longitude": "-159.9859924",
        "altitude": "17",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KTS": {
        "name": "Brevig Mission Airport",
        "city": "Brevig Mission",
        "country": "United States",
        "iata": "KTS",
        "icao": "PFKT",
        "latitude": "65.3312988281",
        "longitude": "-166.466003418",
        "altitude": "38",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "ELI": {
        "name": "Elim Airport",
        "city": "Elim",
        "country": "United States",
        "iata": "ELI",
        "icao": "PFEL",
        "latitude": "64.61470032",
        "longitude": "-162.2720032",
        "altitude": "162",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "GLV": {
        "name": "Golovin Airport",
        "city": "Golovin",
        "country": "United States",
        "iata": "GLV",
        "icao": "PAGL",
        "latitude": "64.5504989624",
        "longitude": "-163.007003784",
        "altitude": "59",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TLA": {
        "name": "Teller Airport",
        "city": "Teller",
        "country": "United States",
        "iata": "TLA",
        "icao": "PATE",
        "latitude": "65.2404022217",
        "longitude": "-166.339004517",
        "altitude": "294",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WAA": {
        "name": "Wales Airport",
        "city": "Wales",
        "country": "United States",
        "iata": "WAA",
        "icao": "PAIW",
        "latitude": "65.622593",
        "longitude": "-168.095",
        "altitude": "22",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "WMO": {
        "name": "White Mountain Airport",
        "city": "White Mountain",
        "country": "United States",
        "iata": "WMO",
        "icao": "PAWM",
        "latitude": "64.689201355",
        "longitude": "-163.412994385",
        "altitude": "267",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KKA": {
        "name": "Koyuk Alfred Adams Airport",
        "city": "Koyuk",
        "country": "United States",
        "iata": "KKA",
        "icao": "PAKK",
        "latitude": "64.9394989014",
        "longitude": "-161.154006958",
        "altitude": "154",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SMK": {
        "name": "St Michael Airport",
        "city": "St. Michael",
        "country": "United States",
        "iata": "SMK",
        "icao": "PAMK",
        "latitude": "63.49010086",
        "longitude": "-162.1100006",
        "altitude": "98",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SKK": {
        "name": "Shaktoolik Airport",
        "city": "Shaktoolik",
        "country": "United States",
        "iata": "SKK",
        "icao": "PFSH",
        "latitude": "64.37110138",
        "longitude": "-161.223999",
        "altitude": "24",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TNC": {
        "name": "Tin City Long Range Radar Station Airport",
        "city": "Tin City",
        "country": "United States",
        "iata": "TNC",
        "icao": "PATC",
        "latitude": "65.56310272",
        "longitude": "-167.9219971",
        "altitude": "271",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "AKB": {
        "name": "Atka Airport",
        "city": "Atka",
        "country": "United States",
        "iata": "AKB",
        "icao": "PAAK",
        "latitude": "52.22029877",
        "longitude": "-174.2059937",
        "altitude": "57",
        "timezone": "-10",
        "dst": "A",
        "tz_name": "America/Adak"
    },
    "IKO": {
        "name": "Nikolski Air Station",
        "city": "Nikolski",
        "country": "United States",
        "iata": "IKO",
        "icao": "PAKO",
        "latitude": "52.94160079956055",
        "longitude": "-168.8489990234375",
        "altitude": "77",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "CYT": {
        "name": "Yakataga Airport",
        "city": "Yakataga",
        "country": "United States",
        "iata": "CYT",
        "icao": "PACY",
        "latitude": "60.080974",
        "longitude": "-142.494541",
        "altitude": "12",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "AUK": {
        "name": "Alakanuk Airport",
        "city": "Alakanuk",
        "country": "United States",
        "iata": "AUK",
        "icao": "PAUK",
        "latitude": "62.680042266799994",
        "longitude": "-164.659927368",
        "altitude": "10",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KPN": {
        "name": "Kipnuk Airport",
        "city": "Kipnuk",
        "country": "United States",
        "iata": "KPN",
        "icao": "PAKI",
        "latitude": "59.932998657199995",
        "longitude": "-164.031005859",
        "altitude": "11",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KFP": {
        "name": "False Pass Airport",
        "city": "False Pass",
        "country": "United States",
        "iata": "KFP",
        "icao": "PAKF",
        "latitude": "54.8474006652832",
        "longitude": "-163.41000366210938",
        "altitude": "20",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "NLG": {
        "name": "Nelson Lagoon Airport",
        "city": "Nelson Lagoon",
        "country": "United States",
        "iata": "NLG",
        "icao": "PAOU",
        "latitude": "56.007499694824",
        "longitude": "-161.16000366211",
        "altitude": "14",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PML": {
        "name": "Port Moller Airport",
        "city": "Cold Bay",
        "country": "United States",
        "iata": "PML",
        "icao": "PAAL",
        "latitude": "56.0060005188",
        "longitude": "-160.561004639",
        "altitude": "20",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KLW": {
        "name": "Klawock Airport",
        "city": "Klawock",
        "country": "United States",
        "iata": "KLW",
        "icao": "PAKW",
        "latitude": "55.579200744599994",
        "longitude": "-133.076004028",
        "altitude": "80",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KWN": {
        "name": "Quinhagak Airport",
        "city": "Quinhagak",
        "country": "United States",
        "iata": "KWN",
        "icao": "PAQH",
        "latitude": "59.7551",
        "longitude": "-161.845",
        "altitude": "42",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KOT": {
        "name": "Kotlik Airport",
        "city": "Kotlik",
        "country": "United States",
        "iata": "KOT",
        "icao": "PFKO",
        "latitude": "63.0306015015",
        "longitude": "-163.533004761",
        "altitude": "15",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KYU": {
        "name": "Koyukuk Airport",
        "city": "Koyukuk",
        "country": "United States",
        "iata": "KYU",
        "icao": "PFKU",
        "latitude": "64.8760986328",
        "longitude": "-157.727005005",
        "altitude": "149",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SCM": {
        "name": "Scammon Bay Airport",
        "city": "Scammon Bay",
        "country": "United States",
        "iata": "SCM",
        "icao": "PACM",
        "latitude": "61.845298767100005",
        "longitude": "-165.570999146",
        "altitude": "14",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "NNL": {
        "name": "Nondalton Airport",
        "city": "Nondalton",
        "country": "United States",
        "iata": "NNL",
        "icao": "PANO",
        "latitude": "59.980201721191",
        "longitude": "-154.8390045166",
        "altitude": "314",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KKH": {
        "name": "Kongiganak Airport",
        "city": "Kongiganak",
        "country": "United States",
        "iata": "KKH",
        "icao": "PADY",
        "latitude": "59.960800170899994",
        "longitude": "-162.880996704",
        "altitude": "30",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "NIB": {
        "name": "Nikolai Airport",
        "city": "Nikolai",
        "country": "United States",
        "iata": "NIB",
        "icao": "PAFS",
        "latitude": "63.0186",
        "longitude": "-154.358002",
        "altitude": "441",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "AKI": {
        "name": "Akiak Airport",
        "city": "Akiak",
        "country": "United States",
        "iata": "AKI",
        "icao": "PFAK",
        "latitude": "60.9029006958",
        "longitude": "-161.231002808",
        "altitude": "30",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "AIN": {
        "name": "Wainwright Airport",
        "city": "Wainwright",
        "country": "United States",
        "iata": "AIN",
        "icao": "PAWI",
        "latitude": "70.6380004883",
        "longitude": "-159.994995117",
        "altitude": "41",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "APZ": {
        "name": "Zapala Airport",
        "city": "ZAPALA",
        "country": "Argentina",
        "iata": "APZ",
        "icao": "SAHZ",
        "latitude": "-38.975498",
        "longitude": "-70.113602",
        "altitude": "3330",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "RDS": {
        "name": "Rincon De Los Sauces Airport",
        "city": "Rincon de los Sauces",
        "country": "Argentina",
        "iata": "RDS",
        "icao": "SAHS",
        "latitude": "-37.3905982971",
        "longitude": "-68.9041976929",
        "altitude": "1968",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "PNT": {
        "name": "Tte. Julio Gallardo Airport",
        "city": "Puerto Natales",
        "country": "Chile",
        "iata": "PNT",
        "icao": "SCNT",
        "latitude": "-51.67150115966797",
        "longitude": "-72.52839660644531",
        "altitude": "217",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "SGV": {
        "name": "Sierra Grande Airport",
        "city": "Sierra Grande",
        "country": "Argentina",
        "iata": "SGV",
        "icao": "SAVS",
        "latitude": "-41.5917015076",
        "longitude": "-65.33940124509999",
        "altitude": "688",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "IGB": {
        "name": "Cabo F.A.A. H. R. Bordón Airport",
        "city": "Ingeniero Jacobacci",
        "country": "Argentina",
        "iata": "IGB",
        "icao": "SAVJ",
        "latitude": "-41.320899963399995",
        "longitude": "-69.5748977661",
        "altitude": "2925",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Argentina/Salta"
    },
    "NCN": {
        "name": "Chenega Bay Airport",
        "city": "Chenega",
        "country": "United States",
        "iata": "NCN",
        "icao": "PFCB",
        "latitude": "60.0773010254",
        "longitude": "-147.992004395",
        "altitude": "72",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "TKJ": {
        "name": "Tok Junction Airport",
        "city": "Tok",
        "country": "United States",
        "iata": "TKJ",
        "icao": "PFTO",
        "latitude": "63.32949829",
        "longitude": "-142.9539948",
        "altitude": "1639",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "IRC": {
        "name": "Circle City /New/ Airport",
        "city": "Circle",
        "country": "United States",
        "iata": "IRC",
        "icao": "PACR",
        "latitude": "65.830498",
        "longitude": "-144.076008",
        "altitude": "613",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "SLQ": {
        "name": "Sleetmute Airport",
        "city": "Sleetmute",
        "country": "United States",
        "iata": "SLQ",
        "icao": "PASL",
        "latitude": "61.7005004883",
        "longitude": "-157.166000366",
        "altitude": "190",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "LMA": {
        "name": "Minchumina Airport",
        "city": "Lake Minchumina",
        "country": "United States",
        "iata": "LMA",
        "icao": "PAMH",
        "latitude": "63.886002",
        "longitude": "-152.302002",
        "altitude": "678",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "MLY": {
        "name": "Manley Hot Springs Airport",
        "city": "Manley Hot Springs",
        "country": "United States",
        "iata": "MLY",
        "icao": "PAML",
        "latitude": "64.99759674069999",
        "longitude": "-150.643997192",
        "altitude": "270",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "YNP": {
        "name": "Natuashish Airport",
        "city": "Natuashish",
        "country": "Canada",
        "iata": "YNP",
        "icao": "CNH2",
        "latitude": "55.913898",
        "longitude": "-61.184399",
        "altitude": "30",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YSO": {
        "name": "Postville Airport",
        "city": "Postville",
        "country": "Canada",
        "iata": "YSO",
        "icao": "CCD4",
        "latitude": "54.9105",
        "longitude": "-59.78507",
        "altitude": "193",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "YWB": {
        "name": "Kangiqsujuaq (Wakeham Bay) Airport",
        "city": "Kangiqsujuaq",
        "country": "Canada",
        "iata": "YWB",
        "icao": "CYKG",
        "latitude": "61.5886001587",
        "longitude": "-71.929397583",
        "altitude": "501",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YTF": {
        "name": "Alma Airport",
        "city": "Alma",
        "country": "Canada",
        "iata": "YTF",
        "icao": "CYTF",
        "latitude": "48.50889968869999",
        "longitude": "-71.64189910889999",
        "altitude": "445",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YGV": {
        "name": "Havre St Pierre Airport",
        "city": "Havre-Saint-Pierre",
        "country": "Canada",
        "iata": "YGV",
        "icao": "CYGV",
        "latitude": "50.281898498535156",
        "longitude": "-63.61140060424805",
        "altitude": "124",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YXK": {
        "name": "Rimouski Airport",
        "city": "Rimouski",
        "country": "Canada",
        "iata": "YXK",
        "icao": "CYXK",
        "latitude": "48.47809982299805",
        "longitude": "-68.49690246582031",
        "altitude": "82",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "XTL": {
        "name": "Tadoule Lake Airport",
        "city": "Tadoule Lake",
        "country": "Canada",
        "iata": "XTL",
        "icao": "CYBQ",
        "latitude": "58.7061",
        "longitude": "-98.512199",
        "altitude": "923",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "XLB": {
        "name": "Lac Brochet Airport",
        "city": "Lac Brochet",
        "country": "Canada",
        "iata": "XLB",
        "icao": "CZWH",
        "latitude": "58.6175003052",
        "longitude": "-101.46900177",
        "altitude": "1211",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "XSI": {
        "name": "South Indian Lake Airport",
        "city": "South Indian Lake",
        "country": "Canada",
        "iata": "XSI",
        "icao": "CZSN",
        "latitude": "56.7928009033",
        "longitude": "-98.9072036743",
        "altitude": "951",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YBT": {
        "name": "Brochet Airport",
        "city": "Brochet",
        "country": "Canada",
        "iata": "YBT",
        "icao": "CYBT",
        "latitude": "57.8894",
        "longitude": "-101.679001",
        "altitude": "1136",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "ZGR": {
        "name": "Little Grand Rapids Airport",
        "city": "Little Grand Rapids",
        "country": "Canada",
        "iata": "ZGR",
        "icao": "CZGR",
        "latitude": "52.04560089111328",
        "longitude": "-95.4657974243164",
        "altitude": "1005",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YCR": {
        "name": "Cross Lake (Charlie Sinclair Memorial) Airport",
        "city": "Cross Lake",
        "country": "Canada",
        "iata": "YCR",
        "icao": "CYCR",
        "latitude": "54.610599517822266",
        "longitude": "-97.76080322265625",
        "altitude": "709",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YRS": {
        "name": "Red Sucker Lake Airport",
        "city": "Red Sucker Lake",
        "country": "Canada",
        "iata": "YRS",
        "icao": "CYRS",
        "latitude": "54.167198181152344",
        "longitude": "-93.55719757080078",
        "altitude": "729",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YOP": {
        "name": "Rainbow Lake Airport",
        "city": "Rainbow Lake",
        "country": "Canada",
        "iata": "YOP",
        "icao": "CYOP",
        "latitude": "58.49140167236328",
        "longitude": "-119.40799713134766",
        "altitude": "1759",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "YBY": {
        "name": "Bonnyville Airport",
        "city": "Bonnyville",
        "country": "Canada",
        "iata": "YBY",
        "icao": "CYBF",
        "latitude": "54.304199",
        "longitude": "-110.744003",
        "altitude": "1836",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "ZNA": {
        "name": "Nanaimo Harbour Water Airport",
        "city": "Nanaimo",
        "country": "Canada",
        "iata": "ZNA",
        "icao": "CAC8",
        "latitude": "49.1833000183",
        "longitude": "-123.949996948",
        "altitude": "0",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YGG": {
        "name": "Ganges Seaplane Base",
        "city": "Ganges",
        "country": "Canada",
        "iata": "YGG",
        "icao": "CAX6",
        "latitude": "48.8545",
        "longitude": "-123.4969",
        "altitude": "0",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YDT": {
        "name": "Boundary Bay Airport",
        "city": "Boundary Bay",
        "country": "Canada",
        "iata": "YDT",
        "icao": "CZBB",
        "latitude": "49.0742",
        "longitude": "-123.012001",
        "altitude": "6",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YLY": {
        "name": "Langley Airport",
        "city": "Langley Township",
        "country": "Canada",
        "iata": "YLY",
        "icao": "CYNJ",
        "latitude": "49.10079956049999",
        "longitude": "-122.630996704",
        "altitude": "34",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YFJ": {
        "name": "Wekweètì Airport",
        "city": "Wekweeti",
        "country": "Canada",
        "iata": "YFJ",
        "icao": "CFJ2",
        "latitude": "64.190804",
        "longitude": "-114.077002",
        "altitude": "1208",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "RNI": {
        "name": "Corn Island",
        "city": "Corn Island",
        "country": "Nicaragua",
        "iata": "RNI",
        "icao": "MNCI",
        "latitude": "12.1628999710083",
        "longitude": "-83.06379699707031",
        "altitude": "1",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "BZA": {
        "name": "San Pedro Airport",
        "city": "Bonanza",
        "country": "Nicaragua",
        "iata": "BZA",
        "icao": "MNBZ",
        "latitude": "13.949999809265137",
        "longitude": "-84.5999984741211",
        "altitude": "600",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "RFS": {
        "name": "Rosita Airport",
        "city": "Rosita",
        "country": "Nicaragua",
        "iata": "RFS",
        "icao": "MNRT",
        "latitude": "13.889699935913086",
        "longitude": "-84.40889739990234",
        "altitude": "193",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "SIU": {
        "name": "Siuna",
        "city": "Siuna",
        "country": "Nicaragua",
        "iata": "SIU",
        "icao": "MNSI",
        "latitude": "13.727222442626953",
        "longitude": "-84.77777862548828",
        "altitude": "606",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "WSP": {
        "name": "Waspam Airport",
        "city": "Waspam",
        "country": "Nicaragua",
        "iata": "WSP",
        "icao": "MNWP",
        "latitude": "14.7391996383667",
        "longitude": "-83.96939849853516",
        "altitude": "98",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "NCR": {
        "name": "San Carlos",
        "city": "San Carlos",
        "country": "Nicaragua",
        "iata": "NCR",
        "icao": "MNSC",
        "latitude": "11.133399963378906",
        "longitude": "-84.7699966430664",
        "altitude": "91",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Managua"
    },
    "PLD": {
        "name": "Playa Samara/Carrillo Airport",
        "city": "Carrillo",
        "country": "Costa Rica",
        "iata": "PLD",
        "icao": "MRCR",
        "latitude": "9.8705101013184",
        "longitude": "-85.481399536133",
        "altitude": "50",
        "timezone": "-6",
        "dst": "U",
        "tz_name": "America/Costa_Rica"
    },
    "COZ": {
        "name": "Constanza - Expedición 14 de Junio National Airport",
        "city": "Constanza",
        "country": "Dominican Republic",
        "iata": "COZ",
        "icao": "MDCZ",
        "latitude": "18.907499313354",
        "longitude": "-70.721900939941",
        "altitude": "3950",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "NEG": {
        "name": "Negril Airport",
        "city": "Negril",
        "country": "Jamaica",
        "iata": "NEG",
        "icao": "MKNG",
        "latitude": "18.34280014038086",
        "longitude": "-78.33209991455078",
        "altitude": "9",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Jamaica"
    },
    "NRR": {
        "name": "José Aponte de la Torre Airport",
        "city": "Ceiba",
        "country": "Puerto Rico",
        "iata": "NRR",
        "icao": "TJRV",
        "latitude": "18.245300293",
        "longitude": "-65.6434020996",
        "altitude": "38",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Puerto_Rico"
    },
    "SPB": {
        "name": "Charlotte Amalie Harbor Seaplane Base",
        "city": "Charlotte Amalie",
        "country": "Virgin Islands",
        "iata": "SPB",
        "icao": "VI22",
        "latitude": "18.338600158691406",
        "longitude": "-64.9406967163086",
        "altitude": "0",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/St_Thomas"
    },
    "ARR": {
        "name": "D. Casimiro Szlapelis Airport",
        "city": "Alto Rio Senguer",
        "country": "Argentina",
        "iata": "ARR",
        "icao": "SAVR",
        "latitude": "-45.013599",
        "longitude": "-70.812202",
        "altitude": "2286",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "JSM": {
        "name": "Jose De San Martin Airport",
        "city": "Jose de San Martin",
        "country": "Argentina",
        "iata": "JSM",
        "icao": "SAWS",
        "latitude": "-44.048599243199995",
        "longitude": "-70.4589004517",
        "altitude": "2407",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Catamarca"
    },
    "UYU": {
        "name": "Uyuni Airport",
        "city": "Uyuni",
        "country": "Bolivia",
        "iata": "UYU",
        "icao": "SLUY",
        "latitude": "-20.446300506599997",
        "longitude": "-66.8483963013",
        "altitude": "11136",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "RBQ": {
        "name": "Rurenabaque Airport",
        "city": "Rerrenabaque",
        "country": "Bolivia",
        "iata": "RBQ",
        "icao": "SLRQ",
        "latitude": "-14.427900314299999",
        "longitude": "-67.4968032837",
        "altitude": "676",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/La_Paz"
    },
    "ABF": {
        "name": "Abaiang Airport",
        "city": "Abaiang Atoll",
        "country": "Kiribati",
        "iata": "ABF",
        "icao": "NGAB",
        "latitude": "1.798609972000122",
        "longitude": "173.04100036621094",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "ABN": {
        "name": "Albina Airport",
        "city": "Albina",
        "country": "Suriname",
        "iata": "ABN",
        "icao": "SMBN",
        "latitude": "5.512720108032227",
        "longitude": "-54.05009841918945",
        "altitude": "19",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "DRJ": {
        "name": "Drietabbetje Airport",
        "city": "Drietabbetje",
        "country": "Suriname",
        "iata": "DRJ",
        "icao": "SMDA",
        "latitude": "4.111359",
        "longitude": "-54.672766",
        "altitude": "236",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "MOJ": {
        "name": "Moengo Airstrip",
        "city": "Moengo",
        "country": "Suriname",
        "iata": "MOJ",
        "icao": "SMMO",
        "latitude": "5.6076",
        "longitude": "-54.4003",
        "altitude": "49",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "ICK": {
        "name": "Nieuw Nickerie Airport",
        "city": "Nieuw Nickerie",
        "country": "Suriname",
        "iata": "ICK",
        "icao": "SMNI",
        "latitude": "5.955560207366943",
        "longitude": "-57.039398193359375",
        "altitude": "9",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "OEM": {
        "name": "Vincent Fayks Airport",
        "city": "Paloemeu",
        "country": "Suriname",
        "iata": "OEM",
        "icao": "SMPA",
        "latitude": "3.3452799320220947",
        "longitude": "-55.442501068115234",
        "altitude": "714",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "SMZ": {
        "name": "Stoelmanseiland Airport",
        "city": "Stoelmans Eiland",
        "country": "Suriname",
        "iata": "SMZ",
        "icao": "SMST",
        "latitude": "4.349999904632568",
        "longitude": "-54.41666793823242",
        "altitude": "187",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "TOT": {
        "name": "Totness Airport",
        "city": "Totness",
        "country": "Suriname",
        "iata": "TOT",
        "icao": "SMCO",
        "latitude": "5.865829944610596",
        "longitude": "-56.32749938964844",
        "altitude": "10",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "AGI": {
        "name": "Wageningen Airstrip",
        "city": "Wageningen",
        "country": "Suriname",
        "iata": "AGI",
        "icao": "SMWA",
        "latitude": "5.841128",
        "longitude": "-56.673328",
        "altitude": "6",
        "timezone": "-3",
        "dst": "U",
        "tz_name": "America/Paramaribo"
    },
    "ORJ": {
        "name": "Orinduik Airport",
        "city": "Orinduik",
        "country": "Guyana",
        "iata": "ORJ",
        "icao": "SYOR",
        "latitude": "4.725269794464111",
        "longitude": "-60.03499984741211",
        "altitude": "1797",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "NAI": {
        "name": "Annai Airport",
        "city": "Annai",
        "country": "Guyana",
        "iata": "NAI",
        "icao": "SYAN",
        "latitude": "3.959439992904663",
        "longitude": "-59.12419891357422",
        "altitude": "301",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "IMB": {
        "name": "Imbaimadai Airport",
        "city": "Imbaimadai",
        "country": "Guyana",
        "iata": "IMB",
        "icao": "SYIB",
        "latitude": "5.7081098556518555",
        "longitude": "-60.2942008972168",
        "altitude": "1646",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "KAR": {
        "name": "Kamarang Airport",
        "city": "Kamarang",
        "country": "Guyana",
        "iata": "KAR",
        "icao": "SYKM",
        "latitude": "5.865340232849121",
        "longitude": "-60.614200592041016",
        "altitude": "1601",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "USI": {
        "name": "Mabaruma Airport",
        "city": "Mabaruma",
        "country": "Guyana",
        "iata": "USI",
        "icao": "SYMB",
        "latitude": "8.199999809265137",
        "longitude": "-59.78329849243164",
        "altitude": "45",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "MHA": {
        "name": "Mahdia Airport",
        "city": "Mahdia",
        "country": "Guyana",
        "iata": "MHA",
        "icao": "SYMD",
        "latitude": "5.277490139007568",
        "longitude": "-59.151100158691406",
        "altitude": "300",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Guyana"
    },
    "PJC": {
        "name": "Dr Augusto Roberto Fuster International Airport",
        "city": "Pedro Juan Caballero",
        "country": "Paraguay",
        "iata": "PJC",
        "icao": "SGPJ",
        "latitude": "-22.639999389648438",
        "longitude": "-55.83000183105469",
        "altitude": "1873",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Asuncion"
    },
    "ACD": {
        "name": "Alcides Fernández Airport",
        "city": "Acandi",
        "country": "Colombia",
        "iata": "ACD",
        "icao": "SKAD",
        "latitude": "8.51667",
        "longitude": "-77.3",
        "altitude": "50",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "RVE": {
        "name": "Los Colonizadores Airport",
        "city": "Saravena",
        "country": "Colombia",
        "iata": "RVE",
        "icao": "SKSA",
        "latitude": "6.951868",
        "longitude": "-71.857179",
        "altitude": "680",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "BQJ": {
        "name": "Batagay Airport",
        "city": "Batagay",
        "country": "Russia",
        "iata": "BQJ",
        "icao": "UEBB",
        "latitude": "67.648002624512",
        "longitude": "134.69500732422",
        "altitude": "696",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "VGZ": {
        "name": "Villa Garzón Airport",
        "city": "Villa Garzon",
        "country": "Colombia",
        "iata": "VGZ",
        "icao": "SKVG",
        "latitude": "0.978767",
        "longitude": "-76.6056",
        "altitude": "1248",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "EBG": {
        "name": "El Bagre Airport",
        "city": "El Bagre",
        "country": "Colombia",
        "iata": "EBG",
        "icao": "SKEB",
        "latitude": "7.59647",
        "longitude": "-74.8089",
        "altitude": "180",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "CAQ": {
        "name": "Juan H White Airport",
        "city": "Caucasia",
        "country": "Colombia",
        "iata": "CAQ",
        "icao": "SKCU",
        "latitude": "7.96847",
        "longitude": "-75.1985",
        "altitude": "174",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "COG": {
        "name": "Mandinga Airport",
        "city": "Condoto",
        "country": "Colombia",
        "iata": "COG",
        "icao": "SKCD",
        "latitude": "5.08333",
        "longitude": "-76.7",
        "altitude": "213",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "TLU": {
        "name": "Golfo de Morrosquillo Airport",
        "city": "Tolu",
        "country": "Colombia",
        "iata": "TLU",
        "icao": "SKTL",
        "latitude": "9.50945",
        "longitude": "-75.5854",
        "altitude": "16",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Bogota"
    },
    "CFB": {
        "name": "Cabo Frio Airport",
        "city": "Cabo Frio",
        "country": "Brazil",
        "iata": "CFB",
        "icao": "SBCB",
        "latitude": "-22.921699523900003",
        "longitude": "-42.074298858599995",
        "altitude": "23",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "OPS": {
        "name": "Presidente João Batista Figueiredo Airport",
        "city": "Sinop",
        "country": "Brazil",
        "iata": "OPS",
        "icao": "SWSI",
        "latitude": "-11.885000228881836",
        "longitude": "-55.58610916137695",
        "altitude": "1227",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "GRP": {
        "name": "Gurupi Airport",
        "city": "Gurupi",
        "country": "Brazil",
        "iata": "GRP",
        "icao": "SWGI",
        "latitude": "-11.73960018157959",
        "longitude": "-49.132198333740234",
        "altitude": "1148",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CMP": {
        "name": "Santana do Araguaia Airport",
        "city": "Santana do Araguaia",
        "country": "Brazil",
        "iata": "CMP",
        "icao": "SNKE",
        "latitude": "-9.31997013092041",
        "longitude": "-50.32849884033203",
        "altitude": "597",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "BVS": {
        "name": "Breves Airport",
        "city": "Breves",
        "country": "Brazil",
        "iata": "BVS",
        "icao": "SNVS",
        "latitude": "-1.6365300416946411",
        "longitude": "-50.443599700927734",
        "altitude": "98",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "SFK": {
        "name": "Soure Airport",
        "city": "Soure",
        "country": "Brazil",
        "iata": "SFK",
        "icao": "SNSW",
        "latitude": "-0.6994310021400452",
        "longitude": "-48.520999908447266",
        "altitude": "43",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "PIN": {
        "name": "Parintins Airport",
        "city": "Parintins",
        "country": "Brazil",
        "iata": "PIN",
        "icao": "SWPI",
        "latitude": "-2.6730198860168457",
        "longitude": "-56.777198791503906",
        "altitude": "87",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "BRA": {
        "name": "Barreiras Airport",
        "city": "Barreiras",
        "country": "Brazil",
        "iata": "BRA",
        "icao": "SNBR",
        "latitude": "-12.078900337219238",
        "longitude": "-45.00899887084961",
        "altitude": "2447",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "STZ": {
        "name": "Santa Terezinha Airport",
        "city": "Santa Terezinha",
        "country": "Brazil",
        "iata": "STZ",
        "icao": "SWST",
        "latitude": "-10.4647216796875",
        "longitude": "-50.518611907958984",
        "altitude": "663",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "MQH": {
        "name": "Minaçu Airport",
        "city": "Minacu",
        "country": "Brazil",
        "iata": "MQH",
        "icao": "SBMC",
        "latitude": "-13.5491",
        "longitude": "-48.195301",
        "altitude": "1401",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "AUX": {
        "name": "Araguaína Airport",
        "city": "Araguaina",
        "country": "Brazil",
        "iata": "AUX",
        "icao": "SWGN",
        "latitude": "-7.22787",
        "longitude": "-48.240501",
        "altitude": "771",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "NVP": {
        "name": "Novo Aripuanã Airport",
        "city": "Novo Aripuana",
        "country": "Brazil",
        "iata": "NVP",
        "icao": "SWNA",
        "latitude": "-5.118030071258545",
        "longitude": "-60.364898681640625",
        "altitude": "118",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "FRC": {
        "name": "Tenente Lund Pressoto Airport",
        "city": "Franca",
        "country": "Brazil",
        "iata": "FRC",
        "icao": "SIMK",
        "latitude": "-20.592199",
        "longitude": "-47.3829",
        "altitude": "3292",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "DOU": {
        "name": "Dourados Airport",
        "city": "Dourados",
        "country": "Brazil",
        "iata": "DOU",
        "icao": "SSDO",
        "latitude": "-22.2019",
        "longitude": "-54.926601",
        "altitude": "1503",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "LBR": {
        "name": "Lábrea Airport",
        "city": "Labrea",
        "country": "Brazil",
        "iata": "LBR",
        "icao": "SWLB",
        "latitude": "-7.278969764709473",
        "longitude": "-64.76950073242188",
        "altitude": "190",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "ROO": {
        "name": "Maestro Marinho Franco Airport",
        "city": "Rondonopolis",
        "country": "Brazil",
        "iata": "ROO",
        "icao": "SWRD",
        "latitude": "-16.586",
        "longitude": "-54.7248",
        "altitude": "1467",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "GPB": {
        "name": "Tancredo Thomas de Faria Airport",
        "city": "Guarapuava",
        "country": "Brazil",
        "iata": "GPB",
        "icao": "SBGU",
        "latitude": "-25.3875007629",
        "longitude": "-51.520198822",
        "altitude": "3494",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "JCB": {
        "name": "Santa Terezinha Airport",
        "city": "Joacaba",
        "country": "Brazil",
        "iata": "JCB",
        "icao": "SSJA",
        "latitude": "-27.1714000702",
        "longitude": "-51.5532989502",
        "altitude": "2546",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "RVD": {
        "name": "General Leite de Castro Airport",
        "city": "Rio Verde",
        "country": "Brazil",
        "iata": "RVD",
        "icao": "SWLC",
        "latitude": "-17.8347225189209",
        "longitude": "-50.956111907958984",
        "altitude": "2464",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "AAX": {
        "name": "Romeu Zema Airport",
        "city": "Araxa",
        "country": "Brazil",
        "iata": "AAX",
        "icao": "SBAX",
        "latitude": "-19.563199996948",
        "longitude": "-46.960399627686",
        "altitude": "3276",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MBZ": {
        "name": "Maués Airport",
        "city": "Maues",
        "country": "Brazil",
        "iata": "MBZ",
        "icao": "SWMW",
        "latitude": "-3.37217",
        "longitude": "-57.7248",
        "altitude": "69",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "RBB": {
        "name": "Borba Airport",
        "city": "Borba",
        "country": "Brazil",
        "iata": "RBB",
        "icao": "SWBR",
        "latitude": "-4.4063401222229",
        "longitude": "-59.60240173339844",
        "altitude": "293",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "CIZ": {
        "name": "Coari Airport",
        "city": "Coari",
        "country": "Brazil",
        "iata": "CIZ",
        "icao": "SWKO",
        "latitude": "-4.134059906005859",
        "longitude": "-63.132598876953125",
        "altitude": "131",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "BAZ": {
        "name": "Barcelos Airport",
        "city": "Barcelos",
        "country": "Brazil",
        "iata": "BAZ",
        "icao": "SWBC",
        "latitude": "-0.981292",
        "longitude": "-62.919601",
        "altitude": "112",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "DMT": {
        "name": "Diamantino Airport",
        "city": "Diamantino",
        "country": "Brazil",
        "iata": "DMT",
        "icao": "SWDM",
        "latitude": "-14.376899719238281",
        "longitude": "-56.40039825439453",
        "altitude": "1476",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "GNM": {
        "name": "Guanambi Airport",
        "city": "Guanambi",
        "country": "Brazil",
        "iata": "GNM",
        "icao": "SNGI",
        "latitude": "-14.208200454711914",
        "longitude": "-42.74610137939453",
        "altitude": "1815",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "QDJ": {
        "name": "Tsletsi Airport",
        "city": "Djelfa",
        "country": "Algeria",
        "iata": "QDJ",
        "icao": "DAFI",
        "latitude": "34.6657",
        "longitude": "3.351",
        "altitude": "3753",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Algiers"
    },
    "NZA": {
        "name": "Nzagi Airport",
        "city": "Nzagi",
        "country": "Angola",
        "iata": "NZA",
        "icao": "FNZG",
        "latitude": "-7.716939926149999",
        "longitude": "21.358200073200003",
        "altitude": "2431",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Luanda"
    },
    "LBZ": {
        "name": "Lucapa Airport",
        "city": "Lucapa",
        "country": "Angola",
        "iata": "LBZ",
        "icao": "FNLK",
        "latitude": "-8.445727348330001",
        "longitude": "20.7320861816",
        "altitude": "3029",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Luanda"
    },
    "KNP": {
        "name": "Capanda Airport",
        "city": "Kapanda",
        "country": "Angola",
        "iata": "KNP",
        "icao": "FNCP",
        "latitude": "-9.76937007904",
        "longitude": "15.4553194046",
        "altitude": "3366",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Luanda"
    },
    "AMC": {
        "name": "Am Timan Airport",
        "city": "Am Timan",
        "country": "Chad",
        "iata": "AMC",
        "icao": "FTTN",
        "latitude": "11.0340003967",
        "longitude": "20.274000167799997",
        "altitude": "1420",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Ndjamena"
    },
    "GSQ": {
        "name": "Shark El Oweinat International Airport",
        "city": "Sharq Al-Owainat",
        "country": "Egypt",
        "iata": "GSQ",
        "icao": "HEOW",
        "latitude": "22.5856990814209",
        "longitude": "28.71660041809082",
        "altitude": "859",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Cairo"
    },
    "MRB": {
        "name": "Eastern WV Regional Airport/Shepherd Field",
        "city": "Martinsburg",
        "country": "United States",
        "iata": "MRB",
        "icao": "KMRB",
        "latitude": "39.40190125",
        "longitude": "-77.98459625",
        "altitude": "565",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AWA": {
        "name": "Awassa Airport",
        "city": "Awasa",
        "country": "Ethiopia",
        "iata": "AWA",
        "icao": "HALA",
        "latitude": "7.066999912261963",
        "longitude": "38.5",
        "altitude": "5450",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "JIJ": {
        "name": "Wilwal International Airport",
        "city": "Jijiga",
        "country": "Ethiopia",
        "iata": "JIJ",
        "icao": "HAJJ",
        "latitude": "9.3325",
        "longitude": "42.9121",
        "altitude": "5954",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "MKS": {
        "name": "Mekane Selam Airport",
        "city": "Mekane Selam",
        "country": "Ethiopia",
        "iata": "MKS",
        "icao": "HAMA",
        "latitude": "10.7254",
        "longitude": "38.7415",
        "altitude": "8405",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "DBM": {
        "name": "Debra Marcos Airport",
        "city": "Debre Marqos",
        "country": "Ethiopia",
        "iata": "DBM",
        "icao": "HADM",
        "latitude": "10.350000381469727",
        "longitude": "37.71699905395508",
        "altitude": "8136",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "DBT": {
        "name": "Debre Tabor Airport",
        "city": "Debre Tabor",
        "country": "Ethiopia",
        "iata": "DBT",
        "icao": "HADT",
        "latitude": "11.967000007629395",
        "longitude": "38",
        "altitude": "8490",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "QHR": {
        "name": "Harar Meda Airport",
        "city": "Debre Zeyit",
        "country": "Ethiopia",
        "iata": "QHR",
        "icao": "HAHM",
        "latitude": "8.7163",
        "longitude": "39.0059",
        "altitude": "6201",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "GOB": {
        "name": "Robe Airport",
        "city": "Goba",
        "country": "Ethiopia",
        "iata": "GOB",
        "icao": "HAGB",
        "latitude": "7.1160634",
        "longitude": "40.0463033",
        "altitude": "7892",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "MYB": {
        "name": "Mayumba Airport",
        "city": "Mayumba",
        "country": "Gabon",
        "iata": "MYB",
        "icao": "FOOY",
        "latitude": "-3.4584197998046875",
        "longitude": "10.674076080322266",
        "altitude": "13",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Libreville"
    },
    "MRE": {
        "name": "Mara Serena Lodge Airstrip",
        "city": "Masai Mara",
        "country": "Kenya",
        "iata": "MRE",
        "icao": "HKMS",
        "latitude": "-1.406111",
        "longitude": "35.008057",
        "altitude": "5200",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "JJM": {
        "name": "Mulika Lodge Airport",
        "city": "Meru National Park",
        "country": "Kenya",
        "iata": "JJM",
        "icao": "HKMK",
        "latitude": "0.165083006024",
        "longitude": "38.1951408386",
        "altitude": "2000",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "RBX": {
        "name": "Rumbek Airport",
        "city": "Rumbek",
        "country": "Sudan",
        "iata": "RBX",
        "icao": "HSMK",
        "latitude": "6.8249998092699995",
        "longitude": "29.6690006256",
        "altitude": "1378",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Juba"
    },
    "CPA": {
        "name": "Cape Palmas Airport",
        "city": "Greenville",
        "country": "Liberia",
        "iata": "CPA",
        "icao": "GLCP",
        "latitude": "4.3790202140808105",
        "longitude": "-7.6969499588012695",
        "altitude": "20",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Africa/Monrovia"
    },
    "IHC": {
        "name": "Inhaca Airport",
        "city": "Inhaca",
        "country": "Mozambique",
        "iata": "IHC",
        "icao": "FQIA",
        "latitude": "-25.9971446991",
        "longitude": "32.929351806599996",
        "altitude": "10",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Maputo"
    },
    "MAX": {
        "name": "Ouro Sogui Airport",
        "city": "Matam",
        "country": "Senegal",
        "iata": "MAX",
        "icao": "GOSM",
        "latitude": "15.593600273132324",
        "longitude": "-13.322799682617188",
        "altitude": "85",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Africa/Dakar"
    },
    "BDI": {
        "name": "Bird Island Airport",
        "city": "Bird Island",
        "country": "Seychelles",
        "iata": "BDI",
        "icao": "FSSB",
        "latitude": "-3.72472",
        "longitude": "55.205299",
        "altitude": "6",
        "timezone": "4",
        "dst": "U",
        "tz_name": null
    },
    "WHF": {
        "name": "Wadi Halfa Airport",
        "city": "Wadi Halfa",
        "country": "Sudan",
        "iata": "WHF",
        "icao": "HSSW",
        "latitude": "21.802698135375977",
        "longitude": "31.521577835083008",
        "altitude": "961",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Khartoum"
    },
    "NBE": {
        "name": "Enfidha - Hammamet International Airport",
        "city": "Enfidha",
        "country": "Tunisia",
        "iata": "NBE",
        "icao": "DTNH",
        "latitude": "36.075833",
        "longitude": "10.438611",
        "altitude": "21",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Tunis"
    },
    "HTY": {
        "name": "Hatay Airport",
        "city": "Hatay",
        "country": "Turkey",
        "iata": "HTY",
        "icao": "LTDA",
        "latitude": "36.36277771",
        "longitude": "36.282222747800006",
        "altitude": "269",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Europe/Istanbul"
    },
    "RVV": {
        "name": "Raivavae Airport",
        "city": "Raivavae",
        "country": "French Polynesia",
        "iata": "RVV",
        "icao": "NTAV",
        "latitude": "-23.885200500499998",
        "longitude": "-147.662002563",
        "altitude": "7",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Tahiti"
    },
    "FUO": {
        "name": "Foshan Shadi Airport",
        "city": "Foshan",
        "country": "China",
        "iata": "FUO",
        "icao": "ZGFS",
        "latitude": "23.083299636799996",
        "longitude": "113.069999695",
        "altitude": "6",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "HUZ": {
        "name": "Huizhou Airport",
        "city": "Huizhou",
        "country": "China",
        "iata": "HUZ",
        "icao": "ZGHZ",
        "latitude": "23.0499992371",
        "longitude": "114.599998474",
        "altitude": "50",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "ILD": {
        "name": "Lleida-Alguaire Airport",
        "city": "Lleida",
        "country": "Spain",
        "iata": "ILD",
        "icao": "LEDA",
        "latitude": "41.728185",
        "longitude": "0.535023",
        "altitude": "1170",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Madrid"
    },
    "BIU": {
        "name": "Bildudalur Airport",
        "city": "Bildudalur",
        "country": "Iceland",
        "iata": "BIU",
        "icao": "BIBD",
        "latitude": "65.64129638671875",
        "longitude": "-23.546199798583984",
        "altitude": "18",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "GJR": {
        "name": "Gjögur Airport",
        "city": "Gjogur",
        "country": "Iceland",
        "iata": "GJR",
        "icao": "BIGJ",
        "latitude": "65.99530029296875",
        "longitude": "-21.326900482177734",
        "altitude": "83",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "SAK": {
        "name": "Sauðárkrókur Airport",
        "city": "Saudarkrokur",
        "country": "Iceland",
        "iata": "SAK",
        "icao": "BIKR",
        "latitude": "65.73169708249999",
        "longitude": "-19.572799682599996",
        "altitude": "8",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Atlantic/Reykjavik"
    },
    "IIA": {
        "name": "Inishmaan Aerodrome",
        "city": "Inishmaan",
        "country": "Ireland",
        "iata": "IIA",
        "icao": "EIMN",
        "latitude": "53.09299850463867",
        "longitude": "-9.568059921264648",
        "altitude": "15",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Europe/Dublin"
    },
    "ULG": {
        "name": "Ulgii Mongolei Airport",
        "city": "Olgii",
        "country": "Mongolia",
        "iata": "ULG",
        "icao": "ZMUL",
        "latitude": "48.9933013916",
        "longitude": "89.9225006104",
        "altitude": "5732",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Hovd"
    },
    "KQT": {
        "name": "Qurghonteppa International Airport",
        "city": "Kurgan Tyube",
        "country": "Tajikistan",
        "iata": "KQT",
        "icao": "UTDT",
        "latitude": "37.86640167236328",
        "longitude": "68.86470031738281",
        "altitude": "1473",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Dushanbe"
    },
    "VGD": {
        "name": "Vologda Airport",
        "city": "Vologda",
        "country": "Russia",
        "iata": "VGD",
        "icao": "ULWW",
        "latitude": "59.282501220703125",
        "longitude": "39.944400787353516",
        "altitude": "387",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ONK": {
        "name": "Olenyok Airport",
        "city": "Olenyok",
        "country": "Russia",
        "iata": "ONK",
        "icao": "UERO",
        "latitude": "68.514999",
        "longitude": "112.480003",
        "altitude": "847",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "SYS": {
        "name": "Saskylakh Airport",
        "city": "Saskylakh",
        "country": "Russia",
        "iata": "SYS",
        "icao": "UERS",
        "latitude": "71.92790222168",
        "longitude": "114.08000183105",
        "altitude": "0",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "LDG": {
        "name": "Leshukonskoye Airport",
        "city": "Arkhangelsk",
        "country": "Russia",
        "iata": "LDG",
        "icao": "ULAL",
        "latitude": "64.8960037231",
        "longitude": "45.7229995728",
        "altitude": "220",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "HSK": {
        "name": "Huesca/Pirineos Airport",
        "city": "Huesca",
        "country": "Spain",
        "iata": "HSK",
        "icao": "LEHC",
        "latitude": "42.0760993958",
        "longitude": "-0.316666990519",
        "altitude": "1768",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Europe/Madrid"
    },
    "CQM": {
        "name": "Ciudad Real Central Airport",
        "city": "Ciudad Real",
        "country": "Spain",
        "iata": "CQM",
        "icao": "LERL",
        "latitude": "38.8563888889",
        "longitude": "-3.97",
        "altitude": "0",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Europe/Madrid"
    },
    "NJF": {
        "name": "Al Najaf International Airport",
        "city": "Najaf",
        "country": "Iraq",
        "iata": "NJF",
        "icao": "ORNI",
        "latitude": "31.989853",
        "longitude": "44.404317",
        "altitude": "103",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "CSA": {
        "name": "Colonsay Airstrip",
        "city": "Colonsay",
        "country": "United Kingdom",
        "iata": "CSA",
        "icao": "EGEY",
        "latitude": "56.0574989319",
        "longitude": "-6.243060112",
        "altitude": "44",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Europe/London"
    },
    "RKH": {
        "name": "Rock Hill - York County Airport",
        "city": "Rock Hill",
        "country": "United States",
        "iata": "RKH",
        "icao": "KUZA",
        "latitude": "34.9878006",
        "longitude": "-81.05719757",
        "altitude": "666",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AGC": {
        "name": "Allegheny County Airport",
        "city": "Pittsburgh",
        "country": "United States",
        "iata": "AGC",
        "icao": "KAGC",
        "latitude": "40.354400634765625",
        "longitude": "-79.9301986694336",
        "altitude": "1252",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "VQQ": {
        "name": "Cecil Airport",
        "city": "Jacksonville",
        "country": "United States",
        "iata": "VQQ",
        "icao": "KVQQ",
        "latitude": "30.2187004089",
        "longitude": "-81.876701355",
        "altitude": "81",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FTY": {
        "name": "Fulton County Airport Brown Field",
        "city": "Atlanta",
        "country": "United States",
        "iata": "FTY",
        "icao": "KFTY",
        "latitude": "33.7790985107",
        "longitude": "-84.5214004517",
        "altitude": "841",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TII": {
        "name": "Tarin Kowt Airport",
        "city": "Tarin Kowt",
        "country": "Afghanistan",
        "iata": "TII",
        "icao": "OATN",
        "latitude": "32.604198455799995",
        "longitude": "65.8657989502",
        "altitude": "4429",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "ZAJ": {
        "name": "Zaranj Airport",
        "city": "Zaranj",
        "country": "Afghanistan",
        "iata": "ZAJ",
        "icao": "OAZJ",
        "latitude": "30.972222",
        "longitude": "61.865833",
        "altitude": "1572",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "CCN": {
        "name": "Chakcharan Airport",
        "city": "Chaghcharan",
        "country": "Afghanistan",
        "iata": "CCN",
        "icao": "OACC",
        "latitude": "34.53300094604492",
        "longitude": "65.26699829101562",
        "altitude": "7383",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "FUG": {
        "name": "Fuyang Xiguan Airport",
        "city": "Fuyang",
        "country": "China",
        "iata": "FUG",
        "icao": "ZSFY",
        "latitude": "32.882157",
        "longitude": "115.734364",
        "altitude": "104",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LCX": {
        "name": "Longyan Guanzhishan Airport",
        "city": "Longyan",
        "country": "China",
        "iata": "LCX",
        "icao": "ZSLD",
        "latitude": "25.6746997833",
        "longitude": "116.747001648",
        "altitude": "1225",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "BSD": {
        "name": "Baoshan Yunduan Airport",
        "city": "Baoshan",
        "country": "China",
        "iata": "BSD",
        "icao": "ZPBS",
        "latitude": "25.053301",
        "longitude": "99.168297",
        "altitude": "5453",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ACX": {
        "name": "Xingyi Airport",
        "city": "Xingyi",
        "country": "China",
        "iata": "ACX",
        "icao": "ZUYI",
        "latitude": "25.0863888889",
        "longitude": "104.959444444",
        "altitude": "4150",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HZH": {
        "name": "Liping Airport",
        "city": "Liping",
        "country": "China",
        "iata": "HZH",
        "icao": "ZUNP",
        "latitude": "26.32217",
        "longitude": "109.1499",
        "altitude": "1620",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "OSU": {
        "name": "The Ohio State University Airport - Don Scott Field",
        "city": "Columbus",
        "country": "United States",
        "iata": "OSU",
        "icao": "KOSU",
        "latitude": "40.0798",
        "longitude": "-83.072998",
        "altitude": "905",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "ADS": {
        "name": "Addison Airport",
        "city": "Addison",
        "country": "United States",
        "iata": "ADS",
        "icao": "KADS",
        "latitude": "32.9686012268",
        "longitude": "-96.8364028931",
        "altitude": "644",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DSI": {
        "name": "Destin Executive Airport",
        "city": "Destin",
        "country": "United States",
        "iata": "DSI",
        "icao": "KDTS",
        "latitude": "30.40010071",
        "longitude": "-86.47149658",
        "altitude": "23",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KHE": {
        "name": "Kherson International Airport",
        "city": "Kherson",
        "country": "Ukraine",
        "iata": "KHE",
        "icao": "UKOH",
        "latitude": "46.6758",
        "longitude": "32.506401",
        "altitude": "148",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "SZS": {
        "name": "Ryan's Creek Aerodrome",
        "city": "Stewart Island",
        "country": "New Zealand",
        "iata": "SZS",
        "icao": "NZRC",
        "latitude": "-46.8997",
        "longitude": "168.100998",
        "altitude": "62",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Auckland"
    },
    "HJJ": {
        "name": "Zhijiang Airport",
        "city": "Zhijiang",
        "country": "China",
        "iata": "HJJ",
        "icao": "ZGCJ",
        "latitude": "27.4411111111",
        "longitude": "109.7",
        "altitude": "882",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "YQI": {
        "name": "Yarmouth Airport",
        "city": "Yarmouth",
        "country": "Canada",
        "iata": "YQI",
        "icao": "CYQI",
        "latitude": "43.826900482177734",
        "longitude": "-66.08809661865234",
        "altitude": "141",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "ISO": {
        "name": "Kinston Regional Jetport At Stallings Field",
        "city": "Kinston",
        "country": "United States",
        "iata": "ISO",
        "icao": "KISO",
        "latitude": "35.331401825",
        "longitude": "-77.60880279540001",
        "altitude": "93",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FFA": {
        "name": "First Flight Airport",
        "city": "Kill Devil Hills",
        "country": "United States",
        "iata": "FFA",
        "icao": "KFFA",
        "latitude": "36.0181999207",
        "longitude": "-75.67130279540001",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LNJ": {
        "name": "Lintsang Airfield",
        "city": "Lincang",
        "country": "China",
        "iata": "LNJ",
        "icao": "ZPLC",
        "latitude": "23.738100051900002",
        "longitude": "100.025001526",
        "altitude": "6230",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CKS": {
        "name": "Carajás Airport",
        "city": "Parauapebas",
        "country": "Brazil",
        "iata": "CKS",
        "icao": "SBCJ",
        "latitude": "-6.11527776718",
        "longitude": "-50.0013885498",
        "altitude": "2064",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "MWK": {
        "name": "Tarempa Airport",
        "city": "Anambas Islands",
        "country": "Indonesia",
        "iata": "MWK",
        "icao": "WIOM",
        "latitude": "3.3481199741363525",
        "longitude": "106.25800323486328",
        "altitude": "10",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "PGU": {
        "name": "Persian Gulf International Airport",
        "city": "Khalije Fars",
        "country": "Iran",
        "iata": "PGU",
        "icao": "OIBP",
        "latitude": "27.379601",
        "longitude": "52.737701",
        "altitude": "27",
        "timezone": "3.5",
        "dst": "U",
        "tz_name": "Asia/Tehran"
    },
    "YES": {
        "name": "Yasouj Airport",
        "city": "Yasuj",
        "country": "Iran",
        "iata": "YES",
        "icao": "OISY",
        "latitude": "30.700500488281",
        "longitude": "51.545101165771",
        "altitude": "5939",
        "timezone": "3.5",
        "dst": "U",
        "tz_name": "Asia/Tehran"
    },
    "OSM": {
        "name": "Mosul International Airport",
        "city": "Mosul",
        "country": "Iraq",
        "iata": "OSM",
        "icao": "ORBM",
        "latitude": "36.305801",
        "longitude": "43.1474",
        "altitude": "719",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Asia/Baghdad"
    },
    "TJH": {
        "name": "Tajima Airport",
        "city": "Toyooka",
        "country": "Japan",
        "iata": "TJH",
        "icao": "RJBT",
        "latitude": "35.51279830932617",
        "longitude": "134.78700256347656",
        "altitude": "584",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "AXJ": {
        "name": "Amakusa Airport",
        "city": "Amakusa",
        "country": "Japan",
        "iata": "AXJ",
        "icao": "RJDA",
        "latitude": "32.482498",
        "longitude": "130.158997",
        "altitude": "340",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "KKX": {
        "name": "Kikai Airport",
        "city": "Kikai",
        "country": "Japan",
        "iata": "KKX",
        "icao": "RJKI",
        "latitude": "28.321300506599997",
        "longitude": "129.927993774",
        "altitude": "21",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "AGJ": {
        "name": "Aguni Airport",
        "city": "Aguni",
        "country": "Japan",
        "iata": "AGJ",
        "icao": "RORA",
        "latitude": "26.5925006866",
        "longitude": "127.240997314",
        "altitude": "38",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Tokyo"
    },
    "ULZ": {
        "name": "Donoi Airport",
        "city": "Uliastai",
        "country": "Mongolia",
        "iata": "ULZ",
        "icao": "ZMDN",
        "latitude": "47.7093",
        "longitude": "96.5258",
        "altitude": "5800",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "UGA": {
        "name": "Bulgan Airport",
        "city": "Bulgan",
        "country": "Mongolia",
        "iata": "UGA",
        "icao": "ZMBN",
        "latitude": "48.85499954223633",
        "longitude": "103.47599792480469",
        "altitude": "4311",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Ulaanbaatar"
    },
    "ULO": {
        "name": "Ulaangom Airport",
        "city": "Ulaangom",
        "country": "Mongolia",
        "iata": "ULO",
        "icao": "ZMUG",
        "latitude": "50.066588",
        "longitude": "91.938273",
        "altitude": "0",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Hovd"
    },
    "LBX": {
        "name": "Lubang Airport",
        "city": "Lubang",
        "country": "Philippines",
        "iata": "LBX",
        "icao": "RPLU",
        "latitude": "13.855400085449219",
        "longitude": "120.1050033569336",
        "altitude": "43",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Manila"
    },
    "TJU": {
        "name": "Kulob Airport",
        "city": "Kulyab",
        "country": "Tajikistan",
        "iata": "TJU",
        "icao": "UTDK",
        "latitude": "37.98809814453125",
        "longitude": "69.80500030517578",
        "altitude": "2293",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Dushanbe"
    },
    "CMJ": {
        "name": "Chi Mei Airport",
        "city": "Cimei",
        "country": "Taiwan",
        "iata": "CMJ",
        "icao": "RCCM",
        "latitude": "23.2131004333",
        "longitude": "119.417999268",
        "altitude": "63",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Taipei"
    },
    "TAZ": {
        "name": "Daşoguz Airport",
        "city": "Dasoguz",
        "country": "Turkmenistan",
        "iata": "TAZ",
        "icao": "UTAT",
        "latitude": "41.761101",
        "longitude": "59.826698",
        "altitude": "272",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Asia/Ashgabat"
    },
    "BWB": {
        "name": "Barrow Island Airport",
        "city": "Barrow Island",
        "country": "Australia",
        "iata": "BWB",
        "icao": "YBWX",
        "latitude": "-20.86440086364746",
        "longitude": "115.40599822998047",
        "altitude": "26",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Australia/Perth"
    },
    "DRB": {
        "name": "Derby Airport",
        "city": "Derby",
        "country": "Australia",
        "iata": "DRB",
        "icao": "YDBY",
        "latitude": "-17.3700008392334",
        "longitude": "123.66100311279297",
        "altitude": "24",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Australia/Perth"
    },
    "WGE": {
        "name": "Walgett Airport",
        "city": "Walgett",
        "country": "Australia",
        "iata": "WGE",
        "icao": "YWLG",
        "latitude": "-30.032800674438477",
        "longitude": "148.12600708007812",
        "altitude": "439",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Sydney"
    },
    "BRT": {
        "name": "Bathurst Island Airport",
        "city": "Bathurst Island",
        "country": "Australia",
        "iata": "BRT",
        "icao": "YBTI",
        "latitude": "-11.769200325012207",
        "longitude": "130.6199951171875",
        "altitude": "67",
        "timezone": "9.5",
        "dst": "U",
        "tz_name": "Australia/Darwin"
    },
    "DKI": {
        "name": "Dunk Island Airport",
        "city": "Dunk Island",
        "country": "Australia",
        "iata": "DKI",
        "icao": "YDKI",
        "latitude": "-17.9416999817",
        "longitude": "146.13999939",
        "altitude": "6",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Brisbane"
    },
    "LZR": {
        "name": "Lizard Island Airport",
        "city": "Lizard Island",
        "country": "Australia",
        "iata": "LZR",
        "icao": "YLZI",
        "latitude": "-14.673273",
        "longitude": "145.454571",
        "altitude": "70",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "HLT": {
        "name": "Hamilton Airport",
        "city": "Hamilton",
        "country": "Australia",
        "iata": "HLT",
        "icao": "YHML",
        "latitude": "-37.64889907836914",
        "longitude": "142.06500244140625",
        "altitude": "803",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "HCQ": {
        "name": "Halls Creek Airport",
        "city": "Halls Creek",
        "country": "Australia",
        "iata": "HCQ",
        "icao": "YHLC",
        "latitude": "-18.23390007019043",
        "longitude": "127.66999816894531",
        "altitude": "1346",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Australia/Perth"
    },
    "FIZ": {
        "name": "Fitzroy Crossing Airport",
        "city": "Fitzroy Crossing",
        "country": "Australia",
        "iata": "FIZ",
        "icao": "YFTZ",
        "latitude": "-18.181900024414062",
        "longitude": "125.55899810791016",
        "altitude": "368",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Australia/Perth"
    },
    "RVT": {
        "name": "Ravensthorpe Airport",
        "city": "Ravensthorpe",
        "country": "Australia",
        "iata": "RVT",
        "icao": "YNRV",
        "latitude": "-33.7971992493",
        "longitude": "120.208000183",
        "altitude": "197",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Australia/Perth"
    },
    "PVU": {
        "name": "Provo Municipal Airport",
        "city": "Provo",
        "country": "United States",
        "iata": "PVU",
        "icao": "KPVU",
        "latitude": "40.219200134277",
        "longitude": "-111.72299957275",
        "altitude": "4497",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SBS": {
        "name": "Steamboat Springs Bob Adams Field",
        "city": "Steamboat Springs",
        "country": "United States",
        "iata": "SBS",
        "icao": "KSBS",
        "latitude": "40.5163002",
        "longitude": "-106.8659973",
        "altitude": "6882",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "DTA": {
        "name": "Delta Municipal Airport",
        "city": "Delta",
        "country": "United States",
        "iata": "DTA",
        "icao": "KDTA",
        "latitude": "39.3805999756",
        "longitude": "-112.508003235",
        "altitude": "4759",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PUC": {
        "name": "Carbon County Regional/Buck Davis Field",
        "city": "Price",
        "country": "United States",
        "iata": "PUC",
        "icao": "KPUC",
        "latitude": "39.61389923",
        "longitude": "-110.7509995",
        "altitude": "5957",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "LAM": {
        "name": "Los Alamos Airport",
        "city": "Los Alamos",
        "country": "United States",
        "iata": "LAM",
        "icao": "KLAM",
        "latitude": "35.8797988892",
        "longitude": "-106.268997192",
        "altitude": "7171",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "HII": {
        "name": "Lake Havasu City Airport",
        "city": "Lake Havasu City",
        "country": "United States",
        "iata": "HII",
        "icao": "KHII",
        "latitude": "34.571098",
        "longitude": "-114.358002",
        "altitude": "783",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "INW": {
        "name": "Winslow Lindbergh Regional Airport",
        "city": "Winslow",
        "country": "United States",
        "iata": "INW",
        "icao": "KINW",
        "latitude": "35.021900177",
        "longitude": "-110.722999573",
        "altitude": "4941",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "DGL": {
        "name": "Douglas Municipal Airport",
        "city": "Douglas",
        "country": "United States",
        "iata": "DGL",
        "icao": "KDGL",
        "latitude": "31.3425998688",
        "longitude": "-109.505996704",
        "altitude": "4173",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "MZK": {
        "name": "Marakei Airport",
        "city": "Marakei",
        "country": "Kiribati",
        "iata": "MZK",
        "icao": "NGMK",
        "latitude": "2.058609962463379",
        "longitude": "173.27099609375",
        "altitude": "10",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "AEA": {
        "name": "Abemama Atoll Airport",
        "city": "Abemama",
        "country": "Kiribati",
        "iata": "AEA",
        "icao": "NGTB",
        "latitude": "0.49083301424980164",
        "longitude": "173.82899475097656",
        "altitude": "8",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "AAK": {
        "name": "Buariki Airport",
        "city": "Buariki",
        "country": "Kiribati",
        "iata": "AAK",
        "icao": "NGUK",
        "latitude": "0.1852779984474182",
        "longitude": "173.63699340820312",
        "altitude": "0",
        "timezone": "10",
        "dst": "U",
        "tz_name": null
    },
    "KUC": {
        "name": "Kuria Airport",
        "city": "Kuria",
        "country": "Kiribati",
        "iata": "KUC",
        "icao": "NGKT",
        "latitude": "0.2186110019683838",
        "longitude": "173.44200134277344",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "AIS": {
        "name": "Arorae Island Airport",
        "city": "Arorae",
        "country": "Kiribati",
        "iata": "AIS",
        "icao": "NGTR",
        "latitude": "-2.61611008644104",
        "longitude": "176.80299377441406",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "TMN": {
        "name": "Tamana Island Airport",
        "city": "Tamana",
        "country": "Kiribati",
        "iata": "TMN",
        "icao": "NGTM",
        "latitude": "-2.485830068588257",
        "longitude": "175.97000122070312",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "BEZ": {
        "name": "Beru Airport",
        "city": "Beru Island",
        "country": "Kiribati",
        "iata": "BEZ",
        "icao": "NGBR",
        "latitude": "-1.3547199964523315",
        "longitude": "176.0070037841797",
        "altitude": "6",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "NIG": {
        "name": "Nikunau Airport",
        "city": "Nikunau",
        "country": "Kiribati",
        "iata": "NIG",
        "icao": "NGNU",
        "latitude": "-1.31444001198",
        "longitude": "176.410003662",
        "altitude": "6",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "BBG": {
        "name": "Butaritari Atoll Airport",
        "city": "Butaritari",
        "country": "Kiribati",
        "iata": "BBG",
        "icao": "NGTU",
        "latitude": "3.08583",
        "longitude": "172.811005",
        "altitude": "5",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "MTK": {
        "name": "Makin Island Airport",
        "city": "Makin",
        "country": "Kiribati",
        "iata": "MTK",
        "icao": "NGMN",
        "latitude": "3.3744399547576904",
        "longitude": "172.99200439453125",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "MNK": {
        "name": "Maiana Airport",
        "city": "Maiana",
        "country": "Kiribati",
        "iata": "MNK",
        "icao": "NGMA",
        "latitude": "1.0036100149154663",
        "longitude": "173.031005859375",
        "altitude": "8",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "NON": {
        "name": "Nonouti Airport",
        "city": "Nonouti",
        "country": "Kiribati",
        "iata": "NON",
        "icao": "NGTO",
        "latitude": "-0.6397219896316528",
        "longitude": "174.42799377441406",
        "altitude": "10",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "TSU": {
        "name": "Tabiteuea South Airport",
        "city": "Tabiteuea",
        "country": "Kiribati",
        "iata": "TSU",
        "icao": "NGTS",
        "latitude": "-1.4744399785995483",
        "longitude": "175.06399536132812",
        "altitude": "0",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Tarawa"
    },
    "WTZ": {
        "name": "Whitianga Airport",
        "city": "Whitianga",
        "country": "New Zealand",
        "iata": "WTZ",
        "icao": "NZWT",
        "latitude": "-36.83169937133789",
        "longitude": "175.6790008544922",
        "altitude": "10",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Auckland"
    },
    "KTF": {
        "name": "Takaka Airport",
        "city": "Takaka",
        "country": "New Zealand",
        "iata": "KTF",
        "icao": "NZTK",
        "latitude": "-40.81330108642578",
        "longitude": "172.77499389648438",
        "altitude": "102",
        "timezone": "12",
        "dst": "U",
        "tz_name": "Pacific/Auckland"
    },
    "AFT": {
        "name": "Afutara Aerodrome",
        "city": "Afutara",
        "country": "Solomon Islands",
        "iata": "AFT",
        "icao": "AGAF",
        "latitude": "-9.19138888889",
        "longitude": "160.948611111",
        "altitude": "23",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "RNA": {
        "name": "Ulawa Airport",
        "city": "Ulawa",
        "country": "Solomon Islands",
        "iata": "RNA",
        "icao": "AGAR",
        "latitude": "-9.86054358262",
        "longitude": "161.979546547",
        "altitude": "40",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "CHY": {
        "name": "Choiseul Bay Airport",
        "city": "Choiseul Bay",
        "country": "Solomon Islands",
        "iata": "CHY",
        "icao": "AGGC",
        "latitude": "-6.711944",
        "longitude": "156.396111",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "NNB": {
        "name": "Santa Ana Airport",
        "city": "Santa Ana",
        "country": "Solomon Islands",
        "iata": "NNB",
        "icao": "AGGT",
        "latitude": "-10.847994",
        "longitude": "162.454108",
        "altitude": "3",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "XYA": {
        "name": "Yandina Airport",
        "city": "Yandina",
        "country": "Solomon Islands",
        "iata": "XYA",
        "icao": "AGGY",
        "latitude": "-9.092816",
        "longitude": "159.21841",
        "altitude": "60",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "BPF": {
        "name": "Batuna Aerodrome",
        "city": "Batuna",
        "country": "Solomon Islands",
        "iata": "BPF",
        "icao": "AGBT",
        "latitude": "-8.56202777778",
        "longitude": "158.119305556",
        "altitude": "24",
        "timezone": "11",
        "dst": "U",
        "tz_name": "Pacific/Guadalcanal"
    },
    "BOW": {
        "name": "Bartow Municipal Airport",
        "city": "Bartow",
        "country": "United States",
        "iata": "BOW",
        "icao": "KBOW",
        "latitude": "27.943399429299998",
        "longitude": "-81.78340148930002",
        "altitude": "125",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "FTI": {
        "name": "Fitiuta Airport",
        "city": "Fiti\\'uta",
        "country": "American Samoa",
        "iata": "FTI",
        "icao": "NSFQ",
        "latitude": "-14.2172",
        "longitude": "-169.425003",
        "altitude": "110",
        "timezone": "-11",
        "dst": "U",
        "tz_name": "Pacific/Pago_Pago"
    },
    "LVK": {
        "name": "Livermore Municipal Airport",
        "city": "Livermore",
        "country": "United States",
        "iata": "LVK",
        "icao": "KLVK",
        "latitude": "37.6934013367",
        "longitude": "-121.819999695",
        "altitude": "400",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RMY": {
        "name": "Mariposa Yosemite Airport",
        "city": "Mariposa",
        "country": "United States",
        "iata": "RMY",
        "icao": "KMPI",
        "latitude": "37.5108985901",
        "longitude": "-120.040000916",
        "altitude": "2254",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GFY": {
        "name": "Grootfontein Airport",
        "city": "Grootfontein",
        "country": "Namibia",
        "iata": "GFY",
        "icao": "FYGF",
        "latitude": "-19.60219955444336",
        "longitude": "18.122699737548828",
        "altitude": "4636",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "NDU": {
        "name": "Rundu Airport",
        "city": "Rundu",
        "country": "Namibia",
        "iata": "NDU",
        "icao": "FYRU",
        "latitude": "-17.956499099731",
        "longitude": "19.719400405884",
        "altitude": "3627",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "TRM": {
        "name": "Jacqueline Cochran Regional Airport",
        "city": "Palm Springs",
        "country": "United States",
        "iata": "TRM",
        "icao": "KTRM",
        "latitude": "33.62670135498",
        "longitude": "-116.16000366211",
        "altitude": "-115",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SMO": {
        "name": "Santa Monica Municipal Airport",
        "city": "Santa Monica",
        "country": "United States",
        "iata": "SMO",
        "icao": "KSMO",
        "latitude": "34.015800476100004",
        "longitude": "-118.450996399",
        "altitude": "177",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "UDD": {
        "name": "Bermuda Dunes Airport",
        "city": "Palm Springs",
        "country": "United States",
        "iata": "UDD",
        "icao": "KUDD",
        "latitude": "33.748401641846",
        "longitude": "-116.27500152588",
        "altitude": "73",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SCF": {
        "name": "Scottsdale Airport",
        "city": "Scottsdale",
        "country": "United States",
        "iata": "SCF",
        "icao": "KSDL",
        "latitude": "33.622898101807",
        "longitude": "-111.91100311279",
        "altitude": "1510",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "OLM": {
        "name": "Olympia Regional Airport",
        "city": "Olympia",
        "country": "United States",
        "iata": "OLM",
        "icao": "KOLM",
        "latitude": "46.9693985",
        "longitude": "-122.9029999",
        "altitude": "209",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RIL": {
        "name": "Garfield County Regional Airport",
        "city": "Rifle",
        "country": "United States",
        "iata": "RIL",
        "icao": "KRIL",
        "latitude": "39.526299",
        "longitude": "-107.726997",
        "altitude": "5548",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SAA": {
        "name": "Shively Field",
        "city": "SARATOGA",
        "country": "United States",
        "iata": "SAA",
        "icao": "KSAA",
        "latitude": "41.444901",
        "longitude": "-106.823997",
        "altitude": "7012",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "PDK": {
        "name": "DeKalb Peachtree Airport",
        "city": "Atlanta",
        "country": "United States",
        "iata": "PDK",
        "icao": "KPDK",
        "latitude": "33.8755989075",
        "longitude": "-84.3020019531",
        "altitude": "1003",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BMG": {
        "name": "Monroe County Airport",
        "city": "Bloomington",
        "country": "United States",
        "iata": "BMG",
        "icao": "KBMG",
        "latitude": "39.145999908447266",
        "longitude": "-86.61669921875",
        "altitude": "846",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SUA": {
        "name": "Witham Field",
        "city": "Stuart",
        "country": "United States",
        "iata": "SUA",
        "icao": "KSUA",
        "latitude": "27.18169975",
        "longitude": "-80.22109985",
        "altitude": "16",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MMU": {
        "name": "Morristown Municipal Airport",
        "city": "Morristown",
        "country": "United States",
        "iata": "MMU",
        "icao": "KMMU",
        "latitude": "40.799400329589844",
        "longitude": "-74.41490173339844",
        "altitude": "187",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "APC": {
        "name": "Napa County Airport",
        "city": "Napa",
        "country": "United States",
        "iata": "APC",
        "icao": "KAPC",
        "latitude": "38.2132",
        "longitude": "-122.280998",
        "altitude": "35",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "SDM": {
        "name": "Brown Field Municipal Airport",
        "city": "San Diego",
        "country": "United States",
        "iata": "SDM",
        "icao": "KSDM",
        "latitude": "32.572299957275",
        "longitude": "-116.98000335693",
        "altitude": "526",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "VNC": {
        "name": "Venice Municipal Airport",
        "city": "Venice",
        "country": "United States",
        "iata": "VNC",
        "icao": "KVNC",
        "latitude": "27.071599960327",
        "longitude": "-82.440299987793",
        "altitude": "18",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PHK": {
        "name": "Palm Beach County Glades Airport",
        "city": "Pahokee",
        "country": "United States",
        "iata": "PHK",
        "icao": "KPHK",
        "latitude": "26.78499985",
        "longitude": "-80.69339752",
        "altitude": "16",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ECP": {
        "name": "Northwest Florida Beaches International Airport",
        "city": "Panama City",
        "country": "United States",
        "iata": "ECP",
        "icao": "KECP",
        "latitude": "30.357106",
        "longitude": "-85.795414",
        "altitude": "69",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SBD": {
        "name": "San Bernardino International Airport",
        "city": "San Bernardino",
        "country": "United States",
        "iata": "SBD",
        "icao": "KSBD",
        "latitude": "34.0954017639",
        "longitude": "-117.23500061",
        "altitude": "1159",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "VAL": {
        "name": "Valença Airport",
        "city": "Valenca",
        "country": "Brazil",
        "iata": "VAL",
        "icao": "SNVB",
        "latitude": "-13.2965",
        "longitude": "-38.992401",
        "altitude": "21",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "CAU": {
        "name": "Caruaru Airport",
        "city": "Caruaru",
        "country": "Brazil",
        "iata": "CAU",
        "icao": "SNRU",
        "latitude": "-8.282389640808105",
        "longitude": "-36.01350021362305",
        "altitude": "1891",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "AWK": {
        "name": "Wake Island Airfield",
        "city": "Wake island",
        "country": "Wake Island",
        "iata": "AWK",
        "icao": "PWAK",
        "latitude": "19.282100677490234",
        "longitude": "166.63600158691406",
        "altitude": "14",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Johnston"
    },
    "QNV": {
        "name": "Aeroclube Airport",
        "city": "Nova Iguacu",
        "country": "Brazil",
        "iata": "QNV",
        "icao": "SDNY",
        "latitude": "-22.74530029296875",
        "longitude": "-43.46030044555664",
        "altitude": "164",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "SQL": {
        "name": "San Carlos Airport",
        "city": "San Carlos",
        "country": "United States",
        "iata": "SQL",
        "icao": "KSQL",
        "latitude": "37.511901855469",
        "longitude": "-122.25",
        "altitude": "5",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RWI": {
        "name": "Rocky Mount Wilson Regional Airport",
        "city": "Rocky Mount",
        "country": "United States",
        "iata": "RWI",
        "icao": "KRWI",
        "latitude": "35.856300354003906",
        "longitude": "-77.89189910888672",
        "altitude": "159",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SXQ": {
        "name": "Soldotna Airport",
        "city": "Soldotna",
        "country": "United States",
        "iata": "SXQ",
        "icao": "PASX",
        "latitude": "60.47570037841797",
        "longitude": "-151.03399658203125",
        "altitude": "113",
        "timezone": "-9",
        "dst": "U",
        "tz_name": "America/Anchorage"
    },
    "SEE": {
        "name": "Gillespie Field",
        "city": "El Cajon",
        "country": "United States",
        "iata": "SEE",
        "icao": "KSEE",
        "latitude": "32.826198577881",
        "longitude": "-116.97200012207",
        "altitude": "388",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "PHA": {
        "name": "Phan Rang Airport",
        "city": "Phan Rang",
        "country": "Vietnam",
        "iata": "PHA",
        "icao": "VVPR",
        "latitude": "11.6335000992",
        "longitude": "108.952003479",
        "altitude": "101",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Saigon"
    },
    "SQH": {
        "name": "Na-San Airport",
        "city": "Son-La",
        "country": "Vietnam",
        "iata": "SQH",
        "icao": "VVNS",
        "latitude": "21.216999053955078",
        "longitude": "104.03299713134766",
        "altitude": "2133",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Saigon"
    },
    "TKF": {
        "name": "Truckee Tahoe Airport",
        "city": "Truckee",
        "country": "United States",
        "iata": "TKF",
        "icao": "KTRK",
        "latitude": "39.319999694799996",
        "longitude": "-120.13999939",
        "altitude": "5900",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "FRJ": {
        "name": "Fréjus Airport",
        "city": "Frejus",
        "country": "France",
        "iata": "FRJ",
        "icao": "LFTU",
        "latitude": "43.4175",
        "longitude": "6.7357",
        "altitude": "33",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "GEX": {
        "name": "Geelong Airport",
        "city": "Geelong",
        "country": "Australia",
        "iata": "GEX",
        "icao": "YGLG",
        "latitude": "-38.224998474121094",
        "longitude": "144.33299255371094",
        "altitude": "43",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "LVM": {
        "name": "Mission Field",
        "city": "Livingston-Montana",
        "country": "United States",
        "iata": "LVM",
        "icao": "KLVM",
        "latitude": "45.6994018555",
        "longitude": "-110.447998047",
        "altitude": "4660",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "GMV": {
        "name": "Monument Valley Airport",
        "city": "Monument Valley",
        "country": "United States",
        "iata": "GMV",
        "icao": "UT25",
        "latitude": "37.016700744599994",
        "longitude": "-110.200996399",
        "altitude": "5192",
        "timezone": "-7",
        "dst": "U",
        "tz_name": "America/Denver"
    },
    "JRA": {
        "name": "West 30th St. Heliport",
        "city": "New York",
        "country": "United States",
        "iata": "JRA",
        "icao": "KJRA",
        "latitude": "40.754501",
        "longitude": "-74.007103",
        "altitude": "7",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LAL": {
        "name": "Lakeland Linder International Airport",
        "city": "Lakeland",
        "country": "United States",
        "iata": "LAL",
        "icao": "KLAL",
        "latitude": "27.988899",
        "longitude": "-82.018602",
        "altitude": "142",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SYH": {
        "name": "Syangboche Airport",
        "city": "Syangboche",
        "country": "Nepal",
        "iata": "SYH",
        "icao": "VNSB",
        "latitude": "27.8112",
        "longitude": "86.7124",
        "altitude": "12400",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "RBK": {
        "name": "French Valley Airport",
        "city": "Murrieta-Temecula",
        "country": "United States",
        "iata": "RBK",
        "icao": "KF70",
        "latitude": "33.5741996765",
        "longitude": "-117.127998352",
        "altitude": "1350",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "FNU": {
        "name": "Oristano-Fenosu Airport",
        "city": "Oristano",
        "country": "Italy",
        "iata": "FNU",
        "icao": "LIER",
        "latitude": "39.895308",
        "longitude": "8.642661",
        "altitude": "36",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "MYQ": {
        "name": "Mysore Airport",
        "city": "Mysore",
        "country": "India",
        "iata": "MYQ",
        "icao": "VOMY",
        "latitude": "12.23",
        "longitude": "76.655833",
        "altitude": "2349",
        "timezone": "5.5",
        "dst": "U",
        "tz_name": "Asia/Calcutta"
    },
    "MGY": {
        "name": "Dayton-Wright Brothers Airport",
        "city": "Dayton",
        "country": "United States",
        "iata": "MGY",
        "icao": "KMGY",
        "latitude": "39.5890007019",
        "longitude": "-84.224899292",
        "altitude": "957",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "FDY": {
        "name": "Findlay Airport",
        "city": "Findley",
        "country": "United States",
        "iata": "FDY",
        "icao": "KFDY",
        "latitude": "41.013500213600004",
        "longitude": "-83.66870117190001",
        "altitude": "813",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "PEA": {
        "name": "Penneshaw Airport",
        "city": "Penneshaw",
        "country": "Australia",
        "iata": "PEA",
        "icao": "YPSH",
        "latitude": "-35.7558462874",
        "longitude": "137.962875366",
        "altitude": "0",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "EMP": {
        "name": "Emporia Municipal Airport",
        "city": "Kempten",
        "country": "Germany",
        "iata": "EMP",
        "icao": "KEMP",
        "latitude": "38.3320999146",
        "longitude": "-96.19120025630001",
        "altitude": "1208",
        "timezone": "-6",
        "dst": "E",
        "tz_name": "America/Chicago"
    },
    "HYC": {
        "name": "Wycombe Air Park",
        "city": "Wycombe",
        "country": "United Kingdom",
        "iata": "HYC",
        "icao": "EGTB",
        "latitude": "51.6116981506",
        "longitude": "-0.8083329796790001",
        "altitude": "515",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "BBP": {
        "name": "Bembridge Airport",
        "city": "Bembridge",
        "country": "United Kingdom",
        "iata": "BBP",
        "icao": "EGHJ",
        "latitude": "50.6781005859",
        "longitude": "-1.10943996906",
        "altitude": "53",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SPF": {
        "name": "Black Hills Airport-Clyde Ice Field",
        "city": "Spearfish-South Dakota",
        "country": "United States",
        "iata": "SPF",
        "icao": "KSPF",
        "latitude": "44.48030090332",
        "longitude": "-103.78299713135",
        "altitude": "3931",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "QYD": {
        "name": "Oksywie Military Air Base",
        "city": "Gdynia",
        "country": "Poland",
        "iata": "QYD",
        "icao": "EPOK",
        "latitude": "54.57970047",
        "longitude": "18.51720047",
        "altitude": "144",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "OLV": {
        "name": "Olive Branch Airport",
        "city": "Olive Branch",
        "country": "United States",
        "iata": "OLV",
        "icao": "KOLV",
        "latitude": "34.9786987305",
        "longitude": "-89.78690338130001",
        "altitude": "402",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KNA": {
        "name": "Viña del mar Airport",
        "city": "Vina del Mar",
        "country": "Chile",
        "iata": "KNA",
        "icao": "SCVM",
        "latitude": "-32.9496",
        "longitude": "-71.4786",
        "altitude": "461",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "ONQ": {
        "name": "Zonguldak Airport",
        "city": "Zonguldak",
        "country": "Turkey",
        "iata": "ONQ",
        "icao": "LTAS",
        "latitude": "41.506401062",
        "longitude": "32.0886001587",
        "altitude": "39",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "BJC": {
        "name": "Rocky Mountain Metropolitan Airport",
        "city": "Broomfield-CO",
        "country": "United States",
        "iata": "BJC",
        "icao": "KBJC",
        "latitude": "39.90879822",
        "longitude": "-105.1169968",
        "altitude": "5673",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SLE": {
        "name": "Salem Municipal Airport/McNary Field",
        "city": "Salem",
        "country": "United States",
        "iata": "SLE",
        "icao": "KSLE",
        "latitude": "44.90950012",
        "longitude": "-123.0029984",
        "altitude": "214",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "UTM": {
        "name": "Tunica Municipal Airport",
        "city": "Tunica",
        "country": "United States",
        "iata": "UTM",
        "icao": "KUTA",
        "latitude": "34.680999755859",
        "longitude": "-90.346702575684",
        "altitude": "194",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ZKB": {
        "name": "Kasaba Bay Airport",
        "city": "Kasaba Bay",
        "country": "Zambia",
        "iata": "ZKB",
        "icao": "FLKY",
        "latitude": "-8.524999618530273",
        "longitude": "30.663000106811523",
        "altitude": "2780",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Lusaka"
    },
    "LND": {
        "name": "Hunt Field",
        "city": "Lindau",
        "country": "Germany",
        "iata": "LND",
        "icao": "KLND",
        "latitude": "42.8152008057",
        "longitude": "-108.730003357",
        "altitude": "5586",
        "timezone": "-7",
        "dst": "E",
        "tz_name": "America/Denver"
    },
    "MWC": {
        "name": "Lawrence J Timmerman Airport",
        "city": "Milwaukee",
        "country": "United States",
        "iata": "MWC",
        "icao": "KMWC",
        "latitude": "43.110401",
        "longitude": "-88.034401",
        "altitude": "745",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JVL": {
        "name": "Southern Wisconsin Regional Airport",
        "city": "Janesville",
        "country": "United States",
        "iata": "JVL",
        "icao": "KJVL",
        "latitude": "42.620300293",
        "longitude": "-89.0416030884",
        "altitude": "808",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LZU": {
        "name": "Gwinnett County Briscoe Field",
        "city": "Lawrenceville",
        "country": "United States",
        "iata": "LZU",
        "icao": "KLZU",
        "latitude": "33.97809982",
        "longitude": "-83.96240234",
        "altitude": "1061",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BWG": {
        "name": "Bowling Green Warren County Regional Airport",
        "city": "Bowling Green",
        "country": "United States",
        "iata": "BWG",
        "icao": "KBWG",
        "latitude": "36.964500427199994",
        "longitude": "-86.41970062259999",
        "altitude": "547",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RVS": {
        "name": "Richard Lloyd Jones Jr Airport",
        "city": "Tulsa",
        "country": "United States",
        "iata": "RVS",
        "icao": "KRVS",
        "latitude": "36.039600372314",
        "longitude": "-95.984596252441",
        "altitude": "638",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NHD": {
        "name": "Al Minhad Air Base",
        "city": "Minhad AB",
        "country": "United Arab Emirates",
        "iata": "NHD",
        "icao": "OMDM",
        "latitude": "25.0268001556",
        "longitude": "55.3661994934",
        "altitude": "165",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "KGO": {
        "name": "Kirovograd Airport",
        "city": "Kirovograd",
        "country": "Ukraine",
        "iata": "KGO",
        "icao": "UKKG",
        "latitude": "48.54280090332031",
        "longitude": "32.28499984741211",
        "altitude": "568",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "DBB": {
        "name": "El Alamein International Airport",
        "city": "Dabaa City",
        "country": "Egypt",
        "iata": "DBB",
        "icao": "HEAL",
        "latitude": "30.92449951171875",
        "longitude": "28.46139907836914",
        "altitude": "143",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Cairo"
    },
    "BCE": {
        "name": "Bryce Canyon Airport",
        "city": "Bryce Canyon",
        "country": "United States",
        "iata": "BCE",
        "icao": "KBCE",
        "latitude": "37.706401825",
        "longitude": "-112.144996643",
        "altitude": "7590",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CKL": {
        "name": "Chkalovskiy Air Base",
        "city": "Shchyolkovo",
        "country": "Russia",
        "iata": "CKL",
        "icao": "UUMU",
        "latitude": "55.8783",
        "longitude": "38.061699",
        "altitude": "499",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "TCZ": {
        "name": "Tengchong Tuofeng Airport",
        "city": "Tengchong",
        "country": "China",
        "iata": "TCZ",
        "icao": "ZUTC",
        "latitude": "24.9380555556",
        "longitude": "98.48583333330001",
        "altitude": "6250",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "UKS": {
        "name": "Belbek Airport",
        "city": "Sevastopol",
        "country": "Ukraine",
        "iata": "UKS",
        "icao": "UKFB",
        "latitude": "44.688999176",
        "longitude": "33.570999145500004",
        "altitude": "344",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Europe/Simferopol"
    },
    "OAZ": {
        "name": "Camp Bastion Airport",
        "city": "Camp Bastion",
        "country": "Afghanistan",
        "iata": "OAZ",
        "icao": "OAZI",
        "latitude": "31.863800048799998",
        "longitude": "64.2246017456",
        "altitude": "2943",
        "timezone": "4.5",
        "dst": "N",
        "tz_name": "Asia/Kabul"
    },
    "JCI": {
        "name": "New Century Aircenter Airport",
        "city": "Olathe",
        "country": "United States",
        "iata": "JCI",
        "icao": "KIXD",
        "latitude": "38.8308982849",
        "longitude": "-94.890296936",
        "altitude": "1087",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ESN": {
        "name": "Easton Newnam Field",
        "city": "Easton",
        "country": "United States",
        "iata": "ESN",
        "icao": "KESN",
        "latitude": "38.8041992188",
        "longitude": "-76.06900024410001",
        "altitude": "72",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HMR": {
        "name": "Stafsberg Airport",
        "city": "Hamar",
        "country": "Norway",
        "iata": "HMR",
        "icao": "ENHA",
        "latitude": "60.81809997558594",
        "longitude": "11.067999839782715",
        "altitude": "713",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Oslo"
    },
    "MYV": {
        "name": "Yuba County Airport",
        "city": "Yuba City",
        "country": "United States",
        "iata": "MYV",
        "icao": "KMYV",
        "latitude": "39.09780121",
        "longitude": "-121.5699997",
        "altitude": "64",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "DUC": {
        "name": "Halliburton Field",
        "city": "Duncan",
        "country": "United States",
        "iata": "DUC",
        "icao": "KDUC",
        "latitude": "34.47090149",
        "longitude": "-97.9598999",
        "altitude": "1114",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "UVA": {
        "name": "Garner Field",
        "city": "Uvalde",
        "country": "United States",
        "iata": "UVA",
        "icao": "KUVA",
        "latitude": "29.2112998962",
        "longitude": "-99.743598938",
        "altitude": "942",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LOT": {
        "name": "Lewis University Airport",
        "city": "Lockport",
        "country": "United States",
        "iata": "LOT",
        "icao": "KLOT",
        "latitude": "41.6072998",
        "longitude": "-88.09619904",
        "altitude": "679",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CCR": {
        "name": "Buchanan Field",
        "city": "Concord",
        "country": "United States",
        "iata": "CCR",
        "icao": "KCCR",
        "latitude": "37.9897003174",
        "longitude": "-122.056999207",
        "altitude": "26",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "OCA": {
        "name": "Ocean Reef Club Airport",
        "city": "Ocean Reef Club Airport",
        "country": "United States",
        "iata": "OCA",
        "icao": "07FA",
        "latitude": "25.325399398804",
        "longitude": "-80.274803161621",
        "altitude": "8",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "YUS": {
        "name": "Yushu Batang Airport",
        "city": "Yushu",
        "country": "China",
        "iata": "YUS",
        "icao": "ZYLS",
        "latitude": "32.836388888900004",
        "longitude": "97.0363888889",
        "altitude": "12816",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "HIA": {
        "name": "Lianshui Airport",
        "city": "Huai An",
        "country": "China",
        "iata": "HIA",
        "icao": "ZSSH",
        "latitude": "33.7908333333",
        "longitude": "119.125",
        "altitude": "28",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "YOO": {
        "name": "Toronto/Oshawa Executive Airport",
        "city": "Oshawa",
        "country": "Canada",
        "iata": "YOO",
        "icao": "CYOO",
        "latitude": "43.922798",
        "longitude": "-78.894997",
        "altitude": "460",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "LHA": {
        "name": "Lahr Airport",
        "city": "Lahr",
        "country": "Germany",
        "iata": "LHA",
        "icao": "EDTL",
        "latitude": "48.3693008423",
        "longitude": "7.82772016525",
        "altitude": "511",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "NYW": {
        "name": "Monywar Airport",
        "city": "Monywa",
        "country": "Burma",
        "iata": "NYW",
        "icao": "VYMY",
        "latitude": "22.221638",
        "longitude": "95.093479",
        "altitude": "298",
        "timezone": "6.5",
        "dst": "N",
        "tz_name": "Asia/Rangoon"
    },
    "ATO": {
        "name": "Ohio University Snyder Field",
        "city": "Athens",
        "country": "United States",
        "iata": "ATO",
        "icao": "KUNI",
        "latitude": "39.2109985352",
        "longitude": "-82.23139953610001",
        "altitude": "766",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "SGH": {
        "name": "Springfield-Beckley Municipal Airport",
        "city": "Springfield",
        "country": "United States",
        "iata": "SGH",
        "icao": "KSGH",
        "latitude": "39.840301513672",
        "longitude": "-83.840202331543",
        "altitude": "1051",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HEX": {
        "name": "Herrera Airport",
        "city": "Santo Domingo",
        "country": "Dominican Republic",
        "iata": "HEX",
        "icao": "MDHE",
        "latitude": "18.4696998596",
        "longitude": "-69.9693984985",
        "altitude": "190",
        "timezone": "-4",
        "dst": "U",
        "tz_name": "America/Santo_Domingo"
    },
    "CDA": {
        "name": "Cooinda Airport",
        "city": "Cooinda",
        "country": "Australia",
        "iata": "CDA",
        "icao": "YCOO",
        "latitude": "-12.903300285339355",
        "longitude": "132.53199768066406",
        "altitude": "13",
        "timezone": "9.5",
        "dst": "U",
        "tz_name": "Australia/Darwin"
    },
    "JAB": {
        "name": "Jabiru Airport",
        "city": "Jabiru",
        "country": "Australia",
        "iata": "JAB",
        "icao": "YJAB",
        "latitude": "-12.658300399780273",
        "longitude": "132.89300537109375",
        "altitude": "85",
        "timezone": "9.5",
        "dst": "U",
        "tz_name": "Australia/Darwin"
    },
    "HGS": {
        "name": "Hastings Airport",
        "city": "Freetown",
        "country": "Sierra Leone",
        "iata": "HGS",
        "icao": "GFHA",
        "latitude": "8.397130012512207",
        "longitude": "-13.12909984588623",
        "altitude": "60",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Freetown"
    },
    "TOP": {
        "name": "Philip Billard Municipal Airport",
        "city": "Topeka",
        "country": "United States",
        "iata": "TOP",
        "icao": "KTOP",
        "latitude": "39.068698883057",
        "longitude": "-95.622497558594",
        "altitude": "881",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MQY": {
        "name": "Smyrna Airport",
        "city": "Smyrna",
        "country": "United States",
        "iata": "MQY",
        "icao": "KMQY",
        "latitude": "36.0089988708",
        "longitude": "-86.5201034546",
        "altitude": "543",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "UOS": {
        "name": "Franklin County Airport",
        "city": "Sewanee",
        "country": "United States",
        "iata": "UOS",
        "icao": "KUOS",
        "latitude": "35.205101013184",
        "longitude": "-85.898101806641",
        "altitude": "1953",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "NGQ": {
        "name": "Ngari Gunsa Airport",
        "city": "Shiquanhe",
        "country": "China",
        "iata": "NGQ",
        "icao": "ZUAL",
        "latitude": "32.1",
        "longitude": "80.0530555556",
        "altitude": "14022",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "CSO": {
        "name": "Cochstedt Airport",
        "city": "Cochstedt",
        "country": "Germany",
        "iata": "CSO",
        "icao": "EDBC",
        "latitude": "51.8563995361",
        "longitude": "11.42029953",
        "altitude": "594",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "PWK": {
        "name": "Chicago Executive Airport",
        "city": "Chicago-Wheeling",
        "country": "United States",
        "iata": "PWK",
        "icao": "KPWK",
        "latitude": "42.114222",
        "longitude": "-87.901494",
        "altitude": "647",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KLS": {
        "name": "Southwest Washington Regional Airport",
        "city": "Kelso",
        "country": "United States",
        "iata": "KLS",
        "icao": "KKLS",
        "latitude": "46.11800003049999",
        "longitude": "-122.898002625",
        "altitude": "20",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ZTA": {
        "name": "Tureia Airport",
        "city": "Tureia",
        "country": "French Polynesia",
        "iata": "ZTA",
        "icao": "NTGY",
        "latitude": "-20.78969955444336",
        "longitude": "-138.57000732421875",
        "altitude": "12",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "PUE": {
        "name": "Puerto Obaldia Airport",
        "city": "Puerto Obaldia",
        "country": "Panama",
        "iata": "PUE",
        "icao": "MPOA",
        "latitude": "8.667",
        "longitude": "-77.418",
        "altitude": "223",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Panama"
    },
    "KHC": {
        "name": "Kerch Airport",
        "city": "Kerch",
        "country": "Ukraine",
        "iata": "KHC",
        "icao": "UKFK",
        "latitude": "45.372501373291016",
        "longitude": "36.40140151977539",
        "altitude": "171",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Simferopol"
    },
    "UKA": {
        "name": "Ukunda Airstrip",
        "city": "Ukunda",
        "country": "Kenya",
        "iata": "UKA",
        "icao": "HKUK",
        "latitude": "-4.29333",
        "longitude": "39.571098",
        "altitude": "98",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "ILN": {
        "name": "Wilmington Airpark",
        "city": "Wilmington",
        "country": "United States",
        "iata": "ILN",
        "icao": "KILN",
        "latitude": "39.427898407",
        "longitude": "-83.792098999",
        "altitude": "1077",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/New_York"
    },
    "AVW": {
        "name": "Marana Regional Airport",
        "city": "Tucson",
        "country": "United States",
        "iata": "AVW",
        "icao": "KAVQ",
        "latitude": "32.4095993042",
        "longitude": "-111.218002319",
        "altitude": "2031",
        "timezone": "-7",
        "dst": "U",
        "tz_name": "America/Phoenix"
    },
    "CGZ": {
        "name": "Casa Grande Municipal Airport",
        "city": "Casa Grande",
        "country": "United States",
        "iata": "CGZ",
        "icao": "KCGZ",
        "latitude": "32.954899",
        "longitude": "-111.766998",
        "altitude": "1464",
        "timezone": "-7",
        "dst": "U",
        "tz_name": "America/Phoenix"
    },
    "BXK": {
        "name": "Buckeye Municipal Airport",
        "city": "Buckeye",
        "country": "United States",
        "iata": "BXK",
        "icao": "KBXK",
        "latitude": "33.42039871",
        "longitude": "-112.685997",
        "altitude": "1033",
        "timezone": "-7",
        "dst": "U",
        "tz_name": "America/Phoenix"
    },
    "MMI": {
        "name": "McMinn County Airport",
        "city": "Athens",
        "country": "United States",
        "iata": "MMI",
        "icao": "KMMI",
        "latitude": "35.39730072",
        "longitude": "-84.56259918",
        "altitude": "875",
        "timezone": "-5",
        "dst": "N",
        "tz_name": "America/New_York"
    },
    "STK": {
        "name": "Sterling Municipal Airport",
        "city": "Sterling",
        "country": "United States",
        "iata": "STK",
        "icao": "KSTK",
        "latitude": "40.61529922",
        "longitude": "-103.2649994",
        "altitude": "4040",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "RWL": {
        "name": "Rawlins Municipal Airport/Harvey Field",
        "city": "Rawlins",
        "country": "United States",
        "iata": "RWL",
        "icao": "KRWL",
        "latitude": "41.80559921",
        "longitude": "-107.1999969",
        "altitude": "6813",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CDW": {
        "name": "Essex County Airport",
        "city": "Caldwell",
        "country": "United States",
        "iata": "CDW",
        "icao": "KCDW",
        "latitude": "40.875198364300005",
        "longitude": "-74.2814025879",
        "altitude": "173",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AIZ": {
        "name": "Lee C Fine Memorial Airport",
        "city": "Kaiser Lake Ozark",
        "country": "United States",
        "iata": "AIZ",
        "icao": "KAIZ",
        "latitude": "38.0960006714",
        "longitude": "-92.54949951170002",
        "altitude": "869",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TVI": {
        "name": "Thomasville Regional Airport",
        "city": "Thomasville",
        "country": "United States",
        "iata": "TVI",
        "icao": "KTVI",
        "latitude": "30.901599884033",
        "longitude": "-83.881301879883",
        "altitude": "264",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HSH": {
        "name": "Henderson Executive Airport",
        "city": "Henderson",
        "country": "United States",
        "iata": "HSH",
        "icao": "KHND",
        "latitude": "35.9728012085",
        "longitude": "-115.134002686",
        "altitude": "2492",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GML": {
        "name": "Gostomel Airport",
        "city": "Kiev",
        "country": "Ukraine",
        "iata": "GML",
        "icao": "UKKM",
        "latitude": "50.60350036621094",
        "longitude": "30.1919002532959",
        "altitude": "517",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "TMA": {
        "name": "Henry Tift Myers Airport",
        "city": "Tifton",
        "country": "United States",
        "iata": "TMA",
        "icao": "KTMA",
        "latitude": "31.4290008545",
        "longitude": "-83.4885025024",
        "altitude": "355",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RDO": {
        "name": "Radom Airport",
        "city": "RADOM",
        "country": "Poland",
        "iata": "RDO",
        "icao": "EPRA",
        "latitude": "51.3891983032",
        "longitude": "21.213300705",
        "altitude": "610",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "DVT": {
        "name": "Phoenix Deer Valley Airport",
        "city": "Phoenix ",
        "country": "United States",
        "iata": "DVT",
        "icao": "KDVT",
        "latitude": "33.6883010864",
        "longitude": "-112.083000183",
        "altitude": "1478",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "YRV": {
        "name": "Revelstoke Airport",
        "city": "Revelstoke",
        "country": "Canada",
        "iata": "YRV",
        "icao": "CYRV",
        "latitude": "50.9667015076",
        "longitude": "-118.182998657",
        "altitude": "1459",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "FRG": {
        "name": "Republic Airport",
        "city": "Farmingdale",
        "country": "United States",
        "iata": "FRG",
        "icao": "KFRG",
        "latitude": "40.7288017273",
        "longitude": "-73.4133987427",
        "altitude": "82",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ZHY": {
        "name": "Zhongwei Shapotou Airport",
        "city": "Zhongwei",
        "country": "China",
        "iata": "ZHY",
        "icao": "ZLZW",
        "latitude": "37.573125",
        "longitude": "105.154454",
        "altitude": "8202",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "MCL": {
        "name": "McKinley National Park Airport",
        "city": "McKinley Park",
        "country": "United States",
        "iata": "MCL",
        "icao": "PAIN",
        "latitude": "63.7326011658",
        "longitude": "-148.910995483",
        "altitude": "1720",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PPC": {
        "name": "Prospect Creek Airport",
        "city": "Prospect Creek",
        "country": "United States",
        "iata": "PPC",
        "icao": "PAPR",
        "latitude": "66.814102172852",
        "longitude": "-150.64399719238",
        "altitude": "1095",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "KHW": {
        "name": "Khwai River Lodge Airport",
        "city": "Khwai River",
        "country": "Botswana",
        "iata": "KHW",
        "icao": "FBKR",
        "latitude": "-19.149999618530273",
        "longitude": "23.783000946044922",
        "altitude": "3000",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Gaborone"
    },
    "TXG": {
        "name": "Taichung Airport",
        "city": "Taichung",
        "country": "Taiwan",
        "iata": "TXG",
        "icao": "RCLG",
        "latitude": "24.18630027770996",
        "longitude": "120.65399932861328",
        "altitude": "369",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Taipei"
    },
    "HLG": {
        "name": "Wheeling Ohio County Airport",
        "city": "Wheeling",
        "country": "United States",
        "iata": "HLG",
        "icao": "KHLG",
        "latitude": "40.1749992371",
        "longitude": "-80.6463012695",
        "altitude": "1195",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "XYE": {
        "name": "Ye Airport",
        "city": "Ye",
        "country": "Burma",
        "iata": "XYE",
        "icao": "VYYE",
        "latitude": "15.300000190734863",
        "longitude": "97.86699676513672",
        "altitude": "30",
        "timezone": "6.5",
        "dst": "U",
        "tz_name": "Asia/Rangoon"
    },
    "DWC": {
        "name": "Al Maktoum International Airport",
        "city": "Dubai",
        "country": "United Arab Emirates",
        "iata": "DWC",
        "icao": "OMDW",
        "latitude": "24.896356",
        "longitude": "55.161389",
        "altitude": "114",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "RKP": {
        "name": "Aransas County Airport",
        "city": "Rockport",
        "country": "United States",
        "iata": "RKP",
        "icao": "KRKP",
        "latitude": "28.0867996216",
        "longitude": "-97.0446014404",
        "altitude": "24",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MVV": {
        "name": "Megève Airport",
        "city": "Verdun",
        "country": "France",
        "iata": "MVV",
        "icao": "LFHM",
        "latitude": "45.82080078125",
        "longitude": "6.652219772338867",
        "altitude": "4823",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Europe/Paris"
    },
    "MFX": {
        "name": "Méribel Altiport",
        "city": "Ajaccio",
        "country": "France",
        "iata": "MFX",
        "icao": "LFKX",
        "latitude": "45.407003",
        "longitude": "6.577942",
        "altitude": "5636",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Europe/Paris"
    },
    "AEB": {
        "name": "Baise Youjiang Airport",
        "city": "Baise",
        "country": "China",
        "iata": "AEB",
        "icao": "ZGBS",
        "latitude": "23.7206001282",
        "longitude": "106.959999084",
        "altitude": "490",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "OKF": {
        "name": "Okaukuejo Airport",
        "city": "Okaukuejo",
        "country": "Namibia",
        "iata": "OKF",
        "icao": "FYOO",
        "latitude": "-19.149200439453125",
        "longitude": "15.91189956665039",
        "altitude": "3911",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "OKU": {
        "name": "Mokuti Lodge Airport",
        "city": "Mokuti Lodge",
        "country": "Namibia",
        "iata": "OKU",
        "icao": "FYMO",
        "latitude": "-18.81279945373535",
        "longitude": "17.05940055847168",
        "altitude": "3665",
        "timezone": "1",
        "dst": "S",
        "tz_name": "Africa/Windhoek"
    },
    "KOQ": {
        "name": "Köthen Airport",
        "city": "Koethen",
        "country": "Germany",
        "iata": "KOQ",
        "icao": "EDCK",
        "latitude": "51.7211112976",
        "longitude": "11.952777862500001",
        "altitude": "305",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "PSH": {
        "name": "St. Peter-Ording Airport",
        "city": "Sankt Peter-Ording",
        "country": "Germany",
        "iata": "PSH",
        "icao": "EDXO",
        "latitude": "54.30888748168945",
        "longitude": "8.686944007873535",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "TTD": {
        "name": "Portland Troutdale Airport",
        "city": "Troutdale",
        "country": "United States",
        "iata": "TTD",
        "icao": "KTTD",
        "latitude": "45.54940032959",
        "longitude": "-122.40100097656",
        "altitude": "39",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "HIO": {
        "name": "Portland Hillsboro Airport",
        "city": "Hillsboro",
        "country": "United States",
        "iata": "HIO",
        "icao": "KHIO",
        "latitude": "45.540401",
        "longitude": "-122.949997",
        "altitude": "208",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "KHT": {
        "name": "Khost Airport",
        "city": "Khost",
        "country": "Afghanistan",
        "iata": "KHT",
        "icao": "OAKS",
        "latitude": "33.3334007263",
        "longitude": "69.952003479",
        "altitude": "3756",
        "timezone": "4.5",
        "dst": "N",
        "tz_name": "Asia/Kabul"
    },
    "NMT": {
        "name": "Namtu Airport",
        "city": "Naypyidaw",
        "country": "Burma",
        "iata": "NMT",
        "icao": "VYNT",
        "latitude": "23.083",
        "longitude": "97.383003",
        "altitude": "2000",
        "timezone": "6.5",
        "dst": "N",
        "tz_name": "Asia/Rangoon"
    },
    "BNO": {
        "name": "Burns Municipal Airport",
        "city": "Burns",
        "country": "United States",
        "iata": "BNO",
        "icao": "KBNO",
        "latitude": "43.5918998718",
        "longitude": "-118.955001831",
        "altitude": "4148",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "PRZ": {
        "name": "Prineville Airport",
        "city": "Prineville",
        "country": "United States",
        "iata": "PRZ",
        "icao": "KS39",
        "latitude": "44.286998748779",
        "longitude": "-120.90399932861",
        "altitude": "3250",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "RBL": {
        "name": "Red Bluff Municipal Airport",
        "city": "Red Bluff",
        "country": "United States",
        "iata": "RBL",
        "icao": "KRBL",
        "latitude": "40.1506996155",
        "longitude": "-122.251998901",
        "altitude": "352",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "NOT": {
        "name": "Marin County Airport - Gnoss Field",
        "city": "Novato",
        "country": "United States",
        "iata": "NOT",
        "icao": "KDVO",
        "latitude": "38.143600463867",
        "longitude": "-122.55599975586",
        "altitude": "2",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "LKV": {
        "name": "Lake County Airport",
        "city": "Lakeview",
        "country": "United States",
        "iata": "LKV",
        "icao": "KLKV",
        "latitude": "42.161098480199996",
        "longitude": "-120.399002075",
        "altitude": "4733",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "OTK": {
        "name": "Tillamook Airport",
        "city": "Tillamook",
        "country": "United States",
        "iata": "OTK",
        "icao": "KTMK",
        "latitude": "45.4182014465",
        "longitude": "-123.814002991",
        "altitude": "36",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "ONO": {
        "name": "Ontario Municipal Airport",
        "city": "Ontario",
        "country": "United States",
        "iata": "ONO",
        "icao": "KONO",
        "latitude": "44.020500183105",
        "longitude": "-117.01399993896",
        "altitude": "2193",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "DLS": {
        "name": "Columbia Gorge Regional the Dalles Municipal Airport",
        "city": "The Dalles",
        "country": "United States",
        "iata": "DLS",
        "icao": "KDLS",
        "latitude": "45.6184997559",
        "longitude": "-121.166999817",
        "altitude": "247",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GAI": {
        "name": "Montgomery County Airpark",
        "city": "Gaithersburg",
        "country": "United States",
        "iata": "GAI",
        "icao": "KGAI",
        "latitude": "39.168300628699996",
        "longitude": "-77.1660003662",
        "altitude": "539",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OAS": {
        "name": "Sharana Airstrip",
        "city": "Sharona",
        "country": "Afghanistan",
        "iata": "OAS",
        "icao": "OASA",
        "latitude": "33.12575",
        "longitude": "68.838517",
        "altitude": "7340",
        "timezone": "4.5",
        "dst": "N",
        "tz_name": "Asia/Kabul"
    },
    "YTA": {
        "name": "Pembroke Airport",
        "city": "Pembroke",
        "country": "Canada",
        "iata": "YTA",
        "icao": "CYTA",
        "latitude": "45.86439895629883",
        "longitude": "-77.25170135498047",
        "altitude": "529",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "TSB": {
        "name": "Tsumeb Airport",
        "city": "Tsumeb",
        "country": "Namibia",
        "iata": "TSB",
        "icao": "FYTM",
        "latitude": "-19.26189994812",
        "longitude": "17.732500076294",
        "altitude": "4353",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Windhoek"
    },
    "YSD": {
        "name": "Suffield Heliport",
        "city": "Suffield",
        "country": "Canada",
        "iata": "YSD",
        "icao": "CYSD",
        "latitude": "50.266700744628906",
        "longitude": "-111.18299865722656",
        "altitude": "2525",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Edmonton"
    },
    "BNU": {
        "name": "Blumenau Airport",
        "city": "BLUMENAU",
        "country": "Brazil",
        "iata": "BNU",
        "icao": "SSBL",
        "latitude": "-26.83060073852539",
        "longitude": "-49.090301513671875",
        "altitude": "60",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "YCC": {
        "name": "Cornwall Regional Airport",
        "city": "Cornwall",
        "country": "Canada",
        "iata": "YCC",
        "icao": "CYCC",
        "latitude": "45.09280014038086",
        "longitude": "-74.56330108642578",
        "altitude": "175",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "IZA": {
        "name": "Zona da Mata Regional Airport",
        "city": "Juiz de Fora",
        "country": "Brazil",
        "iata": "IZA",
        "icao": "SDZY",
        "latitude": "-21.5130558014",
        "longitude": "-43.1730575562",
        "altitude": "1348",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "MVL": {
        "name": "Morrisville Stowe State Airport",
        "city": "Morrisville",
        "country": "United States",
        "iata": "MVL",
        "icao": "KMVL",
        "latitude": "44.53459930419999",
        "longitude": "-72.6139984131",
        "altitude": "732",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RBD": {
        "name": "Dallas Executive Airport",
        "city": "Dallas",
        "country": "United States",
        "iata": "RBD",
        "icao": "KRBD",
        "latitude": "32.6809005737",
        "longitude": "-96.8682022095",
        "altitude": "660",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BXY": {
        "name": "Krainiy Airport",
        "city": "Baikonur",
        "country": "Kazakhstan",
        "iata": "BXY",
        "icao": "UAOL",
        "latitude": "45.621994",
        "longitude": "63.210773",
        "altitude": "317",
        "timezone": "6",
        "dst": "U",
        "tz_name": "Asia/Qyzylorda"
    },
    "WST": {
        "name": "Westerly State Airport",
        "city": "Washington County",
        "country": "United States",
        "iata": "WST",
        "icao": "KWST",
        "latitude": "41.3496017456",
        "longitude": "-71.8033981323",
        "altitude": "81",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BID": {
        "name": "Block Island State Airport",
        "city": "Block Island",
        "country": "United States",
        "iata": "BID",
        "icao": "KBID",
        "latitude": "41.1680984497",
        "longitude": "-71.577796936",
        "altitude": "108",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NME": {
        "name": "Nightmute Airport",
        "city": "Nightmute",
        "country": "United States",
        "iata": "NME",
        "icao": "PAGT",
        "latitude": "60.471000671387",
        "longitude": "-164.70100402832",
        "altitude": "4",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OOK": {
        "name": "Toksook Bay Airport",
        "city": "Toksook Bay",
        "country": "United States",
        "iata": "OOK",
        "icao": "PAOO",
        "latitude": "60.54140091",
        "longitude": "-165.0870056",
        "altitude": "59",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "OBY": {
        "name": "Ittoqqortoormiit Heliport",
        "city": "Ittoqqortoormiit",
        "country": "Greenland",
        "iata": "OBY",
        "icao": "BGSC",
        "latitude": "70.4882288244",
        "longitude": "-21.971679925900002",
        "altitude": "238",
        "timezone": "-1",
        "dst": "U",
        "tz_name": "America/Scoresbysund"
    },
    "VIN": {
        "name": "Vinnytsia/Gavyryshivka Airport",
        "city": "Vinnitsa",
        "country": "Ukraine",
        "iata": "VIN",
        "icao": "UKWW",
        "latitude": "49.242531",
        "longitude": "28.613778",
        "altitude": "961",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "BGE": {
        "name": "Decatur County Industrial Air Park",
        "city": "Bainbridge",
        "country": "United States",
        "iata": "BGE",
        "icao": "KBGE",
        "latitude": "30.9715004",
        "longitude": "-84.63739777",
        "altitude": "141",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ZGS": {
        "name": "La Romaine Airport",
        "city": "La Romaine",
        "country": "Canada",
        "iata": "ZGS",
        "icao": "CTT5",
        "latitude": "50.259701",
        "longitude": "-60.679401",
        "altitude": "90",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "ZKG": {
        "name": "Kegaska Airport",
        "city": "Kegaska",
        "country": "Canada",
        "iata": "ZKG",
        "icao": "CTK6",
        "latitude": "50.1958007812",
        "longitude": "-61.265800476100004",
        "altitude": "32",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Blanc-Sablon"
    },
    "YBI": {
        "name": "Black Tickle Airport",
        "city": "Black Tickle",
        "country": "Canada",
        "iata": "YBI",
        "icao": "CCE4",
        "latitude": "53.4693984985",
        "longitude": "-55.784999847399995",
        "altitude": "57",
        "timezone": "-4",
        "dst": "A",
        "tz_name": "America/Halifax"
    },
    "WHP": {
        "name": "Whiteman Airport",
        "city": "Los Angeles",
        "country": "United States",
        "iata": "WHP",
        "icao": "KWHP",
        "latitude": "34.2593002319",
        "longitude": "-118.413002014",
        "altitude": "1003",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MAE": {
        "name": "Madera Municipal Airport",
        "city": "Madera",
        "country": "United States",
        "iata": "MAE",
        "icao": "KMAE",
        "latitude": "36.9886016846",
        "longitude": "-120.111999512",
        "altitude": "255",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "YZZ": {
        "name": "Trail Airport",
        "city": "Trail",
        "country": "Canada",
        "iata": "YZZ",
        "icao": "CAD4",
        "latitude": "49.0555992126",
        "longitude": "-117.60900116",
        "altitude": "1427",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "YAB": {
        "name": "Old Arctic Bay Airport",
        "city": "Arctic Bay",
        "country": "Canada",
        "iata": "YAB",
        "icao": "CJX7",
        "latitude": "73.0058922479",
        "longitude": "-85.0325489044",
        "altitude": "100",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "GSI": {
        "name": "Grand-Santi Airport",
        "city": "Grand-Santi",
        "country": "French Guiana",
        "iata": "GSI",
        "icao": "SOGS",
        "latitude": "4.285833",
        "longitude": "-54.373056",
        "altitude": "207",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Cayenne"
    },
    "MPY": {
        "name": "Maripasoula Airport",
        "city": "Maripasoula",
        "country": "French Guiana",
        "iata": "MPY",
        "icao": "SOOA",
        "latitude": "3.6575",
        "longitude": "-54.037201",
        "altitude": "406",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Cayenne"
    },
    "LDX": {
        "name": "Saint-Laurent-du-Maroni Airport",
        "city": "Saint-Laurent-du-Maroni",
        "country": "French Guiana",
        "iata": "LDX",
        "icao": "SOOM",
        "latitude": "5.48306",
        "longitude": "-54.034401",
        "altitude": "16",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Cayenne"
    },
    "KJI": {
        "name": "Kanas Airport",
        "city": "Burqin",
        "country": "China",
        "iata": "KJI",
        "icao": "ZWKN",
        "latitude": "48.2223",
        "longitude": "86.9959",
        "altitude": "3921",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CPB": {
        "name": "Capurganá Airport",
        "city": "Capurgana",
        "country": "Colombia",
        "iata": "CPB",
        "icao": "SKCA",
        "latitude": "8.63333",
        "longitude": "-77.35",
        "altitude": "49",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Bogota"
    },
    "HMB": {
        "name": "Sohag International Airport",
        "city": "Sohag",
        "country": "Egypt",
        "iata": "HMB",
        "icao": "HEMK",
        "latitude": "26.342778",
        "longitude": "31.742778",
        "altitude": "310",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Africa/Cairo"
    },
    "RVY": {
        "name": "Presidente General Don Oscar D. Gestido International Airport",
        "city": "Rivera",
        "country": "Uruguay",
        "iata": "RVY",
        "icao": "SURV",
        "latitude": "-30.974599838256836",
        "longitude": "-55.476200103759766",
        "altitude": "712",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Montevideo"
    },
    "POJ": {
        "name": "Patos de Minas Airport",
        "city": "Patos de Minas",
        "country": "Brazil",
        "iata": "POJ",
        "icao": "SNPD",
        "latitude": "-18.672800064086914",
        "longitude": "-46.4911994934082",
        "altitude": "2793",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "JTC": {
        "name": "Bauru - Arealva Airport",
        "city": "Bauru",
        "country": "Brazil",
        "iata": "JTC",
        "icao": "SJTC",
        "latitude": "-22.166859140899998",
        "longitude": "-49.0502866745",
        "altitude": "1949",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "OIA": {
        "name": "Ourilândia do Norte Airport",
        "city": "Ourilandia do Norte",
        "country": "Brazil",
        "iata": "OIA",
        "icao": "SDOW",
        "latitude": "-6.763100147250001",
        "longitude": "-51.0499000549",
        "altitude": "901",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "RDC": {
        "name": "Redenção Airport",
        "city": "Redencao",
        "country": "Brazil",
        "iata": "RDC",
        "icao": "SNDC",
        "latitude": "-8.033289909362793",
        "longitude": "-49.97990036010742",
        "altitude": "670",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "SXX": {
        "name": "São Félix do Xingu Airport",
        "city": "Sao Felix do Xingu",
        "country": "Brazil",
        "iata": "SXX",
        "icao": "SNFX",
        "latitude": "-6.6413",
        "longitude": "-51.9523",
        "altitude": "656",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "BYO": {
        "name": "Bonito Airport",
        "city": "Bointo",
        "country": "Brazil",
        "iata": "BYO",
        "icao": "SJDB",
        "latitude": "-21.247299",
        "longitude": "-56.452499",
        "altitude": "1180",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "SXO": {
        "name": "São Félix do Araguaia Airport",
        "city": "Sao Felix do Araguaia",
        "country": "Brazil",
        "iata": "SXO",
        "icao": "SWFX",
        "latitude": "-11.632399559020996",
        "longitude": "-50.68960189819336",
        "altitude": "650",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "CFC": {
        "name": "Caçador Airport",
        "city": "Cacador",
        "country": "Brazil",
        "iata": "CFC",
        "icao": "SBCD",
        "latitude": "-26.78840065",
        "longitude": "-50.9398002625",
        "altitude": "3376",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CAF": {
        "name": "Carauari Airport",
        "city": "Carauari",
        "country": "Brazil",
        "iata": "CAF",
        "icao": "SWCA",
        "latitude": "-4.871520042419434",
        "longitude": "-66.89749908447266",
        "altitude": "355",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "ERN": {
        "name": "Eirunepé Airport",
        "city": "Eirunepe",
        "country": "Brazil",
        "iata": "ERN",
        "icao": "SWEI",
        "latitude": "-6.639530181884766",
        "longitude": "-69.87979888916016",
        "altitude": "412",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "CCI": {
        "name": "Concórdia Airport",
        "city": "Concordia",
        "country": "Brazil",
        "iata": "CCI",
        "icao": "SSCK",
        "latitude": "-27.180599212646484",
        "longitude": "-52.05270004272461",
        "altitude": "2461",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "FBE": {
        "name": "Francisco Beltrão Airport",
        "city": "Francisco Beltrao",
        "country": "Brazil",
        "iata": "FBE",
        "icao": "SSFB",
        "latitude": "-26.059200286865234",
        "longitude": "-53.063499450683594",
        "altitude": "2100",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "CFO": {
        "name": "Confresa Airport",
        "city": "Confresa",
        "country": "Brazil",
        "iata": "CFO",
        "icao": "SJHG",
        "latitude": "-10.634400367736816",
        "longitude": "-51.5635986328125",
        "altitude": "781",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "AAF": {
        "name": "Apalachicola Regional Airport",
        "city": "Apalachicola",
        "country": "United States",
        "iata": "AAF",
        "icao": "KAAF",
        "latitude": "29.72750092",
        "longitude": "-85.02749634",
        "altitude": "20",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "UMU": {
        "name": "Umuarama Airport",
        "city": "Umuarama",
        "country": "Brazil",
        "iata": "UMU",
        "icao": "SSUM",
        "latitude": "-23.7987003326416",
        "longitude": "-53.31380081176758",
        "altitude": "1558",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "DTI": {
        "name": "Diamantina Airport",
        "city": "Diamantina",
        "country": "Brazil",
        "iata": "DTI",
        "icao": "SNDT",
        "latitude": "-18.232000351",
        "longitude": "-43.650398254399995",
        "altitude": "4446",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "FBA": {
        "name": "Fonte Boa Airport",
        "city": "Fonte Boa",
        "country": "Brazil",
        "iata": "FBA",
        "icao": "SWOB",
        "latitude": "-2.5326099395800004",
        "longitude": "-66.0831985474",
        "altitude": "207",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "OLC": {
        "name": "Senadora Eunice Micheles Airport",
        "city": "Sao Paulo de Olivenca",
        "country": "Brazil",
        "iata": "OLC",
        "icao": "SDCG",
        "latitude": "-3.46792950765",
        "longitude": "-68.9204120636",
        "altitude": "335",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "HUW": {
        "name": "Humaitá Airport",
        "city": "Humaita",
        "country": "Brazil",
        "iata": "HUW",
        "icao": "SWHT",
        "latitude": "-7.532120227810001",
        "longitude": "-63.072101593",
        "altitude": "230",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "IRZ": {
        "name": "Tapuruquara Airport",
        "city": "Santa Isabel do Rio Negro",
        "country": "Brazil",
        "iata": "IRZ",
        "icao": "SWTP",
        "latitude": "-0.3786",
        "longitude": "-64.9923",
        "altitude": "223",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Boa_Vista"
    },
    "ORX": {
        "name": "Oriximiná Airport",
        "city": "Oriximina",
        "country": "Brazil",
        "iata": "ORX",
        "icao": "SNOX",
        "latitude": "-1.7140799760818481",
        "longitude": "-55.83620071411133",
        "altitude": "262",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Belem"
    },
    "UNA": {
        "name": "Hotel Transamérica Airport",
        "city": "Una",
        "country": "Brazil",
        "iata": "UNA",
        "icao": "SBTC",
        "latitude": "-15.355199813799999",
        "longitude": "-38.9990005493",
        "altitude": "20",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Fortaleza"
    },
    "TEF": {
        "name": "Telfer Airport",
        "city": "Telfer",
        "country": "Australia",
        "iata": "TEF",
        "icao": "YTEF",
        "latitude": "-21.71500015258789",
        "longitude": "122.22899627685547",
        "altitude": "970",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Australia/Perth"
    },
    "GZP": {
        "name": "Gazipaşa Airport",
        "city": "Alanya",
        "country": "Turkey",
        "iata": "GZP",
        "icao": "LTGP",
        "latitude": "36.299217",
        "longitude": "32.300598",
        "altitude": "86",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "OAA": {
        "name": "Shank Air Base",
        "city": "Shank",
        "country": "Afghanistan",
        "iata": "OAA",
        "icao": "OASH",
        "latitude": "33.9225",
        "longitude": "69.07722222219999",
        "altitude": "6890",
        "timezone": "4.5",
        "dst": "N",
        "tz_name": "Asia/Kabul"
    },
    "FPR": {
        "name": "St Lucie County International Airport",
        "city": "Fort Pierce",
        "country": "United States",
        "iata": "FPR",
        "icao": "KFPR",
        "latitude": "27.49510002",
        "longitude": "-80.36830139",
        "altitude": "24",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PYM": {
        "name": "Plymouth Municipal Airport",
        "city": "Plymouth",
        "country": "United States",
        "iata": "PYM",
        "icao": "KPYM",
        "latitude": "41.909",
        "longitude": "-70.728798",
        "altitude": "148",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NCO": {
        "name": "Quonset State Airport",
        "city": "North Kingstown",
        "country": "United States",
        "iata": "NCO",
        "icao": "KOQU",
        "latitude": "41.597099304199",
        "longitude": "-71.412101745605",
        "altitude": "18",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OWD": {
        "name": "Norwood Memorial Airport",
        "city": "Norwood",
        "country": "United States",
        "iata": "OWD",
        "icao": "KOWD",
        "latitude": "42.1904983521",
        "longitude": "-71.1728973389",
        "altitude": "49",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BAF": {
        "name": "Westfield-Barnes Regional Airport",
        "city": "Westfield",
        "country": "United States",
        "iata": "BAF",
        "icao": "KBAF",
        "latitude": "42.157799",
        "longitude": "-72.715599",
        "altitude": "271",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MGJ": {
        "name": "Orange County Airport",
        "city": "Montgomery",
        "country": "United States",
        "iata": "MGJ",
        "icao": "KMGJ",
        "latitude": "41.50999832",
        "longitude": "-74.26460266",
        "altitude": "364",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HAR": {
        "name": "Capital City Airport",
        "city": "Harrisburg",
        "country": "United States",
        "iata": "HAR",
        "icao": "KCXY",
        "latitude": "40.2170982361",
        "longitude": "-76.85150146480001",
        "altitude": "347",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DXR": {
        "name": "Danbury Municipal Airport",
        "city": "Danbury",
        "country": "United States",
        "iata": "DXR",
        "icao": "KDXR",
        "latitude": "41.371498107899995",
        "longitude": "-73.48220062259999",
        "altitude": "458",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ASH": {
        "name": "Boire Field",
        "city": "Nashua",
        "country": "United States",
        "iata": "ASH",
        "icao": "KASH",
        "latitude": "42.7817001343",
        "longitude": "-71.51480102539999",
        "altitude": "199",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LWM": {
        "name": "Lawrence Municipal Airport",
        "city": "Lawrence",
        "country": "United States",
        "iata": "LWM",
        "icao": "KLWM",
        "latitude": "42.717201232899995",
        "longitude": "-71.1233978271",
        "altitude": "148",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OXC": {
        "name": "Waterbury Oxford Airport",
        "city": "Oxford",
        "country": "United States",
        "iata": "OXC",
        "icao": "KOXC",
        "latitude": "41.47859954834",
        "longitude": "-73.135200500488",
        "altitude": "726",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RMG": {
        "name": "Richard B Russell Airport",
        "city": "Rome",
        "country": "United States",
        "iata": "RMG",
        "icao": "KRMG",
        "latitude": "34.3506011963",
        "longitude": "-85.15799713130001",
        "altitude": "644",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GAD": {
        "name": "Northeast Alabama Regional Airport",
        "city": "Gadsden",
        "country": "United States",
        "iata": "GAD",
        "icao": "KGAD",
        "latitude": "33.972599",
        "longitude": "-86.088996",
        "altitude": "569",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "WDR": {
        "name": "Barrow County Airport",
        "city": "Winder",
        "country": "United States",
        "iata": "WDR",
        "icao": "KWDR",
        "latitude": "33.98289871",
        "longitude": "-83.66739655",
        "altitude": "943",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DNN": {
        "name": "Dalton Municipal Airport",
        "city": "Dalton",
        "country": "United States",
        "iata": "DNN",
        "icao": "KDNN",
        "latitude": "34.72290039",
        "longitude": "-84.87020111",
        "altitude": "709",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LGC": {
        "name": "LaGrange Callaway Airport",
        "city": "LaGrange",
        "country": "United States",
        "iata": "LGC",
        "icao": "KLGC",
        "latitude": "33.0089",
        "longitude": "-85.072601",
        "altitude": "693",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PIM": {
        "name": "Harris County Airport",
        "city": "Pine Mountain",
        "country": "United States",
        "iata": "PIM",
        "icao": "KPIM",
        "latitude": "32.8406982422",
        "longitude": "-84.8824005127",
        "altitude": "902",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GVL": {
        "name": "Lee Gilmer Memorial Airport",
        "city": "Gainesville",
        "country": "United States",
        "iata": "GVL",
        "icao": "KGVL",
        "latitude": "34.27259827",
        "longitude": "-83.8302002",
        "altitude": "1276",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PHD": {
        "name": "Harry Clever Field",
        "city": "New Philadelpha",
        "country": "United States",
        "iata": "PHD",
        "icao": "KPHD",
        "latitude": "40.470901489258",
        "longitude": "-81.419700622559",
        "altitude": "894",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HHH": {
        "name": "Hilton Head Airport",
        "city": "Hilton Head Island",
        "country": "United States",
        "iata": "HHH",
        "icao": "KHXD",
        "latitude": "32.2243995667",
        "longitude": "-80.6975021362",
        "altitude": "19",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DNL": {
        "name": "Daniel Field",
        "city": "Augusta",
        "country": "United States",
        "iata": "DNL",
        "icao": "KDNL",
        "latitude": "33.4664993286",
        "longitude": "-82.0393981934",
        "altitude": "423",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MRN": {
        "name": "Foothills Regional Airport",
        "city": "Morganton",
        "country": "United States",
        "iata": "MRN",
        "icao": "KMRN",
        "latitude": "35.820202",
        "longitude": "-81.611397",
        "altitude": "1270",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PVL": {
        "name": "Pike County-Hatcher Field",
        "city": "Pikeville",
        "country": "United States",
        "iata": "PVL",
        "icao": "KPBX",
        "latitude": "37.5617981",
        "longitude": "-82.56639862",
        "altitude": "1473",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TOC": {
        "name": "Toccoa Airport - R.G. Letourneau Field",
        "city": "Toccoa",
        "country": "United States",
        "iata": "TOC",
        "icao": "KTOC",
        "latitude": "34.59379959",
        "longitude": "-83.29579926",
        "altitude": "996",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PLV": {
        "name": "Suprunovka Airport",
        "city": "Poltava",
        "country": "Ukraine",
        "iata": "PLV",
        "icao": "UKHP",
        "latitude": "49.568599700927734",
        "longitude": "34.39720153808594",
        "altitude": "505",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Europe/Kiev"
    },
    "WUU": {
        "name": "Wau Airport",
        "city": "Wau",
        "country": "Sudan",
        "iata": "WUU",
        "icao": "HSWW",
        "latitude": "7.7258300781199996",
        "longitude": "27.9750003815",
        "altitude": "1529",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Juba"
    },
    "HUE": {
        "name": "Humera Airport",
        "city": "Humera",
        "country": "Ethiopia",
        "iata": "HUE",
        "icao": "HAHU",
        "latitude": "14.25",
        "longitude": "36.58300018310547",
        "altitude": "1930",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Addis_Ababa"
    },
    "OYL": {
        "name": "Moyale Airport",
        "city": "Moyale",
        "country": "Kenya",
        "iata": "OYL",
        "icao": "HKMY",
        "latitude": "3.46972",
        "longitude": "39.101398",
        "altitude": "2790",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "OZG": {
        "name": "Zagora Airport",
        "city": "Zagora",
        "country": "Morocco",
        "iata": "OZG",
        "icao": "GMAZ",
        "latitude": "30.3202991486",
        "longitude": "-5.86667013168",
        "altitude": "2631",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Africa/Casablanca"
    },
    "WYE": {
        "name": "Yengema Airport",
        "city": "Yengema",
        "country": "Sierra Leone",
        "iata": "WYE",
        "icao": "GFYE",
        "latitude": "8.610469818115234",
        "longitude": "-11.04539966583252",
        "altitude": "1300",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Africa/Freetown"
    },
    "GBK": {
        "name": "Gbangbatok Airport",
        "city": "Gbangbatok",
        "country": "Sierra Leone",
        "iata": "GBK",
        "icao": "GFGK",
        "latitude": "7.767000198364258",
        "longitude": "-12.383000373840332",
        "altitude": "75",
        "timezone": "0",
        "dst": "U",
        "tz_name": "Africa/Freetown"
    },
    "THX": {
        "name": "Turukhansk Airport",
        "city": "Turukhansk",
        "country": "Russia",
        "iata": "THX",
        "icao": "UOTT",
        "latitude": "65.797203064",
        "longitude": "87.9353027344",
        "altitude": "128",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "TGP": {
        "name": "Podkamennaya Tunguska Airport",
        "city": "Bor",
        "country": "Russia",
        "iata": "TGP",
        "icao": "UNIP",
        "latitude": "61.589699",
        "longitude": "89.994003",
        "altitude": "213",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "AFW": {
        "name": "Fort Worth Alliance Airport",
        "city": "Fort Worth",
        "country": "United States",
        "iata": "AFW",
        "icao": "KAFW",
        "latitude": "32.9875984192",
        "longitude": "-97.31880187990001",
        "altitude": "722",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RMK": {
        "name": "Renmark Airport",
        "city": "Renmark",
        "country": "Australia",
        "iata": "RMK",
        "icao": "YREN",
        "latitude": "-34.1963996887207",
        "longitude": "140.6739959716797",
        "altitude": "115",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "LGH": {
        "name": "Leigh Creek Airport",
        "city": "Leigh Creek",
        "country": "Australia",
        "iata": "LGH",
        "icao": "YLEC",
        "latitude": "-30.59830093383789",
        "longitude": "138.42599487304688",
        "altitude": "856",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "RTS": {
        "name": "Rottnest Island Airport",
        "city": "Rottnest Island",
        "country": "Australia",
        "iata": "RTS",
        "icao": "YRTI",
        "latitude": "-32.006699",
        "longitude": "115.540001",
        "altitude": "12",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "FOS": {
        "name": "Forrest Airport",
        "city": "Forrest",
        "country": "Australia",
        "iata": "FOS",
        "icao": "YFRT",
        "latitude": "-30.83810043334961",
        "longitude": "128.11500549316406",
        "altitude": "511",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "KEW": {
        "name": "Keewaywin Airport",
        "city": "Keewaywin",
        "country": "Canada",
        "iata": "KEW",
        "icao": "CPV8",
        "latitude": "52.991100311299995",
        "longitude": "-92.8364028931",
        "altitude": "988",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "YSP": {
        "name": "Marathon Airport",
        "city": "Marathon",
        "country": "Canada",
        "iata": "YSP",
        "icao": "CYSP",
        "latitude": "48.75529861450195",
        "longitude": "-86.34439849853516",
        "altitude": "1035",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHF": {
        "name": "Hearst René Fontaine Municipal Airport",
        "city": "Hearst",
        "country": "Canada",
        "iata": "YHF",
        "icao": "CYHF",
        "latitude": "49.71419906616211",
        "longitude": "-83.68609619140625",
        "altitude": "827",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YHN": {
        "name": "Hornepayne Municipal Airport",
        "city": "Hornepayne",
        "country": "Canada",
        "iata": "YHN",
        "icao": "CYHN",
        "latitude": "49.19309997558594",
        "longitude": "-84.75890350341797",
        "altitude": "1099",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YKX": {
        "name": "Kirkland Lake Airport",
        "city": "Kirkland Lake",
        "country": "Canada",
        "iata": "YKX",
        "icao": "CYKX",
        "latitude": "48.21030044555664",
        "longitude": "-79.98139953613281",
        "altitude": "1157",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YMG": {
        "name": "Manitouwadge Airport",
        "city": "Manitouwadge",
        "country": "Canada",
        "iata": "YMG",
        "icao": "CYMG",
        "latitude": "49.083900451660156",
        "longitude": "-85.86060333251953",
        "altitude": "1198",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YXZ": {
        "name": "Wawa Airport",
        "city": "Wawa",
        "country": "Canada",
        "iata": "YXZ",
        "icao": "CYXZ",
        "latitude": "47.96670150756836",
        "longitude": "-84.78669738769531",
        "altitude": "942",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YEM": {
        "name": "Manitoulin East Municipal Airport",
        "city": "Manitowaning",
        "country": "Canada",
        "iata": "YEM",
        "icao": "CYEM",
        "latitude": "45.84280014038086",
        "longitude": "-81.85810089111328",
        "altitude": "869",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "LWC": {
        "name": "Lawrence Municipal Airport",
        "city": "Lawrence",
        "country": "United States",
        "iata": "LWC",
        "icao": "KLWC",
        "latitude": "39.01119995",
        "longitude": "-95.21659851",
        "altitude": "833",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PPM": {
        "name": "Pompano Beach Airpark",
        "city": "Pompano Beach",
        "country": "United States",
        "iata": "PPM",
        "icao": "KPMP",
        "latitude": "26.247100830078",
        "longitude": "-80.111099243164",
        "altitude": "19",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "XMC": {
        "name": "Mallacoota Airport",
        "city": "Mallacoota",
        "country": "Australia",
        "iata": "XMC",
        "icao": "YMCO",
        "latitude": "-37.59830093383789",
        "longitude": "149.72000122070312",
        "altitude": "31",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Hobart"
    },
    "ULH": {
        "name": "Majeed Bin Abdulaziz Airport",
        "city": "Al-Ula",
        "country": "Saudi Arabia",
        "iata": "ULH",
        "icao": "OEAO",
        "latitude": "26.48",
        "longitude": "38.1288888889",
        "altitude": "2050",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Asia/Riyadh"
    },
    "YUE": {
        "name": "Yuendumu Airport",
        "city": "Yuendumu ",
        "country": "Australia",
        "iata": "YUE",
        "icao": "YYND",
        "latitude": "-22.254199981689453",
        "longitude": "131.78199768066406",
        "altitude": "2205",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Darwin"
    },
    "LOP": {
        "name": "Lombok International Airport",
        "city": "Praya",
        "country": "Indonesia",
        "iata": "LOP",
        "icao": "WADL",
        "latitude": "-8.757322",
        "longitude": "116.276675",
        "altitude": "319",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "ZMH": {
        "name": "South Cariboo Region / 108 Mile Airport",
        "city": "108 Mile Ranch",
        "country": "Canada",
        "iata": "ZMH",
        "icao": "CZML",
        "latitude": "51.736099243199995",
        "longitude": "-121.333000183",
        "altitude": "3126",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "HDG": {
        "name": "Handan Airport",
        "city": "Handan",
        "country": "China",
        "iata": "HDG",
        "icao": "ZBHD",
        "latitude": "36.5258333333",
        "longitude": "114.425555556",
        "altitude": "229",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "LOZ": {
        "name": "London-Corbin Airport/Magee Field",
        "city": "London",
        "country": "United States",
        "iata": "LOZ",
        "icao": "KLOZ",
        "latitude": "37.0821990967",
        "longitude": "-84.08489990230001",
        "altitude": "1212",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FBG": {
        "name": "Simmons Army Air Field",
        "city": "Fredericksburg",
        "country": "United States",
        "iata": "FBG",
        "icao": "KFBG",
        "latitude": "35.13180161",
        "longitude": "-78.93669891",
        "altitude": "244",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "WMI": {
        "name": "Modlin Airport",
        "city": "Warsaw",
        "country": "Poland",
        "iata": "WMI",
        "icao": "EPMO",
        "latitude": "52.451099",
        "longitude": "20.6518",
        "altitude": "341",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "JXA": {
        "name": "Jixi Xingkaihu Airport",
        "city": "Jixi",
        "country": "China",
        "iata": "JXA",
        "icao": "ZYJX",
        "latitude": "45.293",
        "longitude": "131.193",
        "altitude": "760",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "JDG": {
        "name": "Jeongseok Airport",
        "city": "Seogwipo",
        "country": "South Korea",
        "iata": "JDG",
        "icao": "RKPD",
        "latitude": "33.3996009827",
        "longitude": "126.711997986",
        "altitude": "1171",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Seoul"
    },
    "YGM": {
        "name": "Gimli Industrial Park Airport",
        "city": "Gimli",
        "country": "Canada",
        "iata": "YGM",
        "icao": "CYGM",
        "latitude": "50.62810134887695",
        "longitude": "-97.04329681396484",
        "altitude": "753",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Winnipeg"
    },
    "EYK": {
        "name": "Beloyarskiy Airport",
        "city": "Beloyarsky",
        "country": "Russia",
        "iata": "EYK",
        "icao": "USHY",
        "latitude": "63.686901",
        "longitude": "66.698601",
        "altitude": "82",
        "timezone": "5",
        "dst": "N",
        "tz_name": "Asia/Yekaterinburg"
    },
    "RAC": {
        "name": "John H Batten Airport",
        "city": "Racine",
        "country": "United States",
        "iata": "RAC",
        "icao": "KRAC",
        "latitude": "42.7606010437",
        "longitude": "-87.8152008057",
        "altitude": "674",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "RZP": {
        "name": "Cesar Lim Rodriguez Airport",
        "city": "Taytay",
        "country": "Philippines",
        "iata": "RZP",
        "icao": "RPSD",
        "latitude": "10.81874",
        "longitude": "119.507697",
        "altitude": "80",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Manila"
    },
    "RKZ": {
        "name": "Shigatse Air Base",
        "city": "Shigatse",
        "country": "China",
        "iata": "RKZ",
        "icao": "ZURK",
        "latitude": "29.3519",
        "longitude": "89.311401",
        "altitude": "3782",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "TIW": {
        "name": "Tacoma Narrows Airport",
        "city": "Tacoma",
        "country": "United States",
        "iata": "TIW",
        "icao": "KTIW",
        "latitude": "47.26789856",
        "longitude": "-122.5780029",
        "altitude": "294",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "GUF": {
        "name": "Jack Edwards Airport",
        "city": "Gulf Shores",
        "country": "United States",
        "iata": "GUF",
        "icao": "KJKA",
        "latitude": "30.29050064",
        "longitude": "-87.67179871",
        "altitude": "17",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "IBB": {
        "name": "General Villamil Airport",
        "city": "Isabela",
        "country": "Ecuador",
        "iata": "IBB",
        "icao": "SEII",
        "latitude": "-0.942628026009",
        "longitude": "-90.9530029297",
        "altitude": "35",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "Pacific/Galapagos"
    },
    "HMJ": {
        "name": "Khmelnytskyi Airport",
        "city": "Khmeinitskiy",
        "country": "Ukraine",
        "iata": "HMJ",
        "icao": "UKLH",
        "latitude": "49.359699",
        "longitude": "26.933399",
        "altitude": "1150",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "HIW": {
        "name": "Hiroshimanishi Airport",
        "city": "Hiroshima",
        "country": "Japan",
        "iata": "HIW",
        "icao": "RJBH",
        "latitude": "34.36690139770508",
        "longitude": "132.41400146484375",
        "altitude": "15",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Tokyo"
    },
    "KYI": {
        "name": "Yalata Mission Airport",
        "city": "Yalata",
        "country": "Australia",
        "iata": "KYI",
        "icao": "YYTA",
        "latitude": "-31.470600128173828",
        "longitude": "131.8249969482422",
        "altitude": "0",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "HZL": {
        "name": "Hazleton Municipal Airport",
        "city": "Hazleton",
        "country": "United States",
        "iata": "HZL",
        "icao": "KHZL",
        "latitude": "40.986801147499996",
        "longitude": "-75.9949035645",
        "altitude": "1603",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CBE": {
        "name": "Greater Cumberland Regional Airport",
        "city": "Cumberland",
        "country": "United States",
        "iata": "CBE",
        "icao": "KCBE",
        "latitude": "39.615398407",
        "longitude": "-78.7609024048",
        "altitude": "775",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "WYN": {
        "name": "Wyndham Airport",
        "city": "Wyndham",
        "country": "Australia",
        "iata": "WYN",
        "icao": "YWYM",
        "latitude": "-15.51140022277832",
        "longitude": "128.1529998779297",
        "altitude": "14",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Australia/Perth"
    },
    "YBO": {
        "name": "Bob Quinn Lake Airport",
        "city": "Bob Quinn Lake",
        "country": "Canada",
        "iata": "YBO",
        "icao": "CBW4",
        "latitude": "56.9667015076",
        "longitude": "-130.25",
        "altitude": "2000",
        "timezone": "-8",
        "dst": "U",
        "tz_name": "America/Vancouver"
    },
    "KLF": {
        "name": "Grabtsevo Airport",
        "city": "Kaluga",
        "country": "Russia",
        "iata": "KLF",
        "icao": "UUBC",
        "latitude": "54.5499992371",
        "longitude": "36.3666687012",
        "altitude": "656",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "LNR": {
        "name": "Tri-County Regional Airport",
        "city": "Lone Rock",
        "country": "United States",
        "iata": "LNR",
        "icao": "KLNR",
        "latitude": "43.2117004395",
        "longitude": "-90.181602478",
        "altitude": "717",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JOT": {
        "name": "Joliet Regional Airport",
        "city": "Joliet",
        "country": "United States",
        "iata": "JOT",
        "icao": "KJOT",
        "latitude": "41.51779938",
        "longitude": "-88.17549896",
        "altitude": "582",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "VYS": {
        "name": "Illinois Valley Regional Airport-Walter A Duncan Field",
        "city": "Peru",
        "country": "United States",
        "iata": "VYS",
        "icao": "KVYS",
        "latitude": "41.351898",
        "longitude": "-89.153099",
        "altitude": "654",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "JXN": {
        "name": "Jackson County Reynolds Field",
        "city": "Jackson",
        "country": "United States",
        "iata": "JXN",
        "icao": "KJXN",
        "latitude": "42.259799957300004",
        "longitude": "-84.45939636230001",
        "altitude": "1001",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BBX": {
        "name": "Wings Field",
        "city": "Philadelphia",
        "country": "United States",
        "iata": "BBX",
        "icao": "KLOM",
        "latitude": "40.1375007629",
        "longitude": "-75.2650985718",
        "altitude": "302",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OBE": {
        "name": "Okeechobee County Airport",
        "city": "Okeechobee",
        "country": "United States",
        "iata": "OBE",
        "icao": "KOBE",
        "latitude": "27.262800216699997",
        "longitude": "-80.8498001099",
        "altitude": "34",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SEF": {
        "name": "Sebring Regional Airport",
        "city": "Sebring",
        "country": "United States",
        "iata": "SEF",
        "icao": "KSEF",
        "latitude": "27.45639992",
        "longitude": "-81.3423996",
        "altitude": "62",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AVO": {
        "name": "Avon Park Executive Airport",
        "city": "Avon Park",
        "country": "United States",
        "iata": "AVO",
        "icao": "KAVO",
        "latitude": "27.59119987",
        "longitude": "-81.52780151",
        "altitude": "160",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GIF": {
        "name": "Winter Haven Regional Airport - Gilbert Field",
        "city": "Winter Haven",
        "country": "United States",
        "iata": "GIF",
        "icao": "KGIF",
        "latitude": "28.062901",
        "longitude": "-81.753304",
        "altitude": "145",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ZPH": {
        "name": "Zephyrhills Municipal Airport",
        "city": "Zephyrhills",
        "country": "United States",
        "iata": "ZPH",
        "icao": "KZPH",
        "latitude": "28.2282009125",
        "longitude": "-82.15589904790001",
        "altitude": "90",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OCF": {
        "name": "Ocala International Airport - Jim Taylor Field",
        "city": "Ocala",
        "country": "United States",
        "iata": "OCF",
        "icao": "KOCF",
        "latitude": "29.17259979",
        "longitude": "-82.22419739",
        "altitude": "90",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AIK": {
        "name": "Aiken Regional Airport",
        "city": "Aiken",
        "country": "United States",
        "iata": "AIK",
        "icao": "KAIK",
        "latitude": "33.649399",
        "longitude": "-81.684998",
        "altitude": "528",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CDN": {
        "name": "Woodward Field",
        "city": "Camden",
        "country": "United States",
        "iata": "CDN",
        "icao": "KCDN",
        "latitude": "34.2835998535",
        "longitude": "-80.56490325930001",
        "altitude": "302",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LBT": {
        "name": "Lumberton Regional Airport",
        "city": "Lumberton",
        "country": "United States",
        "iata": "LBT",
        "icao": "KLBT",
        "latitude": "34.6099014282",
        "longitude": "-79.05940246579999",
        "altitude": "126",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SOP": {
        "name": "Moore County Airport",
        "city": "Pinehurst-Southern Pines",
        "country": "United States",
        "iata": "SOP",
        "icao": "KSOP",
        "latitude": "35.23740005",
        "longitude": "-79.3911972",
        "altitude": "455",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SVH": {
        "name": "Statesville Regional Airport",
        "city": "Statesville",
        "country": "United States",
        "iata": "SVH",
        "icao": "KSVH",
        "latitude": "35.765300750732",
        "longitude": "-80.953903198242",
        "altitude": "968",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LHV": {
        "name": "William T. Piper Memorial Airport",
        "city": "Lock Haven",
        "country": "United States",
        "iata": "LHV",
        "icao": "KLHV",
        "latitude": "41.13560104",
        "longitude": "-77.42230225",
        "altitude": "556",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BKL": {
        "name": "Burke Lakefront Airport",
        "city": "Cleveland",
        "country": "United States",
        "iata": "BKL",
        "icao": "KBKL",
        "latitude": "41.51750183105469",
        "longitude": "-81.68329620361328",
        "altitude": "583",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DKK": {
        "name": "Chautauqua County-Dunkirk Airport",
        "city": "Dunkirk",
        "country": "United States",
        "iata": "DKK",
        "icao": "KDKK",
        "latitude": "42.49330139",
        "longitude": "-79.27200317",
        "altitude": "693",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LLY": {
        "name": "South Jersey Regional Airport",
        "city": "Mount Holly",
        "country": "United States",
        "iata": "LLY",
        "icao": "KVAY",
        "latitude": "39.942901611299995",
        "longitude": "-74.845703125",
        "altitude": "53",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LDJ": {
        "name": "Linden Airport",
        "city": "Linden",
        "country": "United States",
        "iata": "LDJ",
        "icao": "KLDJ",
        "latitude": "40.617401123",
        "longitude": "-74.2445983887",
        "altitude": "23",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ANQ": {
        "name": "Tri State Steuben County Airport",
        "city": "Angola",
        "country": "United States",
        "iata": "ANQ",
        "icao": "KANQ",
        "latitude": "41.639702",
        "longitude": "-85.083504",
        "altitude": "995",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CLW": {
        "name": "Clearwater Air Park",
        "city": "Clearwater",
        "country": "United States",
        "iata": "CLW",
        "icao": "KCLW",
        "latitude": "27.9766998291",
        "longitude": "-82.7586975098",
        "altitude": "71",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CGX": {
        "name": "Chicago Meigs Airport",
        "city": "Chicago",
        "country": "United States",
        "iata": "CGX",
        "icao": "KCGX",
        "latitude": "41.85879898071289",
        "longitude": "-87.60790252685547",
        "altitude": "593",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CRE": {
        "name": "Grand Strand Airport",
        "city": "North Myrtle Beach",
        "country": "United States",
        "iata": "CRE",
        "icao": "KCRE",
        "latitude": "33.8116989136",
        "longitude": "-78.72389984130001",
        "altitude": "32",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BXO": {
        "name": "Buochs Airport",
        "city": "Buochs",
        "country": "Switzerland",
        "iata": "BXO",
        "icao": "LSZC",
        "latitude": "46.974444",
        "longitude": "8.396944",
        "altitude": "1475",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zurich"
    },
    "WBW": {
        "name": "Wilkes Barre Wyoming Valley Airport",
        "city": "Wilkes-Barre",
        "country": "United States",
        "iata": "WBW",
        "icao": "KWBW",
        "latitude": "41.2971992493",
        "longitude": "-75.8511962891",
        "altitude": "545",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LNN": {
        "name": "Willoughby Lost Nation Municipal Airport",
        "city": "Willoughby",
        "country": "United States",
        "iata": "LNN",
        "icao": "KLNN",
        "latitude": "41.683998107899995",
        "longitude": "-81.3897018433",
        "altitude": "626",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "UMD": {
        "name": "Uummannaq Heliport",
        "city": "Uummannaq",
        "country": "Greenland",
        "iata": "UMD",
        "icao": "BGUM",
        "latitude": "70.6804279261",
        "longitude": "-52.111630439799995",
        "altitude": "50",
        "timezone": "-3",
        "dst": "E",
        "tz_name": "America/Godthab"
    },
    "RLK": {
        "name": "Bayannur Tianjitai Airport",
        "city": "Bayannur",
        "country": "China",
        "iata": "RLK",
        "icao": "ZBYZ",
        "latitude": "40.926",
        "longitude": "107.7428",
        "altitude": "3400",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "FFT": {
        "name": "Capital City Airport",
        "city": "Frankfort",
        "country": "United States",
        "iata": "FFT",
        "icao": "KFFT",
        "latitude": "38.18249893",
        "longitude": "-84.90470123",
        "altitude": "806",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LEW": {
        "name": "Auburn Lewiston Municipal Airport",
        "city": "Lewiston",
        "country": "United States",
        "iata": "LEW",
        "icao": "KLEW",
        "latitude": "44.048500061",
        "longitude": "-70.2835006714",
        "altitude": "288",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MRK": {
        "name": "Marco Island Executive Airport",
        "city": "Marco Island Airport",
        "country": "United States",
        "iata": "MRK",
        "icao": "KMKY",
        "latitude": "25.995001",
        "longitude": "-81.672501",
        "altitude": "5",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DRE": {
        "name": "Drummond Island Airport",
        "city": "Drummond Island",
        "country": "United States",
        "iata": "DRE",
        "icao": "KDRM",
        "latitude": "46.0093002319",
        "longitude": "-83.74389648440001",
        "altitude": "668",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GDW": {
        "name": "Gladwin Zettel Memorial Airport",
        "city": "Gladwin",
        "country": "United States",
        "iata": "GDW",
        "icao": "KGDW",
        "latitude": "43.9706001282",
        "longitude": "-84.47499847410002",
        "altitude": "776",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MFI": {
        "name": "Marshfield Municipal Airport",
        "city": "Marshfield",
        "country": "United States",
        "iata": "MFI",
        "icao": "KMFI",
        "latitude": "44.6369018555",
        "longitude": "-90.18930053710001",
        "altitude": "1277",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ISW": {
        "name": "Alexander Field South Wood County Airport",
        "city": "Wisconsin Rapids",
        "country": "United States",
        "iata": "ISW",
        "icao": "KISW",
        "latitude": "44.3602981567",
        "longitude": "-89.83899688720001",
        "altitude": "1021",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CWI": {
        "name": "Clinton Municipal Airport",
        "city": "Clinton",
        "country": "United States",
        "iata": "CWI",
        "icao": "KCWI",
        "latitude": "41.8311004639",
        "longitude": "-90.3291015625",
        "altitude": "708",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BVY": {
        "name": "Beverly Municipal Airport",
        "city": "Beverly",
        "country": "United States",
        "iata": "BVY",
        "icao": "KBVY",
        "latitude": "42.584201812699995",
        "longitude": "-70.91649627689999",
        "altitude": "107",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "OSF": {
        "name": "Ostafyevo International Airport",
        "city": "Moscow",
        "country": "Russia",
        "iata": "OSF",
        "icao": "UUMO",
        "latitude": "55.511667",
        "longitude": "37.507222",
        "altitude": "568",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "YRQ": {
        "name": "Trois-Rivières Airport",
        "city": "Trois Rivieres",
        "country": "Canada",
        "iata": "YRQ",
        "icao": "CYRQ",
        "latitude": "46.35279846191406",
        "longitude": "-72.67939758300781",
        "altitude": "199",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "POF": {
        "name": "Poplar Bluff Municipal Airport",
        "city": "Poplar Bluff",
        "country": "United States",
        "iata": "POF",
        "icao": "KPOF",
        "latitude": "36.773899078369",
        "longitude": "-90.324897766113",
        "altitude": "331",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "EOK": {
        "name": "Keokuk Municipal Airport",
        "city": "Keokuk",
        "country": "United States",
        "iata": "EOK",
        "icao": "KEOK",
        "latitude": "40.459899902299995",
        "longitude": "-91.4284973145",
        "altitude": "671",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PSL": {
        "name": "Perth/Scone Airport",
        "city": "Perth",
        "country": "United Kingdom",
        "iata": "PSL",
        "icao": "EGPT",
        "latitude": "56.43920135498047",
        "longitude": "-3.372220039367676",
        "altitude": "397",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "STP": {
        "name": "St Paul Downtown Holman Field",
        "city": "St. Paul",
        "country": "United States",
        "iata": "STP",
        "icao": "KSTP",
        "latitude": "44.93450164794922",
        "longitude": "-93.05999755859375",
        "altitude": "705",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SOO": {
        "name": "Söderhamn Airport",
        "city": "Soderhamn",
        "country": "Sweden",
        "iata": "SOO",
        "icao": "ESNY",
        "latitude": "61.26150131225586",
        "longitude": "17.09910011291504",
        "altitude": "88",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Stockholm"
    },
    "VNA": {
        "name": "Saravane Airport",
        "city": "Saravane",
        "country": "Laos",
        "iata": "VNA",
        "icao": "VLSV",
        "latitude": "15.709439207700001",
        "longitude": "106.410698891",
        "altitude": "574",
        "timezone": "7",
        "dst": "U",
        "tz_name": "Asia/Vientiane"
    },
    "DKS": {
        "name": "Dikson Airport",
        "city": "Dikson",
        "country": "Russia",
        "iata": "DKS",
        "icao": "UODD",
        "latitude": "73.51780700683594",
        "longitude": "80.37966918945312",
        "altitude": "47",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "BYT": {
        "name": "Bantry Aerodrome",
        "city": "Bantry",
        "country": "Ireland",
        "iata": "BYT",
        "icao": "EIBN",
        "latitude": "51.66859817504883",
        "longitude": "-9.484169960021973",
        "altitude": "7",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/Dublin"
    },
    "ADY": {
        "name": "Alldays Airport",
        "city": "Alldays",
        "country": "South Africa",
        "iata": "ADY",
        "icao": "FAAL",
        "latitude": "-22.6790008545",
        "longitude": "29.0555000305",
        "altitude": "2600",
        "timezone": "2",
        "dst": "U",
        "tz_name": "Africa/Johannesburg"
    },
    "HAO": {
        "name": "Butler Co Regional Airport - Hogan Field",
        "city": "Hamilton",
        "country": "United States",
        "iata": "HAO",
        "icao": "KHAO",
        "latitude": "39.363800048799995",
        "longitude": "-84.5220031738",
        "altitude": "633",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GAS": {
        "name": "Garissa Airport",
        "city": "Garissa",
        "country": "Kenya",
        "iata": "GAS",
        "icao": "HKGA",
        "latitude": "-0.4635080099105835",
        "longitude": "39.64830017089844",
        "altitude": "475",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "HOA": {
        "name": "Hola Airport",
        "city": "Hola",
        "country": "Kenya",
        "iata": "HOA",
        "icao": "HKHO",
        "latitude": "-1.5219999551773071",
        "longitude": "40.00400161743164",
        "altitude": "195",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "KEY": {
        "name": "Kericho Airport",
        "city": "Kericho",
        "country": "Kenya",
        "iata": "KEY",
        "icao": "HKKR",
        "latitude": "-0.3899",
        "longitude": "35.242093",
        "altitude": "6446",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "ILU": {
        "name": "Kilaguni Airport",
        "city": "Kilaguni",
        "country": "Kenya",
        "iata": "ILU",
        "icao": "HKKL",
        "latitude": "-2.9106099605560303",
        "longitude": "38.06520080566406",
        "altitude": "2750",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "ATJ": {
        "name": "Antsirabe Airport",
        "city": "Antsirabe",
        "country": "Madagascar",
        "iata": "ATJ",
        "icao": "FMME",
        "latitude": "-19.8392214824",
        "longitude": "47.063713073699994",
        "altitude": "4997",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "OVA": {
        "name": "Bekily Airport",
        "city": "Bekily",
        "country": "Madagascar",
        "iata": "OVA",
        "icao": "FMSL",
        "latitude": "-24.235694754699995",
        "longitude": "45.3045272827",
        "altitude": "1270",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Indian/Antananarivo"
    },
    "UTS": {
        "name": "Ust-Tsylma Airport",
        "city": "Ust-Tsylma",
        "country": "Russia",
        "iata": "UTS",
        "icao": "UUYX",
        "latitude": "65.43729400630001",
        "longitude": "52.20033645629999",
        "altitude": "262",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "RGK": {
        "name": "Gorno-Altaysk Airport",
        "city": "Gorno-Altaysk",
        "country": "Russia",
        "iata": "RGK",
        "icao": "UNBG",
        "latitude": "51.9667015076",
        "longitude": "85.8332977295",
        "altitude": "965",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Krasnoyarsk"
    },
    "FLD": {
        "name": "Fond du Lac County Airport",
        "city": "Fond du Lac",
        "country": "United States",
        "iata": "FLD",
        "icao": "KFLD",
        "latitude": "43.7711982727",
        "longitude": "-88.48840332030001",
        "altitude": "808",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "STE": {
        "name": "Stevens Point Municipal Airport",
        "city": "Stevens Point",
        "country": "United States",
        "iata": "STE",
        "icao": "KSTE",
        "latitude": "44.5452003479",
        "longitude": "-89.530296325684",
        "altitude": "1110",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MQJ": {
        "name": "Moma Airport",
        "city": "Honuu",
        "country": "Russia",
        "iata": "MQJ",
        "icao": "UEMA",
        "latitude": "66.450861",
        "longitude": "143.261551",
        "altitude": "656",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "PEF": {
        "name": "Peenemünde Airport",
        "city": "Peenemunde",
        "country": "Germany",
        "iata": "PEF",
        "icao": "EDCP",
        "latitude": "54.1577796936",
        "longitude": "13.774443626399998",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "CJN": {
        "name": "Nusawiru Airport",
        "city": "Nusawiru",
        "country": "Indonesia",
        "iata": "CJN",
        "icao": "WI1A",
        "latitude": "-7.719895",
        "longitude": "108.488995",
        "altitude": "16",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "GQQ": {
        "name": "Galion Municipal Airport",
        "city": "Galion",
        "country": "United States",
        "iata": "GQQ",
        "icao": "KGQQ",
        "latitude": "40.7533988953",
        "longitude": "-82.7238006592",
        "altitude": "1224",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TPN": {
        "name": "Tiputini Airport",
        "city": "Tiputini",
        "country": "Ecuador",
        "iata": "TPN",
        "icao": "SETI",
        "latitude": "-0.7761110067367554",
        "longitude": "-75.52639770507812",
        "altitude": "997",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Guayaquil"
    },
    "PTZ": {
        "name": "Rio Amazonas Airport",
        "city": "Pastaza",
        "country": "Ecuador",
        "iata": "PTZ",
        "icao": "SESM",
        "latitude": "-1.5052399635299998",
        "longitude": "-78.0626983643",
        "altitude": "3465",
        "timezone": "-5",
        "dst": "S",
        "tz_name": "America/Guayaquil"
    },
    "CKV": {
        "name": "Clarksville–Montgomery County Regional Airport",
        "city": "Clarksville",
        "country": "United States",
        "iata": "CKV",
        "icao": "KCKV",
        "latitude": "36.6218986511",
        "longitude": "-87.4150009155",
        "altitude": "550",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LPC": {
        "name": "Lompoc Airport",
        "city": "Lompoc",
        "country": "United States",
        "iata": "LPC",
        "icao": "KLPC",
        "latitude": "34.665599823",
        "longitude": "-120.468002319",
        "altitude": "88",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CTH": {
        "name": "Chester County G O Carlson Airport",
        "city": "Coatesville",
        "country": "United States",
        "iata": "CTH",
        "icao": "KMQS",
        "latitude": "39.97900009",
        "longitude": "-75.8655014",
        "altitude": "660",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BST": {
        "name": "Bost Airport",
        "city": "Lashkar Gah",
        "country": "Afghanistan",
        "iata": "BST",
        "icao": "OABT",
        "latitude": "31.55970001220703",
        "longitude": "64.36499786376953",
        "altitude": "2464",
        "timezone": "4.5",
        "dst": "U",
        "tz_name": "Asia/Kabul"
    },
    "LLK": {
        "name": "Lankaran International Airport",
        "city": "Lankaran",
        "country": "Azerbaijan",
        "iata": "LLK",
        "icao": "UBBL",
        "latitude": "38.746398925799994",
        "longitude": "48.8180007935",
        "altitude": "30",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Baku"
    },
    "GBB": {
        "name": "Gabala International Airport",
        "city": "Qabala",
        "country": "Azerbaijan",
        "iata": "GBB",
        "icao": "UBBQ",
        "latitude": "40.826667",
        "longitude": "47.7125",
        "altitude": "935",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Baku"
    },
    "ZTU": {
        "name": "Zaqatala International Airport",
        "city": "Zaqatala",
        "country": "Azerbaijan",
        "iata": "ZTU",
        "icao": "UBBY",
        "latitude": "41.562222",
        "longitude": "46.667221",
        "altitude": "1279",
        "timezone": "4",
        "dst": "E",
        "tz_name": "Asia/Baku"
    },
    "LKP": {
        "name": "Lake Placid Airport",
        "city": "Lake Placid",
        "country": "United States",
        "iata": "LKP",
        "icao": "KLKP",
        "latitude": "44.2644996643",
        "longitude": "-73.96189880370001",
        "altitude": "1747",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "KDY": {
        "name": "Typliy Klyuch Airport",
        "city": "Khandyga",
        "country": "Russia",
        "iata": "KDY",
        "icao": "UEMH",
        "latitude": "62.7890014648",
        "longitude": "136.854995728",
        "altitude": "925",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "GYG": {
        "name": "Magan Airport",
        "city": "Yakutsk",
        "country": "Russia",
        "iata": "GYG",
        "icao": "UEMM",
        "latitude": "62.103484",
        "longitude": "129.545288",
        "altitude": "577",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "JIQ": {
        "name": "Qianjiang Wulingshan Airport",
        "city": "Qianjiang",
        "country": "China",
        "iata": "JIQ",
        "icao": "ZUQJ",
        "latitude": "29.5133333333",
        "longitude": "108.831111111",
        "altitude": "2075",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "AOH": {
        "name": "Lima Allen County Airport",
        "city": "Lima",
        "country": "United States",
        "iata": "AOH",
        "icao": "KAOH",
        "latitude": "40.706902",
        "longitude": "-84.026703",
        "altitude": "975",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DSO": {
        "name": "Sondok Airport",
        "city": "Hamhung",
        "country": "North Korea",
        "iata": "DSO",
        "icao": "ZKSD",
        "latitude": "39.745201",
        "longitude": "127.473999",
        "altitude": "12",
        "timezone": "8.5",
        "dst": "U",
        "tz_name": "Asia/Pyongyang"
    },
    "SSI": {
        "name": "Malcolm McKinnon Airport",
        "city": "Brunswick",
        "country": "United States",
        "iata": "SSI",
        "icao": "KSSI",
        "latitude": "31.15180016",
        "longitude": "-81.39129639",
        "altitude": "19",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BFP": {
        "name": "Beaver County Airport",
        "city": "Beaver Falls",
        "country": "United States",
        "iata": "BFP",
        "icao": "KBVI",
        "latitude": "40.7724990845",
        "longitude": "-80.39140319820001",
        "altitude": "1253",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "GGE": {
        "name": "Georgetown County Airport",
        "city": "Georgetown",
        "country": "United States",
        "iata": "GGE",
        "icao": "KGGE",
        "latitude": "33.3116989136",
        "longitude": "-79.3196029663",
        "altitude": "39",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "HDI": {
        "name": "Hardwick Field",
        "city": "Cleveland",
        "country": "United States",
        "iata": "HDI",
        "icao": "KHDI",
        "latitude": "35.22010040283203",
        "longitude": "-84.8323974609375",
        "altitude": "874",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "RNT": {
        "name": "Renton Municipal Airport",
        "city": "Renton",
        "country": "United States",
        "iata": "RNT",
        "icao": "KRNT",
        "latitude": "47.4930992126",
        "longitude": "-122.216003418",
        "altitude": "32",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "POC": {
        "name": "Brackett Field",
        "city": "La Verne",
        "country": "United States",
        "iata": "POC",
        "icao": "KPOC",
        "latitude": "34.091598510742",
        "longitude": "-117.78199768066",
        "altitude": "1011",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "CTY": {
        "name": "Cross City Airport",
        "city": "Cross City",
        "country": "United States",
        "iata": "CTY",
        "icao": "KCTY",
        "latitude": "29.6354999542",
        "longitude": "-83.10479736330001",
        "altitude": "42",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "CEU": {
        "name": "Oconee County Regional Airport",
        "city": "Clemson",
        "country": "United States",
        "iata": "CEU",
        "icao": "KCEU",
        "latitude": "34.6719017",
        "longitude": "-82.8864975",
        "altitude": "892",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BEC": {
        "name": "Beech Factory Airport",
        "city": "Wichita",
        "country": "United States",
        "iata": "BEC",
        "icao": "KBEC",
        "latitude": "37.694499969499994",
        "longitude": "-97.21499633790002",
        "altitude": "1408",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "QFO": {
        "name": "Duxford Aerodrome",
        "city": "Duxford",
        "country": "United Kingdom",
        "iata": "QFO",
        "icao": "EGSU",
        "latitude": "52.090801",
        "longitude": "0.131944",
        "altitude": "125",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "SNY": {
        "name": "Sidney Municipal-Lloyd W Carr Field",
        "city": "Sidney",
        "country": "United States",
        "iata": "SNY",
        "icao": "KSNY",
        "latitude": "41.10129929",
        "longitude": "-102.9850006",
        "altitude": "4313",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "GKL": {
        "name": "Great Keppel Is Airport",
        "city": "Great Keppel Island",
        "country": "Australia",
        "iata": "GKL",
        "icao": "YGKL",
        "latitude": "-23.1833000183",
        "longitude": "150.942001343",
        "altitude": "21",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Brisbane"
    },
    "RPB": {
        "name": "Roper Bar Airport",
        "city": "Roper Bar",
        "country": "Australia",
        "iata": "RPB",
        "icao": "YRRB",
        "latitude": "-14.734814",
        "longitude": "134.525485",
        "altitude": "92",
        "timezone": "9.5",
        "dst": "U",
        "tz_name": "Australia/Darwin"
    },
    "IFL": {
        "name": "Innisfail Airport",
        "city": "Innisfail",
        "country": "Australia",
        "iata": "IFL",
        "icao": "YIFL",
        "latitude": "-17.55940055847168",
        "longitude": "146.01199340820312",
        "altitude": "46",
        "timezone": "10",
        "dst": "U",
        "tz_name": "Australia/Brisbane"
    },
    "JRF": {
        "name": "Kalaeloa Airport",
        "city": "Kapolei",
        "country": "United States",
        "iata": "JRF",
        "icao": "PHJR",
        "latitude": "21.3074",
        "longitude": "-158.070009",
        "altitude": "30",
        "timezone": "-10",
        "dst": "U",
        "tz_name": "Pacific/Honolulu"
    },
    "BIN": {
        "name": "Bamiyan Airport",
        "city": "Bamyan",
        "country": "Afghanistan",
        "iata": "BIN",
        "icao": "OABN",
        "latitude": "34.81700134277344",
        "longitude": "67.81700134277344",
        "altitude": "8367",
        "timezone": "4.5",
        "dst": "N",
        "tz_name": "Asia/Kabul"
    },
    "NBS": {
        "name": "Changbaishan Airport",
        "city": "Baishan",
        "country": "China",
        "iata": "NBS",
        "icao": "ZYBS",
        "latitude": "42.066944",
        "longitude": "127.602222",
        "altitude": "2874",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "RGO": {
        "name": "Orang Airport",
        "city": "Chongjin",
        "country": "North Korea",
        "iata": "RGO",
        "icao": "ZKHM",
        "latitude": "41.428538",
        "longitude": "129.647555",
        "altitude": "12",
        "timezone": "8.5",
        "dst": "U",
        "tz_name": "Asia/Pyongyang"
    },
    "MOO": {
        "name": "Moomba Airport",
        "city": "Moomba",
        "country": "Australia",
        "iata": "MOO",
        "icao": "YOOM",
        "latitude": "-28.09939956665039",
        "longitude": "140.19700622558594",
        "altitude": "143",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "LUZ": {
        "name": "Lublin Airport",
        "city": "Lublin",
        "country": "Poland",
        "iata": "LUZ",
        "icao": "EPLB",
        "latitude": "51.240278",
        "longitude": "22.713611",
        "altitude": "633",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Warsaw"
    },
    "ECA": {
        "name": "Iosco County Airport",
        "city": "East Tawas",
        "country": "United States",
        "iata": "ECA",
        "icao": "K6D9",
        "latitude": "44.312801",
        "longitude": "-83.422302",
        "altitude": "606",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "VAM": {
        "name": "Villa Airport",
        "city": "Maamigili",
        "country": "Maldives",
        "iata": "VAM",
        "icao": "VRMV",
        "latitude": "3.47055555556",
        "longitude": "72.8358333333",
        "altitude": "6",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "LLF": {
        "name": "Lingling Airport",
        "city": "Yongzhou",
        "country": "China",
        "iata": "LLF",
        "icao": "ZGLG",
        "latitude": "26.338661",
        "longitude": "111.610043",
        "altitude": "340",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "LSZ": {
        "name": "Lošinj Island Airport",
        "city": "Mali Losinj",
        "country": "Croatia",
        "iata": "LSZ",
        "icao": "LDLO",
        "latitude": "44.5657997131",
        "longitude": "14.3930997849",
        "altitude": "151",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Zagreb"
    },
    "ONS": {
        "name": "Onslow Airport",
        "city": "Onslow",
        "country": "Australia",
        "iata": "ONS",
        "icao": "YOLW",
        "latitude": "-21.668300628662",
        "longitude": "115.1129989624",
        "altitude": "7",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "TDR": {
        "name": "Theodore Airport",
        "city": "Theodore",
        "country": "Australia",
        "iata": "TDR",
        "icao": "YTDR",
        "latitude": "-24.99329948425293",
        "longitude": "150.09300231933594",
        "altitude": "171",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "WBU": {
        "name": "Boulder Municipal Airport",
        "city": "Boulder",
        "country": "United States",
        "iata": "WBU",
        "icao": "KBDU",
        "latitude": "40.0393981934",
        "longitude": "-105.225997925",
        "altitude": "5288",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "BBJ": {
        "name": "Bitburg Airport",
        "city": "Birburg",
        "country": "Germany",
        "iata": "BBJ",
        "icao": "EDRB",
        "latitude": "49.945278",
        "longitude": "6.565",
        "altitude": "1220",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Europe/Berlin"
    },
    "PAO": {
        "name": "Palo Alto Airport of Santa Clara County",
        "city": "Palo Alto",
        "country": "United States",
        "iata": "PAO",
        "icao": "KPAO",
        "latitude": "37.461101532",
        "longitude": "-122.114997864",
        "altitude": "4",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "USR": {
        "name": "Ust-Nera Airport",
        "city": "Ust-Nera",
        "country": "Russia",
        "iata": "USR",
        "icao": "UEMT",
        "latitude": "64.550003051758",
        "longitude": "143.11500549316",
        "altitude": "1805",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "MSC": {
        "name": "Falcon Field",
        "city": "Mesa",
        "country": "United States",
        "iata": "MSC",
        "icao": "KFFZ",
        "latitude": "33.4608001709",
        "longitude": "-111.727996826",
        "altitude": "1394",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Phoenix"
    },
    "YTY": {
        "name": "Yangzhou Taizhou Airport",
        "city": "Yangzhou",
        "country": "China",
        "iata": "YTY",
        "icao": "ZSYA",
        "latitude": "32.5634",
        "longitude": "119.7198",
        "altitude": "7",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "PTK": {
        "name": "Oakland County International Airport",
        "city": "Pontiac",
        "country": "United States",
        "iata": "PTK",
        "icao": "KPTK",
        "latitude": "42.665500640869",
        "longitude": "-83.420097351074",
        "altitude": "980",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "KSI": {
        "name": "Kissidougou Airport",
        "city": "Kissidougou",
        "country": "Guinea",
        "iata": "KSI",
        "icao": "GUKU",
        "latitude": "9.1605596542358",
        "longitude": "-10.124400138855",
        "altitude": "1808",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Conakry"
    },
    "EEN": {
        "name": "Dillant Hopkins Airport",
        "city": "Keene",
        "country": "United States",
        "iata": "EEN",
        "icao": "KEEN",
        "latitude": "42.898399353027344",
        "longitude": "-72.27079772949219",
        "altitude": "488",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "THQ": {
        "name": "Tianshui Maijishan Airport",
        "city": "Tianshui",
        "country": "China",
        "iata": "THQ",
        "icao": "ZLTS",
        "latitude": "34.5593986511",
        "longitude": "105.86000061",
        "altitude": "3590",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "VRO": {
        "name": "Kawama Airport",
        "city": "Kawama",
        "country": "Cuba",
        "iata": "VRO",
        "icao": "MUKW",
        "latitude": "23.1240005493",
        "longitude": "-81.3015975952",
        "altitude": "16",
        "timezone": "-5",
        "dst": "U",
        "tz_name": "America/Havana"
    },
    "GKK": {
        "name": "Kooddoo Airport",
        "city": "Kooddoo",
        "country": "Maldives",
        "iata": "GKK",
        "icao": "VRMO",
        "latitude": "0.7324",
        "longitude": "73.4336",
        "altitude": "29",
        "timezone": "5",
        "dst": "U",
        "tz_name": "Indian/Maldives"
    },
    "RCS": {
        "name": "Rochester Airport",
        "city": "Rochester",
        "country": "United Kingdom",
        "iata": "RCS",
        "icao": "EGTO",
        "latitude": "51.351898193359375",
        "longitude": "0.5033329725265503",
        "altitude": "436",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "RHD": {
        "name": "Termas de Río Hondo international Airport",
        "city": "Rio Hondo",
        "country": "Argentina",
        "iata": "RHD",
        "icao": "SANR",
        "latitude": "-27.4966",
        "longitude": "-64.93595",
        "altitude": "935",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Cordoba"
    },
    "KMP": {
        "name": "Keetmanshoop Airport",
        "city": "Keetmanshoop",
        "country": "Namibia",
        "iata": "KMP",
        "icao": "FYKT",
        "latitude": "-26.5398006439209",
        "longitude": "18.111400604248047",
        "altitude": "3506",
        "timezone": "1",
        "dst": "U",
        "tz_name": "Africa/Windhoek"
    },
    "KGT": {
        "name": "Kangding Airport",
        "city": "Kangding",
        "country": "China",
        "iata": "KGT",
        "icao": "ZUKD",
        "latitude": "30.1575",
        "longitude": "101.734722",
        "altitude": "14042",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "VUS": {
        "name": "Velikiy Ustyug Airport",
        "city": "Veliky Ustyug",
        "country": "Russia",
        "iata": "VUS",
        "icao": "ULWU",
        "latitude": "60.788299560546875",
        "longitude": "46.2599983215332",
        "altitude": "331",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "IOW": {
        "name": "Iowa City Municipal Airport",
        "city": "Iowa City",
        "country": "United States",
        "iata": "IOW",
        "icao": "KIOW",
        "latitude": "41.639198303200004",
        "longitude": "-91.5465011597",
        "altitude": "668",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TLQ": {
        "name": "Turpan Jiaohe Airport",
        "city": "Turpan",
        "country": "China",
        "iata": "TLQ",
        "icao": "ZWTP",
        "latitude": "43.0308",
        "longitude": "89.0987",
        "altitude": "934",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "ANP": {
        "name": "Lee Airport",
        "city": "Annapolis",
        "country": "United States",
        "iata": "ANP",
        "icao": "KANP",
        "latitude": "38.942902",
        "longitude": "-76.568398",
        "altitude": "34",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FXO": {
        "name": "Cuamba Airport",
        "city": "Cuamba",
        "country": "Mozambique",
        "iata": "FXO",
        "icao": "FQCB",
        "latitude": "-14.815",
        "longitude": "36.529999",
        "altitude": "1919",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Maputo"
    },
    "ODO": {
        "name": "Bodaybo Airport",
        "city": "Bodaibo",
        "country": "Russia",
        "iata": "ODO",
        "icao": "UIKB",
        "latitude": "57.866100311299995",
        "longitude": "114.242996216",
        "altitude": "919",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "ZTR": {
        "name": "Zhytomyr Airport",
        "city": "Zhytomyr",
        "country": "Ukraine",
        "iata": "ZTR",
        "icao": "UKKV",
        "latitude": "50.270556",
        "longitude": "28.738611",
        "altitude": "0",
        "timezone": "2",
        "dst": "E",
        "tz_name": "Europe/Kiev"
    },
    "HRI": {
        "name": "Mattala Rajapaksa International Airport",
        "city": "Mattala",
        "country": "Sri Lanka",
        "iata": "HRI",
        "icao": "VCRI",
        "latitude": "6.284467",
        "longitude": "81.124128",
        "altitude": "157",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Colombo"
    },
    "PEQ": {
        "name": "Pecos Municipal Airport",
        "city": "Pecos",
        "country": "United States",
        "iata": "PEQ",
        "icao": "KPEQ",
        "latitude": "31.382400512695",
        "longitude": "-103.51100158691",
        "altitude": "2613",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "HBG": {
        "name": "Hattiesburg Bobby L Chain Municipal Airport",
        "city": "Hattiesburg",
        "country": "United States",
        "iata": "HBG",
        "icao": "KHBG",
        "latitude": "31.26479912",
        "longitude": "-89.25279999",
        "altitude": "151",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "QCJ": {
        "name": "Botucatu - Tancredo de Almeida Neves Airport",
        "city": "Botucatu",
        "country": "Brazil",
        "iata": "QCJ",
        "icao": "SDBK",
        "latitude": "-22.939501",
        "longitude": "-48.467999",
        "altitude": "3012",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "QSC": {
        "name": "Mário Pereira Lopes–São Carlos Airport",
        "city": "Sao Carlos",
        "country": "Brazil",
        "iata": "QSC",
        "icao": "SDSC",
        "latitude": "-21.875401",
        "longitude": "-47.903703",
        "altitude": "2649",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "YKN": {
        "name": "Chan Gurney Municipal Airport",
        "city": "Yankton",
        "country": "United States",
        "iata": "YKN",
        "icao": "KYKN",
        "latitude": "42.916698455811",
        "longitude": "-97.385902404785",
        "altitude": "1306",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "XSB": {
        "name": "Sir Bani Yas Airport",
        "city": "Sir Bani Yas Island",
        "country": "United Arab Emirates",
        "iata": "XSB",
        "icao": "OMBY",
        "latitude": "24.283611",
        "longitude": "52.580278",
        "altitude": "25",
        "timezone": "4",
        "dst": "U",
        "tz_name": "Asia/Dubai"
    },
    "ZBM": {
        "name": "Bromont (Roland Desourdy) Airport",
        "city": "Bromont",
        "country": "Canada",
        "iata": "ZBM",
        "icao": "CZBM",
        "latitude": "45.2907981873",
        "longitude": "-72.74140167239999",
        "altitude": "375",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "KTI": {
        "name": "Kratie Airport",
        "city": "Kratie",
        "country": "Cambodia",
        "iata": "KTI",
        "icao": "VDKT",
        "latitude": "12.48799991607666",
        "longitude": "106.05500030517578",
        "altitude": "0",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Phnom_Penh"
    },
    "GYU": {
        "name": "Guyuan Liupanshan Airport",
        "city": "Guyuan",
        "country": "China",
        "iata": "GYU",
        "icao": "ZLGY",
        "latitude": "36.0788888889",
        "longitude": "106.216944444",
        "altitude": "5696",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "CNI": {
        "name": "Changhai Airport",
        "city": "Changhai",
        "country": "China",
        "iata": "CNI",
        "icao": "ZYCH",
        "latitude": "39.2666666667",
        "longitude": "122.666944444",
        "altitude": "80",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "KRH": {
        "name": "Redhill Aerodrome",
        "city": "Redhill",
        "country": "United Kingdom",
        "iata": "KRH",
        "icao": "EGKR",
        "latitude": "51.2136001587",
        "longitude": "-0.138611003757",
        "altitude": "222",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Europe/London"
    },
    "JGD": {
        "name": "Jiagedaqi Airport",
        "city": "Jiagedaqi District",
        "country": "China",
        "iata": "JGD",
        "icao": "ZYJD",
        "latitude": "50.371389",
        "longitude": "124.1175",
        "altitude": "1205",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "CCL": {
        "name": "Chinchilla Airport",
        "city": "Chinchilla",
        "country": "Australia",
        "iata": "CCL",
        "icao": "YCCA",
        "latitude": "-26.774999618530273",
        "longitude": "150.61700439453125",
        "altitude": "1028",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "HWD": {
        "name": "Hayward Executive Airport",
        "city": "Hayward",
        "country": "United States",
        "iata": "HWD",
        "icao": "KHWD",
        "latitude": "37.659198761",
        "longitude": "-122.122001648",
        "altitude": "52",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "MZP": {
        "name": "Motueka Airport",
        "city": "Motueka",
        "country": "New Zealand",
        "iata": "MZP",
        "icao": "NZMK",
        "latitude": "-41.12329864501953",
        "longitude": "172.98899841308594",
        "altitude": "39",
        "timezone": "12",
        "dst": "Z",
        "tz_name": "Pacific/Auckland"
    },
    "JHQ": {
        "name": "Shute Harbour Airport",
        "city": "Shute Harbour",
        "country": "Australia",
        "iata": "JHQ",
        "icao": "YSHR",
        "latitude": "-20.277221",
        "longitude": "148.755556",
        "altitude": "12",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "ARB": {
        "name": "Ann Arbor Municipal Airport",
        "city": "Ann Arbor",
        "country": "United States",
        "iata": "ARB",
        "icao": "KARB",
        "latitude": "42.2229995728",
        "longitude": "-83.74559783939999",
        "altitude": "839",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SHT": {
        "name": "Shepparton Airport",
        "city": "Shepparton",
        "country": "Australia",
        "iata": "SHT",
        "icao": "YSHT",
        "latitude": "-36.42890167236328",
        "longitude": "145.39300537109375",
        "altitude": "374",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "TEM": {
        "name": "Temora Airport",
        "city": "Temora",
        "country": "Australia",
        "iata": "TEM",
        "icao": "YTEM",
        "latitude": "-34.4213981628418",
        "longitude": "147.51199340820312",
        "altitude": "921",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "GAH": {
        "name": "Gayndah Airport",
        "city": "Gayndah",
        "country": "Australia",
        "iata": "GAH",
        "icao": "YGAY",
        "latitude": "-25.61440086364746",
        "longitude": "151.61900329589844",
        "altitude": "369",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "WIO": {
        "name": "Wilcannia Airport",
        "city": "Wilcannia",
        "country": "Australia",
        "iata": "WIO",
        "icao": "YWCA",
        "latitude": "-31.526399612426758",
        "longitude": "143.375",
        "altitude": "250",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "BFJ": {
        "name": "Bijie Feixiong Airport",
        "city": "Bijie",
        "country": "China",
        "iata": "BFJ",
        "icao": "ZUBJ",
        "latitude": "27.267066",
        "longitude": "105.472097",
        "altitude": "4751",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "ULK": {
        "name": "Lensk Airport",
        "city": "Lensk",
        "country": "Russia",
        "iata": "ULK",
        "icao": "UERL",
        "latitude": "60.7206001282",
        "longitude": "114.825996399",
        "altitude": "801",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "KVR": {
        "name": "Kavalerovo Airport",
        "city": "Kavalerovo",
        "country": "Russia",
        "iata": "KVR",
        "icao": "UHWK",
        "latitude": "44.2726",
        "longitude": "135.029",
        "altitude": "730",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Asia/Vladivostok"
    },
    "IGD": {
        "name": "Iğdır Airport",
        "city": "Igdir",
        "country": "Turkey",
        "iata": "IGD",
        "icao": "LTCT",
        "latitude": "39.9766273499",
        "longitude": "43.8766479492",
        "altitude": "3101",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "GNY": {
        "name": "Şanlıurfa GAP Airport",
        "city": "Sanliurfa",
        "country": "Turkey",
        "iata": "GNY",
        "icao": "LTCS",
        "latitude": "37.445663",
        "longitude": "38.895592",
        "altitude": "2708",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "KZR": {
        "name": "Zafer Airport",
        "city": "Kutahya",
        "country": "Turkey",
        "iata": "KZR",
        "icao": "LTBZ",
        "latitude": "39.113079",
        "longitude": "30.128111",
        "altitude": "3327",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "VLU": {
        "name": "Velikiye Luki Airport",
        "city": "Velikiye Luki",
        "country": "Russia",
        "iata": "VLU",
        "icao": "ULOL",
        "latitude": "56.381099700927734",
        "longitude": "30.60810089111328",
        "altitude": "328",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "BEO": {
        "name": "Lake Macquarie Airport",
        "city": "Lake Macquarie",
        "country": "Australia",
        "iata": "BEO",
        "icao": "YPEC",
        "latitude": "-33.0667",
        "longitude": "151.647995",
        "altitude": "2",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "BMP": {
        "name": "Brampton Island Airport",
        "city": "Brampton Island",
        "country": "Australia",
        "iata": "BMP",
        "icao": "YBPI",
        "latitude": "-20.803300857543945",
        "longitude": "149.27000427246094",
        "altitude": "11",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Brisbane"
    },
    "NGZ": {
        "name": "Alameda Naval Air Station",
        "city": "Alameda",
        "country": "United States",
        "iata": "NGZ",
        "icao": "KNGZ",
        "latitude": "37.78889846801758",
        "longitude": "-122.31999969482422",
        "altitude": "10",
        "timezone": "-8",
        "dst": "U",
        "tz_name": "America/Los_Angeles"
    },
    "YCN": {
        "name": "Cochrane Airport",
        "city": "Cochrane",
        "country": "Canada",
        "iata": "YCN",
        "icao": "CYCN",
        "latitude": "49.10559844970703",
        "longitude": "-81.01360321044922",
        "altitude": "861",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "BJP": {
        "name": "Estadual Arthur Siqueira Airport",
        "city": "Braganca Paulista",
        "country": "Brazil",
        "iata": "BJP",
        "icao": "SBBP",
        "latitude": "-22.979162",
        "longitude": "-46.537508",
        "altitude": "2887",
        "timezone": "-3",
        "dst": "S",
        "tz_name": "America/Sao_Paulo"
    },
    "BQB": {
        "name": "Busselton Regional Airport",
        "city": "Brusselton",
        "country": "Australia",
        "iata": "BQB",
        "icao": "YBLN",
        "latitude": "-33.6884231567",
        "longitude": "115.401596069",
        "altitude": "55",
        "timezone": "8",
        "dst": "O",
        "tz_name": "Australia/Perth"
    },
    "SEK": {
        "name": "Srednekolymsk Airport",
        "city": "Srednekolymsk",
        "country": "Russia",
        "iata": "SEK",
        "icao": "UESK",
        "latitude": "67.4805",
        "longitude": "153.7364",
        "altitude": "60",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "IVR": {
        "name": "Inverell Airport",
        "city": "Inverell",
        "country": "Australia",
        "iata": "IVR",
        "icao": "YIVL",
        "latitude": "-29.888299942",
        "longitude": "151.143997192",
        "altitude": "2667",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "GLI": {
        "name": "Glen Innes Airport",
        "city": "Glen Innes",
        "country": "Australia",
        "iata": "GLI",
        "icao": "YGLI",
        "latitude": "-29.674999237060547",
        "longitude": "151.68899536132812",
        "altitude": "3433",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "IMM": {
        "name": "Immokalee Regional Airport",
        "city": "Immokalee ",
        "country": "United States",
        "iata": "IMM",
        "icao": "KIMM",
        "latitude": "26.43320084",
        "longitude": "-81.40100098",
        "altitude": "37",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TQQ": {
        "name": "Maranggo Airport",
        "city": "Sulawesi Tenggara",
        "country": "Indonesia",
        "iata": "TQQ",
        "icao": "WA44",
        "latitude": "-5.7645702362061",
        "longitude": "123.91699981689",
        "altitude": "169",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Makassar"
    },
    "YIC": {
        "name": "Yichun Mingyueshan Airport",
        "city": "Yichun",
        "country": "China",
        "iata": "YIC",
        "icao": "ZSYC",
        "latitude": "27.8025",
        "longitude": "114.3062",
        "altitude": "430",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "PTB": {
        "name": "Dinwiddie County Airport",
        "city": "Petersburg",
        "country": "United States",
        "iata": "PTB",
        "icao": "KPTB",
        "latitude": "37.183799743652",
        "longitude": "-77.507400512695",
        "altitude": "193",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "SBM": {
        "name": "Sheboygan County Memorial Airport",
        "city": "Sheboygan",
        "country": "United States",
        "iata": "SBM",
        "icao": "KSBM",
        "latitude": "43.76959991",
        "longitude": "-87.85140228",
        "altitude": "755",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "KFE": {
        "name": "Fortescue - Dave Forrest Aerodrome",
        "city": "Cloudbreak",
        "country": "Australia",
        "iata": "KFE",
        "icao": "YFDF",
        "latitude": "-22.290754",
        "longitude": "119.437143",
        "altitude": "1555",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Australia/Perth"
    },
    "BJU": {
        "name": "Bajura Airport",
        "city": "Bajura",
        "country": "Nepal",
        "iata": "BJU",
        "icao": "VNBR",
        "latitude": "29.50200080871582",
        "longitude": "81.66899871826172",
        "altitude": "4300",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": "Asia/Katmandu"
    },
    "MZJ": {
        "name": "Pinal Airpark",
        "city": "Marana",
        "country": "United States",
        "iata": "MZJ",
        "icao": "KMZJ",
        "latitude": "32.5106010437",
        "longitude": "-111.32800293",
        "altitude": "1893",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "SAD": {
        "name": "Safford Regional Airport",
        "city": "Safford",
        "country": "United States",
        "iata": "SAD",
        "icao": "KSAD",
        "latitude": "32.85480118",
        "longitude": "-109.6350021",
        "altitude": "3179",
        "timezone": "-7",
        "dst": "N",
        "tz_name": "America/Phoenix"
    },
    "SLJ": {
        "name": "Solomon Airport",
        "city": "Solomon",
        "country": "Australia",
        "iata": "SLJ",
        "icao": "YSOL",
        "latitude": "-22.2554",
        "longitude": "117.7627",
        "altitude": "2030",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Australia/Perth"
    },
    "KJP": {
        "name": "Kerama Airport",
        "city": "Kerama",
        "country": "Japan",
        "iata": "KJP",
        "icao": "ROKR",
        "latitude": "26.168300628699996",
        "longitude": "127.292999268",
        "altitude": "156",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Tokyo"
    },
    "EKB": {
        "name": "Ekibastuz Airport",
        "city": "Ekibastuz",
        "country": "Kazakhstan",
        "iata": "EKB",
        "icao": "UASB",
        "latitude": "51.590999603271484",
        "longitude": "75.21499633789062",
        "altitude": "621",
        "timezone": "6",
        "dst": "N",
        "tz_name": "Asia/Qyzylorda"
    },
    "SIK": {
        "name": "Sikeston Memorial Municipal Airport",
        "city": "Sikeston",
        "country": "United States",
        "iata": "SIK",
        "icao": "KSIK",
        "latitude": "36.898899078369",
        "longitude": "-89.561798095703",
        "altitude": "315",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "TTI": {
        "name": "Tetiaroa Airport",
        "city": "Tetiaroa",
        "country": "French Polynesia",
        "iata": "TTI",
        "icao": "NTTE",
        "latitude": "-17.0132999420166",
        "longitude": "-149.58700561523438",
        "altitude": "7",
        "timezone": "-10",
        "dst": "N",
        "tz_name": "Pacific/Tahiti"
    },
    "GFL": {
        "name": "Floyd Bennett Memorial Airport",
        "city": "Queensbury",
        "country": "United States",
        "iata": "GFL",
        "icao": "KGFL",
        "latitude": "43.3412017822",
        "longitude": "-73.6102981567",
        "altitude": "328",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MTN": {
        "name": "Martin State Airport",
        "city": "Baltimore",
        "country": "United States",
        "iata": "MTN",
        "icao": "KMTN",
        "latitude": "39.325699",
        "longitude": "-76.413803",
        "altitude": "21",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "FRY": {
        "name": "Eastern Slopes Regional Airport",
        "city": "Fryeburg",
        "country": "United States",
        "iata": "FRY",
        "icao": "KIZG",
        "latitude": "43.991100311299995",
        "longitude": "-70.9478988647",
        "altitude": "454",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "NEW": {
        "name": "Lakefront Airport",
        "city": "New Orleans",
        "country": "United States",
        "iata": "NEW",
        "icao": "KNEW",
        "latitude": "30.042400360107",
        "longitude": "-90.028297424316",
        "altitude": "8",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "COE": {
        "name": "Coeur D'Alene - Pappy Boyington Field",
        "city": "Coeur d'Alene",
        "country": "United States",
        "iata": "COE",
        "icao": "KCOE",
        "latitude": "47.77429962",
        "longitude": "-116.8199997",
        "altitude": "2320",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "BMT": {
        "name": "Beaumont Municipal Airport",
        "city": "Beaumont",
        "country": "United States",
        "iata": "BMT",
        "icao": "KBMT",
        "latitude": "30.0706996918",
        "longitude": "-94.21579742430002",
        "altitude": "32",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DNV": {
        "name": "Vermilion Regional Airport",
        "city": "Danville",
        "country": "United States",
        "iata": "DNV",
        "icao": "KDNV",
        "latitude": "40.19919968",
        "longitude": "-87.59590149",
        "altitude": "697",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "COJ": {
        "name": "Coonabarabran Airport",
        "city": "Coonabarabran",
        "country": "Australia",
        "iata": "COJ",
        "icao": "YCBB",
        "latitude": "-31.332500457763672",
        "longitude": "149.26699829101562",
        "altitude": "2117",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Sydney"
    },
    "TIX": {
        "name": "Space Coast Regional Airport",
        "city": "Titusville",
        "country": "United States",
        "iata": "TIX",
        "icao": "KTIX",
        "latitude": "28.514799118042",
        "longitude": "-80.799201965332",
        "altitude": "34",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BZH": {
        "name": "Bumi Airport",
        "city": "Bumi Hills",
        "country": "Zimbabwe",
        "iata": "BZH",
        "icao": "FVBM",
        "latitude": "-16.8169994354",
        "longitude": "28.3500003815",
        "altitude": "1650",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Harare"
    },
    "UAR": {
        "name": "Bouarfa Airport",
        "city": "Bouarfa",
        "country": "Morocco",
        "iata": "UAR",
        "icao": "GMFB",
        "latitude": "32.5143055556",
        "longitude": "-1.98305555556",
        "altitude": "3630",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Casablanca"
    },
    "NYE": {
        "name": "Nyeri Airport",
        "city": "NYERI",
        "country": "Kenya",
        "iata": "NYE",
        "icao": "HKNI",
        "latitude": "-0.3644140064716339",
        "longitude": "36.978485107421875",
        "altitude": "5830",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Africa/Nairobi"
    },
    "AAP": {
        "name": "Andrau Airpark",
        "city": "Houston",
        "country": "United States",
        "iata": "AAP",
        "icao": "KAAP",
        "latitude": "29.722499847399998",
        "longitude": "-95.58830261230001",
        "altitude": "79",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "FCM": {
        "name": "Flying Cloud Airport",
        "city": "Eden Prairie",
        "country": "United States",
        "iata": "FCM",
        "icao": "KFCM",
        "latitude": "44.8272018433",
        "longitude": "-93.45709991460001",
        "altitude": "906",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "LIX": {
        "name": "Likoma Island Airport",
        "city": "Likoma Island",
        "country": "Malawi",
        "iata": "LIX",
        "icao": "FWLK",
        "latitude": "-12.075833",
        "longitude": "34.737222",
        "altitude": "1600",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Blantyre"
    },
    "OJC": {
        "name": "Johnson County Executive Airport",
        "city": "Olathe",
        "country": "United States",
        "iata": "OJC",
        "icao": "KOJC",
        "latitude": "38.84759903",
        "longitude": "-94.73760223",
        "altitude": "1096",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "GIU": {
        "name": "Sigiriya Air Force Base",
        "city": "Sigiriya",
        "country": "Sri Lanka",
        "iata": "GIU",
        "icao": "VCCS",
        "latitude": "7.956669807430001",
        "longitude": "80.7285003662",
        "altitude": "630",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": "Asia/Colombo"
    },
    "EUM": {
        "name": "Neumünster Airport",
        "city": "Neumuenster",
        "country": "Germany",
        "iata": "EUM",
        "icao": "EDHN",
        "latitude": "54.079444885253906",
        "longitude": "9.941389083862305",
        "altitude": "72",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Berlin"
    },
    "TKT": {
        "name": "Tak Airport",
        "city": "Tak",
        "country": "Thailand",
        "iata": "TKT",
        "icao": "VTPT",
        "latitude": "16.895999908447266",
        "longitude": "99.25330352783203",
        "altitude": "478",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Bangkok"
    },
    "YLK": {
        "name": "Barrie-Orillia (Lake Simcoe Regional Airport)",
        "city": "Barrie-Orillia",
        "country": "Canada",
        "iata": "YLK",
        "icao": "CYLS",
        "latitude": "44.4852981567",
        "longitude": "-79.55560302730001",
        "altitude": "972",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YCM": {
        "name": "Niagara District Airport",
        "city": "Saint Catherines",
        "country": "Canada",
        "iata": "YCM",
        "icao": "CYSN",
        "latitude": "43.19169998168945",
        "longitude": "-79.17169952392578",
        "altitude": "321",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "YPD": {
        "name": "Parry Sound Area Municipal Airport",
        "city": "Parry Sound",
        "country": "Canada",
        "iata": "YPD",
        "icao": "CNK4",
        "latitude": "45.2575",
        "longitude": "-79.829697",
        "altitude": "832",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "MNZ": {
        "name": "Manassas Regional Airport/Harry P. Davis Field",
        "city": "Manassas",
        "country": "United States",
        "iata": "MNZ",
        "icao": "KHEF",
        "latitude": "38.72140121",
        "longitude": "-77.51540375",
        "altitude": "192",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "LJN": {
        "name": "Texas Gulf Coast Regional Airport",
        "city": "Angleton",
        "country": "United States",
        "iata": "LJN",
        "icao": "KLBX",
        "latitude": "29.1086006165",
        "longitude": "-95.462097168",
        "altitude": "25",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BGG": {
        "name": "Bingöl Çeltiksuyu Airport",
        "city": "Bingol",
        "country": "Turkey",
        "iata": "BGG",
        "icao": "LTCU",
        "latitude": "38.8592605591",
        "longitude": "40.5959625244",
        "altitude": "3506",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "KFS": {
        "name": "Kastamonu Airport",
        "city": "Kastamonu",
        "country": "Turkey",
        "iata": "KFS",
        "icao": "LTAL",
        "latitude": "41.31420135498047",
        "longitude": "33.795799255371094",
        "altitude": "3520",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "LLV": {
        "name": "Lüliang Airport",
        "city": "Lvliang",
        "country": "China",
        "iata": "LLV",
        "icao": "ZBLL",
        "latitude": "37.683333",
        "longitude": "111.142778",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "DCY": {
        "name": "Daocheng Yading Airport",
        "city": "Daocheng",
        "country": "China",
        "iata": "DCY",
        "icao": "ZUDC",
        "latitude": "29.323056",
        "longitude": "100.053333",
        "altitude": "14472",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "GXH": {
        "name": "Gannan Xiahe Airport",
        "city": "Xiahe city",
        "country": "China",
        "iata": "GXH",
        "icao": "ZLXH",
        "latitude": "34.8105",
        "longitude": "102.6447",
        "altitude": "10510",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "CIY": {
        "name": "Comiso Airport",
        "city": "Comiso",
        "country": "Italy",
        "iata": "CIY",
        "icao": "LICB",
        "latitude": "36.994601",
        "longitude": "14.607182",
        "altitude": "623",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Rome"
    },
    "KVM": {
        "name": "Markovo Airport",
        "city": "Markovo",
        "country": "Russia",
        "iata": "KVM",
        "icao": "UHMO",
        "latitude": "64.667",
        "longitude": "170.417007",
        "altitude": "0",
        "timezone": "12",
        "dst": "N",
        "tz_name": "Asia/Anadyr"
    },
    "ZKP": {
        "name": "Zyryanka Airport",
        "city": "Zyryanka",
        "country": "Russia",
        "iata": "ZKP",
        "icao": "UESU",
        "latitude": "65.7485",
        "longitude": "150.8889",
        "altitude": "140",
        "timezone": "11",
        "dst": "N",
        "tz_name": "Asia/Srednekolymsk"
    },
    "UMS": {
        "name": "Ust-Maya Airport",
        "city": "Ust-Maya",
        "country": "Russia",
        "iata": "UMS",
        "icao": "UEMU",
        "latitude": "60.356998443604",
        "longitude": "134.43499755859",
        "altitude": "561",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "ADH": {
        "name": "Aldan Airport",
        "city": "Aldan",
        "country": "Russia",
        "iata": "ADH",
        "icao": "UEEA",
        "latitude": "58.60279846191406",
        "longitude": "125.40899658203125",
        "altitude": "2241",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "OLZ": {
        "name": "Olyokminsk Airport",
        "city": "Olekminsk",
        "country": "Russia",
        "iata": "OLZ",
        "icao": "UEMO",
        "latitude": "60.397499",
        "longitude": "120.471001",
        "altitude": "656",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "NLT": {
        "name": "Xinyuan Nalati Airport",
        "city": "Xinyuan",
        "country": "China",
        "iata": "NLT",
        "icao": "ZWNL",
        "latitude": "43.4318",
        "longitude": "83.3786",
        "altitude": "3050",
        "timezone": "8",
        "dst": "U",
        "tz_name": "Asia/Shanghai"
    },
    "PTA": {
        "name": "Port Alsworth Airport",
        "city": "Port alsworth",
        "country": "United States",
        "iata": "PTA",
        "icao": "PALJ",
        "latitude": "60.201681",
        "longitude": "-154.325863",
        "altitude": "280",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "BOR": {
        "name": "Fontaine Airport",
        "city": "Belfort",
        "country": "France",
        "iata": "BOR",
        "icao": "LFSQ",
        "latitude": "47.655601501465",
        "longitude": "7.0108299255371",
        "altitude": "1208",
        "timezone": "1",
        "dst": "E",
        "tz_name": "Europe/Paris"
    },
    "OBC": {
        "name": "Obock Airport",
        "city": "Obock",
        "country": "Djibouti",
        "iata": "OBC",
        "icao": "HDOB",
        "latitude": "11.967000007629395",
        "longitude": "43.266998291015625",
        "altitude": "69",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Africa/Djibouti"
    },
    "TDJ": {
        "name": "Tadjoura Airport",
        "city": "Tadjoura",
        "country": "Djibouti",
        "iata": "TDJ",
        "icao": "HDTJ",
        "latitude": "11.782999992370605",
        "longitude": "42.91699981689453",
        "altitude": "246",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Africa/Djibouti"
    },
    "AQB": {
        "name": "Santa Cruz del Quiche Airport",
        "city": "Santa Cruz des Quiche",
        "country": "Guatemala",
        "iata": "AQB",
        "icao": "MGQC",
        "latitude": "15.012200355529785",
        "longitude": "-91.15059661865234",
        "altitude": "6631",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Guatemala"
    },
    "NOR": {
        "name": "Norðfjörður Airport",
        "city": "Nordfjordur",
        "country": "Iceland",
        "iata": "NOR",
        "icao": "BINF",
        "latitude": "65.13189697265625",
        "longitude": "-13.746399879455566",
        "altitude": "13",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Atlantic/Reykjavik"
    },
    "BTZ": {
        "name": "Bursa Airport",
        "city": "Bursa",
        "country": "Turkey",
        "iata": "BTZ",
        "icao": "LTBE",
        "latitude": "40.233299255371094",
        "longitude": "29.009199142456055",
        "altitude": "331",
        "timezone": "3",
        "dst": "U",
        "tz_name": "Europe/Istanbul"
    },
    "WAR": {
        "name": "Waris Airport",
        "city": "Waris-Papua Island",
        "country": "Indonesia",
        "iata": "WAR",
        "icao": "WAJR",
        "latitude": "-3.235",
        "longitude": "140.994",
        "altitude": "1500",
        "timezone": "9",
        "dst": "U",
        "tz_name": "Asia/Jayapura"
    },
    "EWK": {
        "name": "Newton City-County Airport",
        "city": "Newton",
        "country": "United States",
        "iata": "EWK",
        "icao": "KEWK",
        "latitude": "38.058200836199994",
        "longitude": "-97.2744979858",
        "altitude": "1533",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "BSJ": {
        "name": "Bairnsdale Airport",
        "city": "Bairnsdale",
        "country": "Australia",
        "iata": "BSJ",
        "icao": "YBNS",
        "latitude": "-37.88750076293945",
        "longitude": "147.5679931640625",
        "altitude": "165",
        "timezone": "10",
        "dst": "O",
        "tz_name": "Australia/Hobart"
    },
    "TZR": {
        "name": "Taszár Air Base",
        "city": "Columbus",
        "country": "United States",
        "iata": "TZR",
        "icao": "LHTA",
        "latitude": "46.39310073852539",
        "longitude": "17.917499542236328",
        "altitude": "531",
        "timezone": "1",
        "dst": "A",
        "tz_name": "Europe/Budapest"
    },
    "FBR": {
        "name": "Fort Bridger Airport",
        "city": "Fort Bridger",
        "country": "United States",
        "iata": "FBR",
        "icao": "KFBR",
        "latitude": "41.3918991089",
        "longitude": "-110.406997681",
        "altitude": "7034",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "CLS": {
        "name": "Chehalis Centralia Airport",
        "city": "Chehalis",
        "country": "United States",
        "iata": "CLS",
        "icao": "KCLS",
        "latitude": "46.676998138399995",
        "longitude": "-122.983001709",
        "altitude": "176",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "EVW": {
        "name": "Evanston-Uinta County Airport-Burns Field",
        "city": "Evanston",
        "country": "United States",
        "iata": "EVW",
        "icao": "KEVW",
        "latitude": "41.27479935",
        "longitude": "-111.0350037",
        "altitude": "7143",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "EUF": {
        "name": "Weedon Field",
        "city": "Eufala",
        "country": "United States",
        "iata": "EUF",
        "icao": "KEUF",
        "latitude": "31.9512996674",
        "longitude": "-85.1288986206",
        "altitude": "285",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "MEO": {
        "name": "Dare County Regional Airport",
        "city": "Manteo",
        "country": "United States",
        "iata": "MEO",
        "icao": "KMQI",
        "latitude": "35.91899872",
        "longitude": "-75.69550323",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "AUO": {
        "name": "Auburn University Regional Airport",
        "city": "Auburn",
        "country": "United States",
        "iata": "AUO",
        "icao": "KAUO",
        "latitude": "32.615101",
        "longitude": "-85.433998",
        "altitude": "777",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DBN": {
        "name": "W H 'Bud' Barron Airport",
        "city": "Dublin",
        "country": "United States",
        "iata": "DBN",
        "icao": "KDBN",
        "latitude": "32.56439972",
        "longitude": "-82.98529816",
        "altitude": "309",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "PUK": {
        "name": "Pukarua Airport",
        "city": "Pukarua",
        "country": "French Polynesia",
        "iata": "PUK",
        "icao": "NTGQ",
        "latitude": "-18.29560089111328",
        "longitude": "-137.01699829101562",
        "altitude": "5",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "CVO": {
        "name": "Corvallis Municipal Airport",
        "city": "Corvallis",
        "country": "United States",
        "iata": "CVO",
        "icao": "KCVO",
        "latitude": "44.49720001",
        "longitude": "-123.2900009",
        "altitude": "250",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "PXH": {
        "name": "Prominent Hill Airport",
        "city": "Prominent Hill",
        "country": "Australia",
        "iata": "PXH",
        "icao": "YPMH",
        "latitude": "-29.716",
        "longitude": "135.5244",
        "altitude": "745",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": "Australia/Adelaide"
    },
    "CWT": {
        "name": "Cowra Airport",
        "city": "Chatsworth",
        "country": "Australia",
        "iata": "CWT",
        "icao": "YCWR",
        "latitude": "-33.84469985961914",
        "longitude": "148.6490020751953",
        "altitude": "966",
        "timezone": "10",
        "dst": "A",
        "tz_name": "Australia/Sydney"
    },
    "OGD": {
        "name": "Ogden Hinckley Airport",
        "city": "Ogden",
        "country": "United States",
        "iata": "OGD",
        "icao": "KOGD",
        "latitude": "41.195899963379",
        "longitude": "-112.0120010376",
        "altitude": "4473",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "AKO": {
        "name": "Colorado Plains Regional Airport",
        "city": "Akron",
        "country": "United States",
        "iata": "AKO",
        "icao": "KAKO",
        "latitude": "40.1755981445",
        "longitude": "-103.222000122",
        "altitude": "4714",
        "timezone": "-7",
        "dst": "A",
        "tz_name": "America/Denver"
    },
    "SHN": {
        "name": "Sanderson Field",
        "city": "Shelton",
        "country": "United States",
        "iata": "SHN",
        "icao": "KSHN",
        "latitude": "47.233600616455",
        "longitude": "-123.14800262451",
        "altitude": "273",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Los_Angeles"
    },
    "WNA": {
        "name": "Napakiak Airport",
        "city": "Napakiak",
        "country": "United States",
        "iata": "WNA",
        "icao": "PANA",
        "latitude": "60.69029998779297",
        "longitude": "-161.97900390625",
        "altitude": "17",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "PKA": {
        "name": "Napaskiak Airport",
        "city": "Napaskiak",
        "country": "United States",
        "iata": "PKA",
        "icao": "PAPK",
        "latitude": "60.70289993",
        "longitude": "-161.7779999",
        "altitude": "24",
        "timezone": "-9",
        "dst": "A",
        "tz_name": "America/Anchorage"
    },
    "YBW": {
        "name": "Bedwell Harbour Seaplane Base",
        "city": "Bedwell Harbour",
        "country": "Canada",
        "iata": "YBW",
        "icao": "CAB3",
        "latitude": "48.75",
        "longitude": "-123.233001709",
        "altitude": "0",
        "timezone": "-8",
        "dst": "A",
        "tz_name": "America/Vancouver"
    },
    "WSO": {
        "name": "Washabo Airport",
        "city": "Washabo",
        "country": "Suriname",
        "iata": "WSO",
        "icao": "SMWS",
        "latitude": "5.215277",
        "longitude": "-57.185302",
        "altitude": "68",
        "timezone": "-3",
        "dst": "N",
        "tz_name": "America/Paramaribo"
    },
    "WKR": {
        "name": "Abaco I Walker C Airport",
        "city": "Walker's Cay",
        "country": "Bahamas",
        "iata": "WKR",
        "icao": "MYAW",
        "latitude": "27.266700744628906",
        "longitude": "-78.39969635009766",
        "altitude": "10",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "GFO": {
        "name": "Bartica A Airport",
        "city": "Bartica",
        "country": "Guyana",
        "iata": "GFO",
        "icao": "SYBT",
        "latitude": "6.358864",
        "longitude": "-58.655207",
        "altitude": "3",
        "timezone": "-4",
        "dst": "N",
        "tz_name": "America/Guyana"
    },
    "DYL": {
        "name": "Doylestown Airport",
        "city": "Doylestown",
        "country": "United States",
        "iata": "DYL",
        "icao": "KDYL",
        "latitude": "40.3330001831",
        "longitude": "-75.1222991943",
        "altitude": "394",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "TGI": {
        "name": "Tingo Maria Airport",
        "city": "Tingo Maria",
        "country": "Peru",
        "iata": "TGI",
        "icao": "SPGM",
        "latitude": "-9.133000373840332",
        "longitude": "-75.94999694824219",
        "altitude": "2010",
        "timezone": "-5",
        "dst": "N",
        "tz_name": "America/Lima"
    },
    "TJL": {
        "name": "Plínio Alarcom Airport",
        "city": "Tres Lagoas",
        "country": "Brazil",
        "iata": "TJL",
        "icao": "SSTL",
        "latitude": "-20.754199981689",
        "longitude": "-51.684200286865",
        "altitude": "1050",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Campo_Grande"
    },
    "YZY": {
        "name": "Zhangye Ganzhou Airport",
        "city": "Zhangye",
        "country": "China",
        "iata": "YZY",
        "icao": "ZLZY",
        "latitude": "38.8018989563",
        "longitude": "100.675003052",
        "altitude": "5280",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "OAL": {
        "name": "Cacoal Airport",
        "city": "Cacoal",
        "country": "Brazil",
        "iata": "OAL",
        "icao": "SSKW",
        "latitude": "-11.496",
        "longitude": "-61.4508",
        "altitude": "778",
        "timezone": "-4",
        "dst": "N",
        "tz_name": "America/Boa_Vista"
    },
    "OCW": {
        "name": "Warren Field",
        "city": "Washington",
        "country": "United States",
        "iata": "OCW",
        "icao": "KOCW",
        "latitude": "35.570499420166",
        "longitude": "-77.049797058105",
        "altitude": "38",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "MHC": {
        "name": "Mocopulli Airport",
        "city": "Castro",
        "country": "Chile",
        "iata": "MHC",
        "icao": "SCPQ",
        "latitude": "-42.340388",
        "longitude": "-73.715693",
        "altitude": "528",
        "timezone": "-4",
        "dst": "S",
        "tz_name": "America/Santiago"
    },
    "SWO": {
        "name": "Stillwater Regional Airport",
        "city": "Stillwater",
        "country": "United States",
        "iata": "SWO",
        "icao": "KSWO",
        "latitude": "36.161201477051",
        "longitude": "-97.08570098877",
        "altitude": "1000",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "OKM": {
        "name": "Okmulgee Regional Airport",
        "city": "Okmulgee",
        "country": "United States",
        "iata": "OKM",
        "icao": "KOKM",
        "latitude": "35.668098449707",
        "longitude": "-95.948699951172",
        "altitude": "720",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CUH": {
        "name": "Cushing Municipal Airport",
        "city": "Cushing",
        "country": "United States",
        "iata": "CUH",
        "icao": "KCUH",
        "latitude": "35.9499015808",
        "longitude": "-96.7731018066",
        "altitude": "916",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "CSM": {
        "name": "Clinton Sherman Airport",
        "city": "Clinton",
        "country": "United States",
        "iata": "CSM",
        "icao": "KCSM",
        "latitude": "35.3398017883",
        "longitude": "-99.20050048830001",
        "altitude": "1922",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "WLD": {
        "name": "Strother Field",
        "city": "Winfield",
        "country": "United States",
        "iata": "WLD",
        "icao": "KWLD",
        "latitude": "37.168598175",
        "longitude": "-97.0375976562",
        "altitude": "1160",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "PWA": {
        "name": "Wiley Post Airport",
        "city": "Oklahoma City",
        "country": "United States",
        "iata": "PWA",
        "icao": "KPWA",
        "latitude": "35.53419876",
        "longitude": "-97.64710236",
        "altitude": "1300",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "DTN": {
        "name": "Shreveport Downtown Airport",
        "city": "Shreveport",
        "country": "United States",
        "iata": "DTN",
        "icao": "KDTN",
        "latitude": "32.5401992798",
        "longitude": "-93.7450027466",
        "altitude": "179",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "SEP": {
        "name": "Stephenville Clark Regional Airport",
        "city": "Stephenville",
        "country": "United States",
        "iata": "SEP",
        "icao": "KSEP",
        "latitude": "32.215301513672",
        "longitude": "-98.177696228027",
        "altitude": "1321",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "ADT": {
        "name": "Ada Regional Airport",
        "city": "Ada",
        "country": "United States",
        "iata": "ADT",
        "icao": "KADH",
        "latitude": "34.804298",
        "longitude": "-96.671303",
        "altitude": "1016",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "IRB": {
        "name": "Iraan Municipal Airport",
        "city": "Iraan",
        "country": "United States",
        "iata": "IRB",
        "icao": "K2F0",
        "latitude": "30.9057006836",
        "longitude": "-101.891998291",
        "altitude": "2200",
        "timezone": "-6",
        "dst": "A",
        "tz_name": "America/Chicago"
    },
    "YEL": {
        "name": "Elliot Lake Municipal Airport",
        "city": "ELLIOT LAKE",
        "country": "Canada",
        "iata": "YEL",
        "icao": "CYEL",
        "latitude": "46.351398468",
        "longitude": "-82.5614013672",
        "altitude": "1087",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/Toronto"
    },
    "IKB": {
        "name": "Wilkes County Airport",
        "city": "North Wilkesboro",
        "country": "United States",
        "iata": "IKB",
        "icao": "KUKF",
        "latitude": "36.2228012085",
        "longitude": "-81.09829711910001",
        "altitude": "1301",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "DAN": {
        "name": "Danville Regional Airport",
        "city": "Danville",
        "country": "United States",
        "iata": "DAN",
        "icao": "KDAN",
        "latitude": "36.572898864746094",
        "longitude": "-79.33609771728516",
        "altitude": "571",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "ERG": {
        "name": "Yerbogachen Airport",
        "city": "Yerbogachen",
        "country": "Russia",
        "iata": "ERG",
        "icao": "UIKE",
        "latitude": "61.2750015259",
        "longitude": "108.029998779",
        "altitude": "400",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Irkutsk"
    },
    "HCW": {
        "name": "Cheraw Municipal Airport/Lynch Bellinger Field",
        "city": "Cheraw",
        "country": "United States",
        "iata": "HCW",
        "icao": "KCQW",
        "latitude": "34.71289825",
        "longitude": "-79.95700073",
        "altitude": "239",
        "timezone": "-5",
        "dst": "A",
        "tz_name": "America/New_York"
    },
    "BEM": {
        "name": "Beni Mellal Airport",
        "city": "Beni Mellal",
        "country": "Morocco",
        "iata": "BEM",
        "icao": "GMMD",
        "latitude": "32.401895",
        "longitude": "-6.315905",
        "altitude": "1694",
        "timezone": "0",
        "dst": "E",
        "tz_name": "Africa/Casablanca"
    },
    "NKT": {
        "name": "Şırnak Şerafettin Elçi Airport",
        "city": "Cizre",
        "country": "Turkey",
        "iata": "NKT",
        "icao": "LTCV",
        "latitude": "37.3647",
        "longitude": "42.0582",
        "altitude": "2038",
        "timezone": "3",
        "dst": "E",
        "tz_name": "Europe/Istanbul"
    },
    "SUY": {
        "name": "Suntar Airport",
        "city": "Suntar",
        "country": "Russia",
        "iata": "SUY",
        "icao": "UENS",
        "latitude": "62.185001373291",
        "longitude": "117.63500213623",
        "altitude": "452",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Yakutsk"
    },
    "OUZ": {
        "name": "Tazadit Airport",
        "city": "Zouerat",
        "country": "Mauritania",
        "iata": "OUZ",
        "icao": "GQPZ",
        "latitude": "22.756399154663086",
        "longitude": "-12.483599662780762",
        "altitude": "1129",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Nouakchott"
    },
    "ABB": {
        "name": "Asaba International Airport",
        "city": "Asaba",
        "country": "Nigeria",
        "iata": "ABB",
        "icao": "DNAS",
        "latitude": "6.204167",
        "longitude": "6.665278",
        "altitude": "305",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "QUO": {
        "name": "Akwa Ibom International Airport",
        "city": "Uyo",
        "country": "Nigeria",
        "iata": "QUO",
        "icao": "DNAI",
        "latitude": "4.8725",
        "longitude": "8.093",
        "altitude": "170",
        "timezone": "1",
        "dst": "N",
        "tz_name": "Africa/Lagos"
    },
    "KAA": {
        "name": "Kasama Airport",
        "city": "Kasama",
        "country": "Zambia",
        "iata": "KAA",
        "icao": "FLKS",
        "latitude": "-10.216699600219727",
        "longitude": "31.13330078125",
        "altitude": "4541",
        "timezone": "2",
        "dst": "N",
        "tz_name": "Africa/Lusaka"
    },
    "SGX": {
        "name": "Songea Airport",
        "city": "Songea",
        "country": "Tanzania",
        "iata": "SGX",
        "icao": "HTSO",
        "latitude": "-10.682999610900879",
        "longitude": "35.58300018310547",
        "altitude": "3445",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Africa/Dar_es_Salaam"
    },
    "JUH": {
        "name": "Jiuhuashan Airport",
        "city": "Chizhou",
        "country": "China",
        "iata": "JUH",
        "icao": "ZSJH",
        "latitude": "30.7403",
        "longitude": "117.6856",
        "altitude": "60",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "AOG": {
        "name": "Anshan Air Base",
        "city": "Anshan",
        "country": "China",
        "iata": "AOG",
        "icao": "ZYAS",
        "latitude": "41.105301",
        "longitude": "122.853996",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "DQA": {
        "name": "Saertu Airport",
        "city": "Daqing",
        "country": "China",
        "iata": "DQA",
        "icao": "ZYDQ",
        "latitude": "46.7463888889",
        "longitude": "125.140555556",
        "altitude": "496",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "ZYI": {
        "name": "Zunyi Xinzhou Airport",
        "city": "Zunyi",
        "country": "China",
        "iata": "ZYI",
        "icao": "ZUZY",
        "latitude": "27.5895",
        "longitude": "107.0007",
        "altitude": "2920",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "LDS": {
        "name": "Lindu Airport",
        "city": "Yinchun",
        "country": "China",
        "iata": "LDS",
        "icao": "ZYLD",
        "latitude": "47.7520555556",
        "longitude": "129.019125",
        "altitude": "791",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "AVA": {
        "name": "Anshun Huangguoshu Airport",
        "city": "Anshun",
        "country": "China",
        "iata": "AVA",
        "icao": "ZUAS",
        "latitude": "26.2605555556",
        "longitude": "105.873333333",
        "altitude": "4812",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "KSS": {
        "name": "Sikasso Airport",
        "city": "Sikasso",
        "country": "Mali",
        "iata": "KSS",
        "icao": "GASK",
        "latitude": "11.333000183105469",
        "longitude": "-5.699999809265137",
        "altitude": "1378",
        "timezone": "0",
        "dst": "N",
        "tz_name": "Africa/Bamako"
    },
    "WTB": {
        "name": "Toowoomba Wellcamp Airport",
        "city": "Toowoomba",
        "country": "Australia",
        "iata": "WTB",
        "icao": "YBWW",
        "latitude": "-27.558332",
        "longitude": "151.793335",
        "altitude": "1509",
        "timezone": "10",
        "dst": "N",
        "tz_name": "Australia/Brisbane"
    },
    "TNH": {
        "name": "Tonghua Sanyuanpu Airport",
        "city": "Tonghua",
        "country": "China",
        "iata": "TNH",
        "icao": "ZYTN",
        "latitude": "42.2538888889",
        "longitude": "125.703333333",
        "altitude": "1200",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "SZV": {
        "name": "Suzhou Guangfu Airport",
        "city": "Suzhou",
        "country": "China",
        "iata": "SZV",
        "icao": "ZSSZ",
        "latitude": "31.2631",
        "longitude": "120.401001",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": "Asia/Shanghai"
    },
    "LII": {
        "name": "Mulia Airport",
        "city": "Mulia",
        "country": "Indonesia",
        "iata": "LII",
        "icao": "WAJM",
        "latitude": "-3.7018",
        "longitude": "137.957",
        "altitude": "6527",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "NTI": {
        "name": "Stenkol Airport",
        "city": "Bintuni",
        "country": "Indonesia",
        "iata": "NTI",
        "icao": "WASB",
        "latitude": "-2.1033",
        "longitude": "133.5164",
        "altitude": "57",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "WSR": {
        "name": "Wasior Airport",
        "city": "Wasior",
        "country": "Indonesia",
        "iata": "WSR",
        "icao": "WASW",
        "latitude": "-2.721",
        "longitude": "134.5061",
        "altitude": "49",
        "timezone": "9",
        "dst": "N",
        "tz_name": "Asia/Jayapura"
    },
    "DTB": {
        "name": "Silangit Airport",
        "city": "Siborong-Borong",
        "country": "Indonesia",
        "iata": "DTB",
        "icao": "WIMN",
        "latitude": "2.25973",
        "longitude": "98.991898",
        "altitude": "4700",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "MEQ": {
        "name": "Seunagan Airport",
        "city": "Nagan Raya",
        "country": "Indonesia",
        "iata": "MEQ",
        "icao": "WITC",
        "latitude": "4.25",
        "longitude": "96.21700286865234",
        "altitude": "10",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "BUW": {
        "name": "Betoambari Airport",
        "city": "Bau-Bau",
        "country": "Indonesia",
        "iata": "BUW",
        "icao": "WAWB",
        "latitude": "-5.486879825592041",
        "longitude": "122.56900024414062",
        "altitude": "164",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "KAZ": {
        "name": "Kao Airport",
        "city": "Kao",
        "country": "Indonesia",
        "iata": "KAZ",
        "icao": "WAMK",
        "latitude": "1.1852799654006958",
        "longitude": "127.89600372314453",
        "altitude": "27",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "MNA": {
        "name": "Melangguane Airport",
        "city": "Melonguane",
        "country": "Indonesia",
        "iata": "MNA",
        "icao": "WAMN",
        "latitude": "4.00694",
        "longitude": "126.672997",
        "altitude": "3",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "SGQ": {
        "name": "Sanggata/Sangkimah Airport",
        "city": "Sanggata",
        "country": "Indonesia",
        "iata": "SGQ",
        "icao": "WRLA",
        "latitude": "0.3847",
        "longitude": "117.543",
        "altitude": "60",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "BUU": {
        "name": "Muara Bungo Airport",
        "city": "Muara Bungo",
        "country": "Indonesia",
        "iata": "BUU",
        "icao": "WIPI",
        "latitude": "-1.1278",
        "longitude": "102.135",
        "altitude": "214",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "ILA": {
        "name": "Illaga Airport",
        "city": "Illaga",
        "country": "Indonesia",
        "iata": "ILA",
        "icao": "WABL",
        "latitude": "-3.97648",
        "longitude": "137.6225",
        "altitude": "7989",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "OKL": {
        "name": "Oksibil Airport",
        "city": "Oksibil",
        "country": "Indonesia",
        "iata": "OKL",
        "icao": "WAJO",
        "latitude": "-4.9071",
        "longitude": "140.6277",
        "altitude": "4315",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "KOX": {
        "name": "Kokonau Airport",
        "city": "Kokonau",
        "country": "Indonesia",
        "iata": "KOX",
        "icao": "WABN",
        "latitude": "-4.71075",
        "longitude": "136.43515",
        "altitude": "59",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "CMQ": {
        "name": "Clermont Airport",
        "city": "Clermont",
        "country": "Australia",
        "iata": "CMQ",
        "icao": "YCMT",
        "latitude": "-22.773099899291992",
        "longitude": "147.62100219726562",
        "altitude": "908",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "WMB": {
        "name": "Warrnambool Airport",
        "city": "Warrnambool",
        "country": "Australia",
        "iata": "WMB",
        "icao": "YWBL",
        "latitude": "-38.2952995300293",
        "longitude": "142.44700622558594",
        "altitude": "242",
        "timezone": "10",
        "dst": "O",
        "tz_name": null
    },
    "RCM": {
        "name": "Richmond Airport",
        "city": "Richmond",
        "country": "Australia",
        "iata": "RCM",
        "icao": "YRMD",
        "latitude": "-20.701900482177734",
        "longitude": "143.11500549316406",
        "altitude": "676",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "DCN": {
        "name": "RAAF Base Curtin",
        "city": "Derby",
        "country": "Australia",
        "iata": "DCN",
        "icao": "YCIN",
        "latitude": "-17.5813999176",
        "longitude": "123.82800293",
        "altitude": "300",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "KNO": {
        "name": "Kualanamu International Airport",
        "city": "Medan",
        "country": "Indonesia",
        "iata": "KNO",
        "icao": "WIMM",
        "latitude": "3.642222",
        "longitude": "98.885278",
        "altitude": "23",
        "timezone": "7",
        "dst": "N",
        "tz_name": "Asia/Jakarta"
    },
    "AMN": {
        "name": "Gratiot Community Airport",
        "city": "Kamloops",
        "country": "Canada",
        "iata": "AMN",
        "icao": "KAMN",
        "latitude": "43.322101593",
        "longitude": "-84.68800354",
        "altitude": "754",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "HMY": {
        "name": "Seosan Air Base",
        "city": "Seosan",
        "country": "South Korea",
        "iata": "HMY",
        "icao": "RKTP",
        "latitude": "36.703999",
        "longitude": "126.486",
        "altitude": "39",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "EMT": {
        "name": "San Gabriel Valley Airport",
        "city": "El Monte",
        "country": "United States",
        "iata": "EMT",
        "icao": "KEMT",
        "latitude": "34.086102",
        "longitude": "-118.035004",
        "altitude": "296",
        "timezone": "-8",
        "dst": "E",
        "tz_name": null
    },
    "FAH": {
        "name": "Farah Airport",
        "city": "Farah",
        "country": "Afghanistan",
        "iata": "FAH",
        "icao": "OAFR",
        "latitude": "32.367000579833984",
        "longitude": "62.18299865722656",
        "altitude": "3083",
        "timezone": "4.3",
        "dst": "N",
        "tz_name": null
    },
    "IXT": {
        "name": "Pasighat Airport",
        "city": "Pasighat",
        "country": "India",
        "iata": "IXT",
        "icao": "VEPG",
        "latitude": "28.066099166870117",
        "longitude": "95.33560180664062",
        "altitude": "477",
        "timezone": "5.3",
        "dst": "N",
        "tz_name": null
    },
    "KRQ": {
        "name": "Kramatorsk Airport",
        "city": "Kramatorsk",
        "country": "Ukraine",
        "iata": "KRQ",
        "icao": "UKCK",
        "latitude": "48.70560073852539",
        "longitude": "37.62889862060547",
        "altitude": "646",
        "timezone": "2",
        "dst": "E",
        "tz_name": null
    },
    "QKX": {
        "name": "Kautokeino Air Base",
        "city": "Kautokeino",
        "country": "Norway",
        "iata": "QKX",
        "icao": "ENKA",
        "latitude": "69.04029846191406",
        "longitude": "23.034000396728516",
        "altitude": "1165",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "SSF": {
        "name": "Stinson Municipal Airport",
        "city": "Stinson",
        "country": "United States",
        "iata": "SSF",
        "icao": "KSSF",
        "latitude": "29.336999893188",
        "longitude": "-98.471099853516",
        "altitude": "577",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "JAS": {
        "name": "Jasper County Airport-Bell Field",
        "city": "Jasper",
        "country": "United States",
        "iata": "JAS",
        "icao": "KJAS",
        "latitude": "30.88570023",
        "longitude": "-94.03489685",
        "altitude": "213",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "MRF": {
        "name": "Marfa Municipal Airport",
        "city": "Marfa",
        "country": "United States",
        "iata": "MRF",
        "icao": "KMRF",
        "latitude": "30.371099",
        "longitude": "-104.017997",
        "altitude": "4849",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "ALE": {
        "name": "Alpine Casparis Municipal Airport",
        "city": "Alpine",
        "country": "United States",
        "iata": "ALE",
        "icao": "KE38",
        "latitude": "30.384199142499998",
        "longitude": "-103.683998108",
        "altitude": "4515",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "BQE": {
        "name": "Bubaque Airport",
        "city": "Bubaque",
        "country": "Guinea-Bissau",
        "iata": "BQE",
        "icao": "GGBU",
        "latitude": "11.297355651855469",
        "longitude": "-15.838079452514648",
        "altitude": "0",
        "timezone": "0",
        "dst": "N",
        "tz_name": null
    },
    "CZA": {
        "name": "Chichen Itza International Airport",
        "city": "Chichen Itza",
        "country": "Mexico",
        "iata": "CZA",
        "icao": "MMCT",
        "latitude": "20.6413002014",
        "longitude": "-88.4461975098",
        "altitude": "102",
        "timezone": "-6",
        "dst": "S",
        "tz_name": "America/Mexico_City"
    },
    "BUY": {
        "name": "Bunbury Airport",
        "city": "Bunbury",
        "country": "Australia",
        "iata": "BUY",
        "icao": "YBUN",
        "latitude": "-33.378299713134766",
        "longitude": "115.677001953125",
        "altitude": "53",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "CCB": {
        "name": "Cable Airport",
        "city": "Upland",
        "country": "United States",
        "iata": "CCB",
        "icao": "KCCB",
        "latitude": "34.1115989685",
        "longitude": "-117.68800354",
        "altitude": "1444",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "EKI": {
        "name": "Elkhart Municipal Airport",
        "city": "Elkhart",
        "country": "United States",
        "iata": "EKI",
        "icao": "KEKM",
        "latitude": "41.7193984985",
        "longitude": "-86.00319671630001",
        "altitude": "778",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "CUB": {
        "name": "Jim Hamilton L.B. Owens Airport",
        "city": "Columbia",
        "country": "United States",
        "iata": "CUB",
        "icao": "KCUB",
        "latitude": "33.970500946",
        "longitude": "-80.9952011108",
        "altitude": "193",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "GDC": {
        "name": "Donaldson Field Airport",
        "city": "Greenville",
        "country": "United States",
        "iata": "GDC",
        "icao": "KGYH",
        "latitude": "34.758301",
        "longitude": "-82.376404",
        "altitude": "955",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "HVS": {
        "name": "Hartsville Regional Airport",
        "city": "Hartsville",
        "country": "United States",
        "iata": "HVS",
        "icao": "KHVS",
        "latitude": "34.4030990601",
        "longitude": "-80.11920166019999",
        "altitude": "364",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "SZT": {
        "name": "San Cristobal de las Casas Airport",
        "city": "San Cristobal de las Casas",
        "country": "Mexico",
        "iata": "SZT",
        "icao": "MMSC",
        "latitude": "16.690299987793",
        "longitude": "-92.530097961426",
        "altitude": "7707",
        "timezone": "-6",
        "dst": "N",
        "tz_name": "America/Mexico_City"
    },
    "DU9": {
        "name": "Dunnville Airport",
        "city": "Dunnville",
        "country": "Canada",
        "iata": "DU9",
        "icao": "CDU9",
        "latitude": "42.872200012200004",
        "longitude": "-79.5958023071",
        "altitude": "600",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "RIH": {
        "name": "Scarlett Martinez International Airport",
        "city": "Rio Hato",
        "country": "Panama",
        "iata": "RIH",
        "icao": "MPRH",
        "latitude": "8.375880241394",
        "longitude": "-80.127899169922",
        "altitude": "105",
        "timezone": "-5",
        "dst": "U",
        "tz_name": null
    },
    "LEE": {
        "name": "Leesburg International Airport",
        "city": "Leesburg",
        "country": "United States",
        "iata": "LEE",
        "icao": "KLEE",
        "latitude": "28.82309914",
        "longitude": "-81.80870056",
        "altitude": "76",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "PPY": {
        "name": "Pouso Alegre Airport",
        "city": "Pouso Alegre",
        "country": "Brazil",
        "iata": "PPY",
        "icao": "SNZA",
        "latitude": "-22.289199829101562",
        "longitude": "-45.91910171508789",
        "altitude": "2904",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "DIQ": {
        "name": "Brigadeiro Cabral Airport",
        "city": "Divinopolis",
        "country": "Brazil",
        "iata": "DIQ",
        "icao": "SNDV",
        "latitude": "-20.180700302124",
        "longitude": "-44.870899200439",
        "altitude": "2608",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "EIK": {
        "name": "Yeysk Airport",
        "city": "Eysk",
        "country": "Russia",
        "iata": "EIK",
        "icao": "URKE",
        "latitude": "46.68",
        "longitude": "38.21",
        "altitude": "60",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ERD": {
        "name": "Berdyansk Airport",
        "city": "Berdyansk",
        "country": "Ukraine",
        "iata": "ERD",
        "icao": "UKDB",
        "latitude": "46.814998626708984",
        "longitude": "36.75809860229492",
        "altitude": "171",
        "timezone": "2",
        "dst": "E",
        "tz_name": null
    },
    "BWX": {
        "name": "Blimbingsari Airport",
        "city": "Banyuwangi",
        "country": "Indonesia",
        "iata": "BWX",
        "icao": "WARB",
        "latitude": "-8.31015",
        "longitude": "114.3401",
        "altitude": "112",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "ERL": {
        "name": "Erenhot Saiwusu International Airport",
        "city": "Erenhot",
        "country": "China",
        "iata": "ERL",
        "icao": "ZBER",
        "latitude": "43.4225",
        "longitude": "112.096667",
        "altitude": "3301",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "CNO": {
        "name": "Chino Airport",
        "city": "Chino",
        "country": "United States",
        "iata": "CNO",
        "icao": "KCNO",
        "latitude": "33.97470093",
        "longitude": "-117.637001",
        "altitude": "650",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "HTR": {
        "name": "Hateruma Airport",
        "city": "Taketomi",
        "country": "Japan",
        "iata": "HTR",
        "icao": "RORH",
        "latitude": "24.0589008331",
        "longitude": "123.805999756",
        "altitude": "43",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "BWW": {
        "name": "Las Brujas Airport",
        "city": "Cayo Santa Maria",
        "country": "Cuba",
        "iata": "BWW",
        "icao": "MUBR",
        "latitude": "22.621299743699996",
        "longitude": "-79.1472015381",
        "altitude": "13",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "PRB": {
        "name": "Paso Robles Municipal Airport",
        "city": "Paso Robles",
        "country": "United States",
        "iata": "PRB",
        "icao": "KPRB",
        "latitude": "35.67290115",
        "longitude": "-120.6269989",
        "altitude": "840",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "PKX": {
        "name": "Beijing Daxing International Airport",
        "city": "Beijing",
        "country": "China",
        "iata": "PKX",
        "icao": "ZBAD",
        "latitude": "39.509945",
        "longitude": "116.41092",
        "altitude": "98",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "HAF": {
        "name": "Half Moon Bay Airport",
        "city": "Half Moon Bay",
        "country": "United States",
        "iata": "HAF",
        "icao": "KHAF",
        "latitude": "37.513401031499995",
        "longitude": "-122.500999451",
        "altitude": "66",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "HCJ": {
        "name": "Hechi Jinchengjiang Airport",
        "city": "Hechi",
        "country": "China",
        "iata": "HCJ",
        "icao": "ZGHC",
        "latitude": "24.805",
        "longitude": "107.6997",
        "altitude": "2221",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "WJF": {
        "name": "General WM J Fox Airfield",
        "city": "Lancaster",
        "country": "United States",
        "iata": "WJF",
        "icao": "KWJF",
        "latitude": "34.74110031",
        "longitude": "-118.2190018",
        "altitude": "2351",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "CJF": {
        "name": "Coondewanna Airport",
        "city": "Coondewanna",
        "country": "Australia",
        "iata": "CJF",
        "icao": "YCWA",
        "latitude": "-22.96669960022",
        "longitude": "118.81300354004",
        "altitude": "2300",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "GUZ": {
        "name": "Guarapari Airport",
        "city": "Guarapari",
        "country": "Brazil",
        "iata": "GUZ",
        "icao": "SNGA",
        "latitude": "-20.646499633800005",
        "longitude": "-40.491901397700005",
        "altitude": "28",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "UBT": {
        "name": "Ubatuba Airport",
        "city": "Ubatuba",
        "country": "Brazil",
        "iata": "UBT",
        "icao": "SDUB",
        "latitude": "-23.441099166870117",
        "longitude": "-45.075599670410156",
        "altitude": "13",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "BOX": {
        "name": "Borroloola Airport",
        "city": "Borroloola",
        "country": "Australia",
        "iata": "BOX",
        "icao": "YBRL",
        "latitude": "-16.075300216674805",
        "longitude": "136.302001953125",
        "altitude": "55",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": null
    },
    "QUG": {
        "name": "Chichester/Goodwood Airport",
        "city": "Goodwood",
        "country": "United Kingdom",
        "iata": "QUG",
        "icao": "EGHR",
        "latitude": "50.85940170288086",
        "longitude": "-0.7591670155525208",
        "altitude": "98",
        "timezone": "0",
        "dst": "E",
        "tz_name": null
    },
    "TNW": {
        "name": "Jumandy Airport",
        "city": "Tena",
        "country": "Ecuador",
        "iata": "TNW",
        "icao": "SEJD",
        "latitude": "-1.059722",
        "longitude": "-77.583333",
        "altitude": "1234",
        "timezone": "-5",
        "dst": "S",
        "tz_name": null
    },
    "FYJ": {
        "name": "Dongji Aiport",
        "city": "Fuyuan",
        "country": "China",
        "iata": "FYJ",
        "icao": "ZYFY",
        "latitude": "48.199494",
        "longitude": "134.366447",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "PZL": {
        "name": "Zulu Inyala Airport",
        "city": "Phinda",
        "country": "South Africa",
        "iata": "PZL",
        "icao": "FADQ",
        "latitude": "-27.84939956665039",
        "longitude": "32.30970001220703",
        "altitude": "160",
        "timezone": "2",
        "dst": "U",
        "tz_name": null
    },
    "LPF": {
        "name": "Liupanshui Yuezhao Airport",
        "city": "Liupanshui",
        "country": "China",
        "iata": "LPF",
        "icao": "ZUPS",
        "latitude": "26.609417",
        "longitude": "104.979",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "KJH": {
        "name": "Kaili Airport",
        "city": "Kaili",
        "country": "China",
        "iata": "KJH",
        "icao": "ZUKJ",
        "latitude": "26.972",
        "longitude": "107.988",
        "altitude": "3115",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "HPG": {
        "name": "Shennongjia Hongping Airport",
        "city": "Shennongjia",
        "country": "China",
        "iata": "HPG",
        "icao": "ZHSN",
        "latitude": "31.626",
        "longitude": "110.34",
        "altitude": "8365",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "ZQZ": {
        "name": "Zhangjiakou Ningyuan Airport",
        "city": "Zhangjiakou",
        "country": "China",
        "iata": "ZQZ",
        "icao": "ZBZJ",
        "latitude": "40.7386016846",
        "longitude": "114.930000305",
        "altitude": "2347",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "YIE": {
        "name": "Arxan Yi'ershi Airport",
        "city": "Arxan",
        "country": "China",
        "iata": "YIE",
        "icao": "ZBES",
        "latitude": "47.3106",
        "longitude": "119.9117",
        "altitude": "2925",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "HNY": {
        "name": "Hengyang Nanyue Airport",
        "city": "Hengyang",
        "country": "China",
        "iata": "HNY",
        "icao": "ZGHY",
        "latitude": "26.9053",
        "longitude": "112.627998",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "AHJ": {
        "name": "Hongyuan Airport",
        "city": "Ngawa",
        "country": "China",
        "iata": "AHJ",
        "icao": "ZUHY",
        "latitude": "32.53154",
        "longitude": "102.35224",
        "altitude": "11600",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "WOS": {
        "name": "Wonsan Kalma International Airport",
        "city": "Wonsan",
        "country": "North Korea",
        "iata": "WOS",
        "icao": "ZKWS",
        "latitude": "39.166801",
        "longitude": "127.486",
        "altitude": "7",
        "timezone": "8.5",
        "dst": "N",
        "tz_name": null
    },
    "IGT": {
        "name": "Magas Airport",
        "city": "Magas",
        "country": "Russia",
        "iata": "IGT",
        "icao": "URMS",
        "latitude": "43.322299957300004",
        "longitude": "45.0125999451",
        "altitude": "1165",
        "timezone": "3",
        "dst": "N",
        "tz_name": "Europe/Moscow"
    },
    "ASN": {
        "name": "Talladega Municipal Airport",
        "city": "Talladega",
        "country": "United States",
        "iata": "ASN",
        "icao": "KASN",
        "latitude": "33.569900512699995",
        "longitude": "-86.05090332030001",
        "altitude": "529",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "GMU": {
        "name": "Greenville Downtown Airport",
        "city": "Greenville",
        "country": "United States",
        "iata": "GMU",
        "icao": "KGMU",
        "latitude": "34.847900390599996",
        "longitude": "-82.34999847410002",
        "altitude": "1048",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "NGD": {
        "name": "Captain Auguste George Airport",
        "city": "Anegada",
        "country": "British Virgin Islands",
        "iata": "NGD",
        "icao": "TUPA",
        "latitude": "18.72719955444336",
        "longitude": "-64.32969665527344",
        "altitude": "9",
        "timezone": "-4",
        "dst": "U",
        "tz_name": null
    },
    "TOI": {
        "name": "Troy Municipal Airport at N Kenneth Campbell Field",
        "city": "Troy",
        "country": "United States",
        "iata": "TOI",
        "icao": "KTOI",
        "latitude": "31.860399",
        "longitude": "-86.012101",
        "altitude": "398",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "ETS": {
        "name": "Enterprise Municipal Airport",
        "city": "Enterprise",
        "country": "United States",
        "iata": "ETS",
        "icao": "KEDN",
        "latitude": "31.29969978",
        "longitude": "-85.89990234",
        "altitude": "361",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "EFG": {
        "name": "Efogi Airport",
        "city": "Efogi",
        "country": "Papua New Guinea",
        "iata": "EFG",
        "icao": "AYEF",
        "latitude": "-9.15380555556",
        "longitude": "147.659805556",
        "altitude": "3900",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "KGW": {
        "name": "Kagi Airport",
        "city": "Kagi",
        "country": "Papua New Guinea",
        "iata": "KGW",
        "icao": "AYKQ",
        "latitude": "-9.135916666670001",
        "longitude": "147.669444444",
        "altitude": "4200",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "NDN": {
        "name": "Nadunumu Airport",
        "city": "Nadunumu",
        "country": "Papua New Guinea",
        "iata": "NDN",
        "icao": "AYNC",
        "latitude": "-9.14355555556",
        "longitude": "147.68425",
        "altitude": "5100",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "BNM": {
        "name": "Bodinumu Airport",
        "city": "Bodinumu",
        "country": "Papua New Guinea",
        "iata": "BNM",
        "icao": "AYBD",
        "latitude": "-9.107777777779999",
        "longitude": "147.666722222",
        "altitude": "3700",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "ALX": {
        "name": "Thomas C Russell Field",
        "city": "Alexander City",
        "country": "United States",
        "iata": "ALX",
        "icao": "KALX",
        "latitude": "32.914699554399995",
        "longitude": "-85.9629974365",
        "altitude": "686",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "PKT": {
        "name": "Port Keats Airport",
        "city": "Wadeye",
        "country": "Australia",
        "iata": "PKT",
        "icao": "YPKT",
        "latitude": "-14.25",
        "longitude": "129.5290069580078",
        "altitude": "91",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": null
    },
    "GPN": {
        "name": "Garden Point Airport",
        "city": "Pirlangimpi",
        "country": "Australia",
        "iata": "GPN",
        "icao": "YGPT",
        "latitude": "-11.40250015258789",
        "longitude": "130.4219970703125",
        "altitude": "90",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": null
    },
    "HZP": {
        "name": "Fort Mackay / Horizon Airport",
        "city": "Wood Buffalo",
        "country": "Canada",
        "iata": "HZP",
        "icao": "CYNR",
        "latitude": "57.3816986084",
        "longitude": "-111.700996399",
        "altitude": "916",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "HDE": {
        "name": "Brewster Field",
        "city": "Holdredge",
        "country": "United States",
        "iata": "HDE",
        "icao": "KHDE",
        "latitude": "40.452099",
        "longitude": "-99.336502",
        "altitude": "2313",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "PTT": {
        "name": "Pratt Regional Airport",
        "city": "Pratt",
        "country": "United States",
        "iata": "PTT",
        "icao": "KPTT",
        "latitude": "37.70159912",
        "longitude": "-98.74690247",
        "altitude": "1953",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "LXN": {
        "name": "Jim Kelly Field",
        "city": "Lexington",
        "country": "United States",
        "iata": "LXN",
        "icao": "KLXN",
        "latitude": "40.791000366199995",
        "longitude": "-99.7772979736",
        "altitude": "2413",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "CBF": {
        "name": "Council Bluffs Municipal Airport",
        "city": "Council Bluffs",
        "country": "United States",
        "iata": "CBF",
        "icao": "KCBF",
        "latitude": "41.2592010498",
        "longitude": "-95.760597229",
        "altitude": "1253",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "OKK": {
        "name": "Kokomo Municipal Airport",
        "city": "Kokomo",
        "country": "United States",
        "iata": "OKK",
        "icao": "KOKK",
        "latitude": "40.528198242188",
        "longitude": "-86.05899810791",
        "altitude": "830",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "GBG": {
        "name": "Galesburg Municipal Airport",
        "city": "Galesburg",
        "country": "United States",
        "iata": "GBG",
        "icao": "KGBG",
        "latitude": "40.937999725299996",
        "longitude": "-90.431098938",
        "altitude": "764",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "GUY": {
        "name": "Guymon Municipal Airport",
        "city": "Guymon",
        "country": "United States",
        "iata": "GUY",
        "icao": "KGUY",
        "latitude": "36.6851005554",
        "longitude": "-101.508003235",
        "altitude": "3123",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "IDP": {
        "name": "Independence Municipal Airport",
        "city": "Independence",
        "country": "United States",
        "iata": "IDP",
        "icao": "KIDP",
        "latitude": "37.1584014893",
        "longitude": "-95.77839660640001",
        "altitude": "825",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "BBC": {
        "name": "Bay City Municipal Airport",
        "city": "Bay City",
        "country": "United States",
        "iata": "BBC",
        "icao": "KBYY",
        "latitude": "28.9733009338",
        "longitude": "-95.8635025024",
        "altitude": "45",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "PRX": {
        "name": "Cox Field",
        "city": "Paris",
        "country": "United States",
        "iata": "PRX",
        "icao": "KPRX",
        "latitude": "33.636600494385",
        "longitude": "-95.450798034668",
        "altitude": "547",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "CFV": {
        "name": "Coffeyville Municipal Airport",
        "city": "Coffeyville",
        "country": "United States",
        "iata": "CFV",
        "icao": "KCFV",
        "latitude": "37.09400177",
        "longitude": "-95.5718994141",
        "altitude": "754",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "GXY": {
        "name": "Greeley–Weld County Airport",
        "city": "Greeley",
        "country": "United States",
        "iata": "GXY",
        "icao": "KGXY",
        "latitude": "40.4374008179",
        "longitude": "-104.633003235",
        "altitude": "4697",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "OEL": {
        "name": "Oryol Yuzhny Airport",
        "city": "Oakley",
        "country": "United States",
        "iata": "OEL",
        "icao": "UUOR",
        "latitude": "52.934700012200004",
        "longitude": "36.0022010803",
        "altitude": "656",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "FET": {
        "name": "Fremont Municipal Airport",
        "city": "Fremont",
        "country": "United States",
        "iata": "FET",
        "icao": "KFET",
        "latitude": "41.44910049",
        "longitude": "-96.52020264",
        "altitude": "1204",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "LGD": {
        "name": "La Grande/Union County Airport",
        "city": "La Grande",
        "country": "United States",
        "iata": "LGD",
        "icao": "KLGD",
        "latitude": "45.2901992798",
        "longitude": "-118.007003784",
        "altitude": "2717",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "SZY": {
        "name": "Olsztyn-Mazury Airport",
        "city": "Szczytno-Szymany",
        "country": "Poland",
        "iata": "SZY",
        "icao": "EPSY",
        "latitude": "53.481899",
        "longitude": "20.9377",
        "altitude": "463",
        "timezone": "1",
        "dst": "E",
        "tz_name": null
    },
    "MPO": {
        "name": "Pocono Mountains Municipal Airport",
        "city": "Mount Pocono",
        "country": "United States",
        "iata": "MPO",
        "icao": "KMPO",
        "latitude": "41.137503",
        "longitude": "-75.378897",
        "altitude": "1915",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "UKT": {
        "name": "Quakertown Airport",
        "city": "Quakertown",
        "country": "United States",
        "iata": "UKT",
        "icao": "KUKT",
        "latitude": "40.435199737549",
        "longitude": "-75.381896972656",
        "altitude": "526",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "YBA": {
        "name": "Banff Airport",
        "city": "Banff",
        "country": "Canada",
        "iata": "YBA",
        "icao": "CYBA",
        "latitude": "51.207340469900004",
        "longitude": "-115.541861057",
        "altitude": "4583",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "BNG": {
        "name": "Banning Municipal Airport",
        "city": "Banning",
        "country": "United States",
        "iata": "BNG",
        "icao": "KBNG",
        "latitude": "33.9230995178",
        "longitude": "-116.850997925",
        "altitude": "2219",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "OFK": {
        "name": "Karl Stefan Memorial Airport",
        "city": "Norfolk  Nebraska",
        "country": "United States",
        "iata": "OFK",
        "icao": "KOFK",
        "latitude": "41.985500335693",
        "longitude": "-97.435096740723",
        "altitude": "1573",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "TFL": {
        "name": "Juscelino Kubitscheck Airport",
        "city": "Teofilo Otoni",
        "country": "Brazil",
        "iata": "TFL",
        "icao": "SNTO",
        "latitude": "-17.89229965209961",
        "longitude": "-41.51359939575195",
        "altitude": "1572",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "TPF": {
        "name": "Peter O Knight Airport",
        "city": "Tampa",
        "country": "United States",
        "iata": "TPF",
        "icao": "KTPF",
        "latitude": "27.915599822998",
        "longitude": "-82.44930267334",
        "altitude": "8",
        "timezone": "-5",
        "dst": "U",
        "tz_name": null
    },
    "BZC": {
        "name": "Umberto Modiano Airport",
        "city": "Buzios",
        "country": "Brazil",
        "iata": "BZC",
        "icao": "SBBZ",
        "latitude": "-22.770999908447266",
        "longitude": "-41.96289825439453",
        "altitude": "10",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "ITP": {
        "name": "Itaperuna Airport",
        "city": "Itaperuna",
        "country": "Brazil",
        "iata": "ITP",
        "icao": "SDUN",
        "latitude": "-21.219299316399997",
        "longitude": "-41.8759002686",
        "altitude": "410",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "REZ": {
        "name": "Resende Airport",
        "city": "Resende",
        "country": "Brazil",
        "iata": "REZ",
        "icao": "SDRS",
        "latitude": "-22.4785003662",
        "longitude": "-44.4803009033",
        "altitude": "1320",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "KBN": {
        "name": "Tunta Airport",
        "city": "Kabinda",
        "country": "Congo (Kinshasa)",
        "iata": "KBN",
        "icao": "FZWT",
        "latitude": "-6.132999897",
        "longitude": "24.4829998016",
        "altitude": "2766",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "IKL": {
        "name": "Ikela Airport",
        "city": "Ikela",
        "country": "Congo (Kinshasa)",
        "iata": "IKL",
        "icao": "FZGV",
        "latitude": "-1.048109",
        "longitude": "23.372501",
        "altitude": "1283",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "AIR": {
        "name": "Aripuanã Airport",
        "city": "Aripuana",
        "country": "Brazil",
        "iata": "AIR",
        "icao": "SWRP",
        "latitude": "-10.188278",
        "longitude": "-59.457273",
        "altitude": "623",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "JRN": {
        "name": "Juruena Airport",
        "city": "Juruena",
        "country": "Brazil",
        "iata": "JRN",
        "icao": "SWJU",
        "latitude": "-10.305832862854004",
        "longitude": "-58.489444732666016",
        "altitude": "525",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "JIA": {
        "name": "Juína Airport",
        "city": "Juina",
        "country": "Brazil",
        "iata": "JIA",
        "icao": "SWJN",
        "latitude": "-11.419444",
        "longitude": "-58.701668",
        "altitude": "1083",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "VLP": {
        "name": "Vila Rica Airport",
        "city": "Vila Rica",
        "country": "Brazil",
        "iata": "VLP",
        "icao": "SWVC",
        "latitude": "-9.979443550109863",
        "longitude": "-51.1422233581543",
        "altitude": "892",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "JUA": {
        "name": "Inácio Luís do Nascimento Airport",
        "city": "Juara",
        "country": "Brazil",
        "iata": "JUA",
        "icao": "SIZX",
        "latitude": "-11.2966",
        "longitude": "-57.5495",
        "altitude": "870",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "CCX": {
        "name": "Cáceres Airport",
        "city": "Caceres",
        "country": "Brazil",
        "iata": "CCX",
        "icao": "SWKC",
        "latitude": "-16.04360008239746",
        "longitude": "-57.62990188598633",
        "altitude": "492",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "TGQ": {
        "name": "Tangará da Serra Airport",
        "city": "Tangara da Serra",
        "country": "Brazil",
        "iata": "TGQ",
        "icao": "SWTS",
        "latitude": "-14.661999702500001",
        "longitude": "-57.4435005188",
        "altitude": "1460",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "CQA": {
        "name": "Canarana Airport",
        "city": "Canarana",
        "country": "Brazil",
        "iata": "CQA",
        "icao": "SWEK",
        "latitude": "-13.574443817138672",
        "longitude": "-52.27055740356445",
        "altitude": "1314",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "MTG": {
        "name": "Vila Bela da Santíssima Trindade Airport",
        "city": "Vila Bela da Santissima Trindade ",
        "country": "Brazil",
        "iata": "MTG",
        "icao": "SWVB",
        "latitude": "-14.9942",
        "longitude": "-59.9458",
        "altitude": "660",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "BMB": {
        "name": "Bumbar Airport",
        "city": "Bumba",
        "country": "Congo (Kinshasa)",
        "iata": "BMB",
        "icao": "FZFU",
        "latitude": "2.18278",
        "longitude": "22.481701",
        "altitude": "0",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "APQ": {
        "name": "Arapiraca Airport",
        "city": "Arapiraca",
        "country": "Brazil",
        "iata": "APQ",
        "icao": "SNAL",
        "latitude": "-9.775360107421875",
        "longitude": "-36.62919998168945",
        "altitude": "886",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "FLB": {
        "name": "Cangapara Airport",
        "city": "Floriano",
        "country": "Brazil",
        "iata": "FLB",
        "icao": "SNQG",
        "latitude": "-6.8463897705078125",
        "longitude": "-43.077301025390625",
        "altitude": "689",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "PCS": {
        "name": "Picos Airport",
        "city": "Picos",
        "country": "Brazil",
        "iata": "PCS",
        "icao": "SNPC",
        "latitude": "-7.0620598793029785",
        "longitude": "-41.52370071411133",
        "altitude": "1050",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "BNC": {
        "name": "Beni Airport",
        "city": "Beni",
        "country": "Congo (Kinshasa)",
        "iata": "BNC",
        "icao": "FZNP",
        "latitude": "0.575",
        "longitude": "29.4739",
        "altitude": "3517",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "BNB": {
        "name": "Boende Airport",
        "city": "Boende",
        "country": "Congo (Kinshasa)",
        "iata": "BNB",
        "icao": "FZGN",
        "latitude": "-0.21699999272823334",
        "longitude": "20.850000381469727",
        "altitude": "1168",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "MTP": {
        "name": "Montauk Airport",
        "city": "Montauk",
        "country": "United States",
        "iata": "MTP",
        "icao": "KMTP",
        "latitude": "41.0765",
        "longitude": "-71.920797",
        "altitude": "6",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "VPZ": {
        "name": "Porter County Municipal Airport",
        "city": "Valparaiso IN",
        "country": "United States",
        "iata": "VPZ",
        "icao": "KVPZ",
        "latitude": "41.45399857",
        "longitude": "-87.00710297",
        "altitude": "770",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "DRV": {
        "name": "Dharavandhoo Airport",
        "city": "DHARAVANDHOO",
        "country": "Maldives",
        "iata": "DRV",
        "icao": "VRMD",
        "latitude": "5.1561",
        "longitude": "73.1302",
        "altitude": "6",
        "timezone": "5",
        "dst": "N",
        "tz_name": null
    },
    "SXK": {
        "name": "Saumlaki/Olilit Airport",
        "city": "Saumlaki",
        "country": "Indonesia",
        "iata": "SXK",
        "icao": "WAPI",
        "latitude": "-7.9886097908",
        "longitude": "131.305999756",
        "altitude": "218",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "MLZ": {
        "name": "Cerro Largo International Airport",
        "city": "Melo",
        "country": "Uruguay",
        "iata": "MLZ",
        "icao": "SUMO",
        "latitude": "-32.33789825439453",
        "longitude": "-54.21670150756836",
        "altitude": "364",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "PDU": {
        "name": "Tydeo Larre Borges Airport",
        "city": "Paysandu",
        "country": "Uruguay",
        "iata": "PDU",
        "icao": "SUPU",
        "latitude": "-32.36330032348633",
        "longitude": "-58.0619010925293",
        "altitude": "138",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "ATI": {
        "name": "Artigas International Airport",
        "city": "Artigas ",
        "country": "Uruguay",
        "iata": "ATI",
        "icao": "SUAG",
        "latitude": "-30.400699615478516",
        "longitude": "-56.50790023803711",
        "altitude": "410",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "HSM": {
        "name": "Horsham Airport",
        "city": "Horsham",
        "country": "Australia",
        "iata": "HSM",
        "icao": "YHSM",
        "latitude": "-36.669700622558594",
        "longitude": "142.17300415039062",
        "altitude": "445",
        "timezone": "10",
        "dst": "O",
        "tz_name": null
    },
    "SWH": {
        "name": "Swan Hill Airport",
        "city": "Swan Hill",
        "country": "Australia",
        "iata": "SWH",
        "icao": "YSWH",
        "latitude": "-35.37580108642578",
        "longitude": "143.5330047607422",
        "altitude": "234",
        "timezone": "10",
        "dst": "O",
        "tz_name": null
    },
    "TTL": {
        "name": "Turtle Island Seaplane Base",
        "city": "Turtle Island",
        "country": "Fiji",
        "iata": "TTL",
        "icao": "NFUL",
        "latitude": "-16.966",
        "longitude": "177.368",
        "altitude": "0",
        "timezone": "12",
        "dst": "O",
        "tz_name": null
    },
    "KWB": {
        "name": "Dewadaru - Kemujan Island",
        "city": "Karimunjawa",
        "country": "Indonesia",
        "iata": "KWB",
        "icao": "WARU",
        "latitude": "-5.80091",
        "longitude": "110.47838",
        "altitude": "35",
        "timezone": "7",
        "dst": "U",
        "tz_name": null
    },
    "KOO": {
        "name": "Kongolo Airport",
        "city": "Kongolo",
        "country": "Congo (Kinshasa)",
        "iata": "KOO",
        "icao": "FZRQ",
        "latitude": "-5.39444",
        "longitude": "26.99",
        "altitude": "1850",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "AOU": {
        "name": "Attopeu Airport",
        "city": "Attopeu",
        "country": "Laos",
        "iata": "AOU",
        "icao": "VLAP",
        "latitude": "14.814715357",
        "longitude": "106.821699142",
        "altitude": "344",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "SQX": {
        "name": "São Miguel do Oeste Airport",
        "city": "Sao Miguel do Oeste",
        "country": "Brazil",
        "iata": "SQX",
        "icao": "SSOE",
        "latitude": "-26.781600952148438",
        "longitude": "-53.503501892089844",
        "altitude": "2180",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "LDM": {
        "name": "Mason County Airport",
        "city": "Ludington",
        "country": "United States",
        "iata": "LDM",
        "icao": "KLDM",
        "latitude": "43.96250153",
        "longitude": "-86.40789795",
        "altitude": "646",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "RHV": {
        "name": "Reid-Hillview Airport of Santa Clara County",
        "city": "San Jose",
        "country": "United States",
        "iata": "RHV",
        "icao": "KRHV",
        "latitude": "37.332901001",
        "longitude": "-121.819000244",
        "altitude": "135",
        "timezone": "8",
        "dst": "A",
        "tz_name": null
    },
    "OHS": {
        "name": "Sohar Airport",
        "city": "Sohar",
        "country": "Oman",
        "iata": "OHS",
        "icao": "OOSH",
        "latitude": "24.38604",
        "longitude": "56.62541",
        "altitude": "20",
        "timezone": "4",
        "dst": "U",
        "tz_name": null
    },
    "KCF": {
        "name": "Kadanwari Airport",
        "city": "Kadanwari",
        "country": "Pakistan",
        "iata": "KCF",
        "icao": "OPKW",
        "latitude": "27.1667003632",
        "longitude": "69.31670379639999",
        "altitude": "248",
        "timezone": "5",
        "dst": "U",
        "tz_name": null
    },
    "RZS": {
        "name": "Sawan Airport",
        "city": "Sindh",
        "country": "Pakistan",
        "iata": "RZS",
        "icao": "OPSW",
        "latitude": "26.9662",
        "longitude": "68.878098",
        "altitude": "160",
        "timezone": "5",
        "dst": "U",
        "tz_name": null
    },
    "TMF": {
        "name": "Thimarafushi Airport",
        "city": "Thimarafushi",
        "country": "Maldives",
        "iata": "TMF",
        "icao": "VRNT",
        "latitude": "2.211",
        "longitude": "73.1533",
        "altitude": "6",
        "timezone": "5",
        "dst": "U",
        "tz_name": null
    },
    "IFU": {
        "name": "Ifuru Airport",
        "city": "Ifuru",
        "country": "Maldives",
        "iata": "IFU",
        "icao": "VREI",
        "latitude": "5.7083",
        "longitude": "73.025",
        "altitude": "20",
        "timezone": "5",
        "dst": "U",
        "tz_name": null
    },
    "KZF": {
        "name": "Kaintiba Airport",
        "city": "Kieta",
        "country": "Papua New Guinea",
        "iata": "KZF",
        "icao": "AYKT",
        "latitude": "-7.50025",
        "longitude": "146.033833333",
        "altitude": "2050",
        "timezone": "10",
        "dst": "U",
        "tz_name": null
    },
    "OGU": {
        "name": "Ordu Giresun Airport",
        "city": "Ordu-Giresun",
        "country": "Turkey",
        "iata": "OGU",
        "icao": "LTCB",
        "latitude": "40.966047",
        "longitude": "38.080994",
        "altitude": "11",
        "timezone": "2",
        "dst": "E",
        "tz_name": null
    },
    "YKO": {
        "name": "Hakkari Yüksekova Airport",
        "city": "Hakkari",
        "country": "Turkey",
        "iata": "YKO",
        "icao": "LTCW",
        "latitude": "37.5497",
        "longitude": "44.2381",
        "altitude": "6400",
        "timezone": "2",
        "dst": "E",
        "tz_name": null
    },
    "BUT": {
        "name": "Bathpalathang Airport",
        "city": "Jakar",
        "country": "Bhutan",
        "iata": "BUT",
        "icao": "VQBT",
        "latitude": "27.5622",
        "longitude": "90.7471",
        "altitude": "8485",
        "timezone": "6",
        "dst": "N",
        "tz_name": null
    },
    "TLI": {
        "name": "Sultan Bantilan Airport",
        "city": "Toli-Toli",
        "country": "Indonesia",
        "iata": "TLI",
        "icao": "WAMI",
        "latitude": "1.123428",
        "longitude": "120.793658",
        "altitude": "40",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "TQL": {
        "name": "Tarko-Sale Airport",
        "city": "Tarko-Sale",
        "country": "Russia",
        "iata": "TQL",
        "icao": "USDS",
        "latitude": "64.9308013916",
        "longitude": "77.81809997559999",
        "altitude": "82",
        "timezone": "5",
        "dst": "U",
        "tz_name": null
    },
    "JIC": {
        "name": "Jinchuan Airport",
        "city": "Jinchuan",
        "country": "China",
        "iata": "JIC",
        "icao": "ZLJC",
        "latitude": "38.542222222199996",
        "longitude": "102.348333333",
        "altitude": "4740",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "BPL": {
        "name": "Alashankou Bole (Bortala) airport",
        "city": "Bole",
        "country": "China",
        "iata": "BPL",
        "icao": "ZWAX",
        "latitude": "44.895",
        "longitude": "82.3",
        "altitude": "1253",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "FYN": {
        "name": "Fuyun Koktokay Airport",
        "city": "Fuyun",
        "country": "China",
        "iata": "FYN",
        "icao": "ZWFY",
        "latitude": "46.804169",
        "longitude": "89.512006",
        "altitude": "3081",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "ACS": {
        "name": "Achinsk Airport",
        "city": "Achinsk",
        "country": "Russia",
        "iata": "ACS",
        "icao": "UNKS",
        "latitude": "56.2682991027832",
        "longitude": "90.57080078125",
        "altitude": "1033",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "LFQ": {
        "name": "Linfen Qiaoli Airport",
        "city": "LINFEN",
        "country": "China",
        "iata": "LFQ",
        "icao": "ZBLF",
        "latitude": "36.132629",
        "longitude": "111.641236",
        "altitude": "1483",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "YJP": {
        "name": "Hinton/Jasper-Hinton Airport",
        "city": "Hinton",
        "country": "Canada",
        "iata": "YJP",
        "icao": "CEC4",
        "latitude": "53.3191986084",
        "longitude": "-117.752998352",
        "altitude": "4006",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "WVI": {
        "name": "Watsonville Municipal Airport",
        "city": "Watsonville",
        "country": "United States",
        "iata": "WVI",
        "icao": "KWVI",
        "latitude": "36.9356994629",
        "longitude": "-121.790000916",
        "altitude": "163",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "GLU": {
        "name": "Gelephu Airport",
        "city": "Gelephu",
        "country": "Bhutan",
        "iata": "GLU",
        "icao": "VQGP",
        "latitude": "26.88456",
        "longitude": "90.46412",
        "altitude": "980",
        "timezone": "6",
        "dst": "N",
        "tz_name": null
    },
    "HLI": {
        "name": "Hollister Municipal Airport",
        "city": "Hollister",
        "country": "United States",
        "iata": "HLI",
        "icao": "KCVH",
        "latitude": "36.8932991028",
        "longitude": "-121.410003662",
        "altitude": "230",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "DLK": {
        "name": "Dulkaninna Airport",
        "city": "Dulkaninna",
        "country": "Australia",
        "iata": "DLK",
        "icao": "YDLK",
        "latitude": "-29.0132999420166",
        "longitude": "138.4810028076172",
        "altitude": "0",
        "timezone": "9.5",
        "dst": "U",
        "tz_name": null
    },
    "YBS": {
        "name": "Opapimiskan Lake Airport",
        "city": "Musselwhite Mine",
        "country": "Canada",
        "iata": "YBS",
        "icao": "CKM8",
        "latitude": "52.6067008972",
        "longitude": "-90.3768997192",
        "altitude": "1023",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "RIZ": {
        "name": "Rizhao Shanzihe Airport",
        "city": "Rizhao",
        "country": "China",
        "iata": "RIZ",
        "icao": "ZSRZ",
        "latitude": "35.405033",
        "longitude": "119.324403",
        "altitude": "121",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "SQJ": {
        "name": "Shaxian Airport",
        "city": "Sanming",
        "country": "China",
        "iata": "SQJ",
        "icao": "ZSSM",
        "latitude": "26.4263",
        "longitude": "117.8336",
        "altitude": "830",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "XTO": {
        "name": "Taroom Airport",
        "city": "Taroom",
        "country": "Australia",
        "iata": "XTO",
        "icao": "YTAM",
        "latitude": "-25.801700592041016",
        "longitude": "149.89999389648438",
        "altitude": "240",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "YSE": {
        "name": "Squamish Airport",
        "city": "Squamish",
        "country": "Canada",
        "iata": "YSE",
        "icao": "CYSE",
        "latitude": "49.7817001343",
        "longitude": "-123.162002563",
        "altitude": "171",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "YAH": {
        "name": "La Grande-4 Airport",
        "city": "La Grande-4",
        "country": "Canada",
        "iata": "YAH",
        "icao": "CYAH",
        "latitude": "53.754699707",
        "longitude": "-73.6753005981",
        "altitude": "1005",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YAL": {
        "name": "Alert Bay Airport",
        "city": "Alert Bay",
        "country": "Canada",
        "iata": "YAL",
        "icao": "CYAL",
        "latitude": "50.58219909667969",
        "longitude": "-126.91600036621094",
        "altitude": "240",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YCE": {
        "name": "Centralia / James T. Field Memorial Aerodrome",
        "city": "Centralia",
        "country": "Canada",
        "iata": "YCE",
        "icao": "CYCE",
        "latitude": "43.285599",
        "longitude": "-81.508301",
        "altitude": "824",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YCQ": {
        "name": "Chetwynd Airport",
        "city": "Chetwynd",
        "country": "Canada",
        "iata": "YCQ",
        "icao": "CYCQ",
        "latitude": "55.687198638916016",
        "longitude": "-121.62699890136719",
        "altitude": "2000",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XRR": {
        "name": "Ross River Airport",
        "city": "Ross River",
        "country": "Canada",
        "iata": "XRR",
        "icao": "CYDM",
        "latitude": "61.9706001282",
        "longitude": "-132.42300415",
        "altitude": "2314",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YDO": {
        "name": "Dolbeau St Felicien Airport",
        "city": "Dolbeau-St-Félicien",
        "country": "Canada",
        "iata": "YDO",
        "icao": "CYDO",
        "latitude": "48.778499603271",
        "longitude": "-72.375",
        "altitude": "372",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YEY": {
        "name": "Amos/Magny Airport",
        "city": "Amos",
        "country": "Canada",
        "iata": "YEY",
        "icao": "CYEY",
        "latitude": "48.563903",
        "longitude": "-78.249702",
        "altitude": "1068",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YHE": {
        "name": "Hope Airport",
        "city": "Hope",
        "country": "Canada",
        "iata": "YHE",
        "icao": "CYHE",
        "latitude": "49.3683013916",
        "longitude": "-121.498001099",
        "altitude": "128",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YHT": {
        "name": "Haines Junction Airport",
        "city": "Haines Junction",
        "country": "Canada",
        "iata": "YHT",
        "icao": "CYHT",
        "latitude": "60.78919982910156",
        "longitude": "-137.54600524902344",
        "altitude": "2150",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YDG": {
        "name": "Digby / Annapolis Regional Airport",
        "city": "Digby",
        "country": "Canada",
        "iata": "YDG",
        "icao": "CYID",
        "latitude": "44.5458450365",
        "longitude": "-65.7854247093",
        "altitude": "499",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YJF": {
        "name": "Fort Liard Airport",
        "city": "Fort Liard",
        "country": "Canada",
        "iata": "YJF",
        "icao": "CYJF",
        "latitude": "60.235801696799996",
        "longitude": "-123.46900177",
        "altitude": "708",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YKJ": {
        "name": "Key Lake Airport",
        "city": "Key Lake",
        "country": "Canada",
        "iata": "YKJ",
        "icao": "CYKJ",
        "latitude": "57.256099700927734",
        "longitude": "-105.61799621582031",
        "altitude": "1679",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YLR": {
        "name": "Leaf Rapids Airport",
        "city": "Leaf Rapids",
        "country": "Canada",
        "iata": "YLR",
        "icao": "CYLR",
        "latitude": "56.513301849365234",
        "longitude": "-99.98529815673828",
        "altitude": "959",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YME": {
        "name": "Matane Airport",
        "city": "Matane",
        "country": "Canada",
        "iata": "YME",
        "icao": "CYME",
        "latitude": "48.85689926147461",
        "longitude": "-67.45330047607422",
        "altitude": "102",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YML": {
        "name": "Charlevoix Airport",
        "city": "Charlevoix",
        "country": "Canada",
        "iata": "YML",
        "icao": "CYML",
        "latitude": "47.59749984741211",
        "longitude": "-70.2238998413086",
        "altitude": "977",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YOS": {
        "name": "Owen Sound / Billy Bishop Regional Airport",
        "city": "Owen Sound",
        "country": "Canada",
        "iata": "YOS",
        "icao": "CYOS",
        "latitude": "44.5903015137",
        "longitude": "-80.8375015259",
        "altitude": "1007",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YPS": {
        "name": "Port Hawkesbury Airport",
        "city": "Port Hawkesbury",
        "country": "Canada",
        "iata": "YPS",
        "icao": "CYPD",
        "latitude": "45.6567001343",
        "longitude": "-61.3680992126",
        "altitude": "377",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YQS": {
        "name": "St Thomas Municipal Airport",
        "city": "St Thomas",
        "country": "Canada",
        "iata": "YQS",
        "icao": "CYQS",
        "latitude": "42.77000045776367",
        "longitude": "-81.11080169677734",
        "altitude": "778",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YRO": {
        "name": "Ottawa / Rockcliffe Airport",
        "city": "Ottawa",
        "country": "Canada",
        "iata": "YRO",
        "icao": "CYRO",
        "latitude": "45.4603004456",
        "longitude": "-75.64610290530001",
        "altitude": "188",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YSH": {
        "name": "Smiths Falls-Montague (Russ Beach) Airport",
        "city": "Smiths Falls",
        "country": "Canada",
        "iata": "YSH",
        "icao": "CYSH",
        "latitude": "44.94580078125",
        "longitude": "-75.94059753417969",
        "altitude": "416",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YSL": {
        "name": "St Leonard Airport",
        "city": "St Leonard",
        "country": "Canada",
        "iata": "YSL",
        "icao": "CYSL",
        "latitude": "47.157501220703125",
        "longitude": "-67.83470153808594",
        "altitude": "793",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YVE": {
        "name": "Vernon Airport",
        "city": "Vernon",
        "country": "Canada",
        "iata": "YVE",
        "icao": "CYVK",
        "latitude": "50.24810028076172",
        "longitude": "-119.33100128173828",
        "altitude": "1140",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YXQ": {
        "name": "Beaver Creek Airport",
        "city": "Beaver Creek",
        "country": "Canada",
        "iata": "YXQ",
        "icao": "CYXQ",
        "latitude": "62.410301208496094",
        "longitude": "-140.86700439453125",
        "altitude": "2131",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YSN": {
        "name": "Shuswap Regional Airport",
        "city": "Salmon Arm",
        "country": "Canada",
        "iata": "YSN",
        "icao": "CZAM",
        "latitude": "50.682802",
        "longitude": "-119.228996",
        "altitude": "1751",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KES": {
        "name": "Kelsey Airport",
        "city": "Kelsey",
        "country": "Canada",
        "iata": "KES",
        "icao": "CZEE",
        "latitude": "56.0374984741",
        "longitude": "-96.50969696039999",
        "altitude": "600",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XPK": {
        "name": "Pukatawagan Airport",
        "city": "Pukatawagan",
        "country": "Canada",
        "iata": "XPK",
        "icao": "CZFG",
        "latitude": "55.7491989136",
        "longitude": "-101.26599884",
        "altitude": "958",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZGF": {
        "name": "Grand Forks Airport",
        "city": "Grand Forks",
        "country": "Canada",
        "iata": "ZGF",
        "icao": "CZGF",
        "latitude": "49.01559829711914",
        "longitude": "-118.43099975585938",
        "altitude": "1720",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZJG": {
        "name": "Jenpeg Airport",
        "city": "Jenpeg",
        "country": "Canada",
        "iata": "ZJG",
        "icao": "CZJG",
        "latitude": "54.51890182495117",
        "longitude": "-98.04609680175781",
        "altitude": "729",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YTD": {
        "name": "Thicket Portage Airport",
        "city": "Thicket Portage",
        "country": "Canada",
        "iata": "YTD",
        "icao": "CZLQ",
        "latitude": "55.31890106201172",
        "longitude": "-97.70780181884766",
        "altitude": "678",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PIW": {
        "name": "Pikwitonei Airport",
        "city": "Pikwitonei",
        "country": "Canada",
        "iata": "PIW",
        "icao": "CZMN",
        "latitude": "55.58890151977539",
        "longitude": "-97.16419982910156",
        "altitude": "630",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XPP": {
        "name": "Poplar River Airport",
        "city": "Poplar River",
        "country": "Canada",
        "iata": "XPP",
        "icao": "CZNG",
        "latitude": "52.9965258788",
        "longitude": "-97.2741937637",
        "altitude": "728",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WPC": {
        "name": "Pincher Creek Airport",
        "city": "Pincher Creek",
        "country": "Canada",
        "iata": "WPC",
        "icao": "CZPC",
        "latitude": "49.520599365200006",
        "longitude": "-113.997001648",
        "altitude": "3903",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZST": {
        "name": "Stewart Airport",
        "city": "Stewart",
        "country": "Canada",
        "iata": "ZST",
        "icao": "CZST",
        "latitude": "55.935410448",
        "longitude": "-129.982434511",
        "altitude": "24",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZUC": {
        "name": "Ignace Municipal Airport",
        "city": "Ignace",
        "country": "Canada",
        "iata": "ZUC",
        "icao": "CZUC",
        "latitude": "49.4296989440918",
        "longitude": "-91.7177963256836",
        "altitude": "1435",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "FNB": {
        "name": "Neubrandenburg Airport",
        "city": "Neubrandenburg",
        "country": "Germany",
        "iata": "FNB",
        "icao": "EDBN",
        "latitude": "53.6022",
        "longitude": "13.306",
        "altitude": "228",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "FSS": {
        "name": "RAF Kinloss",
        "city": "Kinloss",
        "country": "United Kingdom",
        "iata": "FSS",
        "icao": "EGQK",
        "latitude": "57.6493988037",
        "longitude": "-3.56064009666",
        "altitude": "22",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BXP": {
        "name": "Biała Podlaska Airfield",
        "city": "Biała Podlaska",
        "country": "Poland",
        "iata": "BXP",
        "icao": "EPBP",
        "latitude": "52.00078",
        "longitude": "23.1325278",
        "altitude": "485",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DGP": {
        "name": "Daugavpils Intrenational Airport",
        "city": "Daugavpils",
        "country": "Latvia",
        "iata": "DGP",
        "icao": "EVDA",
        "latitude": "55.944721221900004",
        "longitude": "26.6650009155",
        "altitude": "398",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "LMR": {
        "name": "Lime Acres Finsch Mine Airport",
        "city": "Lime Acres",
        "country": "South Africa",
        "iata": "LMR",
        "icao": "FALC",
        "latitude": "-28.36009979248047",
        "longitude": "23.43910026550293",
        "altitude": "4900",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SXN": {
        "name": "Sua Pan Airport",
        "city": "Sowa",
        "country": "Botswana",
        "iata": "SXN",
        "icao": "FBSN",
        "latitude": "-20.5534",
        "longitude": "26.115801",
        "altitude": "2985",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "NDD": {
        "name": "Sumbe Airport",
        "city": "Sumbe",
        "country": "Angola",
        "iata": "NDD",
        "icao": "FNSU",
        "latitude": "-11.167900085449219",
        "longitude": "13.84749984741211",
        "altitude": "36",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MAI": {
        "name": "Mangochi Airport",
        "city": "Mangochi",
        "country": "Malawi",
        "iata": "MAI",
        "icao": "FWMG",
        "latitude": "-14.482999801635742",
        "longitude": "35.266998291015625",
        "altitude": "1580",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ADI": {
        "name": "Arandis Airport",
        "city": "Arandis",
        "country": "Namibia",
        "iata": "ADI",
        "icao": "FYAR",
        "latitude": "-22.462200164794922",
        "longitude": "14.979999542236328",
        "altitude": "1905",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MWE": {
        "name": "Merowe New Airport",
        "city": "Merowe",
        "country": "Sudan",
        "iata": "MWE",
        "icao": "HSMN",
        "latitude": "18.4433333333",
        "longitude": "31.8433333333",
        "altitude": "897",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ALN": {
        "name": "St Louis Regional Airport",
        "city": "Alton/St Louis",
        "country": "United States",
        "iata": "ALN",
        "icao": "KALN",
        "latitude": "38.89030075069999",
        "longitude": "-90.0459976196",
        "altitude": "544",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "AXN": {
        "name": "Chandler Field",
        "city": "Alexandria",
        "country": "United States",
        "iata": "AXN",
        "icao": "KAXN",
        "latitude": "45.8662986755",
        "longitude": "-95.39469909670001",
        "altitude": "1425",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CLU": {
        "name": "Columbus Municipal Airport",
        "city": "Columbus",
        "country": "United States",
        "iata": "CLU",
        "icao": "KBAK",
        "latitude": "39.2619018555",
        "longitude": "-85.8963012695",
        "altitude": "656",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BBD": {
        "name": "Curtis Field",
        "city": "Brady",
        "country": "United States",
        "iata": "BBD",
        "icao": "KBBD",
        "latitude": "31.1793003082",
        "longitude": "-99.3238983154",
        "altitude": "1827",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BIH": {
        "name": "Eastern Sierra Regional Airport",
        "city": "Bishop",
        "country": "United States",
        "iata": "BIH",
        "icao": "KBIH",
        "latitude": "37.3731002808",
        "longitude": "-118.363998413",
        "altitude": "4124",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BKE": {
        "name": "Baker City Municipal Airport",
        "city": "Baker City",
        "country": "United States",
        "iata": "BKE",
        "icao": "KBKE",
        "latitude": "44.837299346900004",
        "longitude": "-117.808998108",
        "altitude": "3373",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BPI": {
        "name": "Miley Memorial Field",
        "city": "Big Piney",
        "country": "United States",
        "iata": "BPI",
        "icao": "KBPI",
        "latitude": "42.58509827",
        "longitude": "-110.1110001",
        "altitude": "6990",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WMH": {
        "name": "Ozark Regional Airport",
        "city": "Mountain Home",
        "country": "United States",
        "iata": "WMH",
        "icao": "KBPK",
        "latitude": "36.3689002991",
        "longitude": "-92.47049713130001",
        "altitude": "928",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BTL": {
        "name": "W K Kellogg Airport",
        "city": "Battle Creek",
        "country": "United States",
        "iata": "BTL",
        "icao": "KBTL",
        "latitude": "42.307300567599995",
        "longitude": "-85.2515029907",
        "altitude": "952",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BYI": {
        "name": "Burley Municipal Airport",
        "city": "Burley",
        "country": "United States",
        "iata": "BYI",
        "icao": "KBYI",
        "latitude": "42.542598724399994",
        "longitude": "-113.772003174",
        "altitude": "4150",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CCY": {
        "name": "Northeast Iowa Regional Airport",
        "city": "Charles City",
        "country": "United States",
        "iata": "CCY",
        "icao": "KCCY",
        "latitude": "43.0726013184",
        "longitude": "-92.6108016968",
        "altitude": "1125",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CNU": {
        "name": "Chanute Martin Johnson Airport",
        "city": "Chanute",
        "country": "United States",
        "iata": "CNU",
        "icao": "KCNU",
        "latitude": "37.668800354",
        "longitude": "-95.4850997925",
        "altitude": "1002",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CRG": {
        "name": "Jacksonville Executive at Craig Airport",
        "city": "Jacksonville",
        "country": "United States",
        "iata": "CRG",
        "icao": "KCRG",
        "latitude": "30.3362998962",
        "longitude": "-81.51439666750001",
        "altitude": "41",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CSV": {
        "name": "Crossville Memorial Whitson Field",
        "city": "Crossville",
        "country": "United States",
        "iata": "CSV",
        "icao": "KCSV",
        "latitude": "35.9513015747",
        "longitude": "-85.08499908450001",
        "altitude": "1881",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DAA": {
        "name": "Davison Army Air Field",
        "city": "Fort Belvoir",
        "country": "United States",
        "iata": "DAA",
        "icao": "KDAA",
        "latitude": "38.715000152600005",
        "longitude": "-77.1809997559",
        "altitude": "73",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DAG": {
        "name": "Barstow Daggett Airport",
        "city": "Daggett",
        "country": "United States",
        "iata": "DAG",
        "icao": "KDAG",
        "latitude": "34.85369873",
        "longitude": "-116.7870026",
        "altitude": "1930",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DMN": {
        "name": "Deming Municipal Airport",
        "city": "Deming",
        "country": "United States",
        "iata": "DMN",
        "icao": "KDMN",
        "latitude": "32.262298584",
        "longitude": "-107.721000671",
        "altitude": "4314",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DRA": {
        "name": "Desert Rock Airport",
        "city": "Mercury",
        "country": "United States",
        "iata": "DRA",
        "icao": "KDRA",
        "latitude": "36.6194",
        "longitude": "-116.032997",
        "altitude": "3314",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "EED": {
        "name": "Needles Airport",
        "city": "Needles",
        "country": "United States",
        "iata": "EED",
        "icao": "KEED",
        "latitude": "34.7663002014",
        "longitude": "-114.623001099",
        "altitude": "983",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "EGI": {
        "name": "Duke Field",
        "city": "Crestview",
        "country": "United States",
        "iata": "EGI",
        "icao": "KEGI",
        "latitude": "30.65040016",
        "longitude": "-86.52290344",
        "altitude": "191",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "EKA": {
        "name": "Murray Field",
        "city": "Eureka",
        "country": "United States",
        "iata": "EKA",
        "icao": "KEKA",
        "latitude": "40.803398132299996",
        "longitude": "-124.112998962",
        "altitude": "7",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HYR": {
        "name": "Sawyer County Airport",
        "city": "Hayward",
        "country": "United States",
        "iata": "HYR",
        "icao": "KHYR",
        "latitude": "46.025199890100005",
        "longitude": "-91.44429779050002",
        "altitude": "1216",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "JCT": {
        "name": "Kimble County Airport",
        "city": "Junction",
        "country": "United States",
        "iata": "JCT",
        "icao": "KJCT",
        "latitude": "30.5112991333",
        "longitude": "-99.7634963989",
        "altitude": "1749",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "LOL": {
        "name": "Derby Field",
        "city": "Lovelock",
        "country": "United States",
        "iata": "LOL",
        "icao": "KLOL",
        "latitude": "40.0663986206",
        "longitude": "-118.565002441",
        "altitude": "3904",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MBG": {
        "name": "Mobridge Municipal Airport",
        "city": "Mobridge",
        "country": "United States",
        "iata": "MBG",
        "icao": "KMBG",
        "latitude": "45.54650116",
        "longitude": "-100.4079971",
        "altitude": "1716",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MCB": {
        "name": "Mc Comb/Pike County Airport/John E Lewis Field",
        "city": "Mc Comb",
        "country": "United States",
        "iata": "MCB",
        "icao": "KMCB",
        "latitude": "31.17849922",
        "longitude": "-90.47190094",
        "altitude": "413",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MDH": {
        "name": "Southern Illinois Airport",
        "city": "Carbondale/Murphysboro",
        "country": "United States",
        "iata": "MDH",
        "icao": "KMDH",
        "latitude": "37.778099060058594",
        "longitude": "-89.25199890136719",
        "altitude": "411",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MMT": {
        "name": "Mc Entire Joint National Guard Base",
        "city": "Eastover",
        "country": "United States",
        "iata": "MMT",
        "icao": "KMMT",
        "latitude": "33.92079926",
        "longitude": "-80.80130005",
        "altitude": "254",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "NHZ": {
        "name": "Brunswick Executive Airport",
        "city": "Brunswick",
        "country": "United States",
        "iata": "NHZ",
        "icao": "KNHZ",
        "latitude": "43.89220047",
        "longitude": "-69.93859863",
        "altitude": "72",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "NRB": {
        "name": "Naval Station Mayport (Admiral David L. Mcdonald Field)",
        "city": "Mayport",
        "country": "United States",
        "iata": "NRB",
        "icao": "KNRB",
        "latitude": "30.39109993",
        "longitude": "-81.42469788",
        "altitude": "15",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "OGB": {
        "name": "Orangeburg Municipal Airport",
        "city": "Orangeburg",
        "country": "United States",
        "iata": "OGB",
        "icao": "KOGB",
        "latitude": "33.456798553467",
        "longitude": "-80.859497070312",
        "altitude": "195",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "OTM": {
        "name": "Ottumwa Regional Airport",
        "city": "Ottumwa",
        "country": "United States",
        "iata": "OTM",
        "icao": "KOTM",
        "latitude": "41.10660172",
        "longitude": "-92.44789886",
        "altitude": "845",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "OZR": {
        "name": "Cairns AAF (Fort Rucker) Air Field",
        "city": "Fort Rucker/Ozark",
        "country": "United States",
        "iata": "OZR",
        "icao": "KOZR",
        "latitude": "31.27569962",
        "longitude": "-85.71340179",
        "altitude": "301",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PWY": {
        "name": "Ralph Wenz Field",
        "city": "Pinedale",
        "country": "United States",
        "iata": "PWY",
        "icao": "KPNA",
        "latitude": "42.79550171",
        "longitude": "-109.8069992",
        "altitude": "7102",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "POU": {
        "name": "Dutchess County Airport",
        "city": "Poughkeepsie",
        "country": "United States",
        "iata": "POU",
        "icao": "KPOU",
        "latitude": "41.6265983581543",
        "longitude": "-73.88420104980469",
        "altitude": "165",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RSL": {
        "name": "Russell Municipal Airport",
        "city": "Russell",
        "country": "United States",
        "iata": "RSL",
        "icao": "KRSL",
        "latitude": "38.872100830078",
        "longitude": "-98.811798095703",
        "altitude": "1862",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RWF": {
        "name": "Redwood Falls Municipal Airport",
        "city": "Redwood Falls",
        "country": "United States",
        "iata": "RWF",
        "icao": "KRWF",
        "latitude": "44.54719925",
        "longitude": "-95.08229828",
        "altitude": "1024",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SNS": {
        "name": "Salinas Municipal Airport",
        "city": "Salinas",
        "country": "United States",
        "iata": "SNS",
        "icao": "KSNS",
        "latitude": "36.662799835205",
        "longitude": "-121.60600280762",
        "altitude": "85",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TPH": {
        "name": "Tonopah Airport",
        "city": "Tonopah",
        "country": "United States",
        "iata": "TPH",
        "icao": "KTPH",
        "latitude": "38.06019974",
        "longitude": "-117.086998",
        "altitude": "5430",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "UKI": {
        "name": "Ukiah Municipal Airport",
        "city": "Ukiah",
        "country": "United States",
        "iata": "UKI",
        "icao": "KUKI",
        "latitude": "39.125999450684",
        "longitude": "-123.20099639893",
        "altitude": "614",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "UOX": {
        "name": "University Oxford Airport",
        "city": "Oxford",
        "country": "United States",
        "iata": "UOX",
        "icao": "KUOX",
        "latitude": "34.384300231934",
        "longitude": "-89.536796569824",
        "altitude": "452",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HTV": {
        "name": "Huntsville Regional Airport",
        "city": "Huntsville",
        "country": "United States",
        "iata": "HTV",
        "icao": "KUTS",
        "latitude": "30.7469005585",
        "longitude": "-95.5871963501",
        "altitude": "363",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "VTN": {
        "name": "Miller Field",
        "city": "Valentine",
        "country": "United States",
        "iata": "VTN",
        "icao": "KVTN",
        "latitude": "42.85779953",
        "longitude": "-100.5479965",
        "altitude": "2596",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WMC": {
        "name": "Winnemucca Municipal Airport",
        "city": "Winnemucca",
        "country": "United States",
        "iata": "WMC",
        "icao": "KWMC",
        "latitude": "40.8965988159",
        "longitude": "-117.805999756",
        "altitude": "4308",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WWR": {
        "name": "West Woodward Airport",
        "city": "Woodward",
        "country": "United States",
        "iata": "WWR",
        "icao": "KWWR",
        "latitude": "36.438",
        "longitude": "-99.5226667",
        "altitude": "2189",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZZV": {
        "name": "Zanesville Municipal Airport",
        "city": "Zanesville",
        "country": "United States",
        "iata": "ZZV",
        "icao": "KZZV",
        "latitude": "39.9444007874",
        "longitude": "-81.89209747310001",
        "altitude": "900",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ECV": {
        "name": "Cuatro Vientos Airport",
        "city": "Madrid",
        "country": "Spain",
        "iata": "ECV",
        "icao": "LECU",
        "latitude": "40.370701",
        "longitude": "-3.78514",
        "altitude": "2269",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CDT": {
        "name": "Castellón-Costa Azahar Airport",
        "city": "Castellón de la Plana",
        "country": "Spain",
        "iata": "CDT",
        "icao": "LEDS",
        "latitude": "40.213889",
        "longitude": "0.073333",
        "altitude": "1145",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TEV": {
        "name": "Teruel Airport",
        "city": "Teruel",
        "country": "Spain",
        "iata": "TEV",
        "icao": "LETL",
        "latitude": "40.403",
        "longitude": "-1.2183",
        "altitude": "3380",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "QLP": {
        "name": "Sarzana-Luni Air Base",
        "city": "Sarzana (SP)",
        "country": "Italy",
        "iata": "QLP",
        "icao": "LIQW",
        "latitude": "44.088001",
        "longitude": "9.98795",
        "altitude": "45",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BJY": {
        "name": "Batajnica Air Base",
        "city": "Batajnica",
        "country": "Serbia",
        "iata": "BJY",
        "icao": "LYBT",
        "latitude": "44.935299",
        "longitude": "20.2575",
        "altitude": "265",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RUV": {
        "name": "Rubelsanto Airport",
        "city": "Rubelsanto",
        "country": "Guatemala",
        "iata": "RUV",
        "icao": "MGRB",
        "latitude": "15.991999626159668",
        "longitude": "-90.44529724121094",
        "altitude": "426",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XPL": {
        "name": "Coronel Enrique Soto Cano Air Base",
        "city": "Comayagua",
        "country": "Honduras",
        "iata": "XPL",
        "icao": "MHSC",
        "latitude": "14.3824",
        "longitude": "-87.621201",
        "altitude": "2061",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "UPL": {
        "name": "Upala Airport",
        "city": "Upala",
        "country": "Costa Rica",
        "iata": "UPL",
        "icao": "MRUP",
        "latitude": "10.8922",
        "longitude": "-85.016197",
        "altitude": "184",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "QSN": {
        "name": "San Nicolas De Bari Airport",
        "city": "San Nicolás",
        "country": "Cuba",
        "iata": "QSN",
        "icao": "MUNB",
        "latitude": "22.756099700927734",
        "longitude": "-81.9208984375",
        "altitude": "49",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SNJ": {
        "name": "San Julian Air Base",
        "city": "Pinar Del Rio",
        "country": "Cuba",
        "iata": "SNJ",
        "icao": "MUSJ",
        "latitude": "22.095300674438477",
        "longitude": "-84.1520004272461",
        "altitude": "98",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DWD": {
        "name": "King Salman Abdulaziz Airport",
        "city": "",
        "country": "Saudi Arabia",
        "iata": "DWD",
        "icao": "OEDM",
        "latitude": "24.4499",
        "longitude": "44.121201",
        "altitude": "3026",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KMX": {
        "name": "King Khaled Air Base",
        "city": "",
        "country": "Saudi Arabia",
        "iata": "KMX",
        "icao": "OEKM",
        "latitude": "18.297300338745117",
        "longitude": "42.80350112915039",
        "altitude": "6778",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XXN": {
        "name": "Riyadh Air Base",
        "city": "Riyadh",
        "country": "Saudi Arabia",
        "iata": "XXN",
        "icao": "OERY",
        "latitude": "24.709800720214844",
        "longitude": "46.72520065307617",
        "altitude": "2082",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KNR": {
        "name": "Jam Airport",
        "city": "Kangan",
        "country": "Iran",
        "iata": "KNR",
        "icao": "OIBJ",
        "latitude": "27.820499420166016",
        "longitude": "52.35219955444336",
        "altitude": "2173",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PYK": {
        "name": "Payam International Airport",
        "city": "Karaj",
        "country": "Iran",
        "iata": "PYK",
        "icao": "OIIP",
        "latitude": "35.776100158691",
        "longitude": "50.826698303223",
        "altitude": "4170",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XIJ": {
        "name": "Ahmed Al Jaber Air Base",
        "city": "Ahmed Al Jaber AB",
        "country": "Kuwait",
        "iata": "XIJ",
        "icao": "OKAJ",
        "latitude": "28.9347991943",
        "longitude": "47.791900634799994",
        "altitude": "409",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ATG": {
        "name": "Minhas Air Base",
        "city": "Kamra",
        "country": "Pakistan",
        "iata": "ATG",
        "icao": "OPMS",
        "latitude": "33.8690986633",
        "longitude": "72.4009017944",
        "altitude": "1023",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SGI": {
        "name": "Mushaf Air Base",
        "city": "Sargodha",
        "country": "Pakistan",
        "iata": "SGI",
        "icao": "OPSR",
        "latitude": "32.04859924316406",
        "longitude": "72.66500091552734",
        "altitude": "614",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RQW": {
        "name": "Qayyarah West Airport",
        "city": "Qayyarah",
        "country": "Iraq",
        "iata": "RQW",
        "icao": "ORQW",
        "latitude": "35.76720047",
        "longitude": "43.125099182099994",
        "altitude": "749",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ENN": {
        "name": "Nenana Municipal Airport",
        "city": "Nenana",
        "country": "United States",
        "iata": "ENN",
        "icao": "PANN",
        "latitude": "64.54730224609375",
        "longitude": "-149.07400512695312",
        "altitude": "362",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WWA": {
        "name": "Wasilla Airport",
        "city": "Wasilla",
        "country": "United States",
        "iata": "WWA",
        "icao": "PAWS",
        "latitude": "61.5717010498",
        "longitude": "-149.539993286",
        "altitude": "354",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "IWK": {
        "name": "Iwakuni Marine Corps Air Station",
        "city": "Iwakuni",
        "country": "Japan",
        "iata": "IWK",
        "icao": "RJOI",
        "latitude": "34.143902",
        "longitude": "132.235992",
        "altitude": "7",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "IEJ": {
        "name": "Ie Jima Airport",
        "city": "Ie",
        "country": "Japan",
        "iata": "IEJ",
        "icao": "RORE",
        "latitude": "26.7220001221",
        "longitude": "127.785003662",
        "altitude": "246",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "AAV": {
        "name": "Allah Valley Airport",
        "city": "Surallah",
        "country": "Philippines",
        "iata": "AAV",
        "icao": "RPMA",
        "latitude": "6.366819858551025",
        "longitude": "124.7509994506836",
        "altitude": "659",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BPH": {
        "name": "Bislig Airport",
        "city": "",
        "country": "Philippines",
        "iata": "BPH",
        "icao": "RPMF",
        "latitude": "8.19594955444336",
        "longitude": "126.3219985961914",
        "altitude": "12",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MXI": {
        "name": "Mati National Airport",
        "city": "",
        "country": "Philippines",
        "iata": "MXI",
        "icao": "RPMQ",
        "latitude": "6.949091",
        "longitude": "126.27368",
        "altitude": "156",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BAT": {
        "name": "Chafei Amsei Airport",
        "city": "Barretos",
        "country": "Brazil",
        "iata": "BAT",
        "icao": "SBBT",
        "latitude": "-20.584499359131",
        "longitude": "-48.594100952148",
        "altitude": "1898",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "QHP": {
        "name": "Base de Aviação de Taubaté Airport",
        "city": "Taubaté",
        "country": "Brazil",
        "iata": "QHP",
        "icao": "SBTA",
        "latitude": "-23.04010009765625",
        "longitude": "-45.51599884033203",
        "altitude": "1908",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TOQ": {
        "name": "Barriles Airport",
        "city": "Tocopilla",
        "country": "Chile",
        "iata": "TOQ",
        "icao": "SCBE",
        "latitude": "-22.14109992980957",
        "longitude": "-70.06289672851562",
        "altitude": "3475",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CNR": {
        "name": "Chañaral Airport",
        "city": "Chañaral",
        "country": "Chile",
        "iata": "CNR",
        "icao": "SCRA",
        "latitude": "-26.332500457763672",
        "longitude": "-70.6072998046875",
        "altitude": "97",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TLX": {
        "name": "Panguilemo Airport",
        "city": "Talca",
        "country": "Chile",
        "iata": "TLX",
        "icao": "SCTL",
        "latitude": "-35.37779998779297",
        "longitude": "-71.60169982910156",
        "altitude": "371",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZIC": {
        "name": "Victoria Airport",
        "city": "Victoria",
        "country": "Chile",
        "iata": "ZIC",
        "icao": "SCTO",
        "latitude": "-38.245601654052734",
        "longitude": "-72.34860229492188",
        "altitude": "1148",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TTC": {
        "name": "Las Breas Airport",
        "city": "Taltal",
        "country": "Chile",
        "iata": "TTC",
        "icao": "SCTT",
        "latitude": "-25.564300537109375",
        "longitude": "-70.37590026855469",
        "altitude": "2580",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "API": {
        "name": "Gomez Nino Apiay Air Base",
        "city": "Apiay",
        "country": "Colombia",
        "iata": "API",
        "icao": "SKAP",
        "latitude": "4.07607",
        "longitude": "-73.5627",
        "altitude": "1207",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CVE": {
        "name": "Coveñas Airport",
        "city": "Coveñas",
        "country": "Colombia",
        "iata": "CVE",
        "icao": "SKCV",
        "latitude": "9.40092",
        "longitude": "-75.6913",
        "altitude": "31",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PAL": {
        "name": "German Olano Air Base",
        "city": "La Dorada",
        "country": "Colombia",
        "iata": "PAL",
        "icao": "SKPQ",
        "latitude": "5.48361",
        "longitude": "-74.6574",
        "altitude": "566",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PZA": {
        "name": "Paz De Ariporo Airport",
        "city": "Paz De Ariporo",
        "country": "Colombia",
        "iata": "PZA",
        "icao": "SKPZ",
        "latitude": "5.87615",
        "longitude": "-71.8866",
        "altitude": "900",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TQS": {
        "name": "Tres Esquinas Air Base",
        "city": "Tres Esquinas",
        "country": "Colombia",
        "iata": "TQS",
        "icao": "SKTQ",
        "latitude": "0.7459",
        "longitude": "-75.234",
        "altitude": "585",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RIJ": {
        "name": "Juan Simons Vela Airport",
        "city": "Rioja",
        "country": "Peru",
        "iata": "RIJ",
        "icao": "SPJA",
        "latitude": "-6.067860126495361",
        "longitude": "-77.16000366210938",
        "altitude": "2707",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "JAE": {
        "name": "Shumba Airport",
        "city": "Jaén",
        "country": "Peru",
        "iata": "JAE",
        "icao": "SPJE",
        "latitude": "-5.59248",
        "longitude": "-78.774002",
        "altitude": "2477",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "IKU": {
        "name": "Issyk-Kul International Airport",
        "city": "Tamchy",
        "country": "Kyrgyzstan",
        "iata": "IKU",
        "icao": "UAFL",
        "latitude": "42.58792",
        "longitude": "76.713046",
        "altitude": "5425",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "VYI": {
        "name": "Vilyuisk Airport",
        "city": "Vilyuisk",
        "country": "Russia",
        "iata": "VYI",
        "icao": "UENW",
        "latitude": "63.75666809082",
        "longitude": "121.69333648682",
        "altitude": "361",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BGN": {
        "name": "Belaya Gora Airport",
        "city": "",
        "country": "Russia",
        "iata": "BGN",
        "icao": "UESG",
        "latitude": "68.556602",
        "longitude": "146.231506",
        "altitude": "118",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "GVN": {
        "name": "Maygatka Airport.",
        "city": "Sovetskaya Gavan",
        "country": "Russia",
        "iata": "GVN",
        "icao": "UHKM",
        "latitude": "48.926998138399995",
        "longitude": "140.033996582",
        "altitude": "768",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "NLI": {
        "name": "Nikolayevsk-na-Amure Airport",
        "city": "Nikolayevsk-na-Amure Airport",
        "country": "Russia",
        "iata": "NLI",
        "icao": "UHNN",
        "latitude": "53.154999",
        "longitude": "140.649994",
        "altitude": "170",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "VLK": {
        "name": "Volgodonsk Airport",
        "city": "",
        "country": "Russia",
        "iata": "VLK",
        "icao": "URRY",
        "latitude": "47.682098388671875",
        "longitude": "42.07279968261719",
        "altitude": "276",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "INA": {
        "name": "Inta Airport",
        "city": "Inta",
        "country": "Russia",
        "iata": "INA",
        "icao": "UUYI",
        "latitude": "66.0548324584961",
        "longitude": "60.110321044921875",
        "altitude": "184",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZIX": {
        "name": "Zhigansk Airport",
        "city": "Zhigansk",
        "country": "Russia",
        "iata": "ZIX",
        "icao": "UWKV",
        "latitude": "66.7965011597",
        "longitude": "123.361000061",
        "altitude": "292",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RTC": {
        "name": "Ratnagiri Airport",
        "city": "",
        "country": "India",
        "iata": "RTC",
        "icao": "VARG",
        "latitude": "17.013599",
        "longitude": "73.327797",
        "altitude": "305",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HIM": {
        "name": "Hingurakgoda Air Force Base",
        "city": "Polonnaruwa Town",
        "country": "Sri Lanka",
        "iata": "HIM",
        "icao": "VCCH",
        "latitude": "8.04981",
        "longitude": "80.9814",
        "altitude": "170",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RDP": {
        "name": "Kazi Nazrul Islam Airport",
        "city": "Durgapur",
        "country": "India",
        "iata": "RDP",
        "icao": "VEDG",
        "latitude": "23.6225",
        "longitude": "87.243",
        "altitude": "300",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PUT": {
        "name": "Sri Sathya Sai Airport",
        "city": "Puttaparthi",
        "country": "India",
        "iata": "PUT",
        "icao": "VOPN",
        "latitude": "14.1492996216",
        "longitude": "77.7910995483",
        "altitude": "1558",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "LNX": {
        "name": "Smolensk North Airport",
        "city": "Smolensk",
        "country": "Russia",
        "iata": "LNX",
        "icao": "XUBS",
        "latitude": "54.824",
        "longitude": "32.025",
        "altitude": "820",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ABH": {
        "name": "Alpha Airport",
        "city": "Alpha",
        "country": "Australia",
        "iata": "ABH",
        "icao": "YAPH",
        "latitude": "-23.646099",
        "longitude": "146.584",
        "altitude": "1255",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ARY": {
        "name": "Ararat Airport",
        "city": "",
        "country": "Australia",
        "iata": "ARY",
        "icao": "YARA",
        "latitude": "-37.30939865112305",
        "longitude": "142.98899841308594",
        "altitude": "1008",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BLN": {
        "name": "Benalla Airport",
        "city": "",
        "country": "Australia",
        "iata": "BLN",
        "icao": "YBLA",
        "latitude": "-36.55189895629883",
        "longitude": "146.0070037841797",
        "altitude": "569",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BZD": {
        "name": "Balranald Airport",
        "city": "",
        "country": "Australia",
        "iata": "BZD",
        "icao": "YBRN",
        "latitude": "-34.623600006103516",
        "longitude": "143.5780029296875",
        "altitude": "210",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BWQ": {
        "name": "Brewarrina Airport",
        "city": "",
        "country": "Australia",
        "iata": "BWQ",
        "icao": "YBRW",
        "latitude": "-29.973899841308594",
        "longitude": "146.81700134277344",
        "altitude": "414",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CVC": {
        "name": "Cleve Airport",
        "city": "",
        "country": "Australia",
        "iata": "CVC",
        "icao": "YCEE",
        "latitude": "-33.70970153808594",
        "longitude": "136.5050048828125",
        "altitude": "589",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CWW": {
        "name": "Corowa Airport",
        "city": "",
        "country": "Australia",
        "iata": "CWW",
        "icao": "YCOR",
        "latitude": "-35.99470138549805",
        "longitude": "146.35699462890625",
        "altitude": "469",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CYG": {
        "name": "Corryong Airport",
        "city": "",
        "country": "Australia",
        "iata": "CYG",
        "icao": "YCRG",
        "latitude": "-36.18280029296875",
        "longitude": "147.88800048828125",
        "altitude": "963",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CMD": {
        "name": "Cootamundra Airport",
        "city": "",
        "country": "Australia",
        "iata": "CMD",
        "icao": "YCTM",
        "latitude": "-34.6239013671875",
        "longitude": "148.0279998779297",
        "altitude": "1110",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DRN": {
        "name": "Dirranbandi Airport",
        "city": "",
        "country": "Australia",
        "iata": "DRN",
        "icao": "YDBI",
        "latitude": "-28.591699600219727",
        "longitude": "148.2169952392578",
        "altitude": "567",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DNQ": {
        "name": "Deniliquin Airport",
        "city": "Deniliquin",
        "country": "Australia",
        "iata": "DNQ",
        "icao": "YDLQ",
        "latitude": "-35.5593986511",
        "longitude": "144.945999146",
        "altitude": "316",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DYA": {
        "name": "Dysart Airport",
        "city": "",
        "country": "Australia",
        "iata": "DYA",
        "icao": "YDYS",
        "latitude": "-22.62220001220703",
        "longitude": "148.36399841308594",
        "altitude": "670",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ECH": {
        "name": "Echuca Airport",
        "city": "",
        "country": "Australia",
        "iata": "ECH",
        "icao": "YECH",
        "latitude": "-36.15719985961914",
        "longitude": "144.76199340820312",
        "altitude": "323",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "FRB": {
        "name": "Forbes Airport",
        "city": "Forbes",
        "country": "Australia",
        "iata": "FRB",
        "icao": "YFBS",
        "latitude": "-33.363602",
        "longitude": "147.934998",
        "altitude": "760",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "GUH": {
        "name": "Gunnedah Airport",
        "city": "",
        "country": "Australia",
        "iata": "GUH",
        "icao": "YGDH",
        "latitude": "-30.96109962463379",
        "longitude": "150.25100708007812",
        "altitude": "863",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HXX": {
        "name": "Hay Airport",
        "city": "",
        "country": "Australia",
        "iata": "HXX",
        "icao": "YHAY",
        "latitude": "-34.53139877319336",
        "longitude": "144.8300018310547",
        "altitude": "305",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HTU": {
        "name": "Hopetoun Airport",
        "city": "",
        "country": "Australia",
        "iata": "HTU",
        "icao": "YHPN",
        "latitude": "-35.715301513671875",
        "longitude": "142.36000061035156",
        "altitude": "256",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KRA": {
        "name": "Kerang Airport",
        "city": "",
        "country": "Australia",
        "iata": "KRA",
        "icao": "YKER",
        "latitude": "-35.751399993896484",
        "longitude": "143.93899536132812",
        "altitude": "254",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KPS": {
        "name": "Kempsey Airport",
        "city": "",
        "country": "Australia",
        "iata": "KPS",
        "icao": "YKMP",
        "latitude": "-31.074399948120117",
        "longitude": "152.77000427246094",
        "altitude": "54",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KGY": {
        "name": "Kingaroy Airport",
        "city": "",
        "country": "Australia",
        "iata": "KGY",
        "icao": "YKRY",
        "latitude": "-26.580799102783203",
        "longitude": "151.84100341796875",
        "altitude": "1492",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TGN": {
        "name": "Latrobe Valley Airport",
        "city": "Morwell",
        "country": "Australia",
        "iata": "TGN",
        "icao": "YLTV",
        "latitude": "-38.207199",
        "longitude": "146.470001",
        "altitude": "180",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MRG": {
        "name": "Mareeba Airport",
        "city": "",
        "country": "Australia",
        "iata": "MRG",
        "icao": "YMBA",
        "latitude": "-17.06920051574707",
        "longitude": "145.41900634765625",
        "altitude": "1560",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RPM": {
        "name": "Ngukurr Airport",
        "city": "",
        "country": "Australia",
        "iata": "RPM",
        "icao": "YNGU",
        "latitude": "-14.722800254821777",
        "longitude": "134.7469940185547",
        "altitude": "45",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "QRM": {
        "name": "Narromine Airport",
        "city": "",
        "country": "Australia",
        "iata": "QRM",
        "icao": "YNRM",
        "latitude": "-32.214698791503906",
        "longitude": "148.22500610351562",
        "altitude": "782",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "PPI": {
        "name": "Port Pirie Airport",
        "city": "",
        "country": "Australia",
        "iata": "PPI",
        "icao": "YPIR",
        "latitude": "-33.23889923095703",
        "longitude": "137.9949951171875",
        "altitude": "40",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SIO": {
        "name": "Smithton Airport",
        "city": "",
        "country": "Australia",
        "iata": "SIO",
        "icao": "YSMI",
        "latitude": "-40.834999084472656",
        "longitude": "145.08399963378906",
        "altitude": "31",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SNB": {
        "name": "Snake Bay Airport",
        "city": "",
        "country": "Australia",
        "iata": "SNB",
        "icao": "YSNB",
        "latitude": "-11.422800064086914",
        "longitude": "130.6540069580078",
        "altitude": "173",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SWC": {
        "name": "Stawell Airport",
        "city": "",
        "country": "Australia",
        "iata": "SWC",
        "icao": "YSWL",
        "latitude": "-37.07170104980469",
        "longitude": "142.74099731445312",
        "altitude": "807",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TYB": {
        "name": "Tibooburra Airport",
        "city": "",
        "country": "Australia",
        "iata": "TYB",
        "icao": "YTIB",
        "latitude": "-29.451099395751953",
        "longitude": "142.05799865722656",
        "altitude": "584",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "TUM": {
        "name": "Tumut Airport",
        "city": "",
        "country": "Australia",
        "iata": "TUM",
        "icao": "YTMU",
        "latitude": "-35.26279830932617",
        "longitude": "148.24099731445312",
        "altitude": "878",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WGT": {
        "name": "Wangaratta Airport",
        "city": "",
        "country": "Australia",
        "iata": "WGT",
        "icao": "YWGT",
        "latitude": "-36.41579818725586",
        "longitude": "146.3070068359375",
        "altitude": "504",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WKB": {
        "name": "Warracknabeal Airport",
        "city": "",
        "country": "Australia",
        "iata": "WKB",
        "icao": "YWKB",
        "latitude": "-36.32109832763672",
        "longitude": "142.41900634765625",
        "altitude": "397",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "QRR": {
        "name": "Warren Airport",
        "city": "",
        "country": "Australia",
        "iata": "QRR",
        "icao": "YWRN",
        "latitude": "-31.733299255371094",
        "longitude": "147.80299377441406",
        "altitude": "669",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SXE": {
        "name": "West Sale Airport",
        "city": "Sale",
        "country": "Australia",
        "iata": "SXE",
        "icao": "YWSL",
        "latitude": "-38.090827",
        "longitude": "146.965335",
        "altitude": "72",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WWY": {
        "name": "West Wyalong Airport",
        "city": "West Wyalong",
        "country": "Australia",
        "iata": "WWY",
        "icao": "YWWL",
        "latitude": "-33.9371986389",
        "longitude": "147.190994263",
        "altitude": "859",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "NGA": {
        "name": "Young Airport",
        "city": "",
        "country": "Australia",
        "iata": "NGA",
        "icao": "YYNG",
        "latitude": "-34.25559997558594",
        "longitude": "148.2480010986328",
        "altitude": "1267",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "LHK": {
        "name": "Guangzhou MR Air Base",
        "city": "Guanghua",
        "country": "China",
        "iata": "LHK",
        "icao": "ZHGH",
        "latitude": "32.389400482177734",
        "longitude": "111.69499969482422",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WDS": {
        "name": "Shiyan Wudangshan Airport",
        "city": "Shiyan",
        "country": "China",
        "iata": "WDS",
        "icao": "ZHSY",
        "latitude": "32.591667",
        "longitude": "110.907778",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HTT": {
        "name": "Huatugou Airport",
        "city": "Mengnai",
        "country": "China",
        "iata": "HTT",
        "icao": "ZLHX",
        "latitude": "38.201984",
        "longitude": "90.841495",
        "altitude": "2945",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "UUN": {
        "name": "Baruun Urt Airport",
        "city": "",
        "country": "Mongolia",
        "iata": "UUN",
        "icao": "ZMBU",
        "latitude": "46.660301208496094",
        "longitude": "113.28500366210938",
        "altitude": "3205",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BFU": {
        "name": "Bengbu Airport",
        "city": "Bengbu",
        "country": "China",
        "iata": "BFU",
        "icao": "ZSBB",
        "latitude": "32.8477333333",
        "longitude": "117.320244444",
        "altitude": "100",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "RUG": {
        "name": "Rugao Air Base",
        "city": "Rugao",
        "country": "China",
        "iata": "RUG",
        "icao": "ZSRG",
        "latitude": "32.25788497924805",
        "longitude": "120.50165557861328",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "WHU": {
        "name": "Wuhu Air Base",
        "city": "Wuhu",
        "country": "China",
        "iata": "WHU",
        "icao": "ZSWU",
        "latitude": "31.3906",
        "longitude": "118.408997",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "SXJ": {
        "name": "Shanshan Airport",
        "city": "Shanshan",
        "country": "China",
        "iata": "SXJ",
        "icao": "ZWSS",
        "latitude": "42.91170120239258",
        "longitude": "90.24749755859375",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YKH": {
        "name": "Yingkou Lanqi Airport",
        "city": "Yingkou",
        "country": "China",
        "iata": "YKH",
        "icao": "ZYYK",
        "latitude": "40.542524",
        "longitude": "122.3586",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "BQG": {
        "name": "Bogorodskoye Airport",
        "city": "Bogorodskoe",
        "country": "Russia",
        "iata": "BQG",
        "icao": "UHNB",
        "latitude": "52.38",
        "longitude": "140.448",
        "altitude": "0",
        "timezone": "11",
        "dst": "U",
        "tz_name": null
    },
    "HYD": {
        "name": "Rajiv Gandhi International Airport",
        "city": "Hyderabad",
        "country": "India",
        "iata": "HYD",
        "icao": "VOHS",
        "latitude": "17.2403",
        "longitude": "78.4294",
        "altitude": null,
        "timezone": "N",
        "dst": "5.5",
        "tz_name": "Asia/Calcutta"
    },
    "PKO": {
        "name": "Parakou Airport",
        "city": "Parakou",
        "country": "Benin",
        "iata": "PKO",
        "icao": "DBBP",
        "latitude": "9.35768985748291",
        "longitude": "2.609679937362671",
        "altitude": "1266",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "KDC": {
        "name": "Kandi Airport",
        "city": "Kandi",
        "country": "Benin",
        "iata": "KDC",
        "icao": "DBBK",
        "latitude": "11.14479",
        "longitude": "2.940381",
        "altitude": "951",
        "timezone": "1",
        "dst": "U",
        "tz_name": null
    },
    "HMG": {
        "name": "Hermannsburg Airport",
        "city": "Hermannsburg",
        "country": "Australia",
        "iata": "HMG",
        "icao": "YHMB",
        "latitude": "-23.93000030517578",
        "longitude": "132.80499267578125",
        "altitude": "593",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": null
    },
    "BIB": {
        "name": "Baidoa Airport",
        "city": "Baidoa",
        "country": "Somalia",
        "iata": "BIB",
        "icao": "HCMB",
        "latitude": "3.102220058441162",
        "longitude": "43.62860107421875",
        "altitude": "1820",
        "timezone": "3",
        "dst": "U",
        "tz_name": null
    },
    "KCS": {
        "name": "Kings Creek Airport",
        "city": "Petermann",
        "country": "Australia",
        "iata": "KCS",
        "icao": "YKCS",
        "latitude": "-24.42329978942871",
        "longitude": "131.8350067138672",
        "altitude": "615",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": null
    },
    "BMR": {
        "name": "Baltrum Airport",
        "city": "Baltrum",
        "country": "Germany",
        "iata": "BMR",
        "icao": "EDWZ",
        "latitude": "53.72472381591797",
        "longitude": "7.373332977294922",
        "altitude": "7",
        "timezone": "1",
        "dst": "E",
        "tz_name": null
    },
    "MVW": {
        "name": "Skagit Regional Airport",
        "city": "Skagit",
        "country": "United States",
        "iata": "MVW",
        "icao": "KBVS",
        "latitude": "48.4709014893",
        "longitude": "-122.42099762",
        "altitude": "144",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "GOO": {
        "name": "Goondiwindi Airport",
        "city": "Goondiwindi",
        "country": "Australia",
        "iata": "GOO",
        "icao": "YGDI",
        "latitude": "-28.521400451660156",
        "longitude": "150.32000732421875",
        "altitude": "714",
        "timezone": "10",
        "dst": "O",
        "tz_name": null
    },
    "APT": {
        "name": "Marion County Brown Field",
        "city": "Jasper",
        "country": "United States",
        "iata": "APT",
        "icao": "KAPT",
        "latitude": "35.060699",
        "longitude": "-85.585297",
        "altitude": "641",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "DCU": {
        "name": "Pryor Field Regional Airport",
        "city": "Decatur",
        "country": "United States",
        "iata": "DCU",
        "icao": "KDCU",
        "latitude": "34.652698516799994",
        "longitude": "-86.94539642330001",
        "altitude": "592",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "GLW": {
        "name": "Glasgow Municipal Airport",
        "city": "Glasgow",
        "country": "United States",
        "iata": "GLW",
        "icao": "KGLW",
        "latitude": "37.03179932",
        "longitude": "-85.9536972",
        "altitude": "716",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "DKV": {
        "name": "Docker River Airport",
        "city": "Docker River",
        "country": "Australia",
        "iata": "DKV",
        "icao": "YDVR",
        "latitude": "-24.860000610351562",
        "longitude": "129.07000732421875",
        "altitude": "589",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": null
    },
    "RNZ": {
        "name": "Jasper County Airport",
        "city": "Rensselaer",
        "country": "United States",
        "iata": "RNZ",
        "icao": "KRZL",
        "latitude": "40.9478988647",
        "longitude": "-87.1826019287",
        "altitude": "698",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "SBT": {
        "name": "Sabetta International Airport",
        "city": "Sabetta",
        "country": "Russia",
        "iata": "SBT",
        "icao": "USDA",
        "latitude": "71.219167",
        "longitude": "72.052222",
        "altitude": "46",
        "timezone": "5",
        "dst": "N",
        "tz_name": null
    },
    "AXF": {
        "name": "Alxa Left Banner Bayanhot Airport",
        "city": "Alxa Left Banner",
        "country": "China",
        "iata": "AXF",
        "icao": "ZBAL",
        "latitude": "38.74831",
        "longitude": "105.58858",
        "altitude": "4560",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "NIS": {
        "name": "Simberi Airport",
        "city": "Simberi Island",
        "country": "Papua New Guinea",
        "iata": "NIS",
        "icao": "AYSE",
        "latitude": "-2.66222222222",
        "longitude": "151.997777778",
        "altitude": "10",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "BUL": {
        "name": "Bulolo Airport",
        "city": "Bulolo",
        "country": "Papua New Guinea",
        "iata": "BUL",
        "icao": "AYBU",
        "latitude": "-7.216286671410001",
        "longitude": "146.649541855",
        "altitude": "2240",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "TBR": {
        "name": "Statesboro Bulloch County Airport",
        "city": "Statesboro",
        "country": "United States",
        "iata": "TBR",
        "icao": "KTBR",
        "latitude": "32.4827003479",
        "longitude": "-81.73690032959999",
        "altitude": "187",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "AMT": {
        "name": "Amata Airport",
        "city": "Amata",
        "country": "Australia",
        "iata": "AMT",
        "icao": "YAMT",
        "latitude": "-26.108299255371094",
        "longitude": "131.20700073242188",
        "altitude": "695",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": null
    },
    "EDD": {
        "name": "Erldunda Airport",
        "city": "Erldunda",
        "country": "Australia",
        "iata": "EDD",
        "icao": "YERL",
        "latitude": "-25.205799102800004",
        "longitude": "133.253997803",
        "altitude": "0",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": null
    },
    "FIK": {
        "name": "Finke Airport",
        "city": "Finke",
        "country": "Australia",
        "iata": "FIK",
        "icao": "YFNE",
        "latitude": "-25.5946998596",
        "longitude": "134.582992554",
        "altitude": "0",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": null
    },
    "JJG": {
        "name": "Humberto Ghizzo Bortoluzzi Regional Airport",
        "city": "Jaguaruna",
        "country": "Brazil",
        "iata": "JJG",
        "icao": "SBJA",
        "latitude": "-28.6753",
        "longitude": "-49.0596",
        "altitude": "120",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "WKI": {
        "name": "Hwange (Town) Airport",
        "city": "Hwange",
        "country": "Zimbabwe",
        "iata": "WKI",
        "icao": "FVWT",
        "latitude": "-18.362967",
        "longitude": "26.519791",
        "altitude": "2500",
        "timezone": "2",
        "dst": "N",
        "tz_name": null
    },
    "KLJ": {
        "name": "Klaipėda Airport",
        "city": "Klaipeda",
        "country": "Lithuania",
        "iata": "KLJ",
        "icao": "EYKL",
        "latitude": "55.7118988037",
        "longitude": "21.242799758900002",
        "altitude": "59",
        "timezone": "2",
        "dst": "E",
        "tz_name": null
    },
    "ETB": {
        "name": "West Bend Municipal Airport",
        "city": "WEST BEND",
        "country": "United States",
        "iata": "ETB",
        "icao": "KETB",
        "latitude": "43.4221992493",
        "longitude": "-88.1278991699",
        "altitude": "887",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "GLR": {
        "name": "Gaylord Regional Airport",
        "city": "GAYLORD",
        "country": "United States",
        "iata": "GLR",
        "icao": "KGLR",
        "latitude": "45.013500213600004",
        "longitude": "-84.7035980225",
        "altitude": "1328",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "AID": {
        "name": "Anderson Municipal Darlington Field",
        "city": "ANDERSON",
        "country": "United States",
        "iata": "AID",
        "icao": "KAID",
        "latitude": "40.10860061649999",
        "longitude": "-85.6129989624",
        "altitude": "919",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "QND": {
        "name": "Cenej Airport",
        "city": "Novi Sad",
        "country": "Serbia",
        "iata": "QND",
        "icao": "LYNS",
        "latitude": "45.385799",
        "longitude": "19.839199",
        "altitude": "266",
        "timezone": "1",
        "dst": "E",
        "tz_name": null
    },
    "GUU": {
        "name": "Grundarfjörður Airport",
        "city": "Grundarfjordur",
        "country": "Iceland",
        "iata": "GUU",
        "icao": "BIGF",
        "latitude": "64.99140167236328",
        "longitude": "-23.224700927734375",
        "altitude": "17",
        "timezone": "0",
        "dst": "U",
        "tz_name": null
    },
    "PCD": {
        "name": "Prairie Du Chien Municipal Airport",
        "city": "Prairie du Chien",
        "country": "United States",
        "iata": "PCD",
        "icao": "KPDC",
        "latitude": "43.019298553467",
        "longitude": "-91.12370300293",
        "altitude": "661",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "OTJ": {
        "name": "Otjiwarongo Airport",
        "city": "Otjiwarongo",
        "country": "Namibia",
        "iata": "OTJ",
        "icao": "FYOW",
        "latitude": "-20.434700012207",
        "longitude": "16.660800933838",
        "altitude": "4859",
        "timezone": "2",
        "dst": "U",
        "tz_name": null
    },
    "TSM": {
        "name": "Taos Regional Airport",
        "city": "Taos",
        "country": "United States",
        "iata": "TSM",
        "icao": "KSKX",
        "latitude": "36.45819855",
        "longitude": "-105.6719971",
        "altitude": "7095",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "RTN": {
        "name": "Raton Municipal-Crews Field",
        "city": "Raton",
        "country": "United States",
        "iata": "RTN",
        "icao": "KRTN",
        "latitude": "36.74150085",
        "longitude": "-104.5019989",
        "altitude": "6352",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "PPA": {
        "name": "Perry Lefors Field",
        "city": "Pampa",
        "country": "United States",
        "iata": "PPA",
        "icao": "KPPA",
        "latitude": "35.612998962402",
        "longitude": "-100.99600219727",
        "altitude": "3245",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "FLP": {
        "name": "Marion County Regional Airport",
        "city": "Flippin",
        "country": "United States",
        "iata": "FLP",
        "icao": "KFLP",
        "latitude": "36.29090118",
        "longitude": "-92.59030151",
        "altitude": "719",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "BGD": {
        "name": "Hutchinson County Airport",
        "city": "Borger",
        "country": "United States",
        "iata": "BGD",
        "icao": "KBGD",
        "latitude": "35.700901031499995",
        "longitude": "-101.393997192",
        "altitude": "3055",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "HLE": {
        "name": "St. Helena Airport",
        "city": "Longwood",
        "country": "United Kingdom",
        "iata": "HLE",
        "icao": "FHSH",
        "latitude": "-15.957725",
        "longitude": "-5.645943",
        "altitude": "1017",
        "timezone": "0",
        "dst": "U",
        "tz_name": null
    },
    "BNJ": {
        "name": "Bonn-Hangelar Airport",
        "city": "Sankt-Augustin",
        "country": "Germany",
        "iata": "BNJ",
        "icao": "EDKB",
        "latitude": "50.7688903809",
        "longitude": "7.16333293915",
        "altitude": "197",
        "timezone": "1",
        "dst": "E",
        "tz_name": null
    },
    "NGK": {
        "name": "Nogliki Airport",
        "city": "Nogliki",
        "country": "Russia",
        "iata": "NGK",
        "icao": "UHSN",
        "latitude": "51.780102",
        "longitude": "143.139008",
        "altitude": "109",
        "timezone": "11",
        "dst": "N",
        "tz_name": null
    },
    "SQA": {
        "name": "Santa Ynez Airport",
        "city": "Santa Ynez",
        "country": "United States",
        "iata": "SQA",
        "icao": "KIZA",
        "latitude": "34.60680008",
        "longitude": "-120.0759964",
        "altitude": "674",
        "timezone": "-8",
        "dst": "A",
        "tz_name": null
    },
    "HXD": {
        "name": "Delingha Airport",
        "city": "Haixi",
        "country": "China",
        "iata": "HXD",
        "icao": "ZLDL",
        "latitude": "37.125286",
        "longitude": "97.268658",
        "altitude": "9843",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "BAR": {
        "name": "Qionghai Bo'ao Airport",
        "city": "Qionghai",
        "country": "China",
        "iata": "BAR",
        "icao": "ZJQH",
        "latitude": "19.13824",
        "longitude": "110.454775",
        "altitude": "30",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "ZBO": {
        "name": "Bowen Airport",
        "city": "Bowen",
        "country": "Australia",
        "iata": "ZBO",
        "icao": "YBWN",
        "latitude": "-20.018299102783203",
        "longitude": "148.21499633789062",
        "altitude": "8",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "UCB": {
        "name": "Ulanqab Jining Airport",
        "city": "Wulanchabu",
        "country": "China",
        "iata": "UCB",
        "icao": "ZBUC",
        "latitude": "41.129722",
        "longitude": "113.108056",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "KEO": {
        "name": "Odienne Airport",
        "city": "Odienne",
        "country": "Cote d'Ivoire",
        "iata": "KEO",
        "icao": "DIOD",
        "latitude": "9.5",
        "longitude": "-7.566999912261963",
        "altitude": "1365",
        "timezone": "0",
        "dst": "U",
        "tz_name": null
    },
    "GII": {
        "name": "Siguiri Airport",
        "city": "Siguiri",
        "country": "Guinea",
        "iata": "GII",
        "icao": "GUSI",
        "latitude": "11.432999610901",
        "longitude": "-9.1669998168945",
        "altitude": "1296",
        "timezone": "0",
        "dst": "N",
        "tz_name": null
    },
    "NZE": {
        "name": "Nzérékoré Airport",
        "city": "Nzerekore",
        "country": "Guinea",
        "iata": "NZE",
        "icao": "GUNZ",
        "latitude": "7.8060193061829",
        "longitude": "-8.7017974853516",
        "altitude": "1657",
        "timezone": "0",
        "dst": "N",
        "tz_name": null
    },
    "OCM": {
        "name": "Boolgeeda",
        "city": "Brockman",
        "country": "Australia",
        "iata": "OCM",
        "icao": "YBGD",
        "latitude": "-22.54",
        "longitude": "117.275",
        "altitude": "1871",
        "timezone": "8",
        "dst": "O",
        "tz_name": null
    },
    "WGN": {
        "name": "Shaoyang Wugang Airport",
        "city": "Shaoyang",
        "country": "China",
        "iata": "WGN",
        "icao": "ZGSY",
        "latitude": "26.802",
        "longitude": "110.642",
        "altitude": "1444",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "TXF": {
        "name": "9 de Maio - Teixeira de Freitas Airport",
        "city": "Teixeira de Freitas",
        "country": "Brazil",
        "iata": "TXF",
        "icao": "SNTF",
        "latitude": "-17.524499893188",
        "longitude": "-39.66849899292",
        "altitude": "344",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "SPA": {
        "name": "Spartanburg Downtown Memorial Airport",
        "city": "Spartangurg",
        "country": "United States",
        "iata": "SPA",
        "icao": "KSPA",
        "latitude": "34.915699005127",
        "longitude": "-81.956497192383",
        "altitude": "801",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "BJW": {
        "name": "Bajawa Soa Airport",
        "city": "Bajawa",
        "country": "Indonesia",
        "iata": "BJW",
        "icao": "WATB",
        "latitude": "-8.70743498008",
        "longitude": "121.057426929",
        "altitude": "4326",
        "timezone": "8",
        "dst": "U",
        "tz_name": null
    },
    "NBN": {
        "name": "Annobón Airport",
        "city": "San Antonio de Pale",
        "country": "Equatorial Guinea",
        "iata": "NBN",
        "icao": "FGAB",
        "latitude": "-1.410277",
        "longitude": "5.621944",
        "altitude": "82",
        "timezone": "1",
        "dst": "N",
        "tz_name": null
    },
    "OLL": {
        "name": "Oyo Ollombo Airport",
        "city": "Oyo",
        "country": "Congo (Brazzaville)",
        "iata": "OLL",
        "icao": "FCOD",
        "latitude": "-1.226666",
        "longitude": "15.91",
        "altitude": "1073",
        "timezone": "1",
        "dst": "N",
        "tz_name": null
    },
    "PPF": {
        "name": "Tri-City Airport",
        "city": "Parsons",
        "country": "United States",
        "iata": "PPF",
        "icao": "KPPF",
        "latitude": "37.32989883",
        "longitude": "-95.5062027",
        "altitude": "900",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "PCQ": {
        "name": "Boun Neau Airport",
        "city": "Phongsaly",
        "country": "Laos",
        "iata": "PCQ",
        "icao": "VLFL",
        "latitude": "21.6475",
        "longitude": "101.9",
        "altitude": "3050",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "AYS": {
        "name": "Waycross Ware County Airport",
        "city": "Waycross",
        "country": "United States",
        "iata": "AYS",
        "icao": "KAYS",
        "latitude": "31.2490997314",
        "longitude": "-82.39550018310001",
        "altitude": "142",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "DSS": {
        "name": "Blaise Diagne International Airport",
        "city": "Diass",
        "country": "Senegal",
        "iata": "DSS",
        "icao": "GOBD",
        "latitude": "14.67",
        "longitude": "-17.073333",
        "altitude": "290",
        "timezone": "0",
        "dst": "N",
        "tz_name": null
    },
    "PMH": {
        "name": "Greater Portsmouth Regional Airport",
        "city": "Portsmouth",
        "country": "United States",
        "iata": "PMH",
        "icao": "KPMH",
        "latitude": "38.8404998779",
        "longitude": "-82.84729766850002",
        "altitude": "663",
        "timezone": "-5",
        "dst": "A",
        "tz_name": null
    },
    "NAC": {
        "name": "Naracoorte Airport",
        "city": "Naracoorte",
        "country": "Australia",
        "iata": "NAC",
        "icao": "YNRC",
        "latitude": "-36.98529815673828",
        "longitude": "140.72500610351562",
        "altitude": "169",
        "timezone": "9.5",
        "dst": "O",
        "tz_name": null
    },
    "PGZ": {
        "name": "Ponta Grossa Airport - Comandante Antonio Amilton Beraldo",
        "city": "Ponta Grossa",
        "country": "Brazil",
        "iata": "PGZ",
        "icao": "SSZW",
        "latitude": "-25.1847",
        "longitude": "-50.1441",
        "altitude": "2588",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "PQM": {
        "name": "Palenque International Airport",
        "city": "Palenque",
        "country": "Mexico",
        "iata": "PQM",
        "icao": "MMPQ",
        "latitude": "17.533153",
        "longitude": "-92.015484",
        "altitude": "200",
        "timezone": "-5",
        "dst": "U",
        "tz_name": null
    },
    "CUD": {
        "name": "Caloundra Airport",
        "city": "Caloundra",
        "country": "Australia",
        "iata": "CUD",
        "icao": "YCDR",
        "latitude": "-26.799999237060547",
        "longitude": "153.10000610351562",
        "altitude": "12",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "CLP": {
        "name": "Clarks Point Airport",
        "city": "Clarks Point",
        "country": "United States",
        "iata": "CLP",
        "icao": "PFCL",
        "latitude": "58.83369827",
        "longitude": "-158.529007",
        "altitude": "80",
        "timezone": "-9",
        "dst": "A",
        "tz_name": null
    },
    "JOJ": {
        "name": "Doris Lake",
        "city": "Doris Lake",
        "country": "Canada",
        "iata": "JOJ",
        "icao": "CDL7",
        "latitude": "68.1252746582",
        "longitude": "-106.585281372",
        "altitude": "50",
        "timezone": "-7",
        "dst": "A",
        "tz_name": null
    },
    "ECI": {
        "name": "Costa Esmeralda Airport",
        "city": "Tola",
        "country": "Nicaragua",
        "iata": "ECI",
        "icao": "MNCE",
        "latitude": "11.427542",
        "longitude": "-86.033361",
        "altitude": "84",
        "timezone": "-6",
        "dst": "N",
        "tz_name": null
    },
    "QGQ": {
        "name": "Attu Heliport",
        "city": "Attu",
        "country": "Greenland",
        "iata": "QGQ",
        "icao": "BGAT",
        "latitude": "67.9406628551",
        "longitude": "-53.6218696833",
        "altitude": "32",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "QPW": {
        "name": "Kangaatsiaq Heliport",
        "city": "Kangaatsiaq",
        "country": "Greenland",
        "iata": "QPW",
        "icao": "BGKA",
        "latitude": "68.3126574861",
        "longitude": "-53.4602075815",
        "altitude": "17",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "QJE": {
        "name": "Kitsissuarsuit Heliport",
        "city": "Kitsissuarsuit",
        "country": "Greenland",
        "iata": "QJE",
        "icao": "BGKT",
        "latitude": "68.85792599759999",
        "longitude": "-53.123295307199996",
        "altitude": "18",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "XIQ": {
        "name": "Ilimanaq Heliport",
        "city": "Ilimanaq",
        "country": "Greenland",
        "iata": "XIQ",
        "icao": "BGIL",
        "latitude": "69.0809150007",
        "longitude": "-51.1143153906",
        "altitude": "52",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "QQT": {
        "name": "Qeqertaq Heliport",
        "city": "Qeqertaq",
        "country": "Greenland",
        "iata": "QQT",
        "icao": "BGQE",
        "latitude": "69.9958611087",
        "longitude": "-51.3009166718",
        "altitude": "69",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "QJI": {
        "name": "Ikamiut Heliport",
        "city": "Ikamiut",
        "country": "Greenland",
        "iata": "QJI",
        "icao": "BGIT",
        "latitude": "68.6342530984",
        "longitude": "-51.8322622776",
        "altitude": "49",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "QFG": {
        "name": "Eqalugaarsuit Heliport",
        "city": "Eqalugaarsuit",
        "country": "Greenland",
        "iata": "QFG",
        "icao": "BGET",
        "latitude": "60.6197196536",
        "longitude": "-45.9140619636",
        "altitude": "9",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "QRY": {
        "name": "Ikerassaarsuk Heliport",
        "city": "Ikerasaarsuk",
        "country": "Greenland",
        "iata": "QRY",
        "icao": "BGIK",
        "latitude": "68.14088100629999",
        "longitude": "-53.4414589405",
        "altitude": "165",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "NPR": {
        "name": "Novo Progresso Airport",
        "city": "Novo Progresso",
        "country": "Brazil",
        "iata": "NPR",
        "icao": "SJNP",
        "latitude": "-7.125833",
        "longitude": "-55.400833",
        "altitude": "794",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "SMT": {
        "name": "Adolino Bedin Regional Airport",
        "city": "Sorriso",
        "country": "Brazil",
        "iata": "SMT",
        "icao": "SBSO",
        "latitude": "-12.479177",
        "longitude": "-55.672341",
        "altitude": "1266",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "ENO": {
        "name": "Encarnación Airport",
        "city": "Encarnacion",
        "country": "Paraguay",
        "iata": "ENO",
        "icao": "SGEN",
        "latitude": "-27.227366",
        "longitude": "-55.837495",
        "altitude": "659",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "UZR": {
        "name": "Urzhar Airport",
        "city": "Urzhar",
        "country": "Kazakhstan",
        "iata": "UZR",
        "icao": "UASU",
        "latitude": "47.09115",
        "longitude": "81.66521",
        "altitude": "0",
        "timezone": "6",
        "dst": "U",
        "tz_name": null
    },
    "NYR": {
        "name": "Nyurba Airport",
        "city": "Nyurba",
        "country": "Russia",
        "iata": "NYR",
        "icao": "UENN",
        "latitude": "63.294998",
        "longitude": "118.336998",
        "altitude": "394",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "SUK": {
        "name": "Sakkyryr Airport",
        "city": "Batagay-Alyta",
        "country": "Russia",
        "iata": "SUK",
        "icao": "UEBS",
        "latitude": "67.792",
        "longitude": "130.394",
        "altitude": "1686",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "UKG": {
        "name": "Ust-Kuyga Airport",
        "city": "Ust-Kuyga",
        "country": "Russia",
        "iata": "UKG",
        "icao": "UEBT",
        "latitude": "70.011001586914",
        "longitude": "135.64500427246",
        "altitude": "315",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "VHV": {
        "name": "Verkhnevilyuisk Airport",
        "city": "Verkhnevilyuysk",
        "country": "Russia",
        "iata": "VHV",
        "icao": "UENI",
        "latitude": "63.458057403564",
        "longitude": "120.26916503906",
        "altitude": "411",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "DEE": {
        "name": "Mendeleyevo Airport",
        "city": "Yuzhno-Kurilsk",
        "country": "Russia",
        "iata": "DEE",
        "icao": "UHSM",
        "latitude": "43.9584007263",
        "longitude": "145.682998657",
        "altitude": "584",
        "timezone": "11",
        "dst": "N",
        "tz_name": null
    },
    "EKS": {
        "name": "Shakhtyorsk Airport",
        "city": "Shakhtyorsk",
        "country": "Russia",
        "iata": "EKS",
        "icao": "UHSK",
        "latitude": "49.1903",
        "longitude": "142.082993",
        "altitude": "50",
        "timezone": "11",
        "dst": "N",
        "tz_name": null
    },
    "TLK": {
        "name": "Talakan Airport",
        "city": "Talakan",
        "country": "Russia",
        "iata": "TLK",
        "icao": "UECT",
        "latitude": "59.876389",
        "longitude": "111.044444",
        "altitude": "1329",
        "timezone": "9",
        "dst": "U",
        "tz_name": null
    },
    "RDB": {
        "name": "Red Dog Airport",
        "city": "Red Dog",
        "country": "United States",
        "iata": "RDB",
        "icao": "PADG",
        "latitude": "68.03209686279999",
        "longitude": "-162.899002075",
        "altitude": "969",
        "timezone": "-9",
        "dst": "A",
        "tz_name": null
    },
    "ION": {
        "name": "Impfondo Airport",
        "city": "Impfondo",
        "country": "Congo (Brazzaville)",
        "iata": "ION",
        "icao": "FCOI",
        "latitude": "1.6169999837875366",
        "longitude": "18.066999435424805",
        "altitude": "1099",
        "timezone": "1",
        "dst": "N",
        "tz_name": null
    },
    "EBH": {
        "name": "El Bayadh Airport",
        "city": "El Bayadh",
        "country": "Algeria",
        "iata": "EBH",
        "icao": "DAOY",
        "latitude": "33.721666666699996",
        "longitude": "1.0925",
        "altitude": "4493",
        "timezone": "1",
        "dst": "N",
        "tz_name": null
    },
    "ZIS": {
        "name": "Alzintan Airport",
        "city": "Zintan",
        "country": "Libya",
        "iata": "ZIS",
        "icao": "HLZN",
        "latitude": "31.774878",
        "longitude": "12.25006",
        "altitude": "2080",
        "timezone": "2",
        "dst": "N",
        "tz_name": null
    },
    "MYZ": {
        "name": "Monkey Bay Airport",
        "city": "Monkey Bay",
        "country": "Malawi",
        "iata": "MYZ",
        "icao": "FWMY",
        "latitude": "-14.083600044299999",
        "longitude": "34.9197006226",
        "altitude": "1580",
        "timezone": "2",
        "dst": "N",
        "tz_name": null
    },
    "BCU": {
        "name": "Sir Abubakar Tafawa Balewa International Airport",
        "city": "Bauchi",
        "country": "Nigeria",
        "iata": "BCU",
        "icao": "DNBC",
        "latitude": "10.482833",
        "longitude": "9.744",
        "altitude": "1965",
        "timezone": "1",
        "dst": "N",
        "tz_name": null
    },
    "GMO": {
        "name": "Gombe Lawanti International Airport",
        "city": "Gombe",
        "country": "Nigeria",
        "iata": "GMO",
        "icao": "DNGO",
        "latitude": "10.298333333299999",
        "longitude": "10.896388888899999",
        "altitude": "1590",
        "timezone": "1",
        "dst": "N",
        "tz_name": null
    },
    "KDA": {
        "name": "Kolda North Airport",
        "city": "Kolda",
        "country": "Senegal",
        "iata": "KDA",
        "icao": "GODK",
        "latitude": "12.898500442504883",
        "longitude": "-14.968099594116211",
        "altitude": "33",
        "timezone": "0",
        "dst": "N",
        "tz_name": null
    },
    "SHO": {
        "name": "King Mswati III International Airport",
        "city": "Manzini",
        "country": "Swaziland",
        "iata": "SHO",
        "icao": "FDSK",
        "latitude": "-26.358611",
        "longitude": "31.716944",
        "altitude": "1092",
        "timezone": "2",
        "dst": "N",
        "tz_name": null
    },
    "KIY": {
        "name": "Kilwa Masoko Airport",
        "city": "Kilwa Masoko",
        "country": "Tanzania",
        "iata": "KIY",
        "icao": "HTKI",
        "latitude": "-8.91123",
        "longitude": "39.508619",
        "altitude": "50",
        "timezone": "3",
        "dst": "U",
        "tz_name": null
    },
    "QSI": {
        "name": "Moshi Airport",
        "city": "Moshi",
        "country": "Tanzania",
        "iata": "QSI",
        "icao": "HTMS",
        "latitude": "-3.3633298873901367",
        "longitude": "37.326900482177734",
        "altitude": "2801",
        "timezone": "3",
        "dst": "N",
        "tz_name": null
    },
    "MNS": {
        "name": "Mansa Airport",
        "city": "Mansa",
        "country": "Zambia",
        "iata": "MNS",
        "icao": "FLMA",
        "latitude": "-11.13700008392334",
        "longitude": "28.872600555419922",
        "altitude": "4101",
        "timezone": "2",
        "dst": "N",
        "tz_name": null
    },
    "GZI": {
        "name": "Ghazni Airport",
        "city": "Ghazni",
        "country": "Afghanistan",
        "iata": "GZI",
        "icao": "OAGN",
        "latitude": "33.5312004089",
        "longitude": "68.412902832",
        "altitude": "7150",
        "timezone": "4.5",
        "dst": "N",
        "tz_name": null
    },
    "DBC": {
        "name": "Baicheng Chang'an Airport",
        "city": "Baicheng",
        "country": "China",
        "iata": "DBC",
        "icao": "ZYBA",
        "latitude": "45.505278",
        "longitude": "123.019722",
        "altitude": "480",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "LNL": {
        "name": "Longnan Chengzhou Airport",
        "city": "Longnan",
        "country": "China",
        "iata": "LNL",
        "icao": "ZLLN",
        "latitude": "33.788",
        "longitude": "105.797",
        "altitude": "3707",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "SQD": {
        "name": "Shangrao Sanqingshan Airport",
        "city": "Shangrao",
        "country": "China",
        "iata": "SQD",
        "icao": "ZSSR",
        "latitude": "28.3797",
        "longitude": "117.9643",
        "altitude": "340",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "YSQ": {
        "name": "Songyuan Chaganhu Airport",
        "city": "Songyuan",
        "country": "China",
        "iata": "YSQ",
        "icao": "ZYSQ",
        "latitude": "44.938114",
        "longitude": "124.550178",
        "altitude": "459",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "JSJ": {
        "name": "Jiansanjiang Airport",
        "city": "Jiansanjiang",
        "country": "China",
        "iata": "JSJ",
        "icao": "ZYJS",
        "latitude": "47.11",
        "longitude": "132.660278",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "WMT": {
        "name": "Zunyi Maotai Airport",
        "city": "Zunyi",
        "country": "China",
        "iata": "WMT",
        "icao": "ZUMT",
        "latitude": "27.81638",
        "longitude": "106.33268",
        "altitude": "4068",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "LLB": {
        "name": "Libo Airport",
        "city": "Libo",
        "country": "China",
        "iata": "LLB",
        "icao": "ZULB",
        "latitude": "25.4525",
        "longitude": "107.961667",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "CDE": {
        "name": "Chengde Puning Airport",
        "city": "Chengde",
        "country": "China",
        "iata": "CDE",
        "icao": "ZBCD",
        "latitude": "41.1225",
        "longitude": "118.073889",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "DTU": {
        "name": "Wudalianchi Dedu Airport",
        "city": "Wudalianchi",
        "country": "China",
        "iata": "DTU",
        "icao": "ZYDU",
        "latitude": "48.445",
        "longitude": "126.133",
        "altitude": "984",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "EJN": {
        "name": "Ejin Banner-Taolai Airport",
        "city": "Ejin Banner",
        "country": "China",
        "iata": "EJN",
        "icao": "ZBEN",
        "latitude": "42.0155",
        "longitude": "101.0005",
        "altitude": "3077",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "RHT": {
        "name": "Alxa Right Banner Badanjilin Airport",
        "city": "Alxa Right Banner",
        "country": "China",
        "iata": "RHT",
        "icao": "ZBAR",
        "latitude": "39.225",
        "longitude": "101.546",
        "altitude": "4659",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "HUO": {
        "name": "Holingol Huolinhe Airport",
        "city": "Holingol",
        "country": "China",
        "iata": "HUO",
        "icao": "ZBHZ",
        "latitude": "45.487222",
        "longitude": "119.407222",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "GMQ": {
        "name": "Golog Maqin Airport",
        "city": "Golog",
        "country": "China",
        "iata": "GMQ",
        "icao": "ZLGL",
        "latitude": "34.418066",
        "longitude": "100.301144",
        "altitude": "12426",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "QSZ": {
        "name": "Yeerqiang Airport",
        "city": "Yarkant",
        "country": "China",
        "iata": "QSZ",
        "icao": "ZWSC",
        "latitude": "38.281055",
        "longitude": "77.075192",
        "altitude": "4232",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "TEI": {
        "name": "Tezu Airport",
        "city": "Tezu",
        "country": "India",
        "iata": "TEI",
        "icao": "VETJ",
        "latitude": "27.9412002563",
        "longitude": "96.1343994141",
        "altitude": "800",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": null
    },
    "CWJ": {
        "name": "Cangyuan Washan Airport",
        "city": "Cangyuan",
        "country": "China",
        "iata": "CWJ",
        "icao": "ZPCW",
        "latitude": "23.273889",
        "longitude": "99.373611",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "JMJ": {
        "name": "Lancang Jingmai Airport",
        "city": "Lancang Lahu",
        "country": "China",
        "iata": "JMJ",
        "icao": "ZPJM",
        "latitude": "22.415833",
        "longitude": "99.786389",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "NLH": {
        "name": "Ninglang Luguhu Airport",
        "city": "Ninglang",
        "country": "China",
        "iata": "NLH",
        "icao": "ZPNL",
        "latitude": "27.5403",
        "longitude": "100.7593",
        "altitude": "10804",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "PBQ": {
        "name": "Pimenta Bueno Airport",
        "city": "Pimenta Bueno",
        "country": "Brazil",
        "iata": "PBQ",
        "icao": "SWPM",
        "latitude": "-11.641599655151367",
        "longitude": "-61.179100036621094",
        "altitude": "682",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "WUT": {
        "name": "Xinzhou Wutaishan Airport",
        "city": "Xinzhou",
        "country": "China",
        "iata": "WUT",
        "icao": "ZBXZ",
        "latitude": "38.597456",
        "longitude": "112.969173",
        "altitude": "2527",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "NZL": {
        "name": "Chengjisihan Airport",
        "city": "Zhalantun",
        "country": "China",
        "iata": "NZL",
        "icao": "ZBZL",
        "latitude": "47.865833",
        "longitude": "122.767503",
        "altitude": "928",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "AIP": {
        "name": "Adampur Airport",
        "city": "Adampur",
        "country": "India",
        "iata": "AIP",
        "icao": "VIAX",
        "latitude": "31.4338",
        "longitude": "75.758797",
        "altitude": "775",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": null
    },
    "VDY": {
        "name": "Vijayanagar Aerodrome (JSW)",
        "city": "Toranagallu",
        "country": "India",
        "iata": "VDY",
        "icao": "VOJV",
        "latitude": "15.1749666667",
        "longitude": "76.6349472222",
        "altitude": "1670",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": null
    },
    "SAG": {
        "name": "Shirdi Airport",
        "city": "Shirdi",
        "country": "India",
        "iata": "SAG",
        "icao": "VASD",
        "latitude": "19.688611",
        "longitude": "74.378889",
        "altitude": "1926",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": null
    },
    "PYB": {
        "name": "Jeypore Airport",
        "city": "Jeypore",
        "country": "India",
        "iata": "PYB",
        "icao": "VEJP",
        "latitude": "18.8799991607666",
        "longitude": "82.552001953125",
        "altitude": "1952",
        "timezone": "5.5",
        "dst": "N",
        "tz_name": null
    },
    "SUP": {
        "name": "Trunojoyo Airport",
        "city": "Sumenep",
        "country": "Indonesia",
        "iata": "SUP",
        "icao": "WART",
        "latitude": "-7.0242",
        "longitude": "113.89023",
        "altitude": "10",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "RKO": {
        "name": "Rokot Airport",
        "city": "Sipora",
        "country": "Indonesia",
        "iata": "RKO",
        "icao": "WIBR",
        "latitude": "-2.0991199016571045",
        "longitude": "99.70069885253906",
        "altitude": "1",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "PPR": {
        "name": "Pasir Pangaraan Airport",
        "city": "Pasir Pangaraian",
        "country": "Indonesia",
        "iata": "PPR",
        "icao": "WIDE",
        "latitude": "0.8454310297966003",
        "longitude": "100.37000274658203",
        "altitude": "151",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "TJB": {
        "name": "Sei Bati Airport",
        "city": "Tanjung Balai Karimun",
        "country": "Indonesia",
        "iata": "TJB",
        "icao": "WIBT",
        "latitude": "1.0527",
        "longitude": "103.3931",
        "altitude": "33",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "KRC": {
        "name": "Departi Parbo Airport",
        "city": "Kerinci Regency",
        "country": "Indonesia",
        "iata": "KRC",
        "icao": "WIPH",
        "latitude": "-2.093",
        "longitude": "101.4683",
        "altitude": "2600",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "NRE": {
        "name": "Namrole Airport",
        "city": "Buru Island",
        "country": "Indonesia",
        "iata": "NRE",
        "icao": "WAPG",
        "latitude": "-3.8548",
        "longitude": "126.7012",
        "altitude": "31",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "NAM": {
        "name": "Namlea Airport",
        "city": "Namlea",
        "country": "Indonesia",
        "iata": "NAM",
        "icao": "WAPR",
        "latitude": "-3.235569953918457",
        "longitude": "127.0999984741211",
        "altitude": "41",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "DOB": {
        "name": "Rar Gwamar Airport",
        "city": "Dobo",
        "country": "Indonesia",
        "iata": "DOB",
        "icao": "WAPD",
        "latitude": "-5.7722201347399995",
        "longitude": "134.212005615",
        "altitude": "61",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "SQN": {
        "name": "Emalamo Sanana Airport",
        "city": "Sanana",
        "country": "Indonesia",
        "iata": "SQN",
        "icao": "WAPN",
        "latitude": "-2.080509901046753",
        "longitude": "125.96700286865234",
        "altitude": "39",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "AYW": {
        "name": "Ayawasi Airport",
        "city": "Ayawasi",
        "country": "Indonesia",
        "iata": "AYW",
        "icao": "WASA",
        "latitude": "-1.1593",
        "longitude": "132.4633",
        "altitude": "1560",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "BYQ": {
        "name": "Bunyu Airport",
        "city": "Bunyu",
        "country": "Indonesia",
        "iata": "BYQ",
        "icao": "WALV",
        "latitude": "3.45571994781",
        "longitude": "117.866996765",
        "altitude": "118",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "UOL": {
        "name": "Buol Airport",
        "city": "Buol",
        "country": "Indonesia",
        "iata": "UOL",
        "icao": "WAMY",
        "latitude": "1.1027",
        "longitude": "121.4141",
        "altitude": "27",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "RAQ": {
        "name": "Sugimanuru Airport",
        "city": "Raha",
        "country": "Indonesia",
        "iata": "RAQ",
        "icao": "WAWR",
        "latitude": "-4.760557",
        "longitude": "122.569371",
        "altitude": "132",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "JSK": {
        "name": "Jask Airport",
        "city": "Jask",
        "country": "Iran",
        "iata": "JSK",
        "icao": "OIZJ",
        "latitude": "25.653601",
        "longitude": "57.799301",
        "altitude": "19",
        "timezone": "3.5",
        "dst": "E",
        "tz_name": null
    },
    "ZBY": {
        "name": "Sayaboury Airport",
        "city": "Sainyabuli",
        "country": "Laos",
        "iata": "ZBY",
        "icao": "VLSB",
        "latitude": "19.2436",
        "longitude": "101.7093",
        "altitude": "962",
        "timezone": "7",
        "dst": "N",
        "tz_name": null
    },
    "BGL": {
        "name": "Baglung Airport",
        "city": "Baglung",
        "country": "Nepal",
        "iata": "BGL",
        "icao": "VNBL",
        "latitude": "28.212799072265625",
        "longitude": "83.66629791259766",
        "altitude": "3320",
        "timezone": "5.75",
        "dst": "N",
        "tz_name": null
    },
    "RJB": {
        "name": "Rajbiraj Airport",
        "city": "Rajbiraj",
        "country": "Nepal",
        "iata": "RJB",
        "icao": "VNRB",
        "latitude": "26.510066",
        "longitude": "86.733902",
        "altitude": "250",
        "timezone": "5.45",
        "dst": "N",
        "tz_name": null
    },
    "DQM": {
        "name": "Duqm International Airport",
        "city": "Duqm",
        "country": "Oman",
        "iata": "DQM",
        "icao": "OODQ",
        "latitude": "19.501944",
        "longitude": "57.634167",
        "altitude": "364",
        "timezone": "4",
        "dst": "N",
        "tz_name": null
    },
    "CKI": {
        "name": "Croker Island Airport",
        "city": "Croker Island",
        "country": "Australia",
        "iata": "CKI",
        "icao": "YCKI",
        "latitude": "-11.164999961853027",
        "longitude": "132.48300170898438",
        "altitude": "16",
        "timezone": "9.5",
        "dst": "N",
        "tz_name": null
    },
    "BYP": {
        "name": "Barimunya Airport",
        "city": "Barimunya",
        "country": "Australia",
        "iata": "BYP",
        "icao": "YBRY",
        "latitude": "-22.673900604248",
        "longitude": "119.16600036621",
        "altitude": "2082",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "LUC": {
        "name": "Laucala Island Airport",
        "city": "Laucala",
        "country": "Fiji",
        "iata": "LUC",
        "icao": "NFNH",
        "latitude": "-16.74810028076172",
        "longitude": "-179.66700744628906",
        "altitude": "10",
        "timezone": "12",
        "dst": "U",
        "tz_name": null
    },
    "YAS": {
        "name": "Yasawa Island Airport",
        "city": "Yasawa Island",
        "country": "Fiji",
        "iata": "YAS",
        "icao": "NFSW",
        "latitude": "-16.758899688720703",
        "longitude": "177.5449981689453",
        "altitude": "37",
        "timezone": "12",
        "dst": "Z",
        "tz_name": null
    },
    "WIK": {
        "name": "Waiheke Reeve Airport",
        "city": "Waiheke Island",
        "country": "New Zealand",
        "iata": "WIK",
        "icao": "NZKE",
        "latitude": "-36.80889892578125",
        "longitude": "175.08599853515625",
        "altitude": "226",
        "timezone": "12",
        "dst": "Z",
        "tz_name": null
    },
    "DEX": {
        "name": "Nop Goliat Airport",
        "city": "Dekai",
        "country": "Indonesia",
        "iata": "DEX",
        "icao": "WAVD",
        "latitude": "-4.8557",
        "longitude": "139.482006",
        "altitude": "198",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "NAU": {
        "name": "Napuka Island Airport",
        "city": "Napuka",
        "country": "French Polynesia",
        "iata": "NAU",
        "icao": "NTGN",
        "latitude": "-14.176799774169922",
        "longitude": "-141.26699829101562",
        "altitude": "7",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "FAC": {
        "name": "Faaite Airport",
        "city": "Faaite",
        "country": "French Polynesia",
        "iata": "FAC",
        "icao": "NTKF",
        "latitude": "-16.68670082092285",
        "longitude": "-145.32899475097656",
        "altitude": "7",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "NUK": {
        "name": "Nukutavake Airport",
        "city": "Nukutavake",
        "country": "French Polynesia",
        "iata": "NUK",
        "icao": "NTGW",
        "latitude": "-19.28499984741211",
        "longitude": "-138.77200317382812",
        "altitude": "17",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "VHZ": {
        "name": "Vahitahi Airport",
        "city": "Vahitahi",
        "country": "French Polynesia",
        "iata": "VHZ",
        "icao": "NTUV",
        "latitude": "-18.780000686645508",
        "longitude": "-138.85299682617188",
        "altitude": "9",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "HHZ": {
        "name": "Hikueru Atoll Airport",
        "city": "Hikueru",
        "country": "French Polynesia",
        "iata": "HHZ",
        "icao": "NTGH",
        "latitude": "-17.544700622558594",
        "longitude": "-142.61399841308594",
        "altitude": "5",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "RRR": {
        "name": "Raroia Airport",
        "city": "Raroia",
        "country": "French Polynesia",
        "iata": "RRR",
        "icao": "NTKO",
        "latitude": "-16.045",
        "longitude": "-142.476944",
        "altitude": "18",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "KHZ": {
        "name": "Kauehi Airport",
        "city": "Kauehi",
        "country": "French Polynesia",
        "iata": "KHZ",
        "icao": "NTKA",
        "latitude": "-15.780799865722656",
        "longitude": "-145.12399291992188",
        "altitude": "13",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "TKV": {
        "name": "Tatakoto Airport",
        "city": "Tatakoto",
        "country": "French Polynesia",
        "iata": "TKV",
        "icao": "NTGO",
        "latitude": "-17.355300903320312",
        "longitude": "-138.44500732421875",
        "altitude": "12",
        "timezone": "-10",
        "dst": "N",
        "tz_name": null
    },
    "TDS": {
        "name": "Sasereme Airport",
        "city": "Sasereme",
        "country": "Papua New Guinea",
        "iata": "TDS",
        "icao": "AYSS",
        "latitude": "-7.6217",
        "longitude": "142.868",
        "altitude": "121",
        "timezone": "10",
        "dst": "N",
        "tz_name": null
    },
    "TMH": {
        "name": "Tanah Merah Airport",
        "city": "Boven Digoel",
        "country": "Indonesia",
        "iata": "TMH",
        "icao": "WAKT",
        "latitude": "-6.099219799041748",
        "longitude": "140.29800415039062",
        "altitude": "57",
        "timezone": "9",
        "dst": "N",
        "tz_name": null
    },
    "NKB": {
        "name": "Noonkanbah Airport",
        "city": "Noonkanbah",
        "country": "Australia",
        "iata": "NKB",
        "icao": "YNKA",
        "latitude": "-18.4946994781",
        "longitude": "124.851997375",
        "altitude": "0",
        "timezone": "8",
        "dst": "N",
        "tz_name": null
    },
    "AEI": {
        "name": "Algeciras Heliport",
        "city": "Algeciras",
        "country": "Spain",
        "iata": "AEI",
        "icao": "LEAG",
        "latitude": "36.12888",
        "longitude": "-5.441118",
        "altitude": "98",
        "timezone": "-2",
        "dst": "E",
        "tz_name": null
    },
    "KSE": {
        "name": "Kasese Airport",
        "city": "Kasese",
        "country": "Uganda",
        "iata": "KSE",
        "icao": "HUKS",
        "latitude": "0.18299999833106995",
        "longitude": "30.100000381469727",
        "altitude": "3146",
        "timezone": "3",
        "dst": "N",
        "tz_name": null
    },
    "NCJ": {
        "name": "Sunchales Aeroclub Airport",
        "city": "Sunchales",
        "country": "Argentina",
        "iata": "NCJ",
        "icao": "SAFS",
        "latitude": "-30.9575",
        "longitude": "-61.5283",
        "altitude": "311",
        "timezone": "-3",
        "dst": "N",
        "tz_name": null
    },
    "IST": {
        "name": "Istanbul Airport",
        "city": "Istanbul",
        "country": "Turkey",
        "iata": "IST",
        "icao": "LTFM",
        "latitude": "41.275278",
        "longitude": "28.751944",
        "altitude": "325",
        "timezone": "3",
        "dst": "E",
        "tz_name": null
    },
    "SOV": {
        "name": "Seldovia Airport",
        "city": "Seldovia",
        "country": "United States",
        "iata": "SOV",
        "icao": "PASO",
        "latitude": "59.442401885986",
        "longitude": "-151.70399475098",
        "altitude": "29",
        "timezone": "-9",
        "dst": "A",
        "tz_name": null
    },
    "YSG": {
        "name": "Lutselk'e Airport",
        "city": "Lutselk'e",
        "country": "Canada",
        "iata": "YSG",
        "icao": "CYLK",
        "latitude": "62.418303",
        "longitude": "-110.681998",
        "altitude": "596",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "DWA": {
        "name": "Dwangwa Airport",
        "city": "Dwangwa",
        "country": "Malawi",
        "iata": "DWA",
        "icao": "FWDW",
        "latitude": "-12.5183",
        "longitude": "34.131901",
        "altitude": "1605",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "HGI": {
        "name": "Paloich Airport, Heliport",
        "city": "Higleig",
        "country": "South Sudan",
        "iata": "HGI",
        "icao": "HSFA",
        "latitude": "10.529167",
        "longitude": "32.500556",
        "altitude": "1290",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "KQH": {
        "name": "Kishangarh Airport",
        "city": "Ajmer",
        "country": "India",
        "iata": "KQH",
        "icao": "VIKG",
        "latitude": "26.601473",
        "longitude": "74.814147",
        "altitude": "0",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CNN": {
        "name": "Kannur International Airport",
        "city": "Kannur",
        "country": "India",
        "iata": "CNN",
        "icao": "VOKN",
        "latitude": "11.918614",
        "longitude": "75.547211",
        "altitude": "330",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ZCO": {
        "name": "La Araucanía Airport",
        "city": "Temuco",
        "country": "Chile",
        "iata": "ZCO",
        "icao": "SCQP",
        "latitude": "-38.9259",
        "longitude": "-72.6515",
        "altitude": "333",
        "timezone": "-3",
        "dst": "S",
        "tz_name": null
    },
    "ASS": {
        "name": "Arathusa Safari Lodge Airport",
        "city": "Arathusa",
        "country": "South Africa",
        "iata": "ASS",
        "icao": "FACC",
        "latitude": "-24.744165",
        "longitude": "31.522499",
        "altitude": "1200",
        "timezone": "2",
        "dst": "U",
        "tz_name": null
    },
    "VDI": {
        "name": "Vidalia Regional Airport",
        "city": "Vidalia",
        "country": "United States",
        "iata": "VDI",
        "icao": "KVDI",
        "latitude": "32.192699432373",
        "longitude": "-82.371200561523",
        "altitude": "275",
        "timezone": "-4",
        "dst": "A",
        "tz_name": null
    },
    "ISB": {
        "name": "New Islamabad International Airport",
        "city": "Islamabad",
        "country": "Pakistan",
        "iata": "ISB",
        "icao": "OPIS",
        "latitude": "33.560713",
        "longitude": "72.851613",
        "altitude": "1646",
        "timezone": "5",
        "dst": "U",
        "tz_name": null
    },
    "MHE": {
        "name": "Mitchell Municipal Airport",
        "city": "Mitchell",
        "country": "United States",
        "iata": "MHE",
        "icao": "KMHE",
        "latitude": "43.774799346900004",
        "longitude": "-98.03859710690001",
        "altitude": "1304",
        "timezone": "-6",
        "dst": "A",
        "tz_name": null
    },
    "GIT": {
        "name": "Mchauru Airport",
        "city": "Geita",
        "country": "Tanzania",
        "iata": "GIT",
        "icao": "HTRU",
        "latitude": "-2.813667",
        "longitude": "32.172472",
        "altitude": "3955",
        "timezone": "3",
        "dst": "N",
        "tz_name": null
    },
    "GID": {
        "name": "Gitega Airport",
        "city": "Gitega",
        "country": "Burundi",
        "iata": "GID",
        "icao": "HBBE",
        "latitude": "-3.417209",
        "longitude": "29.911308",
        "altitude": "5741",
        "timezone": "2",
        "dst": "U",
        "tz_name": null
    },
    "RMU": {
        "name": "Región de Murcia International Airport",
        "city": "Murcia",
        "country": "Spain",
        "iata": "RMU",
        "icao": "LEMI",
        "latitude": "37.803",
        "longitude": "-1.125",
        "altitude": "632",
        "timezone": "1",
        "dst": "E",
        "tz_name": null
    },
    "CQS": {
        "name": "Costa Marques Airport",
        "city": "COSTA MARQUES",
        "country": "Brazil",
        "iata": "CQS",
        "icao": "SWCQ",
        "latitude": "-12.421099662780762",
        "longitude": "-64.25160217285156",
        "altitude": "555",
        "timezone": "-4",
        "dst": "S",
        "tz_name": null
    },
    "ZXT": {
        "name": "Zabrat Airport",
        "city": "Baku",
        "country": "Azerbaijan",
        "iata": "ZXT",
        "icao": "UBTT",
        "latitude": "40.4955422161",
        "longitude": "49.9768066406",
        "altitude": "36",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "JAM": {
        "name": "Bezmer Air Base",
        "city": "Yambol",
        "country": "Bulgaria",
        "iata": "JAM",
        "icao": "LBIA",
        "latitude": "42.4548988342",
        "longitude": "26.3521995544",
        "altitude": "509",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "YUA": {
        "name": "Yuanmou Air Base",
        "city": "Yuanmou",
        "country": "China",
        "iata": "YUA",
        "icao": "ZPYM",
        "latitude": "25.737499237061",
        "longitude": "101.88200378418",
        "altitude": "3810",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "XEN": {
        "name": "Xingcheng Air Base",
        "city": "",
        "country": "China",
        "iata": "XEN",
        "icao": "ZYXC",
        "latitude": "40.580299377399996",
        "longitude": "120.697998047",
        "altitude": "30",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "GEC": {
        "name": "Lefkoniko Airport",
        "city": "Geçitkale",
        "country": "Cyprus",
        "iata": "GEC",
        "icao": "LCGK",
        "latitude": "35.235947",
        "longitude": "33.724358",
        "altitude": "146",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MBI": {
        "name": "Songwe Airport",
        "city": "Mbeya",
        "country": "Tanzania",
        "iata": "MBI",
        "icao": "HTGW",
        "latitude": "-8.919942",
        "longitude": "33.273981",
        "altitude": "4412",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "UGU": {
        "name": "Bilogai-Sugapa Airport",
        "city": "Sugapa-Papua Island",
        "country": "Indonesia",
        "iata": "UGU",
        "icao": "WABV",
        "latitude": "-3.73956",
        "longitude": "137.031998",
        "altitude": "7348",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "ETM": {
        "name": "Ramon Airport",
        "city": "Eilat",
        "country": "Israel",
        "iata": "ETM",
        "icao": "LLER",
        "latitude": "29.723694",
        "longitude": "35.011416",
        "altitude": "288",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "MNH": {
        "name": "Rustaq Airport",
        "city": "Al Masna'ah",
        "country": "Oman",
        "iata": "MNH",
        "icao": "OORQ",
        "latitude": "23.640556",
        "longitude": "57.4875",
        "altitude": "349",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CGY": {
        "name": "Laguindingan Airport",
        "city": "Cagayan de Oro City",
        "country": "Philippines",
        "iata": "CGY",
        "icao": "RPMY",
        "latitude": "8.612203",
        "longitude": "124.456496",
        "altitude": "190",
        "timezone": null,
        "dst": null,
        "tz_name": null
    },
    "CPO": {
        "name": "Desierto de Atacama Airport",
        "city": "Copiapo",
        "country": "Chile",
        "iata": "CPO",
        "icao": "SCAT",
        "latitude": "-27.2611999512",
        "longitude": "-70.7791976929",
        "altitude": "670",
        "timezone": null,
        "dst": null,
        "tz_name": null
    }
};


export const abbreviations = [
    {id: 'Mr', name: 'Sr'},
    {id: 'Ms', name: 'Sra'}
];

export const documentTypes = [
    {id: 'DNI', name: 'DNI'},
    {id: 'NIE', name: 'NIE'},
    {id: 'PAS', name: 'PASAPORTE'}
];

export const phoneTypes = [
    {id: 'home', name: 'Fijo'},
    {id: 'mobile', name: 'Móvil'}
];
