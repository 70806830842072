import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {HotelFlightsListResDto} from '@vecib2c/frontend-dto/dist/dto/HotelFlight';
import {Hotel, HotelFilter, PackageHotel, PriceGroup} from '@vecib2c/frontend-dto/dist';
import {ActivatedRoute, Params, Router, NavigationExtras} from '@angular/router';
import {FlightProviderService, MicrositeService} from '../../../lib-shared/services/index';
import {HfFlightsListComponent} from '../hf-flights-list/hf-flights-list.component';
import {SERVICES} from "../../../const";
import * as  uuidv1_ from 'uuid/v1';
import { IFlightPax } from '../../../lib-shared/index';
import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from '../../../utils';

const uuidv1 = uuidv1_;

@Component({
    selector: 'lib-hotel-and-flight-list',
    templateUrl: './hotel-and-flight-list.component.html',
    styleUrls: ['./hotel-and-flight-list.component.scss'],
    providers: [
        FlightProviderService,
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ]
})
export class HotelAndFlightListComponent implements OnInit, OnChanges {
    @Input() hotelFlights: HotelFlightsListResDto;

    @Output() showFlights: EventEmitter<boolean> = new EventEmitter<boolean>();

    _showFlights = false;

    @Input() hotelFilters: HotelFilter;

    @Input() flights: PriceGroup[];

    @Input() hotels: PackageHotel[];

    queryParams: Params;

    @Input() paginationTemplate: TemplateRef<any>;

    @Output() flightSelected: EventEmitter<PriceGroup> = new EventEmitter<PriceGroup>();
    _flightSelected: PriceGroup;


    currentPageNumber = 0;
    currentPageSize = 10;
    pageSizeOptions: number[] = [5, 10, 15];

    @Output() paginate: EventEmitter<{ page: number, pageSize: number }> = new EventEmitter<{ page: number, pageSize: number }>();

    @ViewChild(HfFlightsListComponent, { static: false }) flightListComponent: HfFlightsListComponent;

    @Input() microsites = false;

    @Input() preBookingIsAvailable = true;

    canBooking = false;

    constructor(private route: ActivatedRoute, private router: Router, private micrositeService: MicrositeService) {
        this.queryParams = this.route.snapshot.queryParams;
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hotelFlights) {
            if (this.hotelFlights && this.hotelFlights.Flights) {
                this.flights = [this.hotelFlights.Flights];
                this._flightSelected = this.hotelFlights.Flights;
                this.canBooking = this.validFlight();
                this.hotels = this.hotelFlights.Hotels.rows;
            }
        }
    }

    showOrHideFlights(): void {
        this._showFlights = !this._showFlights;
        this.showFlights.emit(this._showFlights);
        if (this._showFlights) {
            // this._flightSelected = null;
            this.canBooking = this.validFlight();
            this.flightSelected.emit(this._flightSelected);
        }
    }

    pageChanged($event) {
        this.currentPageNumber = $event.pageIndex;
        this.currentPageSize = $event.pageSize;
        this.paginate.emit({page: this.currentPageNumber + 1, pageSize: this.currentPageSize});
    }

    doBook(hotel: Hotel[]): void {
        const flightItemComponent = this.flightListComponent.flightItemComponent
            .find(f => f.item.id === this._flightSelected.id);
        const navigationExtras = this.buildFlightNavigationExtras(
            flightItemComponent.item,
            flightItemComponent.itineraries,
            flightItemComponent.pax,
            flightItemComponent.adultResidents,
            flightItemComponent.childrenResidents,
            flightItemComponent.infantResidents,
        );
        navigationExtras.state.flight.itineraries = this._flightSelected.itineraries.filter(i => (i as any).checked);
        navigationExtras.state.hotel = hotel;
        navigationExtras.state.flight.distribution = this.getPaxes(navigationExtras);
        navigationExtras.state.safe = this.hotelFlights.Safe;
        navigationExtras.state.safe.insurance = this.hotelFlights.Safe.rows[0];
        // navigationExtras.state.hotel.uuid = navigationExtras.state.flight.uuid;

        if (this.micrositeService.isMicrosite()) {
            this.micrositeService.setBookingData(SERVICES.HOTEL_FLIGHT, {
                uuid: navigationExtras.state.flight.uuid,
                navigationExtras,
                item: this._flightSelected
            });
            return;
        } else {
            this.router.navigate(
                ['integration', 'bookings', 'book-edit', 'hotel-flight', navigationExtras.state.flight.uuid], navigationExtras);
        }

    }

    setFlightSelected(flight): void {
        this._flightSelected = flight;
        this.canBooking = this.validFlight();
        this.flightSelected.emit(this._flightSelected);
        if (this._showFlights) {
            this.showOrHideFlights();
        }
    }

    validFlight(): boolean {
        return this._flightSelected.itineraries.filter(i => (i as any).checked).length >= 2;
    }

    getPaxes(navigationExtras) {
        const distributionArrayAdults = [];
        navigationExtras.state.hotel.forEach(hotel => {
            hotel.roomList.forEach(room => {
                distributionArrayAdults.push(room.distribution);
            });
        });
        const sumePaxes = this.sumePaxesAdults(distributionArrayAdults);
        return sumePaxes;
    }

    sumePaxesAdults(distribution) {
        const paxesObj = {
            adults: 0,
            children: 0,
            infants: 0
        };
        let childAcum = 0;
        distribution.forEach(room => {
            paxesObj.adults += Number(room.adults);
            room.children.forEach(child => {
                childAcum++;
            });
        });
        paxesObj.children = childAcum;
        return paxesObj;
    }

    // TODO: Update HF's Booking in order to adjust it like flights
    private buildFlightNavigationExtras(
        item,
        itineraries,
        pax: IFlightPax,
        adultResidents: number,
        childrenResidents: number,
        infantResidents: number,
    ) {
        const request = Object.assign({}, item);
        request.itineraries = item.itineraries.filter(e =>
            itineraries.includes(e.id)
        );

        request.distribution = {
            "adults": pax.adults,
            "children": pax.children,
            "infants": pax.infants
        };

        request.residents = {
            "adults": adultResidents || 0,
            "children": childrenResidents || 0,
            "infants": infantResidents || 0,
        };

        const uuid = uuidv1();
        request.uuid = uuid;
        const navigationExtras: NavigationExtras = {
            state: {
                flight: request,
            }
        };
        return navigationExtras;
    }
}
