// @ts-ignore
import { FieldType, IDynamicCrud } from '../lib-shared/interfaces/index';

export const dossier2FormMetaData: IDynamicCrud = {
    crud: 'dossier',
    name: 'Expedientess',
    urlPath: 'clients/dossier/',
    urlPathList: 'clients/id/dossier/',
    extraKeySave: ['client'],
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'code',
                    name: 'Id',
                    type: FieldType.CHAR,
                    showInTable: true,
                    removeOnCreate: true
                },
                {
                    keyName: 'dossier_budget_status',
                    name: 'Estado',
                    type: FieldType.LIST,
                    showInTable: true
                },
                {
                    keyName: 'dossier_status',
                    name: 'Estado',
                    type: FieldType.LIST,
                    showInTable: true,
                    defaultValue: 1
                },
                {
                    keyName: 'dossier_situation',
                    name: 'Situación',
                    type: FieldType.LIST,
                    showInTable: true,
                    defaultValue: 1
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'total_discount',
                    name: 'Descuento Total',
                    type: FieldType.NUMBER,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
                {
                    keyName: 'net_amount',
                    name: 'Total Neto',
                    type: FieldType.NUMBER,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
                {
                    keyName: 'total_amount',
                    name: 'Total PVP',
                    type: FieldType.NUMBER,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
                {
                    keyName: 'total_services',
                    name: 'Servicios',
                    type: FieldType.NUMBER,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
                {
                    keyName: 'created_by',
                    name: 'Usuario',
                    type: FieldType.CHAR,
                    showInTable: true,
                    removeOnCreate: true,
                    disabled: true
                },
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones',
            fieldChilds: [
                {
                    keyName: 'observation',
                    name: '',
                    type: FieldType.TEXTAREA,
                    removeOnCreate: false,
                },
            ]
        },
    ]
};
