import { NgModule } from '@angular/core';
import { HotelAndFlightListComponent } from './hotel-and-flight-list/hotel-and-flight-list.component';
import { HfFlightsListComponent } from './hf-flights-list/hf-flights-list.component';
import { HfHotelsListComponent } from './hf-hotels-list/hf-hotels-list.component';
import { IonicModule } from '@ionic/angular';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HotelsModule } from '../hotels/index';
import { FlightsIntegrationModule } from '../flights/index';
import { HotelProviderService } from '../../lib-shared/services/index';
import { LibSharedModule } from '../../lib-shared/index';

@NgModule({
    declarations: [HotelAndFlightListComponent, HfFlightsListComponent, HfHotelsListComponent],
    imports: [
        IonicModule,
        HotelsModule,
        FlightsIntegrationModule,
        MatPaginatorModule,
        LibSharedModule
    ],
    exports: [HotelAndFlightListComponent, HfFlightsListComponent, HfHotelsListComponent],
    providers: [HotelProviderService]
})
export class HotelAndFlightModule {
}
