import { Component } from "@angular/core";

@Component({
    selector: 'lib-invalid-price-alert',
    templateUrl: './invalid-price-alert.component.html',
    styleUrls: ['./invalid-price-alert.component.scss']
})
export class InvalidPriceAlertComponent {

    message = 'No se pudo obtener el precio actualizado';

}
