import { Pipe, PipeTransform } from "@angular/core";
import { FormControl } from '@angular/forms';
import { ValidationMessage } from '../interfaces/index';

@Pipe({
    name: 'validationMessage'
})
export class ValidationMessagePipe implements PipeTransform {

    transform(control: FormControl, validations: ValidationMessage[], value: any): string {
        for (let i = 0; i < validations.length; i++) {
            if (control.hasError(validations[i].key)) {
                return validations[i].message;
            }
        }
    }
}
