import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlightPackagesSelectionComponent} from "../flight-packages-selection/flight-packages-selection.component";
import {MatDialog} from "@angular/material";
import {PackagesProviderService} from "../../../lib-shared/services/packages-provider.service";
import {getCurrencySymbol} from "@angular/common";
import * as moment_ from "moment";

const moment = moment_;


export interface IFlightSelected {
    flightBookId: string;
    packageBookId: string;
    totalPrice: string;
    outward: any;
    return: any;
}

@Component({
    selector: 'lib-flight-packages',
    templateUrl: './flight-packages.component.html',
    styleUrls: ['./flight-packages.component.scss']
})
export class FlightPackagesComponent implements OnInit {

    panelOpenState = false;

    // @Input() flights: any[];
    @Input() hotel: any;
    @Input() providerToken: string;
    @Output() eventFlight: EventEmitter<any> = new EventEmitter<any>();
    @Input() flightSelected: IFlightSelected;
    @Input() microsites = false;

    constructor(
        public dialog: MatDialog,
        public packageService: PackagesProviderService,
    ) {
    }

    ngOnInit() {

    }

    eventFlightSelected(item) {
        this.eventFlight.emit(item);
    }

    async changeFlight() {
        const flights = await this.packageService.getOtherFlights({providerToken: this.providerToken}).toPromise();
        this.openDialog(flights);
    }

    openDialog(flights: any[]): void {
        const dialogRef = this.dialog.open(FlightPackagesSelectionComponent, {
            width: '850px',
            height: '600px',
            data: flights,
            panelClass: 'modal-vuelos-change-disney',
        });

        dialogRef.componentInstance.flights = flights;

        dialogRef.componentInstance.emitItineraries.subscribe(response => {
            this.eventFlightSelected(response);
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    parseInt(string): number {
        return parseInt(string, 10);
    }

    getAirportImageSrc(code: string): string {
        return `https://d1hkxmgwhmmdhs.cloudfront.net/dist/assets/img/logo/proveedores/vuelos/x2/logo_${code}.png`;
    }

    isChangeFlight(providerName: string): boolean {
        return providerName && providerName.toLowerCase().indexOf('disney') > -1;
    }

    getDuration(dateInit) {
        let dstDeparture = dateInit.departure.offset.dst;
        let dstArrival = dateInit.arrival.offset.dst;
    
        //Salida Positivo y llegada negativo
        if (dstDeparture > 0 && dstArrival < 0) {
          const Hours = dstDeparture - dstArrival;
          const DateDeparture = moment(
            dateInit.departure.date,
            "YYYY-MM-DD HH:mm:ss"
          )
            .subtract(Hours, "hours")
            .format("DD/MM/YYYY HH:mm:ss");
          const DateArrival = moment(
            dateInit.arrival.date,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD/MM/YYYY HH:mm:ss");
          if (dateInit.departure.airportCode === "LIS" ||
              dateInit.arrival.airportCode === "LIS" ||
              dateInit.departure.airportCode === "CUN" ||
              dateInit.arrival.airportCode === "CUN" ||
              dateInit.departure.airportCode === "PUJ" ||
              dateInit.arrival.airportCode === "PUJ") {
            return moment.utc(
              moment(DateArrival, "DD/MM/YYYY HH:mm:ss").diff(
                moment(DateDeparture, "DD/MM/YYYY HH:mm:ss")
              )
            ).subtract(1, 'hours')
            .format("HH:mm:ss");
    
          }else{
            return moment.utc(
                moment(DateArrival, "DD/MM/YYYY HH:mm:ss").diff(
                  moment(DateDeparture, "DD/MM/YYYY HH:mm:ss")
                )
              )
              .format("HH:mm:ss");
          }
        }
        //Salida Negativo y llegada positivo
        if (dstDeparture < 0 && dstArrival > 0) {
          const Hours = dstArrival - dstDeparture;
          const DateDeparture = moment(
            dateInit.departure.date,
            "YYYY-MM-DD HH:mm:ss"
          )
            .add(Hours, "hours")
            .format("DD/MM/YYYY HH:mm:ss");
          const DateArrival = moment(
            dateInit.arrival.date,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD/MM/YYYY HH:mm:ss");
          if (dateInit.departure.airportCode === "LIS" ||
              dateInit.arrival.airportCode === "LIS" ||
              dateInit.departure.airportCode === "CUN" ||
              dateInit.arrival.airportCode === "CUN" ||
              dateInit.departure.airportCode === "PUJ" ||
              dateInit.arrival.airportCode === "PUJ") {
            return moment.utc(
              moment(DateArrival, "DD/MM/YYYY HH:mm:ss").diff(
                moment(DateDeparture, "DD/MM/YYYY HH:mm:ss")
              )
            ).add(1, 'hours')
            .format("HH:mm:ss");
          }else{
            return moment.utc(
                moment(DateArrival, "DD/MM/YYYY HH:mm:ss").diff(
                  moment(DateDeparture, "DD/MM/YYYY HH:mm:ss")
                )
              )
              .format("HH:mm:ss");
          }
        }
 
    }

    


}
