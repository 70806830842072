import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { HotelRoom, HotelRoomRate } from "@vecib2c/frontend-dto";

@Component({
    selector: "lib-hotel-detail-card",
    templateUrl: "./hotel-detail-card.component.html",
    styleUrls: ["./hotel-detail-card.component.scss"]
})
export class HotelDetailCardComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @Input() item: any;
    @Input() distribution: any;
    @Input() indexDistribution: any;
    @Input() isRoomSelected: boolean;
    @Output("selected") selected: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('facilitiesContainer', { static: false }) facilitiesContainer;
    rates;
    isExpanded: boolean;
    showExpandedButton: boolean;

    constructor(
        private el: ElementRef,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.getRates();
    }

    ngAfterViewInit() {
        const currentHeight = this.facilitiesContainer._element.nativeElement.offsetHeight;
        this.showExpandedButton = 170 - currentHeight === 0 ? true : false;
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    getRates() {
        if (this.item !== undefined) {
            this.rates = this.item["rates"];
        }
    }

    getIntegerNumber(price) {
        return price.substring(0, price.indexOf(".") + 1);
    }

    getDecimalNumber(price) {
        return price.substring(price.indexOf(".") + 1, price.length);
    }

    select(room: HotelRoom, rate: HotelRoomRate) {
        const reservedRoom = {
            name: room.name,
            code: rate.rateId,
            rateName: rate.name,
            price: rate.price,
            distributionRoom: this.distribution,
            indexDistribution: this.indexDistribution
        };
        this.selected.emit(reservedRoom);
    }

    getRateName(rate: HotelRoomRate) {
        return rate.category || rate.name;
    }

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }
}
