import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberTypePipe'
})
export class NumberTypePipe implements PipeTransform {

    transform(amount: number | string, part = 'integer'): string {
        if (!amount) {
            return;
        }

        const amountArray = (amount + '').split('.');

        if (part === 'integer') {
            return amountArray[0];
        }
        return amountArray[1] || '00';
    }
}
