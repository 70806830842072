import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { AgencyChain } from '../../lib-shared/index';

@Component({
    selector: 'lib-document-header',
    templateUrl: './document-header.component.html',
    styleUrls: ['./document-header.component.scss']
})
export class DocumentHeaderComponent implements OnInit, OnDestroy {

    @Input() selectedChain: AgencyChain;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }
}
