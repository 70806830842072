import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
    name: 'getCurrency'
})
export class GetCurrencyPipe implements PipeTransform {

    transform(currencyCode: string): string {
        if (!currencyCode) { return; }
        return getCurrencySymbol(currencyCode.trim().toUpperCase(), 'narrow');
    }

}
