import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { debounceTime, takeUntil } from "rxjs/operators";
import * as moment_ from "moment";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { PackagesProviderService } from '../../../lib-shared/services/packages-provider.service';
import Pax from '../../../paxes/Pax';
import { PackageSearchDto } from '../package-search.component';
import { PaxesService } from '../../../paxes/index';
import { packageOrigins, packageParameters } from '../../../../mock/packages';

const moment = moment_;

@Component({
    selector: 'lib-sky-package-search',
    templateUrl: './sky-package-search.component.html',
    styleUrls: ['./sky-package-search.component.scss'],
    providers: [PackagesProviderService]
})
export class SkyPackageSearchComponent implements OnInit, OnDestroy {
    form: FormGroup;
    originOptions$: any;
    destinationOptions$: Observable<any>;
    pax: Pax;
    packageListReq: PackageSearchDto;

    paxes: any = [];

    @ViewChild("dateRange", { static: false }) dateRange;
    @Input() inputFields;
    @Output() dataChanged = new EventEmitter<any>();
    @Input() isSmall;

    unsusbcribe = new Subject();

    constructor(
        private packageService: PackagesProviderService,
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public paxService: PaxesService,
        public router: Router
    ) {

    }

    ngOnInit(): void {
        this.paxes.push(new Pax(1, 2, []));
        this.initForm();

        this.form.get("origin").valueChanges
            .pipe(
                takeUntil(this.unsusbcribe),
                debounceTime(250)
            )
            .subscribe(() => this.getOriginAutocompleteData());

        this.form.get("destination").valueChanges
            .pipe(
                takeUntil(this.unsusbcribe),
                debounceTime(600)
            )
            .subscribe((words) => {
                if (words.length > 4) {
                    // this.getDestinationAutocompleteData(words);
                }
            });
    }

    ngOnDestroy(): void {
    }

    private initForm() {
        this.form = this.formBuilder.group({
            origin: ['', Validators.required],
            destination: ['', Validators.required],
            distribution: [this.paxes, Validators.required]
        });

        if (this.inputFields) {
            this.setParamsFromQuery(this.inputFields);
            this.setPaxFromQuery(this.inputFields.distribution);
        }
        this.packageListReq = this.form.value;
    }

    private setPaxFromQuery(distribution) {
        this.paxes = distribution;
    }

    private setParamsFromQuery(inputFields: PackageSearchDto) {
        this.form.controls["origin"].setValue(packageOrigins.find(po => po.id === inputFields.origin));
        this.form.controls["destination"].setValue(inputFields.destination);
        this.setDateFromQuery(inputFields.checkIn);
        this.setDateFromQuery(inputFields.checkOut);
        // this.form.controls['distribution'].setValue(inputFields.distribution);
        // this.paxService.changePax(inputFields.distribution);
    }

    private setDateFromQuery(inputDate: string) {
        const date = moment(inputDate).format("DD-MM-YYYY");
        const dateArray = date.split("-");
        this.dateRange.onSelection(new NgbDate(Number(dateArray[2]), Number(dateArray[1]), Number(dateArray[0])));
    }

    search() {
        if (!this.form.valid) {
            return;
        }
        this.setDates();
        this.setPlaces();
        this.setPaxes();
        this.dataChanged.emit(this.getParams());
        // this.router.navigateByUrl("packages/search?" + this.getParams());
    }

    getParams(): HttpParams {
        let query = new HttpParams();

        packageParameters.forEach(parameter => {
            if (this.packageListReq[parameter]) {
                query = query.set(parameter, parameter === "distribution" || parameter === "rooms" ?
                    JSON.stringify(this.packageListReq[parameter]) : this.packageListReq[parameter]);
            }
        });
        return query;
    }

    setDates() {
        this.packageListReq.checkIn = this.dateRange.formatDate(this.dateRange.fromDate);
        this.packageListReq.checkOut = this.dateRange.formatDate(this.dateRange.toDate);
    }

    setPlaces() {
        this.packageListReq.origin = this.form.controls["origin"].value.id;
        this.packageListReq.destination = this.form.controls["destination"].value.code;
    }

    setPaxes() {
        this.packageListReq.distribution = this.paxes;
        // this.packageListReq.adults = this.pax.adults;
        // this.packageListReq.children = this.pax.children;
        // this.packageListReq.rooms = this.pax.rooms;
    }

    getOriginAutocompleteData() {
        this.originOptions$ = of(packageOrigins);
    }

    // getDestinationAutocompleteData(words: string) {
    //     this.destinationOptions$ = this.packageService
    //         .getDestinations({package: 'SKY Y MONTAÑA', words: words})
    //         .pipe(map(({count, rows}) => rows as any));
    // }

    onPaxChanged(pax: any) {
        this.paxes[pax.position] = pax.newPax;
        // this.form.controls["rooms"].setValue(pax.rooms);
        // this.form.controls["adults"].setValue(pax.adults);
        // this.form.controls["children"].setValue(pax.children.length);
    }

    onRemovePax(position) {
        const newPaxes = [];
        this.paxes.forEach((pax: Pax, index) => {
            if (index !== position) {
                newPaxes.push(pax);
            }
        });

        this.paxes = newPaxes;
    }

    addPax() {
        this.paxes.push(new Pax(1, 2, []));
    }

    displayFnOrigin(option: any): string {
        return option ? option.name : undefined;
    }

    displayFnDestination(option: any): string {
        return option ? option.description : undefined;
    }
}
