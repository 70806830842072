import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityBookingComponent } from './activity-booking.component';
import {FormAddEditModule} from "../../form-add-edit/index";

@NgModule({
  declarations: [ActivityBookingComponent],
  imports: [
    CommonModule,
    FormAddEditModule
  ]
})
export class ActivityBookingModule { }
