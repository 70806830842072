import { NgModule } from "@angular/core";
import { LibSharedModule } from "../../../lib-shared/index";
import { CircuitsPriceSummaryComponent } from "./circuits-price-summary.component";

@NgModule({
    declarations: [
        CircuitsPriceSummaryComponent
    ],
    imports: [
        LibSharedModule,
    ],
    exports: [
        CircuitsPriceSummaryComponent
    ]
})
export class CircuitsPriceSummaryModule {

}
