import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

export class ClientAuthServiceConfig {
  authTokenEndpoint: string;
  clientRestEndpoint: string;
  resetPasswordEndpoint: string;
  confirmPasswordEndpoint: string;
  meEndpoint: string;
  redirectToAfterLogin: string;
}

@NgModule({
  declarations: [],
  imports: [],
})
export class ClientAuthModule {
  constructor(@Optional() @SkipSelf() parentModule?: ClientAuthModule) {
    if (parentModule) {
      throw new Error(
        'ClientAuthModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: ClientAuthServiceConfig): ModuleWithProviders {
    return {
      ngModule: ClientAuthModule,
      providers: [
        {
          provide: ClientAuthServiceConfig,
          useValue: config
        },
      ]
    };
  }
}
