import { Pipe, PipeTransform } from "@angular/core";
import { Segment } from "@vecib2c/frontend-dto";
import * as moment_ from 'moment';

const moment = moment_;

@Pipe({
    name: 'displayWaitingTime'
})
export class DisplayWaitingTimePipe implements PipeTransform {

  transform(segment: Segment, nextSegment: Segment): string {
    if (!segment || !nextSegment) { return; }
    const originMoment = moment(nextSegment.originDateTime, 'DD/MM/YYYY kk:mm');
    const destinationMoment = moment(segment.destinationDateTime, 'DD/MM/YYYY kk:mm');
    const diff = originMoment.diff(destinationMoment);
    const diffDuration = moment.duration(diff);
    const hours = diffDuration.days() * 24 + diffDuration.hours();
    const minutes = diffDuration.minutes();

    return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m` : ''}`;
  }
}
