import { ComissionsTransfers } from "../request/prebooking-transfer-request.dto";

export class TransferDtoRes {
    count: number;
    rows: TransfersAvailability;
}

export class TransfersAvailability {
    resAvailTransfers: Transfers[];
    search: Search;
}

export class Search {
    comeBack: Date; 
    departure: Date;
    from: From; 
    to: From; 
    occupancy: Occupancy; 
    sessionId: string;
}

export class Date {
    date: string;
    time: string;
}

export class From {
    code: string; 
    description: string; 
    type: string; 
}

export class Occupancy {
    adults: string; 
    children: string; 
    infants: string; 
}

export class Transfers{
    aplicationType: string;
    hotelInfo: HotelInfo;
    currencyId: string;
    direction: string;
    provider: string;
    totalAmount: number;
    totalNet: string;
    transferKey: string;
    transferType: string;
    vehicleInfo: Vehicle; 
    serviceType: string;
    commission: ComissionsTransfers
}

export class Vehicle {
    categoryCode: string;
    categoryName: string;
    extras?: Extras[];
    images: Images[];
    maxPaxCapacity: string;
    minPaxCapacity: string;
    transferDetailInfo?: TransferDetailInfo;
    vehicleCode: string;
    vehicleName: string;
}

export class TransferDetailInfo {
    description: string; 
    id: string; 
    name: string; 
    type: string; 
}

export class Images {
    url: string; 
    type: string; 
}

export class Extras {
    extraCode: string;
    extraName?: string;
    extraTotalAmount?: string;
    extraUnityAmount?: string;
    extraQuantity: string;
}

export class HotelInfo {
    address: string;
    postalCode: string;
    telephone: string;
    fax: string;
    city: string;
}