import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDynamicCrudQueryParams, IPaginate } from '../interfaces/index';
import { objectToQuerystring } from '../functions/index';
import { TecnoturisService } from '../../tecnoturis.service';
import { tap } from "rxjs/operators";

@Injectable()
export class DynamicCrudService {
    protected host;

    constructor(private http: HttpClient, private tecnoturisService: TecnoturisService) {
        this.host = this.tecnoturisService.config.management;
    }

    setHost(path: string, host?: string): void {
        if (host) {
            this.host = host;
        } else {
            this.host = this.tecnoturisService.config.management;
        }
        this.host += `/${path}`;
    }

    request<T>(): Observable<T> {
        return this.http.get<T>(`${this.host}`);
    }

    get<T>(id: number): Observable<T> {
        return this.http.get<T>(`${this.host}${id}/`);
    }

    list<T>(queryParams?: IDynamicCrudQueryParams, page?: number): Observable<any> {
        const url = queryParams ? `${this.host}${objectToQuerystring(queryParams)}` : this.host;
        return this.http.get<IPaginate<T>>(url, {
            params: {
                page: `${page}`
            }
        });
    }

    add<T, R>(body: T): Observable<R> {
        return this.http.post<R>(this.host, body);
    }

    put<T, R>(id: number, body: T): Observable<R | T> {
        let file;
        if (Object.keys(body).includes('logo')) {
            file = typeof body['logo'] !== 'string' ? (body['logo'] && body['logo'].length ? body['logo'][0] : null) : null;
            delete body['logo'];
        }
        return this.http.patch<R | T>(`${this.host}${id}/`, body).pipe(
            tap(res => {
                if (file) {
                    this.putFiles(id, file).subscribe();
                }
            })
        );
    }

    putFiles(id: number, file: any): Observable<any> {
        const formData = new FormData();
        formData.append('logo', file);
        return this.http.patch<any>(`${this.host}${id}/`, formData).pipe(
            tap(console.log)
        );
    }

    delete<T>(id: number, params = null): Observable<any> {
        return this.http.delete<T>(`${this.host}${id}/`, { params: params });
    }
}

