import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Itinerary2, TrainsRequestDto } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-train-summary',
    templateUrl: './train-summary.component.html',
    styleUrls: ['./train-summary.component.scss']
})
export class TrainSummaryComponent implements OnInit, OnDestroy {

    @Input() trainParams: TrainsRequestDto;

    @Input() set itineraries(value: Itinerary2[]) {
        this._itineraries = value;
        if (!this.trainParams.checkOut) {
            this.invalidToGoToBookingForm = this._itineraries.filter(Boolean).length !== 1;
        } else {
            this.invalidToGoToBookingForm = this._itineraries.filter(Boolean).length !== 2;
        }
    }

    get itineraries(): Itinerary2[] {
        return this._itineraries;
    }

    @Output() goToBookingPage = new EventEmitter<void>();

    invalidToGoToBookingForm = false;
    withoutSelectedItinerary: TemplateRef<any>;

    private _itineraries: Itinerary2[];

    hoverPrice = false;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    goToBookingForm(): void {
        this.goToBookingPage.emit();
    }
}
