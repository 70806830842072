import { ActivityDetailGetResDto, IActivityPaxAmount } from "@vecib2c/frontend-dto";
import { randomNumber } from "../../functions/index";
import { ActivityPreBookingResponse } from "../multidestination/multidestination-pre-booking.model";

export class PassengerAmount {
    numberOfPassenger: number;
    amount: IActivityPaxAmount;

    // @dynamic
    static buildActivityPassengerAges(passengerAmounts: PassengerAmount[]): number[] {
        const arr = passengerAmounts.reduce((acc, cur) => {
            const arr: number[] = new Array(cur.numberOfPassenger).fill(
                cur.amount.paxType === 'ADULT' ?
                    30 :
                    randomNumber(+cur.amount.ageTo, +cur.amount.ageTo)
            );
            return [...acc, ...arr];
        }, []);
        return arr;
    }
}

export interface ActivityDetailWithPassengers {
    activityDetail: ActivityDetailGetResDto;
    passengerAmounts: PassengerAmount[];
}

export class ActivityDetailMapper {

    static calculatePVP(activityResponses: ActivityDetailGetResDto[]): number {
        if (!activityResponses) { return 0; }
        const activityPrice = activityResponses.reduce((activityAccumulator, activity) => {
            return activityAccumulator + this.calculatePVPPerActivity(activity);
        }, 0);
        return activityPrice;
    }

    static calculatePVPPerActivity(activity: ActivityDetailGetResDto): number {
        return activity.modalities[0].rates[0].rateDetails[0].totalAmount.commission.pvp;
    }
}
