import { Pipe, PipeTransform } from "@angular/core";
import { t } from 'typy';

@Pipe({
    name: 'filterByField'
})
export class FilterByFieldPipe implements PipeTransform {

    transform(array: any[], term: string, field: string): any[] {
        if (!array || !term || !field) { return array; }

        return array.filter(item => {
          return t(item, field).safeString.toLowerCase().includes(term.toLowerCase());
        });
    }
}
