import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-alert-confirm',
    templateUrl: './alert-confirm.component.html',
    styleUrls: ['./alert-confirm.component.scss'],
})
export class AlertConfirmComponent implements OnInit {

    @ViewChild('buttonNo', null) buttonNo: MatButton;
    @ViewChild('buttonYes', null) buttonYes: MatButton;

    constructor(public dialogRef: MatDialogRef<AlertConfirmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onNoClick() {
    }

    onYesClick() {
    }

    onKey(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.buttonNo.focus();
        } else if (event.key === 'ArrowRight') {
            this.buttonYes.focus();
        }
    }


}
