import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'airlineLogo'
})
export class AirlineLogoPipe implements PipeTransform {
    transform(value: string, isCode = false): string {
        if (isCode) {
            return value === 'AA' ?
                "assets/images/airline_aa.png" :
                `https://webapi.aramix.es//FicherosVisiblesWeb/Logotipos/Aereolineas/${value}.png`;
        }

        if (value === "https://webapi.aramix.es//FicherosVisiblesWeb/Logotipos/Aereolineas/AA.png") {
            return "assets/images/airline_aa.png";
        }

        return value;
    }
}
