import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "lib-segment-item-itinerary",
    templateUrl: "./segment-item-itinerary.component.html",
    styleUrls: ["./segment-item-itinerary.component.scss"],
})
export class SegmentItemItinaryComponent implements OnInit {

    @Input() segment;
    @Input() pricedSegments;
    @Input() logoAirline;

    constructor() {
    }

    ngOnInit() {
        this.segment.priceInformation = this.pricedSegments.find(el => el.itinerarySegmentId === this.segment.id);
    }

    getAirlineLogo(logoUrl: string): string {
        if (logoUrl === "https://webapi.aramix.es//FicherosVisiblesWeb/Logotipos/Aereolineas/AA.png") {
            return "assets/images/airline_aa.png";
        }
        return logoUrl;
    }
}
