import { Component, Input } from '@angular/core';
import { ShopingBasketCircuit } from '../../../integrations/circuits/index';

@Component({
    selector: 'lib-circuits-book-summary',
    templateUrl: './circuits-book-summary.component.html',
    styleUrls: ['./circuits-book-summary.component.scss']
})
export class CircuitsBookSummaryComponent {

    @Input() circuit: ShopingBasketCircuit;

    constructor() {
    }

    getDateCircuit() {
        const dateCircuit = JSON.parse(atob(this.circuit.rateSelected.rateKey));
        return dateCircuit.fecha_salida;
    }

}
