import { Pipe, PipeTransform } from "@angular/core";
import { Luggage } from "../../interfaces/index";

@Pipe({
    name: 'displayLuggageOptionByDestination'
})
export class DisplayLuggageOptionByDestinationPipe implements PipeTransform {

  transform(luggagesByDestination: Luggage[], destinationIndex: number): Luggage {
    if (!luggagesByDestination) { return; }
    const luggage = luggagesByDestination.find(f => f.destinationIndex === destinationIndex);
    return luggage;
  }
}
