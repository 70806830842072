import {Component, Input, OnInit} from '@angular/core';
import * as moment_ from 'moment';

const moment = moment_;

@Component({
  selector: 'lib-detail-information',
  templateUrl: './detail-information.component.html',
  styleUrls: ['./detail-information.component.scss']
})
export class DetailInformationComponent implements OnInit {

  @Input() vacationPackage: any;
  @Input() hotel: any;
  @Input() infoPackage: any;

  distribution : any
  constructor() { }

  ngOnInit() {
    this.distribution = JSON.parse(this.infoPackage.distribution)
  }

  getCheckout(req) {
    const checkout = moment(req.checkIn, 'DD-MM-YYYY').add(+req.nights, 'days');
    return moment(checkout).format('DD-MM-YYYY');
  }

}
