import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'lib-flight-packages-selection',
    templateUrl: './flight-packages-selection.component.html',
    styleUrls: ['./flight-packages-selection.component.scss']
})
export class FlightPackagesSelectionComponent implements OnInit {

    @Input() flights: any[];
    @Output() emitItineraries: EventEmitter<any[]> = new EventEmitter<any[]>();

    formRadioGroup: FormGroup;
    flightSelected = {} as any;

    constructor(
        public dialogRef: MatDialogRef<FlightPackagesSelectionComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
    }

    selectedFlightOutward(event, flight) {
        this.flightSelected['outward'] = event;
        flight.totalPrice = this.sumPrice(this.flightSelected);
    }

    selectedFlightReturn(event, flight) {
        this.flightSelected['return'] = event;
        flight.totalPrice = this.sumPrice(this.flightSelected);
    }

    sumPrice(flightSelected) {
        return flightSelected['outward'] || flightSelected['return'] ?
            (flightSelected['outward'] ? flightSelected['outward'].price : 0) +
            (flightSelected['return'] ? flightSelected['return'].price : 0) : 0;
    }

    emitFlightSelected(flight) {
        this.emitItineraries.emit(flight);
        this.dialogRef.close();
    }

    getAirportImageSrc(code: string): string {
        return `https://d1hkxmgwhmmdhs.cloudfront.net/dist/assets/img/logo/proveedores/vuelos/x2/logo_${code}.png`;
    }

}
