export const integrationTypes = [
  "hotel",
  "flights",
  "circuits",
  "hotel-flight",
  "insurance",
  "packages",
  "transport-cars",
  "trains",
  "multidestination",
  "activities",
  "transfers",
]

// tslint:disable-next-line
export type IntegrationType =
  | "HOTEL"
  | "FLIGHT"
  | "PACKAGE"
  | "HF"
  | "CIRCUIT"
  | "CAR"
  | "TRAIN"
  | "ACTIVITY"
  | "INSURANCE"
  | "MULTIDESTINATION"
  | "TRANSFER"
