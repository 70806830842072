import { safeNumber, splitLastName } from '../../functions/index';
import { ManagementPax } from "../../interfaces/management.interface";
import { Passenger as TrainPassenger } from '../../interfaces/trains/response/pre-bookings.response.dto';
import { TrainPaxType, TrainDocumentType } from '../../interfaces/trains/response/pre-bookings.response.dto';

export class TrainPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialTrainPaxes: TrainPassenger[],
    ): TrainPassenger[] {
        const arr = paxes.map((pax, i) => {
            const initialTrainPax = initialTrainPaxes[i] || {} as TrainPassenger;
            const {
                age: initialAge,
                documentNumber: initialDocumentNumber,
                documentType: initialDocumentType,
                paxType: initialPaxType,
                phone: initialPhone,
                firstSurname: initialFirstSurname,
                secondSurname: initialSecondSurname,
                email: initialEmail,
            } = initialTrainPax;

            const {
                name,
                last_name: lastName,
                dni,
                age,
                type,
                type_document: documentType,
                phone,
                email,
            } = pax;

            const splittedLastName = splitLastName(lastName);

            const bookingPax: TrainPassenger = {
                age: this.buildAge(age, initialAge),
                name,
                firstSurname: this.buildFirstSurname(splittedLastName[0], initialFirstSurname),
                secondSurname: this.buildSecondSurname(splittedLastName[1], initialSecondSurname),
                documentNumber: this.buildDocumentNumber(dni, initialDocumentNumber),
                paxType: this.buildPaxType(type, initialPaxType),
                documentType: this.buildDocumentType(documentType, initialDocumentType),
                phone: this.buildPhone(phone, initialPhone),
                email: this.buildEmail(email, initialEmail),
            };

            return bookingPax;
        });
        return arr;
    }

    private static buildAge(
        age: number,
        initialAge: number,
    ): number {
        if (!age) { return safeNumber(initialAge, 30); }
        return safeNumber(age, 30);
    }

    private static buildFirstSurname(
        firstSurname: string,
        initialFirstSurname = '',
    ): string {
        if (!firstSurname) { return initialFirstSurname; }
        return firstSurname;
    }

    private static buildSecondSurname(
        secondSurname: string,
        initialSecondSurname = '',
    ): string {
        if (!secondSurname) { return initialSecondSurname; }
        return secondSurname;
    }

    private static buildDocumentNumber(
        documentNumber: string,
        initialDocumentNumber: string,
    ): string {
        if (!documentNumber) { return initialDocumentNumber; }
        return documentNumber;
    }

    private static buildPaxType(
        paxType: string,
        initialPaxType: TrainPaxType = 'Adulto',
    ): TrainPaxType {
        if (!paxType) { return initialPaxType; }
        const mappedPaxType = paxType.toLowerCase();
        if (['ad', 'adult', 'adulto'].includes(mappedPaxType)) { return 'Adulto'; }
        if (['ch', 'child', 'niño'].includes(mappedPaxType)) { return 'Niño'; }
        if (['in', 'infant', 'infante'].includes(mappedPaxType)) { return 'Infante'; }
        return 'Adulto';
    }

    private static buildDocumentType(
        documentType: number,
        initialDocumentType: TrainDocumentType = 'DNI',
    ): TrainDocumentType {
        if (!documentType) { return initialDocumentType; }
        if (documentType === 1) { return 'DNI'; }
        if (documentType === 2) { return 'PAS'; }
        if (documentType === 3) { return 'NIE'; }
        return 'DNI';
    }

    private static buildPhone(
        phone: string,
        initialPhone = '',
    ): string {
        if (!phone) { return initialPhone; }
        return phone;
    }

    private static buildEmail(
        email: string,
        initialEmail = '',
    ): string {
        if (!email) { return initialEmail; }
        return initialEmail;
    }
}
