export class PreBookingsCreateRequestDto {
    providerToken: string;
    trainTypeForOutward: string;
    trainNumberForOutward: string;
    trainTypeForReturn: string;
    trainNumberForReturn: string;
    classCodeOutward: string;
    salesGroupCodeOutward: string;
    classCodeReturn?: string;
    salesGroupCodeReturn?: string;
    requestToken: string;
}
