import {Injectable} from '@angular/core';
import {BookReqDto, CircuitRoom, Passenger} from '@vecib2c/frontend-dto';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import t from 'typy';
import {NavigationExtras, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ShopingBasketCircuit} from '../../../integrations/circuits/index';
import {BasketManager, CircuitsProviderService, NotificationPopupService} from '../../../lib-shared/services/index';

const ADULT = 'AD';
const CHILDREN = 'CH';

@Injectable()
export class CircuitsBookServices {

    circuit: ShopingBasketCircuit;
    occupants: FormGroup;
    nationalities = [];
    provider;

    constructor(
        protected _formBuilder: FormBuilder,
        protected router: Router,
        protected http: HttpClient,
        protected basketManager: BasketManager,
        private notificationPopupService: NotificationPopupService,
        protected circuitsProviderService: CircuitsProviderService,
    ) {
    }

    generateArray(): number[] {

        if (this.circuit.circuitBookingPostReqDto) {
            const n = this.circuit.circuitBookingPostReqDto.rooms.length;
            const arr = new Array(n);
            return arr;
        } else {
            const n = parseInt(this.circuit.distributionSelected[0].rooms.toString(), 10);
            const arr = new Array(n);
            return arr;
        }
    }

    setDistribution() {
        if (typeof this.circuit.circuitGetReq.rooms[0] === 'object') {
            return;
        }
        this.circuit.circuitGetReq.rooms = JSON.parse(JSON.stringify(this.circuit.circuitGetReq.rooms));
    }

    initOccupantsForm() {
        // aqui hay que pasarle el objeto genders.
        const generateOccupant = (type, defaultValue: Passenger) => {
            return this._formBuilder.group({
                name: [t(defaultValue, 'name').safeString, Validators.required],
                surname: [t(defaultValue, 'surname').safeString, Validators.required],
                type: [type],
                age: [t(defaultValue, 'age').safeObject, Validators.required],
                gender: [t(defaultValue, 'gender').safeString, Validators.required],
                documentId: [t(defaultValue, 'documentId').safeObject, Validators.required]
            });
        };
        const roomList = [];
        let storedRoomList;
        let obj;
        let occupantsDetails;
        if (this.circuit.circuitBookingPostReqDto) {
            storedRoomList = this.circuit.circuitBookingPostReqDto.rooms;
        } else {
            obj = new Passenger();
        }

        const parseOccupants = (occupants) => {
            return {
                adults: occupants.filter(occupant => occupant.type === ADULT),
                children: occupants.filter(occupant => occupant.type === CHILDREN)
            };
        };

        for (let i = 0; i < this.circuit.distributionSelected.length; i++) {
            if (storedRoomList) {
                occupantsDetails = parseOccupants(storedRoomList[i].passengers);
            }

            const AdultsOccupantList = [];
            const childrenOccupantList = [];


            const adults = this.circuit.distributionSelected[i].adults;

            for (let j = 0; j < adults; j++) {
                AdultsOccupantList.push(generateOccupant(ADULT, t(occupantsDetails, `adults[${j}]`).safeObject));
            }

            const childrenList = this.circuit.distributionSelected[i].children;

            for (let k = 0; k < childrenList.length; k++) {
                childrenOccupantList.push(generateOccupant(CHILDREN, t(occupantsDetails, `children[${k}]`).safeObject));
            }

            roomList.push(this._formBuilder.group({
                adultsOccupants: this._formBuilder.array(AdultsOccupantList),
                childrensOccupants: this._formBuilder.array(childrenOccupantList),
            }));

        }

        // is it needed? maybe the only needed is roomList, the others can be gettend from this.hotel
        const circuitFormItem = this._formBuilder.group({
            // rateId: [this.hotel.roomList[0].rooms[0].rates[0].rateId, Validators.required],
            // roomId: [1, Validators.required],
            // hotelId: [this.hotel.hotelId, Validators.required],
            // checkIn: [this.hotel.hotelGetReq.checkIn, Validators.required],
            // checkOut: [this.hotel.hotelGetReq.checkOut, Validators.required],
            // price: [this.hotel.roomList[0].rooms[0].rates[0].price, Validators.required],
            // currency: ['EUR', Validators.required],
            roomList: this._formBuilder.array(roomList),
            // to show aditional data in the form
            circuitData: this.circuit
        });

        this.occupants = this._formBuilder.group({
            tolerance: 2,
            language: 'CAS',
            remarks: [''],
            circuitData: circuitFormItem
        });

        return circuitFormItem;
    }

    async addToBasketAndRedirect(microsite = false, redirectPath: string) {
        this.initBookingPost();
        return await this.basketManager.addOrUpdate(this.circuit).then((data) => {
            this.router.navigateByUrl(redirectPath, {replaceUrl: true});
        });
        /* if (this.initBookingPost()) {
          this.basketManager.addOrUpdate(this.circuit).then((data) => {
            this.router.navigateByUrl('/intranet', { replaceUrl: true });
          });
        } else {
          this.notificationPopupService.openPopup("OCCUPANTS_ERROR");
        } */
    }

    protected initBookingPost(): boolean {
        // if (this.occupants.valid) {
        const formValue = this.occupants.value;
        const bookingPost = new BookReqDto();
        bookingPost.language = formValue.language;
        bookingPost.rateKey = formValue.circuitData.circuitData.rateSelected.rateKey;
        bookingPost.provider = formValue.circuitData.circuitData.circuit.provider;
        bookingPost.rooms = this.parsePassengers(formValue.circuitData.roomList);
        this.circuit.circuitBookingPostReqDto = bookingPost;
        return true;
        // }
        // return false;
    }

    parsePassengers(rooms) {
        const circuitRooms: Array<CircuitRoom> = [];
        rooms.forEach(room => {
            const circuitRoom = new CircuitRoom();
            circuitRoom.passengers = [];
            room.adultsOccupants.forEach(occupant => {
                const passenger = new Passenger();
                passenger.age = occupant.age;
                passenger.surname = occupant.surname;
                passenger.name = occupant.name;
                passenger.gender = occupant.gender;
                passenger.documentId = occupant.documentId;
                passenger.type = occupant.type;
                circuitRoom.passengers.push(passenger);
            });
            if (room.childrensOccupants) {
                room.childrensOccupants.forEach(occupant => {
                    const passenger = new Passenger();
                    passenger.age = occupant.age;
                    passenger.surname = occupant.surname;
                    passenger.name = occupant.name;
                    passenger.gender = occupant.gender;
                    passenger.documentId = occupant.documentId;
                    passenger.type = occupant.type;
                    circuitRoom.passengers.push(passenger);
                });
                circuitRooms.push(circuitRoom);
            }
        });
        return circuitRooms;
    }

    goToSummaryOrConfirmation(microsite = false) {
        if (this.initBookingPost()) {
            this.redirectBack(microsite);
        } else {
            this.notificationPopupService.openPopup("OCCUPANTS_ERROR");
        }
    }

    redirectToConfirmation(microsite = false): string {
        return microsite ? '/bookings/confirmation' : '/integration/bookings/book-confirmation';
    }

    redirectToSummary(microsite = false): string {
        return microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
    }

    redirectBack(microsite = false) {
        this.basketManager.addOrUpdate(this.circuit).then(() => {
            this.basketManager.getList().then(basket => {
                if (basket.length === 1 && basket[0].uuid === this.circuit.uuid) {
                    this.booking(this.circuit).then(res => {
                        const navigationExtras: NavigationExtras = {
                            state: {
                                bookingPostResDto: [res]
                            }
                        };

                        const path = this.redirectToConfirmation(microsite);
                        this.router.navigate(
                            [path],
                            navigationExtras
                        );
                    });
                } else {
                    if (basket.length) {
                        const path = this.redirectToSummary(microsite);
                        this.router.navigateByUrl(path);
                    } else {
                        this.notificationPopupService.openPopup("EMPTY_BASKET");
                    }
                }
            });
        });
    }

    booking(item, saveBudget = true) {
        return this.circuitsProviderService.book(item.circuitBookingPostReqDto as BookReqDto, saveBudget)
            .toPromise()
            .then(bookingResponse => {
                bookingResponse.status = bookingResponse.status || "ok";
                bookingResponse.shoppingBasketCircuit = item;
                bookingResponse.integrationType = 'CIRCUIT';
                return bookingResponse;
            })
            .catch(err => {
                console.log(item);
                console.log(err);
                Promise.reject();
                item.failed = true;
                return item;
            });
    }
}
