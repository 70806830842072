import { Pipe, PipeTransform } from '@angular/core';
import { PriceGroup } from '@vecib2c/frontend-dto/dist';
import { TotalAmountByPassengerPipe } from './total-amount-by-passenger.pipe';
import { IFlightPax } from '../../lib-shared/interfaces/index';

@Pipe({
    name: 'totalByFlight'
})
export class TotalByFlightPipe implements PipeTransform {

    constructor(private readonly totalAmountByPassengerPipe: TotalAmountByPassengerPipe) {
    }

    transform(priceGroup: PriceGroup, pax: IFlightPax): number {
        if (!pax) {
            return 0;
        }
        const totalTickets = this.totalAmountByPassengerPipe.transform(priceGroup, 'adult', pax.adults) +
            this.totalAmountByPassengerPipe.transform(priceGroup, 'children', pax.children) +
            this.totalAmountByPassengerPipe.transform(priceGroup, 'infantas', pax.infants) +
            + priceGroup.nonCommissionableRate + priceGroup.commission + priceGroup.fee;
        return totalTickets;
    }

}
