let HOST = `https://caribe.viajeselcorteingles.es/`; 
const resolver = `/api-rest/`;
const HOST_COMPLETE = HOST + resolver;
const managerUrl = 'https://tecnoturis.herokuapp.com';
export const host = 'microsites.tecnoturis.es';

const mapBox = {
    user: 'gestyde',
    styleId: 'ck4a71ctk125d1co2gdg9x2yi',
    token: 'pk.eyJ1IjoiZ2VzdHlkZSIsImEiOiJjazQydmxoYnQwMWRlM21taWFkNGw5ajVzIn0.VB44twAE9-dek2BALjz58Q',
};

export const environment = {
    production: true,
    // logginURL: `${HOST_COMPLETE}management/api/v1/user/auth/token-auth`,
    logginURL: managerUrl + '/api/v1/auth/api-token-auth/',
    host: HOST + resolver,
    defaultVersion: 'api/v1',
    autocompleteEntity: 'map',
    user: `${resolver}user`,
    provider: `${resolver}provider`,
    agency: `${resolver}agency`,
    userHost: HOST_COMPLETE + 'user',
    providerHost: HOST_COMPLETE + 'provider',
    agencyHost: HOST_COMPLETE + 'agency',
    provinceHost: HOST_COMPLETE + 'province',
    bedsOnlineEntity: 'beds',
    bedsHost: HOST_COMPLETE + 'provider-beds',
    olympiaEntity: 'olympia',
    olympiaHost: HOST_COMPLETE + 'provider-olympia',
    graphql: HOST_COMPLETE + 'graphql-entities/graphql',
    filterHost: HOST_COMPLETE + 'filters',
    circuits: HOST_COMPLETE + 'circuits-service',
    clientHost: HOST_COMPLETE + 'management',
    nationalityHost: HOST_COMPLETE + 'client',
    lefleat: {
        tilesLayer: `https://api.mapbox.com/styles/v1/${mapBox.user}/${mapBox.styleId}/tiles/{z}/{x}/{y}?access_token=${mapBox.token}`
    },
    activities: HOST_COMPLETE + 'activities-beds',
    providers: {
        default: HOST_COMPLETE + 'providers-service',
        BedsOnline: HOST_COMPLETE + 'provider-beds',
        olympia: HOST_COMPLETE + 'provider-olympia',
        W2M: HOST_COMPLETE + 'provider-w2m',
        TOUR10: HOST_COMPLETE + 'provider-tour10',
    },
    pelias: HOST_COMPLETE + 'pelias',
    ckeditor: {
        basicstyles: {
            toolbar: [
                {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Styles', 'Format']
                }
            ]
        }
    },
    packages: {
        disney: HOST_COMPLETE + 'packages-disney',
        avoris: HOST_COMPLETE + 'packages-avoris',
        globalia: HOST_COMPLETE + 'packages-globalia',
        soltour: HOST_COMPLETE + 'packages-soltour',
        providers: HOST_COMPLETE + 'packages-providers',
    },
    flights: HOST_COMPLETE + 'providers-flight',
    insurance: HOST_COMPLETE + 'insurance',
    airports: HOST_COMPLETE + 'airports',
    hotelsAndFlights: HOST_COMPLETE + 'package-hft',
    flexibleAutos: HOST_COMPLETE + 'provider-flexibleautos',
    // management: `${HOST_COMPLETE}management/api/v1`,
    management: HOST_COMPLETE + 'management/api/v1',
    exportServices: HOST_COMPLETE + 'management/export',
    circuitsv2: HOST_COMPLETE + 'circuits-service',
    externalServices: HOST_COMPLETE + 'external-serv',
    microsites: HOST_COMPLETE + 'microsites',
    trains: HOST_COMPLETE + 'trains-trenymas',
    veciHome: 'https://www.viajeselcorteingles.es/especiales/viajes-al-caribe',
};
