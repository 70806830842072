import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {concatMap, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs';
import {DialogComponent} from "../../dialog/dialog.component";
import {FormlyForm} from '@ngx-formly/core';
// tslint:disable-next-line:import-spacing max-line-length
import {ClientCommercialActivitiesComponent} from "../client-commercial-activities/client-commercial-activities.component";
import {Location} from '@angular/common';
import {
    AgencyProviderService,
    AuthenticationService,
    DynamicCrudService,
    ManagementService
} from "../../lib-shared/services/index";
import {BaseAddEdit} from '../../base-add-edit';
import {ContactPerson, FieldType, IDynamicCrud} from '../../lib-shared/interfaces/index';
import {ManagementClientService} from '../management.client.service';
import {emailExpression} from '../../lib-shared/index';
import {TecnoturisService} from '../../tecnoturis.service';

import {MessageProviderService} from '../../lib-shared/services/message-provider.service';
import {CommercialEventService} from '../../lib-shared/services/commercial-event.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommercialEventComponent} from '../../management/commercial-event/commercial-event.component';
import {
    IClient,
    ICommercialActivityEvent,
    ICommercialActivityPersonal,
    IDialogData
} from '../../lib-shared/interfaces/commercial-event.interface';

const clientMetaData: IDynamicCrud = {
    crud: 'clients',
    name: 'Clientes',
    urlPath: 'clients/',
    extraKeySave: ['agency'],
    filters: [
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'agency_chain',
                    name: 'Cadena de agencia',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'agency',
                    name: 'Oficina',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'name',
                    name: 'Nombre',
                    type: FieldType.CHAR
                },
                {
                    keyName: 'last_name',
                    name: 'Apellido',
                    type: FieldType.CHAR
                },
                {
                    keyName: 'home_phone',
                    name: 'Teléfono',
                    type: FieldType.CHAR,
                    minLength: 1,
                    maxLength: 20
                },
                {
                    keyName: 'dni',
                    name: 'DNI',
                    type: FieldType.CHAR,
                }
            ]
        }
    ],
    fields: [
        {
            isGroup: true,
            keyName: 'datos_personales',
            name: 'Datos personales',
            fieldChilds: [
                {
                    keyName: 'code',
                    name: 'Cuenta contable',
                    type: FieldType.CHAR,
                    defaultValue: 'Sin informar',
                    disabled: true,
                },
                {
                    keyName: 'client_type',
                    name: 'Tipo Cliente',
                    type: FieldType.LIST,
                    defaultValue: 1,
                    required: true
                },
                {
                    keyName: 'agency_chain',
                    name: 'Agencia',
                    type: FieldType.LIST
                },
                {
                    keyName: 'agency',
                    name: 'Oficina',
                    type: FieldType.LIST,
                    showInTable: true
                }
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: null,
            fieldChilds: [
                {
                    keyName: 'name',
                    name: 'Nombre',
                    type: FieldType.CHAR,
                    showInTable: true,
                    required: true,
                    hideWhen: {
                        value: 2,
                        model: 'client_type'
                    },
                },
                {
                    keyName: 'last_name',
                    name: 'Apellido',
                    type: FieldType.CHAR,
                    showInTable: true,
                    hideWhen: {
                        value: 2,
                        model: 'client_type'
                    },
                },
                {
                    keyName: 'business_name',
                    name: 'Razon social',
                    type: FieldType.CHAR,
                    showInTable: true,
                    required: true,
                    hideWhen: {
                        value: 1,
                        model: 'client_type'
                    },
                },
                {
                    keyName: 'type_document',
                    name: 'Tipo de documento',
                    type: FieldType.LIST,
                    required: true,
                    hideWhen: {
                        value: 2,
                        model: 'client_type'
                    },
                },
                {
                    keyName: 'type_document_business',
                    name: 'Tipo de documento',
                    type: FieldType.LIST,
                    required: true,
                    hideWhen: {
                        value: 1,
                        model: 'client_type'
                    },
                    saveAs: 'type_document',
                },
                {
                    keyName: 'dni',
                    name: 'N° del documento',
                    type: FieldType.VALIDATE,
                    showInTable: true,
                    required: true,
                    validateUrlPath: 'agency/{id}/client/valid/dni/{value}/'
                },
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: null,
            fieldChilds: [
                {
                    keyName: 'birthdate',
                    name: 'Fecha de nacimiento',
                    type: FieldType.DATE,

                },
                {
                    keyName: 'address',
                    name: 'Dirección',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'postal_code',
                    name: 'Código postal',
                    type: FieldType.CHAR,
                    minLength: 1,
                    maxLength: 10
                },
                {
                    keyName: 'country',
                    name: 'Pais',
                    type: FieldType.CHAR,
                },
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: null,
            fieldChilds: [
                {
                    keyName: 'province',
                    name: 'Provincia',
                    type: FieldType.CHAR,
                    required: true,
                    showInTable: true,
                },
                {
                    keyName: 'locality',
                    name: 'Localidad',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'home_phone',
                    name: 'Teléfono',
                    type: FieldType.CHAR,
                    minLength: 1,
                    maxLength: 20
                },
                {
                    keyName: 'phone',
                    name: 'Móvil',
                    type: FieldType.CHAR,
                    showInTable: true,
                    minLength: 1,
                    maxLength: 20
                },
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: null,
            fieldChilds: [
                {
                    keyName: 'phone2',
                    name: 'Otro teléfono',
                    type: FieldType.CHAR,
                    minLength: 1,
                    maxLength: 20
                },
                {
                    keyName: 'email',
                    name: 'Email',
                    type: FieldType.EMAIL,
                    showInTable: true,
                    validations: [{
                        key: 'valid',
                        message: 'no tiene el formato correcto ( ejemplo@ejemplo.es )',
                        expression: emailExpression
                    }],
                    required: true,
                },
                {
                    keyName: 'permit_email',
                    name: '¿Admite email?',
                    type: FieldType.CHECKBOX,
                },
                {
                    keyName: 'down_notification',
                    name: 'Fecha de baja de notificaciones',
                    type: FieldType.DATE,
                },
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: null,
            fieldChilds: [
                {
                    keyName: 'observation',
                    name: 'Observaciones',
                    type: FieldType.TEXTAREA,
                    showInTable: false
                }
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: 'Estadísticas',
            fieldChilds: [
                {
                    keyName: 'number_dossier',
                    name: 'N° de expediente',
                    type: FieldType.CHAR,
                    disabled: true,
                    defaultValue: 1,
                },
                {
                    keyName: 'billing_period',
                    name: 'Facturación',
                    type: FieldType.CHAR,
                    disabled: true,
                    defaultValue: (new Date()).getFullYear(),
                },
                {
                    keyName: 'number_services',
                    name: 'N° de servicios',
                    type: FieldType.CHAR,
                    disabled: true,
                    defaultValue: 0,
                },
                {
                    keyName: 'benefit',
                    name: 'Margen Bruto',
                    type: FieldType.CHAR,
                    disabled: true,
                    defaultValue: 0,
                },
            ]
        },
        {
            isGroup: true,
            keyName: null,
            name: 'Datos fiscales',
            fieldChilds: [
                {
                    keyName: 'fiscal_location',
                    name: 'Localización fiscal',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'payment_method',
                    name: 'Formas de pago',
                    type: FieldType.LIST,
                }
            ]
        },
    ]
};


@Component({
    selector: 'lib-clients-add-edit',
    templateUrl: './clients-add-edit.component.html',
    styleUrls: ['./clients-add-edit.component.scss']
})
export class ClientsAddEditComponent extends BaseAddEdit implements OnInit, AfterViewInit {

    @Input() client: any;

    dynamicCrud: IDynamicCrud = clientMetaData;
    agencyId = AuthenticationService.getUser().agency.id;
    // customObjectToSave = {agency: this.agencyId};
    contacts: ContactPerson[];
    coworkers: ICommercialActivityPersonal[] = [];
    clients: any[] = [];
    showContacts: boolean;

    @ViewChild(ClientCommercialActivitiesComponent, { static: false }) commercialActivities: ClientCommercialActivitiesComponent;
    @ViewChild(FormlyForm, { static: false }) public formlyForm: FormlyForm;

    @Input() showActions = {
        back: true,
        commercialAction: true,
        dossier: true,
        presupuesto: true,
        save: true,
        files: true,
        dossiers: true,
        commercialActivities: true,
        contactPersons: true,
    };

    constructor(route: ActivatedRoute,
                router: Router,
                dynamicCrudService: DynamicCrudService,
                managementService: ManagementService,
                private managementClientService: ManagementClientService,
                private messageProviderService: MessageProviderService,
                private agendaService: CommercialEventService,
                public location: Location,
                private matDialog: MatDialog,
                private agencyProviderService: AgencyProviderService,
                private tecnoturisService: TecnoturisService,
                private snackBar: MatSnackBar) {
        super(route, router, dynamicCrudService, managementService, location);
        if (this.tecnoturisService.config.forms.clientForm) {
            this.dynamicCrud = this.tecnoturisService.config.forms.clientForm;
        }
        if (this.tecnoturisService.config.actions.client) {
            this.showActions = this.tecnoturisService.config.actions.client;
        }
        this.idValidateField = this.agencyId;
        this.agencyProviderService.getChains()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(agencyChains => {
                const agencies = [];
                agencyChains.forEach(a => agencies.push(...a.agencies));
                this.setup = {...this.setup, ...{agency_chain: agencyChains, agency: agencies}};
                this.getOptions();
            });
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.id) {
            this.managementClientService.listContacts(Number(this.id))
                .pipe(
                    takeUntil(this.unsubscribe)
                )
                .subscribe(contacts => {
                    this.contacts = contacts;
                });
        }
    }

    ngAfterViewInit(): void {
        if (this.id) {
            setTimeout(() => {
                const form = this.formlyForm.form.getRawValue();
                this.showContacts = form['client_type'] ? form['client_type'] === 2 : false;
                this.formlyForm.form.get('client_type')
                    .valueChanges
                    .pipe(
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe((clientType: number) => {
                        this.showContacts = clientType === 2;
                    });
            }, 600);
        }
    }

    addDossier(type: number): void {
        this.router.navigate(['intranet', 'management', 'dossier', 'create'], {
            queryParams: {
                client: this.id,
                type: type
            }
        });
    }

    async getParams(): Promise<void> {
        if (this.coworkers.length === 0) {
            const response = await this.agendaService.getCoworkers().toPromise();
            if (response) {
                this.coworkers = response;
            }
        }
    }

    async add(): Promise<void> {
        // this.managementService.emitCommercialEvent(this.id);
        await this.getParams();
        const today = new Date();
        const client: IClient = {
            id: this.id,
            name: this.formlyForm.form.getRawValue().name,
            last_name: this.formlyForm.form.getRawValue().last_name
        };
        const dialogData: IDialogData<ICommercialActivityEvent> = {
            meta: null,
            suggestedHour: today,
            suggestedClient: client,
            setup: this.setup,
            personal: this.coworkers
        };
        const response = await this.messageProviderService.showModalPromise(CommercialEventComponent, dialogData);
        if (response) {
            this.snackBar.open('El evento se ha grabado', null, { duration: 2000 });
            this.commercialActivities.crudComponent.list();
        }
        /*const dialogRef = this.matDialog.open(ClientCommercialActivitiesAddEditComponent, {
            panelClass: 'dialog-commercial-activities',
            data: {
                setup: this.setup,
                client: this.id
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.commercialActivities.crudComponent.list();
            }
        });*/
    }

    addContactPerson(contact: ContactPerson): void {
        contact.client = Number(this.id);
        this.managementClientService.addContact(contact)
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(contact => {
                this.contacts = [...this.contacts, contact];
            });
    }

    removeContactPerson(contactId: number): void {
        const dialogRef = this.matDialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar el registro?', id: this.id}
        });

        dialogRef.afterClosed()
            .pipe(
                concatMap((res) => {
                    if (res) {
                        return this.managementClientService.removeContact(contactId);
                    }
                    return of();
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(res => {
                this.contacts = this.contacts.filter(contact => contact.id !== contactId);
            });
    }

}
