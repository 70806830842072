import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class SearchNavbarService {
    showHide = new BehaviorSubject(true);

    showHideNavbar(hide: boolean) {
        this.showHide.next(hide);
    }

    getHideProperty() {
        return this.showHide;
    }
}
