import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AgencyProviderService, AuthenticationService, DynamicCrudService, ManagementService} from "../../../lib-shared/services/index";
import {AgencyChain} from "../../../lib-shared/interfaces/index";


@Component({
    selector: 'lib-bond',
    templateUrl: './bond.component.html',
    styleUrls: ['./bond.component.scss']
})

export class BondComponent implements OnInit {
    bond;

    id: number;
    service: string;

    unsubscribe = new Subject();

    selectedChain: AgencyChain;

    constructor(
        private dynamicCrudService: DynamicCrudService,
        private managementService: ManagementService,
        private agencyProvider: AgencyProviderService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                this.id = params['id'];
                if (this.id) {
                    this.getBond();
                }
            });
        this.route.queryParams.subscribe(res => {
            if (res.service) {
                this.service = res.service.toLowerCase();
            }
        });
    }

    async ngOnInit() {
        const user = AuthenticationService.getUser();
        this.selectedChain = await this.agencyProvider.getChain(user.agency_chain.id).toPromise();
    }

    getBond(): void {
        this.dynamicCrudService.setHost(`booking-service/${this.id}/bond/`);
        this.dynamicCrudService.request().subscribe(bond => this.bond = bond);
    }

    cancelButton() {
        return this.router.navigate(['intranet', 'management',
            this.service ? this.service + '-booking' : 'hotel-booking', 'edit', this.id]);
    }

    createPdf() {
        return this.managementService.generateBondPdf(this.id);
    }
}
