import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HotelBookServices } from '../services/hotel-book.services';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TecnoturisService } from '../../../tecnoturis.service';
import { Client } from '../../../lib-shared/interfaces/index';
import { filter } from 'rxjs/operators';
import {BasketManager, ClientServices, MicrositeService, NotificationPopupService} from '../../../lib-shared/services/index';
import { AgencyChangeService } from '../../../agency-change/index';

@Component({
    selector: 'lib-hotel-book-edit',
    templateUrl: './hotel-book-edit.component.html',
    styleUrls: ['./hotel-book-edit.component.scss'],
    providers: [HotelBookServices]
})
export class HotelBookEditComponent
    implements OnInit, OnDestroy {

    @Input() hotelFromParent;
    @Input() hotelIndex;
    @Input() showMoreServices;
    @Input() onlyResumeInfo = false;

    nationalities: any;
    indexDistribution: any;
    hotelData: any;
    hotelFormItem: any;
    arrForDistribution = [];

    @Output() validateOccupantForm: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToInsuranceSearcher = new EventEmitter<void>();
    @Output() goToActivitySearcher = new EventEmitter<void>();
    @Output() goToTransportSearcher = new EventEmitter<void>();

    constructor(
        public http: HttpClient,
        private activatedRoute: ActivatedRoute,
        protected location: Location,
        protected _hotelBookService: HotelBookServices,
        protected _basketManager: BasketManager,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected notification: NotificationPopupService,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService,
        public micrositeService: MicrositeService
    ) {
        // super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
    }

    ngOnInit(): void {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.clientService.subscription.subscribe(client => {});
        this.activatedRoute.paramMap
            .subscribe(() => {
                if (this.hotelFromParent) {
                    this._hotelBookService.hotel = this.hotelFromParent;
                    this.initForm();
                }

                this.arrForDistribution = this._hotelBookService.generateArray();
            });
    }

    async initForm() {
        this.hotelData = this._hotelBookService.hotel;
        this._hotelBookService.setDistribution();
        this.hotelFormItem = this._hotelBookService.initOccupantsForm();
        this.hotelFormItem.valueChanges.subscribe(value => {});
        // const client = await this.clientService.subscription.toPromise();
        this.clientService.subscription
            .pipe(filter(client => client && !(Object.keys(client).length === 0) && client.isPax))
            .subscribe((client: Client) => {
                const occupants = ((this.hotelFormItem.get('roomList') as FormArray).at(0).get('adultsOccupants') as FormArray).at(0);
                if (occupants.get('name').value === '') {
                    occupants.get('name').setValue(client.name);
                    occupants.get('surname').setValue(client.surname);
                }
            });
    }

    async addToBasket() {
        await this._hotelBookService.addToBasketAndRedirect();
    }

    getCategory() {
        this._hotelBookService.getCategory();
    }

    checkForm(event): void {
        const obj = {
            form: this.hotelFormItem,
            id: this.hotelIndex
        };
        this.validateOccupantForm.emit(obj);
    }

    ngOnDestroy(): void {
        // this._unsubscribe.next();
        // this._unsubscribe.complete();
    }
}
