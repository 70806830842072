import {
  Hotel,
  HotelBookingPostReqDto,
  HotelGetReqDto,
  HotelRoomDistribution,
  HotelBookingPostReqDtoOccupant,
  HotelRoomList,
  HotelRoomRate,
  HotelBookingPostReqDtoRoomDetail
} from "@vecib2c/frontend-dto";
import { roundNumber, safeNumber } from "../../../lib-shared/functions/index";
import * as  uuidv1_ from 'uuid/v1';
import { IBookingItem } from '../../../lib-shared/interfaces/bookingItem.interface';
import { IntegrationType } from '../../../lib-shared/interfaces/integration.types';
import { RateCancellationPolicy, Rerate } from '../../../lib-shared/interfaces/rerate.model';
import { Client } from '../../../lib-shared/interfaces/client.model';
import { ShoppingBasketComment } from '../../../lib-shared/interfaces/shopping-basket.model';
import { HotelBookingPostReqMapper } from '../../../lib-shared/interfaces/hotel/hotel-booking-post-req-mapper';
import { Location } from '../../../lib-shared/interfaces/multidestination/multidestination-list.model';
import { MultidestinationPreBookingByLocation } from "../../../lib-shared/interfaces/multidestination/multidestination-pre-booking.model";
import { WikiCityInfo } from "../../../lib-shared/interfaces/wiki/wiki-city-info.model";
import { ManagementPax } from "../../../lib-shared/interfaces/management.interface";
import { HotelDistributionMapper } from "../../../lib-shared/interfaces/hotel/hotel-distribution-mapper";
import { HotelBookingPaxFromManagementFactory } from '../../../lib-shared/factories/hotels/hotel-booking-pax-from-management.factory';

const uuidv1 = uuidv1_;

export class ShopingBasketHotel extends Hotel implements IBookingItem {
    uuid: string;
    hotelGetReq: HotelGetReqDto;
    client: any;
    distributionSelected?: HotelRoomDistribution;
    bookingPostReqDto: HotelBookingPostReqDto;
    comments?: HotelComment;
    totalPrice?: number;
    providerHasMultidistributionOption: boolean;
    providerName?: string;
    integrationType: IntegrationType;
    validateMessage?: string;
    checkForm?: boolean;
    reratedPrice?: number;
    originalPrice?: number;
    isSelected?: boolean;
    canBeBooked?: boolean;
    roomIndex?: number;
    failed?: boolean;
    holder?: number;

    static rerate(
        product: ShopingBasketHotel,
        reratingResponse: Rerate,
    ): ShopingBasketHotel {
        const originalPrice = roundNumber(product.roomList[0].rooms[0].rates[0].price);
        product.originalPrice = originalPrice;
        if (reratingResponse) {
            const { pvp, rateKey, cancellationPolicies, canBeBooked } = reratingResponse;
            product.reratedPrice = pvp;
            product.roomList = this.buildRoomListFromReratingResponse(
                product.roomList,
                cancellationPolicies,
                pvp,
                rateKey,
            );
            product.bookingPostReqDto = this.buildBookingPostReqDto(
                product.bookingPostReqDto,
                pvp,
                rateKey,
            );
            product.canBeBooked = canBeBooked;
        }
        return product;
    }

    static buildFromShoppingBasketByDestination(
        location: Location,
        client: Client,
        hotelComments: HotelComment,
        occupants: HotelBookingPostReqDtoOccupant[],
        preBookingResponse: MultidestinationPreBookingByLocation,
    ): ShopingBasketHotel[] {
        if (!location) { return []; }
        const { hotelResponse } = location;
        if (!hotelResponse) { return []; }
        const hotel = hotelResponse.rows[0];
        if (!hotel) { return []; }
        const {
            hotelResponse: hotelPreBookingResponses,
        } = preBookingResponse;
        const{ arrivalDate, checkOut } = location;
        return this.buildShoppingBaskets(hotel, client, hotelComments, occupants, arrivalDate, checkOut, hotelPreBookingResponses);
    }

    static initializeComments(
        item: ShopingBasketHotel,
    ): ShopingBasketHotel {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.roomList[0].rooms[0].rates[0].price) * item.roomList.length,
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShopingBasketHotel,
        paxes: ManagementPax[],
    ): ShopingBasketHotel {
        const rawBookingPostReq = item.bookingPostReqDto;
        const mappedRoomDetails: HotelBookingPostReqDtoRoomDetail[] = rawBookingPostReq.roomDetails.map(rd => {
            const mappedRoomList = rd.roomList.map(rl => {
                const mappedOccupants = HotelBookingPaxFromManagementFactory.build(
                    paxes,
                    rawBookingPostReq.roomDetails[0].roomList[0].occupants,
                );
                return {
                    ...rl,
                    occupants: mappedOccupants,
                };
            });
            return {
                ...rd,
                roomList: mappedRoomList,
            };
        });
        const mappedRoomList = item.roomList.map(rl => {
            return {
                ...rl,
                distribution: HotelDistributionMapper.buildFromOccupants(mappedRoomDetails[0].roomList[0].occupants),
            };
        });
        const rawHotelListParams = item.hotelGetReq;
        const mappedDistribution = mappedRoomList[0].distribution;
        return {
            ...item,
            distributionSelected: mappedDistribution,
            hotelGetReq: { ...rawHotelListParams, distribution: [mappedDistribution] },
            roomList: mappedRoomList,
            bookingPostReqDto: {
                ...rawBookingPostReq,
                roomDetails: mappedRoomDetails,
            },
        };
    }

    static addCityInfo(
        item: ShopingBasketHotel,
        selectedImageFromCity: string,
        imageFromCityByDefault: string,
        cityInfo: WikiCityInfo,
    ): ShopingBasketHotel {
        let imageURL: string;
        if (!selectedImageFromCity) {
            imageURL = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + imageFromCityByDefault;
        } else {
            imageURL = selectedImageFromCity;
        }

        if (!cityInfo) { return item; }
        const { title, extract } = cityInfo;
        const extraInformation: HotelComment = {
            title,
            descriptionCity: extract,
            hotelCity: title,
            mainImage: imageURL,
        };
        item.comments = { ...item.comments, ...extraInformation };

        return item;
    }

    private static buildShoppingBaskets(
        hotel: Hotel,
        client: Client,
        hotelComments: HotelComment,
        occupants: HotelBookingPostReqDtoOccupant[],
        checkIn: string,
        checkOut: string,
        hotelPreBookingResponses: HotelRoomRate[] = [],
    ): ShopingBasketHotel[] {
        if (!hotel) { return []; }

        const provider = hotel.providers[0];
        const { multidistribution, providerName } = provider;
        const { hotelId, roomList } = hotel;
        return roomList.reduce((acc, rl, i) => {
            if (multidistribution && i > 0) { return acc; }
            const thereIsPreBookingResponse = hotelPreBookingResponses.some(h => h.roomIndex === i);
            if (!thereIsPreBookingResponse) { return acc; }
            const rawShoppingBasket: ShopingBasketHotel = {
                ...hotel,
                uuid: uuidv1(),
                hotelGetReq: null,
                client,
                distributionSelected: rl.distribution,
                bookingPostReqDto: HotelBookingPostReqMapper.buildFromRoomList(
                    roomList,
                    occupants,
                    providerName,
                    hotelId,
                    checkIn,
                    checkOut,
                ),
                comments: hotelComments,
                totalPrice: rl.rooms[0].rates[0].price,
                providerHasMultidistributionOption: multidistribution,
                providerName,
                integrationType: 'HOTEL',
                roomIndex: i,
            };

            return [...acc, {
                ...rawShoppingBasket,
                roomList: provider.multidistribution ? roomList : [rl],
            }];

        }, [] as ShopingBasketHotel[]);
    }

    private static buildRoomListFromReratingResponse(
        roomList: HotelRoomList[],
        cancellationPolicies: RateCancellationPolicy[],
        pvp: number,
        rateKey: string,
    ): HotelRoomList[] {
        const arr = roomList.map(rl => {
            const rooms = rl.rooms.map(room => {
                const rates = room.rates.map(rate => {
                    return {
                        ...rate,
                        price: pvp,
                        cancellationPolicies: cancellationPolicies.map(cp => {
                            return {
                                text: cp.text,
                            };
                        }),
                        rateId: rateKey,
                    };
                });
                return {
                    ...room,
                    rates,
                };
            });
            return {
                ...rl,
                rooms,
            };
        });
        return arr;
    }

    private static buildBookingPostReqDto(
        bookingPostReqDto: HotelBookingPostReqDto,
        pvp: number,
        rateKey: string,
    ): HotelBookingPostReqDto {
        const obj = {
            ...bookingPostReqDto,
            roomDetails: bookingPostReqDto.roomDetails.map(rd => {
                return {
                    ...rd,
                    price: pvp,
                    rateId: rateKey,
                };
            }),
        };
        return obj;
    }
}

export class HotelComment extends ShoppingBasketComment {

    title?: string;
    cityTitle?: string;
    descriptionCity?: string;
    hotelCity?: string;
    mainImage?: string;

    static buildFromShoppingBasketComment(
        comment: ShoppingBasketComment,
    ): HotelComment {
        if (!comment) { return; }
        const {
            clientComment,
            agentComment,
            updatedPrice,
        } = comment;
        return {
            clientComment,
            agentComment,
            updatedPrice,
        };
    }
}

export class RoomList {
    adultsOccupants: HotelBookingPostReqDtoOccupant[];
    childrensOccupants: HotelBookingPostReqDtoOccupant[];
}
