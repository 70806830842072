import { OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HotelFlightsListResDto } from '@vecib2c/frontend-dto/dist/dto/HotelFlight';
import {
    FilterPrice,
    FlightListReqDto,
    HotelFilter,
    HotelFilterAccommodation,
    HotelFilterRate,
    HotelRoomDistribution,
    PriceGroup
} from '@vecib2c/frontend-dto/dist';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FlightProviderService, IntegrationsService, ShareService } from '../lib-shared/services/index';
import { TranslateService } from '@ngx-translate/core';
import { HotelFlightService } from '../lib-shared/services/hotel-flight.service';
import { takeUntil } from 'rxjs/operators';
import { FLIGHTS_AIRPORTS } from '../../mock/flights';
import * as moment_ from 'moment';
import { MatRadioButton } from '@angular/material/radio';

const moment = moment_;

export class HotelUtils {
    static setFilter(originalFilter) {
        const hotelFilter = new HotelFilter();

        if (originalFilter.hasOwnProperty('accommodations') || originalFilter.hasOwnProperty('stars')) {
            hotelFilter.accommodation = new HotelFilterAccommodation();
            hotelFilter.accommodation.type = originalFilter.accommodations;
            hotelFilter.accommodation.category = originalFilter.stars;
        }
        if (originalFilter.hasOwnProperty('rates') || originalFilter.hasOwnProperty('prices')) {
            hotelFilter.rate = new HotelFilterRate();
            hotelFilter.rate.category = originalFilter.rates;
            if (originalFilter.hasOwnProperty('prices')) {
                hotelFilter.rate.price = new FilterPrice();
                hotelFilter.rate.price.min = originalFilter.prices.min;
                hotelFilter.rate.price.max = originalFilter.prices.max;
            }
        }
        return hotelFilter;
    }
}

export class HotelFlightSearchList implements OnInit, OnDestroy {
    PAGE_START = 0;
    PAGE_SIZE = 10;
    parsedOrigin: string;
    parsedDestination: string;

    inputFields: any;
    search = false;
    filters = false;
    UrlSearchParameters = [
        "originIata",
        "destinyIata",
        "checkIn",
        "checkOut",
        "distribution",
        "nationality",
        "originCity",
        "originCountry",
        "originAirport",
        "destinationCity",
        "destinationCountry",
        "destinationAirport",
        "departureIsCity",
        "arrivalIsCity",
    ];
    totalItems: number;
    rows: any;
    currentPageNumber = this.PAGE_START;
    currentPageSize = this.PAGE_SIZE;
    pageSizeOptions: number[] = [5, 10, 15];
    request: any;

    hotelFlightData: HotelFlightsListResDto;

    minPrice: number;
    maxPrice: number;

    showFlightFilters = false;

    flights: PriceGroup[];

    filteredFlights: PriceGroup[];

    checkMessage: boolean;

    @ViewChild("segmentOrigin", { static: false }) segmentOrigin: MatRadioButton;
    @ViewChild("segmentDestination", { static: false }) segmentDestination: MatRadioButton;

    lowCost = false;
    tourOrientation = false;
    staticRows: any;
    hotelFilters: HotelFilter = null;

    queryParams;

    unsubscribe = new Subject();

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public share: ShareService,
        public translate: TranslateService,
        public hotelFlightService: HotelFlightService,
        public integrationService: IntegrationsService,
        public flightService: FlightProviderService
    ) {
        this.integrationService.setMetaData({
            name: "flights",
            category: "Vuelos"
        });
        this.checkMessage = false;
    }

    ngOnInit() {
        this.translate.setDefaultLang("es");
        this.route.queryParams
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                this.queryParams = params;
                if (this.isAValidQueryString(params)) {
                    this.inputFields = this.buildDto(params);
                    for (const key in FLIGHTS_AIRPORTS) {
                        if (key === this.inputFields.originIata) {
                            this.parsedOrigin =
                                FLIGHTS_AIRPORTS[key].city + " | " + FLIGHTS_AIRPORTS[key].name;
                        } else if (key === this.inputFields.destinyIata) {
                            this.parsedDestination =
                                FLIGHTS_AIRPORTS[key].city + " | " + FLIGHTS_AIRPORTS[key].name;
                        }
                    }

                    this.lowCost = this.inputFields.includedLowCost;
                    this.tourOrientation = this.inputFields.includedTourOperation;

                    this.getList();
                }
            });
    }

    isAValidQueryString(query: Params): boolean {
        let allParams = true;

        this.UrlSearchParameters.forEach(element => {
            if (!query.hasOwnProperty(element)) {
                allParams = false;
            }
        });
        return allParams;
    }

    buildDto(query: Params, router?: boolean): any {
        const dto: any = {
            country: query["country"],
            filter: null,
            languaje: "ES",
            latitude: query["latitude"],
            locality: query["locality"],
            longitude: query["longitude"],
            province: query["province"],
            radius: '100',
            originIata: query["originIata"],
            destinyIata: query["destinyIata"],
            checkIn: query["checkIn"],
            checkOut: query["checkOut"],
            pageSize: 10,
            page: 1,
            distribution: router ? JSON.stringify(query['distribution']) : JSON.parse(query['distribution']),
            includedLowCost: query["includedLowCost"].toString(),
            includedTourOperation: query["includedTourOperation"].toString(),
            nationality: query["nationality"],
            originCity: query["originCity"],
            originCountry: query["originCountry"],
            originAirport: query["originAirport"],
            destinationCity: query["destinationCity"],
            destinationCountry: query["destinationCountry"],
            destinationAirport: query["destinationAirport"],
            departureIsCity: query["departureIsCity"],
            arrivalIsCity: query["arrivalIsCity"],
        };
        return dto;
    }

    getList() {
        this.hotelFlightService.list(this.buildDto(this.inputFields, true))
            .subscribe((data) => {
                    this.hotelFlightData = data;
                    this.checkHotelsAndFlights(data);
                    this.setFlights(data.Flights);
                    if (data && data.Hotels && data.Hotels.rows) {
                        this.minPrice = (data.Hotels as any).minPriceHotels;
                        this.maxPrice = (data.Hotels as any).maxPriceHotels;
                    }
                },
                err => {
                    console.log(err);
                }
            );
    }

    checkHotelsAndFlights(data) {
        if (data.count === 0) {
            this.checkMessage = true;
        } else {
            this.checkMessage = false;
        }
    }

    pageChanged($event, container?) {
        if (container) {
            container.el.scrollIntoView({block: "start", behavior: "smooth"});
        }
        this.setPagination($event.pageIndex, $event.pageSize);
        this.paginate($event.pageIndex, $event.pageSize);
        this.filteredFlights = this.paginate($event.pageIndex, $event.pageSize);
    }

    paginate(index, size) {
        // transform values
        index = Math.abs(parseInt(index, 10));
        size = parseInt(size, 10);
        size = size < 1 ? 1 : size;
        if (this.flights) {
            // filter
            return [
                ...this.flights.filter((__, n) => {
                    return n >= index * size && n < (index + 1) * size;
                })
            ];
        }
        return [];
    }

    paginateHotels(paginate: { page: number, pageSize: number }): void {
        const queryString = {
            ...this.buildDto(this.inputFields, true),
            filter: JSON.stringify(this.hotelFilters),
            ...paginate
        };
        this.hotelFlightService.list(queryString)
            .subscribe(value => {
                this.hotelFlightData.Hotels = value.Hotels;
            });
    }

    onDataChanged($event) {
        this.staticRows = [];
        this.inputFields = $event;
        this.setPagination(this.PAGE_START, this.PAGE_SIZE);
        this.getList();
    }

    setPagination(pageNumber: number, pageSize: number) {
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
        this.paginate(pageNumber, pageSize);
    }

    toggleSearcher(nameElement) {
        switch (nameElement) {
            case 'search':
                this.search = !this.search;
                this.filters = false;
                break;
            case 'filters':
                this.filters = !this.filters;
                this.search = false;
                break;
        }
    }

    setHotelFilters(filters): void {
        this.hotelFilters = HotelUtils.setFilter(filters);
        const queryString = {...this.buildDto(this.inputFields, true), filter: JSON.stringify(this.hotelFilters)};
        this.hotelFlightService.list(queryString)
            .subscribe(value => {
                this.hotelFlightData.Hotels = value.Hotels;
            });
    }

    buildFlightRequest(params?): Partial<FlightListReqDto> {
        const dto: Partial<FlightListReqDto> = {
            checkIn: moment(this.queryParams["checkIn"], 'YYYY-MM-DD').format('DD/MM/YYYY'),
            checkOut: moment(this.queryParams["checkOut"], 'YYYY-MM-DD').format('DD/MM/YYYY'),
            origin: this.queryParams["originIata"],
            destination: this.queryParams["destinyIata"],
            includedLowCost: this.queryParams["includedLowCost"],
            includedTourOperation: this.queryParams["includedTourOperation"],
            pax: this.getPaxFlightFromHotelRoomDistribution(JSON.parse(this.queryParams['distribution']))
        };

        return dto;
    }

    getPaxFlightFromHotelRoomDistribution(hotelRoom: HotelRoomDistribution[], stringy = false): any {
        const flightPax = {
            adults: hotelRoom[0].adults,
            children: hotelRoom[0].children.length,
            infants: 0
        };
        return stringy ? JSON.stringify(flightPax) : flightPax;
    }

    showOrHideFlights(condition): void {
        this.showFlightFilters = condition;
        if (condition) {
            this.getMoreFlights();
        } else {
            this.setFlights([this.hotelFlightData.Flights]);
        }
    }

    setFlightSelectAndShowHotels(fligthSelected): void {
        this.hotelFlightData.Flights = {...fligthSelected};
    }

    setFlights(value): void {
        this.flights = [];
        this.filteredFlights = [];
        setTimeout(() => {
            this.flights = value;
            if (this.flights && this.flights.length) {
                this.pageChanged({pageIndex: 0, pageSize: 5});
            }
        }, 10);
    }

    getMoreFlights(): void {
        this.flightService.list(this.buildFlightRequest() as any)
            .subscribe(value => {
                this.setFlights(value.rows);
                this.totalItems = value.count;
            });
    }

    setFilteredFlightsFromFilter(flights): void {
        this.filteredFlights = flights.rows;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
