import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'priceText'})
export class PriceTextPipe implements PipeTransform {
    public transform(price: number, currency: string, withText: boolean = true): string {
        price = Math.ceil(price);
        price = price ? price : 0;
        return withText ? `Desde ${price} ${currency}` : `${price} ${currency}`;
    }
}
