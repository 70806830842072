import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'lib-hotel-booking-rooms-pax',
    templateUrl: './hotel-booking-rooms-pax.component.html',
    styleUrls: ['./hotel-booking-rooms-pax.component.scss']
})
export class HotelBookingRoomsPaxComponent implements OnInit, OnChanges {
    @Input() paxes: Array<any>;

    paxForm: FormGroup;

    @Input() dossier: number;

    displayedColumns = ['name', 'last_name', 'type', 'actions'];

    @Output() added: EventEmitter<any> = new EventEmitter<any>();

    @Output() delete: EventEmitter<number> = new EventEmitter<number>();

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.paxForm = this.createPaxForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.paxes) {}
    }

    createPaxForm(): FormGroup {
        return this.fb.group({
            name: [null, Validators.required],
            last_name: [null, Validators.required],
            type: [null, Validators.required],
        });
    }

    addPax(): void {
        this.added.emit(this.paxForm.getRawValue());
    }

}
