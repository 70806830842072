import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  ActivitySearchGetReqDto,
  IActivityDestination,
  IActivityDestinationW2M,
  ICountry,
} from '@vecib2c/frontend-dto';
import { } from '@vecib2c/frontend-dto';
import { ActivityService, NotificationPopupService } from '../../../lib-shared/services/index';
import * as moment_ from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

const moment = moment_;

@Component({
    selector: 'lib-activities-search',
    templateUrl: './activities-search.component.html',
    styleUrls: ['./activities-search.component.scss']
})
export class ActivitiesSearchComponent implements OnInit, OnDestroy {

    @Input() isSmall = false;
    @Input() advancedFilters = false;
    @Input() readQueryParams = false;
    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Input() lowerUpperPrices: { lower: number, upper: number };
    @Input() selectedCategories: string[];
    @Input() selectedDurations: string[];

    destinations: IActivityDestination[];
    querySearch: ActivitySearchGetReqDto;
    searchForm: FormGroup;
    countries: ICountry[];
    queryParams: ParamMap;
    from: NgbDate;
    to: NgbDate;
    paxes = [30];

    @Output() ready: EventEmitter<true> = new EventEmitter();
    @Output() reloadData: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('dateRange', { static: false }) dateRange: any;

     nameDestinations : any={
        language: "ES",
        type:"DES"
    }

    private unsubscribe = new Subject();

    constructor(
        private readonly activityService: ActivityService,
        private readonly fb: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private messageService: NotificationPopupService,
    ) {
    }

    ngOnInit() {
          this.getCountries();

        if (this.readQueryParams) {
            this.querySearch = this.getSearchQueryFromRoute();
            this.initPaxes(this.querySearch.paxes);
            this.setDateRange(this.querySearch.from, this.querySearch.to);
        } else {
            const from = moment().add(1, 'days').format('YYYY-MM-DD');
            const to = moment().add(10, 'days').format('YYYY-MM-DD');
            this.querySearch = this.initSearchQuery(from, to);
            this.setDateRange(from, to );
            this.initPaxes(this.paxes);
        }
        this.init(this.querySearch);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

 /*    async getDestinations(req: any): Promise<void> {
        await this.activityService.getDestinations(req).subscribe(value =>{

            if (value == null) {
                this.messageService.openPopup('Credenciales incorrectas o conexion incorrecta')
            }else  { this.destinations = value} 
 
        }),err =>{
             this.messageService.openPopup('no cuenta con credenciales asignadas')
            
        }
    
    } */
    async getDestinations(country: string): Promise<void> {
        this.destinations = await this.activityService.getDestinations(country).toPromise();
    }



    async getCountries(): Promise<void> {
        this.countries = await this.activityService.getCountries().toPromise();
        if (this.route.snapshot.queryParamMap.get('country')) {
            this.getDestinations(this.route.snapshot.queryParamMap.get('country'));
        }
    }

    selectCategories(categories: string[]) {
        this.searchForm.setControl('categories', this.fb.array(categories));
    }

    selectDurations(durations: string[]) {
        this.searchForm.setControl('durations', this.fb.array(durations));
    }

    selectPrices(prices: { lower: number, upper: number }): void {
        this.searchForm.get('priceFrom').setValue(prices.lower);
        this.searchForm.get('priceTo').setValue(prices.upper);
    }

    findActivities(): void {
        if (this.advancedFilters) {
            this.router.navigate([], {
                queryParams: this.querySearch,
                relativeTo: this.route,
                replaceUrl: true,
            });
        } else {
            this.router.navigate(['integration/activities/search/'],
                {queryParams: this.querySearch});
        }
    }

    updatePaxes(paxes: number[]): void {
        this.paxes = paxes;
        this.searchForm.setControl('paxes', this.fb.array(this.paxes));
    }

    private init(querySearch: ActivitySearchGetReqDto): void {
        this.searchForm = this.createForm(querySearch);
        this.searchForm.get('country').valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value) {
                    this.getDestinations(value);
                }
            });

        this.searchForm.valueChanges.
            pipe(takeUntil(this.unsubscribe))
            .subscribe(form => {
                this.reloadData.emit(true);
                this.querySearch = this.getSearchQueryFromForm();
            });
    }

    private createForm(querySearch: ActivitySearchGetReqDto): FormGroup {
        const form = this.fb.group({
            from: [querySearch.from],
            to: [querySearch.to],
            language: [querySearch.language],
            // paginationItemsPerPage: [10],
            // paginationPage: [1],
            order: [querySearch.order], /* 'DEFAULT' | 'PRICE' | 'NAME' */
            destination: [querySearch.destination,Validators.required],
            factsheet: [],
            service: [], /* Filter By Activity */
            hotel: [],
            // segment: new FormArray([]),
            service_modality: [], /* Filter by Activity Modality */
            priceTo: [querySearch.priceTo], /* Filter by Prices - To */
            priceFrom: [querySearch.priceFrom], /* Filter by Prices - from */
            latitude: [],
            longitude: [],
            giata: [],
            tti: [],
            country: [this.route.snapshot.queryParams['country']],
            categories: this.fb.array(querySearch.categories),
            durations: this.fb.array(querySearch.durations),
            paxes: this.fb.array(querySearch.paxes),
        });
        return form;
    }

    private getSearchQueryFromForm(): ActivitySearchGetReqDto {
        return this.searchForm.getRawValue() as ActivitySearchGetReqDto;
    }

    private getSearchQueryFromRoute(): ActivitySearchGetReqDto {
        const rawPaxes = this.route.snapshot.queryParams['paxes'];
        const rawCategories = this.route.snapshot.queryParams['categories'] || [];
        const rawDurations = this.route.snapshot.queryParams['durations'] || [];
        const searchQuery = Object.assign({},
          this.route.snapshot.queryParams,
          {
              paxes: Array.isArray(rawPaxes) ? rawPaxes : [+rawPaxes],
              categories: Array.isArray(rawCategories) ? rawCategories : [rawCategories],
              durations: Array.isArray(rawDurations) ? rawDurations : [rawDurations],
          },
        );
        return searchQuery as ActivitySearchGetReqDto;
    }


    private initPaxes(paxes: number[]): void {
        this.paxes = paxes;
    }

    private initSearchQuery(from: string, to: string): ActivitySearchGetReqDto {
        return {
            from: from,
            to: to,
            language: 'es',
            paxes: this.paxes,
            order: 'PRICE',
            categories: [],
            durations: [],
        } as ActivitySearchGetReqDto;
    }

    private setDateRange(from: string, to: string): void {
        this.from = this.setNgbDate(from);
        this.to = this.setNgbDate(to);
    }

    private setNgbDate(date: string): NgbDate {
        const mappedDate = moment(date).toDate();
        return new NgbDate(mappedDate.getFullYear(), mappedDate.getMonth() + 1, mappedDate.getDate());
    }

}
