import { Component, OnInit, Input, } from "@angular/core";

@Component({
    selector: 'lib-invalid-alert',
    templateUrl: './invalid-alert.component.html',
    styleUrls: ['./invalid-alert.component.scss']
})
export class InvalidAlertComponent implements OnInit {

    @Input() message: string;

    constructor() {

    }

    ngOnInit(): void {

    }

}
