import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatOptionModule } from '@angular/material';
import { TrainStationAutocompleteComponent } from './train-station-autocomplete.component';
import { TrainsProviderService } from '../lib-shared/services/trains-provider.service';

@NgModule({
    declarations: [
        TrainStationAutocompleteComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        FormsModule,
        MatInputModule,
    ],
    providers: [TrainsProviderService],
    exports: [
        TrainStationAutocompleteComponent
    ]
})
export class TrainStationAutocompleteModule {
}
