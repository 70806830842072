import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { PaymentDossier, PaymentMethod, PaymentOption } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-payment-list',
    templateUrl: './payment-list.component.html',
    styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit, OnDestroy {

    @Input() payments: PaymentDossier[];
    @Input() paymentMethods: PaymentMethod[];
    @Input() cashOptions: PaymentOption[];
    @Input() bankOptions: PaymentOption[];
    @Input() creditCardOptions: PaymentOption[];
    @Input() hideOptions: boolean;
    @Input() showDownloadOptionInColumn: boolean;

    displayedColumns = ['payment_method', 'payment_option', 'paid_amount', 'paid_date'];

    @Output() editPayment = new EventEmitter<number>();
    @Output() deletePayment = new EventEmitter<number>();
    @Output() downloadPayment = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
        if (!this.hideOptions) {
            this.displayedColumns.push('actions');
        }
        if (this.showDownloadOptionInColumn) {
            this.displayedColumns.push('download');
        }
    }

    ngOnDestroy(): void {

    }

}
