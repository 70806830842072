import {
    FlightBookingAbbreviation,
    FlightBookingDocumentType,
    FlightBookingPaxType,
    FlightPhoneType,
    IFlightBookingPax,
} from "@vecib2c/frontend-dto";
import { ManagementPax } from "../../interfaces/management.interface";
import * as moment_ from 'moment';
import { safeNumber } from "../../functions/index";

const moment = moment_;

export class FlightBookingPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialFlightPaxes: IFlightBookingPax[],
    ): IFlightBookingPax[] {
        const arr = paxes.map((pax, i) => {
            const initialFlightPax = initialFlightPaxes[i] || {} as IFlightBookingPax;
            const {
                abbreviation: initialAbbreviation,
                typeDocument: initialDocumentType,
                documentNumber: initialDocumentNumber,
                birthdate: initialBirthdate,
                expirationDate: initialExpirationDate,
                nationality: initialNationality,
                internationalCode: initialInternationalCode,
                typePax: initialPaxType,
                phoneType: initialPhoneType,
                luggageId: initialLuggageId,
                luggageNumberOption: initialLuggageNumberOption,
                luggageOption: initialLuggageOption,
                residentCityCode: initialResidentCityCode,
            } = initialFlightPax;

            const {
                name,
                last_name: lastName,
                email,
                phone,
                dni,
                birthdate,
                expiration_document: expirationDate,
                type,
                type_document: documentType,
                person_title: personTitle,
            } = pax;

            const bookingPax: IFlightBookingPax = {
                abbreviation: this.buildAbbreviation(personTitle, initialAbbreviation),
                name,
                lastName: lastName,
                phone,
                email,
                typeDocument: this.buildDocumentType(documentType, initialDocumentType),
                documentNumber: this.buildDocumentNumber(dni, initialDocumentNumber),
                birthdate: this.buildBirthdate(birthdate, initialBirthdate),
                expirationDate: this.buildExpirationDate(expirationDate, initialExpirationDate),
                nationality: this.buildNationality(initialNationality),
                internationalCode: this.buildInternationalCode(initialInternationalCode),
                residentCityCode: this.buildResidentCityCode(initialResidentCityCode),
                typePax: this.buildPaxType(type, initialPaxType),
                phoneType: this.buildPhoneType(initialPhoneType),
            };

            if (initialLuggageId && initialLuggageNumberOption && initialLuggageOption) {
                bookingPax.luggageId = initialLuggageId;
                bookingPax.luggageNumberOption = initialLuggageNumberOption;
                bookingPax.luggageOption = initialLuggageOption;
            }

            return bookingPax;

        });
        return arr;
    }

    private static buildAbbreviation(
        personTitle: string,
        initialAbbreviation: FlightBookingAbbreviation = 'Mr',
    ): FlightBookingAbbreviation {
        if (!personTitle) { return initialAbbreviation; }
        if (personTitle === 'Señor') { return 'Mr'; }
        if (personTitle === 'Señora') { return 'Ms'; }
        if (personTitle === 'Señorita') { return 'Mrs'; }
        return 'Mr';
    }

    private static buildDocumentType(
        documentType: number,
        initialDocumentType: FlightBookingDocumentType,
    ): FlightBookingDocumentType {
        if (!documentType) { return initialDocumentType; }
        if (documentType === 1) { return 'DNI'; }
        if (documentType === 2) { return 'PAS'; }
        if (documentType === 3) { return 'NIE'; }
    }

    private static buildDocumentNumber(
        documentNumber: string,
        initialDocumentNumber: string,
    ): string {
        if (!documentNumber) { return initialDocumentNumber; }
        return documentNumber;
    }

    private static buildBirthdate(
        birthdate: string,
        initialBirthdate: string,
    ): string {
        if (!birthdate) { return initialBirthdate; }
        return moment(birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    private static buildExpirationDate(
        expirationDate: string,
        initialExpirationDate: string,
    ): string {
        if (!expirationDate) { return initialExpirationDate; }
        return moment(expirationDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    private static buildNationality(
        initialNationality: string,
    ): string {
        if (!initialNationality) { return 'ES'; }
        return initialNationality;
    }

    private static buildInternationalCode(
        initialInternationalCode: number,
    ): number {
        return safeNumber(initialInternationalCode, 34);
    }

    private static buildResidentCityCode(
        initialResidentCityCode: string,
    ): string {
        if (!initialResidentCityCode) { return ''; }
        return initialResidentCityCode;
    }

    private static buildPaxType(
        paxType: string,
        initialPaxType: FlightBookingPaxType = 'Adult',
    ): FlightBookingPaxType {
        if (!paxType) { return initialPaxType; }
        const mappedPaxType = paxType.toLowerCase();
        if (['ad', 'adult'].includes(mappedPaxType)) { return 'Adult'; }
        if (['ch', 'child'].includes(mappedPaxType)) { return 'Child'; }
        if (['in', 'infant'].includes(mappedPaxType)) { return 'Infant'; }
        return 'Adult';
    }

    private static buildPhoneType(
        initialPhoneType: FlightPhoneType,
    ): FlightPhoneType {
        if (!initialPhoneType) { return 'home'; }
        return initialPhoneType;
    }
}
