import { Component, Input, OnInit } from '@angular/core';
import { BookEditComponent } from '../../book-edit.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HotelBookServices } from '../../hotels/services/hotel-book.services';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TecnoturisService } from '../../../tecnoturis.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Client } from '../../../lib-shared/interfaces/index';
import {BasketManager, ClientServices, MicrositeService, NotificationPopupService} from '../../../lib-shared/services/index';
import { BaseEntity } from '../../../lib-shared/Entities/index';
import { AgencyChangeService } from '../../../agency-change/index';

@Component({
    selector: 'lib-hf-book-edit',
    templateUrl: './hf-book-edit.component.html',
    styleUrls: ['./hf-book-edit.component.scss'],
    providers: [HotelBookServices, ClientServices]
})
export class HotelFlightBookEditComponent extends BookEditComponent
    implements OnInit {
    baseEntity: BaseEntity;
    nationalities: any;
    indexDistribution: any;
    hotelData: any;
    hotelFormItem: any;
    arrForDistribution = [];
    @Input() hotelFromParent;
    microsite = false;

    constructor(
        public http: HttpClient,
        private activatedRoute: ActivatedRoute,
        protected location: Location,
        protected _hotelBookService: HotelBookServices,
        protected _basketManager: BasketManager,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected notification: NotificationPopupService,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService,
        private micrositeService: MicrositeService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
        this.microsite = micrositeService.isMicrosite();
    }

    ngOnInit(): void {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.activatedRoute.paramMap
            .subscribe(() => {
                if (this.hotelFromParent) {
                    this._hotelBookService.hotel = this.hotelFromParent;
                    this.initForm();
                }

                this.arrForDistribution = this._hotelBookService.generateArray();
            });
    }

    initForm() {
        this.hotelData = this._hotelBookService.hotel;
        this._hotelBookService.setDistribution();
        this.hotelFormItem = this._hotelBookService.initOccupantsForm();

        this.clientService.subscription
            .pipe(filter(client => client && !(Object.keys(client).length === 0) && client.isPax), takeUntil(this._unsubscribe))
            .subscribe((client: Client) => {
                if (this.hotelFormItem) {
                    const occupants = ((this.hotelFormItem.get('roomList') as FormArray).at(0).get('adultsOccupants') as FormArray).at(0);
                    if (occupants.get('name').value === '') {
                        occupants.get('name').setValue(client.name);
                        occupants.get('surname').setValue(client.surname);
                    }
                }
            });
    }

    async addToBasket() {
        await this._hotelBookService.addToBasketAndRedirect();
    }

    getCategory() {
        this._hotelBookService.getCategory();
    }
}
