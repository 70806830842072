import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'humanizeBoolean'
})

export class HumanizeBooleanPipe implements PipeTransform {

    private booleanHumanize = {
        es: {
            'yes': 'si',
            'no': 'no',
        },
    };

    transform(booleanValue: boolean, locale = 'es'): string {
        const booleanLocale = this.booleanHumanize[locale];
        if (!booleanLocale) { return; }
        if (booleanValue) { return booleanLocale['yes']; }
        return booleanLocale['no'];
    }
}
