import { Pipe, PipeTransform } from "@angular/core";
import { HttpParams } from "@angular/common/http";

@Pipe({
    name: 'hotelDetailUrl'
})
export class HotelDetailUrlPipe implements PipeTransform {
    transform(hotelId, provider, request, roomId: string | null = null): string {

        const query = new HttpParams()
            .set('providerName', provider.providerName)
            .set('checkIn', request.checkIn)
            .set('checkOut', request.checkOut)
            .set('distribution', typeof request.distribution === 'object' ?
                JSON.stringify(request.distribution) : request.distribution)
            .set('room', roomId === null ? '' : roomId)
            .set('latitude', request.latitude)
            .set('longitude', request.longitude)
            .set('locality', request.locality)
            .set('province', request.province)
            .set('country', request.country)
            .set('nationality', request.nationality);

        return window.location.origin + `/#/integration/hotels/detail/${hotelId}?${query}`;
    }
}
