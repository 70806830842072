import { Occupancy } from '../response/transfer.response.dto';

export class PreBookingTransferRequestDto {
    provider: string;
    sessionID: string;
    transferID: string;
    vehicleQuantity?: number; 
    extra?: ExtrasRequest;
    occupancy: Occupancy
    totalAmount: number;
    commission: ComissionsTransfers
}

export class ExtrasRequest {
    code: string;
    quantitty: string;
}

export class ComissionsTransfers {
    price: number;
    pvp: number;
    iva: number;
    fee: number;
    commissionRate: number;
    commission: number
}