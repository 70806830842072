import { Component, Input, OnInit } from "@angular/core";
import { Hotel } from "@vecib2c/frontend-dto";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { BasketManager } from '../../../lib-shared/services/BasketManager';

@Component({
    selector: "lib-tecnoturis-navbar",
    templateUrl: "./tecnoturis-navbar.component.html",
    styleUrls: ["./tecnoturis-navbar.component.scss"]
})
export class TecnoturisNavbarComponent implements OnInit {
    icons = [{code: "&#xe916;"}];
    details: Array<any>;
    hotelList: Array<Hotel>;
    basketObserver$: BehaviorSubject<any>;
    roomsNumber = 0;

    @Input() disableRouting = false;

    constructor(
        private basketManager: BasketManager,
        public router: Router
    ) {
        this.basketObserver$ = this.basketManager.getSubscription(),
            this.getRoomsNumber();
    }

    ngOnInit() {
    }

    getRoomsNumber() {
        this.basketObserver$.subscribe(data => {
            this.roomsNumber = data.length;
        });
    }

    back() {
        this.router.navigateByUrl("intranet");
    }
}
