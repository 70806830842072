import { CircuitBookingPostReqDtoPassengerType, HotelRoomDistribution, Passenger } from "@vecib2c/frontend-dto";

export class CircuitDistributionFromPassengerFactory {

    static build(
        paxes: Passenger[]
    ): HotelRoomDistribution {
        const obj = paxes.reduce((acc, pax) => {
            if (pax.type === CircuitBookingPostReqDtoPassengerType.Adult) {
                return { ...acc, adults: acc.adults + 1 };
            }
            if (pax.type === CircuitBookingPostReqDtoPassengerType.Child) {
                return { ...acc, children: [...acc.children, pax.age] };
            }
            return acc;
        }, { rooms: 1, adults: 0, children: [] } as HotelRoomDistribution);
        return obj;
    }
}
