import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ShoppingBasketHFS } from '../../../integrations/hotels/index';
import { BasketManager, HotelFlightService, NotificationPopupService } from '../../../lib-shared/services/index';

@Injectable()
export class HFBookServices {
    nationalities = [];
    insurance: ShoppingBasketHFS;
    insureds: FormGroup;

    constructor(
        protected _formBuilder: FormBuilder,
        protected router: Router,
        protected basketManager: BasketManager,
        private notificationPopupService: NotificationPopupService,
        protected hotelFlightService: HotelFlightService,
    ) {
    }

    booking(item, saveBudget = true, client) {
        item.client = client;
        return this.hotelFlightService.book(item, saveBudget)
            .toPromise()
            .then(data => {
                data.status = data.status || 'ok';
                // data.shoppingBasketInsurance = item;
                return data;
            })
            .catch(err => {
                Promise.reject();
                item.failed = true;
                return item;
            });
    }
}
