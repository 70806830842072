import { Component, Input } from "@angular/core";
import { ShoppingBasketMultidestination } from "../../../lib-shared/interfaces/index";
import { ShoppingCartSummaryComponent } from '../../shopping-cart-summary.component';

@Component({
    selector: 'lib-multidestination-cart-item',
    templateUrl: './multidestination-cart-item.component.html',
    styleUrls: ['./multidestination-cart-item.component.scss']
})
export class MultidestinationCartItemComponent {

    @Input() item: ShoppingBasketMultidestination;

    constructor(
        public shoppingCartSummaryComponent: ShoppingCartSummaryComponent
    ) {
    }
}
