import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DynamicCrudService, ManagementService } from '../../lib-shared/services/index';
import { DialogComponent } from '../../dialog/index';

@Component({
    selector: 'lib-hotel-booking-rooms',
    templateUrl: './hotel-booking-rooms.component.html',
    styleUrls: ['./hotel-booking-rooms.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class HotelBookingRoomsComponent implements OnInit, OnChanges {

    @Input() rooms: Array<any>;

    roomForm: FormGroup;

    @Input() hotelBooking: number;

    displayedColumns = ['room_type', 'regimen', 'quantity', 'actions'];

    @Output() added: EventEmitter<any> = new EventEmitter<any>();

    roomTypes: Array<any>;

    regimes: Array<any>;

    expandedElement: any;

    @Input() rowDetail = true;

    @Input() emit = false;

    constructor(private fb: FormBuilder,
                private managementService: ManagementService,
                private route: ActivatedRoute,
                private dynamicCrudService: DynamicCrudService,
                private dialog: MatDialog) {
        this.route.data
            .subscribe(data => {
                if (data.setup) {
                    this.roomTypes = data['setup']['room_type'];
                    this.regimes = data['setup']['hotel_regime'];
                }
            });
    }

    ngOnInit() {
        this.roomForm = this.createPaxForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.rooms) {
            if (this.rooms) {
                this.rooms = this.rooms.map(r => {
                    r.detailRow = true;
                    return r;
                });
            }
            this.roomForm = this.createPaxForm();
        }
    }

    createPaxForm(): FormGroup {
        return this.fb.group({
            room_type: [null, Validators.required],
            regimen: [null, Validators.required],
            quantity: [1, Validators.required]
        });
    }

    addPax(room: number, pax): void {
        this.dynamicCrudService.setHost('hotel-booking-rooms-pax/');
        this.dynamicCrudService.add({hotel_room: room, pax}).subscribe((value: any) => {
            if (this.emit) {
                this.added.emit();
            } else {
                const roomIndex = this.rooms.findIndex(r => r.id === room);
                this.rooms[roomIndex].pax.push(value);
                this.rooms[roomIndex].pax = [...this.rooms[roomIndex].pax];
                this.rooms = [...this.rooms];
            }
        });
    }

    addRoom(): void {
        this.managementService.addRoom({...this.roomForm.getRawValue(), hotel_booking_service: this.hotelBooking})
            .subscribe(value => {
                if (this.emit) {
                    this.added.emit();
                } else {
                    this.rooms.push(value);
                    this.rooms = [...this.rooms];
                }
            });
    }

    deleteRoom(id: number): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            panelClass: ['dialog-panel'],
            data: {question: '¿Quieres eliminar el registro?', id: id}
        });

        dialogRef.afterClosed().pipe(
            concatMap((param: number) => {
                this.dynamicCrudService.setHost('hotel-booking-rooms/');
                return param ? this.dynamicCrudService.delete(id) : of();
            }))
            .subscribe(res => {
                if (this.emit) {
                    this.added.emit();
                } else {
                    this.rooms = this.rooms.filter(p => p.id !== id);
                    this.rooms = [...this.rooms];
                }
            });
    }

    deletePax(room: number, pax): void {
        this.managementService.deleteRoomPax(room, pax)
            .subscribe(value => {
                if (this.emit) {
                    this.added.emit();
                } else {
                    const roomIndex = this.rooms.findIndex(r => r.id === room);
                    this.rooms[roomIndex].pax = this.rooms[roomIndex].pax.filter(p => p.id !== pax);
                    this.rooms[roomIndex].pax = [...this.rooms[roomIndex].pax];
                    this.rooms = [...this.rooms];
                }
            });
    }

}
