import { Pipe, PipeTransform } from "@angular/core";
import { ShoppingBasketFlight } from "../../../integrations/hotels/dto/ShoppingBasketFlight";
import { ShoppingBasketByDestination } from '../../interfaces/index';

@Pipe({
    name: 'fetchFlightShoppingBasketsByDestination'
})
export class FetchFlightShoppingBasketsByDestination implements PipeTransform {

    transform(
        shoppingBasketsByDestination: ShoppingBasketByDestination[],
        destinationIndex: number,
    ): ShoppingBasketFlight[] {
    if (!shoppingBasketsByDestination) { return; }
    const shoppingBaskets = shoppingBasketsByDestination.find(s => s.destinationIndex === destinationIndex);
    if (!shoppingBaskets) { return; }
    return shoppingBaskets.flights;
  }
}
