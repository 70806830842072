import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SpinnerLoaderService } from '../../service/SpinnerLoader.service';

@Component({
    selector: 'app-spinner-loading',
    templateUrl: './spinner-loading.component.html',
    styleUrls: ['./spinner-loading.component.scss'],
})
export class SpinnerLoadingComponent implements OnInit {

    diameter: number;
    isLoading: Subject<boolean> = this.spinnerLoader.isLoading;

    constructor(private spinnerLoader: SpinnerLoaderService) {
        this.diameter = 42;
    }

    ngOnInit() {
    }

}
