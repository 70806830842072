import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatSelectChange } from '@angular/material/select';
import { IFilterCoches } from '../../../lib-shared/interfaces/index';

@Component({
    selector: 'lib-transport-search-container',
    templateUrl: './transport-search-container.component.html',
    styleUrls: ['./transport-search-container.component.scss']
})
export class TransportSearchContainerComponent implements OnInit, OnDestroy {

    tabs = [{id: 0, name: 'Trenes'}, {id: 1, name: 'Buses'}, {id: 2, name: 'Coches'}];
    tabControl: FormControl = new FormControl('transportes');
    unsubscribe = new Subject();
    currentTab = 2;

    @Input() inputFields: any;
    @Input() isSmall = false;
    @Input() filters: IFilterCoches = {};

    @Output() dataChanged = new EventEmitter<any>();
    @Output() selectKindOfTransport = new EventEmitter<string>();

    @Input() cocheRouting: string;
    @Input() trainsRouting: string;

    constructor(private route: ActivatedRoute) {
        this.route.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
                if (params['type']) {
                    this.tabControl.setValue(params['type']);
                }
            });
    }

    ngOnInit() {
        const path = window.location.href;
        if (path.includes('trenes')) {
            this.tabControl.setValue('trenes');
        }
        if (path.includes('transfers')){
            this.tabControl.setValue('traslados')
        }
    }

    handleTab(event) {
        this.currentTab = event.index;
    }

    getIndexTab(value: string) {
        switch (value) {
            case 'trenes':
                return 0;
            case 'buses':
                return 1;
            case 'coches':
                return 2;
            case 'traslados':
            return 3;
        }
    }

    getTypeTransport(currentTab) {
        switch (currentTab) {
            case 0:
                return 'Buscar Trenes';
            case 1:
                return 'Buscar Buses';
            case 2:
                return 'Buscar Coches';
            case 3:
                return 'Buscar Traslados';
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    changeTransport(event: MatSelectChange): void {
        this.selectKindOfTransport.emit(event.value);
    }

}
