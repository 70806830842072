import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
    selector: 'lib-popover-price',
    templateUrl: './popover-price.component.html',
    styleUrls: ['./popover-price.component.scss']
})
export class PopoverPriceComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() netPrice: number;
    @Input() commission: number;
    @Input() fee: number;
    @Input() iva: number;
    @Input() totalPrice: number;
    @Input() flightPrice: number;
    @Input() safePrice: number;
    @Input() currency: string;

    @Input() showPopover = false;

    @ViewChild(MdePopoverTrigger, { static: false }) mdePopover: MdePopoverTrigger;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showPopover) {
            // if (this.mdePopover) {
            //     if (this.showPopover) {
            //         this.mdePopover.openPopover();
            //     } else {
            //         this.mdePopover.closePopover();
            //     }
            // }
        }
    }

    ngAfterViewInit(): void {
        if (this.mdePopover) {
            if (this.showPopover) {
                this.mdePopover.openPopover();
            } else {
                this.mdePopover.closePopover();
            }
        }
    }

}
