import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'lib-residents',
  templateUrl: './residents.component.html',
  styleUrls: ['./residents.component.scss']
})
export class ResidentsComponent implements OnInit, OnDestroy {

  @Input() label: string;
  @Input() numberOfResidents = 0;
  @Input() minimumOfResidents = 0;
  @Input() set maximumOfResidents(val: number) {
    if (val < this.numberOfResidents) {
      this.adjust.emit();
    }
    this._maximumOfResidents = val;
  }
  get maximumOfResidents(): number {
    return this._maximumOfResidents;
  }
  @Input() disabled: boolean;

  @Output() update = new EventEmitter<string>();
  @Output() adjust = new EventEmitter<void>();

  private _maximumOfResidents: number;

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }

  increment(): void {
    if (this.numberOfResidents >= this.maximumOfResidents) { return; }
    this.update.emit('increment');
  }

  decrement(): void {
    if (this.numberOfResidents <= this.minimumOfResidents) { return; }
    this.update.emit('decrement');
  }

}
