import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'htmlremove'
    })
    export class HtmlremovePipe implements PipeTransform {

      transform(value: string): string {
        const div = document.createElement("div");
        div.innerHTML = value;
        const text = div.textContent || div.innerText || "";
        return text;
      }
    }
