import { Component } from '@angular/core';
import {Location} from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {map, takeUntil, filter} from "rxjs/operators";

import { TransferService } from '../../../lib-shared/services/transfer.service';
import { BookEditComponent } from '../../book-edit.component';
import { ShoppingBasketTransfer } from '../../../lib-shared/interfaces/shopping-basket-transfers.model';
import { Transfers, Search, } from '../../../lib-shared/interfaces/transfers/response/transfer.response.dto';
import { AuthenticationService } from '../../../lib-shared/services/authentication.service';
import { ClientServices } from '../../../lib-shared/services/client.services';
import { NotificationPopupService } from '../../../lib-shared/services/notification-popup.service';
import { BasketManager } from '../../../lib-shared/services/BasketManager';
import { AgencyChangeService } from '../../../agency-change/agency-change.service';
import { TecnoturisService } from '../../../tecnoturis.service';
import  {ROLES } from '../../../const';
import { TransferDocument, BookingRequestTransfer, TransferPax, transferPaxType, PassengerTransfer, transferDocuments } from '../../../lib-shared/interfaces/transfers/request/booking-create.request.dto';
import { Client } from '../../../lib-shared/interfaces/client.model';
import { NifValidator } from '../../../lib-shared';
import { ManagementService } from '../../../lib-shared/services/management.service';
import { IntegrationsService } from '../../../lib-shared/services/integrations.service';


// import { TransferServiceResponse } from '../../../lib-shared';

@Component({
    selector: 'lib-transfer-book-edit',
    templateUrl: './transfer-book-edit.component.html',
    styleUrls: ['./transfer-book-edit.component.scss'],
    providers: [
        TransferService
    ]
})

export class TransferBookEditComponent extends BookEditComponent {

    myForm: any;

    pax: PassengerTransfer
    transfers: Transfers;
    
    search: Search;
    shoppingBasket: ShoppingBasketTransfer;
    totalPax: number;
    number: number = 1
    adults: number
    children: number
    infants: number
    showButton: boolean = true
    fromTime: string;
    toTime: string;
    
    statusResponse: number;

    transferDocument: TransferDocument[] = transferDocuments
    transferPaxType: TransferPax[] =  transferPaxType;

    code: string;
    companyName: string;
    placeholder: string;
    uuid: string;
    hotelToHotel: boolean = false

    confirmedPrivacyPolicy: boolean = false;
    renderDisabled: boolean = false;

    idBooking: number;

    selected: string = "Adulto"
    ageErrorMessage: string = "La edad tiene que estar comprendida entre 18 y 59 años";


    private unsubscribe = new Subject<void>();
    auth = AuthenticationService.getUser();

    constructor(
        protected location: Location,
        public http: HttpClient,
        protected clientService: ClientServices,
        protected notification: NotificationPopupService,
        protected translate: TranslateService,
        protected activatedRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder, 
        private readonly transferService: TransferService,
        protected router: Router,
        protected _basketManager: BasketManager,
        protected agencyChangeService: AgencyChangeService,
        public tecnoturisService: TecnoturisService,
        private managementService: ManagementService,
        private integrationsService: IntegrationsService,
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService, agencyChangeService);
    }

    async ngOnInit() {
        this.activatedRoute.paramMap
            .pipe(
                map(() => window.history.state),
                takeUntil(this.unsubscribe),
            )
            .subscribe(async (state) => {
                if(state.transfer){
                    this.adults = parseInt(state.transfer.occupancy.adults)
                    this.children = parseInt(state.transfer.occupancy.children)
                    this.infants = parseInt(state.transfer.occupancy.infants)
                    this.totalPax = this.adults + this.children + this.infants 
                    if(this.totalPax > 1){
                        this.showButton = true
                    }
                    this.shoppingBasket = state.transfer;
                    this.renderFromTime(this.shoppingBasket.from.dateTime);
                    this.renderToTime(this.shoppingBasket.to.dateTime);
                    this.shoppingBasket.integrationType = "TRANSFER"
                    if(this.shoppingBasket){
                        this.initForm();
                        this.addPassenger(0);
                    }
                } else {
                    const uuid = this.activatedRoute.snapshot.params['id'];
                    const response = await this._basketManager.getList();
                    const transfer = response.find(item => item.uuid === uuid);
                    this.shoppingBasket = transfer;
                }   
                if(this.shoppingBasket){
                    this.adults = parseInt(this.shoppingBasket.occupancy.adults)
                    this.children = parseInt(this.shoppingBasket.occupancy.children)
                    this.infants = parseInt(this.shoppingBasket.occupancy.infants)
                    this.totalPax = this.adults + this.children + this.infants 
                    if(this.totalPax > 1){
                        this.showButton = true
                    }
                    this.renderFromTime(this.shoppingBasket.from.dateTime);
                    this.renderToTime(this.shoppingBasket.to.dateTime);
                    this.initForm();
                    this.addPassenger(0);
                }      
            })
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    redirectToConfirmation(): string {
        return this.microsite ? '/bookings/confirmation' : '/integration/bookings/book-confirmation';
    }

    redirectToSummary(): string {
        return this.microsite ? '/bookings/summary' : '/integration/bookings/book-summary';
    }

    goToSummaryOrConfirmation(): void {
        if (!super.checkClient()) {
            return;
        }
        const request = this.buildResquest();
        this.shoppingBasket.requestFromBasket = request
        this.shoppingBasket.paxes = request.pax;
        this._basketManager.addOrUpdate(this.shoppingBasket).then(() => {
            this._basketManager.getList().then(basket => {
                if (basket.length === 1 && basket[0].uuid === this.shoppingBasket.uuid) {
                    this.transferService.bookTransfer(request, true)
                        .pipe(
                            map(res => {
                                this.statusResponse = res.status
                                return res
                            }),
                            takeUntil(this.unsubscribe),
                        )
                        .subscribe(async(bookingResponse) => {
                            if(this.statusResponse != 200){
                                this.notification.openPopup("error");
                                this.location.back();
                            } else {
                                const client = await this.clientService.getClient();
                                const dossier = this.integrationsService.validAndGetDossier();

                                delete this.shoppingBasket.requestFromBasket;

                                this.shoppingBasket.totalPrice = bookingResponse.data.totalPrice;
                                this.shoppingBasket.locator = bookingResponse.data.locator;
                                this.shoppingBasket.transfer = bookingResponse.data.transferServicesBooked;

                                const data = {transferData: [this.shoppingBasket], client: client.id, dossier: dossier};

                                const dossierInfo =  await this.managementService.addServicesToDossier(data).toPromise();

                                this.shoppingBasket.id = dossierInfo[0].id

                                const navigationExtras: NavigationExtras = {
                                    state: {
                                        bookingPostResDto: [this.shoppingBasket],
                                    }
                                }
                                const path = this.redirectToConfirmation();
                                this.router.navigate([path], navigationExtras);
                            }
                        })  
                } else {
                    if(basket.length){
                        const path = this.redirectToSummary();
                        this.router.navigateByUrl(path);
                    } else {
                        this.notificationPopupService.openPopup("EMPTY_BASKET");
                    }
                }
            })
        })
    }

    addToBasketAndRedirect(): void {
        if (super.checkClient()) {
            const request = this.buildResquest();
            this.shoppingBasket.requestFromBasket = request
            this.shoppingBasket.paxes = request.pax;
            this._basketManager.addOrUpdate(this.shoppingBasket)
                .then(() => {
                const path = this.microsite ? '/home' : '/intranet';
                this.router.navigateByUrl(path, {replaceUrl: true});
            });
        }
    }

    get passenger(): FormArray {
        return this.myForm.get('passenger') as FormArray
    }

    private initForm(): void {
        this.myForm = this.formBuilder.group({
            passenger: this.formBuilder.array([])
        })

        this.clientService.subscription
            .pipe(
                filter(client => client && !(Object.keys(client).length === 0) && client.isPax))
            .subscribe((client: Client) => {
                if (client && !(Object.keys(client).length === 0)) {
                    const occupants = ((this.myForm.get('passenger') as FormArray)).at(0);
                    occupants.get('name').setValue(client.name);
                    occupants.get('secondSurname').setValue(client.surname);
                    occupants.get('email').setValue(client.email);
                }
            });
    }

    newPassenger(passenger: PassengerTransfer): FormGroup {
        return this.formBuilder.group({
            firstName: new FormControl("jose", [Validators.required]),
            lastName: new FormControl("pepe", [Validators.required]),
            email: new FormControl("a@a.com", [Validators.required, Validators.email]),
            phone: new FormControl("1234567", [Validators.required, Validators.pattern("(^([0-9]{7,15})$)")]),
            documentType: new FormControl("DNI", [Validators.required]),
            documentNumber: new FormControl("53733062W", [Validators.required, NifValidator]),
            age: new FormControl("32", [Validators.required, Validators.min(18), Validators.max(59)]),
            id: new FormControl("1",),
            typeId: new FormControl("A", [Validators.required]),
            transportNumberArr: new FormControl("fr152", Validators.pattern("^((?:[A-z][A-z]|[a-z][0-9]|[0-9][A-z])[A-z]?)([0-9]{1,4}[A-z]?)$")),
            transportNumberDep: new FormControl("", Validators.pattern("^((?:[A-z][A-z]|[a-z][0-9]|[0-9][A-z])[A-z]?)([0-9]{1,4}[A-z]?)$")),
        })
    }

    changeTypeId(){
        for(let i = 0; i < this.passenger.controls.length; i++){
            this.passenger.controls[i].parent.controls[i].get("age").clearValidators();
            if(this.passenger.controls[i].value.typeId == "A"){
                this.passenger.controls[i].parent.controls[i].get("age")
                    .setValidators([Validators.required, Validators.min(18), Validators.max(59)]);
            }
            if(this.passenger.controls[i].value.typeId == "C"){
                this.passenger.controls[i].parent.controls[i].get("age")
                    .setValidators([Validators.required, Validators.min(3), Validators.max(17)]);
                this.ageErrorMessage = "La edad tiene que estar comprendida entre 3 y 17 años"
            }
            if(this.passenger.controls[i].value.typeId == "B"){
                this.passenger.controls[i].parent.controls[i].get("age")
                    .setValidators([Validators.required, Validators.min(0), Validators.max(2)]);
                this.ageErrorMessage = "La edad tiene que estar comprendida entre 0 y 2 años"
            }
            if(this.passenger.controls[i].value.typeId == "S"){
                this.passenger.controls[i].parent.controls[i].get("age")
                    .setValidators([Validators.required, Validators.min(60), Validators.max(99)]);
                this.ageErrorMessage = "La edad tiene que estar comprendida entre 60 y 99 años"
            }
        }
    }

    addPassenger(input) {
        this.number += input;
        if(this.number >= this.totalPax){
            this.showButton = false
        }
        this.passenger.push(this.newPassenger(this.pax))
    }

    deletePassenger(index: number, input: number) {
        this.passenger.removeAt(index);
        this.number -= input;
        this.showButton = true;
    }

    private buildResquest(): BookingRequestTransfer {
        const rawForm = this.myForm.getRawValue();
        const request = new BookingRequestTransfer();

        request.transferType = this.shoppingBasket.from.transferTypeCode;
        request.refNumberKey = this.shoppingBasket.locator;
        request.sessionId = this.shoppingBasket.sessionID;
        request.provider = this.shoppingBasket.provider;

        if(this.shoppingBasket.from.transferTypeCode == "ROU"){
            request.arrivalInfo.arrivalCode = this.shoppingBasket.from.code
            request.arrivalInfo.arrivalTime = this.shoppingBasket.from.dateTime
            request.arrivalInfo.transportNumber = rawForm.passenger[0].transportNumberArr
            request.departureInfo.departureCode = this.shoppingBasket.to.code
            request.departureInfo.departureTime = this.shoppingBasket.to.dateTime
            request.departureInfo.transportNumber = rawForm.passenger[0].transportNumberDep

        }

        if(this.shoppingBasket.from.transferTypeCode == "ARR"){
            request.arrivalInfo.arrivalCode = this.shoppingBasket.from.code
            request.arrivalInfo.arrivalTime = this.shoppingBasket.from.dateTime
            request.arrivalInfo.transportNumber = rawForm.passenger[0].transportNumberArr
        }

        if(this.shoppingBasket.from.transferTypeCode == "DEP"){
            request.departureInfo.departureCode = this.shoppingBasket.from.code
            request.departureInfo.departureTime = this.shoppingBasket.from.dateTime
            request.departureInfo.transportNumber = rawForm.passenger[0].transportNumberArr
        }

        let list = []
        if(rawForm.passenger[0].firstName != null){
            for(let i = 0; i < rawForm.passenger.length; i ++){
                delete rawForm.passenger[i].transportNumberArr
                delete rawForm.passenger[i].transportNumberDep
                rawForm.passenger[i].id = (1 + i).toString();
                list.push(rawForm.passenger[i]);
            }
            request.pax = list;
        } else {
            request.pax[0].documentType = "";
            request.pax[0].typeId = "";
            request.pax[0].age = "";
        }
        
        request.contact.firstName = rawForm.passenger[0].firstName;
        request.contact.lastName = rawForm.passenger[0].lastName;
        request.contact.phone = rawForm.passenger[0].phone;
        request.contact.email = rawForm.passenger[0].email;

        request.totalPrice = this.shoppingBasket.totalPrice
        request.commission = this.shoppingBasket.commission

        request.occupancy = this.shoppingBasket.occupancy

        return request
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }

    confirmPolicy(){
        this.confirmedPrivacyPolicy = !this.confirmedPrivacyPolicy;
        if(this.confirmedPrivacyPolicy){
            this.renderDisabled = true
        } else {
            this.renderDisabled = false
        }
    }

    onSubmit(){
        this.myForm.valid
    }

    renderFromTime(input){
        const date = input;
        const [yyyy,mm,dd,hh,mi] = date.split(/[/:\-T]/)
        this.fromTime = hh+":"+mi
        return this.fromTime
    }

    renderToTime(input){
        const date = input;
        const [yyyy,mm,dd,hh,mi] = date.split(/[/:\-T]/)
        this.toTime = hh+":"+mi
        return this.toTime
    }

}

