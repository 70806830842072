import { SalesGroup } from "../response/trains.response.dto";

export class TrainSaleGroupMapper {

    static calculatePVP(saleGroup: SalesGroup): number {
        const val = saleGroup.cabins[0].paxes.reduce((accumulator, pax) => {
            accumulator = accumulator + (pax.fares[0].commission.pvp * pax.paxNumber);
            return accumulator;
        }, 0);
        return val;
    }

    static calculateNetPrice(saleGroup: SalesGroup): number {
        const val = saleGroup.cabins[0].paxes.reduce((accumulator, pax) => {
            accumulator = accumulator + (pax.fares[0].curNetTa * pax.paxNumber);
            return accumulator;
        }, 0);
        return val;
    }
}
