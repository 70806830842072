import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IPriceRangeFilter } from '../lib-shared/interfaces/filter.interface';

@Component({
    selector: 'lib-price-range',
    templateUrl: './price-range.component.html',
    styleUrls: ['./price-range.component.scss']
})
export class PriceRangeComponent implements OnInit, OnDestroy {

    @Input() minPrice: number;
    @Input() maxPrice: number;
    @Input() currency: string;
    @Input() debounceTime = 50;
    @Input() lowerUpperPrices: IPriceRangeFilter;

    @Output() rangePriceChange: EventEmitter<IPriceRangeFilter> = new EventEmitter<IPriceRangeFilter>();

    rangePriceSubject: Subject<IPriceRangeFilter> = new Subject();
    isLoaded = false;

    private unsubscribe = new Subject();

    constructor() {
    }

    ngOnInit() {
        if (!this.lowerUpperPrices) {
            this.lowerUpperPrices = {lower: this.minPrice, upper: this.maxPrice};
        }
        this.rangePriceSubject
            .pipe(takeUntil(this.unsubscribe), debounceTime(this.debounceTime))
            .subscribe(value => {
                this.rangePriceChange.emit(value);
            });
    }

    changePrices(prices: IPriceRangeFilter): void {
        this.lowerUpperPrices = prices;
        if (this.isLoaded) {
            this.rangePriceSubject.next(this.lowerUpperPrices);
        }
        this.isLoaded = true;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
