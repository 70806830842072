import { NgModule } from '@angular/core';
import {
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { IslandPackageSearchComponent } from './island-package-search.component';
import { PaxesPackageModule } from '../paxes-package/paxes-package.module';
import { PaxesModule } from '../../../paxes/index';
import { DateRangeModule } from '../../../date-range/index';
import { LibSharedModule } from '../../../lib-shared/index';
import { FilterPackagesSearchModule } from '../filter-packages-search/filter-packages-search.module';
import { FilterPackagesModule } from '../filter-packages/filter-packages.module';

@NgModule({
    declarations: [IslandPackageSearchComponent],
    exports: [
        IslandPackageSearchComponent
    ],
    imports: [
        LibSharedModule,
        PaxesPackageModule,
        MatTabsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        PaxesModule,
        DateRangeModule,
        IonicModule,
        MatIconModule,
        MatSelectModule,
        FilterPackagesSearchModule,
        FilterPackagesModule
    ]
})
export class IslandPackageSearchModule {
}
