import { FieldType, IDynamicCrud } from '../../lib-shared/interfaces/index';
import { emailExpression } from '../../lib-shared/index';

export const flightFormMetaData: IDynamicCrud = {
    crud: 'flight-booking',
    name: 'Reserva',
    urlPath: 'booking-service/',
    urlPathList: 'clients/dossier/id/flight-booking/',
    extraKeySave: ['flights'],
    crudFiles: {
        path: 'booking-service/files/',
        urlPathList: 'booking-service/id/files/',
        key: 'booking_service',
    },
    fields: [
        {
            isGroup: true,
            keyName: '',
            name: 'Datos generales',
            fieldChilds: [
                {
                    keyName: 'code',
                    name: 'N° Servicio',
                    type: FieldType.CHAR,
                    removeOnCreate: true,
                    readonly: true
                },
                {
                    keyName: 'confirmed_by',
                    name: 'Confirmado por',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'locator',
                    name: 'Localizador',
                    type: FieldType.CHAR,
                },
                {
                    keyName: 'confirmed_date',
                    name: 'Fecha de confirmación',
                    type: FieldType.DATE,
                    showInTable: true
                },
                {
                    keyName: 'fiscal_location',
                    name: 'Localización fiscal (destino)',
                    type: FieldType.LIST,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'description',
                    name: 'Descripción',
                    type: FieldType.CHAR,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos del proveedor',
            fieldChilds: [
                {
                    keyName: 'provider_accountant_account',
                    name: 'Cuenta contable',
                    type: FieldType.CHAR,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'provider',
                    name: 'Nombre del proveedor',
                    type: FieldType.LIST,
                },
                {
                    keyName: 'payment_method',
                    name: 'Forma de Pago',
                    type: FieldType.LIST,
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'payday_limit',
                    name: 'Fecha límite de pago',
                    type: FieldType.DATE,
                    disabled: false
                },
                {
                    keyName: 'product_type',
                    name: 'Tipo de producto',
                    type: FieldType.LIST
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Datos de comisiones',
            fieldChilds: [
                {
                    keyName: 'fee_apply',
                    name: 'Tipo comisión',
                    type: FieldType.LIST
                },
                {
                    keyName: 'comission',
                    name: '% Comisión',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 100
                },
                {
                    keyName: 'iva',
                    name: 'IVA',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva',
                    name: 'IVA de Compra',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'iva_pay',
                    name: 'IVA de Venta',
                    type: FieldType.NUMBER,
                    min: 0,
                    max: 21,
                    hideNotWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'pvp_fee',
                    name: 'Importe Comisionable',
                    type: FieldType.NUMBER,
                    min: 0,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                },
                {
                    keyName: 'not_fee_amount',
                    name: 'Importe no comisionable (tasas)',
                    type: FieldType.NUMBER,
                    hideWhen: {
                        value: 3,
                        model: 'fee_apply'
                    },
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: '',
            fieldChilds: [
                {
                    keyName: 'total_pvp',
                    name: 'Total PVP',
                    type: FieldType.NUMBER,
                    readonly: true
                },
                {
                    keyName: 'discount',
                    name: 'Descuentos',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'fee',
                    name: 'Fee Agencia',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'total_net',
                    name: 'Total Neto a pagar',
                    type: FieldType.NUMBER
                },
                {
                    keyName: 'profit_gross',
                    name: 'Margen Bruto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                },
                {
                    keyName: 'profit',
                    name: 'Margen Neto',
                    type: FieldType.NUMBER,
                    readonly: true,
                    disabled: true
                }
            ]
        },
        {
            isGroup: true,
            keyName: '',
            name: 'Observaciones generales',
            fieldChilds: [
                {
                    keyName: 'general_observation',
                    name: '',
                    type: FieldType.TEXTAREA
                }
            ]
        }
    ],
    dynamicCruds: [
        {
            crud: 'flight-travelers',
            name: 'Pasajeros',
            urlPath: 'booking-service/pax/',
            extractDataFrom: 'pax',
            extractIsArray: false,
            appendKey: 'booking_service',
            fields: [
                {
                    isGroup: true,
                    keyName: '',
                    name: 'Pasajeros',
                    fieldChilds: [
                        {
                            keyName: 'person_title',
                            name: 'Titulo',
                            type: FieldType.LIST,
                        },
                        {
                            keyName: 'name',
                            name: 'Nombre',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'last_name',
                            name: 'Apellidos',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                    ]
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: [
                        {
                            keyName: 'birthdate',
                            name: 'Fecha de nacimiento',
                            type: FieldType.DATE,
                            showInTable: true
                        },
                        {
                            keyName: 'phone',
                            name: 'Teléfono',
                            type: FieldType.CHAR,
                            showInTable: true,
                            minLength: 1,
                            maxLength: 20
                        },
                        {
                            keyName: 'email',
                            name: 'Email',
                            type: FieldType.CHAR,
                            showInTable: true,
                            validations: [{
                                key: 'valid',
                                message: 'no tiene el formato correcto ( ejemplo@ejemplo.es )',
                                expression: emailExpression
                            }],
                        },
                    ]
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: [
                        {
                            keyName: 'type_document',
                            name: 'Tipo de documento',
                            type: FieldType.LIST,
                            showInTable: true
                        },
                        {
                            keyName: 'dni',
                            name: 'Número de documento',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'expiration_document',
                            name: 'Fecha de expiración',
                            type: FieldType.DATE,
                            showInTable: true
                        }
                    ]
                },
            ],
        },
        {
            crud: 'flight-segments',
            name: 'Vuelos',
            urlPath: 'booking-service/flight/',
            extractIsArray: true,
            extractDataFrom: 'flight_booking_segment',
            fields: [
                {
                    isGroup: true,
                    keyName: '',
                    name: 'Vuelos',
                    fieldChilds: [
                        {
                            keyName: 'flight_number',
                            name: 'N° de vuelo',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'departure',
                            name: 'Origen',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                        {
                            keyName: 'arrival',
                            name: 'Destino',
                            type: FieldType.CHAR,
                            showInTable: true
                        },
                    ]
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: [
                        {
                            keyName: 'departure_at',
                            name: 'Fecha y hora de Salida',
                            type: FieldType.DATETIME,
                            showInTable: true
                        },
                        {
                            keyName: 'arrival_at',
                            name: 'Fecha y hora de llegada',
                            type: FieldType.DATETIME,
                            showInTable: true
                        },
                        {
                            keyName: 'airline',
                            name: 'Aerolinea',
                            type: FieldType.CHAR,
                            showInTable: true
                        }
                    ]
                },
                {
                    isGroup: true,
                    keyName: '',
                    name: '',
                    fieldChilds: [
                        {
                            keyName: 'flight_booking_service',
                            name: '',
                            hidden: true,
                            type: FieldType.CHAR
                        }
                    ]
                },
            ],
        }
    ]
};
