import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TecnoturisService } from '../../tecnoturis.service';

@Injectable({
    providedIn: "root"
})
export class AutocompleteService {

    constructor(
        public http: HttpClient,
        private tecnoturisService: TecnoturisService
    ) {
    }

    private getPath(action, pelias = false) {
        const environment = this.tecnoturisService.config;
        const host = pelias ? environment.pelias : environment.provinceHost;
        let list;
        if (pelias) {
            list = [`${host}`, "api/v1", action];
        } else {
            list = [`${host}`, environment.defaultVersion, environment.autocompleteEntity, action];
        }
        return list.join("/");
    }

    searchPelias(text, req?): Observable<any> {
        let params = new HttpParams().set('text', text);
        if (req) {
            Object.keys(req).forEach(function (key) {
                params = params.append(key, req[key]);
            });
        }
        return this.http.get(this.getPath("map/search", true), {params: params})
            .pipe(
                map(res => Object.values(res['rows']))
            );
    }

    search(text): Observable<any> {
        return this.http.get(this.getPath("search") + `?text=${text}`);
    }

    details(text): Observable<any> {
        return this.http.get(this.getPath("details") + `?text=${text}`);
    }

    searchApi({url, method = 'get', params = {}}): Observable<any> {
        return this.http[method](url, params);
    }
}
