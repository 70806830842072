import { safeNumber, splitLastName } from '../../functions/index';
import { PassengerTransfer, TransferPaxType, TransferDocumentType } from '../../interfaces';
import { ManagementPax } from "../../interfaces/management.interface";

export class TransferPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        initialTransferPaxes: PassengerTransfer[],
    ): PassengerTransfer[] {
        const arr = paxes.map((pax, i) => {
            const initialTransferPax = initialTransferPaxes[i] || {} as PassengerTransfer;
            const {
                age: initialAge,
                documentationNumber: initialDocumentationNumber,
                documentType: initialDocumentType,
                typeId: initialTypeId,
                phone: initialPhone,
                firstName: initialFirstName,
                lastName: initialLastName,
                email: initialEmail,

            } = initialTransferPax;

            const {
                name: firstName,
                last_name: lastName,
                dni,
                age,
                type,
                type_document: documentType,
                phone,
                email,
            } = pax;

            const splittedLastName = splitLastName(lastName);

            const bookingPax: PassengerTransfer = {
                age: this.buildAge(age, initialAge),
                firstName,
                lastName: this.buildLastName(splittedLastName[0], initialLastName),
                documentationNumber: this.buildDocumentNumber(dni, initialDocumentationNumber),
                typeId: this.buildPaxType(type, initialTypeId),
                documentType: this.buildDocumentType(documentType, initialDocumentType),
                phone: this.buildPhone(phone, initialPhone),
                email: this.buildEmail(email, initialEmail),
            };

            return bookingPax;
        });
        return arr;
    }

    private static buildAge(
        age: number | string,
        initialAge: number | string,
    ): number {
        if (!age) { return safeNumber(initialAge, 30); }
        return safeNumber(age, 30);
    }

    private static buildLastName(
        lastName: string,
        initialFirstName = '',
    ): string {
        if (!lastName) { return initialFirstName; }
        return lastName;
    }

    private static buildDocumentNumber(
        documentNumber: string,
        initialDocumentNumber: string,
    ): string {
        if (!documentNumber) { return initialDocumentNumber; }
        return documentNumber;
    }

    private static buildPaxType(
        paxType: string,
        initialPaxType: TransferPaxType = 'Adulto',
    ): TransferPaxType {
        if (!paxType) { return initialPaxType; }
        const mappedPaxType = paxType.toLowerCase();
        if (['ad', 'adult', 'adulto'].includes(mappedPaxType)) { return 'Adulto'; }
        if (['ch', 'child', 'niño'].includes(mappedPaxType)) { return 'Niño'; }
        if (['in', 'infant', 'infante', 'babie'].includes(mappedPaxType)) { return 'Bebe'; }
        return 'Adulto';
    }

    private static buildDocumentType(
        documentType: number,
        initialDocumentType: TransferDocumentType = 'DNI',
    ): TransferDocumentType {
        if (!documentType) { return initialDocumentType; }
        if (documentType === 1) { return 'DNI'; }
        if (documentType === 2) { return 'PAS'; }
        if (documentType === 3) { return 'NIE'; }
        return 'DNI';
    }

    private static buildPhone(
        phone: string,
        initialPhone = '',
    ): string {
        if (!phone) { return initialPhone; }
        return phone;
    }

    private static buildEmail(
        email: string,
        initialEmail = '',
    ): string {
        if (!email) { return initialEmail; }
        return initialEmail;
    }
}