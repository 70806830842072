import { safeNumber } from "../../functions/index";
import { ManagementPax } from "../../interfaces/management.interface";
import { CarBookingDriver } from "../../interfaces/cars/booking.req.model";

export class CarPaxFromManagementFactory {

    static build(
        paxes: ManagementPax[],
        bookingDriver: CarBookingDriver,
    ): CarBookingDriver {
        const pax = paxes[0];
        const {
            driverAge: initialAge,
            driverTitle: initialTitle,
        } = bookingDriver;

        const {
            name,
            last_name: lastName,
            age,
            person_title: personTitle,
        } = pax;

        const bookingPax: CarBookingDriver = {
            driverTitle: this.buildTitle(personTitle, initialTitle),
            driverInitial: name,
            driverLastName: lastName,
            driverAge: this.buildAge(age, initialAge),
        };

        return bookingPax;
    }

    private static buildTitle(
        personTitle: string,
        initialTitle = 'Mr.',
    ): string {
        if (!personTitle) { return initialTitle; }
        if (['Señor', 'Sr'].includes(personTitle)) { return 'Mr.'; }
        if (['Señora', 'Señorita', 'Sra', 'Srita'].includes(personTitle)) { return 'Mrs.'; }
        return 'Mr.';
    }

    private static buildAge(
        age: number,
        initialAge: number,
    ): number {
        if (!age) { return safeNumber(initialAge, 30); }
        return safeNumber(age, 30);
    }
}
