import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SERVICES } from '../../const';

@Injectable({
    providedIn: 'root'
})
export class MicrositeService {
    protected micrositeBookingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    protected microsite = false;

    constructor() {
    }

    getBookingData(): Observable<any> {
        return this.micrositeBookingSubject.asObservable();
    }

    setBookingData(service: SERVICES, data: any): void {
        this.micrositeBookingSubject.next({service, data});
    }

    initMicrosite(): void {
        this.microsite = true;
    }

    isMicrosite(): boolean {
        return this.microsite;
    }
}
