import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormArray, FormGroup } from "@angular/forms";
import * as moment_ from 'moment';
import { Subject } from 'rxjs';
import { ListAirBookBaggage } from '@vecib2c/frontend-dto';
import { COUNTRIES } from '../../../../mock/countries';
import {
    IMunicipality,
    ILuggage,
    FlightAbbreviationType,
    FlightDocumentType,
    FlightPhoneType,
    FlightBaggage,
} from '../../../lib-shared/interfaces/index';

const moment = moment_;

enum Pax {
    adults = 'Adulto',
    children = 'Niño',
    infants = 'Infante',
}

@Component({
    selector: "lib-flight-paxes",
    templateUrl: "./flight-paxes.component.html",
    styleUrls: ["./flight-paxes.component.scss"]
})
export class FlightPaxesComponent implements OnInit, OnDestroy {

    @Input() form: FormGroup;
    @Input() kind: string;
    @Input() abbreviations: FlightAbbreviationType[];
    @Input() typeDocuments: FlightDocumentType[];
    @Input() luggages: ILuggage[];
    @Input() isLowCost: boolean;
    @Input() numberOfResidents: number;
    @Input() phoneTypes: FlightPhoneType[];
    @Input() commissionRate: number;
    @Input() iva: number;

    pax: string;
    countries = {};
    minDate: Date;
    maxDate: Date;
    expirationDate: Date;
    selectedLuggages = [];

    @Output() chooseLuggages = new EventEmitter<FlightBaggage[]>();

    private unsubscribe = new Subject<void>();

    constructor() {
    }

    ngOnInit() {
        this.pax = Pax[this.kind];
        this.countries = COUNTRIES;
        this.setDates();
    }

    get getFormData() {
        return <FormArray>this.form.get(this.kind);
    }

    setDateValue(control: string, index: number, date: Date): void {
        const formattedDate = date ? moment(date).format('DD/MM/YYYY') : null;
        this.getFormData.controls[index].get(control).setValue(formattedDate);
        this.getFormData.controls[index].get(control).markAsTouched();
    }

    setDates(): void {
        if (this.kind === 'adults') {
            this.minDate = new Date(1900, 0, 1);
            this.maxDate = moment().subtract(18, 'year').toDate();
        } else {
            this.minDate = moment().subtract(18, 'year').toDate();
            this.maxDate = new Date();
        }
        this.expirationDate = moment().add(1, 'years').toDate();
    }

    selectLuggage(luggage: ListAirBookBaggage, i: number): void {
        if (luggage) {
            this.getFormData.controls[i].get('luggageOption').setValue(luggage.luggageOption);
            this.getFormData.controls[i].get('luggageNumberOption').setValue(luggage.numberOption);
            this.getFormData.controls[i].get('luggageId').setValue(luggage.id);
        } else {
            this.getFormData.controls[i].get('luggageOption').setValue(null);
            this.getFormData.controls[i].get('luggageNumberOption').setValue(null);
            this.getFormData.controls[i].get('luggageId').setValue(null);
        }
        this.selectedLuggages[i] = luggage;
        this.chooseLuggages.emit(this.selectedLuggages);
    }

    selectMunicipality(municipality: IMunicipality, i: number): void {
        this.getFormData.controls[i].get('residentCityCode').setValue(municipality.code);
        this.getFormData.controls[i].get('residentCityCode').markAsTouched();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
