
export class Occupant {
    public name: string;
    public surname: string;
    public type: string;
}

export class RoomDetail {
    public bookKey: string;
    public roomId: string;
    public occupants: Occupant[];
}

export class BookingPost {
    public tolerance: number;
    public remarks: string;
    public holder: Holder;
    public roomDetails: RoomDetail[];
}

export class Holder {
    name: string;
    surname: string;
}
