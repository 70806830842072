import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { ActivityBookingPutResDto } from "@vecib2c/frontend-dto";
import { NotificationPopupService, ActivityService } from "../../../lib-shared/services/index";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-activity-book-confirmation',
  templateUrl: './activity-book-confirmation.component.html',
  styleUrls: ['./activity-book-confirmation.component.scss'],
})

export class ActivityBookConfirmationComponent implements OnInit, OnDestroy {

  @Input() bookingResponse: ActivityBookingPutResDto;

  isCancelled = false;

  private unsubscribe = new Subject();

  constructor(
      private readonly notificationPopupService: NotificationPopupService,
      private readonly acitvityService: ActivityService
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }

  cancel(referenceCode: string, dossierId: number) {
      this.acitvityService.cancelBooking({ language: 'es', referenceCode })
      .pipe(
          takeUntil(this.unsubscribe),
      )
      .subscribe((res:any) => {
          if (res.status == "CANCELLED") {
            this.isCancelled = true;
            this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.MESSAGE_CANCEL_BOOKING");
            this.acitvityService.RemoveDossier(dossierId).toPromise()
          }else{
            this.isCancelled = false;
            this.notificationPopupService.openPopup("BOOKING.CONFIRMATION.ERROR_CANCEL_BOOKING");
          }
          
      });
  }
}
