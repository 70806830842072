import { InsuranceBookReqDto, InsurancePolicy, InsuranceSearchDto } from "@vecib2c/frontend-dto";
import { roundNumber, safeNumber } from "../../../lib-shared/functions/index";
import { IBookingItem, IntegrationType, ManagementPax, Rerate, ShoppingBasketComment } from '../../../lib-shared/interfaces/index';
import { InsurancePaxFromManagementFactory } from "../../../lib-shared/factories/insurances/insurance-pax-from-management.factory";

export class ShopingBasketInsurance implements IBookingItem {
    uuid: string;
    query: InsuranceSearchDto;
    client: any;
    insuranceBookingPostReqDto?: InsuranceBookReqDto;
    insurance: InsurancePolicy;
    validateMessage?: string;
    checkForm?: boolean;
    integrationType: IntegrationType;
    reratedPrice?: number;
    originalPrice?: number;
    isSelected?: boolean;
    canBeBooked?: boolean;
    comments?: ShoppingBasketComment;

    static rerate(
        product: ShopingBasketInsurance,
        reratingResponse: Rerate,
    ): ShopingBasketInsurance {
        const originalPrice = roundNumber(
            safeNumber(product.insurance.totalPrice) +
            safeNumber(product.insurance.toppingsPrice)
        );
        product.originalPrice = originalPrice;
        if (reratingResponse) {
            const { pvp, nonCommissionableRate, canBeBooked } = reratingResponse;
            product.reratedPrice = pvp;
            product.insurance.totalPrice = pvp - nonCommissionableRate;
            product.insurance.toppingsPrice = nonCommissionableRate;
            product.canBeBooked = canBeBooked;
        }

        return product;
    }

    static initializeComments(
        item: ShopingBasketInsurance,
    ): ShopingBasketInsurance {
        item.comments = {
            clientComment: '',
            agentComment: '',
            updatedPrice: safeNumber(item.insurance.totalPrice) + safeNumber(item.insurance.toppingsPrice),
        };

        return item;
    }

    static updateFromManagementBudget(
        item: ShopingBasketInsurance,
        paxes: ManagementPax[],
    ): ShopingBasketInsurance {
        const initialBookingParams = item.insuranceBookingPostReqDto;
        const mappedPaxes = InsurancePaxFromManagementFactory.build(paxes, initialBookingParams.insureds);
        const insureds = mappedPaxes.length;
        const mappedBookingParams: InsuranceBookReqDto = Object.assign({}, initialBookingParams, {
            insureds: mappedPaxes,
            insuredsTotal: insureds,
        });
        const mappedListParams: InsuranceSearchDto = Object.assign({}, item.query, {
            insureds,
        });

        return {
            ...item,
            insuranceBookingPostReqDto: mappedBookingParams,
            query: mappedListParams,
        };
    }
}
