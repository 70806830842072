import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from "@ngx-translate/core";

import { BaseEntity } from "./BaseEntity";
import { TecnoturisService } from '../../tecnoturis.service';

export class EntityPage extends BaseEntity implements OnInit {
    fieldBlackList = ["active", "createdAt", "updatedAt", "deletedAt"];
    fieldEditBlackList = ["id", "active", "createdAt", "updatedAt", "deletedAt"];
    entity = "user";
    entities = "users";
    loading: boolean;
    id;
    data: any = {};
    form: FormGroup;
    submitted = false;
    public entityService;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public translate: TranslateService,
        public http: HttpClient,
        public tecnoturisService: TecnoturisService,
        public snackBar?: MatSnackBar,
    ) {
        super(http, tecnoturisService);
    }

    ngOnInit() {
        if (this.route.snapshot.params["id"]) {
            this.loadData(this.route.snapshot.params["id"]);
        }
        this.initForm();
    }

    loadData(id) {
        this.id = id;

        this.entityService.get(id).subscribe(response => {

            const data = {};
            for (const key in response) {
                if (!(this.fieldBlackList.indexOf(key) > -1)) {
                    data[key] = response[key];
                }
            }

            this.form.setValue(data);
            this.postLoadData(data);
        });
    }

    postLoadData(data) {

    }

    initForm() {
        this.translate.setDefaultLang("es");
        this.form = this.formBuilder.group({});
    }

    sanitize(input, forbidenFields?) {
        forbidenFields = forbidenFields || [];
        const data = {};
        for (const key in input) {
            if ((input[key] || typeof input[key] === "boolean") && !(forbidenFields.indexOf(key) > -1)) {
                data[key] = input[key];
            }
        }

        return data;
    }

    performAction() {
        if (this.id) {
            this.update();
        } else {
            this.create();
        }
    }

    create() {
        this.preCreate();
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }


        const data = this.sanitize(this.form.value);

        this.entityService.create(data).subscribe(data => {
            this.postCreate().then(() => {
                this.form.reset();
                if (this.snackBar) {
                    this.openSnackBar('Grabado satisfactorio');
                }
            });
        });
    }

    preCreate() {

    }

    postCreate() {
        return Promise.resolve();
    }

    update() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        const data = this.sanitize(this.form.value, this.fieldEditBlackList);
        this.entityService.update(this.id, data).subscribe(data => {
            this.postUpdate().then(() => {
                this.router.navigateByUrl(this.entities);
            });
        });
    }

    postUpdate() {
        return Promise.resolve();
    }

    getAction() {
        const action = this.id ? "SAVE" : "CREATE";
        return this.translate.instant(action);
    }

    shouldWarnRequired(fieldName) {
        return (
            fieldName in this.form.controls &&
            !this.form.controls[fieldName].valid &&
            this.submitted
        );
    }

    cancel() {
        this.router.navigateByUrl(this.entities);
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, null, {
            duration: 2000,
        });
    }
}
