import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { BookEditComponent } from '../../book-edit.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { HotelFlightBookEditComponent } from '../book-edit/hf-book-edit.component';
import { HotelBookingPostReqDto, InsuranceBookReqDto, Segment } from '@vecib2c/frontend-dto';
import * as moment_ from 'moment';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HFBookServices } from '../services/hf-book.services';
import { TecnoturisService } from '../../../tecnoturis.service';
import { HotelBookServices } from '../../hotels/services/hotel-book.services';
import { ShoppingBasketFlight, ShoppingBasketHFS } from '../../../integrations/hotels/index';
import { ShopingBasketInsurance } from '../../../integrations/insurances/index';
import { InsurancesBookServices } from '../../insurances/services/insurances-book.services';
import saveAs from 'file-saver';
import { AuthenticationService, BasketManager, ClientServices, NotificationPopupService } from '../../../lib-shared/services/index';
import { ROLES } from '../../../const';
import { phoneTypes } from "../../../../mock/flights";
import { FlightPhoneType } from '../../../lib-shared/interfaces/index';

const moment = moment_;

@Component({
    selector: 'lib-hf-wrapper-book-edit',
    templateUrl: './hf-book-edit-wrapper.component.html',
    styleUrls: ['./hf-book-edit-wrapper.component.scss']
})
export class HotelFlightWrapperBookEdit extends BookEditComponent implements OnInit {

    @Input() hotel;
    hotels = [];
    @ViewChildren(HotelFlightBookEditComponent) childs;
    @Input() onlyResumeInfo = false;

    /// Flights
    priceGroup: any = [];
    dspNone: boolean;
    changeIcon: boolean;
    pax;
    form: FormGroup;
    abbreviations = [
        {id: 'Mr', name: 'Sr'},
        {id: 'Ms', name: 'Sra'}
    ];
    typeDocuments = [
        {id: 'DNI', name: 'DNI'},
        {id: 'NIE', name: 'NIE'},
        {id: 'PAS', name: 'PASAPORTE'},
        {id: 'IT/CF', name: 'IT/CF'},
        {id: 'CIF', name: 'CIF'},
        {id: 'NIE', name: 'NIE'},
        {id: 'Comunitario', name: 'Comunitario'},
        {id: 'Menor sin NIF', name: 'Menor sin NIF'}
    ];
    request: any;
    cancelPolicies: any = [];
    bookRequest: ShoppingBasketFlight;

    bookRequestV2: ShoppingBasketHFS;
    hotelFormItem: FormGroup;
    arrForDistribution = [];
    hotelData: any;
    bookingPostReqDto = [];

    safe: any;
    bookRequestSafe: ShopingBasketInsurance;
    insuranceFormItem: FormGroup;
    documentTypes = [
        {value: 'NIF'},
        {value: 'IT/CF'},
        {value: 'CIF'},
        {value: 'NIE'},
        {value: 'Comunitario'},
        {value: 'Resto del mundo'},
        {value: 'Menor sin NIF'}
    ];
    resignationSafeCheck = false;
    auth = AuthenticationService.getUser();
    phoneTypes: FlightPhoneType[] = phoneTypes;

    constructor(
        protected _insurancesBookServices: InsurancesBookServices,
        public http: HttpClient,
        private activatedRoute: ActivatedRoute,
        protected location: Location,
        protected _hotelBookService: HotelBookServices,
        protected _basketManager: BasketManager,
        protected clientService: ClientServices,
        protected translate: TranslateService,
        protected notification: NotificationPopupService,
        protected router: Router,
        public formBuilder: FormBuilder,
        protected _hotelflightService: HFBookServices,
        public tecnoturisService: TecnoturisService
    ) {
        super(location, http, clientService, notification, translate, tecnoturisService);
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(map(() => window.history.state))
            .subscribe(state => {
                if (state.safe) {
                    this.safe = state.safe;
                    this._insurancesBookServices.insurance = state.safe;
                    this.initFormSafe();
                }
                if (state.flight) {
                    this.request = state.flight;
                    // los vuelos de ida empiezan por 1xxx y los de vuelta por 2xxx, si no se ordenan se muestran mal
                    this.request.itineraries.sort((a, b) => {
                        return a.id - b.id;
                    });
                }
                if (state.hotel) {
                    this.hotels = state.hotel;
                }

                if (!state.flight) {
                    this.id = this.activatedRoute.snapshot.params['id'];
                    this._basketManager.getList().then(data => {
                        const hf = data.find(item => item.uuid === this.id);
                        if (hf) {
                            this.request = hf.clonedData[0].flight;
                            this.safe = hf.clonedData[0].safe;
                            this.hotels = hf.clonedData[0].hotel;
                            this._insurancesBookServices.insurance = this.safe;
                            this.initFormSafe();
                            this.priceGroup = this.request.clonedData;
                            this.priceGroup.total = parseFloat(this.priceGroup.total).toFixed(2);
                            this.initComponent(hf.clonedData[0].flight.paxes);
                        }
                    });
                } else {
                    this.loadFlightData();
                    this.initComponent();
                    this.prepareInsuredsBook();
                    this.prepareObject();
                    this.prepareHotelsObject();
                }

            });
    }

    checkSafeCheck(value) {
        if (value === false) {
            this.downloadPdfResignationSafe();
        }
    }

    downloadPdfResignationSafe() {
        const pdfUrl = 'assets/files/pdf/renuncia-al-seguro.pdf';
        const pdfName = 'renuncia-al-seguro.pdf';
        saveAs(pdfUrl, pdfName);
    }

    initFormSafe(): void {
        this.insuranceFormItem = this._insurancesBookServices.initInsuredsForm();
        this.insuranceFormItem.disable();
    }

    get insuredFormArray(): FormArray {
        return (this.insuranceFormItem.get('insureds') as FormArray);
    }

    async addToBasketAndRedirect(redirectPath?: string) {
        this.prepareInsuredsBook();
        this.prepareObject();
        this.prepareHotelsObject();
        this.bookRequestV2 = this.setReqParametersV2(this.request);
        this.setPaxHotelsInsurance();
        if (super.checkClient()) {
            await this._basketManager.addOrUpdate(this.bookRequestV2).then(async () => {
                const path = !redirectPath ? this.redirectToPath() : redirectPath;
                return this.router.navigateByUrl(path, {replaceUrl: true});
            });
        }
    }

    mapDocTypeSafe(docType): string {
        switch (docType) {
            case 'DNI':
                return 'NIF';
            case 'PAS':
                return 'Resto del mundo';
            default:
                return docType;
        }
    }

    setPaxHotelsInsurance() {
        // Seguros
        const arrayInsureds = [];

        this.bookRequestV2.clonedData[0].flight.paxes.forEach((pax, idPax) => {
            const objInsured = {
                country: {
                    countryCode: this.bookRequestV2.clonedData[0].safe.query.codeCountry,
                    countryCodeAlpha: this.bookRequestV2.clonedData[0].safe.query.codeCountryAlpha,
                },
                name: pax.name,
                surname: pax.lastName,
                documentType: this.mapDocTypeSafe(pax.typeDocument),
                documentNumber: pax.documentNumber,
                isMain: null
            };
            if (idPax === 0) {
                objInsured.isMain = true;
            } else {
                objInsured.isMain = false;
            }
            arrayInsureds.push(objInsured);
        });
        this.bookRequestV2.clonedData[0].safe.insuranceBookingPostReqDto.insureds = arrayInsureds;

        // Hoteles
        let countPaxDist = 0;
        let countPaxDistBefore = 0;

        this.bookRequestV2.clonedData[0].hotel.forEach(hotel => {
            const arrayOccupantsHotel = [];
            countPaxDist = countPaxDist + hotel.roomList[0].distribution.adults + hotel.roomList[0].distribution.children.length;
            this.bookRequestV2.clonedData[0].flight.paxes.forEach((pax, idPax) => {
                let objOccuppant;
                if (countPaxDistBefore !== 0) {
                    const countPaxDiff = countPaxDist - countPaxDistBefore;
                    if (idPax > countPaxDiff && idPax < countPaxDist) {
                        objOccuppant = {
                            name: pax.name,
                            surname: pax.lastName,
                            type: this.mapTypeOccuppant(pax.typePax),
                            age: this.calculateage(pax.birthdate)
                        };
                        arrayOccupantsHotel.push(objOccuppant);
                    }
                } else if (countPaxDist > idPax && countPaxDistBefore === 0) {
                    objOccuppant = {
                        name: pax.name,
                        surname: pax.lastName,
                        type: this.mapTypeOccuppant(pax.typePax),
                        age: this.calculateage(pax.birthdate)
                    };
                    arrayOccupantsHotel.push(objOccuppant);
                }
            });
            countPaxDistBefore = hotel.roomList[0].distribution.adults + hotel.roomList[0].distribution.children.length;
            hotel.bookingPostReqDto.roomDetails[0].roomList[0].occupants = arrayOccupantsHotel;
        });

    }

    mapTypeOccuppant(paxType): string {
        switch (paxType) {
            case 'Adult':
                return 'AD';
            case 'Child':
                return 'CH';
            case 'Infant':
                return 'CH';
            default:
                return;
        }
    }

    calculateage(birthdate: string): number {
        const formattedDate = moment(birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const today = moment();
        return today.diff(formattedDate, "years");
    }

    async goToSummaryOrConfirmation() {
        if (super.checkClient()) {
            this.prepareInsuredsBook();
            this.prepareObject();
            this.prepareHotelsObject();
            this.bookRequestV2 = this.setReqParametersV2(this.request);
            this.setPaxHotelsInsurance();
            await this._basketManager.addOrUpdate(this.bookRequestV2);
            await this._basketManager.getList().then(data => {
                if (data.length === 1) {
                    this.clientService.getClient().then(client => {
                        this._hotelflightService.booking(this.bookRequestV2, true, client)
                            .then(res => {
                                const navigationExtras: NavigationExtras = {
                                    state: {
                                        bookingPostResDto: [res]
                                    }
                                };
                                const path = this.redirectToConfirmation();
                                this.router.navigate(
                                    [path],
                                    navigationExtras
                                );
                            });
                    });
                } else {
                    if (data.length) {
                        const path = this.redirectToSummary();
                        this.router.navigateByUrl(path);
                    }
                }
            });
        }
    }

    getClonedData() {
        const arrayCloned = [];
        arrayCloned.push({'flight': this.bookRequest, 'hotel': this.hotels, 'safe': this.bookRequestSafe});
        return arrayCloned;
    }

    prepareInsuredsBook() {
        const insureds = this.mapInsureds('insureds');
        this.bookRequestSafe.insuranceBookingPostReqDto.insureds = [
            ...insureds
        ];

        this.bookRequestSafe.insuranceBookingPostReqDto.insureds.forEach((insured, idx) => {
            if (idx === 0) {
                insured.isMain = true;
            } else {
                insured.isMain = false;
            }
            const objCountry = {
                'countryCode': this.bookRequestSafe.query.codeCountry,
                'countryCodeAlpha': this.bookRequestSafe.query.codeCountryAlpha
            };
            insured.country = objCountry;
        });
    }

    private mapInsureds(kind): any[] {
        return this.insuranceFormItem.controls[kind].value.map((el) => {
            return {
                ...el
            };
        });
    }

    prepareHotelsObject() {
        const bookingPost = new HotelBookingPostReqDto();
        if (this.hotels) {
            for (const child of this.hotels) {
                bookingPost.language = 'CAS';
                bookingPost.providerName = child.providerName;
                bookingPost.remarks = "";
                bookingPost.tolerance = 100;
                bookingPost.roomDetails = [{
                    checkIn: child.hotelGetReq.checkIn,
                    checkOut: child.hotelGetReq.checkOut,
                    currency: 'EUR',
                    hotelId: child.hotelId,
                    price: child.roomList[0].rooms[0].rates[0].price,
                    rateId: child.roomList[0].rooms[0].rates[0].rateId,
                    roomId: '1',
                    roomList: [{occupants: null}]
                }];
            }
        }
        this.hotels[0].bookingPostReqDto = bookingPost;
    }

    prepareObject() {
        /* if (!this.form.valid) {
            return;
        } */
        const adults = this.mapPaxes('adults', 'Adult');
        const children = this.mapPaxes('children', 'Child');
        const infants = this.mapPaxes('infants', 'Infant');

        this.bookRequest.paxes = [
            ...adults,
            ...children,
            ...infants
        ];
        this.bookRequest.cancelPolicies = this.cancelPolicies;
    }

    private mapPaxes(kind, typePax): any[] {
        return this.form.controls[kind].value.map((el) => {
            return {
                typePax: typePax,
                ...el
            };
        });
    }

    toggleSegment() {
        this.dspNone = !this.dspNone;
        this.changeIcon = !this.changeIcon;
    }

    calculateWaitingTime(segment: Segment, nextSegment: Segment) {
        const originMoment = moment(nextSegment.originDateTime, 'DD/MM/YYYY kk:mm');
        const destinationMoment = moment(segment.destinationDateTime, 'DD/MM/YYYY kk:mm');
        const diff = originMoment.diff(destinationMoment);
        const diffDuration = moment.duration(diff);
        const hours = diffDuration.days() * 24 + diffDuration.hours();
        const minutes = diffDuration.minutes();

        return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m` : ''}`;
    }

    loadFlightData(fromBasket = false) {
        this.priceGroup = !fromBasket ? this.request : this.request.clonedData;
        this.priceGroup.total = parseFloat(this.priceGroup.total).toFixed(2);
    }

    initComponent(paxes?) {
        this.dspNone = true;
        this.translate.setDefaultLang('es');
        if (paxes) {
            this.pax = {
                adults: new Array(parseInt(this.request.clonedData.distribution.adults, 10)),
                children: new Array(parseInt(this.request.clonedData.distribution.children, 10)),
                infants: new Array(parseInt(this.request.clonedData.distribution.infants, 10)),
            };
        } else {
            this.pax = {
                adults: new Array(parseInt(this.request.distribution.adults, 10)),
                children: new Array(parseInt(this.request.distribution.children, 10)),
                infants: new Array(parseInt(this.request.distribution.infants, 10)),
            };
        }
        this.initForm(paxes);
        this.bookRequest = this.setReqParameters(this.request);
        this.bookRequestSafe = this.setReqParametersSafe(this.request);

        if (this.priceGroup.clonedData) {
            this.loadCancelPoliciesClonedData();
        } else {
            this.loadCancelPolicies();
        }
    }

    initForm(paxes = null) {
        this.form = this.formBuilder.group({
            adults: this.formBuilder.array([]),
            children: this.formBuilder.array([]),
            infants: this.formBuilder.array([])
        });

        let adults = [];
        let children = [];
        let infants = [];
        if (paxes) {
            adults = paxes.filter(pax => pax.typePax === 'Adult');
            children = paxes.filter(pax => pax.type === 'Child');
            infants = paxes.filter(pax => pax.type === 'Infant');
        }

        for (let i = 0; i < this.pax.adults.length; i++) {
            this.formPax('adults', adults[i] || null);
        }

        for (let i = 0; i < this.pax.children.length; i++) {
            this.formPax('children', children[i] || null);
        }

        for (let i = 0; i < this.pax.infants.length; i++) {
            this.formPax('infants', infants[i] || null);
        }

    }

    formPax(arrayName, data = null) {
        const paxes = this.form.controls[arrayName] as FormArray;
        paxes.push(this.formBuilder.group({
            abbreviation: [data ? data.abbreviation : '', Validators.required],
            name: [data ? data.name : '', Validators.required],
            lastName: [data ? data.lastName : '', Validators.required],
            phone: [data ? data.phone : '', Validators.required],
            email: [data ? data.email : ''],
            typeDocument: [data ? data.typeDocument : 'PAS', Validators.required],
            documentNumber: [data ? data.documentNumber : '', Validators.required],
            birthdate: [data ? data.birthdate : '', Validators.required],
            expirationDate: [data ? data.expirationDate : '', Validators.required],
            nationality: [data ? data.nationality : 'ES', Validators.required],
            internationalCode: [34],
            phoneType: [data ? data.phoneType : 'mobile', Validators.required]
        }));
    }

    loadCancelPolicies() {
        if (this.priceGroup.fareNotes) {
            this.priceGroup.fareNotes.forEach(e => {
                if (e.code === "LTD") {
                    const description = e.description.split(' ');
                    const str = description[2].split('');
                    const datePolicy =
                        `Fecha limite para cancelar: ${str[3]}${str[4]} de ${str[5]}${str[6]}${str[7]} del 20${str[8]}${str[9]}`;
                    this.cancelPolicies.unshift(datePolicy);
                } else {
                    this.cancelPolicies.push(e.description);
                }

            });
        }
    }

    loadCancelPoliciesClonedData() {
        this.priceGroup.clonedData.fareNotes.forEach(e => {
            if (e.code === "LTD") {
                const description = e.description.split(' ');
                const str = description[2].split('');
                const datePolicy = `Fecha limite para cancelar: ${str[3]}${str[4]} de ${str[5]}${str[6]}${str[7]} del 20${str[8]}${str[9]}`;
                this.cancelPolicies.unshift(datePolicy);
            } else {
                this.cancelPolicies.push(e.description);
            }

        });
    }

    getPriceTotal(): number {
        let priceTotal: number;
        priceTotal = 0;
        const priceHotel: number = this.getPriceTotalHotel();
        priceTotal = Number(this.bookRequest.price) + priceHotel + this.bookRequestSafe.insuranceBookingPostReqDto.totalPrice;
        return priceTotal;
    }

    getPriceTotalHotel(): number {
        let priceHotel: number;
        priceHotel = 0;
        this.hotels.forEach(hotel => {
            hotel.bookingPostReqDto.roomDetails.forEach(room => {
                priceHotel += Number(room.price);
            });
        });
        return priceHotel;
    }

    setReqParametersV2(queryParams): ShoppingBasketHFS {
        const req: ShoppingBasketHFS = {
            uuid: queryParams.uuid,
            client: {},
            price: this.getPriceTotal(),
            clonedData: this.getClonedData(),
            cancelPolicies: [],
            hfs: true,
            integrationType: 'HF',
        };

        return req;
    }

    setReqParameters(queryParams): ShoppingBasketFlight {
        const req: ShoppingBasketFlight = {
            uuid: queryParams.uuid,
            requestId: parseInt(queryParams.requestId, 10),
            priceGroupId: queryParams.priceGroupId || parseInt(queryParams.id, 10),
            itinerariesId: queryParams.itineraries ? queryParams.itineraries.map(e => e.id).join(",") :
                queryParams.clonedData.itineraries.map(e => e.id).join(","),
            client: {},
            paxes: queryParams.paxes ? queryParams.paxes : this.getPaxesFlight(),
            price: queryParams.total || queryParams.price,
            clonedData: queryParams.clonedData ? queryParams.clonedData : queryParams,
            cancelPolicies: [],
            distribution: undefined,
            integrationType: 'FLIGHT',
        };

        return req;
    }

    getPaxesFlight() {
        const adults = this.mapPaxes('adults', 'Adult');
        const children = this.mapPaxes('children', 'Child');
        const infants = this.mapPaxes('infants', 'Infant');

        return [
            ...adults,
            ...children,
            ...infants
        ];
    }

    setReqParametersSafe(queryParams): ShopingBasketInsurance {
        const req: ShopingBasketInsurance = {
            uuid: queryParams.uuid,
            query: this.safe.query,
            client: undefined,
            insuranceBookingPostReqDto: this.setSafeBookReq(),
            insurance: this.safe.rows ? this.safe.rows[0] : this.safe.insurance,
            integrationType: 'INSURANCE',
        };
        return req;
    }

    setSafeBookReq(): InsuranceBookReqDto {
        const bookingPostReq = new InsuranceBookReqDto();
        bookingPostReq.name = this.safe.rows ? this.safe.rows[0].name : this.safe.insurance.name;
        bookingPostReq.checkIn = this.safe.query.checkIn;
        bookingPostReq.checkOut = this.safe.query.checkOut;
        bookingPostReq.insuredsTotal = this.safe.query.insureds;
        bookingPostReq.basePrice = this.safe.rows ? this.safe.rows[0].totalPrice : this.safe.insuranceBookingPostReqDto.totalPrice;
        bookingPostReq.totalPrice = this.safe.rows ? this.safe.rows[0].totalPrice : this.safe.insuranceBookingPostReqDto.totalPrice;
        bookingPostReq.policyId = this.safe.rows ? this.safe.rows[0].id : this.safe.insuranceBookingPostReqDto.policyId;
        bookingPostReq.insureds = [],
            bookingPostReq.params = this.safe.rows ? this.safe.rows[0].options : this.safe.insurance.options;
        return bookingPostReq;
    }

    getDateCorrectFormat(date) {
        const newDate = new Date(date);
        const options = {day: 'numeric', month: 'numeric', year: 'numeric'};
        return newDate.toLocaleDateString("es-ES", options);
    }

    getCategory(hotelCategory) {
        const stars = [1, 2, 3, 4, 5];
        return stars.filter((star: any) => {
            return star <= hotelCategory;
        });
    }

    checkNif() {
        return this.newClientForm.hasError('nif') &&
            this.newClientForm.get('document').touched;
    }

    checkRole(): boolean {
        if (this.microsite) {
            return false;
        }
        if (this.auth.role.id === ROLES.COMMERCIAL_DEMO) {
            return true;
        } else {
            return false;
        }
    }
}
