export enum ROLES {
    COMMERCIAL_DEMO = 6,
    CONTABILIDAD = 5,
    USUARIO_VENTAS = 4,
    JEFE_OFICINA = 3,
    DIRECTOR_AGENCIA = 2,
    SUPER_USER = 1
}

export const METHOD_LIST = [
    {id: '1', name: 'get'},
    {id: '2', name: 'post'},
];


export  const CONFIG_DEFAULT_CAROUSEL = {
    speed : 500,
    align : 'left',
    delay : 500,
    aniTime : 200,
    currentIndex : 0,
    breakpoint:  []
};

export enum SERVICES {
    HOTEL = 0,
    FLIGHT = 1,
    HOTEL_FLIGHT = 2,
    PACKAGE = 3,
    CIRCUIT = 4,
    TRANSPORT = 5,
    INSURANCE = 999
}

export enum MANAGEMENT_MODULES {
    COMMISSION,
    DAILY_CASH
}
