import { NgModule } from '@angular/core';
import { ActivitySearchFilterComponent } from './activity-search-filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SegmentFormPipe } from './segment.form.pipe';
import { IonicModule } from '@ionic/angular';
import { PriceRangeModule } from '../../../price-range/index';
import {LibSharedModule} from "../../../lib-shared/index";

@NgModule({
    declarations: [
        ActivitySearchFilterComponent,
        SegmentFormPipe,
    ],
    imports: [
        LibSharedModule,
        IonicModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        PriceRangeModule
    ],
    exports: [
        ActivitySearchFilterComponent
    ]
})
export class ActivitySearchFilterModule {
}
