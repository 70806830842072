import { Pipe, PipeTransform } from "@angular/core";
import { PassengerType } from "../../interfaces/multidestination/multidestination-pre-booking.model";
import * as moment_ from 'moment';

const moment = moment_;
type ConditionType = 'min' | 'max';

@Pipe({
    name: 'transformPassengerTypeToConditionDate'
})
export class TransformPassengerTypeToConditionDate implements PipeTransform {

  transform(passengerType: PassengerType, conditionType: ConditionType): Date {
    if (!passengerType) { return; }
    switch (conditionType) {
      case 'min':
        if (passengerType === 'Adult') { return new Date(1900, 0, 1); }
        if (passengerType === 'Child') { return moment().subtract(18, 'year').toDate(); }
        if (passengerType === 'Infant') { return moment().subtract(2, 'year').toDate(); }
        return;
      default:
        if (passengerType === 'Adult') { return moment().subtract(18, 'year').toDate(); }
        if (passengerType === 'Child') { return moment().subtract(1, 'year').subtract(1, 'day').toDate(); }
        if (passengerType === 'Infant') { return moment().subtract(1, 'year').toDate(); }
        return;
    }
  }
}
