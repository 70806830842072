import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  QueryList,
  AfterViewInit
} from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { CircuitFlightPack } from '@vecib2c/frontend-dto';

@Component({
  selector: 'lib-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnChanges, AfterViewInit {

  @Input() flights: CircuitFlightPack[];
  @Output() setSelected = new EventEmitter<number>();
  @ViewChildren(MatRadioButton) radioButtons: QueryList<MatRadioButton>;

  constructor() { }

  ngAfterViewInit() {
    this.checkRadioButtonClicked(0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.flights) {
      if (this.flights && this.flights.length) {
        this.setSelected.emit(0);
      }
    }
  }

  select(index): void {
    this.setSelected.emit(index);
    this.checkRadioButtonClicked(index);
  }

  checkRadioButtonClicked(index): void {
    const radioButtons = this.radioButtons.toArray();
    radioButtons[index].checked = true;
  }

}
