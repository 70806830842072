import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import {
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule
} from "@angular/material";
import { HotelAndFlightSearchComponent } from './hotel-and-flight-search.component';
import { LibSharedModule } from '../../lib-shared/index';
import { DateRangeModule } from '../../date-range/index';
import { PaxesModule } from '../../paxes/index';
import { PaxesHotelModule } from '../hotels/index';

@NgModule({
    declarations: [HotelAndFlightSearchComponent],
    exports: [HotelAndFlightSearchComponent],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatCheckboxModule,
        DateRangeModule,
        PaxesModule,
        MatExpansionModule,
        MatSelectModule,
        PaxesHotelModule,
        LibSharedModule
    ]
})
export class HotelAndFlightSearchModule {
}
