import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment_ from 'moment';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { TrainsProviderService } from '../../../lib-shared/services/index';
import { TrainsRequestDto, TrainStation } from '../../../lib-shared/interfaces/index';

const moment = moment_;

@Component({
    selector: 'lib-trains-search',
    templateUrl: './trains-search.component.html',
    styleUrls: ['./trains-search.component.scss'],
    providers: [TrainsProviderService]
})
export class TrainsSearchComponent implements OnInit, OnDestroy {

    @Input() trainParams: TrainsRequestDto;
    @Input() isSmall = false;

    form: FormGroup;
    checkInControl: FormControl = new FormControl();
    checkOutControl: FormControl = new FormControl();
    onlyDeparture: boolean;

    private unsubscribe = new Subject<void>();

    @Input() routerPath = '/integration/transports/trenes';
    defaultRouting = '/integration/transports/trenes';

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
    ) {

    }

    ngOnInit(): void {
        if (!this.trainParams) {
            this.trainParams = new TrainsRequestDto();
            this.onlyDeparture = false;
        } else {
            this.onlyDeparture = !!!this.trainParams.checkOut;
        }
        this.initForm();
    }

    ngOnDestroy(): void {
    }

    getRouting(): string {
        return this.routerPath ? this.routerPath : this.defaultRouting;
    }

    selectDeparture(trainStation: TrainStation): void {
        this.form.get('origin').setValue(trainStation.id);
        this.form.get('originName').setValue(trainStation.name);
    }

    selectArrival(trainStation: TrainStation): void {
        this.form.get('destination').setValue(trainStation.id);
        this.form.get('destinationName').setValue(trainStation.name);
    }

    search(): void {
        if (this.form.valid) {
            const request = this.buildRequest();
            this.router.navigate([this.getRouting()], {
                queryParams: request
            });
        }
    }

    changeOnlyDeparture(): void {
        this.checkOutControl.setValue('');
        if (this.onlyDeparture) {
            this.form.controls['checkOut'].clearValidators();
        } else {
            this.form.controls['checkOut'].setValidators([Validators.required]);
        }
        this.form.controls['checkOut'].updateValueAndValidity();
    }

    selectCheckIn(date: Date): void {
        this.checkInControl.setValue(date);
        this.checkInControl.markAsTouched();
    }

    selectCheckOut(date: Date): void {
        this.checkOutControl.setValue(date);
        this.checkOutControl.markAsTouched();
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            origin: [this.trainParams.origin, Validators.required],
            originName: [this.trainParams.originName, Validators.required],
            destination: [this.trainParams.destination, Validators.required],
            destinationName: [this.trainParams.destinationName, Validators.required],
            checkIn: [this.trainParams.checkIn, Validators.required],
            checkOut: [this.trainParams.checkOut, !this.onlyDeparture ? Validators.required : null],
            numberOfAdults: [this.trainParams.numberOfAdults, Validators.min(1)],
            numberOfChildren: [this.trainParams.numberOfChildren, Validators.min(0)],
            numberOfInfants: [this.trainParams.numberOfInfants, Validators.min(0)],
        });


        const checkInDate = !this.trainParams.checkIn ?
            moment().format('YYYY/MM/DD') :
            moment(this.trainParams.checkIn, 'DD/MM/YYYY').format('YYYY/MM/DD');

        const checkOutDate = !this.trainParams.checkOut ?
            (!this.onlyDeparture ?
                    moment().add(5, 'days').format('YYYY/MM/DD') :
                    ''
            ) :
            moment(this.trainParams.checkOut, 'DD/MM/YYYY').format('YYYY/MM/DD');

        this.initValueInFormControlDate(this.checkInControl, 'checkIn', checkInDate);
        this.initValueInFormControlDate(this.checkOutControl, 'checkOut', checkOutDate);

        this.setValueInFormControlDate(this.checkInControl, 'checkIn');
        this.setValueInFormControlDate(this.checkOutControl, 'checkOut');
    }

    private buildRequest(): TrainsRequestDto {
        const rawForm = this.form.getRawValue();
        const trainsRequestDto = new TrainsRequestDto();
        Object.keys(rawForm).forEach(key => {
            trainsRequestDto[key] = rawForm[key];
        });
        return trainsRequestDto;
    }

    private initValueInFormControlDate(control: FormControl, formControlName: string, date: string) {
        control.setValue(new Date(date));
        const formattedDate = moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
        this.form.get(formControlName).setValue(formattedDate);
    }

    private setValueInFormControlDate(control: FormControl, formControlName: string): void {
        control.valueChanges
            .pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(value => {
            const formattedDate = !value ? '' : moment(value).format('DD/MM/YYYY');
            this.form.get(formControlName).setValue(formattedDate);
        });
    }

}
