import { Input } from "@angular/core"
import { Location } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from "rxjs/operators"
import { TranslateService } from "@ngx-translate/core"
import { Observable, Subject } from "rxjs"
import { FormGroup } from "@angular/forms"
import { TecnoturisService } from "../tecnoturis.service"
import { BaseEntity } from "../lib-shared/Entities/index"
import {
  ClientServices,
  NotificationPopupService,
} from "../lib-shared/services/index"
import { AgencyChangeService } from "../agency-change/index"

export class BookEditComponent {
  @Input() id: string
  @Input() microsite = false
  @Input() validateClient = false
  @Input() showMoreServices = true
  newClient: boolean
  newClientForm: any
  client: any
  existingClientForm: FormGroup
  clientList$
  baseEntity: BaseEntity
  nationalities: any

  agency: number

  _unsubscribe = new Subject()

  constructor(
    protected location: Location,
    protected http: HttpClient,
    protected clientServices: ClientServices,
    protected notificationPopupService: NotificationPopupService,
    protected translate: TranslateService,
    protected tecnoturisService: TecnoturisService,
    protected agencyChangeService?: AgencyChangeService
  ) {
    this.newClient = false
    this.baseEntity = new BaseEntity(this.http, this.tecnoturisService)

    if (this.agencyChangeService) {
      this.agencyChangeService
        .getAgency()
        .subscribe((value) => (this.agency = value))
    }

    this.clientServices.getClient().then((data) => {
      this.client = data
      ;[this.existingClientForm, this.newClientForm, this.clientList$] =
        this.clientServices.initClientsForm()
      this.clientList$ = this.setClientListenerForAutocomplete(
        this.existingClientForm
      )
    })

    this.clientServices.subscription.subscribe(
      (client) => (this.client = client)
    )
  }

  cancel() {
    this.location.back()
  }

  trackByFn(index: any, item: any) {
    return index
  }

  async saveClientBase() {
    Object.keys(this.newClientForm.controls).forEach((field) => {
      const control = this.newClientForm.get(field)
      control.markAsTouched({ onlySelf: true })
    })

    if (this.newClientForm.valid) {
      const clientData = this.newClientForm.value

      delete clientData.id

      const data = { ...clientData, ...{ agency: this.agency } }

      return this.clientServices.clientEntity
        .create(data)
        .pipe(
          map((data) => {
            this.client = data
            this.clientServices.addClient({
              ...this.client,
              isPax: clientData["isPax"],
            })
            return data
          })
        )
        .subscribe()
    }
  }

  toogleClientForm() {
    this.newClient = !this.newClient
  }

  redirectToPath(): string {
    return this.microsite ? "/home" : "/intranet"
  }

  redirectToConfirmation(): string {
    return this.microsite
      ? "/bookings/confirmation"
      : "/integration/bookings/book-confirmation"
  }

  redirectToSummary(): string {
    return this.microsite
      ? "/bookings/summary"
      : "/integration/bookings/book-summary"
  }

  addToBasketAndRedirect(redirectPath?: string) {}

  goToInsuranceSearcher() {
    const redirectPath = this.microsite
      ? "/home"
      : "/intranet/search/insurances"
    this.addToBasketAndRedirect(redirectPath)
  }

  goToActivitySearcher() {
    const redirectPath = this.microsite
      ? "/home"
      : "/intranet/search/activities"
    this.addToBasketAndRedirect(redirectPath)
  }

  goToTransportSearcher() {
    const redirectPath = this.microsite
      ? "/home"
      : "/intranet/search/transports"
    this.addToBasketAndRedirect(redirectPath)
  }

  public setClientListenerForAutocomplete(
    existingClientForm: FormGroup
  ): Observable<any> {
    return existingClientForm.get("clientAutocomplete").valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      map((value: string) => value.trim()),
      filter((value: string) => value.length > 3),
      switchMap((term: string) =>
        this.clientServices.clientEntity
          .list(1, 10, term)
          .pipe(map((data) => data["rows"]))
      )
    )
  }

  displayFn(data) {
    return data && data.name ? `${data.name} ${data.surname}` : ""
  }

  clientSelected(data) {
    this.client = data.option.value
    this.clientServices.addClient(this.client)
  }

  checkClient(): boolean {
    if (!this.client && !this.validateClient) {
      this.notificationPopupService.openPopup(
        this.translate.instant("ERROR.HOLDER")
      )
      return false
    }

    return true
  }

  removeCurrentClient(existingClientForm) {
    this.client = null
    this.clientServices.removeClient()
    existingClientForm.controls["clientAutocomplete"].setValue(null)
  }

  async saveClient() {
    this.saveClientBase().then((savedClient) => {})
  }

  showClient() {}

  redirectGeneralConditions() {
    if (this.microsite) {
      return "/#/general-conditions"
    } else {
      return "/#"
    }
  }

  redirectPolitics() {
    if (this.microsite) {
      return "/#/politics/privacy"
    } else {
      return "/#"
    }
  }
}
