import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldType, IDynamicCrud } from '../lib-shared/interfaces/index';
import { dossierForm } from './dossier.form';

@Component({
    selector: 'lib-dossier-per-clients',
    templateUrl: './dossier-per-clients.component.html',
    styleUrls: ['./dossier-per-clients.component.scss']
})
export class DossierPerClientsComponent implements OnInit {
    dynamicCrud: IDynamicCrud = JSON.parse(JSON.stringify(dossierForm));

    client;

    dossier;

    constructor(public dialogRef: MatDialogRef<DossierPerClientsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dynamicCrud.urlPathList = 'clients/id/dossier/?type=1';
        this.dynamicCrud.filters = [
            {
                keyName: 'code',
                name: 'Id',
                type: FieldType.CHAR,
            },
        ];
        if (this.data && 'client' in data) {
            this.client = data.client;
        }
    }

    ngOnInit() {

    }

    saveDossier(): void {
        this.dialogRef.close(this.dossier);
    }

}
