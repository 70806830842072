import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ShopingBasketHotel } from "../../integrations/hotels/dto/ShopingBasketHotel";
import { ShoppingBasketFlight } from "../../integrations/hotels/dto/ShoppingBasketFlight";
import { ShopingBasketCircuit } from "../../integrations/circuits/dto/ShopingBasketCircuit";
import { ShopingBasketInsurance } from "../../integrations/insurances/dto/ShopingBasketInsurance";
import { t } from 'typy';
import {
    ShoppingBasketActivity,
    ShoppingBasketCars,
    ShoppingBasketMultidestination,
    ShoppingBasketPackage,
    ShoppingBasketTrains,
} from "../interfaces/index";
import { CustomValidators } from "../validators/customValidators";
import * as moment_ from 'moment';
import { TransformPassengerTypeToConditionDate } from "../pipes/index";
import { ShoppingBasketTransfer } from '../interfaces/shopping-basket-transfers.model';

const moment = moment_;

@Injectable({
    providedIn: 'root',
})
export class BasketIntegrationFormService {

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly transformPassengerTypeToConditionDatePipe: TransformPassengerTypeToConditionDate,
    ) {
    }

    createHotelForm(item: ShopingBasketHotel): FormArray {
        const occupantList = [];
        const occupants = item.bookingPostReqDto.roomDetails[0].roomList[0].occupants;
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                name: [t(occupant, 'name').safeString, Validators.required],
                surname: [t(occupant, 'surname').safeString, Validators.required],
                type: [t(occupant, 'type').safeString, Validators.required],
                age: [t(occupant, 'age').safeObject, Validators.required]
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });
        return this._formBuilder.array(occupantList);
    }

    createFlightForm(item: ShoppingBasketFlight): FormArray {
        const occupantList = [];
        const occupants = item.paxes;
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                abbreviation: [occupant ? occupant.abbreviation : '', Validators.required],
                name: [occupant ? occupant.name : '', Validators.required],
                lastName: [occupant ? occupant.lastName : '', Validators.required],
                phoneType: [occupant ? occupant.phoneType : '', Validators.required],
                phone: [occupant ? occupant.phone : '', Validators.required],
                email: [occupant ? occupant.email : ''],
                typeDocument: [occupant ? occupant.typeDocument : 'PAS', Validators.required],
                documentNumber: [occupant ? occupant.documentNumber : '', Validators.required],
                birthdate: [occupant ? occupant.birthdate : '', Validators.required],
                expirationDate: [occupant ? occupant.expirationDate : '', Validators.required],
                nationality: [occupant ? occupant.nationality : 'ES', Validators.required],
                internationalCode: [34],
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });

        return this._formBuilder.array(occupantList);
    }

    createCircuitForm(item: ShopingBasketCircuit): FormArray {
        const occupantList = [];
        const occupants = item.circuitBookingPostReqDto.rooms[0].passengers;
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                name: [t(occupant, 'name').safeString, Validators.required],
                surname: [t(occupant, 'surname').safeString, Validators.required],
                age: [t(occupant, 'age').safeObject, Validators.required],
                gender: [t(occupant, 'gender').safeObject, Validators.required],
                documentId: [t(occupant, 'documentId').safeObject, Validators.required]
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });

        return this._formBuilder.array(occupantList);
    }

    createInsuranceForm(item: ShopingBasketInsurance): FormArray {
        const occupantList = [];
        const occupants = item.insuranceBookingPostReqDto.insureds;
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                name: [t(occupant, 'name').safeString, Validators.required],
                surname: [t(occupant, 'surname').safeString, Validators.required],
                documentType: [t(occupant, 'documentType').safeString, Validators.required],
                documentNumber: [t(occupant, 'documentNumber').safeObject, Validators.required],
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });
        return this._formBuilder.array(occupantList);
    }

    createPackageForm(item: ShoppingBasketPackage): FormArray {
        const occupantList = [];
        const occupants = item.paxes;
        const agency = [item.agencyInfo]
        
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                abbreviation: [t(occupant, 'abbreviation').safeString, Validators.required],
                name: [t(occupant, 'name').safeString, Validators.required],
                lastName: [t(occupant, 'lastname').safeString, Validators.required],
                phone: [t(occupant, 'phone').safeString, Validators.nullValidator],
                email: [t(occupant, 'email').safeString, Validators.nullValidator],
                documentType: [t(occupant, 'documentType').safeString, Validators.required],
                documentNumber: [t(occupant, 'documentNumber').safeString, Validators.nullValidator],
                type: [t(occupant, 'type').safeString, Validators.required],
                documentExpirationDate: [t(occupant, 'documentExpirationDate').safeString, Validators.nullValidator],
                birthdate: [t(occupant, 'birthdate').safeString, Validators.required],
                nationality: [t(occupant, 'nationality').safeString, Validators.required],
                phoneNumberCode: [t(occupant, 'phoneNumberCode').safeObject, Validators.required],
            });
        };
        const generateAgency = (agency) => {
            return this._formBuilder.group({
                expediente: [t(agency, 'expediente').safeString, [Validators.minLength(13), Validators.maxLength(13), Validators.pattern("(^([0-9]{7,15})$)")]],
                agentNum: [t(agency, 'agentNum').safeString, Validators.required],
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });
        agency.forEach(agency => {
            occupantList.push(generateAgency(agency));
        });
        
        return this._formBuilder.array(occupantList);
    }

    createCarForm(item: ShoppingBasketCars): FormGroup {
        const driver = this._formBuilder.group({
            driverTitle: [t(item, 'driverTitle').safeString, Validators.required],
            driverInitial: [t(item, 'driverInitial').safeString, Validators.required],
            driverLastName: [t(item, 'driverLastName').safeString, Validators.required],
            driverAge: [t(item, 'driverAge').safeObject, Validators.required],
        });

        return driver;
    }

    createTrainForm(item: ShoppingBasketTrains): FormArray {
        const occupantList = [];
        const occupants = item.paxes;
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                paxType: [occupant ? occupant.paxType : '', Validators.required],
                name: [occupant ? occupant.name : '', Validators.required],
                firstSurname: [occupant ? occupant.firstSurname : '', Validators.required],
                secondSurname: [occupant ? occupant.secondSurname : '', Validators.required],
                email: [occupant ? occupant.email : ''],
                documentType: [occupant ? occupant.documentType : '', Validators.required],
                documentNumber: [occupant ? occupant.documentNumber : '', Validators.required],
                age: [occupant ? occupant.age : '', Validators.required],
                phone: [occupant ? occupant.phone : '', Validators.required],
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });

        return this._formBuilder.array(occupantList);
    }

    createMultidestinationForm(item: ShoppingBasketMultidestination): FormGroup {
        const minDocumentExpirationDate = moment().add(1, 'years').toDate();
        const occupants = item.passengers.map(occupant => {
          return this._formBuilder.group({
            type: [occupant ? occupant.type : '', Validators.required],
            name: [occupant ? occupant.name : '', Validators.required],
            lastname: [occupant ? occupant.lastname : '', Validators.required],
            email: [occupant ? occupant.email : '', Validators.required],
            birthdate: [occupant ? occupant.birthdate : '', [ Validators.required,
              CustomValidators.minDate(this.transformPassengerTypeToConditionDatePipe.transform(occupant.type, 'min')),
              CustomValidators.maxDate(this.transformPassengerTypeToConditionDatePipe.transform(occupant.type, 'max')) ]
            ],
            abbreviation: [occupant ? occupant.abbreviation : '', Validators.required],
            phoneNumberCode: [occupant ? occupant.phoneNumberCode : '', Validators.required],
            phoneType: [occupant ? occupant.phoneType : '', Validators.required],
            phone: [occupant ? occupant.phone : '', Validators.required],
            documentType: [occupant ? occupant.documentType : '', Validators.required],
            documentNumber: [occupant ? occupant.documentNumber : '', Validators.required],
            documentExpirationDate: [occupant ? occupant.documentExpirationDate : '', [
              Validators.required,
              CustomValidators.minDate(minDocumentExpirationDate)]
            ],
            nationality: [occupant ? occupant.nationality : '', Validators.required],
            roomNumber: [occupant ? occupant.roomNumber : '', Validators.required],
          });
        });

        return this._formBuilder.group({
            passengers: this._formBuilder.array(occupants),
        });
    }

    createActivityForm(item: ShoppingBasketActivity): FormGroup {
        const form = this._formBuilder.group({
            language: [t(item, 'bookingParameters.language').safeString, Validators.required],
            clientReference: [t(item, 'bookingParameters.clientReference').safeString],
            holder: this._formBuilder.group({
                name: [t(item, 'bookingParameters.holder.name').safeString, Validators.required],
                email: [t(item, 'bookingParameters.holder.email').safeString, Validators.required],
                address: [t(item, 'bookingParameters.holder.email').safeString],
                surname: [t(item, 'bookingParameters.holder.surname').safeString],
                telephones: [t(item, 'bookingParameters.holder.telephones').safeArray, Validators.required],
            }),
            activities: this._formBuilder.array([]),
        });

        item.bookingParameters.activities.forEach(activity => {
            (form.get('activities') as FormArray).push(
                this._formBuilder.group({
                    preferedLanguage: [activity.preferedLanguage, Validators.required],
                    serviceLanguage: [activity.serviceLanguage, Validators.required],
                    from: [activity.from, Validators.required],
                    to: [activity.to, Validators.required],
                    rateKey: [activity.rateKey, Validators.required],
                    paxes: this._formBuilder.array(activity.paxes.map(pax => {
                        return this._formBuilder.group({
                            name: [pax.name, Validators.required],
                            type: [pax.type, Validators.required],
                            surname: [pax.surname, Validators.required],
                            age: [pax.age, Validators.required],
                        });
                    })),
                })
            );
        });

        return form;
    }

    createTransferForm(item: ShoppingBasketTransfer): FormArray {
        const occupantList = [];
        const occupants = item.paxes;
        const generateOccupant = (occupant) => {
            return this._formBuilder.group({
                firtName: [occupant ? occupant.firtName : '', Validators.required],
                lastName: [occupant ? occupant.lastName : '', Validators.required],
                email: [occupant ? occupant.email : ''],
                phone: [occupant ? occupant.phone : ''],
                documentationNumber: [occupant ? occupant.documentationNumber : '', Validators.required],
                age: [occupant ? occupant.age : '', Validators.required],
            });
        };
        occupants.forEach(occupant => {
            occupantList.push(generateOccupant(occupant));
        });
        return this._formBuilder.array(occupantList);
    }

}
