import { calculateAge } from "../../functions/index";
import { Client } from "../client.model";
import { AbbreviationType, MultidestinationPassenger } from "../multidestination/multidestination-pre-booking.model";
import { ShoppingBasketCars } from "../transports.model";
import { CarAvailabilityReq } from "./availability.req.model";
import { Car, CarAvailabilityRes, Cost, DetailedPricing, ExtraDetail } from "./availability.res.model";

export class CarState {
    pickUpLocationID: string;
    dropOffLocationID: string;
    pickUpLocationName: string;
    dropOffLocationName: string;
    pickUpLocationCountry: string;
    dropOffLocationCountry: string;
    pickUpDate: string;
    dropOffDate: string;
    pickUpTime: string;
    dropOffTime: string;
    name: string;
    url: string;
    passenger: string;
    doors: number;
    totalCharge: number;
    isAirCon: string;
    isAutomatic: string;
    supplier: string;
    carType: string;
    rateQualifier: string;
    supplierCode: string;
    productID: string;
    currency: string;
    additionalParameters: string[];
    nameRate: string;
    extras: CarExtra[];
    rate: Cost;
    luggage: number;
    fuel: string;
}

export class CarBookingDtoReq implements CarBookingDriver {
    additionalParameters: string[];
    carType: string;
    driverAge: number;
    driverInitial: string;
    driverLastName: string;
    driverTitle: string;
    dropOffDate: string;
    dropOffLocationID: number;
    dropOffTime: string;
    dropOffLocationCountry: string;
    dropOffLocationName: string;
    extras: CarExtra[];
    flightNumber?: string;
    name: string;
    nameRate: string;
    pickUpDate: string;
    pickUpLocationID: number;
    pickUpTime: string;
    pickUpLocationCountry: string;
    pickUpLocationName: string;
    productID: number;
    rateQualifier: string;
    supplierCode: string;
    supplierName: string;
    totalCharge: number;
    client: Client;
    extrasInfo: CarExtraInfo[];
    urlImage: string;
    uuid: string;
    detailedPricing: DetailedPricing;

    static build(shoppingBasket: ShoppingBasketCars, uuid: string, client: Client): CarBookingDtoReq {
        const obj = {
            supplierCode: shoppingBasket.supplierCode,
            supplierName: shoppingBasket.supplierName,
            pickUpLocationID: shoppingBasket.pickUpLocationID,
            dropOffLocationID: shoppingBasket.dropOffLocationID,
            pickUpDate: shoppingBasket.pickUpDate,
            dropOffDate: shoppingBasket.dropOffDate,
            pickUpTime: shoppingBasket.pickUpTime,
            dropOffTime: shoppingBasket.dropOffTime,
            productID: shoppingBasket.productID,
            carType: shoppingBasket.carType,
            extras: shoppingBasket.extras,
            extrasInfo: shoppingBasket.extrasInfo,
            rateQualifier: shoppingBasket.rateQualifier,
            driverAge: shoppingBasket.driverAge,
            driverTitle: shoppingBasket.driverTitle,
            driverInitial: shoppingBasket.driverInitial,
            driverLastName: shoppingBasket.driverLastName,
            totalCharge: shoppingBasket.totalCharge,
            uuid,
            client,
            name: shoppingBasket.name,
            pickUpLocationName: shoppingBasket.pickUpLocationName,
            dropOffLocationName: shoppingBasket.dropOffLocationName,
            pickUpLocationCountry: shoppingBasket.pickUpLocationCountry,
            dropOffLocationCountry: shoppingBasket.dropOffLocationCountry,
            urlImage: btoa(shoppingBasket.urlImage),
            nameRate: shoppingBasket.nameRate,
            additionalParameters: shoppingBasket.additionalParameters,
            detailedPricing: shoppingBasket.rate.detailedPricing,
        };
        return obj;
    }

    static buildFromCarRequestAndResponse(
        carRequest: CarAvailabilityReq,
        carResponse: CarAvailabilityRes,
    ): Partial<CarBookingDtoReq> {
        const partialReqFromCarRequest = this.buildFromCarRequest(carRequest);
        const partialReqFromCarResponse = this.buildFromCarResponse(carResponse);
        const obj = {
            ...partialReqFromCarRequest,
            ...partialReqFromCarResponse,
        };
        return obj;
    }

    static buildFromCarRequest(
        carRequest: CarAvailabilityReq,
    ): Partial<CarBookingDtoReq> {
        const {
            PickupLocationID: pickUpLocationID,
            DropoffLocationID: dropOffLocationID,
            PickupDate: pickUpDate,
            DropoffDate: dropOffDate,
            PickupTime: pickUpTime,
            DropoffTime: dropOffTime,
            ProductID: productID,
            PickUpLocationName: pickUpLocationName,
            DropOffLocationName: dropOffLocationName,
            PickUpLocationCountry: pickUpLocationCountry,
            DropOffLocationCountry: dropOffLocationCountry,
        } = carRequest;

        const obj = {
            pickUpLocationID,
            dropOffLocationID,
            pickUpDate,
            dropOffDate,
            pickUpTime,
            dropOffTime,
            productID,
            pickUpLocationName,
            dropOffLocationName,
            pickUpLocationCountry,
            dropOffLocationCountry,
        };
        return obj;
    }

    static buildFromCarResponse(
        carResponse: CarAvailabilityRes,
    ): Partial<CarBookingDtoReq> {
        const { CarSet: cars } = carResponse;
        const car = cars[0];
        const rate = car.Costs.Cost[0];
        const {
            SupplierCode: supplierCode,
            Supplier: supplierName,
            CarType: carType,
            extras,
            RateQualifier: rateQualifier,
            TotalCharge: totalCharge,
            Name: carName,
            URL: url,
            AdditionalParameters: additionalParameters,
            extraDetails,
        } = car;

        const {
            Product,
            detailedPricing,
        } = rate;
        const obj = {
            supplierCode,
            supplierName,
            carType,
            extras,
            rateQualifier,
            totalCharge,
            name: carName,
            urlImage: url,
            nameRate: Product,
            additionalParameters,
            detailedPricing: detailedPricing,
            extrasInfo: extraDetails,
        };
        return obj;
    }

}

export class CarBookingDriver {
    driverAge: number;
    driverInitial: string;
    driverLastName: string;
    driverTitle: string;

    static buildDriverFromMultidestinationPassenger(passenger: MultidestinationPassenger) {
        if (!passenger) {
            const obj = {
                driverAge: null,
                driverInitial: '',
                driverLastName: '',
                driverTitle: '',
            };
            return obj;
        }
        const obj = {
            driverAge: calculateAge(passenger.birthdate),
            driverInitial: passenger.name,
            driverLastName: passenger.lastname,
            driverTitle: this.buildPaxTitleFromCar(passenger.abbreviation)
        };
        return obj;
    }

    private static buildPaxTitleFromCar(
        abbreviation: AbbreviationType,
    ): string {
        if (abbreviation === 'Mr') { return 'Mr.'; }
        if (abbreviation === 'Mrs' || abbreviation === 'Ms') { return 'Mrs.'; }
    }
}

export class CarExtraInfo {
    Currency: string;
    ExtraID: string;
    Information: string;
    MaxAmount: string;
    Name: string;
    Period: string;
    Price: string;
    SupplierCode: string;
}

export class CarExtra {
    supplierCode: string;
    amount: number;

    static buildExtraFromExtraDetail(extras: ExtraDetail[] = []): CarExtra[] {
        const arr = extras.map(extra => {
            return {
                supplierCode: extra.SupplierCode,
                amount: 1,
            };
        });
        return arr;
    }
}
