import { Component, OnInit } from '@angular/core';
import { transportsForm } from './transports.form';
import { TecnoturisService } from '../../tecnoturis.service';

@Component({
    selector: 'lib-transports-booking',
    templateUrl: './transports-booking.component.html',
    styleUrls: ['./transports-booking.component.scss']
})
export class TransportsBookingComponent implements OnInit {
    transportsCrud = transportsForm;

    constructor(private tecnoturisService: TecnoturisService) {
        if (this.tecnoturisService.config.forms.transportForm) {
            this.transportsCrud = this.tecnoturisService.config.forms.transportForm;
        }
    }

    ngOnInit() {
    }

}
